import { getCurrentLineMaterial } from "lib/materials";
import { ArcCommand } from "../../commands/primitives/arc";
import { setPosBuffer } from "../../geometries";
import { arcBuffer2pC, arcBuffer3p, arcBufferCR2p } from "../../geometries/arc";
import {
  lineAddVertex,
  lineAuxCreate,
  lineCreate,
  lineMoveVertex,
} from "../../geometries/line";
import { pointCreate } from "../../geometries/point";
import {
  getAngleBetweenAzimuts,
  lineAzimut2p,
  normalizeAngle,
  ORIENT,
} from "../../math/angles";
import { arcParam, circleGetCenter2pR } from "../../math/arc";
import { circleGetCenter3p, getArcDirectionFrom3p } from "../../math/arc";
import { vectorDist3D } from "../../math/distance";
import { vector3Equals } from "../../math/epsilon";
import {
  copyIPoint,
  getMiddlePoint,
  pointLinePositionXY,
} from "../../math/point";
import { IPoint } from "../../math/types";
import { getRelativePoint } from "../../coordinates/plane";
import { cadOpType } from "../factory";
import { settingsOpModes } from "../step-operations";
import { WireframeOP } from "./wireframe";

export enum arcTypeOP {
  ThreePoints,
  CenterFirstLast,
  FirstLastCenter,
  FirstLastRad,
  FirstLastAng,
}

export type arcOperation =
  | ArcThreePointsOP
  | ArcCenterFirstLastOP
  | ArcFirstLastCenterOP
  | ArcFirstLastRadOP
  | ArcFirstLastAngOP;

interface IArcOperation {
  op: ArcBaseOP;
  iniSettingsOp(): void;
  moveLastPoint(pto: IPoint): void;
  setLastPoint(): void;
  save(): void;
}

export class ArcBaseOP extends WireframeOP {
  public opType = cadOpType.ARC;
  public arcOperation: arcOperation;

  public center: IPoint;
  public radius: number;
  public azimutO: number;
  public angleCenter: number;
  public direction: ORIENT = ORIENT.CW;

  public firstPoint: IPoint;
  public thirdPoint: IPoint;
  public lineArc: THREE.Line;
  public auxLine: THREE.Line;
  public centerAux: THREE.Points;

  constructor(type: arcTypeOP, direction: ORIENT, styleId?: string) {
    super(styleId);
    switch (type) {
      case arcTypeOP.ThreePoints:
        this.arcOperation = new ArcThreePointsOP(this);
        break;
      case arcTypeOP.CenterFirstLast:
        this.arcOperation = new ArcCenterFirstLastOP(this, direction);
        break;
      case arcTypeOP.FirstLastCenter:
        this.arcOperation = new ArcFirstLastCenterOP(this, direction);
        break;
      case arcTypeOP.FirstLastRad:
        this.arcOperation = new ArcFirstLastRadOP(this, direction);
        break;
      case arcTypeOP.FirstLastAng:
        this.arcOperation = new ArcFirstLastAngOP(this, direction);
        break;
      default:
        this.arcOperation = new ArcThreePointsOP(this);
        break;
    }
  }
  protected iniSettingsOp() {
    this.arcOperation.iniSettingsOp();
  }

  public async start() {
    await super.start();
    this.initializeArc();
    this.eventManager.connectCustomKeyEvent(this.changeDirection, "m");
  }

  private initializeArc(): void {
    this.lineArc = lineCreate();
    this.auxLine = lineAuxCreate();
    lineAddVertex(this.auxLine, 0, 0, 0);
    lineAddVertex(this.auxLine, 0, 0, 0);
    this.saveToTempScene(this.lineArc);
    this.saveToTempScene(this.auxLine);
  }

  private changeDirection = (): void => {
    this.direction = this.direction === ORIENT.CW ? ORIENT.CCW : ORIENT.CW;
  };

  public moveLastPoint(pto: IPoint) {
    this.arcOperation.moveLastPoint(pto);
  }

  public setLastPoint() {
    this.arcOperation.setLastPoint();
  }

  public save() {
    this.arcOperation.save();
  }

  public cancelOperation(): void {
    if (!this.finished) {
      this.eventManager.disconnectKeyEvent(this.changeDirection);
      super.endOperation();
    }
  }

  public endOperation(): void {
    if (!this.finished) {
      this.eventManager.disconnectKeyEvent(this.changeDirection);
      this.save();
      super.endOperation();
    }
  }

  public getMinRadius(
    ptoExternal: IPoint,
    firstPoint: IPoint,
    thirdPoint: IPoint
  ): number {
    let radius = vectorDist3D(ptoExternal, thirdPoint);
    const minRadius = vectorDist3D(firstPoint, thirdPoint) * 0.5;
    if (radius < minRadius) radius = minRadius;
    return radius;
  }

  public arcGetCenter2pR(
    ptoExternal: IPoint,
    firstPoint: IPoint,
    thirdPoint: IPoint,
    rotation: IPoint
  ): IPoint {
    let radius = this.getMinRadius(ptoExternal, firstPoint, thirdPoint);
    const relPto = getRelativePoint(ptoExternal, firstPoint, rotation);
    const relFirst = getRelativePoint(firstPoint, firstPoint, rotation);
    const relThird = getRelativePoint(thirdPoint, firstPoint, rotation);

    let center: IPoint[];
    if (pointLinePositionXY(relPto, relFirst, relThird) < 0) {
      center = circleGetCenter2pR(
        thirdPoint,
        firstPoint,
        radius,
        rotation
      ) as [IPoint, IPoint];
    } else {
      center = circleGetCenter2pR(
        firstPoint,
        thirdPoint,
        radius,
        rotation
      ) as [IPoint, IPoint];
    }
    return center[0];
  }

  public executeCommand() {
    if (this.graphicProcessor) {
      const arc: arcParam = {
        center: this.center,
        radius: this.radius,
        azimutO: normalizeAngle(this.azimutO),
        angleCenter: this.angleCenter,
        plane: this.currPlane.rotation,
      };
      const command = new ArcCommand(
        arc,
        this.getCurrentSceneId(),
        this.graphicProcessor,
        getCurrentLineMaterial({ lineStyleId: this.lineStyleId })
      );
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }

  public saveAzimutOAngleCenterAndDirection(
    firstPoint: IPoint,
    thirdPoint: IPoint
  ) {
    const c = this.currPlane.getRelativePoint(this.center);
    const p1 = this.currPlane.getRelativePoint(firstPoint);
    this.azimutO = lineAzimut2p(c, p1);

    const p3 = this.currPlane.getRelativePoint(thirdPoint);
    const azimutEnd = lineAzimut2p(c, p3);

    this.angleCenter = getAngleBetweenAzimuts(
      this.azimutO,
      azimutEnd,
      this.direction
    );
    if (this.direction === ORIENT.CCW) this.angleCenter *= -1;
  }
}

class ArcThreePointsOP implements IArcOperation {
  public op: ArcBaseOP;

  public secondPoint: IPoint;

  constructor(arcBaseOP: ArcBaseOP) {
    this.op = arcBaseOP;
  }
  public iniSettingsOp(): void {
    this.op.settingsOpManager.setCfg([{
      infoMsg: "Insert point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.op.addPointFromExt.bind(this.op),
    }, {
      infoMsg: "Insert point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.op.addPointFromExt.bind(this.op),
    }, {
      infoMsg: "Insert point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.op.addPointFromExt.bind(this.op),
    }]);
  }

  public moveLastPoint(pto: IPoint) {
    if (this.op.numPoints > 0) {
      lineMoveVertex(this.op.auxLine, pto.x, pto.y, pto.z);
      if (this.op.numPoints === 2) {
        this.op.thirdPoint = copyIPoint(pto);
        this.calculateArc();
      }
    }
  }

  public setLastPoint(): void {
    const { x, y, z } = this.op.lastPoint;
    if (this.op.numPoints === 1) {
      const planeManager = this.op.graphicProcessor.getPlaneManager();
      planeManager.activePlane.locked = true;
      lineMoveVertex(this.op.auxLine, x, y, z, 0);
      lineMoveVertex(this.op.auxLine, x, y, z);
      this.op.firstPoint = copyIPoint(this.op.lastPoint);
      this.op.setNextStep();
    } else if (this.op.numPoints === 2) {
      if (!vector3Equals(this.op.firstPoint, this.op.lastPoint)) {
        lineMoveVertex(this.op.auxLine, x, y, z);
        lineAddVertex(this.op.auxLine, x, y, z);
        this.op.centerAux = pointCreate(0, 0, 0);
        this.op.saveToTempScene(this.op.centerAux);
        this.secondPoint = copyIPoint(this.op.lastPoint);
        this.op.setNextStep();
      } else {
        this.op.numPoints -= 1;
        return;
      }
    } else if (this.op.numPoints === 3) {
      this.op.thirdPoint = copyIPoint(this.op.lastPoint);
      this.op.endOperation();
    }
  }

  private calculateArc(): void {
    let coords: Float32Array | null;
    if (this.op.firstPoint && this.secondPoint && this.op.thirdPoint) {
      coords = arcBuffer3p(
        this.op.firstPoint,
        this.secondPoint,
        this.op.thirdPoint,
        this.op.currPlane.rotation
      );
      if (coords) {
        this.op.center = circleGetCenter3p(
          this.op.firstPoint,
          this.secondPoint,
          this.op.thirdPoint
        ) as IPoint;
        this.op.centerAux.position.set(
          this.op.center.x,
          this.op.center.y,
          this.op.center.z
        );
      }
    } else {
      return;
    }
    if (!coords) coords = new Float32Array(0);
    setPosBuffer(this.op.lineArc, coords);
  }

  public save() {
    this.op.radius = vectorDist3D(this.op.firstPoint, this.op.center);
    this.op.direction = getArcDirectionFrom3p(
      this.op.firstPoint,
      this.secondPoint,
      this.op.thirdPoint,
      this.op.currPlane.rotation
    );
    this.op.saveAzimutOAngleCenterAndDirection(
      this.op.firstPoint,
      this.op.thirdPoint
    );

    this.op.executeCommand();
  }
}

class ArcCenterFirstLastOP implements IArcOperation {
  public op: ArcBaseOP;

  constructor(arcBaseOP: ArcBaseOP, direction: ORIENT) {
    this.op = arcBaseOP;
    this.op.direction = direction;
  }
  public iniSettingsOp(): void {
    this.op.settingsOpManager.setCfg([{
      infoMsg: "Insert center: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.op.addPointFromExt.bind(this.op),
    }, {
      infoMsg: "Insert point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.op.addPointFromExt.bind(this.op),
    }, {
      infoMsg: "Insert point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.op.addPointFromExt.bind(this.op),
    }]);
  }
  public moveLastPoint(pto: IPoint) {
    if (this.op.numPoints > 0) {
      lineMoveVertex(this.op.auxLine, pto.x, pto.y, pto.z);
      if (this.op.numPoints === 2) {
        this.op.thirdPoint = copyIPoint(pto);
        this.calculateArc();
      }
    }
  }

  private calculateArc(): void {
    let coords: Float32Array | null;
    if (this.op.firstPoint && this.op.center && this.op.thirdPoint) {
      coords = arcBuffer2pC(
        this.op.firstPoint,
        this.op.thirdPoint,
        this.op.center,
        this.op.direction,
        this.op.currPlane.rotation
      );
    } else {
      return;
    }
    if (!coords) coords = new Float32Array(0);
    setPosBuffer(this.op.lineArc, coords);
  }

  public setLastPoint(): void {
    const { x, y, z } = this.op.lastPoint;
    if (this.op.numPoints === 1) {
      const planeManager = this.op.graphicProcessor.getPlaneManager();
      planeManager.activePlane.locked = true;
      lineMoveVertex(this.op.auxLine, x, y, z, 0);
      lineMoveVertex(this.op.auxLine, x, y, z);
      this.op.centerAux = pointCreate(0, 0, 0);
      this.op.saveToTempScene(this.op.centerAux);
      this.op.center = copyIPoint(this.op.lastPoint);
      this.op.setNextStep();
    } else if (this.op.numPoints === 2) {
      lineMoveVertex(this.op.auxLine, x, y, z);
      this.op.firstPoint = copyIPoint(this.op.lastPoint);
      this.op.setNextStep();
    } else if (this.op.numPoints === 3) {
      this.op.thirdPoint = copyIPoint(this.op.lastPoint);
      this.op.endOperation();
    }
  }

  public save() {
    this.op.radius = vectorDist3D(this.op.firstPoint, this.op.center);
    this.op.saveAzimutOAngleCenterAndDirection(
      this.op.firstPoint,
      this.op.thirdPoint
    );
    this.op.executeCommand();
  }
}

class ArcFirstLastCenterOP implements IArcOperation {
  public op: ArcBaseOP;

  constructor(arcBaseOP: ArcBaseOP, direction: ORIENT) {
    this.op = arcBaseOP;
    this.op.direction = direction;
  }
  public iniSettingsOp(): void {
    this.op.settingsOpManager.setCfg([{
      infoMsg: "Insert point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.op.addPointFromExt.bind(this.op),
    }, {
      infoMsg: "Insert point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.op.addPointFromExt.bind(this.op),
    }, {
      infoMsg: "Insert center: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.op.addPointFromExt.bind(this.op),
    }]);
  }
  public moveLastPoint(pto: IPoint) {
    if (this.op.numPoints > 0) {
      lineMoveVertex(this.op.auxLine, pto.x, pto.y, pto.z);
      if (this.op.numPoints === 2) {
        this.op.center = this.op.arcGetCenter2pR(
          pto,
          this.op.firstPoint,
          this.op.thirdPoint,
          this.op.currPlane.rotation
        );
        this.op.centerAux.position.set(
          this.op.center.x,
          this.op.center.y,
          this.op.center.z
        );
        this.calculateArc();
      }
    }
  }

  private calculateArc(): void {
    let coords: Float32Array | null;
    if (this.op.firstPoint && this.op.center && this.op.thirdPoint) {
      coords = arcBuffer2pC(
        this.op.firstPoint,
        this.op.thirdPoint,
        this.op.center,
        this.op.direction,
        this.op.currPlane.rotation
      );
    } else {
      return;
    }
    if (!coords) coords = new Float32Array(0);
    setPosBuffer(this.op.lineArc, coords);
  }

  public setLastPoint(): void {
    const { x, y, z } = this.op.lastPoint;
    if (this.op.numPoints === 1) {
      const planeManager = this.op.graphicProcessor.getPlaneManager();
      planeManager.activePlane.locked = true;
      lineMoveVertex(this.op.auxLine, x, y, z, 0);
      lineMoveVertex(this.op.auxLine, x, y, z);
      this.op.firstPoint = copyIPoint(this.op.lastPoint);
      this.op.setNextStep();
    } else if (this.op.numPoints === 2) {
      this.op.auxLine.visible = false;
      this.op.thirdPoint = copyIPoint(this.op.lastPoint);
      this.op.centerAux = pointCreate(0, 0, 0);
      this.op.saveToTempScene(this.op.centerAux);
      this.op.setNextStep();
    } else if (this.op.numPoints === 3) {
      this.op.endOperation();
    }
  }

  public save() {
    this.op.radius = this.op.getMinRadius(
      this.op.lastPoint,
      this.op.firstPoint,
      this.op.thirdPoint
    );
    this.op.center = this.op.arcGetCenter2pR(
      this.op.lastPoint,
      this.op.firstPoint,
      this.op.thirdPoint,
      this.op.currPlane.rotation
    );
    this.op.saveAzimutOAngleCenterAndDirection(
      this.op.firstPoint,
      this.op.thirdPoint
    );

    this.op.executeCommand();
  }
}

class ArcFirstLastRadOP implements IArcOperation {
  public op: ArcBaseOP;

  constructor(arcBaseOP: ArcBaseOP, direction: ORIENT) {
    this.op = arcBaseOP;
    this.op.direction = direction;
  }
  public iniSettingsOp(): void {
    this.op.settingsOpManager.setCfg([{
      infoMsg: "Insert point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.op.addPointFromExt.bind(this.op),
    }, {
      infoMsg: "Insert point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.op.addPointFromExt.bind(this.op),
    }, {
      infoMsg: "Insert radius: ",
      stepMode: settingsOpModes.ONEBOX,
      cmdLineListener: (cmd: string) => {
        const r = parseFloat(cmd);
        if (r) {
          this.op.radius = r;
          this.op.endOperation();
        }
      },
    }]);
  }
  public moveLastPoint(pto: IPoint) {
    if (this.op.numPoints > 0) {
      lineMoveVertex(this.op.auxLine, pto.x, pto.y, pto.z);
      if (this.op.numPoints === 2) {
        this.op.radius = vectorDist3D(pto, this.op.thirdPoint);
        const minRadius =
          vectorDist3D(this.op.firstPoint, this.op.thirdPoint) * 0.5;
        if (this.op.radius < minRadius) {
          this.op.radius = minRadius;
          this.op.center = getMiddlePoint(
            this.op.firstPoint,
            this.op.thirdPoint
          );
        } else {
          this.op.center = (circleGetCenter2pR(
            this.op.firstPoint,
            this.op.thirdPoint,
            this.op.radius,
            this.op.currPlane.rotation
          ) as IPoint[])[0];
        }
        this.op.centerAux.position.set(
          this.op.center.x,
          this.op.center.y,
          this.op.center.z
        );
        this.calculateArc();
      }
    }
  }

  private calculateArc(): void {
    let coords: Float32Array | null;
    if (
      this.op.firstPoint &&
      this.op.center &&
      this.op.radius &&
      this.op.thirdPoint
    ) {
      coords = arcBufferCR2p(
        this.op.center,
        this.op.radius,
        this.op.firstPoint,
        this.op.thirdPoint,
        this.op.direction,
        this.op.currPlane.rotation
      );
    } else {
      return;
    }
    if (!coords) coords = new Float32Array(0);
    setPosBuffer(this.op.lineArc, coords);
  }

  public setLastPoint(): void {
    const { x, y, z } = this.op.lastPoint;
    if (this.op.numPoints === 1) {
      const planeManager = this.op.graphicProcessor.getPlaneManager();
      planeManager.activePlane.locked = true;
      lineMoveVertex(this.op.auxLine, x, y, z, 0);
      lineMoveVertex(this.op.auxLine, x, y, z);
      this.op.firstPoint = copyIPoint(this.op.lastPoint);
      this.op.setNextStep();
    } else if (this.op.numPoints === 2) {
      this.op.auxLine.visible = false;
      this.op.thirdPoint = copyIPoint(this.op.lastPoint);
      this.op.centerAux = pointCreate(0, 0, 0);
      this.op.saveToTempScene(this.op.centerAux);
      this.op.setNextStep();
    } else if (this.op.numPoints === 3) {
      this.op.radius = vectorDist3D(this.op.lastPoint, this.op.thirdPoint);
      this.op.endOperation();
    }
  }

  public save() {
    const minRadius = vectorDist3D(this.op.firstPoint, this.op.thirdPoint) * 0.5;
    if (this.op.radius < minRadius) {
      this.op.radius = minRadius;
      this.op.center = getMiddlePoint(this.op.firstPoint, this.op.thirdPoint);
    } else {
      this.op.center = (circleGetCenter2pR(
        this.op.firstPoint,
        this.op.thirdPoint,
        this.op.radius,
        this.op.currPlane.rotation
      ) as IPoint[])[0];
    }
    this.op.saveAzimutOAngleCenterAndDirection(
      this.op.firstPoint,
      this.op.thirdPoint
    );

    this.op.executeCommand();
  }
}

class ArcFirstLastAngOP implements IArcOperation {
  public op: ArcBaseOP;

  constructor(arcBaseOP: ArcBaseOP, direction: ORIENT) {
    this.op = arcBaseOP;
    this.op.direction = direction;
  }
  public iniSettingsOp(): void {
    this.op.settingsOpManager.setCfg([{
      infoMsg: "Insert point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.op.addPointFromExt.bind(this.op),
    }, {
      infoMsg: "Insert point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.op.addPointFromExt.bind(this.op),
    }, {
      infoMsg: "Insert angle: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.op.addPointFromExt.bind(this.op),
    }]);
  }
  public moveLastPoint(pto: IPoint) {
    if (this.op.numPoints > 0) {
      lineMoveVertex(this.op.auxLine, pto.x, pto.y, pto.z);
      if (this.op.numPoints === 2) {
        this.op.center = copyIPoint(pto);
        this.op.centerAux.position.set(
          this.op.center.x,
          this.op.center.y,
          this.op.center.z
        );
        this.calculateArc();
      }
    }
  }

  private calculateArc(): void {
    let coords: Float32Array | null;
    if (this.op.firstPoint && this.op.center && this.op.thirdPoint) {
      coords = arcBuffer2pC(
        this.op.firstPoint,
        this.op.thirdPoint,
        this.op.center,
        this.op.direction,
        this.op.currPlane.rotation
      );
    } else {
      return;
    }
    if (!coords) coords = new Float32Array(0);
    setPosBuffer(this.op.lineArc, coords);
  }

  public setLastPoint(): void {
    const { x, y, z } = this.op.lastPoint;
    if (this.op.numPoints === 1) {
      const planeManager = this.op.graphicProcessor.getPlaneManager();
      planeManager.activePlane.locked = true;
      lineMoveVertex(this.op.auxLine, x, y, z, 0);
      lineMoveVertex(this.op.auxLine, x, y, z);
      this.op.firstPoint = copyIPoint(this.op.lastPoint);
      this.op.setNextStep();
    } else if (this.op.numPoints === 2) {
      this.op.auxLine.visible = false;
      this.op.thirdPoint = copyIPoint(this.op.lastPoint);
      this.op.centerAux = pointCreate(0, 0, 0);
      this.op.saveToTempScene(this.op.centerAux);
      this.op.setNextStep();
    } else if (this.op.numPoints === 3) {
      this.op.endOperation();
    }
  }

  public save() {
    this.op.radius = vectorDist3D(this.op.lastPoint, this.op.firstPoint);
    this.op.center = copyIPoint(this.op.lastPoint);
    this.op.saveAzimutOAngleCenterAndDirection(
      this.op.firstPoint,
      this.op.thirdPoint
    );

    this.op.executeCommand();
  }
}
