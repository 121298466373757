import { pointCreate } from "lib/geometries/point";
import { lineAngle2p, normalizeAngle } from "lib/math/angles";
import {
  getEulerFromNormalAndPoint,
  getEulerFromXYvectors,
} from "lib/math/plane";
import {
  getPolarPoint,
  normalizeIpoint,
  substractIpoint,
} from "lib/math/point";
import { IPoint } from "lib/math/types";
import { PlaneDefinition } from "lib/coordinates/plane";
import { Cad3dOp } from "./base";
import { cadOpType } from "./factory";
import { settingsOpModes } from "./step-operations";

/** Working plane creation operations */

export class CreateWorkingPlaneFrom3PtosOP extends Cad3dOp {

  public opType = cadOpType.WRKPLANE3P;
  public auxPlane: PlaneDefinition;
  public pto0: IPoint;
  public pto1: IPoint;

  public async start() {
    this.iniSettingsOp();
    this.initializeSnap();
    this.initializeWorkingPlane();
    this.registerInputs();
    this.registerUpdaters();
    this.registerCancel();
  }
  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Insert first point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
      endStepCallback: this.iniPlaneHelper.bind(this),
    },
    {
      infoMsg: "Insert second point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
    },
    {
      infoMsg: "Insert last point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
      endStepCallback: this.endOperation.bind(this),
    }]);
  }
  public iniPlaneHelper() {
    this.auxPlane = new PlaneDefinition(this.lastPoint);
    this.auxPlane.setAsUserPlane();
    this.auxPlane.addToScene(this.getTempScene());
  }
  public setLastPoint(): void {
    const { x, y, z } = this.lastPoint;
    const point = pointCreate(x, y, z);
    this.saveToTempScene(point);

    if (this.pto0 === undefined) {
      this.pto0 = { x, y, z };
    } else {
      if (this.pto1 === undefined) {
        this.pto1 = { x, y, z };
      }
    }

    if (this.pto0 && this.pto1) {
      const origin = this.pto0;
      const ptoX = this.pto1;
      const normal = normalizeIpoint(substractIpoint(ptoX, origin));
      const euler = getEulerFromNormalAndPoint(normal, origin);

      const planeManager = this.graphicProcessor.getPlaneManager();
      planeManager.activePlane.position = origin;
      planeManager.activePlane.rotation = euler;
      planeManager.activePlane.locked = true;
    }
    this.setNextStep();
  }
  public moveLastPoint(pto: IPoint) {
    if (this.pto0 && this.pto1 === undefined) {
      const angleX = normalizeAngle(lineAngle2p(this.lastPoint, pto));
      const angleY = normalizeAngle(angleX + Math.PI * 0.5);
      const ptoY = getPolarPoint(this.lastPoint, angleY, 10);
      const vx = normalizeIpoint(substractIpoint(pto, this.lastPoint));
      const vy = normalizeIpoint(substractIpoint(ptoY, this.lastPoint));
      const euler = getEulerFromXYvectors(vx, vy);
      this.auxPlane.rotation = { x: euler.x, y: euler.y, z: euler.z };
      this.auxPlane.updatePlaneHelpersGeom();
    } else if (this.pto0 && this.pto1) {
      const origin = this.pto0;
      const ptoX = this.pto1;
      const ptoY = pto;
      const vx = normalizeIpoint(substractIpoint(ptoX, origin));
      const vy = normalizeIpoint(substractIpoint(ptoY, origin));
      const euler = getEulerFromXYvectors(vx, vy);
      this.auxPlane.rotation = { x: euler.x, y: euler.y, z: euler.z };
      this.auxPlane.updatePlaneHelpersGeom();
    }
  }
  public endOperation(): void {
    if (!this.finished) {
      this.save();
      super.endOperation();
    }
  }
  public save() {
    if (this.graphicProcessor) {
      const plnMng = this.graphicProcessor.getPlaneManager();
      plnMng.addUserPlane(this.auxPlane.position, this.auxPlane.rotation);
    }
  }
}

export class CreateWorkingPlaneFromPlaneVectorOP extends Cad3dOp {

  public opType = cadOpType.WRKPLANEVECTOR;
  public auxPlane: PlaneDefinition;
  public pto0: IPoint;
  public normal: IPoint;

  public async start() {
    this.iniSettingsOp();
    this.initializeSnap();
    this.initializeWorkingPlane();
    this.registerInputs();
    this.registerUpdaters();
    this.registerCancel();
  }
  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Select plane origin: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
    },
    {
      infoMsg: "Select vector: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
      endStepCallback: this.endOperation.bind(this),
    }]);
  }
  public iniPlaneHelper() {
    this.auxPlane = new PlaneDefinition(this.lastPoint);
    this.auxPlane.setAsUserPlane();
    this.auxPlane.addToScene(this.getTempScene());
  }
  public setLastPoint(): void {
    const { x, y, z } = this.lastPoint;
    const point = pointCreate(x, y, z);
    this.saveToTempScene(point);

    if (this.pto0 === undefined) {
      this.pto0 = { x, y, z };
      const planeManager = this.graphicProcessor.getPlaneManager();
      planeManager.activePlane.position = this.pto0;
      this.normal = planeManager.activePlane.normal;
      planeManager.activePlane.locked = true;

      this.iniPlaneHelper();
      const euler = getEulerFromNormalAndPoint(this.normal, this.pto0);
      this.auxPlane.rotation = { x: euler.x, y: euler.y, z: euler.z };
      this.auxPlane.updatePlaneHelpersGeom();
    }
    this.setNextStep();
  }
  public moveLastPoint(pto: IPoint) {
    if (this.pto0 && this.normal) {
      const euler = getEulerFromNormalAndPoint(this.normal, this.pto0);
      this.auxPlane.rotation = { x: euler.x, y: euler.y, z: euler.z };

      const planeManager = this.graphicProcessor.getPlaneManager();
      const pt = planeManager.activePlane.getRelativePoint(pto);
      const angleZ = normalizeAngle(lineAngle2p({ x: 0, y: 0, z: 0 }, pt));
      this.auxPlane.rotation.z = angleZ;

      this.auxPlane.updatePlaneHelpersGeom();
    }
  }
  public endOperation(): void {
    if (!this.finished) {
      this.save();
      super.endOperation();
    }
  }
  public save() {
    if (this.graphicProcessor) {
      const plnMng = this.graphicProcessor.getPlaneManager();
      plnMng.addUserPlane(this.auxPlane.position, this.auxPlane.rotation);
    }
  }
}
