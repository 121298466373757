import { growthWidth } from "lib/models-struc/types/struc-base";
import { Beam } from "modules/struc/models/ecore/structural";
import { angleBetweenVectors } from "lib/math/angles";
import { dataModelPersistence } from "lib/input-output/database/loader";
import { objDataType } from "lib/models/types";
import { Storey } from "lib/models-struc/struc-storey";
import { beamParam } from "lib/models-struc/types/beam";
import { ILogger } from 'shared/utils/logger/logger';
import { EcoreImporter } from './structuralElement';

export class EcoreBeamImporter extends EcoreImporter {

  constructor(
    protected ecoreElem: Beam,
    protected logger: ILogger) {
    super();
  }

  getObjData(storey: Storey, layerId: string, cssIdsRefs: string[], materialRefs: string[]): dataModelPersistence {
    const materialIndex = this.ecoreElem.material.$ref.split(".").pop()!;
    const beamCssRef = this.ecoreElem.representation.base.base.$ref.split(".").pop()!;

    const { basePoint, rotation } = this.getPlacement(this.ecoreElem.placement);

    const param: beamParam = {
      storeyId: storey.id,
      name: this.ecoreElem.name,
      lnkObjIds: [],
      materialType: materialRefs[parseInt(materialIndex)],

      orientation: this.getOrientation(),
      points: this.getPoints(),
      crossSectionId: cssIdsRefs[parseInt(beamCssRef)],
      growthType: growthWidth.CENTER,

      basePoint: { ...basePoint, z: basePoint.z + storey.level },
      rotation,
      offset: { x: 0, y: 0, z: 0 },
      scale: { x: 1, y: 1, z: 1 },
    }

    return this.getDataModel(objDataType.BEAM, layerId, param);
  }

  private getOrientation() {
    const vectY = this.ecoreElem.representation.orientation;
    if (vectY) {
      const orientationY = { x: vectY.x ?? 0, y: vectY.y ?? 0, z: vectY.z ?? 0 };
      return angleBetweenVectors(orientationY, { x: 0, y: 0, z: 1 });
    }
    this.logger.warn("[BEAM] Orientation not defined in ExtrudedRepresentation Ecore element")
    return 0;
  }

  private getPoints() {
    const vect = this.ecoreElem.representation.direction;
    const pto1 = { x: vect.x ?? 0, y: vect.y ?? 0, z: vect.z ?? 0 };
    return [{ x: 0, y: 0, z: 0 }, pto1];
  }
}

