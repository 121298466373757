import { blockItem, BlockManager, blockParam, blockRefParam } from "lib/blocks";
import { userAngleToRad } from "lib/general-settings";
import { circleParam } from "lib/geometries/circle";
import { ellipseParam } from "lib/geometries/ellipse";
import { GraphicProcessor } from 'lib/graphic-processor';
import { ILineMaterial, getDefaultLineMaterial, IPointMaterial, getDefaultPointMaterial } from "lib/materials";
import { degToRad, getAngleBetweenMathDirections, lineAngle2p, mecDirectionMathDirection, normalizeAngle } from "lib/math/angles";
import { arcParam, getArcFromBulge } from "lib/math/arc";
import { ACItoRGB } from "lib/math/color";
import { vectorDist2D } from "lib/math/distance";
import { IArcLineParam, IPolylineParam } from "lib/math/line";
import { IColor, IPoint } from "lib/math/types";
import { ArcData } from "lib/models/primitives/arc";
import { BlockData } from "lib/models/block";
import { CircleData } from "lib/models/primitives/circle";
import { EllipseData } from "lib/models/primitives/ellipse";
import { IObjData } from "lib/models/objdata";
import { LineData } from "lib/models/primitives/line";
import { PointData } from "lib/models/primitives/point";
import { textParam, TextData } from "lib/models/text";
import { vector3Equals } from 'lib/math/epsilon';
import { LayerData } from "lib/layers/layer-data";
import { LayerManager } from "lib/layers/layer-manager";
import { dataModelPersistence } from "../database/loader";

enum layerNameTypes { LINES = 0, TEXTS, DIMENSIONS, OTHERS }
const layerNameTypeNames: string[] = [];
layerNameTypeNames[layerNameTypes.LINES] = "Lines";
layerNameTypeNames[layerNameTypes.TEXTS] = "Texts";
layerNameTypeNames[layerNameTypes.DIMENSIONS] = "Dimensions";
layerNameTypeNames[layerNameTypes.OTHERS] = "Others";

export class DxfImporter {

  private dxfLoader: DxfLoaderData;
  private graphicProcessor: GraphicProcessor;

  constructor(graphicProcessor: GraphicProcessor) {
    this.graphicProcessor = graphicProcessor;
  }

  ImportDxf(auxRootLayer: string, dxfData: any) {
    this.dxfLoader = new DxfLoaderData(this.graphicProcessor.getLayerManager());
    const objDatas = this.dxfLoader.LoadDxf(auxRootLayer, dxfData);
    if (objDatas) {
      for (const data of objDatas) {
        data.createGraphicObj();
        this.graphicProcessor.addToLayer(data, data.layerId);
      }
      const modelManager = this.graphicProcessor.getDataModelManager();
      modelManager.dispatchAddedObjs(objDatas);
      const lyrManager = this.graphicProcessor.getLayerManager();
      lyrManager.layerObserver.dispatchLoadLayers();
    }
  }
}

export class DxfLoaderData {

  private DXFDEFAULTZVALUE: number = 0;
  private overWriteZ: boolean = false;
  private layerManager: LayerManager;

  constructor(layerManager: LayerManager) {
    this.layerManager = layerManager;
  }

  LoadDxf(auxRootLayer: string, dxfData: any, z?: number): IObjData[] {
    if (z !== undefined) this.DXFDEFAULTZVALUE = z;
    this.loadLayers(auxRootLayer, dxfData);
    this.loadBlocks(dxfData);
    return this.loadEntities(dxfData);
  }
  // 18c62d0d-8702-485c-99fc-8bc188bd1f65
  getDxfObjDatas(dxfData: any, auxRootLayer: string, z?: number, overWriteZ?: boolean): dataModelPersistence[] {
    if (overWriteZ !== undefined) this.overWriteZ = overWriteZ;
    if (z !== undefined) this.DXFDEFAULTZVALUE = z;
    // Create basic layers by type
    const { auxLayer, childs } = this.createLayersByType(auxRootLayer);
    const data: dataModelPersistence[] = [];

    for (const entity of dxfData.entities) {
      const entityLayer = this.getLayerFromObjType(entity, childs);
      const objLayer = entityLayer ? entityLayer : auxLayer;
      const obj = this.getEntity(entity, data, objLayer.id);
      if (obj) {
        objLayer.objDatas.push(obj);
        data.push(obj.exportToJSON());
      }
    }
    // Remove empty basic layers by type
    this.deleteEmptyLayersByType(childs);
    return data;
  }

  private handleZCoord(z: number | undefined) {
    if (z === undefined) {
      return this.DXFDEFAULTZVALUE;
    } else {
      if (this.overWriteZ) return this.DXFDEFAULTZVALUE;
      return z;
    }
  }

  private loadLayers(auxRootLayer: string, dxfData: any) {
    const layers = dxfData?.tables?.layer?.layers;
    if (layers) {
      const currentLayerId = this.layerManager.currentLayer.id;
      // Get or create layer
      let auxLayer = this.layerManager.getLayerDataFromName(auxRootLayer);
      if (auxLayer === undefined) {
        auxLayer = this.layerManager.addLayer(auxRootLayer);
      }
      // Add layers
      this.layerManager.setCurrentLayer(auxLayer);
      for (const layer in layers) {
        this.layerManager.addLayer(layer);
      }
      // Restore current layer
      this.layerManager.setCurrentLayerById(currentLayerId);
    }
  }

  private createLayersByType(auxRootLayer: string): { auxLayer: LayerData, childs: Map<string, LayerData> } {
    const currentLayerId = this.layerManager.currentLayer.id;
    const childLayers: Map<string, LayerData> = new Map();

    // Get or Create Layer
    let auxLayer = this.layerManager.getLayerDataFromName(auxRootLayer);
    if (auxLayer === undefined) {
      auxLayer = this.layerManager.addLayer(auxRootLayer);
      auxLayer.setBulkData(true);
    }
    // Adds children layers
    this.layerManager.setCurrentLayer(auxLayer);
    for (const layerName of layerNameTypeNames) {
      const lyr = this.layerManager.addLayer(layerName);
      lyr.setBulkData(true);
      childLayers.set(layerName, lyr);
    }
    // restore current Layer
    this.layerManager.setCurrentLayerById(currentLayerId);
    return { auxLayer: auxLayer, childs: childLayers };
  }

  private deleteEmptyLayersByType(layers: Map<string, LayerData>) {
    if (layers) {
      for (const layer of layers.values()) {
        if (!layer.objDatas.length) {
          this.layerManager.deleteLayerById(layer.id);
        }
      }
    }
  }

  private getLayerFromObjType(entity: any, layers: Map<string, LayerData>) {
    switch (entity.type) {
      case "LINE":
      case "POLYLINE":
      case "LWPOLYLINE":
        return layers.get(layerNameTypeNames[layerNameTypes.LINES]);
      case "TEXT":
      case "MTEXT":
        return layers.get(layerNameTypeNames[layerNameTypes.TEXTS]);
      case "DIMENSION":
        return layers.get(layerNameTypeNames[layerNameTypes.DIMENSIONS]);
      case "POINT":
      case "CIRCLE":
      case "ARC":
      case "ELLIPSE":
      case "INSERT":
        return layers.get(layerNameTypeNames[layerNameTypes.OTHERS]);
    }
  }

  private loadEntities(dxfData: any): IObjData[] {
    const entities = dxfData?.entities;
    const objDatas: IObjData[] = [];
    if (entities) {
      for (const entity of entities) {
        const data = this.getEntity(entity, dxfData);
        if (data) {
          objDatas.push(data);
        }
      }
    }
    return objDatas;
  }

  // -------------------------------------------------------------------

  private loadBlocks(dxfData: any) {
    //const blocks = dxfData?.blocks;
    const blocks = undefined; // Force to load nothing because not finished and tested
    if (blocks) {
      for (const [blockName, block] of Object.entries(blocks)) {
        if (blockName[0] !== "*") { // User defined blocks
          const blockEntities: blockItem[] = [];
          const entities = (block as any).entities;
          if (entities) {
            for (const entity of entities) {
              const obj = this.getEntity(entity, dxfData);
              if (obj) {
                const item = { itemType: obj.type, itemDef: obj.definition, material: obj.material };
                blockEntities.push(item);
              }
            }
          }
          const position = (block as any).position;
          const point = { x: position.x, y: position.y, z: this.handleZCoord(position.z) };
          const blockDef: blockParam = { id: 0, name: blockName, basePoint: point, blockItems: blockEntities };
          BlockManager.createBlock(blockDef);
        }
      }
    }
  }

  // -------------------------------------------------------------------

  private getEntity(entity: any, data: any, layerId?: string | null): IObjData | undefined {
    switch (entity.type) {
      case "POINT":
        return this.getPoint(entity, data, layerId);
      case "LINE":
      case "POLYLINE":
      case "LWPOLYLINE":
        return this.getPolyline(entity, data, layerId);
      case "TEXT":
        return this.getText(entity, data, layerId);
      case "MTEXT":
        return this.getMText(entity, data, layerId);
      case "CIRCLE":
        return this.getCircle(entity, data, layerId);
      case "ARC":
        return this.getArc(entity, data, layerId);
      case "ELLIPSE":
        return this.getEllipse(entity, data, layerId);
      case "DIMENSION":
        return this.getDimension(entity, data, layerId);
      case "INSERT":
        return this.getBlockRef(entity, data, layerId);
      default:
        console.warn("[DXF_IMPORT] Not implemented entity type: '" + entity.type + "'");
        break;
    }
  }

  private getPoint(entity: any, data: any, layerId?: string | null): PointData | undefined {
    if (entity) {
      const layerData = layerId ?
        this.layerManager.getLayerDataFromId(layerId)! :
        this.layerManager.getLayerDataFromName(entity.layer)!;

      const mat = this.getPointMaterial(entity, data);
      const pointDef = { x: entity.position.x, y: entity.position.y, z: this.handleZCoord(entity.position.z) };
      const objData = new PointData(pointDef, mat);
      objData.layerObj = layerData;
      return objData;
    }
  }

  private getPolyline(entity: any, data: any, layerId?: string | null): LineData | undefined {
    if (entity) {
      const layerData = layerId ?
        this.layerManager.getLayerDataFromId(layerId)! :
        this.layerManager.getLayerDataFromName(entity.layer)!;

      const mat = this.getLineMaterial(entity, data);
      const points = entity.vertices.map((e: IPoint) => ({ x: e.x, y: e.y, z: this.handleZCoord(e.z) }));
      const arcs = this.getPolylineArcs(entity);
      if (entity.shape && vector3Equals(points[0], points[points.length - 1])) {
        points.pop();
      }
      const lineDef: IPolylineParam = { isClosed: entity.shape, points, arcs };
      const objData = new LineData(lineDef, mat);
      objData.layerObj = layerData;
      return objData;
    }
  }

  private getText(entity: any, data: any, layerId?: string | null): TextData | undefined {
    if (entity) {
      const layerData = layerId ?
        this.layerManager.getLayerDataFromId(layerId)! :
        this.layerManager.getLayerDataFromName(entity.layer)!;

      const styleOverride = {
        color: this.getEntityColor(entity.layer, data),
        size: entity.textHeight
      };
      const pos = { x: entity.startPoint.x, y: entity.startPoint.y, z: this.handleZCoord(entity.startPoint.z) };
      const textDef: textParam = {
        styleId: "",
        text: entity.text,
        position: pos,
        angleO: userAngleToRad(entity.rotation ?? 0),
        plane: { x: 0, y: 0, z: 0 },
        scale: entity.xScale ?? 1,
        override: styleOverride,
      };
      const objData = new TextData(textDef);
      objData.layerObj = layerData;
      return objData;
    }

  }

  private getMText(entity: any, data: any, layerId?: string | null): TextData | undefined {
    if (entity) {
      const layerData = layerId ?
        this.layerManager.getLayerDataFromId(layerId)! :
        this.layerManager.getLayerDataFromName(entity.layer)!;

      const styleOverride = {
        color: this.getEntityColor(entity.layer, data),
        size: entity.height
      };
      const pos = { x: entity.position.x, y: entity.position.y, z: this.handleZCoord(entity.position.z) };
      const mTextDef: textParam = {
        styleId: "",
        text: entity.text.replaceAll('\\P', '\n'), // Fix new lines characters
        position: pos,
        angleO: 0,
        plane: { x: 0, y: 0, z: 0 },
        scale: 1,
        override: styleOverride,
      };
      const objData = new TextData(mTextDef);
      objData.layerObj = layerData;
      return objData;
    }
  }

  private getCircle(entity: any, data: any, layerId?: string | null): CircleData | undefined {
    if (entity) {
      const layerData = layerId ?
        this.layerManager.getLayerDataFromId(layerId)! :
        this.layerManager.getLayerDataFromName(entity.layer)!;

      const mat = this.getLineMaterial(entity, data);
      const c = { x: entity.center.x, y: entity.center.y, z: this.handleZCoord(entity.center.z) };
      const circleDef: circleParam = {
        center: c,
        radius: entity.radius,
        azimutO: 0,
        plane: { x: 0, y: 0, z: 0 }
      };
      const objData = new CircleData(circleDef, mat);
      objData.layerObj = layerData;
      return objData;
    }
  }

  private getArc(entity: any, data: any, layerId?: string | null): ArcData | undefined {
    if (entity) {
      const layerData = layerId ?
        this.layerManager.getLayerDataFromId(layerId)! :
        this.layerManager.getLayerDataFromName(entity.layer)!;

      const mat = this.getLineMaterial(entity, data);
      const c = { x: entity.center.x, y: entity.center.y, z: this.handleZCoord(entity.center.z) };
      const arcDef: arcParam = {
        center: c,
        radius: entity.radius,
        angleCenter: -1 * getAngleBetweenMathDirections(entity.startAngle, entity.endAngle),
        azimutO: mecDirectionMathDirection(entity.startAngle),
        plane: { x: 0, y: 0, z: 0 }
      };
      const objData = new ArcData(arcDef, mat);
      objData.layerObj = layerData;
      return objData;

    }
  }

  private getEllipse(entity: any, data: any, layerId?: string | null): EllipseData | undefined {
    if (entity) {
      const layerData = layerId ?
        this.layerManager.getLayerDataFromId(layerId)! :
        this.layerManager.getLayerDataFromName(entity.layer)!;

      const mat = this.getLineMaterial(entity, data);
      const c = { x: entity.center.x, y: entity.center.y, z: this.handleZCoord(entity.center.z) };
      const point = { x: entity.majorAxisEndPoint.x, y: entity.majorAxisEndPoint.y, z: this.handleZCoord(entity.majorAxisEndPoint.z) };
      const axisA = vectorDist2D({ x: 0, y: 0, z: 0 }, point);
      const axisB = axisA * entity.axisRatio;
      const angle = normalizeAngle(lineAngle2p({ x: 0, y: 0, z: 0 }, point));
      const ellipseDef: ellipseParam = {
        center: c,
        a: axisA,
        b: axisB,
        azimutO: mecDirectionMathDirection(angle),
        plane: { x: 0, y: 0, z: 0 }
      };
      const objData = new EllipseData(ellipseDef, mat);
      objData.layerObj = layerData;
      return objData;
    }
  }

  private getDimension(entity: any, data: any, layerId?: string | null): undefined {
    if (entity) {
      if (layerId === undefined) {
        const layerData = this.layerManager.getLayerDataFromName(entity.layer)!;
        layerId = layerData.id;
      }
      if (layerId) {
        // const dimensionType = getDimensionType(entity.dimensionType);
        // const dimDef: dimParam = {
        // };
        // retunr new DimensionData(dimDef, layerId, mat);
        return;
      }
    }
  }

  private getBlockRef(entity: any, data: any, layerId?: string | null): BlockData | undefined {
    if (entity) {
      const layerData = layerId ?
        this.layerManager.getLayerDataFromId(layerId)! :
        this.layerManager.getLayerDataFromName(entity.layer)!;

      const blockDefinition = this.getBlockDefByName(entity.name);
      if (blockDefinition) {
        const pos = { x: entity.position.x, y: entity.position.y, z: this.handleZCoord(entity.position.z) };
        const blockRef: blockRefParam = {
          blockId: blockDefinition.id,
          position: pos,
          rotation: { x: 0, y: 0, z: degToRad(entity.rotation ?? 0) },
          scale: { x: 1, y: 1, z: 1 }
        };
        const objData = new BlockData(blockRef);
        objData.layerObj = layerData;
        return objData;
      }
    }
  }

  private getPolylineArcs(entity: any): (IArcLineParam | 0)[] {
    let arcs: (IArcLineParam | 0)[] = [];
    for (let i = 0; i < entity.vertices.length; i++) {
      const vertex = entity.vertices[i];
      if (vertex.bulge && vertex.bulge !== 0) {
        const nextVertex = (entity.shape && entity.vertices[i + 1] === undefined) ? entity.vertices[0] : entity.vertices[i + 1];
        if (nextVertex !== undefined) {
          const arc = getArcFromBulge(vertex, vertex.bulge, nextVertex);
          arc.center.z = this.handleZCoord(arc.center.z);
          arcs.push(arc);
        } else {
          arcs.push(0);
        }
      }
      else {
        arcs.push(0);
      }
    }
    if (entity.shape === false) {
      arcs.pop();
    }
    return arcs;
  }

  private getPointMaterial(entity: any, data: any): IPointMaterial | undefined {
    // Nowadays consider color by layer. In the furure, add posibility of color by entity
    const color = this.getEntityColor(entity.layer, data);
    if (color) {
      const mat = getDefaultPointMaterial();
      mat.color.r = color.r;
      mat.color.g = color.g;
      mat.color.b = color.b;
      return mat;
    }
  }

  private getLineMaterial(entity: any, data: any): ILineMaterial | undefined {
    const color = this.getEntityColor(entity.layer, data);
    if (color) {
      const mat = getDefaultLineMaterial();
      mat.color.r = color.r;
      mat.color.g = color.g;
      mat.color.b = color.b;
      return mat;
    }
  }

  private getEntityColor(layer: string, data: any): IColor | undefined {
    // Nowadays consider color by layer. In the furure, add posibility of color by entity
    // If dont want use colors, uncomment next line to use default color.
    //return defaultColor;
    return this.getColorByLayer(layer, data);
  }

  private getColorByLayer(layer: string, data: any): IColor | undefined {
    const layers = data?.tables?.layer?.layers;
    if (layers) {
      const lay = layers[layer];
      if (lay.colorIndex) {
        const rgb = ACItoRGB(lay.colorIndex);
        if (rgb) {
          return { r: rgb.r, g: rgb.g, b: rgb.b, a: 1 }
        }
      }
    }
  }

  private getDimensionType(dimensionType: number) {
    /*
    0 = Rotated, horizontal, or vertical
    1 = Aligned
    2 = Angular
    3 = Diameter
    4 = Radius
    5 = Angular 3-point
    6 = Ordinate
    32 = Indicates that the block reference (group code 2) is referenced by this dimension only
    64 = Ordinate type. This is a bit value (bit 7) used only with integer value 6. If set, ordinate is X-type; if not set, ordinate is Y-type
    128 = This is a bit value (bit 8) added to the other group 70 values if the dimension text has been positioned at a user-defined location rather than at the default location 
    */
    if (dimensionType > 128) { dimensionType -= 128; }
    if (dimensionType > 64) { dimensionType -= 64; }
    if (dimensionType > 32) { dimensionType -= 32; }
    // TODO: return appropiate dimension type
    // return objDataType.ARCDIM;
    // return objDataType.CIRCULARDIM;
    // return objDataType.ANGULARDIM;
    // return objDataType.ALIGNEDDIM;
    // return objDataType.LINEARDIM;
    switch (dimensionType) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
        break;
    }
  }

  private getBlockDefByName(name: string): blockParam | undefined {
    return BlockManager.getBlockDefByName(name);
  }
}