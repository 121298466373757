import { AlignedDimensionCommand } from "lib/commands/dimension/aligned-dim";
import { getCustomDimStyleProperties } from "lib/dimension/style";
import { createAlignedDimension, updateAlignedDimension, getDimLabel } from "lib/dimension/line-dim-builder";
import { lineAngle2p } from "lib/math/angles";
import { distancePointToLine3D } from "lib/math/distance";
import { vector2Equals, vector3Equals } from "lib/math/epsilon";
import { IPlane, intersectLinePlane } from "lib/math/plane";
import { getMiddlePoint } from "lib/math/point";
import { rotatePointX, rotatePointZ } from "lib/math/rotate";
import { ISegment, IPoint, copyISegment } from "lib/math/types";
import { alignedDimensionParam } from "lib/models/dimension/aligned-dim";
import { cadOpType } from "../factory";
import { settingsOpModes } from "../step-operations";
import { dimDirectionMode, DimensionOP, dimOrientationMode, dimPlane } from "./dimension-base";

export class QuickDimensionOP extends DimensionOP {

  public opType = cadOpType.QUICKDIM;
  private dimLine: ISegment;
  private dimOrientation: dimOrientationMode;

  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Insert first point.",
      stepMode: settingsOpModes.WAITMODE,
      stepFun: this.dispathSaveMouseCoordinates.bind(this),
    }, {
      infoMsg: "Insert second point.",
      stepMode: settingsOpModes.WAITMODE,
      stepFun: this.dispathSaveMouseCoordinates.bind(this),
    }]);
  }

  public async start() {
    await super.start();
    this.registerInputs();
    this.registerUpdaters();
    this.initializeSnap();
    this.registerCancel();
  }

  public setLastPoint(): void {
    if (this.numPoints === 1) {
      this.dimPlane = this.getDimPlane();
      this.dimLine = { p1: this.lastPoint, p2: this.lastPoint };
      this.dimOrientation = dimOrientationMode.POSITIVE;
      this.dimStyle.override({ blockDistBaseLine: false, minDistBaseLine1: 0, minDistBaseLine2: 0 });
      const dim = createAlignedDimension(this.dimLine, this.dimStyle, this.dimPlane, this.dimOrientation, this.txtStyle);
      this.saveDimtoTemp(dim);
      this.setNextStep();

    } else if (this.numPoints === 2) {
      this.dimLine.p2 = this.lastPoint;
      if (vector3Equals(this.dimLine.p1, this.dimLine.p2)) {
        this.numPoints--;
        return;
      }
      this.save();
      this.endOperation();
    }
  }
  public moveLastPoint(point: IPoint) {
    if (this.numPoints > 0) {
      if (this.numPoints === 1) {
        // if (!this.dimStyle.blockDistBaseLine) this.dimStyle.distBaseLine = 1;
        // this.dimStyle.distBaseLine = 5;
        // this.dimStyle.offset = 2.5;
        // this.dimStyle.ext = 2.5;
        this.dimPlane = this.getDimPlane();
        this.dimLine.p2 = point;

      } else if (this.numPoints === 2) {
        // Calculo del plano de acotación
        this.dimPlane = this.getDimPlane();

        // Cálculo del punto del raycast en el plano de la acotación
        const v1 = this.dimLine.p1;
        const v2 = this.dimLine.p2;
        let currentPlane: IPlane;
        if (vector2Equals(v1, v2)) {
          // Línea completamente vértical, nos quedamos en el plano XY
          const minZpoint = v1.z < v2.z ? v1 : v2;
          currentPlane = { p1: minZpoint, p2: { x: minZpoint.x + 50, y: minZpoint.y + 50, z: minZpoint.z }, p3: { x: minZpoint.x + 40, y: minZpoint.y + 50, z: minZpoint.z } };
        } else {
          if (this.dimPlane === dimPlane.XY) {
            const XYangle = lineAngle2p(v1, v2);
            const x1: number = v1.x + 50 * Math.cos(XYangle + Math.PI * 0.5);
            const y1: number = v1.y + 50 * Math.sin(XYangle + Math.PI * 0.5);
            currentPlane = { p1: v1, p2: v2, p3: { x: x1, y: y1, z: v1.z } };
          } else {
            currentPlane = { p1: v1, p2: v2, p3: { x: v2.x, y: v2.y, z: v2.z - 50 } };
          }
        }

        const pov: IPoint = this.graphicProcessor.getCursorCamera().position;
        let secondPointProj = intersectLinePlane(pov, point, currentPlane) as IPoint;

        if (vector2Equals(v1, v2)) this.dimPlane = dimPlane.XZ;
        this.dimStyle.distBaseLine = distancePointToLine3D(this.dimLine.p1, this.dimLine.p2, secondPointProj, false);

        const projSegment = copyISegment(this.dimLine);
        // GIRAMOS GEOMETRIA AL PLANO XY (SOLO PLANOS XZ, YZ, DEF)
        if (this.dimPlane !== dimPlane.XY) {
          const centerPoint = getMiddlePoint(projSegment.p1, projSegment.p2);
          let XYangle;
          if (!vector2Equals(projSegment.p1, projSegment.p2)) {
            XYangle = lineAngle2p(projSegment.p1, projSegment.p2);
            projSegment.p1 = rotatePointZ(projSegment.p1, -XYangle, centerPoint);
            projSegment.p1 = rotatePointX(projSegment.p1, -(Math.PI * 0.5), centerPoint);
            projSegment.p2 = rotatePointZ(projSegment.p2, -XYangle, centerPoint);
            projSegment.p2 = rotatePointX(projSegment.p2, -(Math.PI * 0.5), centerPoint);
            secondPointProj = rotatePointZ(secondPointProj, -XYangle, centerPoint);
            secondPointProj = rotatePointX(secondPointProj, -(Math.PI * 0.5), centerPoint);
          }
        }
        this.dimOrientation = this.getDimOrientation(dimDirectionMode.DEF, secondPointProj, projSegment);

        console.log("Plano " + dimPlane[this.dimPlane] + " "
          + ", Orientacion: " + dimOrientationMode[this.dimOrientation]
          + ", distancia: " + this.dimStyle.distBaseLine
          + ", anguloXY: " + lineAngle2p(v1, v2) * 180 / Math.PI);
      }

      if (vector3Equals(this.dimLine.p1, this.dimLine.p2)) {
        // this.selectionTool.setWarningMessage("La distancia a acotar tiene que ser mayor de 0");
        this.dimensionGroup.visible = false;
      } else {
        // this.selectionTool.hideWarningMessage();
        this.dimensionGroup.visible = true;
        updateAlignedDimension(this.dimensionGroup, this.dimLine, this.dimStyle, this.dimPlane, this.dimOrientation, this.txtStyle);
        const dimLabel = getDimLabel(this.dimensionGroup);
        const rot = dimLabel.rotation.toArray();
        // TEXT.modifyText(dimLabel, { text: USRDT.getObjInfo<TEXT.ITextInfo>(dimLabel).text + "*" }, this.txtStyle, true);
        dimLabel.rotation.fromArray(rot);
      }
    }
  }

  public save() {
    const dimInfo: alignedDimensionParam = {
      styleId: this.dimStyle.styleId,
      dimPlane: this.dimPlane,
      dimOrientation: this.dimOrientation,
      objRef: [{ point: this.dimLine.p1 }, { point: this.dimLine.p2 }],
      customStyleProp: getCustomDimStyleProperties(this.dimStyle, this.defDimStyle),
    };
    const command = new AlignedDimensionCommand(dimInfo, this.getCurrentSceneId(), this.graphicProcessor);
    if (command) this.graphicProcessor.storeAndExecute(command);
  }
}