import { structuralType } from "modules/struc/models/ecore/structural";
import { structuralUriModel } from "modules/struc/models/ecore/uri";
import { ILogger } from "shared/utils/logger/logger";

import { EcoreBeamImporter } from "./structural/beam";
import { EcoreColumnImporter } from "./structural/column";
import { EcoreFlatSlabImporter } from "./structural/flat-slab";
import { EcoreWaffleSlabImporter } from "./structural/waffle-slab";
import { EcoreWallImporter } from "./structural/wall";

export function getImporterFromTypeString(ecoreElement: structuralType, logger: ILogger) {
  switch (ecoreElement.eClass) {
    case `${structuralUriModel}Column` as const:
      return new EcoreColumnImporter(ecoreElement, logger);

    case `${structuralUriModel}Wall` as const:
      return new EcoreWallImporter(ecoreElement, logger);

    case `${structuralUriModel}Beam` as const:
      return new EcoreBeamImporter(ecoreElement, logger);

    case `${structuralUriModel}FlatSlab` as const:
      return new EcoreFlatSlabImporter(ecoreElement, logger);

    case `${structuralUriModel}WaffleSlab` as const:
      return new EcoreWaffleSlabImporter(ecoreElement, logger);

    default:
      console.assert(false, "[Ecore Importer] EcoreElement not implemented");
      break;
  }
}

