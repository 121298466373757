import { lineAddVertex } from "../../geometries/line";
import { copyIPoint } from "../../math/point";
import { IPoint } from "../../math/types";
import { lineMoveVertex } from "../../geometries/line";
import { cadOpType } from "../factory";
import { settingsOpModes } from "../step-operations";
import { RotateCommand } from "../../commands/transform/rotate";
import { lineAngle2p, normalizeAngle } from "../../math/angles";
import { updateObjBboxBSphere } from "../../geometries";
import { rotateObj } from "../../geometries/rotate";
import { TransformEdition } from "./transform-edition";
import { radAngleToUser, userAngleToRad } from "lib/general-settings";

export class RotateOP extends TransformEdition {

  public opType = cadOpType.ROTATE;
  public rotationAngle: number = 0;
  public withCopy: boolean = false;

  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([
      {
        infoMsg: "Select objects.",
        stepMode: settingsOpModes.SELECTOBJS,
        multiSelect: true,
        enableSelectMarks: true,
        filterFun: () => true,
        endStepCallback: async () => {
          this.unRegisterRaycast();
          this.setAuxObjs();
          this.objDataAux.forEach((o) => this.saveToTempScene(o.graphicObj));
          this.registerInputs();
          this.registerUpdaters();
          this.initializeSnap();
        },
      },
      {
        infoMsg: "Specify the base point:",
        stepMode: settingsOpModes.DEFAULTXYZ,
        cmdLineListener: this.addPointFromExt.bind(this),
        endStepCallback: () => {
          this.basePoint = copyIPoint(this.lastPoint);
          const { x, y, z } = this.basePoint;
          lineAddVertex(this.auxLine, x, y, z);
          lineAddVertex(this.auxLine, x, y, z);
        },
      },
      {
        infoMsg: `Specify the rotation angle or [Enter "c" to set with copy]:`,
        stepMode: settingsOpModes.ONEBOX,
        currValue: () => radAngleToUser(this.rotationAngle).toFixed(4),
        cmdLineListener: (cmd: string) => {
          if (cmd.toLowerCase() === "c") {
            this.withCopy = true;
          } else {
            const h = userAngleToRad(parseFloat(cmd));
            if (!isNaN(h)) {
              this.rotationAngle = h;
              this.save();
              this.endOperation();
            }
          }
        },
        endStepCallback: () => {
          this.save();
          this.endOperation();
        },
      },
    ]);
  }

  public moveLastPoint(pto: IPoint) {
    if (this.basePoint) {
      lineMoveVertex(this.auxLine, pto.x, pto.y, pto.z);
      for (const o of this.objDataAux) {
        rotateObj(o.graphicObj, 0, 0, -this.rotationAngle, this.basePoint);
      }
      this.rotationAngle = lineAngle2p(this.basePoint, pto);
      for (const o of this.objDataAux) {
        rotateObj(o.graphicObj, 0, 0, this.rotationAngle, this.basePoint);
        updateObjBboxBSphere(o.graphicObj);
      }
    }
  }

  public save() {
    if (this.graphicProcessor && this.basePoint && this.rotationAngle) {
      const command = new RotateCommand(
        this.basePoint,
        0,
        0,
        normalizeAngle(this.rotationAngle),
        this.objDataOrigin,
        this.withCopy,
        this.graphicProcessor
      );
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
}