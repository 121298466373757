import PropertiesInput from 'modules/cad/components/properties/component-input';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

interface cellProps {
  initialValue: any;
  isDisabled: boolean;
  rowIndex: number;
  colIndx: number;
  updateData: (rowIndex: number, columnId: number, value: any) => void;
}

const TCell = (props: cellProps) => {

  const { handleSubmit, register, reset } = useForm()
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(props.initialValue)

  const onChange = (e: any) => {
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    //updateMyData(index, id, value)
  }

  const update = (d: any) => {
    props.updateData(props.rowIndex, props.colIndx, d.val)
  }
  useEffect(() => { reset(); }, [reset]);
  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(props.initialValue)
  }, [props.initialValue])

  return (
    <form onSubmit={handleSubmit(update)}>
      <PropertiesInput
        className={'rounded-none border-0 text-right'}
        disabled={props.isDisabled ?? false}
        value={value}
        id={`val`}
        key={`val`}
        label=''
        name={`val`}
        ref={register({
          setValueAs: (value: string | number) => (isNaN(+value) ? value : +value),
        })}
        onChange={onChange}
        srOnly={true}
      />
    </form>
  )
}
interface Props<T> {
  header: string[];
  rows: T[][];
  disableColumn?: number[]
  updateData: (rowIndex: number, columnIndex: number, value: any) => void
}
const TableGrid = (props: Props<any>) => {

  const { header, rows, disableColumn } = props;

  const updateCell = useCallback((rowIndex: number, columnIndex: number, value: any) => {
    props.updateData(rowIndex, columnIndex, value);
  }, [props]);

  return (
    <Table C={header.length}>
      <thead>
        <tr>
          {header.map((h, i) =>
            <TableHeaderCell key={`tableHeader${i}`}>
              {h}
            </TableHeaderCell>
          )}
        </tr>
      </thead>
      <tbody>
        {rows.map((r, j) =>
        (<TableRow key={`TableRow${j}`}>
          {r.map((c, k) =>
            <TableCell key={`TableCell${k}`}>
              <TCell
                updateData={updateCell}
                isDisabled={disableColumn ? disableColumn.some(c => c === k) : false}
                rowIndex={j}
                colIndx={k}
                initialValue={c}
              />
            </TableCell>
          )}
        </TableRow>)
        )}
      </tbody>
    </Table>
  )
};

// https://adamlynch.com/flexible-data-tables-with-css-grid/

const Table = styled.table<{ C: number }>`
  display: grid;
  grid-template-columns: 0.5fr repeat(${props => props.C - 1}, 1fr);
  border: 1px solid var(--color-gray-400);
  border-collapse: collapse;

  thead,tbody,tr {
    display: contents;
  }
`;

const TableRow = styled.tr`
  /* &:nth-child(odd) td{ */
  /* background-color: var(--color-gray-300);*/
  /* } */
`;

const TableHeaderCell = styled.th`
  text-align: center;
  padding: 2px;
`;

const TableCell = styled.td`
  text-align: right;
  /* &:first-of-type { */
  /* border-right: 1px solid var(--color-gray-400); */
  /* } */
`;

export default TableGrid;