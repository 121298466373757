import { GraphicProcessor } from "lib/graphic-processor";
import { IPoint } from "lib/math/types";
import { objDataType } from "lib/models/types";
import { LoadStructuralData } from "lib/models/structural/load";
import { SlabData } from "lib/models/structural/slab";
import { analysisManager } from "../analysis/analysismodel-manager";
import { loadParam, loadType } from "../types/load";
import { hypothesisDispatcher } from "./dispatcher";
import { getDefaultWindHypothesisValues, IwindHypothesis, windSubTypes } from "./hypothesis";
import { WindCalculer, WindDataOut } from "./windcalculer";

interface windLoads {
  slab: SlabData;
  loads: LoadStructuralData[];
}

export class WindHypothesis {

  private Wx1p: IwindHypothesis;
  private Wx1pLoads: windLoads[] = [];
  private Wx2p: IwindHypothesis;
  private Wx2pLoads: windLoads[] = [];
  private Wx1n: IwindHypothesis;
  private Wx1nLoads: windLoads[] = [];
  private Wx2n: IwindHypothesis;
  private Wx2nLoads: windLoads[] = [];

  private Wy1p: IwindHypothesis;
  private Wy1pLoads: windLoads[] = [];
  private Wy2p: IwindHypothesis;
  private Wy2pLoads: windLoads[] = [];
  private Wy1n: IwindHypothesis;
  private Wy1nLoads: windLoads[] = [];
  private Wy2n: IwindHypothesis;
  private Wy2nLoads: windLoads[] = [];

  hasHypothesis() {
    return !!this.Wx1p;
  }
  getWindHypothesisById(uiid: string) {
    if (this.Wx1p && this.Wx1p.uid === uiid) return this.Wx1p;
    if (this.Wx2p && this.Wx2p.uid === uiid) return this.Wx2p;
    if (this.Wx1n && this.Wx1n.uid === uiid) return this.Wx1n;
    if (this.Wx2n && this.Wx2n.uid === uiid) return this.Wx2n;
    if (this.Wy1p && this.Wy1p.uid === uiid) return this.Wy1p;
    if (this.Wy2p && this.Wy2p.uid === uiid) return this.Wy2p;
    if (this.Wy1n && this.Wy1n.uid === uiid) return this.Wy1n;
    if (this.Wy2n && this.Wy2n.uid === uiid) return this.Wy2n;
    return undefined;
  }
  getWindHypothesis(): IwindHypothesis[] {
    if (this.hasHypothesis()) {
      return [
        this.Wx1p, this.Wx2p, this.Wx1n, this.Wx2n,
        this.Wy1p, this.Wy2p, this.Wy1n, this.Wy2n
      ];
    }
    return [];
  }
  getWindLoadHypothesisByUiid(uiid: string): LoadStructuralData[] {
    if (this.Wx1p && this.Wx1p.uid === uiid) return this.getLoads(this.Wx1pLoads);
    if (this.Wx2p && this.Wx2p.uid === uiid) return this.getLoads(this.Wx2pLoads);
    if (this.Wx1n && this.Wx1n.uid === uiid) return this.getLoads(this.Wx1nLoads);
    if (this.Wx2n && this.Wx2n.uid === uiid) return this.getLoads(this.Wx2nLoads);
    if (this.Wy1p && this.Wy1p.uid === uiid) return this.getLoads(this.Wy1pLoads);
    if (this.Wy2p && this.Wy2p.uid === uiid) return this.getLoads(this.Wy2pLoads);
    if (this.Wy1n && this.Wy1n.uid === uiid) return this.getLoads(this.Wy1nLoads);
    if (this.Wy2n && this.Wy2n.uid === uiid) return this.getLoads(this.Wy2nLoads);
    return [];
  }
  private getLoads(windLoads: windLoads[]) {
    const loads: LoadStructuralData[] = [];
    for (const windLoad of windLoads) {
      loads.push(...windLoad.loads);
    }
    return loads;
  }

  initWindHypothesis(graPro: GraphicProcessor, dataTable: any): void {

    this.deleteWindHypothesis(graPro);
    this.Wx1p = getDefaultWindHypothesisValues(windSubTypes.WX1P);
    this.Wx2p = getDefaultWindHypothesisValues(windSubTypes.WX2P);
    this.Wx1n = getDefaultWindHypothesisValues(windSubTypes.WX1N);
    this.Wx2n = getDefaultWindHypothesisValues(windSubTypes.WX2N);
    this.Wy1p = getDefaultWindHypothesisValues(windSubTypes.WY1P);
    this.Wy2p = getDefaultWindHypothesisValues(windSubTypes.WY2P);
    this.Wy1n = getDefaultWindHypothesisValues(windSubTypes.WY1N);
    this.Wy2n = getDefaultWindHypothesisValues(windSubTypes.WY2N);

    // Devuelvo tantos items como slabs y en ORDEN CRECIENTE DE STOREY, desde las plantas inferiores a las superiores.
    // Y los 16 valores numericos de cargas por slab van en este orden (8 para X mas 8 para Y):
    // Wx1Pos_1, Wx1Pos_2, Wx2Pos_1, Wx2Pos_2, Wx1Neg_1, Wx1Neg_2, Wx2Neg_1, Wx2Neg_2,
    // Wy1Pos_1, Wy1Pos_2, Wy2Pos_1, Wy2Pos_2, Wy1Neg_1, Wy1Neg_2, Wy2Neg_1, Wy2Neg_2
    // Ademas los contornos devueltos van en orden CW y sin repeticion primer-ultimo vertice.
    const vRes: WindDataOut[] = this.calculate(graPro, dataTable);

    // Wx1Pos_1 --> left part
    // Wx1Pos_2 --> right part
    // Wx2Pos_1 --> left part
    // Wx2Pos_2 --> right part
    // Wx1Neg_1 --> left part
    // Wx1Neg_2 --> right part
    // Wx2Neg_1 --> left part
    // Wx2Neg_2 --> right part

    // Wy1Pos_1 --> top part
    // Wy1Pos_2 --> bottom part
    // Wy2Pos_1 --> top part
    // Wy2Pos_2 --> bottom part
    // Wy1Neg_1 --> top part
    // Wy1Neg_2 --> bottom part
    // Wy2Neg_1 --> top part
    // Wy2Neg_2 --> bottom part

    // Aqui le paso el balon a JaWS...
    const strucMng = graPro.getStructuralModelManager();
    for (const slabRes of vRes) {
      const { slabName, loads, vHalfContours } = slabRes;

      const slabData = strucMng.getSlabFromName(slabName)!;
      const basePoint = slabData.definition.basePoint;
      const leftPart = vHalfContours[0].map(p => ({ x: p.x - basePoint.x, y: p.y - basePoint.y, z: 0 }));
      const rightPart = vHalfContours[1].map(p => ({ x: p.x - basePoint.x, y: p.y - basePoint.y, z: 0 }));
      const topPart = vHalfContours[2].map(p => ({ x: p.x - basePoint.x, y: p.y - basePoint.y, z: 0 }));
      const bottomPart = vHalfContours[3].map(p => ({ x: p.x - basePoint.x, y: p.y - basePoint.y, z: 0 }));

      let windLoads: windLoads = { slab: slabData, loads: [] };

      for (let i = 0; i < loads.length; i++) {
        const load = loads[i];
        if (i === 0) {
          const loadElem = this.createLoad(slabData, load, basePoint, leftPart, this.Wx1p, "L", graPro);
          windLoads.loads.push(loadElem)
        } else if (i === 1) {
          const loadElem = this.createLoad(slabData, load, basePoint, rightPart, this.Wx1p, "R", graPro);
          windLoads.loads.push(loadElem)
          this.Wx1pLoads.push(windLoads);
          windLoads = { slab: slabData, loads: [] };

        } else if (i === 2) {
          const loadElem = this.createLoad(slabData, load, basePoint, leftPart, this.Wx2p, "L", graPro);
          windLoads.loads.push(loadElem);
        } else if (i === 3) {
          const loadElem = this.createLoad(slabData, load, basePoint, rightPart, this.Wx2p, "R", graPro);
          windLoads.loads.push(loadElem);
          this.Wx2pLoads.push(windLoads);
          windLoads = { slab: slabData, loads: [] };

        } else if (i === 4) {
          const loadElem = this.createLoad(slabData, load, basePoint, leftPart, this.Wx1n, "L", graPro);
          windLoads.loads.push(loadElem);
        } else if (i === 5) {
          const loadElem = this.createLoad(slabData, load, basePoint, rightPart, this.Wx1n, "R", graPro);
          windLoads.loads.push(loadElem);
          this.Wx1nLoads.push(windLoads);
          windLoads = { slab: slabData, loads: [] };

        } else if (i === 6) {
          const loadElem = this.createLoad(slabData, load, basePoint, leftPart, this.Wx2n, "L", graPro);
          windLoads.loads.push(loadElem);
        } else if (i === 7) {
          const loadElem = this.createLoad(slabData, load, basePoint, rightPart, this.Wx2n, "R", graPro);
          windLoads.loads.push(loadElem);
          this.Wx2nLoads.push(windLoads);
          windLoads = { slab: slabData, loads: [] };


        } else if (i === 8) {
          const loadElem = this.createLoad(slabData, load, basePoint, topPart, this.Wy1p, "T", graPro);
          windLoads.loads.push(loadElem);
        } else if (i === 9) {
          const loadElem = this.createLoad(slabData, load, basePoint, bottomPart, this.Wy1p, "B", graPro);
          windLoads.loads.push(loadElem);
          this.Wy1pLoads.push(windLoads);
          windLoads = { slab: slabData, loads: [] };

        } else if (i === 10) {
          const loadElem = this.createLoad(slabData, load, basePoint, topPart, this.Wy2p, "T", graPro);
          windLoads.loads.push(loadElem);
        } else if (i === 11) {
          const loadElem = this.createLoad(slabData, load, basePoint, bottomPart, this.Wy2p, "B", graPro);
          windLoads.loads.push(loadElem);
          this.Wy2pLoads.push(windLoads);
          windLoads = { slab: slabData, loads: [] };

        } else if (i === 12) {
          const loadElem = this.createLoad(slabData, load, basePoint, topPart, this.Wy1n, "T", graPro);
          windLoads.loads.push(loadElem);
        } else if (i === 13) {
          const loadElem = this.createLoad(slabData, load, basePoint, bottomPart, this.Wy1n, "B", graPro);
          windLoads.loads.push(loadElem);
          this.Wy1nLoads.push(windLoads);
          windLoads = { slab: slabData, loads: [] };

        } else if (i === 14) {
          const loadElem = this.createLoad(slabData, load, basePoint, topPart, this.Wy2n, "T", graPro);
          windLoads.loads.push(loadElem);
        } else if (i === 15) {
          const loadElem = this.createLoad(slabData, load, basePoint, bottomPart, this.Wy2n, "B", graPro);
          windLoads.loads.push(loadElem);
          this.Wy2nLoads.push(windLoads);
          windLoads = { slab: slabData, loads: [] };
        }
      }
    }
    hypothesisDispatcher.dispatchAddWind();
  }
  private calculate(graPro: GraphicProcessor, dataTable: any): WindDataOut[] {
    const windCalc = new WindCalculer(graPro);

    // De momento metemos un unico valor para la excentricidad, aunque segun lo hablado con JL en el futuro habra que
    // poner una excentricidad por planta e introducirla en el GUI. Parece que por defecto valdra un 5%.
    // Debe ser un tanto por uno, no un tanto por ciento, segun me dice JoseLuis.
    windCalc.eccentricity = 0.05;

    // Sacamos los datos de la entrada que vienen en orden arriba-abajo, mientras que WindCalculer los trata al reves,
    // por lo que les daremos la vuelta, metiendolos a la remanguille...
    const vFxFy: [number, number][] = [];
    for (const item of dataTable) {
      const fx = item.fx;
      const fy = item.fy;
      vFxFy.unshift([fx, fy]);
    }
    windCalc.FxFy = vFxFy;

    const vRes = windCalc.calculateWindLoads();
    if (vRes.length === 0) {
      const msg = "ERROR: Can't perform wind loads calculations!!!.";
      console.error(msg);
      window.alert(msg);
    }

    return vRes;
  }
  private createLoad(parent: SlabData, value: number, basePto: IPoint, ptos2d: IPoint[], hypothesis: IwindHypothesis, part: "L" | "R" | "T" | "B", graphicProc: GraphicProcessor) {
    const strucMng = graphicProc.getStructuralModelManager();
    const loadDef: loadParam = {
      name: `${parent.strucName}_${hypothesis.name}_${part}`,
      hypothesisId: hypothesis.uid,
      parentStructElemId: parent.id,
      externalGeo: true,
      type: loadType.SUPERFICIAL,
      loadValue: value,
      ptos2D: ptos2d,
      basePoint: basePto,
      rotation: { x: 0, y: 0, z: 0 },
    }

    const loadData = new LoadStructuralData(loadDef);
    loadData.parentStrucElem = parent;
    loadData.createGraphicObj();
    loadData.isDataVisible = false;
    loadData.graphicObj.visible = false;

    const layerId = strucMng.getLayerByStoreyIdAndStructuralType(parent.storeyId, objDataType.LOAD).id;
    graphicProc.addToLayer(loadData, layerId);
    const lyrManager = graphicProc.getLayerManager();
    lyrManager.layerObserver.dispatchLoadLayers();
    return loadData;
  }

  deleteWindHypothesis(graphicProc: GraphicProcessor): void {
    this.deleteHypothesis(this.Wx1p);
    this.deleteLoads(graphicProc, this.Wx1pLoads);
    this.Wx1p = undefined!;

    this.deleteHypothesis(this.Wx2p);
    this.deleteLoads(graphicProc, this.Wx2pLoads);
    this.Wx2p = undefined!;

    this.deleteHypothesis(this.Wx1p);
    this.deleteLoads(graphicProc, this.Wx1nLoads);
    this.Wx1p = undefined!;

    this.deleteHypothesis(this.Wx2n);
    this.deleteLoads(graphicProc, this.Wx2nLoads);
    this.Wx2n = undefined!;

    this.deleteHypothesis(this.Wy1p);
    this.deleteLoads(graphicProc, this.Wy1pLoads);
    this.Wy1p = undefined!;

    this.deleteHypothesis(this.Wy2p);
    this.deleteLoads(graphicProc, this.Wy2pLoads);
    this.Wy2p = undefined!;

    this.deleteHypothesis(this.Wy1n);
    this.deleteLoads(graphicProc, this.Wy1nLoads);
    this.Wy1n = undefined!;

    this.deleteHypothesis(this.Wy2n);
    this.deleteLoads(graphicProc, this.Wy2nLoads);
    this.Wy2n = undefined!;
    hypothesisDispatcher.dispatchRemoveWind();
  }
  private deleteHypothesis(hypothesis: IwindHypothesis) {
    if (analysisManager.analysis.solveHypothesis.includes(hypothesis)) {
      const i = analysisManager.analysis.solveHypothesis.indexOf(hypothesis);
      analysisManager.analysis.solveHypothesis.splice(i, 1);
    }
  }
  private deleteLoads(graphicProc: GraphicProcessor, windLoads: windLoads[]) {
    for (const windLoad of windLoads) {
      for (const load of windLoad.loads) {
        graphicProc.removeFromLayer(load);
      }
    }
    windLoads.length = 0;
    const lyrManager = graphicProc.getLayerManager();
    lyrManager.layerObserver.dispatchLoadLayers();
  }

  // **********************************************************

  addHypothesis(hypo: IwindHypothesis) {
    if (hypo.subType === windSubTypes.WX1P) this.Wx1p = { ...hypo };
    else if (hypo.subType === windSubTypes.WX2P) this.Wx2p = { ...hypo };
    else if (hypo.subType === windSubTypes.WX1N) this.Wx1n = { ...hypo };
    else if (hypo.subType === windSubTypes.WX2N) this.Wx2n = { ...hypo };
    else if (hypo.subType === windSubTypes.WY1P) this.Wy1p = { ...hypo };
    else if (hypo.subType === windSubTypes.WY2P) this.Wy2p = { ...hypo };
    else if (hypo.subType === windSubTypes.WY1N) this.Wy1n = { ...hypo };
    else if (hypo.subType === windSubTypes.WY2N) this.Wy2n = { ...hypo };
  }
  addHypothesisLoad(hypo: IwindHypothesis, load: LoadStructuralData): void {

    function addLoad(windLoads: windLoads[], loadData: LoadStructuralData) {
      const slabWindLoads = windLoads.find(h => h.slab === slab);
      if (slabWindLoads) {
        if (!slabWindLoads.loads.includes(loadData)) {
          slabWindLoads.loads.push(loadData);
        }
      } else {
        windLoads.push({ slab, loads: [loadData] });
      }
    }

    const slab = load.parentStrucElem as SlabData;
    if (hypo.subType === windSubTypes.WX1P) addLoad(this.Wx1pLoads, load);
    else if (hypo.subType === windSubTypes.WX2P) addLoad(this.Wx2pLoads, load);
    else if (hypo.subType === windSubTypes.WX1N) addLoad(this.Wx1nLoads, load);
    else if (hypo.subType === windSubTypes.WX2N) addLoad(this.Wx2nLoads, load);
    else if (hypo.subType === windSubTypes.WY1P) addLoad(this.Wy1pLoads, load);
    else if (hypo.subType === windSubTypes.WY2P) addLoad(this.Wy2pLoads, load);
    else if (hypo.subType === windSubTypes.WY1N) addLoad(this.Wy1nLoads, load);
    else if (hypo.subType === windSubTypes.WY2N) addLoad(this.Wy2nLoads, load);
  }
  removeHypothesisLoad(load: LoadStructuralData): void {
    const hypoId = load.definition.hypothesisId;
    const hypo = this.getWindHypothesisById(hypoId)!;

    function removeLoad(windLoads: windLoads[], loadData: LoadStructuralData) {
      const slabWindLoads = windLoads.find(h => h.slab === slab);
      if (slabWindLoads) {
        const index = slabWindLoads.loads.indexOf(loadData);
        if (index > -1) {
          slabWindLoads.loads.splice(index, 1);
        }
      }
    }

    const slab = load.parentStrucElem as SlabData;
    if (hypo.subType === windSubTypes.WX1P) removeLoad(this.Wx1pLoads, load);
    else if (hypo.subType === windSubTypes.WX2P) removeLoad(this.Wx2pLoads, load);
    else if (hypo.subType === windSubTypes.WX1N) removeLoad(this.Wx1nLoads, load);
    else if (hypo.subType === windSubTypes.WX2N) removeLoad(this.Wx2nLoads, load);
    else if (hypo.subType === windSubTypes.WY1P) removeLoad(this.Wy1pLoads, load);
    else if (hypo.subType === windSubTypes.WY2P) removeLoad(this.Wy2pLoads, load);
    else if (hypo.subType === windSubTypes.WY1N) removeLoad(this.Wy1nLoads, load);
    else if (hypo.subType === windSubTypes.WY2N) removeLoad(this.Wy2nLoads, load);
  }

}

export let windhypothesisManager: WindHypothesis;
export function initWindHypothesisManager() {
  windhypothesisManager = new WindHypothesis();
  return windhypothesisManager;
}