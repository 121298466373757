import { GraphicProcessor } from "lib/graphic-processor";
import { getChamferInLine, getChamferInPolyLine, IChamferOptions } from "lib/math/chamfer";
import { IObjData } from "lib/models/objdata";
import { LineData } from "lib/models/primitives/line";
import { cadOpType } from "lib/operations/factory";
import { CadCommand } from "../base";

export class ChamferLinesCommand extends CadCommand {
  protected opType = cadOpType.CHAMFER;

  private objDatas: IObjData[] = [];
  private createdObjData: IObjData;
  // parámetros de chaflán
  private polylineMode: boolean = false;
  private chamferOpts: IChamferOptions;

  constructor(objDatas: IObjData[], options: IChamferOptions, polylineMode: boolean, layerId: string, graphicProcessor: GraphicProcessor) {
    super(graphicProcessor);
    this.chamferOpts = options;
    this.polylineMode = polylineMode;
    this.objDatas = objDatas;
    this.layerId = layerId;
  }

  public async execute() {
    const dtMdlMngr = this.graphicProcessor.getDataModelManager();
    let newObjInfo;
    if (this.createdObjData === undefined) {
      newObjInfo = this.calculateChamfer(newObjInfo);
      if (newObjInfo) {
        const data = new LineData(newObjInfo);
        data.createGraphicObj();
        this.graphicProcessor.addToLayer(data, this.layerId);
        this.createdObjData = data;
        dtMdlMngr.dispatchAddedObjs([this.createdObjData]);

        this.graphicProcessor.removeFromLayer(this.objDatas[0]);
        dtMdlMngr.dispatchDeletedObjs([this.objDatas[0]]);
        const differentsLines = this.objDatas[0] !== this.objDatas[1];
        if (differentsLines) {
          this.graphicProcessor.removeFromLayer(this.objDatas[1]);
          dtMdlMngr.dispatchDeletedObjs([this.objDatas[1]]);
        }
      } else {
        console.assert(false, "[CHAMFER CMD] calculate chamfer generate none object")
      }
    } else {
      this.graphicProcessor.addToLayer(this.createdObjData, this.createdObjData.layerId);
      dtMdlMngr.dispatchAddedObjs([this.createdObjData]);

      this.graphicProcessor.removeFromLayer(this.objDatas[0]);
      dtMdlMngr.dispatchDeletedObjs([this.objDatas[0]]);
      const differentsLines = this.objDatas[0] !== this.objDatas[1];
      if (differentsLines) {
        this.graphicProcessor.removeFromLayer(this.objDatas[1]);
        dtMdlMngr.dispatchDeletedObjs([this.objDatas[1]]);
      }
    }
    dtMdlMngr.layerManager.layerObserver.dispatchLoadLayers();
  }

  private calculateChamfer(newObjInfo: any) {
    const differentsLines = this.objDatas[0] !== this.objDatas[1];
    const isRoundChamfer = this.chamferOpts.radius !== undefined;
    if (this.polylineMode) {
      if (isRoundChamfer) {
        // newObjInfo = getRoundChamferInPolyLine(objs[0] as THREE.Line, this.chamferOpts.radius);
      } else {
        newObjInfo = getChamferInPolyLine(this.objDatas[0], this.chamferOpts);
      }
    } else {
      if (!differentsLines) {
        if (isRoundChamfer) {
          // newObjInfo = getRoundChamferInLine(objs[0] as THREE.Line, this.chamferOpts.firstEdgeIndex, this.chamferOpts.secondEdgeIndex, this.chamferOpts.radius);
        } else {
          newObjInfo = getChamferInLine(this.objDatas[0], this.chamferOpts);
        }
      } else {
        if (isRoundChamfer) {
          // newObjInfo = getRoundChamfersBetweenLine(objs as THREE.Line[], this.chamferOpts.firstEdgeIndex, this.chamferOpts.secondEdgeIndex, this.chamferOpts.radius);
        } else {
          // newObjInfo = getChamfersBetweenLine(objs as THREE.Line[], this.chamferOpts);
        }
      }
    }
    return newObjInfo;
  }

  public unExecute(): void | Promise<void> {
    const dtMdlMngr = this.graphicProcessor.getDataModelManager();
    this.graphicProcessor.removeFromLayer(this.createdObjData);
    dtMdlMngr.dispatchDeletedObjs([this.createdObjData]);

    this.graphicProcessor.addToLayer(this.objDatas[0], this.objDatas[0].layerId);
    dtMdlMngr.dispatchAddedObjs([this.objDatas[0]]);
    const differentsLines = this.objDatas[0] !== this.objDatas[1];
    if (differentsLines) {
      this.graphicProcessor.addToLayer(this.objDatas[1], this.objDatas[1].id);
      dtMdlMngr.dispatchAddedObjs([this.objDatas[1]]);
    }
    dtMdlMngr.layerManager.layerObserver.dispatchLoadLayers();
  }

  public delete(): void {
    this.objDatas.length = 0;
    this.createdObjData = undefined!;
  }
}