import { EllipseEditDataCommand } from "lib/commands/edition/ellipse";

import { setPosBuffer } from "lib/geometries";
import { ellipseCreate, ellipseParam } from "lib/geometries/ellipse";
import { lineAddVertex, lineAuxCreate, lineMoveVertex } from "lib/geometries/line";
import { vectorDist3D } from "lib/math/distance";
import { ellipseBuffer } from "lib/math/ellipse";
import { copyIPoint } from "lib/math/point";
import { IPoint } from "lib/math/types";
import { EllipseData } from "lib/models/primitives/ellipse";
import { SimpleEdition } from "../edition";
import { cadOpType } from "../factory";
import { settingsOpModes } from "../step-operations";

export enum editEllipseParameters { SEMIMAJORAXIS, SEMIMINORAXIS, STARTANGLE, ENDANGLE }

export class EllipseEditOP extends SimpleEdition {

  public opType = cadOpType.EDITELLIPSE;

  private objData: EllipseData;
  private param: editEllipseParameters;
  private aAxis: number;
  private bAxis: number;

  constructor(objData: EllipseData, param: editEllipseParameters, iniPoint: IPoint) {
    super(iniPoint);
    this.objData = objData;
    this.param = param;
    const objDef = this.objData.definition;
    this.aAxis = objDef.a;
    this.bAxis = objDef.b;
    const aux = ellipseCreate(objDef.center, this.aAxis, this.bAxis, objDef.azimutO);
    if (aux) {
      this.auxPoly = aux;
    }
    this.auxLine = lineAuxCreate();
    const { x, y, z } = copyIPoint(objDef.center);
    lineAddVertex(this.auxLine, x, y, z);
    lineAddVertex(this.auxLine, x, y, z);
  }
  protected iniSettingsOp() {
    if (this.param === editEllipseParameters.SEMIMAJORAXIS) {
      this.settingsOpManager.setCfg([{
        infoMsg: "Insert semiaxis mayor: ",
        stepMode: settingsOpModes.ONEBOX,
        currValue: () => (this.aAxis).toFixed(4),
        cmdLineListener: (cmd: string) => {
          const h = parseFloat(cmd);
          if (h) {
            this.aAxis = h;
            this.endOperation()
          }
        },
      }]);
    } else if (this.param === editEllipseParameters.SEMIMINORAXIS) {
      this.settingsOpManager.setCfg([{
        infoMsg: "Insert semiaxis mayor: ",
        stepMode: settingsOpModes.ONEBOX,
        currValue: () => (this.bAxis).toFixed(4),
        cmdLineListener: (cmd: string) => {
          const h = parseFloat(cmd);
          if (h) {
            this.bAxis = h;
            this.endOperation()
          }
        },
      }]);
    }
  }
  public moveLastPoint(pto: IPoint) {
    lineMoveVertex(this.auxLine, pto.x, pto.y, pto.z);
    if (this.param === editEllipseParameters.SEMIMAJORAXIS) {
      this.aAxis = vectorDist3D(this.objData.definition.center, pto);
    } else {
      this.bAxis = vectorDist3D(this.objData.definition.center, pto);
    }
    this.calculateEllipse();
  }
  public calculateEllipse(): void {
    const objDef = this.objData.definition;
    let coords = ellipseBuffer(objDef.center, this.aAxis, this.bAxis, objDef.azimutO, objDef.plane);
    if (!coords) coords = new Float32Array(0);
    setPosBuffer(this.auxPoly, coords);
  }
  public setLastPoint(): void {
    if (this.param === editEllipseParameters.SEMIMAJORAXIS) {
      this.aAxis = vectorDist3D(this.objData.definition.center, this.lastPoint);
    } else {
      this.bAxis = vectorDist3D(this.objData.definition.center, this.lastPoint);
    }
    this.endOperation();
  }
  public save() {
    if (this.graphicProcessor) {
      const objDef = this.objData.definition;
      const ellipse: ellipseParam = {
        center: objDef.center,
        a: this.aAxis,
        b: this.bAxis,
        azimutO: objDef.azimutO,
        plane: objDef.plane,
      };
      const command = new EllipseEditDataCommand(this.objData, ellipse, null, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
}