import * as THREE from "three";
import { lineBufferHandler } from "lib/geometry-optimizer/line-optimizer";
import { IColor } from "lib/math/types";
import { isLineBasedGeometry } from "lib/models/checktools";
import { IObjData } from "lib/models/objdata";
import { LineBaseData } from "lib/models/primitives/line-base";
import { DataModelManager } from "lib/models/datamodel-manager";

export class LayerData {

  public id: string;  
  public name: string;
  public visible: boolean = true;
  public locked: boolean = false;
  
  public threeObject: THREE.Group;
  public objDatas: IObjData[] = [];

  private bulkData: boolean = false;
  get isBulkData() { return this.bulkData }

  private lineOptimizer: lineBufferHandler<LineBaseData>;
  public getlineOptimized() { return this.lineOptimizer.line; }

  constructor(name: string) {
    this.name = name;
    this.threeObject = new THREE.Group();
    this.threeObject.name = this.name;
  }

  public setBulkData(isBulkData: boolean) {
    this.bulkData = isBulkData;
    if (isBulkData) {
      this.lineOptimizer = new lineBufferHandler();
    }
  }

  public getDataFromGraphicObject(index: number) {
    const dataRef = this.lineOptimizer.getObjDataFromIndex(index);
    return dataRef?.data;
  }

  public addObjData(objData: IObjData) {
    const dataIsInLayer = this.objDatas.includes(objData);
    console.assert(!dataIsInLayer, "[AddObjData] Object already added to layer");
    if (dataIsInLayer) return;

    objData.layerObj = this;
    if (this.bulkData && isLineBasedGeometry(objData)) {
      const buffer = objData.getBufferGeom();
      const color = objData.material.color;
      const line = this.lineOptimizer.addData(objData, buffer, color);
      if (!line.parent) this.threeObject.add(line);
      this.objDatas.push(objData);
      return line;

    } else {
      this.threeObject.add(objData.graphicObj);
      this.objDatas.push(objData);
      return objData.graphicObj;
    }
  }

  public removeObjData(objData: IObjData) {
    const ind = this.objDatas.indexOf(objData);
    if (ind !== -1) {
      if (this.bulkData && isLineBasedGeometry(objData)) {
        this.lineOptimizer.removeData(objData);
      } else {
        this.threeObject.remove(objData.graphicObj);
      }
      this.objDatas.splice(ind, 1);
    }
  }
  public clearObjDatas() {
    this.threeObject.clear();
    if (this.isBulkData) {
      this.lineOptimizer.clearData();
    }
    this.objDatas.length = 0;
  }

  public updateGeometryFromBulkData(objData: LineBaseData) {
    const buffer = objData.getBufferGeom();
    const color = objData.material.color;
    this.lineOptimizer.updateGeometryData(objData, buffer, color);
  }

  public updateMaterialFromBulkData(objData: LineBaseData) {
    const color = objData.material.color;
    this.lineOptimizer.updateMaterialData(objData, color);
  }

  public changeVisibility(objData: LineBaseData, show: boolean) {
    if (show) {
      const buffer = objData.getBufferGeom();
      const color = objData.material.color;
      this.lineOptimizer.addData(objData, buffer, color);
    } else {
      this.lineOptimizer.removeData(objData);
    }
  }

  // -----------------------------------------------------

  public loadObjDatas(modelManager: DataModelManager) {
    const lines: { obj: LineBaseData, bufferGeom: Float32Array[], colorMat: IColor }[] = []
    for (const objData of this.objDatas) {
      if (this.bulkData && isLineBasedGeometry(objData)) {
        lines.push({
          obj: objData,
          bufferGeom: [objData.getBufferGeom()],
          colorMat: objData.material.color
        });
      } else {
        this.threeObject.add(objData.graphicObj);
        objData.graphicObj.visible = objData.isDataVisible;
        modelManager.mapGraphicObjsBulkData.set(objData.graphicObj, this);
      }
    }
    // Add bulk datas
    if (lines.length) {
      this.lineOptimizer.loadDatas(lines);
      const reslines = this.lineOptimizer.line;
      for (const line of reslines) {
        if (!line.parent) this.threeObject.add(line);
      }
      reslines?.forEach(l => modelManager.mapGraphicObjsBulkData.set(l, this));
    }
  }
}
