import * as THREE from "three";
import { getCurrentSolidMaterial, ISolidMaterial, materialCache } from "lib/materials";
import { setPosRotEsc } from "../../geometries";
import { createBufferCylinder, createCylinder, cylinderParam } from "../../geometries/solid/cylinder";
import { copyIPoint } from "../../math/point";
import { objDataType } from "../types";
import { SolidData } from "./solid";

export class CylinderData extends SolidData {

  public type = objDataType.CYLINDER;
  protected nameObj: string = "Cylinder";
  public definition: cylinderParam;

  constructor(definition: cylinderParam, material?: ISolidMaterial) {
    super();
    this.definition = {
      radius: definition.radius,
      height: definition.height,
      basePoint: copyIPoint(definition.basePoint),
      rotation: copyIPoint(definition.rotation),
      offset: copyIPoint(definition.offset),
      scale: copyIPoint(definition.scale),
    };
    this.material = material ?? getCurrentSolidMaterial();
  }
  static createObj(definition: cylinderParam, material: ISolidMaterial) {
    const threeMaterial = materialCache.getSolidMaterial(material);
    return createCylinder(
      definition.radius,
      definition.height,
      threeMaterial,
      definition.basePoint,
      definition.rotation,
      definition.offset,
      definition.scale,
    );
  }
  public createGraphicObj() {
    if (this.graphicObj) {
      console.warn("Attention: Cylinder graphic object already created!!");
      return;
    }
    this.graphicObj = CylinderData.createObj(this.definition, this.material);
  }
  public cloneDefinition(): cylinderParam {
    const def = super.cloneSolidDefinition() as cylinderParam;
    def.radius = this.definition.radius;
    def.height = this.definition.height;
    return def;
  }
  public createObject(definition?: cylinderParam, material?: ISolidMaterial): THREE.Mesh {
    return CylinderData.createObj(definition ?? this.definition, material ?? this.material);
  }
  public regenerateDefinition() {
    const geom = createBufferCylinder(this.definition.radius, this.definition.height);
    this.graphicObj.geometry = geom;
    const { basePoint, offset, rotation, scale } = this.definition;
    setPosRotEsc(this.graphicObj, basePoint, offset, rotation, scale);
  }
}