import * as THREE from "three";
import { lineAngle2p, lineSlope2p } from "./angles";
import { copyIPoint } from "./point";
import { IPoint } from "./types";

export interface Quaternion {
  x: number;
  y: number;
  z: number;
  w: number;
}

export function rotationToQuaternion(rot: IPoint) {
  const qz = new THREE.Quaternion()
  qz.setFromEuler(new THREE.Euler(rot.x, rot.y, rot.z));
  return { x: qz.x, y: qz.y, z: qz.z, w: qz.w };
}
export function quaternionToRotationAngles(x: number, y: number, z: number, w: number) {
  const qz = new THREE.Quaternion(x, y, z, w);
  const euler = new THREE.Euler();
  euler.setFromQuaternion(qz);
  return { x: euler.x, y: euler.y, z: euler.z };
}
export function getRotationVector(vertex: IPoint, orientation: number = 0) {
  const origin = { x: 0, y: 0, z: 0 };
  const angle = lineAngle2p(origin, vertex);
  const slope = lineSlope2p(origin, vertex);
  const angles = new THREE.Euler(orientation, -slope, angle, "ZYX");
  angles.reorder("XYZ");
  return angles;
}

export function rotatePoint(point: IPoint, angleX: number, angleY: number, angleZ: number, optRelCenter: IPoint = { x: 0, y: 0, z: 0 }): IPoint {
  const p0 = new THREE.Vector3(point.x, point.y, point.z)
  let m = new THREE.Matrix4();
  m.makeTranslation(-optRelCenter.x, -optRelCenter.y, -optRelCenter.z);
  p0.applyMatrix4(m);
  m.identity();
  m.makeRotationFromEuler(new THREE.Euler(angleX, angleY, angleZ));
  p0.applyMatrix4(m);
  m.identity();
  m.makeTranslation(optRelCenter.x, optRelCenter.y, optRelCenter.z);
  p0.applyMatrix4(m);
  return { x: p0.x, y: p0.y, z: p0.z }
}
export function rotatePointX(point: IPoint, angleX: number, optRelCenter: IPoint = { x: 0, y: 0, z: 0 }): IPoint {
  return rotatePoint(point, angleX, 0, 0, optRelCenter);
}
export function rotatePointY(point: IPoint, angleY: number, optRelCenter: IPoint = { x: 0, y: 0, z: 0 }): IPoint {
  return rotatePoint(point, 0, angleY, 0, optRelCenter);
}
export function rotatePointZ(point: IPoint, angleZ: number, optRelCenter: IPoint = { x: 0, y: 0, z: 0 }): IPoint {
  return rotatePoint(point, 0, 0, angleZ, optRelCenter);
}

export function rotateBufferAttribute(buffer: THREE.BufferAttribute, angleX: number, angleY: number, angleZ: number, optBasePoint: IPoint = { x: 0, y: 0, z: 0 }) {
  let m = new THREE.Matrix4();
  m.makeTranslation(-optBasePoint.x, -optBasePoint.y, -optBasePoint.z);
  buffer.applyMatrix4(m);
  m.identity();
  m.makeRotationFromEuler(new THREE.Euler(angleX, angleY, angleZ));
  buffer.applyMatrix4(m);
  m.identity();
  m.makeTranslation(optBasePoint.x, optBasePoint.y, optBasePoint.z);
  buffer.applyMatrix4(m);
}

export function rotateBuffer(buffer: Float32Array, angleX: number, angleY: number, angleZ: number, optBasePoint: IPoint = { x: 0, y: 0, z: 0 }) {
  const bufferAtr = new THREE.Float32BufferAttribute(buffer, 3);
  rotateBufferAttribute(bufferAtr, angleX, angleY, angleZ, optBasePoint);
  return buffer.set(bufferAtr.array);
}

export function rotateVector(vector: IPoint, angleX: number, angleY: number, angleZ: number) {
  let pto = copyIPoint(vector);
  pto = rotatePointY(pto, Math.PI * 0.5);
  pto = rotatePointX(pto, Math.PI * 0.5);
  pto = rotatePoint(pto, angleX, angleY, angleZ);
  return pto;
}