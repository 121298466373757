import { useEffect, useReducer } from "react";
import { userMessage, UserMessageAction, UserMessageActionType, userMessageEvents } from '../../../../lib/events/user-messages';
import { useUI } from "../context";

export function useUserMessages() {

  const { openToast } = useUI();

  function reducer(state: userMessage, action: UserMessageAction): userMessage {
    switch (action.type) {
      case UserMessageActionType.SET_MESSAGE:
        return {
          message: action.payload.message,
          type: action.payload.type,
        };
      default:
        throw new Error(`Action is not defined.`);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    message: "",
    type: "success",
  });

  useEffect(() => {
    userMessageEvents.subscribe(dispatch);
    return () => {
      userMessageEvents.unsubscribe(dispatch);
    };
  }, []);

  useEffect(() => {
    if (state.message) {
      openToast({
        title: state.message,
        status: state.type,
      });
    }
  }, [openToast, state]);

  return state;
}
