import { EllipseCommand } from "lib/commands/primitives/ellipse";

import { getCurrentLineMaterial } from "lib/materials";
import { setPosBuffer } from "../../geometries";
import { ellipseCreate, ellipseParam } from "../../geometries/ellipse";
import { lineAddVertex, lineAuxCreate, lineMoveVertex } from "../../geometries/line";
import { lineAzimut2p, normalizeAngle } from "../../math/angles";
import { vectorDist3D } from "../../math/distance";
import { ellipseBuffer } from "../../math/ellipse";
import { copyIPoint } from "../../math/point";
import { IPoint } from "../../math/types";
import { cadOpType } from "../factory";
import { settingsOpModes } from "../step-operations";
import { WireframeOP } from "./wireframe";

export class EllipseOP extends WireframeOP {
  public opType = cadOpType.ELLIPSE;
  public static sampleDataEllipse = {
    center: { x: 0, y: 0, z: 0 },
    a: 2,
    b: 1,
    azimutO: Math.PI * 0.5,
    objPlane: { x: 0, y: 0, z: 0 }
  };

  public auxPoly: THREE.Line;
  public auxLine: THREE.Line;

  public center: IPoint = EllipseOP.sampleDataEllipse.center;
  public aAxis: number = EllipseOP.sampleDataEllipse.a;
  public bAxis: number = EllipseOP.sampleDataEllipse.b;
  public azimutO: number = EllipseOP.sampleDataEllipse.azimutO;

  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Insert center: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
    }, {
      infoMsg: "Insert semiaxis mayor: ",
      stepMode: settingsOpModes.ONEBOX,
      currValue: () => (this.aAxis).toFixed(4),
      cmdLineListener: (cmd: string) => {
        const h = parseFloat(cmd);
        if (h) {
          this.aAxis = h;
          this.calculateEllipse();
          this.setNextStep();
          this.numPoints++;
        }
      },
    }, {
      infoMsg: "Insert semiaxis minor: ",
      stepMode: settingsOpModes.ONEBOX,
      currValue: () => (this.bAxis).toFixed(4),
      cmdLineListener: (cmd: string) => {
        const h = parseFloat(cmd);
        if (h) {
          this.bAxis = h;
          this.setNextStep();
          this.endOperation();
        }
      },
    }]);
  }

  public async start() {
    await super.start();
    this.initializeEllipse();
  }

  private initializeEllipse(): void {
    const aux = ellipseCreate(this.center, this.aAxis, this.bAxis, this.azimutO);
    if (aux) {
      this.auxPoly = aux;
    }
    this.auxLine = lineAuxCreate();
    this.saveToTempScene(this.auxPoly);
    this.saveToTempScene(this.auxLine);
  }

  public moveLastPoint(pto: IPoint) {
    if (this.numPoints === 0) {
      this.center = copyIPoint(pto);
      this.calculateEllipse();
    } else if (this.numPoints < 3) {
      lineMoveVertex(this.auxLine, pto.x, pto.y, pto.z);
      if (this.numPoints === 1) {
        this.aAxis = this.bAxis = vectorDist3D(this.center, pto);
        const c = this.currPlane.getRelativePoint(this.center);
        const p = this.currPlane.getRelativePoint(pto);
        this.azimutO = normalizeAngle(lineAzimut2p(c, p));
      } else if (this.numPoints >= 2) {
        this.bAxis = vectorDist3D(this.center, pto);
      }
      this.calculateEllipse();
    }
  }

  public calculateEllipse(): void {
    let coords = ellipseBuffer(this.center, this.aAxis, this.bAxis, this.azimutO, this.currPlane.rotation);
    if (!coords) coords = new Float32Array(0);
    setPosBuffer(this.auxPoly, coords);
  }

  public setLastPoint(): void {
    if (this.numPoints === 1) {
      const { x, y, z } = this.lastPoint;
      this.center = copyIPoint(this.lastPoint);
      lineAddVertex(this.auxLine, x, y, z);
      lineAddVertex(this.auxLine, x, y, z);
      const planeManager = this.graphicProcessor.getPlaneManager();
      planeManager.activePlane.position = this.center;
      planeManager.activePlane.locked = true;
      this.setNextStep();
    } else if (this.numPoints === 2) {
      const c = this.currPlane.getRelativePoint(this.center);
      const p = this.currPlane.getRelativePoint(this.lastPoint);
      this.azimutO = normalizeAngle(lineAzimut2p(c, p));
      this.aAxis = vectorDist3D(this.center, this.lastPoint);
      if (this.aAxis <= 0) {
        this.numPoints--;
        return;
      }
      this.setNextStep();
    } else {
      this.bAxis = vectorDist3D(this.center, this.lastPoint);
      if (this.bAxis <= 0) {
        this.numPoints--;
        return;
      }
      this.endOperation();
    }
  }

  public endOperation(): void {
    if (this.numPoints > 3) {
      super.endOperation();
    } else {
      if (this.finished === false) {
        EllipseOP.sampleDataEllipse = {
          center: copyIPoint(this.center),
          a: this.aAxis,
          b: this.bAxis,
          azimutO: this.azimutO,
          objPlane: this.currPlane.rotation,
        };
      };
      this.save();
    }
    super.endOperation();
  }

  public save() {
    if (this.graphicProcessor) {
      const ellipse: ellipseParam = {
        center: copyIPoint(this.center),
        a: this.aAxis,
        b: this.bAxis,
        azimutO: this.azimutO,
        plane: this.currPlane.rotation,
      };
      const command = new EllipseCommand(ellipse, this.getCurrentSceneId(), this.graphicProcessor, getCurrentLineMaterial({ lineStyleId: this.lineStyleId }));
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
}
