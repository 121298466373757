import { Cad3dOp } from "lib/operations/base";
import { settingOp } from "lib/operations/step-operations";

export enum OperationActionType {
  START_OPERATION,
  SET_CURRENT_CONFIG,
  UPDATE_CURRENT_CONFIG,
  END_OPERATION,
}

export type operationAction =
  | {
    type: OperationActionType.START_OPERATION;
    payload: {
      operation: Cad3dOp;
    };
  }
  | {
    type: OperationActionType.SET_CURRENT_CONFIG;
    payload: {
      currCfg: settingOp;
    };
  }
  | {
    type: OperationActionType.UPDATE_CURRENT_CONFIG;
    payload: {
      currCfg: settingOp;
    };
  }
  | {
    type: OperationActionType.END_OPERATION;
    payload: {};
  };
