import { storeyMaterialType, structElemType } from "./types/struc-base";
import { iStorey } from "./types/struc-project";
import { loadHypothesisList } from "./hypothesis/hypothesis";
import { BeamData } from "lib/models/structural/beam";
import { ColumnData } from "lib/models/structural/column";
import { SlabData } from "lib/models/structural/slab";
import { WallData } from "lib/models/structural/wall";
import { FooterData } from "lib/models/structural/footer";
import { PileCapData } from "lib/models/structural/pile-cap";
import { objDataType } from "lib/models/types";
import { IStrucElementData, StructuralElementData, SupportElementTypes } from "lib/models/structural/structural";
import { StrucStorey } from "modules/struc/models/project";

export class Storey implements iStorey {

  public id: string;
  public name: string;
  public remarks: string;

  public columnConcreteType: storeyMaterialType;
  public deadLoad: number;
  public fileDxf: string;
  public floorConcreteType: storeyMaterialType;
  public height: number;
  public level: number;
  public liveLoad: number;
  public occupancy: loadHypothesisList;
  
  public beams: BeamData[] = [];
  public columns: ColumnData[] = [];
  public slabs: SlabData[] = [];
  public walls: WallData[] = [];
  public footers: FooterData[] = [];
  public pileCaps: PileCapData[] = [];

  setFromStrucStorey(data: StrucStorey) {
    this.id = data.id;
    this.name = data.floor_name;
    this.remarks = "";
    this.height = data.height;
    this.level = data.level;
    this.occupancy = data.occupancy;
    this.liveLoad = data.live_load;
    this.deadLoad = data.dead_load;
    this.columnConcreteType = data.column_concrete_type;
    this.floorConcreteType = data.floor_concrete_type;
    if (data.dxf_name) this.fileDxf = data.dxf_name;
  }
  IsEmpty() {
    if (this.beams.length > 0) return false;
    if (this.columns.length > 0) return false;
    if (this.slabs.length > 0) return false;
    if (this.walls.length > 0) return false;
    return true;
  }

  addStructuralElement(data: IStrucElementData) {
    if (data instanceof BeamData) {
      this.beams.push(data);
    } else if (data instanceof ColumnData) {
      this.columns.push(data);
    } else if (data instanceof SlabData) {
      this.slabs.push(data);
    } else if (data instanceof WallData) {
      this.walls.push(data);
    } else if (data instanceof FooterData) {
      this.footers.push(data);
    } else if (data instanceof PileCapData) {
      this.pileCaps.push(data);
    }
  }
  deleteStructuralElement(data: IStrucElementData) {
    if (data instanceof BeamData) {
      const i = this.beams.indexOf(data);
      this.beams.splice(i, 1);
    } else if (data instanceof ColumnData) {
      const i = this.columns.indexOf(data);
      this.columns.splice(i, 1);
    } else if (data instanceof SlabData) {
      const i = this.slabs.indexOf(data);
      this.slabs.splice(i, 1);
    } else if (data instanceof WallData) {
      const i = this.walls.indexOf(data);
      this.walls.splice(i, 1);
    } else if (data instanceof FooterData) {
      const i = this.footers.indexOf(data);
      this.footers.splice(i, 1);
    } else if (data instanceof PileCapData) {
      const i = this.pileCaps.indexOf(data);
      this.pileCaps.splice(i, 1);
    }
  }

  regenerateElements() {
    for (const beam of this.beams) {
      this.setHorizontalDefinitionFromStorey(beam);
    }
    for (const slab of this.slabs) {
      this.setHorizontalDefinitionFromStorey(slab);
    }
    for (const column of this.columns) {
      this.setVerticalDefinitionFromStorey(column);
    }
    for (const wall of this.walls) {
      this.setVerticalDefinitionFromStorey(wall);
    }
  }
  private setVerticalDefinitionFromStorey(elemStruc: SupportElementTypes) {
    const def = elemStruc.definition;
    def.materialType = this.columnConcreteType;
    def.height = this.height;
    def.basePoint = { ...def.basePoint, z: this.level };
    elemStruc.regenerateDefinition();
  }
  private setHorizontalDefinitionFromStorey(elemStruc: StructuralElementData) {
    const def = elemStruc.definition
    def.materialType = this.floorConcreteType;
    elemStruc.translate({ x: 0, y: 0, z: this.level - def.basePoint.z });
  }

  getStructuralElementsByType(type: structElemType) {
    switch (type) {
      case objDataType.BEAM: return [...this.beams];
      case objDataType.COLUMN: return [...this.columns];
      case objDataType.FOOTER: return [...this.footers];
      case objDataType.PILECAP: return [...this.pileCaps];
      case objDataType.SLAB: return [...this.slabs];
      case objDataType.WALL: return [...this.walls];
      default: return [];
    }
  }
}
