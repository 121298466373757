import { ApolloProvider } from "@apollo/client";
import { FC } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "../auth/context";
import client from "../../utils/client";
import queryclient from "../../utils/query-client";
import { UIProvider } from "../ui/context";
import { QueryClientProvider } from "react-query";

const Providers: FC = ({ children }) => {
  return (
    <Router>
      <AuthProvider>
        <QueryClientProvider client={queryclient}>
          <ApolloProvider client={client}>
            <UIProvider>{children}</UIProvider>
          </ApolloProvider>
        </QueryClientProvider>
      </AuthProvider>
    </Router >
  );
};

export default Providers;
