import { GraphicProcessor } from "lib/graphic-processor";
import { TextData, textParam } from "lib/models/text";
import { cadOpType } from "lib/operations/factory";
import { EditDataCommand } from "./edit-base";

export class TextEditDataCommand extends EditDataCommand {
    protected opType = cadOpType.TEXTEDIT;
    constructor(obj: TextData, newDefinition: textParam, graphicProcessor: GraphicProcessor) {
        super(obj, newDefinition, null, graphicProcessor);
    }
}