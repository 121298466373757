const EllipseIcon = ({ ...props }) => {
  return (
    <svg className="h-5 w-5" viewBox="0 0 100 100" fill="none" {...props}>
      <path
        d="M94.528 49.771C94.528 31.689 79.671 16.5 59.86 12.644V5.276H40.02V12.651C20.198 16.485 5.35001 31.671 5.35001 49.77C5.35001 67.881 20.186 83.102 40.02 86.956V94.552H59.86V86.939C79.671 83.083 94.528 67.886 94.528 49.771ZM44.979 10.237H54.899L54.85 20.108L44.979 20.122V10.237ZM54.851 89.544L44.98 89.558V79.673H54.9L54.851 89.544ZM59.86 82.019V74.714H40.02V81.993C22.974 78.31 10.312 65.259 10.312 49.771C10.312 34.289 22.955 21.3 40.021 17.607V25.117H59.861V17.558C76.927 21.246 89.57 34.312 89.57 49.771C89.569 65.271 76.927 78.335 59.86 82.019Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EllipseIcon;
