import { FC } from 'react'
import { useUpdateSettings } from './hooks/use-update-settings'
import Form from '../ui/form'
import Select from 'shared/components/ui/select'
import Input from '../ui/input'
import { PrimaryButton, SecondaryButton } from '../ui/button'
import styled from 'styled-components'
interface Props {
  onClose: () => void
}

const SettingsForm: FC<Props> = ({ onClose }) => {
  const { register, handleSubmit, user } = useUpdateSettings()
  return (
    <Wrapper>
      <Form onSubmit={handleSubmit}>
        <Form.Title>Settings</Form.Title>
        <Input label="Usuario" value={user?.username} disabled />
        <Input label="Correo electrónico" value={user?.email} disabled />
        <Select
          id="defaultStart"
          name="defaultStart"
          label="Default start"
          ref={register({
            required: true,
          })}
          defaultValue={user?.defaultStart}
        >
          <option value="cad">GreyCad</option>
          {/* <option value="lite">GreyLite</option> */}
          <option value="struc">GreyStruc</option>
        </Select>
        <Form.Actions>
          <SecondaryButton onClick={onClose} fullWidth>
            Cancel
          </SecondaryButton>
          <PrimaryButton type="submit" fullWidth>
            Update
          </PrimaryButton>
        </Form.Actions>
      </Form>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-width: 300px;
`

export default SettingsForm
