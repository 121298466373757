import * as THREE from "three";
import { BlockManager, blockRefParam } from "lib/blocks";
import { ObjData } from "./objdata";
import { objDataType } from "./types";
import { IPoint } from "lib/math/types";
import { addIpoint, copyIPoint } from "lib/math/point";
import { mirrorRotation, normalizeAngle } from "lib/math/angles";
import { rotatePoint } from "lib/math/rotate";
import { mirrorPoint } from "lib/math/mirror";

export class BlockData extends ObjData {

  public type = objDataType.BLOCK;
  protected nameObj: string = "Block"

  public definition: blockRefParam;
  public material: undefined;

  public graphicObj: THREE.Group;

  constructor(definition: blockRefParam) {
    super();
    this.definition = {
      blockId: definition.blockId,
      position: copyIPoint(definition.position),
      rotation: copyIPoint(definition.rotation),
      scale: copyIPoint(definition.scale),
    };
  }
  static createObj(definition: blockRefParam) {
    const threeGroup = BlockManager.getBlockGraphicObj(definition.blockId);
    threeGroup.position.set(definition.position.x, definition.position.y, definition.position.z);
    threeGroup.rotation.set(definition.rotation.x, definition.rotation.y, definition.rotation.z);
    threeGroup.scale.set(definition.scale.x, definition.scale.y, definition.scale.z);
    return threeGroup;
  }
  public createGraphicObj() {
    if (this.graphicObj) {
      console.warn("Attention: Block graphic object already created!!");
      return;
    }
    this.graphicObj = BlockData.createObj(this.definition);
  }
  public cloneDefinition(): blockRefParam {
    return {
      blockId: this.definition.blockId,
      position: copyIPoint(this.definition.position),
      rotation: copyIPoint(this.definition.rotation),
      scale: copyIPoint(this.definition.scale),
    };
  }
  public cloneMaterial(): undefined {
    return;
  }
  public createObject(definition?: blockRefParam): THREE.Group {
    return BlockData.createObj(definition ?? this.definition);
  }
  public regenerateObjectFromDefinition(): void {
    this.graphicObj.position.set(this.definition.position.x, this.definition.position.y, this.definition.position.z);
    this.graphicObj.rotation.set(this.definition.rotation.x, this.definition.rotation.y, this.definition.rotation.z);
    this.graphicObj.scale.set(this.definition.scale.x, this.definition.scale.y, this.definition.scale.z);
    this.regenerateDependences();
  }
  public translate(distance: IPoint): void {
    this.definition.position = addIpoint(this.definition.position, distance);
    this.regenerateObjectFromDefinition();
  }
  public rotate(angleX: number, angleY: number, angleZ: number, basePoint: IPoint): void {
    angleX = normalizeAngle(angleX);
    angleY = normalizeAngle(angleY);
    angleZ = normalizeAngle(angleZ);

    this.definition.position = rotatePoint(this.definition.position, angleX, angleY, angleZ, basePoint);

    this.definition.rotation.x += angleX;
    this.definition.rotation.y += angleY;
    this.definition.rotation.z += angleZ;
    this.regenerateObjectFromDefinition();
  }
  public scale(factorX: number, factorY: number, factorZ: number, basePoint: IPoint): void {
    this.definition.scale = { x: factorX, y: factorY, z: factorZ };
    this.regenerateObjectFromDefinition();
  }
  public mirror(startPoint: IPoint, endPoint: IPoint): void {
    this.definition.position = mirrorPoint(this.definition.position, startPoint, endPoint);
    this.definition.rotation = mirrorRotation(this.definition.rotation, startPoint, endPoint);
    this.definition.scale.y *= -1;
    this.regenerateObjectFromDefinition();
  }
  public delete(): void {
    this.graphicObj.children.forEach(child => {
      this.graphicObj.remove(child);
    });
  }
}