import { fileInfo } from "modules/struc/utils/storeys";
import { ChangeEvent, useCallback } from "react";
import { PropsWithChildren } from "react-router/node_modules/@types/react";
import styled from "styled-components";

interface Props {
  uploadFile: ((files: fileInfo[]) => void);
  isMultiple: boolean;
}
const StoreyBulkUploader = ({ children, uploadFile, isMultiple }: PropsWithChildren<Props>) => {

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files?.length) {
        const files = Array.from(event.target.files).map((file) => {
          const blob = URL.createObjectURL(file);
          return { name: file.name, url: blob, file };
        });
        files.sort();
        uploadFile(files);
      }
      event.target.value = '';
    },
    [uploadFile]
  );

  return (
    <Wrapper>
      <input
        type="file"
        onChange={handleChange}
        className="sr-only"
        multiple={isMultiple}
      />
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.label`
  height: 100%;
  display: flex;
  align-items: center;
  border: 2px dashed var(--color-gray-400);
  color: var(--color-gray-700);
  border-radius: var(--radius);
  background-color: var(--color-gray-200);
  cursor: pointer;
  font-size: 0.9rem;
  gap: 4px;
  padding: 8px;
  &:hover {
    background-color: var(--color-gray-300);
  }
`;

export default StoreyBulkUploader;
