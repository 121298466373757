import { useMutation } from '@apollo/client'
import { Storey } from 'modules/lite/models/storey'
import {
  GET_PROJECTS,
  UPDATE_PROJECT_AND_STOREYS,
} from 'modules/lite/services/projects'
import { createCode } from 'modules/lite/utils/projects'
import {
  updateStoreys,
  fillStoreys,
  getStoreysWithStorageUrl,
  recalculateLevels,
  recalculateOrder,
} from 'modules/lite/utils/storeys'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { useUI } from 'shared/components/ui/context'
import { Location, Project } from '../../../models/project'
import useProject from './use-project'
import { GetProjectsData } from './use-projects'

type Status = 'idle' | 'loading' | 'success' | 'error'

interface Data {
  project: Project
}
interface Params {
  id: string
  project: any
  storeys: Storey[]
}

export function useEditProject(defaultValues?: Project) {
  const { project } = useProject()
  const { openToast } = useUI()
  const [status, setStatus] = useState<Status>('idle')
  const [mutate] = useMutation<Data, Params>(UPDATE_PROJECT_AND_STOREYS, {
    update(cache, { data }) {
      if (data) {
        const query = cache.readQuery<GetProjectsData>({
          query: GET_PROJECTS,
        })
        if (query) {
          cache.writeQuery({
            query: GET_PROJECTS,
            data: { projects: [...query.projects] },
          })
        }
      }
    },
  })
  const {
    handleSubmit,
    register,
    unregister,
    errors,
    setValue,
    watch,
  } = useForm<Project>({
    defaultValues,
    shouldFocusError: true,
    mode: 'onTouched',
  })
  const { number_of_storeys, footing_level } = watch()
  const history = useHistory()
  const updateProject = useCallback(
    async ({ storeys, seismic_acc, ...values }: Project) => {
      if (project?.id) {
        setStatus('loading')
        const storeysWithStorageUrl = await getStoreysWithStorageUrl(storeys)
        const code = createCode(project.address.country, project.address.city)
        mutate({
          variables: {
            id: project.id,
            project: { ...values, seismic_acc, code },
            storeys: storeysWithStorageUrl.map(storey => ({
              ...storey,
              project_id: project.id,
            })),
          },
        })
          .then(() => {
            openToast({
              title: 'Project updated successfully',
              status: 'success',
            })
            setStatus('success')
          })
          .catch(error => {
            openToast({
              title: JSON.stringify(error) || 'Error updating project',
              status: 'error',
            })
            setStatus('error')
          })
          .finally(() => {
            history.push(`/lite/projects/${project.id}`)
          })
      }
    },
    [openToast, mutate, history, project]
  )

  const [storeys, setStoreys] = useState<Storey[]>([])

  useEffect(() => {
    if (project?.storeys.length) {
      setStoreys(project?.storeys)
    }
  }, [project])
  useEffect(() => {
    register('storeys')
    register('location')
    return () => {
      unregister('storeys')
      unregister('location')
    }
  }, [register, unregister])

  useEffect(() => {
    const updatedStoreys = fillStoreys(number_of_storeys, storeys)
    setStoreys(updatedStoreys)
    return () => setStoreys([])
  }, [storeys, number_of_storeys])

  useEffect(() => {
    setValue('storeys', storeys)
  }, [setValue, storeys])

  const updateStorey = useCallback(
    (storey: Storey) => {
      const updatedStoreys = updateStoreys(storey, storeys, footing_level)
      setStoreys(updatedStoreys)
    },
    [setStoreys, storeys, footing_level]
  )

  const setLocation = useCallback(
    (location: Location) => {
      setValue('location', location)
    },
    [setValue]
  )

  const setFootingLevel = useCallback(
    (footingLevel: number) => {
      setValue('footing_level', footingLevel)
      const updatedStoreys = recalculateLevels(storeys, footingLevel)
      setStoreys(updatedStoreys)
    },
    [setValue, storeys]
  )

  const sortStoreys = useCallback(
    (source: number, destination: number) => {
      const updatedStoreys = recalculateOrder(
        storeys,
        source,
        destination,
        footing_level
      )
      setStoreys(updatedStoreys)
    },
    [footing_level, storeys]
  )
  return {
    errors,
    handleSubmit: handleSubmit(updateProject),
    project,
    register,
    setFootingLevel,
    setLocation,
    status,
    storeys,
    updateStorey,
    sortStoreys,
  }
}
