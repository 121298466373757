const OrthoModeIcon = ({ ...props }) => {
  return (
    <svg className="h-5 w-5" viewBox="0 0 100 100" fill="none" {...props}>
      <path
        d="M93 91H48.9V86.4H46.9V91H9V53.4H13.9V51.4H9V7C9 5.9 8.1 5 7 5C5.9 5 5 5.9 5 7V93C5 94.1 5.9 95 7 95H93C94.1 95 95 94.1 95 93C95 91.9 94.1 91 93 91Z"
        fill="currentColor"
      />
      <path d="M32.5 51.4H23.2V53.4H32.5V51.4Z" fill="currentColor" />
      <path d="M46.9 58.4H48.9V51.4H41.9V53.4H46.9V58.4Z" fill="currentColor" />
      <path d="M48.9 67.8H46.9V77.1H48.9V67.8Z" fill="currentColor" />
    </svg>
  );
};

export default OrthoModeIcon;
