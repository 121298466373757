import { IObjData } from "lib/models/objdata";
import { GraphicProcessor } from "../../graphic-processor";
import { substractIpoint } from "../../math/point";
import { IPoint } from "../../math/types";
import { cadOpType } from "../../operations/factory";
import { TransformCommand } from "./transform";

export class TranslationCommand extends TransformCommand {
  protected opType = cadOpType.MOVE;

  private basePoint: IPoint;
  private newPosition: IPoint;

  constructor(
    basePoint: IPoint,
    newPosition: IPoint,
    objDatas: IObjData[],
    graphicProcessor: GraphicProcessor
  ) {
    super(objDatas, graphicProcessor);
    this.basePoint = basePoint;
    this.newPosition = newPosition;
  }

  protected transformFun = (obj: IObjData) => {
    const vectorDist = substractIpoint(this.newPosition, this.basePoint);
    obj.translate(vectorDist);
  };

  protected unDoTransformFun = (obj: IObjData) => {
    const vectorDist = substractIpoint(this.basePoint, this.newPosition);
    obj.translate(vectorDist);
  };

}