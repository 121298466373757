import { GraphicProcessor } from "lib/graphic-processor";
import { mulIpoint } from "lib/math/point";
import { strectchArcObj, strectchLineObj } from "lib/math/stretch";
import { IPoint } from "lib/math/types";
import { isLineData, isArcData } from "lib/models/checktools";
import { IObjData } from "lib/models/objdata";
import { cadOpType } from "lib/operations/factory";
import { TransformCommand } from "./transform";

export class StrechtCommand extends TransformCommand {
  protected opType = cadOpType.STRETCH;

  private distanceVector: IPoint;
  private partsToStrectch: number[][];

  constructor(distanceVector: IPoint, objIds: IObjData[], objParts: number[][], graphicProcessor: GraphicProcessor) {
    super(objIds, graphicProcessor);
    this.distanceVector = distanceVector;
    this.partsToStrectch = objParts;
  }
  protected transformFun = (data: IObjData) => {
    const indx = this.objDatas.indexOf(data);
    const parts = this.partsToStrectch[indx];
    this.strectchObj(data, parts, this.distanceVector);
  };
  protected unDoTransformFun = (data: IObjData) => {
    const indx = this.objDatas.indexOf(data);
    const parts = this.partsToStrectch[indx];
    const vector = mulIpoint(this.distanceVector, -1);
    this.strectchObj(data, parts, vector);
  };
  private strectchObj(data: IObjData, parts: number[], vector: IPoint) {
    if (parts?.length === 0) {
      data.translate(vector);
    } else {
      if (isLineData(data)) {
        const def = data.definition;
        strectchLineObj(def, parts, vector);
        data.regenerateObjectFromDefinition();

      } else if (isArcData(data)) {
        const def = data.definition;
        const newDef = strectchArcObj(def, parts, vector);
        if (newDef) {
          data.definition = newDef;
          data.regenerateObjectFromDefinition();
        }
      }
    }
  }
}
