import { getCurrentSolidMaterial, ISolidMaterial, materialCache } from "lib/materials";
import { copyIPoint } from "lib/math/point";
import { pileCapParam } from "lib/models-struc/types/pile-cap";
import { objDataType } from "../types";
import { StructuralElementData } from "./structural";
import { getPileCapFromTemplate } from '../../geometries/structural/pile-cap';
import { buildingElemType } from "lib/models-struc/types/struc-base";
import { beamCrossSectionCache } from "lib/models-struc/cross-sections-shape/beam-cross-sections/cache";
import { shellCrossSectionCache } from "lib/models-struc/cross-sections-shape/shell-cross-sections/cache";
import { ICircular } from "lib/models-struc/cross-sections-shape/beam-cross-sections/types";

export class PileCapData extends StructuralElementData {

  public type: buildingElemType = objDataType.PILECAP;
  protected nameObj: string = "Pile cap";

  public definition: pileCapParam;

  constructor(definition: pileCapParam, material?: ISolidMaterial) {
    super();
    this.definition = {
      storeyId: definition.storeyId,
      name: definition.name,
      lnkObjIds: definition.lnkObjIds ? definition.lnkObjIds.slice() : [],
      materialType: definition.materialType,

      capSectionId: definition.capSectionId,
      capCoords: definition.capCoords.map(copyIPoint),

      pileSections: definition.pileSections,
      pileCoords: definition.pileCoords.map(copyIPoint),

      pilePenetration: definition.pilePenetration,
      height: definition.height,

      basePoint: copyIPoint(definition.basePoint),
      rotation: copyIPoint(definition.rotation),
      offset: copyIPoint(definition.offset),
      scale: copyIPoint(definition.scale),
    };
    this.material = material ?? getCurrentSolidMaterial();
  }

  static createObj(definition: pileCapParam, material: ISolidMaterial) {
    const threeMaterial = materialCache.getSolidMaterial(material);

    const shellSection = shellCrossSectionCache.loadStylefromCache(definition.capSectionId)!;
    const depth = shellSection.thickness;
    const beamSection = beamCrossSectionCache.loadStylefromCache(definition.pileSections)!;
    const diameter = (beamSection.parameter as ICircular).radius * 2;
    const { capCoords, pileCoords, height, pilePenetration } = definition;

    const graphicObj = getPileCapFromTemplate(capCoords, pileCoords, diameter, depth, height, pilePenetration, threeMaterial);
    const position = definition.basePoint;
    const rotation = definition.rotation;
    graphicObj.position.set(position.x, position.y, position.z);
    graphicObj.rotation.set(rotation.x, rotation.y, rotation.z);
    return graphicObj;
  }
  public async createGraphicObj() {
    if (this.graphicObj) {
      console.warn("Attention: Pile cap graphic object already created!!");
      return;
    }
    this.graphicObj = PileCapData.createObj(this.definition, this.material);
  }
  public cloneDefinition(): pileCapParam {
    const solid = super.cloneSolidDefinition();
    return {
      storeyId: this.definition.storeyId,
      name: this.definition.name,
      lnkObjIds: this.definition.lnkObjIds ? this.definition.lnkObjIds.slice() : [],
      materialType: this.definition.materialType,

      capSectionId: this.definition.capSectionId,
      capCoords: this.definition.capCoords.map(copyIPoint),

      pileSections: this.definition.pileSections,
      pileCoords: this.definition.pileCoords.map(copyIPoint),

      pilePenetration: this.definition.pilePenetration,
      height: this.definition.height,

      basePoint: copyIPoint(solid.basePoint),
      rotation: copyIPoint(solid.rotation),
      offset: copyIPoint(solid.offset),
      scale: copyIPoint(solid.scale),
    }
  }
  public createObject(definition?: pileCapParam, material?: ISolidMaterial): THREE.Mesh {
    return PileCapData.createObj(definition ?? this.definition, material ?? this.material);
  }

  public regenerateDefinition() {
    const threeMaterial = materialCache.getSolidMaterial(this.material);

    const shellSection = shellCrossSectionCache.loadStylefromCache(this.definition.capSectionId)!;
    const depth = shellSection.thickness;
    const beamSection = beamCrossSectionCache.loadStylefromCache(this.definition.pileSections)!;
    const diameter = (beamSection.parameter as ICircular).radius * 2;
    const { capCoords, pileCoords, height, pilePenetration } = this.definition;

    const graphicObj = getPileCapFromTemplate(capCoords, pileCoords, diameter, depth, height, pilePenetration, threeMaterial);

    this.graphicObj.material = threeMaterial;
    this.graphicObj.geometry = graphicObj.geometry;
    this.graphicObj.children.length = 0
    this.graphicObj.add(...graphicObj.children);

    const position = this.definition.basePoint;
    const rotation = this.definition.rotation;
    this.graphicObj.position.set(position.x, position.y, position.z);
    this.graphicObj.rotation.set(rotation.x, rotation.y, rotation.z);
    this.graphicObj.updateMatrixWorld();
  }

  public delete(): void {
    this.graphicObj.children.forEach((pile) => {
      (pile as THREE.Mesh).geometry.dispose();
    });
    super.delete();
  }
}
