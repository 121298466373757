import { v4 as uuid } from 'uuid'
import { EditActionType, editObjAction } from 'lib/events/objectdata';
import { GraphicProcessor } from 'lib/graphic-processor';
import { isLoadData } from 'lib/models/checktools';
import { Hypothesis, loadHypothesisList } from './hypothesis';
import { hypothesisDispatcher } from './dispatcher';
import { getHypothesisFromStandardCode } from 'lib/apis/standard-codes/hypothesis';
import { initSEEHypothesisManager } from './sse';
import { initWindHypothesisManager, windhypothesisManager } from './wind';
import { LoadStructuralData } from 'lib/models/structural/load';

export class HypothesisModelManager {

  private graphicProcessor: GraphicProcessor;

  private predefinedHypothesis: Set<Hypothesis>;
  private hypos: Map<loadHypothesisList | string, Hypothesis> = new Map();

  private hypoLoads: WeakMap<Hypothesis, LoadStructuralData[]> = new WeakMap();
  private loadHypo: WeakMap<LoadStructuralData, Hypothesis> = new WeakMap();

  public initialize(graphicProcessor: GraphicProcessor) {
    this.graphicProcessor = graphicProcessor;
    const dtMdlMngr = this.graphicProcessor.getDataModelManager();
    dtMdlMngr.subscribe(this.handleLoadActions);
  }
  public unInitialize() {
    const dtMdlMngr = this.graphicProcessor.getDataModelManager();
    dtMdlMngr.unsubscribe(this.handleLoadActions);
  }
  public clearAll() {
    this.hypos.clear();
    this.hypoLoads = new WeakMap();
    this.loadHypo = new WeakMap();
    initSEEHypothesisManager();
    initWindHypothesisManager();
  }

  private handleLoadActions = (action: editObjAction) => {
    if (action.type === EditActionType.EDIT_OBJ) {
      for (let obj of action.payload.objsEdited) {
        if (isLoadData(obj) && this.loadHypo.has(obj)) {
          const load = obj;
          const oldHypo = this.loadHypo.get(load);
          if (oldHypo && load.definition.hypothesisId !== oldHypo.uid) {
            const loads = this.hypoLoads.get(oldHypo);
            if (loads) {
              const index = loads.indexOf(load);
              if (index > -1) {
                loads.splice(index, 1);
              }
            }
            this.addLoad(load);
          }
        }
      }
    }
  }
  public addLoad(load: LoadStructuralData) {
    const hypoId = load.definition.hypothesisId;
    const hypo = this.getHypothesisByid(hypoId);
    console.assert(hypo !== undefined, "[HypothesisModelManager] addLoad() Hypothesis not found");
    if (hypo) {
      this.loadHypo.set(load, hypo);
      const loads = this.hypoLoads.get(hypo);
      if (loads && !loads.includes(load)) {
        loads.push(load);
        hypothesisDispatcher.dispatchAddLoad(hypo, load);
      }
    } else {
      const windHypo = windhypothesisManager.getWindHypothesisById(hypoId);
      if (windHypo) {
        windhypothesisManager.addHypothesisLoad(windHypo, load);
        hypothesisDispatcher.dispatchAddLoad(windHypo, load);
      }
    }
  }
  public deletedLoad(load: LoadStructuralData) {
    const hypo = this.loadHypo.get(load);
    console.assert(hypo !== undefined, "[HypothesisModelManager] deletedLoad() Hypothesis not found");
    if (hypo) {
      this.loadHypo.delete(load);
      const loads = this.hypoLoads.get(hypo);
      if (loads) {
        const index = loads.indexOf(load);
        if (index > -1) {
          loads.splice(index, 1);
          hypothesisDispatcher.dispatchDeleteLoad();
        }
      }
    } else {
      windhypothesisManager.removeHypothesisLoad(load);
      hypothesisDispatcher.dispatchDeleteLoad();
    }
  }

  // ----------------------------------------------------------------

  public getLoadsByHypotesis(hypo: Hypothesis): LoadStructuralData[] {
    let loads = this.hypoLoads.get(hypo);
    if (loads) return loads;
    return [];
  }
  public getHypothesisByid(hypoId: string): Hypothesis | undefined {
    let hypo = [...this.hypos.values()].find(h => h.uid === hypoId);
    if (hypo) return hypo;
  }
  public getHypothesisByName(hypoName: string): Hypothesis | undefined {
    let hypo = this.hypos.get(hypoName);
    if (hypo) return hypo;
  }

  // ----------------------------------------------------------------

  public isPredefined(hypoName: loadHypothesisList | string) {
    return this.getAllPredefinedHypothesis().some(h => h.name === hypoName);
  }
  public existHypothesis(hypothesis: Hypothesis) {
    const listIds = [...this.hypos.keys()];
    return listIds.includes(hypothesis.name);
  }
  public addHypothesis(hypo: Hypothesis) {
    console.assert(hypo.uid !== undefined, "[addHypothesis] Hypothesis without uid")
    if (hypo.uid === undefined) hypo.uid = uuid();
    this.hypos.set(hypo.name, hypo);
    this.hypoLoads.set(hypo, []);
  }
  public deleteHypothesis(hypo: Hypothesis) {
    if (this.hypos.delete(hypo.name)) {
      hypothesisDispatcher.dispatchDelete(hypo);
      const loads = this.hypoLoads.get(hypo)?.slice();
      if (loads) {
        for (let load of loads) {
          this.graphicProcessor.removeFromLayer(load);
        }
        const modelManager = this.graphicProcessor.getDataModelManager();
        modelManager.dispatchDeletedObjs(loads);
        const lyrManager = this.graphicProcessor.getLayerManager();
        lyrManager.layerObserver.dispatchLoadLayers();
      }
    }
  }
  public editHypothesis(hypothesis: Hypothesis, oldId: string) {
    const hypo = this.getHypothesisByid(oldId);
    if (hypo) {
      const loads = this.hypoLoads.get(hypo);
      this.hypos.delete(hypo.name);
      this.hypos.set(hypothesis.name, hypothesis);
      this.hypoLoads.delete(hypothesis);
      this.hypoLoads.set(hypothesis, loads ?? []);
      loads?.forEach(l => this.loadHypo.set(l, hypothesis));
      hypothesisDispatcher.dispatchEdit(hypothesis);
    }
  }

  public getAllHypothesis(): Hypothesis[] {
    return [...this.hypos.values()];
  }
  public getAllPredefinedHypothesis(): Hypothesis[] {
    return [...this.predefinedHypothesis.values()];
  }

  // ----------------------------------------------------------------

  public async loadPredefinedHypothesis() {
    const hypothesis = await getHypothesisFromStandardCode();
    this.predefinedHypothesis = new Set()
    hypothesis.forEach(h => this.predefinedHypothesis.add(h));
  }
}

export let hypothesisManager: HypothesisModelManager;
export function initHypothesisManager() {
  initSEEHypothesisManager();
  initWindHypothesisManager();
  hypothesisManager = new HypothesisModelManager();
  return hypothesisManager;
}
