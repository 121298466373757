import { IPoint } from "./types";

/**
 * Calculo del area de un array de puntos 2D, (sin el convenio...) donde los puntos forman una poliLinea cerrada sin autocruces.
 * Obviamente estamos calculando un area 2D, que es la mas habitual, sin tener en cuenta la componente Z para nada.
 *
 * @export
 * @param {(IPoint[] | THREE.Vector3[])} points
 * @returns {number}
 */
export function areaPoints(points: IPoint[] | THREE.Vector3[]): number {
  let area: number = 0;
  const numPoints: number = points.length - 1;
  for (let i: number = 0; i <= numPoints; i++) {
    const p1 = points[i];
    let p2 = points[i + 1];
    if (p2 === undefined) p2 = points[0];

    area += (p2.y * p1.x) - (p2.x * p1.y);
  }
  return area < 0 ? -area * 0.5 : area * 0.5;
}