/**
 * \file storey-list.tsx
 * Aqui se implementa la componente funcional de ReactJS denominada "StrucStoreyList", que soporta la tabla de pisos al
 * crear un new project, con los campos/columnas que tienen las etiquetas:
 * 
 * [1] POSITION	
 * [2] NAME
 * [3] HEIGHT (M)         <------ NaN problem.
 * [4] LEVEL (M)          <------ NaN problem.
 * [5] OCCUPANCY
 * [6] LIVE LOAD (N)      <------ NaN problem.
 * [7] DEAD LOAD (N)      <------ NaN problem.
 * [8] SUPPORT CONCRETE
 * [9] FLOOR CONCRETE
 * [10] DXF
 * 
 * Tenemos el problema de que al meter valores en ciertas columnas nos aparece el problema del NaN, pero eso parece que
 * lo puedo resolver metiendo en los <ContentEditable de las columnas anteiores un type="number" que me impedira hacer
 * el cabron metiendo cosas rarunas.
 */

import { DragEvent, useCallback } from 'react'
import Table from 'shared/components/ui/table'
import TrashIcon from 'shared/components/icons/trash'
import Tooltip from 'shared/components/ui/tooltip'
import ContentEditable from 'shared/components/ui/table/content-editable'
import { StrucStorey } from 'modules/struc/models/project'
import { euConcreteType, usConcreteType } from "modules/struc/models/codes"
import { getLiveLoadFromOccupancy, occupancyNameList } from 'modules/struc/utils/storeys'
import CopyIcon from 'modules/cad/components/icons/copy'

const selBorder = document.createElement('style');
selBorder.type = 'text/css';
selBorder.innerHTML = `.selBorder { border-color: rgba(252, 211, 77) !important; }`;

interface Props {
  storeys: StrucStorey[];
  onChange: (storey: StrucStorey) => void;
  changeName: (storey: StrucStorey, newName: string) => void;
  onDrop?: (source: number, destination: number) => void;
  onDelete?: (storey: StrucStorey) => void;
  onCopy?: (storey: StrucStorey) => void;
  concreteTypes: euConcreteType[] | usConcreteType[];
  className?: string;
}

/**
 * Este es el componente funcional de ReactJS para la tabla de datos de los storeys al crear un nuevo proyecto.
 *
 * @param {Props} { storeys, onChange, changeName, onDrop, onDelete, onCopy, concreteTypes, className }
 * @return {*} 
 */
const StrucStoreyList = ({ storeys, onChange, changeName, onDrop, onDelete, onCopy, concreteTypes, className }: Props) => {

  document.getElementsByTagName('head')[0].appendChild(selBorder);

  const handleDragStart = (event: DragEvent<HTMLTableCellElement>) => {
    const cellElement = event.currentTarget
    cellElement.classList.add('dragging');
    event.dataTransfer.setDragImage(cellElement.parentElement!, 0, 0);
    event.dataTransfer.setData('text/plain', cellElement.dataset.storeyNumber as string);
  }
  const handleDragEnd = (event: DragEvent<HTMLTableCellElement>) => {
    event.currentTarget.classList.remove('dragging');
  }
  const handleDragEnter = (event: DragEvent<HTMLTableCellElement>) => {
    event.currentTarget.classList.add('dragging-over');
  }
  const handleDragLeave = (event: DragEvent<HTMLTableCellElement>) => {
    event.currentTarget.classList.remove('dragging-over');
    event.currentTarget.parentElement!.classList.remove("selBorder");
  }
  const handleDragOver = (event: DragEvent<HTMLTableCellElement>) => {
    event.currentTarget.parentElement!.classList.add("selBorder");
    event.preventDefault();
  }

  const handleDrop = useCallback(
    (event: DragEvent<HTMLTableCellElement>) => {
      if (onDrop) {
        event.currentTarget.parentElement!.classList.remove("selBorder");
        event.currentTarget.classList.remove('dragging-over');
        const source = event.dataTransfer.getData('text/plain');
        const destination = event.currentTarget.dataset.storeyNumber as string;
        if (source !== destination) onDrop(+source, +destination);
      }
    },
    [onDrop]
  )

  // Aqui se devuelve el JSX con la parte de la form.
  return (
    <div className={className}>
      <Table>
        <Table.Header>
          <Table.HeaderCell>Position</Table.HeaderCell>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>height (m)</Table.HeaderCell>
          <Table.HeaderCell>Level (m)</Table.HeaderCell>
          <Table.HeaderCell>Occupancy</Table.HeaderCell>
          <Table.HeaderCell>Live load (N)</Table.HeaderCell>
          <Table.HeaderCell>Dead load (N)</Table.HeaderCell>
          <Table.HeaderCell>Support concrete</Table.HeaderCell>
          <Table.HeaderCell>Floor concrete</Table.HeaderCell>
          <Table.HeaderCell>DXF</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {storeys?.map((storey, index) => {
            const {
              id,
              floor_name,
              height,
              occupancy,
              level,
              live_load,
              dead_load,
              column_concrete_type,
              floor_concrete_type,
              dxf_name,
            } = storey
            return (
              <Table.Row key={id}>
                <Table.Cell
                  draggable
                  onDragStart={handleDragStart}
                  onDragEnd={handleDragEnd}
                  onDragEnter={handleDragEnter}
                  onDragLeave={handleDragLeave}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  data-storey-number={index}>
                  {index + 1}
                </Table.Cell>
                <Table.Cell>
                  <ContentEditable
                    value={floor_name}
                    onChange={value => changeName(storey, value)}
                    onDragStart={(ev) => {
                      ev.stopPropagation()
                    }}
                  />
                </Table.Cell>
                <Table.Cell>
                  <ContentEditable
                    type="number"
                    value={height}
                    onChange={value => {
                      const height = Number(value);
                      if (storey.height !== height) {
                        onChange({ ...storey, height });
                      }
                    }}
                  />
                </Table.Cell>
                <Table.Cell>{level.toFixed(2)}</Table.Cell>
                <Table.Cell>
                  <ContentEditable
                    value={occupancy}
                    onChange={value => {
                      const { dead_load, live_load } = getLiveLoadFromOccupancy(value);
                      onChange({
                        ...storey,
                        occupancy: value,
                        dead_load: Number(dead_load),
                        live_load: Number(live_load),
                      });
                    }}
                    type="select"
                    options={occupancyNameList}
                  />
                </Table.Cell>
                <Table.Cell>
                  <ContentEditable
                    type="number"
                    value={live_load}
                    onChange={value => onChange({ ...storey, live_load: Number(value) })}
                  />
                </Table.Cell>
                <Table.Cell>
                  <ContentEditable
                    type="number"
                    value={dead_load}
                    onChange={value => onChange({ ...storey, dead_load: Number(value) })}
                  />
                </Table.Cell>
                <Table.Cell>
                  <ContentEditable
                    value={column_concrete_type}
                    onChange={value => onChange({ ...storey, column_concrete_type: value })}
                    type="select"
                    options={concreteTypes}
                  />
                </Table.Cell>
                <Table.Cell>
                  <ContentEditable
                    value={floor_concrete_type}
                    onChange={value => onChange({ ...storey, floor_concrete_type: value })}
                    type="select"
                    options={concreteTypes}
                  />
                </Table.Cell>
                <Table.Cell>{dxf_name || 'No file attached'}</Table.Cell>
                <Table.Cell>
                  {onCopy && <Tooltip label={"Copy storey"}>
                    <button>
                      <CopyIcon
                        className="h-6 pr-2 cursor-pointer hover:text-yellow-400"
                        onClick={(ev: any) => {
                          ev.preventDefault();
                          onCopy(storey);
                        }}
                      />
                    </button>
                  </Tooltip>}
                  {onDelete && <Tooltip label={"Delete storey"}>
                    <button>
                      <TrashIcon
                        className="h-6 cursor-pointer hover:text-red-400"
                        onClick={(ev: any) => {
                          ev.preventDefault();
                          onDelete(storey);
                        }}
                      />
                    </button>
                  </Tooltip>}
                </Table.Cell>
              </Table.Row>
            )
          }).reverse()}
        </Table.Body>
      </Table >
    </div>
  )
}

export default StrucStoreyList
