import { useState, useContext, createContext, FC, useCallback, useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Role, User } from "shared/models/user";
import { authService } from "../../services/auth";
import { formatUser } from "../../utils/auth";

type Status =
  | "IDLE"
  | "SIGNIN_LOADING"
  | "SIGNIN_SUCCESS"
  | "SIGNUP_LOADING"
  | "SIGNUP_SUCCESS"
  | "CONFIRM_ACCOUNT_LOADING"
  | "CONFIRM_ACCOUNT_SUCCESS";

interface State {
  user: User | null;
  roles: Role[];
  loading: boolean;
  error: string | null;
  status: Status;
}
interface Actions {
  signIn: (username: string, password: string) => void;
  signUp: (username: string, password: string, email: string) => void;
  confirmAccount: (username: string, code: string) => void;
  signOut: () => void;
}

const AuthContext = createContext<State & Actions>({} as State & Actions);

export const AuthProvider: FC = ({ children }) => {

  const { push } = useHistory();
  const [error, setError] = useState(null);
  const [user, setUser] = useState<User | null>(null);
  const [roles, setRoles] = useState<Role[]>([]);
  const [status, setStatus] = useState<Status>("IDLE");

  const handleSignUp = useCallback(
    (user: any) => {
      if (user) {
        setStatus("SIGNUP_SUCCESS");
        push(`/confirm/${user?.username}`);
      }
    },
    [push]
  );
  const handleConfirmAccount = useCallback(
    (status: string) => {
      if (status === "SUCCESS") {
        setStatus("CONFIRM_ACCOUNT_SUCCESS");
        setError(null);
        push(`/`);
      }
    },
    [push]
  );

  const handleSignIn = useCallback(
    async (rawUser) => {
      const user = formatUser(rawUser);
      setUser(user);
      setStatus("SIGNIN_SUCCESS");
      if (user) {
        push("/struc/projects");
      } else {
        push("/");
      }
    },
    [push]
  );
  const handleSignOut = useCallback(() => {
    setUser(null);
    setStatus("IDLE");
    push("/");
  }, [push]);

  const handleError = useCallback((error) => {
    setError(error?.message);
    setStatus("IDLE");
  }, []);
  const signIn = useCallback(
    (username: string, password: string) => {
      setStatus("SIGNIN_LOADING");
      authService
        .signIn(username, password)
        .then(handleSignIn)
        .catch(handleError);
    },
    [handleSignIn, handleError]
  );
  const signUp = useCallback(
    (username: string, password: string, email: string) => {
      setStatus("SIGNUP_LOADING");
      authService
        .signUp(username, password, email)
        .then(handleSignUp)
        .catch(handleError);
    },
    [handleSignUp, handleError]
  );
  const confirmAccount = useCallback(
    (username: string, code: string) => {
      setStatus("CONFIRM_ACCOUNT_LOADING");
      authService
        .confirmAccount(username, code)
        .then(handleConfirmAccount)
        .catch(handleError);
    },
    [handleConfirmAccount, handleError]
  );

  const signOut = useCallback(() => {
    authService.signOut().then(handleSignOut).catch(handleError);
  }, [handleSignOut, handleError]);

  useEffect(() => {
    authService.checkAuth().then(handleSignIn).catch(handleError);
    return () => {
      setUser(null);
      setError(null);
    };
  }, [handleSignIn, handleError]);

  useEffect(() => {
    if (user) {
      authService.getRoles().then(setRoles);
    }
    return () => {
      setRoles([]);
    };
  }, [user]);

  const value = useMemo(
    () => ({
      user,
      signIn,
      signOut,
      signUp,
      confirmAccount,
      status,
      roles,
      loading:
        status === "SIGNIN_LOADING" ||
        status === "SIGNUP_LOADING" ||
        status === "CONFIRM_ACCOUNT_LOADING",
      error,
    }),
    [user, signIn, status, signOut, signUp, confirmAccount, error, roles]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>

};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (Object.keys(context).length === 0) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
