import { areaPoints } from "./area";
import { IInertia, IPoint } from "./types";

/**
 * Calculo de centroide de un array de puntos que forman una linea cerrada, 
 * el primero y el ultimo no se repiten
 *
 * @export
 * @param {IPoint[]} points
 * @returns {IPoint}
 */
export function calculateCentroidPoints(points: IPoint[]): IPoint | null {
  if (points.length > 2) {
    let area = 0;
    let centroideX = 0;
    let centroideY = 0;
    const numPoints = points.length;
    let z = 0;
    for (let i = 0; i < numPoints; i++) {
      const p1 = points[i];
      let p2 = points[i + 1];
      if (p2 === undefined) p2 = points[0];
      const auxVal = p1.x * p2.y - p2.x * p1.y;
      area += auxVal;
      centroideX += (p1.x + p2.x) * auxVal;
      centroideY += (p1.y + p2.y) * auxVal;
      z += p1.z;
    }
    area *= 0.5;
    return {
      x: centroideX / (6 * area),
      y: centroideY / (6 * area),
      z: z / numPoints
    };

    // Z adjust
    // const plane = { p1: points[0], p2: points[1], p3: points[2] };
    // return intersectLinePlane(
    //   { x: c.x, y: c.y, z: 0 },
    //   { x: c.x, y: c.y, z: 10 },
    //   plane
    // );
  }
  return null;
}

/**
 * Calcula el punto medio de una serie de puntos dados, que podrian no guardar relacion alguna.
 *
 * @export
 * @param {IPoint[]} points
 * @returns
 */
export function calculateMeanPoint(points: IPoint[]) {
  let centroideX: number = 0;
  let centroideY: number = 0;
  let centroideZ: number = 0;

  const numPoint: number = points.length;
  for (let i: number = 0; i < numPoint; i++) {
    centroideX += points[i].x;
    centroideY += points[i].y;
    centroideZ += points[i].z;
  }
  return {
    x: centroideX / numPoint,
    y: centroideY / numPoint,
    z: centroideZ / numPoint,
  };
}

/**
 * Calcula el segundo momento de area respecto al centro dado de una serie de puntos que delimitan un contorno cerrado,
 * sin repeticiones, ni autocruces ni cabronadas (el primero y el ultimo prohibido repetirlos!!!).
 * Los calculamos para los ejex X e Y y el producto de inercias XY. Se puede dar un centroide que especifica el centro
 * de gravedad tomado como origen para los calculos.
 * No importa el orden CCW ya que los datos devueltos se fuerzan a ser positivos.
 *
 * @export
 * @param {IPoint[]} points
 * @param {IPoint} [c]
 * @returns {IInertia}
 */
export function calculateInertiaMoments4Points(
  points: IPoint[],
  c?: IPoint
): IInertia {
  // Los calculos se efectuan moviendo los puntos iniciales a un hipotetico centro de masas/centroide/centro de gravedad dado por c.
  // Si no se da lo suponemos en el origen. Asi que lo primero es mover los puntos. OJO, que trabajamos en 2D.
  // Ademas asumimos que los puntos 1º y ultimo NO estan repetidos, y que no hay cruces, repeticiones y datos mierdosos...
  const vP: IPoint[] = [];
  const N = points.length;
  if (c) {
    const cx = c.x;
    const cy = c.y;
    for (let i = 0; i < N; ++i) {
      const p = points[i];
      const q: IPoint = {
        x: p.x - cx,
        y: p.y - cy,
        z: 0,
      };
      vP.push(q);
    }
  } else {
    for (let i = 0; i < N; ++i) {
      const p = points[i];
      const q: IPoint = {
        x: p.x,
        y: p.y,
        z: 0,
      };
      vP.push(q);
    }
  }

  // Se repite el primer vertice al final para simplificar los calculos, por eso no deben estar repetidos de antes.
  // Pero se usa el mismo N.
  const first: IPoint = {
    x: vP[0].x,
    y: vP[0].y,
    z: 0,
  };
  vP.push(first);

  // Ya empiezan los calculos, que hemos sacado de ambas wikipedias:
  // https://es.wikipedia.org/wiki/Segundo_momento_de_%C3%A1rea
  // https://en.wikipedia.org/wiki/Second_moment_of_area
  // El de la inglesa parece mas optimo...
  let inertiaX: number = 0;
  let inertiaY: number = 0;
  let inertiaXY: number = 0;

  for (let i = 0; i < N; ++i) {
    // Simplificando notacion tenemos la I para la posicion en curso i y la J para la siguiente, la i + 1.
    const pI = vP[i];
    const pJ = vP[i + 1];
    const xI = pI.x;
    const yI = pI.y;
    const xJ = pJ.x;
    const yJ = pJ.y;
    // Optimizaciones.
    const xIyJ = xI * yJ;
    const xJyI = xJ * yI;
    const kCommon = xIyJ - xJyI;

    inertiaX += kCommon * (yI * yI + yI * yJ + yJ * yJ);
    inertiaY += kCommon * (xI * xI + xI * xJ + xJ * xJ);
    inertiaXY += kCommon * (xIyJ + 2 * xI * yI + 2 * xJ * yJ + xJyI);
  }

  inertiaX *= 1 / 12;
  inertiaY *= 1 / 12;
  inertiaXY *= 1 / 24;

  // Correccion de signo.
  if (inertiaX < 0) {
    inertiaX *= -1;
  }
  if (inertiaY < 0) {
    inertiaY *= -1;
  }
  if (inertiaXY < 0) {
    inertiaXY *= -1;
  }

  return {
    x: inertiaX,
    y: inertiaY,
    xy: inertiaXY,
  };
}

/**
 * Calculo de los radios de giro para los ejes X e Y de una serie de puntos que delimitan un contorno cerrado y con datos correctos.
 *
 * @export
 * @param {IPoint[]} points
 * @param {IPoint} [c]
 * @returns {{
 *   gyradiusX: number,
 *   gyradiusY: number,
 * }}
 */
export function calculateGyrationRadius4Points(
  points: IPoint[],
  c?: IPoint
): {
  gyradiusX: number;
  gyradiusY: number;
} {
  // Para los calculos necesitamos las inercias en X e Y asi como el area.
  const moment = calculateInertiaMoments4Points(points, c);
  const area = areaPoints(points);

  let gyradiusX = Math.sqrt(moment.x / area);
  let gyradiusY = Math.sqrt(moment.y / area);

  return {
    gyradiusX,
    gyradiusY,
  };
}
