const PolygonIcon = ({ ...props }) => {
  return (
    <svg
      className="h-5 w-5"
      fill="currentColor"
      viewBox="0 0 100 100"
      {...props}
    >
      <path d="M81.32,33.25,58.38,16.92V5.36H41.64V16.92L18.7,33.25H5.36V50H11.2L20.44,77.9H19.31V94.64H36.06V89.06H64v5.58H80.69V77.9H79.58L88.82,50h5.82V33.25ZM47.22,10.94H52.8v5.58H47.22ZM16.52,44.43H10.94V38.85h5.58Zm14,44.63H24.91V83.48h5.57Zm44.63,0H69.54V83.48h5.57ZM73.7,77.9H64v5.58H36.06V77.9H26.32L17.08,50h5V37.7L44,22.1H56.05L77.9,37.68V50h5ZM89.06,44.43H83.48V38.85h5.58Z"></path>
    </svg>
  );
};

export default PolygonIcon;
