import * as THREE from "three";
import { ellipseArcBuffer } from "lib/math/ellipse-arc";
import { IPoint } from "lib/math/types";
import { adjustDrawRange, updateObjBboxBSphere } from ".";
import { getAuxMaterialLine, LineMaterialType } from "lib/materials";

export interface ellipseArcParam {
  center: IPoint;
  a: number;
  b: number;
  azimutO: number;
  azimutStart: number;
  azimutEnd: number;
  plane: IPoint;
}

export function ellipseArcCreate(
  center: IPoint,
  a: number,
  b: number,
  azimutO: number,
  azimutStart: number,
  azimutEnd: number,
  plane?: IPoint,
  material?: LineMaterialType
): THREE.Line {
  if (plane === undefined) plane = { x: 0, y: 0, z: 0 };

  const coords: Float32Array = ellipseArcBuffer(
    center,
    a,
    b,
    azimutO,
    azimutStart,
    azimutEnd,
    plane
  );
  const points: THREE.BufferGeometry = new THREE.BufferGeometry();
  points.setAttribute("position", new THREE.Float32BufferAttribute(coords, 3));
  const mat = material ?? getAuxMaterialLine();
  const elipseArc: THREE.Line = new THREE.Line(points, mat);
  if (mat.type !== "LineBasicMaterial") elipseArc.computeLineDistances();
  adjustDrawRange(elipseArc);
  updateObjBboxBSphere(elipseArc);

  return elipseArc;
}
