import { ellipseParam } from "lib/geometries/ellipse";
import { GraphicProcessor } from "lib/graphic-processor";
import { ILineMaterial } from "lib/materials";
import { objDataType } from "lib/models/types";
import { EllipseData } from "lib/models/primitives/ellipse";
import { cadOpType } from "lib/operations/factory";
import { createCommand } from "./create-base";

export class EllipseCommand extends createCommand {
  
  public createdData: EllipseData;
  protected opType = cadOpType.ELLIPSE;
  protected objType = objDataType.ELLIPSE;
  protected param: ellipseParam;
  protected material: ILineMaterial | undefined;

  constructor(params: ellipseParam, layerId: string, graphicProc: GraphicProcessor, material?: ILineMaterial) {
    super(layerId, graphicProc);
    this.param = params;
    this.material = material;
  }
}