import { LinealDimensionData, linealDimensionParam } from "lib/models/dimension/lineal-dim";
import { objDataType } from "lib/models/types";
import { GraphicProcessor } from "../../graphic-processor";
import { cadOpType } from "../../operations/factory";
import { EditDataCommand } from "../edition/edit-base";
import { createCommand } from "../primitives/create-base";

export class LinearDimensionCommand extends createCommand {

  public createdData: LinealDimensionData;
  protected opType = cadOpType.LINEARDIM;
  protected objType = objDataType.LINEARDIM;
  protected param: linealDimensionParam;
  protected material: undefined;

  constructor(params: linealDimensionParam, layerId: string, graphicProc: GraphicProcessor) {
    super(layerId, graphicProc);
    this.param = params;
  }
}

export class LinealDimensionEditDataCommand extends EditDataCommand {

  protected opType = cadOpType.EDITLINEARDIM;

  constructor(obj: LinealDimensionData, newDefinition: linealDimensionParam | null, graphicProcessor: GraphicProcessor) {
    super(obj, newDefinition, null, graphicProcessor);
  }
}