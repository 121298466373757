
import { useEffect, useReducer } from "react";
import { CompositeNode } from "lib/helpers/composite-tree";
import { BeamCrossSection } from "lib/models-struc/cross-sections-shape/beam-cross-sections/beamcs-shapes";
import { beamCrossSectionCache } from "lib/models-struc/cross-sections-shape/beam-cross-sections/cache";
import { getBeamCrossSectionInfoprop } from "lib/models-struc/cross-sections-shape/beam-cross-sections/types";
import { cacheAction, CacheActionType } from "lib/styles/style-cache";
import { BeamCSItem, IPanelSetting, itemType } from "modules/cad/components/sidebar/context";

interface beamCrossSectionState {
  beamCSNodes: CompositeNode<BeamCSItem>;
}

function buildBeamCrossSectionTree(): beamCrossSectionState {
  const bcsList = beamCrossSectionCache.getAllLoadedStyles().sort((a, b) => a.name.localeCompare(b.name));
  const bcsNode = new CompositeNode("Beam cross section", { type: itemType.BEAMCS } as BeamCSItem);
  for (const bcs of bcsList) {
    const node = bcsNode.addChildLeaf<BeamCSItem>(bcs.name, { info: bcs, type: itemType.BEAMCS })
    node.id = bcs.styleId;
  }
  return { beamCSNodes: bcsNode };
}

const beamReducer = (state: beamCrossSectionState, action: cacheAction<BeamCrossSection>): beamCrossSectionState => {
  switch (action.type) {
    case CacheActionType.DELETE_CACHE: {
      const { id } = action.payload;
      const node = state.beamCSNodes.getNodeById(id);
      if (node) {
        state.beamCSNodes.removeChild(node);
      }
      return { beamCSNodes: state.beamCSNodes.cloneTree() };
    }
    case CacheActionType.SAVE_CACHE: {
      const { id, style } = action.payload;
      if (!state.beamCSNodes.getNodeById(id)) {
        const node = state.beamCSNodes.addChildLeaf<BeamCSItem>(style.name, { info: style, type: itemType.BEAMCS });
        node.id = id;
      }
      return { beamCSNodes: state.beamCSNodes.cloneTree() };
    }
    case CacheActionType.UPDATE_CACHE: {
      const { id, style } = action.payload;
      const node = state.beamCSNodes.getNodeById<BeamCSItem>(id);
      if (node) {
        node.props = { info: style, type: itemType.BEAMCS }
        if (node.name !== style.name) {
          node.name = style.name;
          return { beamCSNodes: state.beamCSNodes.cloneTree() };
        }
      }
      return { beamCSNodes: state.beamCSNodes.cloneTree() };
    }
    default:
      throw new Error(`Action is not defined.`);
  }
}

export function useBeamCrossSections() {

  const [beamState, beamDispatch] = useReducer(beamReducer, {}, buildBeamCrossSectionTree);

  useEffect(() => {
    beamCrossSectionCache.subscribe(beamDispatch);
    return () => {
      beamCrossSectionCache?.unsubscribe(beamDispatch);
    }
  }, []);

  return {
    beamCrossSectionsNodes: beamState.beamCSNodes,
  }
}

export const getPropSettingsFromBeamCS = (beamCSParams: BeamCrossSection): IPanelSetting<BeamCrossSection> => {
  return {
    title: "Beam cross section",
    setting: {
      propValue: getBeamCrossSectionInfoprop(beamCSParams),
      propCallback: (s) => {
        beamCrossSectionCache.updateStyle(beamCSParams.styleId, s)
      }
    }
  }
}