import { GraphicProcessor } from "lib/graphic-processor";
import { copyIPoint } from "lib/math/point";
import { footerParam, stripfootingParam } from "lib/models-struc/types/footer";
import { objDataType } from "lib/models/types";
import { cadOpType } from "lib/operations/factory";
import { createStructuralElementCommand } from "./struc-simple";
import { footerBuilder } from "lib/operations/structural/footer";
import { getFooterLenghtAndWidthFromColumn } from "lib/geometries/structural/footer";
import { getFooterOrPileCapBasePointFromColumn } from "lib/geometries/structural/column";
import { EditBaseStructCommand } from "./struc-edit-base";
import { isColumnData } from "lib/models/checktools";
import { getDataModelBuilder } from "lib/models/model-creator/datamodel-factory";
import { FooterData } from "lib/models/structural/footer";
import { StripFootingData } from "lib/models/structural/stripfooter";
import { IPoint } from "lib/math/types";
import { IPolylineParam } from "lib/math/line";
import { CadCommand } from "../base";

export class FooterCommand extends CadCommand {

  protected opType = cadOpType.FOOTER;
  protected elemType: objDataType.FOOTER = objDataType.FOOTER;
  protected footingCmd: FootingCommand;
  protected stripFootingCmd: StripFootingCommand;

  get createdObjs() {
    return [
      ...this.footingCmd.createdObjs, 
      ...this.stripFootingCmd.createdObjs
    ];
  }

  constructor(footers: footerBuilder[], graphicProc: GraphicProcessor) {
    super(graphicProc);
    for (let param of footers) {
      const obj = param.supportObjData[0];
      if (isColumnData(obj)) {
        const { length, width } = getFooterLenghtAndWidthFromColumn(param.width, obj.definition);
        const basePoint = getFooterOrPileCapBasePointFromColumn(obj.definition);
        this.footingCmd = new FootingCommand(
          param.shellSectionId,
          width, length,
          basePoint,
          this.graphicProcessor,
        )
      } else {
        const { x, y, z } = obj.definition.basePoint;
        this.stripFootingCmd = new StripFootingCommand(
          obj.definition.ptos2D,
          param.shellSectionId,
          param.width,
          { x, y, z: z - obj.definition.height },
          this.graphicProcessor,
        )
      }
    }
  }

  public execute() {
    this.footingCmd.execute();
    this.stripFootingCmd.execute();
    const modelManager = this.graphicProcessor.getDataModelManager();
    modelManager.dispatchAddedObjs(this.createdObjs);
    const lyrManager = this.graphicProcessor.getLayerManager();
    lyrManager.layerObserver.dispatchLoadLayers();
  }

  public unExecute(): void {
    this.footingCmd.unExecute();
    this.stripFootingCmd.unExecute();
    const modelManager = this.graphicProcessor.getDataModelManager();
    modelManager.dispatchDeletedObjs(this.createdObjs);
    const lyrManager = this.graphicProcessor.getLayerManager();
    lyrManager.layerObserver.dispatchLoadLayers();
  }

  public delete() {
    this.footingCmd.delete();
    this.stripFootingCmd.delete();
  }
}
export class FootingCommand extends createStructuralElementCommand {

  protected opType: cadOpType.FOOTER;
  protected elemType: objDataType.FOOTER = objDataType.FOOTER;
  protected structuralParam: footerParam[];
  public createdObjs: FooterData[] = [];

  constructor(
    shellSectionId: string,
    width: number,
    length: number,
    basePoint: IPoint,
    graphicProc: GraphicProcessor,
  ) {

    const strucMng = graphicProc.getStructuralModelManager();
    const storey = strucMng.currBuilding.storeys[0];
    const layer = strucMng.getLayerByStoreyIdAndStructuralType(storey.id, objDataType.FOOTER);
    super(storey.id, layer.id, graphicProc);

    this.structuralParam.push({
      storeyId: storey.id,
      name: strucMng.currBuilding.getNextFooterName(),
      lnkObjIds: [],
      materialType: storey.columnConcreteType,

      shellSectionId: shellSectionId,
      width: width,
      length: length,

      basePoint: copyIPoint(basePoint),
      rotation: copyIPoint({ x: 0, y: 0, z: 0 }),
      offset: copyIPoint({ x: 0, y: 0, z: 0 }),
      scale: copyIPoint({ x: 1, y: 1, z: 1 }),
    });
  }

  public execute() {
    if (this.createdObjs.length === 0) {
      for (let structuralParam of this.structuralParam) {
        const objData = getDataModelBuilder(objDataType.FOOTER, structuralParam) as FooterData;
        objData.createGraphicObj();
        this.graphicProcessor.addToLayer(objData, this.layerId);
        this.createdObjs.push(objData);
      }
    } else {
      for (let createdObj of this.createdObjs) {
        this.graphicProcessor.addToLayer(createdObj, this.layerId);
      }
    }
    const modelManager = this.graphicProcessor.getDataModelManager();
    modelManager.dispatchAddedObjs(this.createdObjs);
    const lyrManager = this.graphicProcessor.getLayerManager();
    lyrManager.layerObserver.dispatchLoadLayers();
  }

  public delete() {
    this.structuralParam.length = 0;
    this.createdObjs.length = 0;
  }
}
export class StripFootingCommand extends createStructuralElementCommand {

  protected opType: cadOpType.FOOTER;
  protected elemType: objDataType.STRIPFOOTER = objDataType.STRIPFOOTER;
  protected structuralParam: stripfootingParam[];
  public createdObjs: StripFootingData[] = [];

  constructor(
    ptos2d: IPolylineParam,
    shellSectionId: string,
    width: number,
    basePoint: IPoint,
    graphicProc: GraphicProcessor,
  ) {

    const strucMng = graphicProc.getStructuralModelManager();
    const storey = strucMng.currBuilding.storeys[0];
    const layer = strucMng.getLayerByStoreyIdAndStructuralType(storey.id, objDataType.FOOTER);
    super(storey.id, layer.id, graphicProc);

    this.structuralParam.push({
      storeyId: storey.id,
      name: strucMng.currBuilding.getNextFooterName(),
      lnkObjIds: [],
      materialType: storey.columnConcreteType,

      shellSectionId: shellSectionId,
      stripLine: ptos2d,
      width: width,

      basePoint: copyIPoint(basePoint),
      rotation: copyIPoint({ x: 0, y: 0, z: 0 }),
      offset: copyIPoint({ x: 0, y: 0, z: 0 }),
      scale: copyIPoint({ x: 1, y: 1, z: 1 }),
    });
  }

  public execute() {
    if (this.createdObjs.length === 0) {
      for (let structuralParam of this.structuralParam) {
        const objData = getDataModelBuilder(objDataType.STRIPFOOTER, structuralParam) as StripFootingData;
        objData.createGraphicObj();
        this.graphicProcessor.addToLayer(objData, this.layerId);
        this.createdObjs.push(objData);
      }
    } else {
      for (let createdObj of this.createdObjs) {
        this.graphicProcessor.addToLayer(createdObj, this.layerId);
      }
    }
    const modelManager = this.graphicProcessor.getDataModelManager();
    modelManager.dispatchAddedObjs(this.createdObjs);
    const lyrManager = this.graphicProcessor.getLayerManager();
    lyrManager.layerObserver.dispatchLoadLayers();
  }

  public delete() {
    this.structuralParam.length = 0;
    this.createdObjs.length = 0;
  }
}

// *******************************************************************

export class FooterEditDataCommand extends EditBaseStructCommand {
  protected opType = cadOpType.EDITFOOTER;
}
export class StripFooterEditDataCommand extends EditBaseStructCommand {
  protected opType = cadOpType.EDITSTRIPFOOTER;
}
