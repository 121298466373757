import { circleParam } from "lib/geometries/circle";
import { ellipseParam } from "lib/geometries/ellipse";
import { isPolygonData } from "lib/models/checktools";
import { LineData } from "../models/primitives/line";
import { PolygonData } from "../models/primitives/polygon";
import { isBetweenDirection, lineAngle2p, mecDirectionMathDirection, normalizeAngle, ORIENT } from "./angles";
import { arcParam } from "./arc";
import { IBox, isPointInBox } from "./box";
import { vectorDist2D } from "./distance";
import { isBiggerThan, isEqual, isSmallerThan, isZero, round, vector3Equals } from "./epsilon";
import { IPlane } from "./plane";
import { addIpoint, copyIPoint, crossProductIpoint, dotProductIpoint, magnitudeIpoint, mulIpoint, normalizeIpoint, pointLinePositionXY, substractIpoint } from "./point";
import { polygon } from "./polygon";
import { rotatePoint } from "./rotate";
import { IPoint, ISegment } from "./types";

// Para facilitar las cosas internamente usamos este tipo para un punto 3D, que se repetira en el exterior. No exportarlo.
type P2D = [number, number];

/** Intersección de dos rectas 2D.
 * http://paulbourke.net/geometry/pointlineplane/Helpers.cs
 * @export
 * @param {IPoint} l1Start Inicio recta 1
 * @param {IPoint} l1End  Fin recta 1
 * @param {IPoint} l2Start  Inicio recta 2
 * @param {IPoint} l2End Fin recta 2
 * @param {boolean} [strict] Solo devuelve la interseción cuando se encuentra en la recta. False devuelve la intersección cuando haría falta enxtenderlas
 * @param {boolean} [adjustZ] Como la z es resultado de la proyección de la intersección sobre el primer segmento, usar este argumento para obtener una z fruto de la media de ambas proyecciones
 * @returns {{ p: IPoint, ua: number, ub: number }}  La intersección - OJO!!!, la z es resultado de ser proyectada sobre el primer segmento.
 */
export function intersect2D(
  l1Start: IPoint,
  l1End: IPoint,
  l2Start: IPoint,
  l2End: IPoint,
  strict?: boolean,
  adjustZ?: boolean
): { p: IPoint | null; ua: number; ub: number } | null {
  const denominator: number =
    (l2End.y - l2Start.y) * (l1End.x - l1Start.x) -
    (l2End.x - l2Start.x) * (l1End.y - l1Start.y);
  const nA: number =
    (l2End.x - l2Start.x) * (l1Start.y - l2Start.y) -
    (l2End.y - l2Start.y) * (l1Start.x - l2Start.x);
  const nB: number =
    (l1End.x - l1Start.x) * (l1Start.y - l2Start.y) -
    (l1End.y - l1Start.y) * (l1Start.x - l2Start.x);

  // CASOS:
  // Denominator = 0 -> Paralelas. Si además nA y nB = 0 -> superpuestas
  // ua < 0 || ua > 1 && ub < 0 || ub > 1 --> Lineas se cortan fuera de los segmentos (modo estricto no hay intersección)
  // ua > 1 -> la intersección se produce fuera de la primera línea, estirando por el segundo punto
  // 0 >= ua <= 1 -> la intersección se produce en la primera línea
  // ua < 0 -> la intersección se produce fuera de la primera línea, estirando por el primer punto.

  if (isZero(denominator)) {
    // Paralelas o Coincidentes (misma dirección)
    if (vector3Equals(l1Start, l2Start) && vector3Equals(l1End, l2End))
      return { p: null, ua: 0, ub: 0 }; // Lineas coincidentes = sentido
    if (vector3Equals(l1Start, l2End) && vector3Equals(l1End, l2Start))
      return { p: null, ua: 0, ub: 0 }; // Lineas coincidentes != sentido
    if (vector3Equals(l1Start, l2Start))
      return { p: copyIPoint(l1Start), ua: 0, ub: 0 }; // Lineas coincidentes en 1 punto (Inicio l1 == Inicio l2)
    if (vector3Equals(l1Start, l2End))
      return { p: copyIPoint(l1Start), ua: 0, ub: 1 }; // Lineas coincidentes en 1 punto (Inicio l1 == Fin l2)
    if (vector3Equals(l1End, l2Start))
      return { p: copyIPoint(l1End), ua: 1, ub: 0 }; // Lineas coincidentes en 1 punto (Fin l1 == Inicio l2)
    if (vector3Equals(l1End, l2End))
      return { p: copyIPoint(l1End), ua: 1, ub: 1 }; // Lineas coincidentes en 1 punto (Fin l1 == Fin l2)
    if (isZero(nA) && isZero(nB)) return { p: null, ua: 0, ub: 0 }; // Lineas coincidentes
    return null; // Lineas paralelas
  }

  const ua: number = nA / denominator;
  const ub: number = nB / denominator;

  if (strict) {
    // Casos de Líneas que No se cortan
    if (isSmallerThan(ua, 0)) return null;
    if (isBiggerThan(ua, 1)) return null;
    if (isSmallerThan(ub, 0)) return null;
    if (isBiggerThan(ub, 1)) return null;
  }

  const intersectPoint = {
    x: l1Start.x + ua * (l1End.x - l1Start.x),
    y: l1Start.y + ua * (l1End.y - l1Start.y),
    z: l1Start.z + ua * (l1End.z - l1Start.z),
  };

  if (adjustZ) {
    // Proyectamos la z sobre la otra recta
    const z2: number = l2Start.z + ub * (l2End.z - l2Start.z);
    intersectPoint.z = z2;
    // Z media
    intersectPoint.z = (intersectPoint.z + z2) * 0.5;
  }
  return { p: intersectPoint, ua, ub };
}
export function intersect2DWithZ(l1Start: IPoint, l1End: IPoint, l2Start: IPoint, l2End: IPoint, strict?: boolean): { p0: IPoint | null, p1: IPoint | null, ua: number, ub: number } | null {
  const inter = intersect2D(l1Start, l1End, l2Start, l2End, strict);
  if (inter) {
    if (inter.p) {
      const ua = inter.ua;
      const ub = inter.ub;
      // Miro si intersecta 3D.
      const z1 = l1Start.z + (ua * (l1End.z - l1Start.z));
      const z2 = l2Start.z + (ub * (l2End.z - l2Start.z));
      if (isEqual(z1, z2)) {
        return { p0: inter.p, p1: inter.p, ua, ub }; // Lineas se cortan
      } else {
        const p0 = { x: inter.p.x, y: inter.p.y, z: z1 };
        const p1 = { x: inter.p.x, y: inter.p.y, z: z2 };
        return { p0, p1, ua, ub }; // Lineas se cruzan
      }
    } else {
      return { p0: null, p1: null, ua: inter.ua, ub: inter.ub };
    }
  }
  return null
}
/** Intersección de dos rectas 3D
 * En el caso de devolver un array vacío se trata de líneas paralelas
 * En el caso de devolver un punto (array de 3 posiciones) se cortan, si devuelven dos puntos (array de 6 posiciones) se cruzan
 * Los dos puntos corresponden al mínimo segmento que une ambas líneas
 *
 * @export
 * @param {IPoint} l1Start -Comienzo linea 1
 * @param {IPoint} l1End -Final línea 1
 * @param {IPoint} l2Start - comienzo línea 2
 * @param {IPoint} l2End - final línea 2
 * @param {boolean} [strict] - considera segmentos(true) o líneas(false)
 * @returns {Array<number>}
 */
export function intersect3D(l1Start: IPoint, l1End: IPoint, l2Start: IPoint, l2End: IPoint, strict?: boolean): { p0: IPoint, p1: IPoint, ua: number, ub: number } | null {
  if (strict === undefined) { strict = false; }

  const p13: IPoint = substractIpoint(l1Start, l2Start);
  const p43: IPoint = substractIpoint(l2End, l2Start);

  // comprobar si son paralelas
  const uv1 = normalizeIpoint(substractIpoint(l1End, l1Start));
  const uv2 = normalizeIpoint(substractIpoint(l2End, l2Start));
  const cpuv12: IPoint = crossProductIpoint(uv1, uv2);
  // Las líneas son paralelas
  if (isZero(magnitudeIpoint(cpuv12))) return null;

  const p21: IPoint = substractIpoint(l1End, l1Start);
  const d1343: number = dotProductIpoint(p13, p43);
  const d4321: number = dotProductIpoint(p43, p21);
  const d1321: number = dotProductIpoint(p13, p21);
  const d4343: number = dotProductIpoint(p43, p43);
  const d2121: number = dotProductIpoint(p21, p21);

  const denom: number = d2121 * d4343 - d4321 * d4321;
  if (isZero(denom)) return null;

  const numer: number = d1343 * d4321 - d1321 * d4343;
  let mua: number = numer / denom;
  let mub: number = (d1343 + d4321 * (mua)) / d4343;

  // Interseccion fuera de los segmentos en modo estricto.
  if (strict && (mua < 0 || mua > 1 || mub < 0 || mub > 1)) {
    if (mua < 0) mua = 0;
    else if (mua > 1) mua = 1;
    if (mub < 0) mub = 0;
    else if (mub > 1) mub = 1;
  }

  const resultSegmentPoint1: IPoint = { x: (l1Start.x + mua * p21.x), y: (l1Start.y + mua * p21.y), z: (l1Start.z + mua * p21.z) };
  const resultSegmentPoint2: IPoint = { x: (l2Start.x + mub * p43.x), y: (l2Start.y + mub * p43.y), z: (l2Start.z + mub * p43.z) };
  return { p0: resultSegmentPoint1, p1: resultSegmentPoint2, ua: mua, ub: mub };
}

export function isLineIntersectBbox(lineP1: IPoint, lineP2: IPoint, bbox: IBox): boolean {
  const p1IsInside = isPointInBox(lineP1, bbox);
  const p2IsInside = isPointInBox(lineP2, bbox);
  if (p1IsInside && p2IsInside) return true;

  const lineTop = { p1: { x: bbox.min.x, y: bbox.max.y, z: 0 }, p2: { x: bbox.max.x, y: bbox.max.y, z: 0 } };
  const lineBott = { p1: { x: bbox.min.x, y: bbox.min.y, z: 0 }, p2: { x: bbox.max.x, y: bbox.min.y, z: 0 } };
  const lineLeft = { p1: { x: bbox.min.x, y: bbox.min.y, z: 0 }, p2: { x: bbox.min.x, y: bbox.max.y, z: 0 } };
  const lineRight = { p1: { x: bbox.max.x, y: bbox.min.y, z: 0 }, p2: { x: bbox.max.x, y: bbox.max.y, z: 0 } };

  const intersTOP = intersect2D(lineTop.p1, lineTop.p2, lineP1, lineP2, true);
  const intersBOT = intersect2D(lineBott.p1, lineBott.p2, lineP1, lineP2, true);
  const intersLEF = intersect2D(lineLeft.p1, lineLeft.p2, lineP1, lineP2, true);
  const intersRIG = intersect2D(lineRight.p1, lineRight.p2, lineP1, lineP2, true);

  if (intersTOP && intersTOP.p) return true;
  if (intersBOT && intersBOT.p) return true;
  if (intersRIG && intersRIG.p) return true;
  if (intersLEF && intersLEF.p) return true;
  return false;
}
export function isLineBCrossLineA(lineAPoints: IPoint[], lineBPoints: IPoint[]): boolean {
  const lenA = lineAPoints.length - 1; // aristas de lineaA
  const lenB = lineBPoints.length - 1; // aristas de LineaB
  let lineA0: IPoint;
  let lineA1: IPoint;
  let lineB0: IPoint;
  let lineB1: IPoint;
  for (let j = 0; j < lenB; j++) {
    // Arista j en B
    lineB0 = lineBPoints[j];
    lineB1 = lineBPoints[j + 1];
    for (let i = 0; i < lenA; i++) {
      // Arista i en A
      lineA0 = lineAPoints[i];
      lineA1 = lineAPoints[i + 1];
      const currIntersect = intersect2D(lineB0, lineB1, lineA0, lineA1, true);
      if (currIntersect && currIntersect.p) {
        return true;
      }
    }
  }
  return false;
}
export function getLineBCrossLineA(
  lineAPoints: IPoint[],
  lineBPoints: IPoint[]
): { p: IPoint | null; ua: number; ub: number } | null {
  const lenA = lineAPoints.length - 1; // aristas de lineaA
  const lenB = lineBPoints.length - 1; // aristas de LineaB
  let lineA0: IPoint;
  let lineA1: IPoint;
  let lineB0: IPoint;
  let lineB1: IPoint;
  for (let j = 0; j < lenB; j++) {
    // Arista j en B
    lineB0 = lineBPoints[j];
    lineB1 = lineBPoints[j + 1];
    for (let i = 0; i < lenA; i++) {
      // Arista i en A
      lineA0 = lineAPoints[i];
      lineA1 = lineAPoints[i + 1];
      const currIntersect = intersect2D(lineB0, lineB1, lineA0, lineA1, true);
      if (currIntersect && currIntersect.p) {
        return currIntersect;
      }
    }
  }
  return null;
}


// http://paulbourke.net/geometry/pointlineplane/
export function intersectLinePlane(lineStart: IPoint, lineEnd: IPoint, plane: IPlane): IPoint | null {
  const vectLine = substractIpoint(lineEnd, lineStart);
  const p3Ls = substractIpoint(plane.p3, lineStart);
  const N = crossProductIpoint(substractIpoint(plane.p3, plane.p1), substractIpoint(plane.p2, plane.p1));

  const n = (N.x * p3Ls.x) + (N.y * p3Ls.y) + (N.z * p3Ls.z);
  if (isZero(n)) console.warn("n es 0, ¿los puntos de definición del plano no serán colineares, no?");

  const d = (N.x * vectLine.x) + (N.y * vectLine.y) + (N.z * vectLine.z);
  if (isZero(d)) return null; // son paralelas

  let u = n / d;
  u = round(u, 9);
  return addIpoint(lineStart, mulIpoint(vectLine, u));
}

interface IIntersectionResults {
  left: IPoint[][];
  right: IPoint[][];
}

export function intersectObjects(intersector: LineData, intersectees: (LineData | PolygonData)[]): (IIntersectionResults | null)[] {
  const res = new Array<IIntersectionResults | null>(intersectees.length);
  if (!intersectsItself(intersector.definition.points)) {
    for (let i = 0; i < intersectees.length; i++) {
      res[i] = intersect(intersector.definition.points, intersectees[i]);
    }
  }
  return res;
}
// export function intersectsPolylineItself(points: IPolylineParam): boolean {
//   const closed = vector3Equals(points[0], points[points.length - 1]);
//   for (let i = 0, length = points.length; i < length - 1; i++) {
//     const p1 = points[i];
//     const p2 = points[i + 1];
//     for (let j = i + 1; j < length - 1; j++) {
//       const ip1 = points[j];
//       const ip2 = points[j + 1];
//       const ip = intersect2D(p1, p2, ip1, ip2, true);
//       if (ip && ip.p && !vector3Equals(ip.p, p2)) {
//         if (closed && i === 0) {
//           if (!vector3Equals(ip.p, p1)) {
//             return true;
//           }
//         } else {
//           return true;
//         }
//       }
//     }
//   }
//   return false;
// }
export function intersectsItself(points: IPoint[]): boolean {
  const closed = vector3Equals(points[0], points[points.length - 1]);
  for (let i = 0, length = points.length; i < length - 1; i++) {
    const p1 = points[i];
    const p2 = points[i + 1];
    for (let j = i + 1; j < length - 1; j++) {
      const ip1 = points[j];
      const ip2 = points[j + 1];
      const ip = intersect2D(p1, p2, ip1, ip2, true);
      if (ip && ip.p && !vector3Equals(ip.p, p2)) {
        if (closed && i === 0) {
          if (!vector3Equals(ip.p, p1)) {
            return true;
          }
        } else {
          return true;
        }
      }
    }
  }
  return false;
}
function intersect(masterLine: IPoint[], slaveLineData: LineData | PolygonData): IIntersectionResults | null {
  function SewFun(arr: IPoint[][]): void {
    if (arr.length > 1) {
      const l = arr[0];
      const l2 = arr[arr.length - 1];
      if (vector3Equals(l[0], l2[l2.length - 1])) {
        arr.splice(arr.length - 1, 1);
        arr.splice(0, 1, l2.concat(l));
      }
    }
  }

  const results: IIntersectionResults = {
    left: [],
    right: [],
  };
  let right: boolean | undefined;

  let slaveLine: IPoint[] = [];
  if (isPolygonData(slaveLineData)) {
    const {
      center,
      radius,
      sides,
      inscribed,
      angleO,
      plane,
    } = slaveLineData.definition;
    slaveLine = polygon(center, radius, sides, inscribed, angleO, plane);
    slaveLine.push(slaveLine[0]); // Add last point to CLOSE geometry
  } else {
    slaveLine = slaveLineData.definition.points;
  }

  let vertexesR: IPoint[] = [];
  let vertexesL: IPoint[] = [];
  const vertexesTemp: IPoint[] = [];

  const numEdges = slaveLine.length - 1;
  for (let i = 0; i < numEdges; i++) {
    const p0 = slaveLine[i];
    const p1 = slaveLine[i + 1];

    // Hay que intersectar la línea con todas las lineas de la intersectora.
    // Guardamos todas las intersecciones y las ordenamos por distancia a p0.
    // Determinamos si v0 es right o left y empezamos a meter.
    const intersections: Array<{
      point: IPoint;
      distance: number;
      segment: ISegment;
    }> = [];

    for (let j = 0; j < masterLine.length - 1; j++) {
      const m0 = masterLine[j];
      const m1 = masterLine[j + 1];

      const intersect = intersect2D(p0, p1, m0, m1, true);
      if (intersect && intersect.p) {
        intersections.push({
          point: intersect.p,
          distance: vectorDist2D(p0, intersect.p),
          segment: { p1: m0, p2: m1 },
        });
      }
    }

    if (right === undefined) {
      // A un array temporal y decidiremos luego.
      vertexesTemp.push(p0);
    } else if (right) {
      vertexesR.push(p0);
    } else {
      vertexesL.push(p0);
    }

    if (intersections.length > 0) {
      if (intersections.length > 1) {
        intersections.sort((a, b) => a.distance - b.distance);
      }

      if (right === undefined) {
        right =
          pointLinePositionXY(
            p0,
            intersections[0].segment.p1,
            intersections[0].segment.p2
          ) > 0;
        if (right) {
          vertexesR = vertexesTemp.slice();
        } else {
          vertexesL = vertexesTemp.slice();
        }
        vertexesTemp.length = 0;
      }

      for (let j = 0; j < intersections.length; j++) {
        if (right) {
          vertexesR.push(intersections[j].point);
          results.right.push(vertexesR.slice());
          vertexesR.length = 0;
          vertexesL.push(intersections[j].point);
        } else {
          vertexesL.push(intersections[j].point);
          results.left.push(vertexesL.slice());
          vertexesL.length = 0;
          vertexesR.push(intersections[j].point);
        }
        right = !right;
      }
    }

    if (i === slaveLine.length - 2) {
      if (right) {
        vertexesR.push(p1);
      } else {
        vertexesL.push(p1);
      }
    }
  }
  if (right) {
    results.right.push(vertexesR);
  } else {
    results.left.push(vertexesL);
  }

  SewFun(results.right);
  SewFun(results.left);

  if (results.left.length === 0 && results.right.length === 0) {
    return null;
  } else {
    return results;
  }
}


export function intersectLineCircle(lStart: IPoint, lEnd: IPoint, circle: Pick<circleParam, "center" | "radius">, strict?: boolean): IPoint[] {
  const { center, radius } = circle;
  const v = substractIpoint(lEnd, lStart);
  const vCenter = substractIpoint(lStart, center);
  const a = Math.pow(v.x, 2) + Math.pow(v.y, 2);
  const b = 2 * (v.x * vCenter.x + v.y * vCenter.y);
  const c = Math.pow(center.x, 2) + Math.pow(center.y, 2) + Math.pow(lStart.x, 2) + Math.pow(lStart.y, 2) - 2 * (center.x * lStart.x + center.y * lStart.y) - Math.pow(radius, 2);
  const i = b * b - 4 * a * c;
  const res: IPoint[] = [];
  if (isSmallerThan(i, 0)) {
    // No hay intersección
  } else if (isZero(i)) {
    // Una intersección
    const mu = -b / (2 * a);
    if (!strict || Math.abs(mu) < 1) {
      res.push({
        x: lStart.x + mu * (v.x),
        y: lStart.y + mu * (v.y),
        z: lStart.z + mu * (v.z),
      });
    }
  } else if (isBiggerThan(i, 0)) {
    // Dos intersecciones
    let mu = (-b + Math.sqrt(Math.pow(b, 2) - 4 * a * c)) / (2 * a);
    if (!strict || (0 < mu && mu < 1)) {
      res.push({
        x: lStart.x + mu * (v.x),
        y: lStart.y + mu * (v.y),
        z: lStart.z + mu * (v.z),
      });
    }
    mu = (-b - Math.sqrt(Math.pow(b, 2) - 4 * a * c)) / (2 * a);
    if (!strict || (0 < mu && mu < 1)) {
      res.push({
        x: lStart.x + mu * (v.x),
        y: lStart.y + mu * (v.y),
        z: lStart.z + mu * (v.z),
      });
    }
  }
  return res;
}
export function intersectArcLine(lStart: IPoint, lEnd: IPoint, arc: arcParam, strictArc?: boolean, strictLine?: boolean): IPoint[] {

  const { center, radius, azimutO, angleCenter } = arc;
  const angleStart = mecDirectionMathDirection(azimutO);
  const angleEnd = mecDirectionMathDirection(azimutO + angleCenter);
  const direction = angleCenter > 0 ? ORIENT.CW : ORIENT.CCW;

  const res: IPoint[] = [];
  const intersections = intersectLineCircle(lStart, lEnd, { center, radius }, strictLine);
  if (strictArc && intersections.length > 0) {
    for (const i of intersections) {
      const angleP1 = normalizeAngle(lineAngle2p(center, i));
      if (isBetweenDirection(angleP1, angleStart, angleEnd, direction)) {
        res.push(i);
      }
    }
  } else {
    return intersections;
  }
  return res;
}
export function intersectCircleCircle(circleA: Pick<circleParam, "center" | "radius">, circleB: Pick<circleParam, "center" | "radius">): IPoint[] {
  const centerA = circleA.center;
  const radiusA = circleA.radius;
  const centerB = circleB.center;
  const radiusB = circleB.radius;
  const distance = vectorDist2D(centerA, centerB);
  if (isBiggerThan(distance, Math.abs(radiusA) + Math.abs(radiusB))) { // Demasiado alejados
    return [];
  }
  if (isSmallerThan(distance, Math.abs(Math.abs(radiusA) - Math.abs(radiusB)))) { // Uno contiene a otro
    return [];
  }
  if (isEqual(distance, 0) && isEqual(Math.abs(radiusA), Math.abs(radiusB))) { // Son el mismo
    return [];
  }
  // Basado en las enseñanzas de Bourke @see http://paulbourke.net/geometry/circlesphere/tvoght.c

  // 'point 2' is the point where the line through the circle
  // intersection points crosses the line between the circle
  // centers. 

  // Determine the distance from point 0 to point 2.
  const a = ((radiusA * radiusA) - (radiusB * radiusB) + (distance * distance)) / (2 * distance);

  const dx = centerB.x - centerA.x;
  const dy = centerB.y - centerA.y;

  //  Determine the coordinates of point 2.
  const p2 = {
    x: centerA.x + (dx * a / distance),
    y: centerA.y + (dy * a / distance),
    z: centerA.z,
  };
  // Determine the distance from point 2 to either of the intersection points.
  let aux = (radiusA * radiusA) - (a * a);
  if (isZero(aux)) aux = 0;
  const distanceP2 = Math.sqrt(aux);
  // Now determine the offsets of the intersection points from point 2.
  const rx = -dy * (distanceP2 / distance);
  const ry = dx * (distanceP2 / distance);
  // Now determine the offsets of the intersection points.
  return [
    {
      x: p2.x + rx,
      y: p2.y + ry,
      z: centerA.z,
    }, {
      x: p2.x - rx,
      y: p2.y - ry,
      z: centerA.z,
    },
  ];
}
export function intersectArcArc(arcA: arcParam, arcB: arcParam, strictArcA?: boolean, strictArcB?: boolean): IPoint[] {
  const res: IPoint[] = [];
  const intersections = intersectCircleCircle(arcA, arcB);

  const angleAStart = mecDirectionMathDirection(arcA.azimutO);
  const angleAEnd = mecDirectionMathDirection(arcA.azimutO + arcA.angleCenter);
  const directionA = arcA.angleCenter > 0 ? ORIENT.CW : ORIENT.CCW;

  const angleBStart = mecDirectionMathDirection(arcB.azimutO);
  const angleBEnd = mecDirectionMathDirection(arcB.azimutO + arcB.angleCenter);
  const directionB = arcB.angleCenter > 0 ? ORIENT.CW : ORIENT.CCW;

  intersections.sort((a, b) => Math.abs(normalizeAngle(lineAngle2p(arcA.center, a)) - angleAEnd) - Math.abs(normalizeAngle(lineAngle2p(arcA.center, b)) - angleAEnd));
  if ((strictArcA || strictArcB) && intersections.length > 0) {
    for (const i of intersections) {
      let validA = false;
      if (strictArcA) {
        const angleP1 = normalizeAngle(lineAngle2p(arcA.center, i));
        if (isBetweenDirection(angleP1, angleAStart, angleAEnd, directionA)) {
          validA = true;
        }
      } else {
        validA = true;
      }
      let validB = false;
      if (strictArcB) {
        const angleP1 = normalizeAngle(lineAngle2p(arcB.center, i));
        if (isBetweenDirection(angleP1, angleBStart, angleBEnd, directionB)) {
          validB = true;
        }
      } else {
        validB = true;
      }
      if (validA && validB) {
        res.push(i);
      }
    }
  } else {
    return intersections;
  }
  return res;
}
export function intersectLineEllipse(lStart: IPoint, lEnd: IPoint, ellipse: ellipseParam, strict?: boolean): IPoint[] {
  // Se considera que la elipse esta en el 0,0 y sin rotar (rotamos la línea respecto a esa posición)
  const { center, a, b, azimutO } = ellipse;
  const res: IPoint[] = [];

  let lS: IPoint = copyIPoint(lStart);
  let lE: IPoint = copyIPoint(lEnd);
  const vOri = substractIpoint(lE, lS);
  lS = substractIpoint(lStart, center);
  lE = substractIpoint(lEnd, center);
  const angleStart = mecDirectionMathDirection(azimutO);
  if (angleStart !== 0) {
    lE = rotatePoint(lE, 0, 0, -angleStart);
    lS = rotatePoint(lS, 0, 0, -angleStart);
  }
  const v = substractIpoint(lE, lS);

  const A = Math.pow(v.x, 2) / a / a + Math.pow(v.y, 2) / b / b;
  const B = 2 * (v.x * lS.x) / a / a + 2 * (v.y * lS.y) / b / b;
  const C = Math.pow(lS.x, 2) / a / a + Math.pow(lS.y, 2) / b / b - 1;
  const i = B * B - 4 * A * C;

  if (i < 0) {
    // no hay intersección

  } else if (i === 0) {
    // una intersección

    const mu = -B / 2 / A;
    if (!strict || (0 < mu && mu < 1)) {
      res.push({
        x: lStart.x + mu * (vOri.x),
        y: lStart.y + mu * (vOri.y),
        z: lStart.z + mu * (vOri.z),
      });
    }
  } else if (i > 0) {
    // Dos intersecciones

    // first intersection
    let mu = (-B + Math.sqrt(Math.pow(B, 2) - 4 * A * C)) / 2 / A;
    if (!strict || (0 < mu && mu < 1)) {
      res.push({
        x: lStart.x + mu * (vOri.x),
        y: lStart.y + mu * (vOri.y),
        z: lStart.z + mu * (vOri.z),
      });
    }
    // second intersection
    mu = (-B - Math.sqrt(Math.pow(B, 2) - 4 * A * C)) / 2 / A;
    if (!strict || (0 < mu && mu < 1)) {
      res.push({
        x: lStart.x + mu * (vOri.x),
        y: lStart.y + mu * (vOri.y),
        z: lStart.z + mu * (vOri.z),
      });
    }
  }
  for (let r of res) {
    if (angleStart !== 0) {
      r = rotatePoint(r, 0, 0, angleStart);
    }
  }
  return res;
}
export function intersectEllipseArcLine(lStart: IPoint, lEnd: IPoint, centerEllipse: IPoint, radiusA: number, radiusB: number, azimutO: number, angleStart: number, angleEnd: number, direction: ORIENT, strict?: boolean): IPoint[] {
  const res: IPoint[] = [];
  const ellipse: ellipseParam = {
    center: centerEllipse,
    a: radiusA,
    b: radiusB,
    azimutO,
    plane: { x: 0, y: 0, z: 0 },
  }
  const intersections = intersectLineEllipse(lStart, lEnd, ellipse, true);
  if (strict && intersections.length > 0) {
    for (const i of intersections) {
      const angleP1 = normalizeAngle(lineAngle2p(centerEllipse, i));
      if (isBetweenDirection(angleP1, angleStart, angleEnd, direction)) {
        res.push(i);
      }
    }
  } else {
    return intersections;
  }
  return res;
}

export function intersectionEllipseEllipse(ellipse1: ellipseParam, ellipse2: ellipseParam): IPoint[] {
  // Abandono pronto...
  if (fastExit4IntersectionEllipseEllipse(ellipse1, ellipse2)) {
    return [] as IPoint[];
  }

  /*
    Llegados aqui podria haber interseccion.
    Necesitaremos saber cuando un punto esta dentro (into), en (on) o fuera (out) de una elipse.
    Como saberlo: De forma similar a una circunferencia, una elipse es el lugar geometrico de 2 puntos focales.
    Es decir que los puntos de esa curva (ON) mantienen una suma de distancias CTE y exactamente igual a esos focos:
    P(x, y) pertenece a la elipse si dist(P, F1) + dist(P, F2) === K.

                                |                     ^
                                |                     | b
                                |                     v
           -----F2--------------+--------------F1-----
                                |<--------a---------->
                                |
                                |

    Los focos estan separados del origen una distancia en X que es:

            c = SQRT(a*a - b*b)

    Obviamente tomando a >= b.
  */

  const a1 = ellipse1.a;
  const b1 = ellipse1.b;
  const W1 = mecDirectionMathDirection(ellipse1.azimutO);

  const a1b1 = a1 * b1;
  const a1Sq = a1 * a1;
  const b1Sq = b1 * b1;
  // Distancia focal.
  const c1 = (a1 > b1) ? Math.sqrt(a1Sq - b1Sq) : Math.sqrt(b1Sq - a1Sq);

  // A partir del angulo w en radianes devolvemos las coordenadas correspondientes.  
  const ellipse1GeneratorXY = (w: number): P2D => {
    const cosw = Math.cos(w);
    const sinw = Math.sin(w);
    const b2Cos2 = b1Sq * cosw * cosw;
    const a2Sin2 = a1Sq * sinw * sinw;

    const radicand = b2Cos2 + a2Sin2;
    if (isEqual(radicand, 0.0, 0.0001)) {
      console.warn("Problemas!!!.");
    }

    const polarRadius = a1b1 / Math.sqrt(radicand);
    // Y de aqui sacamos la X y la Y.
    const x = polarRadius * cosw;
    const y = polarRadius * sinw;

    return [x, y] as P2D;
  };

  const a2 = ellipse2.a;
  const b2 = ellipse2.b;
  const W2 = mecDirectionMathDirection(ellipse2.azimutO);

  const a2b2 = a2 * b2;
  const a2Sq = a2 * a2;
  const b2Sq = b2 * b2;
  // Distancia focal.
  const c2 = (a2 > b2) ? Math.sqrt(a2Sq - b2Sq) : Math.sqrt(b2Sq - a2Sq);

  // A partir del angulo w en radianes devolvemos las coordenadas correspondientes.  
  const ellipse2GeneratorXY = (w: number): P2D => {
    const cosw = Math.cos(w);
    const sinw = Math.sin(w);
    const b2Cos2 = b2Sq * cosw * cosw;
    const a2Sin2 = a2Sq * sinw * sinw;

    const radicand = b2Cos2 + a2Sin2;
    if (isEqual(radicand, 0.0, 0.0001)) {
      console.warn("Problemas!!!.");
    }

    const polarRadius = a2b2 / Math.sqrt(radicand);
    // Y de aqui sacamos la X y la Y.
    const x = polarRadius * cosw;
    const y = polarRadius * sinw;

    return [x, y] as P2D;
  };

  // console.log("E1 ===> (" + ellipse1.center.x + ", " + ellipse1.center.y + ")   a:" + a1 + "   b:" + b1 + "   W:" + (W1 * 180 / Math.PI));
  // console.log(`E1 ===> (${ellipse2.center.x}, ${ellipse2.center.y})   a:${a2}   b:${b2}   W:${(W2 * 180 / Math.PI)}`);

  // if (false) {
  //   // Pintemos lo que nos llega...
  //   // Generacion: Con angulo en el intervalo [0, 2PI) se irian generando bolitas a partir del eje X en sentido CCW.
  //   const incAlpha = 0.01;
  //   const xE1 = ellipse1.center.x;
  //   const yE1 = ellipse1.center.y;
  //   const xE2 = ellipse2.center.x;
  //   const yE2 = ellipse2.center.y;

  //   for (let alpha = 0; alpha < 2 * Math.PI; alpha += incAlpha) {
  //     let [x, y] = ellipse1GeneratorXY(alpha);
  //     let p: IPoint = { x, y, z: 0 };
  //     let q = rotatePoint(p, 0, 0, +W1);
  //     // Traslacion.
  //     q.x += xE1;
  //     q.y += yE1;
  //     graphicProcessor000.addDebugBall(q.x, q.y, 0, 0.5, "", 0xFF0000);

  //     [x, y] = ellipse2GeneratorXY(alpha);
  //     p.x = x;
  //     p.y = y;
  //     p.z = 0;
  //     q = rotatePoint(p, 0, 0, +W2);
  //     // Traslacion.
  //     q.x += xE2;
  //     q.y += yE2;
  //     graphicProcessor000.addDebugBall(q.x, q.y, 0, 0.5, "", 0x0000FF);
  //   }

  //   // Y centros y ambos focos.
  //   graphicProcessor000.addDebugBall(xE1, yE1, 0, 2.5, "", 0xFF0000);
  //   graphicProcessor000.addDebugBall(xE2, yE2, 0, 2.5, "", 0x0000FF);

  //   // Ojo al problema del semieje a no siempre sobre x...
  //   let f1: IPoint = (a1 > b1) ? { x: c1, y: 0, z: 0 } : { x: 0, y: c1, z: 0 };
  //   f1 = rotatePoint(f1, 0, 0, +W1);
  //   graphicProcessor000.addDebugBall(f1.x + xE1, f1.y + yE1, 0, 2.5, "", 0xFF0000);
  //   // Y ahora el opuesto.
  //   f1 = (a1 > b1) ? { x: -c1, y: 0, z: 0 } : { x: 0, y: -c1, z: 0 };
  //   f1 = rotatePoint(f1, 0, 0, +W1);
  //   graphicProcessor000.addDebugBall(f1.x + xE1, f1.y + yE1, 0, 2.5, "", 0xFF0000);

  //   // Y ahora para la segunda elipse.
  //   let f2: IPoint = (a2 > b2) ? { x: c2, y: 0, z: 0 } : { x: 0, y: c2, z: 0 };
  //   f2 = rotatePoint(f2, 0, 0, +W2);
  //   graphicProcessor000.addDebugBall(f2.x + xE2, f2.y + yE2, 0, 2.5, "", 0x0000FF);
  //   f2 = (a2 > b2) ? { x: -c2, y: 0, z: 0 } : { x: 0, y: -c2, z: 0 };
  //   f2 = rotatePoint(f2, 0, 0, +W2);
  //   graphicProcessor000.addDebugBall(f2.x + xE2, f2.y + yE2, 0, 2.5, "", 0x0000FF);
  // }

  // [1] Trasladamos el centro de la elipse 1 al origen y la misma traslacion la hacemos a centro de la elipse 2.
  const translationX = -ellipse1.center.x;
  const translationY = -ellipse1.center.y;
  // A partir de ahora la elipse 1 esta en (0, 0) y la 2 esta en otro sitio.
  const ellipse2Center: IPoint = {
    x: ellipse2.center.x + translationX,
    y: ellipse2.center.y + translationY,
    z: 0,
  };

  // [2] Rotamos la elipse 1 para alinearla con los ejes.
  // Ademas esa rotacion se la aplicamos al centro trasladado de la elipse2, pero OJO tambien habria que variar su azimut!!!.
  const ellipse2CenterRotated = rotatePoint(ellipse2Center, 0, 0, -W1);
  const xCR2 = ellipse2CenterRotated.x;
  const yCR2 = ellipse2CenterRotated.y;
  // Como rotamos W2???. Para rotar la segunda ellipse, solo hay que hacer +W2 - W1.
  const beta = normalizeAngle(W2 - W1);

  // if (false) {
  //   // Pintamos con ambas matrices trasladas y convenientemente rotadas.
  //   const incAlpha = 0.01;

  //   for (let alpha = 0; alpha < 2 * Math.PI; alpha += incAlpha) {
  //     let [x, y] = ellipse1GeneratorXY(alpha);
  //     // Primera elipse:
  //     // Ojo que rotamos 0. Es decir que no rotamos nada por ser canonicos.
  //     // Y la traslacion no es necesaria por estar en origen (0, 0).
  //     graphicProcessor000.addDebugBall(x, y, 0, 0.5, "CW", 0xFF0000);

  //     // Segunda ellipse, esta trasladada y rotada.
  //     [x, y] = ellipse2GeneratorXY(alpha);
  //     const p: IPoint = { x, y, z: 0 };      
  //     const q = rotatePoint(p, 0, 0, beta);
  //     // Traslacion.
  //     q.x += xCR2;
  //     q.y += yCR2;
  //     graphicProcessor000.addDebugBall(q.x, q.y, 0, 0.5, "CW", 0x0000FF);
  //   }

  //   // Y sus centros y focos.
  //   graphicProcessor000.addDebugBall(0, 0, 0, 2.5, "", 0xFF0000);
  //   graphicProcessor000.addDebugBall(xCR2, yCR2, 0, 2.5, "", 0x0000FF);

  //   // Ojo al problema del semieje a no siempre sobre x...
  //   let f1: IPoint = (a1 > b1) ? { x: c1, y: 0, z: 0 } : { x: 0, y: c1, z: 0 };
  //   graphicProcessor000.addDebugBall(f1.x, f1.y, 0, 2.5, "", 0xFF0000);
  //   // Y ahora el opuesto.
  //   f1 = (a1 > b1) ? { x: -c1, y: 0, z: 0 } : { x: 0, y: -c1, z: 0 };
  //   graphicProcessor000.addDebugBall(f1.x, f1.y, 0, 2.5, "", 0xFF0000);

  //   // Y ahora para la segunda elipse.
  //   let f2: IPoint = (a2 > b2) ? { x: c2, y: 0, z: 0 } : { x: 0, y: c2, z: 0 };
  //   f2 = rotatePoint(f2, 0, 0, beta);
  //   graphicProcessor000.addDebugBall(f2.x + xCR2, f2.y + yCR2, 0, 2.5, "", 0x0000FF);
  //   f2 = (a2 > b2) ? { x: -c2, y: 0, z: 0 } : { x: 0, y: -c2, z: 0 };
  //   f2 = rotatePoint(f2, 0, 0, beta);
  //   graphicProcessor000.addDebugBall(f2.x + xCR2, f2.y + yCR2, 0, 2.5, "", 0x0000FF);
  // }

  // Esta es la precision para acercarnos a la "cascara" de la segunda elipse.
  const epsilon = 0.001;

  // Ojo que esto es para la ELIPSE 2.
  // Devuelve una distancia entre el punto dado y el centro del circulo que podemos utilizar para saber si el punto
  // esta en la CASCARA del circulo:
  // Si la distancia es positiva estamos fuera.
  // Si es negativa estamos dentro.
  // Si es 0 estamos en la pomada.
  // Como calculo esa distancia? Como la suma de las distancas del punto a ambos focos de la elipse 2.
  // Con que la comparo? Con la distancia "canonica" que debiera ser siempre 2*a (con a el maximo entre las a y b originales).
  // Asi que necesito ambos focos para la elipse 2 y la distancia como el doble de la longitud del maximo eje: lugar geometrico.
  // Distancia.
  const locusDistance = (a2 > b2) ? 2 * a2 : 2 * b2;
  // Ambos focos.
  let focus2Pos: IPoint = (a2 > b2) ? { x: c2, y: 0, z: 0 } : { x: 0, y: c2, z: 0 };
  focus2Pos = rotatePoint(focus2Pos, 0, 0, beta);
  focus2Pos.x += xCR2;
  focus2Pos.y += yCR2;
  let focus2Neg = (a2 > b2) ? { x: -c2, y: 0, z: 0 } : { x: 0, y: -c2, z: 0 };
  focus2Neg = rotatePoint(focus2Neg, 0, 0, beta);
  focus2Neg.x += xCR2;
  focus2Neg.y += yCR2;

  const getDistance2Ellipse2 = (xy: P2D): number => {
    const p: IPoint = { x: xy[0], y: xy[1], z: 0 };
    const dist2Focus2Pos = vectorDist2D(p, focus2Pos);
    const dist2Focus2Neg = vectorDist2D(p, focus2Neg);
    const dist = dist2Focus2Pos + dist2Focus2Neg;
    if (isEqual(dist, locusDistance, epsilon)) {
      return 0;
    } else {
      if (dist < locusDistance) {
        return -dist;
      } else {
        return +dist;
      }
    }
  };

  // Biseccion con los puntos y sus distancias.
  const maxIterations = 10;
  const bisection = (pA: P2D, pB: P2D, dA: number, dB: number): P2D => {
    let numIterations = 0;
    while (numIterations < maxIterations) {
      const [xA, yA] = pA;
      const [xB, yB] = pB;
      // Calculamos el punto medio.
      const pC: P2D = [0.5 * (xA + xB), 0.5 * (yA + yB)];
      let dC = getDistance2Ellipse2(pC);
      if (dC === 0) {
        return pC;
      }

      // Preparamos la siguiente iteracion.
      ++numIterations;
      if (dA * dC > 0) {
        pA = pC;
      } else {
        pB = pC;
      }
    }

    debugger;
    const pC: P2D = [0.5 * (pA[0] + pB[0]), 0.5 * (pA[1] + pB[1])];
    return pC;
  };

  // Calculo final
  // =======================================================================================================================

  // Resultado.
  const vPoints: IPoint[] = [];

  // Recorremos la elipse 1 (centrada y alineada) para ver si sus puntos caen o no en la elipse 2, pero OJO, EXACTAMENTE en "corteza".
  const incAlpha = 0.001;
  let prevPoint = ellipse1GeneratorXY(2 * Math.PI - incAlpha);
  let prevDist = getDistance2Ellipse2(prevPoint);

  for (let alpha = 0; alpha < 2 * Math.PI; alpha += incAlpha) {
    // El punto sobre la elipse.
    const point = ellipse1GeneratorXY(alpha);
    const distance2Circle = getDistance2Ellipse2(point);

    const x = point[0];
    const y = point[1];
    if (distance2Circle === 0) {
      // console.log("El punto (" + x + ", " + y + ") esta en la cascara de elipse2!!!.");
      const p: IPoint = { x, y, z: 0 };
      // Rotamos en sentido contrario.
      const q = rotatePoint(p, 0, 0, +W1);
      // Y deshacemos la traslacion.
      q.x -= translationX;
      q.y -= translationY;
      vPoints.push(q);
    } else {
      const prod = distance2Circle * prevDist;
      if (prod > 0) {
        // Todo correcto, estamos fuera o bien dentro pero podemos seguir avanzando...
      } else {
        if (prod < 0) {
          // const xPrev = prevPoint[0];
          // const yPrev = prevPoint[1];
          // const distBetweenPoints = Math.sqrt((x - xPrev) * (x - xPrev) + (y - yPrev) * (y - yPrev));

          // // Hay cambio de signo: Tocaria algun tipo de biseccion.
          // if (prevDist > 0) {
          //   console.log("Pasamos de fuera a dentro entre los puntos (" + xPrev + ", " + yPrev + ") y (" + x + ", " + y + ") ===> d:" + distBetweenPoints);
          // } else {
          //   console.log("Pasamos de dentro a fuera entre los puntos (" + xPrev + ", " + yPrev + ") y (" + x + ", " + y + ") ===> d:" + distBetweenPoints);
          // }

          const pC = bisection(prevPoint, point, prevDist, distance2Circle);
          // console.log("Solucion biseccionada (" + pC[0] + ", " + pC[1] + ")");
          const p: IPoint = { x: pC[0], y: pC[1], z: 0 };
          // Rotamos en sentido contrario.
          const q = rotatePoint(p, 0, 0, +W1);
          // Y deshacemos la traslacion.
          q.x -= translationX;
          q.y -= translationY;
          vPoints.push(q);
        } else {
          // Aqui llega cuando prevDist fue 0, asi que pasamos...
        }
      }
    }

    prevDist = distance2Circle;
    prevPoint = point;
  }

  // if (false) {
  //   for (let i = 0; i < vPoints.length; ++i) {
  //     const p = vPoints[i];
  //     graphicProcessor000.addDebugBall(p.x, p.y, 0, 2.5, "circ0", 0xFFFFFF);
  //   }
  // }

  return vPoints;
}

function fastExit4IntersectionEllipseEllipse(ellipse1: ellipseParam, ellipse2: ellipseParam): boolean {
  // Primera y RAPIDA discriminacion: Si sus centros estan a mayor distancia que sus radios maximos, entonces no hay interseccion.
  const distCenters = vectorDist2D(ellipse1.center, ellipse2.center);
  const distMaxFoc1 = Math.max(ellipse1.a, ellipse1.b);
  const distMaxFoc2 = Math.max(ellipse2.a, ellipse2.b)
  if (distCenters > distMaxFoc1 + distMaxFoc2) {
    // Aqui no hay interseccion, pues estan muy lejanas.
    return true;
  } else {
    // Podria estar una dentro de otra?.
    // Por ejemplo la minima esfera de E1 podria contener a la maxima esfera de E2, o bien la minima de E2 a la maxima de E1.
    const distMinFoc1 = Math.min(ellipse1.a, ellipse1.b);
    const distMinFoc2 = Math.min(ellipse2.a, ellipse2.b)

    if ((distMinFoc1 > distCenters + distMaxFoc2) || (distMinFoc2 > distCenters + distMaxFoc1)) {
      // Una esta dentro de otra.
      return true;
    }
  }

  return false;
}

export function intersectionEllipseCircle(ellipse: ellipseParam, circle: circleParam): IPoint[] {
  // Abandono pronto...
  if (fastExit4IntersectionEllipseCircle(ellipse, circle)) {
    return [] as IPoint[];
  }

  /**
   * Notas: Usamos polares para generar la elipse y luego aplicamos biseccion con sus puntos hasta
   * encontrar el punto de interseccion con el circulo.
   * Problema: Cuando son muy excentricas los puntos laterales quedan muy separados.
   * \ToDo: Cambiar el delta de alpha en funcion de excentricidad y posiblemente de separacion entre
   * puntos sucesivos...
   */

  // Esta vez por polares.
  const r = circle.radius;

  const a = ellipse.a;
  const b = ellipse.b;
  const W = mecDirectionMathDirection(ellipse.azimutO);

  const ab = a * b;
  const a2 = a * a;
  const b2 = b * b;

  // A partir del angulo w en radianes devolvemos las coordenadas correspondientes.  
  const ellipseGeneratorXY = (w: number): P2D => {
    const cosw = Math.cos(w);
    const sinw = Math.sin(w);
    const b2Cos2 = b2 * cosw * cosw;
    const a2Sin2 = a2 * sinw * sinw;

    const radicand = b2Cos2 + a2Sin2;
    if (isEqual(radicand, 0.0, 0.0001)) {
      console.warn("Problemas!!!.");
    }

    const polarRadius = ab / Math.sqrt(radicand);
    // Y de aqui sacamos la X y la Y.
    const x = polarRadius * cosw;
    const y = polarRadius * sinw;

    return [x, y] as P2D;
  };

  // if (false) {
  //   // Pintemos lo que nos llega...
  //   // Generacion: Con angulo en el intervalo [0, 2PI) se irian generando bolitas a partir del eje X en sentido CCW.
  //   const incAlpha = 0.01;
  //   const xC = circle.center.x;
  //   const yC = circle.center.y;  
  //   const xE = ellipse.center.x;
  //   const yE = ellipse.center.y;

  //   for (let alpha = 0; alpha < 2 * Math.PI; alpha += incAlpha) {
  //     const [x4E, y4E] = ellipseGeneratorXY(alpha);
  //     const p: IPoint = { x: x4E, y: y4E, z: 0 };
  //     const q = rotatePoint(p, 0, 0, +W);
  //     // Traslacion.
  //     q.x += xE;
  //     q.y += yE;
  //     graphicProcessor000.addDebugBall(q.x, q.y, 0, 0.5, "CW", 0xFF0000);

  //     const x4C = xC + (r * Math.cos(alpha));
  //     const y4C = yC + (r * Math.sin(alpha));
  //     graphicProcessor000.addDebugBall(x4C, y4C, 0, 0.5, "CW", 0x00FF00);
  //   }
  // }

  // [1] Trasladamos el centro de la ellipse al origen y aplicamos esa traslacion al centro del circulo.
  const translationX = -ellipse.center.x;
  const translationY = -ellipse.center.y;
  const circleCenter: IPoint = {
    x: circle.center.x + translationX,
    y: circle.center.y + translationY,
    z: 0,
  };

  // [2] Rotamos la ellipse para alinearla con los ejes (a con X y b con Y).
  // Esa rotacion se la pasamos al centro TRASLADADO del circulo, para mantener la coherencia. Ojo, que se rota en torno a Z.
  const circleCenterRotated = rotatePoint(circleCenter, 0, 0, -W);
  const xCR = circleCenterRotated.x;
  const yCR = circleCenterRotated.y;
  // const r2 = r * r; para la optimizacion...

  // Biseccion con los puntos y sus distancias.
  const maxIterations = 10;
  const bisection = (pA: P2D, pB: P2D, dA: number, dB: number): P2D => {
    let numIterations = 0;
    while (numIterations < maxIterations) {
      const [xA, yA] = pA;
      const [xB, yB] = pB;
      // Calculamos el punto medio.
      const pC: P2D = [0.5 * (xA + xB), 0.5 * (yA + yB)];
      let dC = getDistance2CircleShape(pC);
      if (dC === 0) {
        return pC;
      }

      // Preparamos la siguiente iteracion.
      ++numIterations;
      if (dA * dC > 0) {
        pA = pC;
      } else {
        pB = pC;
      }
    }

    debugger;
    const pC: P2D = [0.5 * (pA[0] + pB[0]), 0.5 * (pA[1] + pB[1])];
    return pC;
  };

  // FUNDAMENTAL: Esta es la precision que usamos para acercarnos al circulo.
  const epsilon = 0.001;

  // Devuelve una distancia entre el punto dado y el centro del circulo que podemos utilizar para saber si el punto
  // esta en la CASCARA del circulo:
  // Si la distancia es positiva estamos fuera.
  // Si es negativa estamos dentro.
  // Si es 0 estamos en la pomada.
  const getDistance2CircleShape = (xy: P2D): number => {
    const x = xy[0];
    const y = xy[1];
    let dx = x - xCR;
    let dy = y - yCR;
    dx *= dx;
    dy *= dy;
    const dist2Center = Math.sqrt(dx + dy);
    if (isEqual(dist2Center, r, epsilon)) {
      return 0;
    } else {
      if (dist2Center < r) {
        return -dist2Center;
      } else {
        return +dist2Center;
      }
    }
  };

  // if (false) {
  //   // Generacion: Con angulo en el intervalo [0, 2PI) se irian generando bolitas a partir del eje X en sentido CCW.
  //   const incAlpha = 0.01;
  //   for (let alpha = 0; alpha < 2 * Math.PI; alpha += incAlpha) {
  //     const [x4E, y4E] = ellipseGeneratorXY(alpha);
  //     graphicProcessor000.addDebugBall(x4E, y4E, 0, 0.5, "CW", 0xFF0000);

  //     const x4C = xCR + (r * Math.cos(alpha));
  //     const y4C = yCR + (r * Math.sin(alpha));

  //     graphicProcessor000.addDebugBall(x4C, y4C, 0, 0.5, "CW", 0x00FF00);
  //   }
  // }

  // Aqui va el resultado.
  const vPoints: IPoint[] = [];

  // Recorremos la elipse centrada y alineada para ver si sus puntos caen o no en el circulo dado, pero OJO, deben caer
  // en la corteza, no en el interior.
  const incAlpha = 0.001;
  let prevPoint = ellipseGeneratorXY(2 * Math.PI - incAlpha);
  let prevDist = getDistance2CircleShape(prevPoint);
  for (let alpha = 0; alpha < 2 * Math.PI; alpha += incAlpha) {
    // El punto sobre la elipse.
    const point = ellipseGeneratorXY(alpha);
    const distance2Circle = getDistance2CircleShape(point);

    const x = point[0];
    const y = point[1];
    if (distance2Circle === 0) {
      // console.log("El punto (" + x + ", " + y + ") esta en la cascara del circulo!!!.");
      const p: IPoint = { x, y, z: 0 };
      // Rotamos en sentido contrario.
      const q = rotatePoint(p, 0, 0, +W);
      // Y deshacemos la traslacion.
      q.x -= translationX;
      q.y -= translationY;
      vPoints.push(q);
    } else {
      const prod = distance2Circle * prevDist;
      if (prod > 0) {
        // Todo correcto, estamos fuera o bien dentro pero podemos seguir avanzando...
      } else {
        if (prod < 0) {
          // const xPrev = prevPoint[0];
          // const yPrev = prevPoint[1];
          // const distBetweenPoints = Math.sqrt((x - xPrev) * (x - xPrev) + (y - yPrev) * (y - yPrev));

          // Hay cambio de signo: Tocaria algun tipo de biseccion.
          // if (prevDist > 0) {
          //   console.log("Pasamos de fuera a dentro entre los puntos (" + xPrev + ", " + yPrev + ") y (" + x + ", " + y + ") ===> d:" + distBetweenPoints);
          // } else {
          //   console.log("Pasamos de dentro a fuera entre los puntos (" + xPrev + ", " + yPrev + ") y (" + x + ", " + y + ") ===> d:" + distBetweenPoints);
          // }

          const pC = bisection(prevPoint, point, prevDist, distance2Circle);
          // console.log("Solucion biseccionada (" + pC[0] + ", " + pC[1] + ")");
          const p: IPoint = { x: pC[0], y: pC[1], z: 0 };
          // Rotamos en sentido contrario.
          const q = rotatePoint(p, 0, 0, +W);
          // Y deshacemos la traslacion.
          q.x -= translationX;
          q.y -= translationY;
          vPoints.push(q);
        } else {
          // Aqui llega cuando prevDist fue 0, asi que pasamos...
        }
      }
    }

    prevDist = distance2Circle;
    prevPoint = point;
  }

  // if (false) {
  //   for (let i = 0; i < vPoints.length; ++i) {
  //     const p = vPoints[i];
  //     graphicProcessor000.addDebugBall(p.x, p.y, 0, 2.5, "circ0", 0xFFFFFF);
  //   }
  // }

  return vPoints;
}

/**
 * Si devuelve true no hay colision posible y podemos abandonar.
 *
 * @param {ellipseParam} ellipse
 * @param {circleParam} circle
 * @returns {boolean}
 */
function fastExit4IntersectionEllipseCircle(ellipse: ellipseParam, circle: circleParam): boolean {
  const a = ellipse.a;
  const b = ellipse.b;
  const radioMax4Ellipse = Math.max(a, b);
  const radioMin4Ellipse = Math.min(a, b);
  const r = circle.radius;
  // Si sus centros estan a mayor distancia que sus radios (el de la esfera mas el maximo de la elipse), entonces
  // NO habra interseccion.
  const distCenters = vectorDist2D(ellipse.center, circle.center);
  if (distCenters > radioMax4Ellipse + r) {
    // Aqui no hay interseccion, pues estan muy lejanas.
    return true;
  } else {
    // Podria estar la una estrictamente contenida en la otra???.
    // Es decir esta la circunferencia dentro del radio minimo de la esfera???. En tal caso no intersection.
    if (distCenters + r < radioMin4Ellipse) {
      return true;
    } else {
      // Y si la elipse esta estrictamente dentro de la circunferencia?. No intersection.
      if (distCenters + radioMax4Ellipse < r) {
        return true;
      }
    }
  }

  // Traslada el centro de la ellipse al origen y aplicar esa traslacion al centro del circulo.
  const translationX = -ellipse.center.x;
  const translationY = -ellipse.center.y;
  const circleCenter: IPoint = {
    x: circle.center.x + translationX,
    y: circle.center.y + translationY,
    z: 0,
  };

  // OJO: El azimut es siempre el del semieje MAYOR/PRINCIPAL
  // Y rotamos la elipse para alinearla con el eje X, es decir que le quitamos la rotacion y queda una
  // elipse de ecuacion simple. Este es el angulo inicial de la ellipse con el eje +X/A
  const W = mecDirectionMathDirection(ellipse.azimutO);
  // Esa rotacion se la pasamos al centro inicial del circulo, para mantener la coherencia. Ojo, que se rota en torno a Z.
  const circleCenterRotated = rotatePoint(circleCenter, 0, 0, -W);
  const ccx = circleCenterRotated.x;
  const ccy = circleCenterRotated.y;

  // Estas son las coordenadas limite del circulo que me interesan.
  const xMinCircle = ccx - r;
  const xMaxCircle = ccx + r;

  // Por los laterales...
  if (xMinCircle > a) {
    return true;
  }

  if (xMaxCircle < -a) {
    return true;
  }

  // Arriba y abajo.
  const yMinCircle = ccy - r;
  const yMaxCircle = ccy + r;

  // Por los laterales...
  if (yMinCircle > b) {
    return true;
  }

  if (yMaxCircle < -b) {
    return true;
  }

  // No se puede abandonar rapidamente.
  return false;
}

export function test4Intersections(): void {

  // Creamos ellipses y circunferencias aleatorias...
  const [minX, maxX] = [-1000, +1000];
  const [minY, maxY] = [-1000, +1000];
  const [minA, maxA] = [1, 100];
  const [minB, maxB] = [1, 100];

  const createRandomEllipse = (): ellipseParam => {
    const eX = minX + (Math.random() * (maxX - minX));
    const eY = minY + (Math.random() * (maxY - minY));
    const a = minA + Math.random() * maxA;
    const b = minB + Math.random() * maxB;
    const azimutO = Math.random() * 2 * Math.PI;

    const ellipse: ellipseParam = {
      center: { x: eX, y: eY, z: 0 },
      a,
      b,
      azimutO,
      plane: { x: 0, y: 0, z: 1 },
    };

    return ellipse;
  };

  const createRandomCircle = (): circleParam => {
    const cX = minX + (Math.random() * (maxX - minX));
    const cY = minY + (Math.random() * (maxY - minY));
    const r = minA + Math.random() * maxA;

    const circle: circleParam = {
      center: { x: cX, y: cY, z: 0 },
      radius: r,
      azimutO: 0,
      plane: { x: 0, y: 0, z: 1 },
    };

    return circle;
  };

  const createRandomTestEllipseCircle = (): [ellipseParam, circleParam] => {
    const ellipse = createRandomEllipse();
    const circle = createRandomCircle();

    return [ellipse, circle];
  };

  const createRandomTestEllipseEllipse = (): [ellipseParam, ellipseParam] => {
    const ellipse1 = createRandomEllipse();
    const ellipse2 = createRandomEllipse();

    return [ellipse1, ellipse2];
  };

  const ellipse0: ellipseParam = {
    center: {
      x: 145.3397995789178,
      y: -142.85724034561053,
      z: 0,
    },
    a: 141.7782957115488,
    b: 420.2499363300627,
    azimutO: 1.878782007359239,
    plane: { x: 0, y: 0, z: 0 },
  };

  const circle0: circleParam = {
    center: {
      x: 165.07258241624132,
      y: 312.58819521557393,
      z: 0,
    },
    radius: 155.58735569633174,
    azimutO: 0,
    plane: { x: 0, y: 0, z: 0 },
  };

  // Un circulo cocentral con la elipse, para probar con cosas mas raras: interseccion cuadruple.
  const circle1: circleParam = {
    center: {
      x: 140,
      y: -142,
      z: 0,
    },
    radius: 160,
    azimutO: 0,
    plane: { x: 0, y: 0, z: 0 },
  };

  if (true) {
    intersectionEllipseCircle(ellipse0, circle0);
    intersectionEllipseCircle(ellipse0, circle1);

    let numTestsEllipseCircle = 0;
    while (numTestsEllipseCircle !== 10) {
      const [elip, circ] = createRandomTestEllipseCircle();
      const vPoints = intersectionEllipseCircle(elip, circ);
      if (vPoints.length) {
        ++numTestsEllipseCircle;
      }
    }
  }

  const ellipse1: ellipseParam = {
    center: {
      x: 165,
      y: 312,
      z: 0,
    },
    a: 145,
    b: 450,
    azimutO: 1.0,
    plane: { x: 0, y: 0, z: 0 },
  };

  const ellipse2: ellipseParam = {
    center: {
      x: 140,
      y: -142,
      z: 0,
    },
    a: 145,
    b: 450,
    azimutO: 0.5,
    plane: { x: 0, y: 0, z: 0 },
  };

  // 2 intersecciones.
  intersectionEllipseEllipse(ellipse0, ellipse1);
  // 4 intersecciones.
  intersectionEllipseEllipse(ellipse0, ellipse2);
  // 0 intersecciones.
  intersectionEllipseEllipse(ellipse1, ellipse2);

  if (true) {
    let numTestsEllipseEllipse = 0;
    while (numTestsEllipseEllipse !== 10) {
      const [ellipse1, ellipse2] = createRandomTestEllipseEllipse();
      const vPoints = intersectionEllipseEllipse(ellipse1, ellipse2);
      if (vPoints.length) {
        ++numTestsEllipseEllipse;
      }
    }
  }
}