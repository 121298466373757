import * as THREE from "three";
import { normalizeRgbColor } from "lib/math/color";
import { Float32Concat, Uint16Concat, Float32delete, Uint16Delete, Float32splice, Uint16splice } from './buffer-utils';
import { IColor } from "lib/math/types";

const uInt16MaxValue = 65535;
const sizeLimit = uInt16MaxValue;
export class lineBufferHandler<T> {

  private threeMaterial: THREE.LineBasicMaterial;
  private resLine: THREE.LineSegments[];
  get line() { return this.resLine; }
  // Save VertexIndex [start (inclusive), end (exclusive)] -> [start, end)
  private indexData: Map<T, [number, number]>[];
  private hasColor: boolean;

  constructor(mat?: THREE.LineBasicMaterial, hasColor: boolean = true) {
    this.hasColor = hasColor;
    this.threeMaterial = mat ? mat : new THREE.LineBasicMaterial({ vertexColors: hasColor });
    this.resLine = [];
    this.indexData = [];
    this.createLine();
  }

  private createLine() {
    const line = new THREE.LineSegments(undefined, this.threeMaterial);
    line.name = "lineBufferHandler";
    line.renderOrder = 5;
    this.resLine.push(line);
    this.indexData.push(new Map());
  }

  public getLine(data: T) {
    const indxBlock = this.indexData.findIndex(ind => ind.has(data));
    if (!this.resLine[indxBlock]) debugger;
    return this.resLine[indxBlock];
  }

  public getObjDataFromIndex(index: number) {
    for (const [data, indices] of this.indexData[0]) {
      if (index >= indices[0] && index <= indices[1]) {
        return { data, index: indices[1] - index };
      }
    }
    console.log("[RAYCAST-INTERSECT] NO data founded by index: " + index)
  }

  public addData(data: T, bufferGeom: Float32Array, colorMat?: IColor) {

    const getIndexStart = (block: number) => {
      let geometry = this.resLine[block].geometry as THREE.BufferGeometry;
      if (geometry.hasAttribute("position")) {
        const position = geometry.getAttribute("position") as THREE.BufferAttribute;
        return position.array.length / 3;
      }
      return 0;
    }
    const checkBlockLen = (bufferGeom: Float32Array) => {
      let len = bufferGeom.length / 3;
      if ((indexCounterStart + len) >= sizeLimit) {
        console.warn("[lineBufferHandler] data dont fit --> build next block");
        currBlock++;
        if (this.resLine[currBlock] === undefined) {
          this.createLine();
        }
        indexCounterStart = getIndexStart(currBlock);
        checkBlockLen(bufferGeom);
      }
    }

    let currBlock = 0;
    let indexCounterStart = getIndexStart(currBlock);

    // Check if data fit in block
    checkBlockLen(bufferGeom);

    let geometry = this.resLine[currBlock].geometry as THREE.BufferGeometry;
    if (geometry.hasAttribute("position")) {
      const position = geometry.getAttribute("position") as THREE.BufferAttribute;
      indexCounterStart = (position.array.length / 3);
      const buffer = Float32Concat(position.array as Float32Array, bufferGeom);
      geometry.setAttribute("position", new THREE.BufferAttribute(buffer, 3));
    } else {
      geometry.setAttribute("position", new THREE.BufferAttribute(bufferGeom, 3));
    }

    const index: number[] = [];
    const color: number[] = [];
    const { r, g, b } = colorMat ? normalizeRgbColor(colorMat) : { r: 1, g: 1, b: 1 };
    const ptosLen = bufferGeom.length / 3;
    const startInd = indexCounterStart;
    for (let i = 0; i < ptosLen - 1; i++) {
      index.push(indexCounterStart, ++indexCounterStart);
      if (this.hasColor) color.push(r, g, b);
    }
    if (this.hasColor) color.push(r, g, b);
    this.indexData[currBlock].set(data, [startInd, indexCounterStart]);

    if (geometry.getIndex()) {
      const oldIndex = geometry.index as THREE.BufferAttribute;
      const buffer = Uint16Concat(oldIndex.array as Uint16Array, new Uint16Array(index));
      geometry.setIndex(Array.prototype.slice.call(buffer));
    } else {
      geometry.setIndex(index);
    }

    if (this.hasColor) {
      const bufferColor = new Float32Array(color);
      if (geometry.hasAttribute("color")) {
        const color = geometry.getAttribute("color") as THREE.BufferAttribute;
        const buffer = Float32Concat(color.array as Float32Array, bufferColor);
        geometry.setAttribute("color", new THREE.BufferAttribute(buffer, 3));
      } else {
        geometry.setAttribute("color", new THREE.BufferAttribute(bufferColor, 3));
      }
    }
    geometry.computeBoundingBox();
    geometry.computeBoundingSphere();
    return this.resLine[currBlock];
  }
  public removeData(data: T) {
    const indxBlock = this.indexData.findIndex(ind => ind.has(data));
    if (indxBlock !== -1) {
      const pointIndex = this.indexData[indxBlock].get(data);
      if (pointIndex) {
        const [start, end] = pointIndex;
        const geometry = this.resLine[indxBlock].geometry as THREE.BufferGeometry;
        const position = geometry.getAttribute("position") as THREE.BufferAttribute;
        const buffer = Float32delete(position.array as Float32Array, start * 3, (end * 3) + 3);
        geometry.setAttribute("position", new THREE.BufferAttribute(buffer, 3));

        if (this.hasColor) {
          const color = geometry.getAttribute("color") as THREE.BufferAttribute;
          const bufferCol = Float32delete(color.array as Float32Array, start * 3, (end * 3) + 3);
          geometry.setAttribute("color", new THREE.BufferAttribute(bufferCol, 3));
        }
        const offset = end - start + 1;
        const index = geometry.index as THREE.BufferAttribute;
        const indexBuffer = index.array as Uint16Array;
        const ini = indexBuffer.indexOf(start);
        const fin = indexBuffer.indexOf(end);
        const bufferIndx = Uint16Delete(indexBuffer, ini, fin + 1);
        for (let i = ini; i < bufferIndx.length; i++) {
          bufferIndx[i] -= offset;
        }
        geometry.setIndex(Array.prototype.slice.call(bufferIndx));

        let edit = false;
        for (const [objData, index] of this.indexData[indxBlock]) {
          if (edit) {
            index[0] -= offset;
            index[1] -= offset;
          }
          if (!edit && data === objData) {
            edit = true;
            this.indexData[indxBlock].delete(data);
          }
        }
        geometry.computeBoundingBox();
        geometry.computeBoundingSphere();
      }
    }
  }
  public updateGeometryData(data: T, coords: Float32Array, colorMat: IColor) {
    const indxBlock = this.indexData.findIndex(ind => ind.has(data));
    const pointIndex = indxBlock !== -1 ? this.indexData[indxBlock].get(data) : undefined;
    if (pointIndex) {
      const geometry = this.resLine[indxBlock].geometry as THREE.BufferGeometry;
      const position = geometry.getAttribute("position") as THREE.BufferAttribute;
      const [start, end] = pointIndex;
      const buffer = Float32splice(position.array as Float32Array, start * 3, (end * 3) + 3, coords);
      if (buffer) {
        const gap = position.array.length - buffer.length;
        // Buffer length has changed
        geometry.setAttribute("position", new THREE.BufferAttribute(buffer, 3));

        if (this.hasColor) {
          const { r, g, b } = normalizeRgbColor(colorMat);
          const colors = new Float32Array(coords.length);
          for (let i = 0; i < coords.length; i += 3) {
            colors[i] = r;
            colors[i + 1] = g;
            colors[i + 2] = b;
          }
          const color = geometry.getAttribute("color") as THREE.BufferAttribute;
          const bufferCol = Float32splice(color.array as Float32Array, start * 3, (end * 3) + 3, colors) as Float32Array;
          geometry.setAttribute("color", new THREE.BufferAttribute(bufferCol, 3));
        }
        const index = geometry.index as THREE.BufferAttribute;
        const indexBuffer = index.array as Uint16Array;

        const ini = indexBuffer.indexOf(start);
        const fin = indexBuffer.indexOf(end);
        const oldSize = (fin - ini) + 1;
        const newSize = oldSize - (gap / 3) * 2;
        const lastIndex = indexBuffer[indexBuffer.indexOf(start - 1)] ?? -1;
        const newBuffer = new Uint16Array(newSize);
        let currInd = lastIndex + 1;
        for (let i = 0; i < newBuffer.length; i += 2) {
          newBuffer[i] = currInd++;
          newBuffer[i + 1] = currInd;
        }
        const bufferIndx = Uint16splice(indexBuffer, ini, fin + 1, newBuffer);
        const offset = (gap / 3);
        if (bufferIndx) {
          for (let i = ini + newSize; i < bufferIndx.length; i++) {
            bufferIndx[i] -= offset;
          }
        }
        geometry.setIndex(Array.prototype.slice.call(bufferIndx));


        let edit = false;
        for (const [objData, index] of this.indexData[indxBlock]) {
          if (edit) {
            index[0] -= offset;
            index[1] -= offset;
          }
          if (!edit && data === objData) {
            edit = true;
            index[1] -= offset;
          }
        }
      }
      position.needsUpdate = true;
      geometry.computeBoundingBox();
      geometry.computeBoundingSphere();
    }
  }
  public updateMaterialData(data: T, colorMat: IColor) {
    const indxBlock = this.indexData.findIndex(ind => ind.has(data));
    const pointIndex = this.indexData[indxBlock].get(data);
    if (pointIndex) {
      const { r, g, b } = normalizeRgbColor(colorMat);
      const geometry = this.resLine[indxBlock].geometry as THREE.BufferGeometry;
      const color = geometry.getAttribute("color") as THREE.BufferAttribute;
      const [start, end] = pointIndex;
      for (let i = start; i <= end; i++) {
        color.setXYZ(i, r, g, b);
      }
      color.needsUpdate = true;
    }
  }
  public clearData() {
    for (const line of this.resLine) {
      const geometry = line.geometry as THREE.BufferGeometry;
      geometry.setAttribute("position", new THREE.BufferAttribute(new Float32Array(), 3));
      geometry.setAttribute("color", new THREE.BufferAttribute(new Float32Array(), 3));
      geometry.setIndex(new THREE.BufferAttribute(new Uint16Array(), 1));
      geometry.computeBoundingBox();
      geometry.computeBoundingSphere();
    }
    for (const indx of this.indexData) {
      indx.clear();
    }
    this.resLine = [];
    this.indexData = [];
    this.createLine();
  }
  public disposeData() {
    for (const line of this.resLine) {
      line.geometry.dispose();
    }
    for (const indx of this.indexData) {
      indx.clear();
    }
    this.resLine = [];
    this.indexData = [];
  }

  public loadDatas(datas: { obj: T, bufferGeom: Float32Array[], colorMat?: IColor }[]) {

    let currBlock = 0;
    const coords: number[] = [];
    const index: number[] = [];
    const colors: number[] = [];

    let startInd;

    const fillBufferGeometry = (geometry: THREE.BufferGeometry) => {
      if (geometry.hasAttribute("position")) {
        const position = geometry.getAttribute("position") as THREE.BufferAttribute;
        const buffer = Float32Concat(position.array as Float32Array, new Float32Array(coords));
        geometry.setAttribute("position", new THREE.BufferAttribute(buffer, 3));

        if (this.hasColor) {
          const color = geometry.getAttribute("color") as THREE.BufferAttribute;
          const buffColor = Float32Concat(color.array as Float32Array, new Float32Array(colors));
          geometry.setAttribute("color", new THREE.BufferAttribute(buffColor, 3));
        }
        const oldIndex = geometry.index as THREE.BufferAttribute;
        const buffIndx = Uint16Concat(oldIndex.array as Uint16Array, new Uint16Array(index));
        geometry.setIndex(Array.prototype.slice.call(buffIndx));
        geometry.computeBoundingBox();
        geometry.computeBoundingSphere();

      } else {
        geometry.setAttribute("position", new THREE.BufferAttribute(new Float32Array(coords), 3));
        if (this.hasColor) geometry.setAttribute("color", new THREE.BufferAttribute(new Float32Array(colors), 3));
        geometry.setIndex(index);
      }
    }

    const getIndexStart = (block: number) => {
      let geometry = this.resLine[block].geometry as THREE.BufferGeometry;
      if (geometry.hasAttribute("position")) {
        const position = geometry.getAttribute("position") as THREE.BufferAttribute;
        return position.array.length / 3;
      }
      return 0;
    }

    const checkBlockLen = (bufferGeom: Float32Array[]) => {
      let len = 0
      bufferGeom.forEach(b => len += b.length);
      if ((indexCounterStart + (len / 3)) >= sizeLimit) {
        if (coords.length) {
          let geometry = this.resLine[currBlock].geometry as THREE.BufferGeometry;
          fillBufferGeometry(geometry);
        }
        console.warn("[lineBufferHandler] data dont fit --> build next block");

        currBlock++;
        if (this.resLine[currBlock] === undefined) {
          this.createLine();
        }
        coords.length = 0;
        index.length = 0;
        colors.length = 0;
        indexCounterStart = getIndexStart(currBlock);
        startInd = indexCounterStart;
        checkBlockLen(bufferGeom);
      }
    }

    let indexCounterStart = getIndexStart(currBlock);
    for (const data of datas) {
      const { r, g, b } = data.colorMat ? normalizeRgbColor(data.colorMat) : { r: 1, g: 1, b: 1 };
      startInd = indexCounterStart;

      // Check if data fit in block
      checkBlockLen(data.bufferGeom);

      for (const buff of data.bufferGeom) {
        for (const n of buff) {
          coords.push(n);
        }
        const ptosLen = buff.length / 3;
        for (let i = 0; i < ptosLen - 1; i++) {
          index.push(indexCounterStart, ++indexCounterStart);
          if (this.hasColor) colors.push(r, g, b);
        }
        if (this.hasColor) colors.push(r, g, b);
        ++indexCounterStart;
      }
      this.indexData[currBlock].set(data.obj, [startInd, indexCounterStart - 1]);
    }
    let geometry = this.resLine[currBlock].geometry as THREE.BufferGeometry;
    fillBufferGeometry(geometry);
  }
}
