import { IPoint } from "../math/types";
import * as THREE from "three";
import { addIpoint } from "../math/point";
import { getAuxMeshBasicMaterial } from "../materials";
import { rotatePoint } from "../math/rotate";
import { solidParam } from "lib/models/solids/solid";

export interface planeParam extends solidParam {
    width: number,
    height: number,
    horizontalDivisions: number,
    verticalDivisions: number,
}

export function createBufferPlane(width: number, height: number, horizontalDivisions: number, verticalDivisions: number) {
    const geom = new THREE.PlaneBufferGeometry(width, height, horizontalDivisions, verticalDivisions);
    return geom;
}

export function createPlane(width: number, height: number, horizontalDivisions: number, verticalDivisions: number, material?: THREE.MeshPhongMaterial, basePoint?: IPoint, rotation?: IPoint, offset?: IPoint, scale?: IPoint) {
    const geom = createBufferPlane(width, height, horizontalDivisions, verticalDivisions);
    const mat = material ?? getAuxMeshBasicMaterial();
    const threeObj = new THREE.Mesh(geom, mat);
    if (basePoint && offset && rotation){
        let aux = addIpoint(basePoint, offset);
        aux = rotatePoint(aux, rotation.x, rotation.y, rotation.z , basePoint)
        threeObj.position.set(aux.x, aux.y, aux.z)
    }
    if (rotation) threeObj.rotation.set(rotation.x, rotation.y, rotation.z);
    if (scale) threeObj.scale.set(scale.x, scale.y, scale.z);
    return threeObj;
}