import { lineBaseCreate } from "lib/geometries/line";
import { polygonParam, polygonBuffer } from "lib/geometries/polygon";
import { getCurrentLineMaterial, ILineMaterial } from "lib/materials";
import { normalizeAngle, eulerAnglesToAxisAngle, mirrorAngle } from "lib/math/angles";
import { mirrorPoint } from "lib/math/mirror";
import { copyIPoint, addIpoint } from "lib/math/point";
import { rotatePoint } from "lib/math/rotate";
import { scalePoint } from "lib/math/scale";
import { IPoint } from "lib/math/types";
import { objDataType } from "../types";
import { LineBaseData } from "./line-base";

export class PolygonData extends LineBaseData {

  public type = objDataType.POLYGON;
  protected nameObj: string = "Polygon";
  public definition: polygonParam;

  constructor(definition: polygonParam, material?: ILineMaterial) {
    super();
    this.definition = {
      center: copyIPoint(definition.center),
      radius: definition.radius,
      sides: definition.sides,
      inscribed: definition.inscribed,
      angleO: definition.angleO,
      plane: copyIPoint(definition.plane),
    };
    this.material = material ?? getCurrentLineMaterial();
  }

  static createObj(definition: polygonParam, material: ILineMaterial) {
    const buffer = polygonBuffer(
      definition.center,
      definition.radius,
      definition.sides,
      definition.inscribed,
      definition.angleO,
      definition.plane
    );
    return lineBaseCreate(buffer, material);
  }
  public createObject(definition?: polygonParam, material?: ILineMaterial) {
    return PolygonData.createObj(definition ?? this.definition, material ?? this.material);
  }
  public getBufferGeom(): Float32Array {
    return polygonBuffer(
      this.definition.center,
      this.definition.radius,
      this.definition.sides,
      this.definition.inscribed,
      this.definition.angleO,
      this.definition.plane
    );
  }

  public cloneDefinition(): polygonParam {
    return {
      center: copyIPoint(this.definition.center),
      radius: this.definition.radius,
      sides: this.definition.sides,
      inscribed: this.definition.inscribed,
      angleO: this.definition.angleO,
      plane: copyIPoint(this.definition.plane),
    };
  }
  public translate(distance: IPoint) {
    this.definition.center = addIpoint(this.definition.center, distance);
    this.regenerateObjectFromDefinition();
  }
  public rotate(
    angleX: number,
    angleY: number,
    angleZ: number,
    basePoint: IPoint
  ): void {
    angleX = normalizeAngle(angleX);
    angleY = normalizeAngle(angleY);
    angleZ = normalizeAngle(angleZ);

    this.definition.center = rotatePoint(
      this.definition.center,
      angleX,
      angleY,
      angleZ,
      basePoint
    );
    const { angle } = eulerAnglesToAxisAngle(angleX, angleY, angleZ);
    this.definition.angleO = normalizeAngle(this.definition.angleO + angle);
    this.regenerateObjectFromDefinition();
  }
  public scale(
    factorX: number,
    factorY: number,
    factorZ: number,
    basePoint: IPoint
  ): void {
    this.definition.center = scalePoint(
      this.definition.center,
      factorX,
      factorY,
      factorZ,
      basePoint
    );
    this.definition.radius *= factorX;
    this.regenerateObjectFromDefinition();
  }
  public mirror(startPoint: IPoint, endPoint: IPoint): void {
    this.definition.center = mirrorPoint(
      this.definition.center,
      startPoint,
      endPoint
    );
    this.definition.angleO = mirrorAngle(
      this.definition.angleO,
      startPoint,
      endPoint
    );
    this.regenerateObjectFromDefinition();
  }
  public delete(): void {
    this.graphicObj.geometry.dispose();
  }
}
