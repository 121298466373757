const TangentIcon = ({ ...props }) => {
  return (
    <svg className="h-5 w-5" viewBox="0 0 100 100" fill="none" {...props}>
      <path
        d="M92.5 84.306H62.165C80.422 69.168 92.5 23.863 92.5 7.5C92.5 6.119 91.381 5 90 5C88.619 5 87.5 6.119 87.5 7.5C87.5 26.179 72.957 72.743 56.126 82.494C54.767 80.569 52.53 79.306 50 79.306C47.47 79.306 45.233 80.569 43.874 82.494C27.043 72.743 12.5 26.179 12.5 7.5C12.5 6.119 11.381 5 10 5C8.619 5 7.5 6.119 7.5 7.5C7.5 23.863 19.578 69.168 37.835 84.306H7.5C6.119 84.306 5 85.425 5 86.806C5 88.187 6.119 89.306 7.5 89.306H42.938C43.971 92.214 46.742 94.306 50.001 94.306C53.26 94.306 56.031 92.214 57.064 89.306H92.5C93.881 89.306 95 88.187 95 86.806C95 85.425 93.881 84.306 92.5 84.306ZM50 89.306C48.621 89.306 47.5 88.184 47.5 86.806C47.5 85.428 48.621 84.306 50 84.306C51.379 84.306 52.5 85.428 52.5 86.806C52.5 88.184 51.379 89.306 50 89.306Z"
        fill="currentColor"
      />
      <path
        d="M55.875 80.125V92.875H43.125V80.125H55.875Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="6.25"
      />
    </svg>
  );
};

export default TangentIcon;
