import { GraphicProcessor } from "lib/graphic-processor";
import { copyIPoint } from "lib/math/point";
import { objDataType } from "lib/models/types";
import { cadOpType } from "lib/operations/factory";
import { createStructuralElementCommand } from "./struc-simple";
import { pileCapParam } from "../../models-struc/types/pile-cap";
import { pileCapBuilder } from "lib/operations/structural/pile-cap";
import { getFooterOrPileCapBasePointFromColumn } from "lib/geometries/structural/column";
import { EditBaseStructCommand } from "./struc-edit-base";
import { pileCapTemplateCache } from "lib/models-struc/pile-cap-templates/cache";
import { ShellCrossSection } from "lib/models-struc/cross-sections-shape/shell-cross-sections/shellcs-shapes";
import { shellCrossSectionCache } from "lib/models-struc/cross-sections-shape/shell-cross-sections/cache";
import { ShelCSSType } from "lib/models-struc/cross-sections-shape/shell-cross-sections/types";
import { BeamCrossSection } from "lib/models-struc/cross-sections-shape/beam-cross-sections/beamcs-shapes";
import { beamCrossSectionCache } from "lib/models-struc/cross-sections-shape/beam-cross-sections/cache";
import { isCircularSection, sectionType } from "lib/models-struc/cross-sections-shape/beam-cross-sections/types";
import { isEqual } from "lib/math/epsilon";
import { IPoint } from "lib/math/types";
import { PileCapData } from "lib/models/structural/pile-cap";

export class PileCapFromTemplateCommand extends createStructuralElementCommand {

  protected opType = cadOpType.PILECAP;
  protected elemType: objDataType.PILECAP = objDataType.PILECAP;
  protected structuralParam: pileCapParam[] = [];

  constructor(pileCaps: pileCapBuilder[], graphicProc: GraphicProcessor) {
    const strucMng = graphicProc.getStructuralModelManager();
    const storey = strucMng.currBuilding.storeys[0];
    const layerId = strucMng.getLayerByStoreyIdAndStructuralType(storey.id, objDataType.PILECAP).id;
    super(storey.id, layerId, graphicProc);

    for (let param of pileCaps) {
      const template = pileCapTemplateCache.loadStylefromCache(param.pileCapTemplateId)!
      const basePoint = getFooterOrPileCapBasePointFromColumn(param.column.definition);
      this.structuralParam.push({
        storeyId: storey.id,
        name: strucMng.currBuilding.getNextPileCapName(),
        lnkObjIds: [param.column.id],
        materialType: storey.columnConcreteType,

        capSectionId: this.getOrCreateShellCrossSection(param.depth),
        capCoords: template.capCoords,

        pileSections: this.getOrCreateBeamCrossSection(param.diameter),
        pileCoords: template.pileCoords,

        pilePenetration: param.pilePenetration,
        height: param.height,

        basePoint: basePoint,
        rotation: copyIPoint({ x: 0, y: 0, z: 0 }),
        offset: copyIPoint({ x: 0, y: 0, z: 0 }),
        scale: copyIPoint({ x: 1, y: 1, z: 1 }),
      });
    }
  }

  private getOrCreateShellCrossSection(depth: number): string {
    const sections = shellCrossSectionCache.getAllLoadedStyles();
    for (const section of sections) {
      const isFlatSection = section.parameters.shellSectionType === ShelCSSType.FLAT;
      if (isFlatSection && section.thickness === depth) {
        return section.styleId;
      }
    }
    const shellCS = new ShellCrossSection({
      name: "Cap section",
      thickness: depth,
      parameters: {
        shellSectionType: ShelCSSType.FLAT,
      }
    });
    const styleId = shellCrossSectionCache.saveStyle(shellCS);
    return styleId;
  }
  private getOrCreateBeamCrossSection(diameter: number): string {
    const radius = diameter * 0.5;
    const sections = beamCrossSectionCache.getAllLoadedStyles();
    for (const section of sections) {
      if (isCircularSection(section.parameter)) {
        if (isEqual(section.parameter.radius, radius)) {
          return section.styleId;
        }
      }
    }
    const beamCrossSection = new BeamCrossSection({
      name: "pile circular section",
      parameter: {
        beamSectionType: sectionType.CIRC,
        radius,
      }
    });
    const styleId = beamCrossSectionCache.saveStyle(beamCrossSection);
    return styleId;
  }
}
export class PileCapCommand extends createStructuralElementCommand {

  protected opType = cadOpType.PILECAP;
  protected elemType: objDataType.PILECAP = objDataType.PILECAP;
  protected structuralParam: pileCapParam[] = [];
  public createdObjs: PileCapData[] = [];

  constructor(
    capSectionId: string,
    capCoords: IPoint[],
    pileSections: string,
    pileCoords: IPoint[],
    pilePenetration: number,
    height: number,
    basePoint: IPoint,
    graphicProc: GraphicProcessor
  ) {
    const strucMng = graphicProc.getStructuralModelManager();
    const storey = strucMng.currBuilding.storeys[0];
    const layerId = strucMng.getLayerByStoreyIdAndStructuralType(storey.id, objDataType.PILECAP).id;
    super(storey.id, layerId, graphicProc);

    this.structuralParam.push({
      storeyId: storey.id,
      name: strucMng.currBuilding.getNextPileCapName(),
      lnkObjIds: [],
      materialType: storey.columnConcreteType,

      capSectionId: capSectionId,
      capCoords: capCoords,

      pileSections: pileSections,
      pileCoords: pileCoords,

      pilePenetration: pilePenetration,
      height: height,

      basePoint: basePoint,
      rotation: copyIPoint({ x: 0, y: 0, z: 0 }),
      offset: copyIPoint({ x: 0, y: 0, z: 0 }),
      scale: copyIPoint({ x: 1, y: 1, z: 1 }),
    });
  }

}

export class PileCapEditDataCommand extends EditBaseStructCommand {
  protected opType = cadOpType.EDITPILECAP;
}
