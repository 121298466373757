import { PolygonEditDataCommand } from "lib/commands/edition/polygon";

import { setPosBuffer } from "lib/geometries";
import { lineAddVertex, lineAuxCreate, lineMoveVertex } from "lib/geometries/line";
import { polygonBuffer, polygonCreate, polygonParam } from "lib/geometries/polygon";
import { lineAngle2p, normalizeAngle } from "lib/math/angles";
import { vectorDist3D } from "lib/math/distance";
import { copyIPoint } from "lib/math/point";
import { IPoint } from "lib/math/types";
import { PolygonData } from "lib/models/primitives/polygon";
import { SimpleEdition } from "../edition";
import { cadOpType } from "../factory";
import { settingsOpModes } from "../step-operations";

export class PolygonEditOP extends SimpleEdition {
  public opType = cadOpType.EDITPOLYGON;

  private objData: PolygonData;
  private radius: number;
  private inscribed: boolean;
  private angleO: number;
  private isVertex: boolean;
  private indexToEdit: number;

  constructor(objData: PolygonData, isVertex: boolean, index: number, iniPoint: IPoint) {
    super(iniPoint);
    this.objData = objData;
    const objDef = this.objData.definition;
    this.inscribed = isVertex;
    this.isVertex = isVertex;
    this.indexToEdit = index;
    this.auxPoly = polygonCreate(objDef.center, objDef.radius, objDef.sides, objDef.inscribed, objDef.angleO);
    this.auxLine = lineAuxCreate();
    const { x, y, z } = copyIPoint(objDef.center);
    lineAddVertex(this.auxLine, x, y, z);
    lineAddVertex(this.auxLine, x, y, z);
  }
  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Insert point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
    }]);
  }
  public async start() {
    await super.start();
    const planeManager = this.graphicProcessor.getPlaneManager();
    planeManager.activePlane.position = this.objData.definition.center;
    planeManager.activePlane.rotation = this.objData.definition.plane;
    planeManager.activePlane.locked = true;
  }
  private getAngleO(center: IPoint, pto: IPoint) {
    const c = this.currPlane.getRelativePoint(center);
    const p = this.currPlane.getRelativePoint(pto);
    return normalizeAngle(lineAngle2p(c, p));
  }
  public setLastPoint(): void {
    this.radius = vectorDist3D(this.lastPoint, this.objData.definition.center);
    this.angleO = this.getAngleO(this.objData.definition.center, this.lastPoint);
    this.endOperation();
  }
  public moveLastPoint(pto: IPoint) {
    lineMoveVertex(this.auxLine, pto.x, pto.y, pto.z);
    this.radius = vectorDist3D(pto, this.objData.definition.center);
    this.angleO = this.getAngleO(this.objData.definition.center, pto);
    this.calculatePolygon();
  }
  private calculatePolygon(): void {
    const objDef = this.objData.definition;
    let coords = polygonBuffer(objDef.center, this.radius, objDef.sides, this.inscribed, this.angleO, objDef.plane);
    if (!coords) coords = new Float32Array(0);
    setPosBuffer(this.auxPoly, coords);
  }
  private setNewParams() {
    const alpha = 2 * Math.PI / this.objData.definition.sides; // Central angle
    if (this.isVertex) { // Is vertex
      if (this.inscribed) {
        this.angleO = this.angleO - alpha * this.indexToEdit;
      } else {
        this.angleO = this.angleO - alpha * this.indexToEdit + alpha * 0.5;
        this.radius = this.radius * Math.cos(alpha * 0.5);
      }
    } else { // Is edge
      if (this.inscribed) {
        this.angleO = this.angleO - alpha * this.indexToEdit - alpha * 0.5;
        this.radius = this.radius / Math.cos(alpha * 0.5);
      } else {
        this.angleO = this.angleO - alpha * this.indexToEdit;
      }
    }
  }
  public save() {
    if (this.graphicProcessor) {
      this.setNewParams();
      const objDef = this.objData.definition;
      const polygon: polygonParam = {
        center: objDef.center,
        radius: this.radius,
        sides: objDef.sides,
        inscribed: this.inscribed,
        angleO: this.angleO,
        plane: objDef.plane,
      };
      const command = new PolygonEditDataCommand(this.objData, polygon, null, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
}