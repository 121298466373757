import { WaffleSlab } from "modules/struc/models/ecore/structural";
import { dataModelPersistence } from "lib/input-output/database/loader";
import { objDataType } from "lib/models/types";
import { Storey } from "lib/models-struc/struc-storey";
import { slabParam } from 'lib/models-struc/types/slab';
import { EcoreImporter } from './structuralElement';
import { ILogger } from 'shared/utils/logger/logger';

export class EcoreWaffleSlabImporter extends EcoreImporter {

  constructor(
    protected ecoreElem: WaffleSlab,
    protected logger: ILogger) {
    super();
  }

  getObjData(storey: Storey, layerId: string, cssIdsRefs: string[], materialRefs: string[]): dataModelPersistence {
    const materialIndex = this.ecoreElem.material.$ref.split(".").pop()!;
    const shellCssRef = this.ecoreElem.css.$ref.split(".").pop()!;
    const waffleCssRef = this.ecoreElem.wafflecss.$ref.split(".").pop()!;

    const { basePoint, rotation } = this.getPlacement(this.ecoreElem.placement);

    const param: slabParam = {
      storeyId: storey.id,
      name: this.ecoreElem.name,
      lnkObjIds: [],
      materialType: materialRefs[parseInt(materialIndex)],

      shellCrossSectionId: cssIdsRefs[parseInt(shellCssRef)],
      waffleShelCrossSectionId: cssIdsRefs[parseInt(waffleCssRef)],

      deadLoadId: "",
      liveLoadId: "",

      depth: -this.ecoreElem.representation.direction.z!,
      ptos2D: this.getSlabGeometry(),
      holes: this.getSlabHolesGeometry(),

      basePoint: { ...basePoint, z: basePoint.z + storey.level },
      rotation,
      offset: { x: 0, y: 0, z: 0 },
      scale: { x: 1, y: 1, z: 1 },
    }

    return this.getDataModel(objDataType.SLAB, layerId, param);

  }
}
