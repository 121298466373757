import { buildingStrucElemRepresentation, getStructElemTypeName } from "lib/models-struc/types/struc-base";
import { IStrucElementData } from "lib/models/structural/structural";
import { SolidDataDefinitionHandler } from "../solid";
import { beamProp } from "./beam";
import { columnProp } from "./column";
import { footerProp } from "./footer";
import { pileCapProp } from './pile-cap';
import { slabProp } from "./slab";
import { wallProp } from "./wall";
import { getCenterObjData } from "lib/geometries/centroids";
import { linealPrecision } from "lib/general-settings";

export type structuralPropTypes = beamProp | columnProp | slabProp | wallProp | footerProp | pileCapProp;

export abstract class StructuralDataDefinitionHandler<T extends structuralPropTypes> extends SolidDataDefinitionHandler<T> {

  protected abstract data: IStrucElementData;

  protected buildInfoProperties() {
    this.definitionInfo = {};
    this.fillSolidDefinition(this.data);
    this.structuralInfo = {};
    this.fillStructuralDefinition(this.data);
  }

  protected fillSolidDefinition(data: IStrucElementData) {
    const def = data.definition;
    const centroId = getCenterObjData(data);
    (this.definitionInfo as any).basePoint = this.getPointView(def.basePoint, "Base point", "m", linealPrecision);
    (this.definitionInfo as any).centroid = this.getPointView(centroId, "Centroid", "m", linealPrecision, false);
    this.materialInfo = this.getSolidMaterialView(data.material);
  }

  protected fillStructuralDefinition(data: IStrucElementData) {
    const strucMng = this.graphicProcessor.getStructuralModelManager();
    const storey = strucMng.currBuilding.getStoreyFromId(data.storeyId)!;
    const def = data.definition;
    (this.structuralInfo as any).name = this.getStringView(def.name, "Name", false);
    (this.structuralInfo as any).storeyName = this.getStringView(storey.name, "Storey Name", false);

    const linkedObjsName = data.lnkObjs.map(l => { return `${getStructElemTypeName(l.type)} - ${l.strucName}` });
    (this.structuralInfo as any).linkedObjs = { publicName: `Linked object (${linkedObjsName.length})`, type: "list", list: linkedObjsName, value: 0 };

    const loadsNames = data.loads.map(l => l.strucName);
    (this.structuralInfo as any).loads = { publicName: `Loads (${loadsNames.length})`, type: "list", list: loadsNames, value: 0 };

    (this.structuralInfo as any).materialType = this.getStringView(def.materialType, "Material", false);
  }

  protected checkNewDefinition(newDefinition: structuralPropTypes): boolean {
    if (!newDefinition) { return false; }
    if (!this.checkSolidNewDefinition(newDefinition)) { return false; }
    return true;
  }

  protected checkStructuralNewDefinition(newDefinition: buildingStrucElemRepresentation): boolean {
    if (!newDefinition) { return false; }
    if (!this.checkString(newDefinition.name)) return false;
    if (!this.checkString(newDefinition.materialType)) return false;
    return true;
  }

  protected checkAndChangedDefinition(oldDefinition: buildingStrucElemRepresentation, newDefinition: structuralPropTypes): buildingStrucElemRepresentation | null {
    let def = null;
    if (this.checkNewDefinition(newDefinition)) {
      def = this.changedNewDefinition(oldDefinition, newDefinition);
    }
    return def;
  }

  protected changedNewDefinition(oldDefinition: buildingStrucElemRepresentation, newDefinition: structuralPropTypes): buildingStrucElemRepresentation | null {
    let def = oldDefinition;
    let changed: boolean = false;
    if (newDefinition) {
      const solid = this.changedSolidNewDefinition(oldDefinition, newDefinition)
      if (solid !== null) {
        def.basePoint = solid.basePoint;
        def.rotation = solid.rotation;
        def.scale = solid.scale;
        def.offset = solid.offset;
        changed = true;
      }
    }
    return (changed ? def : null);
  }
}