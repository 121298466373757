import { GraphicProcessor } from "lib/graphic-processor";
import { copyIPoint } from "lib/math/point";
import { cadOpType } from "lib/operations/factory";
import { heightType } from '../../models-struc/types/struc-base';
import { IWallStretch, wallParam } from "../../models-struc/types/wall";
import { copyIPolylineParam } from "lib/math/line";
import { createCompoundStructuralElementCommand } from "./struc-compound";
import { Storey } from "lib/models-struc/struc-storey";
import { objDataType } from "lib/models/types";
import { IWallSettings } from "lib/operations/structural/wall";
import { EditBaseStructCommand } from "./struc-edit-base";
import { WallData } from "lib/models/structural/wall";

export class WallCommand extends createCompoundStructuralElementCommand<wallParam> {

  protected opType = cadOpType.WALL;
  protected objType = objDataType.WALL;
  protected elemType: objDataType.WALL = objDataType.WALL;
  protected structuralParam: wallParam[];
  public createdObjs: WallData[] = [];

  constructor(wallParam: IWallSettings, graphicProc: GraphicProcessor) {
    super(graphicProc);

    const strucMng = this.graphicProcessor.getStructuralModelManager();
    const currBuilding = strucMng.currBuilding;
    const storeys = currBuilding.storeys;

    if (wallParam.heightType === heightType.STOREY) {
      const z = wallParam.basePoint.z;
      const storey = currBuilding.getStoreyFromLevel(z);
      const wallStrucParam = this.buildParams(storey, wallParam, wallParam.height, z);
      this.structuralParam = [wallStrucParam];

    } else {
      
      this.structuralParam = [];

      let startLevel = wallParam.basePoint.z;
      let finalLevel = startLevel - wallParam.height;
      if (startLevel < finalLevel) {
        [finalLevel, startLevel] = [startLevel, finalLevel]
      }
      const totalHeight = startLevel - finalLevel;

      const iniStorey = currBuilding.getStoreyFromLevel(startLevel);
      let i = storeys.indexOf(iniStorey)

      let currLevel = startLevel;
      while (currLevel > finalLevel) {
        const storey = storeys[i];
        let lowerLevel = storey.level - storey.height;
        if (lowerLevel < finalLevel) lowerLevel = finalLevel;
        const wallHeight = totalHeight > storey.height ? currLevel - lowerLevel : totalHeight;
        const wallStrucParam = this.buildParams(storey, wallParam, wallHeight, currLevel);
        this.structuralParam.push(wallStrucParam);
        
        i -= 1;
        const nextStorey = storeys[i];
        if (nextStorey === undefined) break;
        currLevel = nextStorey.level;
      }
    }
  }
  private buildParams(storey: Storey, wallParam: IWallSettings, height: number, level: number): wallParam {
    const stretch: IWallStretch[] = [];
    for (const normal of wallParam.normals) {
      stretch.push({ normal, orientation: 1, holes: [] });
    }
    return {
      name: "_" + storey.name,
      lnkObjIds: [],
      materialType: storey.columnConcreteType,
      wallType: wallParam.wallType,
      heightType: wallParam.heightType,
      storeyId: storey.id,
      
      widthType: wallParam.widthGrowth,
      height,

      ptos2D: copyIPolylineParam(wallParam.points2D),
      stretch,
      shellCrossSectionId: wallParam.shellCrossSectionId,
      basePoint: { x: wallParam.basePoint.x, y: wallParam.basePoint.y, z: level },
      rotation: copyIPoint(wallParam.rotation),
      offset: copyIPoint(wallParam.offset),
      scale: copyIPoint(wallParam.scale),
    };
  }
}

export class WallEditDataCommand extends EditBaseStructCommand {
  protected opType = cadOpType.EDITWALL;
}