import { CadCommand } from "./base";
import { IObjData } from "lib/models/objdata";
import { cadOpType } from "lib/operations/factory";
import { GraphicProcessor } from "lib/graphic-processor";

export abstract class DeleteBaseCommand extends CadCommand {

  private objsToDelete: IObjData[];

  constructor(objsToDelete: IObjData[], graphicProcessor: GraphicProcessor) {
    super(graphicProcessor);
    this.objsToDelete = objsToDelete;
  }

  public async execute() {
    for (const data of this.objsToDelete) {
      data.unlinkAllDependences();
      data.removeObjAsDependence();
      this.graphicProcessor.removeFromLayer(data);
    }

    const selManager = this.graphicProcessor.getSelectionManager();
    selManager.dispatchSelectedObjects();
    const modelManager = this.graphicProcessor.getDataModelManager();
    modelManager.dispatchDeletedObjs(this.objsToDelete);
    modelManager.layerManager.layerObserver.dispatchLoadLayers();
  }

  public async unExecute() {
    for (const data of this.objsToDelete) {
      data.relinkAllDependences();
      data.addObjAsDependence();
      this.graphicProcessor.addToLayer(data, data.layerId);
    }

    const modelManager = this.graphicProcessor.getDataModelManager();
    modelManager.dispatchAddedObjs(this.objsToDelete);
    modelManager.layerManager.layerObserver.dispatchLoadLayers();
  }

  public delete() {
    this.objsToDelete.length = 0;
  }
}

export class DeleteCommand extends DeleteBaseCommand {
  protected opType = cadOpType.DELETE;
}