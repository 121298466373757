import { Project as ProjectStruct, Site as SiteStruct } from "lib/models-struc/struc-project";
import { Storey as StoreyStruct } from "lib/models-struc/struc-storey";
import { Building as BuildingStruct } from "lib/models-struc/struc-building";
import { Building as BuildingEcore, MaterialSet as MaterialSetEcore, Site as SiteEcore, Storey as StoreyEcore } from 'modules/struc/models/ecore/base';
import { getEcoreCodeSet, getEcoreMaterialSet, getEcoreMeshParams, getEcoreFloat } from './helper-ecore';
import { EcoreBeamElem } from "./structural/beam";
import { EcoreColumnElem } from "./structural/column";
import { EcoreSlabElem } from "./structural/slab";
import { EcoreWallElem } from "./structural/wall";
import { MeshProperties } from "lib/models-struc/mesh/mesh";
import { BuildingElement as EcoreBuildingElement } from "modules/struc/models/ecore/base";
import { FEMStructuralElement, MeshProject as MeshProjectEcore, MeshVersion as MeshVersionEcore } from "modules/struc/models/ecore/mesh";
import { getEcoreCrossSectionShapeSet } from "./cross-section-shapes";
import { IStrucElementData } from "lib/models/structural/structural";
import { IEcoreBuildingElementExport } from "./struc-elem-base";

export const baseUriModel = "http://www.example.org/buildingmodel#//";

/** Build json ecore for calculating structure
 *
 * @export
 * @class EcoreExporter
 */
export class EcoreMeshExporter {

  private materialSet: MaterialSetEcore;
  private crossSectionShapesIds: string[];
  private femStructuralElements: FEMStructuralElement[] = [];

  exportMeshProject(mesh: MeshProperties, project: ProjectStruct): MeshProjectEcore {
    this.materialSet = getEcoreMaterialSet(project.sites[0].buildings[0].storeys);
    return {
      eClass: `${baseUriModel}mesh/MeshProject`,
      id: project.id,
      materialset: this.materialSet,
      name: project.name,
      site: this.exportSiteToEcore(project.sites[0], project.city, project.country),
      versions: this.exportMeshVersionsToEcore(mesh, project.sites[0].buildings[0]),
    };
  }
  private exportSiteToEcore(site: SiteStruct, city: string, country: string): SiteEcore {
    return {
      eClass: baseUriModel + "base/Site",
      id: site.id,
      city: city,
      country: country,
      name: site.name,
    }
  }
  private exportMeshVersionsToEcore(mesh: MeshProperties, building: BuildingStruct): MeshVersionEcore[] {
    const { crossSectionShapesIds, crossSectionShapes } = getEcoreCrossSectionShapeSet();
    this.crossSectionShapesIds = crossSectionShapesIds;
    const meshVersion: MeshVersionEcore = {
      eClass: baseUriModel + "mesh/MeshVersion",
      id: "",
      name: "",
      building: this.exportBuildingToEcore(building),
      meshgenparams: getEcoreMeshParams(mesh),
      femStructuralElements: this.femStructuralElements,
      codeset: getEcoreCodeSet(),
      crosssectionshapeset: {
        eClass: baseUriModel + "base/CrossSectionShapeSet",
        crosssectionshapes: crossSectionShapes,
      },
    }
    return [meshVersion];
  }
  private exportBuildingToEcore(building: BuildingStruct): BuildingEcore {
    return {
      eClass: baseUriModel + "base/Building",
      id: building.id,
      name: building.name,
      compoundelement: [],
      storeys: this.exportStoreysToEcore(building.storeys),
    }
  }
  private exportStoreysToEcore(storeys: StoreyStruct[]): StoreyEcore[] {
    const result: StoreyEcore[] = [];
    for (let storey of storeys) {
      if (!storey.IsEmpty()) {
        const storeyData: StoreyEcore = {
          eClass: baseUriModel + "base/Storey",
          id: storey.id,
          name: storey.name,
          elements: this.exportBuildingElementsToEcore(storey, result.length),
        }
        const elevation = getEcoreFloat(storey.level, 0.0);
        const height = getEcoreFloat(storey.height, 3.0);
        if (elevation !== undefined) storeyData.elevation = elevation;
        if (height !== undefined) storeyData.height = height;
        result.push(storeyData);
      }
    }
    return result;
  }
  private exportBuildingElementsToEcore(storey: StoreyStruct, storeyRef: number): EcoreBuildingElement[] {
    const { floorConcreteType, columnConcreteType } = storey;

    const floorMatRef = this.materialSet.materials.findIndex(m => m.name === floorConcreteType);
    const columMatRef = this.materialSet.materials.findIndex(m => m.name === columnConcreteType);

    const elemRef = { n: 0 };
    const [beams, FEMbeams] = this.exportStructuralElementsToEcore(storey.beams, floorMatRef, storeyRef, elemRef, new EcoreBeamElem());
    const [columns, FEMcolumns] = this.exportStructuralElementsToEcore(storey.columns, columMatRef, storeyRef, elemRef, new EcoreColumnElem());
    const [slabs, FEMslabs] = this.exportStructuralElementsToEcore(storey.slabs, floorMatRef, storeyRef, elemRef, new EcoreSlabElem());
    const [walls, FEMwalls] = this.exportStructuralElementsToEcore(storey.walls, columMatRef, storeyRef, elemRef, new EcoreWallElem());

    let buildingElems: EcoreBuildingElement[] = [];
    if (beams.length || columns.length || slabs.length || walls.length) {
      buildingElems = [...beams, ...columns, ...slabs, ...walls];
    }
    if (FEMbeams.length || FEMcolumns.length || FEMslabs.length || FEMwalls.length) {
      this.femStructuralElements.push(...FEMbeams, ...FEMcolumns, ...FEMslabs, ...FEMwalls);
    }
    return buildingElems;
  }
  private exportStructuralElementsToEcore(elements: IStrucElementData[], materialRef: number, storeyRef: number, elemRef: { n: number }, EcoreExporter: IEcoreBuildingElementExport): [EcoreBuildingElement[], FEMStructuralElement[]] {
    const eCoreBuildingElem: EcoreBuildingElement[] = [];
    const eCoreStrucElem: FEMStructuralElement[] = [];
    for (let elem of elements) {
      EcoreExporter.setStrucElem(elem, this.crossSectionShapesIds);
      const eCoreElem = EcoreExporter.exportToEcore(materialRef);
      eCoreBuildingElem.push(eCoreElem);

      const eCoreStruc = EcoreExporter.exportFemStructuralElementToEcore(storeyRef, elemRef.n);
      eCoreStrucElem.push(eCoreStruc)
      elemRef.n += 1;
    }
    return [eCoreBuildingElem, eCoreStrucElem];
  }
}


