import Section from "shared/components/ui/section";
import { ProjectCodes } from "lib/apis/struc-project/api";
import FormSelect from "shared/components/ui/form-select";
import { concreteCodes, loadCodes } from "modules/struc/models/codes";

interface Props {
  projectCodes: ProjectCodes;
  register: any;
}

const CodesForm = ({ projectCodes, register }: Props) => {

  return (
    <Section title="Codes" isCollapsible={true}>
      <div className="grid grid-cols-6 gap-6">

        <div className="col-span-2">
          <FormSelect
            name="Codes.Concrete"
            ref={register}
            label="Concrete code"
            dark
            defaultValue={projectCodes.Concrete}
          >
            {concreteCodes.map((code) => (
              <option key={code} value={code}>
                {code}
              </option>
            ))}
          </FormSelect>
        </div>

        <div className="col-span-2">
          <FormSelect
            name="Codes.Load"
            ref={register}
            label="Load code"
            dark
            defaultValue={projectCodes.Load}
          >
            {loadCodes.map((code) => (
              <option key={code} value={code}>
                {code}
              </option>
            ))}
          </FormSelect>
        </div>

      </div>
    </Section>
  );
};

export default CodesForm;
