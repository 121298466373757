import { getExtendObjsInfo, IExtendInfo } from "../../math/extend";
import { getVertexFromIndex } from "../../geometries/line";
import { IPoint } from "../../math/types";
import { cadOpType } from "../../operations/factory";
import { CadCommand } from "../base";
import { copyIPoint } from "../../math/point";
import { GraphicProcessor } from "../../graphic-processor";
import { LineData } from "lib/models/primitives/line";

export class ExtendCommand extends CadCommand {

  protected opType = cadOpType.EXTEND;
  public linesToExtend: LineData[];
  public lineToIntersect: LineData | undefined;

  private extendInfoResults: IExtendInfo[];
  private extendInfoOriginal: IExtendInfo[];

  constructor(linesToExtendId: LineData[], lineToIntersectId: LineData, graphicProcessor: GraphicProcessor) {
    super(graphicProcessor);
    this.linesToExtend = linesToExtendId;
    this.lineToIntersect = lineToIntersectId;

    // Genero los resultados.
    const lineToExtendsDef = this.linesToExtend.map((o) => o?.definition.points);
    this.extendInfoResults = getExtendObjsInfo(lineToExtendsDef, this.lineToIntersect.definition.points);

    // Me guardo los originales.
    this.extendInfoOriginal = new Array(this.extendInfoResults.length);
    this.extendInfoResults.forEach((extendInfo, i) => {
      if (extendInfo !== undefined) {
        const lineExt = this.linesToExtend[i].graphicObj;
        this.extendInfoOriginal[i] = {
          first: getVertexFromIndex(lineExt as THREE.Line, 0) as IPoint,
          last: getVertexFromIndex(lineExt as THREE.Line) as IPoint,
        };
      }
    });
  }

  private insertPoints(points: IExtendInfo[]): void {
    // Pintamos las geometrías auxiliares
    for (let i = 0; i < points.length; i++) {
      const extendInfo = points[i];
      if (extendInfo !== undefined) {
        const lineExt = this.linesToExtend[i];
        const lineDef = lineExt?.definition;
        if (extendInfo.first !== undefined) {
          const point = extendInfo.first as IPoint;
          lineDef.points[0] = copyIPoint(point);
        }
        if (extendInfo.last !== undefined) {
          const point = extendInfo.last as IPoint;
          lineDef.points[lineDef.points.length - 1] = copyIPoint(point);
        }
      }
    }
    this.linesToExtend.forEach((o) => o.regenerateObjectFromDefinition());
    const dtMdlMngr = this.graphicProcessor.getDataModelManager();
    dtMdlMngr.dispatchEditObjs(this.linesToExtend, this.opType);
  }

  public async execute() {
    this.insertPoints(this.extendInfoResults);
  }

  public async unExecute() {
    this.insertPoints(this.extendInfoOriginal);
  }

  public delete() {
    this.linesToExtend.length = 0;
    this.lineToIntersect = undefined;
    this.extendInfoResults.length = 0;
    this.extendInfoOriginal.length = 0;
  }
}
