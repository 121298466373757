import { getVertexFromIndex } from "lib/geometries/line";
import { isDirectionInArc, lineAzimut2p, normalizeAngle } from "lib/math/angles";
import { arcParam, getTangentPoints } from "lib/math/arc";
import { calculateCentroidPoints } from "lib/math/centroid";
import { pointOnLine3D } from "lib/math/distance";
import { getPointOnEllipse } from "lib/math/ellipse";
import { getEllipseCenterAngle } from "lib/math/ellipse-arc";
import { intersect3D, intersectArcLine } from "lib/math/intersections";
import { getNearPolylineEdgeIndex, getEdgePolylineFromIndex, getMiddlePointArcDefinition, getIsegmentFromIndex } from "lib/math/line";
import { addIpoint, getAzimutPolarPoint, getMiddlePoint, getPolarPoint } from "lib/math/point";
import { polygon } from "lib/math/polygon";
import { rotatePoint } from "lib/math/rotate";
import { copyISegment, IPoint, ISegment, isISegment } from "lib/math/types";
import { ArcData } from "lib/models/primitives/arc";
import { CircleData } from "lib/models/primitives/circle";
import { CubeData } from "lib/models/solids/cube";
import { EllipseData } from "lib/models/primitives/ellipse";
import { EllipseArcData } from "lib/models/primitives/ellipse-arc";
import { IObjData } from "lib/models/objdata";
import { LineData } from "lib/models/primitives/line";
import { PointData } from "lib/models/primitives/point";
import { PolygonData } from "lib/models/primitives/polygon";
import { RegionData } from "lib/models/solids/region";
import { TextData } from "lib/models/text";
import { getAbsolutePoint } from "lib/coordinates/plane";
import { getCrossMark } from "lib/selection/selection-tools";
import { auxHintColor, snapSettings } from "./snap";
import { BeamData } from "lib/models/structural/beam";
import { WallData } from "lib/models/structural/wall";
import { ColumnData } from "lib/models/structural/column";
import { LoadStructuralData } from "lib/models/structural/load";
import { loadType } from "lib/models-struc/types/load";

export interface IObjDataHint {
  auxHint: THREE.Points[];

  points: IPoint[];
  vertex: IPoint[];
  middlePtos: IPoint[];
  center: IPoint[];
  quadrant: IPoint[];

  tangent: IPoint[];
  perpendicular: IPoint[];
  intersections: IPoint[];
  extension: ISegment[];

  recalculate: (data: IObjData, param?: THREE.Intersection[], prevPoint?: IPoint) => void

}

class ObjDataHint implements IObjDataHint {

  public auxHint: THREE.Points[] = [];
  // public auxExtensionHint: THREE.Line[] = [];

  public points: IPoint[] = [];
  public vertex: IPoint[] = [];
  public middlePtos: IPoint[] = [];
  public center: IPoint[] = [];
  public quadrant: IPoint[] = [];

  public tangent: IPoint[] = [];
  public perpendicular: IPoint[] = [];

  public intersections: IPoint[] = [];
  public extension: ISegment[] = [];

  protected sectionIndexCache: Set<number>;
  protected faceIndexCache: Map<number, [IPoint, IPoint, IPoint]>;

  constructor(data: IObjData, param?: THREE.Intersection[], prevPoint?: IPoint) {
    this.buildHints(data, param, prevPoint);
  }
  protected buildHints(data: IObjData, param?: THREE.Intersection[], prevPoint?: IPoint) {
    // Default behaviour NOT snap hints
  }
  public recalculate(data: IObjData, param?: THREE.Intersection[], prevPoint?: IPoint) {

  }

  protected calculateEdgeHints(section: ISegment | arcParam, prevPoint: IPoint) {
    if (isISegment(section)) {
      // Extension hint
      this.extension.push(copyISegment(section));
      if (prevPoint.x !== Infinity) {
        // Perpendicular hint
        const p = pointOnLine3D(section.p1, section.p2, prevPoint, true);
        if (p[1] !== 0 && p[1] !== 1) {
          this.perpendicular = [p[0]];
        }
      }
    } else {
      const tg = getTangentPoints(section.center, section.radius, prevPoint, section.plane);
      if (tg) {
        const azim0 = normalizeAngle(lineAzimut2p(section.center, tg[0]));
        if (isDirectionInArc(azim0, section.azimutO, section.angleCenter)) {
          this.tangent.push(tg[0]);
        }
        const azim1 = normalizeAngle(lineAzimut2p(section.center, tg[1]));
        if (isDirectionInArc(azim1, section.azimutO, section.angleCenter)) {
          this.tangent.push(tg[1]);
        }
      }
    }
  }
}
export class PointDataHint extends ObjDataHint {

  protected buildHints(data: PointData) {
    // Vertex hints
    if (snapSettings.points && this.points.length === 0) {
      this.points = [data.definition];
    } else {
      this.points = [];
    }
  }
}
export class LineDataHint extends ObjDataHint {

  protected buildHints(data: LineData, intersections: THREE.Intersection[], prevPoint: IPoint) {
    if (this.sectionIndexCache === undefined) this.sectionIndexCache = new Set();

    const { points, arcs, isClosed } = data.definition;
    const indx0 = getNearPolylineEdgeIndex(data.definition, intersections[0].point);
    const section = getIsegmentFromIndex(data.definition, indx0);
    // Perpendicular-tangente
    if (!this.sectionIndexCache.has(indx0)) {
      this.calculateEdgeHints(section, prevPoint);
      this.sectionIndexCache.add(indx0);
    }
    // Vertex Edge hints
    if (!section.arc) {
      this.vertex = [section.p1, section.p2];
      this.middlePtos = [getMiddlePoint(section.p1, section.p2)];
    } else {
      this.vertex = [points[indx0]];
      this.middlePtos = [getMiddlePointArcDefinition(section.p1, section.arc, section.p2)];
    }
    // Center hints
    for (const arc of arcs) {
      if (arc) {
        this.center.push(arc.center);
        this.auxHint.push(getCrossMark(arc.center, auxHintColor));
      }
    }
    if (isClosed) {
      const pos = calculateCentroidPoints(points);
      if (pos) {
        this.center.push(pos);
        this.auxHint.push(getCrossMark(pos, auxHintColor));
      }
    }
    // Self intersections
    this.resolveSelfIntersection(data, intersections);
  }
  public recalculate(data: LineData, intersections: THREE.Intersection[], prevPoint: IPoint) {
    const indx0 = getNearPolylineEdgeIndex(data.definition, intersections[0].point);
    const section = getIsegmentFromIndex(data.definition, indx0);
    // Perpendicular-tangente
    if (!this.sectionIndexCache.has(indx0)) {
      this.calculateEdgeHints(section, prevPoint);
      this.sectionIndexCache.add(indx0);
    }
    // Vertex Edge hints
    if (!section.arc) {
      this.vertex = [section.p1, section.p2];
      this.middlePtos = [getMiddlePoint(section.p1, section.p2)];
    } else {
      this.vertex = [data.definition.points[indx0]];
      this.middlePtos = [getMiddlePointArcDefinition(section.p1, section.arc, section.p2)];
    }
    // Self intersections
    this.resolveSelfIntersection(data, intersections);
  }
  private resolveSelfIntersection(data: LineData, intersections: THREE.Intersection[]) {
    if (intersections.length <= 1) return;
    const def = data.definition;
    const indx0 = getNearPolylineEdgeIndex(def, intersections[0].point);
    const section0 = getEdgePolylineFromIndex(def, indx0);
    const indx1 = getNearPolylineEdgeIndex(def, intersections[1].point);
    const section1 = getEdgePolylineFromIndex(def, indx1);
    if (indx0 !== indx1 && Math.abs(indx0 - indx1) > 1) {
      if (isISegment(section0) && isISegment(section1)) {
        const c = intersect3D(
          section0.p1,
          section0.p2,
          section1.p1,
          section1.p2,
          true
        );
        if (c && c.p0) {
          this.intersections.push(c.p0);
        }
      } else if (isISegment(section0) && !isISegment(section1)) {
        const c = intersectArcLine(
          section0.p1,
          section0.p2,
          section1,
          true,
          true
        );
        if (c.length) {
          this.intersections.push(...c);
        }
      } else if (!isISegment(section0) && isISegment(section1)) {
        const c = intersectArcLine(
          section1.p1,
          section1.p2,
          section0,
          true,
          true
        );
        if (c.length) {
          this.intersections.push(...c);
        }
      }
    }
  }

}
export class PolygonDataHint extends ObjDataHint {

  protected buildHints(data: PolygonData, intersections: THREE.Intersection[], prevPoint: IPoint) {
    if (this.sectionIndexCache === undefined) this.sectionIndexCache = new Set();
    const { center, radius, sides, inscribed, angleO, plane } = data.definition;
    const ptos = polygon(center, radius, sides, inscribed, angleO, plane);
    let index = intersections[0].index as number;
    let p1 = ptos[index];
    let p2 = ptos[index + 1] ?? ptos[0];

    // Vertex hints
    this.vertex = ptos;
    // Middle Edge hints
    this.middlePtos = [getMiddlePoint(p1, p2)];
    // Perpendicular-tangente
    if (!this.sectionIndexCache.has(index)) {
      this.calculateEdgeHints({ p1, p2 }, prevPoint);
      this.sectionIndexCache.add(index);
    }
    // Center hint
    this.center.push(center);
    this.auxHint.push(getCrossMark(center, auxHintColor));
  }

  public recalculate(data: PolygonData, intersections: THREE.Intersection[], prevPoint: IPoint) {

    let index = intersections[0].index as number;
    let p1 = this.vertex[index];
    let p2 = this.vertex[index + 1] ?? this.vertex[0];

    // Middle Edge hints
    this.middlePtos = [getMiddlePoint(p1, p2)];
    // Perpendicular-tangente
    if (!this.sectionIndexCache.has(index)) {
      this.calculateEdgeHints({ p1, p2 }, prevPoint);
      this.sectionIndexCache.add(index);
    }
  }
}
export class CircleDataHint extends ObjDataHint {

  protected buildHints(data: CircleData, param: THREE.Intersection[], prevPoint: IPoint) {
    const { center, radius, plane } = data.definition;
    // tangent hints
    if (prevPoint.x !== Infinity) {
      const tg = getTangentPoints(center, radius, prevPoint, plane);
      if (tg) {
        this.tangent = tg;
      }
    }

    // Quadrant hints
    this.quadrant = [];
    for (let i = 0; i < 4; i++) {
      let vertex = getPolarPoint(center, i * Math.PI * 0.5, radius);
      this.quadrant.push(rotatePoint(vertex, plane.x, plane?.y, plane.z, center));
    }
    // Center hint
    this.center.push(center);
    this.auxHint.push(getCrossMark(center, auxHintColor));
  }

  public recalculate(data: CircleData, intersections: THREE.Intersection[], prevPoint: IPoint) {
    if (prevPoint.x !== Infinity) {
      const { center, radius, plane } = data.definition;
      // tangent hints
      const tg = getTangentPoints(center, radius, prevPoint, plane);
      if (tg) {
        this.tangent = tg;
      }
    }
  }

}
export class TextDataHint extends ObjDataHint {

  protected buildHints(data: TextData) {
    const position = data.definition.position;
    // Center hint
    this.center.push(position);
    this.auxHint.push(getCrossMark(position, auxHintColor));
  }
}
export class RegionDataHint extends ObjDataHint {

  protected buildHints(data: RegionData) {
    const points = data.definition.ptos2D;
    // Vertex hints
    this.vertex = points;
    // Middle Edge hints
    this.middlePtos = [];
    for (let i = 0; i < points.length; i++) {
      const p0 = points[i];
      let p1 = points[i + 1];
      if (p1 === undefined) {
        p1 = points[0];
      }
      if (p0 && p1) {
        this.middlePtos.push(getMiddlePoint(p0, p1));
      }
    }
    // Center hints. FGM: Ya no se repite ultimo punto.
    const pos = calculateCentroidPoints(points);
    if (pos) {
      this.center = [pos];
      this.auxHint.push(getCrossMark(pos, auxHintColor));
    }
  }
}
export class ArcDataHint extends ObjDataHint {

  protected buildHints(data: ArcData, param: THREE.Intersection[], prevPoint: IPoint) {
    const { center, radius, azimutO, angleCenter, plane } = data.definition;

    // tangent hints
    if (prevPoint.x !== Infinity) {
      const tg = getTangentPoints(center, radius, prevPoint, plane);
      if (tg) {
        const azim0 = normalizeAngle(lineAzimut2p(center, tg[0]));
        if (isDirectionInArc(azim0, azimutO, angleCenter)) {
          this.tangent.push(tg[0]);
        }
        const azim1 = normalizeAngle(lineAzimut2p(center, tg[1]));
        if (isDirectionInArc(azim1, azimutO, angleCenter)) {
          this.tangent.push(tg[1]);
        }
      }
    }

    // Vertex hints
    const p0 = getAzimutPolarPoint(center, azimutO, radius);
    const azimutN = azimutO + angleCenter;
    const p1 = getAzimutPolarPoint(center, azimutN, radius);
    this.vertex = [
      rotatePoint(p0, plane.x, plane.y, plane.z, center),
      rotatePoint(p1, plane.x, plane.y, plane.z, center),
    ];
    // Center hints
    this.center = [center];
    this.auxHint.push(getCrossMark(center, auxHintColor));
  }
  public recalculate(data: ArcData, intersections: THREE.Intersection[], prevPoint: IPoint) {
    const { center, radius, azimutO, angleCenter, plane } = data.definition;
    // tangent hints
    if (prevPoint.x !== Infinity) {
      const tg = getTangentPoints(center, radius, prevPoint, plane);
      if (tg) {
        const azim0 = normalizeAngle(lineAzimut2p(center, tg[0]));
        if (isDirectionInArc(azim0, azimutO, angleCenter)) {
          this.tangent.push(tg[0]);
        }
        const azim1 = normalizeAngle(lineAzimut2p(center, tg[1]));
        if (isDirectionInArc(azim1, azimutO, angleCenter)) {
          this.tangent.push(tg[1]);
        }
      }
    }
  }
}
export class EllipseDataHint extends ObjDataHint {

  protected buildHints(data: EllipseData, param: THREE.Intersection[], prevPoint: IPoint) {
    const { center, a, b, azimutO, plane } = data.definition;

    // TODO: tangent to a ellipse
    // const A = (1 / (a * a)) * 2;
    // const B = (1 / (b * b)) * 2;
    // let C = -A / B;
    // C = - (b * b) / (a * a);

    // const m = C * prevPoint.x / prevPoint.y;

    // const c = m * prevPoint.x - prevPoint.y;

    // const p2 = { x: prevPoint.x + 10, y: m * prevPoint.x + 10 + c, z: 0 };
    // const i = intersectLineEllipse(prevPoint, p2, data.definition);
    // this.tangent = i;


    // Vertex hints
    this.quadrant = [];
    for (let i = 0; i < 4; i++) {
      const vertex = getPointOnEllipse(center, a, b, i * Math.PI * 0.5, azimutO);
      this.quadrant.push(rotatePoint(vertex, plane.x, plane.y, plane.z, center));
    }
    // Center hints
    this.center = [center];
    this.auxHint.push(getCrossMark(center, auxHintColor));
  }
}
export class EllipseArcDataHint extends ObjDataHint {

  protected buildHints(data: EllipseArcData) {
    const { center, a, b, azimutO, azimutStart, azimutEnd, plane } = data.definition;
    // Vertex hints
    this.vertex = [];
    const direction1 = getEllipseCenterAngle(center, a, b, azimutStart);
    const direction2 = getEllipseCenterAngle(center, a, b, azimutEnd);
    [direction1, direction2].forEach((dir) => {
      const vertex: IPoint = getPointOnEllipse(center, a, b, dir, azimutO);
      this.vertex.push(rotatePoint(vertex, plane.x, plane.y, plane.z, center));
    });
    // Quadrant hints
    this.quadrant = [];
    for (let i = 0; i < 4; i++) {
      const vertex = getPointOnEllipse(center, a, b, i * Math.PI * 0.5, azimutO);
      const pto = rotatePoint(vertex, plane.x, plane.y, plane.z, center);
      this.quadrant.push(pto);
      this.auxHint.push(getCrossMark(pto, auxHintColor));
    }
    // Center hints
    this.center = [center];
    this.auxHint.push(getCrossMark(center, auxHintColor));
  }
}
export class SolidDataHint extends ObjDataHint {

  public recalculate(data: CubeData, inters: THREE.Intersection[], prevPoint: IPoint) {
    this.buildHints(data, inters, prevPoint);

    const int = inters[0];
    if (int.face && int.faceIndex !== undefined) {
      const [ptoA, ptoB, ptoC] = this.faceIndexCache.get(int.faceIndex) as [IPoint, IPoint, IPoint];

      if (prevPoint.x !== Infinity) {
        const p0 = pointOnLine3D(ptoA, ptoB, prevPoint, true);
        const p1 = pointOnLine3D(ptoB, ptoC, prevPoint, true);
        const p2 = pointOnLine3D(ptoC, ptoA, prevPoint, true);
        // Perpendicular hint
        if (p0[1] !== 0 && p0[1] !== 1) {
          this.perpendicular = [p0[0]];
        }
        if (p1[1] !== 0 && p1[1] !== 1) {
          this.perpendicular.push(p1[0]);
        }
        if (p2[1] !== 0 && p2[1] !== 1) {
          this.perpendicular.push(p2[0]);
        }
      }
    }
  }

  protected buildHints(data: CubeData, inters: THREE.Intersection[], prevPoint: IPoint) {
    if (this.faceIndexCache === undefined) this.faceIndexCache = new Map();

    const { basePoint, offset, rotation, scale } = data.definition;

    const int = inters[0];
    if (int.face && int.faceIndex !== undefined) {

      if (!this.faceIndexCache.has(int.faceIndex)) {

        const faceA = int.face.a;
        const faceB = int.face.b;
        const faceC = int.face.c;

        const graphicObj = int.object as THREE.Mesh; // data.graphicObj

        let ptoA = getVertexFromIndex(graphicObj, faceA)!;
        ptoA = addIpoint(ptoA, offset);
        ptoA = getAbsolutePoint(ptoA, basePoint, rotation, scale);

        let ptoB = getVertexFromIndex(graphicObj, faceB)!;
        ptoB = addIpoint(ptoB, offset);
        ptoB = getAbsolutePoint(ptoB, basePoint, rotation, scale);

        let ptoC = getVertexFromIndex(graphicObj, faceC)!;
        ptoC = addIpoint(ptoC, offset);
        ptoC = getAbsolutePoint(ptoC, basePoint, rotation, scale);

        this.vertex.push(ptoA, ptoB, ptoC);

        this.middlePtos.push(
          getMiddlePoint(ptoA, ptoB),
          getMiddlePoint(ptoB, ptoC),
          getMiddlePoint(ptoC, ptoA),
        );

        // this.extension.push(
        //   { p1: ptoA, p2: ptoB },
        //   { p1: ptoB, p2: ptoC },
        //   { p1: ptoC, p2: ptoA },
        // );

        // if (prevPoint.x !== Infinity) {
        //   const p0 = pointOnLine3D(ptoA, ptoB, prevPoint, true);
        //   const p1 = pointOnLine3D(ptoB, ptoC, prevPoint, true);
        //   const p2 = pointOnLine3D(ptoC, ptoA, prevPoint, true);
        //   // Perpendicular hint
        //   if (p0[1] !== 0 && p0[1] !== 1) {
        //     this.perpendicular = [p0[0]];
        //   }
        //   if (p1[1] !== 0 && p1[1] !== 1) {
        //     this.perpendicular.push(p1[0]);
        //   }
        //   if (p2[1] !== 0 && p2[1] !== 1) {
        //     this.perpendicular.push(p2[0]);
        //   }
        // }
        this.faceIndexCache.set(int.faceIndex, [ptoA, ptoB, ptoC]);
      }

    }
  }
}

export class BeamDataHint extends ObjDataHint {

  public recalculate(data: BeamData, inters: THREE.Intersection[], prevPoint: IPoint) {
    this.buildHints(data, inters, prevPoint);

    const int = inters[0];
    if (int.face && int.faceIndex !== undefined) {
      const [ptoA, ptoB, ptoC] = this.faceIndexCache.get(int.faceIndex) as [IPoint, IPoint, IPoint];

      if (prevPoint.x !== Infinity) {
        const p0 = pointOnLine3D(ptoA, ptoB, prevPoint, true);
        const p1 = pointOnLine3D(ptoB, ptoC, prevPoint, true);
        const p2 = pointOnLine3D(ptoC, ptoA, prevPoint, true);
        // Perpendicular hint
        if (p0[1] !== 0 && p0[1] !== 1) {
          this.perpendicular = [p0[0]];
        }
        if (p1[1] !== 0 && p1[1] !== 1) {
          this.perpendicular.push(p1[0]);
        }
        if (p2[1] !== 0 && p2[1] !== 1) {
          this.perpendicular.push(p2[0]);
        }
      }
    }
  }

  protected buildHints(data: BeamData, inters: THREE.Intersection[], prevPoint: IPoint) {
    if (this.faceIndexCache === undefined) this.faceIndexCache = new Map();

    const { basePoint, points, offset, rotation, scale } = data.definition;

    this.points = [basePoint, addIpoint(basePoint, points[1])];

    const int = inters[0];
    if (int.face && int.faceIndex !== undefined) {

      if (!this.faceIndexCache.has(int.faceIndex)) {

        const faceA = int.face.a;
        const faceB = int.face.b;
        const faceC = int.face.c;

        const graphicObj = int.object as THREE.Mesh; // data.graphicObj

        let ptoA = getVertexFromIndex(graphicObj, faceA)!;
        ptoA = addIpoint(ptoA, offset);
        ptoA = getAbsolutePoint(ptoA, basePoint, rotation, scale);

        let ptoB = getVertexFromIndex(graphicObj, faceB)!;
        ptoB = addIpoint(ptoB, offset);
        ptoB = getAbsolutePoint(ptoB, basePoint, rotation, scale);

        let ptoC = getVertexFromIndex(graphicObj, faceC)!;
        ptoC = addIpoint(ptoC, offset);
        ptoC = getAbsolutePoint(ptoC, basePoint, rotation, scale);

        this.vertex.push(ptoA, ptoB, ptoC);

        this.faceIndexCache.set(int.faceIndex, [ptoA, ptoB, ptoC]);
      }

    }
  }
}
export class ColumnDataHint extends ObjDataHint {

  public recalculate(data: ColumnData, inters: THREE.Intersection[], prevPoint: IPoint) {
    this.buildHints(data, inters, prevPoint);

    const int = inters[0];
    if (int.face && int.faceIndex !== undefined) {
      const [ptoA, ptoB, ptoC] = this.faceIndexCache.get(int.faceIndex) as [IPoint, IPoint, IPoint];

      if (prevPoint.x !== Infinity) {
        const p0 = pointOnLine3D(ptoA, ptoB, prevPoint, true);
        const p1 = pointOnLine3D(ptoB, ptoC, prevPoint, true);
        const p2 = pointOnLine3D(ptoC, ptoA, prevPoint, true);
        // Perpendicular hint
        if (p0[1] !== 0 && p0[1] !== 1) {
          this.perpendicular = [p0[0]];
        }
        if (p1[1] !== 0 && p1[1] !== 1) {
          this.perpendicular.push(p1[0]);
        }
        if (p2[1] !== 0 && p2[1] !== 1) {
          this.perpendicular.push(p2[0]);
        }
      }
    }
  }

  protected buildHints(data: ColumnData, inters: THREE.Intersection[], prevPoint: IPoint) {
    if (this.faceIndexCache === undefined) this.faceIndexCache = new Map();

    const { basePoint, offset, rotation, scale, height } = data.definition;

    this.points = [basePoint, { ...basePoint, z: basePoint.z - height }];

    const int = inters[0];
    if (int.face && int.faceIndex !== undefined) {

      if (!this.faceIndexCache.has(int.faceIndex)) {

        const faceA = int.face.a;
        const faceB = int.face.b;
        const faceC = int.face.c;

        const graphicObj = int.object as THREE.Mesh; // data.graphicObj

        let ptoA = getVertexFromIndex(graphicObj, faceA)!;
        ptoA = addIpoint(ptoA, offset);
        ptoA = getAbsolutePoint(ptoA, basePoint, rotation, scale);

        let ptoB = getVertexFromIndex(graphicObj, faceB)!;
        ptoB = addIpoint(ptoB, offset);
        ptoB = getAbsolutePoint(ptoB, basePoint, rotation, scale);

        let ptoC = getVertexFromIndex(graphicObj, faceC)!;
        ptoC = addIpoint(ptoC, offset);
        ptoC = getAbsolutePoint(ptoC, basePoint, rotation, scale);

        this.vertex.push(ptoA, ptoB, ptoC);

        this.faceIndexCache.set(int.faceIndex, [ptoA, ptoB, ptoC]);
      }

    }
  }
}
export class WallDataHint extends ObjDataHint {

  public recalculate(data: WallData, inters: THREE.Intersection[], prevPoint: IPoint) {
    this.buildHints(data, inters, prevPoint);

    const int = inters[0];
    if (int.face && int.faceIndex !== undefined) {
      const [ptoA, ptoB, ptoC] = this.faceIndexCache.get(int.faceIndex) as [IPoint, IPoint, IPoint];

      if (prevPoint.x !== Infinity) {
        const p0 = pointOnLine3D(ptoA, ptoB, prevPoint, true);
        const p1 = pointOnLine3D(ptoB, ptoC, prevPoint, true);
        const p2 = pointOnLine3D(ptoC, ptoA, prevPoint, true);
        // Perpendicular hint
        if (p0[1] !== 0 && p0[1] !== 1) {
          this.perpendicular = [p0[0]];
        }
        if (p1[1] !== 0 && p1[1] !== 1) {
          this.perpendicular.push(p1[0]);
        }
        if (p2[1] !== 0 && p2[1] !== 1) {
          this.perpendicular.push(p2[0]);
        }
      }
    }
  }

  protected buildHints(data: WallData, inters: THREE.Intersection[], prevPoint: IPoint) {
    if (this.faceIndexCache === undefined) this.faceIndexCache = new Map();

    const { basePoint, offset, rotation, scale, ptos2D } = data.definition;

    this.points = [basePoint]
    this.points.push(...ptos2D.points.map(p => addIpoint(basePoint, p)));

    const int = inters[0];
    if (int.face && int.faceIndex !== undefined) {

      if (!this.faceIndexCache.has(int.faceIndex)) {

        const faceA = int.face.a;
        const faceB = int.face.b;
        const faceC = int.face.c;

        const graphicObj = int.object as THREE.Mesh; // data.graphicObj

        let ptoA = getVertexFromIndex(graphicObj, faceA)!;
        ptoA = addIpoint(ptoA, offset);
        ptoA = getAbsolutePoint(ptoA, basePoint, rotation, scale);

        let ptoB = getVertexFromIndex(graphicObj, faceB)!;
        ptoB = addIpoint(ptoB, offset);
        ptoB = getAbsolutePoint(ptoB, basePoint, rotation, scale);

        let ptoC = getVertexFromIndex(graphicObj, faceC)!;
        ptoC = addIpoint(ptoC, offset);
        ptoC = getAbsolutePoint(ptoC, basePoint, rotation, scale);

        this.vertex.push(ptoA, ptoB, ptoC);
        this.faceIndexCache.set(int.faceIndex, [ptoA, ptoB, ptoC]);
      }
    }
  }
}

export class LoadHint extends ObjDataHint {

  protected buildHints(data: LoadStructuralData) {
    const def = data.definition;
    if (def.type === loadType.CONCENTRATED) {
      this.buildLoadConcentratedHints(data);
    } else if (def.type === loadType.LINEAL) {
      this.buildLoadLinealHints(data);
    } else {
      this.buildLoadSuperficialHints(data);
    }
  }

  private buildLoadConcentratedHints(data: LoadStructuralData) {
    const def = data.definition;
    const points = def.ptos2D.map(p => getAbsolutePoint(p, def.basePoint, def.rotation));
    // Vertex hints
    this.vertex = points;
  }

  private buildLoadLinealHints(data: LoadStructuralData) {
    const def = data.definition;
    const points = def.ptos2D.map(p => getAbsolutePoint(p, def.basePoint, def.rotation));
    // Vertex hints
    this.vertex = points;
    // Middle Edge hints
    this.middlePtos = [getMiddlePoint(points[0], points[1])];
  }

  private buildLoadSuperficialHints(data: LoadStructuralData) {
    const def = data.definition;
    const points = def.ptos2D.map(p => getAbsolutePoint(p, def.basePoint, def.rotation));
    // Vertex hints
    this.vertex = points;
    // Middle Edge hints
    this.middlePtos = [];
    for (let i = 0; i < points.length; i++) {
      const p0 = points[i];
      let p1 = points[i + 1];
      if (p1 === undefined) {
        p1 = points[0];
      }
      if (p0 && p1) {
        this.middlePtos.push(getMiddlePoint(p0, p1));
      }
    }
    // Center hints. FGM: Ya no se repite ultimo punto.
    const pos = calculateCentroidPoints(points);
    if (pos) {
      this.center = [pos];
      this.auxHint.push(getCrossMark(pos, auxHintColor));
    }
  }
}