import styled from 'styled-components';

export const Styles = styled.div`

  table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid var(--color-gray-400);

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.1rem;
      border-bottom: 1px solid var(--color-gray-400);
      border-right: 1px solid var(--color-gray-400);

      :last-child {
        border-right: 0;
      }
    }
    th {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
`;
