import { distance2d, pointToLine2D } from "./distance";
import { isZero, vector2Equals, vector3Equals } from "./epsilon";
import { IPoint, ISegment } from "./types";

export function isIPoint(p: any): p is IPoint {
  return (
    p !== undefined &&
    p.x !== undefined &&
    p.y !== undefined &&
    p.z !== undefined
  );
}
export function copyIPoint(p: IPoint): IPoint {
  console.assert(isIPoint(p));
  return {
    x: p.x,
    y: p.y,
    z: p.z ? p.z : 0,
  };
}

export function IpointsToBuffer(points: IPoint[], start?: number, end?: number): Float32Array {
  if (start === undefined) start = 0;
  if (end === undefined) end = points.length;

  const numPoints = end - start;
  const lenBuff = numPoints * 3;
  const buffer: Float32Array = new Float32Array(lenBuff);

  let c = 0;
  for (let i = start; i < end; i++) {
    const point = points[i];
    if (point) {
      buffer[c++] = point.x;
      buffer[c++] = point.y;
      buffer[c++] = point.z ? point.z : 0;
    }
  }
  return buffer;
}
export function buffer2IPoints(buffer: Float32Array): IPoint[] {
  const ptos: IPoint[] = [];
  const len = buffer.length;
  let x, y, z;
  for (let i = 0; i < len; i += 3) {
    x = buffer[i];
    y = buffer[i + 1];
    z = buffer[i + 2];
    ptos.push({ x, y, z });
  }
  return ptos;
}
export function IPointsToISegments(points: IPoint[]): ISegment[] {
  const res: ISegment[] = [];
  let last: IPoint | undefined;
  for (const p of points) {
    if (last !== undefined) {
      res.push({
        p1: last,
        p2: p,
      });
    }
    last = p;
  }
  return res;
}

/** Producto escalar (dot product) de dos vectores
 *
 * @export
 * @param {IPoint} p1
 * @param {IPoint} p2
 * @returns {number}
 */
export function dotProductIpoint(p1: IPoint, p2: IPoint): number {
  return p1.x * p2.x + p1.y * p2.y + p1.z * p2.z;
}
/** Producto vectorial (cross product) de dos vectores
 *
 * @param {IPoint} p1
 * @param {IPoint} p2
 * @returns {IPoint}
 */
export function crossProductIpoint(p1: IPoint, p2: IPoint): IPoint {
  return {
    x: p1.y * p2.z - p1.z * p2.y,
    y: p1.z * p2.x - p1.x * p2.z,
    z: p1.x * p2.y - p2.x * p1.y,
  };
}
export function magnitudeIpoint(p: IPoint): number {
  return Math.sqrt(p.x * p.x + p.y * p.y + p.z * p.z);
}
export function normalizeIpoint(v: IPoint): IPoint {
  const len = magnitudeIpoint(v);
  if (len === 0) return { x: 0, y: 0, z: 0 };
  return { x: v.x / len, y: v.y / len, z: v.z / len };
}

/**
 * Cálculo de un punto a partir de un origen, una distancia y una dirección.
 * Por defecto en el plano XY
 *
 * @export
 * @param {IPoint} point punto de origen
 * @param {number} angle angulo en radianes
 * @param {number} dist distancia al origen
 * @returns {IPoint}
 */
export function getPolarPoint(
  point: IPoint,
  angle: number,
  dist: number,
  plane: string = "XY"
): IPoint {
  if (undefined === plane) plane = "XY";
  let x: number;
  let y: number;
  let z: number;
  if (plane === "XY") {
    x = point.x + dist * Math.cos(angle);
    y = point.y + dist * Math.sin(angle);
    z = point.z;
  } else if (plane === "XZ") {
    x = point.x + dist * Math.cos(angle);
    y = point.y;
    z = point.z + dist * Math.sin(angle);
  } else if (plane === "YZ") {
    x = point.x;
    y = point.y + dist * Math.cos(angle);
    z = point.z + dist * Math.sin(angle);
  } else {
    x = point.x + dist * Math.cos(angle);
    y = point.y + dist * Math.sin(angle);
    z = point.z;
  }
  return { x, y, z };
}
export function getAzimutPolarPoint(
  point: IPoint,
  azimut: number,
  dist: number
): IPoint {
  return {
    x: point.x + dist * Math.sin(azimut),
    y: point.y + dist * Math.cos(azimut),
    z: point.z,
  };
}

export function addIpoint(p1: IPoint, p2: IPoint): IPoint {
  return {
    x: p1.x + p2.x,
    y: p1.y + p2.y,
    z: p1.z + p2.z,
  };
}
export function substractIpoint(p1: IPoint, p2: IPoint): IPoint {
  return {
    x: p1.x - p2.x,
    y: p1.y - p2.y,
    z: p1.z - p2.z,
  };
}
export function mulIpoint(p: IPoint, factor: number) {
  return {
    x: p.x * factor,
    y: p.y * factor,
    z: p.z * factor,
  };
}

/** Devuelve el punto medio.
 *
 * @export
 * @param {IPoint} v1
 * @param {IPoint} v2
 * @returns {IPoint}
 */
export function getMiddlePoint(v1: IPoint, v2: IPoint): IPoint {
  return getPointOnSegment(v1, v2, 0.5);
}
export function getPointOnSegment(v1: IPoint, v2: IPoint, factor: number): IPoint {
  return {
    x: v1.x + factor * (v2.x - v1.x),
    y: v1.y + factor * (v2.y - v1.y),
    z: v1.z + factor * (v2.z - v1.z),
  };
}

/** Comprueba si tres puntos son colineales (estan contenidos en una recta)
 * (Si todos los coeficientes son 0, los puntos son colineales)
 * @export
 * @param {IPoint} p1
 * @param {IPoint} p2
 * @param {IPoint} p3
 * @returns {boolean}
 */
export function pointsAreCollinear(p1: IPoint, p2: IPoint, p3: IPoint): boolean {
  const coefA: number =
    (p2.y - p1.y) * (p3.z - p1.z) - (p3.y - p1.y) * (p2.z - p1.z);
  if (!isZero(coefA)) {
    return false;
  }
  const coefB: number =
    (p3.x - p1.x) * (p2.z - p1.z) - (p2.x - p1.x) * (p3.z - p1.z);
  if (!isZero(coefB)) {
    return false;
  }
  const coefC: number =
    (p2.x - p1.x) * (p3.y - p1.y) - (p3.x - p1.x) * (p2.y - p1.y);
  if (!isZero(coefC)) {
    return false;
  }
  return true;
}
export function pointsAreCollinear2D(p1: IPoint, p2: IPoint, p3: IPoint): boolean {
  const coefA = (p2.x - p1.x) * (p3.y - p1.y) - (p3.x - p1.x) * (p2.y - p1.y);
  if (!isZero(coefA)) {
    return false;
  }
  return true;
}

//********************************************* */
//    LINE/POLYLINE vs POINT tool functions
//********************************************* */

/** Calcula la posición (factor) de un punto contenido en una línea
 * Si es <0 el punto se encuentra detras de la línea
 * Si es 0 corresponde al primer vertice
 * Si es 1 correposnde al segundo vertice
 * Si es >1 se encuentra delante
 * Si el punto no es contenido en la línea devuelve null
 *
 * @export
 * @param {IPoint} lineP0
 * @param {IPoint} lineP1
 * @param {IPoint} p
 * @returns {number}
 */
export function getFactorLinePosition2p(lineP0: IPoint, lineP1: IPoint, p: IPoint): number | null {
  if (vector3Equals(lineP0, p)) return 0;
  if (vector3Equals(lineP1, p)) return 1;
  if (lineP0 && lineP1 && pointsAreCollinear(lineP0, lineP1, p)) {
    const factorX: number = (p.x - lineP0.x) / (lineP1.x - lineP0.x);
    if (!isNaN(factorX)) {
      return factorX;
    }
    const factorY: number = (p.y - lineP0.y) / (lineP1.y - lineP0.y);
    if (!isNaN(factorY)) {
      return factorY;
    }
    const factorZ: number = (p.z - lineP0.z) / (lineP1.z - lineP0.z);
    if (!isNaN(factorZ)) {
      return factorZ;
    }
  }
  return null;
}
export function getFactorLinePosition2p2D(lineP0: IPoint, lineP1: IPoint, p: IPoint): number | null {
  if (vector2Equals(lineP0, p)) return 0;
  if (vector2Equals(lineP1, p)) return 1;
  if (lineP0 && lineP1 && pointsAreCollinear2D(lineP0, lineP1, p)) {
    const factorX: number = (p.x - lineP0.x) / (lineP1.x - lineP0.x);
    if (!isNaN(factorX)) {
      return factorX;
    }
    const factorY: number = (p.y - lineP0.y) / (lineP1.y - lineP0.y);
    if (!isNaN(factorY)) {
      return factorY;
    }
  }
  return null;
}

/** Relative position of a Point (left/right) related to a line (2D).
 * > 0 Point to the right
 * = 0 Point in Line
 * < 0 Point to the left.
 *
 * @export
 * @param {IPoint} point
 * @param {IPoint} lineP0
 * @param {IPoint} lineP1
 * @returns {number}
 */
export function pointLinePositionXY(point: IPoint, lineP0: IPoint, lineP1: IPoint): number {
  /*
  If you have point (x, y) and line (x0, y0)-(x1, y1) then ((x-x0)*(y1-y0)) - ((y-y0)*(x1-x0)) is 2d vector cross product.
  It will be negative if the point is in the left.
  */
  return (
    (point.x - lineP0.x) * (lineP1.y - lineP0.y) -
    (point.y - lineP0.y) * (lineP1.x - lineP0.x)
  );
}
export function pointLinePositionXZ(point: IPoint, lStart: IPoint, lEnd: IPoint): number {
  const AB = substractIpoint(lEnd, lStart);
  const AP = substractIpoint(point, lStart);
  const crossProd = crossProductIpoint(AP, AB);
  return crossProd.y;
}
export function pointLinePositionYZ(point: IPoint, lStart: IPoint, lEnd: IPoint): number {
  const AB = substractIpoint(lEnd, lStart);
  const AP = substractIpoint(point, lStart);
  const crossProd = crossProductIpoint(AP, AB);
  return crossProd.x;
}
export function pointLinePosition(point: IPoint, lStart: IPoint, lEnd: IPoint, plane?: string): number {
  if (plane === "XZ") return pointLinePositionXZ(point, lStart, lEnd);
  else if (plane === "YZ") return pointLinePositionYZ(point, lStart, lEnd);
  // plane === "XY")
  return pointLinePositionXY(point, lStart, lEnd);
}

interface IPolyLinePointPosition {
  /** Relative position of a Point related to a Poilyline (2D)
   * > 0 Point to the right
   * = 0 Point in Line
   * < 0 Point to the left.
   * @type {number}
   * @memberof IPolyLinePointPosition
   */
  pointPosition: number;
  /** Near point in polyline
   *
   * @type {IPoint}
   * @memberof IPolyLinePointPosition
   */
  pointOnLine: IPoint;
}
/** Relative position of a Point (left/right) related to a Polyline (2D)
 * > 0 Point to the right
 * = 0 Point in Line
 * < 0 Point to the left.
 * Besides return the point in Line.
 *
 * @export
 * @param {ISegment[]} segments
 * @param {IPoint} p
 * @param {boolean} [closedGeom]
 * @returns {IPolyLinePointPosition} relPosToLine: number, pointOnLine: IPoint
 */
export function getPolylinePointPosition(linePoints: IPoint[], p: IPoint): IPolyLinePointPosition {
  let closestDistance: number = Infinity;
  let closestLinePoint: IPoint = { x: p.x, y: p.y, z: p.z };
  let edgeIndex = -1;

  const numEdges = linePoints.length - 1;
  for (let i = 0; i < numEdges; i++) {
    const p0 = linePoints[i];
    const p1 = linePoints[i + 1];
    const pointInLine = pointToLine2D(p.x, p.y, p0.x, p0.y, p1.x, p1.y, true);
    const pointDistance = distance2d(pointInLine.x, pointInLine.y, p.x, p.y);
    if (pointDistance < closestDistance) {
      closestDistance = pointDistance;
      closestLinePoint = { x: pointInLine.x, y: pointInLine.y, z: p.z };
      edgeIndex = i;
    }
  }
  return {
    pointPosition: pointLinePositionXY(
      p,
      linePoints[edgeIndex],
      linePoints[edgeIndex + 1]
    ),
    pointOnLine: closestLinePoint,
  };
}

export function removeContigousEqualPoints(points: IPoint[]): IPoint[] {
  if (points && points.length > 1) {
    let lastPoint = points[0];
    for (let index = 1; index < points.length; index++) {
      if (vector3Equals(points[index], lastPoint)) {
        points.splice(index, 1);
        index--;
      }
      lastPoint = points[index];
    }
  }
  return points;
}