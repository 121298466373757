import * as THREE from "three";
import { defaultColor, IBaseMaterial } from "./base";
import { BaseMaterialCache } from "./base-cache";

export interface IPointMaterial extends IBaseMaterial {
  size: number;
}

export class PointMaterialCache extends BaseMaterialCache<IPointMaterial, THREE.PointsMaterial> {

  public getMaterial(pointMat: IPointMaterial): THREE.PointsMaterial {
    for (const [key, value] of this.map) {
      if (
        key.size === pointMat.size &&
        key.color.r === pointMat.color.r &&
        key.color.g === pointMat.color.g &&
        key.color.b === pointMat.color.b &&
        key.color.a === pointMat.color.a
      ) {
        return value;
      }
    }
    const material = new THREE.PointsMaterial({
      color: new THREE.Color(
        pointMat.color.r / 255,
        pointMat.color.g / 255,
        pointMat.color.b / 255
      ),
      transparent: pointMat.color.a !== 1,
      opacity: pointMat.color.a,
      size: pointMat.size,
      sizeAttenuation: false,
    });
    this.map.set(pointMat, material);
    return material;
  }
}

export function getDefaultPointMaterial(): IPointMaterial {
  return { color: { r: defaultColor.r, g: defaultColor.g, b: defaultColor.b, a: defaultColor.a }, size: 3 };
}

export function getCurrentPointMaterial(
  pointMaterial?: Partial<IPointMaterial>
): IPointMaterial {
  const defMat = getDefaultPointMaterial();
  return {
    color: pointMaterial?.color ?? defMat.color,
    size: pointMaterial?.size ?? defMat.size,
  };
}
