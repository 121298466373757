import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useUI } from "shared/components/ui/context";
import { UPDATE_SETTINGS } from "shared/services/user";
import { useSettings } from "./use-settings";
interface FormData {
  defaultStart: "cad" | "lite" | "struc";
}
export function useUpdateSettings() {

  const { user } = useSettings();
  const { closeModal, openToast } = useUI();
  const [mutate] = useMutation(UPDATE_SETTINGS);

  const { handleSubmit, register, errors } = useForm<FormData>({
    shouldFocusError: true,
    mode: "onTouched",
  });

  const updateSettings = useCallback(
    (values: FormData) => {
      mutate({
        variables: {
          id: user?.id,
          defaultStart: values.defaultStart,
        },
      }).then(() => {
        openToast({
          title: "Settings updated successfully",
          status: "success",
        });
        closeModal();
      });
    },
    [closeModal, openToast, user, mutate]
  );

  return {
    handleSubmit: handleSubmit(updateSettings),
    register,
    errors,
    user
  };
}
