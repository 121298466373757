import { ReactNode } from "react-router/node_modules/@types/react";
import styled from "styled-components";

// https://htmldom.dev/create-resizable-split-views/
interface Props {
  id: string;
  leftChild: ReactNode;
  leftClassName?: string;
  rightChild: ReactNode;
  rightClassName?: string;
}

const HorizontalResizer = (props: Props) => {

  const { id, leftChild, leftClassName, rightChild, rightClassName } = props;

  const mouseDownHandler = (event: PointerEvent) => {
    // Attach the listeners to `document`
    document.addEventListener("pointermove", mouseMoveHandler);
    document.addEventListener('pointerup', mouseUpHandler);
  };
  const mouseUpHandler = (e: PointerEvent) => {

    const resizer = document.getElementById(id) as HTMLElement;
    const leftSide = resizer.previousElementSibling as HTMLElement;
    const rightSide = resizer.nextElementSibling as HTMLElement;

    resizer.style.removeProperty('cursor');
    document.body.style.removeProperty('cursor');

    leftSide.style.removeProperty('user-select');
    leftSide.style.removeProperty('pointer-events');

    rightSide.style.removeProperty('user-select');
    rightSide.style.removeProperty('pointer-events');

    // Remove the handlers of `mousemove` and `mouseup`
    document.removeEventListener('pointermove', mouseMoveHandler);
    document.removeEventListener('pointerup', mouseUpHandler);
  };
  const mouseMoveHandler = (e: PointerEvent) => {

    const resizer = document.getElementById(id) as HTMLElement;
    const leftSide = resizer.previousElementSibling as HTMLElement;
    const rightSide = resizer.nextElementSibling as HTMLElement;
    const parentSize = (resizer.parentNode as HTMLElement).getBoundingClientRect();

    const parentWidth = parentSize.width;
    const resizerWidth = resizer.getBoundingClientRect().width;

    const leftWidthPx = (e.clientX - parentSize.left) - (resizerWidth * 0.5);
    const rightWidthPx = (parentWidth - leftWidthPx) - (resizerWidth * 1);

    leftSide.style.width = `${(leftWidthPx * 100) / parentWidth}%`;
    rightSide.style.width = `${(rightWidthPx * 100) / parentWidth}%`;

    resizer.style.cursor = 'col-resize';
    document.body.style.cursor = 'col-resize';

    leftSide.style.userSelect = 'none';
    leftSide.style.pointerEvents = 'none';

    rightSide.style.userSelect = 'none';
    rightSide.style.pointerEvents = 'none';
  };

  return (
    <Parentresizer className={"h-full flex justify-between overflow-hidden"}>
      <Contentresizer className={"w-1/2 " + leftClassName ?? ""} >
        {leftChild}
      </Contentresizer>
      <Resizer id={id} onPointerDown={(a: any) => { mouseDownHandler(a); }} />
      <Contentresizer className={"w-1/2 " + rightClassName ?? ""} >
        {rightChild}
      </Contentresizer>
    </Parentresizer >
  )
};

const Parentresizer = styled.div`
  background-color: var(--color-gray-200);
`;

const Resizer = styled.div`
  background-color: var(--color-gray-400);
  cursor: ew-resize;
  height: 100%;
  width: 4px;
  min-width: 4px;
  &:hover {
    background-color: var(--color-primary);
  }
`;
const Contentresizer = styled.div`
  min-width: min-content;
  height: 100%;
`;

export default HorizontalResizer;