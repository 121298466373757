import { Cad3dOp } from "./base";
import { CircleOP } from "./primitive/circle";
import { LineOP } from "./primitive/line";
import { PointOP } from "./primitive/point";
import { PolygonOP } from "./primitive/polygon";
import { PolyLineOP } from "./primitive/polyline";
import { CubeOP } from "./solids/cube";
import { CylinderOP } from "./solids/cylinder";
import { TranslationOP } from "./transform/translation";
import { RotateOP } from "./transform/rotate";
import { ScaleOP } from "./transform/scale";
import { MirrorOP } from "./transform/mirror";
import { RPrismOP } from "./solids/rprism";
import { PPrismOP } from "./solids/pprism";
import { PlaneOP } from "./solids/plane";
import { ConeOP } from "./solids/cone";
import { GraphicProcessor } from "../graphic-processor";
import { SelectOP } from "./select";
import { ExtendOP } from "./edition/extend";
import { MatrixOP } from "./edition/matrix";
import { ArcBaseOP } from "./primitive/arc";
import { StraightChamferOP } from "./edition/chamfer";
import { OffsetOP } from "./transform/offset";
import { TrimLinesOP } from "./edition/trimlines";
import { EllipseOP } from "./primitive/ellipse";
import { EllipseArcOP } from "./primitive/ellipse-arc";
import { RegionOP } from "./solids/region";
import { AlignOP } from "./edition/align";
import { CopyOP } from "./edition/copy";
import { PasteOP } from "./edition/paste";
import { CircleEditOP } from "./edition/circle";
import { PolygonEditOP } from "./edition/polygon";
import { EllipseEditOP } from "./edition/ellipse";
import { EllipseArcEditOP } from "./edition/ellipse-arc";
import { ArcEditOP } from "./edition/arc";
import { MovePolyLineVertexOP } from "./edition/polyline-move-vertex";
import { MovePolyLineEdgeOP } from "./edition/polyline-move-edge";
import { QuickDimensionOP } from "./dimension/quick-dim";
import { TextOP, TextParagraphOP } from "./primitive/text";
import { DistanceMeasureOP } from "./dimension/distance";
import { AlignedDimensionOP } from "./dimension/aligned-dim";
import { LinearDimensionOP } from "./dimension/lineal-dim";
import { ArcAngleDimensionOP, ArcLongDimensionOP } from "./dimension/arc-dim";
import { DiametroDimensionOP, RadialDimensionOP } from "./dimension/circular-dim";
import { AngleDimensionOP } from "./dimension/angle-dim";
import { MeasureAreaOP } from "./dimension/measure-area";
import { CreateWorkingPlaneFrom3PtosOP, CreateWorkingPlaneFromPlaneVectorOP } from "./create-wrk-plane";
import { BlockOP, BlockRefOP } from "./primitive/block";
import { MultiOp } from "./edition/multiop";
import { EditAlignedDimensionOP } from "./dimension/aligned-dim-edit";
import { MoveRegionVertexOP } from "./edition/region";
import { EditLinearDimensionOP } from "./dimension/lineal-dim-edit";
import { EditDiameterCircularDimensionOP, EditRadialCircularDimensionOP } from "./dimension/circular-dim-edit";
import { EditArcAngleDimensionOP, EditArcLengthDimensionOP } from "./dimension/arc-dim-edit";
import { EditAngleDimensionOP } from "./dimension/angle-dim-edit";
import { MatchPropertiesOP } from "./edition/match-prop";
import { StretchOP } from "./edition/stretch";
import { ColumnOP } from "./structural/column";
import { SlabOP } from "./structural/slab";
import { MoveSlabVertexOP } from "./structural/slab-edit";
import { MoveSlabHoleVertexOP } from "./structural/slab-hole-edit";
import { BeamOP } from "./structural/beam";
import { ShearWallOP } from "./structural/wall";
import { SlabHoleOP } from "./structural/slab-holes";
import { LoadConcentratedOP, LoadLinealOP, LoadSuperficialOP } from "./structural/load";
import { MoveLoadLinealVertexOP, MoveLoadSuperficialVertexOP } from "./structural/load-edit";
import { WallHoleOP } from "./structural/wall-holes";
import { FooterOP } from "./structural/footer";
import { PileCapOp } from './structural/pile-cap';
import { BeamMoveVertexOP } from "./structural/beam-edit";
import { WallMoveVertexOP } from "./structural/wall-edit";
import { AddPolyLineVertexOP } from "./edition/polyline-add-vertex";

export enum cadOpType {
  SELECT = 0,
  GETPOINT,
  MULTIOP,
  EDITGRIP,
  WRKPLANE3P,
  WRKPLANEVECTOR,

  // Clipboard
  DELETE,
  COPY,
  PASTE,
  PASTEORIENT,
  MPASTE,
  CUT,
  PASTEGROUP,

  // Primitivas 2D
  POINTS,
  BLOCK,
  EDITBLOCK,
  BLOCKREF,
  EDITBLOCKREF,
  NOTSIMBEDIT,
  LINE,
  SEGMENTS,
  POLYLINE,
  POLYLINE2D,
  POLYLINEI,
  ARC,
  POLYGON,
  RECTANGLE,
  SQUARE,
  CIRCLE,
  ELLIPSE,
  ELLIPSEARC,
  TEXT,
  TEXTP,
  TEXTEDIT,

  EDITPTO,
  ADDPOLYLINEVERTEX,
  EDITPOLYLINEVERTEX,
  EDITPOLYLINEEDGE,
  EDITREGION,
  ADDLINEVERTEX,
  EDITAZIMUTLINE,
  EDITPOLYLINEARC,
  EDITPOLYGON,
  EDITSQUARE,
  EDITRECTANGLE,
  EDITCIRCLE,
  EDITARC,
  EDITELLIPSE,
  EDITELLIPSEARC,
  EDITCUBE,
  EDITCYLINDER,
  EDITPLANE,
  EDITCONE,
  EDITPPRISM,
  EDITRPRISM,

  MOVE,
  ROTATE,
  SCALE,
  EXPLODE,
  MIRROR,
  FLIPH,
  FLIPV,
  OFFSET,
  OFFSETBUFFER,
  OFFSETCORRIDOR,
  MATRIX,
  MATRIXRAN,
  MATRIXP,
  REGION,

  // Grupos
  GROUP,
  UNGROUP,
  GROUPEDIT,

  // Operaciones con líneas
  TRIMLINES,
  DIVIDE,
  MEASURE,
  EXTEND,
  SPLITLINE,
  INTERSECTPOINTS,
  CHAMFER,
  ROUNDCHAMFER,
  JOINLINES,
  MERGELINES,
  GENERALIZE,
  DENSIFY,
  REMOVEREPEATED,
  INVERTPOLYLINE,
  OPENCLOSELINE,
  EXPLODEVERTEXES,
  INTERSECTENCLOSURE,
  MIDDLELINE,

  // Acotaciones
  QUICKDIM,
  ALIGNEDDIM,
  LINEARDIM,
  ARCLONGDIM,
  ARCANGLEDIM,
  RADIUSDIM,
  DIAMETERDIM,
  ANGLEDIM,
  MULTILINEARDIM,
  AREADIM,
  SIMBDIM,
  GALIBODIM,
  EDITALIGNEDDIM,
  EDITLINEARDIM,
  EDITARCANGLEDIM,
  EDITARCLONGDIM,
  EDITRADIUSDIM,
  EDITDIAMETERDIM,
  EDITANGLEDIM,

  // Anotaciones
  ANOTACION,
  NOTTABLE,
  NOTSIMB,
  NOTSTYLE,

  PAGES,
  // Primitivas 3D
  CUBE,
  RPRISM,
  PPRISM,
  CYLINDER,
  TUBE,
  CONE,
  SPHERE,
  TORUS,
  PYRAMID,
  WEDGE,
  PLANE,

  // Extrusiones
  EXTRUDE,
  EXTRUDEREVOLUTION,
  EXTRUDESOLEVATED,

  // Modificadores de Mesh
  SKREW,
  ALIGN,

  // Medicion de distancia entre 2 puntos dados.
  DISTANCEMEASURE,

  MATCHPROP,
  STRETCH,

  BEAM,
  COLUMN,
  SLAB,
  SLABHOLE,
  WALL,
  WALLHOLE,
  LOADCONCENTRATED,
  LOADLINEAL,
  LOADSUPERFICIAL,
  FOOTER,
  PILECAP,

  EDITBEAM,
  EDITCOLUMN,
  EDITSLAB,
  EDITSLABHOLE,
  EDITWALL,
  EDITLOAD,
  EDITLOADCONCENTRATED,
  EDITLOADLINEAL,
  EDITLOADSUPERFICIAL,
  
  EDITFOOTER,
  EDITSTRIPFOOTER,
  EDITPILECAP,

  CHANGESTYLE,
  ADD,
}
interface IOpInfo {
  pubName: string;
  constructorOp?: new (...args: any[]) => Cad3dOp;
}

export const OpFactory = {

  cadOpMap: new Map<cadOpType, IOpInfo>(),

  createOP<T extends typeof Cad3dOp>(newtype: cadOpType, tre: GraphicProcessor, args?: ConstructorParameters<T>): InstanceType<T> | null {
    if (OpFactory.cadOpMap.has(newtype)) {
      if (tre.currentOp && !tre.currentOp.finished) {
        // Continuo en la misma operación
        if (tre.currentOp.opType === newtype) return null;
        // Cancelo operacion, para a continuación lanzar otra nueva
        OpFactory.stopOp(tre);
      }
      if (args === undefined) args = [] as ConstructorParameters<T>;
      if (newtype !== cadOpType.SELECT) {
        tre.lastOpStack.push({ type: newtype, args: args.slice() });
      }

      const op = OpFactory.instanciateOP<T>(newtype, tre, args) as InstanceType<T>;
      tre.currentOp = op;
      op.start();
      op.dispatchStartOperation();
      return op;
    }
    throw new Error("No manejamos " + newtype);
  },

  instanciateOP<T extends typeof Cad3dOp>(newtype: cadOpType, tre: GraphicProcessor, args: ConstructorParameters<T>) {
    if (OpFactory.cadOpMap.has(newtype)) {
      const info = OpFactory.cadOpMap.get(newtype)!;
      const opConst = info.constructorOp!;
      const op = new opConst(...args);
      op.GraphicProcessor = tre;
      return op;
    }
    throw new Error("No manejamos " + newtype);
  },

  stopOp(tre: GraphicProcessor) {
    if (tre.currentOp) {
      const op = tre.currentOp;
      op.launchSelectOnFinish = false;
      op.cancelOperation();
      tre.currentOp = null;
    }
  },

  repeatOP(tre: GraphicProcessor) {
    const lastOP = tre.lastOpStack.last();
    if (lastOP) {
      OpFactory.createOP<any>(lastOP.type, tre, lastOP.args.slice());
    }
  },

  finishOP(tre: GraphicProcessor) {
    tre.launchOP(cadOpType.SELECT);
  },

  cancelOP(tre: GraphicProcessor) {
    tre.launchOP(cadOpType.SELECT);
  },

  getPubOpName(type: cadOpType) {
    if (OpFactory.cadOpMap.has(type)) {
      const info = OpFactory.cadOpMap.get(type);
      if (info) return info?.pubName;
    }
    console.warn(`Undefined operation name for type='${type}'. Must be registered in operation factory.`);
    return "";
  },
}

export function iniCadFactory() {
  if (OpFactory.cadOpMap.size === 0) {
    OpFactory.cadOpMap.set(cadOpType.SELECT, {
      pubName: "Selection",
      constructorOp: SelectOP,
    });
    OpFactory.cadOpMap.set(cadOpType.MULTIOP, {
      pubName: "MultiOp",
      constructorOp: MultiOp,
    });
    OpFactory.cadOpMap.set(cadOpType.POINTS, {
      pubName: "Points",
      constructorOp: PointOP,
    });
    OpFactory.cadOpMap.set(cadOpType.EDITPTO, {
      pubName: "Edit point",
    });
    OpFactory.cadOpMap.set(cadOpType.LINE, {
      pubName: "Line",
      constructorOp: LineOP,
    });
    OpFactory.cadOpMap.set(cadOpType.POLYLINE, {
      pubName: "Polyline",
      constructorOp: PolyLineOP,
    });
    OpFactory.cadOpMap.set(cadOpType.ADDPOLYLINEVERTEX, {
      pubName: "Add polyline vertex",
      constructorOp: AddPolyLineVertexOP,
    });
    OpFactory.cadOpMap.set(cadOpType.EDITPOLYLINEVERTEX, {
      pubName: "Edit polyline vertex",
      constructorOp: MovePolyLineVertexOP,
    });
    OpFactory.cadOpMap.set(cadOpType.EDITPOLYLINEEDGE, {
      pubName: "Edit polyline edge",
      constructorOp: MovePolyLineEdgeOP,
    });
    OpFactory.cadOpMap.set(cadOpType.REGION, {
      pubName: "Region",
      constructorOp: RegionOP,
    });
    OpFactory.cadOpMap.set(cadOpType.EDITREGION, {
      pubName: "Edit Region",
      constructorOp: MoveRegionVertexOP,
    });
    OpFactory.cadOpMap.set(cadOpType.POLYGON, {
      pubName: "Polygon",
      constructorOp: PolygonOP,
    });
    OpFactory.cadOpMap.set(cadOpType.CIRCLE, {
      pubName: "Circle",
      constructorOp: CircleOP,
    });
    OpFactory.cadOpMap.set(cadOpType.CUBE, {
      pubName: "Cube",
      constructorOp: CubeOP,
    });
    OpFactory.cadOpMap.set(cadOpType.EDITCUBE, {
      pubName: "Edit cube",
    });
    OpFactory.cadOpMap.set(cadOpType.CYLINDER, {
      pubName: "Cylinder",
      constructorOp: CylinderOP,
    });
    OpFactory.cadOpMap.set(cadOpType.EDITCYLINDER, {
      pubName: "Edit cylinder",
    });
    OpFactory.cadOpMap.set(cadOpType.RPRISM, {
      pubName: "RPrism",
      constructorOp: RPrismOP,
    });
    OpFactory.cadOpMap.set(cadOpType.EDITRPRISM, {
      pubName: "Edit rectangular prism",
    });
    OpFactory.cadOpMap.set(cadOpType.PPRISM, {
      pubName: "PPrism",
      constructorOp: PPrismOP,
    });
    OpFactory.cadOpMap.set(cadOpType.EDITPPRISM, {
      pubName: "Edit polygon prism",
    });
    OpFactory.cadOpMap.set(cadOpType.PLANE, {
      pubName: "Plane",
      constructorOp: PlaneOP,
    });
    OpFactory.cadOpMap.set(cadOpType.EDITPLANE, {
      pubName: "Edit plane",
    });
    OpFactory.cadOpMap.set(cadOpType.CONE, {
      pubName: "Cone",
      constructorOp: ConeOP,
    });
    OpFactory.cadOpMap.set(cadOpType.EDITCONE, {
      pubName: "Edit cone",
    });
    OpFactory.cadOpMap.set(cadOpType.MOVE, {
      pubName: "Translation",
      constructorOp: TranslationOP,
    });
    OpFactory.cadOpMap.set(cadOpType.ROTATE, {
      pubName: "Rotate",
      constructorOp: RotateOP,
    });
    OpFactory.cadOpMap.set(cadOpType.SCALE, {
      pubName: "Scale",
      constructorOp: ScaleOP,
    });
    OpFactory.cadOpMap.set(cadOpType.OFFSETCORRIDOR, {
      pubName: "Offset",
      constructorOp: OffsetOP,
    });
    OpFactory.cadOpMap.set(cadOpType.MIRROR, {
      pubName: "Mirror",
      constructorOp: MirrorOP,
    });
    OpFactory.cadOpMap.set(cadOpType.EXTEND, {
      pubName: "Extend",
      constructorOp: ExtendOP,
    });
    OpFactory.cadOpMap.set(cadOpType.CHAMFER, {
      pubName: "Chamfer",
      constructorOp: StraightChamferOP,
    });
    OpFactory.cadOpMap.set(cadOpType.TRIMLINES, {
      pubName: "Trim",
      constructorOp: TrimLinesOP,
    });
    OpFactory.cadOpMap.set(cadOpType.MATRIX, {
      pubName: "Matrix",
      constructorOp: MatrixOP,
    });
    OpFactory.cadOpMap.set(cadOpType.MATRIXRAN, {
      pubName: "Matrix",
      constructorOp: MatrixOP,
    });
    OpFactory.cadOpMap.set(cadOpType.MATRIXP, {
      pubName: "Matrix",
      constructorOp: MatrixOP,
    });
    OpFactory.cadOpMap.set(cadOpType.ARC, {
      pubName: "ArcBase",
      constructorOp: ArcBaseOP,
    });
    OpFactory.cadOpMap.set(cadOpType.ELLIPSE, {
      pubName: "Ellipse",
      constructorOp: EllipseOP,
    });
    OpFactory.cadOpMap.set(cadOpType.ELLIPSEARC, {
      pubName: "EllipseArc",
      constructorOp: EllipseArcOP,
    });
    OpFactory.cadOpMap.set(cadOpType.ALIGN, {
      pubName: "Align",
      constructorOp: AlignOP,
    });
    OpFactory.cadOpMap.set(cadOpType.COPY, {
      pubName: "Copy",
      constructorOp: CopyOP,
    });
    OpFactory.cadOpMap.set(cadOpType.PASTE, {
      pubName: "Paste",
      constructorOp: PasteOP,
    });
    OpFactory.cadOpMap.set(cadOpType.CUT, {
      pubName: "Cut",
    });
    OpFactory.cadOpMap.set(cadOpType.DELETE, {
      pubName: "Delete",
    });
    OpFactory.cadOpMap.set(cadOpType.EDITCIRCLE, {
      pubName: "CircleEdit",
      constructorOp: CircleEditOP,
    });
    OpFactory.cadOpMap.set(cadOpType.EDITPOLYGON, {
      pubName: "PolygonEdit",
      constructorOp: PolygonEditOP,
    });
    OpFactory.cadOpMap.set(cadOpType.EDITELLIPSE, {
      pubName: "EllipseEdit",
      constructorOp: EllipseEditOP,
    });
    OpFactory.cadOpMap.set(cadOpType.EDITELLIPSEARC, {
      pubName: "EllipseArcEdit",
      constructorOp: EllipseArcEditOP,
    });
    OpFactory.cadOpMap.set(cadOpType.EDITARC, {
      pubName: "ArcEdit",
      constructorOp: ArcEditOP,
    });
    OpFactory.cadOpMap.set(cadOpType.TEXT, {
      pubName: "Text",
      constructorOp: TextOP,
    });
    OpFactory.cadOpMap.set(cadOpType.TEXTEDIT, {
      pubName: "Edit text",
    });
    OpFactory.cadOpMap.set(cadOpType.TEXTP, {
      pubName: "ParagraphText",
      constructorOp: TextParagraphOP,
    });
    OpFactory.cadOpMap.set(cadOpType.QUICKDIM, {
      pubName: "Quick dimension",
      constructorOp: QuickDimensionOP,
    });
    OpFactory.cadOpMap.set(cadOpType.DISTANCEMEASURE, {
      pubName: "Distance",
      constructorOp: DistanceMeasureOP,
    });
    OpFactory.cadOpMap.set(cadOpType.ALIGNEDDIM, {
      pubName: "Aligned dimension",
      constructorOp: AlignedDimensionOP,
    });
    OpFactory.cadOpMap.set(cadOpType.LINEARDIM, {
      pubName: "Lineal dimension",
      constructorOp: LinearDimensionOP,
    });
    OpFactory.cadOpMap.set(cadOpType.ARCANGLEDIM, {
      pubName: "Arc dimension",
      constructorOp: ArcAngleDimensionOP,
    });
    OpFactory.cadOpMap.set(cadOpType.ARCLONGDIM, {
      pubName: "Arc dimension",
      constructorOp: ArcLongDimensionOP,
    });
    OpFactory.cadOpMap.set(cadOpType.DIAMETERDIM, {
      pubName: "Diameter dimension",
      constructorOp: DiametroDimensionOP,
    });
    OpFactory.cadOpMap.set(cadOpType.RADIUSDIM, {
      pubName: "Diameter dimension",
      constructorOp: RadialDimensionOP,
    });
    OpFactory.cadOpMap.set(cadOpType.ANGLEDIM, {
      pubName: "Angular dimension",
      constructorOp: AngleDimensionOP,
    });
    OpFactory.cadOpMap.set(cadOpType.EDITALIGNEDDIM, {
      pubName: "Edit aligned dimension",
      constructorOp: EditAlignedDimensionOP,
    });
    OpFactory.cadOpMap.set(cadOpType.EDITLINEARDIM, {
      pubName: "Edit lineal dimension",
      constructorOp: EditLinearDimensionOP,
    });
    OpFactory.cadOpMap.set(cadOpType.EDITARCANGLEDIM, {
      pubName: "Edit arc angle dimension",
      constructorOp: EditArcAngleDimensionOP,
    });
    OpFactory.cadOpMap.set(cadOpType.EDITARCLONGDIM, {
      pubName: "Edit arc length dimension",
      constructorOp: EditArcLengthDimensionOP,
    });
    OpFactory.cadOpMap.set(cadOpType.EDITDIAMETERDIM, {
      pubName: "Edit diameter dimension",
      constructorOp: EditDiameterCircularDimensionOP,
    });
    OpFactory.cadOpMap.set(cadOpType.EDITRADIUSDIM, {
      pubName: "Edit radial dimension",
      constructorOp: EditRadialCircularDimensionOP,
    });
    OpFactory.cadOpMap.set(cadOpType.EDITANGLEDIM, {
      pubName: "Edit angular dimension",
      constructorOp: EditAngleDimensionOP,
    });
    OpFactory.cadOpMap.set(cadOpType.MEASURE, {
      pubName: "Measure Area",
      constructorOp: MeasureAreaOP,
    });
    OpFactory.cadOpMap.set(cadOpType.WRKPLANE3P, {
      pubName: "Create working plane",
      constructorOp: CreateWorkingPlaneFrom3PtosOP,
    });
    OpFactory.cadOpMap.set(cadOpType.WRKPLANEVECTOR, {
      pubName: "Create working plane",
      constructorOp: CreateWorkingPlaneFromPlaneVectorOP,
    });
    OpFactory.cadOpMap.set(cadOpType.BLOCK, {
      pubName: "Block",
      constructorOp: BlockOP,
    });
    OpFactory.cadOpMap.set(cadOpType.BLOCKREF, {
      pubName: "BlockRef",
      constructorOp: BlockRefOP,
    });
    OpFactory.cadOpMap.set(cadOpType.MATCHPROP, {
      constructorOp: MatchPropertiesOP,
      pubName: "Match properties",
    });
    OpFactory.cadOpMap.set(cadOpType.STRETCH, {
      pubName: "Stretch",
      constructorOp: StretchOP,
    });
    iniStrucCadFactory();
  }
}

export function iniStrucCadFactory() {
  OpFactory.cadOpMap.set(cadOpType.COLUMN, {
    pubName: "Column",
    constructorOp: ColumnOP,
  });
  OpFactory.cadOpMap.set(cadOpType.EDITCOLUMN, {
    pubName: "Edit column",
  });
  OpFactory.cadOpMap.set(cadOpType.SLAB, {
    pubName: "Slab",
    constructorOp: SlabOP,
  });
  OpFactory.cadOpMap.set(cadOpType.SLABHOLE, {
    pubName: "Slab hole",
    constructorOp: SlabHoleOP,
  });
  OpFactory.cadOpMap.set(cadOpType.EDITSLAB, {
    pubName: "Edit slab",
    constructorOp: MoveSlabVertexOP,
  });
  OpFactory.cadOpMap.set(cadOpType.EDITSLABHOLE, {
    pubName: "Edit slab hole",
    constructorOp: MoveSlabHoleVertexOP,
  });
  OpFactory.cadOpMap.set(cadOpType.BEAM, {
    pubName: "Beam",
    constructorOp: BeamOP,
  });
  OpFactory.cadOpMap.set(cadOpType.EDITBEAM, {
    pubName: "Edit beam",
    constructorOp: BeamMoveVertexOP,
  });
  OpFactory.cadOpMap.set(cadOpType.WALL, {
    pubName: "Wall",
    constructorOp: ShearWallOP,
  });
  OpFactory.cadOpMap.set(cadOpType.WALLHOLE, {
    pubName: "Wall hole",
    constructorOp: WallHoleOP,
  });
  OpFactory.cadOpMap.set(cadOpType.FOOTER, {
    pubName: "Footer",
    constructorOp: FooterOP,
  });
  OpFactory.cadOpMap.set(cadOpType.PILECAP, {
    pubName: "Pile cap",
    constructorOp: PileCapOp,
  });
  OpFactory.cadOpMap.set(cadOpType.EDITPILECAP, {
    pubName: "Edit pile cap",
  });
  OpFactory.cadOpMap.set(cadOpType.EDITWALL, {
    pubName: "Edit wall",
    constructorOp: WallMoveVertexOP,
  });
  OpFactory.cadOpMap.set(cadOpType.LOADCONCENTRATED, {
    pubName: "Load concentrated",
    constructorOp: LoadConcentratedOP,
  });
  OpFactory.cadOpMap.set(cadOpType.LOADLINEAL, {
    pubName: "Load lineal",
    constructorOp: LoadLinealOP,
  });
  OpFactory.cadOpMap.set(cadOpType.LOADSUPERFICIAL, {
    pubName: "Load superficial",
    constructorOp: LoadSuperficialOP,
  });
  OpFactory.cadOpMap.set(cadOpType.EDITLOADLINEAL, {
    pubName: "Edit load lineal",
    constructorOp: MoveLoadLinealVertexOP,
  });
  OpFactory.cadOpMap.set(cadOpType.EDITLOAD, {
    pubName: "Edit load",
  });
  OpFactory.cadOpMap.set(cadOpType.EDITLOADSUPERFICIAL, {
    pubName: "Edit load superficial",
    constructorOp: MoveLoadSuperficialVertexOP,
  });
}
