import { Project } from "modules/lite/models/project";
import { FC } from "react";
import { SecondaryLink } from "shared/components/ui/link";
import RenderIf from "shared/components/ui/render-if";
import Section from "shared/components/ui/section";
import MapView from "../ui/map/map-view";

interface Props {
  project: Project;
}
const ProjectDetails: FC<Props> = ({ project }) => {
  const { address } = project;
  return (
    <Section title="Details">
      <dl className="grid grid-cols-1 gap-4 sm:grid-cols-2">        
        <div className="sm:col-span-1 space-y-6">
          <div>
            <dt className="text-sm font-medium text-gray-500">Name</dt>
            <dd className="mt-1 text-sm text-gray-900">{project.name}</dd>
          </div>
          <div>
            <dt className="text-sm font-medium text-gray-500">Address</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {`${address?.street}, ${address?.number} ${address?.city}, ${address?.country} ${address?.zip}`}
            </dd>
          </div>
          <div>
            <dt className="text-sm font-medium text-gray-500">Footing level</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {`${project.footing_level} m`}
            </dd>
          </div>
          <div>
            <dt className="text-sm font-medium text-gray-500">
              Number of storeys
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {project.number_of_storeys}
            </dd>
          </div>
          <div>
            <dt className="text-sm font-medium text-gray-500">
              Seismic acc factor
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {`${project.seismic_acc ?? 0} * g`}
            </dd>
          </div>
        </div>
        <div className="sm:col-span-1">
          <dt className="flex justify-end">
          <SecondaryLink to={`/lite/projects/${project?.id}/edit`}>
            Edit
          </SecondaryLink>
          </dt>
          {/* <dt className="text-sm font-medium text-gray-500">Location</dt>
          <dd className="mt-1">
            <RenderIf isTrue={!!project.location}>
              <MapView location={project.location} />
            </RenderIf>
          </dd> */}
        </div>
      </dl>
    </Section>
  );
};

export default ProjectDetails;
