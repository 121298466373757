const MiddleIcon = ({ ...props }) => {
  return (
    <svg className="h-5 w-5" viewBox="0 0 100 100" fill="none" {...props}>
      <path
        d="M94.6124 5.38752C92.9832 3.75836 90.3499 3.75836 88.7207 5.38752L5.3874 88.7209C3.75824 90.35 3.75824 92.9834 5.3874 94.6125C6.1999 95.425 7.26657 95.8334 8.33324 95.8334C9.3999 95.8334 10.4666 95.425 11.2791 94.6125L94.6124 11.2792C96.2416 9.65002 96.2416 7.01669 94.6124 5.38752Z"
        fill="currentColor"
      />
      <path
        d="M58 42V59H41V42H58Z"
        fill="transparent"
        stroke="currentColor"
        strokeWidth="8"
      />
    </svg>
  );
};

export default MiddleIcon;
