import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";

interface Definintion {
  kind: string;
  operation?: string;
}
const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key: string, value: any) => key === "__typename" ? undefined : value;
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }
  return forward(operation).map((data) => {
    return data;
  });
});

const httpUri = process.env.REACT_APP_GRAPHQL_BACKEND_URL as string;
const wsUri = httpUri.replace(/^https?/, process.env.REACT_APP_ENV === "dev" ? "wss" : "wss");

const httpLink = new HttpLink({ uri: httpUri });
const httpLinkWithMiddleware = ApolloLink.from([cleanTypeName, httpLink]);

const wsLink = new WebSocketLink({
  uri: wsUri,
  options: {
    reconnect: true,
  },
});

const link = split(
  ({ query }) => {
    const { kind, operation }: Definintion = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  httpLinkWithMiddleware
);

const cache = new InMemoryCache();

export default new ApolloClient({
  link,
  cache,
});
