import { useCallback, useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import Checkbox from "shared/components/ui/checkbox";
import PropertiesInput from "./component-input";
import PropertiesSelect from "./component-select";
import PropertiesButton from './component-button';
import { useUI } from "shared/components/ui/context";
import { propSetting } from "lib/operations/step-operations";
import { setFromString, getHexString } from "lib/math/color";
import { IColor, isIColor } from "lib/math/types";
import PropertiesColor from "./component-color";
import PropertiesRange from "./component-range";
import { dataInfoProperty, infoProperty, isInfoProperty, IValueProperty } from "lib/properties/properties";
import PropertiesRadioButton from "./component-radiobtn";
import React from "react";

interface EntityField {
  key: string;
  label: string;
  type: "number" | "checkbox" | "string" | "list" | "color" | "button" | "range" | "radio";
  value?: number | string | boolean | IColor | null;
  title?: string;
  list?: [string, number | string][];
  editable?: boolean;
  max?: number;
  min?: number;
  step?: number;
  buttonCb?: () => void;
  customCb?: (val: any) => void;
  units?: string;
}
function createFieldsFromDefinition<T>(definition?: dataInfoProperty<T>, parentPublicName?: string, parentKey?: string): (EntityField | EntityField[])[] {
  if (!definition) return [];
  const fields: (EntityField | EntityField[])[] = [];
  for (const key in definition) {
    const rawField = definition[key];
    if (rawField) {
      if (isInfoProperty(rawField)) {
        const field = buildField(rawField, key, parentPublicName, parentKey);
        fields.push(field);
      } else {
        if (Array.isArray(rawField)) {
          const arrayfields: EntityField[] = [];
          for (let i = 0; i < rawField.length; i++) {
            const field = rawField[i];
            const pathKey = `${key}.${i}`;
            const nestedFields: any = createFieldsFromDefinition(field, undefined, pathKey);
            arrayfields.push(nestedFields);
          }
          fields.push(arrayfields);

        } else {
          const { publicName, ...rest } = rawField as dataInfoProperty<any> & { publicName: string };
          const pathKey = parentKey ? `${parentKey}.${key}` : key;
          const nestedFields: any = createFieldsFromDefinition(rest as dataInfoProperty<any>, publicName, pathKey);
          fields.push(...nestedFields);
        }
      }
    }
  }
  return fields;
};
function buildField(rawField: infoProperty, key: string, parentPublicName?: string, parentKey?: string): EntityField {
  const field = {} as EntityField;
  field.key = parentKey ? `${parentKey}.${key}` : key;
  field.label = parentPublicName ? `${parentPublicName} / ${rawField.publicName}` : rawField.publicName;
  field.title = rawField.title;
  field.type = rawField.type === "tagList" ? "list" : rawField.type;
  if (rawField.type === "button") {
    field.buttonCb = rawField.buttonCb;
    field.editable = rawField.editable === undefined ? false : rawField.editable;
  } else {
    field.editable = rawField.editable;
    field.customCb = rawField.customCb;
    field.value = typeof rawField.value === 'function' ? rawField.value() : rawField.value;
    switch (rawField.type) {
      case "number":
        if (rawField.precision) field.value = (field.value as number).toFixed(rawField.precision);
        field.units = rawField.units;
        field.max = rawField.max;
        field.min = rawField.min;
        break;
      case "string":
      case "checkbox":
      case "color":
        break;
      case "radio":
      case "list":
        field.list = rawField.list.map((s, i) => ([s, i]));
        break;
      case "tagList":
        field.list = rawField.tagList;
        break;
      case "range":
        field.step = rawField.step;
        field.max = rawField.max;
        field.min = rawField.min;
        break;
      default:
        break;
    }
  }
  return field;
};


const PanelPropertiesForm = <T extends object>({ panelProp }: { panelProp: propSetting<T> }) => {

  const { openToast } = useUI()

  const errors = useRef<string[]>([]);

  const { register, handleSubmit, reset, getValues } = useForm();

  const fields: (EntityField | EntityField[])[] = useMemo(() => {
    return createFieldsFromDefinition<T>(panelProp.propValue)
  }, [panelProp]);

  useEffect(() => {
    reset(fields);
  }, [fields, reset]);

  const formatValues = useCallback((rawValues: T, oriValues: Record<keyof T, IValueProperty<T[keyof T]>>) => {
    const values = Array.isArray(rawValues) ? [] : {} as any;
    let key: keyof T;
    for (key in rawValues) {
      const value = rawValues[key];

      if (value && typeof value === "object" && !isIColor(value)) {
        values[key] = formatValues(value as any, oriValues[key] as any);
      } else {

        const prop = oriValues[key];
        const oldValue = prop.value;
        if (value === undefined) {
          values[key] = oldValue ?? prop;
        } else {
          if (prop.check) {
            if (prop.check(value)) {
              values[key] = prop.parseFun ? prop.parseFun(value) : value;
            } else {
              if (prop.checkError) errors.current.push(prop.checkError);
              values[key] = oldValue;
              reset();
            }
          } else {
            if (key === "color") {
              const alpha = (rawValues as any).opacity;
              if (alpha !== undefined) (value as any).a = alpha;
            }
            values[key] = prop.parseFun ? prop.parseFun(value) : value;
          }
        }
      }
    }
    return values as T;
  }, [reset]);

  const updateData = useCallback((rawValues: any) => {
    errors.current.length = 0;
    const values = formatValues(rawValues, panelProp.propValue);
    if (errors.current.length) {
      openToast({
        title: errors.current[0],
        status: 'error',
      })
    }
    if (panelProp?.propCallback && values) {
      panelProp.propCallback(values);
    }
  }, [formatValues, panelProp, openToast]);

  function getPropertyInput(entity: EntityField, key: any) {
    return (
      <div key={entity.key}>
        {entity.title && getTitleHeader(entity.title)}
        <PropertiesInput
          defaultValue={entity.value as string | number}
          id={entity.key}
          disabled={entity.editable === false}
          key={key}
          label={entity.label || ""}
          name={entity.key}
          ref={register({
            setValueAs: (value: string | number) => {
              if (entity.type === "string") return value;
               return isNaN(+value) ? value : +value;
            },
          })}
          onBlur={() => {
            const a = getValues();
            if (entity.customCb) entity.customCb(a);
            updateData(a);
          }}
          onSubmit={() => {
            if (entity.customCb) entity.customCb(getValues);
          }}
          adornment={<span>{entity.units}</span>}
        />
      </div>
    );
  }
  function getPropertyCheckBox(entity: EntityField, key: any) {
    return (
      <div key={entity.key}>
        {entity.title && getTitleHeader(entity.title)}
        <Checkbox
          className="px-1 py-0 text-xs"
          defaultChecked={entity.value as boolean}
          // checked={entity.value as boolean}
          disabled={entity.editable === false}
          id={entity.key}
          key={key}
          label={entity?.label || ""}
          name={entity.key}
          ref={register}
          onChange={() => {
            const a = getValues();
            if (entity.customCb) entity.customCb(a)
            updateData(a);
          }}
        />
      </div>
    );
  }
  function getPropertySelect(entity: EntityField) {
    return (
      <div key={entity.key}>
        {entity.title && getTitleHeader(entity.title)}
        <PropertiesSelect
          key={entity.key + "Sel"}
          id={entity.key}
          label={entity.label}
          disabled={entity.editable === false}
          name={entity.key}
          ref={register({
            setValueAs: (value: string | number) =>
              (isNaN(+value) ? value : +value),
          })}
          value={entity.value as string}
          onChange={() => {
            const a = getValues();
            if (entity.customCb) entity.customCb(a)
            updateData(a);
          }}
        >
          {entity.list?.map((val, i) => (
            <option key={i} value={val[1]}>
              {val[0]}
            </option>
          ))}
        </PropertiesSelect>
      </div>
    );
  }
  function getButtonAction(entity: EntityField) {
    return (
      <div key={entity.key}>
        {entity.title && getTitleHeader(entity.title)}
        <PropertiesButton
          id={entity.key}
          disabled={entity.editable}
          label={entity.label || ""}
          key={entity.key}
          buttonCb={entity.buttonCb as () => void}
        />
      </div>
    );
  }
  function getPropertyColor(entity: EntityField, key: any) {
    const { r, g, b } = entity.value as IColor;
    return (
      <div key={entity.key}>
        {entity.title && getTitleHeader(entity.title)}
        <PropertiesColor
          id={entity.key}
          label={entity.label}
          disabled={entity.editable === false}
          name={entity.key}
          key={key}
          ref={register({ setValueAs: setFromString })}
          onChange={() => {
            const a = getValues();
            if (entity.customCb) entity.customCb(a)
            updateData(a);
          }}
          defaultValue={getHexString(r, g, b)}
        />
      </div>
    );
  }
  function getPropertyRange(entity: EntityField, key: any) {
    return (
      <div key={entity.key}>
        {entity.title && getTitleHeader(entity.title)}
        <PropertiesRange
          defaultValue={entity.value as string | number}
          id={entity.key}
          disabled={entity.editable === false}
          key={key}
          label={entity.label || ""}
          name={entity.key}
          ref={register({ setValueAs: parseFloat })}
          onChange={() => {
            const a = getValues();
            if (entity.customCb) entity.customCb(a)
            updateData(a);
          }}
          max={entity.max}
          min={entity.min}
          step={entity.step}
          adornment={<span>{entity.units}</span>} />
      </div>
    );
  }
  function getPropertyRadio(entity: EntityField, key: any) {
    return (
      <div key={entity.key}>
        {entity.title && getTitleHeader(entity.title)}
        <PropertiesRadioButton
          defaultValue={entity.value as string | number}
          listItems={entity.list}
          id={entity.key}
          disabled={entity.editable === false}
          key={key}
          name={key}
          ref={register}
          onChange={() => {
            const a = getValues();
            if (entity.customCb) entity.customCb(a)
            updateData(a);
          }}>
        </PropertiesRadioButton>
      </div>
    );
  }
  function getTitleHeader(title: string) {
    return (
      <div className="text-sm pb-2">
        <span>{title}</span>
      </div>
    )
  }

  const renderArrayFields = (field: (EntityField | EntityField[])[]): any => {
    return (
      field.map((cf: EntityField | EntityField[]) => {
        if (Array.isArray(cf)) {
          if (Array.isArray(cf[0])) {
            return renderArrayFields(cf)
          } else {
            return (
              <div className="border-t border-gray-700 pt-2" key={cf[0].key} >
                {renderArrayFields(cf)}
              </div>)
          }
        } else {
          if (cf.type === "button") return getButtonAction(cf);
          if (cf.value !== undefined && cf.value !== null) {
            if (cf.type === "checkbox") return getPropertyCheckBox(cf, cf.key);
            if (cf.type === "list") return getPropertySelect(cf);
            if (cf.type === "color") return getPropertyColor(cf, cf.key);
            if (cf.type === "range") return getPropertyRange(cf, cf.key);
            if (cf.type === "radio") return getPropertyRadio(cf, cf.key);
            return getPropertyInput(cf, cf.key);
          }
          return undefined;
        }
      })
    )
  };

  return (
    <form onSubmit={handleSubmit(updateData)}>
      <div className="grid grid-cols-1 gap-2">
        {renderArrayFields(fields)}
        <input type="submit" className="sr-only" tabIndex={-1} />
      </div>
    </form>
  );
};

export default PanelPropertiesForm;
export const MemoizedPanelPropertiesForm = React.memo(PanelPropertiesForm);