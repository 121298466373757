import { useQuery } from "@apollo/client";
import { CADProject } from "modules/cad/models/projects";
import { GET_CAD_PROJECT } from "modules/cad/services/projects";

interface QueryResult {
  project: CADProject;
}

const useProject = (id: string) => {

  const { data, error, loading } = useQuery<QueryResult>(GET_CAD_PROJECT, {
    variables: { id },
    fetchPolicy: "network-only",
  });
  return {
    project: data?.project,
    error,
    loading,
  };
};

export default useProject;
