import { createExtrusionMesh } from './extrusion';
import { createCylinder } from '../solid/cylinder';
import { getAuxMeshBasicMaterial } from 'lib/materials';
import { IPoint } from 'lib/math/types';
import { mulIpoint } from '../../math/point';
import { generateRectangularSection } from 'lib/models-struc/cross-sections-shape/beam-cross-sections/utils';

export function getPileCapFromTemplate(capCoords: IPoint[], pileCoords: IPoint[], pileDiameter: number, depth: number, height: number, pilePenetration: number, material?: THREE.Material) {
  const capCoord = capCoords.map(p => mulIpoint(p, pileDiameter));
  capCoord.push(capCoord[0]);
  const pileCap = createExtrusionMesh(capCoord, depth, [], material ?? getAuxMeshBasicMaterial());
  pileCap.geometry.translate(0, 0, -depth);
  for (const pile of pileCoords) {
    const p = createCylinder(pileDiameter * 0.5, height, material ?? getAuxMeshBasicMaterial());
    p.geometry.translate(0, 0, -height * 0.5 - depth + pilePenetration);
    p.position.set(pile.x * pileDiameter, pile.y * pileDiameter, pile.z);
    pileCap.add(p);
  }
  return pileCap;
}
export function getPileCap(numberPiles: number, pileDiameter: number, depth: number, height: number, pilePenetration: number, material?: THREE.Material) {
  let params: { capCoords: IPoint[], pileCoords: IPoint[] };
  switch (numberPiles) {
    case 2: params = defaultPileCapBuilder.get2PileCap(pileDiameter); break;
    case 3: params = defaultPileCapBuilder.get3PileCap(pileDiameter); break;
    case 4: params = defaultPileCapBuilder.get4PileCap(pileDiameter); break;
    case 5: params = defaultPileCapBuilder.get5PileCap(pileDiameter); break;
    case 6: params = defaultPileCapBuilder.get6PileCap(pileDiameter); break;
    case 7: params = defaultPileCapBuilder.get7PileCap(pileDiameter); break;
    case 8: params = defaultPileCapBuilder.get8PileCap(pileDiameter); break;
    case 9: params = defaultPileCapBuilder.get9PileCap(pileDiameter); break;
    case 10: params = defaultPileCapBuilder.get10PileCap(pileDiameter); break;
    case 11: params = defaultPileCapBuilder.get11PileCap(pileDiameter); break;
    case 12: params = defaultPileCapBuilder.get12PileCap(pileDiameter); break;
    case 13: params = defaultPileCapBuilder.get13PileCap(pileDiameter); break;
    case 14: params = defaultPileCapBuilder.get14PileCap(pileDiameter); break;
    case 15: params = defaultPileCapBuilder.get15PileCap(pileDiameter); break;
    case 16: params = defaultPileCapBuilder.get16PileCap(pileDiameter); break;
    case 17: params = defaultPileCapBuilder.get17PileCap(pileDiameter); break;
    case 18: params = defaultPileCapBuilder.get18PileCap(pileDiameter); break;
    case 19: params = defaultPileCapBuilder.get19PileCap(pileDiameter); break;
    case 20: params = defaultPileCapBuilder.get20PileCap(pileDiameter); break;
    default:
      console.warn("[PILE CAP] Configuration not defined. 2 Piles built")
      params = defaultPileCapBuilder.get2PileCap(pileDiameter); break;
  }
  params.capCoords.push(params.capCoords[0]);
  const pileCap = createExtrusionMesh(params.capCoords, depth, [], material ?? getAuxMeshBasicMaterial());
  pileCap.geometry.translate(0, 0, -depth);
  for (const pile of params.pileCoords) {
    const p = createCylinder(pileDiameter * 0.5, height, material ?? getAuxMeshBasicMaterial());
    p.geometry.translate(0, 0, -height * 0.5 - pileDiameter + pilePenetration);
    p.position.set(pile.x * pileDiameter, pile.y * pileDiameter, pile.z);
    pileCap.add(p);
  }
  return pileCap;
}
export const defaultPileCapBuilder = {
  get2PileCap(pileDiameter: number = 1) {
    const X = 5.5 * pileDiameter;
    const Y = 2.5 * pileDiameter;
    const pileCoords = [
      { x: -1.5, y: 0, z: 0 },
      { x: 1.5, y: 0, z: 0 }
    ];
    const capCoords = generateRectangularSection(X, Y);
    return { capCoords, pileCoords };
  },
  get3PileCap(pileDiameter: number = 1) {
    const X = 5.5 * pileDiameter;
    const Y = 5.125 * pileDiameter;
    const E = (X - 3 * pileDiameter) / 2;
    const _in = (2.5 - 2) / 6 * pileDiameter;
    const A = 18 * _in;
    const B = 19 * _in;
    const xmin = -pileDiameter * 1.5 - E;
    const xmax = pileDiameter * 1.5 + E;
    const ymin = -pileDiameter * 0.875 - E;
    const ymax = Y + (-pileDiameter * 0.875 - E);
    const capCoords = [
      { x: xmin, y: ymin, z: 0 },
      { x: xmin, y: ymin + B, z: 0 },
      { x: -A * 0.5, y: ymax, z: 0 },
      { x: A * 0.5, y: ymax, z: 0 },
      { x: xmax, y: ymin + B, z: 0 },
      { x: xmax, y: ymin, z: 0 },
      { x: xmin, y: ymin, z: 0 }
    ];
    const pileCoords = [
      { x: 0, y: 1.75, z: 0 },
      { x: - 1.5, y: - 0.875, z: 0 },
      { x: 1.5, y: - 0.875, z: 0 },
    ];
    return { capCoords, pileCoords };
  },
  get4PileCap(pileDiameter: number = 1) {
    const X = 5.5 * pileDiameter;
    const Y = 5.5 * pileDiameter;
    const pileCoords = [
      { x: - 1.5, y: - 1.5, z: 0 },
      { x: - 1.5, y: 1.5, z: 0 },
      { x: 1.5, y: - 1.5, z: 0 },
      { x: 1.5, y: 1.5, z: 0 },
    ];
    const capCoords = generateRectangularSection(X, Y);
    return { capCoords, pileCoords };
  },
  get5PileCap(pileDiameter: number = 1) {
    const X = 6.75 * pileDiameter;
    const Y = 6.75 * pileDiameter;
    const pileCoords = [
      { x: -  2.125, y: - 2.125, z: 0 },
      { x: -  2.125, y: 2.125, z: 0 },
      { x: 2.125, y: - 2.125, z: 0 },
      { x: 2.125, y: 2.125, z: 0 },
      { x: 0, y: 0, z: 0 },
    ];
    const capCoords = generateRectangularSection(X, Y);
    return { capCoords, pileCoords };
  },
  get6PileCap(pileDiameter: number = 1) {
    const X = 8.5 * pileDiameter;
    const Y = 5.5 * pileDiameter;
    const pileCoords = [
      { x: - 3, y: - 1.5, z: 0 },
      { x: - 3, y: 1.5, z: 0 },
      { x: 0, y: - 1.5, z: 0 },
      { x: 0, y: 1.5, z: 0 },
      { x: 3, y: - 1.5, z: 0 },
      { x: 3, y: 1.5, z: 0 },
    ];
    const capCoords = generateRectangularSection(X, Y);
    return { capCoords, pileCoords };
  },
  get7PileCap(pileDiameter: number = 1) {
    const X = 8.5 * pileDiameter;
    const Y = 7.75 * pileDiameter;
    const pileCoords = [
      { x: -1.5, y: 2.625, z: 0 },
      { x: 1.5, y: 2.625, z: 0 },
      { x: -3, y: 0, z: 0 },
      { x: 0, y: 0, z: 0 },
      { x: 3, y: 0, z: 0 },
      { x: - 1.5, y: - 2.625, z: 0 },
      { x: 1.5, y: - 2.625, z: 0 },
    ];
    const capCoords = generateRectangularSection(X, Y);
    return { capCoords, pileCoords };
  },
  get8PileCap(pileDiameter: number = 1) {
    const X = 8.5 * pileDiameter;
    const Y = 7.75 * pileDiameter;
    const pileCoords = [
      { x: -3, y: 2.625, z: 0 },
      { x: 0, y: 2.625, z: 0 },
      { x: 3, y: 2.625, z: 0 },
      { x: -1.5, y: 0, z: 0 },
      { x: 1.5, y: 0, z: 0 },
      { x: - 3, y: -2.625, z: 0 },
      { x: 0, y: -2.625, z: 0 },
      { x: 3, y: -2.625, z: 0 },
    ];
    const capCoords = generateRectangularSection(X, Y);
    return { capCoords, pileCoords };
  },
  get9PileCap(pileDiameter: number = 1) {
    const X = 8.5 * pileDiameter;
    const Y = 8.5 * pileDiameter;
    const pileCoords = [
      { x: -3, y: 3, z: 0 },
      { x: 0, y: 3, z: 0 },
      { x: 3, y: 3, z: 0 },
      { x: - 3, y: 0, z: 0 },
      { x: 0, y: 0, z: 0 },
      { x: 3, y: 0, z: 0 },
      { x: - 3, y: - 3, z: 0 },
      { x: 0, y: - 3, z: 0 },
      { x: 3, y: - 3, z: 0 },
    ];
    const capCoords = generateRectangularSection(X, Y);
    return { capCoords, pileCoords };
  },
  get10PileCap(pileDiameter: number = 1) {
    const X = 11.5 * pileDiameter;
    const Y = 7.75 * pileDiameter;
    const pileCoords = [
      { x: -3, y: 2.625, z: 0 },
      { x: 0, y: 2.625, z: 0 },
      { x: 3, y: 2.625, z: 0 },
      { x: -4.5, y: 0, z: 0 },
      { x: -1.5, y: 0, z: 0 },
      { x: 1.5, y: 0, z: 0 },
      { x: 4.5, y: 0, z: 0 },
      { x: -3, y: - 2.625, z: 0 },
      { x: 0, y: - 2.625, z: 0 },
      { x: 3, y: - 2.625, z: 0 },
    ];
    const capCoords = generateRectangularSection(X, Y);
    return { capCoords, pileCoords };
  },
  get11PileCap(pileDiameter: number = 1) {
    const X = 11.5 * pileDiameter;
    const Y = 7.75 * pileDiameter;
    const pileCoords = [
      { x: -4.5, y: 2.625, z: 0 },
      { x: -1.5, y: 2.625, z: 0 },
      { x: 1.5, y: 2.625, z: 0 },
      { x: 4.5, y: 2.625, z: 0 },

      { x: -3, y: 0, z: 0 },
      { x: 0, y: 0, z: 0 },
      { x: 3, y: 0, z: 0 },

      { x: -4.5, y: -2.625, z: 0 },
      { x: -1.5, y: -2.625, z: 0 },
      { x: 1.5, y: -2.625, z: 0 },
      { x: 4.5, y: -2.625, z: 0 },
    ];
    const capCoords = generateRectangularSection(X, Y);
    return { capCoords, pileCoords };
  },
  get12PileCap(pileDiameter: number = 1) {
    const X = 11.5 * pileDiameter;
    const Y = 8.5 * pileDiameter;
    const pileCoords = [
      { x: -4.5, y: 3, z: 0 },
      { x: -1.5, y: 3, z: 0 },
      { x: 1.5, y: 3, z: 0 },
      { x: 4.5, y: 3, z: 0 },

      { x: -4.5, y: 0, z: 0 },
      { x: -1.5, y: 0, z: 0 },
      { x: 1.5, y: 0, z: 0 },
      { x: 4.5, y: 0, z: 0 },

      { x: -4.5, y: -3, z: 0 },
      { x: -1.5, y: -3, z: 0 },
      { x: 1.5, y: -3, z: 0 },
      { x: 4.5, y: -3, z: 0 },
    ];
    const capCoords = generateRectangularSection(X, Y);
    return { capCoords, pileCoords };
  },
  get13PileCap(pileDiameter: number = 1) {
    const X = 13 * pileDiameter;
    const Y = 8.5 * pileDiameter;
    const pileCoords = [
      { x: -5.25, y: 3, z: 0 },
      { x: 0, y: 3, z: 0 },
      { x: 5.25, y: 3, z: 0 },

      { x: -2.625, y: 1.5, z: 0 },
      { x: 2.625, y: 1.5, z: 0 },

      { x: -5.25, y: 0, z: 0 },
      { x: 0, y: 0, z: 0 },
      { x: 5.25, y: 0, z: 0 },

      { x: -2.625, y: -1.5, z: 0 },
      { x: 2.625, y: -1.5, z: 0 },

      { x: -5.25, y: -3, z: 0 },
      { x: 0, y: -3, z: 0 },
      { x: 5.25, y: -3, z: 0 },
    ];
    const capCoords = generateRectangularSection(X, Y);
    return { capCoords, pileCoords };
  },
  get14PileCap(pileDiameter: number = 1) {
    const X = 11.5 * pileDiameter;
    const Y = 10.75 * pileDiameter;
    const pileCoords = [
      { x: -3, y: 4.125, z: 0 },
      { x: 0, y: 4.125, z: 0 },
      { x: 3, y: 4.125, z: 0 },

      { x: -4.5, y: 1.5, z: 0 },
      { x: -1.5, y: 1.5, z: 0 },
      { x: 1.5, y: 1.5, z: 0 },
      { x: 4.5, y: 1.5, z: 0 },

      { x: -4.5, y: -1.5, z: 0 },
      { x: -1.5, y: -1.5, z: 0 },
      { x: 1.5, y: -1.5, z: 0 },
      { x: 4.5, y: -1.5, z: 0 },

      { x: -3, y: -4.125, z: 0 },
      { x: 0, y: -4.125, z: 0 },
      { x: 3, y: -4.125, z: 0 },
    ];
    const capCoords = generateRectangularSection(X, Y);
    return { capCoords, pileCoords };
  },
  get15PileCap(pileDiameter: number = 1) {
    const X = 14.5 * pileDiameter;
    const Y = 8.5 * pileDiameter;
    const pileCoords = [
      { x: -6, y: 3, z: 0 },
      { x: -3, y: 3, z: 0 },
      { x: 0, y: 3, z: 0 },
      { x: 3, y: 3, z: 0 },
      { x: 6, y: 3, z: 0 },

      { x: -6, y: 0, z: 0 },
      { x: -3, y: 0, z: 0 },
      { x: 0, y: 0, z: 0 },
      { x: 3, y: 0, z: 0 },
      { x: 6, y: 0, z: 0 },

      { x: -6, y: -3, z: 0 },
      { x: -3, y: -3, z: 0 },
      { x: 0, y: -3, z: 0 },
      { x: 3, y: -3, z: 0 },
      { x: 6, y: -3, z: 0 },
    ];
    const capCoords = generateRectangularSection(X, Y);
    return { capCoords, pileCoords };
  },
  get16PileCap(pileDiameter: number = 1) {
    const X = 11.5 * pileDiameter;
    const Y = 11.5 * pileDiameter;
    const pileCoords = [
      { x: -4.5, y: 4.5, z: 0 },
      { x: -1.5, y: 4.5, z: 0 },
      { x: 1.5, y: 4.5, z: 0 },
      { x: 4.5, y: 4.5, z: 0 },

      { x: -4.5, y: 1.5, z: 0 },
      { x: -1.5, y: 1.5, z: 0 },
      { x: 1.5, y: 1.5, z: 0 },
      { x: 4.5, y: 1.5, z: 0 },

      { x: -4.5, y: -1.5, z: 0 },
      { x: -1.5, y: -1.5, z: 0 },
      { x: 1.5, y: -1.5, z: 0 },
      { x: 4.5, y: -1.5, z: 0 },

      { x: -4.5, y: -4.5, z: 0 },
      { x: -1.5, y: -4.5, z: 0 },
      { x: 1.5, y: -4.5, z: 0 },
      { x: 4.5, y: -4.5, z: 0 },
    ];
    const capCoords = generateRectangularSection(X, Y);
    return { capCoords, pileCoords };
  },
  get17PileCap(pileDiameter: number = 1) {
    const X = 13 * pileDiameter;
    const Y = 11.5 * pileDiameter;
    const pileCoords = [
      { x: -2.625, y: 4.5, z: 0 },
      { x: 2.625, y: 4.5, z: 0 },

      { x: 5.25, y: 3, z: 0 },
      { x: 0, y: 3, z: 0 },
      { x: -5.25, y: 3, z: 0 },

      { x: -2.625, y: 1.5, z: 0 },
      { x: 2.625, y: 1.5, z: 0 },

      { x: 5.25, y: 0, z: 0 },
      { x: 0, y: 0, z: 0 },
      { x: -5.25, y: 0, z: 0 },

      { x: -2.625, y: -1.5, z: 0 },
      { x: 2.625, y: -1.5, z: 0 },

      { x: 5.25, y: -3, z: 0 },
      { x: 0, y: -3, z: 0 },
      { x: -5.25, y: -3, z: 0 },

      { x: -2.625, y: -4.5, z: 0 },
      { x: 2.625, y: -4.5, z: 0 },
    ];
    const capCoords = generateRectangularSection(X, Y);
    return { capCoords, pileCoords };
  },
  get18PileCap(pileDiameter: number = 1) {
    const X = 13 * pileDiameter;
    const Y = 11.5 * pileDiameter;
    const pileCoords = [
      { x: -5.25, y: 4.5, z: 0 },
      { x: 0, y: 4.5, z: 0 },
      { x: 5.25, y: 4.5, z: 0 },

      { x: -2.625, y: 3, z: 0 },
      { x: 2.625, y: 3, z: 0 },

      { x: -5.25, y: 1.5, z: 0 },
      { x: 0, y: 1.5, z: 0 },
      { x: 5.25, y: 1.5, z: 0 },

      { x: -2.625, y: 0, z: 0 },
      { x: 2.625, y: 0, z: 0 },

      { x: -5.25, y: -1.5, z: 0 },
      { x: 0, y: -1.5, z: 0 },
      { x: 5.25, y: -1.5, z: 0 },

      { x: -2.625, y: -3, z: 0 },
      { x: 2.625, y: -3, z: 0 },

      { x: -5.25, y: -4.5, z: 0 },
      { x: 0, y: -4.5, z: 0 },
      { x: 5.25, y: -4.5, z: 0 },
    ];
    const capCoords = generateRectangularSection(X, Y);
    return { capCoords, pileCoords };
  },
  get19PileCap(pileDiameter: number = 1) {
    const X = 13.75 * pileDiameter;
    const Y = 11.5 * pileDiameter;
    const pileCoords = [
      { x: -5.625, y: 4.5, z: 0 },
      { x: -2.625, y: 4.5, z: 0 },
      { x: 2.625, y: 4.5, z: 0 },
      { x: 5.625, y: 4.5, z: 0 },

      { x: 0, y: 3, z: 0 },

      { x: -5.625, y: 1.5, z: 0 },
      { x: -2.625, y: 1.5, z: 0 },
      { x: 2.625, y: 1.5, z: 0 },
      { x: 5.625, y: 1.5, z: 0 },

      { x: 0, y: 0, z: 0 },

      { x: -5.625, y: -1.5, z: 0 },
      { x: -2.625, y: -1.5, z: 0 },
      { x: 2.625, y: -1.5, z: 0 },
      { x: 5.625, y: -1.5, z: 0 },

      { x: 0, y: -3, z: 0 },

      { x: -5.625, y: -4.5, z: 0 },
      { x: -2.625, y: -4.5, z: 0 },
      { x: 2.625, y: -4.5, z: 0 },
      { x: 5.625, y: -4.5, z: 0 },
    ];
    const capCoords = generateRectangularSection(X, Y);
    return { capCoords, pileCoords };
  },
  get20PileCap(pileDiameter: number = 1) {
    const X = 14.5 * pileDiameter;
    const Y = 11.5 * pileDiameter;
    const pileCoords = [
      { x: -6, y: 4.5, z: 0 },
      { x: -3, y: 4.5, z: 0 },
      { x: 0, y: 4.5, z: 0 },
      { x: 3, y: 4.5, z: 0 },
      { x: 6, y: 4.5, z: 0 },

      { x: -6, y: 1.5, z: 0 },
      { x: -3, y: 1.5, z: 0 },
      { x: 0, y: 1.5, z: 0 },
      { x: 3, y: 1.5, z: 0 },
      { x: 6, y: 1.5, z: 0 },

      { x: -6, y: -1.5, z: 0 },
      { x: -3, y: -1.5, z: 0 },
      { x: 0, y: -1.5, z: 0 },
      { x: 3, y: -1.5, z: 0 },
      { x: 6, y: -1.5, z: 0 },

      { x: -6, y: -4.5, z: 0 },
      { x: -3, y: -4.5, z: 0 },
      { x: 0, y: -4.5, z: 0 },
      { x: 3, y: -4.5, z: 0 },
      { x: 6, y: -4.5, z: 0 },
    ];
    const capCoords = generateRectangularSection(X, Y);
    return { capCoords, pileCoords };
  },
}
