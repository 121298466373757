import { CircleCommand } from "../../commands/primitives/circle";
import { setPosBuffer } from "../../geometries";
import { circleCreate, circleBuffer, circleParam } from "../../geometries/circle";
import { lineAddVertex, lineAuxCreate, lineMoveVertex } from "../../geometries/line";
import { lineAzimut2p, normalizeAngle } from "../../math/angles";
import { vectorDist2D, vectorDist3D } from "../../math/distance";
import { copyIPoint } from "../../math/point";
import { IPoint } from "../../math/types";
import { cadOpType } from "../factory";
import { settingsOpModes } from "../step-operations";
import { WireframeOP } from "./wireframe";
import { getCurrentLineMaterial } from "lib/materials";

export class CircleOP extends WireframeOP {

  public opType = cadOpType.CIRCLE;

  public static sampleData = {
    azimutO: Math.PI * 0.5,
    center: { x: 0, y: 0, z: 0 },
    radius: 1,
    objPlane: { x: 0, y: 0, z: 0 },
  };

  public auxPoly: THREE.Line;
  public auxLine: THREE.Line;

  public center: IPoint = { x: 0, y: 0, z: 0 };
  public radius: number = CircleOP.sampleData.radius;
  public azimutO: number = CircleOP.sampleData.azimutO;
  private isRadiusStepEnable: boolean = true;

  constructor(fixedRadius: number, styleId?: string) {
    super(styleId);
    if (fixedRadius) {
      this.radius = fixedRadius;
      this.isRadiusStepEnable = false;
    }
  }

  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Insert center: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
    }, {
      infoMsg: "Insert radius: ",
      stepMode: settingsOpModes.ONEBOX,
      currValue: () => this.radius.toFixed(3),
      cmdLineUpdateListener: (cmd: string) => {
        const r = parseFloat(cmd);
        if (r) this.radius = r;
      },
      cmdLineListener: (cmd: string) => {
        const r = parseFloat(cmd);
        if (r) {
          this.radius = r;
          this.endOperation();
        }
      },
    }]);
  }
  public async start() {
    await super.start();
    this.initializeCircle();
  }

  private initializeCircle(): void {
    this.center = CircleOP.sampleData.center;
    this.auxPoly = circleCreate(
      this.center,
      this.radius,
      this.azimutO,
      undefined
    );
    this.auxLine = lineAuxCreate();
    this.saveToTempScene(this.auxPoly);
    this.saveToTempScene(this.auxLine);
  }

  public setLastPoint(): void {
    if (this.numPoints === 1) {
      const { x, y, z } = this.lastPoint;
      lineAddVertex(this.auxLine, x, y, z);
      lineAddVertex(this.auxLine, x, y, z);
      this.center = copyIPoint(this.lastPoint);
      const planeManager = this.graphicProcessor.getPlaneManager();
      planeManager.activePlane.position = this.center;
      planeManager.activePlane.locked = true;
      if (this.isRadiusStepEnable) {
        this.setNextStep();
      } else {
        this.endOperation();
      }

    } else if (this.numPoints === 2) {
      this.radius = vectorDist3D(this.lastPoint, this.center);
      this.azimutO = lineAzimut2p(this.center, this.lastPoint);
      this.endOperation();
    }
  }

  public moveLastPoint(pto: IPoint) {
    if (this.numPoints === 0) {
      this.center = copyIPoint(pto);
    } else if (this.numPoints === 1) {
      lineMoveVertex(this.auxLine, pto.x, pto.y, pto.z);
      const c = this.currPlane.getRelativePoint(this.center);
      const p = this.currPlane.getRelativePoint(pto);
      this.azimutO = normalizeAngle(lineAzimut2p(c, p));
      this.radius = vectorDist2D(p, c);
    }
    this.calculateCircle();
  }
  private calculateCircle(): void {
    let coords = circleBuffer(
      this.center,
      this.radius,
      this.azimutO,
      this.currPlane.rotation
    );
    if (!coords) coords = new Float32Array(0);
    setPosBuffer(this.auxPoly, coords);
  }

  public endOperation(): void {
    if (this.radius <= 0) {
      this.numPoints--;
      return;
    }
    if (this.finished === false) {
      CircleOP.sampleData = {
        azimutO: this.azimutO,
        center: copyIPoint(this.center),
        radius: this.radius,
        objPlane: this.currPlane.rotation,
      };
      this.save();
    }
    super.endOperation();
  }
  public save() {
    if (this.graphicProcessor) {
      const circle: circleParam = {
        center: this.center,
        radius: this.radius,
        azimutO: this.azimutO,
        plane: this.currPlane.rotation,
      };
      const command = new CircleCommand(
        circle,
        this.getCurrentSceneId(),
        this.graphicProcessor,
        getCurrentLineMaterial({ lineStyleId: this.lineStyleId })
      );
      this.graphicProcessor.storeAndExecute(command);
      if (this.endOperationCallback) this.endOperationCallback(command);
    }
  }
  public endOperationCallback: (cmd: CircleCommand) => void;
}
