import { areaPoints } from "lib/math/area";
import { calculateCentroidPoints } from "lib/math/centroid";
import { copyIPoint } from "lib/math/point";
import { IPoint } from "lib/math/types";
import { textParam } from "lib/models/text";
import { createText, modifyText } from "lib/text/builder";
import { sdfDoubleSidedType, textMultiPosTypeH, textMultiPosTypeV, TextOptsBuilder } from "lib/text/styles";
import { currentTextStyleId, textStyleCache } from "lib/text/cache";
import { cadOpType } from "../factory";
import { RegionBaseOP } from "../solids/region";

export class MeasureAreaOP extends RegionBaseOP {

  public opType = cadOpType.MEASURE;
  private styleId: string
  private styleOpts: TextOptsBuilder | undefined;
  private auxText: THREE.Mesh;

  public async start() {
    super.start();
    if (this.styleId === undefined) {
      this.styleId = currentTextStyleId;
    }
    const text: textParam = {
      styleId: this.styleId,
      text: "",
      position: { x: 0, y: 0, z: 0 },
      angleO: 0,
      plane: { x: 0, y: 0, z: 0 },
      scale: 1
    };
    this.styleOpts = await textStyleCache.loadStyle(this.styleId);
    if (this.styleOpts) {
      this.styleOpts.basePointH = textMultiPosTypeH.MIDDLE;
      this.styleOpts.basePointV = textMultiPosTypeV.MEDIAN;

      this.styleOpts.size = 1;
      this.styleOpts.color = { r: 255, g: 255, b: 0, a: 0 }; // "#ffff00";
      this.styleOpts.doubleSided = sdfDoubleSidedType.FRONT;
    }
    this.auxText = createText(text, this.styleOpts);
    this.saveToTempScene(this.auxText);
  }
  public moveLastPoint(pto: IPoint) {
    super.moveLastPoint(pto);

    if (this.numPoints > 1) {
      const ptos = this.points.concat(copyIPoint(pto));
      const planeManager = this.graphicProcessor.getPlaneManager();
      const ptos2D = ptos.map(p => planeManager.activePlane.getRelativePoint(p));
      const calculatedArea = areaPoints(ptos2D);
      const relCentroId = calculateCentroidPoints(ptos2D) as IPoint;
      const centroId = planeManager.activePlane.getAbsolutePoint(relCentroId);

      const kGapPixels = 50;
      // El valor del pixel perfect en metros para esa cantidad de pixels.
      const meters = this.graphicProcessor.getSizeUnitFromPixelUnit(kGapPixels, centroId);

      // changeTextProperties(this.auxText, centroId, { x: 0, y: 0, z: 0 }, 0, 1)
      modifyText(this.auxText, calculatedArea.toFixed(3), this.styleOpts);

      // Ademas dependiendo de donde este la camara podemos tener un offset en Z u otro, pero de momento lo dejamos en 10 siempre.
      // const offsetZ = graphicProcessor000.getCursorCamera().position.z > 0 ? 10 : -50;
      // Esto mas adelante debera ser parametrizado para 
      const offsetZ = 10;

      // Aplica todo al mesh.
      this.auxText.position.set(centroId.x, centroId.y, centroId.z + offsetZ);
      this.auxText.scale.x = 0.5 * meters;
      this.auxText.scale.y = 0.5 * meters;
      this.auxText.scale.z = 0.5 * meters;
      this.auxText.quaternion.copy(this.graphicProcessor.getCursorCamera().quaternion);
    }
  }
  public save(): void {
    // Not implemented
  }
}
