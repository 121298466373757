import cn from "classnames";
import { FC } from "react";

interface Props {
  className?: string;
  id?: string;
  disabled?: boolean;
  label: string;
  buttonCb: () => void;
}

const PropertiesButton: FC<Props> = (props) => {
  const {
    className,
    id,
    label,
    buttonCb,
    ...rest
  } = props;

  const rootClassName = cn(
    "bg-gray-700 block w-full rounded text-gray-100 hover:bg-gray-600",
    "focus:bg-gray-600 px-1 py-0 text-xs leading-5 disabled:text-gray-400",
    "border-gray-600 focus:ring-indigo-500 focus:border-indigo-500",
    className
  );

  return (
    <div className="flex justify-end space-x-4 items-baseline w-full">
      {/* <label
        htmlFor={id}
        className={"text-xs text-gray-400 capitalize"}
      >
        {label}
      </label> */}
      {/* <div className="relative flex items-center col-span-4 w-1/2"> */}
      <button
        className={rootClassName}
        type="button"
        id={id}
        onClick={buttonCb}
        {...rest}>
        {label}
      </button>
      {/* </div> */}
    </div>
  );
};

PropertiesButton.displayName = "PropertiesButton";

export default PropertiesButton;