import { User, Module, Role } from "shared/models/user";

export const formatUser = (user: Record<string, any> | null): User | null => {
  if (user) {
    return {
      id: user.attributes.sub,
      username: user.username,
      email: user.attributes.email,
    };
  }
  return null;
};

export const isAdmin = (roles: Role[]) => roles.includes("admin");

export const canUseCadModule = (roles?: Role[]): boolean => {
  if (!roles) {
    return false;
  }
  return !!roles.find(
    (role) =>
      role === "admin" ||
      role === "cad_customer" ||
      role === "cad_engineer"
  );
};

export const canUseLiteModule = (roles?: Role[]): boolean => {
  if (!roles) {
    return false;
  }
  return !!roles.find(
    (role) =>
      role === "admin" ||
      role === "struct_customer" ||
      role === "struct_engineer"
  );
};

export const canUseStrucModule = (roles?: Role[]): boolean => {
  if (!roles) {
    return false;
  }
  return !!roles.find(
    (role) =>
      role === "admin" ||
      role === "struct_customer" ||
      role === "struct_engineer"
  );
};

export const getModuleFromPathname = (pathname: string): Module => {
  if (pathname.includes("lite")) {
    return "lite";
  }
  if (pathname.includes("cad")) {
    return "cad";
  }
  if (pathname.includes("struc")) {
    return "struc";
  }
  return "lite";
};

export const canSwitchModule = (roles: Role[]) =>
  isAdmin(roles) || roles.length > 1;
