import { getAuxMaterialLine, LineMaterialType } from "lib/materials";
import * as THREE from "three";
import { adjustDrawRange, updateObjBboxBSphere } from ".";
import { ellipseBuffer } from "../math/ellipse";
import { IPoint } from "../math/types";

export interface ellipseParam {
    center: IPoint,
    a: number,
    b: number,
    azimutO: number,
    plane: IPoint
}

export function ellipseCreate(center: IPoint, aAxis: number, bAxis: number, azimutO: number, plane?: IPoint, material?: LineMaterialType): THREE.Line {
    if (plane === undefined) plane = { x: 0, y: 0, z: 0 };
    const coords: Float32Array = ellipseBuffer(center, aAxis, bAxis, azimutO, plane);
    const points: THREE.BufferGeometry = new THREE.BufferGeometry();
    points.setAttribute("position", new THREE.Float32BufferAttribute(coords, 3));
    const mat = material ?? getAuxMaterialLine();
    const elipse: THREE.Line = new THREE.Line(points, mat);
    if (mat.type !== "LineBasicMaterial") elipse.computeLineDistances();
    adjustDrawRange(elipse);
    updateObjBboxBSphere(elipse);
    return elipse;
}