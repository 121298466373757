import { AnalysisProjectsApi } from "lib/apis/analysis-projects/api";
import { MeshProjectsApi } from "lib/apis/mesh-projects/api";
import { ProjectFilesApi, ProjectsApi } from "lib/apis/struc-project/api";
import { AnalysisProperties } from "lib/models-struc/analysis/analysis";
import { MeshProperties } from "lib/models-struc/mesh/mesh";
import { downloadFiles } from "./download-files";

interface ErrorMssg { error: any; mssg: string; }

const getFiles = async (userId: string, projectId: string) => {
  try {
    const controller = new ProjectFilesApi();
    const response = await controller.projectsUserIdIdRefreshGet(userId, projectId);
    const fileDatas = response.data;
    return {
      meshFileUrl: fileDatas.Mesh.Read,
      analysisFileUrl: fileDatas.Analysis.Read,
    }
  } catch (error) {
    console.error(error);
    const err: ErrorMssg = {
      error,
      mssg: `Error when deleting project data ${projectId}`
    }
    throw err;
  }
}
const deleteMeshProject = async (mesh: MeshProperties | null) => {
  try {
    if (mesh?.project) {
      const meshProjectEndPoint = new MeshProjectsApi();
      await meshProjectEndPoint.meshProjectsIdDelete(mesh.project.Id);
    }
  } catch (error) {
    console.error(error);
    const err: ErrorMssg = {
      error,
      mssg: `Error when deleting meshing project ${mesh!.project!.Id}`
    }
    throw err;
  }
}
const deleteAnalysisProject = async (analysis: AnalysisProperties | null) => {
  try {
    if (analysis?.project) {
      const analysisProjectEndPoint = new AnalysisProjectsApi();
      await analysisProjectEndPoint.analysisProjectsIdDelete(analysis.project.Id);
    }
  } catch (error) {
    console.error(error);
    const err: ErrorMssg = {
      error,
      mssg: `Error when deleting analysis project ${analysis!.project!.Id}`
    }
    throw err;
  }
}
const deleteProjectData = async (userId: string, projectId: string) => {
  try {
    const controller = new ProjectsApi();
    const response = await controller.projectsUserIdIdDelete(userId, projectId);
    return response.data;
  } catch (error) {
    console.error(error);
    const err: ErrorMssg = {
      error,
      mssg: `Error when deleting project data ${projectId}`
    }
    throw err;
  }
}

export async function fetchDeleteProject(userId: string, projectId: string) {
  try {
    const { meshFileUrl, analysisFileUrl } = await getFiles(userId, projectId);
    const meshProj = await downloadFiles.mesh(meshFileUrl);
    await deleteMeshProject(meshProj);

    const analysisProj = await downloadFiles.analysis(analysisFileUrl);
    await deleteAnalysisProject(analysisProj);

    const data = await deleteProjectData(userId, projectId);
    return data;

  } catch (error) {
    console.error(error);
    throw error;
  }
}
