import cn from "classnames";
import RenderIf from "shared/components/ui/render-if";

interface Props {
  status?: string;
}

const ProjectStatus = ({ status }: Props) => {
  const className = cn(
    "px-2 inline-flex text-sm leading-5 font-medium rounded capitalize",
    status === "CREATED" && "bg-blue-200 text-blue-800",
    status?.includes("PENDING") && "bg-yellow-200 text-yellow-800",
    status?.includes("SUCCESS") && "bg-green-200 text-green-800",
    status?.includes("ERROR") && "bg-red-200 text-red-800"
  );
  return (
    <RenderIf isTrue={!!status}>
      <span className={className}>{status}</span>
    </RenderIf>
  );
};

export default ProjectStatus;
