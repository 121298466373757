import { ProjectModelManager } from "lib/models-struc/model-managers/projectmodel-manager";
import { StructuralModelManager } from "lib/models-struc/model-managers/structuralmodel-manager";
import { ModelDataImporter } from "lib/input-output/database/data-model-import";
import { StrucDataImporter } from "lib/input-output/database/struc-model-import";
import { loadResources, loadStrucResources, Resources } from "../../resources";
import { HypothesisImport } from "lib/input-output/database/hypothesis";
import { Project } from "lib/apis/struc-project/api";
import { GraphicProcessor } from "lib/graphic-processor";
import { AnalysisModelManager } from "lib/models-struc/analysis/analysismodel-manager";
import { HypothesisModelManager } from "lib/models-struc/hypothesis/hypothesismodel-manager";
import { MeshModelManager } from "lib/models-struc/mesh/meshmodel-manager";
import { ICADModelPersistence } from "lib/input-output/database/loader";
import { standard, euSteelType, usSteelType } from "modules/struc/models/codes";
import { StrucSite, StrucProject } from "modules/struc/models/project";
import { Hypothesis } from "../hypothesis/hypothesis";

export async function loadProject(
  resources: any,
  graphic: GraphicProcessor,
  meshManager: MeshModelManager,
  hypothesisManager: HypothesisModelManager,
  analysisManager: AnalysisModelManager,
  project: Project) {

  loadResources(resources as Resources);
  loadStrucResources(resources as Resources);
  const { Content, Sites, Mesh, Hypothesis, Analysis } = resources;

  const importer = new ModelDataImporter(graphic);
  importer.openProjectCAD(Content!);

  meshManager.importFromJSON(Mesh);

  await hypothesisManager.loadPredefinedHypothesis();
  hypothesisManager.initialize(graphic);
  const hypothesisImporter = new HypothesisImport();
  hypothesisImporter.import(Hypothesis ?? []);

  analysisManager.importFromJSON(Analysis);

  graphic._structuralModelManager = new StructuralModelManager(graphic);
  graphic._projectModelManager = new ProjectModelManager(graphic);

  const proj = getStrucProject(project, Sites!, Content!, Hypothesis);
  const projectStruc = new StrucDataImporter(graphic);
  projectStruc.openProjectStruct(proj);

  hypothesisImporter.importLoadsToHypothesis(graphic);

  graphic._structuralModelManager.registerStoreyLayers();
}

export function getStrucProject(
  data: Project,
  sites: StrucSite[],
  content: ICADModelPersistence,
  hypothesis: Hypothesis[] = []
): StrucProject {

  return {
    version: data.DataModelVersion,
    id: data.Id,
    userId: data.UserId,
    createdAt: data.CreatedAt,
    updatedAt: data.UpdatedAt,

    name: data.Name,
    remarks: data.Remarks,
    country: data.Country,
    city: data.City,

    concrete_code: data.Codes.Concrete,
    load_code: data.Codes.Load,
    steel_standard: data.Codes.SteelStandard as standard,
    steel_type: data.Codes.Steel as euSteelType | usSteelType,
    concrete_standard: data.Codes.ConcreteStandard as standard,

    footing_level: data.FootingLevel,
    sites: sites,

    content,
    hypothesis,
  }
}
