import { isEqual, isZero } from "lib/math/epsilon";
import { IPoint } from "lib/math/types";
import { Placement, Quaternion as EcoreQuaternion, Vector } from "modules/struc/models/ecore/location";
import { Quaternion } from '../../../math/rotate';
import { locationUriModel } from "modules/struc/models/ecore/uri";

export function getEcoreVector2(point: IPoint): Vector {
  const vector: Vector = { eClass: `${locationUriModel}Vector` };
  if (!isZero(point.x)) { vector.x = point.x; }
  if (!isZero(point.y)) { vector.y = point.y; }
  return vector;
}

export function getEcoreVector3(point: IPoint): Vector {
  const vector = getEcoreVector2(point);
  if (!isZero(point.z)) { vector.z = point.z; }
  return vector;
}

function getEcoreRotation(rot: Quaternion): EcoreQuaternion {
  const rotation: EcoreQuaternion = { eClass: `${locationUriModel}Quaternion` };
  if (!isZero(rot.x)) { rotation.x = rot.x; }
  if (!isZero(rot.y)) { rotation.y = rot.y; }
  if (!isZero(rot.z)) { rotation.z = rot.z; }
  if (!isEqual(rot.w, 1)) { rotation.w = rot.w; }
  return rotation;
}
const defQuaternion: Quaternion = { x: 0, y: 0, z: 0, w: 1 };

export function getEcorePlacement(point: IPoint, rotation: Quaternion = defQuaternion): Placement {
  return {
    eClass: `${locationUriModel}Placement`,
    base: getEcoreVector3(point),
    rotation: getEcoreRotation(rotation),
  };
}
