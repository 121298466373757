import * as THREE from "three";
import { DimStyleBuilder } from "lib/dimension/style";
import { currentDimStyle, dimensionCache } from "lib/dimension/cache";
import { getDimArrows, getDimAuxGeometry, getDimGeometry, getDimLabel } from "lib/dimension/line-dim-builder";
import { getNearOrthogonalPlaneFromView } from "lib/helpers/camera";
import { normalizeAngle, lineAngle2p, isBetweenDirection, getBisection, angleBetweenXZPlane, angleBetweenYZPlane, angleBetweenVectors, mecDirectionMathDirection, ORIENT } from "lib/math/angles";
import { lineGetBbox } from "lib/math/box";
import { vectorDist3D, distancePointToLine3D } from "lib/math/distance";
import { isZeroAng } from "lib/math/epsilon";
import { intersect2D } from "lib/math/intersections";
import { getNormalOfPlane, IPlane } from "lib/math/plane";
import { getMiddlePoint, getPolarPoint, pointLinePositionXY, pointLinePosition, mulIpoint } from "lib/math/point";
import { IPoint, ISegment } from "lib/math/types";
import { IObjData } from "lib/models/objdata";
import { TextOptsBuilder } from "lib/text/styles";
import { textStyleCache } from "lib/text/cache";
import { MultiEdition } from "../edition-multi";
import { arcParam } from "lib/math/arc";
import { getRelativePoint } from "lib/coordinates/plane";

const angleLimitDimPlane = Math.PI * 0.25;

// Plano de acotación por defecto por defecto XY (Horizontal)
export enum dimPlane { XY, XZ, YZ, AUTO }
export let defaultDimPlane: dimPlane = dimPlane.AUTO;
export function setDefaultDimPlane(newDimPlane: dimPlane) { defaultDimPlane = newDimPlane; }

export enum dimDirectionMode { NS, WE, DEF, VERT, AUTO }
export let defaultDimDirection: dimDirectionMode = dimDirectionMode.AUTO;
export function setDefaultDimDirection(newDirection: dimDirectionMode) { defaultDimDirection = newDirection; }

export enum dimOrientationMode { POSITIVE, NEGATIVE, AUTO }
export let defaultDimOrientation: dimOrientationMode = dimOrientationMode.AUTO;
export function setDefaultDimOrientation(newOrientation: dimOrientationMode) { defaultDimOrientation = newOrientation; }


export abstract class DimensionOP extends MultiEdition {

  public dimStyleId: string;

  public dimStyle: DimStyleBuilder;        // Estilo de acotación propio
  public defDimStyle: DimStyleBuilder;     // Estilo de acotación por defecto
  public txtStyle: TextOptsBuilder;        // Estilo de texto del estilo de acotación

  public dimPlane: dimPlane;

  public dimensionGroup: THREE.Group;
  public textLabel: THREE.Mesh;
  public dimGeometry: THREE.LineSegments;
  public auxGeometry: THREE.Line;
  public arrow1: THREE.Mesh | THREE.Line;
  public arrow2: THREE.Mesh | THREE.Line;

  constructor(dimStyleId?: string, dataObjs: IObjData[] = []) {
    super(dataObjs);
    this.dimStyleId = dimStyleId ? dimStyleId : currentDimStyle;
  }

  public async start() {
    this.iniSettingsOp();
    // Lectura estilo de acotación
    this.defDimStyle = await dimensionCache.loadStylefromCache(this.dimStyleId)!;
    this.dimStyle = this.defDimStyle.clone();
    // if (this.dimapplyScale) this.dimfontScale = this.tre.glovars.annotative * this.dimscale;
    // Lectura estilo de etiqueta
    this.txtStyle = await textStyleCache.loadStylefromCache(this.dimStyle.textStyleId)!;
    // this.txtsize *= this.dimfontScale;
  }

  protected inicializeDimension(dimGroup: THREE.Group): void {
    this.dimensionGroup = dimGroup;
    this.dimGeometry = getDimGeometry(dimGroup);
    this.textLabel = getDimLabel(dimGroup);
    const arrows = getDimArrows(dimGroup);
    this.arrow1 = arrows[0];
    this.arrow2 = arrows[1];
    this.auxGeometry = getDimAuxGeometry(dimGroup);
  }
  public saveDimtoTemp(dimGroup: THREE.Group): void {
    this.inicializeDimension(dimGroup);
    this.saveToTempScene(this.dimensionGroup);
  }

  /** Devuelve el plano donde se situa la acotación (según la posición de la camara)
   *
   * @private
   * @returns {boolean}
   *
   * @memberof Dimension
   */
  protected getDimPlane(): dimPlane {
    switch (defaultDimPlane) {
      case dimPlane.AUTO:
        const camera = this.graphicProcessor.getCursorCamera();
        const refPlane = getNearOrthogonalPlaneFromView(camera, angleLimitDimPlane);
        if (refPlane === "XY") return dimPlane.XY;
        else if (refPlane === "XZ") return dimPlane.XZ;
        else if (refPlane === "YZ") return dimPlane.YZ;
        break;
      case dimPlane.XY:
        return dimPlane.XY;
      case dimPlane.XZ:
        return dimPlane.XZ;
      case dimPlane.YZ:
        return dimPlane.YZ;
      default:
        break;
    }
    // valor por defecto
    return dimPlane.XY;
  }
  /** Devuleve el plano vertical más cercano a la posición de la cámara
   *
   * @param {IPlane} customPlane
   * @returns {dimPlane}
   * @memberof Dimension
   */
  protected getVerticalOrientation(customPlane: IPlane): dimPlane | null {
    // Normal del Plano de la camara
    const camera = this.graphicProcessor.getCursorCamera();
    const cameraDirection = camera.getWorldDirection(new THREE.Vector3());
    const cameraPlane = mulIpoint({ x: cameraDirection.x, y: cameraDirection.y, z: cameraDirection.z }, -1);

    const angleXZ = angleBetweenXZPlane(cameraPlane);
    const angleYZ = angleBetweenYZPlane(cameraPlane);
    const custmNormal = getNormalOfPlane(customPlane);
    const angleVert = angleBetweenVectors(custmNormal, cameraPlane);

    // En el caso de coincidir
    if (isZeroAng(angleVert - angleXZ) && angleXZ < angleYZ) { return dimPlane.XZ; }
    if (isZeroAng(angleVert - angleYZ) && angleYZ < angleXZ) { return dimPlane.YZ; }

    if (angleVert < angleXZ && angleVert < angleYZ) return dimPlane.AUTO;
    else if (angleXZ < angleVert && angleXZ < angleYZ) return dimPlane.XZ;
    else if (angleYZ < angleVert && angleYZ < angleXZ) return dimPlane.YZ;
    else return null;
  }

  protected getDimDirection(direction: dimDirectionMode, point: IPoint, line: ISegment, plane: "XY" | "XZ" | "YZ"): dimDirectionMode {
    if (defaultDimDirection === dimDirectionMode.AUTO) {
      const centerPoint = getMiddlePoint(line.p1, line.p2);
      let angle = normalizeAngle(lineAngle2p(line.p1, line.p2, plane)) - Math.PI * 0.5;
      if (angle < 0) angle += Math.PI;
      if (angle > Math.PI) angle -= Math.PI;

      if (angle === Math.PI * 0.5) return dimDirectionMode.NS;
      else if (angle === 0 || angle === Math.PI) return dimDirectionMode.WE;

      const bbox = lineGetBbox(line);
      if (bbox) {
        if (plane === "XY" && (bbox.min.x < point.x && point.x < bbox.max.x) && (bbox.min.y < point.y && point.y < bbox.max.y)) return direction;
        else if (plane === "XZ" && (bbox.min.x < point.x && point.x < bbox.max.x) && (bbox.min.z < point.z && point.z < bbox.max.z)) return direction;
        else if (plane === "YZ" && (bbox.min.y < point.y && point.y < bbox.max.y) && (bbox.min.z < point.z && point.z < bbox.max.z)) return direction;
      }
      if (isBetweenDirection(angle, 0, Math.PI * 0.5) || isBetweenDirection(angle, Math.PI, Math.PI * 1.5)) {
        const angle1 = getBisection(0, angle);
        const line1 = { p1: centerPoint, p2: getPolarPoint(centerPoint, angle1, 50, plane) };
        const angle2 = getBisection(angle, Math.PI * 0.5);
        const line2 = { p1: centerPoint, p2: getPolarPoint(centerPoint, angle2, 50, plane) };
        const line3 = { p1: centerPoint, p2: getPolarPoint(centerPoint, Math.PI * 0.75, 50, plane) };

        const rel1 = pointLinePosition(point, line1.p1, line1.p2, plane);
        const rel2 = pointLinePosition(point, line2.p1, line2.p2, plane);
        const rel3 = pointLinePosition(point, line3.p1, line3.p2, plane);

        if (rel3 > 0 && rel2 < 0) return dimDirectionMode.NS;
        else if (rel1 < 0 && rel2 > 0) return dimDirectionMode.DEF;
        else if (rel1 > 0 && rel3 > 0) return dimDirectionMode.WE;
        else if (rel3 < 0 && rel2 > 0) return dimDirectionMode.NS;
        else if (rel2 < 0 && rel1 > 0) return dimDirectionMode.DEF;
        else if (rel1 < 0 && rel3 < 0) return dimDirectionMode.WE;
      } else {
        const line1 = { p1: centerPoint, p2: getPolarPoint(centerPoint, angle - Math.PI * 0.5, 50, plane) };
        const angle2 = getBisection(Math.PI * 0.5, angle);
        const line2 = { p1: centerPoint, p2: getPolarPoint(centerPoint, angle2, 50, plane) };
        const angle3 = getBisection(angle, Math.PI);
        const line3 = { p1: centerPoint, p2: getPolarPoint(centerPoint, angle3, 50, plane) };

        const rel1 = pointLinePosition(point, line1.p1, line1.p2, plane);
        const rel2 = pointLinePosition(point, line2.p1, line2.p2, plane);
        const rel3 = pointLinePosition(point, line3.p1, line3.p2, plane);

        if (rel3 > 0 && rel2 < 0) return dimDirectionMode.DEF;
        else if (rel1 < 0 && rel2 > 0) return dimDirectionMode.NS;
        else if (rel1 > 0 && rel3 > 0) return dimDirectionMode.WE;
        else if (rel3 < 0 && rel2 > 0) return dimDirectionMode.DEF;
        else if (rel2 < 0 && rel1 > 0) return dimDirectionMode.NS;
        else if (rel1 < 0 && rel3 < 0) return dimDirectionMode.WE;
      }

    } else if (defaultDimDirection === dimDirectionMode.NS) {
      return dimDirectionMode.NS;

    } else if (defaultDimDirection === dimDirectionMode.WE) {
      return dimDirectionMode.WE;

    } else if (defaultDimDirection === dimDirectionMode.DEF) {
      return dimDirectionMode.DEF;
    }
    return direction;
  }
  protected getDimOrientation(direction: dimDirectionMode, point: IPoint, line: ISegment): dimOrientationMode {
    if (defaultDimOrientation === dimOrientationMode.AUTO) {
      const centerPoint = getMiddlePoint(line.p1, line.p2);
      const bbox = lineGetBbox(line);
      if (direction === dimDirectionMode.NS) {
        const rel1 = pointLinePositionXY(point,
          { x: bbox.min.x, y: centerPoint.y, z: 0 },
          { x: bbox.max.x, y: centerPoint.y, z: 0 }
        );
        if (rel1 < 0) return dimOrientationMode.POSITIVE;
        else return dimOrientationMode.NEGATIVE;

      } else if (direction === dimDirectionMode.WE) {
        const rel1 = pointLinePositionXY(point,
          { x: centerPoint.x, y: bbox.min.y, z: 0 },
          { x: centerPoint.x, y: bbox.max.y, z: 0 }
        );
        if (rel1 > 0) return dimOrientationMode.POSITIVE;
        else return dimOrientationMode.NEGATIVE;

      } else if (direction === dimDirectionMode.DEF) {
        const angle = lineAngle2p(line.p1, line.p2);
        let rel = 0;
        if (Math.PI * 0.5 > angle && angle > -Math.PI * 0.5) {
          rel = pointLinePositionXY(point, line.p1, line.p2);
        } else {
          rel = pointLinePositionXY(point, line.p2, line.p1);
        }
        if (rel < 0) return dimOrientationMode.POSITIVE;
        else return dimOrientationMode.NEGATIVE;

      }

    } else if (defaultDimOrientation === dimOrientationMode.POSITIVE) {
      return dimOrientationMode.POSITIVE;

    } else if (defaultDimOrientation === dimOrientationMode.NEGATIVE) {
      return dimOrientationMode.NEGATIVE;
    }

    return defaultDimOrientation;
  }
  protected getDimDistance(direction: dimDirectionMode, point: IPoint, line: ISegment): number {
    const cntrPoint = getMiddlePoint(line.p1, line.p2);
    const bboxProj = lineGetBbox(line);
    if (direction === dimDirectionMode.NS) {
      const l1Start = point;
      const l1End = { x: point.x, y: point.y + 1, z: point.z };

      const l2Start = { x: bboxProj.min.x, y: cntrPoint.y, z: cntrPoint.z };
      const l2End = { x: bboxProj.max.x, y: cntrPoint.y, z: cntrPoint.z };

      const int = intersect2D(l1Start, l1End, l2Start, l2End, false);
      if (int) {
        const projPto = int.p as IPoint;
        return vectorDist3D(projPto, point) - (bboxProj.max.y - bboxProj.min.y) * 0.5;
      }

    } else if (direction === dimDirectionMode.WE) {
      const l1Start = point;
      const l1End = { x: point.x + 1, y: point.y, z: point.z };

      const l2Start = { x: cntrPoint.x, y: bboxProj.max.y, z: cntrPoint.z };
      const l2End = { x: cntrPoint.x, y: bboxProj.min.y, z: cntrPoint.z };

      const int = intersect2D(l1Start, l1End, l2Start, l2End, false);
      if (int) {
        const projPto = int.p as IPoint;
        return vectorDist3D(projPto, point) - (bboxProj.max.x - bboxProj.min.x) * 0.5;
      }

    } else if (direction === dimDirectionMode.DEF) {
      return distancePointToLine3D(line.p1, line.p2, point, false);
    }
    return 0;
  }

  public dimDirectionIsInArc(arcInfo: arcParam, pto: IPoint): boolean {
    const p = getRelativePoint(pto, arcInfo.center, arcInfo.plane);
    const c = getRelativePoint(arcInfo.center, arcInfo.center, arcInfo.plane);
    const angleDir = lineAngle2p(c, p);
    const angleStart = mecDirectionMathDirection(arcInfo.azimutO);
    const angleEnd = normalizeAngle(angleStart - arcInfo.angleCenter);
    const direction = arcInfo.angleCenter < 0 ? ORIENT.CCW : ORIENT.CW;
    let ArcDimIsInArc = false;
    if (isBetweenDirection(angleDir, angleStart, angleEnd, direction)) {
      ArcDimIsInArc = true;
    }
    return ArcDimIsInArc;
  }
}