import { GraphicProcessor } from "lib/graphic-processor";
import { IPoint } from "lib/math/types";
import { cloneDataModel } from "lib/models/model-creator/datamodel-factory";
import { IObjData } from "lib/models/objdata";
import { BeamData } from "lib/models/structural/beam";
import { ColumnData } from "lib/models/structural/column";
import { FooterData } from "lib/models/structural/footer";
import { LoadStructuralData } from "lib/models/structural/load";
import { PileCapData } from "lib/models/structural/pile-cap";
import { SlabData } from "lib/models/structural/slab";
import { StripFootingData } from "lib/models/structural/stripfooter";
import { IStrucElementData } from "lib/models/structural/structural";
import { WallData } from "lib/models/structural/wall";
import { BeamCommand } from "../structural/beam";
import { ColumnCommand } from "../structural/column";
import { FootingCommand, StripFootingCommand } from "../structural/footer";
import { LoadCommand } from "../structural/load";
import { PileCapCommand } from "../structural/pile-cap";
import { SlabCommand } from "../structural/slab";
import { WallCommand } from "../structural/wall";
import { ObjDataCommandCreator } from "./primitives";

export abstract class StrucCommandCreator extends ObjDataCommandCreator {

  protected translateAndCreateDataFromCommand(graphicProc: GraphicProcessor, objData: IStrucElementData, vector: IPoint) {
    const copyData = cloneDataModel(objData);
    copyData.translate(vector);
    const newObjData = this.createDataFromCommand(graphicProc, copyData, undefined!) as IStrucElementData;
    const loads = this.copyLoads(graphicProc, newObjData, objData.loads, vector);
    return [newObjData, ...loads];
  }

  private copyLoads(graphicProc: GraphicProcessor, objData: IStrucElementData, loads: LoadStructuralData[], vector: IPoint): LoadStructuralData[] {
    const loadsCopied: LoadStructuralData[] = [];
    if (loads.length) {
      for (const load of loads) {
        if (!load.isDefaultSlabLoad) {
          const copyData = cloneDataModel(load);
          copyData.translate(vector);
          const loadCommand = new LoadCommand(
            copyData.definition.type,
            copyData.definition.hypothesisId,
            copyData.definition.loadValue,
            copyData.definition.ptos2D,
            copyData.definition.basePoint,
            copyData.definition.rotation,
            objData,
            copyData.definition.externalGeo,
            graphicProc,
          );
          loadCommand.execute();
          loadsCopied.push(loadCommand.loadObj);
          loadCommand.delete();
        }
      }
    }
    return loadsCopied;
  }
}

export class ColumnCommandCreator extends StrucCommandCreator {

  protected createDataFromCommand(graphicProc: GraphicProcessor, objData: ColumnData) {
    const command = new ColumnCommand([objData.definition], graphicProc);
    command.execute();
    const newObjData = command.createdObjs[0];
    command.delete();
    return newObjData;
  }
}

export class SlabCommandCreator extends StrucCommandCreator {

  protected createDataFromCommand(graphicProc: GraphicProcessor, objData: SlabData) {
    const command = new SlabCommand(
      objData.definition.materialType,
      objData.definition.shellCrossSectionId,
      objData.definition.waffleShelCrossSectionId,
      objData.definition.depth,
      objData.definition.ptos2D,
      objData.definition.holes,
      objData.definition.basePoint,
      objData.definition.rotation,
      objData.definition.offset,
      objData.definition.scale,
      graphicProc,
    );
    command.execute();
    const newObjData = command.createdObjs[0];
    command.delete();
    return newObjData;
  }
}

export class BeamCommandCreator extends StrucCommandCreator {
  protected createDataFromCommand(graphicProc: GraphicProcessor, objData: BeamData) {
    const command = new BeamCommand(
      objData.definition.growthType,
      objData.definition.materialType,
      objData.definition.points,
      objData.definition.crossSectionId,
      objData.definition.basePoint,
      objData.definition.rotation,
      objData.definition.offset,
      objData.definition.scale,
      graphicProc,
    );
    command.execute();
    const newObjData = command.createdObjs[0];
    command.delete();
    return newObjData;
  }
}

export class WallCommandCreator extends StrucCommandCreator {
  protected createDataFromCommand(graphicProc: GraphicProcessor, objData: WallData) {
    const settings = {
      wallType: objData.definition.wallType,
      shellCrossSectionId: objData.definition.shellCrossSectionId,
      widthGrowth: objData.definition.widthType,
      heightType: objData.definition.heightType,
      height: objData.definition.height,
      normals: objData.definition.stretch.map(s => s.normal),
      points2D: objData.definition.ptos2D,
      basePoint: objData.definition.basePoint,
      rotation: objData.definition.rotation,
      offset: objData.definition.offset,
      scale: objData.definition.scale,
    }
    const command = new WallCommand(settings, graphicProc);
    command.execute();
    const newObjData = command.createdObjs[0];
    command.delete();
    return newObjData;
  }
}

export class FooterCommandCreator extends StrucCommandCreator {
  protected createDataFromCommand(graphicProc: GraphicProcessor, objData: FooterData) {
    const command = new FootingCommand(
      objData.definition.shellSectionId,
      objData.definition.width,
      objData.definition.length,
      objData.definition.basePoint,
      graphicProc
    );
    command.execute();
    const newObjData = command.createdObjs[0] as FooterData;
    command.delete();
    return newObjData;
  }
}
export class StripFooterCommandCreator extends StrucCommandCreator {
  protected createDataFromCommand(graphicProc: GraphicProcessor, objData: StripFootingData) {
    const command = new StripFootingCommand(
      objData.definition.stripLine,
      objData.definition.shellSectionId,
      objData.definition.width,
      objData.definition.basePoint,
      graphicProc
    );
    command.execute();
    const newObjData = command.createdObjs[0] as StripFootingData;
    command.delete();
    return newObjData;
  }
}
export class PileCapCommandCreator extends StrucCommandCreator {
  protected createDataFromCommand(graphicProc: GraphicProcessor, objData: PileCapData) {
    const command = new PileCapCommand(
      objData.definition.capSectionId,
      objData.definition.capCoords,
      objData.definition.pileSections,
      objData.definition.pileCoords,
      objData.definition.pilePenetration,
      objData.definition.height,
      objData.definition.basePoint,
      graphicProc
    );
    command.execute();
    const newObjData = command.createdObjs[0];
    command.delete();
    return newObjData;
  }
}

// *****************************************************************************************************

export class LoadCommandCreator extends ObjDataCommandCreator {
  protected translateAndCreateDataFromCommand(graphicProc: GraphicProcessor, objData: LoadStructuralData, vector: IPoint, layerId: string): IObjData[] {
    const copyData = cloneDataModel(objData);
    copyData.translate(vector);
    copyData.parentStrucElem = objData.parentStrucElem;
    const newObjData = this.createDataFromCommand(graphicProc, copyData);
    return [newObjData];
  }
  protected createDataFromCommand(graphicProc: GraphicProcessor, objData: LoadStructuralData) {
    const command = new LoadCommand(
      objData.definition.type,
      objData.definition.hypothesisId,
      objData.definition.loadValue,
      objData.definition.ptos2D,
      objData.definition.basePoint,
      objData.definition.rotation,
      objData.parentStrucElem,
      objData.definition.externalGeo,
      graphicProc,
    );
    command.execute();
    const newObjData = command.loadObj;
    command.delete();
    return newObjData;
  }
}
