import { IcombinationItem } from "lib/models-struc/storey-drift/storey-drift-repo";

export enum StoreyDriftActionType {
  ADD_COMBINATIONS,
  DELETE_COMB,
}

export type StoreyDriftAction = {
  type: StoreyDriftActionType.ADD_COMBINATIONS;
  payload: { id: string, combinations: IcombinationItem };
} | {
  type: StoreyDriftActionType.DELETE_COMB;
  payload: { id: string };
};