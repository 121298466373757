import { GraphicProcessor } from "lib/graphic-processor";
import ToolbarButton from "modules/cad/components/toolbar/toolbar-button";
import React from "react";
import { useViewportMode } from "./hooks/use-viewport-mode";


const ViewportStrucToolbar = React.memo(({ graphicProc }: { graphicProc: GraphicProcessor }) => {
  const {
    perspectiveMode,
    elevationMode,
    plainMode,
    handlePerspectiveMode,
    handleElevationMode,
    handlePlainMode,
  } = useViewportMode(graphicProc);

  return (
    <div className="flex items-center divide-x divide-gray-600">
      <ToolbarButton
        className="px-1"
        onClick={handlePerspectiveMode}
        title="Perspective"
        isActive={perspectiveMode}
      />
      <div className="h-8"/>
      <ToolbarButton
        className="px-1"
        onClick={handleElevationMode}
        title="Elevation"
        isActive={elevationMode}
      />
      <div className="h-8"/>
      <ToolbarButton
        className="px-1"
        onClick={handlePlainMode}
        title="Plan"
        isActive={plainMode}
      />
      <div className="h-8"/>
    </div>
  );
});

export default ViewportStrucToolbar;
