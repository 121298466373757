import { useAuth } from "shared/components/auth/context";
import UserIcon from "shared/components/icons/user";
import { useUI } from "shared/components/ui/context";
import Menu from "shared/components/ui/menu";

const UserMenu = () => {
  const { signOut, user } = useAuth();
  const { openModal } = useUI();
  return (
    <Menu>
      <Menu.Button>
        <div className="inline-flex space-x-1 items-center bg-gray-700 px-2 py-1 rounded-md text-gray-400 text-xs hover:text-white">
          <UserIcon className="h-5 w-5" />
          <span>{user?.username}</span>
        </div>
      </Menu.Button>
      <Menu.List>
        <Menu.Link to="/cad/projects">Projects</Menu.Link>
        <Menu.Item onSelect={() => openModal("UPDATE_SETTINGS")}>
          Settings
        </Menu.Item>
        <Menu.Item onSelect={signOut}>Sign out</Menu.Item>
      </Menu.List>
    </Menu>
  );
};

export default UserMenu;
