import { FC, lazy } from "react";
import styled from "styled-components";
import { useUI } from "shared/components/ui/context";
import ProjectForm from "../projects/project-form";
import AddLayerForm from "../layers/layer-add-form";
import DeleteLayerForm from "../layers/layer-delete-form";
import ProjectDelete from "../projects/project-delete";
import ProjectRename from "../projects/project-rename";
import SettingsForm from "shared/components/settings/settings-form";
import PlaneList from "../planes/plane-list";
import Header from "./header";
import RenderIf from "shared/components/ui/render-if";
import DimensionStyles from "../dimensions/dimension-editor";

const Toast = lazy(() => import("shared/components/ui/toast"));
const Modal = lazy(() => import("shared/components/ui/modal"));

const Layout: FC = ({ children }) => {
  const {
    displayToast,
    toastData,
    closeToast,
    modalView,
    displayModal,
    closeModal,
    shouldShowHeader,
  } = useUI();

  return (
    <Wrapper className="layers">
      <RenderIf isTrue={shouldShowHeader}>
        <Header />
      </RenderIf>
      <ChildrenWrapper>{children}</ChildrenWrapper>
      <Modal isOpen={displayModal} onClose={closeModal}>
        {modalView === "UPDATE_SETTINGS" && <SettingsForm onClose={closeModal} />}
        {modalView === "ADD_PROJECT" && <ProjectForm onClose={closeModal} />}
        {modalView === "DELETE_CAD_PROJECT" && <ProjectDelete />}
        {modalView === "RENAME_FILE" && <ProjectRename />}

        {modalView === "ADD_LAYER" && <AddLayerForm onClose={closeModal} />}
        {modalView === "DELETE_LAYER" && <DeleteLayerForm onClose={closeModal} />}
        {modalView === "PLANE_LIST" && <PlaneList onClose={closeModal} />}
        {modalView === "DIMENSION_MNG" && <DimensionStyles onClose={closeModal} />}
      </Modal>
      {displayToast && toastData && (
        <Toast
          status={toastData.status}
          title={toastData.title}
          onClose={closeToast}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  background-color: var(--color-gray-200);
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  font-size: 0.75rem;
`;

const ChildrenWrapper = styled.div`
  margin-top: 64px;
`;
export default Layout;
