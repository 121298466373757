import { FC } from "react";
import cn from "classnames";

interface Props {
  title: string;
  className?: string;
}
const PropertiesAccordion: FC<Props> = (props) => {

  const { title, children, className } = props;
  const rootClassName = cn(
    "bg-none text-sm flex items-center justify-between w-full",
    "focus:outline-none hover:text-gray-100 flex-shrink-0",
    className
  );

  return (
    <>
      <div className={rootClassName}>
        <span>{title}</span>
      </div>
      <div>
        {children}
      </div>
    </>
  );
};

export default PropertiesAccordion;
