import * as THREE from "three";
import { getCurrentSolidMaterial, ISolidMaterial, materialCache } from "lib/materials";
import { copyIPoint } from "../../math/point";
import { objDataType } from "../types";
import { SolidData } from "./solid";
import { filledPolygon2DPoints, regionParam, updateFilledPolygon2DPoints } from "../../geometries/solid/region";

export class RegionData extends SolidData {

  public type = objDataType.REGION;
  protected nameObj: string = "Region";
  public definition: regionParam;

  constructor(definition: regionParam, material?: ISolidMaterial) {
    super();
    this.definition = {
      ptos2D: definition.ptos2D.map(copyIPoint),
      basePoint: copyIPoint(definition.basePoint),
      rotation: copyIPoint(definition.rotation),
      offset: copyIPoint(definition.offset),
      scale: copyIPoint(definition.scale),
    };
    this.material = material ?? getCurrentSolidMaterial();
  }
  static createObj(definition: regionParam, material: ISolidMaterial) {
    const threeMaterial = materialCache.getSolidMaterial(material);
    const graphicObj = filledPolygon2DPoints(definition.ptos2D, threeMaterial);
    const position = definition.basePoint;
    const rotation = definition.rotation;
    graphicObj.position.set(position.x, position.y, position.z);
    graphicObj.rotation.set(rotation.x, rotation.y, rotation.z);
    // // FGM: Para poder usar renderOrder en las regiones es necesario esto. NO FUNCIONA BIEN.
    // if (threeMaterial) {
    //   threeMaterial.depthTest = false;
    //   // FGM: Para las regiones graficas intentamos dar un renderOrder que evite el posible z-fighting.
    //   graphicObj.renderOrder = 10;
    //   // La putada de esto es doble: Por una parte jodemos los materiales compartidos que se usen en otros solidos, pero
    //   // ademas tambien se jode la visualizacion de las regiones pegadas sobre solidos: pues se ven desde el lado que no
    //   // deben por el doubleSide a pesar de que esten pegadas sobre un solido que lo debiera impedir...
    //   threeMaterial.depthWrite = false;
    //   graphicObj.onBeforeRender = (renderer: THREE.WebGLRenderer) => {
    //     renderer.clearDepth();
    //   }
    // }

    // Idea pajarera: Mover la posicion de la region para que salga de donde pueda estar encastrada.
    // FUNCIONA!!!.
    // graphicObj.onBeforeRender = (renderer: THREE.WebGLRenderer) => {
    //   let {x, y, z} = graphicObj.position;
    //   x += 0.5 - Math.random();
    //   y += 0.5 - Math.random();
    //   z += 0.5 - Math.random();
    //   graphicObj.position.x = x;
    //   graphicObj.position.y = y;
    //   graphicObj.position.z = z;
    // };

    return graphicObj;
  }
  public createGraphicObj() {
    if (this.graphicObj) {
      console.warn("Attention: Region graphic object already created!!");
      return;
    }
    this.graphicObj = RegionData.createObj(this.definition, this.material);
  }
  public cloneDefinition(): regionParam {
    const def = super.cloneSolidDefinition() as regionParam;
    def.ptos2D = this.definition.ptos2D.map(copyIPoint);
    return def;
  }
  public createObject(definition?: regionParam, material?: ISolidMaterial): THREE.Mesh {
    return RegionData.createObj(definition ?? this.definition, material ?? this.material);
  }
  public regenerateDefinition() {
    updateFilledPolygon2DPoints(this.graphicObj, this.definition.ptos2D);
    const position = this.definition.basePoint;
    const rotation = this.definition.rotation;
    this.graphicObj.position.set(position.x, position.y, position.z);
    this.graphicObj.rotation.set(rotation.x, rotation.y, rotation.z);
  }
}