import { gql, useMutation } from "@apollo/client";
import { GET_PROJECTS } from "modules/lite/services/projects";
import { useCallback, useState } from "react";
import { useUI } from "shared/components/ui/context";
import { GetProjectsData } from "./use-projects";

const DELETE_PROJECT = gql`
  mutation deleteProject($id: uuid!) {
    project: delete_project_by_pk(id: $id) {
      id
    }
  }
`;

type Status = "idle" | "loading" | "success" | "error";

interface Data {
  project: {
    id: string;
  };
}

interface Params {
  id: string;
}

export function useDeleteProject() {
  const { openToast, closeModal, modalData } = useUI();
  const [status, setStatus] = useState<Status>("idle");
  const [mutate] = useMutation<Data, Params>(DELETE_PROJECT, {
    update(cache, { data }) {
      if (data) {
        const query = cache.readQuery<GetProjectsData>({
          query: GET_PROJECTS,
        });
        if (query) {
          cache.writeQuery({
            query: GET_PROJECTS,
            data: {
              projects: query.projects.filter(
                (project) => project.id !== data.project.id
              ),
            },
          });
        }
      }
    },
  });

  const deleteProject = useCallback(() => {
    mutate({
      variables: { id: modalData?.id },
    })
      .then(() => {
        openToast({
          title: "Project successfully deleted",
          status: "success",
        });
        setStatus("success");
      })
      .catch(() => {
        openToast({
          title: "Error deleting project",
          status: "error",
        });
        setStatus("error");
      })
      .finally(() => {
        closeModal();
      });
  }, [closeModal, mutate, openToast, modalData]);

  const cancelDeleteProject = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return { deleteProject, status, cancelDeleteProject };
}
