import { AnalysisAction, AnalysisActionType } from "lib/events/analysis";
import { CompositeNode } from "lib/helpers/composite-tree";
import { analysisManager } from "lib/models-struc/analysis/analysismodel-manager";
import { analysisDispatcher } from "lib/models-struc/analysis/dispatcher";
import { Hypothesis } from "lib/models-struc/hypothesis/hypothesis";
import { hypoSolveItem, itemType } from "modules/cad/components/sidebar/context";
import { useEffect, useMemo, useReducer } from "react";
import { useStoreyDrift } from "../../storey-drift/hooks/use-storey-drift";

interface analysisState {
  solutionsNodes: CompositeNode<hypoSolveItem> | null;
}

function getSolutionNode(hypothesis: Hypothesis[]) {
  const prop: hypoSolveItem = { info: undefined, type: itemType.SOLVEANALYSIS };
  const solutionsNodes = new CompositeNode("Solution", prop);
  solutionsNodes.id = "solution_node";
  const resultNode = solutionsNodes.addChild("Hypothesis results", prop);
  resultNode.id = "hypothesis_result_node";
  for (const hypo of hypothesis) {
    const hypoRes = resultNode.addChild(hypo.name, { info: hypo, type: itemType.HYPOSET });
    hypoRes.id = `${hypo.uid}SolutionNode`;
    hypoRes.addChildLeaf("Displacements", { info: hypo, type: itemType.DISPLACEMENT });
    hypoRes.addChildLeaf("Beams F&M", { info: hypo, type: itemType.BEAMFM });
    hypoRes.addChildLeaf("Shells F&M", { info: hypo, type: itemType.SHELLFM });
    hypoRes.addChildLeaf("Nodal F&M", { info: hypo, type: itemType.NODALFM });
  }
  return solutionsNodes;
}

function reducerSolutions(state: analysisState, action: AnalysisAction): analysisState {
  switch (action.type) {
    case AnalysisActionType.SOLVE_ANALYSIS: {
      const solutionsNodes = getSolutionNode(action.payload.hypothesisSet);
      return { solutionsNodes };
    }
    default:
      throw new Error(`Action is not defined.`);
  }
}

export function useAnalysis() {

  const [solutionState, solutionDispatch] = useReducer(reducerSolutions, {}, () => {
    const isAnalysisDownloaded = analysisManager.analysis.project !== undefined && analysisManager.analysis.solveHypothesis.length > 0;
    if (isAnalysisDownloaded) {
      return { solutionsNodes: getSolutionNode(analysisManager.analysis.solveHypothesis) }
    }
    return { solutionsNodes: null }
  });

  useEffect(() => {
    analysisDispatcher.subscribe(solutionDispatch);
    return () => {
      analysisDispatcher.unsubscribe(solutionDispatch);
    }
  }, []);

  const { storeyDriftNodes } = useStoreyDrift();

  const nodes = useMemo(() => {
    if (!solutionState.solutionsNodes) return null;
    const solutionNodeClone = solutionState.solutionsNodes!.cloneTree();
    const storeyDriftNodeClone = storeyDriftNodes.cloneTree();
    solutionNodeClone.addChildNode(storeyDriftNodeClone);
    return solutionNodeClone;
  }, [solutionState.solutionsNodes, storeyDriftNodes])

  return {
    solutionNodes: nodes,
  }
}
