import { AnalysisProject } from "lib/apis/analysis-projects/api";
import { IAnalysisProject as IAnalysisProjectEcore } from "modules/struc/models/ecore/analysis";
import { Hypothesis } from "../hypothesis/hypothesis";

export interface AnalysisProperties {
  solveHypothesisIds?: string[]; // It is optional for backward compatibility
  project: AnalysisProject | undefined;
}

export interface AnalysisSettings {
  solveHypothesis: Hypothesis[];
  project: AnalysisProject | undefined;
}

export type AnalysisProjectWithErrors = {
  name: string,
  errors: string[]
}

export function isAnalysisProject(analysisProjectData: IAnalysisProjectEcore | AnalysisProjectWithErrors): analysisProjectData is IAnalysisProjectEcore {
  return "eClass" in analysisProjectData;
}