import RenderIf from "shared/components/ui/render-if";
import {
  eventFormats,
  isErrorEvent,
  isPendingEvent,
  isStartEvent,
  isSuccessEvent,
} from "modules/lite/utils/events";
import { FC } from "react";
import { ProjectEvent } from "modules/lite/models/event";

const SuccessEventIcon = () => (
  <div>
    <span className="h-5 w-5 rounded-full bg-white flex items-center justify-center ring-8 ring-white">
      <svg
        className="h-5 w-5 text-green-500"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fillRule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clipRule="evenodd"
        />
      </svg>
    </span>
  </div>
);

const PendingEventDot = () => (
  <div>
    <span className="h-5 w-5 rounded-full bg-white flex items-center justify-center ring-8 ring-white">
      <div className="h-4 w-4 -ml-1 rounded-full bg-gray-300"></div>
    </span>
  </div>
);

export const StartedEventDot = () => (
  <div>
    <span className="h-5 w-5 rounded-full bg-white flex items-center justify-center ring-8 ring-white">
      <svg
        className="animate-spin h-5 w-5 -ml-1 text-indigo-400"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </span>
  </div>
);

const ErrorEventIcon = () => (
  <div>
    <span className="h-5 w-5 rounded-full -ml-1 bg-white flex items-center justify-center ring-8 ring-white">
      <svg
        className="h-5 w-5 text-red-500"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
    </span>
  </div>
);

interface Props {
  event: ProjectEvent;
  isLastEvent?: boolean;
}

const ProjectTimelineEvent: FC<Props> = ({ event, isLastEvent, children }) => {
  return (
    <div className="flex space-x-3 items-center">
      {isErrorEvent(event) && <ErrorEventIcon />}
      {isSuccessEvent(event) && <SuccessEventIcon />}
      {isPendingEvent(event) && <PendingEventDot />}
      {isStartEvent(event) && <StartedEventDot />}
      <div className="min-w-0 flex-1 flex flex-col justify-between items-baseline">
        <div className="flex justify-between items-center space-x-2">
          <p className="text-sm text-gray-600">{eventFormats[event.action]}</p>
          {children}
        </div>
        <div className="text-right text-xs whitespace-nowrap text-gray-400 h-1">
          {event.createdAt && (
            <time dateTime={event.createdAt}>
              {new Date(event.createdAt).toLocaleString()}
            </time>
          )}
        </div>
      </div>
      <RenderIf isTrue={!isLastEvent}>
        <div className="w-8 h-0.5 bg-gray-300" aria-hidden="true"></div>
      </RenderIf>
    </div>
  );
};

export default ProjectTimelineEvent;
