import { linealPrecision } from "lib/general-settings";
import { getCenterObjData } from "lib/geometries/centroids";
import { ISolidMaterial } from "lib/materials";
import { IObjData } from "lib/models/objdata";
import { solidParam } from "lib/models/solids/solid";
import { DataDefinitionHandler } from "./base";
import { coneProp } from "./solids/cone";
import { cubeProp } from './solids/cube';
import { cylinderProp } from "./solids/cylinder";
import { planeProp } from "./solids/plane";
import { pprismProp } from "./solids/pprism";
import { regionProp } from "./solids/region";
import { rprismProp } from "./solids/rprism";
import { structuralPropTypes } from "./structural/structural";

type solidPropTypes = coneProp | cubeProp | cylinderProp | planeProp | pprismProp | regionProp | rprismProp;
type SolidTypes = solidPropTypes | structuralPropTypes;

export abstract class SolidDataDefinitionHandler<T extends SolidTypes> extends DataDefinitionHandler<T, ISolidMaterial, undefined, T> {

  protected fillSolidDefinition(data: IObjData) {
    const def = data.definition as solidParam;
    const centroId = getCenterObjData(data);
    (this.definitionInfo as any).basePoint = this.getPointView(def.basePoint, "Base point", "m", linealPrecision);
    (this.definitionInfo as any).offset = this.getPointView(def.offset, "Offset", "m", linealPrecision);
    (this.definitionInfo as any).rotation = this.getPointAngleView(def.rotation, "Rotation");
    (this.definitionInfo as any).scale = this.getPointView(def.scale, "Scale", "", linealPrecision, undefined, (scl: number) => scl > 0);
    (this.definitionInfo as any).centroid = this.getPointView(centroId, "Centroid", "m", linealPrecision, false);
    this.materialInfo = this.getSolidMaterialView(data.material as ISolidMaterial);
  }

  protected checkSolidNewDefinition(newDefinition: SolidTypes): boolean {
    if (!newDefinition) { return false; }
    if (newDefinition.basePoint && !this.checkPoint(newDefinition.basePoint)) { return false; }
    if (newDefinition.rotation && !this.checkPoint(newDefinition.rotation)) { return false; }
    if (newDefinition.scale && !this.checkPoint(newDefinition.scale)) { return false; }
    if (newDefinition.offset && !this.checkPoint(newDefinition.offset)) { return false; }
    return true;
  }

  protected changedSolidNewDefinition(oldDefinition: solidParam, newDefinition: SolidTypes): solidParam | null {
    let def = oldDefinition;
    let changed: boolean = false;
    if (newDefinition) {
      const basePoint = this.changedPoint(def.basePoint, newDefinition.basePoint);
      if (basePoint !== null) {
        def.basePoint = basePoint;
        changed = true;
      }
      const rotation = this.changedPointRotation(def.rotation, newDefinition.rotation);
      if (rotation !== null) {
        def.rotation = rotation;
        changed = true;
      }
      const scale = this.changedPoint(def.scale, newDefinition.scale);
      if (scale !== null) {
        def.scale = scale;
        changed = true;
      }
      const offset = this.changedPoint(def.offset, newDefinition.offset);
      if (offset !== null) {
        def.offset = offset;
        changed = true;
      }
    }
    return (changed ? def : null);
  }
}