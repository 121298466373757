import Section from "shared/components/ui/section";
import { Project } from "lib/apis/struc-project/api";
import FormSelect from "shared/components/ui/form-select";
import { useCountryCities } from "./hook/use-country-cities";
import Input from "modules/lite/components/ui/input";

interface Props {
  project: Project;
  register: any;
}

const DetailsForm = ({ project, register }: Props) => {

  const {
    countries,
    currentCountry,
    handleChangeCountry,
    cities,
    currentCity,
    handleChangeCity
  } = useCountryCities(project?.Country, project?.City);
  
  return (
    <Section title="Details" isCollapsible={true}>
    <div className="grid grid-cols-6 gap-6">
      <div className="col-span-2 sm:col-span-2">
        <Input
          defaultValue={project?.Name}
          id="name"
          label="Name"
          name="Name"
          ref={register}
          placeholder="Project name"
          required={true}
          dark
        />
      </div>
      <div className="col-span-4">
        <Input
          defaultValue={project?.Remarks}
          id="remarks"
          label="Remarks"
          name="Remarks"
          ref={register}
          placeholder="Project description"
          dark
        />
      </div>
      <div className="col-span-2">
        <FormSelect
          name="Country"
          ref={register}
          label="Country"
          dark
          value={currentCountry[0]}
          onChange={handleChangeCountry}
        >
          {countries && countries.map((continent) => (
            <optgroup key={continent.ContinentISO} label={continent.Continent}>
              {continent.Countries && continent.Countries.map((country) => (
                <option key={country.CountryISO} value={country.Country}>
                  {country.Country}
                </option>
              ))}
            </optgroup>
          ))}
        </FormSelect>
      </div>
      <div className="col-span-2">
        <FormSelect
          name="City"
          ref={register}
          label="City"
          dark
          value={currentCity}
          onChange={handleChangeCity}
        >
          {cities && cities.map((city, ind) => (
            <option key={ind} value={city.CityName}>
              {city.CityName}
            </option>
          ))}
        </FormSelect>
      </div>
    </div>
  </Section>
  );
};

export default DetailsForm;
