import { ReactNode } from "react-router/node_modules/@types/react";
import styled from "styled-components";

// https://htmldom.dev/create-resizable-split-views/
interface Props {
  id: string;
  topChild: ReactNode;
  topClassName?: string;
  bottomChild: ReactNode;
  bottomClassName?: string;
}

const VerticalResizer = (props: Props) => {

  const { id, topChild, topClassName, bottomChild, bottomClassName } = props;

  const mouseDownHandler = (event: PointerEvent) => {
    // Attach the listeners to `document`
    document.addEventListener("pointermove", mouseMoveHandler);
    document.addEventListener('pointerup', mouseUpHandler);
  };
  const mouseUpHandler = (e: PointerEvent) => {

    const resizer = document.getElementById(id) as HTMLElement;
    const leftSide = resizer.previousElementSibling as HTMLElement;
    const rightSide = resizer.nextElementSibling as HTMLElement;

    resizer.style.removeProperty('cursor');
    document.body.style.removeProperty('cursor');

    leftSide.style.removeProperty('user-select');
    leftSide.style.removeProperty('pointer-events');

    rightSide.style.removeProperty('user-select');
    rightSide.style.removeProperty('pointer-events');

    // Remove the handlers of `mousemove` and `mouseup`
    document.removeEventListener('pointermove', mouseMoveHandler);
    document.removeEventListener('pointerup', mouseUpHandler);
  };
  const mouseMoveHandler = (e: PointerEvent) => {

    const resizer = document.getElementById(id) as HTMLElement;
    const topSide = resizer.previousElementSibling as HTMLElement;
    const bottomSide = resizer.nextElementSibling as HTMLElement;
    const parentSize = (resizer.parentNode as HTMLElement).getBoundingClientRect();

    const parentHeight = parentSize.height;
    const resizerHeight = resizer.getBoundingClientRect().height;

    const topHeightPx = (e.clientY - parentSize.top) - (resizerHeight * 0.5);
    const bottomHeightPx = (parentHeight - topHeightPx) - (resizerHeight * 1);

    topSide.style.height = `${(topHeightPx * 100) / parentHeight}%`;
    bottomSide.style.height = `${(bottomHeightPx * 100) / parentHeight}%`;

    resizer.style.cursor = 'row-resize';
    document.body.style.cursor = 'row-resize';

    topSide.style.userSelect = 'none';
    topSide.style.pointerEvents = 'none';

    bottomSide.style.userSelect = 'none';
    bottomSide.style.pointerEvents = 'none';
  };

  return (
    <Parentresizer className={"h-full flex flex-col justify-between overflow-hidden"}>
      <Contentresizer className={"h-1/2 " + topClassName ?? ""} >
        {topChild}
      </Contentresizer>
      <Resizer id={id} onPointerDown={(a: any) => { mouseDownHandler(a); }} />
      <Contentresizer className={"h-1/2 " + bottomClassName ?? ""} >
        {bottomChild}
      </Contentresizer>
    </Parentresizer >
  )
};

const Parentresizer = styled.div`
  background-color: var(--color-gray-200);
`;

const Resizer = styled.div`
  background-color: var(--color-gray-400);
  cursor: s-resize;
  width: 100%;
  height: 4px;
  min-height: 4px;
  &:hover {
    background-color: var(--color-primary);
  }
`;
const Contentresizer = styled.div`
  overflow-y: hidden;
  min-height: min-content;
  width: 100%;
`;

export default VerticalResizer;