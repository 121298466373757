import { IPolylineParam } from "lib/math/line";
import { IPoint } from "lib/math/types";
import { solidParam } from "lib/models/solids/solid";
import { shellCrossSectionCache } from "../cross-sections-shape/shell-cross-sections/cache";
import { isWallCSSParams } from "../cross-sections-shape/shell-cross-sections/types";
import { heightType, growthWidth, storeyMaterialType } from './struc-base';

export enum wallTypes { SHEAR, RETAINING }
export const wallTypeNames: string[] = [];
wallTypeNames[wallTypes.SHEAR] = "Shear wall";
wallTypeNames[wallTypes.RETAINING] = "Retaining wall";

export interface wallParam extends solidParam {
  storeyId: string;
  name: string;
  lnkObjIds: string[];
  materialType: storeyMaterialType;

  wallType: wallTypes;
  heightType: heightType;

  ptos2D: IPolylineParam;
  widthType: growthWidth;
  height: number;
  stretch: IWallStretch[];
  shellCrossSectionId: string;
}
export interface IWallStretch {
  normal: IPoint;
  orientation: 1 | -1;
  holes: IWallHole[];
}
export interface IWallHole {
  points: IPoint[];
}

export function getWallCSSId() {
  const scs = shellCrossSectionCache.getAllLoadedStyles();
  let wallCSS = scs.find(s => isWallCSSParams(s.parameters))!;
  return wallCSS.styleId;
}
