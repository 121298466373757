import { DragEvent, useCallback } from 'react'
import Table from 'shared/components/ui/table'
import ContentEditable from 'shared/components/ui/table/content-editable'
import { Storey } from 'modules/lite/models/storey'
import StoreyUploader from './storey-uploader'
import { occupancyNameList } from 'modules/struc/utils/storeys'

interface Props {
  storeys: Storey[]
  onChange: (storey: Storey) => void
  onDrop: (source: number, destination: number) => void
}
const StoreyList = ({ storeys, onChange, onDrop }: Props) => {
  const handleDragStart = (event: DragEvent<HTMLTableRowElement>) => {
    event.currentTarget.classList.add('dragging')
    event.dataTransfer.setData(
      'text/plain',
      event.currentTarget.dataset.storeyNumber as string
    )
  }
  const handleDragEnd = (event: DragEvent<HTMLTableRowElement>) => {
    event.currentTarget.classList.remove('dragging')
  }
  const handleDragEnter = (event: DragEvent<HTMLTableRowElement>) => {
    event.currentTarget.classList.add('dragging-over')
  }

  const handleDragLeave = (event: DragEvent<HTMLTableRowElement>) => {
    event.currentTarget.classList.remove('dragging-over')
  }
  const handleDrop = useCallback(
    (event: DragEvent<HTMLTableRowElement>) => {
      event.currentTarget.classList.remove('dragging-over')
      const source = event.dataTransfer.getData('text/plain')
      const destination = event.currentTarget.dataset.storeyNumber as string
      onDrop(+source, +destination)
    },
    [onDrop]
  )
  const handleDragOver = (event: DragEvent<HTMLTableRowElement>) => {
    event.preventDefault()
  }
  return (
    <Table>
      <Table.Header>
        <Table.HeaderCell>Position</Table.HeaderCell>
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.HeaderCell>height</Table.HeaderCell>
        <Table.HeaderCell>Level</Table.HeaderCell>
        <Table.HeaderCell>Occupancy</Table.HeaderCell>
        <Table.HeaderCell>DXF</Table.HeaderCell>
        <Table.HeaderCell />
      </Table.Header>
      <Table.Body>
        {storeys?.map((storey, index) => {
          const {
            id,
            storey_number,
            floor_name,
            height,
            occupancy,
            level,
            dxf_name,
            dxf_url,
            file,
          } = storey
          return (
            <Table.Row
              key={id}
              draggable
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              data-storey-number={index}
            >
              <Table.Cell>{storey_number}</Table.Cell>
              <Table.Cell className="text-gray-700">
                <ContentEditable
                  value={floor_name}
                  onChange={value => onChange({ ...storey, floor_name: value })}
                />
              </Table.Cell>
              <Table.Cell>
                <ContentEditable
                  value={height}
                  onChange={value => onChange({ ...storey, height: value })}
                />
              </Table.Cell>
              <Table.Cell>{level.toFixed(2)}</Table.Cell>
              <Table.Cell>
                <ContentEditable
                  value={occupancy}
                  onChange={value => onChange({ ...storey, occupancy: value })}
                  type="select"
                  options={occupancyNameList}
                />
              </Table.Cell>
              <Table.Cell>{dxf_name || 'No file selected'}</Table.Cell>
              <Table.Cell>
                <StoreyUploader
                  onChange={dxf =>
                    onChange({
                      ...storey,
                      dxf_name: dxf?.name,
                      dxf_url: dxf?.url,
                    })
                  }
                  dxf_url={dxf_url}
                  dxf_name={dxf_name}
                  file={file}
                />
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default StoreyList
