import { AnalysisProjectStatusMPValueEnum } from "lib/apis/analysis-projects/api";
import { useMainGraphicContext } from "modules/cad/components/viewport/context";
import { useCallback, useEffect, useState } from "react";
import { useTimer } from "shared/components/ui/hooks/use-timer";
import { useFavicon } from "shared/utils/hooks/use-favicon";
import { useStrucProjectContext } from "../../context";
import useSaveStrucProject from "../../project/hook/use-save-project";

export interface analysisProjectEvent {
  name: string;
  date: Date;
}

let intervalAnalysing: NodeJS.Timeout;

export function useAnalyzing() {

  const graphicProc = useMainGraphicContext();
  const saveStructProject = useSaveStrucProject(graphicProc);
  const { analysisManager } = useStrucProjectContext();

  const { setDefault, setRunning } = useFavicon();

  const { seconds, setIsActive } = useTimer();

  const [status, setStatus] = useState<AnalysisProjectStatusMPValueEnum>(AnalysisProjectStatusMPValueEnum.Running);
  const [error, setError] = useState<string | undefined>(undefined);
  const [lastEvents, setLastEvents] = useState<analysisProjectEvent[]>([]);

  const getLastEvents = useCallback(async () => {
    const events = await analysisManager.getAnalysingLastEvents();
    if (events.length) {
      setLastEvents(events);
    }
  }, [analysisManager])

  const cancelAnalysing = async () => {
    try {
      if (intervalAnalysing) clearInterval(intervalAnalysing);
      const canceled = await analysisManager.cancelAnalysing();
      if (canceled) {
        const newStatus = await analysisManager.getAnalyzingStatus();
        if (newStatus) {
          setStatus(newStatus);
          if (newStatus === AnalysisProjectStatusMPValueEnum.Canceled) {
            endProcess();
            await getLastEvents();
          }
        }
      }
    } catch (err: any) {
      console.error("[cancelMeshing] something go wrong ")
    }
  }

  const endProcess = useCallback(() => {
    if (intervalAnalysing) clearInterval(intervalAnalysing);
    setIsActive(false);
    setDefault();
  }, [setDefault, setIsActive])

  useEffect(() => {
    const initAnalysis = async () => {
      setIsActive(true);
      setRunning();
      const needsSaveProject = await analysisManager.launchAnalysisProject(graphicProc);
      // Save struct project to save analysis id task
      if (needsSaveProject) saveStructProject();
      await getLastEvents();

      intervalAnalysing = setInterval(() => {
        const checkStatus = async () => {
          const newStatus = await analysisManager.getAnalyzingStatus();
          if (newStatus) {
            setStatus(newStatus);
            if (newStatus !== AnalysisProjectStatusMPValueEnum.Running) {
              if (newStatus === AnalysisProjectStatusMPValueEnum.Done) {
                endProcess();
              } else {
                console.assert(false);
                throw new Error("Mesh process has failed");
              }
            }
          }
          await getLastEvents();
        }
        checkStatus().catch((err) => {
          console.error(err);
          setStatus(AnalysisProjectStatusMPValueEnum.Error);
          setError(err.toJSON ? err.toJSON().message : err.message);
          // update last meshing events
          getLastEvents();
          endProcess();
        });
      }, 15000); // send status request each 15 seconds
    }
    initAnalysis().catch((err) => {
      console.error(err);
      setStatus(AnalysisProjectStatusMPValueEnum.Error);
      endProcess();
    });
    return () => {
      if (intervalAnalysing) {
        clearInterval(intervalAnalysing);
      }
    }
  }, [])

  return { status, error, seconds, lastEvents, cancelAnalysing };
}