import { IObjData } from "../objdata";
import { definitionType, materialType, objDataType } from "../types";
import {
  PointCreator,
  LineCreator,
  ArcCreator,
  PolygonCreator,
  CircleCreator,
  EllipseCreator,
  EllipseArcCreator,
  TextCreator,
  BlockCreator,
  AlignedDimCreator,
  LinealDimCreator,
  AngleDimCreator,
  ArcDimCreator,
  CircularDimCreator,
  RegionCreator,
  CubeCreator,
  CylinderDimCreator,
  PlaneCreator,
  ConeCreator,
  RPrismCreator,
  PPrismCreator,
} from "./datamodel-creator";
import {
  ColumnCreator,
  SlabCreator,
  BeamCreator,
  WallCreator,
  FooterCreator,
  PileCapCreator,
  LoadCreator,
  StripFooterCreator
} from "./strucmodel-creator";

export function getDataModelBuilder(type: objDataType, definition: definitionType, material?: materialType): IObjData {
  const creator = getCreator(type);
  return creator.createObjData(definition, material);
}
export function cloneDataModel<T extends IObjData>(dataObj: T): T {
  const creator = getCreator(dataObj.type);
  const data = creator.cloneObjData(dataObj);
  data.createGraphicObj();
  return data as T;
}
export function getDataBuilder(type: objDataType, definition: definitionType, material?: materialType): THREE.Object3D {
  const creator = getCreator(type);
  return creator.createGraphicObj(definition, material);
}

function getCreator(type: objDataType) {
  switch (type) {
    case objDataType.POINT: return new PointCreator();
    case objDataType.POLYLINE: return new LineCreator();
    case objDataType.ARC: return new ArcCreator();
    case objDataType.POLYGON: return new PolygonCreator();
    case objDataType.CIRCLE: return new CircleCreator();
    case objDataType.ELLIPSE: return new EllipseCreator();
    case objDataType.ELLIPSEARC: return new EllipseArcCreator();

    case objDataType.TEXT: return new TextCreator();
    case objDataType.BLOCK: return new BlockCreator();
    case objDataType.BLOCKREF: return new BlockCreator();

    case objDataType.ALIGNEDDIM: return new AlignedDimCreator();
    case objDataType.LINEARDIM: return new LinealDimCreator();
    case objDataType.ANGULARDIM: return new AngleDimCreator();
    case objDataType.ARCDIM: return new ArcDimCreator();
    case objDataType.CIRCULARDIM: return new CircularDimCreator();

    case objDataType.REGION: return new RegionCreator();
    case objDataType.CUBE: return new CubeCreator();
    case objDataType.CYLINDER: return new CylinderDimCreator();
    case objDataType.PLANE: return new PlaneCreator();
    case objDataType.CONE: return new ConeCreator();
    case objDataType.RRPRIM: return new RPrismCreator();
    case objDataType.PPRISM: return new PPrismCreator();

    case objDataType.COLUMN: return new ColumnCreator();
    case objDataType.SLAB: return new SlabCreator();
    case objDataType.BEAM: return new BeamCreator();
    case objDataType.WALL: return new WallCreator();

    case objDataType.LOAD: return new LoadCreator();

    case objDataType.FOOTER: return new FooterCreator();
    case objDataType.STRIPFOOTER: return new StripFooterCreator();
    case objDataType.PILECAP: return new PileCapCreator();
    default:
      throw new Error(`[DataModel] objDataType ${type} doesn't support`);
  }
}

