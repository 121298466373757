import cn from "classnames";
import { forwardRef, InputHTMLAttributes } from "react";
import ExclamationIcon from "shared/components/icons/exclamation";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label?: string;
  error?: string;
  srOnly?: boolean;
  dark?: boolean;
}

const Input = forwardRef<HTMLInputElement, Props>((props, inputRef) => {
  const {
    className,
    children,
    id,
    label,
    onChange,
    type,
    error,
    srOnly,
    dark,
    ...rest
  } = props;
  const rootClassName = cn(
    "mt-1 block w-full border rounded-md shadow-sm py-1 px-2 focus:outline-none sm:text-sm text-gray-700",
    error
      ? "border-red-500 focus:outline-none focus:ring-red-500 focus:border-red-500"
      : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500",
    className
  );
  return (
    <div>
      <label
        htmlFor={id}
        className={
          srOnly
            ? "sr-only"
            : `block text-sm font-medium text-gray-${dark ? '2' : '6'}00 capitalize`
        }
      >
        {label || rest.name}
      </label>
      <div className={`${!srOnly ? "mt-1" : ""} relative`}>
        {!!error && error === "required" && (
          <div className="absolute -top-6 right-0">
            <span className="text-xs font-medium text-red-500 uppercase">
              Required
            </span>
          </div>
        )}
        <input
          id={id}
          ref={inputRef}
          className={rootClassName}
          onChange={onChange}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          type={type || "text"}
          {...rest}
        />
        {!!error && !error.includes("required") && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationIcon className="h-5 w-5 text-red-500" />
          </div>
        )}
      </div>
      {!!error && !error.includes("required") && (
        <p className="mt-2 text-sm text-red-600">{error}</p>
      )}
    </div>
  );
});

Input.displayName = "Input";

export default Input;
