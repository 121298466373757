import { RegionCommand } from "lib/commands/primitives/region";

import { lineAddVertex, lineMoveVertex } from "lib/geometries/line";
import { filledPolygon2DPoints, updateFilledPolygon2DPoints } from "lib/geometries/solid/region";
import { getCurrentSolidMaterial } from "lib/materials";
import { copyIPoint } from "lib/math/point";
import { IPoint } from "lib/math/types";
import { cadOpType } from "../factory";
import { LineBaseOP } from "../primitive/line";
import { settingsOpModes } from "../step-operations";


export abstract class RegionBaseOP extends LineBaseOP {

  protected auxRegion: THREE.Mesh;
  protected basePoint: IPoint = { x: 0, y: 0, z: 0 };
  protected rotation: IPoint = { x: 0, y: 0, z: 0 };
  protected offSet: IPoint = { x: 0, y: 0, z: 0 };
  protected scale: IPoint = { x: 1, y: 1, z: 1 };

  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Insert point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
    }]);
  }
  public async start() {
    this.auxRegion = filledPolygon2DPoints([]);
    this.saveToTempScene(this.auxRegion);
    super.start();
  }
  public setLastPoint(): void {
    const planeManager = this.graphicProcessor.getPlaneManager();
    if (this.numPoints === 1) {
      planeManager.activePlane.position = copyIPoint(this.lastPoint);
      planeManager.activePlane.locked = true;
    }

    const { x, y, z } = this.lastPoint;
    lineAddVertex(this.line, x, y, z);

    this.points.push(planeManager.activePlane.getRelativePoint(this.lastPoint));


    updateFilledPolygon2DPoints(this.auxRegion, this.points);
    const { position, rotation } = planeManager.activePlane;
    this.basePoint = position;
    this.rotation = rotation;
    this.auxRegion.position.set(position.x, position.y, position.z);
    this.auxRegion.rotation.set(rotation.x, rotation.y, rotation.z);

    lineMoveVertex(this.auxLine, x, y, z, 0);
    if (this.numPoints === 1) {
      lineMoveVertex(this.auxLine, x, y, z);
    }
  }
  public moveLastPoint(pto: IPoint) {
    super.moveLastPoint(pto);
    this.updateRegion(pto);
  }
  protected updateRegion(pto: IPoint) {
    const planeManager = this.graphicProcessor.getPlaneManager();
    const ptos2D = this.points.concat(planeManager.activePlane.getRelativePoint(pto));
    updateFilledPolygon2DPoints(this.auxRegion, ptos2D);
    const { position, rotation } = planeManager.activePlane;
    this.basePoint = position;
    this.rotation = rotation;
    this.auxRegion.position.set(position.x, position.y, position.z);
    this.auxRegion.rotation.set(rotation.x, rotation.y, rotation.z);
  }
}
export class RegionOP extends RegionBaseOP {

  public opType = cadOpType.REGION;

  public save() {
    if (this.graphicProcessor && this.points.length > 2) {
      const command = new RegionCommand(this.points, this.basePoint, this.rotation, this.offSet, this.scale, this.getCurrentSceneId(), this.graphicProcessor, getCurrentSolidMaterial());
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
}