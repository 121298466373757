import * as THREE from "three";
import { Cad3dOp } from "./base";
import { cadOpType, OpFactory } from "./factory";
import { DeleteCommand } from "lib/commands/delete";
import { cadClipBoard } from "lib/clipboard";
import { getCenterMinZMultiObjData } from "lib/geometries/centroids";
import { RectangleSelector } from "lib/selection/rectangle-selector";
import { CutCommand } from "lib/commands/copy";
import { IObjData } from "lib/models/objdata";
import { createBufferCube } from '../geometries/solid/cube';
import { getDefaultSolidMaterial } from '../materials/solid';
import { rayCastResults } from "lib/coordinates/raycaster";
import { VertexNormalsHelper } from 'three/examples/jsm/helpers/VertexNormalsHelper.js';
import { CSG } from 'lib/helpers/three-csg/csg-lib';
import { getPileCap } from "lib/geometries/structural/pile-cap";
import { PasteOP } from "./edition/paste";
import { IOpFun } from "./edition/multiop";

export class SelectOP extends Cad3dOp {

  public opType = cadOpType.SELECT;

  private rectSelector: RectangleSelector;
  public operationLauncherEnabled: boolean = true;

  protected iniSettingsOp(): void {
    // TODO: throw new Error("Method not implemented.");
  }
  public start() {
    this.registerRectSelector();
    this.registerRepeatOP();
    this.registerKeyBoard();
    this.registerMarksRaycast();
  }

  private registerRectSelector() {
    this.rectSelector = new RectangleSelector(this.graphicProcessor, this.processSelectionObjCallback);
  }
  private unregisterRectSelector() {
    this.rectSelector.unregister();
  }

  /* #################################################################### */
  /*                        REGISTRO A EVENTOS                            */
  /* #################################################################### */

  protected registerRepeatOP() {
    this.eventManager.connectMouseSecondUpEvent(this.handleRepeatOP);
  }
  protected unRegisterRepeatOP() {
    this.eventManager.disconnectMouseSecondUpEvent(this.handleRepeatOP);
  }
  private handleRepeatOP = (): void => {
    OpFactory.repeatOP(this.graphicProcessor);
  };

  // ----------------------------------------------------------------------

  protected registerKeyBoard(): void {
    if (this.treEventNames.has("keypress")) return;
    this.treEventNames.add("keypress");
    this.eventManager.connectKeyEvent(this.keypress);
  }
  protected unRegisterKeyBoard() {
    if (this.treEventNames.has("keypress")) {
      this.treEventNames.delete("keypress");
      this.eventManager.disconnectKeyEvent(this.keypress);
    }
  }
  private keypress = (event: KeyboardEvent) => {
    event.stopPropagation();
    if (event.ctrlKey) {
      switch (event.key) {
        case "z":
        case "Z":
          this.graphicProcessor.undo();
          break;
        case "y":
        case "Y":
          this.graphicProcessor.redo();
          break;
        case "c":
        case "C":
          const objsCopy = this.graphicProcessor.getSelectedObjs();
          if (objsCopy.length > 0) {
            const basePoint = objsCopy.length > 1 ? getCenterMinZMultiObjData(objsCopy) : objsCopy[0].getBasePoint();
            cadClipBoard.setElements(objsCopy, basePoint);
          }
          break;
        case "v":
        case "V":
          const elements = cadClipBoard.getElements();
          if (this.operationLauncherEnabled && elements && elements.length > 0) {
            this.graphicProcessor.launchOP<typeof PasteOP>(cadOpType.PASTE, [cadClipBoard.getElements(), cadClipBoard.basePoint]);
          }
          break;
        case "x":
        case "X":
          const objsCut = this.graphicProcessor.getSelectedObjs();
          if (objsCut.length > 0) {
            const basePoint = objsCut.length > 1 ? getCenterMinZMultiObjData(objsCut) : objsCut[0].getBasePoint();
            cadClipBoard.setElements(objsCut, basePoint);
            const cutCmd = new CutCommand(objsCut, this.graphicProcessor);
            this.graphicProcessor.storeAndExecute(cutCmd);
          }
          break;
      }
    } else {
      switch (event.key) {
        case "Escape":
          this.graphicProcessor.unselectAll();
          break;
        case "Delete":
          const objsDelete = this.graphicProcessor.getSelectedObjs();
          if (this.operationLauncherEnabled && objsDelete.length > 0) {
            const deleteCmd = new DeleteCommand(objsDelete, this.graphicProcessor);
            this.graphicProcessor.storeAndExecute(deleteCmd);
          }
          break;
        case " ":
          // OpFactory.repeatOP();
          // this.testMultiCubes();
          // this.graphicProcessor.seeRendererInfo();
          // materialCache.infoCacheMaterials();
          // materialCache.clear();
          // this.testCasetones();
          // const graphicProcessor = GetMainGraphicContext();
          this.testEdgeGEometry();
          break;
        case "u":
          this.testNormalHelper();
          break;
      }
    }
  };

  // ----------------------------------------------------------------------

  protected registerMarksRaycast(): void {
    this.eventManager.connectMouseMainDownEvent(this.markRaycastEventCallback);
  }
  protected unRegisterMarksRaycast() {
    this.eventManager.disconnectMouseMainDownEvent(this.markRaycastEventCallback);
  }
  protected markRaycastEventCallback = (ev: PointerEvent): void => {
    if (this.finished) return;
    const raycaster = this.graphicProcessor.getRaycaster();
    const selectionScene = this.graphicProcessor.getSelectionScene();
    const intersects = raycaster.raycastObjects(selectionScene);
    if (intersects.length) {
      // Raycast to selection marks
      this.processMarkRaycastCallback(intersects);
    } else {
      // Raycast to graphic Object
      const castObjs = this.graphicProcessor.getRayCastObjects();
      if (castObjs.length > 0) {
        this.raycastCallback(castObjs);
      }
    }
  };

  /* #################################################################### */
  /*                          RESULTS RAYCAST                             */
  /* #################################################################### */

  private processMarkRaycastCallback = (castObjs: THREE.Intersection[]): void => {
    // Raycast to selection Mark
    let ops: IOpFun[] = [];
    for (const mark of castObjs) {
      const selMngr = this.graphicProcessor.getSelectionManager();
      const opFun = selMngr.getMarksOperation(mark);
      if (opFun) ops.push(opFun);
    }
    if (ops.length && this.operationLauncherEnabled) {
      if (ops.length === 1) {
        ops[0].graphicProcessor.launchOP<any>(ops[0].type, ops[0].args);
      } else {
        this.graphicProcessor.launchOP<any>(cadOpType.MULTIOP, [ops]);
      }
    }
  };

  private raycastCallback = (castObjs: rayCastResults[]): void => {
    // Raycast to graphic Object
    if (castObjs.length > 0) {
      const objData = castObjs[0].dataObject;
      this.rectSelector.unPreSelectData(objData);
      const selectManager = this.graphicProcessor.getSelectionManager();
      selectManager.selectObjDatas([objData]);
    }
  };

  private processSelectionObjCallback = (objDatas: IObjData[]) => {
    for (const data of objDatas) {
      this.rectSelector.unPreSelectData(data);
    }
    const selectManager = this.graphicProcessor.getSelectionManager();
    selectManager.selectObjDatas(objDatas);
  }

  /* #################################################################### */
  /*                      FUNCIONES DE FINALIZADO                         */
  /* #################################################################### */

  public save(): void {
    // Method not implemented for Select Op
  }

  public endOperation(): void {
    if (this.finished === false) {
      this.finished = true;
      this.unregisterRectSelector();
      this.unRegisterKeyBoard();
      this.unRegisterMarksRaycast();
      this.unRegisterRepeatOP();
      this.unRegister();
    }
  }
  public cancelOperation(): void {
    this.endOperation();
  }

  /* #################################################################### */

  private async testMultiCubes() {
    const col = 20;
    const row = 20;
    const height = 20;
    const space = 75;
    const side = 50;
    // const modelManager = this.graphicProcessor.getDataModelManager();

    const geometry = createBufferCube(side);
    const solidMat = getDefaultSolidMaterial();
    const material = new THREE.MeshPhongMaterial({
      color: new THREE.Color(solidMat.color.r / 255, solidMat.color.g / 255, solidMat.color.b / 255),
      transparent: solidMat.color.a !== 1,
      opacity: solidMat.color.a,
      side: THREE.DoubleSide,
    });

    for (let i = 0; i < col; i++) {
      for (let j = 0; j < row; j++) {
        for (let k = 0; k < height; k++) {

          const mesh = new THREE.Mesh(geometry, material);
          mesh.position.set(i * space, j * space, k * space);
          this.getTempScene().add(mesh)

          // const cube: cubeParam = {
          //   side,
          //   rotation: { x: 0, y: 0, z: 0 },
          //   offset: { x: 0, y: 0, z: 0 },
          //   scale: { x: 1, y: 1, z: 1 },
          //   basePoint: { x: i * space, y: j * space, z: k * space },
          // }
          // const data = new CubeData(cube, this.getSceneId());
          // data.createGraphicObj();

          // // const obj = data.graphicObj;
          // // obj.raycast = () => { };
          // // this.getTempScene().add(obj)

          // modelManager.addData(data)
        }
      }
    }
  }

  private testcsg() {
    const obj = this.graphicProcessor.getSelectedObjs();
    const data = obj[0];
    const data1 = obj[1];
    if (data && data1) {
      data.graphicObj.updateMatrix();
      data1.graphicObj.updateMatrix();

      const helper = new VertexNormalsHelper(data.graphicObj, 0.2, 0x00ff00);
      this.saveToTempScene(helper);
      const helper0 = new VertexNormalsHelper(data1.graphicObj, 0.2, 0xff0000);
      this.saveToTempScene(helper0);


      const mesh0 = CSG.fromMesh(data.graphicObj as THREE.Mesh)
      const mesh1 = CSG.fromMesh(data1.graphicObj as THREE.Mesh)
      const res = mesh0.subtract(mesh1);
      const meshResult = CSG.toMesh(res, data.graphicObj.matrix, (data.graphicObj as THREE.Mesh).material);

      meshResult.position.add(new THREE.Vector3(50, 50, 0));
      this.saveToTempScene(meshResult)
    }
  }
  private testNormalHelper() {
    const obj = this.graphicProcessor.getSelectedObjs();
    const data = obj[0];
    if (data) {
      data.graphicObj.updateMatrix();
      const helper = new VertexNormalsHelper(data.graphicObj, 0.2, 0x00ff00);
      this.saveToTempScene(helper);
    }
  }
  private testCasetones() {

    // // Dimensiones slab
    // const w = 50;
    // const h = 25;
    // const d = 1.5;

    // const ptos: IPoint[] = [{ x: 0, y: 0, z: 0 }, { x: 0, y: h, z: 0 }, { x: w, y: h, z: 0 }, { x: w, y: 0, z: 0 }, { x: 0, y: 0, z: 0 }];
    // const data = new SlabData({
    //   depth: d,
    //   ptos2D: ptos,
    //   holes: [],
    //   basePoint: { x: 0, y: 0, z: 1.5 },
    //   offset: { x: 0, y: 0, z: 0 },
    //   rotation: { x: 0, y: 0, z: 0 },
    //   scale: { x: 1, y: 1, z: 1 },
    // }, this.getCurrentSceneId());
    // data.createGraphicObj();

    // // Division para generar casetones
    // const casetones = [];
    // const divW = 10;
    // const divH = 5;
    // const sep = 1;

    // const castenonW = (w - (sep * 2 + sep * (divW - 1))) / divW;
    // const castenonH = (h - (sep * 2 + sep * (divH - 1))) / divH;
    // let currX = sep;
    // let currY = sep;
    // for (let i = 0; i < divW; i++) {
    //   const xmin = currX;
    //   const xmax = xmin + castenonW;
    //   for (let j = 0; j < divH; j++) {
    //     const ymin = currY;
    //     const ymax = ymin + castenonH;
    //     const c = [{ x: xmin, y: ymin, z: 0 }, { x: xmin, y: ymax, z: 0 }, { x: xmax, y: ymax, z: 0 }, { x: xmax, y: ymin, z: 0 }, { x: xmin, y: ymin, z: 0 }];
    //     casetones.push(c)
    //     currY = ymax + sep;
    //   }
    //   currY = sep;
    //   currX = xmax + sep;
    // }

    // data.graphicObj.updateMatrix();
    // let meshResult = data.graphicObj

    // for (let i = 0; i < casetones.length; i++) {
    //   const cas = casetones[i];

    //   const m = createExtrusionMesh(cas, 0.5);
    //   m.updateMatrix();
    //   const mesh0 = CSG.fromMesh(meshResult as THREE.Mesh)
    //   const mesh1 = CSG.fromMesh(m as THREE.Mesh)
    //   const res = mesh0.subtract(mesh1);
    //   meshResult = CSG.toMesh(res, meshResult.matrix, meshResult.material);
    //   meshResult.updateMatrix();
    // }
    // this.saveToTempScene(meshResult);
  }

  private testPileCap() {
    const lines = [
      getPileCap(2, 1, 1, 5, 0.1),
      getPileCap(3, 1, 1, 5, 0.1),
      getPileCap(4, 1, 1, 5, 0.1),
      getPileCap(5, 1, 1, 5, 0.1),
      getPileCap(6, 1, 1, 5, 0.1),
      getPileCap(7, 1, 1, 5, 0.1),
      getPileCap(8, 1, 1, 5, 0.1),
      getPileCap(9, 1, 1, 5, 0.1),
      getPileCap(10, 1, 1, 5, 0.1),
      getPileCap(11, 1, 1, 5, 0.1),
      getPileCap(12, 1, 1, 5, 0.1),
      getPileCap(13, 1, 1, 5, 0.1),
      getPileCap(14, 1, 1, 5, 0.1),
      getPileCap(15, 1, 1, 5, 0.1),
      getPileCap(16, 1, 1, 5, 0.1),
      getPileCap(17, 1, 1, 5, 0.1),
      getPileCap(18, 1, 1, 5, 0.1),
      getPileCap(19, 1, 1, 5, 0.1),
      getPileCap(20, 1, 1, 5, 0.1)
    ];

    let AX = 0;
    let AY = 0;
    let c = 0;
    for (let i = 0; i < 5; i++) {
      for (let j = 0; j < 4; j++) {
        if (lines[c]) {
          const line = lines[c++]
          line.position.set(AX, AY, 0);
          this.saveToTempScene(line);
        }
        AX += 20;
      }
      AX = 0;
      AY += 20;
    }
  }
  private testEdgeGEometry() {
    const objs = this.graphicProcessor.getSelectedObjs();
    if (objs.length) {
      const obj = objs[0].graphicObj as THREE.Mesh;

      const thresholdAngle = 15;
      const geom = new THREE.EdgesGeometry(obj.geometry, thresholdAngle);
      const material = new THREE.LineBasicMaterial({ color: 0x000000 });
      const lines = new THREE.LineSegments(geom, material);
      obj.add(lines);
    }
  }
}
