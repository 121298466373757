import { useForm } from "react-hook-form";
import { useUI } from "shared/components/ui/context";
import { GraphicProcessor } from '../../../../../lib/graphic-processor';

interface FormData {
  name: string;
}
export function useAddLayer(graphicProcessor: GraphicProcessor) {
  const { closeModal } = useUI();

  const { handleSubmit, register, errors } = useForm<FormData>({
    shouldFocusError: true,
    mode: "onTouched",
  });

  const onValid = (data: FormData) => {
    const layerManager = graphicProcessor.getLayerManager();
    const newLayer = layerManager.addLayer(data.name);
    layerManager.layerObserver.dispatchAddLayer(newLayer);
    closeModal();
  };

  return {
    handleSubmit: handleSubmit(onValid),
    ref: register,
    errors,
  };
}
