import { MeshProperties } from "lib/models-struc/mesh/mesh";
import { CodeSet, Material, MaterialSet } from "modules/struc/models/ecore/base";
import { CombinationCodes, ConcreteCodes, LoadCodes, SeismicCodes, SteelCodes, WindCodes } from "modules/struc/models/ecore/codes";
import { MeshGenParams } from "modules/struc/models/ecore/mesh";
import { DEFAULT_FLOAT, DEFAULT_INT } from "modules/struc/utils/storeys";
import { baseUriModel } from "./mesh-exporter";
import { Storey } from "lib/models-struc/struc-storey";
import { isEqual } from "lib/math/epsilon";

export function getEcoreBoolean(value: boolean, defaultValue: boolean = false): boolean | undefined {
  if (value !== defaultValue) {
    return value;
  }
}
export function getEcoreInt(int: number, defaultValue: number = DEFAULT_INT): number | undefined {
  if (!isEqual(int, defaultValue)) {
    return int;
  }
}
export function getEcoreFloat(float: number, defaultValue: number = DEFAULT_FLOAT): number | undefined {
  if (!isEqual(float, defaultValue)) {
    return float;
  }
}

// ------------------------------------------

export function getEcoreMaterialSet(storeys: Storey[]): MaterialSet {
  const materials: Material[] = []
  const matList = new Set();
  for (const storey of storeys) {
    const { floorConcreteType, columnConcreteType } = storey;
    if (!matList.has(floorConcreteType)) {
      matList.add(floorConcreteType);
      const mat: Material = getEcoreMaterial(floorConcreteType);
      materials.push(mat);
    }
    if (!matList.has(columnConcreteType)) {
      matList.add(columnConcreteType);
      const mat: Material = getEcoreMaterial(columnConcreteType);
      materials.push(mat);
    }
  }
  return {
    eClass: baseUriModel + "base/MaterialSet",
    materials: materials,
  };
}
function getEcoreMaterial(material: string) {
  const mat: Material = {
    eClass: baseUriModel + "resources/materials/Material",
    name: material,
    standard: "",
  }
  const density = getEcoreFloat(0);
  const elasticitiModulus = getEcoreFloat(0);
  const poissonCoefficient = getEcoreFloat(0);
  const strength = getEcoreFloat(0);
  if (density !== undefined) mat.density = density;
  if (elasticitiModulus !== undefined) mat.elasticitiModulus = elasticitiModulus;
  if (poissonCoefficient !== undefined) mat.poissonCoefficient = poissonCoefficient;
  if (strength !== undefined) mat.strength = strength;
  return mat;
}

// ------------------------------------------

export function getEcoreCodeSet(): CodeSet {
  const concrete = getConcreteCode(ConcreteCodes.CBH_87);
  const steel = getSteelCode(0);
  const wind = getWindCode(WindCodes.NB_1225003);
  const seismic = getSeismicCode(SeismicCodes.NCSE_94);
  const load = getLoadCode(LoadCodes.NB_1225001);
  const combinationCodes = getCombinationCode(0);
  const codeSet: CodeSet = { eClass: baseUriModel + "base/CodeSet" };
  if (concrete !== undefined) codeSet.concrete = concrete;
  if (steel !== undefined) codeSet.steel = steel;
  if (wind !== undefined) codeSet.wind = wind;
  if (seismic !== undefined) codeSet.seismic = seismic;
  if (load !== undefined) codeSet.load = load;
  if (combinationCodes !== undefined) codeSet.combinationCodes = combinationCodes;
  return codeSet;
}
function getConcreteCode(concreteCode: ConcreteCodes): ConcreteCodes | undefined {
  if (concreteCode !== ConcreteCodes.CBH_87) {
    return concreteCode;
  }
}
function getSteelCode(steelCode: SteelCodes): SteelCodes | undefined {
  if (steelCode !== 0) {
    return steelCode;
  }
}
function getWindCode(windCode: WindCodes): WindCodes | undefined {
  if (windCode !== WindCodes.NB_1225003) {
    return windCode;
  }
}
function getSeismicCode(seismicCode: SeismicCodes): SeismicCodes | undefined {
  if (seismicCode !== SeismicCodes.NCSE_94) {
    return seismicCode;
  }
}
function getLoadCode(loadCode: LoadCodes): LoadCodes | undefined {
  if (loadCode !== LoadCodes.NB_1225001) {
    return loadCode;
  }
}
function getCombinationCode(combinationCode: CombinationCodes): CombinationCodes | undefined {
  if (combinationCode !== 0) {
    return combinationCode;
  }
}

// ------------------------------------------

export function getEcoreMeshParams(mesh: MeshProperties): MeshGenParams {
  const shellSize = getEcoreFloat(mesh.params.shellSize!, 1);
  const beamSize = getEcoreFloat(mesh.params.beamSize!, 1);
  const nodalBcThres = getEcoreFloat(mesh.params.nodalBcThres!, 0.001);
  const mpcThres = getEcoreFloat(mesh.params.mpcThres!, 0.5);
  const mergeTolerance = getEcoreFloat(mesh.params.mergeTolerance!, 0.01);
  const meshParam: MeshGenParams = { eClass: baseUriModel + "mesh/MeshGenParams" };
  if (shellSize !== undefined) meshParam.shell_size = shellSize;
  if (beamSize !== undefined) meshParam.beam_size = beamSize;
  if (nodalBcThres !== undefined) meshParam.nodal_bc_thres = nodalBcThres;
  if (mpcThres !== undefined) meshParam.mpc_thres = mpcThres;
  if (mergeTolerance !== undefined) meshParam.merge_tolerance = mergeTolerance;
  return meshParam;
}
