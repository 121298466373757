/**
 * \file distance.ts
 * Implementacion de la operacion que mide la distancia 3D entre los 2 puntos dados.
 */

import { dashedLineCreateIPoints, lineMoveVertex } from "lib/geometries/line";
import { pointCreate } from "../../geometries/point";
import { IPoint } from "lib/math/types";
import { Cad3dOp } from "../base";
import { cadOpType } from "../factory";
import { vectorDist3D } from "lib/math/distance";
import { createText, modifyText } from "lib/text/builder";
import { sdfDoubleSidedType, textMultiPosTypeH, textMultiPosTypeV, TextOptsBuilder } from "lib/text/styles";
import { currentTextStyleId, textStyleCache } from "lib/text/cache";
import { textParam } from "lib/models/text";
import * as THREE from "three";

export class DistanceMeasureOP extends Cad3dOp {

  public opType = cadOpType.DISTANCEMEASURE;

  /**
   * Punto de origen de la medida.
   *
   * @private
   * @type {IPoint}
   * @memberof DistanceMeasureOP
   */
  private pointA: IPoint = { x: 0, y: 0, z: 0 };

  /**
   * Punto de fin de la medida, posiblemente incluso en otro plano, ya que la medida es 3D.
   *
   * @private
   * @type {IPoint}
   * @memberof DistanceMeasureOP
   */
  private pointB: IPoint = { x: 0, y: 0, z: 0 };

  /**
   * Linea de medida, como elemento grafico, que logicamente es la mas corta entre el origen A y el destino B. Ira de marron punteado.
   *
   * @private
   * @type {THREE.Line}
   * @memberof DistanceMeasureOP
   */
  private gLine: THREE.Line;

  /**
   * La representacion grafica temporal de los puntos de inicio (el A), y el final hasta el momento.
   * 2 puntos blancos para ayudar en la representacion.
  */
  private gPointA: THREE.Points;
  private gPointB: THREE.Points;

  /**
   * La etiqueta con la distancia computada.
   *
   * @private
   * @type {THREE.Mesh}
   * @memberof DistanceMeasureOP
   */
  private gDistanceLabel: THREE.Mesh;

  /**
   * El estilo de texto aplicado a la etiqueta con la distancia.
   *
   * @private
   * @type {TextOptsBuilder}
   * @memberof DistanceMeasureOP
   */
  private styleText: TextOptsBuilder;

  /** Las relaciones entre lo lleno y lo hueco de la linea de distancia, verificando una relacion 3:1. */
  private dashSize: number;
  private gapSize: number;

  /**
   * Con esto marcamos que inicialmente no tenemos ajustado el punto de origen A.
   *
   * @private
   * @type {boolean}
   * @memberof DistanceMeasureOP
   */
  private hasSourcePointA: boolean;

  protected iniSettingsOp(): void {
    const brown = 0xffaa00;
    // Creamos la linea con una escala del 3:1 lleno-hueco.
    this.dashSize = 3;
    this.gapSize = 1;
    this.gLine = dashedLineCreateIPoints([this.pointA, this.pointB], undefined, brown, this.dashSize, this.gapSize, 1);
    this.gPointA = pointCreate(this.pointA.x, this.pointA.y, this.pointA.y);
    this.gPointB = pointCreate(this.pointB.x, this.pointB.y, this.pointB.y);
    // Al principio lo hacemos todo invisible.
    this.gLine.visible = this.gPointA.visible = this.gPointB.visible = false;
    // La inicializacion del texto al ser asyncrona nos la llevamos al start().
  }

  public async start() {
    this.iniSettingsOp();
    this.initializeSnap();
    this.initializeWorkingPlane();

    this.pointA.x = this.lastPoint.x;
    this.pointA.y = this.lastPoint.y;
    this.pointA.z = this.lastPoint.z;

    this.saveToTempScene(this.gLine);

    this.registerCancel();
    this.registerInputs();
    this.registerUpdaters();

    // Aun no tenemos punto de inicio.
    this.hasSourcePointA = false;

    // Y finalmente el coñazo del puto texto.
    const txtParams: textParam = {
      styleId: currentTextStyleId,
      text: "",
      position: { x: 0, y: 0, z: 0 },
      angleO: 0,
      plane: { x: 0, y: 0, z: 0 },
      scale: 100,
    };
    this.styleText = (await textStyleCache.loadStyle(txtParams.styleId))!;
    this.styleText.basePointV = textMultiPosTypeV.MEDIAN;
    this.styleText.basePointH = textMultiPosTypeH.MIDDLE;
    this.styleText.size = 1;
    this.styleText.color = { r: 255, g: 255, b: 0, a: 0 }; // "#ffff00";
    this.styleText.doubleSided = sdfDoubleSidedType.FRONT;

    this.gDistanceLabel = createText(txtParams, this.styleText);
    // No la activo hasta que no haga falta.
    this.gDistanceLabel.visible = true;
    this.saveToTempScene(this.gDistanceLabel);

    // La primera vez solicitamos que se escoja punto de partida y no pintamos nada.
    console.log("Haga click sobre el punto de origen A...");
  }

  // Llamada una vez que se hace click.
  public setLastPoint(): void {

    const x = this.lastPoint.x;
    const y = this.lastPoint.y;
    const z = this.lastPoint.z;

    if (!this.hasSourcePointA) {
      // La primera vez fijamos ambos puntos.
      this.pointA.x = this.pointB.x = x;
      this.pointA.y = this.pointB.y = y;
      this.pointA.z = this.pointB.z = z;
      this.hasSourcePointA = true;
      lineMoveVertex(this.gLine, x, y, z, 0);
      lineMoveVertex(this.gLine, x, y, z, 1);

      this.gPointA.position.set(x, y, z);
      this.saveToTempScene(this.gPointA);
      this.gPointB.position.set(x, y, z);
      this.saveToTempScene(this.gPointB);

      this.gLine.visible = this.gPointA.visible = this.gPointB.visible = true;

      console.log("Seleccionado el punto de origen, haga click sobre el punto de destino B...");
    } else {
      // La segunda vez fijamos el ultimo punto, el de destino.
      this.pointB.x = x;
      this.pointB.y = y;
      this.pointB.z = z;
      lineMoveVertex(this.gLine, x, y, z, 1);
      this.gPointB.position.set(x, y, z);
      console.log("Seleccionado el punto de destino.");

      this.endOperation();
    }
  }

  // Llamada siempre que se mueve el raton.
  public moveLastPoint(pto: IPoint) {
    if (this.hasSourcePointA) {
      // Para mejorar la visualizacion a la escala que sea a nuestra linea le procesamos el material dashed anteriormente
      // asignado, modificando sus parametros para aplicarle el pixelPerfect.
      // Cte de los pixels que queremos.
      const kGapPixels = 50;
      // El valor del pixel perfect en metros para esa cantidad de pixels.
      const meters = this.graphicProcessor.getSizeUnitFromPixelUnit(kGapPixels, pto);
      const oldMaterial = this.gLine.material as THREE.LineDashedMaterial;
      // Ojo, que aplicamos los cambios directamente pero usando la misma proporcion que cuando se creo la linea.
      oldMaterial.dashSize = this.dashSize * meters;
      oldMaterial.gapSize = this.gapSize * meters;
      oldMaterial.scale = 1;

      // Pero cuando tenemos punto de partida pintamos y calculamos distancias hasta que se escoja el destino.
      lineMoveVertex(this.gLine, pto.x, pto.y, pto.z, 1);
      this.gLine.computeLineDistances();
      this.gPointB.position.set(pto.x, pto.y, pto.z);

      this.printDistanceLabel(pto);
    }
  }

  public cancelOperation(): void {
    if (!this.finished) {
      this.endOperation();
    }
  }

  public endOperation(): void {
    if (!this.finished) {
      if (this.hasSourcePointA) {
        const dist = vectorDist3D(this.pointA, this.pointB);
        console.log("Distancia temporal: " + dist);
      }
      this.save();
      super.endOperation();
    }
  }

  public save() {
    // if (this.graphicProcessor && this.pointsDef.length > 0) {
    //   const command = new PointCommand(
    //     this.pointsDef,
    //     this.getSceneId(),
    //     this.graphicProcessor
    //   );
    //   if (command) this.graphicProcessor.storeAndExecute(command);
    // }
  }

  private printDistanceLabel(pDestination: IPoint): number {
    const dist = vectorDist3D(this.pointA, pDestination)
    const distTxt = dist.toFixed(3);
    // Ya no hace falta calcular rotacion de la linea.
    // const alpha = getRotationLine(this.pointA, pDestination);

    const x = 0.5 * (this.pointA.x + pDestination.x);
    const y = 0.5 * (this.pointA.y + pDestination.y);
    const z = 0.5 * (this.pointA.z + pDestination.z);

    const kGapPixels = 50;
    // El valor del pixel perfect en metros para esa cantidad de pixels.
    const meters = this.graphicProcessor.getSizeUnitFromPixelUnit(kGapPixels, { x, y, z });

    modifyText(this.gDistanceLabel, distTxt, this.styleText);

    // Aplica todo al mesh.
    this.gDistanceLabel.position.set(x, y, z);
    this.gDistanceLabel.scale.x = 0.5 * meters;
    this.gDistanceLabel.scale.y = 0.5 * meters;
    this.gDistanceLabel.scale.z = 0.5 * meters;
    if (false) {
      // Con esto puede salir el texto incluso boca abajo.
      // this.gDistanceLabel.setRotationFromEuler(alpha);
      this.gDistanceLabel.rotateZ(0.5 * Math.PI);
    } else {
      // Con esto siempre apuntaria hacia la camara. El problema es que cabecea...
      // https://stackoverflow.com/questions/12919638/textgeometry-to-always-face-user
      // this.gDistanceLabel.lookAt(graphicProcessor000.getCursorCamera().position);
      // Esta es la solucion ultracorrecta para que su horizontalidad sea magnifica.
      this.gDistanceLabel.quaternion.copy(this.graphicProcessor.getCursorCamera().quaternion);
    }
    return dist;
  }
}
