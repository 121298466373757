import * as THREE from "three";
import { IPoint } from "lib/math/types";
import { createExtrusionMesh, updateExtrusionMesh } from "./extrusion";
import { columnParam } from "lib/models-struc/types/column";
import { calculateCentroidPoints } from "lib/math/centroid";
import { addIpoint } from "lib/math/point";
import { generatePointsBeamCrossSection } from "lib/models-struc/cross-sections-shape/beam-cross-sections/utils";
import { beamCrossSectionCache } from "lib/models-struc/cross-sections-shape/beam-cross-sections/cache";
import { sectionType } from "lib/models-struc/cross-sections-shape/beam-cross-sections/types";

export function createColumn(crossSectionId: string, height: number, material?: THREE.MeshPhongMaterial) {
  const section = beamCrossSectionCache.loadStylefromCache(crossSectionId)!;
  const { contour, holes } = generatePointsBeamCrossSection(section);
  const mesh = createExtrusionMesh(contour, height, [holes], material);
  mesh.geometry.translate(0, 0, -height);
  return mesh;
}

export function updateColumn(mesh: THREE.Mesh, crossSectionId: string, height: number) {
  const section = beamCrossSectionCache.loadStylefromCache(crossSectionId)!;
  const { contour, holes } = generatePointsBeamCrossSection(section);
  updateExtrusionMesh(mesh, contour, height, [holes]);
  mesh.geometry.translate(0, 0, -height);
}

export function getFooterOrPileCapBasePointFromColumn(param: columnParam): IPoint {
  const { x, y, z } = param.basePoint;
  let basePoint = { x, y, z: z - param.height }
  const section = beamCrossSectionCache.loadStylefromCache(param.crossSectionId)!;
  if (section.parameter.beamSectionType === sectionType.CUSTOM) {
    if (section.parameter.polyline.points.length) {
      const center = calculateCentroidPoints(section.parameter.polyline.points);
      if (center) {
        basePoint = addIpoint(basePoint, center);
      }
    }
  }
  return basePoint;
}
