import { RegionEditDataCommand } from "lib/commands/edition/region";

import { IPoint } from "lib/math/types";
import { RegionData } from "lib/models/solids/region";
import { regionParam } from "lib/geometries/solid/region";
import { SolidDataDefinitionHandler } from "../solid";
import { ISolidMaterial } from '../../materials/solid';

export type regionProp = regionParam & { centroid: IPoint; }
export class RegionDataDefinitionHandler extends SolidDataDefinitionHandler<regionProp> {

  protected data: RegionData;

  protected buildInfoProperties() {
    this.definitionInfo = {};
    this.fillSolidDefinition(this.data);
    // const def = this.data.definition;
    // const vertex = def.ptos2D.map((p) => ({ x: p.x, y: p.y, z: p.z }));
    // this.definitionInfo = { 
    //   x: { publicName: "X", units: "m", precision: linealPrecision },
    //   y: { publicName: "Y", units: "m", precision: linealPrecision },
    //   z: { publicName: "Z", units: "m", precision: linealPrecision },
    //   values: vertex,
    // };
    this.materialInfo = this.getSolidMaterialView(this.data.material);
  }

  private checkNewDefinition(newDefinition: regionProp): boolean {
    if (!newDefinition) { return false; }
    if (!this.checkSolidNewDefinition(newDefinition)) { return false; }
    return true;
  }

  private changedNewDefinition(oldDefinition: regionParam, newDefinition: regionProp): regionParam | null {
    let def = oldDefinition;
    let changed: boolean = false;
    if (newDefinition) {
      const solid = this.changedSolidNewDefinition(oldDefinition, newDefinition)
      if (solid !== null) {
        def.basePoint = solid.basePoint;
        def.rotation = solid.rotation;
        def.scale = solid.scale;
        def.offset = solid.offset;
        changed = true;
      }
    }
    return (changed ? def : null);
  }

  private checkAndChangedDefinition(oldDefinition: regionParam, newDefinition: regionProp): regionParam | null {
    let def = null
    if (this.checkNewDefinition(newDefinition)) {
      def = this.changedNewDefinition(oldDefinition, newDefinition);
    }
    return def;
  }

  public saveAndRegenerate = (newDefinition: regionProp) => {
    const regionDefinition = this.checkAndChangedDefinition(this.data.cloneDefinition(), newDefinition);
    if (regionDefinition) {
      const command = new RegionEditDataCommand(this.data, regionDefinition, null, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  };
  public saveAndRegenerateMaterial = (newMaterial: ISolidMaterial) => {
    const regionMaterial = this.checkAndChangedSolidMaterial(this.data.material, newMaterial);
    if (regionMaterial) {
      const command = new RegionEditDataCommand(this.data, null, regionMaterial, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  };
}