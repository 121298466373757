import * as THREE from "three";
import { cadOpType } from "lib/operations/factory";
import { LoadStructuralData } from "lib/models/structural/load";
import { SelectionManager } from "../../selection-manager";
import { GraphicProcessor } from "lib/graphic-processor";
import { IloadSelectionHandler } from "./load";

export class LoadConcentratedDataSelector implements IloadSelectionHandler {

  public buildSelectMarks(data: LoadStructuralData) {
    return { vertex: [data.definition.basePoint] };
  }

  public launchMarkOP(data: LoadStructuralData, graphicProc: GraphicProcessor, selectionManager: SelectionManager, mark: THREE.Object3D, index?: number) {
    return {
      type: cadOpType.MOVE,
      graphicProcessor: graphicProc,
      args: [[data], data.definition.basePoint],
    };
  }
}
