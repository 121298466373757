import { useCallback, useState } from "react";
import { useAuth } from "shared/components/auth/context";
import { useUI } from "shared/components/ui/context";
import { Project } from "lib/apis/struc-project/api";
import { useMutation, useQueryClient } from "react-query";
import { fetchCloneProject } from "lib/models-struc/infrastructure/clone-project";
import { userMessageEvents } from "lib/events/user-messages";

type Status = 'idle' | 'loading' | 'success' | 'error'

export function useCloneProjectData(project: Project) {

  const queryClient = useQueryClient();
  const { user } = useAuth();
  const { closeModal, modalData } = useUI();
  const [status, setStatus] = useState<Status>('idle');

  const getProjectName = (name: string, projectsNameList: string[]) => {
    let c = 0;
    while (projectsNameList.includes(name)) {
      name = `${name}_copy_${c}`;
      c++
    }
    return name;
  }

  const mutation = useMutation((variables: { userId: string, project: Project, projectsNameList: string[] }) => {
    const newName = getProjectName(variables.project.Name, variables.projectsNameList);
    return fetchCloneProject(newName, variables.project, variables.userId);
  }, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['GET_STRUC_PROJECTS']);
      //const query = queryClient.getQueriesData<projectListResponse[]>(['GET_STRUC_PROJECTS']);
      //queryClient.setQueryData(["GET_STRUC_PROJECTS"], [data, ...query[0][1]]);
    }
  })

  const cloneStrucProjectData = useCallback(async () => {
    const { projectsNameList } = modalData as { id: string, version: string, projectsNameList: string[] }
    const userId = user!.id;

    try {
      setStatus('loading');
      await mutation.mutateAsync({ project, projectsNameList, userId });
      setStatus('success');
      userMessageEvents.dispatchSucces("Project cloned successfully.");

    } catch (err) {
      setStatus("error");
      userMessageEvents.dispatchError("Error creating project.");

    } finally {
      closeModal();
    };

  }, [closeModal, modalData, mutation, project, user]);

  return {
    cloneStrucProjectData,
    status,
  };
}


