import { vectorDist3D } from "./distance";
import { intersect2D } from "./intersections";
import { IPointsToISegments } from "./point";
import { IPoint, ISegment } from "./types";

export interface IExtendInfo {
    first: IPoint | number;
    last: IPoint | number;
}

interface IIntersectOpts {
    strict: boolean;
    l1Scrict?: boolean;
    l1PositiveDir?: boolean;
    l2Scrict?: boolean;
    l2PositiveDir?: boolean;
}
function checkIntersection(intersection: { p: IPoint | null, ua: number, ub: number } | null, opts: IIntersectOpts) {
    if (intersection === null) return null;
    const { ua, ub } = intersection;
    if (opts.strict && (ua < 0 || ua > 1 || ub < 0 || ub > 1)) return undefined;
    if (opts.l1Scrict && (ua < 0 || ua > 1)) return undefined;
    if (opts.l2Scrict && (ub < 0 || ub > 1)) return undefined;
    if (opts.l1PositiveDir && ua < 0) return undefined;
    if (opts.l2PositiveDir && ub < 0) return undefined;
    return intersection.p;
}
function extendGetInterFirstLast(p1: IPoint, p2: IPoint, lines: ISegment[]): IPoint | undefined {
    let intersection = intersect2D(p1, p2, lines[0].p2, lines[0].p1,);
    const intersection1 = checkIntersection(intersection, { strict: false, l1PositiveDir: true, l2PositiveDir: true });
    
    intersection = intersect2D(p1, p2, lines[lines.length - 1].p1, lines[lines.length - 1].p2);
    const intersection2 = checkIntersection(intersection, { strict: false, l1PositiveDir: true, l2PositiveDir: true });

    if (intersection1 || intersection2) {
        let dist1: number = Infinity;
        let dist2: number = Infinity;
        if (intersection1) {
            dist1 = vectorDist3D(intersection1, p2);
        }
        if (intersection2) {
            dist2 = vectorDist3D(intersection2, p2);
        }
        if (dist1 < dist2) {
            return intersection1 as IPoint;
        } else {
            return intersection2 as IPoint;
        }
    }
    return undefined;
}

function getExtendObjInfo(objToExtend: IPoint[], objToIntersect: IPoint[]): IExtendInfo {
    const lastSegment: ISegment = {
        p1: objToExtend[objToExtend.length - 2],
        p2: objToExtend[objToExtend.length - 1]
    };
    const firstSegment: ISegment = {
        p1: objToExtend[0],
        p2: objToExtend[1]
    };

    let closestDistanceLast: number = Infinity;
    let closestDistanceFirst: number = Infinity;

    let closestInterLast: IPoint | undefined;
    let closestInterFirst: IPoint | undefined;

    const objToIntersectSegments: ISegment[] = IPointsToISegments(objToIntersect);

    objToIntersectSegments.forEach((lineInter: ISegment, index: number) => {
        const intersection = intersect2D(lastSegment.p1, lastSegment.p2, lineInter.p1, lineInter.p2);
        const intersectionPto = checkIntersection(intersection, { strict: false, l1PositiveDir: true, l2Scrict: true });
        let currentDistance: number = Infinity;
        if (intersectionPto) {
            currentDistance = vectorDist3D(intersectionPto, lastSegment.p2);
        }
        if (currentDistance < closestDistanceLast) {
            closestDistanceLast = currentDistance;
            closestInterLast = intersectionPto as IPoint;
        }
        const lIntersection = intersect2D(firstSegment.p2, firstSegment.p1, lineInter.p1, lineInter.p2);
        const lintersectionPto = checkIntersection(lIntersection, { strict: false, l1PositiveDir: true, l2Scrict: true });

        let currentFDistance: number = Infinity;
        if (lintersectionPto) {
            currentFDistance = vectorDist3D(lintersectionPto, firstSegment.p1);
        }
        if (currentFDistance < closestDistanceFirst) {
            closestDistanceFirst = currentFDistance;
            closestInterFirst = lintersectionPto as IPoint;
        }
    });

    let last: IPoint | number | undefined = undefined;
    let first: IPoint | number | undefined = undefined;

    if (closestInterLast) {
        last = closestInterLast;
    } else { // casos especiales donde permitimos interseccion fuera de las lineas.
        const intersection = extendGetInterFirstLast(lastSegment.p1, lastSegment.p2, objToIntersectSegments);
        if (intersection) {
            last = intersection;
        }
    }
    if (closestInterFirst) {
        first = closestInterFirst;
    } else {
        const intersection = extendGetInterFirstLast(firstSegment.p2, firstSegment.p1, objToIntersectSegments);
        if (intersection) {
            first = intersection;
        }
    }

    const extendResult: IExtendInfo = {
        first: first as IPoint | number,
        last: last as IPoint | number,
    }
    return extendResult;
}

/** Extiende una serie de geometrías vs una polilínea y devuelve los resultados. NO MODIFICA las geometrías originales.
 *
 * @export
 * @param {IPoint[][]} objsToExtend Una serie de geometrías a extender.
 * @param {IPoint[]} objToIntersect El obj contra el cual se quiere extender. El obj no tiene que ser necesariamente una polilínea pero se tratará como tal.
 * @returns {IExtendInfo[]} Los resultados. Contiene el nuevo primer y último punto (en caso de tener). Si se trata de geometrías basadas en radio se devuelven los nuevos ángulos.
 */
export function getExtendObjsInfo(objsToExtend: IPoint[][], objToIntersect: IPoint[]): IExtendInfo[] {
    const res = new Array<IExtendInfo>(objsToExtend.length);
    for (let i = 0; i < objsToExtend.length; i++) {
        res[i] = getExtendObjInfo(objsToExtend[i], objToIntersect);
    }
    return res;
}