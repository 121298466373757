import { getAuxMaterialPoint } from "lib/materials";
import * as THREE from "three";
import { adjustDrawRange, updateObjBboxBSphere } from ".";
import { isIPoint } from "../math/point";
import { IPoint } from "../math/types";

export function pointCreate(x: number | IPoint, y: number, z: number, size?: number, material?: THREE.PointsMaterial): THREE.Points {
  if (size === undefined) size = 3;
  const dotGeometry: THREE.BufferGeometry = new THREE.BufferGeometry();
  const buffer: Float32Array = new Float32Array(3);
  if (arguments.length > 0) {
    if (isIPoint(x)) {
      buffer[0] = x.x;
      buffer[1] = x.y;
      buffer[2] = x.z;
    } else {
      buffer[0] = x;
      buffer[1] = y;
      buffer[2] = z ? z : 0;
    }
  }
  dotGeometry.setAttribute("position", new THREE.Float32BufferAttribute(buffer, 3));
  const mat = material ?? getAuxMaterialPoint();
  const obj: THREE.Points = new THREE.Points(dotGeometry, mat);

  updateObjBboxBSphere(obj);
  adjustDrawRange(obj);
  const { boundingSphere } = obj.geometry;
  if (boundingSphere) {
    boundingSphere.radius = size;
  }
  return obj;
}
