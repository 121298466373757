import { IObjData } from "lib/models/objdata";
import { LayerData } from "./layer-data";
import { CompositeNode, ICompositeComponent } from "lib/helpers/composite-tree";
import { LayerDispatcher } from "./dispatcher";
import { LayerModelPersistence } from "lib/input-output/database/loader";

export class LayerManager {

  static rootLayerId = "root-000";

  public layerComposite: CompositeNode<LayerData>;
  
  private currentLayerNode: CompositeNode<LayerData>;
  get currentLayer() { return this.currentLayerNode.props };

  public layerObserver: LayerDispatcher;

  constructor(rootGroup?: THREE.Group) {
    const currentLayer = new LayerData("root");
    if (rootGroup) currentLayer.threeObject = rootGroup;

    this.layerComposite = new CompositeNode<LayerData>(currentLayer.name, currentLayer);
    currentLayer.id = this.layerComposite.id = LayerManager.rootLayerId;

    this.currentLayerNode = this.layerComposite;
    this.layerObserver = new LayerDispatcher(this);
  }
  public clear() {
    this.layerComposite.children.length = 0;
  }

  public setCurrentLayer(layer: LayerData) {
    const hasChanged = this.currentLayerNode.props !== layer;
    if (hasChanged) {
      this.currentLayerNode = this.getNodeLayerById(layer.id)!;
      this.layerObserver.dispatchSetCurrentLayer(this.currentLayerNode.props);
    }
  }
  public setCurrentLayerById(id: string) {
    const hasChanged = this.currentLayerNode.id !== id;
    if (hasChanged) {
      this.currentLayerNode = this.getNodeLayerById(id)!;
      this.layerObserver.dispatchSetCurrentLayer(this.currentLayerNode.props);
    }
  }

  public addLayer(name: string, parentLayerId?: string) {
    let parentLayerNode = this.currentLayerNode;
    if (parentLayerId !== undefined) {
      parentLayerNode = this.getNodeLayerById(parentLayerId)!;
    }
    const newLayer = new LayerData(name);
    const newNode = parentLayerNode.addChild(name, newLayer);
    newLayer.id = newNode.id;
    const parentGroup = parentLayerNode.props.threeObject;
    const newSonGroup = newLayer.threeObject;
    parentGroup.add(newSonGroup);
    return newLayer;
  }
  public deleteLayerById(id: string): LayerData[] | undefined {
    const layerNodeToDelete = this.getNodeLayerById(id)!;
    const layer = layerNodeToDelete.props;
    if (!this.isRootLayer(layer) && !layer.locked) {

      const parentLayerNode = layerNodeToDelete.parent as CompositeNode<LayerData>;
      if (this.currentLayerNode === layerNodeToDelete) {
        this.currentLayerNode = parentLayerNode;
      }

      const layerDeleted: Set<LayerData> = new Set();
      layerNodeToDelete.traverse((node) => {
        layerDeleted.add(node.props as LayerData);
        return true;
      });

      parentLayerNode.removeChild(layerNodeToDelete);

      const parentLayer = parentLayerNode.props;
      parentLayer.threeObject.remove(layer.threeObject);

      return [layer, ...layerDeleted];
    }
  }

  public moveLayer2Layer(sourceLayerId: string, targetLayerId: string) {
    const sourceNodeLayer = this.getNodeLayerById(sourceLayerId)!;
    const targetNodeLayer = this.getNodeLayerById(targetLayerId)!;
    const sourceLayer = sourceNodeLayer.props;
    const targetLayer = targetNodeLayer.props;
    if (targetLayer && sourceLayer) {
      if (targetLayer.locked && sourceLayer.locked) return;

      // parent node is the same of target node
      if (sourceNodeLayer.parent === targetNodeLayer) return;

      // targetNode cant be children of sourceNode 
      const parents = targetNodeLayer.getNodePath();
      if (parents.includes(sourceNodeLayer)) return;

      const parentLayerNode = sourceNodeLayer.parent as CompositeNode<LayerData>;
      parentLayerNode.removeChild(sourceNodeLayer);
      targetNodeLayer.addChildNode(sourceNodeLayer);

      const srcObjA = sourceLayer.threeObject;
      const dstObjB = targetLayer.threeObject;
      const parentA = srcObjA.parent;
      if (parentA) {
        parentA.remove(srcObjA);
        dstObjB.add(srcObjA);
      }

      this.layerObserver.dispatchDragLayers(sourceLayer, targetLayer);
    }
  }

  public moveObjData2Layer(objData: IObjData, layerId: string) {
    const sourceLayer = this.getLayerDataFromId(objData.layerId);
    const targetLayer = this.getLayerDataFromId(layerId);
    if (targetLayer && sourceLayer && targetLayer !== sourceLayer) {
      if (!targetLayer.locked && !sourceLayer.locked && !objData.isLocked) {
        sourceLayer.removeObjData(objData);
        targetLayer.addObjData(objData);
        this.layerObserver.dispatchDragObject2Layer(objData, targetLayer);
      }
    }
  }
  public addObjDataToLayer(objData: IObjData, layerId: string) {
    // Add Object to Layer
    const layerData = this.getLayerDataFromId(layerId)!;
    layerData.addObjData(objData);
    // Resolve visibility/lock inheritance
    const lyrs = this.getParentLayersDataFromId(objData.layerId);
    if (lyrs) {
      const isNotVisible = lyrs.some(lyr => !lyr.visible);
      const isLayerLocked = lyrs.some(lyr => lyr.locked);
      objData.isLayerLocked = isLayerLocked;
      objData.isLayerVisible = !isNotVisible;
    }
  }
  public removeObjDataFromLayer(objData: IObjData) {
    // Remove Object to Layer
    const layerData = objData.layerObj;
    layerData.removeObjData(objData);
  }

  // ***************************** VISIBILITY ******************************* */

  public isLayerVisibleById(id: string) {
    const layer = this.getLayerDataFromId(id);
    if (layer) return layer.visible
  }

  // ******************************** LOCK ********************************** */

  public isLayerLockedById(id: string) {
    const layer = this.getLayerDataFromId(id);
    if (layer) return layer.locked;
  }

  // ************************************************************************ */

  public loadLayerModel(layerData: LayerModelPersistence[]) {
    let rootId: string | number = -1;
    for (const layer of layerData) {
      if (layer.id === LayerManager.rootLayerId) continue
      if ((layer.parentId as any) === -1) {
        rootId = layer.id;
        continue
      }
      const layerObj = new LayerData(layer.name);
      layerObj.id = layer.id.toString();
      layerObj.visible = layer.visible;
      layerObj.locked = layer.locked;
      layerObj.setBulkData(layer.hasBulkData);

      const parentId = layer.parentId === rootId ? this.layerComposite.id : layer.parentId.toString();
      const parentLayerNode = this.getNodeLayerById(parentId)!;
      const layerNode = parentLayerNode.addChild(layerObj.name, layerObj);
      layerNode.id = layerObj.id;

      const parentGroup = parentLayerNode.props.threeObject;
      const newSonGroup = layerObj.threeObject;
      newSonGroup.visible = layerObj.visible;
      parentGroup.add(newSonGroup);
    }
  }
  public exportLayerModel(): LayerModelPersistence[] {
    const layers: LayerModelPersistence[] = [];
    this.layerComposite.traverse((layerNode) => {
      const layerData = layerNode.props as LayerData;
      layers.push({
        id: layerData.id,
        name: layerData.name,
        visible: layerData.visible,
        locked: layerData.locked,
        hasBulkData: layerData.isBulkData,
        parentId: layerNode.parent?.id,
      });
      return true;
    });
    return layers;
  }

  // ************************************************************************ * /

  public getRootLayer(): LayerData {
    return this.layerComposite.props;
  }
  private isRootLayer(layer: LayerData): boolean {
    return this.getRootLayer() === layer;
  }
  private getNodeLayerById(layerId: string): CompositeNode<LayerData> | undefined {
    const layerNode = this.layerComposite.getNodeById<LayerData>(layerId);
    if (layerNode) {
      return layerNode as CompositeNode<LayerData>;
    }
    console.warn("[LAYER_MANAGER] getLayerDataFromId(" + layerId + ") layer NOT found");
  }
  public getLayerDataFromId(layerId: string): LayerData | undefined {
    const layerNode = this.layerComposite.getNodeById<LayerData>(layerId);
    if (layerNode && layerNode.children !== undefined) {
      return layerNode.props;
    }
    console.warn("[LAYER_MANAGER] getLayerDataFromId(" + layerId + ") layer NOT found");
  }

  /** Include the node with layerId parameter
   *
   * @param {number} layerId
   * @return {*}  {(LayerData[] | undefined)}
   * @memberof LayerManager
   */
  public getParentLayersDataFromId(layerId: string): LayerData[] | undefined {
    const layerNode = this.getNodeLayerById(layerId);
    if (layerNode) {
      const nodes = layerNode.getNodePath();
      return nodes.map(n => n.props as LayerData);
    }
  }
  public getLayerDataFromName(layerName: string): LayerData | undefined {
    let layer: LayerData;
    this.layerComposite.traverse((layerNode) => {
      const layerData = layerNode.props as LayerData;
      if (layerData.name === layerName) {
        layer = layerData;
        return false
      }
      return true;
    });
    return layer!;
  }
  public getAllLayers(layerId?: string): LayerData[] {
    const layers: LayerData[] = [];
    let inilayerNode: ICompositeComponent<LayerData>;
    if (layerId !== undefined) {
      inilayerNode = this.layerComposite.getNodeById<LayerData>(layerId)!;
    } else {
      inilayerNode = this.layerComposite;
    }
    inilayerNode.traverse((layerNode) => {
      const layerData = layerNode;
      if (layerData.children !== undefined) {
        layers.push(layerData.props as LayerData)
      }
      return true;
    });
    return layers;
  }
  public iterAllLayers(cb: (layer: LayerData) => void, startLayerId?: string) {
    let inilayerNode: ICompositeComponent<LayerData>;
    if (startLayerId !== undefined) {
      inilayerNode = this.layerComposite.getNodeById<LayerData>(startLayerId)!;
    } else {
      inilayerNode = this.layerComposite;
    }
    inilayerNode.traverse((layerNode) => {
      const layerData = layerNode.props as LayerData;
      cb(layerData);
      return true
    });
  }
  public getOrCreateChildrenLayerByParentIdAndName(parentLayerId: string, childLayerName: string): LayerData {
    const parentNode = this.getNodeLayerById(parentLayerId)!;
    const childs = parentNode.children.map(child => child.props as LayerData);
    if (childs) {
      for (let child of childs) {
        if (child.name === childLayerName) {
          return child;
        }
      }
    }
    // No child layer with name childLayerName => then create it
    const newLayer = this.addLayer(childLayerName, parentLayerId);
    return newLayer;
  }
}
