import { FC, useCallback } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getTimeAgo } from "shared/utils/dates";
import { CADProject } from "modules/cad/models/projects";
import DotsVerticalIcon from "shared/components/icons/dots-vertical";
import { useUI } from "shared/components/ui/context";
import Menu from "shared/components/ui/menu";

interface Props {
  projects: CADProject[];
}

const Wrapper = styled.div`
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
  gap: 32px;
  user-select: none;
  width: 100%;
  flex-flow: row wrap;
  align-content: stretch;
  cursor: default;
`;

const ProjectLink = styled(Link)`
  margin: 0;
  display: flex;
  border-radius: 8px;
  height: 232px;
  position: relative;
  flex: 1 1 280px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

interface SnapshotProps {
  url: string | null;
}
const Snapshot = styled.div<SnapshotProps>`
  background: ${(props) => (props.url ? `url(${props.url})` : "")};
  flex: 1;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const Title = styled.h3``;

const ProjectList: FC<Props> = ({ projects }) => {
  const { openModal } = useUI();
  const renameFile = useCallback(
    (event: any, id: string, name: string) => {
      event.stopPropagation();
      openModal("RENAME_FILE", { id, name });
    },
    [openModal]
  );
  const deleteFile = useCallback(
    (event: any, id: string) => {
      event.stopPropagation();
      openModal("DELETE_CAD_PROJECT", { id });
    },
    [openModal]
  );
  return (
    <Wrapper>
      {projects.map(({ id, name, updatedAt, snapshot }) => (
        <ProjectLink
          key={id}
          className="border border-gray-700 rounded-md"
          to={`/cad/projects/${id}`}
        >
          <Snapshot className="bg-gray-700" url={snapshot} />
          <div className="flex justify-between items-center text-sm px-6 py-3 transition-all duration-150 space-x-3">
            <div>
              <Title>{name}</Title>
              <div className="flex flex-1 justify-end">
                <span>Updated {getTimeAgo(updatedAt)}</span>
              </div>
            </div>
            <Menu>
              <Menu.Button>
                <DotsVerticalIcon />
              </Menu.Button>
              <Menu.List>
                <Menu.Item
                  onSelect={() => { /*To avoid warning in debug*/ }}
                  onClick={(event: any) => renameFile(event, id, name)}>
                  Rename
                </Menu.Item>
                <Menu.Item
                  onSelect={() => { /*To avoid warning in debug*/ }}
                  onClick={(event: any) => deleteFile(event, id)}>
                  Delete
                </Menu.Item>
              </Menu.List>
            </Menu>
          </div>
        </ProjectLink>
      ))}
    </Wrapper>
  );
};

export default ProjectList;
