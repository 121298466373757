import { angleUnit, centToRad, degToRad, normalizeAngle, radToCent, radToDeg } from "./math/angles";

export let linealPrecision: number = 3;
export let angularPrecision: number = 4;

export let angleSetting: angleUnit = angleUnit.DEG;

export function getUserAngleUnitSufix() {
  switch (angleSetting) {
    case angleUnit.RAD:
      return "rad";
    case angleUnit.GRA:
      return "gra";
    case angleUnit.DEG:
      return "°";
  }
}
export function userNormalizeAngleToRad(angle: number) {
  return normalizeAngle(userAngleToRad(angle));
}
export function userAngleToRad(angle: number) {
  let newAngle = angle;
  switch (angleSetting) {
    case angleUnit.RAD:
      break;
    case angleUnit.GRA:
      newAngle = centToRad(newAngle);
      break;
    case angleUnit.DEG:
      newAngle = degToRad(newAngle);
      break;
  }
  return newAngle;
}
export function radAngleToUser(angle: number) {
  let newAngle = angle;
  switch (angleSetting) {
    case angleUnit.RAD:
      break;
    case angleUnit.GRA:
      newAngle = radToCent(newAngle);
      break;
    case angleUnit.DEG:
      newAngle = radToDeg(newAngle);
      break;
  }
  return newAngle;
}

