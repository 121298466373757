import * as THREE from "three";
import { lineStyleCache } from "lib/line-styles/cache";
import { ILineMaterial } from "./line";
import { BaseMaterialCache } from "./base-cache";
export class LineDashedMaterialCache extends BaseMaterialCache<ILineMaterial, THREE.LineDashedMaterial> {

  public getMaterial(lineMat: ILineMaterial): THREE.LineDashedMaterial {
    for (const [key, value] of this.map) {
      if (
        key.lineStyleId === lineMat.lineStyleId &&
        key.width === lineMat.width &&
        key.color.r === lineMat.color.r &&
        key.color.g === lineMat.color.g &&
        key.color.b === lineMat.color.b &&
        key.color.a === lineMat.color.a
      ) {
        return value;
      }
    }
    const lineStyle = lineStyleCache.loadStylefromCache(lineMat.lineStyleId);
    const material = new THREE.LineDashedMaterial({
      color: new THREE.Color(
        lineMat.color.r / 255,
        lineMat.color.g / 255,
        lineMat.color.b / 255
      ),
      transparent: (lineMat.color.a !== 1),
      opacity: lineMat.color.a,
      linewidth: lineMat.width,
      depthFunc: THREE.AlwaysDepth,
    });
    if (lineStyle !== undefined) {
      material.dashSize = lineStyle.dash * lineStyle.dist;
      material.gapSize = lineStyle.gap * lineStyle.dist;
      // scale: lineStyle.dist,
    }
    this.map.set(lineMat, material);
    return material;
  }
}
