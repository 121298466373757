import { solidParam } from "lib/models/solids/solid";
import * as THREE from "three";
import { setPosRotEsc } from "..";
import { getAuxMeshBasicMaterial } from "../../materials";
import { IPoint } from "../../math/types";

export interface coneParam extends solidParam {
    upperRadius: number,
    lowerRadius: number,
    height: number,
}

export function createBufferCone(upperRadius: number, lowerRadius: number, height: number) {
    const geom = new THREE.CylinderBufferGeometry(upperRadius, lowerRadius, height, 32, undefined, false);
    geom.rotateX(Math.PI * 0.5);
    return geom;

}

export function createCone(upperRadius: number, lowerRadius: number, height: number, material?: THREE.MeshPhongMaterial, basePoint?: IPoint, rotation?: IPoint, offset?: IPoint, scale?: IPoint) {
    const geom = createBufferCone(upperRadius, lowerRadius, height);
    const mat = material ?? getAuxMeshBasicMaterial();
    const threeObj = new THREE.Mesh(geom, mat);
    if (basePoint && offset && rotation && scale) {
        setPosRotEsc(threeObj, basePoint, offset, rotation, scale);
    }
    return threeObj;
}