import { getHex } from "lib/math/color";
import { lineStyleCache } from "lib/line-styles/cache";
import * as THREE from "three";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { BaseMaterialCache } from "./base-cache";
import { ILineMaterial } from "./line";

export class LineMaterialCache extends BaseMaterialCache<ILineMaterial, LineMaterial> {

  public getMaterial(lineMat: ILineMaterial): LineMaterial {
    for (const [key, value] of this.map) {
      if (key.lineStyleId === lineMat.lineStyleId &&
        key.width === lineMat.width &&
        key.color.r === lineMat.color.r &&
        key.color.g === lineMat.color.g &&
        key.color.b === lineMat.color.b &&
        key.color.a === lineMat.color.a) {
        return value;
      }
    }
    const lineStyle = lineStyleCache.loadStylefromCache(lineMat.lineStyleId);
    const material = new LineMaterial({
      color: getHex(lineMat.color.r, lineMat.color.g, lineMat.color.b),
      transparent: (lineMat.color.a !== 1),
      opacity: lineMat.color.a,
      linewidth: lineMat.width,
      depthFunc: THREE.AlwaysDepth,
    });
    if (lineStyle !== undefined) {
      if (lineStyle.dash !== 0) {
        material.dashed = true;
        material.dashSize = lineStyle.dash * lineStyle.dist;
        material.gapSize = lineStyle.gap * lineStyle.dist;
        // material.dashScale = lineStyle.dist;
        material.defines.USE_DASH = "";
      }
    }
    material.needsUpdate = true;
    material.uniformsNeedUpdate = true;
    material.resolution.set(window.innerWidth, window.innerHeight);
    this.map.set(lineMat, material);
    return material;

  }

  public updateResolution(width: number, height: number) {
    this.map.forEach(m => m.resolution.set(width, height));
  }
}
