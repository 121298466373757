import { LayerManager } from '../layers/layer-manager';
import { LayerData } from "lib/layers/layer-data";
import { IObjData } from "lib/models/objdata";

export enum LayerActionType {
  LOAD_LAYERS,
  VISIBILITY_LAYERS,
  SET_CURRENT_LAYER,
  ADD_LAYER,
  DELETE_LAYER,
  RENAME_LAYER,
  CLEAR_LAYER,
  LOCK_LAYER,
  DRAG_LAYERS,

  DRAG_OBJECT2LAYER,
  ADD_OBJECT2LAYER,
}

export type LayerAction = {
  type: LayerActionType.LOAD_LAYERS;
  payload: {
    currentLayer: LayerData;
    layerManager: LayerManager;
  };
} | {
  type: LayerActionType.VISIBILITY_LAYERS;
  payload: {
    layer: LayerData;
    visible: boolean;
  };
} | {
  type: LayerActionType.SET_CURRENT_LAYER;
  payload: {
    currentLayer: LayerData;
    layerManager: LayerManager;
  };
} | {
  type: LayerActionType.ADD_LAYER;
  payload: {
    addedLayer: LayerData;
    layerManager: LayerManager;
  };
} | {
  type: LayerActionType.DELETE_LAYER;
  payload: {
    deletedLayer: LayerData;
    deletedLayers: LayerData[];
    layerManager: LayerManager;
  };
} | {
  type: LayerActionType.RENAME_LAYER;
  payload: {
    layer: LayerData;
    newName: string;
    layerManager: LayerManager;
  };
} | {
  type: LayerActionType.CLEAR_LAYER;
  payload: {
    clearedLayer: LayerData;
    layerManager: LayerManager;
  };
} | {
  type: LayerActionType.LOCK_LAYER;
  payload: {
    lockedLayer: LayerData;
    lockedStatus: boolean;
  };
} | {
  type: LayerActionType.DRAG_LAYERS;
  payload: {
    layerMoved: LayerData,
    layerTarget: LayerData,
    layerManager: LayerManager;
  };
} | {
  type: LayerActionType.DRAG_OBJECT2LAYER;
  payload: {
    srcObject: IObjData;
    dstLayer: LayerData;
    layerManager: LayerManager;
  };
} | {
  type: LayerActionType.ADD_OBJECT2LAYER;
  payload: {
    srcObject: IObjData;
    dstLayer: LayerData;
    layerManager: LayerManager;
  };
};
