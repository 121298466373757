import React, { FC, useCallback, useEffect, useMemo, useReducer } from "react";
import { useRouteMatch } from "react-router";

export interface ToastData {
  title?: string;
  description?: string;
  status?: "success" | "error" | "warning";
  duration?: number;
  isClosable?: boolean;
}
export type ModalView =
  | "ADD_PROJECT"
  | "ADD_LAYER"
  | "DELETE_LAYER"
  | "DELETE_PROJECT"
  | "DELETE_STRUC_PROJECT"
  | "CLONE_STRUC_PROJECT"
  | "DELETE_CAD_PROJECT"
  | "RENAME_FILE"
  | "UPDATE_SETTINGS"
  | "PLANE_LIST"
  | "SHELL_CROSS_SECTIONS"
  | "BEAM_CROSS_SECTIONS"
  | "DIMENSION_MNG"
  | "PILE_CAP_MNG"
  | "ADD_HYPOTHESIS"
  | "DELETE_HYPOTHESIS"
  | "WAFFLE"
  | "MESHING"
  | "ANALYZING"
  | "STOREYDRIFTWIZARD"
  | "STOREYDRIFTINFO"
  | "WIND_HYPOTHESIS_WIZARD";

type ModalData = Record<string, any> | null;
export interface State {
  displayModal: boolean;
  modalView: ModalView;
  modalData?: ModalData | null;
  displayToast: boolean;
  toastData: ToastData | null;
  shouldShowHeader: boolean;
}
export interface Actions {
  openModal: (view: ModalView, data?: ModalData) => void;
  closeModal: () => void;
  openToast: (data: ToastData) => void;
  closeToast: () => void;
}

const initialState = {
  displayModal: false,
  modalView: "ADD_PROJECT" as ModalView,
  modalData: null,
  displayToast: false,
  toastData: null,
  shouldShowHeader: true,
};

type Action = {
  type: "OPEN_TOAST";
  toastData: ToastData;
} | {
  type: "CLOSE_TOAST";
} | {
  type: "OPEN_MODAL";
  modalView: ModalView;
  modalData?: ModalData;
} | {
  type: "CLOSE_MODAL";
};

export const UIContext = React.createContext<State | any>(initialState);
UIContext.displayName = "UIContext";

function uiReducer(state: State, action: Action) {
  switch (action.type) {
    case "OPEN_MODAL": {
      return {
        ...state,
        modalView: action.modalView,
        modalData: action.modalData,
        displayModal: true,
      };
    }
    case "CLOSE_MODAL": {
      return {
        ...state,
        displayModal: false,
        modalData: null,
      };
    }
    case "OPEN_TOAST": {
      return {
        ...state,
        displayToast: true,
        toastData: action.toastData,
      };
    }
    case "CLOSE_TOAST": {
      return {
        ...state,
        displayToast: false,
        toastData: null,
      };
    }
  }
}

const UIProvider: FC = ({ children }) => {

  const [state, dispatch] = useReducer(uiReducer, initialState);

  const openModal = useCallback((modalView: ModalView, modalData: ModalData) => {
    dispatch({ type: "OPEN_MODAL", modalView, modalData });
  }, [dispatch]);

  const closeModal = useCallback(() => {
    dispatch({ type: "CLOSE_MODAL" });
  }, [dispatch]);

  const openToast = useCallback((toastData: ToastData) => {
    dispatch({ type: "OPEN_TOAST", toastData });
  }, [dispatch]);

  const closeToast = useCallback(() => {
    dispatch({ type: "CLOSE_TOAST" });
  }, [dispatch]);

  const currentRoute = useRouteMatch(["/cad/projects/:id", "/struc/projects/:id"]);

  const shouldShowHeader = useMemo(() => !currentRoute, [currentRoute]);

  useEffect(() => {
    if (state.displayToast) {
      const duration = state?.toastData?.duration || 3000;
      const id = setTimeout(closeToast, duration);
      return () => clearTimeout(id);
    }
  }, [state.displayToast, state.toastData, closeToast]);

  const value = useMemo(
    () => ({
      ...state,
      openModal, closeModal,
      openToast, closeToast,
      shouldShowHeader,
    }),
    [state, openModal, closeModal, openToast, closeToast, shouldShowHeader]
  );

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};

const useUI = (): State & Actions => {
  const context = React.useContext(UIContext);
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`);
  }
  return context;
};

export { UIProvider, useUI };
