import { User } from "shared/models/user";
import { Project } from "../models/project";

export const COUNTRIES = {
  bolivia: "BO",
  uruguay: "UR",
  paraguay: "PY",
  colombia: "CO",
};

export const CITIES = {
  montevideo: "MO",
  lapaz: "LP",
  asuncion: "AS",
  santacruz: "SC",
  cochabamba: "CB",
  cartagena: "CT",
  tarija: "TJ",
};

function isRightFormatted(countryCode: string, cityCode: string) {
  const options = ["PYAS", "BOLP", "BOSC", "BOCB", "BOTJ", "COCT", "URMO"];
  const code = `${countryCode}${cityCode}`;
  return options.some((option) => code.includes(option));
}

export function createCode(country: string, city: string) {
  const countryKey = country
    .split(" ")
    .join("")
    .toLowerCase() as keyof typeof COUNTRIES;
  const countryCode = COUNTRIES[countryKey];
  const cityKey = city.split(" ").join("").toLowerCase() as keyof typeof CITIES;
  const cityCode = CITIES[cityKey];
  return isRightFormatted(countryCode, cityCode)
    ? `${countryCode}${cityCode}21001`
    : "PYAS21001";
}
export function getSeismicAcc(code: String) {
  let seismicAcc = 0;
  switch (code.substr(0, 4)) {
    case 'PYAS':
      seismicAcc = 0;
      break;
    case 'BOLP':
      seismicAcc = 0.176;
      break;
    case 'BOSC':
      seismicAcc = 0.152;
      break;
    default:
      seismicAcc = 0;
      break;
  }
  return seismicAcc;
}

export function createNewProject(project: Project, user: User) {
  const { seismic_acc, storeys, address, ...rest } = project;
  const code = createCode(address.country, address.city);
  return {
    ...rest,
    seismic_acc,
    code,
    user_id: user.id,
    storeys: { data: storeys },
    events: {
      data: {
        action: "CREATED",
        description: `Project created by ${user.username}`,
      },
    },
  };
}
