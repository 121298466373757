import { Ecore } from "./base";
import { Vector } from "./location";
import { geometryUriModel } from "./uri";

export type geomType = Point | LineSegment | Arc | Circle | Rectangle | WireGeometry | OffsetGeometry;

export interface Geometry extends Ecore {
}

export interface Point extends Geometry {
  eClass: `${typeof geometryUriModel}Point`;
  coords: Vector;
}

export interface Circle extends Geometry {
  eClass: `${typeof geometryUriModel}Circle`;
  center: Vector;
  radio?: number;
}
export interface Rectangle extends Geometry {
  eClass: `${typeof geometryUriModel}Rectangle`;
  p0: Vector; // Right-bottom corner
  p1: Vector; // Left-up corner
}
interface OpenGeometry extends Geometry { }

export interface WireGeometry extends Geometry {
  eClass: `${typeof geometryUriModel}WireGeometry`;
  geometries: OpenGeometry[];
}
export interface LineSegment extends OpenGeometry {
  eClass: `${typeof geometryUriModel}LineSegment`;
  p0: Vector;
  p1: Vector;
}
export interface Arc extends OpenGeometry {
  eClass: `${typeof geometryUriModel}Arc`;
  p0: Vector;
  p1: Vector;
  pa: Vector;
}

export const defaultOffsetWith = 0.3;
export interface OffsetGeometry extends Geometry {
  eClass: `${typeof geometryUriModel}OffsetGeometry`;
  growthWidth?: GrowthWidth;
  width?: number;
  wiregeometry: WireGeometry;
}

export enum GrowthWidth {
  CENTER = "CENTER",
  LEFT = "LEFT",
  RIGHT = "RIGHT"
}
