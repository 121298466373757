import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "shared/components/auth/context";
import {
  canUseCadModule,
  canUseLiteModule,
  getModuleFromPathname,
  canSwitchModule,
  canUseStrucModule,
} from "shared/utils/auth";

export function useModules() {
  const { location, push } = useHistory();
  const { roles } = useAuth();

  const showCadModule = useMemo(() => canUseCadModule(roles), [roles]);
  const showLiteModule = useMemo(() => canUseLiteModule(roles), [roles]);
  const showStrucModule = useMemo(() => canUseStrucModule(roles), [roles]);

  const module = useMemo(() => getModuleFromPathname(location.pathname), [location.pathname]);

  const setCadModule = useCallback(() => {
    push("/cad/projects");
  }, [push]);

  const setStructModule = useCallback(() => {
    push("/lite/projects");
  }, [push]);

  const setStrucModule = useCallback(() => {
    push("/struc/projects");
  }, [push]);

  const setModule = useCallback((path: string) => {
    push(`/${path}/projects`);
  }, [push]);

  const showModuleOptions = useMemo(() => canSwitchModule(roles), [roles]);
  return {
    module,
    setCadModule,
    setStructModule,
    setStrucModule,
    showCadModule,
    showLiteModule,
    showStrucModule,
    roles,
    showModuleOptions,
    setModule,
  };
}
