
import { GraphicProcessor } from "lib/graphic-processor";
import { Site } from "lib/models-struc/struc-project";
import { Storey } from "lib/models-struc/struc-storey";
import { Building } from "lib/models-struc/struc-building";
import { StrucSite, StrucBuilding, StrucStorey, StrucProject } from "modules/struc/models/project";
import { SupportElementTypes } from "lib/models/structural/structural";

const VERSION: string = "0.1";
export class StrucDataExporter {

  private graphicProcessor: GraphicProcessor;

  constructor(graphicProcessor: GraphicProcessor) {
    this.graphicProcessor = graphicProcessor;
  }

  saveProjectStruct(): Partial<StrucProject> {
    const projMng = this.graphicProcessor.getProjectModelManager();
    const project = projMng.project
    const projectData: Partial<StrucProject> = {
      version: VERSION,
      id: project.id,
      name: project.name,
      remarks: project.remarks,
      city: project.city,
      country: project.country,
      steel_type: project.steel_type,
      steel_standard: project.steel_standard,
      concrete_code: project.concrete_code,
      concrete_standard: project.concrete_standard,
      load_code: project.load_code,
      footing_level: project.footingLevel,
    }
    projectData.sites = this.saveStrucSites(project.sites);
    return projectData;
  }

  private saveStrucSites(sites: Site[]): StrucSite[] {
    const result: StrucSite[] = [];
    for (let site of sites) {
      const siteData: StrucSite = {
        id: site.id,
        name: site.name,
        remarks: site.remarks,
        buildings: this.saveStrucBuildings(site.buildings),
      };
      result.push(siteData);
    }
    return result;
  }

  private saveStrucBuildings(buildings: Building[]): StrucBuilding[] {
    const result: StrucBuilding[] = [];
    for (let building of buildings) {
      const buildingData: StrucBuilding = {
        id: building.id,
        name: building.name,
        remarks: building.remarks,
        struc_storeys: this.saveStrucStoreys(building.storeys),
        struc_elements: this.saveStrucElements(building),
        load_elements: this.saveLoadElements(building),
        struc_counters: building.getCurrentCounters(),
        compound_columns: this.saveStrucCompoundElements(building.columnCompoundElements.getElements()),
        compound_walls: this.saveStrucCompoundElements(building.wallCompoundElements.getElements()),
      };
      result.push(buildingData);
    }
    return result;
  }

  private saveStrucStoreys(storeys: Storey[]): StrucStorey[] {
    const result: StrucStorey[] = [];
    for (let i = 0; i < storeys.length; i++) {
      const storey = storeys[i];
      const str: StrucStorey = {
        id: storey.id,
        floor_name: storey.name,
        height: storey.height,
        level: storey.level,
        occupancy: storey.occupancy,
        live_load: storey.liveLoad,
        dead_load: storey.deadLoad,
        column_concrete_type: storey.columnConcreteType,
        floor_concrete_type: storey.floorConcreteType,
        dxf_name: storey.fileDxf,
        dxf_url: "",
        storey_number: i + 1,
      };
      result.push(str);
    }
    return result;
  }

  private saveStrucElements(building: Building): string[] {
    const strucMng = this.graphicProcessor.getStructuralModelManager();
    const structElements = strucMng.getStructElements();
    const elems: string[] = [];
    for (let elem of structElements) {
      // Current element belongs to any storey of current building
      if (building.storeys.some(s => s.id === elem.storeyId)) {
        elems.push(elem.id);
      }
    }
    return elems;
  }

  private saveLoadElements(building: Building): string[] {
    const strucMng = this.graphicProcessor.getStructuralModelManager();
    const loadElements = strucMng.getLoadElements();
    const elems: string[] = [];
    for (let load of loadElements) {
      // Current element belongs to any storey of current building
      const parentElem = strucMng.getStructElemFromId(load.definition.parentStructElemId);
      if (parentElem && building.storeys.some(s => s.id === parentElem.storeyId)) {
        elems.push(load.id);
      }
    }
    return elems;
  }

  private saveStrucCompoundElements(compounds: Map<string, SupportElementTypes[]>) {
    const result = Object.create(null);
    compounds.forEach((value, key) => {
      let elements = [];
      for (let elem of value) {
        // Only persist elements in scene
        elements.push(elem.id);
      }
      if (elements.length) {
        result[key] = elements;
      }
    });
    return result;
  }
}