import { ILineMaterial } from "lib/materials";
import { objDataType } from "lib/models/types";
import { ArcData } from "lib/models/primitives/arc";
import { GraphicProcessor } from "../../graphic-processor";
import { arcParam } from "../../math/arc";
import { cadOpType } from "../../operations/factory";
import { createCommand } from "./create-base";

export class ArcCommand extends createCommand {
  
  public createdData: ArcData;
  protected opType = cadOpType.ARC;
  protected objType = objDataType.ARC;
  protected param: arcParam;
  protected material: ILineMaterial | undefined;

  constructor(params: arcParam, layerId: string, graphicProc: GraphicProcessor, material?: ILineMaterial) {
    super(layerId, graphicProc);
    this.param = params;
    this.material = material;
  }
}