import { PolylineEditDataCommand } from "lib/commands/edition/polyline";
import { angularPrecision, getUserAngleUnitSufix, linealPrecision, radAngleToUser } from "lib/general-settings";
import { normalizeAngle, lineAzimut2p } from "lib/math/angles";
import { areaPoints } from "lib/math/area";
import { calculateInertiaMoments4Points } from "lib/math/centroid";
import { getArcStartAzimut, IPolylineParam, movePolyArcVertex, polylineContainArcs } from "lib/math/line";
import { calculatePerimeterPolyArc } from "lib/math/perimeter";
import { IPoint } from "lib/math/types";
import { LineData } from "lib/models/primitives/line";
import { DataDefinitionHandler } from "../base";
import { ILineMaterial } from '../../materials/line';

export type vertexView = IPoint & {
  azimut: number | null;
  radius: number | null;
};

export type polylineView = {
  points: vertexView[];
  isClosed: boolean;
  lineLength: number;
  lineArea: number;
  inertiaX: number;
  inertiaY: number;
  inertiaXY: number;
};

export class PolylineDataDefinitionHandler extends DataDefinitionHandler<polylineView, ILineMaterial, undefined, undefined> {

  protected data: LineData;

  protected buildInfoProperties() {
    const def = this.data.definition;
    const vertex = this.getDefinitionView();
    this.definitionInfo = {
      lineLength: this.getNumberView(calculatePerimeterPolyArc(def), "Length", "m", linealPrecision, false),
    };
    if (def.points.length > 2) {
      this.definitionInfo.isClosed = this.getBooleanView(def.isClosed, "Closed");
    }
    if (def.isClosed && !polylineContainArcs(def)) {
      const area = areaPoints(this.data.definition.points);
      this.definitionInfo.lineArea = this.getNumberView(area, "Area", "m²", linealPrecision, false);
      const inertia = calculateInertiaMoments4Points(def.points);
      this.definitionInfo.inertiaX = this.getNumberView(inertia.x, "Inertia X", "kg m²", linealPrecision, false);
      this.definitionInfo.inertiaY = this.getNumberView(inertia.y, "Inertia Y", "kg m²", linealPrecision, false);
      this.definitionInfo.inertiaXY = this.getNumberView(inertia.xy, "Inertia XY", "kg m²", linealPrecision, false);
    }
    this.definitionInfo.points = vertex.map((p) => ({
      x: { type: "number", value: p.x, publicName: "X", units: "m", precision: linealPrecision },
      y: { type: "number", value: p.y, publicName: "Y", units: "m", precision: linealPrecision },
      z: { type: "number", value: p.z, publicName: "Z", units: "m", precision: linealPrecision },
      azimut: p.azimut ? {
        type: "number",
        value: p.azimut,
        publicName: "Azimut",
        units: getUserAngleUnitSufix(),
        precision: angularPrecision,
        editable: false,
      } : undefined,
      radius: p.radius ? {
        type: "number",
        value: p.radius,
        publicName: "Radius",
        units: "m",
        precision: linealPrecision,
        editable: false,
      } : undefined,
    }));
    this.materialInfo = this.getLineMaterialView(this.data.material);
  }
  private getDefinitionView(): vertexView[] {
    const def = this.data.definition;
    return def.points.map((p, i) => {
      const p0 = p;
      const arc = def.arcs[i];
      let p1 = def.points[i + 1];
      if (def.isClosed && p1 === undefined) {
        p1 = def.points[0];
      }
      let az = null;
      if (arc) {
        az = getArcStartAzimut(p0, arc);
      } else if (p1) {
        az = normalizeAngle(lineAzimut2p(p0, p1));
      }
      return {
        x: p.x, y: p.y, z: p.z,
        azimut: az ? radAngleToUser(az) : az,
        radius: arc ? arc.radius : null,
      };
    });
  }
  public saveAndRegenerate = (newDefinition: any) => {
    let polylineDefinition: IPolylineParam | null = null;
    if (newDefinition) {
      if (this.definitionInfo.isClosed && this.definitionInfo.isClosed.value !== newDefinition.isClosed) {
        polylineDefinition = this.data.cloneDefinition();
        polylineDefinition.isClosed = newDefinition.isClosed;
      } else {
        const oldDef = this.data.definition.points;
        let index = -1;
        let point: IPoint | null = { x: 0, y: 0, z: 0 };
        for (let i = 0; i < oldDef.length; i++) {
          point = this.changedPoint(oldDef[i], newDefinition.points[i])
          if (point) {
            index = i;
            break;
          }
        }
        if (point) {
          polylineDefinition = this.data.cloneDefinition();
          movePolyArcVertex(polylineDefinition, point, index);
        }
      }
    }
    if (polylineDefinition) {
      const gp = this.graphicProcessor;
      const command = new PolylineEditDataCommand(this.data, polylineDefinition, null, gp);
      gp.storeAndExecute(command);
    }
  };
  public saveAndRegenerateMaterial = (newMaterial: ILineMaterial) => {
    const polylineMaterial = this.checkAndChangedLineMaterial(this.data.material, newMaterial);
    if (polylineMaterial) {
      const gp = this.graphicProcessor;
      const command = new PolylineEditDataCommand(this.data, null, polylineMaterial, gp);
      gp.storeAndExecute(command);
    }
  };
}