import * as THREE from "three";
import { Cad3dOp } from "./base";
import { settingsOpModes } from "./step-operations";
import { IPoint } from "lib/math/types";
import { copyIPoint } from "lib/math/point";
import { Line2 } from "three/examples/jsm/lines/Line2";

/** Edition of Data, changing its parameters (NO Selection by Raycast)
 *
 * @export
 * @abstract
 * @class SimpleEdition
 * @extends {Cad3dOp}
 */
export abstract class SimpleEdition extends Cad3dOp {

  protected auxPoly: THREE.Line | Line2;
  protected auxLine: THREE.Line;

  constructor(iniPoint: IPoint) {
    super();
    this.lastPoint = copyIPoint(iniPoint);
  }

  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Insert point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
    }]);
  }

  public async start() {
    this.iniSettingsOp();
    this.initializeSnap();
    this.initializeWorkingPlane();
    this.saveToTempScene(this.auxPoly);
    this.saveToTempScene(this.auxLine);
    this.registerCancel();
    this.registerInputs();
    this.registerUpdaters();
  }
  public endOperation(): void {
    if (!this.finished) {
      this.save();
      super.endOperation();
    }
  }
}
