import { updateObjBboxBSphere } from "lib/geometries";
import { getVertexFromIndex, lineMoveVertex } from "lib/geometries/line";
import { rotateObjX, rotateObjZ } from "lib/geometries/rotate";
import { lineAngle2p, lineAzimut2p, lineSlope2p } from "lib/math/angles";
import { distancePointToLine3D, vectorDist3D } from "lib/math/distance";
import { vector2Equals, vector2EqualsXZ, vector2EqualsYZ, vector3Equals } from "lib/math/epsilon";
import { getParalleLine3DFromPoint, getParallelLine2DFromDist } from "lib/math/line";
import { copyIPoint, getMiddlePoint, getPointOnSegment, getPolarPoint, IpointsToBuffer } from "lib/math/point";
import { rotatePointZ, rotatePointX } from "lib/math/rotate";
import { dimPlane, dimDirectionMode, dimOrientationMode } from "lib/operations/dimension/dimension-base";
import { createText, modifyText } from "lib/text/builder";
import { sdfDoubleSidedType, TextOptsBuilder } from "lib/text/styles";
import * as THREE from "three";
import { lineGetBbox } from "../math/box";
import { IPoint, ISegment } from "../math/types";
import { updateArrowSymbol, getArrowSymbol } from "./arrow";
import { DimStyleBuilder } from "./style";

interface ILinearDimRef {
  oriDimPlane?: dimPlane;
  dimPlane: dimPlane;
  dimDirection: dimDirectionMode;
  dimOrientation: dimOrientationMode;
  /** Posición del plano paralelo donde se contiene la acotación (por delante o por detras del bbox) */
  behindObj: number;
}

export function getDimGeometry(dimension: THREE.Group): THREE.LineSegments { return dimension.children[0] as THREE.LineSegments; }
export function getDimLabel(dimension: THREE.Group): THREE.Mesh { return dimension.children[1] as THREE.Mesh; }
export function getDimArrows(dimension: THREE.Group): THREE.Mesh[] { return [dimension.children[2] as THREE.Mesh, dimension.children[3] as THREE.Mesh]; }
export function getDimAuxGeometry(dimension: THREE.Group): THREE.Line { return dimension.children[4] as THREE.Line; }


export function createAlignedDimension(dimLine: ISegment, dimStyle: DimStyleBuilder, plane: dimPlane, orientation: dimOrientationMode, txtStyle: TextOptsBuilder): THREE.Group {
  const dimGeometry = getAlignedDimGeometry(dimLine, dimStyle, plane, orientation) as THREE.LineSegments;
  const textLabel = getLinearDimLabelText(dimGeometry, dimStyle, txtStyle, plane);
  const arrows = getLinearDimArrows(dimGeometry, dimStyle, plane);

  const dim = new THREE.Group();
  dim.add(dimGeometry);
  dim.add(textLabel);
  dim.add(arrows[0]);
  dim.add(arrows[1]);
  return dim;
}
export function createLinearDimension(dimLine: ISegment, dimRef: ILinearDimRef, dimStyle: DimStyleBuilder, txtStyle: TextOptsBuilder): THREE.Group {
  if (vector2Equals(dimLine.p1, dimLine.p2)) {
    return createAlignedDimension(dimLine, dimStyle, dimRef.dimPlane, dimRef.dimOrientation, txtStyle);
  }

  const projLine = getProyectedLine(dimLine, dimRef) as ISegment;
  const centerPoint = getMiddlePoint(projLine.p1, projLine.p2);
  const XYangle = lineAngle2p(projLine.p1, projLine.p2);
  if (dimRef.dimPlane !== dimPlane.XY) {
    projLine.p1 = rotatePointZ(projLine.p1, -XYangle, centerPoint);
    projLine.p1 = rotatePointX(projLine.p1, -(Math.PI * 0.5), centerPoint);
    projLine.p2 = rotatePointZ(projLine.p2, -XYangle, centerPoint);
    projLine.p2 = rotatePointX(projLine.p2, -(Math.PI * 0.5), centerPoint);
  }
  const currentOPlane = dimRef.dimPlane;
  dimRef.dimPlane = dimPlane.XY;
  let dimGeometry: THREE.LineSegments;
  if (dimRef.dimDirection === dimDirectionMode.DEF) {
    dimGeometry = getAlignedDimGeometry(projLine, dimStyle, dimRef.dimPlane, dimRef.dimOrientation) as THREE.LineSegments;
  } else {
    if (dimRef.dimDirection === dimDirectionMode.AUTO) dimRef.dimDirection = dimDirectionMode.NS;
    dimGeometry = getLinearDimGeometry(projLine, dimRef, dimStyle) as THREE.LineSegments;
  }

  const textLabel = getLinearDimLabelText(dimGeometry, dimStyle, txtStyle, dimRef.dimPlane);
  const arrows = getLinearDimArrows(dimGeometry, dimStyle, dimRef.dimPlane);

  const dim = new THREE.Group();
  dim.add(dimGeometry);
  dim.add(textLabel);
  dim.add(arrows[0]);
  dim.add(arrows[1]);

  dimRef.dimPlane = currentOPlane;
  if (dimRef.dimPlane !== dimPlane.XY) {
    rotateObjX(dimGeometry, Math.PI * 0.5, centerPoint);
    rotateObjZ(dimGeometry, XYangle, centerPoint);

    rotateObjX(textLabel, Math.PI * 0.5, centerPoint);
    rotateObjZ(textLabel, XYangle, centerPoint);

    rotateObjX(arrows[0], Math.PI * 0.5, centerPoint);
    rotateObjZ(arrows[0], XYangle, centerPoint);

    rotateObjX(arrows[1], Math.PI * 0.5, centerPoint);
    rotateObjZ(arrows[1], XYangle, centerPoint);
  }

  const auxGeometry = getLinearAuxGeometry(dimLine, dimGeometry, dimStyle, dimRef.dimPlane) as THREE.Object3D;
  dim.add(auxGeometry);

  return dim;
}
export function getProyectedLine(line: ISegment, dimRef: ILinearDimRef): ISegment {
  if (dimRef.dimPlane === dimPlane.AUTO || vector2Equals(line.p1, line.p2)) {
    return { p1: copyIPoint(line.p1), p2: copyIPoint(line.p2) };
  } else {

    if (dimRef.behindObj === undefined) {
      const bbox = lineGetBbox(line);
      if (dimRef.dimPlane === dimPlane.XY) {
        dimRef.behindObj = bbox.min.z;
      } else if (dimRef.dimPlane === dimPlane.XZ) {
        dimRef.behindObj = bbox.min.y;
      } else if (dimRef.dimPlane === dimPlane.YZ) {
        dimRef.behindObj = bbox.min.x;
      }
    }

    let projLine;
    const v1 = line.p1;
    const v2 = line.p2;
    if (dimRef.dimPlane === dimPlane.XY) {
      projLine = {
        p1: { x: v1.x, y: v1.y, z: dimRef.behindObj },
        p2: { x: v2.x, y: v2.y, z: dimRef.behindObj },
      };
    } else if (dimRef.dimPlane === dimPlane.XZ) {
      projLine = {
        p1: { x: v1.x, y: dimRef.behindObj, z: v1.z },
        p2: { x: v2.x, y: dimRef.behindObj, z: v2.z },
      };
    } else if (dimRef.dimPlane === dimPlane.YZ) {
      projLine = {
        p1: { x: dimRef.behindObj, y: v1.y, z: v1.z },
        p2: { x: dimRef.behindObj, y: v2.y, z: v2.z },
      };
    }
    return projLine as ISegment;
  }
}

export function updateAlignedDimension(dimensionGroup: THREE.Group, dimBase: ISegment, dimStyle: DimStyleBuilder, plane: dimPlane, orientation: dimOrientationMode, txtStyle: TextOptsBuilder): void {
  const geometry = getDimGeometry(dimensionGroup);
  const newGeometry = getAlignedDimBuffer(dimBase, dimStyle, plane, orientation) as THREE.BufferGeometry;
  geometry.geometry = newGeometry;
  updateObjBboxBSphere(geometry);

  let arrows = getDimArrows(dimensionGroup);
  updateArrowSymbol(dimensionGroup, arrows[0], dimStyle.arrowId);
  updateArrowSymbol(dimensionGroup, arrows[1], dimStyle.arrowId);
  arrows = getDimArrows(dimensionGroup);
  updateLinearDimArrows(arrows[0], arrows[1], geometry, plane);

  const textLabel = getDimLabel(dimensionGroup);
  updateLinearDimLabelText(textLabel, geometry, dimStyle, plane, txtStyle);
}
export function updateLinearDimension(dimensionGroup: THREE.Group, dimBase: ISegment, dimRef: ILinearDimRef, dimStyle: DimStyleBuilder, txtStyle: TextOptsBuilder): void {
  if (vector2Equals(dimBase.p1, dimBase.p2)) {
    // Vertical line
    updateAlignedDimension(dimensionGroup, dimBase, dimStyle, dimRef.dimPlane, dimRef.dimOrientation, txtStyle);
    const auxGeometry = getDimAuxGeometry(dimensionGroup);
    if (auxGeometry) dimensionGroup.remove(auxGeometry);

  } else {
    const projLine = getProyectedLine(dimBase, dimRef) as ISegment;

    const centerPoint = getMiddlePoint(projLine.p1, projLine.p2);
    const XYangle = lineAngle2p(projLine.p1, projLine.p2);
    if (dimRef.dimPlane !== dimPlane.XY) {
      rotatePointZ(projLine.p1, -XYangle, centerPoint);
      rotatePointX(projLine.p1, -(Math.PI * 0.5), centerPoint);
      rotatePointZ(projLine.p2, -XYangle, centerPoint);
      rotatePointX(projLine.p2, -(Math.PI * 0.5), centerPoint);
    }

    const currentOPlane = dimRef.dimPlane;
    dimRef.dimPlane = dimPlane.XY;

    const geometry = getDimGeometry(dimensionGroup);
    let newGeometry;
    if (dimRef.dimDirection === dimDirectionMode.DEF || vector2Equals(projLine.p1, projLine.p2)) {
      newGeometry = getAlignedDimBuffer(projLine, dimStyle, dimRef.dimPlane, dimRef.dimOrientation) as THREE.BufferGeometry;
    } else {
      newGeometry = getLinearDimBuffer(projLine, dimRef, dimStyle) as THREE.BufferGeometry;
    }
    geometry.geometry = newGeometry;
    updateObjBboxBSphere(geometry);

    let arrows = getDimArrows(dimensionGroup);
    updateArrowSymbol(dimensionGroup, arrows[0], dimStyle.arrowId);
    updateArrowSymbol(dimensionGroup, arrows[1], dimStyle.arrowId);
    arrows = getDimArrows(dimensionGroup);
    updateLinearDimArrows(arrows[0], arrows[1], geometry, dimRef.dimPlane);

    const textLabel = getDimLabel(dimensionGroup);
    updateLinearDimLabelText(textLabel, geometry, dimStyle, dimRef.dimPlane, txtStyle);

    dimRef.dimPlane = currentOPlane;
    if (dimRef.dimPlane !== dimPlane.XY) {
      rotateObjX(dimensionGroup, Math.PI * 0.5, centerPoint);
      rotateObjZ(dimensionGroup, XYangle, centerPoint);
    }

    const auxGeometry = getDimAuxGeometry(dimensionGroup);
    const newAuxGeometry = getLinearAuxBuffer(dimBase, geometry, dimStyle, dimRef.dimPlane);
    if (newAuxGeometry && auxGeometry) {
      auxGeometry.geometry =  newAuxGeometry;
      updateObjBboxBSphere(auxGeometry);
    } else if (newAuxGeometry && !auxGeometry) {
      const newAuxLine = new THREE.LineSegments(newAuxGeometry, getDimAuxLineMaterial());
      dimensionGroup.add(newAuxLine);
    } else if (!newAuxGeometry && auxGeometry) {
      dimensionGroup.remove(auxGeometry);
    }
  }
}

export function getDimAuxLineMaterial(): THREE.LineBasicMaterial {
  return new THREE.LineBasicMaterial({
    color: "#9c9d9e",
    transparent: true,
    opacity: 0.7,
  });
}

function getAlignedDimGeometry(obj: ISegment, dimStyle: DimStyleBuilder, plane: dimPlane, orientation: dimOrientationMode): THREE.LineSegments | void {
  const geometry = getAlignedDimBuffer(obj, dimStyle, plane, orientation) as THREE.BufferGeometry;
  const resultsLines = new THREE.LineSegments(geometry, getDimAuxLineMaterial());
  return resultsLines;
}
export function getAlignedDimBuffer(obj: ISegment, dimStyle: DimStyleBuilder, plane: dimPlane, orientation: dimOrientationMode, ptoBaseLine?: IPoint): THREE.BufferGeometry | void {
  const position: IPoint[] = new Array(10);
  const p1: IPoint = obj.p1;
  const p2: IPoint = obj.p2;

  // Línea base de cota
  let dimBaseLine: ISegment;
  if (ptoBaseLine) {
    dimBaseLine = getParalleLine3DFromPoint(obj, ptoBaseLine);
    dimStyle.distBaseLine = distancePointToLine3D(p1, p2, ptoBaseLine, false);
  } else {
    let dist = dimStyle.distBaseLine;

    const az = lineAzimut2p(p1, p2);
    if (az <= 0) {
      if (plane === dimPlane.XY) {
        if (orientation === dimOrientationMode.POSITIVE) {
          dist *= -1;
        }
      } else {
        if (orientation === dimOrientationMode.NEGATIVE) {
          dist *= -1;
        }
      }
    } else {
      if (orientation === dimOrientationMode.NEGATIVE) {
        dist *= -1;
      }
    }

    if (plane === dimPlane.XY) {
      dimBaseLine = getParallelLine2DFromDist(obj, dist, true);
    } else {
      if (vector2Equals(p1, p2) && plane === dimPlane.YZ) {
        dimBaseLine = getParallelLine2DFromDist(obj, dist, true);
      } else {
        dimBaseLine = getParallelLine2DFromDist(obj, dist, false);
      }
    }
  }
  const dimBase1 = dimBaseLine.p1;
  const dimBase2 = dimBaseLine.p2;

  const dimBaseLineLength: number = vectorDist3D(p1, p2);
  position[0] = getPointOnSegment(dimBase1, dimBase2, -(dimStyle.extBaseLine / dimBaseLineLength));
  position[1] = getPointOnSegment(dimBase1, dimBase2, 1 + dimStyle.extBaseLine / dimBaseLineLength);

  // Lineas lateral auxiliar 1
  const lateralLine1: ISegment = { p1, p2: dimBase1 };
  const lateralLineLength1 = vectorDist3D(dimBase1, p1);
  position[2] = getPointOnSegment(lateralLine1.p1, lateralLine1.p2, 1 + (dimStyle.ext / lateralLineLength1));
  position[3] = getPointOnSegment(lateralLine1.p1, lateralLine1.p2, dimStyle.offset / lateralLineLength1);

  // Lineas lateral auxiliar 2
  const lateralLine2: ISegment = { p1: p2, p2: dimBase2 };
  const lateralLineLength2 = vectorDist3D(dimBase2, p2);
  position[4] = getPointOnSegment(lateralLine2.p1, lateralLine2.p2, 1 + (dimStyle.ext / lateralLineLength2));
  position[5] = getPointOnSegment(lateralLine2.p1, lateralLine2.p2, dimStyle.offset / lateralLineLength2);

  // Puntos intersección linea base - lineas laterales
  position[6] = { x: dimBase1.x, y: dimBase1.y, z: dimBase1.z };
  position[7] = { x: dimBase2.x, y: dimBase2.y, z: dimBase2.z };

  // Puntos intersección linea base - lineas laterales
  position[8] = { x: p1.x, y: p1.y, z: p1.z };
  position[9] = { x: p2.x, y: p2.y, z: p2.z };

  const geometry: THREE.BufferGeometry = new THREE.BufferGeometry();
  const coords: Float32Array = IpointsToBuffer(position);
  geometry.setAttribute("position", new THREE.BufferAttribute(coords, 3));
  geometry.setDrawRange(0, 6);
  return geometry;
}

function getLinearDimGeometry(obj: ISegment, dimRef: ILinearDimRef, dimStyle: DimStyleBuilder): THREE.LineSegments | void {
  const geometry = getLinearDimBuffer(obj, dimRef, dimStyle) as THREE.BufferGeometry;
  const resultsLines = new THREE.LineSegments(geometry, getDimAuxLineMaterial());
  return resultsLines;
}
export function getLinearDimBuffer(obj: ISegment, dimRef: ILinearDimRef, dimStyle: DimStyleBuilder): THREE.BufferGeometry | void {
  const position: IPoint[] = new Array(10);
  const v1: IPoint = obj.p1;
  const v2: IPoint = obj.p2;
  const centerPoint = getMiddlePoint(v1, v2);
  const bbox = lineGetBbox(obj);
  let dir = dimRef.dimDirection;

  if (bbox.max.y === bbox.min.y && bbox.max.z === bbox.min.z) dir = dimDirectionMode.NS;
  if (bbox.max.x === bbox.min.x && bbox.max.z === bbox.min.z) dir = dimDirectionMode.WE;

  const coordLimit = bbox.min.z;

  if (dir === dimDirectionMode.NS) {
    const heigthOffset = bbox.max.y - centerPoint.y;
    if (dimRef.dimOrientation === dimOrientationMode.POSITIVE) {
      position[6] = { x: bbox.min.x, y: centerPoint.y + heigthOffset + dimStyle.distBaseLine, z: coordLimit };
      position[7] = { x: bbox.max.x, y: centerPoint.y + heigthOffset + dimStyle.distBaseLine, z: coordLimit };
    } else if (dimRef.dimOrientation === dimOrientationMode.NEGATIVE) {
      position[6] = { x: bbox.min.x, y: centerPoint.y - heigthOffset - dimStyle.distBaseLine, z: coordLimit };
      position[7] = { x: bbox.max.x, y: centerPoint.y - heigthOffset - dimStyle.distBaseLine, z: coordLimit };
    }
    if (v1.x < v2.x) {
      position[8] = { x: v1.x, y: v1.y, z: coordLimit };
      position[9] = { x: v2.x, y: v2.y, z: coordLimit };
    } else {
      position[8] = { x: v2.x, y: v2.y, z: coordLimit };
      position[9] = { x: v1.x, y: v1.y, z: coordLimit };
    }
    position[0] = { x: position[6].x - dimStyle.extBaseLine, y: position[6].y, z: coordLimit };
    position[1] = { x: position[7].x + dimStyle.extBaseLine, y: position[7].y, z: coordLimit };

    let angle = Math.PI * 0.5;
    if (position[6].y < position[8].y) { angle = -Math.PI * 0.5; }
    position[2] = getPolarPoint(position[6], angle, dimStyle.ext);
    position[3] = getPolarPoint(position[8], angle, dimStyle.offset);

    angle = Math.PI * 0.5;
    if (position[7].y < position[9].y) { angle = -Math.PI * 0.5; }
    position[4] = getPolarPoint(position[7], angle, dimStyle.ext);
    position[5] = getPolarPoint(position[9], angle, dimStyle.offset);

  } else if (dir === dimDirectionMode.WE) {
    const widthOffset = centerPoint.x - bbox.min.x;
    if (dimRef.dimOrientation === dimOrientationMode.POSITIVE) {
      position[6] = { x: centerPoint.x + widthOffset + dimStyle.distBaseLine, y: bbox.min.y, z: coordLimit };
      position[7] = { x: centerPoint.x + widthOffset + dimStyle.distBaseLine, y: bbox.max.y, z: coordLimit };
    } else if (dimRef.dimOrientation === dimOrientationMode.NEGATIVE) {
      position[6] = { x: centerPoint.x - widthOffset - dimStyle.distBaseLine, y: bbox.min.y, z: coordLimit };
      position[7] = { x: centerPoint.x - widthOffset - dimStyle.distBaseLine, y: bbox.max.y, z: coordLimit };
    }
    if (v1.y < v2.y) {
      position[8] = { x: v1.x, y: v1.y, z: coordLimit };
      position[9] = { x: v2.x, y: v2.y, z: coordLimit };
    } else {
      position[8] = { x: v2.x, y: v2.y, z: coordLimit };
      position[9] = { x: v1.x, y: v1.y, z: coordLimit };
    }
    position[0] = { x: position[6].x, y: position[6].y - dimStyle.extBaseLine, z: coordLimit };
    position[1] = { x: position[7].x, y: position[7].y + dimStyle.extBaseLine, z: coordLimit };

    let angle = Math.PI;
    if (position[6].x > position[8].x) { angle = 0; }
    position[2] = getPolarPoint(position[6], angle, dimStyle.ext);
    position[3] = getPolarPoint(position[8], angle, dimStyle.offset);

    angle = Math.PI;
    if (position[7].x > position[9].x) { angle = 0; }
    position[4] = getPolarPoint(position[7], angle, dimStyle.ext);
    position[5] = getPolarPoint(position[9], angle, dimStyle.offset);
  }

  const geometry: THREE.BufferGeometry = new THREE.BufferGeometry();
  const coords: Float32Array = IpointsToBuffer(position);
  geometry.setAttribute("position", new THREE.BufferAttribute(coords, 3));
  geometry.setDrawRange(0, 6);
  geometry.computeBoundingBox();
  geometry.computeBoundingSphere();
  return geometry;
}
function getLinearAuxGeometry(obj: ISegment, geom: THREE.LineSegments, dimStyle: DimStyleBuilder, plane: dimPlane): THREE.LineSegments | void {
  const geometry = getLinearAuxBuffer(obj, geom, dimStyle, plane);
  if (geometry) {
    const resultsLines = new THREE.LineSegments(geometry, getDimAuxLineMaterial());
    return resultsLines;
  }
}
export function getLinearAuxBuffer(obj: ISegment, geom: THREE.LineSegments, dimStyle: DimStyleBuilder, plane: dimPlane): THREE.BufferGeometry | null {
  const v1Obj = obj.p1;
  const v2Obj = obj.p2;

  let vectorEqual2D = vector2Equals;
  if (plane === dimPlane.XZ) vectorEqual2D = vector2EqualsXZ;
  if (plane === dimPlane.YZ) vectorEqual2D = vector2EqualsYZ;

  const v1Dim = getVertexFromIndex(geom as THREE.Line, 8) as IPoint;
  const v2Dim = getVertexFromIndex(geom as THREE.Line, 9) as IPoint;
  const position: IPoint[] = [];
  if (vector3Equals(v1Obj, v1Dim) && !vector3Equals(v2Obj, v2Dim)) {
    position[0] = v2Obj;
    position[1] = v2Dim;
    const dist = vectorDist3D(v2Obj, v2Dim);
    position[0] = getPointOnSegment(v2Obj, v2Dim, dimStyle.extBaseLine / dist);
    lineMoveVertex(geom, v2Dim.x, v2Dim.y, v2Dim.z, 5);
  } else if (vector3Equals(v2Obj, v2Dim) && !vector3Equals(v1Obj, v1Dim)) {
    position[0] = v1Obj;
    position[1] = v1Dim;
    const dist = vectorDist3D(v1Obj, v1Dim);
    position[0] = getPointOnSegment(v1Obj, v1Dim, dimStyle.extBaseLine / dist);
    lineMoveVertex(geom, v1Dim.x, v1Dim.y, v1Dim.z, 3);
  } else if (vector3Equals(v1Obj, v2Dim) && !vector3Equals(v2Obj, v1Dim)) {
    position[0] = v2Obj;
    position[1] = v1Dim;
    const dist = vectorDist3D(v2Obj, v1Dim);
    position[0] = getPointOnSegment(v2Obj, v1Dim, dimStyle.extBaseLine / dist);
    lineMoveVertex(geom, v1Dim.x, v1Dim.y, v1Dim.z, 3);
  } else if (vector3Equals(v2Obj, v1Dim) && !vector3Equals(v1Obj, v2Dim)) {
    position[0] = v1Obj;
    position[1] = v2Dim;
    const dist = vectorDist3D(v1Obj, v2Dim);
    position[0] = getPointOnSegment(v1Obj, v2Dim, dimStyle.extBaseLine / dist);
    lineMoveVertex(geom, v2Dim.x, v2Dim.y, v2Dim.z, 5);
  } else if (!vector3Equals(v2Obj, v1Dim) && !vector3Equals(v1Obj, v2Dim) && !vector3Equals(v1Obj, v1Dim) && !vector3Equals(v2Obj, v2Dim)) {
    if (vectorEqual2D(v1Obj, v1Dim) && !vector3Equals(v1Obj, v1Dim)) {
      const dist = vectorDist3D(v1Obj, v1Dim);
      position.push(getPointOnSegment(v1Obj, v1Dim, dimStyle.extBaseLine / dist));
      position.push(v1Dim);
      lineMoveVertex(geom, v1Dim.x, v1Dim.y, v1Dim.z, 3);
    }
    if (vectorEqual2D(v1Obj, v2Dim) && !vector3Equals(v1Obj, v2Dim)) {
      const dist = vectorDist3D(v1Obj, v2Dim);
      position.push(getPointOnSegment(v1Obj, v2Dim, dimStyle.extBaseLine / dist));
      position.push(v2Dim);
      lineMoveVertex(geom, v2Dim.x, v2Dim.y, v2Dim.z, 5);
    }
    if (vectorEqual2D(v2Obj, v1Dim) && !vector3Equals(v2Obj, v1Dim)) {
      const dist = vectorDist3D(v2Obj, v1Dim);
      position.push(getPointOnSegment(v2Obj, v1Dim, dimStyle.extBaseLine / dist));
      position.push(v1Dim);
      lineMoveVertex(geom, v1Dim.x, v1Dim.y, v1Dim.z, 3);
    }
    if (vectorEqual2D(v2Obj, v2Dim) && !vector3Equals(v2Obj, v2Dim)) {
      const dist = vectorDist3D(v2Obj, v2Dim);
      position.push(getPointOnSegment(v2Obj, v2Dim, dimStyle.extBaseLine / dist));
      position.push(v2Dim);
      lineMoveVertex(geom, v2Dim.x, v2Dim.y, v2Dim.z, 5);
    }
  } else {
    position[0] = v1Obj;
  }
  if (position.length >= 1) {
    const geometry: THREE.BufferGeometry = new THREE.BufferGeometry();
    const coords: Float32Array = IpointsToBuffer(position);
    geometry.setAttribute("position", new THREE.BufferAttribute(coords, 3));
    return geometry;
  }
  return null;
}

// LABELS

function getLinearDimLabelText(geom: THREE.LineSegments, dimStyle: DimStyleBuilder, txtOpts: TextOptsBuilder, plane: dimPlane): THREE.Mesh {
  const dimBaseLineP1 = getVertexFromIndex(geom, 6) as IPoint;
  const dimBaseLineP2 = getVertexFromIndex(geom, 7) as IPoint;
  const text = vectorDist3D(dimBaseLineP1, dimBaseLineP2).toFixed(3);

  txtOpts.basePointH = dimStyle.basePointH; // textMultiPosTypeH.MIDDLE;
  txtOpts.basePointV = dimStyle.basePointV; // textMultiPosTypeV.MEDIAN;
  txtOpts.doubleSided = sdfDoubleSidedType.HOR;
  const label: THREE.Mesh = createText({ text }, txtOpts);
  const position = getAlignedTextPosition(label, geom, dimStyle);
  label.position.set(position.x, position.y, position.z);
  getAlignedTextRotation(label, geom, plane, dimStyle.fontRotation);

  if (dimStyle.fontRotation && dimBaseLineP1.z < dimBaseLineP2.z) {
    label.rotateZ(Math.PI);
  }
  const angle: number = lineAngle2p(dimBaseLineP1, dimBaseLineP2);
  if (plane === dimPlane.XY && dimStyle.fontRotation === 0 && (Math.PI * 0.5 < angle || angle <= -Math.PI * 0.5)) {
    label.rotateZ(Math.PI);
  }
  return label;
}
export function updateLinearDimLabelText(label: THREE.Mesh, dimGeometry: THREE.LineSegments, dimStyle: DimStyleBuilder, plane: dimPlane, opts: TextOptsBuilder): void {
  const dimBaseLineP1 = getVertexFromIndex(dimGeometry, 6) as IPoint;
  const dimBaseLineP2 = getVertexFromIndex(dimGeometry, 7) as IPoint;
  const text = vectorDist3D(dimBaseLineP1, dimBaseLineP2).toFixed(3);

  opts.basePointH = dimStyle.basePointH; // textMultiPosTypeH.MIDDLE;
  opts.basePointV = dimStyle.basePointV; // textMultiPosTypeV.MEDIAN;
  opts.doubleSided = sdfDoubleSidedType.HOR;
  modifyText(label, text, opts);
  const pos = getAlignedTextPosition(label, dimGeometry, dimStyle);
  label.position.set(pos.x, pos.y, pos.z);
  getAlignedTextRotation(label, dimGeometry, plane, dimStyle.fontRotation);

  if (dimStyle.fontRotation && dimBaseLineP1.z < dimBaseLineP2.z) {
    label.rotateZ(Math.PI);
  }
  const angle: number = lineAngle2p(dimBaseLineP1, dimBaseLineP2);
  if (plane === dimPlane.XY && dimStyle.fontRotation === 0 && (Math.PI * 0.5 < angle || angle <= -Math.PI * 0.5)) {
    label.rotateZ(Math.PI);
  }
}

function getAlignedTextPosition(label: THREE.Mesh, dimGeometry: THREE.LineSegments, dimStyle: DimStyleBuilder): IPoint {
  const dimBaseLineP1 = getVertexFromIndex(dimGeometry, 6) as IPoint;
  const dimBaseLineP2 = getVertexFromIndex(dimGeometry, 7) as IPoint;
  const distBaseLine = vectorDist3D(dimBaseLineP1, dimBaseLineP2);
  const factor = dimStyle.fontOffsetX / distBaseLine + 0.5;
  const BaseLinePto = getPointOnSegment(dimBaseLineP1, dimBaseLineP2, factor);
  const objP1 = getVertexFromIndex(dimGeometry, 8) as IPoint;
  const objP2 = getVertexFromIndex(dimGeometry, 9) as IPoint;
  const ObjPto = getPointOnSegment(objP1, objP2, factor);
  const min = (label.geometry.boundingBox as THREE.Box3).min;
  const max = (label.geometry.boundingBox as THREE.Box3).max;
  const offsetY = (max.y - min.y) * 0.5;
  return getPointOnSegment(ObjPto, BaseLinePto, 1 + (dimStyle.fontOffsetY + offsetY) / vectorDist3D(ObjPto, BaseLinePto));
}
function getAlignedTextRotation(label: THREE.Mesh | THREE.Line, dimGeometry: THREE.LineSegments, plane: dimPlane, auxRotation: number): void {
  const dimBaseLineP1 = getVertexFromIndex(dimGeometry, 6) as IPoint;
  const dimBaseLineP2 = getVertexFromIndex(dimGeometry, 7) as IPoint;

  label.rotation.set(0, 0, 0);
  if (vector2Equals(dimBaseLineP1, dimBaseLineP2)) {
    // Línea vertical
    // const slope = lineAngleVert2p(dimBaseLineP1, dimBaseLineP2);
    const slope: number = lineSlope2p(dimBaseLineP1, dimBaseLineP2);

    const dimBaseLineP3 = getVertexFromIndex(dimGeometry, 8) as IPoint;
    const angle = lineAngle2p(dimBaseLineP1, dimBaseLineP3);
    label.rotateZ(angle);
    label.rotateY(-slope);
    label.rotateX(Math.PI * 0.5);
    label.rotateZ(auxRotation);
  } else {
    // getRotationLine
    const angle: number = lineAngle2p(dimBaseLineP1, dimBaseLineP2);
    const slope: number = lineSlope2p(dimBaseLineP1, dimBaseLineP2);
    label.rotateZ(angle);
    label.rotateY(-slope);
    if (plane !== dimPlane.XY) label.rotateX(Math.PI * 0.5);
    label.rotateZ(auxRotation);
  }
}

// ARROWS

function getLinearDimArrows<T extends THREE.Mesh | THREE.Line>(geom: THREE.LineSegments, dimStyle: DimStyleBuilder, plane: dimPlane): [T, T] {
  const arrow1 = getArrowSymbol(dimStyle.arrowId) as T;
  const arrow2 = getArrowSymbol(dimStyle.arrowId) as T;
  updateLinearDimArrows(arrow1, arrow2, geom, plane);
  return [arrow1, arrow2];
}
export function updateLinearDimArrows(arrow1: THREE.Mesh | THREE.Line, arrow2: THREE.Mesh | THREE.Line, geom: THREE.LineSegments, plane: dimPlane): void {
  let pos = getVertexFromIndex(geom as THREE.Line, 6);
  if (pos) arrow1.position.set(pos.x, pos.y, pos.z);
  getAlignedTextRotation(arrow1, geom, plane, 0);

  pos = getVertexFromIndex(geom as THREE.Line, 7);
  if (pos) arrow2.position.set(pos.x, pos.y, pos.z);
  getAlignedTextRotation(arrow2, geom, plane, 0);
  arrow2.rotateZ(Math.PI);
}

