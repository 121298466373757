import { ellipseArcParam } from "lib/geometries/ellipse-arc";
import { GraphicProcessor } from "lib/graphic-processor";
import { ILineMaterial } from "lib/materials";
import { objDataType } from "lib/models/types";
import { EllipseArcData } from "lib/models/primitives/ellipse-arc";
import { cadOpType } from "lib/operations/factory";
import { createCommand } from "./create-base";

export class EllipseArcCommand extends createCommand {

  public createdData: EllipseArcData;
  protected opType = cadOpType.ELLIPSEARC;
  protected objType = objDataType.ELLIPSEARC;
  protected param: ellipseArcParam;
  protected material: ILineMaterial | undefined;

  constructor(params: ellipseArcParam, layerId: string, graphicProc: GraphicProcessor, material?: ILineMaterial) {
    super(layerId, graphicProc);
    this.param = params;
    this.material = material;
  }
}