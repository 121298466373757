import { solidParam } from "lib/models/solids/solid";
import * as THREE from "three";
import { setPosRotEsc } from "..";
import { getAuxMeshBasicMaterial } from "../../materials";
import { IPoint } from "../../math/types";

export interface cylinderParam extends solidParam {
    radius: number,
    height: number,
}

export function createBufferCylinder(radius: number, height: number) {
    const geom = new THREE.CylinderBufferGeometry(radius, radius, height, 32, undefined, false);
    geom.rotateX(Math.PI * 0.5);
    return geom;
}

export function createCylinder(radius: number, height: number, material?: THREE.Material, basePoint?: IPoint, rotation?: IPoint, offset?: IPoint, scale?: IPoint) {
    const geom = createBufferCylinder(radius, height);
    const mat = material ?? getAuxMeshBasicMaterial();
    const threeObj = new THREE.Mesh(geom, mat);
    if (basePoint && offset && rotation && scale) {
        setPosRotEsc(threeObj, basePoint, offset, rotation, scale);
    }
    return threeObj;
}