import { Storey } from "lib/models-struc/struc-storey";
import { eventBus } from "./event-bus";

export enum StoreyActionType { LOADSTOREYS, UPDATE_LEVEL, SETCURRENTSTOREY }
export type storeyAction = {
  type: StoreyActionType.UPDATE_LEVEL;
  payload: {
    storey: Storey,
    oldLevel: number,
  };
} | {
  type: StoreyActionType.SETCURRENTSTOREY;
  payload: {
    storey: Storey,
    storeys: Storey[],
  };
} | {
  type: StoreyActionType.LOADSTOREYS;
  payload: {
    storey: Storey,
    storeys: Storey[],
  };
};

type storeyActionCallback = (action: storeyAction) => void;

export const storeysEvents = {
  dispatch: (action: storeyAction) => {
    eventBus.dispatch("STOREY", action);
  },
  subscribe: (callback: storeyActionCallback) =>
    eventBus.subscribe("STOREY", callback),
  unsubscribe: (callback: storeyActionCallback) =>
    eventBus.unsubscribe("STOREY", callback),
};