import { CrossSectionRepresentation } from "modules/struc/models/ecore/representation";
import { Vector } from "modules/struc/models/ecore/location";
import { BuildingElement, MaterialRef } from "modules/struc/models/ecore/base";
import { baseUriModel } from "./mesh-exporter";
import { getEcorePlacement } from "./locations";
import { ICrossSectionShapeRef } from "modules/struc/models/ecore/cross-section-shape";
import { FEMStructuralElement } from "modules/struc/models/ecore/mesh";
import { IStrucElementData } from "lib/models/structural/structural";
import { representationUriModel } from "modules/struc/models/ecore/uri";

export interface IEcoreBuildingElementExport {
  setStrucElem(elem: IStrucElementData, crossSectionShapesIds?: string[]): void;
  exportToEcore(materialRef: number): BuildingElement;
  getEcoreDirection(): Vector;

  exportFemStructuralElementToEcore(storeyRef: number, elemRef: number): FEMStructuralElement;
}

export abstract class EcoreStrucElemBase implements IEcoreBuildingElementExport {

  public abstract setStrucElem(elem: IStrucElementData, crossSectionShapesIds?: string[]): void;
  public abstract exportToEcore(materialRef: number): BuildingElement;
  public abstract getEcoreDirection(): Vector;

  public abstract exportFemStructuralElementToEcore(storeyRef: number, elemRef: number): FEMStructuralElement;

  protected getEcoreMaterialRef(materialRef: number): MaterialRef {
    return {
      eClass: "http://www.example.org/buildingmodel#//resources/materials/Material",
      $ref: `//@materialset/@materials.${materialRef}`,
    }
  }

  protected getEcoreCrossSectionRepresentation(cssIndexRef: number): CrossSectionRepresentation {
    return {
      eClass: `${representationUriModel}CrossSectionRepresentation`,
      placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
      base: this.getEcoreCrossSectionRef(cssIndexRef),
    }
  }
  protected getEcoreCrossSectionRef(cssIndexRef: number): ICrossSectionShapeRef {
    return {
      eClass: baseUriModel + "resources/crosssectionshapes/CrossSectionShape",
      $ref: `//@versions.0/@crosssectionshapeset/@crosssectionshapes.${cssIndexRef}`,
    }
  }
}