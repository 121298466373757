import { updateWall } from "lib/geometries/structural/wall";
import { IPoint } from "lib/math/types";
import { cadOpType } from "../factory";
import { settingOp, settingsOpModes } from "../step-operations";
import { copyIPoint, normalizeIpoint, substractIpoint } from "lib/math/point";
import { IPolylineParam, iterPolylineEdges } from "lib/math/line";
import { createExtrusionMesh } from "lib/geometries/structural/extrusion";
import { WallCommand } from "lib/commands/structural/wall";
import { isBiggerThan, isSmallerEqThan, vector3Equals } from "lib/math/epsilon";
import { growthWidth, heightType, heightTypeNames, growthWidthTypeNames } from "../../models-struc/types/struc-base";
import { wallTypeNames, wallTypes } from "../../models-struc/types/wall";
import { StructElementBaseOP } from './structural';
import { dataInfoProperty } from "lib/properties/properties";
import { IObjData } from "lib/models/objdata";
import { isLineData, isPolygonData } from "lib/models/checktools";
import { userMessageEvents } from "lib/events/user-messages";
import { polygon } from "lib/math/polygon";
import { rotatePointZ } from "lib/math/rotate";
import { shellCrossSectionCache, currentShellCrossSectionId } from "lib/models-struc/cross-sections-shape/shell-cross-sections/cache";
import { isWallCSSParams } from "lib/models-struc/cross-sections-shape/shell-cross-sections/types";

export interface IWallSettings {
  wallType: wallTypes,
  shellCrossSectionId: string,
  widthGrowth: growthWidth,
  heightType: heightType,
  height: number,

  normals: IPoint[],
  points2D: IPolylineParam,
  basePoint: IPoint,
  rotation: IPoint,
  offset: IPoint,
  scale: IPoint,
}

abstract class WallOP extends StructElementBaseOP {

  public opType = cadOpType.WALL;

  protected abstract wallType: wallTypes;

  private shellCrossSectionId: string = currentShellCrossSectionId;
  protected thickness: number = 0.2;
  protected widthGrowth: growthWidth = growthWidth.CENTER;

  protected height: number = 0.2;
  protected heightType: heightType = heightType.STOREY;

  private lineBase: IPolylineParam = { arcs: [], isClosed: false, points: [] };
  private normals: IPoint[] = [];

  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Insert wall points: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
      panelProp: this.setPanelProperties(),
    }, {
      infoMsg: "Select Polyline",
      stepMode: settingsOpModes.SELECTOBJS,
      enableSelectMarks: false,
      multiSelect: false,
      filterFun: (obj: IObjData) => {
        if (!isLineData(obj) && !isPolygonData(obj)) {
          userMessageEvents.dispatchError("Select a polyline or polygon");
          return false;
        }
        return true;
      },
      endStepCallback: () => {
        if (isLineData(this.objDataOrigin[0])) {
          const lineData = this.objDataOrigin[0];
          this.basePoint = copyIPoint(lineData.definition.points[0]);
          this.lineBase.points = lineData.definition.points.map(p => substractIpoint(p, this.basePoint));
          this.lineBase.isClosed = lineData.definition.isClosed;
          super.endOperation();

        } else if (isPolygonData(this.objDataOrigin[0])) {
          const lineData = this.objDataOrigin[0];
          const { center, radius, sides, inscribed, angleO, plane } = lineData.definition;
          const points = polygon(center, radius, sides, inscribed, angleO, plane);
          points.push(copyIPoint(points[0]));
          this.basePoint = copyIPoint(points[0]);
          this.lineBase.points = points.map(p => substractIpoint(p, this.basePoint));
          this.lineBase.isClosed = true;
          super.endOperation();
        }
      },
      startStepCallback: (cfg: settingOp) => {
        this.unRegisterInputs();
        this.closeSnap();
        this.clearWorkingPlane();
        this.registerRaycast();
      },
    }]);
  }
  private setPanelProperties() {
    const wallCSSnameList: [string, string][] = [];
    const ShellCSSs = shellCrossSectionCache.getAllLoadedStyles().sort((a, b) => a.name.localeCompare(b.name));
    for (const shellCSS of ShellCSSs) {
      const param = shellCSS.parameters;
      if (isWallCSSParams(param)) {
        wallCSSnameList.push([shellCSS.name, shellCSS.styleId]);
      }
    }
    const wallTempl = shellCrossSectionCache.loadStylefromCache(this.shellCrossSectionId)!;
    this.thickness = wallTempl.thickness;
    const panelProp: dataInfoProperty<IWallSettings & { thickness: number, polyline: unknown }> = {
      wallType: {
        publicName: "Wall type",
        value: this.wallType,
        editable: true,
        type: "list",
        list: wallTypeNames,
      },
      shellCrossSectionId: {
        publicName: "Shell cross section",
        value: this.shellCrossSectionId,
        editable: true,
        type: "tagList",
        tagList: wallCSSnameList,
      },
      thickness: {
        publicName: "Tkickness",
        value: this.thickness,
        editable: false,
        precision: 3,
        type: "number",
        units: "m",
      },
      widthGrowth: {
        publicName: "Width grownth",
        value: this.widthGrowth,
        editable: true,
        type: "list",
        list: growthWidthTypeNames,
      },
      heightType: {
        publicName: "Height type",
        value: this.heightType,
        editable: true,
        type: "list",
        list: heightTypeNames
      },
      height: {
        publicName: this.heightType === heightType.LEVEL ? "Level" : "Height",
        value: this.height,
        editable: this.heightType === heightType.STOREY ? false : true,
        precision: 3,
        type: "number",
        units: "m",
      },
      polyline: {
        publicName: "Select polyline",
        type: "button",
        buttonCb: () => { this.setNextStep() }
      },
    }
    return {
      propValue: panelProp,
      propCallback: this.updatePanel.bind(this),
    };
  }
  public updatePanel(newWallProp: IWallSettings) {
    this.wallType = newWallProp.wallType;
    this.widthGrowth = newWallProp.widthGrowth;
    this.shellCrossSectionId = newWallProp.shellCrossSectionId;
    const wallCSS = shellCrossSectionCache.loadStylefromCache(this.shellCrossSectionId)!;
    this.thickness = wallCSS.thickness;

    this.heightType = newWallProp.heightType;
    if (this.heightType === heightType.STOREY) {
      this.height = this.getHeightFromStorey();
    } else {
      this.height = newWallProp.height !== undefined ? newWallProp.height : this.currentStorey.height;
    }
    this.updateAuxWall();

    this.settingsOpManager.currCfg = {
      ...this.settingsOpManager.currCfg,
      panelProp: this.setPanelProperties(),
    }
    this.settingsOpManager.dispatchUpdateCurrStep();
  }

  public async start() {
    await super.start();
    const ShellCSSs = shellCrossSectionCache.getAllLoadedStyles();
    const wallCSS = ShellCSSs.find(s => isWallCSSParams(s.parameters))!;
    this.shellCrossSectionId = wallCSS.styleId;
    this.thickness = wallCSS.thickness;
    this.height = this.currentStorey.height;
    this.iniSettingsOp();

    this.auxMesh = createExtrusionMesh([], this.height, []);
    this.saveToTempScene(this.auxMesh);
  }

  public setLastPoint(): void {
    const planeManager = this.graphicProcessor.getPlaneManager();
    if (this.numPoints === 1) {
      planeManager.activePlane.position = copyIPoint(this.lastPoint);
      planeManager.activePlane.locked = true;
    }
    const relPto = planeManager.activePlane.getRelativePoint(this.lastPoint);
    this.lineBase.points.push(relPto);
  }

  public moveLastPoint(pto: IPoint) {
    if (this.lineBase.points.length) {
      const planeManager = this.graphicProcessor.getPlaneManager();

      const relPto = planeManager.activePlane.getRelativePoint(pto)
      const currlineBase: IPolylineParam = {
        arcs: [],
        isClosed: vector3Equals(this.lineBase.points[0], relPto),
        points: this.lineBase.points.map(copyIPoint),
      }
      if (!currlineBase.isClosed) {
        currlineBase.points.push(relPto);
      }
      this.updateAuxWall(currlineBase);
    }
  }

  public updateAuxWall(currlineBase?: IPolylineParam) {
    const startLevel = this.basePoint.z;
    let currHeight: number = 0;
    switch (this.heightType) {
      case heightType.STOREY: currHeight = this.getHeightFromStorey(); break;
      case heightType.LEVEL: currHeight = startLevel - this.height; break;
      case heightType.LENGTH: currHeight = this.height; break;
      default: break;
    }
    updateWall(this.auxMesh, currlineBase ?? this.lineBase, currHeight, this.thickness, this.widthGrowth);
    const planeManager = this.graphicProcessor.getPlaneManager();
    const { position, rotation } = planeManager.activePlane;
    this.basePoint = position;
    this.rotation = rotation;
    this.auxMesh.position.set(position.x, position.y, position.z);
    this.auxMesh.rotation.set(rotation.x, rotation.y, rotation.z);
  }

  private getHeightFromStorey() {
    const bottomLevel = this.currentStorey.level - this.currentStorey.height;
    const topLevel = this.currentStorey.level;
    const z = this.basePoint.z;
    if (isBiggerThan(z, bottomLevel) && isSmallerEqThan(z, topLevel)) {
      return z - (this.currentStorey.level - this.currentStorey.height);
    } else {
      const strucMng = this.graphicProcessor.getStructuralModelManager();
      const storey = strucMng.currBuilding.getStoreyFromLevel(z);
      return z - (storey.level - storey.height);
    }
  }

  public save() {
    if (this.lineBase.points.length > 1) {

      const isClosed = vector3Equals(this.lineBase.points[0], this.lineBase.points[this.lineBase.points.length - 1]);
      if (isClosed) {
        this.lineBase.isClosed = true;
        this.lineBase.points.pop();
      }
      for (const edge of iterPolylineEdges(this.lineBase)) {
        const { p0, p1 } = edge;
        const xVec = normalizeIpoint(substractIpoint(p1, p0));
        const normal = rotatePointZ(xVec, -Math.PI * 0.5);
        this.normals.push(normal);
      }

      const startLevel = this.basePoint.z;
      switch (this.heightType) {
        case heightType.STOREY: this.height = this.getHeightFromStorey(); break;
        case heightType.LEVEL: this.height = startLevel - this.height; break;
        default: break;
      }

      const wallParam: IWallSettings = {
        wallType: this.wallType,
        shellCrossSectionId: this.shellCrossSectionId,
        widthGrowth: this.widthGrowth,
        heightType: this.heightType,
        height: this.height,

        points2D: this.lineBase,
        basePoint: this.basePoint,
        rotation: this.rotation,
        offset: this.offSet,
        scale: this.scale,
        normals: this.normals,
      }
      const command = new WallCommand(wallParam, this.graphicProcessor);
      this.graphicProcessor.storeAndExecute(command);
    }
  }

  public cancelOperation(): void {
    if (!this.finished) {
      this.endOperation();
    }
  }
}

export class ShearWallOP extends WallOP {
  protected wallType = wallTypes.SHEAR;

}
export class RetainingWallOP extends WallOP {
  protected wallType = wallTypes.RETAINING;
}
