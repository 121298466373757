import { useEffect, useCallback, useState, useMemo } from "react";
import { GraphicProcessor } from "lib/graphic-processor";
import { operationAction, OperationActionType } from "lib/events/operation";
import { coordModes, xyzModes } from "lib/operations/step-operations";
import { IPoint } from "lib/math/types";
import { substractIpoint } from "lib/math/point";
import { addIpoint, getPolarPoint } from '../../../../../lib/math/point';
import { vectorDist3D } from '../../../../../lib/math/distance';
import { lineAngle2p, normalizeAngle } from '../../../../../lib/math/angles';
import { radAngleToUser, userAngleToRad } from '../../../../../lib/general-settings';
import { EventManager } from "lib/operations/event-manager";

interface ICoordinatesPosition {
  xCoord: number; xLocked: boolean;
  yCoord: number; yLocked: boolean;
  zCoord: number; zLocked: boolean;
}
export type useCoordinatePositionType = ReturnType<typeof useCoordinatePosition>;

export function useCoordinatePosition(graphicProc: GraphicProcessor, xyzMode = xyzModes.ABS, lastPto?: IPoint) {

  const eventManager = useMemo(() => new EventManager(graphicProc), [graphicProc]);

  const [lastPoint, setLastPoint] = useState<IPoint | undefined>(lastPto);
  const [coordMode, setCoordMode] = useState<coordModes>(coordModes.XYZ);
  const [currXyzMode, setCurrXyzMode] = useState<xyzModes>(xyzMode);
  const [stateCoord, setStateCoord] = useState<ICoordinatesPosition>({
    xCoord: 0, xLocked: false,
    yCoord: 0, yLocked: false,
    zCoord: 0, zLocked: false,
  });

  const filterPoint = useCallback(() => {
    const absMousePto = graphicProc.getMouseCoordinates();
    if (coordMode === coordModes.XYZ) {
      if (currXyzMode === xyzModes.ABS || (currXyzMode === xyzModes.REL && !lastPoint)) {
        const x = stateCoord.xLocked ? stateCoord.xCoord : absMousePto.x;
        const y = stateCoord.yLocked ? stateCoord.yCoord : absMousePto.y;
        const z = stateCoord.zLocked ? stateCoord.zCoord : absMousePto.z;
        setStateCoord({ ...stateCoord, xCoord: x, yCoord: y, zCoord: z });
        return { x, y, z };
      } else {
        const lstPto = lastPoint ?? { x: 0, y: 0, z: 0 };
        const relPto = substractIpoint(absMousePto, lstPto);
        const x = stateCoord.xLocked ? stateCoord.xCoord : relPto.x;
        const y = stateCoord.yLocked ? stateCoord.yCoord : relPto.y;
        const z = stateCoord.zLocked ? stateCoord.zCoord : relPto.z;
        setStateCoord({ ...stateCoord, xCoord: x, yCoord: y, zCoord: z });
        const absPto = addIpoint(lstPto, { x, y, z });
        return { x: absPto.x, y: absPto.y, z: absPto.z };
      }
    } else {
      const lstPto = lastPoint ?? { x: 0, y: 0, z: 0 };
      const distance = vectorDist3D(absMousePto, lstPto);
      const angle = lineAngle2p(lstPto, absMousePto);
      const x = stateCoord.xLocked ? stateCoord.xCoord : distance;
      const y = stateCoord.yLocked ? userAngleToRad(stateCoord.yCoord) : normalizeAngle(angle);
      setStateCoord({ ...stateCoord, xCoord: x, yCoord: radAngleToUser(y) });
      const absPto = getPolarPoint(lstPto, y, x);
      return { x: absPto.x, y: absPto.y, z: absPto.z };
    }
  }, [coordMode, currXyzMode, graphicProc, lastPoint, stateCoord])

  const dispatchSetCoordinates = useCallback(() => {
    const absMousePto = filterPoint();
    setLastPoint({ x: absMousePto.x, y: absMousePto.y, z: absMousePto.z });
    const mouseResolver = graphicProc.getMouseResolver();
    mouseResolver.dispatchSaveCoordinate(absMousePto);
  }, [graphicProc, filterPoint]);

  // *****************************************************************

  const dispatchChangeCoordinates = useCallback(() => {
    const absMousePto = filterPoint();
    const mouseResolver = graphicProc.getMouseResolver();
    mouseResolver.dispatchChangeCoordinate(absMousePto);
  }, [graphicProc, filterPoint]);

  const canRegisterEvents = graphicProc.container!!
  useEffect(() => {
    if (canRegisterEvents) {
      eventManager.connectMouseMoveEvent(dispatchChangeCoordinates);
    }
    return () => {
      eventManager.disconnectMouseMoveEvent(dispatchChangeCoordinates);
    }
  }, [canRegisterEvents, dispatchChangeCoordinates, eventManager]);

  // *****************************************************************

  const stepCall = useCallback((action: operationAction) => {
    if (action.type === OperationActionType.SET_CURRENT_CONFIG) {
      setStateCoord({
        ...stateCoord,
        xLocked: false,
        yLocked: false,
        zLocked: false,
      });
    }
  }, [stateCoord])

  useEffect(() => {
    graphicProc.operationObserver.subscribe(stepCall);
    return () => graphicProc.operationObserver.unsubscribe(stepCall);
  }, [graphicProc.operationObserver, stepCall]);

  // *****************************************************************

  return {
    setLastPoint,
    stateCoord,
    setStateCoord,
    currXyzMode,
    setCurrXyzMode,
    coordMode,
    setCoordMode,
    dispatchSetCoordinates,
  };
}
