import { useCallback } from "react";

function getFaviconEl() {
  return document.getElementById("favicon") as HTMLAnchorElement;
}

export function useFavicon() {

  const setRunning = useCallback(() => {
    const favicon = getFaviconEl(); // Accessing favicon element
    favicon.href = "/running.ico";
  }, []);

  const setDefault = useCallback(() => {
    const favicon = getFaviconEl();
    favicon.href = "/favicon.ico";
  }, []);

  return {
    setRunning,
    setDefault,
  };
}
