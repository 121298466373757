import { useMutation } from "@apollo/client";
import { CADProject } from "modules/cad/models/projects";
import {
  GET_CAD_PROJECTS_BY_USER,
  RENAME_CAD_PROJECT,
} from "modules/cad/services/projects";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useUI } from "shared/components/ui/context";
import { GetProjectsData } from "./use-projects";

type Status = "idle" | "loading" | "success" | "error";

interface Data {
  project: CADProject;
}

interface Params {
  id: string;
  name: string;
}
interface FormData {
  name: string;
}
export function useRenameProject() {
  const { openToast, closeModal, modalData } = useUI();
  const [status, setStatus] = useState<Status>("idle");
  const { handleSubmit, register, errors } = useForm<FormData>({
    shouldFocusError: true,
    mode: "onTouched",
    defaultValues: {
      name: modalData?.name || "",
    },
  });
  const [mutate] = useMutation<Data, Params>(RENAME_CAD_PROJECT, {
    update(cache, { data }) {
      if (data) {
        const query = cache.readQuery<GetProjectsData>({
          query: GET_CAD_PROJECTS_BY_USER,
        });
        if (query) {
          cache.writeQuery({
            query: GET_CAD_PROJECTS_BY_USER,
            data: {
              projects: query.projects.map((project) =>
                project.id === data.project.id ? data.project : project
              ),
            },
          });
        }
      }
    },
  });

  const renameFile = useCallback(
    ({ name }: FormData) => {
      mutate({
        variables: { id: modalData?.id, name: name },
      })
        .then(() => {
          openToast({
            title: "Project successfully renamed",
            status: "success",
          });
          setStatus("success");
        })
        .catch(() => {
          openToast({
            title: "Error renaming project",
            status: "error",
          });
          setStatus("error");
        })
        .finally(() => {
          closeModal();
        });
    },
    [closeModal, mutate, openToast, modalData]
  );

  return {
    confirmAction: handleSubmit(renameFile),
    cancelAction: closeModal,
    status,
    register,
    errors,
  };
}
