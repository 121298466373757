import { ViewportAction } from "lib/events/viewports";
import { GraphicViewport } from "lib/graphic-viewport";
import { IPoint } from "lib/math/types";

export class RectangleSelectionHelper {

  private styleLeftRect: HTMLStyleElement;
  private styleRightRect: HTMLStyleElement;

  private rectElement: HTMLDivElement;

  private startPoint: IPoint = { x: 0, y: 0, z: 0 };
  private pointTopLeft: IPoint = { x: 0, y: 0, z: 0 };
  private pointBottomRight: IPoint = { x: 0, y: 0, z: 0 };

  private newViewport: HTMLElement | undefined;
  private currContainer: HTMLElement;

  public isLeft: boolean = false;
  public isSelecting: boolean = false;

  constructor(viewport: GraphicViewport) {
    this.styleLeftRect = this.createRectangleStyle(`.selectBoxLeft {
      border: 2px dashed #55aaff;
      background-color: rgba(75, 160, 255, 0.2);
      position: fixed;
    }`);
    this.styleRightRect = this.createRectangleStyle(`.selectBoxRight {
      border: 2px solid #55aaff;
      background-color: rgba(75, 160, 255, 0.2);
      position: fixed;
    }`);

    this.rectElement = document.createElement('div');
    this.rectElement.classList.add("selectBoxLeft");
    this.rectElement.style.pointerEvents = 'none';

    this.currContainer = viewport.elemHTML as HTMLElement;
  }
  public unregister() {
    document.getElementsByTagName('head')[0].removeChild(this.styleLeftRect);
    document.getElementsByTagName('head')[0].removeChild(this.styleRightRect);
    if (this.currContainer === this.rectElement.parentElement) {
      this.currContainer.removeChild(this.rectElement);
    }
  }

  private createRectangleStyle(cssProp: string) {
    const styleElem = document.createElement('style');
    styleElem.type = 'text/css';
    styleElem.innerHTML = cssProp;
    document.getElementsByTagName('head')[0].appendChild(styleElem);
    return styleElem;
  }

  public onChangeViewport = (action: ViewportAction) => {
    this.newViewport = action.payload.viewport.elemHTML as HTMLElement;
    if (!this.isSelecting) {
      this.currContainer = this.newViewport;
      this.newViewport = undefined;
    }
  }
  public onMouseDown = (event: PointerEvent) => {
    this.isSelecting = true;
    this.rectElement.style.left = event.clientX + 'px';
    this.rectElement.style.top = event.clientY + 'px';
    this.rectElement.style.width = '0px';
    this.rectElement.style.height = '0px';
    this.startPoint.x = event.clientX;
    this.startPoint.y = event.clientY;
  }
  public onMouseMove = (event: PointerEvent) => {
    if (this.isSelecting) {
      if (this.rectElement.parentElement !== this.currContainer) {
        this.currContainer.appendChild(this.rectElement);
      }
      this.pointBottomRight.x = Math.max(this.startPoint.x, event.clientX);
      this.pointBottomRight.y = Math.max(this.startPoint.y, event.clientY);
      this.pointTopLeft.x = Math.min(this.startPoint.x, event.clientX);
      this.pointTopLeft.y = Math.min(this.startPoint.y, event.clientY);

      if (this.pointTopLeft.x < this.startPoint.x) {
        this.rectElement.classList.remove("selectBoxRight");
        this.isLeft = true;
      } else {
        this.rectElement.classList.add("selectBoxRight");
        this.isLeft = false;
      }
      this.rectElement.style.left = this.pointTopLeft.x + 'px';
      this.rectElement.style.top = this.pointTopLeft.y + 'px';
      this.rectElement.style.width = (this.pointBottomRight.x - this.pointTopLeft.x) + 'px';
      this.rectElement.style.height = (this.pointBottomRight.y - this.pointTopLeft.y) + 'px';
    }
  }
  public onMouseUp = (event: PointerEvent) => {
    this.isSelecting = false;
    if (this.currContainer === this.rectElement.parentElement) {
      this.currContainer.removeChild(this.rectElement);
    }
    if (this.newViewport !== undefined) {
      this.currContainer = this.newViewport;
      this.newViewport = undefined;
    }
  }
}
