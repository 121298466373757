/**
 * \file structmodel3d.ts
 * Implementacion de la clase StructModel3D donde tenemos todo el objeto grafico a representar, formado por nodes, beams
 * y cells que pueden a su vez ser triangles o quads.
 */
import { GraphicProcessor } from 'lib/graphic-processor';
import * as THREE from 'three';
import { createText } from 'lib/text/builder';
import { textParam } from 'lib/models/text';
import { TextOptsBuilder, textMultiPosTypeH, textMultiPosTypeV, sdfDoubleSidedType } from 'lib/text/styles';
// Descomentar para salvador de JSON con mesh.
import { saveFile } from 'lib/apis/utils';

import { displacementList } from 'lib/models-struc/hypothesis/hypothesis';
// Look-Up-Table para la paleta de colores. Posiblemente no la necesite...
import { Lut } from 'three/examples/jsm/math/Lut.js';

// FALLIDO DE MOMENTO.
// Intentamos probar si se pueden cargar ficheros HDF5 desde JS/TS para lo que usamos la libreria jsfive, que parece la
// mas conocida y medio madura. El comando es "npm install jsfive --save-dev" pero la putada es que NO es codigo TS y no
// tiene el fichero "".d.ts" asociado para ser incorporable a TS. Pasamos de el y usamos el h5wasm que tambien va mal...
// import * as hdf5 from "h5wasm";

// Para usar lineas gordas en las vigas.
import { LineSegments2 } from 'three/examples/jsm/lines/LineSegments2';
import { LineSegmentsGeometry } from 'three/examples/jsm/lines/LineSegmentsGeometry';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { WindCalculer } from 'lib/models-struc/hypothesis/windcalculer';

// Para simplificar sintaxis usamos LOCALMENTE este tipo para un punto 3D. Ojo que es de uso interno a este modulo, por
// lo que no se debe exportar, aunque probablemente lo repita en otros modulos externos.
type P3D = [number, number, number];

// Tipo del objeto que contiene todos los datos de ESFUERZOS Y MOMENTOS.
type Data4ForcesAndMoments = {
    // Vector lineal con los datos de F&M, que son distintos segun el tipo de informacion considerada:
    // Beams F&M:  [2 + 6] ===> [iELEMENT, iNODE] + [N, VY, VZ, MT, MFY, MFZ].
    // Shells F&M: [2 + 8] ===> [iELEMENT, iNODE] + [NXX, NYY, NXY, MXX, MYY, MXY, QX, QY].
    // Nodal F&M: ?????.
    // En todo caso guardo en el array en primera posicion esos 2 indices para localizar al responsable...        
    vData: number[];
    // La dimension de cada tupla de datos, actualmente 8 o 10, indices incluidos.
    dim: number;
    // El numero de tuplas cargadas.
    numTuples: number;
    // El tipo de datos cargados: "None", "Beams", "Shells" y "Nodal" (cuando los haiga...).
    typeData: "None" | "Beams" | "Shells" | "Nodal";
    // Los titulos de las columnas originales, sacadas del fichero, por si fueran necesarias.
    vCols: string[];
    // Los rangos [min, max] de valores encontrados para cada una de las columnas sensibles. Las columnas que no
    // contengan informacion las dejamos con [+Infinity, -Infinity].
    vRngs: [number, number][];
    // Mapa para traducir los indices de los elementos implicados en los datos a los indices de los elementos originales del meshing.
    mapNew2SrcElems: Map<number, number>;
};

/**
 * Informacion que recopilaremos de cada planta.
 */
type StoreyInfo = {
    name: string;
    elevation: number;
    // La altura real de cada planta, que rellenaremos tras calcular los COG por planta.
    z: number;
    // El vector Center Of Gravity de la planta, separado en sus diferentes slabs (flat o waffle) en este array.
    // AHORA ES 3D!!!. Ademas se tendran en cuenta los huecos presentes en el slab para su calculo...
    vCOG: THREE.Vector3[];
    // Informacion sobre el elemento shell (posicion del quad/tri) dentro del cual esta contenido el punto COG.
    // Son 2 indices, el primero la posicion del elemento shell dentro del vector de indices pertinente y el segundo es
    // la aridad del shell (3 o 4 segun sea tri o quad). Van en array por la posibilidad de multiples forjados.
    // En caso de error tendremos [-1, 0].
    vShellPos4COG: [number, number][];
    // Contador de los forjados encontrados en la planta.
    cntSlabs: number;
    // Mapas de los indices de cada tipo de elemento indexados por indice de slab.
    mIndices4Nodes: Map<number, number[]>;
    mIndices4Beams: Map<number, number[]>;
    mIndices4Tris: Map<number, number[]>;
    mIndices4Quads: Map<number, number[]>;
    mIndices4SlabsNames: Map<number, string>;
};

export class StructModel3D {
    // BA con los puntos 3D o nodos dados inicialmente. Ademas retiene los valores originales que necesitare conservar
    // para aplicar las deformaciones, ya que en el buffer se hace una copia profunda de estos.
    nodes_: Float32Array;
    numNodes_: number;

    // BA con los puntos de integracion, que son puntos 3D asociados a beams/tris/quads y contenidos en su innerVolume.
    // Ojo, que a los IP los guardo como puntos 3D que es lo que finalmente representare.
    iPoints_: Float32Array;
    numIPoints_: number;
    // Junto con los puntos 3D guardo los tercetos con los indices de los shells, el offset de los IP y su aridad.
    vIPIOAs_: [number, number, number][];
    
    // Los siguentes DMC's son [V]ectores de [I]ndices (enteros), referidos al vector de nodos/points anterior.
    // Ojo, que los puntos 3D seran NODES y las lines BEAMS.
    beamsIV_: number[];
    numBeams_: number;
    
    trisIV_: number[];
    numTris_: number;
    
    quadsIV_: number[];
    numQuads_: number;

    /*
        Para almacenar los distintos tipos de elementos (beam[2], tri[3], quad[4]) presentes en el modelo.
        Es el centro de la topologia de los elementos finitos, ya que da el acceso a los nodos desde los elementos.
        La clave es el id (unico) y los valores son un par de la forma [type234, offset4Nodes], donde el id lo sacamos
        del json y type234 es el tipo del elemento considerado (2: beam, 3: triangle y 4: quad, en funcion de su numero
        de nodos). Finalmente offset es la posicion dentro del array final donde estan los nodos que lo componen, que
        es beamsIV_ para beams, trisIV_ para los triangles y para los quads es quadsIV_. Ademas el type234 nos dice el
        numero de nodes que componen al susodicho elemento. Recuerda que esta todo en forma lineal en los *IV_, luego
        con el offset y el numero de nodos lo tenemos todo. Para acceder a los nodos de un elemento dado usar la fmc
        getNodes4ElementI().
    */
    mElems_: Map<number, [number, number]>;

    // Vector lineal con los datos de las deformaciones, que son 6 por el numero de nodos.
    // Esos 6 datos son DX,DY,DZ,DRX,DRY,DR.
    vDeformations_: number[];
    numDeformations_: number;
    // El tipo de deformacion en curso, sacado de displacementList. Por defecto es la XYZ.
    deformationType_: string;

    // Para saber cual fue el ultimo coeficiente del slider dado previamente. Lo usamos al cambiar el modo de deformacion.
    lastCoeffF_: number;

    // DATOS DE ESFUERZOS Y MOMENTOS:
    // Meto todos los datos de F&M en este objeto, de nombre objFaMs_: (obj)ect (4)for (F)orces (a)nd (M)oment(s).
    obj4FaMs_: Data4ForcesAndMoments;

    gObj_: THREE.Group;

    // El volumen envolvente de todos los puntos/nodes y por ende de todo el modelo de malla grafica.
    aabb_: THREE.Box3;
    // Para saber si hemos invertido los planos de clipping en X, Y y Z.
    negated_: boolean[];

    // Centralizo aqui toda la gestion de colores, en la paleta en curso, que es el objeto que lidia con los colores.
    // Tendremos aqui una paleta "default", otra "dxyz", etc... pero siempre almacenadas aqui y accesibles mediante un
    // simple nombre unico.
    paletteLut_: Lut;

    // Flag temporal auxiliar para pruebas de stress.
    runStress_: boolean;
    // Repeticiones de la malla en X.
    numXStress_: number;
    // Repeticiones de la malla en Y.
    numYStress_: number;

    // Para los mensajes con letras orientables en 3D necesitamos esta fuente.
    currentFont_: THREE.Font;

    // Mapa de los pisos, con informacion sobre centros de gravedad, etc...
    // Aqui esta todo lo necesario para el calculo de los desplomes.
    mStoreys_: Map<number, StoreyInfo>;

    owner_: GraphicProcessor | null;

    // Los nombres de los objetos graficos para tenerlos localizados en la escena. Obviamente son unicos.
    public static readonly name4Nodes = "nodes";
    public static readonly name4IPoints = "i_points";
    public static readonly name4Beams = "beams";
    public static readonly name4FatBeams = "fat_beams";
    public static readonly name4Quads = "cells_quads";
    public static readonly name4Tris = "cells_tris";
    public static readonly name4QuadsEdges = "edges_quads";
    public static readonly name4TrisEdges = "edges_tris";
    public static readonly name4NodesMaterialAsPoints = "nodes_points";
    public static readonly name4NodesMaterialAsBalls = "nodes_balls";
    public static readonly name4COGs = "COGs";
    // Identificadores de los objetos graficos con los INDICES para nodes, shells y beams.
    public static readonly name4NodesIds = "nodes_ids";
    public static readonly name4ShellsIds = "shells_ids";
    public static readonly name4BeamsIds = "beams_ids";
    public static readonly name4Palette = "palette";
    public static readonly name4Label = "label";

    constructor() {
        this.numNodes_ = this.numIPoints_ = this.numBeams_ = this.numTris_ = this.numQuads_ = 0;
        this.nodes_ = null as unknown as Float32Array;
        this.iPoints_ = null as unknown as Float32Array;
        this.vIPIOAs_ = null as unknown as [number, number, number][];

        this.beamsIV_ = this.trisIV_ = this.quadsIV_ = null as unknown as number[];
        this.mElems_ = null as unknown as Map<number, [number, number]>;
        this.vDeformations_ = null as unknown as number[];
        this.numDeformations_ = 0;
        this.deformationType_ = displacementList[6];

        this.obj4FaMs_ = {
            vData: [],
            dim: 0,
            numTuples: 0,
            typeData: "None",
            vCols: [],
            vRngs: [],
            mapNew2SrcElems: new Map<number, number>(),
        };

        this.lastCoeffF_ = 0;
        this.gObj_ = null as unknown as THREE.Group;
        this.negated_ = [false, false, false];
        this.paletteLut_ = new Lut();
        // Pruebas de estres.
        // Activacion del modo de stress..
        this.runStress_ = false;
        // Repeticiones de la malla en X.
        this.numXStress_ = 50;
        // Repeticiones de la malla en Y.
        this.numYStress_ = 50;

        /*
            Con los datos de estres de 50x * 50y se replica la malla original de meshing demo multiplicandola un total
            de 50 * 50 = 2500 veces. Eso nos da 11545000 nodos con 322500 beams + 9162500 quads + 650000 triangles.
            Mi NVidia GForce GTX-960 lo mueve a unos 8-10 FPS y se consumen unos 1760 MB de los 4 GB disponibles en JS.
            Con la nueva 1080-Ti se mueve a unos 20-30 FPS.
        */

        this.mStoreys_ = new Map<number, StoreyInfo>();

        this.owner_ = null;
    }

    /// Propiedades RO.
    get numNodes(): number { return this.numNodes_; }
    get numIntegrationPoints(): number { return this.numIPoints_; }
    get numTriangles(): number { return this.numTris_; }
    get numQuads(): number { return this.numQuads_; }

    set owner(graPro: GraphicProcessor) {
        this.owner_ = graPro;
    }

    /**
     * Llamese a este destructor primero y luego se tiene que eliminar al objeto grafico en cuyo userData se oculta
     * este modelo, de forma que se eviten posibles referencias ciclicas.
     */
    destroy() {        
        this.numNodes_ = this.numBeams_ = this.numTris_ = this.numQuads_ = 0;
        this.nodes_ = null as unknown as Float32Array;
        this.iPoints_ = null as unknown as Float32Array;
        if (this.vIPIOAs_) {
            this.vIPIOAs_.length = 0;
            this.vIPIOAs_ = null as unknown as [number, number, number][];
        }
        if (this.beamsIV_) {
            this.beamsIV_.length = 0;
            this.beamsIV_ = null as unknown as number[];
        }
        if (this.trisIV_) {
            this.trisIV_.length = 0;
            this.trisIV_ = null as unknown as number[];
        }
        if (this.quadsIV_) {
            this.quadsIV_.length = 0;
            this.quadsIV_ = null as unknown as number[];
        }
        if (this.mElems_) {
            this.mElems_.clear();
            this.mElems_ = null as unknown as Map<number, [number, number]>;
        }
        
        if (this.vDeformations_) {
            this.vDeformations_.length = 0;
            this.vDeformations_ = null as unknown as number[];
            this.numDeformations_ = 0;
        }
        this.deformationType_ = displacementList[6];
        this.lastCoeffF_ = 0;

        this.obj4FaMs_.vData.length = 0;
        this.obj4FaMs_.dim = 0;
        this.obj4FaMs_.numTuples = 0;
        this.obj4FaMs_.typeData = "None";
        this.obj4FaMs_.vCols.length = 0;
        this.obj4FaMs_.vRngs.length = 0;
    
        // Esto es importante para evitar autoreferencias ciclicas que podrian impedir la destruccion.
        this.gObj_ = null as unknown as THREE.Group;

        this.aabb_ = null as unknown as THREE.Box3;

        this.paletteLut_ = null as unknown as Lut;

        this.mStoreys_.clear();

    }

    setNodes(nodesBA: Float32Array): boolean {
        if (this.numNodes_ === 0) {
            this.nodes_ = nodesBA;
            this.numNodes_ = nodesBA.length / 3;
            return this.numNodes_ > 0;
        }
        return false;
    }

    getNodes(): Float32Array {
        return this.nodes_;
    }

    /**
     * Dado el indice de un elemento (beam|tri|quad) nos devuelve una tupla con los indices de sus nodos integrantes, o
     * una tupla vacia en caso de que el elemento no exista en nuestra topologia actual.
     * @param elemI 
     * @returns 
     */
    getNodes4ElementI(elemI: number): [number, number] | [number, number, number] | [number, number, number, number] | [] {
        if (this.mElems_.has(elemI)) {
            const [type234, offset4Nodes] = this.mElems_.get(elemI) as [number, number];            
            if (type234 === 4) {
                const src4Nodes = this.quadsIV_;
                // Sacamos los indices de los 4 nodos implicados.
                const iN0 = src4Nodes[offset4Nodes];
                const iN1 = src4Nodes[offset4Nodes + 1];
                const iN2 = src4Nodes[offset4Nodes + 2];
                const iN3 = src4Nodes[offset4Nodes + 3];
                return [iN0, iN1, iN2, iN3] as [number, number, number, number];
            } else {
                if (type234 === 3) {
                    const src4Nodes = this.trisIV_;
                    const iN0 = src4Nodes[offset4Nodes];
                    const iN1 = src4Nodes[offset4Nodes + 1];
                    const iN2 = src4Nodes[offset4Nodes + 2];
                    return [iN0, iN1, iN2] as [number, number, number];
                } else {
                    const src4Nodes = this.beamsIV_;
                    const iN0 = src4Nodes[offset4Nodes];
                    const iN1 = src4Nodes[offset4Nodes + 1];
                    return [iN0, iN1] as [number, number];
                }
            }
        } else {
            return [];
        }
    }

    /**
     * Devuelve un array con los indices de los 3 nodos del triangulo en la posicion dada, o un array vacio en caso de
     * error.
     * @param pos 
     * @returns 
     */
    getTriangleN(pos: number): [number, number, number] | [] {
        if (0 <= pos && pos < this.numTris_) {
            const src4Nodes = this.trisIV_;
            const offset4Nodes = 3 * pos;
            const iN0 = src4Nodes[offset4Nodes];
            const iN1 = src4Nodes[offset4Nodes + 1];
            const iN2 = src4Nodes[offset4Nodes + 2];
            return [iN0, iN1, iN2] as [number, number, number];
        }
        return [];
    }

    /**
     * Devuelve un array con los indices de los 4 nodos del quad en la posicion dada, o un array vacio en caso de
     * error.
     * @param pos 
     * @returns 
     */
     getQuadN(pos: number): [number, number, number, number] | [] {
        if (0 <= pos && pos < this.numQuads_) {
            const src4Nodes = this.quadsIV_;
            const offset4Nodes = 4 * pos;
            const iN0 = src4Nodes[offset4Nodes];
            const iN1 = src4Nodes[offset4Nodes + 1];
            const iN2 = src4Nodes[offset4Nodes + 2];
            const iN3 = src4Nodes[offset4Nodes + 3];
            return [iN0, iN1, iN2, iN3] as [number, number, number, number];
        }
        return [];
    }

    /**
     * De manera inversa a la fmc getNodes4ElemenI() esta fmc nos devuelve una vector numerico con los indices de los
     * elementos que contienen al nodo dado, ya sean beams|tris|quads. En caso de que el nodo dado sea "huerfano" se
     * devuelve el vector vacio, lo que deberia ser indicativo de error.
     * Recuerdese que un mismo nodo puede estar contenido en un monton de elementos de la misma o diferente naturaleza.
     * @param nodeI 
     * @returns 
     */
    getElements4NodeI(nodeI: number): number[] {
        const vElementsJ: number[] = [];

        // Lo mas rapido que se me ocurre, recorrer el mapa con la topologia, acumulando los elementos contenedores.
        for (const [iElemJ, [type234, offset4Nodes]] of this.mElems_) {
            if (type234 === 4) {
                const src4Nodes = this.quadsIV_;
                // Sacamos los indices de los 4 nodos implicados.
                const iN0 = src4Nodes[offset4Nodes];
                if (iN0 === nodeI) {
                    vElementsJ.push(iElemJ);
                    continue;
                }
                const iN1 = src4Nodes[offset4Nodes + 1];
                if (iN1 === nodeI) {
                    vElementsJ.push(iElemJ);
                    continue;
                }
                const iN2 = src4Nodes[offset4Nodes + 2];
                if (iN2 === nodeI) {
                    vElementsJ.push(iElemJ);
                    continue;
                }
                const iN3 = src4Nodes[offset4Nodes + 3];
                if (iN3 === nodeI) {
                    vElementsJ.push(iElemJ);
                    continue;
                }
            } else {
                if (type234 === 3) {
                    const src4Nodes = this.trisIV_;
                    const iN0 = src4Nodes[offset4Nodes];
                    if (iN0 === nodeI) {
                        vElementsJ.push(iElemJ);
                        continue;
                    }
                    const iN1 = src4Nodes[offset4Nodes + 1];
                    if (iN1 === nodeI) {
                        vElementsJ.push(iElemJ);
                        continue;
                    }
                    const iN2 = src4Nodes[offset4Nodes + 2];
                    if (iN2 === nodeI) {
                        vElementsJ.push(iElemJ);
                        continue;
                    }
                } else {
                    const src4Nodes = this.beamsIV_;
                    const iN0 = src4Nodes[offset4Nodes];
                    if (iN0 === nodeI) {
                        vElementsJ.push(iElemJ);
                        continue;
                    }
                    const iN1 = src4Nodes[offset4Nodes + 1];
                    if (iN1 === nodeI) {
                        vElementsJ.push(iElemJ);
                        continue;
                    }
                }
            }
        }
        return vElementsJ;
    }

    /**
     * Devuelve un array con todos los id's de los elementos beams almacenados en el modelo, posiblemente ordenados.
     * @param sorted 
     * @returns 
     */
    getIds4AllBeamElements(sorted = false): number[] {
        const vElementsJ: number[] = [];
        for (const [iElemJ, [type234, offset4Nodes]] of this.mElems_) {
            if (type234 === 2) {
                vElementsJ.push(iElemJ);
            }
        }

        if (sorted) {
            // Nunca uses sort() sin argumento para ordenar numeros!!!.
            vElementsJ.sort((a, b) => (a - b));
        }

        return vElementsJ;
    }

    /**
     * Devuelve un array con todos los id's de los elementos tris almacenados en el modelo, posiblemente ordenados.
     * @param sorted 
     * @returns 
     */
    getIds4AllTriangleElements(sorted = false): number[] {
        const vElementsJ: number[] = [];
        for (const [iElemJ, [type234, offset4Nodes]] of this.mElems_) {
            if (type234 === 3) {
                vElementsJ.push(iElemJ);
            }
        }

        if (sorted) {
            vElementsJ.sort((a, b) => (a - b));
        }
        return vElementsJ;
    }

    /**
     * Devuelve un array con todos los id's de los elementos quads  en el modelo, posiblemente ordenados.
     * @param sorted 
     * @returns 
     */
     getIds4AllQuadElements(sorted = false): number[] {
        const vElementsJ: number[] = [];
        for (const [iElemJ, [type234, offset4Nodes]] of this.mElems_) {
            if (type234 === 4) {
                vElementsJ.push(iElemJ);
            }
        }
        if (sorted) {
            vElementsJ.sort((a, b) => (a - b));
        }
        return vElementsJ;
    }

    /**
     * Devuelve un array con todos los id's de los elementos shell (tris|quads) del modelo, posiblemente ordenados.
     * @param sorted 
     * @returns 
     */
     getIds4AllShellElements(sorted = false): number[] {
        const vElementsJ: number[] = [];
        for (const [iElemJ, [type234, offset4Nodes]] of this.mElems_) {
            if (type234 > 2) {
                vElementsJ.push(iElemJ);
            }
        }
        if (sorted) {
            vElementsJ.sort((a, b) => (a - b));
        }
        return vElementsJ;
    }
    
    setIntegrationPoints(integrationPointsBA: Float32Array, vIOA: [number, number, number][]): boolean {
        if (this.numIPoints_ === 0) {
            this.iPoints_ = integrationPointsBA;
            this.numIPoints_ = integrationPointsBA.length / 3;
            this.vIPIOAs_ = vIOA;
            return this.numIPoints_ > 0;
        }
        return false;
    }

    getIntegrationPoints(): Float32Array {
        return this.iPoints_;
    }

    /**
     * Te devuelve la secuencia de puntos de integracion para los datos indice, offset y cuenta sacados de nuestros
     * contadores miembros en this.vIPIOAs_. Ojo, que en caso de error se devuelve un array vacio.
     *
     * @param offset 
     * @param cnt 
     * @returns 
     */
    private getIntegrationPointsI(offset: number, cnt: number): THREE.Vector3[] {
        const vRes: THREE.Vector3[] = [];
        if (0 <= offset && offset < this.numIPoints_) {
            for (let i = 0; i < cnt; ++i) {
                const pos = offset + i;
                const x = this.iPoints_[3 * pos];
                const y = this.iPoints_[3 * pos + 1];
                const z = this.iPoints_[3 * pos + 2];
                vRes.push(new THREE.Vector3(x, y, z));
            }
        }

        return vRes;
    }

    setDeformationType(type: string): boolean {
        if (!this.numDeformations_ || this.gObj_ === null) {
            console.error("No se pueden aplicar las deformaciones.");
            return false;
        }

        if (type === this.deformationType_) {
            // Si no cambia, no tiene sentido...
            return true;
        } else {
            if (-1 === displacementList.indexOf(type)) {
                return false;
            }
        }
        this.deformationType_ = type;
        console.log("DEFORMATION TYPE: " + type);
        // ...pero si el tipo ha cambiado y el factor que aplicamos (el del slider) no es 0, entonces habria que
        // reaplicarlo para que se regenere la deformacion que vemos con los valores adecuados.
        // Ojo: Tenemos ademas el problema de que al andar jugando con el slider se pueden acumular valores que hacen
        // que al volver a poner el slider en 0 el modelo este distorsionado respecto a la version original.
        // Solucion: Resetear los puntos graficos para que tengan los valores iniciales de los nodos dados.
        this.resetNodes();
        this.applyDeformationsCoeff(this.lastCoeffF_);

        return true;
    }

    getDeformationType(): string {
        return this.deformationType_;
    }

    // Para fijar las deformaciones empleadas, que deben coincidir con el numero de nodos.
    // \Warning: Se apropia del parametro dado, que es una referencia y no le hace deep-copy para ahorrar memoria, asi
    // que tengase esto en cuenta para no cagarla...
    setDeformations(deformations: number[]): boolean {
        // Siempre borramos lo que hubiera.
        if (this.vDeformations_) {
            this.vDeformations_.length = 0;
            this.vDeformations_ = null as unknown as number[];
            this.numDeformations_ = 0;
        }

        // Debe haber puntos asignados!!!.
        if (this.numNodes_ === 0) {
            return false;
        }
        if (this.numDeformations_ === 0) {
            this.vDeformations_ = deformations;
            this.numDeformations_ = deformations.length / 6;
            if (this.numDeformations_ !== this.numNodes_) {
                window.alert(`ERROR: Numero de deformaciones (${this.numDeformations_}) y nodos (${this.numNodes_}) no coincidentes.`);
                // De momento, para ir palante, vamos a adaptar las deformaciones recibidas a los nodos existentes.
                if (this.numDeformations_ > this.numNodes_) {
                    window.alert("WARNING DE ÑAPA: Recortamos deformaciones para que casen...");
                    // Mas deformaciones que nodos: suprimimos las restantes...
                    while (this.numDeformations_ > this.numNodes_) {
                        // Quitamos una fila de deformaciones.
                        deformations.pop(); deformations.pop(); deformations.pop();
                        deformations.pop(); deformations.pop(); deformations.pop();
                        --this.numDeformations_;
                    }
                } else {
                    // Menos deformaciones que nodos: Repetimos la ultima fila hasta completar el numero de nodos.
                    window.alert("WARNING DE ÑAPA: Ampliamos deformaciones para que casen...");
                    const lastPos = 6 * (this.numDeformations_ - 1);
                    let lastValues = [
                        deformations[lastPos], deformations[lastPos + 1], deformations[lastPos + 2],
                        deformations[lastPos + 3], deformations[lastPos + 4], deformations[lastPos + 5]
                    ];
                    while (this.numDeformations_ < this.numNodes_) {
                        deformations.push(...lastValues);
                        ++this.numDeformations_;
                    }
                }
                return true;
            } else {
                // Podriamos hacer ciertos retoques en los valores de las deformaciones.
                // ...
            }
            return this.numDeformations_ > 0;
        }
        return false;
    }

    private emptyRanges4FaMs(): void {

        const setValues = (range: [number, number], values: [number, number]): void => {
            range[0] = values[0];            
            range[1] = values[1];
        };

        const emptyValues: [number, number] = [+Infinity, -Infinity];

        for (const range of this.obj4FaMs_.vRngs) {
            setValues(range, emptyValues);
        }
    }

    private fillRanges4FaMs(): void {
        this.emptyRanges4FaMs();
        
        // Actualiza el rango dado si es menester. Asi ahorro codigo.
        const updateRange = (value: number, range: [number, number]): void => {
            if (value < range[0]) {
                range[0] = value;
            } else {
                if (value > range[1]) {
                    range[1] = value;
                }
            }
        };

        const N = this.obj4FaMs_.numTuples;
        const D = this.obj4FaMs_.dim;
        const V = this.obj4FaMs_.vData;

        // Tambien metemos en los rangos a M y N. Aqui tomamos los valores iniciales.
        let i = 0;
        for (let j = 0; j < D; ++j) {
            const range = this.obj4FaMs_.vRngs[j];
            const val = V[i * D + j];            
            range[0] = range[1] = val;
        }

        // Y ahora recorremos todos los valores acumulando los menores y mayores encontrados.
        for (i = 1; i < N; ++i) {
            for (let j = 0; j < D; ++j) {
                const val = V[i * D + j];
                updateRange(val, this.obj4FaMs_.vRngs[j]);
            }
        }

        const info = (msg: string, range: [number, number]): void => {
            console.log(`\t${msg} ===> range [${range[0]}, ${range[1]}]`);
        };
        console.log(`Actualizados rangos para las ${D} variables de F&M de tipo "${this.obj4FaMs_.typeData}":`);
        for (let j = 0; j < D; ++j) {
            const range = this.obj4FaMs_.vRngs[j];
            info(`\t[${j}] "${this.obj4FaMs_.vCols[j]}"`, range);
        }
    }

    setIndices(i4Beams: number[] | null, i4Tris: number[] | null, i4Quads: number[] | null): boolean {
        // Primero se deben fijar los puntos previamente.
        if (!this.numNodes_) {
            return false;
        }
        // No se admiten variaciones.
        if (this.numBeams_ || this.numTris_ || this.numQuads_) {
            return false;
        }        
        // Hay que dar alguna cosa con indices.
        if (i4Beams === null && i4Tris === null && i4Quads === null) {
            return false;
        }
        // Por si se dan arrays vacios.
        const numBeams = (i4Beams === null) ? 0 : i4Beams.length / 2;
        const numTris = (i4Tris === null) ? 0 : i4Tris.length / 3;
        const numQuads = (i4Quads === null) ? 0 : i4Quads.length / 4;
        // Si no se nos da nada no tiene sentido.
        if (!numBeams && !numTris && !numQuads) {
            return false;
        }

        if (numBeams) {
            this.numBeams_ = numBeams;
            this.beamsIV_ = i4Beams as number[];
        }
        if (numTris) {
            this.numTris_ = numTris;
            this.trisIV_ = i4Tris as number[];
        }
        if (numQuads) {
            this.numQuads_ = numQuads;
            this.quadsIV_ = i4Quads as number[];
        }

        return true;
    }

    /**
     * Construye (la primera vez) el objeto grafico de la malla del modelo o devuelve el grafico ya construido.
     * 
     * @returns 
     */
    getGraphicObject(): THREE.Group | null {
        if (this.gObj_) {
            return this.gObj_;
        }
        if (!this.numNodes_) {
            return null;
        }

        this.gObj_ = new THREE.Group();

        // Por lo menos construimos los puntos que eso no puede faltar.
        const pointsGO = StructModel3D.createPoints(this.nodes_);
        pointsGO.name = StructModel3D.name4Nodes;
        this.gObj_.add(pointsGO);

        // De los puntos sacamos la AABB que nos sera util para el clipping y demas.
        // \ToDo: Quizas algun dia la AABB necesite ser oriented.
        this.aabb_ = new THREE.Box3().setFromObject(pointsGO);
        const v = new THREE.Vector3();
        this.aabb_.getSize(v);
        console.log("Dimensiones del mesh (X*Y*Z): " + v.x + " * " + v.y + " * " + v.z);

        // La AABB la expandimos un metro por cada lado, para mejorar la representacion con el clipping.
        const gap = 1.0;
        v.x = v.y = v.z = gap;
        this.aabb_.expandByVector(v);
        this.aabb_.getSize(v);
        console.log("Expandida a: " + v.x + " * " + v.y + " * " + v.z);

        // Los otros componentes son opcionales.
        if (this.numBeams_ + this.numTris_ + this.numQuads_ === 0) {
            return this.gObj_;    
        }

        // Vamos a compartir geometria, al menos las posiciones.
        const attrib4Pos = pointsGO.geometry.getAttribute('position') as THREE.BufferAttribute;
        // Tambien compartimos colores, que creamos en funcion de los puntos.
        const vColors: number[] = [];
        StructModel3D.setGradientColor(this.nodes_, vColors);
        const attrib4Col = new THREE.Float32BufferAttribute(vColors, 3);

        if (this.numBeams_) {
            // Comprobacion que es correcta y comento.
            // if (true) {
            //     const numErrors = this.checkIndices4Errors(this.numNodes_, this.beamsIV_);
            //     if (numErrors) {
            //         const msg = `ERROR: Se han encontrado ${numErrors} errores en los indices de las BEAMS.`;
            //         console.error(msg);
            //         window.alert(msg);
            //         debugger;
            //     }
            // }
            const [linesGO, fatLinesGO] = StructModel3D.create2LineSegments(attrib4Pos, this.beamsIV_);
            linesGO.name = StructModel3D.name4Beams;
            this.gObj_.add(linesGO);
            fatLinesGO.name = StructModel3D.name4FatBeams;
            // Inicialmente no visibles.
            fatLinesGO.visible = false;
            this.gObj_.add(fatLinesGO);
        }

        if (this.numTris_) {
            const trisFGO = StructModel3D.createTriangleFaces(attrib4Pos, this.trisIV_);
            trisFGO.geometry.setAttribute('color', attrib4Col);
            trisFGO.name = StructModel3D.name4Tris;
            this.gObj_.add(trisFGO);
            const trisEGO = StructModel3D.createTrianglesEdges(attrib4Pos, this. trisIV_);
            trisEGO.name = StructModel3D.name4TrisEdges;
            this.gObj_.add(trisEGO);
        }
        if (this.numQuads_) {
            const quadsFGO = StructModel3D.createQuadsFaces(attrib4Pos, this.quadsIV_);
            quadsFGO.geometry.setAttribute('color', attrib4Col);
            quadsFGO.name = StructModel3D.name4Quads;
            this.gObj_.add(quadsFGO);
            const quadsEGO = StructModel3D.createQuadsEdges(attrib4Pos, this.quadsIV_);
            quadsEGO.name = StructModel3D.name4QuadsEdges;
            this.gObj_.add(quadsEGO);
        }

        if (true) {
            const paletteGO = StructModel3D.createPalette();
            paletteGO.name = StructModel3D.name4Palette;
            paletteGO.visible = false;
            this.gObj_.add(paletteGO);

            // La paleta tiene 3 metros de altura por 1 de ancho. La posiciono en el vertice mayor de la AABB.
            paletteGO.position.set(this.aabb_.max.x, this.aabb_.max.y, this.aabb_.max.z);
        }

        if (true) {
            // Tecnica experimental para aminorar el z-fighting por lo menos de las plantas en XY: Subimos cierta cantidad
            // en mm en Z la altura del modelo malla. Tengase esto en cuenta para la posterioridad.
            // Si funciona en conjuncion con el logarithmicDepthBuffer: true en la creacion del renderer.
            const zOffset = 0.001;
            this.gObj_.position.z += zOffset;
        }

        return this.gObj_;
    }

    /**
     * Comprobador de posibles errores encontrados en los indices, como indices negativos, no enteros o fuera del rango
     * de puntos dado en [0, N). Devuelve el numero de errores encontrado, que seria 0 en caso correcto.
     * @param N 
     * @param vIndices 
     */
    private checkIndices4Errors(N: number, vIndices: number[]): number {
        let numErrs = 0;
        const I = vIndices.length;
        if (!I) {
            return 1;
        }
        if (I % 2) {
            console.error("ERROR: Numero impar de indices!!!");
            return 1;
        }
        // Recorrido indice a indice.
        for (let i = 0; i < I; ++i) {
            const index = vIndices[i];
            if (index < 0 || N <= index) {
                ++numErrs;
            }
            if (!Number.isInteger(index)) {
                ++numErrs;
            }
        }
        // Recorrido por pares.
        let avgDist = 0;
        let sumDist = 0;
        let sumDist2 = 0;
        for (let i = 0; i < I; i += 2) {
            const indexA = vIndices[i];
            const indexB = vIndices[i + 1];
            if (indexA === indexB) {
                ++numErrs;
            } else {
                // Comprobemos que no hay distancias casi nulas para los tramos encontrados.
                const ax = this.nodes_[3 * indexA];
                const ay = this.nodes_[3 * indexA + 1];
                const az = this.nodes_[3 * indexA + 2];
                const bx = this.nodes_[3 * indexB];
                const by = this.nodes_[3 * indexB + 1];
                const bz = this.nodes_[3 * indexB + 2];
                const pointA = new THREE.Vector3(ax, ay, az);
                const pointB = new THREE.Vector3(bx, by, bz);
                const distAB = pointA.distanceTo(pointB);
                if (distAB < 0.001) {
                    ++numErrs;
                }
                avgDist += distAB;
                sumDist += distAB;
                sumDist2 += distAB * distAB;                
            }            
        }
        const N2 = I / 2;
        avgDist = avgDist / N2;
        sumDist /= N2;
        sumDist *= sumDist;
        sumDist2 /= N2;
        const stdDev = Math.sqrt(sumDist2 - sumDist);
        console.log(`Avg(beam length) = ${avgDist}    StdDev = ${stdDev}`);
        return numErrs;
    }

    /**
     * Devuelve un material para puntos simple, en forma de puntos cuadrados.
     */
    private static createPointsMaterial(): THREE.PointsMaterial {
        const material = new THREE.PointsMaterial({
            color: 0xFFFFFF,
            // Aqui el tamaño va en pixels.
            size: 2 + 1,
            // blending: THREE.AdditiveBlending,
            // transparent: true,
            sizeAttenuation: false
        });
        material.name = StructModel3D.name4NodesMaterialAsPoints;
        return material;
    }

    /**
     * Devuelve un material para puntos basado en una textura/sprite, en forma de bolita azul.
     */
    private static createBallsMaterial(): THREE.PointsMaterial {
        // Ojo, que si el path de la textura no se antecede de '/' la aplicacion React no la pilla.
        const texture = new THREE.TextureLoader().load('/files_mesh3d/disc.png');
        const material = new THREE.PointsMaterial({
            color: 0x0080ff,
            map: texture,
            // Aqui el tamaño va en metros.
            size: 0.25 * 2,
            // Duda: No se si vale pa algo...
            alphaTest: 0.5
        });
        // Para localizar el material empleado y evitar reajustes innecesarios le damos un nombre.
        material.name = StructModel3D.name4NodesMaterialAsBalls;
        return material;
    }

    private static createPoints(pointsBA: Float32Array, useMaterial = true): THREE.Points<THREE.BufferGeometry> {
        const geometry = new THREE.BufferGeometry();
        const buffAttrib4Pos = new THREE.Float32BufferAttribute(pointsBA, 3).setUsage(THREE.DynamicDrawUsage);
        geometry.setAttribute('position', buffAttrib4Pos);
        geometry.computeBoundingSphere();
            
        let material: THREE.PointsMaterial | null = null;
    
        if (useMaterial) {
            const useTexture = false;
            if (useTexture) {
                material = StructModel3D.createBallsMaterial();
            } else {
                material = StructModel3D.createPointsMaterial();
            }
        }
    
        const points = new THREE.Points(geometry, useMaterial ? material as THREE.PointsMaterial : undefined);
        return points;
    }
    
    /**
     * Toma los puntos 3D del buffer origen usando los indices y los va metiendo en el buffer de salida, sin repeticiones
     * entre puntos contiguos. Asumimos todo correcto.
     * @param vSrc 
     * @param vDst 
     * @param vInd 
     */
    private static sendIndexedPositions2NonIndexed(vSrc: ArrayLike<number>, vDst: number[], vInd: ArrayLike<number>): void {
        const I = vInd.length;
        vDst.length = 0;

        let lastIndex = -1;
        for (let i = 0; i < I; i += 2) {
            const indexA = vInd[i];
            const indexB = vInd[i + 1];

            //if (indexA !== lastIndex) {
                const ax = vSrc[3 * indexA];
                const ay = vSrc[3 * indexA + 1];
                const az = vSrc[3 * indexA + 2];
                vDst.push(ax, ay, az);
            //}

            const bx = vSrc[3 * indexB];
            const by = vSrc[3 * indexB + 1];
            const bz = vSrc[3 * indexB + 2];
            vDst.push(bx, by, bz);

            lastIndex = indexB;
        }
    }

    /**
     * Crea 2 tipos de segmentos: Los normales, sin grosor, mas los gordos.
     * @param buffAttr 
     * @param vIndices 
     * @returns 
     */
    private static create2LineSegments(buffAttr: THREE.BufferAttribute, vIndices: number[]): [THREE.LineSegments, LineSegments2] {
        const geometry = new THREE.BufferGeometry();
        geometry.setAttribute('position', buffAttr);
        geometry.setIndex(vIndices);
        geometry.computeBoundingSphere();
    
        // Estas son las lineas delgadas de toda la vida.
        const mat = new  THREE.LineBasicMaterial({
            color: 0xFFFFFF,
            vertexColors: true,
        })
        const lines = new THREE.LineSegments(geometry);
        (lines.material as THREE.LineBasicMaterial).color = new THREE.Color(0xFF0000);
        
        // Ahora lo pongo con lineas gordas y punteadas.
        const mat4FatLine = new LineMaterial({
            color: 0x00FF00,
            // Ojo, que esto debe ser extremadamente piquico, pues en caso contrario sale tan gordo que ocupa todo!!!.
            linewidth: 0.005, // In world units with size attenuation, pixels otherwise.
            vertexColors: false,
            // resolution:  // To be set by renderer, eventually.
            dashed: false,
        });

        // Parece que no se puede poner geometria indexada, asi que metemos todos los puntos necesarios mediante los
        // indices, evitando repeticiones.
        const vSrcPoints3D = geometry.attributes.position.array;
        const vDstPoints3D: number[] = [];
        StructModel3D.sendIndexedPositions2NonIndexed(vSrcPoints3D, vDstPoints3D, vIndices);

        const geo4FatLine = new LineSegmentsGeometry();        
        geo4FatLine.setPositions(vDstPoints3D);
        // geometry.setAttribute('position', buffAttr);
        // geo4FatLine.setIndex(vIndices);
        // geo4FatLine.computeBoundingBox();
        // geo4FatLine.computeBoundingSphere();
        const fatLines = new LineSegments2(geo4FatLine, mat4FatLine);
        // fatLines.computeLineDistances();
        // fatLines.scale.set(1, 1, 1);

        if (false) {
            // Ejemplillo que funciona!!!.
            const positions = [
                0, 0, 0,
                10, 10, 10,
            ];
            const lineGeometry = new LineGeometry();
            lineGeometry.setPositions(positions);

            const matLine = new LineMaterial({
                // Un azul molon.
                color: 0x277cb2,
                vertexColors: false,
                dashed: false,
                linewidth: 0.01,
                // resolution???
            });
    
            const fatLines = new Line2(lineGeometry, matLine);
            // fatLines.computeLineDistances();
            // fatLines.scale.set(1, 1, 1);
        }

        return [lines, fatLines];
    }
    
    private static createTriangleFaces(buffAttr: THREE.BufferAttribute, vIndices: number[]): THREE.Mesh<THREE.BufferGeometry> {
        const geometry = new THREE.BufferGeometry();
        geometry.setAttribute('position', buffAttr);
        geometry.setIndex(vIndices);
        geometry.computeBoundingSphere();
    
        const material = new THREE.MeshBasicMaterial({
            side: THREE.DoubleSide,
            vertexColors: true,
            wireframe: false,
            // Experimento para mejorar la visibilidad de las lineas, sacado de:
            // https://stackoverflow.com/questions/31539130/display-wireframe-and-solid-color/31541369#31541369
            // Pues parece que asi se ve algo mejor y los agujeros no tienen la telilla.
            polygonOffset: true,
            polygonOffsetFactor: 1, // positive value pushes polygon further away
            polygonOffsetUnits: 1
        });
    
        const mesh = new THREE.Mesh(geometry, material);
        return mesh;
    }

    private static createTrianglesEdges(buffAttr: THREE.BufferAttribute, vIndices: number[]): THREE.LineSegments {
        const geometry = new THREE.BufferGeometry();
        geometry.setAttribute('position', buffAttr);
        const vIndices2: number[] = [];
        const numTris = vIndices.length / 3;
        for (let i = 0; i < numTris; ++i) {
            const a = vIndices[3 * i];
            const b = vIndices[3 * i + 1];
            const c = vIndices[3 * i + 2];
            vIndices2.push(a, b, b, c, c, a);
        }
        geometry.setIndex(vIndices2);
        geometry.computeBoundingSphere();
        const lines = new THREE.LineSegments(geometry);
        // Lo ponemos en gris darkgray para diferenciarlo de los nodos blancos y que haya contraste.
        const grayColor = 0xA9A9A9;
        (lines.material as THREE.LineBasicMaterial).color = new THREE.Color(grayColor);
        return lines;
    }

    private static createQuadsFaces(buffAttr: THREE.BufferAttribute, vIndices: number[]): THREE.Mesh<THREE.BufferGeometry> {
        const geometry = new THREE.BufferGeometry();
        geometry.setAttribute('position', buffAttr);
        // Los 4 indices del quad se rompen en 2 triangulos.
        const vIndices2: number[] = [];
        const numQuads = vIndices.length / 4;
        for (let i = 0; i < numQuads; ++i) {
            // Se nos dan los 4 puntos en orden CW asi, respecto a X e Y:    Y+  A--B
            //                                                               |   |  |
            //                                                               |   D--C
            //                                                               +--------X+
            // Por tanto los triangulos en orden CCW son ACB y ADC
            const a = vIndices[4 * i];
            const b = vIndices[4 * i + 1];
            const c = vIndices[4 * i + 2];
            const d = vIndices[4 * i + 3];
            vIndices2.push(a, c, b);
            vIndices2.push(a, d, c);
        }
        geometry.setIndex(vIndices2);
        geometry.computeBoundingSphere();

        const material = new THREE.MeshBasicMaterial({
            side: THREE.DoubleSide,
            vertexColors: true,
            wireframe: false,
            // Experimento para mejorar la visibilidad de las lineas, sacado de:
            // https://stackoverflow.com/questions/31539130/display-wireframe-and-solid-color/31541369#31541369
            // Pues parece que asi se ve algo mejor y los agujeros no tienen la telilla.
            polygonOffset: true,
            polygonOffsetFactor: 1, // positive value pushes polygon further away
            polygonOffsetUnits: 1
        });

        const mesh = new THREE.Mesh(geometry, material);
        return mesh;
    }

    /**
     * Construye las 4 aristas por celda de tipo quad, ya que como wireframe cada faceta se romperia en 2 triangulos y lo
     * que necesitamos es una unica faceta cuadrilatera.
     * @param buffAttr 
     * @param vIndices 
     * @returns 
     */
    private static createQuadsEdges(buffAttr: THREE.BufferAttribute, vIndices: number[]): THREE.LineSegments {
        const geometry = new THREE.BufferGeometry();
        geometry.setAttribute('position', buffAttr);
        const vIndices2: number[] = [];
        const numQuads = vIndices.length / 4;
        for (let i = 0; i < numQuads; ++i) {
            const a = vIndices[4 * i];
            const b = vIndices[4 * i + 1];
            const c = vIndices[4 * i + 2];
            const d = vIndices[4 * i + 3];
            vIndices2.push(a, b, b, c, c, d, d, a);
        }
        geometry.setIndex(vIndices2);
        geometry.computeBoundingSphere();

        const lines = new THREE.LineSegments(geometry);
        // Lo ponemos en gris darkgray para diferenciarlo de nodos blancos y que haya contraste. Colores sacados de:
        // https://en.wikipedia.org/wiki/Web_colors#X11_color_names
        const grayColor = 0xA9A9A9;
        (lines.material as THREE.LineBasicMaterial).color = new THREE.Color(grayColor);

        return lines;
    }

    private static createPalette(): THREE.Sprite {
        const [W, H] = [100, 300];
        const canvas = document.createElement('canvas');
        [canvas.width, canvas.height] = [W, H];
        
        const ctxt = canvas.getContext('2d') as CanvasRenderingContext2D;
        ctxt.fillStyle = "white";
        ctxt.fillText("1234.6789012", 0, 10);
        ctxt.fillText("1234.6789012", 0, canvas.height - 10);

        // Ahora quiero sacar la imagen de un segundo canvas de la lut y pegarlo en el primer canvas.
        if (true) {
            const paletteLut = new Lut();
            const srcCnvs: HTMLCanvasElement = paletteLut.createCanvas();
            // Como sacar la imagen de un canvas???.
            const srcCtxt = srcCnvs.getContext('2d') as CanvasRenderingContext2D;
            const srcImg = srcCtxt.getImageData(0, 0, srcCnvs.width, srcCnvs.height);
            // Como ampliar esa puta imagen?. Copiandola a un canvas de dimensiones mayores.
            const zoomCanvas = document.createElement('canvas');
            [zoomCanvas.width, zoomCanvas.height] = [W / 2, H];
            const zoomContext = zoomCanvas.getContext('2d') as CanvasRenderingContext2D;
            zoomContext.drawImage(srcCnvs, 0, 0, zoomCanvas.width, zoomCanvas.height);
            const zoomImg = zoomContext.getImageData(0, 0, zoomCanvas.width, zoomCanvas.height);
            // Y como copiarla.
            ctxt.putImageData(zoomImg, canvas.width / 2, 0);
        }

        const texture = new THREE.CanvasTexture(canvas);
        const material = new THREE.SpriteMaterial({ map: texture, transparent: true });

        const sprite = new THREE.Sprite(material);
        sprite.scale.y = 3.0;
        sprite.scale.x = 1.0;

        return sprite;
    }

    private static createPalette_old(): THREE.Group {
        const grp = new THREE.Group();
        const paletteLut = new Lut();
        const canvas: HTMLCanvasElement = paletteLut.createCanvas();
        // const canvas = document.createElement('canvas');
        // Al cambiar de tamaño se jode y queda negro.
        // canvas.height = 100;
        // canvas.width = 300;
        const ctxt = canvas.getContext('2d') as CanvasRenderingContext2D;
        ctxt.fillStyle = "black";
        ctxt.fillText("ABC", 0, canvas.height / 2);

        if (false) {
            const w = 100;
            const h = 400;
            canvas.width = w;
            canvas.height = h;
    
            // Pinto algo arriba y abajo a ver que tal se ve...
            const context = canvas.getContext('2d') as CanvasRenderingContext2D;
            context.globalCompositeOperation = 'overlay'; // 'xor';

            if (false) {
                context.rect(1, 1, w - 1, h - 1);
                context.fillStyle = "red";
                context.fill();
            }

            context.font = "12px Arial";
            context.fillStyle = "white";
            context.textAlign = "center";
            context.fillText("0.1234567891011", 50, 15);
            context.fillText("999.9876543210", 50, 385);
        } else {
            if (false) {
                const w = canvas.width;
                const h = canvas.height;
                console.error(" ====> Canvas original de " + w + " * " + h + " <=========================");
                let txt = "0.1234567891011";
                let txtH = 10;
                const context = canvas.getContext('2d') as CanvasRenderingContext2D;
                if (true) {
                    context.rect(0, 5, w, h - 5);
                    context.fillStyle = "red";
                    context.fill();
                }
                context.font = "normal " + txtH + "px Arial";
                context.textAlign = "center";
                context.textBaseline = "middle";
                context.fillStyle = "#ffffff";
                const metrics = context?.measureText(txt) as TextMetrics;
                let txtW = metrics.width;
                context.fillText(txt, txtW / 2, txtH / 2);

                debugger;
            }
        }

        const texture = new THREE.CanvasTexture(canvas);
        const material = new THREE.SpriteMaterial({ map: texture });

        // Al ser un sprite es un billboard que siempre mira de cara a la muerte, esto a la camara/observador.
        // Quizas solo debiera verse en un viewport 3D.
        const sprite4Palette = new THREE.Sprite(material);
        // Por defecto un sprite es un cuadrado entre las esquinas (-.5, -.5, 0) y (+.5, +.5, 0), y lo escalo para que
        // tenga unas dimensiones de 3m de alto por 1 de ancho.
        // sprite4Palette.scale.y = 3.0;
        // sprite4Palette.scale.x = 1.0;
        // sprite4Palette.position.x = 0;
        
        grp.add(sprite4Palette);

        const sepZ = 1.90;
        const sprite4LabelUp = makeLabelSprite(150, 32, "012345678901");
        //sprite4LabelUp.position.z = sepZ;
        sprite4LabelUp.translateZ(sepZ);

        const sprite4LabelDown = makeLabelSprite(150, 32, "Abajo!!!");
        sprite4LabelDown.position.z = -sepZ;

        // grp.add(sprite4LabelUp);
        // grp.add(sprite4LabelDown);

        // Los agrego al sprite principal para ver si asi van juntitos.
        // sprite4Palette.add(sprite4LabelUp);
        // sprite4Palette.add(sprite4LabelDown);

        // Probando...
        // sprite4Palette.attach(sprite4LabelUp);
        // sprite4Palette.attach(sprite4LabelDown);

        const text = createCharacterLabel("012345678901");
        // text.scale.y = 1.0;
        // text.scale.x = 1.0;
        // text.position.x = 0;
        // text.position.y = -0.001;
        text.renderOrder = sprite4Palette.renderOrder - 10;
        grp.add(text);

        return grp;
    }

    /**
     * Una vez que tenemos todos los datos necesarios cargados podemos ir creando o reajustando todas las paletas
     * necesarias.
     * vColor es el parametro de salida con tantos tercetos [r, g, b] como puntos/nodos tengamos. OJO, que es LINEAL.
     * En este caso esto implica una teorica paleta 3D, con rojos a lo largo del eje X, verdes en el Y y azules en Z,
     * con intensidades que crecen con los ejes, del negro en la esquina menor de la AABB al blanco en su esquina mayor.
     */
    private createGradientColors4PointsLimits(vColors: number[]): void {
        if (vColors.length) {
            vColors.length = 0;
        }

        // Segun los limites de nuestra AABB expandida previamente calculada creamos la paleta por defecto, "default".
        // Recuerda que esta EXPANDIDA un metro por cada lado, con lo que evitamos los minimos negros absolutos y los
        // maximos blancos absolutos. Y ademas por esa expansion evitamos posibles divisiones por 0 antes posibles.
        const v = new THREE.Vector3();
        this.aabb_.getSize(v);
        // Dimensiones en los 3 ejes, que mapearemos a [0, 1] y ahi tendremos los colores.
        const [dimX, dimY, dimZ] = [v.x, v.y, v.z];
        const [minX, minY, minZ] = [this.aabb_.min.x, this.aabb_.min.y, this.aabb_.min.z];

        // El vector lineal de ptos 3D aka nodos.
        const vP = this.nodes_;
        const N = vP.length / 3;

        for (let i = 0; i < N; ++i) {
            const x = vP[3 * i];
            const y = vP[3 * i + 1];
            const z = vP[3 * i + 2];

            // Recuerda que son componentes en [0, 1], aunque por la expansion en realidad estan en (0, 1).
            const r = (x - minX) / dimX;
            const g = (y - minY) / dimY;
            const b = (z - minZ) / dimZ;
            vColors.push(r, g, b);
        }
        // Quedaria modificar la representacion grafica de la paleta...
        // \ToDo: Quizas fuera mas rapido modificar directamente los colores dentro de su propio attribute...
    }

    private createSalomeMecaColor4Points(vColors: number[]): void {
        if (vColors.length) {
            vColors.length = 0;
        }

        // Aqui usamos la paleta de Salome-Meca en 2D, sin Z y con el ambito [-1, +1].

        // Recuerda que esta EXPANDIDA un metro por cada lado, con lo que evitamos los minimos negros absolutos y los
        // maximos blancos absolutos. Y ademas por esa expansion evitamos posibles divisiones por 0 antes posibles.
        const v = new THREE.Vector3();
        this.aabb_.getSize(v);
        // Dimensiones en los 2 ejes, que mapearemos a [0, 1] y ahi tendremos los colores.
        // Ojo que la AABB original esta ampliada un metro por cada lado y aqui lo reducimos para casar mejor.
        const gap = 1.0;
        const [dimX, dimY] = [v.x - gap, v.y - gap];
        const [minX, minY] = [this.aabb_.min.x + gap, this.aabb_.min.y + gap];

        // El vector lineal de ptos 3D aka nodos.
        const vP = this.nodes_;
        const N = vP.length / 3;

        const lut = createLUT4SalomeMeca();

        for (let i = 0; i < N; ++i) {
            const x = vP[3 * i];
            const y = vP[3 * i + 1];

            // Recuerda que son componentes en [0, 1].
            const fX = (x - minX) / dimX;
            const fY = (y - minY) / dimY;
            // La duda aqui es la aplicacion 2D del color 1D...
            const colorX = lut.getColor(fX);
            const colorY = lut.getColor(fY);
            const r = colorX.r + (colorY.r - colorX.r) * 0.5;
            const g = colorX.g + (colorY.g - colorX.g) * 0.5;
            const b = colorX.b + (colorY.b - colorX.b) * 0.5;

            vColors.push(r, g, b);
        }
        // Quedaria modificar la representacion grafica de la paleta...
        // \ToDo: Quizas fuera mas rapido modificar directamente los colores dentro de su propio attribute...
    }

    private createDeformationColors4DXYZ(vColors: number[]): void {
        if (vColors.length) {
            vColors.length = 0;
        }

        const vD = this.vDeformations_;
        const N = vD.length / 6;
        let [minVal, maxVal] = [+Infinity, -Infinity];
        const [I0, I1, I2] = [0, 1, 2];

        for (let i = 0; i < N; ++i) {
            const x = vD[6 * i + I0];
            const y = vD[6 * i + I1];
            const z = vD[6 * i + I2];
            const val = Math.sqrt(x * x + y * y + z * z);
            if (val > maxVal) {
                maxVal = val;
            }
            if (val < minVal) {
                minVal = val;
            }
        }

        // Paso de [minVal, maxVal] a [0, 1].
        // Rango de valores encontrado. 
        const rng = maxVal - minVal;
        // Factor de conversion a 01: Si el rango es nulo o casi evitamos imprecision.
        const f01 = (-0.0001 < rng && rng < +0.0001) ? 1.0 : 1.0 / rng;

        // Funcion que a partir de los 3 valores (dx, dy, dz) calcula el correspondiente color (r, g, b) en la paleta
        // blue2red segun la normalizacion de su modulo al intervalo [0, 1].
        const calculateColor = (xx: number, yy: number, zz: number): [number, number, number] => 
        {
            // Lo primero es pasar el modulo a [0, 1].
            let v = Math.sqrt(xx * xx + yy * yy + zz * zz);
            v = f01 * (v - minVal);
            // Interpolacion lineal entre los colores A y B: (color_B - color_A) * val_01 + color_A.
            // Reducimos operaciones.
            const vPi = v * Math.PI;
            const halfPi = 0.5 * Math.PI;
            const r = 0.5 * Math.sin(vPi - halfPi) + 0.5;
            const g = 0.5 * Math.sin(2 * vPi - halfPi) + 0.5;
            const b = 0.5 * Math.cos(vPi) + 0.5;
            return [r, g, b] as [number, number, number];
        };

        for (let i = 0; i < N; ++i) {
            const x = vD[6 * i + I0];
            const y = vD[6 * i + I1];
            const z = vD[6 * i + I2];
            const [r, g, b] = calculateColor(x, y, z);
            vColors.push(r, g, b);
        }

        // Queda modificar la paleta GO... poniendo incluso los limites...
        console.log(`Deformations DXYZ range [${minVal}, ${maxVal}]`);
    }

    /**
     * Calcula los colores pero con solo la I-esima columna de deformaciones de las 6 disponibles.
     * @param I 
     * @param vColors 
     */
    private createDeformationColors4OnlyOneColumnI(I: number, vColors: number[]): void {
        if (vColors.length) {
            vColors.length = 0;
        }

        const vD = this.vDeformations_;
        const N = vD.length / 6;
        let [minVal, maxVal] = [+Infinity, -Infinity];
        const [I0, I1, I2] = [0, 1, 2];

        for (let i = 0; i < N; ++i) {
            const val = vD[6 * i + I];
            if (val > maxVal) {
                maxVal = val;
            }
            if (val < minVal) {
                minVal = val;
            }
        }

        // Paso de [minVal, maxVal] a [0, 1].        
        // Rango de valores encontrado. 
        const rng = maxVal - minVal;
        // Factor de conversion a 01: Si el rango es nulo o casi evitamos imprecision.
        const f01 = (-0.0001 < rng && rng < +0.0001) ? 1.0 : 1.0 / rng;

        // A partir del valor dado se calcula el correspondiente color (r, g, b) en la paleta blue2red.
        const calculateColor = (v: number): [number, number, number] => 
        {
            // Lo primero es pasar el valor a [0, 1].
            v = f01 * (v - minVal);
            // Interpolacion lineal entre los colores A y B: (color_B - color_A) * val_01 + color_A.
            // Reducimos operaciones.
            const vPi = v * Math.PI;
            const halfPi = 0.5 * Math.PI;
            const r = 0.5 * Math.sin(vPi - halfPi) + 0.5;
            const g = 0.5 * Math.sin(2 * vPi - halfPi) + 0.5;
            const b = 0.5 * Math.cos(vPi) + 0.5;
            return [r, g, b] as [number, number, number];
        };

        for (let i = 0; i < N; ++i) {
            const val = vD[6 * i + I];
            const [r, g, b] = calculateColor(val);
            vColors.push(r, g, b);
        }

        // Queda modificar la paleta GO... poniendo incluso los limites...
        const column = displacementList[I];
        console.log(`Deformations ${column} range [${minVal}, ${maxVal}] = ${rng}`);
    }

    /**
     * Calcula los colores pero con solo la I-esima columna de F&M's de las 6/8 disponibles segun el tipo beams/shells.
     * @param I 
     * @param vColors 
     */
    private createFaMsColors4ColumnI(I: number, vColors: number[]): void {
        if (vColors.length) {
            vColors.length = 0;
        }

        // Pasamos de los indices para acceder directamente a los datos de F&M's en la columna.
        I += 2;

        const vData = this.obj4FaMs_.vData;
        const N = this.obj4FaMs_.numTuples;
        const D = this.obj4FaMs_.dim;
        const range = this.obj4FaMs_.vRngs[I];
        const minVal = range[0];
        const maxVal = range[1];
        // Esta es la topologia, el mapa que dado un indice de elementos

        // Paso de [minVal, maxVal] a [0, 1] usando el rango de valores.
        const rng = maxVal - minVal;
        // Factor de conversion a 01: Si el rango es nulo o casi evitamos imprecision.
        const f01 = (-0.0001 < rng && rng < +0.0001) ? 1.0 : 1.0 / rng;

        // A partir del valor dado se calcula el correspondiente color (r, g, b) en la paleta blue2red.
        const calculateColor = (v: number): [number, number, number] => 
        {
            // Lo primero es pasar el valor a [0, 1].
            v = f01 * (v - minVal);
            // Interpolacion lineal entre los colores A y B: (color_B - color_A) * val_01 + color_A.
            const vPi = v * Math.PI;
            const halfPi = 0.5 * Math.PI;
            const r = 0.5 * Math.sin(vPi - halfPi) + 0.5;
            const g = 0.5 * Math.sin(2 * vPi - halfPi) + 0.5;
            const b = 0.5 * Math.cos(vPi) + 0.5;
            return [r, g, b] as [number, number, number];
        };

        const lut = createLUT4SalomeMecaDiscrete();
        const useSalomeMecaColors = true;        

        // Mapa de colores en el que cada color se asigna al indice de nodo. Si se repite interpolamos...
        const mColors = new Map<number, [number, number, number]>();
        for (let i = 0; i < N; ++i) {
            const val = vData[D * i + I];
            let [r, g, b] = [0, 0, 0];
            if (!useSalomeMecaColors) {
                [r, g, b] = calculateColor(val);
            } else {
                const val01 = f01 * (val - minVal);
                // Esto es lo mas simple, sin multiplicaciones ni hostias que lo hacen quedar peor.
                const color = lut.getColor(val01);
                [r, g, b] = [color.r, color.g, color.b];
            }

            // En las 2 primeras posiciones tenemos los indices del elemento y del nodo.
            // El elemento lo traducimos.
            const i4Elem0 = vData[D * i];
            if (!this.obj4FaMs_.mapNew2SrcElems.has(i4Elem0)) {
                debugger;
            }
            const i4ElemI = this.obj4FaMs_.mapNew2SrcElems.get(i4Elem0) as number;
            // Sabemos que los nodos son los mismos tras la traduccion pero en diferente orden...
            // De momento los consideramos como en el mismo orden, pero quiza haya que rotarlos???.
            // Ojo, que le quito 1 para hacerlo 0-based.
            let i4Node = vData[D * i + 1] - 1;
            // Y podemos sacar los demas nodos del elemento dado accediendo a la topologia que tenemos almacenada.
            const vNodes4Elem = this.getNodes4ElementI(i4ElemI);
            // GRAVES ERRORES: El elemento NO tiene nodos o bien el nodo no esta dentro del elemento.
            if (0 === vNodes4Elem.length) {
                const msg = `ERROR: Elemento [${i4ElemI}] (${i}/${N}) ERRONEO, sin nodos!!!. Posible divergencia malla-calculos.`;
                console.error(msg);
                window.alert(msg);
                debugger;
                continue;
            } else {
                const pos4I4Node = vNodes4Elem.indexOf(i4Node);
                if (-1 === pos4I4Node) {
                    const msg = `ERROR: El elemento original [${i4ElemI}] (${i}/${N}) con los nodos [${vNodes4Elem}] no contiene` 
                        + ` al nodo [${i4Node}] de F&M. Posible divergencia malla-calculos.`;
                    console.error(msg);
                    window.alert(msg);
                    debugger;
                    continue;
                }
            }

            if (!mColors.has(i4Node)) {
                mColors.set(i4Node, [r, g, b]);
            } else {
                const dstRGB = mColors.get(i4Node) as [number, number, number];
                // Esto no creo que este bien: Con esto si un nodo tenia un color lo interpolo con el nuevo color.
                // pero parece que en Salome-Meca se podria pillar el maximo color.
                if (!useSalomeMecaColors) {
                    dstRGB[0] += r;
                    dstRGB[1] += g;
                    dstRGB[2] += b;    
                    dstRGB[0] *= 0.5;
                    dstRGB[1] *= 0.5;
                    dstRGB[2] *= 0.5;
                } else {
                    // Pillamos el maximo del anterior y el nuevo pero en cuanto a modulo supongo...
                    const srcColor = new THREE.Vector3(dstRGB[0], dstRGB[1], dstRGB[2]);
                    const newColor = new THREE.Vector3(r, g, b);
                    const srcMod = srcColor.length();
                    const newMod = newColor.length();
                    // El nuevo persiste si es mayor.
                    if (newMod > srcMod) {
                        dstRGB[0] = r;
                        dstRGB[1] = g;
                        dstRGB[2] = b;        
                    }
                }
            }
        }

        // Y ahora para todos los nodos metemos los nuevos colores. Los que no esten los dejamos a NEGRO.
        for (let i = 0; i < this.numNodes_; ++i) {
            if (mColors.has(i)) {
                const [r, g, b] = mColors.get(i) as [number, number, number];
                vColors.push(r, g, b);
            } else {
                // vColors.push(1.0, 1.0, 1.0);
                vColors.push(0, 0, 0);
            }
        }
    }

    /**
     * Crea un degradado de colores RGB segun los limites de las coordenadas dadas y lo devuelve en vColors.
     * Ojo, que esto en realidad lleva implicita una textura 3D, no 2D.
     */
    private static setGradientColor(vVertices: Float32Array, vColors: number[]): void {
        if (vColors.length) {
            vColors.length = 0;
        }
        const nVertices = vVertices.length / 3;

        let [xMin, xMax] = [+Infinity, -Infinity];
        let [yMin, yMax] = [+Infinity, -Infinity];
        let [zMin, zMax] = [+Infinity, -Infinity];

        for (let i = 0; i < nVertices; ++i) {
            const x = vVertices[3 * i];
            const y = vVertices[3 * i + 1];
            const z = vVertices[3 * i + 2];
            if (x < xMin) { xMin = x; }
            if (x > xMax) { xMax = x; }
            if (y < yMin) { yMin = y; }
            if (y > yMax) { yMax = y; }
            if (z < zMin) { zMin = z; }
            if (z > zMax) { zMax = z; }
        }

        const xRange = xMax - xMin;
        const yRange = yMax - yMin;
        const zRange = zMax - zMin;

        for (let i = 0; i < nVertices; ++i) {
            const x = vVertices[3 * i];
            const y = vVertices[3 * i + 1];
            const z = vVertices[3 * i + 2];

            const r = (x - xMin) / xRange;
            const g = (y - yMin) / yRange;
            const b = (z - zMin) / zRange;
            vColors.push(r, g, b);
        }
    }

    /**
     * Devuelve los limites de los puntos 3D dados, en la forma [xMin, xMax, yMin, yMax, zMin, zMax].
     */
    getLimitsXYZ(): [number, number, number, number, number, number] {
        let [xMin, xMax] = [+Infinity, -Infinity];
        let [yMin, yMax] = [+Infinity, -Infinity];
        let [zMin, zMax] = [+Infinity, -Infinity];

        for (let i = 0; i < this.numNodes_; ++i) {
            const x = this.nodes_[3 * i];
            const y = this.nodes_[3 * i + 1];
            const z = this.nodes_[3 * i + 2];
            if (x < xMin) { xMin = x; }
            if (x > xMax) { xMax = x; }
            if (y < yMin) { yMin = y; }
            if (y > yMax) { yMax = y; }
            if (z < zMin) { zMin = z; }
            if (z > zMax) { zMax = z; }
        }
        return [xMin, xMax, yMin, yMax, zMin, zMax];
    }

    /**
     * A consecuencia de andar jugando con distintos tipos de deformaciones, (y de la acumulacion de errores aritmeticos
     * al jugar mucho con el slider de deformaciones), a veces es necesario resetear los puntos 3D del modelo grafico
     * para que vuelvan a contener los mismos valores que se le dieron inicialmente. Si no se hace esto tenemos mallas
     * en las que aunque el slider esta en 0, los nodos estan algo deformados. Esta es la solucion.
     */
    private resetNodes(): void {
        // Evidentemente a esta fmc solo se la llama en un status coherente, sin posibles errores.
        const pointsGO = this.gObj_.getObjectByName(StructModel3D.name4Nodes) as THREE.Points<THREE.BufferGeometry>;
        const geo = pointsGO.geometry;
        const bufDst = geo.getAttribute('position').array as Float32Array;
        // Lo mas rapido es copiar directamente los buffer arrays.
        // \Trick: Copiar in-line el contenido de un tipedArray a otro.
        bufDst.set(this.nodes_);
        geo.attributes.position.needsUpdate = true;
    }

    applyDeformationsCoeff(f: number): void {
        this.lastCoeffF_ = f;

        if (!this.numDeformations_ || this.gObj_ === null) {
            console.error("No se pueden aplicar las deformaciones.");
            return;
        }

        const pointsGO = this.gObj_.getObjectByName(StructModel3D.name4Nodes) as THREE.Points<THREE.BufferGeometry>;
        const geo = pointsGO.geometry;

        // Segun el modo de deformaciones en curso aplicamos unos flags de uso para reducir accesos y optimizar.
        // Recuerda que estos son los valores: ["Dx", "Dy", "Dz", "DRx", "DRy", "DRz", "Dsum", "DRsum"].
        let [useX, useY, useZ] = [true, true, true];
        let useRotation = false;

        switch (this.deformationType_) {
            case displacementList[0]:   // Dx
                useY = useZ = false;
                break;
            case displacementList[1]:   // Dy
                useX = useZ = false;
                break;
            case displacementList[2]:   // Dz
                useX = useY = false;
                break;

            case displacementList[3]:   // DRx
                useRotation = true;
                useY = useZ = false;
                break;
            case displacementList[4]:   // DRy
                useRotation = true;
                useX = useZ = false;
                break;
            case displacementList[5]:   // DRz que parece ser que en realidad es DR a pelo, sin z, segun creo yo...
                useRotation = true;
                useX = useY = false;
                break;

            case displacementList[6]:   // Dsum que es X+Y+Z
                break;

            case displacementList[7]:   // DRsum que no se si serian las 3 Dr o solo DRx + DRy.
                useRotation = true;
                // De momento uso las 3 a ver que pintilla...
                break;
            default:
                window.alert("Tipo de deformacion no permitido (aun...).");
                return;
        }

        // Sacamos el array de posiciones del buffer.
        const buf = geo.getAttribute('position').array as Float32Array;

        const I0 = useRotation ? 3 : 0;
        const I1 = useRotation ? 4 : 1;
        const I2 = useRotation ? 5 : 2;

        for (let i = 0; i < this.numNodes_; ++i) {
            if (useX) {
                const defoX = this.vDeformations_[6 * i + I0];
                buf[3 * i] = this.nodes_[3 * i] + f * defoX;
            }
            if (useY) {
                const defoY = this.vDeformations_[6 * i + I1];
                buf[3 * i + 1] = this.nodes_[3 * i + 1] + f * defoY;
            }
            if (useZ) {
                const defoZ = this.vDeformations_[6 * i + I2];            
                buf[3 * i + 2] = this.nodes_[3 * i + 2] + f * defoZ;
            }
        }

        geo.attributes.position.needsUpdate = true;

        // Y aqui momentaneamente procederemos a calcular la tabla de desplomes para los N pisos disponibles.
        // this.calculateFakeStoreyDriftTable(f);
    }

    private static setDeformationsColor4DXDYDZ(vDeformations: number[], vColors: number[]): void {
        if (vColors.length) {
            vColors.length = 0;
        }
        const nVertices = vDeformations.length / 6;
        let min_length = +Infinity;
        let max_length = -Infinity;
    
        for (let i = 0; i < nVertices; ++i) {
            const x = vDeformations[6 * i];
            const y = vDeformations[6 * i + 1];
            const z = vDeformations[6 * i + 2];
            const length = Math.sqrt(x * x + y * y + z * z);
            if (length > max_length) {
                max_length = length;
            }
            if (length < min_length) {
                min_length = length;
            }    
        }

        // Para la conversion del intervalo de modulos de deformacion minima y maxima [min_length, max_length] a [0, 1]
        // debemos tener cuidado con las posibles divisiones por 0. Recuerda que el factor_01 es un coef. usado para
        // pasar del modulo de la deformacion al intervalo normalizado [0, 1] y poder asignar asi los colores RGB.
        let factor_01 = 1.0;
        const def_range = max_length - min_length;
        
        if (-0.0001 < def_range && def_range < +0.0001) {
            factor_01 = 1.0;
        } else {
            factor_01 = 1.0 / def_range;
        }

        const get_coefficient_01 = (xx: number, yy: number, zz: number): number => {
            let mag = Math.sqrt(xx * xx + yy * yy + zz * zz);
            // Tenemos mag en [min_length, max_length] y lo normalizamos a [0, 1].
            mag = factor_01 * (mag - min_length);
            return mag;
        };
    
        const get_deformation_color_blue_red = (xx: number, yy: number, zz: number): [number, number, number] => 
        {
            const loc_max_length = get_coefficient_01(xx, yy, zz);
            // Interpolacion lineal entre los colores A y B: (color_B - color_A) * val_01 + color_A
            const comp_R = 0.5 * Math.sin(loc_max_length * Math.PI - 0.5 * Math.PI) + 0.5;
            const comp_G = 0.5 * Math.sin(loc_max_length * 2 * Math.PI - 0.5 * Math.PI) + 0.5;
            const comp_B = 0.5 * Math.cos(loc_max_length * Math.PI) + 0.5;

            const color_C = [comp_R, comp_G, comp_B] as [number, number, number];
            return color_C;
        };

        for (let i = 0; i < nVertices; ++i) {
            const x = vDeformations[6 * i];
            const y = vDeformations[6 * i + 1];
            const z = vDeformations[6 * i + 2];
            const [rr, gg, bb] = get_deformation_color_blue_red(x, y, z);
            vColors.push(rr, gg, bb);
        }
        // Al devolverlo al exterior se debiera hacer esto:
        // const attrib4Col = new THREE.Float32BufferAttribute(vColors, 3);
    }

    private static setDeformationsColor4DR(vDeformations: number[], vColors: number[]): void {
        if (vColors.length) {
            vColors.length = 0;
        }
        const nVertices = vDeformations.length / 6;
        let minDR = +Infinity;
        let maxDR = -Infinity;
    
        for (let i = 0; i < nVertices; ++i) {
            const DR = vDeformations[6 * i + 5];
            if (DR > maxDR) {
                maxDR = DR;
            }
            if (DR < minDR) {
                minDR = DR;
            }    
        }

        let factor_01 = 1.0;
        const def_range = maxDR - minDR;
        
        if (-0.0001 < def_range && def_range < +0.0001) {
            factor_01 = 1.0;
        } else {
            factor_01 = 1.0 / def_range;
        }

        const get_coefficient_01 = (dr: number): number => {
            // Tenemos mag en [min_length, max_length] y lo normalizamos a [0, 1].
            dr = factor_01 * (dr - minDR);
            return dr;
        };
    
        const get_deformation_color_blue_red = (dr: number): [number, number, number] => 
        {
            const loc_max_length = get_coefficient_01(dr);
            return [loc_max_length, loc_max_length, loc_max_length] as [number, number, number];

            // Interpolacion lineal entre los colores A y B: (color_B - color_A) * val_01 + color_A
            const comp_R = 0.5 * Math.sin(loc_max_length * Math.PI - 0.5 * Math.PI) + 0.5;
            const comp_G = 0.5 * Math.sin(loc_max_length * 2 * Math.PI - 0.5 * Math.PI) + 0.5;
            const comp_B = 0.5 * Math.cos(loc_max_length * Math.PI) + 0.5;

            const color_C = [comp_R, comp_G, comp_B] as [number, number, number];
            return color_C;
        };

        for (let i = 0; i < nVertices; ++i) {
            const dr = vDeformations[6 * i + 5];
            const [rr, gg, bb] = get_deformation_color_blue_red(dr);
            vColors.push(rr, 0 * gg, 0 * bb);
        }
        // Al devolverlo al exterior se debiera hacer esto:
        // const attrib4Col = new THREE.Float32BufferAttribute(vColors, 3);
    }

    private setColor2MeshGraphicObject(name4Obj: string, newColor: THREE.Color): boolean {
        if (!this.gObj_.getObjectByName(name4Obj)) {
            return false;
        }
        const graObj = this.gObj_.getObjectByName(name4Obj) as THREE.Mesh<THREE.BufferGeometry>;
        const material = graObj.material as THREE.MeshBasicMaterial;
        material.vertexColors = false;
        material.color = newColor;
        material.needsUpdate = true;

        return true;
    }

    private setColor2LineSegmentsGraphicObject(name4Obj: string, newColor: THREE.Color): boolean {
        if (!this.gObj_.getObjectByName(name4Obj)) {
            return false;
        }
        const graObj = this.gObj_.getObjectByName(name4Obj) as THREE.LineSegments;
        const material = graObj.material as THREE.LineBasicMaterial;
        material.vertexColors = false;
        material.color = newColor;
        material.needsUpdate = true;

        return true;
    }

    public setPaletteById(id: number): void {
        const vColors: number[] = [];
        let letsProcessBeams = false;

        switch(id) {
            case -1:
                // StructModel3D.setGradientColor(this.nodes_, vColors);
                // Esto mejora la calidad de colores.
                this.createGradientColors4PointsLimits(vColors);
                break;

            case -2:
                // Con esto aplicamos la paleta 2D de Salome-Meca.
                this.createSalomeMecaColor4Points(vColors);
                break;
            case 3:
                // Paleta con solo naranja para la malla que tengamos en pantalla, es decir para quads y triangulos.
                // Ademas de poner naranja los mesh de los shells, tambien cambiamos el color de las aristas.
                const orangeColor = new THREE.Color('orange');
                const whiteColor = new THREE.Color(0xFFFFFF);
                if (this.numTris_) {
                    this.setColor2MeshGraphicObject(StructModel3D.name4Tris, orangeColor);
                    this.setColor2LineSegmentsGraphicObject(StructModel3D.name4TrisEdges, whiteColor);
                }
                if (this.numQuads_) {
                    this.setColor2MeshGraphicObject(StructModel3D.name4Quads, orangeColor);
                    this.setColor2LineSegmentsGraphicObject(StructModel3D.name4QuadsEdges, whiteColor);
                }
                return;

            // Aqui van las paletas plausibles para los tipos de deformacion soportados.
            case 0:
            case 1:
            case 2:
            
            case 4:
            case 5:                
            case 6:
                if (this.numDeformations_) {
                    if (id === 6) {
                        // StructModel3D.setDeformationsColor4DXDYDZ(this.vDeformations_, vColors);
                        // Las 3 combinaciones DXDYDZ.
                        this.createDeformationColors4DXYZ(vColors);
                    } else {
                        // StructModel3D.setDeformationsColor4DR(this.vDeformations_, vColors);
                        // Columnas individuales de la 0 a la 5: DX-DY-DZ-DRX-DRY-DR.
                        this.createDeformationColors4OnlyOneColumnI(id, vColors);
                    }
                } else {
                    const msg = "[WARNING]: No hay deformaciones!!!."
                    console.error(msg);
                    window.alert(msg);
                    return;
                }
                break;

            // Aqui van las paletas para los tipos de F&M's soportados para beams [N, VY, VZ, MT, MFY, MFZ].
            case 10:
                // Aqui iria el none que deja la paleta de beams como estaba. Todo rojo.
                if (this.numBeams_) {
                    const beamsGO = this.gObj_.getObjectByName(StructModel3D.name4Beams) as THREE.LineSegments<THREE.BufferGeometry>
                    const material = beamsGO.material as THREE.LineBasicMaterial;
                    material.color = new THREE.Color('red');
                    material.vertexColors = false;
                    material.needsUpdate = true;
                    return;
                }
                break;
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
            case 16:
                if (this.obj4FaMs_.numTuples && this.obj4FaMs_.typeData === "Beams") {
                    this.createFaMsColors4ColumnI(id - 11, vColors);
                    letsProcessBeams = true;
                } else {
                    const msg = "[WARNING]: No hay datos F&M's para beams!!!."
                    console.error(msg);
                    window.alert(msg);
                    return;
                }
                break;
            
            // Aqui los F&M's para shells [NXX, NYY, NXY, MXX, MYY, MXY, QX, QY].
            case 21:
            case 22:
            case 23:
            case 24:
            case 25:
            case 26:
            case 27:
            case 28:
                if (this.obj4FaMs_.numTuples && this.obj4FaMs_.typeData === "Shells") {
                    this.createFaMsColors4ColumnI(id - 21, vColors);
                } else {
                    const msg = "[WARNING]: No hay datos F&M's para shells!!!."
                    console.error(msg);
                    window.alert(msg);
                    return;
                }
                break;

            default:
                window.alert("Modo de paleta aun no implementado.");
                return;
        }

        // Dejamos los edges como estaban.
        const darkGrayColor = new THREE.Color(0xA9A9A9);
        const whiteColor = new THREE.Color(0xFFFFFF);
        const attrib4Col = new THREE.Float32BufferAttribute(vColors, 3);
        if (this.numTris_) {
            // Faces.
            const trisFGO = this.gObj_.getObjectByName(StructModel3D.name4Tris) as THREE.Mesh<THREE.BufferGeometry>;
            const material = trisFGO.material as THREE.MeshBasicMaterial;
            material.vertexColors = true;
            material.color = whiteColor;
            material.needsUpdate = true;
            trisFGO.geometry.setAttribute('color', attrib4Col);
            trisFGO.geometry.attributes.color.needsUpdate = true;
            this.setColor2LineSegmentsGraphicObject(StructModel3D.name4TrisEdges, darkGrayColor);
        }
        if (this.numQuads_) {
            const quadsFGO = this.gObj_.getObjectByName(StructModel3D.name4Quads) as THREE.Mesh<THREE.BufferGeometry>;
            const material = quadsFGO.material as THREE.MeshBasicMaterial;
            material.vertexColors = true;
            material.color = whiteColor;
            material.needsUpdate = true;
            quadsFGO.geometry.setAttribute('color', attrib4Col);
            quadsFGO.geometry.attributes.color.needsUpdate = true;
            this.setColor2LineSegmentsGraphicObject(StructModel3D.name4QuadsEdges, darkGrayColor);
        }
        if (letsProcessBeams) {
            const beamsGO = this.gObj_.getObjectByName(StructModel3D.name4Beams) as THREE.LineSegments<THREE.BufferGeometry>
            const material = beamsGO.material as THREE.LineBasicMaterial;
            material.color = whiteColor;
            material.vertexColors = true;
            material.needsUpdate = true;
            beamsGO.geometry.setAttribute('color', attrib4Col);
            beamsGO.geometry.attributes.color.needsUpdate = true;
        }
    }

    /**
     * Funcion temporal para pintar un modelo 3D de nodos, beams, quads & tri's a partir de la informacion presente en
     * unos ficheros de texto.
     *
     * @param baseName 
     * @param deformationsFile 
     * @returns 
     */
    public static createStructModel3D_from_files(baseName: string, deformationsFile: string): StructModel3D | null {
        // [1] Primero intentamos cargar los puntos, sin los cuales no hay nada de lo restante.
        let fileName = baseName + ".points";
        // Buffer array que recibe todos los puntos.
        let pointsBA: Float32Array | null = null;
        let numPoints = 0;
    
        const buf = new TextBuffer();
        readTextFile(fileName, buf);
    
        // Esperamos hasta leer todo lo necesario. En caso de error informamos pero nada hacemos.
        // Esto lo hago asi porque la lectura podria ir en segundo plano y tardar mas de la cuenta.
        if (!buf.wait2Load()) {
            window.alert("ERROR al intentar cargar el fichero de puntos '" + fileName + "'");
            return null;
        } else {
            const txt = buf.buffer;
            if (txt.length === 0) {
                window.alert("ERROR al intentar cargar el fichero de puntos '" + fileName + "'");
                return null;
            } else {
                // Leemos los puntos del buffer en un bufferArray, solo si su cardinal es multiplo de 3.
                const res = sendStringNumbers2Array(txt, 3);
                if (res !== null) {
                    pointsBA = res[0] as Float32Array;
                    const isMultiple3 = res[1];
                    const numCoordinates = pointsBA.length;
                    if (!isMultiple3) {
                        console.error("ERROR: Las " + numCoordinates + " coordenadas leidas NO son multiplo de 3!!!.");
                        return null;
                    } else {
                        numPoints = numCoordinates / 3;
                        console.log("Cargados " + numPoints + " puntos 3D o nodos.");
                    }
                } else {
                    window.alert("ERROR al intentar cargar el fichero de puntos '" + fileName + "'");
                    return null;
                }
            }
        }
    
        // [2] Lectura de lineas, posiblemente opcionales.
        // Simple vector de indices IV para las lineas.
        let linesIV: number[] | null = null;
        let numSegments = 0;
    
        buf.reset();
        fileName = baseName + ".lines";
        readTextFile(fileName, buf);
    
        if (!buf.wait2Load()) {
            console.log("ERROR al cargar el fichero de lineas '" + fileName + "'. Posiblemente no hay.");
        } else {
            const txt = buf.buffer;
            if (txt.length === 0) {
                window.alert("ERROR al intentar cargar el fichero de lineas '" + fileName + "'");
                return null;    
            } else {
                // Leemos los indices del buffer y los metemos en un vector, verificando que su cardinal sea multiplo de 2.
                const res = sendStringNumbers2Array(txt, 2, false);
                if (res !== null) {
                    linesIV = res[0] as number[];
                    const isMultiple2 = res[1];
                    const numIndexes = linesIV.length;
                    if (!isMultiple2) {
                        console.log("ERROR: Los " + numIndexes + " indices de linea leidos NO son multiplo de 2!!!.");
                        return null;
                    } else {
                        numSegments = numIndexes / 2;
                        console.log("Cargados " + numSegments + " segmentos de linea.");
                    }
                } else {
                    window.alert("ERROR al intentar cargar el fichero de lineas '" + fileName + "'");
                    return null;
                }
            }
        }
    
        // [3] Lectura de triangulos, posiblemente opcionales.
        let trisIV: number[] | null = null;
        let numTris = 0;
    
        buf.reset();
        fileName = baseName + ".tris";
        readTextFile(fileName, buf);
    
        if (!buf.wait2Load()) {
            console.log("ERROR al cargar el fichero de triangulos '" + fileName + "'. Posiblemente no los hay.");
        } else {
            const txt = buf.buffer;
            if (txt.length === 0) {
                window.alert("ERROR al intentar cargar el fichero de triangulos '" + fileName + "'");
                return null;    
            } else {
                // Leemos los indices del buffer y los metemos en un vector, verificando que su cardinal sea multiplo de 3.
                const res = sendStringNumbers2Array(txt, 3, false);
                if (res !== null) {
                    trisIV = res[0] as number[];
                    const isMultiple3 = res[1];
                    const numIndexes = trisIV.length;
                    if (!isMultiple3) {
                        console.log("ERROR: Los " + numIndexes + " indices de triangulo leidos NO son multiplo de 3!!!.");
                        return null;
                    } else {
                        numTris = numIndexes / 3;
                        console.log("Cargados " + numTris + " triangulos.");
                    }
                } else {
                    window.alert("ERROR al intentar cargar el fichero de triangulos '" + fileName + "'");
                    return null;
                }
            }
        }
    
        // [3] Lectura de quads, posiblemente opcionales.
        let quadsIV: number[] | null = null;
        let numQuads = 0;
    
        buf.reset();
        fileName = baseName + ".quads";
        readTextFile(fileName, buf);
    
        if (!buf.wait2Load()) {
            console.log("ERROR al cargar el fichero de quads '" + fileName + "'. Posiblemente no los hay.");
        } else {
            const txt = buf.buffer;
            if (txt.length === 0) {
                window.alert("ERROR al intentar cargar el fichero de quads '" + fileName + "'");
                return null;    
            } else {
                // Leemos los indices del buffer y los metemos en un vector, verificando que su cardinal sea multiplo de 4.
                const res = sendStringNumbers2Array(txt, 4, false);
                if (res !== null) {
                    quadsIV = res[0] as number[];
                    const isMultiple4 = res[1];
                    const numIndexes = quadsIV.length;
                    if (!isMultiple4) {
                        console.log("ERROR: Los " + numIndexes + " indices de quad leidos NO son multiplo de 4!!!.");
                        return null;
                    } else {
                        numQuads = numIndexes / 3;
                        console.log("Cargados " + numQuads + " quads.");
                    }
                } else {
                    window.alert("ERROR al intentar cargar el fichero de quads '" + fileName + "'");
                    return null;
                }
            }
        }
    
        // [4] Lectura de los datos de deformaciones.
        let deformations: number[] | null = null;
        let numDeformations = 0;
        fileName = deformationsFile;
        buf.reset();
        readTextFile(fileName, buf);
        if (!buf.wait2Load()) {
            console.log("ERROR al cargar el fichero de deformaciones '" + fileName + "'. Posiblemente no las hay.");
        } else {
            const txt = buf.buffer;
            if (txt.length === 0) {
                window.alert("ERROR al intentar cargar el fichero de deformaciones '" + fileName + "'");
                return null;    
            } else {
                const res = sendStringNumbers2Array(txt, 6, false);
                if (res !== null) {
                    deformations = res[0] as number[];
                    const isMultiple6 = res[1];
                    const numDeformationsData = deformations.length;
                    if (!isMultiple6) {
                        console.log("ERROR: Los " + numDeformationsData + " datos de deformacion NO son multiplo de 6!!!.");
                        return null;
                    } else {
                        numDeformations = numDeformationsData / 6;
                        console.log("Cargadas " + numDeformations + " deformaciones.");
                        if (numDeformations !== numPoints) {
                            window.alert("ERROR: Dimensiones de nodos y deformaciones no coincidentes (" + numPoints
                                + " != " + numDeformations + ").");
                            return null;
                        }
                    }
                } else {
                    window.alert("ERROR al intentar cargar el fichero de deformaciones '" + fileName + "'");
                    return null;
                }
            }
        }
    
        const model = new StructModel3D();
        model.setNodes(pointsBA as Float32Array);
        model.setIndices(linesIV as number[], trisIV as number[], quadsIV as number[]);
        if (deformations !== null) {
            model.setDeformations(deformations as number[]);
        }

        const graphicObj = model.getGraphicObject();
        if (graphicObj !== null) {
            graphicObj.name = "mesh_model3D";
            // \Trick: Truco del almendruco!!!.
            // Desde el exterior es mas que posible que solo podamos acceder al objeto grafico Three.js mediante el
            // nombre dado al mismo, pero necesitaremos tambien acceder al propio objeto que aqui devolvemos, que es de
            // tipo StructModel3D; para ello enganchamos el objeto real al objeto grafico con el userData.
            graphicObj.userData = model;
        }
        return model;
    
        // Desde el exterior tocaria hacer lo habitual...
        // scene.add(graphicObj);
    
        // // Sobre nuestro modelo creamos una bonita aabb un poco holgada.
        // const bbox = new THREE.Box3().setFromObject(graphicObj);
        // const gap = 1.0;
        // const v = new THREE.Vector3(gap, gap, gap);
        // bbox.expandByVector(v);
        // bbox.getSize(v);
        // const geom = new THREE.BoxGeometry(v.x, v.y, v.z);
        // const mat = new THREE.MeshBasicMaterial({ color: 0xFFFF00, wireframe: true});
        // const aabb = new THREE.Mesh(geom, mat);
        // // Lo posicionamos donde corresponde.
        // bbox.getCenter(v);
        // aabb.position.set(v.x, v.y, v.z);
        // aabb.name = "aabb";
        // scene.add(aabb);
    }

    /**
     * Lector de JSON desde fichero a puro huevo para mis pruebas internas.
     * Devuelve el objeto JSON enterito, desde la raiz, o bien null en caso de error.
     * @param fileName 
     * @returns 
     */
    public static readJSON(fileName: string): any | null {
        const buf = new TextBuffer();

        // Registramos tiempo de lectura del JSON para ver como va la cosa...
        let t0 = performance.now();

        // Parece que no hay diferencia aparente entre leerlo como simple texto o leerlo como JSON implicito.
        readJSONFile(fileName, buf);
        // readTextFile(fileName, buf);
    
        // Esperamos hasta leer todo lo necesario. En caso de error informamos pero nada hacemos.
        // Esto lo hago asi porque la lectura podria ir en segundo plano y tardar mas de la cuenta.
        if (!buf.wait2Load()) {
            window.alert("[ERROR] No se ha podido cargar el fichero JSON del mesh '" + fileName + "'");
            return null;
        } else {
            const txt = buf.buffer;
            if (txt.length === 0) {
                window.alert("[ERROR] El fichero JSON del mesh '" + fileName + "' NO tiene contenido!!!.");
                return null;
            } else {
                let t1 = performance.now();
                console.info("[FILE LOADING] JSON loaded '" + fileName + "' (" + txt.length + " bytes) " + (t1 - t0).toFixed(3) + " milliseconds.");
            
                // Tenemos aqui el texto de puta madre y lo podriamos parsear.
                t0 = performance.now();
                const obj4JSON = JSON.parse(txt);
                t1 = performance.now();
                console.info("[TEXT PARSING] " + (t1 - t0).toFixed(3) + " milliseconds.");
                return obj4JSON;
            }
        }
        return null;
    }

    /**
     * A partir de un FICHERO JSON con los datos de la malla podemos generar el modelo logico y grafico asociado al
     * mismo, con sus nodes, beams y cells (quad's & tri's) asociados. Ademas opcionalmente podemos incluir un fichero
     * con datos de deformaciones, que no estan soportadas en el modelo JSON.
     *
     * @param fileName 
     * @param deformationsFile 
     * @returns 
     */
    public static createStructModel3D_from_JSON(fileName: string, deformationsFile: string = ""): StructModel3D | null {
        // En este buffer depositamos el texto leido del JSON.
        const buf = new TextBuffer();

        // Registramos tiempo de lectura del JSON para ver como va la cosa...
        let t0 = performance.now();

        // Parece que no hay diferencia aparente entre leerlo como simple texto o leerlo como JSON implicito.
        readJSONFile(fileName, buf);
        // readTextFile(fileName, buf);
    
        // Esperamos hasta leer todo lo necesario. En caso de error informamos pero nada hacemos.
        // Esto lo hago asi porque la lectura podria ir en segundo plano y tardar mas de la cuenta.
        if (!buf.wait2Load()) {
            window.alert("[ERROR] No se ha podido cargar el fichero JSON del mesh '" + fileName + "'");
            return null;
        } else {
            const txt = buf.buffer;
            if (txt.length === 0) {
                window.alert("[ERROR] El fichero JSON del mesh '" + fileName + "' NO tiene contenido!!!.");
                return null;
            } else {
                let t1 = performance.now();
                console.info("[FILE LOADING] JSON loaded '" + fileName + "' (" + txt.length + " bytes) " + (t1 - t0).toFixed(3) + " milliseconds.");
            
                // Tenemos aqui el texto de puta madre y lo podriamos parsear.
                t0 = performance.now();
                const obj4JSON = JSON.parse(txt);
                t1 = performance.now();
                console.info("[TEXT PARSING] " + (t1 - t0).toFixed(3) + " milliseconds.");

                if (obj4JSON.nodes) {
                    const numNodes: number = obj4JSON.nodes.length;
                    console.info(" [NODES]: " + numNodes);
                    // Suponemos el JSON bien formado, pues seria imposible controlar todos los posibles errores...
                    // Ademas si tenemos un chorrazo de puntos no podemos usar la estrategia clasica de crear un array
                    // normal y reconvertirlo a bufferArray, ya que gastariamos demasiada memoria, asi que creamos aqui
                    // directamente ese buffer array con los puntacos previstos.
                    t0 = performance.now();
                    const pointsBA = new Float32Array(3 * numNodes);
                    
                    for (let i = 0; i < numNodes; ++i) {
                        const node = obj4JSON.nodes[i];
                        // Ojo, que el puto pyECore tiene la feature de que ahorra valores cuando algun atributo toma el
                        // valor por defecto, que en este caso serie el 0. Supongo que se aplica a Z y tambien a X e Y.
                        pointsBA[3 * i] = (node.coords.x === undefined) ? 0.0 : node.coords.x;
                        pointsBA[3 * i + 1] = (node.coords.y === undefined) ? 0.0 : node.coords.y;
                        pointsBA[3 * i + 2] = (node.coords.z === undefined) ? 0.0 : node.coords.z;

                        // Descomentar lo de abajo si alguna vez hay problema con el JSON...
                        // if (true) {
                        //     // CPQD: Comprobacion psicopatica que desaparecera.
                        //     if (i !== parseInt(node.id)) {
                        //         console.log("   >>>> Indices no coincidentes: " + i + " !== " + node.id);
                        //     }
                        //     if (node.coords.x === undefined) {
                        //         console.log("N: No hay X en el id " + node.id);
                        //     }
                        //     if (node.coords.y === undefined) {
                        //         console.log("N: No hay Y en el id " + node.id);
                        //     }
                        //     if (node.coords.z === undefined) {
                        //         console.log("N: No hay Z en el id " + node.id);
                        //     }
                        // }
                    }
                    // A continuacion los indices de las beams/lineas.
                    let numBeams = 0;
                    let beamsIV: number[] = [];
                    if (obj4JSON.beams) {
                        numBeams = obj4JSON.beams.length;
                        console.info(" [BEAMS]: " + numBeams);
                        for (let i = 0; i < numBeams; ++i) {
                            const beam = obj4JSON.beams[i];
                            const info = beam.nodes;
                            // Siempre es un vector de 2 componentes, cada uno de los cuales con una propiedad como:
                            // $ref: '//@nodes.194'
                            // De esa forma (nada optima) tenemos almacenados los 2 indices que tendremos que parsear.
                            const ref0 = info[0]['$ref'];
                            const ref1 = info[1]['$ref'];

                            // Descomentar lo de abajo si alguna vez hay problema con el JSON...
                            // if (true) {
                            //     // CPQD: Comprobacion psicopatica que desaparecera.
                            //     if (ref0 === undefined) {
                            //         console.log("B: No hay ref0 en el i " + i);
                            //     }
                            //     if (ref1 === undefined) {
                            //         console.log("B: No hay ref1 en el i " + i);
                            //     }
                            // }
    
                            // Toca sacar el numero contenido en las variables anteriores, que sabemos que empieza en la
                            // posicion [9] y acaba donde acabe el string. Asi que lo sacamos directamente, sin ER ni
                            // otras cosas mas lentas y logicamente suponiendo que siempre empieza por '//@nodes.'
                            // '//@nodes.194'
                            //  012345678^
                            // \Trick: Las formas mas rapidas de sacar un subString y convertirlo a entero.
                            const index0: number = ref0.slice(9) * 1;
                            const index1: number = ref1.slice(9) * 1;

                            // Descomentar lo de abajo si alguna vez hay problema con el JSON...
                            // if (true) {
                            //     // CPQD: Comprobacion psicopatica que desaparecera.
                            //     if (index0 < 0 || numNodes <= index0) {
                            //         // Que no este fuera de rango!!!.
                            //         console.log("B: Indice erroneo ref0[ " + i + "] " + index0);
                            //     }
                            //     if (index1 < 0 || numNodes <= index1) {
                            //         console.log("B: Indice erroneo ref1[ " + i + "] " + index1);
                            //     }
                            // }
  
                            beamsIV.push(index0, index1);
                        }
                    }

                    // Los indices de los triangulos.
                    let numTris = 0;
                    let trisIV: number[] = [];
                    if (obj4JSON.triangles) {
                        numTris = obj4JSON.triangles.length;
                        console.info(" [TRIANGLES]: " + numTris);
                        for (let i = 0; i < numTris; ++i) {
                            const triangle = obj4JSON.triangles[i];
                            const info = triangle.nodes;
                            // Idem que antes, pero con 3 componentes: $ref: '//@nodes.194'
                            const ref0 = info[0]['$ref'];
                            const ref1 = info[1]['$ref'];
                            const ref2 = info[2]['$ref'];

                            // Descomentar lo de abajo si alguna vez hay problema con el JSON...
                            // if (true) {
                            //     // CPQD: Comprobacion psicopatica que desaparecera.
                            //     if (ref0 === undefined) {
                            //         console.log("T: No hay ref0 en el i " + i);
                            //     }
                            //     if (ref1 === undefined) {
                            //         console.log("T: No hay ref1 en el i " + i);
                            //     }
                            //     if (ref2 === undefined) {
                            //         console.log("T: No hay ref2 en el i " + i);
                            //     }
                            // }

                            const index0: number = ref0.slice(9) * 1;
                            const index1: number = ref1.slice(9) * 1;
                            const index2: number = ref2.slice(9) * 1;

                            // Descomentar lo de abajo si alguna vez hay problema con el JSON...
                            // if (true) {
                            //     // CPQD: Comprobacion psicopatica que desaparecera.
                            //     if (index0 < 0 || numNodes <= index0) {
                            //         // Que no este fuera de rango!!!.
                            //         console.log("T: Indice erroneo ref0[ " + i + "] " + index0);
                            //     }
                            //     if (index1 < 0 || numNodes <= index1) {
                            //         console.log("T: Indice erroneo ref1[ " + i + "] " + index1);
                            //     }
                            //     if (index2 < 0 || numNodes <= index2) {
                            //         console.log("T: Indice erroneo ref2[ " + i + "] " + index2);
                            //     }
                            // }

                            trisIV.push(index0, index1, index2);
                        }
                    }

                    // Indices de quads.
                    let numQuads = 0;
                    let quadsIV: number[] = [];
                    if (obj4JSON.quads) {
                        numQuads = obj4JSON.quads.length;
                        console.info(" [QUADS]: " + numQuads);
                        for (let i = 0; i < numQuads; ++i) {
                            const quad = obj4JSON.quads[i];
                            const info = quad.nodes;
                            // Idem que antes, pero con 4 componentes: $ref: '//@nodes.194'
                            const ref0 = info[0]['$ref'];
                            const ref1 = info[1]['$ref'];
                            const ref2 = info[2]['$ref'];
                            const ref3 = info[3]['$ref'];

                            // Descomentar lo de abajo si alguna vez hay problema con el JSON...
                            // if (true) {
                            //     // CPQD: Comprobacion psicopatica que desaparecera.
                            //     if (ref0 === undefined) {
                            //         console.log("Q: No hay ref0 en el i " + i);
                            //     }
                            //     if (ref1 === undefined) {
                            //         console.log("Q: No hay ref1 en el i " + i);
                            //     }
                            //     if (ref2 === undefined) {
                            //         console.log("Q: No hay ref2 en el i " + i);
                            //     }
                            //     if (ref3 === undefined) {
                            //         console.log("Q: No hay ref3 en el i " + i);
                            //     }
                            // }

                            const index0: number = ref0.slice(9) * 1;
                            const index1: number = ref1.slice(9) * 1;
                            const index2: number = ref2.slice(9) * 1;
                            const index3: number = ref3.slice(9) * 1;

                            // Descomentar lo de abajo si alguna vez hay problema con el JSON...
                            // if (true) {
                            //     // CPQD: Comprobacion psicopatica que desaparecera.
                            //     if (index0 < 0 || numNodes <= index0) {
                            //         // Que no este fuera de rango!!!.
                            //         console.log("Q: Indice erroneo ref0[ " + i + "] " + index0);
                            //     }
                            //     if (index1 < 0 || numNodes <= index1) {
                            //         console.log("Q: Indice erroneo ref1[ " + i + "] " + index1);
                            //     }
                            //     if (index2 < 0 || numNodes <= index2) {
                            //         console.log("Q: Indice erroneo ref2[ " + i + "] " + index2);
                            //     }
                            //     if (index3 < 0 || numNodes <= index3) {
                            //         console.log("Q: Indice erroneo ref3[ " + i + "] " + index3);
                            //     }
                            // }

                            quadsIV.push(index0, index1, index2, index3);
                        }
                    }
                    
                    const model = new StructModel3D();
                    model.setNodes(pointsBA);
                    model.setIndices(beamsIV, trisIV, quadsIV);

                    // Por el momento cargamos las deformaciones desde un fichero auxiliar con las mismas, separado del
                    // JSON y que podemos dar opcionalmente.
                    if (deformationsFile.length) {
                        buf.reset();
                        let deformations: number[] | null = null;
                        let numDeformations = 0;
                        readTextFile(deformationsFile, buf);
                        if (!buf.wait2Load()) {
                            console.log("ERROR al cargar el fichero de deformaciones '" + deformationsFile + "'. Posiblemente no las hay.");
                        } else {
                            const txt = buf.buffer;
                            if (txt.length === 0) {
                                window.alert("ERROR al intentar cargar el fichero de deformaciones '" + deformationsFile + "'");
                                return null;    
                            } else {
                                const res = sendStringNumbers2Array(txt, 6, false);
                                if (res !== null) {
                                    deformations = res[0] as number[];
                                    const isMultiple6 = res[1];
                                    const numDeformationsData = deformations.length;
                                    if (!isMultiple6) {
                                        console.log("ERROR: Los " + numDeformationsData + " datos de deformacion NO son multiplo de 6!!!.");
                                        return null;
                                    } else {
                                        numDeformations = numDeformationsData / 6;
                                        console.log("Cargadas " + numDeformations + " deformaciones.");
                                        if (numDeformations !== numNodes) {
                                            window.alert("ERROR: Dimensiones de nodos y deformaciones no coincidentes (" + numNodes
                                                + " != " + numDeformations + ").");
                                            return null;
                                        }
                                    }
                                } else {
                                    window.alert("ERROR al intentar cargar el fichero de deformaciones '" + deformationsFile + "'");
                                    return null;
                                }
                            }
                        }

                        if (deformations) {
                            model.setDeformations(deformations as number[]);
                        }
                    }
            
                    const graphicObj = model.getGraphicObject();
                    if (graphicObj !== null) {
                        graphicObj.name = "mesh_model3D";
                        graphicObj.userData = model;
                    }

                    t1 = performance.now();
                    console.info("[3D MESH MODEL CREATION] " + (t1 - t0).toFixed(3) + " milliseconds.");

                    return model;            
                }
            }
        }
        return null;
    }
    
    /**
     * Constructor del objeto modelo en base a un enorme objeto JSON del que tomamos las partes que necesitemos.
     * En caso de error se devuelve null.
     * 
     * @param fullObjectJSON 
     * @param graPro 
     * @returns 
     */
    public static createStructModel3D_from_JSON_object(
        fullObjectJSON: any,
        graPro: GraphicProcessor | null = null
    ): StructModel3D | null {
        if (fullObjectJSON === null || fullObjectJSON === undefined) {
            return null;
        }

        // Para los calculos fundamentales, del enorme objeto JSON que me llega solo necesito este trozaco, que es el
        // que contiene los puros datos geometricos.
        const obj4JSON = fullObjectJSON["versions"][0]["femmeshstructure"];
        if (obj4JSON === null || obj4JSON === undefined) {
            return null;
        }
    
        let t0: number = 0;
        let t1: number = 0;
        if (obj4JSON.nodes) {
            const numNodes: number = obj4JSON.nodes.length;
            console.info(" [NODES]: " + numNodes);
            // Suponemos el JSON bien formado, pues seria imposible controlar todos los posibles errores...
            // Ademas si tenemos un chorrazo de puntos no podemos usar la estrategia clasica de crear un array
            // normal y reconvertirlo a bufferArray, ya que gastariamos demasiada memoria, asi que creamos aqui
            // directamente ese buffer array con los puntacos previstos.
            t0 = performance.now();
            const pointsBA = new Float32Array(3 * numNodes);

            // ATENCION: En el JSON los id (indices) de los nodos no se usan para nada, sino que todo es posicional, pero
            // como no me fio visto lo visto, vamos a meter un mapa de id's to indices...
            const mId2Index4Nodes = new Map<number, number>();
            for (let i = 0; i < numNodes; ++i) {
                const node = obj4JSON.nodes[i];
                const id = parseInt(node.id);
                if (mId2Index4Nodes.has(id)) {
                    debugger;
                } else {
                    mId2Index4Nodes.set(id, i);
                }
                // console.log("N[" + i + "] ===> " + id);
                // Ojo, que el puto pyECore tiene la feature de que ahorra valores cuando algun atributo toma el
                // valor por defecto, que en este caso serie el 0. Supongo que se aplica a Z y tambien a X e Y.
                pointsBA[3 * i] = (node.coords.x === undefined) ? 0.0 : node.coords.x;
                pointsBA[3 * i + 1] = (node.coords.y === undefined) ? 0.0 : node.coords.y;
                pointsBA[3 * i + 2] = (node.coords.z === undefined) ? 0.0 : node.coords.z;
            }

            if (true) {
                // Comprobamos ordenacion original.
                const vkeys = [...mId2Index4Nodes.keys()];
                if (isConsecutiveArray(vkeys)) {
                    const first = vkeys[0];
                    const last = vkeys[vkeys.length - 1];
                    console.log(`Los indices recibidos para los nodos son 1+CONSECUTIVOS en [${first}, ${last}].`);
                } else {
                    console.log(`Los indices de nodos recibidos no son 1+consecutivos.`);
                }
            }

            const reference2Index = (ref: string): number => {
                // Comprobacion psicopatica que desaparecera: Que la referencia pasada sea correcta.
                if (ref === null || ref === undefined || ref.length === 0) {
                    console.error("ERROR: Referencia vacia: <" + ref + ">");
                    return -1;
                }
                let posPoint = ref.length - 1;
                while (posPoint) {
                    if (ref[posPoint] === '.') {
                        break;
                    } else {
                        --posPoint;
                    }
                }
                // \Trick: Esta es una de las formas mas rapidas de sacar un subString y convertirlo a entero.
                // Tambien podria multiplicar por 1, pero me da error.
                const id: number = +ref.slice(posPoint + 1);
                return id;
            };

            // Aqui meteremos los indices de los nodos correspondientes a los beams, triangulos y quads.
            // Los ponemos aqui para que esten disponibles en la funcion auxiliar addElem().
            // Ademas si detectamos elementos repetidos no los incluimos; es el error detectado por JLuis en "delete".
            let beamsIV: number[] = [];
            let trisIV: number[] = [];
            let quadsIV: number[] = [];

            // Mapa que lleva la topologia, que a cada elemento finito le asigna por id su tipo beam[2]|tri[3]|quad[4]
            // mas el offset donde estan sus 2|3|4 nodos (sus indices) en los pertinentes mapas de indices *IV_.
            const mElems = new Map<number, [number, number]>();
            let numErrorsJL = 0;
            const addElem = (id: number, type234: 2 | 3 | 4, offset: number, newNodes: number[]): boolean => {
                if (!mElems.has(id)) {
                    mElems.set(id, [type234, offset]);
                    return true;
                }

                // Comprobacion del error detectado por JL.
                ++numErrorsJL;
                let letsAlert = false;
                let who = "Quad";
                if (type234 === 3) {
                    who = "Tri";
                } else {
                    if (type234 == 2) {
                        who = "Beam";
                    }
                }
                
                let msg = `[${numErrorsJL}] ERROR: ${who}-finite element with id [${id}] and offset [${offset}] is DUPLICATED!!!.`;
                // Veamos cual era el elemento original.
                const [srcType234, srcOffset] = mElems.get(id) as [number, number];
                const srcNodes: number[] = [];
                let srcWho = "";
                if (srcType234 === 4) {
                    srcWho = "Quad";
                    srcNodes.push(quadsIV[srcOffset]);
                    srcNodes.push(quadsIV[srcOffset + 1]);
                    srcNodes.push(quadsIV[srcOffset + 2]);
                    srcNodes.push(quadsIV[srcOffset + 3]);
                } else {
                    if (srcType234 === 3) {
                        srcWho = "Tri";
                        srcNodes.push(trisIV[srcOffset]);
                        srcNodes.push(trisIV[srcOffset + 1]);
                        srcNodes.push(trisIV[srcOffset + 2]);
                    } else {
                        srcWho = "Beam";
                        srcNodes.push(beamsIV[srcOffset]);
                        srcNodes.push(beamsIV[srcOffset + 1]);
                    }
                }
                msg += `\nSource ${srcWho}-finite element has offset [${srcOffset}].`;
                if (srcType234 !== type234) {
                    msg += "\nDifferent types of finite elements!!!."
                    letsAlert = true;
                } else {
                    // Mismas aridades: Compararemos los nodos.
                    if (!compareArraysOrdered(srcNodes, newNodes)) {
                        letsAlert = true;
                        msg += `\nOld nodes ${srcNodes} <===> New nodes ${newNodes}.`;
                    } else {
                        msg += '\nSame nodes in same order.';
                    }
                }
                if (srcOffset === offset) {
                    msg += "\nSame offset ===> No problem!!!.";
                }

                console.error(msg);
                if (letsAlert) {
                    window.alert(msg);
                }
                return false;
            };

            // A continuacion los indices de las beams/lineas.
            let numBeams = 0;            
            if (obj4JSON.beams) {
                numBeams = obj4JSON.beams.length;
                console.info(" [BEAMS]: " + numBeams);
                for (let i = 0; i < numBeams; ++i) {
                    const beam = obj4JSON.beams[i];
                    const info = beam.nodes;
                    const id = parseInt(beam.id);

                    // Siempre es un vector de 2 componentes, cada uno de los cuales con una propiedad como:
                    // $ref: '//@versions.0/@femmeshstructure/@nodes.441'
                    // De esa forma (nada optima) tenemos almacenados los 2 indices que tendremos que parsear.
                    const ref0 = info[0]['$ref'];
                    const ref1 = info[1]['$ref'];

                    // Toca sacar el numero contenido en las variables anteriores, que ahora sabemos que empieza justo
                    // tras el ultimo punto contenido en la cadena de referencia y acaba donde acabe el string. 
                    const index0: number = reference2Index(ref0);
                    const index1: number = reference2Index(ref1);
                    // console.log("B[" + i + "] ===> " + id + " { " + index0 + ", " + index1 + " }");
                    const offset = beamsIV.length;

                    if (addElem(id, 2, offset, [index0, index1])) {                    
                        beamsIV.push(index0, index1);
                    }
                }
            }

            // Los indices de los triangulos.
            let numTris = 0;
            if (obj4JSON.triangles) {
                numTris = obj4JSON.triangles.length;
                console.info(" [TRIANGLES]: " + numTris);
                for (let i = 0; i < numTris; ++i) {
                    const triangle = obj4JSON.triangles[i];
                    const info = triangle.nodes;
                    const id = parseInt(triangle.id);
                    // Idem que antes, pero con 3 componentes.
                    const ref0 = info[0]['$ref'];
                    const ref1 = info[1]['$ref'];
                    const ref2 = info[2]['$ref'];

                    const index0: number = reference2Index(ref0);
                    const index1: number = reference2Index(ref1);
                    const index2: number = reference2Index(ref2);
                    // console.log("T[" + i + "] ===> " + id + " { " + index0 + ", " + index1 + ", " + index2 + " }");
                    const offset = trisIV.length;

                    if (addElem(id, 3, offset, [index0, index1, index2])) {
                        trisIV.push(index0, index1, index2);
                    }
                }
            }

            // Indices de quads.
            let numQuads = 0;            
            if (obj4JSON.quads) {
                numQuads = obj4JSON.quads.length;
                console.info(" [QUADS]: " + numQuads);
                for (let i = 0; i < numQuads; ++i) {
                    const quad = obj4JSON.quads[i];
                    const info = quad.nodes;
                    const id = parseInt(quad.id);                    
                    // Idem que antes, pero con 4 componentes.
                    const ref0 = info[0]['$ref'];
                    const ref1 = info[1]['$ref'];
                    const ref2 = info[2]['$ref'];
                    const ref3 = info[3]['$ref'];

                    const index0: number = reference2Index(ref0);
                    const index1: number = reference2Index(ref1);
                    const index2: number = reference2Index(ref2);
                    const index3: number = reference2Index(ref3);
                    // console.log("Q[" + i + "] ===> " + id + " { " + index0 + ", " + index1 + ", " + index2 + ", " + index3 + " }");
                    const offset = quadsIV.length;

                    if (addElem(id, 4, offset, [index0, index1, index2, index3])) {
                        quadsIV.push(index0, index1, index2, index3);
                    }
                }
            }

            const model = new StructModel3D();
            if (!model.runStress_) {
                model.setNodes(pointsBA);
                model.setIndices(beamsIV, trisIV, quadsIV);
            } else {
                // En el modo de stress en vez de un solo edificio/bloque de datos, lo replicamos un numero de veces
                // en X y otro numero de veces en Y, dados por los DMC's numXStress_ y numYStress_ de forma que:
                //
                //                      ***  ***  ***
                //         3X * 2Y      ***  ***  ***
                //    *** =========> 
                //    ***               ***  ***  ***
                //                      ***  ***  ***
                //
                // Para ello hay que calcular la AABB de los puntos originales para sacar unos limites y establecer unas
                // separaciones y unos nuevos puntos de origen para los nuevos puntos que incorporamos en los nuevos
                // bloques, y luego generar unos nuevos indices...
                model.setStressData(pointsBA, beamsIV, trisIV, quadsIV);
            }
            // Incorporamos el mapa de elementos finitos.
            model.mElems_ = mElems;

            // Una serie de comprobaciones para ver que todo es correcto. Ya se quitaran...
            if (true) {
                // Visualizamos informacion sobre los datos recibidos.
                console.log(` # Read [${model.numNodes_}] NODES.`);
                const vIds4Beams = model.getIds4AllBeamElements(true);
                if (vIds4Beams.length !== model.numBeams_) {
                    console.error(`Error(1): The number of beams in the model [${model.numBeams_}] is different from the actually received [${vIds4Beams.length}]`);
                    debugger;
                }
                console.log(` # Read [${model.numBeams_}] BEAMS...`);
                if (isConsecutiveArray(vIds4Beams)) {
                    console.log(`\tConsecutive values in the range [${vIds4Beams[0]}, ${vIds4Beams[model.numBeams_ - 1]}].`);
                } else {
                    console.log(`\tNon consecutive values!!! => first/last = {${vIds4Beams[0]}, ${vIds4Beams[model.numBeams_ - 1]}}.`);
                }

                const vIds4Tris = model.getIds4AllTriangleElements(true);
                if (vIds4Tris.length !== model.numTris_) {
                    console.error("Error(2)");
                    debugger;
                }
                console.log(` # Read [${model.numTris_}] TRIANGLES...`);
                if (isConsecutiveArray(vIds4Tris)) {
                    console.log(`\tConsecutive values in the range [${vIds4Tris[0]}, ${vIds4Tris[model.numTris_ - 1]}].`);
                } else {
                    console.log(`\tNon consecutive values!!! => first/last = {${vIds4Tris[0]}, ${vIds4Tris[model.numTris_ - 1]}}.`);
                }
                
                const vIds4Quads = model.getIds4AllQuadElements(true);
                if (vIds4Quads.length !== model.numQuads_) {
                    console.error(`Error(3): The number of quads in the model [${model.numQuads_}] is different from the actually received [${vIds4Quads.length}]`);
                    debugger;
                }
                console.log(` # Read [${model.numQuads_}] QUADS...`);
                if (isConsecutiveArray(vIds4Quads)) {
                    console.log(`\tConsecutive values in the range [${vIds4Quads[0]}, ${vIds4Quads[model.numQuads_ - 1]}].`);
                } else {
                    console.log(`\tNon consecutive values!!! => first/last = {${vIds4Quads[0]}, ${vIds4Quads[model.numQuads_ - 1]}}.`);
                }

                // Tambien vamos a ver si triangulos + quads, es decir shells como un total ocupan un rango consecutivo
                // o hay huecos entre ellos.
                const vIds4Shells = model.getIds4AllShellElements(true);
                if (vIds4Shells.length !== model.numTris_ + model.numQuads_) {
                    console.error(`Error(4): The theoretic number of triangles plus quads [${model.numTris_ + model.numQuads_}] differs from the actually received [${vIds4Shells.length}]`);
                    debugger;
                }
                console.log(` # Read [${model.numTris_ + model.numQuads_}] SHELLS...`);
                if (isConsecutiveArray(vIds4Shells)) {
                    console.log(`\tConsecutive values in the range [${vIds4Shells[0]}, ${vIds4Shells[model.numTris_ + model.numQuads_ - 1]}].`);
                } else {
                    console.log(`\tNon consecutive values!!! => first/last = {${vIds4Shells[0]}, ${vIds4Shells[model.numTris_ + model.numQuads_ - 1]}}.`);
                }

                // Recorremos todos los datos en el orden recibido para imprimir su composicion.
                let allText = "";
                let index = 0;
                let N = model.mElems_.size;
                // Vamos a calcular los indices extremos de nodos, beams, tris, quads, shells y elems.
                let [minNode, maxNode] = [+Infinity, -Infinity];
                let [minBeam, maxBeam] = [+Infinity, -Infinity];
                let [minTri, maxTri] = [+Infinity, -Infinity];
                let [minQuad, maxQuad] = [+Infinity, -Infinity];
                let [minShell, maxShell] = [+Infinity, -Infinity];
                let [minElem, maxElem] = [+Infinity, -Infinity];
                // Indices como beam, tri o quad y como shell y element.
                let i4B = 0;
                let i4T = 0;
                let i4Q = 0;
                let i4S = 0;
                let i4E = 0;

                for (const [iElemJ, [type234, offset4Nodes]] of model.mElems_) {
                    let src4Nodes = model.quadsIV_;
                    const vNodes: number[] = [];
                    let msg = "[" + index + "/" + N + "] ";

                    if (type234 === 2) {
                        src4Nodes = model.beamsIV_;
                        const iN0 = src4Nodes[offset4Nodes];
                        const iN1 = src4Nodes[offset4Nodes + 1];
                        vNodes.push(iN0, iN1);
                        msg += "B-" + i4E + "-" + i4B + ":";
                        if (iElemJ < minBeam) {
                            minBeam = iElemJ;
                        }
                        if (iElemJ > maxBeam) {
                            maxBeam = iElemJ;
                        }
                        ++i4B;
                        ++i4E;
                    } else {
                        if (type234 === 3) {
                            src4Nodes = model.trisIV_;
                            const iN0 = src4Nodes[offset4Nodes];
                            const iN1 = src4Nodes[offset4Nodes + 1];
                            const iN2 = src4Nodes[offset4Nodes + 2];
                            vNodes.push(iN0, iN1, iN2);
                            msg += "T-" + i4E + "-" + i4S + "-" + i4T + ":";
                            if (iElemJ < minTri) {
                                minTri = iElemJ;
                            }
                            if (iElemJ > maxTri) {
                                maxTri = iElemJ;
                            }
                            if (iElemJ < minShell) {
                                minShell = iElemJ;
                            }
                            if (iElemJ > maxShell) {
                                maxShell = iElemJ;
                            }
                            ++i4T;
                            ++i4E;
                            ++i4S;
                        } else {
                            const iN0 = src4Nodes[offset4Nodes];
                            const iN1 = src4Nodes[offset4Nodes + 1];
                            const iN2 = src4Nodes[offset4Nodes + 2];
                            const iN3 = src4Nodes[offset4Nodes + 3];
                            vNodes.push(iN0, iN1, iN2, iN3);
                            msg += "Q-" + i4E + "-" + i4S + "-" + i4Q + ":";
                            if (iElemJ < minQuad) {
                                minQuad = iElemJ;
                            }
                            if (iElemJ > maxQuad) {
                                maxQuad = iElemJ;
                            }
                            if (iElemJ < minShell) {
                                minShell = iElemJ;
                            }
                            if (iElemJ > maxShell) {
                                maxShell = iElemJ;
                            }
                            ++i4Q;
                            ++i4E;
                            ++i4S;
                        }
                    }

                    if (iElemJ < minElem) {
                        minElem = iElemJ;
                    }
                    if (iElemJ > maxElem) {
                        maxElem = iElemJ;
                    }

                    for (const node of vNodes) {
                        if (node < minNode) {
                            minNode = node;
                        }
                        if (node > maxNode) {
                            maxNode = node;
                        }
                    }
                    msg += iElemJ + " = { " + vNodes + " }   ===>   " + model.getString4IndicesPoints(vNodes);
                    // console.log(msg);
                    msg += "\n";
                    allText += msg;
                    index += 1;
                }
                let txt = `Range of used NODES[${model.numNodes_}]: {${minNode}, ${maxNode}}`;
                console.log(txt);
                allText += txt + "\n";
                txt = `Range of BEAMS[${model.numBeams_}]:  {${minBeam}, ${maxBeam}}`;
                console.log(txt);
                allText += txt + "\n";
                txt = `Range of TRIS[${model.numTris_}]:   {${minTri}, ${maxTri}}`;
                console.log(txt);
                allText += txt + "\n";
                txt = `Range of QUADS[${model.numQuads_}]:  {${minQuad}, ${maxQuad}}`;
                console.log(txt);
                allText += txt + "\n";
                txt = `Range of SHELLS[${model.numTris_ + model.numQuads_}]: {${minShell}, ${maxShell}}`;
                console.log(txt);
                allText += txt + "\n";
                const numElements = model.numTris_ + model.numQuads_ + model.numBeams_;
                const numElementsPlusNodes = numElements + model.numNodes_;
                txt = `Range of ELEMS[${numElements}/${numElementsPlusNodes}]:  {${minElem}, ${maxElem}}`;
                console.log(txt);
                allText += txt + "\n";

                // Lo salvamos.
                const name = "SRC_mesh_3D.dat";
                saveFile(allText, name, "application/json");
            }

            // Si se dio un procesador grafico lo incorporamos pues sera necesario para calculos de COG.
            if (graPro) {
                model.owner = graPro;
            }

            // Para el calculo de DESPLOMES (aka STOREY DRIFT) y otros necesitamos leer la informacion de storeys, para
            // asi poder relacionar los nodos/tris/quads con la planta a la que pertenecen, calcular CDG, etc...
            const infoStoreysJSON = fullObjectJSON["versions"][0]["building"]["storeys"];
            model.readStoreysInfo(infoStoreysJSON);
            const infoStructuralElements = fullObjectJSON["versions"][0]["femStructuralElements"];
            // Sacamos un mapa con los indices de las plantas y los indices de sus grupos separados por slabs.
            const mStorey2Groups = model.readStructuralElementsInfo(infoStructuralElements);

            model.readAllGroups4FemMeshStructure(obj4JSON["groups"], mStorey2Groups);
            model.calculateCentersOfGravity4Storeys();
            // Tras calcular los COG por planta + slab calculamos los indices de los elementos SHELL (tri's o quad's)
            // dentro de los que cae ese COG.
            model.calculateShellsIds4StoreysCOGs();

            const graphicObj = model.getGraphicObject();
            if (graphicObj !== null) {
                graphicObj.name = "mesh_model3D";
                graphicObj.userData = model;
            }

            // Al modelo grafico 3D le agregamos items graficos para denotar los COG en cada slab de los storeys.
            model.drawCOGs();

            t1 = performance.now();
            console.info("[3D MESH MODEL CREATION] " + (t1 - t0).toFixed(3) + " milliseconds.");

            if (numErrorsJL) {
                const msg = `WARNING: There were ${numErrorsJL} possible duplication problems.\n`
                    + `Maybe the size for waffle slab is some coarse-grained???.\n`
                    + 'Change it and recalculate meshing please.';
                console.error(msg);
                window.alert(msg);
            }

            return model;
        }

        return null;
    }

    private drawCOGs(): void {
        
        const group4COGs = new THREE.Group();
        group4COGs.name = StructModel3D.name4COGs;
        const radius = 0.5;
        const numRadials = 12;
        const numCircles = 4;
        const numDivisions = 64;
        const color1 = "black";
        const color2 = "white";
        // Geometria de la bolita.
        const radius2 = 0.5 * radius;
        const geom = new THREE.IcosahedronBufferGeometry(radius2, 1);
        // Y color.
        const color = new THREE.Color();				
        const count = geom.attributes.position.count;
        geom.setAttribute('color', new THREE.BufferAttribute(new Float32Array(count * 3), 3));
        const colors = geom.attributes.color;
        const positions = geom.attributes.position;

        for (let i = 0; i < count; i ++) {
            color.setHSL((positions.getY(i) / radius2 + 1 ) / 2, 1.0, 0.5);
            // color.setHSL(0, (positions.getY(i) / radius2 + 1) / 2, 0.5);
            // color.setRGB(1, 0.8 - (positions.getY(i) / radius2 + 1) / 2, 0);
            colors.setXYZ(i, color.r, color.g, color.b);
        }

        const mat = new THREE.MeshBasicMaterial( {
            color: 0xffffff,
            flatShading: true,
            vertexColors: true,
        });
        const matWire = new THREE.MeshBasicMaterial({ color: "white", wireframe: true, transparent: true });

        // Las funciones de crecimiento y decrecimiento de las bolicas.
        // https://github.com/mrdoob/three.js/blob/master/examples/webgl_instancing_scatter.html
        // Source: https://gist.github.com/gre/1650294
		const easeOutCubic = (t: number) => {
			return (--t) * t * t + 1;
		};
        const scaleCurve = (t: number) => {
            return Math.abs(easeOutCubic((t > 0.5 ? 1 - t : t) * 2));
        };
        
        for (const [indexSt, infoSt] of this.mStoreys_) {
            for (let i = 0; i < infoSt.cntSlabs; ++i) {
                const COG = infoSt.vCOG[i];

                const gItem = new THREE.PolarGridHelper(radius, numRadials, numCircles, numDivisions, color1, color2);
                const gItem2 = new THREE.AxesHelper(2 * radius);
				gItem.position.set(COG.x, COG.y, COG.z);
                gItem.rotateX(-Math.PI/2);
                gItem2.position.set(COG.x, COG.y, COG.z);
                group4COGs.add(gItem);
                group4COGs.add(gItem2);

                // Ademas del campo polar y de los ejes, meto una bolita.
                const gItem3 = new THREE.Mesh(geom, mat);
                gItem3.name = "ball";
                const wireframe = new THREE.Mesh(geom, matWire);
                gItem3.add(wireframe);
                gItem3.position.set(COG.x, COG.y, COG.z);

                // Para cambiar dinamicamente el tamaño de las bolas.
                // No se puede hacer en el grupo, sino con cosas renderizables.
                gItem3.onBeforeRender = () => {
                    const t = Date.now() % 1000;
                    // const f = 0.001 * t;
                    const f = scaleCurve(0.001 * t);
                    gItem3.scale.set(f, f, f);
                };

                group4COGs.add(gItem3);

                gItem.raycast = gItem2.raycast = gItem3.raycast = wireframe.raycast = () => {};
            }
        }

        const graphicObj = this.getGraphicObject() as THREE.Group;        
        graphicObj.add(group4COGs);


    }

    private getString4IndicesPoints(v: number[]): string {
        let txt = "[ ";
        for (const index of v) {
            const x = this.nodes_[3 * index];
            const y = this.nodes_[3 * index + 1];
            const z = this.nodes_[3 * index + 2];
            txt += "(" + x + ", " + y + ", " + z + ") ";
        }
        txt += "]"
        return txt;
    }

    public static createStructModel3D_from_JSON_graphicProcessor(obj4JSON: any, graPro: GraphicProcessor): StructModel3D | null {

        // De momento para probar ciertas cosas...
        if (false) {
            // debugger;
            // const nums = new Numbers7SD(0.10, 2.5, 0.5);
            // const obj3D = nums.createRandomNumber(1234567890, 0.5, 0.5);
            // const sceneMngr = graPro.getSceneManager();
            // sceneMngr.mainScene.add(obj3D);

            // Probamos el parser generico de ficheros de datos.
            const gPrsr = new GenParser();
            gPrsr.readData4File('/files_mesh3d/hyp_disp_cdti.txt');
            gPrsr.destroy();
            gPrsr.readData4File('/files_mesh3d/hyp_forc_cdti.txt');
        }

        if (obj4JSON === null || obj4JSON === undefined) {
            const msg = "\ERROR: El objeto JSON recibido es null/undefined\nHay que volver a ejecutar el mallado.";
            console.error(msg);
            window.alert(msg);
            return null;
        }
        if (graPro === null || graPro === undefined) {
            return null;
        }

        // Si hay algo previo me lo cargo.
        graPro.destroyStructModel3D();

        // SOLO DISPONIBLE EN MODO DEVELOPER.
        // Para guardar el JSON recibido en fichero, de cara a cargarlo en otros experimentos sin tener que esperar los
        // minutos de mallado.
        if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "dev") {
            // Salvamos el JSON a fichero con este nombre.
            const name = "mesh_3d.json";
            console.info("Saving current mesh JSON to file...");
            const t0 = performance.now();
            let txt2Save = JSON.stringify(obj4JSON);
            const size = txt2Save.length;            
            saveFile(txt2Save, name, "application/json");
            const t1 = performance.now();
            console.info("[JSON SAVING] '" + name + "' (" + size + " bytes) " + (t1 - t0).toFixed(3) + " milliseconds.");
            // Ayudo al GC.
            txt2Save = "";
        }

        // Le conectamos a su dueño-creador para darle acceso a su infrastructura.
        const model3D = StructModel3D.createStructModel3D_from_JSON_object(obj4JSON, graPro);
        if (model3D) {
            const graphicObj = model3D.getGraphicObject() as THREE.Object3D;
            // Inicialmente lo ponemos visible para que el usuario sepa que lo tiene disponible.
            graphicObj.visible = true;
            // Ademas lo agregamos fuera de capas y pollas, en un sitio aparte de donde lo recuperaremos por su nombre.
            const sceneMngr = graPro.getSceneManager();
            // Ojo, que el unmount() tambien destruimos todo lo implicado en este modelo.
            sceneMngr.mainScene.add(graphicObj);
            return model3D;
        }
        window.alert("ERROR: No se ha podido crear el modelo 3D del mesh!!!.");
        return null;
    }

    /**
     * Truculento mecanismo para que Efren se conecte desde el exterior...
     * OJO: Estamos asumiendo que el modelo es un singleton!!!.
     * @param graPro Procesador grafico en cuya escena fue creado el modelo de mallado.
     * @returns 
     */
    public static getCurrentModel(graPro: GraphicProcessor): StructModel3D | null {
        const meshName = "mesh_model3D";
        const sceneMngr = graPro.getSceneManager();
        const obj3D = sceneMngr.mainScene.getObjectByName(meshName);
        if (obj3D) {
          if (obj3D.userData) {
            // Del objeto grafico saco el modelo inicial.
            const model3D = obj3D.userData as StructModel3D;
            return model3D;
          }
        }
        window.alert(`WARNING: Todavia no se ha creado modelo de mallado.\n
            Para ello antes se deben pulsar en el subarbol '*Mesh', en 'Mesh properties' los botones:\n
            'Launch meshing' o bien 'View mesh'.`);
        return null;
    }

    public setDeformations4File(deformationsFile: string): boolean {
        if (this.vDeformations_) {
            this.vDeformations_.length = 0;
            this.vDeformations_ = null as unknown as number[];
            this.numDeformations_ = 0;
        }
        if (this.numNodes_ === 0) {
            return false;
        }

        // Por el momento cargamos las deformaciones desde un fichero auxiliar con las mismas, separado del JSON y que
        // podemos dar opcionalmente. Ademas creo que podemos hacerlo incluso antes de crear el grafico.
        if (deformationsFile.length) {
            // En este buffer depositamos el texto leido del fichero.
            const buf = new TextBuffer();
            readTextFile(deformationsFile, buf);
            if (!buf.wait2Load()) {
                console.log("ERROR al cargar el fichero de deformaciones '" + deformationsFile + "'. Posiblemente no las hay.");
            } else {
                const deformationsStr = buf.buffer;
                if (deformationsStr.length === 0) {
                    window.alert("ERROR al intentar cargar el fichero de deformaciones '" + deformationsFile + "'");
                    return false;
                } else {
                    // No volvemos a salvar algo que proviene del disco.
                    return this.setDeformations4String(deformationsStr, false);
                }
            }
        }
        return false;
    }

    /**
     * A esta funcion debera llamar Efren desde el exterior, antes de irse ;).
     * Pasos en el GUI para llegar a que se llame esta fmc:
     * [0] Tener previamente cargado el proyecto "meshing demo", y crear su modelo de mesh, que es el unico con datos,
     * aunque sean ficticios por el momento. Una vez que consigamos ver la malla grafica podemos seguir...
     * [1] ">Code analysis" ===> "*Hypothesis" ===> Sale una botonera "Hypothesis" y hay que activar sus checkboxes de
     * "SELF_WEIGHT", "RESIDENTIAL" & "OFFICE".
     * [2] Pulsar el boton "Solve code analysis" y da mensaje de error.
     * [3] En el arbol del GUI, bajo "*Hypothesis" se crea un nodo (nuevo) ">Solution" ===> ">Hypothesis results" mas
     * un subnodo por cada una de las hipotesis antes seleccionadas en [1], y dentro de cada una de esas hipotesis
     * tendremos los subnodos que contienen "*Displacements", "*Beams F&M", "*Shells F&M" & "*Nodal F&M".
     * [4] Al pulsar sobre "*Displacements" se cargaran automaticamente los desplazamientos y se llamara a esta fmc.
     * Ojo que solo hay desplazamientos y no tenemos esfuerzos y momentos aun, ni ficticios.
     * 
     * Ademas incluimos la posibilidad de salvar los resultados recibidos para poderlos recuperar en el futuro.
     *
     * @param deformationsStr 
     * @param save2File 
     * @returns 
     */
    public setDeformations4String(deformationsStr: string, save2File = true): boolean {
        if (save2File) {
            // De momento dejamos este mensaje para ver que efectivamente han llegado datos de deformaciones.
            window.alert("Recibidos datos de DEFORMACIONES.");
            // Y ademas salvamos los datos recibidos por si hace falta mirarlos o volverlos a cargar.
            const name = "mesh_deformations_back.dat";
            saveFile(deformationsStr, name, "application/json");
        }

        // Borramos los datos anteriormente puestos, de haberlos, ya que esto puede ser costoso en cuanto a memoria y
        // no podemos mantener varios conjuntos de datos de deformaciones simultaneamente, de momento.
        // \DuDa: Preguntar a Paul LittleField por ello.
        if (this.vDeformations_) {
            this.vDeformations_.length = 0;
            this.vDeformations_ = null as unknown as number[];
            this.numDeformations_ = 0;
        }
        if (this.numNodes_ === 0) {
            return false;
        }

        if (deformationsStr.length === 0) {
            window.alert("ERROR: La cadena de deformaciones recibida es vacia!!!.");
            return false;
        } else {
            const t0 = performance.now();

            // YA LLEGAN LOS DATOS CON EL NUEVO FORMATO!!!.
            // Usamos el parser generico de ficheros de datos.
            const gPrsr = new GenParser();

            // Aqui leerian los datos de deformaciones que son:
            // [0] 'DX'   [1] 'DY'   [2] 'DZ'   [3] 'DRX'   [4] 'DRY'   [5] 'DR'
            const result = gPrsr.readData4String(deformationsStr);
            if (!result) {
                window.alert("ERROR al parsear el chorizo de deformaciones (I)!!!.");
                return false;
            }

            // Saquemos los datos haciendo una copia local.
            const deformations: number[] = [];
            let numDeformations = 0;
    
            const getData = (DX: number, DY: number, DZ: number, DRX: number, DRY: number, DR: number): void => {
                deformations.push(DX, DY, DZ, DRX, DRY, DR);
                ++numDeformations;
            };

            // Sacamos todos los datos en el orden original. \ToDo: Quizas sea mas rapido algo directo...
            let res = gPrsr.applyFunctor2Columns(getData, gPrsr.titles_);
            if (res) {
                this.setDeformations(deformations as number[]);
                const t1 = performance.now();
                console.info("[setDeformations4String] (" + deformationsStr.length + " bytes) " + (t1 - t0).toFixed(3) + " mSecs.");
                return true;
            } else {
                window.alert("ERROR al recoger los datos de deformaciones.");
            }
        }
        return false;
    }

    public setEsfuerzos4String(str4FaMs: string): boolean {
        if (true) {
            // De momento dejamos este mensaje para ver que efectivamente han llegado datos de deformaciones.
            window.alert("Recibidos datos de ESFUERZOS y MOMENTOS (F&M).");
            // Y ademas salvamos los datos recibidos por si hace falta mirarlos o volverlos a cargar.
            const name = "mesh_FaMs_back.dat";
            saveFile(str4FaMs, name, "application/json");
        }

        this.obj4FaMs_.vData.length = 0;
        this.obj4FaMs_.dim = 0;
        this.obj4FaMs_.numTuples = 0;
        this.obj4FaMs_.typeData = "None";
        this.obj4FaMs_.vCols.length = 0;
        this.obj4FaMs_.vRngs.length = 0;

        if (this.numNodes_ === 0) {
            return false;
        }

        if (str4FaMs.length === 0) {
            window.alert("ERROR: La cadena de F&Ms recibida es vacia!!!.");
            return false;
        }

        const t0 = performance.now();
        const gPrsr = new GenParser();
        const includeMN = true;
        const result = gPrsr.readData4String(str4FaMs, includeMN);
        if (!result) {
            window.alert("ERROR al parsear la cadena de F&M's!!!.");
            return false;
        }

        // Saquemos los datos haciendo una copia local.
        const arrayOfFaMs = [...gPrsr.data_];
        console.log(`DATA COLUMNS:`);
        console.log(`\t[${gPrsr.titles_}]`);

        // Y ahora toca el trasvase de los datos en el parser a nuestro objeto F&M.
        this.obj4FaMs_.vData = arrayOfFaMs;
        this.obj4FaMs_.dim = gPrsr.titles_.length;
        this.obj4FaMs_.numTuples = gPrsr.numTuples_;
        // De momento lo hago simple...
        if (this.obj4FaMs_.dim === 8) {
            this.obj4FaMs_.typeData = "Beams";

            const name = "mesh_FaMs_Beams_back.dat";
            saveFile(str4FaMs, name, "application/json");
        } else {
            if (this.obj4FaMs_.dim === 10) {
                this.obj4FaMs_.typeData = "Shells";

                const name = "mesh_FaMs_Shells_back.dat";
                saveFile(str4FaMs, name, "application/json");    
            } else {
                window.alert("ERROR: Tipo de F&M todavia no implementado???.")
                this.obj4FaMs_.typeData = "None";
            }
        }
        this.obj4FaMs_.vCols = gPrsr.titles_;
        for (let i = 0; i < this.obj4FaMs_.dim; ++i) {
            this.obj4FaMs_.vRngs.push([+Infinity, -Infinity]);
        }

        // Con esto rellenamos los rangos en this.obj4FaMs_.vRngs a partir de los datos sensibles.
        this.fillRanges4FaMs();

        if (true) {
            // Comprobamos la topologia de los datos recibidos, que almacenaremos en este mapa que a cada indice de
            // elemento le asigna los indices de sus nodos asociados. Tambien almacenamos los nodos usados en un set.
            const mElems = new Map<number, number[]>();
            const sNodes = new Set<number>();
            for (let i = 0; i < this.obj4FaMs_.numTuples; ++i) {
                const i4ElemI = this.obj4FaMs_.vData[this.obj4FaMs_.dim * i];
                // A los indices de los nodos los hacemos 0-based.
                const i4Node = this.obj4FaMs_.vData[this.obj4FaMs_.dim * i + 1] - 1;

                sNodes.add(i4Node);

                if (i4Node >= this.numNodes_ || i4Node < 0) {
                    console.error(`ERROR: Invalid id for node: ${i4Node}`);
                }

                if (mElems.has(i4ElemI)) {
                    let vNodes = mElems.get(i4ElemI);
                    if (-1 === vNodes?.indexOf(i4Node)) {
                        vNodes.push(i4Node);
                        vNodes.sort((a, b) => a - b);
                    } else {
                        console.error("ERROR: Nodo repetido???.");
                        debugger;
                    }
                } else {
                    mElems.set(i4ElemI, [i4Node]);
                }
            }

            const vNodes = [...sNodes].sort((a, b) => a - b);
            console.log(`Nodes [${vNodes.length}] used in F&M data [${vNodes}]`);
            console.log(`\tFirst: ${vNodes[0]}`);
            console.log(`\tLast:  ${vNodes[vNodes.length - 1]}`);

            // Recorrido para comprobar coherencia de datos respecto al meshing original: El rollo de las movedizas...
            if (true) {
                let [minNode, maxNode] = [+Infinity, -Infinity];
                let [minBeam, maxBeam] = [+Infinity, -Infinity];
                let [minTri, maxTri] = [+Infinity, -Infinity];
                let [minQuad, maxQuad] = [+Infinity, -Infinity];
                let [minShell, maxShell] = [+Infinity, -Infinity];
                let [minElem, maxElem] = [+Infinity, -Infinity];

                let index = 0;
                let N = mElems.size;
                let allText = "";
                let numTris = 0;
                let numQuads = 0;
                let numBeams = 0;

                // Indices como beam, tri o quad y como shell y element.
                let i4B = 0;
                let i4T = 0;
                let i4Q = 0;
                let i4S = 0;
                let i4E = 0;

                for (const [iElemJ, vNodes] of mElems) {
                    let msg = "[" + index + "/" + N + "] ";
                    const type234 = vNodes.length;

                    if (type234 === 2) {
                        ++numBeams;
                        msg += "B-" + i4E + "-" + i4B + ":";
                        if (iElemJ < minBeam) {
                            minBeam = iElemJ;
                        }
                        if (iElemJ > maxBeam) {
                            maxBeam = iElemJ;
                        }
                        ++i4B;
                        ++i4E;
                    } else {
                        if (type234 === 3) {
                            ++numTris;
                            msg += "T-" + i4E + "-" + i4S + "-" + i4T + ":";
                            if (iElemJ < minTri) {
                                minTri = iElemJ;
                            }
                            if (iElemJ > maxTri) {
                                maxTri = iElemJ;
                            }
                            if (iElemJ < minShell) {
                                minShell = iElemJ;
                            }
                            if (iElemJ > maxShell) {
                                maxShell = iElemJ;
                            }
                            ++i4T;
                            ++i4E;
                            ++i4S;
                        } else {
                            ++numQuads;
                            msg += "Q-" + i4E + "-" + i4S + "-" + i4Q + ":";
                            if (iElemJ < minQuad) {
                                minQuad = iElemJ;
                            }
                            if (iElemJ > maxQuad) {
                                maxQuad = iElemJ;
                            }
                            if (iElemJ < minShell) {
                                minShell = iElemJ;
                            }
                            if (iElemJ > maxShell) {
                                maxShell = iElemJ;
                            }
                            ++i4Q;
                            ++i4E;
                            ++i4S;
                        }
                    }

                    if (iElemJ < minElem) {
                        minElem = iElemJ;
                    }
                    if (iElemJ > maxElem) {
                        maxElem = iElemJ;
                    }

                    for (const node of vNodes) {
                        if (node < minNode) {
                            minNode = node;
                        }
                        if (node > maxNode) {
                            maxNode = node;
                        }
                    }
                    msg += iElemJ + " = { " + vNodes + " }   ===>   " + this.getString4IndicesPoints(vNodes);                    
                    console.log(msg);
                    msg += "\n";
                    allText += msg;
                    index += 1;
                }

                let txt = `Range of used NODES[${this.numNodes_}]: {${minNode}, ${maxNode}}`;
                console.log(txt);
                allText += txt + "\n";
                txt = `Range of BEAMS[${this.numBeams_}:${numBeams}]:  {${minBeam}, ${maxBeam}}`;
                console.log(txt);
                allText += txt + "\n";
                txt = `Range of TRIS[${this.numTris_}:${numTris}]:   {${minTri}, ${maxTri}}`;
                console.log(txt);
                allText += txt + "\n";
                txt = `Range of QUADS[${this.numQuads_}:${numQuads}]:  {${minQuad}, ${maxQuad}}`;
                console.log(txt);
                allText += txt + "\n";
                txt = `Range of SHELLS[${this.numTris_ + this.numQuads_}]: {${minShell}, ${maxShell}}`;
                console.log(txt);
                allText += txt + "\n";
                const numElements = this.numTris_ + this.numQuads_ + this.numBeams_;
                const numElementsPlusNodes = numElements + this.numNodes_;
                txt = `Range of ELEMS[${numElements}/${numElementsPlusNodes}]:  {${minElem}, ${maxElem}}`;
                console.log(txt);
                allText += txt + "\n";

                // Lo salvamos.
                const name = "NEW_mesh_3D.dat";
                saveFile(allText, name, "application/json");
            }

            if (true) {
                // Esta es la roseta que nos permitira la traduccion.
                this.obj4FaMs_.mapNew2SrcElems = this.matchNewMeshingTopology(mElems);
                return true;
            }

            if (this.obj4FaMs_.typeData === "Beams") {
                const vKeys0 = this.getIds4AllBeamElements();
                const vKeys1 = [...mElems.keys()]
                if (vKeys0.length !== vKeys1.length) {
                    debugger;
                }
                let numNon = 0;
                for (let i = 0; i < this.numBeams_; ++i) {
                    const iBeam0 = vKeys0[i];
                    const iBeam1 = vKeys1[i];
                    const vIds0 = this.getNodes4ElementI(iBeam0) as [number, number];
                    const vIds1 = mElems.get(iBeam1) as number[];
                    if (compareArraysNoOrder(vIds0, vIds1)) {
                        debugger;
                    } else {
                        console.log(`[${i}/${this.numBeams_}] ===> Beam${iBeam0} [${vIds0}] vs Beam${iBeam1} [${vIds1}]`);
                        // Parece que a veces se cumple que si a los componentes de vIds0 (los originales), les sumamos
                        // 1 entonces coinciden con vIds1.
                        const v2 = [vIds0[0] + 1, vIds0[1] + 1];
                        if (compareArraysNoOrder(v2, vIds1)) {
                            console.log("\t+1!!!");
                        } else {
                            console.log("\tNON.");
                            ++numNon;
                        }
                    }
                }
                debugger;
                if (numNon) {
                    console.log(`Hay [${numNon}/${this.numBeams_}] divergencias."`);
                } else {
                    console.log(`Concidencia total +1 para los [${this.numBeams_}] beams!!!.`);
                    let solved = 0;
                    if (isConsecutiveArray(vKeys0)) {
                        const first = vKeys0[0];
                        const last = vKeys0[vKeys0.length - 1];
                        console.log(`Los id's originales son consecutivos en [${first}, ${last}].`);
                        ++solved;
                    } else {
                        console.log(`Los id's originales NO son consecutivos.`);
                    }
                    if (isConsecutiveArray(vKeys1)) {
                        const first = vKeys1[0];
                        const last = vKeys1[vKeys1.length - 1];
                        console.log(`Los id's recibidos en F&M son consecutivos en [${first}, ${last}].`);
                        ++solved;
                    } else {
                        console.log(`Los id's F&M NO son consecutivos.`);
                    }

                    // SOLUCION FINAL PARA ADAPTAR LOS INDICES DE LOS DATOS RECIBIDOS F&M A LOS DATOS ORIGINALES DE LA
                    // MALLA TANTO PARA NODOS COMO PARA BEAMS:
                    if (solved === 2) {
                        // En los datos F&M recibidos a los indices de nodos les restamos 1 para hacerlos 0-based, y a
                        // a los indices de los beams les restamos su minimo y le sumamos el minimo de los indices beam
                        // originales.
                        const minIdSrc = vKeys0[0];
                        const minIdRec = vKeys1[0];

                        const N = this.obj4FaMs_.numTuples;
                        const D = this.obj4FaMs_.dim;
                        const V = this.obj4FaMs_.vData;
                
                        for (let i = 0; i < N; ++i) {
                            // Modifica el indice de elemento beam en la primera posicion.
                            V[i * D] = V[i * D] - minIdRec + minIdSrc;
                            // Modifica el indice de nodo en la segunda posicion.
                            V[i * D + 1] -= 1;
                        }
                    }
                }
            } else {
                if (this.obj4FaMs_.typeData === "Shells") {
                    const vKeys0 = this.getIds4AllShellElements();
                    const vKeys1 = [...mElems.keys()]
                    if (vKeys0.length !== vKeys1.length) {
                        debugger;
                    }

                    // Vamos a contar los triangulos recibidos en los datos F&M para ver si son coherentes.
                    const NTQ = this.numTris_ + this.numQuads_;
                    let numTris1 = 0;
                    let numQuads1 = 0;
                    for (let i = 0; i < Math.min(NTQ, vKeys1.length); ++i) {
                        const iShell1 = vKeys1[i];
                        const vIds1 = mElems.get(iShell1) as number[];
                        if (vIds1.length === 3) {
                            ++numTris1;
                        }
                        if (vIds1.length === 4) {
                            ++numQuads1;
                        }
                    }
                    if (numTris1 + numQuads1 !== NTQ) {
                        debugger;
                    }
                    if (numTris1 !== this.numTris_) {
                        debugger;
                    }
                    if (numQuads1 !== this.numQuads_) {
                        debugger;
                    }

                    // Ordeno ambos vectores de claves, ya que de no hacerlo hay MAS incoherencias T-Q.
                    vKeys0.sort((a, b) => a - b);
                    vKeys1.sort((a, b) => a - b);

                    for (let i = 0; i < NTQ; ++i) {
                        const iShell0 = vKeys0[i];
                        const iShell1 = vKeys1[i];
                        const vIds0 = this.getNodes4ElementI(iShell0);
                        const vIds1 = mElems.get(iShell1) as number[];
                        if (compareArraysNoOrder(vIds0, vIds1)) {
                            debugger;
                        } else {
                            console.log(`[${i}/${NTQ}] ===> Shell${iShell0} [${vIds0}] vs Shell${iShell1} [${vIds1}]`);
                            // Veamos si se cumplen las cardinalidades, es decir que no se mezclan tri's con quad's.
                            if (vIds0.length !== vIds1.length) {
                                console.error(`\tERROR: Divergence T-Q!!!.`);
                            }
                        }
                    }
                    debugger;    
                }
            }
        }

        return true;
    }

    public setIntegrationPoints4File(filename: string): boolean {
        if (this.iPoints_) {
            this.iPoints_ = null as unknown as Float32Array;
            this.numIPoints_ = 0;
        }
        if (this.vIPIOAs_) {
            this.vIPIOAs_.length = 0;
            this.vIPIOAs_ = null as unknown as [number, number, number][];
        }
        if (this.numNodes_ === 0) {
            return false;
        }

        if (filename.length) {
            const buf = new TextBuffer();
            readTextFile(filename, buf);
            if (!buf.wait2Load()) {
                console.log("ERROR when loading integration points file '" + filename + "'. Maybe empty.");
            } else {
                const strIP = buf.buffer;
                if (strIP.length === 0) {
                    window.alert("ERROR when loading integration points file '" + filename + "'");
                    return false;
                } else {
                    // Ahora no cargamos a huevo el fichero de Salome-Meca, sino un CSV por lo que usamos el GenParser.
                    const gPrsr = new GenParser();

                    // Aqui leeremos los datos de los puntos de integracion dados sobre los elementos, pero correspondientes
                    // a sus nodos implicados, con una cabecera de la forma:
                    // ELEMENT,IP,X,Y,Z,W
                    // Que al final quedara solo con X,Y,Z y un mapa de elementos...
                    const result = gPrsr.readData4String(strIP);
                    if (!result) {
                        window.alert("ERROR al parsear el chorizo de puntos de integracion!!!.");
                        return false;
                    }

                    // Vamos a comprobar que lo recibido es correcto, que los quads y tris son lo que tienen que ser...
                    // y meterlo todo en su sitio.
                    if (true) {
                        // Entradas de la forma [indiceElemento, offset donde empiezan sus 3|4 puntos, aridad 3|4].
                        const vIndOffAri: [number, number, number][] = [];
                        for (const [keyId, value] of gPrsr.mapElems_) {
                            // La clave es el indice del shell implicado y el valor una secuencia con los indices 1|2|3|4
                            // de los nodos implicados mas sus offsets correlativos.
                            const arity34 = value.length;
                            if (arity34 !== 3 && arity34 !== 4) {
                                debugger;
                            }
                            vIndOffAri.push([keyId, value[0][0], arity34]);
                        }
                        this.setIntegrationPoints(new Float32Array(gPrsr.data_), vIndOffAri);

                        // Podriamos comprobar...
                    }
        
                    // Antes cargaba ficheros de Salome-Meca.
                    // No volvemos a salvar algo que proviene del disco.
                    // if (this.setIntegrationPoints4String(strIP, false)) {
                    if (true) {
                        // Creacion grafica y agregado a la malla global. /ToDo: Sacar fuera y reconstruir graficamente...
                        const iPointsGO = StructModel3D.createPoints(this.iPoints_, false);
                        
                        // const material = new THREE.PointsMaterial({
                        //     color: 0xFF0000,
                        //     // Aqui el tamaño va en pixels.
                        //     size: 2 + 1,
                        //     // blending: THREE.AdditiveBlending,
                        //     // transparent: true,
                        //     sizeAttenuation: false
                        // });

                        if (this.numDeformations_) {
                            // De momento les meto la misma paleta que llevan los nodos.
                            const vColors: number[] = [];
                            StructModel3D.setDeformationsColor4DXDYDZ(this.iPoints_ as unknown as number[], vColors);
                            const attrib4Col = new THREE.Float32BufferAttribute(vColors, 3);
                            iPointsGO.geometry.setAttribute('color', attrib4Col);

                            const material = new THREE.PointsMaterial({
                                color: 0xFFFFFF,
                                vertexColors: true,
                                // Aqui el tamaño va en pixels.
                                size: 2 + 1,
                                // blending: THREE.AdditiveBlending,
                                // transparent: true,
                                sizeAttenuation: false
                            });
                            iPointsGO.material = material;
                            
                            iPointsGO.name = StructModel3D.name4IPoints;
                            this.gObj_.add(iPointsGO);
                            // Accedo desde aqui al vp en curso e informo.
                            if (this.owner_) {
                                this.owner_.getActiveViewport().setInfo("Created integration points from deformations...");
                            }
                        } else {
                            const size4Point = 5;
                            const color4All = new THREE.Vector4(1.0, 1.0, 0.0, 1.0/2);
                            const [material, uniforms] = createShaderMaterial4RoundPoints(size4Point, color4All);
                            iPointsGO.material = material;
                    
                            iPointsGO.name = StructModel3D.name4IPoints;
                            this.gObj_.add(iPointsGO);
                            // return true;

                            const everyFPS = 60;
                            let cntFPS = 0;
                            let coef01 = 0;

                            // Esta paleta queda bien ya que es ciclica.
                            const lutPalette = createLUT4HSVColorWheel();
                            // La de B&W no queda bien por no ser ciclica.
                            // const lutPalette = createLUT4BlackWhite();
                            
                            const color = new THREE.Color();

                            // Experimento para ver si podemos variar colores sutilmente de forma facil. Funciona.
                            iPointsGO.onBeforeRender = (
                                                        rndr: THREE.WebGLRenderer, scn: THREE.Scene, cmr: THREE.Camera,
                                                        geo: THREE.BufferGeometry | THREE.Geometry, mat: THREE.Material,
                                                        grp: THREE.Group): void => {
                                ++cntFPS;
                                if (cntFPS === everyFPS) {
                                    const colorHex = lutPalette.getColor(coef01);
                                    color.set(colorHex);
                                    // console.log(`${coef01} ${colorHex}`);
                                    // Antes el color se daba asi.
                                    // (mat as THREE.PointsMaterial).color = color;
                                    // Ahora sencillamente variamos el uniform del shader.
                                    const unifSrcColor = uniforms['extColor4'].value as THREE.Vector4;
                                    unifSrcColor.x = color.r;
                                    unifSrcColor.y = color.g;
                                    unifSrcColor.z = color.b;
                                    cntFPS = 0;
                                    coef01 += 0.01;
                                    if (coef01 > 1.0) {
                                        coef01 = 0.0;
                                    }                                
                                }
                            };
                        }

                        return true;
                    }
                }
            }
        }
        return false;
    }

    public async processSalomeMecaFile(filename: string) {
        if (this.iPoints_) {
            this.iPoints_ = null as unknown as Float32Array;
            this.numIPoints_ = 0;
        }
        if (this.numNodes_ === 0) {
            return false;
        }

        if (filename.length === 0) {
            return false;
        }

        // De momento solo admitimos los ficheros con las extensiones .txt y .rmed.
        const posPoint = filename.lastIndexOf('.');
        if (-1 === posPoint) {
            const msg = `ERROR: Invalid file "${filename}" with no extension. We only process .TXT/.RMED files.`;
            console.error(msg);
            window.alert(msg);
            return false;
        }
        const extension = filename.slice(posPoint + 1).toLowerCase();
        if (extension !== 'txt' && extension !== 'rmed') {
            const msg = `ERROR: Invalid file type "${filename}". We only process .TXT/.RMED files.`;
            console.error(msg);
            window.alert(msg);
            return false;
        }

        if (extension === 'txt') {
            const buf = new TextBuffer();
            readTextFile(filename, buf);
            if (!buf.wait2Load()) {
                console.log("ERROR when loading SALOME-MECA file '" + filename + "'. Maybe empty.");                
            } else {
                const dataStr = buf.buffer;
                if (dataStr.length === 0) {
                    window.alert("ERROR when loading SALOME-MECA file '" + filename + "'");
                    return false;
                }
    
                let result: [Map<string, Map<number, number[]>>, string[]] | null = null;
                result = parseString4SalomeMecaTxt(dataStr, this.mElems_);
                if (result === null) {
                    window.alert("ERROR: El fichero '" + filename + "' no ha podido ser parseado.");
                } else {
                    const mHyp = result[0];
                    const vColumns = result[1];

                    let i = 0;
                    let sz = mHyp.size;
                    console.log("\n HYPOTHESES FOUND:\n=======================");
                    for (const [hyp, val] of mHyp) {
                        ++i;
                        console.log(`HYP[${i}/${sz}] "${hyp}"`);
                        const nE = val.size;
                        console.log(`\tElems: ${nE}`);
                    }

                    i = 0;
                    sz = vColumns.length;
                    console.log("\n COLUMNS FOUND:\n=======================");
                    for (const col of vColumns) {
                        ++i;
                        console.log(`COL[${i}/${sz}] "${col}"`);
                    }                        
                    return true;
                }
            }
        }

        if (extension === 'rmed') {
            window.alert("HDF5 loading not working yet.");
            return false;

            // const fCont = (arg: Uint8Array | null): void => {
            //     if (arg) {
            //         this.processBufferRMED(arg);
            //     } else {
            //         window.alert("ERROR: No se ha recibido el buffer binario.");
            //     }
            // };
            // const buff = await readBinaryFile_ASYNC(filename, fCont);

            // const buf = new BinaryBuffer();
            // readBinaryFile(filename, buf);

            // if (!buf.wait2Load()) {
            //     console.log("ERROR when loading HDF file '" + filename + "'. Maybe empty.");                
            // } else {
            //     const dataBin = buf.buffer as Uint8Array;
            //     if (dataBin.length === 0) {
            //         window.alert("ERROR when loading HDF file '" + filename + "'");
            //         return false;
            //     }

            //     parseBinary4SalomeMecaHDF(dataBin);
            // }
        }
        return false;
    }

    public processBufferRMED(vData: Uint8Array): void {
        // Aqui tocaria el proceso del fichero RMED/HDF5...
        // const f = new hdf5.File();
    }

    public setIntegrationPoints4String(strIP: string, save2File = true): boolean {
        if (save2File) {
            window.alert("Received integration points data.");
            const name = "mesh_integration_pointss_back.dat";
            saveFile(strIP, name, "application/json");
        }

        if (this.iPoints_) {
            this.iPoints_ = null as unknown as Float32Array;
            this.numIPoints_ = 0;
        }
        if (this.vIPIOAs_) {
            this.vIPIOAs_.length = 0;
            this.vIPIOAs_ = null as unknown as [number, number, number][];
        }
        if (this.numNodes_ === 0) {
            return false;
        }
        if (strIP.length === 0) {
            window.alert("ERROR: Empty string for integration points data!!!.");
            return false;
        }

        const t0 = performance.now();
        // A partir de este instante tenemos la cadena con los datos en el formato original de MecaSalome dado por JL.
        // Los iremos parseando y metiendo en un array temporal de puntos 3D que despues volcaremos al BA final.
        // Simplemente los puntos 3D, sin informacion de nodos, elementos o pollas.
        const [p3D, vIOA] = parseIntegrationPointsStr(strIP);
        if (p3D.length === 0) {
            return false;
        }
        const result = this.setIntegrationPoints(new Float32Array(p3D), vIOA);
        p3D.length = 0;

        if (result) {
            this.testIntegrationPoints();
        }

        return result;
    }

    /**
     * Para comprobar que todos los quads presentes en el modelo son CONVEXOS. En tal caso devolvemos 0, pero si la cosa
     * va mal devolvemos el numero de quads NO convexos.
     *
     * @returns 
     */
    public testAllQuadsAreConvex(): number {
        let numNonConvex = 0;

        for (let i = 0; i < this.numQuads_; ++i) {
            const qI = this.getQuadN(i);
            if (qI.length === 4) {
                const [iA, iB, iC, iD] = qI;
                const v0 = new THREE.Vector3().fromArray(this.getNode(iA));
                const v1 = new THREE.Vector3().fromArray(this.getNode(iB));
                const v2 = new THREE.Vector3().fromArray(this.getNode(iC));
                const v3 = new THREE.Vector3().fromArray(this.getNode(iD));
                if (!isConvexQuadABCD(v0, v1, v2, v3)) {
                    console.error(`ERROR: El quad en la POSICION [${i}|${this.numQuads_}] NO es convexo!!!.`);
                    ++numNonConvex;
                }
            } else {
                window.alert(`ERROR GRAVE: El quad en la POSICION [${i}|${this.numQuads_}] tiene [${qI.length}] items.`);
                debugger;
                return +Infinity;
            }
        }

        return numNonConvex;
    }

    public testAllQuadsHaveCoplanarPoints(): number {
        let numNonCoplanar = 0;

        for (let i = 0; i < this.numQuads_; ++i) {
            const qI = this.getQuadN(i);
            if (qI.length === 4) {
                const [iA, iB, iC, iD] = qI;
                const v0 = new THREE.Vector3().fromArray(this.getNode(iA));
                const v1 = new THREE.Vector3().fromArray(this.getNode(iB));
                const v2 = new THREE.Vector3().fromArray(this.getNode(iC));
                const v3 = new THREE.Vector3().fromArray(this.getNode(iD));
                if (!isCoplanarQuad([v0, v1, v2, v3])) {
                    console.error(`ERROR: El quad en la POSICION [${i}|${this.numQuads_}] NO es coplanar!!!.`);
                    ++numNonCoplanar;
                }
            } else {
                window.alert(`ERROR GRAVE: El quad en la POSICION [${i}|${this.numQuads_}] tiene [${qI.length}] items.`);
                debugger;
                return +Infinity;
            }
        }

        return numNonCoplanar;
    }

    /**
     * Tenemos un monton de puntos de integracion, pero desafortunadamente pueden no casar con los shells de los que
     * disponemos (por la divergencia movediza), asi que vamos a enfrentar todos los puntos disponibles contra todos
     * los shells disponibles.
     */
    private testIntegrationPoints(): void {
        const numNonConvex = this.testAllQuadsAreConvex();
        if (numNonConvex) {
            console.error(`ERROR: Hay [${numNonConvex}] quads NO CONVEXOS!!!.`);
        }

        const numNonCoplanar = this.testAllQuadsHaveCoplanarPoints();
        if (numNonCoplanar) {
            console.error(`ERROR: Hay [${numNonCoplanar}] quads NO COPLANARES!!!.`);
        }

        const Eps = 0.0001;
        const N = this.vIPIOAs_.length;
        const v0 = new THREE.Vector3();
        const v1 = new THREE.Vector3();
        const v2 = new THREE.Vector3();
        const v3 = new THREE.Vector3();

        const fillVertices = (vNodes: [number, number, number] | [number, number, number, number]): void => {
            const i0 = vNodes[0];
            const i1 = vNodes[1];
            const i2 = vNodes[2];
            const p0 = this.getNode(i0);
            const p1 = this.getNode(i1);
            const p2 = this.getNode(i2);

            v0.fromArray(p0);
            v1.fromArray(p1);
            v2.fromArray(p2);

            if (vNodes.length === 4) {
                const i3 = vNodes[3];
                const p3 = this.getNode(i3);
                v3.fromArray(p3);
            }
        };

        const showCoeffs3 = (v: THREE.Vector3) => {
            const sum = v.x + v.y + v.z;
            if (Math.abs(sum - 1.0) < Eps) {
                console.log(`\t Coefs: (${v.x}, ${v.y}, ${v.z})`);
            } else {
                console.log(`\t Coefs: (${v.x}, ${v.y}, ${v.z}) <==== Sum: ${sum}`);
            }
        };

        const showCoeffs4 = (v: THREE.Vector4) => {
            const sum = v.x + v.y + v.z + v.w;
            if (Math.abs(sum - 1.0) < Eps) {
                console.log(`\t Coefs: (${v.x}, ${v.y}, ${v.z}, ${v.w})`);
            } else {
                console.log(`\t Coefs: (${v.x}, ${v.y}, ${v.z}, ${v.w}) <==== Sum: ${sum}`);
            }
        };

        // Mapa que de la referencia de unos IP nos da el indice del shell que los contiene.
        // Los valores debieran ser unicos.
        const mIP2Id = new Map<number, number>();
        // Mapa inverso al anterior, de un shell nos dice que IP's contiene. Los valores debieran ser unicos.
        const mId2IP = new Map<number, number>();

        // Por el problema movedizo, podemos tener distinto numero de triangulos y de quads entre los PI y los shells
        // originales de la malla.
        let numTris4IPs = 0;
        let numQuads4IPs = 0;

        // Bucle de recorrido de todos los grupos de IP's.
        for (let i = 0; i < N; ++i) {
            const [indexIP, offsetIP, arityIP] = this.vIPIOAs_[i];
            const vIP = this.getIntegrationPointsI(offsetIP, arityIP);
            if (vIP.length !== arityIP) {
                window.alert("ERROR!!!");
                debugger;
                continue;
            } else {
                if (arityIP === 4) {
                    ++numQuads4IPs;
                } else {
                    if (arityIP === 3) {
                        ++numTris4IPs;
                    } else {
                        // No creo que haya beams.
                        debugger;
                    }
                }
            }

            console.log(`[${i}/${N}] Integration points [${arityIP}] for shell [${indexIP}]`);

            // Bucle de recorrido de todos los shells (tris|quads).
            for (const idShell of this.mElems_.keys()) {
                const vNodes = this.getNodes4ElementI(idShell);
                
                if (arityIP === 3 && vNodes.length === 3) {
                    fillVertices(vNodes);
                    const tri = new THREE.Triangle(v0, v1, v2);
                    // Comprobacion.
                    // if (false) {
                    //     const center = new THREE.Vector3();
                    //     tri.getMidpoint(center);
                    //     const bc4Center = getBarycentricCoords4TrianglePoint3D(tri, center);
                    //     showCoeffs(bc4Center);
                    // }
                    // Ahora enfrentamos los 3 IP contra el triangulo a ver que sale.
                    const bcIP0 = getBarycentricCoords4TrianglePoint3D(tri, vIP[0]);
                    const bcIP1 = getBarycentricCoords4TrianglePoint3D(tri, vIP[1]);
                    const bcIP2 = getBarycentricCoords4TrianglePoint3D(tri, vIP[2]);
                    // Las coordenadas para el triangulo indican contencion cuando todos los lambdas estan en [0, 1]
                    // y su suma es 1 (aunque esto podria ahorrarse???).
                    const in0 = isContainedBarycentric3(bcIP0);
                    const in1 = isContainedBarycentric3(bcIP1);
                    const in2 = isContainedBarycentric3(bcIP2);
                    if (in0 && in1 && in2) {
                        console.log(`\t Tri[${idShell}] contains IP[${indexIP}].`);
                        if (!mIP2Id.has(indexIP)) {
                            mIP2Id.set(indexIP, idShell);
                            showCoeffs3(bcIP0);
                            showCoeffs3(bcIP1);
                            showCoeffs3(bcIP2);
                        } else {
                            debugger;
                        }
                        if (!mId2IP.has(idShell)) {
                            mId2IP.set(idShell, indexIP);
                        } else {
                            debugger;
                        }
                    } else {
                        if (in0 || in1 || in2) {
                            console.log(`\tTri[${idShell}] contains PARTIALLY IP[${indexIP}]: {${in0}, ${in1}, ${in2}}`);
                            in0 && showCoeffs3(bcIP0);
                            in1 && showCoeffs3(bcIP1);
                            in2 && showCoeffs3(bcIP2);
                        }
                    }
                } else {
                    if (arityIP === 4 && vNodes.length === 4) {
                        fillVertices(vNodes);
                        const quad = [v0, v1, v2, v3] as [THREE.Vector3, THREE.Vector3, THREE.Vector3, THREE.Vector3];
                        const bcIP0 = getBarycentricCoords4QuadPoint3D(quad, vIP[0]);
                        const bcIP1 = getBarycentricCoords4QuadPoint3D(quad, vIP[1]);
                        const bcIP2 = getBarycentricCoords4QuadPoint3D(quad, vIP[2]);
                        const bcIP3 = getBarycentricCoords4QuadPoint3D(quad, vIP[3]);
    
                        const in0 = isContainedBarycentric4(bcIP0);
                        const in1 = isContainedBarycentric4(bcIP1);
                        const in2 = isContainedBarycentric4(bcIP2);
                        const in3 = isContainedBarycentric4(bcIP3);

                        if (in0 && in1 && in2 && in3) {
                            console.log(`\t Quad[${idShell}] contains IP[${indexIP}].`);
                            if (!mIP2Id.has(indexIP)) {
                                mIP2Id.set(indexIP, idShell);
                                showCoeffs4(bcIP0);
                                showCoeffs4(bcIP1);
                                showCoeffs4(bcIP2);
                                showCoeffs4(bcIP3);
                            } else {
                                debugger;
                            }
                            if (!mId2IP.has(idShell)) {
                                mId2IP.set(idShell, indexIP);
                            } else {
                                debugger;
                            }
                        } else {
                            if (in0 || in1 || in2 || in3) {
                                console.log(`\tQuad[${idShell}] contains PARTIALLY IP[${indexIP}]: {${in0}, ${in1}, ${in2}, ${in3}}`);
                                in0 && showCoeffs4(bcIP0);
                                in1 && showCoeffs4(bcIP1);
                                in2 && showCoeffs4(bcIP2);
                                in3 && showCoeffs4(bcIP3);
                            }
                        }
                    }
                }
            }
        }

        console.log(`Tenemos un total de [${N}] sets de IP repartidos en [${numTris4IPs}] tris y [${numQuads4IPs}] quads.`);
        console.log(`En la malla hay [${this.numTris_ + this.numQuads_}] elementos shell repartidos en [${this.numTris_}] tris y [${this.numQuads_}] quads.`);
        console.log(`Coincidencias: ${mIP2Id.size}/${mId2IP.size}.`)
        debugger;
    }

    /**
     * Activa o no la visualizacion en modo alambrico, solo para las partes cell (quads/tris).
     * @param onOff 
     * @returns 
     */
    public setWireFrameOnOff(onOff: boolean): boolean {
        // A partir de la parte grafica del modelo buscamos el subconjunto que nos interesa, sin usar la escena.
        const obj3D = this.getGraphicObject();
        if (obj3D) {
            const setWireframe4Mesh = (mesh: THREE.Mesh, onOff: boolean) => {
                if (mesh.material) {
                    const mat = mesh.material as THREE.MeshBasicMaterial;
                    mat.wireframe = onOff;
                }
            };

            const quads = obj3D.getObjectByName(StructModel3D.name4Quads);
            if (quads) {
                setWireframe4Mesh(quads as THREE.Mesh, onOff);
            }
            const tris = obj3D.getObjectByName(StructModel3D.name4Tris);
            if (tris) {
                setWireframe4Mesh(tris as THREE.Mesh, onOff);
            }
            return true;
        }
        return false;
    }

    /**
     * Si el valor es true, se cambia el material de los nodes para que se vean como bolitas, mientras que si es false,
     * valor inicial por defecto, entonces se usan los poco caros puntitos cuadrados para representar los nodes.
     * @param value 
     */
    public showNodesAsBallsOrPoints(value: boolean): boolean {
        const obj3D = this.getGraphicObject();
        if (obj3D) {
            const subComponent3D = obj3D.getObjectByName(StructModel3D.name4Nodes);
            if (subComponent3D) {
                const nodesGO = subComponent3D as THREE.Points;
                let oldMaterial = nodesGO.material as THREE.PointsMaterial;
                let newMaterial: THREE.PointsMaterial | null = null;
                if (oldMaterial.name === StructModel3D.name4NodesMaterialAsPoints && value === true) {
                    // Hemos de cambiar de puntos a bolas.
                    newMaterial = StructModel3D.createBallsMaterial();
                } else {
                    if (oldMaterial.name === StructModel3D.name4NodesMaterialAsBalls && value === false) {
                        // Hemos de cambiar de bolas a puntos.
                        newMaterial = StructModel3D.createPointsMaterial();
                    }
                }
                if (newMaterial) {
                    // Para que sigan usandose los colores como lo venian haciendo.
                    newMaterial.vertexColors = oldMaterial.vertexColors;
                    // Destruimos el viejo material.
                    if (oldMaterial.map) {
                        oldMaterial.map.dispose();
                        oldMaterial.map = undefined as unknown as THREE.Texture;
                    }
                    oldMaterial.dispose();
                    oldMaterial = undefined as unknown as THREE.PointsMaterial;
                    // Y asignamos el nuevo.
                    nodesGO.material = newMaterial;
                }
                return true;
            }
        }
        return false;
    }

    /**
     * Activa o no la visualizacion del subcomponente de nombre dado, que puede ser alguno de estos:
     * StructModel3D.name4Nodes = "nodes";
     * StructModel3D.name4Beams = "beams";
     * StructModel3D.name4Quads = "cells_quads";
     * StructModel3D.name4Tris = "cells_tris";
     * StructModel3D.name4QuadsEdges = "edges_quads";
     * StructModel3D.name4TrisEdges = "edges_tris";
     * ...
     *
     * @param name 
     * @param onOff 
     */
    public setVisibilityOnOff(name: string, onOff: boolean): boolean {
        const obj3D = this.getGraphicObject();
        if (obj3D) {
            // A partir de la parte grafica del modelo buscamos el subconjunto que nos interesa, sin usar la escena.
            const subComponent3D = obj3D.getObjectByName(name);
            if (subComponent3D) {
                subComponent3D.visible = onOff;
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    /**
     * Accesor al color [r, g, b] del i-esimo nodo. Son componentes en [0, 1].
     * En caso de error (no hay grafico, no hay nodos o no hay buffer de color aun), devuelve null.
     * @param i 
     * @returns 
     */
    public getNodeColor(i: number): [number, number, number] | null {
        const obj3D = this.getGraphicObject();
        if (obj3D) {
            const subComponent3D = obj3D.getObjectByName(StructModel3D.name4Nodes);
            if (subComponent3D) {
                const nodesGO = subComponent3D as THREE.Points;
                const currentGeometry = nodesGO.geometry as THREE.BufferGeometry;
                if (currentGeometry.hasAttribute('color')) {
                    const attrib4Col = currentGeometry.getAttribute('color');
                    const v = attrib4Col.array;
                    // El numero de items individuales, formados por varias subComponentes.
                    if (i < attrib4Col.count) {
                        const sz = attrib4Col.itemSize;
                        const r = v[i * sz];
                        const g = v[i * sz + 1];
                        const b = v[i * sz + 2];
                        return [r, g, b];
                    }
                }
            }
        }
        return null;
    }

    public setNodeColor(i: number, rgb: [number, number, number]): boolean {
        const obj3D = this.getGraphicObject();
        if (obj3D) {
            const subComponent3D = obj3D.getObjectByName(StructModel3D.name4Nodes);
            if (subComponent3D) {
                const nodesGO = subComponent3D as THREE.Points;
                const currentGeometry = nodesGO.geometry as THREE.BufferGeometry;
                if (currentGeometry.hasAttribute('color')) {
                    const attrib4Col = currentGeometry.getAttribute('color');
                    const v = attrib4Col.array;
                    // El numero de items individuales, formados por varias subComponentes.
                    if (i < attrib4Col.count) {
                        const sz = attrib4Col.itemSize;
                        attrib4Col.setXYZ(i, rgb[0], rgb[1], rgb[2]);
                        // Imprescindible tras un cambio.
                        currentGeometry.attributes.color.needsUpdate = true;
                        return true;
                    }
                }
            }
        }
        return false;
    }
    
    /**
     * Si el valor es true, se cambia el color presente en los nodes para que se vean colores individuales sacados de
     * alguna paleta (de momento la RGB-AABB negada), mientras que si es false, valor inicial por defecto, entonces se
     * dejan los nodos con el mismo y unico color compartido por todos, ya esten como puntos o como bolitas.
     * @param value 
     */
    public setColoredNodes(value: boolean): boolean {
        const obj3D = this.getGraphicObject();
        if (obj3D) {
            const subComponent3D = obj3D.getObjectByName(StructModel3D.name4Nodes);
            if (subComponent3D) {
                const nodesGO = subComponent3D as THREE.Points;
                const currentMaterial = nodesGO.material as THREE.PointsMaterial;

                currentMaterial.vertexColors = value;
                // Esto es totalmente necesario para que el cambio surta efecto.
                currentMaterial.needsUpdate = true;

                if (value) {
                    if (currentMaterial.name === StructModel3D.name4NodesMaterialAsBalls) {
                        // Hay que cambiar el color del sprite para que sea blanco y pille mejor los nuevos colores.
                        // Aunque tengo mis dudas...
                        currentMaterial.color = new THREE.Color(0xffffff);
                        currentMaterial.alphaTest = 0;
                    }

                    // Cuando activamos por primera vez el coloreado o simplemente cambiemos de paleta, sera necesario
                    // generar un nuevo buffer de colores.
                    const currentGeometry = nodesGO.geometry as THREE.BufferGeometry;
                    if (currentGeometry.hasAttribute('color')) {
                        // Aqui reescribiriamos todos los valores con los de la paleta en curso, si es diferente.
                        // const numPoints = 550;
                        // for (let i = 0; i < numPoints; ++i) {
                        //     if (!this.setNodeColor(i, [1.0, 0.0, 0.0])) {
                        //         console.error("Falla en " + i);
                        //     }
                        // }
                    } else {
                        const vColors: number[] = [];
                        if (this.numDeformations_) {
                            // StructModel3D.setDeformationsColor4DXDYDZ()
                            this.createDeformationColors4DXYZ(vColors);
                        } else {
                            // Debemos crear el attributo de color y meterle la paleta pertinente.
                            StructModel3D.setGradientColor(this.nodes_, vColors);
                            // Invierto los colores para notar diferencia con los cells...
                            for (let i = 0; i < vColors.length; ++i) {
                                vColors[i] = 1 - vColors[i];
                                // vColors[i] = 0.5;
                            }
                        }
                    
                        const attrib4Col = new THREE.Float32BufferAttribute(vColors, 3);
                        currentGeometry.setAttribute('color', attrib4Col);
                        currentGeometry.attributes.color.needsUpdate = true;
                    }
                } else {
                    if (currentMaterial.name === StructModel3D.name4NodesMaterialAsBalls) {
                        // Hay que cambiar el color del sprite al inicial.
                        currentMaterial.color = new THREE.Color(0x0080ff);
                    }
                }

                return true;
            }
        }
        return false;
    }

    /**
     * Si se da true se activa (y crea la primera vez) la infraestructura para poner en la posicion de cada nodo su id
     * numerico (un numerito natural). Si es false se invisibiliza.
     * @param value 
     * @returns 
     */
    public setNodesIds(value: boolean): boolean {
        const obj3D = this.getGraphicObject();
        if (obj3D) {
            const subComponent3D = obj3D.getObjectByName(StructModel3D.name4Nodes);
            if (subComponent3D) {
                // La primera vez que se active los numeracos no existiran y habra que crearlos.
                const scene = obj3D.parent as THREE.Scene;
                let graphicObject = scene.getObjectByName(StructModel3D.name4NodesIds);

                if (value) {
                    if (!graphicObject) {
                        // Posiblemente sea costoso mantenerlos en memoria cuando son muchos y habra que sopesar el crearlos
                        // cada vez si es que es asequible...
                        const onlyColor = new THREE.Color(1, 1, 0);
                        const xLen = 0.05 * 0.5;
                        const k4Y = 2.5;
                        const k4Spc = 0.5 * 0.5;
                        const numSet = new Numbers7SD(xLen, k4Y, k4Spc, onlyColor.getHex());
                        const nodesGO = subComponent3D as THREE.Points;
                        const vPos = (nodesGO.geometry as THREE.BufferGeometry).attributes.position.array;
                        graphicObject = Numbers7SD.createNumbersField(numSet, vPos, 10);
                        graphicObject.name = StructModel3D.name4NodesIds;
                        scene.add(graphicObject);

                        // Prueba de estres.
                        if (false) {
                            const N = 1000000;
                            const grpDemo = Numbers7SD.testPerformance(N, numSet);
                            scene.add(grpDemo);
                        }
    
                    }
                    graphicObject.visible = true;
                } else {
                    (graphicObject as THREE.Object3D).visible = false;
                }

                return true;
            }
        }
        return false;
    }

    /**
     * Truco del almendruco para poder registrar y desregistrar EXACTAMENTE siempre el mismo callback.
     */
    static inmutableAlias4Function: any = null;
    /**
     * Activa o desactiva el modo de visualizacion de la malla en el que al colocar el raton sobre un nodo se muestra
     * una etiqueta con el identificador del mismo. Para ello se requiere el procesador grafico en curso, al menos la
     * primera vez, para poder montar la infraestructura necesaria para acceder a las coordenadas del raton.
     * @param value 
     * @param graPro 
     * @returns 
     */
    public setPointedNodeId(value: boolean, graPro: GraphicProcessor): boolean {
        const obj3D = this.getGraphicObject();
        if (obj3D) {
            let labelMeshGO = obj3D.getObjectByName(StructModel3D.name4Label);
            // Datos del calculo de colisiones.
            let vISections: THREE.Intersection[] = [];
            const parentCanvas = graPro.container;

            if (StructModel3D.inmutableAlias4Function === null) {
                // Callback que calcula colisiones cada vez que el raton se mueve y puede desencadenar el render...
                // La putada es que cada vez que llamamos se crea una de estas funciones y no se puede desregistrar.
                const pointerMoveCallback = () => {
                    const raycaster = graPro.getRaycaster();
                    const nodesGO = obj3D.getObjectByName(StructModel3D.name4Nodes) as THREE.Points<THREE.BufferGeometry>;
                    // Por el funcionamiento interno de raycastObjects() me veo obligado a esto.
                    const pseudoFather = {
                        children: [nodesGO] as THREE.Object3D[]
                    };
                    vISections = raycaster.raycastObjects(pseudoFather as THREE.Object3D, false);
                    const numISections = vISections.length;
                    if (numISections) {
                        if (labelMeshGO) {
                            labelMeshGO.visible = true;
                        }
                    }
                };
            
                StructModel3D.inmutableAlias4Function = pointerMoveCallback;
            }

            if (!labelMeshGO) {

                const msg = `CREATION & ACTIVATION of SHOW-POINTED-NODE`;
                graPro.getActiveViewport().setInfo(msg);

                // La primera vez el objeto grafico con la etiqueta NO existira, asi que lo crearemos junto con la
                // infraestructura necesaria para calculo de intersecciones con los nodos. Para eso necesitamos el
                // graPro, que nos permite el calculo de colisiones con la nube de nodos.
                // En la creacion del objeto necesitamos la carga previa de la fuente que emplea.
                // Al final este objeto label es un mesh de texto donde podremos mostrar diversos mensajes, como por
                // ejemplo el id del nodo sobre el que esta el raton.
                const loader = new THREE.FontLoader();
                const file4Font = '/files_mesh3d/helvetiker_bold.typeface.json';

                loader.load(
                    // resource URL.
                    file4Font,
            
                    // onLoad callback.
                    (font: THREE.Font) => {            
                        console.log(`Loaded 3D font from file ${file4Font}`);
                        this.currentFont_ = font;
                        const textGeo = new THREE.TextGeometry(
                            'Inicialmente vacio',
                            {
                                font: font,
                                size: 200,
                                height: 50,
                                curveSegments: 12,
                                bevelThickness: 2,
                                bevelSize: 5,
                                bevelEnabled: true
                            }
                        );
            
                        const textMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 });
                        const labelMesh = new THREE.Mesh(textGeo, textMaterial);
                        labelMesh.name = StructModel3D.name4Label;
                        labelMesh.position.x = 0;
                        labelMesh.position.y = 0;
                        labelMesh.position.z = 0;
                        labelMesh.scale.set(0.001, 0.001, 0.001);
                        textGeo.computeBoundingBox();
                        labelMesh.visible = false;
                        // Experimental, sacado de:
                        // https://stackoverflow.com/questions/12919638/textgeometry-to-always-face-user
                        // Va peor todo!!!
                        // labelMesh.frustumCulled = false;
                        // No funcionan colisiones.
                        // labelMesh.matrixAutoUpdate = false;
                        labelMeshGO = labelMesh;

                        parentCanvas.addEventListener('pointermove', StructModel3D.inmutableAlias4Function);

                        // Con esto ademas lo orientaremos hacia la camara cuando este visible.
                        // La putada es que esto solo se ejecuta cuando el objeto esta visible...
                        labelMesh.onBeforeRender = (r: THREE.WebGLRenderer, s: THREE.Scene, camera: THREE.Camera) => {
                            const numISections = vISections.length;
                            if (numISections) {
                                // Nos quedamos con los datos de la primera interseccion, que es la mas cercana.
                                const iSect = vISections[0];
                                const index = iSect.index as number;
                                const text = "" + index;
                                this.changeText4LabelGO(text);

                                // Y reorientamos la misma para que apunte de cara al observador que esta en la camara.
                                // A veces NO orienta bien la cosa... Quizas haya que rotar antes de posicionar???.
                                // const targetQuaternion = new THREE.Quaternion();
                                // camera.getWorldQuaternion(targetQuaternion);
                                // // labelMesh.quaternion.copy(targetQuaternion);
                                // if (!labelMesh.quaternion.equals(targetQuaternion)) {                    
                                //     let step = 1;
                                //     labelMesh.quaternion.rotateTowards(targetQuaternion, step);
                                // }                                

                                labelMesh.position.set(0, 0, 0);
                                labelMesh.quaternion.copy(camera.quaternion);

                                // labelMesh.rotation.setFromRotationMatrix(camera.matrix);

                                const nodesGO = obj3D.getObjectByName(StructModel3D.name4Nodes) as THREE.Points<THREE.BufferGeometry>;
                                const vPoints3D = nodesGO.geometry.attributes.position.array;
                                const x = vPoints3D[3 * index];
                                const y = vPoints3D[3 * index + 1];
                                const z = vPoints3D[3 * index + 2];
                                // labelMesh.position.set(x, y, z);
                                
                                // Acercamos la posicion 1 metro en la direccion de la camara para que se vea mejor.
                                const camPosition = new THREE.Vector3();
                                camera.getWorldPosition(camPosition);
                                const nodePosition = new THREE.Vector3(x, y, z);
                                // El vector nodo-camara.
                                const v = new THREE.Vector3();
                                v.subVectors(camPosition, nodePosition);
                                v.normalize();
                                nodePosition.add(v);
                                labelMesh.position.set(nodePosition.x, nodePosition.y, nodePosition.z);

                                // const angle = Math.PI * 45 / 180.0;
                                // labelMesh.setRotationFromAxisAngle(v, angle);

                                // labelMesh.rotation.y = Math.atan2(camera.position.x - x, camera.position.z - z);
                                // labelMesh.lookAt(camPosition);

                            } else {
                                labelMesh.visible = false;
                            }
                        };
            
                        // Finalmente se agrega a la pseudo-escena.
                        this.gObj_.add(labelMesh);
                    },
            
                    // onProgress callback.
                    (xhr: ProgressEvent) => {
                        // console.log((xhr.loaded / xhr.total * 100) + '% loaded');
                    },
            
                    // onError callback.
                    (err: ErrorEvent) => {
                        console.log(`ERROR loading font ${file4Font}: "${err}".`);
                    }        
                );
            } else {
                if (value) {
                    const msg = `ACTIVATION of SHOW-POINTED-NODE`;
                    graPro.getActiveViewport().setInfo(msg);    
                    parentCanvas.addEventListener('pointermove', StructModel3D.inmutableAlias4Function);
                    labelMeshGO.visible = true;
                } else {
                    const msg = `DEACTIVATION of SHOW-POINTED-NODE`;
                    graPro.getActiveViewport().setInfo(msg);    
                    parentCanvas.removeEventListener('pointermove', StructModel3D.inmutableAlias4Function);
                    labelMeshGO.visible = false;
                }
            }
            return true;
        }
        return false;
    }

    private changeText4LabelGO(newText: string): void {
        const obj3D = this.getGraphicObject();
        if (obj3D) {
            const labelMeshGO = obj3D.getObjectByName(StructModel3D.name4Label) as THREE.Mesh;
            // Cambiamos la geometria con la fuente ya cargada y el texto dado.
            labelMeshGO.geometry = new THREE.TextGeometry(
                newText,
                {
                    font: this.currentFont_,
                    size: 200,
                    height: 50,
                    curveSegments: 12,
                    bevelThickness: 2,
                    bevelSize: 5,
                    bevelEnabled: true
                }
            );
            // labelMeshGO.material = new THREE.MeshBasicMaterial({ color: Math.random() * 0xff0000 });
        } else {
            window.alert("ERROR: There's no graphic object!!!.");
        }
    }

    /**
     * Si se da true se activa (y crea la primera vez) la infraestructura para poner en el baricentro de cada shell su
     * id numerico (un numerito natural). Si es false se invisibiliza.
     * @param value 
     * @returns 
     */
     public setShellsIds(value: boolean): boolean {
        const obj3D = this.getGraphicObject();
        if (obj3D) {
            const tris3D = obj3D.getObjectByName(StructModel3D.name4Tris);
            const quads3D = obj3D.getObjectByName(StructModel3D.name4Quads);
            if (tris3D || quads3D) {
                // La primera vez que se active los numeracos no existiran y habra que crearlos.
                const scene = obj3D.parent as THREE.Scene;
                let graphicObject = scene.getObjectByName(StructModel3D.name4ShellsIds);

                if (value) {
                    if (!graphicObject) {
                        // Posiblemente sea costoso mantenerlos en memoria cuando son muchos y habra que sopesar el crearlos
                        // cada vez si es que es asequible...
                        const onlyColor = new THREE.Color('white');
                        const xLen = 0.05 * 0.5;
                        const k4Y = 2.5;
                        const k4Spc = 0.5 * 0.5;
                        const numSet = new Numbers7SD(xLen, k4Y, k4Spc, onlyColor.getHex());
                        // Posiciones de los baricentros de todos los shells, mas todos los ids implicados.
                        const vPos: number[] = [];
                        const vIds: number[] = this.getIds4AllShellElements(true);
                        const N = vIds.length;
                        for (let i = 0; i < N; ++i) {
                            const id = vIds[i];
                            const [x, y, z] = this.barycenter4Shell(id) as P3D;
                            vPos.push(x, y, z);
                        }
                        graphicObject = Numbers7SD.createNumbersField(numSet, vPos, vIds);
                        graphicObject.name = StructModel3D.name4ShellsIds;
                        scene.add(graphicObject);
                    }
                    graphicObject.visible = true;
                } else {
                    (graphicObject as THREE.Object3D).visible = false;
                }

                return true;
            }
        }
        return false;
    }

    /**
     * Si se da true se activa (y crea la primera vez) la infraestructura para poner en el medio de cada beam su id
     * numerico (un numerito natural). Si es false se invisibiliza.
     * @param value 
     * @returns 
     */
     public setBeamsIds(value: boolean): boolean {
        const obj3D = this.getGraphicObject();
        if (obj3D) {
            const beams3D = obj3D.getObjectByName(StructModel3D.name4Beams);
            if (beams3D) {
                // La primera vez que se active los numeracos no existiran y habra que crearlos.
                const scene = obj3D.parent as THREE.Scene;
                let graphicObject = scene.getObjectByName(StructModel3D.name4BeamsIds);

                if (value) {
                    if (!graphicObject) {
                        // Posiblemente sea costoso mantenerlos en memoria cuando son muchos y habra que sopesar el crearlos
                        // cada vez si es que es asequible...
                        const onlyColor = new THREE.Color('red');
                        const isVertical = true;
                        const numSet = new Numbers7SD(0.05, 2.5, 0.5, onlyColor.getHex(), isVertical);
                        // Posiciones de los puntos centrales de todos los beams, mas todos los ids implicados.
                        const vPos: number[] = [];
                        const vIds: number[] = this.getIds4AllBeamElements(true);
                        const N = vIds.length;
                        for (let i = 0; i < N; ++i) {
                            const id = vIds[i];
                            const [x, y, z] = this.barycenter4Beam(id) as P3D;
                            vPos.push(x, y, z);
                        }
                        graphicObject = Numbers7SD.createNumbersField(numSet, vPos, vIds);
                        graphicObject.name = StructModel3D.name4BeamsIds;
                        scene.add(graphicObject);
                    }
                    graphicObject.visible = true;
                } else {
                    (graphicObject as THREE.Object3D).visible = false;
                }

                return true;
            }
        }
        return false;
    }

    /**
     * Activa/desactiva el modo de clipping o corte parcial 3D mediante 3 planos X-Y-Z. Por defecto esta inactivo.
     * @param onOff 
     * @param graPro 
     */
    public setClippingModeXYZ(onOff: boolean, graPro: GraphicProcessor): boolean {
        const obj3D = this.getGraphicObject();
        if (obj3D) {
            // Esto es lo que habilita ese modo.
            const renderer = graPro.getRenderer();
            renderer.localClippingEnabled = onOff;
            const Empty = Object.freeze([]);
            renderer.clippingPlanes = Empty as any;
            if (onOff) {
                const dimX = this.aabb_.max.x - this.aabb_.min.x;
                const dimY = this.aabb_.max.y - this.aabb_.min.y;
                const dimZ = this.aabb_.max.z - this.aabb_.min.z;
                const midX = this.aabb_.min.x + 0.5 * dimX;
                const midY = this.aabb_.min.y + 0.5 * dimY;
                const midZ = this.aabb_.min.z + 0.5 * dimZ;
                // Colocaremos los planos a la distancia minima, de forma que se vea el modelo completo (aka coef. 0).
                const distX = this.aabb_.min.x;
                const distY = this.aabb_.min.y;
                const distZ = this.aabb_.min.z;

                // Se inician los planos con vectores "positivos" y distancias negativas, las minimas posibles a cada
                // eje; de esta forma inicialmente y con los sliders a 0 se podra ver el modelo integro.
                const plX = new THREE.Plane(new THREE.Vector3(+1, 0, 0), -this.aabb_.min.x);
                const plY = new THREE.Plane(new THREE.Vector3(0, +1, 0), -this.aabb_.min.y);
                const plZ = new THREE.Plane(new THREE.Vector3(0, 0, +1), -this.aabb_.min.z);

                const clipPlanes = [plX, plY, plZ];

                // Esos 3 planos de clipping hay que pasarselos a todos los materiales implicados en el modelo grafico.
                obj3D.traverse((child: THREE.Object3D) => {
                    // console.log(child.id + " <" + child.name + "> " + child.type);
                    // Comprobamos si tiene material. Es la mejor forma en TS, pues de las habituales no compila...
                    // Pero tiene el problema de una futurible ofuscacion...
                    if (Object.prototype.hasOwnProperty.call(child, 'material')) {
                        // Hago la trampa de castear a Mesh por la puta azucar sintactica.
                        const falseMesh = child as unknown as THREE.Mesh;
                        // Ojo a si en el futuro se dan materiales con arrays!!!.
                        const material =  falseMesh.material as THREE.Material;
                        material.clippingPlanes = clipPlanes;
                        // Con esto lo que se consigue es que el clipping se haga justo sobre lo que corta el plano.
                        // PERO PARECE QUE NO FUNCIONA!!!.
                        // material.clipIntersection = true;
                    }
                });

                // Aqui creamos los correspondientes helpers graficos para los 3 planos.
                // Antes usaba planeHelpers, pero son problematicos de posicionar y cuadrados, asi que usare meshes con
                // geometria de planos, que son mas controlables.
                const createPlane = (width: number, height: number, color: number,
                                     name: string, plane: THREE.Plane): THREE.Mesh => {
                    const geom = new THREE.PlaneGeometry(width, height, 1, 1);
                    const mat = new THREE.MeshBasicMaterial({
                        color: color,
                        side: THREE.DoubleSide,
                        opacity: 0.2,
                        transparent: true,
                    });
                    const mesh4Plane = new THREE.Mesh(geom, mat);
                    mesh4Plane.name = name;
                    // Guardo en el propio mesh el plano de corte real asociado.
                    mesh4Plane.userData = plane;
                    return mesh4Plane;
                };

                const meshPlaneX = createPlane(dimY, dimZ, 0xff0000, 'clipPlaneX', plX);
                meshPlaneX.rotation.z = Math.PI / 2;
                meshPlaneX.rotation.y = Math.PI / 2;
                meshPlaneX.position.set(this.aabb_.min.x, midY, midZ);

                const meshPlaneY = createPlane(dimX, dimZ, 0x00ff00, 'clipPlaneY', plY);
                meshPlaneY.rotation.x = Math.PI / 2;
                meshPlaneY.position.set(midX, this.aabb_.min.y, midZ);

                const meshPlaneZ = createPlane(dimX, dimY, 0x0000ff, 'clipPlaneZ', plZ);
                meshPlaneZ.position.set(midX, midY, this.aabb_.min.z);

                const helpers = new THREE.Group();
                helpers.name = 'clipPlanesXYZ';
                helpers.add(meshPlaneX);
                helpers.add(meshPlaneY);
                helpers.add(meshPlaneZ);

                // Esos helpers los agregamos en la misma escena en que esta el modelo, que es su padre.
                const scene = obj3D.parent as THREE.Scene;
                scene.add(helpers);
            } else {
                // Se quitan los planos de clip.
                obj3D.traverse((child: THREE.Object3D) => {
                    if (Object.prototype.hasOwnProperty.call(child, 'material')) {
                        const falseMesh = child as unknown as THREE.Mesh;
                        const material =  falseMesh.material as THREE.Material;
                        material.clippingPlanes = null;
                    }
                });
            }
            return true;
        }
        window.alert("ERROR: No hay modelo de malla disponible.");
        return false;
    }

    /**
     * Para el eje seleccionado con el indice axis x:0, y:1, 2:z, se aplica el valor dado en [0, 1] para mover el plano
     * de recorte elegido desde 0, vision total, a 1 recorte total, en la dimension pertinente.
     *
     * @param axis 
     * @param value01 
     * @returns 
     */
    public clipXYZ(axis: 0 | 1 | 2, value01: number): void {
        const obj3D = this.getGraphicObject();
        if (obj3D) {
            // Saco la escena del modelo grafico para pedirle el plano de clipping en X.
            const scene = obj3D.parent as THREE.Scene;
            let name: string;            
            if (axis === 0) {
                name = 'clipPlaneX';
            } else {
                if (axis === 1) {
                    name = 'clipPlaneY';
                } else {
                    name = 'clipPlaneZ';
                }
            }
            
            const plane = scene.getObjectByName(name);
            if (plane) {
                let dim: number;
                let offset: number;
                

                if (axis === 0) {
                    // Nos movemos en X: Del intervalo de value01 [0, 1] pasamos al equivalente en [minX, maxX].
                    dim = this.aabb_.max.x - this.aabb_.min.x;
                    offset = this.aabb_.min.x + value01 * dim;
                    plane.position.x = offset;
                } else {
                    if (axis === 1) {
                        dim = this.aabb_.max.y - this.aabb_.min.y;
                        offset = this.aabb_.min.y + value01 * dim;
                        plane.position.y = offset;
                    } else {
                        dim = this.aabb_.max.z - this.aabb_.min.z;
                        offset = this.aabb_.min.z + value01 * dim;
                        plane.position.z = offset;                        
                    }
                }
                // Sacamos el plano de corte asociado del userData.
                const pl = plane.userData as THREE.Plane;
                const signum = this.negated_[axis] ? -1.0 : +1.0;
                pl.constant = offset * -signum;
                return;
            } else {
                console.error("ERROR: El plano '" + name + "' no existe. Posiblemente no estamos en modo clipping.")
                return;
            }            
        }
        window.alert("ERROR: No hay modelo grafico de malla disponible.");
    }

    public invertClipPlaneXYZ(axis: 0 | 1 | 2, value: boolean): void {
        const obj3D = this.getGraphicObject();
        if (obj3D) {
            // Saco la escena del modelo grafico para pedirle el plano de clipping en X.
            const scene = obj3D.parent as THREE.Scene;
            let name: string;
            if (axis === 0) {
                name = 'clipPlaneX';
            } else {
                if (axis === 1) {
                    name = 'clipPlaneY';
                } else {
                    name = 'clipPlaneZ';
                }
            }
            
            const plane = scene.getObjectByName(name);
            if (plane) {
                // Sacamos el plano de corte asociado del userData.
                const pl = plane.userData as THREE.Plane;
                // Necesitaremos conocer el estado de negacion.
                this.negated_[axis] = value;
                pl.negate();
                return;
            } else {
                console.error("ERROR: El plano '" + name + "' no existe. Posiblemente no estamos en modo clipping.")
                return;
            }            
        }
        window.alert("ERROR: No hay modelo grafico de malla disponible.");
    }

    public showClipPlaneXYZ(axis: 0 | 1 | 2, value: boolean): void {
        const obj3D = this.getGraphicObject();
        if (obj3D) {
            // Saco la escena del modelo grafico para pedirle el plano de clipping en X.
            const scene = obj3D.parent as THREE.Scene;
            let name: string;
            if (axis === 0) {
                name = 'clipPlaneX';
            } else {
                if (axis === 1) {
                    name = 'clipPlaneY';
                } else {
                    name = 'clipPlaneZ';
                }
            }
            
            const plane = scene.getObjectByName(name);
            if (plane) {
                plane.visible = value;
                return;
            } else {
                console.error("ERROR: El plano '" + name + "' no existe. Posiblemente no estamos en modo clipping.")
                return;
            }            
        }
        window.alert("ERROR: No hay modelo grafico de malla disponible.");
    }

    /**
     * Prueba de fuerza bruta mostrando en pantalla para cada nodo un texto grafico de la forma "N: x, y, z" con su
     * indice ordinal y sus coordenadas 3D. Aqui usamos la infraestructura de texto usada en el resto de la aplicacion.
     * Va mal: Para 14000 nodos tarda 36 segundos en generarlo y pasamos de 60 a 6 FPS!!!.
     */
    public testTextPerformance(): void {
        const obj3D = this.getGraphicObject();
        if (obj3D) {
            // El estilo lo es todo!!!.
            const styleOpts = new TextOptsBuilder();

            styleOpts.basePointH = textMultiPosTypeH.MIDDLE;
            styleOpts.basePointV = textMultiPosTypeV.MEDIAN;
            styleOpts.size = 1;
            styleOpts.color = { r: 255, g: 0, b: 0, a: 0 };
            styleOpts.doubleSided = sdfDoubleSidedType.FRONT;

            const text: textParam = {
                styleId: styleOpts.styleId,
                text: "NADA DE NADA",
                position: { x: -1, y: -1, z: -1},
                angleO: 0,
                plane: { x: 0, y: 0, z: 0 },
                scale: 0.0125,
            };

            // Saco la escena del modelo grafico para meterle los textos.
            const scene = obj3D.parent as THREE.Scene;
            // Del objeto grafico que es un grupo, extraigo solamente el hijo con los nodos.
            const pointsGO = this.gObj_.getObjectByName(StructModel3D.name4Nodes) as THREE.Points<THREE.BufferGeometry>;
            const geo = pointsGO.geometry;

            // Para optimizar metemos todo en un grupo aparte.
            const group = new THREE.Group();
    
            // Sacamos el array de posiciones del buffer, material base de lo que pintare.
            // \DuDa: Pinto eso o el DMC con los puntos originales?.
            const buf = geo.getAttribute('position').array as Float32Array;
            const t0 = performance.now();
            for (let i = 0; i < this.numNodes_; ++i) {
                const x = buf[3 * i];
                const y = buf[3 * i + 1];
                const z = buf[3 * i + 2];
                const txt = "[" + i + "] (" + x.toFixed(2) + ", " + y.toFixed(2) + ", " + z.toFixed(2) + ")";
                // console.log(txt);

                text.text = txt;
                text.position.x = x;
                text.position.y = y;
                text.position.z = z + 0.01;
          
                const textGO = createText(text, styleOpts);
                group.add(textGO);
            }
            scene.add(group);
            const t1 = performance.now();
            console.log("Agregados " + this.numNodes_ + " textos en " + (t1 - t0).toFixed(3) + " mSecs.");
            return;
        }
        window.alert("ERROR: No hay modelo grafico de malla disponible.");
    }

    /**
     * Prueba de fuerza bruta mostrando en pantalla para cada nodo un texto grafico de la forma "N: x, y, z" con su
     * indice ordinal y sus coordenadas 3D. Aqui mapeamos los puntos con texturas con el texto, pero con sprites!!!.
     */
    public testTextPerformance_Sprites(): void {
        const obj3D = this.getGraphicObject();
        if (obj3D) {

            // Saco la escena del modelo grafico para meterle los textos.
            const scene = obj3D.parent as THREE.Scene;
            // Del objeto grafico que es un grupo, extraigo solamente el hijo con los nodos.
            const pointsGO = this.gObj_.getObjectByName(StructModel3D.name4Nodes) as THREE.Points<THREE.BufferGeometry>;
            const geo = pointsGO.geometry;

            // Para optimizar metemos todo en un grupo aparte.
            const group = new THREE.Group();
    
            // Sacamos el array de posiciones del buffer, material base de lo que pintare.
            // \DuDa: Pinto eso o el DMC con los puntos originales?.
            const buf = geo.getAttribute('position').array as Float32Array;

            const colorBG = new THREE.Color('red');
            const colorFG = new THREE.Color('white');

            const t0 = performance.now();
            for (let i = 0; i < this.numNodes_; i += 2) {
                const x = buf[3 * i];
                const y = buf[3 * i + 1];
                const z = buf[3 * i + 2];
                const txt = "[" + i + "] (" + x.toFixed(2) + ", " + y.toFixed(2) + ", " + z.toFixed(2) + ")";
                // console.log(txt);
                const texture = this.createTexture4Text(txt, colorFG, colorBG);          
                const material = new THREE.SpriteMaterial({ map: texture, toneMapped: false });
                const sprite = new THREE.Sprite(material);
                sprite.position.set(x, y, z);
                group.add(sprite);
            }
            scene.add(group);
            const t1 = performance.now();
            console.log("Agregados " + this.numNodes_ + " textos en " + (t1 - t0).toFixed(3) + " mSecs.");
            return;
        }
        window.alert("ERROR: No hay modelo grafico de malla disponible.");
    }

    /**
     * Crea textura cuadrada con minimo tamaño para albergar el texto dado, con los colores de letra y fondo dados.
     * @param txt 
     * @param colorFG 
     * @param colorBG 
     * @returns 
     */
    public createTexture4Text(txt: string, colorFG: THREE.Color, colorBG: THREE.Color): THREE.CanvasTexture {
        const canvas = document.createElement('canvas');
        const w = 64;
        const h = w / 2;
        canvas.width = w;
        canvas.height = h;
        const context = canvas.getContext('2d') as CanvasRenderingContext2D;
        context.rect(1, 1, w - 1, h - 1);
        context.fillStyle = colorBG.getStyle();
        context.fill();

        context.font = '10px Arial';
        context.textAlign = 'center';
        context.fillStyle = colorFG.getStyle();
        context.fillText(txt, 16, 26);

        const texture = new THREE.CanvasTexture(canvas);
        return texture;
    }

    public createRandomDeformations4Nodes(): void {
        // Supongamos normalizados todos nuestros nodos respecto a las 3 dimensiones de su hipotetica AABB en el
        // intervalo [0, 1] <===> [minX, maxX] <===> [minY, maxY] <===> [minZ, maxZ].
        // Calculamos un pto 3D aleatorio en [0, 1] que tomamos como foco, al que consideramos como centro de las
        // deformaciones. Para cada nodo calculamos su distancia al foco y en la linea que los une le metemos la
        // deformacion proporcional a esa distancia.
        const focus = new THREE.Vector3();
        focus.random();
        const dimX = this.aabb_.max.x - this.aabb_.min.x;
        const dimY = this.aabb_.max.y - this.aabb_.min.y;
        const dimZ = this.aabb_.max.z - this.aabb_.min.z;
        const minX = this.aabb_.min.x;
        const minY = this.aabb_.min.y;
        const minZ = this.aabb_.min.z;

        // Ahora tenemos el foco colocado en 3D.
        focus.multiply(new THREE.Vector3(dimX, dimY, dimZ));
        focus.add(new THREE.Vector3(minX, minY, minZ));

        if (this.vDeformations_) {
            this.vDeformations_.length = 0;
        } else {
            this.vDeformations_ = [];
        }

        const f2 = 0.001;
        let txt2Save: string = "";
        for (let i = 0; i < this.numNodes_; ++i) {
            let x = this.nodes_[3 * i];
            let y = this.nodes_[3 * i + 1];
            let z = this.nodes_[3 * i + 2];
            // Paso a [0, 1].
            x = (x - minX) / dimX;
            y = (y - minY) / dimY;
            z = (z - minZ) / dimZ;
            // Paso a [-1, +1].
            x = 2 * x - 1;
            y = 2 * y - 1;
            z = 2 * z - 1;
            // x = f2 * x * Math.sin(x);
            // y = f2 * y * Math.cos(y);
            // z = f2 * z * Math.sin(z) * Math.cos(z);
            x *= Math.sin(Math.abs(x));
            y *= Math.cos(Math.abs(y));
            z *= Math.sin(Math.abs(x)) * Math.cos(Math.abs(y));

            x = f2 * x * Math.random();
            y = f2 * y * Math.random();
            z = f2 * z * Math.random();

            // Esto en el fichero CDTI de Paul LitleField tenia valores en [-10, +10] con una media de -0.18, asi que
            // pongamos aqui algo aleatorio en [-10, +10] y que tenga que ver con las posiciones.
            // let x2 = this.nodes_[3 * i];
            // let y2 = this.nodes_[3 * i + 1];
            // let z2 = this.nodes_[3 * i + 2];
            // // Paso a [0, 1].
            // x2 = (x2 - minX) / dimX;
            // y2 = (y2 - minY) / dimY;
            // z2 = (z2 - minZ) / dimZ;
            // // Paso a [-10, +10].
            // x2 = 20 * x2 - 1;
            // y2 = 20 * y2 - 1;
            // z2 = 20 * z2 - 1;
            // let dr = x2 * x2 + y2 * y2 + z2 * z2;
            // dr = Math.sqrt(dr);

            let dr = 20 * Math.random() - 10;

            this.vDeformations_.push(x, y, z, 0, 0, dr);
            txt2Save += "" + x + " " + y + " " + z + " 0 0 " + dr + " ";
        }

        const name = "mesh_deformations_3d.dat";
        saveFile(txt2Save, name, "application/json");

        this.numDeformations_ = this.numNodes_;

        // Reseteamos para aprovechar el valor.
        this.resetNodes();
        this.applyDeformationsCoeff(this.lastCoeffF_);
    }

    public testArrowsPerformance_ArrowHelper(): void {
        // De momento vamos a poner una misera flecha en cada NODO sacado del objeto grafico en curso (pues si usamos
        // directamente los nodos originales y hay deformaciones pueden no casar). Ademas todas del mismo color y con
        // direccion hacia el cielo... Ya optimizaremos...
        if (!this.numNodes_ || this.gObj_ === null) {
            return;
        }

        const obj3D = this.getGraphicObject() as THREE.Group;

        const pointsGO = this.gObj_.getObjectByName(StructModel3D.name4Nodes) as THREE.Points<THREE.BufferGeometry>;
        const geo = pointsGO.geometry;

        // Parametros comunes a todas las flechas.
        const dir = new THREE.Vector3(0, 0, +1);
        // Es la longitud total.
        const arrowLength = 1.0;
        const headLength = 0.25;
        const headWidth = 0.2;
        const color = new THREE.Color('green');

        const helpers = new THREE.Group();
        helpers.name = 'arrows';
        
        // Sacamos el array de posiciones del buffer.
        const buf = geo.getAttribute('position').array as Float32Array;
        for (let i = 0; i < this.numNodes_; ++i) {
            // const defoX = this.vDeformations_[6 * i];
            // const defoY = this.vDeformations_[6 * i + 1];
            // const defoZ = this.vDeformations_[6 * i + 2];
            const x = buf[3 * i];
            const y = buf[3 * i + 1];
            const z = buf[3 * i + 2];
            const pos = new THREE.Vector3(x, y, z);

            const arrow = new THREE.ArrowHelper(dir, pos, arrowLength, color, headLength, headWidth);
            helpers.add(arrow);
        }

        // Esos helpers los agregamos en la misma escena en que esta el modelo, que es su padre.
        const scene = obj3D.parent as THREE.Scene;
        scene.add(helpers);
    }

    public showGraphicInfo(graPro: GraphicProcessor): void {
        console.log("\n");
        console.log(" +---------------------------------------------------------------------------------");
        console.log(" | GRAPHIC INFO FOR CURRENT MESH MODEL:");
        console.log(` |  NODES:      ${this.numNodes_}`);
        console.log(` |  BEAMS:      ${this.numBeams_}`);
        console.log(` |  CELLS:      ${this.numQuads_ + this.numTris_} (${this.numQuads_}Q + ${this.numTris_}T)`);
        // Ojo, que restamos a la AABB el gap de 1 m.
        console.log(` |  RANGE X:    [${(this.aabb_.min.x + 1.0).toFixed(2)}, ${(this.aabb_.max.x - 1.0).toFixed(2)}]`);
        console.log(` |  RANGE Y:    [${(this.aabb_.min.y + 1.0).toFixed(2)}, ${(this.aabb_.max.y - 1.0).toFixed(2)}]`);
        console.log(` |  RANGE Z:    [${(this.aabb_.min.z + 1.0).toFixed(2)}, ${(this.aabb_.max.z - 1.0).toFixed(2)}]`);
        const renderer = graPro.getRenderer();
        const info = renderer.info;
        console.log(" | GRAPHIC INFO FOR MAIN RENDERER:");
        console.log(` |  Three.js:   ${THREE.REVISION}`);
        console.log(` |  GEOMETRIES: ${info.memory.geometries}`);
        console.log(` |  TEXTURES:   ${info.memory.textures}`);
        console.log(` |  CALLS:      ${info.render.calls}`);
        console.log(` |  FRAME:      ${info.render.frame}`);
        console.log(` |  LINES:      ${info.render.lines}`);
        console.log(` |  POINTS:     ${info.render.points}`);
        console.log(` |  TRIANGLES:  ${info.render.triangles}`);
        const dimensions = new THREE.Vector2();
        renderer.getDrawingBufferSize(dimensions);
        console.log(` |  #SIZE X*Y:  ${dimensions.x} * ${dimensions.y}`);
        // Ademas emitimos informacion de uso de memoria si fuese posible.
        // @ts-ignore
        if (window.performance.memory) {
            // @ts-ignore
            const jsHeapSizeLimitNow = window.performance.memory.jsHeapSizeLimit;
            // @ts-ignore
            const totalJSHeapSizeNow = window.performance.memory.totalJSHeapSize;
            // @ts-ignore
            const usedJSHeapSizeNow = window.performance.memory.usedJSHeapSize;
            console.log(" | MEMORY INFO:")
            console.log(` |  jsHeapSizeLimitNow[GB]: ${(jsHeapSizeLimitNow / 1073741824.0).toFixed(2)}`);
            console.log(` |  totalJSHeapSizeNow[MB]: ${(totalJSHeapSizeNow / 1048576.0).toFixed(2)}`);
            console.log(` |  usedJSHeapSizeNow[MB]:  ${(usedJSHeapSizeNow / 1048576.0).toFixed(2)}`);
        }

        if (true) {
            console.log(" | RENDERER:");
            let canvas = document.createElement('canvas');
            const gl = canvas.getContext('webgl') as WebGLRenderingContext;
            const debugInfo = gl.getExtension('WEBGL_debug_renderer_info') as WEBGL_debug_renderer_info;
            const vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
            const renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
            console.log(` |  VENDOR:     ${vendor}`);
            console.log(` |  GPU:        ${renderer}`);
            canvas = null as unknown as HTMLCanvasElement;
        }

        console.log(" +---------------------------------------------------------------------------------\n");

        if (false) {
            // De momento aqui hare algunas pruebas.
            this.testParsingExternFiles();
        }
    }

    public testArrowsPerformance_BasicArrow3(): void {
        // De momento vamos a poner una misera flecha en cada NODO sacado del objeto grafico en curso (pues si usamos
        // directamente los nodos originales y hay deformaciones pueden no casar). Ademas todas del mismo color y con
        // direccion hacia el cielo... Ya optimizaremos...
        if (!this.numNodes_ || this.gObj_ === null) {
            return;
        }

        const obj3D = this.getGraphicObject() as THREE.Group;

        const pointsGO = this.gObj_.getObjectByName(StructModel3D.name4Nodes) as THREE.Points<THREE.BufferGeometry>;
        const geo = pointsGO.geometry;

        // Parametros comunes a todas las flechas.
        const dir = new THREE.Vector3(0, 0, +1);
        // Es la longitud total.
        const arrowLength = 1.0;
        const headLength = 0.20;
        const headWidth = 0.05;
        const color = (new THREE.Color('green')).getHex();

        const helpers = new THREE.Group();
        helpers.name = 'arrows';
        
        // Sacamos el array de posiciones del buffer.
        const buf = geo.getAttribute('position').array as Float32Array;
        for (let i = 0; i < this.numNodes_; ++i) {
            // const defoX = this.vDeformations_[6 * i];
            // const defoY = this.vDeformations_[6 * i + 1];
            // const defoZ = this.vDeformations_[6 * i + 2];
            const x = buf[3 * i];
            const y = buf[3 * i + 1];
            const z = buf[3 * i + 2];
            // const pos = new THREE.Vector3(x, y, z);

            const arrow = new Arrow3(arrowLength, headLength, headWidth, color);
            const go = arrow.gObj_;
            go.position.set(x, y, z);
            helpers.add(arrow.gObj_);
        }

        // Esos helpers los agregamos en la misma escena en que esta el modelo, que es su padre.
        const scene = obj3D.parent as THREE.Scene;
        scene.add(helpers);
    }

    public testArrowsPerformance_InstancedArrow3_InstancedMesh(): void {
        // Aqui tenemos una unica flecha que convertimos en un mesh multi-instanciado, de forma que cada una de esas
        // instancias la colocamos en cada nodo del grafico...
        // PROBLEMA: THREE.InstancedMesh como su nombre indica esta pensado para meshes y con lineas produce artefactos
        // que visualmente quedan mal.
        if (!this.numNodes_ || this.gObj_ === null) {
            return;
        }

        const obj3D = this.getGraphicObject() as THREE.Group;
        const scene = obj3D.parent as THREE.Scene;

        const pointsGO = this.gObj_.getObjectByName(StructModel3D.name4Nodes) as THREE.Points<THREE.BufferGeometry>;
        const geo = pointsGO.geometry;

        // Creo una sola flecha.
        const dir = new THREE.Vector3(0, 0, +1);
        // Es la longitud total.
        const arrowLength = 1.0;
        const headLength = 0.20;
        const headWidth = 0.05;
        const color = (new THREE.Color('green')).getHex();

        const arrow = new Arrow3(arrowLength, headLength, headWidth, color);
        const go = arrow.gObj_;

        // Creamos el mesh instanciado que tendra todas las instancias de flechas.
        // No le doy el material original de linea, sino este.
        const material = new THREE.MeshBasicMaterial({
            side: THREE.DoubleSide,
            color: 'orange',
            // Sin esto no se pintan como lineas.
            wireframe: true
        });

        const meshMultiArrow = new THREE.InstancedMesh(go.geometry, material, this.numNodes_);
        meshMultiArrow.instanceMatrix.setUsage(THREE.DynamicDrawUsage);
        scene.add(meshMultiArrow);

        // Y ahora posicionamos usando una instancia intermedia dummy.
        const dummy = new THREE.Object3D();
        const buf = geo.getAttribute('position').array as Float32Array;
        for (let i = 0; i < this.numNodes_; ++i) {
            const x = buf[3 * i];
            const y = buf[3 * i + 1];
            const z = buf[3 * i + 2];
            dummy.position.set(x, y, z);
            dummy.updateMatrix();
			meshMultiArrow.setMatrixAt(i, dummy.matrix);
        }

        // Con esto comentado parece funcionar.
        // meshMultiArrow.instanceMatrix.needsUpdate = true;
    }

    public testArrowsPerformance_InstancedArrow3_InstancedBufferGeometry(): void {
        // Aqui tenemos una unica flecha que convertimos en una geometria multi-instanciada, de forma que cada una de
        // esas instancias la colocamos en cada nodo del grafico y con una orientacion diferente...
        if (!this.numNodes_ || this.gObj_ === null) {
            return;
        }

        const obj3D = this.getGraphicObject() as THREE.Group;
        const scene = obj3D.parent as THREE.Scene;
        // this.useInstancedBufferGeometry(scene);

        // Creamos campo de flechas en posiciones de los nodos, orientadas aleatoriamente y con colores aleatorios.
        const pointsGO = this.gObj_.getObjectByName(StructModel3D.name4Nodes) as THREE.Points<THREE.BufferGeometry>;
        const geo = pointsGO.geometry;
        const buf = geo.getAttribute('position').array as Float32Array;

        const vPos = [];
        const vDir = [];
        const vClr = [];
        // Mucho ojo, que las direcciones se especifican por vectores 4D y no 3D, lo que provoca lio...
        const v = new THREE.Vector4();
        for (let i = 0; i < this.numNodes_; ++i) {
            const x = buf[3 * i];
            const y = buf[3 * i + 1];
            const z = buf[3 * i + 2];
            // Una cosa es la posicion, y otra la orientacion.
            vPos.push(x, y, z);
            // Asi se aplica la orientacion en cada punto, obviamente ya posicionado.
            v.x = x;
            v.y = y;
            v.z = z;
            v.w = 1.0;
            // Asi las cosas quedan en su sitio, con la orientacion por defecto que era (0, 0, +1), es decir PARRIBA!!!.
            // v.set(0, 0, 0, 1);
            // Siempre normalizar!!!.
            v.normalize();
            vDir.push(v.x, v.y, v.z, v.w);
            vClr.push(v.x, v.y, v.z);
        }
        const arrowsField4Nodes = Arrow3.createArrowsField(vPos, vDir, vClr);
        scene.add(arrowsField4Nodes);
        return;

        // Ahora vamos a probar a meter flechas en los barycenters de los beams/tris/quads.

        // [1] Beams: Los pondremos apuntando desde el baricentro en la direccion (0, -1, 0), hacia el espectador.
        vPos.length = 0;
        vDir.length = 0;
        vClr.length = 0;
        // Con (0, 0, 0, 1).normalize() los origenes estan en el punto dado y cada flecha se orienta por defecto.
        v.set(0.5, 0.5, 0.5, 1).normalize();

        const axisX = new THREE.Vector3(1, 0, 0);
        const angle = Math.PI / 2;
        const v3 = new THREE.Vector3();
        
        for (const id of this.getIds4AllBeamElements()) {
            const bary3D = this.barycenter4Beam(id) as P3D;
            vPos.push(...bary3D);
            vDir.push(v.x, v.y, v.z, v.w);

            // La direccion es la unitaria original (0, 0, +1) rotada en torno a X para apuntar hacia "delante".
            // v3.x = bary3D[0];
            // v3.y = bary3D[1];
            // v3.z = bary3D[2];


            // // Rotamos.
            // v3.applyAxisAngle(axisX, angle);
            // // Normalizamos.
            // v3.normalize();
            // // Y pasamos a 4D.
            // v.x = v3.x;
            // v.y = v3.y;
            // v.z = v3.z;
            // v.w = 1;
            // v.normalize();

            // vDir.push(v.x, v.y, v.z, v.w);

            vClr.push(1, 1, 1);
        }

        // vPos.push(0, 0, 0);
        // vDir.push(v.x, v.y, v.z, v.w);
        // vClr.push(1, 1, 1);

        // vPos.push(1, 1, 1);
        // vDir.push(v.x, v.y, v.z, v.w);
        // vClr.push(1, 0, 0);

        const arrowsField4Beams = Arrow3.createArrowsField(vPos, vDir, vClr);
        scene.add(arrowsField4Beams);

        vPos.length = 0;
        vDir.length = 0;
        vClr.length = 0;
        // Con esto apunta hacia arriba siempre, a la orientacion por defecto (0, 0, +1).
        v.set(0, 0, 0, 1).normalize();

        for (const id of this.getIds4AllTriangleElements()) {
            const [x, y, z] = this.barycenter4Triangle(id) as P3D;
            vPos.push(x, y, z);
            // Como orientacion aplicaremos la normal.
            const n = this.normal4Triangle(id) as P3D;
            // Siempre hay que normalizar porque sino se van a tomar por culo...
            v.set(n[0], n[1], n[2], 1).normalize();
            vDir.push(v.x, v.y, v.z, v.w);
            vClr.push(1, 0, 0);
        }
        const arrowsField4Tris = Arrow3.createArrowsField(vPos, vDir, vClr);
        scene.add(arrowsField4Tris);

    }

    private useInstancedBufferGeometry(scene: THREE.Scene): void {
        // Ejemplo adaptado del original en:
        // https://stackoverflow.com/questions/65839289/cant-get-a-three-js-instancedbuffergeometry-to-appear-in-a-scene

        // Creo una sola flecha.
        const dir = new THREE.Vector3(0, 0, +1);
        // Es la longitud total.
        const arrowLength = 1.0;
        const headLength = 0.20;
        const headWidth = 0.05;
        const color = (new THREE.Color('green')).getHex();
        
        const arrow = new Arrow3(arrowLength, headLength, headWidth, color);
        const arrowGO = arrow.gObj_ as THREE.LineSegments<THREE.BufferGeometry>;
        const geomSRC = arrowGO.geometry;
        // Con un simple cubo funciona.
        // const geomSRC_ = new THREE.BoxBufferGeometry();
        
        // Creamos la geometria [M]ulti-[I]nstanciada que tendra todas las instancias de flechas.
        const geomMIB = new THREE.InstancedBufferGeometry();
        geomMIB.attributes.position = geomSRC.attributes.position;
        // No solo copio los puntos de la geometria origen , sino tambien los indices.
        // geomMIB.index = geomSRC.index;        
        geomMIB.setIndex([
            0, 1,
            1, 2,
            1, 3,
            1, 4,
            1, 5
        ]);

        const count = 100000;
        geomMIB.instanceCount = count;
        const offsets = [];
        const orientations = [];
        const vExtColors = [];
        const vector = new THREE.Vector4();
        let x, y, z, w;
      
        
        for (let i = 0; i < count; ++i) {
      
          // offsets
          x = Math.random() * 100 - 50;
          y = Math.random() * 100 - 50;
          z = Math.random() * 100 - 50;

          x *= 0.5;
          y *= 0.5;
          z *= 0.5;
      
          vector.set(x, y, z, 0).normalize();
      
          offsets.push(x + vector.x, y + vector.y, z + vector.z);
      
          // orientations
          x = Math.random() * 2 - 1;
          y = Math.random() * 2 - 1;
          z = Math.random() * 2 - 1;
          w = Math.random() * 2 - 1;
      
          vector.set(x, y, z, w).normalize();
          orientations.push(vector.x, vector.y, vector.z, vector.w);
          vExtColors.push(Math.abs(x), Math.abs(y), Math.abs(z));
        }
      
        const offsetAttribute = new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3);
        const orientationAttribute = new THREE.InstancedBufferAttribute(new Float32Array(orientations), 4);
        const extColorsAttribute = new THREE.InstancedBufferAttribute(new Float32Array(vExtColors), 3);
      
        geomMIB.setAttribute('offset', offsetAttribute);
        geomMIB.setAttribute('orientation', orientationAttribute);
        geomMIB.setAttribute('extColor', extColorsAttribute);

        // Aqui van los shaders a huevo.
        const material = new THREE.RawShaderMaterial({
          vertexShader: `
            // precision highp float;
            // precision lowp float;

            uniform mat4 modelViewMatrix;
            uniform mat4 projectionMatrix;
  
            attribute vec3 position;
            attribute vec3 offset;
            attribute vec4 orientation;
            attribute vec3 extColor;
            varying vec3 extColor2;
  
            void main(){
              extColor2 = extColor;
              vec3 pos = offset + position;
              vec3 vcV = cross(orientation.xyz, pos);
              pos = vcV * (2.0 * orientation.w) + (cross(orientation.xyz, vcV) * 2.0 + pos);
              gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
            }`,

          fragmentShader: `
            precision highp float;
            // precision lowp float;
            varying vec3 extColor2;

            void main() {
              // gl_FragColor = vec4(1.0, 0.0, 0.0, 1.0);
              gl_FragColor = vec4(extColor2.xyz, 1.0);
            }`,

          // side: THREE.DoubleSide
        });
      
        // const mesh = new THREE.Mesh(geomMIB, material);
        // scene.add(mesh);
        const arrows = new THREE.LineSegments(geomMIB, material);
        scene.add(arrows);

    }

    public testArrowsPerformance(): void {
        // Pon aqui lo que quieras probar...
        // this.testArrowsPerformance_InstancedArrow3_InstancedMesh();
        this.testArrowsPerformance_InstancedArrow3_InstancedBufferGeometry();
    }

    // Devuelve las coordenadas 3D reales (no graficas) del nodo de indice/posicion dado.
    // \Warning: Suponemos el indice correcto, por lo que no se hacen comprobaciones.
    // Ojo que el i esta en [0, this.numNodes_) y es una posicion, no un indice como en los beams/tris/quads.
    public getNode(i: number): P3D {
        const x = this.nodes_[i * 3];
        const y = this.nodes_[i * 3 + 1];
        const z = this.nodes_[i * 3 + 2];
        return [x, y, z] as P3D;
    }

    /**
     * Calculo del baricentro real del elemento beam de INDICE dado, no posicion.
     * En caso de error null al canto.
     * @param id
     * @returns 
     */
    public barycenter4Beam(id: number): P3D | null {
        if (this.mElems_.has(id)) {
            const vNodes = this.getNodes4ElementI(id);
            if (vNodes.length === 2) {
                // Indices de los 2 puntos/nodos implicados.
                const [i0, i1] = vNodes as [number, number];
                // De los indices sacamos los puntos/nodos 3D REALES, no graficos.
                const p0 = this.getNode(i0);
                const p1 = this.getNode(i1);
                let x = p0[0] + p1[0];
                let y = p0[1] + p1[1];
                let z = p0[2] + p1[2];
                x *= 0.5;
                y *= 0.5;
                z *= 0.5;
                return [x, y, z] as P3D;
            }
        }
        return null;
    }

    /**
     * Calculo del baricentro real del elemento triangle shell/cell de INDICE dado, no posicion.
     * En caso de error null al canto.
     * @param id 
     * @returns 
     */
    public barycenter4Triangle(id: number): P3D | null {
        if (this.mElems_.has(id)) {
            const vNodes = this.getNodes4ElementI(id);
            if (vNodes.length === 3) {
                // Indices de los 3 nodos implicados.
                const [i0, i1, i2] = vNodes as [number, number, number];
                // De los indices sacamos los puntos/nodos 3D REALES, no graficos.
                const p0 = this.getNode(i0);
                const p1 = this.getNode(i1);
                const p2 = this.getNode(i2);
                let x = p0[0] + p1[0] + p2[0];
                let y = p0[1] + p1[1] + p2[1];
                let z = p0[2] + p1[2] + p2[2];
                x /= 3.0;
                y /= 3.0;
                z /= 3.0;
                return [x, y, z] as P3D;
            }
        }
        return null;
    }

    /**
     * Calculo del baricentro real del elemento quad shell/cell de INDICE dado, no posicion.
     * En caso de error null al canto.
     * @param id 
     * @returns 
     */
    public barycenter4Quad(id: number): P3D | null {
        if (this.mElems_.has(id)) {
            const vNodes = this.getNodes4ElementI(id);
            if (vNodes.length === 4) {
                // Indices de los 3 nodos implicados.
                const [i0, i1, i2, i3] = vNodes as [number, number, number, number];
                // De los indices sacamos los puntos/nodos 3D REALES, no graficos.
                const p0 = this.getNode(i0);
                const p1 = this.getNode(i1);
                const p2 = this.getNode(i2);
                const p3 = this.getNode(i3);
                let x = p0[0] + p1[0] + p2[0] + p3[0];
                let y = p0[1] + p1[1] + p2[1] + p3[1];
                let z = p0[2] + p1[2] + p2[2] + p3[2];
                x *= 0.25;
                y *= 0.25;
                z *= 0.25;
                return [x, y, z] as P3D;
            }
        }
        return null;
    }

    /**
     * Calculo del baricentro real (correspondiente a los valores no graficos) del shell de id dado.
     * Lo que se da es un INDICE, no una posicion.
     * En caso de error se devuelve null.
     * @param id 
     * @returns
     */
    public barycenter4Shell(id: number): P3D | null {
        if (this.mElems_.has(id)) {
            const vNodes = this.getNodes4ElementI(id);
            if (vNodes.length === 4) {
                // Indices de los 3 nodos implicados.
                const [i0, i1, i2, i3] = vNodes as [number, number, number, number];
                // De los indices sacamos los puntos/nodos 3D REALES, no graficos.
                const p0 = this.getNode(i0);
                const p1 = this.getNode(i1);
                const p2 = this.getNode(i2);
                const p3 = this.getNode(i3);
                let x = p0[0] + p1[0] + p2[0] + p3[0];
                let y = p0[1] + p1[1] + p2[1] + p3[1];
                let z = p0[2] + p1[2] + p2[2] + p3[2];
                x *= 0.25;
                y *= 0.25;
                z *= 0.25;
                return [x, y, z] as P3D;
            }
            if (vNodes.length === 3) {
                // Indices de los 3 nodos implicados.
                const [i0, i1, i2] = vNodes as [number, number, number];
                // De los indices sacamos los puntos/nodos 3D REALES, no graficos.
                const p0 = this.getNode(i0);
                const p1 = this.getNode(i1);
                const p2 = this.getNode(i2);
                let x = p0[0] + p1[0] + p2[0];
                let y = p0[1] + p1[1] + p2[1];
                let z = p0[2] + p1[2] + p2[2];
                x /= 3.0;
                y /= 3.0;
                z /= 3.0;
                return [x, y, z] as P3D;
            }
        }
        return null;
    }

    /// Calculo de la normal de un triangulo de indice dado.
    public normal4Triangle(i: number): P3D | null {
        if (i < this.numTris_) {            
            // Indices de los 3 puntos/nodos implicados.
            const i0 = this.trisIV_[3 * i];
            const i1 = this.trisIV_[3 * i + 1];
            const i2 = this.trisIV_[3 * i + 2];
            // De los indices sacamos los puntos/nodos 3D REALES, no graficos.
            const [x0, y0, z0] = this.getNode(i0);
            const [x1, y1, z1] = this.getNode(i1);
            const [x2, y2, z2] = this.getNode(i2);
            const plane = new THREE.Plane();
            const a = new THREE.Vector3(x0, y0, z0);
            const b = new THREE.Vector3(x1, y1, z1);
            const c = new THREE.Vector3(x2, y2, z2);
            const abV = new THREE.Vector3();
            const cbV = new THREE.Vector3();

            const normal = cbV.subVectors(c, b).cross(abV.subVectors(a, b)).normalize();
            return [normal.x, normal.y, normal.z] as P3D;
        }
        return null;
    }

    /**
     * Leemos los ficheros externos de deformaciones y F&M y vemos que pinta tienen sus datos.
     */
    private testParsingExternFiles(): void {
        // Probamos el parser generico de ficheros de datos.
        const gPrsr = new GenParser();

        // Aqui leemos los datos de deformaciones que son:
        // [0] 'DX'   [1] 'DY'   [2] 'DZ'   [3] 'DRX'   [4] 'DRY'   [5] 'DR'
        gPrsr.readData4File('/files_mesh3d/hyp_disp_cdti.txt');

        // Podemos simular un paso por referencia en JS/TS mediante un array?. Asi simplificariamos codigo.
        const getMinMaxSumatory = (arg: [number, number, number, number]): [number, number, number] => {
            let [x, minX, maxX, accumX] = arg;
            (x < minX) && (minX = x);
            (x > maxX) && (maxX = x);
            accumX += x;
            return [minX, maxX, accumX];
        };

        const initVals = [+Infinity, -Infinity, 0.0];

        // // A las deformaciones les podria quitar estas columnas.
        // const vCols2Delete: string[] = ['DR', 'DRY', 'DRX'];
        // if (gPrsr.deleteDataColumns(vCols2Delete)) {
        //     console.log(`Borradas las columnas [${vCols2Delete}]`);
        // } else {
        //     console.error(`ERROR al intentar borrar las columnas [${vCols2Delete}]`);
        // }

        // [1] ANALISIS DE DATOS DX-DY-DZ:
        
        // A los datos leidos les aplico esta funcion para calcular los valores minimos y maximos, asi como el modulo
        // minimo y maximo para las 3 coordenadas de deformaciones en estas columnas:
        // Recuerda que el orden es el dado en las columnas vCols.
        let vCols = ['DX', 'DY', 'DZ'];
        let [minDX, maxDX, avgDX] = initVals;
        let [minDY, maxDY, avgDY] = initVals;
        let [minDZ, maxDZ, avgDZ] = initVals;
        let [minModDXYZ, maxModDXYZ, avgModDXYZ] = initVals;
        let numValues = 0;

        const getAveragesLimits4DXDYDZ = (DX: number, DY: number, DZ: number): void => {
            [minDX, maxDX, avgDX] = getMinMaxSumatory([DX, minDX, maxDX, avgDX]);
            [minDY, maxDY, avgDY] = getMinMaxSumatory([DY, minDY, maxDY, avgDY]);
            [minDZ, maxDZ, avgDZ] = getMinMaxSumatory([DZ, minDZ, maxDZ, avgDZ]);
            const modDXYZ = Math.sqrt(DX * DX + DY * DY + DZ * DZ);
            [minModDXYZ, maxModDXYZ, avgModDXYZ] = getMinMaxSumatory([modDXYZ, minModDXYZ, maxModDXYZ, avgModDXYZ]);

            ++numValues;
        };

        const logMsgMinMaxAvg = (msg: string, arg: [number, number, number]): void => {
            const [minX, maxX, avgX] = arg;
            console.log(`\t${msg}:   [${minX}, ${maxX}]   ${avgX}`);
        };

        let res = gPrsr.applyFunctor2Columns(getAveragesLimits4DXDYDZ, vCols);
        if (res) {
            avgDX /= numValues;
            avgDY /= numValues;
            avgDZ /= numValues;
            avgModDXYZ /= numValues;
    
            console.log(`N: ${numValues}`);
            console.log("Limits + averages:");
            logMsgMinMaxAvg("DX", [minDX, maxDX, avgDX]);
            logMsgMinMaxAvg("DY", [minDY, maxDY, avgDY]);
            logMsgMinMaxAvg("DZ", [minDZ, maxDZ, avgDZ]);
            logMsgMinMaxAvg("modDXYZ", [minModDXYZ, maxModDXYZ, avgModDXYZ]);
        }

        // [2] ANALISIS DE DATOS DRX-DRY-DR:
        vCols = ['DRX', 'DRY', 'DR'];
        let [minDRX, maxDRX, avgDRX] = initVals;
        let [minDRY, maxDRY, avgDRY] = initVals;
        let [minDR, maxDR, avgDR] = initVals;
        let [minModDRXY, maxModDRXY, avgModDRXY] = initVals;

        const getAveragesLimits4DRXDRYDR = (DRX: number, DRY: number, DR: number): void => {
            [minDRX, maxDRX, avgDRX] = getMinMaxSumatory([DRX, minDRX, maxDRX, avgDRX]);
            [minDRY, maxDRY, avgDRY] = getMinMaxSumatory([DRY, minDRY, maxDRY, avgDRY]);
            [minDR, maxDR, avgDR] = getMinMaxSumatory([DR, minDR, maxDR, avgDR]);
            const modDRXY = Math.sqrt(DRX * DRX + DRY * DRY);
            [minModDRXY, maxModDRXY, avgModDRXY] = getMinMaxSumatory([modDRXY, minModDRXY, maxModDRXY, avgModDRXY]);
            ++numValues;
        };

        res = gPrsr.applyFunctor2Columns(getAveragesLimits4DRXDRYDR, vCols);
        if (res) {
            avgDRX /= numValues;
            avgDRY /= numValues;
            avgDR /= numValues;
            avgModDRXY /= numValues;
    
            logMsgMinMaxAvg("DRX", [minDRX, maxDRX, avgDRX]);
            logMsgMinMaxAvg("DRY", [minDRY, maxDRY, avgDRY]);
            logMsgMinMaxAvg("DR", [minDR, maxDR, avgDR]);
            logMsgMinMaxAvg("modDRXY", [minModDRXY, maxModDRXY, avgModDRXY]);
        }

        gPrsr.destroy();

        // Aqui leemos los datos de fuerzas y momentos que son todos datos independientes NO vectoriales.
        // [0]'NXX'   [1]'NYY'   [2]'NXY'   [3]'MXX'   [4]'MYY'   [5]'MXY'   [6]'QX'   [7]'QY'
        gPrsr.readData4File('/files_mesh3d/hyp_forc_cdti.txt');

        let [minNXX, maxNXX, avgNXX] = initVals;
        let [minNYY, maxNYY, avgNYY] = initVals;
        let [minNXY, maxNXY, avgNXY] = initVals;
        let [minMXX, maxMXX, avgMXX] = initVals;
        let [minMYY, maxMYY, avgMYY] = initVals;
        let [minMXY, maxMXY, avgMXY] = initVals;
        let [minQX, maxQX, avgQX] = initVals;
        let [minQY, maxQY, avgQY] = initVals;

        vCols = ['NXX', 'NYY', 'NXY', 'MXX', 'MYY', 'MXY', 'QX', 'QY'];
        const getData4FM = (
            NXX: number, NYY: number, NXY: number,
            MXX: number, MYY: number, MXY: number,
            QX: number, QY: number) => {
            [minNXX, maxNXX, avgNXX] = getMinMaxSumatory([NXX, minNXX, maxNXX, avgNXX]);
            [minNYY, maxNYY, avgNYY] = getMinMaxSumatory([NYY, minNYY, maxNYY, avgNYY]);
            [minNXY, maxNXY, avgNXY] = getMinMaxSumatory([NXY, minNXY, maxNXY, avgNXY]);
            [minMXX, maxMXX, avgMXX] = getMinMaxSumatory([MXX, minMXX, maxMXX, avgMXX]);
            [minMYY, maxMYY, avgMYY] = getMinMaxSumatory([MYY, minMYY, maxMYY, avgMYY]);
            [minMXY, maxMXY, avgMXY] = getMinMaxSumatory([MXY, minMXY, maxMXY, avgMXY]);
            [minQX, maxQX, avgQX] = getMinMaxSumatory([QX, minQX, maxQX, avgQX]);
            [minQY, maxQY, avgQY] = getMinMaxSumatory([QY, minQY, maxQY, avgQY]);            
        };

        res = gPrsr.applyFunctor2Columns(getData4FM, vCols);
        if (res) {
            avgNXX /= numValues;
            avgNYY /= numValues;
            avgNXY /= numValues;
            avgMXX /= numValues;
            avgMYY /= numValues;
            avgMXY /= numValues;
            avgQX /= numValues;
            avgQY /= numValues;

            logMsgMinMaxAvg("NXX", [minNXX, maxNXX, avgNXX]);
            logMsgMinMaxAvg("NYY", [minNYY, maxNYY, avgNYY]);
            logMsgMinMaxAvg("NXY", [minNXY, maxNXY, avgNXY]);
            logMsgMinMaxAvg("MXX", [minMXX, maxMXX, avgMXX]);
            logMsgMinMaxAvg("MYY", [minMYY, maxMYY, avgMYY]);
            logMsgMinMaxAvg("MXY", [minMXY, maxMXY, avgMXY]);
            logMsgMinMaxAvg("QX", [minQX, maxQX, avgQX]);
            logMsgMinMaxAvg("QY", [minQY, maxQY, avgQY]);
        }

    }

    private calculateAABB4PointCloud(vP3D: ArrayLike<number>): THREE.Box3 {
        const N = vP3D.length / 3;
        let [minX, maxX] = [vP3D[0], vP3D[0]];
        let [minY, maxY] = [vP3D[1], vP3D[1]];
        let [minZ, maxZ] = [vP3D[2], vP3D[2]];

        for (let i = 1; i < N; ++i) {
            const x = vP3D[3 * i];
            const y = vP3D[3 * i + 1];
            const z = vP3D[3 * i + 2];
            if (x < minX) {
                minX = x;
            } else {
                if (x > maxX) {
                    maxX = x;
                }
            }
            if (y < minY) {
                minY = y;
            } else {
                if (y > maxY) {
                    maxY = y;
                }
            }
            if (z < minZ) {
                minZ = z;
            } else {
                if (z > maxZ) {
                    maxZ = z;
                }
            }
        }
        
        const vMin = new THREE.Vector3(minX, minY, minZ);
        const vMax = new THREE.Vector3(maxX, maxY, maxZ);
        const aabb = new THREE.Box3(vMin, vMax);
        return aabb;
    }

    private setStressData(nodesBA: Float32Array, i4Beams: number[] | null, i4Tris: number[] | null, i4Quads: number[] | null): void {
        // Tamaños originales de los arrays recibidos.
        const N = nodesBA.length / 3;
        const B = i4Beams ? i4Beams.length : 0;
        const T = i4Tris ? i4Tris.length : 0;
        const Q = i4Quads ? i4Quads.length : 0;

        const NX = this.numXStress_;
        const MY = this.numYStress_;
        const NM = NX * MY;
        
        // Lo primero sacar los limites de los nodos actuales, para saber como posicionar los NM-1 restantes submodelos.
        const aabb = this.calculateAABB4PointCloud(nodesBA);
        // Se expande un metro por cada lateral.
        const gap = 1.0;
        const vGap = new THREE.Vector3(gap, gap, gap);
        aabb.expandByVector(vGap);
        // Y sacamos las dimensiones iniciales.
        const vDims = new THREE.Vector3();
        aabb.getSize(vDims);
        const dimX = vDims.x;
        const dimY = vDims.y;
        // Vamos a separar los submodelos por estos valores en el plano XY.
        const [gapX, gapY] = [5, 10];
        // Aqui metemos los puntos originales mas los expandidos.
        const vP3D: number[] = Array.from(nodesBA);

        for (let i = 0; i < this.numXStress_; ++i) {
            for (let j = 0; j < this.numYStress_; ++j) {
                if (i === 0 && j === 0) {
                    // Nada que hacer pues se hizo en la inicializacion.
                } else {
                    const x0 = i * (dimX + gapX);
                    const y0 = j * (dimY + gapY);
                    // Ojo, que son posiciones de puntos, no de sus componentes!!!.
                    const offset = vP3D.length / 3;

                    // Incorporacion de los puntos expandidos.
                    for (let n = 0; n < N; ++n) {
                        let x = vP3D[3 * n];
                        let y = vP3D[3 * n + 1];
                        let z = vP3D[3 * n + 2];
                        x += x0;
                        y += y0;
                        vP3D.push(x, y, z);
                    }

                    // Expansion de indices.
                    if (i4Beams) {
                        for (let n = 0; n < B; ++n) {
                            let iB = i4Beams[n];
                            iB += offset;
                            i4Beams.push(iB);
                        }
                    }
                    if (i4Tris) {
                        for (let n = 0; n < T; ++n) {
                            let iT = i4Tris[n];
                            iT += offset;
                            i4Tris.push(iT);
                        }
                    }
                    if (i4Quads) {
                        for (let n = 0; n < Q; ++n) {
                            let iQ = i4Quads[n];
                            iQ += offset;
                            i4Quads.push(iQ);
                        }
                    }
                }
            }    
        }

        this.setNodes(new Float32Array(vP3D));
        this.setIndices(i4Beams, i4Tris, i4Quads);
    }

    /**
     * Procesa un mapa de nodos e informa de como se ajusta a la topologia de meshing previamente existente.
     * @param mElems
     */
    private matchNewMeshingTopology(mElems: Map<number, number[]>): Map<number, number> {
        // Con este mapa que devolvemos al exterior podremos traducir los indices de los nuevos elementos a los indices
        // de los elementos iniciales en el meshing.
        const mapNew2SrcElems = new Map<number, number>();
        // Como doble comprobacion.
        const mapSrc2NewElems = new Map<number, number>();
        let index = 0;
        let N = mElems.size;
        let numNotFound = 0;
        let identical = 0;

        console.log("MATCHING NEW & OLD MESHING TOPOLOGY...");
        console.log("=================================================");

        for (const [iElemJ, vNodesJ] of mElems) {
            let msgJ = "[" + index + "/" + N + "] NEW ";
            const type234J = vNodesJ.length;

            if (type234J === 2) {
                msgJ += "B";
            } else {
                if (type234J === 3) {
                    msgJ += "T";
                } else {
                    msgJ += "Q";
                }
            }
            msgJ += iElemJ + " = { " + vNodesJ + " }";
            
            // Localizamos el elemento original de nuestro meshing que contiene TODOS esos nodos, posiblemente en diferente orden.
            let found = false;

            for (const [iElemK, [type234K, offset4Nodes]] of this.mElems_) {
                if (type234K !== type234J) {
                    continue;
                }

                let src4Nodes = this.quadsIV_;
                const vNodesK: number[] = [];

                if (type234K === 2) {
                    src4Nodes = this.beamsIV_;
                    const iN0 = src4Nodes[offset4Nodes];
                    const iN1 = src4Nodes[offset4Nodes + 1];
                    vNodesK.push(iN0, iN1);
                } else {
                    if (type234K === 3) {
                        src4Nodes = this.trisIV_;
                        const iN0 = src4Nodes[offset4Nodes];
                        const iN1 = src4Nodes[offset4Nodes + 1];
                        const iN2 = src4Nodes[offset4Nodes + 2];
                        vNodesK.push(iN0, iN1, iN2);
                    } else {
                        const iN0 = src4Nodes[offset4Nodes];
                        const iN1 = src4Nodes[offset4Nodes + 1];
                        const iN2 = src4Nodes[offset4Nodes + 2];
                        const iN3 = src4Nodes[offset4Nodes + 3];
                        vNodesK.push(iN0, iN1, iN2, iN3);
                    }
                }

                // Comparamos ambos arrays.
                if (compareArraysNoOrder(vNodesJ, vNodesK)) {
                    // msgJ += " <===> SRC ELEM " + iElemK + " = { " + vNodesK + " }";
                    if (compareArraysOrdered(vNodesJ, vNodesK)) {
                        msgJ += " EXACT!!!";
                        ++identical;
                    } // else {
                    //     // Veamos si son una rotacion hacia izquierda o derecha.
                    //     const rightRotations = compareArraysRotated(vNodesK, vNodesJ);
                    //     if (-1 !== rightRotations) {
                    //         msgJ += " " + rightRotations + "-RIGHT-ROTATIONS.";
                    //     } else {
                    //         msgJ += " DISORDER!!!";
                    //     }
                    // }
                    // console.log(msgJ);
                    found = true;

                    if (!mapNew2SrcElems.has(iElemJ)) {
                        mapNew2SrcElems.set(iElemJ, iElemK);
                    } else {
                        window.alert("ERROR: Clave repetida (I)!!!");
                    }

                    // Doble comprobacion.
                    if (!mapSrc2NewElems.has(iElemK)) {
                        mapSrc2NewElems.set(iElemK, iElemJ);
                    } else {
                        window.alert("ERROR: Clave repetida (II)!!!");
                    }

                    break;
                }                
            }
            if (!found) {
                msgJ += " NOT FOUND!!!."
                console.error(msgJ);
                ++numNotFound;
            }

            index += 1;
        }

        if (numNotFound) {
            console.error(`Element with no match: ${numNotFound}.`);
        } else {
            console.log(`All ${N} new elements have been correctly matched with old elements.`);
            console.log(`\tIdentical: ${identical}.`);
        }

        return mapNew2SrcElems;
    }


    private readStoreysInfo(vObjs: any[]): void {
        this.mStoreys_.clear();

        const N = vObjs.length;
        console.log(`Reading information for ${N} storeys...`);

        for (let i = 0; i < N; ++i) {
            const jStorey = vObjs[i];
            console.log(`\t[${i}/${N}] "${jStorey.name}" ===> id:${jStorey.id}`);
            const vElements = jStorey.elements as any[];
            const M = vElements.length;
            let cntSlabs = 0;
            const mIndices4SlabsNames = new Map<number, string>();
            for (let j = 0; j < M; ++j) {
                const elem = vElements[j];
                const url = elem.eClass;
                const type = url.substring(url.lastIndexOf('/') + 1);
                console.log(`\t\t[${j}/${M}] id:${elem.id} "${elem.name}" ===> type:${type}`);
                // Atencion: Hay estos 2 tipos de slabs por el momento.
                if (type === "FlatSlab" || type === "WaffleSlab") {
                    // Metemos el nombre del slab indexado por su ordinal 0-based.
                    mIndices4SlabsNames.set(cntSlabs, elem.name);
                    ++cntSlabs;
                }
            }

            if (cntSlabs) {
                const info: StoreyInfo = {
                    name: jStorey.name,
                    elevation: jStorey.elevation,
                    // Todavia no hemos calculado los COG.
                    z: -Infinity,
                    vCOG: [],
                    vShellPos4COG: [],
                    cntSlabs: cntSlabs,
                    mIndices4Nodes: new Map<number, number[]>(),
                    mIndices4Beams: new Map<number, number[]>(),
                    mIndices4Tris: new Map<number, number[]>(),
                    mIndices4Quads: new Map<number, number[]>(),
                    // Ojo que rellenamos aqui los nombres de los slabs.
                    mIndices4SlabsNames: mIndices4SlabsNames,
                };

                this.mStoreys_.set(i, info);
            } else {
                console.error(`WARNING: LA PLANTA [${i}] NO CONTIENE NINGUN SLAB Y NO SERA TENIDA EN CUENTA.`);
                // debugger;
            }
        }
    }

    /**
     * Crea un mapa con claves los indices de los storeys y con valor un duo formado por los indices de los grupos en
     * el orden exacto [indexGroup4Nodes, indexGroup4Elements], con los indices de los grupos de nodos y de elementos
     * shell contenidos en cada planta y separados por slabs.
     * @param vObjs 
     * @returns 
     */
    private readStructuralElementsInfo(vObjs: any[]): Map<number, [number, number][]> {
        const mStorey2Groups = new Map<number, [number, number][]>();
        const N = vObjs.length;
        const getLastURL = (url: string): string => {
            return url.substring(url.lastIndexOf('/') + 1);
        };

        console.log(`Reading information for ${N} structural elements...`);
        for (let i = 0; i < N; ++i) {
            const jElem = vObjs[i];            
            const type0 = getLastURL(jElem.eClass);
            // No solo tenemos en cuenta los "ShellStructuralElement" donde suelen anidar los "FlatSlab", sino tambien
            // los nuevos "FEMSEComp" donde moran los "WaffleSlab", creo...
            if (type0 === "ShellStructuralElement" || type0 === "FEMSEComp") {
                const structuralElem = jElem.structuralelement;
                const type1 = getLastURL(structuralElem.eClass);
                const ref1 = structuralElem.$ref;                
                if (type1 !== "FlatSlab" && type1 !== "WaffleSlab") {
                    continue;
                }
                console.log(`\t[${i}/${N}] "${jElem.eClass}"`);
                console.log(`\t\t ${type1} ===> ${ref1}`);
                const index4Storey = GenParser.getIndex4Ref(ref1, "storeys");
                if (-1 === index4Storey) {
                    debugger;
                }

                // ACHTUNG!!!: Aqui esta el ultimo cambio donde se rompe la compatibilidad con lo anterior.
                if (jElem.sections) {
                    // \ToDo: Quitar esto cuando no sea necesario.
                    // En la version previa de meshing_demo teniamos secciones con los datos.
                    // Hay varias secciones donde pueden estar los diferentes grupos de nodos/shells por SLAB.
                    const vSections = jElem.sections;
                    console.log(`\t\t Sections for storey [${index4Storey}]: ${vSections.length}`);
                    const vData: [number, number][] = [];
                    for (const section of vSections) {
                        const type2 = getLastURL(section.eClass);
                        const nodeGroup = section.nodeGroup;
                        const refNodeGroup = nodeGroup.$ref;
                        const elemGroup = section.elementGroup;
                        const refElemGroup = elemGroup.$ref;
                        // Como no se aun que indice de grupo tomar, sacamos ambos.
                        console.log(`\t\t ${type2} ===> "${refNodeGroup}" + "${refElemGroup}"`);
                        const index4NodeGroup = GenParser.getIndex4Ref(refNodeGroup, "groups");
                        const index4ElemGroup = GenParser.getIndex4Ref(refElemGroup, "groups");
                        if (-1 === index4NodeGroup || -1 === index4ElemGroup) {
                            debugger;
                        }
                        vData.push([index4NodeGroup, index4ElemGroup])
                    }
                    if (mStorey2Groups.has(index4Storey)) {
                        debugger;
                    }
                    mStorey2Groups.set(index4Storey, vData);
                } else {
                    // Pero en las versiones nuevas esto desaparece y esa informacion la tenemos de otra forma.
                    // "elementGroup": {
                    //     "eClass": "http://www.example.org/buildingmodel#//mesh/FEMGroup",
                    //     "$ref": "//@versions.0/@femmeshstructure/@groups.7"
                    // },
                    // "structuralelement": {
                    //     "eClass": "http://www.example.org/buildingmodel#//structural/FlatSlab",
                    //     "$ref": "//@versions.0/@building/@storeys.0/@elements.3"
                    // },
                    // "nodeGroup": {
                    //     "eClass": "http://www.example.org/buildingmodel#//mesh/FEMGroup",
                    //     "$ref": "//@versions.0/@femmeshstructure/@groups.6"
                    // },

                    // No tengo claro aun como va la cosa cuando hay varios slabs...                    
                    if (jElem.nodeGroup && jElem.elementGroup) {
                        const nodeGroup = jElem.nodeGroup;
                        const refNodeGroup = nodeGroup.$ref;
                        const elemGroup = jElem.elementGroup;
                        const refElemGroup = elemGroup.$ref;
                        const index4NodeGroup = GenParser.getIndex4Ref(refNodeGroup, "groups");
                        const index4ElemGroup = GenParser.getIndex4Ref(refElemGroup, "groups");
                        if (-1 === index4NodeGroup || -1 === index4ElemGroup) {
                            debugger;
                        }
                        const vData: [number, number][] = [];
                        vData.push([index4NodeGroup, index4ElemGroup]);
                        if (mStorey2Groups.has(index4Storey)) {
                            debugger;
                        }
                        mStorey2Groups.set(index4Storey, vData);
                    } else {
                        if (jElem.elements) {
                            const M = jElem.elements.length;
                            let i_M = 0;
                            const vData: [number, number][] = [];
                            for (const element of jElem.elements) {
                                console.log(`Processing WaffleSlab element [${i_M}/${M}]...`);

                                const nodeGroup = element.nodeGroup;
                                const refNodeGroup = nodeGroup.$ref;
                                const elemGroup = element.elementGroup;
                                const refElemGroup = elemGroup.$ref;
                                const index4NodeGroup = GenParser.getIndex4Ref(refNodeGroup, "groups");
                                const index4ElemGroup = GenParser.getIndex4Ref(refElemGroup, "groups");
                                if (-1 === index4NodeGroup || -1 === index4ElemGroup) {
                                    debugger;
                                }
                                vData.push([index4NodeGroup, index4ElemGroup]);
                                ++i_M;
                            }
                            if (mStorey2Groups.has(index4Storey)) {
                                debugger;
                            }
                            mStorey2Groups.set(index4Storey, vData);    
                        } else {
                            console.error("ERROR: Maybe the model JSON format has changed???.");
                            debugger;
                        }
                    }
                }
            }
        }

        return mStorey2Groups;
    }

    private readAllGroups4FemMeshStructure(vObjs: any[], mStorey2Groups: Map<number, [number, number][]>): void {
        const N = vObjs.length;
        const M = mStorey2Groups.size;
        const getLastURL = (url: string): string => {
            return url.substring(url.lastIndexOf('/') + 1);
        };

        console.log(`Reading information for ${M} storeys with ${N} groups in FemMeshStructure...`);
        for (const [index4Storey, vData] of mStorey2Groups) {
            console.log(`Storey [${index4Storey}] with ${vData.length} sections:`);
            if (!this.mStoreys_.has(index4Storey)) {
                window.alert(`ERROR: El storey [${index4Storey}] no esta contenido en la lista!!!.`)
                debugger;
            }

            let index = 0;
            for (const [indexGroup4Nodes, indexGroup4Elems] of vData) {
                console.log(`\t Group4Nodes[${indexGroup4Nodes}] + Group4Elems[${indexGroup4Nodes}]`);
                const group4Nodes = vObjs[indexGroup4Nodes].elements as any[];
                const group4Elems = vObjs[indexGroup4Elems].elements as any[];

                const type0 = getLastURL(vObjs[indexGroup4Nodes].eClass);
                if (type0 !== "FEMGroup") {
                    debugger
                }
                const type1 = getLastURL(vObjs[indexGroup4Elems].eClass);
                if (type1 !== "FEMGroup") {
                    debugger
                }

                // Ojo, que son vectores quaternarios!!!.
                const vIndices4GroupNodes = this.accumulateIndices4Groups(group4Nodes);
                const vIndices4GroupElems = this.accumulateIndices4Groups(group4Elems);

                // En los nodos solo deberia rellenarse el primer vector y los otros 3 vacios, y en el de elementos lo contrario.
                if (vIndices4GroupNodes[0].length && !vIndices4GroupNodes[1].length && !vIndices4GroupNodes[2].length && !vIndices4GroupNodes[3].length) {
                    ; // Ok.
                } else {
                    debugger;
                }
                if (!vIndices4GroupElems[0].length && (vIndices4GroupNodes[1].length || vIndices4GroupNodes[2].length || vIndices4GroupNodes[3].length)) {
                    debugger;
                }

                const info = this.mStoreys_.get(index4Storey) as StoreyInfo;
                // Y ahora rellenamos la informacion recopilada para el piso/slab, primero con los indices de nodos...
                if (vIndices4GroupNodes[0].length) {
                    console.log(`\t\t Adding ${vIndices4GroupNodes[0].length} indices for nodes.`);
                    info.mIndices4Nodes.set(index, vIndices4GroupNodes[0]);
                }
                // ...despues respectivamente con beams, tris y quads en las posiciones [1], [2] y [3] de los elementos.
                if (vIndices4GroupElems[1].length) {
                    console.log(`\t\t Adding ${vIndices4GroupElems[1].length} indices for beams.`);
                    info.mIndices4Beams.set(index, vIndices4GroupElems[1]);
                }
                if (vIndices4GroupElems[2].length) {
                    console.log(`\t\t Adding ${vIndices4GroupElems[2].length} indices for tris.`);
                    info.mIndices4Tris.set(index, vIndices4GroupElems[2]);
                }
                if (vIndices4GroupElems[3].length) {
                    console.log(`\t\t Adding ${vIndices4GroupElems[3].length} indices for quads.`);
                    info.mIndices4Quads.set(index, vIndices4GroupElems[3]);
                }

                ++index;
            }
        }
    }

    /**
     * Dada una serie de grupos como un vector de objetos JSON'es, acumulamos los indices de los items contenidos en los
     * mismos, que O BIEN pueden ser indices posicionales de elementos NODO o bien pueden ser de elementos SHELL.
     * Para distinguir todos los posibles casos posibles devolvemos un vector CUARTETO de vectores numericos, conteniendo
     * los indices EN ESTE ESTRICTO ORDEN de: [[NODES], [BEAMS], [TRIS], [QUADS]].
     * @param vObjs 
     */
    private accumulateIndices4Groups(vObjs: any): [number[], number[], number[], number[]] {
        const vI4Nodes: number[] = [];
        const vI4Beams: number[] = [];
        const vI4Tris: number[] = [];
        const vI4Quads: number[] = [];
        const v = [vI4Nodes, vI4Beams, vI4Tris, vI4Quads] as [number[], number[], number[], number[]];

        const getNumber = (url: string): number => {
            url = url.substring(url.lastIndexOf('.') + 1);
            return +url;
        };

        const selectDestinationVector = (url: string): number[] | null => {
            const begin = url.lastIndexOf('@') + 1;
            const end = url.lastIndexOf('.');
            const what = url.substring(begin, end);
            switch(what) {
                case "nodes":
                    return vI4Nodes;
                case "beams":
                    return vI4Beams;
                case "triangles":
                    return vI4Tris;
                case "quads":
                    return vI4Quads;
                default:
                    debugger;                    
            } 
            return null;
        };

        for (const group of vObjs) {
            const ref = group.$ref;
            const num = getNumber(ref);
            const vDst = selectDestinationVector(ref);
            if (!vDst) {
                debugger;
            } else {
                if (-1 !== vDst.indexOf(num)) {
                    debugger;
                }
                vDst.push(num);    
            }
        }

        return v;
    }

    /**
     * A partir de los indices de los nodos previamente calculados y de la separacion por pisos, se calcula para cada
     * planta su CENTRO DE GRAVEDAD (el rollo geometrico, que no se si seria el mas adecuado).
     * Ademas se llenan las alturas Z de los pisos, con una comprobacion de desviaciones standard de altura.
     */
    private calculateCentersOfGravity4Storeys(): void {

        // Para rellenar la altura real de cada planta recurriendo a la Z vamos a calcular la desviacion standard de la
        // misma por la formula de Bessel: https://en.wikipedia.org/wiki/Algorithms_for_calculating_variance

        if (!this.mStoreys_.size) {
            window.alert("WARNING: THERE ARE 0 STOREYS!!!.");
            return;
        }

        // Vector auxiliar.
        const v = new THREE.Vector3();

        for (const [indexSt, infoSt] of this.mStoreys_) {

            let sum = 0;
            let sumSq = 0;
            let sumN = 0;
        
            console.log(`Storey[${indexSt}]:`);
            // Recorremos los indices de los nodos para sacar los puntos 3D, pero recordemos que puede haber varios slabs
            // para esta misma planta.
            for (const [slabIndex, vIndices4Nodes] of infoSt.mIndices4Nodes) {
                const vCOG = new THREE.Vector3();
                const N = vIndices4Nodes.length;
                sumN += N;
                for (const index of vIndices4Nodes) {
                    // Comprobamos que el indice del nodo es correcto.
                    if (0 <= index && index < this.numNodes_) {
                        v.x = this.nodes_[3 * index];
                        v.y = this.nodes_[3 * index + 1];
                        v.z = this.nodes_[3 * index + 2];
                        vCOG.add(v);
                        sum += v.z;
                        sumSq += v.z * v.z;
                    } else {
                        window.alert("ERROR on index node!!!");
                        debugger;
                    }
                }
                vCOG.divideScalar(N);
                console.log(`\t\t Slab[${slabIndex}] ===> ${N} nodes ===> COG(${vCOG.x}, ${vCOG.y}, ${vCOG.z})`);
                infoSt.vCOG.push(vCOG);
            }

            const varZ = (sumSq - (sum * sum / sumN)) / sumN;
            const stdDevZ = Math.sqrt(varZ);
            console.log(`\tStdDev(Z) = ${stdDevZ}`);
            // Ponemos una tolerancia de 1 mm para ajustar la altura del piso.
            if (stdDevZ < 0.001) {
                infoSt.z = sum / sumN;
                console.log(`Z(storey) = ${infoSt.z}`);
            } else {
                // No se deberia llegar aqui nunca, luego ya se quitara...
                debugger;
            }
        }

        if (this.owner_) {
            this.calculateHoledCOGs4();
        }
    }

    /**
     * Calcula los COG pero teniendo en cuenta los agujeros presentes.
     */
    private calculateHoledCOGs4(): void {
        // Para ello recurrimos a este viejo amigo que usa un metodo mas exacto.
        const windCalc = new WindCalculer(this.owner_ as GraphicProcessor);
        // Sacamos los datos que son mas exactos que los anteriormente calculados.
        const mInfo = windCalc.mSlab2Info_;

        // Pasamos los datos de los COG del windCalculer a nuestro lado.
        for (const [key, value] of mInfo) {
            const newCOG = value.COG3D;
            // Requiero el indice del piso y del slab implicados.
            const ind4Storey = value.index4Storey;
            const name4Slab = value.slabName;
            // Y con esos datos recorro mi propia lista.
            if (this.mStoreys_.has(ind4Storey)) {
                const myInfo = this.mStoreys_.get(ind4Storey) as StoreyInfo;
                // Ahora busco el slab del nombre dado, concretamente su posicion.
                let ind4Slab = -1;
                for (const [ind, name] of myInfo.mIndices4SlabsNames) {
                    if (name === name4Slab) {
                        ind4Slab = ind;
                        break;
                    }
                }
                if (ind4Slab !== -1) {
                    // Sustitucion del COG.
                    const oldCog = myInfo.vCOG[ind4Slab];
                    const msg = `Change storey[${ind4Storey}]-slab[${ind4Slab}:"${name4Slab}"] `
                        + `old COG(${oldCog.x}, ${oldCog.y}, ${oldCog.z}) for new COG(${newCOG[0]}, ${newCOG[1]}, ${newCOG[2]})`;
                    console.log(msg);
                    oldCog.x = newCOG[0];
                    oldCog.y = newCOG[1];
                    oldCog.z = newCOG[2];
                } else {
                    console.error(`ERROR: We don't have slab "${name4Slab}"!!!.`);
                    debugger;    
                }
            } else {
                console.error(`ERROR: We don't have storey[${ind4Storey}]!!!.`);
                debugger;
            }
        }
    }

    /**
     * Efectua el calculo del shell mas cercano al COG para cada slab.
     */
    private calculateShellsIds4StoreysCOGs(): void {
        // Tenemos N storeys, cada uno con 1 o mas slabs; para cada slab tenemos calculado un COG.
        // Ademas sabemos los shells implicados en cada slab, asi que podemos recorrerlos buscando el mas cercano al COG.
        for (const [indexSt, infoSt] of this.mStoreys_) {

            console.log(`Storey[${indexSt}]`);
            for (let i = 0; i < infoSt.cntSlabs; ++i) {
                const COG = infoSt.vCOG[i];
                const height = infoSt.z;
                let minDist = +Infinity;
                // Esta sera la posicion (que no indice) del shell mas cercano, asi como su aridad 3|4.
                let [nearestPos, arity] = [-1, 0] as [number, number];

                // Tenemos que buscar entre los quads y los tris, pero OJO, que puede no haber unos EXOR otros!!!.
                if (infoSt.mIndices4Tris.has(i)) {
                    const vIndices4Tris = infoSt.mIndices4Tris.get(i) as number[];
                    for (let jTri of vIndices4Tris) {

                        const v3Nodes = this.getTriangleN(jTri);
                        if (v3Nodes.length !== 3) {
                            debugger;
                            continue;
                        }
                        const A = this.getNode(v3Nodes[0]);
                        const B = this.getNode(v3Nodes[1]);
                        const C = this.getNode(v3Nodes[2]);
                        // Comprobacion psicopatica que desaparecera: Todas las alturas coinciden con la del storey.
                        if (A[2] !== height || B[2] !== height || C[2] !== height) {
                            debugger;
                        }
                        const vA = new THREE.Vector3(...A);
                        const vB = new THREE.Vector3(...B);
                        const vC = new THREE.Vector3(...C);
                        // Calculo las distancias de los vertices al COG y me quedo con la media.
                        const dist2A = COG.distanceTo(vA);
                        const dist2B = COG.distanceTo(vB);
                        const dist2C = COG.distanceTo(vC);
                        const dist = (dist2A + dist2B + dist2C) / 3;
                        if (dist < minDist) {
                            minDist = dist;
                            [nearestPos, arity] = [jTri, 3];
                        }
                    }
                }

                if (infoSt.mIndices4Quads.has(i)) {
                    const vIndices4Quads = infoSt.mIndices4Quads.get(i) as number[];
                    for (let jQuad of vIndices4Quads) {
                        const v4Nodes = this.getQuadN(jQuad);
                        if (v4Nodes.length !== 4) {
                            debugger;
                            continue;
                        }
                        const A = this.getNode(v4Nodes[0]);
                        const B = this.getNode(v4Nodes[1]);
                        const C = this.getNode(v4Nodes[2]);
                        const D = this.getNode(v4Nodes[3]);
                        // Comprobacion psicopatica que desaparecera: Todas las alturas coinciden con la del storey.
                        if (A[2] !== height || B[2] !== height || C[2] !== height || D[2] !== height) {
                            debugger;
                        }
                        const vA = new THREE.Vector3(...A);
                        const vB = new THREE.Vector3(...B);
                        const vC = new THREE.Vector3(...C);
                        const vD = new THREE.Vector3(...D);
                        const dist2A = COG.distanceTo(vA);
                        const dist2B = COG.distanceTo(vB);
                        const dist2C = COG.distanceTo(vC);
                        const dist2D = COG.distanceTo(vD);
                        const dist = (dist2A + dist2B + dist2C + dist2D) / 4;
                        if (dist < minDist) {
                            minDist = dist;
                            [nearestPos, arity] = [jQuad, 4];
                        }
                    }
                }

                if (arity === 3 || arity === 4) {
                    console.log(`\t Slab[${i}]: Nearest (${minDist}) shell to COG is ${arity === 3 ? "Triangle" : "Quad"} ${nearestPos}`);
                } else {
                    console.error(" ERROR: Not found???...");
                }
                infoSt.vShellPos4COG.push([nearestPos, arity]);
            }
        }
    }

    /**
     * Funcion que tiene que ser llamada por JaWS desde el exterior suministrando un vector de cadenas con los ficheros
     * de datos que me tengo que comer para los calculos.
     * Devuelvo al exterior un array con tantas entradas como storeys y cada una de esas entradas sera un vector con tantas
     * entradas como slabs tenga el storey. Finalmente en cada entrada tendremos la secuencia de datos de desplome para
     * el par unico storey-slab:
     * [0] NameStorey
     * [1] NameSlab
     * [2] Sx
     * [3] Sy
     * [4] Sxy
     * [5] Sx / level
     * [6] Sy / level
     * [7] Sxy / level
     * [8] Sx / height
     * [9] Sy / height
     * [10] Sxy / height
     * En caso de error se devuelve un array vacio.
     * Ademas para las pruebas si pasamos un array vData vacio y un f positivo sacamos los datos de las deformaciones
     * multiplicados por el factor dado.
     * 
     * @param vData 
     * @param f 
     * @returns 
     */
    public calculateStoreyDriftTable(vData: string[], f: number = -1): any[][][] {
        // La [] mas a la derecha indica planta.
        // La anterior indica forjados dentro de la planta.
        // La primera se refiere a los 9 parametros devueltos.
        const vTable: number[][][] = [];

        // Me ha pasado que las N > 1 combinaciones que llegan son exactamente iguales.
        const N = vData.length;
        if (N > 1) {
            for (let i = 0; i < N; ++i) {
                const a = vData[i];
                for (let j = i + 1; j < N; ++j) {
                    const b = vData[j];
                    if (a == b) {
                        const msg = `WARNING: Las cadenas de las combinaciones [${i}] (${a.length}) y [${j}] (${b.length}) son EXACTAMENTE IGUALES!!!.`
                        console.error(msg);
                        window.alert(msg);
                        debugger;
                    }
                }
            }
        }

        if (f === -1) {            
            for (let iC = 0; iC < N; ++iC) {
                // Para ir rellenando la tabla con los mismos valores.
                const vSt: any[][] = [];

                // Calculos reales. Para ello ahora el factor f valdra 1.0 para no "teñir" los calculos.
                f = 1.0;
                // Sacamos los datos de deformaciones haciendo una copia local.
                const deformations: number[] = [];
                let numDeformations = 0;

                const getData = (DX: number, DY: number, DZ: number, DRX: number, DRY: number, DR: number): void => {
                    deformations.push(DX, DY, DZ, DRX, DRY, DR);
                    ++numDeformations;
                };

                const dataI = vData[iC];
                const gPrsr = new GenParser();

                const result = gPrsr.readData4String(dataI);
                if (!result) {
                    window.alert("ERROR al parsear el chorizo de deformaciones (II)!!!.");
                    debugger;
                    continue;
                }
                console.log(`Data from combination [${iC}/${N}] ===> ${gPrsr.titles_}`);
                console.log(`\t Tuples: ${gPrsr.numTuples_} * ${gPrsr.dimTuple_}`);
                if (gPrsr.numTuples_ !== this.numNodes_) {
                    window.alert(`ERROR: El numero de tuplas [${gPrsr.numTuples_}] difiere del numero de nodos [${this.numNodes_}] en el meshModel.`);
                    debugger;
                    continue;
                }
                // Tenemos aqui las deformaciones leidas y las aplicamos a los nodos existentes.
                numDeformations = 0;
                deformations.length = 0;

                // Sacamos todos los datos en el orden original. \ToDo: Quizas sea mas rapido algo directo...
                let res = gPrsr.applyFunctor2Columns(getData, gPrsr.titles_);
                if (res) {
                    this.setDeformations(deformations as number[]);
                } else {
                    window.alert("ERROR al recoger los datos de deformaciones.");
                    continue;
                }
                // Y llegados aqui hacemos los calculos reales.
                for (const [iSt, infoSt] of this.mStoreys_) {
                    console.log(`STOREY[${iSt}]:"${infoSt.name}" ===> Height = ${infoSt.z}   Elevation = ${infoSt.elevation}   Slabs = ${infoSt.cntSlabs}`);
                    for (let i = 0; i < infoSt.cntSlabs; ++i) {
                        const pCOG = infoSt.vCOG[i];
                        // Ojo que posShell es la posicion, no el indice, ya sea de un triangulo (arity 3) o un quad (arity 4).
                        const [posShell, arity] = infoSt.vShellPos4COG[i];
                        console.log(`\t SLAB[${i}]:   COG(${pCOG.x}, ${pCOG.y}, ${pCOG.z}) ===> Shell[${posShell}:${arity}]`);
                        if (arity !== 3 && arity !== 4) {
                            // Nunca.
                            window.alert(`ERROR: Arity[${arity}] not admited!!!."`);
                            continue;
                        }

                        const [distX, distY, distXY] = this.calculateDrifDistances4Shell(posShell, arity, f);

                        if (true) {
                            console.log(`Datos A:   ${distX}   ${distY}   ${distXY}`);
                            // Calculo alternativo a ver como va...
                            const vNodes4Slab = infoSt.mIndices4Nodes.get(i) as number[];
                            const [distX2, distY2, distXY2] = this.calculateDriftDistances4SlabNodes(pCOG, vNodes4Slab, f);
                            console.log(`Datos B:   ${distX2}   ${distY2}   ${distXY2}`);
                            console.log(`AbsDiffs:  ${Math.abs(distX2 - distX)}   ${Math.abs(distY2 - distY)}   ${Math.abs(distXY2 - distXY)}`);
                        }
                        const nameStorey = infoSt.name;
                        const nameSlab = infoSt.mIndices4SlabsNames.get(i) as string;
                        const Sx = distX;
                        const Sy = distY;
                        const Sxy = distXY;
                        const SxByLevel = distX / infoSt.elevation;
                        const SyByLevel = distY / infoSt.elevation;
                        const SxyByLevel = distXY / infoSt.elevation;
                        const SxByHeight = distX / infoSt.z;
                        const SyByHeight = distY / infoSt.z;
                        const SxyByHeight = distXY / infoSt.z;
                        vSt.push([nameStorey, nameSlab, Sx, Sy, Sxy, SxByLevel, SyByLevel, SxyByLevel, SxByHeight, SyByHeight, SxyByHeight]);
                        console.log(`[${iC}] ${nameStorey} ${nameSlab} ${Sx} ${Sy} ${Sxy} ${SxByLevel} ${SyByLevel} ${SxyByLevel} ${SxByHeight} ${SyByHeight} ${SxyByHeight}`);
                    }
                }
                
                vTable.push(vSt);
            }
        } else {
            // Calculos fake en base a los datos de deformaciones en curso y el factor f.
            for (const [iSt, infoSt] of this.mStoreys_) {
                const vSt: any[][] = [];
                console.log(`STOREY[${iSt}]:"${infoSt.name}" ===> Height = ${infoSt.z}   Elevation = ${infoSt.elevation}   Slabs = ${infoSt.cntSlabs}`);
                for (let i = 0; i < infoSt.cntSlabs; ++i) {
                    const pCOG = infoSt.vCOG[i];
                    // Ojo que posShell es la posicion, no el indice, ya sea de un triangulo (arity 3) o un quad (arity 4).
                    const [posShell, arity] = infoSt.vShellPos4COG[i];
                    console.log(`\t SLAB[${i}]:   COG(${pCOG.x}, ${pCOG.y}, ${pCOG.z}) ===> Shell[${posShell}:${arity}]`);
                    if (arity !== 3 && arity !== 4) {
                        // Nunca.
                        window.alert(`ERROR: Arity[${arity}] not admited!!!."`);
                        continue;
                    }
                    
                    const [distX, distY, distXY] = this.calculateDrifDistances4Shell(posShell, arity, f);

                    if (true) {
                        console.log(`Datos A:   ${distX}   ${distY}   ${distXY}`);
                        // Calculo alternativo a ver como va...
                        const vNodes4Slab = infoSt.mIndices4Nodes.get(i) as number[];
                        const [distX2, distY2, distXY2] = this.calculateDriftDistances4SlabNodes(pCOG, vNodes4Slab, f);
                        console.log(`Datos B:   ${distX2}   ${distY2}   ${distXY2}`);
                        console.log(`AbsDiffs:  ${Math.abs(distX2 - distX)}   ${Math.abs(distY2 - distY)}   ${Math.abs(distXY2 - distXY)}`);
                    }
                    const nameStorey = infoSt.name;
                    const nameSlab = infoSt.mIndices4SlabsNames.get(i) as string;                    
                    const Sx = distX;
                    const Sy = distY;
                    const Sxy = distXY;
                    const SxByLevel = distX / infoSt.elevation;
                    const SyByLevel = distY / infoSt.elevation;
                    const SxyByLevel = distXY / infoSt.elevation;
                    const SxByHeight = distX / infoSt.z;
                    const SyByHeight = distY / infoSt.z;
                    const SxyByHeight = distXY / infoSt.z;
                    vSt.push([nameStorey, nameSlab, Sx, Sy, Sxy, SxByLevel, SyByLevel, SxyByLevel, SxByHeight, SyByHeight, SxyByHeight]);
                }
                vTable.push(vSt);
            }
        }
        return vTable;
    }

    /**
     * Metodo alternativo para calcular las distancias de drift pero usando TODOS los nodos contenidos en un slab.
     * @param vNodes 
     */
    private calculateDriftDistances4SlabNodes(cog: THREE.Vector3, vNodes: number[], f: number = 1.0): [number, number, number] {
        const N = vNodes.length;
        let dx = 0;
        let dy = 0;
        let dxy = 0;
        const vAvg2D = new THREE.Vector2();

        const applyDeformations = (q: THREE.Vector3, posDeform: number) => {
            q.x += f * this.vDeformations_[6 * posDeform];
            q.y += f * this.vDeformations_[6 * posDeform + 1];
            q.z += f * this.vDeformations_[6 * posDeform + 2];
        };

        for (let i = 0; i < N; ++i) {
            const node = this.getNode(vNodes[i]);
            const p = new THREE.Vector3(...node);
            applyDeformations(p, vNodes[i]);
            vAvg2D.add(new THREE.Vector2(p.x, p.y));
            dx += p.x;
            dy += p.y;            
        }
        dx /= N;
        dy /= N;
        vAvg2D.divideScalar(N);
        // Comparamos con el dato de entrada y asi tenemos los ansiados incrementos.
        dx = Math.abs(cog.x - dx);
        dy = Math.abs(cog.y - dy);
        dxy = new THREE.Vector2(cog.x, cog.y).distanceTo(vAvg2D);
        return [dx, dy, dxy];
    }

    /**
     * Calcula las distancias de drift Dx, Dy y Dxy para el shell dado, como fruto de aplicarle a sus nodos las correspondientes
     * deformaciones actualmente cargadas factorizadas por f.
     * @param posShell 
     * @param arity 
     * @param f 
     * @returns 
     */
    private calculateDrifDistances4Shell(posShell: number, arity: 3 | 4, f: number = 1.0): [number, number, number] {

        const applyDeformations = (q: THREE.Vector3, posDeform: number) => {
            q.x += f * this.vDeformations_[6 * posDeform];
            q.y += f * this.vDeformations_[6 * posDeform + 1];
            q.z += f * this.vDeformations_[6 * posDeform + 2];
        };

        let pMid, pMid2: THREE.Vector3;

        if (arity === 4) {
            const v4Nodes = this.getQuadN(posShell) as [number, number, number, number];
            const A = this.getNode(v4Nodes[0]);
            const B = this.getNode(v4Nodes[1]);
            const C = this.getNode(v4Nodes[2]);
            const D = this.getNode(v4Nodes[3]);
            const vA = new THREE.Vector3(...A);
            const vB = new THREE.Vector3(...B);
            const vC = new THREE.Vector3(...C);
            const vD = new THREE.Vector3(...D);
            // Calculamos el baricentro de ese quad mas cercano que es el que se uso para la distancia minima...
            pMid = new THREE.Vector3().add(vA).add(vB).add(vC).add(vD).divideScalar(4);
            // A los 4 puntos implicados los movemos lo indicado por la deformacion (mas el factor).
            applyDeformations(vA, v4Nodes[0]);
            applyDeformations(vB, v4Nodes[1]);
            applyDeformations(vC, v4Nodes[2]);
            applyDeformations(vD, v4Nodes[3]);
            // Este es el baricentro deformado.
            pMid2 = new THREE.Vector3().add(vA).add(vB).add(vC).add(vD).divideScalar(4);
        } else {
            const v4Nodes = this.getQuadN(posShell) as [number, number, number, number];
            const A = this.getNode(v4Nodes[0]);
            const B = this.getNode(v4Nodes[1]);
            const C = this.getNode(v4Nodes[2]);
            const vA = new THREE.Vector3(...A);
            const vB = new THREE.Vector3(...B);
            const vC = new THREE.Vector3(...C);
            // Calculamos el baricentro de ese tri mas cercano que es el que se uso para la distancia minima...
            pMid = new THREE.Vector3().add(vA).add(vB).add(vC).divideScalar(3);
            // A los 3 puntos implicados los movemos lo indicado por la deformacion (mas el factor).
            applyDeformations(vA, v4Nodes[0]);
            applyDeformations(vB, v4Nodes[1]);
            applyDeformations(vC, v4Nodes[2]);
            // Este es el baricentro deformado.
            pMid2 = new THREE.Vector3().add(vA).add(vB).add(vC).divideScalar(4);
        }
            
        // He aqui los incrementos de las distancias.
        const dX = Math.abs(pMid2.x - pMid.x);
        const dY = Math.abs(pMid2.y - pMid.y);            
        const dXY = new THREE.Vector2(pMid.x, pMid.y).distanceTo(new THREE.Vector2(pMid2.x, pMid2.y));
        return [dX, dY, dXY];
    }

    /**
     * Funcion para la demo que lo que hara sera simplemente usar los datos de deformaciones en curso para calcular e
     * imprimir la tabla de desplomes por planta.
     * @param f 
     */
    private calculateFakeStoreyDriftTable(f: number): void {
        const table = this.calculateStoreyDriftTable([], f);
        if (table.length === 0) {
            window.alert("ERROR: Algo ha ido mal...");
            return;
        }

        console.log(`Storey Drift table for f = ${f}:`)
        console.log("================================================================================================");
        const N = table.length;
        for (let i = 0; i < N; ++i) {
            const storeyData = table[i];
            console.log(`Storey[${i}/${N}]___________________________________________________________________________`);
            const S = storeyData.length;
            for (let j = 0; j < S; ++j) {
                const slabData = storeyData[j];
                console.log(`\t Slab[${j}/${S}]-----------------`);
                const [NameStorey, NameSlab, Sx, Sy, Sxy, SxByLevel, SyByLevel, SxyByLevel, SxByHeight, SyByHeight, SxyByHeight] = slabData;                
                console.log(`\t\t NameStorey "${NameStorey}"`);
                console.log(`\t\t NameSlab   "${NameSlab}"`);
                console.log(`\t\t Sx = ${Sx}`);
                console.log(`\t\t Sy = ${Sy}`);
                console.log(`\t\t Sxy = ${Sxy}`);
                console.log(`\t\t SxByLevel = ${SxByLevel}`);
                console.log(`\t\t SyByLevel = ${SyByLevel}`);
                console.log(`\t\t SxyByLevel = ${SxyByLevel}`);
                console.log(`\t\t SxByHeight = ${SxByHeight}`);
                console.log(`\t\t SyByHeight = ${SyByHeight}`);
                console.log(`\t\t SxyByHeight = ${SxyByHeight}`);
            }
        }
    }



} // class StructModel3D

class Arrow3 {

    gObj_: THREE.LineSegments;

    constructor(arrowLength = 1.0, headLength = 0.2 * arrowLength, headWidth = 0.2 * headLength, clr = 0xffff00) {
        // Evitar daños.
        if (arrowLength < 0.001) {
            arrowLength = 1.0;
            headLength = 0.2 * arrowLength;
            headWidth = 0.2 * headLength;
        } else {
            if (headLength < 0.001) {
                headLength = 0.2 * arrowLength;
                headWidth = 0.2 * headLength;    
            } else {
                if (headWidth < 0.001) {
                    headWidth = 0.2 * headLength;    
                }
            }
        }

        // Nuestro objeto grafico es una flor/gancho/garfio de 6 puntos, que empieza siempre en el peciolo P0(0, 0, 0).
        // Vista de alzado XZ:                              Vista de planta XY:
        //
        //              P1 (0, 0, AL)                                 P2 (0, HW, AL-HL)
        //             /|\                                            |
        //            / | \                                           |
        //          P3  |  P5  z = AL - HL     (-HW, 0, AL-HL) P3---P1/P0---P5 (HW, 0, AL-HL)
        //              |                                             |
        //              |                                             |
        //              P0 (0, 0, 0)                                  P4 (0, -HW, AL-HL)

        const z = arrowLength - headLength;

        const geom = new THREE.BufferGeometry();

        if (false) {
            // Los 6 puntos, del P0 al P5.
            geom.setAttribute(
                'position',
                new THREE.Float32BufferAttribute([
                    0, 0, 0,
                    0, 0, arrowLength,
                    0, headWidth, z,
                    -headWidth, 0, z,
                    0, -headWidth, z,
                    headWidth, 0, z
                ], 3)
            );
            // Los indices de las 5 lineas.
            geom.setIndex([
                0, 1,
                1, 2,
                1, 3,
                1, 4,
                1, 5
            ]);
        } else {
            // Con solo 5 puntos, del P0 al P4, en forma de garfio triple.
            let alpha = 0 * Math.PI / 180;
            const x2 = headWidth * Math.cos(alpha);
            const y2 = headWidth * Math.sin(alpha);

            alpha = 120 * Math.PI / 180;
            const x3 = headWidth * Math.cos(alpha);
            const y3 = headWidth * Math.sin(alpha);

            alpha = 240 * Math.PI / 180;
            const x4 = headWidth * Math.cos(alpha);
            const y4 = headWidth * Math.sin(alpha);

            geom.setAttribute(
                'position',
                new THREE.Float32BufferAttribute([
                    0, 0, 0,
                    0, 0, arrowLength,
                    x2, y2, z,
                    x3, y3, z,
                    x4, y4, z
                ], 3)
            );
            // Los indices de las 4 lineas.
            geom.setIndex([
                0, 1,
                1, 2,
                1, 3,
                1, 4
            ]);
        }

        geom.computeBoundingSphere();

        const mat = new THREE.LineBasicMaterial({ color: clr, toneMapped: false });
        this.gObj_ = new THREE.LineSegments(geom, mat);
    }

    static createArrowsField(
        vPositions: ArrayLike<number>,
        vOrientations: ArrayLike<number>,
        vColors: ArrayLike<number> = []
    ): THREE.LineSegments {

        // [1] Creamos la flecha que replicaremos ad nauseam.
        const arrowLength = 1.0;
        const headLength = 0.20;
        const headWidth = 0.05;
        const color = (new THREE.Color('green')).getHex();
        
        const arrow = new Arrow3(arrowLength, headLength, headWidth, color);
        const arrowGO = arrow.gObj_ as THREE.LineSegments<THREE.BufferGeometry>;
        const geomSRC = arrowGO.geometry;

        return createMultiInstancedGraphicObject(THREE.LineSegments, geomSRC, vPositions, vOrientations, vColors);
    }
}

/**
 * Constructor VIRTUAL a partir de una geometria fuente a la que multi-instanciamos.
 * @param constructorClass 
 * @param geomSRC 
 * @param vPositions 
 * @param vOrientations 
 * @param vColors 
 * @returns 
 */
export function createMultiInstancedGraphicObject<TypeOut>(
    constructorClass: new (geo: any, mat: any) => TypeOut,
    geomSRC: THREE.BufferGeometry,
    vPositions: ArrayLike<number>,
    vOrientations: ArrayLike<number> = [],
    vColors: ArrayLike<number> = []
): TypeOut {
    // [1] Creamos la geometria [M]ulti-[I]nstanciada que tendra todas las instancias de la geometria fuente...
    const geomMIB = new THREE.InstancedBufferGeometry();
    
    // ... y no solo copiamos los puntos de la geometria origen, sino tambien los indices, de haberlos...
    geomMIB.attributes.position = geomSRC.attributes.position;
    if (geomSRC.index) {
        geomMIB.index = geomSRC.index;        
    }

    // [2] Asignamos los parametros, que SUPONEMOS CORRECTAMENTE DADOS en cuanto a cardinales.
    const count = vPositions.length / 3;
    geomMIB.instanceCount = count;
    const offsetAttribute = new THREE.InstancedBufferAttribute(new Float32Array(vPositions), 3);
    const orientationAttribute = new THREE.InstancedBufferAttribute(new Float32Array(vOrientations), 4);

    geomMIB.setAttribute('offset', offsetAttribute);
    geomMIB.setAttribute('orientation', orientationAttribute);

    const useColors = (vColors.length > 3);
    if (useColors) {
        // Si la longitud es para un solo color, meterlo a huevo en el shader...
        const extColorsAttribute = new THREE.InstancedBufferAttribute(new Float32Array(vColors), 3);
        geomMIB.setAttribute('extColor', extColorsAttribute);
    }

    // [3] Alla va el propio shader.
    const useOrientation = (vOrientations.length > 0);
    // Incluso permito el uso de un solo color optimizado.
    const onlyColor = vColors.length === 3 ? new THREE.Color(vColors[0], vColors[1], vColors[2]) : undefined;
    const materialShader: THREE.RawShaderMaterial = createShaderMaterial(useColors, useOrientation, onlyColor);

    // Aqui esta el objeto final que soporta la geometria multi-instanciada por medio del shader.
    // Ojo al constructor virtual, con una idea refinada a partir de:
    // https://www.digitalocean.com/community/tutorials/how-to-use-classes-in-typescript
    // Mas los genericos de:
    // https://www.typescriptlang.org/docs/handbook/2/generics.html
    const objMIB = new constructorClass(geomMIB, materialShader);

    // SOLUCION AL PROBLEMA DE QUE EN DETERMINADAS POSICIONES DE CAMARA EL GRAFICO DEL SHADER DESAPARECE:
    // https://stackoverflow.com/questions/21184061/mesh-suddenly-disappears-in-three-js-clipping
    (objMIB as unknown as THREE.Object3D).frustumCulled = false;
    return objMIB;
}

function createShaderMaterial(useColors: boolean,
                              useOrientation: boolean,
                              onlyColor: THREE.Color = new THREE.Color(1, 0, 0)
                            ): THREE.RawShaderMaterial {
    let matShdr: THREE.RawShaderMaterial;

    // 4 Combinaciones de mas simple a mas complejo:
    // [1] Sin color ni orientacion. Se usa el color dado...
    // [2] Con solo color.
    // [3] Con solo orientacion.
    // [4] Color + orientacion.

    if (!useColors && !useOrientation) {
        // [1] Sin color ni orientacion. En tal caso va todo en rojo por defecto o con el unico color dado.
        matShdr = new THREE.RawShaderMaterial({
            uniforms: { },
            vertexShader: `
                precision highp float;
                uniform mat4 modelViewMatrix;
                uniform mat4 projectionMatrix;
        
                attribute vec3 position;
                attribute vec3 offset;
        
                void main(){
                    vec3 pos = offset + position;
                    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
                }`,
            fragmentShader: `
                precision highp float;
      
                void main() {
                    gl_FragColor = vec4(${onlyColor.r}, ${onlyColor.g}, ${onlyColor.b}, 1.0);
                }`,
            side: THREE.DoubleSide,
        });
    } else {        
        if (useColors && !useOrientation) {
            // [2] Con solo color.
            matShdr = new THREE.RawShaderMaterial({
                uniforms: { },
                vertexShader: `
                    precision highp float;
                    uniform mat4 modelViewMatrix;
                    uniform mat4 projectionMatrix;
            
                    attribute vec3 position;
                    attribute vec3 offset;
                    attribute vec3 extColor;
                    varying vec3 extColor2;
            
                    void main(){
                        extColor2 = extColor;
                        vec3 pos = offset + position;
                        gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
                    }`,
                fragmentShader: `
                    precision highp float;
                    varying vec3 extColor2;
        
                    void main() {
                        gl_FragColor = vec4(extColor2.xyz, 1.0);
                    }`,
                side: THREE.DoubleSide,
            });
        } else {            
            if (!useColors && useOrientation) {
                // [3] Con solo orientacion.
                matShdr = new THREE.RawShaderMaterial({
                    uniforms: { },
                    vertexShader: `
                        precision highp float;
                        uniform mat4 modelViewMatrix;
                        uniform mat4 projectionMatrix;
                
                        attribute vec3 position;
                        attribute vec3 offset;
                        attribute vec4 orientation;
                
                        void main(){
                            vec3 pos = offset + position;
                            vec3 vcV = cross(orientation.xyz, pos);
                            pos = vcV * (2.0 * orientation.w) + (cross(orientation.xyz, vcV) * 2.0 + pos);
                            gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
                        }`,
                    fragmentShader: `
                        precision highp float;
            
                        void main() {
                            gl_FragColor = vec4(1.0, 0.0, 0.0, 1.0);
                        }`,
                    side: THREE.DoubleSide,
                });
            } else {
                // [4] Color mas orientacion.
                matShdr = new THREE.RawShaderMaterial({
                    uniforms: { },
                    vertexShader: `
                        precision highp float;
                        uniform mat4 modelViewMatrix;
                        uniform mat4 projectionMatrix;
                
                        attribute vec3 position;
                        attribute vec3 offset;
                        attribute vec4 orientation;
                        attribute vec3 extColor;
                        varying vec3 extColor2;
                
                        void main(){
                            extColor2 = extColor;
                            vec3 pos = offset + position;
                            vec3 vcV = cross(orientation.xyz, pos);
                            pos = vcV * (2.0 * orientation.w) + (cross(orientation.xyz, vcV) * 2.0 + pos);
                            gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
                        }`,
                    fragmentShader: `
                        precision highp float;
                        varying vec3 extColor2;
            
                        void main() {
                            gl_FragColor = vec4(extColor2.xyz, 1.0);
                        }`,
                    side: THREE.DoubleSide,
                });
            }
        }
    }
    return matShdr;
}

/**
 * Necesito leer ficheros de texto y cargar su informacion y saber cuando la tengo completamente leida.
 */
class TextBuffer {
    status: number = -1;
    buffer: string = "";

    constructor() {
        this.reset();
    }

    load(newText: string) {
        this.buffer = newText;
        this.status = +1;
    }

    reset() {
        // Con esto indicamos que el buffer aun no ha sido puesto a cargar.
        this.status = -1;
        this.buffer = "";
    }

    // Aqui indicamos un estado de error.
    setError() {
        this.status = 0;
    }

    // Avisamos de que se ha comenzado una lectura, que podra o no tener exito.
    setLoadStarted(): boolean {
        // Solo se autoriza la transaccion desde el estado inicial para evitar recovecos...
        if (this.status === -1) {
            this.status = -2;
            console.log("Starting reading operation...");
            return true;
        }
        return false;
    }

    // Mecanismo de espera bloqueante. El true indica correcta lectura.
    wait2Load(): boolean {
        // Asi evitamos bucles infinitos o cosas raras por lecturas demasiado rapidas...
        if (this.status === +1) {
            return true;
        }
        if (this.status !== -2) {
            console.log("ERROR: Status(" + this.status + "): Reading operation not yet started!!!.");
            return false;
        }

        // Con el indice intento controlar tiempos excesivos de espera para impedir posibles bucles infinitos.
        let index = 0;
        while (this.status === -2) {
            console.log("[" + index + "] wait...");
            ++index;
            if (index > 1000000) {
                this.status = -666;
            }
        }

        if (this.status === +1) {
            return true;
        }
        return false;
    }

} // class TextBuffer

function readTextFile(fileName:string, buf: TextBuffer) {
    console.log("Trying to read TEXT file '" + fileName + "'...");
    if (fileName === "") {
        return;
    }

    // Avisamos de que comienza la carga, si es ello posible.
    if (!buf.setLoadStarted()) {
        console.log("ERROR: Can't start loading!!!.");
        return;
    }

    // Esto funciona en HTML standard, pero da errores en React, pues siempre devuelve el contenido de index.html.        
    // La solucion fue anteponer a los path relativos de los ficheros un "/".
    const rawFile = new XMLHttpRequest();
    rawFile.open("GET", fileName, false);

    rawFile.onreadystatechange = () => {
        console.log("\tonreadystatechange('" + fileName + "');");

        if (rawFile.readyState === 4) {
            if (rawFile.status === 200 || rawFile.status === 0) {
                console.log("\tFile '" + fileName + "' read OK!!!.");
                const allText = rawFile.responseText;
                buf.load(allText);
            } else {
                console.log("\tFile '" + fileName + "' ERROR(I).");
                buf.setError();
            }
        } else {
            console.log("\tFile '" + fileName + "' ERROR(II).");
        }
    };

    rawFile.send(null);
}

function readJSONFile(fileName:string, buf: TextBuffer) {
    console.log("Trying to read JSON file '" + fileName + "'...");
    if (fileName === "") {
        return;
    }

    // Avisamos de que comienza la carga, si es ello posible.
    if (!buf.setLoadStarted()) {
        console.log("ERROR: Can't start loading!!!.");
        return;
    }

    // Esto funciona en HTML standard, pero da errores en React, pues siempre devuelve el contenido de index.html.        
    // La solucion fue anteponer a los path relativos de los ficheros un "/".
    const rawFile = new XMLHttpRequest();
    // La principal novedad es esta linea:
    rawFile.overrideMimeType("application/json");
    rawFile.open("GET", fileName, false);
    rawFile.onreadystatechange = () => {
        console.log("\tonreadystatechange('" + fileName + "');");

        if (rawFile.readyState === 4) {
            if (rawFile.status === 200 || rawFile.status === 0) {
                console.log("\tFile '" + fileName + "' read OK!!!.");
                const allText = rawFile.responseText;
                console.log("\t\tLength: " + allText.length);
                buf.load(allText);
            } else {
                console.log("\tFile '" + fileName + "' ERROR(I).");
                buf.setError();
            }
        } else {
            console.log("\tFile '" + fileName + "' ERROR(II).");
        }
    };

    rawFile.send(null);
}

class BinaryBuffer {
    status: number = -1;
    buffer: Uint8Array | null = null

    constructor() {
        this.reset();
    }

    load(newBinData: Uint8Array) {
        this.buffer = newBinData;
        this.status = +1;
    }

    reset() {
        // Con esto indicamos que el buffer aun no ha sido puesto a cargar.
        this.status = -1;
        this.buffer = null;
    }

    // Aqui indicamos un estado de error.
    setError() {
        this.status = 0;
    }

    // Avisamos de que se ha comenzado una lectura, que podra o no tener exito.
    setLoadStarted(): boolean {
        // Solo se autoriza la transaccion desde el estado inicial para evitar recovecos...
        if (this.status === -1) {
            this.status = -2;
            console.log("Starting reading operation...");
            return true;
        }
        return false;
    }

    // Mecanismo de espera bloqueante. El true indica correcta lectura.
    wait2Load(): boolean {
        // Asi evitamos bucles infinitos o cosas raras por lecturas demasiado rapidas...
        if (this.status === +1) {
            return true;
        }
        if (this.status !== -2) {
            console.log("ERROR: Status(" + this.status + "): Reading operation not yet started!!!.");
            return false;
        }

        // Con el indice intento controlar tiempos excesivos de espera para impedir posibles bucles infinitos.
        let index = 0;
        while (this.status === -2) {
            console.log("[" + index + "] wait...");
            ++index;
            if (index > 1000000) {
                this.status = -666;
            }
        }

        if (this.status === +1) {
            return true;
        }
        return false;
    }

} // class BinaryBuffer

function readBinaryFile(fileName:string, buf: BinaryBuffer) {
    console.log("Trying to read BINARY file '" + fileName + "'...");
    if (fileName === "") {
        return;
    }

    // Avisamos de que comienza la carga, si es ello posible.
    if (!buf.setLoadStarted()) {
        console.log("ERROR: Can't start loading!!!.");
        return;
    }

    // Esto funciona en HTML standard, pero da errores en React, pues siempre devuelve el contenido de index.html.        
    // La solucion fue anteponer a los path relativos de los ficheros un "/".
    const rawFile = new XMLHttpRequest();
    rawFile.responseType = "arraybuffer";
    rawFile.open("GET", fileName, false);
    // rawFile.responseType = "arraybuffer";


    rawFile.onreadystatechange = () => {
        console.log("\tonreadystatechange('" + fileName + "');");

        if (rawFile.readyState === 4) {
            if (rawFile.status === 200 || rawFile.status === 0) {
                console.log("\tFile '" + fileName + "' read OK!!!.");
                const arrayBuffer = rawFile.response;
                const byteArray = new Uint8Array(arrayBuffer);    
                buf.load(byteArray);
            } else {
                console.log("\tFile '" + fileName + "' ERROR(I).");
                buf.setError();
            }
        } else {
            console.log("\tFile '" + fileName + "' ERROR(II).");
        }
    };

/*    
    // Adaptado de https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Sending_and_Receiving_Binary_Data
    rawFile.onload = function (oEvent) {
        var arrayBuffer = rawFile.response; // Note: not oReq.responseText
        if (arrayBuffer) {
            const byteArray = new Uint8Array(arrayBuffer);
            buf.load(byteArray);
        //   var byteArray = new Uint8Array(arrayBuffer);
        //   for (var i = 0; i < byteArray.byteLength; i++) {
        //     // do something with each byte in the array
        //   }
        } else {
            buf.setError();
        }
      };
*/
    // rawFile.onreadystatechange = () => {
    //     console.log("\tonreadystatechange('" + fileName + "');");

    //     if (rawFile.readyState === 4) {
    //         if (rawFile.status === 200 || rawFile.status === 0) {
    //             console.log("\tFile '" + fileName + "' read OK!!!.");
    //             const allText = rawFile.responseText;
    //             buf.load(allText);
    //         } else {
    //             console.log("\tFile '" + fileName + "' ERROR(I).");
    //             buf.setError();
    //         }
    //     } else {
    //         console.log("\tFile '" + fileName + "' ERROR(II).");
    //     }
    // };

    rawFile.send(null);
}

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function readBinaryFile_ASYNC(fileName:string, fCont: (arg: Uint8Array | null) => void) {
    console.log("Trying to read BINARY file '" + fileName + "'...");
    if (fileName === "") {
        return false;
    }

    let returnArray: Uint8Array | null = null;
    let mustContinue = true;

    // Esto funciona en HTML standard, pero da errores en React, pues siempre devuelve el contenido de index.html.        
    // La solucion fue anteponer a los path relativos de los ficheros un "/".
    const rawFile = new XMLHttpRequest();
    rawFile.open("GET", fileName, true);
    rawFile.responseType = "arraybuffer";

    // Adaptado de https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Sending_and_Receiving_Binary_Data
    rawFile.onload = (oEvent) => {
        const arrayBuffer = rawFile.response;
        if (arrayBuffer) {
            returnArray = new Uint8Array(arrayBuffer);
            fCont(returnArray);
        } else {
            fCont(null);
        }
        mustContinue = false;
    };

    rawFile.send(null);
    return true;
}

/**
 * Devuelve, un buffer array, o bien un vector de numeros mas posiblemente algo para verificar que la cantidad de items
 * devuelta como salida es multiplo de algo. En caso de error devuelve null.
 *
 * @param txt 
 * @param testMultiple 
 * @param returnBA
 * @returns 
 */
export function sendStringNumbers2Array(txt: string,
                                        testMultiple: number = 0,
                                        returnBA: boolean = true): [Float32Array | number[], boolean] | null {
    let len = txt.length;
    if (!len) {
        return null;
    }
    // Aqui iremos acumulando las coordenadas que vayamos extrayendo del texto dado.
    // Antes lo tenia con un solo espacio, que era mas rapido, pero la ER es mas segura, pues detecta varios.
    // Problema: Me mete siempre un 0.0 final al llegar al ultimo \n, asi que he tenido que meter el replace().
    const separator = /\s+/;
    const v_xyz: number[] = txt.replace(/^\s+|\s+$/g, '').split(separator).map(Number);
    len = v_xyz.length;
    if (!len) {
        return null;
    }
        
    let isMultiple = false;
    if (testMultiple) {
        // Comprobamos si la cantidad de numeros extraidos es multiplo del numero no nulo dado.
        if (len % testMultiple === 0) {
            isMultiple = true;
        }
    }

    if (returnBA) {
        return [new Float32Array(v_xyz), isMultiple];
    } else {
        return [v_xyz, isMultiple];
    }
}


/**
 * Un parser generico para leer los datos de deformaciones/displacementes o bien los de forces/moments con la estructura
 * final que usaremos, implicita en los ficheros en "hyp_disp_cdti.txt" y "hyp_forc_cdti.txt" presentes en el directorio
 * frontend/public/files_mesh3d.
 * Siempre en la primera linea tenemos los titulos y en las siguentes una serie de items separados por comas.
 *
 * Los ficheros de DEFORMACIONES son de la forma:
 *  NODE,DX,DY,DZ,DRX,DRY,DR
 *  N1,-0.00022349999999999998,-5.53e-05,-0.000248,-0.0001972,0.0002647,-2.702
 *  N2,-0.0002214,-5.902e-05,-0.0006362999999999999,-0.00022610000000000002,0.0002841,-0.2288
 *  N3,-0.00011909999999999998,-4.4e-05,-0.0001557,0.00018659999999999998,0.0002491,-7.846
 *  N4,-0.00022309999999999997,-5.901e-05,-0.0005689,-0.0002213,0.0002533,0.0
 *  N5,-0.00011719999999999999,-5.148e-05,-0.0003715,0.0002701,0.000258,-0.2021
 *  N6,-0.0002272,-8.914e-05,-0.00045410000000000003,-0.00032539999999999994,-1.287e-05,-1.8519999999999999
 *  N7,-0.00011540000000000001,-5.149e-05,-0.0002847,0.0002915,0.00024019999999999996,0.0
 *  ...
 *  ...
 *  
 *  Como vemos hay en la primera columna una cadena "Ni" con i el identificador posicional del nodo implicado y las 6
 *  columnas restantes llevan los datos especificados en la linea 1.
 *
 * Mientras que los ficheros F&M son de la forma:
 *  ELEMENT,NODE,NXX,NYY,NXY,MXX,MYY,MXY,QX,QY
 *  M33689,N26,-9.892E+02,2.616E+03,-1.813E+03,-1.945E+03,-5.372E+03,4.104E+03,8.561E+03,3.632E+04
 *  M33689,N247,-9.892E+02,2.616E+03,-1.813E+03,-1.083E+02,-1.316E+03,3.513E+03,8.561E+03,3.632E+04
 *  M33689,N36,-9.892E+02,2.616E+03,-1.813E+03,-1.467E+03,4.076E+02,4.579E+03,8.561E+03,3.632E+04
 *  M33690,N1105,4.636E+04,2.193E+04,1.892E+04,5.665E+03,4.482E+03,-1.909E+02,-2.364E+04,-4.283E+02
 *  ...
 *  ...
 *
 *  Siendo la primera columna "Mi" el ordinal del elemento implicado y el segundo "Nj" el ordinal del nodo implicado.
 */
class GenParser {
    /**
     * Array LINEAL en el que meteremos todos los datos NUMERICOS leidos, de tamaño numTuples_ * dimTuple_.
     * WARNING: Usaremos precision float para ahorrar memoria, esperando que no haya problemas de precision...
     */
    data_: Float32Array;

    /**
     * El numero de datos NUMERICOS en cada tupla, por ejemplo para las deformaciones son 6 y para las F&M son 8 o 10.
     * Es el numero de columnas en cada linea menos 2 (ordinal de elemento y ordinal de nodo, al principio de cada
     * linea), salvo para F&M que las incluye. Inicialmente vale -1 para denotar la no lectura aun.
     */
    dimTuple_: number;

    /**
     * El total de tuplas leidas coincidente con el numero de lineas del fichero menos 1 (por la cabecera con los
     * titulos de las columnas). Inicialmente vale -1 para denotar la no lectura aun.
     */
    numTuples_: number;

    /**
     * Los titulos de las columnas numericas (es decir la de los datos numericos que importan, sin los uno o dos datos
     * ordinales posicionales al inicio de cada linea), de forma que el indice en title_ coincida con el de la hipotetica
     * columna en data_. Asi que van en orden siempre de columnas.
     */
    titles_: string[];

    /**
     * Los tipos de informacion a los que va dirigido este parser: Basicamente parsearemos 2 tipos de informacion:
     * NODAL(1) y ELEMENTAL+NODAL(2), segun que en la primera linea aparezca primero "NODE" o bien "ELEMENT" + "NODE".
     * Por defecto pondremos Unknown(0).
     * Agregamos los IP, puntos de integracion asociados a los elementos.
     */
    static readonly UnknownInfo = 0;
    static readonly NodalInfo = 1;
    static readonly ElementalNodalInfo = 2;
    static readonly ElementalIP = 3;

    // Detectaremos ademas el tipo de informacion parseada NODAL o ELEMENTAL-NODAL.
    typeInfo_: number;

    /**
     * Tambien, segun el tipo de informacion NODAL/ELEMENTAL necesitamos seguir la pista de las tuplas que les corresponden
     * a los nodos o elementos:
     * Cuando la informacion es nodal, solamente tenemos para cada nodo de indice I una tupla de datos en la posicion J
     * del buffer data_ o bien digamos que a cada I le corresponde una linea J, de forma que metemos un mapa de entero
     * a un array de enteros de una UNICA posicion (para aprovechar el caso ELEMENTAL mas abajo de la misma forma).
     * Si la informacion es elemental, cada ELEMENTO tiene VARIOS NODOS y cada uno de esos nodos para ese elemento tienen
     * su UNICO indice de linea o posicion asociada, (ojo que un mismo nodo puede estar en varios elementos con distinas
     * lineas, pero una linea es unica para un nodo y elemento) para lo que metemos un mapa de enteros a arrays de duplas
     * [indiceNodo, indiceLinea], ademas en este caso usamos el mapa de nodos para apuntar no a las lineas sino a los
     * elementos de los que este nodo forma parte. Un poco lioso...
     */
    mapNodes_: Map<number, number[]>;
    mapElems_: Map<number, [number, number][]>;

    constructor() {
        this.data_ = null as unknown as Float32Array;
        this.dimTuple_ = this.numTuples_ = -1;
        this.titles_ = [];
        this.typeInfo_ = GenParser.UnknownInfo;
        this.mapNodes_ = new Map<number, number[]>();
        this.mapElems_ = new Map<number, [number, number][]>();
    }

    destroy(): void {
        this.data_ = null as unknown as Float32Array;
        this.dimTuple_ = this.numTuples_ = -1;
        this.titles_.length = 0;
        this.typeInfo_ = GenParser.UnknownInfo;
        this.mapNodes_.clear();
        this.mapElems_.clear();
    }

    /**
     * Para leer los datos a partir del fichero de nombre dado.
     * \Warning: Se debe dar con el path completo a un directorio publico autorizado.
     * En caso de error devuelve false.
     * 
     * @param fileName 
     * @returns 
     */
    readData4File(fileName: string): boolean {
        if (this.data_) {
            this.destroy();
        }

        if (fileName.length) {
            // En este buffer depositamos el texto leido del fichero.
            const buf = new TextBuffer();
            const t0 = performance.now();

            readTextFile(fileName, buf);
            if (!buf.wait2Load()) {
                console.log("ERROR al cargar el fichero de datos '" + fileName + "'. Posible path erroneo???.");
            } else {
                let txt = buf.buffer;
                if (txt.length === 0) {
                    window.alert("ERROR al intentar cargar el fichero de datos '" + fileName + "'. Vacio!!!.");
                    return false;
                } else {
                    // Y aqui pasamos el pastelito al parseador de textos, con el chorron de datos.
                    const result = this.readData4String(txt);

                    const t1 = performance.now();
                    console.info("[GenParser.readData4File() time] " + (t1 - t0).toFixed(3) + " milliseconds.");
        
                    // Ayuda al GC.
                    txt = "";
                    buf.reset();
                    return result;
                }
            }
        }
        return false;
    }

    /**
     * Para leer los datos a partir del chorrazo de lineas que nos lleguen en un cadenon de la hostia.
     * En caso de error devuelve false.
     * Si includeMN es true, se devuelven al principio los datos con los indices del elemento y nodo implicados.
     *
     * @param txt 
     * @returns 
     */
    public readData4String(txt: string, includeMN: boolean = false): boolean {
        if (this.data_) {
            this.destroy();
        }

        const numCharacters = txt.length;
        if (numCharacters === 0) {
            window.alert("ERROR al intentar cargar una cadena de datos vacia!!!.");
            return false;
        } else {
            console.log(`Recibido un string con ${numCharacters} caracteres.`)
        }

        // El separador de items es siempre la coma ",", luego si hay comas donde no se debe tenemos lio al canto.
        // Detectaremos ademas el tipo de informacion parseada NODAL o ELEMENTAL-NODAL.
        let typeInfo = GenParser.UnknownInfo;

        let cntLines = 0;
        let vColsTitles: string[] | null = null
        let numItemsPerCol = -1;
        let numNumericColumns = -1;
        let vTmpData: number[] = [];
        // Para asociar a los mapas con la asignacion de referencias ndos-elementos-lineas.
        const mNodes = new Map<number, number[]>();
        const mElems = new Map<number, [number, number][]>();
        // Un indice para asignar los nodos/elementos a la posicion correspondiente a la tupla en data_.
        let indexData = 0;

        const t0 = performance.now();

        // \DuDa: Quizas esto gaste mucha memoria y necesite algo que no lea todo sino cada linea por separado...
        for (let line of txt.split("\n")) {
            // Toleramos lineas vacias por el medio y al final, fruto del ultimo salto de linea...
            if (line === "") {
                continue;
            }
            // console.log(`[${cntLines}] "${line}"`);
            if (cntLines === 0) {
                // La linea 0 lleva los titulos de las columnas.
                vColsTitles = line.split(",");
                numItemsPerCol = vColsTitles.length;
                // Por si las moscas les quitamos los posibles blancos iniciales y finales en cada columna, que no me fio.
                for (let i = 0; i < numItemsPerCol; ++i) {
                    vColsTitles[i] = vColsTitles[i].trim();
                }

                if (numItemsPerCol < 3) {
                    console.error(`ERROR: Linea inicial con numero de datos insuficientes: "${line}"`);
                    return false;
                } else {
                    // Aqui o bien tenemos "NODE" como primera columna (para datos NODALES) o bien "ELEMENT" + "NODE"
                    // como primera y segunda respectivamente (para datos por ELEMENTO + NODO).
                    const firstItem = vColsTitles[0];
                    if (firstItem === "NODE") {
                        typeInfo = GenParser.NodalInfo;
                    } else {
                        if (firstItem === "ELEMENT") {
                            const secondItem = vColsTitles[1];
                            if (secondItem === "NODE") {
                                typeInfo = GenParser.ElementalNodalInfo;
                            } else {
                                if (secondItem === "IP") {
                                    typeInfo = GenParser.ElementalIP;
                                }
                            }
                        } else {
                            if (firstItem === "ELEMENT_ID") {
                                // \ToDo: Quizas haya casos en los que haya un segundo *_ID, pero de momento me conformo asi.
                                typeInfo = GenParser.NodalInfo;
                            }
                        }
                    }

                    if (!typeInfo) {
                        console.error(`ERROR: La linea inicial no indica nodos/elementos: "${line}"`);
                        return false;        
                    }
                    
                    if (typeInfo === GenParser.NodalInfo) {
                        if (!includeMN) {
                            numNumericColumns = numItemsPerCol - 1;                            
                            // Quitamos la primera columna ("NODE") de los titulos encontrados.
                            vColsTitles.shift();
                        } else {
                            numNumericColumns = numItemsPerCol;
                        }
                        console.log(`Encontradas [${numNumericColumns}] columnas de datos numericos NODALES:`);
                    } else {
                        if (typeInfo === GenParser.ElementalNodalInfo) {
                            if (!includeMN) {
                                numNumericColumns = numItemsPerCol - 2;
                                // Quitamos primera y segunda columnas ("ELEMENT" + "NODE") de los titulos encontrados.                        
                                vColsTitles.shift();
                                vColsTitles.shift();
                            } else {
                                numNumericColumns = numItemsPerCol
                            }
                            console.log(`Encontradas [${numNumericColumns}] columnas de datos numericos ELEMENTALES-NODALES:`);
                        } else {
                            if (typeInfo === GenParser.ElementalIP) {
                                numNumericColumns = 3;
                                vColsTitles.shift();
                                vColsTitles.shift();
                                vColsTitles.pop();
                            }                                    
                        }
                    }

                    // Otra cosa que no soportamos es que las columnas tengan nombres repetidos o vacios.                    
                    let auxTxt = "\t";
                    for (let i = 0; i < numNumericColumns; ++i) {
                        const colTitle = vColsTitles[i];
                        if (colTitle.length === 0) {
                            console.error(`ERROR: La linea inicial contiene la columna numerica [${i}] vacia: "${line}"`);
                            return false;            
                        } else {
                            // Comprobacion de no repeticion.
                            for (let j = i + 1; j < numNumericColumns; ++j) {
                                const colTitleJ = vColsTitles[j];
                                if (colTitle === colTitleJ) {
                                    console.error(`ERROR: Hay columnas con el mismo nombre "${colTitle}": "${line}"`);
                                    return false;                    
                                }
                            }
                        }
                        auxTxt += "[" + i + "] '" + colTitle + "'   ";
                    }
                    console.log(auxTxt);
                }
            } else {
                // Las restantes lineas contienen la informacion numerica previa a ciertos indices ordinales, que o bien
                // son solo de nodos o de elementos + nodos. Pero en todo caso todo esta separado por comas.
                const vItems = line.split(",");
                const numItems = vItems.length;
                // Debe haber los mismos items en todas las lineas.
                if (numItems !== numItemsPerCol) {
                    console.error(`ERROR: En la linea [${cntLines + 1}] hay un numero diferente de items (${numItems}) que los indicados en la inicial (${numItemsPerCol}).`);
                    console.log(line);
                    return false;
                }

                // El primer elemento empieza por M para las F&M o bien por N para los displacements.
                // En el caso F&M el segundo elemento empezara por N. En todo caso sacaremos los indices i:j Mi y Nj.
                let indexElem = -1;
                let indexNode = -1;
                const firstItem = vItems[0];
                let isOk = true;

                // Informacion NODAL: N(in) + ...
                // Informacion ELEMENTAL-NODAL: M(ie) + N(in) + ...
                if (typeInfo === GenParser.NodalInfo) {
                    if (firstItem[0] === 'N') {
                        // Sacamos el indice N(in) asociado al nodo correspondiente.
                        const numStr = firstItem.slice(1) as any;
                        indexNode = numStr * 1;
                        if (!includeMN) {
                            // Quitamos solo primer elemento.
                            vItems.shift();
                        }
                        // Asociamos la posicion de los datos al nodo, comprobando posible existencia previa.
                        // Como estamos en datos NODALES simplemente enlazamos indiceNodo ===> indiceData_ que deberia
                        // ser un par totalmente unico [indexNode, [indexData]] en el mapa, sin ninguna posibilidad de
                        // repeticion.
                        if (!mNodes.has(indexNode)) {
                            mNodes.set(indexNode, [indexData]);
                        } else {
                            const prevContent = mNodes.get(indexNode);
                            console.warn(`WARNIG: Nodo ${indexNode} ya existente con este contenido previo:`);
                            console.log(prevContent);
                            debugger;
                            isOk = false;
                        }
                    } else {
                        // Nuevo cambio por sugerencia de PaulLittleField: Ya no ponemos N en la primera posicion, sino
                        // que lo primero es directamente el id del propio nodo, sin prefijo "N".
                        const numStr = firstItem as any;
                        indexNode = numStr * 1;
                        if (!includeMN) {
                            // Quitamos solo primer elemento.
                            vItems.shift();
                        }
                        // Asociamos la posicion de los datos al nodo, comprobando posible existencia previa.
                        // Como estamos en datos NODALES simplemente enlazamos indiceNodo ===> indiceData_ que deberia
                        // ser un par totalmente unico [indexNode, [indexData]] en el mapa, sin ninguna posibilidad de
                        // repeticion.
                        if (!mNodes.has(indexNode)) {
                            mNodes.set(indexNode, [indexData]);
                        } else {
                            const prevContent = mNodes.get(indexNode);
                            console.warn(`WARNIG: Nodo ${indexNode} ya existente con este contenido previo:`);
                            console.log(prevContent);
                            debugger;
                            isOk = false;
                        }
                    }
                } else {
                    if (typeInfo === GenParser.ElementalNodalInfo) {
                        const secondItem = vItems[1];
                        if (firstItem[0] === 'M' && secondItem[0] === 'N') {
                            // Indice de elemento e indice de nodo.
                            const numStr1 = firstItem.slice(1) as any;
                            indexElem = numStr1 * 1;
                            const numStr2 = secondItem.slice(1) as any;
                            indexNode = numStr2 * 1;
                            if (!includeMN) {
                                // Quitamos 2 primeros elementos.
                                vItems.shift();
                                vItems.shift();
                            } else {
                                vItems[0] = numStr1;
                                vItems[1] = numStr2;
                            }

                            // Asociamos la posicion de los datos al nodo y al elemento. Estamos en el caso de datos
                            // NODALES-ELEMENTALES, por lo que asignamos al elemento los varios nodos que lo componen,
                            // y a cada nodo la linea??? \DuDa aqui!!!.
                            // Recordatorio: En el mapa de elementos metemos para ese indice de elemento el par formado
                            // por [indiceNodo, indiceDatos] y en el mapa de nodos para ese nodo metemos los elementos
                            // a los que pertenece. Creo que de esta forma tengo toda la informacion completada.
                            if (!mElems.has(indexElem)) {
                                mElems.set(indexElem, [[indexNode, indexData]]);
                            } else {
                                const prevContent = mElems.get(indexElem) as [number, number][];
                                // Veamos que en el contenido previo no estaba lo que queremos meter:
                                // No se puede repetir el mismo nodo o la misma linea.
                                const pos = this.indicesOfAB4Vector(indexNode, indexData, prevContent);
                                if (pos) {
                                    console.error("Imposible (I).")
                                    isOk = false;
                                    debugger;
                                } else {
                                    prevContent.push([indexNode, indexData]);
                                }                                
                            }                         
                            if (!mNodes.has(indexNode)) {
                                // En este caso se mete el elemento asociado.
                                mNodes.set(indexNode, [indexElem]);
                            } else {
                                const prevContent = mNodes.get(indexNode) as number[];
                                // Veamos que no hay repeticiones.
                                const pos = prevContent.indexOf(indexElem)
                                if (-1 === pos) {
                                    // Recuerda que prevContent es una referencia y sus modificaciones se propagan.
                                    prevContent.push(indexElem);
                                } else {
                                    console.error("Imposible (II).")
                                    isOk = false;
                                    debugger;
                                }
                            }    
                        } else {
                            isOk = false;
                        }
                    } else {
                        if (typeInfo === GenParser.ElementalIP) {
                            // Informacion sobre puntos de integracion.
                            // En este caso tenemos una cadena de la forma:
                            // "M4292,1,1.47500000000000E+01,8.00000000000037E-01,2.83917669571040E+00,2.73148148148148E-0"
                            const secondItem = vItems[1];
                            // Siempre empezara por M mas el numero del shell implicado y continuara por 1|2|3|4.
                            if (firstItem[0] === 'M' && (secondItem === '1' || secondItem === '2' || secondItem === '3' || secondItem === '4')) {
                                // Indice del shell element.
                                indexElem = (firstItem.slice(1) as any) * 1;
                                // Y el indice ORDINAL (no el real) de su nodo asociado, de los 3|4 disponibles y se supone
                                // que en el mismo orden.
                                indexNode = (secondItem as any) * 1;
                                // Y quitamos esos 2 elementos iniciales, quedandonos con el resto.
                                vItems.shift();
                                vItems.shift();
                                // Lo que resta es la informacion necesaria de puntos de integracion, X, Y, Z, salvo la
                                // ultima, la W que no usamos para nada y tambien nos la quitamos de encima.
                                vItems.pop();
                                // Almacenamos los indices REALES del elemento y los ORDINALES 1|2|3|4 de sus nodos...
                                // pero tambien metemos la posicion de esos datos asociados una sola vez.                                
                                if (!mElems.has(indexElem)) {
                                    // Primera insercion.
                                    mElems.set(indexElem, [[indexNode, indexData]]);
                                } else {
                                    // Un nuevo par. Esto es muy redundante, pero de momento que funcione...
                                    const prevContent = mElems.get(indexElem) as [number, number][];
                                    prevContent.push([indexNode, indexData]);
                                }
                            } else {
                                isOk = false;
                            }

                        } else {
                            console.error(`ERROR: No es posible: tipos de dato NODAL-ELEMENTAL [${typeInfo}] incorrecto???.`);
                            return false;
                        }
                    }
                }

                if (!isOk) {
                    console.error(`ERROR: [${cntLines + 1}] "${line}"`);
                    return false;
                }

                // Ahora sencillamente toda la informacion en vItems es numerica y la podemos meter a huevo.
                vTmpData.push(...vItems.map(Number));

                ++indexData;
            }

            ++cntLines;
        }

        // Quitamos la primera linea de la cuenta.
        --cntLines;

        console.log(`Procesado un total de ${cntLines} lineas cada una con ${numNumericColumns} datos numericos.`)
        // Comprobacion psicopatica que desaparecera.
        const numDataInArray = vTmpData.length;
        const numDataTheoric = cntLines * numNumericColumns;
        if (numDataInArray !== numDataTheoric) {
            console.error(`ERROR: El numero de datos en el array (${numDataInArray}) no coincide con el teorico (${numDataTheoric}).`)
            return false;
        } else {
            console.log(`Incorporado un total de ${numDataInArray} datos numericos.`);
        }

        // Llegados aqui todo esta bien, asi que asignamos los datos.
        this.data_ = new Float32Array(vTmpData);
        vTmpData.length = 0;
        this.dimTuple_ = numNumericColumns;
        this.numTuples_ = cntLines;
        this.titles_ = vColsTitles as string[];
        this.typeInfo_ = typeInfo;
        this.mapNodes_ = mNodes;
        this.mapElems_ = mElems;

        const t1 = performance.now();
        console.info("[GenParser.readData4String() time] " + (t1 - t0).toFixed(3) + " milliseconds.");

        return true;
    }

    /**
     * Dado un vector con nombres de columnas, en cualquier orden, se nos devuelve un vector con sus respectivos indices
     * de orden posicional (en el orden en que esas columnas hayan sido dadas), o null en caso de error.
     *
     * @param vColumns 
     */
    public getIndices4Columns(vColumns: string[]): number[] | null {
        // Obviamente debemos tener columnas ya definidas.
        if (!this.titles_.length) {
            return null;
        }

        // Compruebo que no hay error alguno en los nombres de columnas dados. Ni repeticiones ni inexistencias...
        // Ademas esas columnas se pueden dar en el orden que se quiera.
        // ya que las ordenaremos.
        const numCols = vColumns.length;
        if (!numCols) {
            return null;
        }

        const vIndices: number[] = [];
        for (const col of vColumns) {
            // Si no esta entre las disponibles, capote de grana y oro.
            const index = this.titles_.indexOf(col);
            if (-1 === index) {
                return null;
            } else {
                // Veamos que no esta repe.
                const pos2 = vIndices.indexOf(index);
                if (-1 === pos2) {
                    vIndices.push(index);
                } else {
                    return null;
                }
            }
        }
        return vIndices;
    }

    /**
     * Supongamos que nos dan las deformaciones, que tienen 6 datos de los que solo usaremos finalmente los 3 primeros
     * y no queremos malgastar nuestra escasa memoria en los 3 ultimos que para nada usamos. Para esto sirve esta fmc,
     * que devuelve false en caso de error sin tocar nada mas, pero que si funciona bien elimina las columnas dadas,
     * reduce la memoria usada y el tamaño de las tuplas.
     * @param vColumns Un vector con los titulos de las columnas a eliminar, en cualquier orden.
     */
    deleteDataColumns(vColumns: string[]): boolean {
        // Lo primero es sacar los indices de las columnas, si estas son validas.
        const vPosCols2Delete = this.getIndices4Columns(vColumns);
        if (vPosCols2Delete === null) {
            return false;
        }

        // Ordenamos los indices a borrar inversamente para que al borrar no se invaliden indices.
        vPosCols2Delete.sort().reverse();
        // Y generamos los indices a conservar, poniendolos todos y quitando los que se borran.
        const vIndices2Preserve = [...Array(this.dimTuple_).keys()];
        for (const pos of vPosCols2Delete) {
            vIndices2Preserve.splice(pos, 1);
        }

        // No permito borrarlo todo, ya que no tendria sentido, pues para eso llamas a la fmc destroy().
        const num2Preserve = vIndices2Preserve.length;
        if (!num2Preserve) {
            return false;
        }

        // Ahora sacamos los datos originales que recorremos en las tuplas del cardinal original.
        const vDstData: number[] = [];
        for (let i = 0; i < this.numTuples_; ++i) {
            const tupleSrc = this.data_.subarray(i * this.dimTuple_, this.dimTuple_);
            for (let k = 0; k < num2Preserve; ++k) {
                const value = tupleSrc[vIndices2Preserve[k]];
                vDstData.push(value);
            }
        }

        // Ya podemos hacer el trasvase de elementos.
        this.data_ = new Float32Array(vDstData);
        vDstData.length = 0;
        this.dimTuple_ = num2Preserve;
        for (const pos of vPosCols2Delete) {
            this.titles_.splice(pos, 1);
        }

        return true;
    }

    /**
     * Dados 2 numeros A y B, y un vector de duplas de numeros V, se devuelve una dupla de numeros [posA, posB] con:
     * posA: La primera posicion en V de una dupla donde aparece A como primera componente, aka [A, *].
     * posB: Idem pero donde B aparece como segunda componente, aka [*, B].
     * Si alguno no aparece la posicion sera -1, es decir que se devuelve [-1, posB] o [posA, -1], pero en caso de no
     * aparecer ninguno devolvemos null.
     *
     * @param a 
     * @param b 
     * @param v 
     */
    private indicesOfAB4Vector(a: number, b: number, v: [number, number][]): [posA: number, posB: number] | null {
        let [posA, posB] = [-1, -1];
        const numPos = v.length;
        
        for (let i = 0; i < numPos; ++i) {
            const [aI, bI] = v[i];
            if (posA === -1) {
                if (a === aI) {
                    posA = i;
                    if (posB !== -1) {
                        // Encontramos los 2.
                        return [posA, posB];
                    }
                }
            }
            if (posB === -1) {
                if (b === bI) {
                    posB = i;
                    if (posA !== -1) {
                        // Encontramos los 2.
                        return [posA, posB];
                    }
                }
            }
        }
        // Si no encontramos ninguno.
        if (posA === -1 && posB === -1) {
            return null;
        }
        // Encontramos alguno.
        return [posA, posB];
    }

    /**
     * Dada una funcion/functor que admite como entrada un vector de N parametros (con N variable y los parametros de
     * cualquier tipo), se la aplicamos a todas las columnas dadas y en ese preciso orden. Las columnas se especifican
     * mediante sus nombres.
     * En caso de error se devuelve false, pero si todo es correcto se procede a llamar, para todas las tuplas
     * disponibles a esa funcion, alimentandolas con los valores correspondientes a esas columnas y en el orden en que
     * estas fueron dadas.
     *
     * @param f 
     * @param vColumns 
     * @returns 
     */
    public applyFunctor2Columns(f: (...vParams: any[]) => void, vColumns: string[]): boolean {

        if (f === null || f === undefined) {
            return false;
        }

        const vIndices = this.getIndices4Columns(vColumns);
        if (vIndices === null) {
            return false;
        }

        // Parametros de entrada a f, y su cardinal.
        const arity = vIndices.length;
        const vArgs = new Array<number>(arity);
        // Recoleccion de tupla, extraccion de parametros e inyeccion a f.
        for (let i = 0; i < this.numTuples_; ++i) {
            // Ojo, que se deben dar los datos de la forma [start, end), no start + amount.
            const start = i * this.dimTuple_;
            const end = start + this.dimTuple_;
            const tupleSrc = this.data_.subarray(start, end);
            for (let k = 0; k < arity; ++k) {
                const value = tupleSrc[vIndices[k]];
                vArgs[k] = value;
            }
            f(...vArgs);
        }

        return true;
    }

    /**
     * Dada una cadena con una referencia de la forma "//@versions.0/@femmeshstructure/@groups.112" y otra cadena con el
     * contenido de alguno de sus componentes de la forma "/@cadena.numero/ "(como versions o groups), con o sin incluir
     * la "@" inicial o el "." final, se devuelve el numero implicado, como el 0 o el 112, o -1 en caso de error.
     * @param ref 
     * @param item 
     */
    static getIndex4Ref(ref: string, item: string): number {
        // Metemos la "@" inicial y el "." final al item.
        if (item[0] !== "@") {
            item = "@" + item;
        }        
        if (item[item.length - 1] !== ".") {
            item = item + ".";
        }

        let pos = ref.lastIndexOf(item);
        if (pos !== -1) {
            pos += item.length;
            // Recortamos saltandonos el item, asi que pasamos de la referencia inicial:
            // '//@versions.0/@building/@storeys.0/@elements.11' ===> '0/@elements.11'
            ref = ref.slice(pos);
            // Pudieran pasar o bien que el trozo cortado contiene solo al numero buscado o bien que hay un trozaco mas.
            // Buscamos un posible delimitador "/".
            const end = ref.indexOf("/");
            if (-1 !== end) {
                ref = ref.slice(0, end);
            }
            const num = parseInt(ref);
            return num;
        }
        return -1;
    }

} // class GenParser

/**
 * Clase de los numeros de display de 7 segmentos: Number-7-[S]egment-[D]isplay.
 * Intento resolver el problema de cantidades masivas de numeros usando geometrias MIB (multiple-instanced-buffer).
 * Usaremos 8 vertices (+) entre los que dibujaremos unas lineas, como numeros de un display digital:
 *
 *      +--+  +--+  +--+  +--+  +--+  +  +  +--+  +--+     +  +--+
 *      |  |  |  |     |  |     |     |  |     |     |     |  |  |
 *      +--+  +--+     +  +--+  +--+  +--+  +--+  +--+     +  +  +
 *         |  |  |     |  |  |     |     |     |  |        |  |  |
 *      +--+  +--+     +  +--+  +--+     +  +--+  +--+     +  +--+
 *
 *      +--+  +--+  +--+  +--+  +--+  +  +  +--+  +--+     +  +--+
 *      |  |  |  |     |  |     |     |  |     |     |     |  |  |
 *      +--|  |--|     |  |--+  +--+  +--|  +--|  +--+     |  |  |
 *         |  |  |     |  |  |     |     |     |  |        |  |  |
 *      +--+  +--+     +  +--+  +--+     +  +--+  +--+     +  +--+
 *
 *  Tenemos un total de 6 vertices, entre los que tiramos unas lineas (de 1 a 5) por medio de indices.
 *  Al vertice [0], en la esquina inferior izquierda, lo considero como el hipotetico origen de coordenadas (0, 0, 0):
 * 
 *      4--5            (0,y)---(x,y)
 *      |  |    y=kx      |       |
 *      2--3    ===>    (0,y/2)-(x,y/2)
 *      |  |              |       |
 *      0--1            (0,0)---(x,0)
 * 
 *  De esta forma cada numero esta compuesto por una serie de segmentos entre algunos de esos vertices:
 *
 *      4--5  4--5  4--5  4--5  4--5  4  5  4--5  4--5     5  4--5
 *      |  |  |  |     |  |     |     |  |     |     |     |  |  |
 *      2-3|  |23|     |  |2-3  2--3  2-3|  2-3|  2--3     |  |  |
 *         |  |  |     |  |  |     |     |     |  |        |  |  |
 *      0--1  0--1     1  0--1  0--1     1  0--1  0--1     1  0--1
 *
 *      "9": 0--1---5--4-2--3           5 segms.
 *      "8": 0--1---5--4---0, 2--3      5 segms.
 *      "7": 1---5--4                   2 segms.
 *      "6": 5--4---0--1-3--2           5 segms.
 *      "5": 0--1-3--2-4--5             5 segms.
 *      "4": 1---5, 4-2--3              3 segms.
 *      "3": 0--1---5--4,2--3           4 segms.
 *      "2": 1--0-2--3-5--4             5 segms.
 *      "1": 1---5                      1 segms.
 *      "0": 0--1---5--4---0            4 segms.
 * 
 * OJO: Estos numeros solo valen para representar cantidades ENTERAS y POSITIVAS.
 */
class Numbers7SD {

    /** Ancho en metros de cualquier numero. */
    x_: number;

    /** Alto en metros de cualquier numero. */
    y_: number;

    /**
     * Separacion en metros entre 2 numeros consecutivamente graficos.
     * Tambien es la separacion XYZ entre la posicion inicial del primer digito y la respectiva posicion dada.
     */
    spc_: number;
    
    /**
     * Los 6 vertices comunes a todos los 10 numeros, si son comunes los buffAttr entonces ahorramos algo.
     * Si se recrean, \ToDo: Quizas una geometria mas simple por numero sin indices seria mas barata y rapida...
     */
    vVertsBuffAttr_: THREE.Float32BufferAttribute;

    /** Los 10 numeros graficos en [0, 9] metidos en un vector para simplificar. */
    vGObj_: THREE.LineSegments[];

    /** El unico color usado para todos los numeros representados. */
    color_: THREE.Color;

    /**
     * Constructor de los 10 numeros, con una base de dimension dada por xLen y una altura dada por k4Y * xLen, con el
     * color dado.
     * 
     * @param xLen Anchura (X) en metros que ocupa cada numero como maximo (el 1 tambien!!!). Por defecto es 1.
     * @param k4Y Factor multiplicativo de la anchura para dar la altura (Y) de cada numero. Por defecto es 2.
     * @param k4Spc Factor multiplicativo de la anchura para dar la separacion entre 2 numeros. Por defecto es 0.1
     * @param clr Color comun a todos los numeros. Amarillo por defecto.
     * @param isVertical Falso por defecto, sirve para orientar verticalmente los numeros y que se vean bien en beams.
     */
    constructor(xLen = 1.0, k4Y = 2.0, k4Spc = 0.1, clr = 0xffff00, isVertical = false) {
        // Segun los parametros de dimensiones crearemos la geometria.
        const x = xLen;
        const y = xLen * k4Y;

        //     4--5            (0,y)---(x,y)
        //     |  |    y=kx      |       |
        //     2--3    ===>    (0,y/2)-(x,y/2)
        //     |  |              |       |
        //     0--1            (0,0)---(x,0)

        if (!isVertical) {
            // Para que los numeros se puedan ver en el plano XY.
            this.vVertsBuffAttr_ = new THREE.Float32BufferAttribute([
                0, 0, 0,
                x, 0, 0,
                0, 0.5 * y, 0,
                x, 0.5 * y, 0,
                0, y, 0,
                x, y, 0
            ], 3);
        } else {
            // Para que los numeros se puedan ver en vertical, con la z parriba.
            this.vVertsBuffAttr_ = new THREE.Float32BufferAttribute([
                0, 0, 0,
                x, 0, 0,
                0, 0, 0.5 * y,
                x, 0, 0.5 * y,
                0, 0, y,
                x, 0, y
            ], 3);
        }

        this.x_ = x;
        this.y_ = y;
        this.spc_ = x * k4Spc;
        this.color_ = new THREE.Color(clr);

        const vIndices10: number[][] = [
            [0, 1, 1, 5, 5, 4, 4, 0],
            [1, 5],
            [1, 0, 0, 2, 2, 3, 3, 5, 5, 4],
            [0, 1, 1, 5, 5, 4, 2, 3],
            [1, 5, 4, 2, 2, 3],
            [0, 1, 1, 3, 3, 2, 2, 4, 4, 5],
            [5, 4, 4, 0, 0, 1, 1, 3, 3, 2],
            [1, 5, 5, 4],
            [0, 1, 1, 5, 5, 4, 4, 0, 2, 3],
            [0, 1, 1, 5, 5, 4, 4, 2, 2, 3]
        ];

        // Finalmente creo los 10 objetos graficos que multiinstanciaremos.
        this.vGObj_ = [];
        const mat = new THREE.LineBasicMaterial({ color: this.color_, toneMapped: false });
        const voidCasting = () => {};
        for (let i = 0; i < 10; ++i) {
            const geom = new THREE.BufferGeometry();
            geom.setAttribute('position', this.vVertsBuffAttr_);
            geom.setIndex(vIndices10[i]);
            // Creo que no necesito bounding spheres, ya que no intervendra en colisiones.
            // Y podria dar un callback de colisiones vacio.
            const obj3D = new THREE.LineSegments(geom, mat);
            obj3D.raycast = voidCasting;
            this.vGObj_.push(obj3D);
        }
    }

    /**
     * Crea un objeto grafico correspondiente al un numero aleatorio en [0, N).
     * @param N 
     */
    createRandomNumber(N: number, x = 0, y = 0): THREE.Group {
        // N = Math.floor(N * Math.random());
        const grp = new THREE.Group();

        // Forma rapida de pasar de entero positivo a sus digitos individuales y en el orden correcto:
        // 5201 ===> [5, 2, 0, 1].
        const numDigits = Math.log(N) * Math.LOG10E + 1 | 0;

        for (let i = 0; i < numDigits; ++i) {
            const j = numDigits - i - 1;
            const digit = ((N / Math.pow(10, j)) % 10) | 0;
            // console.log(N + " ===> " + digit);
            const obj = this.vGObj_[i];
            grp.add(obj);
            obj.position.x = x;
            obj.position.y = y;
            x += this.x_ + this.spc_;
        }

        return grp;
    }

    static createNumbersField(
        numSet: Numbers7SD,
        vBasePositions: ArrayLike<number>,
        numbersRange?: number | number[]
    ): THREE.Group {
        const cnt = vBasePositions.length / 3;

        let isNumber: boolean;
        let startNum: number = 0;
        if (numbersRange === undefined) {
            isNumber = true;
            startNum = 0;
        } else {
            if (numbersRange.constructor.name === "Number") {
                isNumber = true;
                startNum = numbersRange as number;
            } else {
                if (numbersRange.constructor.name === "Array") {
                    if ((numbersRange as number[]).length) {
                        isNumber = false;
                    } else {
                        // Ante cualquier error tiramos de 0 en adelante.
                        isNumber = true;
                        startNum = 0;
                    }
                } else {
                    isNumber = true;
                    startNum = 0;
                }
            }
        }
        // Representamos una serie de numeros que estan en el rango [startNum, startNum + cnt) y que "posicionamos" en
        // las posiciones base pertinentes. "Posicionamos" en la posicion base dada solo al primer digito (MSB) de cada
        // numero y a los siguientes los ponemos a su derecha donde toque respetando separaciones y anchuras...
        // Al final todo es un grupo compuesto por instancias de los 10 digitos de numSet colocados en las distintas
        // posiciones antes referidas: Posicion base mas posiciones "derechas".

        // Separacion en X entre 2 digitos consecutivos.
        const incX = numSet.x_ + numSet.spc_;

        // Aqui estan las respectivas posiciones para los bloques de cada digito.
        const vPosDigits09: number[][] = [ [], [], [], [], [], [], [], [], [], [] ];

        for (let i = 0; i < cnt; ++i) {
            // El numero en curso.
            const N = isNumber ? i + startNum : (numbersRange as number[])[i];
            // La posicion base del primer digito de este numero, que es la dada externamente.
            // Las posiciones de los restantes digitos se calculan incrementalmente a partir de esta.
            let x = vBasePositions[3 * i];
            let y = vBasePositions[3 * i + 1];
            let z = vBasePositions[3 * i + 2];

            // Meto una separacion para que no se pisen el punto base y la esquina inferior izquierda del primer digito.
            x -= numSet.spc_;
            y += numSet.spc_;
            z += numSet.spc_;

            // \Trick: Para sacar el numero de digitos de un entero positivo. Ojo que para el '0' tendremos un digito.
            const numDigits = N ? Math.log(N) * Math.LOG10E + 1 | 0 : 1;
            for (let j = 0; j < numDigits; ++j) {
                // Esta es la posicion de izquierda (MSB) a derecha (LSB), acabando en 1.
                const k = numDigits - j - 1;
                // Ecce digitum.
                const digit = ((N / Math.pow(10, k)) % 10) | 0;
                // Segun el digito sacamos el morral de posiciones que le toca.
                const vPos = vPosDigits09[digit];

                // Si es el primer digito va en la posicion base.
                if (i === 0) {
                    vPos.push(x, y, z);
                } else {
                    // Si es uno de los digitos restantes, se calcula la posicion.
                    x += incX;
                    vPos.push(x, y, z);
                }
            }
        }

        // Y ahora ya podemos crear las instanciaciones multiples que meteremos en este grupo final.
        const grp = new THREE.Group;
        for (let i = 0; i < 10; ++i) {
            const vPos = vPosDigits09[i];
            if (vPos.length) {
                const geomSrc = numSet.vGObj_[i].geometry as THREE.BufferGeometry;
                const onlyColor = [numSet.color_.r, numSet.color_.g, numSet.color_.b];
                const ojb4DigitI = createMultiInstancedGraphicObject(THREE.LineSegments, geomSrc, vPos, undefined, onlyColor);
                grp.add(ojb4DigitI);
            }
        }

        return grp;
    }

    /**
     * Simple prueba de fuerza bruta para ver cuantas cifras podemos tener simultaneamente en pantalla.
     * @param N 
     * @param numSet 
     * @returns 
     */
    static testPerformance(N: number, numSet: Numbers7SD): THREE.Group {
        const grp = new THREE.Group;
        let x, y, z, w;
        const vector = new THREE.Vector4();
        const offsets = [];

        for (let i = 0; i < N; ++i) {
      
            // offsets
            x = Math.random() * 100 - 50;
            y = Math.random() * 100 - 50;
            z = Math.random() * 100 - 50;
  
            x *= 0.5;
            y *= 0.5;
            z *= 0.5;
        
            vector.set(x, y, z, 0).normalize();
        
            offsets.push(x + vector.x, y + vector.y, z + vector.z);
        }

        return Numbers7SD.createNumbersField(numSet, offsets);
    }
} // class Numbers7SD

/**
 * Ejemplo de creacion de una textura con texto. Sacada de: http://jsfiddle.net/sSD65/28/
 */
function exampleCreateTextureWithText(canvas: HTMLCanvasElement): THREE.Texture {
    if (canvas === null) {
        canvas = document.createElement('canvas') as HTMLCanvasElement;
    }
    const size = 257;
    canvas.width = canvas.height = size;
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    context.font = '20pt Arial';
    context.fillStyle = 'red';
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = 'white';
    context.fillRect(10, 10, canvas.width - 20, canvas.height - 20);
    context.fillStyle = 'black';
    context.textAlign = "center";
    context.textBaseline = "middle";
    const text = "" + (new Date().getTime());
    context.fillText(text, canvas.width / 2, canvas.height / 2);

    context.fillStyle = 'red';
    context.fillRect(0, 0, 1, 1);

    const texture = new THREE.Texture(canvas);
    return texture;
}

/**
 * Sacado de https://threejs.org/manual/#en/billboards
 */
function makeLabelCanvas(baseWidth: number, size: number, text: string): HTMLCanvasElement {
    const borderSize = 2;
    const ctx = document.createElement('canvas').getContext('2d') as CanvasRenderingContext2D;
    const font = `${size}px bold sans-serif`;
    ctx.font = font;
    // measure how long the name will be
    const textWidth = ctx.measureText(text).width;

    const doubleBorderSize = borderSize * 2;
    const width = baseWidth + doubleBorderSize;
    const height = size + doubleBorderSize;
    ctx.canvas.width = width;
    ctx.canvas.height = height;

    // need to set font again after resizing canvas
    ctx.font = font;
    ctx.textBaseline = 'middle';
    ctx.textAlign = 'center';

    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, width, height);

    // scale to fit but don't stretch
    const scaleFactor = Math.min(1, baseWidth / textWidth);
    ctx.translate(width / 2, height / 2);
    ctx.scale(scaleFactor, 1);
    ctx.fillStyle = 'black';
    ctx.fillText(text, 0, 0);

    return ctx.canvas;
}

function makeLabelSprite(labelWidth: number, size: number, text: string): THREE.Sprite {
    const canvas = makeLabelCanvas(labelWidth, size, text);
    const texture = new THREE.CanvasTexture(canvas);
    // Es probable que nuestro canvas no tenga unas dimensiones potencia de 2, por lo que filtramos adecuadamente.
    texture.minFilter = THREE.LinearFilter;
    texture.wrapS = THREE.ClampToEdgeWrapping;
    texture.wrapT = THREE.ClampToEdgeWrapping;

    const labelMaterial = new THREE.SpriteMaterial({ map: texture, transparent: true });
    const labelBaseScale = 0.01;
    const label = new THREE.Sprite(labelMaterial);

    label.scale.x = canvas.width * labelBaseScale;
    label.scale.y = canvas.height * labelBaseScale;

    return label;
}

function createCharacterLabel(text: string): THREE.Sprite {
    const textCanvas = document.createElement('canvas');
    textCanvas.height = 34;
    const ctx = textCanvas.getContext('2d') as CanvasRenderingContext2D;
    const font = '24px grobold';

    ctx.font = font;
    textCanvas.width = Math.ceil(ctx.measureText(text).width + 16);

    ctx.font = font;
    ctx.strokeStyle = '#222';
    ctx.lineWidth = 8;
    ctx.lineJoin = 'miter';
    ctx.miterLimit = 3;
    ctx.strokeText(text, 8, 26);
    ctx.fillStyle = 'white';
    ctx.fillText(text, 8, 26);

    const src = ctx.getImageData(0, 0, textCanvas.width, textCanvas.height);
    const spriteMap = new THREE.Texture(src as unknown as HTMLImageElement);
    spriteMap.minFilter = THREE.LinearFilter;
    spriteMap.generateMipmaps = false;
    spriteMap.needsUpdate = true;

    const sprite = new THREE.Sprite(new THREE.SpriteMaterial({ map: spriteMap }));
    sprite.scale.set(0.12 * textCanvas.width / textCanvas.height, 0.12, 1);
    sprite.position.y = 0.7;

    return sprite;
}

/**
 * Parsea el fichero que nos ha dado JL (meshing_demo_integration_points.dat) con los puntos de integracion que tiene el
 * formato nativo en texto de mecaSalome...
 * Como resultado final se devuelve al exterior un duo de arrays:
 * - El primero contiene un subarray lineal con todos los puntos 3D implicados sacados del fichero.
 * - El segundo contiene otro subarray con entradas de la forma [indiceM*, offset, aridad34] con el indice del elemento
 * shell M*, el offset de sus 3|4 puntos (en el vector lineal anterior) y la aridad 3|4 para tri|quad correspondiente
 * a ese shell. Quede claro que a un shell tri le corresponden 3 puntos y a uno quad 4.
 *
 * @param str 
 * @returns 
 */
function parseIntegrationPointsStr(str: string): [number[], [number, number, number][]] {
    /*
        Los datos leidos que vienen en la cadena son de la forma:


            --------------------------------------------------------------------------------
            CONCEPT   COOR      DE TYPE  CHAM_ELEM                                         


            ------>
            CHAMP PAR ELEMENT AUX POINTS DE GAUSS                                           
            M4292              X                     Y                     Z                     W          
                    1  1.47500000000000E+01  8.00000000000037E-01  2.83917669571040E+00  2.73148148148148E-01
                    2  1.47500000000000E+01  8.00000000000037E-01  2.45833333333333E+00  4.37037037037037E-01
                    3  1.47500000000000E+01  8.00000000000037E-01  2.07748997095627E+00  2.73148148148148E-01
            M4293              X                     Y                     Z                     W          
                    1  1.47500000000000E+01  8.00000000000037E-01  1.85584336237706E+00  2.73148148148148E-01
                    2  1.47500000000000E+01  8.00000000000037E-01  1.47500000000000E+00  4.37037037037037E-01
                    3  1.47500000000000E+01  8.00000000000037E-01  1.09415663762294E+00  2.73148148148148E-01
            M4294              X                     Y                     Z                     W          
                    1  1.47500000000000E+01  8.00000000000037E-01  8.72510029043729E-01  2.73148148148148E-01
                    2  1.47500000000000E+01  8.00000000000037E-01  4.91666666666667E-01  4.37037037037037E-01
                    3  1.47500000000000E+01  8.00000000000037E-01  1.10823304289604E-01  2.73148148148148E-01

        Esas tercetas parecen ser de triangulos, pero tambien hay cuartetos para quads:

            M7783              X                     Y                     Z                     W          
                    1  1.03943373183054E+01  7.10566271342553E+00  3.00000000000000E+00  6.25000000958419E-02
                    2  1.03943373475029E+01  7.39433781646040E+00  3.00000000000000E+00  6.24999994052900E-02
                    3  1.01056621840950E+01  7.39433780281584E+00  3.00000000000000E+00  6.24999997639563E-02
                    4  1.01056621517079E+01  7.10566269812436E+00  3.00000000000000E+00  6.25000004545082E-02

        Lo que no he encontrado son duetos...

    */

    // Estos 2 datos son los que se devuelven al que llama.
    // Aqui se meten temporalmente los datos (x, y, z). Ojo, que es un array lineal X-Y-Z sin las W's.
    const p3D: number[] = [];
    // Aqui para cada M* encontrado metemos su id, el offset donde empiezan sus 3|4 puntos en p3D y su aridad 3|4.
    const vIndOffAri: [number, number, number][] = [];

    let numLine = 0;
    let indElement = -1;
    let intoElement = false;
    let index2Find = '';
    let index = -1;
    let [numQuads, numTris, numBeams, numElements] = [0, 0, 0, 0];

    // Generador de sucesores "1" ===> "2" ===> "3" ===> "4" o "" para los "insucesibles".
    const nextIndex = (n: string): string => {
        if (n === "1") {
            return "2";
        }
        if (n === "2") {
            return "3";
        }
        if (n === "3") {
            return "4";
        }
        return "";
    };

    // Estudiamos los datos.
    const getMinMaxSumatory = (arg: [number, number, number, number]): [number, number, number] => {
        let [x, minX, maxX, accumX] = arg;
        (x < minX) && (minX = x);
        (x > maxX) && (maxX = x);
        accumX += x;
        return [minX, maxX, accumX];
    };

    const initVals = [+Infinity, -Infinity, 0.0];
    let [minX, maxX, avgX] = initVals;
    let [minY, maxY, avgY] = initVals;
    let [minZ, maxZ, avgZ] = initVals;
    let [minW, maxW, avgW] = initVals;
    let numValues = 0;

    const getAveragesLimits4XYZW = (x: number, y: number, z: number, w: number): void => {
        [minX, maxX, avgX] = getMinMaxSumatory([x, minX, maxX, avgX]);
        [minY, maxY, avgY] = getMinMaxSumatory([y, minY, maxY, avgY]);
        [minZ, maxZ, avgZ] = getMinMaxSumatory([z, minZ, maxZ, avgZ]);
        [minW, maxW, avgW] = getMinMaxSumatory([w, minW, maxW, avgW]);

        ++numValues;
    };

    const logMsgMinMaxAvg = (msg: string, arg: [number, number, number]): void => {
        const [minX, maxX, avgX] = arg;
        console.log(`\t${msg}:   [${minX}, ${maxX}]   ${avgX}`);
    };

    let currentIOA: [number, number, number] = [-1, -1, -1];

    for (let line of str.split("\n")) {
        ++numLine;
        line = line.trim();
        if (line === "") {            
            continue;
        }

        // console.log(`[${numLine}] <${line}>`);

        // Solo me interesan las lineas que empiezan por M/1/2/3/4.
        const first = line[0];
        if (!intoElement) {
            if (first === 'M') {
                ++numElements;
                intoElement = true;
                index2Find = '1';
                // Ademas sacamos el indice del elemento que acompaña a la M.
                const items = line.split(/\s+/);
                indElement = +items[0].slice(1);
                // console.log(`[${numElements}] Elem[${indElement}]`);

                // Guardamos indice M*.
                currentIOA[0] = indElement;
                // Guardamos offset donde empiezan sus puntos de integracion.
                currentIOA[1] = p3D.length / 3;
            } else {
                // Podemos pasar de esta linea pues aun no estamos dentro de los datos de un elemento.
                continue;
            }
        } else {
            if (first === index2Find) {
                // Procesamos la linea 1/2/3/4...
                const items = line.split(/\s+/);
                index = +items[0];
                let x = +items[1];
                let y = +items[2];
                let z = +items[3];
                let w = +items[4];
                // console.log(`\t[${index}]  ${x}  ${y}  ${z}  ${w}`);
                if (false) {
                    // Prueba para JoseLuis que no dio resultado efectivo. La W no nos vale para nada.
                    x /= w;
                    y /= w;
                    z /= w;
                }
                // De momento no hacemos nada con la W.
                p3D.push(x, y, z);
                getAveragesLimits4XYZW(x, y, z, w);

                index2Find = nextIndex(index2Find);
                if (index2Find === "") {
                    // Pasaremos a otro elemento...
                    intoElement = false;
                    // ...pero con index ya sabemos que el elemento es un quad.
                    if (index === 4) {
                        // console.log("\t\tQUAD");
                        ++numQuads;
                        currentIOA[2] = 4;
                        // Almacenamos el trio de datos, con una deep-copy.
                        vIndOffAri.push([currentIOA[0], currentIOA[1], currentIOA[2]]);
                        // Y la tupla original la machacamos.
                        currentIOA[0] = currentIOA[1] = currentIOA[2] = -1;
                    }
                }
            } else {
                if (first === 'M') {
                    // Informamos de lo que era el elemento anterior.
                    if (index === 4) {
                        // console.log("\t\tQUAD");
                        currentIOA[2] = 4;
                    } else {
                        if (index === 3) {
                            // console.log("\t\tTRI");
                            currentIOA[2] = 3;
                            ++numTris;
                        } else {
                            if (index === 2) {
                                // console.log("\t\tBEAM");
                                currentIOA[2] = 2;
                                ++numBeams;
                            } else {
                                debugger;
                            }
                        }
                    }

                    // Almacenamos el trio de datos, con una deep-copy.
                    vIndOffAri.push([currentIOA[0], currentIOA[1], currentIOA[2]]);
                    // Y la tupla original la machacamos.
                    currentIOA[0] = currentIOA[1] = currentIOA[2] = -1;

                    ++numElements;
                    index = -1;
                    index2Find = '1';
                    // Ademas sacamos el indice del elemento que acompaña a la M.
                    const items = line.split(/\s+/);
                    indElement = +items[0].slice(1);

                    // Guardamos indice M*.
                    currentIOA[0] = indElement;
                    // Guardamos offset donde empiezan sus puntos de integracion.
                    currentIOA[1] = p3D.length / 3;

                    // console.log(`[${numElements}] Elem[${indElement}]`);
                } else {
                    // Algo raruno pasa aqui...
                    debugger;
                }
            }
        }
    } // for (let line of str.split("\n"))

    console.log(`\n\nElements found: ${numElements} (${numBeams}B + ${numTris}T + ${numQuads}Q)`);
    if (numElements !== numBeams + numTris + numQuads) {
        debugger;
    }

    avgX /= numValues;
    avgY /= numValues;
    avgZ /= numValues;
    avgW /= numValues;

    console.log(`N: ${numValues}`);
    console.log("Limits + averages:");
    logMsgMinMaxAvg("X", [minX, maxX, avgX]);
    logMsgMinMaxAvg("Y", [minY, maxY, avgY]);
    logMsgMinMaxAvg("Z", [minZ, maxZ, avgZ]);
    logMsgMinMaxAvg("W", [minW, maxW, avgW]);

    return [p3D, vIndOffAri];
}

/**
 * Parsea el fichero que nos ha dado PC (meshing_demo_integration_points_new_csv.dat) con los puntos de integracion que
 * viene en formato CSV (similar a la funcion parseIntegrationPointsStr() para formato nativo).
 * Como resultado final se devuelve al exterior un duo de arrays:
 * - El primero contiene un subarray lineal con todos los puntos 3D implicados sacados del fichero.
 * - El segundo contiene otro subarray con entradas de la forma [indiceM*, offset, aridad34] con el indice del elemento
 * shell M*, el offset de sus 3|4 puntos (en el vector lineal anterior) y la aridad 3|4 para tri|quad correspondiente
 * a ese shell. Quede claro que a un shell tri le corresponden 3 puntos y a uno quad 4.
 *
 * @param str 
 */
// function parseIntegrationPointsStr_CSV(str: string): [number[], [number, number, number][]] {
//     /*
//         En este caso los datos leidos son de la forma:

//             ELEMENT,IP,X,Y,Z,W
//             M4292,1,1.47500000000000E+01,8.00000000000037E-01,2.83917669571040E+00,2.73148148148148E-0
//             M4292,2,1.47500000000000E+01,8.00000000000037E-01,2.45833333333333E+00,4.37037037037037E-0
//             M4292,3,1.47500000000000E+01,8.00000000000037E-01,2.07748997095627E+00,2.73148148148148E-0
//             M4293,1,1.47500000000000E+01,8.00000000000037E-01,1.85584336237706E+00,2.73148148148148E-0
//             M4293,2,1.47500000000000E+01,8.00000000000037E-01,1.47500000000000E+00,4.37037037037037E-0
//             M4293,3,1.47500000000000E+01,8.00000000000037E-01,1.09415663762294E+00,2.73148148148148E-0
//             M4294,1,1.47500000000000E+01,8.00000000000037E-01,8.72510029043729E-01,2.73148148148148E-0
//             M4294,2,1.47500000000000E+01,8.00000000000037E-01,4.91666666666667E-01,4.37037037037037E-0
//             M4294,3,1.47500000000000E+01,8.00000000000037E-01,1.10823304289604E-01,2.73148148148148E-0

//         Donde aparecen los puntos de integracion con las 4 coordenadas (X,Y,Z,W) en tripletas o cuartetos segun se trate
//         de triangulos o de quads... El indice del pertinente elemento esta en el M*.
//     */
//     // Estos 2 datos son los que se devuelven al que llama.
//     // Aqui se meten temporalmente los datos (x, y, z). Ojo, que es un array lineal X-Y-Z sin las W's.
//     const p3D: number[] = [];
//     // Aqui para cada M* encontrado metemos su id, el offset donde empiezan sus 3|4 puntos en p3D y su aridad 3|4.
//     const vIndOffAri: [number, number, number][] = [];

//     let numLine = 0;
//     let indElement = -1;
//     let intoElement = false;
//     let index2Find = '';
//     let index = -1;
//     let [numQuads, numTris, numBeams, numElements] = [0, 0, 0, 0];

//     let currentIOA: [number, number, number] = [-1, -1, -1];

//     for (let line of str.split("\n")) {
//         ++numLine;
//         line = line.trim();
//         if (line === "") {            
//             continue;
//         }

//         // console.log(`[${numLine}] <${line}>`);

//         // Solo me interesan las lineas que empiezan por M*, salvo la cabecera inicial en la primera linea disponible.
//         const first = line[0];
//     }

// }

/**
 * Parsea algunos de los ficheros dados por JL (t_2D_EFGA_by_hypo.txt o t_1D_EFGA_by_hypo.txt), que pueden almacenar
 * varias hipotesis o distintas columnas, pero que no cambian estas ultimas para todos los elementos.
 * @param str 
 */
function parseString4SalomeMecaTxt(
                                   str: string,
                                   mElems?: Map<number, [number, number]>
                                  ): [Map<string, Map<number, number[]>>, string[]] | null {
    const lenStr = str.length;
    if (lenStr) {
        console.log(`Parseando cadena con ${lenStr} caracteres...`);
    } else {
        console.error("Cadena vacia!!!.");
        return null;
    }

    // Cuando se da un mapa de elementos finitos externo se pueden comprobar los id de los elementos finitos para ver
    // si se corresponden con lo que tenemos almacenado previamente del json inicial.
    const checkElem = mElems
        ? (id: number, maxIndex: number): boolean => {
            if (mElems.has(id)) {
                const [type234, _] = mElems.get(id) as [number, number];
                if (type234 !== maxIndex) {
                    console.error(`\t ERROR: El elemento M${id} tiene un tipo declarado de ${type234} frente al ${maxIndex} propuesto.`);        
                    return false;
                }
                return true;
            }
            console.error(`\t ERROR: Elemento M${id} inexistente.`);
            return false;
        }
        : (id: number, maxIndex: number): boolean => {
            return true;
        };

    /*
        Los datos en la cadena son de la forma:


            --------------------------------------------------------------------------------
            ASTER 14.04.00 CONCEPT efga1 CALCULE LE 08/03/2022 A 11:31:11 DE TYPE CHAM_ELEM 
            ASTER 14.04.00 CONCEPT efga1 CALCULE LE 08/03/2022 A 11:31:11 DE TYPE CHAM_ELEM 

                                ENTITES TOPOLOGIQUES SELECTIONNEES 
            GROUP_MA :  e_w_6 e_w_7  e_fs_3 e_fs_4 e_fs_5 e_fs_6 e_fs_7 e_w_1 e_w_2 e_w_3   


            ------>
            2D gauss points F for hypothesis SELF_WEIGHT                                    
            M7728       NXX            NYY            NXY            MXX            MYY            MXY        
                        QX             QY         
                    1      3.800E+00      7.693E-01     -1.130E+00     -2.016E+00     -7.330E-01     -3.683E-01
                        -1.180E+00     -5.209E+00
                    2      3.306E+00      6.854E-01     -4.281E-01     -2.695E+00     -8.484E-01     -2.954E-01
                        -5.315E-01     -5.209E+00
                    3      3.019E+00     -1.006E+00     -2.232E-01     -2.725E+00     -1.024E+00     -1.381E-02
                        -5.315E-01     -5.581E+00
                    4      3.513E+00     -9.218E-01     -9.250E-01     -2.046E+00     -9.085E-01     -8.663E-02
                        -1.180E+00     -5.581E+00
            M7729       NXX            NYY            NXY            MXX            MYY            MXY        
                        QX             QY         
                    1      1.930E+00      1.330E+00      1.225E-01     -4.628E+00     -5.088E+00      1.214E+00
                        9.567E-02      7.435E-01
                    2      1.752E+00      1.299E+00      1.716E-01     -4.764E+00     -5.111E+00      1.172E+00
                        2.512E-01      7.435E-01
                    3      1.731E+00      1.181E+00      2.456E-01     -4.747E+00     -5.011E+00      1.228E+00
                        2.512E-01      5.883E-01
                    4      1.910E+00      1.212E+00      1.966E-01     -4.611E+00     -4.987E+00      1.270E+00
                        9.567E-02      5.883E-01
            M7730       NXX            NYY            NXY            MXX            MYY            MXY        
                        QX             QY         
                    1     -3.973E+00      1.100E+00      1.912E+00      3.038E+00      4.332E+00      1.949E+00
                        6.720E+00     -2.788E+00
                    2     -4.020E+00      8.232E-01      2.983E+00      3.084E+00      4.602E+00      1.848E+00
                        6.720E+00     -5.115E+00
                    3     -1.440E+00      1.262E+00      2.868E+00      2.840E+00      4.561E+00      1.960E+00
                        3.884E+00     -5.115E+00
                    4     -1.393E+00      1.539E+00      1.797E+00      2.794E+00      4.291E+00      2.061E+00
                        3.884E+00     -2.788E+00
            ...
            ...
            ...
            M11575      NXX            NYY            NXY            MXX            MYY            MXY        
                QX             QY         
            1      2.535E+00     -2.928E+01      1.013E+00      3.479E-02      2.084E-01      4.004E-02
                -2.801E-02     -6.111E-01
            2      2.066E+00     -2.988E+01      1.387E+00      4.729E-02      2.510E-01      2.326E-02
                -5.040E-02     -5.834E-01
            3      2.267E+00     -3.037E+01      1.442E+00      5.026E-02      2.925E-01      3.135E-02
                -8.093E-02     -6.325E-01
            4      2.738E+00     -2.982E+01      1.081E+00      3.824E-02      2.536E-01      4.830E-02
                -5.651E-02     -6.592E-01
  

            --------------------------------------------------------------------------------
            ASTER 14.04.00 CONCEPT efga2 CALCULE LE 08/03/2022 A 11:31:11 DE TYPE CHAM_ELEM 
            ASTER 14.04.00 CONCEPT efga2 CALCULE LE 08/03/2022 A 11:31:11 DE TYPE CHAM_ELEM 

                                ENTITES TOPOLOGIQUES SELECTIONNEES 
            GROUP_MA :  e_w_6 e_w_7  e_fs_3 e_fs_4 e_fs_5 e_fs_6 e_fs_7 e_w_1 e_w_2 e_w_3   


            ------>
            2D gauss points F for hypothesis DEAD_LOAD                                      
            M7728       NXX            NYY            NXY            MXX            MYY            MXY        
                        QX             QY         
                    1      3.283E+00      6.459E-01     -9.333E-01     -2.189E+00     -1.008E+00     -5.271E-01
                        -5.613E-01     -3.233E+00
                    2      2.865E+00      5.749E-01     -3.510E-01     -2.488E+00     -1.059E+00     -5.052E-01
                        -3.511E-02     -3.233E+00
                    3      2.627E+00     -8.282E-01     -1.777E-01     -2.497E+00     -1.111E+00     -3.811E-01
                        -3.511E-02     -3.667E+00
                    4      3.044E+00     -7.572E-01     -7.600E-01     -2.198E+00     -1.061E+00     -4.030E-01
                        -5.613E-01     -3.667E+00
            M7729       NXX            NYY            NXY            MXX            MYY            MXY        
                        QX             QY         
                    1      1.696E+00      1.102E+00      9.752E-02     -3.762E+00     -3.077E+00      3.089E-01
                        2.641E-01      8.079E-01
                    2      1.553E+00      1.077E+00      1.384E-01     -3.639E+00     -3.056E+00      2.974E-01
                        2.989E-01      8.079E-01
                    3      1.536E+00      9.789E-01      1.977E-01     -3.634E+00     -3.029E+00      2.460E-01
                        2.989E-01      7.729E-01
                    4      1.679E+00      1.003E+00      1.568E-01     -3.758E+00     -3.050E+00      2.576E-01
                        2.641E-01      7.729E-01
            ...
        El fichero tiene 16.6 Mb, con 236000 lineas y 6 hipotesis.
    */

    const t0 = performance.now();

    // Para simplificar el procesado de la cadena, que es la ejecucion de un AFD, rompemos el string original en un
    // vector de lineas no vacias individuales que iremos recorriendo con la posibilidad de saltar adelante o volver
    // hacia atras en funcion del estado del automata.
    const vLines = str.split("\n");
    const cntLines = vLines.length;
    // Ahorra memoria!!!.
    str = "";
    for (let i = 0; i < cntLines; ++i) {
        vLines[i] = vLines[i].trim();
    }

    let numLine = 0;
    // Flag para saber cuando estamos dentro de una hipotesis.
    let intoHypo = false;
    let currHypo = "";
    let cntHypo = 0;
    const txtHypo = "hypothesis";
    const lenHypo = txtHypo.length;

    /*
        Linea dentro de un elemento M* de la hipotesis en curso, que va de 1 a...
        [-1] ------>
        [0] 2D gauss points F for hypothesis SELF_WEIGHT                                    
        [1] M7728       NXX            NYY            NXY            MXX            MYY            MXY        
        [2]            QX             QY         
        [3]        1      3.800E+00      7.693E-01     -1.130E+00     -2.016E+00     -7.330E-01     -3.683E-01
        [4]            -1.180E+00     -5.209E+00
        [5]        2      3.306E+00      6.854E-01     -4.281E-01     -2.695E+00     -8.484E-01     -2.954E-01
        [6]            -5.315E-01     -5.209E+00
        [7]        3      3.019E+00     -1.006E+00     -2.232E-01     -2.725E+00     -1.024E+00     -1.381E-02
        [8]            -5.315E-01     -5.581E+00
        [9]        4      3.513E+00     -9.218E-01     -9.250E-01     -2.046E+00     -9.085E-01     -8.663E-02
        [10]           -1.180E+00     -5.581E+00
        [1] M7729       NXX            NYY            NXY            MXX            MYY            MXY        
        [2]            QX             QY         
        ...
    */
    let indice1234 = -1;
    // Flag para saber cuando estamos dentro de un elemento.
    let intoElem = false;
    // Contador de elementos para la hipotesis en curso.
    let cntElem = 0;
    // Indice que aparece a la derecha de la M***. Es el indice externo del nodo en curso.
    let indElem = -1;
    // Contador de TODOS los elementos para todas las hipotesis.
    let cntAllElems = 0;
    // Aqui meteremos los titulos de las columnas para el elemento en curso, que sacamos de sus lineas 1 y 2.
    let vColsI: string[] = [];
    // VALOR DEVUELTO [2/2]:
    // Almacenamos las primeras columnas encontradas para verificar posibles cambios, pues deben ser siempre iguales y
    // este es el valor que devolveremos en segunda posicion.
    let vColumns: string[] = [];
    let numColumns = 0;

    // Para ver que las columnas leidas son siempre las mismas.
    const checkColumns = (): boolean => {
        const lenI = vColsI.length;
        if (lenI != numColumns) {
            return false;
        }
        for (let i = 0; i < numColumns; ++i) {
            if (vColsI[i] !== vColumns[i]) {
                return false;
            }
        }
        return true;
    };

    // VALOR DEVUELTO [1/2]:
    // Los datos leidos van en un mapa indexado por las cadenas de las hypotheses y con valores otros mapas que a su vez
    // son indexados por los numeros de los elementos y con valores finales unos arrays numericos lineales con los valores
    // de las columnas recopilados para esos elementos.
    const mHyp = new Map<string, Map<number, number[]>>();
    // Valores temporales que usaremos para rellenar en cada momento los valores asociados a cada clave de mHyp.
    let val4Hyp: Map<number, number[]>;
    // Idem para los valores asociados a cada clave de val4Hyp.
    let vVal4Hyp: number[];
    
    // Array lineal donde meteremos todos los valores encontrados.
    const vValues: number[] = [];
    // Indice de la linea en el array de lineas vLines.
    let iLine = 0;
    // Para evitar salida a consola y acelerar.
    const letsDebug = true;

    // Avanza hasta cargar los datos de la siguiente hipotesis. En caso de no encontrarla devuelve -1.
    const advance2Hypo = (): number => {
        while (iLine < cntLines) {
            const lineI = vLines[iLine];

            // Buscamos la cadena para ver si estamos en hipotesis.
            let pos = lineI.indexOf(txtHypo);
            if (-1 === pos) {
                vLines[iLine] = "";
                ++iLine;
            } else {
                // Sacamos la hipotesis actual.
                ++cntHypo;
                intoHypo = true;
                currHypo = lineI.slice(pos + lenHypo + 1);
                letsDebug && console.log(`\t [${cntHypo}] Current hypothesis: "${currHypo}" in line [${iLine + 1}/${cntLines}]`);
                // A partir de la siguiente linea nos vamos a buscar los diferentes componentes del elemento.
                indice1234 = 0;
                vLines[iLine] = "";
                return iLine + 1;
            }
        }
        return -1;
    };

    let line = "";
    // Modifica iLine y line hasta llegar a un valor de linea no vacio.
    // En caso de salirse del rango devolvemos false.
    const advance2NextLine = (): boolean => {
        while (iLine < cntLines) {
            line = vLines[iLine];
            if (line !== "") {
                return true;
            }
            ++iLine;
        }
        // Si devuelve false es que nos salimos de madre.
        return false;
    };

    // BUCLE PRINCIPAL.
    while (iLine < cntLines) {
        if (!advance2NextLine()) {
            break;
        }

        if (!intoHypo) {
            iLine = advance2Hypo();
            if (-1 === iLine) {
                console.error("ERROR: No se ha encontrado una hipotesis.");
                return null;
            } else {
                // Continuaremos en el principio de los datos de la hypo, pero primero creamos la entrada donde meteremos
                // los datos que vayamos recopilando para la hypotesis, que no puede estar repetida.
                letsDebug && console.log(`\tNueva HIPOTESIS "${currHypo}"`);
                if (mHyp.has(currHypo)) {
                    console.error(`ERROR: Hipotesis repetida "${currHypo}".`);
                    return null;
                }
                val4Hyp = new Map<number, number[]>();
                // A partir de este momento iremos metiendo valores en val4Hyp hasta encontrar la siguiente hypotesis.
                mHyp.set(currHypo, val4Hyp);
                cntElem = 0;
                continue;
            }
        }

        letsDebug && console.log(`[${iLine}] <${line}>`);

        // Llegados aqui estamos por cojones dentro de una hipotesis.
        // Obligatoriamente hay que empezar por M mas el numero de elemento seguido de ciertas columnas.
        if (!intoElem) {
            if (line[0] === 'M') {
                intoElem = true;
                // Saquemos el indice de elemento, el M***.
                let vItems = line.split(/\s+/);
                indElem = +vItems[0].slice(1);
                let numItems = vItems.length;
                for (let i = 1; i < numItems; ++i) {
                    vColsI.push(vItems[i]);
                }
                ++cntAllElems;
                ++cntElem;
                letsDebug && console.log(`\tENCONTRADO ELEMENTO [M${indElem}] DE LA HIPOTESIS ACTUAL "${currHypo}"`);
                letsDebug && console.log(`[${cntAllElems}/${cntElem}] ELEM${indElem}`);

                // Creamos entrada para los datos que vamos a recopilar para este elemento, que NO debe estar repetido.
                val4Hyp = mHyp.get(currHypo) as Map<number, number[]>;
                if (val4Hyp.has(indElem)) {
                    console.error(`ERROR: El elemento M${indElem} esta repetido!!!.`);
                    return null;
                }
                vVal4Hyp = [];
                val4Hyp.set(indElem, vVal4Hyp);

                // Ya estamos en un elemento y a esta linea la podria suceder o bien:
                // - El indice 1 con los datos...
                // - O bien podria haber una segunda linea con mas columnas...
                // Asi que pre-miramos la siguiente linea...
                vLines[iLine] = "";
                advance2NextLine();
                vItems = line.split(/\s+/);
                let moreColumns = false;
                if (vItems[0] !== '1') {
                    // Quedan mas columnas que recolectar.
                    numItems = vItems.length;
                    for (let i = 0; i < numItems; ++i) {
                        vColsI.push(vItems[i]);
                    }
                    moreColumns = true;
                }
                    
                // Si es la primera vez rellenamos las columnas finales canonicas, sino comprobamos si hay cambios
                // en las columnas respecto a las canonicas.
                if (numColumns) {
                    if (!checkColumns()) {
                        console.error("ERROR: Diferente disposicion de columnas!!!.");
                        return null;
                    }        
                } else {
                    for (const col of vColsI) {
                        vColumns.push(col);
                    }
                    numColumns = vColumns.length;
                    if (!numColumns) {
                        console.error("ERROR: No hay columnas???");
                        return null;
                    }
                    letsDebug && console.log(`Columnas[${numColumns}] = [${vColumns}]`);
                }
                    
                // Toca buscar este grupo de valores a continuacion.
                indice1234 = 1;
                vColsI.length = 0;

                if (!moreColumns) {
                    continue;
                }
            } else {
                debugger;
            }
        } else {
            // Aqui vamos a por los N bloques de numColumns datos presentes en el elemento.
            // Debemos encontrar en la linea actual el primer elemento con la cadena numLineElem, y en la siguiente
            // linea podria haber mas datos hasta completar el numColums.
            let vItems = line.split(/\s+/);

            if (vItems[0][0] === 'M') {
                const numBlocks = indice1234 - 1;
                letsDebug && console.log(`Acabado el elemento M${indElem} con ${numBlocks} bloques de datos.`);
                checkElem(indElem, numBlocks);
                indice1234 = -1;
                intoElem = false;
                continue;
            }

            if (vItems[0] === "" + indice1234) {
                // Aqui metemos los valores numericos asociados a la i-esima entrada del elemento en curso.
                // Su cardinal es obviamente el numero de columnas.
                const vNumVals4I: number[] = [];
                let numItems = vItems.length;
                for (let i = 1; i < numItems; ++i) {
                    vNumVals4I.push(+vItems[i]);
                }

                // Pasamos a la linea siguiente, si es que no hemos completado el numero de datos.
                if (vNumVals4I.length < numColumns) {
                    vLines[iLine] = "";
                    advance2NextLine();
                    vItems = line.split(/\s+/);
                    numItems = vItems.length;
                    for (let i = 0; i < numItems; ++i) {
                        vNumVals4I.push(+vItems[i]);
                    }                    
                }

                if (vNumVals4I.length !== numColumns) {
                    console.error("ERROR: Numero de valores no coincidente con el de columnas.");
                    return null;
                }
                
                letsDebug && console.log(`Valores[${indice1234}] = [${vNumVals4I}]`);
                // Guardamos los valores numericos encontrados en el array para el elemento, que es LINEAL.
                val4Hyp = mHyp.get(currHypo) as Map<number, number[]>;
                vVal4Hyp = val4Hyp.get(indElem) as number[];
                vVal4Hyp.push(...vNumVals4I);
                // Por si acaso.
                vNumVals4I.length = 0;
                // Busquemos el siguiente indice.
                ++indice1234;
            } else {
                // Atencion: Nos hemos podido salir del elemento en curso, pero saliendonos a una nueva hipotesis.
                intoHypo = false;
                intoElem = false;
                continue;
            }
        }

        // Pasamos a la linea siguiente, pero borramos la actual para ahorrar.
        vLines[iLine] = "";
        ++iLine;
    }

    const t1 = performance.now();
    console.log(`[PARSING TIME] ${(t1 - t0).toFixed(3)} milliseconds.`);
    console.log(`Lines: ${cntLines}   Length: ${lenStr}`);

    // Comprobaciones finales que se pueden obviar.
    if (false) {
        // Que todas las lineas originales han sido procesadas y por tanto vaciadas.
        let nonEmpty = 0;
        for (let i = 0; i < cntLines; ++i) {
            const line = vLines[i];
            if (line.length) {
                console.log(`Lin[${i}/${cntLines}] "${line}"`);
                ++nonEmpty;
            }
        }
        if (nonEmpty) {
            debugger;
        }
        // Que los indices de elementos son los mismos para todas las hipotesis.
        // Sacamos la primera hipotesis, y de ella su vector de elementos.
        const vHypsAsKeys = [...mHyp.keys()];
        const hypFirstKey = vHypsAsKeys[0];
        const firstElemsAsValue = mHyp.get(hypFirstKey) as Map<number, number[]>;
        const vElemsIndicesFirst = [...firstElemsAsValue.keys()];
        const cardFirst = vElemsIndicesFirst.length;

        const checkElementsIndices = (v: number[]): boolean => {
            const lenI = v.length;
            if (cardFirst != lenI) {
                return false;
            }
            for (let i = 0; i < cardFirst; ++i) {
                if (v[i] !== vElemsIndicesFirst[i]) {
                    return false;
                }
            }
            return true;
        }

        // Recorremos para comprobar igualdad.
        let numErrors = 0;
        for (const [key, value] of mHyp) {
            if (key !== hypFirstKey) {
                const vElemsIndices = [...value.keys()];
                if (!checkElementsIndices(vElemsIndices)) {
                    console.error(`Diferentes indices de elementos en la hipotesis "${key}".`);
                    ++numErrors;
                }
            }
        }
        if (numErrors) {
            debugger;
        }

    }

    // Ahorremos memoria, ayudando al GC.
    vLines.length = 0;

    // Recuerda que devolvemos tanto los datos como las columnas (estas ultimas comunes a todo e inmutables).
    return [mHyp, vColumns];
}

function parseBinary4SalomeMecaHDF(dataBin: Uint8Array): void {
    debugger;
}

/**
 * Shader supersimplificado para cantidades masivas de puntos CUADRADOS, compartiendo todos el mismo tamaño y color.
 * Puede con 10 millones de puntos a unos 30 FPS.
 * @param size4Point Numero con el tamaño del punto/pixel cuadrado. Positivo no nulo y puede no ser entero.
 * @param color Vector 4D con el formato RGBA de componentes en [0, 1].
 * @returns El material basado en shader necesario para la representacion de una nube de puntos cuadrados.
 */
function createShaderMaterial4SquaredPoints(size4Point: number, color: THREE.Vector4): THREE.RawShaderMaterial {
    // ATENCION: Ademas la posicion del punto, se usan OBLIGATORIAMENTE las matrices de vision y proyeccion.
    // En caso contrario no se va a ver un cagao!!!.
    const material = new THREE.RawShaderMaterial({
        vertexShader: `
          attribute vec3 position;
          uniform float size4Point;
          uniform mat4 modelViewMatrix;
          uniform mat4 projectionMatrix;

          void main(){
            gl_PointSize = size4Point;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
          }`,

        fragmentShader: `
          precision highp float;
          uniform vec4 extColor4;

          void main() {
            gl_FragColor = extColor4;
          }`,

        uniforms: {
            'size4Point': { value: size4Point },
            'extColor4': { value: color },
        },
      });

    return material;
}

/**
 * Shader 'avanzado' para cantidades masivas de puntos REDONDOS, compartiendo todos el mismo tamaño y color.
 * Es 'avanzado' ya que los puntos redondos no estan contemplados en Three.js!!!. Informacion adaptada de:
 * https://www.desultoryquest.com/blog/drawing-anti-aliased-circular-points-using-opengl-slash-webgl/
 * Es bastante mas costoso que el caso de los puntos cuadrados.
 *
 * @param size4Point Numero con el tamaño del punto/pixel redondo. Positivo no nulo y puede no ser entero.
 * @param color Vector 4D con el formato RGBA de componentes en [0, 1].
 * @returns El material basado en shader necesario para la representacion de una nube de puntos redondos.
 */
function createShaderMaterial4RoundPoints(size4Point: number, color: THREE.Vector4): [THREE.RawShaderMaterial, any] {
    // ATENCION: Ademas la posicion del punto, se usan OBLIGATORIAMENTE las matrices de vision y proyeccion.
    // En caso contrario no se va a ver un cagao!!!.
    // Creo recordar que los shader's son "a la std-C" en el sentido de la obligatoriedad de declarar las variables al
    // principio de cada bloque (y no como "a la C++" donde los puedes intercalar donde quieras).
    const params = {
        vertexShader: `
          attribute vec3 position;
          uniform float size4Point;
          uniform mat4 modelViewMatrix;
          uniform mat4 projectionMatrix;

          void main(){
            gl_PointSize = size4Point;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
          }`,

        fragmentShader: `
          precision mediump float;
          uniform vec4 extColor4;

          void main() {
            vec2 cxy = 2.0 * gl_PointCoord - 1.0;
            float r = dot(cxy, cxy);
            float alpha = 1.0;
            if (r > 1.0) {
                discard;
            }
            gl_FragColor = extColor4 * (alpha);
          }`,

        uniforms: {
            'size4Point': { value: size4Point },
            'extColor4': { value: color },
        }
    };

    const material = new THREE.RawShaderMaterial(params);

    return [material, params.uniforms];
}

export function testNewShaders4Points(scene: THREE.Scene, whichOne = 1): void {
    const N = 1000000;
    const vP3D: number[] = [];
    const [dimX, dimY, dimZ] = [100, 200, 400];

    for (let i = 0; i < N; ++i) {
        let x = dimX * (Math.random() - 0.5);
        let y = dimY * (Math.random() - 0.5);
        let z = dimZ * (Math.random() - 0.5);
        vP3D.push(x, y, z);
    }

    const geometry = new THREE.BufferGeometry();
    const pointsBA = new Float32Array(vP3D);
    const buffAttrib4Pos = new THREE.Float32BufferAttribute(pointsBA, 3).setUsage(THREE.DynamicDrawUsage);
    geometry.setAttribute('position', buffAttrib4Pos);
    geometry.computeBoundingSphere();

    // Con este shader se puede dar el tamaño y el color UNICOS para la ingente cantidad de puntos.
    const size4Point: number = 10.0;
    const color4 = new THREE.Vector4(1.0, 1.0, 0.0, 1.0);
    let material: THREE.RawShaderMaterial;

    if (whichOne === 1) {
        material = createShaderMaterial4SquaredPoints(size4Point, color4);
    } else {
        [material, ] = createShaderMaterial4RoundPoints(size4Point, color4);
    }

    const points = new THREE.Points(geometry, material);
    scene.add(points);
}

/**
 * Creamos una LUT/palette, es decir una gama de colores para la rueda de colores HSV, sacada de:
 * https://en.wikipedia.org/wiki/Wikipedia:WikiProject_Color/Normalized_Color_Coordinates
 * @returns
 */
function createLUT4HSVColorWheel(): Lut {
    const lut = new Lut();
    // Colores sacados de https://en.wikipedia.org/wiki/Web_colors
    const vColors = [
        'red',          // [0] 0
        'orange',       // [1] 1/12
        'yellow',       // [2] 2/12
        'chartreuse',   // [3] 3/12
        'green',        // [4] 4/12
        'springgreen',  // [5] 5/12
        'cyan',         // [6] 6/12
        'azure',        // [7] 7/12
        'blue',         // [8] 8/12
        'violet',       // [9] 9/12
        'magenta',      // [10] 10/12
        0xFF0080        // [11] 11/12 'rose'
                        // [12] 12/12 1.0 'red'
    ];

    lut.setMin(0.0);
    lut.setMax(1.0);
    const colorRange: [number, number][] = [];
    let value01 = 0;
    const incVal01 = 1 / 12;
    for (const colorData of vColors) {
        const color = new THREE.Color(colorData);
        colorRange.push([value01, color.getHex()]);
        value01 += incVal01;
    }
    // Finalmente volvemos a meter el rojo al principio para un bucle sin fin...
    colorRange.push([1.0, colorRange[0][1]]);

    lut.addColorMap('wheelHSV', colorRange);
    lut.setColorMap('wheelHSV', 100);

    return lut;
}

/**
 * Crea una paleta de B&W.
 * @returns 
 */
function createLUT4BlackWhite(): Lut {
    const lut = new Lut();
    lut.setMin(0.0);
    lut.setMax(1.0);

    lut.addColorMap('B&W', [[0.0, 0x000000], [1.0, 0xFFFFFF]]);
    lut.setColorMap('B&W', 100);

    return lut;
}

/**
 * Crea una paleta LUT de estilo Salome-Meca, que a fe mia tiene este aspecto:
 *      Maximo[+1]: Rojizo #B40426 (180, 4, 38).
 *      Medio[0]: Grisaceo #DDDDDD (221, 221, 221).
 *      Minimo[-1]: Azulete #3B4CC0 (59, 76, 192).
 * 
 * Ojo, que las paletas hasta ahora usadas van en [0, +1], asi que cuidado. Ademas esta paleta es CONTINUA.
 */
function createLUT4SalomeMeca(): Lut {
    const lut = new Lut();

    lut.setMin(0.0);
    lut.setMax(+1.0);

    lut.addColorMap('Salome-Meca', [[0.0, 0x3B4CC0], [0.5, 0xDDDDDD], [+1.0, 0xB40426]]);
    lut.setColorMap('Salome-Meca', 100);

    return lut;
}

/**
 * Construye la paleta discreta aparentemente usada en Salome-Meca, que es RETICULADA con 15 colores.
 * @returns
 */
function createLUT4SalomeMecaDiscrete(): Lut {
    const lut = new Lut();

    lut.setMin(0.0);
    lut.setMax(+1.0);

    const inc = 1.0 / 15.0;
    const d = 0.0001;
    const data = [
        [0 * inc, 0x3b4cc0], [1 * inc - d, 0x3b4cc0],
        [1 * inc, 0x516bda], [2 * inc - d, 0x516bda],
        [2 * inc, 0x6889ee], [3 * inc - d, 0x6889ee],
        [3 * inc, 0x81a4fa], [4 * inc - d, 0x81a4fa],
        [4 * inc, 0x9abaff], [5 * inc - d, 0x9abaff],
        [5 * inc, 0xb2ccfb], [6 * inc - d, 0xb2ccfb],
        [6 * inc, 0xc9d8ef], [7 * inc - d, 0xc9d8ef],
        [7 * inc, 0xdddddd], [8 * inc - d, 0xdddddd],
        [8 * inc, 0xedd1c2], [9 * inc - d, 0xedd1c2],
        [9 * inc, 0xf6bfa6], [10 * inc - d, 0xf6bfa6],
        [10 * inc, 0xf7a889], [11 * inc - d, 0xf7a889],
        [11 * inc, 0xf08b6d], [12 * inc - d, 0xf08b6d],
        [12 * inc, 0xe26a53], [13 * inc - d, 0xe26a53],
        [13 * inc, 0xce433b], [14 * inc - d, 0xce433b],
        [14 * inc, 0xb40426], [1.0, 0xb40426]        
    ];

    lut.addColorMap('Salome-Meca-Discrete', data);
    lut.setColorMap('Salome-Meca-Discrete', 100);

    return lut;
}


/**
 * Para saber si un array de numeros es consecutivo, es decir que sus elementos son todos incrementalmente sacados del
 * primero sumandole la posicion: [N, N+1, N+2, ... , N+I, ..., N+LastPos].
 * @param v 
 * @returns 
 */
function isConsecutiveArray(v: ArrayLike<number>): boolean {
    const N = v.length;
    if (!N) {
        return false;
    }

    let prev = v[0];
    for (let pos = 1; pos < N; ++pos) {
        const val = v[pos];
        if (prev + 1 !== val) {
            return false;
        }
        prev = val;
    }
    return true;
}

/// Comparador de arrays numericos, pero que es idoneo cuando los datos de ambos NO estan ordenados.
/// Devuelve true si ambos arrays contienen exactamente los mismos valores (y en las mismas cantidades).
/// No reordena ni requiere creaciones gigantes de memoria.
function compareArraysNoOrder(vA: number[], vB: number[]): boolean {
    const lenA = vA.length;
    let lenB = vB.length;
    if (lenA !== lenB) {
        return false;
    }

    // Creamos un vector auxiliar de disponibles en B, con sus mismas dimensiones.
    const availB = Array(lenA).fill(true);

    // Recorremos cada item de A...
    for (let i = 0; i < lenA; ++i) {
        const a = vA[i];

        // ... intentandolo comparar con cada item DISPONIBLE de B.
        let j = 0;
        for (; j < lenA; ++j) {
            if (!availB[j]) {
                continue;
            }

            const b = vB[j];
            if (a === b) {
                availB[j] = false;
                --lenB;
                break;
            }
        }

        // Si hemos llegado hasta aqui si encontrar 'a', esta claro que no esta en vB.
        if (j === lenA) {
            return false;
        }
    }

    // Llegados aqui, hemos encontrado todos los elementos de vA en vB, y como son del mismo tamaño entonces son iguales.
    return true;
}

/**
 * Comparador de arrays de cualquier tipo, pero que esten en el mismo orden y con los mismos elementos.
 * Devuelve true si son iguales y false si son diferentes.
 *
 * @param a 
 * @param b 
 * @returns 
 */
function compareArraysOrdered(a: any[], b: any[]): boolean {
    const lenA = a.length;
    const lenB = b.length;
    if (lenA !== lenB) {
        return false;
    }

    for (let i = 0; i < lenA; ++i) {
        if (a[i] !== b[i]) {
            return false;
        }
    }

    return true;
}

/// Comparador de arrays ROTADOS. Digamos que 2 arrays se consideran rotados cuando tienen exactamente los mismos elementos,
/// pero permutados como si se hubieran rotado a izquierda o derecha un cierto numero de veces.
/// a = { m, n, o, p, q, r, s, t }
/// b = { n, o, p, q, r, s, t, m } << Lleva una rotacion a la izquierda respecto a 'a'.
/// c = { t, m, n, o, p, q, r, s } >> Lleva una rotacion a la derecha respecto a 'a'. 
function compareArraysRotated(a: any[], b: any[]): number {
    if (compareArraysNoOrder(a, b)) {
        if (compareArraysOrdered(a, b)) {
            return 0;
        }
        // Son iguales en cuanto a elementos, pero en distinto orden, asi que vemos si hay rotacion a izquierda (-) o a
        // la derecha (+) y cuanta (si es que la hay).
        const N = a.length;
        // Los elementos de A estan respectivamente en las posiciones 0...N-1. Veamos las posiciones relativas en B.
        const posB: number[] = [];
        for (const item of a) {
            posB.push(b.indexOf(item));
        }

        // Calcula el sucesor circular.
        const succ = (n: number) => {
            if (n === N - 1) {
                return 0;
            } else {
                return n + 1;
            }
        };
        let prev = posB[N - 1];
        for (const item of posB) {
            if (item === succ(prev)) {
                prev = item;
            } else {
                return -1;
            }
        }

        return posB[0];
    }

    // No hay rotacion.
    return -1;
}

/**
 * Dados 2 arrays A y B que contienen exactamente los mismos elementos, (sin repeticiones y ambos con el mismo numero de
 * elementos), aunque posiblemente en distinto orden, se devuelve como resultado un array con los indices de las posiciones
 * de los elementos de B con respecto a las posiciones originales en A.
 * En caso de ser exactamente iguales el array estara vacio para denotarlo.
 * Por ejemplo:
 * A = [10, 20, 30, 40]
 * B = [10, 20, 30, 40] ===> Se devuelve [] pues son EXACTAMENTE iguales en orden.
 * C = [40, 30, 20, 10] ===> Se devuelve [3, 2, 1, 0].
 * @param a 
 * @param b 
 * @returns 
 */
function getCombination(a: any[], b: any[]): number[] {
    const res: number[] = [];

    if (compareArraysOrdered(a, b)) {
        return res;
    }

    // Recuerda: Se supone que B es un shuffle de A.
    for (const elemB of b) {
        res.push(a.indexOf(elemB));
    }

    return res;
}

/**
 * Dado un triangulo t y un punto p se devuelven las coordenadas (lambda0, lambda1, lambda2) del punto con respecto a los
 * vertices del triangulo.
 * @param t 
 * @param p 
 * @returns 
 */
function getBarycentricCoords4TrianglePoint3D(t: THREE.Triangle, p: THREE.Vector3): THREE.Vector3 {
    const vBC = new THREE.Vector3();
    t.getBarycoord(p, vBC);
    return vBC;
}

/**
 * Para comprobar que las coordenadas indican la contencion efectiva dentro de un triangulo.
 * @param v 
 * @returns 
 */
function isContainedBarycentric3(v: THREE.Vector3): boolean {
    const sum = v.x + v.y + v.z;
    const Eps = 0.000001;    
    if (Math.abs(sum - 1.0) < Eps) {
        const A = -Eps;
        const B = 1.0 + Eps;
        if (A < v.x && v.x < B) {
            if (A < v.y && v.y < B) {
                if (A < v.z && v.z < B) {
                    return true;
                }
            }
        }
    }
    return false;
}

/**
 * Para comprobar que las coordenadas indican la contencion efectiva dentro de un quad.
 * @param v 
 * @returns 
 */
function isContainedBarycentric4(v: THREE.Vector4): boolean {
    // Optimizo poniendo primero el caso mas general.
    // Ademas de estar todas en [0, 1], su suma debe ser exactamente 1.
    const sum = v.x + v.y + v.z + v.w;
    const Eps = 0.000001;    
    if (Math.abs(sum - 1.0) < Eps) {
        const A = -Eps;
        const B = 1.0 + Eps;
        if (A < v.x && v.x < B) {
            if (A < v.y && v.y < B) {
                if (A < v.z && v.z < B) {
                    if (A < v.w && v.w < B) {
                        return true;
                    }
                }
            }
        }
    }
    return false;
}

/**
 * Dado un quad Q, CONVEXO y COPLANAR, y un punto P, se nos devuelve un vector con las 4 coordenadas barycentricas del mismo respecto
 * a los 4 vertices del quad. En caso de error por no estar en el plano se devuelve un vector nulo.
 *
 * @param Q 
 * @param P 
 */
function getBarycentricCoords4QuadPoint3D(
    Q: [THREE.Vector3, THREE.Vector3, THREE.Vector3, THREE.Vector3],
    P: THREE.Vector3
): THREE.Vector4 {
    const [A, B, C, D] = Q;
    const vBC4 = new THREE.Vector4();

    // [1] Despreciar puntos que esten separados del plano del quad. A distinta altura cuando son planos horizontales
    // o bien con cierta separacion cuando son verticales...
    // Sabemos que los 4 vertices del quad son coplanares, luego con los 3 primeros calculamos el plano y comprobamos
    // que el punto p cae en ese plano...
    const plane3D = new THREE.Plane();
    plane3D.setFromCoplanarPoints(A, B, C);
    // ...viendo si la distancia del punto al plano es 0.
    const distPoint2Plane = plane3D.distanceToPoint(P);
    const Eps = 0.000001;
    if (Math.abs(distPoint2Plane) >= Eps) {
        // No cae, luego devolvemos vector nulo para informar de no contencion.
        return vBC4;
    }

    // [2] La tecnica de las Mean Value Coordinates parece no soportar coordenadas exteriores al quad, asi que hacemos
    // un filtrado previo del punto respecto a los 2 triangulos en que podemos romper el quad. Si no pertenece a ninguno
    // no tiene sentido seguir.

    // Del quad Q sabemos que es coplanar, asi que rompemos el quad en los 2 triangulos.
    const tABC = new THREE.Triangle(A, B, C);
    const tACD = new THREE.Triangle(A, C, D);

    // Calculamos las coordenadas baricentricas del punto respecto a los triangulos.
    const bcABC = getBarycentricCoords4TrianglePoint3D(tABC, P);
    const bcACD = getBarycentricCoords4TrianglePoint3D(tACD, P);

    const isP_inABC = isContainedBarycentric3(bcABC);
    const isP_inACD = isContainedBarycentric3(bcACD);
    // El punto podria estar en uno u otro triangulo de los 2 y ser valido.
    if (!isP_inABC && !isP_inACD) {
        return vBC4;
    }

    // [3] Lo que nos sirve de puta madre para el calculo final: Mean Value Coordinates. Sacado de:
    // https://www.slideserve.com/tomasso/a-quadrilateral-rendering-primitive
    // Tenemos el punto P y los 4 vertices A, B, C y D.
    // Calculamos los vectores del punto P a los 4 vertices: PA, PB, PC y PD.
    // Calculamos los 4 angulos entre esos 4 vectores: angPA, angPB, angPC y angPD.
    // Para cada vertice calculamos:
    //
    //      Mu (v) = (tan(0.5 * ang(i-1)) + tan(0.5 * ang(i))) / rad(i)
    //        i
    //
    // Y finalmente los lambda de P se calculan como:
    //
    //      Lambda (v) = Mu (v) / Sumatory (Mu(j))
    //            i        i             j=0:3

    // [a] Los 4 vectores.
    const PA = new THREE.Vector3().subVectors(A, P);
    const PB = new THREE.Vector3().subVectors(B, P);
    const PC = new THREE.Vector3().subVectors(C, P);
    const PD = new THREE.Vector3().subVectors(D, P);
    // [b] Sus longitudes.
    const lenPA = PA.length();
    const lenPB = PB.length();
    const lenPC = PC.length();
    const lenPD = PD.length();
    // Si alguna de las 4 longitudes anteriores es 0, se debiera devolver el pertinente 1 y demas 0's.
    if (lenPA < Eps) {
        vBC4.x = 1.0;
        return vBC4;
    }
    if (lenPB < Eps) {
        vBC4.y = 1.0;
        return vBC4;
    }
    if (lenPC < Eps) {
        vBC4.z = 1.0;
        return vBC4;
    }
    if (lenPD < Eps) {
        vBC4.w = 1.0;
        return vBC4;
    }
    // [c] Los angulos entre el punto y sus vertices adyacentes, asi como las tangentes.
    const angAB = PA.angleTo(PB);
    const angBC = PB.angleTo(PC);
    const angCD = PC.angleTo(PD);
    const angDA = PD.angleTo(PA);
    const tanA = Math.tan(0.5 * angAB);
    const tanB = Math.tan(0.5 * angBC);
    const tanC = Math.tan(0.5 * angCD);
    const tanD = Math.tan(0.5 * angDA);
    // [d] Los coef. mu y su suma total.
    const muA = (tanD + tanA) / lenPA;
    const muB = (tanA + tanB) / lenPB;
    const muC = (tanB + tanC) / lenPC;
    const muD = (tanC + tanD) / lenPD;
    const sumMu = muA + muB + muC + muD;
    // Los 4 lambda finales, uno respecto a cada vertice.
    const lambdaA = muA / sumMu;
    const lambdaB = muB / sumMu;
    const lambdaC = muC / sumMu;
    const lambdaD = muD / sumMu;

    // Comprobacion de que todo es correcto:
    // P === lambdaA * A + lamdbaB * B + lambdaC * C + lamdbaD * D;
    const vRes = new THREE.Vector3().addScaledVector(A, lambdaA);
    vRes.addScaledVector(B, lambdaB);
    vRes.addScaledVector(C, lambdaC);
    vRes.addScaledVector(D, lambdaD);
    vRes.sub(P);
    if (vRes.length() >= Eps) {
        console.error("ERROR: Divergencia entre P y P'");
        debugger;
        return vBC4;
    }
    
    vBC4.x = lambdaA;
    vBC4.y = lambdaB;
    vBC4.z = lambdaC;
    vBC4.w = lambdaD;
    console.log(`\tBC4 ===> (${vBC4.x}, ${vBC4.y}, ${vBC4.z}, ${vBC4.w})`);

    return vBC4;
}

function testEquations(): boolean {
    debugger;
    const Eps = 0.000001;
    const M = new THREE.Matrix3();
    // Con la fmc set() se dan las componentes en orden de COLUMNAS (por incognitas). Asi pues para este ejemplo:
    //  3x + 2y + 1z = 1
    //  2x + 0y + 1z = 2
    // -1x + 1y + 2z = 4
    // Hariamos esto:        
    const a = 3, b = 2, c = 1, k0 = 1;
    const d = 2, e = 0, f = 1, k1 = 2;
    const g = -1, h = 1, i = 2, k2 = 4;
    // Recuerda por coeficientes de X primero, luego Y, etc...
    M.set(a, d, g,
          b, e, h,
          c, f, i);

    const B = new THREE.Vector3(k0, k1, k2);
    const resX = resolve3EquationsByCramer(M, B);
    if (resX) {
        const [x, y, z] = [resX.x, resX.y, resX.z];
        console.log(`Solucion: X = ${x}   Y = ${y}   Z = ${z}`);
        // Comprobacion:
        const val0 = a * x + b * y + c * z;
        const val1 = d * x + e * y + f * z;
        const val2 = g * x + h * y + i * z;
        let numFails = 0;
        if (Math.abs(val0 - k0) >= Eps) {
            ++numFails;
            console.error("ERROR val0");
        }
        if (Math.abs(val1 - k1) >= Eps) {
            ++numFails;
            console.error("ERROR val1");
        }
        if (Math.abs(val2 - k2) >= Eps) {
            ++numFails;
            console.error("ERROR val2");
        }
        if (numFails === 0) {
            return true;
        }
    } else {
        console.error("ERROR: Sistema incompatible???.");
    }

    return false;
}

/**
 * Resolucion de un sistema de 3 ecuaciones con 3 incognitas de la forma:
 *      B0 = x * A00 + y * A01 + z * A22
 *      B1 = x * A10 + y * A11 + z * A22
 *      B2 = x * A20 + y * A11 + z * A22
 * Es decir que se puede colocar como una multiplicacion de una MATRIZ (3*3) por un VECTOR COLUMNA dando como resultado
 * otro vector columna, en la forma:
 *      A * X = B
 * Donde:
 *      A es matriz (3 * 3) de coeficientes de las incognitas
 *      X es el vector (3) columna de las incognitas, que es el resultado que devolveremos.
 *      B el vector columna (3) de los terminos independientes.
 * Devolvemos el vector columna X con los valores de las 3 incognitas o bien null en caso de error.
 *
 * @param A 
 * @param B 
 * @returns 
 */
function resolve3EquationsByCramer(A: THREE.Matrix3, B: THREE.Vector3): THREE.Vector3 | null {
    // Ojo que para que el sistema de ecuaciones sea compatible determinado, el determinante de A no puede ser nulo.
    const Eps = 0.000001;
    const DetA = A.determinant();
    if (Math.abs(DetA) < Eps) {
        console.error(`ERROR: Incompatible equations system: Det(A) = ${DetA}`);
        return null;
    }

    // Aqui meteremos las soluciones.
    const X = new THREE.Vector3();

    // Guardamos los valores anteriores de A para las sustituciones.
    const prev = new THREE.Vector3(A.elements[0], A.elements[3], A.elements[6]);

    // Para resolver la primera incognita, se sustituye la columna primera de A por el vector B.
    A.elements[0] = B.x;
    A.elements[3] = B.y;
    A.elements[6] = B.z;

    let detX = A.determinant();
    X.x = detX / DetA;

    // Y asi sucesivamente para las otras columnas, restaurando los valores previos.
    A.elements[0] = prev.x;
    A.elements[3] = prev.y;
    A.elements[6] = prev.z;

    prev.x = A.elements[1];
    prev.y = A.elements[4];
    prev.z = A.elements[7];

    A.elements[1] = B.x;
    A.elements[4] = B.y;
    A.elements[7] = B.z;
    
    detX = A.determinant();
    X.y = detX / DetA;

    // And the last one.
    A.elements[1] = prev.x;
    A.elements[4] = prev.y;
    A.elements[7] = prev.z;

    // prev.x = A.elements[2];
    // prev.y = A.elements[5];
    // prev.z = A.elements[8];

    A.elements[2] = B.x;
    A.elements[5] = B.y;
    A.elements[8] = B.z;
    
    detX = A.determinant();
    X.z = detX / DetA;

    return X; 
} 

/**
 * Para saber si los 4 puntos que forman un quad son coplanares.
 * @param q 
 */
function isCoplanarQuad(q: [THREE.Vector3, THREE.Vector3, THREE.Vector3, THREE.Vector3]): boolean {
    // Con los 3 primeros determinamos el plano y vemos si el ultimo esta en ese plano...
    const [A, B, C, D] = q;
    const plane3D = new THREE.Plane();
    plane3D.setFromCoplanarPoints(A, B, C);
    // ...viendo si la distancia al mismo es 0.
    const distPoint2Plane = plane3D.distanceToPoint(D);
    const Eps = 0.000001;
    if (Math.abs(distPoint2Plane) < Eps) {
        return true;
    }

    return false;
}

/**
 * Dado el quadrilatero entre los vertices (3D) A-->B-->C-->D (con D continuando por A):
 * 
 *                              A-----------B
 *                              |           |
 *                              |           |
 *                              D-----------C
 * 
 * Nos dice si este es CONVEXO (es decir que NO presenta entrantes o salientes excesivos).
 * Suponemos el orden de los vertices coherente, ya sea CW|CCW y que no es complejo: Sus aristas no se cruzan.
 * 
 * @param A
 * @param B 
 * @param C 
 * @param D 
 */
function isConvexQuadABCD(A: THREE.Vector3, B: THREE.Vector3, C: THREE.Vector3, D: THREE.Vector3): boolean {
    // Euler's quadrilateral theorem:
    // https://en.wikipedia.org/wiki/Euler%27s_quadrilateral_theorem
    // Longitudes de las 4 aristas (AB, BC, CD y DA) elevadas al cuadrado.
    const aa = A.distanceToSquared(B);
    const bb = B.distanceToSquared(C);
    const cc = C.distanceToSquared(D);
    const dd = D.distanceToSquared(A);
    // Longitudes de las 2 diagonales (AC y BD), tambien elevadas al cuadrado.
    const ee = A.distanceToSquared(C);
    const ff = B.distanceToSquared(D);
    // Puntos medios de las diagonales anteriores.
    const midAC = new THREE.Vector3().lerpVectors(A, C, 0.5);
    const midBC = new THREE.Vector3().lerpVectors(B, D, 0.5);
    // Distancia entre ambos medios de las diagonales, al cuadrado.
    const gg = midAC.distanceToSquared(midBC);
    // Suma de los cuadrados de las longitudes de las 4 aristas.
    const sumEdgesSq = aa + bb + cc + dd;
    // Suma de los cuadrados de las longitudes de las diagonales mas 4 por la distancia (sq) entre sus centros.
    const sumDiagsSq = ee + ff + 4 * gg;
    // Segun el teorema de Euler-Pitagoras ambas sumas deben coincidir.
    const Eps = 0.000001;
    if (Math.abs(sumEdgesSq - sumDiagsSq) < Eps) {
        return true;
    }

    return false;
}

/**
 * Calculos de interseccion 3D entre un rayo de origen y direccion dado y un triangulo dados sus 3 vertices 3D.
 * En caso de no haber interseccion se devuelve un array vacio, pero en caso contrario se devuelven las 3 coordenadas
 * baricentricas correspondientes al punto como un array de 3 valores numericos.
 *
 * @param orig 
 * @param dir 
 * @param v0 
 * @param v1 
 * @param v2 
 * @returns 
 */
function rayTriangleIntersect(orig: THREE.Vector3, dir: THREE.Vector3, v0: THREE.Vector3, v1: THREE.Vector3, v2: THREE.Vector3): P3D | [] {
    let t: number;
    let u: number;
    let v: number;
    const kEpsilon: number = 1e-8;

    // Compute plane's normal.
    const v0v1 = new THREE.Vector3().subVectors(v1, v0); // v1 - v0;
    const v0v2 = new THREE.Vector3().subVectors(v2, v0); // v2 - v0;
    // No need to normalize.
    const N = v0v1.cross(v0v2); // N 
    const denom = N.dot(N);
 
    // Step 1: Finding P:
    // Check if ray and plane are parallel.
    const NdotRayDirection = N.dot(dir); 
    if (Math.abs(NdotRayDirection) < kEpsilon) {
        // Almost 0: they are parallel so they don't intersect!.
        return [];
    }
 
    // Compute d parameter using equation 2.
    const d = N.dot(v0); 
 
    // Compute t (equation 3).
    t = (N.dot(orig) + d) / NdotRayDirection;
    // Check if the triangle is in behind the ray.
    if (t < 0) {
        // The triangle is behind.
        return [];
    }
 
    // Compute the intersection point using equation 1.
    const P = new THREE.Vector3().addVectors(orig, new THREE.Vector3().addScaledVector(dir, t));
 
    // Step 2: Inside-outside test:
    // vector perpendicular to triangle's plane 
    let C: THREE.Vector3;
 
    // Edge 0;
    const edge0 = new THREE.Vector3().subVectors(v1, v0);   // v1 - v0
    const vp0 = new THREE.Vector3().subVectors(P, v0);      // P - v0
    C = edge0.cross(vp0); 
    if (N.dot(C) < 0) {
        // P is on the right side 
        return [];
    }
 
    // Edge 1.
    const edge1 = new THREE.Vector3().subVectors(v2, v1);   // v2 - v1
    const vp1 = new THREE.Vector3().subVectors(P, v1);      // P - v1
    C = edge1.cross(vp1); 
    if ((u = N.dot(C)) < 0) {
        // P is on the right side.
        return [];
    }
 
    // Edge 2.
    const edge2 = new THREE.Vector3().subVectors(v0, v2);   // v0 - v2
    const vp2 = new THREE.Vector3().subVectors(P, v2);      // P - v2
    C = edge2.cross(vp2); 
    if ((v = N.dot(C)) < 0) {
        // P is on the right side.
        return [];
    }
 
    u /= denom;
    v /= denom;
 
    // This ray hits the triangle & the shit hits the fan.
    return [t, u, v];
}

