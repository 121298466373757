import { IObjData } from "lib/models/objdata";
import { cadOpType } from "lib/operations/factory";

export enum EditActionType {
  ADD_OBJ,
  DELETE_OBJ,
  EDIT_OBJ,
  LOCK_OBJ,
  VISIBILITY_OBJ,
  CHANGELAYER_OBJ,
}

export type editObjAction = {
  type: EditActionType.ADD_OBJ;
  payload: {
    objsAdded: IObjData[],
  };
} | {
  type: EditActionType.DELETE_OBJ;
  payload: {
    objsDeleted: IObjData[],
  };
} | {
  type: EditActionType.EDIT_OBJ;
  payload: {
    opType: cadOpType,
    objsEdited: IObjData[],
  };
} | {
  type: EditActionType.LOCK_OBJ;
  payload: {
    objData: IObjData,
  };
} | {
  type: EditActionType.VISIBILITY_OBJ;
  payload: {
    objData: IObjData,
  };
} | {
  type: EditActionType.CHANGELAYER_OBJ;
  payload: {
    oldLayer: number,
    objData: IObjData,
  };
};