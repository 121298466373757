import cn from "classnames";

export interface ToastData {
  title?: string;
  description?: string;
  status?: "success" | "error" | "warning";
  duration?: number;
  isClosable?: boolean;
}

interface Props {
  onClose: () => void;
}
const Toast: React.FC<ToastData & Props> = ({
  status = "success",
  description,
  title,
  isClosable = true,
  onClose,
}) => {
  const rootClassName = cn("rounded-md p-4", {
    "bg-green-500": status === "success",
    "bg-red-500": status === "error",
    "bg-yellow-500": status === "warning",
  });
  return (
    <div className="fixed inset-x-0 bottom-0 p-4 w-full max-w-xl mx-auto z-50">
      <div className={rootClassName}>
        <div className="flex">
          <div className="flex-shrink-0">
            {/*  <svg
              className="h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg> */}
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-white">{title}</p>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                className="inline-flex bg-transparent rounded-md p-1.5 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                onClick={onClose}
              >
                {isClosable && <span className="sr-only">Dismiss</span>}
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Toast;
