import { addIpoint, copyIPoint, getFactorLinePosition2p2D, getPointOnSegment, normalizeIpoint, substractIpoint } from "lib/math/point";
import { IPoint, ISegment } from "lib/math/types";
import { cadOpType } from "../factory";
import { settingsOpModes } from "../step-operations";
import { Cad3dOp } from "../base";
import { cloneDataModel } from "lib/models/model-creator/datamodel-factory";
import { WallData } from "lib/models/structural/wall";
import { WallEditDataCommand } from "lib/commands/structural/wall";
import { vector3EqualZero } from "lib/math/epsilon";
import { wallParam } from "lib/models-struc/types/wall";
import { getEdgePolylineFromIndex } from "lib/math/line";
import { rotatePointZ } from "lib/math/rotate";

export class WallMoveVertexOP extends Cad3dOp {

  public opType = cadOpType.EDITWALL;
  protected objData: WallData;
  protected auxCol: WallData;

  private indexToEdit: number;
  private basePoint: IPoint;
  private editedPoint: IPoint;

  constructor(data: WallData, indexToEdit: number) {
    super();
    this.indexToEdit = indexToEdit;
    this.objData = data;
    this.basePoint = copyIPoint(data.definition.basePoint);
    this.editedPoint = addIpoint(this.basePoint, data.definition.ptos2D.points[indexToEdit]);
  }

  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Edit vertex: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
    }]);
  }

  public async start() {
    this.iniSettingsOp();
    this.initializeSnap();
    this.initializeWorkingPlane();
    this.registerCancel();
    this.registerInputs();
    this.registerUpdaters();

    this.auxCol = cloneDataModel(this.objData);    
    this.setAuxObj(this.auxCol.graphicObj)
    this.saveToTempScene(this.auxCol.graphicObj);

    const planeManager = this.graphicProcessor.getPlaneManager();
    planeManager.activePlane.position = this.indexToEdit === 0 ? this.basePoint : this.editedPoint;
    planeManager.activePlane.rotation = { x: 0, y: 0, z: 0 };
    planeManager.activePlane.locked = true;
  }

  public setLastPoint(): void {
    this.save();
    this.endOperation();
  }

  public moveLastPoint(pto: IPoint) {
    const oriDef = this.objData.definition;
    const def = this.auxCol.definition;
    if (this.indexToEdit === 0) {
      // Move basePoint
      for (let i = 1; i < oriDef.ptos2D.points.length; i++) {
        const absPto = addIpoint(this.basePoint, oriDef.ptos2D.points[i]);
        def.ptos2D.points[i] = substractIpoint(absPto, pto);
      }
      // Move holes
      this.moveStretch(oriDef, def, 0);
      if (oriDef.ptos2D.isClosed) {
        this.moveStretch(oriDef, def, oriDef.stretch.length - 1);
      }

      this.checkClosedGeometry(def);
      this.auxCol.definition.basePoint = pto;
      this.auxCol.regenerateDefinition();
    } else {
      // Move point
      def.ptos2D.points[this.indexToEdit] = substractIpoint(pto, this.basePoint);
      // Move holes
      this.moveStretch(oriDef, def, this.indexToEdit - 1);
      this.moveStretch(oriDef, def, this.indexToEdit);
      
      this.checkClosedGeometry(def);
      this.auxCol.regenerateDefinition();
    }
  }

  private moveStretch(oriDef: wallParam, def: wallParam, indexEdge: number) {
    const defEdge = getEdgePolylineFromIndex(def.ptos2D, indexEdge) as ISegment;
    if (oriDef.stretch[indexEdge].holes.length) {
      const oriEdge = getEdgePolylineFromIndex(oriDef.ptos2D, indexEdge) as ISegment;
      const defHoles = def.stretch[indexEdge].holes;
      const oriHoles = oriDef.stretch[indexEdge].holes;
      for (let i = 0; i < oriHoles.length; i++) {
        const hole = oriHoles[i];
        for (let j = 0; j < hole.points.length; j++) {
          const z = defHoles[i].points[j].z;
          const f = getFactorLinePosition2p2D(oriEdge.p1, oriEdge.p2, hole.points[j])!;
          defHoles[i].points[j] = getPointOnSegment(defEdge.p1, defEdge.p2, f);
          defHoles[i].points[j].z = z;
        }
      }
    }
    const xVec = normalizeIpoint(substractIpoint(defEdge.p2, defEdge.p1));
    def.stretch[indexEdge].normal = rotatePointZ(xVec, -Math.PI * 0.5);
  }

  private checkClosedGeometry(def: wallParam) {
    def.ptos2D.isClosed = this.objData.definition.ptos2D.isClosed;
    if (!def.ptos2D.isClosed && vector3EqualZero(def.ptos2D.points[def.ptos2D.points.length - 1])) {
      def.ptos2D.points.pop();
      def.ptos2D.isClosed = true;
    }
  }

  public save() {
    const newDefinition = this.auxCol.definition;
    const command = new WallEditDataCommand(this.objData, newDefinition, null, this.graphicProcessor);
    this.graphicProcessor.storeAndExecute(command);
  }

  public cancelOperation(): void {
    if (!this.finished) {
      this.endOperation();
    }
  }
}