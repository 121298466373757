import { ISolidMaterial } from "lib/materials";
import { copyIPoint } from "lib/math/point";
import { objDataType } from "lib/models/types";
import { GraphicProcessor } from "../../graphic-processor";
import { IPoint } from "../../math/types";
import { RegionData } from "../../models/solids/region";
import { regionParam } from "../../geometries/solid/region";
import { cadOpType } from "../../operations/factory";
import { createCommand } from "./create-base";

export class RegionCommand extends createCommand {

  public createdData: RegionData;
  protected opType = cadOpType.REGION;
  protected objType = objDataType.REGION;
  protected param: regionParam;
  protected material: ISolidMaterial;

  constructor(points2D: IPoint[], basePoint: IPoint, rotation: IPoint, offset: IPoint, scale: IPoint, layerId: string, graphicProc: GraphicProcessor, material: ISolidMaterial) {
    super(layerId, graphicProc);
    this.param = {
      ptos2D: points2D.map(copyIPoint),
      basePoint: copyIPoint(basePoint),
      rotation: copyIPoint(rotation),
      offset: copyIPoint(offset),
      scale: copyIPoint(scale),
    };
    this.material = material;
  }
}