import { getRelativePoint } from "lib/coordinates/plane";
import { getAngleBetweenAzimuts, lineAzimut2p, ORIENT } from "./angles";
import { getStartEndPointArc, arcParam, circleGetCenter2pR, getRadiusFromSagittaChord, getSagittaFromChordRadius } from "./arc";
import { vectorDist2D } from "./distance";
import { IArcLineParam, IPolylineParam } from "./line";
import { addIpoint } from "./point";
import { IPoint } from "./types";


export function strectchLineObj(def: IPolylineParam, parts: number[], vector: IPoint) {
  for (const ind of parts) {
    const p0 = def.points[ind - 1];
    const p1 = def.points[ind];
    const p3 = def.points[ind + 1];
    const newPto = addIpoint(p1, vector);

    const prev = parts.indexOf(ind - 1) !== -1;
    const next = parts.indexOf(ind + 1) !== -1;

    if (def.arcs[ind - 1] && !prev) {
      const arc = (def.arcs[ind - 1] as IArcLineParam);
      const s = stretchP1Arc(newPto, p0, p1, arc.radius, arc.direction);
      if (s) {
        arc.radius = s.radius;
        arc.center = s.center;
      }
    }
    if (def.arcs[ind] && !next) {
      const arc = (def.arcs[ind] as IArcLineParam);
      const s = stretchP3Arc(newPto, p1, p3, arc.radius, arc.direction);
      if (s) {
        arc.radius = s.radius;
        arc.center = s.center;
      }
    }
    if (def.arcs[ind] && next) {
      const arc = (def.arcs[ind] as IArcLineParam);
      arc.center = addIpoint(arc.center, vector);
    }
    def.points[ind] = newPto;
  }
}

export function strectchArcObj(def: arcParam, parts: number[], vector: IPoint): arcParam | undefined {
  const direction = def.angleCenter > 0 ? ORIENT.CW : ORIENT.CCW;
  const [p0, p1] = getStartEndPointArc(def);
  if (parts[0] === 0) {
    const newPto = addIpoint(p0, vector);
    const newDef = stretchP3Arc(newPto, p0, p1, def.radius, direction, def.plane);
    if (newDef) return newDef;

  } else if (parts[0] === 1) {
    const newPto = addIpoint(p1, vector);
    const newDef = stretchP1Arc(newPto, p0, p1, def.radius, direction, def.plane);
    if (newDef) return newDef;
  }
}
export function stretchP1Arc(pto: IPoint, p0: IPoint, p1: IPoint, radius: number, direction: ORIENT, plane: IPoint = { x: 0, y: 0, z: 0 }): arcParam | null {
  const chord = vectorDist2D(p0, p1);
  const h = getSagittaFromChordRadius(radius, chord);
  const newChord = vectorDist2D(pto, p0);
  const newRadius = getRadiusFromSagittaChord(h, newChord);
  const centers = circleGetCenter2pR(p0, pto, newRadius);
  if (centers) {
    let center;
    if (newChord * 0.5 > h) {
      center = direction === ORIENT.CW ? centers[0] : centers[1];
    } else {
      center = direction === ORIENT.CW ? centers[1] : centers[0];
    }
    const v1 = getRelativePoint(p0, center, plane);
    const v3 = getRelativePoint(pto, center, plane);
    const azimutO = lineAzimut2p({ x: 0, y: 0, z: 0 }, v1);
    const azimutEnd = lineAzimut2p({ x: 0, y: 0, z: 0 }, v3);
    let angleCenter = getAngleBetweenAzimuts(azimutO, azimutEnd, direction);
    if (direction === ORIENT.CCW) angleCenter *= -1;
    return { angleCenter, azimutO, center, plane, radius: newRadius };

  }
  return null;
}
export function stretchP3Arc(pto: IPoint, p0: IPoint, p1: IPoint, radius: number, direction: ORIENT, plane: IPoint = { x: 0, y: 0, z: 0 }): arcParam | null {
  const chord = vectorDist2D(p0, p1);
  const h = getSagittaFromChordRadius(radius, chord);
  const newChord = vectorDist2D(pto, p1);
  const newRadius = getRadiusFromSagittaChord(h, newChord);
  const centers = circleGetCenter2pR(pto, p1, newRadius);
  if (centers) {
    let center;
    if (newChord * 0.5 > h) {
      center = direction === ORIENT.CW ? centers[0] : centers[1];
    } else {
      center = direction === ORIENT.CW ? centers[1] : centers[0];
    }
    const v1 = getRelativePoint(pto, center, plane);
    const v3 = getRelativePoint(p1, center, plane);
    const azimutO = lineAzimut2p({ x: 0, y: 0, z: 0 }, v1);
    const azimutEnd = lineAzimut2p({ x: 0, y: 0, z: 0 }, v3);
    let angleCenter = getAngleBetweenAzimuts(azimutO, azimutEnd, direction);
    if (direction === ORIENT.CCW) angleCenter *= -1;
    return { angleCenter, azimutO, center, plane, radius: newRadius };
  }
  return null;
}