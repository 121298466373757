import { GraphicProcessor } from "lib/graphic-processor";
import { objDataType } from "lib/models/types";
import { heightType } from "lib/models-struc/types/struc-base";
import { columnParam } from "lib/models-struc/types/column";
import { Storey } from "lib/models-struc/struc-storey";
import { cadOpType } from "lib/operations/factory";
import { createCompoundStructuralElementCommand } from "./struc-compound";
import { IColumnSettings } from "lib/operations/structural/column";
import { EditBaseStructCommand } from "./struc-edit-base";
import { ColumnData } from "lib/models/structural/column";

export class ColumnCommand extends createCompoundStructuralElementCommand<columnParam> {

  protected opType = cadOpType.COLUMN;
  protected objType = objDataType.COLUMN;
  protected elemType: objDataType.COLUMN = objDataType.COLUMN;
  protected structuralParam: columnParam[];
  public createdObjs: ColumnData[] = [];

  constructor(colParams: IColumnSettings[], graphicProc: GraphicProcessor) {
    super(graphicProc);

    this.structuralParam = [];

    const strucMng = this.graphicProcessor.getStructuralModelManager();
    const currBuilding = strucMng.currBuilding;
    const storeys = currBuilding.storeys;

    for (const colParam of colParams) {
      if (colParam.heightType === heightType.STOREY) {
        const z = colParam.basePoint.z;
        const storey = currBuilding.getStoreyFromLevel(z);
        if (storey.height >= colParam.height) {
          const colStruParam = this.buildParams(storey, colParam, colParam.height, z);
          this.structuralParam.push(colStruParam);
          continue;
        }
      }

      let startLevel = colParam.basePoint.z;
      let finalLevel = startLevel - colParam.height;
      if (startLevel < finalLevel) {
        [finalLevel, startLevel] = [startLevel, finalLevel]
      }
      const totalHeight = startLevel - finalLevel;

      const iniStorey = currBuilding.getStoreyFromLevel(startLevel);
      let i = storeys.indexOf(iniStorey);

      let currLevel = startLevel;
      while (currLevel > finalLevel) {
        const storey = storeys[i];
        let lowerLevel = storey.level - storey.height;
        if (lowerLevel < finalLevel) lowerLevel = finalLevel;
        const colHeight = totalHeight > storey.height ? currLevel - lowerLevel : totalHeight;
        const colStruParam = this.buildParams(storey, colParam, colHeight, currLevel);
        this.structuralParam.push(colStruParam);

        i -= 1;
        const nextStorey = storeys[i];
        if (nextStorey === undefined) break;
        currLevel = nextStorey.level;
      }
    }
  }

  private buildParams(storey: Storey, colParam: IColumnSettings, height: number, level: number): columnParam {
    return {
      name: "_" + storey.name,
      lnkObjIds: [],
      materialType: storey.columnConcreteType,
      heightType: colParam.heightType,
      storeyId: storey.id,

      height: height,
      orientation: 0,
      crossSectionId: colParam.crossSectionId,

      basePoint: { ...colParam.basePoint, z: level },
      rotation: { ...colParam.rotation },
      offset: { ...colParam.offset },
      scale: { ...colParam.scale },
    };
  }
}

export class ColumnEditDataCommand extends EditBaseStructCommand {
  protected opType = cadOpType.EDITCOLUMN;
}
