
export class MemoryCentinel {
  /** Tamaño maximo del heap JS (en bytes) disponible para el contexto. */
  private _jsHeapSizeLimit: number;

  /** Tamaño total del heap JS asignado, en bytes. */
  private _totalJSHeapSize: number;

  /** Tamaño en bytes del segmento actualmente activo del heap JS. */
  private _usedJSHeapSize: number;

  public update = (): boolean => {
    // @ts-ignore
    const jsHeapSizeLimitNow = window.performance.memory.jsHeapSizeLimit;
    // @ts-ignore
    const totalJSHeapSizeNow = window.performance.memory.totalJSHeapSize;
    // @ts-ignore
    const usedJSHeapSizeNow = window.performance.memory.usedJSHeapSize;

    const inc0 = jsHeapSizeLimitNow - this._jsHeapSizeLimit;
    const inc1 = totalJSHeapSizeNow - this._totalJSHeapSize;
    const inc2 = usedJSHeapSizeNow - this._usedJSHeapSize;

    this._jsHeapSizeLimit = jsHeapSizeLimitNow;
    this._totalJSHeapSize = totalJSHeapSizeNow;
    this._usedJSHeapSize = usedJSHeapSizeNow;

    if (inc0) {
      console.log("\t GB jsHeapSizeLimitNow: " + (this._jsHeapSizeLimit / 1073741824.0).toFixed(2) + " (Inc. " + (inc0 / 1073741824.0).toFixed(2) + " GB)");
    }
    if (inc1) {
      console.log("\t MB totalJSHeapSizeNow: " + (this._totalJSHeapSize / 1048576.0).toFixed(2) + " (Inc. " + (inc1 / 1048576.0).toFixed(2) + " MB)");
    }
    if (inc2) {
      console.log("\t MB usedJSHeapSizeNow:  " + (this._usedJSHeapSize / 1048576.0).toFixed(2) + " (Inc. " + (inc2 / 1024.0).toFixed(2) + " KB)");
    }

    return inc0 !== 0 || inc1 !== 0 || inc2 !== 0;
  };

  constructor() {
    // La extension podria no estar disponible.
    // @ts-ignore
    if (!window.performance.memory) {
      window.alert("La extension performance.memory no esta disponible.");
      console.error("No estamos usando Chrome???.");
      console.error("O bien falta incluir en el fichero launch.json la entrada:");
      console.error('"runtimeArgs": ["--enable-precise-memory-info"]');
      this._jsHeapSizeLimit = -1;
      this._totalJSHeapSize = -1;
      this._usedJSHeapSize = -1;
      this.update = (): boolean => {
        return false;
      };
    } else {
      this._jsHeapSizeLimit = 0;
      this._totalJSHeapSize = 0;
      this._usedJSHeapSize = 0;
      console.log("\n\n AVAILABLE MEMORY IN THE NAVIGATOR:");
      console.log("==============================================================================");
      this.update();
      console.log("==============================================================================\n\n");
    }
  }
}
