import { ArcEditDataCommand } from "lib/commands/edition/arc";
import { setPosBuffer } from "lib/geometries";
import { arcBuffer3p, arcCreate } from "lib/geometries/arc";
import { lineAddVertex, lineAuxCreate, lineMoveVertex } from "lib/geometries/line";
import { pointCreate } from "lib/geometries/point";
import { getAngleBetweenAzimuts, lineAzimut2p, normalizeAngle, ORIENT } from "lib/math/angles";
import { arc, arcParam, circleGetCenter3p, getArcDirectionFrom3p } from "lib/math/arc";
import { vectorDist3D } from "lib/math/distance";
import { copyIPoint } from "lib/math/point";
import { IPoint } from "lib/math/types";
import { ArcData } from "lib/models/primitives/arc";
import { SimpleEdition } from "../edition";
import { cadOpType } from "../factory";
import { settingsOpModes } from "../step-operations";

export enum editArcParameters { FIRSTPOINT, SECONDPOINT, THIRDPOINT }

export class ArcEditOP extends SimpleEdition {

  public opType = cadOpType.EDITARC;

  private objData: ArcData;
  private param: editArcParameters;
  private firstPoint: IPoint;
  private secondPoint: IPoint;
  private thirdPoint: IPoint;
  private center: IPoint;
  private radius: number;
  private azimutO: number;
  private angleCenter: number;
  private direction: ORIENT = ORIENT.CW;
  private centerAux: THREE.Points;

  constructor(objData: ArcData, param: editArcParameters, iniPoint: IPoint) {
    super(iniPoint);
    this.objData = objData;
    this.param = param;
  }

  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Insert point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
    }]);
  }

  private initializeThreePoints() {
    const objDef = this.objData.definition;
    const points = arc(objDef.center, objDef.radius, objDef.azimutO, objDef.angleCenter, objDef.plane);
    this.firstPoint = points[0];
    this.secondPoint = points[(points.length - 1) / 2];
    this.thirdPoint = points[points.length - 1];
  }
  private initializeArcParams() {
    const objDef = this.objData.definition;
    this.center = objDef.center;
    this.radius = objDef.radius;
    this.azimutO = objDef.azimutO;
    this.angleCenter = objDef.angleCenter;
  }
  private initializeAuxObjects() {
    const objDef = this.objData.definition;
    this.centerAux = pointCreate(0, 0, 0);
    this.auxPoly = arcCreate(objDef.center, objDef.radius, objDef.azimutO, objDef.angleCenter, objDef.plane);
    this.auxLine = lineAuxCreate();
    const { x, y, z } = this.getCurrentPoint();
    lineAddVertex(this.auxLine, x, y, z);
    lineAddVertex(this.auxLine, x, y, z);

    this.saveToTempScene(this.centerAux);
    this.saveToTempScene(this.auxPoly);
    this.saveToTempScene(this.auxLine);
  }
  private getCurrentPoint(): IPoint {
    if (this.param === editArcParameters.FIRSTPOINT) {
      return copyIPoint(this.firstPoint);
    } else if (this.param === editArcParameters.SECONDPOINT) {
      return copyIPoint(this.secondPoint);
    } else {
      return copyIPoint(this.thirdPoint);
    }
  }
  public async start() {
    super.start();
    this.initializeThreePoints();
    this.initializeArcParams();
    this.initializeAuxObjects();
    const planeManager = this.graphicProcessor.getPlaneManager();
    planeManager.activePlane.position = this.objData.definition.center;
    planeManager.activePlane.rotation = this.objData.definition.plane;
    planeManager.activePlane.locked = true;
  }
  public moveLastPoint(pto: IPoint) {
    lineMoveVertex(this.auxLine, pto.x, pto.y, pto.z);
    this.setCurrentPoint(pto);
    this.calculateArc();
  }
  private setCurrentPoint(pto: IPoint) {
    if (this.param === editArcParameters.FIRSTPOINT) {
      this.firstPoint = copyIPoint(pto);
    } else if (this.param === editArcParameters.SECONDPOINT) {
      this.secondPoint = copyIPoint(pto);
    } else {
      this.thirdPoint = copyIPoint(pto);
    }
  }
  private calculateArc(): void {
    let coords: Float32Array | null;
    if (this.firstPoint && this.secondPoint && this.thirdPoint) {
      coords = arcBuffer3p(this.firstPoint, this.secondPoint, this.thirdPoint, this.currPlane.rotation);
      if (coords) {
        this.center = circleGetCenter3p(this.firstPoint, this.secondPoint, this.thirdPoint) as IPoint;
        this.centerAux.position.set(this.center.x, this.center.y, this.center.z);
      }
    } else {
      return;
    }
    if (!coords) coords = new Float32Array(0);
    setPosBuffer(this.auxPoly, coords);
  }
  public setLastPoint(): void {
    this.setCurrentPoint(this.lastPoint);
    this.endOperation();
  }
  private saveAzimutOAngleCenterAndDirection(firstPoint: IPoint, thirdPoint: IPoint) {
    const c = this.currPlane.getRelativePoint(this.center);
    const p1 = this.currPlane.getRelativePoint(firstPoint);
    this.azimutO = lineAzimut2p(c, p1);

    const p3 = this.currPlane.getRelativePoint(thirdPoint);
    const azimutEnd = lineAzimut2p(c, p3);

    this.angleCenter = getAngleBetweenAzimuts(this.azimutO, azimutEnd, this.direction);
    if (this.direction === ORIENT.CCW) this.angleCenter *= -1;
  }
  public save() {
    if (this.graphicProcessor && this.firstPoint && this.secondPoint && this.thirdPoint) {
      this.radius = vectorDist3D(this.firstPoint, this.center);
      this.direction = getArcDirectionFrom3p(this.firstPoint, this.secondPoint, this.thirdPoint, this.currPlane.rotation);
      this.saveAzimutOAngleCenterAndDirection(this.firstPoint, this.thirdPoint);

      const arc: arcParam = {
        center: this.center,
        radius: this.radius,
        azimutO: normalizeAngle(this.azimutO),
        angleCenter: this.angleCenter,
        plane: this.objData.definition.plane,
      };
      const command = new ArcEditDataCommand(this.objData, arc, null, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
}