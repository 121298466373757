import { ChangeEvent, forwardRef, InputHTMLAttributes, useState } from "react";
import cn from "classnames";


interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
}
const Checkbox = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { id, label, onChange, defaultChecked, ...rest } = props;

  const [checked, setChecked] = useState(defaultChecked);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      setChecked(!checked);
      onChange(event);
    }
  }

  const rootClassName = cn(
    "cursor-pointer mx-2 block text-xs select-none capitalize",
    props.disabled ? "text-gray-500" : "text-gray-400");

  return (
    <div className="flex justify-end px-1 py-0 text-xs leading-5">
      <div className="flex items-center">
        <label
          htmlFor={id ?? "mychbx"}
          className={rootClassName}
        >
          {label}
        </label>
        <input
          id={id ?? "mychbx"}
          type="checkbox"
          className="cursor-pointer h-3 w-3 bg-gray-700 text-indigo-500 block rounded hover:bg-gray-600 focus:bg-gray-600 focus:ring-2 focus:ring-indigo-600 focus:border-none"
          ref={ref}
          onChange={handleOnChange}
          checked={checked}
          {...rest}
        />

      </div>
    </div>
  );
});

export default Checkbox;
