const ConeIcon = ({ ...props }) => {
  return (
    <svg
      className="h-5 w-5"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="currentColor"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M59.673 72.3961L59.4862 74.3298L55.6562 74.0308L55.7683 72.0972L59.673 72.3961Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M65.5021 73.1621L65.1751 75.0771L61.3919 74.5353L61.6161 72.6109L65.5021 73.1621Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.2657 74.3671L70.7893 76.2447C69.5469 75.9271 68.3325 75.6562 67.0621 75.4133L67.4264 73.5077C68.7249 73.7599 69.986 74.0402 71.2657 74.3671Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M76.9173 76.1326L76.2354 77.9449C75.049 77.4965 73.844 77.0948 72.6296 76.7398L73.1714 74.8809C74.4325 75.2452 75.6843 75.6656 76.9173 76.1326Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.9612 76.1046L23.6525 77.9075C22.4568 78.3652 21.3451 78.851 20.2148 79.4395L19.3181 77.7207C20.5231 77.0948 21.6908 76.5904 22.9612 76.1046Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.6128 74.3484L29.0892 76.2261C27.8748 76.5343 26.6324 76.88 25.4367 77.2817L24.8295 75.4414C26.0626 75.0304 27.3517 74.6661 28.6128 74.3484Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.3858 73.1527L34.7034 75.0584C33.461 75.2639 32.1812 75.5068 30.9481 75.787L30.5278 73.8907C31.7982 73.6105 33.106 73.3583 34.3858 73.1527Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.2148 72.3867L40.411 74.3204L36.5997 74.7688L36.3288 72.8538L40.2148 72.3867Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.0719 71.9944L46.1467 73.9374L42.3167 74.1523L42.1672 72.2186L46.0719 71.9944Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.8066 72.0037L53.7412 73.9374L48.071 73.8814L48.0336 71.9477L53.8066 72.0037Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.3998 94.283C38.496 99.3274 61.3078 99.3274 75.404 94.283C83.9608 91.2284 89.8178 85.8664 81.2704 80.411C80.1962 79.7291 79.1312 79.178 77.9729 78.6642L78.7576 76.8893C80.1775 77.5245 81.4759 78.2064 82.7557 79.0752L49.9112 4.78281L17.0948 79.0285C17.2536 78.9257 17.4031 78.823 17.5619 78.7296L18.6081 80.355C9.98598 85.8384 15.731 91.1817 24.3998 94.283ZM85.5862 80.7006C87.4731 84.9603 88.9304 87.5946 84.3531 91.6114C82.3914 93.3302 79.5609 94.8529 76.0486 96.114C61.532 101.298 38.2625 101.298 23.7459 96.114C20.5792 94.9743 17.9542 93.6198 16.0299 92.0971C11.6394 88.5194 12.1065 86.1934 13.8814 81.5133L49.9112 0L85.5862 80.7006Z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="100" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ConeIcon;
