import { WallData } from "lib/models/structural/wall";
import { ColumnData } from "lib/models/structural/column";

abstract class CompoundElement<T> {

  protected abstract elements: Map<string, T[]>;

  public getElements(): Map<string, T[]> {
    return this.elements;
  }
  public getElementsWithName(name: string): T[] {
    return this.elements.get(name) ?? [];
  }
  public addElements(name: string, data: T[]) {
    const elem = this.elements.get(name);
    if (elem) {
      this.elements.set(name, elem.concat(data));
    } else {
      this.elements.set(name, data);
    }
  }
  public insertElements(name: string, startIndex: number, data: T[]) {
    const elem = this.elements.get(name);
    if (elem) {
      elem.splice(startIndex, 0, ...data);
    } else {
      this.elements.set(name, data);
    }
  }
  public removeElement(name: string, data: T) {
    const elements = this.elements.get(name);
    if (elements) {
      const i = elements.indexOf(data);
      if (i > -1) {
        elements.splice(i, 1);
        if (!elements.length) {
          this.elements.delete(name);
        }
      }
    }
  }
  public removeElements(name: string) {
    this.elements.delete(name);
  }
  public clear() {
    this.elements.clear();
  }
}

export class WallCompoundElement extends CompoundElement<WallData> {
  protected elements: Map<string, WallData[]> = new Map();
}

export class ColumnCompoundElement extends CompoundElement<ColumnData> {
  protected elements: Map<string, ColumnData[]> = new Map();
}
