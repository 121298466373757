import * as THREE from "three";
import { cadOpType } from "lib/operations/factory";
import { getBboxMark } from "../selection-tools";
import { BlockData } from "lib/models/block";
import { SelectionManager } from "../selection-manager";
import { ObjDataSelector } from "./selector-data";


export class BlockDataSelector extends ObjDataSelector<BlockData> {

  public override buildSelectMarks() {
    const bboxmark = getBboxMark(this.data.graphicObj);
    this.BBOX = bboxmark;
    return { vertex: [this.data.definition.position] };
  }

  public override launchMarkOP(selectionManager: SelectionManager, mark: THREE.Object3D, index?: number) {
    if (selectionManager.vertexSelector.pointObj === mark) {
      return {
        type: cadOpType.MOVE,
        graphicProcessor: this.graphicProc,
        args: [[this.data], this.data.definition.position],
      };
    }
  }
}
