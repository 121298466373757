import * as THREE from "three";
import { updateObjBboxBSphere } from ".";
import { isZero, vector3Equals } from "../math/epsilon";
import { addIpoint } from "../math/point";
import { IPoint } from "../math/types";
import { getBoundingBox } from "./bounding-box";

export function moveObj(threeObj: THREE.Object3D, distance: IPoint) {
  if (vector3Equals(distance, { x: 0, y: 0, z: 0 })) return;
  if (threeObj instanceof THREE.Mesh) {
    const position = addIpoint(threeObj.position, distance);
    // harcodeo para ajustar el position contando con un offset
    // ya que el origen de los THREE.mesh estan en el centro geometrico del objeto
    const bbox = getBoundingBox(threeObj);
    const offsetZ = bbox.max.z - bbox.min.z;
    if (isZero(position.z)) {
      position.z += offsetZ * 0.5;
    }
    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    threeObj.position.set(position.x, position.y, position.z);
    threeObj.updateMatrix();
    threeObj.geometry.computeBoundingBox();
    threeObj.geometry.computeBoundingSphere();

  } else if (threeObj instanceof THREE.Line || threeObj instanceof THREE.Points) {
    const buffer = (threeObj.geometry as THREE.BufferGeometry).getAttribute("position") as THREE.BufferAttribute;
    let m = new THREE.Matrix4();
    m.makeTranslation(distance.x, distance.y, distance.z);
    buffer.applyMatrix4(m);
    updateObjBboxBSphere(threeObj);

  } else if (threeObj instanceof THREE.Group) {
    const dist = addIpoint(threeObj.position, distance);
    threeObj.position.set(dist.x, dist.y, dist.z);
  }
}

export function moveObjs(threeObjs: THREE.Object3D[], distance: IPoint) {
  for (let i: number = 0, l: number = threeObjs.length; i < l; i++) {
    moveObj(threeObjs[i], distance);
  }
}
