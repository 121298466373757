import { getOverlappedElements, getContiguousElements } from "lib/geometries/structural/overlap";
import { GraphicProcessor } from "lib/graphic-processor";
import { isFoundationData, isSupportData } from "lib/models/checktools";
import { objDataType } from "lib/models/types";
import { IStrucElementData } from "lib/models/structural/structural";
import { Storey } from "../struc-storey";
import { buildingElemType, structElemType, structElemTypeList } from "../types/struc-base";

function getStrucElemetsToLinkObjs(type: buildingElemType, storey: Storey): Map<number, IStrucElementData[]> {
  const excludeTypes = [type, objDataType.LOAD, objDataType.FOOTER, objDataType.PILECAP];
  const types = Object.values(structElemTypeList)
    .filter(type => typeof type !== "string")
    .filter(type => !excludeTypes.includes(type)) as structElemType[];

  const elemsByType: Map<number, IStrucElementData[]> = new Map(types.map(t => [t, []]));
  if (storey) {
    for (let type of types) {
      const elems = storey.getStructuralElementsByType(type);
      elemsByType.get(type)?.push(...elems);
    }
  }
  return elemsByType;
}

// ----------------------------------------------------

function addOverlappedObjsAsLinked(strucElem: IStrucElementData, storey: Storey): void {
  const elemsByType = getStrucElemetsToLinkObjs(strucElem.type, storey);
  for (let elemType of elemsByType.values()) {
    if (elemType.length) {
      const elems = getOverlappedElements(strucElem, elemType);
      for (let elem of elems) {
        strucElem.addLinkedObject(elem);
        elem.addLinkedObject(strucElem);
      }
    }
  }
}
function addContiguousObjsAsLinked(strucElem: IStrucElementData, storey: Storey): void {
  const elemsByType = getStrucElemetsToLinkObjs(strucElem.type, storey);
  for (let elemType of elemsByType.values()) {
    if (elemType.length) {
      const elems = getContiguousElements(strucElem, elemType, storey);
      for (let elem of elems) {
        strucElem.addLinkedObject(elem);
        elem.addLinkedObject(strucElem);
      }
    }
  }
}
export function addOverlappedAndContiguousObjsAsLinkedObjs(strucElem: IStrucElementData, graphicProcessor: GraphicProcessor): void {
  const strucMng = graphicProcessor.getStructuralModelManager();
  const currBuilding = strucMng.currBuilding;
  const storey = currBuilding.getStoreyFromId(strucElem.storeyId)!;
  addOverlappedObjsAsLinked(strucElem, storey);

  const checkStorey = isSupportData(strucElem) ? currBuilding.getLowerStoreyFromId(strucElem.storeyId) : currBuilding.getUpperStoreyFromId(strucElem.storeyId);
  if (checkStorey) {
    addContiguousObjsAsLinked(strucElem, checkStorey);
  }
  if (isFoundationData(strucElem)) {
    addContiguousObjsAsLinked(strucElem, storey);
  }
}

// ----------------------------------------------------

function removeOverlappedObjsAsLinked(strucElem: IStrucElementData, storey: Storey): void {
  const elemsByType = getStrucElemetsToLinkObjs(strucElem.type, storey);
  for (let elemType of elemsByType.values()) {
    if (elemType.length) {
      const elems = getOverlappedElements(strucElem, elemType);
      for (let elem of elems) {
        strucElem.removeLinkedObject(elem);
        elem.removeLinkedObject(strucElem);
      }
    }
  }
}
function removeContiguousObjsAsLinked(strucElem: IStrucElementData, storey: Storey): void {
  const elemsByType = getStrucElemetsToLinkObjs(strucElem.type, storey);
  for (let elemType of elemsByType.values()) {
    if (elemType.length) {
      const elems = getContiguousElements(strucElem, elemType, storey);
      for (let elem of elems) {
        strucElem.removeLinkedObject(elem);
        elem.removeLinkedObject(strucElem);
      }
    }
  }
}
function removeOverlappedAndContiguousObjsAsLinkedObjs(strucElem: IStrucElementData, graphicProcessor: GraphicProcessor): void {
  const strucMng = graphicProcessor.getStructuralModelManager();
  const storey = strucMng.currBuilding.getStoreyFromId(strucElem.storeyId);
  if (storey) {
    removeOverlappedObjsAsLinked(strucElem, storey);
  }
  const upperStory = strucMng.currBuilding.getUpperStoreyFromId(strucElem.storeyId);
  if (upperStory) {
    removeContiguousObjsAsLinked(strucElem, upperStory);
  }
}
