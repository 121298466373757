import PositionToolbar from "modules/cad/components/position/position-toolbar";
import SnapToolbar from "modules/cad/components/snap/snap-toolbar";
import OrthoModeButton from "modules/cad/components/snap/ortho-mode-button";
import GridDisplayButton from "modules/cad/components/snap/grid-display-button";
import { defaultColor, setDefaultColor } from "lib/materials";
import { getHexString, setFromString } from "lib/math/color";
import ColorSelector from "modules/cad/components/color/color-selector";
import ViewportStrucToolbar from '../../../struc/components/viewport-mode/viewport-toolbar';
import ViewportCADToolbar from "../viewport/viewport-toolbar";
import { useLocation } from "react-router";
import { GraphicProcessor } from '../../../../lib/graphic-processor';
import { useCoordinatePositionType } from "../position/hooks/use-position";

interface Props {
  graphicProc: GraphicProcessor;
  usePosition: useCoordinatePositionType;
}
const FooterToolbar = ({ graphicProc, usePosition }: Props) => {

  const isStrucModule = (pathname: string) => pathname.includes("struc");
  const { pathname } = useLocation();
  const strucModule = isStrucModule(pathname);

  return (
    <div className="h-8 w-full bg-gray-800 border-t border-gray-700 inline-flex items-center justify-between">
      {strucModule ?
        <ViewportStrucToolbar graphicProc={graphicProc} /> :
        <ViewportCADToolbar graphicProc={graphicProc} />
      }
      <div className="flex items-center space-x-2">
        <PositionToolbar graphicProc={graphicProc} usePosition={usePosition} />
        <div className="w-20">
          <ColorSelector
            defaultValue={getHexString(defaultColor.r, defaultColor.g, defaultColor.b)}
            onChange={(e) => {
              const color = setFromString(e.target.value);
              setDefaultColor(color);
            }}
          />
        </div>
      </div>
      <div className="flex items-center divide-x divide-gray-600">
        <GridDisplayButton graphicProc={graphicProc} />
        <OrthoModeButton />
        <SnapToolbar />
      </div>
    </div>
  );
};

export default FooterToolbar;