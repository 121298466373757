import * as THREE from "three";
import { cadOpType } from "lib/operations/factory";
import { getAbsolutePoint } from "lib/coordinates/plane";
import { SlabData } from "lib/models/structural/slab";
import { markBulkTypes, ObjDataSelector } from "../selector-data";
import { SelectionManager } from "lib/selection/selection-manager";


export class SlabDataSelector extends ObjDataSelector<SlabData> {

  public override buildSelectMarks() {
    const { ptos2D, holes, basePoint, rotation } = this.data.definition;
    const points = ptos2D.map(p => getAbsolutePoint(p, basePoint, rotation));
    const pointsOri = [points[0]];
    for (const hole of holes) {
      pointsOri.push(getAbsolutePoint(hole[0], basePoint, rotation));
      for (let i = 1; i < hole.length; i++) { // (hole.length - 1) repeated end point in hole definition
        const holePoints = getAbsolutePoint(hole[i], basePoint, rotation);
        points.push(holePoints);
      }
    }
    const bulkData: markBulkTypes = {};
    bulkData.origin = pointsOri;
    bulkData.vertex = points.slice(1);
    return bulkData;
  }

  public override launchMarkOP(selectionManager: SelectionManager, mark: THREE.Points, vertexIndex: number) {
    const { ptos2D, holes } = this.data.definition;
    if (selectionManager.originSelector.pointObj === mark) {
      if (vertexIndex === 0) {
        // Edit contour
        return {
          type: cadOpType.EDITSLAB,
          graphicProcessor: this.graphicProc,
          args: [this.data, 0, ptos2D[0]],
        };
      } else {
        // Edit hole
        const holeIndex = vertexIndex - 1;
        return {
          type: cadOpType.EDITSLABHOLE,
          graphicProcessor: this.graphicProc,
          args: [this.data, 0, holes[holeIndex][0], holeIndex],
        };
      }
    }

    if (selectionManager.vertexSelector.pointObj === mark) {
      if (vertexIndex < (ptos2D.length - 1)) {
        // Edit contour
        return {
          type: cadOpType.EDITSLAB,
          graphicProcessor: this.graphicProc,
          args: [this.data, vertexIndex + 1, ptos2D[vertexIndex + 1]],
        };
      } else {
        // Edit hole
        let holeIdx: number = 0;
        let holePtoIndex = vertexIndex - (ptos2D.length - 1);
        for (holeIdx; holeIdx < holes.length; holeIdx++) {
          const hole = holes[holeIdx];
          if (holePtoIndex < (hole.length - 1)) {
            break;
          }
          holePtoIndex = holePtoIndex - (hole.length - 1);
        }
        return {
          type: cadOpType.EDITSLABHOLE,
          graphicProcessor: this.graphicProc,
          args: [this.data, holePtoIndex + 1, holes[holeIdx][holePtoIndex], holeIdx],
        };
      }
    }
  }
}
