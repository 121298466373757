import { setPosBuffer } from "lib/geometries";
import { updateFilledPolygon2DPoints } from "lib/geometries/solid/region";
import { updateLoadLineal } from "lib/geometries/structural/load";
import { getBufferFromPolylineParam, IPolylineParam } from "lib/math/line";
import { IPoint } from "lib/math/types";
import { getRelativePoint } from "lib/coordinates/plane";
import { LoadEditDataCommand } from "../../commands/structural/load";
import { addIpoint, copyIPoint, substractIpoint } from "../../math/point";
import { loadParam } from "../../models-struc/types/load";
import { SimpleEdition } from "../edition";
import { cadOpType } from "../factory";
import { settingsOpModes } from "../step-operations";
import { LoadStructuralData } from "lib/models/structural/load";
import { Line2 } from "three/examples/jsm/lines/Line2";

export class MoveLoadLinealVertexOP extends SimpleEdition {
  public opType = cadOpType.EDITLOADLINEAL;

  protected loadData: LoadStructuralData;

  protected auxDefinition: loadParam;
  protected indexToEdit: number;

  constructor(data: LoadStructuralData, indexToEdit: number, iniPoint: IPoint) {
    super(iniPoint);
    this.indexToEdit = indexToEdit;
    this.loadData = data;
    this.auxDefinition = this.loadData.cloneDefinition();
    this.auxPoly = this.loadData.createObject(this.auxDefinition) as Line2;
  }
  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Edit point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
    }]);
  }
  public setLastPoint() {
    this.endOperation();
  }
  public moveLastPoint(pto: IPoint) {
    this.auxDefinition = this.loadData.cloneDefinition();
    this.updateDefinition(pto);
  }

  private updateDefinition(pto: IPoint) {
    if (this.indexToEdit !== 0) {
      const newPoint = substractIpoint(pto, this.auxDefinition.basePoint);
      this.auxDefinition.ptos2D[this.indexToEdit] = newPoint;
    } else {
      const newBasePoint = copyIPoint(pto);
      const oldPoint = addIpoint(this.auxDefinition.ptos2D[1], this.auxDefinition.basePoint);
      this.auxDefinition.ptos2D[1] = substractIpoint(oldPoint, newBasePoint);
      this.auxDefinition.basePoint = newBasePoint;
    }
    const polyParam: IPolylineParam = { points: this.auxDefinition.ptos2D.map(copyIPoint), isClosed: false, arcs: [] };
    const buff = getBufferFromPolylineParam(polyParam);
    setPosBuffer(this.auxPoly, buff);
    const { x, y, z } = this.auxDefinition.basePoint;
    this.auxPoly.position.set(x, y, z);
    updateLoadLineal(this.auxPoly, this.auxDefinition.loadValue, this.auxDefinition.ptos2D);
  }

  public save() {
    this.updateDefinition(this.lastPoint);
    const def = this.auxDefinition;
    const command = new LoadEditDataCommand(this.loadData, def, null, this.graphicProcessor);
    this.graphicProcessor.storeAndExecute(command);
  }
}

export class MoveLoadSuperficialVertexOP extends SimpleEdition {
  public opType = cadOpType.EDITLOADSUPERFICIAL;

  protected loadData: LoadStructuralData;
  protected auxRegion: THREE.Mesh;

  protected auxDefinition: loadParam;
  protected indexToEdit: number;

  constructor(data: LoadStructuralData, indexToEdit: number, iniPoint: IPoint) {
    super(iniPoint);
    this.indexToEdit = indexToEdit;
    this.loadData = data;
    this.auxDefinition = this.loadData.cloneDefinition();
    this.auxRegion = this.loadData.createObject(this.auxDefinition) as THREE.Mesh;
  }
  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Edit point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
    }]);
  }
  public async start() {
    await super.start();
    this.loadData.visibleGraphicObject = false;
    this.saveToTempScene(this.auxRegion);
    const planeManager = this.graphicProcessor.getPlaneManager();
    planeManager.activePlane.position = this.loadData.definition.basePoint;
    planeManager.activePlane.rotation = this.loadData.definition.rotation;
    planeManager.activePlane.locked = true;
  }
  public setLastPoint() {
    this.endOperation();
  }
  public moveLastPoint(pto: IPoint) {
    this.auxDefinition = this.loadData.cloneDefinition();
    const { basePoint, rotation } = this.auxDefinition;
    this.auxDefinition.ptos2D[this.indexToEdit] = getRelativePoint(pto, basePoint, rotation);
    updateFilledPolygon2DPoints(this.auxRegion, this.auxDefinition.ptos2D);
    this.auxRegion.position.set(basePoint.x, basePoint.y, basePoint.z);
    this.auxRegion.rotation.set(rotation.x, rotation.y, rotation.z);
  }
  public cancelOperation(): void {
    this.loadData.visibleGraphicObject = true;
    super.cancelOperation();
  }
  public endOperation(): void {
    this.loadData.visibleGraphicObject = true;
    super.endOperation();
  }
  public save() {
    const def = this.auxDefinition;
    const command = new LoadEditDataCommand(this.loadData, def, null, this.graphicProcessor);
    this.graphicProcessor.storeAndExecute(command);
  }
}
