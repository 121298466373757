import { style } from "lib/styles/style-cache";
import { IShellCrossSection, shellCrossSectionParam, isWaffleCSSParams, ShelCSSType, waffleCrossSection, isSandwichCSSParams, sandwichCrossSection } from "./types";

export class ShellCrossSection implements style<IShellCrossSection>, IShellCrossSection {

  public default: boolean = false;
  public styleId: string;

  public name: string;
  public thickness: number = 0.4;
  public parameters: shellCrossSectionParam = {
    shellSectionType: ShelCSSType.FLAT,
  };

  constructor(opts?: IShellCrossSection) {
    if (opts !== undefined) {
      this.override(opts);
    }
  }

  public override(prop: Partial<IShellCrossSection>) {
    if (prop) {
      if (prop.name !== undefined) this.name = prop.name;
      if (prop.thickness !== undefined) this.thickness = prop.thickness;
      if (prop.parameters !== undefined) {
        if (prop.parameters.shellSectionType !== undefined) this.parameters.shellSectionType = prop.parameters.shellSectionType;

        if (isWaffleCSSParams(this.parameters)) {
          const newParams = prop.parameters as waffleCrossSection;
          if (newParams.xInteraxis !== undefined) this.parameters.xInteraxis = newParams.xInteraxis;
          if (newParams.yInteraxis !== undefined) this.parameters.yInteraxis = newParams.yInteraxis;
          if (newParams.ribWidth !== undefined) this.parameters.ribWidth = newParams.ribWidth;
          if (newParams.numberOfBlocks !== undefined) this.parameters.numberOfBlocks = newParams.numberOfBlocks;
          if (newParams.outerNonstBeamWidth !== undefined) this.parameters.outerNonstBeamWidth = newParams.outerNonstBeamWidth;
          if (newParams.innerNonstBeamWidth !== undefined) this.parameters.innerNonstBeamWidth = newParams.innerNonstBeamWidth;
          if (newParams.minDropPanelEdge !== undefined) this.parameters.minDropPanelEdge = newParams.minDropPanelEdge;
          if (newParams.rotationAngle !== undefined) this.parameters.rotationAngle = newParams.rotationAngle;
          if (newParams.centerWaffleX !== undefined) this.parameters.centerWaffleX = newParams.centerWaffleX;
          if (newParams.centerWaffleY !== undefined) this.parameters.centerWaffleY = newParams.centerWaffleY;
          if (newParams.dropPanelRatio !== undefined) this.parameters.dropPanelRatio = newParams.dropPanelRatio;
          if (newParams.compressionLayerDepth !== undefined) this.parameters.compressionLayerDepth = newParams.compressionLayerDepth;

        } else if (isSandwichCSSParams(this.parameters)) {
          const newParams = prop.parameters as sandwichCrossSection;
          if (newParams.xInteraxis !== undefined) this.parameters.xInteraxis = newParams.xInteraxis;
          if (newParams.yInteraxis !== undefined) this.parameters.yInteraxis = newParams.yInteraxis;
          if (newParams.ribWidth !== undefined) this.parameters.ribWidth = newParams.ribWidth;
          if (newParams.numberOfBlocks !== undefined) this.parameters.numberOfBlocks = newParams.numberOfBlocks;
          if (newParams.outerNonstBeamWidth !== undefined) this.parameters.outerNonstBeamWidth = newParams.outerNonstBeamWidth;
          if (newParams.innerNonstBeamWidth !== undefined) this.parameters.innerNonstBeamWidth = newParams.innerNonstBeamWidth;
          if (newParams.minDropPanelEdge !== undefined) this.parameters.minDropPanelEdge = newParams.minDropPanelEdge;
          if (newParams.rotationAngle !== undefined) this.parameters.rotationAngle = newParams.rotationAngle;
          if (newParams.centerWaffleX !== undefined) this.parameters.centerWaffleX = newParams.centerWaffleX;
          if (newParams.centerWaffleY !== undefined) this.parameters.centerWaffleY = newParams.centerWaffleY;
          if (newParams.dropPanelRatio !== undefined) this.parameters.dropPanelRatio = newParams.dropPanelRatio;
          if (newParams.compressionLayerDepth !== undefined) this.parameters.compressionLayerDepth = newParams.compressionLayerDepth;
          if (newParams.lowerCompressionLayerDepth !== undefined) this.parameters.lowerCompressionLayerDepth = newParams.lowerCompressionLayerDepth;
        }

      }
    }
  }

  public clone() {
    const dolly = new ShellCrossSection(this);
    dolly.styleId = this.styleId;
    dolly.default = this.default;
    return dolly;
  }
}
