import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { useAuth } from "shared/components/auth/context";
import { useOperation } from "modules/cad/components/operations/context";
import { userMessageEvents } from "lib/events/user-messages";
import { fetchSaveProject } from "lib/models-struc/infrastructure/save-project";
import { GraphicProcessor } from "lib/graphic-processor";

export default function useSaveStrucProject(graphicProc: GraphicProcessor) {

  const params = useParams<{ id: string }>();
  const { id } = params;
  const { user } = useAuth();
  const { cleanChanges } = useOperation();

  const queryClient = useQueryClient();

  const mutation = useMutation((variables: { userId: string, id: string, graphicProc: GraphicProcessor }) => {
    return fetchSaveProject(variables.graphicProc, variables.userId, variables.id);
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(['GET_STRUC_PROJECTS']);
    }
  })

  const saveStrucProject = useCallback(async () => {
    try {
      await mutation.mutateAsync({ userId: user!.id, id, graphicProc });
      cleanChanges();
      userMessageEvents.dispatchSucces("Project saved successfully.");

    } catch (error) {
      userMessageEvents.dispatchError("Error saving project.");
    }
  }, [cleanChanges, graphicProc, id, mutation, user]);

  return saveStrucProject;
}
