import { SelectionAction, SelectionActionType } from "lib/events/select";
import { IObjData } from "lib/models/objdata";
import { SelectionManager } from "lib/selection/selection-manager";
import { useCallback, useEffect, useReducer } from "react";

interface EntityState {
  selectedEntities: IObjData[];
  selectedEntity: IObjData | null;
}

const numberObjs = 20;

function selectReducer(state: EntityState, action: SelectionAction): EntityState {
  switch (action.type) {
    case SelectionActionType.SELECT:
      return {
        selectedEntities: action.payload.obj.slice(0, numberObjs),
        selectedEntity: action.payload.obj[action.payload.obj.length - 1],
      };
    case SelectionActionType.UNSELECT:
      return {
        selectedEntities: action.payload.obj.slice(0, numberObjs),
        selectedEntity: action.payload.obj[0] ?? null,
      };
    case SelectionActionType.UNSELECTALL:
      return {
        selectedEntities: [],
        selectedEntity: null,
      };
    case SelectionActionType.SELECT_ONE:
      return {
        ...state,
        selectedEntity: action.payload.objData ?? null,
      };
    default:
      throw new Error(`Action is not defined.`);
  }
}

export function useSelection(selMng: SelectionManager, objData?: IObjData) {

  const [SelectionState, dispatch] = useReducer(selectReducer, {
    selectedEntities: objData ? [objData] : selMng.selectedObjs,
    selectedEntity: objData ? objData : (selMng.selectedObjs[0] ?? null),
  });

  useEffect(() => {
    selMng.subscribe(dispatch);
    return () => {
      selMng.unsubscribe(dispatch);
    };
  }, []);

  const selectEntity = useCallback((objData: IObjData) => {
    dispatch({ type: SelectionActionType.SELECT_ONE, payload: { objData } });
  }, []);

  return {
    selectedEntity: SelectionState.selectedEntity ? SelectionState.selectedEntity : null,
    selectedEntities: SelectionState.selectedEntities.slice(0, numberObjs),
    selectEntity,
  };
}
