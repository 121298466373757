import { useCallback, useState } from "react";
import { CADProject } from "modules/cad/models/projects";
import { cadOpType, iniCadFactory } from "lib/operations/factory";
import { GraphicProcessor } from '../../../../../lib/graphic-processor';

type Status = "idle" | "loading" | "mounted" | "unmounted" | "error";

let resizer: ResizeObserver;

export default function useViewport(graphicProc: GraphicProcessor, project?: CADProject) {

  const [status, setStatus] = useState<Status>("idle");

  const mountContainer = async (node: HTMLDivElement) => {
    setStatus("loading");
    graphicProc.mount(node, project);
    resizer = new ResizeObserver(graphicProc.handleWindowResize);
    resizer.observe(node);
    setStatus("mounted");
  }
  const dispatchEvents = () => {
    graphicProc.getDataModelManager().layerManager.layerObserver.dispatchLoadLayers();
  }
  const unMountContainer = () => {
    resizer.disconnect();
    graphicProc.unmount();
    setStatus("unmounted");
  }

  const setContainerRef = useCallback((node: HTMLDivElement) => {
    if (node) {
      mountContainer(node).then(() => {
        iniCadFactory();
        graphicProc.launchOP(cadOpType.SELECT);
        dispatchEvents();
      });
    } else {
      unMountContainer();
    }
  }, [])

  return {
    setContainerRef,
    status,
  };
}
