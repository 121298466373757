import { LayerData } from "lib/layers/layer-data"
import { IObjData } from "lib/models/objdata"
import { useCallback, useState } from "react"
import { useUI } from "shared/components/ui/context"
import { menuCtxSettings } from "shared/components/ui/context-menu/context-menu"
import { NodeData } from "shared/components/ui/tree/tree"

function isLayerData(data: any): data is LayerData {
  return !!data.objDatas;
}

export function useLayerTreeContextMenu() {

  const { openModal } = useUI();
  const [menuCtxItems, setmenuCtxItems] = useState<menuCtxSettings[]>([])

  const setMenuContextual = useCallback((node?: NodeData<LayerData | IObjData>) => {
    if (node && node.data) {
      const nodeProp = node.data;
      if (isLayerData(nodeProp)) {
        setmenuCtxItems([
          { label: "Add layer", callback: () => openModal("ADD_LAYER") },
          { label: "Delete layer", callback: () => openModal("DELETE_LAYER") }
        ]);
      } else {
        setmenuCtxItems([
          { label: "Add layer", callback: () => openModal("ADD_LAYER") },
        ]);
      }
    } else {
      setmenuCtxItems([]);
    }
  }, [openModal])

  return {
    menuCtxItems,
    setMenuContextual,
  }
}