import { addEulerAnglesToEulerAngles } from "lib/math/plane";
import * as THREE from "three";
import { updateObjBboxBSphere } from ".";
import { rotateBufferAttribute, rotatePoint } from "../math/rotate";
import { IPoint } from "../math/types";

export function rotateObj(threeObj: THREE.Object3D, angleX: number, angleY: number, angleZ: number, optBasePoint: IPoint = { x: 0, y: 0, z: 0 }) {
  if (angleX === 0 && angleY === 0 && angleZ === 0) return;
  if (threeObj instanceof THREE.Mesh || threeObj instanceof THREE.Group) {
    const newPoint = rotatePoint(threeObj.position, angleX, angleY, angleZ, optBasePoint);
    threeObj.position.set(newPoint.x, newPoint.y, newPoint.z);

    const r1 = addEulerAnglesToEulerAngles(threeObj.rotation, { x: angleX, y: angleY, z: angleZ });
    threeObj.rotation.set(r1.x, r1.y, r1.z);
    threeObj.updateMatrix();
    
    const geom = (threeObj as THREE.Mesh).geometry
    if (geom) {
      geom.computeBoundingBox();
      geom.computeBoundingSphere();
    }

  } else if (threeObj instanceof THREE.Line || threeObj instanceof THREE.Points) {
    const buffer = (threeObj.geometry as THREE.BufferGeometry).getAttribute("position") as THREE.BufferAttribute;
    rotateBufferAttribute(buffer, angleX, angleY, angleZ, optBasePoint);
    updateObjBboxBSphere(threeObj);
  }
}
export function rotateObjX(
  threeObj: THREE.Object3D,
  angleX: number,
  optBasePoint: IPoint = { x: 0, y: 0, z: 0 }
) {
  rotateObj(threeObj, angleX, 0, 0, optBasePoint);
}
export function rotateObjY(
  threeObj: THREE.Object3D,
  angleY: number,
  optBasePoint: IPoint = { x: 0, y: 0, z: 0 }
) {
  rotateObj(threeObj, 0, angleY, 0, optBasePoint);
}
export function rotateObjZ(
  threeObj: THREE.Object3D,
  angleZ: number,
  optBasePoint: IPoint = { x: 0, y: 0, z: 0 }
) {
  rotateObj(threeObj, 0, 0, angleZ, optBasePoint);
}

export function rotateObjs(threeObjs: THREE.Object3D[], angleX: number, angleY: number, angleZ: number, optAbsCenter?: IPoint): void {
  for (let i: number = 0, l: number = threeObjs.length; i < l; i++) {
    rotateObj(threeObjs[i], angleX, angleY, angleZ, optAbsCenter);
  }
}