import Page from '../components/ui/page'
import { useAddProject } from '../components/projects/hooks/use-add-project'
import ProjectForm from '../components/projects/project-form'
import { SecondaryLink } from 'shared/components/ui/link'
import { PrimaryButton } from 'shared/components/ui/button'

const NewLiteProject = () => {
  const {
    handleSubmit,
    register,
    storeys,
    setLocation,
    status,
    updateStorey,
    setFootingLevel,
    sortStoreys,
    uploadBulkDXF,
  } = useAddProject()
  return (
    <Page title="New project">
      <ProjectForm
        onChangeLocation={setLocation}
        onChangeFootingLevel={setFootingLevel}
        onSubmit={handleSubmit}
        onChangeStorey={updateStorey}
        register={register}
        storeys={storeys}
        onDropStorey={sortStoreys}
        onBulkDXFUpload={uploadBulkDXF}
      >
        <div className="flex items-center justify-end space-x-2">
          <SecondaryLink to="/lite/projects" variant="tertiary">
            Cancel
          </SecondaryLink>
          <PrimaryButton type="submit" loading={status === 'loading'}>
            Create project
          </PrimaryButton>
        </div>
      </ProjectForm>
    </Page>
  )
}

export default NewLiteProject
