import { structElemType, supportDefinitionType } from "lib/models-struc/types/struc-base";
import { CadCommand } from "../base";
import { objDataType } from "lib/models/types";
import { SupportElementTypes } from "lib/models/structural/structural";
import { getDataModelBuilder } from "lib/models/model-creator/datamodel-factory";

export abstract class createCompoundStructuralElementCommand<T extends supportDefinitionType> extends CadCommand {

  protected abstract objType: objDataType;
  protected abstract elemType: objDataType.WALL | objDataType.COLUMN;
  protected abstract structuralParam: T[];

  public createdObjs: SupportElementTypes[] = [];

  public execute() {
    if (this.createdObjs.length === 0) {
      const strucMng = this.graphicProcessor.getStructuralModelManager();
      for (const param of this.structuralParam) {
        const objData = getDataModelBuilder(this.elemType, param) as SupportElementTypes;
        objData.createGraphicObj();
        const layerData = strucMng.getLayerByStoreyIdAndStructuralType(param.storeyId, objData.type as structElemType);
        this.graphicProcessor.addToLayer(objData, layerData.id);
        this.createdObjs.push(objData);
      }
    } else {
      for (const data of this.createdObjs) {
        this.graphicProcessor.addToLayer(data, data.layerId);
      }
    }
    const modelManager = this.graphicProcessor.getDataModelManager();
    modelManager.dispatchAddedObjs(this.createdObjs);
    const lyrManager = this.graphicProcessor.getLayerManager();
    lyrManager.layerObserver.dispatchLoadLayers();
  }

  public unExecute(): void {
    for (const data of this.createdObjs) {
      this.graphicProcessor.removeFromLayer(data);
    }
    const modelManager = this.graphicProcessor.getDataModelManager();
    modelManager.dispatchDeletedObjs(this.createdObjs);
    const lyrManager = this.graphicProcessor.getLayerManager();
    lyrManager.layerObserver.dispatchLoadLayers();
  }

  public delete(): void {
    this.structuralParam.length = 0;
    this.createdObjs.length = 0;
  }
}
