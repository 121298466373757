import { useCallback, useEffect, useRef, useState } from "react";

export function useDynamicInput(viewportRef: HTMLElement) {

  const dynInputRef = useRef<HTMLInputElement>(null);
  const [freeze, setFreeze] = useState(false);
  const [visible, setVisible] = useState(true);
  const [x, setX] = useState("0px");
  const [y, setY] = useState("0px");

  const handleMouseMove = useCallback((event: PointerEvent) => {
    if (dynInputRef.current && !freeze) {
      const { bottom, right } = (event.currentTarget as any).getClientRects()['0'];
      const { height, width } = dynInputRef.current.getBoundingClientRect() as DOMRect;
      const d0 = bottom - event.pageY;
      const offsetY = (d0 < (height + height * 0.5)) ? -height - height * 0.5 : height * 0.5;
      const d1 = right - event.pageX;
      const offsetX = (d1 < (width + 20)) ? -width - 20 : 20;
      setX(`${event.pageX + offsetX}px`);
      setY(`${event.pageY + offsetY}px`);
    }
  }, [freeze, dynInputRef]);

  const handleMouseEnter = useCallback((event: MouseEvent) => {
    setVisible(true);
    setFreeze(false);
  }, []);

  const handleMouseLeave = useCallback((event: MouseEvent) => {
    setVisible(false);
  }, []);

  useEffect(() => {
    viewportRef.addEventListener("pointermove", handleMouseMove);
    viewportRef.addEventListener("mouseenter", handleMouseEnter);
    viewportRef.addEventListener("mouseleave", handleMouseLeave);
    return () => {
      viewportRef.removeEventListener("pointermove", handleMouseMove);
      viewportRef.removeEventListener("mouseenter", handleMouseEnter);
      viewportRef.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, [handleMouseEnter, handleMouseLeave, handleMouseMove, viewportRef]);

  return {
    dynInputRef,
    freeze,
    setFreeze,
    visible,
    x,
    y,
  };
}
