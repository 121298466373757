import { useState } from "react";
import Section from "shared/components/ui/section";
import FormSelect from "shared/components/ui/form-select";
import { StrucStorey } from "modules/struc/models/project";
import {
  euConcreteType, euConcreteTypes,
  euSteelType, euSteelTypes,
  standards,
  usConcreteType, usConcreteTypes,
  usSteelType, usSteelTypes
} from "modules/struc/models/codes";
import { ProjectCodes } from "lib/apis/struc-project/api";

interface Props {
  projectCodes: ProjectCodes;
  register: any;

  storeys: StrucStorey[];
  setStoreys: (val: StrucStorey[]) => void;
  setConcreteTypes: (val: euConcreteType[] | usConcreteType[]) => void;
}

const MaterialsForm = ({
  projectCodes,
  register,
  storeys,
  setStoreys,
  setConcreteTypes,

}: Props) => {

  const [steelTypes, setSteelTypes] = useState<euSteelType[] | usSteelType[]>(euSteelTypes);

  return (
    <Section title="Materials" isCollapsible={true}>
      <div className="grid grid-cols-8 gap-6">
        <div className="col-span-2">
          <FormSelect
            name="Codes.SteelStandard"
            ref={register}
            label="Steel standard"
            dark
            defaultValue={projectCodes.SteelStandard}
            onChange={event => {
              setSteelTypes(event.target.value === 'EU' ? euSteelTypes : usSteelTypes);
            }}
          >
            {standards.map((standard) => (
              <option key={standard} value={standard}>
                {standard}
              </option>
            ))}
          </FormSelect>
        </div>
        <div className="col-span-2">
          <FormSelect
            name="Codes.Steel"
            ref={register}
            label="Steel type"
            dark
            defaultValue={projectCodes.Steel}
          >
            {steelTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </FormSelect>
        </div>
        <div className="col-span-2">
          <FormSelect
            name="Codes.ConcreteStandard"
            ref={register}
            label="Concrete standard"
            dark
            defaultValue={projectCodes.ConcreteStandard}
            onChange={event => {
              if (event.target.value === 'EU') {
                setConcreteTypes(euConcreteTypes);
                setStoreys(storeys.map(s => ({
                  ...s,
                  column_concrete_type: "HA25",
                  floor_concrete_type: "HA25"
                })));
              } else {
                setConcreteTypes(usConcreteTypes);
                setStoreys(storeys.map(s => ({
                  ...s,
                  column_concrete_type: "4000psi",
                  floor_concrete_type: "4000psi"
                })));
              }
            }}
          >
            {standards.map((code) => (
              <option key={code} value={code}>
                {code}
              </option>
            ))}
          </FormSelect>
        </div>
      </div>
    </Section>
  );
};

export default MaterialsForm;
