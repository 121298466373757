import { SolidData } from "../solids/solid";
import { IPoint } from "lib/math/types";
import { IObjData } from "../objdata";
import { buildingElemType, buildingStrucElemRepresentation } from "lib/models-struc/types/struc-base";
import { ISolidMaterial } from "lib/materials";
import { IAxisHelper } from "lib/selection/axis-helper-objdata";
import { LoadStructuralData } from "./load";
import { GraphicProcessor } from "lib/graphic-processor";
import { ColumnData } from "./column";
import { WallData } from "./wall";

export type SupportElementTypes = ColumnData | WallData;

export interface IStrucElementData extends IObjData {
  type: buildingElemType;

  readonly strucName: string;
  readonly storeyId: string;

  loads: LoadStructuralData[];
  lnkObjs: IStrucElementData[];

  definition: buildingStrucElemRepresentation;
  material: ISolidMaterial;
  cloneDefinition(): buildingStrucElemRepresentation;
  deleteAxisHelper(): void;

  addLinkedObject(elem: IStrucElementData): void;
  removeLinkedObject(elem: IStrucElementData): void;
  removeAllLinkedObject(): void;

  showAxisHelper(graphicProc: GraphicProcessor): void;
}

export abstract class StructuralElementData extends SolidData implements IStrucElementData {

  public loads: LoadStructuralData[] = [];
  public lnkObjs: IStrucElementData[] = [];

  get strucName(): string { return this.definition.name }
  get objName(): string { return this.definition.name }
  get storeyId(): string { return this.definition.storeyId }

  public abstract type: buildingElemType;
  public abstract definition: buildingStrucElemRepresentation;
  public abstract cloneDefinition(): buildingStrucElemRepresentation;

  // ----------------------------------------------------

  addLinkedObject(obj: IStrucElementData | undefined): void {
    if (obj !== undefined) {
      const def = this.definition;
      if (!def.lnkObjIds.includes(obj.id)) {
        def.lnkObjIds.push(obj.id);
      }
      if (!this.lnkObjs.includes(obj)) {
        this.lnkObjs.push(obj);
      }
    }
  }
  removeLinkedObject(obj: IStrucElementData | undefined): void {
    if (obj !== undefined) {
      const def = this.definition;
      const indexId = def.lnkObjIds.indexOf(obj.id);
      if (indexId > -1) {
        def.lnkObjIds.splice(indexId, 1);
      }
      const indexRef = this.lnkObjs.indexOf(obj);
      if (indexRef > -1) {
        this.lnkObjs.splice(indexRef, 1);
      }
    }
  }
  removeAllLinkedObject() {
    for (const lnkObjs of this.lnkObjs) {
      lnkObjs.removeLinkedObject(this);
    }
    this.lnkObjs.length = 0;
    this.definition.lnkObjIds.length = 0;
  }

  // ----------------------------------------------------

  public override translate(distance: IPoint): void {
    super.translate(distance);
    for (let load of this.loads) {
      load.translate(distance);
    }
  }
  public override rotate(angleX: number, angleY: number, angleZ: number, basePoint: IPoint): void {
    for (let load of this.loads) {
      load.rotate(angleX, angleY, angleZ, basePoint);
    }
  }
  public override scale(factorX: number, factorY: number, factorZ: number, basePoint: IPoint): void {
    for (let load of this.loads) {
      load.scale(factorX, factorY, factorZ, basePoint);
    }
  }
  public override mirror(startPoint: IPoint, endPoint: IPoint): void {
    for (let load of this.loads) {
      load.mirror(startPoint, endPoint);
    }
  }
  public delete(): void {
    this.loads.length = 0;
    this.lnkObjs.length = 0;
    if (this.axisHelper) this.axisHelper.delete();
    super.delete();
  }

  // ----------------------------------------------------

  protected axisHelper: IAxisHelper;
  public showAxisHelper(graphicProc: GraphicProcessor) {
    console.log("[SHOWaxishelper] Object (" + this.nameObj + ") hasn`t AXISHELPER")
  }
  protected updateAxisHelper() {
    if (this.axisHelper) {
      this.axisHelper.update();
    }
  }
  public deleteAxisHelper() {
    if (this.axisHelper) {
      this.axisHelper.delete();
      this.axisHelper = undefined!
    }
  }
}
