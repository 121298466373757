import { useAuth } from "shared/components/auth/context";
import { DangerButton, SecondaryButton } from "shared/components/ui/button";
import { useUI } from "shared/components/ui/context";
import LoadingDots from "shared/components/ui/loading-dots";
import { useGetStrucProject } from "../hook/use-project";
import { useCloneProjectData } from "./hook/use-clone-project";

const ProjectCloneModal = () => {

  const { user } = useAuth();
  const { modalData, closeModal } = useUI();

  const newProject = useGetStrucProject(user!.id, modalData?.id);
  const newCloneHook = useCloneProjectData(newProject.projectData!);

  if (newProject.isLoading || newCloneHook.status === "loading") {
    return (
      <div className="bg-gray-800 flex flex-col items-center justify-center">
        <LoadingDots />
      </div>
    );
  }
  return (
    <div className="space-y-6 max-w-sm">
      <div>
        <h1 className="text-lg leading-6 font-medium text-gray-400">
          {"Clone selected project" + newProject.projectData?.Name}
        </h1>
      </div>
      <div className="flex justify-center space-x-4">
        <SecondaryButton onClick={closeModal} fullWidth>
          Cancel
        </SecondaryButton>
        <DangerButton onClick={() => { newCloneHook.cloneStrucProjectData() }
        } fullWidth>
          Confirm
        </DangerButton>
      </div>
    </div >
  );
};

export default ProjectCloneModal;

