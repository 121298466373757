import { PolygonEditDataCommand } from "lib/commands/edition/polygon";
import { linealPrecision } from "lib/general-settings";
import { polygonArea } from "lib/geometries/area";
import { calculatePerimeter3D } from "lib/math/perimeter";
import { IInertia } from "lib/math/types";
import { polygonParam } from "../../geometries/polygon";
import { polygon, polygonInertia } from "../../math/polygon";
import { PolygonData } from "../../models/primitives/polygon";
import { DataDefinitionHandler } from "../base";
import { ILineMaterial } from '../../materials/line';

type polygonProp = polygonParam & {
  length: number;
  area: number;
  inertia: IInertia;
};

export class PolygonDataDefinitionHandler extends DataDefinitionHandler<polygonProp, ILineMaterial, undefined, undefined> {

  protected data: PolygonData;

  protected buildInfoProperties() {
    const def = this.data.definition;
    const polygonPtos = polygon(
      def.center,
      def.radius,
      def.sides,
      def.inscribed,
      def.angleO,
      def.plane
    );
    const inertia = polygonInertia(
      def.center,
      def.radius,
      def.sides,
      def.inscribed,
      def.angleO,
      def.plane
    );

    this.definitionInfo = {
      center: this.getPointView(def.center, "Center", "m", linealPrecision, true),
      radius: this.getNumberView(def.radius, "Radius", "m", linealPrecision, true, (radius: number) => radius > 0),
      sides: this.getNumberView(def.sides, "Sides", "", 0, true, (side: number) => side > 2),
      inscribed: this.getBooleanView(def.inscribed, "Inscribed"),
      angleO: this.getAngleView(def.angleO, "Origin"),
      plane: this.getPointAngleView(def.plane, "Rotation"),
      length: this.getNumberView(calculatePerimeter3D(polygonPtos), "Length", "m", linealPrecision, false),
      area: this.getNumberView(polygonArea(def), "Area", "m²", linealPrecision, false),
      inertia: {
        publicName: "Inertia",
        x: this.getNumberView(inertia.x, "X", "kg m²", linealPrecision, false),
        y: this.getNumberView(inertia.y, "Y", "kg m²", linealPrecision, false),
        xy: this.getNumberView(inertia.xy, "XY", "kg m²", linealPrecision, false),
      },
    };

    this.materialInfo = this.getLineMaterialView(this.data.material);
  }

  private checkNewDefinition(newDefinition: polygonProp): boolean {
    if (!newDefinition) return false;
    if (!this.checkPoint(newDefinition.center)) return false;
    if (!this.checkNumber(newDefinition.radius)) return false;
    if (!this.checkNumber(newDefinition.sides)) return false;
    if (!this.checkBoolean(newDefinition.inscribed)) return false;
    if (!this.checkAngle(newDefinition.angleO)) return false;
    if (!this.checkPoint(newDefinition.plane)) return false;
    return true;
  }

  private changedNewDefinition(oldDefinition: polygonParam, newDefinition: polygonProp): polygonParam | null {
    let def = oldDefinition;
    let changed: boolean = false;
    if (newDefinition) {
      const center = this.changedPoint(def.center, newDefinition.center);
      if (center !== null) {
        def.center = center;
        changed = true;
      }
      const radius = this.changedNumber(def.radius, newDefinition.radius);
      if (radius !== null) {
        def.radius = radius;
        changed = true;
      }
      const sides = this.changedNumber(def.sides, newDefinition.sides);
      if (sides !== null) {
        def.sides = sides;
        changed = true;
      }
      const inscribed = this.changedBoolean(def.inscribed, newDefinition.inscribed);
      if (inscribed !== null) {
        def.inscribed = inscribed;
        changed = true;
      }
      const angleO = this.changedAngle(def.angleO, newDefinition.angleO);
      if (angleO !== null) {
        def.angleO = angleO;
        changed = true;
      }
      const plane = this.changedPointRotation(def.plane, newDefinition.plane);
      if (plane !== null) {
        def.plane = plane;
        changed = true;
      }
    }
    return changed ? def : null;
  }

  private checkAndChangedDefinition(oldDefinition: polygonParam, newDefinition: polygonProp): polygonParam | null {
    let def = null;
    if (this.checkNewDefinition(newDefinition)) {
      def = this.changedNewDefinition(oldDefinition, newDefinition);
    }
    return def;
  }

  public saveAndRegenerate = (newDefinition: polygonProp) => {
    const polygonDefinition = this.checkAndChangedDefinition(this.data.cloneDefinition(), newDefinition);
    if (polygonDefinition) {
      const command = new PolygonEditDataCommand(this.data, polygonDefinition, null, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  };
  public saveAndRegenerateMaterial = (newMaterial: ILineMaterial) => {
    const polygonMaterial = this.checkAndChangedLineMaterial(this.data.material, newMaterial);
    if (polygonMaterial) {
      const command = new PolygonEditDataCommand(this.data, null, polygonMaterial, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  };
}