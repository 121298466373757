import { DXF } from "modules/lite/models/storey";
import { ChangeEvent, useCallback } from "react";
import BulkUploadIcon from "shared/components/icons/bulk-upload";
import styled from "styled-components";

interface Props {
  onChange: (files: DXF[]) => void;
}
const StoreyBulkUploader = ({ onChange }: Props) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files?.length) {
        const files = Array.from(event.target.files).map((file) => {
          const blob = URL.createObjectURL(file);
          return { name: file.name, url: blob, file };
        });
        files.sort();
        onChange(files);
      }
    },
    [onChange]
  );
  return (
    <Wrapper>
      <BulkUploadIcon />
      <span>Bulk DXF upload</span>
      <input
        id="bulk-dxf-upload"
        type="file"
        onChange={handleChange}
        className="sr-only"
        multiple
      />
    </Wrapper>
  );
};

const Wrapper = styled.label`
  height: 100%;
  display: flex;
  align-items: center;
  border: 2px dashed var(--color-gray-400);
  color: var(--color-gray-700);
  border-radius: var(--radius);
  background-color: var(--color-gray-200);
  cursor: pointer;
  font-size: 0.9rem;
  gap: 4px;
  padding: 8px;
  &:hover {
    background-color: var(--color-gray-300);
  }
`;

export default StoreyBulkUploader;
