const NearIcon = ({ ...props }) => {
  return (
    <svg className="h-5 w-5" viewBox="0 0 100 100" fill="none" {...props}>
      <path
        d="M96.875 21.875V3.125H78.125V17.4688L17.4688 78.125H3.125V96.875H21.875V82.5312L82.5312 21.875H96.875ZM84.375 9.375H90.625V15.625H84.375V9.375ZM15.625 90.625H9.375V84.375H15.625V90.625Z"
        fill="currentColor"
      />
      <path
        d="M96.875 21.875V3.125H78.125V17.4688L17.4688 78.125H3.125V96.875H21.875V82.5312L82.5312 21.875H96.875ZM84.375 9.375H90.625V15.625H84.375V9.375ZM15.625 90.625H9.375V84.375H15.625V90.625Z"
        fill="currentColor"
      />
      <path
        d="M85.875 53.125V65.875H73.125V53.125H85.875Z"
        fill="white"
        stroke="currentColor"
        strokeWidth="6.25"
      />
    </svg>
  );
};

export default NearIcon;
