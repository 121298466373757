import * as THREE from "three";
import { updateObjBboxBSphere } from ".";
import { scaleBuffer, scalePoint } from "../math/scale";
import { IPoint } from "../math/types";

export function scaleObj(threeObj: THREE.Object3D, scaleFactorX: number, scaleFactorY: number, scaleFactorZ: number, optBasePoint: IPoint = { x: 0, y: 0, z: 0 }) {
    if (scaleFactorX === 0 && scaleFactorY === 0 && scaleFactorZ === 0) return;
    if (threeObj instanceof THREE.Mesh) {
        const newPoint = scalePoint(threeObj.position, scaleFactorX, scaleFactorY, scaleFactorZ, optBasePoint);
        threeObj.position.set(newPoint.x, newPoint.y, newPoint.z);
        threeObj.scale.set(scaleFactorX, scaleFactorY, scaleFactorZ);
        threeObj.updateMatrix();
        threeObj.geometry.computeBoundingBox();
        threeObj.geometry.computeBoundingSphere();

    } else if (threeObj instanceof THREE.Line || threeObj instanceof THREE.Points) {
        const buffer = (threeObj.geometry as THREE.BufferGeometry).getAttribute("position") as THREE.BufferAttribute;
        scaleBuffer(buffer, scaleFactorX, scaleFactorX, scaleFactorX, optBasePoint)
        updateObjBboxBSphere(threeObj);

    } else if (threeObj instanceof THREE.Group) {
        const newPoint = scalePoint(threeObj.position, scaleFactorX, scaleFactorY, scaleFactorZ, optBasePoint);
        threeObj.position.set(newPoint.x, newPoint.y, newPoint.z);
        threeObj.scale.set(scaleFactorX, scaleFactorY, scaleFactorZ);
        threeObj.updateMatrix();
    }
}