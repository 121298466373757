import * as THREE from "three";
import { loadParam, loadType } from "lib/models-struc/types/load";
import { ObjData } from "../objdata";
import { objDataType } from "../types";
import {
  createLoadConcentrated,
  createLoadLineal,
  createLoadSuperficial,
  updateLoadConcentrated,
  updateLoadLineal,
  updateLoadSuperficial
} from "lib/geometries/structural/load";
import { IPoint } from "lib/math/types";
import { Line2 } from "three/examples/jsm/lines/Line2";
import { addIpoint, copyIPoint, IpointsToBuffer, substractIpoint } from "lib/math/point";
import { setPosBuffer } from "lib/geometries";
import { rotatePoint } from "lib/math/rotate";
import { normalizeAngle } from "lib/math/angles";
import { IStrucElementData } from "./structural";
import { isSlabData } from "../checktools";
import { scalePoint } from "lib/math/scale";
import { mirrorPoint } from "lib/math/mirror";
import { updateFilledPolygon2DPoints } from "lib/geometries/solid/region";

export class LoadStructuralData extends ObjData {

  public type = objDataType.LOAD;

  protected nameObj: string;
  get strucName(): string { return this.definition.name }
  get objName(): string { return this.definition.name }

  public definition: loadParam;
  public material: undefined;
  public parentStrucElem: IStrucElementData;

  get isDefaultSlabLoad() {
    if (isSlabData(this.parentStrucElem)) {
      const def = this.parentStrucElem.definition;
      if (def.liveLoadId === this.id) return true;
      if (def.deadLoadId === this.id) return true;
    }
    return false;
  }

  public graphicObj: THREE.Object3D;
  private graphicObjHandler: LoadGraphicObjHandler;

  constructor(definition: loadParam) {
    super();
    this.definition = {
      name: definition.name,
      hypothesisId: definition.hypothesisId,
      parentStructElemId: definition.parentStructElemId,
      externalGeo: definition.externalGeo,

      type: definition.type,
      loadValue: definition.loadValue,
      ptos2D: definition.ptos2D.map(copyIPoint),
      basePoint: copyIPoint(definition.basePoint),
      rotation: copyIPoint(definition.rotation),
    };

    if (this.definition.type === loadType.CONCENTRATED) {
      this.graphicObjHandler = new LoadConcentratedData();
    } else if (this.definition.type === loadType.LINEAL) {
      this.graphicObjHandler = new LoadLinealData();
    } else {
      this.graphicObjHandler = new LoadSuperficialData();
    }
    this.nameObj = this.graphicObjHandler.nameObj;
  }

  public createGraphicObj() {
    if (this.graphicObj) {
      console.warn("Attention: Concentrated load graphic object already created!!");
      return;
    }
    this.graphicObj = this.createObject(this.definition);
    // this.graphicObj.visible = false;
    // this.isDataVisible = false;
  }
  public createObject(definition?: loadParam | undefined): THREE.Object3D {
    return this.graphicObjHandler.createObject(definition ?? this.definition)
  }
  static createObj(definition: loadParam): THREE.Object3D {
    if (definition.type === loadType.CONCENTRATED) {
      return LoadConcentratedData.createObj(definition);
    } else if (definition.type === loadType.LINEAL) {
      return LoadLinealData.createObj(definition);
    } else {
      return LoadSuperficialData.createObj(definition);
    }
  }

  public cloneDefinition(): loadParam {
    return {
      name: this.definition.name,
      hypothesisId: this.definition.hypothesisId,
      parentStructElemId: this.definition.parentStructElemId,
      externalGeo: this.definition.externalGeo,

      type: this.definition.type,
      loadValue: this.definition.loadValue,
      ptos2D: this.definition.ptos2D.map(copyIPoint),
      basePoint: copyIPoint(this.definition.basePoint),
      rotation: copyIPoint(this.definition.rotation),
    }
  }

  public override regenerateObjectFromDefinition(): void {
    this.graphicObjHandler.regenerateDefinition(this.graphicObj, this.definition);
  }

  public translate(distance: IPoint): void {
    this.definition.basePoint = addIpoint(this.definition.basePoint, distance);
    this.regenerateObjectFromDefinition();
  }
  public rotate(angleX: number, angleY: number, angleZ: number, basePoint: IPoint): void {
    angleX = normalizeAngle(angleX);
    angleY = normalizeAngle(angleY);
    angleZ = normalizeAngle(angleZ);
    const ptos = this.definition.ptos2D.map(p => addIpoint(p, this.definition.basePoint));
    this.definition.basePoint = rotatePoint(this.definition.basePoint, angleX, angleY, angleZ, basePoint);
    const ptosRot = ptos.map(p => rotatePoint(p, angleX, angleY, angleZ, basePoint));
    this.definition.ptos2D = ptosRot.map(p => substractIpoint(p, this.definition.basePoint));
    this.regenerateObjectFromDefinition();
  }
  public scale(factorX: number, factorY: number, factorZ: number, basePoint: IPoint): void {
    const ptos = this.definition.ptos2D.map(p => addIpoint(p, this.definition.basePoint));
    this.definition.basePoint = scalePoint(this.definition.basePoint, factorX, factorY, factorZ, basePoint);
    const ptosScaled = ptos.map(p => scalePoint(p, factorX, factorY, factorZ, basePoint));
    this.definition.ptos2D = ptosScaled.map(p => substractIpoint(p, this.definition.basePoint));
    this.regenerateObjectFromDefinition();
  }
  public mirror(startPoint: IPoint, endPoint: IPoint): void {
    const ptos = this.definition.ptos2D.map(p => addIpoint(p, this.definition.basePoint));
    this.definition.basePoint = mirrorPoint(this.definition.basePoint, startPoint, endPoint);
    const ptosMirr = ptos.map(p => mirrorPoint(p, startPoint, endPoint));
    this.definition.ptos2D = ptosMirr.map(p => substractIpoint(p, this.definition.basePoint));
    this.regenerateObjectFromDefinition();
  }

  public delete() {
    this.graphicObjHandler.delete(this.graphicObj);
    const indx = this.parentStrucElem.loads.indexOf(this);
    this.parentStrucElem.loads.splice(indx, 1);
    this.parentStrucElem = undefined!;
    super.delete();
  }
}

interface LoadGraphicObjHandler {
  nameObj: string;
  createObject(definition: loadParam): THREE.Object3D;
  regenerateDefinition(graphicObj: THREE.Object3D, definition: loadParam): void;
  delete(graphicObj: THREE.Object3D): void;
}

class LoadConcentratedData implements LoadGraphicObjHandler {

  public nameObj: string = "Load concentrated";

  static createObj(definition: loadParam) {
    const graphicObj = createLoadConcentrated(definition.loadValue);
    const position = definition.basePoint;
    const rotation = definition.rotation;
    graphicObj.position.set(position.x, position.y, position.z);
    graphicObj.rotation.set(rotation.x, rotation.y, rotation.z);
    return graphicObj;
  }
  public createObject(definition: loadParam): THREE.Points {
    return LoadConcentratedData.createObj(definition);
  }
  public regenerateDefinition(graphicObj: THREE.Points, definition: loadParam): void {
    const position = definition.basePoint;
    const rotation = definition.rotation;
    graphicObj.position.set(position.x, position.y, position.z);
    graphicObj.rotation.set(rotation.x, rotation.y, rotation.z);
    updateLoadConcentrated(graphicObj, definition.loadValue);
  }
  public delete(graphicObj: THREE.Points) {
    const label = graphicObj.children[0] as THREE.Sprite;
    label.geometry.dispose();
    label.material.dispose();
    graphicObj.children.length = 0;
    graphicObj.geometry.dispose();
    (graphicObj.material as THREE.PointsMaterial).dispose();
  }
}
class LoadLinealData implements LoadGraphicObjHandler {

  public nameObj: string = "Load lineal";

  static createObj(definition: loadParam) {
    const graphicObj = createLoadLineal(definition.loadValue, definition.ptos2D);
    const position = definition.basePoint;
    const rotation = definition.rotation;
    graphicObj.position.set(position.x, position.y, position.z);
    graphicObj.rotation.set(rotation.x, rotation.y, rotation.z);
    return graphicObj;
  }
  public createObject(definition: loadParam): Line2 {
    return LoadLinealData.createObj(definition);
  }
  public regenerateDefinition(graphicObj: Line2, definition: loadParam): void {
    const position = definition.basePoint;
    const rotation = definition.rotation;
    graphicObj.position.set(position.x, position.y, position.z);
    graphicObj.rotation.set(rotation.x, rotation.y, rotation.z);
    graphicObj.matrixAutoUpdate = true;
    const coords = IpointsToBuffer(definition.ptos2D);
    setPosBuffer(graphicObj, coords);
    updateLoadLineal(graphicObj, definition.loadValue, definition.ptos2D);
  }
  public delete(graphicObj: Line2) {
    const label = graphicObj.children[0] as THREE.Sprite;
    label.geometry.dispose();
    label.material.dispose();
    graphicObj.children.length = 0;
    graphicObj.geometry.dispose();
  }
}
class LoadSuperficialData implements LoadGraphicObjHandler {

  public nameObj: string = "Load superficial";

  static createObj(definition: loadParam) {
    const graphicObj = createLoadSuperficial(definition.loadValue, definition.ptos2D);
    const position = definition.basePoint;
    const rotation = definition.rotation;
    graphicObj.position.set(position.x, position.y, position.z);
    graphicObj.rotation.set(rotation.x, rotation.y, rotation.z);
    return graphicObj;
  }
  public createObject(definition: loadParam): THREE.Mesh {
    return LoadSuperficialData.createObj(definition);
  }
  public regenerateDefinition(graphicObj: THREE.Mesh, definition: loadParam): void {
    updateFilledPolygon2DPoints(graphicObj, definition.ptos2D);
    const position = definition.basePoint;
    const rotation = definition.rotation;
    graphicObj.position.set(position.x, position.y, position.z);
    graphicObj.rotation.set(rotation.x, rotation.y, rotation.z);
    updateLoadSuperficial(graphicObj, definition.loadValue, definition.ptos2D);
  }
  public delete(graphicObj: THREE.Mesh) {
    const line = graphicObj.children[0] as THREE.LineSegments;
    line.geometry.dispose();
    const label = graphicObj.children[1] as THREE.Sprite;
    label.geometry.dispose();
    label.material.dispose();
    graphicObj.children.length = 0;
    graphicObj.geometry.dispose();
  }
}
