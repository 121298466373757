import { IObjData } from "lib/models/objdata";
import { cloneDataModel } from "lib/models/model-creator/datamodel-factory";
import { GraphicProcessor } from "../../graphic-processor";
import { IPoint } from "../../math/types";
import { cadOpType } from "../../operations/factory";
import { TransformCommand } from "./transform";

export class ScaleCommand extends TransformCommand {
  protected opType = cadOpType.SCALE;

  private basePoint: IPoint;
  private scaleFactorX: number;
  private scaleFactorY: number;
  private scaleFactorZ: number;

  constructor(basePoint: IPoint, scaleFactorX: number, scaleFactorY: number, scaleFactorZ: number, objIds: IObjData[], withCopy: boolean, graphicProcessor: GraphicProcessor) {
    super(objIds, graphicProcessor);
    this.basePoint = basePoint;
    this.scaleFactorX = scaleFactorX;
    this.scaleFactorY = scaleFactorY;
    this.scaleFactorZ = scaleFactorZ;
    this.withCopy = withCopy;
  }

  protected transformFun = (obj: IObjData) => {
    if (this.withCopy) {
      if (!this.createdObjs.has(obj)) {
        const data = cloneDataModel(obj);
        data.scale(this.scaleFactorX, this.scaleFactorY, this.scaleFactorZ, this.basePoint);
        this.graphicProcessor.addToLayer(data, obj.layerId);
        this.createdObjs.set(obj, data);
      }
    } else {
      obj.scale(this.scaleFactorX, this.scaleFactorY, this.scaleFactorZ, this.basePoint);
    }
  };

  protected unDoTransformFun = (obj: IObjData) => {
    if (this.withCopy) {
      const alreadyCopied = this.createdObjs.get(obj)!;
      this.graphicProcessor.removeFromLayer(alreadyCopied);
    } else {
      obj.scale(1 / this.scaleFactorX, 1 / this.scaleFactorY, 1 / this.scaleFactorZ, this.basePoint);
    }
  };
}
