import { GraphicProcessor } from "lib/graphic-processor";
import { LoadStructuralData } from "lib/models/structural/load";
import { IFEMStructuralElement, IFEMStructuralElementRef, IMeshProject } from "modules/struc/models/ecore/analysis";
import { Building } from "modules/struc/models/ecore/base";

export class FEMStructuralElementManager {

  private mapLoadFEMStructuralElemRef: Map<LoadStructuralData, IFEMStructuralElementRef> = new Map();
  private newRootRef = "mesh_project.json#//@"

  hasMeshData(): boolean {
    return this.mapLoadFEMStructuralElemRef.size > 0;
  }

  async importMeshDataForLoads(graphicProc: GraphicProcessor, stringMesh: string): Promise<void> {
    this.mapLoadFEMStructuralElemRef.clear();
    const jsonMesh = JSON.parse(stringMesh.replaceAll("//@", this.newRootRef));
    const { building, femStructuralElems } = this.loadElemsAndFEMStrucElems(jsonMesh);
    if (building && femStructuralElems) {
      const strucMng = graphicProc.getStructuralModelManager();
      const loads = strucMng.getLoadElements();
      for (let load of loads) {
        const parentId = load.definition.parentStructElemId;
        const { storeyIndex, elemIndex } = this.getIndexOfStructuralElementById(parentId, building);
        if (storeyIndex !== undefined && elemIndex !== undefined) {
          const ref = `${this.newRootRef}versions.0/@building/@storeys.${storeyIndex}/@elements.${elemIndex}`;
          const { femEclass, femRef } = this.getInfoOfFEMStructuralElementByRef(ref, femStructuralElems);
          if (femEclass !== undefined && femRef !== undefined) {
            this.mapLoadFEMStructuralElemRef.set(load, {
              eClass: femEclass,
              $ref: femRef,
            });
          }
        }
      }
    }
  }

  getFEMStructuralElemRef(load: LoadStructuralData): IFEMStructuralElementRef | undefined {
    return this.mapLoadFEMStructuralElemRef.get(load);
  }

  private loadElemsAndFEMStrucElems(jsonMesh: any) {
    let building, femStructuralElems;
    const mesh = jsonMesh as IMeshProject;
    const version = mesh.versions[0];
    if (version) {
      building = version.building;
      femStructuralElems = version.femStructuralElements;
    }
    return { building, femStructuralElems };
  }

  private getIndexOfStructuralElementById(id: string, building: Building) {
    for (let storeyIndex = 0; storeyIndex < building.storeys.length; storeyIndex++) {
      const elems = building.storeys[storeyIndex].elements;
      if (elems) {
        const elemIndex = elems.findIndex(e => e.id === id);
        if (elemIndex > -1) {
          return { storeyIndex, elemIndex }
        }
      }
    }
    return { storeyIndex: undefined, elemIndex: undefined };
  }

  private getInfoOfFEMStructuralElementByRef(ref: string, femStructuralElems: IFEMStructuralElement[]) {
    const femIndex = femStructuralElems.findIndex(f => f.structuralelement.$ref === ref);
    if (femIndex > -1) {
      const ref = `${this.newRootRef}versions.0/@femStructuralElements.${femIndex}`;
      return { femEclass: femStructuralElems[femIndex].eClass, femRef: ref };
    }
    return { femEclass: undefined, femRef: undefined };
  }
}

export let femStructuralManager: FEMStructuralElementManager;
export function initFEMStructuralManager() {
  femStructuralManager = new FEMStructuralElementManager();
  return femStructuralManager;
}