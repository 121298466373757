import { circleParam } from "../geometries/circle";
import { polygonParam } from "../geometries/polygon";
import { IObjData } from "../models/objdata";
import { isBetweenDirection, lineAngle2p, mecDirectionMathDirection, normalizeAngle, ORIENT } from "./angles";
import { distance2DPointToPolyline, distancePointToLine2D, vectorDist2D, vectorDist3D } from "./distance";
import { getPolarPoint, copyIPoint, IPointsToISegments, pointLinePositionXY } from "./point";
import { IPoint, ISegment } from "./types";
import { getFactorLinePosition2p } from "./point";
import { getLineBCrossLineA, intersect2D, intersectArcArc, intersectArcLine, isLineBCrossLineA } from "./intersections";
import { isSmallerThan, isZero, vector2Equals, vector3Equals } from "./epsilon";
import { isCircleData, isPolygonData, isLineData } from "lib/models/checktools";
import { arcLineToArcParam, getArcEndAngle, getArcStartAngle, IArcLineParam, IPolylineParam } from "./line";
import ClipperLib from 'lib/helpers/clipper';
import { LineData } from "lib/models/primitives/line";
import { getFactorPointArcPosition } from "./arc";
import { GraphicProcessor } from "lib/graphic-processor";

let a = 1;
export async function testOffset(graphicProcessor: GraphicProcessor) {
  const objs = graphicProcessor.getSelectedObjs();
  if (objs.length && isLineData(objs[0])) {
    const line = objs[0].definition as IPolylineParam;
    const modelManager = graphicProcessor.getDataModelManager();
    const layer = modelManager.layerManager.currentLayer.id;
    const newRes = calculateOffsetBuffer(line, 50 * a);
    if (newRes.length) {
      for (const d of newRes) {
        const data0 = new LineData(d);
        data0.createGraphicObj();
        graphicProcessor.addToLayer(data0, layer);
      }
    }
  }
  a *= -1;
}

interface Iintersection {
  p: IPoint;
  ua: number;
  ub: number;
  indxSegA: number;
  indxSegB: number;
  visited: boolean;
}

export function offsetBuffer(data: IObjData, distance2D: number): Array<circleParam | polygonParam | IPolylineParam> {
  if (distance2D === 0) return [];
  if (isCircleData(data)) {
    const oriRadius = data.definition.radius;
    const newRadius = oriRadius + distance2D;
    if (newRadius <= 0) return [];
    const newDefinition: circleParam = {
      center: data.definition.center,
      radius: newRadius,
      azimutO: data.definition.azimutO,
      plane: data.definition.plane,
    };
    return [newDefinition];
  } else if (isPolygonData(data)) {
    const oriRadius = data.definition.radius;
    const newRadius = oriRadius + distance2D;
    if (newRadius <= 0) return [];
    const newDefinition: polygonParam = {
      center: data.definition.center,
      radius: newRadius,
      sides: data.definition.sides,
      inscribed: data.definition.inscribed,
      angleO: data.definition.angleO,
      plane: data.definition.plane,
    };
    return [newDefinition];
  } else if (isLineData(data)) {
    return calculateOffsetBuffer(data.definition, distance2D);
  }
  return [];
}

/** An offset algorithm for polyline curves
 * Source: https://hal.inria.fr/inria-00518005/document 
 * 
 * @export
 * @param {IPolylineParam} data
 * @param {number} distance (left: positive, right: negative)
 * @return {*} 
 */
export function calculateOffsetBuffer(data: IPolylineParam, distance: number) {

  // * 1. Prepare Data.
  // **************************************************
  const { points, arcs, isClosed } = data;
  const pLine0: { p1: IPoint, bulge?: IArcLineParam | 0 }[] = [];
  for (let i = 0; i < points.length; i++) {
    const p0 = points[i];
    const arc = arcs[i];
    const bulge = arc !== undefined && arc !== 0 ? arc : 0;
    pLine0.push({ p1: p0, bulge });
  }
  if (isClosed) {
    const arc = arcs[0];
    const bulge = arc !== undefined && arc !== 0 ? arc : 0;
    pLine0.push({ p1: points[0], bulge });

    const arc1 = arcs[1];
    const bulge1 = arc1 !== undefined && arc1 !== 0 ? arc1 : 0;
    pLine0.push({ p1: points[1], bulge: bulge1 });
  }
  // * 2. Pretreatment Data. (Local self-intersections)
  // **************************************************
  // for (let i = 0; i < SEG0.length; i++) {
  //   const s1 = SEG0[i];
  //   const s2 = SEG0[i + 1];
  //   if (s2) {
  //     if (s1.bulge === 0 && s2.bulge) {
  //       // Line-Arc
  //       const a = arcLineToArcParam(s2.p1, s2.p2, s2.bulge);
  //       const p = intersectArcLine(s1.p1, s1.p2, a, true);
  //       if (p.length > 1) {

  //       }

  //     } else if (s1.bulge && s2.bulge === 0) {
  //       // Arc-Line
  //       const a = arcLineToArcParam(s1.p1, s1.p2, s1.bulge);
  //       const p = intersectArcLine(s2.p1, s2.p2, a, true);
  //       if (p.length > 1) {

  //       }
  //     } else if (s1.bulge && s2.bulge) {
  //       // Arc-Line
  //       const a0 = arcLineToArcParam(s1.p1, s1.p2, s1.bulge);
  //       const a1 = arcLineToArcParam(s2.p1, s2.p2, s2.bulge);
  //       const p = intersectArcArc(a0, a1, true, true);
  //       if (p.length > 1) {

  //       }
  //     }
  //   }
  // }

  // * 3. Calculate offset curves
  // **************************************************
  const SEG1 = offsetSegments(pLine0, distance);
  const SEG2 = offsetSegments(pLine0, -distance);

  // * 4. Trim/join offset neighbouring segments
  // **************************************************
  const pLine1 = trimJoinOffsetSegments(pLine0, SEG1, distance);
  const pLine2 = trimJoinOffsetSegments(pLine0, SEG2, -distance);
  if (!isClosed) {
    pLine2[pLine2.length - 1].bulge = {
      center: copyIPoint(pLine0[pLine0.length - 1].p1),
      radius: Math.abs(distance),
      p1Tangent: false,
      p3Tangent: false,
      direction: distance < 0 ? ORIENT.CW : ORIENT.CCW,
    };
    pLine2.push({ p1: pLine1[pLine1.length - 1].p1, bulge: 0 });
    pLine2.unshift({
      p1: pLine1[0].p1, bulge: {
        center: copyIPoint(pLine0[0].p1),
        radius: Math.abs(distance),
        p1Tangent: false,
        p3Tangent: false,
        direction: distance < 0 ? ORIENT.CW : ORIENT.CCW,
      }
    });
  }

  // * 5. Clipping algorithm
  // **************************************************
  const sInt = splitSegmentsByintersections(pLine1, pLine2);
  const p: { p1: IPoint; bulge: 0 | IArcLineParam | undefined; isInt?: boolean }[][] = [];
  let currP: { p1: IPoint; bulge: 0 | IArcLineParam | undefined; isInt: boolean; }[] = [];
  for (const currS of sInt) {
    if (!currS.isInt) {
      currP.push(currS);
    } else {
      currP.push(currS);
      p.push(currP);
      currP = [currS];
    }
  }
  if (currP.length) {
    p.push(currP);
  }

  const tmpArray1 = [];
  let currIndx = 0
  for (let k = 0; k < p.length; k++) {
    const currS = p[k];
    const intersections: number[] = [];
    for (let i = 0; i < currS.length; i++) {
      const s00 = currS[i];
      const s01 = currS[i + 1];
      if (s01) {
        currIndx++;
        for (let j = 0; j < pLine0.length; j++) {
          const s10 = pLine0[j];
          const s11 = pLine0[j + 1];
          if (s11) {
            let res: IPoint[] = [];
            if (s00.bulge === 0 && s10.bulge === 0) {
              const int = intersect2D(s00.p1, s01.p1, s10.p1, s11.p1, true);
              if (int && int.p) res = [int.p];

            } else if (s00.bulge === 0 && s10.bulge) {
              const arc = arcLineToArcParam(s10.p1, s11.p1, s10.bulge);
              res = intersectArcLine(s00.p1, s01.p1, arc, true, true);

            } else if (s00.bulge && s10.bulge === 0) {
              const arc = arcLineToArcParam(s00.p1, s01.p1, s00.bulge);
              res = intersectArcLine(s10.p1, s11.p1, arc, true, true);

            } else if (s00.bulge && s10.bulge) {
              const arc0 = arcLineToArcParam(s00.p1, s01.p1, s00.bulge);
              const arc1 = arcLineToArcParam(s10.p1, s11.p1, s10.bulge);
              res = intersectArcArc(arc0, arc1, true, true);
            }
            if (res.length) {
              intersections.push(currIndx);
            }
          }
        }
      }
    }
    if (intersections.length === 0) {
      tmpArray1.push(p[k]);
    }
  }

  const array = [];
  let discard = false;
  for (let i = 0; i < tmpArray1.length; i++) {
    const s = tmpArray1[i];
    discard = false;
    for (let j = 0; j < s.length; j++) {
      const s00 = s[j];
      const s01 = s[j + 1];
      if (s01) {
        for (let j = 0; j < pLine0.length; j++) {
          const s10 = pLine0[j];
          const s11 = pLine0[j + 1];
          if (s11) {
            if (s00.bulge === 0 && s10.bulge === 0) {
              let d = distancePointToLine2D(s00.p1.x, s00.p1.y, s10.p1.x, s10.p1.y, s11.p1.x, s11.p1.y, true);
              if (isSmallerThan(d, Math.abs(distance))) {
                discard = true
                break;
              }
              d = distancePointToLine2D(s01.p1.x, s01.p1.y, s10.p1.x, s10.p1.y, s11.p1.x, s11.p1.y, true);
              if (isSmallerThan(d, Math.abs(distance))) {
                discard = true
                break;
              }
              d = distancePointToLine2D(s10.p1.x, s10.p1.y, s00.p1.x, s00.p1.y, s01.p1.x, s01.p1.y, true);
              if (isSmallerThan(d, Math.abs(distance))) {
                discard = true
                break;
              }
              d = distancePointToLine2D(s11.p1.x, s11.p1.y, s00.p1.x, s00.p1.y, s01.p1.x, s01.p1.y, true);
              if (isSmallerThan(d, Math.abs(distance))) {
                discard = true
                break;
              }

            } else if (s00.bulge === 0 && s10.bulge) {


            } else if (s00.bulge && s10.bulge === 0) {


            } else if (s00.bulge && s10.bulge) {

            }
          }
        }
        if (discard) {
          break;
        }
      }
    }
    if (!discard) {
      array.push(s);
    }
  }

  // * 6 . Closed curves
  // **************************************************
  if (isClosed) {
    if (array.length) {
      array[0].shift();
      if (array[0].length === 1) {
        array.shift();
      } else {
        const last = array[array.length - 1];
        array[0].unshift(last[last.length - 1]);
      }
    }
  }

  const totalRes = [];
  let res: IPolylineParam = {
    arcs: [],
    isClosed: false,
    points: [],
  }
  for (const s of array) {
    // if (tmpArray1.indexOf(s) !== -1) {
    for (const pc of s) {
      const last = res.points[res.points.length - 1]
      if (last && vector3Equals(last, pc.p1)) {
        continue;
      }
      res.points.push(pc.p1);
      res.arcs.push(pc.bulge ? pc.bulge : 0);
    }
    // } else {
    if (res.points.length > 0) {
      // res.points.push(s.p1);
      totalRes.push(res);
    }
    res = {
      arcs: [],
      isClosed: false,
      points: [],
    }
  }
  if (res.points.length > 1) {
    totalRes.push(res);
  }
  return totalRes;
}
function offsetSegments(pLine0: { p1: IPoint, bulge?: IArcLineParam | 0 }[], distance: number) {
  const rectAngle = Math.PI * 0.5;
  const SEG1: { p1: IPoint, p2: IPoint, bulge?: IArcLineParam | 0 }[] = [];
  let angle1, p1, p2;
  for (let i = 0; i < pLine0.length; i++) {
    const s0 = pLine0[i];
    const s1 = pLine0[i + 1];
    if (s1 !== undefined) {
      if (s0.bulge) {
        angle1 = getArcStartAngle(s0.p1, s0.bulge);
        p1 = getPolarPoint(s0.p1, angle1 + rectAngle, distance);
        angle1 = getArcEndAngle(s0.bulge, s1.p1);
        p2 = getPolarPoint(s1.p1, angle1 + rectAngle, distance);
        SEG1.push({
          p1, p2, bulge: {
            center: copyIPoint(s0.bulge.center),
            radius: vectorDist3D(s0.bulge.center, p1),
            p1Tangent: s0.bulge.p1Tangent,
            p3Tangent: s0.bulge.p3Tangent,
            direction: s0.bulge.direction,
          }
        });
      } else {
        angle1 = lineAngle2p(s0.p1, s1.p1);
        p1 = getPolarPoint(s0.p1, angle1 + rectAngle, distance);
        p2 = getPolarPoint(s1.p1, angle1 + rectAngle, distance);
        SEG1.push({ p1, p2, bulge: 0 });
      }
    }
  }
  return SEG1;
}
function trimJoinOffsetSegments(pLine0: { p1: IPoint, bulge?: IArcLineParam | 0 }[], SEG1: { p1: IPoint, p2: IPoint, bulge?: IArcLineParam | 0 }[], distance: number) {

  enum intersType { TIP, FIP, PFIP, NFIP };
  const pLine1: { p1: IPoint, bulge?: IArcLineParam | 0 }[] = [];
  pLine1.push(SEG1[0]);
  for (let i = 0; i < SEG1.length; i++) {
    const s0 = SEG1[i];
    const s1 = SEG1[i + 1];
    if (s1) {
      if (s0.bulge === 0 && s1.bulge === 0) {
        // 2 segments
        const res = intersect2D(s0.p1, s0.p2, s1.p1, s1.p2);
        if (res?.p) {
          if (res.ua >= 0 && res.ua <= 1 && res.ub >= 0 && res.ub <= 1) {
            // TIP - TIP
            pLine1.push({ p1: res.p, bulge: 0 })
          } else if (res.ua > 1 && res.ub < 0) {
            // PFIP - FIP
            pLine1.push({ p1: res.p, bulge: 0 })
          } else {
            pLine1.push({ p1: s0.p2, bulge: 0 });
            pLine1.push({ p1: s1.p1, bulge: 0 });
          }
        }

      } else if (s0.bulge === 0 && s1.bulge) {
        // segment - arc
        const arc = arcLineToArcParam(s1.p1, s1.p2, s1.bulge);
        const res = intersectArcLine(s0.p1, s0.p2, arc, false);
        if (res.length === 1 || vector3Equals(s0.p2, s1.p1)) {
          pLine1.push({
            p1: res[0] ?? s0.p2, bulge: {
              center: s1.bulge.center,
              direction: s1.bulge.direction,
              radius: s1.bulge.radius,
              p1Tangent: false,
              p3Tangent: false,
            }
          })
        } else if (res.length === 2) {
          if (vector3Equals(res[0], res[1])) {
            pLine1.push({
              p1: res[0], bulge: {
                center: s1.bulge.center,
                direction: s1.bulge.direction,
                radius: s1.bulge.radius,
                p1Tangent: false,
                p3Tangent: false,
              }
            })
          } else {
            const uA0 = getFactorLinePosition2p(s0.p1, s0.p2, res[0]) as number;
            const uA1 = getFactorLinePosition2p(s0.p1, s0.p2, res[1]) as number;
            let p, s0i;
            if (Math.abs(uA0 - 1) < Math.abs(uA1 - 1)) {
              p = res[0];
              s0i = (uA0 >= 0 && uA0 <= 1) ? intersType.TIP : (uA0 > 1) ? intersType.PFIP : intersType.NFIP;
            } else {
              p = res[1];
              s0i = (uA1 >= 0 && uA1 <= 1) ? intersType.TIP : (uA1 > 1) ? intersType.PFIP : intersType.NFIP;
            }
            const angleP1 = normalizeAngle(lineAngle2p(arc.center, p));
            const angleStart = mecDirectionMathDirection(arc.azimutO);
            const angleEnd = mecDirectionMathDirection(arc.azimutO + arc.angleCenter);
            const s1i = (isBetweenDirection(angleP1, angleStart, angleEnd, s1.bulge.direction)) ? intersType.TIP : intersType.FIP;

            if (s0i === intersType.TIP && s1i === intersType.TIP) {
              pLine1.push({
                p1: p, bulge: {
                  center: s1.bulge.center,
                  direction: s1.bulge.direction,
                  radius: s1.bulge.radius,
                  p1Tangent: false,
                  p3Tangent: false,
                }
              });
            } else if (s0i === intersType.PFIP && s1i === intersType.FIP) {
              const d = pointLinePositionXY(s0.p1, s0.p2, s1.p1);
              const dir = d > 0 ? ORIENT.CW : ORIENT.CCW;
              pLine1.push({
                p1: s0.p2, bulge: {
                  center: pLine0[i + 1].p1,
                  direction: dir,
                  radius: Math.abs(distance),
                  p1Tangent: false,
                  p3Tangent: false,
                }
              });
              pLine1.push({
                p1: s1.p1, bulge: {
                  center: s1.bulge.center,
                  direction: s1.bulge.direction,
                  radius: s1.bulge.radius,
                  p1Tangent: false,
                  p3Tangent: false,
                }
              });
            } else if (s0i === intersType.NFIP && s1i === intersType.TIP) {
              pLine1.push({ p1: s0.p2, bulge: 0 });
              pLine1.push({
                p1: s1.p1, bulge: {
                  center: s1.bulge.center,
                  direction: s1.bulge.direction,
                  radius: s1.bulge.radius,
                  p1Tangent: false,
                  p3Tangent: false,
                }
              });
            } else if (s0i === intersType.TIP && s1i === intersType.FIP) {
              pLine1.push({ p1: s0.p2, bulge: 0 });
              pLine1.push({
                p1: s1.p1, bulge: {
                  center: s1.bulge.center,
                  direction: s1.bulge.direction,
                  radius: s1.bulge.radius,
                  p1Tangent: false,
                  p3Tangent: false,
                }
              });
            }
          }
        } else {
          const d = pointLinePositionXY(s0.p1, s0.p2, s1.p1);
          const dir = d > 0 ? ORIENT.CW : ORIENT.CCW;
          if (!isZero(d)) {
            pLine1.push({
              p1: s0.p2, bulge: {
                center: pLine0[i + 1].p1,
                direction: dir,
                radius: Math.abs(distance),
                p1Tangent: false,
                p3Tangent: false,
              }
            });
          }
          pLine1.push({
            p1: s1.p1, bulge: {
              center: s1.bulge.center,
              direction: s1.bulge.direction,
              radius: s1.bulge.radius,
              p1Tangent: false,
              p3Tangent: false,
            }
          });
        }
      } else if (s0.bulge && s1.bulge === 0) {
        // arc - segment
        const arc = arcLineToArcParam(s0.p1, s0.p2, s0.bulge);
        const res = intersectArcLine(s1.p1, s1.p2, arc, false);
        if (res.length === 1 || vector3Equals(s0.p2, s1.p1)) {
          pLine1.push({ p1: res[0] ?? s0.p2, bulge: 0 });

        } else if (res.length === 2) {
          if (vector3Equals(res[0], res[1])) {
            pLine1.push({ p1: res[0], bulge: 0 });
          } else {
            const uA0 = getFactorLinePosition2p(s1.p1, s1.p2, res[0]) as number;
            const uA1 = getFactorLinePosition2p(s1.p1, s1.p2, res[1]) as number;
            let p, s1i;
            if (Math.abs(uA0) < Math.abs(uA1)) {
              p = res[0];
              s1i = (uA0 >= 0 && uA0 <= 1) ? intersType.TIP : (uA0 > 1) ? intersType.PFIP : intersType.NFIP;
            } else {
              p = res[1];
              s1i = (uA1 >= 0 && uA1 <= 1) ? intersType.TIP : (uA1 > 1) ? intersType.PFIP : intersType.NFIP;
            }
            const angleP1 = normalizeAngle(lineAngle2p(arc.center, p));
            const angleStart = mecDirectionMathDirection(arc.azimutO);
            const angleEnd = mecDirectionMathDirection(arc.azimutO + arc.angleCenter);
            const s0i = (isBetweenDirection(angleP1, angleStart, angleEnd, s0.bulge.direction)) ? intersType.TIP : intersType.FIP;

            if (s0i === intersType.TIP && s1i === intersType.TIP) {
              pLine1.push({ p1: p, bulge: 0 });

            } else if (s0i === intersType.FIP && s1i === intersType.NFIP) {
              const d = pointLinePositionXY(s1.p1, s1.p2, s0.p2);
              if (!isZero(d)) {
                const dir = d > 0 ? ORIENT.CW : ORIENT.CCW;
                pLine1.push({
                  p1: s0.p2, bulge: {
                    center: pLine0[i + 1].p1,
                    direction: dir,
                    radius: Math.abs(distance),
                    p1Tangent: false,
                    p3Tangent: false,
                  }
                });
              }
              pLine1.push({ p1: s1.p1, bulge: 0 });
            } else if (s0i === intersType.TIP && s1i === intersType.PFIP) {
              pLine1.push({ p1: s0.p2, bulge: 0 });
              pLine1.push({ p1: s1.p1, bulge: 0 });
            } else if (s0i === intersType.FIP && s1i === intersType.TIP) {
              pLine1.push({ p1: s0.p2, bulge: 0 });
              pLine1.push({ p1: s1.p1, bulge: 0 });
            }
          }
        } else {
          const d = pointLinePositionXY(s1.p1, s1.p2, s0.p2);
          if (!isZero(d)) {
            const dir = d > 0 ? ORIENT.CW : ORIENT.CCW;
            pLine1.push({
              p1: s0.p2, bulge: {
                center: pLine0[i + 1].p1,
                direction: dir,
                radius: Math.abs(distance),
                p1Tangent: false,
                p3Tangent: false,
              }
            });
          }
          pLine1.push({ p1: s1.p1, bulge: 0 });
        }
      } else if (s0.bulge && s1.bulge) {
        // arc - arc
        const arc0 = arcLineToArcParam(s0.p1, s0.p2, s0.bulge);
        const arc1 = arcLineToArcParam(s1.p1, s1.p2, s1.bulge);
        const res = intersectArcArc(arc0, arc1, false, false);
        if (vector3Equals(s0.p2, s1.p1)) {
          pLine1.push({
            p1: s1.p1, bulge: {
              center: s1.bulge.center,
              direction: s1.bulge.direction,
              radius: vectorDist3D(s1.bulge.center, s1.p1),
              p1Tangent: false,
              p3Tangent: false,
            }
          });
        } else if (res.length) {
          const pto = pLine0[i + 1].p1;
          const d0 = vectorDist3D(pto, res[0]);
          const d1 = vectorDist3D(pto, res[1]);
          const p = d0 < d1 ? res[0] : res[1];

          const angleP0 = normalizeAngle(lineAngle2p(arc0.center, p));
          const angleStart0 = mecDirectionMathDirection(arc0.azimutO);
          const angleEnd0 = mecDirectionMathDirection(arc0.azimutO + arc0.angleCenter);
          const s0i = (isBetweenDirection(angleP0, angleStart0, angleEnd0, s0.bulge.direction)) ? intersType.TIP : intersType.FIP;

          const angleP1 = normalizeAngle(lineAngle2p(arc1.center, p));
          const angleStart1 = mecDirectionMathDirection(arc1.azimutO);
          const angleEnd1 = mecDirectionMathDirection(arc1.azimutO + arc1.angleCenter);
          const s1i = (isBetweenDirection(angleP1, angleStart1, angleEnd1, s1.bulge.direction)) ? intersType.TIP : intersType.FIP;


          if ((s0i === intersType.TIP && s1i === intersType.TIP) || (s0i === intersType.FIP && s1i === intersType.FIP)) {
            pLine1.push({
              p1: p, bulge: {
                center: s1.bulge.center,
                direction: s1.bulge.direction,
                radius: vectorDist3D(s1.bulge.center, s1.p1),
                p1Tangent: false,
                p3Tangent: false,
              }
            });
          } else if ((s0i === intersType.TIP && s1i === intersType.FIP) || (s0i === intersType.FIP && s1i === intersType.TIP)) {
            const dir = s0.bulge.direction === ORIENT.CW ? ORIENT.CCW : ORIENT.CW;
            pLine1.push({
              p1: s0.p2, bulge: {
                center: pto,
                direction: dir,
                radius: Math.abs(distance),
                p1Tangent: false,
                p3Tangent: false,
              }
            });
            pLine1.push({
              p1: s1.p1, bulge: {
                center: s1.bulge.center,
                direction: s1.bulge.direction,
                radius: vectorDist3D(s1.bulge.center, s1.p1),
                p1Tangent: false,
                p3Tangent: false,
              }
            });
          }
        } else {
          const dir = s0.bulge.direction === ORIENT.CW ? ORIENT.CCW : ORIENT.CW;
          pLine1.push({
            p1: s0.p2, bulge: {
              center: pLine0[i + 1].p1,
              direction: dir,
              radius: Math.abs(distance),
              p1Tangent: false,
              p3Tangent: false,
            }
          });
          pLine1.push({
            p1: s1.p1, bulge: {
              center: s1.bulge.center,
              direction: s1.bulge.direction,
              radius: vectorDist3D(s1.bulge.center, s1.p1),
              p1Tangent: false,
              p3Tangent: false,
            }
          });
        }

      }
    } else {
      // Last point
      pLine1.push({ p1: s0.p2, bulge: 0 })
    }
  }
  return pLine1;
}
function splitSegmentsByintersections(pLine1: { p1: IPoint, bulge?: IArcLineParam | 0 }[], pLine2: { p1: IPoint, bulge?: IArcLineParam | 0 }[]) {

  const intersections: { p: IPoint, f: number }[] = [];
  const paux: { p1: IPoint, bulge: 0 | IArcLineParam | undefined, isInt: boolean }[] = [];
  const p: { p1: IPoint, bulge: 0 | IArcLineParam | undefined, isInt: boolean }[] = [];
  for (let i = 0; i < pLine1.length; i++) {
    const s10 = pLine1[i];
    const s11 = pLine1[i + 1];

    const last = paux[paux.length - 1];
    if (last && vector3Equals(last.p1, s10.p1)) { paux.pop(); }
    paux.push({ p1: s10.p1, bulge: s10.bulge, isInt: false });

    if (s11) {
      intersections.length = 0;
      for (let j = 0; j < pLine2.length; j++) {
        const s20 = pLine2[j];
        const s21 = pLine2[j + 1];
        if (s21) {
          let res: { p: IPoint, f: number }[] = [];
          if (s10.bulge === 0 && s20.bulge === 0) {
            const int = intersect2D(s10.p1, s11.p1, s20.p1, s21.p1, true);
            if (int && int.p) res = [{ p: int.p, f: int.ua }];

          } else if (s10.bulge === 0 && s20.bulge) {
            const arc = arcLineToArcParam(s20.p1, s21.p1, s20.bulge);
            const intPto = intersectArcLine(s10.p1, s11.p1, arc, true, true);
            for (const ip of intPto) {
              if (isNaN(ip.x)) debugger;
              const fPto = getFactorLinePosition2p(s10.p1, s11.p1, ip) as number;
              res.push({ p: ip, f: fPto });
            }

          } else if (s10.bulge && s20.bulge === 0) {
            const arc = arcLineToArcParam(s10.p1, s11.p1, s10.bulge);
            const intPto = intersectArcLine(s20.p1, s21.p1, arc, true, true);
            for (const ip of intPto) {
              if (isNaN(ip.x)) debugger;
              const fPto = getFactorPointArcPosition(arc, ip) as number;
              res.push({ p: ip, f: fPto });
            }

          } else if (s10.bulge && s20.bulge) {
            const arc1 = arcLineToArcParam(s10.p1, s11.p1, s10.bulge);
            const arc2 = arcLineToArcParam(s20.p1, s21.p1, s20.bulge);
            const intPto = intersectArcArc(arc1, arc2, true, true);
            for (const ip of intPto) {
              if (isNaN(ip.x)) debugger;
              const fPto = getFactorPointArcPosition(arc1, ip) as number;
              res.push({ p: ip, f: fPto });
            }
          }
          if (res?.length) {
            intersections.push(...res);
          }
        }
      }
      for (const int of intersections.sort((a, b) => a.f - b.f)) {
        const last = paux[paux.length - 1];
        if (!vector3Equals(last.p1, int.p)) {
          paux.push({ p1: int.p, bulge: s10.bulge, isInt: true });
        }
      }
    }
  }

  for (let i = 0; i < paux.length; i++) {
    const s00 = paux[i];
    const s01 = paux[i + 1];

    const last = p[p.length - 1];
    if (last && vector3Equals(last.p1, s00.p1)) { p.pop(); }
    p.push({ p1: s00.p1, bulge: s00.bulge, isInt: s00.isInt });

    if (s01) {
      intersections.length = 0;
      for (let j = 0; j < paux.length; j++) {
        const s10 = paux[j];
        const s11 = paux[j + 1];
        if (s11 && i !== j && i !== j - 1 && i !== j + 1) {
          let res: { p: IPoint, f: number }[] = [];
          if (s00.bulge === 0 && s10.bulge === 0) {
            const int = intersect2D(s00.p1, s01.p1, s10.p1, s11.p1, true);
            if (int && int.p) res = [{ p: int.p, f: int.ua }];

          } else if (s00.bulge === 0 && s10.bulge) {
            const arc = arcLineToArcParam(s10.p1, s11.p1, s10.bulge);
            const intPto = intersectArcLine(s00.p1, s01.p1, arc, true, true);
            for (const ip of intPto) {
              if (isNaN(ip.x)) debugger;
              const fPto = getFactorLinePosition2p(s00.p1, s01.p1, ip) as number;
              res.push({ p: ip, f: fPto });
            }

          } else if (s00.bulge && s10.bulge === 0) {
            const arc = arcLineToArcParam(s00.p1, s01.p1, s00.bulge);
            const intPto = intersectArcLine(s10.p1, s11.p1, arc, true, true);
            for (const ip of intPto) {
              if (isNaN(ip.x)) debugger;
              const fPto = getFactorPointArcPosition(arc, ip) as number;
              res.push({ p: ip, f: fPto });
            }

          } else if (s00.bulge && s10.bulge) {
            const arc1 = arcLineToArcParam(s00.p1, s01.p1, s00.bulge);
            const arc2 = arcLineToArcParam(s10.p1, s11.p1, s10.bulge);
            const intPto = intersectArcArc(arc1, arc2, true, true);
            for (const ip of intPto) {
              if (isNaN(ip.x)) debugger;
              const fPto = getFactorPointArcPosition(arc1, ip) as number;
              res.push({ p: ip, f: fPto });
            }
          }
          if (res?.length) {
            intersections.push(...res);

          }
        }
      }
      for (const int of intersections.sort((a, b) => a.f - b.f)) {
        const last = p[p.length - 1];
        if (!vector3Equals(last.p1, int.p)) {
          p.push({ p1: int.p, bulge: s00.bulge, isInt: true })
        }
      }
    }
  }
  return p;
}

/* Clipper - an open source freeware library for
 clipping and offsetting lines and polygons.
 Source: https://github.com/junmer/clipper-lib
*/

export function calculateOffsetBuffer0(data: IPolylineParam, distance: number, endType: number = ClipperLib.EndType.etOpenButt): IPolylineParam[] {

  if (data.isClosed) endType = ClipperLib.EndType.etClosedLine;
  const lineData = data.points;
  const scale = 10000;

  const path = lineData.map(l => ({ X: l.x, Y: l.y }));
  ClipperLib.JS.ScaleUpPaths([path], scale);
  const miterLimit = 5;
  const arcTolerance = 0.001;
  const co = new ClipperLib.ClipperOffset(miterLimit, arcTolerance);
  co.MiterLimit = 10;
  co.AddPath(path, ClipperLib.JoinType.jtMiter, endType);
  const delta = distance;
  var offsetted_paths = new ClipperLib.Paths() as any;
  co.Execute(offsetted_paths, delta * scale);

  const scaleInv = 1 / scale;
  const endPaths = offsetted_paths.map((p: any) => {
    return p.map((g: any) => ({ X: g.X * scaleInv, Y: g.Y * scaleInv }))
  });

  return endPaths.map((p: any) => ({
    arcs: [],
    isClosed: true,
    points: p.map((l: { X: any; Y: any; }) => ({ x: l.X, y: l.Y, z: 0 })),
  }));
}

/* An algorithm for generating geometric buffers for vector feature layers
 Source: https://www.tandfonline.com/doi/full/10.1080/10095020.2012.747643
*/

export function calculateOffsetLine(data: IPolylineParam, distance: number): IPolylineParam {

  const points = data.points;
  const lineData = data.isClosed ? [...points, points[0]] : points;
  const lineSegments = IPointsToISegments(lineData);
  const rectAngle = Math.PI * 0.5;

  // * 1. Hacemos el offset de cada segmento.
  // ****************************************
  const offsetLinesAB: ISegment[] = [];
  let angle, p1, p2;
  for (let i: number = 0, l: number = lineSegments.length; i < l; i++) {
    const v1 = lineSegments[i].p1;
    const v2 = lineSegments[i].p2;
    angle = lineAngle2p(v1, v2);
    p1 = getPolarPoint(v1, angle + rectAngle, distance);
    p2 = getPolarPoint(v2, angle + rectAngle, distance);
    offsetLinesAB.push({ p1, p2 });
  }

  // * 2. Calculamos la intersección y ajustamos los segmentos calculados
  // ********************************************************************
  const offsetLines: ISegment[] = [];
  let lineA, lineB, res;
  for (let i = 0, l = offsetLinesAB.length; i < l; i++) {
    lineA = offsetLinesAB[i];
    lineB = offsetLinesAB[i + 1];
    if (lineB === undefined && data.isClosed) {
      lineB = offsetLinesAB[0];
    }
    if (lineB) {
      res = intersect2D(lineA.p1, lineA.p2, lineB.p1, lineB.p2);
      if (res?.p && res.ua >= 0 && res.ub <= 1) {
        //  --> Truncado
        lineA.p2 = copyIPoint(res.p);
        lineB.p1 = copyIPoint(res.p);
        offsetLines.push({ p1: lineA.p1, p2: lineA.p2 });
      } else {
        // TODO: ---> meter aqui un arco/bisel/plano según configuración
        offsetLines.push({ p1: lineA.p1, p2: lineA.p2 });
        offsetLines.push({ p1: lineA.p2, p2: lineB.p1 });
      }
    }
  }
  if (data.isClosed) {
    offsetLines[0].p1 = offsetLinesAB[0].p1;
  } else {
    const lastSeg = offsetLinesAB[offsetLinesAB.length - 1];
    offsetLines.push({ p1: lastSeg.p1, p2: lastSeg.p2 });
  }

  // **************************** Resultado ***************************
  const resLinesBuffer: IPolylineParam = {
    arcs: [],
    isClosed: data.isClosed,
    points: offsetLines.map(o => o.p1),
  }
  if (!data.isClosed) {
    const lastPto = offsetLines[offsetLines.length - 1].p2;
    resLinesBuffer.points.push(lastPto);
  }
  return resLinesBuffer;
}
export function calculateOffsetBuffer1(data: IPolylineParam, distance: number): IPolylineParam[] {

  const points = data.points;
  const lineData = data.isClosed ? [...points, points[0]] : points;

  const lineSegments = IPointsToISegments(lineData);
  const lineIsclosed = false;
  const rectAngle = Math.PI * 0.5;

  // * 1. Hacemos el offset de cada segmento.
  // ****************************************
  const offsetLinesAB: ISegment[] = [];
  const offsetLinesBA: ISegment[] = [];
  let angle, p1, p2;
  for (let i: number = 0, l: number = lineSegments.length; i < l; i++) {
    const v1 = lineSegments[i].p1;
    const v2 = lineSegments[i].p2;
    angle = lineAngle2p(v1, v2);
    p1 = getPolarPoint(v1, angle + rectAngle, distance);
    p2 = getPolarPoint(v2, angle + rectAngle, distance);
    offsetLinesAB.push({ p1, p2 });

    if (!lineIsclosed) {
      p1 = getPolarPoint(v2, angle - rectAngle, distance);
      p2 = getPolarPoint(v1, angle - rectAngle, distance);
      offsetLinesBA.push({ p1, p2 });
    }
  }
  // Cierre del area de influencia (Final de la línea)
  if (!lineIsclosed) {
    offsetLinesAB.push({
      p1: copyIPoint(offsetLinesAB[offsetLinesAB.length - 1].p2),
      p2: copyIPoint(offsetLinesBA[offsetLinesBA.length - 1].p1),
    });
  }
  for (let i = offsetLinesBA.length - 1, l = 0; i >= l; i--) {
    offsetLinesAB.push(offsetLinesBA[i]);
  }
  // Cierre del area de influencia (Inicio de la línea)
  if (!lineIsclosed) {
    offsetLinesAB.push({
      p1: copyIPoint(offsetLinesAB[offsetLinesAB.length - 1].p2),
      p2: copyIPoint(offsetLinesAB[0].p1),
    });
  }

  // * 2. Calculamos la intersección y ajustamos los segmentos calculados
  // ********************************************************************
  const offsetLines = [];
  let lineA, lineB, res;
  for (let i = 0, l = offsetLinesAB.length; i < l; i++) {
    lineA = offsetLinesAB[i];
    lineB = offsetLinesAB[i + 1];
    if (lineB === undefined) lineB = offsetLinesAB[0];

    res = intersect2D(lineA.p1, lineA.p2, lineB.p1, lineB.p2);
    if (res?.p && res.ua >= 0 && res.ub <= 1) {
      //  --> Truncado
      lineA.p2 = copyIPoint(res.p); // asignIPoint(res.p, lineA.p2);
      lineB.p1 = copyIPoint(res.p); // asignIPoint(res.p, lineB.p1);
      offsetLines.push({ p1: lineA.p1, p2: lineA.p2 });
    } else {
      // TODO: ---> meter aqui un arco/bisel/plano según configuración
      offsetLines.push({ p1: lineA.p1, p2: lineA.p2 });
      offsetLines.push({ p1: lineA.p2, p2: lineB.p1 });
    }
  }

  // * 3. Intersectamos consigo mismo el buffer
  // ******************************************
  const inters = intersectISegments(offsetLines);

  // * 4. Calculo de vertice mas a la izquierda de todos los segmentos para iniciar el recorrido
  // *******************************************************************************************
  const ptosBuffer: IPoint[] = [];
  let startPto: number = -1;
  let xLeft = Infinity;
  for (const s of offsetLines) {
    const indx = ptosBuffer.push(s.p1) - 1;
    // Calculo vertice de la izquierda
    if (s.p1.x < xLeft) {
      xLeft = s.p1.x;
      startPto = indx;
    }
  }
  ptosBuffer.push(offsetLines[offsetLines.length - 1].p2);

  // * 5. Recorrid0o de la línea exterior comprobando intersecciones
  // **************************************************************
  let contourNewPtos: IPoint[] = [];
  if (lineIsclosed && distance < 0 && inters.length > 0) {
    // No hay línea exterior ---> Pasamos a resolver agujeros
  } else {
    contourNewPtos = resolveOutterLoop(startPto, ptosBuffer, inters);
    const intrOriLine = isLineBCrossLineA(contourNewPtos, lineData);
    if (intrOriLine && lineIsclosed) contourNewPtos = [];
  }

  // * 6. Resolvemos intersecciones no visitadas (huecos interiores)
  // ***************************************************************
  const restOfIntersections = inters.filter((i) => {
    if (i.visited) return false;
    // descartamos las intersecciones que estan dentro del area de influencia
    const dist = distance2DPointToPolyline(i.p, lineData);
    if (isSmallerThan(dist, Math.abs(distance))) return false;
    return true;
  });
  const holes = resolveInnerLoop(
    ptosBuffer,
    restOfIntersections,
    lineData,
    distance
  );

  // **************************** Resultado ***************************
  const resLinesBuffer: IPolylineParam[] = [{
    arcs: [],
    isClosed: true,
    points: contourNewPtos,
  }]
  for (const h of holes) {
    resLinesBuffer.push({
      arcs: [],
      isClosed: true,
      points: h,
    })
  }
  return resLinesBuffer;
}

function intersectISegments(segments: ISegment[]): Iintersection[] {
  const intersc: Array<Iintersection> = [];
  let res: Iintersection;
  let intersection: { p: IPoint | null; ua: number; ub: number } | null;
  for (let i = 0, l = segments.length; i < l; i++) {
    const lineA = segments[i];
    for (let j = 0, m = segments.length; j < m; j++) {
      const lineB = segments[j];
      intersection = intersect2D(lineA.p1, lineA.p2, lineB.p1, lineB.p2, true);
      if (intersection && intersection.p) {
        if (intersection.ua === 0 && intersection.ub === 1) continue;
        if (intersection.ua === 0 && intersection.ub === 0) continue;
        if (intersection.ua === 1 && intersection.ub === 0) continue;
        if (intersection.ua === 1 && intersection.ub === 1) continue;
        res = {
          p: intersection.p,
          ua: intersection.ua,
          ub: intersection.ub,
          indxSegA: i,
          indxSegB: j,
          visited: false,
        };
        const noAdd = intersc.some((n) => n.indxSegA === j && n.indxSegB === i);
        if (!noAdd) intersc.push(res);
      }
    }
  }
  return intersc;
}

function resolveOutterLoop(
  startPto: number,
  ptosBuffer: IPoint[],
  inters: Iintersection[]
) {
  const newPtos = [];
  let c = startPto;
  newPtos.push(copyIPoint(ptosBuffer[c]));
  let currPto, nextPto, hasInters;
  let k = 0;
  while (k < 5000 && currPto !== startPto) {
    k++;

    if (k === 5000) debugger; // Salgo del bucle demasiadas iteraciones

    // Segmento actual
    currPto = ptosBuffer[c];
    nextPto = ptosBuffer[c + 1] ? ptosBuffer[c + 1] : ptosBuffer[0];

    // Existe alguna intersección no visitada en segmento actual
    let dist = vectorDist2D(currPto, nextPto);
    for (const intRes of inters) {
      if (
        intRes.visited === false &&
        isZero(pointLinePositionXY(intRes.p, currPto, nextPto))
      ) {
        const cDist = vectorDist2D(intRes.p, currPto);
        if (cDist < dist) {
          dist = cDist;
          hasInters = intRes;
        }
      }
    }

    if (hasInters) {
      hasInters.visited = true;
      newPtos.push(copyIPoint(hasInters.p));

      // Excepción para cuando existan dos puntos intersección consecutivos en el contorno
      // Retomo el camino por el nuevo segmento
      c = hasInters.indxSegB === c ? hasInters.indxSegA : hasInters.indxSegB;
      nextPto = ptosBuffer[c + 1];

      let intResPlus = hasConsecutiveOutterIntersection(
        hasInters.p,
        nextPto,
        inters
      );
      let w = 0;
      while (w < 5000 && intResPlus) {
        w++;
        if (intResPlus) {
          intResPlus.visited = true;
          newPtos.push(copyIPoint(intResPlus.p));
          c =
            intResPlus.indxSegB === c
              ? intResPlus.indxSegA
              : intResPlus.indxSegB;
          nextPto = ptosBuffer[c + 1];
          intResPlus = undefined;
          intResPlus = hasConsecutiveInnerIntersection(
            newPtos[newPtos.length - 1],
            nextPto,
            inters
          );
        }
      }
      // Cuando no hay intersecciones consecutivas añado el punto final de arista actual
      newPtos.push(copyIPoint(nextPto));
      hasInters = undefined;
    } else {
      newPtos.push(copyIPoint(nextPto));
    }
    c++;
    if (ptosBuffer[c] === undefined) c = 0;
    currPto = c;
  }
  return newPtos;
}
function hasConsecutiveOutterIntersection(
  prevInters: IPoint,
  nextPto: IPoint,
  intersections: Iintersection[]
) {
  let intResPlus;
  let dAux = vectorDist2D(prevInters, nextPto);
  for (const intRes of intersections) {
    if (
      intRes.visited === false &&
      isZero(pointLinePositionXY(intRes.p, prevInters, nextPto))
    ) {
      const f = getFactorLinePosition2p(prevInters, nextPto, intRes.p);
      if (f && f > 0) {
        const cDist = vectorDist2D(intRes.p, prevInters);
        if (dAux > cDist) {
          dAux = cDist;
          intResPlus = intRes;
        }
      }
    }
  }
  return intResPlus;
}

function resolveInnerLoop(
  ptosBuffer: IPoint[],
  intersections: Iintersection[],
  oriLine: IPoint[],
  distance2D: number
) {
  const holes = [];

  let newPtos: IPoint[];

  let startPoint: IPoint;
  let currPto: IPoint | undefined;
  let nextPto: IPoint;
  let indxEdge: number;
  let hasInters: Iintersection | undefined;
  let intersectionsVisited: Iintersection[] = [];

  for (const intersection of intersections) {
    let k = 0;
    if (intersection.visited === false) {
      // Punto de inicio del contorno interior
      startPoint = intersection.p;
      intersection.visited = true;
      newPtos = [copyIPoint(startPoint)];
      indxEdge = intersection.indxSegA;

      while (
        k < 5000 &&
        (currPto === undefined || !vector2Equals(currPto, startPoint))
      ) {
        k++;

        // Segmento actual
        currPto = newPtos[newPtos.length - 1];
        nextPto = ptosBuffer[indxEdge + 1]
          ? ptosBuffer[indxEdge + 1]
          : ptosBuffer[1];

        // Existe alguna intersección no visitada en segmento actual
        hasInters = hasConsecutiveInnerIntersection(
          currPto,
          nextPto,
          intersections
        );
        if (hasInters) {
          let q = 0;
          while (q < 5000 && hasInters) {
            q++;
            if (hasInters.visited === false) {
              const intrOriLine = getLineBCrossLineA(
                [currPto, hasInters.p],
                oriLine
              );
              if (intrOriLine) {
                // Punto invalido (cruza la línea original), cambiamos dirección de inicio
                resetLoop(intersection);
              } else {
                hasInters.visited = true;
                intersectionsVisited.push(hasInters);
                newPtos.push(copyIPoint(hasInters.p));
                currPto = newPtos[newPtos.length - 1];
                // Giramos de direccion al añadir un cruce
                indxEdge =
                  hasInters.indxSegB === indxEdge
                    ? hasInters.indxSegA
                    : hasInters.indxSegB;
                nextPto = ptosBuffer[indxEdge + 1];
                // Comprobacion si hay puntos intersección consecutivos de nuevo
                hasInters = hasConsecutiveInnerIntersection(
                  newPtos[newPtos.length - 1],
                  nextPto,
                  intersections
                );
              }
            } else {
              if (vector2Equals(hasInters.p, startPoint)) {
                // Cerramos current hole
                newPtos.push(copyIPoint(hasInters.p));
                holes.push(newPtos);
                intersectionsVisited = [];
                k = 5000;
                break;
              }
              resetLoop(intersection);
            }
          }
        } else {
          const dist = distance2DPointToPolyline(nextPto, oriLine);
          let intrOriLine = getLineBCrossLineA([currPto, nextPto], oriLine);
          if (intrOriLine && intrOriLine.p) {
            if (
              intrOriLine.p.x === oriLine[0].x &&
              intrOriLine.p.y === oriLine[0].y
            ) {
              intrOriLine = null;
            } else if (
              intrOriLine.p.x === oriLine[oriLine.length - 1].x &&
              intrOriLine.p.y === oriLine[oriLine.length - 1].y
            ) {
              intrOriLine = null;
            }
          }
          if (intrOriLine || isSmallerThan(dist, Math.abs(distance2D))) {
            // Punto invalido (dentro del area de influencia), cambiamos dirección de inicio
            resetLoop(intersection);
          } else {
            newPtos.push(copyIPoint(nextPto));
            indxEdge++;
            if (indxEdge > ptosBuffer.length - 1) indxEdge = 0;
            currPto = newPtos[newPtos.length - 1];
          }
        }
      }
    }
  }
  function resetLoop(intersection: Iintersection) {
    newPtos = [copyIPoint(startPoint)];
    indxEdge = intersection.indxSegB;
    currPto = undefined;
    hasInters = undefined;
    intersectionsVisited.forEach((i) => (i.visited = false));
    intersectionsVisited = [];
  }
  return holes;
}
function hasConsecutiveInnerIntersection(
  prevInters: IPoint,
  nextPto: IPoint,
  intersections: Iintersection[]
) {
  let intResPlus;
  let dAux = vectorDist2D(prevInters, nextPto);
  for (const intRes of intersections) {
    if (isZero(pointLinePositionXY(intRes.p, prevInters, nextPto))) {
      const f = getFactorLinePosition2p(prevInters, nextPto, intRes.p);
      if (f && f > 0) {
        const cDist = vectorDist2D(intRes.p, prevInters);
        if (dAux > cDist) {
          dAux = cDist;
          intResPlus = intRes;
        }
      }
    }
  }
  return intResPlus;
}
