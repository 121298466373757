import { ButtonHTMLAttributes, FC, ReactNode } from "react";
import cn from "classnames";
import Tooltip from "shared/components/ui/tooltip";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: ReactNode;
  tooltip?: string;
  className?: string;
  isActive?: boolean;
}

function capitalizeFirstLetter(string?: string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}

const ToolbarButton: FC<Props> = ({ ...props }) => {
  const {
    className,
    children,
    icon,
    tooltip,
    title,
    isActive,
    ...rest
  } = props;
  const rootClassName = cn(
    "bg-transparent flex items-center focus:outline-none h-8 justify-center",
    isActive
      ? "text-white bg-gray-700 hover:bg-gray-600"
      : "text-gray-400 hover:text-white hover:bg-gray-700",
    className
  );
  return (
    tooltip ? (
      < Tooltip label={capitalizeFirstLetter(tooltip)} >
        <button className={rootClassName} {...rest} style={{ minWidth: "32px" }}>
          <div className="flex flex-col items-center justify-center">
            {icon || <span>{title}</span>}
            <span>{children}</span>
          </div>
        </button>
      </Tooltip >
    ) : (
      <button className={rootClassName} {...rest} style={{ minWidth: "32px" }}>
        <div className="flex flex-col items-center justify-center">
          {icon || <span>{title}</span>}
          <span>{children}</span>
        </div>
      </button>
    )
  );
};

export default ToolbarButton;
