import { Hypothesis } from "lib/models-struc/hypothesis/hypothesis";
import { LoadStructuralData } from "lib/models/structural/load";

export enum HipothesisActionType {
  LOAD_HYPOTHESIS,
  ADD_HYPOTHESIS,
  DELETE_HYPOTHESIS,
  EDIT_HYPOTHESIS,
  ADD_LOAD,
  DELETE_LOAD,
  ADD_WIND_HYPOTHESIS,
  DELETE_WIND_HYPOTHESIS,
  ADD_SSE_HYPOTHESIS,
  EDIT_SSE_HYPOTHESIS,
}

export type HypothesisAction = {
  type: HipothesisActionType.LOAD_HYPOTHESIS;
  payload: {};
} | {
  type: HipothesisActionType.ADD_HYPOTHESIS;
  payload: { hypothesis: Hypothesis };
} | {
  type: HipothesisActionType.DELETE_HYPOTHESIS;
  payload: { hypothesis: Hypothesis };
} | {
  type: HipothesisActionType.EDIT_HYPOTHESIS;
  payload: { hypothesis: Hypothesis };
} | {
  type: HipothesisActionType.ADD_LOAD;
  payload: {
    hypothesis: Hypothesis,
    load: LoadStructuralData,
  }
} | {
  type: HipothesisActionType.DELETE_LOAD;
  payload: {};
} | {
  type: HipothesisActionType.ADD_WIND_HYPOTHESIS;
  payload: {};
} | {
  type: HipothesisActionType.DELETE_WIND_HYPOTHESIS;
  payload: {};
} | {
  type: HipothesisActionType.ADD_SSE_HYPOTHESIS;
  payload: {};
} | {
  type: HipothesisActionType.EDIT_SSE_HYPOTHESIS;
  payload: {};
};
