import { Storey } from "modules/lite/models/storey";
import { FC } from "react";
import Section from "shared/components/ui/section";
import Table from "shared/components/ui/table";
import DXFViewer from "../dxf/dxf-viewer";

interface Props {
  storeys: Storey[];
}
const ProjectStoreys: FC<Props> = ({ storeys }) => {
  return (
    <Section title="Storeys">
      <Table>
        <Table.Header>
          <Table.HeaderCell>Position</Table.HeaderCell>
          <Table.HeaderCell style={{ width: 1, minWidth: 300 }}>
            Name
          </Table.HeaderCell>
          <Table.HeaderCell>height</Table.HeaderCell>
          <Table.HeaderCell>Level</Table.HeaderCell>
          <Table.HeaderCell>Occupancy</Table.HeaderCell>
          <Table.HeaderCell>File</Table.HeaderCell>
          <Table.HeaderCell style={{ width: 1 }}></Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {storeys?.map(
            ({
              id,
              storey_number,
              floor_name,
              height,
              occupancy,
              level,
              dxf_name,
              dxf_url,
            }) => (
              <Table.Row key={id}>
                <Table.Cell>{storey_number}</Table.Cell>
                <Table.Cell className="text-gray-700 font-medium">
                  {floor_name}
                </Table.Cell>
                <Table.Cell>{height.toFixed(2)}</Table.Cell>
                <Table.Cell>{level.toFixed(2)}</Table.Cell>
                <Table.Cell>{occupancy}</Table.Cell>
                <Table.Cell>{dxf_name}</Table.Cell>
                <Table.Cell>
                  <div className="flex items-center space-x-1">
                    <DXFViewer url={dxf_url} name={dxf_name} />
                  </div>
                </Table.Cell>
              </Table.Row>
            )
          )}
        </Table.Body>
      </Table>
    </Section>
  );
};

export default ProjectStoreys;
