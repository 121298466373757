import { CADProject } from "modules/cad/models/projects";
import React, { FC, useCallback, useEffect, useMemo, useReducer } from "react";
import { useHistory, useParams } from "react-router";
import useProject from "../projects/hooks/use-project";

export interface State {
  openFiles: CADProject[];
  currentFile: CADProject | null;
}
export interface Actions {
  openFile: (file: CADProject) => void;
  closeFile: (fileId: string) => void;
}

const initialState: State = {
  openFiles: [],
  currentFile: null,
};

type Action =
  | {
    type: "OPEN_FILE";
    payload: { file: CADProject };
  }
  | {
    type: "CLOSE_FILE";
    payload: { fileId: string };
  };

export const FileContext = React.createContext<State & Actions>(
  {} as State & Actions
);

FileContext.displayName = "FileContext";

function uiReducer(state: State, action: Action): State {
  switch (action.type) {
    case "OPEN_FILE": {
      const currentFile = action.payload.file;
      const isAlreadyOpen = state.openFiles.some(
        (file) => file.id === currentFile.id
      );
      return {
        ...state,
        openFiles: isAlreadyOpen
          ? state.openFiles
          : [...state.openFiles, currentFile],
        currentFile,
      };
    }
    case "CLOSE_FILE": {
      const { fileId } = action.payload;

      return {
        ...state,
        openFiles: state.openFiles.filter((file) => file.id !== fileId),
        currentFile:
          state.currentFile?.id === fileId
            ? state.openFiles[0]
            : state.currentFile,
      };
    }
    default:
      throw new Error("Action not supported");
  }
}

const FileProvider: FC = ({ children }) => {

  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { project } = useProject(id);

  const [{ openFiles, currentFile }, dispatch] = useReducer(uiReducer, initialState);

  const openFile = useCallback(
    (file: CADProject) => {
      dispatch({ type: "OPEN_FILE", payload: { file } });
      history.push(`/cad/projects/${file.id}`);
    },
    [dispatch, history]
  );

  const closeFile = useCallback(
    (fileId: string) => {
      dispatch({ type: "CLOSE_FILE", payload: { fileId } });
      const path = `/cad/projects/${openFiles.length === 1 ? "" : openFiles[0].id}`;
      history.push(path);
    },
    [dispatch, openFiles, history]
  );

  useEffect(() => {
    if (project) {
      openFile(project);
    }
  }, [openFile, project]);

  const value = useMemo(
    () => ({
      openFiles,
      currentFile,
      openFile,
      closeFile,
    }),
    [openFiles, currentFile, openFile, closeFile]
  );

  return <FileContext.Provider value={value}>{children}</FileContext.Provider>;
};

const useFiles = (): State & Actions => {
  const context = React.useContext(FileContext);
  if (context === undefined) {
    throw new Error(`useUI must be used within a FileProvider`);
  }
  return context;
};

export { FileProvider, useFiles };
