
export enum ConcreteCodes {
  CBH_87 = "CBH_87,",
  NB_1225001_1 = "NB_1225001_1,",
  EHE_08 = "EHE_08,",
  NSR_10_Titulo_C = "NSR_10_Titulo_C"
}

export enum SteelCodes { }

export enum WindCodes {
  NB_1225003 = "NB_1225003",
  NP = "NP",
  UNIT_50_84 = "UNIT_50_84",
  NSR_10_Titulo_B = "NSR_10_Titulo_B"
}

export enum SeismicCodes {
  NCSE_94 = "NCSE_94",
  SIN_SISMO = "SIN_SISMO",
  NSR_10_Titulo_A = "NSR_10_Titulo_A",
  NCSE_02 = "NCSE_02"
}

export enum LoadCodes {
  NB_1225001 = "NB_1225001",
  CTE_DB_SE_AE = "CTE_DB_SE_AE",
  UNIT_50_84 = "UNIT_50_84",
  NSR_10_Titulo_B = "NSR_10_Titulo_B"
}

export enum CombinationCodes { }
