import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import CameraControls from "./helpers/camera-controls";

// SETTING MOUSE BEHAVIOUR

enum mouseAction {
  PAN = 0,
  ROTATE,
  DOLLY,
  SELECT,
}

interface IMouseSetting {
  left: mouseAction;
  middle: mouseAction;
  right: mouseAction;
  wheel: mouseAction;
}

export const mouseSetting: IMouseSetting = {
  left: mouseAction.SELECT,
  middle: mouseAction.PAN,
  right: mouseAction.ROTATE,
  wheel: mouseAction.DOLLY,
};

function getThreeMouseAction(action: mouseAction) {
  if (action === mouseAction.ROTATE) {
    return THREE.MOUSE.ROTATE;
  } else if (action === mouseAction.PAN) {
    return THREE.MOUSE.PAN;
  } else if (action === mouseAction.SELECT) {
    return null;
  } else {
    return THREE.MOUSE.DOLLY;
  }
}

/**
 * Modificada para admitir tanto los antiguos OrbitControls como los nuevos CameraControls.
 *
 * @param {(OrbitControls | CameraControls)} srcControl
 * @param {true} isOrbitControls
 * @returns {void}
 */
export function setMouseControls(srcControl: OrbitControls | CameraControls, isOrbitControls: boolean = true): void {
  if (isOrbitControls) {
    const control = srcControl as OrbitControls;
    control.mouseButtons = {} as any;

    const leftControl = getThreeMouseAction(mouseSetting.left);
    if (leftControl !== null) {
      control.mouseButtons.LEFT = leftControl;
    }
    const middleControl = getThreeMouseAction(mouseSetting.middle);
    if (middleControl !== null) {
      control.mouseButtons.MIDDLE = middleControl;
    }
    const rightControl = getThreeMouseAction(mouseSetting.right);
    if (rightControl !== null) {
      control.mouseButtons.RIGHT = rightControl;
    }
  } else {
    console.log("Setting mouse buttons for camControl...");
    const control = srcControl as CameraControls;
    // Ojo con el OFFSET que te mueve la camara y la separa del punto donde apuntas: No usarlo, sino el truck.
    control.mouseButtons.left = CameraControls.ACTION.NONE;
    // Ademas si la camara es ortografica NUNCA puede usar DOLLY, sino ZOOM.
    // Esto ademas resuelve los problemas de desaparicion de la imagen al acercarse demasiado con la ortografica.
    if ((control.getCamera() as THREE.PerspectiveCamera).isPerspectiveCamera === true) {
      control.mouseButtons.wheel = CameraControls.ACTION.DOLLY;
      // En perspectiva admitimos rotacion, pero no en ortografica.
      control.mouseButtons.right = CameraControls.ACTION.ROTATE;
    } else {
      control.mouseButtons.wheel = CameraControls.ACTION.ZOOM;
      control.mouseButtons.right = CameraControls.ACTION.NONE;
    }
    control.mouseButtons.middle = CameraControls.ACTION.TRUCK;

  }
}

// event.buttons === 0  --> None
// event.buttons === 1  --> Left button
// event.buttons === 2  --> Right button
// event.buttons === 4  --> Wheel button
export function isRotateButtonPressed(event: PointerEvent) {
  if (event.buttons === 1 && mouseSetting.left === mouseAction.ROTATE) {
    return true
  };
  if (event.buttons === 2 && mouseSetting.right === mouseAction.ROTATE) {
    return true
  };
  if (event.buttons === 4 && mouseSetting.middle === mouseAction.ROTATE) {
    return true
  };
  return false;
}
export function isSelectButtonPressed(event: PointerEvent) {
  if (event.buttons === 1 && mouseSetting.left === mouseAction.SELECT) {
    return true
  };
  if (event.buttons === 2 && mouseSetting.right === mouseAction.SELECT) {
    return true
  };
  if (event.buttons === 4 && mouseSetting.middle === mouseAction.SELECT) {
    return true
  };
  return false;
}

// event.button === 0  --> Left button
// event.button === 1  --> Wheel button
// event.button === 2  --> Right button
export function isRotateButtonClicked(event: PointerEvent) {
  if (event.button === 0 && mouseSetting.left === mouseAction.ROTATE) {
    return true
  };
  if (event.button === 2 && mouseSetting.right === mouseAction.ROTATE) {
    return true
  };
  if (event.button === 1 && mouseSetting.middle === mouseAction.ROTATE) {
    return true
  };
  return false;
}
export function isSelectButtonClicked(event: PointerEvent) {
  if (event.button === 0 && mouseSetting.left === mouseAction.SELECT) {
    return true
  };
  if (event.button === 2 && mouseSetting.right === mouseAction.SELECT) {
    return true
  };
  if (event.button === 1 && mouseSetting.middle === mouseAction.SELECT) {
    return true
  };
  return false;
}
