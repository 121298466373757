import cn from "classnames";

interface Props {
  options: string[];
  onSelect?: (value: string) => void;
  activeTab?: string;
}

const getTabClassName = (isActive: boolean) =>
  cn(
    "whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm focus:outline-none capitalize",
    isActive
      ? "border-indigo-500 text-indigo-500"
      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
  );

const Tabs = ({ options, onSelect = () => {}, activeTab }: Props) => {
  return (
    <div className="px-4 sm:px-0 mb-4">
      <div className="pt-4 sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          defaultValue={activeTab || options[0]}
          onChange={(event) => onSelect(event.target.value)}
        >
          {options.map((value, index) => (
            <option key={index}>{value}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <div className="-mb-px flex space-x-8" role="tablist">
            {options.map((value, index) => (
              <button
                aria-selected={value === activeTab}
                className={getTabClassName(value === activeTab)}
                key={index}
                onClick={() => onSelect(value)}
                role="tab"
                tabIndex={-1}
                type="button"
              >
                {value}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
