import { FC } from "react";
import styled from "styled-components";
import { Link as RouterLink, LinkProps } from "react-router-dom";

interface Props extends LinkProps {
  loading?: boolean;
  variant?: any;
  fullWidth?: boolean;
}

const Link: FC<Props> = (props) => {
  return <Wrapper {...props} />;
};

const Wrapper = styled(RouterLink)<Props>`
  font-size: ${14 / 16}rem;
  display: inline-flex;
  color: var(--color-text);
  padding: 8px 24px;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius);
  font-weight: 500;
  width: ${(props) => props.fullWidth && "100%"};
  transition: all 0.2s;
  box-shadow: rgba(63, 63, 68, 0.05) 0 0 0 1px,
    rgba(63, 63, 68, 0.15) 0 1px 3px 0;
  &:disabled {
    opacity: 0.5;
    cursor: revert;
  }
`;

export const PrimaryLink = styled(Link)`
  color: var(--color-white);
  background-color: var(--color-primary);
  &:hover {
    background-color: var(--color-primary-dark);
  }
`;

export const SecondaryLink = styled(Link)`
  background-color: var(--color-gray-100);
  &:hover {
    background-color: var(--color-secondary-dark);
  }
`;

export const LeftArrowLink = styled(RouterLink)`
width: 0; 
height: 0; 
border-top: 0.7rem solid transparent;
border-bottom: 0.7rem solid transparent; 
border-right:0.7rem solid var(--color-gray-400);
cursor: pointer; 
  &:hover {
    border-right:0.7rem solid var(--color-gray-700);
  }
`;