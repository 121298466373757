import { FC } from "react";
import { DangerButton, SecondaryButton } from "shared/components/ui/button";
import { useDeleteLayer } from "./hooks/use-delete-layer";
import { useMainGraphicContext } from "../viewport/context";

interface Props {
  onClose: () => void;
}
const DeleteLayerForm: FC<Props> = ({ onClose }) => {

  const graphicProc = useMainGraphicContext();
  const { deleteLayer } = useDeleteLayer(graphicProc);

  return (
    <div className="space-y-4">
      <div>
        <h1 className="text-lg leading-6 font-medium text-gray-400">
          Delete selected layer
        </h1>
      </div>
      <div className="flex gap-4">
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        <DangerButton onClick={deleteLayer}>Delete layer</DangerButton>
      </div>
    </div>
  );
};

export default DeleteLayerForm;
