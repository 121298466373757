import { GraphicProcessor } from "lib/graphic-processor";
import { substractIpoint } from "lib/math/point";
import { IPoint } from "lib/math/types";
import { cadOpType } from "lib/operations/factory";
import { CadCommand } from "./base";
import { DeleteBaseCommand } from "./delete";
import { IObjData } from '../models/objdata';
import { EventBus } from "lib/events/event-bus";
import { translateAndCreateDataFromCommand } from "./command-creator/command-factory";

abstract class CopyBaseCommand extends CadCommand {

  protected basePoint: IPoint;
  protected endPoints: IPoint[];
  private objsToCopy: IObjData[] = [];
  private createdObjs: IObjData[] = [];

  constructor(basePoint: IPoint, endPoints: IPoint[], objsToCopy: IObjData[], layerId: string, graphicProcessor: GraphicProcessor) {
    super(graphicProcessor);
    this.objsToCopy = objsToCopy;
    this.basePoint = basePoint;
    this.endPoints = endPoints.slice();
    this.layerId = layerId;
  }

  execute() {
    if (this.createdObjs.length === 0) {
      const vectors = this.endPoints.map(end => substractIpoint(end, this.basePoint));
      EventBus.enableDispatch = false;
      for (const vect of vectors) {
        for (const dataObj of this.objsToCopy) {
          const newdata = translateAndCreateDataFromCommand(this.graphicProcessor, dataObj, vect, this.layerId);
          this.createdObjs.push(...newdata);
        }
      }
      EventBus.enableDispatch = true;
      this.graphicProcessor.unselectAll();
    } else {
      for (const data of this.createdObjs) {
        data.addObjAsDependence();
        this.graphicProcessor.addToLayer(data, data.layerId);
      }
    }
    const dtMdlMngr = this.graphicProcessor.getDataModelManager();
    dtMdlMngr.dispatchAddedObjs(this.createdObjs);
    dtMdlMngr.layerManager.layerObserver.dispatchLoadLayers();
  }

  unExecute() {
    for (const data of this.createdObjs) {
      data.removeObjAsDependence();
      this.graphicProcessor.removeFromLayer(data);
    }
    const dtMdlMngr = this.graphicProcessor.getDataModelManager();
    dtMdlMngr.dispatchDeletedObjs(this.createdObjs);
    dtMdlMngr.layerManager.layerObserver.dispatchLoadLayers();
  }

  delete() {
    this.objsToCopy.length = 0;
    this.createdObjs.length = 0;
    this.endPoints.length = 0;
  }
}

export class CopyCommand extends CopyBaseCommand {
  protected opType = cadOpType.COPY;
}

export class CutCommand extends DeleteBaseCommand {
  protected opType = cadOpType.CUT;
}

export class PasteCommand extends CopyBaseCommand {
  protected opType = cadOpType.PASTE;
}