import { GraphicProcessor } from "lib/graphic-processor";
import { loadType } from "lib/models-struc/types/load";
import { LoadStructuralData } from "lib/models/structural/load";
import { SelectionManager } from "lib/selection/selection-manager";
import { ObjDataSelector } from "../selector-data";
import { LoadConcentratedDataSelector } from "./load-concentrated";
import { LoadLinealDataSelector } from "./load-lineal";
import { LoadSuperficialDataSelector } from "./load-superficial";

export interface IloadSelectionHandler {
  buildSelectMarks(data: LoadStructuralData): any;
  launchMarkOP(data: LoadStructuralData, graphicProc: GraphicProcessor, selectionManager: SelectionManager, mark: THREE.Object3D, index?: number): any
}

export class LoadDataSelector extends ObjDataSelector<LoadStructuralData> {

  loadSelectionHandler: IloadSelectionHandler;

  constructor(data: LoadStructuralData, graphicProc: GraphicProcessor) {
    super(data, graphicProc);
    if (data.definition.type === loadType.CONCENTRATED) {
      this.loadSelectionHandler = new LoadConcentratedDataSelector();
    } else if (data.definition.type === loadType.LINEAL) {
      this.loadSelectionHandler = new LoadLinealDataSelector();
    } else {
      this.loadSelectionHandler = new LoadSuperficialDataSelector();
    }
  }
  public override buildSelectMarks() {
    if (!this.data.isDefaultSlabLoad) {
      return this.loadSelectionHandler.buildSelectMarks(this.data);
    }
  }

  public override launchMarkOP(selectionManager: SelectionManager, mark: THREE.Object3D, index?: number) {
    if (!this.data.isDefaultSlabLoad) {
      return this.loadSelectionHandler.launchMarkOP(this.data, this.graphicProc, selectionManager, mark, index);
    }
  }
}

