
import * as THREE from "three";
import { setHexFromHexString } from "lib/math/color";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";

/** Change the color material of any object saving reference to restore original material
 *
 * @export
 * @class SelectCacheThreeMaterial
 */
export class SelectCacheThreeMaterial {

  private oriMaterialObjects: Map<THREE.Object3D, THREE.Material> = new Map();

  private selectionColor: string;

  private pointMaterial: THREE.PointsMaterial | undefined;
  private lineMaterial: LineMaterial | undefined;
  private lineBasicMaterial: THREE.LineBasicMaterial | undefined;

  private frontSolidBasicMaterial: THREE.MeshBasicMaterial | undefined;
  private backSolidBasicMaterial: THREE.MeshBasicMaterial | undefined;
  private doubleSolidBasicMaterial: THREE.MeshBasicMaterial | undefined;

  private frontSolidMaterial: THREE.MeshPhongMaterial | undefined;
  private backSolidMaterial: THREE.MeshPhongMaterial | undefined;
  private doubleSolidMaterial: THREE.MeshPhongMaterial | undefined;

  // THREE.FrontSide	-->  back side
  // THREE.BackSide	  -->  front side
  // THREE.DoubleSide	-->  both sides

  constructor(color: string) {
    this.selectionColor = color;
  }
  public clear() {
    this.pointMaterial?.dispose();
    this.pointMaterial = undefined;
    this.lineMaterial?.dispose();
    this.lineMaterial = undefined;
    this.lineBasicMaterial?.dispose();
    this.lineBasicMaterial = undefined;
    this.frontSolidBasicMaterial?.dispose();
    this.frontSolidBasicMaterial = undefined;
    this.backSolidBasicMaterial?.dispose();
    this.backSolidBasicMaterial = undefined;
    this.doubleSolidBasicMaterial?.dispose();
    this.doubleSolidBasicMaterial = undefined;
    this.frontSolidMaterial?.dispose();
    this.frontSolidMaterial = undefined;
    this.backSolidMaterial?.dispose();
    this.backSolidMaterial = undefined;
    this.doubleSolidMaterial?.dispose();
    this.doubleSolidMaterial = undefined;
  }

  public size() {
    return this.oriMaterialObjects.size;
  }

  public isSelected(threeObj: THREE.Object3D) {
    return this.oriMaterialObjects.has(threeObj);
  }
  public selectObj(threeObj: THREE.Object3D) {
    const oriMaterial = (threeObj as THREE.Mesh).material as THREE.Material;
    if (oriMaterial) {
      let preSelectMat = this.getPreSelectMaterial(oriMaterial);
      if (preSelectMat) {
        this.oriMaterialObjects.set(threeObj, oriMaterial);
        ((threeObj as THREE.Mesh).material as THREE.Material) = preSelectMat;
      }
    }
    for (const obj of threeObj.children) {
      this.selectObj(obj);
    }
  }
  public unselectObj(threeObj: THREE.Object3D) {
    const oriMaterial = this.oriMaterialObjects.get(threeObj);
    if (oriMaterial) {
      ((threeObj as THREE.Mesh).material as THREE.Material) = oriMaterial;
      this.oriMaterialObjects.delete(threeObj);
    }
    for (const obj of threeObj.children) {
      this.unselectObj(obj);
    }
  }
  public clearSelection() {
    if (this.oriMaterialObjects.size) {
      for (const threeObj of this.oriMaterialObjects.keys()) {
        this.unselectObj(threeObj);
      }
      this.oriMaterialObjects.clear();
    }
  }

  // ---------------------------------------------

  private getPreSelectMaterial(material: THREE.Material) {
    const type = material.type;
    switch (type) {
      case "PointsMaterial":
        return this.getPointPreSelectMaterial();

      case "LineMaterial":
        return this.getLinePreSelectMaterial();

      case "LineBasicMaterial":
      case "LineDashedMaterial":
        return this.getBasicLinePreSelectMaterial();

      case "MeshBasicMaterial":
        if (material.side === THREE.FrontSide) {
          return this.getFrontSolidBasicPreSelectMaterial();
        }
        if (material.side === THREE.BackSide) {
          return this.getBackSolidBasicPreSelectMaterial();
        }
        return this.getSolidBasicPreSelectMaterial();

      case "MeshPhongMaterial":
        if (material.side === THREE.FrontSide) {
          return this.getFrontSolidPreSelectMaterial();
        }
        if (material.side === THREE.BackSide) {
          return this.getBackSolidPreSelectMaterial();
        }
        return this.getSolidPreSelectMaterial();

      default:
        break;
    }
  }

  private getPointPreSelectMaterial() {
    if (this.pointMaterial === undefined) {
      this.pointMaterial = new THREE.PointsMaterial({
        color: this.selectionColor,
        sizeAttenuation: false,
        size: 5,
      });
    }
    return this.pointMaterial;
  }
  private getLinePreSelectMaterial() {
    if (this.lineMaterial === undefined) {
      this.lineMaterial = new LineMaterial({
        color: setHexFromHexString(this.selectionColor),
        linewidth: 4,
      });
      this.lineMaterial.resolution.set(window.innerWidth, window.innerHeight);
    }
    return this.lineMaterial;
  }
  private getBasicLinePreSelectMaterial() {
    if (this.lineBasicMaterial === undefined) {
      this.lineBasicMaterial = new THREE.LineBasicMaterial({
        color: this.selectionColor,
      });
    }
    return this.lineBasicMaterial;
  }
  private getFrontSolidBasicPreSelectMaterial() {
    if (this.frontSolidBasicMaterial === undefined) {
      this.frontSolidBasicMaterial = new THREE.MeshBasicMaterial({
        color: this.selectionColor,
        side: THREE.FrontSide,
      });
    }
    return this.frontSolidBasicMaterial;
  }
  private getBackSolidBasicPreSelectMaterial() {
    if (this.backSolidBasicMaterial === undefined) {
      this.backSolidBasicMaterial = new THREE.MeshBasicMaterial({
        color: this.selectionColor,
        side: THREE.BackSide,
      });
    }
    return this.backSolidBasicMaterial;
  }
  private getSolidBasicPreSelectMaterial() {
    if (this.doubleSolidBasicMaterial === undefined) {
      this.doubleSolidBasicMaterial = new THREE.MeshBasicMaterial({
        color: this.selectionColor,
        side: THREE.DoubleSide,
      });
    }
    return this.doubleSolidBasicMaterial;
  }

  private getFrontSolidPreSelectMaterial() {
    if (this.frontSolidMaterial === undefined) {
      this.frontSolidMaterial = new THREE.MeshPhongMaterial({
        color: this.selectionColor,
        side: THREE.FrontSide,
      });
    }
    return this.frontSolidMaterial;
  }
  private getBackSolidPreSelectMaterial() {
    if (this.backSolidMaterial === undefined) {
      this.backSolidMaterial = new THREE.MeshPhongMaterial({
        color: this.selectionColor,
        side: THREE.BackSide,
      });
    }
    return this.backSolidMaterial;
  }
  private getSolidPreSelectMaterial() {
    if (this.doubleSolidMaterial === undefined) {
      this.doubleSolidMaterial = new THREE.MeshPhongMaterial({
        color: this.selectionColor,
        side: THREE.DoubleSide,
      });
    }
    return this.doubleSolidMaterial;
  }
}