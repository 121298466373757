import { FC, lazy } from "react";
import styled from "styled-components";
import { useUI } from "shared/components/ui/context";
import Header from "./header";
import PlaneList from "modules/cad/components/planes/plane-list";
import DeleteLayerForm from "modules/cad/components/layers/layer-delete-form";
import AddLayerForm from "modules/cad/components/layers/layer-add-form";
import DimensionStyles from "modules/cad/components/dimensions/dimension-editor";
import PileCapTemplates from "../pile-cap/pile-cap-editor";
import AddHypothesisForm from "../hypothesis/hypothesis-add-form";
import DeleteHypothesisForm from "../hypothesis/hypothesis-delete-form";
import Meshing from "../mesh/meshing";
import Analyzing from "../analysis/analyzing";
import BeamCrossSectionDlg from "../cross-sections/beam-cross-section/beam-cross-sections";
import ShellCrossSectionDlg from "../cross-sections/shell-cross-section/shell-cross-sections";
import StoreyDriftWizard from "../storey-drift/storey-drift-selection";
import StoreyDriftInfo from "../storey-drift/storey-drift-info";
import WindHypothesisWizard from "../hypothesis/wind-hypothesis";
import CalculateWaffle from "../waffles/waffle-calculate";
import ProjectCloneModal from "../project/clone/project-clone";
import ProjectDeleteModal from "../project/delete/project-delete";

const Toast = lazy(() => import("shared/components/ui/toast"));
const Modal = lazy(() => import("shared/components/ui/modal"));

const Layout: FC = ({ children }) => {

  const {
    modalData,
    displayToast,
    toastData,
    closeToast,
    modalView,
    displayModal,
    closeModal,
    shouldShowHeader,
  } = useUI();

  return (
    <Wrapper className="layers">
      {shouldShowHeader &&
        <Header />
      }
      <ChildrenWrapper>
        {children}
      </ChildrenWrapper>
      <Modal isOpen={displayModal} onClose={closeModal} title={modalView}>
        {modalView === "DELETE_STRUC_PROJECT" && <ProjectDeleteModal />}
        {modalView === "CLONE_STRUC_PROJECT" && <ProjectCloneModal />}

        {modalView === "ADD_LAYER" && <AddLayerForm onClose={closeModal} />}
        {modalView === "DELETE_LAYER" && <DeleteLayerForm onClose={closeModal} />}

        {modalView === "SHELL_CROSS_SECTIONS" && <ShellCrossSectionDlg onClose={closeModal} />}
        {modalView === "BEAM_CROSS_SECTIONS" && <BeamCrossSectionDlg onClose={closeModal} />}
        {modalView === "PLANE_LIST" && <PlaneList onClose={closeModal} />}
        {modalView === "DIMENSION_MNG" && <DimensionStyles onClose={closeModal} />}
        {modalView === "PILE_CAP_MNG" && <PileCapTemplates onClose={closeModal} />}

        {modalView === "ADD_HYPOTHESIS" && <AddHypothesisForm onClose={closeModal} />}
        {modalView === "DELETE_HYPOTHESIS" && <DeleteHypothesisForm onClose={closeModal} />}

        {modalView === "WAFFLE" && <CalculateWaffle onClose={closeModal} />}
        {modalView === "MESHING" && <Meshing onClose={closeModal} />}
        {modalView === "ANALYZING" && <Analyzing onClose={closeModal} />}

        {modalView === "STOREYDRIFTWIZARD" && <StoreyDriftWizard onClose={closeModal} />}
        {modalView === "STOREYDRIFTINFO" && <StoreyDriftInfo onClose={closeModal} data={modalData as any} />}

        {modalView === "WIND_HYPOTHESIS_WIZARD" && <WindHypothesisWizard onClose={closeModal} />}
      </Modal>
      {displayToast && toastData && (
        <Toast
          status={toastData.status}
          title={toastData.title}
          onClose={closeToast}
          {...toastData}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  background-color: var(--color-gray-200);
  margin: 0 auto;
  position: relative;
  overflow: auto;
  font-size: 0.75rem;
`;
const ChildrenWrapper = styled.div`
  margin-top: 64px;
`;
export default Layout;
