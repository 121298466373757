import { solidParam } from "lib/models/solids/solid";
import * as THREE from "three";
import { setPosRotEsc } from "..";
import { getAuxMeshBasicMaterial } from "../../materials";
import { IPoint } from "../../math/types";

export interface cubeParam extends solidParam {
    side: number,
}

export function createBufferCube(side: number) {
    return new THREE.BoxBufferGeometry(side, side, side);
}

export function createCube(side: number, material?: THREE.MeshPhongMaterial, basePoint?: IPoint, rotation?: IPoint, offset?: IPoint, scale?: IPoint) {
    const geom = createBufferCube(side);
    const mat = material ?? getAuxMeshBasicMaterial();
    const threeObj = new THREE.Mesh(geom, mat);
    if (basePoint && offset && rotation && scale) {
        setPosRotEsc(threeObj, basePoint, offset, rotation, scale);
    }
    return threeObj;
}