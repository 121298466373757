import { DxfImporter } from "lib/input-output/loaders/dxf";
import { ChangeEvent } from "react";
import CloudUploadIcon from "shared/components/icons/cloud-upload";
import UploadIcon from "shared/components/icons/upload";
import InfoIcon from "shared/components/icons/info";
import ToolbarButton from "../toolbar-button";
import ToolbarSection from "../toolbar-section";
import DxfParser from "dxf-parser";
import { useMainGraphicContext } from "../../viewport/context";
import { EcoreMeshImporter } from "lib/input-output/e-core/importer/mesh-importer";
import { userMessageEvents } from "lib/events/user-messages";
import { logging } from "shared/utils/logger/log-manager";

const ImporterSection = () => {

  const graphicProcessor = useMainGraphicContext();

  const uploadDxf = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (evt: any) => {
        const fileContent: string = evt.target.result;
        const parser = new DxfParser();
        const dxfData = parser.parseSync(fileContent);
        const auxLayerName = file.name.replace(/.dxf/gi, '_aux');
        const dxfImporter = new DxfImporter(graphicProcessor);
        dxfImporter.ImportDxf(auxLayerName, dxfData);
      }
      reader.readAsText(file);
    }
  }

  const importEcore = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (evt: any) => {
        try {
          const eCoreData = JSON.parse(evt.target.result);
          const ecoreImporterLogger = logging.getLogger('Ecore-importer');
          const importer = new EcoreMeshImporter(graphicProcessor, ecoreImporterLogger);
          importer.importMeshProject(eCoreData);
        } catch (error: any) {
          console.error(error.stack);
          userMessageEvents.dispatchError("Ecore mesh importer failed: " + error.stack);
        }
      }
      reader.readAsText(file);
    }
  }

  return (
    <ToolbarSection title="Import DXF" className={"border-b border-gray-600"}>
      <ToolbarButton className="px-1" tooltip="Import DXF">
        <label>
          <CloudUploadIcon className="h-6 w-6" />
          <input type="file" className="sr-only"
            onChange={(e) => {
              uploadDxf(e);
              e.target.value = "";
            }}
          />
        </label>
      </ToolbarButton>
      <ToolbarButton className="px-1" tooltip="Import Ecore">
        <label>
          <UploadIcon className="h-6 w-6" />
          <input type="file" className="sr-only"
            onChange={(e) => {
              importEcore(e);
              e.target.value = "";
            }}
          />
        </label>
      </ToolbarButton>
      <ToolbarButton className="px-1" tooltip="Info Model" onClick={() => {
        const mdl = graphicProcessor.getDataModelManager();
        mdl.getModelInfo();
      }} >
        <InfoIcon className="h-6 w-6" />
      </ToolbarButton>

    </ToolbarSection>
  );
};

export default ImporterSection;
