const CubeIcon = ({ ...props }) => {
  return (
    <svg
      className="h-5 w-5"
      viewBox="0 0 100 100"
      fill="currentColor"
      {...props}
    >
      <path d="M80 66.6667H76.6667V70.0001H80V66.6667Z" />
      <path d="M30 10L10 30V33.3333V90H66.6667H70L90 70V10H33.3333H30ZM13.3333 86.6667V33.3333H33.3333H66.6667V86.6667H13.3333ZM70 85.2867V70H73.3333V66.6667H70V34.7133V32.3566L86.6667 15.6899V18.0467V66.6667H83.3333V70H85.2867L70 85.2867ZM84.31 13.3333L67.6433 30H33.3333V26.6667H30V30H14.7133L30 14.7133V16.6667H33.3333V13.3333H84.31Z" />
      <path d="M33.3333 20H30V23.3333H33.3333V20Z" />
    </svg>
  );
};

export default CubeIcon;
