import PolygonalPrismIcon from "../../../cad/components/icons/pprism";
import { useOperation } from "../../../cad/components/operations/context";
import ToolbarButton from "../../../cad/components/toolbar/toolbar-button";
import ToolbarSection from "../../../cad/components/toolbar/toolbar-section";
import { useUI } from "shared/components/ui/context";
import PlaneIcon from "modules/cad/components/icons/plane";
import CubeIcon from "../../../cad/components/icons/cube";
import LineIcon from "../../../cad/components/icons/line";
import PointIcon from "modules/cad/components/icons/point";
import PolylineIcon from "modules/cad/components/icons/polyline";
import WorkingPlaneIcon from "modules/cad/components/icons/wrk-plane";

const StructuralSection = () => {
  const { openModal } = useUI();
  const {
    createBeam,
    createColumn,
    createSlab,
    createSlabHole,
    createWall,
    createWallHole,
    createConcentratedLoad,
    createLinealLoad,
    createSuperficialLoad,
    createFooter,
    createPileCap,
  } = useOperation();
  return (
    <ToolbarSection title="Structural" className={"border-b border-gray-600"}>
      <ToolbarButton onClick={() => openModal("BEAM_CROSS_SECTIONS")} tooltip="Open beam cross section editor">
        <WorkingPlaneIcon />
      </ToolbarButton>
      <ToolbarButton onClick={() => openModal("SHELL_CROSS_SECTIONS")} tooltip="Open shell cross section editor">
        <WorkingPlaneIcon />
      </ToolbarButton>

      <ToolbarSection.Row>
        <ToolbarButton onClick={createBeam} tooltip="Beam">
          <LineIcon />
        </ToolbarButton>
        <ToolbarButton onClick={createColumn} tooltip="Column">
          <PolygonalPrismIcon />
        </ToolbarButton>
        <ToolbarButton onClick={createSlab} tooltip="Slab">
          <PlaneIcon />
        </ToolbarButton>
        <ToolbarButton onClick={createSlabHole} tooltip="Slab hole">
          <PlaneIcon />
        </ToolbarButton>
        <ToolbarButton onClick={createWall} tooltip="Wall">
          <CubeIcon />
        </ToolbarButton>
        <ToolbarButton onClick={createWallHole} tooltip="Wall hole">
          <CubeIcon />
        </ToolbarButton>
        <ToolbarButton onClick={createConcentratedLoad} tooltip="Load concentrated">
          <PointIcon />
        </ToolbarButton>
        <ToolbarButton onClick={createLinealLoad} tooltip="Load lineal">
          <LineIcon />
        </ToolbarButton>
        <ToolbarButton onClick={createSuperficialLoad} tooltip="Load superficial">
          <PolylineIcon />
        </ToolbarButton>
        <ToolbarButton onClick={createFooter} tooltip="Footer">
          <CubeIcon />
        </ToolbarButton>
        <ToolbarButton onClick={createPileCap} tooltip="Pile cap">
          <CubeIcon />
        </ToolbarButton>
        <ToolbarButton onClick={() => openModal("PILE_CAP_MNG")} tooltip="Pile cap manager">
          <CubeIcon />
        </ToolbarButton>
      </ToolbarSection.Row>
    </ToolbarSection>
  );
};

export default StructuralSection;
