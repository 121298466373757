
import { BeamCrossSection } from "lib/models-struc/cross-sections-shape/beam-cross-sections/beamcs-shapes";
import { ShellCrossSection } from "lib/models-struc/cross-sections-shape/shell-cross-sections/shellcs-shapes";
import { Hypothesis, SSEDirs } from "lib/models-struc/hypothesis/hypothesis";
import { MeshGenParams } from "lib/models-struc/mesh/mesh";
import { IcombinationItem } from "lib/models-struc/storey-drift/storey-drift-repo";
import { LoadStructuralData } from "lib/models/structural/load";
import { propSetting } from "lib/operations/step-operations";
import { createContext, useContext, useMemo, ReactNode, useState, useEffect, useCallback } from "react";
import { useOpSettings } from "../steps/hooks/use-op-settings";
import { useMainGraphicContext } from "../viewport/context";

export const enum itemType {
  OPERATION,
  STRUCELEMENT,
  BEAMCS,
  SHELLCS,
  MESH,
  HYPOSET,
  LOAD,
  SSE,
  SSE_PROP,
  CODEANALYSIS,
  SOLVEANALYSIS,
  DISPLACEMENT,
  BEAMFM,
  SHELLFM,
  NODALFM,
  TOOLS,
  STOREYDRIFT,
};

interface OperationItem { type: itemType.OPERATION, info: { title: string, setting: propSetting<any> } }
export interface StrucElemItem { type: itemType.STRUCELEMENT, info: undefined }
export interface BeamCSItem { type: itemType.BEAMCS, info: BeamCrossSection }
export interface ShellCSItem { type: itemType.SHELLCS, info: ShellCrossSection }
export interface meshItem { type: itemType.MESH, info: MeshGenParams }
export interface hypoSetItem { type: itemType.HYPOSET, info: Hypothesis | undefined }
export interface loadItem { type: itemType.LOAD, info: LoadStructuralData }
export interface SSEItem { type: itemType.SSE, info: undefined }
export interface SSEPropNode { type: itemType.SSE_PROP, info: SSEDirs }
export interface codeAnalysisItem { type: itemType.CODEANALYSIS, info: undefined }
export interface hypoSolveItem { type: itemType.SOLVEANALYSIS, info: undefined }
export interface displacementItem { type: itemType.DISPLACEMENT, info: Hypothesis }
export interface beamFMItem { type: itemType.BEAMFM, info: Hypothesis }
export interface shellFMItem { type: itemType.SHELLFM, info: Hypothesis }
export interface nodalFMItem { type: itemType.NODALFM, info: Hypothesis }
export interface toolsItem { type: itemType.TOOLS, info: undefined }
export interface storeyDriftItem { type: itemType.STOREYDRIFT, info: { id: string } & IcombinationItem }

export type ItemInfo =
  OperationItem |
  StrucElemItem |
  BeamCSItem |
  ShellCSItem |
  meshItem |
  hypoSetItem |
  loadItem |
  SSEItem |
  SSEPropNode |
  codeAnalysisItem |
  hypoSolveItem |
  displacementItem |
  beamFMItem |
  shellFMItem |
  nodalFMItem |
  toolsItem |
  storeyDriftItem |
  undefined;

export interface IPanelSetting<T> {
  title: string,
  setting: propSetting<T>,
}

type propVal = {
  itemData: ItemInfo,
  setData: (data: ItemInfo) => void
}

const SidebarContext = createContext<propVal>({} as propVal);

const SideBarProvider = ({ children }: { children: ReactNode }) => {

  const [itemData, setItemData] = useState<ItemInfo>(undefined);
  const setData = useCallback((data: ItemInfo) => { setItemData(data) }, [])

  const graphicProcessor = useMainGraphicContext();
  const { opSettings } = useOpSettings(graphicProcessor);
  useEffect(() => {
    if (opSettings.currentOperation && opSettings.currentStep?.panelProp) {
      const props = {
        title: `${opSettings.currentOperation.pubOpName} options`,
        setting: opSettings.currentStep.panelProp
      }
      setItemData({ type: itemType.OPERATION, info: props });
    } else {
      setItemData(undefined);
    }
  }, [opSettings])

  const value = useMemo(() => ({
    itemData,
    setData,
  }), [itemData, setData])

  return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
};

const useSideBarContext = () => {
  const context = useContext(SidebarContext);
  if (Object.keys(context).length === 0) {
    throw new Error(`useSideBarContext must be used within a SideBarProvider`);
  }
  return context;
};

export { SideBarProvider, useSideBarContext };