import { GraphicProcessor } from "../../graphic-processor";
import { IPoint } from "../../math/types";
import { loadParam, loadType } from "../../models-struc/types/load";
import { LoadStructuralData } from "../../models/structural/load";
import { cadOpType } from "../../operations/factory";
import { CadCommand } from "../base";
import { EditDataCommand } from "../edition/edit-base";
import { copyIPoint, removeContigousEqualPoints } from "lib/math/point";
import { IStrucElementData } from "lib/models/structural/structural";
import { objDataType } from "lib/models/types";

export class LoadCommand extends CadCommand {

  protected opType: cadOpType;
  private structuralParam: loadParam;
  private parentStructData: IStrucElementData;
  private createdObj: LoadStructuralData;
  get loadObj() { return this.createdObj }

  constructor(
    type: loadType,
    hypothesisId: string,
    loadValue: number,
    ptos2D: IPoint[],
    basePoint: IPoint,
    rotation: IPoint,
    parentStructElem: IStrucElementData,
    externalGeo: boolean,
    graphicProc: GraphicProcessor,
  ) {
    super(graphicProc);
    const storeyId = parentStructElem.storeyId;
    const strucMng = graphicProc.getStructuralModelManager();
    this.layerId = strucMng.getLayerByStoreyIdAndStructuralType(storeyId, objDataType.LOAD).id;

    this.setTypeOP(type);
    const name = strucMng.currBuilding.getNextLoadName();

    this.parentStructData = parentStructElem;
    const cleanPoints = removeContigousEqualPoints(ptos2D.map(copyIPoint));
    this.structuralParam = {
      name,
      hypothesisId: hypothesisId,
      parentStructElemId: parentStructElem.id,
      externalGeo,
      type,
      loadValue,
      ptos2D: cleanPoints,
      basePoint: copyIPoint(basePoint),
      rotation: copyIPoint(rotation),
    }
  }

  private setTypeOP(type: loadType) {
    switch (type) {
      case loadType.CONCENTRATED:
        this.opType = cadOpType.LOADCONCENTRATED;
        break;
      case loadType.LINEAL:
        this.opType = cadOpType.LOADLINEAL;
        break;
      case loadType.SUPERFICIAL:
        this.opType = cadOpType.LOADSUPERFICIAL;
        break;
    }
  }

  public execute() {
    if (this.createdObj === undefined) {
      this.createdObj = new LoadStructuralData(this.structuralParam);
      this.createdObj.parentStrucElem = this.parentStructData;
      this.createdObj.createGraphicObj();
    }
    this.graphicProcessor.addToLayer(this.createdObj, this.layerId);
    const modelManager = this.graphicProcessor.getDataModelManager();
    modelManager.dispatchAddedObjs([this.createdObj]);
    const lyrManager = this.graphicProcessor.getLayerManager();
    lyrManager.layerObserver.dispatchLoadLayers();
  }

  public async unExecute() {
    this.graphicProcessor.removeFromLayer(this.createdObj);
    const modelManager = this.graphicProcessor.getDataModelManager();
    modelManager.dispatchDeletedObjs([this.createdObj]);
    const lyrManager = this.graphicProcessor.getLayerManager();
    lyrManager.layerObserver.dispatchLoadLayers();
  }

  public delete(): void {
    this.structuralParam = undefined!;
    this.parentStructData = undefined!;
    this.createdObj = undefined!;
  }
}

export class LoadEditDataCommand extends EditDataCommand {
  protected opType = cadOpType.EDITLOAD;
}
