import gql from "graphql-tag";

export const USER_FRAGMENT = gql`
  fragment userFragment on user {
    id
    username
    email
    defaultStart
  }
`;

export const GET_USER = gql`
  query getUser($id: uuid!) {
    user: user_by_pk(id: $id) {
      ...userFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const UPDATE_SETTINGS = gql`
  mutation updateSettings($id: uuid!, $defaultStart: String) {
    user: update_user_by_pk(
      pk_columns: { id: $id }
      _set: { defaultStart: $defaultStart }
    ) {
      ...userFragment
    }
  }
  ${USER_FRAGMENT}
`;
