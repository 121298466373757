import { cadOpType } from "../factory";
import { lineMoveVertex } from "../../geometries/line";
import { LineBaseOP } from "./line";
import { copyIPoint, getAzimutPolarPoint, IpointsToBuffer } from "../../math/point";
import { getArcP1TangentDefinition, IArcLineParam } from "../../math/line";
import { PolyLineCommand } from "../../commands/primitives/polyline";
import { IPoint } from "../../math/types";
import { setPosBuffer } from "../../geometries";
import { arcPointsCR2p } from "../../math/arc";
import { lineAzimut2p, normalizeAngle, ORIENT } from "../../math/angles";
import { pointOnLine3D } from "../../math/distance";
import { settingsOpModes } from "../step-operations";
import { getCurrentLineMaterial } from "lib/materials";
import { LineData } from "lib/models/primitives/line";
import { vector3Equals } from "lib/math/epsilon";

enum polylineInputMode { RECT, ARC }

export class PolyLineOP extends LineBaseOP {

  public opType = cadOpType.POLYLINE;
  private arcs: (0 | IArcLineParam)[] = [];
  private isClosed: boolean = false;
  private mode: polylineInputMode = polylineInputMode.RECT;

  public redoStackPoints: IPoint[] = [];
  public redoStackArcs: (0 | IArcLineParam)[] = [];

  public auxLineObjData: LineData;

  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Insert point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: (cmd: string) => {
        if (cmd === "c") {
          this.isClosed = !this.isClosed;
          this.endOperation();
        } else if (cmd === "a") {
          this.changeArcLine();
        } else {
          this.addPointFromExt(cmd);
        }
      },
    }]);
  }
  public async start() {
    await super.start();
    this.auxLineObjData = new LineData({ arcs: this.arcs, isClosed: this.isClosed, points: this.points });
    this.auxLineObjData.createGraphicObj();    
    this.saveToTempScene(this.auxLineObjData.graphicObj);
    const c = this.graphicProcessor.getRaycaster();
    c.setObject2Raycast([this.auxLineObjData]);
    this.registerUndoRedo();
  }

  private updateMssgInfo() {
    if (this.points.length > 1) {
      const open = this.isClosed ? "Open" : "Close";
      const arc = this.mode === polylineInputMode.ARC ? "Line" : "Arc";
      if (this.points.length < 3) {
        this.settingsOpManager.currCfg.infoMsg = `Insert point or [${arc}]`;
      } else {
        this.settingsOpManager.currCfg.infoMsg = `Insert point or [${open}, ${arc}]`;
      }
    }
    this.settingsOpManager.dispatchUpdateCurrStep();
  }
  public changeCloseOpen = () => {
    this.isClosed = !this.isClosed;
    this.updateMssgInfo();
  }
  public changeArcLine = () => {
    if (this.mode === polylineInputMode.RECT && this.points.length > 1) {
      this.mode = polylineInputMode.ARC;
    } else if (this.mode === polylineInputMode.ARC) {
      this.mode = polylineInputMode.RECT;
    }
    this.updateMssgInfo();
  }

  public setLastPoint(): void {
    this.updateCurrentPlane();
    const numPtos = this.points.length;
    const endPoint = this.points[numPtos - 1];
    const lastArc = this.arcs[numPtos - 2];

    if (this.mode === polylineInputMode.RECT) {
      this.setLastSegmentPoint(lastArc, endPoint);
    } else if (this.mode === polylineInputMode.ARC) {
      this.setLastArcPoint(lastArc, endPoint, numPtos);
    }

    this.auxLineObjData.definition = { arcs: this.arcs, isClosed: this.isClosed, points: this.points };
    this.auxLineObjData.regenerateObjectFromDefinition();
    this.redoStackPoints = [];
    this.redoStackArcs = [];
    if (this.points.length < 4) {
      this.updateMssgInfo();
    }
  }
  private setLastArcPoint(lastArc: 0 | IArcLineParam, endPoint: IPoint, numPtos: number) {
    let azimut: number;
    if (lastArc) {
      // Last edge is an ARC
      azimut = this.getLastAzimut(lastArc, endPoint);
    } else {
      // Last edge is a SEGMENT
      const preLast = this.points[numPtos - 2];
      azimut = normalizeAngle(lineAzimut2p(preLast, endPoint));
    }
    const arc = getArcP1TangentDefinition(endPoint, azimut, this.lastPoint);
    arc.p1Tangent = false;
    arc.p3Tangent = false;
    this.points.push(copyIPoint(this.lastPoint));
    this.arcs.push(arc);
    this.mode = polylineInputMode.RECT;
    this.updateMssgInfo();
  }
  private setLastSegmentPoint(lastArc: 0 | IArcLineParam, endPoint: IPoint) {
    if (lastArc) {
      // Last edge is an ARC
      const azimut = this.getLastAzimut(lastArc, endPoint);
      const auxPto = getAzimutPolarPoint(endPoint, azimut, 10);
      const p = pointOnLine3D(endPoint, auxPto, this.lastPoint)[0];
      this.points.push(p);
      this.arcs.push(0);
    } else {
      // Last edge is a SEGMENT
      this.points.push(copyIPoint(this.lastPoint));
      if (this.points.length > 1)
        this.arcs.push(0);
    }
    // update auxLine
    const { x, y, z } = this.points[this.points.length - 1];
    lineMoveVertex(this.auxLine, x, y, z, 0);
    if (this.numPoints === 1) {
      lineMoveVertex(this.auxLine, x, y, z);
    }
  }

  public moveLastPoint(pto: IPoint) {
    const numPtos = this.points.length;
    if (this.points.length > 0) {
      const endPoint = this.points[numPtos - 1];
      const lastArc = this.arcs[numPtos - 2]
      if (this.mode === polylineInputMode.RECT) {
        this.moveLastSegmentPoint(lastArc, endPoint, pto);

      } else if (this.mode === polylineInputMode.ARC) {
        this.moveLastArcPoint(lastArc, endPoint, numPtos, pto);
      }
    }
  }
  private moveLastArcPoint(lastArc: 0 | IArcLineParam, endPoint: IPoint, numPtos: number, pto: IPoint) {
    let azimut: number;
    if (lastArc) {
      // Last edge is an ARC
      azimut = this.getLastAzimut(lastArc, endPoint);
    } else {
      // Last edge is a SEGMENT
      const preLast = this.points[numPtos - 2];
      azimut = normalizeAngle(lineAzimut2p(preLast, endPoint));
    }
    const arc = getArcP1TangentDefinition(endPoint, azimut, pto);
    arc.p1Tangent = false;
    arc.p3Tangent = false;
    const lineAuxPtos = arcPointsCR2p(arc.center, arc.radius, endPoint, pto, arc.direction, { x: 0, y: 0, z: 0 });
    if (this.isClosed)
      lineAuxPtos.push(this.points[0]);
    const buffer = IpointsToBuffer(lineAuxPtos);
    setPosBuffer(this.auxLine, buffer);
  }
  private moveLastSegmentPoint(lastArc: 0 | IArcLineParam, endPoint: IPoint, pto: IPoint) {
    if (lastArc) {
      // Last edge is an ARC
      const azimut = this.getLastAzimut(lastArc, endPoint);
      const auxPto = getAzimutPolarPoint(endPoint, azimut, 10);
      const p = pointOnLine3D(endPoint, auxPto, pto)[0];

      const lineAuxPtos = [endPoint, p];
      if (this.isClosed)
        lineAuxPtos.push(this.points[0]);
      const buffer32 = IpointsToBuffer(lineAuxPtos);
      setPosBuffer(this.auxLine, buffer32);
    } else {
      // Last edge is a SEGMENT
      const lineAuxPtos = [endPoint, pto];
      if (this.isClosed)
        lineAuxPtos.push(this.points[0]);
      const buffer = IpointsToBuffer(lineAuxPtos);
      setPosBuffer(this.auxLine, buffer);
    }
  }

  private getLastAzimut(lastArc: IArcLineParam, lastpto: IPoint) {
    let azimut = lineAzimut2p(lastArc.center, lastpto);
    if (lastArc.direction === ORIENT.CW) {
      azimut += Math.PI * 0.5;
    } else {
      azimut -= Math.PI * 0.5;
    }
    return normalizeAngle(azimut);
  }

  public undo(): void {
    if (this.points.length && this.arcs.length) {
      this.redoStackPoints.push(this.points.pop() as IPoint);
      this.redoStackArcs.push(this.arcs.pop() as 0);
      this.auxLineObjData.definition = { arcs: this.arcs, isClosed: this.isClosed, points: this.points };
      this.auxLineObjData.regenerateObjectFromDefinition();

      const position = this.graphicProcessor.getMouseCoordinates();
      this.changeLastPoint(position);
    }
  }
  public redo(): void {
    if (this.redoStackPoints.length) {
      this.points.push(this.redoStackPoints.pop() as IPoint);
      this.arcs.push(this.redoStackArcs.pop() as 0);
      this.auxLineObjData.definition = { arcs: this.arcs, isClosed: this.isClosed, points: this.points };
      this.auxLineObjData.regenerateObjectFromDefinition();

      const position = this.graphicProcessor.getMouseCoordinates();
      this.changeLastPoint(position);
    }
  }

  public save() {
    const c = this.graphicProcessor.getRaycaster();
    c.setObject2Raycast([]);

    if (this.graphicProcessor && this.points.length > 1) {
      if (vector3Equals(this.points[0], this.points[this.points.length - 1])) {
        this.points.pop();
        this.isClosed = true;
      }
      if (this.isClosed) this.arcs.push(0);
      const command = new PolyLineCommand({
        points: this.points,
        isClosed: this.isClosed,
        arcs: this.arcs,
      },
        this.getCurrentSceneId(),
        this.graphicProcessor,
        getCurrentLineMaterial({ lineStyleId: this.lineStyleId })
      );
      this.graphicProcessor.storeAndExecute(command);
      if (this.endOperationCallback) this.endOperationCallback(command);
    }
  }
  public endOperationCallback: (cmd: PolyLineCommand) => void;

}
