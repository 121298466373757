import { eventBus } from "./event-bus";

export enum UserMessageActionType {
  SET_MESSAGE,
}
export interface userMessage {
  message: string;
  type: "success" | "error" | "warning";
}
export type UserMessageAction = {
  type: UserMessageActionType.SET_MESSAGE;
  payload: userMessage;
}

type UserMessageCallback = (action: UserMessageAction) => void;

export const userMessageEvents = {
  dispatchError: (mssg: string) =>
    eventBus.dispatch("USER_MESSAGE", {
      type: UserMessageActionType.SET_MESSAGE,
      payload: { message: mssg, type: "error" },
    }),
  dispatchWarning: (mssg: string) =>
    eventBus.dispatch("USER_MESSAGE", {
      type: UserMessageActionType.SET_MESSAGE,
      payload: { message: mssg, type: "warning" },
    }),
  dispatchSucces: (mssg: string) =>
    eventBus.dispatch("USER_MESSAGE", {
      type: UserMessageActionType.SET_MESSAGE,
      payload: { message: mssg, type: "success" },
    }),
  subscribe: (callback: UserMessageCallback) =>
    eventBus.subscribe("USER_MESSAGE", callback),
  unsubscribe: (callback: UserMessageCallback) =>
    eventBus.unsubscribe("USER_MESSAGE", callback),
};
