import { FC, TableHTMLAttributes } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import RenderIf from "../render-if";
import styled from "styled-components";
interface CellProps extends TableHTMLAttributes<HTMLTableCellElement> {
  className?: string;
  variant?: "auto" | "minimized";
  style?: Record<string, any>;
  to?: string;
}

interface HeaderCellProps extends CellProps {
  colSpan?: number;
  rowSpan?: number;
  scope?: string;
}

interface RowProps extends TableHTMLAttributes<HTMLTableRowElement> {
  isOpen?: boolean;
  onClick?: (...args: any[]) => void;
}
interface Composition {
  Header: FC;
  HeaderCell: FC<HeaderCellProps>;
  Body: FC;
  Row: FC<RowProps>;
  Cell: FC<CellProps>;
  Container: FC;
}

const Table: FC & Composition = ({ children }) => {
  return (
    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600 bg-white dark:bg-gray-800">
      {children}
    </table>
  );
};

const Container: FC = ({ children }) => (
  <div className="flex flex-col overflow-hidden">
    <div className="align-middle inline-block min-w-full overflow-x-auto">
      {children}
    </div>
  </div>
);

const Header: FC = ({ children }) => (
  <thead>
    <tr>{children}</tr>
  </thead>
);
const HeaderCell: FC<HeaderCellProps> = ({
  children,
  className,
  variant = "minimized",
  style,
  colSpan,
  rowSpan,
  scope,
}) => {
  const rootClassName = cn(
    "px-1 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-400",
    variant === "minimized" && "w-16",
    variant === "auto" && "w-full",
    className
  );

  return (
    <th
      colSpan={colSpan}
      rowSpan={rowSpan}
      scope={scope || "col"}
      className={rootClassName}
      style={style}
    >
      {children}
    </th>
  );
};
const Body: FC = ({ children }) => <tbody>{children}</tbody>;

const Row: FC<RowProps> = ({ children, isOpen = true, onClick, ...props }) => (
  <RenderIf isTrue={!!isOpen}>
    <RowWrapper onClick={onClick} {...props}>
      {children}
    </RowWrapper>
  </RenderIf>
);
const RowWrapper = styled.tr<RowProps>`
  cursor: ${(props) => (props.onClick ? "pointer" : props.draggable ? "move" : "default")};
  border-bottom: 1px solid;
  border-color: var(--color-divider);
  &.dragging-over {
    background-color: var(--color-gray-200);
  }
  &:hover {
    background-color: var(--color-gray-100);
  }
`;

const Cell: FC<CellProps> = ({ children, className, to, ...props }) => {
  const rootClassName = cn(
    "px-1 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400 max-w-xs truncate",
    props.draggable ? "cursor-move" : "",
    className
  );
  const content = children || children === 0 ? children : "—";
  return (
    <td className={to ? className : rootClassName} {...props}>
      {to ? (
        <Link
          to={to}
          className={
            "block w-full px-1 py-2 whitespace-nowrap text-sm text-gray-500 max-w-xs truncate"
          }
        >
          {content}
        </Link>
      ) : (
        content
      )}
    </td>
  );
};

Table.Header = Header;
Table.HeaderCell = HeaderCell;
Table.Body = Body;
Table.Row = Row;
Table.Cell = Cell;
Table.Container = Container;

export default Table;
