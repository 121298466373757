import PropertiesInput from "modules/cad/components/properties/component-input";
import { isSandwichCSSParams, sandwichCrossSection, waffleCrossSection } from "lib/models-struc/cross-sections-shape/shell-cross-sections/types";
import { getUserAngleUnitSufix, radAngleToUser } from "lib/general-settings";

interface Props {
  register: any;
  paramSlab: waffleCrossSection | sandwichCrossSection;
  isDefault: boolean;
}

const WaffleCSSParam = ({ register, paramSlab, isDefault }: Props) => {

  return (
    <>
      <div className="border-b border-gray-700 space-y-2"></div>
      <PropertiesInput
        defaultValue={paramSlab.xInteraxis}
        id={"xInteraxis"}
        disabled={isDefault}
        key={"xInteraxis"}
        label={"Interaxis X:"}
        name={"parameters.xInteraxis"}
        ref={register({ valueAsNumber: true })}
        adornment={<span>{"m"}</span>}
      />
      <PropertiesInput
        defaultValue={paramSlab.yInteraxis}
        id={"yInteraxis"}
        disabled={isDefault}
        key={"yInteraxis"}
        label={"Interaxis Y:"}
        name={"parameters.yInteraxis"}
        ref={register({ valueAsNumber: true })}
        adornment={<span>{"m"}</span>}
      />
      <PropertiesInput
        defaultValue={paramSlab.ribWidth}
        id={"ribwidth"}
        disabled={isDefault}
        key={"ribwidth"}
        label={"Rib width:"}
        name={"parameters.ribWidth"}
        ref={register({ valueAsNumber: true })}
        adornment={<span>{"m"}</span>}
      />
      <PropertiesInput
        defaultValue={paramSlab.numberOfBlocks}
        id={"numberOfBlocks"}
        disabled={isDefault}
        key={"numberOfBlocks"}
        label={"Number of blocks:"}
        name={"parameters.numberOfBlocks"}
        ref={register({ valueAsNumber: true })}
        adornment={<span>{"m"}</span>}
      />
      <PropertiesInput
        defaultValue={paramSlab.outerNonstBeamWidth}
        id={"outerNonstBeamWidth"}
        disabled={isDefault}
        key={"outerNonstBeamWidth"}
        label={"Outer non beam width:"}
        name={"parameters.outerNonstBeamWidth"}
        ref={register({ valueAsNumber: true })}
        adornment={<span>{"m"}</span>}
      />
      <PropertiesInput
        defaultValue={paramSlab.innerNonstBeamWidth}
        id={"innerNonstBeamWidth"}
        disabled={isDefault}
        key={"innerNonstBeamWidth"}
        label={"Inner non beam width:"}
        name={"parameters.innerNonstBeamWidth"}
        ref={register({ valueAsNumber: true })}
        adornment={<span>{"m"}</span>}
      />
      <PropertiesInput
        defaultValue={paramSlab.minDropPanelEdge}
        id={"minDropPanelEdge"}
        disabled={isDefault}
        key={"minDropPanelEdge"}
        label={"Min drop panel edge:"}
        name={"parameters.minDropPanelEdge"}
        ref={register({ valueAsNumber: true })}
        adornment={<span>{"m"}</span>}
      />
      <PropertiesInput
        defaultValue={radAngleToUser(paramSlab.rotationAngle)}
        id={"rotationAngle"}
        disabled={isDefault}
        key={"rotationAngle"}
        label={"Rotation angle:"}
        name={"parameters.rotationAngle"}
        ref={register({ valueAsNumber: true })}
        adornment={<span>{getUserAngleUnitSufix()}</span>}
      />
      <PropertiesInput
        defaultValue={paramSlab.centerWaffleX}
        id={"centerWaffleX"}
        disabled={isDefault}
        key={"centerWaffleX"}
        label={"Center waffle X:"}
        name={"parameters.centerWaffleX"}
        ref={register({ valueAsNumber: true })}
        adornment={<span>{"m"}</span>}
      />
      <PropertiesInput
        defaultValue={paramSlab.centerWaffleY}
        id={"centerWaffleY"}
        disabled={isDefault}
        key={"centerWaffleY"}
        label={"Center waffle Y:"}
        name={"parameters.centerWaffleY"}
        ref={register({ valueAsNumber: true })}
        adornment={<span>{"m"}</span>}
      />
      <PropertiesInput
        defaultValue={paramSlab.dropPanelRatio}
        id={"dropPanelRatio"}
        disabled={isDefault}
        key={"dropPanelRatio"}
        label={"Drop panel ratio:"}
        name={"parameters.dropPanelRatio"}
        ref={register({ valueAsNumber: true })}
        adornment={<span>{"m"}</span>}
      />
      <PropertiesInput
        defaultValue={paramSlab.compressionLayerDepth}
        id={"compressionLayerDepth"}
        disabled={isDefault}
        key={"compressionLayerDepth"}
        label={"Compression layer depth:"}
        name={"parameters.compressionLayerDepth"}
        ref={register({ valueAsNumber: true })}
        adornment={<span>{"m"}</span>}
      />
      {isSandwichCSSParams(paramSlab) &&
        <PropertiesInput
          defaultValue={paramSlab.lowerCompressionLayerDepth}
          id={"lowerCompressionLayerDepth"}
          disabled={isDefault}
          key={"lowerCompressionLayerDepth"}
          label={"Lower compression layer depth:"}
          name={"parameters.lowerCompressionLayerDepth"}
          ref={register({ valueAsNumber: true })}
          adornment={<span>{"m"}</span>}
        />
      }
    </>
  )
}

export default WaffleCSSParam;