import { GraphicProcessor } from "lib/graphic-processor";
import { isLoadData, isStructData } from "lib/models/checktools";
import { IObjData } from "lib/models/objdata";
import { AddConsolidation } from "../checkers.ts/struc-consolidate";
import { hypothesisManager } from "../hypothesis/hypothesismodel-manager";

export class AddObjDatasToLayer {

  static run(data: IObjData, layerId: string, graphicProcessor: GraphicProcessor) {

    const dataModel = graphicProcessor.getDataModelManager();
    const layerManager = dataModel.layerManager;

    if (dataModel.isRegister(data)) {
      console.warn(`[ADD_OBJ] Object ${data.objName} already register`);
      return;
    }

    dataModel.registerData(data);
    layerManager.addObjDataToLayer(data, layerId);
    dataModel.registerGraphicData(data);

    if (isLoadData(data)) {
      const strcMdlManager = graphicProcessor.getStructuralModelManager();
      strcMdlManager.registerLoadData(data);
      data.parentStrucElem.loads.push(data);
      hypothesisManager.addLoad(data);
    }

    if (isStructData(data)) {
      const strcMdlManager = graphicProcessor.getStructuralModelManager();
      strcMdlManager.registerStrucData(data);
      // Restore possible loads
      for (let load of data.loads) {
        if (dataModel.isRegister(load)) {
          console.warn(`[ADD_OBJ] Object ${load.objName} already register`);
          return;
        }
        dataModel.registerData(load);
        layerManager.addObjDataToLayer(load, load.layerId);
        dataModel.registerGraphicData(load);
        strcMdlManager.registerLoadData(load);
        hypothesisManager.addLoad(load);
      }

      // Resolve consolidation and linked object
      AddConsolidation(data, graphicProcessor);
    }
  }
}