import Section from "shared/components/ui/section";
import { useMeasurements } from "../measurements/hooks/use-measurements";
import FEMResults from "../fem/fem-results";
import FEMEvents from "../fem/fem-events";
import styled from "styled-components";
import useProject from "./hooks/use-project";
import { PrimaryButton } from "shared/components/ui/button";

const Side = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;


const ProjectMeasurements = () => {
  const { events, buildingModelUrl } = useMeasurements();
  const { calculateFEMResults } = useProject();
  return (
    <>
      <Section title="Events">
      <Side>  
        <FEMEvents events={events} />
          <PrimaryButton onClick={calculateFEMResults}>
              Calculate
          </PrimaryButton>      
        </Side>
      </Section>
      <Section title="Results">
        <FEMResults buildingModelUrl={buildingModelUrl} />
      </Section>
    </>
  );
};

export default ProjectMeasurements;
