import * as THREE from "three";
import { getDimAuxGeometry, getDimGeometry, getDimLabel } from "lib/dimension/line-dim-builder";
import { lineExtractAllVertex } from "lib/geometries/line";
import { cadOpType } from "lib/operations/factory";
import { CircularDimensionData, circularDimType } from "lib/models/dimension/circular-dim";
import { getAbsolutePoint } from "lib/coordinates/plane";
import { SelectionManager } from "lib/selection/selection-manager";
import { ObjDataSelector } from "../selector-data";

export class CircularDimensionDataSelector extends ObjDataSelector<CircularDimensionData> {

  public override buildSelectMarks() {
    /* POSICION DE LOS PUNTOS QUE DEFINEN LA GEOMETRÍA DE LA ACOTACIÓN
        Acotación fuera del circulo
         0-----------------------------1----------------------------------·------2
         |            radio            | longFlecha | distBaseLine | textYOffset |
        Acotación dentro del circulo
         0        2-----·----------------------------------1
         |        |textYOffset | distBaseLine | longFlecha |
    */
    const pos = this.data.graphicObj.position;
    const rot = this.data.graphicObj.rotation;

    let lineArc: Float32Array | undefined;
    const auxGeom = getDimAuxGeometry(this.data.graphicObj);
    if (auxGeom && auxGeom.visible) {
      const bufferArc = auxGeom.geometry as THREE.BufferGeometry;
      const bufferArrayArc = bufferArc.getAttribute("position").clone();
      lineArc = bufferArrayArc.applyMatrix4(this.data.graphicObj.matrixWorld).array as Float32Array;
    }

    const dimGeom = getDimGeometry(this.data.graphicObj);
    const v = lineExtractAllVertex(dimGeom);
    const textPos = getDimLabel(this.data.graphicObj);

    const buffer = dimGeom.geometry as THREE.BufferGeometry;
    const bufferArray = buffer.getAttribute("position").clone();
    const lines = [bufferArray.applyMatrix4(this.data.graphicObj.matrixWorld).array as Float32Array];
    if (lineArc)
      lines.push(lineArc);

    return {
      vertex: [
        getAbsolutePoint(textPos.position, pos, rot),
        getAbsolutePoint(v[0], pos, rot),
        getAbsolutePoint(v[1], pos, rot),
        getAbsolutePoint(v[2], pos, rot),
      ],
      line: lines,
    };
  }

  public override launchMarkOP(selectionManager: SelectionManager, mark: THREE.Object3D, vertexIndex: number) {
    if (selectionManager.vertexSelector.pointObj === mark) {
      const type = this.data.definition.type === circularDimType.DIAMETER ? cadOpType.EDITDIAMETERDIM : cadOpType.EDITRADIUSDIM;
      return { type, graphicProcessor: this.graphicProc, args: [this.data, vertexIndex] };
    }
  }
}
