import { TextEditDataCommand } from "lib/commands/edition/text";

import { linealPrecision } from "lib/general-settings";
import { IColor } from "lib/math/types";
import { TextData, textParam } from "lib/models/text";
import { fontArrayNames } from "lib/text/font-loader";
import { sdfDoubleSidedTypeNames, textMultiPosTypeNamesH, textMultiPosTypeNamesV, TextOptsBuilder } from "lib/text/styles";
import { getDefaultTextStyle, textStyleCache } from "lib/text/cache";
import { DataDefinitionHandler } from "./base";
import { ISolidMaterial } from '../materials/solid';

type textStyleProp = {
  styleId: string;
  font: number;
  doubleSided: number;
  letterSpacing: number;
  lineHeight: number;
  //cursiveFactor: number;
  size: number;
  color: IColor;
  basePointV: number;
  basePointH: number;
}

export class TextDataDefinitionHandler extends DataDefinitionHandler<textParam, ISolidMaterial, textStyleProp, undefined> {

  protected data: TextData;

  protected buildInfoProperties() {
    const def = this.data.definition;
    const style = textStyleCache.loadStylefromCache(def.styleId, def.override) ?? getDefaultTextStyle();
    this.definitionInfo = {
      text: { type: "string", value: def.text, publicName: "Text", },
      angleO: this.getAngleView(def.angleO, "Origin"),
      position: this.getPointView(def.position, "Position", "m", linealPrecision),
      plane: this.getPointAngleView(def.plane, "Rotation"),
      scale: this.getNumberView(def.scale, "Scale", "", linealPrecision, true, (scl: number) => scl > 0),
    }
    this.styleInfo = {
      styleId: this.getTextStyleView(def.styleId),
      font: this.getEnumView(style.font, fontArrayNames, "Font"),
      doubleSided: this.getEnumView(style.doubleSided, sdfDoubleSidedTypeNames, "Double Sided"),
      letterSpacing: this.getNumberView(style.letterSpacing, "Letter spacing", "m", linealPrecision),
      lineHeight: this.getNumberView(style.lineHeight, "Line height", "m", linealPrecision),
      // cursiveFactor?: infoProperty<number>;
      size: this.getNumberView(style.size, "Size", "m", linealPrecision),
      color: this.getColorView(style.color),
      basePointV: this.getEnumView(style.basePointV, textMultiPosTypeNamesV, "Base PointV"),
      basePointH: this.getEnumView(style.basePointH, textMultiPosTypeNamesH, "Base PointH"),
    }
  }

  private checkNewDefinition(newDefinition: textParam): boolean {
    if (!newDefinition) { return false; }
    if (!this.checkString(newDefinition.text)) { return false; }
    if (!this.checkAngle(newDefinition.angleO)) { return false; }
    if (!this.checkPoint(newDefinition.position)) { return false; }
    if (!this.checkPoint(newDefinition.plane)) { return false; }
    if (!this.checkNumber(newDefinition.scale)) { return false; }
    return true;
  }
  private checkNewStyle(newStyle: textStyleProp): boolean {
    if (!newStyle) { return false; }
    if (!this.checkString(newStyle.styleId)) { return false; }
    if (!this.checkEnum(newStyle.font)) { return false; }
    if (!this.checkEnum(newStyle.doubleSided)) { return false; }
    if (!this.checkNumber(newStyle.letterSpacing)) { return false; }
    if (!this.checkNumber(newStyle.lineHeight)) { return false; }
    if (!this.checkNumber(newStyle.size)) { return false; }
    if (!this.checkColor(newStyle.color)) { return false; }
    if (!this.checkEnum(newStyle.basePointV)) { return false; }
    if (!this.checkEnum(newStyle.basePointH)) { return false; }
    return true;
  }

  private changedNewDefinition(oldDefinition: textParam, newDefinition: textParam): textParam | null {
    let def = oldDefinition;
    let changed: boolean = false;
    if (newDefinition) {
      const text = this.changedString(def.text, newDefinition.text);
      if (text !== null) {
        def.text = text;
        changed = true;
      }
      const angleO = this.changedAngle(def.angleO, newDefinition.angleO);
      if (angleO !== null) {
        def.angleO = angleO;
        changed = true;
      }
      const position = this.changedPoint(def.position, newDefinition.position);
      if (position !== null) {
        def.position = position;
        changed = true;
      }
      const plane = this.changedPointRotation(def.plane, newDefinition.plane);
      if (plane !== null) {
        def.plane = plane;
        changed = true;
      }
      const scale = this.changedNumber(def.scale, newDefinition.scale);
      if (scale !== null) {
        def.scale = scale;
        changed = true;
      }
    }
    return (changed ? def : null);
  }
  private changedNewStyle(oldDefinition: textParam, newStyle: textStyleProp): textParam | null {
    let def = oldDefinition;
    let changed: boolean = false;
    if (newStyle) {
      const textStyleId = this.changedString(def.styleId, newStyle.styleId);
      if (textStyleId !== null) {
        def.styleId = textStyleId;
        def.override = undefined;
        changed = true;
      } else {
        const newOverride = this.changedNewStyleOverride(def, newStyle);
        if (newOverride !== null) {
          def.override = newOverride;
          changed = true;
        }
      }
    }
    return (changed ? def : null);
  }
  private changedNewStyleOverride(oldDefinition: textParam, newStyle: textStyleProp): Partial<TextOptsBuilder> | null {
    let def = oldDefinition;
    let changedOverride: boolean = false;
    const newOverride = Object.assign({}, oldDefinition.override);
    const style = textStyleCache.loadStylefromCache(def.styleId, def.override) ?? getDefaultTextStyle();
    const font = this.changedEnum(style.font, newStyle.font);
    if (font !== null) {
      newOverride.font = font;
      changedOverride = true;
    }
    const doubleSided = this.changedEnum(style.doubleSided, newStyle.doubleSided);
    if (doubleSided !== null) {
      newOverride.doubleSided = doubleSided;
      changedOverride = true;
    }
    const letterSpacing = this.changedNumber(style.letterSpacing, newStyle.letterSpacing);
    if (letterSpacing !== null) {
      newOverride.letterSpacing = letterSpacing;
      changedOverride = true;
    }
    const lineHeight = this.changedNumber(style.lineHeight, newStyle.lineHeight);
    if (lineHeight !== null) {
      newOverride.lineHeight = lineHeight;
      changedOverride = true;
    }
    const size = this.changedNumber(style.size, newStyle.size);
    if (size !== null) {
      newOverride.size = size;
      changedOverride = true;
    }
    const color = this.changedColor(style.color, newStyle.color);
    if (color !== null) {
      newOverride.color = color;
      changedOverride = true;
    }
    const basePointV = this.changedEnum(style.basePointV, newStyle.basePointV);
    if (basePointV !== null) {
      newOverride.basePointV = basePointV;
      changedOverride = true;
    }
    const basePointH = this.changedEnum(style.basePointH, newStyle.basePointH);
    if (basePointH !== null) {
      newOverride.basePointH = basePointH;
      changedOverride = true;
    }
    return (changedOverride ? newOverride : null);
  }


  public saveAndRegenerate = (newDefinition: textParam) => {
    const textDefinition = this.checkNewDefinition(newDefinition) ? this.changedNewDefinition(this.data.cloneDefinition(), newDefinition) : null;
    if (textDefinition) {
      const command = new TextEditDataCommand(this.data, textDefinition, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    };
  }
  public saveAndRegenerateStyle = (newStyle: textStyleProp) => {
    const textDefinition = this.checkNewStyle(newStyle) ? this.changedNewStyle(this.data.cloneDefinition(), newStyle) : null;
    if (textDefinition) {
      const command = new TextEditDataCommand(this.data, textDefinition, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    };
  }
}