import { FC } from "react";
import { Project } from "modules/lite/models/project";
import ProjectDetails from "./project-details";
import ProjectStoreys from "./proyect-storeys";
import styled from "styled-components";

const Wrapper = styled.div`
  & > * + * {
    margin-top: 16px;
  }
`;

interface Props {
  project: Project;
}

const ProjectBoard: FC<Props> = ({ project }) => {
  return (
    <Wrapper>
      <ProjectDetails project={project} />
      <ProjectStoreys storeys={project.storeys} />
    </Wrapper>
  );
};

export default ProjectBoard;
