/**
 * \file viewports.ts
 * La parte de eventos relativos a los viewports.
 */

import { GraphicViewport } from "lib/graphic-viewport";

export enum ViewportActionType {
  CHANGE_ACTIVE_VIEWPORT,
}

export type ViewportAction =
  | {
    type: ViewportActionType;
    payload: {
      viewport: GraphicViewport;
      container: HTMLDivElement;
    };
  };
