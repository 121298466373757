import { euConcreteType, usConcreteType } from "modules/struc/models/codes";
import { beamParam } from './beam';
import { columnParam } from './column';
import { wallParam } from './wall';
import { slabParam } from './slab';
import { footerParam, stripfootingParam } from './footer';
import { pileCapParam } from './pile-cap';
import { objDataType } from "lib/models/types";

export type buildingElemType =
  objDataType.SLAB |
  objDataType.COLUMN |
  objDataType.BEAM |
  objDataType.WALL |
  objDataType.FOOTER |
  objDataType.STRIPFOOTER |
  objDataType.PILECAP;

export type structElemType = objDataType.LOAD | buildingElemType;

export const structElemTypeList = [
  objDataType.SLAB,
  objDataType.COLUMN,
  objDataType.BEAM,
  objDataType.WALL,
  objDataType.FOOTER,
  objDataType.STRIPFOOTER,
  objDataType.PILECAP
]

export function getStructElemTypeName(type: structElemType) {
  if (type === objDataType.SLAB) return "SLAB"
  if (type === objDataType.COLUMN) return "COL"
  if (type === objDataType.BEAM) return "BEAM"
  if (type === objDataType.WALL) return "WALL"
  if (type === objDataType.LOAD) return "LOAD"
  if (type === objDataType.FOOTER) return "FOOTER"
  if (type === objDataType.STRIPFOOTER) return "STRIPFOOTER"
  if (type === objDataType.PILECAP) return "PILECAP"
  return "NONE"
}

export type supportDefinitionType =
  wallParam |
  columnParam;

export type buildingStrucElemRepresentation =
  wallParam |
  columnParam |
  beamParam |
  slabParam |
  footerParam |
  stripfootingParam |
  pileCapParam;

export type storeyMaterialType = euConcreteType | usConcreteType;

// **********************************************************************

export enum originPoint { TOPLEFT, TOPCENTER, TOPRIGHT, CENTERLEFT, CENTERCENTER, CENTERRIGHT, BOTTOMLEFT, BOTTOMCENTER, BOTTOMRIGHT }

export enum growthWidth { TOLEFT, CENTER, TORIGHT }
export const growthWidthTypeNames: string[] = [];
growthWidthTypeNames[growthWidth.TOLEFT] = "Left";
growthWidthTypeNames[growthWidth.CENTER] = "Center";
growthWidthTypeNames[growthWidth.TORIGHT] = "Right";

export enum heightType { STOREY, LEVEL, LENGTH }
export const heightTypeNames: string[] = [];
heightTypeNames[heightType.STOREY] = "Storey";
heightTypeNames[heightType.LEVEL] = "Level";
heightTypeNames[heightType.LENGTH] = "Length";
