import { ArcEditDataCommand } from "lib/commands/edition/arc";

import { linealPrecision } from "lib/general-settings";
import { arcParam } from "lib/math/arc";
import { ArcData } from "lib/models/primitives/arc";
import { ORIENT } from "../../math/angles";
import { DataDefinitionHandler } from "../base";
import { ILineMaterial } from '../../materials/line';

type arcProp = arcParam & { direction: ORIENT; length: number; };
export class ArcDataDefinitionHandler extends DataDefinitionHandler<arcProp, ILineMaterial, undefined, undefined> {

  protected data: ArcData;

  protected buildInfoProperties() {
    const def = this.data.definition;
    this.definitionInfo = {
      center: this.getPointView(def.center, "Center", "m", linealPrecision),
      radius: this.getNumberView(def.radius, "Radius", "m", linealPrecision, true, (radius: number) => radius > 0),
      azimutO: this.getAngleView(def.azimutO, "Origin"),
      angleCenter: this.getAngleView(def.angleCenter, "Develop angle"),
      plane: this.getPointAngleView(def.plane, "Rotation"),
      direction: {
        type: "string",
        value: def.angleCenter < 0 ? ORIENT.CCW : ORIENT.CW,
        publicName: "Direccion",
        editable: false,
      },
      length: this.getNumberView(Math.abs(def.angleCenter * def.radius), "Length", "m", linealPrecision, false),
    };
    this.materialInfo = this.getLineMaterialView(this.data.material);
  }

  private checkNewDefinition(newDefinition: arcProp): boolean {
    if (!newDefinition) { return false; }
    if (!this.checkPoint(newDefinition.center)) { return false; }
    if (!this.checkNumber(newDefinition.radius)) { return false; }
    if (!this.checkAngle(newDefinition.azimutO)) { return false; }
    if (!this.checkAngle(newDefinition.angleCenter)) { return false; }
    if (!this.checkPoint(newDefinition.plane)) { return false; }
    return true;
  }

  private changedNewDefinition(oldDefinition: arcParam, newDefinition: arcProp): arcParam | null {
    let def = oldDefinition;
    let changed: boolean = false;
    if (newDefinition) {
      const center = this.changedPoint(def.center, newDefinition.center);
      if (center !== null) {
        def.center = center;
        changed = true;
      }
      const radius = this.changedNumber(def.radius, newDefinition.radius);
      if (radius !== null) {
        def.radius = radius;
        changed = true;
      }
      const azimutO = this.changedAngle(def.azimutO, newDefinition.azimutO);
      if (azimutO !== null) {
        def.azimutO = azimutO;
        changed = true;
      }
      const angleCenter = this.changedAngle(def.angleCenter, newDefinition.angleCenter);
      if (angleCenter !== null) {
        def.angleCenter = angleCenter;
        changed = true;
      }
      const plane = this.changedPointRotation(def.plane, newDefinition.plane);
      if (plane !== null) {
        def.plane = plane;
        changed = true;
      }
    }
    return changed ? def : null;
  }

  private checkAndChangedDefinition(oldDefinition: arcParam, newDefinition: arcProp): arcParam | null {
    let def = null;
    if (this.checkNewDefinition(newDefinition)) {
      def = this.changedNewDefinition(oldDefinition, newDefinition);
    }
    return def;
  }

  public saveAndRegenerate = (newDefinition: arcProp) => {
    const arcDefinition = this.checkAndChangedDefinition(this.data.cloneDefinition(), newDefinition);
    if (arcDefinition) {
      const command = new ArcEditDataCommand(this.data, arcDefinition, null, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  };
  public saveAndRegenerateMaterial = (newMaterial: ILineMaterial) => {
    const arcMaterial = this.checkAndChangedLineMaterial(this.data.material, newMaterial);
    if (arcMaterial) {
      const command = new ArcEditDataCommand(this.data, null, arcMaterial, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  };
}