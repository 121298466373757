import * as THREE from "three";
import { addIpoint, copyIPoint } from "lib/math/point";
import { IPoint } from "lib/math/types";
import { ObjData } from "./objdata";
import { objDataType } from "./types";
import { changeTextProperties, createText, modifyText } from "lib/text/builder";
import { TextOptsBuilder } from "lib/text/styles";
import { textStyleCache } from "lib/text/cache";
import { eulerAnglesToAxisAngle, mirrorAngle, normalizeAngle } from "lib/math/angles";
import { rotatePoint } from "lib/math/rotate";
import { scalePoint } from "lib/math/scale";
import { mirrorPoint } from "lib/math/mirror";

export interface textParam {
  styleId: string;
  text: string;
  position: IPoint;
  angleO: number;
  plane: IPoint;
  scale: number; // Scale factor applied to the size of the text
  override?: Partial<TextOptsBuilder>;
}

export class TextData extends ObjData {

  public type = objDataType.TEXT;
  protected nameObj: string = "Text";

  public definition: textParam;
  public material: undefined;

  public graphicObj: THREE.Mesh;

  constructor(definition: textParam) {
    super();
    this.definition = {
      styleId: definition.styleId,
      text: definition.text,
      position: copyIPoint(definition.position),
      angleO: definition.angleO,
      plane: copyIPoint(definition.plane),
      scale: definition.scale,
      override: definition.override,
    }
  }
  static createObj(definition: textParam) {
    // Style is in cache
    const style = textStyleCache.loadStylefromCache(definition.styleId, definition.override);
    return createText(definition, style);
  }
  public createGraphicObj() {
    if (this.graphicObj) {
      console.warn("Attention: Text graphic object already created!!");
      return;
    }
    this.graphicObj = TextData.createObj(this.definition);
    this.graphicObj.renderOrder = 3;
  }
  public cloneDefinition(): textParam {
    return {
      styleId: this.definition.styleId,
      text: this.definition.text,
      position: copyIPoint(this.definition.position),
      angleO: this.definition.angleO,
      plane: copyIPoint(this.definition.plane),
      scale: this.definition.scale,
      override: this.definition.override,
    }
  }
  public createObject(definition?: textParam): THREE.Mesh {
    return TextData.createObj(definition ?? this.definition);
  }

  public override regenerateObjectFromDefinition(): void {
    const def = this.definition;
    const style = textStyleCache.loadStylefromCache(def.styleId, def.override);
    modifyText(this.graphicObj, def.text, style)
    changeTextProperties(this.graphicObj, def.position, def.plane, def.angleO, def.scale);
  }

  public translate(distance: IPoint) {
    this.definition.position = addIpoint(this.definition.position, distance);
    this.regenerateObjectFromDefinition();
  }
  public rotate(angleX: number, angleY: number, angleZ: number, basePoint: IPoint): void {
    angleX = normalizeAngle(angleX);
    angleY = normalizeAngle(angleY);
    angleZ = normalizeAngle(angleZ);

    this.definition.position = rotatePoint(this.definition.position, angleX, angleY, angleZ, basePoint);
    const { angle } = eulerAnglesToAxisAngle(angleX, angleY, angleZ);
    this.definition.angleO = normalizeAngle(this.definition.angleO + angle);
    this.regenerateObjectFromDefinition();
  }
  public scale(factorX: number, factorY: number, factorZ: number, basePoint: IPoint): void {
    this.definition.position = scalePoint(this.definition.position, factorX, factorY, factorZ, basePoint);
    this.definition.scale = factorX;
    this.regenerateObjectFromDefinition();
  }
  public mirror(startPoint: IPoint, endPoint: IPoint): void {
    this.definition.position = mirrorPoint(
      this.definition.position,
      startPoint,
      endPoint
    );
    this.definition.angleO = mirrorAngle(
      this.definition.angleO,
      startPoint,
      endPoint
    );
    // TODO: falta espejo de la fuente
    this.regenerateObjectFromDefinition();
  }
  public delete(): void {
    this.graphicObj.geometry.dispose();
  }
}
