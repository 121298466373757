import { getHypothesisInfoprop, Hypothesis } from "lib/models-struc/hypothesis/hypothesis";
import { dataInfoProperty } from "lib/properties/properties";
import { useState, useCallback, useEffect } from "react";
import { useStrucProjectContext } from "../../context";

export function useDeleteHypothesis() {

  const { hypothesisManager } = useStrucProjectContext();
  const hypothesisSets = hypothesisManager.getAllHypothesis();
  const [currHypothesis, setCurrHypothesis] = useState<Hypothesis>(hypothesisSets[0]);

  const [hypothesisProperties, setHypothesisProperties] = useState<dataInfoProperty<Hypothesis>>(() => {
    const isPredefined = hypothesisManager.isPredefined(hypothesisSets[0].name);
    return getHypothesisInfoprop(hypothesisSets[0], !isPredefined);
  });

  const setCurrHypothesisName = useCallback((hypoName: string) => {
    const hypoSet = hypothesisSets.find((h) => h.name === hypoName);
    if (hypoSet) {
      setCurrHypothesis(hypoSet);
    }
  }, [hypothesisSets]);

  useEffect(() => {
    const isPredefined = hypothesisManager.isPredefined(currHypothesis.name);
    setHypothesisProperties(getHypothesisInfoprop(currHypothesis, !isPredefined));
  }, [currHypothesis, hypothesisManager]);

  const editHypothesisProperties = (newVal: Hypothesis) => {
    const hypoProp = { ...newVal };
    const isPredefined = hypothesisManager.isPredefined(hypoProp.name);
    setHypothesisProperties(getHypothesisInfoprop(hypoProp, !isPredefined));
    setCurrHypothesis(hypoProp);
    hypothesisManager.editHypothesis(hypoProp, currHypothesis.uid);
  }

  const deleteHypothesisSet = useCallback(() => {
    hypothesisManager.deleteHypothesis(currHypothesis);
    setCurrHypothesis(hypothesisSets[0]);
  }, [currHypothesis, hypothesisManager, hypothesisSets]);

  return {
    hypothesisSets,
    currHypothesis,
    setCurrHypothesisName,
    hypothesisProperties,
    editHypothesisProperties,
    deleteHypothesisSet,
  };
}
