import { GraphicProcessor } from "lib/graphic-processor";
import { objDataType } from "lib/models/types";
import { TextData, textParam } from "lib/models/text";
import { cadOpType } from "lib/operations/factory";
import { createCommand } from "./create-base";

export class TextCommand extends createCommand {
    
    public createdData: TextData;
    protected opType = cadOpType.TEXT;
    protected objType = objDataType.TEXT;
    protected param: textParam;
    protected material: undefined;

    constructor(params: textParam, layerId: string, graphicProcessor: GraphicProcessor) {
        super(layerId, graphicProcessor);
        this.param = params;
    }
}

export class TextParagraphCommand extends createCommand {

    public createdData: TextData;
    protected opType = cadOpType.TEXTP;
    protected objType = objDataType.TEXT;
    protected param: textParam;
    protected material: undefined;

    constructor(params: textParam, layerId: string, graphicProcessor: GraphicProcessor) {
        super(layerId, graphicProcessor);
        this.param = params;
    }
}