import { angularPrecision, getUserAngleUnitSufix, linealPrecision, radAngleToUser, userAngleToRad } from "lib/general-settings";
import React, { ChangeEvent, useState } from "react";
import RenderIf from "shared/components/ui/render-if";
import styled from "styled-components";
import CloseIcon from "../icons/close";
import LightIcon from "shared/components/icons/light"
import InputDef from './plane-prop-input';
import ColorSelector from "../color/color-selector";
import cn from "classnames";
import { usePlaneList } from "./hooks/use-plane-list";


const tabs = ["Geometric properties", "Associated grid"];

interface Props { onClose: () => void }

const PlaneList = ({ onClose }: Props) => {

  const [currentTab, setCurrentTab] = useState(0);

  const {
    userPlanes,
    selectedPlane,
    activePlanes,
    createUserPlane,
    duplicateUserPlane,
    deleteUserPlane,
    createPlaneFromThreePoints,
    createPlaneFromVector,
    changeCurrPlane,
    toogleVisibility,
    editCurrPlaneGeom,
    editCurrPlane,
  } = usePlaneList(onClose);

  const inputClassName = cn("w-1/2 mr-1 text-right outline-none bg-transparent");

  return (
    <Wrapper className="text-xs">
      <Header>
        <h1>Coordinate system and auxiliary planes</h1>
        <button onClick={onClose}>
          <CloseIcon className="h-4 w-4" />
        </button>
      </Header>
      <Content>
        <ActionBar>
          <Actions>
            <button onClick={createUserPlane}>Create</button>
            <button onClick={duplicateUserPlane}>Duplicate</button>
            <button onClick={deleteUserPlane}>Delete</button>
          </Actions>
          <Actions>
            <button onClick={createPlaneFromThreePoints}>Three points</button>
            <button onClick={createPlaneFromVector}>Vector</button>
          </Actions>
        </ActionBar>
        <Box>Plane list</Box>
        <Tabs>
          {tabs.map((name, index) => (
            <Tab
              key={index}
              className={currentTab === index ? "selected" : ""}
              onClick={() => setCurrentTab(index)}
            >
              {name}
            </Tab>
          ))}
        </Tabs>
        <PlaneListWrapper>
          {/* <PlaneListDetails>
            <PlaneListTitle>Coordinate system</PlaneListTitle>
            <PlaneListContent>
              <PlaneListItem onClick={() => {
                changeCurrPlane(mainPlane, -1);
              }}>
                Universal system
              </PlaneListItem>
            </PlaneListContent>
          </PlaneListDetails> */}
          {/* <PlaneListDetails> */}
          {/* {/* <PlaneListTitle>Auxiliary planes</PlaneListTitle> */}
          <PlaneListContent className="usersPlanes">
            {userPlanes.map((plane, index) => (
              <div className="flex items-center justify-end space-x-1">
                <PlaneListItem
                  onClick={() => changeCurrPlane(plane, index)}
                >{`Plane ${index + 1}`}</PlaneListItem>
                <LightIcon
                  className={`cursor-pointer w-4 h-4 hover:text-gray-100 ${plane.active ? "text-yellow-200" : "text-gray-400 "}`}
                  onClick={() => toogleVisibility(plane)}
                />
              </div>
            ))}
          </PlaneListContent>
          {/* </PlaneListDetails> */}
        </PlaneListWrapper>
        <PropertiesWrapper>
          {currentTab === 0 ? (
            <Table>
              <thead>
                <tr>
                  <TableHeaderCell>Position</TableHeaderCell>
                  <TableHeaderCell>Rotation</TableHeaderCell>
                </tr>
              </thead>
              <tbody>
                <RenderIf isTrue={!!selectedPlane}>
                  <TableRow>
                    <TableCell>
                      <Property>
                        <PropertyLabel>X Coordinate</PropertyLabel>
                        <InputDef
                          className={inputClassName}
                          defVal={selectedPlane?.position.x}
                          units={"m"}
                          decimals={linealPrecision}
                          onChangeVal={(val: number) => {
                            if (selectedPlane) {
                              selectedPlane.position.x = val;
                              editCurrPlaneGeom();
                            }
                          }}
                        />
                      </Property>
                    </TableCell>
                    <TableCell>
                      <Property>
                        <PropertyLabel>X Axis</PropertyLabel>
                        <InputDef
                          className={inputClassName}
                          defVal={selectedPlane ? radAngleToUser(selectedPlane.rotation.x) : undefined}
                          units={getUserAngleUnitSufix()}
                          decimals={angularPrecision}
                          parseFun={userAngleToRad}
                          onChangeVal={(val: number) => {
                            if (selectedPlane) {
                              selectedPlane.rotation.x = val;
                              editCurrPlaneGeom();
                            }
                          }}
                        />
                      </Property>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Property>
                        <PropertyLabel>Y Coordinate</PropertyLabel>
                        <InputDef
                          className={inputClassName}
                          defVal={selectedPlane?.position.y}
                          units={"m"}
                          decimals={linealPrecision}
                          onChangeVal={(val: number) => {
                            if (selectedPlane) {
                              selectedPlane.position.y = val;
                              editCurrPlaneGeom();
                            }
                          }}
                        />
                      </Property>
                    </TableCell>
                    <TableCell>
                      <Property>
                        <PropertyLabel>Y Axis</PropertyLabel>
                        <InputDef
                          className={inputClassName}
                          defVal={selectedPlane ? radAngleToUser(selectedPlane.rotation.y) : undefined}
                          units={getUserAngleUnitSufix()}
                          decimals={angularPrecision}
                          parseFun={userAngleToRad}
                          onChangeVal={(val: number) => {
                            if (selectedPlane) {
                              selectedPlane.rotation.y = val;
                              editCurrPlaneGeom();
                            }
                          }}
                        />
                      </Property>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Property>
                        <PropertyLabel>Z Coordinate</PropertyLabel>
                        <InputDef
                          className={inputClassName}
                          defVal={selectedPlane?.position.z}
                          units={"m"}
                          decimals={linealPrecision}
                          onChangeVal={(val: number) => {
                            if (selectedPlane) {
                              selectedPlane.position.z = val;
                              editCurrPlaneGeom();
                            }
                          }}
                        />
                      </Property>
                    </TableCell>
                    <TableCell>
                      <Property>
                        <PropertyLabel>Z Axis</PropertyLabel>
                        <InputDef
                          className={inputClassName}
                          defVal={selectedPlane ? radAngleToUser(selectedPlane.rotation.z) : undefined}
                          units={getUserAngleUnitSufix()}
                          decimals={angularPrecision}
                          parseFun={userAngleToRad}
                          onChangeVal={(val: number) => {
                            if (selectedPlane) {
                              selectedPlane.rotation.z = val;
                              editCurrPlaneGeom();
                            }
                          }}
                        />
                      </Property>
                    </TableCell>
                  </TableRow>
                </RenderIf>
              </tbody>
            </Table>
          ) : (<Table>
            <thead>
              <tr>
                <TableHeaderCell>Property</TableHeaderCell>
              </tr>
            </thead>
            <tbody>
              <RenderIf isTrue={!!selectedPlane}>
                <TableRow>
                  <TableCell>
                    <Property>
                      <PropertyLabel>Size</PropertyLabel>
                      <InputDef
                        className={inputClassName}
                        defVal={selectedPlane ? selectedPlane.gridHelperSettings.size : undefined}
                        decimals={linealPrecision}
                        units={"m"}
                        onChangeVal={(val: number) => {
                          if (selectedPlane) {
                            selectedPlane.gridHelperSettings.size = val;
                            editCurrPlane();
                          }
                        }}
                      />
                    </Property>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Property>
                      <PropertyLabel>Division</PropertyLabel>
                      <InputDef
                        className={inputClassName}
                        defVal={selectedPlane ? selectedPlane.gridHelperSettings.division : undefined}
                        decimals={0}
                        units={"m"}
                        onChangeVal={(val: number) => {
                          if (selectedPlane) {
                            selectedPlane.gridHelperSettings.division = val;
                            editCurrPlane();
                          }
                        }}
                      />
                    </Property>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Property>
                      <PropertyLabel>Main lines</PropertyLabel>
                      <ColorSelector
                        defaultValue={selectedPlane ? selectedPlane.gridHelperSettings.colorCenterLine as string : ""}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          if (selectedPlane) {
                            const hexStringColor = e.target.value;
                            selectedPlane.gridHelperSettings.colorCenterLine = hexStringColor;
                            editCurrPlane();
                          }
                        }}
                      />
                    </Property>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Property>
                      <PropertyLabel>Line divisions</PropertyLabel>
                      <ColorSelector
                        defaultValue={selectedPlane ? selectedPlane.gridHelperSettings.colorGrid as string : ""}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          if (selectedPlane) {
                            const hexStringColor = e.target.value;
                            selectedPlane.gridHelperSettings.colorGrid = hexStringColor;
                            editCurrPlane();
                          }
                        }}
                      />
                    </Property>
                  </TableCell>
                </TableRow>
              </RenderIf>
            </tbody>
          </Table>)}
        </PropertiesWrapper>
        <ActionBar>
          <span>{`Active: Universal system`}</span>
          <span>{`Auxiliary (created/active): ${userPlanes.length}/${activePlanes}`}</span>
        </ActionBar>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow: hidden;
  border-radius: 4px;
  width: 600px;
  margin: -32px;
  background-color: var(--color-gray-300);
`;

const Property = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const PropertyLabel = styled.label`
  padding-right: 4px;
  width: 50%;
`;

// const PropertyInput = styled.input`
//   width: 50%;
//   background: none;
//   color: inherit;
//   text-align: right;
//   margin-right: 4px;
//   outline: none;
// `;
const Header = styled.div`
  background-color: var(--color-gray-300);
  border-bottom: 2px solid var(--color-primary);
  padding: 8px;
  display: flex;
  justify-content: space-between;
`;

const Box = styled.div`
  background-color: var(--color-gray-200);
  padding: 8px;
  border-radius: 4px;
  overflow: hidden;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  & > * + * {
    margin-left: 8px;
  }
`;

const ActionBar = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column: 1 / 4;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 36px 30px 150px 30px;
  gap: 4px;
  padding: 8px;
  height: 100%;
`;

// const PlaneListDetails = styled.details`
//   &:first-child {
//     margin-bottom: 4px;
//   }
// `;
const PropertiesWrapper = styled(Box)`
  padding: 0;
  grid-column: 2/4;
`;
const PlaneListWrapper = styled(Box)`
  padding: 0;
`;

// const PlaneListTitle = styled.summary`
//   cursor: pointer;
//   padding: 4px;
// `;

const PlaneListContent = styled.div`
  padding: 4px 0;
  max-height: 100px;
  overflow-y: auto;
`;

const PlaneListItem = styled.button`
  user-select: none;
  display: block;
  width: 100%;
  text-align: left;
  background-color: var(--color-gray-200);
  padding: 4px 24px;
  overflow: hidden;
  &:hover {
    background-color: var(--color-gray-100);
  }
`;

const Tabs = styled(Box)`
  padding: 0;
  display: flex;
  grid-column: 2/4;
`;
const Tab = styled.button`
  padding: 0 4px;
  border-bottom: 2px solid transparent;
  &:hover {
    color: var(--color-primary);
  }
  &.selected {
    color: var(--color-primary);
    border-color: var(--color-primary);
  }
`;

const Table = styled.table`
  width: 100%;
`;
const TableRow = styled.tr`
  &:nth-child(2n + 1) {
    background-color: var(--color-gray-300);
  }
`;
const TableHeaderCell = styled.th`
  text-align: left;
  padding: 4px;
`;
const TableCell = styled.td`
  padding: 4px;
  &:first-of-type {
    border-right: 1px solid var(--color-gray-400);
  }
`;

export default PlaneList;
