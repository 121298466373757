import { ISolidMaterial } from "lib/materials";
import { IPoint } from "lib/math/types";
import { objDataType } from "lib/models/types";
import { ConeData } from "lib/models/solids/cone";
import { coneParam } from "../../geometries/solid/cone";
import { GraphicProcessor } from "../../graphic-processor";
import { cadOpType } from "../../operations/factory";
import { createCommand } from "./create-base";

export class ConeCommand extends createCommand {

  public createdData: ConeData;
  protected opType = cadOpType.CONE;
  protected objType = objDataType.CONE;
  protected param: coneParam;
  protected material: ISolidMaterial;

  constructor(
    upperRadius: number,
    lowerRadius: number,
    height: number,
    basePoint: IPoint,
    rotation: IPoint,
    offset: IPoint,
    scale: IPoint,
    layerId: string,
    graphicProcessor: GraphicProcessor,
    material: ISolidMaterial
  ) {
    super(layerId, graphicProcessor);
    this.param = {
      upperRadius,
      lowerRadius,
      height,
      basePoint,
      rotation,
      offset,
      scale,
    };
    this.material = material;
  }
}