import { setPosBuffer } from "lib/geometries";
import { GraphicProcessor } from "lib/graphic-processor";
import { ILineMaterial, materialCache } from "lib/materials";
import { Line2 } from "three/examples/jsm/lines/Line2";
import { ObjData } from "../objdata";

export abstract class LineBaseData extends ObjData {

  material: ILineMaterial;
  graphicObj: THREE.Line | Line2;

  abstract getBufferGeom(): Float32Array;

  override createGraphicObj() {
    if (this.graphicObj) {
      console.warn("Attention: Line based graphic object already created!!");
      return;
    }
    this.graphicObj = this.createObject(this.definition, this.material) as THREE.Line | Line2;
    this.graphicObj.renderOrder = 5;
  }

  override regenerateObjectFromDefinition(): void {
    if (this.layerObj && this.layerObj.isBulkData) {
      this.layerObj.updateGeometryFromBulkData(this);
    }
    // FIX: Seguimos conservando el graphicObj ya que la selección por rectangulo intersecta con ella
    const coords = this.getBufferGeom();
    setPosBuffer(this.graphicObj, coords);
    this.regenerateDependences();
  }
  override regenerateObjectFromMaterial(graphicProcessor: GraphicProcessor): void {
    if (this.layerObj && this.layerObj.isBulkData) {
      this.layerObj.updateMaterialFromBulkData(this);
    } else {
      this.regenerateMaterial(graphicProcessor);
    }
  }

  private regenerateMaterial(graphicProcessor: GraphicProcessor) {
    const currMaterial = this.graphicObj.material as THREE.Material;
    if (this.material.width !== 1) {
      if (currMaterial.type === 'LineBasicMaterial' || currMaterial.type === 'LineDashedMaterial') {
        // To Line2
        this.updateDifferentMaterial(graphicProcessor);
      } else {
        this.updateSameMaterial();
      }
    } else {
      if (currMaterial.type === 'LineMaterial') {
        // To THREE.Line
        this.updateDifferentMaterial(graphicProcessor);
      } else {
        this.updateSameMaterial();
      }
    }
  }
  private updateDifferentMaterial(graphicProcessor: GraphicProcessor) {
    const model = graphicProcessor.getDataModelManager();
    this.layerObj.removeObjData(this);
    model.mapGraphicObjs.delete(this.graphicObj);
    model.mapGraphicObjsBulkData.delete(this.graphicObj);

    this.graphicObj = this.createObject(this.definition, this.material) as THREE.Line | Line2;
    this.graphicObj.renderOrder = 5;

    this.layerObj.addObjData(this);
    model.mapGraphicObjs.set(this.graphicObj, this);
    model.mapGraphicObjsBulkData.set(this.graphicObj, this.layerObj);
  }
  private updateSameMaterial() {
    const threeMaterial = materialCache.getMaterial(this.material);
    this.graphicObj.material = threeMaterial;
    if (threeMaterial.type === "LineDashedMaterial") {
      this.graphicObj.computeLineDistances();
    }
  }
}
