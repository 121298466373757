import { SlabEditVertexDataCommand } from "lib/commands/structural/slab";
import { lineCreateIPoints, lineMoveVertex } from "lib/geometries/line";
import { IPoint } from "lib/math/types";
import { slabParam } from "lib/models-struc/types/slab";
import { SlabData } from "lib/models/structural/slab";
import { SimpleEdition } from "../edition";
import { cadOpType } from "../factory";
import { settingsOpModes } from "../step-operations";
import { userMessageEvents } from "lib/events/user-messages";
import { intersectsItself } from "lib/math/intersections";
import { isPointsInsidePolygon2DPoints } from "lib/math/polygon";
import { copyIPoint } from '../../math/point';

export class MoveSlabVertexOP extends SimpleEdition {

  public opType = cadOpType.EDITSLAB;

  protected slabData: SlabData;
  protected auxDefinition: slabParam;

  protected indexToEdit: number;
  protected depth: number;

  constructor(data: SlabData, indexToEdit: number, iniPoint: IPoint) {
    super(iniPoint);
    this.indexToEdit = indexToEdit;
    this.slabData = data;
    this.auxDefinition = this.slabData.cloneDefinition();
  }
  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Edit contour point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
    }]);
  }
  public async start() {
    await super.start();
    const planeManager = this.graphicProcessor.getPlaneManager();
    planeManager.activePlane.position = this.slabData.definition.basePoint;
    planeManager.activePlane.rotation = this.slabData.definition.rotation;
    planeManager.activePlane.locked = true;
    this.depth = this.slabData.definition.depth;

    const ptos = this.auxDefinition.ptos2D.map(p => planeManager.activePlane.getAbsolutePoint(p));
    this.auxLine = lineCreateIPoints([...ptos, ptos[0]]);
    this.saveToTempScene(this.auxLine);
  }

  public setLastPoint() {
    const pto = this.currPlane.getRelativePoint(this.lastPoint);
    this.setCountourPoint(pto);
  }
  private setCountourPoint(pto: IPoint) {
    const ptos = this.auxDefinition.ptos2D;
    const oldPto = copyIPoint(ptos[this.indexToEdit]);
    ptos[this.indexToEdit] = pto;
    const holeIntersectItSelf = intersectsItself([...ptos, ptos[0]]);
    if (holeIntersectItSelf) {
      userMessageEvents.dispatchError("Slab countour cannot intersect itself");
      ptos[this.indexToEdit] = oldPto;
      return;
    }

    const holeIsOutside = this.auxDefinition.holes.some(h => (!isPointsInsidePolygon2DPoints(h, ptos)));
    if (holeIsOutside) {
      userMessageEvents.dispatchError("Slab countour has to include all its holes");
      ptos[this.indexToEdit] = oldPto;
      return;
    }
    this.endOperation();
  }

  public moveLastPoint(pto: IPoint) {
    lineMoveVertex(this.auxLine, pto.x, pto.y, pto.z, this.indexToEdit);
    if (this.indexToEdit === 0) lineMoveVertex(this.auxLine, pto.x, pto.y, pto.z);
  }

  public endOperation(): void {
    this.save();
    super.endOperation();
  }
  public save() {
    const command = new SlabEditVertexDataCommand(this.slabData, this.auxDefinition, null, this.graphicProcessor);
    this.graphicProcessor.storeAndExecute(command);
  }
}


