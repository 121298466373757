import * as THREE from "three";
import { getAngleBetweenAzimuts } from "../math/angles";
import { isZeroAng } from "../math/epsilon";
import { copyIPoint, getAzimutPolarPoint } from "../math/point";
import { IPoint } from "../math/types";
import { getCenterMultiObj } from "./centroids";
import { rotateObjs } from "./rotate";
import { moveObjs } from "./translation";

/**
 *
 * @export
 * @param {IPoint} originPosition - Punto de inserción expresado como distancia a los objetos que se usan como base.
 * @param {number} columnNumber - Numero de columnas (eje x)
 * @param {number} columnSeparation - Separación entre columnas
 * @param {number} rowNumber - Número de filas (eje y)
 * @param {number} rowSeparation - Separación entre filas
 * @param {number} levelNumber - Numero de niveles (eje z)
 * @param {number} levelSeparation - Separación entre niveles
 * @param {boolean} alternate - Aternar puntos
 * @returns {IPoint[]}
 */
export function generateRectangularMatrixPoints(
    originPosition: IPoint,
    columnNumber: number, columnSeparation: number,
    rowNumber: number, rowSeparation: number,
    levelNumber: number, levelSeparation: number,
    alternate: boolean): IPoint[] {

    const position: IPoint = copyIPoint(originPosition);
    const newPositions = [];
    let alt = false;
    for (let i: number = 0; i < columnNumber; i++) {
        position.y = originPosition.y;
        for (let j: number = 0; j < rowNumber; j++) {
            if (alternate) {
                if (alt) {
                    position.y += rowSeparation;
                    alt = !alt;
                    continue;
                }
            }
            position.z = originPosition.z;
            for (let k: number = 0; k < levelNumber; k++) {
                newPositions.push(copyIPoint(position));
                // incremento la distancia de la z;
                position.z += levelSeparation;
            }
            position.y += rowSeparation;
            alt = !alt;
        }
        position.x += columnSeparation;
    }
    return newPositions;
}

/**
 *
 * @export
 * @param {IPoint} origin - Punto de inserción expresado como distancia a los objetos que se usan como base.
 * @param {number} azimutStart - Dirección de origen
 * @param {number} azimutEnd - Dirección final
 * @param {number} radius - Radio
 * @param {boolean} isRotated - Objetos rotados según posición
 * @param {number} columnNumber - numero de elementos en un "aspas"
 * @param {number} columnSeparation - Separación entre elementos de un "aspa"
 * @param {number} elemNumber - número de "aspas"
 * @param {number} levelNumber - número de pisos (en Z)
 * @param {number} levelSeparation - separación entre pisos
 * @param {boolean} alternate - alternar entre alementos
 * @returns {[IPoint[], number[]]}
 */
export function generatePolarMatrixPoints(
    origin: IPoint,
    azimutStart: number, azimutEnd: number,
    radius: number, isRotated: boolean,
    columnNumber: number, columnSeparation: number,
    elemNumber: number,
    levelNumber: number, levelSeparation: number, alternate: boolean): [IPoint[], number[]] {

    const newPositions = [];
    const newRotations = [];

    const angle = getAngleBetweenAzimuts(azimutStart, azimutEnd);
    let angleStep: number = (angle) / (elemNumber - 1);
    if (isZeroAng(angle) || isZeroAng(angle - 2 * Math.PI)) {
        angleStep = 2 * Math.PI / elemNumber;
    }
    let currentAngle: number = azimutStart;
    let currentRadius: number = radius;

    let position = copyIPoint(origin);
    let alt = false;
    for (let i: number = 0; i < elemNumber; i++) {
        const currentAngleStep: number = angleStep * i;
        currentAngle = currentAngleStep + azimutStart;
        currentRadius = radius;
        for (let j: number = 0; j < columnNumber; j++) {
            if (alternate) {
                if (alt) {
                    currentRadius += columnSeparation;
                    alt = !alt;
                    continue;
                }
            }
            position = getAzimutPolarPoint(origin, currentAngle, currentRadius);
            for (let k: number = 0; k < levelNumber; k++) {
                newPositions.push(copyIPoint(position));
                if (isRotated) newRotations.push(-currentAngle);
                // incremento la distancia de la z;
                position.z += levelSeparation;
            }
            currentRadius += columnSeparation;
            alt = !alt;
        }
    }
    return [newPositions, newRotations];
}

let theSeed: number = 14214.699367523193;
export function getRandomSeed(): number {
    return theSeed;
}
export function setRandomSeed(seed: number): void {
    theSeed = seed;
}
// Generador de numeros aleatorios con semilla, para poder repetir experimentos pseudo-aleatorios.
export function randomSeed(): number {
    theSeed = (theSeed * 9301 + 49297) % 233280;
    return theSeed / 233280.0;
}
export function applyNoise(threeObjs: THREE.Object3D[], noiseX: number, noiseY: number, noiseZ: number, rotation?: boolean, toProp?: boolean): void {
    const x: number = randomSeed() * noiseX * 2 - noiseX;
    // lo multiplico por dos y le resto la distancia para que me pueda dar valores negativos.
    const y: number = randomSeed() * noiseY * 2 - noiseY;
    const z: number = randomSeed() * noiseZ * 2 - noiseZ;

    if (!toProp) {
        // Aplico al bufferGeometry
        moveObjs(threeObjs, { x, y, z } as IPoint);
        if (rotation === true) {
            const angle: number = randomSeed() * 2 * Math.PI;
            rotateObjs(threeObjs, 0, 0, angle, getCenterMultiObj(threeObjs));
        }
    } else {
        // Aplico al position y rotation
        threeObjs.forEach((o) => { o.position.add(new THREE.Vector3(x, y, z)); });
        if (rotation === true) {
            threeObjs.forEach((o) => {
                // if (isSolid3D(o)) {
                //     o.rotation.x = randomSeed() * 2 * Math.PI;
                //     o.rotation.y = randomSeed() * 2 * Math.PI;
                // }
                o.rotation.z = randomSeed() * 2 * Math.PI;
            });
        }
        threeObjs.forEach((o) => o.updateMatrix());
    }
}

export function applyNoiseToPoints(points: IPoint[], noiseX: number, noiseY: number, noiseZ: number, rotation?: boolean): void {
    const x: number = randomSeed() * noiseX * 2 - noiseX;
    // lo multiplico por dos y le resto la distancia para que me pueda dar valores negativos.
    const y: number = randomSeed() * noiseY * 2 - noiseY;
    const z: number = randomSeed() * noiseZ * 2 - noiseZ;

    points.forEach((p) => { 
        p.x += x;
        p.y += y;        
        p.z += z;        
    });
}