import { BlockManager, blockParam, blockRefParam } from "lib/blocks";
import { BlockData } from "lib/models/block";
import { IObjData } from "lib/models/objdata";
import { objDataType } from "lib/models/types";
import { GraphicProcessor } from "../../graphic-processor";
import { cadOpType } from "../../operations/factory";
import { createCommand } from "./create-base";

export class BlockCommand extends createCommand {

  public createdData: BlockData;
  protected opType = cadOpType.BLOCK;
  protected objType = objDataType.BLOCK;

  protected param: blockParam;
  protected material: undefined;

  private originObjs: IObjData[];

  constructor(params: blockParam, originObjsId: IObjData[], layerId: string, graphicProc: GraphicProcessor) {
    super(layerId, graphicProc);
    this.param = params;
    this.originObjs = originObjsId;
  }

  public async execute() {
    this.removeOriginObjFromScene();
    if (this.createdData === undefined) {
      this.createBlockAndAddToScene();
    } else {
      this.graphicProcessor.addToLayer(this.createdData, this.createdData.layerId);
    }
    const modelManager = this.graphicProcessor.getDataModelManager();
    modelManager.dispatchAddedObjs([this.createdData]);
    const lyrManager = this.graphicProcessor.getLayerManager();
    lyrManager.layerObserver.dispatchLoadLayers();
  }

  public unExecute(): void | Promise<void> {
    if (this.createdData) {
      const modelManager = this.graphicProcessor.getDataModelManager();
      this.graphicProcessor.removeFromLayer(this.createdData);
      modelManager.dispatchDeletedObjs([this.createdData]);
      // Restore original objects
      for (const data of this.originObjs) {
        this.graphicProcessor.addToLayer(data, data.layerId);
      }
      modelManager.dispatchAddedObjs(this.originObjs);
      const lyrManager = this.graphicProcessor.getLayerManager();
      lyrManager.layerObserver.dispatchLoadLayers();
    }
  }

  public delete(): void {
    super.delete();
    this.originObjs.length = 0;
  }

  private createBlockAndAddToScene() {
    const id = BlockManager.createBlock(this.param);
    const blockRef: blockRefParam = { blockId: id, position: this.param.basePoint, rotation: { x: 0, y: 0, z: 0 }, scale: { x: 1, y: 1, z: 1 } };
    const data = new BlockData(blockRef);
    data.createGraphicObj();
    this.graphicProcessor.addToLayer(data, this.layerId);
    this.createdData = data;
  }

  private removeOriginObjFromScene() {
    for (const data of this.originObjs) {
      this.graphicProcessor.removeFromLayer(data);
    }
    const dtMdlMngr = this.graphicProcessor.getDataModelManager();
    dtMdlMngr.dispatchDeletedObjs(this.originObjs);
  }
}

export class BlockRefCommand extends createCommand {

  public createdData: BlockData;
  protected opType = cadOpType.BLOCKREF;
  protected objType = objDataType.BLOCKREF;
  protected param: blockRefParam;
  protected material: undefined;

  constructor(params: blockRefParam, layerId: string, graphicProc: GraphicProcessor) {
    super(layerId, graphicProc);
    this.param = params;
  }
}