import { PolygonData } from "lib/models/primitives/polygon";
import { GraphicProcessor } from "../../graphic-processor";
import { intersectObjects } from "../../math/intersections";
import { LineData } from "../../models/primitives/line";
import { cadOpType } from "../../operations/factory";
import { CadCommand } from "../base";

export class TrimLinesCommand extends CadCommand {
  protected opType = cadOpType.TRIMLINES;

  private leftResults: boolean;
  private masterLine: LineData;
  private trimLines: (LineData | PolygonData)[];
  private createdObjs: LineData[] = [];

  constructor(leftResults: boolean, masterLine: LineData, trimLines: (LineData | PolygonData)[], graphicProcessor: GraphicProcessor) {
    super(graphicProcessor);
    this.leftResults = leftResults;
    this.masterLine = masterLine;
    this.trimLines = trimLines.slice();
  }

  public async execute() {
    const modelManager = this.graphicProcessor.getDataModelManager();
    if (this.createdObjs.length > 0) {
      // Add to scene of TrimLines generated previously
      for (const data of this.createdObjs) {
        this.graphicProcessor.addToLayer(data, data.layerId);
      }
      modelManager.dispatchAddedObjs(this.createdObjs);

      // Remove To Scene originals TrimLines
      for (const line of this.trimLines) {
        this.graphicProcessor.removeFromLayer(line);
      }
      modelManager.dispatchDeletedObjs(this.trimLines);

    } else {
      // Calculate Trim lines
      const trimLines = [];
      const result = intersectObjects(this.masterLine, this.trimLines);
      for (let i = 0; i < this.trimLines.length; i++) {
        const lineRes = result[i];
        if (lineRes) {
          const res = this.leftResults ? lineRes.left : lineRes.right;
          if (res.length > 0) {
            for (const p of res) {
              const newData = new LineData({ points: p, isClosed: false, arcs: [] });
              newData.createGraphicObj();
              this.graphicProcessor.addToLayer(newData, this.masterLine.layerId);
              this.createdObjs.push(newData);
            }
            this.graphicProcessor.removeFromLayer(this.trimLines[i]);
            trimLines.push(this.trimLines[i]);
          }
        }
      }
      modelManager.dispatchAddedObjs(this.createdObjs);
      modelManager.dispatchDeletedObjs(trimLines);
    }
    const lyrManager = this.graphicProcessor.getLayerManager();
    lyrManager.layerObserver.dispatchLoadLayers();
  }

  public async unExecute() {
    const modelManager = this.graphicProcessor.getDataModelManager();
    // Remove from scene of TrimLines generated
    for (const data of this.createdObjs) {
      this.graphicProcessor.removeFromLayer(data);
    }
    modelManager.dispatchDeletedObjs(this.createdObjs);

    // Add To Scene originals TrimLines
    for (const line of this.trimLines) {
      this.graphicProcessor.addToLayer(line, line.layerId);
    }
    modelManager.dispatchAddedObjs(this.trimLines);

    const lyrManager = this.graphicProcessor.getLayerManager();
    lyrManager.layerObserver.dispatchLoadLayers();
  }

  public delete() {
    this.trimLines.length = 0;
    this.createdObjs.length = 0;
  }
}