import Page from "../components/ui/page";
import ProjectForm from "../components/projects/project-form";
import { useEditProject } from "../components/projects/hooks/use-edit-project";
import { PrimaryButton } from "shared/components/ui/button";
import { SecondaryLink } from "shared/components/ui/link";

const EditProject = () => {
  const {
    handleSubmit,
    register,
    storeys,
    project,
    setLocation,
    status,
    updateStorey,
    setFootingLevel,
    sortStoreys,
  } = useEditProject();
  return (
    <Page title="Edit project">
      {project && (
        <ProjectForm
          onChangeLocation={setLocation}
          onChangeFootingLevel={setFootingLevel}
          onSubmit={handleSubmit}
          onChangeStorey={updateStorey}
          onDropStorey={sortStoreys}
          project={project}
          register={register}
          storeys={storeys}
          onBulkDXFUpload={() => {}}
        >
          <div className="flex items-center justify-end space-x-2">
            <SecondaryLink
              to={`/lite/projects/${project?.id}`}
              variant="tertiary"
            >
              Cancel
            </SecondaryLink>
            <PrimaryButton type="submit" loading={status === "loading"}>
              Update project
            </PrimaryButton>
          </div>
        </ProjectForm>
      )}
    </Page>
  );
};

export default EditProject;
