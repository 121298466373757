
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import PropertiesInput from "modules/cad/components/properties/component-input";
import PropertiesSelect from "modules/cad/components/properties/component-select";
import ChevronDownIcon from "shared/components/icons/chevron-down";
import ChevronUpIcon from "shared/components/icons/chevron-up";
import { useStoreys } from "./hook/use-storey";
import { useMainGraphicContext } from "modules/cad/components/viewport/context";
import { userMessageEvents } from "lib/events/user-messages";

const StoreyNavigation = () => {

  const graphicProcessor = useMainGraphicContext();
  const strModelManager = graphicProcessor.getStructuralModelManager();
  const {
    currStorey,
    storeyList,
    go2Storey,
    go2nextStorey,
    go2prevStorey,
    changeStoreyLevel,
  } = useStoreys(strModelManager);

  const [storeyLevel, setStoreyLevel] = useState<number>(currStorey.level);
  useEffect(() => { setStoreyLevel(currStorey.level) }, [currStorey])

  const handleKeyDown = useCallback((event) => {
    event.preventDefault();
    try {
      changeStoreyLevel(storeyLevel);
    } catch (error: any) {
      userMessageEvents.dispatchError(error.message);
      setStoreyLevel(currStorey.level);
    }
  }, [changeStoreyLevel, currStorey.level, storeyLevel]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const v = event.target.value;
    const newVal = parseFloat(v);
    setStoreyLevel(newVal);
  };

  return (
    <div className="p-2 overflow-hidden border-b border-gray-600">
      <div className="flex justify-between items-center pb-2">
        <ChevronDownIcon
          className="h-4 w-8 pr-2 cursor-pointer hover:text-gray-100"
          onClick={go2prevStorey}
        />
        <PropertiesSelect
          srOnly={true}
          value={currStorey.id}
          onChange={go2Storey}
        >
          {storeyList.map((val, i) => (
            <option key={val.id} value={val.id}> {`storey: ${val.name}`} </option>
          ))}
        </PropertiesSelect>
        <ChevronUpIcon
          className="h-4 w-8 pl-2 cursor-pointer hover:text-gray-100"
          onClick={go2nextStorey}
        />
      </div>
      <form onSubmit={handleKeyDown} className="flex justify-between items-center">
        <PropertiesInput
          value={storeyLevel ?? 0}
          label={"Storey level:"}
          adornment={<span>{"m"}</span>}
          onChange={handleChange}
          onBlur={handleKeyDown}
        />
      </form>
    </div >
  );
};

export default StoreyNavigation;