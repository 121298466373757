import { useState } from "react";
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import queryClient from 'shared/utils/query-client';
import { useAuth } from 'shared/components/auth/context';
import { projectListResponse } from 'modules/struc/components/projects/hooks/use-projects';
import { Project } from 'lib/apis/struc-project/api';
import { StrucStorey } from "modules/struc/models/project";
import { userMessageEvents } from 'lib/events/user-messages';
import { fetchPostCreateProject } from "lib/models-struc/infrastructure/create-project";

type Status = 'idle' | 'loading' | 'success' | 'error'

export function useCreateProject() {

  const { user } = useAuth();
  const history = useHistory();
  const [status, setStatus] = useState<Status>('idle');

  const mutation = useMutation((variables: { project: Project, userId: string, storeys: StrucStorey[] }) => {
    return fetchPostCreateProject(variables.project, variables.userId, variables.storeys);
  }, {
    onSuccess: (data) => {
      const query = queryClient.getQueriesData<projectListResponse[]>(['GET_STRUC_PROJECTS']);
      const projData: projectListResponse = { projectData: data, snapShots: null };
      queryClient.setQueryData(["GET_STRUC_PROJECTS"], [projData, ...query[0][1]]);
    }
  })

  const addProject = async (project: Project, storeys: StrucStorey[]) => {
    if (storeys === undefined || storeys.length === 0) {
      userMessageEvents.dispatchError('No storeys defined.');
      setStatus('error');
    } else {
      try {
        setStatus('loading');

        const userId = user!.id;
        const data = await mutation.mutateAsync({ project, userId, storeys });

        userMessageEvents.dispatchSucces('Project created successfully.');
        setStatus('success');
        console.info(`[ADD_PROJECT] Project created successfully. ${data.Id}`);

      } catch (er: any) {
        console.error(er);
        userMessageEvents.dispatchError('Error creating project.');
        setStatus('error');

      } finally {
        history.push('/struc/projects');
      }
    }
  };

  return {
    addProject,
    status
  };
}
