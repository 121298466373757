import { ISolidMaterial } from "lib/materials";
import { pileCapParam } from "lib/models-struc/types/pile-cap";
import { footerParam, stripfootingParam } from "lib/models-struc/types/footer";
import { loadParam } from "lib/models-struc/types/load";
import { slabParam } from "lib/models-struc/types/slab";
import { wallParam } from "lib/models-struc/types/wall";
import { columnParam } from "lib/models-struc/types/column";
import { beamParam } from "lib/models-struc/types/beam";
import { BeamData } from "../structural/beam";
import { ColumnData } from "../structural/column";
import { FooterData } from "../structural/footer";
import { LoadStructuralData } from "../structural/load";
import { PileCapData } from "../structural/pile-cap";
import { SlabData } from "../structural/slab";
import { WallData } from "../structural/wall";
import { ObjDataCreator } from "./datamodel-creator";
import { StripFootingData } from "../structural/stripfooter";


export class ColumnCreator extends ObjDataCreator {
  protected createObjDataMethod(definition: columnParam, material?: ISolidMaterial) {
    return new ColumnData(definition, material);
  }
  protected createGraphicObjMethod(definition: columnParam, material: ISolidMaterial) {
    return ColumnData.createObj(definition, material);
  }
}
export class SlabCreator extends ObjDataCreator {
  protected createObjDataMethod(definition: slabParam, material?: ISolidMaterial) {
    return new SlabData(definition, material);
  }
  protected createGraphicObjMethod(definition: slabParam, material: ISolidMaterial) {
    return SlabData.createObj(definition, material);
  }
}
export class BeamCreator extends ObjDataCreator {
  protected createObjDataMethod(definition: beamParam, material?: ISolidMaterial) {
    return new BeamData(definition, material);
  }
  protected createGraphicObjMethod(definition: beamParam, material: ISolidMaterial) {
    return BeamData.createObj(definition, material);
  }
}
export class WallCreator extends ObjDataCreator {
  protected createObjDataMethod(definition: wallParam, material?: ISolidMaterial) {
    return new WallData(definition, material);
  }
  protected createGraphicObjMethod(definition: wallParam, material: ISolidMaterial) {
    return WallData.createObj(definition, material);
  }
}
export class FooterCreator extends ObjDataCreator {
  protected createObjDataMethod(definition: footerParam, material?: ISolidMaterial) {
    return new FooterData(definition, material);
  }
  protected createGraphicObjMethod(definition: footerParam, material: ISolidMaterial) {
    return FooterData.createObj(definition, material);
  }
}
export class StripFooterCreator extends ObjDataCreator {
  protected createObjDataMethod(definition: stripfootingParam, material?: ISolidMaterial) {
    return new StripFootingData(definition, material);
  }
  protected createGraphicObjMethod(definition: stripfootingParam, material: ISolidMaterial) {
    return StripFootingData.createObj(definition, material);
  }
}
export class PileCapCreator extends ObjDataCreator {
  protected createObjDataMethod(definition: pileCapParam, material?: ISolidMaterial) {
    return new PileCapData(definition, material);
  }
  protected createGraphicObjMethod(definition: pileCapParam, material: ISolidMaterial) {
    return PileCapData.createObj(definition, material);
  }
}

// *****************************************************************************************************

export class LoadCreator extends ObjDataCreator {
  protected createObjDataMethod(definition: loadParam) {
    return new LoadStructuralData(definition);
  }
  protected createGraphicObjMethod(definition: loadParam) {
    return LoadStructuralData.createObj(definition);
  }
}
