import * as THREE from "three";
import { IPoint } from "../math/types";
import { IObjData } from "../models/objdata";
import { getBoundingBox } from "./bounding-box";

export function getCenterObj(threeObj: THREE.Object3D): IPoint {
  const center: THREE.Vector3 = new THREE.Vector3();
  let boundingBox = getBoundingBox(threeObj);
  if (!boundingBox) {
    (threeObj as THREE.Line).geometry.computeBoundingBox();
    boundingBox = getBoundingBox(threeObj);
  }
  if (boundingBox) {
    center.set(
      0.5 * (boundingBox.max.x + boundingBox.min.x),
      0.5 * (boundingBox.max.y + boundingBox.min.y),
      0.5 * (boundingBox.max.z + boundingBox.min.z)
    );
  }
  return { x: center.x, y: center.y, z: center.z };
}

export function getCenterObjData(objData: IObjData): IPoint {
  let center: IPoint = { x: 0, y: 0, z: 0 };
  let boundingBox = objData.getBoundingBox();
  if (boundingBox) {
    center = {
      x: 0.5 * (boundingBox.max.x + boundingBox.min.x),
      y: 0.5 * (boundingBox.max.y + boundingBox.min.y),
      z: 0.5 * (boundingBox.max.z + boundingBox.min.z),
    };
  }
  return { x: center.x, y: center.y, z: center.z };
}

export function getCenterMinZObjData(objData: IObjData): IPoint {
  let center: IPoint = { x: 0, y: 0, z: 0 };
  let boundingBox = objData.getBoundingBox();
  if (boundingBox) {
    center = {
      x: 0.5 * (boundingBox.max.x + boundingBox.min.x),
      y: 0.5 * (boundingBox.max.y + boundingBox.min.y),
      z: boundingBox.min.z,
    };
  }
  return { x: center.x, y: center.y, z: center.z };
}

/**
 * Calcula y devuelve el centro de multiples objetos.
 *
 * @export
 * @param {Array<THREE.Object3D>} threeObjs
 * @returns {IPoint}
 */
export function getCenterMultiObj(threeObjs: THREE.Object3D[]): IPoint {
  if (threeObjs.length === 1) {
    return getCenterObj(threeObjs[0]);
  }

  const center: IPoint = { x: 0, y: 0, z: 0 };
  const max: IPoint = { x: -Infinity, y: -Infinity, z: -Infinity };
  const min: IPoint = { x: Infinity, y: Infinity, z: Infinity };
  // obtenemos los extremos de la caja que engloba a los objetos.
  for (let i: number = 0, l: number = threeObjs.length; i < l; i++) {
    const threeObj: THREE.Object3D = threeObjs[i];
    const boundingBox = getBoundingBox(threeObj);
    if (boundingBox) {
      if (boundingBox.min.x < min.x) {
        min.x = boundingBox.min.x;
      }
      if (boundingBox.min.y < min.y) {
        min.y = boundingBox.min.y;
      }
      if (boundingBox.min.z < min.z) {
        min.z = boundingBox.min.z;
      }

      if (boundingBox.max.x > max.x) {
        max.x = boundingBox.max.x;
      }
      if (boundingBox.max.y > max.y) {
        max.y = boundingBox.max.y;
      }
      if (boundingBox.max.z > max.z) {
        max.z = boundingBox.max.z;
      }
    }
  }

  center.x = min.x + 0.5 * (max.x - min.x);
  center.y = min.y + 0.5 * (max.y - min.y);
  center.z = min.z + 0.5 * (max.z - min.z);

  return center;
}

function getMinMaxBoundingBoxObjData(objsData: IObjData[]): { min: IPoint, max: IPoint } {
  const max: IPoint = { x: -Infinity, y: -Infinity, z: -Infinity };
  const min: IPoint = { x: Infinity, y: Infinity, z: Infinity };
  // obtenemos los extremos de la caja que engloba a los objetos.
  for (let i: number = 0, l: number = objsData.length; i < l; i++) {
    const objData = objsData[i];
    const boundingBox = objData.getBoundingBox();
    if (boundingBox) {
      if (boundingBox.min.x < min.x) {
        min.x = boundingBox.min.x;
      }
      if (boundingBox.min.y < min.y) {
        min.y = boundingBox.min.y;
      }
      if (boundingBox.min.z < min.z) {
        min.z = boundingBox.min.z;
      }

      if (boundingBox.max.x > max.x) {
        max.x = boundingBox.max.x;
      }
      if (boundingBox.max.y > max.y) {
        max.y = boundingBox.max.y;
      }
      if (boundingBox.max.z > max.z) {
        max.z = boundingBox.max.z;
      }
    }
  }
  return { min, max };
}

/**
* Calcula y devuelve el centro de multiples objetos.
*
* @export
* @param {Array<IObjData>} threeObjs
* @returns {IPoint}
*/
export function getCenterMultiObjData(objsData: IObjData[]): IPoint {
  if (objsData.length === 1) {
    return getCenterObjData(objsData[0]);
  }

  const center: IPoint = { x: 0, y: 0, z: 0 };
  const { min, max } = getMinMaxBoundingBoxObjData(objsData);

  center.x = min.x + 0.5 * (max.x - min.x);
  center.y = min.y + 0.5 * (max.y - min.y);
  center.z = min.z + 0.5 * (max.z - min.z);

  return center;
}

/**
 * Calcula y devuelve el centro de multiples objetos.
 *
 * @export
 * @param {Array<IObjData>} threeObjs
 * @returns {IPoint}
 */
export function getCenterMinZMultiObjData(objsData: IObjData[]): IPoint {
  if (objsData.length === 1) {
    return getCenterMinZObjData(objsData[0]);
  }

  const center: IPoint = { x: 0, y: 0, z: 0 };
  const { min, max } = getMinMaxBoundingBoxObjData(objsData);

  center.x = min.x + 0.5 * (max.x - min.x);
  center.y = min.y + 0.5 * (max.y - min.y);
  center.z = min.z;

  return center;
}