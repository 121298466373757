import * as THREE from "three";
import { RegionData } from "lib/models/solids/region";
import { cadOpType } from "lib/operations/factory";
import { getAbsolutePoint } from "lib/coordinates/plane";
import { SelectionManager } from "../selection-manager";
import { ObjDataSelector, markBulkTypes } from "./selector-data";


export class RegionDataSelector extends ObjDataSelector<RegionData> {

  public override buildSelectMarks() {
    const { ptos2D, basePoint, rotation } = this.data.definition;
    const puntos = ptos2D.map(p => getAbsolutePoint(p, basePoint, rotation));
    const bulkData: markBulkTypes = { vertex: puntos };
    return bulkData;
  }

  public override launchMarkOP(selectionManager: SelectionManager, mark: THREE.Object3D, vertexIndex: number) {
    if (selectionManager.vertexSelector.pointObj === mark) {
      return {
        type: cadOpType.EDITREGION,
        graphicProcessor: this.graphicProc,
        args: [this.data, vertexIndex, this.data.definition.ptos2D[vertexIndex]],
      };
    }
  }
}
