type Event =
  | "LAYERS"
  | "VIEWPORTS"
  | "OPERATIONS"
  | "UNDO_REDO"
  | "MOUSE_POSITION"
  | "SELECT"
  | "SNAP"
  | "STEPS"
  | "EDITOBJ"
  | "MATRIX"
  | "CACHE"
  | "STOREY"
  | "USER_MESSAGE";

interface Action {
  type: any;
  payload: any;
}

export class EventBus {
  public static enableDispatch = true;
  // WeakMap to save callbacks references. Usefull to remove events
  private referenceEvents = new WeakMap();

  subscribe(event: Event, outCallback: (action: Action) => void) {
    const innerCallback = (e: CustomEventInit<Action>) => {
      outCallback(e.detail as Action);
    };
    this.referenceEvents.set(outCallback, innerCallback);
    document.addEventListener(event, innerCallback);
  }

  unsubscribe(event: Event, outCallback: any) {
    const innerCallback = this.referenceEvents.get(outCallback);
    document.removeEventListener(event, innerCallback);
    this.referenceEvents.delete(outCallback);
  }

  dispatch(event: Event, action: Action) {
    if (EventBus.enableDispatch) {
      const ev = new CustomEvent(event, { detail: action });
      document.dispatchEvent(ev);
    }
  }
}
export const eventBus = new EventBus();


/** Manager to handler events subscription 
 *
 * @export
 * @class ObserverManager
 * @template T - Action events
 */
export class ObserverManager<T> {

  private listeners = new Set<(action: T) => void>();

  public subscribe(listener: (action: T) => void) {
    this.listeners.add(listener)
  }

  public unsubscribe(listener: (action: T) => void) {
    this.listeners.delete(listener)
  }

  public dispatch(action: T) {
    if (EventBus.enableDispatch) {
      for (const l of this.listeners) {
        l(action);
      }
    }
  }

  public cleanSubscriptions() {
    this.listeners.clear();
  }

}