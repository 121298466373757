import { solidParam } from "lib/models/solids/solid";
import * as THREE from "three";
import { setPosRotEsc } from "..";
import { getAuxMeshBasicMaterial } from "../../materials";
import { IPoint } from "../../math/types";

export interface rprismParam extends solidParam {
    sideX: number,
    sideY: number,
    sideZ: number,
}

export function createBufferRPrism(sideX: number, sideY: number, sideZ: number) {
    // const geom = new THREE.BoxGeometry(sideX, sideY, sideZ);
    // return new THREE.BufferGeometry().fromGeometry(geom);
    return new THREE.BoxBufferGeometry(sideX, sideY, sideZ);
}

export function createRPrism(sideX: number, sideY: number, sideZ: number, material?: THREE.MeshPhongMaterial, basePoint?: IPoint, rotation?: IPoint, offset?: IPoint, scale?: IPoint) {
    const geom = createBufferRPrism(sideX, sideY, sideZ);
    const mat = material ?? getAuxMeshBasicMaterial();
    const threeObj = new THREE.Mesh(geom, mat);
    if (basePoint && offset && rotation && scale) {
        setPosRotEsc(threeObj, basePoint, offset, rotation, scale);
    }
    return threeObj;
}
