import ChevronDownIcon from "shared/components/icons/chevron-down";
import RedoIcon from "shared/components/icons/redo";
import UndoIcon from "shared/components/icons/undo";
import Menu from "shared/components/ui/menu";
import RenderIf from "shared/components/ui/render-if";
import useUndoRedo from "./hooks/use-undo-redo";
import { GraphicProcessor } from '../../../../lib/graphic-processor';

const UndoRedoMenu = ({ graphicProc }: { graphicProc: GraphicProcessor }) => {

  const { undoCommands, redoCommands, undo, redo } = useUndoRedo(graphicProc);
  return (
    <>
      <div className="flex items-center space-x-1">
        <button
          className="focus:outline-none hover:text-gray-100 disabled:text-gray-500"
          disabled={!undoCommands.length}
          onClick={() => undo(0)}
        >
          <UndoIcon className="h-5 w-5" />
        </button>
        <Menu>
          <Menu.Button disabled={!undoCommands.length}>
            <ChevronDownIcon
              className={`h-4 w-4 ${!undoCommands.length ? "text-gray-500" : "text-current"
                }`}
            />
          </Menu.Button>
          <RenderIf isTrue={!!undoCommands.length}>
            <Menu.List>
              {undoCommands.map((cmd, index) => (
                <Menu.Item key={index} onSelect={() => undo(index)}>
                  <div className="">{cmd.commandName || "Unknown"}</div>
                </Menu.Item>
              ))}
            </Menu.List>
          </RenderIf>
        </Menu>
      </div>
      <div className="flex items-center px-1 space-x-1">
        <Menu>
          <Menu.Button disabled={!redoCommands.length}>
            <ChevronDownIcon
              className={`h-4 w-4 ${!redoCommands.length ? "text-gray-500" : "text-current"
                }`}
            />
          </Menu.Button>
          <RenderIf isTrue={!!redoCommands.length}>
            <Menu.List>
              {redoCommands.map((cmd, index) => (
                <Menu.Item key={index} onSelect={() => redo(index)}>
                  <div className="">{cmd.commandName || "Unknown"}</div>
                </Menu.Item>
              ))}
            </Menu.List>
          </RenderIf>
        </Menu>
        <button
          className="focus:outline-none hover:text-gray-100 disabled:text-gray-500"
          onClick={() => redo(0)}
          disabled={!redoCommands.length}
        >
          <RedoIcon className="h-5 w-5" />
        </button>
      </div>
    </>
  );
};

export default UndoRedoMenu;
