import { BlockManager } from "lib/blocks";
import { GraphicProcessor } from "lib/graphic-processor";
import { ICADModelPersistence } from "lib/input-output/database/loader";

export class ModelDataExporter {

  saveProjectCAD(graphicProcessor: GraphicProcessor): ICADModelPersistence {
    console.info("[LOADER] Save model to JSON");
    const t0 = performance.now();

    // Build data to persist as json
    const modelManager = graphicProcessor.getDataModelManager();
    const layerData = modelManager.layerManager.exportLayerModel();
    const blockData = BlockManager.getBlocksDefinition();
    const objData = modelManager.exportDataModel();

    const t1 = performance.now();
    console.info("[LOADER] Model saved " + (t1 - t0).toFixed(3) + " milliseconds.");
    graphicProcessor.getDataModelManager().getModelInfo();
    return { layerData, objData, blockData };
  }
}
