import { Storage } from "@aws-amplify/storage";

interface UploadResult {
  key: string;
}
const upload = async (
  filepath: string,
  file: File | Blob | string,
  config: Record<string, any> = {}
): Promise<string> => {
  const result = await Storage.put(filepath, file, config);
  return (result as UploadResult).key;
};

const get = async (filepath: string): Promise<string> => {
  const dxf_url = await Storage.get(filepath);
  return dxf_url as string;
};

const list = async (filepath: string): Promise<any[]> => {
  return await Storage.list(filepath);
};

export const storageService = {
  get,
  list,
  upload,
};
