import { GraphicProcessor } from "lib/graphic-processor";
import { copyIPoint, removeContigousEqualPoints } from "lib/math/point";
import { IPoint } from "lib/math/types";
import { objDataType } from "lib/models/types";
import { storeyMaterialType } from "lib/models-struc/types/struc-base";
import { slabParam } from "lib/models-struc/types/slab";
import { cadOpType } from "lib/operations/factory";
import { createStructuralElementCommand } from "./struc-simple";
import { SlabData } from "lib/models/structural/slab";
import { EditBaseStructCommand } from "./struc-edit-base";
import { loadHypothesisList } from "lib/models-struc/hypothesis/hypothesis";
import { hypothesisManager } from "lib/models-struc/hypothesis/hypothesismodel-manager";
import { Storey } from "lib/models-struc/struc-storey";
import { loadType } from "lib/models-struc/types/load";
import { LoadCommand } from "./load";

export class SlabCommand extends createStructuralElementCommand {

  protected opType = cadOpType.SLAB;
  protected elemType: objDataType.SLAB = objDataType.SLAB;
  protected structuralParam: slabParam[] = [];
  public createdObjs: SlabData[] = [];

  constructor(
    materialType: storeyMaterialType,
    shellCrossSectionId: string,
    waffleShelCrossSectionId: string | undefined,
    depth: number,
    points2D: IPoint[],
    holes: IPoint[][],
    basePoint: IPoint,
    rotation: IPoint,
    offset: IPoint,
    scale: IPoint,
    graphicProc: GraphicProcessor,
  ) {
    const strucMng = graphicProc.getStructuralModelManager();
    const storeyId = strucMng.currBuilding.getStoreyFromLevel(basePoint.z).id;
    const layerId = strucMng.getLayerByStoreyIdAndStructuralType(storeyId, objDataType.SLAB).id;
    super(storeyId, layerId, graphicProc);

    const name = strucMng.currBuilding.getNextSlabName();
    const cleanPoints = removeContigousEqualPoints(points2D.map(copyIPoint));
    const param: slabParam = {
      storeyId,
      name,
      lnkObjIds: [],
      materialType,
      shellCrossSectionId: shellCrossSectionId,

      deadLoadId: "",
      liveLoadId: "",

      ptos2D: cleanPoints,
      holes: holes.length ? holes?.map(h => h?.map(copyIPoint)) : [],
      depth,
      basePoint: copyIPoint(basePoint),
      rotation: copyIPoint(rotation),
      offset: copyIPoint(offset),
      scale: copyIPoint(scale),
    }
    if (waffleShelCrossSectionId) param.waffleShelCrossSectionId = waffleShelCrossSectionId;
    this.structuralParam.push(param);
  }

  public execute() {
    if (this.createdObjs.length === 0) {
      super.execute();
      const objData = this.createdObjs[0];
      const strucMng = this.graphicProcessor.getStructuralModelManager();
      const storey = strucMng.currBuilding.getStoreyFromId(objData.storeyId)!;
      this.createLiveLoad(storey, objData);
      this.createDeadLoad(storey, objData);
    } else {
      super.execute();
    }
  }

  private createLiveLoad(storey: Storey, slab: SlabData) {
    const liveHypo = hypothesisManager.getHypothesisByName(storey.occupancy)!;
    const { ptos2D, basePoint, rotation } = slab.definition;
    const cmd = new LoadCommand(loadType.SUPERFICIAL, liveHypo.uid, storey.liveLoad, ptos2D, basePoint, rotation, slab, false, this.graphicProcessor);
    cmd.execute();
    slab.definition.liveLoadId = cmd.loadObj.id;
    cmd.loadObj.isDataVisible = false;
    cmd.loadObj.graphicObj.visible = false;
    cmd.delete();
  }
  private createDeadLoad(storey: Storey, slab: SlabData) {
    const deadHypo = hypothesisManager.getHypothesisByName(loadHypothesisList.DEAD_LOAD)!;
    const { ptos2D, basePoint, rotation } = slab.definition;
    const cmd = new LoadCommand(loadType.SUPERFICIAL, deadHypo.uid, storey.deadLoad, ptos2D, basePoint, rotation, slab, false, this.graphicProcessor);
    cmd.execute();
    slab.definition.deadLoadId = cmd.loadObj.id;
    cmd.loadObj.isDataVisible = false;
    cmd.loadObj.graphicObj.visible = false;
    cmd.delete();
  }
}
export class SlabEditDataCommand extends EditBaseStructCommand {
  protected opType = cadOpType.EDITSLAB;
}
export class SlabEditVertexDataCommand extends SlabEditDataCommand {

  public async execute() {
    super.execute();
    this.updateDefaultLoads();
  }
  public async unExecute() {
    super.unExecute();
    this.updateDefaultLoads();
  }
  private updateDefaultLoads() {
    const slab = this.objData as SlabData;
    const { ptos2D, basePoint, rotation, deadLoadId, liveLoadId } = slab.definition;

    const deadLoad = slab.loads.find(l => l.id === deadLoadId)!;
    deadLoad.definition.ptos2D = ptos2D.map(copyIPoint);
    deadLoad.definition.basePoint = copyIPoint(basePoint);
    deadLoad.definition.rotation = copyIPoint(rotation);
    deadLoad.regenerateObjectFromDefinition();

    const liveLoad = slab.loads.find(l => l.id === liveLoadId)!;
    liveLoad.definition.ptos2D = ptos2D.map(copyIPoint);
    liveLoad.definition.basePoint = copyIPoint(basePoint);
    liveLoad.definition.rotation = copyIPoint(rotation);
    liveLoad.regenerateObjectFromDefinition();
  }
}

