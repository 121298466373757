import { ArcData } from "./primitives/arc";
import { BlockData } from "./block";
import { ConeData } from "./solids/cone";
import { CubeData } from "./solids/cube";
import { CylinderData } from "./solids/cylinder";
import { AlignedDimensionData } from "./dimension/aligned-dim";
import { AngleDimensionData } from "./dimension/angle-dim";
import { ArcDimensionData } from "./dimension/arc-dim";
import { CircularDimensionData } from "./dimension/circular-dim";
import { LinealDimensionData } from "./dimension/lineal-dim";
import { IObjData } from "./objdata";
import { objDataType } from "./types";
import { PlaneData } from "./solids/plane";
import { PPrismData } from "./solids/pprism";
import { RegionData } from "./solids/region";
import { RPrismData } from "./solids/rprism";
import { BeamData } from "./structural/beam";
import { ColumnData } from "./structural/column";
import { SlabData } from "./structural/slab";
import { WallData } from "./structural/wall";
import { TextData } from "./text";
import { CircleData } from "./primitives/circle";
import { EllipseData } from "./primitives/ellipse";
import { EllipseArcData } from "./primitives/ellipse-arc";
import { LineData } from "./primitives/line";
import { PointData } from "./primitives/point";
import { PolygonData } from "./primitives/polygon";
import { StructuralElementData } from "./structural/structural";
import { LoadStructuralData } from "./structural/load";
import { LineBaseData } from "./primitives/line-base";
import { FooterData } from "./structural/footer";
import { PileCapData } from "./structural/pile-cap";
import { IDimensionData } from "./dimension/dimension";

export function isPointData(data: IObjData): data is PointData {
  return data.type === objDataType.POINT;
}
export function isLineData(data: IObjData): data is LineData {
  return data.type === objDataType.POLYLINE;
}
export function isLineClosedData(data: IObjData): boolean {
  if (isLineData(data)) {
    return data.definition.isClosed;
  }
  return false;
}
export function isCircleData(data: IObjData): data is CircleData {
  return data.type === objDataType.CIRCLE;
}
export function isTextData(data: IObjData): data is TextData {
  return data.type === objDataType.TEXT;
}
export function isPolygonData(data: IObjData): data is PolygonData {
  return data.type === objDataType.POLYGON;
}
export function isRegionData(data: IObjData): data is RegionData {
  return data.type === objDataType.REGION;
}
export function isArcData(data: IObjData): data is ArcData {
  return data.type === objDataType.ARC;
}
export function isEllipseData(data: IObjData): data is EllipseData {
  return data.type === objDataType.ELLIPSE;
}
export function isEllipseArcData(data: IObjData): data is EllipseArcData {
  return data.type === objDataType.ELLIPSEARC;
}
export function isCubeData(data: IObjData): data is CubeData {
  return data.type === objDataType.CUBE;
}
export function isCylinderData(data: IObjData): data is CylinderData {
  return data.type === objDataType.CYLINDER;
}
export function isPlaneData(data: IObjData): data is PlaneData {
  return data.type === objDataType.PLANE;
}
export function isConeData(data: IObjData): data is ConeData {
  return data.type === objDataType.CONE;
}
export function isRPrismData(data: IObjData): data is RPrismData {
  return data.type === objDataType.RRPRIM;
}
export function isPPrismData(data: IObjData): data is PPrismData {
  return data.type === objDataType.PPRISM;
}
export function isBlockData(data: IObjData): data is BlockData {
  return data.type === objDataType.BLOCK;
}

export function isDimensionData(data: IObjData): data is IDimensionData {
  if (data.type === objDataType.ALIGNEDDIM) return true;
  if (data.type === objDataType.ANGULARDIM) return true;
  if (data.type === objDataType.ARCDIM) return true;
  if (data.type === objDataType.CIRCULARDIM) return true;
  if (data.type === objDataType.LINEARDIM) return true;
  return false;
}
export function isAlignedDimensionData(data: IObjData): data is AlignedDimensionData {
  if (data.type === objDataType.ALIGNEDDIM) return true;
  return false;
}
export function isAngleDimensionData(data: IObjData): data is AngleDimensionData {
  if (data.type === objDataType.ANGULARDIM) return true;
  return false;
}
export function isArcDimensionData(data: IObjData): data is ArcDimensionData {
  if (data.type === objDataType.ARCDIM) return true;
  return false;
}
export function isCircularDimensionData(data: IObjData): data is CircularDimensionData {
  if (data.type === objDataType.CIRCULARDIM) return true;
  return false;
}
export function isLinealDimensionData(data: IObjData): data is LinealDimensionData {
  if (data.type === objDataType.LINEARDIM) return true;
  return false;
}
export function isLinealGeometry(data: IObjData) {
  if (data.type === objDataType.POLYLINE) return true;
  if (data.type === objDataType.POLYGON) return true;
  if (data.type === objDataType.CIRCLE) return true;
  return false;
}
export function isLineBasedGeometry(data: IObjData): data is LineBaseData {
  if (data.type === objDataType.POLYLINE) return true;
  if (data.type === objDataType.POLYGON) return true;
  if (data.type === objDataType.ARC) return true;
  if (data.type === objDataType.CIRCLE) return true;
  if (data.type === objDataType.ELLIPSE) return true;
  if (data.type === objDataType.ELLIPSEARC) return true;
  return false;
}
export function isClosedData(data: IObjData): boolean {
  if (isCircleData(data)) return true;
  if (isPolygonData(data)) return true;
  if (isLineData(data)) {
    return data.definition.isClosed;
  }
  return false;
}
export function isLineWithArcsData(data: IObjData): data is LineData {
  if (isLineData(data)) {
    if (data.definition.arcs.length > 0) {
      return true;
    }
  }
  return false;
}
export function isRadiusBasedGeometry(data: IObjData): data is PolygonData | CircleData | ArcData {
  if (isPolygonData(data)) return true;
  if (isCircleData(data)) return true;
  if (isArcData(data)) return true;
  return false;
}
export function isCenterData(data: IObjData): data is PolygonData {
  if (data.type === objDataType.CIRCLE) return true;
  if (data.type === objDataType.POLYLINE) return true;
  if (data.type === objDataType.ELLIPSE) return true;
  if (data.type === objDataType.ELLIPSEARC) return true;
  if (data.type === objDataType.ARC) return true;
  if (data.type === objDataType.POLYGON) return true;
  // if (isLineWithArcsData(data)) return true;
  return false;
}

export function isStructData(data: IObjData): data is StructuralElementData {
  if (data.type === objDataType.BEAM) return true;
  if (data.type === objDataType.COLUMN) return true;
  if (data.type === objDataType.SLAB) return true;
  if (data.type === objDataType.WALL) return true;
  if (data.type === objDataType.FOOTER) return true;
  if (data.type === objDataType.PILECAP) return true;
  return false
}
export function isBeamData(data: IObjData): data is BeamData {
  return data.type === objDataType.BEAM;
}

export function isColumnData(data: IObjData): data is ColumnData {
  return data.type === objDataType.COLUMN;
}
export function isSlabData(data: IObjData): data is SlabData {
  return data.type === objDataType.SLAB;
}
export function isWallData(data: IObjData): data is WallData {
  return data.type === objDataType.WALL;
}
export function isSupportData(data: IObjData): data is ColumnData | WallData {
  return data.type === objDataType.COLUMN || data.type === objDataType.WALL;
}

export function isFooterData(data: IObjData): data is FooterData {
  return data.type === objDataType.FOOTER;
}
export function isPileCapData(data: IObjData): data is PileCapData {
  return data.type === objDataType.PILECAP;
}
export function isFoundationData(data: IObjData): data is FooterData | PileCapData {
  return data.type === objDataType.FOOTER || data.type === objDataType.PILECAP;
}

export function isLoadData(data: IObjData): data is LoadStructuralData {
  if (data.type === objDataType.LOAD) return true;
  return false;
}
