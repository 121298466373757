import { IColor } from "lib/math/types";
import { style } from "lib/styles/style-cache";
import { fontType } from "./font-loader";

export enum sdfDoubleSidedType {
  NONE = 0,
  HOR,
  VER,
  FRONT,
}
export const sdfDoubleSidedTypeNames: string[] = [];
sdfDoubleSidedTypeNames[sdfDoubleSidedType.NONE] = "None";
sdfDoubleSidedTypeNames[sdfDoubleSidedType.HOR] = "Hor";
sdfDoubleSidedTypeNames[sdfDoubleSidedType.VER] = "Ver";
sdfDoubleSidedTypeNames[sdfDoubleSidedType.FRONT] = "Front";

export enum textMultiPosTypeH {
  LEFT = 0,
  MIDDLE,
  RIGHT,
}
export const textMultiPosTypeNamesH: string[] = [];
textMultiPosTypeNamesH[textMultiPosTypeH.LEFT] = "Left";
textMultiPosTypeNamesH[textMultiPosTypeH.MIDDLE] = "Middle";
textMultiPosTypeNamesH[textMultiPosTypeH.RIGHT] = "Right";

export enum textMultiPosTypeV {
  /** Ascender: Lo que sobresale de una minúscula en respecto a una mayúscula => Ah */
  ASCENDER = 0,
  /** Median: La altura de una minúscula => a */
  MEDIAN,
  /** Baseline: Línea base sobre la cual se escribe. => _ */
  BASELINE,
  /** Descender: Línea hasta la cual llegan las letras más bajas => j */
  DESCENDER,
}
export const textMultiPosTypeNamesV: string[] = [];
textMultiPosTypeNamesV[textMultiPosTypeV.ASCENDER] = "Ascender";
textMultiPosTypeNamesV[textMultiPosTypeV.MEDIAN] = "Median";
textMultiPosTypeNamesV[textMultiPosTypeV.BASELINE] = "Baseline";
textMultiPosTypeNamesV[textMultiPosTypeV.DESCENDER] = "Descender";

/** Texts settings. */
export interface ITextOptions {
  /** Escala de referencia para la que esta hecha el texto
   *
   * @type {number}
   * @memberof ITextOptions
   */
  mecRefScale: number;
  /** El texto cambia al cambiar ala escala anotativa
   *
   * @type {boolean}
   * @memberof ITextOptions
   */
  applyMecRefScale: boolean;
  /** Nombre
   *
   * @type {string}
   * @memberof TextOptions
   */
  name: string;
  /** Tipo de punto base horizontal
   *
   * @type {textMultiPosTypeH}
   * @memberof TextOptions
   */
  basePointH: textMultiPosTypeH;
  /** Tipo de punto base vertical
   *
   * @type {textMultiPosTypeV}
   * @memberof TextOptions
   */
  basePointV: textMultiPosTypeV;
  /** Fuente
   *
   * @type {fontType}
   * @memberof TextOptions
   */
  font: fontType;
  /** Color
   *
   * @type {(string | number)}
   * @memberof TextOptions
   */
  color: IColor;
  /** Tamaño (en metros)
   *
   * @type {number}
   * @memberof TextOptions
   */
  size: number;
  /** Tipo de doble cara
   *
   * @type {sdfDoubleSidedType}
   * @memberof TextOptions
   */
  doubleSided: sdfDoubleSidedType;
  /** Espaciado entre letras
   *
   * @type {number}
   * @memberof TextOptions
   */
  letterSpacing: number;
  /** Espaciado (añadido) entre líneas.
   *
   * @type {number}
   * @memberof TextOptions
   */
  lineHeight: number;
  /** Factor cursiva (metros de desfase entre la parte superior e inferior)
   *
   * @type {number}
   * @memberof TextOptions
   */
  cursiveFactor: number;
}

export class TextOptsBuilder implements style<ITextOptions>, ITextOptions {

  public default: boolean = false;
  public styleId: string;

  public name: string = "Nuevo estilo de texto";
  public font: fontType = fontType.LUCIDA;
  public doubleSided: sdfDoubleSidedType = sdfDoubleSidedType.NONE;
  public letterSpacing: number = 0;
  public lineHeight: number = 0.2;
  public cursiveFactor: number = 0;
  public size: number = 1;

  // Annotative properties
  public mecRefScale: number = 0.001; // 1:1000
  public applyMecRefScale: boolean = true;

  public color: IColor = { r: 255, g: 255, b: 255, a: 0 }; //"#ffffff"
  public basePointV: textMultiPosTypeV = textMultiPosTypeV.BASELINE;
  public basePointH: textMultiPosTypeH = textMultiPosTypeH.LEFT;

  get isCentererdV(): boolean {
    return this.basePointV === textMultiPosTypeV.MEDIAN;
  }
  get isCentererdH(): boolean {
    return this.basePointH === textMultiPosTypeH.MIDDLE;
  }

  constructor(opts?: ITextOptions) {
    if (opts !== undefined) {
      this.override(opts);
    }
  }

  public override(newProperties: Partial<ITextOptions>): void {
    if (newProperties) {
      if (newProperties.name !== undefined) this.name = newProperties.name;

      if (newProperties.mecRefScale !== undefined) this.mecRefScale = newProperties.mecRefScale;
      if (newProperties.applyMecRefScale !== undefined) this.applyMecRefScale = newProperties.applyMecRefScale;

      if (newProperties.basePointV !== undefined) this.basePointV = newProperties.basePointV;
      if (newProperties.basePointH !== undefined) this.basePointH = newProperties.basePointH;

      if (newProperties.font !== undefined) this.font = newProperties.font;
      if (newProperties.color !== undefined) this.color = newProperties.color;
      if (newProperties.size !== undefined) this.size = newProperties.size;

      if (newProperties.doubleSided !== undefined) this.doubleSided = newProperties.doubleSided;
      if (newProperties.letterSpacing !== undefined) this.letterSpacing = newProperties.letterSpacing;
      if (newProperties.lineHeight !== undefined) this.lineHeight = newProperties.lineHeight;
      if (newProperties.cursiveFactor !== undefined) this.cursiveFactor = newProperties.cursiveFactor;
    }
  }

  public clone(): TextOptsBuilder {
    const dolly = new TextOptsBuilder(this);
    dolly.default = this.default;
    dolly.styleId = this.styleId;
    return dolly;
  }
}


