const PolylineIcon = ({ ...props }) => {
  return (
    <svg
      className="h-5 w-5"
      viewBox="0 0 100 100"
      fill="currentColor"
      {...props}
    >
      <path d="m96.875 21.875v-18.75h-18.75v14.344l-20.031 20.031h-20.594v20.594l-20.031 20.031h-14.344v18.75h18.75v-14.344l20.031-20.031h20.594v-20.594l20.031-20.031zm-12.5-12.5h6.25v6.25h-6.25zm-68.75 81.25h-6.25v-6.25h6.25zm40.625-34.375h-12.5v-12.5h12.5z" />
    </svg>
  );
};

export default PolylineIcon;
