import { Project } from 'lib/apis/struc-project/api';
import { useForm } from 'react-hook-form'
import { StrucSite } from '../../../../models/project';
import { concreteCodes, euSteelTypes, loadCodes, standards } from "../../../../models/codes";

function getDefaultProjectData(): Partial<Project> {
  return {
    Name: "",
    Remarks: "",
    Country: "",
    City: "",
    Codes: {
      Concrete: concreteCodes[0],
      Load: loadCodes[0],
      SteelStandard: standards[0],
      Steel: euSteelTypes[0],
      ConcreteStandard: standards[0],
    },
    FootingLevel: 0,
  }
}

export function useProjectForm(project?: Project, site?: StrucSite) {

  const { handleSubmit, register, watch } = useForm<Project>({
    defaultValues: project ?? getDefaultProjectData(),
    shouldFocusError: true,
    mode: 'onTouched',
  });

  const dataProj = watch();

  return {
    handleSubmit,
    register,
    project: dataProj,
  }
}
