import { useCallback, useRef, useState } from "react";

interface IBounds {
  left: number;
  top: number;
  width: number;
  height: number;
}

export function useBoundContainer() {

  const updateBounds = useCallback((entries: ResizeObserverEntry[]) => {
    setBounds(entries[0].contentRect);
  }, []);

  const resizer = useRef(new ResizeObserver(updateBounds));

  const [bounds, setBounds] = useState<IBounds>({ left: 0, top: 0, width: 0, height: 0 });

  const divRef = useCallback(node => {
    if (node !== null) {
      setBounds(node.getBoundingClientRect());
      resizer.current.observe(node);
    } else {
      resizer.current.disconnect();
    }
  }, []);

  return { divRef, bounds }

}