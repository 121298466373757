import * as THREE from "three";
import { defaultColor, IBaseMaterial } from "./base";
import { BaseMaterialCache } from "./base-cache";

export interface ISolidMaterial extends IBaseMaterial {
  texture: number;
}

export class SolidMaterialCache extends BaseMaterialCache<ISolidMaterial, THREE.MeshPhongMaterial> {

  public getMaterial(solidMat: ISolidMaterial): THREE.MeshPhongMaterial {
    for (const [key, value] of this.map) {
      if (key.color.r === solidMat.color.r &&
        key.color.g === solidMat.color.g &&
        key.color.b === solidMat.color.b &&
        key.color.a === solidMat.color.a
      ) {
        return value;
      }
    }

    const material = new THREE.MeshPhongMaterial({
      color: new THREE.Color(solidMat.color.r / 255, solidMat.color.g / 255, solidMat.color.b / 255),
      transparent: solidMat.color.a !== 1,
      opacity: solidMat.color.a,
      side: THREE.DoubleSide,
      map: this.textureTest(),
      // Tambien se podria jugar con el bumpMapping que refuerza la volumetria de la textura.
      // bumpMap: useTexture ? texture : undefined,
      // bumpScale: 10,
      // wireframe: true,
    });

    this.map.set(solidMat, material);
    return material;
  }

  private textureTest() {
    const useTexture = false;
    let texture: THREE.Texture | null = null;
    if (useTexture) {
      // Construimos la textura, que solamente la primera vez tardara, y las siguientes se reaprovecha.
      // Esta que es remota ha funcionado perfectamente.
      // const url = "https://threejsfundamentals.org/threejs/resources/images/wall.jpg";
      // Esta, en el directorio frontend\public (junto con otros ficheros publicos) tambien ha funcionado perfectamente.
      // Si la coloco en otros directorios la cosa no va. Quizas haya que registrar de alguna forma ciertos directorios?.
      // Esta es la textura que usariamos para ajustes finos...
      // const url = "http://localhost:3000/texture-fgm.jpg";
      // Fuente de buenas texturas opensource: https://opengameart.org/content/seamless-brickconcrete-textures
      const url = "http://localhost:3000/Concrete512.png";
      texture = new THREE.TextureLoader().load(url, (txt: THREE.Texture) => {
        console.error("Ok!!!");
      }, undefined, (ev: ErrorEvent) => {
        console.error("Error");
      });

      // Alguna manera de mejorar la textura?.
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      // Quizas esto sea muy caro?.
      // texture.anisotropy = 16;
      // Quizas esto habria que calcularlo dinamicamente segun las dimensiones del objeto al que la pegamos?...
      // texture.repeat.set(100, 100);
    }
    return texture;
  }
}

export function getDefaultSolidMaterial(): ISolidMaterial {
  return { color: { r: defaultColor.r, g: defaultColor.g, b: defaultColor.b, a: defaultColor.a }, texture: 0 };
}

export function getCurrentSolidMaterial(solidMaterial?: Partial<ISolidMaterial>): ISolidMaterial {
  const defMat = getDefaultSolidMaterial();
  return {
    color: solidMaterial?.color ?? defMat.color,
    texture: solidMaterial?.texture ?? defMat.texture,
  };
}
