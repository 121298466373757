import * as THREE from "three";
import { getCurrentSolidMaterial, ISolidMaterial, materialCache } from "lib/materials";
import { setPosRotEsc } from "../../geometries";
import { createBufferRPrism, createRPrism, rprismParam, } from "../../geometries/solid/rprism";
import { copyIPoint } from "../../math/point";
import { IPoint } from "../../math/types";
import { objDataType } from "../types";
import { SolidData } from "./solid";

export class RPrismData extends SolidData {
  public type = objDataType.RRPRIM;
  protected nameObj: string = "Rectangular prism";
  public definition: rprismParam;

  constructor(definition: rprismParam, material?: ISolidMaterial) {
    super();
    this.definition = {
      sideX: definition.sideX,
      sideY: definition.sideY,
      sideZ: definition.sideZ,
      basePoint: copyIPoint(definition.basePoint),
      rotation: copyIPoint(definition.rotation),
      offset: copyIPoint(definition.offset),
      scale: copyIPoint(definition.scale),
    };
    this.material = material ?? getCurrentSolidMaterial();
  }
  static createObj(definition: rprismParam, material: ISolidMaterial) {
    const threeMaterial = materialCache.getSolidMaterial(material);
    return createRPrism(
      definition.sideX,
      definition.sideY,
      definition.sideZ,
      threeMaterial,
      definition.basePoint,
      definition.rotation,
      definition.offset,
      definition.scale
    );
  }
  public createGraphicObj() {
    if (this.graphicObj) {
      console.warn("Attention: RPrism graphic object already created!!");
      return;
    }
    this.graphicObj = RPrismData.createObj(this.definition, this.material);
  }
  public cloneDefinition(): rprismParam {
    const def = super.cloneSolidDefinition() as rprismParam;
    def.sideX = this.definition.sideX;
    def.sideY = this.definition.sideY;
    def.sideZ = this.definition.sideZ;
    return def;
  }
  public createObject(definition?: rprismParam, material?: ISolidMaterial): THREE.Mesh {
    return RPrismData.createObj(definition ?? this.definition, material ?? this.material);
  }
  public regenerateDefinition() {
    const geom = createBufferRPrism(this.definition.sideX, this.definition.sideY, this.definition.sideZ);
    this.graphicObj.geometry = geom;
    const { basePoint, offset, rotation, scale } = this.definition;
    setPosRotEsc(this.graphicObj, basePoint, offset, rotation, scale);
  }
  public scale(factorX: number, factorY: number, factorZ: number, basePoint: IPoint): void {
    this.definition.sideX *= factorX;
    this.definition.sideY *= factorY;
    this.definition.sideZ *= factorZ;
    super.scale(factorX, factorY, factorZ, basePoint);
  }
}