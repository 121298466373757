import AuthContainer from "shared/components/auth/auth-container";
import ConfirmAccountForm from "../components/auth/confirm-account-form";

const ConfirmAccount = () => {
  return (
    <AuthContainer title="Check your email">
      <ConfirmAccountForm />
    </AuthContainer>
  );
};

export default ConfirmAccount;
