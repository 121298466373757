import { GraphicProcessor } from "lib/graphic-processor";
import { structElemType } from "../types/struc-base";
import { SelectionAction, SelectionActionType } from "lib/events/select";
import { LayerAction, LayerActionType } from 'lib/events/layers';
import { ViewportAction } from "lib/events/viewports";
import { Storey } from "lib/models-struc/struc-storey";
import { Building } from "lib/models-struc/struc-building";
import { storeyAction, StoreyActionType, storeysEvents } from 'lib/events/storeys';
import { EventBus } from "lib/events/event-bus";
import { LayerData } from "lib/layers/layer-data";
import { BeamCrossSection } from "../cross-sections-shape/beam-cross-sections/beamcs-shapes";
import { beamCrossSectionCache, currentBeamCrossSectionId } from "../cross-sections-shape/beam-cross-sections/cache";
import { ShellCrossSection } from "../cross-sections-shape/shell-cross-sections/shellcs-shapes";
import { shellCrossSectionCache } from "../cross-sections-shape/shell-cross-sections/cache";
import { cacheAction, CacheActionType } from "lib/styles/style-cache";
import { cadOpType } from "lib/operations/factory";
import { isWallCSSParams } from "../cross-sections-shape/shell-cross-sections/types";
import { LoadStructuralData } from "lib/models/structural/load";
import { SlabData } from "lib/models/structural/slab";
import { IStrucElementData } from "lib/models/structural/structural";
import { objDataType } from "lib/models/types";
import { EditActionType, editObjAction } from "lib/events/objectdata";
import { isStructData } from "lib/models/checktools";
import { EditConsolidation } from "../checkers.ts/struc-consolidate";

export class StructuralModelManager {

  private graphicProcessor: GraphicProcessor;

  // Link storey <--> Layer Id
  private layerStoreys: WeakMap<Storey, string> = new WeakMap();
  private dxfLayerStoreys: WeakMap<Storey, string> = new WeakMap();
  private storeysLayers: Map<string, Storey> = new Map();
  private storeysDxfLayers: Map<string, Storey> = new Map();

  // Cache id --> StructuralElement
  private mapStructural: Map<string, IStrucElementData> = new Map();
  private mapLoads: Map<string, LoadStructuralData> = new Map();

  public currBuilding: Building;
  public currStorey: Storey;
  public currStoreyLayers: string[] = [];

  constructor(graphicProcessor: GraphicProcessor) {
    this.graphicProcessor = graphicProcessor;
    this.registerEvents();
  }

  private registerEvents() {
    const selMng = this.graphicProcessor.getSelectionManager();
    selMng.subscribe(this.changeStoreyCB);

    this.graphicProcessor.viewportObserver.subscribe(this.handleViewPortChange);

    const lyrMng = this.graphicProcessor.getLayerManager();
    lyrMng.layerObserver.subscribe(this.handleLayerChange);

    const modelMng = this.graphicProcessor.getDataModelManager();
    modelMng.subscribe(this.handleEditObj);

    storeysEvents.subscribe(this.handleStoreyEvents);

    beamCrossSectionCache.subscribe(this.handleChangeBeamCrossSection);
    shellCrossSectionCache.subscribe(this.handleChangeShellCrossSection);
  }
  public unregisterEvents() {
    const selMng = this.graphicProcessor.getSelectionManager();
    selMng.unsubscribe(this.changeStoreyCB);

    this.graphicProcessor.viewportObserver.unsubscribe(this.handleViewPortChange);

    const lyrMng = this.graphicProcessor.getLayerManager();
    lyrMng.layerObserver.unsubscribe(this.handleLayerChange);

    const modelMng = this.graphicProcessor.getDataModelManager();
    modelMng.unsubscribe(this.handleEditObj);

    storeysEvents.unsubscribe(this.handleStoreyEvents);

    beamCrossSectionCache.unsubscribe(this.handleChangeBeamCrossSection);
    shellCrossSectionCache.subscribe(this.handleChangeShellCrossSection);
  }

  private handleEditObj = (action: editObjAction): void => {
    if (action.type === EditActionType.EDIT_OBJ) {
      const objsData = action.payload.objsEdited
      for (const objData of objsData) {
        if (isStructData(objData)) {
          EditConsolidation(objData, this.graphicProcessor);
        }
      }
    }
  };
  private changeStoreyCB = (action: SelectionAction): void => {
    if (action.type === SelectionActionType.SELECT) {
      const vData = action.payload.obj;
      if (vData.length === 1) {
        const objData = vData[0];
        const layerManager = this.graphicProcessor.getDataModelManager().layerManager;
        layerManager.setCurrentLayerById(objData.layerId);
      }
    }
  };
  private handleLayerChange = (action: LayerAction): void => {
    if (action.type === LayerActionType.SET_CURRENT_LAYER) {

      const strLyrsId = Array.from(this.storeysLayers.keys());
      const strDxfLyrsId = Array.from(this.storeysDxfLayers.keys());

      const currentLayer = action.payload.currentLayer;
      const lyrMng = this.graphicProcessor.getLayerManager();
      const lyrs = lyrMng.getParentLayersDataFromId(currentLayer.id)!;

      for (const layerData of lyrs) {
        if (strLyrsId.indexOf(layerData.id) !== -1) {
          const storey = this.storeysLayers.get(layerData.id)!;
          this.setCurrStorey(storey);
          const rayCaster = this.graphicProcessor.getRaycaster();
          rayCaster.setStrucLayer2Raycast(this.currStoreyLayers);
          return;

        } else if (strDxfLyrsId.indexOf(layerData.id) !== -1) {
          const storey = this.storeysDxfLayers.get(layerData.id)!;
          this.setCurrStorey(storey);
          const rayCaster = this.graphicProcessor.getRaycaster();
          rayCaster.setStrucLayer2Raycast(this.currStoreyLayers);
          return;
        }
      }
      const planeMng = this.graphicProcessor.getPlaneManager();
      planeMng.mainPlane.position.z = 0;
      planeMng.activePlane.position.z = 0;
      planeMng.lastMainPosition.z = 0;
      this.currStoreyLayers = [];
      const rayCaster = this.graphicProcessor.getRaycaster();
      rayCaster.setStrucLayer2Raycast(this.currStoreyLayers);
    }
  };
  private handleViewPortChange = (action: ViewportAction): void => {
    const viewport = action.payload.viewport;
    const camera = viewport.camera;
    if (camera) {
      const rayCaster = this.graphicProcessor.getRaycaster();
      rayCaster.setStrucLayer2Raycast(this.currStoreyLayers);
    }
  };
  private handleStoreyEvents = (action: storeyAction): void => {
    if (action.type === StoreyActionType.UPDATE_LEVEL) {
      const storey = action.payload.storey;
      const layerId = this.dxfLayerStoreys.get(storey);
      if (layerId) {
        const dataModelMng = this.graphicProcessor.getDataModelManager();
        dataModelMng.iterAllDataFromLayers([layerId], (data) => {
          data.translate({ x: 0, y: 0, z: storey.level - action.payload.oldLevel })
        })
      }
    }
  };

  private handleChangeBeamCrossSection = (action: cacheAction<BeamCrossSection>): void => {
    if (action.type === CacheActionType.UPDATE_CACHE || action.type === CacheActionType.DELETE_CACHE) {
      const id = action.payload.id;
      const editedObj = [];
      for (const storey of this.currBuilding.storeys) {
        for (const column of storey.columns) {
          const def = column.definition;
          if (def.crossSectionId === id) {
            if (action.type === CacheActionType.DELETE_CACHE) def.crossSectionId = currentBeamCrossSectionId;
            column.regenerateObjectFromDefinition();
            editedObj.push(column);
          }
        }
        for (const beam of storey.beams) {
          const def = beam.definition;
          if (def.crossSectionId === id) {
            if (action.type === CacheActionType.DELETE_CACHE) def.crossSectionId = currentBeamCrossSectionId;
            beam.regenerateObjectFromDefinition();
            editedObj.push(beam);
          }
        }
        for (const pileCap of storey.pileCaps) {
          const def = pileCap.definition;
          if (def.pileSections === id) {
            pileCap.regenerateObjectFromDefinition();
            editedObj.push(pileCap);
          }
        }
      }
      const dtMdlMnrg = this.graphicProcessor.getDataModelManager();
      dtMdlMnrg.dispatchEditObjs(editedObj, cadOpType.CHANGESTYLE);
    }
  }
  private handleChangeShellCrossSection = (action: cacheAction<ShellCrossSection>): void => {
    if (action.type === CacheActionType.UPDATE_CACHE) {
      const id = action.payload.id;
      const shellCrossSection = action.payload.style;
      const editedObj = [];
      if (isWallCSSParams(shellCrossSection.parameters)) {
        for (const storey of this.currBuilding.storeys) {
          for (const wall of storey.walls) {
            const repr = wall.definition;
            if (repr.shellCrossSectionId === id) {
              wall.regenerateObjectFromDefinition();
              editedObj.push(wall);
            }
          }
        }
      } else {
        for (const storey of this.currBuilding.storeys) {
          for (const slab of storey.slabs) {
            const repr = slab.definition;
            if (repr.shellCrossSectionId === id) {
              repr.depth = shellCrossSection.thickness;
              slab.regenerateObjectFromDefinition();
              editedObj.push(slab);
            }
            if (repr.waffleShelCrossSectionId && repr.waffleShelCrossSectionId === id) {
              // TODO: update waffle slab parameters
            }
          }
          for (const pileCap of storey.pileCaps) {
            const def = pileCap.definition;
            if (def.capSectionId === id) {
              pileCap.regenerateObjectFromDefinition();
              editedObj.push(pileCap);
            }
          }
          for (const footer of storey.footers) {
            const def = footer.definition;
            if (def.shellSectionId === id) {
              footer.regenerateObjectFromDefinition();
              editedObj.push(footer);
            }
          }
        }
      }
      const dtMdlMnrg = this.graphicProcessor.getDataModelManager();
      dtMdlMnrg.dispatchEditObjs(editedObj, cadOpType.CHANGESTYLE);
    }
  }

  // ------------------------------------------------------------------

  setCurrStorey(storey: Storey) {
    this.currStorey = storey;
    this.currBuilding.dispatchSetCurrentStorey(this.currStorey);
    // Set storey plane 
    const planeMng = this.graphicProcessor.getPlaneManager();
    planeMng.mainPlane.position.z = this.currStorey.level;
    planeMng.activePlane.position.z = this.currStorey.level;
    planeMng.lastMainPosition.z = this.currStorey.level;
    // Set storey linked layers 
    const lyrId = this.layerStoreys.get(this.currStorey);
    const dxfLyrId = this.dxfLayerStoreys.get(this.currStorey);
    this.currStoreyLayers = [];
    if (lyrId) this.currStoreyLayers.push(lyrId);
    if (dxfLyrId) this.currStoreyLayers.push(dxfLyrId);
  }
  setCurrBuilding(building: Building) {
    this.currBuilding = building;
  }

  // ------------------------------------------------------------------

  getLayerByStoreyIdAndStructuralType(storeyId: string, elemType: structElemType): LayerData {
    const storey = this.currBuilding.getStoreyFromId(storeyId)!;
    const layerId = this.layerStoreys.get(storey)!;
    const newLayerData = this.getLayerByParentLayerAndStructuralType(layerId, elemType);
    this.storeysLayers.set(newLayerData.id, storey);
    return newLayerData;
  }
  private getLayerByParentLayerAndStructuralType(parentLayerId: string, elemType: structElemType): LayerData {
    const childLayerName = this.getLayerNameByStructuralType(elemType);
    const layerManager = this.graphicProcessor.getDataModelManager().layerManager;
    const layerData = layerManager.getOrCreateChildrenLayerByParentIdAndName(parentLayerId, childLayerName);
    return layerData;
  }
  private getLayerNameByStructuralType(elemType: structElemType | undefined): string {
    switch (elemType) {
      case objDataType.BEAM: return "Beams";
      case objDataType.FOOTER:
      case objDataType.PILECAP: return "Foundations"
      case objDataType.COLUMN: return "Columns";
      case objDataType.LOAD: return "Loads";
      case objDataType.SLAB: return "Slabs";
      case objDataType.WALL: return "Walls";
      default: return "root"
    }
  }

  //-------------------------------------------------------

  registerStrucData(structElem: IStrucElementData) {
    this.mapStructural.set(structElem.id, structElem);
    const storey = this.currBuilding.getStoreyFromId(structElem.storeyId)!;
    storey.addStructuralElement(structElem);
  }
  registerLoadData(loadData: LoadStructuralData) {
    this.mapLoads.set(loadData.id, loadData);
  }
  unregisterStrucData(structElem: IStrucElementData) {
    this.mapStructural.delete(structElem.id);
    const storey = this.currBuilding.getStoreyFromId(structElem.storeyId)!;
    storey.deleteStructuralElement(structElem);
  }
  unregisterLoadData(loadData: LoadStructuralData) {
    this.mapLoads.delete(loadData.id);
  }

  //-------------------------------------------------------

  getStructElemFromId(structId: string) {
    const data = this.mapStructural.get(structId);
    if (data) {
      return data;
    }
    return null;
  }
  getSlabFromName(name: string): SlabData | null {
    const allStoreys = this.currBuilding.storeys;
    for (const storey of allStoreys) {
      const slabs = storey.slabs;
      for (const slab of slabs) {
        if (name === slab.strucName) {
          return slab;
        }
      }
    }
    return null;
  }
  getStructElements() {
    return [...this.mapStructural.values()];
  }
  getLoadElements() {
    return [...this.mapLoads.values()];
  }
  clearAll() {
    this.layerStoreys = new WeakMap();
    this.dxfLayerStoreys = new WeakMap();
    this.storeysLayers.clear();
    this.storeysDxfLayers.clear();
    this.mapStructural.clear();
    this.mapLoads.clear();

    this.currBuilding = undefined!;
    this.currStorey = undefined!;
    this.currStoreyLayers.length = 0;
  }

  // ----------------------------------------------

  registerStoreyLayers() {
    const projMng = this.graphicProcessor.getProjectModelManager();
    this.setCurrBuilding(projMng.project.sites[0].buildings[0]);
    if (this.currBuilding.storeys.length) {
      const storeys = this.currBuilding.storeys;
      const layerManager = this.graphicProcessor.getLayerManager();
      for (const storey of storeys) {
        const layerData = layerManager.getLayerDataFromName(storey.name)!;
        this.layerStoreys.set(storey, layerData.id);
        this.storeysLayers.set(layerData.id, storey);

        const layerDxf = layerManager.getLayerDataFromName(storey.name + "dxf");
        if (layerDxf) {
          this.dxfLayerStoreys.set(storey, layerDxf.id);
          this.storeysDxfLayers.set(layerDxf.id, storey);
        }
      }

      EventBus.enableDispatch = false;
      const currStorey = this.currBuilding.storeys[0];
      this.setCurrStorey(currStorey);
      layerManager.setCurrentLayerById(this.layerStoreys.get(currStorey)!);
      EventBus.enableDispatch = true;

      this.dispatchLoadStoreys();
    }
  }
  dispatchLoadStoreys() {
    storeysEvents.dispatch({
      type: StoreyActionType.LOADSTOREYS,
      payload: {
        storeys: this.currBuilding.storeys,
        storey: this.currStorey,
      }
    });
  }
}