import { GraphicProcessor } from "lib/graphic-processor";
import { createContext, useContext, ReactNode } from "react";
import useLayers from "./hooks/use-layers";

const LayerContext = createContext<any>({});

const LayerProvider = ({ children, graphicProc }: { children: ReactNode, graphicProc: GraphicProcessor }) => {

  const useHypothesisProp = useLayers(graphicProc);

  return (
    <LayerContext.Provider value={useHypothesisProp}>
      {children}
    </LayerContext.Provider>);
};

const useLayerContext = () => {
  const context = useContext<ReturnType<typeof useLayers>>(LayerContext);
  if (Object.keys(context).length === 0) {
    throw new Error(`useLayerContext must be used within a LayerProvider`);
  }
  return context;
};

export { LayerProvider, useLayerContext };