import * as THREE from "three";
import { getAuxMaterialLine, LineMaterialType } from "lib/materials";
import { getRelativePoint } from "lib/coordinates/plane";
import { adjustDrawRange, getRotationSystemFrom3p, updateObjBboxBSphere } from ".";
import { getAngleBetweenAzimuts, lineAzimut2p, ORIENT } from "../math/angles";
import { arcParam, circleGetCenter3p, getArcDirectionFrom3p } from "../math/arc";
import { vectorDist3D } from "../math/distance";
import { copyIPoint, getAzimutPolarPoint } from "../math/point";
import { rotateBuffer } from "../math/rotate";
import { IPoint } from "../math/types";
import { ARC_DISCR } from "./circle";

export function arcCreate(center: IPoint, radius: number, azimutO?: number, angleCenter?: number, plane?: IPoint, material?: LineMaterialType): THREE.Line {
    if (azimutO === undefined) azimutO = 0;
    if (plane === undefined) plane = { x: 0, y: 0, z: 0 };
    if (angleCenter === undefined || angleCenter === 0) angleCenter = Math.PI * 2;

    const points: THREE.BufferGeometry = new THREE.BufferGeometry();
    const coords: Float32Array = arcBuffer(center, radius, azimutO, angleCenter, plane);
    points.setAttribute("position", new THREE.Float32BufferAttribute(coords, 3));
    const mat = material ?? getAuxMaterialLine();
    const arc: THREE.Line = new THREE.Line(points, mat);
    if (mat.type !== "LineBasicMaterial") arc.computeLineDistances();
    adjustDrawRange(arc);
    updateObjBboxBSphere(arc);
    return arc;
}

export function arcBuffer(center: IPoint, radius: number, azimutO: number, angleCenter?: number, plane?: IPoint): Float32Array {
    if (plane === undefined) plane = { x: 0, y: 0, z: 0 };
    if (angleCenter === undefined || angleCenter === 0) angleCenter = Math.PI * 2;

    const angle: number = angleCenter / ARC_DISCR;
    const numNewVertex: number = ARC_DISCR + 1;
    const coords: Float32Array = new Float32Array(numNewVertex * 3);

    let position: number, currAng: number, point: IPoint;
    for (let i: number = 0; i < numNewVertex; i++) {
        position = i * 3;
        currAng = azimutO + (i * angle);
        point = getAzimutPolarPoint(center, currAng, radius);
        coords[position] = point.x;
        coords[position + 1] = point.y;
        coords[position + 2] = center.z;
    }
    if ((plane.x !== 0) || (plane.y !== 0) || (plane.z !== 0)) {
        rotateBuffer(coords, plane.x, plane.y, plane.z, center);
    }
    return coords;
}

export function arcBuffer3p(p1: IPoint, p2: IPoint, p3: IPoint, plane?: IPoint): Float32Array | null {
    if (plane === undefined) {
        plane = getRotationSystemFrom3p(p1, p2, p3);
        if (plane.x < 0 || plane.y < 0 || plane.z < 0) {
            plane = getRotationSystemFrom3p(p3, p2, p1);
        }
    }
    const center = circleGetCenter3p(p1, p2, p3);
    if (center) {
        const radius: number = vectorDist3D(center, p1);
        const direction = getArcDirectionFrom3p(p1, p2, p3, plane);
        return arcBufferCR2p(center, radius, p1, p3, direction, plane);
    }
    return null;
}

export function arcBuffer2pC(p1: IPoint, p3: IPoint, center: IPoint, direction: ORIENT, plane?: IPoint): Float32Array {
    if (plane === undefined) {
        plane = (direction === ORIENT.CCW) ? getRotationSystemFrom3p(center, p3, p1) : getRotationSystemFrom3p(center, p1, p3);
        if (plane.x < 0 || plane.y < 0 || plane.z < 0) {
            plane = getRotationSystemFrom3p(p1, p3, center);
        }
    }
    const radius = vectorDist3D(center, p1);
    return arcBufferCR2p(center, radius, p1, p3, direction, plane);
}

export function arcBufferCR2p(center: IPoint, radius: number, p1: IPoint, p3: IPoint, direction: ORIENT, plane?: IPoint): Float32Array {
    if (plane === undefined) {
        plane = direction === ORIENT.CCW ? getRotationSystemFrom3p(center, p3, p1) : getRotationSystemFrom3p(center, p1, p3);
        if (plane.x < 0 || plane.y < 0 || plane.z < 0) {
            plane = getRotationSystemFrom3p(p1, p3, center);
        }
    }
    let v1 = copyIPoint(p1);
    let v3 = copyIPoint(p3);
    v1 = getRelativePoint(v1, center, plane);
    v3 = getRelativePoint(v3, center, plane);
    const azimutO = lineAzimut2p({ x: 0, y: 0, z: 0 }, v1);
    const azimutEnd = lineAzimut2p({ x: 0, y: 0, z: 0 }, v3);
    let angleCenter = getAngleBetweenAzimuts(azimutO, azimutEnd, direction);
    if (direction === ORIENT.CCW) angleCenter *= -1;
    return arcBuffer(center, radius, azimutO, angleCenter, plane);
}

export function arcParamFrom2pC(p1: IPoint, p3: IPoint, center: IPoint, direction: ORIENT, plane?: IPoint): arcParam {
    if (plane === undefined) {
        plane = direction === ORIENT.CCW ? getRotationSystemFrom3p(center, p3, p1) : getRotationSystemFrom3p(center, p1, p3);
        if (plane.x < 0 || plane.y < 0 || plane.z < 0) {
            plane = getRotationSystemFrom3p(p1, p3, center);
        }
    }
    const radius = vectorDist3D(center, p1);
    let v1 = copyIPoint(p1);
    let v3 = copyIPoint(p3);
    v1 = getRelativePoint(v1, center, plane);
    v3 = getRelativePoint(v3, center, plane);
    const azimutO = lineAzimut2p({ x: 0, y: 0, z: 0 }, v1);
    const azimutEnd = lineAzimut2p({ x: 0, y: 0, z: 0 }, v3);
    let angleCenter = getAngleBetweenAzimuts(azimutO, azimutEnd, direction);
    if (direction === ORIENT.CCW) angleCenter *= -1;
    return { center, radius, azimutO, angleCenter, plane };
}