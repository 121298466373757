import { PasteCommand } from "lib/commands/copy";

import { copyIPoint } from "lib/math/point";
import { cadOpType } from "../factory";
import { TranslationBaseOP } from "../transform/translation";

export class PasteOP extends TranslationBaseOP {

  public opType = cadOpType.PASTE;

  public async start() {
    this.iniSettingsOp();
    this.registerCancel();
    this.setStartObjs();
    this.setNextStep(2);
  }

  public save() {
    this.endPoints.push(copyIPoint(this.lastPoint));
    if (this.graphicProcessor && this.basePoint && this.endPoints.length) {
      const command = new PasteCommand(
        this.basePoint,
        this.endPoints,
        this.objDataOrigin.slice(),
        this.getCurrentSceneId(),
        this.graphicProcessor
      );
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
}