import { cadOpType } from "lib/operations/factory";
import { useUI } from "shared/components/ui/context";
import WorkingPlaneIcon from "../icons/wrk-plane";
import ToolbarButton from "../toolbar/toolbar-button";
import ToolbarSection from "../toolbar/toolbar-section";
import { useMainGraphicContext } from "../viewport/context";

const PlaneToolbar = () => {
  const { openModal } = useUI();
  const graphicProcessor = useMainGraphicContext();
  return (
    <ToolbarSection title="Working planes" className={"border-b border-gray-600"}>
      <ToolbarButton className="px-1" onClick={() => openModal("PLANE_LIST")} tooltip="Show working planes" >
        <WorkingPlaneIcon />
      </ToolbarButton>
      <ToolbarButton
        className="px-1"
        onClick={() => graphicProcessor.launchOP(cadOpType.WRKPLANE3P)}
        tooltip="Add working plane"
      >
        <svg className="h-8 w-8" viewBox="0 0 100 100" fill="none">
          <path
            d="M41.1001 47.2L42.9001 47.7C48.4001 49.2 51.5001 52.4 52.7001 57.4L53.2001 59.7L53.7001 57.4C54.8001 52.4 58.0001 49.2 63.5001 47.7L65.2001 47.2L63.5001 46.7C58.0001 45.2 54.8001 42 53.7001 37L53.2001 34.7L52.7001 37C51.6001 42 48.4001 45.2 42.9001 46.7L41.1001 47.2Z"
            fill="currentColor"
          />
          <path
            d="M40.8002 38L41.1002 39.2L41.4002 38C41.7002 36.4 42.8002 35.4 44.5002 34.9L45.4002 34.6L44.5002 34.3C42.8002 33.8 41.8002 32.8 41.4002 31.2L41.1002 30L40.8002 31.2C40.5002 32.8 39.4002 33.8 37.7002 34.3L36.7002 34.6L37.7002 34.9C39.5002 35.4 40.5002 36.4 40.8002 38Z"
            fill="currentColor"
          />
          <path
            d="M63.7998 38.7L64.0998 39.8L64.3998 38.7C64.5998 37.9 65.0998 37.4 65.9998 37.1L66.8998 36.9L65.9998 36.7C65.0998 36.4 64.5998 35.9 64.3998 35.1L64.0998 34L63.7998 35.1C63.5998 35.9 63.0998 36.4 62.1998 36.7L61.2998 36.9L62.1998 37.1C63.0998 37.4 63.5998 37.9 63.7998 38.7Z"
            fill="currentColor"
          />
          <path
            d="M65 48.7L64.6 49.6L86.8 57.4L50 69.4L13.2 57.4L35.4 49.6L35 48.7L10 57.4L50 70.5L90 57.4L65 48.7Z"
            fill="currentColor"
          />
          <path
            d="M42.2002 53.3C42.2002 54 42.8002 54.6 43.5002 54.6C44.2002 54.6 44.8002 54 44.8002 53.3C44.8002 52.6 44.2002 52 43.5002 52C42.8002 52.1 42.2002 52.6 42.2002 53.3Z"
            fill="currentColor"
          />
          <path
            d="M56.0002 32C56.7002 32 57.3002 31.4 57.3002 30.7C57.3002 30 56.7002 29.4 56.0002 29.4C55.3002 29.4 54.7002 30 54.7002 30.7C54.8002 31.5 55.3002 32 56.0002 32Z"
            fill="currentColor"
          />
        </svg>
      </ToolbarButton>
    </ToolbarSection>
  );
};

export default PlaneToolbar;
