import { StyleCacheHandler } from "lib/styles/style-cache";
import { defaultPileCapBuilder } from '../../geometries/structural/pile-cap';
import { PileCapTemplate } from "./pile-cap-template";

export let pileCapTemplateCache: pileCapTemplateCacheHandler;

export let currentPileCapTemplateId: string;

export class pileCapTemplateCacheHandler extends StyleCacheHandler<PileCapTemplate> {

  static initialize() {
    pileCapTemplateCache = new pileCapTemplateCacheHandler();
    pileCapTemplateCache.loadDefaultItems();
  }
  static clear() {
    pileCapTemplateCache.clear();
    pileCapTemplateCache = undefined!;
  }

  private loadDefaultItems() {
    const pc = new PileCapTemplate({ name: "PC02", ...defaultPileCapBuilder.get2PileCap() });
    pc.default = true;
    pc.styleId = "PileCapDef00";
    pileCapTemplateCache.saveStyle(pc);
    currentPileCapTemplateId = pc.styleId;
    const pc01 = new PileCapTemplate({ name: "PC03", ...defaultPileCapBuilder.get3PileCap() });
    pc01.default = true;
    pc01.styleId = "PileCapDef01";
    pileCapTemplateCache.saveStyle(pc01);
    const pc02 = new PileCapTemplate({ name: "PC04", ...defaultPileCapBuilder.get4PileCap() });
    pc02.default = true;
    pc02.styleId = "PileCapDef02";
    pileCapTemplateCache.saveStyle(pc02);
    const pc03 = new PileCapTemplate({ name: "PC05", ...defaultPileCapBuilder.get5PileCap() });
    pc03.default = true;
    pc03.styleId = "PileCapDef03";
    pileCapTemplateCache.saveStyle(pc03);
    const pc04 = new PileCapTemplate({ name: "PC06", ...defaultPileCapBuilder.get6PileCap() });
    pc04.default = true;
    pc04.styleId = "PileCapDef04";
    pileCapTemplateCache.saveStyle(pc04);
    const pc05 = new PileCapTemplate({ name: "PC07", ...defaultPileCapBuilder.get7PileCap() });
    pc05.default = true;
    pc05.styleId = "PileCapDef05";
    pileCapTemplateCache.saveStyle(pc05);
    const pc06 = new PileCapTemplate({ name: "PC08", ...defaultPileCapBuilder.get8PileCap() });
    pc06.default = true;
    pc06.styleId = "PileCapDef06";
    pileCapTemplateCache.saveStyle(pc06);
    const pc07 = new PileCapTemplate({ name: "PC09", ...defaultPileCapBuilder.get9PileCap() });
    pc07.default = true;
    pc07.styleId = "PileCapDef07";
    pileCapTemplateCache.saveStyle(pc07);
    const pc08 = new PileCapTemplate({ name: "PC010", ...defaultPileCapBuilder.get10PileCap() });
    pc08.default = true;
    pc08.styleId = "PileCapDef08";
    pileCapTemplateCache.saveStyle(pc08);
    const pc09 = new PileCapTemplate({ name: "PC011", ...defaultPileCapBuilder.get11PileCap() });
    pc09.default = true;
    pc09.styleId = "PileCapDef09";
    pileCapTemplateCache.saveStyle(pc09);
    const pc10 = new PileCapTemplate({ name: "PC012", ...defaultPileCapBuilder.get12PileCap() });
    pc10.default = true;
    pc10.styleId = "PileCapDef010";
    pileCapTemplateCache.saveStyle(pc10);
    const pc11 = new PileCapTemplate({ name: "PC013", ...defaultPileCapBuilder.get13PileCap() });
    pc11.default = true;
    pc11.styleId = "PileCapDef011";
    pileCapTemplateCache.saveStyle(pc11);
    const pc12 = new PileCapTemplate({ name: "PC014", ...defaultPileCapBuilder.get14PileCap() });
    pc12.default = true;
    pc12.styleId = "PileCapDef012";
    pileCapTemplateCache.saveStyle(pc12);
    const pc13 = new PileCapTemplate({ name: "PC015", ...defaultPileCapBuilder.get15PileCap() });
    pc13.default = true;
    pc13.styleId = "PileCapDef013";
    pileCapTemplateCache.saveStyle(pc13);
    const pc14 = new PileCapTemplate({ name: "PC016", ...defaultPileCapBuilder.get16PileCap() });
    pc14.default = true;
    pc14.styleId = "PileCapDef014";
    pileCapTemplateCache.saveStyle(pc14);
    const pc15 = new PileCapTemplate({ name: "PC017", ...defaultPileCapBuilder.get17PileCap() });
    pc15.default = true;
    pc15.styleId = "PileCapDef015";
    pileCapTemplateCache.saveStyle(pc15);
    const pc16 = new PileCapTemplate({ name: "PC018", ...defaultPileCapBuilder.get18PileCap() });
    pc16.default = true;
    pc16.styleId = "PileCapDef016";
    pileCapTemplateCache.saveStyle(pc16);
    const pc17 = new PileCapTemplate({ name: "PC019", ...defaultPileCapBuilder.get19PileCap() });
    pc17.default = true;
    pc17.styleId = "PileCapDef017";
    pileCapTemplateCache.saveStyle(pc17);
    const pc18 = new PileCapTemplate({ name: "PC020", ...defaultPileCapBuilder.get20PileCap() });
    pc18.default = true;
    pc18.styleId = "PileCapDef018";
    pileCapTemplateCache.saveStyle(pc18);
  }

  load(itemsSaved: Record<string, PileCapTemplate>) {
    for (const id in itemsSaved) {
      if (Object.prototype.hasOwnProperty.call(itemsSaved, id)) {
        const pileCap = new PileCapTemplate(itemsSaved[id]);
        pileCap.styleId = id;
        this.saveStyle(pileCap);
      }
    }
  }
}
