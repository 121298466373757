import { StrechtCommand } from "lib/commands/transform/stretch";

import { setPosBuffer } from "lib/geometries";
import { arcBuffer } from "lib/geometries/arc";
import { lineAddVertex, lineAuxCreate, lineMoveVertex } from "lib/geometries/line";
import { moveObj } from "lib/geometries/translation";
import { getBufferFromPolylineParam } from "lib/math/line";
import { copyIPoint, mulIpoint, substractIpoint } from "lib/math/point";
import { strectchArcObj, strectchLineObj } from "lib/math/stretch";
import { IPoint } from "lib/math/types";
import { isArcData, isLineData } from "lib/models/checktools";
import { MultiEdition } from "../edition-multi";
import { cadOpType } from "../factory";
import { settingsOpModes } from "../step-operations";

export class StretchOP extends MultiEdition {
  public opType = cadOpType.STRETCH;

  private auxLine: THREE.Line;

  private basePoint: IPoint | null = null;
  private distanceVector: IPoint = { x: 0, y: 0, z: 0 };

  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([
      {
        infoMsg: "Select objects.",
        stepMode: settingsOpModes.SELECTOBJS,
        multiSelect: true,
        enableSelectMarks: true,
        filterFun: () => true,
        endStepCallback: async () => {
          this.unRegisterRaycast();
          if (this.objDataAux.length === 0) this.setAuxObjs();
          this.objDataAux.forEach((o) => this.saveToTempScene(o.graphicObj));
          this.registerInputs();
          this.registerUpdaters();
          this.initializeSnap();
        },
      }, {
        infoMsg: "Specify base point",
        stepMode: settingsOpModes.DEFAULTXYZ,
        cmdLineListener: this.addPointFromExt.bind(this),
        endStepCallback: () => {
          this.basePoint = copyIPoint(this.lastPoint);
          const { x, y, z } = this.basePoint;
          lineAddVertex(this.auxLine, x, y, z, 0);
          lineAddVertex(this.auxLine, x, y, z, 1);
        },
      }, {
        infoMsg: "Specify final point",
        stepMode: settingsOpModes.DEFAULTXYZ,
        cmdLineListener: this.addPointFromExt.bind(this),
        endStepCallback: () => {
          this.save();
          this.endOperation();
        },
      },
    ]);
  }

  public async start() {
    this.iniSettingsOp();
    this.objDataOrigin.length = 0;
    this.graphicProcessor.unselectAll();

    this.auxLine = lineAuxCreate();
    this.saveToTempScene(this.auxLine);
    this.registerCancel();
    this.setStartObjs();
    this.registerRaycast();
  }

  public setLastPoint(): void {
    this.setNextStep();
  }

  public moveLastPoint(pto: IPoint) {
    if (this.basePoint) {
      this.distanceVector = mulIpoint(this.distanceVector, -1);
      for (let i = 0; i < this.objDataOrigin.length; i++) {
        const aux = this.objDataAux[i];
        const parts = this.objDataParts.get(this.objDataOrigin[i]);
        if (parts) {
          if (parts.length === 0) {
            moveObj(aux.graphicObj, this.distanceVector);
          }
        }
      }
      this.distanceVector = substractIpoint(pto, this.basePoint);
      if (this.auxLine) lineMoveVertex(this.auxLine, pto.x, pto.y, pto.z);

      for (let i = 0; i < this.objDataOrigin.length; i++) {
        const aux = this.objDataAux[i];
        const parts = this.objDataParts.get(this.objDataOrigin[i]);

        if (parts) {
          if (parts.length === 0) {
            moveObj(aux.graphicObj, this.distanceVector);
          } else {
            const data = this.objDataOrigin[i];
            if (isLineData(data)) {
              const def = data.cloneDefinition();
              strectchLineObj(def, parts, this.distanceVector);
              const buff = getBufferFromPolylineParam(def);
              setPosBuffer(aux.graphicObj as THREE.Line, buff);

            } else if (isArcData(data)) {
              const def = data.cloneDefinition();
              const newDef = strectchArcObj(def, parts, this.distanceVector);
              if (newDef) {
                const buff = arcBuffer(newDef.center, newDef.radius, newDef.azimutO, newDef.angleCenter, newDef.plane);
                setPosBuffer(aux.graphicObj as THREE.Line, buff);
              }
            }
          }
        }
      }
    }
  }

  public save() {
    if (this.graphicProcessor) {
      const command = new StrechtCommand(
        this.distanceVector,
        this.objDataOrigin,
        [...this.objDataParts.values()],
        this.graphicProcessor
      );
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
}
