import { polygonParam } from "./polygon";

export function polygonArea(param: polygonParam): number {
  if (param.inscribed) {
    const betha = Math.PI * (1 - 2 / param.sides);
    return param.sides * 0.5 * param.radius * param.radius * Math.sin(betha);
  } else {
    const alpha = 2 * Math.PI / param.sides;
    return param.sides * param.radius * param.radius * Math.tan(alpha * 0.5);
  }
}