import * as THREE from "three";
import { IPoint } from "../../math/types";
import { cadOpType } from "../factory";
import { lineAddVertex, lineAuxCreate, lineCreate, lineMoveVertex } from "../../geometries/line";
import { PolyLineCommand } from "../../commands/primitives/polyline";
import { copyIPoint } from "lib/math/point";
import { settingsOpModes } from "../step-operations";
import { WireframeOP } from "./wireframe";
import { getCurrentLineMaterial } from "lib/materials";

export abstract class LineBaseOP extends WireframeOP {
  public line: THREE.Line;
  public auxLine: THREE.Line;

  protected points: IPoint[] = [];

  public async start() {
    await super.start();
    this.initializeLine();
  }

  private initializeLine(): void {
    this.line = lineCreate(undefined, undefined, "#ffffff");
    this.auxLine = lineAuxCreate();
    lineAddVertex(this.auxLine, 0, 0, 0);
    lineAddVertex(this.auxLine, 0, 0, 0);
    this.saveToTempScene(this.line);
    this.saveToTempScene(this.auxLine);
  }

  public updateCurrentPlane() {
    // Comportamiento propio de las líneas
    // Guarda el último punto como referencia al cambiar de plano
    this.workingPlane.setCurrPlanePosition(this.lastPoint);
    this.workingPlane.updateCurrentPlane();
  }

  public moveLastPoint(pto: IPoint) {
    if (this.numPoints > 0) {
      lineMoveVertex(this.auxLine, pto.x, pto.y, pto.z, 1);
    }
  }
  public cancelOperation(): void {
    if (!this.finished) {
      this.endOperation();
    }
  }
  public endOperation(): void {
    if (!this.finished) {
      this.save();
      super.endOperation();
    }
  }
}
export class LineOP extends LineBaseOP {

  public opType = cadOpType.LINE;

  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Insert point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
    }]);
  }

  public setLastPoint(): void {
    this.updateCurrentPlane();
    this.points.push(copyIPoint(this.lastPoint));
    const { x, y, z } = this.lastPoint;
    lineAddVertex(this.line, x, y, z);
    lineMoveVertex(this.auxLine, x, y, z, 0);
    if (this.numPoints === 1) {
      lineMoveVertex(this.auxLine, x, y, z);
    }
    if (this.numPoints > 1) this.endOperation();
  }

  public save() {
    if (this.graphicProcessor && this.points.length > 1) {
      const command = new PolyLineCommand(
        { points: this.points, isClosed: false, arcs: [] },
        this.getCurrentSceneId(),
        this.graphicProcessor,
        getCurrentLineMaterial({ lineStyleId: this.lineStyleId })
      );
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
}
