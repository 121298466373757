import { GraphicProcessor } from "lib/graphic-processor";
import { IObjData } from "lib/models/objdata";
import { LayerData } from "../layer-data";

export class LayerChangeVisibility {

  private graphicProcessor: GraphicProcessor;

  constructor(graphicProcessor: GraphicProcessor) {
    this.graphicProcessor = graphicProcessor;
  }

  run(layerId: string, isVisible: boolean) {
    const layerManager = this.graphicProcessor.getLayerManager();
    const layer = layerManager.getLayerDataFromId(layerId);
    if (layer && layer.visible !== isVisible) {
      layer.visible = isVisible;
      layer.threeObject.visible = isVisible;

      const objDatas: IObjData[] = [];
      layerManager.iterAllLayers((layerData: LayerData) => {
        for (const objData of layerData.objDatas) {
          const lyrs = layerManager.getParentLayersDataFromId(objData.layerId);
          if (lyrs) {
            const isNotVisible = lyrs.some(lyr => !lyr.visible);
            objData.isLayerVisible = !isNotVisible;
            if (!isVisible) objDatas.push(objData);
          }
        }
      }, layer.id);

      const selectionManager = this.graphicProcessor.getSelectionManager();
      selectionManager.unSelectObjDatas(objDatas);

      layerManager.layerObserver.dispatchVisibilityLayer(layer, isVisible);
    }
  }
}