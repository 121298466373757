import {
  FEMEvent,
  MeasurementEventAction,
  FEMEventAction,
  ProjectEvent,
  MeasurementEvent,
  OptimizerEvent,
  OptimizerEventAction,
} from "../models/event";

const eventFormats: Record<MeasurementEventAction | FEMEventAction | OptimizerEventAction, string> = {
  CREATED: "Project created",
  PENDING: "Measurement process",
  START: "Measurement process",
  MODEL_PENDING: "Model generation",
  MODEL_START: "Model generation",
  MODEL_DONE: "Model generation",
  MODEL_ERROR: "Model generation error",
  FEATURES_PENDING: "Feature calculation",
  FEATURES_START: "Feature calculation",
  FEATURES_DONE: "Feature calculation",
  FEATURES_ERROR: "Feature calculation",
  STEEL_PENDING: "Steel estimation",
  STEEL_START: "Steel estimation",
  STEEL_DONE: "Steel estimation",
  STEEL_ERROR: "Steel estimation",
  MEASUREMENT_PENDING: "Measurement estimation",
  MEASUREMENT_START: "Measurement estimation",
  MEASUREMENT_DONE: "Measurement estimation",
  MEASUREMENT_ERROR: "Measurement estimation",
  FINISHED: "Measurement process",
  ERROR: "Measurement process",
  FEM_MODEL_PENDING: "FEM Model",
  FEM_MODEL_START: "FEM Model",
  FEM_MODEL_END: "FEM Model",
  FEM_MODEL_ERROR: "FEM Model",
  MACHINE_REQ_PENDING: "Queued process",
  MACHINE_REQ_START: "Queued process",
  MACHINE_REQ_END: "Queued process",
  MESH_PENDING: "Mesh calculation",
  MESH_START: "Mesh calculation",
  MESH_END: "Mesh calculation",
  MESH_ERROR: "Mesh calculation",
  CA_MODEL_PENDING: "Code Aster Model",
  CA_MODEL_START: "Code Aster Model",
  CA_MODEL_END: "Code Aster Model",
  CA_MODEL_ERROR: "Code Aster Model",
  CA_PENDING: "FEM Structure",
  CA_START: "FEM Structure",
  CA_END: "FEM Structure",
  CA_ERROR: "FEM Structure",
  CENTER_PENDING: "Center pending",
  CENTER_START: "Center start",
  CENTER_DONE: "Center done",
  CENTER_ERROR: "Center error"

};

const getEventsByAction = (
  action: string,
  events: ProjectEvent[]
): ProjectEvent[] =>
  events.filter(
    (ev) =>
      ev.action === `${action}_PENDING` ||
      ev.action === `${action}_START` ||
      ev.action === `${action}_END` ||
      ev.action === `${action}_DONE` ||
      ev.action === `${action}_ERROR`
  );

const isPendingEvent = (event: ProjectEvent) =>
  event.action.includes("PENDING");
const isErrorEvent = (event: ProjectEvent) => event.action.includes("ERROR");
const isSuccessEvent = (event: ProjectEvent) =>
  event.action.includes("DONE") ||
  event.action.includes("CREATED") ||
  event.action.includes("_END");

const isStartEvent = (event: ProjectEvent) => event.action.includes("START");

const getLastEvent = (action: string, events: ProjectEvent[]): ProjectEvent => {
  const errorEvent = events.find(isErrorEvent);
  if (errorEvent) {
    return errorEvent;
  }
  const successEvent = events.find(isSuccessEvent);
  if (successEvent) {
    return successEvent;
  }
  const startEvent = events.find(isStartEvent);
  if (startEvent) {
    return startEvent;
  }
  return { action: `${action}_PENDING` } as ProjectEvent;
};

const getCurrentEventByAction = (
  action: string,
  events: ProjectEvent[]
): ProjectEvent => {
  const featureEvents = getEventsByAction(action, events);
  const lastEvent = getLastEvent(action, featureEvents);
  return lastEvent;
};

const getMeasurementEvents = (events: ProjectEvent[]): MeasurementEvent[] => {
  const measurementEvents = events.filter(
    (event) => event.type === "measurements"
  ) as MeasurementEvent[];
  const actions = ["MODEL", "FEATURES", "STEEL", "MEASUREMENT"];
  const lastEventsByAction = actions.map((action) =>
    getCurrentEventByAction(action, measurementEvents)
  );
  return lastEventsByAction as MeasurementEvent[];
};

function getFEMEvents(events: ProjectEvent[]): FEMEvent[] {
  const measurementEvents = events.filter(
    (event) => event.type === "fem"
  ) as MeasurementEvent[];
  const actions = ["FEM_MODEL", "MACHINE_REQ", "MESH", "CA_MODEL", "CA"];
  const lastEventsByAction = actions.map((action) =>
    getCurrentEventByAction(action, measurementEvents)
  );
  return lastEventsByAction as FEMEvent[];
}

function getOptimizerEvents(events: ProjectEvent[]): OptimizerEvent[] {
  const optimizerEvents = events.filter(
    (event) => event.type === "optimizer"
  ) as MeasurementEvent[];
  const actions = ["CENTER"];
  const lastEventsByAction = actions.map((action) =>
    getCurrentEventByAction(action, optimizerEvents)
  );
  return lastEventsByAction as OptimizerEvent[];
}

export {
  eventFormats,
  getMeasurementEvents,
  isPendingEvent,
  isStartEvent,
  isErrorEvent,
  isSuccessEvent,
  getFEMEvents,
  getOptimizerEvents,
};
