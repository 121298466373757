import { SlabEditDataCommand } from "lib/commands/structural/slab";
import { lineCreateIPolylineParam, lineMoveVertex } from "lib/geometries/line";
import { slabParam } from "lib/models-struc/types/slab";
import { SlabData } from "lib/models/structural/slab";
import { SimpleEdition } from "../edition";
import { cadOpType } from "../factory";
import { settingsOpModes } from "../step-operations";
import { userMessageEvents } from "lib/events/user-messages";
import { intersectsItself } from "lib/math/intersections";
import { IPoint } from "lib/math/types";
import { isPointInsidePolygon2DPoints } from "lib/math/polygon";
import { copyIPoint } from '../../math/point';

export class MoveSlabHoleVertexOP extends SimpleEdition {

  public opType = cadOpType.EDITSLABHOLE;

  protected slabData: SlabData;
  protected auxDefinition: slabParam;

  protected holeIndex: number;
  protected indexToEdit: number;
  protected thickness: number;

  constructor(data: SlabData, indexToEdit: number, iniPoint: IPoint, holeIndex: number) {
    super(iniPoint);
    this.indexToEdit = indexToEdit;
    this.holeIndex = holeIndex;
    this.slabData = data;
    this.auxDefinition = this.slabData.cloneDefinition();
  }
  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Edit hole point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
    }]);
  }
  public async start() {
    await super.start();
    const planeManager = this.graphicProcessor.getPlaneManager();
    planeManager.activePlane.position = this.slabData.definition.basePoint;
    planeManager.activePlane.rotation = this.slabData.definition.rotation;
    planeManager.activePlane.locked = true;
    this.thickness = this.slabData.definition.depth;

    const ptos = this.auxDefinition.holes[this.holeIndex].map(p => planeManager.activePlane.getAbsolutePoint(p));
    this.auxLine = lineCreateIPolylineParam({ points: ptos, arcs: [], isClosed: true });

    this.saveToTempScene(this.auxLine);
  }

  public setLastPoint() {
    const planemanager = this.graphicProcessor.getPlaneManager();
    const pto = planemanager.activePlane.getRelativePoint(this.lastPoint);
    this.setHolePoint(pto);
  }
  private setHolePoint(pto: IPoint) {
    const hole = this.auxDefinition.holes[this.holeIndex];
    const oldPto = copyIPoint(hole[this.indexToEdit]);
    hole[this.indexToEdit] = pto;

    const isInSlab = isPointInsidePolygon2DPoints(pto, this.auxDefinition.ptos2D);
    const isInSlabHoles = this.auxDefinition.holes.some(h => (h !== hole && isPointInsidePolygon2DPoints(pto, h)));
    if (!isInSlab || isInSlabHoles) {
      userMessageEvents.dispatchError("Hole point must be inside Slab");
      hole[this.indexToEdit] = oldPto;
      return;
    }
    const holeIntersectItSelf = intersectsItself([...hole, hole[0]]);
    if (holeIntersectItSelf) {
      userMessageEvents.dispatchError("Hole cannot intersect itself");
      hole[this.indexToEdit] = oldPto;
      return;
    }

    hole[this.indexToEdit] = pto;
    this.endOperation();
  }

  public moveLastPoint(pto: IPoint) {
    const { x, y, z } = pto;
    lineMoveVertex(this.auxLine, x, y, z, this.indexToEdit);
    if (this.indexToEdit === 0)
      lineMoveVertex(this.auxLine, x, y, z);
  }

  public save() {
    const command = new SlabEditDataCommand(this.slabData, this.auxDefinition, null, this.graphicProcessor);
    this.graphicProcessor.storeAndExecute(command);
  }
}
