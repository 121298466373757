import EventEmitter from "events";

type logType = 'trace' | 'debug' | 'info' | 'warn' | 'error';

export interface LogEntry {
  level: logType;
  module: string;
  location?: string;
  message: string;
}

export interface ILogger {
  trace(message: string): void;
  debug(message: string): void;
  info(message: string): void;
  warn(message: string): void;
  error(message: string): void;
}

export class Logger implements ILogger {

  private logManager: EventEmitter;
  private minLevel: number;
  private module: string;

  private messageStack: Map<logType, string[]> = new Map();

  private readonly levels: { [key: string]: number } = {
    'trace': 1,
    'debug': 2,
    'info': 3,
    'warn': 4,
    'error': 5
  };

  constructor(logManager: EventEmitter, module: string, minLevel: string) {
    this.logManager = logManager;
    this.module = module;
    this.minLevel = this.levelToInt(minLevel);
  }

  /** Converts a string level (trace/debug/info/warn/error) into a number 
   * 
   * @param minLevel 
   */
  private levelToInt(minLevel: string): number {
    if (minLevel.toLowerCase() in this.levels)
      return this.levels[minLevel.toLowerCase()];
    else
      return 99;
  }

  /** Central logging method.
   * @param logLevel 
   * @param message 
   */
  private log(logLevel: logType, message: string): void {
    const level = this.levelToInt(logLevel);
    if (level < this.minLevel) return;

    const logEntry: LogEntry = { level: logLevel, module: this.module, message };

    // Obtain the line/file through a thoroughly hacky method
    // This creates a new stack trace and pulls the caller from it.  
    if (logLevel === "trace") {
      const error = new Error("");
      if (error.stack) {
        const cla = error.stack.split("\n");
        let idx = 1;
        while (idx < cla.length && cla[idx].includes("at Logger.Object.")) idx++;
        if (idx < cla.length) {
          logEntry.location = cla[idx].slice(cla[idx].indexOf("at ") + 3, cla[idx].length);
        }
      }
    }
    this.logManager.emit('log', logEntry);
  }

  trace(message: string): void {
    this.log('trace', message);
    this.addMessage('trace', message);
  }
  debug(message: string): void {
    this.log('debug', message);
    this.addMessage('debug', message);
  }
  info(message: string): void {
    this.log('info', message);
    this.addMessage('info', message);
  }
  warn(message: string): void {
    this.log('warn', message);
    this.addMessage('warn', message);
  }
  error(message: string): void {
    this.log('error', message);
    this.addMessage('error', message);
  }

  private addMessage(what: logType, message: string): void {
    if (this.messageStack.has(what)) {
      this.messageStack.get(what)!.push(message);
    } else {
      this.messageStack.set(what, [message]);
    }
  }
  getMessageStack(what: logType): string[] | undefined {
    return this.messageStack.get(what);
  }
  clearMessageStack(): void {
    this.messageStack.clear();
  }
}
