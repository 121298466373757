import { gql } from "@apollo/client";

export const CAD_PROJECT_FRAGMENT = gql`
  fragment cadProjectFragment on cad_project {
    id
    name
    content
    updatedAt
    planes
    userId
    snapshot
    cameraCfg
  }
`;

export const GET_CAD_PROJECTS_BY_USER = gql`
  query getCADProjectsByUser {
    projects: cad_project(order_by: { updatedAt: desc }) {
      ...cadProjectFragment
    }
  }
  ${CAD_PROJECT_FRAGMENT}
`;

export const GET_CAD_PROJECT = gql`
  query getCADProject($id: uuid!) {
    project: cad_project_by_pk(id: $id) {
      ...cadProjectFragment
    }
  }
  ${CAD_PROJECT_FRAGMENT}
`;

export const CREATE_CAD_PROJECT = gql`
  mutation createCADProject($object: cad_project_insert_input!) {
    project: insert_cad_project_one(object: $object) {
      ...cadProjectFragment
    }
  }
  ${CAD_PROJECT_FRAGMENT}
`;
export const RENAME_CAD_PROJECT = gql`
  mutation renameCADProject($id: uuid!, $name: String!) {
    project: update_cad_project_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name }
    ) {
      ...cadProjectFragment
    }
  }
  ${CAD_PROJECT_FRAGMENT}
`;

export const SAVE_FILE = gql`
  mutation saveFile(
    $id: uuid!
    $content: jsonb
    $snapshot: String
    $planes: jsonb
    $cameraCfg: jsonb
  ) {
    project: update_cad_project_by_pk(
      pk_columns: { id: $id }
      _set: { content: $content, snapshot: $snapshot, planes: $planes, cameraCfg: $cameraCfg }
    ) {
      ...cadProjectFragment
    }
  }
  ${CAD_PROJECT_FRAGMENT}
`;

export const DELETE_CAD_PROJECT = gql`
  mutation deleteCADProject($id: uuid!) {
    project: delete_cad_project_by_pk(id: $id) {
      id
    }
  }
`;
