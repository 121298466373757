import { dataInfoProperty } from "lib/properties/properties";
import { useState, useCallback, useRef, useEffect } from "react";
import { GraphicProcessor } from '../../../../../lib/graphic-processor';
import { CircleCommand } from "lib/commands/primitives/circle";
import { PolyLineCommand } from "lib/commands/primitives/polyline";
import { circleParam } from "lib/geometries/circle";
import { IPolylineParam } from "lib/math/line";
import { PileCapTemplate } from "lib/models-struc/pile-cap-templates/pile-cap-template";
import { currentPileCapTemplateId, pileCapTemplateCache } from "lib/models-struc/pile-cap-templates/cache";
import { IObjData } from '../../../../../lib/models/objdata';
import { EditActionType, editObjAction } from "lib/events/objectdata";
import { LineData } from "lib/models/primitives/line";
import { copyIPoint } from "lib/math/point";
import { CircleData } from "lib/models/primitives/circle";
import { EventBus } from "lib/events/event-bus";
import { cadOpType } from "lib/operations/factory";
import { useResources } from "modules/struc/services/hooks/use-resources";
import { capDataTable } from "../cap-table";
import { IPoint } from "lib/math/types";
import { pileDataTable } from "../pile-table";
import { userMessageEvents } from "lib/events/user-messages";

const getInfoprop = (style: PileCapTemplate) => {
  const info: dataInfoProperty<PileCapTemplate> = {
    name: {
      type: "string",
      publicName: "Name",
      value: style?.name ?? "PC02",
    },
  }
  return info;
};

export function usePileCapManager(graphicProc: GraphicProcessor) {

  const { updateResource2 } = useResources("pile_cap_templates");

  const capObj = useRef<IObjData>();
  const pilesObjs = useRef<IObjData[]>([]);

  const [pileCapTemplatesLoaded, setPileCapTemplatesLoaded] = useState<PileCapTemplate[]>(() => pileCapTemplateCache.getAllLoadedStyles());
  const [canBeSaved, setCanBeSaved] = useState<boolean>(false);

  const selectedStyle = useRef<PileCapTemplate>(pileCapTemplateCache.loadStylefromCache(currentPileCapTemplateId)!);

  const [styleProperties, setStyleProperties] = useState<dataInfoProperty<PileCapTemplate>>(getInfoprop(selectedStyle.current));

  const drawTemplate = useCallback((template: PileCapTemplate, graphicProc: GraphicProcessor) => {
    EventBus.enableDispatch = false;
    if (capObj.current) {
      graphicProc.removeFromLayer(capObj.current);
    }
    for (const pile of pilesObjs.current) {
      graphicProc.removeFromLayer(pile);
    }

    const layerManager = graphicProc.getLayerManager();
    const { id } = layerManager.getRootLayer();
    capObj.current = undefined;
    if (template.capCoords.length) {
      const line1: IPolylineParam = { points: template.capCoords, isClosed: true, arcs: [] };
      const cmd = new PolyLineCommand(line1, id, graphicProc)
      cmd.execute();
      capObj.current = cmd.createdData;
    }

    pilesObjs.current.length = 0;
    for (const pile of template.pileCoords) {
      const circle: circleParam = { center: pile, radius: 0.5, azimutO: 0, plane: { x: 0, y: 0, z: 0 } };
      const pileCmd = new CircleCommand(circle, id, graphicProc);
      pileCmd.execute();
      pilesObjs.current.push(pileCmd.createdData);
    }

    graphicProc.zoomFitV2();
    EventBus.enableDispatch = true;
  }, [])

  const selectStyle = useCallback((id: string) => {
    if (selectedStyle.current.styleId !== id) {
      const newTemplate = pileCapTemplateCache.loadStylefromCache(id)!;
      selectedStyle.current = newTemplate;

      setStyleProperties({ ...getInfoprop(newTemplate) });

      setCapRows(selectedStyle.current.capCoords.map((p, i) => ({ indx: i + 1, x: p.x.toFixed(3), y: p.y.toFixed(3) })));
      setPileRows(selectedStyle.current.pileCoords.map((p, i) => ({ indx: i + 1, x: p.x.toFixed(3), y: p.y.toFixed(3) })));

      drawTemplate(newTemplate, graphicProc);
      setCanBeSaved(false);
    }
  }, [drawTemplate, graphicProc]);

  // --------------------------------------------------------------------

  const deleteStyle = async () => {
    const idItem = selectedStyle.current.styleId;
    const deleted = pileCapTemplateCache.deleteStyle(idItem);
    if (deleted) {
      selectStyle(pileCapTemplatesLoaded[0].styleId);
      const i = pileCapTemplatesLoaded.findIndex(s => s.styleId === idItem);
      if (i !== -1) pileCapTemplatesLoaded.splice(i, 1);
      setPileCapTemplatesLoaded([...pileCapTemplatesLoaded]);
      setCanBeSaved(false);
      await updateResource2();
    }
  };
  const createStyle = async () => {
    const newStyle = new PileCapTemplate();
    pileCapTemplateCache.saveStyle(newStyle);
    selectStyle(newStyle.styleId);
    pileCapTemplatesLoaded.push(newStyle);
    setPileCapTemplatesLoaded([...pileCapTemplatesLoaded]);
    setCanBeSaved(false);
    await updateResource2();
  };
  const saveChanges = async () => {
    const nameList = pileCapTemplatesLoaded.map(s => s.name)
    if (!nameList.includes(selectedStyle.current.name)) {
      const newDef = selectedStyle.current.clone();
      pileCapTemplateCache.updateStyle(selectedStyle.current.styleId, newDef);
      setPileCapTemplatesLoaded(pileCapTemplateCache.getAllLoadedStyles());
      setCanBeSaved(false);
      await updateResource2();
    } else {
      userMessageEvents.dispatchWarning(`Template with name "${selectedStyle.current.name}" already exists.`);
    }
  };

  // --------------------------------------------------------------------

  const hasChanged = useCallback(() => {
    const original = pileCapTemplateCache.loadStylefromCache(selectedStyle.current.styleId)!;
    const originalJson = JSON.stringify(original);
    const editedJson = JSON.stringify(selectedStyle);
    const hasChanged = originalJson !== editedJson;
    setCanBeSaved(hasChanged);
  }, [])

  const editStyleName = useCallback((style: PileCapTemplate) => {
    // cacheHandler.updateStyle(selectedStyle.current.styleId, { name: style.name });
    selectedStyle.current.name = style.name;
    setStyleProperties({ ...getInfoprop(selectedStyle.current) });
    setPileCapTemplatesLoaded(pileCapTemplateCache.getAllLoadedStyles());
    hasChanged();
  }, [hasChanged]);

  const addContourCap = useCallback((contourData: LineData) => {
    const newContour = contourData.definition.points;
    capObj.current = contourData;
    selectedStyle.current.capCoords = newContour.map(copyIPoint);
    setCapRows(selectedStyle.current.capCoords.map((p, i) => ({ indx: i + 1, x: p.x.toFixed(3), y: p.y.toFixed(3) })));
    hasChanged();
  }, [hasChanged]);

  const addPile = useCallback((pileData: CircleData) => {
    const pile = pileData.definition.center;
    pilesObjs.current.push(pileData);
    selectedStyle.current.pileCoords.push(copyIPoint(pile))
    setPileRows(selectedStyle.current.pileCoords.map((p, i) => ({ indx: i + 1, x: p.x.toFixed(3), y: p.y.toFixed(3) })));
    hasChanged();
  }, [hasChanged])

  const editFromCanvas = useCallback((action: editObjAction) => {
    switch (action.type) {
      case EditActionType.EDIT_OBJ:
        const data = action.payload.objsEdited;
        const newTemplate = pileCapTemplateCache.loadStylefromCache(selectedStyle.current.styleId) as PileCapTemplate;
        if (data[0] === capObj.current) {
          // cap edited
          const capObj = data[0] as LineData;
          newTemplate.capCoords = capObj.definition.points.map(copyIPoint);
          selectedStyle.current = newTemplate;
          pileCapTemplateCache.updateStyle(newTemplate.styleId, newTemplate);
          setCapRows(selectedStyle.current.capCoords.map((p, i) => ({ indx: i + 1, x: p.x.toFixed(3), y: p.y.toFixed(3) })));
        } else {
          // Pile edited
          const pileObj = data[0] as CircleData;
          const indx = pilesObjs.current.indexOf(pileObj)
          newTemplate.pileCoords[indx] = copyIPoint(pileObj.definition.center);
          selectedStyle.current = newTemplate;
          pileCapTemplateCache.updateStyle(newTemplate.styleId, newTemplate);
          setPileRows(selectedStyle.current.pileCoords.map((p, i) => ({ indx: i + 1, x: p.x.toFixed(3), y: p.y.toFixed(3) })));
        }
        break;
      case EditActionType.DELETE_OBJ:
        const datas = action.payload.objsDeleted;
        for (const data of datas) {
          if (data === capObj.current) {
            // cap deleted
            const newTemplate = pileCapTemplateCache.loadStylefromCache(selectedStyle.current.styleId) as PileCapTemplate;
            newTemplate.capCoords = [];
            selectedStyle.current = newTemplate;
            pileCapTemplateCache.updateStyle(newTemplate.styleId, newTemplate);
            setCapRows(selectedStyle.current.capCoords.map((p, i) => ({ indx: i + 1, x: p.x.toFixed(3), y: p.y.toFixed(3) })));
          } else {
            // Pile edited
            const indx = pilesObjs.current.indexOf(data)
            const newTemplate = pileCapTemplateCache.loadStylefromCache(selectedStyle.current.styleId) as PileCapTemplate;
            newTemplate.pileCoords.splice(indx, 1);
            selectedStyle.current = newTemplate;
            pileCapTemplateCache.updateStyle(newTemplate.styleId, newTemplate);
            setPileRows(selectedStyle.current.pileCoords.map((p, i) => ({ indx: i + 1, x: p.x.toFixed(3), y: p.y.toFixed(3) })));
          }
        }
        break;
      default:
        break;
    }
    hasChanged();
  }, [hasChanged]);

  useEffect(() => {
    const dtMdlMngr = graphicProc.getDataModelManager();
    dtMdlMngr.subscribe(editFromCanvas);
    drawTemplate(selectedStyle.current, graphicProc);
    const plneMngr = graphicProc.getPlaneManager();
    plneMngr.mainPlane.gridHelperSettings.size = 10;
    plneMngr.mainPlane.gridHelperSettings.division = 10;
    plneMngr.mainPlane.updatePlaneHelpers();
    plneMngr.addMainPlaneHelperToScene();
    return () => {
      const dtMdlMngr = graphicProc.getDataModelManager();
      dtMdlMngr.unsubscribe(editFromCanvas);
    };
  }, []);

  const [capRows, setCapRows] = useState<capDataTable[]>(() => {
    return selectedStyle.current.capCoords.map((p, i) => ({ indx: i + 1, x: p.x.toFixed(3), y: p.y.toFixed(3) }));
  });
  const updateCapDataGrid = useCallback((rowIndex: number, pto: IPoint) => {
    const capObjData = capObj.current as LineData;
    capObjData.definition.points[rowIndex] = pto;
    capObjData.regenerateObjectFromDefinition();

    const dtMdlMngr = graphicProc.getDataModelManager();
    dtMdlMngr.dispatchEditObjs([capObjData], cadOpType.EDITPOLYLINEVERTEX);
  }, [graphicProc])

  const [pileRows, setPileRows] = useState<pileDataTable[]>(() => {
    return selectedStyle.current.pileCoords.map((p, i) => ({ indx: i + 1, x: p.x.toFixed(3), y: p.y.toFixed(3) }));
  });
  const updatePileDataGrid = (rowIndex: number, pto: IPoint) => {
    const pileObj = pilesObjs.current[rowIndex] as CircleData;
    pileObj.definition.center = pto;
    pileObj.regenerateObjectFromDefinition();

    const dtMdlMngr = graphicProc.getDataModelManager();
    dtMdlMngr.dispatchEditObjs([pileObj], cadOpType.EDITCIRCLE);
  }

  return {
    saveChanges,
    canBeSaved,
    selectStyle,
    deleteStyle,
    createStyle,
    selectedStyle: selectedStyle.current,
    pileCapTemplatesLoaded,
    styleProperties,
    editStyle: editStyleName,
    addPile,
    addContourCap,
    capRows,
    pileRows,
    updateCapData: updateCapDataGrid,
    updatePileData: updatePileDataGrid,
  };
}
