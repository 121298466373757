import { snapSettings, ISnapSettings } from "lib/operations/snap/snap";
import { useCallback, useState } from "react";

export function useSnap() {
  const [isOrthoModeActive, setIsOrthoModeActive] = useState(snapSettings.orto);
  const [snapState, setSnapState] = useState<Record<keyof ISnapSettings, boolean>>(snapSettings);

  const handleChange = useCallback((key: keyof ISnapSettings) => {
    const newValue = !snapSettings[key];
    snapSettings[key] = newValue;
    setSnapState((current) => ({
      ...current,
      [key]: newValue,
    }));
  }, []);

  const toggleOrthoMode = useCallback(() => {
    const newValue = !snapSettings.orto;
    snapSettings.orto = newValue;
    setIsOrthoModeActive(newValue);
  }, []);

  return {
    snapState,
    handleChange,
    isOrthoModeActive,
    toggleOrthoMode,
    snapOptions: Object.entries(snapState).filter(([key]) => key !== "orto"),
    isSnapActive: Object.values(snapState).some((value) => value),
  };
}
