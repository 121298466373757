import * as THREE from "three";
import { getVertexFromIndex } from "lib/geometries/line";
import { getPointOnEllipse } from "lib/math/ellipse";
import { getEllipseCenterAngle, ellipseArcBuffer } from "lib/math/ellipse-arc";
import { rotatePoint } from "lib/math/rotate";
import { IPoint } from "lib/math/types";
import { EllipseArcData } from "lib/models/primitives/ellipse-arc";
import { editEllipseParameters } from "lib/operations/edition/ellipse";
import { cadOpType } from "lib/operations/factory";
import { SelectionManager } from "../selection-manager";
import { ObjDataSelector, markBulkTypes } from "./selector-data";


export class EllipseArcDataSelector extends ObjDataSelector<EllipseArcData> {

  public override buildSelectMarks() {
    const { center, a, b, azimutO, azimutStart, azimutEnd, plane } = this.data.definition;
    let points: IPoint[] = [];
    for (let i = 0; i < 4; i++) {
      const vertex = getPointOnEllipse(center, a, b, i * Math.PI * 0.5, azimutO);
      points.push(rotatePoint(vertex, plane.x, plane.y, plane.z, center));
    }
    // AAxis -> points[0] - points[2];
    // BAxis -> points[1] - points[3];    
    const direction1 = getEllipseCenterAngle(center, a, b, azimutStart);
    const vertex1: IPoint = getPointOnEllipse(center, a, b, direction1, azimutO);
    const vertexStart = rotatePoint(vertex1, plane.x, plane.y, plane.z, center);

    const direction2 = getEllipseCenterAngle(center, a, b, azimutEnd);
    const vertex2: IPoint = getPointOnEllipse(center, a, b, direction2, azimutO);
    const vertexEnd = rotatePoint(vertex2, plane.x, plane.y, plane.z, center);

    const bulkData: markBulkTypes = {};
    bulkData.vertex = [...points, vertexStart, vertexEnd];
    bulkData.center = [center];
    bulkData.line = [ellipseArcBuffer(center, a, b, azimutO, azimutStart, azimutEnd, plane)];
    return bulkData;
  }

  public override launchMarkOP(selectionManager: SelectionManager, mark: THREE.Object3D, vertexIndex: number) {
    if (selectionManager.vertexSelector.pointObj === mark) {
      let param = editEllipseParameters.SEMIMAJORAXIS;
      if (vertexIndex === 1 || vertexIndex === 3) {
        param = editEllipseParameters.SEMIMINORAXIS;
      } else if (vertexIndex === 4) {
        param = editEllipseParameters.STARTANGLE;
      } else if (vertexIndex === 5) {
        param = editEllipseParameters.ENDANGLE;
      }
      return {
        type: cadOpType.EDITELLIPSEARC,
        graphicProcessor: this.graphicProc,
        args: [this.data, param, getVertexFromIndex(mark as THREE.Points, vertexIndex)],
      };
    }
    if (selectionManager.centerSelector.pointObj === mark) {
      return {
        type: cadOpType.MOVE,
        graphicProcessor: this.graphicProc,
        args: [[this.data], this.data.definition.center],
      };
    }
  }
}
