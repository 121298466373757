import { getCenter, updateAngleDimension } from "lib/dimension/angular-dim-builder";
import { lineAngle2p, normalizeAngle } from "lib/math/angles";
import { vectorDist3D } from "lib/math/distance";
import { IPoint } from "lib/math/types";
import { angleDimensionParam } from "lib/models/dimension/angle-dim";
import { ArcDimensionData } from "lib/models/dimension/arc-dim";
import { cadOpType } from "../factory";
import { EditDimensionOP } from "./dimension-base-edit";


export class EditAngleDimensionOP extends EditDimensionOP<angleDimensionParam> {
  public opType = cadOpType.EDITANGLEDIM;

  constructor(threeObj: ArcDimensionData, vertex: number) {
    super(threeObj);
    this.vertexToEdit = vertex;
  }

  public async start() {
    await super.start();
    const lineAO = this.newDimInfo.objRef[0].segment;
    const lineBO = this.newDimInfo.objRef[1].segment;
    const centerO = getCenter(lineAO, lineBO) as IPoint;

    const planeManager = this.graphicProcessor.getPlaneManager();
    planeManager.activePlane.position = centerO;
    planeManager.activePlane.rotation = this.newDimInfo.dimObjPlane;
    planeManager.activePlane.locked = true;
  }

  public moveLastPoint(point: IPoint) {
    const p = this.currPlane.getRelativePoint(point);
    if (this.vertexToEdit !== 0) {
      this.dimStyle.distBaseLine = vectorDist3D({ x: 0, y: 0, z: 0 }, p);
    } else {
      this.newDimInfo.dimDirectionAngle = normalizeAngle(lineAngle2p({ x: 0, y: 0, z: 0 }, p));
    }
    updateAngleDimension(this.objDataAux[0].graphicObj as THREE.Group, this.newDimInfo, this.dimStyle, this.txtStyle);
  }
}