import { useMutation, useQuery } from "@apollo/client";
import { femService } from "modules/lite/services/fem";
import { measurementService } from "modules/lite/services/measurements";
import { optimizerService } from "modules/lite/services/optimizer";
import {
  GET_PROJECT,
  UPDATE_PROJECT_EVENTS,
} from "modules/lite/services/projects";
import { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { storageService } from "shared/services/storage";
import { Project } from "../../../models/project";

interface QueryResult {
  project: Project;
}

interface Params {
  id: string;
}

const useProject = () => {
  const { id } = useParams<Params>();
  const history = useHistory();
  const { data, error, loading } = useQuery<QueryResult>(GET_PROJECT, {
    variables: { id },
    fetchPolicy: "network-only",
    pollInterval: 3000,
  });

  const [mutate] = useMutation(UPDATE_PROJECT_EVENTS);

  const calculateMeasurements = useCallback(() => {
    if (data?.project) {
      measurementService.estimate(data.project);
      mutate({
        variables: {
          id,
          object: { status: "MEASUREMENTS_PENDING" },
          type: "measurements",
        },
      });
    }
  }, [data, mutate, id]);

  const calculateFEMResults = useCallback(() => {
    if (data?.project?.id && data.project.buildingModelUrl) {
      femService.calculate(data.project.id, data.project.buildingModelUrl);
      mutate({
        variables: {
          id,
          object: { status: "FEM_PENDING" },
          type: "fem",
        },
      });
    }
  }, [data, mutate, id]);

const calculateOptimizerResults = useCallback(() => {
    if (data?.project?.id && data.project.buildingModelUrl) {
      optimizerService.center(data.project.id);
      mutate({
        variables: {
          id,
          object: { status: "CENTER_PENDING" },
          type: "optimizer",
        },
      });
    }
  }, [data, mutate, id]);

  const downloadModel = useCallback(async () => {
    const buildingModelUrl = data?.project.buildingModelUrl;
    if (buildingModelUrl) {
      const url = await storageService.get(buildingModelUrl);
      window.location.href = url;
    }
  }, [data]);

  const viewMeasurements = useCallback(() => {
    history.push(`/lite/projects/${data?.project?.id}/measurements`);
  }, [history, data]);
  return {
    project: data?.project,
    error,
    loading,
    calculateMeasurements,
    viewMeasurements,
    downloadModel,
    calculateFEMResults,
    calculateOptimizerResults,
  };
};

export default useProject;
