import { DiameterDimensionEditDataCommand, RadialDimensionEditDataCommand } from "lib/commands/dimension/circular-dim";
import { CircularDimensionData, circularDimensionParam, circularDimType } from "lib/models/dimension/circular-dim";
import { DimensionDataDefinitionHandler, dimensionStyleParam } from "./dimension";

export class CircularDimensionDefinitionHandler extends DimensionDataDefinitionHandler<circularDimensionParam> {

  protected data: CircularDimensionData;

  public saveAndRegenerateStyle = (newStyle: dimensionStyleParam) => {
    const circularDimensionDefinition = this.checkAndChangedStyle(newStyle) as circularDimensionParam;
    if (circularDimensionDefinition) {
      let fnCommand = null;
      if (circularDimensionDefinition.type === circularDimType.RADIUS) {
        fnCommand = RadialDimensionEditDataCommand;
      } else if (circularDimensionDefinition.type === circularDimType.DIAMETER) {
        fnCommand = DiameterDimensionEditDataCommand;
      }
      if (fnCommand) {
        const command = new fnCommand(this.data, circularDimensionDefinition, this.graphicProcessor);
        if (command) this.graphicProcessor.storeAndExecute(command);
      }
    }
  };
}