import { copyIPolylineParam } from "lib/math/line";
import { style } from "lib/styles/style-cache";
import { beamCrossSection, IBeamCrossSection, ICircular, ICustom, IQuadShape, IRectangular, isCircularSection, isCustomSection, ISectionI, ISectionT, ISectionU, isISection, isQuadSection, isRectangularSection, isTSection, isTubeSection, isUSection, ITube, sectionType } from "./types";

export class BeamCrossSection implements style<IBeamCrossSection>, IBeamCrossSection {

  default: boolean = false;
  styleId: string;

  // Default values
  name: string = "default name beam section";
  parameter: beamCrossSection = {
    beamSectionType: sectionType.RECT,
    height: 0.2,
    width: 0.2,
  };

  constructor(opts?: IBeamCrossSection) {
    if (opts !== undefined) {
      this.override(opts);
    }
  }

  override(opt: Partial<IBeamCrossSection>) {
    if (opt) {
      if (opt.name !== undefined) this.name = opt.name;
      if (opt.parameter !== undefined) {
        if (opt.parameter.beamSectionType !== undefined) this.parameter.beamSectionType = opt.parameter.beamSectionType;
        if (isRectangularSection(this.parameter)) {
          const newParams = opt.parameter as IRectangular;
          if (newParams.height !== undefined) this.parameter.height = newParams.height;
          if (newParams.width !== undefined) this.parameter.width = newParams.width;

        } else if (isQuadSection(this.parameter)) {
          const newParams = opt.parameter as IQuadShape;
          if (newParams.height !== undefined) this.parameter.height = newParams.height;
          if (newParams.width !== undefined) this.parameter.width = newParams.width;
          if (newParams.verticalWebThickness !== undefined) this.parameter.verticalWebThickness = newParams.verticalWebThickness;
          if (newParams.horizontalFlangeThickness !== undefined) this.parameter.horizontalFlangeThickness = newParams.horizontalFlangeThickness;

        } else if (isCircularSection(this.parameter)) {
          const newParams = opt.parameter as ICircular;
          if (newParams.radius !== undefined) this.parameter.radius = newParams.radius;

        } else if (isTubeSection(this.parameter)) {
          const newParams = opt.parameter as ITube;
          if (newParams.radius !== undefined) this.parameter.radius = newParams.radius;
          if (newParams.thickness !== undefined) this.parameter.thickness = newParams.thickness;

        } else if (isTSection(this.parameter)) {
          const newParams = opt.parameter as ISectionT;
          if (newParams.height !== undefined) this.parameter.height = newParams.height;
          if (newParams.webThickness !== undefined) this.parameter.webThickness = newParams.webThickness;
          if (newParams.topFlangeLength !== undefined) this.parameter.topFlangeLength = newParams.topFlangeLength;
          if (newParams.topFlangeThickness !== undefined) this.parameter.topFlangeThickness = newParams.topFlangeThickness;

        } else if (isISection(this.parameter)) {
          const newParams = opt.parameter as ISectionI;

          if (newParams.height !== undefined) this.parameter.height = newParams.height;
          if (newParams.webThickness !== undefined) this.parameter.webThickness = newParams.webThickness;
          if (newParams.topFlangeLength !== undefined) this.parameter.topFlangeLength = newParams.topFlangeLength;
          if (newParams.topFlangeThickness !== undefined) this.parameter.topFlangeThickness = newParams.topFlangeThickness;
          if (newParams.bottomFlangeLength !== undefined) this.parameter.bottomFlangeLength = newParams.bottomFlangeLength;
          if (newParams.bottomFlangeThickness !== undefined) this.parameter.bottomFlangeThickness = newParams.bottomFlangeThickness;

        } else if (isUSection(this.parameter)) {
          const newParams = opt.parameter as ISectionU;
          if (newParams.height !== undefined) this.parameter.height = newParams.height;
          if (newParams.width !== undefined) this.parameter.width = newParams.width;
          if (newParams.verticalWebThickness !== undefined) this.parameter.verticalWebThickness = newParams.verticalWebThickness;
          if (newParams.horizontalFlangeThickness !== undefined) this.parameter.horizontalFlangeThickness = newParams.horizontalFlangeThickness;

        } else if (isCustomSection(this.parameter)) {
          const newParams = opt.parameter as ICustom;
          if (newParams.polyline !== undefined) this.parameter.polyline = copyIPolylineParam(newParams.polyline);
        }
      }
    }
  }

  clone(): BeamCrossSection {
    const dolly = new BeamCrossSection(this);
    dolly.default = this.default;
    dolly.styleId = this.styleId;
    return dolly;
  }
}
