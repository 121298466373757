import { EditDimComand } from "lib/commands/dimension/edit-dim";
import { getCustomDimStyleProperties } from "lib/dimension/style";
import { alignedDimensionParam } from "lib/models/dimension/aligned-dim";
import { angleDimensionParam } from "lib/models/dimension/angle-dim";
import { arcDimensionParam } from "lib/models/dimension/arc-dim";
import { circularDimensionParam } from "lib/models/dimension/circular-dim";
import { linealDimensionParam } from "lib/models/dimension/lineal-dim";
import { IObjData } from "lib/models/objdata";
import { settingsOpModes } from "../step-operations";
import { DimensionOP } from "./dimension-base";

export type dimType = alignedDimensionParam | linealDimensionParam | circularDimensionParam | angleDimensionParam | arcDimensionParam;

export abstract class EditDimensionOP<T extends dimType> extends DimensionOP {

  public vertexToEdit: number;
  public newDimInfo: T;

  constructor(threeObjs: IObjData) {
    super(undefined, [threeObjs]);
  }

  protected iniSettingsOp(): void {
    this.settingsOpManager.setCfg([{
      infoMsg: "Edit dimension.",
      stepMode: settingsOpModes.WAITMODE,
      stepFun: this.dispathSaveMouseCoordinates.bind(this),
    }]);
  }

  public async start() {
    await super.start();

    const objDataDim = this.objDataOrigin[0];
    this.newDimInfo = objDataDim.cloneDefinition() as T;
    this.newDimInfo.customStyleProp = {};
    this.dimStyle.override((objDataDim.definition as T).customStyleProp);
    this.dimStyleId = this.dimStyle.styleId;

    this.registerCancel();
    this.registerUpdaters();
    this.registerInputs();
    this.initializeSnap();

    this.setAuxObjs();
    this.objDataAux.forEach(o => this.saveToTempScene(o.graphicObj));
    this.inicializeDimension(this.objDataAux[0].graphicObj as THREE.Group);

    this.graphicProcessor.unSelectObjData(objDataDim);
    objDataDim.visibleGraphicObject = false;
  }

  public setLastPoint(): void {
    this.save();
    this.endOperation();
  }
  public cancelOperation(): void {
    this.objDataOrigin[0].visibleGraphicObject = true;
    super.cancelOperation();
  }
  public endOperation(): void {
    this.objDataOrigin[0].visibleGraphicObject = true;
    super.endOperation();
  }

  public save() {
    this.newDimInfo.customStyleProp = getCustomDimStyleProperties(this.dimStyle, this.defDimStyle);
    this.newDimInfo.customStyleProp.distBaseLine = this.dimStyle.distBaseLine;
    const command = new EditDimComand(this.objDataOrigin[0], this.newDimInfo, this.graphicProcessor);
    if (command) this.graphicProcessor.storeAndExecute(command);
  }
}
