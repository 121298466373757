import * as THREE from "three";
import { getRotationLine } from "lib/geometries/line";
import { calculateCentroidPoints } from "lib/math/centroid";
import { getBufferFromPolylineParam, getMiddlePointArcDefinition, getMiddlePointEdgePolylineFromIndex, IArcLineParam } from "lib/math/line";
import { getMiddlePoint } from "lib/math/point";
import { IPoint } from "lib/math/types";
import { LineData } from "lib/models/primitives/line";
import { cadOpType } from "lib/operations/factory";
import { getEdgeMark } from "../selection-tools";
import { SelectionManager } from "../selection-manager";
import { ObjDataSelector, markBulkTypes } from "./selector-data";


export class LineDataSelector extends ObjDataSelector<LineData> {

  public override buildSelectMarks() {

    const { points, arcs, isClosed } = this.data.definition;
    const bulkData: markBulkTypes = {};
    bulkData.origin = [points[0]];
    bulkData.vertex = points.slice(1);
    if (isClosed) {
      const cntrd = calculateCentroidPoints(points);
      if (cntrd)
        bulkData.centroid = [cntrd];
    }
    bulkData.line = [getBufferFromPolylineParam(this.data.definition)];

    const centers: IPoint[] = [];
    const edgeMarks: THREE.Mesh[] = [];
    for (let i = 0; i < points.length; i++) {
      const p0 = points[i];
      let p1 = points[i + 1];
      const arc = arcs[i];
      if (p1 === undefined && isClosed) {
        p1 = points[0];
      }
      if (p0 && p1) {
        let mark: THREE.Mesh;
        if (arc) {
          centers.push(arc.center);
          const pto = getMiddlePointArcDefinition(p0, arc, p1);
          mark = getEdgeMark(pto);
        } else {
          const pto = getMiddlePoint(p0, p1);
          mark = getEdgeMark(pto);
        }
        const rot = getRotationLine(p0, p1);
        mark.rotation.set(rot.x, rot.y, rot.z);
        edgeMarks.push(mark);
      }
    }
    if (centers.length)
      bulkData.center = centers;
    this.EDGE = edgeMarks;
    return bulkData;
  }

  public override launchMarkOP(selectionManager: SelectionManager, mark: THREE.Object3D, vertexIndex: number) {
    if (selectionManager.vertexSelector.pointObj === mark) {
      return {
        type: cadOpType.EDITPOLYLINEVERTEX,
        graphicProcessor: this.graphicProc,
        args: [this.data, vertexIndex + 1, this.data.definition.points[vertexIndex + 1]],
      };
    }
    if (selectionManager.originSelector.pointObj === mark) {
      return {
        type: cadOpType.EDITPOLYLINEVERTEX,
        graphicProcessor: this.graphicProc,
        args: [this.data, 0, this.data.definition.points[0]],
      };
    }
    if (selectionManager.centerSelector.pointObj === mark) {
      const arcs = this.data.definition.arcs.filter((a) => a !== 0) as IArcLineParam[];
      const center = arcs[vertexIndex].center;
      return {
        type: cadOpType.MOVE,
        graphicProcessor: this.graphicProc,
        args: [[this.data], center],
      };
    }
    if (selectionManager.centroidSelector.pointObj === mark) {
      const centroid = calculateCentroidPoints(this.data.definition.points) as IPoint;
      return {
        type: cadOpType.MOVE,
        graphicProcessor: this.graphicProc,
        args: [[this.data], centroid],
      };
    }
    if (this.EDGE?.some((e) => e === mark)) {
      const index = this.EDGE.indexOf(mark as THREE.Mesh);
      return {
        type: cadOpType.EDITPOLYLINEEDGE,
        graphicProcessor: this.graphicProc,
        args: [
          this.data,
          index,
          getMiddlePointEdgePolylineFromIndex(this.data.definition, index),
        ],
      };
    }
  }
}
