import { rayCastResults } from "lib/coordinates/raycaster";
import { setPosBuffer } from "lib/geometries";
import { circleParam } from "lib/geometries/circle";
import { ellipseParam } from "lib/geometries/ellipse";
import { dashedLineCreateIPoints } from "lib/geometries/line";
import { GraphicProcessor } from "lib/graphic-processor";
import { normalizeAngle, lineAngle2p } from "lib/math/angles";
import { arcParam } from "lib/math/arc";
import { distancePointToLine3D, vectorDist2D, vectorDist3D } from "lib/math/distance";
import { vector3Equals } from "lib/math/epsilon";
import { intersectArcArc, intersectArcLine, intersectCircleCircle, intersectLineCircle, intersectLineEllipse, intersectionEllipseCircle, intersectionEllipseEllipse, intersect3D } from "lib/math/intersections";
import { getEdgePolylineFromIndex, getNearPolylineEdgeIndex, getParalleLine3DFromPoint } from "lib/math/line";
import { getPolarPoint, IpointsToBuffer } from "lib/math/point";
import { polygon } from "lib/math/polygon";
import { IPoint, ISegment, isISegment } from "lib/math/types";
import { isArcData, isCircleData, isEllipseData, isLineData, isPolygonData } from "lib/models/checktools";
import { IObjData } from "lib/models/objdata";
import { definitionType } from "lib/models/types";
import { getCrossMark, getMarkMaterial, markTypes } from "lib/selection/selection-tools";
import { IObjDataHint } from "./hint-calculator";
import { buildHintData } from "./hint-creator";

const snapSize = 5;
const hintStackSize = 5;
const hinthelperSize = 15;
const hinthelperColor = "#ff7700";
export const auxHintColor = "#ffffff";

enum hintType {
  // points snap
  POINTS,
  VERTEX,
  MIDDLE,
  CENTER,
  NEAR,
  QUADRANT,
  INTERSECTION,
  // segments snap
  EXTENSION,
  TANGENT,
  PERPENDICULAR,
  PARALLEL,
}
export interface ISnapSettings {
  orto: boolean;
  near: boolean;
  // ----------------
  points: boolean;
  vertex: boolean;
  middle: boolean;
  center: boolean;
  quadrant: boolean;
  intersection: boolean;
  // ----------------
  extension: boolean;
  tangent: boolean;
  perpendicular: boolean;
  parallel: boolean;
}

export const snapSettings: ISnapSettings = {
  orto: false,
  near: true,
  // ----------------
  points: true,
  vertex: true,
  middle: true,
  center: true,
  quadrant: true,
  intersection: true,
  // ----------------
  extension: true,
  tangent: false,
  perpendicular: false,
  parallel: false,
};

interface hint {
  p: IPoint;
  type: hintType,
  objHint: IObjDataHint,
  dist: number,
}

export class SnapTool {

  private graphicProcessor: GraphicProcessor;

  private snapHintHelper: THREE.Points;

  private lastHint: hint | null = null;

  private hintDataObj: Map<IObjData, IObjDataHint> = new Map();
  private nearPointSnap: IPoint | null;

  private isSnapOperationActive: boolean = false;
  private timeInterval: NodeJS.Timeout | null = null;


  constructor(graphicProcessor: GraphicProcessor) {
    this.graphicProcessor = graphicProcessor;
  }

  initOperationSnap() {
    this.registerCleanEvent();
    const pto = this.graphicProcessor.getMouseCoordinates();
    this.snapHintHelper = getCrossMark(pto, hinthelperColor, hinthelperSize);
    this.saveToTempScene(this.snapHintHelper);
    this.isSnapOperationActive = true;
  }
  stopOperationSnap() {
    for (const hint of this.hintDataObj.values()) {
      for (const h of hint.auxHint) {
        this.deleteFromTempScene(h);
        h.geometry.dispose();
      }
    }
    this.hintDataObj.clear();
    this.lastHint = null;

    if (this.extensionLine) {
      this.deleteFromTempScene(this.extensionLine)
      this.extensionLine.geometry.dispose();
      (this.extensionLine.material as THREE.LineDashedMaterial).dispose();
      this.extensionLine = undefined;
    }
    if (this.snapHintHelper) {
      this.deleteFromTempScene(this.snapHintHelper);
      this.snapHintHelper.geometry.dispose();
    }
    this.unregisterCleanEvent();
    this.isSnapOperationActive = false;
  }

  private cleanEvent = () => {
    for (const hint of this.hintDataObj.values()) {
      for (const h of hint.auxHint) {
        this.deleteFromTempScene(h);
        h.geometry.dispose();
      }
    }
    this.hintDataObj.clear();
    this.lastHint = null;

    if (this.extensionLine) {
      this.extensionLine.visible = false;
    }
  }
  private registerCleanEvent() {
    const container = this.graphicProcessor.container;
    container.addEventListener("wheel", this.cleanEvent);
  }
  private unregisterCleanEvent() {
    const container = this.graphicProcessor.container;
    container.removeEventListener("wheel", this.cleanEvent);
  }
  private getCurrentCameraPosition() {
    const viewPort = this.graphicProcessor.getActiveViewport();
    return (viewPort.camera as THREE.Camera).position;
  }

  filterPoint(prevPto: IPoint, pto: IPoint) {
    if (!this.isSnapOperationActive) return pto;

    let currHint: hint | null = null;
    const camPov = this.getCurrentCameraPosition();

    const checkHints = (points: IPoint[], type: hintType, objHint: IObjDataHint) => {
      for (const p of points) {
        const dist = distancePointToLine3D(camPov, pto, p);
        if (d > dist) {
          currHint = { p, type, objHint, dist };
          d = dist;
        }
      }
    };

    this.updateVisibleDataHints(prevPto);

    const extensionsDetected = [];
    let d = Infinity;
    for (const hintData of this.hintDataObj.values()) {
      if (snapSettings.points) {
        checkHints(hintData.points, hintType.POINTS, hintData);
      }
      if (snapSettings.vertex) {
        checkHints(hintData.vertex, hintType.VERTEX, hintData);
      }
      if (snapSettings.middle) {
        checkHints(hintData.middlePtos, hintType.MIDDLE, hintData);
      }
      if (snapSettings.center) {
        checkHints(hintData.center, hintType.CENTER, hintData);
      }
      if (snapSettings.quadrant) {
        checkHints(hintData.quadrant, hintType.QUADRANT, hintData);
      }
      if (snapSettings.intersection) {
        checkHints(hintData.intersections, hintType.INTERSECTION, hintData);
      }
      if (snapSettings.tangent) {
        checkHints(hintData.tangent, hintType.TANGENT, hintData);
      }
      if (snapSettings.perpendicular) {
        checkHints(hintData.perpendicular, hintType.PERPENDICULAR, hintData);
      }
      if (snapSettings.extension) {
        extensionsDetected.push(hintData.extension);
      }
    }

    if (currHint && d !== Infinity) {
      const { p, type, dist } = currHint;
      const len = this.graphicProcessor.getSizeUnitFromPixelUnit(snapSize, p);
      // const dist = vectorDist3D(p, pto);
      if (len > dist) {
        // Snap found
        // this.checkSnapReference(p, type);
        this.lastHint = currHint;
        const { x, y, z } = p;
        this.snapHintHelper.position.set(x, y, z);
        this.changeHintHelper(type);
        return p;
      }
    }

    // if (this.timeInterval) {
    //   clearInterval(this.timeInterval);
    //   this.timeInterval = null;
    // }

    // Check extension snap
    if (snapSettings.extension && this.lastHint) {
      const extPto = this.checkExtensionSnapReference(pto, extensionsDetected);
      if (extPto) return extPto;
    }

    // Check parallel snap
    if (snapSettings.parallel && this.lastHint) {
      const extPto = this.checkParallelSnapReference(prevPto, pto);
      if (extPto) return extPto;
    }

    // Snap not found
    let hintCurrType;
    // Check near snap
    if (snapSettings.near && this.nearPointSnap) {
      pto = this.nearPointSnap;
      hintCurrType = hintType.NEAR;
    }

    this.changeHintHelper(hintCurrType);
    this.snapHintHelper.position.set(pto.x, pto.y, pto.z);
    this.snapHintHelper.updateMatrix();
    return pto;
  }
  filterPolarAngle(pto0: IPoint, pto1: IPoint, polarAngle: number) {
    const snapToleranceAngle = polarAngle * 0.5;

    const oriDirection = normalizeAngle(lineAngle2p(pto0, pto1));
    // Result in quadrant (between 0-90)
    const diff = oriDirection % polarAngle;

    let anglediff: number;
    if (diff <= snapToleranceAngle) {
      anglediff = -diff;
    } else {
      anglediff = polarAngle - diff;
    }
    let newAngle = normalizeAngle(oriDirection + anglediff);

    const dist = vectorDist2D(pto0, pto1);
    const newDist = dist * Math.cos(anglediff);
    return getPolarPoint(pto0, newAngle, newDist);
  }
  filterOrto(prevPto: IPoint, pto: IPoint) {
    if (!this.isSnapOperationActive) return pto;
    const camPov = this.getCurrentCameraPosition();
    const ptoX = { x: prevPto.x + 10, y: prevPto.y, z: prevPto.z };
    const ptoY = { x: prevPto.x, y: prevPto.y + 10, z: prevPto.z };
    const ptoZ = { x: prevPto.x, y: prevPto.y, z: prevPto.z + 10 };

    const PX = intersect3D(camPov, pto, prevPto, ptoX);
    const PY = intersect3D(camPov, pto, prevPto, ptoY);
    const PZ = intersect3D(camPov, pto, prevPto, ptoZ);
    if (PX && PY && PZ && PX.p0 && PX.p1 && PY.p0 && PY.p1 && PZ.p0 && PZ.p1) {
      const dX = vectorDist3D(PX.p0, PX.p1);
      const dY = vectorDist3D(PY.p0, PY.p1);
      const dZ = vectorDist3D(PZ.p0, PZ.p1);
      if (dX < dY && dX < dZ) {
        return PX.p1;
      }
      if (dY < dX && dY < dZ) {
        return PY.p1;
      }
      if (dZ < dX && dZ < dY) {
        return PZ.p1;
      }
    }
    return pto;
  }


  // -----------------------------------------------------------------

  private extensionLine: THREE.LineSegments | undefined;

  private manageLineExtesion(ptos: IPoint[], len: number) {
    if (this.extensionLine) {
      this.extensionLine.visible = true;
      const buffer = IpointsToBuffer(ptos);
      setPosBuffer(this.extensionLine, buffer);
      const oldMaterial = this.extensionLine.material as THREE.LineDashedMaterial;
      oldMaterial.dashSize = oldMaterial.gapSize = len * 0.5;
      this.extensionLine.computeLineDistances();
    } else {
      this.extensionLine = dashedLineCreateIPoints(ptos, [], 0xffaa00, len * 0.5, len * 0.5, 1) as THREE.LineSegments;
      this.saveToTempScene(this.extensionLine);
    }
  }
  private checkExtensionSnapReference(pto: IPoint, sections: ISegment[][]) {
    if (sections.length) {
      const res: { s: ISegment, p: IPoint, len: number }[] = []
      const camPov = this.getCurrentCameraPosition();
      for (const segments of sections) {
        for (const s of segments) {
          const { p1, p2 } = s;
          const f = intersect3D(camPov, pto, p1, p2);
          if (f && f.p0 && f.p1) {
            const dist = vectorDist3D(f.p0, f.p1);
            const snapPto = f.p1;
            const len = this.graphicProcessor.getSizeUnitFromPixelUnit(snapSize, snapPto);
            if (len > dist) {
              // Snap found
              res.push({ s, p: snapPto, len });
            }
          }
        }
      }
      if (res.length === 1) {
        this.snapHintHelper.position.set(res[0].p.x, res[0].p.y, res[0].p.z);
        this.changeHintHelper(hintType.EXTENSION);
        this.manageLineExtesion([res[0].p, res[0].s.p1], res[0].len * 0.5);
        return res[0].p;
      }

      if (res.length > 1 && snapSettings.intersection) {
        let j = 0;
        for (let i = 0; i < res.length; i++) {
          j = i + 1;
          for (j; j < res.length; j++) {
            const s0 = res[i];
            const s1 = res[j];
            const c = intersect3D(s0.s.p1, s0.s.p2, s1.s.p1, s1.s.p2, false);
            if (c && c.p0) {
              this.snapHintHelper.position.set(c.p0.x, c.p0.y, c.p0.z);
              this.changeHintHelper(hintType.INTERSECTION);
              this.manageLineExtesion([c.p0, res[0].s.p1, c.p0, res[1].s.p1], res[0].len * 0.5);
              return c.p0;
            }
          }
        }
      }
    }
    if (this.extensionLine) {
      this.extensionLine.visible = false;
    }
  }
  private checkParallelSnapReference(prevPto: IPoint, pto: IPoint) {
    const hintData = this.lastHint!.objHint;
    if (hintData.extension.length) {
      const camPov = this.getCurrentCameraPosition();
      for (const segment of hintData.extension) {
        const parallelSegment = getParalleLine3DFromPoint(segment, prevPto);
        const f = intersect3D(camPov, pto, parallelSegment.p1, parallelSegment.p2);
        if (f && f.p0 && f.p1) {
          const dist = vectorDist3D(f.p0, f.p1);
          const snapPto = f.p1;
          const len = this.graphicProcessor.getSizeUnitFromPixelUnit(snapSize, snapPto);
          if (len > dist) {
            // Snap found
            this.snapHintHelper.position.set(snapPto.x, snapPto.y, snapPto.z);
            this.changeHintHelper(hintType.PARALLEL);
            this.manageLineExtesion([snapPto, prevPto], len * 0.5);
            return snapPto;
          }
        }
      }
    }
    if (this.extensionLine) {
      this.extensionLine.visible = false;
    }
  }

  // -----------------------------------------------------------------

  private checkSnapReference(p: IPoint, type: hintType) {
    if (this.lastHint) {
      if (this.lastHint.type === type && vector3Equals(this.lastHint.p, p)) {
        if (this.timeInterval === null) {
          this.timeInterval = setInterval(() => {
            this.addReferenceHint();
            clearInterval(this.timeInterval as NodeJS.Timeout);
            this.timeInterval = null;
          }, 1000);
        }
      } else {
        if (this.timeInterval) {
          clearInterval(this.timeInterval);
          this.timeInterval = null;
        }
      }
    }
  }

  private refObjMark: Map<IObjDataHint, THREE.Points> = new Map();

  private addReferenceHint() {
    if (this.lastHint) {
      const s = getCrossMark(this.lastHint.p, auxHintColor);
      this.refObjMark.set(this.lastHint.objHint, s);
      this.saveToTempScene(s);
    }
  }

  // -----------------------------------------------------------------

  private updateVisibleDataHints(prevPto: IPoint) {
    // Calculate hints
    const intersections0 = this.graphicProcessor.getRayCastObjects();
    if (intersections0.length > 0) {
      const intersections = [intersections0[0]];
      if (intersections0.length > 1) {
        intersections.push(intersections0[1]);
      }
      // Order by Objects
      const dataInters: Map<IObjData, rayCastResults[]> = new Map();
      for (const res of intersections) {
        const ints = dataInters.get(res.dataObject);
        if (ints) {
          ints.push(res);
        } else {
          dataInters.set(res.dataObject, [res]);
        }
      }

      // Calculate Snaps
      let inters: { h: IObjDataHint; d: IObjData; p: THREE.Intersection; section?: any; }[] = [];
      for (const [data, intersection] of dataInters) {
        let hintData = this.hintDataObj.get(data);
        if (hintData === undefined) {
          hintData = buildHintData(data, intersection, prevPto) as IObjDataHint;
          if (hintData) {
            this.registerHint(data, hintData);
          }
        } else {
          hintData.recalculate(data, intersection, prevPto);
        }
        inters.push({
          h: hintData!,
          d: data,
          p: intersection[0],
        });
      }

      // Update nearSnap value (near point intersection)
      this.nearPointSnap = intersections[0].point;

      // Snap Intersections several Objects
      if (dataInters.size > 1 && snapSettings.intersection) {
        this.resolveIntersections(inters);
      }

    } else {
      this.nearPointSnap = null;
    }
  }

  // -----------------------------------------------------------------

  private intersectionCached: [IObjData, IObjData][] = [];
  private addIntersection(dataA: IObjData, dataB: IObjData) {
    this.intersectionCached.push([dataA, dataB]);
    this.intersectionCached.push([dataB, dataA]);
  }
  private isIntersectionCalculated(dataA: IObjData, dataB: IObjData) {
    if (this.intersectionCached.some((i) => i[0] === dataA && i[1] === dataB)) {
      return true;
    }
    if (this.intersectionCached.some((i) => i[1] === dataA && i[0] === dataB)) {
      return true;
    }
    return false;
  }
  private removeIntersections(dataA: IObjData) {
    for (let i = this.intersectionCached.length - 1; i >= 0; i--) {
      const res = this.intersectionCached[i];
      if (res[0] === dataA || res[1] === dataA) {
        this.intersectionCached.splice(i, 1);
      }
    }
  }
  private resolveIntersections(
    intersections: {
      h: IObjDataHint;
      d: IObjData;
      p: THREE.Intersection;
      section?: ISegment | definitionType;
    }[]
  ) {
    for (const intersec of intersections) {
      const { d, p } = intersec;
      if (isLineData(d)) {
        const indx = getNearPolylineEdgeIndex(d.definition, p.point);
        intersec.section = getEdgePolylineFromIndex(d.definition, indx);
      } else if (isPolygonData(d)) {
        const {
          center,
          radius,
          sides,
          inscribed,
          angleO,
          plane,
        } = d.definition;
        const points = polygon(
          center,
          radius,
          sides,
          inscribed,
          angleO,
          plane
        );
        const indx = p.index as number;
        const p1 = points[indx];
        const p2 = points[indx + 1] ?? points[0];
        intersec.section = { p1, p2 };
      } else {
        intersec.section = d.definition;
      }
    }

    let j = 0;
    for (let i = 0; i < intersections.length; i++) {
      const currData = intersections[i].d;
      const currSection = intersections[i].section;
      j = i + 1;
      for (j; j < intersections.length; j++) {
        const data = intersections[j].d;
        const section = intersections[j].section;
        if (this.isIntersectionCalculated(currData, data)) {
          continue;
        }
        if (isISegment(currSection)) {
          if (isISegment(section)) {
            const c = intersect3D(
              currSection.p1,
              currSection.p2,
              section.p1,
              section.p2,
              true
            );
            if (c && c.p0) {
              intersections[i].h.intersections.push(c.p0);
              this.addIntersection(currData, data);
            }
          } else if (isArcData(data)) {
            const c = intersectArcLine(
              currSection.p1,
              currSection.p2,
              section as arcParam,
              true,
              true
            );
            if (c.length) {
              intersections[i].h.intersections.push(...c);
              this.addIntersection(currData, data);
            }
          } else if (isCircleData(data)) {
            const c = intersectLineCircle(
              currSection.p1,
              currSection.p2,
              section as circleParam,
              true
            );
            if (c.length) {
              intersections[i].h.intersections.push(...c);
              this.addIntersection(currData, data);
            }
          } else if (isEllipseData(data)) {
            const c = intersectLineEllipse(
              currSection.p1,
              currSection.p2,
              section as ellipseParam,
              true
            );
            if (c.length) {
              intersections[i].h.intersections.push(...c);
              this.addIntersection(currData, data);
            }
          }
        } else if (isArcData(currData) || isLineData(currData)) {
          if (isISegment(section)) {
            const c = intersectArcLine(
              section.p1,
              section.p2,
              currSection as arcParam,
              true,
              true
            );
            if (c.length) {
              intersections[i].h.intersections.push(...c);
              this.addIntersection(currData, data);
            }
          } else if (isArcData(data)) {
            const c = intersectArcArc(
              section as arcParam,
              currSection as arcParam,
              true,
              true
            );
            if (c.length) {
              intersections[i].h.intersections.push(...c);
              this.addIntersection(currData, data);
            }
          } else if (isCircleData(data)) {
            // const c = intersectCircleArc(section as circleParam, currSection as arcParam, true);
            // if (c.length) {
            //   intersections[i].h.intersections.push(...c);
            // }
          }
        } else if (isCircleData(currData)) {
          if (isISegment(section)) {
            const c = intersectLineCircle(
              section.p1,
              section.p2,
              currSection as circleParam,
              true
            );
            if (c.length) {
              intersections[i].h.intersections.push(...c);
              this.addIntersection(currData, data);
            }
          } else if (isArcData(data)) {
            // const c = intersectCircleArc(currSection as circleParam, section as arcParam, true);
            // if (c.length) {
            //   intersections[i].h.intersections.push(...c);
            // }
          } else if (isCircleData(data)) {
            const c = intersectCircleCircle(
              section as circleParam,
              currSection as circleParam
            );
            if (c.length) {
              intersections[i].h.intersections.push(...c);
              this.addIntersection(currData, data);
            }
          } else if (isEllipseData(data)) {
            const c = intersectionEllipseCircle(section as ellipseParam, currSection as circleParam);
            if (c.length) {
              intersections[i].h.intersections.push(...c);
              this.addIntersection(currData, data);
            }
          }
        } else if (isEllipseData(currData)) {
          if (isISegment(section)) {
            const c = intersectLineEllipse(
              section.p1,
              section.p2,
              currSection as ellipseParam,
              true
            );
            if (c.length) {
              intersections[i].h.intersections.push(...c);
              this.addIntersection(currData, data);
            }
          } else if (isEllipseData(data)) {
            const c = intersectionEllipseEllipse(section as ellipseParam, currSection as ellipseParam);
            if (c.length) {
              intersections[i].h.intersections.push(...c);
              this.addIntersection(currData, data);
            }
          } else if (isCircleData(data)) {
            const c = intersectionEllipseCircle(currSection as ellipseParam, section as circleParam);
            if (c.length) {
              intersections[i].h.intersections.push(...c);
              this.addIntersection(currData, data);
            }
          }

        }
      }
    }
  }

  // -----------------------------------------------------------------

  private registerHint(data: IObjData, hint: IObjDataHint) {
    if (this.hintDataObj.size > hintStackSize) {
      // Clear first element
      for (const [data, hint] of this.hintDataObj) {
        for (const h of hint.auxHint) {
          this.deleteFromTempScene(h);
        }
        this.hintDataObj.delete(data);
        this.removeIntersections(data);
        break;
      }
    }
    // Add new hint element
    this.hintDataObj.set(data, hint);
    for (const h of hint.auxHint) {
      this.saveToTempScene(h);
    }
  }
  private changeHintHelper(hint?: hintType) {
    let markType = markTypes.CROSS;
    if (hint === hintType.POINTS) {
      markType = markTypes.SQUAREVOID;
    } else if (hint === hintType.VERTEX) {
      markType = markTypes.SQUAREVOID;
    } else if (hint === hintType.MIDDLE) {
      markType = markTypes.TRIANGLEVOID;
    } else if (hint === hintType.CENTER) {
      markType = markTypes.CIRCLEVOID;
    } else if (hint === hintType.QUADRANT) {
      markType = markTypes.DIAMONDVOID;
    } else if (hint === hintType.INTERSECTION) {
      markType = markTypes.X;
    } else if (hint === hintType.EXTENSION) {
      markType = markTypes.X;
    } else if (hint === hintType.NEAR) {
      markType = markTypes.NEARHINT;
    } else if (hint === hintType.TANGENT) {
      markType = markTypes.TANGENT;
    } else if (hint === hintType.PERPENDICULAR) {
      markType = markTypes.PERPENDICULAR;
    } else if (hint === hintType.PARALLEL) {
      markType = markTypes.PARALLEL;
    }
    const mat = getMarkMaterial(markType, hinthelperSize, hinthelperColor);
    this.snapHintHelper.material = mat;
  }
  private saveToTempScene(threeObj: THREE.Object3D): void {
    const scene = this.graphicProcessor.getAuxScene();
    if (scene) {
      scene.add(threeObj);
    }
  }
  private deleteFromTempScene(threeObj: THREE.Object3D): void {
    const scene = this.graphicProcessor.getAuxScene();
    if (scene) {
      scene.remove(threeObj);
    }
  }
}
