
import { getBoundingBoxPoints } from "lib/math/box";
import { vector3Equals } from "lib/math/epsilon";
import { IPoint } from "lib/math/types";
import { geomType, LineSegment, Rectangle, WireGeometry } from "modules/struc/models/ecore/geometry";
import { geometryUriModel } from "modules/struc/models/ecore/uri";

export class EcoreGeometryImporter {

  parseGeometryToIPoints(ecoreElement: geomType): IPoint[] {
    switch (ecoreElement.eClass) {
      case `${geometryUriModel}WireGeometry` as const:
        return this.parseWireGeometry(ecoreElement);
      case `${geometryUriModel}Rectangle` as const:
        return this.parseRectangleGeometry(ecoreElement);
      default:
        console.assert(false, "[Ecore Importer] EcoreElement not implemented");
        throw new Error("[Ecore Importer] EcoreElement not implemented");
    }
  }

  private parseRectangleGeometry(geomRect: Rectangle): IPoint[] {
    const { p0, p1 } = geomRect;
    const pto0 = { x: p0.x ?? 0, y: p0.y ?? 0, z: p0.z ?? 0 };
    const pto1 = { x: p1.x ?? 0, y: p1.y ?? 0, z: p1.z ?? 0 };
    const bbx = getBoundingBoxPoints([pto0, pto1]);
    return [
      bbx.min,
      { x: bbx.min.x, y: bbx.max.y, z: bbx.min.z },
      bbx.max,
      { x: bbx.max.x, y: bbx.min.y, z: bbx.min.z },
    ];
  }

  private parseWireGeometry(geomWire: WireGeometry): IPoint[] {
    const points: IPoint[] = [];
    const lines = geomWire.geometries as LineSegment[];
    for (const line of lines) {
      const { p0 } = line;
      const pto0 = { x: p0.x ?? 0, y: p0.y ?? 0, z: p0.z ?? 0 };
      points.push(pto0);
    }
    const { p1 } = lines[lines.length - 1];
    const pto1 = { x: p1.x ?? 0, y: p1.y ?? 0, z: p1.z ?? 0 };
    if (!vector3Equals(pto1, points[0])) {
      points.push(pto1);
    }
    return points;
  }
}

export function wireGeometry2Ipoints(wireGeom: WireGeometry, z: number = 0) {
  const points: IPoint[] = [];
  const geom = wireGeom.geometries as LineSegment[];
  for (const line of geom) {
    const { p0, p1 } = line;
    const pto0 = { x: p0.x ?? 0, y: p0.y ?? 0, z: p0.z ? p0.z : z };
    const pto1 = { x: p1.x ?? 0, y: p1.y ?? 0, z: p1.z ? p1.z : z };
    points.push(pto0);
    points.push(pto1);
  }
  return points;
}