import { copyIPoint } from "lib/math/point";
import { cadOpType } from "lib/operations/factory";
import { PlaneDefinition } from "lib/coordinates/plane";
import { useState, useCallback } from "react";
import { useMainGraphicContext } from "../../viewport/context";

let planeIndx = -1;

export function usePlaneList(onClose: () => void) {

  const graphicProcessor = useMainGraphicContext();
  const { userPlanes } = graphicProcessor.getPlaneManager();
  const [selectedPlane, setSelectedPlane] = useState<PlaneDefinition | null>(null);
  const [activePlanes, setActivePlanes] = useState(userPlanes.filter(p => p.active).length);

  const createPlaneFromThreePoints = useCallback(() => {
    onClose();
    graphicProcessor.launchOP(cadOpType.WRKPLANE3P);
  }, [graphicProcessor, onClose]);

  const createPlaneFromVector = useCallback(() => {
    onClose();
    graphicProcessor.launchOP(cadOpType.WRKPLANEVECTOR);
  }, [graphicProcessor, onClose]);

  const createUserPlane = useCallback(() => {
    const plneManager = graphicProcessor.getPlaneManager();
    const plane = plneManager.addUserPlane();
    setSelectedPlane(plane.clone());
  }, [graphicProcessor]);
  const duplicateUserPlane = useCallback(() => {
    if (selectedPlane) {
      const plneManager = graphicProcessor.getPlaneManager();
      const plane = plneManager.addUserPlane(selectedPlane.position, selectedPlane.rotation);
      setSelectedPlane(plane.clone());
    }
  }, [graphicProcessor, selectedPlane]);
  const deleteUserPlane = useCallback(() => {
    if (planeIndx >= 0) {
      const plneManager = graphicProcessor.getPlaneManager();
      plneManager.deleteUserPlane(userPlanes[planeIndx]);
      setSelectedPlane(null);
    }

  }, [graphicProcessor, userPlanes]);

  const toogleVisibility = useCallback((plane: PlaneDefinition) => {
    plane.active = !plane.active;
    if (plane.active) {
      plane.showPlaneHelpers();
    } else {
      plane.hidePlaneHelpers();
    }
    setActivePlanes(userPlanes.filter(p => p.active).length);
  }, [userPlanes]);

  const changeCurrPlane = useCallback((plane: PlaneDefinition, indx: number) => {
    planeIndx = indx;
    setSelectedPlane(plane.clone());
  }, []);

  const editCurrPlaneGeom = useCallback(() => {
    if (selectedPlane) {
      if (planeIndx === -1) {
        // Main plane is NOT editable
      } else {
        const currPlane = userPlanes[planeIndx];
        currPlane.position = copyIPoint(selectedPlane.position);
        currPlane.rotation = copyIPoint(selectedPlane.rotation);
        currPlane.updatePlaneHelpersGeom();
        setSelectedPlane(currPlane.clone());
      }
    }
  }, [selectedPlane, userPlanes]);
  const editCurrPlane = useCallback(() => {
    if (selectedPlane) {
      if (planeIndx === -1) {
        // Main plane is NOT editable
      } else {
        const currPlane = userPlanes[planeIndx];
        currPlane.gridHelperSettings.size = selectedPlane.gridHelperSettings.size;
        currPlane.gridHelperSettings.division = selectedPlane.gridHelperSettings.division;
        currPlane.gridHelperSettings.colorCenterLine = selectedPlane.gridHelperSettings.colorCenterLine;
        currPlane.gridHelperSettings.colorGrid = selectedPlane.gridHelperSettings.colorGrid;
        currPlane.updatePlaneHelpers();
        setSelectedPlane(currPlane.clone());
      }
    }
  }, [selectedPlane, userPlanes]);


  return {
    userPlanes,
    selectedPlane,
    activePlanes,
    createUserPlane,
    duplicateUserPlane,
    deleteUserPlane,
    createPlaneFromThreePoints,
    createPlaneFromVector,
    changeCurrPlane,
    toogleVisibility,
    editCurrPlaneGeom,
    editCurrPlane,
  };
}