import { IPoint } from "lib/math/types";

export enum MousePositionActionType {
  CHANGE_MOUSE_COORDINATES,
  SAVE_MOUSE_COORDINATES,
}

export type MouseCoordinatesAction = {
  type: MousePositionActionType.CHANGE_MOUSE_COORDINATES;
  payload: {
    point: IPoint;
  };
} | {
  type: MousePositionActionType.SAVE_MOUSE_COORDINATES;
  payload: {
    point: IPoint;
  };
}
