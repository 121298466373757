import styled from "styled-components";
import cn from "classnames";

import CloseIcon from "modules/cad/components/icons/close";
import { SecondaryButton } from "shared/components/ui/button";
import { storeyDriftRepo } from "lib/models-struc/storey-drift/storey-drift-repo";
import { useState, useEffect, useRef } from "react";
import LoadingDots from "shared/components/ui/loading-dots";
import { useMainGraphicContext } from "modules/cad/components/viewport/context";
import Table from "shared/components/ui/table/table";
import { Column } from "react-table";

type ViewState = (
  { status: 'loading', loadingSince: Date } |
  { status: 'done', data: string[][] } |
  { status: 'error', error: Error }
)
interface Props {
  data: { name: string },
  onClose: () => void,
}

const columns: Column<{}>[] = [
  { Header: "Storey", accessor: "Storey" },
  { Header: "Slab", accessor: "Slab" },
  { Header: "Sx", accessor: "Sx" },
  { Header: "Sy", accessor: "Sy" },
  { Header: "Sxy", accessor: "Sxy" },
  { Header: "Sx/level", accessor: "SxLevel" },
  { Header: "Sy/level", accessor: "SyLevel" },
  { Header: "Sxy/level", accessor: "SxyLevel" },
  { Header: "Sx/height", accessor: "SxHeight" },
  { Header: "Sy/height", accessor: "SyHeight" },
  { Header: "Sxy/height", accessor: "SxyHeight" },
];

function formatData(data: any[][]) {
  return data.map((storeyData: any[]) => {
    return {
      Storey: storeyData[0],
      Slab: storeyData[1],
      Sx: storeyData[2].toFixed(4),
      Sy: storeyData[3].toFixed(4),
      Sxy: storeyData[4].toFixed(4),
      SxLevel: `1/${(1 / storeyData[5]).toFixed(0)}`,
      SyLevel: `1/${(1 / storeyData[6]).toFixed(0)}`,
      SxyLevel: `1/${(1 / storeyData[7]).toFixed(0)}`,
      SxHeight: `1/${(1 / storeyData[8]).toFixed(0)}`,
      SyHeight: `1/${(1 / storeyData[9]).toFixed(0)}`,
      SxyHeight: `1/${(1 / storeyData[10]).toFixed(0)}`
    }
  });
}

const StoreyDriftInfo = ({ onClose, data }: Props) => {

  const mainGraphicProc = useMainGraphicContext();

  const allTableData = useRef<any[][]>([])
  const currTableData = useRef<any[]>([])

  const [viewState, setViewState] = useState<ViewState>({
    status: 'loading',
    loadingSince: new Date()
  })

  useEffect(() => {
    setViewState({ status: 'loading', loadingSince: new Date() })
    storeyDriftRepo.getCombinationsFiles(data.name).then(fileUrls => {
      const datas = fileUrls.map(f => f[1]);

      const str3D = mainGraphicProc.getStructModel3D();
      allTableData.current = str3D?.calculateStoreyDriftTable(datas)!;
      currTableData.current = formatData(allTableData.current[0]);

      setViewState({ status: 'done', data: fileUrls })
      setCurrTab(fileUrls[0][0]);
    }).catch((error: Error) => {
      console.error(error);
      setViewState({ status: 'error', error })
    })
  }, [])



  const [currTab, setCurrTab] = useState<string>("")

  const rootClassName = (newTab: string) =>
    cn("font-medium focus:outline-none hover:text-gray-300",
      newTab === currTab ? "text-gray-100" : "text-gray-400");

  return (
    <Wrapper className="text-xs">
      <Header>
        <h1>{`Storey drift visualization ${data.name}`}</h1>
        <button onClick={onClose}>
          <CloseIcon className="h-4 w-4" />
        </button>
      </Header>
      <Content>
        <ActionBar0 className="layers">
          <div className="h-full flex flex-col bg-gray-600">
            {viewState.status === "loading" &&
              <div className="bg-gray-800 flex justify-center h-full w-full z-50">
                <LoadingDots />
              </div>
            }
            {viewState.status === "error" && <>{viewState.error.stack}</>}
            {viewState.status === "done" &&
              <>
                <div className="p-2 flex gap-4">
                  {viewState.data.map((d) =>
                    <button
                      key={d[0]}
                      className={rootClassName(d[0])}
                      onClick={() => {
                        setCurrTab(d[0])
                        const currData = allTableData.current[viewState.data.findIndex(dt => dt[0] === d[0])]
                        currTableData.current = formatData(currData);
                      }}
                    >
                      {d[0]}
                    </button>
                  )}
                </div>
                <div className="flex-auto bg-gray-700 overflow-y-auto p-2">
                  <Table
                    columns={columns}
                    data={currTableData.current}
                  />
                </div>
              </>
            }
          </div>
        </ActionBar0>

        <BottomToolBar>
          <SecondaryButton onClick={onClose}>
            Cancel
          </SecondaryButton>
          <SecondaryButton onClick={onClose}>
            Accept
          </SecondaryButton>
        </BottomToolBar>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  overflow: hidden;
  border-radius: 4px;
  width: 850px;
  margin: -32px;
  background-color: var(--color-gray-300);
`;
const Header = styled.div`
  background-color: var(--color-gray-300);
  border-bottom: 2px solid var(--color-primary);
  padding: 8px;
  display: flex;
  justify-content: space-between;
`;
const Content = styled.div`
  display: grid;
  grid-template-rows: 400px 36px;
  grid-template-columns: auto;
  gap: 4px;
  padding: 8px;
  height: 100%;
`;
const Box = styled.div`
  background-color: var(--color-gray-200);
  padding: 8px;
  border-radius: 4px;
  overflow: hidden;
`;
const ActionBar0 = styled(Box)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  width: 100%;
`;
const BottomToolBar = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
export default StoreyDriftInfo;