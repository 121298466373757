import { useGetStrucProject } from "modules/struc/components/project/hook/use-project";
import { useHistory, useLocation } from "react-router";
import { useParams } from "react-router-dom";
import { useAuth } from "shared/components/auth/context";
import HomeIcon from "../icons/home";
import { useFiles } from "./context";
import OpenFileTab from "./open-file-tab";

const FileToolbar = () => {
  const { openFiles, openFile, currentFile, closeFile } = useFiles();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const { user } = useAuth();
  //const { project } = useProject(id);
  const { projectData } = useGetStrucProject(user!.id, id);

  const { pathname } = useLocation();
  const isStrucModule = pathname.includes("struc");
  const currentRoute = isStrucModule ? "/struc/projects" : "/cad/projects";

  return (
    <>
      <OpenFileTab onSelect={() => history.push(currentRoute)}>
        <HomeIcon className="h-5 w-5" />
      </OpenFileTab>

      {isStrucModule ?
        <OpenFileTab
          onSelect={() => { }}>
          {projectData?.Name}
        </OpenFileTab>
        :
        openFiles.map((file) => (
          <OpenFileTab
            key={file.id}
            onSelect={() => openFile(file)}
            onClose={() => closeFile(file.id)}
            isSelected={currentFile?.id === file.id}
          >
            {file.name}
          </OpenFileTab>
        ))
      }
    </>
  );
};

export default FileToolbar;
