import { dimensionCache } from "lib/dimension/cache";
import { isDimensionData, isTextData } from "lib/models/checktools";
import { IObjData } from "lib/models/objdata";
import { textStyleCache } from "lib/text/cache";
import * as THREE from "three";
import { ghost } from "../styles/colors";
import { ILineMaterial } from "./line";

export function getAuxMaterialLine(): THREE.LineBasicMaterial {
  return new THREE.LineBasicMaterial({
    color: ghost,
    transparent: true,
    opacity: 0.7,
  });
}
export function getAuxMaterialPoint(): THREE.PointsMaterial {
  return new THREE.PointsMaterial({
    color: ghost,
    transparent: true,
    opacity: 0.7,
    size: 3,
    sizeAttenuation: false,
  });
}
export function getAuxMeshBasicMaterial(): THREE.MeshBasicMaterial {
  const material = new THREE.MeshBasicMaterial(
    /*{ map: texture }*/ {
      color: ghost,
      wireframe: false,
      transparent: true,
      opacity: 0.4,
      side: THREE.DoubleSide,
    }
  );
  return material;
}
export function getAuxWireFrameMaterial(): THREE.MeshBasicMaterial {
  const material = new THREE.MeshBasicMaterial(
    /*{ map: texture }*/ {
      color: ghost,
      wireframe: true,
      transparent: true,
      opacity: 0.4,
      side: THREE.DoubleSide,
    }
  );
  return material;
}
export function getAuxPhongMaterial(): THREE.MeshPhongMaterial {
  const material = new THREE.MeshPhongMaterial({
    color: ghost,
    transparent: true,
    opacity: 0.4,
    side: THREE.DoubleSide,
  });
  return material;
}

export function setAuxMaterial(threeObj: THREE.Object3D) {
  if (threeObj instanceof THREE.Points) {
    threeObj.material = getAuxMaterialPoint();
    threeObj.material.needsUpdate = true;
  } else if (threeObj instanceof THREE.Line) {
    threeObj.material = getAuxMaterialLine();
    threeObj.material.needsUpdate = true;
  } else if (threeObj.type === "Line2") {
    // TODO
  } else if (threeObj instanceof THREE.Mesh) {
    threeObj.material = getAuxMeshBasicMaterial();
    threeObj.material.needsUpdate = true;
  }
}

export function getLineMaterial(colorVal: string | number): THREE.LineBasicMaterial {
  return new THREE.LineBasicMaterial({
    color: colorVal
  });
}
export function getMaterial(colorVal: string | number): THREE.MeshBasicMaterial {
  const material = new THREE.MeshBasicMaterial(
    /*{ map: texture }*/ {
      color: colorVal,
      wireframe: false,
      side: THREE.DoubleSide,
    }
  );
  return material;
}
export function getWireFrameMaterial(colorVal: string | number): THREE.MeshBasicMaterial {
  const material = new THREE.MeshBasicMaterial(
    /*{ map: texture }*/ {
      color: colorVal,
      wireframe: true,
      side: THREE.DoubleSide,
    }
  );
  return material;
}
export function getPhongMaterial(colorVal: string | number = 0xffffff): THREE.MeshPhongMaterial {
  // faltan parametros FIX :
  const material = new THREE.MeshPhongMaterial({
    color: colorVal,
    side: THREE.DoubleSide,
  });
  return material;
}

export function setOpacityMaterial(threeObj: THREE.Object3D, opacity: number): void {
  console.assert(opacity >= 0 && opacity <= 1);
  if (
    threeObj instanceof THREE.Line ||
    threeObj instanceof THREE.Points ||
    threeObj instanceof THREE.Mesh
  ) {
    threeObj.material = threeObj.material.clone();
    if (threeObj.material.transparent !== undefined) {
      threeObj.material.transparent = opacity !== 1;
      threeObj.material.opacity = opacity;
      threeObj.material.needsUpdate = true;
    }
  } else {
    console.warn("[setStyleMaterial] THREE Object withOut opacity?");
  }
}
export function setColorMaterial(threeObj: THREE.Object3D, color: string | number): void {
  if (
    threeObj instanceof THREE.Line ||
    threeObj instanceof THREE.Points ||
    threeObj instanceof THREE.Mesh
  ) {
    threeObj.material = threeObj.material.clone();
    const colorDef = new THREE.Color(color);
    threeObj.material.color = colorDef;
    threeObj.material.needsUpdate = true;
  } else {
    console.warn("[setStyleMaterial] THREE Object withOut material?");
  }
}

export function getMatchProperties(data: IObjData) {
  let color = undefined;
  let dimStyleId = undefined, dimOverride = undefined;
  let textStyleId = undefined, textOverride = undefined;
  if (isDimensionData(data)) {
    const dimParam = data.definition;
    const dimStyle = dimensionCache.loadStylefromCache(dimParam.styleId, dimParam.customStyleProp)!;
    const styleText = textStyleCache.loadStylefromCache(dimStyle?.textStyleId)!;
    color = styleText?.color;
    dimStyleId = dimParam.styleId;
    dimOverride = dimParam.customStyleProp;

  } else if (isTextData(data)) {
    const styleText = textStyleCache.loadStylefromCache(data.definition.styleId, data.definition.override);
    color = styleText?.color;
    textStyleId = data.definition.styleId;
    textOverride = data.definition.override;
  } else {
    color = data.material?.color;
  }
  return {
    color: color,
    layerId: data.layerId,
    lineStyleId: (data.material as ILineMaterial)?.lineStyleId,
    width: (data.material as ILineMaterial)?.width,
    dimStyleId: dimStyleId,
    dimOverride: dimOverride,
    textStyleId: textStyleId,
    textOverride: textOverride,
  };
}