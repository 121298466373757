import { ISolidMaterial } from "lib/materials";
import { objDataType } from "lib/models/types";
import { RPrismData } from "lib/models/solids/rprism";
import { rprismParam } from "../../geometries/solid/rprism";
import { GraphicProcessor } from "../../graphic-processor";
import { IPoint } from "../../math/types";
import { cadOpType } from "../../operations/factory";
import { createCommand } from "./create-base";

export class RPrismCommand extends createCommand {
  
  public createdData: RPrismData;
  protected opType = cadOpType.RPRISM;
  protected objType = objDataType.RRPRIM;
  protected param: rprismParam;
  protected material: ISolidMaterial;

  constructor(
    sideX: number,
    sideY: number,
    sideZ: number,
    basePoint: IPoint,
    rotation: IPoint,
    offset: IPoint,
    scale: IPoint,
    layerId: string,
    graphicProc: GraphicProcessor,
    material: ISolidMaterial
  ) {
    super(layerId, graphicProc);
    this.param = {
      sideX,
      sideY,
      sideZ,
      basePoint,
      rotation,
      offset,
      scale,
    };
    this.material = material;
  }
}
