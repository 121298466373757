import * as THREE from "three";
import { getCurrentSolidMaterial, ISolidMaterial, materialCache } from "lib/materials";
import { setPosRotEsc } from "../../geometries";
import { createBufferPlane, createPlane, planeParam } from "../../geometries/plane";
import { copyIPoint } from "../../math/point";
import { objDataType } from "../types";
import { SolidData } from "./solid";

export class PlaneData extends SolidData {

  public type = objDataType.PLANE;
  protected nameObj: string = "Plane";
  public definition: planeParam;

  constructor(definition: planeParam, material?: ISolidMaterial) {
    super();
    this.definition = {
      horizontalDivisions: definition.horizontalDivisions,
      verticalDivisions: definition.verticalDivisions,
      width: definition.width,
      height: definition.height,
      basePoint: copyIPoint(definition.basePoint),
      rotation: copyIPoint(definition.rotation),
      offset: copyIPoint(definition.offset),
      scale: copyIPoint(definition.scale),
    };
    this.material = material ?? getCurrentSolidMaterial();
  }
  static createObj(definition: planeParam, material: ISolidMaterial) {
    const threeMaterial = materialCache.getSolidMaterial(material);
    return createPlane(
      definition.width,
      definition.height,
      definition.horizontalDivisions,
      definition.verticalDivisions,
      threeMaterial,
      definition.basePoint,
      definition.rotation,
      definition.offset,
      definition.scale
    );
  }
  public createGraphicObj() {
    if (this.graphicObj) {
      console.warn("Attention: Plane graphic object already created!!");
      return;
    }
    this.graphicObj = PlaneData.createObj(this.definition, this.material);
  }
  public cloneDefinition(): planeParam {
    const def = super.cloneSolidDefinition() as planeParam;
    def.horizontalDivisions = this.definition.horizontalDivisions;
    def.verticalDivisions = this.definition.verticalDivisions;
    def.width = this.definition.width;
    def.height = this.definition.height;
    return def;
  }
  public createObject(definition?: planeParam, material?: ISolidMaterial): THREE.Mesh {
    return PlaneData.createObj(definition ?? this.definition, material ?? this.material);
  }
  public regenerateDefinition() {
    const geom = createBufferPlane(this.definition.width, this.definition.height, this.definition.horizontalDivisions, this.definition.verticalDivisions);
    this.graphicObj.geometry = geom;
    const { basePoint, offset, rotation, scale } = this.definition;
    setPosRotEsc(this.graphicObj, basePoint, offset, rotation, scale);
  }
  public scale(): void {
    throw new Error("Method not implemented.");
  }
}