import * as THREE from "three";
import { getRotationLine } from "lib/geometries/line";
import { IpointsToBuffer, getMiddlePoint } from "lib/math/point";
import { polygon, polygonVertex } from "lib/math/polygon";
import { PolygonData } from "lib/models/primitives/polygon";
import { cadOpType } from "lib/operations/factory";
import { getEdgeMark } from "../selection-tools";
import { SelectionManager } from "../selection-manager";
import { ObjDataSelector, markBulkTypes } from "./selector-data";


export class PolygonDataSelector extends ObjDataSelector<PolygonData> {

  public override buildSelectMarks() {
    const def = this.data.definition;
    const points = polygon(def.center, def.radius, def.sides, def.inscribed, def.angleO, def.plane);

    const bulkData: markBulkTypes = {};
    bulkData.origin = [points[0]];
    bulkData.vertex = points.slice(1);
    bulkData.center = [def.center];

    points.push(points[0]);
    bulkData.line = [IpointsToBuffer(points)];

    const edgeMarks = [];
    for (let i = 0; i < points.length; i++) {
      const p0 = points[i];
      const p1 = points[i + 1];
      if (p0 && p1) {
        const pto = getMiddlePoint(p0, p1);
        const mark = getEdgeMark(pto);
        const rot = getRotationLine(p0, p1);
        mark.rotation.set(rot.x, rot.y, rot.z);
        edgeMarks.push(mark);
      }
    }
    this.EDGE = edgeMarks;
    return bulkData;
  }

  public override launchMarkOP(selectionManager: SelectionManager, mark: THREE.Object3D, vertexIndex: number) {
    if (selectionManager.vertexSelector.pointObj === mark) {
      const { center, radius, sides, inscribed, angleO, plane } = this.data.definition;
      const pto = polygonVertex(vertexIndex + 1, center, radius, sides, inscribed, angleO, plane);
      return {
        type: cadOpType.EDITPOLYGON,
        graphicProcessor: this.graphicProc,
        args: [this.data, true, vertexIndex + 1, pto],
      };
    }
    if (selectionManager.originSelector.pointObj === mark) {
      const { center, radius, sides, inscribed, angleO, plane } = this.data.definition;
      const pto = polygonVertex(0, center, radius, sides, inscribed, angleO, plane);
      return {
        type: cadOpType.EDITPOLYGON,
        graphicProcessor: this.graphicProc,
        args: [this.data, true, 0, pto],
      };
    }
    if (selectionManager.centerSelector.pointObj === mark) {
      return {
        type: cadOpType.MOVE,
        graphicProcessor: this.graphicProc,
        args: [[this.data], this.data.definition.center],
      };
    }

    if (this.EDGE?.some((e) => e === mark)) {
      const indexEdge = this.EDGE.indexOf(mark as THREE.Mesh);
      const { center, radius, sides, inscribed, angleO, plane } = this.data.definition;
      const p0 = polygonVertex(indexEdge, center, radius, sides, inscribed, angleO, plane);
      const nextIndx = indexEdge + 1 >= sides ? 0 : indexEdge + 1;
      const p1 = polygonVertex(nextIndx, center, radius, sides, inscribed, angleO, plane);
      const pto = getMiddlePoint(p0, p1);
      return {
        type: cadOpType.EDITPOLYGON,
        graphicProcessor: this.graphicProc,
        args: [
          this.data,
          false,
          indexEdge,
          pto,
        ],
      };
    }
  }
}
