import * as THREE from "three";
import { IPoint } from "lib/math/types";
import { createExtrusionMesh, updateExtrusionMesh } from './extrusion';
import { IPolylineParam, iterPolylineEdges } from "lib/math/line";
import { calculateOffsetBuffer0, calculateOffsetLine } from "lib/math/offset-buffer";
import { growthWidth } from "lib/models-struc/types/struc-base";
import { CSG } from "lib/helpers/three-csg/csg-lib";
import { getEulerFromNormalAndPoint } from "lib/math/plane";
import { crossProductIpoint, mulIpoint } from "lib/math/point";
import { getRelativePoint } from "lib/coordinates/plane";
import { wallParam } from "lib/models-struc/types/wall";
import { lineAngle2p } from "lib/math/angles";

export function createWall(lineData: IPolylineParam, height: number, width: number, widthType: growthWidth, material?: THREE.MeshPhongMaterial): THREE.Mesh {
  let contour: IPoint[] = [];
  let holes: IPoint[][] = [];
  if (lineData.points.length) {

    if (widthType === growthWidth.TOLEFT) {
      lineData = calculateOffsetLine(lineData, width * 0.5);
    } else if (widthType === growthWidth.TORIGHT) {
      lineData = calculateOffsetLine(lineData, -width * 0.5);
    }

    const res = calculateOffsetBuffer0(lineData, width * 0.5);
    contour = res.shift()?.points as IPoint[];
    holes = res.map(r => r.points) as IPoint[][];
  }
  const extrudedWall = createExtrusionMesh(contour, height, holes, material)
  extrudedWall.geometry.translate(0, 0, -height);
  return extrudedWall;
}

export function updateWall(mesh: THREE.Mesh, lineData: IPolylineParam, height: number, width: number, widthType: growthWidth) {
  if (lineData.points.length) {

    if (widthType === growthWidth.TOLEFT) {
      lineData = calculateOffsetLine(lineData, width * 0.5);
    } else if (widthType === growthWidth.TORIGHT) {
      lineData = calculateOffsetLine(lineData, -width * 0.5);
    }

    const res = calculateOffsetBuffer0(lineData, width * 0.5);
    const contour = res.shift()?.points as IPoint[];
    const holes = res.map(r => r.points) as IPoint[][];
    updateExtrusionMesh(mesh, contour, height, holes);
    mesh.geometry.translate(0, 0, -height);
  }
}

export function addWallHole(wallMesh: THREE.Mesh, width: number, widthType: growthWidth, hole: IPoint[], normal: IPoint) {
  const rot = getEulerFromNormalAndPoint(mulIpoint(normal, -1));
  const ptos2D = hole.map(p => getRelativePoint(p, hole[0], rot));
  const mesh = createExtrusionMesh(ptos2D, width * 2.2);
  switch (widthType) {
    case growthWidth.CENTER: mesh.geometry.translate(0, 0, -width * 2.2 * 0.5); break;
    case growthWidth.TORIGHT: mesh.geometry.translate(0, 0, -width * 2.1); break;
    case growthWidth.TOLEFT: mesh.geometry.translate(0, 0, -0.01); break;
    default: break;
  }
  mesh.position.set(hole[0].x, hole[0].y, hole[0].z);
  mesh.rotation.set(rot.x, rot.y, rot.z);
  mesh.updateMatrixWorld();

  const pos = wallMesh.position.clone();
  wallMesh.position.set(0, 0, 0);
  wallMesh.updateMatrixWorld();
  const mesh0 = CSG.fromMesh(wallMesh as THREE.Mesh);
  const mesh1 = CSG.fromMesh(mesh);
  const res = mesh0.subtract(mesh1);
  const newGrom = CSG.toGeometry(res, true);

  let inv = new THREE.Matrix4().copy(wallMesh.matrix).invert();
  newGrom.applyMatrix4(inv);
  newGrom.computeBoundingSphere();
  newGrom.computeBoundingBox();

  wallMesh.geometry = newGrom;
  wallMesh.position.add(pos);
  wallMesh.updateMatrixWorld();
}

export function delWallHole(wallMesh: THREE.Mesh, width: number, widthType: growthWidth, hole: IPoint[], normal: IPoint) {
  const rot = getEulerFromNormalAndPoint(mulIpoint(normal, -1));
  const ptos2D = hole.map(p => getRelativePoint(p, hole[0], rot));
  const mesh = createExtrusionMesh(ptos2D, width * 2.2);
  switch (widthType) {
    case growthWidth.CENTER: mesh.geometry.translate(0, 0, -width * 2 * 0.5); break;
    case growthWidth.TORIGHT: mesh.geometry.translate(0, 0, -width * 2.1); break;
    case growthWidth.TOLEFT: mesh.geometry.translate(0, 0, -0.01); break;
    default: break;
  }
  mesh.position.set(hole[0].x, hole[0].y, hole[0].z);
  mesh.rotation.set(rot.x, rot.y, rot.z);
  mesh.updateMatrix();

  const pos = wallMesh.position.clone();
  wallMesh.position.set(0, 0, 0);
  wallMesh.updateMatrix();
  const mesh0 = CSG.fromMesh(wallMesh as THREE.Mesh)
  const mesh1 = CSG.fromMesh(mesh)
  const res = mesh0.union(mesh1);
  const newGrom = CSG.toGeometry(res, true);

  let inv = new THREE.Matrix4().copy(wallMesh.matrix).invert();
  newGrom.applyMatrix4(inv);
  newGrom.computeBoundingSphere();
  newGrom.computeBoundingBox();

  wallMesh.geometry = newGrom;
  wallMesh.position.add(pos);
  wallMesh.updateMatrixWorld();
}

export function getWallNormals(params: wallParam) {
  const normals = [];
  for (const edge of iterPolylineEdges(params.ptos2D)) {
    const { p0, p1 } = edge;
    const angle = lineAngle2p(p0, p1);

    const a = new THREE.Euler(0, 0, angle);
    const xVec = new THREE.Vector3(1, 0, 0);
    xVec.applyEuler(a);
    const yVec = new THREE.Vector3(0, 0, 1);
    const zVec = crossProductIpoint(xVec, yVec);
    normals.push(zVec);
  }
  return normals;
}
