const GridDisplayIcon = ({ ...props }) => {
  return (
    <svg className="h-5 w-5" viewBox="0 0 100 100" fill="none" {...props}>
      <path
        d="M95 25V20H75V0H70V20H50V0H45V20H25V0H20V20H0V25H20V45H0V50H20V70H0V75H20V95H25V75H45V95H50V75H70V95H75V75H95V70H75V50H95V45H75V25H95ZM25 25H45V45H25V25ZM25 70V50H45V70H25ZM70 70H50V50H70V70ZM70 45H50V25H70V45Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default GridDisplayIcon;
