import { IObjData } from "lib/models/objdata";
import { objDataType } from "lib/models/types";
import { IObjDataSelector } from "./selector-objdata/selector-data";
import { SlabDataSelector } from "./selector-objdata/structural/slab";
import { BlockDataSelector } from "./selector-objdata/block";
import { AngleDimensionDataSelector } from "./selector-objdata/dimension/angle-dim";
import { ArcDimensionDataSelector } from "./selector-objdata/dimension/arc-dim";
import { CircularDimensionDataSelector } from "./selector-objdata/dimension/circular-dim";
import { AlignedDimensionDataSelector } from "./selector-objdata/dimension/lineal-dim";
import { TextDataSelector } from "./selector-objdata/text";
import { SolidDataSelector } from "./selector-objdata/solid";
import { EllipseArcDataSelector } from "./selector-objdata/ellipse-arc";
import { EllipseDataSelector } from "./selector-objdata/ellipse";
import { ArcDataSelector } from "./selector-objdata/arc";
import { RegionDataSelector } from "./selector-objdata/region";
import { CircleDataSelector } from "./selector-objdata/circle";
import { PolygonDataSelector } from "./selector-objdata/polygon";
import { LineDataSelector } from "./selector-objdata/polyline";
import { PointDataSelector } from "./selector-objdata/point";
import { GraphicProcessor } from "lib/graphic-processor";
import { BeamDataSelector } from "./selector-objdata/structural/beam";
import { WallDataSelector } from "./selector-objdata/structural/wall";
import { LoadDataSelector } from "./selector-objdata/structural/load";

const ObjDataSelectors = {
  [objDataType.POINT]: PointDataSelector,
  [objDataType.POLYLINE]: LineDataSelector,
  [objDataType.POLYGON]: PolygonDataSelector,
  [objDataType.CIRCLE]: CircleDataSelector,
  [objDataType.REGION]: RegionDataSelector,
  [objDataType.ARC]: ArcDataSelector,
  [objDataType.ELLIPSE]: EllipseDataSelector,
  [objDataType.ELLIPSEARC]: EllipseArcDataSelector,
  [objDataType.TEXT]: TextDataSelector,
  [objDataType.ALIGNEDDIM]: AlignedDimensionDataSelector,
  [objDataType.LINEARDIM]: AlignedDimensionDataSelector,
  [objDataType.ARCDIM]: ArcDimensionDataSelector,
  [objDataType.CIRCULARDIM]: CircularDimensionDataSelector,
  [objDataType.ANGULARDIM]: AngleDimensionDataSelector,

  [objDataType.BLOCK]: BlockDataSelector,
  [objDataType.SLAB]: SlabDataSelector,
  [objDataType.LOAD]: LoadDataSelector,

  [objDataType.BLOCKREF]: SolidDataSelector,
  [objDataType.BEAM]: BeamDataSelector,
  [objDataType.WALL]: WallDataSelector,
  [objDataType.COLUMN]: SolidDataSelector,
  [objDataType.FOOTER]: SolidDataSelector,
  [objDataType.STRIPFOOTER]: SolidDataSelector,
  [objDataType.PILECAP]: SolidDataSelector,
  [objDataType.CUBE]: SolidDataSelector,
  [objDataType.CYLINDER]: SolidDataSelector,
  [objDataType.PLANE]: SolidDataSelector,
  [objDataType.CONE]: SolidDataSelector,
  [objDataType.RRPRIM]: SolidDataSelector,
  [objDataType.PPRISM]: SolidDataSelector,
}

export function getDataSelector(data: IObjData, graphicProc: GraphicProcessor): IObjDataSelector {
  const dataSelectorBuilder = ObjDataSelectors[data.type] as any;
  const handler = new dataSelectorBuilder(data, graphicProc);
  return handler;
}
