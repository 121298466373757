export interface IPoint {
  x: number;
  y: number;
  z: number;
}
export interface ISegment {
  p1: IPoint;
  p2: IPoint;
}
export function isISegment(o: any): o is ISegment {
  if (o.p1 && o.p2) return true;
  return false;
}
export function copyISegment(segment: ISegment): ISegment {
  console.assert(isISegment(segment));
  return {
    p1: { x: segment.p1.x, y: segment.p1.y, z: segment.p1.z },
    p2: { x: segment.p2.x, y: segment.p2.y, z: segment.p2.z },
  };
}
export interface IInertia {
  x: number;
  y: number;
  xy: number;
}
export interface IColor {
  r: number;
  g: number;
  b: number;
  a: number;
}
export function isIColor(o: any): o is IColor {
  if (o.r !== undefined && o.g !== undefined && o.b !== undefined && o.a !== undefined) return true;
  return false;
}