import { CubeEditDataCommand } from "lib/commands/edition/cube";
import { linealPrecision } from "lib/general-settings";
import { volumeCube } from "lib/geometries/volume";
import { IPoint } from "lib/math/types";
import { cubeParam } from "../../geometries/solid/cube";
import { CubeData } from "../../models/solids/cube";
import { dataInfoProperty } from "../properties";
import { SolidDataDefinitionHandler } from "../solid";
import { ISolidMaterial } from '../../materials/solid';

export type cubeProp = cubeParam & { volume: number; centroid: IPoint; }
export type cubeView = dataInfoProperty<cubeProp>

export class CubeDataDefinitionHandler extends SolidDataDefinitionHandler<cubeProp> {

  protected data: CubeData;

  protected buildInfoProperties() {
    this.definitionInfo = {};
    const def = this.data.definition;
    this.definitionInfo.side = this.getNumberView(def.side, "Side", "m", linealPrecision, true, (side: number) => side > 0);
    this.fillSolidDefinition(this.data);
    this.definitionInfo.volume = this.getNumberView(volumeCube(def), "Volume", "m³", linealPrecision, false);
  }

  private checkNewDefinition(newDefinition: cubeProp): boolean {
    if (!newDefinition) { return false; }
    if (!this.checkSolidNewDefinition(newDefinition)) { return false; }
    if (!this.checkNumber(newDefinition.side)) { return false; }
    return true;
  }

  private changedNewDefinition(oldDefinition: cubeParam, newDefinition: cubeProp): cubeParam | null {
    let def = oldDefinition;
    let changed: boolean = false;
    if (newDefinition) {
      const solid = this.changedSolidNewDefinition(oldDefinition, newDefinition)
      if (solid !== null) {
        def.basePoint = solid.basePoint;
        def.rotation = solid.rotation;
        def.scale = solid.scale;
        def.offset = solid.offset;
        changed = true;
      }
      const side = this.changedNumber(def.side, newDefinition.side);
      if (side !== null) {
        def.side = side;
        changed = true;
      }
    }
    return (changed ? def : null);
  }

  private checkAndChangedDefinition(oldDefinition: cubeParam, newDefinition: cubeProp): cubeParam | null {
    let def = null;
    if (this.checkNewDefinition(newDefinition)) {
      def = this.changedNewDefinition(oldDefinition, newDefinition);
    }
    return def;
  }

  public saveAndRegenerate = (newDefinition: cubeProp) => {
    const cubeDefinition = this.checkAndChangedDefinition(this.data.cloneDefinition(), newDefinition);
    if (cubeDefinition) {
      const command = new CubeEditDataCommand(this.data, cubeDefinition, null, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    };
  }

  public saveAndRegenerateMaterial = (newMaterial: ISolidMaterial) => {
    const cubeMaterial = this.checkAndChangedSolidMaterial(this.data.material, newMaterial);
    if (cubeMaterial) {
      const command = new CubeEditDataCommand(this.data, null, cubeMaterial, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    };
  }
}