import { StripFooterEditDataCommand } from "lib/commands/structural/footer";
import { linealPrecision } from "lib/general-settings";
import { ISolidMaterial } from "lib/materials/solid";
import { IPoint } from "lib/math/types";
import { shellCrossSectionCache } from "lib/models-struc/cross-sections-shape/shell-cross-sections/cache";
import { isFlatCSSParams } from "lib/models-struc/cross-sections-shape/shell-cross-sections/types";
import { footerParam } from "lib/models-struc/types/footer";
import { FooterData } from "lib/models/structural/footer";
import { StructuralDataDefinitionHandler } from "./structural";

export type footerProp = footerParam & { depth: number, centroid: IPoint; }

export class StripFooterDataDefinitionHandler extends StructuralDataDefinitionHandler<footerProp> {

  protected data: FooterData;

  protected buildInfoProperties() {
    super.buildInfoProperties();
    this.fillFooterDefinition(this.data);
  }

  private fillFooterDefinition(data: FooterData) {
    const def = data.definition;

    const ShellCSSs = shellCrossSectionCache.getAllLoadedStyles().sort((a, b) => a.name.localeCompare(b.name));
    const flatCSSnameList: [string, string][] = [];
    for (const shellCSS of ShellCSSs) {
      const param = shellCSS.parameters;
      if (isFlatCSSParams(param)) flatCSSnameList.push([shellCSS.name, shellCSS.styleId]);
    }
    const capSection = shellCrossSectionCache.loadStylefromCache(def.shellSectionId)!;
    this.structuralInfo.shellSectionId = { publicName: "Flat shell section", type: "tagList", value: capSection.styleId, tagList: flatCSSnameList };
    this.structuralInfo.depth = this.getNumberView(capSection.thickness, "Depth", "m", linealPrecision, false);
    this.structuralInfo.width = this.getNumberView(def.width, "Width", "m", linealPrecision);
  }

  private checkNewStructural(newStructural: footerProp) {
    const repr = newStructural;
    if (!newStructural) { return false; }
    if (!this.checkStructuralNewDefinition(newStructural)) { return false; }
    if (repr.shellSectionId !== undefined && !this.checkString(repr.shellSectionId)) { return false; }
    if (repr.width !== undefined && !this.checkNumber(repr.width)) { return false; }
    return true;
  }

  private changedFooterNewStructural(oldDefinition: footerParam, newDefinition: footerProp) {
    let def = oldDefinition;
    let changed: boolean = false;
    if (newDefinition) {
      const name = this.changedString(def.name, newDefinition.name);
      if (name !== null) {
        def.name = name;
        changed = true;
      }
      const shellSectionId = this.changedString(def.shellSectionId, newDefinition.shellSectionId);
      if (shellSectionId !== null) {
        def.shellSectionId = shellSectionId;
        changed = true;
      }
      const width = this.changedNumber(def.width, newDefinition.width);
      if (width !== null) {
        def.width = width;
        changed = true;
      }
    }
    return (changed ? def : null);
  }

  public saveAndRegenerate = (newDefinition: footerProp) => {
    const footerDefinition = this.checkAndChangedDefinition(this.data.cloneDefinition(), newDefinition);
    if (footerDefinition) {
      const command = new StripFooterEditDataCommand(this.data, footerDefinition, null, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }

  public saveAndRegenerateMaterial = (newMaterial: ISolidMaterial) => {
    const footerMaterial = this.checkAndChangedSolidMaterial(this.data.material, newMaterial);
    if (footerMaterial) {
      const command = new StripFooterEditDataCommand(this.data, null, footerMaterial, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }

  public saveAndRegenerateStruct = (newStructural: footerProp) => {
    if (this.checkNewStructural(newStructural)) {
      const footerStrucParam = this.changedFooterNewStructural(this.data.cloneDefinition(), newStructural);
      if (footerStrucParam) {
        const command = new StripFooterEditDataCommand(this.data, footerStrucParam, null, this.graphicProcessor);
        if (command) this.graphicProcessor.storeAndExecute(command);
      }
    }
  }
}