import { IDimBuildProperties } from "lib/dimension/style";
import { IPointReference, IEdgeReference } from "lib/operations/step-operations";
import { IObjData, ObjData } from "../objdata";
import { dimDefinition } from "../types";

export interface dimensionParam {
  styleId: string;
  customStyleProp: Partial<IDimBuildProperties>;
}
export interface dimDependence {
  data: IObjData;
  dimPos?: number;
}

export interface IDimensionData extends IObjData {
  definition: dimDefinition;
}

export abstract class DimensionData extends ObjData {

  public graphicObj: THREE.Group;
  public material: undefined;
  public definition: dimDefinition;
  
  protected getDepsPointsRef(pointsRef: IPointReference[]): dimDependence[] {
    const deps: dimDependence[] = [];
    if (pointsRef) {
      for (let i = 0; i < 2; i++) {
        const ref = pointsRef[i];
        if (ref.data) {
          const depend: dimDependence = {
            data: ref.data,
            dimPos: i
          };
          if (!deps.some(d => this.dependenciesAreEquals(d, depend))) {
            deps.push(depend);
          }
        }
      }
    }
    return deps;
  }
  protected getDepsEdgesRef(edgesRef: IEdgeReference[]): dimDependence[] {
    const deps: dimDependence[] = [];
    if (edgesRef) {
      for (let i = 0; i < 2; i++) {
        const ref = edgesRef[i];
        if (ref.data) {
          const depend: dimDependence = {
            data: ref.data,
            dimPos: i
          };
          if (!deps.some(d => this.dependenciesAreEquals(d, depend))) {
            deps.push(depend);
          }
        }
      }
    }
    return deps;
  }

}