import { gql } from "@apollo/client";

export const PROJECT_FRAGMENT = gql`
  fragment projectFragment on project {
    id
    name
    code
    createdAt
    updatedAt
    user {
      username
    }
    status
    material
    location
    footing_level
    seismic_acc
    number_of_storeys
    address
    buildingModelUrl
    storeys {
      id
      dxf_name
      dxf_url
      floor_name
      height
      level
      occupancy
      storey_number
    }
    events(order_by: { created_at: asc }) {
      id
      type
      action
      description
      createdAt: created_at
    }
    measurements {
      id
      result
    }
  }
`;

export const GET_PROJECTS = gql`
  query getProjects {
    projects: project(order_by: { createdAt: desc }) {
      ...projectFragment
    }
  }
  ${PROJECT_FRAGMENT}
`;

export const UPDATE_PROJECT = gql`
  mutation updateProject($id: uuid!, $object: project_set_input!) {
    project: update_project_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...projectFragment
    }
  }
  ${PROJECT_FRAGMENT}
`;

export const UPDATE_PROJECT_AND_STOREYS = gql`
  mutation updateProject(
    $id: uuid!
    $project: project_set_input!
    $storeys: [storey_insert_input!]!
  ) {
    project: update_project_by_pk(pk_columns: { id: $id }, _set: $project) {
      id
      name
      status
      updatedAt
      createdAt
    }
    delete_storey(where: { project_id: { _eq: $id } }) {
      affected_rows
    }
    insert_storey(objects: $storeys) {
      affected_rows
    }
  }
`;

export const UPDATE_PROJECT_EVENTS = gql`
  mutation updateProjectEvents(
    $id: uuid!
    $object: project_set_input!
    $type: String!
  ) {
    delete_event(where: { project_id: { _eq: $id }, type: { _eq: $type } }) {
      affected_rows
    }
    project: update_project_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...projectFragment
    }
  }
  ${PROJECT_FRAGMENT}
`;

export const GET_PROJECT = gql`
  query getProject($id: uuid!) {
    project: project_by_pk(id: $id) {
      ...projectFragment
    }
  }
  ${PROJECT_FRAGMENT}
`;

export const ADD_PROJECT = gql`
  mutation addProject($object: project_insert_input!) {
    project: insert_project_one(object: $object) {
      id
      name
      status
      updatedAt
      createdAt
    }
  }
`;
