import { operationAction, OperationActionType } from "lib/events/operation";
import { GraphicProcessor } from "lib/graphic-processor";
import { Cad3dOp } from "lib/operations/base";
import { settingOp } from "lib/operations/step-operations";
import { useEffect, useReducer } from "react";

export interface opState {
  currentStep: settingOp | null;
  currentOperation: Cad3dOp | null;
}
const opInitialState: opState = {
  currentStep: null,
  currentOperation: null,
};

function opReducer(state: opState, action: operationAction): opState {
  switch (action.type) {
    case OperationActionType.START_OPERATION:
      return {
        ...state,
        currentOperation: action.payload.operation,
      };
    case OperationActionType.SET_CURRENT_CONFIG:
      return {
        ...state,
        currentStep: action.payload.currCfg,
      };
    case OperationActionType.UPDATE_CURRENT_CONFIG:
      return {
        ...state,
        currentStep: action.payload.currCfg,
      };
    case OperationActionType.END_OPERATION:
      return {
        currentStep: null,
        currentOperation: null,
      };
    default:
      throw new Error("Action is not defined.");
  }
}

export function useOpSettings(graphicProc: GraphicProcessor) {

  const [opSettings, dispatchOP] = useReducer(opReducer, opInitialState);

  useEffect(() => {
    graphicProc.operationObserver.subscribe(dispatchOP);
    return () => {
      graphicProc.operationObserver.unsubscribe(dispatchOP);
    }
  }, [graphicProc.operationObserver]);

  return { opSettings };
}
