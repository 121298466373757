import { ILineMaterial } from "lib/materials";
import { objDataType } from "lib/models/types";
import { PolygonData } from "lib/models/primitives/polygon";
import { polygonParam } from "../../geometries/polygon";
import { GraphicProcessor } from "../../graphic-processor";
import { cadOpType } from "../../operations/factory";
import { createCommand } from "./create-base";

export class PolygonCommand extends createCommand {
  
  public createdData: PolygonData;
  protected opType = cadOpType.POLYGON;
  protected objType = objDataType.POLYGON;
  protected param: polygonParam;
  protected material: ILineMaterial | undefined;

  constructor(params: polygonParam, layerId: string, graphicProc: GraphicProcessor, material?: ILineMaterial) {
    super(layerId, graphicProc);
    this.param = params;
    this.material = material;
  }
}