import { ComponentType, useCallback, DragEvent, FC } from "react";
import cn from "classnames";
import ChevronRightIcon from "shared/components/icons/chevron-right";
import ChevronDownIcon from "shared/components/icons/chevron-down";
import { CSSProperties } from "styled-components";
import DotIcon from "shared/components/icons/dot";
import { NodeData } from "./tree";

interface iNodeProps {
  nodeData: NodeData<any>;
  isOpen: boolean;
  isActiveNode: boolean;
  setActiveNode: (node: NodeData<any>) => void;
  setCurrentNode: (node?: NodeData<any>) => void;
  onOpenCloseNode: (node: NodeData<any>) => void;
  dragNode: (nodeId: string, nodeTarget: NodeData<any>) => void;
  startDepth: number;
  style: CSSProperties;
  NodeOptions: ComponentType<any> | null;
}

export const TreeNode: FC<iNodeProps> = ({ nodeData, isOpen, isActiveNode, setActiveNode, setCurrentNode, dragNode, startDepth, style, onOpenCloseNode, NodeOptions }) => {

  const { id, depth, name, isLeaf } = nodeData;

  const handleDragStart = (event: DragEvent<HTMLDivElement>) => {
    event.currentTarget.classList.add("dragging");
    event.dataTransfer.setData("text/plain", nodeData.id);
  };
  const handleDragEnd = (event: DragEvent<HTMLDivElement>) => {
    event.currentTarget.classList.remove("dragging");
  };
  const handleDragEnter = (event: DragEvent<HTMLDivElement>) => {
    event.currentTarget.classList.add("border-indigo-500", "border-b-2");
  };
  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.currentTarget.classList.remove("border-indigo-500", "border-b-2");
  };
  const handleDrop = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.currentTarget.classList.remove("border-indigo-500", "border-b-2");
    const sourceLayerId = event.dataTransfer.getData("text/plain");
    dragNode(sourceLayerId, nodeData);
  }, [dragNode, nodeData]);
  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const Icon = isOpen ? ChevronDownIcon : ChevronRightIcon;
  const rootClassName = cn(isActiveNode ? "bg-gray-600" : "hover:bg-gray-600", " cursor-pointer");
  const nodeStyle: CSSProperties = { paddingLeft: `${(depth - startDepth) ? (depth - startDepth) * 20 : 5}px` }

  return (
    <div style={style as any} className={rootClassName}>
      <div
        className={"h-full flex items-center rounded-sm px-1 text-gray-200"}
        style={nodeStyle}
      >
        {!isLeaf ?
          <Icon className="w-4 h-4" onClick={() => onOpenCloseNode(nodeData)} /> :
          <DotIcon className="w-4 h-4" />
        }
        <div
          className="flex-1 border-transparent"
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          data-id={id}
          onClick={() => setActiveNode(nodeData)}
          onMouseOver={() => { setCurrentNode(nodeData) }}
          // onMouseLeave={() => { setCurrentNode() }}
          draggable
        >
          <span>{`${name}`}</span>
        </div>
        {NodeOptions && <NodeOptions nodeData={nodeData} />}
      </div>
    </div>
  );
};
