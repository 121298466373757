import { ViewportType } from "lib/graphic-viewport";
import { useCallback, useState } from "react";
import { GraphicProcessor } from '../../../../../lib/graphic-processor';

export function useViewportMode(graphicProcessor: GraphicProcessor) {

  const [perspectiveMode, setPerspectiveMode] = useState<boolean>(true);
  const [plainMode, setPlainMode] = useState<boolean>(true);
  const [elevationMode, setElevationMode] = useState<boolean>(true);

  const handlePerspectiveMode = useCallback(() => {
    if (graphicProcessor.transformCurrentViewportCameraTo(ViewportType.ViewPerspective3D)) {
      // window.alert("Current viewport camera transformed to 3D-perspective.");
    }
  }, [graphicProcessor, perspectiveMode]);

  const handleElevationMode = useCallback(() => {
    if (graphicProcessor.transformCurrentViewportCameraTo(ViewportType.ViewElevOrtho2D)) {
      // window.alert("Current viewport camera transformed to 2D-ortographic with elevation view.");
    }
  }, [graphicProcessor, elevationMode]);

  const handlePlainMode = useCallback(() => {
    // graphicProcessor.managerVP.debug("INFORMATION ABOUT CURRENT VP's:", true);
    if (graphicProcessor.transformCurrentViewportCameraTo(ViewportType.ViewPlanOrtho2D)) {
      // window.alert("Current viewport camera transformed to 2D-ortographic with plan view.");
    }
  }, [graphicProcessor, plainMode]);

  return {
    perspectiveMode,
    elevationMode,
    plainMode,
    handlePerspectiveMode,
    handleElevationMode,
    handlePlainMode,
  };
}
