import { ArcData } from "../primitives/arc";
import { BlockData } from "../block";
import { CircleData } from "../primitives/circle";
import { ConeData } from "../solids/cone";
import { CubeData } from "../solids/cube";
import { CylinderData } from "../solids/cylinder";
import { AlignedDimensionData, alignedDimensionParam } from "../dimension/aligned-dim";
import { AngleDimensionData, angleDimensionParam } from "../dimension/angle-dim";
import { ArcDimensionData, arcDimensionParam } from "../dimension/arc-dim";
import { CircularDimensionData, circularDimensionParam } from "../dimension/circular-dim";
import { LinealDimensionData, linealDimensionParam } from "../dimension/lineal-dim";
import { EllipseData } from "../primitives/ellipse";
import { EllipseArcData } from "../primitives/ellipse-arc";
import { IObjData } from "../objdata";
import { definitionType, materialType } from "../types";
import { LineData } from "../primitives/line";
import { PlaneData } from "../solids/plane";
import { PointData } from "../primitives/point";
import { PolygonData } from "../primitives/polygon";
import { PPrismData } from "../solids/pprism";
import { RegionData } from "../solids/region";
import { regionParam } from "../../geometries/solid/region";
import { RPrismData } from "../solids/rprism";
import { TextData, textParam } from "../text";
import { ILineMaterial, IPointMaterial, ISolidMaterial } from "lib/materials";
import { IPoint } from "lib/math/types";
import { IPolylineParam } from "lib/math/line";
import { arcParam } from "lib/math/arc";
import { polygonParam } from "lib/geometries/polygon";
import { circleParam } from "lib/geometries/circle";
import { ellipseParam } from "lib/geometries/ellipse";
import { ellipseArcParam } from "lib/geometries/ellipse-arc";
import { blockRefParam } from "lib/blocks";
import { cubeParam } from "lib/geometries/solid/cube";
import { cylinderParam } from "lib/geometries/solid/cylinder";
import { planeParam } from "lib/geometries/plane";
import { coneParam } from "lib/geometries/solid/cone";
import { rprismParam } from "lib/geometries/solid/rprism";
import { pprismParam } from "lib/geometries/solid/pprism";

// ********************************* Factory Method for creating ObjData ***********************************

export abstract class ObjDataCreator {

  protected abstract createObjDataMethod(definition: definitionType, material?: materialType): IObjData;
  public createObjData(definition: definitionType, material?: materialType) {
    const product = this.createObjDataMethod(definition, material);
    return product;
  }
  protected cloneObjDataMethod(dataObj: IObjData): IObjData {
    return this.createObjDataMethod(dataObj.definition, dataObj.material);
  }
  public cloneObjData(dataObj: IObjData) {
    const product = this.cloneObjDataMethod(dataObj);
    return product;
  }
  protected abstract createGraphicObjMethod(definition: definitionType, material?: materialType): THREE.Object3D;
  public createGraphicObj(definition: definitionType, material?: materialType) {
    const product = this.createGraphicObjMethod(definition, material);
    return product;
  }
}

export class PointCreator extends ObjDataCreator {
  protected createObjDataMethod(definition: IPoint, material?: IPointMaterial) {
    return new PointData(definition, material);
  }
  protected createGraphicObjMethod(definition: IPoint, material: IPointMaterial) {
    return PointData.createObj(definition, material);
  }
}
export class LineCreator extends ObjDataCreator {
  protected createObjDataMethod(definition: IPolylineParam, material?: ILineMaterial) {
    return new LineData(definition, material);
  }
  protected createGraphicObjMethod(definition: IPolylineParam, material: ILineMaterial) {
    return LineData.createObj(definition, material);
  }
}
export class ArcCreator extends ObjDataCreator {
  protected createObjDataMethod(definition: arcParam, material?: ILineMaterial) {
    return new ArcData(definition, material);
  }
  protected createGraphicObjMethod(definition: arcParam, material: ILineMaterial) {
    return ArcData.createObj(definition, material);
  }
}
export class PolygonCreator extends ObjDataCreator {
  protected createObjDataMethod(definition: polygonParam, material?: ILineMaterial) {
    return new PolygonData(definition, material);
  }
  protected createGraphicObjMethod(definition: polygonParam, material: ILineMaterial) {
    return PolygonData.createObj(definition, material);
  }
}
export class CircleCreator extends ObjDataCreator {
  protected createObjDataMethod(definition: circleParam, material?: ILineMaterial) {
    return new CircleData(definition, material);
  }
  protected createGraphicObjMethod(definition: circleParam, material: ILineMaterial) {
    return CircleData.createObj(definition, material);
  }
}
export class EllipseCreator extends ObjDataCreator {
  protected createObjDataMethod(definition: ellipseParam, material?: ILineMaterial) {
    return new EllipseData(definition, material);
  }
  protected createGraphicObjMethod(definition: ellipseParam, material: ILineMaterial) {
    return EllipseData.createObj(definition, material);
  }
}
export class EllipseArcCreator extends ObjDataCreator {
  protected createObjDataMethod(definition: ellipseArcParam, material?: ILineMaterial) {
    return new EllipseArcData(definition, material);
  }
  protected createGraphicObjMethod(definition: ellipseArcParam, material: ILineMaterial) {
    return EllipseArcData.createObj(definition, material);
  }
}
export class TextCreator extends ObjDataCreator {
  protected createObjDataMethod(definition: textParam) {
    return new TextData(definition)
  }
  protected createGraphicObjMethod(definition: textParam) {
    return TextData.createObj(definition);
  }
}
export class BlockCreator extends ObjDataCreator {
  protected createObjDataMethod(definition: blockRefParam) {
    return new BlockData(definition)
  }
  protected createGraphicObjMethod(definition: blockRefParam) {
    return BlockData.createObj(definition);
  }
}
// *****************************************************************************************************
export class AlignedDimCreator extends ObjDataCreator {
  protected createObjDataMethod(definition: alignedDimensionParam) {
    return new AlignedDimensionData(definition)
  }
  protected createGraphicObjMethod(definition: alignedDimensionParam) {
    return AlignedDimensionData.createObj(definition);
  }
}
export class LinealDimCreator extends ObjDataCreator {
  protected createObjDataMethod(definition: linealDimensionParam) {
    return new LinealDimensionData(definition)
  }
  protected createGraphicObjMethod(definition: linealDimensionParam) {
    return LinealDimensionData.createObj(definition);
  }
}
export class AngleDimCreator extends ObjDataCreator {
  protected createObjDataMethod(definition: angleDimensionParam) {
    return new AngleDimensionData(definition)
  }
  protected createGraphicObjMethod(definition: angleDimensionParam) {
    return AngleDimensionData.createObj(definition);
  }
}
export class ArcDimCreator extends ObjDataCreator {
  protected createObjDataMethod(definition: arcDimensionParam) {
    return new ArcDimensionData(definition)
  }
  protected createGraphicObjMethod(definition: arcDimensionParam) {
    return ArcDimensionData.createObj(definition);
  }
}
export class CircularDimCreator extends ObjDataCreator {
  protected createObjDataMethod(definition: circularDimensionParam) {
    return new CircularDimensionData(definition);
  }
  protected createGraphicObjMethod(definition: circularDimensionParam) {
    return CircularDimensionData.createObj(definition);
  }
}
// *****************************************************************************************************
export class RegionCreator extends ObjDataCreator {
  protected createObjDataMethod(definition: regionParam, material?: ISolidMaterial) {
    return new RegionData(definition, material);
  }
  protected createGraphicObjMethod(definition: regionParam, material: ISolidMaterial) {
    return RegionData.createObj(definition, material);
  }
}
export class CubeCreator extends ObjDataCreator {
  protected createObjDataMethod(definition: cubeParam, material?: ISolidMaterial) {
    return new CubeData(definition, material);
  }
  protected createGraphicObjMethod(definition: cubeParam, material: ISolidMaterial) {
    return CubeData.createObj(definition, material);
  }
}
export class CylinderDimCreator extends ObjDataCreator {
  protected createObjDataMethod(definition: cylinderParam, material?: ISolidMaterial) {
    return new CylinderData(definition, material);
  }
  protected createGraphicObjMethod(definition: cylinderParam, material: ISolidMaterial) {
    return CylinderData.createObj(definition, material);
  }
}
export class PlaneCreator extends ObjDataCreator {
  protected createObjDataMethod(definition: planeParam, material?: ISolidMaterial) {
    return new PlaneData(definition, material);
  }
  protected createGraphicObjMethod(definition: planeParam, material: ISolidMaterial) {
    return PlaneData.createObj(definition, material);
  }
}
export class ConeCreator extends ObjDataCreator {
  protected createObjDataMethod(definition: coneParam, material?: ISolidMaterial) {
    return new ConeData(definition, material);
  }
  protected createGraphicObjMethod(definition: coneParam, material: ISolidMaterial) {
    return ConeData.createObj(definition, material);
  }
}
export class RPrismCreator extends ObjDataCreator {
  protected createObjDataMethod(definition: rprismParam, material?: ISolidMaterial) {
    return new RPrismData(definition, material);
  }
  protected createGraphicObjMethod(definition: rprismParam, material: ISolidMaterial) {
    return RPrismData.createObj(definition, material);
  }
}
export class PPrismCreator extends ObjDataCreator {
  protected createObjDataMethod(definition: pprismParam, material?: ISolidMaterial) {
    return new PPrismData(definition, material);
  }
  protected createGraphicObjMethod(definition: pprismParam, material: ISolidMaterial) {
    return PPrismData.createObj(definition, material);
  }
}
