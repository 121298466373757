import { IStrucElementData } from "lib/models/structural/structural";
import { EditConsolidation } from "lib/models-struc/checkers.ts/struc-consolidate";
import { EditDataCommand } from "../edition/edit-base";

export abstract class EditBaseStructCommand extends EditDataCommand {

  public async execute() {
    super.execute();
    EditConsolidation(this.objData as IStrucElementData, this.graphicProcessor);
  }
  public async unExecute() {
    super.unExecute();
    EditConsolidation(this.objData as IStrucElementData, this.graphicProcessor);
  }
}