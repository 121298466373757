import { ProjectsApi, ProjectFilesApi, Project } from "lib/apis/struc-project/api";
import { StrucSite, StrucStorey } from "modules/struc/models/project";
import { updateFiles } from "./update-files";

export async function fetchPutEditProject(userId: string, Id: string, project: Project, site: StrucSite, storeys: StrucStorey[]) {
  try {
    const controller = new ProjectsApi();
    console.info("[EDIT_PROJECT] Edit project info ...");
    const response = await controller.projectsUserIdIdPut(userId, Id, project);
    const data = response.data as Project;
    const dataFiles = data.DataFiles!;
    site.buildings[0].struc_storeys = storeys;
    console.info("[EDIT_PROJECT] Edit sites info ...");
    await updateFiles.sites([site], dataFiles.Sites.Write);

    const fileProj = new ProjectFilesApi();
    await fileProj.projectsUserIdIdUpdatePut(userId, data.Id);
    console.info("[EDIT_PROJECT] Project edited successfully.");
    return data;

  } catch (error) {
    console.error(error);
    throw error;
  }
}
