import { getAuxMaterialLine, LineMaterialType } from "lib/materials";
import * as THREE from "three";
import { adjustDrawRange, updateObjBboxBSphere } from ".";
import { polygon } from "../math/polygon";
import { IPoint } from "../math/types";

export interface polygonParam {
  center: IPoint,
  radius: number,
  sides: number,
  inscribed: boolean,
  angleO: number,
  plane: IPoint,
}

export function polygonCreate(
  center: IPoint,
  radius: number,
  sides: number,
  inscribed: boolean,
  angleO?: number,
  plane?: IPoint,
  material?: LineMaterialType
): THREE.Line {
  if (angleO === undefined) angleO = 0;
  if (plane === undefined) plane = { x: 0, y: 0, z: 0 };
  console.assert(sides > 2, "Numero de lados invalido");

  const points: THREE.BufferGeometry = new THREE.BufferGeometry();
  const coords = polygonBuffer(
    center,
    radius,
    sides,
    inscribed,
    angleO,
    plane
  );
  if (coords) {
    points.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(coords, 3)
    );
  }
  const mat = material ?? getAuxMaterialLine();
  const polygon: THREE.Line = new THREE.Line(points, mat);
  if (mat.type !== "LineBasicMaterial") polygon.computeLineDistances();
  updateObjBboxBSphere(polygon);
  adjustDrawRange(polygon);
  return polygon;
}

export function polygonBuffer(
  center: IPoint,
  radius: number,
  sides: number,
  inscribed: boolean,
  angleO?: number,
  plane?: IPoint
): Float32Array {
  if (angleO === undefined) angleO = 0;
  if (plane === undefined) plane = { x: 0, y: 0, z: 0 };

  const points = polygon(center, radius, sides, inscribed, angleO, plane);

  const coords = new Float32Array((sides + 1) * 3);
  let position = 0;
  for (const point of points) {
    coords[position++] = point.x;
    coords[position++] = point.y;
    coords[position++] = point.z;
  }
  // El buffer necesita que se repita el primer punto.
  if (points[0]) {
    coords[sides * 3] = points[0].x;
    coords[sides * 3 + 1] = points[0].y;
    coords[sides * 3 + 2] = points[0].z;
  }
  return coords;
}
