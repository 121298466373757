import { AnalysisProjectsResultsApi } from "lib/apis/analysis-projects/api";
import { downloadSomeFilesFromS3, fileData, fileUrl, statusRequestOK } from "lib/apis/utils";
import { userMessageEvents } from "lib/events/user-messages";
import { gunzipSync } from "zlib";
import { Hypothesis } from "../hypothesis/hypothesis";

// displ - nodes
// displ - shells
// displ - beams
// fm - nodes
// fm - shells
// fm - beams

export enum resultType {
  DISPL_NODES = "displ_nodes",
  DISPL_IP_BEAMS = "displ_ip_beams",
  DISPL_IP_SHELLS = "displ_ip_shells",
  FM_NODAL_BEAMS = "fm_nodal_beams",
  FM_NODAL_SHELLS = "fm_nodal_shells",
  FM_IP_BEAMS = "fm_ip_beams",
  FM_IP_SHELSS = "fm_ip_shells",
}

export class SolutionModelManager {

  private displacements: Map<Hypothesis, string> = new Map();
  private beamsFM: Map<Hypothesis, string> = new Map();
  private shellsFM: Map<Hypothesis, string> = new Map();
  //private nodalFM: Map<Hypothesis, string> = new Map();

  async getResult(analysisProjectId: string, type: resultType, hypos: Hypothesis[]): Promise<fileData[] | undefined> {
    try {
      const results: fileData[] = [];
      const hyposToDownload: Hypothesis[] = [];
      const storage = this.getStorageForResults(type);
      if (storage) {
        hypos.forEach(h => {
          // Check existing data
          const data = storage.get(h);
          if (data) {
            // Data for hypo found
            results.push({ name: h.name, data: data })
          } else {
            // Data for hypo to download
            hyposToDownload.push(h);
          }
        });
        if (hyposToDownload.length) {
          const fileDatas = await this.downloadResultsByHypos(analysisProjectId, type, hyposToDownload);
          if (fileDatas) {
            this.storeResultsByHypos(storage, fileDatas, hypos);
            results.push(...fileDatas);
          }
        }
      }
      return results;
    } catch (err) {
      console.log(err);
      const message = `There was a problem at download solution for ${type}`;
      userMessageEvents.dispatchError(message);
    }
  }

  private async downloadResultsByHypos(analysisProjectId: string, type: resultType, hypos: Hypothesis[]): Promise<fileData[]> {
    const analysisProjectsResult = new AnalysisProjectsResultsApi();
    console.log("[RESULTS-GET-DOWNLOAD] " + analysisProjectId)
    const analysisProjectsRes = await analysisProjectsResult.analysisProjectsIdResultsGet(analysisProjectId, type, hypos.map(h => h.name));
    if (analysisProjectsRes.status === statusRequestOK) {
      const filesUrl = analysisProjectsRes.data as fileUrl[];
      if (filesUrl.length) {
        // Download from S3 and un-compress data
        const fileDatas = await downloadSomeFilesFromS3<ArrayBuffer>(filesUrl);
        if (fileDatas.length) {
          const ret = fileDatas.map(fd => {
            return {
              name: fd.name,
              data: gunzipSync(Buffer.from(fd.data)).toString(),
            };
          });
          return ret;
        } else {
          throw new Error("S3 Download files failed");
        }
      } else {
        throw new Error("Analysis project list of FileUrl empty");
      }
    } else {
      throw new Error("Error getting the list of FileUrl objects of a set of hypothesis");
    }
  }

  private getStorageForResults(type: resultType): Map<Hypothesis, string> | undefined {
    switch (type) {
      case resultType.DISPL_NODES:
        return this.displacements;
      case resultType.FM_NODAL_BEAMS:
        return this.beamsFM;
      case resultType.FM_NODAL_SHELLS:
        return this.shellsFM;
    }
  }

  private storeResultsByHypos(storage: Map<Hypothesis, string>, fileDatas: fileData[], hypos: Hypothesis[]) {
    if (fileDatas) {
      fileDatas.forEach(fd => {
        const hypo = hypos.find(h => h.name === fd.name);
        if (hypo) {
          storage.set(hypo, fd.data);
        }
      });
    }
  }
}

export let solutionManager: SolutionModelManager;
export function initSolutionManager() {
  solutionManager = new SolutionModelManager();
  return solutionManager;
}