import { IPoint } from "./math/types";
import { IObjData } from "./models/objdata";

class ClipBoard {
  public basePoint: IPoint | undefined;
  private elements: Set<IObjData> = new Set();

  public getElements(): IObjData[] {
    return Array.from(this.elements);
  }

  public setElements(datas: IObjData[], basePoint?: IPoint): void {
    this.elements.clear();
    this.basePoint = basePoint;
    const objs = datas.slice();
    objs.forEach((obj) => this.elements.add(obj));
  }

  public clear() {
    this.basePoint = undefined;
    this.elements = new Set(); 
  }
}

export const cadClipBoard = new ClipBoard();
