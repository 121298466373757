import { v4 as uuid } from 'uuid'

export interface ICompositeComponent<T> {
  id: string
  name: string;
  props: T;
  parent: ICompositeComponent<any>;
  cloneTree(): ICompositeComponent<T>;
  cloneNode(): ICompositeComponent<T>;
  traverse(fn: (comp: ICompositeComponent<any>) => boolean): void;
  getNodeById<U>(nodeId: string): ICompositeComponent<U> | undefined;
  getDepthLevel(): number;
  getNodePath(): void;

  children?: ICompositeComponent<any>[];
}

abstract class compositeBase<T> implements ICompositeComponent<T> {

  public id: string;
  public name: string;
  public props: T;
  public parent: ICompositeComponent<any>;

  abstract cloneTree(): ICompositeComponent<T>;
  abstract cloneNode(): ICompositeComponent<T>;
  abstract traverse(fn: (comp: ICompositeComponent<any>) => boolean): void
  abstract getNodeById(nodeId: string): ICompositeComponent<any> | undefined;
  public children?: ICompositeComponent<any>[] | undefined;

  constructor(name: string, props?: T) {
    this.id = uuid();
    this.name = name;
    if (props) this.props = props;
  }

  public getDepthLevel() {
    const getParent = (node: ICompositeComponent<any>) => {
      if (node.parent) {
        level++;
        getParent(node.parent);
      }
    };
    let level = 0;
    getParent(this);
    return level;
  }

  public getNodePath(): ICompositeComponent<any>[] {
    const getParent = (node: ICompositeComponent<any>) => {
      nodes.push(node);
      if (node.parent) {
        getParent(node.parent);
      }
    };
    let nodes: ICompositeComponent<any>[] = [];
    getParent(this);
    return nodes;
  }

}

export class CompositeNode<T> extends compositeBase<T> {

  public children: ICompositeComponent<any>[] = [];

  constructor(name: string = "root", props?: T) {
    super(name, props);
  }

  public cloneTree() {
    const cloned = this.cloneNode();
    for (const child of this.children) {
      const childNode = child.cloneTree();
      cloned.addChildNode(childNode);
    }
    return cloned;
  }
  public cloneNode() {
    const cloned = new CompositeNode<T>(this.name, this.props);
    cloned.id = this.id;
    // cloned.parent = this.parent;
    // cloned.children = this.children;
    return cloned;
  }

  public addChildNode(node: ICompositeComponent<unknown>) {
    this.children.push(node);
    node.parent = this;
    return node;
  }
  public addChild<U>(name: string, props?: U): CompositeNode<U> {
    const c = new CompositeNode(name, props);
    c.parent = this;
    this.children.push(c);
    return c
  }
  public addChildLeaf<U>(name: string, props?: U): CompositeLeaf<U> {
    const c = new CompositeLeaf(name, props);
    c.parent = this;
    this.children.push(c);
    return c
  }

  public removeChild(node: ICompositeComponent<unknown>) {
    const i = this.children.indexOf(node);
    this.children.splice(i, 1);
    node.parent = null!;
    return node;
  }
  public removeAllChild() {
    this.children.forEach(c => c.parent = null!)
    this.children.length = 0
  }

  public traverse(fn: (comp: ICompositeComponent<unknown>) => boolean) {
    const visitChildren = fn(this);
    if (visitChildren) {
      for (const child of this.children) {
        child.traverse(fn)
      }
    }
  }

  public getNodeById<U>(nodeId: string): ICompositeComponent<U> | undefined {
    let node: ICompositeComponent<U> | undefined;
    this.traverse((n: ICompositeComponent<any>) => {
      if (n.id === nodeId) {
        node = n;
        return false;
      }
      return true;
    })
    return node;
  }
}
export class CompositeLeaf<T> extends compositeBase<T> {

  cloneTree(): ICompositeComponent<T> {
    return this.cloneNode();
  }
  cloneNode() {
    const cloned = new CompositeLeaf<T>(this.name, this.props);
    cloned.id = this.id;
    // cloned.parent = this.parent;
    return cloned;
  }

  traverse(fn: (comp: ICompositeComponent<unknown>) => void) {
    fn(this)
  }
  getNodeById<T>(nodeId: string): ICompositeComponent<T> | undefined {
    if (this.id === nodeId) return this as any;
  }
}
