const CopyIcon = ({ ...props }) => {
  return (
    <svg className="h6 w-6" viewBox="0 0 100 100" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 0C8.97167 0 0 8.97167 0 20C0 31.0283 8.97167 40 20 40C31.0283 40 40 31.0283 40 20C40 8.97167 31.0283 0 20 0Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 13.3333H74.31L80 19.0233V28.3333H73.3333C73.1167 28.3333 72.9 28.3783 72.6967 28.4617C72.2883 28.63 71.9633 28.955 71.795 29.3633C71.6267 29.77 71.6267 30.23 71.795 30.6367C71.88 30.8417 72.0017 31.025 72.1567 31.18L80.4883 39.5117C80.8133 39.8367 81.24 40 81.6667 40C82.0933 40 82.52 39.8367 82.845 39.5117L91.1767 31.18C91.3317 31.025 91.4533 30.8417 91.5383 30.6367C91.7067 30.23 91.7067 29.77 91.5383 29.3633C91.37 28.955 91.045 28.63 90.6367 28.4617C90.4333 28.3783 90.2167 28.3333 90 28.3333H83.3333V18.3333C83.3333 18.1167 83.29 17.9 83.205 17.6967C83.12 17.4917 82.9983 17.3083 82.8433 17.1533L76.18 10.49C76.025 10.335 75.8417 10.2133 75.6367 10.1283C75.4333 10.045 75.2167 10 75 10H50C49.0783 10 48.3333 10.7467 48.3333 11.6667C48.3333 12.5867 49.0783 13.3333 50 13.3333Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.5555 68.1818C80.5555 57.4602 71.8322 48.7368 61.1105 48.7368C50.3888 48.7368 41.6672 57.4602 41.6672 68.1818C41.6672 78.9035 50.3888 87.6268 61.1105 87.6268C71.8322 87.6268 80.5555 78.9035 80.5555 68.1818Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.0307 61.2867C83.7173 63.465 84.0907 65.78 84.0907 68.1817C84.0907 79.83 75.3707 89.4583 64.1207 90.9417C67.654 96.5133 73.8523 100 80.5557 100C91.2773 100 100.001 91.2767 100.001 80.555C100.001 70.675 92.5857 62.5083 83.0307 61.2867Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CopyIcon;
