import { AlignedDimensionData, alignedDimensionParam } from "lib/models/dimension/aligned-dim";
import { materialType, objDataType } from "lib/models/types";
import { GraphicProcessor } from "../../graphic-processor";
import { cadOpType } from "../../operations/factory";
import { EditDataCommand } from "../edition/edit-base";
import { createCommand } from "../primitives/create-base";

export class AlignedDimensionCommand extends createCommand {

  public createdData: AlignedDimensionData;
  protected opType = cadOpType.ALIGNEDDIM;
  protected objType = objDataType.ALIGNEDDIM;
  protected param: alignedDimensionParam;
  protected material: materialType | undefined;

  constructor(params: alignedDimensionParam, layerId: string, graphicProc: GraphicProcessor) {
    super(layerId, graphicProc);
    this.param = params;
  }
}

export class AlignedDimensionEditDataCommand extends EditDataCommand {

  protected opType = cadOpType.EDITALIGNEDDIM;

  constructor(obj: AlignedDimensionData, newDefinition: alignedDimensionParam | null, graphicProcessor: GraphicProcessor) {
    super(obj, newDefinition, null, graphicProcessor);
  }
}