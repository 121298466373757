import { LayerDelete } from "lib/layers/application/layer-delete";
import { useCallback } from "react";
import { useUI } from "shared/components/ui/context";
import { GraphicProcessor } from '../../../../../lib/graphic-processor';

export function useDeleteLayer(graphicProc: GraphicProcessor) {

  const { closeModal } = useUI();

  const deleteLayer = useCallback(() => {
    const layerManager = graphicProc.getLayerManager();
    const action = new LayerDelete(graphicProc);
    action.run(layerManager.currentLayer.id);
    closeModal();
  }, [graphicProc, closeModal]);

  return {
    deleteLayer,
  };
}