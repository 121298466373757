import { useState } from "react";
import { canvasOptions, GraphicProcessor } from "lib/graphic-processor";
import { refViewPlane } from "lib/coordinates/plane-manager";
import { cadOpType } from "lib/operations/factory";

export type viewPortStatus = "idle" | "loading" | "mounted" | "unmounted";

let resizer: ResizeObserver;

export default function useViewportLite(graphicProc: GraphicProcessor, options: canvasOptions = {}) {

  const [status, setStatus] = useState<viewPortStatus>("idle");

  const mountContainer = (node: HTMLDivElement) => {
    setStatus("loading");

    if (options.showthreeStat === undefined) options.showthreeStat = false;
    if (options.showXyzGyzmo === undefined) options.showXyzGyzmo = false;
    if (options.showMainGrid === undefined) options.showMainGrid = false;
    if (options.camera === undefined) options.camera = "perspective";
    if (options.cameraView === undefined) options.cameraView = refViewPlane.TOP;
    if (options.activeControls === undefined) options.activeControls = true;

    graphicProc.mount(node, undefined, options);
    graphicProc.launchOP(cadOpType.SELECT);
    resizer = new ResizeObserver(graphicProc.handleWindowResize);
    resizer.observe(node);
    setStatus("mounted");
  }
  const unMountContainer = () => {
    resizer.disconnect();
    graphicProc.unmount();
    setStatus("unmounted");
  }

  return {
    mountContainer,
    unMountContainer,
    status,
  };
}
