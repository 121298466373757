import { IObjData } from "lib/models/objdata";
import { cloneDataModel } from "lib/models/model-creator/datamodel-factory";
import { GraphicProcessor } from "../../graphic-processor";
import { IPoint } from "../../math/types";
import { cadOpType } from "../../operations/factory";
import { TransformCommand } from "./transform";

export class MirrorCommand extends TransformCommand {
  protected opType = cadOpType.MIRROR;

  private startPoint: IPoint;
  private endPoint: IPoint;

  constructor(startPoint: IPoint, endPoint: IPoint, objIds: IObjData[], withCopy: boolean, graphicProcessor: GraphicProcessor) {
    super(objIds, graphicProcessor);
    this.startPoint = startPoint;
    this.endPoint = endPoint;
    this.withCopy = withCopy;
  }

  protected transformFun = (obj: IObjData) => {
    if (this.withCopy) {
      if (!this.createdObjs.has(obj)) {
        const data = cloneDataModel(obj);
        data.mirror(this.startPoint, this.endPoint);
        this.graphicProcessor.addToLayer(data, obj.layerId);
        this.createdObjs.set(obj, data);
      }
    } else {
      obj.mirror(this.startPoint, this.endPoint);
    }
  };

  protected unDoTransformFun = (obj: IObjData) => {
    if (this.withCopy) {
      const alreadyCopied = this.createdObjs.get(obj)!;
      this.graphicProcessor.removeFromLayer(alreadyCopied);
    } else {
      // Undo is equivalent to make operation again respect the same line
      obj.mirror(this.startPoint, this.endPoint);
    }
  };
}
