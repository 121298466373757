import { useMutation } from '@apollo/client'
import { DXF, Storey } from 'modules/lite/models/storey'
import { GET_PROJECTS, ADD_PROJECT } from 'modules/lite/services/projects'
import { createCode, getSeismicAcc } from 'modules/lite/utils/projects'
import {
  updateStoreys,
  fillStoreys,
  getStoreysWithStorageUrl,
  recalculateLevels,
  recalculateOrder,
} from 'modules/lite/utils/storeys'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { useAuth } from 'shared/components/auth/context'
import { useUI } from 'shared/components/ui/context'
import { Location, Project } from '../../../models/project'
import { GetProjectsData } from './use-projects'

type Status = 'idle' | 'loading' | 'success' | 'error'

interface Data {
  project: Project
}
interface Params {
  object: any
}

export function useAddProject() {
  const { user } = useAuth()
  const { openToast } = useUI()
  const [status, setStatus] = useState<Status>('idle')
  const [mutate] = useMutation<Data, Params>(ADD_PROJECT, {
    update(cache, { data }) {
      if (data) {
        const query = cache.readQuery<GetProjectsData>({
          query: GET_PROJECTS,
        })
        if (query) {
          cache.writeQuery({
            query: GET_PROJECTS,
            data: { projects: [data.project, ...query.projects] },
          })
        }
      }
    },
  })
  const {
    handleSubmit,
    register,
    unregister,
    errors,
    setValue,
    watch,
  } = useForm<Project>({
    defaultValues: {
      storeys: [],
      footing_level: 0,
      number_of_storeys: 0,
      seismic_acc: 0,
    },
    shouldFocusError: true,
    mode: 'onTouched',
  })
  const { number_of_storeys, footing_level } = watch()
  const history = useHistory()
  const addProject = useCallback(
    async ({ storeys, seismic_acc, ...project }: Project) => {
      if (user) {
        setStatus('loading')
        const storeysWithStorageUrl = await getStoreysWithStorageUrl(storeys)
        const code = createCode(project.address.country, project.address.city)
        const seismicAcc = getSeismicAcc(code)
        const object = {
          ...project,
          seismic_acc: seismicAcc,
          code,
          user_id: user.id,
          storeys: { data: storeysWithStorageUrl },
          events: {
            data: {
              action: 'CREATED',
              description: `Project created by ${user.username}`,
            },
          },
        }
        mutate({
          variables: { object },
        })
          .then(() => {
            openToast({
              title: 'Project created successfully.',
              status: 'success',
            })
            setStatus('success')
          })
          .catch(() => {
            openToast({
              title: 'Error creating project.',
              status: 'error',
            })
            setStatus('error')
          })
          .finally(() => {
            history.push('/lite/projects')
          })
      }
    },
    [user, openToast, mutate, history]
  )

  const [storeys, setStoreys] = useState<Storey[]>([])
  useEffect(() => {
    register('storeys')
    register('location')
    return () => {
      unregister('storeys')
      unregister('location')
    }
  }, [register, unregister])

  useEffect(() => {
    const updatedStoreys = fillStoreys(number_of_storeys, storeys)
    setStoreys(updatedStoreys)
    return () => setStoreys([])
  }, [storeys, number_of_storeys])

  useEffect(() => {
    setValue('storeys', storeys)
  }, [setValue, storeys])

  const updateStorey = useCallback(
    (storey: Storey) => {
      const updatedStoreys = updateStoreys(storey, storeys, footing_level)
      setStoreys(updatedStoreys)
    },
    [setStoreys, storeys, footing_level]
  )

  const setLocation = useCallback(
    (location: Location) => {
      setValue('location', location)
    },
    [setValue]
  )
  const setFootingLevel = useCallback(
    (footingLevel: number) => {
      setValue('footing_level', footingLevel)
      const updatedStoreys = recalculateLevels(storeys, footingLevel)
      setStoreys(updatedStoreys)
    },
    [setValue, storeys]
  )

  const sortStoreys = useCallback(
    (source: number, destination: number) => {
      const updatedStoreys = recalculateOrder(
        storeys,
        source,
        destination,
        footing_level
      )
      setStoreys(updatedStoreys)
    },
    [footing_level, storeys]
  )

  const uploadBulkDXF = useCallback(
    (files: DXF[]) => {
      setValue('number_of_storeys', files.length)
      const updatedStoreys = fillStoreys(files.length, [], files)
      setStoreys(updatedStoreys)
    },
    [setValue]
  )
  return {
    errors,
    handleSubmit: handleSubmit(addProject),
    register,
    setFootingLevel,
    setLocation,
    status,
    storeys,
    updateStorey,
    sortStoreys,
    uploadBulkDXF,
  }
}
