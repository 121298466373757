import * as THREE from "three";
import { normalizeRgbColor } from "lib/math/color";
import { Float32Concat, Float32delete, Float32splice } from "./buffer-utils";
import { IColor, IPoint } from '../math/types';

export class PointBufferHandler<T> {

  private resPoint: THREE.Points;
  get pointObj() { return this.resPoint; }
  // Save bufferIndex [start (inclusive), end (exclusive)] -> [start, end)
  private indexData: Map<T, [number, number]> = new Map();
  private hasColor: boolean;

  constructor(mat?: THREE.Material, hasColor: boolean = false) {
    this.hasColor = hasColor;
    if (mat) {
      mat.vertexColors = hasColor;
      this.resPoint = new THREE.Points(undefined, mat);
    } else {
      const material = new THREE.PointsMaterial({ vertexColors: hasColor });
      this.resPoint = new THREE.Points(undefined, material);
    }
    (this.resPoint.geometry as THREE.BufferGeometry).setAttribute("position", new THREE.BufferAttribute(new Float32Array(), 3));
    this.resPoint.name = "PointBufferHandler";
  }

  public getDataFromBufferIndex(vertexIndex: number) {
    const bufferIndex = vertexIndex * 3;
    for (const [data, indices] of this.indexData) {
      if (bufferIndex >= indices[0] && bufferIndex < indices[1]) {
        return { data, index: (vertexIndex - indices[0] / 3) };
      }
    }
    console.log("[] NO data founded by index: " + bufferIndex)
  }

  public addData(data: T, bufferGeom: Float32Array, colorMat?: IColor) {
    let indexCounterStart = 0;
    const geometry = this.resPoint.geometry as THREE.BufferGeometry;
    if (geometry.hasAttribute("position")) {
      const position = geometry.getAttribute("position") as THREE.BufferAttribute;
      indexCounterStart = position.array.length;
      const buffer = Float32Concat(position.array as Float32Array, bufferGeom);
      geometry.setAttribute("position", new THREE.BufferAttribute(buffer, 3));
    } else {
      geometry.setAttribute("position", new THREE.BufferAttribute(bufferGeom, 3));
    }

    const ptosLen = bufferGeom.length;
    this.indexData.set(data, [indexCounterStart, indexCounterStart + ptosLen]);

    if (this.hasColor && colorMat) {
      const { r, g, b } = normalizeRgbColor(colorMat);
      const bufferColor = new Float32Array([r, g, b]);
      if (geometry.hasAttribute("color")) {
        const color = geometry.getAttribute("color") as THREE.BufferAttribute;
        const buffer = Float32Concat(color.array as Float32Array, bufferColor);
        geometry.setAttribute("color", new THREE.BufferAttribute(buffer, 3));
      } else {
        geometry.setAttribute("color", new THREE.BufferAttribute(bufferColor, 3));
      }
    }
    geometry.computeBoundingBox();
    geometry.computeBoundingSphere();
    return this.resPoint;
  }
  public removeData(data: T) {
    const bufferIndex = this.indexData.get(data);
    if (bufferIndex) {
      const [start, end] = bufferIndex;
      const geometry = this.resPoint.geometry as THREE.BufferGeometry;
      const position = geometry.getAttribute("position") as THREE.BufferAttribute;
      const buffer = Float32delete(position.array as Float32Array, start, end);
      geometry.setAttribute("position", new THREE.BufferAttribute(buffer, 3));
      if (this.hasColor) {
        const color = geometry.getAttribute("color") as THREE.BufferAttribute;
        const bufferCol = Float32delete(color.array as Float32Array, start, end);
        geometry.setAttribute("color", new THREE.BufferAttribute(bufferCol, 3));
      }

      const offset = end - start;
      let edit = false;
      for (const [objData, index] of this.indexData) {
        if (edit) {
          index[0] -= offset;
          index[1] -= offset;
        }
        if (!edit && data === objData) {
          edit = true;
          this.indexData.delete(data);
        }
      }

      geometry.computeBoundingBox();
      geometry.computeBoundingSphere();
    }
  }
  private updateGeometryData(startInd: number, coords: Float32Array, colorMat?: IColor) {
    if (startInd !== -1) {
      const geometry = this.resPoint.geometry as THREE.BufferGeometry;
      const position = geometry.getAttribute("position") as THREE.BufferAttribute;
      Float32splice(position.array as Float32Array, startInd * 3, (startInd * 3) + 3, coords);
      position.needsUpdate = true;

      if (this.hasColor && colorMat) {
        const { r, g, b } = normalizeRgbColor(colorMat);
        const bufferColor = new Float32Array([r, g, b]);
        const color = geometry.getAttribute("color") as THREE.BufferAttribute;
        Float32splice(color.array as Float32Array, startInd * 3, (startInd * 3) + 3, bufferColor);
        color.needsUpdate = true;
      }
      geometry.computeBoundingBox();
      geometry.computeBoundingSphere();
    }
  }
  private updateMaterialData(startInd: number, colorMat: IColor) {
    if (this.hasColor) {
      if (startInd !== -1) {
        const { r, g, b } = normalizeRgbColor(colorMat);
        const geometry = this.resPoint.geometry as THREE.BufferGeometry;
        const color = geometry.getAttribute("color") as THREE.BufferAttribute;
        color.setXYZ(startInd, r, g, b);
        color.needsUpdate = true;
      }
    }
  }
  public clearData() {
    const geometry = this.resPoint.geometry as THREE.BufferGeometry;
    geometry.setAttribute("position", new THREE.BufferAttribute(new Float32Array(), 3));
    if (this.hasColor) {
      geometry.setAttribute("color", new THREE.BufferAttribute(new Float32Array(), 3));
    }
    geometry.computeBoundingBox();
    geometry.computeBoundingSphere();
    this.indexData.clear();
  }

  public loadDatas(datas: { obj: T, points: IPoint[] }[]) {
    const coords: number[] = [];
    let indexCounterStart = 0;
    const geometry = this.resPoint.geometry as THREE.BufferGeometry;
    if (geometry.hasAttribute("position")) {
      const position = geometry.getAttribute("position") as THREE.BufferAttribute;
      indexCounterStart = position.array.length;
    }
    for (const data of datas) {
      for (const pto of data.points) {
        coords.push(pto.x, pto.y, pto.z);
      }
      const indexEnd = indexCounterStart + data.points.length * 3;
      this.indexData.set(data.obj, [indexCounterStart, indexEnd]);
      indexCounterStart = indexEnd;
    }

    if (geometry.hasAttribute("position")) {
      const position = geometry.getAttribute("position") as THREE.BufferAttribute;
      const buffer = Float32Concat(position.array as Float32Array, new Float32Array(coords));
      geometry.setAttribute("position", new THREE.BufferAttribute(buffer, 3));
    } else {
      geometry.setAttribute("position", new THREE.BufferAttribute(new Float32Array(coords), 3));
    }
    geometry.computeBoundingBox();
    geometry.computeBoundingSphere();
    return this.resPoint;
  }
}
