import PropertiesInput from 'modules/cad/components/properties/component-input';
import { FormEvent, useEffect, useState } from 'react';
import { Column, useTable } from 'react-table'
import { Styles } from './styles';

// const dataTable: readonly Column<{}>[] = [
//   { Header: 'x', accessor: 'id', },
//   { Header: '1', accessor: 'data0' },
//   { Header: '2', accessor: 'data1' },
//   { Header: '3', accessor: 'data2' },
//   { Header: '4', accessor: 'data3' },
// ];
// const datas: readonly {}[] = [
//   { id: "T", data0: 0.1, data1: 1.0, data2: 10.0, data3: 15 },
//   { id: "a/g", data0: 0.1, data1: 1.7, data2: 1.7, data3: 0.5 },
// ];

interface prop {
  value: any,
  row: { index: number },
  column: { id: number },
  // This is a custom function that we supplied to our table instance
  updateMyData: (index: number, id: number, value: any) => void,
}
const EditableCell = ({ value: initialValue, row: { index }, column: { id }, updateMyData }: prop) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue)

  const onChange = (e: any) => {
    setValue(e.target.value)
  }

  const onBlur = () => {
    if (initialValue?.toString() !== value?.toString()) {
      updateMyData(index, id, value);
    }
  }
  const handleSubmit = (ev: FormEvent<HTMLFormElement>): void => {
    ev.preventDefault();
    updateMyData(index, id, value)
  }
  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue])

  return (
    <form onSubmit={handleSubmit}>
      <PropertiesInput
        className={'rounded-none border-0 text-right'}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        srOnly={true}
      />
    </form>
  )
}
const DefaultCell = ({ value: initialValue }: prop) => {
  return (
    <div className={"flex justify-center"}>
      <label className={"text-xs text-gray-400 px-1"}>
        {initialValue}
      </label>
    </div>
  )
}

const defaultColumn = {
  Cell: DefaultCell,
  inputCell: EditableCell,
}
interface props<T> {
  columns: readonly Column<{}>[];
  hideColumn?: boolean;
  data: readonly T[];
  updateMyData?: (rowIndex: number, columnId: keyof T, value: any) => void;
}
function Table<T>({ columns, hideColumn, data, updateMyData }: props<T>) {

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    defaultColumn,
    updateMyData,
  } as any)

  // Render the UI for your table
  return (
    <Styles>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                hideColumn ? null :
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, j) => {
                  if (j === 0 || updateMyData === undefined) {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  }
                  return <td {...cell.getCellProps()}>{cell.render('inputCell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </Styles>
  )
}

export default Table