const AngleDimensionIcon = ({ ...props }) => {
  return (
    <svg
      className="h-5 w-5"
      viewBox="0 0 100 100"
      fill="currentColor"
      {...props}
    >
      <path d="M49.125 10.6562H92.3594C92.8774 10.6562 93.3742 10.4505 93.7404 10.0842C94.1067 9.71791 94.3125 9.22113 94.3125 8.70312C94.3125 8.18512 94.1067 7.68834 93.7404 7.32206C93.3742 6.95578 92.8774 6.75 92.3594 6.75H49.125C48.607 6.75 48.1102 6.95578 47.7439 7.32206C47.3776 7.68834 47.1719 8.18512 47.1719 8.70312C47.1719 9.22113 47.3776 9.71791 47.7439 10.0842C48.1102 10.4505 48.607 10.6562 49.125 10.6562Z" />
      <path d="M8.64062 47.1094C8.12262 47.1094 7.62584 47.3152 7.25956 47.6814C6.89328 48.0477 6.6875 48.5445 6.6875 49.0625V92.2969C6.6875 92.8149 6.89328 93.3117 7.25956 93.6779C7.62584 94.0442 8.12262 94.25 8.64062 94.25C9.15863 94.25 9.65541 94.0442 10.0217 93.6779C10.388 93.3117 10.5938 92.8149 10.5938 92.2969V49.0625C10.5938 48.5445 10.388 48.0477 10.0217 47.6814C9.65541 47.3152 9.15863 47.1094 8.64062 47.1094V47.1094Z" />
      <path d="M95.4375 34.0781L84.2344 14.6718C84.0469 14.3947 83.7943 14.1678 83.4987 14.0109C83.2032 13.854 82.8737 13.772 82.5391 13.772C82.2045 13.772 81.875 13.854 81.5794 14.0109C81.2839 14.1678 81.0313 14.3947 80.8438 14.6718L69.6406 34.0781C69.4322 34.4623 69.3575 34.905 69.4285 35.3364C69.4994 35.7677 69.7119 36.1632 70.0324 36.4605C70.3529 36.7577 70.7633 36.9398 71.1988 36.9781C71.6342 37.0164 72.07 36.9086 72.4375 36.6718L80.5938 31.0781V48.875C80.5814 57.2655 77.2417 65.3086 71.3072 71.2401C65.3728 77.1716 57.328 80.5073 48.9375 80.5156H31.1406L36.7344 72.375C36.982 72.0103 37.099 71.5727 37.0664 71.1331C37.0338 70.6936 36.8535 70.278 36.5547 69.9539C36.256 69.6298 35.8564 69.4163 35.421 69.3481C34.9855 69.2798 34.5398 69.3609 34.1563 69.5781L14.7344 80.7812C14.4393 80.9532 14.1945 81.1995 14.0244 81.4956C13.8542 81.7917 13.7646 82.1272 13.7646 82.4687C13.7646 82.8102 13.8542 83.1458 14.0244 83.4419C14.1945 83.738 14.4393 83.9843 14.7344 84.1562L34.1563 95.3125C34.5398 95.5297 34.9855 95.6107 35.421 95.5425C35.8564 95.4743 36.256 95.2608 36.5547 94.9367C36.8535 94.6126 37.0338 94.197 37.0664 93.7574C37.099 93.3179 36.982 92.8802 36.7344 92.5156L31.1563 84.375H48.9375C58.3559 84.3667 67.3871 80.6259 74.0528 73.9719C80.7185 67.318 84.4752 58.2934 84.5 48.875V31.0781L92.6406 36.6718C93.0081 36.9086 93.4439 37.0164 93.8794 36.9781C94.3149 36.9398 94.7252 36.7577 95.0457 36.4605C95.3663 36.1632 95.5787 35.7677 95.6497 35.3364C95.7206 34.905 95.646 34.4623 95.4375 34.0781V34.0781Z" />
    </svg>
  );
};

export default AngleDimensionIcon;
