import cn from "classnames";
import { FocusEvent, forwardRef, InputHTMLAttributes, RefObject, useState } from "react";
import LockIcon from "shared/components/icons/lock";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  reference: RefObject<HTMLInputElement>;
  editable: boolean;
  label: string;
  isPositionLocked: boolean;
  unlockPosition: () => void;
}

const PositionInput = forwardRef<HTMLInputElement, Props>((props, _) => {

  const [editting, setEditting] = useState(false);

  const {
    className,
    id,
    editable,
    reference,
    label,
    onChange,
    unlockPosition,
    value,
    isPositionLocked,
    ...rest
  } = props;
  const rootClassName = cn(
    "bg-gray-700 block w-24 text-gray-400 rounded-sm pl-5 focus:text-gray-200 hover:bg-gray-600 focus:bg-gray-600 px-1 py-0 text-xs border-gray-600 focus:ring-indigo-500 focus:border-indigo-500",
    className
  );

  return (
    <div className="relative">
      <label
        htmlFor={id}
        className="absolute left-2 top-0 bottom-0 m-y-auto flex items-center text-gray-500 pointer-events-none"
      >
        {label}
      </label>
      <input
        id={id}
        ref={reference}
        className={rootClassName}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        type="text"
        onFocus={(event: FocusEvent<HTMLInputElement>) => { event.currentTarget.select(); setEditting(true); }}
        onBlur={() => { setEditting(false); }}
        value={!editting ? value : undefined}
        disabled={!editable}
        {...rest}
      />
      {isPositionLocked && (
        <button
          type="button"
          className="focus:outline-none m-0 p-0 absolute right-1 top-0 bottom-0 margin-y-auto"
          onClick={unlockPosition}
        >
          {<LockIcon className=" w-3 h-3 text-gray-500 hover:text-gray-400" />}
        </button>
      )}
    </div>
  );
});

PositionInput.displayName = "PositionInput";

export default PositionInput;
