import { GraphicProcessor } from "lib/graphic-processor";
import { EventManager } from "lib/operations/event-manager";
import { settingsOpModes } from "lib/operations/step-operations";
import { useCallback, useEffect, useMemo } from "react";
import { CSSProperties } from "styled-components";
import { useCoordinatePositionType } from "../position/hooks/use-position";
import DynamicInputDef from "./dynamic-input-def";
import DynamicInputXYZ from "./dynamic-input-xyz";
import { useDynamicInput } from "./hooks/use-dynamic-input";
import { opState, useOpSettings } from "./hooks/use-op-settings";

export const DynamicInput = ({ graphicProc, usePosition }: { graphicProc: GraphicProcessor; usePosition: useCoordinatePositionType }) => {

  const { opSettings } = useOpSettings(graphicProc);

  if (opSettings.currentStep === null || opSettings.currentOperation === null) {
    return null
  }
  return (
    <DynamicInputComponent
      graphicProc={graphicProc}
      usePosition={usePosition}
      opSettings={opSettings}
    />
  )
}

type props = {
  graphicProc: GraphicProcessor;
  usePosition: useCoordinatePositionType,
  opSettings: opState
}

const DynamicInputComponent = ({ graphicProc, usePosition, opSettings }: props) => {

  const currentOperation = opSettings.currentOperation!
  const currentStep = opSettings.currentStep!;

  const { visible, x, y, dynInputRef, setFreeze, freeze } = useDynamicInput(graphicProc.container);

  //let xyzMode = (currentStep.stepMode === settingsOpModes.DEFAULTXYZ && currentStep.xyzModes === xyzModes.REL) ? xyzModes.REL : xyzModes.ABS;

  const eventManager = useMemo(() => new EventManager(graphicProc), [graphicProc]);

  const handleSecondMouseUp = useCallback((event: PointerEvent) => {
    if (currentStep.stepMode === settingsOpModes.WAITMODE) {
      currentStep.stepFun();
    } else {
      currentOperation.cancelOperation();
    }
  }, [currentOperation, currentStep]);

  const handleMainMouseUp = useCallback((event: PointerEvent) => {
    if (currentStep.stepMode === settingsOpModes.WAITMODE) {
      currentStep.stepFun();
    }
  }, [currentStep]);

  useEffect(() => {
    eventManager.connectMouseSecondUpEvent(handleSecondMouseUp);
    eventManager.connectMouseMainDownEvent(handleMainMouseUp);
    return () => {
      eventManager.disconnectMouseSecondUpEvent(handleSecondMouseUp);
      eventManager.disconnectMouseMainDownEvent(handleMainMouseUp);
    };
  }, [eventManager, handleMainMouseUp, handleSecondMouseUp]);

  const hiddenStyle: CSSProperties = {
    display: "inline-block",
    position: "absolute",
    overflow: "hidden",
    clip: "rect(0 0 0 0)",
    height: 1,
    width: 1,
    margin: -1,
    padding: 0,
    border: 0,
  }

  return (
    <div style={visible ? {} : hiddenStyle}>
      <div
        className="rounded-sm fixed flex items-center space-x-1 z-50"
        ref={dynInputRef}
        id="dynamic-input-component"
        style={{ top: y, left: x }}
      >
        <p className="overflow-hidden whitespace-nowrap p-1 border-gray-600 bg-gray-700 rounded-sm">
          {currentStep.infoMsg}
        </p>

        {currentStep.stepMode === settingsOpModes.DEFAULTXYZ &&
          <DynamicInputXYZ
            graphicProc={graphicProc}
            useCoordinates={usePosition}
            setFreeze={setFreeze}
            freeze={freeze}
            visible={visible}
            currentStep={currentStep}
            currentOperation={currentOperation!}
          />
        }
        {currentStep.stepMode === settingsOpModes.ONEBOX &&
          < DynamicInputDef
            graphicProc={graphicProc}
            setFreeze={setFreeze}
            freeze={freeze}
            visible={visible}
            currentStep={currentStep}
            currentOperation={currentOperation!}
          />
        }
      </div>
    </div >
  );
};
