import { cadOpType } from "../factory";
import { settingsOpModes, propSetting } from "../step-operations";
import { StructBaseOP } from "./structural";
import { isColumnData, isFooterData, isPileCapData } from '../../models/checktools';
import { ColumnData } from '../../models/structural/column';
import { getPileCapFromTemplate } from '../../geometries/structural/pile-cap';

import { PileCapFromTemplateCommand } from "lib/commands/structural/pile-cap";
import { getPhongMaterial } from "lib/materials";
import { userMessageEvents } from "lib/events/user-messages";
import { getFooterOrPileCapBasePointFromColumn } from "lib/geometries/structural/column";
import { currentPileCapTemplateId, pileCapTemplateCache } from "lib/models-struc/pile-cap-templates/cache";
import { linealPrecision } from "lib/general-settings";
import { isBiggerEqThan, isSmallerThan } from "lib/math/epsilon";

export interface pileCapBuilder {
  column: ColumnData;
  pileCapTemplateId: string;
  pilePenetration: number;
  height: number;
  diameter: number;
  depth: number;
}
export class PileCapOp extends StructBaseOP {

  public opType = cadOpType.PILECAP;

  private pilecapParams: pileCapBuilder[] = [];
  private pileCapTemplateId: string = currentPileCapTemplateId;
  private pilePenetration: number = 0.1;
  private diameter: number = 0.25;
  private height: number = 10;
  private depth: number = 0.3;

  public async start() {
    this.iniSettingsOp();
    this.registerCancel();
    this.registerRaycast();
  }

  protected iniSettingsOp(): void {
    this.settingsOpManager.setCfg([{
      infoMsg: "Select Columns",
      panelProp: this.setPanelProperties(),
      stepMode: settingsOpModes.SELECTOBJS,
      enableSelectMarks: false,
      multiSelect: true,
      filterFun: isColumnData,
      getObjsCallback: () => {
        for (const obj of this.objDataOrigin) {
          const col = obj as ColumnData;
          const strucModel = this.graphicProcessor.getStructuralModelManager();
          const col0 = strucModel.currBuilding.getBaseCompoundElement(col) as ColumnData;
          const footersOrPileCaps = col0.lnkObjs.filter(o => (isFooterData(o) || isPileCapData(o)));
          if (footersOrPileCaps.length === 0) {
            if (!this.pilecapParams.some(p => p.column === col0)) {
              this.addPileCap(col0);
              this.pilecapParams.push({
                column: col0,
                pileCapTemplateId: this.pileCapTemplateId,
                pilePenetration: this.pilePenetration,
                diameter: this.diameter,
                height: this.height,
                depth: this.depth,
              });
            } else {
              userMessageEvents.dispatchError("Columns can only have one active footer or pile cap.");
            }
          } else {
            userMessageEvents.dispatchError("Columns can only have one active footer or pile cap.");
          }
        }
        this.objDataOrigin.length = 0;
      },
    }]);
  }

  private setPanelProperties(): propSetting<pileCapBuilder> {
    const pileCapList = pileCapTemplateCache.getAllLoadedStyles();
    const pileCapTemplateListNames: [string, string][] = pileCapList.map(s => [s.name, s.styleId]);
    return {
      propValue: {
        pileCapTemplateId: {
          publicName: "Pile cap template",
          value: this.pileCapTemplateId,
          editable: true,
          type: "tagList",
          tagList: pileCapTemplateListNames,
        },
        diameter: {
          type: "number",
          publicName: "Diameter",
          value: this.diameter,
          units: "m",
          precision: linealPrecision,
        },
        height: {
          type: "number",
          publicName: "Height",
          value: this.height,
          units: "m",
          precision: linealPrecision,
        },
        depth: {
          type: "number",
          publicName: "Depth",
          value: this.depth,
          units: "m",
          editable: isSmallerThan(this.diameter, 0.3),
          parseFun: (v: number) => (isSmallerThan(v, 0.3) ? 0.3 : v),
          precision: linealPrecision,
        },
        pilePenetration: {
          type: "number",
          publicName: "Pile penetration",
          value: this.pilePenetration,
          units: "m",
          precision: linealPrecision,
        }
      },
      propCallback: this.updateParam.bind(this)
    };
  }

  private updateParam(param: pileCapBuilder) {
    this.pileCapTemplateId = param.pileCapTemplateId;
    this.pilePenetration = param.pilePenetration;
    this.diameter = param.diameter;
    this.height = param.height;
    this.depth = param.depth;

    if (isBiggerEqThan(this.diameter, 0.3)) {
      this.depth = this.diameter;
    }

    this.settingsOpManager.currCfg = {
      ...this.settingsOpManager.currCfg,
      panelProp: this.setPanelProperties(),
    }
    this.settingsOpManager.dispatchUpdateCurrStep();
  }

  private addPileCap(col: ColumnData) {
    const template = pileCapTemplateCache.loadStylefromCache(this.pileCapTemplateId)!
    const pileCap = getPileCapFromTemplate(template.capCoords, template.pileCoords, this.diameter, this.depth, this.height, this.pilePenetration, getPhongMaterial());
    const { x, y, z } = getFooterOrPileCapBasePointFromColumn(col.definition);
    pileCap.position.set(x, y, z);
    this.saveToTempScene(pileCap);
  }

  public cancelOperation(): void {
    this.endOperation();
  }

  public save() {
    if (this.pilecapParams.length) {
      const command = new PileCapFromTemplateCommand(this.pilecapParams, this.graphicProcessor);
      this.graphicProcessor.storeAndExecute(command);
    }
  }
}
