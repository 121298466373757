import { useCallback } from "react"
import { Column } from "react-table"
import { GraphicProcessor } from "lib/graphic-processor"
import { IPoint } from "lib/math/types"
import { cadOpType } from "lib/operations/factory"
import ToolbarButton from "modules/cad/components/toolbar/toolbar-button"
import Section from "shared/components/ui/section"
import Table from "shared/components/ui/table/table"
import CircleIcon from "modules/cad/components/icons/circle"
import { CircleCommand } from "lib/commands/primitives/circle"
import { CircleData } from "lib/models/primitives/circle"

const columns: Column<{}>[] = [
  { Header: "Center", accessor: "indx" },
  { Header: "X (m)", accessor: "x" },
  { Header: "Y (m)", accessor: "y" },
];
export interface pileDataTable {
  indx: number;
  x: string;
  y: string;
}

interface Props {
  graphicProc: GraphicProcessor;
  dataTable: pileDataTable[];
  isDefault: boolean;
  updatePileData(rowIndex: number, pto: IPoint): void;
  addPileData(data: CircleData): void;
}

const PileTableCoordinates = ({ graphicProc, dataTable, isDefault, updatePileData, addPileData }: Props) => {

  const updateData = useCallback((rowIndex: number, columnId: keyof pileDataTable, value: string) => {
    const key = columnId as "x" | "y";
    const val = parseFloat(value);
    const pto = { ...dataTable[rowIndex], [key]: val }
    updatePileData(rowIndex, { x: parseFloat(pto.x), y: parseFloat(pto.y), z: 0 });
  }, [dataTable, updatePileData]);

  const launchPileCapOP = () => {
    const op = graphicProc.launchOP<any>(cadOpType.CIRCLE, [0.5]);
    if (op) {
      op.endOperationCallback = (cmd: CircleCommand) => {
        const data = cmd.createdData;
        data.graphicObj.renderOrder = 1;
        addPileData(data);
      }
    }
  }

  return (
    <Section
      title="Pile coordinates"
      className={"whitespace-nowrap text-gray-400"}
      withBorder={false}
      optionalComponent={!isDefault &&
        <ToolbarButton onClick={launchPileCapOP} tooltip="Add pile cap">
          <CircleIcon />
        </ToolbarButton>
      }>
      {dataTable.length ?
        <Table
          columns={columns}
          data={dataTable}
          updateMyData={updateData}
        />
        : <p>Piles not defined</p>
      }
    </Section>
  )
}

export default PileTableCoordinates;