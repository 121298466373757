import * as THREE from "three";
import { cadOpType } from "lib/operations/factory";
import { getAbsolutePoint } from "lib/coordinates/plane";
import { LoadStructuralData } from "lib/models/structural/load";
import { SelectionManager } from "lib/selection/selection-manager";
import { GraphicProcessor } from "lib/graphic-processor";
import { IloadSelectionHandler } from "./load";

export class LoadSuperficialDataSelector implements IloadSelectionHandler {

  public buildSelectMarks(data: LoadStructuralData) {
    const { ptos2D, basePoint, rotation } = data.definition;
    const puntos = ptos2D.map(p => getAbsolutePoint(p, basePoint, rotation));
    return { vertex: puntos };
  }

  public launchMarkOP(data: LoadStructuralData, graphicProc: GraphicProcessor, selectionManager: SelectionManager, mark: THREE.Object3D, vertexIndex: number) {
    if (selectionManager.vertexSelector.pointObj === mark) {
      return {
        type: cadOpType.EDITLOADSUPERFICIAL,
        graphicProcessor: graphicProc,
        args: [data, vertexIndex, data.definition.ptos2D[vertexIndex]],
      };
    }
  }
}
