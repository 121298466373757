import { useMutation } from "@apollo/client";
import { userMessageEvents } from "lib/events/user-messages";
import { CREATE_CAD_PROJECT, GET_CAD_PROJECTS_BY_USER } from "modules/cad/services/projects";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { useAuth } from "shared/components/auth/context";
import { useUI } from "shared/components/ui/context";
import { GetProjectsData } from "./use-projects";

type Status = "idle" | "loading" | "success" | "error";

interface Data {
  project: {
    id: string;
    userId: string;
    name: string;
    updatedAt: string;
  };
}

interface Params {
  object: { name: string; userId: string };
}

interface FormData {
  name: string;
}

export function useAddProject() {

  const { user } = useAuth();
  const { closeModal } = useUI();
  const history = useHistory();
  const [status, setStatus] = useState<Status>("idle");

  const [mutate] = useMutation<Data, Params>(CREATE_CAD_PROJECT, {
    update(cache, { data }) {
      if (data) {
        const query = cache.readQuery<GetProjectsData>({
          query: GET_CAD_PROJECTS_BY_USER,
        });
        if (query) {
          cache.writeQuery({
            query: GET_CAD_PROJECTS_BY_USER,
            data: { projects: [data.project, ...query.projects] },
          });
        }
      }
    },
  });

  const { handleSubmit, register, errors } = useForm<FormData>({
    shouldFocusError: true,
    mode: "onTouched",
  });

  const addProject = useCallback(
    (data: FormData) => {
      if (user) {
        setStatus("loading");
        const object = {
          name: data.name,
          userId: user.id,
        };
        mutate({
          variables: { object },
        })
          .then(({ data }) => {
            userMessageEvents.dispatchSucces("Project created succesfully");
            setStatus("success");
            history.push(`/cad/projects/${data?.project?.id}`);
          })
          .catch(() => {
            userMessageEvents.dispatchSucces("Something happened creating project.");
            setStatus("error");
          })
          .finally(() => {
            closeModal();
          });
      }
    },
    [user, mutate, closeModal, history]
  );

  return {
    handleSubmit: handleSubmit(addProject),
    ref: register,
    status,
    errors,
  };
}
