import { DEFAULT_HEIGHT, DEFAULT_OCCUPANCY } from 'modules/struc/utils/storeys'
import { storageService } from 'shared/services/storage'
import { move } from 'shared/utils/array'
import { v4 as uuid } from 'uuid'
import { DXF, Storey } from '../models/storey'

export interface RawStorey {
  id: string
  floor_name: string
  height: number
  occupancy: string
  dxf_name: string
  dxf_url: string
}

/**
 *
 *
 * @param {number} storeyNumber
 * @param {DXF} [dxf]
 * @return {*}  {Storey}
 */
function createEmptyStorey(storeyNumber: number, dxf?: DXF): Storey {
  return {
    id: uuid(),
    storey_number: storeyNumber,
    floor_name: `F${storeyNumber}`,
    height: DEFAULT_HEIGHT,
    level: DEFAULT_HEIGHT * storeyNumber,
    occupancy: DEFAULT_OCCUPANCY,
    dxf_name: dxf?.name || null,
    dxf_url: dxf?.url || null,
    file: dxf?.file,
  }
}

export function fillStoreys(
  length: number,
  currentStoreys: Storey[],
  bulkDXF?: DXF[]
): Storey[] {
  const newLength = length - currentStoreys.length
  if (newLength > 0) {
    const emptyStoreys =
      newLength > 0
        ? [...Array(newLength)].map((_, index: number) =>
            createEmptyStorey(
              index + currentStoreys.length + 1,
              bulkDXF?.[index]
            )
          )
        : []
    return [...currentStoreys, ...emptyStoreys]
  }
  if (newLength < 0) {
    const maxIndex = Math.abs(newLength) - 1
    const filteredStoreys = currentStoreys.slice(0, maxIndex)
    return filteredStoreys
  }
  return currentStoreys
}

export function recalculateLevels(
  storeys: Storey[],
  footingLevel: number
): Storey[] {
  let currentLevel = footingLevel || 0
  const updatedLevels: number[] = storeys.map(storey => {
    const level = +storey.height + currentLevel
    currentLevel = level
    return level
  })
  return storeys.map((storey, index) => ({
    ...storey,
    storey_number: index + 1,
    level: updatedLevels[index],
  }))
}

export function updateStoreys(
  updatedStorey: Storey,
  storeys: Storey[],
  footingLevel: number
): Storey[] {
  const updatedStoreys = storeys.map(storey =>
    storey.id === updatedStorey.id ? updatedStorey : storey
  )
  return recalculateLevels(updatedStoreys, footingLevel)
}

const getBlobFromStorey = async (blobUrl: string) => {
  const response = await fetch(blobUrl)
  const blob = await response.blob()
  return blob
}

const uploadDXF = async (storey: Storey) => {
  if (!storey.dxf_url) {
    return null
  }
  const isAlreadyUploaded = !storey.dxf_url.includes('blob:')
  if (isAlreadyUploaded) {
    return storey.dxf_url
  }
  const blob = await getBlobFromStorey(storey.dxf_url)
  const url = await storageService.upload(
    `storeys/${storey.id}/${storey.dxf_name}`,
    blob
  )
  return url
}

const getStoreyWithUrl = async (storey: Storey): Promise<Storey> => {
  const dxf_url = await uploadDXF(storey)
  if (storey.file) delete storey.file;
  return {
    ...storey,
    dxf_url,
  }
}

export const getStoreysWithStorageUrl = async (
  storeys: Storey[]
): Promise<Storey[]> => await Promise.all(storeys.map(getStoreyWithUrl))

export function recalculateOrder(
  storeys: Storey[],
  source: number,
  destination: number,
  footingLevel: number
): Storey[] {
  const movedStoreys: Storey[] = move(storeys, source, destination)
  const updatedStoreys = recalculateLevels(movedStoreys, footingLevel)
  return updatedStoreys
}
