import { MatchPropertiesCommand } from "lib/commands/edition/match-prop";
import { IDimBuildProperties } from "lib/dimension/style";
import { getMatchProperties } from "lib/materials/helpers";
import { IColor } from "lib/math/types";
import { IObjData } from "lib/models/objdata";
import { TextOptsBuilder } from "lib/text/styles";
import { MultiEdition } from "../edition-multi";
import { cadOpType } from "../factory";
import { settingsOpModes } from "../step-operations";

export interface matchProperties {
  color?: IColor;
  layerId: string;
  lineStyleId?: string;
  width?: number;
  dimStyleId?: string;
  dimOverride?: Partial<IDimBuildProperties>;
  textStyleId?: string,
  textOverride?: Partial<TextOptsBuilder>;
}

export class MatchPropertiesOP extends MultiEdition {
  public opType: cadOpType.MATCHPROP;
  private objData: IObjData;

  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([
      {
        infoMsg: "Select source object",
        stepMode: settingsOpModes.SELECTOBJS,
        multiSelect: false,
        enableSelectMarks: true,
        filterFun: () => true,
        endStepCallback: async () => {
          this.objData = this.objDataOrigin[0];
          this.objDataOrigin = [];
        },
      },
      {
        infoMsg: "Select target objects",
        stepMode: settingsOpModes.SELECTOBJS,
        multiSelect: true,
        enableSelectMarks: true,
        filterFun: (o: IObjData) => {
          const res = (o !== this.objData);
          return res;
        },
        endStepCallback: async () => {
          this.unRegisterRaycast();
          this.save();
          this.endOperation();
        },
      },
    ]);
  }
  public async start() {
    this.iniSettingsOp();
    this.registerCancel();
    this.setStartObjs();
    if (this.objDataOrigin.length === 0) {
      this.registerRaycast();
    } else {
      this.setNextStep();
    }
  }
  public save() {
    if (this.graphicProcessor && this.objData !== undefined && this.objDataOrigin.length > 0) {
      const props = getMatchProperties(this.objData);
      const command = new MatchPropertiesCommand(props, this.objDataOrigin, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
}