import { storeysEvents, StoreyActionType } from "lib/events/storeys";
import { StructCounters } from "modules/struc/models/project";
import { structElemType } from "./types/struc-base";
import { iBuilding } from "./types/struc-project";
import { ColumnCompoundElement, WallCompoundElement } from "./compound-element";
import { ColumnData } from '../models/structural/column';
import { WallData } from '../models/structural/wall';
import { isColumnData, isWallData } from '../models/checktools';
import { isBiggerThan, isSmallerEqThan } from "lib/math/epsilon";
import { Storey } from "./struc-storey";
import { objDataType } from "lib/models/types";
import { IStrucElementData, SupportElementTypes } from "lib/models/structural/structural";

export class Building implements iBuilding {

  public id: string;
  public name: string;
  public remarks: string;

  public storeys: Storey[] = [];

  public columnCompoundElements: ColumnCompoundElement = new ColumnCompoundElement();
  public wallCompoundElements: WallCompoundElement = new WallCompoundElement();

  private numberOfColumns: number = 0;
  private numberOfWalls: number = 0;
  private numberOfBeams: number = 0;
  private numberOfSlabs: number = 0;
  private numberOfFooters: number = 0;
  private numberOfPileCaps: number = 0;
  private numberOfLoads: number = 0;

  public getStoreyFromId(storeyId: string): Storey | undefined {
    for (const storey of this.storeys) {
      if (storey.id === storeyId) {
        return storey;
      }
    }
  }
  public getLowerStoreyFromId(storeyId: string): Storey | undefined {
    for (let index = 0; index < this.storeys.length; index++) {
      if (this.storeys[index].id === storeyId) {
        return this.storeys[index - 1];
      }
    }
  }
  public getUpperStoreyFromId(storeyId: string): Storey | undefined {
    for (let index = 0; index < this.storeys.length; index++) {
      if (this.storeys[index].id === storeyId) {
        return this.storeys[index + 1];
      }
    }
  }
  public getStoreyFromLevel(z: number): Storey {
    let bottomLevel = -Infinity;
    let topLevel = -Infinity;
    for (let i = 0; i < this.storeys.length; i++) {
      if (this.storeys[i - 1]) {
        bottomLevel = this.storeys[i - 1].level;
      }
      topLevel = this.storeys[i].level;
      if (isBiggerThan(z, bottomLevel) && isSmallerEqThan(z, topLevel)) {
        return this.storeys[i];
      }
    }
    return this.storeys[this.storeys.length - 1];
  }

  public dispatchSetCurrentStorey(storey: Storey) {
    storeysEvents.dispatch({
      type: StoreyActionType.SETCURRENTSTOREY,
      payload: {
        storey,
        storeys: this.storeys,
      }
    });
  }

  private fixCompoundGroupName(compoundName: string, elems: SupportElementTypes[]): void {
    if (elems && elems.length) {
      const supportElems = elems;
      supportElems.forEach(d => d.compoundGroupName = compoundName);
    }
  }

  public getAllStructElements(type: structElemType) {
    const elem: IStrucElementData[] = [];
    for (const storey of this.storeys) {
      if (type === objDataType.SLAB)
        elem.push(...storey.slabs);
      if (type === objDataType.BEAM)
        elem.push(...storey.beams);
      if (type === objDataType.COLUMN)
        elem.push(...storey.columns);
      if (type === objDataType.WALL)
        elem.push(...storey.walls);
      if (type === objDataType.FOOTER)
        elem.push(...storey.footers);
      if (type === objDataType.PILECAP)
        elem.push(...storey.pileCaps);
    }
    return elem;
  }

  public clear() {
    this.columnCompoundElements.clear();
    this.wallCompoundElements.clear();
    for (const storey of this.storeys) {
      storey.beams.length = 0;
      storey.columns.length = 0;
      storey.slabs.length = 0;
      storey.walls.length = 0;
    }
    this.storeys.length = 0;
  }

  // ----------------------------------------------------------

  public getCompoundElements(type: objDataType): Map<string, SupportElementTypes[]> {
    let result: Map<string, SupportElementTypes[]> = new Map();
    if (type === objDataType.COLUMN) {
      result = this.columnCompoundElements.getElements();
    } else if (type === objDataType.WALL) {
      result = this.wallCompoundElements.getElements();
    }
    return result;
  }
  public getCompoundElementsOfGroup(type: objDataType, compoundGroupName: string): SupportElementTypes[] {
    let result: SupportElementTypes[] = [];
    if (type === objDataType.COLUMN) {
      result = this.columnCompoundElements.getElementsWithName(compoundGroupName);
    } else if (type === objDataType.WALL) {
      result = this.wallCompoundElements.getElementsWithName(compoundGroupName);
    }
    return result;
  }
  public getBaseCompoundElement<T extends SupportElementTypes>(elemData: T): T {    
    const compundGroup = isWallData(elemData) ? this.wallCompoundElements : this.columnCompoundElements;    
    const compoundName = elemData.compoundGroupName;
    const elements = compundGroup.getElementsWithName(compoundName);
    return elements[elements.length - 1] as T;
  }

  public addCompoundElements(compoundName: string, elems: SupportElementTypes[]): void {
    this.fixCompoundGroupName(compoundName, elems);
    if (isColumnData(elems[0])) {
      this.columnCompoundElements.addElements(compoundName, elems as ColumnData[]);
    } else if (isWallData(elems[0])) {
      this.wallCompoundElements.addElements(compoundName, elems as WallData[]);
    }
  }
  public insertCompoundElements(compoundName: string, startIndex: number, elems: SupportElementTypes[]): void {
    this.fixCompoundGroupName(compoundName, elems);
    if (isColumnData(elems[0])) {
      this.columnCompoundElements.insertElements(compoundName, startIndex, elems as ColumnData[]);
    } else if (isWallData(elems[0])) {
      this.wallCompoundElements.insertElements(compoundName, startIndex, elems as WallData[]);
    }
  }
  public deleteCompoundElement(compoundName: string, elem: SupportElementTypes): void {
    elem.compoundGroupName = "";
    if (isColumnData(elem)) {
      this.columnCompoundElements.removeElement(compoundName, elem);
    } else if (isWallData(elem)) {
      this.wallCompoundElements.removeElement(compoundName, elem);
    }
  }
  public deleteFullCompoundElement(compoundName: string, type: structElemType): void {
    const elements = this.getCompoundElementsOfGroup(type, compoundName);
    elements.forEach(e => e.compoundGroupName = "");
    if (type === objDataType.COLUMN) {
      this.columnCompoundElements.removeElements(compoundName);
    } else if (type === objDataType.WALL) {
      this.wallCompoundElements.removeElements(compoundName);
    }
  }

  // ----------------------------------------------------------

  public getNextColumnName() {
    this.numberOfColumns++;
    return "P" + (this.numberOfColumns).toString().padStart(3, "0");
  }
  public getNextWallName() {
    this.numberOfWalls++;
    return "W" + (this.numberOfWalls).toString().padStart(3, "0");
  }
  public getNextBeamName() {
    this.numberOfBeams++;
    return "B" + (this.numberOfBeams).toString().padStart(3, "0");
  }
  public getNextSlabName() {
    this.numberOfSlabs++;
    return "S" + (this.numberOfSlabs).toString().padStart(3, "0");
  }
  public getNextFooterName() {
    this.numberOfFooters++;
    return "F" + (this.numberOfFooters).toString().padStart(3, "0");
  }
  public getNextPileCapName() {
    this.numberOfPileCaps++;
    return "P" + (this.numberOfPileCaps).toString().padStart(3, "0");
  }
  public getNextLoadName() {
    this.numberOfLoads++;
    return "L" + (this.numberOfLoads).toString().padStart(3, "0");
  }
  public getNextElemName(type: structElemType) {
    switch (type) {
      case objDataType.BEAM: return this.getNextBeamName();
      case objDataType.WALL: return this.getNextWallName();
      case objDataType.COLUMN: return this.getNextColumnName();
      case objDataType.SLAB: return this.getNextSlabName();
      case objDataType.FOOTER: return this.getNextFooterName();
      case objDataType.PILECAP: return this.getNextPileCapName();
      case objDataType.LOAD: return this.getNextLoadName();
      default: return "";
    }
  }

  public getNextCompoundNameAndUpdateElementsName(type: structElemType, structElements: SupportElementTypes[]): string {
    const compoundName = this.getNextElemName(type);
    this.updateElementsName(compoundName, structElements);
    return compoundName;
  }
  public updateElementsName(compoundName: string, structElements: SupportElementTypes[]): void {
    for (let elem of structElements) {
      const story = this.getStoreyFromId(elem.storeyId);
      elem.definition.name = compoundName + "_" + story?.name;
    }
  }
  public recreateNextElemNames(counters: StructCounters | undefined) {
    if (counters !== undefined) {
      this.numberOfBeams = counters.numBeams;
      this.numberOfColumns = counters.numColumns;
      this.numberOfFooters = counters.numFooters;
      this.numberOfPileCaps = counters.numPileCaps;
      this.numberOfSlabs = counters.numSlabs;
      this.numberOfWalls = counters.numWalls;
      this.numberOfLoads = counters.numLoads ?? 0;
    } else {
      for (const storey of this.storeys) {
        this.numberOfBeams += storey.beams.length;
        this.numberOfSlabs += storey.slabs.length;
        this.numberOfFooters += storey.footers.length;
        this.numberOfPileCaps += storey.pileCaps.length;
      }
      this.numberOfColumns = this.columnCompoundElements.getElements().size;
      this.numberOfWalls = this.wallCompoundElements.getElements().size;
    }
  }
  public getCurrentCounters(): StructCounters {
    return {
      numBeams: this.numberOfBeams,
      numColumns: this.numberOfColumns,
      numFooters: this.numberOfFooters,
      numPileCaps: this.numberOfPileCaps,
      numSlabs: this.numberOfSlabs,
      numWalls: this.numberOfWalls,
      numLoads: this.numberOfLoads,
    };
  }

  public addStoreys(storeys: Storey[]) {
    this.storeys.push(...storeys);
  }
}
