import * as THREE from "three";
import { dimensionCache } from "lib/dimension/cache";
import { copyISegment, IPoint } from "lib/math/types";
import { objDataType } from "../types";
import { textStyleCache } from "lib/text/cache";
import { copyIPoint } from "lib/math/point";
import { createAngleDimension, updateAngleDimension } from "lib/dimension/angular-dim-builder";
import { dimDependence, DimensionData, dimensionParam } from "./dimension";
import { isLineData, isPolygonData } from "../checktools";
import { polygon } from "lib/math/polygon";
import { eulerAnglesToAxisAngle, mirrorAngle, normalizeAngle } from "lib/math/angles";
import { GraphicProcessor } from "lib/graphic-processor";
import { IEdgeReference } from "lib/operations/step-operations";

export interface angleDimensionParam extends dimensionParam {
  dimObjPlane: IPoint;
  dimDirectionAngle: number;
  objRef: [IEdgeReference, IEdgeReference];
}

export class AngleDimensionData extends DimensionData {

  public type = objDataType.ANGULARDIM;
  protected nameObj: string = "Angular dimension";
  public definition: angleDimensionParam;

  constructor(definition: angleDimensionParam) {
    super();
    this.definition = {
      styleId: definition.styleId,
      customStyleProp: definition.customStyleProp,
      dimObjPlane: copyIPoint(definition.dimObjPlane),
      dimDirectionAngle: definition.dimDirectionAngle,
      objRef: [{
        data: definition.objRef[0].data,
        p1Index: definition.objRef[0].p1Index,
        p2Index: definition.objRef[0].p2Index,
        segment: copyISegment(definition.objRef[0].segment),
      }, {
        data: definition.objRef[1].data,
        p1Index: definition.objRef[1].p1Index,
        p2Index: definition.objRef[1].p2Index,
        segment: copyISegment(definition.objRef[1].segment),
      }],
    }
  }
  static createObj(definition: angleDimensionParam) {
    const dimStyle = dimensionCache.loadStylefromCache(definition.styleId, definition.customStyleProp)!;
    const styleText = textStyleCache.loadStylefromCache(dimStyle?.textStyleId)!;
    const segments = [definition.objRef[0].segment, definition.objRef[1].segment];
    return createAngleDimension(
      segments,
      definition.dimDirectionAngle,
      dimStyle,
      styleText,
      definition.dimObjPlane
    );
  }
  public createGraphicObj() {
    this.graphicObj = AngleDimensionData.createObj(this.definition);
    this.addObjAsDependence();
  }
  public cloneDefinition(): angleDimensionParam {
    return {
      styleId: this.definition.styleId,
      customStyleProp: this.definition.customStyleProp,
      dimObjPlane: copyIPoint(this.definition.dimObjPlane),
      dimDirectionAngle: this.definition.dimDirectionAngle,
      objRef: [{
        data: this.definition.objRef[0].data,
        p1Index: this.definition.objRef[0].p1Index,
        p2Index: this.definition.objRef[0].p2Index,
        segment: copyISegment(this.definition.objRef[0].segment),
      }, {
        data: this.definition.objRef[1].data,
        p1Index: this.definition.objRef[1].p1Index,
        p2Index: this.definition.objRef[1].p2Index,
        segment: copyISegment(this.definition.objRef[1].segment),
      }],
    }
  }
  public cloneMaterial(): undefined {
    return;
  }
  public createObject(definition?: angleDimensionParam): THREE.Group {
    return AngleDimensionData.createObj(definition ?? this.definition);
  }

  // ---------------------------------------------------------------

  public override addObjAsDependence() {
    const deps = this.getDepsEdgesRef(this.definition.objRef);
    for (const d of deps) {
      d.data.addToMyDependences([{ data: this, dimPos: d.dimPos }]);
    }
  }
  public removeObjAsDependence() {
    const deps = this.getDepsEdgesRef(this.definition.objRef);
    for (const d of deps) {
      d.data.removeFromMyDependences([{ data: this, dimPos: d.dimPos }]);
    }
  }
  public regenerateReferences(graphicProc: GraphicProcessor) {
    const modelMngr = graphicProc.getDataModelManager();
    if (this.definition.objRef[0].dataId) {
      this.definition.objRef[0].data = modelMngr.getData(this.definition.objRef[0].dataId)!;
    }
    if (this.definition.objRef[1].dataId) {
      this.definition.objRef[1].data = modelMngr.getData(this.definition.objRef[1].dataId)!;
    }
  }

  public relinkOneDependence(dep: dimDependence) {
    if (dep.dimPos !== undefined) {
      this.definition.objRef[dep.dimPos].data = dep.data;
    }
  }
  public unlinkOneDependence(dimPos: number | undefined) {
    if (dimPos !== undefined) {
      this.definition.objRef[dimPos].data = undefined;
    }
  }

  // ---------------------------------------------------------------

  public override regenerateObjectFromDefinition(): void {
    this.updateDependences();
    const dimStyle = dimensionCache.loadStylefromCache(this.definition.styleId, this.definition.customStyleProp)!;
    const styleText = textStyleCache.loadStylefromCache(dimStyle?.textStyleId)!;
    updateAngleDimension(this.graphicObj, this.definition, dimStyle, styleText);
  }
  private updateDependences() {
    for (const obj of this.definition.objRef) {
      if (obj.data) {
        const data = obj.data;
        if (isLineData(data)) {
          const def = data.definition;
          // for now, with arc same as without arc
          const p1 = def.points[obj.p1Index];
          const p2 = def.points[obj.p2Index];
          obj.segment = { p1, p2 };
        } else if (isPolygonData(data)) {
          const { center, radius, sides, inscribed, angleO, plane } = data.definition;
          const points = polygon(center, radius, sides, inscribed, angleO, plane);
          const p1 = points[obj.p1Index];
          const p2 = points[obj.p2Index];
          obj.segment = { p1, p2 };
        } else {
          // TODO: getter of reference solid vertex (meshes)
        }
      }
    }
  }
  public translate(distance: IPoint): void {
    // Nothing to do
  }
  public rotate(angleX: number, angleY: number, angleZ: number, basePoint: IPoint): void {
    angleX = normalizeAngle(angleX);
    angleY = normalizeAngle(angleY);
    angleZ = normalizeAngle(angleZ);

    const { angle } = eulerAnglesToAxisAngle(angleX, angleY, angleZ);
    this.definition.dimDirectionAngle = normalizeAngle(this.definition.dimDirectionAngle - angle);
    this.regenerateObjectFromDefinition();
  }
  public scale(factorX: number, factorY: number, factorZ: number, basePoint: IPoint): void {
    // Nothing to do
  }
  public mirror(startPoint: IPoint, endPoint: IPoint): void {
    this.definition.dimDirectionAngle = mirrorAngle(this.definition.dimDirectionAngle, startPoint, endPoint);
    this.regenerateObjectFromDefinition();
  }

  // -------------------------------------------------------------------------

  protected exportDefinitionToJSON(): angleDimensionParam {
    return {
      styleId: this.definition.styleId,
      customStyleProp: this.definition.customStyleProp,
      dimObjPlane: copyIPoint(this.definition.dimObjPlane),
      dimDirectionAngle: this.definition.dimDirectionAngle,
      objRef: [{
        dataId: this.definition.objRef[0].dataId,
        p1Index: this.definition.objRef[0].p1Index,
        p2Index: this.definition.objRef[0].p2Index,
        segment: copyISegment(this.definition.objRef[0].segment),
      }, {
        dataId: this.definition.objRef[1].dataId,
        p1Index: this.definition.objRef[1].p1Index,
        p2Index: this.definition.objRef[1].p2Index,
        segment: copyISegment(this.definition.objRef[1].segment),
      }],
    }
  }
}