import { GraphicProcessor } from "lib/graphic-processor";
import { StyleCacheHandler } from "lib/styles/style-cache";
import { ShelCSSType, getDefaultShellCrossSectionShape } from "./types";
import { ShellCrossSection } from "./shellcs-shapes";

export let shellCrossSectionCache: shellCrossSectionCacheHandler;

export let currentShellCrossSectionId: string;
export let defaultFlatSectionId: string;

export class shellCrossSectionCacheHandler extends StyleCacheHandler<ShellCrossSection> {

  static initialize() {
    shellCrossSectionCache = new shellCrossSectionCacheHandler();
    shellCrossSectionCache.loadDefaultItems();
  }
  static clear() {
    shellCrossSectionCache.clear();
    shellCrossSectionCache = undefined!;
  }
  private loadDefaultItems() {
    const shellCS0 = new ShellCrossSection({
      name: "Forjado-0 (flat)",
      thickness: 0.25,
      parameters: {
        shellSectionType: ShelCSSType.FLAT,
      }
    });
    shellCS0.styleId = "ShellDef00";
    shellCS0.default = true;
    shellCrossSectionCache.saveStyle(shellCS0);
    currentShellCrossSectionId = shellCS0.styleId;
    defaultFlatSectionId = shellCS0.styleId;

    const ShellCS1 = new ShellCrossSection({
      name: "Forjado-1 (waffle)",
      thickness: 0.25,
      parameters: getDefaultShellCrossSectionShape(ShelCSSType.WAFFLE),
    });
    ShellCS1.default = true;
    ShellCS1.styleId = "ShellDef01";
    shellCrossSectionCache.saveStyle(ShellCS1);

    const shellCS2 = new ShellCrossSection({
      name: "Ground slab (flat)",
      thickness: 0.25,
      parameters: {
        shellSectionType: ShelCSSType.FLAT,
      }
    });
    shellCS2.default = true;
    shellCS2.styleId = "ShellDef02";
    shellCrossSectionCache.saveStyle(shellCS2);

    const shellCS3 = new ShellCrossSection({
      name: "Sandwich waffle slab",
      thickness: 0.25,
      parameters: getDefaultShellCrossSectionShape(ShelCSSType.SANDWICH),
    });
    shellCS3.default = true;
    shellCS3.styleId = "ShellDef04";
    shellCrossSectionCache.saveStyle(shellCS3);

    const shellCS4 = new ShellCrossSection({
      name: "wall section",
      thickness: 0.25,
      parameters: getDefaultShellCrossSectionShape(ShelCSSType.WALL),
    });
    shellCS4.default = true;
    shellCS4.styleId = "ShellDef04";
    shellCrossSectionCache.saveStyle(shellCS4);
  }

  load(itemsSaved: Record<string, ShellCrossSection>) {
    for (const id in itemsSaved) {
      if (Object.prototype.hasOwnProperty.call(itemsSaved, id)) {
        const shellCSS = new ShellCrossSection(itemsSaved[id]);
        shellCSS.styleId = id;
        this.saveStyle(shellCSS);
      }
    }
  }

  isSectionInUse(id: string, graphicProc: GraphicProcessor) {
    const strucModel = graphicProc.getStructuralModelManager();
    for (const storey of strucModel.currBuilding.storeys) {
      for (const column of storey.slabs) {
        if (column.definition.shellCrossSectionId === id)
          return true;
        if (column.definition.waffleShelCrossSectionId === id)
          return true;
      }
      for (const footer of storey.footers) {
        if (footer.definition.shellSectionId === id)
          return true;
      }
      for (const pileCap of storey.pileCaps) {
        if (pileCap.definition.pileSections === id)
          return true;
      }
    }
    return false;
  }

}
