import { flatShellSection, sandwichCrossSection, ShelCSSType, shellCrossSectionParam, waffleCrossSection, wallShellSection } from "lib/models-struc/cross-sections-shape/shell-cross-sections/types";
import { beamCrossSection, ICircular, ICustom, IQuadShape, IRectangular, ISectionI, ISectionT, ISectionU, ITube, sectionType } from "lib/models-struc/cross-sections-shape/beam-cross-sections/types";
import { BeamCSS, CircleShape, CrossSectionShape, CustomCSS, defaulCircleShape, defaulIShape, defaulRectangleShape, defaultSandwichParam, defaulTShape, defaultShellParam, defaulTubeShape, defaultWaffleShapeParam, defaulUShape, FlatCSS, IShape, QuadShape, RectangleShape, SandwichCSS, ShellCSS, TShape, TubeShape, UShape, WaffleCSS, WallCSS } from "modules/struc/models/ecore/cross-section-shape";
import { BeamCrossSection } from "lib/models-struc/cross-sections-shape/beam-cross-sections/beamcs-shapes";
import { beamCrossSectionCache } from "lib/models-struc/cross-sections-shape/beam-cross-sections/cache";
import { ShellCrossSection } from "lib/models-struc/cross-sections-shape/shell-cross-sections/shellcs-shapes";
import { shellCrossSectionCache } from "lib/models-struc/cross-sections-shape/shell-cross-sections/cache";

const beamCssTypes = ["RectangleShape", "TShape", "IShape", "UShape", "QuadShape", "CircleShape", "TubeShape", "CustomCSS"] as const;
type beamCssTypeList = typeof beamCssTypes[number];

const enum shellCssTypes { FlatCSS = "FlatCSS", WallCSS = "WallCSS", WaffleCSS = "WaffleCSS", SandwichCSS = "SandwichCSS" }
type shellCssTypeList = keyof typeof shellCssTypes

function getElemTypeString(eClassPath: string) {
  return eClassPath.split("/").pop() as beamCssTypeList | shellCssTypeList;
}
function isBeamCSS(cssType: any): cssType is beamCssTypeList {
  return beamCssTypes.includes(cssType);
}

export function getCrossSectionShapeRef(ecoreCSS: CrossSectionShape): string {
  const cssType = getElemTypeString(ecoreCSS.eClass);
  if (isBeamCSS(cssType)) {
    return createBeamCrossSectionShape(cssType, ecoreCSS);
  } else {
    return createShellCrossSectionShape(cssType, ecoreCSS);
  }
}
function createBeamCrossSectionShape(cssType: beamCssTypeList, ecoreCSS: BeamCSS) {
  const cssParam = getBeamCrossSectionParam[cssType](ecoreCSS);
  const beamCrossSection = new BeamCrossSection({ name: cssType, parameter: cssParam });
  const id = beamCrossSectionCache.saveStyle(beamCrossSection);
  return id;
}
function createShellCrossSectionShape(cssType: shellCssTypeList, ecoreCSS: ShellCSS) {
  const cssParam = getShellCrossSectionParam[cssType](ecoreCSS);
  const thickness = ecoreCSS.thickness ?? defaultShellParam.thickness;
  const shellCrossSection = new ShellCrossSection({ name: cssType, thickness, parameters: cssParam });
  const id = shellCrossSectionCache.saveStyle(shellCrossSection);
  return id;
}

//-----------------------------------------------------------------------------------

const getBeamCrossSectionParam: Record<beamCssTypeList, (ecoreCSS: BeamCSS) => beamCrossSection> = {
  RectangleShape: getRectangleParam,
  TShape: getTShapeParam,
  IShape: getIShapeParam,
  UShape: getUShapeParam,
  QuadShape: getQuadParam,
  CircleShape: getCircleParam,
  TubeShape: getTubeParam,
  CustomCSS: getCustomParam as (ecoreCSS: BeamCSS) => beamCrossSection,
};
function getRectangleParam(ecoreCSS: RectangleShape): IRectangular {
  return {
    beamSectionType: sectionType.RECT,
    height: ecoreCSS.height ?? defaulRectangleShape.height,
    width: ecoreCSS.width ?? defaulRectangleShape.width,
  }
}
function getTShapeParam(ecoreCSS: TShape): ISectionT {
  return {
    beamSectionType: sectionType.T,
    height: ecoreCSS.height ?? defaulRectangleShape.height,
    topFlangeLength: ecoreCSS.width ?? defaulRectangleShape.width,
    webThickness: ecoreCSS.wt ?? defaulTShape.wt,
    topFlangeThickness: ecoreCSS.ft ?? defaulTShape.ft,
  }
}
function getIShapeParam(ecoreCSS: IShape): ISectionI {
  return {
    beamSectionType: sectionType.I,
    height: ecoreCSS.height ?? defaulRectangleShape.height,
    topFlangeLength: ecoreCSS.width ?? defaulRectangleShape.width,
    webThickness: ecoreCSS.wt ?? defaulTShape.wt,
    topFlangeThickness: ecoreCSS.ft ?? defaulTShape.ft,
    bottomFlangeThickness: ecoreCSS.lft ?? defaulIShape.lft,
    bottomFlangeLength: ecoreCSS.lw ?? defaulIShape.lw,
  }
}
function getUShapeParam(ecoreCSS: UShape): ISectionU {
  return {
    beamSectionType: sectionType.U,
    width: ecoreCSS.width ?? defaulRectangleShape.width,
    height: ecoreCSS.height ?? defaulRectangleShape.height,
    horizontalFlangeThickness: ecoreCSS.thf ?? defaulUShape.thf,
    verticalWebThickness: ecoreCSS.tvw ?? defaulUShape.tvw,
  }
}
function getQuadParam(ecoreCSS: QuadShape): IQuadShape {
  return {
    beamSectionType: sectionType.QUAD,
    width: ecoreCSS.width ?? defaulRectangleShape.width,
    height: ecoreCSS.height ?? defaulRectangleShape.height,
    horizontalFlangeThickness: ecoreCSS.thf ?? defaulUShape.thf,
    verticalWebThickness: ecoreCSS.tvw ?? defaulUShape.tvw,
  }
}
function getCircleParam(ecoreCSS: CircleShape): ICircular {
  return {
    beamSectionType: sectionType.CIRC,
    radius: ecoreCSS.radio ?? defaulCircleShape.radio,
  }
}
function getTubeParam(ecoreCSS: TubeShape): ITube {
  return {
    beamSectionType: sectionType.TUBE,
    radius: ecoreCSS.radio ?? defaulCircleShape.radio,
    thickness: ecoreCSS.thickness ?? defaulTubeShape.thickness,
  }
}
function getCustomParam(ecoreCSS: CustomCSS): ICustom {
  return {
    beamSectionType: sectionType.CUSTOM,
    polyline: { points: [], arcs: [], isClosed: true }
  }
}

//-----------------------------------------------------------------------------------

const getShellCrossSectionParam: Record<shellCssTypeList, (ecoreCSS: ShellCSS) => shellCrossSectionParam> = {
  FlatCSS: getFlatParam,
  WallCSS: getWallParam,
  WaffleCSS: getWaffleParam,
  SandwichCSS: getSandwichParam,
}
function getFlatParam(ecoreCSS: FlatCSS): flatShellSection {
  return {
    shellSectionType: ShelCSSType.FLAT,
  }
}
function getWallParam(ecoreCSS: WallCSS): wallShellSection {
  return {
    shellSectionType: ShelCSSType.WALL,
  }
}
function getWaffleParam(ecoreCSS: WaffleCSS): waffleCrossSection {
  return {
    shellSectionType: ShelCSSType.WAFFLE,
    xInteraxis: ecoreCSS.xInteraxis ?? defaultWaffleShapeParam.xInteraxis,
    yInteraxis: ecoreCSS.yInteraxis ?? defaultWaffleShapeParam.yInteraxis,
    ribWidth: ecoreCSS.ribWidth ?? defaultWaffleShapeParam.ribWidth,
    numberOfBlocks: ecoreCSS.numberOfBlocks ?? defaultWaffleShapeParam.numberOfBlocks,
    outerNonstBeamWidth: ecoreCSS.outerNonstBeamWidth ?? defaultWaffleShapeParam.outerNonstBeamWidth,
    innerNonstBeamWidth: ecoreCSS.innerNonstBeamWidth ?? defaultWaffleShapeParam.innerNonstBeamWidth,
    minDropPanelEdge: ecoreCSS.minDropPanelEdge ?? defaultWaffleShapeParam.minDropPanelEdge,
    rotationAngle: ecoreCSS.rotationAngle ?? defaultWaffleShapeParam.rotationAngle,
    centerWaffleX: ecoreCSS.centerWaffleX ?? defaultWaffleShapeParam.centerWaffleX,
    centerWaffleY: ecoreCSS.centerWaffleY ?? defaultWaffleShapeParam.centerWaffleY,
    dropPanelRatio: ecoreCSS.dropPanelRatio ?? defaultWaffleShapeParam.dropPanelRatio,
    compressionLayerDepth: ecoreCSS.compressionLayerDepth ?? defaultWaffleShapeParam.compressionLayerDepth,
  }
}
function getSandwichParam(ecoreCSS: SandwichCSS): sandwichCrossSection {
  return {
    shellSectionType: ShelCSSType.SANDWICH,
    xInteraxis: ecoreCSS.xInteraxis ?? defaultWaffleShapeParam.xInteraxis,
    yInteraxis: ecoreCSS.yInteraxis ?? defaultWaffleShapeParam.yInteraxis,
    ribWidth: ecoreCSS.ribWidth ?? defaultWaffleShapeParam.ribWidth,
    numberOfBlocks: ecoreCSS.numberOfBlocks ?? defaultWaffleShapeParam.numberOfBlocks,
    outerNonstBeamWidth: ecoreCSS.outerNonstBeamWidth ?? defaultWaffleShapeParam.outerNonstBeamWidth,
    innerNonstBeamWidth: ecoreCSS.innerNonstBeamWidth ?? defaultWaffleShapeParam.innerNonstBeamWidth,
    minDropPanelEdge: ecoreCSS.minDropPanelEdge ?? defaultWaffleShapeParam.minDropPanelEdge,
    rotationAngle: ecoreCSS.rotationAngle ?? defaultWaffleShapeParam.rotationAngle,
    centerWaffleX: ecoreCSS.centerWaffleX ?? defaultWaffleShapeParam.centerWaffleX,
    centerWaffleY: ecoreCSS.centerWaffleY ?? defaultWaffleShapeParam.centerWaffleY,
    dropPanelRatio: ecoreCSS.dropPanelRatio ?? defaultWaffleShapeParam.dropPanelRatio,
    compressionLayerDepth: ecoreCSS.compressionLayerDepth ?? defaultWaffleShapeParam.compressionLayerDepth,
    lowerCompressionLayerDepth: ecoreCSS.lowerCompressionLayerDepth ?? defaultSandwichParam.lowerCompressionLayerDepth,
  }
}
