import { AnalysisAction, AnalysisActionType } from "lib/events/analysis";
import { ObserverManager } from "lib/events/event-bus";
import { Hypothesis } from "../hypothesis/hypothesis";

export class AnalysisDispatcher {

  public analysisObserver = new ObserverManager<AnalysisAction>();

  public subscribe(listener: (action: AnalysisAction) => void) {
    this.analysisObserver.subscribe(listener)
  }
  public unsubscribe(listener: (action: AnalysisAction) => void) {
    this.analysisObserver.unsubscribe(listener)
  }

  public dispatchSolve(hypothesisSet: Hypothesis[]) {
    this.analysisObserver.dispatch({
      type: AnalysisActionType.SOLVE_ANALYSIS,
      payload: { hypothesisSet },
    });
  }
}

export let analysisDispatcher: AnalysisDispatcher;
export function initAnalysisDispatcher() {
  analysisDispatcher = new AnalysisDispatcher();
  return analysisDispatcher;
}