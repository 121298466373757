import { angleBetweenVectors } from "lib/math/angles";
import { mulIpoint } from "lib/math/point";
import * as THREE from "three";

export function visibleHeightAtZDepth(depth: number, camera: THREE.PerspectiveCamera) {
  // compensate for cameras not positioned at z=0
  // const cameraOffset = camera.position.z;
  // if (depth < cameraOffset) depth -= cameraOffset;
  // else depth += cameraOffset;

  // vertical fov in radians
  const vFOV = camera.fov * Math.PI / 180;

  // Math.abs to ensure the result is always positive
  return 2 * Math.tan(vFOV / 2) * Math.abs(depth);
};
export function visibleWidthAtZDepth(depth: number, camera: THREE.PerspectiveCamera) {
  const height = visibleHeightAtZDepth(depth, camera);
  return height * camera.aspect;
};

/** Devuelve el plano ortogonal que más se parece al de la vista
 *
 * @export
 * @param {number} [angleLimit] Angulo en rad para considerar cambio del plano XY a otro vertical
 * @returns {(string | void)}
 */
export function getNearOrthogonalPlaneFromView(camera: THREE.Camera, angleLimit: number = Math.PI * 0.25) {
  // Normal del Plano de la camara
  const cameraDirection = camera.getWorldDirection(new THREE.Vector3());
  const Ncamera = mulIpoint({ x: cameraDirection.x, y: cameraDirection.y, z: cameraDirection.z }, -1);

  // Cálculo del ángulo más pequeño
  let angleXY = angleBetweenVectors({ x: 0, y: 0, z: 1 }, Ncamera);
  if (angleXY > Math.PI * 0.5) angleXY = Math.PI - angleXY;
  if (angleXY < angleLimit) {
    return "XY";
  } else {
    let angleXZ = angleBetweenVectors({ x: 0, y: 1, z: 0 }, Ncamera);
    if (angleXZ > Math.PI * 0.5) angleXZ = Math.PI - angleXZ;
    let angleYZ = angleBetweenVectors({ x: 1, y: 0, z: 0 }, Ncamera);
    if (angleYZ > Math.PI * 0.5) angleYZ = Math.PI - angleYZ;

    if (angleXY < angleXZ && angleXY < angleYZ) return "XY";
    if (angleXZ < angleXY && angleXZ < angleYZ) return "XZ";
    if (angleYZ < angleXY && angleYZ < angleXZ) return "YZ";
  }

  return "XY";
}

export function getFrustumFromCamera(camera: THREE.Camera, scene?: THREE.Scene) {
  const frustum = new THREE.Frustum();
  frustum.setFromProjectionMatrix(new THREE.Matrix4().multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse));
  if (scene) {
    const plane0 = new THREE.PlaneHelper(frustum.planes[0].clone(), 200, 0x4287f5);  // #4287f5
    const plane1 = new THREE.PlaneHelper(frustum.planes[1].clone(), 200, 0xb042f5);  // #b042f5
    const plane2 = new THREE.PlaneHelper(frustum.planes[2].clone(), 200, 0xffb5f3);  // #ffb5f3
    const plane3 = new THREE.PlaneHelper(frustum.planes[3].clone(), 200, 0xf54242);  // #f54242
    const plane4 = new THREE.PlaneHelper(frustum.planes[4].clone(), 200, 0x50fa43);  // #50fa43
    const plane5 = new THREE.PlaneHelper(frustum.planes[5].clone(), 200, 0xffe9ad);  // #ffe9ad
    const cam = new THREE.CameraHelper(camera.clone());

    scene.add(plane0);
    scene.add(plane1);
    scene.add(plane2);
    scene.add(plane3);
    scene.add(plane4);
    scene.add(plane5);
    scene.add(cam);
  }
  return frustum;
}