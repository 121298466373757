import { PropsWithChildren, useState } from "react";
import DetailsForm from "./section-details-form";
import MaterialsForm from "./section-materials-form";
import CodesForm from "./section-codes-form";
import StoreysForm from "./section-storeys-form";
import { useProjectForm } from "./hook/use-project-form";
import { Project } from "lib/apis/struc-project/api";
import { StrucSite, StrucStorey } from "modules/struc/models/project";
import { euConcreteTypes, euConcreteType, usConcreteType, standard } from "modules/struc/models/codes";

type Props = PropsWithChildren<{
  submit: (data: Project, storeys: StrucStorey[]) => void;
  currProject?: Project,
  site?: StrucSite,
}>

const ProjectForm = ({ submit, currProject, children, site }: Props) => {

  const { handleSubmit, project, register } = useProjectForm(currProject, site);

  const [storeys, setStoreys] = useState<StrucStorey[]>(site?.buildings[0].struc_storeys ?? []);

  const [concreteTypes, setConcreteTypes] = useState<euConcreteType[] | usConcreteType[]>(euConcreteTypes);

  return (
    <form onSubmit={handleSubmit((data) => submit(project, storeys))}>
      <div className="space-y-2">

        <DetailsForm
          project={project}
          register={register} />

        <CodesForm
          projectCodes={project.Codes}
          register={register}
        />

        <MaterialsForm
          projectCodes={project.Codes}
          register={register}
          storeys={storeys}
          setStoreys={setStoreys}
          setConcreteTypes={setConcreteTypes}
        />

        <StoreysForm
          concreteStandard={project.Codes.ConcreteStandard as standard}
          concreteTypes={concreteTypes}
          footingLevel={project.FootingLevel}
          editForm={!!currProject && !!site}
          register={register}
          storeys={storeys}
          setStoreys={setStoreys}
        />

        {children}
      </div>
    </form>
  );
};

export default ProjectForm;