import { GraphicProcessor } from "lib/graphic-processor";
import { IObjData } from "lib/models/objdata";
import { dimType } from "lib/operations/dimension/dimension-base-edit";
import { cadOpType } from "lib/operations/factory";
import { EditDataCommand } from "../edition/edit-base";

export class EditDimComand extends EditDataCommand {
    
    protected opType = cadOpType.EDITALIGNEDDIM;

    constructor(obj: IObjData, newDefinition: dimType, graphicProcessor: GraphicProcessor) {
        super(obj, newDefinition, null, graphicProcessor);
    }
}