import { lineAddVertex, lineMoveVertex } from "../../geometries/line";
import { copyIPoint, substractIpoint } from "../../math/point";
import { IPoint } from "../../math/types";
import { cadOpType } from "../factory";
import { settingsOpModes, xyzModes } from "../step-operations";
import { TranslationCommand } from "../../commands/transform/translation";
import { TransformEdition } from "./transform-edition";

export abstract class TranslationBaseOP extends TransformEdition {

  public endPoints: IPoint[] = [];
  private distanceVector: IPoint = { x: 0, y: 0, z: 0 };

  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([
      {
        infoMsg: "Select objects.",
        stepMode: settingsOpModes.SELECTOBJS,
        multiSelect: true,
        enableSelectMarks: true,
        filterFun: () => true,
        endStepCallback: () => {
          this.unRegisterRaycast();
          this.setAuxObjs();
          this.objDataAux.forEach((o) => this.saveToTempScene(o.graphicObj));
          this.registerInputs();
          this.registerUpdaters();
          this.initializeSnap();
        },
      },
      {
        infoMsg: "Specify base point",
        stepMode: settingsOpModes.DEFAULTXYZ,
        cmdLineListener: this.addPointFromExt.bind(this),
        endStepCallback: () => {
          this.basePoint = copyIPoint(this.lastPoint);
          const { x, y, z } = this.basePoint;
          lineAddVertex(this.auxLine, x, y, z, 0);
          lineAddVertex(this.auxLine, x, y, z, 1);
        },
      },
      {
        infoMsg: "Specify final point",
        stepMode: settingsOpModes.DEFAULTXYZ,
        xyzModes: xyzModes.REL,
        cmdLineListener: this.addPointFromExt.bind(this),
        endStepCallback: () => {
          this.save();
          this.endOperation();
        },
      },
    ]);
  }

  public moveLastPoint(pto: IPoint) {
    if (this.basePoint) {
      this.distanceVector = {
        x: -this.distanceVector.x,
        y: -this.distanceVector.y,
        z: -this.distanceVector.z,
      };
      for (const o of this.objDataAux) {
        o.translate(this.distanceVector);
      }
      this.distanceVector = substractIpoint(pto, this.basePoint);
      if (this.auxLine) {
        lineMoveVertex(this.auxLine, pto.x, pto.y, pto.z);
      }
      for (const o of this.objDataAux) {
        o.translate(this.distanceVector);
      }
    }
  }
}

export class TranslationOP extends TranslationBaseOP {

  public opType = cadOpType.MOVE;

  public save() {
    this.endPoints.push(copyIPoint(this.lastPoint));
    if (this.graphicProcessor && this.basePoint && this.endPoints.length) {
      const command = new TranslationCommand(
        this.basePoint,
        this.endPoints[0],
        this.objDataOrigin,
        this.graphicProcessor
      );
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
}
