import * as THREE from "three";
import { Line2 } from "three/examples/jsm/lines/Line2.js";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial.js";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry.js";

import { adjustDrawRange, updateObjBboxBSphere } from "../geometries";
import { IPoint } from "../math/types";
import { graphicProcessor000 } from '../graphic-processor';

export const preSelectionColor = "#7dd7fa";         // old "#fc88fc";
export const selectionColor = "#0066ff";            // old "#ff00ff";
export const selectionGripColor = "#A7FA00";        // old "#A7FA00";
export const selectionCenterGripColor = "#7dd7fa";
export const selectionOriginGripColor = "#0066ff";
export const selectionGripSize = 7; // pixels
export const selectionWith = 3; // pixels

export enum markTypes {
  POINT = 0,
  VERTEX,
  EDGE,
  CENTER,
  CENTROID,
  LINE,
  BBOX,

  // Used by snap hints
  CROSS,
  SQUAREVOID,
  TRIANGLEVOID,
  CIRCLEVOID,
  DIAMONDVOID,
  X,
  NEARHINT,
  TANGENT,
  PERPENDICULAR,
  PARALLEL,
}

// FLYWEIGHT TO MANAGE SELECTION MARKS MATERIALS AND TEXTURES
// **************************************************************

type marksMaterial = THREE.PointsMaterial | THREE.MeshBasicMaterial | THREE.LineBasicMaterial | LineMaterial;

class SelectionMarkMaterials {
  public type: markTypes;
  public color: string;
  public size: number;

  private material!: marksMaterial;

  constructor(type: markTypes, size: number, color: string) {
    this.type = type;
    this.color = color;
    this.size = size;
    switch (this.type) {
      case markTypes.POINT: this.createPointMaterial(); break;
      case markTypes.VERTEX: this.createVerticesMaterial(); break;
      case markTypes.EDGE: this.createEdgeMaterial(); break;
      case markTypes.CENTER: this.createCenterMaterial(); break;
      case markTypes.CENTROID: this.createCentroidMaterial(); break;
      case markTypes.LINE: this.createLineMaterial(); break;
      case markTypes.BBOX: this.createBboxMaterial(); break;
      case markTypes.CROSS: this.createCrossMaterial(); break;
      case markTypes.SQUAREVOID: this.createSquareVoidMaterial(); break;
      case markTypes.TRIANGLEVOID: this.createTriangleVoidMaterial(); break;
      case markTypes.CIRCLEVOID: this.createCircleVoidMaterial(); break;
      case markTypes.DIAMONDVOID: this.createDiamondVoidMaterial(); break;
      case markTypes.X: this.createXMaterial(); break;
      case markTypes.NEARHINT: this.createNearHintMaterial(); break;
      case markTypes.TANGENT: this.createTangentHintMaterial(); break;
      case markTypes.PERPENDICULAR: this.createPerpendicularHintMaterial(); break;
      case markTypes.PARALLEL: this.createParallelHintMaterial(); break;
      default: break;
    }
  }
  public getMaterial() {
    return this.material;
  }
  // ********************************************************
  private createPointMaterial() {
    const canvas = drawCanvasSquare(this.size);
    const texture = new THREE.Texture(canvas);
    texture.needsUpdate = true;
    this.material = new THREE.PointsMaterial({
      size: this.size,
      color: this.color,
      depthTest: true,
      depthWrite: true,
      opacity: 1,
      sizeAttenuation: false,
      depthFunc: THREE.LessEqualDepth,
      transparent: true,
      map: texture,
    });
  }
  private createVerticesMaterial() {
    const texture = new THREE.Texture(drawCanvasSquare(this.size));
    texture.needsUpdate = true;
    this.material = new THREE.PointsMaterial({
      size: this.size,
      depthTest: true,
      depthWrite: true,
      opacity: 1,
      sizeAttenuation: false,
      depthFunc: THREE.LessEqualDepth,
      transparent: false,
      map: texture,
      vertexColors: true,
    });
  }
  private createEdgeMaterial() {
    this.material = new THREE.MeshBasicMaterial({
      color: this.color,
      side: THREE.DoubleSide,
    });
  }
  private createCenterMaterial() {
    const canvas = drawCanvasCircle(this.size);
    const texture = new THREE.Texture(canvas);
    texture.needsUpdate = true;
    this.material = new THREE.PointsMaterial({
      size: this.size,
      color: this.color,
      depthTest: true,
      depthWrite: true,
      opacity: 1,
      sizeAttenuation: false,
      depthFunc: THREE.LessEqualDepth,
      transparent: true,
      map: texture,
    });
  }
  private createCentroidMaterial() {
    const canvas = drawCanvasCenter(this.size);
    const texture = new THREE.Texture(canvas);
    texture.needsUpdate = true;
    this.material = new THREE.PointsMaterial({
      size: this.size,
      color: this.color,
      depthTest: true,
      depthWrite: true,
      opacity: 1,
      sizeAttenuation: false,
      depthFunc: THREE.LessEqualDepth,
      transparent: true,
      map: texture,
    });
  }
  private createLineMaterial() {
    this.material = new LineMaterial({
      linewidth: selectionWith, // in pixels
      opacity: 0.7,
      transparent: true,
      depthFunc: THREE.NeverDepth,
    });
    this.material.color = new THREE.Color(selectionColor);
  }
  private createBboxMaterial() {
    this.material = new THREE.LineBasicMaterial({
      linewidth: selectionWith, // in pixels
      opacity: 0.7,
      transparent: true,
      depthFunc: THREE.LessEqualDepth,
    });
    this.material.color = new THREE.Color(selectionColor);
  }
  // ********************************************************
  private createCrossMaterial() {
    const canvas = drawCanvasCross(this.size);
    const texture = new THREE.Texture(canvas);
    texture.needsUpdate = true;
    this.material = new THREE.PointsMaterial({
      size: this.size,
      color: this.color,
      depthTest: true,
      depthWrite: true,
      opacity: 1,
      sizeAttenuation: false,
      depthFunc: THREE.LessEqualDepth,
      transparent: true,
      map: texture,
    });
  }
  private createSquareVoidMaterial() {
    const canvas = drawCanvasSquareVoid(this.size);
    const texture = new THREE.Texture(canvas);
    texture.needsUpdate = true;
    this.material = new THREE.PointsMaterial({
      size: this.size,
      color: this.color,
      depthTest: true,
      depthWrite: true,
      opacity: 1,
      sizeAttenuation: false,
      depthFunc: THREE.AlwaysDepth,
      transparent: true,
      map: texture,
    });
  }
  private createTriangleVoidMaterial() {
    const canvas = drawCanvasTriangleVoid(this.size);
    const texture = new THREE.Texture(canvas);
    texture.needsUpdate = true;
    this.material = new THREE.PointsMaterial({
      size: this.size,
      color: this.color,
      depthTest: true,
      depthWrite: true,
      opacity: 1,
      sizeAttenuation: false,
      depthFunc: THREE.AlwaysDepth,
      transparent: true,
      map: texture,
    });
  }
  private createCircleVoidMaterial() {
    const canvas = drawCanvasCircleVoid(this.size);
    const texture = new THREE.Texture(canvas);
    texture.needsUpdate = true;
    this.material = new THREE.PointsMaterial({
      size: this.size,
      color: this.color,
      depthTest: true,
      depthWrite: true,
      opacity: 1,
      sizeAttenuation: false,
      depthFunc: THREE.AlwaysDepth,
      transparent: true,
      map: texture,
    });
  }
  private createDiamondVoidMaterial() {
    const canvas = drawCanvasDiamondVoid(this.size);
    const texture = new THREE.Texture(canvas);
    texture.needsUpdate = true;
    this.material = new THREE.PointsMaterial({
      size: this.size,
      color: this.color,
      depthTest: true,
      depthWrite: true,
      opacity: 1,
      sizeAttenuation: false,
      depthFunc: THREE.AlwaysDepth,
      transparent: true,
      map: texture,
    });
  }
  private createXMaterial() {
    const canvas = drawCanvasX(this.size);
    const texture = new THREE.Texture(canvas);
    texture.needsUpdate = true;
    this.material = new THREE.PointsMaterial({
      size: this.size,
      color: this.color,
      depthTest: true,
      depthWrite: true,
      opacity: 1,
      sizeAttenuation: false,
      depthFunc: THREE.AlwaysDepth,
      transparent: true,
      map: texture,
    });
  }
  private createNearHintMaterial() {
    const canvas = drawCanvasNearHint(this.size);
    const texture = new THREE.Texture(canvas);
    texture.needsUpdate = true;
    this.material = new THREE.PointsMaterial({
      size: this.size,
      color: this.color,
      depthTest: true,
      depthWrite: true,
      opacity: 1,
      sizeAttenuation: false,
      depthFunc: THREE.AlwaysDepth,
      transparent: true,
      map: texture,
    });
  }
  private createTangentHintMaterial() {
    const canvas = drawCanvasTangentHint(this.size);
    const texture = new THREE.Texture(canvas);
    texture.needsUpdate = true;
    this.material = new THREE.PointsMaterial({
      size: this.size,
      color: this.color,
      depthTest: true,
      depthWrite: true,
      opacity: 1,
      sizeAttenuation: false,
      depthFunc: THREE.AlwaysDepth,
      transparent: true,
      map: texture,
    });
  }
  private createPerpendicularHintMaterial() {
    const canvas = drawCanvasPerpendicularHint(this.size);
    const texture = new THREE.Texture(canvas);
    texture.needsUpdate = true;
    this.material = new THREE.PointsMaterial({
      size: this.size,
      color: this.color,
      depthTest: true,
      depthWrite: true,
      opacity: 1,
      sizeAttenuation: false,
      depthFunc: THREE.AlwaysDepth,
      transparent: true,
      map: texture,
    });
  }
  private createParallelHintMaterial() {
    const canvas = drawCanvasParallelHint(this.size);
    const texture = new THREE.Texture(canvas);
    texture.needsUpdate = true;
    this.material = new THREE.PointsMaterial({
      size: this.size,
      color: this.color,
      depthTest: true,
      depthWrite: true,
      opacity: 1,
      sizeAttenuation: false,
      depthFunc: THREE.AlwaysDepth,
      transparent: true,
      map: texture,
    });
  }
}
const marksMaterialsCache: SelectionMarkMaterials[] = [];

export function getMarkMaterial(type: markTypes, size: number, color: string): marksMaterial {
  for (const mark of marksMaterialsCache) {
    if (mark.type === type && mark.size === size && mark.color === color) {
      return mark.getMaterial();
    }
  }
  const newMarkMaterial = new SelectionMarkMaterials(type, size, color);
  marksMaterialsCache.push(newMarkMaterial);
  return newMarkMaterial.getMaterial();
}

export function cleanMarksMaterialsCache() {
  for (const mat of marksMaterialsCache) {
    const material = mat.getMaterial() as THREE.PointsMaterial;
    if (material.map) {
      material.map?.dispose();
      material.map = null
    }
    material.dispose();
  }
  marksMaterialsCache.length = 0;

  canvasCenterMap.clear();
  canvasCircleMap.clear();
  canvasSquareMap.clear();
  canvasCrossMap.clear();
  canvasSquareVoidMap.clear();
  canvasTriangleVoidMap.clear();
  canvasCircleVoidMap.clear();
  canvasDiamondVoidMap.clear();
  canvasXMap.clear();
  canvasNearHintMap.clear();
  canvasTangentHintMap.clear();
  canvasPerpendicularHintMap.clear();
  canvasParallelHintMap.clear();
  arrowGeom?.dispose();
  arrowGeom = undefined;
}

const canvasCenterMap = new Map<number, HTMLCanvasElement>();
function drawCanvasCenter(size: number): HTMLCanvasElement {
  if (canvasCenterMap.has(size)) {
    return canvasCenterMap.get(size) as HTMLCanvasElement;
  }
  const canvas: HTMLCanvasElement = document.createElement("canvas");
  canvas.width = size;
  canvas.height = size;
  const context = canvas.getContext("2d") as CanvasRenderingContext2D;
  const center: number = size * 0.5;
  context.beginPath();
  context.arc(center, center, center * 0.9, 0, Math.PI * 2, true);
  context.lineWidth = center * 0.3;
  context.strokeStyle = "#ffffff";
  context.stroke();
  context.beginPath();
  context.arc(center, center, center * 0.3, 0, Math.PI * 2, true);
  context.fillStyle = "#ffffff";
  context.fill();
  canvasCenterMap.set(size, canvas);
  return canvas;
}
const canvasCircleMap = new Map<number, HTMLCanvasElement>();
function drawCanvasCircle(size: number): HTMLCanvasElement {
  if (canvasCircleMap.has(size)) {
    return canvasCircleMap.get(size) as HTMLCanvasElement;
  }
  const canvas: HTMLCanvasElement = document.createElement("canvas");
  canvas.width = size;
  canvas.height = size;
  const context = canvas.getContext("2d") as CanvasRenderingContext2D;
  const center: number = size * 0.5;
  context.beginPath();
  context.arc(center, center, center, 0, 2 * Math.PI, false);
  context.fillStyle = "#ffffff";
  context.fill();
  canvasCircleMap.set(size, canvas);
  return canvas;
}
const canvasSquareMap = new Map<number, HTMLCanvasElement>();
function drawCanvasSquare(size: number): HTMLCanvasElement {
  if (canvasSquareMap.has(size)) {
    return canvasSquareMap.get(size) as HTMLCanvasElement;
  }
  const canvas: HTMLCanvasElement = document.createElement("canvas");
  canvas.width = size;
  canvas.height = size;
  const context = canvas.getContext("2d") as CanvasRenderingContext2D;
  context.fillStyle = "#ffffff";
  context.fillRect(0, 0, size, size);
  canvasSquareMap.set(size, canvas);
  return canvas;
}
const canvasCrossMap = new Map<number, HTMLCanvasElement>();
function drawCanvasCross(size: number): HTMLCanvasElement {
  if (canvasCrossMap.has(size)) {
    return canvasCrossMap.get(size) as HTMLCanvasElement;
  }
  const canvas: HTMLCanvasElement = document.createElement("canvas");
  canvas.width = size;
  canvas.height = size;
  const context = canvas.getContext("2d") as CanvasRenderingContext2D;
  const center: number = size * 0.5;
  context.beginPath();
  context.lineWidth = 2;
  context.strokeStyle = "#ffffff";
  context.moveTo(center, 0);
  context.lineTo(center, size);
  context.moveTo(0, center);
  context.lineTo(size, center);
  context.stroke();
  canvasCrossMap.set(size, canvas);
  return canvas;
}
const canvasSquareVoidMap = new Map<number, HTMLCanvasElement>();
function drawCanvasSquareVoid(size: number): HTMLCanvasElement {
  if (canvasSquareVoidMap.has(size)) {
    return canvasSquareVoidMap.get(size) as HTMLCanvasElement;
  }
  const canvas: HTMLCanvasElement = document.createElement("canvas");
  canvas.width = size;
  canvas.height = size;
  const context = canvas.getContext("2d") as CanvasRenderingContext2D;
  context.lineWidth = 5;
  context.strokeStyle = "#ffffff";
  context.strokeRect(0, 0, size, size);
  canvasSquareVoidMap.set(size, canvas);
  return canvas;
}
const canvasTriangleVoidMap = new Map<number, HTMLCanvasElement>();
function drawCanvasTriangleVoid(size: number): HTMLCanvasElement {
  if (canvasTriangleVoidMap.has(size)) {
    return canvasTriangleVoidMap.get(size) as HTMLCanvasElement;
  }
  const canvas: HTMLCanvasElement = document.createElement("canvas");
  canvas.width = size;
  canvas.height = size;
  const context = canvas.getContext("2d") as CanvasRenderingContext2D;
  const center: number = size * 0.5;
  context.moveTo(0, size);
  context.beginPath();
  context.lineTo(center, 0);
  context.lineTo(size, size);
  context.lineTo(0, size);
  context.closePath();
  context.lineWidth = 3;
  context.strokeStyle = "#ffffff";
  context.stroke();
  canvasTriangleVoidMap.set(size, canvas);
  return canvas;
}
const canvasCircleVoidMap = new Map<number, HTMLCanvasElement>();
function drawCanvasCircleVoid(size: number): HTMLCanvasElement {
  if (canvasCircleVoidMap.has(size)) {
    return canvasCircleVoidMap.get(size) as HTMLCanvasElement;
  }
  const canvas: HTMLCanvasElement = document.createElement("canvas");
  canvas.width = size;
  canvas.height = size;
  const context = canvas.getContext("2d") as CanvasRenderingContext2D;
  const center: number = size * 0.5;
  context.beginPath();
  context.arc(center, center, center * 0.8, 0, 2 * Math.PI, false);
  context.lineWidth = 2;
  context.strokeStyle = "#ffffff";
  context.stroke();
  canvasCircleVoidMap.set(size, canvas);
  return canvas;
}
const canvasDiamondVoidMap = new Map<number, HTMLCanvasElement>();
function drawCanvasDiamondVoid(size: number): HTMLCanvasElement {
  if (canvasDiamondVoidMap.has(size)) {
    return canvasDiamondVoidMap.get(size) as HTMLCanvasElement;
  }
  const canvas: HTMLCanvasElement = document.createElement("canvas");
  canvas.width = size;
  canvas.height = size;
  const context = canvas.getContext("2d") as CanvasRenderingContext2D;
  const center: number = size * 0.5;
  context.moveTo(0, center);
  context.beginPath();
  context.lineTo(center, 0);
  context.lineTo(size, center);
  context.lineTo(center, size);
  context.lineTo(0, center);
  context.closePath();
  context.lineWidth = 2;
  context.strokeStyle = "#ffffff";
  context.stroke();
  canvasDiamondVoidMap.set(size, canvas);
  return canvas;
}
const canvasXMap = new Map<number, HTMLCanvasElement>();
function drawCanvasX(size: number): HTMLCanvasElement {
  if (canvasXMap.has(size)) {
    return canvasXMap.get(size) as HTMLCanvasElement;
  }
  const canvas: HTMLCanvasElement = document.createElement("canvas");
  canvas.width = size;
  canvas.height = size;
  const context = canvas.getContext("2d") as CanvasRenderingContext2D;
  context.beginPath();
  context.lineWidth = 3;
  context.strokeStyle = "#ffffff";
  context.moveTo(0, 0);
  context.lineTo(size, size);
  context.moveTo(0, size);
  context.lineTo(size, 0);
  context.stroke();
  canvasXMap.set(size, canvas);
  return canvas;
}
const canvasNearHintMap = new Map<number, HTMLCanvasElement>();
function drawCanvasNearHint(size: number): HTMLCanvasElement {
  if (canvasNearHintMap.has(size)) {
    return canvasNearHintMap.get(size) as HTMLCanvasElement;
  }
  const canvas: HTMLCanvasElement = document.createElement("canvas");
  canvas.width = size;
  canvas.height = size;
  const context = canvas.getContext("2d") as CanvasRenderingContext2D;
  context.beginPath();
  context.lineWidth = 3;
  context.strokeStyle = "#ffffff";
  context.moveTo(0, 0);
  context.lineTo(size, 0);
  context.lineTo(0, size);
  context.lineTo(size, size);
  context.lineTo(0, 0);
  context.stroke();
  canvasNearHintMap.set(size, canvas);
  return canvas;
}
const canvasTangentHintMap = new Map<number, HTMLCanvasElement>();
function drawCanvasTangentHint(size: number): HTMLCanvasElement {
  if (canvasTangentHintMap.has(size)) {
    return canvasTangentHintMap.get(size) as HTMLCanvasElement;
  }
  const canvas: HTMLCanvasElement = document.createElement("canvas");
  canvas.width = size;
  canvas.height = size;
  const context = canvas.getContext("2d") as CanvasRenderingContext2D;
  context.beginPath();
  context.lineWidth = 3;
  context.strokeStyle = "#ffffff";
  context.moveTo(0, 0);
  context.lineTo(size, 0);
  context.stroke();

  const center: number = size * 0.5;
  context.moveTo(size, center);
  context.arc(center, center, center * 0.75, 0, 2 * Math.PI, false);
  context.lineWidth = 2;
  context.stroke();
  canvasTangentHintMap.set(size, canvas);
  return canvas;
}
const canvasPerpendicularHintMap = new Map<number, HTMLCanvasElement>();
function drawCanvasPerpendicularHint(size: number): HTMLCanvasElement {
  if (canvasPerpendicularHintMap.has(size)) {
    return canvasPerpendicularHintMap.get(size) as HTMLCanvasElement;
  }
  const canvas: HTMLCanvasElement = document.createElement("canvas");
  canvas.width = size;
  canvas.height = size;
  const context = canvas.getContext("2d") as CanvasRenderingContext2D;
  context.beginPath();
  context.lineWidth = 3;
  context.strokeStyle = "#ffffff";
  context.moveTo(0, 0);
  context.lineTo(0, size);
  context.lineTo(size, size);
  const center: number = size * 0.5;
  context.moveTo(0, center);
  context.lineTo(center, center);
  context.lineTo(center, size);
  context.stroke();
  canvasPerpendicularHintMap.set(size, canvas);
  return canvas;
}
const canvasParallelHintMap = new Map<number, HTMLCanvasElement>();
function drawCanvasParallelHint(size: number): HTMLCanvasElement {
  if (canvasParallelHintMap.has(size)) {
    return canvasParallelHintMap.get(size) as HTMLCanvasElement;
  }
  const canvas: HTMLCanvasElement = document.createElement("canvas");
  canvas.width = size;
  canvas.height = size;
  const context = canvas.getContext("2d") as CanvasRenderingContext2D;
  context.beginPath();
  context.lineWidth = 3;
  context.strokeStyle = "#ffffff";

  const div: number = size / 3;
  context.moveTo(0, div * 2);
  context.lineTo(div * 2, 0);
  context.moveTo(div, size);
  context.lineTo(size, div);
  context.stroke();
  canvasParallelHintMap.set(size, canvas);
  return canvas;
}

let arrowGeom: THREE.ConeBufferGeometry | undefined;
function getConeGeometry(size?: number): THREE.ConeBufferGeometry {
  if (arrowGeom === undefined) {
    if (size === undefined) size = 1;
    arrowGeom = new THREE.ConeBufferGeometry(size * 0.5, size * 1.5, 32);
  }
  return arrowGeom;
}

// BUILDERS SELECTION MARKS
// **************************************************************

/** VERTEX selection mark
 *
 * @export
 * @param {IPoint} [position]
 * @param {(string | number)} [color]
 * @param {number} [size]
 * @returns {THREE.Points}
 */
function getVertexMark(position?: IPoint, color?: string, size?: number): THREE.Points {
  if (position === undefined) position = { x: 0, y: 0, z: 0 };
  if (color === undefined) color = selectionGripColor;
  if (size === undefined) size = selectionGripSize;
  const staticSprite = getStaticSprite(markTypes.POINT, size, color, position);
  return staticSprite;
}
function getVertexMarks(buffer: Float32Array, color?: string | number, size?: number): THREE.Points {
  if (color === undefined) color = selectionGripColor;
  if (size === undefined) size = selectionGripSize;

  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute("position", new THREE.BufferAttribute(buffer, 3));

  const colArr: Float32Array = new Float32Array(buffer.length);
  const colorVertex = new THREE.Color(color);
  let count = 0;
  for (let i = 0; i < buffer.length / 3; i++) {
    colArr[count++] = colorVertex.r;
    colArr[count++] = colorVertex.g;
    colArr[count++] = colorVertex.b;
  }
  geometry.setAttribute("color", new THREE.BufferAttribute(colArr, 3));

  const material = getMarkMaterial(markTypes.VERTEX, size, color as string);
  const mark = new THREE.Points(geometry, material);
  updateObjBboxBSphere(mark);
  adjustDrawRange(mark);
  return mark;
}
function setOriginVertex(vertex: THREE.Points) {
  const colorOrigin = new THREE.Color(selectionOriginGripColor);
  const geometry = vertex.geometry as THREE.BufferGeometry;
  const colors = geometry.getAttribute("color") as THREE.BufferAttribute;
  const buffer = colors.array as Float32Array;
  buffer[0] = colorOrigin.r;
  buffer[1] = colorOrigin.g;
  buffer[2] = colorOrigin.b;
}

/** EDGE selection mark
 *
 * @export
 * @param {IPoint} position
 * @param {(string | number)} [color]
 * @param {number} [size]
 * @returns {THREE.Mesh}
 */
export function getEdgeMark(position: IPoint, color?: string, size?: number): THREE.Mesh {
  if (color === undefined) color = selectionGripColor;
  if (size === undefined) {
    size = graphicProcessor000.getSizeUnitFromPixelUnit(selectionGripSize, position);
  }
  const material = getMarkMaterial(markTypes.EDGE, 0, color);
  const geometry = getConeGeometry();
  const mark = new THREE.Mesh(geometry, material);
  mark.position.set(position.x, position.y, position.z);
  mark.scale.set(size, size, size);
  return mark;
}

/** CENTROID selection mark
 *
 * @export
 * @param {IPoint} [position]
 * @param {(string | number)} [color]
 * @param {number} [size]
 * @returns {THREE.Points}
 */
function getCentroidMark(position?: IPoint, color?: string, size?: number): THREE.Points {
  if (position === undefined) position = { x: 0, y: 0, z: 0 };
  if (color === undefined) color = selectionGripColor;
  if (size === undefined) size = selectionGripSize;
  const staticSprite = getStaticSprite(markTypes.CENTER, size, color, position);
  return staticSprite;
}
/** CENTER selection mark
 *
 * @export
 * @param {IPoint} [position]
 * @param {(string | number)} [color]
 * @param {number} [size]
 * @returns {THREE.Points}
 */
function getCenterMark(position?: IPoint, color?: string, size?: number): THREE.Points {
  if (position === undefined) position = { x: 0, y: 0, z: 0 };
  if (color === undefined) color = selectionCenterGripColor;
  if (size === undefined) size = selectionGripSize;
  const staticSprite = getStaticSprite(
    markTypes.CENTROID,
    size,
    color,
    position
  );
  return staticSprite;
}
/** BBOX selection mark
 *
 * @export
 * @param {THREE.Object3D} bbox
 * @param {(string | number)} [color]
 * @returns {THREE.BoxHelper}
 */
export function getBboxMark(bbox: THREE.Object3D, color?: string): THREE.BoxHelper {
  if (color === undefined) color = selectionColor;
  const bboxMark = new THREE.BoxHelper(bbox);
  const material = getMarkMaterial(markTypes.BBOX, 0, color);
  bboxMark.material = material;
  updateObjBboxBSphere(bboxMark);
  return bboxMark;
}
/** LINES selection marks
 *
 * @export
 * @param {(number[] | Float32Array)} coords
 * @returns {Line2}
 */
function getLineMark(coords: number[] | Float32Array): Line2 {
  const geom = new LineGeometry();
  geom.setPositions(coords);
  const material = getMarkMaterial(markTypes.LINE, selectionWith, selectionColor) as LineMaterial;
  material.resolution.set(window.innerWidth, window.innerHeight);
  const fatLine = new Line2(geom, material);
  fatLine.renderOrder = -900;
  return fatLine;
}
/** CROSS symbol point
 *
 * @export
 * @param {IPoint} [position]
 * @param {string} [color]
 * @param {number} [size]
 * @returns {THREE.Points}
 */
export function getCrossMark(position?: IPoint, color?: string, size?: number): THREE.Points {
  if (position === undefined) position = { x: 0, y: 0, z: 0 };
  if (color === undefined) color = selectionCenterGripColor;
  if (size === undefined) size = selectionGripSize;
  const staticSprite = getStaticSprite(
    markTypes.CROSS,
    size,
    color,
    position
  );
  return staticSprite;
}

function getStaticSprite(type: markTypes, size?: number, color?: string, position?: IPoint): THREE.Points {
  size = size ? size : 4; // píxeles
  position = position ? position : { x: 0, y: 0, z: 0 };
  color = color ? color : "#ffffff";

  const buffer = new THREE.BufferAttribute(new Float32Array([0, 0, 0]), 3);
  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute("position", buffer);
  const material = getMarkMaterial(type, size, color);
  const staticSprite = new THREE.Points(geometry, material);
  staticSprite.position.set(position.x, position.y, position.z);
  updateObjBboxBSphere(staticSprite);
  if (staticSprite.geometry.boundingSphere) {
    staticSprite.geometry.boundingSphere.radius = graphicProcessor000.getSizeUnitFromPixelUnit(size, position);
  }
  return staticSprite;
}