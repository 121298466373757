import { cadClipBoard } from "lib/clipboard";
import { materialCache } from "lib/materials";
import { cleanFontMaterialCache } from "lib/text/builder";
import { cleanMarksMaterialsCache } from "lib/selection/selection-tools";
import { DimStyleBuilder } from "lib/dimension/style";
import { dimensionCache, DimensionCacheHandler } from "lib/dimension/cache";
import { LineStyleBuilder } from "lib/line-styles/line";
import { lineStyleCache, LineStyleCacheHandler } from "lib/line-styles/cache";
import { TextOptsBuilder } from "lib/text/styles";
import { textStyleCache, TextStyleCacheHandler } from "lib/text/cache";
import { PileCapTemplate } from "lib/models-struc/pile-cap-templates/pile-cap-template";
import { pileCapTemplateCache, pileCapTemplateCacheHandler } from "lib/models-struc/pile-cap-templates/cache";
import { ShellCrossSection } from "lib/models-struc/cross-sections-shape/shell-cross-sections/shellcs-shapes";
import { shellCrossSectionCache, shellCrossSectionCacheHandler } from "lib/models-struc/cross-sections-shape/shell-cross-sections/cache";
import { BeamCrossSection } from "lib/models-struc/cross-sections-shape/beam-cross-sections/beamcs-shapes";
import { beamCrossSectionCache, beamCrossSectionCacheHandler } from "lib/models-struc/cross-sections-shape/beam-cross-sections/cache";

export interface Resources {
  shell_cross_section: Record<string, ShellCrossSection>,
  beam_cross_section: Record<string, BeamCrossSection>,
  pile_cap_templates: Record<string, PileCapTemplate>,
  dimension_styles: Record<string, DimStyleBuilder>,
  line_styles: Record<string, LineStyleBuilder>,
  text_styles: Record<string, TextOptsBuilder>,
}

export function initializeResources() {
  LineStyleCacheHandler.initialize();
  TextStyleCacheHandler.initialize();
  DimensionCacheHandler.initialize();
  shellCrossSectionCacheHandler.initialize();
  beamCrossSectionCacheHandler.initialize();
  pileCapTemplateCacheHandler.initialize();
}

export function loadResources(resources: Resources) {
  lineStyleCache.load(resources.line_styles ?? {});
  textStyleCache.load(resources.text_styles ?? {});
  dimensionCache.load(resources.dimension_styles ?? {});
};

export function loadStrucResources(resources: Resources) {
  shellCrossSectionCache.load(resources.shell_cross_section ?? {});
  beamCrossSectionCache.load(resources.beam_cross_section ?? {});
  pileCapTemplateCache.load(resources.pile_cap_templates ?? {});
};

export function unloadResources() {

  LineStyleCacheHandler.clear();
  TextStyleCacheHandler.clear();
  DimensionCacheHandler.clear();
  shellCrossSectionCacheHandler.clear();
  beamCrossSectionCacheHandler.clear();
  pileCapTemplateCacheHandler.clear();

  cleanMarksMaterialsCache();
  cleanFontMaterialCache();
  materialCache.clear();
  cadClipBoard.clear();
};
