import { BeamCommand } from "lib/commands/structural/beam";
import { createBeam, updateBeam } from "lib/geometries/structural/beam";
import { copyIPoint, normalizeIpoint } from "lib/math/point";
import { IPoint } from "lib/math/types";
import { growthWidth, growthWidthTypeNames } from "lib/models-struc/types/struc-base";
import { dataInfoProperty } from "lib/properties/properties";
import { cadOpType } from "../factory";
import { settingOp, settingsOpModes } from "../step-operations";
import { StructElementBaseOP } from "./structural";
import { getRotationVector } from "lib/math/rotate";
import { beamCrossSectionCache, currentBeamCrossSectionId } from "lib/models-struc/cross-sections-shape/beam-cross-sections/cache";

interface IBeamSettings {
  crossSectionId: string;
  growthType: growthWidth;
}

export class BeamOP extends StructElementBaseOP {
  public opType = cadOpType.BEAM;

  private points: IPoint[] = [];
  private crossSectionId = currentBeamCrossSectionId;
  private growthType: growthWidth = growthWidth.CENTER;

  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Insert begin point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
      panelProp: this.setPanelProperties(),
    }, {
      infoMsg: "Insert end point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
      startStepCallback: (step: settingOp) => {
        step.panelProp = this.setPanelProperties();
      },
      endStepCallback: () => {
        this.endOperation();
      }
    }]);
  }

  private setPanelProperties() {
    const beamCS = beamCrossSectionCache.getAllLoadedStyles().sort((a, b) => a.name.localeCompare(b.name));
    const beamCrossSectionListNames: [string, string][] = beamCS.map(s => [s.name, s.styleId]);
    const panelProp: dataInfoProperty<IBeamSettings> = {
      crossSectionId: {
        publicName: "Beam cross section",
        value: this.crossSectionId,
        editable: true,
        type: "tagList",
        tagList: beamCrossSectionListNames,
      },
      growthType: {
        publicName: "Growth type",
        type: "list",
        list: growthWidthTypeNames,
        value: this.growthType
      }
    }
    return {
      propValue: panelProp,
      propCallback: this.updatePanel.bind(this),
    };
  }

  public updatePanel(v: IBeamSettings) {
    this.crossSectionId = v.crossSectionId;
    this.growthType = v.growthType;
    this.settingsOpManager.currCfg = {
      ...this.settingsOpManager.currCfg,
      panelProp: this.setPanelProperties(),
    }
    this.settingsOpManager.dispatchUpdateCurrStep();
  }

  public async start() {
    await super.start();
    this.auxMesh = createBeam([], this.crossSectionId, this.growthType);
    this.saveToTempScene(this.auxMesh);
  }

  public setLastPoint(): void {
    if (this.numPoints === 1) {
      const planeManager = this.graphicProcessor.getPlaneManager();
      planeManager.activePlane.position = copyIPoint(this.lastPoint);
      planeManager.activePlane.rotation.x = 0;
      planeManager.activePlane.rotation.y = 0;
      planeManager.activePlane.rotation.z = 0;
      planeManager.activePlane.locked = true;
    }
    this.points.push(copyIPoint(this.lastPoint));
    updateBeam(this.auxMesh, this.points, this.crossSectionId, this.growthType);
    this.setNextStep();
  }

  public moveLastPoint(pto: IPoint) {
    if (this.points.length) {
      const ptos = [this.points[0], pto].map(p => this.currPlane.getRelativePoint(p))
      updateBeam(this.auxMesh, ptos, this.crossSectionId, this.growthType);

      const beamVect = normalizeIpoint(ptos[1]);
      const rot = getRotationVector(beamVect);
      this.rotation = { x: rot.x, y: rot.y, z: rot.z };
      this.basePoint = this.points[0];
      this.auxMesh.position.set(this.basePoint.x, this.basePoint.y, this.basePoint.z);
      this.auxMesh.rotation.set(this.rotation.x, this.rotation.y, this.rotation.z);
    }
  }

  public save() {
    if (this.graphicProcessor && this.points.length > 1) {
      const beamVect = normalizeIpoint(this.points[1]);
      const rot = getRotationVector(beamVect)
      this.rotation = { x: rot.x, y: rot.y, z: rot.z };

      const command = new BeamCommand(
        this.growthType,
        this.currentStorey ? this.currentStorey.columnConcreteType : 'HA21', // storyMaterialType
        this.points.map(p => this.currPlane.getRelativePoint(p)),
        this.crossSectionId,
        this.points[0],               //basePoint
        this.rotation,
        this.offSet,
        this.scale,
        this.graphicProcessor);

      this.graphicProcessor.storeAndExecute(command);
    }
  }

  public cancelOperation(): void {
    if (!this.finished) {
      this.endOperation();
    }
  }
}