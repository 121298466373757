import { createBeam, updateBeam } from "lib/geometries/structural/beam";
import { materialCache } from "lib/materials/base";
import { getCurrentSolidMaterial, ISolidMaterial } from "lib/materials/solid";
import { beamParam, cloneBeamParam, mirrorBeamParam, rotateBeamParam, scaleBeamParam } from "lib/models-struc/types/beam";
import { objDataType } from "../types";
import { StructuralElementData } from "./structural";
import { getRotationVector } from "lib/math/rotate";
import { normalizeIpoint } from "lib/math/point";
import { IPoint } from "lib/math/types";
import { AxisHelperBeamData } from "lib/selection/axis-helper-objdata";
import { buildingElemType } from "lib/models-struc/types/struc-base";
import { GraphicProcessor } from "lib/graphic-processor";

export class BeamData extends StructuralElementData {

  public type: buildingElemType = objDataType.BEAM;
  protected nameObj: string = "Beam";

  public definition: beamParam;

  constructor(definition: beamParam, material?: ISolidMaterial) {
    super();
    this.definition = cloneBeamParam(definition);
    this.material = material ?? getCurrentSolidMaterial();
  }
  static createObj(definition: beamParam, material: ISolidMaterial) {
    const threeMaterial = materialCache.getSolidMaterial(material);
    const { points, crossSectionId, growthType, basePoint, orientation } = definition;
    const graphicObj = createBeam(points, crossSectionId, growthType, threeMaterial);
    const beamVect = normalizeIpoint(points[1]);
    const rot0 = getRotationVector(beamVect);
    definition.rotation = { x: rot0.x, y: rot0.y, z: rot0.z };
    const rot = getRotationVector(beamVect, orientation);
    graphicObj.rotation.set(rot.x, rot.y, rot.z);
    graphicObj.position.set(basePoint.x, basePoint.y, basePoint.z);
    graphicObj.updateMatrixWorld();

    return graphicObj;
  }
  public createGraphicObj() {
    if (this.graphicObj) {
      console.warn("Attention: Beam graphic object already created!!");
      return;
    }
    this.graphicObj = BeamData.createObj(this.definition, this.material);
  }
  public cloneDefinition(): beamParam {
    return cloneBeamParam(this.definition);
  }
  public createObject(definition?: beamParam, material?: ISolidMaterial): THREE.Mesh {
    return BeamData.createObj(definition ?? this.definition, material ?? this.material);
  }

  public override regenerateDefinition(): void {
    const { points, crossSectionId, growthType, basePoint, orientation } = this.definition;
    updateBeam(this.graphicObj, points, crossSectionId, growthType);
    const beamVect = normalizeIpoint(points[1]);
    const rot = getRotationVector(beamVect, orientation);
    this.graphicObj.rotation.set(rot.x, rot.y, rot.z);
    this.graphicObj.position.set(basePoint.x, basePoint.y, basePoint.z);
    this.graphicObj.updateMatrixWorld();
    this.updateAxisHelper();
  }

  public override rotate(angleX: number, angleY: number, angleZ: number, basePoint: IPoint): void {
    rotateBeamParam(this.definition, angleX, angleY, angleZ, basePoint);
    this.regenerateObjectFromDefinition();
    super.rotate(angleX, angleY, angleZ, basePoint);
  }
  public override scale(factorX: number, factorY: number, factorZ: number, basePoint: IPoint): void {
    scaleBeamParam(this.definition, factorX, factorY, factorZ, basePoint);
    this.regenerateObjectFromDefinition();
    super.scale(factorX, factorY, factorZ, basePoint);
  }
  public override mirror(startPoint: IPoint, endPoint: IPoint): void {
    mirrorBeamParam(this.definition, startPoint, endPoint);
    this.regenerateObjectFromDefinition();
    super.mirror(startPoint, endPoint);
  }

  public showAxisHelper(graphicProc: GraphicProcessor) {
    if (this.axisHelper === undefined) {
      this.axisHelper = new AxisHelperBeamData(this, graphicProc);
    }
    this.axisHelper.showHide();
  }
}
