import { getArrowSymbol } from "lib/dimension/arrow";
import { updateCircularDimension } from "lib/dimension/circular-dim-builder";
import { getBoundingBox } from "lib/geometries/bounding-box";
import { lineAzimut2p } from "lib/math/angles";
import { vectorDist3D } from "lib/math/distance";
import { IPoint } from "lib/math/types";
import { CircularDimensionData, circularDimensionParam } from "lib/models/dimension/circular-dim";
import { cadOpType } from "../factory";
import { EditDimensionOP } from "./dimension-base-edit";

abstract class EditCircularDimensionOP extends EditDimensionOP<circularDimensionParam> {

  constructor(threeObj: CircularDimensionData, vertex: number) {
    super(threeObj);
    this.vertexToEdit = vertex;
  }

  public async start() {
    await super.start();
    const planeManager = this.graphicProcessor.getPlaneManager();
    planeManager.activePlane.position = this.newDimInfo.objBase.center;
    planeManager.activePlane.rotation = this.newDimInfo.objBase.plane;
    planeManager.activePlane.locked = true;
  }

  public moveLastPoint(point: IPoint) {
    const { center, radius } = this.newDimInfo.objBase
    const planeManager = this.graphicProcessor.getPlaneManager();
    const c = planeManager.activePlane.getRelativePoint(center);
    const p = planeManager.activePlane.getRelativePoint(point);
    this.newDimInfo.dimDirectionAngle = lineAzimut2p(c, p);

    if (this.vertexToEdit === 0) {
      this.dimStyle.distBaseLine = vectorDist3D(point, center) - radius;
      const min = (this.textLabel.geometry.boundingBox as THREE.Box3).min;
      const max = (this.textLabel.geometry.boundingBox as THREE.Box3).max;
      const textLong = (max.x - min.x);
      const arrow = getArrowSymbol(this.dimStyle.arrowId);
      const bbox = getBoundingBox(arrow) as THREE.Box3;
      const arrowLong = (bbox.max.x - bbox.min.x) * 2;
      if (Math.abs(this.dimStyle.distBaseLine) < arrowLong + textLong) {
        this.dimStyle.distBaseLine = arrowLong + textLong;
      }
      if (vectorDist3D(center, point) > radius) {
        this.dimStyle.distBaseLine = Math.abs(this.dimStyle.distBaseLine);
      } else {
        this.dimStyle.distBaseLine = -Math.abs(this.dimStyle.distBaseLine);
      }
      this.newDimInfo.customStyleProp.distBaseLine = this.dimStyle.distBaseLine;
    }
    updateCircularDimension(this.objDataAux[0].graphicObj as THREE.Group, this.newDimInfo, this.dimStyle, this.txtStyle);
  }
}

export class EditRadialCircularDimensionOP extends EditCircularDimensionOP {
  public opType = cadOpType.EDITRADIUSDIM;
}
export class EditDiameterCircularDimensionOP extends EditCircularDimensionOP {
  public opType = cadOpType.EDITDIAMETERDIM;
}