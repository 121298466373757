import useSaveProject from "../projects/hooks/use-save-project";
import UndoRedoMenu from "../undo-redo/undo-redo-menu";
import SaveIcon from "../icons/save";
import PlusIcon from "shared/components/icons/plus";
import { useUI } from "shared/components/ui/context";
import FileToolbar from "../files/file-toolbar";
import UserMenu from "../layout/user-menu";
import ToolbarButton from "./toolbar-button";
import ExportIcon from "../icons/export";
import { useScreenshotProject } from "modules/cad/components/projects/hooks/use-screenshot-project";
import { useLocation } from "react-router-dom";
import RenderIf from "shared/components/ui/render-if";
import StrucUserMenu from "../../../struc/components/layout/user-menu";
import useSaveStrucProject from "modules/struc/components/project/hook/use-save-project";
import { useMainGraphicContext } from "../viewport/context";

const ProjectToolbar = () => {

  const graphicProc = useMainGraphicContext();
  const saveCadProject = useSaveProject(graphicProc);
  const saveStrucProject = useSaveStrucProject(graphicProc);
  const screenshotProject = useScreenshotProject(graphicProc);

  const { openModal } = useUI();
  const isStrucModule = (pathname: string) => pathname.includes("struc");
  const { pathname } = useLocation();
  const strucModule = isStrucModule(pathname);
  const saveProject = strucModule ? saveStrucProject : saveCadProject;
  return (
    <header className="flex justify-between border-b border-gray-600">
      <div className="flex">
        <FileToolbar />
        <RenderIf isTrue={!strucModule}>
          <ToolbarButton
            onClick={() => openModal("ADD_PROJECT")}
            className="px-2 border-b border-gray-600"
            tooltip="New project"
          >
            <PlusIcon />
          </ToolbarButton>
        </RenderIf>
      </div>
      <div className="flex px-2 space-x-2">
        <UndoRedoMenu graphicProc={graphicProc} />
        <ToolbarButton
          onClick={saveProject}
          icon={<SaveIcon />}
          tooltip="Save"
        />
        <ToolbarButton
          onClick={screenshotProject}
          icon={<ExportIcon />}
          tooltip="Screenshot"
        />
        <RenderIf isTrue={!strucModule}>
          <UserMenu />
        </RenderIf>
        <RenderIf isTrue={strucModule}>
          <StrucUserMenu />
        </RenderIf>
      </div>
    </header>
  );
};

export default ProjectToolbar;
