
import { ChangeEvent, FC, InputHTMLAttributes, useRef, useState } from "react";
import styled from "styled-components";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  defVal: number | string | undefined;
  onChangeVal(val: number): void;
  parseFun?(val: number): number;
  units?: string;
  className?: string
  decimals?: number
}

const InputDef: FC<Props> = ((props) => {

  const [isEditting, setIsEditting] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    defVal,
    onChangeVal,
    parseFun,
    units,
    className,
    decimals,
    ...rest
  } = props;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setIsEditting(true);
    console.log(inputRef?.current?.value)
  }
  const handleSubmit = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    if (isEditting && inputRef?.current && onChangeVal) {
      setIsEditting(false);
      const elem = inputRef?.current.value as string;
      let newVal = parseFloat(elem);
      if (parseFun) newVal = parseFun(newVal);
      onChangeVal(newVal);
    }
  }

  return (
    <form className={"contents"} onSubmit={handleSubmit}>
      <InputVal
        className={className}
        ref={inputRef}
        onChange={handleChange}
        value={!isEditting ? decimals ? (defVal as number)?.toFixed(decimals) : defVal : undefined}
        {...rest}
      />
      {units && <LabelUnits>{units}</LabelUnits>}
    </form>
  );
});

export default InputDef;

const LabelUnits = styled.span`
  margin-left: calc(-1rem - 5px);
  padding-right: 5px;
`;
const InputVal = styled.input`
  padding-right: calc(1rem + 5px);
`;
