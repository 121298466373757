import { v4 as uuid } from 'uuid'
import { quaternionToRotationAngles } from "lib/math/rotate";
import { IPoint } from 'lib/math/types';
import { structuralType } from "modules/struc/models/ecore/structural";
import { Geometry, geomType } from 'modules/struc/models/ecore/geometry';
import { ExtrudedRepresentation, GeoRepresentation } from 'modules/struc/models/ecore/representation';
import { Placement } from "modules/struc/models/ecore/location";
import { EcoreGeometryImporter } from '../geometry';
import { ILogger } from "shared/utils/logger/logger";
import { dataModelPersistence } from "lib/input-output/database/loader";
import { Storey } from "lib/models-struc/struc-storey";
import { definitionType, objDataType } from "lib/models/types";

export abstract class EcoreImporter {

  protected ecoreElem: structuralType;
  protected logger: ILogger;

  abstract getObjData(storey: Storey, layerId: string, cssIdsRefs: string[], materialRefs: string[]): dataModelPersistence;

  protected getDataModel(type: objDataType, layerId: string, param: definitionType): dataModelPersistence {
    return {
      id: uuid(),
      type,
      layerId,
      definition: param,
      material: undefined,
      isDataVisible: true,
      isDataLocked: false,
    };
  }

  protected getPlacement(placement: Placement) {
    const x = placement.base.x ?? 0;
    const y = placement.base.y ?? 0;
    const z = placement.base.z ?? 0;

    if (placement.rotation === undefined) {
      this.logger.warn(`[${this.ecoreElem.eClass}] Rotation not defined in Placement Ecore element ${this.ecoreElem.name}`);
    }

    const rotX = placement.rotation?.x ?? 0;
    const rotY = placement.rotation?.y ?? 0;
    const rotZ = placement.rotation?.z ?? 0;
    const rotW = placement.rotation?.w ?? 1;

    return {
      basePoint: { x, y, z },
      rotation: quaternionToRotationAngles(rotX, rotY, rotZ, rotW),
    }
  }
  protected getSlabGeometry() {
    const geom = this.ecoreElem.representation.base.base as geomType;
    const geomImp = new EcoreGeometryImporter();
    const ptos = geomImp.parseGeometryToIPoints(geom);
    return ptos;
  }
  protected getSlabHolesGeometry() {
    const holesWireGeom = this.ecoreElem.representation.substractions;
    if (holesWireGeom === undefined) return [];
    const holes: IPoint[][] = [];
    for (const holeGeom of holesWireGeom) {
      const h = holeGeom as ExtrudedRepresentation<GeoRepresentation<Geometry>>
      const geom = h.base.base as geomType
      const geomImp = new EcoreGeometryImporter();
      const points = geomImp.parseGeometryToIPoints(geom);
      holes.push(points);
    }
    return holes;
  }

}