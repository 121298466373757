import { CognitoUser, Auth } from "@aws-amplify/auth";
import { Role } from "shared/models/user";

const signUp = async (
  username: string,
  password: string,
  email: string
): Promise<CognitoUser | null> => {
  const { user } = await Auth.signUp({
    username,
    password,
    attributes: { email },
  });
  return user;
};

const confirmAccount = async (
  username: string,
  code: string
): Promise<string> => {
  const status = await Auth.confirmSignUp(username, code);
  return status as string;
};

const signIn = async (
  username: string,
  password: string
): Promise<CognitoUser> => {
  const user: CognitoUser = await Auth.signIn(username, password);
  return user;
};
const signOut = async () => {
  return await Auth.signOut();
};
const getRoles = async (): Promise<Role[]> => {
  const session = await Auth.currentSession();
  const accessToken = session.getAccessToken();
  const roles = accessToken.payload["cognito:groups"];
  return roles;
};

const checkAuth = async (): Promise<CognitoUser> => {
  const user: CognitoUser = await Auth.currentAuthenticatedUser();
  return user;
};
const getToken = async (): Promise<string> => {
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  return token;
};
export const authService = {
  signUp,
  signIn,
  signOut,
  checkAuth,
  getToken,
  getRoles,
  confirmAccount,
};
