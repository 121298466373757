import { v4 as uuid } from 'uuid'
import { getHypothesisFromStandardCode } from "lib/apis/standard-codes/hypothesis";
import { NewProjectInfo, ProjectsApi, ProjectFilesApi, Project } from "lib/apis/struc-project/api";
import { buildModelDataFromStoreys, buildPlaneDataFromStoreys } from "lib/input-output/database/loader-helper";
import { getHypothesisFromOccupancies } from "lib/models-struc/hypothesis/hypothesis";
import { StrucStorey, StrucSite } from "modules/struc/models/project";
import { updateFiles } from "./update-files";

export async function fetchPostCreateProject(project: Project, userId: string, storeys: StrucStorey[]) {
  try {
    const projInfo: NewProjectInfo = {
      DataModelVersion: "0.2",
      Name: project.Name,
      Remarks: project.Remarks,
      Country: project.Country,
      City: project.City,
      Codes: {
        ConcreteStandard: project.Codes.ConcreteStandard,
        Concrete: project.Codes.Concrete,
        Load: project.Codes.Load,
        SteelStandard: project.Codes.SteelStandard,
        Steel: project.Codes.Steel,
      },
      UserId: userId,
      FootingLevel: project.FootingLevel,
    };
    const controller = new ProjectsApi();
    const response = await controller.projectsPost(projInfo);
    const data = response.data as Project;

    const dataFiles = data.DataFiles!;

    const content = await buildModelDataFromStoreys(storeys);
    await updateFiles.content(content, dataFiles.Content.Write);

    const planes = buildPlaneDataFromStoreys(storeys);
    await updateFiles.planes(planes, dataFiles.Planes.Write);

    const sites = getsite(storeys);
    await updateFiles.sites(sites, dataFiles.Sites.Write);

    const hypothesis = await getHypothesisFromStandardCode();
    const hypothesisLoaded = getHypothesisFromOccupancies(hypothesis, storeys);
    await updateFiles.hypothesis(hypothesisLoaded, dataFiles.Hypothesis.Write);

    const fileProj = new ProjectFilesApi();
    await fileProj.projectsUserIdIdUpdatePut(userId, data.Id);
    return data;

  } catch (error) {
    console.error(error);
    throw error;
  }
}

const getsite = (strucStoreys: StrucStorey[]) => {
  const site: StrucSite = {
    id: uuid(),
    name: "",
    remarks: "",
    buildings: [{
      id: uuid(),
      name: "",
      remarks: "",
      struc_storeys: strucStoreys,
      struc_elements: [],
      load_elements: [],
    }],
  }
  return [site];
}
