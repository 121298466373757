import { IColor } from "lib/math/types";
import { Color } from "three";

// Blender default colors
// sceneBackground: "#393939",
// sceneLines: "#666666",

export const COLORS = {
  // Configuracion por defecto algo oscura.
  sceneBackground: "#18181B",
  sceneFog: "#1F2937",

  // Configuracion mas clara con lineas blancas y fondo grisaceo.
  // sceneBackground: "#393939", 
  // sceneLines: "#FFFFFF",

  // Configuracion igualica a la del Blender.
  // sceneBackground: "#393939", 
  // sceneLines: "#666666",
};

export const generateRandomColor = () =>
  Math.floor(Math.random() * 16777215).toString(16);

const rgb = (r: number, g: number, b: number) =>
  new Color(r / 255, g / 255, b / 255);

export const dragonfruit = rgb(255, 123, 172);
export const pink = rgb(203, 85, 153);
export const green = rgb(158, 205, 117);
export const turquoise = rgb(73, 198, 183);
export const teal = rgb(79, 141, 157);
export const blue = rgb(101, 160, 214);
export const magenta = rgb(203, 85, 153);
export const orangered = rgb(230, 97, 93);
export const orange = rgb(234, 141, 99);
export const white = rgb(255, 255, 255);
export const gray1 = rgb(234, 237, 239);
export const gray2 = rgb(203, 209, 212);
export const gray3 = rgb(88, 101, 147);
export const black = rgb(0, 0, 0);
export const pinapple = rgb(244, 251, 116);
export const ghost = "#CFB4B4";

export const planeOpXY = "#0000ff";
export const planeOpXZ = "#ff0000";
export const planeOpYZ = "#00ff00";
export const userPlaneOp = "#ff00ff";

export const mainPlaneGrid = "#444444";
export const mainPlaneLines = "#575761";
export const userPlaneGrid = "#5c403e";
export const userPlaneLines = "#cc9793";

export function copyColor(col: IColor): IColor {
  return { r: col.r, g: col.g, b: col.b, a: col.a };
}
export function equalsColor(cola: IColor, colb: IColor): boolean {
  return (cola.r === colb.r && cola.g === colb.g && cola.b === colb.b && cola.a === colb.a);
}