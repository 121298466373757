import { ProjectEvent } from "modules/lite/models/event";
import { getFEMEvents } from "modules/lite/utils/events";
import { useMemo } from "react";
import ProjectTimelineEvent from "../projects/project-timeline-event";
import styled from "styled-components";
interface Props {
  events: ProjectEvent[];
}

const FemResultsEvents = ({ events }: Props) => {
  const currentEvents = useMemo(() => getFEMEvents(events), [events]);
  return (
    <Wrapper>
      {currentEvents.map((event, index) => (
        <li key={index}>
          <ProjectTimelineEvent
            event={event as any}
            isLastEvent={index === currentEvents.length - 1}
          />
        </li>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.ul`
  display: flex;
`;
export default FemResultsEvents;
