import { DangerButton, SecondaryButton } from "shared/components/ui/button";
import { useUI } from "shared/components/ui/context";
import LoadingDots from "shared/components/ui/loading-dots";
import { useDeleteProject } from "./hook/use-delete-project";

const ProjectDeleteModal = () => {

  const { closeModal } = useUI();
  const delNewProject = useDeleteProject();

  if (delNewProject.status === "loading") {
    return (
      <div className="bg-gray-800 flex flex-col items-center justify-center">
        <LoadingDots />
      </div>
    );
  }
  return (
    <div className="space-y-6 max-w-sm">
      <div>
        <h1 className="text-lg leading-6 font-medium text-gray-400">
          Delete selected project
        </h1>
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            Are you sure you want to delete this project? This action cannot be
            undone.
          </p>
        </div>
      </div>
      <div className="flex justify-center space-x-4">
        <SecondaryButton onClick={closeModal} fullWidth>
          Cancel
        </SecondaryButton>
        <DangerButton onClick={delNewProject.deleteProject} fullWidth>
          Confirm
        </DangerButton>
      </div>
    </div>
  );
};

export default ProjectDeleteModal;
