import * as THREE from "three";
import { IPoint } from "lib/math/types";
import { createExtrusionMesh, updateExtrusionMesh } from "./extrusion";
import { Storey } from "lib/models-struc/struc-storey";
import { shellCrossSectionCache } from "lib/models-struc/cross-sections-shape/shell-cross-sections/cache";
import { CSG } from "lib/helpers/three-csg/csg-lib";
import { GeoRepresentation } from "modules/struc/models/ecore/representation";
import { getCurrentSolidMaterial, materialCache } from "lib/materials";
import { Geometry, WireGeometry } from "modules/struc/models/ecore/geometry";
import { wireGeometry2Ipoints } from "lib/input-output/e-core/importer/geometry";

export function createSlab(points: IPoint[], depth: number, holes: IPoint[][] = [], material?: THREE.MeshPhongMaterial): THREE.Mesh {
  const slabMesh = createExtrusionMesh(points, depth, undefined, material);
  slabMesh.geometry.translate(0, 0, -depth);

  for (const hole of holes) {
    const holeMesh = createExtrusionMesh(hole, depth * 1.1);
    holeMesh.geometry.translate(0, 0, -depth);

    slabMesh.updateMatrixWorld();
    const mesh0 = CSG.fromMesh(slabMesh);
    const mesh1 = CSG.fromMesh(holeMesh);
    const res = mesh0.subtract(mesh1);
    const newGeom = CSG.toGeometry(res, true);

    newGeom.computeBoundingSphere();
    newGeom.computeBoundingBox();
    slabMesh.geometry = newGeom;
  }

  return slabMesh;
}

export function addSlabHole(slabMesh: THREE.Mesh, depth: number, hole: IPoint[]) {
  const holeMesh = createExtrusionMesh(hole, depth * 1.2);
  holeMesh.geometry.translate(0, 0, -depth * 1.1);

  slabMesh.updateMatrixWorld();
  holeMesh.applyMatrix4(slabMesh.matrixWorld);

  const mesh0 = CSG.fromMesh(slabMesh);
  const mesh1 = CSG.fromMesh(holeMesh);
  const res = mesh0.subtract(mesh1);
  const newGeom = CSG.toGeometry(res, true);

  let inv = new THREE.Matrix4().copy(slabMesh.matrixWorld).invert();
  newGeom.applyMatrix4(inv);

  newGeom.computeBoundingSphere();
  newGeom.computeBoundingBox();
  slabMesh.geometry = newGeom;
}

export function updateSlab(slabMesh: THREE.Mesh, points: IPoint[], depth: number, holes: IPoint[][] = []) {
  const pos = slabMesh.position.clone();
  const rot = slabMesh.rotation.clone();
  slabMesh.position.set(0, 0, 0);
  slabMesh.rotation.set(0, 0, 0);
  slabMesh.updateMatrixWorld();
  updateExtrusionMesh(slabMesh, points, depth);
  slabMesh.geometry.translate(0, 0, -depth);
  for (const hole of holes) {
    addSlabHole(slabMesh, depth, hole);
  }
  slabMesh.position.set(pos.x, pos.y, pos.z)
  slabMesh.rotation.set(rot.x, rot.y, rot.z);
  slabMesh.updateMatrixWorld();
}

export function getDepthFromSlab(currentStorey: Storey, currentSlab: number) {
  const slab = currentStorey.slabs[currentSlab ?? 0];
  if (slab !== undefined) {
    const slabTempl = shellCrossSectionCache.loadStylefromCache(slab.definition.shellCrossSectionId)
    if (slabTempl !== undefined) {
      return slabTempl.thickness;
    }
  }
}

export function createWaffleSlab(depth: number, WaffleGeo: GeoRepresentation<Geometry>[], SolidGeo: GeoRepresentation<Geometry>[], material?: THREE.MeshPhongMaterial) {

  const getMeshPtos = (geom: GeoRepresentation<Geometry>, mat: THREE.MeshPhongMaterial) => {
    const base = geom.base as WireGeometry;
    const baePoints = wireGeometry2Ipoints(base);
    const baseMesh = createExtrusionMesh(baePoints, depth, undefined, mat);
    baseMesh.geometry.translate(0, 0, -depth);
    const additions = geom.additions as GeoRepresentation<Geometry>[];
    if (additions) {
      for (const adds of additions) {
        const addMesh = getMeshPtos(adds, mat);
        const mesh0 = CSG.fromMesh(baseMesh);
        const mesh1 = CSG.fromMesh(addMesh);
        const res = mesh0.union(mesh1);
        const newGeom = CSG.toGeometry(res, true);
        newGeom.computeBoundingSphere();
        newGeom.computeBoundingBox();
        baseMesh.geometry = newGeom;
      }
    }
    const substraction = geom.substractions as GeoRepresentation<Geometry>[];
    if (substraction) {
      for (const subs of substraction) {
        const subsMesh = getMeshPtos(subs, mat);
        const mesh0 = CSG.fromMesh(baseMesh);
        const mesh1 = CSG.fromMesh(subsMesh);
        const res = mesh0.subtract(mesh1);
        const newGeom = CSG.toGeometry(res, true);
        newGeom.computeBoundingSphere();
        newGeom.computeBoundingBox();
        baseMesh.geometry = newGeom;
      }
    }
    return baseMesh;
  }

  const mesh = new THREE.Group();

  const material0 = getCurrentSolidMaterial();
  material0.color = { r: 0, g: 255, b: 0, a: 0.7 };
  const threeMaterial0 = materialCache.getSolidMaterial(material0);
  SolidGeo.forEach((geomRepr) => { mesh.add(getMeshPtos(geomRepr, threeMaterial0)) });

  const material1 = getCurrentSolidMaterial();
  material1.color = { r: 255, g: 0, b: 0, a: 0.7 };
  const threeMaterial1 = materialCache.getSolidMaterial(material1);
  WaffleGeo.forEach((geomRepr) => { mesh.add(getMeshPtos(geomRepr, threeMaterial1)) });

  return mesh;
}
