import { useState, useCallback } from "react";
import { useQuery } from "react-query";
import { Project, ProjectsApi } from "lib/apis/struc-project/api";
import { downloadFiles } from "lib/models-struc/infrastructure/download-files";

export interface projectListResponse {
  projectData: Project,
  snapShots: string | null,
}

let cancelQuery = false;

export function useGetStrucProjectsList(userId: string) {

  const [lastItem, setLastItem] = useState<string>("");

  const [page, setPage] = useState<number>(0);
  const [canNextPage, setCanNextPage] = useState<boolean>(true);
  const [projects, setProjects] = useState<projectListResponse[]>([]);


  const goToNextPage = useCallback(() => {
    if (lastItem) {
      setPage(old => old + 1);
    }
  }, [lastItem]);

  const goToPrevPage = useCallback(() => {
    if (page > 0) {
      setCanNextPage(true);
      setPage(old => old - 1);
    }
  }, [page]);


  const { isLoading, error } = useQuery(["GET_STRUC_PROJECTS", page], async () => {

    if (cancelQuery) {
      cancelQuery = false;
      return;
    }

    const controller = new ProjectsApi();
    let response;
    if (lastItem && page !== 0) {
      response = await controller.projectsGet(userId, lastItem);
    } else {
      response = await controller.projectsGet(userId);
    }
    const pagination = response.data;
    const projects = pagination.Items as Project[];
    if (projects && projects.length) {
      const lastItm = JSON.stringify(pagination.LastEvaluatedKey)
      setLastItem(lastItm);
      if (lastItm === undefined) {
        setCanNextPage(false);
      }
      let snapShotString: string | null;
      const proj: projectListResponse[] = [];
      for (const project of projects) {
        const snapShotUrl = project.SnapshotRead;
        snapShotString = await downloadFiles.snapshot(snapShotUrl!);
        proj.push({
          projectData: project,
          snapShots: snapShotString ?? null,
        })
      }
      setProjects(proj);
      return proj;

    } else {
      // No more projects
      cancelQuery = true;
      setPage(page - 1);
      setCanNextPage(false);
    }
  });

  return {
    projects,

    iniProjsIsLoading: page === 0 && isLoading,
    nextProjsIsLoading: page > 0 && isLoading,

    error: error,
    canPreviousPage: page !== 0,
    canNextPage,
    goToNextPage,
    goToPrevPage,

  };
}
