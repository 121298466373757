import { Reducer } from "react";
import { FixedStack, UniqueFixedIArray } from "../../../../lib/helpers/arrays";
import { IPoint } from "../../../../lib/math/types";
import { Cad3dOp } from "../../../../lib/operations/base";
import { cadOpType } from "../../../../lib/operations/factory";
import { getCurrentTime } from "../../../../shared/utils/dates";

interface OpInfo {
  pubName: string;
  constructorOp: new (...args: any[]) => Cad3dOp;
}

export interface OperationActions {
  cleanChanges: () => void;
  launchOperation: (type: cadOpType, needObjs?: boolean) => void;
  createPoint: () => void;
  createLine: () => void;
  createPolyline: () => void;
  createPolygon: () => void;
  createCircle: () => void;
  createText: () => void;
  createParagraph: () => void;
  createCube: () => void;
  createCylinder: () => void;
  createRPrism: () => void;
  createPPrism: () => void;
  createPlane: () => void;
  createCone: () => void;
  createEllipse: () => void;
  createArc: () => void;
  createEllipseArc: () => void;
  createRegion: () => void;
  createBlock: () => void;
  createBlockRef: () => void;
  calcDistanceMeasure: () => void;
  move: () => void;
  copy: () => void;
  rotate: () => void;
  trim: () => void;
  scale: () => void;
  mirror: () => void;
  extend: () => void;
  stretch: () => void;
  createBeam: () => void;
  createColumn: () => void;
  createSlab: () => void;
  createSlabHole: () => void;
  createWall: () => void;
  createWallHole: () => void;
  createConcentratedLoad: () => void;
  createLinealLoad: () => void;
  createSuperficialLoad: () => void;
  createFooter: () => void;
  createPileCap: () => void;
}

export interface OperationState {
  operationType: cadOpType | null;
  hasUnsavedChanges: boolean;
  history: string[];
  currentOp: Cad3dOp | null;
  lastOp: { type: cadOpType; args: any[] } | null;
  cadOpMap: Map<cadOpType, OpInfo>;
  lastOpActions: UniqueFixedIArray<{
    type: cadOpType;
    args: any[];
  }>;
  lastInputPoints: FixedStack<IPoint>;
}

export const initialOperationState: OperationState = {
  operationType: null,
  hasUnsavedChanges: false,
  history: [],
  currentOp: null,
  lastOp: null,
  cadOpMap: new Map<cadOpType, OpInfo>(),
  lastOpActions: new UniqueFixedIArray<{
    type: cadOpType;
    args: any[];
  }>(10),
  lastInputPoints: new FixedStack<IPoint>(20),
};

type ActionType = cadOpType | "CLEAN_CHANGES" | "FINISH_OPERATION";

interface OperationAction {
  type: ActionType;
  payload?: Record<string, any>;
}

export const operationReducer: Reducer<OperationState, OperationAction> = (state, action: OperationAction) => {
  switch (action.type) {
    case cadOpType.POINTS: {
      return {
        ...state,
        hasUnsavedChanges: true,
        operationType: cadOpType.POINTS,
        history: [
          ...state.history,
          `${getCurrentTime()} Comando punto activado`,
        ],
      };
    }
    case cadOpType.LINE: {
      return {
        ...state,
        hasUnsavedChanges: true,
        operationType: cadOpType.LINE,
        history: [
          ...state.history,
          `${getCurrentTime()} Comando línea activado`,
        ],
      };
    }
    case cadOpType.POLYLINE: {
      return {
        ...state,
        hasUnsavedChanges: true,
        operationType: cadOpType.POLYLINE,
        history: [
          ...state.history,
          `${getCurrentTime()} Comando polilínea activado`,
        ],
      };
    }
    case cadOpType.CIRCLE: {
      return {
        ...state,
        hasUnsavedChanges: true,
        operationType: cadOpType.CIRCLE,
        history: [
          ...state.history,
          `${getCurrentTime()} Comando círculo activado`,
        ],
      };
    }
    case cadOpType.POLYGON: {
      return {
        ...state,
        hasUnsavedChanges: true,
        operationType: cadOpType.POLYGON,
        history: [
          ...state.history,
          `${getCurrentTime()} Comando polígono activado`,
        ],
      };
    }
    case cadOpType.CUBE: {
      return {
        ...state,
        hasUnsavedChanges: true,
        operationType: cadOpType.CUBE,
        history: [
          ...state.history,
          `${getCurrentTime()} Comando cubo activado`,
        ],
      };
    }
    case cadOpType.CYLINDER: {
      return {
        ...state,
        hasUnsavedChanges: true,
        operationType: cadOpType.CYLINDER,
        history: [
          ...state.history,
          `${getCurrentTime()} Comando cilindro activado`,
        ],
      };
    }
    case cadOpType.RPRISM: {
      return {
        ...state,
        hasUnsavedChanges: true,
        operationType: cadOpType.RPRISM,
        history: [
          ...state.history,
          `${getCurrentTime()} Comando prisma rectangular activado`,
        ],
      };
    }
    case cadOpType.PPRISM: {
      return {
        ...state,
        hasUnsavedChanges: true,
        operationType: cadOpType.PPRISM,
        history: [
          ...state.history,
          `${getCurrentTime()} Comando prisma poligonal activado`,
        ],
      };
    }
    case cadOpType.PLANE: {
      return {
        ...state,
        hasUnsavedChanges: true,
        operationType: cadOpType.PLANE,
        history: [
          ...state.history,
          `${getCurrentTime()} Comando plano activado`,
        ],
      };
    }
    case cadOpType.CONE: {
      return {
        ...state,
        hasUnsavedChanges: true,
        operationType: cadOpType.CONE,
        history: [
          ...state.history,
          `${getCurrentTime()} Comando cono activado`,
        ],
      };
    }
    case "CLEAN_CHANGES": {
      return {
        ...state,
        hasUnsavedChanges: false,
        operationType: null,
      };
    }
    case "FINISH_OPERATION": {
      return {
        ...state,
        operationType: null,
      };
    }
    default: {
      return state;
    }
  }
};
