import styled from "styled-components";
import { SecondaryButton } from "shared/components/ui/button";
import PropertiesSelect from "modules/cad/components/properties/component-select";
import PropertiesInput from "modules/cad/components/properties/component-input";
import TableGrid from "../pile-cap/table-grid";
import { useStoreyDriftSelection } from "./hooks/use-storey-drift-selection";
import { useUI } from "shared/components/ui/context";
import { LoadCombinationStructure } from "lib/apis/analysis-projects/api";
import { useEffect, useState } from "react";
import { storeyDriftRepo } from "lib/models-struc/storey-drift/storey-drift-repo";
import LoadingDots from "shared/components/ui/loading-dots";
import CloseIcon from "modules/cad/components/icons/close";

// 1. RT2 (RESULT TYPE)
// 2. CT3 (COMB TYPE)
// 3. 14 (CONSTRUCION PHASE) Por defecto, en el combo box seleccionar el mayor valor (el último)
// 3. 149 (COMB NUM)

// RT2-CT3-14-149 -> 

type ViewState = (
  { status: 'loading', loadingSince: Date } |
  { status: 'done', data: LoadCombinationStructure[] } |
  { status: 'error', error: Error }
)

interface Props { onClose: () => void }

const StoreyDriftWizard = ({ onClose }: Props) => {

  const [viewState, setViewState] = useState<ViewState>({
    status: 'loading',
    loadingSince: new Date()
  })

  useEffect(() => {
    setViewState({ status: 'loading', loadingSince: new Date() })
    storeyDriftRepo.getLoadCombinations().then(data => {
      setViewState({ status: 'done', data })
    }).catch((error: Error) => {
      setViewState({ status: 'error', error })
    })
  }, [])

  const { openModal } = useUI();

  const {
    name,
    setName,

    loadCombination,
    setLoadCombination,

    changeResultType,
    changeCombination,
    resultTypes,
    combinationTypes,
    constructionPhaseList,

    AddCombination,
    DeleteCombination,
    combinationList,
    onAccept
  } = useStoreyDriftSelection(viewState.status === "done" ? viewState.data : []);

  return (
    <Wrapper className="text-xs">
      <Header>
        <h1>Storey drift wizard</h1>
        <button onClick={onClose}>
          <CloseIcon className="h-4 w-4" />
        </button>
      </Header>
      <Content>
        <ActionBar0 className="layers">
          {viewState.status === "loading" &&
            <div className="bg-gray-800 flex justify-center h-full w-full z-50">
              <LoadingDots />
            </div>
          }
          {viewState.status === "error" &&
            <div className="bg-gray-800 flex justify-center h-full w-full z-50">
              {viewState.error.stack}
            </div>
          }
          {viewState.status === "done" &&
            <div className="grid grid-cols-1 gap-2">

              <PropertiesInput
                value={name}
                type={"text"}
                label={"Load combination set name:"}
                onChange={(ev) => {
                  setName(ev.target.value);
                }}
              />
              <div className="border-b border-gray-700 space-y-2"></div>
              <PropertiesSelect
                label={"Results type:"}
                value={loadCombination.currentResultType}
                disabled={true}
                onChange={(ev) => {
                  const indx = (ev.target as HTMLSelectElement).selectedIndex;
                  changeResultType(resultTypes[indx]);
                }}
              >
                {resultTypes.map((val, i) => (
                  <option key={i} value={val}> {val} </option>
                ))}
              </PropertiesSelect>
              <PropertiesSelect
                label={"Combination type:"}
                value={loadCombination.currentCombType}
                onChange={(ev) => {
                  const currIndx = (ev.target as HTMLSelectElement).selectedIndex;
                  changeCombination(combinationTypes[currIndx]);
                }}
              >
                {combinationTypes.map((val, i) => (
                  <option key={i} value={val}> {val} </option>
                ))}
              </PropertiesSelect>
              <PropertiesSelect
                label={"Construction phase:"}
                value={loadCombination.currConstrPhase}
                onChange={(ev) => {
                  const currIndx = (ev.target as HTMLSelectElement).selectedIndex;
                  setLoadCombination({
                    ...loadCombination,
                    currConstrPhase: constructionPhaseList[currIndx],
                  });
                }}
              >
                {constructionPhaseList.map((val, i) => (
                  <option key={i} value={val}> {val} </option>
                ))}
              </PropertiesSelect>
              <PropertiesInput
                value={loadCombination.currNumComb}
                type={"number"}
                // disabled={0 === loadCombination.maxNumComb - 1}
                min={0}
                max={loadCombination.maxNumComb - 1}
                step={1}
                label={"Combination number:"}
                onChange={(ev) => {
                  setLoadCombination({
                    ...loadCombination,
                    currNumComb: ev.target.valueAsNumber,
                  });
                }}
              />
              <div className="flex justify-around">
                <SecondaryButton onClick={AddCombination}>
                  Add to list
                </SecondaryButton>
                <SecondaryButton onClick={DeleteCombination}>
                  Delete last item
                </SecondaryButton>
              </div>
              <div className="border-b border-gray-700 space-y-2"></div>

              <TableGrid
                header={["", "Result", "Combination", "Constr. phase", "Comb. number"]}
                rows={combinationList}
                disableColumn={[0, 1, 2, 3, 4]}
                updateData={() => { }}
              />
            </div>
          }
        </ActionBar0>

        <BottomToolBar>
          <SecondaryButton onClick={onClose}>
            Cancel
          </SecondaryButton>
          <SecondaryButton onClick={() => {
            if (onAccept()) {
              onClose();
              openModal("STOREYDRIFTINFO", { name: name });
            }
          }}>
            Accept
          </SecondaryButton>
        </BottomToolBar>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  overflow: hidden;
  border-radius: 4px;
  width: 400px;
  margin: -32px;
  background-color: var(--color-gray-300);
`;
const Header = styled.div`
  background-color: var(--color-gray-300);
  border-bottom: 2px solid var(--color-primary);
  padding: 8px;
  display: flex;
  justify-content: space-between;
`;
const Content = styled.div`
  display: grid;
  grid-template-rows: 500px 36px;
  grid-template-columns: auto;
  gap: 4px;
  padding: 8px;
  height: 100%;
`;
const Box = styled.div`
  background-color: var(--color-gray-200);
  padding: 8px;
  border-radius: 4px;
  overflow: hidden;
`;
const ActionBar0 = styled(Box)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  width: 100%;
`;
const BottomToolBar = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export default StoreyDriftWizard;