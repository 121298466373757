const ArcIcon = ({ ...props }) => {
  return (
    <svg className="h-5 w-5" viewBox="0 0 106 111" fill="none" {...props}>
      <path
        d="M30.333 44.0938C19.0597 57.1855 11.2997 73.8472 8.66633 92.3338"
        stroke="currentColor"
        strokeWidth="6"
      />
      <path
        d="M90.3333 10.6667C71.0183 13.4383 53.6917 21.835 40.3333 34.01"
        stroke="currentColor"
        strokeWidth="6"
      />
      <path
        d="M40.875 33.125V45.875H28.125V33.125H40.875Z"
        stroke="currentColor"
        strokeWidth="6.25"
      />
      <path
        d="M102.875 3.125V15.875H90.125V3.125H102.875Z"
        stroke="currentColor"
        strokeWidth="6.25"
      />
      <path
        d="M15.875 95.125V107.875H3.125V95.125H15.875Z"
        stroke="currentColor"
        strokeWidth="6.25"
      />
    </svg>
  );
};

export default ArcIcon;
