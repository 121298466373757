import { getRotationSystemFrom3p } from "../geometries";
import { arcBuffer } from "../geometries/arc";
import { ARC_DISCR } from "../geometries/circle";
import { getAbsolutePoint, getRelativePoint } from "../coordinates/plane";
import {
  getAngleBetweenAzimuts,
  getAngleBetweenMathDirections,
  lineAngle2p,
  lineAzimut2p,
  mecDirectionMathDirection,
  normalizeAngle,
  ORIENT,
} from "./angles";
import { distance3d, vectorDist2D, vectorDist3D } from "./distance";
import { IArcLineParam } from "./line";
import {
  addIpoint,
  copyIPoint,
  dotProductIpoint,
  getAzimutPolarPoint,
  getPolarPoint,
  mulIpoint,
  pointLinePositionXY,
  substractIpoint,
} from "./point";
import { rotatePoint } from "./rotate";
import { IPoint } from "./types";

export interface arcParam {
  center: IPoint;
  radius: number;
  azimutO: number;
  angleCenter: number;
  plane: IPoint;
}
export function isArcParam(param: any): param is arcParam {
  if (param.center === undefined) return false;
  if (param.radius === undefined) return false;
  if (param.azimutO === undefined) return false;
  if (param.angleCenter === undefined) return false;
  if (param.plane === undefined) return false;
  return true;
}

/** Calculo del centro de una circunferencia a partir de 3 puntos
 *
 * @export
 * @param {IPoint} p1
 * @param {IPoint} p2
 * @param {IPoint} p3
 * @returns {(IPoint | void)}
 * @see https://github.com/sergarrido/random/tree/master/circle3d
 */
export function circleGetCenter3p(p1: IPoint, p2: IPoint, p3: IPoint): IPoint | undefined {
  const v1 = substractIpoint(p2, p1);
  const v2 = substractIpoint(p3, p1);
  const v1v1 = dotProductIpoint(v1, v1);
  const v2v2 = dotProductIpoint(v2, v2);
  const v1v2 = dotProductIpoint(v1, v2);
  const base = 0.5 / (v1v1 * v2v2 - v1v2 * v1v2);
  if (base === Infinity || base === -Infinity) return undefined;
  const k1 = base * v2v2 * (v1v1 - v1v2);
  const k2 = base * v1v1 * (v2v2 - v1v2);
  const pAux1 = mulIpoint(v1, k1);
  const pAux2 = mulIpoint(v2, k2);
  return addIpoint(addIpoint(p1, pAux1), pAux2);
}

/** Devuelve el sentido de un arco: CW / CCW
 *
 * @export
 * @param {IPoint} firstPoint Inicio del arco
 * @param {IPoint} secondPoint Caulquier otro punto intermedio del arco
 * @param {IPoint} lastPoint Final del arco
 * @returns {number}
 */
export function getArcDirectionFrom3p(firstPoint: IPoint, secondPoint: IPoint, lastPoint: IPoint, plane?: IPoint): ORIENT {
  let v1 = copyIPoint(firstPoint);
  let v2 = copyIPoint(secondPoint);
  let v3 = copyIPoint(lastPoint);
  if (plane === undefined)
    plane = getRotationSystemFrom3p(firstPoint, secondPoint, lastPoint);

  if (plane.x !== 0 || plane.y !== 0 || plane.z !== 0) {
    v1 = getRelativePoint(v1, firstPoint, plane);
    v2 = getRelativePoint(v2, firstPoint, plane);
    v3 = getRelativePoint(v3, firstPoint, plane);
  }

  const relativePosition: number = pointLinePositionXY(v2, v1, v3);
  if (relativePosition < 0) {
    return ORIENT.CW;
  } else if (relativePosition > 0) {
    return ORIENT.CCW;
  } else {
    return ORIENT.CW;
  }
}

export function arcPointsCR2p(center: IPoint, radius: number, p1: IPoint, p3: IPoint, direction: ORIENT, plane?: IPoint): IPoint[] {
  if (plane === undefined) {
    plane =
      direction === ORIENT.CCW
        ? getRotationSystemFrom3p(center, p3, p1)
        : getRotationSystemFrom3p(center, p1, p3);
    if (plane.x < 0 || plane.y < 0 || plane.z < 0) {
      plane = getRotationSystemFrom3p(p1, p3, center);
    }
  }
  let v1 = copyIPoint(p1);
  let v3 = copyIPoint(p3);
  v1 = getRelativePoint(v1, center, plane);
  v3 = getRelativePoint(v3, center, plane);

  const azimutO = lineAzimut2p({ x: 0, y: 0, z: 0 }, v1);
  const azimutEnd = lineAzimut2p({ x: 0, y: 0, z: 0 }, v3);
  let angleCenter = getAngleBetweenAzimuts(azimutO, azimutEnd, direction);
  if (direction === ORIENT.CCW) angleCenter *= -1;
  return arc(center, radius, azimutO, angleCenter, plane);
}

export function arcBuffer2angC(azimut0: number, azimutEnd: number, radius: number, center: IPoint, direction: ORIENT, plane?: IPoint): Float32Array {
  if (plane === undefined) plane = { x: 0, y: 0, z: 0 };
  let angleCenter = getAngleBetweenAzimuts(azimut0, azimutEnd, direction);
  if (direction === ORIENT.CCW) angleCenter *= -1;
  return arcBuffer(center, radius, azimut0, angleCenter, plane);
}
export function arc(center: IPoint, radius: number, azimutO?: number, angleCenter?: number, plane?: IPoint): IPoint[] {
  if (azimutO === undefined) azimutO = 0;
  if (plane === undefined) plane = { x: 0, y: 0, z: 0 };
  if (angleCenter === undefined || angleCenter === 0) angleCenter = Math.PI * 2;

  const angle: number = angleCenter / ARC_DISCR;
  const numNewVertex: number = ARC_DISCR + 1;

  const points = [] as IPoint[];
  let currAng: number, point: IPoint;
  for (let i: number = 0; i < numNewVertex; i++) {
    currAng = azimutO + i * angle;
    point = getAzimutPolarPoint(center, currAng, radius);
    points.push(rotatePoint(point, plane.x, plane.y, plane.z, center));
  }
  return points;
}

export function getStartEndPointArc(arcParam: arcParam): [IPoint, IPoint] {
  const { center, angleCenter, azimutO, radius, plane } = arcParam;
  let p1 = getAzimutPolarPoint({ x: 0, y: 0, z: 0 }, azimutO, radius);
  p1 = getAbsolutePoint(p1, center, plane);
  let p2 = getAzimutPolarPoint({ x: 0, y: 0, z: 0 }, azimutO + angleCenter, radius);
  p2 = getAbsolutePoint(p2, center, plane);
  return [p1, p2];
}
export function circleGetCenter2pR(p1: IPoint, p2: IPoint, r: number, plane?: IPoint): [IPoint, IPoint] | void {
  if (plane === undefined) plane = { x: 0, y: 0, z: 0 };
  let v1 = copyIPoint(p1);
  let v2 = copyIPoint(p2);
  if (plane.x !== 0 || plane.y !== 0 || plane.z !== 0) {
    v1 = getRelativePoint(v1, p1, plane);
    v2 = getRelativePoint(v2, p1, plane);
  }
  // Center resolution by direct intersection of distances
  const chord: number = distance3d(v1.x, v1.y, v1.z, v2.x, v2.y, v2.z);
  if (r >= chord * 0.5) {
    const D: number = Math.acos((r * r + chord * chord - r * r) / (2 * r * chord));
    let p1p2Dir: number = lineAngle2p(v1, v2);
    if (p1p2Dir < 0) p1p2Dir += 2 * Math.PI;

    const p1Cdir: number = p1p2Dir - D;
    const x: number = v1.x + r * Math.cos(p1Cdir);
    const y: number = v1.y + r * Math.sin(p1Cdir);
    let center = { x, y, z: v1.z };
    if (plane.x !== 0 || plane.y !== 0 || plane.z !== 0) {
      center = getAbsolutePoint(center, p1, plane);
    }

    const p1Cdir2: number = p1p2Dir + D;
    const x2: number = v1.x + r * Math.cos(p1Cdir2);
    const y2: number = v1.y + r * Math.sin(p1Cdir2);
    let center2 = { x: x2, y: y2, z: v1.z };
    if (plane.x !== 0 || plane.y !== 0 || plane.z !== 0) {
      center2 = getAbsolutePoint(center, p1, plane);
    }

    return [center, center2];

  } else {
    console.warn(`Radius too short ${r} < ${chord * 0.5}`);
    return;
  }
}
export function getTangentPoints(center: IPoint, radius: number, pto: IPoint, plane?: IPoint): [IPoint, IPoint] | null {

  const p = plane ? getRelativePoint(pto, center, plane) : pto;
  const c = plane ? { x: 0, y: 0, z: 0 } : center;

  const dist = vectorDist3D(c, p);
  if (dist <= radius) return null;
  const a = Math.asin(radius / dist);
  const b = lineAngle2p(p, c);
  let t = b - a;
  const T1 = {
    x: c.x + radius * Math.sin(t),
    y: c.y + radius * -Math.cos(t),
    z: 0
  };
  t = b + a;
  const T2 = {
    x: c.x + radius * -Math.sin(t),
    y: c.y + radius * Math.cos(t),
    z: 0,
  }
  if (plane) {
    return [
      getAbsolutePoint(T1, center, plane),
      getAbsolutePoint(T2, center, plane)
    ];
  }
  return [T1, T2];
}
export function getFactorPointArcPosition(arc: arcParam, pto: IPoint): number {
  const angle = normalizeAngle(lineAngle2p(arc.center, pto));
  const sAngle = normalizeAngle(mecDirectionMathDirection(arc.azimutO));
  const direction = arc.angleCenter > 0 ? ORIENT.CW : ORIENT.CCW;
  const a = getAngleBetweenMathDirections(sAngle, angle, direction);
  return a / Math.abs(arc.angleCenter);
}
export function getPointOnArc(arc: arcParam, f: number) {
  const newAngleCenter = f * arc.angleCenter;
  const angleEnd = mecDirectionMathDirection(arc.azimutO + newAngleCenter);
  return getPolarPoint(arc.center, angleEnd, arc.radius);
}
export function getRadiusFromSagittaChord(sagitta: number, chord: number): number {
  const a = (4 * sagitta * sagitta) + (chord * chord);
  return a / (8 * sagitta);
}
export function getSagittaFromChordRadius(radius: number, chord: number): number {
  const a = (4 * radius * radius) - (chord * chord);
  return radius - 0.5 * Math.sqrt(a);
}
export function getArcFromBulge(p1: IPoint, bulge: number, p3: IPoint): IArcLineParam {
  const direction = bulge < 0 ? ORIENT.CW : ORIENT.CCW;
  const angle = 4 * Math.atan(bulge);
  const d = vectorDist2D(p1, p3) * 0.5;
  let radius = d / Math.sin(angle * 0.5);
  // const dist = MATH.vectorDist3D(p1, p3);
  // const radius = dist * (1 + bulge * bulge) / (4 * Math.abs(bulge));
  const a = (Math.PI * 0.5 - angle * 0.5) + lineAngle2p(p1, p3);
  const center = getPolarPoint(p1, a, radius);
  if (radius < 0) radius *= -1;
  return { p1Tangent: false, center, radius, p3Tangent: false, direction };
}