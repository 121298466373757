import { getMeasurementEvents } from "modules/lite/utils/events";
import { useCallback, useMemo } from "react";
import DownloadIcon from "shared/components/icons/download";
import { storageService } from "shared/services/storage";
import ProjectTimelineEvent from "../projects/project-timeline-event";
import styled from "styled-components";
import { ProjectEvent } from "modules/lite/models/event";
import RenderIf from "shared/components/ui/render-if";
interface Props {
  events: ProjectEvent[];
  projectId: string;
}

const MeasurementEvents = ({ events, projectId }: Props) => {
  const measurementEvents = useMemo(() => getMeasurementEvents(events), [
    events,
  ]);
  const downloadModel = useCallback(async () => {
    const url = await storageService.get(
      `freecad_projects/${projectId}/model.fcstd`
    );
    window.location.href = url;
  }, [projectId]);

  const downloadFeatures = useCallback(async () => {
    const url = await storageService.get(
      `freecad_projects/${projectId}/features.csv`
    );
    window.location.href = url;
  }, [projectId]);

  return (
    <Wrapper>
      {measurementEvents.map((event, index) => (
        <li key={index}>
          <ProjectTimelineEvent
            event={event}
            isLastEvent={index === measurementEvents.length - 1}
          >
            <RenderIf isTrue={event.action === "MODEL_DONE"}>
              <button
                onClick={downloadModel}
                className="tooltip focus:outline-none  rounded-sm focus:ring-2 focus:ring-indigo-500"
                data-alt="Download building model"
              >
                <DownloadIcon />
              </button>
            </RenderIf>
            <RenderIf isTrue={event.action === "FEATURES_DONE"}>
              <button
                onClick={downloadFeatures}
                className="tooltip focus:outline-none  rounded-sm focus:ring-2 focus:ring-indigo-500"
                data-alt="Download building model"
              >
                <DownloadIcon />
              </button>
            </RenderIf>
          </ProjectTimelineEvent>
        </li>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.ul`
  display: flex;
`;

export default MeasurementEvents;
