import { ObserverManager } from "lib/events/event-bus";
import { HypothesisAction, HipothesisActionType } from "lib/events/hypothesis";
import { LoadStructuralData } from "lib/models/structural/load";
import { Hypothesis } from "./hypothesis";

export class HypothesisDispatcher {

  public hypothesisObserver = new ObserverManager<HypothesisAction>();

  public subscribe(listener: (action: HypothesisAction) => void) {
    this.hypothesisObserver.subscribe(listener)
  }
  public unsubscribe(listener: (action: HypothesisAction) => void) {
    this.hypothesisObserver.unsubscribe(listener)
  }

  public dispatchLoad() {
    this.hypothesisObserver.dispatch({
      type: HipothesisActionType.LOAD_HYPOTHESIS,
      payload: {},
    });
  }
  public dispatchAdd(hypothesis: Hypothesis) {
    this.hypothesisObserver.dispatch({
      type: HipothesisActionType.ADD_HYPOTHESIS,
      payload: { hypothesis },
    });
  }
  public dispatchDelete(hypothesis: Hypothesis) {
    this.hypothesisObserver.dispatch({
      type: HipothesisActionType.DELETE_HYPOTHESIS,
      payload: { hypothesis },
    });
  }
  public dispatchEdit(hypothesis: Hypothesis) {
    this.hypothesisObserver.dispatch({
      type: HipothesisActionType.EDIT_HYPOTHESIS,
      payload: { hypothesis },
    });
  }

  public dispatchAddLoad(hypothesis: Hypothesis, load: LoadStructuralData) {
    this.hypothesisObserver.dispatch({
      type: HipothesisActionType.ADD_LOAD,
      payload: { hypothesis, load },
    });
  }
  public dispatchDeleteLoad() {
    this.hypothesisObserver.dispatch({
      type: HipothesisActionType.DELETE_LOAD,
      payload: {},
    });
  }
  
  public dispatchAddWind() {
    this.hypothesisObserver.dispatch({
      type: HipothesisActionType.ADD_WIND_HYPOTHESIS,
      payload: {},
    });
  }

  public dispatchAddSSE() {
    this.hypothesisObserver.dispatch({
      type: HipothesisActionType.ADD_SSE_HYPOTHESIS,
      payload: {},
    });
  }
  public dispatcEditSSE() {
    this.hypothesisObserver.dispatch({
      type: HipothesisActionType.EDIT_SSE_HYPOTHESIS,
      payload: {},
    });
  }

  public dispatchRemoveWind() {
    this.hypothesisObserver.dispatch({
      type: HipothesisActionType.DELETE_WIND_HYPOTHESIS,
      payload: {},
    });
  }
}
export let hypothesisDispatcher: HypothesisDispatcher;
export function initHypothesisDispatcher() {
  hypothesisDispatcher = new HypothesisDispatcher();
  return hypothesisDispatcher;
}