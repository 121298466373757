import THREE from "three";
import { pointOnLine3D } from "./distance";
import { IPoint } from "./types";

export function mirrorPoint(point: IPoint, startPoint: IPoint, endPoint: IPoint): IPoint {
  const linePoint: IPoint = pointOnLine3D(startPoint, endPoint, point)[0];
  if (linePoint) {
    return {
      x: point.x + 2 * (linePoint.x - point.x),
      y: point.y + 2 * (linePoint.y - point.y),
      z: point.z,
    };
  } else {
    return point;
  }
}

export function mirrorBuffer(bufferGeometry: THREE.BufferGeometry, startPoint: IPoint, endPoint: IPoint) {
  const bufferArray = bufferGeometry.getAttribute("position").array as Float64Array;
  const end = bufferArray.length; // bufferGeometry.drawRange.count;
  console.assert(end < Infinity);
  for (let i: number = 0; i < end; i++) {
    const position: number = i * 3;
    let Vector3: IPoint = {
      x: bufferArray[position],
      y: bufferArray[position + 1],
      z: bufferArray[position + 2],
    };
    Vector3 = mirrorPoint(Vector3, startPoint, endPoint);
    bufferArray[position] = Vector3.x;
    bufferArray[position + 1] = Vector3.y;
    bufferArray[position + 2] = Vector3.z;
  }
}
