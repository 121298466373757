import { userMessageEvents } from "lib/events/user-messages";
import { graphicProcessor000 } from "lib/graphic-processor";
import { ShellCrossSection } from "lib/models-struc/cross-sections-shape/shell-cross-sections/shellcs-shapes";
import { shellCrossSectionCache } from "lib/models-struc/cross-sections-shape/shell-cross-sections/cache";
import { getDefaultShellCrossSectionShape, IShellCrossSection } from "lib/models-struc/cross-sections-shape/shell-cross-sections/types";
import { useResources } from "modules/struc/services/hooks/use-resources";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";

export function useShellCrossSections() {

  const { updateResource2 } = useResources("shell_cross_section");

  const [shellCrossSectionLoaded, setShellCrossSectionLoaded] = useState<ShellCrossSection[]>(() => {
    const list = shellCrossSectionCache.getAllLoadedStyles();
    return list.sort((a, b) => a.name.localeCompare(b.name));
  });
  const [canBeSaved, setCanBeSaved] = useState<boolean>(false);
  const [selectedShellCSS, setSelectedShellCSS] = useState<ShellCrossSection>(shellCrossSectionLoaded[0].clone());

  const { handleSubmit, register, reset } = useForm();

  const selectSlabById = useCallback((id: string) => {
    const newtempl = shellCrossSectionCache.loadStylefromCache(id)!;
    setSelectedShellCSS(newtempl);
    setCanBeSaved(false);
    reset();
  }, [reset]);

  const onChangeProp = useCallback((tempSlab: IShellCrossSection) => {
    if (selectedShellCSS.parameters.shellSectionType === tempSlab.parameters.shellSectionType) {
      selectedShellCSS.override(tempSlab);
    } else {
      selectedShellCSS.parameters = getDefaultShellCrossSectionShape(tempSlab.parameters.shellSectionType);
    }
    const original = shellCrossSectionCache.loadStylefromCache(selectedShellCSS.styleId)!;
    const originalJson = JSON.stringify(original);
    const editedJson = JSON.stringify(selectedShellCSS);
    const hasChanged = originalJson !== editedJson;
    setCanBeSaved(hasChanged);
  }, [selectedShellCSS])

  // --------------------------------------------------------------

  const saveChanges = async () => {
    const nameList = shellCrossSectionLoaded.map(s => s.name)
    if (!nameList.includes(selectedShellCSS.name)) {
      const newDef = selectedShellCSS.clone();
      shellCrossSectionCache.updateStyle(selectedShellCSS.styleId, newDef);
      setShellCrossSectionLoaded(() => {
        const list = shellCrossSectionCache.getAllLoadedStyles();
        return list.sort((a, b) => a.name.localeCompare(b.name));
      });

      setCanBeSaved(false);
      await updateResource2();
    } else {
      userMessageEvents.dispatchWarning(`Section with name "${selectedShellCSS.name}" already exists.`);
    }
  };

  const createShellCrossSection = async () => {
    const newDef = selectedShellCSS.clone();
    newDef.name = "New shell cross section";
    newDef.styleId = undefined!;
    newDef.default = false;
    shellCrossSectionCache.saveStyle(newDef.clone());
    setShellCrossSectionLoaded((prev: ShellCrossSection[]) => {
      const list = [...prev, newDef];
      return list.sort((a, b) => a.name.localeCompare(b.name));
    });
    setCanBeSaved(false);
    await updateResource2();
  };

  const deleteShellCrossSection = async () => {
    const currId = selectedShellCSS.styleId;
    if (shellCrossSectionCache.isSectionInUse(currId, graphicProcessor000)) {
      userMessageEvents.dispatchWarning(`Section "${selectedShellCSS.name}" is being used`);
      return;
    }
    const deleted = shellCrossSectionCache.deleteStyle(currId);
    if (deleted) {
      setShellCrossSectionLoaded((prev: ShellCrossSection[]) => {
        return prev.filter(b => b.styleId !== currId);
      });
      selectSlabById(shellCrossSectionLoaded[0].styleId);
      setCanBeSaved(false);
      await updateResource2();
    }
  };

  return {
    shellCrossSectionLoaded,
    selectedSlab: selectedShellCSS,
    canBeSaved,
    createShellCrossSection,
    deleteShellCrossSection,
    selectSlabById,
    register,
    handleSubmit: handleSubmit(onChangeProp),
    saveChanges: handleSubmit(saveChanges),
  }
}
