import { cadOpType } from "../factory";
import { Parameter3dOP } from "../solids/parameter-3d";
import { copyIPoint } from "../../math/point";
import { IPoint } from "../../math/types";
import { vectorDist3D } from "../../math/distance";
import { lineAngle2p, lineSlope2p } from "../../math/angles";
import { createBufferRPrism, createRPrism } from "../../geometries/solid/rprism";
import { RPrismCommand } from "../../commands/primitives/rprism";
import { settingsOpModes } from "../step-operations";
import { getCurrentSolidMaterial } from "lib/materials";
import { getXYZEulerFromEulerAngles, addEulerAnglesToEulerAngles } from "lib/math/plane";

export class RPrismOP extends Parameter3dOP {
  public opType = cadOpType.RPRISM;
  protected static sampleData = { sideX: 1, sideY: 2, sideZ: 0.5 };

  constructor() {
    super();
    this.offSet.x = RPrismOP.sampleData.sideX * 0.5;
    this.offSet.y = RPrismOP.sampleData.sideY * 0.5;
    this.offSet.z = RPrismOP.sampleData.sideZ * 0.5;
  }
  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Insert point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
      endStepCallback: () => {
        this.basePoint = copyIPoint(this.lastPoint);
        const planeManager = this.graphicProcessor.getPlaneManager();
        planeManager.activePlane.position = this.basePoint;
        planeManager.activePlane.locked = true;
      }
    }, {
      infoMsg: "Insert width: ",
      stepMode: settingsOpModes.ONEBOX,
      currValue: () => (RPrismOP.sampleData.sideX).toFixed(3),
      cmdLineListener: (cmd: string) => {
        const w = parseFloat(cmd);
        if (w) {
          RPrismOP.sampleData.sideX = w;
          this.offSet.x = RPrismOP.sampleData.sideX * 0.5;
          this.setNextStep();
          this.numPoints++;
          this.recalculateObj();
        }
      },
      endStepCallback: () => {
        const planeManager = this.graphicProcessor.getPlaneManager();
        planeManager.activePlane.position = this.basePoint;
        planeManager.activePlane.rotation = this.rotation;
        planeManager.activePlane.locked = true;
      }
    }, {
      infoMsg: "Insert length: ",
      stepMode: settingsOpModes.ONEBOX,
      currValue: () => (RPrismOP.sampleData.sideY).toFixed(3),
      cmdLineListener: (cmd: string) => {
        const h = parseFloat(cmd);
        if (h) {
          RPrismOP.sampleData.sideY = h;
          this.offSet.y = RPrismOP.sampleData.sideY * 0.5;
          this.setNextStep();
          this.numPoints++;
          this.recalculateObj();
        }
      },
      endStepCallback: () => {
        const planeManager = this.graphicProcessor.getPlaneManager();
        planeManager.activePlane.rotation = addEulerAnglesToEulerAngles({ x: Math.PI * 0.5, y: 0, z: 0 }, this.rotation);
      }
    }, {
      infoMsg: "Insert height: ",
      stepMode: settingsOpModes.ONEBOX,
      currValue: () => (RPrismOP.sampleData.sideZ).toFixed(3),
      cmdLineListener: (cmd: string) => {
        const h = parseFloat(cmd);
        if (h) {
          RPrismOP.sampleData.sideZ = h;
          this.offSet.z = RPrismOP.sampleData.sideZ * 0.5;
          this.endOperation();
        }
      },
      endStepCallback: () => {
        this.endOperation();
      }
    }]);
  }

  protected createAux() {
    return createRPrism(
      RPrismOP.sampleData.sideX,
      RPrismOP.sampleData.sideY,
      RPrismOP.sampleData.sideZ
    );
  }
  protected createGeom() {
    return createBufferRPrism(
      RPrismOP.sampleData.sideX,
      RPrismOP.sampleData.sideY,
      RPrismOP.sampleData.sideZ
    );
  }

  public moveLastPoint(point: IPoint) {
    if (this.numPoints === 0) {
      this.basePoint = copyIPoint(point);
      this.rotation = this.currPlane.rotation;
      this.recalculateObj();

    } else if (this.numPoints === 1) {
      RPrismOP.sampleData.sideX = vectorDist3D(this.basePoint, point);
      this.offSet.x = RPrismOP.sampleData.sideX * 0.5;
      const { x, y, z } = this.currPlane.rotation;
      const p1 = this.currPlane.getRelativePoint(point);
      const angle = lineAngle2p({ x: 0, y: 0, z: 0 }, p1);
      const slope = lineSlope2p({ x: 0, y: 0, z: 0 }, p1);
      const currRot = getXYZEulerFromEulerAngles(0, -slope, angle, "ZYX");
      this.rotation = { x: x + currRot.x, y: y + currRot.y, z: z + currRot.z };
      this.recalculateObj();

    } else if (this.numPoints === 2) {
      const p1 = this.currPlane.getRelativePoint(point);
      RPrismOP.sampleData.sideY = Math.abs(p1.y);
      if (p1.y > 0) this.offSet.y = RPrismOP.sampleData.sideY * 0.5;
      else this.offSet.y = -RPrismOP.sampleData.sideY * 0.5;
      this.recalculateObj();

    } else if (this.numPoints === 3) {
      const planeManager = this.graphicProcessor.getPlaneManager();
      const p1 = planeManager.activePlane.getRelativePoint(point);
      RPrismOP.sampleData.sideZ = p1.y;
      this.offSet.z = RPrismOP.sampleData.sideZ * 0.5;
      this.recalculateObj();
    }
  }

  public save(): void {
    if (this.graphicProcessor) {
      const command = new RPrismCommand(
        RPrismOP.sampleData.sideX,
        RPrismOP.sampleData.sideY,
        RPrismOP.sampleData.sideZ,
        this.basePoint,
        this.rotation,
        this.offSet,
        { x: 1, y: 1, z: 1 },
        this.getCurrentSceneId(),
        this.graphicProcessor,
        getCurrentSolidMaterial()
      );
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
}
