import { ScaleCommand } from "../../commands/transform/scale";
import { updateObjBboxBSphere } from "../../geometries";
import { getBoundingBoxMultiObj } from "../../math/box"
import { lineAddVertex, lineAuxCreate, lineMoveVertex } from "../../geometries/line";
import { scaleObj } from "../../geometries/scale";
import { vectorDist3D } from "../../math/distance";
import { copyIPoint } from "../../math/point";
import { IPoint } from "../../math/types";
import { TransformEdition } from "./transform-edition";
import { cadOpType } from "../factory";
import { settingsOpModes } from "../step-operations";

export class ScaleOP extends TransformEdition {

  public opType = cadOpType.SCALE;
  public scaleFactor: number = 1;
  public objX: number = 1;
  public objY: number = 1;
  public objZ: number = 1;
  public withCopy: boolean = false;

  private referenceMode: boolean = false;
  private refLine: THREE.Line;
  private refP1: IPoint;
  private refP2: IPoint;
  private refDist: number;
  private P1: IPoint;
  private P2: IPoint;

  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([
      {
        infoMsg: "Select objects.",
        stepMode: settingsOpModes.SELECTOBJS,
        multiSelect: true,
        enableSelectMarks: true,
        filterFun: () => true,
        endStepCallback: async () => {
          this.unRegisterRaycast();
          this.setAuxObjs();
          this.objDataAux.forEach((o) => this.saveToTempScene(o.graphicObj));
          this.registerInputs();
          this.registerUpdaters();
          this.initializeSnap();

          const bbox = getBoundingBoxMultiObj(this.objDataOrigin);
          this.objX = bbox.max.x - bbox.min.x;
          this.objY = bbox.max.y - bbox.min.y;
          this.objZ = bbox.max.z - bbox.min.z;
        },
      },
      {
        infoMsg: "Specify the base point.",
        stepMode: settingsOpModes.DEFAULTXYZ,
        cmdLineListener: this.addPointFromExt.bind(this),
        endStepCallback: () => {
          this.basePoint = copyIPoint(this.lastPoint);
          const { x, y, z } = this.basePoint;
          lineAddVertex(this.auxLine, x, y, z);
          lineAddVertex(this.auxLine, x, y, z);
        },
      },
      {
        infoMsg: `Specify the Scale factor or ["c" to set copy]["r" reference length]:`,
        stepMode: settingsOpModes.ONEBOX,
        currValue: () => this.scaleFactor.toFixed(4),
        cmdLineListener: (cmd: string) => {
          if (cmd.toLowerCase() === "c") {
            this.withCopy = !this.withCopy;
            this.updateMssgInfo();
          } else if (cmd.toLowerCase() === "r") {
            this.referenceMode = true;
            this.setNextStep();
          } else {
            const s = parseFloat(cmd);
            if (!isNaN(s)) {
              this.scaleFactor = s;
              this.save();
              this.endOperation();
            }
          }
        }
      },
      {
        infoMsg: "Specify reference length P1.",
        stepMode: settingsOpModes.DEFAULTXYZ,
        cmdLineListener: this.addPointFromExt.bind(this),
        startStepCallback: () => {
          for (const o of this.objDataAux) { o.graphicObj.visible = false }
          this.auxLine.visible = false;
        },
        endStepCallback: () => {
          this.refP1 = copyIPoint(this.lastPoint);
          const { x, y, z } = this.refP1;
          this.refLine = lineAuxCreate();
          lineAddVertex(this.refLine, x, y, z, 0);
          lineAddVertex(this.refLine, x, y, z, 1);
          this.saveToTempScene(this.refLine);
        },
      }, {
        infoMsg: "Specify reference length P2.",
        stepMode: settingsOpModes.DEFAULTXYZ,
        cmdLineListener: this.addPointFromExt.bind(this),
        endStepCallback: () => {
          for (const o of this.objDataAux) { o.graphicObj.visible = true }
          this.refP2 = copyIPoint(this.lastPoint);
          this.refDist = vectorDist3D(this.refP1, this.refP2);
          this.auxLine.visible = true;
        },
      }, {
        infoMsg: `Specify new length. ["p" to set points]`,
        stepMode: settingsOpModes.DEFAULTXYZ,
        cmdLineListener: (cmd: string) => {
          if (cmd.toLowerCase() === "p") {
            this.setNextStep();
          } else {
            this.addPointFromExt(cmd);
          }
        },
      }, {
        infoMsg: `Specify new length. P1`,
        stepMode: settingsOpModes.DEFAULTXYZ,
        cmdLineListener: this.addPointFromExt.bind(this),
        startStepCallback: () => {
          for (const o of this.objDataAux) { o.graphicObj.visible = false }
          this.auxLine.visible = false;
        },
        endStepCallback: () => {
          this.P1 = copyIPoint(this.lastPoint);
          const { x, y, z } = this.lastPoint;
          lineMoveVertex(this.auxLine, x, y, z, 0);
          lineMoveVertex(this.auxLine, x, y, z, 1);
          for (const o of this.objDataAux) { o.graphicObj.visible = true }
          this.auxLine.visible = true;
        },
      }, {
        infoMsg: `Specify new length. P2`,
        stepMode: settingsOpModes.DEFAULTXYZ,
        cmdLineListener: this.addPointFromExt.bind(this),
        endStepCallback: () => {
          this.save();
          this.endOperation();
        },
      }
    ]);
  }
  private updateMssgInfo() {
    if (this.withCopy) {
      this.settingsOpManager.currCfg.infoMsg = `Specify the Scale factor or ["c" to unset copy]["r" reference length]:`;
    } else {
      this.settingsOpManager.currCfg.infoMsg = `Specify the Scale factor or ["c" to set copy]["r" reference length]:`;
    }
    this.settingsOpManager.dispatchUpdateCurrStep();
  }

  public moveLastPoint(pto: IPoint) {
    if (this.basePoint) {

      if (this.referenceMode && this.refLine && !this.refDist) {
        lineMoveVertex(this.refLine, pto.x, pto.y, pto.z);

      } else {
        lineMoveVertex(this.auxLine, pto.x, pto.y, pto.z);
        const restFactor = this.scaleFactor === 0 ? 0 : 1 / this.scaleFactor;
        for (const o of this.objDataAux) {
          scaleObj(
            o.graphicObj,
            restFactor,
            restFactor,
            restFactor,
            this.basePoint
          );
        }
        this.scaleFactor = this.getScaleFactor(pto);
        for (const o of this.objDataAux) {
          scaleObj(
            o.graphicObj,
            this.scaleFactor,
            this.scaleFactor,
            this.scaleFactor,
            this.basePoint
          );
          updateObjBboxBSphere(o.graphicObj);
        }
      }
    }
  }

  public getScaleFactor(pto: IPoint): number {
    if (this.basePoint && pto) {
      let newDist = vectorDist3D(this.basePoint, pto);
      if (this.referenceMode && this.refDist) {
        if (this.P1) {
          newDist = vectorDist3D(this.P1, pto);
        }
        return newDist / this.refDist;
      }
      const max = Math.max.apply(null, [this.objX, this.objY, this.objZ]);
      return newDist / max;
    } else {
      return 1;
    }
  }

  public save() {
    if (this.graphicProcessor && this.basePoint && this.scaleFactor) {
      const command = new ScaleCommand(
        this.basePoint,
        this.scaleFactor,
        this.scaleFactor,
        this.scaleFactor,
        this.objDataOrigin,
        this.withCopy,
        this.graphicProcessor
      );
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
}