import { requestStatus } from "lib/apis/utils";
import { StructModel3D } from "lib/helpers/structmodel3d";
import PropertiesAccordion from "modules/cad/components/properties/component-accordion";
import PropertiesButton from "modules/cad/components/properties/component-button";
import PanelPropertiesForm from "modules/cad/components/properties/properties-panel-form";
import { useMainGraphicContext } from "modules/cad/components/viewport/context";
import { useCallback } from "react";
import { useStrucProjectContext } from "../context";
import { useMeshViewer } from "./hook/use-mesh-viewer";

const MeshViewerPanel = ({ isMeshDownloaded }: { isMeshDownloaded: boolean }) => {

  const graphicProc = useMainGraphicContext();
  const { meshViewerSettings } = useMeshViewer(graphicProc);
  const { meshManager } = useStrucProjectContext();

  const downloadAndDrawMesh = useCallback(async () => {
    const jsonMesh = await meshManager.downloadMesh(graphicProc);
    StructModel3D.createStructModel3D_from_JSON_graphicProcessor(jsonMesh, graphicProc);
  }, [graphicProc, meshManager]);

  if (meshManager.status === requestStatus.ERROR) {
    return (
      <div className={"bg-red-500 rounded text-xs text-center"}>
        Mesh with errors. Relaunch mesh
      </div>
    )
  }
  if (meshManager.status === requestStatus.DONE) {
    return (
      <PropertiesAccordion title={"Mesh viewer"} >
        <PropertiesButton
          className="mb-2"
          label={"View mesh"}
          buttonCb={downloadAndDrawMesh} />
        {isMeshDownloaded && <PanelPropertiesForm panelProp={meshViewerSettings} />}
      </PropertiesAccordion>
    )
  }
  return null;
};

export default MeshViewerPanel;
