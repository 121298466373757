import ChevronRightIcon from "shared/components/icons/chevron-right";
import { cadOpType } from "lib/operations/factory";
import { useOperation } from "../operations/context";
import { useOpSettings } from "./hooks/use-op-settings";
import RenderIf from "shared/components/ui/render-if";
import { useState, useCallback, ChangeEvent, FormEvent, KeyboardEvent } from "react";
import { GraphicProcessor } from "lib/graphic-processor";

const getCommand = (key: cadOpType) => cadOpType[key];

interface Props {
  graphicProc: GraphicProcessor;
}
const Console = ({ graphicProc }: Props) => {

  const { opSettings } = useOpSettings(graphicProc);
  const [, setHistory] = useState<string[]>([]);
  const { launchOperation } = useOperation();
  const [command, setCommand] = useState("");

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setCommand(event.target.value);
  }, []);

  const blurInput = () => document.getElementById("command")?.blur();

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Escape") {
        blurInput();
      }
    }, []
  );

  const cleanCommand = useCallback(() => {
    setHistory((current) => [...current, command]);
    setCommand("");
  }, [command, setCommand]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      if (opSettings.currentStep?.cmdLineListener) {
        opSettings.currentStep.cmdLineListener(command);
      } else {
        const cadOpType = command.toUpperCase() as any;
        const currentCommand = getCommand(cadOpType);
        launchOperation(+currentCommand);
      }
    } catch (error) {
      console.log(error);
    }
    blurInput();
  };
  return (
    <div className="w-full h-8 border-t border-gray-700 inline-flex items-center space-x-2">
      <div className="flex items-center">
        <ChevronRightIcon className="h-4" />
        <label htmlFor="command">Command:</label>
        <span className="ml-2">{`${opSettings.currentOperation?.pubOpName || ""} ${opSettings.currentStep?.infoMsg || ""}`}</span>
      </div>
      <RenderIf isTrue={!!opSettings.currentStep?.cmdLineListener}>
        <form onSubmit={handleSubmit}>
          <input
            id="command"
            className="bg-transparent focus:outline-none"
            onChange={handleChange}
            value={command || ""}
            onKeyDown={handleKeyDown}
            onBlur={cleanCommand}
            onFocus={cleanCommand}
            autoComplete="off"
          />
        </form>
      </RenderIf>
    </div>
  );
};

export default Console;
