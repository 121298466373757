import { analysisManager } from '../analysis/analysismodel-manager';
import { hypothesisDispatcher } from "./dispatcher";
import { getDefaultSEEHypothesisValues, SSEDirs, SSHypothesis } from "./hypothesis";

export class SSEHypothesis {

  private SSx: SSHypothesis;
  SSxIsActive: boolean = false;

  private SSy: SSHypothesis;
  SSyIsActive: boolean = false;

  private SSz: SSHypothesis;
  SSzIsActive: boolean = false;

  getSSE(type: SSEDirs): SSHypothesis {
    switch (type) {
      case SSEDirs.SSX: return this.SSx;
      case SSEDirs.SSY: return this.SSy;
      case SSEDirs.SSZ: return this.SSz;
    }
  }
  hasSSE(): boolean {
    return this.SSxIsActive || this.SSyIsActive || this.SSzIsActive;
  }
  getActiveSSEDirs(omitType?: SSEDirs): SSEDirs[] {
    const list: SSEDirs[] = [];
    if (omitType !== SSEDirs.SSX && this.SSxIsActive) list.push(SSEDirs.SSX);
    if (omitType !== SSEDirs.SSY && this.SSyIsActive) list.push(SSEDirs.SSY);
    if (omitType !== SSEDirs.SSZ && this.SSzIsActive) list.push(SSEDirs.SSZ);
    return list;
  }
  getSSEByUuid(uuid: string) {
    if (this.SSx && this.SSx.uid === uuid) return this.SSx;
    if (this.SSy && this.SSy.uid === uuid) return this.SSy;
    if (this.SSz && this.SSz.uid === uuid) return this.SSz;
  }
  getActiveSSE(): SSHypothesis[] {
    const list: SSHypothesis[] = [];
    if (this.SSxIsActive) list.push(this.SSx);
    if (this.SSyIsActive) list.push(this.SSy);
    if (this.SSzIsActive) list.push(this.SSz);
    return list;
  }
  getActiveSSEHypothesis(): SSHypothesis[] {
    const list: SSHypothesis[] = [];
    if (this.SSxIsActive) list.push(this.SSx);
    if (this.SSyIsActive) list.push(this.SSy);
    if (this.SSzIsActive) list.push(this.SSz);
    return list;
  }
  initSSE(): void {
    this.activeSSx(true);
    this.activeSSy(true);
    this.activeSSz(false);
    hypothesisDispatcher.dispatchAddSSE();
  }
  activeSSx(isActive: boolean) {
    this.SSxIsActive = isActive;
    if (this.SSx === undefined && isActive) {
      this.SSx = getDefaultSEEHypothesisValues(SSEDirs.SSX);
    }
    if (!isActive && analysisManager.analysis.solveHypothesis.includes(this.SSx)) {
      const i = analysisManager.analysis.solveHypothesis.indexOf(this.SSx);
      analysisManager.analysis.solveHypothesis.splice(i, 1);
    }
  }
  activeSSy(isActive: boolean) {
    this.SSyIsActive = isActive;
    if (this.SSy === undefined && isActive) {
      this.SSy = getDefaultSEEHypothesisValues(SSEDirs.SSY);
    }
    if (!isActive && analysisManager.analysis.solveHypothesis.includes(this.SSy)) {
      const i = analysisManager.analysis.solveHypothesis.indexOf(this.SSy);
      analysisManager.analysis.solveHypothesis.splice(i, 1);
    }
  }
  activeSSz(isActive: boolean) {
    this.SSzIsActive = isActive;
    if (this.SSz === undefined && isActive) {
      this.SSz = getDefaultSEEHypothesisValues(SSEDirs.SSZ);
    }
    if (!isActive && analysisManager.analysis.solveHypothesis.includes(this.SSz)) {
      const i = analysisManager.analysis.solveHypothesis.indexOf(this.SSz);
      analysisManager.analysis.solveHypothesis.splice(i, 1);
    }
  }
  addHypothesis(hypo: SSHypothesis) {
    if (hypo.sseDir === SSEDirs.SSX) {
      this.SSx = { ...hypo, values: hypo.values.map(v => ({ ag: v.ag, T: v.T })) };
      this.SSxIsActive = true;
    }
    if (hypo.sseDir === SSEDirs.SSY) {
      this.SSy = { ...hypo, values: hypo.values.map(v => ({ ag: v.ag, T: v.T })) };
      this.SSyIsActive = true;
    }
    if (hypo.sseDir === SSEDirs.SSZ) {
      this.SSz = { ...hypo, values: hypo.values.map(v => ({ ag: v.ag, T: v.T })) };
      this.SSzIsActive = true;
    }
  }
}

export let SSEhypothesisManager: SSEHypothesis;
export function initSEEHypothesisManager() {
  SSEhypothesisManager = new SSEHypothesis();
  return SSEhypothesisManager;
}