import * as THREE from "three";
import { addIpoint } from "lib/math/point";
import { markBulkTypes, ObjDataSelector } from "../selector-data";
import { SelectionManager } from "lib/selection/selection-manager";
import { cadOpType } from "lib/operations/factory";
import { WallData } from "lib/models/structural/wall";

export class WallDataSelector extends ObjDataSelector<WallData> {

  public override buildSelectMarks() {
    const { basePoint, ptos2D, stretch } = this.data.definition;

    const bulkData: markBulkTypes = {};
    const vertex = ptos2D.points.map(p => addIpoint(p, basePoint));
    vertex.shift();
    for (const s of stretch) {
      for (const hole of s.holes) {
        vertex.push(...hole.points.map(p => addIpoint(p, basePoint)));
      }
    }
    bulkData.vertex = vertex;
    bulkData.origin = [basePoint];
    return bulkData;
  }

  public override launchMarkOP(selectionManager: SelectionManager, mark: THREE.Object3D, index: number) {
    if (selectionManager.originSelector.pointObj === mark) {
      return {
        type: cadOpType.EDITWALL,
        graphicProcessor: this.graphicProc,
        args: [this.data, 0],
      };
    } else if (selectionManager.vertexSelector.pointObj === mark) {
      if (index < this.data.definition.ptos2D.points.length - 1) {
        return {
          type: cadOpType.EDITWALL,
          graphicProcessor: this.graphicProc,
          args: [this.data, index + 1],
        };
      }
    }
  }
}
