const UndoIcon = ({ ...props }) => {
  return (
    <svg className="w-6 h-6" viewBox="0 0 100 100" fill="none" {...props}>
      <path
        d="M81.25 59.375C81.25 65.1766 78.9453 70.7406 74.843 74.843C70.7406 78.9453 65.1766 81.25 59.375 81.25H50C49.1712 81.25 48.3763 80.9208 47.7903 80.3347C47.2042 79.7487 46.875 78.9538 46.875 78.125C46.875 77.2962 47.2042 76.5013 47.7903 75.9153C48.3763 75.3292 49.1712 75 50 75H59.375C63.519 75 67.4933 73.3538 70.4235 70.4235C73.3538 67.4933 75 63.519 75 59.375C75 55.231 73.3538 51.2567 70.4235 48.3265C67.4933 45.3962 63.519 43.75 59.375 43.75H29.4062L42.8437 57.1563C43.1366 57.4468 43.3691 57.7924 43.5278 58.1732C43.6864 58.554 43.7681 58.9625 43.7681 59.375C43.7681 59.7875 43.6864 60.196 43.5278 60.5768C43.3691 60.9576 43.1366 61.3032 42.8437 61.5937C42.5532 61.8866 42.2076 62.1191 41.8268 62.2778C41.446 62.4364 41.0375 62.5181 40.625 62.5181C40.2125 62.5181 39.804 62.4364 39.4232 62.2778C39.0424 62.1191 38.6968 61.8866 38.4062 61.5937L19.6562 42.8437C19.3633 42.5532 19.1309 42.2076 18.9722 41.8268C18.8136 41.446 18.7319 41.0375 18.7319 40.625C18.7319 40.2125 18.8136 39.804 18.9722 39.4232C19.1309 39.0424 19.3633 38.6968 19.6562 38.4062L38.4062 19.6562C38.6976 19.3649 39.0435 19.1338 39.4242 18.9761C39.8049 18.8184 40.2129 18.7372 40.625 18.7372C41.0371 18.7372 41.4451 18.8184 41.8258 18.9761C42.2065 19.1338 42.5524 19.3649 42.8437 19.6562C43.1351 19.9476 43.3662 20.2935 43.5239 20.6742C43.6816 21.0549 43.7628 21.4629 43.7628 21.875C43.7628 22.2871 43.6816 22.6951 43.5239 23.0758C43.3662 23.4565 43.1351 23.8024 42.8437 24.0937L29.4062 37.5H59.375C65.1766 37.5 70.7406 39.8047 74.843 43.907C78.9453 48.0094 81.25 53.5734 81.25 59.375Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default UndoIcon;
