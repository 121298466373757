import { SlabCommand } from "lib/commands/structural/slab";
import { createSlab, updateSlab } from "lib/geometries/structural/slab";
import { IPoint } from "lib/math/types";
import { defaultFlatSectionId, shellCrossSectionCache } from "lib/models-struc/cross-sections-shape/shell-cross-sections/cache";
import { dataInfoProperty } from "lib/properties/properties";
import { cadOpType } from "../factory";
import { settingOp, settingsOpModes } from "../step-operations";
import { StructElementBaseOP } from "./structural";
import { linealPrecision } from '../../general-settings';
import { IObjData } from "lib/models/objdata";
import { isLineClosedData } from '../../models/checktools';
import { LineData } from "lib/models/primitives/line";
import { copyIPoint, substractIpoint } from '../../math/point';
import { userMessageEvents } from "lib/events/user-messages";
import { intersectsItself } from "lib/math/intersections";
import { isWaffleCSSParams, isSandwichCSSParams, isFlatCSSParams } from "lib/models-struc/cross-sections-shape/shell-cross-sections/types";

interface ISlabSettings {
  shellCSSId: string,
  waffleCSSId: string;
  depth: number,
  polyline: undefined,
}

export class SlabOP extends StructElementBaseOP {

  public opType = cadOpType.SLAB;

  private points: IPoint[] = [];
  private holes: IPoint[][] = [];

  private shellCrossSectionId: string = defaultFlatSectionId;
  private waffleCrossSectionId: string = "";

  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Insert point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
      panelProp: this.setPanelProperties(),
    }, {
      infoMsg: "Select Polyline",
      stepMode: settingsOpModes.SELECTOBJS,
      enableSelectMarks: false,
      multiSelect: false,
      filterFun: (obj: IObjData) => {
        if (!isLineClosedData(obj)) {
          userMessageEvents.dispatchError("Select a closed polyline");
          return false;
        }
        return true;
      },
      endStepCallback: () => {
        const lineData = this.objDataOrigin[0] as LineData;
        const ptos = lineData.definition.points;
        this.basePoint = copyIPoint(ptos[0]);
        this.points = ptos.map((p => (substractIpoint(p, this.basePoint))));
        super.endOperation();
      },
      startStepCallback: (cfg: settingOp) => {
        this.unRegisterInputs();
        this.closeSnap();
        this.clearWorkingPlane();
        this.registerRaycast();
      },
    }]);
  }

  private setPanelProperties() {
    const flatCSSnameList: [string, string][] = [];
    const waffleCSSnameList: [string, string][] = [["None", ""]];
    const ShellCSSs = shellCrossSectionCache.getAllLoadedStyles().sort((a, b) => a.name.localeCompare(b.name));
    for (const shellCSS of ShellCSSs) {
      const param = shellCSS.parameters;
      if (isWaffleCSSParams(param) || isSandwichCSSParams(param)) {
        waffleCSSnameList.push([shellCSS.name, shellCSS.styleId]);
      } else if (isFlatCSSParams(param)) {
        flatCSSnameList.push([shellCSS.name, shellCSS.styleId]);
      }
    }
    const slabTempl = shellCrossSectionCache.loadStylefromCache(this.shellCrossSectionId)!;
    const panelProp: dataInfoProperty<ISlabSettings> = {
      shellCSSId: {
        publicName: "Shell cross section",
        value: this.shellCrossSectionId,
        editable: true,
        type: "tagList",
        tagList: flatCSSnameList,
      },
      waffleCSSId: {
        publicName: "Waffle cross section",
        value: this.waffleCrossSectionId,
        editable: true,
        type: "tagList",
        tagList: waffleCSSnameList,
      },
      depth: {
        publicName: "Depth (shell)",
        value: slabTempl.thickness,
        editable: false,
        type: "number",
        precision: linealPrecision,
      },
      polyline: {
        publicName: "Select polyline",
        type: "button",
        buttonCb: () => {
          this.setNextStep(1);
        }
      }
    };
    return {
      propValue: panelProp,
      propCallback: this.updatePanel.bind(this),
    };
  }
  public updatePanel(newSlabProp: ISlabSettings) {
    this.shellCrossSectionId = newSlabProp.shellCSSId;
    this.waffleCrossSectionId = newSlabProp.waffleCSSId;
    const flatCSS = shellCrossSectionCache.loadStylefromCache(this.shellCrossSectionId)!;
    updateSlab(this.auxMesh, this.points, flatCSS.thickness, this.holes);
    this.setPosition();

    this.settingsOpManager.currCfg = {
      ...this.settingsOpManager.currCfg,
      panelProp: this.setPanelProperties(),
    }
    this.settingsOpManager.dispatchUpdateCurrStep();
  }

  public async start() {
    await super.start();
    this.iniSettingsOp();

    const flatCSS = shellCrossSectionCache.loadStylefromCache(this.shellCrossSectionId)!;
    this.auxMesh = createSlab([], flatCSS.thickness, this.holes)
    this.saveToTempScene(this.auxMesh);
  }

  public setLastPoint(): void {
    const planeManager = this.graphicProcessor.getPlaneManager();
    if (this.numPoints === 1) {
      planeManager.activePlane.position = copyIPoint(this.lastPoint);
      planeManager.activePlane.locked = true;
    }
    const nextPto = planeManager.activePlane.getRelativePoint(this.lastPoint);

    if (this.points.length) {
      const holeIntersectItSelf = intersectsItself([...this.points, nextPto, this.points[0]]);
      if (holeIntersectItSelf) {
        userMessageEvents.dispatchError("Slab countour cannot intersect itself");
        return;
      }
    }
    this.points.push(nextPto);

    const flatCSS = shellCrossSectionCache.loadStylefromCache(this.shellCrossSectionId)!;
    updateSlab(this.auxMesh, this.points, flatCSS.thickness, this.holes);
    this.setPosition();
  }

  public moveLastPoint(pto: IPoint) {
    const planeManager = this.graphicProcessor.getPlaneManager();
    const ptos2D = this.points.concat(planeManager.activePlane.getRelativePoint(pto));
    const flatCSS = shellCrossSectionCache.loadStylefromCache(this.shellCrossSectionId)!;
    updateSlab(this.auxMesh, ptos2D, flatCSS.thickness, this.holes);
    this.setPosition();
  }

  private setPosition() {
    const planeManager = this.graphicProcessor.getPlaneManager();
    const { position, rotation } = planeManager.activePlane;
    this.basePoint = position;
    this.rotation = rotation;
    this.auxMesh.position.set(position.x, position.y, position.z);
    this.auxMesh.rotation.set(rotation.x, rotation.y, rotation.z);
  }

  public save() {
    if (this.graphicProcessor && this.points.length > 2) {
      const flatCSS = shellCrossSectionCache.loadStylefromCache(this.shellCrossSectionId)!;
      const command = new SlabCommand(
        this.currentStorey ? this.currentStorey.columnConcreteType : 'HA21',
        this.shellCrossSectionId,
        this.waffleCrossSectionId ?? undefined,
        flatCSS.thickness,

        this.points,
        this.holes[0]?.length ? this.holes : [],

        this.basePoint,
        this.rotation,
        this.offSet,
        this.scale,

        this.graphicProcessor
      );
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
  public cancelOperation(): void {
    if (!this.finished) {
      this.endOperation();
    }
  }
}
