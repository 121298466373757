import * as THREE from "three";
import { getCurrentSolidMaterial, ISolidMaterial, materialCache } from "lib/materials";
import { setPosRotEsc } from "../../geometries";
import { createBufferPPrism, createPPrism, pprismParam } from "../../geometries/solid/pprism";
import { copyIPoint } from "../../math/point";
import { objDataType } from "../types";
import { SolidData } from "./solid";

export class PPrismData extends SolidData {

  public type = objDataType.PPRISM;
  protected nameObj: string = "Poligonal prism";
  public definition: pprismParam;

  constructor(definition: pprismParam, material?: ISolidMaterial) {
    super();
    this.definition = {
      open: definition.open,
      radius: definition.radius,
      sides: definition.sides,
      height: definition.height,
      basePoint: copyIPoint(definition.basePoint),
      rotation: copyIPoint(definition.rotation),
      offset: copyIPoint(definition.offset),
      scale: copyIPoint(definition.scale),
    };
    this.material = material ?? getCurrentSolidMaterial();
  }
  static createObj(definition: pprismParam, material: ISolidMaterial) {
    const threeMaterial = materialCache.getSolidMaterial(material);
    return createPPrism(
      definition.radius,
      definition.height,
      definition.sides,
      definition.open,
      threeMaterial,
      definition.basePoint,
      definition.rotation,
      definition.offset,
      definition.scale
    );
  }
  public createGraphicObj() {
    if (this.graphicObj) {
      console.warn("Attention: PPrism graphic object already created!!");
      return;
    }
    this.graphicObj = PPrismData.createObj(this.definition, this.material);
  }
  public cloneDefinition(): pprismParam {
    const def = super.cloneSolidDefinition() as pprismParam;
    def.open = this.definition.open;
    def.radius = this.definition.radius;
    def.sides = this.definition.sides;
    def.height = this.definition.height;
    return def;
  }
  public createObject(definition?: pprismParam, material?: ISolidMaterial): THREE.Mesh {
    return PPrismData.createObj(definition ?? this.definition, material ?? this.material);
  }
  public regenerateDefinition() {
    const geom = createBufferPPrism(this.definition.radius, this.definition.height, this.definition.sides, this.definition.open);
    this.graphicObj.geometry = geom;
    const { basePoint, offset, rotation, scale } = this.definition;
    setPosRotEsc(this.graphicObj, basePoint, offset, rotation, scale);
  }
}