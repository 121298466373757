import { getDataModelBuilder } from "lib/models/model-creator/datamodel-factory";
import { IObjData } from "lib/models/objdata"
import { definitionType, materialType, objDataType } from "lib/models/types";
import { CadCommand } from "../base"
import { GraphicProcessor } from "lib/graphic-processor";

export abstract class createCommand extends CadCommand {

  public abstract createdData: IObjData;
  protected abstract objType: objDataType;
  protected abstract param: definitionType;
  protected abstract material: materialType | undefined;

  constructor(layerId: string, graphicProcessor: GraphicProcessor) {
    super(graphicProcessor);
    if (layerId) {
      this.layerId = layerId;
    } else {
      const layerManager = this.graphicProcessor.getLayerManager();
      this.layerId = layerManager.currentLayer.id;
    }
  }

  public async execute() {
    if (this.createdData === undefined) {
      this.createdData = getDataModelBuilder(this.objType, this.param, this.material);
      this.createdData.createGraphicObj();
    } else {
      this.createdData.addObjAsDependence();
    }
    this.graphicProcessor.addToLayer(this.createdData, this.layerId);

    const modelManager = this.graphicProcessor.getDataModelManager();
    modelManager.dispatchAddedObjs([this.createdData]);
    const lyrManager = this.graphicProcessor.getLayerManager();
    lyrManager.layerObserver.dispatchLoadLayers();
  }

  public unExecute(): void | Promise<void> {
    if (this.createdData) {
      this.createdData.removeObjAsDependence();
      this.graphicProcessor.removeFromLayer(this.createdData);

      const modelManager = this.graphicProcessor.getDataModelManager();
      modelManager.dispatchDeletedObjs([this.createdData]);
      const lyrManager = this.graphicProcessor.getLayerManager();
      lyrManager.layerObserver.dispatchLoadLayers();
    }
  }

  public delete(): void {
    this.createdData = undefined!;
  }
}
