import { buildingElemType, buildingStrucElemRepresentation } from "lib/models-struc/types/struc-base";
import { CadCommand } from "../base";
import { IStrucElementData } from "lib/models/structural/structural";
import { GraphicProcessor } from "lib/graphic-processor";
import { getDataModelBuilder } from "lib/models/model-creator/datamodel-factory";

export abstract class createStructuralElementCommand extends CadCommand {

  protected abstract elemType: buildingElemType;
  protected abstract structuralParam: buildingStrucElemRepresentation[];

  public createdObjs: IStrucElementData[] = [];
  protected storeyId: string;

  constructor(storeyId: string, layerId: string, graphicProc: GraphicProcessor) {
    super(graphicProc);
    this.storeyId = storeyId;
    this.layerId = layerId;
  }

  public execute() {
    if (this.createdObjs.length === 0) {
      for (let structuralParam of this.structuralParam) {
        const objData = getDataModelBuilder(this.elemType, structuralParam) as IStrucElementData;
        objData.createGraphicObj();
        this.graphicProcessor.addToLayer(objData, this.layerId);
        this.createdObjs.push(objData);
      }
    } else {
      for (let createdObj of this.createdObjs) {
        this.graphicProcessor.addToLayer(createdObj, this.layerId);
      }
    }
    const modelManager = this.graphicProcessor.getDataModelManager();
    modelManager.dispatchAddedObjs(this.createdObjs);
    const lyrManager = this.graphicProcessor.getLayerManager();
    lyrManager.layerObserver.dispatchLoadLayers();
  }

  public unExecute() {
    for (let createdObj of this.createdObjs) {
      this.graphicProcessor.removeFromLayer(createdObj);
    }
    const modelManager = this.graphicProcessor.getDataModelManager();
    modelManager.dispatchDeletedObjs(this.createdObjs);
    const lyrManager = this.graphicProcessor.getLayerManager();
    lyrManager.layerObserver.dispatchLoadLayers();
  }

  public delete() {
    this.structuralParam.length = 0;
    this.createdObjs.length = 0;
  }
}
