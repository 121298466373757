import { Suspense } from "react";
import { useAuth } from "../auth/context";
import LoadingDots from "../ui/loading-dots";
import AuthenticatedApp from "./authenticated-app";
import UnauthenticatedApp from "./unauthenticated-app";
// const AuthenticatedApp = lazy(() => import("./authenticated-app"));
// const UnauthenticatedApp = lazy(() => import("./unauthenticated-app"));

const Loader = () => (
  <div className="h-screen m-auto">
    <LoadingDots />
  </div>
);
function App() {
  const { user, status } = useAuth();
  return (
    <Suspense fallback={<Loader />}>
      {user && status !== "SIGNIN_LOADING" ? (
        <AuthenticatedApp />
      ) : (
        <UnauthenticatedApp />
      )}
    </Suspense>
  );
}

export default App;
