import { FC } from "react";
import AccountForm from "../components/account/account-form";
import useAccount from "../components/account/hooks/use-account";
import Page from "../components/layout/page";

const Account: FC = () => {
  const { user } = useAccount();
  return <Page title="Account">{user && <AccountForm user={user} />}</Page>;
};

export default Account;
