import { ISnapSettings } from "lib/operations/snap/snap";
import { memo } from "react";
import CenterIcon from "../icons/center";
import IntersectionIcon from "../icons/intersection";
import MiddleIcon from "../icons/middle";
import NearIcon from "../icons/near";
import ParallelIcon from "../icons/parallel";
import PerpendicularIcon from "../icons/perpendicular";
import QuadrantIcon from "../icons/quadrant";
import TangentIcon from "../icons/tangent";
import VertexIcon from "../icons/vertex";
import ToolbarButton from "../toolbar/toolbar-button";
import { useSnap } from "./hooks/use-snap";

const ICONS: Record<keyof ISnapSettings, any> = {
  near: NearIcon,
  points: CenterIcon,
  vertex: VertexIcon,
  middle: MiddleIcon,
  center: CenterIcon,
  quadrant: QuadrantIcon,
  intersection: IntersectionIcon,
  extension: CenterIcon,
  tangent: TangentIcon,
  perpendicular: PerpendicularIcon,
  parallel: ParallelIcon,
  orto: null,
};

const SnapToolbar = memo(() => {
  const { snapOptions, handleChange } = useSnap();

  return (
    <>
      {snapOptions.map(([key, value]) => {
        const Icon = ICONS[key as keyof ISnapSettings];
        return (
          <ToolbarButton
            key={key}
            onSelect={() => {}}
            onClick={(event: any) => {
              event.preventDefault();
              event.stopPropagation();
              handleChange(key as keyof ISnapSettings);
            }}
            isActive={!!value}
            tooltip={key}
          >
            <Icon />
          </ToolbarButton>
        );
      })}
    </>
  );
});

export default SnapToolbar;
