import { useState } from "react";

export function useModal() {
  const [isOpen, setIsOpen] = useState(false);
  const open = (event?: any) => {
    event?.preventDefault();
    setIsOpen(true);
  };
  const close = (event?: any) => {
    event?.preventDefault();
    setIsOpen(false);
  };
  return {
    isOpen,
    open,
    close,
  };
}
