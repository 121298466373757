import { GraphicProcessor } from "../../graphic-processor";
import { IObjData } from "../../models/objdata";
import { CadCommand } from "../base";

/** Move, Rotate, Scale, Mirror, Align Commnands
 *
 * @export
 * @abstract
 * @class EditBasicCommand
 * @extends {CadCommand} 
 */

export abstract class TransformCommand extends CadCommand {

  protected objDatas: IObjData[];

  protected withCopy: boolean = false;
  protected createdObjs: Map<IObjData, IObjData> = new Map();

  constructor(objIds: IObjData[], graphicProcessor: GraphicProcessor) {
    super(graphicProcessor);
    this.objDatas = objIds;
  }

  protected abstract transformFun: (obj: IObjData) => void;
  protected abstract unDoTransformFun: (obj: IObjData) => void;

  execute() {
    for (const obj of this.objDatas) {
      this.transformFun(obj);
    }
    const modelManager = this.graphicProcessor.getDataModelManager();
    if (this.withCopy) {
      modelManager.dispatchAddedObjs([...this.createdObjs.values()]);
      const lyrManager = this.graphicProcessor.getLayerManager();
      lyrManager.layerObserver.dispatchLoadLayers();
    } else {
      modelManager.dispatchEditObjs(this.objDatas, this.opType);
    }
  }

  unExecute() {
    for (const obj of this.objDatas) {
      this.unDoTransformFun(obj);
    }
    const modelManager = this.graphicProcessor.getDataModelManager();
    if (this.withCopy) {
      modelManager.dispatchDeletedObjs([...this.createdObjs.values()]);
      const lyrManager = this.graphicProcessor.getLayerManager();
      lyrManager.layerObserver.dispatchLoadLayers();
    } else {
      modelManager.dispatchEditObjs(this.objDatas, this.opType);
    }
  }

  delete() {
    this.objDatas.length = 0;
    this.createdObjs.clear();
  }
}
