import { GraphicProcessor } from "lib/graphic-processor";
import { CircularDimensionData, circularDimensionParam } from "lib/models/dimension/circular-dim";
import { objDataType } from "lib/models/types";
import { cadOpType } from "lib/operations/factory";
import { EditDataCommand } from "../edition/edit-base";
import { createCommand } from "../primitives/create-base";

export class RadialDimensionCommand extends createCommand {
  
  public createdData: CircularDimensionData;
  protected opType = cadOpType.RADIUSDIM;
  protected objType = objDataType.CIRCULARDIM;
  protected param: circularDimensionParam;
  protected material: undefined;

  constructor(params: circularDimensionParam, layerId: string, graphicProc: GraphicProcessor) {
    super(layerId, graphicProc);
    this.param = params;
  }
}

export class RadialDimensionEditDataCommand extends EditDataCommand {
  
  protected opType = cadOpType.EDITRADIUSDIM;

  constructor(obj: CircularDimensionData, newDefinition: circularDimensionParam | null, graphicProcessor: GraphicProcessor) {
    super(obj, newDefinition, null, graphicProcessor);
  }
}

export class DiameterDimensionCommand extends createCommand {

  public createdData: CircularDimensionData;
  protected opType = cadOpType.DIAMETERDIM;
  protected objType = objDataType.CIRCULARDIM;
  protected param: circularDimensionParam;
  protected material: undefined;

  constructor(params: circularDimensionParam, layerId: string, graphicProc: GraphicProcessor) {
    super(layerId, graphicProc);
    this.param = params;
  }
}

export class DiameterDimensionEditDataCommand extends EditDataCommand {
  
  protected opType = cadOpType.EDITDIAMETERDIM;
  
  constructor(obj: CircularDimensionData, newDefinition: circularDimensionParam | null, graphicProcessor: GraphicProcessor) {
    super(obj, newDefinition, null, graphicProcessor);
  }
}