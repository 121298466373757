import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root {
    --color-primary: #6366f1;
    --color-primary-dark: #4338CA;
    --color-gray-900: #18181b;
    --color-gray-800: #27272a;
    --color-gray-700: #3f3f46;
    --color-gray-600: #52525b;
    --color-gray-500: #a1a1aa;
    --color-gray-400: #d4d4d8;
    --color-gray-300: #e4e4e7;
    --color-gray-200: #f4f4f5;
    --color-gray-100: #fafafa;
    --color-white: #ffffff;
    --color-red-100: #FCA5A5;
    --color-red-200: #EF4444;
    --color-red-300: #DC2626;
    --color-background: var(--color-white);
    --color-divider: var(--color-gray-300);
    --color-secondary: var(--color-white);
    --color-secondary-dark: var(--color-gray-200);
    --color-text: var(--color-gray-800);
    --color-text-secondary: var(--color-gray-600);
    --radius: 4px;
    --shadow:rgba(63,63,68,.05) 0 0 0 1px,rgba(63,63,68,.15) 0 1px 3px 0;
  }
  .dark {
    --color-gray-900: #fafafa;
    --color-gray-800: #f4f4f5;
    --color-gray-700: #e4e4e7;
    --color-gray-600: #d4d4d8;
    --color-gray-500: #a1a1aa;
    --color-gray-400: #52525b;
    --color-gray-300: #3f3f46;
    --color-gray-200: #27272a;
    --color-gray-100: #18181b;
    --color-background: var(--color-gray-200);

  }

  html {
    --reach-dialog: 1;
    --reach-menu-button: 1;
  }

  body {
    color: var(--color-text);
  }

  html,
  body {
    height: 100%;
  }

  #root {
    isolation: isolate;
  }

  button:focus {
    outline: none;
  }
`;
