import { ISolidMaterial } from "lib/materials";
import { objDataType } from "lib/models/types";
import { PlaneData } from "lib/models/solids/plane";
import { planeParam } from "../../geometries/plane";
import { GraphicProcessor } from "../../graphic-processor";
import { IPoint } from "../../math/types";
import { cadOpType } from "../../operations/factory";
import { createCommand } from "./create-base";

export class PlaneCommand extends createCommand {
  
  public createdData: PlaneData;
  protected opType = cadOpType.PLANE;
  protected objType = objDataType.PLANE;
  protected param: planeParam;
  protected material: ISolidMaterial;

  constructor(
    width: number,
    height: number,
    horizontalDivisions: number,
    verticalDivisions: number,
    basePoint: IPoint,
    rotation: IPoint,
    offset: IPoint,
    scale: IPoint,
    layerId: string,
    graphicProc: GraphicProcessor,
    material: ISolidMaterial
  ) {
    super(layerId, graphicProc);
    this.param = {
      width,
      height,
      horizontalDivisions,
      verticalDivisions,
      basePoint,
      rotation,
      offset,
      scale,
    };
    this.material = material;
  }
}