import { PlaneEditDataCommand } from "lib/commands/edition/plane";

import { linealPrecision } from "lib/general-settings";
import { planeParam } from "lib/geometries/plane";
import { IPoint } from "lib/math/types";
import { PlaneData } from "lib/models/solids/plane";
import { dataInfoProperty } from "../properties";
import { SolidDataDefinitionHandler } from "../solid";
import { ISolidMaterial } from '../../materials/solid';

export type planeProp = planeParam & { centroid: IPoint; }
export type planeView = dataInfoProperty<planeProp>

export class PlaneDataDefinitionHandler extends SolidDataDefinitionHandler<planeProp> {
  protected data: PlaneData;

  protected buildInfoProperties() {
    this.definitionInfo = {};
    const def = this.data.definition;
    this.definitionInfo.width = this.getNumberView(def.width, "Width", "m", linealPrecision, true, (width: number) => width > 0);
    this.definitionInfo.height = this.getNumberView(def.height, "Height", "m", linealPrecision, true, (height: number) => height > 0);
    this.definitionInfo.horizontalDivisions = this.getNumberView(def.horizontalDivisions, "Horizontal Divisions", "", linealPrecision, true, (horizontalDivisions: number) => horizontalDivisions > 0);
    this.definitionInfo.verticalDivisions = this.getNumberView(def.verticalDivisions, "Vertical Divisions", "", linealPrecision, true, (verticalDivisions: number) => verticalDivisions > 0);
    this.fillSolidDefinition(this.data);
  }

  private checkNewDefinition(newDefinition: planeProp): boolean {
    if (!newDefinition) { return false; }
    if (!this.checkSolidNewDefinition(newDefinition)) { return false; }
    if (!this.checkNumber(newDefinition.width)) { return false; }
    if (!this.checkNumber(newDefinition.height)) { return false; }
    if (!this.checkNumber(newDefinition.horizontalDivisions)) { return false; }
    if (!this.checkNumber(newDefinition.verticalDivisions)) { return false; }
    return true;
  }

  private changedNewDefinition(oldDefinition: planeParam, newDefinition: planeProp): planeParam | null {
    let def = oldDefinition;
    let changed: boolean = false;
    if (newDefinition) {
      const solid = this.changedSolidNewDefinition(
        oldDefinition,
        newDefinition
      );
      if (solid !== null) {
        def.basePoint = solid.basePoint;
        def.rotation = solid.rotation;
        def.scale = solid.scale;
        def.offset = solid.offset;
        changed = true;
      }
      const width = this.changedNumber(def.width, newDefinition.width);
      if (width !== null) {
        def.width = width;
        changed = true;
      }
      const height = this.changedNumber(def.height, newDefinition.height);
      if (height !== null) {
        def.height = height;
        changed = true;
      }
      const horizontalDivisions = this.changedNumber(
        def.height,
        newDefinition.horizontalDivisions
      );
      if (horizontalDivisions !== null) {
        def.horizontalDivisions = horizontalDivisions;
        changed = true;
      }
      const verticalDivisions = this.changedNumber(
        def.height,
        newDefinition.verticalDivisions
      );
      if (verticalDivisions !== null) {
        def.verticalDivisions = verticalDivisions;
        changed = true;
      }
    }
    return changed ? def : null;
  }

  private checkAndChangedDefinition(oldDefinition: planeParam, newDefinition: planeProp): planeParam | null {
    let def = null;
    if (this.checkNewDefinition(newDefinition)) {
      def = this.changedNewDefinition(oldDefinition, newDefinition);
    }
    return def;
  }

  public saveAndRegenerate = (newDefinition: planeProp) => {
    const planeDefinition = this.checkAndChangedDefinition(this.data.cloneDefinition(), newDefinition);
    if (planeDefinition) {
      const command = new PlaneEditDataCommand(this.data, planeDefinition, null, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  };
  public saveAndRegenerateMaterial = (newMaterial: ISolidMaterial) => {
    const planeMaterial = this.checkAndChangedSolidMaterial(this.data.material, newMaterial);
    if (planeMaterial) {
      const command = new PlaneEditDataCommand(this.data, null, planeMaterial, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  };
}
