import { IDimBuildProperties } from "lib/dimension/style";
import { currentDimStyle, dimensionCache } from "lib/dimension/cache";
import { dataInfoProperty } from "lib/properties/properties";
import { useState, useCallback, useRef } from "react";
import { DimStyleBuilder } from '../../../../../lib/dimension/style';
import { GraphicProcessor, graphicProcessor000 } from '../../../../../lib/graphic-processor';
import { CircleCommand } from "lib/commands/primitives/circle";
import { PolyLineCommand } from "lib/commands/primitives/polyline";
import { circleParam } from "lib/geometries/circle";
import { ORIENT } from "lib/math/angles";
import { ArcCommand } from "lib/commands/primitives/arc";
import { arcParamFrom2pC } from "lib/geometries/arc";
import { IPolylineParam } from "lib/math/line";
import { alignedDimensionParam } from "lib/models/dimension/aligned-dim";
import { dimDirectionMode, dimOrientationMode, dimPlane } from "lib/operations/dimension/dimension-base";
import { AlignedDimensionCommand } from "lib/commands/dimension/aligned-dim";
import { linealDimensionParam } from "lib/models/dimension/lineal-dim";
import { LinearDimensionCommand } from "lib/commands/dimension/lineal-dim";
import { arcDimensionParam, arcDimType } from "lib/models/dimension/arc-dim";
import { ArcAngleDimensionCommand } from "lib/commands/dimension/arc-dim";
import { circularDimensionParam, circularDimType } from "lib/models/dimension/circular-dim";
import { DiameterDimensionCommand, RadialDimensionCommand } from "lib/commands/dimension/circular-dim";
import { angleDimensionParam } from "lib/models/dimension/angle-dim";
import { AngleDimensionCommand } from "lib/commands/dimension/angle-dim";
import { DimensionData } from "lib/models/dimension/dimension";
import { dimArrowsLabels } from "lib/dimension/arrow";
import { getUserAngleUnitSufix, radAngleToUser, userAngleToRad } from "lib/general-settings";
import { objDataType } from "lib/models/types";
import { textMultiPosTypeNamesH, textMultiPosTypeNamesV } from "lib/text/styles";
import { userMessageEvents } from "lib/events/user-messages";

const getInfoprop = (style: IDimBuildProperties) => {
  const info: dataInfoProperty<IDimBuildProperties> = {
    name: {
      type: "string",
      publicName: "Name",
      value: style.name,
    },
    descripcion: {
      type: "string",
      publicName: "Description",
      value: style.descripcion,
    },
    distBaseLine: {
      type: "number",
      publicName: "Distance to line base",
      value: style.distBaseLine,
      precision: 3,
    },
    extBaseLine: {
      type: "number",
      publicName: "Ext dimension line",
      value: style.extBaseLine,
      precision: 3,
    },
    offset: {
      type: "number",
      publicName: "Ext lines offset origin",
      value: style.offset,
      precision: 3,
    },
    ext: {
      type: "number",
      publicName: "Ext lines offset dim line",
      value: style.ext,
      precision: 3,
    },
    arrowId: {
      publicName: "Arrowheads",
      value: style.arrowId,
      type: "list",
      list: dimArrowsLabels,
    },
    fontOffsetX: {
      type: "number",
      publicName: "Horizontal label offset",
      value: style.fontOffsetX,
      precision: 3,
    },
    fontOffsetY: {
      type: "number",
      publicName: "Vertical label offset",
      value: style.fontOffsetY,
      precision: 3,
    },
    fontRotation: {
      type: "number",
      publicName: "Label rotation",
      value: radAngleToUser(style.fontRotation),
      precision: 4,
      units: getUserAngleUnitSufix(),
      parseFun: userAngleToRad,
    },
    basePointH: {
      publicName: "Label base point X",
      value: style.basePointH,
      type: "list",
      list: textMultiPosTypeNamesH,
    },
    basePointV: {
      publicName: "Label base point Y",
      value: style.basePointV,
      type: "list",
      list: textMultiPosTypeNamesV,
    },
  }
  return info;
};

export function useDimensionManager(graphicProc: GraphicProcessor) {

  const dimObjs = useRef<DimensionData[]>([]);
  const [dimensionLoaded, setDimensionLoaded] = useState<DimStyleBuilder[]>(() => dimensionCache.getAllLoadedStyles());
  const [selectedStyle, setSelectedStyle] = useState<DimStyleBuilder>(dimensionCache.loadStylefromCache(currentDimStyle) as DimStyleBuilder);

  const editStyle = useCallback((style: DimStyleBuilder) => {
    const id = style.styleId === undefined ? selectedStyle.styleId : style.styleId;
    const newStyle = dimensionCache.loadStylefromCache(id, style);
    if (newStyle) {
      const { name, descripcion, ...prop } = style;
      newStyle.name = name;
      newStyle.descripcion = descripcion;
      setSelectedStyle(newStyle);
      setStyleProperties({ ...getInfoprop(newStyle) });

      for (const dimObj of dimObjs.current) {
        dimObj.definition.customStyleProp = prop as Partial<IDimBuildProperties>;
        if (dimObj.type === objDataType.ANGULARDIM) {
          dimObj.definition.customStyleProp.distBaseLine = 15;
        }
        dimObj.regenerateObjectFromDefinition();
      }
    }
  }, [selectedStyle.styleId]);

  const [styleProperties, setStyleProperties] = useState<dataInfoProperty<IDimBuildProperties>>(getInfoprop(selectedStyle));

  const selectStyle = useCallback((id: string) => {
    if (selectedStyle.styleId !== id) {
      const newStyle = dimensionCache.loadStylefromCache(id);
      if (newStyle) {
        setSelectedStyle(newStyle);
        setStyleProperties({ ...getInfoprop(newStyle) });

        for (const dimObj of dimObjs.current) {
          dimObj.definition.styleId = newStyle.styleId;
          dimObj.definition.customStyleProp = {};
          if (dimObj.type === objDataType.ANGULARDIM) {
            dimObj.definition.customStyleProp.distBaseLine = 15;
          }
          dimObj.regenerateObjectFromDefinition();
        }
        if (graphicProc._isMounted) {
          graphicProc.zoomFitV2();
        }
      }
    }
  }, [graphicProc, selectedStyle.styleId]);

  const saveChanges = useCallback(() => {
    for (const dimObj of dimObjs.current) {
      dimObj.definition.customStyleProp = {};
      if (dimObj.type === objDataType.ANGULARDIM) {
        dimObj.definition.customStyleProp.distBaseLine = 15;
      }
    }
    dimensionCache.updateStyle(selectedStyle.styleId, selectedStyle);
    const i = dimensionLoaded.findIndex(s => s.styleId === selectedStyle.styleId);
    if (i !== -1) dimensionLoaded[i] = selectedStyle.clone();
    setDimensionLoaded([...dimensionLoaded]);

  }, [dimensionLoaded, selectedStyle]);

  const deleteStyle = useCallback(async () => {
    const id = selectedStyle.styleId;
    if (dimensionCache.isDimStyleInUse(id, graphicProcessor000)) {
      userMessageEvents.dispatchWarning(`Style "${selectedStyle.name}" is being used`)
      return;
    }
    const deleted = dimensionCache.deleteStyle(id);
    if (deleted) {
      selectStyle(dimensionLoaded[0].styleId);
      const i = dimensionLoaded.findIndex(s => s.styleId === id);
      if (i !== -1) dimensionLoaded.splice(i, 1);
      setDimensionLoaded([...dimensionLoaded]);
    }
  }, [dimensionLoaded, selectStyle, selectedStyle]);

  const createStyle = useCallback(async () => {
    const newStyle = new DimStyleBuilder();
    dimensionCache.saveStyle(newStyle);
    selectStyle(newStyle.styleId);
    dimensionLoaded.push(newStyle);
    setDimensionLoaded([...dimensionLoaded]);

  }, [dimensionLoaded, selectStyle]);

  // --------------------------------------------------------------------

  const iniDimensionObjs = () => {
    const layerManagr = graphicProc.getLayerManager();
    const { id } = layerManagr.getRootLayer();
    const f = 0.5;
    const circle: circleParam = { center: { x: 0, y: 0, z: 0 }, radius: 10 * f, azimutO: 0, plane: { x: 0, y: 0, z: 0 } };
    const arc1 = arcParamFrom2pC({ x: 20 * f, y: 5 * f, z: 0 }, { x: 5 * f, y: 20 * f, z: 0 }, { x: 5 * f, y: 5 * f, z: 0 }, ORIENT.CCW, { x: 0, y: 0, z: 0 });
    const arc2 = arcParamFrom2pC({ x: 5 * f, y: -20 * f, z: 0 }, { x: 20 * f, y: -5 * f, z: 0 }, { x: 5 * f, y: -5 * f, z: 0 }, ORIENT.CCW, { x: 0, y: 0, z: 0 });
    const line1: IPolylineParam = { points: [{ x: 5 * f, y: 20 * f, z: 0 }, { x: -25 * f, y: 20 * f, z: 0 }], isClosed: false, arcs: [] };
    const line2: IPolylineParam = { points: [{ x: -25 * f, y: 20 * f, z: 0 }, { x: -50 * f, y: -20 * f, z: 0 }], isClosed: false, arcs: [] };
    const line3: IPolylineParam = { points: [{ x: -50 * f, y: -20 * f, z: 0 }, { x: 5 * f, y: -20 * f, z: 0 }], isClosed: false, arcs: [] };
    const line4: IPolylineParam = { points: [{ x: 20 * f, y: -5 * f, z: 0 }, { x: 20 * f, y: 5 * f, z: 0 }], isClosed: false, arcs: [] };

    new CircleCommand(circle, id, graphicProc).execute();
    new ArcCommand(arc1, id, graphicProc).execute();
    new ArcCommand(arc2, id, graphicProc).execute();
    new PolyLineCommand(line1, id, graphicProc).execute();
    new PolyLineCommand(line2, id, graphicProc).execute();
    new PolyLineCommand(line3, id, graphicProc).execute();
    new PolyLineCommand(line4, id, graphicProc).execute();

    const currStyle = currentDimStyle;
    const alignedDimParam: alignedDimensionParam = {
      dimPlane: dimPlane.XY,
      dimOrientation: dimOrientationMode.POSITIVE,
      objRef: [{ point: line1.points[1] }, { point: line1.points[0] }],
      styleId: currStyle,
      customStyleProp: {},
    }
    const aligDim = new AlignedDimensionCommand(alignedDimParam, id, graphicProc);
    aligDim.execute();

    const linealDimParam: linealDimensionParam = {
      dimPlane: dimPlane.XY,
      dimDirection: dimDirectionMode.WE,
      dimOrientation: dimOrientationMode.NEGATIVE,
      behindObj: 0,
      objRef: [{ point: line2.points[0] }, { point: line2.points[1] }],
      styleId: currStyle,
      customStyleProp: {},
    }
    const linearDim = new LinearDimensionCommand(linealDimParam, id, graphicProc)
    linearDim.execute();

    const arcDimParam: arcDimensionParam = {
      type: arcDimType.ANG,
      arcBase: arc2,
      InnerArc: true,
      styleId: currStyle,
      customStyleProp: {}, //fontOffsetX: 0 },
    }
    const arcDim = new ArcAngleDimensionCommand(arcDimParam, id, graphicProc)
    arcDim.execute();

    const radiusDimParam: circularDimensionParam = {
      type: circularDimType.RADIUS,
      objBase: arc1,
      dimDirectionAngle: Math.PI * 0.25,
      styleId: currStyle,
      customStyleProp: {},
    }
    const radiusDim = new RadialDimensionCommand(radiusDimParam, id, graphicProc)
    radiusDim.execute();

    const diameterDimParam: circularDimensionParam = {
      type: circularDimType.DIAMETER,
      objBase: circle,
      dimDirectionAngle: -Math.PI * 0.35,
      styleId: currStyle,
      customStyleProp: {},
    }
    const diamDim = new DiameterDimensionCommand(diameterDimParam, id, graphicProc)
    diamDim.execute();

    const angleDimParam: angleDimensionParam = {
      dimObjPlane: { x: 0, y: 0, z: 0 },
      dimDirectionAngle: Math.PI * 0.16,
      objRef: [
        { segment: { p1: line2.points[0], p2: line2.points[1] }, p1Index: 0, p2Index: 1 },
        { segment: { p1: line3.points[0], p2: line3.points[1] }, p1Index: 0, p2Index: 1 }
      ],
      styleId: currStyle,
      customStyleProp: { distBaseLine: 15 },
    }
    const angleDim = new AngleDimensionCommand(angleDimParam, id, graphicProc)
    angleDim.execute();

    graphicProc.zoomFitV2();

    dimObjs.current = [aligDim.createdData, linearDim.createdData, arcDim.createdData, radiusDim.createdData, diamDim.createdData, angleDim.createdData];

  }
  const clearDimensionObjs = () => {
    dimObjs.current.length = 0;
  }
  const setCurrentStyle = () => {
    dimensionCache.setCurrentDimStyle(selectedStyle.styleId);
  }

  return {
    clearDimensionObjs,
    iniDimensionObjs,
    selectStyle,
    saveChanges,
    deleteStyle,
    createStyle,
    setCurrentStyle,
    editStyle,
    selectedStyle,
    dimensionLoaded,
    styleProperties,
  };
}
