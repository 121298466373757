import { PropsWithChildren, useState } from "react";
import styled from "styled-components";
import Menu from "../menu";
import useContextMenu from "./hooks/use-context-menu";

export interface menuCtxSettings {
  label: string;
  callback: () => void;
}

type Props = PropsWithChildren<{
  setting: menuCtxSettings[],
  idMenu: string;
}>;

export const MenuCtx = ({ children, setting, idMenu }: Props) => {

  const [show, setShow] = useState(false);
  const { wrapperMenuRef, menuRef, x, y } = useContextMenu(idMenu, setShow, setting.length > 0);

  return (
    <div className="h-full" id={idMenu} ref={wrapperMenuRef}>
      {show ?
        <Wrapper className="-inset-0 absolute h-screen w-screen" >
          <MenuCtxCont ref={menuRef} className="bg-gray-800 z-20" style={{ top: y, left: x }}>
            <Menu>
              {setting.map(s =>
                < Menu.Item
                  key={s.label}
                  onSelect={() => {
                    setShow(false);
                    s.callback()
                  }}>
                  {s.label}
                </Menu.Item>
              )}
            </Menu>
          </MenuCtxCont>
        </Wrapper>
        : null
      }
      {children}
    </div >
  );
};

const Wrapper = styled.div`
  background: hsl(0deg 0% 0% / 0.3);
  z-index: 9;
`
const MenuCtxCont = styled.div`
  font-size: 14px;
  border-radius: 0.25rem;
  min-width: 125px;
  height: auto;
  position: fixed;
  box-shadow: 0 0 5px 0 #ccc;
  color: var(--color-text-secondary);
`