import * as THREE from "three";
import { getCurrentSolidMaterial, ISolidMaterial, materialCache } from "lib/materials";
import { setPosRotEsc } from "../../geometries";
import { coneParam, createBufferCone, createCone } from "../../geometries/solid/cone";
import { copyIPoint } from "../../math/point";
import { objDataType } from "../types";
import { SolidData } from "./solid";

export class ConeData extends SolidData {

  public type = objDataType.CONE;
  protected nameObj: string = "Cone";
  public definition: coneParam;

  constructor(definition: coneParam, material?: ISolidMaterial) {
    super();
    this.definition = {
      lowerRadius: definition.lowerRadius,
      upperRadius: definition.upperRadius,
      height: definition.height,
      basePoint: copyIPoint(definition.basePoint),
      rotation: copyIPoint(definition.rotation),
      offset: copyIPoint(definition.offset),
      scale: copyIPoint(definition.scale),
    };
    this.material = material ?? getCurrentSolidMaterial();
  }
  static createObj(definition: coneParam, material: ISolidMaterial) {
    const threeMaterial = materialCache.getSolidMaterial(material);
    return createCone(
      definition.upperRadius,
      definition.lowerRadius,
      definition.height,
      threeMaterial,
      definition.basePoint,
      definition.rotation,
      definition.offset,
      definition.scale
    );
  }
  public createGraphicObj() {
    if (this.graphicObj) {
      console.warn("Attention: Cone graphic object already created!!");
      return;
    }
    this.graphicObj = ConeData.createObj(this.definition, this.material);
  }
  public cloneDefinition(): coneParam {
    const def = super.cloneSolidDefinition() as coneParam;
    def.lowerRadius = this.definition.lowerRadius;
    def.upperRadius = this.definition.upperRadius;
    def.height = this.definition.height;
    return def;
  }
  public createObject(definition?: coneParam, material?: ISolidMaterial): THREE.Mesh {
    return ConeData.createObj(definition ?? this.definition, material ?? this.material);
  }
  public regenerateDefinition() {
    const geom = createBufferCone(this.definition.upperRadius, this.definition.lowerRadius, this.definition.height);
    this.graphicObj.geometry = geom;
    const { basePoint, offset, rotation, scale } = this.definition;
    setPosRotEsc(this.graphicObj, basePoint, offset, rotation, scale);
  }
}