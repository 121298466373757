const MoveIcon = ({ ...props }) => {
  return (
    <svg className="h-5 w-5" viewBox="0 0 100 100" fill="none" {...props}>
      <path
        d="M98.3219 46.566L80.9917 33.0743C79.0958 31.5993 76.3635 31.9327 74.8792 33.8368C73.4042 35.7327 73.7417 38.566 75.6458 40.041L82.9625 45.841H54.1667V17.0493L59.9625 24.3702C61.4417 26.2743 64.2333 26.6035 66.125 25.1368C68.0292 23.6577 68.3875 20.9202 66.9083 19.016L53.4292 1.691C52.6083 0.632665 51.3458 0.0159988 49.9958 0.0159988C48.6542 0.0159988 47.3875 0.632665 46.5583 1.691L33.0625 19.0118C31.5875 20.916 31.925 23.6452 33.825 25.1327C34.6167 25.7493 35.5625 26.0493 36.5 26.0493C37.7958 26.0493 39.175 25.4702 40.0333 24.3743L45.8333 17.0493V45.8327H17.0375L24.3583 40.0285C26.2583 38.5535 26.6 35.7743 25.1208 33.866C23.6417 31.9702 20.8958 31.6118 19.0042 33.091L1.67917 46.566C0.616667 47.3952 0 48.6577 0 49.9993C0 51.341 0.616667 52.6035 1.67917 53.4327L19.0042 66.9243C19.8 67.541 20.7417 67.8452 21.6792 67.8452C22.975 67.8452 24.2583 67.266 25.1208 66.1702C26.5958 64.2754 26.2583 61.4337 24.3583 59.9629L17.0375 54.1587H45.8333V82.9587L40.0333 75.6243C38.5542 73.7295 35.7625 73.3952 33.8708 74.866C31.9708 76.3452 31.6083 79.0827 33.0875 80.9785L46.5708 98.3118C47.3958 99.3712 48.6583 99.9868 50.0042 99.9868C51.35 99.9868 52.6083 99.3702 53.4427 98.3118L66.9292 80.9785C68.4042 79.0827 68.0625 76.3493 66.1667 74.866C64.2708 73.391 61.4375 73.7285 59.9594 75.6243L54.1667 82.9587V54.166H82.9594L75.6427 59.9702C73.7385 61.4452 73.3969 64.2327 74.876 66.1327C75.7385 67.24 77.025 67.8327 78.3177 67.8327C79.2552 67.8327 80.1979 67.5493 80.9885 66.9202L98.3219 53.4368C99.3833 52.6035 100 51.3368 100 49.9993C100 48.6535 99.3833 47.391 98.3219 46.566Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MoveIcon;
