import { ConeEditDataCommand } from "lib/commands/edition/cone";

import { linealPrecision } from "lib/general-settings";
import { coneParam } from "lib/geometries/solid/cone";
import { volumeCone } from "lib/geometries/volume";
import { IPoint } from "lib/math/types";
import { ConeData } from "lib/models/solids/cone";
import { dataInfoProperty } from "../properties";
import { SolidDataDefinitionHandler } from "../solid";
import { ISolidMaterial } from '../../materials/solid';

export type coneProp = coneParam & { volume: number; centroid: IPoint; }
export type coneView = dataInfoProperty<coneProp>

export class ConeDataDefinitionHandler extends SolidDataDefinitionHandler<coneProp> {

  protected data: ConeData;

  protected buildInfoProperties() {
    this.definitionInfo = {};
    const def = this.data.definition;
    this.definitionInfo.upperRadius = this.getNumberView(def.upperRadius, "Upper Radius", "m", linealPrecision, undefined, (upperRadius) => upperRadius > 0);
    this.definitionInfo.lowerRadius = this.getNumberView(def.lowerRadius, "Lower Radius", "m", linealPrecision, undefined, (lowerRadius) => lowerRadius > 0);
    this.definitionInfo.height = this.getNumberView(def.height, "Height", "m", linealPrecision, undefined, (height) => height > 0);
    this.fillSolidDefinition(this.data);
    this.definitionInfo.volume = this.getNumberView(volumeCone(def), "Volume", "m³", linealPrecision, false);
  }

  private checkNewDefinition(newDefinition: coneProp): boolean {
    if (!newDefinition) { return false; }
    if (!this.checkSolidNewDefinition(newDefinition)) { return false; }
    if (!this.checkNumber(newDefinition.upperRadius)) { return false; }
    if (!this.checkNumber(newDefinition.lowerRadius)) { return false; }
    if (!this.checkNumber(newDefinition.height)) { return false; }
    return true;
  }

  private changedNewDefinition(oldDefinition: coneParam, newDefinition: coneProp): coneParam | null {
    let def = oldDefinition;
    let changed: boolean = false;
    if (newDefinition) {
      const solid = this.changedSolidNewDefinition(oldDefinition, newDefinition)
      if (solid !== null) {
        def.basePoint = solid.basePoint;
        def.rotation = solid.rotation;
        def.scale = solid.scale;
        def.offset = solid.offset;
        changed = true;
      }
      const upperRadius = this.changedNumber(def.upperRadius, newDefinition.upperRadius);
      if (upperRadius !== null) {
        def.upperRadius = upperRadius;
        changed = true;
      }
      const lowerRadius = this.changedNumber(def.lowerRadius, newDefinition.lowerRadius);
      if (lowerRadius !== null) {
        def.lowerRadius = lowerRadius;
        changed = true;
      }
      const height = this.changedNumber(def.height, newDefinition.height);
      if (height !== null) {
        def.height = height;
        changed = true;
      }
    }
    return (changed ? def : null);
  }

  private checkAndChangedDefinition(oldDefinition: coneParam, newDefinition: coneProp): coneParam | null {
    let def = null;
    if (this.checkNewDefinition(newDefinition)) {
      def = this.changedNewDefinition(oldDefinition, newDefinition);
    }
    return def;
  }

  public saveAndRegenerate = (newDefinition: coneProp) => {
    const coneDefinition = this.checkAndChangedDefinition(this.data.cloneDefinition(), newDefinition);
    if (coneDefinition) {
      const command = new ConeEditDataCommand(this.data, coneDefinition, null, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    };
  }
  public saveAndRegenerateMaterial = (newMaterial: ISolidMaterial) => {
    const coneMaterial = this.checkAndChangedSolidMaterial(this.data.material, newMaterial);
    if (coneMaterial) {
      const command = new ConeEditDataCommand(this.data, null, coneMaterial, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    };
  }
}