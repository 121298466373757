import { isPolygonData, isLineData } from "lib/models/checktools";
import { IObjData } from "lib/models/objdata";
import { angleBetweenLines, lineAngle2p, ORIENT } from "./angles";
import { vectorDist3D } from "./distance";
import { vector3Equals } from "./epsilon";
import { intersect3D } from "./intersections";
import { copyIArcLine, getIsegmentFromIndex, IArcLineParam, IPolylineParam } from "./line";
import { getPolarPoint, getFactorLinePosition2p, copyIPoint } from "./point";
import { polygon } from "./polygon";
import { IPoint, ISegment } from "./types";

export interface IChamferInfo {
  center?: IPoint;
  radius?: number;
  direction?: ORIENT;

  p1: IPoint;
  p2: IPoint;
  /** Si cae dentro o fuera del segmento que se usó para crear el arco/chaflan.
   *
   * @type {number}
   * @memberof IChamferInfo
   */
  p1Factor: number;
  /** Si cae dentro o fuera del segmento que se usó para crear el arco/chaflan.
   *
   * @type {number}
   * @memberof IChamferInfo
   */
  p2Factor: number;
}

export interface IChamferOptions {
  d1: number;
  d2?: number;
  angle?: number;
  angleInput?: boolean;

  radius?: number;

  firstEdgeIndex?: number;
  secondEdgeIndex?: number;
}

export function getChamferInLine(obj: IObjData | undefined, opts: IChamferOptions): IPolylineParam | null {
  if (obj === undefined) { return null; }
  let result: IPolylineParam = {
    isClosed: false,
    points: [],
    arcs: [],
  };
  if (isPolygonData(obj)) {
    const { center, radius, sides, inscribed, angleO, plane } = obj.definition;
    result.points = polygon(center, radius, sides, inscribed, angleO, plane);
    result.isClosed = true;
  } else if (isLineData(obj)) {
    result.isClosed = obj.definition.isClosed;
    result.points = obj.definition.points.map(copyIPoint);
    result.arcs = obj.definition.arcs.map(a => { return a ? copyIArcLine(a) : 0; });
  }

  const segmentA = getIsegmentFromIndex(result, opts.firstEdgeIndex);
  const segmentB = getIsegmentFromIndex(result, opts.secondEdgeIndex);

  let dist2 = opts.d2 as number;
  if (opts.angleInput && opts.angle !== undefined) {
    dist2 = getDistanceFromAngle(segmentA, segmentB, opts.d1, opts.angle);
  }
  const chmfr = chamfer(segmentA, opts.d1, segmentB, dist2);
  if (chmfr) {
    if (chmfr.p1Factor < 0) return null;
    if (chmfr.p1Factor > 1) return null;
    if (chmfr.p2Factor < 0) return null;
    if (chmfr.p2Factor > 1) return null;

    const indxA = opts.firstEdgeIndex as number;
    const indxB = opts.secondEdgeIndex as number;
    if (Math.abs(indxB - indxA) > 1) {
      // Line closed - first vertex Intersection 
      if (indxB === 0) {
        result.points.splice(indxA + 1, 0, chmfr.p1);
        result.points.splice(indxB + 1, 0, chmfr.p2);
      } else {
        result.points.splice(indxB + 1, 0, chmfr.p2);
        result.points.splice(indxA + 1, 0, chmfr.p1);
      }
      result.arcs.push(0);
      result.points.splice(0, 1);
    } else {
      if (indxA < indxB) {
        result.points.splice(indxB + 1, 0, chmfr.p2);
        result.points.splice(indxA + 1, 0, chmfr.p1);
        result.arcs.splice(indxB + 1, 0, 0);
      } else {
        result.points.splice(indxA + 1, 0, chmfr.p1);
        result.points.splice(indxB + 1, 0, chmfr.p2);
        result.arcs.splice(indxA + 1, 0, 0);
      }
      result.points.splice(Math.max(indxA, indxB) + 1, 1);
    }
    return result;
  }
  return null;
}
export function getChamfersBetweenLine(line0: IObjData | undefined, line1: IObjData | undefined, opts: IChamferOptions): IPolylineParam | null {
  // TODO: chamfer between differents lines edges
  // const segmentA = getIsegmentFromIndex(line0.definition, opts.firstEdgeIndex);
  // const segmentB = getIsegmentFromIndex(line1.definition, opts.secondEdgeIndex);
  // let dist2 = opts.d2 as number;
  // if (opts.angleInput && opts.angle !== undefined) {
  //     dist2 = getDistanceFromAngle(segmentA, segmentB, opts.d1, opts.angle);
  // }
  // const chmfr = chamfer(segmentA, opts.d1, segmentB, dist2);
  // if (chmfr) {
  //     if (chmfr.p1Factor < 0) return null;
  //     if (chmfr.p1Factor > 1) return null;
  //     if (chmfr.p2Factor < 0) return null;
  //     if (chmfr.p2Factor > 1) return null;

  //     // const infoA = USRDT.getObjInfo<USRDT.IPolyArcInfo>(lines[0]);
  //     const defA = line0.definition; // infoA.definition ? copyPolyArcDefinition(infoA.definition) : lineExtractAllVertex(lines[0]);
  //     // const infoB = USRDT.getObjInfo<USRDT.IPolyArcInfo>(lines[1]);
  //     const defB = line1.definition; // infoB.definition ? copyPolyArcDefinition(infoB.definition) : lineExtractAllVertex(lines[1]);

  //     if (vector3Equals(segmentA.p2, segmentB.p1)) {
  //         defA.pop();
  //         defA.push(chmfr.p1, chmfr.p2);
  //         defB.shift();

  //     } else if (vector3Equals(segmentA.p2, segmentB.p2)) {
  //         defA.pop();
  //         defA.push(chmfr.p1, chmfr.p2);
  //         defB.pop();
  //         defB.reverse();

  //     } else if (vector3Equals(segmentA.p1, segmentB.p1)) {
  //         defA.reverse();
  //         defA.pop();
  //         defA.push(chmfr.p1, chmfr.p2);
  //         defB.shift();

  //     } else if (vector3Equals(segmentA.p1, segmentB.p2)) {
  //         defA.reverse();
  //         defA.pop();
  //         defA.push(chmfr.p1, chmfr.p2);
  //         defB.pop();
  //         defB.reverse();
  //     }
  //     const definition = defA.concat(defB);

  //     // let isClosed = false;
  //     // const p0 = definition[0] as IPoint;
  //     // const pN = definition[definition.length - 1];
  //     // if (isIPoint(pN) && vector3Equals(p0, pN)) {
  //     //     isClosed = true;
  //     // }
  //     return definition;
  //     // return {
  //     //     containsArcs: definition.some((d) => !isIPoint(d)),
  //     //     isClosed,
  //     //     definition,
  //     // };
  // }
  return null;
}
export function getChamferInPolyLine(obj: IObjData | undefined, opts: IChamferOptions): IPolylineParam | null {
  if (obj === undefined) { return null; }
  let line: IPolylineParam = {
    isClosed: false,
    points: [],
    arcs: [],
  };
  if (isPolygonData(obj)) {
    const { center, radius, sides, inscribed, angleO, plane } = obj.definition;
    line.points = polygon(center, radius, sides, inscribed, angleO, plane);
    line.isClosed = true;
    line.arcs = new Array(sides).fill(0);
  } else if (isLineData(obj)) {
    line.isClosed = obj.definition.isClosed;
    line.points = obj.definition.points.map(copyIPoint);
    line.arcs = obj.definition.arcs.map(a => { return a ? copyIArcLine(a) : 0; });
  }

  // Calculo de los redondeos
  let results: IPolylineParam = {
    isClosed: line.isClosed,
    points: [],
    arcs: [],
  };

  if (!line.isClosed) {
    results.points.push(line.points[0]);
  }
  let lastChamfer: IChamferInfo | null = null;
  for (const { p0, p1, p2, index1, edge0, edge1 } of forEachPolyArcVertex(line)) {

    if (results.points.length > 0) {
      results.arcs.push(edge0);
    }

    // Check if thre is arcs (chamfer not posible)
    const isArc0 = edge0 !== 0;
    const isArc1 = edge1 !== 0;
    if (isArc0 || isArc1) {
      results.points.push(p1);
      continue;
    }

    // --------------------------------------------
    // Calculate chamfer
    let dist2 = opts.d2 as number;
    if (opts.angleInput && opts.angle !== undefined) {
      dist2 = getDistanceFromAngle({ p1: p0, p2: p1 }, { p1, p2 }, opts.d1, opts.angle);
    }
    let res = chamfer({ p1: p0, p2: p1 }, opts.d1, { p1, p2 }, dist2);
    if (res) {
      if (res.p1Factor < 0) { res = null; }
      else if (res.p1Factor > 1) { res = null; }
      else if (res.p2Factor < 0) { res = null; }
      else if (res.p2Factor > 1) { res = null; }
      else if (lastChamfer && lastChamfer.p2Factor > res.p1Factor) { res = null; }
    }
    lastChamfer = res;

    // --------------------------------------------
    // Add Chamfer points
    if (index1 === 0) {
      if (res) {
        results.points.push(res?.p1);
        results.arcs.push(0);
        results.points[0] = res?.p2;
      }
    } else {
      if (res) {
        results.points.push(res?.p1);
        results.arcs.push(0);
        results.points.push(res?.p2);
      } else {
        results.points.push(p1);
        results.arcs.push(line.arcs[index1 - 1]);
      }
    }
  }

  if (!line.isClosed) {
    results.arcs.push(line.arcs[line.arcs.length - 1]);
    results.points.push(line.points[line.points.length - 1]);
  }
  return results;
}
function* forEachPolyArcVertex(param: IPolylineParam) {
  const { points, arcs, isClosed } = param;
  const hasArcs = arcs.length > 0;
  for (let i = 0; i < points.length; i++) {
    const p0 = points[i];
    let p1 = points[i + 1];
    let p2 = points[i + 2];
    let index1 = i + 1
    if (p1 === undefined && isClosed) {
      p1 = points[0];
      p2 = points[1];
      index1 = 0;
    }
    if (p2 === undefined && isClosed) {
      p2 = points[0];
    }
    let edge0: 0 | IArcLineParam = 0
    let edge1: 0 | IArcLineParam = 0;
    if (hasArcs) {
      edge0 = arcs[i];
      edge1 = arcs[i + 1];
    }
    if (p0 && p1 && p2) {
      yield { p0, p1, p2, index1, edge0, edge1 };
    }
  }
}
function getDistanceFromAngle(segmentA: ISegment, segmentB: ISegment, distance1: number, angle: number): number {
  const lineAStart = segmentA.p1;
  const lineAEnd = segmentA.p2;
  const lineBStart = segmentB.p1;
  const lineBEnd = segmentB.p2;
  const betha = angleBetweenLines(lineAStart, lineAEnd, lineBStart, lineBEnd);
  const gamma = Math.PI - betha - angle;
  return distance1 / Math.sin(gamma) * Math.sin(angle);
}
/** Devuelve la nueva arista que forma el chaflán
 *
 * @param {ISegment} segmentA - primera línea
 * @param {number} distA - Distancia deseada de la intersección a la primera linea
 * @param {ISegment} segmentB - segunda línea
 * @param {number} distB - Distancia deseada de la intersección a la segunda linea
 * @returns {[IPoint, IChamferInfo, IPoint]}
 */
function chamfer(segmentA: ISegment, distA: number, segmentB: ISegment, distB: number): IChamferInfo | null {

  const res = prepareSegments(segmentA, segmentB);
  if (res === null) return null;

  const [lineA, intersection, lineB] = res;
  if (distA === 0 || distB === 0) {
    return null;
  } else {
    const angleA = lineAngle2p(lineA.p2, lineA.p1);
    const angleB = lineAngle2p(lineB.p2, lineB.p1);
    const p1 = getPolarPoint(intersection, angleA, distA);
    const p2 = getPolarPoint(intersection, angleB, distB);

    const factorInLineA = getFactorLinePosition2p(segmentA.p1, segmentA.p2, p1) as number;
    const factorInLineB = getFactorLinePosition2p(segmentB.p1, segmentB.p2, p2) as number;

    return {
      p1,
      p1Factor: factorInLineA,
      p2Factor: factorInLineB,
      p2,
    };
  }
}
function prepareSegments(lineA: ISegment, lineB: ISegment): [ISegment, IPoint, ISegment] | null {

  //       i
  //      1 1
  //     /   \
  //   A/     \B
  //   /       \
  //  /         \
  // 0           0

  const lineAStart = lineA.p1;
  const lineAEnd = lineA.p2;
  const lineBStart = lineB.p1;
  const lineBEnd = lineB.p2;

  const intersectRes = intersect3D(lineAStart, lineAEnd, lineBStart, lineBEnd, false);
  if (intersectRes === null) {
    console.log("[GEO.Chaflán] Las líneas de entrada son paralelas.");
    return null;
  } else if (intersectRes.p0 === null) {
    console.log("[GEO.Chaflán] Las líneas de entrada son coincidentes.");
    return null;
  } else if (intersectRes.p1 && !vector3Equals(intersectRes.p0, intersectRes.p1)) {
    console.log("[GEO.Chaflán] Las líneas de entrada se cruzan.");
    return null;
  }
  const intersectPoint = intersectRes.p0;

  let lineAInverted: boolean = false;
  let lineBInverted: boolean = false;

  const distA1i: number = vectorDist3D(lineAStart, intersectPoint);
  const distA2i: number = vectorDist3D(lineAEnd, intersectPoint);
  if (distA1i < distA2i) { lineAInverted = true; }

  const distB1i: number = vectorDist3D(lineBStart, intersectPoint);
  const distB2i: number = vectorDist3D(lineBEnd, intersectPoint);
  if (distB1i < distB2i) { lineBInverted = true; }

  const res = [];
  if (lineAInverted) {
    res[0] = { p1: copyIPoint(lineAEnd), p2: copyIPoint(lineAStart) };
  } else {
    res[0] = { p1: copyIPoint(lineAStart), p2: copyIPoint(lineAEnd) };
  }
  res[1] = intersectPoint;
  if (lineBInverted) {
    res[2] = { p1: copyIPoint(lineBEnd), p2: copyIPoint(lineBStart) };
  } else {
    res[2] = { p1: copyIPoint(lineBStart), p2: copyIPoint(lineBEnd) };
  }
  return res as [ISegment, IPoint, ISegment];
}