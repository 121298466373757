import { useLocation } from "react-router-dom";
import TextSection from "./tabs/text";
import ModelingSection from "./tabs/modeling";
import EditionSection from "./tabs/edition";
import DrawingSection from "./tabs/drawing";
import ImporterSection from "./tabs/importer";
import DimensionSection from "../dimensions/dimension-toolbar";
import PlaneToolbar from "../planes/plane-toolbar";
import StructuralSection from "../../../struc/components/toolbar/structural";

const Toolbar = () => {

  const isStrucModule = (pathname: string) => pathname.includes("struc");
  const { pathname } = useLocation();
  const strucModule = isStrucModule(pathname);

  return (
    <div className="flex flex-wrap divide-x divide-gray-600">
      <DrawingSection />
      <EditionSection />
      <TextSection />
      <DimensionSection />
      <ModelingSection />
      <PlaneToolbar />
      {strucModule && <StructuralSection />}
      <ImporterSection />
    </div>
  );
};

export default Toolbar;
