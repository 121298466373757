import { ObserverManager } from "lib/events/event-bus";
import { MeshAction, MeshActionType } from "lib/events/mesh";
import { MeshGenParams } from "./mesh";

export class MeshDispatcher {

  private meshObserver = new ObserverManager<MeshAction>();

  public subscribe(listener: (action: MeshAction) => void) {
    this.meshObserver.subscribe(listener)
  }
  public unsubscribe(listener: (action: MeshAction) => void) {
    this.meshObserver.unsubscribe(listener)
  }

  public dispatchLoadMeshProp(params: MeshGenParams) {
    this.meshObserver.dispatch({
      type: MeshActionType.LOAD_MESH_PROP,
      payload: { params },
    });
  }
  public dispatchUpdateMeshParams(params: MeshGenParams) {
    this.meshObserver.dispatch({
      type: MeshActionType.UPDATE_MESH_PARAMS,
      payload: { params },
    });
  }
  public dispatchDownloadedMesh() {
    this.meshObserver.dispatch({
      type: MeshActionType.DOWNLOADED_MESH,
      payload: {},
    });
  }
}

export let meshDispatcher: MeshDispatcher;
export function initMeshDispatcher() {
  meshDispatcher = new MeshDispatcher();
  return meshDispatcher;
}