import * as THREE from "three";
import { IPoint } from "./types";

export function scalePoint(point: IPoint, scaleFactorX: number, scaleFactorY: number, scaleFactorZ: number, optRelCenter: IPoint = { x: 0, y: 0, z: 0 }) {
    const p0 = new THREE.Vector3(point.x, point.y, point.z)
    let m = new THREE.Matrix4();
    m.makeTranslation(-optRelCenter.x, -optRelCenter.y, -optRelCenter.z);
    p0.applyMatrix4(m);
    m = new THREE.Matrix4();
    m.scale(new THREE.Vector3(scaleFactorX, scaleFactorY, scaleFactorZ));
    p0.applyMatrix4(m);
    m = new THREE.Matrix4();
    m.makeTranslation(optRelCenter.x, optRelCenter.y, optRelCenter.z);
    p0.applyMatrix4(m);
    return { x: p0.x, y: p0.y, z: p0.z }
}

export function scaleBuffer(buffer: THREE.BufferAttribute, scaleFactorX : number, scaleFactorY : number, scaleFactorZ : number, optAbsCenter: IPoint = { x: 0, y: 0, z: 0 }) {
    let m = new THREE.Matrix4();
    m.makeTranslation(-optAbsCenter.x, -optAbsCenter.y, -optAbsCenter.z);
    buffer.applyMatrix4(m);
    m = new THREE.Matrix4();
    m.scale(new THREE.Vector3(scaleFactorX, scaleFactorY, scaleFactorZ));
    buffer.applyMatrix4(m);
    m = new THREE.Matrix4();
    m.makeTranslation(optAbsCenter.x, optAbsCenter.y, optAbsCenter.z);
    buffer.applyMatrix4(m);
}