import { ICommand } from "lib/commands/base";

export enum UndoRedoActionType {
  UNDO,
  REDO,
  STORE,
  DELETE,
  SET_COMMANDS,
}

export type UndoRedoAction =
  | {
      type: UndoRedoActionType.UNDO;
      payload: {
        cmd: ICommand;
      };
    }
  | {
      type: UndoRedoActionType.REDO;
      payload: {
        cmd: ICommand;
      };
    }
  | {
      type: UndoRedoActionType.DELETE;
      payload: {
        cmd: ICommand;
      };
    }
  | {
      type: UndoRedoActionType.STORE;
      payload: {
        cmd: ICommand;
      };
    }
  | {
      type: UndoRedoActionType.SET_COMMANDS;
      payload: {
        commands: ICommand[];
        currentCommandIndex: number;
      };
    };
