import { getVerticalRectangle } from "lib/geometries/structural/extrusion";
import { addIpoint, copyIPoint, normalizeIpoint, substractIpoint } from "lib/math/point";
import { IPoint, ISegment } from "lib/math/types";
import { LineData } from "lib/models/primitives/line";
import { WallData } from "lib/models/structural/wall";
import { cadOpType } from "../factory";
import { settingOp, settingsOpModes } from "../step-operations";
import { StructBaseOP } from "./structural";
import { WallHoleCommand } from "lib/commands/structural/wall-hole";
import { isWallData } from "lib/models/checktools";
import { userMessageEvents } from "lib/events/user-messages";
import { dataInfoProperty } from "lib/properties/properties";
import { IWallHole } from "lib/models-struc/types/wall";
import { intersectsItself } from "lib/math/intersections";
import { getEdgePolylineFromIndex, getNearPolylineEdgeIndex } from "lib/math/line";
import { rotatePointZ } from "lib/math/rotate";
import { projectPointInPlane } from "lib/math/plane";

export class WallHoleOP extends StructBaseOP {

  public opType = cadOpType.WALLHOLE;

  private rectangleMode = true;

  private wallData: WallData;
  private wallHole: IPoint[] = [];

  private edgeWallNormal: IPoint;
  private edgeWall: number;

  private auxLine: LineData;

  public async start() {
    this.graphicProcessor.unselectAll();
    this.iniSettingsOp();
    this.registerCancel();
    this.registerRaycast()
  }

  protected iniSettingsOp(): void {
    this.settingsOpManager.setCfg([
      {
        infoMsg: "Select wall",
        stepMode: settingsOpModes.SELECTOBJS,
        enableSelectMarks: false,
        multiSelect: false,
        filterFun: isWallData,
        endStepCallback: () => {
          this.unRegisterRaycast();
          this.wallData = this.objDataOrigin[0] as WallData;
          this.objDataOrigin.length = 0;
        }
      }, {
        infoMsg: "Insert rectangle points: ",
        stepMode: settingsOpModes.DEFAULTXYZ,
        cmdLineListener: this.addPointFromExt.bind(this),
        startStepCallback: (step: settingOp) => {
          step.panelProp = this.setPanelProperties();

          this.auxLine = new LineData({ arcs: [], isClosed: true, points: [] });
          this.auxLine.createGraphicObj();
          this.saveToTempScene(this.auxLine.graphicObj);

          this.initializeSnap();
          this.initializeWorkingPlane();
          this.registerInputs();
          this.registerUpdaters();
        },
        endStepCallback: () => {
          if (this.rectangleMode) {
            this.endOperation();
          }
        },
      }, {
        infoMsg: "Insert polyline points: ",
        stepMode: settingsOpModes.DEFAULTXYZ,
        cmdLineListener: this.addPointFromExt.bind(this),
        startStepCallback: (step: settingOp) => {
          step.panelProp = undefined;
          this.wallHole.length = 0;
          this.auxLine.definition = { arcs: [], points: [{ x: 0, y: 0, z: 0 }], isClosed: true };
          this.auxLine.regenerateObjectFromDefinition();
        },
        endStepCallback: () => {
          this.endOperation();
        },
      }
    ]);
  }

  private setPanelProperties() {
    const panelProp: dataInfoProperty<{}> = {
      button1: {
        value: undefined,
        publicName: "Draw polyline",
        type: "button",
        buttonCb: () => {
          this.rectangleMode = false;
          this.setNextStep();
        }
      },
    }
    return { propValue: panelProp };
  }

  public setLastPoint(): void {
    const r = this.graphicProcessor.getRayCastObjects();
    if (r[0] === undefined || r[0].dataObject !== this.wallData) {
      userMessageEvents.dispatchError("Hole must be inside the wall");
      return;
    }

    const pto = substractIpoint(this.lastPoint, this.wallData.definition.basePoint);
    const edgeWall = getNearPolylineEdgeIndex(this.wallData.definition.ptos2D, pto);
    if (r[0].face && this.edgeWall !== undefined && this.edgeWall !== edgeWall) {
      userMessageEvents.dispatchError("Hole must be in the same wall section");
      return;
    }

    if (this.wallHole.length === 0) {
      this.edgeWall = edgeWall;
      const edge = getEdgePolylineFromIndex(this.wallData.definition.ptos2D, this.edgeWall) as ISegment;
      const xVec = normalizeIpoint(substractIpoint(edge.p2, edge.p1));
      this.edgeWallNormal = rotatePointZ(xVec, -Math.PI * 0.5);
      // this.edgeWallNormal = r[0].face?.normal as IPoint;
    }

    if (this.rectangleMode) {
      if (this.wallHole.length === 0) {
        this.wallHole[0] = copyIPoint(this.lastPoint);
        this.auxLine.definition.points.push(copyIPoint(this.lastPoint));
        this.auxLine.regenerateObjectFromDefinition();
      } else {
        this.wallHole[1] = copyIPoint(this.lastPoint);
        this.endOperation();
      }
    } else {
      const holeIntersectItSelf = intersectsItself([this.lastPoint, ...this.wallHole, this.lastPoint]);
      if (holeIntersectItSelf) {
        userMessageEvents.dispatchError("Hole cannot intersect itself");
        return;
      }
      this.wallHole.push(copyIPoint(this.lastPoint));
      this.auxLine.definition.points.push(copyIPoint(this.lastPoint));
      this.auxLine.regenerateObjectFromDefinition();
    }
  }

  public moveLastPoint(pto: IPoint) {
    const ptos = this.auxLine.definition.points;
    if (ptos.length) {
      if (this.rectangleMode && ptos[0]) {
        const rect = getVerticalRectangle(ptos[0], pto);
        this.auxLine.definition.points = rect;
        this.auxLine.regenerateObjectFromDefinition();
      } else {
        const len = ptos.length - 1;
        ptos[len] = pto;
        this.auxLine.regenerateObjectFromDefinition();
      }
    }
  }

  public cancelOperation(): void {
    this.endOperation();
  }

  public save() {
    let wallHoleParam: IWallHole | undefined = undefined;
    if (this.rectangleMode && this.wallHole.length === 2) {
      wallHoleParam = {
        points: getVerticalRectangle(this.wallHole[0], this.wallHole[1]),
      };
    }
    if (!this.rectangleMode && this.wallHole.length > 2) {
      wallHoleParam = {
        points: this.wallHole,
      };
    }

    if (wallHoleParam) {
      // Proyect Hole points to wall strecht definition plane
      const edge = getEdgePolylineFromIndex(this.wallData.definition.ptos2D, this.edgeWall) as ISegment;
      const planePoint = addIpoint(edge.p1, this.wallData.definition.basePoint);
      wallHoleParam.points = wallHoleParam.points.map(p => {
        const pto = projectPointInPlane(p, planePoint, this.edgeWallNormal);
        return substractIpoint(pto, this.wallData.definition.basePoint);
      });
      const command = new WallHoleCommand(this.wallData, wallHoleParam, this.edgeWall, this.graphicProcessor);
      this.graphicProcessor.storeAndExecute(command);
    }
  }
}

