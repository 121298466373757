import * as THREE from "three";
import { dimensionCache } from "lib/dimension/cache";
import { IPoint } from "lib/math/types";
import { IObjData } from '../objdata';
import { objDataType } from "../types";
import { textStyleCache } from "lib/text/cache";
import { arcParam } from "lib/math/arc";
import { createArcDimension, updateArcDimension } from "lib/dimension/arc-dim-builder";
import { dimDependence, DimensionData, dimensionParam } from "./dimension";
import { isArcData, isCircleData, isLineData } from "../checktools";
import { arcLineToArcParam, getIsegmentFromIndex, IArcLineParam } from "lib/math/line";
import { GraphicProcessor } from '../../graphic-processor';

export enum arcDimType { ANG, LONG };

export interface arcDimensionParam extends dimensionParam {
  type: arcDimType;
  arcBase: arcParam;
  InnerArc: boolean;
  dataId?: string | undefined;
  data?: IObjData;
  edgeIndex?: number;
}

export class ArcDimensionData extends DimensionData {

  public type = objDataType.ARCDIM;
  protected nameObj: string = "Arc dimension";
  public definition: arcDimensionParam;

  constructor(definition: arcDimensionParam) {
    super();
    this.definition = {
      styleId: definition.styleId,
      customStyleProp: definition.customStyleProp,
      type: definition.type,
      arcBase: definition.arcBase,
      InnerArc: definition.InnerArc,
      data: definition.data,
      edgeIndex: definition.edgeIndex,
    }
  }
  static createObj(definition: arcDimensionParam) {
    const dimStyle = dimensionCache.loadStylefromCache(definition.styleId, definition.customStyleProp)!;
    const styleText = textStyleCache.loadStylefromCache(dimStyle?.textStyleId)!;
    return createArcDimension(
      definition.type,
      definition.arcBase,
      definition.InnerArc,
      dimStyle,
      styleText
    );
  }
  public createGraphicObj() {
    this.graphicObj = ArcDimensionData.createObj(this.definition);
    this.addObjAsDependence();
  }
  public cloneDefinition(): arcDimensionParam {
    return {
      styleId: this.definition.styleId,
      type: this.definition.type,
      customStyleProp: this.definition.customStyleProp,
      arcBase: this.definition.arcBase,
      InnerArc: this.definition.InnerArc,
      data: this.definition.data,
      edgeIndex: this.definition.edgeIndex,
    }
  }
  public cloneMaterial(): undefined {
    return;
  }
  public createObject(definition?: arcDimensionParam): THREE.Group {
    return ArcDimensionData.createObj(definition ?? this.definition);
  }

  // -------------------------------------------------------------------------

  public override addObjAsDependence() {
    if (this.definition.data) {
      const dep = { data: this };
      this.definition.data.addToMyDependences([dep]);
    }
  }
  public removeObjAsDependence() {
    if (this.definition.data) {
      const dep = { data: this };
      this.definition.data.removeFromMyDependences([dep]);
    }
  }
  public regenerateReferences(graphicProc: GraphicProcessor) {
    if (this.definition.dataId) {
      const modelMngr = graphicProc.getDataModelManager();
      this.definition.data = modelMngr.getData(this.definition.dataId)!;
    }
  }
  public relinkOneDependence(dep: dimDependence) {
    this.definition.data = dep.data;
  }
  public unlinkOneDependence() {
    this.definition.data = undefined;
  }

  // -------------------------------------------------------------------------

  public override regenerateObjectFromDefinition(): void {
    this.updateDependences();
    const dimStyle = dimensionCache.loadStylefromCache(this.definition.styleId, this.definition.customStyleProp)!;
    const styleText = textStyleCache.loadStylefromCache(dimStyle?.textStyleId)!;
    updateArcDimension(this.graphicObj, this.definition, dimStyle, styleText);
  }

  private updateDependences() {
    const data = this.definition.data;
    if (data) {
      if (isLineData(data)) {
        const edge = getIsegmentFromIndex(data.definition, this.definition.edgeIndex);
        const arc = arcLineToArcParam(edge.p1, edge.p2, edge.arc as IArcLineParam);
        this.definition.arcBase = arc;
      } else if (isCircleData(data)) {
        const { center, plane, radius } = data.definition;
        this.definition.arcBase.center = center;
        this.definition.arcBase.plane = plane;
        this.definition.arcBase.radius = radius;
      } else if (isArcData(data)) {
        this.definition.arcBase = data.definition;
      }
    }
  }
  public translate(distance: IPoint): void {
    // Nothing to do
  }
  public rotate(angleX: number, angleY: number, angleZ: number, basePoint: IPoint): void {
    // Nothing to do
  }
  public scale(factorX: number, factorY: number, factorZ: number, basePoint: IPoint): void {
    // Nothing to do
  }
  public mirror(startPoint: IPoint, endPoint: IPoint): void {
    // Nothing to do
  }

  // -------------------------------------------------------------------------

  protected exportDefinitionToJSON(): arcDimensionParam {
    return {
      styleId: this.definition.styleId,
      type: this.definition.type,
      customStyleProp: this.definition.customStyleProp,
      arcBase: this.definition.arcBase,
      InnerArc: this.definition.InnerArc,
      dataId: this.definition.dataId,
      edgeIndex: this.definition.edgeIndex,
    }
  }
}