import { euSteelType, standard, usSteelType } from "modules/struc/models/codes";
import { iProject, iSite } from "./types/struc-project";
import { Building } from "./struc-building";

export class Project implements iProject {

  public id: string;
  public name: string;
  public remarks: string;

  public city: string;
  public country: string;
  public concrete_code: string;
  public concrete_standard: standard;

  public createdAt: string;
  public updatedAt: string;
  public floorNumber: number;
  public footingLevel: number;
  public load_code: string;
  public sites: Site[] = [];
  public steel_type: euSteelType | usSteelType;
  public steel_standard: standard;

  public addSite(site: Site) {
    this.sites.push(site);
  }

  public addSites(sites: Site[]) {
    this.sites.push(...sites);
  }
}

export class Site implements iSite {

  public id: string;
  public name: string;
  public remarks: string;
  public buildings: Building[] = [];

  public addBuilding(building: Building) {
    this.buildings.push(building)
  }

  public addBuildings(buildings: Building[]) {
    this.buildings.push(...buildings)
  }
}