import { useEffect } from "react";
import { useLocation } from "react-router";

const isCadModule = (pathname: string) => pathname.includes("cad");
const isStrucModule = (pathname: string) => pathname.includes("struc");
export function useTheme() {
  const { pathname } = useLocation();
  useEffect(() => {
    if (isCadModule(pathname) || isStrucModule(pathname)) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [pathname]);
}
