import { ObserverManager } from "lib/events/event-bus";
import { StoreyDriftAction, StoreyDriftActionType } from "lib/events/storey-drift";
import { IcombinationItem } from "./storey-drift-repo";

export class StoreyDriftDispatcher {

  private observer = new ObserverManager<StoreyDriftAction>();

  public subscribe(listener: (action: StoreyDriftAction) => void) {
    this.observer.subscribe(listener)
  }
  public unsubscribe(listener: (action: StoreyDriftAction) => void) {
    this.observer.unsubscribe(listener)
  }

  public dispatchAddCombination(id: string, combinations: IcombinationItem) {
    this.observer.dispatch({
      type: StoreyDriftActionType.ADD_COMBINATIONS,
      payload: { id, combinations },
    });
  }
  public dispatchDeleteCombination(id: string) {
    this.observer.dispatch({
      type: StoreyDriftActionType.DELETE_COMB,
      payload: { id },
    });
  }
}