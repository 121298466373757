import { GraphicProcessor } from "lib/graphic-processor";
import { isDimensionData } from "lib/models/checktools";
import { StyleCacheHandler } from "lib/styles/style-cache";
import { currentTextStyleId } from "lib/text/cache";
import { dimArrows } from "./arrow";
import { DimStyleBuilder } from "./style";

export let dimensionCache: DimensionCacheHandler;

export let currentDimStyle: string;

export class DimensionCacheHandler extends StyleCacheHandler<DimStyleBuilder> {

  static initialize() {
    dimensionCache = new DimensionCacheHandler();
    dimensionCache.loadDefaultItems();
  }
  static clear() {
    dimensionCache.clear();
    dimensionCache = undefined!;
  }
  private loadDefaultItems() {
    const defTxtStyleID = currentTextStyleId;
    if (!defTxtStyleID) {
      throw new Error("[INITIALIZE DIMENSION CACHE] Dimension style must be initialize after Text styles");
    }

    const dimStyle1 = new DimStyleBuilder();
    dimStyle1.default = true;
    dimStyle1.styleId = "DimDef00";
    dimStyle1.name = "Standart style";
    dimStyle1.descripcion = "Estilo con distancia fija a línea base";
    dimStyle1.textStyleId = defTxtStyleID;
    dimStyle1.ext = 1.5 * 0.4;
    dimStyle1.offset = 2 * 0.1;
    dimStyle1.distBaseLine = 8 * 0.1;
    dimStyle1.blockDistBaseLine = false;
    dimStyle1.minDistBaseLine1 = 0; //8 * 0.1;
    dimStyle1.minDistBaseLine2 = 0; //5 * 0.1;
    dimStyle1.extBaseLine = 0;
    dimStyle1.fontOffsetY = 1 * 0.4;
    dimStyle1.fontOffsetX = 0;
    dimStyle1.fontRotation = 0;
    dimStyle1.arrowId = dimArrows.ARROW;
    dimensionCache.saveStyle(dimStyle1);
    currentDimStyle = dimStyle1.styleId;

    const dimStyle2 = new DimStyleBuilder();
    dimStyle2.default = true;
    dimStyle2.styleId = "DimDef01";
    dimStyle2.name = "Dim style 1";
    dimStyle2.descripcion = "descripción estilo 1";
    dimStyle2.textStyleId = defTxtStyleID;
    dimStyle2.ext = 2;
    dimStyle2.offset = 3;
    dimStyle2.distBaseLine = 9;
    dimStyle2.blockDistBaseLine = false;
    dimStyle2.minDistBaseLine1 = 0; //8;
    dimStyle2.minDistBaseLine2 = 0; //5;
    dimStyle2.extBaseLine = 3;
    dimStyle2.fontOffsetY = 2;
    dimStyle2.fontOffsetX = 0;
    dimStyle2.fontRotation = 0;
    dimStyle2.arrowId = dimArrows.SLASH;
    dimensionCache.saveStyle(dimStyle2);

    const dimStyle3 = new DimStyleBuilder();
    dimStyle3.default = true;
    dimStyle3.styleId = "DimDef02";
    dimStyle3.name = "Dim style 2";
    dimStyle3.descripcion = "descripción estilo 2";
    dimStyle3.textStyleId = defTxtStyleID;
    dimStyle3.ext = 2;
    dimStyle3.offset = 2;
    dimStyle3.distBaseLine = 9;
    dimStyle3.blockDistBaseLine = false;
    dimStyle3.minDistBaseLine1 = 0; //8;
    dimStyle3.minDistBaseLine2 = 0; //5;
    dimStyle3.extBaseLine = 2;
    dimStyle3.fontOffsetY = 1;
    dimStyle3.fontOffsetX = 0;
    dimStyle3.fontRotation = 0;
    dimStyle3.arrowId = dimArrows.CIRCLE;
    dimensionCache.saveStyle(dimStyle3);

    const dimStyle4 = new DimStyleBuilder();
    dimStyle4.default = true;
    dimStyle4.styleId = "DimDef03";
    dimStyle4.name = "Dim gauge";
    dimStyle4.descripcion = "descripción estilo 3";
    dimStyle4.textStyleId = defTxtStyleID;
    dimStyle4.ext = 1.5;
    dimStyle4.offset = 1;
    dimStyle4.distBaseLine = 5;
    dimStyle4.blockDistBaseLine = false;
    dimStyle4.minDistBaseLine1 = 0; //1;
    dimStyle4.minDistBaseLine2 = 0; //5;
    dimStyle4.extBaseLine = 0;
    dimStyle4.fontOffsetY = 2;
    dimStyle4.fontOffsetX = 0;
    dimStyle4.fontRotation = -Math.PI * 0.5;
    dimStyle4.arrowId = dimArrows.ARROW;
    dimensionCache.saveStyle(dimStyle4);
  }

  load(itemsSaved: Record<string, DimStyleBuilder>) {
    for (const id in itemsSaved) {
      if (Object.prototype.hasOwnProperty.call(itemsSaved, id)) {
        const dimension = new DimStyleBuilder(itemsSaved[id]);
        dimension.styleId = id;
        this.saveStyle(dimension);
      }
    }
  }

  isDimStyleInUse(id: string, graphicProc: GraphicProcessor) {
    const dataModel = graphicProc.getDataModelManager();
    for (const data of dataModel.iterDataByFilter(isDimensionData)) {
      if (data.definition.styleId === id)
        return true;
    }
    return false;
  }

  setCurrentDimStyle(styleId: string) {
    currentDimStyle = styleId;
  }
}
