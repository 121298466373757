import { PolylineEditDataCommand } from "lib/commands/edition/polyline";
import { setPosBuffer } from "lib/geometries";
import { lineAuxCreate } from "lib/geometries/line";
import { IPolylineParam, movePolyArcVertex, getBufferFromPolylineParam } from "lib/math/line";
import { IPoint } from "lib/math/types";
import { LineData } from "lib/models/primitives/line";
import { SimpleEdition } from "../edition";
import { cadOpType } from "../factory";
import { settingsOpModes } from "../step-operations";

export class MovePolyLineVertexOP extends SimpleEdition {

  public opType = cadOpType.EDITPOLYLINEVERTEX;
  protected objData: LineData;
  protected auxDefinition: IPolylineParam;
  protected indexToEdit: number;

  constructor(data: LineData, indexToEdit: number, iniPoint: IPoint) {
    super(iniPoint);
    this.indexToEdit = indexToEdit;
    this.objData = data;
    this.auxDefinition = this.objData.cloneDefinition();
    this.auxPoly = this.objData.createObject(this.auxDefinition);
    this.auxLine = lineAuxCreate();
  }

  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Edit point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
    }]);
  }

  public setLastPoint() {
    this.endOperation();
  }

  public moveLastPoint(pto: IPoint) {
    this.auxDefinition = this.objData.cloneDefinition();
    movePolyArcVertex(this.auxDefinition, pto, this.indexToEdit);
    const buff = getBufferFromPolylineParam(this.auxDefinition);
    setPosBuffer(this.auxPoly, buff);
  }

  public save() {
    const command = new PolylineEditDataCommand(this.objData, this.auxDefinition, null, this.graphicProcessor);
    this.graphicProcessor.storeAndExecute(command);
  }
}
