import * as THREE from "three";
import { getRotationLine, getVertexFromIndex } from "lib/geometries/line";
import { arc, getStartEndPointArc } from "lib/math/arc";
import { IpointsToBuffer } from "lib/math/point";
import { ArcData } from "lib/models/primitives/arc";
import { editArcParameters } from "lib/operations/edition/arc";
import { cadOpType } from "lib/operations/factory";
import { getEdgeMark } from "../selection-tools";
import { SelectionManager } from "../selection-manager";
import { ObjDataSelector, markBulkTypes } from "./selector-data";


export class ArcDataSelector extends ObjDataSelector<ArcData> {

  public override buildSelectMarks() {
    const { center, radius, azimutO, angleCenter, plane } = this.data.definition;

    const [firstPoint, thirdPoint] = getStartEndPointArc(this.data.definition);
    const bulkData: markBulkTypes = {};
    bulkData.origin = [firstPoint];
    bulkData.vertex = [thirdPoint];
    bulkData.center = [center];

    const points = arc(center, radius, azimutO, angleCenter, plane);
    bulkData.line = [IpointsToBuffer(points)];

    const edgeMarks = [];
    const mark = getEdgeMark(points[(points.length - 1) / 2]); // mark in the midpoint
    const rot = getRotationLine(firstPoint, thirdPoint);
    mark.rotation.set(rot.x, rot.y, rot.z);
    edgeMarks.push(mark);
    this.EDGE = edgeMarks;

    return bulkData;
  }

  public override launchMarkOP(selectionManager: SelectionManager, mark: THREE.Object3D, vertexIndex: number) {
    if (selectionManager.originSelector.pointObj === mark) {
      return {
        type: cadOpType.EDITARC,
        graphicProcessor: this.graphicProc,
        args: [this.data, editArcParameters.FIRSTPOINT, getVertexFromIndex(mark as THREE.Points, vertexIndex)],
      };
    }
    if (selectionManager.vertexSelector.pointObj === mark) {
      return {
        type: cadOpType.EDITARC,
        graphicProcessor: this.graphicProc,
        args: [this.data, editArcParameters.THIRDPOINT, getVertexFromIndex(mark as THREE.Points, vertexIndex)],
      };
    }
    if (selectionManager.centerSelector.pointObj === mark) {
      return {
        type: cadOpType.MOVE,
        graphicProcessor: this.graphicProc,
        args: [[this.data], this.data.definition.center],
      };
    }
    if (this.EDGE?.some((e) => e === mark)) {
      return {
        type: cadOpType.EDITARC,
        graphicProcessor: this.graphicProc,
        args: [
          this.data,
          editArcParameters.SECONDPOINT,
          mark.position,
        ],
      };
    }
  }
}
