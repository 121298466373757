import { DynamicInput } from "../steps/dynamic-input";
import styled from "styled-components";
import PixelInfo from "../steps/pixel-info";
import { CADProject } from "modules/cad/models/projects";
import FooterToolbar from "../toolbar/toolbar-viewport";
import Console from "../steps/console";
import { useMainGraphicContext } from "./context";
import { useCoordinatePosition } from "../position/hooks/use-position";
import LoadingDots from "shared/components/ui/loading-dots";
import useViewport from "./hooks/use-viewport";

function cleanFields() {
  const activeElement = document.activeElement as HTMLInputElement | HTMLSelectElement | HTMLButtonElement;
  const tagName = document.activeElement?.tagName;
  if (tagName === "INPUT" || tagName === "SELECT" || tagName === "BUTTON") {
    activeElement.blur();
  }
}
const VieportWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto auto;
`;

const Wrapper = styled.section`
  z-index: 0;
  position: relative;
  overflow: hidden;
`;

const Viewport = ({ project }: { project: CADProject | undefined }) => {

  const graphicProc = useMainGraphicContext();
  const { status, setContainerRef } = useViewport(graphicProc, project);
  const usePosition = useCoordinatePosition(graphicProc);

  return (
    <>
      {status === "loading" &&
        <div className="bg-gray-800 flex justify-center h-full w-full z-50">
          <LoadingDots />
        </div>
      }
      <VieportWrapper>
        <Wrapper id="viewport" ref={setContainerRef} onMouseEnter={cleanFields}>
          {status === "mounted" &&
            <DynamicInput graphicProc={graphicProc} usePosition={usePosition} />
          }
          {status === "mounted" &&
            <PixelInfo viewportRef={graphicProc.container} />
          }
        </Wrapper>

        <Console graphicProc={graphicProc} />
        {status === "mounted" &&
          <FooterToolbar graphicProc={graphicProc} usePosition={usePosition} />
        }
      </VieportWrapper>
    </>
  );
};

export default Viewport;
