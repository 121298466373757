import { AlignedDimensionCommand } from "lib/commands/dimension/aligned-dim";
import { getCustomDimStyleProperties } from "lib/dimension/style";
import { createAlignedDimension, updateAlignedDimension } from "lib/dimension/line-dim-builder";
import { lineAngle2p } from "lib/math/angles";
import { distancePointToLine3D } from "lib/math/distance";
import { vector2Equals } from "lib/math/epsilon";
import { IPlane, intersectLinePlane } from "lib/math/plane";
import { copyIPoint, getMiddlePoint } from "lib/math/point";
import { rotatePointZ, rotatePointX, rotatePointY } from "lib/math/rotate";
import { ISegment, IPoint, copyISegment } from "lib/math/types";
import { alignedDimensionParam } from "lib/models/dimension/aligned-dim";
import { cadOpType } from "../factory";
import { IPointReference, settingsOpModes } from "../step-operations";
import { dimDirectionMode, DimensionOP, dimOrientationMode, dimPlane } from "./dimension-base";
import { lineAuxCreate, lineAddVertex } from "lib/geometries/line";
import { IObjData } from "lib/models/objdata";
import { isDimensionData } from "lib/models/checktools";

export class AlignedDimensionOP extends DimensionOP {

  public opType = cadOpType.ALIGNEDDIM;

  public firstBasePoint: IPointReference;
  public secondBasePoint: IPointReference;
  private dimLine: ISegment;
  private dimOrientation: dimOrientationMode;

  public auxLine: THREE.Line;

  protected iniSettingsOp(): void {
    this.settingsOpManager.setCfg([{
      infoMsg: "Select first point.",
      stepMode: settingsOpModes.SELECTVERTEX,
      filterFun: (obj: IObjData) => {
        if (isDimensionData(obj)) return false;
        return true;
      },
      getVertexCallback: (pto) => {
        this.firstBasePoint = pto;
        this.setNextStep();
      },
      endStepCallback: () => {
        this.dimLine = {
          p1: copyIPoint(this.firstBasePoint.point),
          p2: copyIPoint(this.firstBasePoint.point),
        };
        this.dimPlane = this.getDimPlane();
        this.dimOrientation = dimOrientationMode.POSITIVE;
        const dim = createAlignedDimension(this.dimLine, this.dimStyle, this.dimPlane, this.dimOrientation, this.txtStyle);
        this.saveDimtoTemp(dim);
      },
    }, {
      infoMsg: "Select second point.",
      stepMode: settingsOpModes.SELECTVERTEX,
      filterFun: () => { return true },
      getVertexCallback: (pto) => {
        this.secondBasePoint = pto;
        this.setNextStep();
      },
      endStepCallback: () => {
        this.dimLine.p1 = copyIPoint(this.firstBasePoint.point);
        this.dimLine.p2 = copyIPoint(this.secondBasePoint.point);
        this.inicializeAlignedDimension();
        this.unRegisterRaycast();
      },
    }, {
      infoMsg: "Insert dimension.",
      stepMode: settingsOpModes.WAITMODE,
      startStepCallback: this.registerInputs.bind(this),
      stepFun: this.dispathSaveMouseCoordinates.bind(this),
    }]);
  }

  public async start() {
    await super.start();
    this.auxLine = lineAuxCreate();
    lineAddVertex(this.auxLine, 0, 0, 0);
    lineAddVertex(this.auxLine, 0, 0, 0);
    this.saveToTempScene(this.auxLine);
    this.registerCancel();
    this.registerRaycast();
    this.registerUpdaters();
    this.initializeSnap();
  }
  private inicializeAlignedDimension(): void {
    // if (this.dimStyle.blockDistBaseLine) {
    //     const dimLine = { p1: this.firstBasePoint.point, p2: this.secondBasePoint.point };
    //     this.dimStyle.distBaseLine = calculateBlockDistance(dimLine, this.threeObjOri[0], this.dimStyle, this.dimPlane, this.dimOrientation, this.tre);
    // }
    // updateAlignedDimension(this.dimensionGroup, this.dimLine, this.dimStyle, this.dimPlane, this.dimOrientation, this.txtStyle);
  }
  public setLastPoint(): void {
    this.save();
    this.endOperation();
  }
  public moveLastPoint(point: IPoint) {
    if (this.firstBasePoint && this.secondBasePoint) {
      // Calculo del plano de acotación
      this.dimPlane = this.getDimPlane();

      // Cálculo del punto del raycast en el plano de la acotación
      const v1 = this.dimLine.p1;
      const v2 = this.dimLine.p2;

      if (vector2Equals(v1, v2)) {
        this.resolveVerticalLine(point); // Línea completamente vértical

      } else {
        let currentPlane: IPlane;
        if (this.dimPlane === dimPlane.XY) {
          const XYangle = lineAngle2p(v1, v2);
          const x1: number = v1.x + 50 * Math.cos(XYangle + Math.PI * 0.5);
          const y1: number = v1.y + 50 * Math.sin(XYangle + Math.PI * 0.5);
          currentPlane = { p1: v1, p2: v2, p3: { x: x1, y: y1, z: v1.z } };
        } else {
          currentPlane = { p1: v1, p2: v2, p3: { x: v2.x, y: v2.y, z: v2.z - 50 } };
        }

        const pov: IPoint = this.graphicProcessor.getCursorCamera().position;
        let secondPointProj = intersectLinePlane(pov, point, currentPlane) as IPoint;

        // Calculo de la distancia
        this.dimStyle.distBaseLine = distancePointToLine3D(this.dimLine.p1, this.dimLine.p2, secondPointProj, false);

        const projSegment = copyISegment(this.dimLine);
        // GIRAMOS GEOMETRIA AL PLANO XY (SOLO PLANOS XZ, YZ, DEF)
        if (this.dimPlane !== dimPlane.XY) {
          const centerPoint = getMiddlePoint(projSegment.p1, projSegment.p2);
          const XYangle = lineAngle2p(projSegment.p1, projSegment.p2);
          projSegment.p1 = rotatePointZ(projSegment.p1, -XYangle, centerPoint);
          projSegment.p1 = rotatePointX(projSegment.p1, -(Math.PI * 0.5), centerPoint);
          projSegment.p2 = rotatePointZ(projSegment.p2, -XYangle, centerPoint);
          projSegment.p2 = rotatePointX(projSegment.p2, -(Math.PI * 0.5), centerPoint);
          secondPointProj = rotatePointZ(secondPointProj, -XYangle, centerPoint);
          secondPointProj = rotatePointX(secondPointProj, -(Math.PI * 0.5), centerPoint);
        }
        this.dimOrientation = this.getDimOrientation(dimDirectionMode.DEF, secondPointProj, projSegment);

        if (this.dimStyle.blockDistBaseLine) {
          // const dimLine = { p1: this.firstBasePoint.point, p2: this.secondBasePoint.point };
          // this.dimStyle.distBaseLine = calculateBlockDistance(dimLine, this.threeObjOri[0], this.dimStyle, this.dimPlane, this.dimOrientation, this.tre);
        } else {
          if (this.dimStyle.distBaseLine < this.dimStyle.minDistBaseLine1)
            this.dimStyle.distBaseLine = this.dimStyle.minDistBaseLine1;
        }
        updateAlignedDimension(this.dimensionGroup, this.dimLine, this.dimStyle, this.dimPlane, this.dimOrientation, this.txtStyle);
        console.log("Plano " + dimPlane[this.dimPlane]
          + ", Orientacion: " + dimOrientationMode[this.dimOrientation]
          + ", distancia: " + this.dimStyle.distBaseLine);
      }

    } else if (this.firstBasePoint && !this.secondBasePoint) {
      if (!this.dimStyle.blockDistBaseLine) this.dimStyle.distBaseLine = 0.1;
      this.dimPlane = this.getDimPlane();
      this.dimLine.p2 = point;
      updateAlignedDimension(this.dimensionGroup, this.dimLine, this.dimStyle, this.dimPlane, this.dimOrientation, this.txtStyle);
    }
  }

  private resolveVerticalLine(point: IPoint) {
    let currentPlane: IPlane;
    const v1 = this.dimLine.p1, v2 = this.dimLine.p2;
    const minZpoint = v1.z < v2.z ? v1 : v2;
    if (this.dimPlane === dimPlane.XY || this.dimPlane === dimPlane.XZ) {
      this.dimPlane = dimPlane.XZ;
      currentPlane = { p1: minZpoint, p2: { x: minZpoint.x, y: minZpoint.y, z: minZpoint.z + 50 }, p3: { x: minZpoint.x + 50, y: minZpoint.y, z: minZpoint.z } };
    } else {
      currentPlane = { p1: minZpoint, p2: { x: minZpoint.x, y: minZpoint.y, z: minZpoint.z + 50 }, p3: { x: minZpoint.x, y: minZpoint.y + 50, z: minZpoint.z } };
    }

    const pov: IPoint = this.graphicProcessor.getCursorCamera().position;
    const secondPointProj = intersectLinePlane(pov, point, currentPlane) as IPoint;

    // Calculo de la distancia
    this.dimStyle.distBaseLine = distancePointToLine3D(this.dimLine.p1, this.dimLine.p2, secondPointProj, false);

    // GIRAMOS GEOMETRIA AL PLANO XY para ver dimOrientation (POSITIVO/NEGATIVO)
    const projSegment = { p1: copyIPoint(currentPlane.p1), p2: copyIPoint(currentPlane.p2) };
    const centerPoint = getMiddlePoint(projSegment.p1, projSegment.p2);
    const rotateFunc = this.dimPlane === dimPlane.XZ ? rotatePointX : rotatePointY;
    rotateFunc(projSegment.p1, -(Math.PI * 0.5), centerPoint);
    rotateFunc(projSegment.p2, -(Math.PI * 0.5), centerPoint);
    rotateFunc(secondPointProj, -(Math.PI * 0.5), centerPoint);
    this.dimOrientation = this.getDimOrientation(dimDirectionMode.DEF, secondPointProj, projSegment);

    if (this.dimStyle.blockDistBaseLine) {
      // const dimLine = { p1: this.firstBasePoint.point, p2: this.secondBasePoint.point };
      // this.dimStyle.distBaseLine = calculat eBlockDistance(dimLine, this.threeObjOri[0], this.dimStyle, this.dimPlane, this.dimOrientation, this.tre);
    } else {
      if (this.dimStyle.distBaseLine < this.dimStyle.minDistBaseLine1)
        this.dimStyle.distBaseLine = this.dimStyle.minDistBaseLine1;
    }
    updateAlignedDimension(this.dimensionGroup, this.dimLine, this.dimStyle, this.dimPlane, this.dimOrientation, this.txtStyle);
    console.log("Plano " + dimPlane[this.dimPlane]
      + ", Orientacion: " + dimOrientationMode[this.dimOrientation]
      + ", distancia: " + this.dimStyle.distBaseLine);
  }

  public save() {
    const dimInfo: alignedDimensionParam = {
      styleId: this.dimStyle.styleId,
      dimPlane: this.dimPlane,
      dimOrientation: this.dimOrientation,
      objRef: [this.firstBasePoint, this.secondBasePoint],
      customStyleProp: getCustomDimStyleProperties(this.dimStyle, this.defDimStyle),
    };
    const command = new AlignedDimensionCommand(dimInfo, this.getCurrentSceneId(), this.graphicProcessor);
    if (command) this.graphicProcessor.storeAndExecute(command);
  }
}
