import { FC } from "react";
import styled from "styled-components";
import { useUI } from "shared/components/ui/context";
import Modal from "shared/components/ui/modal";
import Toast from "shared/components/ui/toast";
import ProjectDeleteModal from "../projects/project-delete-modal";
import Header from "./header";

const Wrapper = styled.div`
  min-height: 100vh;
  background: var(--color-background);
`;

const Main = styled.main`
  padding: 8px 0;
  height: 100%;
  max-width: 1300px;
  margin: 0 auto;
  margin-top: 64px;
  isolation: isolate;
`;

const Layout: FC = ({ children }) => {
  const {
    displayToast,
    toastData,
    closeToast,
    modalView,
    displayModal,
    closeModal,
  } = useUI();
  return (
    <Wrapper>
      <Header />
      <Main>{children}</Main>
      <Modal isOpen={displayModal} onClose={closeModal} title={modalView}>
        {modalView === "DELETE_PROJECT" && <ProjectDeleteModal />}
      </Modal>
      {displayToast && toastData && (
        <Toast onClose={closeToast} {...toastData} />
      )}
    </Wrapper>
  );
};

export default Layout;
