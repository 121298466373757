import { getFemResultsBaseUrlFromBuildingModelUrl } from "modules/lite/utils/fem-results";
import { useCallback, useEffect, useState } from "react";
import RenderIf from "shared/components/ui/render-if";
import Table from "shared/components/ui/table";
import { storageService } from "shared/services/storage";
import { formatDate } from "shared/utils/dates";
import DXFViewer from "../dxf/dxf-viewer";

interface Props {
  buildingModelUrl: string | null;
}

const getFilenameFromUrl = (filepath: string) => {
  const split = filepath.split("/");
  return split[split.length - 1];
};

const getLinksFromS3 = (items: any[]) => {
  return items.map((url) => ({
    value: url.key,
    label: getFilenameFromUrl(url.key),
    updatedAt: url.lastModified,
  }));
};

const isDXF = (filepath: string) => filepath.includes(".dxf");

const OptimizerResults = ({ buildingModelUrl }: Props) => {
  const [links, setLinks] = useState<any[]>([]);
  useEffect(() => {
    if (buildingModelUrl) {
      storageService
        .list(
          `${getFemResultsBaseUrlFromBuildingModelUrl(buildingModelUrl)}/optimizer`
        )
        .then(getLinksFromS3)
        .then(setLinks);
    }
  }, [buildingModelUrl]);

  const downloadFile = useCallback(
    async (link: string) => {
      if (buildingModelUrl) {
        const url = await storageService.get(link);
        window.location.href = url;
      }
    },
    [buildingModelUrl]
  );
  return (
    <Table>
      <Table.Header>
        <Table.HeaderCell>File</Table.HeaderCell>
        <Table.HeaderCell>Updated</Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {links.map(({ label, value, updatedAt }, index) => (
          <Table.Row key={index}>
            <Table.Cell>
              <button onClick={() => downloadFile(value)}>{label}</button>
            </Table.Cell>
            <Table.Cell>{formatDate(updatedAt)}</Table.Cell>
            <Table.Cell>
              <RenderIf isTrue={isDXF(value)}>
                <DXFViewer name={label} url={value} />
              </RenderIf>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default OptimizerResults;
