import * as THREE from "three";
import { TextData } from "lib/models/text";
import { cadOpType } from "lib/operations/factory";
import { SelectionManager } from "../selection-manager";
import { ObjDataSelector } from "./selector-data";


export class TextDataSelector extends ObjDataSelector<TextData> {

  public override buildSelectMarks() {
    return { vertex: [this.data.definition.position] };
  }

  public override launchMarkOP(selectionManager: SelectionManager, mark: THREE.Object3D, index?: number) {
    return {
      type: cadOpType.MOVE,
      graphicProcessor: this.graphicProc,
      args: [[this.data], this.data.definition.position],
    };
  }
}
