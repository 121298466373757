import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { splitFile } from "../../../shared/utils/files";

// * RECUERDA: GLTF no usa THREE.Group, sino que nos devuelve THREE.Object3D equivalentes en vez de ellos.
// * ===================================================================================================================
// * (*) ATENCION: Three soporta como elementos agrupadores tanto a THREE.Group como a THREE.Object3D, siendo preferibles
// * los primeros porque DIRECTAMENTE SON NO RENDERIZABLES (el renderer pasa de ellos), mientras que los segundos si que
// * son comprobados. A que viene esto? A que cuando cargamos un GLTF perdemos todos los Group, sustituidos por Object3D.
// * ===================================================================================================================

function saveFile(blob: Blob, dxf_name: string) {
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = URL.createObjectURL(blob);
  link.download = dxf_name;
  link.click();
}

function saveString(text: string, dxf_name: string) {
  saveFile(new Blob([text], { type: "text/plain" }), dxf_name);
}

function saveArrayBuffer(buffer: BlobPart, dxf_name: string) {
  saveFile(new Blob([buffer], { type: "application/octet-stream" }), dxf_name);
}

export function exportGLTF(input: THREE.Object3D) {
  const gltfExporter = new GLTFExporter();

  const options = {
    trs: false,
    onlyVisible: true,
    truncateDrawRange: true,
    binary: false,
    maxTextureSize: Infinity, // To prevent NaN value
  };
  gltfExporter.parse(
    input,
    function (result) {
      if (result instanceof ArrayBuffer) {
        saveArrayBuffer(result, "scene.glb");
      } else {
        const output = JSON.stringify(result, null, 2);
        console.log(output);
        saveString(output, "scene.gltf");
      }
    },
    options
  );
}

export const importGLTF = (
  scene: THREE.Scene,
  file: string,
  onLoad: () => void
) => {
  const { path, dxf_name } = splitFile(file);
  const loader = new GLTFLoader().setPath(path);
  loader.load(dxf_name, (gltf) => {
    scene.add(gltf.scene);
    onLoad();
  });
};

const save = (input: THREE.Object3D, onComplete: (input: any) => void) => {
  const gltfExporter = new GLTFExporter();
  const options = {
    trs: false,
    onlyVisible: true,
    truncateDrawRange: true,
    binary: false,
    maxTextureSize: Infinity,
  };
  gltfExporter.parse(input, onComplete, options);
};

const load = (
  rawScene: Record<string, any>,
  onLoad: (input: any) => void,
  onError?: () => void
) => {
  const data = JSON.stringify(rawScene.content);
  const loader = new GLTFLoader();
  loader.parse(data, "", onLoad, onError);
};

export const GLTFClient = {
  save,
  load,
};
