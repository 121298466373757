import { heightType, storeyMaterialType } from './struc-base';
import { copyIPoint } from "lib/math/point";
import { solidParam } from "lib/models/solids/solid";
import { currentBeamCrossSectionId } from "../cross-sections-shape/beam-cross-sections/cache";

export interface columnParam extends solidParam {
  storeyId: string;
  name: string;
  lnkObjIds: string[];
  materialType: storeyMaterialType;
  
  heightType: heightType;
  height: number;
  
  orientation: number;
  crossSectionId: string;
}

export function cloneColumnParam(param: columnParam): columnParam {
  return {
    name: param.name ?? "",
    lnkObjIds: param.lnkObjIds ? param.lnkObjIds.slice() : [],
    materialType: param.materialType ?? "HA21",
    storeyId: param.storeyId ?? "",
    heightType: param.heightType ?? heightType.STOREY,

    height: param.height,
    orientation: param.orientation ?? 0,
    crossSectionId: param.crossSectionId ?? currentBeamCrossSectionId,

    basePoint: copyIPoint(param.basePoint),
    rotation: copyIPoint(param.rotation),
    offset: copyIPoint(param.offset),
    scale: copyIPoint(param.scale),
  };
}