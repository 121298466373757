import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Project } from "../../../models/project";

interface QueryResult {
  project: Project;
}
const GET_MEASUREMENTS = gql`
  query getMeasurements($projectId: uuid!) {
    project: project_by_pk(id: $projectId) {
      id
      buildingModelUrl
      events(order_by: { created_at: asc }) {
        id
        type
        action
        description
        createdAt: created_at
      }
      measurements {
        id
        result
      }
    }
  }
`;

interface Params {
  id: string;
}

export const useMeasurements = () => {
  const { id: projectId } = useParams<Params>();
  const { data, error, loading } = useQuery<QueryResult>(GET_MEASUREMENTS, {
    variables: { projectId },
  });
  return {
    measurements: data?.project?.measurements?.result,
    error,
    loading,
    projectId,
    buildingModelUrl: data?.project.buildingModelUrl || null,
    events: data?.project.events || [],
  };
};
