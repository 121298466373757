import { getIsegmentFromIndex, getNearPolylineEdgeIndex } from "lib/math/line";
import { isPointInsidePolygon } from "lib/math/polygon";
import { isLinealGeometry, isClosedData, isLineData, isPolygonData, isCircleData } from "lib/models/checktools";
import { IObjData } from "lib/models/objdata";
import * as THREE from "three";
import { OffsetBufferCommand } from "../../commands/edition/offset";
import { lineAddVertex, lineMoveVertex, } from "../../geometries/line";
import { distance2d, vectorDist3D } from "../../math/distance";
import { offsetBuffer } from "../../math/offset-buffer";
import { pointLinePosition, substractIpoint } from "../../math/point";
import { IPoint } from "../../math/types";
import { TransformEdition } from "./transform-edition";
import { cadOpType } from "../factory";
import { settingsOpModes } from "../step-operations";

export class OffsetOP extends TransformEdition {

  public opType = cadOpType.OFFSETCORRIDOR;

  private distance: number = 0;
  private closedGeom: boolean = false;
  private resultLines: THREE.Line[][] = [[], []];

  private round: boolean = false;

  constructor(objsData?: IObjData[], withArcs?: boolean) {
    super(objsData);
    if (withArcs !== undefined) this.round = withArcs;
  }
  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: `Select object`,
      stepMode: settingsOpModes.SELECTOBJS,
      multiSelect: false,
      enableSelectMarks: true,
      filterFun: (o) => {
        const res = isLinealGeometry(o);
        return res;
      },
      endStepCallback: async () => {
        this.unRegisterRaycast();
        this.closedGeom = isClosedData(this.objDataOrigin[0]);
        this.registerInputs();
        this.registerUpdaters();
        this.initializeSnap();
      },
    }, {
      infoMsg: `Specify distance. First point`,
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
      endStepCallback: async () => {
        const { x, y, z } = this.lastPoint;
        lineAddVertex(this.auxLine, x, y, z, 0);
        lineAddVertex(this.auxLine, x, y, z, 1);
      },
    }, {
      infoMsg: `Specify distance. Second point`,
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
      endStepCallback: async () => {
        this.deleteFromTempScene(this.auxLine);
        this.calculateOffset();
        this.closeSnap();
      },
    }, {
      infoMsg: `Select result.`,
      stepMode: settingsOpModes.WAITMODE,
      stepFun: async () => {
        this.save();
        this.endOperation();
      },
    }]);
  }

  public setLastPoint() {
    this.setNextStep();
  }

  public moveLastPoint(point: IPoint) {
    if (this.numPoints === 1) {
      lineMoveVertex(this.auxLine, point.x, point.y, point.z);
      this.distance = vectorDist3D(point, this.lastPoint);
    }
    if (this.numPoints === 2) {
      this.selectResult(point);
    }
  }

  private calculateOffset() {
    const obj = this.objDataOrigin[0];
    const res = offsetBuffer(obj, this.distance);
    for (const l of res) {
      const threeObj = obj.createObject(l);
      this.resultLines[0].push(threeObj as THREE.Line);
    }
    this.resultLines[0].forEach((r) => {
      this.saveToTempScene(r)
    });
    const res2 = offsetBuffer(obj, -this.distance);
    for (const l of res2) {
      const threeObj = obj.createObject(l);
      threeObj.visible = false;
      this.resultLines[1].push(threeObj as THREE.Line);
    }
    this.resultLines[1].forEach((r) => {
      this.saveToTempScene(r)
    });

  }
  private selectResult(point: IPoint) {
    let right = true;
    const obj = this.objDataOrigin[0];
    if (isLineData(obj)) {
      const i = getNearPolylineEdgeIndex(obj.definition, point);
      const edge = getIsegmentFromIndex(obj.definition, i);
      if (edge.arc) {
        const r = vectorDist3D(edge.arc.center, point);
        if (r < edge.arc.radius) this.distance *= -1;
      } else {
        const l = pointLinePosition(point, edge.p1, edge.p2);
        if (l < 0) right = false;
      }

    } else if (isCircleData(obj)) {
      const { center, radius } = obj.definition;
      const r = vectorDist3D(center, point);
      if (r < radius) right = false;

    } else if (isPolygonData(obj)) {
      const inside = isPointInsidePolygon(point, obj.definition);
      if (inside < 1) right = false;
    }
    this.distance = Math.abs(this.distance);
    if (right) this.distance *= -1;

    if (this.resultLines[0]) this.resultLines[0].forEach(o => o.visible = !right);
    if (this.resultLines[1]) this.resultLines[1].forEach(o => o.visible = right);
  }
  private calcResult(point: IPoint) {
    const p = substractIpoint(point, this.lastPoint);
    this.distance = Math.abs(distance2d(0, 0, p.x, p.y));
    if (this.distance > 0) {
      if (this.resultLines[0] && this.resultLines[0].length) {
        for (const l of this.resultLines[0]) {
          this.deleteFromTempScene(l);
        }
      }
      if (this.resultLines[1] && this.resultLines[1].length) {
        for (const l of this.resultLines[1]) {
          this.deleteFromTempScene(l);
        }
      }
      this.resultLines[0].length = 0;
      this.resultLines[1].length = 0;
      const obj = this.objDataOrigin[0];
      const res = offsetBuffer(obj, this.distance);
      for (const l of res) {
        const threeObj = obj.createObject(l);
        this.resultLines[0].push(threeObj as THREE.Line);
      }
      if (this.closedGeom) {
        const res2 = offsetBuffer(obj, -this.distance);
        for (const l of res2) {
          const threeObj = obj.createObject(l);
          this.resultLines[1].push(threeObj as THREE.Line);
        }
      }
      this.resultLines[0].forEach((r) => this.saveToTempScene(r));
      this.resultLines[1].forEach((r) => this.saveToTempScene(r));
    }
  }

  public save() {
    if (this.graphicProcessor) {
      const command = new OffsetBufferCommand(
        this.objDataOrigin[0],
        this.distance,
        this.round,
        this.getCurrentSceneId(),
        this.graphicProcessor
      );
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
}
