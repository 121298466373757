import { AngleDimensionCommand } from "lib/commands/dimension/angle-dim";
import { ArcAngleDimensionCommand } from "lib/commands/dimension/arc-dim";
import { createAngleDimension, getCenter, updateAngleDimension } from "lib/dimension/angular-dim-builder";
import { createArcDimension, updateArcDimension } from "lib/dimension/arc-dim-builder";
import { getCustomDimStyleProperties } from "lib/dimension/style";
import { getRotationSystemFrom3p } from "lib/geometries";
import { circleParam } from "lib/geometries/circle";
import { getVertexFromIndex, lineAddVertex, lineAuxCreate, lineCreateIPoints, lineMoveVertex } from "lib/geometries/line";
import { pointCreate } from "lib/geometries/point";
import { normalizeAngle, lineAzimut2p, getAngleBetweenAzimuts, lineAngle2p } from "lib/math/angles";
import { arcParam } from "lib/math/arc";
import { vectorDist3D } from "lib/math/distance";
import { vector3Equals } from "lib/math/epsilon";
import { copyIPoint } from "lib/math/point";
import { IPoint } from "lib/math/types";
import { isArcData, isCircleData, isLineData, isRadiusBasedGeometry } from "lib/models/checktools";
import { angleDimensionParam } from "lib/models/dimension/angle-dim";
import { arcDimensionParam, arcDimType } from "lib/models/dimension/arc-dim";
import { IObjData } from "lib/models/objdata";
import { cadOpType } from "../factory";
import { IEdgeReference, settingsOpModes } from "../step-operations";
import { DimensionOP } from "./dimension-base";

type angleDimOperation = lineAngleDimensionOP | arcAngleDimensionOP | circleAngleDimensionOP;

export class AngleDimensionOP extends DimensionOP {

  public opType = cadOpType.ANGLEDIM;

  public angleOperation: angleDimOperation;

  protected iniSettingsOp(): void {
    this.settingsOpManager.setCfg([{
      infoMsg: "Select object (line, arc, or circle).",
      stepMode: settingsOpModes.SELECTEDGE,
      filterFun: (obj: IObjData) => {
        if (isRadiusBasedGeometry(obj)) return true;
        if (isLineData(obj)) return true;
        //("El objeto debe ser un arco, un círculo o dos líneas");
        return false;
      },
      getEdgeCallback: (edge) => {
        if (edge) {
          this.angleOperation = new lineAngleDimensionOP(this);
          this.angleOperation.firstBaseSegment = edge;
          const l = lineCreateIPoints([edge.segment.p1, edge.segment.p2]);
          this.saveToTempScene(l);
          this.setNextStep();

        } else if (this.objDataOrigin.length) {
          if (isArcData(this.objDataOrigin[0])) {
            this.angleOperation = new arcAngleDimensionOP(this);
            this.initializeEvents();

          } else if (isCircleData(this.objDataOrigin[0])) {
            this.angleOperation = new circleAngleDimensionOP(this);
            this.settingsOpManager.index = 1;
            this.initializeEvents();
          }
          this.setNextStep();
        }
      },
    }]);
  }
  public async start() {
    await super.start();
    this.registerCancel();
    this.registerRaycast();
  }
  public initializeEvents() {
    this.unRegisterRaycast();
    this.initializeSnap();
    this.registerInputs();
    this.registerUpdaters();
  }
  public setLastPoint(): void {
    this.angleOperation.setLastPoint();
  }
  public moveLastPoint(point: IPoint) {
    this.angleOperation.moveLastPoint(point);
  }
  public save() {
    this.angleOperation.save();
  }
  public endOperation() {
    this.save();
    super.endOperation();
  }
}

interface IAngleOperation {
  op: AngleDimensionOP;
  inicializeAngleDim(): void;
  moveLastPoint(pto: IPoint): void;
  setLastPoint(): void;
  save(): void;
}

class lineAngleDimensionOP implements IAngleOperation {

  public op: AngleDimensionOP;

  // Variables que controlan angulo entre dos líneas
  public firstBaseSegment: IEdgeReference;
  public secondBaseSegment: IEdgeReference;

  // Variable que controla angulo de desarrollo de un arco de circulo
  private arcData: arcParam;

  constructor(arcBaseOP: AngleDimensionOP) {
    this.op = arcBaseOP;
    this.op.settingsOpManager.cfg.push({
      infoMsg: "Select second line.",
      stepMode: settingsOpModes.SELECTEDGE,
      filterFun: (obj: IObjData) => {
        if (isLineData(obj)) return true;
        //("El objeto debe ser una línea");
        return false;
      },
      getEdgeCallback: (edge) => {
        if (edge) {
          const center = getCenter(this.firstBaseSegment.segment, edge.segment);
          if (center === null) {
            // ("Las aristas no se cortan");
          } else {
            this.secondBaseSegment = edge;
            const l = lineCreateIPoints([edge.segment.p1, edge.segment.p2]);
            this.op.saveToTempScene(l);
            this.op.setNextStep();
          }
        }
      },
      endStepCallback: () => {
        this.op.initializeEvents();
        this.inicializeAngleDim();
      },
    }, {
      infoMsg: "Insert dimension.",
      stepMode: settingsOpModes.WAITMODE,
      stepFun: this.op.dispathSaveMouseCoordinates.bind(this.op),
    });
  }
  inicializeAngleDim(): void {
    const edge1 = this.firstBaseSegment.segment;
    const edge2 = this.secondBaseSegment.segment;
    const center = getCenter(edge1, edge2);
    if (center === null) {
      console.warn("Las líneas no se cortan");
      this.op.cancelOperation();
    } else {
      const p1 = vector3Equals(center, edge1.p1) ? edge1.p2 : edge1.p1;
      const p2 = vector3Equals(center, edge2.p1) ? edge2.p2 : edge2.p1;
      this.arcData = {
        center,
        radius: 0,
        azimutO: 0,
        angleCenter: 0,
        plane: getRotationSystemFrom3p(center, p1, p2),
      };

      const planeManager = this.op.graphicProcessor.getPlaneManager();
      planeManager.activePlane.position = this.arcData.center;
      planeManager.activePlane.rotation = this.arcData.plane;
      planeManager.activePlane.locked = true;

      const c = pointCreate(this.arcData.center.x, this.arcData.center.y, this.arcData.center.z);
      this.op.saveToTempScene(c);
      const dim = createAngleDimension([edge1, edge2], 0, this.op.dimStyle, this.op.txtStyle, this.arcData.plane);
      this.op.saveDimtoTemp(dim);
    }
  }
  moveLastPoint(point: IPoint): void {
    if (this.op.numPoints === 0) {
      const planeManager = this.op.graphicProcessor.getPlaneManager();
      const c = planeManager.activePlane.getRelativePoint(this.arcData.center);
      const p = planeManager.activePlane.getRelativePoint(point);

      this.op.dimStyle.distBaseLine = vectorDist3D(p, c);
      const dimInfo: angleDimensionParam = {
        styleId: this.op.dimStyle.styleId,
        customStyleProp: getCustomDimStyleProperties(this.op.dimStyle, this.op.defDimStyle),
        dimObjPlane: this.arcData.plane,
        dimDirectionAngle: normalizeAngle(lineAngle2p(c, p)),
        objRef: [this.firstBaseSegment, this.secondBaseSegment],
      }
      updateAngleDimension(this.op.dimensionGroup, dimInfo, this.op.dimStyle, this.op.txtStyle);
    }
  }
  setLastPoint(): void {
    if (this.op.numPoints === 1) {
      this.op.endOperation();
    }
  }
  save(): void {
    const planeManager = this.op.graphicProcessor.getPlaneManager();
    const c = planeManager.activePlane.getRelativePoint(this.arcData.center);
    const p = planeManager.activePlane.getRelativePoint(this.op.lastPoint);
    const dimInfo: angleDimensionParam = {
      styleId: this.op.dimStyle.styleId,
      customStyleProp: getCustomDimStyleProperties(this.op.dimStyle, this.op.defDimStyle),
      dimObjPlane: this.arcData.plane,
      dimDirectionAngle: normalizeAngle(lineAngle2p(c, p)),
      objRef: [this.firstBaseSegment, this.secondBaseSegment],
    }
    const command = new AngleDimensionCommand(dimInfo, this.op.getCurrentSceneId(), this.op.graphicProcessor);
    if (command) this.op.graphicProcessor.storeAndExecute(command);
  }

}
class arcAngleDimensionOP implements IAngleOperation {

  public op: AngleDimensionOP;

  private arcData: arcParam;

  constructor(arcBaseOP: AngleDimensionOP) {
    this.op = arcBaseOP;
    this.op.settingsOpManager.cfg.push({
      infoMsg: "Insert dimension.",
      stepMode: settingsOpModes.WAITMODE,
      stepFun: this.op.dispathSaveMouseCoordinates.bind(this.op),
    });
    this.inicializeAngleDim();
  }
  public inicializeAngleDim(): void {
    this.arcData = this.op.objDataOrigin[0].definition as arcParam;

    const planeManager = this.op.graphicProcessor.getPlaneManager();
    planeManager.activePlane.position = this.arcData.center;
    planeManager.activePlane.rotation = this.arcData.plane;
    planeManager.activePlane.locked = true;

    const dim = createArcDimension(arcDimType.ANG, this.arcData, true, this.op.dimStyle, this.op.txtStyle);
    this.op.saveDimtoTemp(dim);
  }
  public setLastPoint(): void {
    this.op.endOperation();
  }
  public moveLastPoint(point: IPoint) {
    this.op.dimStyle.distBaseLine = vectorDist3D(point, this.arcData.center) - this.arcData.radius;
    const dimInfo: arcDimensionParam = {
      styleId: this.op.dimStyle.styleId,
      type: arcDimType.ANG,
      customStyleProp: getCustomDimStyleProperties(this.op.dimStyle, this.op.defDimStyle),
      arcBase: this.arcData,
      InnerArc: this.op.dimDirectionIsInArc(this.arcData, point),
      data: this.op.objDataOrigin[0],
    };
    updateArcDimension(this.op.dimensionGroup, dimInfo, this.op.dimStyle, this.op.txtStyle);
  }
  public save(): void {
    const dimInfo: arcDimensionParam = {
      styleId: this.op.dimStyle.styleId,
      type: arcDimType.ANG,
      customStyleProp: getCustomDimStyleProperties(this.op.dimStyle, this.op.defDimStyle),
      arcBase: this.arcData,
      InnerArc: this.op.dimDirectionIsInArc(this.arcData, this.op.lastPoint),
      data: this.op.objDataOrigin[0],
    };
    const command = new ArcAngleDimensionCommand(dimInfo, this.op.getCurrentSceneId(), this.op.graphicProcessor);
    if (command) this.op.graphicProcessor.storeAndExecute(command);
  }
}
class circleAngleDimensionOP implements IAngleOperation {

  public op: AngleDimensionOP;

  private arcData: arcParam;
  private auxLine: THREE.Line;

  constructor(arcBaseOP: AngleDimensionOP) {
    this.op = arcBaseOP;

    this.op.settingsOpManager.cfg.push({
      infoMsg: "Select start of angle.",
      stepMode: settingsOpModes.WAITMODE,
      stepFun: this.op.dispathSaveMouseCoordinates.bind(this.op),
    }, {
      infoMsg: "Select end of angle.",
      stepMode: settingsOpModes.WAITMODE,
      stepFun: this.op.dispathSaveMouseCoordinates.bind(this.op),
    }, {
      infoMsg: "Insert dimension.",
      stepMode: settingsOpModes.WAITMODE,
      stepFun: this.op.dispathSaveMouseCoordinates.bind(this.op),
    });

    const circleData = this.op.objDataOrigin[0].definition as circleParam;
    this.arcData = {
      center: copyIPoint(circleData.center),
      radius: circleData.radius,
      azimutO: 0,
      angleCenter: 0,
      plane: copyIPoint(circleData.plane),
    };
    this.op.lastPoint = this.arcData.center;

    const planeManager = this.op.graphicProcessor.getPlaneManager();
    planeManager.activePlane.position = this.arcData.center;
    planeManager.activePlane.rotation = this.arcData.plane;
    planeManager.activePlane.locked = true;

    // Linea auxiliar fantasma de las dos últimas posiciones
    this.auxLine = lineAuxCreate();
    lineAddVertex(this.auxLine, this.op.lastPoint.x, this.op.lastPoint.y, this.op.lastPoint.z);
    lineAddVertex(this.auxLine, this.op.lastPoint.x, this.op.lastPoint.y, this.op.lastPoint.z);
    this.op.saveToTempScene(this.auxLine);
  }
  public inicializeAngleDim(): void {
    const dim = createArcDimension(arcDimType.ANG, this.arcData, true, this.op.dimStyle, this.op.txtStyle);
    this.op.saveDimtoTemp(dim);
  }
  public moveLastPoint(point: IPoint): void {
    if (this.op.numPoints === 0) {
      lineMoveVertex(this.auxLine as THREE.Line, point.x, point.y, point.z);

    } else if (this.op.numPoints === 1) {

      const planeManager = this.op.graphicProcessor.getPlaneManager();
      const c = planeManager.activePlane.getRelativePoint(this.arcData.center);
      const p = planeManager.activePlane.getRelativePoint(point);

      const azimutEnd = normalizeAngle(lineAzimut2p(c, p));
      this.arcData.angleCenter = getAngleBetweenAzimuts(this.arcData.azimutO, azimutEnd);
      const dimInfo: arcDimensionParam = {
        styleId: this.op.dimStyle.styleId,
        type: arcDimType.ANG,
        customStyleProp: getCustomDimStyleProperties(this.op.dimStyle, this.op.defDimStyle),
        arcBase: this.arcData,
        InnerArc: this.op.dimDirectionIsInArc(this.arcData, point),
        data: this.op.objDataOrigin[0],
      };
      updateArcDimension(this.op.dimensionGroup, dimInfo, this.op.dimStyle, this.op.txtStyle);
    } else {
      this.op.dimStyle.distBaseLine = vectorDist3D(point, this.arcData.center) - this.arcData.radius;
      const dimInfo: arcDimensionParam = {
        styleId: this.op.dimStyle.styleId,
        type: arcDimType.ANG,
        customStyleProp: getCustomDimStyleProperties(this.op.dimStyle, this.op.defDimStyle),
        arcBase: this.arcData,
        InnerArc: this.op.dimDirectionIsInArc(this.arcData, point),
        data: this.op.objDataOrigin[0],
      };
      updateArcDimension(this.op.dimensionGroup, dimInfo, this.op.dimStyle, this.op.txtStyle);

    }
  }
  public setLastPoint(): void {
    if (this.op.numPoints === 1) {
      const p1 = getVertexFromIndex(this.auxLine, 1) as IPoint;
      this.auxLine.visible = false;
      this.arcData.azimutO = normalizeAngle(lineAzimut2p(this.arcData.center, p1));
      this.inicializeAngleDim();
      this.op.setNextStep();

    } else if (this.op.numPoints === 3) {
      this.op.endOperation();
    }
  }
  public save(): void {
    const dimInfo: arcDimensionParam = {
      styleId: this.op.dimStyle.styleId,
      type: arcDimType.ANG,
      customStyleProp: getCustomDimStyleProperties(this.op.dimStyle, this.op.defDimStyle),
      arcBase: this.arcData,
      InnerArc: this.op.dimDirectionIsInArc(this.arcData, this.op.lastPoint),
      data: this.op.objDataOrigin[0],
    };
    const command = new ArcAngleDimensionCommand(dimInfo, this.op.getCurrentSceneId(), this.op.graphicProcessor);
    if (command) this.op.graphicProcessor.storeAndExecute(command);
  }
}