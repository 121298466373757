import { useOperation } from "../../operations/context";
import ToolbarButton from "../toolbar-button";
import ToolbarSection from "../toolbar-section";
import PointIcon from "../../icons/point";
import CircleIcon from "../../icons/circle";
import EllipseIcon from "../../icons/ellipse";
import LineIcon from "../../icons/line";
import PolylineIcon from "../../icons/polyline";
import PolygonIcon from "../../icons/polygon";
import ArcIcon from "../../icons/arc";

const DrawingSection = () => {
  const {
    createPoint,
    createLine,
    createPolyline,
    createCircle,
    createPolygon,
    createArc,
    createEllipse,
    createEllipseArc,
  } = useOperation();
  return (
    <ToolbarSection title="Drawing" className={"border-b border-gray-600"}>
      <ToolbarSection.Row >
        <ToolbarButton onClick={createPoint} tooltip="Point">
          <PointIcon />
        </ToolbarButton>
        <ToolbarButton onClick={createLine} tooltip="Line">
          <LineIcon />
        </ToolbarButton>
        <ToolbarButton onClick={createPolyline} tooltip="Polyline">
          <PolylineIcon />
        </ToolbarButton>
        <ToolbarButton onClick={createCircle} tooltip="Circle">
          <CircleIcon />
        </ToolbarButton>
        <ToolbarButton onClick={createPolygon} tooltip="Polygon">
          <PolygonIcon />
        </ToolbarButton>
        <ToolbarButton onClick={createArc} tooltip="Arc">
          <ArcIcon />
        </ToolbarButton>
        <ToolbarButton onClick={createEllipse} tooltip="Ellipse">
          <EllipseIcon />
        </ToolbarButton>
        <ToolbarButton onClick={createEllipseArc} tooltip="Ellipse Arc">
          <EllipseIcon />
        </ToolbarButton>
      </ToolbarSection.Row>
    </ToolbarSection>
  );
};

export default DrawingSection;
