/* tslint:disable */
/* eslint-disable */
/**
 * GreyStruc App Backend API
 * API for managing GreyStruc project information.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: pablo.campillo@clerhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface DataFileUrl
 */
export interface DataFileUrl {
    /**
     * 
     * @type {string}
     * @memberof DataFileUrl
     */
    'Write': string;
    /**
     * 
     * @type {string}
     * @memberof DataFileUrl
     */
    'Read': string;
}
/**
 * 
 * @export
 * @interface DataFiles
 */
export interface DataFiles {
    /**
     * 
     * @type {DataFileUrl}
     * @memberof DataFiles
     */
    'Content': DataFileUrl;
    /**
     * 
     * @type {DataFileUrl}
     * @memberof DataFiles
     */
    'Planes': DataFileUrl;
    /**
     * 
     * @type {DataFileUrl}
     * @memberof DataFiles
     */
    'CameraConfig': DataFileUrl;
    /**
     * 
     * @type {DataFileUrl}
     * @memberof DataFiles
     */
    'Snapshot': DataFileUrl;
    /**
     * 
     * @type {DataFileUrl}
     * @memberof DataFiles
     */
    'Sites': DataFileUrl;
    /**
     * 
     * @type {DataFileUrl}
     * @memberof DataFiles
     */
    'Hypothesis': DataFileUrl;
    /**
     * 
     * @type {DataFileUrl}
     * @memberof DataFiles
     */
    'Mesh': DataFileUrl;
    /**
     * 
     * @type {DataFileUrl}
     * @memberof DataFiles
     */
    'Analysis': DataFileUrl;
    /**
     * 
     * @type {DataFileUrl}
     * @memberof DataFiles
     */
    'ShellCrossSections': DataFileUrl;
    /**
     * 
     * @type {DataFileUrl}
     * @memberof DataFiles
     */
    'BeamCrossSections': DataFileUrl;
    /**
     * 
     * @type {DataFileUrl}
     * @memberof DataFiles
     */
    'PileCapTemplates': DataFileUrl;
    /**
     * 
     * @type {DataFileUrl}
     * @memberof DataFiles
     */
    'DimensionStyles': DataFileUrl;
    /**
     * 
     * @type {DataFileUrl}
     * @memberof DataFiles
     */
    'LineStyles': DataFileUrl;
    /**
     * 
     * @type {DataFileUrl}
     * @memberof DataFiles
     */
    'TextStyles': DataFileUrl;
}
/**
 * 
 * @export
 * @interface MutableProjectInfo
 */
export interface MutableProjectInfo {
    /**
     * 
     * @type {string}
     * @memberof MutableProjectInfo
     */
    'DataModelVersion': string;
    /**
     * 
     * @type {string}
     * @memberof MutableProjectInfo
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof MutableProjectInfo
     */
    'Remarks': string;
    /**
     * 
     * @type {string}
     * @memberof MutableProjectInfo
     */
    'Country': string;
    /**
     * 
     * @type {string}
     * @memberof MutableProjectInfo
     */
    'City': string;
    /**
     * 
     * @type {ProjectCodes}
     * @memberof MutableProjectInfo
     */
    'Codes': ProjectCodes;
}
/**
 * 
 * @export
 * @interface NewProjectInfo
 */
export interface NewProjectInfo {
    /**
     * 
     * @type {string}
     * @memberof NewProjectInfo
     */
    'DataModelVersion': string;
    /**
     * 
     * @type {string}
     * @memberof NewProjectInfo
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof NewProjectInfo
     */
    'Remarks': string;
    /**
     * 
     * @type {string}
     * @memberof NewProjectInfo
     */
    'Country': string;
    /**
     * 
     * @type {string}
     * @memberof NewProjectInfo
     */
    'City': string;
    /**
     * 
     * @type {ProjectCodes}
     * @memberof NewProjectInfo
     */
    'Codes': ProjectCodes;
    /**
     * 
     * @type {string}
     * @memberof NewProjectInfo
     */
    'UserId': string;
    /**
     * 
     * @type {number}
     * @memberof NewProjectInfo
     */
    'FootingLevel': number;
}
/**
 * 
 * @export
 * @interface NewProjectInfoAllOf
 */
export interface NewProjectInfoAllOf {
    /**
     * 
     * @type {string}
     * @memberof NewProjectInfoAllOf
     */
    'UserId': string;
    /**
     * 
     * @type {number}
     * @memberof NewProjectInfoAllOf
     */
    'FootingLevel': number;
}
/**
 * 
 * @export
 * @interface PaginationResponse
 */
export interface PaginationResponse {
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'Count': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'ScannedCount': number;
    /**
     * 
     * @type {Array<object>}
     * @memberof PaginationResponse
     */
    'Items': Array<object>;
    /**
     * 
     * @type {object}
     * @memberof PaginationResponse
     */
    'LastEvaluatedKey'?: object;
}
/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'DataModelVersion': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'Remarks': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'Country': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'City': string;
    /**
     * 
     * @type {ProjectCodes}
     * @memberof Project
     */
    'Codes': ProjectCodes;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'UserId': string;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    'FootingLevel': number;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'Id': string;
    /**
     * 
     * @type {DataFiles}
     * @memberof Project
     */
    'DataFiles'?: DataFiles;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'SnapshotRead'?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'CreatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'UpdatedAt': string;
}
/**
 * 
 * @export
 * @interface ProjectAllOf
 */
export interface ProjectAllOf {
    /**
     * 
     * @type {string}
     * @memberof ProjectAllOf
     */
    'Id': string;
    /**
     * 
     * @type {DataFiles}
     * @memberof ProjectAllOf
     */
    'DataFiles'?: DataFiles;
    /**
     * 
     * @type {string}
     * @memberof ProjectAllOf
     */
    'SnapshotRead'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAllOf
     */
    'CreatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAllOf
     */
    'UpdatedAt': string;
}
/**
 * 
 * @export
 * @interface ProjectCodes
 */
export interface ProjectCodes {
    /**
     * 
     * @type {string}
     * @memberof ProjectCodes
     */
    'ConcreteStandard': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCodes
     */
    'Concrete': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCodes
     */
    'Load': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCodes
     */
    'SteelStandard': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCodes
     */
    'Steel': string;
}

/**
 * ProjectFilesApi - axios parameter creator
 * @export
 */
export const ProjectFilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a project given its Id.
         * @summary Returns a project given its Id.
         * @param {string} userId Id of the user who created the project
         * @param {string} id Id of the GreyStruc Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsUserIdIdRefreshGet: async (userId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('projectsUserIdIdRefreshGet', 'userId', userId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsUserIdIdRefreshGet', 'id', id)
            const localVarPath = `/projects/{UserId}/{Id}/refresh`
                .replace(`{${"UserId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * It must be call after a new file is uploaded.
         * @summary Updates just the UpdatedAt field.
         * @param {string} userId Id of the user who created the project
         * @param {string} id Id of the GreyStruc Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsUserIdIdUpdatePut: async (userId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('projectsUserIdIdUpdatePut', 'userId', userId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsUserIdIdUpdatePut', 'id', id)
            const localVarPath = `/projects/{UserId}/{Id}/update`
                .replace(`{${"UserId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectFilesApi - functional programming interface
 * @export
 */
export const ProjectFilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectFilesApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a project given its Id.
         * @summary Returns a project given its Id.
         * @param {string} userId Id of the user who created the project
         * @param {string} id Id of the GreyStruc Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsUserIdIdRefreshGet(userId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataFiles>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsUserIdIdRefreshGet(userId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * It must be call after a new file is uploaded.
         * @summary Updates just the UpdatedAt field.
         * @param {string} userId Id of the user who created the project
         * @param {string} id Id of the GreyStruc Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsUserIdIdUpdatePut(userId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsUserIdIdUpdatePut(userId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectFilesApi - factory interface
 * @export
 */
export const ProjectFilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectFilesApiFp(configuration)
    return {
        /**
         * Returns a project given its Id.
         * @summary Returns a project given its Id.
         * @param {string} userId Id of the user who created the project
         * @param {string} id Id of the GreyStruc Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsUserIdIdRefreshGet(userId: string, id: string, options?: any): AxiosPromise<DataFiles> {
            return localVarFp.projectsUserIdIdRefreshGet(userId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * It must be call after a new file is uploaded.
         * @summary Updates just the UpdatedAt field.
         * @param {string} userId Id of the user who created the project
         * @param {string} id Id of the GreyStruc Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsUserIdIdUpdatePut(userId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.projectsUserIdIdUpdatePut(userId, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectFilesApi - object-oriented interface
 * @export
 * @class ProjectFilesApi
 * @extends {BaseAPI}
 */
export class ProjectFilesApi extends BaseAPI {
    /**
     * Returns a project given its Id.
     * @summary Returns a project given its Id.
     * @param {string} userId Id of the user who created the project
     * @param {string} id Id of the GreyStruc Project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectFilesApi
     */
    public projectsUserIdIdRefreshGet(userId: string, id: string, options?: AxiosRequestConfig) {
        return ProjectFilesApiFp(this.configuration).projectsUserIdIdRefreshGet(userId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * It must be call after a new file is uploaded.
     * @summary Updates just the UpdatedAt field.
     * @param {string} userId Id of the user who created the project
     * @param {string} id Id of the GreyStruc Project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectFilesApi
     */
    public projectsUserIdIdUpdatePut(userId: string, id: string, options?: AxiosRequestConfig) {
        return ProjectFilesApiFp(this.configuration).projectsUserIdIdUpdatePut(userId, id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectsApi - axios parameter creator
 * @export
 */
export const ProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns all projects of a user.
         * @summary Returns all projects of a user.
         * @param {string} userId Id of the user whose projects will be returned.
         * @param {string} [exclusiveStartKey] Id of the last project returned (for pagination).
         * @param {number} [limit] Number of proyects returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsGet: async (userId: string, exclusiveStartKey?: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('projectsGet', 'userId', userId)
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['UserId'] = userId;
            }

            if (exclusiveStartKey !== undefined) {
                localVarQueryParameter['ExclusiveStartKey'] = exclusiveStartKey;
            }

            if (limit !== undefined) {
                localVarQueryParameter['Limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new standard code.
         * @summary Creates a new standard code.
         * @param {NewProjectInfo} newProjectInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsPost: async (newProjectInfo: NewProjectInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newProjectInfo' is not null or undefined
            assertParamExists('projectsPost', 'newProjectInfo', newProjectInfo)
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newProjectInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a project given its Id.
         * @summary Returns a project given its Id.
         * @param {string} userId Id of the user who created the project
         * @param {string} id Id of the GreyStruc Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsUserIdIdDelete: async (userId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('projectsUserIdIdDelete', 'userId', userId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsUserIdIdDelete', 'id', id)
            const localVarPath = `/projects/{UserId}/{Id}`
                .replace(`{${"UserId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a project given its Id.
         * @summary Returns a project given its Id.
         * @param {string} userId Id of the user who created the project
         * @param {string} id Id of the GreyStruc Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsUserIdIdGet: async (userId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('projectsUserIdIdGet', 'userId', userId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsUserIdIdGet', 'id', id)
            const localVarPath = `/projects/{UserId}/{Id}`
                .replace(`{${"UserId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a project.
         * @summary Updates a project.
         * @param {string} userId Id of the user who created the project
         * @param {string} id Id of the GreyStruc Project
         * @param {MutableProjectInfo} mutableProjectInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsUserIdIdPut: async (userId: string, id: string, mutableProjectInfo: MutableProjectInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('projectsUserIdIdPut', 'userId', userId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsUserIdIdPut', 'id', id)
            // verify required parameter 'mutableProjectInfo' is not null or undefined
            assertParamExists('projectsUserIdIdPut', 'mutableProjectInfo', mutableProjectInfo)
            const localVarPath = `/projects/{UserId}/{Id}`
                .replace(`{${"UserId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mutableProjectInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns all projects of a user.
         * @summary Returns all projects of a user.
         * @param {string} userId Id of the user whose projects will be returned.
         * @param {string} [exclusiveStartKey] Id of the last project returned (for pagination).
         * @param {number} [limit] Number of proyects returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsGet(userId: string, exclusiveStartKey?: string, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsGet(userId, exclusiveStartKey, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new standard code.
         * @summary Creates a new standard code.
         * @param {NewProjectInfo} newProjectInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsPost(newProjectInfo: NewProjectInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewProjectInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsPost(newProjectInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a project given its Id.
         * @summary Returns a project given its Id.
         * @param {string} userId Id of the user who created the project
         * @param {string} id Id of the GreyStruc Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsUserIdIdDelete(userId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsUserIdIdDelete(userId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a project given its Id.
         * @summary Returns a project given its Id.
         * @param {string} userId Id of the user who created the project
         * @param {string} id Id of the GreyStruc Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsUserIdIdGet(userId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsUserIdIdGet(userId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates a project.
         * @summary Updates a project.
         * @param {string} userId Id of the user who created the project
         * @param {string} id Id of the GreyStruc Project
         * @param {MutableProjectInfo} mutableProjectInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsUserIdIdPut(userId: string, id: string, mutableProjectInfo: MutableProjectInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsUserIdIdPut(userId, id, mutableProjectInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectsApiFp(configuration)
    return {
        /**
         * Returns all projects of a user.
         * @summary Returns all projects of a user.
         * @param {string} userId Id of the user whose projects will be returned.
         * @param {string} [exclusiveStartKey] Id of the last project returned (for pagination).
         * @param {number} [limit] Number of proyects returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsGet(userId: string, exclusiveStartKey?: string, limit?: number, options?: any): AxiosPromise<PaginationResponse> {
            return localVarFp.projectsGet(userId, exclusiveStartKey, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new standard code.
         * @summary Creates a new standard code.
         * @param {NewProjectInfo} newProjectInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsPost(newProjectInfo: NewProjectInfo, options?: any): AxiosPromise<NewProjectInfo> {
            return localVarFp.projectsPost(newProjectInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a project given its Id.
         * @summary Returns a project given its Id.
         * @param {string} userId Id of the user who created the project
         * @param {string} id Id of the GreyStruc Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsUserIdIdDelete(userId: string, id: string, options?: any): AxiosPromise<Project> {
            return localVarFp.projectsUserIdIdDelete(userId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a project given its Id.
         * @summary Returns a project given its Id.
         * @param {string} userId Id of the user who created the project
         * @param {string} id Id of the GreyStruc Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsUserIdIdGet(userId: string, id: string, options?: any): AxiosPromise<Project> {
            return localVarFp.projectsUserIdIdGet(userId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a project.
         * @summary Updates a project.
         * @param {string} userId Id of the user who created the project
         * @param {string} id Id of the GreyStruc Project
         * @param {MutableProjectInfo} mutableProjectInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsUserIdIdPut(userId: string, id: string, mutableProjectInfo: MutableProjectInfo, options?: any): AxiosPromise<Project> {
            return localVarFp.projectsUserIdIdPut(userId, id, mutableProjectInfo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
    /**
     * Returns all projects of a user.
     * @summary Returns all projects of a user.
     * @param {string} userId Id of the user whose projects will be returned.
     * @param {string} [exclusiveStartKey] Id of the last project returned (for pagination).
     * @param {number} [limit] Number of proyects returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsGet(userId: string, exclusiveStartKey?: string, limit?: number, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).projectsGet(userId, exclusiveStartKey, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new standard code.
     * @summary Creates a new standard code.
     * @param {NewProjectInfo} newProjectInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsPost(newProjectInfo: NewProjectInfo, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).projectsPost(newProjectInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a project given its Id.
     * @summary Returns a project given its Id.
     * @param {string} userId Id of the user who created the project
     * @param {string} id Id of the GreyStruc Project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsUserIdIdDelete(userId: string, id: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).projectsUserIdIdDelete(userId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a project given its Id.
     * @summary Returns a project given its Id.
     * @param {string} userId Id of the user who created the project
     * @param {string} id Id of the GreyStruc Project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsUserIdIdGet(userId: string, id: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).projectsUserIdIdGet(userId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a project.
     * @summary Updates a project.
     * @param {string} userId Id of the user who created the project
     * @param {string} id Id of the GreyStruc Project
     * @param {MutableProjectInfo} mutableProjectInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsUserIdIdPut(userId: string, id: string, mutableProjectInfo: MutableProjectInfo, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).projectsUserIdIdPut(userId, id, mutableProjectInfo, options).then((request) => request(this.axios, this.basePath));
    }
}


