import cn from "classnames";
import { forwardRef, InputHTMLAttributes, ReactNode } from "react";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label?: string;
  error?: string;
  srOnly?: boolean;
  adornment?: ReactNode;
}

const PropertiesInput = forwardRef<HTMLInputElement, Props>((props, inputRef) => {
  const {
    className,
    children,
    id,
    label,
    type,
    error,
    srOnly,
    adornment,
    ...rest
  } = props;

  const inputRootClassName = cn(
    "block w-full px-1 py-0 rounded",
    "bg-gray-700 text-gray-100 hover:bg-gray-600 focus:bg-gray-600",
    "text-xs leading-5 disabled:text-gray-400 truncate",
    error
      ? "border-red-500 focus:outline-none focus:ring-red-500 focus:border-red-500"
      : "border-gray-600 focus:ring-indigo-500 focus:border-indigo-500",
    className
  );

  return (
    <div className={srOnly ? "w-full" : "flex gap-2 items-baseline w-full"}>
      <label
        htmlFor={id}
        className={srOnly ? "sr-only" : "text-xs text-gray-400 truncate text-right flex-1"}
      >
        {label}
      </label>
      <div className={`relative flex items-center ${srOnly ? "w-full" : "flex-1"}`}>
        <input
          id={id}
          ref={inputRef}
          className={inputRootClassName}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          type={type || "text"}
          {...rest}
        />
        {adornment && (
          <div className="absolute right-1 z-10 text-xs">{adornment}</div>
        )}
      </div>
    </div>
  );
});

PropertiesInput.displayName = "PropertiesInput";

export default PropertiesInput;
