/** Encapsulador de colección de elementos únicos
 *  NOTE: Ha pasado de usar un array a usar un Set (con sus ventaja de rendimiento). Se mantiene la clase por no romper la compatibilidad con funciones de Array
 *
 * @export
 * @class UniqueIArray
 * @template T
 */
export class UniqueIArray<T> {
  protected elemSet = new Set<T>();

  public push(elem: T): void {
    this.elemSet.add(elem);
  }

  public remove(elem: T): void {
    this.elemSet.delete(elem);
  }

  public find(cb: (e: T) => boolean): T | undefined {
    for (const elem of this.elemSet) {
      if (cb(elem)) return elem;
    }
    return undefined;
  }

  public length() {
    return this.elemSet.size;
  }

  public clear() {
    this.elemSet.clear();
  }

  public *[Symbol.iterator]() {
    for (const elem of this.elemSet) {
      yield elem;
    }
  }

  public map<K>(mapFun: (val: T) => K) {
    const retArr: K[] = [];
    for (const val of this.elemSet) {
      retArr.push(mapFun(val));
    }
    return retArr;
  }

  public filter(filterFun: (val: T) => boolean) {
    const retArr: T[] = [];
    for (const val of this.elemSet) {
      if (filterFun(val)) {
        retArr.push(val);
      }
    }
    return retArr;
  }

  public slice() {
    return Array.from(this.elemSet);
  }
}

export class UniqueFixedIArray<T> extends UniqueIArray<T> {
  // LIFO

  constructor(protected maxElemSize: number) {
    super();
  }

  private first(): T | undefined {
    for (const elem of this.elemSet) return elem;
    return undefined;
  }

  public last(): T {
    return this.slice()[this.length() - 1];
  }

  public push(elem: T): void {
    super.push(elem);
    if (this.elemSet.size > this.maxElemSize) {
      const first = this.first();
      if (typeof first !== "undefined") {
        this.elemSet.delete(first);
      }
    }
  }
}

abstract class IArray<T> {
  public elems: T[];

  constructor() {
    this.elems = [];
  }
  public push(val: T): void {
    this.elems.push(val);
  }
  public pop(): T | undefined {
    return this.elems.pop();
  }
  public get(index: number): T | undefined {
    if (index < this.elems.length) {
      return this.elems[index];
    } else {
      return undefined;
    }
  }
  public getLast(): T | undefined {
    const index = this.elems.length - 1;
    return this.get(index);
  }
  public length(): number {
    return this.elems.length;
  }
  public clear(): void {
    this.elems.length = 0;
  }
}

export class FixedStack<T> extends IArray<T> {
  // FIFO
  public size: number;

  constructor(size: number) {
    super();
    this.size = size;
  }

  public push(elem: T): T[] {
    let deleted: T[] = [];
    if (this.elems.length >= this.size) {
      // Eliminar el elemento en la posicion 0
      deleted = this.elems.splice(0, 1);
    }
    super.push(elem);
    return deleted;
  }

  public getAll(): T[] {
    return this.elems;
  }

  public getReverse(): T[] {
    return this.elems.slice().reverse();
  }
}

export class CmdStack<T> {

  protected commands: T[];
  protected currCmdIndex: number;

  constructor() {
    this.commands = [];
    this.currCmdIndex = 0;
  }

  public store(cmd: T): void {
    this.commands[this.currCmdIndex] = cmd;
    this.currCmdIndex++;
    this.commands.length = this.currCmdIndex;
  }

  public insert(cmd: T, pos: number): void {
    this.commands.splice(pos, 0, cmd);
    if (this.currCmdIndex >= pos) this.currCmdIndex++;
  }

  public delete(cmd: T): void {
    const index = this.commands.indexOf(cmd);
    if (index > -1) {
      this.commands.splice(index, 1);
      this.currCmdIndex = this.commands.length;
    }
  }

  public clear(): void {
    this.commands.length = 0;
    this.currCmdIndex = 0;
  }
}


/** Maneja un array de funciones para subscripciones
 *
 * @export
 * @class ArrSub
 * @template T
 */
export class ArraySubscription<T> {

  private funs: T[] = [];

  /**
   * Se da de alta
   *
   * @param {T} callback
   * @param {string} errMsg
   * @memberof ArrSub
   */
  public subscribe(callback: T, errMsg: string): void {
    if (this.funs.indexOf(callback) === -1) {
      this.funs.push(callback);
    } else {
      console.warn(errMsg);
    }
  }

  /**
   * Se da de baja
   *
   * @param {T} callback
   * @param {string} errMsg
   * @memberof ArrSub
   */
  public unSubscribe(callback: T, errMsg: string): void {
    const index: number = this.funs.indexOf(callback);
    if (index > -1) {
      this.funs.splice(index, 1);
    } else {
      console.warn(errMsg);
    }
  }

  /**
   * Recorre todas las funciones
   *
   * @param {Function} callback
   * @memberof ArrSub
   */
  public iterAll(callback: (fun: T) => void): void {
    for (const fun of this.funs) {
      callback(fun);
    }
  }
}
