import cn from "classnames";
import { useLocation } from "react-router";
import LayerTreeMenu from "../layers/layer-tree";
import { useState } from "react";
import StrucTreeMenu from "modules/struc/components/struc-project-tree/struc-tree";
import { useStrucProjectTree } from "modules/struc/components/struc-project-tree/hook/use-struc-project-tree";
import { ItemInfo, useSideBarContext } from "../sidebar/context";

enum TreeMode { Layers, Objects, StucProject }

const ProjectTreeMenu = () => {

  const { pathname } = useLocation();
  const isStrucModule = pathname.includes("struc");

  const [treeMode, setTreeMode] = useState(TreeMode.Layers);
  const { setData } = useSideBarContext();
  const useProjectHook = useStrucProjectTree();

  const changeTab = (mode: TreeMode) => {
    setTreeMode(mode);
    if (mode === TreeMode.StucProject) {
      const { projectTree, currentNodeId } = useProjectHook;
      const node = projectTree.getNodeById<ItemInfo>(currentNodeId)!;
      if (node) setData(node.props);
    } else {
      setData(undefined);
    }
  }

  const rootClassName = (currTreeMode: TreeMode) =>
    cn("font-medium focus:outline-none hover:text-gray-300",
      currTreeMode === treeMode ? "text-gray-100" : "text-gray-400");

  const treePanel: Record<TreeMode, React.ReactElement> = {
    [TreeMode.Layers]: <LayerTreeMenu showObject={false} />,
    [TreeMode.Objects]: <LayerTreeMenu showObject={true} />,
    [TreeMode.StucProject]: <StrucTreeMenu projectDataTree={useProjectHook} />
  }

  return (
    <div className="h-full flex flex-col">
      <div className="p-2 flex gap-2">
        <button
          className={rootClassName(TreeMode.Layers)}
          onClick={() => { changeTab(TreeMode.Layers); }}
        >
          Layers
        </button>
        <button
          className={rootClassName(TreeMode.Objects)}
          onClick={() => { changeTab(TreeMode.Objects) }}
        >
          Objects
        </button>
        {isStrucModule &&
          <button
            className={rootClassName(TreeMode.StucProject)}
            onClick={() => { changeTab(TreeMode.StucProject) }}
          >
            Project
          </button>}
      </div>
      <div className="flex-auto bg-gray-700 overflow-y-auto">
        {treePanel[treeMode]}
      </div>
    </div >
  );
};

export default ProjectTreeMenu;