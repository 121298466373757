import { getAuxMaterialLine, LineMaterialType } from "lib/materials";
import { polygon } from "lib/math/polygon";
import * as THREE from "three";
import { adjustDrawRange, updateObjBboxBSphere } from ".";
import { IPoint } from "../math/types";
import { polygonBuffer } from "./polygon";

export const ARC_DISCR = 64;

export interface circleParam {
  center: IPoint,
  radius: number,
  azimutO: number,
  plane: IPoint,
}

export function circleCreate(center: IPoint, radius: number, azimutO?: number, plane?: IPoint, material?: LineMaterialType): THREE.Line {
  if (azimutO === undefined) azimutO = 0;
  if (plane === undefined) plane = { x: 0, y: 0, z: 0 };

  const points: THREE.BufferGeometry = new THREE.BufferGeometry();
  const coords: Float32Array = circleBuffer(center, radius, azimutO, plane);
  points.setAttribute("position", new THREE.Float32BufferAttribute(coords, 3));
  const mat = material ?? getAuxMaterialLine();
  const circle: THREE.Line = new THREE.Line(points, mat);
  if (mat.type !== "LineBasicMaterial") circle.computeLineDistances();
  adjustDrawRange(circle);
  updateObjBboxBSphere(circle);

  console.error(`Cicle(${center.x}, ${center.y}) r:${radius}`);
  return circle;
}

export function circleBuffer(center: IPoint, radius: number, azimutO?: number, plane?: IPoint): Float32Array {
  if (azimutO === undefined) azimutO = 0;
  if (plane === undefined) plane = { x: 0, y: 0, z: 0 };
  let sides: number = ARC_DISCR;
  return polygonBuffer(center, radius, sides, true, azimutO, plane);
}

export function circlePoints(center: IPoint, radius: number, azimutO?: number, plane?: IPoint): IPoint[] {
  if (azimutO === undefined) azimutO = 0;
  if (plane === undefined) plane = { x: 0, y: 0, z: 0 };
  let sides: number = ARC_DISCR;
  return polygon(center, radius, sides, true, azimutO, plane);
}
