import * as THREE from "three";
import { IpointsToBuffer, getMiddlePoint, addIpoint } from "lib/math/point";
import { cadOpType } from "lib/operations/factory";
import { getAbsolutePoint } from "lib/coordinates/plane";
import { LoadStructuralData } from "lib/models/structural/load";
import { SelectionManager } from "lib/selection/selection-manager";
import { markBulkTypes } from "../selector-data";
import { GraphicProcessor } from "lib/graphic-processor";
import { IloadSelectionHandler } from "./load";

export class LoadLinealDataSelector implements IloadSelectionHandler {

  public buildSelectMarks(data: LoadStructuralData) {
    const { ptos2D, basePoint, rotation } = data.definition;
    const puntos = ptos2D.map(p => getAbsolutePoint(p, basePoint, rotation));

    const bulkData: markBulkTypes = {};
    bulkData.origin = [puntos[0]];
    bulkData.vertex = puntos.slice(1);

    const points = ptos2D.map(p => addIpoint(p, basePoint));
    const pos = getMiddlePoint(points[0], points[1]);
    bulkData.center = [pos];

    bulkData.line = [IpointsToBuffer(puntos)];
    return bulkData;
  }

  public launchMarkOP(data: LoadStructuralData, graphicProc: GraphicProcessor, selectionManager: SelectionManager, mark: THREE.Object3D, vertexIndex: number) {
    if (selectionManager.originSelector.pointObj === mark) {
      return {
        type: cadOpType.EDITLOADLINEAL,
        graphicProcessor: graphicProc,
        args: [data, 0, data.definition.ptos2D[0],],
      };
    }
    if (selectionManager.vertexSelector.pointObj === mark) {
      return {
        type: cadOpType.EDITLOADLINEAL,
        graphicProcessor: graphicProc,
        args: [data, vertexIndex + 1, data.definition.ptos2D[vertexIndex + 1]],
      };
    }
    if (selectionManager.centerSelector.pointObj === mark) {
      const midPto = getMiddlePoint(data.definition.ptos2D[0], data.definition.ptos2D[1]);
      const absMidPto = addIpoint(data.definition.basePoint, midPto);
      return {
        type: cadOpType.MOVE,
        graphicProcessor: graphicProc,
        args: [[data], absMidPto],
      };
    }
  }
}
