
import { GraphicProcessor } from "lib/graphic-processor";
import { IObjData } from "lib/models/objdata";
import { objDataType } from "lib/models/types";
import { IPoint } from "lib/math/types";
import {
  PointCommandCreator,
  LineCommandCreator,
  ArcCommandCreator,
  PolygonCommandCreator,
  CircleCommandCreator,
  EllipseCommandCreator,
  EllipseArcCommandCreator,
  TextCommandCreator,
  BlockCommandCreator,
} from "./primitives";
import {
  RegionCommandCreator,
  CubeCommandCreator,
  CylinderDimCommandCreator,
  PlaneCommandCreator,
  ConeCommandCreator,
  RPrismCommandCreator,
  PPrismCommandCreator
} from "./solid";
import {
  AlignedDimCommandCreator,
  LinealDimCommandCreator,
  AngleDimCommandCreator,
  ArcDimCommandCreator,
  CircularDimCommandCreator
} from "./dimensions";
import {
  ColumnCommandCreator,
  SlabCommandCreator,
  BeamCommandCreator,
  WallCommandCreator,
  FooterCommandCreator,
  PileCapCommandCreator,
  LoadCommandCreator,
  StripFooterCommandCreator
} from "./structural";

export function getDataFromCommand(graphicProc: GraphicProcessor, objData: IObjData, layerId: string): IObjData {
  const creator = getCreator(objData.type);
  return creator.executeCreate(graphicProc, objData, layerId);
}

export function translateAndCreateDataFromCommand(graphicProc: GraphicProcessor, objData: IObjData, vector: IPoint, layerId: string): IObjData[] {
  const creator = getCreator(objData.type);
  return creator.executeTranslateAndCreate(graphicProc, objData, vector, layerId);
}

function getCreator(type: objDataType) {
  switch (type) {
    case objDataType.POINT: return new PointCommandCreator();
    case objDataType.POLYLINE: return new LineCommandCreator();
    case objDataType.ARC: return new ArcCommandCreator();
    case objDataType.POLYGON: return new PolygonCommandCreator();
    case objDataType.CIRCLE: return new CircleCommandCreator();
    case objDataType.ELLIPSE: return new EllipseCommandCreator();
    case objDataType.ELLIPSEARC: return new EllipseArcCommandCreator();

    case objDataType.TEXT: return new TextCommandCreator();
    case objDataType.BLOCK: return new BlockCommandCreator();
    case objDataType.BLOCKREF: return new BlockCommandCreator();

    case objDataType.ALIGNEDDIM: return new AlignedDimCommandCreator();
    case objDataType.LINEARDIM: return new LinealDimCommandCreator();
    case objDataType.ANGULARDIM: return new AngleDimCommandCreator();
    case objDataType.ARCDIM: return new ArcDimCommandCreator();
    case objDataType.CIRCULARDIM: return new CircularDimCommandCreator();

    case objDataType.REGION: return new RegionCommandCreator();
    case objDataType.CUBE: return new CubeCommandCreator();
    case objDataType.CYLINDER: return new CylinderDimCommandCreator();
    case objDataType.PLANE: return new PlaneCommandCreator();
    case objDataType.CONE: return new ConeCommandCreator();
    case objDataType.RRPRIM: return new RPrismCommandCreator();
    case objDataType.PPRISM: return new PPrismCommandCreator();

    case objDataType.COLUMN: return new ColumnCommandCreator();
    case objDataType.SLAB: return new SlabCommandCreator();
    case objDataType.BEAM: return new BeamCommandCreator();
    case objDataType.WALL: return new WallCommandCreator();

    case objDataType.LOAD: return new LoadCommandCreator();

    case objDataType.FOOTER: return new FooterCommandCreator();
    case objDataType.STRIPFOOTER: return new StripFooterCommandCreator();
    case objDataType.PILECAP: return new PileCapCommandCreator();
    default:
      throw new Error(`[DataModel] objDataType ${type} doesn't support`);
  }
}

