import { PrimaryButton } from "../ui/button";
import useConfirmAccount from "./hooks/use-confirm-account";

const ConfirmAccountForm = () => {
  const { handleSubmit, ref, loading, error } = useConfirmAccount();
  return (
    <form className="space-y-4" onSubmit={handleSubmit}>
      <div>
        <label
          htmlFor="verificationCode"
          className="block text-sm font-medium text-gray-700"
        >
          Verification code
        </label>
        <div className="mt-1">
          <input
            id="code"
            name="code"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            ref={ref}
          />
        </div>
        {error && (
          <p className="mt-3 text-sm text-red-600" id="code-error">
            {error}
          </p>
        )}
      </div>
      <div>
        <PrimaryButton type="submit" loading={loading}>
          Confirm account
        </PrimaryButton>
      </div>
    </form>
  );
};

export default ConfirmAccountForm;
