const RotateIcon = ({ ...props }) => {
  return (
    <svg className="h-5 w-5" viewBox="0 0 100 100" fill="none" {...props}>
      <path
        d="M45.9805 9C23.3831 9 5 27.3918 5 50C5 72.6081 23.3831 91 45.9805 91C46.3779 91.0056 46.7724 90.9322 47.1411 90.7839C47.5099 90.6357 47.8455 90.4156 48.1284 90.1365C48.4114 89.8573 48.6361 89.5247 48.7895 89.158C48.9428 88.7912 49.0218 88.3976 49.0218 88C49.0218 87.6024 48.9428 87.2088 48.7895 86.842C48.6361 86.4753 48.4114 86.1427 48.1284 85.8635C47.8455 85.5844 47.5099 85.3643 47.1411 85.2161C46.7724 85.0678 46.3779 84.9944 45.9805 85C26.6242 85 10.9972 69.3655 10.9972 50C10.9972 30.6345 26.6242 15 45.9805 15C65.3369 15 80.9639 30.6345 80.9639 50C80.9639 58.4721 77.9588 66.2341 72.9677 72.2812V57C72.9761 56.5982 72.9037 56.1988 72.7547 55.8255C72.6058 55.4522 72.3835 55.1127 72.1009 54.827C71.8183 54.5414 71.4812 54.3155 71.1096 54.1627C70.7381 54.0099 70.3396 53.9333 69.9379 53.9375C69.5415 53.9416 69.1498 54.0243 68.7855 54.1808C68.4212 54.3373 68.0915 54.5646 67.8156 54.8494C67.5396 55.1342 67.3229 55.4709 67.1778 55.84C67.0328 56.2092 66.9623 56.6034 66.9706 57V79C66.9707 79.7956 67.2866 80.5586 67.8489 81.1212C68.4113 81.6838 69.1739 81.9999 69.9692 82H91.9587C92.3561 82.0056 92.7506 81.9322 93.1193 81.7839C93.488 81.6357 93.8236 81.4156 94.1066 81.1365C94.3896 80.8573 94.6143 80.5247 94.7676 80.158C94.921 79.7912 95 79.3976 95 79C95 78.6024 94.921 78.2088 94.7676 77.842C94.6143 77.4753 94.3896 77.1427 94.1066 76.8635C93.8236 76.5844 93.488 76.3643 93.1193 76.2161C92.7506 76.0678 92.3561 75.9944 91.9587 76H77.653C83.4669 68.9221 86.9611 59.8626 86.9611 50C86.9611 27.3918 68.578 9 45.9805 9V9Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default RotateIcon;
