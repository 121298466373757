import * as THREE from "three";
import { getDimAuxGeometry, getDimGeometry, getDimLabel } from "lib/dimension/line-dim-builder";
import { lineExtractAllVertex } from "lib/geometries/line";
import { IpointsToBuffer } from "lib/math/point";
import { cadOpType } from "lib/operations/factory";
import { AngleDimensionData } from "lib/models/dimension/angle-dim";
import { getAbsolutePoint } from "lib/coordinates/plane";
import { SelectionManager } from "../../selection-manager";
import { ObjDataSelector } from "../selector-data";


export class AngleDimensionDataSelector extends ObjDataSelector<AngleDimensionData> {

  public override buildSelectMarks() {
    const pos = this.data.graphicObj.position;
    const rot = this.data.graphicObj.rotation;
    const dimGeom = getDimGeometry(this.data.graphicObj);
    const v = lineExtractAllVertex(dimGeom);

    const buffer0 = IpointsToBuffer([v[2], v[3]].map(p => getAbsolutePoint(p, pos, rot)));
    const buffer1 = IpointsToBuffer([v[4], v[5]].map(p => getAbsolutePoint(p, pos, rot)));
    const auxGeom = getDimAuxGeometry(this.data.graphicObj);
    const bufferArc = auxGeom.geometry as THREE.BufferGeometry;
    const buffAttr = bufferArc.getAttribute("position").clone();
    const bufferArrayArc = buffAttr.applyMatrix4(this.data.graphicObj.matrixWorld).array as Float32Array;

    const textPos = getDimLabel(this.data.graphicObj);
    return {
      vertex: [
        getAbsolutePoint(textPos.position, pos, rot),
        getAbsolutePoint(v[0], pos, rot),
        getAbsolutePoint(v[1], pos, rot),
        getAbsolutePoint(v[6], pos, rot),
      ],
      line: [buffer0, buffer1, bufferArrayArc],
    };
  }

  public override launchMarkOP(selectionManager: SelectionManager, mark: THREE.Object3D, vertexIndex: number) {
    if (selectionManager.vertexSelector.pointObj === mark) {
      return {
        type: cadOpType.EDITANGLEDIM,
        graphicProcessor: this.graphicProc,
        args: [this.data, vertexIndex],
      };
    }
  }
}
