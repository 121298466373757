import { DataFiles, NewProjectInfo, Project, ProjectFilesApi, ProjectsApi } from "lib/apis/struc-project/api";
import { downloadFileFromS3, uploadFileToS3 } from "lib/apis/utils";

const cloneFileProject = async (fromProj: DataFiles, toProj: DataFiles) => {

  async function cloneFile(readUrl: string, writeUrl: string) {
    let data = await downloadFileFromS3(readUrl);
    await uploadFileToS3(writeUrl, Buffer.from(data));
  }

  await Promise.allSettled([
    cloneFile(fromProj.CameraConfig.Read, toProj.CameraConfig.Write),
    cloneFile(fromProj.Content.Read, toProj.Content.Write),
    cloneFile(fromProj.Hypothesis.Read, toProj.Hypothesis.Write),
    cloneFile(fromProj.Planes.Read, toProj.Planes.Write),
    cloneFile(fromProj.Sites.Read, toProj.Sites.Write),

    cloneFile(fromProj.BeamCrossSections.Read, toProj.BeamCrossSections.Write),
    cloneFile(fromProj.DimensionStyles.Read, toProj.DimensionStyles.Write),
    cloneFile(fromProj.LineStyles.Read, toProj.LineStyles.Write),
    cloneFile(fromProj.ShellCrossSections.Read, toProj.ShellCrossSections.Write),
    cloneFile(fromProj.TextStyles.Read, toProj.TextStyles.Write),
    cloneFile(fromProj.PileCapTemplates.Read, toProj.PileCapTemplates.Write),
  ])
}

export const fetchCloneProject = async (projectName: string, project: Project, userId: string) => {
  try {
    const projInfo: NewProjectInfo = {
      DataModelVersion: "0.2",
      Name: projectName,
      Remarks: project.Remarks,
      Country: project.Country,
      City: project.City,
      Codes: {
        ConcreteStandard: project.Codes.ConcreteStandard,
        Concrete: project.Codes.Concrete,
        Load: project.Codes.Load,
        SteelStandard: project.Codes.SteelStandard,
        Steel: project.Codes.Steel,
      },
      UserId: userId,
      FootingLevel: project.FootingLevel ?? 0,
    };
    const controller = new ProjectsApi();
    const postResponse = await controller.projectsPost(projInfo);
    const data = postResponse.data as Project;

    await cloneFileProject(project.DataFiles!, data.DataFiles!);

    const fileProj = new ProjectFilesApi();
    await fileProj.projectsUserIdIdUpdatePut(userId, data.Id);

    return {
      projectData: data,
      snapShots: null,
    }

  } catch (error) {
    console.error(error);
    throw error;
  }
}