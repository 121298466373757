import { AlignCommand } from "lib/commands/transform/align";
import { lineAddVertex, lineAuxCreate, lineMoveVertex } from "lib/geometries/line";
import { rotateObj } from "lib/geometries/rotate";
import { moveObj } from "lib/geometries/translation";
import { setGeometryFromThreeObj } from "lib/helpers/cloning";
import { lineAngle2p } from "lib/math/angles";
import { copyIPoint, substractIpoint } from "lib/math/point";
import { IPoint } from "lib/math/types";
import { IObjData } from "lib/models/objdata";
import { MultiEdition } from "../edition-multi";
import { cadOpType } from "../factory";
import { settingsOpModes } from "../step-operations";

export interface IAlignOptions {
  firstPointIni: IPoint,
  firstPointEnd: IPoint,
  secondPointIni: IPoint,
  secondPointEnd: IPoint,
  withScale: boolean
}

export class AlignOP extends MultiEdition {

  public opType = cadOpType.ALIGN;

  private auxLineFirst: THREE.Line;
  private auxLineSecond: THREE.Line;
  private firstPointIni: IPoint | undefined;
  private firstPointEnd: IPoint | undefined;
  private secondPointIni: IPoint | undefined;
  private secondPointEnd: IPoint | undefined;
  private distance: IPoint = { x: 0, y: 0, z: 0 };
  private withScale: boolean = true;

  constructor(objsData?: IObjData[], basePoint?: IPoint) {
    super(objsData);
    this.firstPointIni = basePoint;
  }

  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Select objects.",
      stepMode: settingsOpModes.SELECTOBJS,
      multiSelect: false,
      enableSelectMarks: true,
      filterFun: () => true,
      endStepCallback: async () => {
        this.unRegisterRaycast();
        this.setAuxObjs();
        this.objDataAux.forEach((o) => this.saveToTempScene(o.graphicObj));
        this.registerInputs();
        this.registerUpdaters();
        this.initializeSnap();
      }
    },
    {
      infoMsg: "Specify first source point.",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
      endStepCallback: () => {
        this.firstPointIni = copyIPoint(this.lastPoint);
        const { x, y, z } = this.firstPointIni;
        lineAddVertex(this.auxLineFirst, x, y, z, 0);
        lineAddVertex(this.auxLineFirst, x, y, z, 1);
      }
    },
    {
      infoMsg: "Specify first destination point.",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
      endStepCallback: () => {
        this.firstPointEnd = copyIPoint(this.lastPoint);
        const { x, y, z } = this.firstPointEnd;
        lineMoveVertex(this.auxLineFirst, x, y, z);
      }
    },
    {
      infoMsg: "Specify second source point.",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
      endStepCallback: () => {
        this.secondPointIni = copyIPoint(this.lastPoint);
        const { x, y, z } = this.secondPointIni;
        lineAddVertex(this.auxLineSecond, x, y, z, 0);
        lineAddVertex(this.auxLineSecond, x, y, z, 1);
      }
    },
    {
      infoMsg: "Specify second destination point.",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
      endStepCallback: () => {
        this.secondPointEnd = copyIPoint(this.lastPoint);
        const { x, y, z } = this.secondPointEnd;
        lineMoveVertex(this.auxLineSecond, x, y, z);
        this.save();
        this.endOperation();
      }
    }
    ]);
  }

  public async start() {
    this.iniSettingsOp();
    this.auxLineFirst = lineAuxCreate();
    this.auxLineSecond = lineAuxCreate();
    this.saveToTempScene(this.auxLineFirst);
    this.saveToTempScene(this.auxLineSecond);

    this.registerCancel();
    this.setStartObjs();
    if (this.objDataOrigin.length === 0) {
      this.registerRaycast();
    } else {
      this.setNextStep();
    }
  }

  public setLastPoint(): void {
    this.setNextStep();
  }

  public moveLastPoint(pto: IPoint) {
    if (this.firstPointIni && this.firstPointEnd && !this.secondPointIni && !this.secondPointEnd) {
      return;
    }
    for (let i = 0; i < this.objDataOrigin.length; i++) {
      setGeometryFromThreeObj(this.objDataOrigin[i].graphicObj, this.objDataAux[i].graphicObj);
    }
    if (this.firstPointIni) {
      if (!this.firstPointEnd) {
        lineMoveVertex(this.auxLineFirst, pto.x, pto.y, pto.z);
        this.distance = substractIpoint(pto, this.firstPointIni);
      } else {
        this.distance = substractIpoint(this.firstPointEnd, this.firstPointIni);
      }
      for (const o of this.objDataAux) {
        moveObj(o.graphicObj, this.distance);
      }
      if (this.secondPointIni) {
        if (!this.secondPointEnd) {
          lineMoveVertex(this.auxLineSecond, pto.x, pto.y, pto.z);
        }
        const angleIni = lineAngle2p(this.firstPointIni, this.secondPointIni);
        if (this.firstPointEnd) {
          const angleEnd = lineAngle2p(this.firstPointEnd, pto);
          for (const o of this.objDataAux) {
            rotateObj(o.graphicObj, 0, 0, angleEnd - angleIni, this.firstPointEnd);
          }
        }
      }
    }
  }

  public save() {
    if (this.graphicProcessor && this.firstPointIni && this.firstPointEnd && this.secondPointIni && this.secondPointEnd) {
      const alignOptions: IAlignOptions = {
        firstPointIni: this.firstPointIni,
        firstPointEnd: this.firstPointEnd,
        secondPointIni: this.secondPointIni,
        secondPointEnd: this.secondPointEnd,
        withScale: this.withScale
      };
      const command = new AlignCommand(this.objDataOrigin, alignOptions, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
}