import { AnalysisModelManager, initAnalisisManager } from "lib/models-struc/analysis/analysismodel-manager";
import { AnalysisDispatcher, initAnalysisDispatcher } from "lib/models-struc/analysis/dispatcher";
import { HypothesisDispatcher, initHypothesisDispatcher } from "lib/models-struc/hypothesis/dispatcher";
import { HypothesisModelManager, initHypothesisManager } from "lib/models-struc/hypothesis/hypothesismodel-manager";
import { initMeshDispatcher, MeshDispatcher } from "lib/models-struc/mesh/dispatcher";
import { FEMStructuralElementManager, initFEMStructuralManager } from "lib/models-struc/mesh/femstructural-manager";
import { initMeshModelManager, MeshModelManager } from "lib/models-struc/mesh/meshmodel-manager";
import { initSolutionManager, SolutionModelManager } from "lib/models-struc/solution/solutionmodel-manager";
import { initStoreyDriftRepo, StoreyDriftRepository } from "lib/models-struc/storey-drift/storey-drift-repo";
import { createContext, useContext, useMemo, ReactNode } from "react";

interface IStrucResources {
  meshManager: MeshModelManager,
  meshDispatcher: MeshDispatcher,

  hypothesisManager: HypothesisModelManager,
  hypothesisDispatcher: HypothesisDispatcher,

  analysisManager: AnalysisModelManager,
  analysisDispatcher: AnalysisDispatcher,
  femStructuralManager: FEMStructuralElementManager,

  solutionManager: SolutionModelManager,
  storeyDriftRepo: StoreyDriftRepository,
}

const StrucProjectContext = createContext<IStrucResources>({} as IStrucResources);

const StrucProjectProvider = ({ children }: { children: ReactNode }) => {

  const resources = useMemo(() => ({
    meshManager: initMeshModelManager(),
    meshDispatcher: initMeshDispatcher(),
    hypothesisManager: initHypothesisManager(),
    hypothesisDispatcher: initHypothesisDispatcher(),
    analysisManager: initAnalisisManager(),
    analysisDispatcher: initAnalysisDispatcher(),
    femStructuralManager: initFEMStructuralManager(),
    solutionManager: initSolutionManager(),
    storeyDriftRepo: initStoreyDriftRepo()
  }), []);

  return <StrucProjectContext.Provider value={resources}>{children}</StrucProjectContext.Provider>;
};

const useStrucProjectContext = () => {
  const context = useContext(StrucProjectContext);
  if (Object.keys(context).length === 0) {
    throw new Error(`useStrucProjectContext must be used within a StrucProjProvider`);
  }
  return context;
};

export { StrucProjectProvider, useStrucProjectContext };