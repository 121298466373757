import { style } from "../styles/style-cache";

export interface ILineStyle {
  name: string;
  dash: number;
  gap: number;
  dist: number;
}

export class LineStyleBuilder implements style<ILineStyle>, ILineStyle {

  public default: boolean = false;
  public styleId: string;
  
  public name: string = "New line style";
  public dash: number;
  public gap: number;
  public dist: number;

  constructor(opts?: ILineStyle) {
    if (opts !== undefined) {
      this.override(opts);
    }
  }

  public override(newProperties: Partial<ILineStyle>): void {
    if (newProperties) {
      if (newProperties.name !== undefined) this.name = newProperties.name;
      if (newProperties.dash !== undefined) this.dash = newProperties.dash;
      if (newProperties.gap !== undefined) this.gap = newProperties.gap;
      if (newProperties.dist !== undefined) this.dist = newProperties.dist;
    }
  }

  public clone(): LineStyleBuilder {
    const dolly = new LineStyleBuilder(this);
    dolly.default = this.default;
    dolly.styleId = this.styleId;
    return dolly;
  }
}


