const ExtendIcon = ({ ...props }) => {
  return (
    <svg className="h-5 w-5" viewBox="0 0 100 100" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.8333 44.2333H52.5C50.2033 44.2333 48.3333 46.1033 48.3333 48.4C48.3333 50.6967 50.2033 52.5667 52.5 52.5667H55.8333C58.13 52.5667 60 50.6967 60 48.4C60 46.1033 58.13 44.2333 55.8333 44.2333Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.8333 52.5333C73.13 52.5333 75 50.6633 75 48.3666C75 46.07 73.13 44.2 70.8333 44.2H67.5C65.2033 44.2 63.3333 46.07 63.3333 48.3666C63.3333 50.6633 65.2033 52.5333 67.5 52.5333H70.8333Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.8333 44.1667H4.16667C1.87 44.1667 0 46.0367 0 48.3333C0 50.63 1.87 52.5 4.16667 52.5H40.8333C43.13 52.5 45 50.63 45 48.3333C45 46.0367 43.13 44.1667 40.8333 44.1667Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.4032 14.506C95.2765 13.6377 92.8398 14.6677 91.9748 16.7977L66.2398 80.0977C65.8215 81.1277 65.8298 82.2593 66.2632 83.286C66.6965 84.3143 67.5015 85.1093 68.5298 85.5277C69.0415 85.736 69.5715 85.8343 70.0932 85.8343C71.7398 85.8343 73.3015 84.8527 73.9598 83.2377L99.6948 19.9343C100.56 17.806 99.5315 15.371 97.4032 14.506Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ExtendIcon;
