import { PointEditDataCommand } from "lib/commands/edition/point";
import { linealPrecision } from "lib/general-settings";
import { IPoint } from "../../math/types";
import { PointData } from "../../models/primitives/point";
import { DataDefinitionHandler } from "../base";
import { IPointMaterial } from '../../materials/point';
export class PointDataDefinitionHandler extends DataDefinitionHandler<IPoint, IPointMaterial, undefined, undefined> {

  protected data: PointData;  

  protected buildInfoProperties() {
    const def = this.data.definition;
    this.definitionInfo = {
      x: this.getNumberView(def.x, "X", "m", linealPrecision),
      y: this.getNumberView(def.y, "Y", "m", linealPrecision),
      z: this.getNumberView(def.z, "Z", "m", linealPrecision),
    };
    this.materialInfo = this.getPointMaterialView(this.data.material);
  }

  private checkNewDefinition(newDefinition: IPoint): boolean {
    if (!newDefinition) { return false; }
    if (!this.checkPoint(newDefinition)) { return false; }
    return true;
  }

  private changedNewDefinition(oldDefinition: IPoint, newDefinition: IPoint): IPoint | null {
    let def = oldDefinition;
    let changed: boolean = false;
    if (newDefinition) {
      const point = this.changedPoint(def, newDefinition);
      if (point !== null) {
        def = point;
        changed = true;
      }
    }
    return (changed ? def : null);
  }

  private checkAndChangedDefinition(oldDefinition: IPoint, newDefinition: IPoint): IPoint | null {
    let def = null;
    if (this.checkNewDefinition(newDefinition)) {
      def = this.changedNewDefinition(oldDefinition, newDefinition);
    }
    return def;
  }

  public saveAndRegenerate = (newDefinition: IPoint) => {
    const pointDefinition = this.checkAndChangedDefinition(this.data.cloneDefinition(), newDefinition);
    if (pointDefinition) {
      const command = new PointEditDataCommand(this.data, pointDefinition, null, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    };
  }
  public saveAndRegenerateMaterial = (newMaterial: IPointMaterial) => {
    const pointMaterial = this.checkAndChangedPointMaterial(this.data.material, newMaterial);
    if (pointMaterial) {
      const command = new PointEditDataCommand(this.data, null, pointMaterial, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    };
  }
}