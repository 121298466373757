import SelectionPropertiesForm from "./selection-properties";
import { useSelection } from "./hooks/use-selection";
import styled from "styled-components";
import PropertiesSelect from "../properties/component-select";
import { useMainGraphicContext } from "../viewport/context";
import React from "react";
import { IObjData } from "lib/models/objdata";

const Wrapper = styled.div`  
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  height: 100%;
  width: 100%;
`;

const SelectionPanel = ({ objData }: { objData: IObjData }) => {

  const graphicProcessor = useMainGraphicContext();
  const { selectedEntity, selectedEntities, selectEntity } = useSelection(graphicProcessor.getSelectionManager(), objData);

  return (
    <Wrapper className="py-2 space-y-2">
      <div className="px-2">
        <span className="text-sm font-medium">Properties</span>
      </div>
      {selectedEntity ? (
        <>
          {selectedEntities.length > 0 &&
            <div className="px-2">
              <PropertiesSelect
                id="selected-object"
                srOnly={true}
                name="selected-object"
                onChange={(event) => {
                  const id = event.target.value;
                  const newSelectedEntity = selectedEntities.find((entity) => entity.id === id)!;
                  selectEntity(newSelectedEntity);
                  event.target.blur();
                }}
                value={selectedEntity.id}
              >
                {selectedEntities.map((entity) => (
                  <option key={entity.id} value={entity.id}>
                    {entity ? `${entity.objName} (${entity.id})` : ""}
                  </option>
                ))}
              </PropertiesSelect>
            </div>
          }
          <div className="px-2 layer overflow-hidden">
            <SelectionPropertiesForm objData={selectedEntity} />
          </div>
        </>
      ) : (
        <label className="px-2">
          No object selected
        </label>
      )}
    </Wrapper>
  );
};

export default SelectionPanel;
export const MemoizedSelectionPanel = React.memo(SelectionPanel);
