import { polygonParam } from "lib/geometries/polygon";
import { calculateInertiaMoments4Points } from "./centroid";
import { distancePointToLine3D } from "./distance";
import { vector3EqualZero } from "./epsilon";
import { getPolarPoint, pointLinePosition } from "./point";
import { rotatePoint } from "./rotate";
import { IInertia, IPoint, ISegment } from "./types";

/** Vertices de un poligono (NO repite el ultimo y el primero)
 * i.e. 3 lados - 3 vertices
 * @export
 * @param {IPoint} center
 * @param {number} radius
 * @param {number} sides
 * @param {boolean} inscribed
 * @param {number} [angleO]
 * @param {IPoint} [plane]
 * @returns {IPoint[]}
 */
export function polygon(center: IPoint, radius: number, sides: number, inscribed: boolean, angleO?: number, plane?: IPoint): IPoint[] {
  if (angleO === undefined) angleO = 0;
  if (plane === undefined) {
    plane = { x: 0, y: 0, z: 0 };
  }

  if (inscribed === false) {
    // ****************************************** //
    //   If the radius or circumradius is given:  //
    //    inradius = outradius * cos( PI/lados )  //
    // ****************************************** //
    const outradius: number = radius / (Math.cos(Math.PI / sides));
    radius = outradius;
    // por defecto la rotación coincide con el vértice,
    // si es circumscrito queremos que coincida con la mitad de la arista.
    angleO -= Math.PI / sides;
  }
  // ******************************************************* //
  //      Un polígono se define por:                         //
  //         x[n] = r * cos(2*pi*n/N + angulo) + centro_x    //
  //         y[n] = r * sin(2*pi*n/N + angulo) + centro_y    //
  //      n: número de vertice a calcular                    //
  //      N: número de lados                                 //
  //      angulo: orientación (opcional)                     //
  //      centro: desplazamiento (opcional)                  //
  // ******************************************************* //
  const points = [] as IPoint[];
  for (let i: number = 0; i < sides; i++) {
    const currAng: number = angleO + 2 * Math.PI * i / sides;
    points.push(getPolarPoint(center, currAng, radius));
  }
  if (plane !== undefined && !vector3EqualZero(plane)) {
    const ptos = [];
    for (const p of points) {
      ptos.push(rotatePoint(p, plane.x, plane.y, plane.z, center));
    }
    return ptos;
  }
  return points;
}
export function polygonVertex(index: number, center: IPoint, radius: number, sides: number, inscribed: boolean, angleO?: number, plane?: IPoint) {
  if (angleO === undefined) angleO = 0;
  if (plane === undefined) plane = { x: 0, y: 0, z: 0 };

  if (inscribed === false) {
    // ****************************************** //
    //   If the radius or circumradius is given:  //
    //    inradius = outradius * cos( PI/lados )  //
    // ****************************************** //
    const outradius: number = radius / (Math.cos(Math.PI / sides));
    radius = outradius;
    // por defecto la rotación coincide con el vértice,
    // si es circumscrito queremos que coincida con la mitad de la arista.
    angleO -= Math.PI / sides;
  }
  // ******************************************************* //
  //      Un polígono se define por:                         //
  //         x[n] = r * cos(2*pi*n/N + angulo) + centro_x    //
  //         y[n] = r * sin(2*pi*n/N + angulo) + centro_y    //
  //      n: número de vertice a calcular                    //
  //      N: número de lados                                 //
  //      angulo: orientación (opcional)                     //
  //      centro: desplazamiento (opcional)                  //
  // ******************************************************* //
  const currAng: number = angleO + 2 * Math.PI * index / sides;
  const point = getPolarPoint(center, currAng, radius);

  if (plane !== undefined && !vector3EqualZero(plane)) {
    return rotatePoint(point, plane.x, plane.y, plane.z, center);
  }
  return point;
}

export function polygonInertia(center: IPoint, radius: number, sides: number, inscribed: boolean, angleO?: number, plane?: IPoint): IInertia {
  const points = polygon(center, radius, sides, inscribed, angleO, plane);
  return calculateInertiaMoments4Points(points, center);
}

export function isPointInsidePolygon(point: IPoint, polygonParam: polygonParam): number {
  const { center, radius, sides, inscribed, angleO, plane } = polygonParam;
  const ptos = polygon(center, radius, sides, inscribed, angleO, plane);
  let segment: ISegment = { p1: ptos[0], p2: ptos[1] };
  let dist = Infinity;
  for (let i = 0; i < ptos.length; i++) {
    const p1 = ptos[i];
    let p2 = ptos[i + 1];
    if (p2 === undefined) p2 = ptos[0];
    const d = distancePointToLine3D(p1, p2, point, true);
    if (d < dist) {
      dist = d;
      segment = { p1, p2 }
    }
  }
  return pointLinePosition(point, segment.p1, segment.p2);
}
/** Computa si un punto esta contenido en un polígono por el método de la winding number.
 *
 * Es equivalente en cuanto a rendimiento al método de rayos y más exacto.
 *
 * http://geomalgorithms.com/a03-_inclusion.html
 *
 * @export
 * @param {IPoint} point El punto
 * @param {ISegment[]} segments El polígono
 * @returns {number} La winding number. 0 si está fuera; > 0 si esta contenido; Número = número de veces que esta contenido (polígonos que se solapan)
 */
export function isPointInsidePolygon2DPoints(point: IPoint, polygonPtos: IPoint[]): number {
  let wn = 0;
  const n = polygonPtos.length;
  /*  Reglas de cruce:
      - Una arista con sentido ascendente incluye su punto incial y excluye su punto final
      - Una arista con sentido descendente excluye su punto incial e incluye su punto final
      - Las aristas horizontales se excluyen
      - La intersección arista-rayo debe estar estrictamente a la derecha del punto
  */
  // Recorremos todas las aristas del polígono
  for (let i = 0; i < n; i++) {
    const segmentP1 = polygonPtos[i];
    const segmentP2 = polygonPtos[i + 1] ? polygonPtos[i + 1] : polygonPtos[0];

    if (segmentP1.y <= point.y) {
      if (segmentP2.y > point.y) { // Cruce hacia arriba (segmento contiene al punto en Y)
        if (isLeftPoint(segmentP1, segmentP2, point) > 0) {  // El punto esta situado a la izquierda del segmento
          ++wn; // se valida como intersección hacia arriba.
        }
      }
    } else { // implicito que point.y < segmentP1.y
      if (segmentP2.y <= point.y) { // Cruce hacia abajo (segmento contiene al punto en Y)
        if (isLeftPoint(segmentP1, segmentP2, point) < 0) { // El punto esta situado a la derecha del segmento
          --wn; // se valida como intersección hacia abajo.
        }
      }
    }
  }
  return wn;
}
function isLeftPoint(p0: IPoint, p1: IPoint, p: IPoint) {
  return ((p1.x - p0.x) * (p.y - p0.y) - (p.x - p0.x) * (p1.y - p0.y));
}

export function isPointsInsidePolygon2DPoints(points: IPoint[], polygonPtos: IPoint[]): boolean {
  for (const point of points) {
    const w = isPointInsidePolygon2DPoints(point, polygonPtos);
    if (w === 0) return false;
  }
  return true;
}