import { IPointMaterial } from "lib/materials";
import { GraphicProcessor } from "../../graphic-processor";
import { IPoint } from "../../math/types";
import { PointData } from "../../models/primitives/point";
import { cadOpType } from "../../operations/factory";
import { CadCommand } from "../base";

export class PointCommand extends CadCommand {

  protected opType = cadOpType.POINTS;
  protected pointsDef: IPoint[];
  public createdData: PointData[] = [];
  protected material: IPointMaterial | undefined;

  constructor(pointsDef: IPoint[], layerId: string, graphicProc: GraphicProcessor, material?: IPointMaterial) {
    super(graphicProc)
    this.pointsDef = pointsDef;
    this.material = material;
    this.layerId = layerId;
  }

  public createObjData() {
    for (let i = 0; i < this.pointsDef.length; i++) {
      const p = this.pointsDef[i]
      const data = new PointData(p, this.material)
      data.createGraphicObj();
      this.graphicProcessor.addToLayer(data, this.layerId);
      this.createdData[i] = data;
    }
  }

  public execute() {
    if (this.createdData.length) {
      for (const data of this.createdData) {
        this.graphicProcessor.addToLayer(data, data.layerId);
      }
    } else {
      this.createObjData()
    }
    const modelManager = this.graphicProcessor.getDataModelManager();
    modelManager.dispatchAddedObjs(this.createdData);
    const lyrManager = this.graphicProcessor.getLayerManager();
    lyrManager.layerObserver.dispatchLoadLayers();
  }

  public unExecute(): void | Promise<void> {
    for (const data of this.createdData) {
      this.graphicProcessor.removeFromLayer(data);
    }
    const modelManager = this.graphicProcessor.getDataModelManager();
    modelManager.dispatchDeletedObjs(this.createdData);
    const lyrManager = this.graphicProcessor.getLayerManager();
    lyrManager.layerObserver.dispatchLoadLayers();
  }

  public delete() {
    this.pointsDef.length = 0
    this.createdData.length = 0
  }
}
