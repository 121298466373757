import { ComponentType, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FixedSizeList, ListOnScrollProps } from 'react-window'
import { CSSProperties } from "styled-components";
import { ICompositeComponent } from "lib/helpers/composite-tree";
import { TreeNode } from "./tree-node";
import { useBoundContainer } from "../hooks/use-heigth";

export interface NodeData<T> {
  id: string;
  name: string;
  depth: number;
  isLeaf: boolean;
  data?: T;
}

function getRecursiveNodes<T>(treeComposite: ICompositeComponent<T>, startDepth: number): Map<string, NodeData<T>> {
  const res: Map<string, NodeData<any>> = new Map();
  if (treeComposite) {
    treeComposite.traverse((node) => {
      const dataNode: NodeData<any> = {
        id: node.id,
        name: node.name,
        isLeaf: node.children === undefined,
        depth: node.getDepthLevel(),
        data: node.props,
      };
      if (dataNode.depth >= startDepth) {
        res.set(dataNode.id, dataNode);
      }
      return true;
    });
  }
  return res;
}

function getNodeList<T>(treeData: ICompositeComponent<T>, nodeDatas: Map<string, NodeData<T>>, startDepth: number, expandedKeys: string[]) {
  const res: NodeData<T>[] = [];
  treeData.traverse((node) => {
    let visitChildren = true;
    const depth = node.getDepthLevel();
    if (depth >= startDepth) {
      const currNode = nodeDatas.get(node.id);
      if (currNode) {
        res.push(currNode);
        visitChildren = expandedKeys.includes(currNode.id);
      } else {
        debugger;
      }
    }
    return visitChildren;
  });
  return res;
}

interface TreeProps<T> {
  treeData: ICompositeComponent<T>;
  currentNodeId: string;
  expandedKeys: string[];
  scrollOffset: { offset: number };
  setActiveNodeCb: (node: NodeData<T>) => void;
  setCurrentNodeCb?: (node?: NodeData<T>) => void;
  dragNode?: (nodeId: NodeData<any>, nodeTarget: NodeData<any>) => void;
  defaultOpen?: boolean;
  startDepth: number;
  nodeOptions: ComponentType<any> | null;
}

export const TreeView = <T extends any>({ treeData, currentNodeId, expandedKeys, scrollOffset, setCurrentNodeCb, setActiveNodeCb, dragNode, startDepth, nodeOptions }: TreeProps<T>) => {

  const refTree = useRef<FixedSizeList>(null);

  const [activeNodeId, setActiveNodeId] = useState<string>(currentNodeId);
  const currNodeId = useRef<string>(currentNodeId);

  const nodeDatas = useMemo<Map<string, NodeData<T>>>(() => getRecursiveNodes<T>(treeData, startDepth), [startDepth, treeData]);

  useEffect(() => {
    const nodeList = getNodeList(treeData, nodeDatas, startDepth, expandedKeys);
    setNodeDataRender(nodeList);
    refTree.current!.scrollTo(scrollOffset.offset);
  }, [expandedKeys, nodeDatas, scrollOffset, startDepth, treeData])

  const [nodeDataRender, setNodeDataRender] = useState<NodeData<T>[]>([...nodeDatas.values()]);

  const openCloseNode = useCallback((n: NodeData<T>) => {
    const node = nodeDatas.get(n.id)!;
    const index = expandedKeys.indexOf(node.id);
    if (index === -1) {
      expandedKeys.push(node.id);
    } else {
      expandedKeys.splice(index, 1);
    }
    const res = getNodeList(treeData, nodeDatas, startDepth, expandedKeys);
    setNodeDataRender(res);
    setActiveNodeCb(node);
  }, [expandedKeys, nodeDatas, setActiveNodeCb, startDepth, treeData])

  const setActiveNode = useCallback((node: NodeData<T>) => {
    setActiveNodeId(node.id);
    setActiveNodeCb(node);
  }, [setActiveNodeCb])

  const setCurrentNode = useCallback((node?: NodeData<T>) => {
    if (!node) {
      if (setCurrentNodeCb) setCurrentNodeCb();
    } else {
      if (currNodeId.current !== node.id) {
        currNodeId.current = node.id;
        if (setCurrentNodeCb) setCurrentNodeCb(node);
      }
    }
  }, [setCurrentNodeCb])

  const dragNodeData = useCallback((nodeId: string, targetNode: NodeData<T>) => {
    if (dragNode && nodeId !== targetNode.id) {
      const sourdeNode = nodeDatas.get(nodeId)!;
      dragNode(sourdeNode, targetNode)
    }
  }, [dragNode, nodeDatas])

  const onTreeScroll = useCallback((scrollParams: ListOnScrollProps) => {
    if (scrollParams.scrollOffset !== 0) scrollOffset.offset = scrollParams.scrollOffset;
  }, [scrollOffset])

  const RenderRow = ({ index, style }: { index: number, style: CSSProperties }) => {
    const node = nodeDataRender[index]
    return (
      < TreeNode
        onOpenCloseNode={openCloseNode}
        isActiveNode={activeNodeId === node.id}
        isOpen={expandedKeys.includes(node.id)}
        setActiveNode={setActiveNode}
        setCurrentNode={setCurrentNode}
        dragNode={dragNodeData}
        key={index}
        nodeData={node}
        startDepth={startDepth}
        style={style}
        NodeOptions={nodeOptions}
      >
      </TreeNode >
    );
  }

  const { divRef, bounds } = useBoundContainer()

  return (
    <div ref={divRef} className="h-full">
      <FixedSizeList
        ref={refTree}
        onScroll={onTreeScroll}
        className="layers select-none h-full"
        height={bounds.height ?? 300}
        itemCount={nodeDataRender.length}
        itemSize={25}
        width={"auto"}
      >
        {RenderRow}
      </FixedSizeList>
    </div>
  )
};
