import { ILineMaterial } from "lib/materials";
import { objDataType } from "lib/models/types";
import { CircleData } from "lib/models/primitives/circle";
import { circleParam } from "../../geometries/circle";
import { GraphicProcessor } from "../../graphic-processor";
import { cadOpType } from "../../operations/factory";
import { createCommand } from "./create-base";

export class CircleCommand extends createCommand {
  
  public createdData: CircleData;
  protected opType = cadOpType.CIRCLE;
  protected objType = objDataType.CIRCLE;
  protected param: circleParam;
  protected material: ILineMaterial | undefined;

  constructor(params: circleParam, layerId: string, graphicProcessor: GraphicProcessor, material?: ILineMaterial) {
    super(layerId, graphicProcessor);
    this.param = params;
    this.material = material;
  }
}