import styled from "styled-components";
import CloseIcon from "modules/cad/components/icons/close";
import Menu from "shared/components/ui/menu";
import DotsVerticalIcon from "shared/components/icons/dots-vertical";
import PropertiesInput from "modules/cad/components/properties/component-input";
import PropertiesSelect from "modules/cad/components/properties/component-select";
import { SecondaryButton } from "shared/components/ui/button";
import { useShellCrossSections } from './hooks/use-shell-cross-section';
import { isWaffleCSSParams, ShelCSSTypeNames, isSandwichCSSParams } from "lib/models-struc/cross-sections-shape/shell-cross-sections/types";
import WaffleCSSParam from "./waffle-css-param";
import HorizontalResizer from "shared/components/ui/resizer/horizontal-resizer";
import { OpFactory, cadOpType } from "lib/operations/factory";
import { useMainGraphicContext } from "modules/cad/components/viewport/context";
import { ChangeEvent, useEffect } from "react";


interface Props { onClose: () => void }

const ShellCrossSectionDlg = ({ onClose }: Props) => {

  const mainGraphicProc = useMainGraphicContext();
  useEffect(() => {
    OpFactory.stopOp(mainGraphicProc);
    return () => {
      mainGraphicProc.launchOP(cadOpType.SELECT);
    };
  }, [mainGraphicProc]);

  const {
    selectedSlab,
    shellCrossSectionLoaded,
    canBeSaved,
    createShellCrossSection,
    deleteShellCrossSection,
    selectSlabById,
    register,
    handleSubmit,
    saveChanges,
  } = useShellCrossSections();

  return (
    <Wrapper className="text-xs">
      <Header>
        <h1>Shell cross section manager</h1>
        <button onClick={onClose}>
          <CloseIcon className="h-4 w-4" />
        </button>
      </Header>
      <Content>
        <ActionBar>
          <label className={"text-xs text-gray-400"}>{"Shell cross sections:"}</label>
          <PropertiesSelect
            srOnly={true}
            value={selectedSlab.styleId}
            wheelEvent={true}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              const newStyle = event.target.value;
              selectSlabById(newStyle);
            }}>
            {shellCrossSectionLoaded.map((val, i) => (
              <option key={i} value={val.styleId}> {`${val.name}${val.default ? " [Default]" : ""}`} </option>
            ))}
          </PropertiesSelect>
          <Menu>
            <Menu.Button>
              <DotsVerticalIcon />
            </Menu.Button>
            <Menu.List>
              <Menu.Item onSelect={createShellCrossSection}> Create new </Menu.Item>
              <Menu.Item onSelect={deleteShellCrossSection}> Delete </Menu.Item>
            </Menu.List>
          </Menu>
        </ActionBar>
        <HorizontalResizer id={"pileCapModal"}
          leftClassName={"w-1/2 p-2 min-w-min overflow-auto layers"}
          leftChild={
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 gap-2">
                <PropertiesInput
                  defaultValue={selectedSlab.name}
                  id={"name"}
                  disabled={selectedSlab.default}
                  key={"name"}
                  label={"name:"}
                  name={"name"}
                  ref={register}
                  adornment={undefined}
                />
                <PropertiesSelect
                  key={"type"}
                  id={"slab"}
                  label={"type:"}
                  disabled={selectedSlab.default}
                  name={"parameters.shellSectionType"}
                  ref={register({ valueAsNumber: true })}
                  value={selectedSlab.parameters.shellSectionType}
                  onChange={handleSubmit}
                >
                  {ShelCSSTypeNames.map((val, i) => (
                    <option key={i} value={i}> {val} </option>
                  ))}
                </PropertiesSelect>

                <PropertiesInput
                  defaultValue={selectedSlab.thickness}
                  id={"thickness"}
                  disabled={selectedSlab.default}
                  key={"thickness"}
                  label={"thickness:"}
                  name={"thickness"}
                  ref={register({ valueAsNumber: true })}
                  adornment={<span>{"m"}</span>}
                />
                {(isWaffleCSSParams(selectedSlab.parameters) || isSandwichCSSParams(selectedSlab.parameters)) &&
                  <WaffleCSSParam register={register} paramSlab={selectedSlab.parameters} isDefault={selectedSlab.default} />
                }
              </div>
              <input type="submit" className="sr-only" tabIndex={-1} />
            </form>
          }
          rightClassName={"w-1/2"}
          rightChild={
            <div className="flex h-full w-full">
              <img src={"/waffle.svg"} alt="fireSpot" />
            </div>
          }
        />
        <BottomToolBar>
          <SecondaryButton onClick={saveChanges} disabled={!canBeSaved}>
            Save
          </SecondaryButton>
          <SecondaryButton onClick={onClose}>
            Cancel
          </SecondaryButton>
        </BottomToolBar>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  overflow: hidden;
  border-radius: 4px;
  width: 750px;
  margin: -32px;
  background-color: var(--color-gray-300);
`;
const Header = styled.div`
  background-color: var(--color-gray-300);
  border-bottom: 2px solid var(--color-primary);
  padding: 8px;
  display: flex;
  justify-content: space-between;
`;
const Content = styled.div`
  display: grid;
  grid-template-rows: 36px 400px 36px;
  grid-template-columns: auto;
  gap: 4px;
  padding: 8px;
  height: 100%;
`;
const Box = styled.div`
  background-color: var(--color-gray-200);
  padding: 8px;
  border-radius: 4px;
  overflow: hidden;
`;
const ActionBar = styled(Box)`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
`;

const BottomToolBar = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
export default ShellCrossSectionDlg;