import { GraphicProcessor } from "lib/graphic-processor";
import { Cad3dOp } from "lib/operations/base";
import { EventManager } from "lib/operations/event-manager";
import { IOneBoxMode } from "lib/operations/step-operations";
import React, { ChangeEvent, CSSProperties, FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

interface Props {
  graphicProc: GraphicProcessor;
  setFreeze: (freeze: boolean) => void;
  currentStep: IOneBoxMode;
  currentOperation: Cad3dOp;
  freeze: boolean;
  visible: boolean;
}

const DynamicInputDef: FC<Props> = (props) => {

  const {
    graphicProc,
    setFreeze,
    freeze,
    visible,
    currentStep,
    currentOperation,
  } = props;

  const eventManager = useMemo(() => new EventManager(graphicProc), [graphicProc]);

  const input0 = useRef<HTMLInputElement>(null);
  const [edittingX, setEdittingX] = useState(false);

  // ***************************************************************

  useEffect(() => {
    const input = input0.current;
    return () => {
      input?.blur();
    }
  }, [currentStep, input0]);

  useEffect(() => {
    if (!freeze && visible) {
      setEdittingX(false);
    }
  }, [freeze, visible])

  useEffect(() => {
    if (input0.current) {
      input0.current.focus();
      if (!currentStep.hide) {
        input0.current.select();
      }
    }
  });

  // ***************************************************************

  const tabHandler = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    setFreeze(false);
    const elem = event.target;
    if (input0.current === elem) {
      setEdittingX(false);
    }
  }, [setFreeze]);

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Tab" || event.key === ",") {
      event.preventDefault();
      tabHandler(event);
      setEdittingX(false);

    } else if (event.key === "Enter") {
      if (currentStep?.cmdLineListener) {
        currentStep.cmdLineListener((event.target as HTMLInputElement).value);
      }
      event.preventDefault();
      setEdittingX(false);
      setFreeze(false);

    } else if (event.key === "Escape") {
      currentOperation?.cancelOperation();
      setEdittingX(false);

    } else {
      console.log("[DynamicInputDef] KEYPRESSED " + event.key + " " + event.ctrlKey + " " + event.altKey);
    }
  }, [currentOperation, currentStep, setFreeze, tabHandler]);

  const editionInputHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const elem = event.target as HTMLInputElement
    const v = elem.value;
    if (v !== "") {
      if (currentStep?.cmdLineUpdateListener) {
        currentStep.cmdLineUpdateListener(v);
      }
    }
  }, [currentStep]);

  // ***************************************************************

  const handleSecondMouseUp = useCallback((event: PointerEvent) => {
    if (currentStep.cmdLineListener) {
      currentStep.cmdLineListener((input0.current as HTMLInputElement).value);
      setEdittingX(false);
    } else {
      currentOperation.cancelOperation();
    }
  }, [currentStep, currentOperation]);

  const handleMainMouseUp = useCallback((event: PointerEvent) => {
    if (currentStep.cmdLineListener) {
      currentStep.cmdLineListener((input0.current as HTMLInputElement).value);
    }
  }, [currentStep]);

  useEffect(() => {
    eventManager.connectMouseSecondUpEvent(handleSecondMouseUp);
    eventManager.connectMouseMainUpEvent(handleMainMouseUp);
    return () => {
      eventManager.disconnectMouseSecondUpEvent(handleSecondMouseUp);
      eventManager.disconnectMouseMainUpEvent(handleMainMouseUp);
    };
  }, [eventManager, handleMainMouseUp, handleSecondMouseUp]);

  // ***************************************************************

  const hiddenInputStyle: CSSProperties = {
    display: "inline-block",
    position: "absolute",
    overflow: "hidden",
    clip: "rect(0 0 0 0)",
    height: 1,
    width: 1,
    margin: -1,
    padding: 0,
    border: 0,
  }

  const currStep = currentStep as IOneBoxMode;
  let v = undefined;
  if (!edittingX && currStep.currValue) {
    v = currStep.currValue();
  }
  return (
    <span className="text-black space-x-0.5 flex items-center" style={currStep.hide ? hiddenInputStyle : {}}>
      <input
        id="dynamic-input-0"
        className="p-1 rounded-sm"
        type="string"
        ref={input0}
        size={10}
        value={v}
        onChange={editionInputHandler}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === "Control" || event.key === "Alt" || event.key === "AltGraph") {
            event.preventDefault();
          } else {
            setEdittingX(true);
            handleKeyDown(event);
          }
        }}
      />
    </span>
  );
};

export default DynamicInputDef;
