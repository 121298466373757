import { FooterCommand } from "lib/commands/structural/footer";
import { userMessageEvents } from "lib/events/user-messages";
import { linealPrecision } from "lib/general-settings";
import { getFooterOrPileCapBasePointFromColumn } from "lib/geometries/structural/column";
import { createFooter, createStripFooter, getFooterLenghtAndWidthFromColumn } from "lib/geometries/structural/footer";
import { getPhongMaterial } from "lib/materials";
import { defaultFlatSectionId, shellCrossSectionCache } from "lib/models-struc/cross-sections-shape/shell-cross-sections/cache";
import { isFlatCSSParams } from "lib/models-struc/cross-sections-shape/shell-cross-sections/types";
import { footerParam } from "lib/models-struc/types/footer";
import { isColumnData, isFooterData, isPileCapData, isWallData } from "lib/models/checktools";
import { ColumnData } from "lib/models/structural/column";
import { WallData } from "lib/models/structural/wall";
import { cadOpType } from "../factory";
import { propSetting, settingsOpModes } from "../step-operations";
import { StructBaseOP } from "./structural";

export interface footerBuilder {
  supportObjData: Array<ColumnData | WallData>;
  shellSectionId: string;
  width: number;
}

export class FooterOP extends StructBaseOP {

  public opType = cadOpType.FOOTER;
  private footerParams: footerBuilder[] = [];
  private shellCrossSectionId: string = defaultFlatSectionId;
  private width: number = 0.5;

  public async start() {
    this.graphicProcessor.unselectAll();
    this.iniSettingsOp();
    this.registerCancel();
    this.registerRaycast();
  }

  protected iniSettingsOp(): void {
    this.settingsOpManager.setCfg([{
      infoMsg: "Select columns or walls.",
      stepMode: settingsOpModes.SELECTOBJS,
      multiSelect: true,
      enableSelectMarks: false,
      filterFun: (obj) => isColumnData(obj) || isWallData(obj),
      panelProp: this.setPanelProperties(),
      getObjsCallback: () => {
        for (const obj of this.objDataOrigin) {
          if (isColumnData(obj)) {
            this.createColumnFooter(obj);
          }
          if (isWallData(obj)) {
            this.createWallFooter(obj);
          }
        }
        this.objDataOrigin.length = 0;
      }
    }])
  }
  private createColumnFooter(col: ColumnData) {
    const strucModel = this.graphicProcessor.getStructuralModelManager();
    const col0 = strucModel.currBuilding.getBaseCompoundElement(col);
    const footersOrPileCaps = col0.lnkObjs.filter(o => (isFooterData(o) || isPileCapData(o)));
    if (footersOrPileCaps.length === 0) {
      if (!this.footerParams.some(p => p.supportObjData.some(c => c === col0))) {
        this.addColumnFooter(col0);
        this.footerParams.push({
          supportObjData: [col0],
          shellSectionId: this.shellCrossSectionId,
          width: this.width,
        });
      } else {
        userMessageEvents.dispatchError("Columns can only have one active footer or pile cap.");
      }
    } else {
      userMessageEvents.dispatchError("Columns can only have one active footer or pile cap.");
    }
  }
  private createWallFooter(wall: WallData) {
    const strucModel = this.graphicProcessor.getStructuralModelManager();
    const wall0 = strucModel.currBuilding.getBaseCompoundElement(wall);
    const footersOrPileCaps = wall0.lnkObjs.filter(o => (isFooterData(o) || isPileCapData(o)));
    if (footersOrPileCaps.length === 0) {
      if (!this.footerParams.some(p => p.supportObjData.some(c => c === wall0))) {
        this.addWallFooter(wall0);
        this.footerParams.push({
          supportObjData: [wall0],
          shellSectionId: this.shellCrossSectionId,
          width: this.width,
        });
      } else {
        userMessageEvents.dispatchError("Walls can only have one active footer or pile cap.");
      }
    } else {
      userMessageEvents.dispatchError("Walls can only have one active footer or pile cap.");
    }
  }

  private setPanelProperties(): propSetting<footerParam> {
    const flatCSSnameList: [string, string][] = [];
    const ShellCSSs = shellCrossSectionCache.getAllLoadedStyles().sort((a, b) => a.name.localeCompare(b.name));
    for (const shellCSS of ShellCSSs) {
      const param = shellCSS.parameters;
      if (isFlatCSSParams(param)) {
        flatCSSnameList.push([shellCSS.name, shellCSS.styleId]);
      }
    }
    const section = shellCrossSectionCache.loadStylefromCache(this.shellCrossSectionId)!;
    return {
      propValue: {
        shellSectionId: {
          publicName: "Shell cross section",
          value: this.shellCrossSectionId,
          editable: true,
          type: "tagList",
          tagList: flatCSSnameList,
        },
        depth: {
          publicName: "Depth",
          value: section.thickness,
          editable: false,
          type: "number",
          precision: linealPrecision,
        },
        width: {
          publicName: "Width",
          value: this.width,
          editable: true,
          precision: linealPrecision,
          type: "number",
          units: "m",
        },
      },
      propCallback: this.updateParam.bind(this),
    };
  }

  public updateParam(param: footerParam) {
    this.shellCrossSectionId = param.shellSectionId;
    this.width = param.width;

    this.settingsOpManager.currCfg = {
      ...this.settingsOpManager.currCfg,
      panelProp: this.setPanelProperties(),
    }
    this.settingsOpManager.dispatchUpdateCurrStep();
  }

  private addColumnFooter(col: ColumnData) {
    const { length, width } = getFooterLenghtAndWidthFromColumn(this.width, col.definition);
    const section = shellCrossSectionCache.loadStylefromCache(this.shellCrossSectionId)!;
    const footer = createFooter(length, width, section.thickness, getPhongMaterial());
    const { x, y, z } = getFooterOrPileCapBasePointFromColumn(col.definition);
    footer.position.set(x, y, z);
    this.saveToTempScene(footer);
  }
  private addWallFooter(wall: WallData) {
    const wallLine = wall.definition.ptos2D;
    const section = shellCrossSectionCache.loadStylefromCache(this.shellCrossSectionId)!;
    const footer = createStripFooter(wallLine, this.width, section.thickness, getPhongMaterial());
    const { x, y, z } = wall.definition.basePoint;
    footer.position.set(x, y, z - wall.definition.height);
    this.saveToTempScene(footer);
  }

  public cancelOperation(): void {
    this.endOperation();
  }

  public save() {
    if (this.footerParams.length) {
      const command = new FooterCommand(this.footerParams, this.graphicProcessor);
      this.graphicProcessor.storeAndExecute(command);
    }
  }
}