import { useForm } from "react-hook-form";
import { useCallback } from "react";
import { useAuth } from "../context";

interface FormData {
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
}
export default function useSignUp() {
  const { signUp, loading } = useAuth();
  const { handleSubmit, register } = useForm<FormData>({
    shouldFocusError: true,
  });

  const onValid = useCallback(
    ({ username, password, email }: FormData) => {
      signUp(username, password, email);
    },
    [signUp]
  );
  return {
    handleSubmit: handleSubmit(onValid),
    ref: register,
    loading,
  };
}
