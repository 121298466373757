import { FC, useCallback, useState } from "react";
import cn from "classnames";
import { ReactNode } from "react-router/node_modules/@types/react";
import ChevronDownIcon from "../icons/chevron-down";
import ChevronRightIcon from "../icons/chevron-right";
import styled from "styled-components";

interface Props {
  title: string;
  opened?: boolean;
  className?: string;
  isCollapsible?: boolean;
  withBorder?: boolean;
  optionalComponent?: ReactNode
}

const Section: FC<Props> = ({ children, title, isCollapsible, opened, withBorder, className, optionalComponent }) => {
  const [isOpen, setIsOpen] = useState(opened !== undefined ? opened : true);

  const handleClick = useCallback(() => {
    setIsOpen((current) => !current);
  }, []);

  withBorder = withBorder !== undefined ? withBorder : true;
  isCollapsible = isCollapsible !== undefined ? isCollapsible : true;

  const sectionCN = cn(
    "py-2 flex items-center select-none",
    isCollapsible ? "cursor-pointer" : "",
  );

  return (
    <section aria-label={title}>
      <div className={sectionCN} >
        <span onClick={(ev: any) => { if (isCollapsible) handleClick(); }}>
          {isCollapsible && (isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />)}
        </span>
        <h2
          id="applicant-info-title"
          className={"flex-auto " + (className !== undefined ? className : "text-xl leading-6")}
          onClick={(ev: any) => { if (isCollapsible) handleClick(); }}>
          {title}
        </h2>
        {optionalComponent !== undefined && isOpen && optionalComponent}
      </div>
      <Container className={withBorder ? "border-t border-gray-200 py-4" : ""}>
        <span hidden={!isOpen}>
          {children}
        </span>
      </Container>
    </section>
  );
};

const Container = styled.div`
  border-color: var(--color-primary);
`;

export default Section;
