
import { ComponentPropsWithoutRef, forwardRef } from "react";

interface Props extends ComponentPropsWithoutRef<"input"> {
  defaultValue: string | number;
  error?: string;
  srOnly?: boolean;
  listItems?: [string, string | number][]
}

const PropertiesRadioButton = forwardRef<HTMLInputElement, Props>((props, inputRef) => {
  const {
    defaultValue,
    srOnly,
    onChange,
    listItems,
    name,
  } = props;

  return (
    <div onChange={onChange} className={`flex gap-2 flex-col items-start ${srOnly ? "w-full" : "flex-1"}`}>
      {listItems?.map((val, i) => (
        <div className="flex gap-2" key={val[0]}>
          <input
            ref={inputRef}
            type="radio"
            className={"hover:bg-gray-600"}
            id={val[0]}
            value={val[0]}
            name={name}
            defaultChecked={val[0] === defaultValue}
          />
          <label
            className={"text-xs text-gray-400 truncate text-right flex-1"}
            htmlFor={val[0]}>
            {val[0]}
          </label>
        </div>
      ))}
    </div>
  );
});

PropertiesRadioButton.displayName = "PropertiesRadioButton";

export default PropertiesRadioButton;
