const LineIcon = ({ ...props }) => {
  return (
    <svg
      className="h-5 w-5"
      viewBox="0 0 100 100"
      fill="currentColor"
      {...props}
    >
      <path d="m96.875 21.875v-18.75h-18.75v14.344l-60.656 60.656h-14.344v18.75h18.75v-14.344l60.656-60.656zm-12.5-12.5h6.25v6.25h-6.25zm-68.75 81.25h-6.25v-6.25h6.25z" />
    </svg>
  );
};

export default LineIcon;
