const VertexIcon = ({ ...props }) => {
  return (
    <svg className="h-5 w-5" viewBox="0 0 100 100" fill="none" {...props}>
      <path
        d="M78.125 78.125V84.375H21.875V78.125H15.625V21.875H21.875V3.125H3.125V21.875H9.375V78.125H3.125V96.875H21.875V90.625H78.125V96.875H96.875V78.125H78.125ZM9.375 9.375H15.625V15.625H9.375V9.375ZM15.625 90.625H9.375V84.375H15.625V90.625ZM90.625 90.625H84.375V84.375H90.625V90.625Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default VertexIcon;
