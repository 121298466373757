import * as THREE from "three";
import { PointData } from "lib/models/primitives/point";
import { cadOpType } from "lib/operations/factory";
import { SelectionManager } from "../selection-manager";
import { ObjDataSelector } from "./selector-data";


export class PointDataSelector extends ObjDataSelector<PointData> {

  public override buildSelectMarks() {
    return { vertex: [this.data.definition] };
  }

  public override launchMarkOP(selectionManager: SelectionManager, mark: THREE.Object3D, index?: number) {
    return {
      type: cadOpType.MOVE,
      graphicProcessor: this.graphicProc,
      args: [[this.data], this.data.definition],
    };
  }
}
