import { FC, useCallback, useEffect, useState } from "react";

interface Props {
  viewportRef: HTMLDivElement;
}
const PixelInfo: FC<Props> = ({ viewportRef: viewRef }) => {

  const [mousex, setMouseX] = useState("px");
  const [mousey, setMouseY] = useState("px");

  const handleMouseMove = useCallback((event: PointerEvent) => {
    setMouseX(`${event.offsetX.toFixed(3)}px`);
    setMouseY(`${event.offsetY.toFixed(3)}px`);
  }, []);

  useEffect(() => {
    viewRef.addEventListener("pointermove", handleMouseMove);
    return () => {
      viewRef.removeEventListener("pointermove", handleMouseMove);
    };
  }, []);

  return (
    <label
      className="bottom-1 left-1 absolute p-1 bg-gray-700"
      htmlFor="pixX">
      {`${mousex} : ${mousey}`}
    </label>
  );
};

export default PixelInfo;
