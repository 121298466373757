import { addWallHole, createWall, updateWall } from "lib/geometries/structural/wall";
import { materialCache } from "lib/materials";
import { getCurrentSolidMaterial, ISolidMaterial } from "lib/materials/solid";
import { copyIPolylineParam } from "lib/math/line";
import { addIpoint, copyIPoint, substractIpoint } from "lib/math/point";
import { getWallCSSId, wallParam } from "lib/models-struc/types/wall";
import { objDataType } from "../types";
import { StructuralElementData } from "./structural";
import { AxisHelperWallData } from "lib/selection/axis-helper-objdata";
import { IPoint } from "lib/math/types";
import { normalizeAngle } from "lib/math/angles";
import { rotatePoint } from "lib/math/rotate";
import { mirrorPoint } from "lib/math/mirror";
import { scalePoint } from "lib/math/scale";
import { buildingElemType } from "lib/models-struc/types/struc-base";
import { GraphicProcessor } from "lib/graphic-processor";
import { shellCrossSectionCache } from "lib/models-struc/cross-sections-shape/shell-cross-sections/cache";

export class WallData extends StructuralElementData {

  public type: buildingElemType = objDataType.WALL;
  protected nameObj: string = "Wall";

  public compoundGroupName: string;
  public definition: wallParam;

  constructor(definition: wallParam, material?: ISolidMaterial) {
    super();
    const shellCSSId = definition.shellCrossSectionId === undefined ? getWallCSSId() : definition.shellCrossSectionId;

    this.definition = {
      name: definition.name,
      lnkObjIds: definition.lnkObjIds ? definition.lnkObjIds.slice() : [],
      materialType: definition.materialType,
      wallType: definition.wallType,
      storeyId: definition.storeyId,
      heightType: definition.heightType,

      ptos2D: copyIPolylineParam(definition.ptos2D),
      stretch: definition.stretch.map(s => ({
        normal: copyIPoint(s.normal),
        orientation: s.orientation,
        holes: s.holes.map(h => ({
          points: h.points.map(p => copyIPoint(p)),
        })),
      })),
      shellCrossSectionId: shellCSSId,
      height: definition.height,
      widthType: definition.widthType,
      basePoint: copyIPoint(definition.basePoint),
      rotation: copyIPoint(definition.rotation),
      offset: copyIPoint(definition.offset),
      scale: copyIPoint(definition.scale),
    };
    this.material = material ?? getCurrentSolidMaterial();
  }

  static createObj(definition: wallParam, material: ISolidMaterial) {
    const threeMaterial = materialCache.getSolidMaterial(material);
    const section = shellCrossSectionCache.loadStylefromCache(definition.shellCrossSectionId)!;
    const graphicObj = createWall(definition.ptos2D, definition.height, section.thickness, definition.widthType, threeMaterial);
    const position = definition.basePoint;
    const rotation = definition.rotation;
    graphicObj.position.set(position.x, position.y, position.z);
    graphicObj.rotation.set(rotation.x, rotation.y, rotation.z);
    for (const stretch of definition.stretch) {
      const normal = stretch.normal;
      for (const hole of stretch.holes) {
        addWallHole(graphicObj, section.thickness, definition.widthType, hole.points, normal);
      }
    }
    return graphicObj;
  }
  public createGraphicObj() {
    if (this.graphicObj) {
      console.warn("Attention: Slab graphic object already created!!");
      return;
    }
    this.graphicObj = WallData.createObj(this.definition, this.material);
  }
  public cloneDefinition(): wallParam {
    const solid = super.cloneSolidDefinition();
    return {
      name: this.definition.name,
      lnkObjIds: this.definition.lnkObjIds ? this.definition.lnkObjIds.slice() : [],
      materialType: this.definition.materialType,
      wallType: this.definition.wallType,
      storeyId: this.definition.storeyId,
      heightType: this.definition.heightType,

      ptos2D: copyIPolylineParam(this.definition.ptos2D),
      stretch: this.definition.stretch.map(s => ({
        normal: copyIPoint(s.normal),
        orientation: s.orientation,
        holes: s.holes.map(h => ({
          points: h.points.map(p => copyIPoint(p)),
        })),
      })),
      shellCrossSectionId: this.definition.shellCrossSectionId,
      widthType: this.definition.widthType,
      height: this.definition.height,
      basePoint: copyIPoint(solid.basePoint),
      rotation: copyIPoint(solid.rotation),
      offset: copyIPoint(solid.offset),
      scale: copyIPoint(solid.scale),
    }
  }
  public createObject(definition?: wallParam, material?: ISolidMaterial): THREE.Mesh {
    return WallData.createObj(definition ?? this.definition, material ?? this.material);
  }

  public override regenerateDefinition(): void {
    const section = shellCrossSectionCache.loadStylefromCache(this.definition.shellCrossSectionId)!;
    updateWall(this.graphicObj, this.definition.ptos2D, this.definition.height, section.thickness, this.definition.widthType);
    const position = this.definition.basePoint;
    const rotation = this.definition.rotation;
    this.graphicObj.position.set(position.x, position.y, position.z);
    this.graphicObj.rotation.set(rotation.x, rotation.y, rotation.z);
    for (const stretch of this.definition.stretch) {
      const normal = stretch.normal;
      for (const hole of stretch.holes) {
        addWallHole(this.graphicObj, section.thickness, this.definition.widthType, hole.points, normal);
      }
    }
    this.updateAxisHelper();
  }

  public override translate(distance: IPoint): void {
    this.definition.basePoint = addIpoint(this.definition.basePoint, distance);
    for (const stretch of this.definition.stretch) {
      for (const hole of stretch.holes) {
        hole.points = hole.points.map(pto => addIpoint(pto, distance));
      }
    }
    this.regenerateObjectFromDefinition();
    for (let load of this.loads) {
      load.translate(distance);
    }
  }
  public override rotate(angleX: number, angleY: number, angleZ: number, basePoint: IPoint): void {
    angleX = normalizeAngle(angleX);
    angleY = normalizeAngle(angleY);
    angleZ = normalizeAngle(angleZ);
    const ptos = this.definition.ptos2D.points.map(p => addIpoint(p, this.definition.basePoint));
    this.definition.basePoint = rotatePoint(this.definition.basePoint, angleX, angleY, angleZ, basePoint);
    const ptosRot = ptos.map(p => rotatePoint(p, angleX, angleY, angleZ, basePoint));
    this.definition.ptos2D.points = ptosRot.map(p => substractIpoint(p, this.definition.basePoint));
    for (const stretch of this.definition.stretch) {
      stretch.normal = rotatePoint(stretch.normal, angleX, angleY, angleZ);
      for (const hole of stretch.holes) {
        hole.points = hole.points.map(pto => rotatePoint(pto, angleX, angleY, angleZ, basePoint));
      }
    }
    this.regenerateObjectFromDefinition();
    super.rotate(angleX, angleY, angleZ, basePoint);
  }
  public override mirror(startPoint: IPoint, endPoint: IPoint): void {
    const ptos = this.definition.ptos2D.points.map(p => addIpoint(p, this.definition.basePoint));
    this.definition.basePoint = mirrorPoint(this.definition.basePoint, startPoint, endPoint);
    const ptosMirr = ptos.map(p => mirrorPoint(p, startPoint, endPoint));
    this.definition.ptos2D.points = ptosMirr.map(p => substractIpoint(p, this.definition.basePoint));
    const endPointRel = substractIpoint(endPoint, startPoint);
    for (const stretch of this.definition.stretch) {
      stretch.normal = mirrorPoint(stretch.normal, { x: 0, y: 0, z: 0 }, endPointRel);
      for (const hole of stretch.holes) {
        hole.points = hole.points.map(pto => mirrorPoint(pto, startPoint, endPoint));
      }
    }
    this.regenerateObjectFromDefinition();
    super.mirror(startPoint, endPoint);
  }
  public override scale(factorX: number, factorY: number, factorZ: number, basePoint: IPoint): void {
    const ptos = this.definition.ptos2D.points.map(p => addIpoint(p, this.definition.basePoint));
    this.definition.basePoint = scalePoint(this.definition.basePoint, factorX, factorY, factorZ, basePoint);
    const ptosScaled = ptos.map(p => scalePoint(p, factorX, factorY, factorZ, basePoint));
    this.definition.ptos2D.points = ptosScaled.map(p => substractIpoint(p, this.definition.basePoint));
    this.regenerateObjectFromDefinition();
    super.scale(factorX, factorY, factorZ, basePoint);
  }

  public showAxisHelper(graphicProc: GraphicProcessor) {
    if (this.axisHelper === undefined) {
      this.axisHelper = new AxisHelperWallData(this, graphicProc);
    }
    this.axisHelper.showHide();
  }
}
