import { EllipseArcCommand } from "lib/commands/primitives/ellipse-arc";

import { radAngleToUser, userAngleToRad } from "lib/general-settings";
import { setPosBuffer } from "lib/geometries";
import { ellipseArcCreate } from "lib/geometries/ellipse-arc";
import { lineMoveVertex } from "lib/geometries/line";
import { getCurrentLineMaterial } from "lib/materials";
import { lineAzimut2p, mecDirectionMathDirection, normalizeAngle } from "lib/math/angles";
import { vectorDist3D } from "lib/math/distance";
import { ellipseArcBuffer } from "lib/math/ellipse-arc";
import { isEqual } from "lib/math/epsilon";
import { copyIPoint } from "lib/math/point";
import { IPoint } from "lib/math/types";
import { cadOpType } from "../factory";
import { settingsOpModes } from "../step-operations";
import { EllipseOP } from "./ellipse";

export class EllipseArcOP extends EllipseOP {
  public opType = cadOpType.ELLIPSEARC;
  public static sampleDataEllipseArc = {
    center: { x: 0, y: 0, z: 0 },
    a: 2,
    b: 1,
    azimutO: Math.PI * 0.5,
    azimutStart: 0,
    azimutEnd: Math.PI * 0.5,
    objPlane: { x: 0, y: 0, z: 0 },
  };
  public azimutStart: number = EllipseArcOP.sampleDataEllipseArc.azimutStart;
  public azimutEnd: number = EllipseArcOP.sampleDataEllipseArc.azimutEnd;

  public async start() {
    super.start();
    this.initializeEllipseArc();
  }
  private initializeEllipseArc(): void {
    this.center = EllipseArcOP.sampleDataEllipseArc.center;
    this.aAxis = EllipseArcOP.sampleDataEllipseArc.a;
    this.bAxis = EllipseArcOP.sampleDataEllipseArc.b;
    this.azimutO = EllipseArcOP.sampleDataEllipseArc.azimutO;
  }
  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Insert center: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
    }, {
      infoMsg: "Insert semiaxis mayor: ",
      stepMode: settingsOpModes.ONEBOX,
      currValue: () => (this.aAxis).toFixed(4),
      cmdLineListener: (cmd: string) => {
        const h = parseFloat(cmd);
        if (h) {
          this.aAxis = h;
          this.calculateEllipseArc();
          this.setNextStep();
          this.numPoints++;
        }
      },
    }, {
      infoMsg: "Insert semiaxis minor: ",
      stepMode: settingsOpModes.ONEBOX,
      currValue: () => (this.bAxis).toFixed(4),
      cmdLineListener: (cmd: string) => {
        const h = parseFloat(cmd);
        if (h) {
          this.bAxis = h;
          this.calculateEllipseArc();
          this.setNextStep();
          this.numPoints++;
        }
      },
    }, {
      infoMsg: "Insert start angle: ",
      stepMode: settingsOpModes.ONEBOX,
      currValue: () => radAngleToUser(this.azimutStart).toFixed(4),
      cmdLineListener: (cmd: string) => {
        const h = userAngleToRad(parseFloat(cmd));
        if (!isNaN(h)) {
          this.azimutStart = h;
          this.calculateEllipseArc();
          this.setNextStep();
          this.numPoints++;
        }
      },
    }, {
      infoMsg: "Insert end angle: ",
      stepMode: settingsOpModes.ONEBOX,
      currValue: () => radAngleToUser(this.azimutEnd).toFixed(4),
      cmdLineListener: (cmd: string) => {
        const h = userAngleToRad(parseFloat(cmd));
        if (!isNaN(h)) {
          this.azimutEnd = h;
          this.endOperation();
        }
      },
    }]);
  }
  public moveLastPoint(pto: IPoint) {
    super.moveLastPoint(pto);
    if (this.numPoints > 0) {
      lineMoveVertex(this.auxLine, pto.x, pto.y, pto.z);
      if (this.numPoints === 4) {
        const c = this.currPlane.getRelativePoint(this.center);
        const p = this.currPlane.getRelativePoint(pto);
        this.azimutEnd = normalizeAngle(
          mecDirectionMathDirection(this.azimutO) +
          normalizeAngle(lineAzimut2p(c, p))
        );
        this.calculateEllipseArc();
      }
    }
  }

  public calculateEllipseArc(): void {
    let coords = ellipseArcBuffer(
      this.center,
      this.aAxis,
      this.bAxis,
      this.azimutO,
      this.azimutStart,
      this.azimutEnd,
      this.currPlane.rotation
    );
    if (!coords) coords = new Float32Array(0);
    setPosBuffer(this.auxPoly, coords);
  }

  public setLastPoint(): void {
    if (this.numPoints < 3) {
      super.setLastPoint();
    } else if (this.numPoints === 3) {
      this.bAxis = vectorDist3D(this.center, this.lastPoint);
      if (this.bAxis <= 0) {
        this.numPoints--;
        return;
      }
      this.setNextStep();
    } else if (this.numPoints === 4) {
      const c = this.currPlane.getRelativePoint(this.center);
      const p = this.currPlane.getRelativePoint(this.lastPoint);
      this.azimutStart = normalizeAngle(
        mecDirectionMathDirection(this.azimutO) +
        normalizeAngle(lineAzimut2p(c, p))
      );
      this.auxPoly = ellipseArcCreate(
        this.center,
        this.aAxis,
        this.bAxis,
        this.azimutO,
        this.azimutStart,
        this.azimutEnd
      );
      this.saveToTempScene(this.auxPoly);
      this.setNextStep();
    } else {
      const c = this.currPlane.getRelativePoint(this.center);
      const p = this.currPlane.getRelativePoint(this.lastPoint);
      this.azimutEnd = normalizeAngle(
        mecDirectionMathDirection(this.azimutO) +
        normalizeAngle(lineAzimut2p(c, p))
      );
      if (isEqual(this.azimutStart, this.azimutEnd)) {
        this.numPoints--;
        return;
      }
      this.endOperation();
    }
  }

  public endOperation(): void {
    if (this.finished === false) {
      EllipseArcOP.sampleDataEllipseArc = {
        center: copyIPoint(this.center),
        a: this.aAxis,
        b: this.bAxis,
        azimutO: this.azimutO,
        azimutStart: this.azimutStart,
        azimutEnd: this.azimutEnd,
        objPlane: this.currPlane.rotation,
      };
    }
    this.save();
    super.endOperation();
  }

  public save() {
    if (this.graphicProcessor) {
      const ellipseArc = {
        center: copyIPoint(this.center),
        a: this.aAxis,
        b: this.bAxis,
        azimutO: this.azimutO,
        azimutStart: this.azimutStart,
        azimutEnd: this.azimutEnd,
        plane: this.currPlane.rotation,
      };
      const command = new EllipseArcCommand(
        ellipseArc,
        this.getCurrentSceneId(),
        this.graphicProcessor,
        getCurrentLineMaterial({ lineStyleId: this.lineStyleId })
      );
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
}
