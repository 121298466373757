import * as THREE from "three";
import { getAuxMaterialPoint } from "lib/materials/helpers";
import { IPoint } from "lib/math/types";
import { Line2 } from "three/examples/jsm/lines/Line2";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { getMiddlePoint, IpointsToBuffer, substractIpoint } from "lib/math/point";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { defaultLineStyleId, defaultLineWidth, materialCache } from "lib/materials";
import { calculateCentroidPoints } from "lib/math/centroid";
import { getBoundingBoxPoints } from "lib/math/box";
import { nearestPointToPolyline } from "lib/math/distance";
import { lineCreateIPoints } from "../line";
import { setPosBuffer } from "..";
import { filledPolygon2DPoints } from "../solid/region";

const loadLinealColor = { r: 255, g: 255, b: 0, a: 1 }
const loadLinealWidth = 4;
const loadSuperficialColor = { r: 255, g: 255, b: 170, a: 0.3 }
const loadSegmentsColor = { r: 0, g: 0, b: 255, a: 1 }

function drawBoxWithValue(value: number): HTMLCanvasElement {
  const canvas: HTMLCanvasElement = document.createElement("canvas");
  const context = canvas.getContext("2d") as CanvasRenderingContext2D;

  const borderThickness = 3;
  const fontSize = 24;
  const text: string = String(value.toFixed(3) ?? 0);

  context.font = fontSize + "px Arial";
  const metrics = context.measureText(text);
  const textWidth = metrics.width;

  const minWidth = textWidth + (borderThickness * 2);
  const minHeight = fontSize + (borderThickness * 2);

  canvas.width = minWidth;
  canvas.height = minHeight;

  // draw border canvas
  context.strokeStyle = "#00ffff"; // border color
  context.strokeRect(0, 0, canvas.width, canvas.height);

  // draw rect
  context.fillStyle = "#ffffff"; // background color
  context.fillRect(0, 0, minWidth, minHeight);

  // draw border
  context.lineWidth = borderThickness;
  context.strokeStyle = "#0000ff"; // border color
  context.strokeRect(borderThickness * 0.5, borderThickness * 0.5, minWidth - borderThickness, minHeight - borderThickness);

  // draw text
  context.font = fontSize + "px Arial";
  context.textAlign = "center";
  context.fillStyle = "#000000"; // text color
  context.fillText(text, minWidth * 0.5, (minHeight * 0.5) + (fontSize * 0.3));

  return canvas;
}

function getSpriteFromCanvas(canvas: HTMLCanvasElement) {
  const texture = new THREE.CanvasTexture(canvas);
  texture.needsUpdate = true;
  const material = new THREE.SpriteMaterial({ map: texture, toneMapped: false });
  const sprite = new THREE.Sprite(material);
  sprite.scale.set(0.01 * canvas.width, 0.01 * canvas.height, 1); // magic number from answers in https://stackoverflow.com/questions/23514274/three-js-2d-text-sprite-labels
  return sprite;
}

function getFatLine(ptos2D: IPoint[]): Line2 {
  const geometry = new LineGeometry();
  const buffer = IpointsToBuffer(ptos2D);
  geometry.setPositions(buffer);
  const mat = { color: loadLinealColor, lineStyleId: defaultLineStyleId, width: loadLinealWidth };
  const material = materialCache.getMaterial(mat) as LineMaterial;
  return new Line2(geometry, material);
}

function getNearestPoints(points: IPoint[]): [IPoint, IPoint, IPoint, IPoint] {
  const bbx = getBoundingBoxPoints(points);
  const [min, max] = [bbx.min, bbx.max];
  const p1 = nearestPointToPolyline({ x: min.x, y: min.y, z: min.z }, points);
  const p2 = nearestPointToPolyline({ x: min.x, y: max.y, z: min.z }, points);
  const p3 = nearestPointToPolyline({ x: max.x, y: max.y, z: min.z }, points);
  const p4 = nearestPointToPolyline({ x: max.x, y: min.y, z: min.z }, points);
  return [p1, p2, p3, p4];
}

function getLoadLineSegment(center: IPoint, ptos2D: IPoint[]): THREE.LineSegments {
  const [p1, p2, p3, p4] = getNearestPoints(ptos2D);
  const mat = { color: loadSegmentsColor, lineStyleId: defaultLineStyleId, width: defaultLineWidth };
  const material = materialCache.getMaterial(mat);
  const lineSegment = lineCreateIPoints([center, p1, p2, p3, p4], [0, 1, 0, 2, 0, 3, 0, 4], material) as THREE.LineSegments;
  return lineSegment;
}

function getLoadMesh(center: IPoint, ptos2D: IPoint[]): THREE.Mesh {
  const lineSgment = getLoadLineSegment(center, ptos2D);
  const mat = { color: loadSuperficialColor, texture: 0 };
  const material = materialCache.getSolidMaterial(mat);
  const mesh = filledPolygon2DPoints(ptos2D, material);
  mesh.add(lineSgment);
  return mesh;
}

function updateSpritePosition(sprite: THREE.Sprite, pos: IPoint) {
  if (sprite) {
    sprite.position.set(pos.x, pos.y, pos.z);
  }
}

function updateSpritePositionMiddle(sprite: THREE.Sprite, ptos2D: IPoint[]) {
  if (sprite) {
    const center = getMiddlePoint(ptos2D[0], ptos2D[1]);
    const pos = substractIpoint(center, ptos2D[0]);
    updateSpritePosition(sprite, pos);
  }
}

function updateSpriteValue(sprite: THREE.Sprite, newValue: number) {
  if (sprite) {
    const canvas = drawBoxWithValue(newValue);
    const texture = new THREE.CanvasTexture(canvas);
    texture.needsUpdate = true;
    sprite.material = new THREE.SpriteMaterial({ map: texture, toneMapped: false });
    sprite.scale.set(0.01 * canvas.width, 0.01 * canvas.height, 1); // magic number from answers in https://stackoverflow.com/questions/23514274/three-js-2d-text-sprite-labels
  }
}

export function createLoadConcentrated(value: number): THREE.Points {
  const canvas = drawBoxWithValue(value / 1000);
  const buffer = new THREE.BufferAttribute(new Float32Array([0, 0, 0]), 3);
  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute("position", buffer);
  const material = getAuxMaterialPoint();
  const loadPoint = new THREE.Points(geometry, material);
  const sprite = getSpriteFromCanvas(canvas);
  const pos = sprite.position;
  sprite.position.set(pos.x + 0.2, pos.y - 0.2, pos.z);
  loadPoint.add(sprite);
  return loadPoint;
}

export function createLoadLineal(value: number, ptos2D: IPoint[]): Line2 {
  const canvas = drawBoxWithValue(value / 1000);
  const loadLine = getFatLine(ptos2D);
  const sprite = getSpriteFromCanvas(canvas);
  updateSpritePositionMiddle(sprite, ptos2D);
  loadLine.add(sprite);
  return loadLine;
}

export function createLoadSuperficial(value: number, ptos2D: IPoint[]): THREE.Mesh {
  const center = calculateCentroidPoints(ptos2D);
  let mesh = new THREE.Mesh();
  if (center) {
    const canvas = drawBoxWithValue(value / 1000);
    mesh = getLoadMesh(center, ptos2D);
    const sprite = getSpriteFromCanvas(canvas);
    updateSpritePosition(sprite, center);
    mesh.add(sprite);
  }
  return mesh;
}

export function updateLoadConcentrated(graphicObj: THREE.Object3D, newValue: number) {
  for (let i = 0; i < graphicObj.children.length; i++) {
    let child = graphicObj.children[i];
    if (child instanceof THREE.Sprite) {
      updateSpriteValue(child, newValue / 1000);
    }
  }
}

export function updateLoadLineal(graphicObj: THREE.Object3D, newValue: number, ptos2D: IPoint[]) {
  for (let i = 0; i < graphicObj.children.length; i++) {
    let child = graphicObj.children[i];
    if (child instanceof THREE.Sprite) {
      updateSpriteValue(child, newValue / 1000);
      updateSpritePositionMiddle(child, ptos2D);
    }
  }
}

export function updateLoadSuperficial(graphicObj: THREE.Object3D, newValue: number, ptos2D: IPoint[]) {
  const center = calculateCentroidPoints(ptos2D);
  if (center) {
    for (let i = 0; i < graphicObj.children.length; i++) {
      let child = graphicObj.children[i];
      if (child instanceof THREE.Sprite) {
        updateSpriteValue(child, newValue / 1000);
        updateSpritePosition(child, center);
      } else if (child instanceof THREE.LineSegments) {
        const [p1, p2, p3, p4] = getNearestPoints(ptos2D);
        const coords = IpointsToBuffer([center, p1, p2, p3, p4]);
        setPosBuffer(child, coords);
      }
    }
  }
}