import { CSG } from "lib/helpers/three-csg/csg-lib";
import { isPointInsidePolygon2DPoints } from "lib/math/polygon";
import { Storey } from "lib/models-struc/struc-storey";
import { getAbsolutePoint } from "lib/coordinates/plane";
import { vector3Equals } from "lib/math/epsilon";
import { IStrucElementData } from "lib/models/structural/structural";
import { isFoundationData, isColumnData, isSlabData, isSupportData } from "lib/models/checktools";

function getCgsMesh(elem: IStrucElementData) {
  const graObj = elem.graphicObj;
  graObj.updateMatrix();
  return CSG.fromMesh(graObj);
}
export function checkOverlap(elemA: IStrucElementData, elemB: IStrucElementData): boolean {
  const meshA = getCgsMesh(elemA);
  const meshB = getCgsMesh(elemB);
  const res = meshA.intersect(meshB);
  return (res.polygons.length > 0);
}
export function getOverlappedElements(element: IStrucElementData, elements: IStrucElementData[]): IStrucElementData[] {
  const result: IStrucElementData[] = [];
  for (let elem of elements) {
    if (checkOverlap(element, elem)) {
      result.push(elem);
    }
  }
  return result;
}

export function getContiguousElements(element: IStrucElementData, elements: IStrucElementData[], storey: Storey): IStrucElementData[] {
  const result: IStrucElementData[] = [];
  for (let elem of elements) {
    const slabElem = isSlabData(element) ? element : isSlabData(elem) ? elem : undefined;
    const suppElem = isSupportData(elem) ? elem : isSupportData(element) ? element : undefined;
    const baseElem = isFoundationData(elem) ? elem : isFoundationData(element) ? element : undefined;

    if (slabElem !== undefined && suppElem !== undefined) {
      const slabRepr = slabElem.definition;
      const suppRepr = suppElem.definition;
      // Nowadays, the condition is: column basepoint is in slab polyline
      // TODO: In the future, use contour column is in slab polyline
      const slabPtos = slabRepr.ptos2D.map(p => getAbsolutePoint(p, slabRepr.basePoint, slabRepr.rotation));
      if (suppRepr.height >= storey.height && isPointInsidePolygon2DPoints(suppRepr.basePoint, slabPtos) !== 0) {
        result.push(elem);
      }

    } else if (baseElem !== undefined && suppElem !== undefined && isColumnData(suppElem)) {
      const baseRepr = baseElem.definition;
      const suppRepr = suppElem.definition;
      const areLinked = vector3Equals(baseRepr.basePoint, { x: suppRepr.basePoint.x, y: suppRepr.basePoint.y, z: suppRepr.basePoint.z - suppRepr.height });
      if (areLinked) {
        result.push(elem);
      }
    }
  }
  return result;
}