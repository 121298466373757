import { useQuery } from "@apollo/client";
import { GET_PROJECTS } from "modules/lite/services/projects";
import { Project } from "../../../models/project";

export interface GetProjectsData {
  projects: Project[];
}

function useProjects() {
  const { data, loading, error } = useQuery<GetProjectsData>(GET_PROJECTS);
  return {
    projects: data?.projects,
    loading,
    error,
  };
}

export default useProjects;
