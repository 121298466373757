import ModuleSelect from "shared/components/app/module-select";
import styled from "styled-components";
import UserMenu from "./user-menu";

const Header = () => {
  return (
    <Wrapper>
      <Navbar>
        <ModuleSelect />
        <UserMenu />
      </Navbar>
    </Wrapper>
  );
};

const Wrapper = styled.header`
  height: 64px;
  background-color: var(--color-background);
  border-bottom: 1px solid var(--color-divider);
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
`;

const Navbar = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  max-width: 1300px;
  margin: 0 auto;
`;

export default Header;
