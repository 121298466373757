import * as THREE from "three";
import { dimensionCache } from "lib/dimension/cache";
import { createLinearDimension, updateLinearDimension } from "lib/dimension/line-dim-builder";
import { IPoint } from "lib/math/types";
import { objDataType } from "../types";
import { addIpoint, copyIPoint, getPointOnSegment } from "lib/math/point";
import { textStyleCache } from "lib/text/cache";
import { dimPlane, dimDirectionMode, dimOrientationMode } from "lib/operations/dimension/dimension-base";
import { dimDependence, DimensionData, dimensionParam } from "./dimension";
import { isLineData, isPolygonData } from "../checktools";
import { polygon } from "lib/math/polygon";
import { getPointOnArc } from "lib/math/arc";
import { getIsegmentFromIndex, arcLineToArcParam } from "lib/math/line";
import { normalizeAngle } from "lib/math/angles";
import { rotatePoint } from "lib/math/rotate";
import { scalePoint } from "lib/math/scale";
import { mirrorPoint } from "lib/math/mirror";
import { GraphicProcessor } from "lib/graphic-processor";
import { IPointReference } from "lib/operations/step-operations";

export interface linealDimensionParam extends dimensionParam {
  dimPlane: dimPlane;
  dimDirection: dimDirectionMode;
  dimOrientation: dimOrientationMode;
  /** Posición del plano paralelo donde se contiene la acotación (por delante o por detras del bbox) */
  behindObj: number;
  objRef: [IPointReference, IPointReference];
}

export class LinealDimensionData extends DimensionData {

  public type = objDataType.LINEARDIM;
  protected nameObj: string = "Lineal dimension";
  public definition: linealDimensionParam;

  constructor(definition: linealDimensionParam) {
    super();
    this.definition = {
      styleId: definition.styleId,
      customStyleProp: definition.customStyleProp,
      dimPlane: definition.dimPlane,
      dimOrientation: definition.dimOrientation,
      dimDirection: definition.dimDirection,
      behindObj: definition.behindObj,
      objRef: [{
        data: definition.objRef[0].data,
        edgeIndex: definition.objRef[0].edgeIndex,
        factor: definition.objRef[0].factor,
        point: copyIPoint(definition.objRef[0].point),
      }, {
        data: definition.objRef[1].data,
        edgeIndex: definition.objRef[1].edgeIndex,
        factor: definition.objRef[1].factor,
        point: copyIPoint(definition.objRef[1].point),
      }]
    }
  }
  static createObj(definition: linealDimensionParam) {
    const dimStyle = dimensionCache.loadStylefromCache(definition.styleId, definition.customStyleProp)!;
    const styleText = textStyleCache.loadStylefromCache(dimStyle?.textStyleId)!;
    const dimLine = { p1: definition.objRef[0].point, p2: definition.objRef[1].point };
    const dimRef = { dimPlane: definition.dimPlane, dimDirection: definition.dimDirection, dimOrientation: definition.dimOrientation, behindObj: definition.behindObj };
    return createLinearDimension(
      dimLine,
      dimRef,
      dimStyle,
      styleText
    );
  }
  public createGraphicObj() {
    this.graphicObj = LinealDimensionData.createObj(this.definition);
    this.addObjAsDependence();
  }
  public cloneDefinition(): linealDimensionParam {
    return {
      styleId: this.definition.styleId,
      customStyleProp: this.definition.customStyleProp,
      dimPlane: this.definition.dimPlane,
      dimOrientation: this.definition.dimOrientation,
      dimDirection: this.definition.dimDirection,
      behindObj: this.definition.behindObj,
      objRef: [{
        data: this.definition.objRef[0].data,
        edgeIndex: this.definition.objRef[0].edgeIndex,
        factor: this.definition.objRef[0].factor,
        point: copyIPoint(this.definition.objRef[0].point),
      }, {
        data: this.definition.objRef[1].data,
        edgeIndex: this.definition.objRef[1].edgeIndex,
        factor: this.definition.objRef[1].factor,
        point: copyIPoint(this.definition.objRef[1].point),
      }]
    }
  }
  public cloneMaterial(): undefined {
    return;
  }
  public createObject(definition?: linealDimensionParam): THREE.Group {
    return LinealDimensionData.createObj(definition ?? this.definition);
  }

  // ---------------------------------------------------------------

  public override addObjAsDependence() {
    const deps = this.getDepsPointsRef(this.definition.objRef);
    for (const d of deps) {
      d.data.addToMyDependences([{ data: this, dimPos: d.dimPos }]);
    }
  }
  public removeObjAsDependence() {
    const deps = this.getDepsPointsRef(this.definition.objRef);
    for (const d of deps) {
      d.data.removeFromMyDependences([{ data: this, dimPos: d.dimPos }]);
    }
  }
  public regenerateReferences(graphicProc: GraphicProcessor) {
    const modelMngr = graphicProc.getDataModelManager();
    if (this.definition.objRef[0].dataId) {
      this.definition.objRef[0].data = modelMngr.getData(this.definition.objRef[0].dataId)!;
    }
    if (this.definition.objRef[1].dataId) {
      this.definition.objRef[1].data = modelMngr.getData(this.definition.objRef[1].dataId)!;
    }
  }

  public relinkOneDependence(dep: dimDependence) {
    if (dep.dimPos !== undefined) {
      this.definition.objRef[dep.dimPos].data = dep.data;
    }
  }
  public unlinkOneDependence(dimPos: number | undefined) {
    if (dimPos !== undefined) {
      this.definition.objRef[dimPos].data = undefined;
    }
  }

  // ---------------------------------------------------------------

  public override regenerateObjectFromDefinition(): void {
    this.updateDependences();
    const def = this.definition;
    const dimStyle = dimensionCache.loadStylefromCache(def.styleId, def.customStyleProp)!;
    const styleText = textStyleCache.loadStylefromCache(dimStyle?.textStyleId)!;
    const dimLine = { p1: def.objRef[0].point, p2: def.objRef[1].point };
    const dimRef = { dimPlane: def.dimPlane, dimDirection: def.dimDirection, dimOrientation: def.dimOrientation, behindObj: def.behindObj };
    updateLinearDimension(this.graphicObj, dimLine, dimRef, dimStyle, styleText);
  }

  private updateDependences() {
    if (this.definition.objRef) {
      for (const obj of this.definition.objRef) {
        const data = obj.data
        if (data && obj.edgeIndex !== undefined) {
          if (isLineData(data)) {
            const def = data.definition;
            const segOrArc = getIsegmentFromIndex(def, obj.edgeIndex);
            let pto;
            if (segOrArc.arc) {
              const arc = arcLineToArcParam(segOrArc.p1, segOrArc.p2, segOrArc.arc);
              pto = getPointOnArc(arc, obj.factor!);
            } else {
              pto = getPointOnSegment(segOrArc.p1, segOrArc.p2, obj.factor!);
            }
            obj.point = copyIPoint(pto);
          }
          else if (isPolygonData(data)) {
            const { center, radius, sides, inscribed, angleO, plane } = data.definition;
            const ptos = polygon(center, radius, sides, inscribed, angleO, plane);
            const edge = { p1: ptos[obj.edgeIndex], p2: ptos[obj.edgeIndex + 1] ?? ptos[0] };
            const pto = getPointOnSegment(edge.p1, edge.p2, obj.factor!);
            obj.point = copyIPoint(pto);
          } else {
            // TODO: getter of reference solid vertex (meshes)
          }
        }
      }
    }
  }
  public translate(distance: IPoint): void {
    if (this.definition.objRef[0].data === undefined && this.definition.objRef[1].data === undefined) {
      this.definition.objRef[0].point = addIpoint(this.definition.objRef[0].point, distance);
      this.definition.objRef[1].point = addIpoint(this.definition.objRef[1].point, distance);
      this.regenerateObjectFromDefinition();
    }
  }
  public rotate(angleX: number, angleY: number, angleZ: number, basePoint: IPoint): void {
    if (this.definition.objRef[0].data === undefined && this.definition.objRef[1].data === undefined) {
      angleX = normalizeAngle(angleX);
      angleY = normalizeAngle(angleY);
      angleZ = normalizeAngle(angleZ);

      this.definition.objRef[0].point = rotatePoint(this.definition.objRef[0].point, angleX, angleY, angleZ, basePoint);
      this.definition.objRef[1].point = rotatePoint(this.definition.objRef[1].point, angleX, angleY, angleZ, basePoint);
      this.regenerateObjectFromDefinition();
    }
  }
  public scale(factorX: number, factorY: number, factorZ: number, basePoint: IPoint): void {
    if (this.definition.objRef[0].data === undefined && this.definition.objRef[1].data === undefined) {
      this.definition.objRef[0].point = scalePoint(this.definition.objRef[0].point, factorX, factorY, factorZ, basePoint);
      this.definition.objRef[1].point = scalePoint(this.definition.objRef[1].point, factorX, factorY, factorZ, basePoint);
      this.regenerateObjectFromDefinition();
    }
  }
  public mirror(startPoint: IPoint, endPoint: IPoint): void {
    if (this.definition.objRef[0].data === undefined && this.definition.objRef[1].data === undefined) {
      this.definition.objRef[0].point = mirrorPoint(this.definition.objRef[0].point, startPoint, endPoint);
      this.definition.objRef[1].point = mirrorPoint(this.definition.objRef[1].point, startPoint, endPoint);
      this.regenerateObjectFromDefinition();
    }
  }

  // -------------------------------------------------------------------------

  protected exportDefinitionToJSON(): linealDimensionParam {
    return {
      styleId: this.definition.styleId,
      customStyleProp: this.definition.customStyleProp,
      dimPlane: this.definition.dimPlane,
      dimOrientation: this.definition.dimOrientation,
      dimDirection: this.definition.dimDirection,
      behindObj: this.definition.behindObj,
      objRef: [{
        dataId: this.definition.objRef[0].dataId,
        edgeIndex: this.definition.objRef[0].edgeIndex,
        factor: this.definition.objRef[0].factor,
        point: copyIPoint(this.definition.objRef[0].point),
      }, {
        dataId: this.definition.objRef[1].dataId,
        edgeIndex: this.definition.objRef[1].edgeIndex,
        factor: this.definition.objRef[1].factor,
        point: copyIPoint(this.definition.objRef[1].point),
      }]
    }
  }
}