import { useQuery } from "@apollo/client";
import { CADProject } from "modules/cad/models/projects";
import { GET_CAD_PROJECTS_BY_USER } from "modules/cad/services/projects";

export interface GetProjectsData {
  projects: CADProject[];
}

function useProjects() {
  const { data, loading, error } = useQuery<GetProjectsData>(
    GET_CAD_PROJECTS_BY_USER
  );
  return {
    projects: data?.projects,
    loading,
    error,
  };
}

export default useProjects;
