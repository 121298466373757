import { IDimBuildProperties } from "lib/dimension/style";
import { dimensionCache } from "lib/dimension/cache";
import { linealPrecision } from "lib/general-settings";
import { IDimensionData } from "lib/models/dimension/dimension";
import { dimDefinition } from "lib/models/types";
import { textStyleCache } from "lib/text/cache";
import { DataDefinitionHandler } from "../base";
import { dataInfoProperty } from "../properties";

export type dimensionStyleParam = {
  dimStyleId: string;
  ext: number;
  offset: number;
  distBaseLine: number;
  // blockDistBaseLine: boolean;
  minDistBaseLine1: number;
  minDistBaseLine2: number;
  extBaseLine: number;
  textStyleId: string;
}
export type dimensionStyleView = dataInfoProperty<dimensionStyleParam>

export abstract class DimensionDataDefinitionHandler<T extends dimDefinition> extends DataDefinitionHandler<T, undefined, dimensionStyleParam, undefined> {

  protected abstract data: IDimensionData;

  protected buildInfoProperties() {
    this.styleInfo = {};
    this.fillStyle(this.data);
  }

  protected fillStyle(data: IDimensionData) {
    const def = data.definition;
    const dimStyle = dimensionCache.loadStylefromCache(def.styleId, def.customStyleProp)!;
    const styleText = textStyleCache.loadStylefromCache(dimStyle?.textStyleId)!;
    this.styleInfo = {
      dimStyleId: this.getDimensionStyleView(def.styleId),
      ext: this.getNumberView(dimStyle.ext, "Ext line ext", "m", linealPrecision),
      offset: this.getNumberView(dimStyle.offset, "Ext line offset", "m", linealPrecision),
      distBaseLine: this.getNumberView(dimStyle.distBaseLine, "Dist baseLine", "m", linealPrecision),
      // blockDistBaseLine: this.getBooleanView(dimStyle.blockDistBaseLine, "Block Dist BaseLine"),
      minDistBaseLine1: this.getNumberView(dimStyle.minDistBaseLine1, "Min dist baseLine1", "m", linealPrecision),
      minDistBaseLine2: this.getNumberView(dimStyle.minDistBaseLine2, "Min dist baseLine2", "m", linealPrecision),
      extBaseLine: this.getNumberView(dimStyle.extBaseLine, "Ext line base", "m", linealPrecision),
      textStyleId: this.getTextStyleView(styleText.styleId),
    }
  }

  protected checkNewStyle(newStyle: dimensionStyleParam): boolean {
    if (!newStyle) { return false; }
    if (!this.checkString(newStyle.dimStyleId)) { return false; }
    if (!this.checkNumber(newStyle.ext)) { return false; }
    if (!this.checkNumber(newStyle.offset)) { return false; }
    if (!this.checkNumber(newStyle.distBaseLine)) { return false; }
    // if (!this.checkBoolean(newStyle.blockDistBaseLine)) { return false; }
    if (!this.checkNumber(newStyle.minDistBaseLine1)) { return false; }
    if (!this.checkNumber(newStyle.minDistBaseLine2)) { return false; }
    if (!this.checkNumber(newStyle.extBaseLine)) { return false; }
    if (!this.checkString(newStyle.textStyleId)) { return false; }
    return true;
  }

  protected changedNewStyle(oldDefinition: dimDefinition, newStyle: dimensionStyleParam): dimDefinition | null {
    let def = oldDefinition;
    let changed: boolean = false;
    if (newStyle) {
      const dimensionStyleId = this.changedString(def.styleId, newStyle.dimStyleId);
      if (dimensionStyleId !== null) {
        def.styleId = dimensionStyleId;
        changed = true;
      }
      const customStyleProp = this.changedNewStyleCustomStyleProp(oldDefinition, newStyle);
      if (customStyleProp !== null) {
        def.customStyleProp = customStyleProp;
        changed = true;
      }
    }
    return changed ? def : null;
  }
  protected changedNewStyleCustomStyleProp(oldDefinition: dimDefinition, newStyle: dimensionStyleParam): Partial<IDimBuildProperties> | null {
    let def = oldDefinition;
    let changed: boolean = false;
    const newCustomStyleProp = Object.assign({}, oldDefinition.customStyleProp);
    if (newStyle) {
      const dimStyle = dimensionCache.loadStylefromCache(def.styleId, def.customStyleProp)!;
      const styleText = textStyleCache.loadStylefromCache(dimStyle?.textStyleId)!;
      const ext = this.changedNumber(dimStyle.ext, newStyle.ext);
      if (ext !== null) {
        newCustomStyleProp.ext = ext;
        changed = true;
      }
      const offset = this.changedNumber(dimStyle.offset, newStyle.offset);
      if (offset !== null) {
        newCustomStyleProp.offset = offset;
        changed = true;
      }
      const distBaseLine = this.changedNumber(dimStyle.distBaseLine, newStyle.distBaseLine);
      if (distBaseLine !== null) {
        newCustomStyleProp.distBaseLine = distBaseLine;
        changed = true;
      }
      // const blockDistBaseLine = this.changedBoolean(dimStyle.blockDistBaseLine, newStyle.blockDistBaseLine);
      // if (blockDistBaseLine !== null) {
      //   newCustomStyleProp.blockDistBaseLine = blockDistBaseLine;
      //   changed = true;
      // }
      const minDistBaseLine1 = this.changedNumber(dimStyle.minDistBaseLine1, newStyle.minDistBaseLine1);
      if (minDistBaseLine1 !== null) {
        newCustomStyleProp.minDistBaseLine1 = minDistBaseLine1;
        changed = true;
      }
      const minDistBaseLine2 = this.changedNumber(dimStyle.minDistBaseLine2, newStyle.minDistBaseLine2);
      if (minDistBaseLine2 !== null) {
        newCustomStyleProp.minDistBaseLine2 = minDistBaseLine2;
        changed = true;
      }
      const extBaseLine = this.changedNumber(dimStyle.extBaseLine, newStyle.extBaseLine);
      if (extBaseLine !== null) {
        newCustomStyleProp.extBaseLine = extBaseLine;
        changed = true;
      }
      const textStyleId = this.changedString(styleText.styleId, newStyle.textStyleId);
      if (textStyleId !== null) {
        newCustomStyleProp.textStyleId = textStyleId;
        changed = true;
      }
    }
    return changed ? newCustomStyleProp : null;;
  }

  protected checkAndChangedStyle(newStyle: dimensionStyleParam): dimDefinition | null {
    let def = null;
    if (this.checkNewStyle(newStyle)) {
      def = this.changedNewStyle(this.data.cloneDefinition() as dimDefinition, newStyle);
    }
    return def;
  }
}