import { HipothesisActionType, HypothesisAction } from "lib/events/hypothesis";
import { CompositeNode } from "lib/helpers/composite-tree";
import { hypothesisDispatcher } from "lib/models-struc/hypothesis/dispatcher";
import { getHypothesisInfoprop, Hypothesis, SSEDirs } from "lib/models-struc/hypothesis/hypothesis";
import { hypothesisManager } from "lib/models-struc/hypothesis/hypothesismodel-manager";
import { SSEhypothesisManager } from "lib/models-struc/hypothesis/sse";
import { windhypothesisManager } from "lib/models-struc/hypothesis/wind";
import { hypoSetItem, itemType, SSEPropNode, IPanelSetting, SSEItem, loadItem } from "modules/cad/components/sidebar/context";
import { useEffect, useMemo, useReducer } from "react";


function buildHypoNode(
  hypoSetsNodes: CompositeNode<hypoSetItem>[],
  hypoSSENodes: CompositeNode<SSEItem> | undefined,
  hypoWindNodes: CompositeNode<hypoSetItem>[],
) {
  const hypoSet = new CompositeNode<hypoSetItem>("Hypothesis set", { info: undefined, type: itemType.HYPOSET });
  hypoSet.id = "Hypothesis_set_node";
  hypoSetsNodes.forEach(h => hypoSet.addChildNode(h));
  if (hypoSSENodes) hypoSet.addChildNode(hypoSSENodes);
  hypoWindNodes.forEach(h => hypoSet.addChildNode(h));
  return hypoSet;
}

function buildHypothesisTree(): CompositeNode<hypoSetItem>[] {
  const hypoSetsNodes = [];
  const hypothesis = hypothesisManager.getAllHypothesis();
  for (const h of hypothesis) {
    const hNode = new CompositeNode<hypoSetItem>(h.name, { info: h, type: itemType.HYPOSET });
    hNode.id = `${h.uid}hypothesisNode`;
    const loads = hypothesisManager.getLoadsByHypotesis(h);
    for (const l of loads) {
      const loadNode = hNode.addChildLeaf<loadItem>(l.strucName, { info: l, type: itemType.LOAD });
      loadNode.id = `${l.id}hypothesisLoadNode`;
    }
    hypoSetsNodes.push(hNode);
  }
  return hypoSetsNodes;
}
function buildSSEHypothesisTree(): CompositeNode<SSEItem> | undefined {
  if (SSEhypothesisManager.hasSSE()) {
    const sseNode = new CompositeNode<SSEItem>("SSE", { info: undefined, type: itemType.SSE });
    addSSENodes(sseNode);
    return sseNode;
  }
}
function addSSENodes(sseNode: CompositeNode<SSEItem>) {
  if (SSEhypothesisManager.SSxIsActive) {
    sseNode.addChildLeaf<SSEPropNode>("SSx", { info: SSEDirs.SSX, type: itemType.SSE_PROP });
  }
  if (SSEhypothesisManager.SSyIsActive) {
    sseNode.addChildLeaf<SSEPropNode>("SSy", { info: SSEDirs.SSY, type: itemType.SSE_PROP });
  }
  if (SSEhypothesisManager.SSzIsActive) {
    sseNode.addChildLeaf<SSEPropNode>("SSz", { info: SSEDirs.SSZ, type: itemType.SSE_PROP });
  }
}
function buildWindHypothesisTree(): CompositeNode<hypoSetItem>[] {
  const hypoSetsNodes = [];
  if (windhypothesisManager.hasHypothesis()) {
    const windHypo = windhypothesisManager.getWindHypothesis()!;
    for (const hypo of windHypo) {
      const windHnode = new CompositeNode<hypoSetItem>(hypo.subType, { info: hypo, type: itemType.HYPOSET });
      windHnode.id = `${hypo.uid}windHypothesisNode`;
      const loads = windhypothesisManager.getWindLoadHypothesisByUiid(hypo.uid);
      for (const l of loads) {
        const loadNode = windHnode.addChildLeaf<loadItem>(l.strucName, { info: l, type: itemType.LOAD });
        loadNode.id = `${l.id}windHypothesisLoadNode`;
      }
      hypoSetsNodes.push(windHnode);
    }
  }
  return hypoSetsNodes;
}

function reducer(state: hypothesisState, action: HypothesisAction): hypothesisState {
  switch (action.type) {
    case HipothesisActionType.LOAD_HYPOTHESIS:
    case HipothesisActionType.ADD_HYPOTHESIS:
    case HipothesisActionType.DELETE_HYPOTHESIS:
    case HipothesisActionType.EDIT_HYPOTHESIS: {
      return {
        ...state,
        hypoNodes: buildHypothesisTree(),
      };
    }
    case HipothesisActionType.ADD_LOAD:
    case HipothesisActionType.DELETE_LOAD: {
      return {
        ...state,
        hypoNodes: buildHypothesisTree(),
        WindNodes: buildWindHypothesisTree(),
      };
    }
    case HipothesisActionType.ADD_WIND_HYPOTHESIS:
    case HipothesisActionType.DELETE_WIND_HYPOTHESIS: {
      return {
        ...state,
        WindNodes: buildWindHypothesisTree(),
      };
    }
    case HipothesisActionType.ADD_SSE_HYPOTHESIS: {
      return {
        ...state,
        SSENodes: buildSSEHypothesisTree(),
      };
    }
    case HipothesisActionType.EDIT_SSE_HYPOTHESIS: {
      if (state.SSENodes) {
        state.SSENodes.removeAllChild();
        addSSENodes(state.SSENodes);
      }
      return {
        ...state,
        SSENodes: state.SSENodes!.cloneTree(),
      };
    }
    default:
      return state;
  }
}

interface hypothesisState {
  hypoNodes: CompositeNode<hypoSetItem>[];
  SSENodes: CompositeNode<SSEItem> | undefined;
  WindNodes: CompositeNode<hypoSetItem>[];
}

export function useHypothesis() {

  const [state, dispatch] = useReducer(reducer, undefined, () => ({
    hypoNodes: buildHypothesisTree(),
    SSENodes: buildSSEHypothesisTree(),
    WindNodes: buildWindHypothesisTree(),
  }));

  useEffect(() => {
    hypothesisDispatcher.subscribe(dispatch);
    return () => {
      hypothesisDispatcher.unsubscribe(dispatch);
    }
  }, []);

  const nodes = useMemo(() => {
    return buildHypoNode(state.hypoNodes, state.SSENodes, state.WindNodes)
  }, [state.SSENodes, state.hypoNodes, state.WindNodes])

  return {
    hypoSetsNodes: nodes,
  }
}

export const getPropSettingsFromHypothesis = (hypothesis: Hypothesis): IPanelSetting<Hypothesis> | null => {
  if (hypothesis) {
    const predefined = hypothesisManager.isPredefined(hypothesis.name);
    return {
      title: "Hypothesis properties",
      setting: {
        propValue: getHypothesisInfoprop(hypothesis, !predefined),
        propCallback: (h) => {
          const editedHypo = { ...hypothesis, ...h };
          hypothesisManager.editHypothesis(editedHypo, hypothesis.uid);
        }
      }
    }
  }
  return null;
}
export const getPropSettingsFromSSE = (): IPanelSetting<{ SSx: boolean, SSy: boolean, SSz: boolean }> | null => {
  return {
    title: "SSE Properties",
    setting: {
      propValue: {
        SSx: { publicName: "SSx", type: "checkbox", value: SSEhypothesisManager.SSxIsActive },
        SSy: { publicName: "SSy", type: "checkbox", value: SSEhypothesisManager.SSyIsActive },
        SSz: { publicName: "SSz", type: "checkbox", value: SSEhypothesisManager.SSzIsActive },
        ...getHypothesisInfoprop(SSEhypothesisManager.getSSE(SSEDirs.SSX), false),
        name: undefined,
      },
      propCallback: (sseh) => {
        SSEhypothesisManager.activeSSx(sseh.SSx);
        SSEhypothesisManager.activeSSy(sseh.SSy);
        SSEhypothesisManager.activeSSz(sseh.SSz);
        hypothesisDispatcher.dispatcEditSSE();
      }
    }
  }
}
