import { EllipseArcEditDataCommand } from "lib/commands/edition/ellipse-arc";

import { linealPrecision } from "lib/general-settings";
import { ellipseArcParam } from "lib/geometries/ellipse-arc";
import { ILineMaterial } from "lib/materials";
import { EllipseArcData } from "lib/models/primitives/ellipse-arc";
import { getEllipseFlattening } from "../../math/ellipse";
import { DataDefinitionHandler } from "../base";

type ellipseArcProp = ellipseArcParam & { flattening: number; };

export class EllipseArcDataDefinitionHandler extends DataDefinitionHandler<ellipseArcProp, ILineMaterial, undefined, undefined> {

  protected data: EllipseArcData;

  protected buildInfoProperties() {
    const def = this.data.definition;
    this.definitionInfo = {
      center: this.getPointView(def.center, "Center", "m", linealPrecision),
      a: this.getNumberView(def.a, "Semi-major axis", "m", linealPrecision, true, (a: number) => a > 0),
      b: this.getNumberView(def.b, "Semi-minor axis", "m", linealPrecision, true, (b: number) => b > 0),
      azimutO: this.getAngleView(def.azimutO, "Origin"),
      azimutStart: this.getAngleView(def.azimutStart, "Start angle"),
      azimutEnd: this.getAngleView(def.azimutEnd, "End angle"),
      plane: this.getPointAngleView(def.plane, "Rotation"),
      flattening: this.getNumberView(1 / getEllipseFlattening(def.a, def.b), "Flattening", "m", linealPrecision),
    };
    this.materialInfo = this.getLineMaterialView(this.data.material);
  }

  private checkNewDefinition(newDefinition: ellipseArcProp): boolean {
    if (!newDefinition) { return false; }
    if (!this.checkPoint(newDefinition.center)) { return false; }
    if (!this.checkNumber(newDefinition.a)) { return false; }
    if (!this.checkNumber(newDefinition.b)) { return false; }
    if (!this.checkAngle(newDefinition.azimutO)) { return false; }
    if (!this.checkPoint(newDefinition.plane)) { return false; }
    return true;
  }

  private changedNewDefinition(oldDefinition: ellipseArcParam, newDefinition: ellipseArcProp): ellipseArcParam | null {
    let def = oldDefinition;
    let changed: boolean = false;
    if (newDefinition) {
      const center = this.changedPoint(def.center, newDefinition.center);
      if (center !== null) {
        def.center = center;
        changed = true;
      }
      const a = this.changedNumber(def.a, newDefinition.a);
      if (a !== null) {
        def.a = a;
        changed = true;
      }
      const b = this.changedNumber(def.b, newDefinition.b);
      if (b !== null) {
        def.b = b;
        changed = true;
      }
      const azimutO = this.changedAngle(def.azimutO, newDefinition.azimutO);
      if (azimutO !== null) {
        def.azimutO = azimutO;
        changed = true;
      }
      const azimutStart = this.changedAngle(def.azimutStart, newDefinition.azimutStart);
      if (azimutStart !== null) {
        def.azimutStart = azimutStart;
        changed = true;
      }
      const azimutEnd = this.changedAngle(def.azimutEnd, newDefinition.azimutEnd);
      if (azimutEnd !== null) {
        def.azimutEnd = azimutEnd;
        changed = true;
      }
      const plane = this.changedPointRotation(def.plane, newDefinition.plane);
      if (plane !== null) {
        def.plane = plane;
        changed = true;
      }
    }
    return changed ? def : null;
  }

  private checkAndChangedDefinition(oldDefinition: ellipseArcParam, newDefinition: ellipseArcProp): ellipseArcParam | null {
    let def = null;
    if (this.checkNewDefinition(newDefinition)) {
      def = this.changedNewDefinition(oldDefinition, newDefinition);
    }
    return def;
  }

  public saveAndRegenerate = (newDefinition: ellipseArcProp) => {
    const ellipseArcDefinition = this.checkAndChangedDefinition(this.data.cloneDefinition(), newDefinition);
    if (ellipseArcDefinition) {
      const command = new EllipseArcEditDataCommand(this.data, ellipseArcDefinition, null, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  };
  public saveAndRegenerateMaterial = (newMaterial: ILineMaterial) => {
    const ellipseArcMaterial = this.checkAndChangedLineMaterial(this.data.material, newMaterial);
    if (ellipseArcMaterial) {
      const command = new EllipseArcEditDataCommand(this.data, null, ellipseArcMaterial, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  };
}