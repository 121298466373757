import { LoadCombinationStructure, LoadCombinationStructureResultTypeEnum } from "lib/apis/analysis-projects/api";
import { userMessageEvents } from "lib/events/user-messages";
import { IcombinationItem, storeyDriftRepo } from "lib/models-struc/storey-drift/storey-drift-repo";
import { useState, useEffect, useCallback } from "react";

interface IcombinationLoad {
  currentResultType: LoadCombinationStructureResultTypeEnum;
  currentCombType: string;
  currConstrPhase: number;
  currNumComb: number;
  maxNumComb: number;
}

function getResultData(data: LoadCombinationStructure[]) {
  const res: LoadCombinationStructureResultTypeEnum[] = [];
  for (const result of data) {
    res.push(result.ResultType);
  }
  return res;
}
function getCombinationData(data: LoadCombinationStructure[], result: LoadCombinationStructureResultTypeEnum) {
  for (const resultVal of data) {
    if (resultVal.ResultType === result) {
      return resultVal.Values.map(r => r.CombType);
    }
  }
  return [];
}

export function useStoreyDriftSelection(data: LoadCombinationStructure[]) {

  const [name, setName] = useState<string>("comb_set0");

  const [resultTypes, setResultTypes] = useState<LoadCombinationStructureResultTypeEnum[]>([]);
  const [combinationTypes, setCombinationTypes] = useState<string[]>([]);
  const [constructionPhaseList, setConstructionPhaseList] = useState<number[]>([]);

  const [loadCombination, setLoadCombination] = useState<IcombinationLoad>({
    currentResultType: "displ_ip_beams",
    currentCombType: "",
    currConstrPhase: 0,
    currNumComb: 0,
    maxNumComb: 0,
  });


  useEffect(() => {
    if (data.length) {
      const resData = getResultData(data);
      setResultTypes(resData);
      const resType = data[0].ResultType

      const combData = getCombinationData(data, resType);
      setCombinationTypes(combData);

      const result = data.find(r => r.ResultType === resType)!;
      const resultVal = result.Values.find(r => r.CombType === combData[0])!;
      const { ConsPhases, NumComb } = resultVal;
      setConstructionPhaseList(ConsPhases);

      setLoadCombination({
        currentResultType: resType,
        currentCombType: combData[0],
        currConstrPhase: ConsPhases[ConsPhases.length - 1],
        currNumComb: NumComb - 1,
        maxNumComb: NumComb,
      })
    }
  }, [data]);

  const changeCombination = useCallback((combData: string) => {
    const result = data.find(r => r.ResultType === loadCombination.currentResultType)!;
    const resultVal = result.Values.find(r => r.CombType === combData)!;
    const { ConsPhases, NumComb } = resultVal;
    setConstructionPhaseList(ConsPhases);

    setLoadCombination({
      ...loadCombination,
      currentCombType: combData,
      currConstrPhase: ConsPhases[ConsPhases.length - 1],
      currNumComb: NumComb - 1,
      maxNumComb: NumComb,
    })
  }, [data, loadCombination]);

  const changeResultType = useCallback((resType: LoadCombinationStructureResultTypeEnum) => {
    const combData = getCombinationData(data, resType);
    setCombinationTypes(combData);

    const result = data.find(r => r.ResultType === resType)!;
    const resultVal = result.Values.find(r => r.CombType === combData[0])!;
    const { ConsPhases, NumComb } = resultVal;
    setConstructionPhaseList(ConsPhases);

    setLoadCombination({
      currentResultType: resType,
      currentCombType: combData[0],
      currConstrPhase: ConsPhases[ConsPhases.length - 1],
      currNumComb: NumComb - 1,
      maxNumComb: NumComb,
    })
  }, [data]);

  // ----------------------------------------------------------------

  const [combinationList, setCombinationList] = useState<any[]>([])

  const AddCombination = () => {
    console.log(name)
    const list: any[] = [...combinationList]
    list.push([
      list.length + 1,
      loadCombination.currentResultType,
      loadCombination.currentCombType,
      loadCombination.currConstrPhase,
      loadCombination.currNumComb
    ])
    setCombinationList([...list]);
  }

  const DeleteCombination = () => {
    const list: any[] = [...combinationList]
    list.pop();
    setCombinationList([...list]);
  }

  const onAccept = () => {
    if (combinationList.length === 0) {
      userMessageEvents.dispatchWarning("Combination list is empty");
      return;
    }
    if (name.length === 0) {
      userMessageEvents.dispatchWarning("Combination name is empty");
      return;
    }
    const combinations: IcombinationItem = {
      name: name,
      combination: combinationList.map(c => ({
        resultType: c[1],
        combinationType: c[2],
        constrPhase: c[3],
        numComb: c[4],
      })),
    }
    storeyDriftRepo.save(combinations);
    return true;
  }

  return {
    name,
    setName,

    changeResultType,
    changeCombination,

    loadCombination,
    setLoadCombination,

    resultTypes,
    combinationTypes,
    constructionPhaseList,

    AddCombination,
    DeleteCombination,
    combinationList,
    onAccept
  }
}
