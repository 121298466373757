const RegionIcon = ({ ...props }) => {
  return (
    <svg className="h-5 w-5" viewBox="0 0 100 100" fill="none" {...props}>
      <path
        d="M3 75L38.6322 25L81.6284 29.5918L96 73.3418L3 75Z"
        stroke="currentColor"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default RegionIcon;
