import { slabParam } from "lib/models-struc/types/slab";
import { isLineClosedData, isSlabData } from "lib/models/checktools";
import { LineData } from "lib/models/primitives/line";
import { SlabData } from "lib/models/structural/slab";
import { dataInfoProperty } from "lib/properties/properties";
import { cadOpType } from "../factory";
import { settingsOpModes, settingOp } from "../step-operations";
import { StructBaseOP } from "./structural";
import { IPoint } from "lib/math/types";
import { updateSlab } from "lib/geometries/structural/slab";
import { SlabHoleCommand } from "lib/commands/structural/slab-hole";
import { addSlabHole } from '../../geometries/structural/slab';
import { isPointInsidePolygon2DPoints } from "lib/math/polygon";
import { userMessageEvents } from "lib/events/user-messages";
import { lineAddVertex, lineAuxCreate, lineMoveVertex } from '../../geometries/line';
import { intersectsItself } from "lib/math/intersections";

export class SlabHoleOP extends StructBaseOP {

  public opType = cadOpType.SLABHOLE;
  private static selectMode = false;

  private slabData: SlabData;
  private auxDefinition: slabParam;
  private hole: IPoint[] = [];
  private lineHoles: LineData[] = [];

  private auxLine: THREE.Line;

  private selectSlabStep: settingOp = {
    infoMsg: "Select slab",
    stepMode: settingsOpModes.SELECTOBJS,
    enableSelectMarks: false,
    multiSelect: false,
    filterFun: isSlabData,
    startStepCallback: () => {
      const selMng = this.graphicProcessor.getSelectionManager();
      selMng.unselectAll();
      this.registerRaycast();
    },
    endStepCallback: () => {
      this.unRegisterRaycast();
      this.slabData = this.objDataOrigin[0] as SlabData;
      this.objDataOrigin.length = 0;
      this.auxDefinition = this.slabData.cloneDefinition();
    }
  }
  private pointStep: settingOp = {
    infoMsg: "Insert hole points: ",
    stepMode: settingsOpModes.DEFAULTXYZ,
    cmdLineListener: this.addPointFromExt.bind(this),
    panelProp: this.setPanelProperties(),
    startStepCallback: (step: settingOp) => {
      this.hole = [];
      this.auxDefinition.holes.push(this.hole);

      this.auxLine = lineAuxCreate();
      this.saveToTempScene(this.auxLine);
      lineAddVertex(this.auxLine, 0, 0, 0);
      lineAddVertex(this.auxLine, 0, 0, 0);

      step.panelProp = this.setPanelProperties();
      this.initializeSnap();
      this.initializeWorkingPlane();
      this.registerInputs();
      this.registerUpdaters();
      this.setPlaneSettings();
    },
    endStepCallback: () => {
      this.unRegisterInputs();
      this.closeSnap();
      this.clearWorkingPlane();
      if (this.hole.length < 3) {
        this.auxDefinition.holes.pop();
      } else {
        this.hole.pop();
        this.updateSlabHoles();
      }
      this.hole = [];
    },
  }
  private selectLineStep: settingOp = {
    infoMsg: "Select Polyline",
    stepMode: settingsOpModes.SELECTOBJS,
    enableSelectMarks: false,
    multiSelect: true,
    filterFun: isLineClosedData,
    panelProp: this.setPanelProperties(),
    getObjsCallback: () => {
      if (this.objDataOrigin.length) {
        this.createHoleFromLines(this.objDataOrigin as LineData[])
        this.objDataOrigin.length = 0;
      }
    },
    startStepCallback: (step: settingOp) => {
      step.panelProp = this.setPanelProperties();
      this.registerRaycast()
      this.setPlaneSettings();
    },
    endStepCallback: () => { this.unRegisterRaycast() },
  }

  private setPlaneSettings() {
    const planeManager = this.graphicProcessor.getPlaneManager();
    planeManager.activePlane.position = this.auxDefinition.basePoint;
    planeManager.activePlane.rotation = this.auxDefinition.rotation;
    planeManager.activePlane.locked = true;
  }
  protected iniSettingsOp(): void {
    if (SlabHoleOP.selectMode) {
      this.settingsOpManager.setCfg([this.selectSlabStep, this.selectLineStep, this.pointStep]);
    } else {
      this.settingsOpManager.setCfg([this.selectSlabStep, this.pointStep, this.selectLineStep]);
    }
  }
  private setPanelProperties() {
    const panelProp: dataInfoProperty<{}> = {
      button0: {
        value: undefined,
        publicName: "Select polyline",
        type: "button",
        buttonCb: () => {
          if (!SlabHoleOP.selectMode) {
            SlabHoleOP.selectMode = true;
            const curIndx = this.settingsOpManager.index;
            const stepIndx = curIndx === 2 ? 1 : 2;
            this.setNextStep(stepIndx);
          }
        }
      },
      button1: {
        value: undefined,
        publicName: "Insert points",
        type: "button",
        buttonCb: () => {
          if (SlabHoleOP.selectMode) {
            SlabHoleOP.selectMode = false;
            const curIndx = this.settingsOpManager.index;
            const stepIndx = curIndx === 1 ? 2 : 1;
            this.setNextStep(stepIndx);
          }
        }
      },
    }
    return { propValue: panelProp };
  }
  public async start() {
    this.iniSettingsOp();
    this.registerCancel();
  }

  public setLastPoint(): void {
    const pto = this.currPlane.getRelativePoint(this.lastPoint);
    const def = this.auxDefinition;

    const isInSlab = isPointInsidePolygon2DPoints(pto, def.ptos2D);
    const isInSlabHoles = def.holes.some(h => (h !== this.hole && isPointInsidePolygon2DPoints(pto, h)));
    if (!isInSlab || isInSlabHoles) {
      userMessageEvents.dispatchError("Hole point must be inside Slab");
      return;
    }
    const holeIntersectItSelf = intersectsItself([pto, ...this.hole, pto]);
    if (holeIntersectItSelf) {
      userMessageEvents.dispatchError("Hole cannot intersect itself");
      return;
    }
    this.hole.push(pto);
    lineAddVertex(this.auxLine, this.lastPoint.x, this.lastPoint.y, this.lastPoint.z);
    if (this.hole.length > 2) this.updateSlabHoles();
  }
  public moveLastPoint(pto: IPoint) {
    lineMoveVertex(this.auxLine, pto.x, pto.y, pto.z, 0);
    lineMoveVertex(this.auxLine, pto.x, pto.y, pto.z);
  }
  private updateSlabHoles() {
    const def = this.auxDefinition;
    const mesh = this.slabData.graphicObj;
    updateSlab(mesh, def.ptos2D, def.depth, def.holes);
  }

  private createHoleFromLines(lineDatas: LineData[]) {
    for (const lineData of lineDatas) {
      if (this.lineHoles.indexOf(lineData) !== -1) continue;
      const ptos = lineData.definition.points;
      const holePts = ptos.map((p) => (this.currPlane.getRelativePoint(p)));
      const def = this.auxDefinition;
      def.holes.push(holePts);
      this.lineHoles.push(lineData);

      const mesh = this.slabData.graphicObj;
      addSlabHole(mesh, def.depth, holePts);
    }
  }

  public cancelOperation(): void {
    this.endOperation();
  }

  public save() {
    if (!this.auxDefinition) return;

    if (this.hole.length) {
      if (this.hole.length < 3) {
        this.auxDefinition.holes.pop();
        return;
      }
    }
    const holes = this.auxDefinition.holes;
    const command = new SlabHoleCommand(this.slabData, holes, this.graphicProcessor);
    this.graphicProcessor.storeAndExecute(command);
  }
}
