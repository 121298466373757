import { ArcDimensionData, arcDimensionParam } from "lib/models/dimension/arc-dim";
import { objDataType } from "lib/models/types";
import { GraphicProcessor } from "../../graphic-processor";
import { cadOpType } from "../../operations/factory";
import { EditDataCommand } from "../edition/edit-base";
import { createCommand } from "../primitives/create-base";

export class ArcAngleDimensionCommand extends createCommand {

  public createdData: ArcDimensionData;
  protected opType = cadOpType.ARCANGLEDIM;
  protected objType = objDataType.ARCDIM;
  protected param: arcDimensionParam;
  protected material: undefined;

  constructor(params: arcDimensionParam, layerId: string, graphicProc: GraphicProcessor) {
    super(layerId, graphicProc);
    this.param = params;
  }
}

export class ArcAngleDimensionEditDataCommand extends EditDataCommand {

  protected opType = cadOpType.EDITARCANGLEDIM;
  
  constructor(obj: ArcDimensionData, newDefinition: arcDimensionParam | null, graphicProcessor: GraphicProcessor) {
    super(obj, newDefinition, null, graphicProcessor);
  }
}

export class ArcLongDimensionCommand extends createCommand {

  public createdData: ArcDimensionData;
  protected opType = cadOpType.ARCLONGDIM;
  protected objType = objDataType.ARCDIM;
  protected param: arcDimensionParam;
  protected material: undefined;

  constructor(params: arcDimensionParam, layerId: string, graphicProc: GraphicProcessor) {
    super(layerId, graphicProc);
    this.param = params;
  }
}

export class ArcLongDimensionEditDataCommand extends EditDataCommand {
  
  protected opType = cadOpType.EDITARCLONGDIM;

  constructor(obj: ArcDimensionData, newDefinition: arcDimensionParam | null, graphicProcessor: GraphicProcessor) {
    super(obj, newDefinition, null, graphicProcessor);
  }
}