import { forwardRef, SelectHTMLAttributes } from "react";
import cn from "classnames";

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  srOnly?: boolean;
  label?: string;
  wheelEvent?: boolean;
}
const PropertiesSelect = forwardRef<HTMLSelectElement, Props>(
  ({ id, label, srOnly, children, wheelEvent, className, ...props }, ref) => {
    const rootClassName = cn(
      "text-xs leading-5 block border-gray-600 focus:outline-none hover:bg-gray-600 rounded px-1 py-0",
      " w-full bg-gray-700 text-gray-300 focus:ring-indigo-500 overflow-hidden layers",
      className
    );
    return (
      <div className={srOnly ? "flex-grow" : "flex gap-2 items-baseline w-full"}>
        <label
          htmlFor={id}
          className={srOnly ? "sr-only" : "text-xs text-gray-400 text-right truncate flex-1"}
        >
          {label}
        </label>
        <div className={`relative flex items-center ${srOnly ? "w-full" : "flex-1"}`}>
          <select className={rootClassName}
            id={id}
            ref={ref}
            onWheel={(e) => {
              if (wheelEvent) {
                e.preventDefault();
                const currIndx = (e.target as HTMLSelectElement).selectedIndex;
                const opts = (e.target as HTMLSelectElement).options;
                if (e.deltaY < 0 && opts[currIndx - 1]) {
                  opts[currIndx - 1].selected = true;
                  fireEvent(e.target, "change");
                }
                if (e.deltaY > 0 && opts[currIndx + 1]) {
                  opts[currIndx + 1].selected = true;
                  fireEvent(e.target, "change");
                }
              }
            }}
            {...props}
          >
            {children}
          </select>
        </div>
      </div>
    );
  }
);

function fireEvent(element: any, event: any) {
  if ((document as any).createEventObject) {
    // dispatch for IE
    const evt = (document as any).createEventObject();
    return element.fireEvent('on' + event, evt)
  }
  else {
    // dispatch for firefox + others
    const evt = document.createEvent("HTMLEvents");
    evt.initEvent(event, true, true); // event type,bubbling,cancelable
    return !element.dispatchEvent(evt);
  }
}

export default PropertiesSelect;
