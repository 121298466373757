import { useParams } from "react-router-dom";
import ViewportSidebar from "../components/sidebar/viewport-sidebar";
import Viewport from "../components/viewport";
import LoadingDots from "shared/components/ui/loading-dots";
import Toolbar from "../components/toolbar";
import ProjectToolbar from "../components/toolbar/toolbar-project";
import styled from "styled-components";
import HorizontalResizer from "shared/components/ui/resizer/horizontal-resizer";
import { useEffect, useState } from "react";
import { ModelDataImporter } from "lib/input-output/database/data-model-import";
import { useMainGraphicContext } from "../components/viewport/context";
import { unloadResources } from "lib/resources";
import useProject from "../components/projects/hooks/use-project";

const Project = () => {

  const { id } = useParams<{ id: string }>();
  const { project, loading } = useProject(id);
  const [resourcesLoaded, setResourcesLoaded] = useState<boolean>(false);

  const graphic = useMainGraphicContext();

  useEffect(() => {
    if (project && !resourcesLoaded) {
      const importer = new ModelDataImporter(graphic);
      importer.openProjectCAD(project.content!);
      setResourcesLoaded(true);
    }
  }, [loading])

  useEffect(() => {
    return () => {
      unloadResources();
    }
  }, [])

  if (loading || !resourcesLoaded)
    return (
      <div className="h-screen bg-gray-800 flex flex-col items-center justify-center">
        <LoadingDots />
      </div>
    );
  return (
    <MainWrapper className="-inset-0">
      <ProjectToolbar />
      <Toolbar />
      <HorizontalResizer id={"main"}
        leftClassName={"w-min"}
        leftChild={<ViewportSidebar />}
        rightClassName={"w-full"}
        rightChild={<Viewport project={project} />}
      />
    </MainWrapper>
  );
};

const MainWrapper = styled.section`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto 1fr;
`;

export default Project;
