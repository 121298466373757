import { ReactNode } from "react";
import { DialogContent, DialogOverlay } from "@reach/dialog";
import styled from "styled-components";
interface Props {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  maxWidth?: string;
}

const Overlay = styled(DialogOverlay)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: hsl(0deg 0% 0% / 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled(DialogContent)`
  position: relative;
  background: var(--color-background);
  border-radius: 8px;
  @media (max-width: 550px) {
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }
`;

const ChildrenWrapper = styled.div`
  padding: 24px 32px;
`;

function Modal({ children, isOpen, onClose, title }: Props) {
  return (
    <Overlay isOpen={isOpen}>  {/*onDismiss={onClose}>*/}
      <Content aria-label={title}>
        <ChildrenWrapper>{children}</ChildrenWrapper>
      </Content>
    </Overlay>
  );
}

export default Modal;
