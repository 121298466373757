import { useMutation } from "@apollo/client";
import {
  DELETE_CAD_PROJECT,
  GET_CAD_PROJECTS_BY_USER,
} from "modules/cad/services/projects";
import { useCallback, useState } from "react";
import { useUI } from "shared/components/ui/context";
import { GetProjectsData } from "./use-projects";

type Status = "idle" | "loading" | "success" | "error";

interface Data {
  project: {
    id: string;
  };
}

interface Params {
  id: string;
}

export function useDeleteProject() {
  const { openToast, closeModal, modalData } = useUI();
  const [status, setStatus] = useState<Status>("idle");
  const [mutate] = useMutation<Data, Params>(DELETE_CAD_PROJECT, {
    update(cache, { data }) {
      if (data) {
        const query = cache.readQuery<GetProjectsData>({
          query: GET_CAD_PROJECTS_BY_USER,
        });
        if (query) {
          cache.writeQuery({
            query: GET_CAD_PROJECTS_BY_USER,
            data: {
              projects: query.projects.filter(
                (project) => project.id !== data.project.id
              ),
            },
          });
        }
      }
    },
  });

  const deleteProject = useCallback(() => {
    mutate({
      variables: { id: modalData?.id },
    })
      .then(() => {
        openToast({
          title: "Project successfully deleted",
          status: "success",
        });
        setStatus("success");
      })
      .catch(() => {
        openToast({
          title: "Error deleting project",
          status: "error",
        });
        setStatus("error");
      })
      .finally(() => {
        closeModal();
      });
  }, [closeModal, mutate, openToast, modalData]);

  return { deleteProject, status, cancelDeleteProject: closeModal };
}
