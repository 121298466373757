import { GraphicProcessor } from "../../graphic-processor"
import { IPolylineParam } from "../../math/line"
import { cadOpType } from "../../operations/factory"
import { createCommand } from "./create-base"
import { ILineMaterial } from "lib/materials"
import { objDataType } from "lib/models/types"
import { LineData } from "lib/models/primitives/line"

export class PolyLineCommand extends createCommand {

  public createdData: LineData;
  protected opType = cadOpType.POLYLINE;
  protected objType = objDataType.POLYLINE;
  protected param: IPolylineParam;
  protected material: ILineMaterial | undefined;

  constructor(lineDef: IPolylineParam, layerId: string, graphicProc: GraphicProcessor, material?: ILineMaterial) {
    super(layerId, graphicProc)
    this.param = {
      isClosed: lineDef.isClosed,
      points: lineDef.points.slice(),
      arcs: lineDef.arcs?.slice() ?? undefined,
    };
    this.material = material;
  }
}