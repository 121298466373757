import { IObjData } from "lib/models/objdata";

export enum SelectionActionType {
  SELECT,
  UNSELECT,
  UNSELECTALL,
  SELECT_ONE,
}

export type SelectionAction =
  | {
    type: SelectionActionType.SELECT;
    payload: {
      selected: IObjData[];
      unSelected: IObjData[];
      obj: IObjData[];
    };
  }
  | {
    type: SelectionActionType.UNSELECT;
    payload: {
      obj: IObjData[];
    };
  }
  | {
    type: SelectionActionType.UNSELECTALL;
    payload: {
      obj: IObjData[];
    };
  }
  | {
    type: SelectionActionType.SELECT_ONE;
    payload: {
      objData: IObjData;
    };
  };
