import { circleParam, circleBuffer } from "lib/geometries/circle";
import { lineBaseCreate } from "lib/geometries/line";
import { getCurrentLineMaterial, ILineMaterial } from "lib/materials";
import { normalizeAngle, eulerAnglesToAxisAngle } from "lib/math/angles";
import { mirrorPoint } from "lib/math/mirror";
import { copyIPoint, addIpoint } from "lib/math/point";
import { rotatePoint } from "lib/math/rotate";
import { scalePoint } from "lib/math/scale";
import { IPoint } from "lib/math/types";
import { objDataType } from "../types";
import { LineBaseData } from "./line-base";

export class CircleData extends LineBaseData {

  public type = objDataType.CIRCLE;
  protected nameObj: string = "Circle";
  public definition: circleParam;

  constructor(definition: circleParam, material?: ILineMaterial) {
    super();
    this.definition = {
      center: copyIPoint(definition.center),
      radius: definition.radius,
      azimutO: definition.azimutO,
      plane: copyIPoint(definition.plane),
    };
    this.material = material ?? getCurrentLineMaterial();
  }

  static createObj(definition: circleParam, material: ILineMaterial) {
    const buffer = circleBuffer(
      definition.center,
      definition.radius,
      definition.azimutO,
      definition.plane
    );
    return lineBaseCreate(buffer, material);
  }
  public createObject(definition?: circleParam, material?: ILineMaterial) {
    return CircleData.createObj(definition ?? this.definition, material ?? this.material);
  }
  public getBufferGeom(): Float32Array {
    return circleBuffer(
      this.definition.center,
      this.definition.radius,
      this.definition.azimutO,
      this.definition.plane
    );
  }

  public cloneDefinition(): circleParam {
    return {
      center: copyIPoint(this.definition.center),
      radius: this.definition.radius,
      azimutO: this.definition.azimutO,
      plane: copyIPoint(this.definition.plane),
    };
  }
  public translate(distance: IPoint) {
    this.definition.center = addIpoint(this.definition.center, distance);
    this.regenerateObjectFromDefinition();
  }
  public rotate(angleX: number, angleY: number, angleZ: number, basePoint: IPoint): void {
    angleX = normalizeAngle(angleX);
    angleY = normalizeAngle(angleY);
    angleZ = normalizeAngle(angleZ);

    this.definition.center = rotatePoint(
      this.definition.center,
      angleX,
      angleY,
      angleZ,
      basePoint
    );
    const { angle } = eulerAnglesToAxisAngle(angleX, angleY, angleZ);
    this.definition.azimutO = normalizeAngle(this.definition.azimutO - angle);
    this.regenerateObjectFromDefinition();
  }
  public scale(factorX: number, factorY: number, factorZ: number, basePoint: IPoint): void {
    this.definition.center = scalePoint(
      this.definition.center,
      factorX,
      factorY,
      factorZ,
      basePoint
    );
    this.definition.radius *= factorX;
    this.regenerateObjectFromDefinition();
  }
  public mirror(startPoint: IPoint, endPoint: IPoint): void {
    this.definition.center = mirrorPoint(
      this.definition.center,
      startPoint,
      endPoint
    );
    this.regenerateObjectFromDefinition();
  }
  public delete(): void {
    this.graphicObj.geometry.dispose();
  }
}
