import { updateAlignedDimension } from "lib/dimension/line-dim-builder";
import { getVertexFromIndex } from "lib/geometries/line";
import { lineAngle2p } from "lib/math/angles";
import { distancePointToLine3D, pointOnLine3D, vectorDist3D } from "lib/math/distance";
import { vector2Equals } from "lib/math/epsilon";
import { IPlane, intersectLinePlane } from "lib/math/plane";
import { getMiddlePoint, copyIPoint, getFactorLinePosition2p } from "lib/math/point";
import { rotatePointZ, rotatePointX, rotatePointY } from "lib/math/rotate";
import { IPoint } from "lib/math/types";
import { AlignedDimensionData, alignedDimensionParam } from "lib/models/dimension/aligned-dim";
import { cadOpType } from "../factory";
import { dimPlane, dimDirectionMode } from "./dimension-base";
import { EditDimensionOP } from "./dimension-base-edit";

export class EditAlignedDimensionOP extends EditDimensionOP<alignedDimensionParam> {

  public opType = cadOpType.EDITALIGNEDDIM;

  constructor(threeObj: AlignedDimensionData, vertex: number) {
    super(threeObj);
    this.vertexToEdit = vertex;
  }

  public moveLastPoint(point: IPoint) {

    this.dimPlane = this.newDimInfo.dimPlane;

    if (this.vertexToEdit === 3) {
      this.newDimInfo.objRef[0].point = point;

    } else if (this.vertexToEdit === 4) {
      this.newDimInfo.objRef[1].point = point;

    } else {
      this.calculateDimPlane();
      const pov: IPoint = this.graphicProcessor.getCursorCamera().position;
      const secondPointProj = intersectLinePlane(pov, point, this.currentPlane) as IPoint;

      if (this.vertexToEdit === 0) {
        this.editTextLabel(secondPointProj);
      } else {
        this.editDistanceLineBase(secondPointProj)
      }
    }
    const lineBase = { p1: this.newDimInfo.objRef[0].point, p2: this.newDimInfo.objRef[1].point };    
    updateAlignedDimension(this.objDataAux[0].graphicObj as THREE.Group, lineBase, this.dimStyle, this.dimPlane, this.newDimInfo.dimOrientation, this.txtStyle);
  }

  private currentPlane: IPlane;

  private calculateDimPlane() {
    const lineBase = { p1: this.newDimInfo.objRef[0].point, p2: this.newDimInfo.objRef[1].point };
    let v1 = lineBase.p1;
    let v2 = lineBase.p2;
    // Cálculo del punto del raycast en el plano de la acotación
    if (vector2Equals(v1, v2)) {
      // Línea completamente vértical
      const minZpoint = v1.z < v2.z ? v1 : v2;
      if (this.dimPlane === dimPlane.XY || this.dimPlane === dimPlane.XZ) {
        this.dimPlane = dimPlane.XZ;
        this.currentPlane = { p1: minZpoint, p2: { x: minZpoint.x, y: minZpoint.y, z: minZpoint.z + 50 }, p3: { x: minZpoint.x + 50, y: minZpoint.y, z: minZpoint.z } };
      } else {
        this.currentPlane = { p1: minZpoint, p2: { x: minZpoint.x, y: minZpoint.y, z: minZpoint.z + 50 }, p3: { x: minZpoint.x, y: minZpoint.y + 50, z: minZpoint.z } };
      }
    } else {
      if (this.dimPlane === dimPlane.XY) {
        const XYangle = lineAngle2p(v1, v2);
        const x1: number = v1.x + 50 * Math.cos(XYangle + Math.PI * 0.5);
        const y1: number = v1.y + 50 * Math.sin(XYangle + Math.PI * 0.5);
        this.currentPlane = { p1: v1, p2: v2, p3: { x: x1, y: y1, z: v1.z } };
      } else {
        this.currentPlane = { p1: v1, p2: v2, p3: { x: v2.x, y: v2.y, z: v2.z - 50 } };
      }
    }
  }
  private editTextLabel(secondPointProj: IPoint) {
    const v1 = getVertexFromIndex(this.dimGeometry, 6) as IPoint;
    const v2 = getVertexFromIndex(this.dimGeometry, 7) as IPoint;

    // const min = (this.textLabel.geometry.boundingBox as THREE.Box3).min;
    // const max = (this.textLabel.geometry.boundingBox as THREE.Box3).max;
    // const offsetX = (max.x - min.x);
    // const offsetXLimit = offsetX / vectorDist3D(v1, v2);

    const offsetX = 0; // this.textLabel.geometry.boundingBox?.max.x ?? 10;
    const offsetXLimit = 0; // offsetX / vectorDist3D(v1, v2);

    const vMiddle = getMiddlePoint(v1, v2);
    const auxPto = pointOnLine3D(v1, v2, secondPointProj, false)[0];
    const factor = getFactorLinePosition2p(v1, v2, auxPto) as number;

    if (factor > 1 + offsetXLimit) this.dimStyle.fontOffsetX = vectorDist3D(v1, v2) * 0.5 + offsetX;
    else if (factor >= 0.5 && factor < 1 + offsetXLimit) this.dimStyle.fontOffsetX = vectorDist3D(vMiddle, auxPto);
    else if (factor < 0.5 && factor > - offsetXLimit) this.dimStyle.fontOffsetX = - vectorDist3D(vMiddle, auxPto);
    else if (factor < - offsetXLimit) this.dimStyle.fontOffsetX = - (vectorDist3D(v1, v2) * 0.5 + offsetX);
  }
  private editDistanceLineBase(secondPointProj: IPoint) {
    const lineBase = {
      p1: this.newDimInfo.objRef[0].point,
      p2: this.newDimInfo.objRef[1].point,
    };
    // Calculo de la distancia
    this.dimStyle.distBaseLine = distancePointToLine3D(lineBase.p1, lineBase.p2, secondPointProj, false);

    const projSegment = { p1: copyIPoint(this.currentPlane.p1), p2: copyIPoint(this.currentPlane.p2) };
    // GIRAMOS GEOMETRIA AL PLANO XY (SOLO PLANOS XZ, YZ, DEF)
    if (this.dimPlane !== dimPlane.XY) {
      const centerPoint = getMiddlePoint(projSegment.p1, projSegment.p2);
      if (!vector2Equals(projSegment.p1, projSegment.p2)) {
        const XYangle = lineAngle2p(projSegment.p1, projSegment.p2);
        projSegment.p1 = rotatePointZ(projSegment.p1, -XYangle, centerPoint);
        projSegment.p1 = rotatePointX(projSegment.p1, -(Math.PI * 0.5), centerPoint);
        projSegment.p2 = rotatePointZ(projSegment.p2, -XYangle, centerPoint);
        projSegment.p2 = rotatePointX(projSegment.p2, -(Math.PI * 0.5), centerPoint);
        secondPointProj = rotatePointZ(secondPointProj, -XYangle, centerPoint);
        secondPointProj = rotatePointX(secondPointProj, -(Math.PI * 0.5), centerPoint);
      } else {
        const rotateFunc = this.dimPlane === dimPlane.XZ ? rotatePointX : rotatePointY;
        rotateFunc(projSegment.p1, -(Math.PI * 0.5), centerPoint);
        rotateFunc(projSegment.p2, -(Math.PI * 0.5), centerPoint);
        rotateFunc(secondPointProj, -(Math.PI * 0.5), centerPoint);
      }
    }
    this.newDimInfo.dimOrientation = this.getDimOrientation(dimDirectionMode.DEF, secondPointProj, projSegment);

    if (this.dimStyle.blockDistBaseLine) {
      // this.dimStyle.distBaseLine = calculateBlockDistance(lineBase, this.threeObjOri[0], this.dimStyle, this.dimPlane, this.orientation, this.tre);
    } else {
      if (this.dimStyle.distBaseLine < this.dimStyle.minDistBaseLine1)
        this.dimStyle.distBaseLine = this.dimStyle.minDistBaseLine1;
    }
  }

}
