import { useForm } from "react-hook-form";
import { useCallback } from "react";
import { useAuth } from "../context";

interface FormData {
  username: string;
  password: string;
}
export default function useSignIn() {
  const { signIn, loading, error } = useAuth();
  const { handleSubmit, register } = useForm<FormData>({
    shouldFocusError: true,
  });

  const onValid = useCallback(
    ({ username, password }: FormData) => {
      signIn(username, password);
    },
    [signIn]
  );
  return {
    handleSubmit: handleSubmit(onValid),
    ref: register,
    loading,
    error,
  };
}
