/**
 * \file graphic-processor.ts
 * Aqui implementaremos las clases GraphicProcessor, encargada de encapsular los procesamientons graficos basicos que caen
 * sobre Three.js, que a su vez se auxilia de una clase estatica GraphicProcessorManager que sera el gestor que lleve el control
 * y acceso de los multiples procesadores graficos que vayamos usando.
 *
 * Convenio de TS: Un dmc #name es tomado automaticamente como private.
 * Esto tiene un pequeño inconveniente: Al depurar ese nombre no aparece como tal sino __private_numero_LaVariableOriginal.
 * Ademas parece ser que la infraestructura profunda derivada de eso obliga a un tratamiento menos eficiente; por todo ello
 * decido cambiar el convenio #variable por _variable.
 */

import * as THREE from "three";

// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

// Estos son los nuevos controles del japones Akihiro Oyamada aka Yomotsu. Sustituyen a los OrbitControls originales de
// Three.js que desaparecen de nuestro codigo. Han sido adaptados de:
// https://github.com/yomotsu/camera-controls
import { CameraControls } from "./helpers/camera-controls/CameraControls"

import { TransformControls } from "three/examples/jsm/controls/TransformControls";
import Stats from "three/examples/jsm/libs/stats.module.js";
import { SceneManager } from "./layers/scene-manager";
import { getViewPosition, PlaneManager, refViewPlane } from "./coordinates/plane-manager";
import { IPoint } from "./math/types";
import { exportGLTF, importGLTF } from "./input-output/loaders/gltf";
import { ViewportAction, ViewportActionType } from './events/viewports';
import { SelectionManager } from "./selection/selection-manager";
import { DataModelManager } from "./models/datamodel-manager";
import { cadOpType, OpFactory } from "./operations/factory";
import { setMouseControls } from './mouse-settings';
import { clearXyzGyzmoSharedcomponents, XYZmo } from "./helpers/xyzmo";
import { isEqual } from "./math/epsilon";
import { distancePointToLine3D, vectorDist3D } from "./math/distance";

// Gestion del compositor de efectos graficos especiales FX.
import { composerFX } from "./helpers/composer-fx";
import { CoordinateResolver } from "./coordinates/coordinate";
import { scuMainGrid } from "./scu-grid";
import { StructuralModelManager } from "./models-struc/model-managers/structuralmodel-manager";
import { ProjectModelManager } from "./models-struc/model-managers/projectmodel-manager";
import { isStrucProject, StrucProject } from "modules/struc/models/project";
import { GraphicViewport, ViewportType, ViewportTypeTxt } from "./graphic-viewport";
import { GraphicViewportManager } from "./graphic-viewport-manager";
import { SelectCacheThreeMaterial } from "./selection/selection-material-cache";
import { DispatcherEvent } from "./helpers/camera-controls/EventDispatcher";
import { GraphicProcessorManager } from "./graphic-processor-manager";
import { materialCache } from "./materials";
import { MemoryCentinel } from "./helpers/memory-centinel";
import { LayerManager } from 'lib/layers/layer-manager';
import { EventBus, ObserverManager } from './events/event-bus';
import { CADProject } from "modules/cad/models/projects";
import { XYZmo_RCOH8 } from "./helpers/xyzmo_rcoh8";
import { visibleHeightAtZDepth } from "./helpers/camera";
import { IObjData } from './models/objdata';
import { CommandInvoker } from "./commands/undo-redo";
import { ICommand } from "./commands/base";
import { Cad3dOp } from "./operations/base";
import { UniqueFixedIArray } from "./helpers/arrays";
import { operationAction } from './events/operation';
import { hypothesisManager } from "./models-struc/hypothesis/hypothesismodel-manager";
import { StructModel3D } from "./helpers/structmodel3d";
import { ModelDataImporter } from "./input-output/database/data-model-import";
import { AddObjDatasToLayer } from "./models-struc/application/objdata-add-to-layer";
import { RemoveObjDataFromLayer } from "./models-struc/application/objdata-remove-from-layer";
import { RaycasterModel } from "./coordinates/raycaster";

// Esto es un enganche OBLIGATORIO y necesario para la correcta conexion entre ambas librerias.
CameraControls.install({ THREE: THREE });

export interface canvasOptions {
  showthreeStat?: boolean;
  showXyzGyzmo?: boolean;
  showMainGrid?: boolean;
  camera?: "perspective" | "ortographic",
  cameraView?: refViewPlane;
  activeControls?: boolean;
}

export type CameraSettings = any;


/**
 * Clase procesador grafico: estructura y encapsula todas las tareas de rendering y visualizacion grafica que por debajo
 * efectua Three.js. Es gestionado automaticamente por su clase asociada GraphicProcessorManager.
 *
 * @export
 * @class GraphicProcessor
 */
export class GraphicProcessor {
  /**
   * Todo GPO (Graphic Processor Object) debe siempre tener un nombre unico que lo identifique, que le sera dado en su
   * construccion y que no cambiara. Accesible RO por la propiedad name.
   */
  _name: string = "";
  get name(): string {
    return this._name;
  }

  /**
   * El gestor de todos los viewports, accesible RO mediante la propiedad managerVP.
   */
  _mngrVP: GraphicViewportManager;
  get managerVP(): GraphicViewportManager {
    return this._mngrVP;
  }

  /**
   * Cuando este dmc es true entonces la vista principal, no ningun viewport, es quien tiene el control de entrada activo.
   * Es decir que el raton esta encima de ella y NO encima de ningun viewport, por lo tanto solo estaria activo el cameraControl
   * de esta vista principal (e inactivos todos los camControls de los posibles viewports existentes).
   */
  _onlyMainView: boolean;

  /**
   * Con este dmc podemos activar o desactivar el rendering de la vista principal. Inicialmente vale false hasta que lleguemos
   * al instante en que se "monte" el graphicProcessor, que automaticamente pasara a valer true.
   * Esto sera muy util cuando tenemos varios viewports que se solapan y no dejan sitio para que se pinte la vista principal,
   * como pretendemos en la configuracion cuadruple de Rhinoceros. Activable desde la fmc renderMainView(), que en caso de no
   * dar el parametro sirve como accesor de lectura.
   */
  _renderMainView: boolean;
  public renderMainView(onOff?: boolean): boolean {
    if (onOff !== undefined) {
      this._renderMainView = onOff;
      this._perspControls.enabled = this._orthoControls.enabled = onOff;
      // Puede ser posible que al reactivarlos sea necesario algun update???.
      if (!onOff) {
        // Asi nos aseguramos de que no quede nada en pantalla, que podria ser engañoso.
        this._renderer.clear();
        console.log("Deshabilitada la mainView.");
      } else {
        console.log("Habilitada la mainView.");
      }
    }
    return this._renderMainView;
  }

  /**
   * Tenemos camara de perspectiva para movernos libremente y camara ortografica para las 6 vistas laterales predefinidas.
   * La camara activa actualmente es accesible en RO con getCursorCamera().
   * \ToDo: Posiblemente tengamos arrays de camaras mas adelante...
   */
  _perspCamera: THREE.PerspectiveCamera;
  _orthoCamera: THREE.OrthographicCamera;

  /** Devuelve la camara activa al exterior que ahora puede ser o bien la de perspectiva o bien la ortografica o la que se
   * este utilizando en el posible viewport activo en curso.
   */
  public getCursorCamera(): THREE.Camera {
    const viewPort = this.getActiveViewport();
    if (viewPort?.camera) {
      return viewPort.camera;
    }
    if (this._onlyMainView) {
      // La mayor parte del tiempo la accion sucedera solamente con la vista principal activa, sin viewports.
      return this._isActivePerspective ? this._perspCamera : this._orthoCamera;
    } else {
      // Pero a veces podria haber varios viewports, que incluso podrian solaparse y dejar vision parcial de la mainView.
      // Asi que devolveremos la camara en curso del viewport en curso.
      if (this._activeViewport === this._mainViewport) {
        return this._isActivePerspective ? this._perspCamera : this._orthoCamera;
      } else {
        // Sacamos la camara del propio viewport, que NO es la mainView.
        const camera4VP = this._activeViewport.camera;
        if (!camera4VP) {
          console.error("ERROR: El viewport activo siempre debiera tener su propia camara.");
        }
        return camera4VP as THREE.Camera;
      }
    }
  }
  /** Devuelve el tamaño correspondiente a x pixeles en un punto del espacio
   *
   * @export
   * @param {number} size
   * @param {IPoint} position
   * @returns
   */
  public getSizeUnitFromPixelUnit(size: number, position: IPoint, camera?: THREE.Camera) {
    if (camera === undefined) {
      camera = this.getCursorCamera();
    }
    if (camera.type === "PerspectiveCamera") {
      const depth = vectorDist3D(position, camera.position);
      const H = visibleHeightAtZDepth(depth, camera as THREE.PerspectiveCamera);
      const { y } = this._renderer.getDrawingBufferSize(new THREE.Vector2());
      return size * H / y;
    } else {
      const zoom = (camera as THREE.OrthographicCamera).zoom;
      return size / zoom;
    }
  }
  /** Calculate pixel coordinate from [-1,1] viewport size
   *
   * @private
   * @param {number} vectorx [-1,1] width position
   * @param {number} vectory [-1,1] height position
   * @returns
   * @memberof SelectionBox
   */
  public vectorToScreenCoord(vectorx: number, vectory: number) {
    const viewPort = this.getActiveViewport();
    const rect = viewPort.elemHTML.getBoundingClientRect();
    const width = rect.width;
    const height = rect.height;

    const xp = Math.round((vectorx + 1) * width / 2);
    const yp = Math.round(- (vectory - 1) * height / 2);
    const xx = (2 * xp) / rect.width - 1;
    const yy = 1 - (2 * yp) / rect.height;
    return { x: xx, y: yy, z: 0 };
  }

  /**
   * Los nuevos controles de posicionamiento de camaras en modos perspectiva y ortografico.
   * Los controles parece que dan problemas al cambiar en caliente de camara, asi que tendre uno para cada camara. Y solo uno activo.
   */
  _perspControls: CameraControls;
  _orthoControls: CameraControls;

  /**
   * La posicion previa de la camara de perspectiva, sacada de su control de movimiento. La necesitamos para hacer mas facil el acercamiento
   * a los puntos destino a medida que nos vayamos acercando.
   * Tambien almacenamos la posicion previa del target o point of view (POV) al que apunta la camara de perspectiva asi como la posicion
   * previa del cursor del raton.
   */
  _prevPerspCamPosition: THREE.Vector3;
  _prevTargetPosition: THREE.Vector3;
  _prevCursorPosition: THREE.Vector3;

  /**
   * Unos listeners centralizados para poder seguir la pista a lo que hacen loa controles de camara para la de perspectiva.
   * Estos son los 6 eventos de CameraControls que procesaremos.
   * [1] 'controlstart': Cuando el usuario comienza a controlar la camara por medio del raton o de touches.
   * [2] 'control': Cuando el usuario controla la camara (dragging).
   * [3] 'controlend': Cuando el usuario finaliza el control de la camara.
   * [4] 'update': Cuando la posicion de la camara es actualizada.
   * [5] 'wake': Cuando la camara comienza a moverse.
   * [6] 'sleep': Cuando la camara finaliza su movimiento.
   * Al estar centralizados tenemos simplificado su enganche y desenganche como listeners.
   */
  readonly _controlstartEvntLstnr = () => {
    console.error("controlstart --------------------------------------------------");

    const cameraControls = this._perspControls;
    const action = cameraControls.currentAction;
    if (action === CameraControls.ACTION.NONE) {
      return;
    } else {
      console.log(" Action: " + action);
    }

    switch (action) {
      case CameraControls.ACTION.ROTATE:
      case CameraControls.ACTION.TOUCH_ROTATE: {
        console.log("[1#] ROTATE " + action);
        // Parece que esto podria variar el estilo del cursor...
        // this._renderer.domElement.classList.add('-dragging');
        break;
      }

      case CameraControls.ACTION.TRUCK:
      case CameraControls.ACTION.TOUCH_TRUCK: {
        console.log("[2#] TRUCK " + cameraControls.currentAction);
        // this._renderer.domElement.classList.add('-moving');
        break;
      }

      case CameraControls.ACTION.DOLLY:
      case CameraControls.ACTION.ZOOM: {
        console.log("[3#] DOLLY " + cameraControls.currentAction);
        // this._renderer.domElement.classList.add('-zoomIn');
        break;
      }

      case CameraControls.ACTION.TOUCH_DOLLY_TRUCK:
      case CameraControls.ACTION.TOUCH_ZOOM_TRUCK: {
        console.log("[4#] DOLLY+TRUCK " + cameraControls.currentAction);
        // this._renderer.domElement.classList.add('-moving');
        break;
      }

      default: {
        console.log("[D#] " + cameraControls.currentAction);
        break;
      }
    }
  };

  readonly _controlEvntLstnr = () => {
    console.log("control ------------------------");
    const cameraControls = this._perspControls;
    const action = cameraControls.currentAction;
    if (action !== CameraControls.ACTION.NONE) {
      console.log(" Action: " + action);
    }
  };

  readonly _controlendEvntLstnr = () => {
    console.log("controlend ------------------------");
    const cameraControls = this._perspControls;
    const action = cameraControls.currentAction;
    if (action !== CameraControls.ACTION.NONE) {
      console.log(" Action: " + action);
    }
    // this._renderer.domElement.classList.remove('-dragging', '-moving', '-zoomIn');
  };

  /**
   * A este callback se le llama siempre que el controlador de movimiento de la camara de perspectiva principal debe efectuar
   * algun tipo de actualizacion. La putada es que no tenemos una forma rapida de distinguir si se esta haciendo un pan, un
   * dolly, etc...
   *
   * Otra hijoputez del JS: Las lambda o arrow functions no tienen parametro arguments implicito.
   *
   * @memberof GraphicProcessor
   */
  readonly _updateEvntLstnr = (ev: DispatcherEvent): void => {
    // La putada aqui es que la accion siempre es NONE, aunque estes dandole al dollyZoom.
    // Asi que no hay forma de saber directamente cuando estas en un zoom, salvo el truco de mas abajo...
    // Como siempre, se puede sacar el cameraControl implicado directamente del evento asi...
    const cameraControls = ev.target;

    // Hay que jugar con 3 posiciones: La de la camara[1], la del target[2] (siempre en el centro de pantalla) y la del cursor[3].

    // [1] Esta es la posicion de la camara, con coincidencia total entre lo que me dice el control y lo que me dice la camara.
    const pos = cameraControls.getPosition(new THREE.Vector3());

    // [2] Posicion del point of view aka target sacado del control.
    const target = new THREE.Vector3();
    cameraControls.getTarget(target);

    // [3] Posicion 3D del cursor del raton, que es hacia donde queremos ir moviendo el target.
    const { x, y, z } = this.getMouseCoordinates();
    const cursor = new THREE.Vector3(x, y, z);

    // Las 3 distancias: De la camara a target y a cursor y la distancia target-cursor.
    const distCam2Target = pos.distanceTo(target);
    const distCam2Cursor = pos.distanceTo(cursor);
    // \ToDo: Usando esto se podria afinar el acercamiento, haciendolo mas liso y continuo???...
    // const distTarget2Cursor = target.distanceTo(cursor);

    // Se supone que esto es lo mismo EXACTAMENTE, pero a veces hay ciertas desviaciones.
    // if (!isEqual(cameraControls.distance, distCam2Target)) {
    //   console.error("\tATENCION: " + cameraControls.distance.toFixed(3) + " != " + distCam2Target.toFixed(3));
    // }

    const updateValues4NextCalling = () => {
      this._prevPerspCamPosition.x = pos.x;
      this._prevPerspCamPosition.y = pos.y;
      this._prevPerspCamPosition.z = pos.z;
      this._prevTargetPosition.x = target.x;
      this._prevTargetPosition.y = target.y;
      this._prevTargetPosition.z = target.z;
      this._prevCursorPosition.x = cursor.x;
      this._prevCursorPosition.y = cursor.y;
      this._prevCursorPosition.z = cursor.z;
    };

    // Las 3 distancias a las posiciones previas de camara, target y cursor.
    const dist2PrevPos = pos.distanceTo(this._prevPerspCamPosition);
    const dist2PrevTarget = target.distanceTo(this._prevTargetPosition);
    const dist2PrevCursor = cursor.distanceTo(this._prevCursorPosition);

    // La mayor parte de las veces esta optimizacion es valida???...
    // if (dist2PrevPos <= 0.0001) {
    //   return;
    // }

    const zero2PrevPos = isEqual(dist2PrevPos, 0, 0.001);
    const zero2PrevTar = isEqual(dist2PrevTarget, 0, 0.001);
    const zero2PrevCur = isEqual(dist2PrevCursor, 0, 0.001);

    if (zero2PrevPos && zero2PrevTar) {
      // console.error("PANNING OR ROTATING!!!");
      updateValues4NextCalling();
      return;
    } else {
      if (!zero2PrevPos && !zero2PrevTar && zero2PrevCur) {
        // console.error("DOING ZOOM!!!");
      } else {
        // console.error("???");
        updateValues4NextCalling();
        return;
      }
    }

    // La direccion a la que mira la camara, siempre unitaria.
    const dirCam2Tar = cameraControls.getCamera().getWorldDirection(new THREE.Vector3());

    // const seeP = (txt: string, p: number): string => {
    //   const res = " " +txt + ":" + p.toFixed(3) + " ";
    //   return res;
    // };

    // Con esto salimos al detectar un zoomOut.
    if (true) {
      // El vector de avance desde la posicion anterior a la actual de la camara.
      const dirCamMovement = new THREE.Vector3();
      dirCamMovement.subVectors(pos, this._prevPerspCamPosition).normalize();
      // Lo comparamos con la direccion de la camara para ver si vamos para adelante o para atras. Simplemente la distancia.
      const distXYZ = dirCam2Tar.distanceTo(dirCamMovement);
      // console.log(seeP("d", distXYZ));
      if (distXYZ > 1.0) {
        // Nos salimos por hacer un zoomOut e ir en direccion contraria.
        // console.clear();
        // console.log("ZOOM OUT");
        updateValues4NextCalling();
        return;
      }
    }

    // if (true) {
    //   const seeV = (txt: string, v: THREE.Vector3): string => {
    //     const res = " " + txt + "(" + v.x.toFixed(3) + ", " + v.y.toFixed(3) + ", " + v.z.toFixed(3) + ") ";
    //     return res;
    //   };

    //   let msg = "POS ";
    //   msg += seeV("   Cam", pos) + seeP("inc", dist2PrevPos);
    //   msg += seeV("   Tar", target) + seeP("inc", dist2PrevTarget);
    //   msg += seeV("   Kur", cursor) + seeP("inc", dist2PrevCursor);
    //   console.log(msg);
    //   msg = "DIST ";
    //   msg += seeP("   Cam2Tar", distCam2Target) + seeP("   cam2Kur", distCam2Cursor) + seeP("   tar2Kur", distTarget2Cursor);
    //   console.log(msg);
    // }

    if (false) {
      let pointer: THREE.Object3D | undefined = undefined;
      if (true) {
        // En esta bola amarilla tenemos el target actual.
        const name = "targetBall";
        // Esto lo colocamos en la escena principal ya que en otras no se ve.
        // \ToDo: Convertirlo en unos ejes como en el BimVision.
        const scene = this.getMainScene(); // this._layerManager.auxScene;
        pointer = scene.getObjectByName(name);
        if (!pointer) {
          this.addDebugBall(target.x, target.y, target.z, 10, name, 0xFFFF00, scene, true);
          pointer = scene.getObjectByName(name);
        }
        pointer?.position.set(target.x, target.y, target.z);
      }
      if (true) {
        // En esta bola violacea tenemos el cursor 3D del raton.
        const name = "cursorBall";
        const scene = this.getMainScene();
        pointer = scene.getObjectByName(name);
        const { x, y, z } = this.getMouseCoordinates();
        if (!pointer) {
          pointer = this.addDebugBall(x, y, z, 10, name, 0xFF00FF, scene, true);
        } else {
          pointer?.position.set(x, y, z);
        }
      }
      if (false) {
        const name = "aXes";
        // Esto lo colocamos en la escena principal ya que en otras no se ve.
        const scene = this.getMainScene();
        pointer = scene.getObjectByName(name);
        if (!pointer) {
          pointer = this.addDebugAxes(pos, dirCam2Tar, 1, 2, name, scene);
        } else {
          pointer?.position.set(target.x, target.y, target.z);
        }
      }
    }

    if (true) {
      // console.log("ZOOM IN!!!: Prolongamos target.");

      // De momento colocamos el target en la linea de vision, pero a la distancia del cursor.
      const t = new THREE.Vector3();
      // La mejor solucion: Colocar el target en su linea actual pero a la misma distancia a la que esta el cursor POV.
      // const step = distCam2Cursor;
      // Asi parece que va un poco mejor, pues el near molestaria menos...
      const step = 0.9 * distCam2Cursor;
      // Con el minimo se queda pegado.
      // const step = Math.min(distCam2Cursor, distCam2Target);
      // Con el maximo se atraviesa el objetivo demasiado rapidamente.
      // const step = Math.max(distCam2Cursor, distCam2Target);
      t.x = pos.x + step * dirCam2Tar.x;
      t.y = pos.y + step * dirCam2Tar.y;
      t.z = pos.z + step * dirCam2Tar.z;
      // Si intentamos colocar el target a la mitad con el cursor se producen molestos saltos. Asi que dejamos la cosa como esta.
      // t.x += cursor.x;
      // t.y += cursor.y;
      // t.z += cursor.z;
      // t.x *= 0.5;
      // t.y *= 0.5;
      // t.z *= 0.5;
      cameraControls.setTarget(t.x, t.y, t.z, true);

      updateValues4NextCalling();
      return;
    }

    if (distCam2Target < 1.0) {
      // Al llegar aqui hay que tener en cuenta las otras distancias para ver exactamente que hacer...
      console.log("MAS ZOOOOOOOOM!!!");

      const dir2Cursor = new THREE.Vector3();
      dir2Cursor.subVectors(pos, cursor).normalize();

      console.log("\tDistancia a cursor: " + distCam2Cursor.toFixed(3));

      // La distancia entre la camara y el target es inferior a un metro. Tenemos posibles puntos de vista (POV):
      // [1] Podemos usar el target en curso prolongandolo cierta cantidad en la direccion en la que apunta la camara.
      // [2] Podemos usar el punto 3D al que apunta el puntero del raton, que a veces presenta problemas pues se va a
      // tomar por el culo...
      const extreme = pos.clone();
      extreme.addScaledVector(dirCam2Tar, 100);
      // Esta es la distancia perpendicular del cursor a la linea de vision actual de la camara.
      const distPerpendicular2Cursor = distancePointToLine3D(pos as IPoint, extreme as IPoint, cursor as IPoint);
      const dist2 = distancePointToLine3D(pos as IPoint, extreme as IPoint, target as IPoint);

      console.log("\tDistancias laterales cursor/target: " + distPerpendicular2Cursor.toFixed(3) + "   " + dist2.toFixed(3));
      const distCursorTarget = target.distanceTo(cursor);
      console.log("Distancia target-cursor: " + distCursorTarget.toFixed(3));
      console.error("======================================================================");

      if (0.001 < distPerpendicular2Cursor && distPerpendicular2Cursor <= 1000.0) {
        console.log("Opt[1]");
        // Directamente tiramos a la posicion actual del cursor, que tomamos como valida.
        cameraControls.setTarget(cursor.x, cursor.y, cursor.z);
        // Cogemos la posicion intermedia entre el cursor y el target.
        // cameraControls.setTarget(0.5 * (cursor.x + t.x), 0.5 * (cursor.y + t.y), 0.5 * (cursor.z + t.z));
      } else {
        console.log("Opt[2]");
        // Aqui tenemos que colocar el target en la direccion de avance actual, pero a cierta distancia por delante.
        // No podemos usar esto ya que tiembla la imagen.
        // cameraControls.forward(1.0);
        // Asi que simplemente posicionamos el target cierta distancia por delante en la posicion de vision de la camara.
        const step = distCam2Cursor;
        // t.x += step * dir.x;
        // t.y += step * dir.y;
        // t.z += step * dir.z;
        target.x = pos.x + step * dirCam2Tar.x;
        target.y = pos.y + step * dirCam2Tar.y;
        target.z = pos.z + step * dirCam2Tar.z;

        cameraControls.setTarget(target.x, target.y, target.z, true);
      }
      // (ball as THREE.Object3D).visible = false;
    }

    updateValues4NextCalling();
  };

  readonly _wakeEvntLstnr = () => {
    console.log("wake ------------------------");
    const cameraControls = this._perspControls;
    const action = cameraControls.currentAction;
    if (action !== CameraControls.ACTION.NONE) {
      console.log(" Action: " + action);
    }
  };

  readonly _sleepEvntLstnr = () => {
    console.log("sleep ------------------------");
    const cameraControls = this._perspControls;
    const action = cameraControls.currentAction;
    if (action !== CameraControls.ACTION.NONE) {
      console.log(" Action: " + action);
    }
  };


  /**
   * Para activar una u otra camara (EXOR). Asi que si no esta activa la de perspectiva estara la ortografica.
   * \ToDo: Cuando tengamos varias camaras (mas de 2) habra que tener un indice o algo similar equivalente a esto.
   */
  _isActivePerspective: boolean;

  /**
   * Objeto de Three que renderiza lo que sea. Accesible RO desde el exterior con getRenderer().
   * \ToDo: Habra varios posiblemente...
   */
  _renderer: THREE.WebGLRenderer;
  public getRenderer(): THREE.WebGLRenderer {
    return this._renderer;
  }

  /**
   * Esto es el canvas/container incorporado externamente, asi como su anchura y su altura (accesibles solo RO).
   * Es unico, pero jugando con el renderer generaremos varios canvas virtuales (vCanvas) aka viewports.
   * Aunque es unico, puede contener diferentes elementos HTML que se comportaran como viewports.
   */
  _container: HTMLDivElement;
  get container(): HTMLDivElement {
    return this._container;
  }
  _width: number;
  _height: number;
  get width(): number {
    return this._width;
  }
  get height(): number {
    return this._height;
  }

  /**
   * Tambien necesitaremos su posicion para poder hacer ciertos calculos (rayCasting en gizmos, etc...).
   */
  _left: number;
  _top: number;

  // ---------------------------------------------------------------------

  /**
   * Gestor de capas de la escena principal. Accesible RO desde el exterior mediante getLayerManager().
   * \ToDo: Si va a haber varias escenas entonces tendremos varios gestores...
   */
  _sceneManager: SceneManager;
  public getSceneManager(): SceneManager {
    return this._sceneManager;
  }
  public getAuxScene(): THREE.Scene {
    return this._sceneManager.auxScene;
  }
  public getHelperScene(): THREE.Scene {
    return this._sceneManager.helperScene;
  }
  public getSelectionScene(): THREE.Scene {
    return this._sceneManager.selectionScene;
  }
  public getMainScene(): THREE.Scene {
    return this._sceneManager.mainScene;
  }

  // ---------------------------------------------------------------------

  /** Gestor de los datos "reales", aquellos del mundo exterior que son independientes de los datos graficos.
   * Usaremos DM para referirnos a estos datos del Data Model.
   */
  private _dataModelManager: DataModelManager;

  public getDataModelManager(): DataModelManager {
    return this._dataModelManager;
  }
  public getLayerManager(): LayerManager {
    return this._dataModelManager.layerManager;
  }
  public addToLayer(data: IObjData, layerId: string) {
    AddObjDatasToLayer.run(data, layerId, this);
  }
  public removeFromLayer(data: IObjData) {
    RemoveObjDataFromLayer.run(data, this);
  }

  // ---------------------------------------------------------------------

  _selectionManager: SelectionManager;
  public getSelectionManager(): SelectionManager {
    return this._selectionManager;
  }
  public unSelectObjData(data: IObjData) {
    const selecMng = this._selectionManager;
    selecMng.unSelectObjData(data);
  }
  public getSelectedObjs() {
    return this._selectionManager.selectedObjs;
  }
  public unselectAll() {
    this._selectionManager.unselectAll();
  }

  // ---------------------------------------------------------------------

  public currentOp: Cad3dOp | null = null;
  public lastOpStack = new UniqueFixedIArray<{ type: cadOpType; args: any[] }>(10);
  public operationObserver: ObserverManager<operationAction>;

  public getOpName(type: cadOpType) {
    return OpFactory.getPubOpName(type);
  }
  public launchOP<T extends typeof Cad3dOp>(optype: cadOpType, args?: ConstructorParameters<T>) {
    return OpFactory.createOP<T>(optype, this, args);
  }
  public finishOP() {
    OpFactory.finishOP(this);
  }
  public cancelOP() {
    OpFactory.cancelOP(this);
  }

  // ---------------------------------------------------------------------

  /** Uno/Redo stack */
  _undoRedoStack: CommandInvoker;
  public getCommandInvoker() {
    return this._undoRedoStack;
  }
  public async storeAndExecute(com: ICommand) {
    await this._undoRedoStack.storeAndExecute(com);
  }
  public undo(steps: number = 1): void {
    this._undoRedoStack.undo(steps);
  }
  public redo(steps: number = 1): void {
    this._undoRedoStack.redo(steps);
  }

  // ---------------------------------------------------------------------

  // Graphic structural data model

  _structuralModelManager: StructuralModelManager;
  public getStructuralModelManager(): StructuralModelManager {
    return this._structuralModelManager;
  }
  // Structural model
  _projectModelManager: ProjectModelManager;
  public getProjectModelManager(): ProjectModelManager {
    return this._projectModelManager;
  }
  // ---------------------------------------------------------------------

  /**
   * Gestor del sistema de coordenadas y planos.
   */
  _coordinateSystemManager: PlaneManager;
  public getPlaneManager(): PlaneManager {
    return this._coordinateSystemManager;
  }

  /** Manejador de la posición del ratón (world coordinates) and raycast results
   *
   * @memberof GraphicProcessor
   */
  _mouseResolver: CoordinateResolver;

  public getMouseResolver(): CoordinateResolver {
    return this._mouseResolver;
  }
  public getMouseCoordinates(): IPoint {
    return this._mouseResolver.mouseCoordinates;
  }
  public getRaycaster(): RaycasterModel {
    return this._mouseResolver.raycaster;
  }
  public getRayCastObjects() {
    return this._mouseResolver.raycaster.raycastedObject;
  }

  // ---------------------------------------------------------------------

  /** El planeManager necesita informacion sobre el tipo de plano de referencia usado en funcion de la camara.
   * Asi que solo por esto tendremos este dmc accesible mediante la fmc getMainViewDefinition().
   * Atencion: Este viewport tiene a _container como elemento HTML.
   * Y como "tecnicamente" no es un vp al uso, no estara contenido nunca en this._vViewports (ni en this._vViewports2Delete).
   */
  _mainViewport: GraphicViewport;

  public getMainViewDefinition(): GraphicViewport {
    return this._mainViewport;
  }

  /** Este es el viewport (o la vista principal) que tiene el foco en cada instante y esta activo.
   * Obviamente estara enabled... Pero el activo/enfocado SOLO es uno en cada instante.
   * Accesible desde el exterior mediante la fmc getActiveViewport().
   * Recuerda que SIEMPRE hay uno activo.
   * Ademas centralizare su asignacion mediante la fmc setActiveViewport() para tener controlados los eventos en una
   * unica zona de codigo, asi que NO asignarlo nunca directamente.
   * Realmente la responsabilidad cae sobre el manager de GVP's que es el que hace el curro.
   */
  private _activeViewport: GraphicViewport;

  // ViewPort Events Handler (Observer)
  public viewportObserver: ObserverManager<ViewportAction>;

  // ---------------------------------------------------------------------

  /**
   * Para determinar si ha sido "montado" desde la parte exterior con la llamada a mount() y esta listo para funcionar.
   */
  public _isMounted = false;

  _transformControl: TransformControls;

  /**
   * Gestor de las estadisticas graficas de Three.js, colocado en la esquina superior derecha, con FPS, uso de memoria, etc...
   */
  _stats: Stats;

  /**
   * El numero de iteraciones efectuadas desde que el renderer se inicializo: llamadas a la fmc render().
   * Si todo va bien se harian 60 iteraciones por segundo en una maquina normal.
   * Lo puedo usar para activar automaticamente ciertas pruebas.
   */
  _numIterations: number;

  /**
   * El gizmo o control de rotacion 3D que aparecera en la esquina superior derecha (a la autoCad).
   */
  _gizmo: XYZmo;

  /**
   * El grid helper infinito, que lo pongo accesible directamente para no tener que buscarlo en la escena.
   * Se accede a activarlo o desactivarlo mediante la fmc activateInfinitumGridHelper() que cuando no se dan
   * parametros devuelve el estado actual de visibilidad, y si se dan parametros se visibiliza o invisibiliza.
   */
  _infinitumGH: scuMainGrid;
  get scuGrid() { return this._infinitumGH; }


  /** El centinela de la memoria. */
  public memoryCentinel: MemoryCentinel;

  /**
   * Reloj necesario para las temporizaciones en los updates (ahora obligatorios) de los CameraControls.
   * Es necesario para las nuevas virguerias que nos dan los CameraControls de Yomotsu que requieren ser alimentados con tiempos
   * necesarios para el calculo de transiciones y otras maldades...
   */
  public _clock: THREE.Clock;

  // ___________________________________________Inicio_DMC's_FX_tipo_HALO_LASER_en_seleccion________________________________

  private _composerFX: composerFX;

  public getComposerFX() { return this._composerFX; }

  // ___________________________________________Inicio_DMC's_para_la_visibilidad_en_modo_GreyStruc_(triple)_________________

  /**
   * Flag para saber cuando estamos en el modo de funcionamiento GreyStruc (acabado en c). Este es el modo en el que tendremos
   * vista triple del modelo en 3 viewports con los nombres "storeys" [0], "plan" [1] y "elevation" [2] respectivamente.
   *
   * @type {boolean}
   * @memberof GraphicProcessor
   */
  _isGreyStrucMode: boolean;

  /**
   * Esto se encarga de las sustituciones de material cuando queremos "engrisecer" la escena salvo la capa seleccionada.
   * Ademas lleva la contabilidad de quien tiene el cambio de material hecho y permite deshacer el cambio.
   *
   * @type {SelectCacheThreeMaterial}
   * @memberof GraphicProcessor
   */
  _cache4GrayMaterials: SelectCacheThreeMaterial;

  /**
   * Un mapa temporal y auxiliar para almacenar el status de visibilidad de la escena cuando sea necesario.
   *
   * @type {Map<THREE.Object3D, boolean>}
   * @memberof GraphicProcessor
   */
  _tmpMap4Visibility: Map<THREE.Object3D, boolean>;

  // ___________________________________________Fin_DMC's_para_la_visibilidad_en_modo_GeyStruct_____________________________

  // ___________________________________________Fin_de_la_declaracion_de_datos_miembro______________________________________
  // ^^^^^^^ Poner encima los nuevos DMC's que vayas agregando.

  /**
   * Constructor de un GPO. Obligatoriamente se le debe dar un nombre valido para que sea automaticamente registrado en su
   * clase padre gestora. Si el nombre es invalido no se registra, aunque seria funcional.
   *
   * @param {string} [name=""]
   * @memberof GraphicProcessor
   */
  constructor(name: string = "") {
    this._name = name;

    this.memoryCentinel = new MemoryCentinel();

    this._isMounted = false;
    this._renderMainView = false;

    this._mngrVP = new GraphicViewportManager(this);
    this.viewportObserver = new ObserverManager();
    this.operationObserver = new ObserverManager();

    this._sceneManager = new SceneManager();
    this._dataModelManager = new DataModelManager(this);
    this._selectionManager = new SelectionManager(this);

    this._coordinateSystemManager = new PlaneManager(this);

    this._undoRedoStack = new CommandInvoker();
  }

  mount(container: HTMLDivElement, project?: CADProject | StrucProject, options?: canvasOptions) {
    console.log("GRAPHICPROCESSOR.MOUNT() " + this.name);
    this.init(container, options);

    if (project) {
      if (project.content && project.content.layerData && project.content.objData) {
        const modelLoader = new ModelDataImporter(this);
        modelLoader.drawProjectCAD();
        this._isGreyStrucMode = isStrucProject(project);
      }

      if (project.planes && !this._isGreyStrucMode) {
        this.getPlaneManager().importFromJSON(project.planes);
      }

      if (project.cameraCfg) {
        this.mainCameraCfgImportFromJSON(project.cameraCfg);
      }

      if (this._isGreyStrucMode) {
        console.log("PROYECTO EN MODO GREYSTRUC.");
        this.enableGreyStrucMode();
      }
    }

    // // De momento metemos aqui siempre a huevo los mismos ficheros, a ver si funciona la cosa y luego lo haremos bien.
    // // Ojo, que dentro de React parece que no se pillan los ficheros si no se anteceden de un "/".
    // if (true) {
    //   // Esto habia antes que se alimentaba de datos masticados a un fichero.
    //   // const baseName = "/files_mesh3d/mesh_ELGA";
    //   // const deformationsFileName = "/files_mesh3d/deformations_ELGA_11.dat";
    //   // const model3D = StructModel3D.createStructModel3D_from_files(baseName, deformationsFileName);

    //   // Este tiene deformaciones en el fichero paralelo a continuacion. Los otros no.
    //   const filenameJSON = "/files_mesh3d/Results_ELGA.med.json";
    //   // Pongase como nombre vacio o no se use en la llamada de creacion del modelo para no tener deformaciones.
    //   const deformationsFileName = "/files_mesh3d/deformations_ELGA_11.dat";

    //   // const filenameJSON = "/files_mesh3d/big.json";
    //   // const deformationsFileName = "";
    //   // const filenameJSON = "/files_mesh3d/model_JL.med.json";
    //   // const filenameJSON = "/files_mesh3d/Results.med.json";
    //   // const filenameJSON = "/files_mesh3d/Results_NOEU.med.json";

    //   const model3D = StructModel3D.createStructModel3D_from_JSON(filenameJSON, deformationsFileName);

    //   if (model3D) {
    //     const graphicObj = model3D.getGraphicObject() as THREE.Object3D;
    //     // Inicialmente lo ponemos invisible para no liar la representacion y que el usuario pulse el boton para verlo.
    //     graphicObj.visible = false;
    //     // Ademas lo agregamos fuera de capas y pollas, en un sitio aparte de donde lo recuperaremos por su nombre.
    //     const sceneMngr = this.getSceneManager();
    //     // Ojo, que el unmount() tambien destruimos todo lo implicado en este modelo.
    //     sceneMngr.mainScene.add(graphicObj);
    //   } else {
    //     window.alert("ERROR!!!");
    //   }
    // }

    this._isMounted = true;
    this.seeRendererInfo();
    console.log("\n   +---------------------------------------------------");
    console.log("   |   Starting rendering loop...");
    console.log("   +---------------------------------------------------\n");

    this._renderer.setAnimationLoop(() => this.render());
  }
  unmount(): void {
    console.log("GRAPHICPROCESSOR.UNMOUNT() " + this.name);
    if (this._isMounted) {
      this.uninit();
    }
  }

  private init(initContainer: HTMLDivElement, options: canvasOptions = {}): void {
    // Default options values
    if (options.showthreeStat === undefined) options.showthreeStat = true;
    if (options.showXyzGyzmo === undefined) options.showXyzGyzmo = true;
    if (options.showMainGrid === undefined) options.showMainGrid = true;
    if (options.camera === undefined) options.camera = "perspective";
    if (options.cameraView === undefined) options.cameraView = refViewPlane.DEF;
    if (options.activeControls === undefined) options.activeControls = true;

    GraphicProcessorManager.register(this)

    // Esto es un darkgray para los integrantes de las capas no seleccionadas.
    this._cache4GrayMaterials = new SelectCacheThreeMaterial("#A9A9A9");
    this._tmpMap4Visibility = new Map<THREE.Object3D, boolean>();

    // Expediente X que me sucede en casa: Las dimensiones no son enteras, lo que jode toda subdivision subsiguiente.
    if (GraphicViewport.testDiv4NonIntegerDims(initContainer)) {
      console.error("\tWARNING: El container global tiene dimensiones decimales!!!.");
      // Lo redondeamos perdiendo todos los putos decimales. NO FUNCIONA.
      // GraphicViewport.assignIntegerDimensions2Div(initContainer);
      // Y como no me fio...
      if (GraphicViewport.testDiv4NonIntegerDims(initContainer)) {
        console.error("\tWARNING: El redondeamiento del div no ha funcionado.");
      }
    }

    // Resuelto fallo al cambiar de viewport: precision total.
    const aabb = initContainer.getBoundingClientRect();
    this._left = aabb.left;
    this._top = aabb.top;
    this._width = aabb.width;
    this._height = aabb.height;

    console.log(" +--------------------------------------------------------------------------------");
    console.log(" | GRAPHICPROCESSOR.INIT(): "  + this.name);
    console.log(` | (left, top) = (${this._left}, ${this._top})            w * h = ${this._width}, ${this._height}`);
    console.log(" +--------------------------------------------------------------------------------");

    this._container = initContainer;

    this._dataModelManager.connectEvents();
    this._selectionManager.connectEvents();

    // Inicialmente solo la vista principal debiera tener control activo.
    this._onlyMainView = true;

    if (options.showMainGrid) {
      this._infinitumGH = new scuMainGrid(this.getMainScene());
    }

    const aspect = this._width / this._height;
    const firstCameraPos = getViewPosition(options.cameraView);

    this._perspCamera = this.createPerspectiveCamera(aspect);
    this._perspCamera.name = "mainPersCam";
    this._perspCamera.position.set(firstCameraPos.x, firstCameraPos.y, firstCameraPos.z);
    this._perspCamera.lookAt(0, 0, 0);

    const frustumSize = this._height;
    this._orthoCamera = this.createOrthographicCamera(aspect, frustumSize);
    this._orthoCamera.name = "mainOrthoCam";
    this._orthoCamera.position.set(firstCameraPos.x, firstCameraPos.y, firstCameraPos.z);
    this._orthoCamera.lookAt(0, 0, 0);

    this._isActivePerspective = options.camera === "perspective";

    // Mirando desde +Z a origen. Es decir una planta (vision superior).
    // this._orthoCamera.position.set(0, 0, 1000);
    // Mirando desde -Y a origen. Es decir un alzado (vision frontal).
    // this._orthoCamera.position.set(0, -1000, 0);
    // Mirando desde -X a origen. Es decir un perfil (vision izquierda).
    // this._orthoCamera.position.set(-1000, 0, 0);
    // Mirando por debajo, de -Z a origen.
    // this._orthoCamera.position.set(0, 0, -1000);
    // Mirando por detras, de +Y a origen.
    // this._orthoCamera.position.set(0, 1000, 0);
    // Mirando por la derecha, de +X a origen.
    // this._orthoCamera.position.set(1000, 0, 0);

    // El gestor de estadisticas de Three.
    if (options.showthreeStat) {
      this.iniStatThreeMonitor();
    }

    if (!this._renderer) {
      // Solo se crean una vez tanto el renderer como ciertos complementos suyos.
      this._renderer = new THREE.WebGLRenderer({
        antialias: true,
        powerPreference: "high-performance",
        // Probamos con esto para quitar el z-fighting de la malla contra el modelo gris, y parece que funciona con un
        // pequeño offset en Z para el modelo de malla. Ademas no parece joder otras cosas.
        logarithmicDepthBuffer: true,
        // preserveDrawingBuffer: true,
      });
      this._renderer.setPixelRatio(window.devicePixelRatio);
      console.log("Creamos nuevo renderer.");

      if (false) {
        // this._renderer.shadowMap.enabled = true;
        // Curiosamente al meter esto los colores son mas claros, pero al seleccionar algo permanecen mas oscuros hasta deseleccionarlo.
        this._renderer.outputEncoding = THREE.sRGBEncoding;
      }

      // Si no hay renderer tampoco hay esto. Quizas esto no tenga sentido que exista...
      this._mainViewport = new GraphicViewport(this._mngrVP);

      // Junto con el renderer creamos tambien el reloj para la temporizacion en los updates de los cameraControls.
      this._clock = new THREE.Clock();

    } else {
      // Con esto borramos los restos del rendering anterior, pues sino se ven durante una milesima.
      this._renderer.clear();
      console.log("Reinicio del renderer previo.");
    }

    this._renderer.setSize(this._width, this._height);

    // Inicializamos las iteraciones.
    this._numIterations = 0;

    // Rellenamos los datos del hipotetico mainView, que AHORA es un casi como un viewport de cara al planeManager...
    this._mainViewport.name = "mainView";
    // ...pues ya tiene elemento que antes no tenia.
    this._mainViewport.elemHTML = this._container;
    this._mainViewport.left01 = this._mainViewport.top01 = 0;
    // Obviamente ocupa el 100% horizontal y vertical.
    this._mainViewport.width01 = 1;
    this._mainViewport.height01 = 1;
    // Y los pixels correspondientes.
    this._mainViewport.widthPx = this._width;
    this._mainViewport.heightPx = this._height;
    this._mainViewport.left01 = this._mainViewport.topPx = 0;
    // Ojo que inicialmente para el mainViewport la camara es la de perspectiva, pero luego podria cambiar...
    this._mainViewport.camera = this._perspCamera;
    this._mainViewport.enabled = true;
    this._mainViewport.viewPlaneType = refViewPlane.DEF;

    // Con esto el renderer NO borra automaticamente la pantalla ANTES de renderizar un nuevo frame.
    // Esto es lo que nos permite tener varios renderizados correspondientes a varias escenas superpuestas.
    // Antes lo estabamos asignando en el bucle de render todo el tiempo y no hace falta.
    this._renderer.autoClear = false;
    this._container.appendChild(this._renderer.domElement);

    if (options.showXyzGyzmo) {
      this.iniGyzmo();
    }
    // 3D Navigation
    if (options.activeControls) {
      this.createControls();
    }
    // Ojo, que tras la creacion de los camControls quedaba asignarle al mainViewport el control por defecto.
    this._mainViewport.controls = this._isActivePerspective ? this._perspControls : this._orthoControls;

    // Manager for coordinate system and working planes.
    this._mouseResolver = new CoordinateResolver(this);
    // this._coordinateSystemManager = new PlaneManager(this);

    // Ahora se necesita esta activacion previa para los eventos de JaWS. Ojo que si lo comento se jode el movimiento.
    this.setActiveViewport(this._mainViewport);

    // Connect to ZoomFit with wheel double click
    this._container.addEventListener("pointerup", this.zoomFitEventhandler);
    // Inicialmente todos desconectados hasta que pulses algo...
    this._mngrVP.activateOnlyViewportInput(-1);

    // Listener to get mouse position coordinate.
    this._mouseResolver.subscribeMouseCoordinateListener();

    // A partir de este momento nos ponemos a renderizar la vista principal. Desde el exterior podrian cambiarlo.
    this._renderMainView = true;

    // No hay foto pendiente.
    this._pendingSnapshot = false;

    // Efectos FX.
    // this._composerFX = new composerFX(this._renderer, this._sceneManager, this._perspCamera);

    if (this._cache4GrayMaterials.size()) {
      this._cache4GrayMaterials.clearSelection();
    }

  }
  private iniStatThreeMonitor() {
    this._stats = Stats();

    // Cambio a filosofia ETabs: para que no moleste a los titulos de los vp's colocamos las estadisticas abajo a la
    // derecha (esquina inferior derecha EID). Se mantiene la distancia fija dada a los bordes especificados.
    this._stats.domElement.style.cssText = `position:fixed;bottom:69px;right:4px;cursor:pointer;opacity:0.75`;

    // Asi lo coloco sobre todo lo demas y sera siempre clickable.
    this._stats.domElement.style.zIndex = "10";
    this._container.appendChild(this._stats.domElement);
  }
  private iniGyzmo() {
    // Creamos aqui el gizmo, ya que necesitamos que el renderer haya sido correctamente enchufado a su padre container.
    // Lo colocamos como en autoCad, en la esquina superior derecha, y no abajo pues parece que Rafa se me come algo de espacio
    // con la barra de comandos y hay un desfase de coordenadas... \ToDo: Posicionamiento parametrizable en cualquier esquina.
    this._gizmo = new XYZmo(true, true, this._mainViewport, true);
    // Inicializamos el gizmo con lo que necesita: Posicion, dimensiones, camara externa asociada y contenedor padre.
    const dimGizmoXY = 128;
    const xGizmo = this._width - dimGizmoXY;
    const yGizmo = 0;
    this._gizmo.init(xGizmo, yGizmo, dimGizmoXY, this.getCursorCamera(), this._container);
    this._gizmo.subscribeMouseEvents();
  }
  /** Funcion centralizada para la creacion y configuracion de los controles de movimiento de las camaras principales,
   * tanto la de perspectiva como la ortografica.
   *
   * @private
   * @memberof GraphicProcessor
   */
  private createControls(): void {

    // Controles de movimmiento para las 2 camaras principales en el modo mainView:
    // Los creo cada uno con su propia camara que no cambiara. Ambos sobre el elemento HTML del render.
    // [1] Control de la camara de perspectiva.
    this._perspControls = this.createCameraControls(this._perspCamera, this._renderer.domElement);
    if (true) {
      // Estos son los [6] eventos que maneja el CameraControls:
      // [1] 'controlstart': Cuando el usuario comienza a controlar la camara por medio del raton o de touches.
      // [2] 'control': Cuando el usuario controla la camara (dragging).
      // [3] 'controlend': Cuando el usuario finaliza el control de la camara.
      // [4] 'update': Cuando la posicion de la camara es actualizada.
      // [5] 'wake': Cuando la camara comienza a moverse.
      // [6] 'sleep': Cuando la camara finaliza su movimiento.
      // ATENCION: Todos estos eventos deben ser desconectados en el momento del unmount(), al hacer el uninit().

      // Este listener no solo nos vale para depurar lo que se este haciendo, sino que...
      // TAMBIEN PODRIAMOS AQUI CAMBIAR EL CURSOR DEL PUNTERO DEL RATON EN FUNCION DE LO QUE HAGAMOS.
      const cameraControls = this._perspControls;
      // Ademas esos callbacks necesitan algunas variables auxiliares.
      if (!this._prevPerspCamPosition) {
        this._prevPerspCamPosition = new THREE.Vector3();
        this._prevTargetPosition = new THREE.Vector3();
        this._prevCursorPosition = new THREE.Vector3();
      }

      // cameraControls.addEventListener("controlstart", this._controlstartEvntLstnr);
      // cameraControls.addEventListener("control", this._controlEvntLstnr);
      // cameraControls.addEventListener("controlend", this._controlendEvntLstnr);
      // Este evento es el que activa el mecanismo de precision incrementada para esta camara.
      cameraControls.addEventListener("update", this._updateEvntLstnr);
      // cameraControls.addEventListener("wake", this._wakeEvntLstnr);
      // cameraControls.addEventListener("sleep", this._sleepEvntLstnr);
    }
    setMouseControls(this._perspControls, false);

    // [2] Control de la camara ortografica.
    this._orthoControls = this.createCameraControls(this._orthoCamera, this._renderer.domElement);
    // Para pruebas le desconecto el dolly.
    // this._orthoControls.dollyToCursor = false;
    if (false) {
      const cameraControls = this._orthoControls;
      cameraControls.addEventListener('controlstart', () => {
        switch (cameraControls.currentAction) {
          case CameraControls.ACTION.ROTATE:
          case CameraControls.ACTION.TOUCH_ROTATE: {
            console.log("[1#ORTHO] ROTATE " + cameraControls.currentAction);
            // Parece que esto podria variar el estilo del cursor...
            this._renderer.domElement.classList.add('-dragging');
            break;
          }

          case CameraControls.ACTION.TRUCK:
          case CameraControls.ACTION.TOUCH_TRUCK: {
            console.log("[2#ORTHO] TRUCK " + cameraControls.currentAction);
            this._renderer.domElement.classList.add('-moving');
            break;
          }

          case CameraControls.ACTION.DOLLY:
          case CameraControls.ACTION.ZOOM: {
            console.log("[3#ORTHO] DOLLY " + cameraControls.currentAction);
            this._renderer.domElement.classList.add('-zoomIn');
            break;
          }

          case CameraControls.ACTION.TOUCH_DOLLY_TRUCK:
          case CameraControls.ACTION.TOUCH_ZOOM_TRUCK: {
            console.log("[4#ORTHO] DOLLY+TRUCK " + cameraControls.currentAction);
            this._renderer.domElement.classList.add('-moving');
            break;
          }

          default: {
            console.log("[D#ORTHO] " + cameraControls.currentAction);
            break;
          }
        }
      });

      cameraControls.addEventListener('controlend', () => {
        console.log("[END#ORTHO]");
        this._renderer.domElement.classList.remove('-dragging', '-moving', '-zoomIn');
      });
    }
    setMouseControls(this._orthoControls, false);

    // Para usar una u otra camara con los controles de movimiento.
    // const activeCamera: THREE.Camera = this._isActivePerspective ? this._perspCamera : this._orthoCamera;
    // TransformControls
    // this._transformControl = new TransformControls(activeCamera, this._renderer.domElement);
    // this._transformControl.addEventListener("change", () => this.render());
    // this._transformControl.addEventListener("dragging-changed", (event: THREE.Event): void => {
    //   this._perspControls.enabled = !event.value;});

    // Tras todo esto hay que dar el primer empujon.
    // this._perspControls.update(this._clock.getDelta());
    // this._orthoControls.update(this._clock.getDelta());
  }

  private uninit(): void {
    console.log("start GRAPHICPROCESSOR.UNINIT()");
    EventBus.enableDispatch = false;

    // Detenemos el renderer antes de que casque por no tener escena.
    // Ademas aqui tarda menos que si lo hago antes?.
    this._renderer.setAnimationLoop(null);

    GraphicProcessorManager.unregister(this._name);
    this.destroy();

    this.seeRendererInfo();

    // End current CAD operation
    OpFactory.stopOp(this);
    this.lastOpStack.clear();
    this._undoRedoStack.clear();

    if (this._isGreyStrucMode) {
      hypothesisManager.unInitialize();

      this._structuralModelManager.unregisterEvents();
      this._structuralModelManager.clearAll();
      this._structuralModelManager = undefined!;

      this._projectModelManager.clearAll();
      this._projectModelManager = undefined!;
    }

    // Borramos parte de los datos graficos y al propio gestor, ya que sera regenerado.
    this._selectionManager.disconnectEvents();
    this._dataModelManager.disconnectEvents();
    this._dataModelManager.clearAll();
    // this._dataModelManager = undefined!;

    if (this._infinitumGH) {
      this._infinitumGH.deleteGrid();
    }
    // clean composerFX
    this._composerFX = undefined!;

    // Tambien me cepillaria, si es que lo tengo, al modelo de malla 3D.
    this.destroyStructModel3D();

    // Antes de la destruccion de las camaras principales y sus controles asociados procedemos a desregistrar los eventos asociados.
    if (this._perspControls) {
      const cameraControls = this._perspControls;
      cameraControls.removeEventListener("controlstart", this._controlstartEvntLstnr);
      cameraControls.removeEventListener("control", this._controlEvntLstnr);
      cameraControls.removeEventListener("controlend", this._controlendEvntLstnr);
      cameraControls.removeEventListener("update", this._updateEvntLstnr);
      cameraControls.removeEventListener("wake", this._wakeEvntLstnr);
      cameraControls.removeEventListener("sleep", this._sleepEvntLstnr);
    }

    // Tambien destruimos camaras y demas cosas para volverlas a generar limpias y sanotas.
    this._perspCamera = undefined!;
    this._orthoCamera = undefined!;

    this._perspControls = undefined!;
    this._orthoControls = undefined!;

    this._coordinateSystemManager = undefined!;

    this._container.removeChild(this._renderer.domElement);
    this._isMounted = false;

    // Listener to get mouse position coordinate
    this._mouseResolver.unsubscribeMouseCoordinateListener();

    // Me cargo al scene manager.
    this._sceneManager.reset();
    this._sceneManager = undefined!;

    // Disconnect to ZoomFit with wheel double cleck
    this._container.removeEventListener("pointerup", this.zoomFitEventhandler);

    // Aqui podrian ir algunas cosas exoticas a la par que avanzadas para el renderer.
    this.seeRendererInfo();

    // Destruccion total de todos los vp's y su parafernalia asociada, pero con la posibilidad de re-mount().
    if (this._gizmo) {
      this._gizmo.destroy();
      clearXyzGyzmoSharedcomponents();
    }
    this._mngrVP.destroy();

    console.log("end GRAPHICPROCESSOR.UNINIT()");
    EventBus.enableDispatch = true;
  }
  private destroy(): void {
    // Quizas esto tenga que ir antes de la destruccion grafica.
    this._cache4GrayMaterials.clearSelection();
    if (this._cache4GrayMaterials.size()) {
      this._cache4GrayMaterials.clear();
    }
    this._cache4GrayMaterials = undefined!;
    if (this._tmpMap4Visibility.size) {
      this._tmpMap4Visibility.clear();
    }
    this._tmpMap4Visibility = undefined!;
  }

  // ***********************************************************

  // No puedo capturar un double click de la manera canonica, (solo puede generarse con el boton primario).
  // Por tanto recurro a este ardid, treta, artimaña o estratagema...
  private tmOtHndlr: NodeJS.Timeout | null = null;
  private count = 0;
  private zoomFitEventhandler = (event: PointerEvent) => {
    if (1 === event.button) {
      ++this.count;
      if (!this.tmOtHndlr) {
        this.tmOtHndlr = setTimeout(() => {
          this.tmOtHndlr = null;
          if (this.count >= 2) {
            console.log("DobleClick sobre mouseWheel.");
            console.log("\t" + this._activeViewport.name);
            if (this._activeViewport.isCursorOut === true) {
              console.log("\tDoubleClick outside of current active VP doesn't make zoom!!!.");
            } else {
              console.log("\tDoubleClick inside of current active VP: Let's zoom!!!.");
              this.zoomFitV2();
            }
          }
          this.count = 0;
        }, 250);
      }
    }
  }

  /**
   * Funcion centralizada para la creacion de camaras de perspectiva con todas (mas o menos) con los mismos parametros.
   *
   * @param {number} aspect
   * @returns {THREE.PerspectiveCamera}
   * @memberof GraphicProcessor
   */
  public createPerspectiveCamera(aspect: number): THREE.PerspectiveCamera {
    /*
      Los parametros de construccion de la camara de perspectiva son estos 4 valores numericos:
      fov: Camera frustum vertical field of view. Angulo vertical del campo de vista del frustum de la camara, desde abajo hasta arriba, en grados. Por defecto es 50.
      aspect: Camera frustum aspect ratio. Ratio entre ancho y alto del canvas de dibujo usado. Por defecto es 1 asumiendo canvas cuadrado.
      near: Camera frustum near plane. Por defecto 0.1
      far: Camera frustum far plane. Por defecto 2000.
      Con esos datos se define el frustum de vision de la camara.
    */
    // Ponemos un FOV mas cercano al del ser humano.
    const fov = 45;
    const near = 0.01;
    // Un near mas lejano para que el modelo no se salga tan facilmente del frustum de vision.
    const far = 100000;
    const perspCamera = new THREE.PerspectiveCamera(fov, aspect, near, far);
    // Todas las camaras tienen el up para arriba.
    perspCamera.up = new THREE.Vector3(0, 0, 1);
    return perspCamera;
  }
  /**
   * Funcion centralizada para la creacion de camaras ortograficas con los mismos parametros.
   * NOTA SOBRE LAS CAMARAS ORTOGRAFICAS: Los parametros de inicializacion de la camara, left/right/top/botton,
   * AL FINAL SON COORDENADAS MUNDIALES DEL PARALELEPIPEDO/FRUSTUM DE VISION que queremos montar, con la camara en su cima.
   * No son valores relativos a la propia camara como pensaba y como piensa todo el mundo que encuentra dificultades
   * para mover estas putas camaras. El error es, aparte de mi estulticia, por las pesimas explicaciones en la documentacion.
   *
   * @param {number} aspect
   * @param {number} frustumSize Tamaño relativo en metros que constituye el frustum vertical.
   * @returns {THREE.OrthographicCamera}
   * @memberof GraphicProcessor
   */
  public createOrthographicCamera(aspect: number, frustumSize: number): THREE.OrthographicCamera {
    /*
      Los parametros de construccion de la camara ortografica son estos 6 valores numericos:
      left: Plano izquierdo del frustum de la camara.
      right: Idem plano derecho.
      top: Idem plano superior.
      bottom: Idem plano Inferior.
      near: Idem plano near.
      far: Idem plano far. Para una camara ortografica es admisible un near 0.
      Para entender esto vayanse vuesas mercedes a la explicacion con dibujos chachis en https://en.wikipedia.org/wiki/Viewing_frustum
      PERO OJO: Esas dimensiones no son relativas a la camara, sino dimensiones reales MUNDIALES de lo que queremos abarcar con ella.
      Por lo tanto para ponernos a 10 km de distancia tendriamos que tener esa distancia como diferencia entre top y bottom.
    */
    const left = -(frustumSize * aspect) / 2;
    const right = (frustumSize * aspect) / 2;
    const top = frustumSize / 2;
    const bottom = -frustumSize / 2;
    const near = 0.01;
    const far = 100000;
    const orthoCamera = new THREE.OrthographicCamera(left, right, top, bottom, near, far);
    orthoCamera.up = new THREE.Vector3(0, 0, 1);
    return orthoCamera;
  }

  /**
   * Funcion centralizada para crear todos los controles de movimiento de las camaras con los mismos parametros iniciales.
   *
   * @param {(THREE.PerspectiveCamera | THREE.OrthographicCamera)} camera
   * @param {HTMLElement} elem
   * @returns {CameraControls}
   * @memberof GraphicProcessor
   */
  public createCameraControls(camera: THREE.PerspectiveCamera | THREE.OrthographicCamera, elem: HTMLElement): CameraControls {

    const ctrls = new CameraControls(camera, elem);

    // Fijamos los parametros de funcionamiento del control de camaras por defecto.
    this.setCameraControlsDefaultValues(ctrls);

    // Experimental: De cara a mejorar el zoom al viewport posiblemente esto ayude. Pues no, ya que jode el dolly.
    if (false) {
      const rect = elem.getBoundingClientRect();
      ctrls.setViewport(rect.left, rect.top, rect.width, rect.height);
    }

    return ctrls;
  }

  /**
   * Centraliza el ajuste de parametros por defecto para los controles de las camaras.
   *
   * @param ctrls
   */
  private setCameraControlsDefaultValues(ctrls: CameraControls): void {

    // [1] Parametros comunes a ambos tipos de camaras.
    // Con esto el zoom es al punto al que apunta el cursor.
    ctrls.dollyToCursor = true;

    // Esto define como la posicion de la camara es trasladada al hacer panning. Si es true (valor por defecto), entonces la
    // camara hace panning en el espacio de pantalla. Sino solo se puede hacer panning en el plano ortogonal a la direccion UP
    // de la camara. Creo que asi es mas usable, pero no permite "escalar" las fachadas de edificios en altura, asi que de momento
    // lo dejamos.
    // ctrls.verticalDragToForward = true (false por defecto). Es equivalente en orbitControls al screenSpacePanning.

    if ((ctrls.getCamera() as THREE.PerspectiveCamera).isPerspectiveCamera === true) {
      // [2] Parametros que incorporamos solamente a los controles de camaras de perspectiva.
      // Esto es la amortiguacion de inercia o inertia damping, que por defecto en CameraControls es de 0.05.
      ctrls.dampingFactor = 0.2;
      // Experimental: Resuelve esto los problemas de acercarse a algo???.
      // ctrls.infinityDolly = true;
    } else {
      if ((ctrls.getCamera() as THREE.OrthographicCamera).isOrthographicCamera === true) {
        // [3] Parametros que incorporamos solamente a los controles de camaras ortograficas.
      }
    }

    // Para intentar resolver los problemas de la inercia remanente al cambiar de vp optamos por dar el mismo valor de amortiguacion
    // a todas las camaras que creemos. Recuerda que por defecto esto valdria 0.05 y que da el problema anterior.
    // Lo que no se aun son los valores maximo y minimo admisibles.
    // Con valor negativo se va a tomar por el culo, con 0 va a tirones y con +1 es como muy robotico, pero parece el mejor segun JaWS.
    ctrls.dampingFactor = +1.0;

    // Tambien tenemos el problema de no podernos acercar demasiado a ciertas areas una vez hemos hecho cierto tipo de movimientos.
    // Intentaremos resolverlo dando valores distintos de los por defecto.

    // Esta es la maxima distancia para el dolly, que por defecto era Infinity, cosa demasiado lejana.
    ctrls.maxDistance = 5000;
    // Por defecto era 0, pero por si las moscas damos un cm.
    ctrls.minDistance = 0.01;
    // Por defecto era 1.
    ctrls.dollySpeed = 0.75;

    // Tambien tenemos una damping inertia while dragging que por defecto vale 0.25, sacada de https://github.com/yomotsu/camera-controls,
    // que se almacena en el miembro .draggingDampingFactor
    // ctrls.draggingDampingFactor = +1.0;
  }

  /**
   * Activador/desactivador de alguna de las vistas con camara ortografica en el modo de mainView, es decir que no vale para
   * los otros viewports. Si activa la vista ortografica entonces desactiva la camara de perspectiva y activa la ortografica
   * y a la inversa, (logicamente de forma que nunca ambas pueden estar simultaneamente en el mismo estado).
   * El vector con la ortoposicion es el que da la vista ortografica escogida, de forma que posicionando la ortoCamara en una
   * posicion "multiplo" de esta posicion unitaria y mirando hacia el origen (0, 0, 0) tendremos estas vistas:
   * k * (0, 0, 1): Mirando desde +Z a origen. Es decir una planta (vision superior).
   * k * (0, -1, 0): Mirando desde -Y a origen. Es decir un alzado (vision frontal).
   * k * (-1, 0, 0): Mirando desde -X a origen. Es decir un perfil (vision izquierda).
   * k * (0, 0, -1): Mirando por debajo, de -Z a origen.
   * k * (0, 1, 0): Mirando por detras, de +Y a origen.
   * k * (1, 0, 0): Mirando por la derecha, de +X a origen.
   * @param onOff
   * @param orthoPosition
   * @returns
   */
  public activateOrthograficCameraView_DEPRECATED(onOff: boolean, orthoPosition?: [number, number, number]): void {

    // Funcion auxiliar para limitar un vector en 3D dando unos limites minimos y maximos. En caso de ser limitado se devuelve
    // ese valor limitado, pero en caso de estar en los limites se devuelve null.
    // \ToDo: Colocarlo en alguna libreria matematica o afin...
    const clamp = (vSrc: IPoint, vMin: IPoint, vMax: IPoint): IPoint | null => {
      let { x, y, z } = vSrc;
      let numChanges = 0;
      if (x < vMin.x || vMax.x < x) {
        ++numChanges;
        x = x < vMin.x ? vMin.x : vMax.x;
      }
      if (y < vMin.y || vMax.y < y) {
        ++numChanges;
        y = y < vMin.y ? vMin.y : vMax.y;
      }
      if (z < vMin.z || vMax.z < z) {
        ++numChanges;
        z = z < vMin.z ? vMin.z : vMax.z;
      }
      if (numChanges) {
        return { x, y, z } as IPoint;
      }
      return null;
    };

    // Para el clamp usaremos estos limites.
    const limMin = -10000;
    const limMax = +10000;
    const vMin: IPoint = { x: limMin, y: limMin, z: limMin };
    const vMax: IPoint = { x: limMax, y: limMax, z: limMax };

    // Veamos donde estaba situada y apuntando la camara actualmente activa. De ahi sacamos una distancia de enfoque.
    let pCamera: [number, number, number] = [0, 0, 0];
    let pFocus: IPoint | null = null;
    let touchesPlaneZ0 = true;

    // Si hubo limitacion debemos saber por donde.
    let xClamped = false;
    let yClamped = false;
    let zClamped = false;
    let isClamped = false;

    const test4Clamping = (vSrc: IPoint): IPoint | null => {
      const vClamp = clamp(vSrc, vMin, vMax);
      if (vClamp) {
        isClamped = true;
        if (vSrc.x !== vClamp.x) {
          xClamped = true;
        }
        if (vSrc.y !== vClamp.y) {
          yClamped = true;
        }
        if (vSrc.z !== vClamp.z) {
          zClamped = true;
        }
        console.log("WARNING: Las coordenadas del punto focal han sido limitadas [" + xClamped + ", " + yClamped + ", " + zClamped + "]");
        console.log(`\tvSrc(${vSrc.x}, ${vSrc.y}, ${vSrc.z}) ===> vClamp(${vClamp.x}, ${vClamp.y}, ${vClamp.z})`);
        return vClamp as IPoint;
      }
      return null;
    };

    if (this._isActivePerspective) {
      console.log("Camara activa ===> PERSPECTIVA:");
      const pFocusNull = this._coordinateSystemManager.getPointMainPlane(this._perspCamera);
      if (!pFocusNull) {
        touchesPlaneZ0 = false;
        pFocus = { x: 0, y: 0, z: 0 };
      } else {
        pFocus = pFocusNull;
        const vClamp = test4Clamping(pFocus);
        if (vClamp) {
          debugger;
        }
      }

      pCamera[0] = this._perspCamera.position.x;
      pCamera[1] = this._perspCamera.position.y;
      pCamera[2] = this._perspCamera.position.z;
    } else {
      console.log("Camara activa ===> ORTOGONAL:");
      const pFocusNull = this._coordinateSystemManager.getPointMainPlane(this._orthoCamera);
      if (!pFocusNull) {
        touchesPlaneZ0 = false;
        pFocus = { x: 0, y: 0, z: 0 };
      } else {
        // A veces puede pasar con la camara ortogonal que los puntos de foco se van a tomar por el culo a casa dios.
        pFocus = pFocusNull;
        /*const vClamp =*/ test4Clamping(pFocus);
      }

      pCamera[0] = this._orthoCamera.position.x;
      pCamera[1] = this._orthoCamera.position.y;
      pCamera[2] = this._orthoCamera.position.z;
    }

    if (!touchesPlaneZ0) {
      console.error("ATENCION: El haz de vision no toca el plano suelo z=0.");
    }

    // Entre la posicion de la camara y el punto al que enfoca tenemos una distancia de enfoque que queremos conservar para la parte ortografica.
    let distFocus = Math.sqrt((pFocus.x - pCamera[0]) * (pFocus.x - pCamera[0]) + (pFocus.y - pCamera[1]) * (pFocus.y - pCamera[1]) + (pFocus.z - pCamera[2]) * (pFocus.z - pCamera[2]));

    console.log(`\tPosicion(${pCamera[0]}, ${pCamera[1]}, ${pCamera[2]}) ---> Foco(${pFocus.x}, ${pFocus.y}, ${pFocus.z}) ---> distFocal:${distFocus}`);

    // Con una distancia focal casi nula, fruto de estar ya sobre el plano z=0, tendremos errores, asi que lo tendremos en cuenta...
    let isErrorByZeroFocalDistance = false;
    if (distFocus <= 0.01) {
      console.error("Distancia focal demasiado pequeña.");
      isErrorByZeroFocalDistance = true;
    }

    // Cambiamos a la camara que toque.
    this._isActivePerspective = !onOff;

    if (this._isActivePerspective) {
      // Si ya estaba activa, la dejamos como estaba.
      if (this._perspControls.enabled) {
        console.log("La camara de perspectiva ya estaba activada.");
        return;
      }
      console.log("Activamos la camara de perspectiva.");
      this._perspControls.enabled = true;
      this._orthoControls.enabled = false;

      // Experimental.
      this._perspControls.reset();

      // Resolucion de posibles problemas cuando nos vamos a tomar por culo...
      if (isErrorByZeroFocalDistance) {
        pCamera[0] = 0;
        pCamera[1] = -1000;
        pCamera[2] = 1000;

        pFocus.x = 0;
        pFocus.y = 0;
        pFocus.z = 0;
      }

      // Nos colocamos con la camara de perspectiva en la ultima posicion en que dejamos la camara anteriormente seleccionada.
      this._perspCamera.position.set(...pCamera);
      // Y mirando al ultimo punto focal.
      (this._perspControls as CameraControls).setTarget(pFocus.x, pFocus.y, pFocus.z);
      // El truco posible: Cada vez que cambies algo en un control, update() inmediatamente despues.
      this._perspControls.update(this._clock.getDelta());
      this._perspCamera.lookAt(pFocus.x, pFocus.y, pFocus.z);

      this._perspCamera.updateProjectionMatrix();
      this._perspControls.update(this._clock.getDelta());
    } else {
      console.log("Activamos la camara ortografica.");
      this._perspControls.enabled = false;
      this._orthoControls.enabled = true;

      if (orthoPosition) {
        // Esto parece solucionar los problemas de giros raros cuando has hecho muchos cambios de camara previos???.
        this._orthoControls.reset();

        // Con esto desactivamos el panning de la camara.
        // this._orthoControls.enablePan = false;

        // Impedimos rotar, con lo que nos queda siempre una vista en un plano ortonormal.
        // ??? this._orthoControls.enableRotate = false;

        if (isErrorByZeroFocalDistance) {
          // Correccion de la distancia focal "echandonos atras" para ampliar la distancia focal.
          distFocus = 10;
          pFocus.x = 0;
          pFocus.y = 0;
          pFocus.z = 0;
        }

        if (isClamped) {
          // Correccion por el clamping al dar un valor muy grande en el punto focal debido a un mal calculo del mismo...
          distFocus = 10;
          pFocus.x = 0;
          pFocus.y = 0;
          pFocus.z = 0;
        }

        // Alguna de las 6 vistas predefinidas.
        console.log("\tCon la vista PREDEFINIDA [" + orthoPosition + "].");
        orthoPosition[0] *= distFocus;
        orthoPosition[1] *= distFocus;
        orthoPosition[2] *= distFocus;

        // Del punto foco sacamos la posicion ortogonal de la camara ortografica.
        orthoPosition[0] += pFocus.x;
        orthoPosition[1] += pFocus.y;
        orthoPosition[2] += pFocus.z;
        this._orthoCamera.position.set(...orthoPosition);

        // Parece que ambas cosas son necesarias???. Tanto el target como el lookAt. DUDAS CON LOS NUEVOS CONTROLES.
        this._orthoControls.setTarget(pFocus.x, pFocus.y, pFocus.z);
        // El truco posible: Cada vez que cambies algo en un control, update() inmediatamente despues.
        this._orthoControls.update(this._clock.getDelta());
        this._orthoCamera.lookAt(pFocus.x, pFocus.y, pFocus.z);

        console.log("OrtoCamara en [" + orthoPosition + "] mirando a [" + pFocus.x + ", " + pFocus.y + ", " + pFocus.z + "]");

        // Estas son las vistas definidas en el origen, colocando la camara en la posicion dada y mirando hacia el origen.
        // Mirando desde +Z a origen. Es decir una planta (vision superior).
        // orthoCamera.position.set(0, 0, 1000);
        // Mirando desde -Y a origen. Es decir un alzado (vision frontal).
        // orthoCamera.position.set(0, -1000, 0);
        // Mirando desde -X a origen. Es decir un perfil (vision izquierda).
        // orthoCamera.position.set(-1000, 0, 0);
        // Mirando por debajo, de -Z a origen.
        // orthoCamera.position.set(0, 0, -1000);
        // Mirando por detras, de +Y a origen.
        // orthoCamera.position.set(0, 1000, 0);
        // Mirando por la derecha, de +X a origen.
        // orthoCamera.position.set(1000, 0, 0);
      } else {
        // Permitimos toda clase de rotaciones en el modo libre.
        // this._orthoControls.enableRotate = true;
        // Nos colocamos con la ortoCamara en posicion libre, en la misma posicion en que estaba la camara de perspectiva
        // y mirando hacia el ultimo punto al que se hubo mirado antes, bien sea por una camara previa de perspectiva u ortografica...
        console.log("\tCon vista libre.");
        // Nos ponemos en la ultima posicion y apuntando al ultimo foco.
        this._orthoCamera.position.set(...pCamera);
        this._orthoControls.setTarget(pFocus.x, pFocus.y, pFocus.z);
        this._orthoControls.update(this._clock.getDelta());
        this._orthoCamera.lookAt(pFocus.x, pFocus.y, pFocus.z);
      }

      this._orthoCamera.updateProjectionMatrix();
      // Siempre que haya cambios en las camaras el control debe actualizarse.
      this._orthoControls.update(this._clock.getDelta());
    }
  }

  public activateOrthograficCameraView(onOff: boolean, orthoPosition?: [number, number, number]): void {

    if (this._isActivePerspective) {
      console.log("Camara actualmente activa ===> PERSPECTIVA:");
    } else {
      console.log("Camara actualmente activa ===> ORTOGONAL:");
    }

    // Cambiamos a la camara que toque.
    this._isActivePerspective = !onOff;

    if (this._isActivePerspective) {
      if (true) {
        // EXPERIMENTAL:
        this._mainViewport.camera = this._perspCamera;
        this._mainViewport.controls = this._perspControls;
      }

      // Si ya estaba activa, la dejamos como estaba.
      if (this._perspControls.enabled) {
        console.log("La camara de perspectiva ya estaba activada.");
        return;
      }
      console.log("Activamos la camara de perspectiva.");
      this._perspControls.enabled = true;
      this._orthoControls.enabled = false;
    } else {
      if (true) {
        // EXPERIMENTAL:
        this._mainViewport.camera = this._orthoCamera;
        this._mainViewport.controls = this._orthoControls;
      }

      console.log("Activamos la camara ortografica.");
      this._perspControls.enabled = false;
      this._orthoControls.enabled = true;

      if (orthoPosition) {
        // Estos angulos van en grados sexagesimales.
        // Por defecto ponemos una isometrica a 45º
        // Cabeceo no en eje Z.
        let alpha = 45;
        // Cabeceo si en eje X.
        let beta = 45;

        // Alguna de las 6 vistas predefinidas.
        console.log("\tCon la vista PREDEFINIDA [" + orthoPosition + "].");
        const [x, y, z] = orthoPosition;
        if (!x && !y && z === 1) {
          console.log("\tPLANTA.");
          alpha = 0;
          beta = 0;
        } else {
          if (!x && y === -1 && !z) {
            console.log("\tALZADO.");
            alpha = 0;
            beta = 90;
          } else {
            if (x === -1 && !y && !z) {
              console.log("\tPERFIL.");
              alpha = 270;
              beta = 90;
            } else {
              console.error("Caso no tratado.");
            }
          }
        }

        this._orthoControls.rotateTo(alpha * THREE.MathUtils.DEG2RAD, beta * THREE.MathUtils.DEG2RAD, true);
        // this.zoomFitV2();
      } else {
        // Permitimos toda clase de rotaciones en el modo libre.
        console.log("\tCon vista libre.");
      }
    }
  }

  /**
   * Hace lo que tenga que hacer cuando estamos en modo GreyStruc y se nos pasa uno de los 3 vp's implicados en ese modo.
   * Si estamos en el vp "storeys" marcamos con gris a todos los items de las plantas excepto a la seleccionada y a su asociada,
   * que no sufren cambio alguno. Si estamos en los vp's "plan" o "elevation" lo que haremos sera invisibilizar todo salvo los
   * componentes de la capa seleccionada y su asociada.
   *
   * RECORDEMOS que ahora trabajaremos en paralelo con 2 capas, la seleccionada de entre las DXF(*) y su correspondiente asociada.
   * (*) Es decir las capas que estan dentro de la subCapa de #root denominada "Imported (dxf)".
   *
   * Ademas en la fmc asociada finishProcessing4GreyStrucMode() se desharan los pertinentes cambios hechos para que todo
   * quede como estaba de cara al resto del sistema.
   *
   * @private
   * @param {GraphicViewport} vp
   * @returns {boolean}
   * @memberof GraphicProcessor
   */
  private startProcessing4GreyStrucMode(vp: GraphicViewport): boolean {

    // Con la posibilidad actual de cambiar el tipo de camara en caliente, disponemos que las camaras ortograficas solo
    // muestran las capas seleccionadas, mientras que las de perspectiva lo muestran todo todito.
    const isPerspective = (vp.camera as THREE.PerspectiveCamera).isPerspectiveCamera;

    // Una serie de capas, generalmente 2 (la planta DXF mas su asociada) con las que trabajaremos despreciando al resto.
    // Pero podrian ser 0, 1 o N > 2. Si son 0 lo mostraremos todo.
    const vLayersIds = this._structuralModelManager.currStoreyLayers;
    const vLayersObjs: THREE.Object3D[] = [];
    let numLayers = vLayersIds.length;
    if (!numLayers) {
      return false;
    } else {
      // Las acumulamos como objetos de Three que pedimos al layerManager, pues es mas rapido que pedirlos a escena.
      for (let i = 0; i < numLayers; ++i) {
        const id = vLayersIds[i];
        const obj = this._dataModelManager.layerManager.getLayerDataFromId(id);
        if (obj) {
          vLayersObjs.push(obj.threeObject);
        }
      }
    }

    numLayers = vLayersIds.length;
    if (!numLayers) {
      return false;
    }

    if (isPerspective) {
      // Ojo, que ahora se ponen grises todas las capas excepto la actual y la "asociada" a la misma.
      this.doMaterialReplacing2GrayExceptLayers(vLayersObjs);
    } else {
      // Estamos en la fase de alimentar a los vp's de planta y alzado, simplemente ocultando todas aquellas capas que no sean
      // la seleccionada ni su asociada. Pero esto solo lo haremos durante los rendering's especificos de cada uno de esos dos vp's.
      // Primero salvamos el status de visibilidad de la escena principal y luego lo recuperaremos...
      this.getVisibilityStatus(this._tmpMap4Visibility);
      this.showOnlyLayers(vLayersObjs);
    }

    return true;
  }

  /**
   * Deshace los cambios hechos en la fmc startProcessing4GreyStrucMode() para el vp dado, dejando el sistema en un estado
   * coherente de cara a la continuacion del proceso de rendering.
   *
   * @private
   * @param {GraphicViewport} vp
   * @memberof GraphicProcessor
   */
  private finishProcessing4GreyStrucMode(vp: GraphicViewport): void {
    const isPerspective = (vp.camera as THREE.PerspectiveCamera).isPerspectiveCamera;

    if (isPerspective) {
      if (this._cache4GrayMaterials.size()) {
        this._cache4GrayMaterials.clearSelection();
      }
    } else {
      // Es el modo de los otros 2 vp, asi que todo debiera volver a poder ser visible... \ToDo: Salvo ideas...
      this.setVisibilityStatus(this._tmpMap4Visibility);
      // De momento la borramos, aunque se podria optimizar no calculandolo continuamente...
      this._tmpMap4Visibility.clear();
    }
  }

  /**
   * Dada una camara renderiza todas las escenas habituales.
   * Sirve para centralizar la llamada y ahorrar codigo con los viewports.
   */
  private multipleRender(camera: THREE.Camera): void {
    if (false && this._composerFX.hasSelectedObjects()) {
      this._composerFX.rendererFX(camera);
    } else {

      this._renderer.render(this.getMainScene(), camera);

      // Esta cuando no tiene nada solo conserva las 3 luces, asi que no la renderizariamos.
      if (this._sceneManager.auxScene.children.length > 3) {
        this._renderer.render(this._sceneManager.auxScene, camera);
      }

      // Optimizacion: Las escenas vacias no tienen que renderizarse.
      if (this._sceneManager.selectionScene.children.length) {
        this._renderer.render(this._sceneManager.selectionScene, camera);
      }

      this._renderer.render(this._sceneManager.helperScene, camera);
    }
  }

  public infoWGL() {
    function extractValue(reg: any, str: any) {
      const matches = str.match(reg);
      return matches && matches[0];
    }

    // WebGL Context Setup
    const canvas = document.createElement('canvas');
    const gl = canvas.getContext('webgl') as any;
    const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');

    const vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
    const renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);

    // Full card description and webGL layer (if present)
    const layer = extractValue(/(ANGLE)/g, renderer);
    const card = extractValue(/((NVIDIA|AMD|Intel)[^\d]*[^\s]+)/, renderer);

    const tokens = card.split(' ');
    tokens.shift();

    // Split the card description up into pieces
    // with brand, manufacturer, card version
    const manufacturer = extractValue(/(NVIDIA|AMD|Intel)/g, card);
    const cardVersion = tokens.pop();
    const brand = tokens.join(' ');
    const integrated = manufacturer === 'Intel';

    console.log({
      layer,
      card,
      manufacturer,
      cardVersion,
      brand,
      integrated,
      vendor,
      renderer
    });
  }

  public render(): void {

    // Aqui va el rendering de la vista principal, la que ocuparia toda la pantalla independiente de los viewports existentes,
    // que ademas podemos o no efectuar. Va la primera para que los posibles viewports se superpongan a ella y sean pintados
    // despues segun su profundidad (algoritmo del pintor).
    if (this._renderMainView) {
      // De momento pintamos en la principal tologordo y si hay viewports ellos van detras...
      // Podemos seleccionar una u otra camara en caliente sin problemas.
      const activeCamera: THREE.Camera = this._isActivePerspective ? this._perspCamera : this._orthoCamera;
      this.multipleRender(activeCamera);
    }

    // Aqui va el procesamiento de los viewports, si fuera necesario.
    const numViewports = this._mngrVP.getNumViewports();
    if (numViewports) {
      for (let i = 0; i < numViewports; ++i) {
        const vp = this._mngrVP.getViewportN(i);

        // El modo tripleView usado en el calculo de estructuras.
        const letsProcessFinish4GreyStructMode = this._isGreyStrucMode && this.startProcessing4GreyStrucMode(vp);

        // Aqui tenemos en cuenta SOLO que este activado, pues ya no hay animacion.
        const isActive = vp.enabled;
        if (isActive) {
          let isUpdated = false;

          // Si el vp es el activo, lo obligamos a actualizar su camControl interno. No se puede quitar.
          // \ToDo: Quizas si lo hago siempre entonces sea esta la clave para solucionar los problemas de la inercia no finalizada.
          if (vp === this._activeViewport) {
            if (vp.controls) {
              vp.controls.update(this._clock.getDelta());
              isUpdated = true;
            }
          }

          const camera = vp.camera as THREE.Camera;
          // Recuerda que esto va referido al 0, sin offset lateral por estar dentro del canvas???.
          let left = Math.floor(this._width * vp.left01);
          // El puto scissoring ha dado bastantes problemas, pues su criterio es totalmente contrario: Restamos altura total...
          let bottom = this._height - Math.floor(this._height * vp.top01);
          let width = Math.floor(this._width * vp.width01);
          let height = Math.floor(this._height * vp.height01);
          // ...y luego la altura parcial para colocarnos abajo.
          bottom -= height;

          this._renderer.setScissorTest(true);

          // El truco para coger el color de fondo para el viewport es asignarlo antes del render parcial...
          // this.getCurrentScene().background = vp.backgroundColor as THREE.Color;

          this._renderer.setViewport(left + 1, bottom + 1, width - 2, height - 2);
          this._renderer.setScissor(left + 1, bottom + 1, width - 2, height - 2);
          // De las coordenadas en curso para el scissoring del vieport sacamos las necesarias para el gizmo.
          const xSc = left + 1 + (width - 2) - (vp.gizmo as XYZmo_RCOH8).dimXY;
          const ySc = bottom + 1 + (height - 2) - (vp.gizmo as XYZmo_RCOH8).dimXY;

          this._renderer.setClearColor(vp.backgroundColor as THREE.Color);
          this.multipleRender(camera);

          // ...y tras el render parcial dejarlo a null como estaba antes.
          // this.getCurrentScene().background = null;

          if (vp.gizmo) {
            this._renderer.setScissorTest(false);
            this._renderer.setSize(this._width, this._height, true);
            vp.gizmo.render(this._renderer, xSc, ySc);
          }
        } // if (isActive)

        if (letsProcessFinish4GreyStructMode) {
          this.finishProcessing4GreyStrucMode(vp);
        }
      } // for (let i = 0; i < numViewports; ++i)

      // OBLIGATORIO: Desconectar la tijera.
      this._renderer.setScissorTest(false);
      // OBLIGATORIO: Resetear al tamaño original. Da igual el true que el false???.
      this._renderer.setSize(this._width, this._height, true);
    } // if (numViewports)

    // Lo ultimo seria esto para que vaya "por encima" de todo lo demas.
    if (this._renderMainView && this._gizmo) {
      if (this._gizmo.animating) {
        if (!this._gizmo.useCameraControls && !this._gizmo.updateRotationAnimation()) {
          // Al acabar de rotar hacemos esto para evitar situaciones en las que la camara o sus controles hacen extraños... Parece que funciona!!!.
          console.log("Alcanzado punto destino.");
          (this._perspControls as CameraControls).setTarget(0, 0, 0);
          // El truco posible: Cada vez que cambies algo en un control, update() inmediatamente despues.
          this._perspControls.update(this._clock.getDelta());
          this._perspCamera.lookAt(0, 0, 0);

          this._perspCamera.updateProjectionMatrix();
          this._perspControls.update(this._clock.getDelta());
        }
      }
      // \ToDo: Si algun dia el renderizado del gizmo/s fuera demasiado costoso, se podria poner una pegatina con una textura
      // en su sitio en vez de volverlo a renderizar (si es que no ha cambiado en nada)???...
      this._gizmo.render(this._renderer, this.width - this._gizmo.dimXY, this.height - this._gizmo.dimXY);
      // VOILA!!! Esta linea magica es la que hace que todo vaya bien.
      this._renderer.setSize(this._width, this._height, true);
    }

    // Al final de todo actualizo las estadisticas...
    // \ToDo: Poner las estadisticas como desactivables...
    if (this._stats) this._stats.update();

    // Para futuras pruebas con algo de inercia en el movimiento...
    if (this._perspControls && this._perspControls.enabled) {
      // if (this._perspControls.enableDamping) {
      //   this._perspControls.update();
      // }

      if (true) {
        const delta = this._clock.getDelta();
        // Atencion: El update para los nuevos CameraControls es siempre obligatoro.
        this._perspControls.update(delta);
        // Este sobra???.
        // (this._activeViewport.controls as CameraControls).update(delta);
      }
    }

    if (this._orthoControls && this._orthoControls.enabled) {
      const delta = this._clock.getDelta();
      this._orthoControls.update(delta);
    }

    ++this._numIterations;

    const numViewports2Kill = this._mngrVP.getNumViewports2Delete();
    if (numViewports2Kill) {
      // En el modo GreyStruct usamos el estilo ETab's y destruimos los GVP's.
      if (this._isGreyStrucMode) {
        this._mngrVP.deferredViewportsDestruction();
      }
    }

    if (this._pendingSnapshot) {
      this.takeSnapshotNow();
    }

    // Cada minuto examinamos la memoria.
    // if (0 === this._numIterations % 3600) {
    //   if (this.memoryCentinel.update()) {
    //     this.seeRendererInfo();
    //   }
    // }

    // Para mis pruebas.
    // if (!(this._numIterations % 600)) {
    //   const seconds = this._numIterations / 60;
    //   console.log(`${seconds} segundos.`);
    //   debugger;
    //   this.testMe();
    // }
    // Al llevar 20 segundos sacamos una foto.
    // if (this._numIterations === 1200) {
    //   console.error("Foto!!!");
    //   this.takeSnapshot2PNG("pepito");
    // }
    // // Tras 10 segundos ejecutamos una division del viewport en curso, sea el que sea.
    // if (this._numIterations % 600 === 0) {
    //   console.error(`Subdivisions for current viewport "${this._activeViewport.name}".`);
    //   const srcVP = this._activeViewport;
    //   const isVertical = true;
    //   const newVP = this.subdivision4Viewport(srcVP, isVertical);
    //   if (!newVP) {
    //     console.error("\tERROR: Can't subdivide this!!!.");
    //   } else {
    //     console.log(`\tAdded new viewport "${newVP.name}".`);
    //   }
    // }

  } // public render(): void


  public handleWindowResize = () => {

    this._width = this._container.clientWidth;
    this._height = this._container.clientHeight;

    console.log(" +--------------------------------------------------------------------------------");
    console.log(" | GRAPHICPROCESSOR.handleWindowResize():");
    console.log(` | (left, top) = (${this._left}, ${this._top})            w * h = ${this._width}, ${this._height}`);
    console.log(" +--------------------------------------------------------------------------------");

    const aspect = this._width / this._height;
    this._renderer.setSize(this._width, this._height);

    // Debemos actualizar ambas camaras, aunque no esten activas.
    // La camara de perspectiva.
    this._perspCamera.aspect = aspect;
    this._perspCamera.updateProjectionMatrix();

    // La camara ortografica.
    const frustumSize = this._height;
    this._orthoCamera.left = (-frustumSize * aspect) / 2;
    this._orthoCamera.right = (frustumSize * aspect) / 2;
    this._orthoCamera.top = frustumSize / 2;
    this._orthoCamera.bottom = -frustumSize / 2;
    this._orthoCamera.updateProjectionMatrix();

    // Ademas al actualizar las camaras el control de movimiento debe actualizarse.
    if (this._perspControls) {
      this._perspControls.update(this._clock.getDelta());
    }
    if (this._orthoControls) {
      this._orthoControls.update(this._clock.getDelta());
    }

    // Toca actualizar todos los viewports y sus camaras y gizmos disponibles.
    if (this._gizmo) {
      // Este es el de la escena principal.
      this._gizmo.handleResize(this._width, this._height);
    }

    // Resize de los viewports.
    this._mngrVP.handleResize();

    // La tropa de los FX tambien requiere actualizacion.
    // this._composerFX.setSize(this._width, this._height);

    // Esto es necesario para que no falle el raycast sobre lineas con grosor...
    // TODO: esto se supone que algún día lo corregirán en la librería
    materialCache.updateLineMaterialResolution(window.innerWidth, window.innerHeight);
  };

  public exportFile(): void {
    exportGLTF(this.getMainScene());
  }
  public importFile(file: string): void {
    importGLTF(this.getMainScene(), file, () => this.render());
  }

  /**
   * Devuelve el viewport actualmente con el foco.
   * Incluso podria ser la vista principal (que no es un viewport), pero SIEMPRE existe.
   * \ToDo: Pasarla al manager de VP.
   */
  public getActiveViewport(): GraphicViewport {
    return this._mngrVP.getActiveViewport();
  }

  /**
   * Cambiar el viewport activo, pero delegando la accion al manager de GVP's.
   */

  public setActiveViewport(newViewport: GraphicViewport): boolean {
    this._activeViewport = newViewport;
    return this._mngrVP.setActiveViewport(newViewport);
  }

  /**
   * Llamada desde el manager de GVP's cuando efectivamente ha habido un cambio de viewport.
   * @param newViewport 
   */
  public dispatchViewportEvent(newViewport: GraphicViewport): void {
    this._activeViewport = newViewport;
    this.viewportObserver.dispatch({
      type: ViewportActionType.CHANGE_ACTIVE_VIEWPORT,
      payload: {
        viewport: newViewport,
        container: this._container
      }
    });
  }

  /**
   * A partir del div dado se busca a su vp propietario y se lo coloca como el activo en curso.
   * En caso de error se devuelve false.
   *
   * @param {HTMLDivElement} div
   * @returns {boolean}
   * @memberof GraphicProcessor
   */
  public setActiveViewport4Div(div: HTMLDivElement): boolean {

    // Podria ser el mainVP???

    // O bien lo buscamos entre los VP definidos.
    let vp: GraphicViewport | null = null;
    vp = this._mngrVP.getViewport4Div(div);

    if (vp) {
      this.setActiveViewport(vp);
      return true;
    }
    return false;
  }

  /**
   * Para agregar bolitas en posiciones como mecanismo de depuracion.
   * Si no se da escena se tiran en la escena principal.
   *
   * @param {number} x
   * @param {number} y
   * @param {number} z
   * @param {number} radius
   * @param {string} name
   * @param {number} color
   * @param {THREE.Scene} [scene]
   * @memberof GraphicProcessor
   */
  public addDebugBall(
    x: number,
    y: number,
    z: number,
    radius: number,
    name: string,
    color: number,
    scene?: THREE.Scene,
    hollow: boolean = false
  ): THREE.Object3D {
    const obj = new THREE.Group();
    const geometry = new THREE.SphereBufferGeometry(radius, 10, 10);

    if (!hollow) {
      // No es hueco, luego lleva material.
      const meshMaterial = new THREE.MeshPhongMaterial({ color: color, emissive: 0x072534, side: THREE.DoubleSide, flatShading: true });
      obj.add(new THREE.Mesh(geometry, meshMaterial));
    }

    // Si es hueco usamos el color para las lineas.
    const lineMaterial = new THREE.LineBasicMaterial({ color: hollow ? color : 0xffffff, transparent: true, opacity: 0.5 });

    obj.add(new THREE.LineSegments(geometry, lineMaterial));
    obj.position.x = x;
    obj.position.y = y;
    obj.position.z = z;
    obj.name = name;
    if (scene) {
      scene.add(obj);
    } else {
      // A la escena principal por defecto.
      this.getMainScene().add(obj);
    }
    return obj;
  }

  /**
   * Agrega un objeto auxiliar para debug formado por unos ejes 3D y una flecha.
   * La direccion de la flecha debe ser unitaria.
   *
   * @param {THREE.Vector3} pos
   * @param {THREE.Vector3} dir
   * @param {number} lenAxes
   * @param {number} lenArrow
   * @param {string} name
   * @param {THREE.Scene} [scene]
   * @returns {THREE.Object3D}
   * @memberof GraphicProcessor
   */
  public addDebugAxes(pos: THREE.Vector3, dir: THREE.Vector3, lenAxes: number, lenArrow: number, name: string, scene?: THREE.Scene): THREE.Object3D {
    const obj = new THREE.Group();
    const axes = new THREE.AxesHelper(lenAxes);
    axes.position.set(pos.x, pos.y, pos.z);
    obj.add(axes);
    const arrow = new THREE.ArrowHelper(dir, pos, lenArrow);
    obj.add(arrow);
    obj.name = name;
    if (scene) {
      scene.add(obj);
    } else {
      // A la escena principal por defecto.
      this.getMainScene().add(obj);
    }
    return obj;
  }

  /**
   * Muestra cierta informacion por consola.
   */
  public seeRendererInfo() {
    console.log("RENDERER INFO:");
    console.log("==============");
    if (this._renderer) {
      const info = this._renderer.info;
      console.log(`\t#Geometries: ${info.memory.geometries}`);
      console.log(`\t#Textures:   ${info.memory.textures}`);
      console.log(`\t#Calls:      ${info.render.calls}`);
      console.log(`\t#Frame:      ${info.render.frame}`);
      console.log(`\t#Lines:      ${info.render.lines}`);
      console.log(`\t#Points:     ${info.render.points}`);
      console.log(`\t#Triangles:  ${info.render.triangles}`);
      // Sacamos las dimensiones propuestas del canvas.
      console.log(`\t#Size:       ${this._width} * ${this._height}`);
      // Tambien sacamos la anchura y altura real mas el rect del div canvas.
      const dimensions = new THREE.Vector2();
      this._renderer.getDrawingBufferSize(dimensions);
      console.log(`\t#Real size:  ${dimensions.x} * ${dimensions.y}`);
      const r = this._container.getBoundingClientRect();
      console.log(`\t#Rect:       (${r.left}, ${r.top}, ${r.width}, ${r.height})`);
    } else {
      console.error("\tNot available.");
    }
  }

  /** Captura imagen del canvas de rendering */
  _pendingSnapshot: boolean;
  _onSaveSnapshot: (snapshot: string) => void;

  public takeSnapshot() {
    this._pendingSnapshot = true;
    return new Promise<string>((resolve) => {
      this._onSaveSnapshot = resolve;
    });
  }
  private takeSnapshotNow() {
    const snapshot = this._renderer.domElement.toDataURL().replace("image/png", "image/octet-stream");
    this._onSaveSnapshot(snapshot);
    this._pendingSnapshot = false;
  }

  /**
   * Funcion para crear 4 viewports directamente, que ocupen toda la pantalla y se basan en elementos HTML, de la forma:
   *
   *                  +-------------------------+-------------------------+
   *                  |                         |                         |
   *                  |        C                |       D                 |
   *                  |                         |                         |
   *                  +-------------------------+-------------------------+
   *                  |                         |                         |
   *                  |        A                |       B                 |
   *                  |                         |                         |
   *                  +-------------------------+-------------------------+
   *
   * Todos estan referenciados a la ESQUINA SUPERIOR IZQUIERDA (ESI).
   * Vamos ademas a poner el sistema americano para el diedrico:
   *
   *                  +-------------------------+-------------------------+
   *                  |                         |                         |
   *                  | Perspectiva             | Planta                  |
   *                  |                         |                         |
   *                  +-------------------------+-------------------------+
   *                  |                         |                         |
   *                  | Perfil izquierdo        | Alzado                  |
   *                  |                         |                         |
   *                  +-------------------------+-------------------------+
   *
   */
  private create4ViewportsHTML(): void {
    // Por si las moscas, aunque el boton impide la reentrada...
    if (this._mngrVP.isQuadViewportMode()) {
      console.error("WARNING: Ya esta en el modo quadViewport.");
    } else {
      console.clear();
    }

    const w = this.width;
    const h = this.height;

    // Factor multiplicativo a la hora de meter parametros a las camaras ortograficas.
    // Equivale a ampliar el frustum ese...
    const orthoK = 2000;
    const dimXY = 128;

    const createOrthoCamera4Viewport = (vp: GraphicViewport): THREE.OrthographicCamera => {
      const aspect = (w * vp.width01) / (h * vp.height01);
      const frustumSize = orthoK * vp.height01;
      const orthoCamera = this.createOrthographicCamera(aspect, frustumSize);
      return orthoCamera;
    };

    // NOTA: Todo va referenciado a la puta esquina superior izquierda ESI!!!.

    // A: Perfil izquierdo, abajo a la izquierda.
    if (true) {
      const vpA = this._mngrVP.createViewport("vpA", [0, 0.5, 0.5, 0.5]) as GraphicViewport;
      vpA.backgroundColor = new THREE.Color("#505050");
      const orthoCamera = createOrthoCamera4Viewport(vpA);
      orthoCamera.position.set(-1000, 0, 0);
      orthoCamera.lookAt(0, 0, 0);
      vpA.camera = orthoCamera;
      vpA.viewPlaneType = refViewPlane.LEFT;

      // Y asignamos los restantes parametros (div + gizmo) dentro del viewport.
      this._mngrVP.configureViewport_DEL(vpA, dimXY);
      // Finalmente se enchufa en el viewport. Ademas esto es lo que creara el control de movimiento asociado.
      if (this._mngrVP.addViewport(vpA)) {
        console.log(`\tAgregado el viewport "${vpA.name}" para la vista de perfil izquierdo.`);
        // Quiza mas facil que esto sea quitarle la posibilidad de rotar mediante el boton...
        // (vpA.controls as CameraControls).enableRotate = false;
      }
    }

    // B: Alzado.
    if (true) {
      const vpB = this._mngrVP.createViewport("vpB", [0.5, 0.5, 0.5, 0.5]) as GraphicViewport;
      vpB.backgroundColor = new THREE.Color("#505050");
      const orthoCamera = createOrthoCamera4Viewport(vpB);
      orthoCamera.position.set(0, -1000, 0);
      orthoCamera.lookAt(0, 0, 0);
      vpB.camera = orthoCamera;
      vpB.viewPlaneType = refViewPlane.FRONT;

      this._mngrVP.configureViewport_DEL(vpB, dimXY);
      if (this._mngrVP.addViewport(vpB)) {
        console.log(`\tAgregado el viewport "${vpB.name}" para la vista de alzado.`);
        // Quiza mas facil que esto sea quitarle la posibilidad de rotar mediante el boton...
        // (vpB.controls as CameraControls).enableRotate = false;
      }
    }

    // C: Perspectiva 3D guai del Paraguay.
    if (true) {
      const vpC = this._mngrVP.createViewport("vpC", [0, 0, 0.5, 0.5]) as GraphicViewport;
      vpC.backgroundColor = new THREE.Color("#505050");
      const aspectC = (w * vpC.width01) / (h * vpC.height01);
      const cameraC = this.createPerspectiveCamera(aspectC);
      cameraC.position.set(0, -5000, 1800);
      cameraC.lookAt(0, 0, 0);
      vpC.camera = cameraC;
      vpC.viewPlaneType = refViewPlane.DEF;

      this._mngrVP.configureViewport_DEL(vpC, dimXY);
      if (this._mngrVP.addViewport(vpC)) {
        console.log(`tAgregado el viewport "${vpC.name}" para la vista de perspectiva libre.`);
      }
    }

    // D: Planta.
    if (true) {
      const vpD = this._mngrVP.createViewport("vpD", [0.5, 0, 0.5, 0.5]) as GraphicViewport;
      vpD.backgroundColor = new THREE.Color("#505050");
      const orthoCamera = createOrthoCamera4Viewport(vpD);
      orthoCamera.position.set(0, 0, 1000);
      orthoCamera.lookAt(0, 0, 0);
      vpD.camera = orthoCamera;
      vpD.viewPlaneType = refViewPlane.TOP;

      this._mngrVP.configureViewport_DEL(vpD, dimXY);
      if (this._mngrVP.addViewport(vpD)) {
        console.log(`\tAgregado el viewport "${vpD.name}" para la vista de planta.`);
        // Le quito la rotacion al control de movimiento.
        // (vpD.controls as CameraControls).enableRotate = false;
        // controls.dampingFactor = 0.2;
      }
    }
  }

  /**
   * Funcion para crear los 3 viewports del modo de funcionamiento "GreyStruc" (aka GSC3, el de las plantas de los DXF) directamente,
   * (respectivamente "storeys", "plan" y "elevation"), con sus 2 separadores y ocupando toda la pantalla, basados en elementos HTML,
   * de la forma:
   *
   *                  +---------------+-------------------------+
   *                  |               |                         |^
   *                  |               |                         ||
   *                  | [0] "storeys" | [1] "plan"              |70%
   *                  |   C.Persp.    |   C.Orthog.  ^          ||
   *                  |               |                         ||
   *                  |               |                         |v
   *                  |             <SH>------------SV----------+
   *                  |               |              v          |^
   *                  |               | [2] "elevation"         |30%
   *                  |               |   C.Orthog.             |v
   *                  +---------------+-------------------------+
   *                   <-----30%-----> <--------70%------------>
   *
   * Todos estan referenciados a la ESI.
   * En el vp "storeys" tenemos una camara de perspectiva y mostramos las N plantas disponibles, mientras que en "plan"
   * y "elevation" tenemos sendas camaras ortograficas y representamos la planta y el alzado del nivel/capa/DXF seleccionado.
   * Ademas incluimos un separador vertical entre [0] y [1] + [2] y otro separador horizontal entre [1] y [2].
   */
  private create3ViewportsHTML(): void {
    // Por si las moscas, aunque el boton impide la reentrada...
    if (this._mngrVP.isTripleViewportMode()) {
      console.error("WARNING: Ya esta en el modo TripleViewport.");
    } else {
      // console.clear();
    }

    const w = this.width;
    const h = this.height;

    // Para que los separadores funcionen correctamente necesitamos replicar un arbol de div's que permita su intercalado y
    // correcto redimensionado mediante los mismos.
    const fatherDiv0 = this.container;

    if (GraphicViewport.testDiv4NonIntegerDims(fatherDiv0)) {
      console.error("\tEl container inicial tiene dimensiones decimales!!!.");
    }

    // Factor multiplicativo a la hora de meter parametros a las camaras ortograficas.
    // Equivale a ampliar el frustum ese...
    const orthoK = 2000;
    const dimXY = 128;

    // Inicialmente la parte izquierda ("storeys") ocupara el 30% de la anchura total.
    const leftTCP = 0.3;
    // Y la superior ("plan"), el 70% de la altura total.
    const topTCP = 0.7;

    const createOrthoCamera4Viewport = (vp: GraphicViewport): THREE.OrthographicCamera => {
      const aspect = (w * vp.width01) / (h * vp.height01);
      const frustumSize = orthoK * vp.height01;
      const orthoCamera = this.createOrthographicCamera(aspect, frustumSize);
      return orthoCamera;
    };

    // [0] "storeys", toda la izquierda, con un porcentaje de anchura dado por leftTCP.
    if (true) {
      const vp0 = this._mngrVP.createViewport("storeys", [0, 0, leftTCP, 1.0]) as GraphicViewport;
      vp0.backgroundColor = new THREE.Color("#505050");
      const aspect = (w * vp0.width01) / (h * vp0.height01);
      const camera = this.createPerspectiveCamera(aspect);
      camera.position.set(0, -5000, 1800);
      camera.lookAt(0, 0, 0);
      vp0.camera = camera;
      vp0.viewPlaneType = refViewPlane.DEF;

      // Esta es la que crea el div para el VP en funcion de los valores *01 previamente dados.
      // En la vista en perspectiva tenemos el gizmo completamente responsivo.
      const isGizmoClickable = true;
      this._mngrVP.configureViewport_DEL(vp0, dimXY, undefined, undefined, fatherDiv0, isGizmoClickable);
      if (this._mngrVP.addViewport(vp0)) {
        console.log(`\tAgregado el viewport "${vp0.name}" para la vista de plantas en perspectiva libre.`);
      }

      if (true) {
        // Le acoplamos a la camara el mecanismo de incremento de precision a ver si cuela...
        vp0.controls?.addEventListener("update", this._updateEvntLstnr);
        console.log("Conectado el mecanismo de incremento de precision a la camara de perspectiva del vp 'storeys'.");
      }
    }

    // Necesito el padre div de los 2 proximos vp's, que es justamente la diferencia entre el div "storeys" descontandole su
    // propio padre, es decir que me quedo con la parte derecha:
    const fatherDiv12 = document.createElement("div");
    if (true) {
      fatherDiv12.id = "fatherDiv12";
      fatherDiv12.style.left = (100 * leftTCP) + "%";
      fatherDiv12.style.top = "0%";
      fatherDiv12.style.width = (100 * (1 - leftTCP)) + "%";
      fatherDiv12.style.height = "100%";
      fatherDiv12.style.position = "absolute";
      // OJO, que sin esto no se ve un pijo a los otros 2 vp's.
      fatherDiv0.appendChild(fatherDiv12);
    }

    // [1] "plan"
    if (true) {
      const vp1 = this._mngrVP.createViewport("plan", [leftTCP, 0, 1 - leftTCP, topTCP]) as GraphicViewport;
      vp1.backgroundColor = new THREE.Color("#505050");
      const orthoCamera = createOrthoCamera4Viewport(vp1);
      orthoCamera.position.set(0, 0, 1000);
      orthoCamera.lookAt(0, 0, 0);
      vp1.camera = orthoCamera;
      vp1.viewPlaneType = refViewPlane.TOP;
      // Esta vista es fija y por tanto su gizmo no es doble-clickable para cambiar de ejes.
      const isGizmoClickable = false;
      this._mngrVP.configureViewport_DEL(vp1, dimXY, undefined, undefined, fatherDiv12, isGizmoClickable);
      if (this._mngrVP.addViewport(vp1)) {
        console.log(`\tAgregado el viewport "${vp1.name}" para la vista de planta.`);
        // Le quito la rotacion al control de movimiento.
        // (vpD.controls as CameraControls).enableRotate = false;
        // controls.dampingFactor = 0.2;
      }
    }

    // [2] "elevation"
    if (true) {
      const vp2 = this._mngrVP.createViewport("elevation", [leftTCP, topTCP, 1 - leftTCP, 1 - topTCP]) as GraphicViewport;
      vp2.backgroundColor = new THREE.Color("#505050");
      const orthoCamera = createOrthoCamera4Viewport(vp2);
      orthoCamera.position.set(0, -1000, 0);
      orthoCamera.lookAt(0, 0, 0);
      vp2.camera = orthoCamera;
      vp2.viewPlaneType = refViewPlane.FRONT;
      // Esta vista es fija y por tanto su gizmo no es doble-clickable para cambiar de ejes.
      const gizmoClickable = false;
      this._mngrVP.configureViewport_DEL(vp2, dimXY, undefined, undefined, fatherDiv12, gizmoClickable);
      if (this._mngrVP.addViewport(vp2)) {
        console.log(`\tAgregado el viewport "${vp2.name}" para la vista de alzado.`);
        // Quiza mas facil que esto sea quitarle la posibilidad de rotar mediante el boton...
        // (vpB.controls as CameraControls).enableRotate = false;
      }
    }

    // Quedan los separadores entre estos malvados, ademas de englobar a las 2 vistas ortograficas en un mismo padre.
    // Tambien debemos quitar los pseudobotones para no crear mas subViewports.
    if (true) {
      const vp0 = this._mngrVP.getViewport("storeys") as GraphicViewport;
      const vp1 = this._mngrVP.getViewport("plan") as GraphicViewport;
      const vp2 = this._mngrVP.getViewport("elevation") as GraphicViewport;
      const div0 = vp0.elemHTML;
      const div1 = vp1.elemHTML;
      const div2 = vp2.elemHTML;

      div0.id = vp0.name + "0+Div";
      div1.id = vp1.name + "1+Div";
      div2.id = vp2.name + "2+Div";

      if (GraphicViewport.testDiv4NonIntegerDims(div0)) {
        console.error("\tEl div0 '" + div0.id + "' tiene dimensiones decimales!!!.");
      }
      if (GraphicViewport.testDiv4NonIntegerDims(div1)) {
        console.error("\tEl div1 '" + div1.id + "' tiene dimensiones decimales!!!.");
      }
      if (GraphicViewport.testDiv4NonIntegerDims(div2)) {
        console.error("\tEl div2 '" + div2.id + "' tiene dimensiones decimales!!!.");
      }

      // Comprobaciones para ver si se pierde algun puto pixel.
      GraphicViewportManager.testFitting4DivsABC(div0, fatherDiv12, fatherDiv0, true);
      GraphicViewportManager.testFitting4DivsABC(div1, div2, fatherDiv12, false);

      // Resuelto el slider entre ambos.
      let isVertical = false;
      const sliderDiv12 = this._mngrVP.configureSeparatorSlider4DivsAB(div1, div2, isVertical, fatherDiv12);
      sliderDiv12.id = "slider_1+2";

      // Ahora queda el slider entre "storeys" y el padre fatherDiv12.
      isVertical = true;
      const sliderDiv012 = this._mngrVP.configureSeparatorSlider4DivsAB(div0, fatherDiv12, isVertical, fatherDiv0);
      sliderDiv012.id = "slider_0+12";
    }

  } // private create3ViewportsHTML(): void

  /**
   * Funcion para crear los 3 viewports del modo de funcionamiento "GreyStruc/ETabs" planta + alzado + perspectiva 3D:
   *
   *                  +--------------------+--------------------------------+
   *                 ^|                    |                                |^
   *                 ||                    |                                ||
   *               70%| [1] "plan"         | [0] "storeys"                  ||
   *                 ||   C.Orthog.        |   C.Persp.                     ||
   *                 ||                    |                                || 100%
   *                 v|                  <SV>                               ||
   *                  +-------<SH>---------|                                ||
   *                 ^| [2] "elevation"    |                                ||
   *               30%|   C.Orthog         |                                ||
   *                 v|                    |                                |v
   *                  +--------------------+--------------------------------+
   *                   <--------30%-------> <-------------70%-------------->
   *
   * Todos estan referenciados a la ESI.
   * En el vp "storeys" tenemos una camara de perspectiva y mostramos las N plantas disponibles, mientras que en "plan"
   * tenemos camara ortografica representando la planta del nivel/capa/DXF seleccionado y en "elevation" tenemos la
   * vista de alzado tambien en ortografica.
   * Ademas incluimos un separador vertical entre "storeys" y las otras 2 que a su vez tienen un separador horizontal.
   */
  private create2ETabsViewportsHTML(): void {
    // Por si las moscas, aunque el boton impide la reentrada...
    if (this._mngrVP.isTripleViewportMode()) {
      console.error("WARNING: Ya esta en el modo TripleViewport.");
    } else {
      // console.clear();
    }

    const w = this.width;
    const h = this.height;

    // Para que los separadores funcionen correctamente necesitamos replicar un arbol de div's que permita su intercalado y
    // correcto redimensionado mediante los mismos.
    const fatherDiv0 = this.container;

    if (GraphicViewport.testDiv4NonIntegerDims(fatherDiv0)) {
      console.error("\tEl container inicial tiene dimensiones decimales!!!.");
    }

    // Factor VERTICAL para camaras ortograficas. Nos da la dimension (en metros) en el eje Y de la zona que se quiere
    // visualizar (mas o menos). Asi pues visualizaremos una anchura de 100 metros (en el eje Y) inicialmente con esta
    // camara. Para la correspondiente dimension en X se tendra en cuenta el aspect ratio...
    // Lo ponemos en 2000 ya que da problemas en el picking/rayCasting con orthoCameras...
    const orthoK = 2000;
    const dimXY = 128;

    // Inicialmente la parte izquierda ("plan" + "elevation") ocupara el 30% de la anchura total y la otra el 70%.
    // Ademas "plan" y "elevation" ocupan verticalmente el 70% + 30% de la altura total.
    const leftTCP = 0.3;
    const topTCP = 0.7;

    let fatherDiv12Left: HTMLDivElement | null = null;
    let divRight4Storeys: HTMLDivElement | null = null;
    let divTop4Plan: HTMLDivElement | null = null;
    let divBottom4Elevation: HTMLDivElement | null = null;
    if (true) {
      let res = GraphicViewport.createDivision4Div(fatherDiv0, 100 * leftTCP, true);
      if (res) {
        fatherDiv12Left = res[0];
        divRight4Storeys = res[1];
        res = GraphicViewport.createDivision4Div(fatherDiv12Left, 100 * topTCP, false);
        if (res) {
          divTop4Plan = res[0];
          divBottom4Elevation = res[1];
        }
      }
    }

    const createOrthoCamera4Viewport = (vp: GraphicViewport): THREE.OrthographicCamera => {
      const aspect = (w * vp.width01) / (h * vp.height01);
      const frustumSize = orthoK * vp.height01;
      const orthoCamera = this.createOrthographicCamera(aspect, frustumSize);
      return orthoCamera;
    };

    const globalDiv = this._mngrVP.owner.container;
    // Estos offsets son para situar a los gizmos con respecto a la esquina superior derecha del VP.
    const [offsetX, offsetY] = [undefined, 40];

    // [0] "plan", 30% izquierda + 70% arriba.
    if (true) {
      const vp = this._mngrVP.createViewport("plan", [0, 0, leftTCP, topTCP]) as GraphicViewport;
      vp.backgroundColor = new THREE.Color("#505050");
      const orthoCamera = createOrthoCamera4Viewport(vp);
      // Esta es la primera posicion de la camara ortografica. La colocamos a esa altura para que no haya problemas de
      // corte en la visualizacion de edificios muy altos, pues con 250 metros da para unas 50 plantas...
      orthoCamera.position.set(0, 0, 250);
      orthoCamera.lookAt(0, 0, 0);
      vp.camera = orthoCamera;
      vp.viewPlaneType = refViewPlane.TOP;
      // Esta vista es fija y por tanto su gizmo no es doble-clickable para cambiar de ejes.
      const isGizmoClickable = false;
      vp.configure(globalDiv, dimXY, offsetX, offsetY, fatherDiv12Left as HTMLDivElement, isGizmoClickable, divTop4Plan as HTMLDivElement);
      vp.type = ViewportType.ViewPlanOrtho2D;
      if (this._mngrVP.addViewport(vp)) {
        console.log(`\tAgregado el viewport "${vp.name}" para la vista de planta.`);
      }

      if (GraphicViewport.testDiv4NonIntegerDims(vp.elemHTML)) {
        console.error("\tEl vp 'plan' tiene dimensiones decimales!!!.");
      }
    }

    // [1] "elevation", 30% izquierda + 30% abajo.
    if (true) {
      const vp = this._mngrVP.createViewport("elevation", [0, topTCP, leftTCP, 1 - topTCP]) as GraphicViewport;
      vp.backgroundColor = new THREE.Color("#505050");
      const orthoCamera = createOrthoCamera4Viewport(vp);

      if (true) {
        // Esto esta dando problemas por el far y el near, a pesar de ser abundantes de sobra. Asi que los cambio aqui.
        // No entiendo porque, pero el valor negativo parece solucionarlo todo...
        orthoCamera.near = -1000;
        orthoCamera.far = +1000;
        // Al variar lo anterior se requiere esto.
        orthoCamera.updateProjectionMatrix();
      }
      orthoCamera.position.set(0, -100, 0);
      orthoCamera.lookAt(0, 0, 0);
      vp.camera = orthoCamera;
      vp.viewPlaneType = refViewPlane.FRONT;
      // Esta vista es fija y por tanto su gizmo no es doble-clickable para cambiar de ejes.
      const isGizmoClickable = false;
      vp.configure(globalDiv, dimXY, offsetX, offsetY, fatherDiv12Left as HTMLDivElement, isGizmoClickable, divBottom4Elevation as HTMLDivElement);
      vp.type = ViewportType.ViewElevOrtho2D;
      if (this._mngrVP.addViewport(vp)) {
        console.log(`\tAgregado el viewport "${vp.name}" para la vista de alzado.`);
      }

      if (GraphicViewport.testDiv4NonIntegerDims(vp.elemHTML)) {
        console.error("\tEl vp 'elevation' tiene dimensiones decimales!!!.");
      }
    }

    // [2] "storeys", toda la derecha, con un porcentaje de anchura dado por 1 - leftTCP.
    if (true) {
      const vp = this._mngrVP.createViewport("storeys", [leftTCP, 0, 1 - leftTCP, 1.0]) as GraphicViewport;
      vp.backgroundColor = new THREE.Color("#505050");
      const aspect = (w * vp.width01) / (h * vp.height01);
      const camera = this.createPerspectiveCamera(aspect);
      // camera.position.set(20, -50, 100);
      // Con este valor inicialmente miramos desde -Y para poder ver los ejes +X y +Z.
      // camera.position.set(100, -150, 100);

      camera.lookAt(0, 0, 0);
      vp.camera = camera;
      vp.viewPlaneType = refViewPlane.DEF;

      // Esta es la que crea el div para el VP en funcion de los valores *01 previamente dados.
      // En la vista en perspectiva tenemos el gizmo completamente responsivo.
      const isGizmoClickable = true;

      vp.configure(globalDiv, dimXY, offsetX, offsetY, fatherDiv0, isGizmoClickable, divRight4Storeys as HTMLDivElement);
      vp.type = ViewportType.ViewPerspective3D;
      if (this._mngrVP.addViewport(vp)) {
        console.log(`\tAgregado el viewport "${vp.name}" para la vista de plantas en perspectiva libre.`);
      }

      // Es esto el responsable de que no se pueda fijar cierta vista superior???. No señor.
      if (true) {
        // Le acoplamos a la camara el mecanismo de incremento de precision a ver si cuela...
        vp.controls?.addEventListener("update", this._updateEvntLstnr);
        console.log("Conectado el mecanismo de incremento de precision a la camara de perspectiva del vp 'storeys'.");
      }

      if (GraphicViewport.testDiv4NonIntegerDims(vp.elemHTML)) {
        console.error("\tEl vp 'storeys' tiene dimensiones decimales!!!.");
      }
    }

    // Quedan los separadores entre estos malvados, ademas de englobar a las 2 vistas ortograficas en un mismo padre.
    // Tambien debemos quitar los pseudobotones para no crear mas subViewports.
    if (true) {
      const vp0 = this._mngrVP.getViewport("storeys") as GraphicViewport;
      const vp1 = this._mngrVP.getViewport("plan") as GraphicViewport;
      const vp2 = this._mngrVP.getViewport("elevation") as GraphicViewport;
      const div0 = vp0.elemHTML;
      const div1 = vp1.elemHTML;
      const div2 = vp2.elemHTML;

      div0.id = "R_000";
      div1.id = "L_000";
      div2.id = "D_000";

      if (GraphicViewport.testDiv4NonIntegerDims(div0)) {
        console.error("\tEl div0 '" + div0.id + "' tiene dimensiones decimales!!!.");
      }
      if (GraphicViewport.testDiv4NonIntegerDims(div1)) {
        console.error("\tEl div1 '" + div1.id + "' tiene dimensiones decimales!!!.");
      }
      if (GraphicViewport.testDiv4NonIntegerDims(div2)) {
        console.error("\tEl div1 '" + div2.id + "' tiene dimensiones decimales!!!.");
      }

      // Comprobaciones para ver si se pierde algun puto pixel.
      GraphicViewportManager.testFitting4DivsABC(fatherDiv12Left as HTMLDivElement, div0, fatherDiv0, true);
      GraphicViewportManager.testFitting4DivsABC(div1, div2, fatherDiv12Left as HTMLDivElement, false);

      // Resuelto el slider entre plan y elevation.
      let isVertical = false;
      const sliderDiv12 = this._mngrVP.configureSeparatorSlider4DivsAB(div1, div2, isVertical, fatherDiv12Left as HTMLDivElement);

      // Resuelto el slider entre la parte izquierda y storeys.
      isVertical = true;
      const sliderDiv012 = this._mngrVP.configureSeparatorSlider4DivsAB(fatherDiv12Left as HTMLDivElement, div0, isVertical, fatherDiv0);

      // Finalmente colocamos las camaras en los vp's.
      // Vista inicial en una posicion que permite ver el cuadrante (+, +) y es solidaria con los ejes en planta.
      if (vp0.controls) {
        this.locatePerspectiveCameraInitialViewPoint(vp0);
        // vp0.autoZoom2RootAABB();
        // Necesario para que aparezca correctamente.
        vp0.controls.update(vp0.owner.owner._clock.getDelta());
        this._mngrVP.updateViewport4Div(vp0);
      }

      // El autozoom para maximizar la escena en la parte orthoCamera da problemas.
      if (vp1.controls) {
        vp1.autoZoom2RootAABB();
        // Esto es necesario para que aparezca correctamente ampliada y centrada.
        vp1.controls.update(vp1.owner.owner._clock.getDelta());
        // Esto resuelve el problema del salto de tamaños que habia antes.
        this._mngrVP.updateViewport4Div(vp1);
      }

      if (vp2.controls) {
        vp2.autoZoom2RootAABB();
        // Esto es necesario para que aparezca correctamente ampliada y centrada.
        vp2.controls.update(vp2.owner.owner._clock.getDelta());
        // Esto resuelve el problema del salto de tamaños que habia antes.
        this._mngrVP.updateViewport4Div(vp2);
      }

      // Colocamos como vp activo al de la perspectiva.
      this.setActiveViewport(vp0);
      vp0.setInfo("This viewport currently has the focus...");
    }

  } // private create2ETabsViewportsHTML(): void

  public enableMultiViewportMode(): void {
    const vpA = this._mngrVP.getViewport("vpA") as GraphicViewport;
    if (!vpA) {
      this.create4ViewportsHTML();
    } else {
      // Ahora activo todo lo que empiece por vp y desactivo lo que empiece por Left y Right.
      this._mngrVP.setAllViewportsOnOff("LEFT", false);
      this._mngrVP.setAllViewportsOnOff("RIGHT", false);
      this._mngrVP.setAllViewportsOnOff("UP", false);
      this._mngrVP.setAllViewportsOnOff("DOWN", false);
      this._mngrVP.setAllViewportsOnOff("vp", true);
    }
    this.renderMainView(false);
  }

  public disableMultiViewportMode(): void {
    const vpA = this._mngrVP.getViewport("vpA") as GraphicViewport;
    if (vpA) {
      this._mngrVP.setAllViewportsOnOff("vp", false);
      this._mngrVP.setAllViewportsOnOff("LEFT", true);
      this._mngrVP.setAllViewportsOnOff("RIGHT", true);
      this._mngrVP.setAllViewportsOnOff("UP", true);
      this._mngrVP.setAllViewportsOnOff("DOWN", true);

      this.renderMainView(true);
    }
  }

  /**
   * Activador del modo GreyStruc con la doble vista a la ETabs.
   * En caso de error se devuelve false.
   *
   * @memberof GraphicProcessor
   */
  public enableGreyStrucMode() {
    const vp0 = this._mngrVP.getViewport("storeys") as GraphicViewport;
    if (!vp0) {
      // this.create3ViewportsHTML();
      // Antes de pasarnos al modo ETabs sera preciso desactivar la actividad del mainViewport para que NO INTERFIERA
      // con los nuevos vp's.
      this._mainViewport.gizmo?.unSubscribeMouseEvents();
      this._gizmo.unSubscribeMouseEvents();

      this.create2ETabsViewportsHTML();
      // Por defecto activamos el vp "storeys" el unico 3D.
      // this._mngrVP.setAllViewportsOnOff("storeys", true);

      const vp = this._mngrVP.getViewport("storeys") as GraphicViewport;
      if (vp) {
        vp.setActiveViewport();
      }
    } else {
      // Ahora activo los 3 vp's necesarios, desactivando todo lo demas.
      this._mngrVP.setAllViewportsOnOff("storeys", false);
      this._mngrVP.setAllViewportsOnOff("plan", false);
      // this._mngrVP.setAllViewportsOnOff("elevation", false);
    }
    this.renderMainView(false);

    // Ojo, que tambien hay que desactivar el mainViewport.
    this._mainViewport.enabled = false;
  }

  /**
   * Desactivador del modo GreyStruc y vuelta al modo normal.
   *
   * @memberof GraphicProcessor
   */
  public disableGreyStrucMode(): void {
    if (this._isGreyStrucMode === false) {
      window.alert("DESACTIVACION: No estabamos en el modo de funcionamiento GREYSTRUC.");
      return;
    }
    const vp0 = this._mngrVP.getViewport("storeys") as GraphicViewport;
    if (vp0) {
      this._mngrVP.setAllViewportsOnOff("storeys", false);
      this._mngrVP.setAllViewportsOnOff("plan", true);
      this._mngrVP.setAllViewportsOnOff("elevation", true);

      this.renderMainView(true);
    }
  }

  /**
   * Version simplificada gracias a que utilizamos los CameraControls.
   * Es el que mejor va. Ya no usamos la animacion de camara, que puede ser costosa.
   */
  public zoomFitV2(): void {
    const ctrls = this._activeViewport.controls;
    if (ctrls) {
      const camera = this._activeViewport.camera;
      if ((camera as THREE.PerspectiveCamera).isPerspectiveCamera) {
        // In persperctive viewports Zoom extent for all visible objects
        const box = new THREE.Box3();
        for (const data of this._dataModelManager.iterAllData()) {
          if (data.isVisible) {
            box.expandByObject(data.graphicObj);
          }
        }
        const s = new THREE.Sphere();
        box.getBoundingSphere(s);
        if (s.radius) {
          ctrls.fitToSphere(s, false);
        }

      } else {
        // In ortographic viewports Zoom extent for only objects in struc layers
        const box = new THREE.Box3();
        const layers = this.getRaycaster().getStrucLayer2Raycast();
        this._dataModelManager.iterAllDataFromLayers(layers, (data) => {
          box.expandByObject(data.graphicObj);
        });
        ctrls.fitToBox(box, false);
      }
    }
  }

  /**
   * Crea y devuelve un objeto grafico en base a un unico punto.
   *
   * @param v
   * @returns
   */
  static createPoint4Vector(v: THREE.Vector3): THREE.Points {
    const geom = new THREE.BufferGeometry();
    const vTA = new Float32Array([v.x, v.y, v.z]);
    geom.setAttribute("position", new THREE.BufferAttribute(vTA, 3));
    return new THREE.Points(geom);
  }

  /**
   * Devuelve un objeto JSON con la configuracion completa de la camara/control de perspectiva correspondiente al mainView.
   * Solo la de esa camara y su control asociado, no la de ninguna otra.
   *
   * @returns {*}
   * @memberof GraphicProcessor
   */
  public mainCameraCfgExportToJSON(): CameraSettings {
    // Saca texto con el JSON de la camara de perspectiva principal.
    const text: string = this._perspControls.toJSON();
    // Ese texto se parsea a un objeto que se devuelve.
    const json = JSON.parse(text);
    let [x, y, z] = json.position;
    // console.log(`SAVED POSITION: (${x.toFixed(3)}, ${y.toFixed(3)}, ${z.toFixed(3)})`);
    [x, y, z] = json.target;
    // console.log(`SAVED TARGET: (${x.toFixed(3)}, ${y.toFixed(3)}, ${z.toFixed(3)})`);
    [x, y, z] = [this._perspCamera.position.x, this._perspCamera.position.y, this._perspCamera.position.z];
    // console.log(`CAMERA POSITION: (${x.toFixed(3)}, ${y.toFixed(3)}, ${z.toFixed(3)})`);
    json.cx = x;
    json.cy = y;
    json.cz = z;
    return json;
  }

  public mainCameraCfgImportFromJSON(json: CameraSettings): void {
    // El JSON leido se convierte en texto con el que se reconstruira la cfg de la camara.
    const txt: string = JSON.stringify(json);
    this._perspControls.fromJSON(txt, false);

    if (true) {
      // A ver esto...
      let [x, y, z] = json.position ? json.position : [0, -10, 20];
      if (json.cx) {
        x = json.cx;
      }
      if (json.cy) {
        y = json.cy;
      }
      if (json.cz) {
        z = json.cz;
      }
      console.log(`NEW LOADED POSITION: (${x.toFixed(3)}, ${y.toFixed(3)}, ${z.toFixed(3)})`);
      this._perspControls.setPosition(x, y, z, false);
      [x, y, z] = json.target ? json.target : [0, +100, 0];
      console.log(`NEW LOADED TARGET: (${x.toFixed(3)}, ${y.toFixed(3)}, ${z.toFixed(3)})`);
      this._perspControls.setTarget(x, y, z, false);
    }
  }

  /**
   * Version para 2 capas, necesaria para el nuevo modo de funcionamiento, basado en dar una capa de las DXF mas su capa asociada.
   *
   * @param vLayers 
   */
  private doMaterialReplacing2GrayExceptLayers(vLayers: THREE.Object3D[]): void {
    let numLayers = vLayers.length;
    if (!numLayers) {
      return;
    }

    // Aqui iremos metiendo los sucesivos nodos que vayamos encontrando como fruto del recorrido del sceneGraph.
    const vAvailableNodes: THREE.Object3D[] = [];
    // Empezamos por la raiz y sus hijos.
    let node = this._sceneManager.rootLayer;
    let numChildren = node.children.length;
    for (let i = 0; i < numChildren; ++i) {
      vAvailableNodes.push(node.children[i]);
    }
    let numAvailables = numChildren;

    // Aqui rellenamos la ristra de materiales grisaceos alternativos, usando la infraestructura de JaWS.
    // \ToDo: Probar algun dia una variacion en la opacidad o lo que se nos ocurra...
    if (this._cache4GrayMaterials.size()) {
      this._cache4GrayMaterials.clearSelection();
    }

    // Mediante el while hacemos un recorrido lineal del arbol de escena, evitando el gravoso traverse() que es recursivo.
    while (numAvailables) {
      // Los nuevos los meto por el FINAL con push(), pero para procesarlos los saco del PRINCIPIO con shift().
      node = vAvailableNodes.shift() as THREE.Group;
      --numAvailables;

      // Comprobacion para ver si estamos en alguna de las capas que se libran.
      if (numLayers) {
        // \ToDo: Algun dia habra que optimizarlo solo para 2 capas, pero de momento lo dejamos para las N que puedan restar.
        let found = false;
        for (let i = 0; i < numLayers; ++i) {
          if (node === vLayers[i]) {
            found = true;
            --numLayers;
            vLayers.splice(i, 1);
            break;
          }
        }

        if (found) {
          // Pasamos del nodo excepcion y de todos sus hijos.
          continue;
        }
      }

      // Si tiene material hay que cambiarlo y contabilizarlo.
      // Forma menos horrorosa de comprobar existencia de propiedades en TS.
      if ((node as any).material !== undefined) {
        // const material = (node as any).material as THREE.Material;
        // result.set(node, material);
        // Y la substitucion. Quizas se necesite un material.update o similar???.
        // De momento lo hacemos con esto.
        this._cache4GrayMaterials.selectObj(node);
      }

      // Si tiene hijos los acumularemos para la continuacion.
      numChildren = node.children.length;
      if (numChildren) {
        for (let i = 0; i < numChildren; ++i) {
          vAvailableNodes.push(node.children[i]);
        }
        numAvailables += numChildren;
      }
    }
  }

  /**
   * De la escena principal, desde #root para abajo (inclusive), se calcula y devuelve un mapa con la visibilidad de todo.
   * Su counterpart es la fmc setVisibilityStatus() que aplica la configuracion aqui producida.
   * El parametro dado es donde se guardaran los datos producidos, y se vacia previamente a su uso.
   * El problema de esta fmc es que recorre todo recursivamente, lo cual es caro.
   * \ToDo: Una funcion alternativa que se base en un recorrido no recursivo.
   *
   * @private
   * @param {Map<THREE.Object3D, boolean>} mapOut
   * @memberof GraphicProcessor
   */
  private getVisibilityStatus(mapOut: Map<THREE.Object3D, boolean>): void {
    if (mapOut.size) {
      mapOut.clear();
    }
    const rootGroup = this._sceneManager.rootLayer;
    // Esto es relativamente costoso, ya que se recorre la escena principal recursivamente y de forma completa a partir del
    // nodo root, acumulando todos los pares objeto+visibilidad.
    rootGroup.traverse((object: THREE.Object3D) => {
      const visible = object.visible;
      mapOut.set(object, visible);
    });
  }

  /**
   * A la escena principal le aplica la configuracion de visibilidad especificada.
   * Es la contraparte de la fmc getVisibilityStatus().
   *
   * @private
   * @param {Map<THREE.Object3D, boolean>} map
   * @memberof GraphicProcessor
   */
  private setVisibilityStatus(map: Map<THREE.Object3D, boolean>): void {
    // Esto debiera ser mas rapido que hacer un traverse de la main scene a partir de root.
    // Aunque no tengo claro si no hay concomitancias ocultas recursivas...
    for (const [obj, isVisible] of map) {
      obj.visible = isVisible;
    }
  }

  /**
   * Dado un objeto grafico correspondiente con la pertinente capa seleccionada, se hace INVISIBLE toda la escena principal
   * (desde #root hacia abajo, inclusive), salvo la capa indicada por obj4Lyr y todo su contenido, directo o recursivo.
   * Ademas para que la capa dada sea visible, todas sus capas ancestro seran VISIBILIZADAS, pero sus posibles "hermanos"
   * seran INVISIBILIZADOS hasta llegar a #root.
   * 
   * Ademas, si forzamos a que siempre se vea la capa dada por cojones, entonces nunca podriamos invisibilizarla desde
   * el control con los solecicos, asi que tenemos el parametro flag opcional para evitar este comportamiento por defecto.
   *
   * 
   *                           #root[+1]
   *                            /   \
   *                           /     \
   *                          /       \
   *                     GrandFather   Other[-1]
   *                      Layer[+]
   *                      /   \
   *                     /     \
   *                    /       \
   *                Father      Uncle
   *                Layer[+]      [-]
   *                /    \
   *               /      \
   *              /        \
   *          Selected      Brother
   *          Layer[+]        [-]
   *           / | \
   *          +  +  +
   *
   * @private
   * @param {THREE.Object3D} obj4Lyr
   * @memberof GraphicProcessor
   */
  private showOnlyLayerHideRemaining(obj4Lyr: THREE.Object3D, alwaysShowLayer: boolean = true): void {
    // [1] La capa dada y todos sus componentes son visibilizados, si es necesario.
    if (alwaysShowLayer) {
      this.applyVisibility2Layer(obj4Lyr, true);
    }

    // [2] Invisibilizar el resto, si o si.
    // Sacamos la lista de los ancestros.
    const vFathers = this.getParentNodes(obj4Lyr);
    // Y la recorremos hacia #root INVISIBILIZANDO a todos los hermanos no incluidos en la misma.
    // Quitamos al padre inicial para no salirnos de #root.
    const numFathers = vFathers.length - 1;
    for (let n = 0; n < numFathers; ++n) {
      const father = vFathers[n];
      // Logicamente el padre debe ser visible para que la capa seleccionada lo pueda ser.
      father.visible = true;
      // Todo hijo que no coincida con el hermano obj4Lyr sera invisibilizado.
      const numChildren = father.children.length;
      if (numChildren > 1) {
        for (let m = 0; m < numChildren; ++m) {
          const child = father.children[m];
          if (child !== obj4Lyr) {
            child.visible = false;
          }
        }
      }
      // Pasamos el testigo hacia arriba.
      obj4Lyr = father;
    }
  }

  /**
   * Version de la fmc showOnlyLayerHideRemaining() en la que se da un array con las capas que se quiere RESPETAR y el resto se invisibilizan.
   * RECUERDA: Debes respetar el estado de visibilidad previo en el arbol de capas del GUI para las capas dadas. El resto se invisibilizan.
   *
   * @private
   * @param {THREE.Object3D[]} vLayers
   * @memberof GraphicProcessor
   */
  private showOnlyLayers(vLayers: THREE.Object3D[]): void {
    let numLayers = vLayers.length;
    if (!numLayers) {
      return;
    }

    // Invisibilizo todo desde #root hacia abajo, pero cuando me encuentro con alguna de las capas dadas las dejo con el estado
    // de visibilidad previo que tenian, para respetar el GUI...
    // Logicamente si ese estado de visibilidad previo era VISIBLE, entonces para que se vean debo hacer visibles a todos sus padres
    // hasta llegar a #root.
    // Necesito una copia del argumento original de la que elimino las capas subcontenidas...
    const vLayers2: THREE.Object3D[] = [];

    // El problema aqui es que en el parametro de entrada haya capas contenidas entre ellas y se pisen la manguera entre ellas,
    // asi que lo primero sera quedarse solo con las de nivel superior. Esto desaparecera pues se supone que solo me daran 2 capas y nunca anidadas.
    for (let i = 0; i < numLayers; ++i) {
      const lyrI = vLayers[i];
      let numContentionsI = 0;
      for (let j = 0; j < numLayers; ++j) {
        if (i !== j) {
          const lyrJ = vLayers[j];
          // Comprobacion psicopatica que desaparecera.
          if (lyrI === lyrJ) {
            debugger;
          } else {
            if (SceneManager.isAncestor(lyrJ, lyrI)) {
              // Si la capa J es ancestro de la I, entonces esta esta contenida.
              ++numContentionsI;
            }
          }
        }
      }
      if (0 === numContentionsI) {
        // Nadie la contiene luego podemos guardarla.
        vLayers2.push(lyrI);
      }
    }

    numLayers = vLayers2.length;

    // Primer recorrido para acumular todos los objetos del scenegraph a partir de root (ambos inclusive) en un mapa lineal
    // junto con su numero total de hijos. Esto nos valdra para descartar subramas inferiores y dejarlas "sin tocar".
    const mObjs = new Map<THREE.Object3D, number>();
    this._sceneManager.rootLayer.traverse((obj: THREE.Object3D) => {
      mObjs.set(obj, 0);

      let parent = obj.parent as THREE.Object3D;
      // Y ahora incrementamos en una unidad a todos los padres de este objeto, hasta llegar a #root que tiene como padre
      // a la escena principal, y donde nos detendriamos.
      while (parent !== this.getMainScene()) {
        const numChild = mObjs.get(parent) as number;
        mObjs.set(parent, numChild + 1);
        parent = parent.parent as THREE.Object3D;
      }
    });

    // Acabado esto tenemos para cada nodo su numero total de hijos, 0 para los nodos hoja y N para los nodos intermedios.
    // Ahora podemos recorrer la maldad linealmente con la posibilidad de saltar las subramas coincidentes con las dadas.
    const vNodes = [...mObjs.keys()];
    const numNodes = mObjs.size;

    for (let i = 0; i < numNodes; ++i) {
      const node = vNodes[i];
      // Buscamos entre las capas dadas aun disponibles. Antes lo hacia con un vLayers2.indexOf(node), pero teniendo en
      // cuenta que se nos daran 2 capas y que esto lo hay que hacer la hostia de veces sera mejor una comprobacion directa.
      // \ToDo: Optimizar eso mas adelante cuando tengamos claro que solo son 2 capas.
      const pos = vLayers2.indexOf(node);
      if (pos === -1) {
        // No esta, luego podemos invisibilizar directamente.
        node.visible = false;
      } else {
        // Si que esta, luego saltamos TOTALMENTE esta subrama y todo su contenido, dejandola como estaba. El salto:
        // Al estar en una estructura lineal simplemente incrementamos el indice posicional para saltar todos los hijos.
        const numChilds = mObjs.get(node) as number;
        i += numChilds;
      }
    }

    // Y ahora solo resta hacer visibles a los ancestros (y solamente a ellos) de las capas dadas que esten visibles.
    for (let i = 0; i < numLayers; ++i) {
      const layer = vLayers2[i];
      // Solo cuando la capa esta visible.
      if (layer.visible) {
        let parent = layer.parent as THREE.Object3D;
        while (parent !== this.getMainScene()) {
          parent.visible = true;
          parent = parent.parent as THREE.Object3D;
        }
      }
    }
  }

  /**
   * Solamente aplica la visibilidad dada a la capa dada y sus descendientes.
   *
   * @private
   * @param {THREE.Object3D} obj4Layer
   * @param {boolean} onOff
   * @memberof GraphicProcessor
   */
  private applyVisibility2Layer(obj4Layer: THREE.Object3D, onOff: boolean): void {
    obj4Layer.visible = onOff;
    // Si la visibilidad es positiva hay que aplicarla a todos los hijos recursivamente, pero si es negativa basta con
    // aplicarla solo al objeto dado que es la subRaiz de la misma.
    if (onOff) {
      obj4Layer.traverse((object: THREE.Object3D) => {
        object.visible = true;
      });
    }
  }

  /**
   * Dado un objeto grafico, devolvemos la lista de capas a las que pertenece, empezando por la mas cercana al mismo y acabando
   * en la capa #root. Ojo que lo que devolvemos es un vector con N entradas que son objetos de Three, donde la entrada [0] es
   * el padre del objeto dado y la [N - 1] es la capa/objeto root.
   *
   * @private
   * @param {THREE.Object3D} obj
   * @returns {THREE.Object3D[]}
   * @memberof GraphicProcessor
   */
  private getParentNodes(obj: THREE.Object3D): THREE.Object3D[] {
    const vResult: THREE.Object3D[] = [];
    const rootGroup = this._sceneManager.rootLayer;

    do {
      const parent = obj.parent;
      if (null === parent) {
        return vResult;
      } else {
        vResult.push(parent);
        obj = parent;
      }
    } while (obj !== rootGroup);

    return vResult;
  }

  public destroyStructModel3D(): void {
    const meshName = "mesh_model3D";
    const sceneMngr = this.getSceneManager();
    let obj3D = sceneMngr.mainScene.getObjectByName(meshName);
    if (obj3D) {
      if (obj3D.userData) {
        // Del objeto grafico saco el modelo inicial.
        let model3D = obj3D.userData as StructModel3D;
        model3D.destroy();
        model3D = undefined!;
        obj3D.userData = null!
      }
      sceneMngr.mainScene.remove(obj3D);
      obj3D = undefined!;
    }
  }

  public getStructModel3D(): StructModel3D | null {
    const meshName = "mesh_model3D";
    const sceneMngr = this.getSceneManager();
    let obj3D = sceneMngr.mainScene.getObjectByName(meshName);
    if (obj3D) {
      if (obj3D.userData) {
        // Del objeto grafico saco el modelo inicial.
        const model3D = obj3D.userData as StructModel3D;
        return model3D;
      }
    }
    window.alert("WARNING: Todavia no hay objeto StructModel3D disponible. Cargue un mesh si es tan amable...");
    return null;
  }

  /**
   * Intenta transformar la camara del viewport activo en curso al nuevo tipo dado:
   * 1: Perspectiva.
   * 2: Ortografica con vista de planta.
   * 3: Ortografica con vista de alzado.
   * Si se efectua la transformacion se devuelve true, en caso contrario, pues la camara ya estaba en el tipo pedido,
   * se devuelve false.
   *
   * @param dstCamType 
   * @returns 
   */
  public transformCurrentViewportCameraTo(
    dstCamType: ViewportType.ViewPerspective3D | ViewportType.ViewPlanOrtho2D | ViewportType.ViewElevOrtho2D
  ): boolean {

    const vp = this.getActiveViewport();
    let srcCam = vp.camera;
    let srcPerspCam: THREE.PerspectiveCamera | null = null;
    let srcOrthoCam: THREE.OrthographicCamera | null = null;
    let srcCamType: ViewportType = vp.type;

    if ((srcCam as THREE.PerspectiveCamera).isPerspectiveCamera) {
      srcPerspCam = srcCam as THREE.PerspectiveCamera;
    } else {
      if ((srcCam as THREE.OrthographicCamera).isOrthographicCamera) {
        srcOrthoCam = srcCam as THREE.OrthographicCamera;
      }
    }

    if (!srcPerspCam && !srcOrthoCam) {
      const errMsg = "ERROR: Current camera is not perspective nor orthographic!!!.";
      console.error(errMsg);
      window.alert(errMsg);
      return false;
    }

    // Casos en los que no hay transformacion pues lo que se pide es lo que hay.
    if (dstCamType === srcCamType) {
      console.log("The view transformation asked for is already executed.");
      return false;
    }

    // Y llegados aqui nos tocan las transformaciones marianas!!!.
    // Podemos pasar de cualquier tipo a cualquier tipo con los id de tipo 1|2|3 diferentes. La duda es la reconstruccion
    // de camaras, controles y la puta de su madre... Hay 2 * 3 = 6 pasos posibles:
    //
    //                    [1] Persp.
    //                   /   \
    //                  /     \
    //                [2]-----[3]
    //          Orth.Plan     Orth.Elev.
    //
    // Cosas que se cambian en la reasignacion:
    // vp.type
    // vp.viewPlaneType
    const controls = vp.controls as CameraControls;

    if (srcCamType === ViewportType.ViewPerspective3D) {
      // De perspectiva a ortografica planta|alzado.
      console.log("Conversion from source PERSPECTIVE 3D camera to...");
      // Quitamos el mecanismo de incremento de precision, por si molestase.
      controls.removeEventListener("update", this._updateEvntLstnr);

      const newOrthoCam = this.cameraPerspective2Orthographic(srcPerspCam as THREE.PerspectiveCamera);
      controls.setCamera(newOrthoCam);
      this.setCameraControlsDefaultValues(controls);
      setMouseControls(controls, false);
      vp.gizmo?.setNewCamera(newOrthoCam);
      vp.gizmo?.setNonClickableAxes();

      // Quitamos los callbacks de teclado.
      vp.removeKeystrokesCallbacks();

      if (dstCamType === ViewportType.ViewPlanOrtho2D) {
        // [1] De perspectiva a ortografica planta.
        console.log("\t ...destination ORTHOGRAPHIC 2D PLAN camera.");
        vp.viewPlaneType = refViewPlane.TOP;
        newOrthoCam.near = 0.01;
        newOrthoCam.far = 100000;
        controls.setLookAt(0, 0, 250, 0, 0, 0);
      } else {
        // [2] De perspectiva a ortografica alzado.
        console.log("\t ...destination ORTHOGRAPHIC 2D ELEVATION camera.");
        vp.viewPlaneType = refViewPlane.FRONT;
        newOrthoCam.near = -1000;
        newOrthoCam.far = +1000;
        // ...pero los otros parametros los hay que cambiar metiendole mano al control, no a la camara.
        controls.setLookAt(0, -100, 0, 0, 0, 0);
      }
      vp.camera = newOrthoCam;
      vp.isStatusSaved = false;
      vp.storeCameraStatus();
      vp.autoZoom2RootAABB();

      // Reincorporamos los callbacks de teclado.
      vp.owner.setKeyboardControls(vp);

      controls.update(vp.owner.owner._clock.getDelta());
    } else {
      if (dstCamType === ViewportType.ViewPerspective3D) {
        // De ortografica planta|alzado a perspectiva.
        vp.viewPlaneType = refViewPlane.DEF;
        // Creamos una nueva camara a ver si la podemos enganchar al control en curso.
        const w = this.width;
        const h = this.height;
        const prevAspectRatio = (w * vp.width01) / (h * vp.height01);
        const newPerspCam = this.cameraOrthographic2Perspective(srcOrthoCam as THREE.OrthographicCamera, prevAspectRatio);
        vp.camera = newPerspCam;
        controls.setCamera(newPerspCam);
        this.setCameraControlsDefaultValues(controls);
        setMouseControls(controls, false);
        vp.gizmo?.setNewCamera(newPerspCam);
        vp.gizmo?.resetInternalValues();
        vp.gizmo?.setClickableAxes();

        // Vista inicial en una posicion que permite ver el cuadrante (+, +) y es solidaria con los ejes en planta.
        this.locatePerspectiveCameraInitialViewPoint(vp);

        vp.isStatusSaved = false;
        vp.storeCameraStatus();
        // Agrego el mecanismo de incremento de precision para las camaras de perspectiva.
        controls.addEventListener("update", this._updateEvntLstnr);
        // vp.autoZoom2RootAABB();

        // Quitamos los callbacks de teclado y los reconstruimos en funcion de la nueva camara.
        vp.removeKeystrokesCallbacks();
        // Reincorporamos los callbacks de teclado.
        vp.owner.setKeyboardControls(vp);

        // Necesario para que aparezca correctamente.
        controls.update(vp.owner.owner._clock.getDelta());

        if (srcCamType === ViewportType.ViewPlanOrtho2D) {
          // [3] De ortografica planta a perspectiva.
          console.log("Conversion from source ORTHOGRAPHIC 2D PLAN camera to...");
        } else {
          // [4] De ortografica alzado a perspectiva.
          console.log("Conversion from source ORTHOGRAPHIC 2D ELEVATION camera to...");
        }
        console.log("\t ...destination PERSPECTIVE 3D camera.");
      } else {
        // De una ortografica a otra, que son los casos mas faciles.
        const orthoCam = srcOrthoCam as THREE.OrthographicCamera;
        if (srcCamType === ViewportType.ViewPlanOrtho2D && dstCamType === ViewportType.ViewElevOrtho2D) {
          // [5] De planta a alzado.
          console.log("Conversion from source ORTHOGRAPHIC 2D PLAN camera to...");
          console.log("\t ...destination ORTHOGRAPHIC 2D ELEVATION camera.");
          vp.viewPlaneType = refViewPlane.FRONT;
          // Esto parece pillarse bien...
          orthoCam.near = -1000;
          orthoCam.far = +1000;
          // ...pero los otros parametros los hay que cambiar metiendole mano al control, no a la camara.
          controls.setLookAt(0, -100, 0, 0, 0, 0);
        } else {
          // [6] De alzado a planta.
          console.log("Conversion from source ORTHOGRAPHIC 2D ELEVATION camera to...");
          console.log("\t ...destination ORTHOGRAPHIC 2D PLAN camera.");
          vp.viewPlaneType = refViewPlane.TOP;
          orthoCam.near = 0.01;
          orthoCam.far = 100000;
          controls.setLookAt(0, 0, 250, 0, 0, 0);
        }
        vp.autoZoom2RootAABB();
        controls.update(vp.owner.owner._clock.getDelta());
      }
    }

    vp.type = dstCamType;
    vp.setInfo(`Camera changed to "${ViewportTypeTxt[dstCamType]}"`);

    return true;
  }

  /**
   * A partir de una camara en perspectiva se devuelve una ortografica mas o menos equivalente.
   * @param srcCam 
   * @returns 
   */
  private cameraPerspective2Orthographic(srcCam: THREE.PerspectiveCamera): THREE.OrthographicCamera {
    // Adaptado de:
    // https://stackoverflow.com/questions/48758959/what-is-required-to-convert-threejs-perspective-camera-to-orthographic
    // La cosa no es tan inmediata, sino que hay que fijar una profundidad de vision para calcular los limites de la
    // nueva camara: Supongo que miramos a toda la escena principal, de la que sacamos su AABB y miramos a su centro.
    const rootGroup = this._sceneManager.rootLayer;
    const box = new THREE.Box3();
    box.setFromObject(rootGroup);
    const target3D = new THREE.Vector3();
    box.getCenter(target3D);
    const posSrcCam = srcCam.position;


    const lineOfSight = new THREE.Vector3();
    srcCam.getWorldDirection(lineOfSight);

    const viewDistance = target3D.clone().sub(posSrcCam);
    const depth = viewDistance.dot(lineOfSight);


    const srcAspect = srcCam.aspect;
    const fovY = srcCam.fov;

    const heightOrtho = depth * 2 * Math.atan(fovY * (Math.PI / 180) / 2);
    const widthOrtho = heightOrtho * srcAspect;

    const far = +10000;
    const near = -10000;

    const W = 0.5 * widthOrtho;
    const H = 0.5 * heightOrtho;

    const dstCam = new THREE.OrthographicCamera(-W, W, H, -H, near, far);
    dstCam.up = new THREE.Vector3(0, 0, 1);

    dstCam.position.copy(srcCam.position);
    dstCam.quaternion.copy(srcCam.quaternion);

    return dstCam;
  }

  /**
   * A partir de una camara ortografica se devuelve una de perspectiva mas o menos equivalente.
   * @param srcCam 
   * @returns 
   */
  private cameraOrthographic2Perspective(srcCam: THREE.OrthographicCamera, aspect: number): THREE.PerspectiveCamera {

    const dstCam = this.createPerspectiveCamera(aspect);
    dstCam.position.copy(srcCam.position);
    dstCam.quaternion.copy(srcCam.quaternion);

    return dstCam;
  }

  /**
   * Funcion para asignar la primera posicion en la que se colocara la camara de perspectiva correspondiente al viewport
   * dado. Solo funciona para camaras de perspectiva.
   * El objetivo es mirar desde una posicion en la que veamos el cuadrante (+,+).
   * @param vp 
   */
  private locatePerspectiveCameraInitialViewPoint(vp: GraphicViewport): void {
    if ((vp.camera as THREE.PerspectiveCamera).isPerspectiveCamera) {
      const rootGroup = this._sceneManager.rootLayer;
      const box = new THREE.Box3();
      box.setFromObject(rootGroup);

      // Problema: Ahora es posible que aparezcan inicialmente escenas vacias, y por tanto el AABB seria erroneo.
      let letsCorrect = false;
      if (box.min.x > box.max.x) {
        console.log("WARNING: Empty scene, so we locate the perspective camera using an imaginary 10 * 10 * 10 cube.");
        box.min.set(-5, -5, -5);
        box.max.set(+5, +5, +5);
        letsCorrect = true;
      }

      // Punto hacia donde mira la camara.
      const target = new THREE.Vector3();
      box.getCenter(target);
      // Point of view de la camara, es decir la posicion desde la que esta mirando.
      const pov = new THREE.Vector3();
      // Queremos mirar de esta forma (planta):
      //
      //  +Y ^     +----+----+
      //     |     |         |
      //     |     |         |
      //     |     +    C    +
      //     |     |         |
      //     |     |         |
      //     |     +----+----+
      //     +-------------------------> +X
      //                ^
      //                |
      //                |
      //                P
      // Luego la X coincide, y la Y estara separada una distancia del centro, siempre alejandose en -Y.
      pov.x = target.x;
      const distY = 4 * (box.max.y - box.min.y);
      pov.y = target.y - distY;
      // Consideramos una Z mas alta que la del edificio.
      const incZ = 0.10;
      pov.z = box.max.z + incZ * (box.max.z - box.min.z);

      if (letsCorrect) {
        pov.set(+30, +30, +10);
      }

      vp.controls?.setLookAt(pov.x, pov.y, pov.z, target.x, target.y, target.z, false);
    }
  }

  // ^^^^^^^ Poner encima las nuevas FMC's que vayas agregando.
} // class GraphicProcessor

// =========================================================================================================================

// Creamos esta instancia por defecto que es la que usa la parte de Rafa y de JaWS, ya que por defecto necesitan un contexto grafico inicial.
// Ojo que hay que inicializarla antes de que nadie la use.
export let graphicProcessor000: GraphicProcessor;
export function initMainGraphicProcessor(name: string = "graphicProcessor000") {
  graphicProcessor000 = new GraphicProcessor(name);
  return graphicProcessor000;
}
export function disposeMainGraphicProcessor() {
  graphicProcessor000 = undefined!;
}

/**
 * Ejemplo para crear 4 viewports directamente, que ocupen toda la pantalla y no sean elementos HTML, de la forma:
 *
 *                  +-------------------------+-------------------------+
 *                  |                         |                         |
 *                  |        C                |       D                 |
 *                  |                         |                         |
 *                  +-------------------------+-------------------------+
 *                  |                         |                         |
 *                  |        A                |       B                 |
 *                  |                         |                         |
 *                  +-------------------------+-------------------------+
 *
 * Todos estan referenciados a la ESQUINA INFERIOR IZQUIERDA (EII).
 * Vamos ademas a poner el sistema americano para el diedrico:
 *
 *                  +-------------------------+-------------------------+
 *                  |                         |                         |
 *                  | Perspectiva             | Planta                  |
 *                  |                         |                         |
 *                  +-------------------------+-------------------------+
 *                  |                         |                         |
 *                  | Perfil izquierdo        | Alzado                  |
 *                  |                         |                         |
 *                  +-------------------------+-------------------------+
 *
 * NOTA FINAL: En esta version la creacion y registro de los viewports se delega en el GraphicViewportManager.
 */
export function create4ViewportsExample(graphicProcessor: GraphicProcessor): void {
  const w = graphicProcessor.width;
  const h = graphicProcessor.height;
  const fov = 30;
  const near = 0.01;
  const far = 10000;

  // Factor multiplicativo a la hora de meter parametros a las camaras ortograficas.
  // Equivale a ampliar el frustum ese...
  const orthoK = 2000;

  // NOTA SOBRE LAS CAMARAS ORTOGRAFICAS: Los parametros de inicializacion de la camara, left/right/top/botton,
  // AL FINAL SON COORDENADAS MUNDIALES DEL PARALELEPIPEDO/FRUSTUM DE VISION que queremos montar, con la camara en su cima.
  // No son valores relativos a la propia camara como pensaba y como piensa todo el mundo que encuentra dificultades
  // para mover estas putas camaras. El error es, aparte de mi estulticia, por las pesimas explicaciones en la documentacion.

  // A: Perfil izquierdo.
  if (true) {
    const vpA = graphicProcessor.managerVP.createViewport("vpA", [0, 0, 0.5, 0.5]) as GraphicViewport;
    vpA.backgroundColor = new THREE.Color("black");
    const aspectA = (w * vpA.width01) / (h * vpA.height01);
    const frustumSize = orthoK * vpA.height01;
    const left = -(frustumSize * aspectA) / 2;
    const right = (frustumSize * aspectA) / 2;
    const top = frustumSize / 2;
    const bottom = -frustumSize / 2;
    const orthoCameraA = new THREE.OrthographicCamera(left, right, top, bottom, near, far);
    orthoCameraA.up = new THREE.Vector3(0, 0, 1);
    orthoCameraA.position.set(-1000, 0, 0);
    orthoCameraA.lookAt(0, 0, 0);
    vpA.camera = orthoCameraA;
    vpA.viewPlaneType = refViewPlane.LEFT;

    if (graphicProcessor.managerVP.addViewport(vpA)) {
      console.log("\tAgregado el viewport '" + vpA.name + "' para la vista de perfil izquierdo.");
      // (vpA.controls as CameraControls).enableRotate = false;
    }
  }

  // B: Alzado.
  if (true) {
    const vpB = graphicProcessor.managerVP.createViewport("vpB", [0.5, 0.0, 0.5, 0.5]) as GraphicViewport;
    vpB.backgroundColor = new THREE.Color("black");
    const aspectB = (w * vpB.width01) / (h * vpB.height01);
    const frustumSize = orthoK * vpB.height01;
    const left = -(frustumSize * aspectB) / 2;
    const right = (frustumSize * aspectB) / 2;
    const top = frustumSize / 2;
    const bottom = -frustumSize / 2;
    const orthoCameraB = new THREE.OrthographicCamera(left, right, top, bottom, near, far);
    orthoCameraB.up = new THREE.Vector3(0, 0, 1);
    orthoCameraB.position.set(0, -1000, 0);
    orthoCameraB.lookAt(0, 0, 0);
    vpB.camera = orthoCameraB;
    vpB.viewPlaneType = refViewPlane.FRONT;

    if (graphicProcessor.managerVP.addViewport(vpB)) {
      console.log("\tAgregado el viewport '" + vpB.name + "' para la vista de alzado.");
      // (vpB.controls as CameraControls).enableRotate = false;
    }
  }

  // C: Perspectiva 3D guai del Paraguay.
  if (true) {
    const vpC = graphicProcessor.managerVP.createViewport("vpC", [0, 0.5, 0.5, 0.5]) as GraphicViewport;
    vpC.backgroundColor = new THREE.Color("black");
    const aspectC = (w * vpC.width01) / (h * vpC.height01);
    const cameraC = new THREE.PerspectiveCamera(fov, aspectC, near, far);
    cameraC.position.set(0, -5000, 1800);
    cameraC.up = new THREE.Vector3(0, 0, 1);
    vpC.camera = cameraC;
    vpC.viewPlaneType = refViewPlane.DEF;

    if (graphicProcessor.managerVP.addViewport(vpC)) {
      console.log("\tAgregado el viewport '" + vpC.name + "' para la vista de perspectiva libre.");
    }
  }

  // D: Planta.
  if (true) {
    const vpD = graphicProcessor.managerVP.createViewport("vpD", [0.5, 0.5, 0.5, 0.5]) as GraphicViewport;
    vpD.backgroundColor = new THREE.Color("black");
    const aspectD = (w * vpD.width01) / (h * vpD.height01);
    const frustumSize = orthoK * vpD.height01;
    const left = -(frustumSize * aspectD) / 2;
    const right = (frustumSize * aspectD) / 2;
    const top = frustumSize / 2;
    const bottom = -frustumSize / 2;
    const orthoCameraD = new THREE.OrthographicCamera(left, right, top, bottom, near, far);
    orthoCameraD.up = new THREE.Vector3(0, 0, 1);
    orthoCameraD.position.set(0, 0, 1000);
    orthoCameraD.lookAt(0, 0, 0);
    vpD.camera = orthoCameraD;
    vpD.viewPlaneType = refViewPlane.TOP;

    if (graphicProcessor.managerVP.addViewport(vpD)) {
      console.log("\tAgregado el viewport '" + vpD.name + "' para la vista de planta.");
      // Le quito la rotacion al control de movimiento.
      // const controls = vpD.controls as CameraControls;
      // controls.enableRotate = false;
      // controls.dampingFactor = 0.2;
    }
  }
}

export function createBigScene(graphicProcessor: GraphicProcessor): void {
  const scene = graphicProcessor.getMainScene();
  const light = new THREE.DirectionalLight(0xffffff);
  light.position.set(0, 0, 1);
  scene.add(light);

  if (true) {
    const helper = new THREE.GridHelper(2000, 100);
    // helper.position.y = - 199;
    (helper.material as THREE.Material).opacity = 0.75;
    (helper.material as THREE.Material).transparent = true;
    scene.add(helper);
    const axes = new THREE.AxesHelper(1100);
    scene.add(axes);
  }

  if (true) {
    const radius = 20;
    const geometry1 = new THREE.IcosahedronBufferGeometry(radius, 1);
    const count = geometry1.attributes.position.count;
    geometry1.setAttribute(
      "color",
      new THREE.BufferAttribute(new Float32Array(count * 3), 3)
    );

    const geometry2 = geometry1.clone();
    const geometry3 = geometry1.clone();

    const color = new THREE.Color();
    const positions1 = geometry1.attributes.position;
    const positions2 = geometry2.attributes.position;
    const positions3 = geometry3.attributes.position;
    const colors1 = geometry1.attributes.color;
    const colors2 = geometry2.attributes.color;
    const colors3 = geometry3.attributes.color;

    for (let i = 0; i < count; ++i) {
      color.setHSL((positions1.getY(i) / radius + 1) / 2, 1.0, 0.5);
      colors1.setXYZ(i, color.r, color.g, color.b);

      color.setHSL(0, (positions2.getY(i) / radius + 1) / 2, 0.5);
      colors2.setXYZ(i, color.r, color.g, color.b);

      color.setRGB(1, 0.8 - (positions3.getY(i) / radius + 1) / 2, 0);
      colors3.setXYZ(i, color.r, color.g, color.b);
    }

    const material = new THREE.MeshPhongMaterial({ color: 0xffffff, flatShading: true, vertexColors: true, shininess: 0 });
    const wireframeMaterial = new THREE.MeshBasicMaterial({ color: 0x000000, wireframe: true, transparent: true });

    let mesh = new THREE.Mesh(geometry1, material);
    let wireframe = new THREE.Mesh(geometry1, wireframeMaterial);
    mesh.add(wireframe);
    mesh.position.x = -400;
    mesh.position.y = radius;
    mesh.rotation.x = -1.87;
    scene.add(mesh);

    mesh = new THREE.Mesh(geometry2, material);
    wireframe = new THREE.Mesh(geometry2, wireframeMaterial);
    mesh.add(wireframe);
    mesh.position.x = 400;
    scene.add(mesh);

    mesh = new THREE.Mesh(geometry3, material);
    wireframe = new THREE.Mesh(geometry3, wireframeMaterial);
    mesh.add(wireframe);
    scene.add(mesh);

    const sep = 5;
    const nX = 20;
    const startX = (-nX * (sep + 2 * radius)) / 2 + radius + 0.5 * sep;
    const nY = 20;
    const startY = (-nY * (sep + 2 * radius)) / 2 + radius + 0.5 * sep;
    const nZ = 20;
    const startZ = (-nY * (sep + 2 * radius)) / 2 + radius + 0.5 * sep;

    let posX = startX;
    for (let iX = 0; iX < nX; ++iX) {
      let posY = startY;
      for (let jY = 0; jY < nY; ++jY) {
        let posZ = startZ;
        for (let kZ = 0; kZ < nZ; ++kZ) {
          const mesh = new THREE.Mesh(geometry1, material);
          const wireframe = new THREE.Mesh(geometry1, wireframeMaterial);
          mesh.add(wireframe);
          mesh.position.x = posX;
          mesh.position.y = posY;
          mesh.position.z = posZ;
          mesh.rotation.x = -1.87;
          scene.add(mesh);
          posZ += 2 * radius + sep;
        }
        posY += 2 * radius + sep;
      }
      posX += 2 * radius + sep;
    }
  }
}

/*
    EXPERIMENTO: Camera shake basico para cada vez que un viewport adquiere el foco.
*/

/*
const ONE_SECOND = 1000;
const FPS = 60;
// const _vec3a = new THREE.Vector3();
const _vec3b = new THREE.Vector3();

class CameraShake {

  _cameraControls: CameraControls;
  _duration: number;
  strength: number;
  _noiseX: number[];
  _noiseY: number[];
  _noiseZ: number[];

  _lastOffsetX: number;
  _lastOffsetY: number;
  _lastOffsetZ: number;

  // Frequency: cycle par second
  constructor(cameraControls: CameraControls, duration = ONE_SECOND, frequency = 10, strength = 1) {
    this._cameraControls = cameraControls;
    this._duration = duration;
    this.strength = strength;
    this._noiseX = CameraShake.makePNoise1D(duration / ONE_SECOND * frequency, duration / ONE_SECOND * FPS);
    this._noiseY = CameraShake.makePNoise1D(duration / ONE_SECOND * frequency, duration / ONE_SECOND * FPS);
    this._noiseZ = CameraShake.makePNoise1D(duration / ONE_SECOND * frequency, duration / ONE_SECOND * FPS);

    this._lastOffsetX = 0;
    this._lastOffsetY = 0;
    this._lastOffsetZ = 0;
  }

  shake() {

    const startTime = performance.now();

    const anim = () => {

      const elapsedTime = performance.now() - startTime;
      const frameNumber = ( elapsedTime / ONE_SECOND * FPS ) | 0;
      const progress = elapsedTime / this._duration;
      const ease = CameraShake.sineOut(1 - progress);

      if (progress >= 1) {

        // this._cameraControls.setPosition(
        // 	_vec3a.x - this._lastOffsetX,
        // 	_vec3a.y - this._lastOffsetY,
        // 	_vec3a.z - this._lastOffsetZ,
        // 	false
        // );

        this._cameraControls.setTarget(
          _vec3b.x - this._lastOffsetX,
          _vec3b.y - this._lastOffsetY,
          _vec3b.z - this._lastOffsetZ,
          false
        );

        this._lastOffsetX = 0;
        this._lastOffsetY = 0;
        this._lastOffsetZ = 0;
        return;

      }

      requestAnimationFrame(anim);

      // this._cameraControls.getPosition( _vec3a );
      this._cameraControls.getTarget(_vec3b);

      const offsetX = this._noiseX[ frameNumber ] * this.strength * ease;
      const offsetY = this._noiseY[ frameNumber ] * this.strength * ease;
      const offsetZ = this._noiseZ[ frameNumber ] * this.strength * ease;

      // this._cameraControls.setPosition(
      // 	_vec3a.x + offsetX - this._lastOffsetX,
      // 	_vec3a.y + offsetY - this._lastOffsetY,
      // 	_vec3a.z + offsetZ - this._lastOffsetZ,
      // 	false
      // );

      this._cameraControls.setTarget(
        _vec3b.x + offsetX - this._lastOffsetX,
        _vec3b.y + offsetY - this._lastOffsetY,
        _vec3b.z + offsetZ - this._lastOffsetZ,
        false
      );

      this._lastOffsetX = offsetX;
      this._lastOffsetY = offsetY;
      this._lastOffsetZ = offsetZ;

    };

    anim();

  }

  static makePNoise1D(length: number, step: number): number[] {
    const noise: number[] = [];
    const gradients = [];

    for ( let i = 0; i < length; i ++ ) {

      gradients[ i ] = Math.random() * 2 - 1;

    };

    for ( let t = 0; t < step; t ++ ) {

      const x = ( length - 1 ) / ( step - 1 ) * ( t );

      const i0 = x|0;
      const i1 = ( i0 + 1 )|0;

      const g0 = gradients[ i0 ];
      const g1 = gradients[ i1 ] || gradients[ i0 ];

      const u0 = x - i0;
      const u1 = u0 - 1;

      const n0 = g0 * u0;
      const n1 = g1 * u1;

      noise.push( n0 * ( 1 - CameraShake.fade( u0 ) ) + n1 * CameraShake.fade( u0 ) );

    }

    return noise;

  }

  static fade (t: number): number {
    return t * t * t * (t * (6 * t - 15) + 10);
  }

  static sineOut(t: number): number {
    return Math.sin(t * HALF_PI);
  }

}



const HALF_PI = Math.PI * 0.5;

*/
