const ParagraphIcon = ({ ...props }) => {
  return (
    <svg
      className="h-5 w-5"
      viewBox="0 0 100 100"
      fill="currentColor"
      {...props}
    >
      <path d="M87.5001 16.6666V20.8333H47.9167V16.6666H87.5001ZM47.9167 45.8333H87.5001V41.6666H47.9167V45.8333ZM16.6667 70.8333H87.5001V66.6666H16.6667V70.8333ZM16.6667 58.3333H87.5001V54.1666H16.6667V58.3333ZM47.9167 33.3333H87.5001V29.1666H47.9167V33.3333ZM16.6667 83.3333H79.1667V79.1666H16.6667V83.3333ZM20.8334 20.8333H27.0834V41.6666H22.9167V45.8333H35.4167V41.6666H31.2501V20.8333H37.5001V22.9166H41.6667V16.6666H16.6667V22.9166H20.8334V20.8333Z" />
    </svg>
  );
};

export default ParagraphIcon;
