import { FC } from "react";
import { Route, RouteProps, Switch } from "react-router-dom";
import SignIn from "shared/pages/signin";
import SignUp from "shared/pages/signup";
import ConfirmAccount from "shared/pages/confirm-account";
import Layout from "../layout";

const PublicRoute: FC<RouteProps> = ({ children, ...props }) => (
  <Route {...props}>
    <Layout>{children}</Layout>
  </Route>
);

const UnauthenticatedApp: FC = () => {
  return (
    <Switch>
      <PublicRoute path="/signup">
        <SignUp />
      </PublicRoute>
      <PublicRoute path="/confirm/:username">
        <ConfirmAccount />
      </PublicRoute>
      <PublicRoute path="*">
        <SignIn />
      </PublicRoute>
    </Switch>
  );
};

export default UnauthenticatedApp;
