import { cloneDataModel } from "lib/models/model-creator/datamodel-factory";
import { getCenterMinZMultiObjData } from "../../geometries/centroids";
import { applyNoiseToPoints, generatePolarMatrixPoints, generateRectangularMatrixPoints, randomSeed } from "../../geometries/matrix";
import { GraphicProcessor } from "../../graphic-processor";
import { substractIpoint } from "../../math/point";
import { IPoint } from "../../math/types";
import { IObjData } from "../../models/objdata";
import { IMatrixBaseParameters, IMatrixPolarParameters, IMatrixRandomParameters } from "../../operations/edition/matrix";
import { cadOpType } from "../../operations/factory";
import { CadCommand } from "../base";

export abstract class MatrixCommand extends CadCommand {

  protected objDatas: IObjData[];
  protected basePoint: IPoint;
  private createdObjDatas: IObjData[] = [];

  constructor(objDatas: IObjData[], basePoint: IPoint, layerId: string, graphicProcessor: GraphicProcessor) {
    super(graphicProcessor);
    this.objDatas = objDatas;
    this.basePoint = basePoint;
    this.layerId = layerId;
  }

  public async unExecute() {
    for (const data of this.createdObjDatas) {
      this.graphicProcessor.removeFromLayer(data);
    }
    const dtMdlMngr = this.graphicProcessor.getDataModelManager();
    dtMdlMngr.dispatchDeletedObjs(this.createdObjDatas);
    const lyrManager = this.graphicProcessor.getLayerManager();
    lyrManager.layerObserver.dispatchLoadLayers();

  }

  public delete() {
    this.createdObjDatas.length = 0;
    this.objDatas.length = 0;
  }

  protected async createMatrixObjs(position: IPoint[], rotation?: number[]) {
    const centerPoint = getCenterMinZMultiObjData(this.objDatas);
    for (const obj of this.objDatas) {
      for (let j = 0, l = position.length; j < l; j++) {
        const pos = position[j];
        const rot = rotation !== undefined ? rotation[j] : undefined;

        const data = cloneDataModel(obj);
        if (rot) data.rotate(0, 0, rot, centerPoint);
        const d = substractIpoint(pos, centerPoint);
        data.translate(d);
        this.graphicProcessor.addToLayer(data, this.layerId);
        this.createdObjDatas.push(data);
      }
    }
    const modelManager = this.graphicProcessor.getDataModelManager();
    modelManager.dispatchAddedObjs(this.createdObjDatas);
    const lyrManager = this.graphicProcessor.getLayerManager();
    lyrManager.layerObserver.dispatchLoadLayers();
  }
}

export class MatrixRectangularCommand extends MatrixCommand {
  protected opType = cadOpType.MATRIX;
  private rectMatrix: IMatrixBaseParameters;

  constructor(objDatas: IObjData[], basePoint: IPoint, rectMatrix: IMatrixBaseParameters, layerId: string, graphicProcessor: GraphicProcessor) {
    super(objDatas, basePoint, layerId, graphicProcessor);
    this.rectMatrix = rectMatrix;
  }

  public async execute() {
    const newPos = generateRectangularMatrixPoints(
      this.basePoint,
      this.rectMatrix.columnNumber,
      this.rectMatrix.columnSeparation,
      this.rectMatrix.rowNumber,
      this.rectMatrix.rowSeparation,
      this.rectMatrix.levelNumber,
      this.rectMatrix.levelSeparation,
      this.rectMatrix.alternate
    );

    this.createMatrixObjs(newPos);
  }
}
export class MatrixRandomCommand extends MatrixCommand {
  protected opType = cadOpType.MATRIXRAN;
  private randomMatrix: IMatrixRandomParameters;

  constructor(objDatas: IObjData[], basePoint: IPoint, randomMatrix: IMatrixRandomParameters, layerId: string, graphicProcessor: GraphicProcessor) {
    super(objDatas, basePoint, layerId, graphicProcessor);
    this.randomMatrix = randomMatrix;
  }

  public async execute() {
    const newPos = generateRectangularMatrixPoints(
      this.basePoint,
      this.randomMatrix.columnNumber,
      this.randomMatrix.columnSeparation,
      this.randomMatrix.rowNumber,
      this.randomMatrix.rowSeparation,
      this.randomMatrix.levelNumber,
      this.randomMatrix.levelSeparation,
      this.randomMatrix.alternate
    );

    applyNoiseToPoints(
      newPos,
      this.randomMatrix.noiseX,
      this.randomMatrix.noiseY,
      this.randomMatrix.noiseZ
    );

    const newRot: number[] = newPos.map(() => randomSeed() * 2 * Math.PI);
    this.createMatrixObjs(newPos, newRot);
  }
}
export class MatrixPolarCommand extends MatrixCommand {
  protected opType = cadOpType.MATRIXRAN;
  private polarMatrix: IMatrixPolarParameters;

  constructor(objDatas: IObjData[], basePoint: IPoint, polarMatrix: IMatrixPolarParameters, layerId: string, graphicProcessor: GraphicProcessor) {
    super(objDatas, basePoint, layerId, graphicProcessor);
    this.polarMatrix = polarMatrix;
  }

  public async execute() {
    const [newPos, newRot] = generatePolarMatrixPoints(
      this.basePoint,
      this.polarMatrix.azimutStart,
      this.polarMatrix.azimutEnd,
      this.polarMatrix.radius,
      this.polarMatrix.isRotated,
      this.polarMatrix.columnNumber,
      this.polarMatrix.columnSeparation,
      this.polarMatrix.rowNumber,
      this.polarMatrix.levelNumber,
      this.polarMatrix.levelSeparation,
      this.polarMatrix.alternate
    );

    this.createMatrixObjs(newPos, newRot);
  }
}
