import { MutableProjectInfo, ProjectsApi, ProjectFilesApi } from "lib/apis/struc-project/api";
import { GraphicProcessor } from "lib/graphic-processor";
import { ModelDataExporter } from "lib/input-output/database/data-model-export";
import { HypothesisExport } from "lib/input-output/database/hypothesis";
import { StrucDataExporter } from "lib/input-output/database/struc-model-export";
import { analysisManager } from "lib/models-struc/analysis/analysismodel-manager";
import { meshManager } from "lib/models-struc/mesh/meshmodel-manager";
import { StrucProject } from "modules/struc/models/project";
import { updateFiles } from "./update-files";

export async function fetchSaveProject(graphicProc: GraphicProcessor, userId: string, id: string) {
  try {
    const exporter = new ModelDataExporter();
    const content = exporter.saveProjectCAD(graphicProc);
    const projectStruc = new StrucDataExporter(graphicProc);
    const strucProj = projectStruc.saveProjectStruct() as StrucProject;

    const hypothesisExporter = new HypothesisExport();
    const hypothesis = hypothesisExporter.export();

    const mesh = meshManager.exportToJSON();
    const analysis = analysisManager.exportToJSON();
    const planes = graphicProc.getPlaneManager().exportToJSON();
    const cameraCfg = graphicProc.mainCameraCfgExportToJSON();
    const snapshot = await graphicProc.takeSnapshot();

    const projInfo: MutableProjectInfo = {
      DataModelVersion: "0.2",
      Name: strucProj.name,
      Remarks: strucProj.remarks,
      Country: strucProj.country,
      City: strucProj.city,
      Codes: {
        ConcreteStandard: strucProj.concrete_standard,
        Concrete: strucProj.concrete_code,
        Load: strucProj.load_code,
        SteelStandard: strucProj.steel_standard,
        Steel: strucProj.steel_type,
      },
    };

    const controller = new ProjectsApi();
    console.log("[PUT] Saving project " + strucProj.name);
    const putResponse = await controller.projectsUserIdIdPut(userId, id, projInfo);
    const data = putResponse.data;

    console.log("[UPLOAD] Update Files " + strucProj.name);
    const fileData = data.DataFiles!;
    await Promise.allSettled([
      await updateFiles.analysis(analysis, fileData.Analysis.Write),
      await updateFiles.cameraConfig(cameraCfg, fileData.CameraConfig.Write),
      await updateFiles.content(content, fileData.Content.Write),
      await updateFiles.hypothesis(hypothesis, fileData.Hypothesis.Write),
      await updateFiles.mesh(mesh, fileData.Mesh.Write),
      await updateFiles.planes(planes, fileData.Planes.Write),
      await updateFiles.sites(strucProj.sites, fileData.Sites.Write),
      await updateFiles.snapshot(snapshot, fileData.Snapshot.Write),
    ])

    console.log("[PUT] Files refresh " + strucProj.name);
    const fileProj = new ProjectFilesApi();
    await fileProj.projectsUserIdIdUpdatePut(userId, id);

    return {
      projectData: data,
      snapShots: snapshot,
    }

  } catch (error) {
    console.error(error);
    throw (error);
  }
}
