import { City, Continent, GeonamesApi } from "./api";

export async function getAllCountries(): Promise<Continent[]> {
  const geonamesEndPoint: GeonamesApi = new GeonamesApi();
  const continentsRes = await geonamesEndPoint.continentsGet();
  return continentsRes.data;
}

export async function getCitiesForCountry(continentIso: string, countryIso: string): Promise<City[]> {
  const geonamesEndPoint: GeonamesApi = new GeonamesApi();
  const citiesRes = await geonamesEndPoint.continentsContinentIsoCountriesCountryIsoCitiesGet(continentIso, countryIso);
  return citiesRes.data;
}