import { IPoint } from "lib/math/types";

export enum loadType {
  CONCENTRATED,
  LINEAL,
  SUPERFICIAL
}
export interface loadParam {
  name: string;

  hypothesisId: string;
  parentStructElemId: string;

  type: loadType;
  externalGeo: boolean;
  loadValue: number;

  ptos2D: IPoint[];
  basePoint: IPoint;
  rotation: IPoint;
}
