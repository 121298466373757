import * as THREE from "three";
import { getUserAngleUnitSufix, radAngleToUser } from "lib/general-settings";
import { updateObjBboxBSphere, adjustDrawRange } from "lib/geometries";
import { normalizeAngle, lineAngle2p, getAngleBetweenMathDirections, isBetweenDirection, mecDirectionMathDirection, ORIENT, getAngleBetweenAzimuts } from "lib/math/angles";
import { arcParam } from "lib/math/arc";
import { vectorDist3D } from "lib/math/distance";
import { vector3Equals } from "lib/math/epsilon";
import { intersect3D } from "lib/math/intersections";
import { getPolarPoint, getFactorLinePosition2p, IpointsToBuffer, copyIPoint } from "lib/math/point";
import { copyISegment, IPoint, ISegment } from "lib/math/types";
import { createText, modifyText } from "lib/text/builder";
import { sdfDoubleSidedType, TextOptsBuilder } from "lib/text/styles";
import { getArcDimArrows, updateArcDimArrows } from "./arc-dim-builder";
import { DimStyleBuilder } from "./style";
import { getDimGeometry, getDimAuxGeometry, getDimArrows, getDimLabel, getDimAuxLineMaterial } from "./line-dim-builder";
import { arcBuffer } from "lib/geometries/arc";
import { angleDimensionParam } from "lib/models/dimension/angle-dim";
import { getRelativePoint } from "lib/coordinates/plane";
import { updateArrowSymbol } from "./arrow";

export function createAngleDimension(lines: ISegment[], direction: number, dimStyle: DimStyleBuilder, txtStyle: TextOptsBuilder, plane: IPoint): THREE.Group {
    // Original Lines
    const lineAO = copyISegment(lines[0]);
    const lineBO = copyISegment(lines[1]);
    const centerO = getCenter(lineAO, lineBO) as IPoint;

    // Move to center as origin 
    const lineA = {
        p1: getRelativePoint(lineAO.p1, centerO, plane),
        p2: getRelativePoint(lineAO.p2, centerO, plane),
    };
    const lineB = {
        p1: getRelativePoint(lineBO.p1, centerO, plane),
        p2: getRelativePoint(lineBO.p2, centerO, plane),
    };

    const dimGeometry = getAngleDimGeometry(lineA, lineB, dimStyle, direction);
    const arcParamAux = getAngleDimAuxArcParam(lineA, lineB, dimStyle, direction);
    const auxGeometry = getAngleAuxGeometry(arcParamAux);
    const arrows = getArcDimArrows(auxGeometry, dimGeometry, ORIENT.CW, dimStyle);
    const textLabel = getAngleDimLabelText(arcParamAux, dimStyle, txtStyle, direction);

    const dim = new THREE.Group();
    dim.add(dimGeometry);
    dim.add(textLabel);
    dim.add(arrows[0]);
    dim.add(arrows[1]);
    dim.add(auxGeometry);

    dim.rotation.set(plane.x, plane.y, plane.z);
    dim.position.set(centerO.x, centerO.y, centerO.z);

    return dim;
}
export function updateAngleDimension(dimensionGroup: THREE.Group, dimInfo: angleDimensionParam, dimStyle: DimStyleBuilder, txtStyle: TextOptsBuilder): void {
    // Original Lines
    const lineAO = copyISegment(dimInfo.objRef[0].segment);
    const lineBO = copyISegment(dimInfo.objRef[1].segment);
    const centerO = getCenter(lineAO, lineBO) as IPoint;

    // Move to center as origin 
    const plane = dimInfo.dimObjPlane;
    const lineA = {
        p1: getRelativePoint(lineAO.p1, centerO, plane),
        p2: getRelativePoint(lineAO.p2, centerO, plane),
    };
    const lineB = {
        p1: getRelativePoint(lineBO.p1, centerO, plane),
        p2: getRelativePoint(lineBO.p2, centerO, plane),
    };

    dimStyle.override(dimInfo.customStyleProp);
    if (dimStyle.blockDistBaseLine || Math.abs(dimStyle.minDistBaseLine1) > Math.abs(dimStyle.distBaseLine)) {
        dimStyle.distBaseLine = dimStyle.minDistBaseLine1;
    }

    const geometry = getDimGeometry(dimensionGroup);
    let newGeometry = getAngleDimBuffer(lineA, lineB, dimStyle, dimInfo.dimDirectionAngle);
    geometry.geometry = newGeometry;
    updateObjBboxBSphere(geometry);

    const auxGeometry = getDimAuxGeometry(dimensionGroup);
    const arcParamAux = getAngleDimAuxArcParam(lineA, lineB, dimStyle, dimInfo.dimDirectionAngle);
    newGeometry = getAngleAuxBuffer(arcParamAux);
    auxGeometry.geometry = newGeometry;
    adjustDrawRange(auxGeometry);
    updateObjBboxBSphere(auxGeometry);

    let arrows = getDimArrows(dimensionGroup);
    updateArrowSymbol(dimensionGroup, arrows[0], dimStyle.arrowId);
    updateArrowSymbol(dimensionGroup, arrows[1], dimStyle.arrowId);
    arrows = getDimArrows(dimensionGroup);
    updateArcDimArrows(arrows[0], arrows[1], auxGeometry, geometry, ORIENT.CW);

    const textLabel = getDimLabel(dimensionGroup);
    updateAngularDimLabelText(textLabel, arcParamAux, dimStyle, txtStyle, dimInfo.dimDirectionAngle);

    dimensionGroup.rotation.set(plane.x, plane.y, plane.z);
    dimensionGroup.position.set(centerO.x, centerO.y, centerO.z);
}

function getAngleDimGeometry(lineA: ISegment, lineB: ISegment, dimStyle: DimStyleBuilder, direction: number): THREE.LineSegments {
    const geometry: THREE.BufferGeometry = getAngleDimBuffer(lineA, lineB, dimStyle, direction);
    const resultsLines = new THREE.LineSegments(geometry, getDimAuxLineMaterial());
    updateObjBboxBSphere(resultsLines);
    return resultsLines;
}
function getAngleDimBuffer(lineA: ISegment, lineB: ISegment, dimStyle: DimStyleBuilder, direction: number): THREE.BufferGeometry {
    const position: IPoint[] = [];
    const newRadius = dimStyle.distBaseLine;
    const center = getCenter(lineA, lineB) as IPoint;
    const angles = getAngles(lineA, lineB, center, direction) as number[];
    const angleStart = angles[0];
    const angleEnd = angles[1];

    // lineas lateral A
    const P0 = getPolarPoint(center, angleStart, newRadius);
    position[0] = P0;
    // Coincide con linea A o linea B
    let currentLine = lineA;
    if (getFactorLinePosition2p(lineA.p1, lineA.p2, P0) === null) currentLine = lineB;

    const dimA = getlateralPoints(P0, center, currentLine, angleStart);
    if (dimA) {
        position[2] = dimA[0];
        position[3] = dimA[1];
    }

    // lineas lateral B
    const P1 = getPolarPoint(center, angleEnd, newRadius);
    position[1] = P1;
    // Seleccionamos la otra línea como la actual
    if (currentLine === lineA) currentLine = lineB;
    else if (currentLine === lineB) currentLine = lineA;

    const dimB = getlateralPoints(P1, center, currentLine, angleEnd);
    if (dimB) {
        position[4] = dimB[0];
        position[5] = dimB[1];
    }

    position[6] = center;
    position[7] = lineA.p1;
    position[8] = lineA.p2;
    position[9] = lineB.p1;
    position[10] = lineB.p2;

    const coords: Float32Array = IpointsToBuffer(position);
    const geometry: THREE.BufferGeometry = new THREE.BufferGeometry();
    geometry.setAttribute("position", new THREE.BufferAttribute(coords, 3));
    geometry.setDrawRange(2, 4);
    return geometry;

    function getlateralPoints(P: IPoint, C: IPoint, line: ISegment, angle: number): IPoint[] | void {
        const result = [];

        const v1 = line.p1;
        const v2 = line.p2;
        const factorP0 = getFactorLinePosition2p(v1, v2, P) as number;
        const factorCenter = getFactorLinePosition2p(v1, v2, C) as number;

        if (factorP0 >= 0 && factorP0 <= 1) {
            // No hay línea de cota lateral
            result[0] = { x: 0, y: 0, z: 0 };
            result[1] = { x: 0, y: 0, z: 0 };
        } else if (factorCenter >= 0 && factorCenter <= 1 && factorP0 > 1) {
            //  V0----C----V1----P
            result[0] = getPolarPoint(center, angle, newRadius + dimStyle.ext);
            result[1] = getPolarPoint(center, angle, vectorDist3D(center, v2) + dimStyle.offset);
        } else if (factorCenter >= 0 && factorCenter <= 1 && factorP0 < 0) {
            //  P----V0----C-----V1
            result[0] = getPolarPoint(center, angle, newRadius + dimStyle.ext);
            result[1] = getPolarPoint(center, angle, vectorDist3D(center, v1) + dimStyle.offset);
        } else if (factorCenter > 1 && factorP0 < 0) {
            //  P----V0----V1------C
            result[0] = getPolarPoint(center, angle, newRadius + dimStyle.ext);
            result[1] = getPolarPoint(center, angle, vectorDist3D(center, v1) + dimStyle.offset);
        } else if (factorCenter <= 0 && factorP0 > 1) {
            //  C----V0----V1------P
            result[0] = getPolarPoint(center, angle, newRadius + dimStyle.ext);
            result[1] = getPolarPoint(center, angle, vectorDist3D(center, v2) + dimStyle.offset);
        } else if (factorP0 > 1 && factorCenter >= 1 && factorP0 > factorCenter) {
            // V0----V1----C----P
            result[0] = getPolarPoint(center, angle, newRadius + dimStyle.ext);
            result[1] = getPolarPoint(center, angle, dimStyle.offset);
        } else if (factorP0 < 0 && factorCenter <= 0 && factorP0 < factorCenter) {
            // P---C---V0----V1
            result[0] = getPolarPoint(center, angle, newRadius + dimStyle.ext);
            result[1] = getPolarPoint(center, angle, dimStyle.offset);
        } else if (factorP0 > 1 && factorCenter > 1 && factorP0 < factorCenter) {
            // V0----V1----P----C
            result[0] = getPolarPoint(center, angle, newRadius - dimStyle.ext);
            result[1] = getPolarPoint(center, angle, vectorDist3D(center, v2) - dimStyle.offset);
        } else if (factorP0 < 0 && factorCenter < 0 && factorP0 > factorCenter) {
            // C----P----V0----V1
            result[0] = getPolarPoint(center, angle, newRadius - dimStyle.ext);
            result[1] = getPolarPoint(center, angle, vectorDist3D(center, v1) - dimStyle.offset);
        } else {
            result[0] = { x: 0, y: 0, z: 0 };
            result[1] = { x: 0, y: 0, z: 0 };
        }
        return result;
    }
}
function getAngleDimAuxArcParam(lineA: ISegment, lineB: ISegment, dimStyle: DimStyleBuilder, angle?: number): arcParam {
    if (angle === undefined) angle = Math.PI * 0.5;
    const center = getCenter(lineA, lineB) as IPoint;
    const newRadius = dimStyle.distBaseLine;
    const mainDirections = getAngles(lineA, lineB, center, angle) as number[];
    const azimutO = mecDirectionMathDirection(mainDirections[0]);
    const azimutEnd = mecDirectionMathDirection(mainDirections[1]);
    const angleCenter = getAngleBetweenAzimuts(azimutO, azimutEnd, ORIENT.CW);
    return {
        center: copyIPoint(center),
        radius: newRadius,
        angleCenter,
        azimutO,
        plane: { x: 0, y: 0, z: 0 },
    }
}
function getAngleAuxGeometry(arc: arcParam): THREE.Line {
    const geometry: THREE.BufferGeometry = getAngleAuxBuffer(arc);
    const resultsLines = new THREE.Line(geometry, getDimAuxLineMaterial());
    adjustDrawRange(resultsLines);
    updateObjBboxBSphere(resultsLines);
    return resultsLines;
}
function getAngleAuxBuffer(arc: arcParam): THREE.BufferGeometry {
    const { center, radius, azimutO, angleCenter } = arc;
    const newArcCoords = arcBuffer(center, radius, azimutO, angleCenter, { x: 0, y: 0, z: 0 });
    const newArcBuffer = new THREE.BufferGeometry();
    newArcBuffer.setAttribute("position", new THREE.BufferAttribute(newArcCoords, 3));
    return newArcBuffer;
}

// LABEL

function getAngleDimLabelText(arcDimInfo: arcParam, dimStyle: DimStyleBuilder, opts: TextOptsBuilder, direction: number): THREE.Mesh {
    const arcAngle = Math.abs(arcDimInfo.angleCenter);
    const text = radAngleToUser(arcAngle).toFixed(4) + getUserAngleUnitSufix();
    opts.basePointH = dimStyle.basePointH; // textMultiPosTypeH.MIDDLE;
    opts.basePointV = dimStyle.basePointV; // textMultiPosTypeV.MEDIAN;
    opts.doubleSided = sdfDoubleSidedType.HOR;
    const label = createText({ text }, opts);
    updateAngularDimLabelText(label, arcDimInfo, dimStyle, opts, direction);
    return label;
}
function updateAngularDimLabelText(label: THREE.Mesh, arcParam: arcParam, dimStyle: DimStyleBuilder, opts: TextOptsBuilder, angleDir: number): void {
    const arcAngle = Math.abs(arcParam.angleCenter);
    const text = radAngleToUser(arcAngle).toFixed(4) + getUserAngleUnitSufix();
    opts.basePointH = dimStyle.basePointH; // textMultiPosTypeH.MIDDLE;
    opts.basePointV = dimStyle.basePointV; // textMultiPosTypeV.MEDIAN;
    opts.doubleSided = sdfDoubleSidedType.HOR;
    modifyText(label, text, opts);

    const min = (label.geometry.boundingBox as THREE.Box3).min;
    const max = (label.geometry.boundingBox as THREE.Box3).max;
    const offsetY = (max.y - min.y) * 0.5;

    const pos = getPolarPoint(arcParam.center, angleDir, dimStyle.distBaseLine + dimStyle.fontOffsetY + offsetY);
    label.position.set(pos.x, pos.y, pos.z);

    label.rotation.set(0, 0, 0);
    if (angleDir < Math.PI) {
        label.rotateZ(angleDir - Math.PI * 0.5);
    } else {
        label.rotateZ(angleDir + Math.PI * 0.5);
    }
}

// Funciones auxiliares para el calculo de geometrias en acotaciones angulares entre líneas

/**
 * cálculo de la intersección de dos segmentos
 * null si los segmentos son paralelos o se cruzan
 *
 * @param {THREE.Object3D} lineA
 * @param {THREE.Object3D} lineB
 * @returns {IPoint}
 */
export function getCenter(lineA: ISegment, lineB: ISegment): IPoint | null {
    const intersection = intersect3D(lineA.p1, lineA.p2, lineB.p1, lineB.p2, false);
    if (intersection && intersection.p0 && intersection.p1) {
        return { x: intersection.p0.x, y: intersection.p0.y, z: intersection.p0.z };
    }
    // Lineas paralelas o se cruzan, (no se cortan)
    return null;
}
/**
 * Cálculo de las dos direcciones entre las cuales se sitúa una tercera.
 * Las direcciones las definen dos segmentos
 * (0-LineAv0  1-LineB  2-LineAv1  3-LineB)
 *
 * @param {THREE.Object3D} lineA
 * @param {THREE.Object3D} lineB
 * @param {IPoint} center
 * @param {number} [angle] Por defecto 90º
 * @returns {Array<number>}
 */
export function getAngles(lineA: ISegment, lineB: ISegment, center: IPoint, angle?: number): number[] | null {
    // Se considera sentido horario siempre
    const direction = ORIENT.CW;
    // Direcciones entre ángulos a acotar (0-LineAv0  1-LineB  2-LineAv1  3-LineB)
    let angle0, angle1, angle2, angle3;

    if (!vector3Equals(center, lineA.p1)) {
        angle0 = normalizeAngle(lineAngle2p(center, lineA.p1));
    } else {
        angle0 = normalizeAngle(lineAngle2p(center, lineA.p2));
    }
    angle2 = normalizeAngle(angle0 - Math.PI);

    if (!vector3Equals(center, lineB.p1)) {
        angle1 = normalizeAngle(lineAngle2p(center, lineB.p1));
    } else {
        angle1 = normalizeAngle(lineAngle2p(center, lineB.p2));
    }
    if (getAngleBetweenMathDirections(angle0, angle1, direction) < Math.PI) {
        angle3 = normalizeAngle(angle1 - Math.PI);
    } else {
        angle3 = angle1;
        angle1 = normalizeAngle(angle3 - Math.PI);
    }
    // Posición del arco de acotación
    if (angle === undefined) angle = Math.PI * 0.5;
    if (isBetweenDirection(angle, angle0, angle1, direction)) {
        return [angle0, angle1];
    } else if (isBetweenDirection(angle, angle1, angle2, direction)) {
        return [angle1, angle2];
    } else if (isBetweenDirection(angle, angle2, angle3, direction)) {
        return [angle2, angle3];
    } else if (isBetweenDirection(angle, angle3, angle0, direction)) {
        return [angle3, angle0];
    }
    return null;
}
