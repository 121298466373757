import { MaterialType, ThreeMaterial } from "./base";

export abstract class BaseMaterialCache<U extends MaterialType, T extends ThreeMaterial> {

  protected map: Map<U, T> = new Map();

  get size() { return this.map.size; }

  public abstract getMaterial(mat: U): T;

  public clear() {
    for (const material of this.map.values()) {
      material.dispose();
    }
    this.map.clear();
  }
}
