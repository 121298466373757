import * as THREE from "three";
import { getEcorePlacement, getEcoreVector3 } from "../locations";
import { getEcorePointGeometry } from "../geometry";
import { baseUriModel } from "../mesh-exporter";
import { CrossSectionRepresentation, ExtrudedRepresentation, GeoRepresentation } from "modules/struc/models/ecore/representation";
import { Vector as EcoreVector } from "modules/struc/models/ecore/location";
import { EcoreStrucElemBase } from "../struc-elem-base";
import { Column as EcoreColumn } from "modules/struc/models/ecore/structural";
import { BeamStructuralElementEcore } from "modules/struc/models/ecore/mesh";
import { ColumnData } from "lib/models/structural/column";
import { Point } from "modules/struc/models/ecore/geometry";
import { representationUriModel, structuralUriModel } from "modules/struc/models/ecore/uri";

export class EcoreColumnElem extends EcoreStrucElemBase {

  private strucElem: ColumnData;
  private crossSectionRef: number;

  setStrucElem(strElem: ColumnData, crossSectionShapesIds: string[]) {
    this.strucElem = strElem;
    const repr = this.strucElem.definition;
    this.crossSectionRef = crossSectionShapesIds.indexOf(repr.crossSectionId);
  }

  exportToEcore(materialRef: number): EcoreColumn {
    const repr = this.strucElem.definition;
    const colEcore: EcoreColumn = {
      eClass: `${structuralUriModel}Column`,
      id: this.strucElem.id,
      name: repr.name,
      material: this.getEcoreMaterialRef(materialRef),
      placement: getEcorePlacement({ ...repr.basePoint, z: 0 }),
      representation: this.getRepresentation(this.crossSectionRef),
    }
    return colEcore;
  }
  private getRepresentation(cssIndexRef: number): ExtrudedRepresentation<CrossSectionRepresentation> {
    return {
      eClass: `${representationUriModel}ExtrudedRepresentation`,
      placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
      direction: this.getEcoreDirection(),
      orientation: this.getOrientationVectorY(),
      base: this.getEcoreCrossSectionRepresentation(cssIndexRef)
    }
  }

  exportFemStructuralElementToEcore(storeyRef: number, elemRef: number): BeamStructuralElementEcore {
    const femStr: BeamStructuralElementEcore = {
      eClass: baseUriModel + "mesh/BeamStructuralElement",
      structuralelement: {
        eClass: baseUriModel + "structural/Column",
        $ref: `//@versions.0/@building/@storeys.${storeyRef}/@elements.${elemRef}`,
      },
      section: {
        eClass: baseUriModel + "mesh/CrossSection",
        placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
        shape: this.getEcoreCrossSectionRef(this.crossSectionRef),
      },
      representation: this.getFEMStrcElemRepresentation(),
      orientation: this.getOrientationVectorY(),
    }
    return femStr;
  }
  private getFEMStrcElemRepresentation(): ExtrudedRepresentation<GeoRepresentation<Point>> {
    return {
      eClass: `${representationUriModel}ExtrudedRepresentation`,
      placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
      orientation: this.getOrientationVectorY(),
      direction: this.getEcoreDirection(),
      base: {
        eClass: `${representationUriModel}GeoRepresentation`,
        placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
        base: getEcorePointGeometry({ x: 0, y: 0, z: 0 }),
      },
    }
  }

  private getOrientationVectorY(): EcoreVector {
    const strucDefinition = this.strucElem.definition;
    const angle = new THREE.Euler(0, 0, strucDefinition.orientation);
    const yVec = new THREE.Vector3(0, 1, 0);
    yVec.applyEuler(angle);
    return getEcoreVector3(yVec);
  }
  getEcoreDirection(): EcoreVector {
    const strucDefinition = this.strucElem.definition;
    const direction = { x: 0, y: 0, z: -strucDefinition.height };
    return getEcoreVector3(direction);
  }
}
