import { GraphicProcessor } from "lib/graphic-processor";
import { copyMaterial, getMatchProperties, ILineMaterial } from "lib/materials";
import { isDimensionData, isTextData } from "lib/models/checktools";
import { IObjData } from "lib/models/objdata";
import { matchProperties } from "lib/operations/edition/match-prop";
import { cadOpType } from "lib/operations/factory";
import { CadCommand } from "../base";

export class MatchPropertiesCommand extends CadCommand {

  protected opType = cadOpType.MATCHPROP;
  private props: matchProperties;
  private objDatas: IObjData[];
  private oldProperties: Map<IObjData, matchProperties> = new Map();

  constructor(props: matchProperties, objIds: IObjData[], graphicProcessor: GraphicProcessor) {
    super(graphicProcessor);
    this.props = props;
    this.objDatas = objIds;
  }

  execute(): void {
    for (const data of this.objDatas) {
      // Save old properties to call undo command
      if (!this.oldProperties.has(data)) {
        this.oldProperties.set(data, getMatchProperties(data));
      }
      // Change properties
      this.regenerate(data, this.props);
    }
    const dtMdlMngr = this.graphicProcessor.getDataModelManager();
    dtMdlMngr.dispatchEditObjs(this.objDatas, this.opType);
  }
  unExecute(): void {
    for (let [data, oldProp] of this.oldProperties) {
      // Restore old properties
      this.regenerate(data, oldProp);
    }
    const dtMdlMngr = this.graphicProcessor.getDataModelManager();
    dtMdlMngr.dispatchEditObjs(this.objDatas, this.opType);
  }
  delete(): void {
    this.objDatas.length = 0;
    this.oldProperties.clear();
    this.props = undefined!;
  }

  private regenerate(data: IObjData, props: matchProperties) {
    this.changeDataMatchProperties(data, props);
    const updateDefinition = (isTextData(data) || isDimensionData(data));
    if (updateDefinition) data.regenerateObjectFromDefinition();
    data.regenerateObjectFromMaterial(this.graphicProcessor);
  }

  private changeDataMatchProperties(data: IObjData, props: matchProperties) {
    if (data.material) {
      const mat = copyMaterial(data.material);
      if (props.color !== undefined) { mat.color = props.color!; }
      const lineMaterial = mat as ILineMaterial;
      if (lineMaterial) {
        if (props.lineStyleId !== undefined) { lineMaterial.lineStyleId = props.lineStyleId!; }
        if (props.width !== undefined) { lineMaterial.width = props.width!; }
      }
      data.material = mat;
    }
    if (isDimensionData(data)) {
      const dimParam = data.definition;
      if (props.dimStyleId !== undefined) { dimParam.styleId = props.dimStyleId!; }
      if (props.dimOverride !== undefined) { dimParam.customStyleProp = props.dimOverride!; }
      if (props.textStyleId !== undefined) {
        const newOverride = Object.assign({}, dimParam.customStyleProp);
        newOverride.textStyleId = props.textStyleId;
        dimParam.customStyleProp = newOverride;
      }
      // TODO: in the future, must change text color, lineStyle and width, 
      // when implemented override text properties for dimensions

    } else if (isTextData(data)) {
      if (props.textStyleId !== undefined) { data.definition.styleId = props.textStyleId!; }
      if (props.textOverride !== undefined) { data.definition.override = props.textOverride!; }
      if (props.color !== undefined) {
        const newOverride = Object.assign({}, data.definition.override);
        newOverride.color = props.color;
        data.definition.override = newOverride;
      }
    }
    const layerManager = this.graphicProcessor.getLayerManager();
    layerManager.moveObjData2Layer(data, props.layerId);
  }
}
