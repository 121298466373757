import { useQuery } from "react-query";
import { StrucSite } from "modules/struc/models/project";
import { Project, ProjectFilesApi, ProjectsApi } from "lib/apis/struc-project/api";
import { downloadFiles } from "lib/models-struc/infrastructure/download-files";

export function useGetStrucProject(userId: string, projectId: string): { projectData: Project | undefined; isLoading: boolean; error: unknown; } {
  const { isLoading, error, data } = useQuery(["GETSTRUCPROYECT", userId, projectId], async () => {
    console.log("[GET_STRUC_PROYECT]");
    console.log("User    Id: " + userId);
    console.log("Project Id: " + projectId);
    const controller = new ProjectsApi();
    const response = await controller.projectsUserIdIdGet(userId, projectId);
    return response.data;
  });
  return {
    projectData: data,
    isLoading,
    error,
  };
}

export function useGetStrucProjectFiles(userId: string, projectId: string): { project: Project | undefined; isLoading: boolean; error: unknown; } {

  const { isLoading, error, data } = useQuery(["GETSTRUCPROYECTFILES", userId, projectId], async () => {

    console.log("[GET_STRUC_PROYECT]");
    console.log("User    Id: " + userId);
    console.log("Project Id: " + projectId);
    const controller = new ProjectsApi();
    const response = await controller.projectsUserIdIdGet(userId, projectId);
    const data = response.data;

    const url = response.data.DataFiles?.Content.Read!;
    const urlParams = new URLSearchParams(url);
    const timestamp = urlParams.get('Expires')!;
    const delta = (parseInt(timestamp) * 1000 - new Date().getTime()) / 1000 / 60 / 60;
    console.log("[TIMESTAMP] " + delta);
    if (parseInt(timestamp) * 1000 <= new Date().getTime()) {
      console.log("[TIMESTAMP] Refresh url files");
      const controller0 = new ProjectFilesApi();
      const response0 = await controller0.projectsUserIdIdRefreshGet(userId, projectId);
      const files = response0.data;
      data.DataFiles = files;
    }
    return data
  });
  return {
    project: data,
    isLoading,
    error,
  };
}

export function useGetStrucProjectSites(userId: string, projectId: string): { sites: StrucSite[] | undefined; isLoading: boolean; error: unknown; } {
  const { isLoading, error, data } = useQuery(["GETSTRUCPROYECTSITES", userId, projectId], async () => {
    const controller = new ProjectFilesApi();
    const response = await controller.projectsUserIdIdRefreshGet(userId, projectId);
    const SiteFile = response.data.Sites;
    const sites = await downloadFiles.sites(SiteFile.Read);
    return sites ?? undefined;
  }, {
    enabled: !!userId && !!projectId
  });
  return {
    sites: data!,
    isLoading,
    error,
  };
}
