import { ExtendCommand } from "lib/commands/edition/extend";
import { getExtendObjsInfo } from "lib/math/extend";
import { lineCreateIPoints } from "lib/geometries/line";
import { getAuxMaterialLine } from "lib/materials";
import { IPoint, ISegment } from "lib/math/types";
import { isLineData } from "lib/models/checktools";
import { IObjData } from "lib/models/objdata";
import { LineData } from "lib/models/primitives/line";
import { MultiEdition } from "../edition-multi";
import { cadOpType } from "../factory";
import { settingsOpModes } from "../step-operations";

export class ExtendOP extends MultiEdition {

  public opType = cadOpType.EXTEND;
  public linesToExtend!: LineData[];
  public lineToIntersect!: LineData;

  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([
      {
        infoMsg: `Select entity to extends.`,
        stepMode: settingsOpModes.SELECTOBJS,
        multiSelect: true,
        enableSelectMarks: true,
        filterFun: (o: IObjData) => {
          const res = isLineData(o);
          return res;
        },
        endStepCallback: async () => {
          this.linesToExtend = this.objDataOrigin as LineData[];
          this.objDataOrigin = [];
        }
      },
      {
        infoMsg: `Select boundary entity for extend.`,
        stepMode: settingsOpModes.SELECTOBJS,
        multiSelect: false,
        enableSelectMarks: true,
        filterFun: (o: IObjData) => {
          if (this.linesToExtend.some((l) => l === o)) {
            return false;
          }
          const res = isLineData(o);
          return res;
        },
        endStepCallback: async () => {
          this.unRegisterRaycast();
          this.lineToIntersect = this.objDataOrigin[0] as LineData;
          this.showResult();
        }
      },
      {
        infoMsg: `Extends lines.`,
        stepMode: settingsOpModes.WAITMODE,
        stepFun: () => {
          this.save();
          this.endOperation();
        }
      }
    ]);
  }

  public async start() {
    this.iniSettingsOp();
    this.registerCancel();
    this.registerRaycast();
  }

  private showResult(): void {
    console.assert(this.linesToExtend.length > 0);
    console.assert(this.lineToIntersect !== undefined);

    const resultLines: THREE.Line[] = [];

    const linesToExtend = this.linesToExtend.map(l => l.definition.points);
    const lineToIntersect = this.lineToIntersect.definition.points;

    const extendResult = getExtendObjsInfo(linesToExtend, lineToIntersect);

    // Pintamos las geometrías auxiliares
    for (let i = 0; i < extendResult.length; i++) {
      const extendInfo = extendResult[i];
      if (extendInfo !== undefined) {
        const lineExt = this.linesToExtend[i].definition;
        const numVert = lineExt.points.length;
        const lastSegment: ISegment = {
          p1: lineExt.points[numVert - 2],
          p2: lineExt.points[numVert - 1],
        };
        const firstSegment: ISegment = {
          p1: lineExt.points[0],
          p2: lineExt.points[1],
        };
        if (extendInfo.first !== undefined) {
          const line: THREE.Line = lineCreateIPoints([extendInfo.first, firstSegment.p1] as IPoint[], undefined, getAuxMaterialLine());
          resultLines.push(line);
        }
        if (extendInfo.last !== undefined) {
          const line: THREE.Line = lineCreateIPoints([lastSegment.p2, extendInfo.last] as IPoint[], undefined, getAuxMaterialLine());
          resultLines.push(line);
        }
      }
    }

    for (const line of resultLines) {
      this.saveToTempScene(line);
    }
  }

  public save() {
    if (this.graphicProcessor && this.linesToExtend && this.lineToIntersect) {
      const command = new ExtendCommand(this.linesToExtend, this.lineToIntersect, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
}