import { dataInfoProperty } from "lib/properties/properties";
import { StrucStorey } from "modules/struc/models/project";
import { v4 as uuid } from 'uuid'

export enum loadHypothesisList {
  ACCESSIBLE_ROOF = 'ACCESSIBLE_ROOF',
  DEAD_LOAD = 'DEAD_LOAD',
  EARTH_PRESSURE = 'EARTH_PRESSURE',
  NON_ACCESSIBLE_ROOF = 'NON_ACCESSIBLE_ROOF',
  OFFICE = 'OFFICE',
  PARKING = 'PARKING',
  RESIDENTIAL = 'RESIDENTIAL',
  SELF_WEIGHT = 'SELF_WEIGHT',
  SHOPPING = 'SHOPPING',
  SNOW_ABOVE_1000 = 'SNOW_ABOVE_1000',
  SNOW_BELOW_1000 = 'SNOW_BELOW_1000',
  SSE = 'SSE',
  TEMPERATURE = 'TEMPERATURE',
  WIND = 'WIND',
  OTHER = 'OTHER',
}

const hypothesisTypesList = ["DL", "LL", "SSE", "WIND", "TEMP", "EARTH"] as const;
export type hypothesisTypes = typeof hypothesisTypesList[number];

export interface Hypothesis {
  uid: string;
  name: string;
  favourableLoadFactor: number;
  unfavourableLoadFactor: number;
  massRatio: number;
  psi0: number;
  psi1: number;
  psi2: number;
  unfavorableSse: number;
  service: number;
  constructionPhase: number;
  type: hypothesisTypes;
  tag: loadHypothesisList;
}

// ------------------------------------------------------------------

export interface SSHypothesis extends Hypothesis {
  sseDir: SSEDirs;
  values: SSERecord[];
}
export enum SSEDirs { SSX = "SSx", SSY = "SSy", SSZ = "SSz" }
export type SSERecord = { T: number, ag: number }

export function isSSEHypothesis(hypothesis: Hypothesis): hypothesis is SSHypothesis {
  return "sseDir" in hypothesis && hypothesis.tag === loadHypothesisList.SSE;
}

// ------------------------------------------------------------------

export interface IwindHypothesis extends Hypothesis {
  subType: windSubTypes;
}
export enum windSubTypes {
  WX1P = "Wx1+",
  WX2P = "Wx2+",
  WX1N = "Wx1-",
  WX2N = "Wx2-",
  WY1P = "Wy1+",
  WY2P = "Wy2+",
  WY1N = "Wy1-",
  WY2N = "Wy2-",
}

export function isWindHypothesis(hypothesis: Hypothesis): hypothesis is IwindHypothesis {
  return "subType" in hypothesis && hypothesis.tag === loadHypothesisList.WIND;
}

// ------------------------------------------------------------------

export const displacementList = ["Dx", "Dy", "Dz", "DRx", "DRy", "DRz", "Dsum", "DRsum"];
export const beamFMList = ["Fx", "Fy", "Fz", "Mx", "My", "Mz"];
export const shellFMList = ["Nxx", "Nyy", "Nxy", "Qx", "Qy", "Mxx", "Myy", "Mxy"];
export const nodalFMList = ["Fx", "Fy", "Fz", "Mx", "My", "Mz"];

export interface displacementsParameterViewer {
  Dx: boolean,
  Dy: boolean,
  Dz: boolean,
  DRx: boolean,
  DRy: boolean,
  DRz: boolean,
  Dsum: boolean,
  DRsum: boolean,
}

// ------------------------------------------------------------------

export function getDefaultHypothesis(): Hypothesis[] {
  const selfWeight: Hypothesis = {
    uid: uuid(),
    name: loadHypothesisList.SELF_WEIGHT,
    favourableLoadFactor: 1,
    unfavourableLoadFactor: 2,
    massRatio: 3,
    psi0: 0.1,
    psi1: 0.2,
    psi2: 0.3,
    unfavorableSse: 1,
    service: 1,
    constructionPhase: 14,
    type: "DL",
    tag: loadHypothesisList.SELF_WEIGHT,
  };
  const deadLoad: Hypothesis = {
    uid: uuid(),
    name: loadHypothesisList.DEAD_LOAD,
    favourableLoadFactor: 1,
    unfavourableLoadFactor: 2,
    massRatio: 3,
    psi0: 0.1,
    psi1: 0.2,
    psi2: 0.3,
    unfavorableSse: 1,
    service: 1,
    constructionPhase: 120,
    type: "DL",
    tag: loadHypothesisList.DEAD_LOAD,
  };
  return [selfWeight, deadLoad];
}
export function getDefaultWindHypothesisValues(subType: windSubTypes): IwindHypothesis {
  return {
    uid: uuid(),
    name: subType,
    favourableLoadFactor: 0,
    unfavourableLoadFactor: 1.5,
    massRatio: 0,
    psi0: 0.6,
    psi1: 0.5,
    psi2: 0,
    unfavorableSse: 1,
    service: 1,
    constructionPhase: 360,
    type: "WIND",
    subType,
    tag: loadHypothesisList.WIND,
  }
}
export function getDefaultSEEHypothesisValues(dir: SSEDirs): SSHypothesis {
  return {
    uid: uuid(),
    name: dir,
    favourableLoadFactor: 1,
    unfavourableLoadFactor: 1,
    massRatio: 0,
    psi0: 1,
    psi1: 1,
    psi2: 1,
    unfavorableSse: 0,
    service: 0,
    constructionPhase: 360,
    type: "SSE",
    tag: loadHypothesisList.SSE,
    sseDir: dir,
    values: [
      { T: 0.05, ag: 0.4 },
      { T: 0.2, ag: 0.9 },
      { T: 0.8, ag: 0.9 },
      { T: 3, ag: 0.2 },
    ]
  }
}

export function getHypothesisFromOccupancies(hypos: Hypothesis[], storeys: StrucStorey[]): Hypothesis[] {
  const hypothesis = getDefaultHypothesis();
  const uniqueOccupancies = [...new Set(storeys.map(s => s.occupancy))];
  for (const occupancy of uniqueOccupancies) {
    const hypoFromOccupancy = hypos.find(h => h.name === occupancy);
    if (hypoFromOccupancy) {
      hypothesis.push(hypoFromOccupancy);
    } else {
      console.warn("[HYPOTHESIS] Hypothesis from occupancy not found ('getHypothesisFromOccupancies')")
    }
  }
  return hypothesis;
}
export const getHypothesisInfoprop = (prop: Hypothesis, editable: boolean) => {
  if (prop) {
    const info: dataInfoProperty<Hypothesis> = {
      name: { type: "string", publicName: "Name", value: prop.name, editable },
      favourableLoadFactor: { publicName: "Favourable Load Factor", value: prop.favourableLoadFactor, type: "number", editable },
      unfavourableLoadFactor: { publicName: "Unfavourable Load Factor", value: prop.unfavourableLoadFactor, type: "number", editable },
      massRatio: { publicName: "Mass Ratio", value: prop.massRatio, type: "number", editable },
      psi0: { publicName: "psi0", value: prop.psi0, type: "number", editable },
      psi1: { publicName: "psi1", value: prop.psi1, type: "number", editable },
      psi2: { publicName: "psi2", value: prop.psi2, type: "number", editable },
      unfavorableSse: { publicName: "Unfavourable see", value: prop.unfavorableSse, type: "number", editable },
      service: { publicName: "Service", value: prop.service, type: "number", editable },
      constructionPhase: { publicName: "Construction phase", value: prop.constructionPhase, type: "number", editable },
      tag: { type: "string", publicName: "Tag", value: prop.tag, editable },
      type: { type: "string", publicName: "Type", value: prop.type, editable },
    }
    return info;
  } else {
    return {}
  }
};
