import { useCallback, useEffect, useState } from "react";
import { cadOpType, iniCadFactory } from "lib/operations/factory";
import { StrucProject } from "modules/struc/models/project";
import { GraphicProcessor } from '../../../../../lib/graphic-processor';
import { useStrucProjectContext } from "modules/struc/components/context";

type Status = "idle" | "loading" | "mounted" | "unmounted" | "error";

let resizer: ResizeObserver;

export function useViewport(graphicProc: GraphicProcessor, project?: StrucProject) {

  const [status, setStatus] = useState<Status>("idle");

  const {
    meshManager,
    meshDispatcher,
    analysisDispatcher,
    analysisManager,
    hypothesisDispatcher
  } = useStrucProjectContext()

  const mountContainer = (node: HTMLDivElement) => {
    setStatus("loading");
    graphicProc.mount(node, project);
    resizer = new ResizeObserver(graphicProc.handleWindowResize);
    resizer.observe(node);
    setStatus("mounted");
  }
  
  const dispatchEvents = () => {
    const params = meshManager.mesh.params;
    meshDispatcher.dispatchLoadMeshProp(params);
    const solveHypo = analysisManager.analysis.solveHypothesis;
    if (solveHypo.length) {
      analysisDispatcher.dispatchSolve(solveHypo);
    }
    hypothesisDispatcher.dispatchLoad();
    graphicProc._structuralModelManager.dispatchLoadStoreys();
    graphicProc.getDataModelManager().layerManager.layerObserver.dispatchLoadLayers();
  }

  const unMountContainer = () => {
    resizer.disconnect();
    graphicProc.unmount();
    setStatus("unmounted");
  }

  useEffect(() => {
    if (status === "mounted") {
      iniCadFactory();
      graphicProc.launchOP(cadOpType.SELECT);
      dispatchEvents();
    }
  }, [status])

  const setContainerRef = useCallback((node: HTMLDivElement) => {
    if (node) {
      mountContainer(node)
    } else {
      unMountContainer();
    }
  }, [])

  return {
    setContainerRef,
    status,
  };
}
