import { GraphicProcessor } from 'lib/graphic-processor';
import CloseIcon from 'modules/cad/components/icons/close';
import PropertiesSelect from 'modules/cad/components/properties/component-select';
import PanelPropertiesForm from 'modules/cad/components/properties/properties-panel-form';
import { useMainGraphicContext } from 'modules/cad/components/viewport/context';
import ViewportLite from 'modules/cad/components/viewport/viewport-lite';
import { ChangeEvent, useEffect, useMemo } from 'react';
import DotsVerticalIcon from 'shared/components/icons/dots-vertical';
import { SecondaryButton } from 'shared/components/ui/button';
import HorizontalResizer from 'shared/components/ui/resizer/horizontal-resizer';
import Menu from 'shared/components/ui/menu';
import styled from 'styled-components';
import { usePileCapManager } from './hooks/use-pile-cap';
import { cadOpType, OpFactory } from '../../../../lib/operations/factory';
import CapTableCoordinates from './cap-table';
import PileTableCoordinates from './pile-table';

interface Props { onClose: () => void }

const PileCapTemplates = ({ onClose }: Props) => {

  const mainGraphicProc = useMainGraphicContext();
  useEffect(() => {
    OpFactory.stopOp(mainGraphicProc);
    return () => {
      mainGraphicProc.launchOP(cadOpType.SELECT);
    };
  }, [mainGraphicProc]);

  const graphicProc = useMemo(() => new GraphicProcessor("pileCapGPlite"), []);

  const {
    saveChanges,
    canBeSaved,
    selectStyle,
    deleteStyle,
    createStyle,
    selectedStyle,
    pileCapTemplatesLoaded,
    styleProperties,
    editStyle,

    addContourCap,
    capRows,
    updateCapData,

    addPile,
    pileRows,
    updatePileData,

  } = usePileCapManager(graphicProc);

  return (
    <Wrapper className="text-xs rounded">
      <Header>
        <h1>Pile cap templates</h1>
        <button onClick={onClose}>
          <CloseIcon className="h-4 w-4" />
        </button>
      </Header>
      <Content>
        <Box className={"flex gap-2 items-center justify-between"}>
          <label className={"text-xs text-gray-400 pr-2"}>Templates:</label>
          <PropertiesSelect
            srOnly={true}
            value={selectedStyle.styleId}
            wheelEvent={true}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              const newStyle = event.target.value;
              selectStyle(newStyle);
            }}>
            {pileCapTemplatesLoaded.map((val, i) => (
              <option key={i} value={val.styleId}> {`${val.name} [${val.styleId}]`} </option>
            ))}
          </PropertiesSelect>
          <Menu>
            <Menu.Button>
              <DotsVerticalIcon />
            </Menu.Button>
            <Menu.List>
              <Menu.Item onSelect={createStyle}> Create new </Menu.Item>
              <Menu.Item onSelect={deleteStyle}> Delete </Menu.Item>
            </Menu.List>
          </Menu>
        </Box>
        <HorizontalResizer id={"pileCapModal"}
          leftClassName={"w-1/2 p-2 min-w-min overflow-auto layers"}
          leftChild={
            <>
              <PanelPropertiesForm
                panelProp={{ propValue: styleProperties, propCallback: editStyle }}
              />
              <CapTableCoordinates
                graphicProc={graphicProc}
                dataTable={capRows}
                isDefault={selectedStyle.default}
                updateCapData={updateCapData}
                addCapData={addContourCap}
              />
              <PileTableCoordinates
                graphicProc={graphicProc}
                dataTable={pileRows}
                isDefault={selectedStyle.default}
                updatePileData={updatePileData}
                addPileData={addPile}
              />
            </>
          }
          rightClassName={"w-1/2"}
          rightChild={<ViewportLite graphicProc={graphicProc} />}
        />
        <Box className={"flex items-center justify-around"}>
          <SecondaryButton onClick={saveChanges} disabled={!canBeSaved}>
            Save
          </SecondaryButton>
          <SecondaryButton onClick={onClose}>
            Close
          </SecondaryButton>
        </Box>
      </Content>
    </Wrapper>
  )
};

const Wrapper = styled.div`
  overflow: hidden;
  width: 800px;
  margin: -32px;
  background-color: var(--color-gray-300);
`;
const Header = styled.div`
  background-color: var(--color-gray-300);
  border-bottom: 2px solid var(--color-primary);
  padding: 8px;
  display: flex;
  justify-content: space-between;
`;
const Content = styled.div`
  display: grid;
  grid-template-rows: 36px 340px 36px;
  grid-template-columns: auto;
  gap: 4px;
  padding: 8px;
  height: 100%;
`;
const Box = styled.div`
  background-color: var(--color-gray-200);
  padding: 8px;
  border-radius: 4px;
  overflow: hidden;
`;

export default PileCapTemplates;
