import { BeamCrossSection } from "lib/models-struc/cross-sections-shape/beam-cross-sections/beamcs-shapes";
import { beamCrossSectionCache } from "lib/models-struc/cross-sections-shape/beam-cross-sections/cache";
import { IRectangular, ISectionI, ISectionT, ISectionU, IQuadShape, sectionType, ICircular, ITube, ICustom } from "lib/models-struc/cross-sections-shape/beam-cross-sections/types";
import { ShellCrossSection } from "lib/models-struc/cross-sections-shape/shell-cross-sections/shellcs-shapes";
import { shellCrossSectionCache } from "lib/models-struc/cross-sections-shape/shell-cross-sections/cache";
import { sandwichCrossSection, ShelCSSType, waffleCrossSection } from "lib/models-struc/cross-sections-shape/shell-cross-sections/types";
import { CrossSectionShape, RectangleShape, TShape, IShape, UShape, TubeShape, CircleShape, CustomCSS, BeamCSS, ShellCSS, QuadShape, WaffleCSS, SandwichCSS, defaulRectangleShape, defaulTShape, defaulIShape, defaulUShape, defaulCircleShape, defaulTubeShape, defaultWaffleShapeParam, defaultSandwichParam } from "modules/struc/models/ecore/cross-section-shape";
import { getEcoreFloat } from "./helper-ecore";
import { getEcorePlacement } from "./locations";
import { getEcoreClosedWireGeometry } from "./geometry";
import { baseUriModel } from "./mesh-exporter";
import { representationUriModel } from "modules/struc/models/ecore/uri";

export function getEcoreCrossSectionShapeSet() {
  const crossSectionShapes: CrossSectionShape[] = [];
  const crossSectionShapesIds: string[] = [];
  const bcss = beamCrossSectionCache.getAllLoadedStyles();
  for (const bcs of bcss) {
    const sectionEcore = getBeamCrossSectionShapeEcore(bcs);
    crossSectionShapes.push(sectionEcore);
    crossSectionShapesIds.push(bcs.styleId);
  }
  const scss = shellCrossSectionCache.getAllLoadedStyles();
  for (const scs of scss) {
    const sectionEcore = getShellCrossSectionShapeEcore(scs);
    crossSectionShapes.push(sectionEcore);
    crossSectionShapesIds.push(scs.styleId);
  }
  return {
    crossSectionShapesIds,
    crossSectionShapes,
  };
}

//-----------------------------------------------------------------------------------

function getBeamCrossSectionShapeEcore(bcs: BeamCrossSection): BeamCSS {
  const type = bcs.parameter.beamSectionType;
  switch (type) {
    case sectionType.RECT: return getRectangularSectionEcore(bcs.parameter);
    case sectionType.T: return getTSectionEcore(bcs.parameter);
    case sectionType.I: return getISectionEcore(bcs.parameter);
    case sectionType.U: return getUSectionEcore(bcs.parameter);
    case sectionType.QUAD: return getQuadSectionEcore(bcs.parameter);
    case sectionType.CIRC: return getCircleSectionEcore(bcs.parameter);
    case sectionType.TUBE: return getTubeSectionEcore(bcs.parameter);
    case sectionType.CUSTOM: return getCustomSectionEcore(bcs.parameter);
  }
}
function getRectangularSectionEcore(param: IRectangular): RectangleShape {
  const section: RectangleShape = { eClass: baseUriModel + "resources/crosssectionshapes/RectangleShape" };
  const width = getEcoreFloat(param.width, defaulRectangleShape.width);
  const height = getEcoreFloat(param.height, defaulRectangleShape.height);
  if (width !== undefined) section.width = width;
  if (height !== undefined) section.height = height;
  return section;
}
function getTSectionEcore(param: ISectionT): TShape {
  const section: TShape = { eClass: baseUriModel + "resources/crosssectionshapes/TShape" };
  const width = getEcoreFloat(param.topFlangeLength, defaulRectangleShape.width);
  const height = getEcoreFloat(param.height, defaulRectangleShape.height);
  const wt = getEcoreFloat(param.webThickness, defaulTShape.wt);
  const ft = getEcoreFloat(param.topFlangeThickness, defaulTShape.ft);
  if (width !== undefined) section.width = width;
  if (height !== undefined) section.height = height;
  if (wt !== undefined) section.wt = wt;
  if (ft !== undefined) section.ft = ft;
  return section;
}
function getISectionEcore(param: ISectionI): IShape {
  const section: IShape = { eClass: baseUriModel + "resources/crosssectionshapes/IShape" };
  const width = getEcoreFloat(param.topFlangeLength, defaulRectangleShape.width);
  const height = getEcoreFloat(param.height, defaulRectangleShape.height);
  const wt = getEcoreFloat(param.webThickness, defaulTShape.wt);
  const ft = getEcoreFloat(param.topFlangeThickness, defaulTShape.ft);
  const lft = getEcoreFloat(param.bottomFlangeThickness, defaulIShape.lft);
  const lw = getEcoreFloat(param.bottomFlangeLength, defaulIShape.lw);
  if (width !== undefined) section.width = width;
  if (height !== undefined) section.height = height;
  if (wt !== undefined) section.wt = wt;
  if (ft !== undefined) section.ft = ft;
  if (lft !== undefined) section.lft = lft;
  if (lw !== undefined) section.lw = lw;
  return section;
}
function getUSectionEcore(param: ISectionU): UShape {
  const section: UShape = { eClass: baseUriModel + "resources/crosssectionshapes/UShape" };
  const width = getEcoreFloat(param.width, defaulRectangleShape.width);
  const height = getEcoreFloat(param.height, defaulRectangleShape.height);
  const thf = getEcoreFloat(param.horizontalFlangeThickness, defaulUShape.thf);
  const tvw = getEcoreFloat(param.verticalWebThickness, defaulUShape.tvw);
  if (width !== undefined) section.width = width;
  if (height !== undefined) section.height = height;
  if (thf !== undefined) section.thf = thf;
  if (tvw !== undefined) section.tvw = tvw;
  return section;
}
function getQuadSectionEcore(param: IQuadShape): QuadShape {
  const section: QuadShape = { eClass: baseUriModel + "resources/crosssectionshapes/QuadShape" };
  const width = getEcoreFloat(param.width, defaulRectangleShape.width);
  const height = getEcoreFloat(param.height, defaulRectangleShape.height);
  const thf = getEcoreFloat(param.horizontalFlangeThickness, defaulUShape.thf);
  const tvw = getEcoreFloat(param.verticalWebThickness, defaulUShape.tvw);
  if (width !== undefined) section.width = width;
  if (height !== undefined) section.height = height;
  if (thf !== undefined) section.thf = thf;
  if (tvw !== undefined) section.tvw = tvw;
  return section;
}

function getCircleSectionEcore(param: ICircular): CircleShape {
  const section: CircleShape = { eClass: baseUriModel + "resources/crosssectionshapes/CircleShape" };
  const radio = getEcoreFloat(param.radius, defaulCircleShape.radio);
  if (radio !== undefined) section.radio = radio;
  return section;
}
function getTubeSectionEcore(param: ITube): TubeShape {
  const section: TubeShape = { eClass: baseUriModel + "resources/crosssectionshapes/TubeShape" };
  const radio = getEcoreFloat(param.radius, defaulCircleShape.radio);
  const thickness = getEcoreFloat(param.thickness, defaulTubeShape.thickness);
  if (radio !== undefined) section.radio = radio;
  if (thickness !== undefined) section.thickness = thickness;
  return section;
}

function getCustomSectionEcore(param: ICustom): CustomCSS {
  return {
    eClass: baseUriModel + "resources/crosssectionshapes/CustomCSS",
    representation: {
      eClass: `${representationUriModel}GeoRepresentation`,
      placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
      base: getEcoreClosedWireGeometry(param.polyline.points),
    }
  };
}

//-----------------------------------------------------------------------------------

function getShellCrossSectionShapeEcore(scs: ShellCrossSection): ShellCSS {
  const section: ShellCSS = { eClass: "" };
  const thickness = getEcoreFloat(scs.thickness, 0.3);
  if (thickness !== undefined) section.thickness = thickness;
  const type = scs.parameters.shellSectionType;
  switch (type) {
    case ShelCSSType.FLAT: return getFlatSectionEcore(section);
    case ShelCSSType.WALL: return getWallEcore(section);
    case ShelCSSType.WAFFLE: return getWaffleSectionEcore(section, scs.parameters);
    case ShelCSSType.SANDWICH: return getSandwichSectionEcore(section, scs.parameters);
  }
}
function getFlatSectionEcore(section: ShellCSS) {
  section.eClass = baseUriModel + "resources/crosssectionshapes/FlatCSS";
  return section;
}
function getWallEcore(section: ShellCSS) {
  section.eClass = baseUriModel + "resources/crosssectionshapes/WallCSS";
  return section;
}
function getWaffleSectionEcore(section: WaffleCSS, param: waffleCrossSection) {
  section.eClass = baseUriModel + "resources/crosssectionshapes/WaffleCSS";
  getwaffleParam(param, section);
  return section;
}
function getSandwichSectionEcore(section: SandwichCSS, param: sandwichCrossSection) {
  section.eClass = baseUriModel + "resources/crosssectionshapes/SandwichCSS";
  getwaffleParam(param, section);
  const lowerCompressionLayerDepth = getEcoreFloat(param.lowerCompressionLayerDepth, defaultSandwichParam.lowerCompressionLayerDepth);
  if (lowerCompressionLayerDepth !== undefined) section.lowerCompressionLayerDepth = lowerCompressionLayerDepth;
  return section;
}
function getwaffleParam(param: waffleCrossSection | sandwichCrossSection, section: WaffleCSS) {
  const xInteraxis = getEcoreFloat(param.xInteraxis, defaultWaffleShapeParam.xInteraxis);
  const yInteraxis = getEcoreFloat(param.yInteraxis, defaultWaffleShapeParam.yInteraxis);
  const ribWidth = getEcoreFloat(param.ribWidth, defaultWaffleShapeParam.ribWidth);
  const numberOfBlocks = getEcoreFloat(param.numberOfBlocks, defaultWaffleShapeParam.numberOfBlocks);
  const outerNonstBeamWidth = getEcoreFloat(param.outerNonstBeamWidth, defaultWaffleShapeParam.outerNonstBeamWidth);
  const innerNonstBeamWidth = getEcoreFloat(param.innerNonstBeamWidth, defaultWaffleShapeParam.innerNonstBeamWidth);
  const minDropPanelEdge = getEcoreFloat(param.minDropPanelEdge, defaultWaffleShapeParam.minDropPanelEdge);
  const rotationAngle = getEcoreFloat(param.rotationAngle, defaultWaffleShapeParam.rotationAngle);
  const centerWaffleX = getEcoreFloat(param.centerWaffleX, defaultWaffleShapeParam.centerWaffleX);
  const centerWaffleY = getEcoreFloat(param.centerWaffleY, defaultWaffleShapeParam.centerWaffleY);
  const dropPanelRatio = getEcoreFloat(param.dropPanelRatio, defaultWaffleShapeParam.dropPanelRatio);
  const compressionLayerDepth = getEcoreFloat(param.compressionLayerDepth, defaultWaffleShapeParam.compressionLayerDepth);
  if (xInteraxis !== undefined) section.xInteraxis = xInteraxis;
  if (yInteraxis !== undefined) section.yInteraxis = yInteraxis;
  if (ribWidth !== undefined) section.ribWidth = ribWidth;
  if (numberOfBlocks !== undefined) section.numberOfBlocks = numberOfBlocks;
  if (outerNonstBeamWidth !== undefined) section.outerNonstBeamWidth = outerNonstBeamWidth;
  if (innerNonstBeamWidth !== undefined) section.innerNonstBeamWidth = innerNonstBeamWidth;
  if (minDropPanelEdge !== undefined) section.minDropPanelEdge = minDropPanelEdge;
  if (rotationAngle !== undefined) section.rotationAngle = rotationAngle;
  if (centerWaffleX !== undefined) section.centerWaffleX = centerWaffleX;
  if (centerWaffleY !== undefined) section.centerWaffleY = centerWaffleY;
  if (dropPanelRatio !== undefined) section.dropPanelRatio = dropPanelRatio;
  if (compressionLayerDepth !== undefined) section.compressionLayerDepth = compressionLayerDepth;
}