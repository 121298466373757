import { RegionEditDataCommand } from "lib/commands/edition/region";
import { IPoint } from "lib/math/types";
import { RegionData } from "lib/models/solids/region";
import { regionParam, updateFilledPolygon2DPoints } from "lib/geometries/solid/region";
import { getRelativePoint } from "lib/coordinates/plane";
import { SimpleEdition } from "../edition";
import { cadOpType } from "../factory";
import { settingsOpModes } from "../step-operations";

export class MoveRegionVertexOP extends SimpleEdition {
  public opType = cadOpType.EDITREGION;
  protected objData: RegionData;
  protected auxRegion: THREE.Mesh;
  protected auxDefinition: regionParam;
  protected indexToEdit: number;

  constructor(data: RegionData, indexToEdit: number, iniPoint: IPoint) {
    super(iniPoint);
    this.indexToEdit = indexToEdit;
    this.objData = data;
    this.auxDefinition = this.objData.cloneDefinition();
    this.auxRegion = this.objData.createObject(this.auxDefinition);
  }
  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Edit point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
    }]);
  }
  public async start() {
    await super.start();
    this.objData.visibleGraphicObject = false;
    this.saveToTempScene(this.auxRegion);
    const planeManager = this.graphicProcessor.getPlaneManager();
    planeManager.activePlane.position = this.objData.definition.basePoint;
    planeManager.activePlane.rotation = this.objData.definition.rotation;
    planeManager.activePlane.locked = true;
  }
  public setLastPoint() {
    this.endOperation();
  }
  public moveLastPoint(pto: IPoint) {
    this.auxDefinition = this.objData.cloneDefinition();
    const { basePoint, rotation } = this.auxDefinition;
    this.auxDefinition.ptos2D[this.indexToEdit] = getRelativePoint(pto, basePoint, rotation);
    updateFilledPolygon2DPoints(this.auxRegion, this.auxDefinition.ptos2D);
    this.auxRegion.position.set(basePoint.x, basePoint.y, basePoint.z);
    this.auxRegion.rotation.set(rotation.x, rotation.y, rotation.z);
  }
  public cancelOperation(): void {
    this.objData.visibleGraphicObject = true;
    super.cancelOperation();
  }
  public endOperation(): void {
    this.objData.visibleGraphicObject = true;
    super.endOperation();
  }
  public save() {
    const command = new RegionEditDataCommand(this.objData, this.auxDefinition, null, this.graphicProcessor);
    this.graphicProcessor.storeAndExecute(command);
  }
}