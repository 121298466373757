import { forwardRef, SelectHTMLAttributes } from "react";
import cn from "classnames";

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  srOnly?: boolean;
  label: string;
  dark?: boolean;
}
const FormSelect = forwardRef<HTMLSelectElement, Props>(
  ({ id, label, srOnly, dark, children, className, ...props }, ref) => {
    const rootClassName = cn(
      "text-sm leading-4 block border-gray-600 focus:outline-none rounded w-full bg-gray-700 text-gray-300 focus:ring-indigo-500 overflow-hidden mt-1",
      className
    );
    return (
      <div>
        <label
          htmlFor={id}
          className={srOnly ? "sr-only" : `block text-sm font-medium text-gray-${dark ? '2' : '4'}00 capitalize`}
        >
          {label}
          <select className={rootClassName} id={id} ref={ref} {...props}>
            {children}
          </select>
        </label>
      </div>
    );
  }
);

export default FormSelect;
