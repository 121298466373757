import { Project } from "modules/lite/models/project";
import cn from "classnames";
import Table from "shared/components/ui/table";
import { formatDate } from "shared/utils/dates";
import { useUI } from "shared/components/ui/context";
import DotsHorizontalIcon from "shared/components/icons/dots-horizontal";
import Menu from "shared/components/ui/menu";

interface Props {
  projects: Project[];
}

const getStatusClassName = (status: string) =>
  cn(
    "px-2 inline-flex text-sm leading-5 font-medium rounded capitalize",
    status === "created" && "bg-blue-200 text-blue-800",
    status === "waiting" && "bg-yellow-200 text-yellow-800",
    status === "success" && "bg-green-200 text-green-800",
    status === "error" && "bg-red-200 text-red-800",
    status === "CREATED" && "bg-blue-200 text-blue-800",
    status?.includes("PENDING") && "bg-yellow-200 text-yellow-800",
    status?.includes("SUCCESS") && "bg-green-200 text-green-800",
    status?.includes("ERROR") && "bg-red-200 text-red-800"
  );

const getLink = (id?: string) => `/lite/projects/${id}`;

const ProjectList = ({ projects = [] }: Props) => {
  const { openModal } = useUI();
  return (
    <Table.Container>
      <Table>
        <Table.Header>
          <Table.HeaderCell style={{ width: 1, minWidth: 150 }}>
            Name
          </Table.HeaderCell>
          <Table.HeaderCell>Username</Table.HeaderCell>
          <Table.HeaderCell>Storeys</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Updated</Table.HeaderCell>
          <Table.HeaderCell style={{ width: 1 }} />
        </Table.Header>
        <Table.Body>
          {projects?.map(
            ({ id, updatedAt, name, status, number_of_storeys , user }) => (
              <Table.Row key={id}>
                <Table.Cell
                  className="font-medium text-gray-700"
                  to={getLink(id)}
                >
                  {name}
                </Table.Cell>
                <Table.Cell to={getLink(id)}>{user?.username || ''}</Table.Cell>
                <Table.Cell to={getLink(id)}>{number_of_storeys}</Table.Cell>
                <Table.Cell to={getLink(id)}>
                  <span className={getStatusClassName(status)}>{status}</span>
                </Table.Cell>
                <Table.Cell to={getLink(id)}>
                  {formatDate(updatedAt)}
                </Table.Cell>
                <Table.Cell>
                  <Menu>
                    <Menu.Button>
                      <DotsHorizontalIcon className="h-6 w-6" />
                    </Menu.Button>
                    <Menu.List>
                      <Menu.Link to={`/lite/projects/${id}/edit`}>
                        Edit
                      </Menu.Link>
                      <Menu.Item
                        onSelect={() => openModal("DELETE_PROJECT", { id })}
                      >
                        Delete
                      </Menu.Item>
                    </Menu.List>
                  </Menu>
                </Table.Cell>
              </Table.Row>
            )
          )}
        </Table.Body>
      </Table>
    </Table.Container>
  );
};

export default ProjectList;
