import { FC } from "react";
import cn from "classnames";
interface Props {
  title: string;
  className?: string;
}

interface Composition {
  Row: FC;
}
const ToolbarSection: FC<Props> & Composition = ({
  children,
  title,
  className,
}) => {
  const rootClassName = cn("flex items-center", className);
  return (
    <div className={rootClassName}>
      <div className="flex items-center">{children}</div>
      {/* <div className="flex items-center justify-center h-full py-0 border-t border-gray-600 w-full px-2 bg-gray-700">
        <span className="text-xs">{title}</span>
      </div> */}
    </div>
  );
};

const Row: FC = ({ children }) => (
  <div className="flex items-center">{children}</div>
);
ToolbarSection.Row = Row;
export default ToolbarSection;
