import { useRef } from "react";

// https://felixgerschau.com/react-rerender-components/

interface RenderCounterProps {
  name: string;
}
const RenderCounter = ({ name }: RenderCounterProps) => {
  const rerenderCounter = useRef(0);
  rerenderCounter.current += 1;
  console.log("[REACT RENDERER] " + name + ":", rerenderCounter.current);
  return <></>;
};
export default RenderCounter;