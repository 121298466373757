import { RprismEditDataCommand } from "lib/commands/edition/pprism";

import { linealPrecision } from "lib/general-settings";
import { rprismParam } from "lib/geometries/solid/rprism";
import { volumeRPrism } from "lib/geometries/volume";
import { IPoint } from "lib/math/types";
import { RPrismData } from "lib/models/solids/rprism";
import { dataInfoProperty } from "../properties";
import { SolidDataDefinitionHandler } from "../solid";
import { ISolidMaterial } from '../../materials/solid';

export type rprismProp = rprismParam & { volume: number; centroid: IPoint; }
export type rprismView = dataInfoProperty<rprismProp>
export class RPrismDataDefinitionHandler extends SolidDataDefinitionHandler<rprismProp> {

  protected data: RPrismData;

  protected buildInfoProperties() {
    this.definitionInfo = {};
    const def = this.data.definition;
    this.definitionInfo.sideX = this.getNumberView(def.sideX, "Side x", "m", linealPrecision, undefined, (sideX: number) => sideX > 0);
    this.definitionInfo.sideY = this.getNumberView(def.sideY, "Side y", "m", linealPrecision, undefined, (sideY: number) => sideY > 0);
    this.definitionInfo.sideZ = this.getNumberView(def.sideZ, "Side z", "m", linealPrecision, undefined, (sideZ: number) => sideZ > 0);
    this.fillSolidDefinition(this.data);
    this.definitionInfo.volume = this.getNumberView(volumeRPrism(def), "Volume", "m³", linealPrecision, false);
  }

  private checkNewDefinition(newDefinition: rprismProp): boolean {
    if (!newDefinition) { return false; }
    if (!this.checkSolidNewDefinition(newDefinition)) { return false; }
    if (!this.checkNumber(newDefinition.sideX)) return false;
    if (!this.checkNumber(newDefinition.sideY)) return false;
    if (!this.checkNumber(newDefinition.sideZ)) return false;
    return true;
  }

  private changedNewDefinition(oldDefinition: rprismParam, newDefinition: rprismProp): rprismParam | null {
    let def = oldDefinition;
    let changed: boolean = false;
    if (newDefinition) {
      const solid = this.changedSolidNewDefinition(oldDefinition, newDefinition)
      if (solid !== null) {
        def.basePoint = solid.basePoint;
        def.rotation = solid.rotation;
        def.scale = solid.scale;
        def.offset = solid.offset;
        changed = true;
      }
      const sideX = this.changedNumber(def.sideX, newDefinition.sideX);
      if (sideX !== null) {
        def.sideX = sideX;
        changed = true;
      }
      const sideY = this.changedNumber(def.sideY, newDefinition.sideY);
      if (sideY !== null) {
        def.sideY = sideY;
        changed = true;
      }
      const sideZ = this.changedNumber(def.sideZ, newDefinition.sideZ);
      if (sideZ !== null) {
        def.sideZ = sideZ;
        changed = true;
      }
    }
    return (changed ? def : null);
  }

  private checkAndChangedDefinition(oldDefinition: rprismParam, newDefinition: rprismProp): rprismParam | null {
    let def = null;
    if (this.checkNewDefinition(newDefinition)) {
      def = this.changedNewDefinition(oldDefinition, newDefinition);
    }
    return def;
  }

  public saveAndRegenerate = (newDefinition: rprismProp) => {
    const rprismDefinition = this.checkAndChangedDefinition(this.data.cloneDefinition(), newDefinition);
    if (rprismDefinition) {
      const command = new RprismEditDataCommand(this.data, rprismDefinition, null, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    };
  }
  public saveAndRegenerateMaterial = (newMaterial: ISolidMaterial) => {
    const rprismMaterial = this.checkAndChangedSolidMaterial(this.data.material, newMaterial);
    if (rprismMaterial) {
      const command = new RprismEditDataCommand(this.data, null, rprismMaterial, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    };
  }
}