import { GraphicProcessor } from "lib/graphic-processor";
import { lineAngle2p } from "lib/math/angles";
import { vectorDist3D } from "lib/math/distance";
import { substractIpoint } from "lib/math/point";
import { IObjData } from "lib/models/objdata";
import { IAlignOptions } from "lib/operations/edition/align";
import { cadOpType } from "lib/operations/factory";
import { TransformCommand } from "./transform";

export class AlignCommand extends TransformCommand {
  protected opType = cadOpType.ALIGN;
  private alignOpts: IAlignOptions;
  private angle: number;
  private factorScale: number;

  constructor(objsDatas: IObjData[], options: IAlignOptions, graphicProcessor: GraphicProcessor) {
    super(objsDatas, graphicProcessor);
    this.alignOpts = options;
    const angleIni = lineAngle2p(this.alignOpts.firstPointIni, this.alignOpts.secondPointIni);
    const angleEnd = lineAngle2p(this.alignOpts.firstPointEnd, this.alignOpts.secondPointEnd);
    this.angle = angleEnd - angleIni;
    const distance1 = vectorDist3D(this.alignOpts.secondPointIni, this.alignOpts.firstPointIni);
    const distance2 = vectorDist3D(this.alignOpts.secondPointEnd, this.alignOpts.firstPointEnd);
    this.factorScale = distance2 / distance1;
  }

  protected transformFun = (obj: IObjData) => {
    obj.translate(substractIpoint(this.alignOpts.firstPointEnd, this.alignOpts.firstPointIni));
    obj.rotate(0, 0, this.angle, this.alignOpts.firstPointEnd)
    if (this.alignOpts.withScale) {
      obj.scale(this.factorScale, this.factorScale, this.factorScale, this.alignOpts.firstPointEnd);
    }
  };
  protected unDoTransformFun = (obj: IObjData) => {
    if (this.alignOpts.withScale) {
      obj.scale(1 / this.factorScale, 1 / this.factorScale, 1 / this.factorScale, this.alignOpts.firstPointEnd);
    }
    obj.rotate(0, 0, -this.angle, this.alignOpts.firstPointEnd)
    obj.translate(substractIpoint(this.alignOpts.firstPointIni, this.alignOpts.firstPointEnd));
  };
}