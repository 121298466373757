import { useQuery } from "@apollo/client";
import { useAuth } from "../../auth/context";
import { User } from "shared/models/user";
import { GET_USER } from "shared/services/user";

interface QueryResult {
  user: User;
}

export function useSettings() {
  const { user } = useAuth();
  const { data, error, loading } = useQuery<QueryResult>(GET_USER, {
    variables: { id: user?.id },
  });
  return {
    user: data?.user,
    error,
    loading,
  };
}
