const ChamferIcon = ({ ...props }) => {
  return (
    <svg className="h-5 w-5" viewBox="0 0 100 100" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.4615 14.4231C48.0769 14.4231 15.1442 46.3942 15.3846 64.4231V97.1154H98.0769V14.4231H63.4615Z"
        stroke="currentColor"
        strokeWidth="3.84615"
        strokeLinecap="round"
      />
      <path
        d="M48.0769 2.88464C33.7904 2.88464 3.21154 32.6558 3.43462 49.4423"
        stroke="currentColor"
        strokeWidth="3.84615"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ChamferIcon;
