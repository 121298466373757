
import { GraphicProcessor } from "lib/graphic-processor";
import { IObjData } from "lib/models/objdata";
import { SelectCacheThreeMaterial } from "./selection-material-cache";
import { preSelectionColor } from "./selection-tools";

export class preSelector {

  private graphicProcessor: GraphicProcessor;

  private cachePreSelect = new SelectCacheThreeMaterial(preSelectionColor);
  private enabled: boolean = true;

  constructor(graphicProcessor: GraphicProcessor) {
    this.graphicProcessor = graphicProcessor;
    this.registerMouseMoveEvent();
  }
  disable() {
    this.enabled = false;
  }
  enable() {
    this.enabled = true;
  }

  registerMouseMoveEvent() {
    const parentCanvas = this.graphicProcessor.container;
    parentCanvas.addEventListener("pointermove", this.preSelectDataMouseMove);
  }
  unregisterMouseMoveEvent() {
    const parentCanvas = this.graphicProcessor.container;
    parentCanvas.removeEventListener("pointermove", this.preSelectDataMouseMove);
    this.cachePreSelect.clearSelection();
    this.cachePreSelect.clear();
    this.cachePreSelect = undefined!;
    this.graphicProcessor = undefined!;
  }
  private preSelectDataMouseMove = () => {
    if (this.enabled) {
      this.cachePreSelect.clearSelection();
      const castObjs = this.graphicProcessor.getRayCastObjects();
      if (castObjs.length) {
        const o = castObjs[0].dataObject;
        this.preSelectData(o);
      }
    }
  }

  preSelectData(data: IObjData) {
    const selecMngr = this.graphicProcessor.getSelectionManager();
    if (selecMngr.canBeSelected(data)) {
      this.cachePreSelect.selectObj(data.graphicObj);
    }
  }
  unPreSelectData(data: IObjData) {
    this.cachePreSelect.unselectObj(data.graphicObj);
  }
  unPreselectAll() {
    this.cachePreSelect.clearSelection();
  }
}
