const PerpendicularIcon = ({ ...props }) => {
  return (
    <svg className="h-5 w-5" viewBox="0 0 100 100" fill="none" {...props}>
      <path
        d="M22.0445 99.9561L0 99.9197V93.8465V87.7732L21.9902 87.7459L43.9805 87.7185L44.0077 43.8736L44.035 0.0286865H50H55.965L55.9923 43.8736L56.0195 87.7185L78.0098 87.7459L100 87.7732V93.8467V99.9202H80.9832C70.524 99.9202 57.944 99.9365 53.0277 99.9564C48.1114 99.9763 34.1689 99.9762 22.0445 99.9564V99.9561Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PerpendicularIcon;
