

export function Float32Concat(buffer: Float32Array, second: Float32Array) {
  const firstLength = buffer.length;
  const result = new Float32Array(firstLength + second.length);
  result.set(buffer);
  result.set(second, firstLength);
  return result;
}
export function Float32splice(buffer: Float32Array, start: number, end: number, second: Float32Array) {
  const deleteCount = (end - start);
  const newSize = buffer.length - deleteCount + second.length;
  if (newSize === buffer.length) {
    buffer.set(second, start);
  } else {
    const splicedArray = new Float32Array(newSize);
    splicedArray.set(buffer.subarray(0, start));
    splicedArray.set(second, start);
    splicedArray.set(buffer.subarray(end), start + second.length);
    return splicedArray;
  }
}
export function Float32delete(buffer: Float32Array, start: number, end: number) {
  const deleteCount = (end - start);
  const newSize = buffer.length - deleteCount;
  const splicedArray = new Float32Array(newSize);
  splicedArray.set(buffer.subarray(0, start));
  splicedArray.set(buffer.subarray(end), start);
  return splicedArray;
}

export function Uint16Concat(buffer: Uint16Array, second: Uint16Array) {
  const firstLength = buffer.length;
  const result = new Uint16Array(firstLength + second.length);
  result.set(buffer);
  result.set(second, firstLength);
  return result;
}
export function Uint16Delete(buffer: Uint16Array, start: number, end: number) {
  const deleteCount = (end - start);
  const newSize = buffer.length - deleteCount;
  const splicedArray = new Uint16Array(newSize);
  splicedArray.set(buffer.subarray(0, start));
  splicedArray.set(buffer.subarray(end), start);
  return splicedArray;
}
export function Uint16splice(buffer: Uint16Array, start: number, end: number, second: Uint16Array) {
  const deleteCount = (end - start);
  const newSize = buffer.length - deleteCount + second.length;
  if (newSize === buffer.length) {
    buffer.set(second, start);
  } else {
    const splicedArray = new Uint16Array(newSize);
    splicedArray.set(buffer.subarray(0, start));
    splicedArray.set(second, start);
    splicedArray.set(buffer.subarray(end), start + second.length);
    return splicedArray;
  }
}