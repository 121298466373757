import { FC } from "react";
import Input from "shared/components/ui/input";
import { PrimaryButton, SecondaryButton } from "shared/components/ui/button";
import { useAddLayer } from "./hooks/use-add-layer";
import { useMainGraphicContext } from "../viewport/context";

interface Props {
  onClose: () => void;
}

const AddLayerForm: FC<Props> = ({ onClose }) => {

  const graphicProc = useMainGraphicContext();
  const { ref, handleSubmit, errors } = useAddLayer(graphicProc);

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-6">
        <div>
          <h1 className="text-lg leading-6 font-medium text-gray-400">
            New layer
          </h1>
        </div>
        <Input
          id="name"
          name="name"
          label="Name"
          ref={ref({
            required: "Required field",
          })}
          error={errors.name?.type}
        />
        <div className="flex gap-4">
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
          <PrimaryButton type="submit">Create</PrimaryButton>
        </div>
      </div>
    </form>
  );
};

export default AddLayerForm;
