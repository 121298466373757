import { ArcAngleDimensionCommand, ArcLongDimensionCommand } from "lib/commands/dimension/arc-dim";
import { createArcDimension, updateArcDimension } from "lib/dimension/arc-dim-builder";
import { getCustomDimStyleProperties } from "lib/dimension/style";
import { arcParam } from "lib/math/arc";
import { vectorDist3D } from "lib/math/distance";
import { arcLineToArcParam, getIsegmentFromIndex } from "lib/math/line";
import { IPoint } from "lib/math/types";
import { isArcData, isLineData } from "lib/models/checktools";
import { arcDimensionParam, arcDimType } from "lib/models/dimension/arc-dim";
import { IObjData } from "lib/models/objdata";
import { cadOpType } from "../factory";
import { IPointReference, settingsOpModes } from "../step-operations";
import { DimensionOP } from "./dimension-base";

abstract class ArcDimensionOP extends DimensionOP {

  /*   POSICION DE LOS PUNTOS QUE DEFINEN LA GEOMETRÍA DE LA ACOTACIÓN
          2          4
          |         /
         0|________/1
          |       /
          |      /
          3     5

          7___8

          .6
  */
  protected abstract type: arcDimType;

  protected arcParam: arcParam;
  protected edgeIndex: number | undefined;

  protected iniSettingsOp(): void {
    this.settingsOpManager.setCfg([{
      infoMsg: "Select arc.",
      stepMode: settingsOpModes.SELECTVERTEX,
      filterFun: (obj: IObjData) => {
        if (isArcData(obj)) return true;
        if (isLineData(obj)) return true;
        return false;
      },
      getVertexCallback: (ref: IPointReference) => {
        const data = ref.data;
        if (data) {
          if (isArcData(data)) {
            this.objDataOrigin = [data];
            this.arcParam = data.definition;
            this.setNextStep();
          } else if (isLineData(data)) {
            this.edgeIndex = ref.edgeIndex;
            const edge = getIsegmentFromIndex(data.definition, ref.edgeIndex);
            if (edge.arc) {
              this.arcParam = arcLineToArcParam(edge.p1, edge.p2, edge.arc)
              this.objDataOrigin = [data];
              this.setNextStep();
            }
          }
        }
      },
      endStepCallback: this.unRegisterRaycast.bind(this),
    }, {
      infoMsg: "Insert dimension.",
      stepMode: settingsOpModes.WAITMODE,
      startStepCallback: () => {
        this.registerInputs();
        this.registerUpdaters();
        this.initializeSnap();
        this.inicializeArcDim();
      },
      stepFun: this.dispathSaveMouseCoordinates.bind(this),
    }]);
  }

  public async start() {
    await super.start();
    this.registerCancel();
    this.registerRaycast();
  }

  private inicializeArcDim(): void {
    const planeManager = this.graphicProcessor.getPlaneManager();
    planeManager.activePlane.position = this.arcParam.center;
    planeManager.activePlane.rotation = this.arcParam.plane;
    planeManager.activePlane.locked = true;

    const dim = createArcDimension(this.type, this.arcParam, true, this.dimStyle, this.txtStyle);
    this.saveDimtoTemp(dim);
  }

  public setLastPoint(): void {
    this.save();
    this.endOperation();
  }
  public moveLastPoint(point: IPoint) {
    this.dimStyle.distBaseLine = vectorDist3D(point, this.arcParam.center) - this.arcParam.radius;
    const dimInfo: arcDimensionParam = {
      type: this.type,
      styleId: this.dimStyle.styleId,
      customStyleProp: getCustomDimStyleProperties(this.dimStyle, this.defDimStyle),
      arcBase: this.arcParam,
      InnerArc: this.dimDirectionIsInArc(this.arcParam, point),
      data: this.objDataOrigin[0],
    };
    updateArcDimension(this.dimensionGroup, dimInfo, this.dimStyle, this.txtStyle);
  }
}
export class ArcAngleDimensionOP extends ArcDimensionOP {

  public opType = cadOpType.ARCANGLEDIM;
  protected type = arcDimType.ANG;

  public save() {
    const dimInfo: arcDimensionParam = {
      type: this.type,
      styleId: this.dimStyle.styleId,
      customStyleProp: getCustomDimStyleProperties(this.dimStyle, this.defDimStyle),
      arcBase: this.arcParam,
      InnerArc: this.dimDirectionIsInArc(this.arcParam, this.lastPoint),
      data: this.objDataOrigin[0],
      edgeIndex: this.edgeIndex,
    };
    const command = new ArcAngleDimensionCommand(dimInfo, this.getCurrentSceneId(), this.graphicProcessor);
    if (command) this.graphicProcessor.storeAndExecute(command);
  }
}
export class ArcLongDimensionOP extends ArcDimensionOP {

  public opType = cadOpType.ARCLONGDIM;
  protected type = arcDimType.LONG;

  public save() {
    const dimInfo: arcDimensionParam = {
      type: this.type,
      styleId: this.dimStyle.styleId,
      customStyleProp: getCustomDimStyleProperties(this.dimStyle, this.defDimStyle),
      arcBase: this.arcParam,
      InnerArc: this.dimDirectionIsInArc(this.arcParam, this.lastPoint),
      data: this.objDataOrigin[0],
      edgeIndex: this.edgeIndex,
    };
    const command = new ArcLongDimensionCommand(dimInfo, this.getCurrentSceneId(), this.graphicProcessor);
    if (command) this.graphicProcessor.storeAndExecute(command);
  }
}