import { copyIPoint } from "lib/math/point";
import { IPoint } from "lib/math/types";
import { isBiggerThan, isSmallerEqThan } from "lib/math/epsilon";
import { StructElementBaseOP } from "./structural";
import { cadOpType } from "../factory";
import { settingOp, settingsOpModes } from "../step-operations";
import { dataInfoProperty } from "lib/properties/properties";
import { createColumn, updateColumn } from "lib/geometries/structural/column";
import { heightType, heightTypeNames } from "lib/models-struc/types/struc-base";
import { beamCrossSectionCache, currentBeamCrossSectionId } from "lib/models-struc/cross-sections-shape/beam-cross-sections/cache";
import { storeyAction, StoreyActionType } from "lib/events/storeys";
import { ColumnData } from "lib/models/structural/column";
import { ColumnCommand } from "lib/commands/structural/column";

export interface IColumnSettings {
  crossSectionId: string;
  heightType: heightType;
  height: number;
  basePoint: IPoint,
  rotation: IPoint,
  scale: IPoint,
  offset: IPoint,
}

export class ColumnOP extends StructElementBaseOP {

  public opType = cadOpType.COLUMN;
  private crossSectionId = currentBeamCrossSectionId;
  private heightType: heightType = heightType.STOREY;
  private height: number = 0.2;

  private auxColumns: ColumnData[] = [];
  private columnParams: IColumnSettings[] = [];

  protected iniSettingsOp() {
    this.height = this.currentStorey.height;
    this.settingsOpManager.setCfg([{
      infoMsg: "Insert column: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
      panelProp: this.setPanelProperties(),
      startStepCallback: (step: settingOp) => {
        step.panelProp = this.setPanelProperties();
      },
    }]);
  }

  private setPanelProperties() {
    const beamCS = beamCrossSectionCache.getAllLoadedStyles().sort((a, b) => a.name.localeCompare(b.name));
    const beamCrossSectionListNames: [string, string][] = beamCS.map(s => [s.name, s.styleId]);
    const panelProp: dataInfoProperty<IColumnSettings> = {};
    panelProp.crossSectionId = {
      publicName: "Beam cross section",
      value: this.crossSectionId,
      editable: true,
      type: "tagList",
      tagList: beamCrossSectionListNames,
    };
    panelProp.heightType = {
      publicName: "Height type",
      value: this.heightType,
      editable: true,
      type: "list",
      list: heightTypeNames
    };
    panelProp.height = {
      publicName: this.heightType === heightType.LEVEL ? "Level" : "Height",
      value: this.height,
      editable: this.heightType === heightType.STOREY ? false : true,
      precision: 3,
      type: "number",
      units: "m",
    };
    return {
      propValue: panelProp,
      propCallback: this.updatePanel.bind(this),
    };
  }

  public updatePanel(newColumnProp: IColumnSettings) {
    if (newColumnProp.crossSectionId !== undefined) {
      this.crossSectionId = newColumnProp.crossSectionId;
    }

    this.heightType = newColumnProp.heightType;
    if (this.heightType === heightType.STOREY) {
      this.height = this.getHeightFromStorey();
    } else {
      this.height = newColumnProp.height !== undefined ? newColumnProp.height : this.currentStorey.height;
    }

    this.updateAuxColumn();

    this.settingsOpManager.currCfg = {
      ...this.settingsOpManager.currCfg,
      panelProp: this.setPanelProperties(),
    }
    this.settingsOpManager.dispatchUpdateCurrStep();
  }

  public async start() {
    await super.start();
    this.auxMesh = createColumn(this.crossSectionId, this.height);
    this.saveToTempScene(this.auxMesh);
    const c = this.graphicProcessor.getRaycaster();
    c.setObject2Raycast(this.auxColumns);
  }
  protected storeyListener = (action: storeyAction) => {
    if (action.type === StoreyActionType.SETCURRENTSTOREY) {
      this.currentStorey = action.payload.storey;
      if (this.heightType === heightType.STOREY) {
        this.height = this.currentStorey.height;

        this.updateAuxColumn();
        this.settingsOpManager.currCfg = {
          ...this.settingsOpManager.currCfg,
          panelProp: this.setPanelProperties(),
        }
        this.settingsOpManager.dispatchUpdateCurrStep();
      }
    }
  }

  public setLastPoint(): void {
    this.basePoint = copyIPoint(this.lastPoint);
    const colSetting = this.addColumnSettings();
    this.columnParams.push(colSetting);
  }

  public moveLastPoint(pto: IPoint) {
    this.basePoint = copyIPoint(pto);
    this.updateAuxColumn();
  }

  public updateAuxColumn() {
    const startLevel = this.basePoint.z;
    let currHeight: number = 0;
    switch (this.heightType) {
      case heightType.STOREY: currHeight = this.getHeightFromStorey(); break;
      case heightType.LEVEL: currHeight = startLevel - this.height; break;
      case heightType.LENGTH: currHeight = this.height; break;
      default: break;
    }
    updateColumn(this.auxMesh, this.crossSectionId, currHeight);
    this.auxMesh.position.set(this.basePoint.x, this.basePoint.y, this.basePoint.z);
    this.auxMesh.rotation.set(this.rotation.x, this.rotation.y, this.rotation.z);
  }

  private getHeightFromStorey() {
    const bottomLevel = this.currentStorey.level - this.currentStorey.height;
    const topLevel = this.currentStorey.level;
    const z = this.basePoint.z;
    if (isBiggerThan(z, bottomLevel) && isSmallerEqThan(z, topLevel)) {
      return z - (this.currentStorey.level - this.currentStorey.height);
    } else {
      const strucMng = this.graphicProcessor.getStructuralModelManager();
      const storey = strucMng.currBuilding.getStoreyFromLevel(z);
      return z - (storey.level - storey.height);
    }
  }

  private addColumnSettings() {
    const startLevel = this.basePoint.z;
    let currHeight: number = 0;
    switch (this.heightType) {
      case heightType.STOREY: currHeight = this.getHeightFromStorey(); break;
      case heightType.LEVEL: currHeight = startLevel - this.height; break;
      case heightType.LENGTH: currHeight = this.height; break;
      default: break;
    }
    const colParam: IColumnSettings = {
      crossSectionId: this.crossSectionId,

      heightType: this.heightType,
      height: currHeight,

      basePoint: { ...this.basePoint },
      rotation: { ...this.rotation },
      offset: { ...this.offSet },
      scale: { ...this.scale },
    }
    this.drawColumn(colParam);
    return colParam;
  }

  private drawColumn(colParam: IColumnSettings) {
    const col = new ColumnData({
      name: "_", lnkObjIds: [], materialType: "", storeyId: "",

      heightType: colParam.heightType,
      height: colParam.height,
      orientation: 0,
      crossSectionId: colParam.crossSectionId,

      basePoint: { ...this.basePoint },
      rotation: { ...this.rotation },
      offset: { ...this.offSet },
      scale: { ...this.scale },
    })
    col.createGraphicObj();
    this.setAuxObj(col.graphicObj)
    this.saveToTempScene(col.graphicObj);
    this.auxColumns.push(col);
  }

  public save(): void {
    const command = new ColumnCommand(this.columnParams, this.graphicProcessor);
    this.graphicProcessor.storeAndExecute(command);
  }

  public cancelOperation(): void {
    if (!this.finished) {
      super.endOperation();
    }
    const c = this.graphicProcessor.getRaycaster();
    c.setObject2Raycast([]);
  }
}