import { client } from "shared/utils/rest-client";
import { Project } from "../models/project";

const apiUrl = process.env.REACT_APP_BACKEND_URL;
const apiKey = process.env.REACT_APP_BACKEND_API_KEY;

function estimate(project: Project) {
  return client.post(`${apiUrl}/measurements/estimate`, project, {
    headers: {
      Authorization: apiKey,
    },
  });
}

export const measurementService = {
  estimate,
};
