import { useEffect, useReducer } from "react";
import { UndoRedoAction, UndoRedoActionType } from "lib/events/undo-redo";
import { ICommand } from "lib/commands/base";
import { cadOpType } from '../../../../../lib/operations/factory';
import { GraphicProcessor } from "lib/graphic-processor";

interface UndoRedoState {
  undoCommands: ICommand[];
  redoCommands: ICommand[];
  currentCommandIndex: number;
}
const initialState: UndoRedoState = {
  undoCommands: [] as ICommand[],
  redoCommands: [] as ICommand[],
  currentCommandIndex: 0,
};
function reducer(state: UndoRedoState, action: UndoRedoAction): UndoRedoState {
  switch (action.type) {
    case UndoRedoActionType.SET_COMMANDS:
      const currentCommandIndex = action.payload.currentCommandIndex;
      const undoCommands = action.payload.commands.slice(0, currentCommandIndex).reverse();
      const redoCommands = action.payload.commands.slice(currentCommandIndex);
      return {
        redoCommands,
        undoCommands,
        currentCommandIndex: action.payload.currentCommandIndex,
      };
    default:
      throw new Error(`Action is not defined.`);
  }
}

export default function useUndoRedo(graphicProc: GraphicProcessor) {

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    graphicProc.getCommandInvoker().subscribe(dispatch);
    return () => graphicProc.getCommandInvoker().unsubscribe(dispatch);
  }, [graphicProc]);

  const undo = (index: number = 0) => {
    const operation = graphicProc.currentOp;
    if (operation) {
      if (operation?.opType === cadOpType.SELECT) {
        graphicProc.undo(index + 1);
      } else {
        operation?.undo();
      }
    }
  };
  const redo = (index: number = 0) => {
    const operation = graphicProc.currentOp;
    if (operation) {
      if (operation?.opType === cadOpType.SELECT) {
        graphicProc.redo(index + 1);
      } else {
        operation?.redo();
      }
    }
  };

  return {
    undoCommands: state.undoCommands,
    redoCommands: state.redoCommands,
    undo,
    redo,
  };
}
