import { FC, useCallback, useState } from "react";
import GridDisplayIcon from "../icons/grid-display";
import ToolbarButton from "../toolbar/toolbar-button";
import { GraphicProcessor } from '../../../../lib/graphic-processor';


const GridDisplayButton: FC<{ graphicProc: GraphicProcessor }> = ({ graphicProc }) => {
  const initialState = graphicProc.scuGrid?.activateInfinitumGridHelper() ?? true;
  const [isActive, setIsActive] = useState(initialState);
  const toggleIsActive = useCallback(() => {
    graphicProc.scuGrid.activateInfinitumGridHelper(!isActive);
    setIsActive((current) => !current);
  }, [graphicProc.scuGrid, isActive]);
  return (
    <ToolbarButton
      onClick={toggleIsActive}
      tooltip="Grid Display"
      isActive={isActive}
    >
      <GridDisplayIcon />
    </ToolbarButton>
  );
};

export default GridDisplayButton;
