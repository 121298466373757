import * as THREE from "three";
import { IPoint } from "lib/math/types";
import { IObjData } from "lib/models/objdata";
import { IOpFun } from "lib/operations/edition/multiop";
import { SelectionManager } from "../selection-manager";
import { GraphicProcessor } from "lib/graphic-processor";

export interface markBulkTypes {
  origin?: IPoint[],
  vertex?: IPoint[],
  center?: IPoint[],
  centroid?: IPoint[],
  line?: Float32Array[],
}

export interface IObjDataSelector {
  data: IObjData;

  buildSelectMarks(): markBulkTypes;
  EDGE?: THREE.Mesh[];
  BBOX?: THREE.BoxHelper;

  getMarkOP(selectionManager: SelectionManager, mark: THREE.Object3D, index?: number): IOpFun | void;
  getDataFromBufferIndex(index: number): { data: IObjDataSelector; index: number }
}

export abstract class ObjDataSelector<T extends IObjData> implements IObjDataSelector {

  protected graphicProc: GraphicProcessor;
  public data: T;
  abstract buildSelectMarks(): markBulkTypes;
  protected abstract launchMarkOP(selectionManager: SelectionManager, mark: THREE.Object3D, index?: number): IOpFun | void;

  public EDGE?: THREE.Mesh[];
  public BBOX?: THREE.BoxHelper;

  constructor(data: T, graphicProc: GraphicProcessor) {
    this.data = data;
    this.graphicProc = graphicProc;
  }

  getMarkOP(selectionManager: SelectionManager, mark: THREE.Object3D, index?: number) {
    return this.launchMarkOP(selectionManager, mark, index);
  }

  getDataFromBufferIndex(index: number) {
    return { data: this, index };
  }


}
