import { LayerData } from "lib/layers/layer-data";
import { IObjData } from "lib/models/objdata";
import { useState } from "react";
import LightIcon from "shared/components/icons/light";
import LockIcon from "shared/components/icons/lock";
import { NodeData } from "shared/components/ui/tree/tree";
import { useLayerContext } from "./context";

interface iNodeProps {
  nodeData: NodeData<LayerData | IObjData>;
}

export const TreeNodeOptions = ({ nodeData }: iNodeProps) => {

  const [locked, setLocked] = useState<boolean>("locked" in nodeData.data! ? nodeData.data!.locked : nodeData.data!.isLocked);
  const [visible, setVisible] = useState<boolean>("visible" in nodeData.data! ? nodeData.data!.visible : nodeData.data!.isDataVisible);

  const { toggleLayerLock, toggleVisibility } = useLayerContext();

  return (
    <div className="flex items-center justify-end space-x-1">
      <LockIcon
        className={`cursor-pointer w-4 h-4 hover:text-gray-100 ${locked ? "text-white" : "text-gray-500"}`}
        onClick={() => {
          setLocked(!locked);
          toggleLayerLock(nodeData, !locked);
        }}
      />
      <LightIcon
        className={`cursor-pointer w-4 h-4 hover:text-gray-100 ${visible ? "text-yellow-200" : "text-gray-400 "}`}
        onClick={() => {
          setVisible(!visible);
          toggleVisibility(nodeData, !visible);
        }}
      />
    </div>
  );
};
