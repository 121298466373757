import { IPoint } from "lib/math/types";
import { growthWidth, storeyMaterialType } from './struc-base';
import { addIpoint, copyIPoint, normalizeIpoint, substractIpoint } from "lib/math/point";
import { normalizeAngle } from "lib/math/angles";
import { rotatePoint, getRotationVector } from "lib/math/rotate";
import { scalePoint } from "lib/math/scale";
import { mirrorPoint } from "lib/math/mirror";
import { solidParam } from "lib/models/solids/solid";
import { currentBeamCrossSectionId } from "../cross-sections-shape/beam-cross-sections/cache";

export interface beamParam extends solidParam {
  storeyId: string;
  name: string;
  lnkObjIds: string[];
  materialType: storeyMaterialType;

  orientation: number,
  points: IPoint[];
  crossSectionId: string;
  growthType: growthWidth;
}

export function cloneBeamParam(param: beamParam): beamParam {
  return {
    name: param.name,
    storeyId: param.storeyId,
    lnkObjIds: param.lnkObjIds ? param.lnkObjIds.slice() : [],
    materialType: param.materialType,

    orientation: param.orientation ?? 0,
    points: param.points.map(copyIPoint),
    crossSectionId: param.crossSectionId ?? currentBeamCrossSectionId,
    growthType: param.growthType,
    basePoint: copyIPoint(param.basePoint),
    rotation: copyIPoint(param.rotation),
    offset: copyIPoint(param.offset),
    scale: copyIPoint(param.scale),
  };
}

export function translateBeamParam(repr: beamParam, distance: IPoint) {
  repr.basePoint = addIpoint(repr.basePoint, distance);
}
export function rotateBeamParam(repr: beamParam, angleX: number, angleY: number, angleZ: number, basePoint: IPoint) {
  angleX = normalizeAngle(angleX);
  angleY = normalizeAngle(angleY);
  angleZ = normalizeAngle(angleZ);
  const p1 = addIpoint(repr.basePoint, repr.points[1]);
  repr.basePoint = rotatePoint(repr.basePoint, angleX, angleY, angleZ, basePoint);
  const p1Rot = rotatePoint(p1, angleX, angleY, angleZ, basePoint);
  repr.points[1] = substractIpoint(p1Rot, repr.basePoint);
  const beamVect = normalizeIpoint(repr.points[1]);
  const rot0 = getRotationVector(beamVect);
  repr.rotation = { x: rot0.x, y: rot0.y, z: rot0.z };
}
export function scaleBeamParam(repr: beamParam, factorX: number, factorY: number, factorZ: number, basePoint: IPoint) {
  repr.basePoint = scalePoint(repr.basePoint, factorX, factorY, factorZ, basePoint);
}
export function mirrorBeamParam(repr: beamParam, startPoint: IPoint, endPoint: IPoint) {
  const p1 = addIpoint(repr.basePoint, repr.points[1]);
  repr.basePoint = mirrorPoint(repr.basePoint, startPoint, endPoint);
  const p1Mirr = mirrorPoint(p1, startPoint, endPoint);
  repr.points[1] = substractIpoint(p1Mirr, repr.basePoint);
  repr.orientation = normalizeAngle(-repr.orientation);
  const beamVect = normalizeIpoint(repr.points[1]);
  const rot0 = getRotationVector(beamVect);
  repr.rotation = { x: rot0.x, y: rot0.y, z: rot0.z };
}
