import { PrimaryButton, SecondaryButton } from "shared/components/ui/button";
import Input from "shared/components/ui/input";
import { useRenameProject } from "./hooks/use-rename-project";

const ProjectDeleteModal = () => {
  const { confirmAction, cancelAction, errors, register } = useRenameProject();
  return (
    <form onSubmit={confirmAction}>
      <div className="space-y-6">
        <div>
          <h1 className="text-lg leading-6 font-medium text-gray-400">
            Rename file
          </h1>
        </div>
        <Input
          id="name"
          name="name"
          ref={register({
            required: true,
          })}
          error={errors.name?.type}
        />
        <div className="flex justify-end space-x-4">
          <SecondaryButton onClick={cancelAction}>Cancel</SecondaryButton>
          <PrimaryButton type="submit">Confirm</PrimaryButton>
        </div>
      </div>
    </form>
  );
};

export default ProjectDeleteModal;
