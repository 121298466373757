import { Link } from "react-router-dom";
import styled from "styled-components";
import { getTimeAgo } from "shared/utils/dates";
import DotsVerticalIcon from "shared/components/icons/dots-vertical";
import { useUI } from "shared/components/ui/context";
import Menu from "shared/components/ui/menu";
import Logo from "shared/components/icons/logo";

const ProjectLink = styled(Link)`
  margin: 0;
  display: flex;
  border-radius: 8px;
  height: 232px;
  position: relative;
  flex: 1 1 280px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Snapshot = styled.div<{ url: string | null }>`
  background: ${(props) => (props.url ? `url(${props.url})` : "")};
  flex: 1;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

interface Props {
  version: string;
  id: string;
  name: string;
  updatedAt: string;
  snapshot: string | null | undefined;
  projectsNameList: string[];
}

const ProjectItemLink = ({ id, version, name, updatedAt, snapshot, projectsNameList }: Props) => {

  const { openModal } = useUI();
  return (
    <ProjectLink
      key={id}
      className="border border-gray-700 rounded-md"
      to={`/struc/projects/${id}`}
    >
      {snapshot ? <Snapshot className="bg-gray-700" url={snapshot} /> : <Logo className="p-5" />}

      <div className="flex justify-between items-center text-sm px-6 py-3 transition-all duration-150 space-x-3">
        <div>
          <h3>{name}</h3>
          <div className="flex flex-1 justify-end">
            <span>Updated {getTimeAgo(updatedAt)}</span>
          </div>
        </div>
        <Menu>
          <Menu.Button>
            <DotsVerticalIcon />
          </Menu.Button>
          <Menu.List>
            <Menu.Link to={`/struc/projects/${id}/edit`}>
              Edit
            </Menu.Link>
            <Menu.Item
              onSelect={() => openModal("CLONE_STRUC_PROJECT", { id, version, projectsNameList })}
              onClick={(event: any) => event.stopPropagation()}>
              Clone
            </Menu.Item>
            <Menu.Item
              onSelect={() => openModal("DELETE_STRUC_PROJECT", { id, version })}
              onClick={(event: any) => event.stopPropagation()}>
              Delete
            </Menu.Item>
          </Menu.List>
        </Menu>
      </div>
    </ProjectLink>
  );
};

export default ProjectItemLink;
