import { userMessageEvents } from "lib/events/user-messages";
import { hypothesisDispatcher } from "lib/models-struc/hypothesis/dispatcher";
import { getHypothesisInfoprop, Hypothesis } from "lib/models-struc/hypothesis/hypothesis";
import { dataInfoProperty } from "lib/properties/properties";
import { useMemo, useState, useEffect } from "react";
import { useUI } from "shared/components/ui/context";
import { useStrucProjectContext } from "../../context";

export function useAddHypothesis() {

  const { closeModal } = useUI();

  const { hypothesisManager } = useStrucProjectContext();

  const hypothesis = useMemo(() => hypothesisManager.getAllPredefinedHypothesis(), [hypothesisManager]);

  const [currHypothesisName, setCurrHypothesisName] = useState<string>(hypothesis[0].name)
  const [hypothesIsEditable, setHypothesIsEditable] = useState<boolean>(false);
  const [editableHypothesis, setEditableHypothesis] = useState<Hypothesis>(hypothesis[0]);
  const [hypothesisProperties, setHypothesisProperties] = useState<dataInfoProperty<Hypothesis>>(getHypothesisInfoprop(hypothesis[0], false));

  useEffect(() => {
    const h = hypothesis.find((h) => h.name === currHypothesisName);
    if (h) {
      const hypoProp = { ...h };
      hypoProp.name = hypothesIsEditable ? hypoProp.name + "_custom" : hypoProp.name;
      setHypothesisProperties(getHypothesisInfoprop(hypoProp, hypothesIsEditable));
      setEditableHypothesis(hypoProp);
    }
  }, [currHypothesisName, hypothesis, hypothesIsEditable])

  const editHypothesisProperties = (newVal: Hypothesis) => {
    const hypoProp = { ...newVal };
    setHypothesisProperties(getHypothesisInfoprop(hypoProp, hypothesIsEditable));
    setEditableHypothesis(hypoProp);
  }

  const addHypothesisSet = () => {
    if (editableHypothesis) {
      if (hypothesisManager.existHypothesis(editableHypothesis)) {
        userMessageEvents.dispatchError("Hypothesis already added to hypothesis set")
      } else {
        hypothesisManager.addHypothesis(editableHypothesis);
        hypothesisDispatcher.dispatchAdd(editableHypothesis);
        closeModal();
      }
    }
  }

  return {
    hypothesis,
    hypothesisProperties,
    editHypothesisProperties,
    hypothesIsEditable,
    setHypothesIsEditable,
    setCurrHypothesisName,
    currHypothesisName,
    addHypothesisSet,
  };
}
