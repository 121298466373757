import { storeyAction, StoreyActionType, storeysEvents } from "lib/events/storeys";
import { IPoint } from "lib/math/types";
import { Storey } from "lib/models-struc/struc-storey";
import { MultiEdition } from "../edition-multi";

export abstract class StructBaseOP extends MultiEdition {

  protected currentStorey: Storey;

  protected basePoint: IPoint = { x: 0, y: 0, z: 0 };
  protected rotation: IPoint = { x: 0, y: 0, z: 0 };

  public async start() {
    const strucMng = this.graphicProcessor.getStructuralModelManager();
    this.currentStorey = strucMng.currStorey;
    storeysEvents.subscribe(this.storeyListener);

    this.iniSettingsOp();
    this.initializeSnap();
    this.initializeWorkingPlane();
    this.registerCancel();
    this.registerInputs();
    this.registerUpdaters();
  }

  protected storeyListener = (action: storeyAction) => {
    if (action.type === StoreyActionType.SETCURRENTSTOREY) {
      this.currentStorey = action.payload.storey;
    }
  }

  public endOperation(): void {
    if (!this.finished) {
      this.save();
      storeysEvents.unsubscribe(this.storeyListener);
      super.endOperation();
    }
  }
}

export abstract class StructElementBaseOP extends StructBaseOP {

  protected auxMesh: THREE.Mesh;

  protected offSet: IPoint = { x: 0, y: 0, z: 0 };
  protected scale: IPoint = { x: 1, y: 1, z: 1 };
}