import { ChangeEvent, ChangeEventHandler, forwardRef, InputHTMLAttributes } from "react";
import { useDebounceCallback } from "shared/utils/hooks/use-debounce";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  defaultValue: string
  onChange: ChangeEventHandler<HTMLInputElement>;
}
const ColorSelector = forwardRef<HTMLInputElement, Props>((props, ref) => {

  const { id, defaultValue, onChange, ...rest } = props;

  const handleOnChange = useDebounceCallback((event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (onChange) {
      onChange(event);
    }
  }, 100);

  return (
    <div className="w-full border border-gray-600 rounded-sm">
      <input className="w-full rounded-sm"
        style={{ backgroundColor: "inherit", }}
        type="color"
        ref={ref}
        defaultValue={defaultValue}
        onChange={handleOnChange}
        {...rest}
      >
      </input>
    </div>
  )
});

export default ColorSelector