import * as THREE from "three";
import { GraphicProcessor } from "lib/graphic-processor";
import { IObjData } from "lib/models/objdata";

export type rayCastResults = THREE.Intersection & { dataObject: IObjData }

const defaultRaycastThresholdValue = 5;

export class RaycasterModel {

  private graphicProcessor: GraphicProcessor;
  private raycaster: THREE.Raycaster;
  get ray() { return this.raycaster.ray }

  private objsIntersections: rayCastResults[] = [];
  get raycastedObject() { return this.objsIntersections }

  private layers2Raycast: string[] = [];
  private object2Raycast: IObjData[] = [];

  constructor(graphicProc: GraphicProcessor) {
    this.graphicProcessor = graphicProc;
    // Default raycaster to obtain objects in scene.
    this.raycaster = new THREE.Raycaster();
    this.setRaycasterThreshold(defaultRaycastThresholdValue);
  }
  setRaycasterThreshold(unit: number): void {
    this.raycaster.params = {
      Mesh: {},
      Line: { threshold: unit },
      LOD: {},
      Points: { threshold: unit },
      Sprite: {},
    };
  }
  clearRaycaster() {
    this.objsIntersections.length = 0;
    this.layers2Raycast.length = 0;
    this.object2Raycast.length = 0;
    this.graphicProcessor = undefined!;
  }

  /** set struc layers according to active viewport
   * Used by selection box and zomm extents
   *
   * @param {string[]} layerIds
   * @memberof RaycasterModel
   */
  setStrucLayer2Raycast(layerIds: string[]): void {
    const viewPort = this.graphicProcessor.getActiveViewport();
    const cursorCamera = viewPort.camera as THREE.Camera;
    if (cursorCamera.type === "PerspectiveCamera") {
      this.layers2Raycast = [];
    } else {
      this.layers2Raycast = layerIds;
    }
  }
  getStrucLayer2Raycast(): string[] {
    return this.layers2Raycast;
    // get struc layers according to active viewport to iterate model data
    // const layers: string[] = [];
    // const viewPort = this.graphicProcessor.getActiveViewport();
    // const cursorCamera = viewPort.camera as THREE.Camera;
    // if (cursorCamera.type !== "PerspectiveCamera") {
    //   const strucModelMng = this.graphicProcessor.getStructuralModelManager();
    //   layers.push(...strucModelMng.currStoreyLayers);
    // }
    // return layers;
  }
  /** Aditionally object included to raycast 
   *
   * @param {IObjData[]} objs
   * @memberof RaycasterModel
   */
  setObject2Raycast(objs: IObjData[]): void {
    this.object2Raycast = objs;
  }
  /** Model raycast 
   *
   * @param {THREE.Vector2} mousePos
   * @param {THREE.Camera} camera
   * @memberof RaycasterModel
   */
  intersectObjects(mousePos: THREE.Vector2, camera: THREE.Camera) {
    this.raycaster.setFromCamera(mousePos, camera);
    // this.threeRaycast();
    this.ownModelRaycast();
    // console.log("Raycast result: " + this.objsIntersections.length);
  }

  private ownModelRaycast() {
    this.objsIntersections = [];
    const dataModel = this.graphicProcessor.getDataModelManager();
    dataModel.iterAllDataFromLayers(this.layers2Raycast, (data) => {
      if (data.isVisible && !data.isLocked) {
        const inters = this.raycaster.intersectObject(data.graphicObj, true);
        if (inters.length) {
          this.objsIntersections.push({ ...inters[0], dataObject: data });
        }
      }
    });
    for (const data of this.object2Raycast) {
      const inters = this.raycaster.intersectObject(data.graphicObj, true);
      if (inters.length) {
        this.objsIntersections.push({ ...inters[0], dataObject: data });
      }
    }
    // Order raycast result by distance
    this.objsIntersections = this.objsIntersections.sort((a, b) => a.distance - b.distance);
  }
  private threeRaycast() {
    const scene = this.getScene2Raycast();
    const dataModel = this.graphicProcessor.getDataModelManager();
    const intersects = this.raycaster.intersectObjects(scene, true);
    this.objsIntersections = [];
    for (const i of intersects) {
      const res = dataModel.getDataFromGraphic(i);
      if (res && res.dataObject.isVisible) {
        this.objsIntersections.push(res);
      } else {
        const resObj = this.object2Raycast.find(o => o.graphicObj === i.object);
        if (resObj)
          this.objsIntersections.push({ ...i, dataObject: resObj });
      }
    }
  }
  private getScene2Raycast(): THREE.Object3D[] {
    const layerManager = this.graphicProcessor.getLayerManager();
    let objs: THREE.Object3D[] = []
    if (this.layers2Raycast?.length) {
      objs = this.layers2Raycast.map(id => {
        const layer = layerManager.getLayerDataFromId(id)!;
        return layer.threeObject;
      });
    } else {
      objs = [layerManager.getRootLayer().threeObject];
    }

    if (this.object2Raycast?.length) {
      objs.push(...this.object2Raycast.map(o => o.graphicObj));
    }
    return objs;
  }

  /** Custom raycast to three object
   *
   * @param {THREE.Object3D} objects
   * @param {boolean} [recursive=true]
   * @return {*}  {THREE.Intersection[]}
   * @memberof RaycasterModel
   */
  raycastObjects(objects: THREE.Object3D, recursive: boolean = true): THREE.Intersection[] {
    return this.raycaster.intersectObjects(objects.children, recursive);
  }
}