import { Building, Ecore, Root } from "./base";
import { Geometry } from "./geometry";
import { GeoRepresentation } from "./representation";
import { Vector } from "./location";

export interface IAnalysisProject extends Ecore {
  analysisversion: IAnalysisVersion[];
}

export interface IAnalysisVersion extends Ecore {
  analysis: CodeAnalysis[],
  hypothesisset: IHypothesisSet;
  meshproject: MeshProjectRef;
}
interface Analysis extends Ecore {
  //boundarycondition: BoundaryCondition[];
}
export interface CodeAnalysis extends Analysis {
  hypothesis: IHypothesisRef[];
}
interface MeshProjectRef extends Ecore {
  $ref: string;
}
export interface IHypothesisSet extends Ecore {
  hypothesis: IHypothesis[];
}
export interface IHypothesis extends Ecore {
  construction_phase?: number;
  fav?: number;
  loads: ILoad[];
  mass_ratio?: number;
  name?: string;
  psi_0?: number;
  psi_1?: number;
  psi_2?: number;
  service?: number;
  tag?: HYPOTHESIS_NAMES;
  type?: HYPOTHESIS_TYPES;
  un_fav?: number;
  unfav_sse?: number;
}
export interface DirSSEHypothesis extends IHypothesis {
  sse_dir?: SSEDirsEcore;
  values: SSERecord[];
}
export interface SSERecord extends Ecore {
  t?: number,
  ag?: number,
}
export interface WindHypothesis extends IHypothesis {
  wind_type?: WindTypes;
}

export interface IHypothesisRef extends Ecore {
  $ref: string;
}

export const enum HYPOTHESIS_NAMES {
  RESIDENTIAL = "RESIDENTIAL",
  OFFICE = "OFFICE",
  PARKING = "PARKING",
  SHOPPING = "SHOPPING",
  ACCESSIBLE_ROOF = "ACCESSIBLE_ROOF",
  NON_ACCESSIBLE_ROOF = "NON_ACCESSIBLE_ROOF",
  SNOW_ABOVE_1000 = "SNOW_ABOVE_1000",
  SNOW_BELOW_1000 = "SNOW_BELOW_1000",
  OTHER = "OTHER",
  DEAD_LOAD = "DEAD_LOAD",
  SELF_WEIGHT = "SELF_WEIGHT",
  WIND = "WIND",
  TEMPERATURE = "TEMPERATURE",
  EARTH_PRESSURE = "EARTH_PRESSURE",
  SSE = "SSE"
}

export const enum HYPOTHESIS_TYPES {
  DL = "DL",
  LL = "LL",
  WIND = "WIND",
  TEMP = "TEMP",
  EARTH = "EARTH",
  SSE = "SSE"
};

export const enum SSEDirsEcore {
  SSx = "SSx",
  SSy = "SSy",
  SSz = "SSz"
};

export const enum WindTypes {
  Wx1Pos = "Wx1Pos",
  Wx1Neg = "Wx1Neg",
  Wx2Pos = "Wx2Pos",
  Wx2Neg = "Wx2Neg",
  Wy1Pos = "Wy1Pos",
  Wy1Neg = "Wy1Neg",
  Wy2Pos = "Wy2Pos",
  Wy2Neg = "Wy2Neg"
};


export interface ILoad extends Ecore {
  externalGeo?: boolean;
  geometry?: GeoRepresentation<Geometry>[];
  value?: number;
  vector?: Vector;
  element?: IFEMStructuralElementRef;
}

export interface IMeshProject extends Root {
  versions: IMeshVersion[];
}

interface IMeshVersion extends Root {
  building: Building;
  femStructuralElements: IFEMStructuralElement[];
}

export interface IFEMStructuralElement extends Ecore {
  structuralelement: IStructuralElementRef;
}

interface IStructuralElementRef extends Ecore {
  $ref: string;
}

export interface IFEMStructuralElementRef extends Ecore {
  $ref: string;
}