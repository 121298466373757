import * as THREE from "three";
import { getDimAuxGeometry, getDimGeometry, getDimLabel } from "lib/dimension/line-dim-builder";
import { lineExtractAllVertex } from "lib/geometries/line";
import { IpointsToBuffer } from "lib/math/point";
import { AlignedDimensionData } from "lib/models/dimension/aligned-dim";
import { objDataType } from "lib/models/types";
import { cadOpType } from "lib/operations/factory";
import { LinealDimensionData } from "lib/models/dimension/lineal-dim";
import { SelectionManager } from "lib/selection/selection-manager";
import { ObjDataSelector } from "../selector-data";

export class AlignedDimensionDataSelector extends ObjDataSelector<AlignedDimensionData | LinealDimensionData> {

  public override buildSelectMarks() {
    /*  Posición de los PUNTOS QUE DEFINEN LA GEOMETRÍA DE LA ACOTACIÓN
        2*             *4
         |             |
   0*___6._____________.7___*1
         |             |
         |             |
         |             |
        3*             *5
        
        8* ----------- *9
    */
    const dimGeom = getDimGeometry(this.data.graphicObj);
    const v = lineExtractAllVertex(dimGeom);
    const buffer0 = IpointsToBuffer([v[2], v[3]]);
    const buffer1 = IpointsToBuffer([v[0], v[1]]);
    const buffer2 = IpointsToBuffer([v[4], v[5]]);
    const lines = [buffer0, buffer1, buffer2];

    const auxGeom = getDimAuxGeometry(this.data.graphicObj);
    if (auxGeom) {
      const bufferAux = auxGeom.geometry as THREE.BufferGeometry;
      const bufferArray = bufferAux.getAttribute("position").array as Float32Array;
      if (bufferArray.length > 3)
        lines.push(bufferArray);
    }
    const textPos = getDimLabel(this.data.graphicObj);

    return {
      vertex: [textPos.position, v[6], v[7], v[8], v[9]],
      line: lines,
    };
  }

  public override launchMarkOP(selectionManager: SelectionManager, mark: THREE.Object3D, vertexIndex: number) {
    if (selectionManager.vertexSelector.pointObj === mark) {
      const type = this.data.type === objDataType.ALIGNEDDIM ? cadOpType.EDITALIGNEDDIM : cadOpType.EDITLINEARDIM;
      return {
        type,
        graphicProcessor: this.graphicProc,
        args: [this.data, vertexIndex],
      };
    }
  }
}
