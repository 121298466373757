import * as THREE from "three";
import { setPosBuffer } from "lib/geometries";
import { pointCreate } from "lib/geometries/point";
import { getCurrentPointMaterial, IPointMaterial, materialCache } from "lib/materials";
import { mirrorPoint } from "lib/math/mirror";
import { copyIPoint, addIpoint } from "lib/math/point";
import { rotatePoint } from "lib/math/rotate";
import { scalePoint } from "lib/math/scale";
import { IPoint } from "lib/math/types";
import { ObjData } from "../objdata";
import { objDataType } from "../types";

export class PointData extends ObjData {

  public type = objDataType.POINT;
  protected nameObj: string = "Point";
  public definition: IPoint;
  public material: IPointMaterial;

  public graphicObj: THREE.Points;

  constructor(definition: IPoint, material?: IPointMaterial) {
    super();
    this.definition = copyIPoint(definition);
    this.material = material ?? getCurrentPointMaterial();
  }
  static createObj(definition: IPoint, material: IPointMaterial) {
    const threeMaterial = materialCache.getPointMaterial(material);
    return pointCreate(
      definition.x,
      definition.y,
      definition.z,
      undefined,
      threeMaterial
    );
  }
  public createGraphicObj() {
    if (this.graphicObj) {
      console.warn("Attention: Point graphic object already created!!");
      return;
    }
    this.graphicObj = PointData.createObj(this.definition, this.material);
    this.graphicObj.renderOrder = 5;
  }
  public cloneDefinition(): IPoint {
    return copyIPoint(this.definition);
  }
  public createObject(definition?: IPoint, material?: IPointMaterial): THREE.Points {
    return PointData.createObj(definition ?? this.definition, material ?? this.material);
  }

  public override regenerateObjectFromDefinition(): void {
    const { x, y, z } = this.definition;
    const coords = new Float32Array([x, y, z]);
    setPosBuffer(this.graphicObj, coords);
    this.regenerateDependences();
  }
  public override regenerateObjectFromMaterial(): void {
    const threeMaterial = materialCache.getPointMaterial(this.material);
    if (threeMaterial) {
      this.graphicObj.material = threeMaterial;
    }
  }

  public translate(distance: IPoint) {
    this.definition = addIpoint(this.definition, distance);
    this.regenerateObjectFromDefinition();
  }
  public rotate(
    angleX: number,
    angleY: number,
    angleZ: number,
    basePoint: IPoint
  ): void {
    this.definition = rotatePoint(
      this.definition,
      angleX,
      angleY,
      angleZ,
      basePoint
    );
    this.regenerateObjectFromDefinition();
  }
  public scale(
    factorX: number,
    factorY: number,
    factorZ: number,
    basePoint: IPoint
  ): void {
    this.definition = scalePoint(
      this.definition,
      factorX,
      factorY,
      factorZ,
      basePoint
    );
    this.regenerateObjectFromDefinition();
  }
  public mirror(startPoint: IPoint, endPoint: IPoint): void {
    this.definition = mirrorPoint(this.definition, startPoint, endPoint);
    this.regenerateObjectFromDefinition();
  }
  public delete(): void {
    this.graphicObj.geometry.dispose();
  }
}
