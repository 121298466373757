import { useState } from 'react'
import { useHistory } from 'react-router'
import { useAuth } from 'shared/components/auth/context'
import { StrucSite, StrucStorey } from '../../../../models/project';
import { Project } from 'lib/apis/struc-project/api'
import { projectListResponse } from 'modules/struc/components/projects/hooks/use-projects';
import { useMutation } from 'react-query';
import queryClient from 'shared/utils/query-client';
import { fetchPutEditProject } from 'lib/models-struc/infrastructure/edit-project';
import { userMessageEvents } from 'lib/events/user-messages';

type Status = 'idle' | 'loading' | 'success' | 'error'

export function useEditProject() {

  const { user } = useAuth();
  const history = useHistory();
  const [status, setStatus] = useState<Status>('idle');

  const mutation = useMutation((p: { userId: string, Id: string, project: Project, site: StrucSite, storeys: StrucStorey[] }) => {
    return fetchPutEditProject(p.userId, p.Id, p.project, p.site, p.storeys);
  }, {
    onSuccess: (data) => {
      const query = queryClient.getQueriesData<projectListResponse[]>(['GET_STRUC_PROJECTS']);
      const q = query[0][1].map(p =>
        p.projectData.Id !== data.Id ? p : { ...p, projectData: { ...p.projectData, ...data }, }
      );
      queryClient.setQueryData(["GET_STRUC_PROJECTS"], q);
    }
  })

  const editProject = async (Id: string, project: Project, site: StrucSite, storeys: StrucStorey[]) => {
    try {
      setStatus('loading');
      const userId = user!.id;
      await mutation.mutateAsync({ userId, Id, project, site, storeys });
      console.info("[ADD_PROJECT] Model built. saving ...")
      userMessageEvents.dispatchSucces('Project created successfully.');
      setStatus('success');
      console.info("[ADD_PROJECT] Project created successfully.");

    } catch (error) {
      userMessageEvents.dispatchError('Error editing project.');
      setStatus('error');

    } finally {
      history.push('/struc/projects')
    }
  }

  return {
    editProject,
    status,
  }
}
