import { storeyAction, StoreyActionType, storeysEvents } from "lib/events/storeys";
import { StructuralModelManager } from "lib/models-struc/model-managers/structuralmodel-manager";
import { Storey } from "lib/models-struc/struc-storey";
import { ChangeEvent, useEffect, useReducer } from "react";


function reducer(state: storeyState, action: storeyAction): storeyState {
  switch (action.type) {
    case StoreyActionType.LOADSTOREYS:
      return {
        storeys: action.payload.storeys,
        currStorey: action.payload.storey,
      };
    case StoreyActionType.SETCURRENTSTOREY:
      return {
        ...state,
        currStorey: action.payload.storey,
      };
    default:
      return state;
  }
}
interface storeyState {
  currStorey: Storey;
  storeys: Storey[];
}
const initialState: storeyState = {
  currStorey: new Storey(),
  storeys: [],
};

export function useStoreys(strucMng: StructuralModelManager) {

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    storeysEvents.subscribe(dispatch);
    return () => storeysEvents.unsubscribe(dispatch);
  }, []);

  const go2Storey = (event: ChangeEvent<HTMLSelectElement>) => {
    const newStoreyId = event.target.value;
    const indx = state.storeys.findIndex(s => s.id === newStoreyId);
    const newStorey = state.storeys[indx];
    if (newStorey) {
      strucMng.setCurrStorey(newStorey);
    }
  }
  const go2nextStorey = () => {
    const indx = state.storeys.findIndex(s => s.id === state.currStorey.id);
    const newStorey = state.storeys[indx + 1];
    if (newStorey) {
      strucMng.setCurrStorey(newStorey);
    }
  }
  const go2prevStorey = () => {
    const indx = state.storeys.findIndex(s => s.id === state.currStorey.id);
    const newStorey = state.storeys[indx - 1];
    if (newStorey) {
      strucMng.setCurrStorey(newStorey);
    }
  }

  const changeStoreyLevel = (newLevel: number) => {
    const indx = state.storeys.indexOf(state.currStorey);
    const prevStorey = state.storeys[indx - 1];
    const currStorey = state.currStorey
    const nextStorey = state.storeys[indx + 1];

    if (prevStorey && prevStorey.level >= newLevel) {
      throw new Error(`Storey level must be bigger than previous storey level ${prevStorey.level} m`);
    }
    if (nextStorey && nextStorey.level <= newLevel) {
      throw new Error(`Storey level must be lower than next storey level ${nextStorey.level} m`);
    }

    const oldLevel = currStorey.level;
    currStorey.level = newLevel;
    currStorey.height = prevStorey ? newLevel - prevStorey.level : newLevel;
    currStorey.regenerateElements();

    if (nextStorey) {
      nextStorey.height = nextStorey.level - newLevel;
      nextStorey.regenerateElements();
    }

    storeysEvents.dispatch({
      type: StoreyActionType.UPDATE_LEVEL,
      payload: {
        storey: state.currStorey,
        oldLevel
      }
    });
  }

  return {
    currStorey: state.currStorey,
    storeyList: state.storeys,
    go2Storey,
    go2nextStorey,
    go2prevStorey,
    changeStoreyLevel,
  }
}