import { gql, useQuery } from "@apollo/client";
import { useAuth } from "shared/components/auth/context";
import { User } from "shared/models/user";

export const GET_ACCOUNT = gql`
  query getAccount {
    user: user_by_pk(id: $id) {
      id
      username
      email
      defaultStart
    }
  }
`;

interface QueryResult {
  user: User;
}

function useAccount() {
  const { user } = useAuth();
  const { data, loading, error } = useQuery<QueryResult>(GET_ACCOUNT, {
    variables: { id: user?.id },
  });
  return {
    user: data?.user,
    loading,
    error,
  };
}

export default useAccount;
