import { FC, FormEvent } from "react";

interface Props {
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

interface Composition {
  Title: FC;
  Actions: FC;
}
const Form: FC<Props> & Composition = ({ children, onSubmit }) => {
  return (
    <form onSubmit={onSubmit}>
      <div className="space-y-4">{children}</div>
    </form>
  );
};

const Title: FC = ({ children }) => {
  return (
    <div>
      <h1 className="text-lg leading-6 font-medium text-gray-400">
        {children}
      </h1>
    </div>
  );
};

const Actions: FC = ({ children }) => {
  return <div className="flex space-x-4 pt-2 justify-end">{children}</div>;
};

Form.Title = Title;
Form.Actions = Actions;

export default Form;
