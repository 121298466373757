import { isBetweenAzimuts, lineAzimut2p, ORIENT } from "./angles";
import { substractIpoint, getFactorLinePosition2p, getAzimutPolarPoint, copyIPoint } from "./point";
import { IPoint } from "./types";

export function distance3d(x1: number, y1: number, z1: number, x2: number, y2: number, z2: number) {
  return Math.sqrt(
    (x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1) + (z2 - z1) * (z2 - z1)
  );
}
export function distance2d(x1: number, y1: number, x2: number, y2: number) {
  return Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
}
export function vectorDist3D(p1: IPoint, p2: IPoint) {
  return distance3d(p1.x, p1.y, p1.z, p2.x, p2.y, p2.z);
}
export function vectorDist2D(p1: IPoint, p2: IPoint) {
  return distance2d(p1.x, p1.y, p2.x, p2.y);
}

/** Minimum Distance between a Point and a Line
 * http://paulbourke.net/geometry/pointlineplane/
 *
 *               x = x1 + u (x2 - x1)
 *               y = y1 + u (y2 - y1)
 *
 *         (px - x1)(x2 - x1) + (py - y1)(y2 - y1)
 *    u = -----------------------------------------
 *              ||(x2, y2) - (x1, y1)|| ^ 2
 *
 * @param {any} px - coordenada X de P
 * @param {any} py - coordenada Y de P
 * @param {any} x1 - coordenada X del punto inicio de la línea
 * @param {any} y1 - coordenada Y del punto inicio de la línea
 * @param {any} x2 - coordenada X del punto final de la línea
 * @param {any} y2 - coordenada Y del punto final de la línea
 * @param {any} strict - controla si se considerar la recta infinita o no. strict = false --> recta infinita
 * @returns punto de la línea más cercano a P y posición de P
 */
export function pointToLine2D(px: number, py: number, x1: number, y1: number, x2: number, y2: number, strict: boolean = false): { x: number; y: number; u: number } {
  let x, y;
  const u =
    ((px - x1) * (x2 - x1) + (py - y1) * (y2 - y1)) /
    ((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
  if (strict) {
    if (u < 0) return { x: x1, y: y1, u: 0 };
    if (u > 1) return { x: x2, y: y2, u: 1 };
  }
  x = x1 + u * (x2 - x1);
  y = y1 + u * (y2 - y1);
  return { x, y, u };
}
export function pointOnLine3D(lineA: IPoint, lineB: IPoint, P: IPoint, strict: boolean = false): [IPoint, number | null] {
  if (undefined === strict) strict = false;

  // Vector director de la recta
  const u = substractIpoint(lineB, lineA);

  // Calculo del plano que pasa por P y es perpendicular a la recta AB
  const coefA = u.x;
  const coefB = u.y;
  const coefC = u.z;
  const coefD = -coefA * P.x - coefB * P.y - coefC * P.z;

  // Intersección de recta con el plano calculado
  const t =
    -(coefA * lineA.x + coefB * lineA.y + coefC * lineA.z + coefD) /
    (coefA * u.x + coefB * u.y + coefC * u.z);
  const Q = {
    x: lineA.x + u.x * t,
    y: lineA.y + u.y * t,
    z: lineA.z + u.z * t,
  };
  const factor = getFactorLinePosition2p(lineA, lineB, Q);

  if (strict && factor) {
    if (factor <= 0) return [lineA, 0];
    else if (factor >= 1) return [lineB, 1];
  }
  return [Q, factor];
}

export function distancePointToLine2D(px: number, py: number, x1: number, y1: number, x2: number, y2: number, strict: boolean = false): number {
  const projPoint = pointToLine2D(px, py, x1, y1, x2, y2, strict);
  return distance2d(projPoint.x, projPoint.y, px, py);
}
export function distancePointToLine3D(lineP0: IPoint, lineP1: IPoint, point: IPoint, strict: boolean = false): number {
  const Q = pointOnLine3D(lineP0, lineP1, point, strict)[0];
  return vectorDist3D(Q, point);
}

/** Minimum Distance between a Point and a PolyLine
 *  For CLOSED polylines repeat last point
 * @export
 * @param {MATH.IPoint} p
 * @param {MATH.IPoint[]} polyline
 */
export function distance2DPointToPolyline(p: IPoint, linePtos: IPoint[]) {
  let dist = Infinity;
  let p0, p1, currDist;
  for (let i = 0, l = linePtos.length; i < l - 1; i++) {
    p0 = linePtos[i];
    p1 = linePtos[i + 1];
    currDist = distancePointToLine2D(p.x, p.y, p0.x, p0.y, p1.x, p1.y, true);
    if (currDist < dist) dist = currDist;
  }
  return dist;
}
export function nearestPointToPolyline(p: IPoint, linePtos: IPoint[]): IPoint {
  let dist = Infinity;
  let p0, currDist, pNearest = p;
  for (let i = 0; i < linePtos.length; i++) {
    p0 = linePtos[i];
    currDist = vectorDist3D(p, p0);
    if (currDist <= dist) {
      dist = currDist;
      pNearest = copyIPoint(p0);
    }
  }
  return pNearest;
}
export function getDistancePointToArc(center: IPoint, radius: number, azimutStart: number, azimutEnd: number, direction: ORIENT, point: IPoint): number {
  const pAzimut = lineAzimut2p(center, point);
  if (direction === ORIENT.CW && isBetweenAzimuts(pAzimut, azimutStart, azimutEnd)) {
    return getDistancePointToCircle(center, radius, point);
  }
  if (direction === ORIENT.CCW && !isBetweenAzimuts(pAzimut, azimutStart, azimutEnd)) {
    return getDistancePointToCircle(center, radius, point);
  }
  const P1 = getAzimutPolarPoint(center, azimutStart, radius);
  const P3 = getAzimutPolarPoint(center, azimutEnd, radius);
  const d1 = vectorDist3D(P1, point);
  const d3 = vectorDist3D(P3, point);
  return d1 > d3 ? d3 : d1;
}
export function getDistancePointToCircle(center: IPoint, radius: number, point: IPoint): number {
  // La forma más barata de calcular la distancia de un punto a un círculo es calcular la distancia a su centro y restar el radio.
  // De hecho es la distancia punto - esfera.
  let d = vectorDist3D(center, point) - radius;
  if (d < 0) {
    d = -d;
  }
  return d;

}