import { createStripFooter, updateStripFooter } from "lib/geometries/structural/footer";
import { getCurrentSolidMaterial, ISolidMaterial, materialCache } from "lib/materials";
import { copyIPoint } from "lib/math/point";
import { stripfootingParam } from "lib/models-struc/types/footer";
import { objDataType } from "../types";
import { StructuralElementData } from "./structural";
import { buildingElemType } from "lib/models-struc/types/struc-base";
import { defaultFlatSectionId, shellCrossSectionCache } from "lib/models-struc/cross-sections-shape/shell-cross-sections/cache";
import { copyIPolylineParam } from "lib/math/line";

export class StripFootingData extends StructuralElementData {

  public type: buildingElemType = objDataType.STRIPFOOTER;
  protected nameObj: string = "Strip footing";

  public definition: stripfootingParam;

  constructor(definition: stripfootingParam, material?: ISolidMaterial) {
    super();
    this.definition = {
      storeyId: definition.storeyId,
      name: definition.name,
      lnkObjIds: definition.lnkObjIds ? definition.lnkObjIds.slice() : [],
      materialType: definition.materialType,

      shellSectionId: definition.shellSectionId ?? defaultFlatSectionId,
      stripLine: copyIPolylineParam(definition.stripLine),
      width: definition.width,
      basePoint: copyIPoint(definition.basePoint),
      rotation: copyIPoint(definition.rotation),
      offset: copyIPoint(definition.offset),
      scale: copyIPoint(definition.scale),
    };
    this.material = material ?? getCurrentSolidMaterial();
  }
  static createObj(definition: stripfootingParam, material: ISolidMaterial) {
    const threeMaterial = materialCache.getSolidMaterial(material);
    const section = shellCrossSectionCache.loadStylefromCache(definition.shellSectionId)!;
    const graphicObj = createStripFooter(definition.stripLine, definition.width, section.thickness, threeMaterial);
    const position = definition.basePoint;
    const rotation = definition.rotation;
    graphicObj.position.set(position.x, position.y, position.z);
    graphicObj.rotation.set(rotation.x, rotation.y, rotation.z);
    return graphicObj;
  }
  public async createGraphicObj() {
    if (this.graphicObj) {
      console.warn("Attention: Strip Footer graphic object already created!!");
      return;
    }
    this.graphicObj = StripFootingData.createObj(this.definition, this.material);
  }
  public cloneDefinition(): stripfootingParam {
    const solid = super.cloneSolidDefinition();
    return {
      storeyId: this.definition.storeyId,
      name: this.definition.name,
      lnkObjIds: this.definition.lnkObjIds.slice(),
      materialType: this.definition.materialType,

      shellSectionId: this.definition.shellSectionId,
      stripLine: copyIPolylineParam(this.definition.stripLine),
      width: this.definition.width,
      basePoint: copyIPoint(solid.basePoint),
      rotation: copyIPoint(solid.rotation),
      offset: copyIPoint(solid.offset),
      scale: copyIPoint(solid.scale),
    }
  }
  public createObject(definition?: stripfootingParam, material?: ISolidMaterial): THREE.Mesh {
    return StripFootingData.createObj(definition ?? this.definition, material ?? this.material);
  }

  public regenerateDefinition() {
    const section = shellCrossSectionCache.loadStylefromCache(this.definition.shellSectionId)!;
    updateStripFooter(this.graphicObj, this.definition.stripLine, this.definition.width, section.thickness);
    const position = this.definition.basePoint;
    const rotation = this.definition.rotation;
    this.graphicObj.position.set(position.x, position.y, position.z);
    this.graphicObj.rotation.set(rotation.x, rotation.y, rotation.z);
    this.graphicObj.updateMatrixWorld();
  }
}
