import EyeIcon from "shared/components/icons/eye";
import Modal from "shared/components/ui/modal";
import { useModal } from "shared/components/ui/modal/hooks/use-modal";
import { useEffect, useRef } from "react";
import { loadDXF } from "./utils";

interface Props {
  url: string | null;
  name: string | null;
}

const Canvas = ({ url }: { url: string | null }) => {
  const ref = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    if (ref.current && url) {
      loadDXF(url, ref.current);
    }
  }, [url, ref]);

  return <canvas id="dxf-viewer" ref={ref} />;
};
const DXFViewer = ({ url, name }: Props) => {
  const { isOpen, open, close } = useModal();
  return (
    <>
      <button
        aria-label="show dxf file"
        onClick={open}
        className="flex items-center hover:text-gray-700 focus:outline-none"
      >
        <EyeIcon />
      </button>
      <Modal isOpen={isOpen} onClose={close} title={name as string}>
        <h1 className="text-lg leading-6 font-medium text-gray-400">
          {name || "No file selected"}
        </h1>
        <Canvas url={url} />
      </Modal>
    </>
  );
};

export default DXFViewer;
