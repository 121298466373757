import { v4 as uuid } from 'uuid'
import { move } from 'shared/utils/array'
import { loadHypothesisList } from 'lib/models-struc/hypothesis/hypothesis';
import { StrucStorey } from '../models/project'
import { standard } from "../models/codes";

export interface fileInfo {
  name: string;
  url: string;
  file: File;
}

export type occupancyList =
  'RESIDENTIAL' |
  'OFFICE' |
  'PARKING' |
  'SHOPPING' |
  'ACCESSIBLE_ROOF' |
  'NON_ACCESSIBLE_ROOF' |
  'SNOW_ABOVE_1000' |
  'SNOW_BELOW_1000' |
  'OTHER';

export const occupancyNameList = [
  'RESIDENTIAL',
  'OFFICE',
  'PARKING',
  'SHOPPING',
  'ACCESSIBLE_ROOF',
  'NON_ACCESSIBLE_ROOF',
  'SNOW_ABOVE_1000',
  'SNOW_BELOW_1000',
  'OTHER'
]

export function getLiveLoadFromOccupancy(occupancy: occupancyList): { live_load: number, dead_load: number } {
  switch (occupancy) {
    case 'RESIDENTIAL':
      return { live_load: 2 * 1000, dead_load: 2 * 1000 };
    case 'OFFICE':
      return { live_load: 2 * 1000, dead_load: 2 * 1000 };
    case 'PARKING':
      return { live_load: 5 * 1000, dead_load: 1 * 1000 };
    case 'SHOPPING':
      return { live_load: 4 * 1000, dead_load: 1 * 1000 };
    case 'ACCESSIBLE_ROOF':
      return { live_load: 1 * 1000, dead_load: 2.5 * 1000 };
    case 'NON_ACCESSIBLE_ROOF':
      return { live_load: 0.4 * 1000, dead_load: 2.5 * 1000 };
    case 'SNOW_ABOVE_1000':
      return { live_load: 1 * 1000, dead_load: 2.5 * 1000 };
    case 'SNOW_BELOW_1000':
      return { live_load: 1 * 1000, dead_load: 2.5 * 1000 };
    case 'OTHER':
      return { live_load: 2 * 1000, dead_load: 2 * 1000 };
    default:
      return { live_load: 2 * 1000, dead_load: 2 * 1000 };
  }
}

export interface RawStorey {
  id: string
  floor_name: string
  height: number
  occupancy: string
  dxf_name: string
  dxf_url: string
}

export const DEFAULT_INT = 0;
export const DEFAULT_FLOAT = 0.0;
export const DEFAULT_HEIGHT = 3
export const DEFAULT_OCCUPANCY = loadHypothesisList.RESIDENTIAL;

export function createEmptyStorey(storeyNumber: number, footingLevel: number, concrete_standard: standard, dxf?: fileInfo): StrucStorey {
  const occupancy = DEFAULT_OCCUPANCY;
  const { dead_load, live_load } = getLiveLoadFromOccupancy(occupancy);
  return {
    id: uuid(),
    storey_number: storeyNumber,
    floor_name: `F${storeyNumber}`,
    height: DEFAULT_HEIGHT,
    level: footingLevel + DEFAULT_HEIGHT * storeyNumber,
    occupancy,
    dxf_name: dxf?.name ?? "",
    dxf_url: dxf?.url ?? "",
    column_concrete_type: concrete_standard === 'EU' ? 'HA25' : '4000psi',
    floor_concrete_type: concrete_standard === 'EU' ? 'HA25' : '4000psi',
    dead_load,
    live_load,
  }
}
export function fillStoreys(length: number, currentStoreys: StrucStorey[], bulkDXF: fileInfo[], concrete_standard: standard, footingLevel: number): StrucStorey[] {
  const newLength = length - currentStoreys.length
  if (newLength > 0) {
    const emptyStoreys = [];
    for (let i = 0; i < newLength; i++) {
      const storey = createEmptyStorey(i + currentStoreys.length + 1, footingLevel, concrete_standard, bulkDXF?.[i]);
      emptyStoreys.push(storey)
    }
    return [...currentStoreys, ...emptyStoreys]
  }
  if (newLength < 0) {
    debugger;
    const maxIndex = Math.abs(newLength) - 1
    const filteredStoreys = currentStoreys.slice(0, maxIndex)
    return filteredStoreys
  }
  return currentStoreys
}
export function addStorey(currentStoreys: StrucStorey[], footingLevel: number, concrete_standard: standard, bulkDXF?: fileInfo): StrucStorey[] {
  const newLength = currentStoreys.length + 1;
  const storey = createEmptyStorey(newLength, footingLevel, concrete_standard, bulkDXF);
  return [...currentStoreys, storey];
}
export function recalculateLevels(storeys: StrucStorey[], footingLevel: number): StrucStorey[] {
  let currentLevel = footingLevel || 0
  const updatedLevels: number[] = storeys.map(storey => {
    const level = +storey.height + currentLevel
    currentLevel = level
    return level
  })
  return storeys.map((storey, index) => ({
    ...storey,
    storey_number: index + 1,
    level: updatedLevels[index],
  }))
}
export function updateStoreyName(storey: StrucStorey, storeys: StrucStorey[], newName: string): StrucStorey[] {
  const name = checkStoreyName(storeys, newName);
  const updatedStoreys = storeys.map(s => {
    if (s.id === storey.id) s.floor_name = name;
    return s;
  })
  return updatedStoreys;
}
function checkStoreyName(storeys: StrucStorey[], newName: string) {
  let name = newName;
  let count = 0;
  let nameExists = true;
  while (nameExists) {
    // eslint-disable-next-line no-loop-func
    nameExists = storeys.some(s => s.floor_name === name);
    if (nameExists) {
      name = `${newName}_${count}`;
      count++;
    }
  }
  return name;
}
export function updateStoreys(updatedStorey: StrucStorey, storeys: StrucStorey[], footingLevel: number): StrucStorey[] {
  const updatedStoreys = storeys.map(storey =>
    storey.id === updatedStorey.id ? updatedStorey : storey
  )
  return recalculateLevels(updatedStoreys, footingLevel)
}
export function cloneStorey(currentStoreys: StrucStorey[], storey: StrucStorey) {
  const index = currentStoreys.indexOf(storey);
  const storeyNumber = currentStoreys.length + 1;

  let floorName = "";
  if (storey.floor_name.includes("_copy")) {
    const [name] = storey.floor_name.split("_copy") as string[];
    floorName = name + "_copy";
  } else {
    floorName = storey.floor_name + "_copy";
  }

  const name = checkStoreyName(currentStoreys, floorName);
  const clonedStorey: StrucStorey = {
    id: uuid(),
    storey_number: storeyNumber,
    floor_name: name,
    height: DEFAULT_HEIGHT,
    level: DEFAULT_HEIGHT * storeyNumber,
    occupancy: storey.occupancy,
    dxf_name: storey.dxf_name,
    dxf_url: storey.dxf_url,
    column_concrete_type: storey.column_concrete_type,
    floor_concrete_type: storey.floor_concrete_type,
    dead_load: storey.dead_load,
    live_load: storey.live_load,
  }
  const newList = currentStoreys.slice();
  newList.splice(index + 1, 0, clonedStorey);
  return newList;
}
export function recalculateOrder(storeys: StrucStorey[], source: number, destination: number, footingLevel: number): StrucStorey[] {
  const movedStoreys: StrucStorey[] = move(storeys, source, destination)
  const updatedStoreys = recalculateLevels(movedStoreys, footingLevel)
  return updatedStoreys
}
