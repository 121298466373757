import { getProyectedLine, updateLinearDimension } from "lib/dimension/line-dim-builder";
import { getVertexFromIndex } from "lib/geometries/line";
import { lineAngle2p } from "lib/math/angles";
import { pointOnLine3D, vectorDist3D } from "lib/math/distance";
import { vector2Equals } from "lib/math/epsilon";
import { IPlane, intersectLinePlane } from "lib/math/plane";
import { getMiddlePoint, copyIPoint, getFactorLinePosition2p } from "lib/math/point";
import { rotatePointZ, rotatePointX, rotatePointY } from "lib/math/rotate";
import { IPoint, ISegment } from "lib/math/types";
import { AlignedDimensionData } from "lib/models/dimension/aligned-dim";
import { linealDimensionParam } from "lib/models/dimension/lineal-dim";
import { cadOpType } from "../factory";
import { dimDirectionMode, dimOrientationMode, dimPlane } from "./dimension-base";
import { EditDimensionOP } from "./dimension-base-edit";

export class EditLinearDimensionOP extends EditDimensionOP<linealDimensionParam> {

    public opType = cadOpType.EDITLINEARDIM;

    constructor(threeObj: AlignedDimensionData, vertex: number) {
        super(threeObj);
        this.vertexToEdit = vertex;
    }

    public moveLastPoint(point: IPoint) {
        const v1 = this.newDimInfo.objRef[0].point;
        const v2 = this.newDimInfo.objRef[1].point;

        let projSegment = getProyectedLine({ p1: v1, p2: v2 }, this.newDimInfo);
        this.calculateDimPlane(projSegment);

        const pov: IPoint = this.graphicProcessor.getCursorCamera().position;
        let secondPointProj = intersectLinePlane(pov, point, this.currentPlane) as IPoint;

        if (this.vertexToEdit === 0) {
            this.editTextLabel(secondPointProj);
        } else {
            this.editDistanceLineBase(secondPointProj, projSegment);
        }
        console.log("Plano " + dimPlane[this.newDimInfo.dimPlane]
            + ", Direccion: " + dimDirectionMode[this.newDimInfo.dimDirection]
            + ", Orientacion: " + dimOrientationMode[this.newDimInfo.dimOrientation]
            + ", distancia: " + this.dimStyle.distBaseLine);

        updateLinearDimension(this.objDataAux[0].graphicObj as THREE.Group, { p1: v1, p2: v2 }, this.newDimInfo, this.dimStyle, this.txtStyle);
    }

    private currentPlane: IPlane;

    private calculateDimPlane(projSegment: ISegment) {
        const v1 = this.newDimInfo.objRef[0].point;
        const v2 = this.newDimInfo.objRef[1].point;
        // Cálculo del punto del raycast en el plano de la acotación
        if (vector2Equals(v1, v2)) {
            // Línea completamente vértical
            const minZpoint = v1.z < v2.z ? v1 : v2;
            if (this.newDimInfo.dimPlane === dimPlane.XY || this.newDimInfo.dimPlane === dimPlane.XZ) {
                this.newDimInfo.dimPlane = dimPlane.XZ;
                this.currentPlane = { p1: minZpoint, p2: { x: minZpoint.x, y: minZpoint.y, z: minZpoint.z + 50 }, p3: { x: minZpoint.x + 50, y: minZpoint.y, z: minZpoint.z } };
            } else {
                this.currentPlane = { p1: minZpoint, p2: { x: minZpoint.x, y: minZpoint.y, z: minZpoint.z + 50 }, p3: { x: minZpoint.x, y: minZpoint.y + 50, z: minZpoint.z } };
            }
            projSegment = {
                p1: copyIPoint(this.currentPlane.p1),
                p2: copyIPoint(this.currentPlane.p2),
            };
        } else {
            if (this.newDimInfo.dimPlane === dimPlane.XY) {
                this.currentPlane = { p1: { x: 1, y: 1, z: projSegment.p1.z }, p2: { x: 2, y: 2, z: projSegment.p1.z }, p3: { x: 2, y: 1, z: projSegment.p1.z } };
            } else if (this.newDimInfo.dimPlane === dimPlane.XZ) {
                this.currentPlane = { p1: { x: 1, y: projSegment.p1.y, z: 0 }, p2: { x: 2, y: projSegment.p1.y, z: 0 }, p3: { x: 2, y: projSegment.p1.y, z: 1 } };
            } else if (this.newDimInfo.dimPlane === dimPlane.YZ) {
                this.currentPlane = { p1: { x: projSegment.p1.x, y: 1, z: 1 }, p2: { x: projSegment.p1.x, y: 2, z: 2 }, p3: { x: projSegment.p1.x, y: 2, z: 1 } };
            } else if (this.newDimInfo.dimPlane === dimPlane.AUTO) {
                this.currentPlane = { p1: v1, p2: v2, p3: { x: v2.x, y: v2.y, z: v2.z - 50 } };
            }
        }
    }
    private editTextLabel(secondPointProj: IPoint) {
        const v1 = getVertexFromIndex(this.dimGeometry, 6) as IPoint;
        const v2 = getVertexFromIndex(this.dimGeometry, 7) as IPoint;

        // const min = (this.textLabel.geometry.boundingBox as THREE.Box3).min;
        // const max = (this.textLabel.geometry.boundingBox as THREE.Box3).max;
        const offsetX = 0; // (max.x - min.x);
        // const offsetX = 0; // this.textLabel.geometry.boundingBox?.max.x ?? 10;
        const offsetXLimit = 0; // offsetX / vectorDist3D(v1, v2);

        const vMiddle = getMiddlePoint(v1, v2);
        const auxPto = pointOnLine3D(v1, v2, secondPointProj, false)[0];
        const factor = getFactorLinePosition2p(v1, v2, auxPto) as number;

        if (factor > 1 + offsetXLimit) this.dimStyle.fontOffsetX = vectorDist3D(v1, v2) * 0.5 + offsetX;
        else if (factor >= 0.5 && factor < 1 + offsetXLimit) this.dimStyle.fontOffsetX = vectorDist3D(vMiddle, auxPto);
        else if (factor < 0.5 && factor > - offsetXLimit) this.dimStyle.fontOffsetX = - vectorDist3D(vMiddle, auxPto);
        else if (factor < - offsetXLimit) this.dimStyle.fontOffsetX = - (vectorDist3D(v1, v2) * 0.5 + offsetX);
    }
    private editDistanceLineBase(secondPointProj: IPoint, projSegment: ISegment) {
        // GIRAMOS GEOMETRIA AL PLANO XY (SOLO PLANOS XZ, YZ, DEF)
        const centerPoint = getMiddlePoint(projSegment.p1, projSegment.p2);
        if (this.newDimInfo.dimPlane !== dimPlane.XY) {
            if (!vector2Equals(projSegment.p1, projSegment.p2)) {
                const XYangle = lineAngle2p(projSegment.p1, projSegment.p2);
                projSegment.p1 = rotatePointZ(projSegment.p1, -XYangle, centerPoint);
                projSegment.p1 = rotatePointX(projSegment.p1, -(Math.PI * 0.5), centerPoint);
                projSegment.p2 = rotatePointZ(projSegment.p2, -XYangle, centerPoint);
                projSegment.p2 = rotatePointX(projSegment.p2, -(Math.PI * 0.5), centerPoint);
                secondPointProj = rotatePointZ(secondPointProj, -XYangle, centerPoint);
                secondPointProj = rotatePointX(secondPointProj, -(Math.PI * 0.5), centerPoint);
            } else {
                const rotateFunc = this.newDimInfo.dimPlane === dimPlane.XZ ? rotatePointX : rotatePointY;
                projSegment.p1 = rotateFunc(projSegment.p1, -(Math.PI * 0.5), centerPoint);
                projSegment.p2 = rotateFunc(projSegment.p2, -(Math.PI * 0.5), centerPoint);
                secondPointProj = rotateFunc(secondPointProj, -(Math.PI * 0.5), centerPoint);
            }
        }
        this.newDimInfo.dimOrientation = this.getDimOrientation(this.newDimInfo.dimDirection, secondPointProj, projSegment);

        if (!this.dimStyle.blockDistBaseLine) {
            this.dimStyle.distBaseLine = this.getDimDistance(this.newDimInfo.dimDirection, secondPointProj, projSegment);
        } else {
            this.dimStyle.distBaseLine = this.dimStyle.minDistBaseLine1;
        }
    }

}