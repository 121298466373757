import { AnalysisProject, AnalysisProjectRequest, AnalysisProjectsApi, AnalysisProjectsStatusApi, AnalysisProjectStatusMPValueEnum } from "lib/apis/analysis-projects/api";
import { compressAndUploadToS3, createRequestOK, requestStatus, saveFile, statusRequestOK, updateRequestOK } from "lib/apis/utils";
import { userMessageEvents } from "lib/events/user-messages";
import { GraphicProcessor } from "lib/graphic-processor";
import { EcoreAnalysisExporter } from "lib/input-output/e-core/exporter/analysis-exporter";
import { analysisProjectEvent } from "modules/struc/components/analysis/hook/use-analyzing";
import { IAnalysisProject as IAnalysisProjectEcore } from "modules/struc/models/ecore/analysis";
import { hypothesisManager } from "../hypothesis/hypothesismodel-manager";
import { SSEhypothesisManager } from "../hypothesis/sse";
import { windhypothesisManager } from "../hypothesis/wind";
import { meshManager } from "../mesh/meshmodel-manager";
import { AnalysisProperties, AnalysisProjectWithErrors, isAnalysisProject, AnalysisSettings } from "./analysis";
import { analysisDispatcher } from "./dispatcher";

export class AnalysisModelManager {

  public analysis: AnalysisSettings = { solveHypothesis: [], project: undefined }
  public status: requestStatus = requestStatus.VOID;

  importFromJSON(analysis?: AnalysisProperties) {
    if (analysis) {
      this.analysis.project = analysis.project;
      if (analysis.solveHypothesisIds) {
        for (const hypoUid of analysis.solveHypothesisIds) {
          const hypo = this.getHypothesisFromId(hypoUid);
          if (hypo) this.analysis.solveHypothesis.push(hypo);
        }
        analysisDispatcher.dispatchSolve(this.analysis.solveHypothesis);
      }
    }
  }
  exportToJSON(): AnalysisProperties {
    return {
      project: this.analysis.project,
      solveHypothesisIds: this.analysis.solveHypothesis.map(h => h.uid),
    };
  }

  getHypothesisFromId(hypoId: string) {
    const hypo = hypothesisManager.getHypothesisByid(hypoId);
    if (hypo) return hypo;
    const windHypo = windhypothesisManager.getWindHypothesisById(hypoId);
    if (windHypo) return windHypo;
    const sseHypo = SSEhypothesisManager.getSSEByUuid(hypoId)
    if (sseHypo) return sseHypo;
  }

  //-----------------------------------------------

  checkAnalysisProjectErrors(graphicProc: GraphicProcessor): AnalysisProjectWithErrors | undefined {
    if (this.analysis.solveHypothesis.length) {
      // TODO: Implement logic
      analysisDispatcher.dispatchSolve(this.analysis.solveHypothesis);
      //   console.table(this.solveHypothesis, ["id"]);
      return undefined;
    }
    return { name: "Error", errors: ["No hypothesis selected"] }
  }

  exportAnalysisProjectStruct(graphicProc: GraphicProcessor): AnalysisProjectWithErrors | IAnalysisProjectEcore {
    const errors = this.checkAnalysisProjectErrors(graphicProc);
    if (errors) {
      return errors;
    } else {
      try {
        const eCoreAdapter = new EcoreAnalysisExporter();
        return eCoreAdapter.exportAnalysisProject()
      } catch (err: any) {
        throw new Error("Error to export ecore analysis model.");
      }
    }
  }

  async launchAnalysisProject(graphicProc: GraphicProcessor) {
    try {
      if (this.status !== requestStatus.RUNNING) {
        if (!meshManager.hasMeshData()) {
          await meshManager.downloadMesh(graphicProc);
        }
        const analysisProjectData = this.exportAnalysisProjectStruct(graphicProc);
        if (isAnalysisProject(analysisProjectData)) {
          this.status = requestStatus.RUNNING;
          await this.createAndUploadAnalysis(graphicProc, analysisProjectData);
          return Promise.resolve(true);
        } else {
          this.status = requestStatus.ERROR;
          const message = "The analysis project has errors that make the analysis impossible. Please, check the downloaded file for more info ...";
          userMessageEvents.dispatchError(message);
          saveFile(JSON.stringify(analysisProjectData), `${analysisProjectData.name}.json`, "application/json");
          throw new Error(message);
        }
      } else {
        return Promise.resolve(false);
      }
    } catch (err: any) {
      userMessageEvents.dispatchError(err.stack);
      return Promise.reject(err);
    }
  }

  private async createAndUploadAnalysis(graphicProc: GraphicProcessor, analysisProjectData: any) {
    try {
      const analysisProjectEndPoint: AnalysisProjectsApi = new AnalysisProjectsApi();
      let lastAnalysisProject: AnalysisProject | undefined;
      if (this.analysis.project) {
        const analysisProjectRes = await analysisProjectEndPoint.analysisProjectsIdUploadGet(this.analysis.project.Id);
        console.log("[ANALYZING-GET] " + this.analysis.project.Id)
        if (analysisProjectRes.status === updateRequestOK) {
          lastAnalysisProject = { ...this.analysis.project, UploadUrl: analysisProjectRes.data.UploadUrl };
        }
      } else {
        const projMng = graphicProc.getProjectModelManager();
        const analysisProjectParams: AnalysisProjectRequest = { MeshProjectID: meshManager.mesh.project?.Id!, Name: projMng.project.id };
        const analysisProjectRes = await analysisProjectEndPoint.analysisProjectsPost(analysisProjectParams);
        console.log("[ANALYZING-POST] " + analysisProjectRes.data.Id)
        if (analysisProjectRes.status === createRequestOK) {
          lastAnalysisProject = analysisProjectRes.data;
        }
      }

      if (lastAnalysisProject) {
        this.analysis.project = lastAnalysisProject;
        // Compress data and upload to S3
        await compressAndUploadToS3(JSON.stringify(analysisProjectData), lastAnalysisProject.UploadUrl);
      }
    } catch (error) {
      this.status = requestStatus.ERROR;
      console.error(error);
      return Promise.reject(error);
    }
  }

  async getAnalyzingStatus(): Promise<AnalysisProjectStatusMPValueEnum | null> {
    if (this.analysis.project) {
      const analysisProjectEndPoint = new AnalysisProjectsStatusApi();
      const analysisProjectRes = await analysisProjectEndPoint.analysisProjectsIdStatusGet(this.analysis.project.Id);

      if (analysisProjectRes.status === statusRequestOK) {
        console.log("[ANALYZING-GET-STATUS] " + this.analysis.project.Id + " - " + analysisProjectRes.data.MPValue);
        const status = analysisProjectRes.data.MPValue;
        if (status === AnalysisProjectStatusMPValueEnum.Done) this.status = requestStatus.DONE;
        else if (status === AnalysisProjectStatusMPValueEnum.Error) this.status = requestStatus.ERROR;
        else if (status === AnalysisProjectStatusMPValueEnum.Running) this.status = requestStatus.RUNNING;
        else if (status === AnalysisProjectStatusMPValueEnum.Canceled) this.status = requestStatus.CANCELED;
        return status
      }
    }
    this.status = requestStatus.VOID;
    return Promise.resolve(null)
  }

  async getAnalysingLastEvents(): Promise<analysisProjectEvent[]> {
    const result: analysisProjectEvent[] = [];
    if (this.analysis.project) {
      const analysisProjectEndPoint: AnalysisProjectsApi = new AnalysisProjectsApi();
      const analysisProjectRes = await analysisProjectEndPoint.analysisProjectsIdGet(this.analysis.project.Id);
      console.log("[ANALYZING-GET-LASTEVENTS] " + this.analysis.project.Id);
      if (analysisProjectRes.status === updateRequestOK) {
        const project = analysisProjectRes.data;
        const projectEvents = project.Events;
        if (projectEvents) {
          const events = [projectEvents[projectEvents.length - 1]]; // ?.filter(e => (e.CreatedAt ?? lastDate) >= lastDate);
          if (events && events.length > 0) {
            events.forEach(e => {
              if (e.Name !== undefined && e.CreatedAt !== undefined) {
                result.push({ name: e.Name, date: new Date(e.CreatedAt) });
              }
            });
          }
        }
      }
    }
    return result;
  }

  async cancelAnalysing(): Promise<boolean> {
    let result = false;
    if (this.analysis.project) {
      const analysisProjectEndPoint = new AnalysisProjectsStatusApi();
      const analysisProjectRes = await analysisProjectEndPoint.analysisProjectsIdCancelPost(this.analysis.project.Id);
      console.log("[ANALYZING-POST-CANCEL] " + this.analysis.project.Id);
      if (analysisProjectRes.status === statusRequestOK) {
        result = true;
      }
    }
    return result;
  }
}

export let analysisManager: AnalysisModelManager;
export function initAnalisisManager() {
  analysisManager = new AnalysisModelManager();
  return analysisManager;
}