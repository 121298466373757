import { Cad3dOp } from "../base";
import { IPoint } from "../../math/types";
import { addIpoint } from "lib/math/point";
import { rotatePoint } from "lib/math/rotate";

export abstract class Parameter3dOP extends Cad3dOp {
  protected threeObjAux: THREE.Mesh;

  // CONFIGURACION DE OPERACIONES DE CREACIÓN
  protected basePoint: IPoint = { x: 0, y: 0, z: 0 };
  protected rotation: IPoint = { x: 0, y: 0, z: 0 };
  protected offSet: IPoint = { x: 0, y: 0, z: 0 };

  protected abstract createAux(): THREE.Mesh;
  protected abstract createGeom(): THREE.Geometry | THREE.BufferGeometry;

  public async start() {
    this.iniSettingsOp();
    this.threeObjAux = this.createAux();
    this.saveToTempScene(this.threeObjAux);
    this.registerInputs();
    this.registerUpdaters();
    this.initializeSnap();
    this.registerCancel();
  }

  public setLastPoint(): void {
    this.setNextStep();
  }

  public recalculateObj() {
    this.threeObjAux.geometry = this.createGeom();
    let aux = addIpoint(this.basePoint, this.offSet);
    aux = rotatePoint(
      aux,
      this.rotation.x,
      this.rotation.y,
      this.rotation.z,
      this.basePoint
    );
    this.threeObjAux.position.set(aux.x, aux.y, aux.z);
    this.threeObjAux.rotation.set(this.rotation.x, this.rotation.y, this.rotation.z);
  }

  public endOperation(): void {
    this.save();
    super.endOperation();
  }
}
