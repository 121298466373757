import { GraphicProcessor } from "../../graphic-processor";
import { IObjData } from "../../models/objdata";
import { offsetBuffer } from "../../math/offset-buffer";
import { cadOpType } from "../../operations/factory";
import { CadCommand } from "../base";
import { circleParam } from "lib/geometries/circle";
import { polygonParam } from "lib/geometries/polygon";
import { IPolylineParam } from "lib/math/line";
import { getDataModelBuilder } from "lib/models/model-creator/datamodel-factory";

export class OffsetBufferCommand extends CadCommand {

  protected opType = cadOpType.OFFSETCORRIDOR;

  private objData: IObjData;
  private createdObjDatas: IObjData[] = [];

  private round: boolean;
  private distance: number;

  constructor(objData: IObjData, distance: number, round: boolean, layerId: string, graphicProcessor: GraphicProcessor) {
    super(graphicProcessor);
    this.round = round;
    this.distance = distance;
    this.objData = objData;
    this.layerId = layerId;
  }

  public async execute() {
    const resultLines: (IPolylineParam | circleParam | polygonParam)[] = [];
    const res = offsetBuffer(this.objData, this.distance);
    for (const l of res) {
      resultLines.push(l);
    }
    // const closedGeom = isClosedData(obj);
    // if (closedGeom) {
    //   const res2 = offsetBuffer(obj, -this.distance);
    //   for (const l of res2) {
    //     resultLines.push(l);
    //   }
    // }
    for (const dataDefinition of resultLines) {
      const data = getDataModelBuilder(this.objData.type, dataDefinition, this.objData.material);
      data.createGraphicObj();
      this.graphicProcessor.addToLayer(data, this.layerId);
      this.createdObjDatas.push(data);
    }
    const modelManager = this.graphicProcessor.getDataModelManager();
    modelManager.dispatchAddedObjs(this.createdObjDatas);
    const lyrManager = this.graphicProcessor.getLayerManager();
    lyrManager.layerObserver.dispatchLoadLayers();
  }
  public unExecute() {
    for (const data of this.createdObjDatas) {
      this.graphicProcessor.removeFromLayer(data);
    }
    const dtMdlMngr = this.graphicProcessor.getDataModelManager();
    dtMdlMngr.dispatchDeletedObjs(this.createdObjDatas);
    const lyrManager = this.graphicProcessor.getLayerManager();
    lyrManager.layerObserver.dispatchLoadLayers();
  }
  public delete(): void {
    this.objData = undefined!;
    this.createdObjDatas.length = 0;
  }
}
