import { LinearDimensionCommand } from "lib/commands/dimension/lineal-dim";
import { getCustomDimStyleProperties } from "lib/dimension/style";
import { updateAlignedDimension, createLinearDimension, getAlignedDimBuffer, getLinearDimBuffer, updateLinearDimLabelText, updateLinearDimArrows, getLinearAuxBuffer } from "lib/dimension/line-dim-builder";
import { lineAddVertex, lineAuxCreate, lineMoveVertex } from "lib/geometries/line";
import { rotateObjX, rotateObjZ } from "lib/geometries/rotate";
import { lineAngle2p } from "lib/math/angles";
import { lineGetBbox } from "lib/math/box";
import { distancePointToLine3D } from "lib/math/distance";
import { vector2Equals } from "lib/math/epsilon";
import { IPlane, intersectLinePlane } from "lib/math/plane";
import { copyIPoint, getMiddlePoint } from "lib/math/point";
import { rotatePointZ, rotatePointX, rotatePointY } from "lib/math/rotate";
import { ISegment, IPoint, copyISegment } from "lib/math/types";
import { linealDimensionParam } from "lib/models/dimension/lineal-dim";
import { sdfDoubleSidedType } from "lib/text/styles";
import { cadOpType } from "../factory";
import { IPointReference, settingsOpModes } from "../step-operations";
import { defaultDimPlane, dimDirectionMode, DimensionOP, dimOrientationMode, dimPlane } from "./dimension-base";
import { IObjData } from "lib/models/objdata";
import { isDimensionData } from "lib/models/checktools";

export class LinearDimensionOP extends DimensionOP {

  /*  Posición de los PUNTOS QUE DEFINEN LA GEOMETRÍA DE LA ACOTACIÓN
          2*             *4                1*
           |             |                  |
     0*___6._____________.7___*1      4*___7.____________*5   *9
           |             |                  |
           |             |                  |
           |             *5                 |            WE
           |                                |
           |             *9                 |
          3*                          2*___6._______*3   *8
                  NS                        |
          8*                               0*
  */
  public opType = cadOpType.LINEARDIM;

  public dimDirection: dimDirectionMode;
  public dimOrientation: dimOrientationMode;
  public coordLimit: number;

  public firstBasePoint: IPointReference;
  public secondBasePoint: IPointReference;
  public dimLine: ISegment;

  public auxLine: THREE.Line;

  protected iniSettingsOp(): void {
    this.settingsOpManager.setCfg([{
      infoMsg: "Select first point.",
      stepMode: settingsOpModes.SELECTVERTEX,
      filterFun: (obj: IObjData) => {
        if (isDimensionData(obj)) return false;
        return true;
      },
      getVertexCallback: (pto) => {
        this.firstBasePoint = pto;
        this.setNextStep();
      },
      endStepCallback: () => {
        const { x, y, z } = this.firstBasePoint.point;
        lineMoveVertex(this.auxLine, x, y, z, 0);
        lineMoveVertex(this.auxLine, x, y, z, 1);
      },
    }, {
      infoMsg: "Select second point.",
      stepMode: settingsOpModes.SELECTVERTEX,
      filterFun: () => { return true },
      getVertexCallback: (pto) => {
        this.secondBasePoint = pto;
        this.setNextStep();
      },
      endStepCallback: () => {
        const { x, y, z } = this.secondBasePoint.point;
        lineMoveVertex(this.auxLine, x, y, z, 1);
        this.unRegisterRaycast();
        this.dimLine = {
          p1: copyIPoint(this.firstBasePoint.point),
          p2: copyIPoint(this.secondBasePoint.point),
        };
        this.inicializeLinealDim();
      },
    }, {
      infoMsg: "Insert dimension.",
      stepMode: settingsOpModes.WAITMODE,
      startStepCallback: this.registerInputs.bind(this),
      stepFun: this.dispathSaveMouseCoordinates.bind(this),
    }]);
  }
  public async start() {
    await super.start();
    this.auxLine = lineAuxCreate();
    lineAddVertex(this.auxLine, 0, 0, 0);
    lineAddVertex(this.auxLine, 0, 0, 0);
    this.saveToTempScene(this.auxLine);
    this.registerCancel();
    this.registerRaycast();
    this.registerUpdaters();
    this.initializeSnap();
  }

  private inicializeLinealDim(): void {
    this.dimDirection = dimDirectionMode.NS;
    this.dimOrientation = dimOrientationMode.POSITIVE;
    this.dimPlane = this.getDimPlane();
    const bbox = lineGetBbox(this.dimLine);
    this.coordLimit = bbox.min.z;
    const dimRef = { dimPlane: this.dimPlane, dimDirection: this.dimDirection, dimOrientation: this.dimOrientation, behindObj: this.coordLimit };
    this.txtStyle.doubleSided = sdfDoubleSidedType.HOR;
    // if (this.dimStyle.blockDistBaseLine) {
    // this.dimStyle.distBaseLine = calculateLinearBlockDistance(this.dimLine, this.threeObjOri[0], this.dimStyle, dimRef, this.tre);
    // }
    const dim = createLinearDimension(this.dimLine, dimRef, this.dimStyle, this.txtStyle);
    this.saveDimtoTemp(dim);
  }

  public setLastPoint(): void {
    this.save();
    this.endOperation();
  }
  public moveLastPoint(point: IPoint) {

    if (this.firstBasePoint === undefined) return;
    if (this.secondBasePoint === undefined) {
      lineMoveVertex(this.auxLine, point.x, point.y, point.z, 1);
      return;
    }

    const v1 = this.dimLine.p1;
    const v2 = this.dimLine.p2;

    // Cálculo del plano de acotación
    this.dimPlane = this.calculateDimPlane(v1, v2);

    // Línea completamente vértical
    if (vector2Equals(v1, v2)) {
      this.resolveVerticalLine(point);

    } else {
      // Cálculo del punto del raycast y la línea en el plano mas lejano de la acotación
      const pov: IPoint = this.graphicProcessor.getCursorCamera().position;
      let projSegment: ISegment, currentPlane: IPlane;
      const bbox = lineGetBbox(this.dimLine);
      if (this.dimPlane === dimPlane.XY) {
        this.coordLimit = bbox.min.z;
        if (pov.z < bbox.min.z) this.coordLimit = bbox.max.z;
        currentPlane = { p1: { x: 1, y: 1, z: this.coordLimit }, p2: { x: 2, y: 2, z: this.coordLimit }, p3: { x: 2, y: 1, z: this.coordLimit } };
        projSegment = { p1: { x: v1.x, y: v1.y, z: this.coordLimit }, p2: { x: v2.x, y: v2.y, z: this.coordLimit } };
      } else if (this.dimPlane === dimPlane.XZ) {
        this.coordLimit = bbox.min.y;
        if (pov.y < bbox.min.y) this.coordLimit = bbox.max.y;
        currentPlane = { p1: { x: 1, y: this.coordLimit, z: 0 }, p2: { x: 2, y: this.coordLimit, z: 0 }, p3: { x: 2, y: this.coordLimit, z: 1 } };
        projSegment = { p1: { x: v1.x, y: this.coordLimit, z: v1.z }, p2: { x: v2.x, y: this.coordLimit, z: v2.z } };
      } else if (this.dimPlane === dimPlane.YZ) {
        this.coordLimit = bbox.min.x;
        if (pov.x < bbox.min.x) this.coordLimit = bbox.max.x;
        currentPlane = { p1: { x: this.coordLimit, y: 1, z: 1 }, p2: { x: this.coordLimit, y: 2, z: 2 }, p3: { x: this.coordLimit, y: 2, z: 1 } };
        projSegment = { p1: { x: this.coordLimit, y: v1.y, z: v1.z }, p2: { x: this.coordLimit, y: v2.y, z: v2.z } };
      } else { // dimPlane.AUTO
        currentPlane = { p1: v1, p2: v2, p3: { x: v2.x, y: v2.y, z: v2.z - 50 } };
        projSegment = copyISegment(this.dimLine);
      }
      let secondPointProj = intersectLinePlane(pov, point, currentPlane) as IPoint;

      // GIRAMOS GEOMETRIA AL PLANO XY (SOLO PLANOS XZ, YZ, DEF)
      const centerPoint = getMiddlePoint(projSegment.p1, projSegment.p2);
      let XYangle = lineAngle2p(projSegment.p1, projSegment.p2);
      if (this.dimPlane !== dimPlane.XY) {
        projSegment.p1 = rotatePointZ(projSegment.p1, -XYangle, centerPoint);
        projSegment.p1 = rotatePointX(projSegment.p1, -(Math.PI * 0.5), centerPoint);
        projSegment.p2 = rotatePointZ(projSegment.p2, -XYangle, centerPoint);
        projSegment.p2 = rotatePointX(projSegment.p2, -(Math.PI * 0.5), centerPoint);
        secondPointProj = rotatePointZ(secondPointProj, -XYangle, centerPoint);
        secondPointProj = rotatePointX(secondPointProj, -(Math.PI * 0.5), centerPoint);
      }

      // Cálculo de la dirección y distancia de la acotación (calculos en plano XY)
      this.dimDirection = this.getDimDirection(this.dimDirection, secondPointProj, projSegment, "XY") as dimDirectionMode;
      this.dimOrientation = this.getDimOrientation(this.dimDirection, secondPointProj, projSegment);
      // if (!this.dimStyle.blockDistBaseLine) {
      this.dimStyle.distBaseLine = this.getDimDistance(this.dimDirection, secondPointProj, projSegment);
      // } else {
      //   this.dimStyle.distBaseLine = this.dimStyle.minDistBaseLine1;
      //   const dimRef = { dimPlane: this.dimPlane, dimDirection: this.dimDirection, dimOrientation: this.dimOrientation, behindObj: this.coordLimit };
      // this.dimStyle.distBaseLine = calculateLinearBlockDistance(this.dimLine, this.threeObjOri[0], this.dimStyle, dimRef, this.tre);
      // }

      console.log("Plano " + dimPlane[this.dimPlane] + " " + this.coordLimit
        + ", Direccion: " + dimDirectionMode[this.dimDirection]
        + ", Orientacion: " + dimOrientationMode[this.dimOrientation]
        + ", distancia: " + this.dimStyle.distBaseLine
        + ", anguloXY: " + lineAngle2p(v1, v2) * 180 / Math.PI);

      // Calculamos la acotación en el plano XY
      const currentOPlane = this.dimPlane;
      this.dimPlane = dimPlane.XY;
      let newGeometry;
      if (this.dimDirection === dimDirectionMode.DEF || vector2Equals(projSegment.p1, projSegment.p2)) {
        // if (!this.dimStyle.blockDistBaseLine) {
        //   newGeometry = getAlignedDimBuffer(projSegment, this.dimStyle, this.dimPlane, this.dimOrientation, secondPointProj) as THREE.BufferGeometry;
        // } else {
        newGeometry = getAlignedDimBuffer(projSegment, this.dimStyle, this.dimPlane, this.dimOrientation) as THREE.BufferGeometry;
        // }
      } else {
        const dimRef = { dimPlane: this.dimPlane, dimDirection: this.dimDirection, dimOrientation: this.dimOrientation, behindObj: this.coordLimit };
        newGeometry = getLinearDimBuffer(projSegment, dimRef, this.dimStyle) as THREE.BufferGeometry;
      }
      this.dimGeometry.geometry = newGeometry;
      updateLinearDimLabelText(this.textLabel, this.dimGeometry, this.dimStyle, this.dimPlane, this.txtStyle);
      updateLinearDimArrows(this.arrow1, this.arrow2, this.dimGeometry, this.dimPlane);
      this.dimPlane = currentOPlane;

      // DESHACEMOS EL GIRO DE LA GEOMETRIA DE ACOTACIÓN
      if (this.dimPlane !== dimPlane.XY && !vector2Equals(projSegment.p1, projSegment.p2)) {
        this.dimGeometry.rotation.set(0, 0, 0);
        rotateObjX(this.dimGeometry, Math.PI * 0.5, centerPoint);
        rotateObjZ(this.dimGeometry, XYangle, centerPoint);

        rotateObjX(this.textLabel, Math.PI * 0.5, centerPoint);
        rotateObjZ(this.textLabel, XYangle, centerPoint);

        rotateObjX(this.arrow1, Math.PI * 0.5, centerPoint);
        rotateObjZ(this.arrow1, XYangle, centerPoint);

        rotateObjX(this.arrow2, Math.PI * 0.5, centerPoint);
        rotateObjZ(this.arrow2, XYangle, centerPoint);
      }

      newGeometry = getLinearAuxBuffer(this.dimLine, this.dimGeometry, this.dimStyle, this.dimPlane);
      if (newGeometry) {
        if (this.auxGeometry) {
          this.auxGeometry.geometry = newGeometry;
        }
      }
    }
  }
  protected calculateDimPlane(v1: IPoint, v2: IPoint): dimPlane {
    let orientationPlane = this.getDimPlane();
    if (defaultDimPlane === dimPlane.AUTO && orientationPlane !== dimPlane.XY && v1.x !== v2.x && v1.y !== v2.y) {
      const lineVertPlane = { p1: v1, p2: v2, p3: { x: v1.x, y: v1.y, z: v1.z - 50 } };
      const currentPlane = this.getVerticalOrientation(lineVertPlane);
      if (null !== currentPlane) orientationPlane = currentPlane;
    }
    return orientationPlane;
  }

  private resolveVerticalLine(point: IPoint) {
    let currentPlane: IPlane;
    this.dimDirection = dimDirectionMode.DEF;
    this.coordLimit = this.dimLine.p1.x;
    const v1 = this.dimLine.p1, v2 = this.dimLine.p2;
    const minZpoint = v1.z < v2.z ? v1 : v2;
    if (this.dimPlane === dimPlane.XY || this.dimPlane === dimPlane.XZ) {
      this.dimPlane = dimPlane.XZ;
      currentPlane = { p1: minZpoint, p2: { x: minZpoint.x, y: minZpoint.y, z: minZpoint.z + 50 }, p3: { x: minZpoint.x + 50, y: minZpoint.y, z: minZpoint.z } };
    } else {
      currentPlane = { p1: minZpoint, p2: { x: minZpoint.x, y: minZpoint.y, z: minZpoint.z + 50 }, p3: { x: minZpoint.x, y: minZpoint.y + 50, z: minZpoint.z } };
    }

    const pov: IPoint = this.graphicProcessor.getCursorCamera().position;
    let secondPointProj = intersectLinePlane(pov, point, currentPlane) as IPoint;

    // Calculo de la distancia
    this.dimStyle.distBaseLine = distancePointToLine3D(this.dimLine.p1, this.dimLine.p2, secondPointProj, false);

    // GIRAMOS GEOMETRIA AL PLANO XY para ver dimOrientation (POSITIVO/NEGATIVO)
    const projSegment = { p1: copyIPoint(currentPlane.p1), p2: copyIPoint(currentPlane.p2) };
    const centerPoint = getMiddlePoint(projSegment.p1, projSegment.p2);
    const rotateFunc = this.dimPlane === dimPlane.XZ ? rotatePointX : rotatePointY;
    projSegment.p1 = rotateFunc(projSegment.p1, -(Math.PI * 0.5), centerPoint);
    projSegment.p2 = rotateFunc(projSegment.p2, -(Math.PI * 0.5), centerPoint);
    secondPointProj = rotateFunc(secondPointProj, -(Math.PI * 0.5), centerPoint);
    this.dimOrientation = this.getDimOrientation(dimDirectionMode.DEF, secondPointProj, projSegment);

    // if (this.dimStyle.blockDistBaseLine) {
    //   const dimLine = { p1: this.firstBasePoint.point, p2: this.secondBasePoint.point };
    //   this.dimStyle.distBaseLine = calculateBlockDistance(dimLine, this.threeObjOri[0], this.dimStyle, this.dimPlane, this.dimOrientation, this.tre);
    // } else {
    //   if (this.dimStyle.distBaseLine < this.dimStyle.minDistBaseLine1)
    //     this.dimStyle.distBaseLine = this.dimStyle.minDistBaseLine1;
    // }
    updateAlignedDimension(this.dimensionGroup, this.dimLine, this.dimStyle, this.dimPlane, this.dimOrientation, this.txtStyle);
    console.log("Plano " + dimPlane[this.dimPlane] + " " + this.coordLimit
      + ", Direccion: " + dimDirectionMode[this.dimDirection]
      + ", Orientacion: " + dimOrientationMode[this.dimOrientation]
      + ", distancia: " + this.dimStyle.distBaseLine
      + ", anguloXY: " + lineAngle2p(v1, v2) * 180 / Math.PI);
  }

  public save() {
    const dimInfo: linealDimensionParam = {
      styleId: this.dimStyle.styleId,
      dimPlane: this.dimPlane,
      dimOrientation: this.dimOrientation,
      dimDirection: this.dimDirection,
      behindObj: this.coordLimit,
      objRef: [this.firstBasePoint, this.secondBasePoint],
      customStyleProp: getCustomDimStyleProperties(this.dimStyle, this.defDimStyle),
    };
    const command = new LinearDimensionCommand(dimInfo, this.getCurrentSceneId(), this.graphicProcessor);
    if (command) this.graphicProcessor.storeAndExecute(command);
  }
}
