import { GraphicProcessor } from "lib/graphic-processor";
import { cadOpType } from "lib/operations/factory";
import { CadCommand } from "../base";
import { IWallHole } from "../../models-struc/types/wall";
import { addWallHole } from '../../geometries/structural/wall';
import { WallData } from "lib/models/structural/wall";
import { shellCrossSectionCache } from "lib/models-struc/cross-sections-shape/shell-cross-sections/cache";

export class WallHoleCommand extends CadCommand {

  protected opType = cadOpType.WALLHOLE;
  private wallData: WallData;
  private newHole: IWallHole;
  private stretch: number;

  constructor(wallElem: WallData, newHole: IWallHole, stretch: number, graphicProc: GraphicProcessor) {
    super(graphicProc);
    this.wallData = wallElem;
    this.newHole = newHole;
    this.stretch = stretch;
  }

  public execute(): void | Promise<void> {
    const { stretch, widthType, shellCrossSectionId } = this.wallData.definition;
    const section = shellCrossSectionCache.loadStylefromCache(shellCrossSectionId)!;
    const wallStretch = stretch[this.stretch];
    wallStretch.holes.push(this.newHole);
    addWallHole(this.wallData.graphicObj, section.thickness, widthType, this.newHole.points, wallStretch.normal);
  }
  public unExecute(): void | Promise<void> {
    this.wallData.definition.stretch[this.stretch].holes.pop();
    this.wallData.regenerateDefinition();
    //delWallHole(wallData.graphicObj, wallData.definition.thickness, wallData.definition.widthType, this.newHole.points, this.newHole.wallNormal);
  }
  public delete(): void {
    this.newHole.points = [];
    this.wallData = undefined!;
  }
}
