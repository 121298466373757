import { MeshAction, MeshActionType } from "lib/events/mesh";
import { CompositeLeaf, ICompositeComponent } from "lib/helpers/composite-tree";
import { itemType, meshItem } from "modules/cad/components/sidebar/context";
import { useEffect, useReducer } from "react";
import { useStrucProjectContext } from "../../context";

interface meshState {
  meshNode: ICompositeComponent<meshItem>;
}

function reducer(state: meshState, action: MeshAction): meshState {
  switch (action.type) {
    case MeshActionType.UPDATE_MESH_PARAMS: {
      const props: meshItem = { type: itemType.MESH, info: action.payload.params };
      state.meshNode.props = props
      return { ...state, meshNode: state.meshNode.cloneNode() };
    }
    case MeshActionType.LOAD_MESH_PROP:
    case MeshActionType.DOWNLOADED_MESH: {
      return state;
    }
    default:
      throw new Error(`Action is not defined.`);
  }
}

export function useMesh() {

  const { meshManager, meshDispatcher } = useStrucProjectContext();

  const [state, dispatch] = useReducer(reducer, {
    meshNode: new CompositeLeaf<meshItem>("Mesh", { type: itemType.MESH, info: meshManager.mesh.params }),
  });

  useEffect(() => {
    meshDispatcher.subscribe(dispatch);
    return () => {
      meshDispatcher.unsubscribe(dispatch);
    }
  }, []);

  return {
    meshNode: state.meshNode,
  }
}
