import * as THREE from "three";
import { GraphicProcessor } from "./graphic-processor";
// Para la version de react.
import React from "react";

/**
 * Gestor de las instancias de GraphicProcessor que vayamos creando, permitiendo su acceso por nombre.
 * Al construirse los objetos gestionados se registran automaticamente en este gestor estatico.
 * Al destruirse con su fmc destroy() se desregistran.
 *
 * @export
 * @class GraphicProcessorManager
 */

export class GraphicProcessorManager {
  /**
   * Aqui llevaremos automaticamente la cuenta de todos los objetos procesador grafico creados.
   *
   * @static
   * @private
   * @type {Map<string, GraphicProcessor>}
   * @memberof GraphicProcessorManager
   */
  private static mObjects: Map<string, GraphicProcessor> = new Map();

  public static register(obj: GraphicProcessor): boolean {
    if (GraphicProcessorManager.mObjects.has(obj.name)) {
      window.alert(`GraphicProcessor "${obj.name}" no ha sido registrado en el manager.`);
      return false;
    } else {
      GraphicProcessorManager.mObjects.set(obj.name, obj);
      return true;
    }
  }

  public static unregister(name: string): boolean {
    if (!GraphicProcessorManager.mObjects.has(name)) {
      window.alert(`GraphicProcessor "${name}"  no ha sido desregistrado en el manager.`);
      return false;
    } else {
      GraphicProcessorManager.mObjects.delete(name);
      return true;
    }
  }

  public static get(name: string): GraphicProcessor | null {
    if (GraphicProcessorManager.mObjects.has(name)) {
      return GraphicProcessorManager.mObjects.get(name) as GraphicProcessor;
    }
    return null;
  }

  public static has(name: string): boolean {
    return GraphicProcessorManager.mObjects.has(name);
  }

  static get size(): number {
    return GraphicProcessorManager.mObjects.size;
  }

  /**
   * Aqui se efectuan determinadas configuraciones graficas que solo necesitan ser efectuadas una sola vez.
   *
   * @static
   * @returns {boolean}
   * @memberof GraphicProcessorManager
   */
  public static initGraphicConfig(): boolean {
    if (GraphicProcessorManager.letsConfigure === true) {
      return false;
    }

    GraphicProcessorManager.letsConfigure = true;

    /*
      Informacion sobre versiones. Va con process. y alguno de estos:    
        REACT_APP_ANALYSISMS_URL="https://eaqecnd1t9.execute-api.eu-west-1.amazonaws.com/prod"
        REACT_APP_APPBACKEND_URL="https://lcjmyawzrd.execute-api.eu-west-1.amazonaws.com/prod"
        REACT_APP_FREECADMS_URL="https://bhj3859m2d.execute-api.eu-west-1.amazonaws.com/prod"
        REACT_APP_MESHMS_URL="https://x8jrytrmcf.execute-api.eu-west-1.amazonaws.com/prod"
        REACT_APP_STANDARDCODES_URL="https://cx7gulyo27.execute-api.eu-west-1.amazonaws.com/prod"
        REACT_APP_GRAPHQL_BACKEND_URL="http://greystruct.herokuapp.com/v1/graphql"
      Este distingue entre la version developer/production.      
        REACT_APP_ENV="dev"
    */
    if (process.env.REACT_APP_ENV) {
      if (process.env.REACT_APP_ENV === "dev") {
        console.log(` + DEVELOPER VERSION`);
      } else {
        console.log(` + PRODUCTION VERSION`);
      }
    } else {
      console.error(" + WARNING: process.env.REACT_APP_ENV has no value!!!.")
    }
    console.log(` + Using Three.js VERSION: ${THREE.REVISION}`);
    console.log(` + Using React.js VERSION: ${React.version}`);
    // Todo apunta para arriba.
    THREE.Object3D.DefaultUp.set(0, 0, 1);

    return true;
  }

  public static letsConfigure = false;
} // class GraphicProcessorManager

GraphicProcessorManager.letsConfigure = GraphicProcessorManager.initGraphicConfig();