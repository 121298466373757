import { columnParam } from "lib/models-struc/types/column";
import { heightType } from "lib/models-struc/types/struc-base";
import { angleBetweenVectors } from "lib/math/angles";
import { dataModelPersistence } from "lib/input-output/database/loader";
import { objDataType } from "lib/models/types";
import { Storey } from "lib/models-struc/struc-storey";
import { Column } from 'modules/struc/models/ecore/structural';
import { ILogger } from 'shared/utils/logger/logger';
import { EcoreImporter } from './structuralElement';

export class EcoreColumnImporter extends EcoreImporter {

  constructor(
    protected ecoreElem: Column,
    protected logger: ILogger) {
    super();
  }

  getObjData(storey: Storey, layerId: string, cssIdsRefs: string[], materialRefs: string[]): dataModelPersistence {
    const materialIndex = this.ecoreElem.material.$ref.split(".").pop()!;
    const beamCssRef = this.ecoreElem.representation.base.base.$ref.split(".").pop()!;

    const { basePoint, rotation } = this.getPlacement(this.ecoreElem.placement);
    const param: columnParam = {
      storeyId: storey.id,
      name: this.ecoreElem.name,
      lnkObjIds: [],

      materialType: materialRefs[parseInt(materialIndex)],
      crossSectionId: cssIdsRefs[parseInt(beamCssRef)],

      heightType: heightType.STOREY,
      height: -this.ecoreElem.representation.direction.z!,
      orientation: this.getOrientation(),

      basePoint: { ...basePoint, z: basePoint.z + storey.level },
      rotation,
      offset: { x: 0, y: 0, z: 0 },
      scale: { x: 1, y: 1, z: 1 },
    }

    return this.getDataModel(objDataType.COLUMN, layerId, param);
  }

  private getOrientation(): number {
    const vecY = this.ecoreElem.representation.orientation;
    if (vecY) {
      const Vec = { x: vecY.x ?? 0, y: vecY.y ?? 0, z: vecY.z ?? 0 };
      return angleBetweenVectors(Vec, { x: 0, y: 1, z: 0 });
    }
    this.logger.warn("[COLUMN] Orientation not defined in ExtrudedRepresentation Ecore element")
    return 0;
  }

}

