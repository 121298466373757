import { DataFiles } from "lib/apis/struc-project/api";
import { downloadFiles } from "lib/models-struc/infrastructure/download-files";
import { useQueries } from "react-query";

export function useDownloadResources(DataFiles: DataFiles) {

  const res = useQueries([{
    queryKey: ["Analysis", DataFiles],
    queryFn: async () => { return downloadFiles.analysis(DataFiles.Analysis.Read) },
    enabled: !!DataFiles
  }, {
    queryKey: ["CameraSettings", DataFiles],
    queryFn: async () => { return downloadFiles.cameraConfig(DataFiles.CameraConfig.Read) },
    enabled: !!DataFiles
  }, {
    queryKey: ["Content", DataFiles],
    queryFn: async () => { return downloadFiles.content(DataFiles.Content.Read) },
    enabled: !!DataFiles,
  }, {
    queryKey: ["Hypothesis", DataFiles],
    queryFn: async () => { return downloadFiles.hypothesis(DataFiles.Hypothesis.Read) },
    enabled: !!DataFiles,
  }, {
    queryKey: ["Mesh", DataFiles],
    queryFn: async () => { return downloadFiles.mesh(DataFiles.Mesh.Read) },
    enabled: !!DataFiles,
  }, {
    queryKey: ["Planes", DataFiles],
    queryFn: async () => { return downloadFiles.planes(DataFiles.Planes.Read) },
    enabled: !!DataFiles,
  }, {
    queryKey: ["Sites", DataFiles],
    queryFn: async () => { return downloadFiles.sites(DataFiles.Sites.Read) },
    enabled: !!DataFiles,
  },
  {
    queryKey: ["BeamCrossSection", DataFiles],
    queryFn: async () => { return downloadFiles.beamCrossSection(DataFiles.BeamCrossSections.Read) },
    enabled: !!DataFiles,
  }, {
    queryKey: ["Dimension", DataFiles],
    queryFn: async () => { return downloadFiles.dimension(DataFiles.DimensionStyles.Read) },
    enabled: !!DataFiles,
  }, {
    queryKey: ["LineStyles", DataFiles],
    queryFn: async () => { return downloadFiles.lineStyles(DataFiles.LineStyles.Read) },
    enabled: !!DataFiles,
  }, {
    queryKey: ["ShellCrossSections", DataFiles],
    queryFn: async () => { return downloadFiles.shellCrossSections(DataFiles.ShellCrossSections.Read) },
    enabled: !!DataFiles,
  }, {
    queryKey: ["TextStyles", DataFiles],
    queryFn: async () => { return downloadFiles.textStyles(DataFiles.TextStyles.Read) },
    enabled: !!DataFiles,
  }, {
    queryKey: ["PileCapTemplates", DataFiles],
    queryFn: async () => { return downloadFiles.pileCapTemplates(DataFiles.PileCapTemplates.Read) },
    enabled: !!DataFiles,    
  }]);

  return {
    isLoading: res.some(r => r.isLoading),

    Analysis: res[0].data,
    CameraSettings: res[1].data,
    Content: res[2].data,
    Hypothesis: res[3].data,
    Mesh: res[4].data,
    Planes: res[5].data,
    Sites: res[6].data,

    beam_cross_section: res[7].data,
    dimension_styles: res[8].data,
    line_styles: res[9].data,
    shell_cross_section: res[10].data,
    text_styles: res[11].data,
    pile_cap_templates: res[12].data,
  }
}


