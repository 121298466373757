import * as THREE from "three";
import { getVertexFromIndex } from "lib/geometries/line";
import { getPointOnEllipse, ellipseBuffer } from "lib/math/ellipse";
import { rotatePoint } from "lib/math/rotate";
import { IPoint } from "lib/math/types";
import { EllipseData } from "lib/models/primitives/ellipse";
import { editEllipseParameters } from "lib/operations/edition/ellipse";
import { cadOpType } from "lib/operations/factory";
import { SelectionManager } from "../selection-manager";
import { ObjDataSelector, markBulkTypes } from "./selector-data";


export class EllipseDataSelector extends ObjDataSelector<EllipseData> {

  public override buildSelectMarks() {
    const { center, a, b, azimutO, plane } = this.data.definition;
    let points: IPoint[] = [];
    for (let i = 0; i < 4; i++) {
      const vertex = getPointOnEllipse(center, a, b, i * Math.PI * 0.5, azimutO);
      points.push(rotatePoint(vertex, plane.x, plane.y, plane.z, center));
    }
    // AAxis -> points[0] - points[2];
    // BAxis -> points[1] - points[3];
    const bulkData: markBulkTypes = {};
    bulkData.vertex = points;
    bulkData.center = [center];
    bulkData.line = [ellipseBuffer(center, a, b, azimutO, plane)];
    return bulkData;
  }

  public override launchMarkOP(selectionManager: SelectionManager, mark: THREE.Object3D, vertexIndex: number) {
    if (selectionManager.vertexSelector.pointObj === mark) {
      const param = vertexIndex === 0 || vertexIndex === 2 ? editEllipseParameters.SEMIMAJORAXIS : editEllipseParameters.SEMIMINORAXIS;
      return {
        type: cadOpType.EDITELLIPSE,
        graphicProcessor: this.graphicProc,
        args: [this.data, param, getVertexFromIndex(mark as THREE.Points, vertexIndex)],
      };
    }
    if (selectionManager.centerSelector.pointObj === mark) {
      return {
        type: cadOpType.MOVE,
        graphicProcessor: this.graphicProc,
        args: [[this.data], this.data.definition.center],
      };
    }
  }
}
