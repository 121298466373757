import * as THREE from "three";

export function groupAdd(group: THREE.Group, threeObj: THREE.Object3D) {
    group.add(threeObj);
}

export function groupCreate(threeObjs?: THREE.Object3D[]): THREE.Group {
    const group: THREE.Group = new THREE.Group();
    if (threeObjs) {
        for (let i: number = 0, l: number = threeObjs.length; i < l; i++) {
            const obj: THREE.Object3D = threeObjs[i];
            if (obj) {
                    groupAdd(group, obj);
            }
        }
    }
    return group;
}