import { ChangeEvent } from "react";
import { ChangeEventHandler, forwardRef, InputHTMLAttributes } from "react";
import ColorSelector from "../color/color-selector";


interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  defaultValue: string;
}

const PropertiesColor = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    id,
    label,
    onChange,
    defaultValue,
    ...rest
  } = props;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (onChange) {
      onChange(event);
    }
  }

  return (
    <div className="flex gap-2 items-center w-full">
      <label
        htmlFor={id}
        className={"text-xs text-gray-400 truncate text-right flex-1"}
      >
        {label}
      </label>
      <div className="relative flex items-center flex-1">
        <ColorSelector
          defaultValue={defaultValue}
          ref={ref}
          onChange={handleChange}
          {...rest} />
      </div>
    </div>
  );
});

export default PropertiesColor;