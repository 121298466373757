const RectangleIcon = ({ ...props }) => {
  return (
    <svg viewBox="0 0 18 18" {...props}>
      <path
        fill="currentColor"
        d="M1,2.5v13a.5.5,0,0,0,.5.5h15a.5.5,0,0,0,.5-.5V2.5a.5.5,0,0,0-.5-.5H1.5A.5.5,0,0,0,1,2.5ZM16,15H2V3H16Z"
      />
    </svg>
  );
};

export default RectangleIcon;
