import { BlockManager, blockRefParam } from "lib/blocks";
import { BlockRefEditDataCommand } from "lib/commands/edition/block";
import { linealPrecision } from "lib/general-settings";
import { BlockData } from "lib/models/block";
import { DataDefinitionHandler } from "./base";

type blockProp = blockRefParam & { name: string; };
export class BlockDataDefinitionHandler extends DataDefinitionHandler<blockProp, undefined, undefined, undefined> {

  protected data: BlockData;

  protected buildInfoProperties() {
    const def = this.data.definition;
    const blockParam = BlockManager.getBlockDefById(def.blockId);
    this.definitionInfo = {};
    if (blockParam) {
      this.definitionInfo = {
        name: this.getStringView(blockParam.name, "Name", false),
        position: this.getPointView(def.position, "Position", "m", linealPrecision),
        rotation: this.getPointAngleView(def.rotation, "Rotation"),
        scale: this.getPointView(def.scale, "Scale", "", linealPrecision, true, (scl: number) => scl > 0),
      }
    }
  }

  private checkNewDefinition(newDefinition: blockProp): boolean {
    if (!newDefinition) { return false; }
    if (!this.checkPoint(newDefinition.position)) { return false; }
    if (!this.checkPoint(newDefinition.rotation)) { return false; }
    if (!this.checkPoint(newDefinition.scale)) { return false; }
    return true;
  }

  private changedNewDefinition(oldDefinition: blockRefParam, newDefinition: blockProp): blockRefParam | null {
    let def = oldDefinition;
    let changed: boolean = false;
    if (newDefinition) {
      const position = this.changedPoint(def.position, newDefinition.position);
      if (position !== null) {
        def.position = position;
        changed = true;
      }
      const rotation = this.changedPointRotation(def.rotation, newDefinition.rotation);
      if (rotation !== null) {
        def.rotation = rotation;
        changed = true;
      }
      const scale = this.changedPoint(def.scale, newDefinition.scale);
      if (scale !== null) {
        def.scale = scale;
        changed = true;
      }
    }
    return changed ? def : null;
  }

  private checkAndChangedDefinition(oldDefinition: blockRefParam, newDefinition: blockProp): blockRefParam | null {
    let def = null;
    if (this.checkNewDefinition(newDefinition)) {
      def = this.changedNewDefinition(oldDefinition, newDefinition);
    }
    return def;
  }

  public saveAndRegenerate = (newDefinition: blockProp) => {
    const blockRefDefinition = this.checkAndChangedDefinition(this.data.cloneDefinition(), newDefinition);
    if (blockRefDefinition) {
      const command = new BlockRefEditDataCommand(this.data, blockRefDefinition, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  };
}