import { SlabEditDataCommand, SlabEditVertexDataCommand } from "lib/commands/structural/slab";
import { IPoint } from "lib/math/types";
import { slabParam } from "lib/models-struc/types/slab";
import { SlabData } from "lib/models/structural/slab";
import { linealPrecision } from "lib/general-settings";
import { ISolidMaterial } from "lib/materials";
import { shellCrossSectionCache } from "lib/models-struc/cross-sections-shape/shell-cross-sections/cache";
import { StructuralDataDefinitionHandler } from "./structural";
import { isFlatCSSParams, isSandwichCSSParams, isWaffleCSSParams } from "lib/models-struc/cross-sections-shape/shell-cross-sections/types";
import { waffleGeomGenerator } from "lib/models-struc/waffle/waffle-geometry";

export type slabProp = slabParam & {
  centroid: IPoint,
  waffleSlab: any,
  cog: IPoint
}

export class SlabDataDefinitionHandler extends StructuralDataDefinitionHandler<slabProp> {

  protected data: SlabData;

  protected buildInfoProperties() {
    super.buildInfoProperties();
    this.fillSlabDefinition(this.data);
  }

  private fillSlabDefinition(data: SlabData) {
    const def = data.definition;
    const flatCSSnameList: [string, string][] = [];
    const waffleCSSnameList: [string, string][] = [["None", ""]];
    const ShellCSSs = shellCrossSectionCache.getAllLoadedStyles().sort((a, b) => a.name.localeCompare(b.name));
    for (const shellCSS of ShellCSSs) {
      const param = shellCSS.parameters;
      if (isWaffleCSSParams(param) || isSandwichCSSParams(param)) {
        waffleCSSnameList.push([shellCSS.name, shellCSS.styleId]);
      } else if (isFlatCSSParams(param)) {
        flatCSSnameList.push([shellCSS.name, shellCSS.styleId]);
      }
    }

    this.structuralInfo.shellCrossSectionId = { publicName: "Shell cross section", type: "tagList", tagList: flatCSSnameList, value: def.shellCrossSectionId };
    this.structuralInfo.waffleShelCrossSectionId = { publicName: "Waffle cross section", type: "tagList", tagList: waffleCSSnameList, value: def.waffleShelCrossSectionId ?? "" };
    this.structuralInfo.depth = this.getNumberView(def.depth, "Depth", "m", linealPrecision, false);

    if (def.cog) {
      this.structuralInfo.cog = this.getPointView(def.cog, "COG", "m", linealPrecision, false);
    }
    if (waffleGeomGenerator.hasWaffleGeometry(def.name)) {
      this.structuralInfo.waffleSlab = { publicName: "show/hide waffle", type: "button", buttonCb: () => { this.data.showHideWaffle() } }
    }
  }

  private checkNewStructural(newStructural: slabProp) {
    if (!newStructural) { return false; }
    if (!this.checkStructuralNewDefinition(newStructural)) { return false; }
    if (!this.checkString(newStructural.shellCrossSectionId)) { return false; }
    if (!this.checkString(newStructural.waffleShelCrossSectionId)) { return false; }
    return true;
  }

  private changedSlabNewStructural(oldDefinition: slabParam, newDefinition: slabProp) {
    let def = oldDefinition;
    let changed: boolean = false;
    if (newDefinition) {
      const name = this.changedString(def.name, newDefinition.name);
      if (name !== null) {
        def.name = name;
        changed = true;
      }
      const slabType = this.changedString(def.shellCrossSectionId, newDefinition.shellCrossSectionId);
      if (slabType !== null) {
        def.shellCrossSectionId = slabType;
        changed = true;
        const shellCrossSection = shellCrossSectionCache.loadStylefromCache(def.shellCrossSectionId);
        if (shellCrossSection) def.depth = shellCrossSection.thickness;
      }
      const slabWaffleType = this.changedString(def.waffleShelCrossSectionId!, newDefinition.waffleShelCrossSectionId);
      if (slabWaffleType !== null) {
        def.waffleShelCrossSectionId = slabWaffleType ? slabWaffleType : undefined;
        changed = true;
      }
    }
    return (changed ? def : null);
  }

  public saveAndRegenerate = (newDefinition: slabProp) => {
    const slabDefinition = this.checkAndChangedDefinition(this.data.cloneDefinition(), newDefinition);
    if (slabDefinition) {
      const command = new SlabEditVertexDataCommand(this.data, slabDefinition, null, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }

  public saveAndRegenerateMaterial = (newMaterial: ISolidMaterial) => {
    const slabMaterial = this.checkAndChangedSolidMaterial(this.data.material, newMaterial);
    if (slabMaterial) {
      const command = new SlabEditDataCommand(this.data, null, slabMaterial, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }

  public saveAndRegenerateStruct = (newStructural: slabProp) => {
    if (this.checkNewStructural(newStructural)) {
      const slabStrucParam = this.changedSlabNewStructural(this.data.cloneDefinition(), newStructural);
      if (slabStrucParam) {
        const command = new SlabEditDataCommand(this.data, slabStrucParam, null, this.graphicProcessor);
        if (command) this.graphicProcessor.storeAndExecute(command);
      }
    }
  }
}
