import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div>
      Sorry... nothing here. <Link to="/cad/projects">Go home</Link>
    </div>
  );
}

export default NotFound;
