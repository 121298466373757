import * as THREE from "three";

export enum dimArrows { ARROW, CIRCLE, SLASH }

export const dimArrowsLabels: string[] = [];
dimArrowsLabels[dimArrows.ARROW] = "[►] Closed filled";
dimArrowsLabels[dimArrows.CIRCLE] = "[•] Dot";
dimArrowsLabels[dimArrows.SLASH] = "[/] Oblique";

let meshDimMat: THREE.MeshBasicMaterial | undefined;
let lineDimMat: THREE.LineBasicMaterial | undefined;

let arrowGeom: THREE.BufferGeometry | undefined;
let arrowGeomCentered: THREE.BufferGeometry | undefined;

let circleGeom: THREE.ShapeGeometry | undefined;
let circleGeomCentered: THREE.ShapeGeometry | undefined;

let slashGeom: THREE.BufferGeometry | undefined;
let slashGeomCentered: THREE.BufferGeometry | undefined;

export function getArrowSymbol(arrowId: dimArrows): THREE.Mesh | THREE.Line {
  if (meshDimMat === undefined) {
    meshDimMat = new THREE.MeshBasicMaterial({ color: "#FFFFFF", side: THREE.DoubleSide });
  }
  if (lineDimMat === undefined) {
    lineDimMat = new THREE.LineBasicMaterial({ color: "#FFFFFF" });
  }
  switch (arrowId) {
    case dimArrows.ARROW:
      return getArrowHelper(false, 0.5, meshDimMat);

    case dimArrows.CIRCLE:
      return getCircleHelper(true, 0.4, meshDimMat);

    case dimArrows.SLASH:
      return getSlashHelper(true, 1, lineDimMat);

    default:
      return getArrowHelper(false, 0.5, meshDimMat);
  }
}
export function updateArrowSymbol(dimGroup: THREE.Group, arrow: THREE.Mesh | THREE.Line, arrowId: number): void {
  const geomArrow = arrow.geometry;
  let styleGeomArrow;
  switch (arrowId) {
    case dimArrows.ARROW:
      styleGeomArrow = arrowGeom;
      break;
    case dimArrows.CIRCLE:
      styleGeomArrow = circleGeomCentered;
      break;
    case dimArrows.SLASH:
      styleGeomArrow = slashGeomCentered;
      break;
    default:
      styleGeomArrow = arrowGeom
  }
  if (geomArrow !== styleGeomArrow) {
    const childIndx = dimGroup.children.indexOf(arrow);
    dimGroup.remove(arrow);
    const newArrowSymbol = getArrowSymbol(arrowId);
    dimGroup.add(newArrowSymbol);
    dimGroup.children.splice(childIndx, 0, newArrowSymbol);
    dimGroup.children.splice(dimGroup.children.length - 1, 1);
  }
}

function getArrowHelper(centered?: boolean, size?: number, material?: THREE.MeshBasicMaterial): THREE.Mesh {
  if (size === undefined) size = 0.8;
  if (arrowGeom === undefined) {
    arrowGeom = new THREE.BufferGeometry();
    arrowGeom.setAttribute("position", new THREE.BufferAttribute(new Float32Array([0, 0, 0, size, size * 0.25, 0, size, -size * 0.25, 0]), 3));
    arrowGeom.computeBoundingBox();
    arrowGeom.computeBoundingSphere();
    arrowGeom.setDrawRange(0, 3);
  }
  if (arrowGeomCentered === undefined) {
    arrowGeomCentered = new THREE.BufferGeometry();
    arrowGeomCentered.setAttribute("position", new THREE.BufferAttribute(new Float32Array([-size * 0.5, 0, 0, size * 0.5, size * 0.25, 0, size * 0.5, -size * 0.25, 0]), 3));
    arrowGeomCentered.computeBoundingBox();
    arrowGeomCentered.computeBoundingSphere();
    arrowGeomCentered.setDrawRange(0, 3);
  }
  if (material) {
    return new THREE.Mesh(centered ? arrowGeomCentered : arrowGeom, material);
  } else {
    return new THREE.Mesh(centered ? arrowGeomCentered : arrowGeom, meshDimMat);
  }
}
function getCircleHelper(centered?: boolean, size?: number, material?: THREE.MeshBasicMaterial): THREE.Mesh {
  if (size === undefined) size = 0.6;
  if (circleGeom === undefined) {
    const shape: THREE.Shape = new THREE.Shape();
    shape.absellipse(size, 0, size, size, 0, Math.PI * 2, true, 0);
    circleGeom = new THREE.ShapeGeometry(shape);
  }
  if (circleGeomCentered === undefined) {
    const shape: THREE.Shape = new THREE.Shape();
    shape.absellipse(0, 0, size, size, 0, Math.PI * 2, true, 0);
    circleGeomCentered = new THREE.ShapeGeometry(shape);
  }
  if (material) {
    return new THREE.Mesh(centered ? circleGeomCentered : circleGeom, material);
  } else {
    return new THREE.Mesh(centered ? circleGeomCentered : circleGeom, meshDimMat);
  }
}
function getSlashHelper(centered?: boolean, size: number = 2.5, material?: THREE.LineBasicMaterial): THREE.Line {
  if (slashGeom === undefined) {
    slashGeom = new THREE.BufferGeometry();
    const coord = new Float32Array([0, 0, 0, size, size, 0]);
    slashGeom.setAttribute("position", new THREE.BufferAttribute(coord, 3));
    slashGeom.computeBoundingBox();
    slashGeom.computeBoundingSphere();
    slashGeom.setDrawRange(0, 2);
  }
  if (slashGeomCentered === undefined) {
    slashGeomCentered = new THREE.BufferGeometry();
    const coord = new Float32Array([-size * 0.5, -size * 0.5, 0, size * 0.5, size * 0.5, 0]);
    slashGeomCentered.setAttribute("position", new THREE.BufferAttribute(coord, 3));
    slashGeomCentered.computeBoundingBox();
    slashGeomCentered.computeBoundingSphere();
    slashGeomCentered.setDrawRange(0, 2);
  }
  if (material) {
    return new THREE.Line(centered ? slashGeomCentered : slashGeom, material);
  } else {
    return new THREE.Line(centered ? slashGeomCentered : slashGeom, lineDimMat);
  }
}
export function cleanDimensionArrowsCache() {
  meshDimMat?.dispose();
  meshDimMat = undefined;
  lineDimMat?.dispose();
  lineDimMat = undefined;
  arrowGeom?.dispose();
  arrowGeom = undefined;
  arrowGeomCentered?.dispose();
  arrowGeomCentered = undefined;
  circleGeom?.dispose();
  circleGeom = undefined;
  circleGeomCentered?.dispose();
  circleGeomCentered = undefined;
  slashGeom?.dispose();
  slashGeom = undefined;
  slashGeomCentered?.dispose();
  slashGeomCentered = undefined;
}