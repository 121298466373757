import { GraphicProcessor } from "lib/graphic-processor";
import { copyIPoint } from "lib/math/point";
import { IPoint } from "lib/math/types";
import { cadOpType } from "lib/operations/factory";
import { CadCommand } from "../base";
import { SlabData } from "lib/models/structural/slab";

export class SlabHoleCommand extends CadCommand {

  protected opType = cadOpType.SLABHOLE;
  protected slabData: SlabData;
  protected holes: IPoint[][];
  protected oldholes: IPoint[][] = [];

  constructor(slabElem: SlabData, holes: IPoint[][], graphicProc: GraphicProcessor) {
    super(graphicProc);
    this.slabData = slabElem;
    this.holes = holes;
    const defHoles = this.slabData.definition.holes;
    for (const hole of defHoles) {
      this.oldholes.push(hole.map(h => copyIPoint(h)));
    }
  }

  public execute(): void | Promise<void> {
    const defHoles = this.slabData.definition.holes;
    defHoles.length = 0;
    for (const hole of this.holes) {
      if (hole.length > 2)
        defHoles.push(hole);
    }
    this.applyHoles();
  }
  public unExecute(): void | Promise<void> {
    this.slabData.definition.holes = this.oldholes;
    this.applyHoles();
  }
  private applyHoles() {
    this.slabData.regenerateDefinition();
  }
  public delete(): void {
    this.holes = [];
    this.oldholes = [];
    this.slabData = undefined!;
  }
}
