import * as THREE from "three";
import { EventManager } from "./event-manager";
import { GraphicProcessor } from "../graphic-processor";
import { isZero } from "../math/epsilon";
import { copyIPoint, getPolarPoint } from "../math/point";
import { IPoint } from "../math/types";
import { cadOpType } from "./factory";
import { SettingOpManager } from "./step-state-machine";
import { mouseWorkingPlane } from "../coordinates/working-plane";
import { OperationActionType } from "lib/events/operation";
import { userAngleToRad } from "lib/general-settings";
import { MouseCoordinatesAction } from 'lib/events/mouse-position';
import { MousePositionActionType } from '../events/mouse-position';
import { setAuxMaterial } from "lib/materials";

export abstract class Cad3dOp {

  public abstract opType: cadOpType;

  public get pubOpName() {
    return this.graphicProcessor.getOpName(this.opType);
  }
  public graphicProcessor!: GraphicProcessor;

  set GraphicProcessor(graphicProcessor: GraphicProcessor) {
    this.graphicProcessor = graphicProcessor;
    this.eventManager = new EventManager(this.graphicProcessor);
    this.settingsOpManager = new SettingOpManager(this.graphicProcessor);
  }

  public finished = false;

  public launchSelectOnFinish = true;

  public abstract start(): void;

  /* #################################################################### */
  /*                      MANEJO DEL STEPMANAGER                          */
  /* #################################################################### */

  public settingsOpManager: SettingOpManager;

  protected abstract iniSettingsOp(): void;

  /** Lanza EndCallback, pasa al siguiente paso y le hace el setUp */
  public setNextStep(indx?: number) {
    if (!this.finished) {
      this.settingsOpManager.goNextStep(indx);
    }
  }

  public dispatchStartOperation() {
    this.settingsOpManager.dispatch({
      type: OperationActionType.START_OPERATION,
      payload: { operation: this },
    });
  }
  public dispatchEndOperation() {
    this.settingsOpManager.dispatch({
      type: OperationActionType.END_OPERATION,
      payload: {},
    });
  }

  /* #################################################################### */
  /*                         MANEJO DE ESCENAS                            */
  /* #################################################################### */

  public getTempScene(): THREE.Scene {
    return this.graphicProcessor?.getAuxScene();
  }

  protected setAuxObj(threeObj: THREE.Object3D) {
    setAuxMaterial(threeObj);
  }

  public saveToTempScene(threeObj: THREE.Object3D): void {
    const scene = this.getTempScene();
    if (scene) {
      scene.add(threeObj);
    }
  }
  protected deleteFromTempScene(threeObj: THREE.Object3D): void {
    const scene = this.getTempScene();
    scene?.remove(threeObj);
  }
  protected clearTempScene(): void {
    const lyrMng = this.graphicProcessor.getSceneManager();
    lyrMng.clearTempScene();
  }

  public getCurrentSceneId() {
    return this.graphicProcessor.getLayerManager().currentLayer.id;
  }
  protected getCurrentLayer() {
    return this.graphicProcessor.getLayerManager().currentLayer;
  }

  /* #################################################################### */
  /*                          EVENTS REGISTER                             */
  /* #################################################################### */

  protected treEventNames: Set<
    "pointerup" | "pointermove" | "wheel" | "keyup" |
    "raycast" | "keypress" | "undoRedo"> = new Set();

  protected registerInputs(): void {
    if (this.treEventNames.has("pointerup")) return;
    this.treEventNames.add("pointerup");
    this.graphicProcessor.getMouseResolver().subscribe(this.handleMouseUp);
  }
  private handleMouseUp = (action: MouseCoordinatesAction): void => {
    if (action.type === MousePositionActionType.SAVE_MOUSE_COORDINATES) {
      this.addPoint(action.payload.point);
    }
  };

  protected registerUpdaters(): void {
    if (!this.treEventNames.has("pointermove")) {
      this.treEventNames.add("pointermove");
      this.graphicProcessor.getMouseResolver().subscribe(this.handleChangeLastPoint);
    }
  }
  private handleChangeLastPoint = (action: MouseCoordinatesAction) => {
    if (action.type === MousePositionActionType.CHANGE_MOUSE_COORDINATES) {
      this.changeLastPoint(action.payload.point);
    }
  };

  public eventManager!: EventManager;

  protected registerCancel(): void {
    if (this.treEventNames.has("keyup")) return;
    this.treEventNames.add("keyup");
    this.eventManager.connectCustomKeyEvent(
      this.handleRegisterCancel,
      "Escape"
    );
  }
  private handleRegisterCancel = () => {
    this.cancelOperation();
  };

  protected unRegisterInputs(): void {
    if (this.treEventNames.has("pointerup")) {
      this.graphicProcessor.getMouseResolver().unsubscribe(this.handleMouseUp);
      this.treEventNames.delete("pointerup");
    }
    if (this.treEventNames.has("pointermove")) {
      this.graphicProcessor.getMouseResolver().unsubscribe(this.handleChangeLastPoint);
      this.treEventNames.delete("pointermove");
    }
  }
  protected unRegister(): void {
    if (this.treEventNames.has("pointerup")) {
      this.graphicProcessor.getMouseResolver().unsubscribe(this.handleMouseUp);
    }
    if (this.treEventNames.has("pointermove")) {
      this.graphicProcessor.getMouseResolver().unsubscribe(this.handleChangeLastPoint);
    }
    if (this.treEventNames.has("keyup")) {
      this.eventManager.disconnectKeyEvent(this.handleRegisterCancel);
    }
    if (this.treEventNames.has("undoRedo")) {
      this.eventManager.disconnectKeyEvent(this.undoRedoAction);
    }
    this.treEventNames.clear();
  }

  public dispathSaveMouseCoordinates(): void {
    const mouseResolver = this.graphicProcessor.getMouseResolver();
    mouseResolver.dispatchSaveCoordinate();
  }

  /* #################################################################### */
  /*                 FUNCIONES DE MANEJO DEL PLANO ACTIVO                 */
  /* #################################################################### */

  public workingPlane: mouseWorkingPlane;

  protected initializeWorkingPlane() {
    if (this.workingPlane === undefined) {
      this.workingPlane = new mouseWorkingPlane(this.graphicProcessor);
    }
  }
  protected clearWorkingPlane() {
    const plnMngr = this.graphicProcessor.getPlaneManager();
    plnMngr.activePlane.locked = false;
    plnMngr.activePlane = plnMngr.mainPlane.clone();
    plnMngr.lastMainPosition = copyIPoint(plnMngr.activePlane.position);
    if (this.workingPlane) {
      this.workingPlane.clean();
      this.workingPlane = undefined!;
    }
  }

  /* #################################################################### */
  /*                            SNAP FUNCTIONS                            */
  /* #################################################################### */

  protected initializeSnap() {
    const coordManager = this.graphicProcessor.getMouseResolver();
    coordManager.pointsList.clear();
    coordManager.snap.initOperationSnap();
  }
  protected closeSnap() {
    const coordManager = this.graphicProcessor.getMouseResolver();
    coordManager.snap.stopOperationSnap();
  }

  /* #################################################################### */
  /*            FUNCIONES DE MANEJO DE COORDENADAS RECIBIBIDAS            */
  /* #################################################################### */

  /** Numero de puntos actuales.
   *
   * @type {number}
   */
  public numPoints: number = 0;

  public lastPoint: IPoint = { x: Infinity, y: Infinity, z: Infinity }; // Último punto insertado

  public lastTwoPoints: [IPoint | null, IPoint | null] = [null, null];

  get currPlane() {
    const planeManager = this.graphicProcessor.getPlaneManager();
    return planeManager.activePlane;
  }

  public setLastPoint() {
    console.warn("[CAD3D_OP] No hay función 'setLastPoint' definida");
  }
  public moveLastPoint(p: IPoint) {
    console.warn("[CAD3D_OP] No hay función 'moveLastPoint' definida");
  }

  private coordinateParsing(coordOrder: string) {
    if (coordOrder[0] === "@" && this.lastPoint.x !== Infinity) {
      coordOrder = coordOrder.replace("@", "");
      if (coordOrder.includes("<")) {
        // Polar Mode
        const order = coordOrder.split("<");
        if (order.length === 2) {
          const dist = parseFloat(order[0]);
          const angle = userAngleToRad(parseFloat(order[1]));
          if (!isNaN(angle)) {
            return getPolarPoint(this.lastPoint, angle, dist);
          }
        }
      } else {
        const order = coordOrder.split(",");
        if (order.length === 2 || order.length === 3) {
          const x = parseFloat(order[0]);
          const y = parseFloat(order[1]);
          const z = parseFloat(order[2] ?? 0);
          return {
            x: this.lastPoint.x + x,
            y: this.lastPoint.y + y,
            z: this.lastPoint.z + z,
          };
        }
      }
    } else {
      const order = coordOrder.split(",");
      if (order.length === 2 || order.length === 3) {
        const x = parseFloat(order[0]);
        const y = parseFloat(order[1]);
        const z = parseFloat(order[2] ?? 0);
        return { x, y, z };
      }
    }
  }
  public addPointFromExt(coordOrder: string) {
    const pto = this.coordinateParsing(coordOrder);
    if (pto && !isNaN(pto.x) && !isNaN(pto.y) && !isNaN(pto.z)) {
      this.addPoint(pto);
    }
  }

  /** Añadir un punto
   *
   * @memberof Cad3dOp
   */
  public addPoint = (position: IPoint): void => {
    if (position) {
      const { x, y, z } = position;
      this.numPoints++;
      let filteredPoint: IPoint = {
        x: isZero(x) ? 0 : x,
        y: isZero(y) ? 0 : y,
        z: isZero(z) ? 0 : z,
      };
      if (this.lastTwoPoints[1]) {
        this.lastTwoPoints[0] = copyIPoint(this.lastTwoPoints[1]);
      }
      this.lastPoint = copyIPoint(filteredPoint);
      this.lastTwoPoints[1] = this.lastPoint;

      this.setLastPoint();
    }
  };
  /** Mover ultimo punto
   *
   * @memberof Cad3dOp
   */
  public changeLastPoint = (position: IPoint) => {
    const { x, y, z } = position;
    let filteredPoint: IPoint = {
      x: isZero(x) ? 0 : x,
      y: isZero(y) ? 0 : y,
      z: isZero(z) ? 0 : z,
    };
    if (this.workingPlane) {
      this.workingPlane.movePlaneAuxHelperObj(filteredPoint);
    }
    this.moveLastPoint(filteredPoint);
  };

  /* #################################################################### */
  /*                    FUNCIONES DE UNDO/REDO LOCAL                      */
  /* #################################################################### */

  protected registerUndoRedo() {
    if (!this.treEventNames.has("undoRedo")) {
      this.eventManager.connectKeyEvent(this.undoRedoAction);
      this.treEventNames.add("undoRedo");
    }
  }
  protected unregisterUndoRedo() {
    if (this.treEventNames.has("undoRedo")) {
      this.eventManager.disconnectKeyEvent(this.undoRedoAction);
      this.treEventNames.delete("undoRedo");
    }
  }
  private undoRedoAction = (event: KeyboardEvent) => {
    if (event.ctrlKey) {
      switch (event.key) {
        case "z":
        case "Z":
          this.undo();
          break;
        case "y":
        case "Y":
          this.redo();
          break;
      }
    }
  }
  // Default unod/redo function. Overwrite in operations (polylines)
  public undo(): void {
    this.cancelOperation();
  }
  public redo(): void {
    this.cancelOperation();
  }

  /* #################################################################### */
  /*                      FUNCIONES DE FINALIZADO                         */
  /* #################################################################### */

  public abstract save(): void;

  // public endOperationCallback = <T extends CadCommand>(cmd: T) => {  }

  /**
   * Finaliza la operación
   *
   * @memberof Cad3dOp
   */
  public endOperation(): void {
    if (this.finished === false) {
      this.finished = true;
      this.clearTempScene();
      this.unRegister();
      this.clearWorkingPlane();
      this.closeSnap();
      this.dispatchEndOperation();
      if (this.launchSelectOnFinish) {
        this.graphicProcessor.finishOP();
      }
    }
  }
  /**
   * Cancela la operación
   *
   * @memberof Cad3dOp
   */
  public cancelOperation(): void {
    if (this.finished === false) {
      this.finished = true;
      this.unRegister();
      this.clearTempScene();
      this.clearWorkingPlane();
      this.closeSnap();
      this.dispatchEndOperation();
      if (this.launchSelectOnFinish) {
        this.graphicProcessor.cancelOP();
      }
    }
  }
}
