import { TreeView } from "shared/components/ui/tree/tree";
import { MenuCtx } from "shared/components/ui/context-menu/context-menu";
import { useStrucProjectTreeContextMenu } from "./hook/use-struc-tree-ctx-menu";

const idMenu = "ctxStrucMenu";

const StrucTreeMenu = (projectDataTree: any) => {

  const {
    projectTree,
    currentNodeId,
    expandedKeys,
    scrollOffset,
    setCurrentNode
  } = projectDataTree.projectDataTree;

  const {
    menuCtxItems,
    setMenuContextual,
  } = useStrucProjectTreeContextMenu();

  return (
    <MenuCtx setting={menuCtxItems} idMenu={idMenu}>
      <TreeView
        treeData={projectTree}
        currentNodeId={currentNodeId}
        expandedKeys={expandedKeys.current}
        scrollOffset={scrollOffset.current}
        setActiveNodeCb={setCurrentNode}
        setCurrentNodeCb={setMenuContextual}
        startDepth={1}
        nodeOptions={null}
      />
    </MenuCtx >
  );
};

export default StrucTreeMenu;
