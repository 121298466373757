import { GraphicProcessor } from "../../graphic-processor";
import { IObjData } from "../../models/objdata";
import { definitionType, materialType } from "../../models/types";
import { CadCommand } from "../base";

/** Edition of parametric properties object (geometry/material)
 *
 * @export
 * @abstract
 * @class EditDataCommand
 * @extends {CadCommand}
 */
export abstract class EditDataCommand extends CadCommand {

  protected objData: IObjData;
  private oldDefinition?: definitionType;
  private newDefinition?: definitionType;
  private oldMaterial?: materialType;
  private newMaterial?: materialType;

  constructor(obj: IObjData, newDefinition: definitionType | null, newMaterial: materialType | null, graphicProcessor: GraphicProcessor) {
    super(graphicProcessor);
    this.objData = obj;
    if (newDefinition) {
      this.oldDefinition = this.objData.cloneDefinition();
      this.newDefinition = newDefinition;
    }
    if (newMaterial) {
      this.oldMaterial = this.objData.cloneMaterial();
      this.newMaterial = newMaterial;
    }
  }
  public async execute() {
    this.editObj(this.newDefinition, this.newMaterial);
  }
  public async unExecute() {
    this.editObj(this.oldDefinition, this.oldMaterial);
  }

  private editObj(definition?: definitionType, material?: materialType) {
    if (definition) {
      this.objData.definition = { ...this.objData.definition, ...definition } as definitionType;
      this.objData.regenerateObjectFromDefinition();
    }
    if (material) {
      this.objData.material = material;
      this.objData.regenerateObjectFromMaterial(this.graphicProcessor);
    }
    const mdlMngr = this.graphicProcessor.getDataModelManager();
    mdlMngr.dispatchEditObjs([this.objData], this.opType);
  }

  public delete() {
    this.objData = undefined!;
    this.oldDefinition = undefined!;
    this.newDefinition = undefined!;
    this.oldMaterial = undefined!;
    this.newMaterial = undefined!;
  }
}


