import { ButtonHTMLAttributes } from "react";
import styled from "styled-components";
import LoadingDots from "../loading-dots";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  fullWidth?: boolean;
}

const Button = ({ children, loading, ...props }: Props) => {
  return (
    <Wrapper {...props}>
      {loading ? <LoadingDots /> : children}
    </Wrapper >
  );
};

const Wrapper = styled.button<Props>`
  display: flex ;
  gap: 4px;
  font-size: ${13 / 16}rem;
  color: var(--color-text);
  padding: 6px 24px;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius);
  font-weight: 350;
  width: ${(props) => props.fullWidth && "100%"};
  transition: all 0.2s;
  box-shadow: rgba(63, 63, 68, 0.05) 0 0 0 1px,
    rgba(63, 63, 68, 0.15) 0 1px 3px 0;
  &:disabled {
    opacity: 0.5;
    cursor: revert;
  }
`;

export const PrimaryButton = styled(Button)`
  color: var(--color-white);
  background-color: var(--color-primary);
  &:hover {
    background-color: var(--color-primary-dark);
  }
`;

export const SecondaryButton = styled(Button)`
  background-color: var(--color-gray-300);
  &:hover {
    background-color: var(--color-gray-400);
  }
`;

export const DangerButton = styled(Button)`
  color: var(--color-white);
  background-color: var(--color-red-200);
  &:hover {
    background-color: var(--color-red-300);
  }
`;

export const IconButton = styled.button`
  display: block;
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  text-align: left;
  font: inherit;
  color: inherit;
`;
