import { MeshGenParams } from "lib/models-struc/mesh/mesh";

export enum MeshActionType {
  LOAD_MESH_PROP,
  UPDATE_MESH_PARAMS,
  DOWNLOADED_MESH,
}

export type MeshAction = {
  type: MeshActionType.LOAD_MESH_PROP;
  payload: { params: MeshGenParams };
} | {
  type: MeshActionType.UPDATE_MESH_PARAMS;
  payload: { params: MeshGenParams };
} | {
  type: MeshActionType.DOWNLOADED_MESH;
  payload: {};
};