import { ISolidMaterial } from "lib/materials";
import { objDataType } from "lib/models/types";
import { PPrismData } from "lib/models/solids/pprism";
import { pprismParam } from "../../geometries/solid/pprism";
import { GraphicProcessor } from "../../graphic-processor";
import { IPoint } from "../../math/types";
import { cadOpType } from "../../operations/factory";
import { createCommand } from "./create-base";

export class PPrismCommand extends createCommand {
  
  public createdData: PPrismData;
  protected opType = cadOpType.PPRISM;
  protected objType = objDataType.PPRISM;
  protected param: pprismParam;
  protected material: ISolidMaterial;

  constructor(
    radius: number,
    height: number,
    sides: number,
    open: boolean,
    basePoint: IPoint,
    rotation: IPoint,
    offset: IPoint,
    scale: IPoint,
    layerId: string,
    graphicProc: GraphicProcessor,
    material: ISolidMaterial
  ) {
    super(layerId, graphicProc);
    this.param = {
      radius,
      height,
      sides,
      open,
      basePoint,
      rotation,
      offset,
      scale,
    };
    this.material = material;
  }
}
