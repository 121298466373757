import { Link } from "react-router-dom";
import { PrimaryButton } from "../ui/button/button";
import useSignIn from "./hooks/use-signin";

const SignInForm = () => {
  const { handleSubmit, ref, loading, error } = useSignIn();
  return (
    <form className="space-y-6" onSubmit={handleSubmit}>
      <div>
        <label
          htmlFor="username"
          className="block text-sm font-medium text-gray-700"
        >
          Username
        </label>
        <div className="mt-1">
          <input
            id="username"
            name="username"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            ref={ref}
          />
        </div>
      </div>
      <div>
        <label
          htmlFor="password"
          className="block text-sm font-medium text-gray-700"
        >
          Password
        </label>
        <div className="mt-1">
          <input
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            ref={ref}
          />
        </div>
        {error && (
          <p className="mt-3 text-sm text-red-600" id="code-error">
            {error}
          </p>
        )}
      </div>
      <div className="flex items-center space-x-2">
        <p className="block text-sm text-gray-900">Don't have an account?</p>
        <div className="text-sm">
          <Link
            to="/signup"
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >
            Sign up
          </Link>
        </div>
      </div>
      <div>
        <PrimaryButton type="submit" fullWidth loading={loading}>
          Sign in
        </PrimaryButton>
      </div>
    </form>
  );
};

export default SignInForm;
