import { addIpoint, copyIPoint, substractIpoint } from "lib/math/point";
import { IPoint } from "lib/math/types";
import { cadOpType } from "../factory";
import { settingsOpModes } from "../step-operations";
import { BeamData } from "lib/models/structural/beam";
import { Cad3dOp } from "../base";
import { cloneDataModel } from "lib/models/model-creator/datamodel-factory";
import { BeamEditDataCommand } from "lib/commands/structural/beam";

export class BeamMoveVertexOP extends Cad3dOp {

  public opType = cadOpType.EDITBEAM;
  protected objData: BeamData;
  protected auxBeam: BeamData;

  private indexToEdit: number;
  private basePoint: IPoint;
  private secondPoint: IPoint;

  constructor(data: BeamData, indexToEdit: number) {
    super();
    this.indexToEdit = indexToEdit;
    this.objData = data;
    this.basePoint = copyIPoint(data.definition.basePoint);
    this.secondPoint = addIpoint(this.basePoint, data.definition.points[1]);
  }

  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Edit vertex: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
    }]);
  }

  public async start() {
    this.iniSettingsOp();
    this.initializeSnap();
    this.initializeWorkingPlane();
    this.registerCancel();
    this.registerInputs();
    this.registerUpdaters();

    this.auxBeam = cloneDataModel(this.objData);    
    this.setAuxObj(this.auxBeam.graphicObj);
    this.saveToTempScene(this.auxBeam.graphicObj);

    const planeManager = this.graphicProcessor.getPlaneManager();
    planeManager.activePlane.position = this.indexToEdit === 0 ? this.basePoint : this.secondPoint;
    planeManager.activePlane.rotation = { x: 0, y: 0, z: 0 };
    planeManager.activePlane.locked = true;
  }

  public setLastPoint(): void {
    this.save();
    this.endOperation();
  }

  public moveLastPoint(pto: IPoint) {
    if (this.indexToEdit === 0) {
      // Move basePoint
      this.auxBeam.definition.points[1] = substractIpoint(this.secondPoint, pto);
      this.auxBeam.definition.basePoint = pto;
      this.auxBeam.regenerateDefinition();
    } else {
      // Move point 1
      this.auxBeam.definition.points[1] = substractIpoint(pto, this.basePoint);
      this.auxBeam.regenerateDefinition();
    }
  }

  public save() {
    const newDefinition = this.auxBeam.definition;
    const command = new BeamEditDataCommand(this.objData, newDefinition, null, this.graphicProcessor);
    this.graphicProcessor.storeAndExecute(command);
  }

  public cancelOperation(): void {
    if (!this.finished) {
      this.endOperation();
    }
  }
}