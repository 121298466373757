import { FC } from "react";
import cn from "classnames";
import CloseIcon from "../icons/close";
import RenderIf from "shared/components/ui/render-if";

interface Props {
  isSelected?: boolean;
  onSelect: () => void;
  onClose?: () => void;
}
const OpenFileTab: FC<Props> = ({
  isSelected,
  onSelect,
  onClose,
  children,
}) => {
  const rootClassName = cn(
    "px-2 flex items-center space-x-1 hover:text-white border-r border-gray-600",
    isSelected ? "bg-gray-800" : "text-gray-700 hover:text-gray-500"
  );
  const closeClassName = cn(
    "focus:outline-none text-xs flex items-center space-x-1 hover:text-white"
  );
  return (
    <div className={rootClassName}>
      <button
        onClick={onSelect}
        className={`focus:outline-none text-xs hover:text-white flex items-center space-x-1 ${
          isSelected ? "text-gray-100" : "text-gray-400"
        }`}
      >
        <span>{children}</span>
      </button>
      <RenderIf isTrue={!!onClose}>
        <button onClick={onClose} className={closeClassName}>
          <CloseIcon className="h-3 w-3" />
        </button>
      </RenderIf>
    </div>
  );
};

export default OpenFileTab;
