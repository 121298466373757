import { AnalysisProjectsLoadCombinationApi } from 'lib/apis/analysis-projects/api';
import { downloadFileFromS3 } from 'lib/apis/utils';
import { v4 as uuid } from 'uuid'
import { gunzipSync } from 'zlib';
import { analysisManager } from '../analysis/analysismodel-manager';
import { StoreyDriftDispatcher } from './storey-drift-dispatcher';

interface IcombinationsRes {
  resultType: string,
  combinationType: string,
  numComb: number,
  constrPhase: number,
}

export interface IcombinationItem {
  name: string,
  combination: IcombinationsRes[];
}

export class StoreyDriftRepository {

  private combinationList: Map<string, IcombinationItem> = new Map();

  public eventBus: StoreyDriftDispatcher = new StoreyDriftDispatcher();

  save(combinations: IcombinationItem) {
    const id = uuid();
    this.combinationList.set(id, combinations);
    this.eventBus.dispatchAddCombination(id, combinations);
  }
  delete(id: string) {
    if (this.combinationList.has(id)) {
      this.combinationList.delete(id);
      this.eventBus.dispatchDeleteCombination(id)
    }
  }
  search(name: string) {
    for (const combinationItem of this.combinationList.values()) {
      if (combinationItem.name === name) {
        return combinationItem;
      }
    }
  }
  getAll() {
    return this.combinationList;
  }

  // ----------------------------------------------

  async getLoadCombinations() {
    // const analysisId = "XXXXX-XXXXX-XXXXX-XXXXX"; 
    const analysisId = analysisManager.analysis.project?.Id;
    if (analysisId) {
      const g = new AnalysisProjectsLoadCombinationApi();
      const loadCombination = await g.analysisProjectsIdCombinationsGet(analysisId);
      return loadCombination.data;
    }
    return Promise.reject(new Error(`Object Not Found in Analysis Id: ${analysisId}`));
  }

  async getCombinationsFiles(name: string): Promise<string[][]> {
    // const analysisId = "XXXXX-XXXXX-XXXXX-XXXXX"; 
    const analysisId = analysisManager.analysis.project?.Id;
    if (analysisId) {
      const combIds: string[] = [];
      const combList = this.search(name)!;
      for (const comb of combList.combination) {
        const { resultType, combinationType, constrPhase, numComb } = comb;
        combIds.push(`${resultType}-${combinationType}-${constrPhase}-${numComb}`)
      }

      // Load combinations hardcoding
      // const combIds = ["displ_nodes-ULS-14-0", "displ_nodes-ULS-14-1", "fm_nodal_shells-ULS-14-1"];

      const g = new AnalysisProjectsLoadCombinationApi();
      const loadCombination = await g.analysisProjectsIdCombinationsFilesGet(analysisId, combIds);
      const files: string[][] = [];
      for (const data of loadCombination.data) {
        const { Name, Url } = data
        const arrayBuffer = await downloadFileFromS3(Url);
        if (arrayBuffer) {
          const stringData = gunzipSync(Buffer.from(arrayBuffer)).toString();
          files.push([Name, stringData]);
        }
      }
      return files;
    }
    return Promise.reject(new Error(`Object Not Found in Analysis Id: ${analysisId}`));
  }
}

export let storeyDriftRepo: StoreyDriftRepository;
export function initStoreyDriftRepo() {
  storeyDriftRepo = new StoreyDriftRepository();
  return storeyDriftRepo;
}