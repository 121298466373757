import * as THREE from "three";
import { gridGraphicSettings } from "./coordinates/plane";
import { mainPlaneGrid, mainPlaneLines } from "./styles/colors";


export const scuGridHelperDefault: gridGraphicSettings = {
  size: 100,
  division: 10, // Lines each 10 units
  colorCenterLine: mainPlaneLines,
  colorGrid: mainPlaneGrid,
};

export class scuMainGrid {

  private infinitumGH: THREE.Mesh | undefined;
  private axesHelper: THREE.AxesHelper | undefined;
  private scene: THREE.Scene;

  constructor(scene: THREE.Scene) {
    this.scene = scene;
    this.createGrid();
  }

  public createGrid() {
    // Las dimension de la casilla mayor del grid y sus subdivisiones.
    const size = scuGridHelperDefault.size;
    const division = scuGridHelperDefault.division;
    // const colorCenterLine = scuGridHelperDefault.colorCenterLine;
    // El color de la parrilla.
    // const colorGrid = scuGridHelperDefault.colorGrid;

    // La maxima distancia alcanzable al pintar.
    const maxDist = 10000;
    const color = new THREE.Color(scuGridHelperDefault.colorCenterLine);
    // Creamos el dmc infinitum grid helper, que ahora es miembro para estar disponible para activacion/desactivacion.
    this.infinitumGH = this.createInfinitumGrid(division, size, maxDist, color);
    this.scene.add(this.infinitumGH);

    // Quitamos la niebla, PORQUE invisibiliza las selecciones hechas a ciertas distancias y no te das cuenta de lo seleccionado.
    // Y un poco de niebla, siempre de la tonalidad del fondo.
    // this._scene.fog = new THREE.Fog(COLORS.sceneFog, 500, 10000);

    // this._renderer.clear();

    this.axesHelper = new THREE.AxesHelper(200);
    this.scene.add(this.axesHelper);
  }

  public deleteGrid() {
    if (this.axesHelper) {
      this.scene.remove(this.axesHelper);
      this.axesHelper.geometry.dispose();
      this.axesHelper.geometry = undefined!;
      (this.axesHelper.material as THREE.Material).dispose();
      this.axesHelper.material = undefined!;
      this.axesHelper = undefined;
    }
    if (this.infinitumGH) {
      this.scene.remove(this.infinitumGH);
      this.infinitumGH.geometry.dispose();
      this.infinitumGH.geometry = undefined!;
      (this.infinitumGH.material as THREE.Material).dispose();
      this.infinitumGH.material = undefined!;
      this.infinitumGH = undefined;
    }
  }

  public activateInfinitumGridHelper(onOff?: boolean): boolean {
    if (onOff === true || onOff === false) {
      if (this.infinitumGH) this.infinitumGH.visible = onOff;
    }
    // Como pudiera ser que aun no estuviera construido el grid y la infraestructura React necesita un valor, haremos esto.
    if (this.infinitumGH) {
      return this.infinitumGH.visible;
    }
    return true;
  }

  /**
   * Creamos un mesh que soporta un suelo infinito con estos 4 parametros: dimension menor, mayor, maxima distancia y color.
   * Todas las dimensiones se dan en metros.
   * Esta basado en un shader con precision float que visualmente a veces no da buenos resultados: https://github.com/Fyrestar/THREE.InfiniteGridHelper
   * El truco esta en coger un color de fondo con el que no haya demasiado contraste. Si ponemos blanco y negro hay mucho ruido de Moire y ademas
   * mucho ruido normal en deteriminados angulos de camara. Quizas sea porque internamente las operaciones del shader son en float???...
   * \ToDo: Poner las operaciones del shader en double, que no es tan trivial, aunque perfectamente posible...
   *
   * @export
   * @param {number} minSz Tamaño del grid pequeño.
   * @param {number} maxSz Tamaño del grid grande.
   * @param {number} distance Creo que es la maxima distancia barrida por la parrilla.
   * @param {THREE.Color} color
   * @returns {THREE.Mesh}
   */
  private createInfinitumGrid(minSz: number, maxSz: number, distance: number, color: THREE.Color): THREE.Mesh {

    // La geometria sera este simple plano.
    // Ancho (en X), profundo (en Y) y numero de segmentos a lo ancho y a lo profundo.
    const geometry = new THREE.PlaneGeometry(2, 2, 1, 1);

    // El material es la chicha del shader.
    const material = new THREE.ShaderMaterial({
      // Datos generales heredados del material.
      // Necesario para que se vea desde ambos lados. Con FrontSide solo se ve desde arriba, luego es correcta la orientacion.
      side: THREE.DoubleSide,
      // Esto debe ser activado para que se vea correctamente. Si lo quito queda mal.
      transparent: true,

      // Estos 2 son de cosecha propia...
      // Sin luces, que por defecto ya es asi, creo. Si lo activo cascan los shaders.
      // lights: false,
      // La version 3 no funciona.
      // glslVersion: THREE.GLSL1,
      // Para la transparencia.
      // opacity: 1 + 0*0.75,
      // ?
      // flatShading: true,

      // Datos propios del shader.
      // Recuerda que las uniforms entran tanto al vertexShader como al fragmentShader. Aqui van los parametros de entrada.
      uniforms: {
        uSize1: {
          value: minSz,
        },
        uSize2: {
          value: maxSz,
        },
        uColor: {
          value: color,
        },
        uDistance: {
          value: distance,
        },
      },

      // Y para el VSh. que es el primero en ejecutarse ponemos aqui el codigo.
      vertexShader: `           
      varying vec3 worldPosition;
      uniform float uDistance;
           
      void main() {
        // Multiplica la posicion del vertice (sacada de la geometria) por la distancia maxima.
        vec3 pos = position.xzy * uDistance;
        // Y le suma la posicion de la camara (world).
        pos.xz += cameraPosition.xz;
        // La pasamos al FSh.
        worldPosition = pos;
        // Esta es la posicion final del vertice (world) pero le pasa "pos" en vez de "position" (eso es lo que me despisto inicialmente).
        // Este es el truco para sacar algo mas grande que la geometria pequeñita inicial que solo era un quad 1x1.
        gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
      }
    `,

      // Aqui va el FSh., segundo en ejecutarse.
      // No permite el gl_PointSize.
      fragmentShader: `
      // Esta viene precalculada del vSh.
      varying vec3 worldPosition;
      // Tamaño pequeño y grande del grid.
      uniform float uSize1;
      uniform float uSize2;
      // El color impuesto.
      uniform vec3 uColor;
      // La maxima distancia, que en realidad no es infinito.
      uniform float uDistance;
                  
      float getGrid(float size) {
        // Vector con los ratios entre las coordenadas X y Z y la arista del grid.
        vec2 r = worldPosition.xz / size;
        // fwidth(p) solo disponible para el FSh. devuelve la suma de los valores absolutos de las derivadas en X e Y del vector dado p,
        // usando diferencias locales. Es equivalente a abs(dFdx(p)) + abs(dFdy(p))
        // dFd*() => these functions return the partial derivative of expression p with respect to the window * coordinate...
        // This is the total derivative for the function DF = dF/dx*dx + dF/dy*dy using the 8 surrounding pixels in the 3x3 neighborhood.
        vec2 grid = abs(fract(r - 0.5) - 0.5) / fwidth(r);
        float line = min(grid.x, grid.y);
                
        return 1.0 - min(line, 1.0);
      }
            
      void main() {
        // distance(a, b) devuelve la distancia entre los puntos dados.
        float d = 1.0 - min(distance(cameraPosition.xz, worldPosition.xz) / uDistance, 1.0);
        float g1 = getGrid(uSize1);
        float g2 = getGrid(uSize2);

        // mix(a, b, x) hace una interpolacion lineal entre los 2 valores a y b usando c, dando el valor a * (1 - x) + b * x, es decir,
        // la lambda-interpolacion que usamos masivamente. Si x es 0 entonces devuelve a y si es 1 devuelve b...
        // Aparece 2 veces g1 para darle mas preponderancia al mayor???. Aqui hay truco???.
        // Damos el color inicial alterado con la transparencia donde hace el truco ese.
        gl_FragColor = vec4(uColor.rgb, mix(g2, g1, g1) * pow(d, 3.0));

        // Y vuelve a modificar la transparencia.
        // Con el valor maximo 1.0 es TODO un plano BLANCO nevado, totalmente opaco sin transparencia, sin dejar ver nada al
        // otro lado, mientras que con 0.0 es todo un plano NEGRO por culpa de la transparencia total que deja ver el fondo.
        // Asi que hay que limitar la transparencia para que solo sea por la parte en que no toca grid...
        // El problema esta en no joder la marrana con el ruido...
        gl_FragColor.a = mix(0.5 * gl_FragColor.a, gl_FragColor.a, g2);

        // discard le dice al FSh. que no escriba ningun pixel, aka que lo descarte: Esos son los huecos del grid!!!.
        if (gl_FragColor.a <= 0.0) {
          discard;
        }
      }
    `,

      extensions: {
        derivatives: true,
      },
    });

    const mesh = new THREE.Mesh(geometry, material);
    mesh.frustumCulled = false;
    mesh.name = "InfinitumGH";

    // Para colocarlo sobre el plano XY lo rotamos.
    mesh.rotation.x = -Math.PI / 2;

    return mesh;
  }
}