import { getEcorePlacement, getEcoreVector3 } from "../locations";
import { getEcoreClosedWireGeometry } from "../geometry";
import { baseUriModel } from "../mesh-exporter";
import { IPoint } from "lib/math/types";
import { ExtrudedRepresentation, GeoRepresentation, Representation as EcoreRepresentation } from "modules/struc/models/ecore/representation";
import { Vector } from "modules/struc/models/ecore/location";
import { EcoreStrucElemBase } from "../struc-elem-base";
import { FlatSlab, WaffleSlab } from "modules/struc/models/ecore/structural";
import { FEMSEComp, FEMStructuralElement, PhyStructuralElement, ShellStructuralElement } from "modules/struc/models/ecore/mesh";
import { waffleGeomGenerator } from "lib/models-struc/waffle/waffle-geometry";
import { SlabData } from "lib/models/structural/slab";
import { getEcoreFloat } from "../helper-ecore";
import { WireGeometry } from "modules/struc/models/ecore/geometry";
import { representationUriModel, structuralUriModel } from "modules/struc/models/ecore/uri";

export class EcoreSlabElem extends EcoreStrucElemBase {

  private strucElem: SlabData;
  private crossSectionRef: number;
  private waffleCrossSectionRef: number | undefined;

  setStrucElem(strElem: SlabData, crossSectionShapesIds: string[]) {
    this.strucElem = strElem;
    const strucDefinition = this.strucElem.definition;
    this.crossSectionRef = crossSectionShapesIds.indexOf(strucDefinition.shellCrossSectionId);
    if (strucDefinition.waffleShelCrossSectionId) {
      this.waffleCrossSectionRef = crossSectionShapesIds.indexOf(strucDefinition.waffleShelCrossSectionId);
    }
  }

  exportToEcore(materialRef: number): FlatSlab | WaffleSlab {
    const repr = this.strucElem.definition;
    if (this.waffleCrossSectionRef) {
      const flatSlabEcore: WaffleSlab = {
        eClass: `${structuralUriModel}WaffleSlab`,
        id: this.strucElem.id,
        name: repr.name,
        material: this.getEcoreMaterialRef(materialRef),
        placement: getEcorePlacement({ ...repr.basePoint, z: 0 }),
        representation: this.getRepresentation(),
        css: this.getEcoreCrossSectionRef(this.crossSectionRef),
        wafflecss: this.getEcoreCrossSectionRef(this.waffleCrossSectionRef),
      }
      return flatSlabEcore;
    } else {
      const flatSlabEcore: FlatSlab = {
        eClass: `${structuralUriModel}FlatSlab`,
        id: this.strucElem.id,
        name: repr.name,
        material: this.getEcoreMaterialRef(materialRef),
        placement: getEcorePlacement({ ...repr.basePoint, z: 0 }),
        representation: this.getRepresentation(),
        css: this.getEcoreCrossSectionRef(this.crossSectionRef),
      }
      return flatSlabEcore;
    }
  }
  private getRepresentation(): ExtrudedRepresentation<GeoRepresentation<WireGeometry>> {
    const repr = this.strucElem.definition;
    const direction = { x: 0, y: 0, z: -repr.depth };
    return {
      eClass: `${representationUriModel}ExtrudedRepresentation`,
      placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
      direction: this.getEcoreDirection(),
      base: {
        eClass: `${representationUriModel}GeoRepresentation`,
        placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
        base: getEcoreClosedWireGeometry(repr.ptos2D),
      },
      substractions: this.getEcoreSubstractionsSlab(repr.holes, direction),
    }
  }

  exportFemStructuralElementToEcore(storeyRef: number, elemRef: number): FEMStructuralElement | FEMSEComp {
    if (this.waffleCrossSectionRef === undefined) {
      return this.exportSimpleShellStructuralElement(storeyRef, elemRef);
    } else {
      return this.exportCompoundShellStructuralElement(storeyRef, elemRef);
    }
  }
  private exportSimpleShellStructuralElement(storeyRef: number, elemRef: number) {
    const femStr: ShellStructuralElement = {
      eClass: baseUriModel + "mesh/ShellStructuralElement",
      structuralelement: {
        eClass: baseUriModel + "structural/FlatSlab",
        $ref: `//@versions.0/@building/@storeys.${storeyRef}/@elements.${elemRef}`,
      },
      section: {
        eClass: baseUriModel + "mesh/CrossSection",
        placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
        shape: this.getEcoreCrossSectionRef(this.crossSectionRef),
      },
      orientation: getEcoreVector3({ x: 0, y: 0, z: 0 }),
      representation: this.getFEMStrcElemRepresentation(),
    };
    return femStr;
  }
  private exportCompoundShellStructuralElement(storeyRef: number, elemRef: number) {
    const elements: PhyStructuralElement[] = [];
    const strucDefinition = this.strucElem.definition;
    const name = strucDefinition.name
    const waffle = waffleGeomGenerator.getWaffleGeometry(name);
    if (waffle) {
      const flatSlabs = waffle.SolidGeo.map((geomRepr) => {
        const shell: ShellStructuralElement = {
          eClass: baseUriModel + "mesh/ShellStructuralElement",
          section: {
            eClass: baseUriModel + "mesh/CrossSection",
            placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
            shape: this.getEcoreCrossSectionRef(this.crossSectionRef),
          },
          orientation: getEcoreVector3({ x: 0, y: 0, z: 0 }),
          representation: geomRepr,
        };
        return shell;
      });
      const waffleSlabs = waffle.WaffleGeo.map((geomRepr) => {
        const shell: ShellStructuralElement = {
          eClass: baseUriModel + "mesh/ShellStructuralElement",
          section: {
            eClass: baseUriModel + "mesh/CrossSection",
            placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
            shape: this.getEcoreCrossSectionRef(this.waffleCrossSectionRef!),
          },
          orientation: getEcoreVector3({ x: 0, y: 0, z: 0 }),
          representation: geomRepr,
        };
        const eqVol = getEcoreFloat(waffle.EquiVolume, 0);
        if (eqVol !== undefined) shell.section.equiVolume = eqVol;
        return shell;
      });
      elements.push(...flatSlabs, ...waffleSlabs);

    } else {
      //console.assert(false, "[Ecore Export] Waffle geometry not generated")
      const flatSlab: ShellStructuralElement = {
        eClass: baseUriModel + "mesh/ShellStructuralElement",
        section: {
          eClass: baseUriModel + "mesh/CrossSection",
          placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
          shape: this.getEcoreCrossSectionRef(this.crossSectionRef),
        },
        orientation: getEcoreVector3({ x: 0, y: 0, z: 0 }),
        representation: this.getFEMStrcElemRepresentation(),
      }
      const waffleSlab: ShellStructuralElement = {
        eClass: baseUriModel + "mesh/ShellStructuralElement",
        section: {
          eClass: baseUriModel + "mesh/CrossSection",
          placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
          shape: this.getEcoreCrossSectionRef(this.waffleCrossSectionRef!),
        },
        orientation: getEcoreVector3({ x: 0, y: 0, z: 0 }),
        representation: this.getFEMStrcElemRepresentation(),
      }
      elements.push(flatSlab, waffleSlab)
    }
    const femStr: FEMSEComp = {
      eClass: `${baseUriModel}mesh/FEMSEComp`,
      structuralelement: {
        eClass: baseUriModel + "structural/WaffleSlab",
        $ref: `//@versions.0/@building/@storeys.${storeyRef}/@elements.${elemRef}`,
      },
      elements,
    }
    return femStr;
  }

  private getFEMStrcElemRepresentation(): GeoRepresentation<WireGeometry> {
    const repr = this.strucElem.definition;
    const holes: GeoRepresentation<WireGeometry>[] = [];
    for (const hole of repr.holes) {
      holes.push({
        eClass: `${representationUriModel}GeoRepresentation`,
        placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
        base: getEcoreClosedWireGeometry(hole),
      })
    }
    const geoRepr: GeoRepresentation<WireGeometry> = {
      eClass: `${representationUriModel}GeoRepresentation`,
      placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
      base: getEcoreClosedWireGeometry(repr.ptos2D),
    }
    if (holes.length) geoRepr.substractions = holes;
    return geoRepr;
  }


  getEcoreDirection(): Vector {
    const repr = this.strucElem.definition;
    const direction = { x: 0, y: 0, z: -repr.depth };
    return getEcoreVector3(direction);
  }

  private getEcoreSubstractionsSlab(holes: IPoint[][], direction: IPoint): EcoreRepresentation[] | undefined {
    const substractions: EcoreRepresentation[] = [];
    for (let hole of holes) {
      const ecoreHole: ExtrudedRepresentation<GeoRepresentation<WireGeometry>> = {
        eClass: `${representationUriModel}ExtrudedRepresentation`,
        placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
        direction: getEcoreVector3(direction),
        base: {
          eClass: `${representationUriModel}GeoRepresentation`,
          placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
          base: getEcoreClosedWireGeometry(hole),
        },
      }
      substractions.push(ecoreHole);
    }
    if (substractions.length) {
      return substractions;
    }
  }
}