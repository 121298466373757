/**
 * \file xyzmo_rcoh8.ts
 * Implementacion de la clase XYZmo_RCOH8, nuestro propio gizmo rotacional basado en un solido arquimediano conocido
 * como rhombiCubeOctahedron/rombicubooctaedro.
 * Vease: https://en.wikipedia.org/wiki/Rhombicuboctahedron
 * 
 * Esta figura 3D consta de 26 caras en las que tenemos:
 *
 * [1] Caras primarias: 6 grandes quads para seleccionar las 6 direcciones principales:
 *      +X, +Y, +Z,
 *      -X, -Y, -Z
 *
 * [2] Caras secundarias: 12 quads menores (entre las 6 caras principales) para seleccionar las 12 direcciones secundarias,
 *     que son las que estan entre 2 direcciones principales (logicamente contiguas):
 *      +X+Y  -X+Y  +Y+Z
 *      +X+Z  -X+Z  +Y-Z
 *      +X-Y  -X-Y  -Y+Z
 *      +X-Z  -X-Z  -Y-Z
 *
 * [3] Caras terciarias: 8 triangulos (cada uno entre 3 de las caras principales) para seleccionar las 8 direcciones
 *     terciarias, que son las que estan entre 3 direcciones principales (logicamente contiguas):
 *      +X+Y+Z
 *      +X+Y-Z
 *      +X-Y+Z
 *      +X-Y-Z
 *      -X+Y+Z
 *      -X+Y-Z
 *      -X-Y+Z
 *      -X-Y-Z
 *
 * La gracia de esta figura esta en lo facil que es seleccionar caras o pasar a las adyacentes.
 * Incluso las perspectivas isometricas son inmediatas de seleccionar.
 * Por eso lo usan ACad y otros...
 */

import { GraphicViewport } from "lib/graphic-viewport";
import * as THREE from "three";
import { CameraControls } from "./camera-controls/CameraControls";
import { SceneManager } from '../layers/scene-manager';

// Tipo de datos donde metemos vectores direccion, vectores rotacion, angulos alpha y beta y lo que podamos necesitar
// para centralizar todos los datos implicitos para las rotaciones 3D del gyzmo.
type TInfo4Orientation3D = {
  // Vector direccion correspondiente. Siempre unitario.
  dir: THREE.Vector3;
  // Vector rotacion para llegar a esta direccion a partir de +X.
  rot: THREE.Vector3;
  // Angulo horizontal en el plano XY para llegar a esta direccion a partir de un hipotetico -Y en sentido CCW.
  alpha: number;
  // Angulo vertical con respecto al hipotetico eje +Z que seria el 0.
  beta: number;
  // El nombre del eje 3D implicado.
  axis: string;
};

/**
 * Nuesta propia implementacion de un gizmo rotacional, pero ahora usando un ROMBICUBEOCTAHEDRO, que es un solido de tipo
 * arquimediano que nos facilita la eleccion del punto de vista.
 * En esta version el gizmo se mueve solidariamente con la escena y admite doble click para poner la vista desde la cara
 * pulsada. Ademas suprimimos la animacion previa, que reduce el rendimiento.
 * Lo que NO admite es lo inverso: mover el gizmo y que la escena se mueva con solidaridad.
 * Suprimimos el panel y simplemente damos una esquina (x0, y0) y un tamaño del lado para crear el cuadrado hipotetico donde se pinta.
 * \ToDo: Esto de abajo aun no esta.
 * Ademas por peticion de JaWS hacemos lo mismo que en Blender: Si pulsas otra vez en algo en lo que ya estas automaticamente te vas
 * al opuesto. Es decir que pulso en la +Z y me voy a la misma. Luego vuelvo a pulsar en la misma y me iria automaticamente a -Z.
 */
export class XYZmo_RCOH8 {

  /** Camara externa, la del editor exterior, necesaria para hacerla rotar y cambiar de angulo. */
  externCamera: THREE.Camera;
  /**
   * Coordenadas de la esquina superior izquierda del hipotetico cuadro del gizmo, referidas a un origen (0, 0) en la
   * esquina superior izquierda. Se extenderan hasta un maximo de las dimensiones limite del canvas contenedor menos
   * nuestas dimensiones,
   */
  x0: number;
  y0: number;
  /** Dimensiones del panel donde metemos el gizmo. */
  dimXY: number;
  /** Panel contenedor donde metemos al panel del gizmo. */
  parentContainer: HTMLDivElement;

  /** La posicion del raton. */
  mouse: THREE.Vector2;
  /** La camara local que aqui se usa internamente para la visualizacion. */
  camera: THREE.OrthographicCamera;

  /**
   * Objecto grafico con el grupo de todos los ejes, el mesh con el RCO8H y las 8 caras con las texturas.
   * Esto es lo unico que se ve graficamente desde el exterior.
   */
  gObj: THREE.Group;

	/**
   * Lo unico que interviene en colisiones y ademas lleva la geometria 3D real.
   * De esta forma se simplifica mucho el calculo de intersecciones.
   */
	private mesh: THREE.Mesh;

  /**
   * Los nombres de las TODAS las 44 caras, repitiendo por las facetas quads pasadas a tris.
   * Hay 44 por las 26 caras del RCOH8 que ponemos como 8 pequeñas caras cuadradas (las de los 8 vertices) mas las otras
   * 12 + 6 = 18 caras restantes, (aristas + caroplones), que descomponemos en 2 triangulos, dando 8 + 2 * 18 = 44.
   */
	private static readonly vFacesNames = [
		"+X", "+X",
		"+X+Y", "+X+Y",
		"+Y", "+Y",
		"-X+Y", "-X+Y",
		"-X", "-X",
		"-X-Y", "-X-Y",
		"-Y", "-Y",
		"+X-Y", "+X-Y",
		"+Z", "+Z",
		"-Z", "-Z",
		"+X+Y+Z",
		"-X+Y+Z",
		"-X-Y+Z",
		"+X-Y+Z",
		"+X+Y-Z",
		"-X+Y-Z",
		"-X-Y-Z",
		"+X-Y-Z",
		"+X+Z", "+X+Z",
		"+Y+Z", "+Y+Z",
		"-X+Z", "-X+Z",
		"-Y+Z", "-Y+Z",
		"+X-Z", "+X-Z",
		"+Y-Z", "+Y-Z",
		"-X-Z", "-X-Z",
		"-Y-Z", "-Y-Z", // 42, 43
	];

  /** Las localizaciones de la camara para cada cara de las 44 anteriores. */
	private static readonly vCamLocs = [
		[+1.0, +0.0, +0.0], [+1.0, +0.0, +0.0],
		[+1.0, +1.0, +0.0], [+1.0, +1.0, +0.0],
		[+0.0, +1.0, +0.0], [+0.0, +1.0, +0.0],

		[-1.0, +1.0, +0.0], [-1.0, +1.0, +0.0],
		[-1.0, +0.0, +0.0], [-1.0, +0.0, +0.0],
		[-1.0, -1.0, +0.0], [-1.0, -1.0, +0.0],
		[+0.0, -1.0, +0.0], [+0.0, -1.0, +0.0],
		[+1.0, -1.0, +0.0], [+1.0, -1.0, +0.0],
		[+0.0, +0.0, +1.0], [+0.0, +0.0, +1.0],
		[+0.0, +0.0, -1.0], [+0.0, +0.0, -1.0],
		[+1.0, +1.0, +1.0],
		[-1.0, +1.0, +1.0],
		[-1.0, -1.0, +1.0],
		[+1.0, -1.0, +1.0],
		[+1.0, +1.0, -1.0],
		[-1.0, +1.0, -1.0],
		[-1.0, -1.0, -1.0],
		[+1.0, -1.0, -1.0],
		[+1.0, +0.0, +1.0], [+1.0, +0.0, +1.0],
		[+0.0, +1.0, +1.0], [+0.0, +1.0, +1.0],
		[-1.0, +0.0, +1.0], [-1.0, +0.0, +1.0],
		[+0.0, -1.0, +1.0], [+0.0, -1.0, +1.0],
		[+1.0, +0.0, -1.0], [+1.0, +0.0, -1.0],
		[+0.0, +1.0, -1.0], [+0.0, +1.0, -1.0],
		[-1.0, +0.0, -1.0], [-1.0, +0.0, -1.0],
		[+0.0, -1.0, -1.0], [+0.0, -1.0, -1.0] // "-Y-Z"	42, 43
	];

  /** Al final metemos todo en una escena, tanto el cubo como los ejes. */
  scene: THREE.Scene;

  /** Un colisionador para saber que componente tocamos con el raton. */
  raycaster: THREE.Raycaster;
  /** Posicion, angulo y radio de giro que se aplicaran a la camara externa para que sufra lo mismo que el gizmo. */
  targetPosition: THREE.Vector3;
  targetQuaternion: THREE.Quaternion;
  radius: number;
  /**
   * La posicion del centro exterior en torno a la que giraremos. Ese sera el punto focal al que siempre miremos en las animaciones.
   * \ToDo: Dar infraestructura para que sea externamente seleccionable, si es que es necesario.
   */
  externCenter: THREE.Vector3;
  /** Un par de quaterniones creo que para angulo inicial y final con la animacion. */
  q1: THREE.Quaternion;
  q2: THREE.Quaternion;

  /** Cadena con el ultimo eje seleccionado en curso. Si aun no lo hubiera sera cadena vacia. */
  selectedAxis: string;

  /** El viewport paterno es necesario para cuando se haga click sobre algun pseudoBoton del gizmo, para transmitirle la orden. */
  parentViewport: GraphicViewport;

  /** Andamiaje para poder utilizar la infraestructura de rotacion del CameraControls del viewport paterno. */
  useCameraControls: boolean;

  /**
   * Para poder activar y reactivar facilmente el calculo de intersecciones almacenamos la funcion que la calcula, para
   * poder reasignarla o anularla cuando nos convenga.
   */
  prevRayCastFunction: (raycaster: THREE.Raycaster, intersects: THREE.Intersection[]) => void;

  /**
   * Para detectar cuando hay cambios en la direccion de observacion y reducir bastantes calculos tenemos un vector
   * direccion actual y otro con la direccion previa.
   */
  currentDirectionVect: THREE.Vector3;
  prevDirectionVect: THREE.Vector3;

  /**
   * Un mapa para indexar las diferentes direcciones posibles -X, +X+Y+Z, etc... que son todo vectores unitarios.
   * Me serviran para acelerar y simplificar calculos. Al construir el objeto llamamos a createOrientationInfo().
   * Ademas la informacion de orientacion la tengo como mapa y como vector para optimizar el acceso.
   */
  private static mOrientInfo: Map<string, TInfo4Orientation3D>;
  private static vOrientInfo: TInfo4Orientation3D[];
  private static initOrient: boolean = XYZmo_RCOH8.createOrientationInfo();

  /**
   * Constructor con la infraestructura minima, despues de la cual hay que llamar al init() con sus parametros.
   * La idea es reducir al maximo las nuevas creaciones.
   * 'useCameraControls' permite usar la rotacion externa mediante la infraestructura del propio viewport. En caso
   * contrario usaremos la nuestra propia.
   *
   * @param {GraphicViewport} parentViewport
   * @param {boolean} [useCameraControls=false]
   * @memberof XYZmo
   */
  constructor(parentViewport: GraphicViewport, useCameraControls: boolean = false) {

    // Guardate a tu padre, que te hara falta...
    this.parentViewport = parentViewport;

    // Este flag permite usar la infraestructura del cameraControls del viewport paterno para ejecutar la animacion de rotacion.
    this.useCameraControls = useCameraControls;

    const near = 0;
    const far = 10;
    const zPos = 3.5 - 0.25;
    const dim = 4.5 - 0.5;
    this.camera = new THREE.OrthographicCamera(-dim, dim, dim, -dim, near, far);
    // Aqui colocamos la camara, desde +Z mirando al plano XY desde +Z.
    this.camera.position.set(0, 0, zPos);

    // Esta es la maldad grafica, donde formaremos el grupo gObj con el que compondremos todo el trampantojo.
    this.builtGraphicGyzmo();

  }

  // Dado un nombre de eje de la forma "+X+Y+Z" le cambia los signos.
  private static readonly negateAxisName = (name: string): string => {
    const len = name.length;
    let res = "";
    for (let i = 0; i < len; ++i) {
      if (name[i] === "-") {
        res += "+";
      } else {
        if (name[i] === "+") {
          res += "-";
        } else {
          res += name[i];
        }
      }
    }
    return res;
  };

  /**
   * Rellena la tabla de vectores unitarios empleados para las direcciones.
   */
  private static createOrientationInfo(): boolean {
    // Si ya esta llena no la tocamos.
    if (XYZmo_RCOH8.mOrientInfo) {
      return true;
    }

    const vNames = [
      "+X",     //  [0]
      "+Y",     //  [1]
      "+Z",     //  [2]
      "-X",     //  [3]
      "-Y",     //  [4]
      "-Z",     //  [5]
      "+X+Y",   //  [6]
      "+X-Y",   //  [7]
      "+X+Z",   //  [8]
      "+X-Z",   //  [9]
      "-X+Y",   // [10]
      "-X-Y",   // [11]
      "-X+Z",   // [12]
      "-X-Z",   // [13]
      "+Y+Z",   // [14]
      "+Y-Z",   // [15]
      "-Y+Z",   // [16]
      "-Y-Z",   // [17]
      "+X+Y+Z", // [18]
      "+X+Y-Z", // [19]
      "+X-Y+Z", // [20]
      "+X-Y-Z", // [21]
      "-X+Y+Z", // [22]
      "-X+Y-Z", // [23]
      "-X-Y+Z", // [24]
      "-X-Y-Z", // [25]
    ];

    XYZmo_RCOH8.mOrientInfo = new Map<string, TInfo4Orientation3D>();
    XYZmo_RCOH8.vOrientInfo = [];
    XYZmo_RCOH8.vOrientInfo.length = vNames.length;

    // Valores ordinales de ejes positivos para simplificar uso.
    const X = 1;
    const Y = 2;
    const Z = 3;

    // Vectores auxiliares para calculo de vectores rotacion en los 26 ejes.
    // Los consideramos como rotaciones a partir del eje +Z, que por eso tiene rotacion nula.
    const xRotPos = new THREE.Vector3(0, 0.5 * Math.PI, 0);
    const yRotPos = new THREE.Vector3(-0.5 * Math.PI, 0, 0);
    const zRotPos = new THREE.Vector3(0, 0, 0);
    const xRotNeg = new THREE.Vector3(0, -0.5 * Math.PI, 0);
    const yRotNeg = new THREE.Vector3(0.5 * Math.PI, 0, 0);
    const zRotNeg = new THREE.Vector3(0, Math.PI, 0);
    const vROTS = [xRotPos, yRotPos, zRotPos, xRotNeg, yRotNeg, zRotNeg];

    // Para construir la secuencia de numeros de ejes implicados a partir del nombre de tipo "+X+Y+Z".
    const constructAxisVector = (name: string): number[] => {
      const len = name.length / 2;
      const res: number[] = [];

      for (let i = 0; i < len; ++i) {
        const sign = name[2 * i] === "+" ? +1 : -1;
        const chr = name[2 * i + 1];
        const ax = (chr === "X") ? X : (chr === "Y") ? Y : Z;
        res.push(sign * ax);
      }
      return res;
    };
  
    // Funcion auxiliar para rellenar facilmente valores.
    const f = (name: string, alpha: number, beta: number, v: THREE.Vector3): void => {
      const item: TInfo4Orientation3D = {
        dir: v,
        rot: new THREE.Vector3(),
        alpha: alpha * THREE.MathUtils.DEG2RAD,
        beta: beta * THREE.MathUtils.DEG2RAD,
        axis: name
      };

      const vAxis = constructAxisVector(name);
      for (let pos of vAxis) {
        if (pos < 0) {
          // Paso de [-1, -2, -3] a [3, 4, 5].
          pos = 2 - pos;
        } else {
          // Paso de [1, 2, 3] a [0, 1, 2].
          pos = pos - 1;
        }
        item.rot.add(vROTS[pos]);
      }
      item.dir.normalize();
      // Posiblemente haya que normalizar tambien la rotacion?.
      item.rot.normalize();

      item.dir = v;

      XYZmo_RCOH8.mOrientInfo.set(name, item);
      // Asignamos segun el orden dado en vNames.
      XYZmo_RCOH8.vOrientInfo[vNames.indexOf(name)] = item;
    };

    // 6 ejes primarios.
    f("+X",      +90.0,  +90.0, new THREE.Vector3(+1,  0,  0));
    f("+Y",     +180.0,  +90.0, new THREE.Vector3( 0, +1,  0));
    f("+Z",     +180.0,    0.0, new THREE.Vector3( 0,  0, +1));
    f("-X",      -90.0,  +90.0, new THREE.Vector3(-1,  0,  0));
    f("-Y",        0.0,  +90.0, new THREE.Vector3( 0, -1,  0));
    f("-Z",     +180.0, +180.0, new THREE.Vector3( 0,  0, -1));
    // 12 ejes secundarios.
    f("+X+Y",   +135.0,  +90.0, new THREE.Vector3( 0.7071067811865475,  0.7071067811865475, 0));
    f("+X-Y",    +45.0,  +90.0, new THREE.Vector3( 0.7071067811865475, -0.7071067811865475, 0));
    f("+X+Z",    +90.0,  +45.0, new THREE.Vector3( 0.7071067811865475, 0,  0.7071067811865475));
    f("+X-Z",    +90.0, +135.0, new THREE.Vector3( 0.7071067811865475, 0, -0.7071067811865475));
    f("-X+Y",   -135.0,  +90.0, new THREE.Vector3(-0.7071067811865475,  0.7071067811865475, 0));
    f("-X-Y",    -45.0,  +90.0, new THREE.Vector3(-0.7071067811865475, -0.7071067811865475, 0));
    f("-X+Z",    -90.0,  +45.0, new THREE.Vector3(-0.7071067811865475, 0,  0.7071067811865475));
    f("-X-Z",    -90.0, +135.0, new THREE.Vector3(-0.7071067811865475, 0, -0.7071067811865475));
    f("+Y+Z",   +180.0,  +45.0, new THREE.Vector3( 0,  0.7071067811865475,  0.7071067811865475));
    f("+Y-Z",   +180.0, +135.0, new THREE.Vector3( 0,  0.7071067811865475, -0.7071067811865475));
    f("-Y+Z",      0.0,  +45.0, new THREE.Vector3( 0, -0.7071067811865475,  0.7071067811865475));
    f("-Y-Z",      0.0, +135.0, new THREE.Vector3( 0, -0.7071067811865475, -0.7071067811865475));
    // 8 ejes ternarios. Aqui aparecen las diferencias.
    f("+X+Y+Z", +135.0,  +45.0, new THREE.Vector3( 0.5,  0.5,  0.7071067811865475));
    f("+X+Y-Z", +135.0, +135.0, new THREE.Vector3( 0.5,  0.5, -0.7071067811865475));
    f("+X-Y+Z",  +45.0,  +45.0, new THREE.Vector3( 0.5, -0.5,  0.7071067811865475));
    f("+X-Y-Z",  +45.0, +135.0, new THREE.Vector3( 0.5, -0.5, -0.7071067811865475));
    f("-X+Y+Z", -135.0,  +45.0, new THREE.Vector3(-0.5,  0.5,  0.7071067811865475));
    f("-X+Y-Z", -135.0, +135.0, new THREE.Vector3(-0.5,  0.5, -0.7071067811865475));
    f("-X-Y+Z",  -45.0,  +45.0, new THREE.Vector3(-0.5, -0.5,  0.7071067811865475));
    f("-X-Y-Z",  -45.0, +135.0, new THREE.Vector3(-0.5, -0.5, -0.7071067811865475));

    if (XYZmo_RCOH8.mOrientInfo.size !== 26) {
      debugger;
    }

    return true;
  }

  /**
   * Construccion de toda la infraestructura grafica necesaria para el gizmo.
   */
  private builtGraphicGyzmo() {
    /**
     * Las coordenadas de los vertices del rhombicuboctahedron (RCO) son las permutaciones pares de estos 3 valores,
     * tanto positivos como negativos: (+-1, +-1, +-(1 + sqrt(2))). Si aumento la a tengo un aspecto mas mejor...
     */
    const a = 1.5;
    const b = 1 + Math.sqrt(2);

    // Tenemos 4 + 8 + 8 + 4 = 24 puntos.
    const vVertices: [number, number, number][] = [
      // Los 8 primeros puntos [0 a 7] van todos a altura +a.
      [+b, -a, +a], [+b, +a, +a], [+a, +b, +a], [-a, +b, +a],
      [-b, +a, +a], [-b, -a, +a], [-a, -b, +a], [+a, -b, +a],
      // Los 8 siguiemtes [8 a 15] son iguales pero a altura -a.
      [+b, -a, -a], [+b, +a, -a], [+a, +b, -a], [-a, +b, -a],
      [-b, +a, -a], [-b, -a, -a], [-a, -b, -a], [+a, -b, -a],
      // Los 4 de arriba [16 a 19] a altura +b.
      [+a, -a, +b], [+a, +a, +b], [-a, +a, +b], [-a, -a, +b],
      // Y los 4 de abajo [20 a 23], como los 4 superiores pero a altura -b.
      [+a, -a, -b], [+a, +a, -b], [-a, +a, -b], [-a, -a, -b],
    ];

    // Ojo, que el sentido es CCW!!!. Esto son los indices para las caras.
    const vFacesIndexes = [
      // Cara +X con sus 2 triangulos.
      [0, 9, 1], [0, 8, 9],
      // Cara +X+Y, con 2 triangulos.
      [1, 10, 2], [1, 9, 10],
      // Cara +Y.
      [2, 11, 3], [2, 10, 11],
      // Cara -X+Y.
      [3, 12, 4], [3, 11, 12],
      // Cara -X.
      [4, 13, 5], [4, 12, 13],
      // Cara -X-Y.
      [5, 14, 6], [5, 13, 14],
      // Cara -Y.
      [6, 15, 7], [6, 14, 15],
      // Cara +X-Y que es la final de las cuadradas en el plano XY.
      [7, 8, 0], [7, 15, 8],
      // Cara +Z que es la de arriba.
      [16, 17, 18], [16, 18, 19],
      // Cara -Z que es la de abajo.
      [20, 22, 21], [20, 23, 22],
      // Los 4 triangulos de arriba: El de encima de +X+Y.
      [1, 2, 17],
      // El de encima de -X+Y.
      [3, 4, 18],
      // El de encima de -X-Y.
      [5, 6, 19],
      // El de encima de +X-Y, que es el ultimo de arriba.
      [7, 0, 16],
      // Los 4 triangulos de abajo: El de debajo de +X+Y.
      [9, 21, 10],
      // El de debajo de -X+Y.
      [11, 22, 12],
      // El de debajo de -X-Y.
      [13, 23, 14],
      // El de debajo de +X-Y, que es el ultimo de abajo.
      [15, 20, 8],

      // Los 4 + 4 rectangulos que faltan, formado cada uno por 2 triangulos.
      // Por arriba, respecto a Z. El +X+Z.
      [0, 1, 17], [0, 17, 16],
      // +Y+Z.
      [2, 3, 18], [2, 18, 17],
      // -X+Z.
      [4, 5, 19], [4, 19, 18],
      // -Y+Z.
      [6, 7, 16], [6, 16, 19],
      // Y los 4 por debajo, empezando en +X-Z.
      [9, 8, 21], [8, 20, 21],
      // +Y-Z.
      [11, 10, 21], [11, 21, 22],
      // -X-Z.
      [13, 12, 22], [13, 22, 23],
      // -Y-Z.
      [15, 14, 23], [15, 23, 20]
    ];

    // Esto son los indices para las lineas separadoras de caras, las aristas.
    // Aqui no importa el sentido.
    const vEdgesIndexes = [
      // [0] Cara +X cuadrada.
      [0, 1, 9, 8],
      // Cara +X+Y.
      [1, 2, 10, 9],
      // [2] Cara +Y.
      [2, 3, 11, 10],
      // Cara -X+Y.
      [3, 4, 12, 11],
      // [4] Cara -X.
      [4, 5, 13, 12],
      // Cara -X-Y.
      [5, 6, 14, 13],
      // [6] Cara -Y.
      [6, 7, 15, 14],
      // Cara +X-Y que es la final de las cuadradas en el plano XY.
      [7, 0, 8, 15],
      // [8] Cara +Z que es la de arriba.
      [16, 17, 18, 19],
      // [9] Cara -Z que es la de abajo.
      [20, 21, 22, 23],
      // Los 4 triangulos de arriba: El de encima de +X+Y.
      [1, 2, 17],
      // El de encima de -X+Y.
      [3, 4, 18],
      // El de encima de -X-Y.
      [5, 6, 19],
      // El de encima de +X-Y, que es el ultimo de arriba.
      [7, 0, 16],
      // Los 4 triangulos de abajo: El de debajo de +X+Y.
      [9, 21, 10],
      // El de debajo de -X+Y.
      [11, 22, 12],
      // El de debajo de -X-Y.
      [13, 23, 14],
      // El de debajo de +X-Y, que es el ultimo de abajo.
      [15, 20, 8],
    ];

    const index4FacePosX = 0;
    const index4FacePosY = 2;
    const index4FacePosZ = 8;
    const index4FaceNegX = 4;
    const index4FaceNegY = 6;
    const index4FaceNegZ = 9;

    const nVertices = vVertices.length;
    const nFaces = vFacesIndexes.length;
    const nEdges = vEdgesIndexes.length;

    const indicesFaces = [];
    const indicesEdges = [];
    const vertices = [];
    const colors: number[] = [];

    // this.setGradientColor(vVertices, colors);

    for (let i = 0; i < nVertices; i++) {
      const [x, y, z] = vVertices[i];
      vertices.push(x, y, z);
      colors.push(0, 0, 0);
    }

    const assignColor2Index = (index: number, r: number, g: number, b: number) => {
      colors[index * 3] = r;
      colors[index * 3 + 1] = g;
      colors[index * 3 + 2] = b;
    };

    // Les doy color a mano a ciertas caras estrategicas como la +X, +Y, +Z.
    const assignColor2Face = (indices: [number, number, number, number], color: [number, number, number]) => {
      const [i0, i1, i2, i3] = indices;
      const [r, g, b] = color;
      assignColor2Index(i0, r, g, b);
      assignColor2Index(i1, r, g, b);
      assignColor2Index(i2, r, g, b);
      assignColor2Index(i3, r, g, b);
    };

    // Las 3 caras principales van en RGB.
    // Cara +X roja.
    let face = vEdgesIndexes[index4FacePosX] as [number, number, number, number];
    assignColor2Face(face, [1.0, 0.0, 0.0]);
    // Cara +Y verde.
    face = vEdgesIndexes[index4FacePosY] as [number, number, number, number];
    assignColor2Face(face, [0.0, 1.0, 0.0]);
    // Cara +Z azul.
    face = vEdgesIndexes[index4FacePosZ] as [number, number, number, number];
    assignColor2Face(face, [0.0, 0.0, 1.0]);
    // Y las restantes 3 caras con los colores de sus texturas sacados a huevo.        
    face = vEdgesIndexes[index4FaceNegX] as [number, number, number, number];
    assignColor2Face(face, [170.0 / 255, 51.0 / 255, 51.0 / 255]);
    face = vEdgesIndexes[index4FaceNegY] as [number, number, number, number];
    assignColor2Face(face, [51.0 / 255, 170.0 / 255, 51.0 / 255]);
    face = vEdgesIndexes[index4FaceNegZ] as [number, number, number, number];
    assignColor2Face(face, [51.0 / 255, 51.0 / 255, 170.0 / 255]);

    // Coloca los indices de las caras.
    for (let i = 0; i < nFaces; i++) {
      const face = vFacesIndexes[i];
      const [i0, i1, i2] = face;
      indicesFaces.push(i0, i1, i2);
    }

    // Coloca los indices de las aristas.
    for (let i = 0; i < nEdges; i++) {
      const edge = vEdgesIndexes[i];
      if (edge.length === 3) {
        const [i0, i1, i2] = edge;
        indicesEdges.push(i0, i1, i1, i2, i2, i0);
      } else {
        const [i0, i1, i2, i3] = edge;
        indicesEdges.push(i0, i1, i1, i2, i2, i3, i3, i0);
      }
    }

    const geometry = new THREE.BufferGeometry();
    geometry.setIndex(indicesFaces);
    geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

    const material = new THREE.MeshBasicMaterial({
      side: THREE.FrontSide,
      // color: 0xfabada,
      vertexColors: true,
      wireframe: false
    });

    this.mesh = new THREE.Mesh(geometry, material);
    this.mesh.name = "mesh_RCO8H";

    // Almacenamos su funcion de calculo de intersecciones por si necesitasemos desactivarla.
    this.prevRayCastFunction = this.mesh.raycast;

    // Este es el grupo contenedor de todo.
    this.gObj = new THREE.Group();
    this.gObj.add(this.mesh);

    // Las aristas.
    const geomEdges = new THREE.BufferGeometry();
    geomEdges.setIndex(indicesEdges);
    geomEdges.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
    const edges = new THREE.LineSegments(geomEdges);
    (edges.material as THREE.LineBasicMaterial).color = new THREE.Color(0x0000);

    if (false) {
      const wireframe = new THREE.WireframeGeometry(this.mesh.geometry);
      const line = new THREE.LineSegments(wireframe);
      (line.material as THREE.LineBasicMaterial).depthTest = false;
      (line.material as THREE.LineBasicMaterial).opacity = 0.25;
      (line.material as THREE.LineBasicMaterial).transparent = true;
      (line.material as THREE.LineBasicMaterial).color = new THREE.Color(0x0000);
      this.gObj.add(line);
    }

    if (false) {
      const materialPoints = new THREE.PointsMaterial({ size: 0.1, vertexColors: false, });
      const geomPoints = new THREE.BufferGeometry();
      geomPoints.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
      const points = new THREE.Points(geomPoints, materialPoints);
      this.gObj.add(points);
    }

    // if (false) {
    //   const vnh = new VertexNormalsHelper(this.mesh, 0.5);
    //   this.gObj.add(vnh);
    // }

    const axes = new THREE.AxesHelper(5);

    // \Trick: Desactivar calculo de intersecciones asociando una funcion vacia.
    // \ToDo: Quizas haya una forma mas simple???...
    edges.raycast = () => { };
    axes.raycast = () => { };
    // Efectivamente esto quita colisiones.
    // this.mesh.raycast = () => {};
    this.gObj.name = "RCO8H";
    // Posiblemente las intersecciones se optimicen con esto.
    geometry.computeBoundingSphere();
    geometry.computeBoundingBox();

    this.gObj.add(edges);
    this.gObj.add(axes);

    // Para el z-fighting separamos un poco positivo por encima/etc...
    let d = +b + 0.001;
    let quadVertices = [
      d, -a, +a,
      d, +a, +a,
      d, +a, -a,
      d, -a, -a
    ];
    let quadUVs = [
      0.0, 1.0,
      1.0, 1.0,
      1.0, 0.0,
      0.0, 0.0
    ];
    let quadIndices = [
      0, 2, 1,
      0, 3, 2
    ];

    // Las 6 fabulosas caras van con sus correspondientes texturas, y aqui hay que tener en cuenta el entorno REACT:
    // Como meter ficheros externos (texturas, iconos y pollas) en modo develop en la parte JS/React???:
    // Se pueden meter directorios con esos datos en el directorio frontend/public y luego para acceder a ellos se deben
    // tipificar con el prefijo de url "http://localhost:3000/".
    // Por ejemplo en mi caso meti las texturas en frontend/public/img y accedo a ellas con la url:
    // "http://localhost:3000/" + "img/xPosRed.png"
    // Asi funciona en mi maquina y en modo developer, pero creo que para que funcione en otras debe ser mas simple:
    // const url = "http://localhost:3000/";
    const url = "/";
    console.debug(` =====> Carga de texturas con nueva URL: "${url}"`);

    if (true) {
      // +X
      this.createFaceXYZ(quadVertices, quadIndices, quadUVs, url + 'img/xPosRed.png');
    }

    if (true) {
      // +Y
      quadVertices = [
        -a, d, +a,
        +a, d, +a,
        +a, d, -a,
        -a, d, -a
      ];

      // No lo entiendo...
      quadIndices = [
        0, 1, 2,
        0, 2, 3
      ];

      quadUVs = [
        1.0, 1.0,
        0.0, 1.0,
        0.0, 0.0,
        1.0, 0.0,
      ];

      this.createFaceXYZ(quadVertices, quadIndices, quadUVs, url + 'img/yPosGreen.png');
    }

    if (true) {
      // +Z		
      quadVertices = [
        -a, +a, d,
        +a, +a, d,
        +a, -a, d,
        -a, -a, d,
      ];

      quadIndices = [
        0, 2, 1,
        0, 3, 2
      ];

      quadUVs = [
        0.0, 1.0,
        1.0, 1.0,
        1.0, 0.0,
        0.0, 0.0,
      ];

      this.createFaceXYZ(quadVertices, quadIndices, quadUVs, url + 'img/zPosBlue.png');
    }

    // Para el z-fighting.
    d = -b - 0.001;

    if (true) {
      // -X
      quadVertices = [
        d, -a, +a,
        d, +a, +a,
        d, +a, -a,
        d, -a, -a
      ];
      quadUVs = [
        1.0, 1.0,
        0.0, 1.0,
        0.0, 0.0,
        1.0, 0.0
      ];
      quadIndices = [
        0, 1, 2,
        0, 2, 3,
      ];
      this.createFaceXYZ(quadVertices, quadIndices, quadUVs, url + 'img/xNeg.png');
    }

    if (true) {
      // -Y
      quadVertices = [
        -a, d, +a,
        +a, d, +a,
        +a, d, -a,
        -a, d, -a
      ];

      quadIndices = [
        0, 2, 1,
        0, 3, 2
      ];

      quadUVs = [
        0.0, 1.0,
        1.0, 1.0,
        1.0, 0.0,
        0.0, 0.0,
      ];

      this.createFaceXYZ(quadVertices, quadIndices, quadUVs, url + 'img/yNeg.png');
    }

    if (true) {
      // -Z
      quadVertices = [
        -a, +a, d,
        +a, +a, d,
        +a, -a, d,
        -a, -a, d,
      ];

      quadIndices = [
        0, 1, 2,
        0, 2, 3,
      ];

      quadUVs = [
        0.0, 0.0,
        1.0, 0.0,
        1.0, 1.0,
        0.0, 1.0,
      ];

      this.createFaceXYZ(quadVertices, quadIndices, quadUVs, url + 'img/zNeg.png');
    }

    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();

    // Recuerda que ahora para que funcione bien la parte estatica todo debe estar en una escena, por cortesia de las "peculiaridades" de Three.js...
    this.scene = new THREE.Scene();
    this.scene.add(this.gObj);
  }

	private createFaceXYZ(quadVertices: number[], quadIndices: number[], quadUVs: number[],
		textureFile: string): boolean {

		const vertices = new Float32Array(quadVertices);
		// En este caso cada uno de los 4 vertices tiene su propia coordenada UV de texture mapping.
		// Con los 4 vertices pintamos los 2 triangulos que forman el cuadrado.
		const indices = new Uint32Array(quadIndices);

		const geometry = new THREE.BufferGeometry();
		geometry.setIndex(new THREE.BufferAttribute(indices, 1));
		geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
		geometry.setAttribute('uv', new THREE.Float32BufferAttribute(quadUVs, 2));

		// Carga de la textura.
		const texture = new THREE.TextureLoader().load(textureFile);

		if (texture) {
			console.log(`Cargada la textura "${textureFile}" correctamente.`);
			const material = new THREE.MeshBasicMaterial({ map: texture });
			const mesh = new THREE.Mesh(geometry, material);
      mesh.raycast = () => { };
			this.gObj.add(mesh);
			return true;
		}

		return false;
	}

  /** Crea un degradado de colores RGB segun las coordenadas dadas y lo devuelve en vColors. */
  private setGradientColor(vVertices: [number, number, number][], vColors: number[]): void {
    if (vColors.length) {
      vColors.length = 0;
    }
    const nVertices = vVertices.length;

    let [xMin, xMax] = [+Infinity, -Infinity];
    let [yMin, yMax] = [+Infinity, -Infinity];
    let [zMin, zMax] = [+Infinity, -Infinity];

    for (let i = 0; i < nVertices; i++) {
      const [x, y, z] = vVertices[i];
      if (x < xMin) { xMin = x; }
      if (x > xMax) { xMax = x; }
      if (y < yMin) { yMin = y; }
      if (y > yMax) { yMax = y; }
      if (z < zMin) { zMin = z; }
      if (z > zMax) { zMax = z; }
    }

    const xRange = xMax - xMin;
    const yRange = yMax - yMin;
    const zRange = zMax - zMin;

    for (let i = 0; i < nVertices; i++) {
      const [x, y, z] = vVertices[i];
      const r = (x - xMin) / xRange;
      const g = (y - yMin) / yRange;
      const b = (z - zMin) / zRange;
      vColors.push(r, g, b);
    }
  }

  public subscribeMouseEvents() {
    this.parentContainer.addEventListener("dblclick", this.mouseClickHandler);
  }

  public unSubscribeMouseEvents() {
    this.parentContainer.removeEventListener("dblclick", this.mouseClickHandler);
  }

  private mouseClickHandler = (event: MouseEvent) => {
    const x0 = event.offsetX;
    const y0 = event.offsetY;
    const XY = this.inViewport(x0, y0);
    if (XY) {
      console.log(`Double-clicking Gizmo ${this.parentViewport.name}: (${x0}, ${y0})`);
      console.log(`\t OK (${XY.x}, ${XY.y})`);
      const touch = this.processDoubleClickXY(XY.x, XY.y);
      if (touch) {
        event.stopPropagation();
      }
    }
  };

  /**
   * Por que sera que todo lo que es construido tiene que ser destruido...
   * El objetivo aqui es que no quede ni una sola posible referencia con vida, aunque nos pasemos...
   */
  public destroy(): void {
    this.unSubscribeMouseEvents();
    this.externCamera = (undefined as unknown) as THREE.Camera;
    // Quizas haya que quitarlo de algun sitio...
    this.parentContainer = (undefined as unknown) as HTMLDivElement;
    this.mouse = (undefined as unknown) as THREE.Vector2;
    this.camera = (undefined as unknown) as THREE.OrthographicCamera;
    this.gObj = (undefined as unknown) as THREE.Group;

    this.raycaster = (undefined as unknown) as THREE.Raycaster;

    SceneManager.sceneDestruction(this.scene, false);
    this.scene = (undefined as unknown) as THREE.Scene;

    this.targetPosition = (undefined as unknown) as THREE.Vector3;
    this.targetQuaternion = (undefined as unknown) as THREE.Quaternion;
    this.externCenter = (undefined as unknown) as THREE.Vector3;
    this.q1 = (undefined as unknown) as THREE.Quaternion;
    this.q2 = (undefined as unknown) as THREE.Quaternion;
    this.selectedAxis = (undefined as unknown) as string;

    this.parentViewport = (undefined as unknown) as GraphicViewport;

    this.currentDirectionVect = (undefined as unknown) as THREE.Vector3;  
    this.prevDirectionVect = (undefined as unknown) as THREE.Vector3;  
  }

  /**
   * Hace que los ejes asociados al gizmo no puedan ser pulsados.
   *
   * @returns {boolean}
   * @memberof XYZmo
   */
  public setNonClickableAxes(): boolean {
    // Simplemente anulamos el calculo de intersecciones haciendo el objeto RCO8H no colisionable.
    this.mesh.raycast = () => {};
    return true;
  }

  /**
   * Reasigna colisiones al RCO8H para que vuelva a ser clickable.
   */
  public setClickableAxes(): void {
    this.mesh.raycast = this.prevRayCastFunction;    
  }

  /**
   * Aqui podemos inicializar realmente el gizmo con el tamaño dado en la coordenada dada, controlando la camara
   * dada y dentro del contenedor dado. Ojo que (x, y) seria su esquina SUPERIOR DERECHA (ESD) y el gizmo se extenderia
   * dimXY tanto en anchura como en altura y esos 3 valores son dados en pixels... pero creo que no va asi...
   *
   * @param {number} x
   * @param {number} y
   * @param {number} dimXY
   * @param {THREE.Camera} externOuterCam
   * @param {HTMLDivElement} container
   * @memberof XYZmo
   */
  init(x: number, y: number, dimXY: number, externOuterCam: THREE.Camera, container: HTMLDivElement): void {
    this.x0 = x;
    this.y0 = y;
    this.dimXY = dimXY;

    this.externCamera = externOuterCam;
    this.parentContainer = container;

    this.resetInternalValues();
  }

  public resetInternalValues(): void {
    this.selectedAxis = "";
    this.raycaster = new THREE.Raycaster();

    this.targetPosition = new THREE.Vector3();
    this.targetQuaternion = new THREE.Quaternion();
    this.radius = 0;
    this.q1 = new THREE.Quaternion();
    this.q2 = new THREE.Quaternion();
    this.externCenter = new THREE.Vector3();

    this.currentDirectionVect = new THREE.Vector3();
    this.prevDirectionVect = new THREE.Vector3();
  }

  /**
   * Para el cambio de camara externa en caliente.
   * @param newExternCam 
   */
  setNewCamera(newExternCam: THREE.Camera): void {
    this.externCamera = newExternCam;
  }

  /**
   * Para cambiar "en caliente" el div al que pertenece el gizmo, pero ojo, que antes habria que desuscribir el viejo div
   * y luego volver a subscribir al nuevo div a los eventos del dobleclick.
   * @param newDiv 
   */
  setNewParentContainer(newDiv: HTMLDivElement): void {
    this.parentContainer = newDiv;
  }

  /**
   * Sera llamado desde el exterior ante un cambio de tamaño de la aplicacion, con los nuevos datos de anchura y altura.
   *
   * @memberof XYZmo
   */
  handleResize(newWidth: number, newHeight: number): void {
    if (this.x0 === undefined) {
      window.alert("Ojo: Gyzmo no inicializado, so maricon!!!.");
    }
    // Desplazamos la esquina superior izquierda del gizmo, pero solo la x, pues la y sigue con valor 0.
    this.x0 = newWidth - this.dimXY;
    // Posiblemente sea opcional, ya que la proporcion no varia. /DuDa aqui!!!.
    this.camera.updateMatrixWorld();
  }

  /**
   * Una lambda expresion para saber si unas coordenadas (x, y) caen dentro del hipotetico viewport del gizmo.
   * Ojo que las coordenadas de entrada (x, y) son de pantalla y en caso de caer en el viewport las normalizamos en [0, dimXY).
   * En caso de que caigan devolvemos {x: x', y: y'} con las coordenadas adaptadas al viewport del gizmo, y si no un buen null.
   * @param {number} x
   * @param {number} y
   * @param {GraphicViewport} viewport
   * @returns {boolean}
   */
  readonly inViewport = (x: number, y: number): { x: number, y: number } | null => {
    // Ojo, que para evitar problemas requerimos estar en el interior TOTAL, no tocando la frontera.
    if (x <= this.x0 || this.x0 + this.dimXY <= x) {
      return null;
    }
    if (y <= this.y0 || this.y0 + this.dimXY <= y) {
      return null;
    }
    return {
      x: x - this.x0,
      y: y - this.y0
    };
  };

  /**
   * Gestion de la seleccion externa: Es llamado automaticamente desde el exterior cada vez que se nos hace un doble click
   * dentro de los dominios XY de nuestro gizmo. Las coordenadas nos llegan ya normalizadas en el intervalo [0, dimXY].
   * Aqui comprobamos si esto afecta a alguno de los 6 ejes/sprites y en tal caso se ordena la colocacion de esa vista.
   * 
   * @returns true si las (x, y) dadas tocan algo que obliga a un cambio o false en caso contrario.
   */
  private processDoubleClickXY(x: number, y: number): boolean {
    // Hay un problema a veces: Puede coincidir POSICIONALMENTE un gizmo de un viewport sobre el gizmo de mainView.
    // Y si hacemos dobleClick para cerrar ese viewport secundario tambien estariamos pulsando en el gizmo de mainView
    // para agregar nuevos viewports en subdivision vertical. Vaya lio!!!.
    // Solucion: Solo se permite atender al que esta en curso. Asi que sacamos al padre VP y al abuelo GP.
    const fatherVP = this.parentViewport;
    if (!fatherVP) {
      console.warn("ERROR: Gizmo without parent viewport???...");
      debugger;
      return false;
    }
    const ownerGPO = fatherVP.owner.owner;
    if (!ownerGPO) {
      window.alert("ERROR: Gizmo without grandfather graphicProcessor???...");
      debugger;
      return false;
    } else {
      if (ownerGPO.getActiveViewport() !== fatherVP) {
        console.error("ATENCION: Cagando fuera de tu pota...");
        console.log(`\tHas pulsado en el gizmo del vp "${fatherVP.name}" ${fatherVP.enabled ? "ACTIVO" : "INACTIVO"}`);
        console.log(`\tque no coindice con el vp activo en curso "${ownerGPO.getActiveViewport().name}".`);
        return false;
      }
    }

    // Si el vp dueño no esta activo no debiera sufrir esta llamada.
    if (!fatherVP.enabled) {
      console.error(`Has pulsado en el gizmo del vp "${fatherVP.name}" que estava INACTIVO.`);
      debugger;
      return false;
    }

    // Con la posicion del raton "dentro del hipotetico panel" componemos el rayo para ver si tocamos sprite.
    // Busco coordenadas en [-1, 1] dentro de [0, dimXY].
    x = (x / this.dimXY) * 2 - 1;
    y = -(y / this.dimXY) * 2 + 1;

    const mouse = new THREE.Vector2(x, y);
    this.raycaster.setFromCamera(mouse, this.camera);

    // Solo comprobamos intersecciones contra el RCO8H, nada mas. 
    const vIntersects = this.raycaster.intersectObject(this.mesh, false);
    const numIntersects = vIntersects.length;

    // Solo puede haber una interseccion o ninguna. Si no la hay salimos cuanto antes.
    if (!numIntersects) {
      return false;
    } else {
      // Comprobacion psicopatica que desaparecera. No puede haber algo distinto de 1.
      if (numIntersects !== 1) {
        console.error("No puede haber mas de una interseccion con el RCO8H!!!.");
        debugger;
      }
    }

    const intersection = vIntersects[0] as THREE.Intersection;
    // Con el indice de la cara podemos saber de que orientacion se trata.
    const faceIndex = intersection.faceIndex as number;

    const selectedAxis = XYZmo_RCOH8.vFacesNames[faceIndex];
    if (selectedAxis === this.selectedAxis) {
      // Al pulsar otra vez sobre el eje donde ya estabamos previamente pasamos como en Blender: Al eje opuesto.
      // Simple cambio de signo en el mismo nombre.
      this.selectedAxis = XYZmo_RCOH8.getOppositeAxis(selectedAxis);
      console.warn("\tINVERSE SELECTION: From <" + selectedAxis + "> to <" + this.selectedAxis + ">");
    } else {
      this.selectedAxis = selectedAxis;
      console.warn("\tDIRECT SELECTION: " + intersection.object.name + " F[" + faceIndex + "]: <" + selectedAxis + ">");
    }

    if (this.useCameraControls) {
      this.prepareAnimationDataUsingCameraControls(this.selectedAxis, this.externCenter);
    } else {
      this.prepareAnimationData(this.selectedAxis, this.externCenter);
    }

    return true;
  }

  /**
   * En funcion del nombre del eje suministrado, primario, secundario o terciario, que es uno de los 26 valores diferentes
   * contenidos en el vector vFacesNames, se elaboran los datos de salida como vector posicion destino, vector rotacion
   * destino y angulos horizontal y vertical (opcionales).
   * 
   * Aclaracion: En el RCO8H tenemos exactamente 26 caras que nos permiten elegir directamente una serie de ejes:
   * [1] Ejes primarios o principales, que son exactamente 6, las 6 caras quad principales ortogonales e involucran un
   * solo eje, ya sea positivo o negativo:
   *      +X, +Y, +Z,
   *      -X, -Y, -Z.
   * [2] Ejes secundarios, que son exactamente 12, las caras quad menores que coinciden con las 12 aristas del cuboide e
   * involucran 2 ejes en sus combinaciones positivas y negativas:
   *      +X+Y, +X-Y, -X+Y, -X-Y
   *      +X+Z, +X-Z, -X+Z, -X-Z
   *      +Y+Z, +Y-Z, -Y+Z, -Y-Z
   * [3] Ejes terciarios, exactamente 8, las caras triangulares que coinciden con las 8 esquinas del cuboide e implican
   * 3 ejes en sus combinaciones positivas y negativas:
   *      +X+Y+Z, +X+Y-Z, +X-Y+Z, +X-Y-Z, -X+Y+Z, -X+Y-Z, -X-Y+Z, -X-Y-Z
   * 
   * \Warning: ATENCION; el dstPos calculado debe ser UNITARIO, ya que es multiplicado externamente por una distancia.
   * @param axis
   * @param dstPos 
   * @param dstRot 
   * @param angles 
   * @returns 
   */
  private static getDestinationData(axis: string, dstPos: THREE.Vector3, dstRot: THREE.Vector3, angles?: [number, number]): boolean {

    if (!XYZmo_RCOH8.mOrientInfo.has(axis)) {
      console.error("ERROR XYZmo: Eje de nombre invalido: '" + axis + "'.");
      debugger;
      return false;
    }
    
    const item = XYZmo_RCOH8.mOrientInfo.get(axis) as TInfo4Orientation3D;
    
    dstPos.copy(item.dir);
    dstRot.copy(item.rot);
  
    if (angles) {
      angles[0] = item.alpha;
      angles[1] = item.beta;
    }

    return true;
  }

  /**
   * Prepara todos los datos necesarios para ejecutar una animacion GRADUAL que se movera desde la orientacion actual de la escena
   * hacia la orientacion del eje/sprite seleccionado (+X, -X, +Y, -Y, +Z, -Z).
   *
   * @private
   * @param {THREE.Object3D} axis
   * @param {THREE.Vector3} focusPoint
   * @returns {void}
   * @memberof XYZmo
   */
  private prepareAnimationData(axis: string, focusPoint: THREE.Vector3): void {

    // Posicion destino.
    const dstPos = new THREE.Vector3();
    // Rotacion destino dada mediante los angulos de Euler.
    const dstRot = new THREE.Vector3();

    if (!XYZmo_RCOH8.getDestinationData(axis, dstPos, dstRot)) {
      return;
    }

    // Asignamos la posicion destino (X, Y, Z) asi como su quaternion final, creado este ultimo a partir de los 3 angulos de Euler.
    this.targetPosition.set(dstPos.x, dstPos.y, dstPos.z);
    const dstRotEuler = new THREE.Euler(dstRot.x, dstRot.y, dstRot.z);
    this.targetQuaternion.setFromEuler(dstRotEuler);

    console.log(`Posicion destino: (${this.targetPosition.x}, ${this.targetPosition.y}, ${this.targetPosition.z})`);
    // Tomamos como radio para esa trayectoria la distancia de la camara al punto de interes.
    this.radius = this.externCamera.position.distanceTo(focusPoint);
    // Esta es la posicion final respecto al objeto de interes tomando en cuenta el radio mas la posicion del gizmo solicitada.
    this.targetPosition.multiplyScalar(this.radius).add(focusPoint);

    // En la posicion de interes miramos a la camara externa...
    const dummyObserver = new THREE.Object3D();
    dummyObserver.position.copy(focusPoint);
    dummyObserver.lookAt(this.externCamera.position);
    // Y tras mirar a la camara externa sacamos su quaternion que es la rotacion de ORIGEN.
    this.q1.copy(dummyObserver.quaternion);
    // Y luego (en esa misma posicion) lo ponemos a mirar a la posicion destino, sacamos su quaternion y esa es la rotacion destino.
    dummyObserver.lookAt(this.targetPosition);
    this.q2.copy(dummyObserver.quaternion);
  }

  private prepareAnimationDataUsingCameraControls(axis: string, focusPoint: THREE.Vector3): void {
    // Posicion destino.
    const dstPos = new THREE.Vector3();
    // Rotacion destino dada mediante los angulos de Euler.
    const dstRot = new THREE.Vector3();

    const angles: [number, number] = [Infinity, Infinity];

    if (!XYZmo_RCOH8.getDestinationData(axis, dstPos, dstRot, angles)) {
      debugger;
      return;
    }

    const [alpha, beta] = angles;
    const cntrls = this.parentViewport.controls as CameraControls;

    // Sin transicion se efectua la rotacion, es decir de un solo golpe y plumazo.
    cntrls.rotateTo(alpha, beta);
  }

  /**
   * Funcion de actualizacion para la animacion ejecutada cuando seleccionamos un eje en el gizmo.
   * En caso de acabar la animacion devuelve un false para informar al exterior.
   */
  updateRotationAnimation(): boolean {
    // if (!this.animating) {
    //   debugger;
    // }

    // // Del reloj sacamos el incremento acumulativo de tiempo.
    // const delta = this.clock.getDelta();
    // const step = delta * this.turnRate;
    // const focusPoint = this.externCenter;

    // // Anima la posicion haciendo un slerp y luego escalando la posicion en/sobre la esfera unitaria.
    // // Con esto se hace rotar a q1 hacia q2 pero en un paso angular (en radianes) dado por step. Ademas NUNCA superaria q2.
    // this.q1.rotateTowards(this.q2, step);
    // // Hemos variado q1 y colocamos la camara externa en esa misma posicion radialmente...
    // this.externCamera.position.set(0, 0, 1).applyQuaternion(this.q1).multiplyScalar(this.radius).add(focusPoint);

    // // Si hacemos esto, variando la orientacion de la camara esta cabeceara y no estara vertical.
    // // this.externCamera.quaternion.rotateTowards(this.targetQuaternion, step);
    // // Esto la mantiene erguida cual polla enhiesta.
    // this.externCamera.lookAt(focusPoint);

    // // Ademas movemos solidariamente al propio gizmo traidor. DE PUTA MADRE FUNCIONA!!!.
    // this.gObj.quaternion.copy(this.externCamera.quaternion).invert();
    // this.gObj.updateMatrixWorld();

    // // Fin de la animacion/trayectoria alcanzado?.
    // // Calculamos el angulo en radianes entre ambos quaterniones (siempre positivo) y lo comparamos con un epsilon.
    // const alpha = this.q1.angleTo(this.q2);
    // const epsilon = 0.0001;
    // // console.log("ANIMATION ===> alpha: " + alpha + "    delta: " + delta);
    // if (alpha < epsilon) {
    //   console.log("End of axial rotation.");
    //   this.animating = false;
    //   // Se acabo la animacion, luego detenemos el reloj para evitar acumulaciones indebidas.
    //   this.clock.stop();

    //   if (alpha !== 0.0) {
    //     console.log(alpha);
    //   }

    //   return false;
    // }
    // // La animacion aun no ha acabado.
    // return true;

    return false;
  }

  numIter: number = 0;
  vPos = new THREE.Vector3();
  vDir = new THREE.Vector3();
  vPrevPos = new THREE.Vector3();
  vPrevDir = new THREE.Vector3();
  quat = new THREE.Quaternion();
  eule = new THREE.Euler();


  public seeExternCamera(showOnlyIfDifference: boolean = true): void {
    ++this.numIter;
    this.externCamera.getWorldPosition(this.vPos);
    this.externCamera.getWorldDirection(this.vDir);

    // Si hay diferencia eso es que hay movimiento.
    const noMovement = this.vPos.equals(this.vPrevPos) && this.vDir.equals(this.vPrevDir);
    if (showOnlyIfDifference && noMovement) {
      return;
    }

    // Datos varios de la camara como tipo de camara, near+far, nombre de su vp propietario...
    let typeCam = "None";
    let [near, far] = [+Infinity, -Infinity];
    if ((this.externCamera as THREE.PerspectiveCamera).isPerspectiveCamera === true) {
      typeCam = "PERSP";
      near = (this.externCamera as THREE.PerspectiveCamera).near;
      far = (this.externCamera as THREE.PerspectiveCamera).far;
    } else {
      if ((this.externCamera as THREE.OrthographicCamera).isOrthographicCamera === true) {
        typeCam = "ORTHO";
        near = (this.externCamera as THREE.OrthographicCamera).near;
        far = (this.externCamera as THREE.OrthographicCamera).far;
      } 
    }
    const vpName = this.parentViewport.name;


    // [1] Posicion mundial de la camara.
    let msg = `${this.numIter}: Cam[vp:"${vpName}"+${typeCam}+Near:${near.toFixed(3)}+Far:${far.toFixed(3)}]: `;
    msg += `Pos(${this.vPos.x.toFixed(3)}, ${this.vPos.y.toFixed(3)}, ${this.vPos.z.toFixed(3)}) `;
    // [1.5] Distancia a origen, como una especie de modulo.
    let dist2Orig = this.vPos.length();
    msg += `|${dist2Orig.toFixed(3)}| `;
    // [2] Direccion en que apunta la camara y su modulo si es no unitario.
    msg += `===> Dir(${this.vDir.x.toFixed(4)}, ${this.vDir.y.toFixed(4)}, ${this.vDir.z.toFixed(4)}) `;
    // Esto es siempre unitario. Si no lo es lo indicamos!!!.
    const modDir = this.vDir.length();
    if (modDir < 0.9999 || 1.0001 < modDir) {
      msg += `|${modDir.toFixed(4)}| NON UNIT!!! `;
    }

    // [3] Angulos
    const angleXZ = THREE.MathUtils.radToDeg(Math.atan2(this.vDir.x, this.vDir.z));
    msg += `<AngleXZ:${angleXZ.toFixed(3)}º> `;

    const angleXY = THREE.MathUtils.radToDeg(Math.atan2(this.vDir.x, this.vDir.y));
    msg += `<AngleXY:${angleXY.toFixed(3)}º> `;

    const angleYZ = THREE.MathUtils.radToDeg(Math.atan2(this.vDir.y, this.vDir.z));
    msg += `<AngleYZ:${angleYZ.toFixed(3)}º> `;

    // Saco los angulos del quaternion.
    this.externCamera.getWorldQuaternion(this.quat);
    this.eule.setFromQuaternion(this.quat);
    const alphaX = THREE.MathUtils.radToDeg(this.eule.x);
    const betaY = THREE.MathUtils.radToDeg(this.eule.y);
    const deltaZ = THREE.MathUtils.radToDeg(this.eule.z);
    msg += ` <<${alphaX.toFixed(3)}:${betaY.toFixed(3)}:${deltaZ.toFixed(3)}>> `;

    console.log(msg);

    this.vPrevPos.copy(this.vPos);
    this.vPrevDir.copy(this.vDir);    
  }

  /**
   * Renderizador del gizmo al que se le dan externamente las coordenadas para hacer el scissoring.
   * Me ha costado un cojon y parte del otro que esto funcionara siempre correctamente.
   *
   * @param {THREE.WebGLRenderer} renderer
   * @param {number} xSc
   * @param {number} ySc
   * @memberof XYZmo
   */
  render(renderer: THREE.WebGLRenderer, xSc: number, ySc: number): void {

    // Imprimimos informacion de la camara externa.
    // if (true) {
    //   if (this.parentViewport.name === 'plan' || this.parentViewport.name === 'elevation') {
    //     this.seeExternCamera();
    //   }
    // }

    // Necesario para llevarnos a otra parte el gyzmo.
    // xSc -= 100;
    ySc -= this.y0;

    // const beforeDirectionVector = new THREE.Vector3();
    // beforeDirectionVector.copy(this.currentDirectionVect);

    // Mueve el gizmo solidariamente con la camara externa.
    this.gObj.quaternion.copy(this.externCamera.quaternion).invert();
    this.gObj.updateMatrixWorld();
    // Recuerda que siempre usamos el eje +Z como base de todas las rotaciones.
    this.currentDirectionVect.set(0, 0, 1);
    // Este teoricamente es el bueno.
    this.currentDirectionVect.applyQuaternion(this.externCamera.quaternion);

    // const dist2Before = this.currentDirectionVect.distanceTo(beforeDirectionVector);
    // if (dist2Before > 0) {
    //  console.log("Dist----> " + dist2Before);
    // }

    // if (beforeDirectionVector.equals(this.currentDirectionVect)) {
    //   // Si pusieramos aqui un return solo se pintaria el gyzmo entre movimientos y desapareceria en la inmovilidad.
    // }

    const prevAxis = this.selectedAxis;

    // Podriamos colocar un epsilon de sensibilidad...
    const dist2PrevDir = this.currentDirectionVect.distanceTo(this.prevDirectionVect);
    const hasChangedDirection = (dist2PrevDir > 0.00009);

    // Tenemos la cosa de que cuando nos movemos solidariamente con la escena, sin animacion por medio, podemos estar
    // cambiando sin querer o medio queriendo el selectedAxis, que obviamente no tiene por que coincidir con el previo.
    // Lo solucionamos asi, pero solo cuando hay cambios, para optimizar.
    if (hasChangedDirection) {  
      if (this.updateCurrentAxis(this.currentDirectionVect)) {
        if (prevAxis !== this.selectedAxis) {
          console.log("Hemos pasado del eje '" + prevAxis + "' al nuevo eje '" + this.selectedAxis + "'.");
        }
      } else {
        // En este caso no estamos en ninguno de los 26 ejes canonicos, pero si estamos muy cerca podriamos hacer una
        // especie de "pegado magnetico" al eje mas cercano.
        const pos = XYZmo_RCOH8.getNearestAxisToVector(this.currentDirectionVect, 0.0125);
        if (pos !== -1) {
          // Aqui tenemos el problema de algo parecido al gimbal lock que solo se da cuando nos intentamos pegar al eje
          // Z, ya sea +Z o -Z. Directamente nos pegamos y rotamos a una posicion fija y jodemos la rotacion previamente
          // alcanzada en torno al propio eje Z.
          const item = XYZmo_RCOH8.vOrientInfo[pos];
          this.selectedAxis = item.axis;
          // Al acercarnos al eje +Z|-Z nos pegamos al mismo, pero conservando la rotacion en torno al mismo para no
          // saltar bruscamente y no forzar a la misma posicion.
          if (this.selectedAxis === "+Z" || this.selectedAxis === "-Z") {
            // console.error(` Bypassing magnetic glued for axis '${this.selectedAxis}'.`);
            this.selectedAxis = "";
          } else {
            this.prepareAnimationDataUsingCameraControls(this.selectedAxis, this.externCenter);
            this.currentDirectionVect.copy(item.dir);
            console.error(` Magnetic glued to axis '${this.selectedAxis}'.`);
          }
        } else {
          this.selectedAxis = "";
        }
      }

      // Preservamos el vector direccion.
      this.prevDirectionVect.copy(this.currentDirectionVect);
    }

    // Parece que esto es muy necesario para evitar problemas.
    renderer.clearDepth();
    // El scissoring lo he agregado yo, pues en el original no lo habia.
    // RECUERDA: En setScissor() y setViewport() la coordenada Y va al reves, empieza en 0 ABAJO.
    renderer.setScissorTest(true);
    renderer.setScissor(xSc, ySc, this.dimXY, this.dimXY);
    renderer.setViewport(xSc, ySc, this.dimXY, this.dimXY);
    renderer.render(this.scene, this.camera);
    // Finalmente desactivamos el tijereteo.
    renderer.setScissorTest(false);
    // ATENCION: Al salir de aqui debemos asegurarnos de que el renderer vuelve a tener el tamaño original, mediante una
    // subsecuente llamada a renderer.setSize(width, height, true) hecha desde el exterior.
    // Si no se hace esa llamada lo que queda es un batiburrillo con todo en el area del gizmo.
  }

  private static getNearestAxisToVector(v: THREE.Vector3, epsilon: number): number {
    // Recorremos los 26 ejes posibles buscando la primera distancia que verifique epsilon.
    for (let i = 0; i < 26; ++i) {
      const item = XYZmo_RCOH8.vOrientInfo[i];
      const dist = v.distanceTo(item.dir);
      if (dist < epsilon) {
        return i;
      }
    }
    return -1;
  }

  /**
   * En funcion del vector dado actualiza el dmc selectedAxis, que podria ser alguno de los 26 posibles (las 6 + 12 + 8
   * caras del RCO8H) o "" en caso de estar en una situacion de rotacion que no cae en ningun eje. Ademas en caso de que
   * caiga en alguno de los ejes devolvemos true. Recuerda true devuelto indica coincidencia con eje canonico (26).
   * Ademas el vector que nos llega SIEMPRE es unitario, totalmente comprobado.
   *
   * @param {THREE.Vector3} v
   * @returns {boolean}
   * @memberof XYZmo
   */
  private updateCurrentAxis(v: THREE.Vector3): boolean {

    const epsilon = 0.00001;
    
    const isCurrentAxis = (this.selectedAxis !== "");
    // En la mayoria de los casos el eje que nos llega es vacio por no coincidir con ningun eje "ortodoxo" de los 26
    // posibles, o bien puede ser posible que coincida con el valor anterior.
    if (isCurrentAxis) {
      // Lo primero seria ver si seguimos en el mismo eje que indica el nombre actual.
      const item = XYZmo_RCOH8.mOrientInfo.get(this.selectedAxis) as TInfo4Orientation3D;
      const vect = item.dir;
      const modulus = vect.distanceTo(v);
      if ((-epsilon < modulus) && (modulus < +epsilon)) {
        return true;
      }

      console.warn("\tCurrent axis: <" + this.selectedAxis + "> distance: " + modulus);
      // console.log(vect);
      // console.log(v);
    }

    // Simplificamos codigo.
    const newPosition = XYZmo_RCOH8.getAxisIndex4Vector(v);
    if (newPosition !== -1) {
      const item = XYZmo_RCOH8.vOrientInfo[newPosition];
      const name = item.axis;
      this.selectedAxis = name;
      console.warn("\tAssigned new axis '" + this.selectedAxis + "'.");
      return true;
    }

    // No lo hemos encontrado.
    if (isCurrentAxis) {
      console.log("Not in an RCO8H canonic axis from previous axis " + this.selectedAxis);
      this.selectedAxis = "";
    }
    return false;
  }

  private static getAxisIndex4Vector(v: THREE.Vector3): number {
    const epsilon = 0.00001;
    // Comprobamos contra los 26 casos posibles.
    // Si la distancia entre v y alguno de esos 26 es 0 entonces ese es el eje.
    for (let i = 0; i < 26; ++i) {
      const item = XYZmo_RCOH8.vOrientInfo[i];
      // La resta entre v y dir debiera ser 0 para que coincidieran. Es decir que la distancia entre ambos sea CERO.
      const d = item.dir;
      const modulus = v.distanceTo(d);
      
      if ((-epsilon < modulus) && (modulus < +epsilon)) {
        const name = item.axis;
        console.log("\t\tTo [" + i + "] '" + name +"' (" + d.x + ", " + d.y + ", " + d.z + ") ===> " + modulus);
        return i;
      }
    }
    return -1;
  }

  /**
   * Dado un nombre de eje de los contenidos en vFacesNames, se devuelve el nombre del eje opuesto en cuanto a direccion.
   * Por ejemplo para "+X" devolveria -"X", para "-X-Y+Z" devolveria "+X+Y-Z", etc...
   * En caso de error (que debiera ser imposible) se devuelve la cadena vacia.
   * @param axis 
   */
  private static getOppositeAxis(axis: string): string {
    if (XYZmo_RCOH8.mOrientInfo.has(axis)) {
      return XYZmo_RCOH8.negateAxisName(axis);
    }

    return "";
  }


} // class XYZmo_RCOH8
