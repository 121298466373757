import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import CloudUploadIcon from "shared/components/icons/cloud-upload";
import DXFViewer from "../dxf/dxf-viewer";
import DXFErrorsViewer from "../dxf/dxf-errors-viewer";
import { checkDXFFile } from "../dxf/utils";
interface DXF {
  name: string;
  url: string;
  file: File;
}
interface Props {
  onChange: (dxf: DXF) => void;
  dxf_url: string | null;
  dxf_name: string | null;
  file?: File;
}

const StoreyUploader = ({ onChange, dxf_name, dxf_url, file }: Props) => {
  const [validationErrors, setValidationErrors] = useState<string[]>([]); 
  useEffect(() => {
    const getInitErrorFiles = async () => {
    const fileErrors = file ?  await checkDXFFile(file) : [];
    setValidationErrors(fileErrors);
    }
    getInitErrorFiles();
  }, [file])

  const handleChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        const blob = URL.createObjectURL(file);
        const newLayersErrors = await checkDXFFile(file);
        setValidationErrors( newLayersErrors);
        onChange({ name: file.name, url: blob, file});
      }
    },
    [onChange]
  );

  const onInputClick = ( event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const element = event.target as HTMLInputElement
    element.value = ''
  }

  return (
    <div className="flex justify-center items-center space-x-2">
      {validationErrors.length > 0 ? <DXFErrorsViewer errors={validationErrors}></DXFErrorsViewer> : <div className='h-6 w-6'></div>}
      <label>
        <CloudUploadIcon className="cursor-pointer h-6 w-6 flex items-center hover:text-gray-700" />
        <input type="file" onChange={handleChange} onClick={onInputClick} className="sr-only" />
      </label>   
      <DXFViewer url={dxf_url} name={dxf_name} />
    </div>
  );
};

export default StoreyUploader;
