import { addStorey, cloneStorey, fileInfo, fillStoreys, recalculateLevels, recalculateOrder, updateStoreyName, updateStoreys } from 'modules/struc/utils/storeys'
import { useCallback } from 'react'
import { StrucStorey } from '../../../../models/project';
import { standard } from "../../../../models/codes";

export function useStoreysForm(
  footing_level: number,
  concrete_standard: standard,
  storeys: StrucStorey[],
  setStoreys: (val: StrucStorey[]) => void,
) {

  const recalculateStoreys = useCallback(() => {
    const updatedStoreys = recalculateLevels(storeys, footing_level)
    setStoreys(updatedStoreys)
  }, [footing_level, setStoreys, storeys])

  const sortStoreys = useCallback((source: number, destination: number) => {
    const updatedStoreys = recalculateOrder(
      storeys,
      source,
      destination,
      footing_level
    )
    setStoreys(updatedStoreys)
  }, [footing_level, setStoreys, storeys]);

  const changeStoreyName = useCallback((storey: StrucStorey, newName: string) => {
    const updatedStorey = updateStoreyName(storey, storeys, newName);
    setStoreys(updatedStorey);
  }, [setStoreys, storeys]);

  const updateStorey = useCallback((storey: StrucStorey) => {
    const updatedStorey = updateStoreys(storey, storeys, footing_level);
    setStoreys(updatedStorey);
  }, [footing_level, setStoreys, storeys]);

  const uploadBulkDXF = useCallback((files: fileInfo[]) => {
    const updatedStoreys = fillStoreys(files.length, [], files, concrete_standard, footing_level);
    setStoreys(updatedStoreys);
  }, [concrete_standard, footing_level, setStoreys]);

  const uploadDXF = useCallback((file?: fileInfo, copies: number = 1) => {
    let updatedStoreys: StrucStorey[] = storeys;
    for (let i = 0; i < copies; i++) {
      updatedStoreys = addStorey(updatedStoreys, footing_level, concrete_standard, file);
    }
    setStoreys(updatedStoreys);
  }, [concrete_standard, footing_level, setStoreys, storeys]);

  const copyStorey = useCallback((storey: StrucStorey) => {
    const newStoreys = cloneStorey(storeys, storey);
    
    const storeysUpdated = recalculateLevels(newStoreys, footing_level);
    setStoreys([...storeysUpdated]);
  }, [footing_level, setStoreys, storeys]);

  const deleteStorey = useCallback((storey: StrucStorey) => {
    const indx = storeys.indexOf(storey);
    storeys.splice(indx, 1);
    const storeysUpdated = recalculateLevels(storeys, footing_level);
    setStoreys([...storeysUpdated]);
  }, [footing_level, setStoreys, storeys]);

  return {
    uploadBulkDXF,
    uploadDXF,
    recalculateStoreys,
    sortStoreys,
    changeName: changeStoreyName,
    updateStorey,
    deleteStorey,
    copyStorey,
    setStoreys,
  }
}
