import * as THREE from "three";
import { IPoint } from "lib/math/types";
import { getAuxMeshBasicMaterial } from "lib/materials";

function createExtrusionShape(contourSection: IPoint[], holesSections: IPoint[][] = []): THREE.Shape {
  const vect2d = contourSection.length ? contourSection.map(p => new THREE.Vector2(p.x, p.y)) : undefined;
  const shape = new THREE.Shape(vect2d);
  const pathHoles: THREE.Path[] = [];
  if (holesSections.length) {
    for (let i = 0; i < holesSections.length; i++) {
      const hole = holesSections[i];
      if (hole.length > 2) {
        const vect2d: THREE.Vector2[] = []
        for (let j = 0; j < hole.length; j++) {
          vect2d.push(new THREE.Vector2(hole[j].x, hole[j].y));
        }
        const path = new THREE.Path(vect2d);
        pathHoles.push(path);
      }
    }
    shape.holes = pathHoles;
  }
  return shape;
}

export function createExtrusionMesh(contourSection: IPoint[], depth: number, holesSections: IPoint[][] = [], material?: THREE.Material): THREE.Mesh {
  const extrudeParam = {
    steps: 1,
    depth: depth,
    bevelEnabled: false,
  }
  const shape = createExtrusionShape(contourSection, holesSections);
  const geometry = new THREE.ExtrudeBufferGeometry(shape, extrudeParam);
  // geometry.clearGroups();
  // const normGeom = new THREE.BufferGeometry().fromGeometry(geometry);
  const mat = material ?? getAuxMeshBasicMaterial();
  const mesh = new THREE.Mesh(geometry, mat);
  mesh.geometry.computeVertexNormals();
  mesh.geometry.computeBoundingBox();
  mesh.geometry.computeBoundingSphere();
  return mesh;
}

export function updateExtrusionMesh(mesh: THREE.Mesh, contourSection: IPoint[], depth: number, holesSections: IPoint[][] = []) {
  const extrudeParam = {
    steps: 1,
    depth: depth,
    bevelEnabled: false,
  }
  const shape = createExtrusionShape(contourSection, holesSections);
  const geom = new THREE.ExtrudeBufferGeometry(shape, extrudeParam);
  mesh.geometry = geom;
  mesh.geometry.computeVertexNormals();
  mesh.geometry.computeBoundingBox();
  mesh.geometry.computeBoundingSphere();
}

export function getVerticalRectangle(p0: IPoint, p1: IPoint) {
  if (p0.z < p1.z) {
    const a = { x: p0.x, y: p0.y, z: p1.z };
    const b = { x: p1.x, y: p1.y, z: p0.z };
    return [p0, a, p1, b];
  } else {
    const a = { x: p0.x, y: p0.y, z: p1.z };
    const b = { x: p1.x, y: p1.y, z: p0.z };
    return [p0, b, p1, a];
  }
}