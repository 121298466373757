import { AlignedDimensionEditDataCommand } from "lib/commands/dimension/aligned-dim";
import { AlignedDimensionData, alignedDimensionParam } from "lib/models/dimension/aligned-dim";
import { DimensionDataDefinitionHandler, dimensionStyleParam } from "./dimension";

export class AlignedDimensionDefinitionHandler extends DimensionDataDefinitionHandler<alignedDimensionParam> {

  protected data: AlignedDimensionData;

  public saveAndRegenerateStyle = (newStyle: dimensionStyleParam) => {
    const alignedDimensionDefinition = this.checkAndChangedStyle(newStyle) as alignedDimensionParam;
    if (alignedDimensionDefinition) {
      const command = new AlignedDimensionEditDataCommand(this.data, alignedDimensionDefinition, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  };
}