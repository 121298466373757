import { GraphicProcessor } from "lib/graphic-processor";
import { ConeData } from "lib/models/solids/cone";
import { CubeData } from "lib/models/solids/cube";
import { CylinderData } from "lib/models/solids/cylinder";
import { PlaneData } from "lib/models/solids/plane";
import { PPrismData } from "lib/models/solids/pprism";
import { RegionData } from "lib/models/solids/region";
import { RPrismData } from "lib/models/solids/rprism";
import { ConeCommand } from "../primitives/cone";
import { CubeCommand } from "../primitives/cube";
import { CylinderCommand } from "../primitives/cylinder";
import { PlaneCommand } from "../primitives/plane";
import { PPrismCommand } from "../primitives/pprism";
import { RegionCommand } from "../primitives/region";
import { RPrismCommand } from "../primitives/rprism";
import { PrimitiveCommandCreator } from "./primitives";

export class RegionCommandCreator extends PrimitiveCommandCreator {
  protected createDataFromCommand(graphicProc: GraphicProcessor, objData: RegionData, layerId: string): RegionData {
    const { ptos2D, basePoint, rotation, scale, offset } = objData.definition;
    const command = new RegionCommand(
      ptos2D, basePoint, rotation, scale, offset,
      layerId,
      graphicProc,
      objData.material
    );
    command.execute();
    const newObjData = command.createdData;
    command.delete();
    return newObjData;
  }
}
export class CubeCommandCreator extends PrimitiveCommandCreator {
  protected createDataFromCommand(graphicProc: GraphicProcessor, objData: CubeData, layerId: string): CubeData {
    const { side, basePoint, rotation, scale, offset } = objData.definition;
    const command = new CubeCommand(
      side, basePoint, rotation, scale, offset,
      layerId,
      graphicProc,
      objData.material
    );
    command.execute();
    const newObjData = command.createdData;
    command.delete();
    return newObjData;
  }
}
export class CylinderDimCommandCreator extends PrimitiveCommandCreator {
  protected createDataFromCommand(graphicProc: GraphicProcessor, objData: CylinderData, layerId: string): CylinderData {
    const { radius, height, basePoint, rotation, scale, offset } = objData.definition;
    const command = new CylinderCommand(
      radius, height, basePoint, rotation, scale, offset,
      layerId,
      graphicProc,
      objData.material
    );
    command.execute();
    const newObjData = command.createdData;
    command.delete();
    return newObjData;
  }
}
export class PlaneCommandCreator extends PrimitiveCommandCreator {
  protected createDataFromCommand(graphicProc: GraphicProcessor, objData: PlaneData, layerId: string): PlaneData {
    const { horizontalDivisions, verticalDivisions, width, height, basePoint, rotation, scale, offset } = objData.definition;
    const command = new PlaneCommand(
      width, height,
      horizontalDivisions, verticalDivisions,
      basePoint, rotation, scale, offset,
      layerId,
      graphicProc,
      objData.material
    );
    command.execute();
    const newObjData = command.createdData;
    command.delete();
    return newObjData;
  }
}
export class ConeCommandCreator extends PrimitiveCommandCreator {
  protected createDataFromCommand(graphicProc: GraphicProcessor, objData: ConeData, layerId: string): ConeData {
    const { upperRadius, lowerRadius, height, basePoint, rotation, scale, offset } = objData.definition;
    const command = new ConeCommand(
      upperRadius, lowerRadius,
      height,
      basePoint, rotation, scale, offset,
      layerId,
      graphicProc,
      objData.material
    );
    command.execute();
    const newObjData = command.createdData;
    command.delete();
    return newObjData;
  }
}
export class RPrismCommandCreator extends PrimitiveCommandCreator {
  protected createDataFromCommand(graphicProc: GraphicProcessor, objData: RPrismData, layerId: string): RPrismData {
    const { sideX, sideY, sideZ, basePoint, rotation, scale, offset } = objData.definition;
    const command = new RPrismCommand(
      sideX, sideY, sideZ,
      basePoint, rotation, scale, offset,
      layerId,
      graphicProc,
      objData.material
    );
    command.execute();
    const newObjData = command.createdData;
    command.delete();
    return newObjData;
  }
}
export class PPrismCommandCreator extends PrimitiveCommandCreator {
  protected createDataFromCommand(graphicProc: GraphicProcessor, objData: PPrismData, layerId: string): PPrismData {
    const { radius, height, sides, open, basePoint, rotation, scale, offset } = objData.definition;
    const command = new PPrismCommand(
      radius, height, sides, open,
      basePoint, rotation, scale, offset,
      layerId,
      graphicProc,
      objData.material
    );
    command.execute();
    const newObjData = command.createdData;
    command.delete();
    return newObjData;
  }
}
