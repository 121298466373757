import { MeshProjectStatusMPValueEnum } from "lib/apis/mesh-projects/api";
import { StructModel3D } from "lib/helpers/structmodel3d";
import { waffleGeomGenerator } from "lib/models-struc/waffle/waffle-geometry";
import { useMainGraphicContext } from "modules/cad/components/viewport/context";
import { useCallback, useEffect, useState } from "react";
import { useTimer } from "shared/components/ui/hooks/use-timer";
import { useFavicon } from "shared/utils/hooks/use-favicon";
import { useStrucProjectContext } from "../../context";
import useSaveStrucProject from "../../project/hook/use-save-project";

export interface meshProjectEvent {
  name: string;
  date: Date;
}

let intervalMeshing: NodeJS.Timeout;

export function useMeshing() {

  const graphicProc = useMainGraphicContext();
  const saveStructProject = useSaveStrucProject(graphicProc);
  const { meshManager } = useStrucProjectContext();

  const { setDefault, setRunning } = useFavicon();

  const { seconds, setIsActive } = useTimer();

  const [status, setStatus] = useState(MeshProjectStatusMPValueEnum.Running);
  const [error, setError] = useState<string | undefined>(undefined);
  const [lastEvents, setLastEvents] = useState<meshProjectEvent[]>([]);

  const getLastEvents = useCallback(async () => {
    const events = await meshManager.getMeshingLastEvents();
    if (events.length) {
      setLastEvents([...events]);
    }
  }, [meshManager]);

  const cancelMeshing = async () => {
    try {
      if (intervalMeshing) clearInterval(intervalMeshing);
      const canceled = await meshManager.cancelMeshing();
      if (canceled) {
        const newStatus = await meshManager.getMeshingStatus();
        if (newStatus) {
          setStatus(newStatus);
          if (newStatus === MeshProjectStatusMPValueEnum.Canceled) {
            endProcess();
            await getLastEvents();
          }
        }
      }
    } catch (err: any) {
      console.error("[cancelMeshing] something go wrong ")
    }
  }

  const endProcess = useCallback(() => {
    if (intervalMeshing) clearInterval(intervalMeshing);
    setIsActive(false);
    setDefault();
  }, [setDefault, setIsActive])

  useEffect(() => {
    const initMeshing = async () => {
      setIsActive(true);
      setRunning();
      const newStatus = await meshManager.getMeshingStatus();
      if (newStatus !== MeshProjectStatusMPValueEnum.Running) {
        setStatus(MeshProjectStatusMPValueEnum.Running);
        setLastEvents([{ name: "Calculating waffles", date: new Date() }]);
        await waffleGeomGenerator.waffleReGenerate([], graphicProc);
        setLastEvents([{ name: "Init meshing", date: new Date() }]);
      }

      const needsSaveProject = await meshManager.launchMeshingProject(graphicProc);
      if (needsSaveProject) await saveStructProject();

      await getLastEvents();

      intervalMeshing = setInterval(() => {
        const checkStatus = async () => {
          const newStatus = await meshManager.getMeshingStatus();
          if (newStatus) {
            setStatus(newStatus);
            if (newStatus !== MeshProjectStatusMPValueEnum.Running) {
              if (newStatus === MeshProjectStatusMPValueEnum.Done) {
                const jsonMesh = await meshManager.downloadMesh(graphicProc);
                StructModel3D.createStructModel3D_from_JSON_graphicProcessor(jsonMesh, graphicProc);
                endProcess();
              } else {
                console.assert(false);
                throw new Error("Mesh process has failed");
              }
            }
          }
          // update last meshing events
          await getLastEvents();
        }
        checkStatus().catch((err) => {
          console.error(err);
          setStatus(MeshProjectStatusMPValueEnum.Error);
          setError(err.toJSON ? err.toJSON().message : err.message);
          // update last meshing events
          getLastEvents();
          endProcess();
        })

      }, 15000); // send status request each 15 seconds
    }
    initMeshing().catch((err) => {
      console.error(err);
      setStatus(MeshProjectStatusMPValueEnum.Error);
      endProcess();
    })

    return () => {
      if (intervalMeshing) {
        clearInterval(intervalMeshing);
      }
    }
  }, []);

  return { status, error, seconds, lastEvents, cancelMeshing };
}