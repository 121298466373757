import { compressAndUploadToS3, downloadFileFromS3, requestStatus } from "lib/apis/utils";
import { TasksApi, WaffleGenerationRequest, WaffleSlabsApi } from "lib/apis/waffle/api";
import { GraphicProcessor } from "lib/graphic-processor";
import { EcoreMeshExporter } from "lib/input-output/e-core/exporter/mesh-exporter";
import { Geometry } from "modules/struc/models/ecore/geometry";
import { MeshProject } from "modules/struc/models/ecore/mesh";
import { GeoRepresentation } from "modules/struc/models/ecore/representation";
import { gunzipSync } from "zlib";
import { MeshProjectWithErrors } from "../mesh/mesh";
import { meshManager } from "../mesh/meshmodel-manager";

export interface waffleArea {
  Name: string;
  WaffleGeo: GeoRepresentation<Geometry>[];
  SolidGeo: GeoRepresentation<Geometry>[];
  COG: number[];
  EquiVolume: number;
}

class WaffleGeometryAPI {

  private waffles: Map<string, waffleArea> = new Map();

  public status: requestStatus = requestStatus.VOID;

  private checkMeshProjectErrors(graphicProc: GraphicProcessor): MeshProjectWithErrors | undefined {
    const projMng = graphicProc.getProjectModelManager();
    if (projMng.project) {
      const errors: string[] = []; // = getProjectErrors(projMng.project, false);
      if (errors.length) {
        return { name: projMng.project.name, errors };
      }
    }
  }
  private exportMeshProjectStruct(graphicProc: GraphicProcessor): MeshProjectWithErrors | MeshProject {
    try {
      const errors = this.checkMeshProjectErrors(graphicProc);
      if (errors) {
        throw errors;
      } else {
        const mesh = meshManager.mesh;
        const projMng = graphicProc.getProjectModelManager();
        const eCoreAdapter = new EcoreMeshExporter();
        return eCoreAdapter.exportMeshProject(mesh, projMng.project);
      }
    } catch (err) {
      this.status = requestStatus.ERROR;
      console.error(err);
      throw new Error("Error to export MESH ecore model")
    }
  }

  hasWaffleGeometry(slabName: string): boolean {
    return this.waffles.has(slabName);
  }
  getWaffleGeometry(slabName: string): waffleArea | undefined {
    return this.waffles.get(slabName);
  }
  async waffleReGenerate(waffleNames: string[] = [], graphicProc: GraphicProcessor): Promise<waffleArea[]> {
    this.waffles.clear();
    return this.calculateWaffles(waffleNames, graphicProc);
  }
  async waffleGenerate(waffleNames: string[] = [], graphicProc: GraphicProcessor): Promise<waffleArea[]> {
    if (this.waffles.size) return [];
    return this.calculateWaffles(waffleNames, graphicProc);
  }

  private async calculateWaffles(waffleNames: string[] = [], graphicProc: GraphicProcessor): Promise<waffleArea[]> {
    try {
      const projMng = graphicProc.getProjectModelManager();
      const ProjectId = projMng.project.id
      const meshProjectData = this.exportMeshProjectStruct(graphicProc);

      const resTask = await this.generateWaffleTask(ProjectId, waffleNames);

      const taskId = resTask.data.Id;
      const url = (resTask.data as any).UploadUrl as string;
      await compressAndUploadToS3(JSON.stringify(meshProjectData), url);

      const downloadUrl = await this.runWaffleTask(taskId);

      const arrayBuffer = await downloadFileFromS3(downloadUrl);
      const stringMesh = gunzipSync(Buffer.from(arrayBuffer)).toString();
      const waffle = JSON.parse(stringMesh) as waffleArea[];

      const strMng = graphicProc.getStructuralModelManager();
      waffle.forEach(w => {
        const slab = strMng.getSlabFromName(w.Name)!;
        slab.definition.cog = { x: w.COG[0], y: w.COG[1], z: w.COG[2] };
        slab.definition.EquiVolume = w.EquiVolume
        this.waffles.set(w.Name, w);
      });
      return waffle;

    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  private async generateWaffleTask(ProjectId: string, waffleNames: string[]) {
    try {
      const waffleApi = new WaffleSlabsApi();
      const body: WaffleGenerationRequest = { ProjectId }
      if (waffleNames.length) body.Params = { "WaffleNames": waffleNames };
      const resTask = await waffleApi.waffleGeneratePost(body);
      return resTask;
    } catch (error) {
      throw error;
    }
  }
  private async runWaffleTask(taskId: string): Promise<string> {
    try {
      const taskApi = new TasksApi();
      let downloadUrl: string = "";
      let status = requestStatus.RUNNING;
      let count = 0;
      while (status !== requestStatus.DONE) {
        if (count > 15 * 60) { // Timeout of 15 min
          throw new Error("Timeout");
        }
        const res = await taskApi.tasksIdGet(taskId);
        status = (res.data as any).MPStatus.MPValue;
        if (status === requestStatus.ERROR) {
          throw res;
        }
        console.info("[CALCULATE WAFFLE SLAB] " + status);
        downloadUrl = (res.data as any).Result.FileUrl;
        await new Promise(r => setTimeout(r, 5000));
        count += 5;
      }
      return downloadUrl;
    } catch (error) {
      throw error;
    }
  }
}

export let waffleGeomGenerator = new WaffleGeometryAPI();