import { FC, useCallback, useState } from "react";
import { SecondaryButton } from "shared/components/ui/button";
import styled from "styled-components";
import Table from "../../../../shared/components/ui/table/table";
import { Column } from "react-table";
import { GraphicProcessor } from "lib/graphic-processor";
import { useMainGraphicContext } from "modules/cad/components/viewport/context";
import { userMessageEvents } from "lib/events/user-messages";
import { windhypothesisManager } from "lib/models-struc/hypothesis/wind";
import { waffleGeomGenerator } from "lib/models-struc/waffle/waffle-geometry";
import RenderCounter from "shared/components/ui/renderer-component";

interface dataTable {
  storey: string;
  fx: number;
  fy: number;
}
function getData(graphicProc: GraphicProcessor) {
  const data: dataTable[] = [];
  const structuralModelManager = graphicProc.getStructuralModelManager();
  const allStoreys = structuralModelManager.currBuilding.storeys;
  for (let i = allStoreys.length - 1; i >= 0; i--) {
    const storey = allStoreys[i];
    data.push({
      storey: storey.name,
      fx: undefined!,
      fy: undefined!
    });
  }
  return data;
}

const columns: Column<{}>[] = [
  { Header: "Storey", accessor: "storey" },
  { Header: "Fx (kN)", accessor: "fx" },
  { Header: "Fy (kN)", accessor: "fy" },
];

interface Props {
  onClose: () => void;
}

const WindHypothesisWizard: FC<Props> = ({ onClose }) => {

  const gp = useMainGraphicContext();
  const [dataTable, setDataTable] = useState(() => getData(gp));

  const [calculating, setCalculating] = useState<boolean>(false);

  const updateData = useCallback((rowIndex: number, columnId: keyof dataTable, value: string) => {
    setDataTable(prev =>
      prev.map((row, index) => {
        if (index === rowIndex) {
          return { ...prev[rowIndex], [columnId]: parseFloat(value) }
        }
        return row;
      })
    )
  }, []);

  const calculate = useCallback(async () => {
    if (dataTable.some(d => (d.fx === undefined || d.fy === undefined))) {
      userMessageEvents.dispatchError("You have to fill all the data in the table");
    } else {
      setCalculating(true);
      await waffleGeomGenerator.waffleGenerate([], gp);
      const data = dataTable.map(d => {
        return {
          storey: d.storey,
          fx: d.fx * 1000,
          fy: d.fy * 1000,
        }
      })
      windhypothesisManager.initWindHypothesis(gp, data);
      onClose();
    }
  }, [dataTable, gp, onClose]);

  return (
    <Wrapper className="text-xs">
      <RenderCounter name={"WindHypothesisWizard"} />
      <Header>
        <h1>Wind hypothesis wizard</h1>
      </Header>
      <Content>
        <Box className="layers" disable={calculating}>
          <Table
            columns={columns}
            data={dataTable}
            updateMyData={updateData}
          />
        </Box>
        <BottomToolBar disable={false}>
          <SecondaryButton onClick={calculate} loading={calculating}>
            Calculate
          </SecondaryButton>
          <SecondaryButton onClick={onClose} disabled={calculating}>
            Cancel
          </SecondaryButton>
        </BottomToolBar>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow: hidden;
  border-radius: 4px;
  width: 250px;
  margin: -32px;
  background-color: var(--color-gray-300);
`;
const Header = styled.div`
  background-color: var(--color-gray-300);
  border-bottom: 2px solid var(--color-primary);
  padding: 8px;
  display: flex;
  justify-content: space-between;
`;
const Content = styled.div`
  display: grid;
  /* grid-template-rows: 36px auto 36px; */
  grid-template-columns: auto;
  gap: 4px;
  padding: 8px;
  height: 100%;
`;
const Box = styled.div<{ disable: boolean }>`
  background-color: var(--color-gray-200);
  padding: 8px;
  border-radius: 4px;
  overflow: hidden;

  pointer-events: ${(props) => (props.disable ? "none" : "all")};
  opacity: ${(props) => (props.disable ? 0.8 : 1)};
`;
const BottomToolBar = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
export default WindHypothesisWizard;
