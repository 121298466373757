import DotsVerticalIcon from "shared/components/icons/dots-vertical";
import { SecondaryButton } from "shared/components/ui/button";
import Menu from "shared/components/ui/menu";
import styled from "styled-components";
import CloseIcon from "../icons/close";
import PropertiesSelect from "modules/cad/components/properties/component-select";
import { useDimensionManager } from "./hooks/use-dimension";
import { ChangeEvent, useEffect, useMemo } from "react";
import PanelPropertiesForm from "../properties/properties-panel-form";
import HorizontalResizer from "shared/components/ui/resizer/horizontal-resizer";
import ViewportLite from "../viewport/viewport-lite";
import { GraphicProcessor } from "lib/graphic-processor";

interface Props { onClose: () => void }

const DimensionStyles = ({ onClose }: Props) => {

  const graphicProc = useMemo(() => new GraphicProcessor("dimlite"), []);

  const {
    clearDimensionObjs,
    iniDimensionObjs,
    selectStyle,
    editStyle,
    selectedStyle,
    dimensionLoaded,
    styleProperties,
    saveChanges,
    deleteStyle,
    createStyle,
    setCurrentStyle,
  } = useDimensionManager(graphicProc);

  useEffect(() => {
    iniDimensionObjs();
    return () => {
      clearDimensionObjs();
    }
  }, []);

  return (
    <Wrapper className="text-xs rounded">
      <Header>
        <h1>Dimension styles manager</h1>
        <button onClick={onClose}>
          <CloseIcon className="h-4 w-4" />
        </button>
      </Header>
      <Content>
        <Box className={"flex gap-2 items-center justify-between"}>
          <label className={"text-xs text-gray-400 pr-2"}>{"Styles:"}</label>
          <PropertiesSelect
            srOnly={true}
            value={selectedStyle.styleId}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              const newSlab = event.target.value;
              selectStyle(newSlab);
            }}
          >
            {dimensionLoaded.map((val, i) => (
              <option key={i} value={val.styleId}> {`${val.name} [${val.styleId}]`} </option>
            ))}
          </PropertiesSelect>
          <Menu>
            <Menu.Button>
              <DotsVerticalIcon />
            </Menu.Button>
            <Menu.List>
              <Menu.Item onSelect={setCurrentStyle}> Set current </Menu.Item>
              <Menu.Item onSelect={createStyle}> Create new </Menu.Item>
              <Menu.Item onSelect={deleteStyle}> Delete </Menu.Item>
            </Menu.List>
          </Menu>
        </Box>
        <HorizontalResizer id={"dimModal"}
          leftClassName={"w-1/2 p-2 overflow-auto layers"}
          leftChild={<PanelPropertiesForm panelProp={{ propValue: styleProperties, propCallback: editStyle }} />}
          rightClassName={"w-1/2"}
          rightChild={<ViewportLite graphicProc={graphicProc}/>}
        />
        <Box className={"flex items-center justify-around"}>
          <SecondaryButton onClick={saveChanges}>
            Save
          </SecondaryButton>
          <SecondaryButton onClick={onClose}>
            Cancel
          </SecondaryButton>
        </Box>
      </Content>
    </Wrapper>
  )
};

const Wrapper = styled.div`
  overflow: hidden;
  width: 800px;
  margin: -32px;
  background-color: var(--color-gray-300);
`;
const Header = styled.div`
  background-color: var(--color-gray-300);
  border-bottom: 2px solid var(--color-primary);
  padding: 8px;
  display: flex;
  justify-content: space-between;
`;
const Content = styled.div`
  display: grid;
  grid-template-rows: 36px 340px 36px;
  grid-template-columns: auto;
  gap: 4px;
  padding: 8px;
  height: 100%;
`;
const Box = styled.div`
  background-color: var(--color-gray-200);
  padding: 8px;
  border-radius: 4px;
  overflow: hidden;
`;

export default DimensionStyles;