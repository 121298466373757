import { IPoint } from "./types";
import { vectorDist2D, vectorDist3D } from "./distance";
import { IPolylineParam } from "./line";
import { getAngleBetweenAzimuts, lineAzimut2p, normalizeAngle } from "./angles";

/**
 * Calculo del perimetro 2D de una serie de puntos que forman una polilinea cerrada y sin autocruces.
 * Ojo, que unimos automaticamente el ultimo con el primero y no usamos aquel caduco convenio de que
 * se repetian el primero y el ultimo.
 *
 * @export
 * @param {IPoint[]} points
 * @returns {number}
 */
export function calculatePerimeter2D(points: IPoint[]): number {
  const numPoints = points.length;
  let perimeter2D = 0;
  let pPrev = points[numPoints - 1];

  for (let i = 0; i < numPoints; i++) {
    const pCurr = points[i];
    perimeter2D += vectorDist2D(pPrev, pCurr);
    pPrev = pCurr;
  }
  return perimeter2D;
}

/**
 * Calculo del perimetro 3D (con los mismos requisitos del perimetro 2D).
 *
 * @export
 * @param {IPoint[]} points
 * @returns {number}
 */
export function calculatePerimeter3D(points: IPoint[]): number {
  const numPoints = points.length;
  let perimeter3D = 0;
  let pPrev = points[numPoints - 1];

  for (let i = 0; i < numPoints; i++) {
    const pCurr = points[i];
    perimeter3D += vectorDist3D(pPrev, pCurr);
    pPrev = pCurr;
  }
  return perimeter3D;
}

export function calculatePerimeterPolyArc(param: IPolylineParam): number {
  const points = param.points;
  const arcs = param.arcs;

  const numPoints = param.points.length;
  let perimeter3D = 0;
  let pPrev = points[numPoints - 1];

  for (let i = 0; i < numPoints; i++) {
    const pCurr = points[i];
    const arc = arcs[i];
    if (arc) {
      const azimutO = normalizeAngle(lineAzimut2p(arc.center, pPrev));
      const azimutF = normalizeAngle(lineAzimut2p(arc.center, pCurr));
      let angleCenter = getAngleBetweenAzimuts(azimutO, azimutF, arc.direction);
      perimeter3D += angleCenter * arc.radius;
    } else {
      perimeter3D += vectorDist3D(pPrev, pCurr);
    }
    pPrev = pCurr;
  }
  return perimeter3D;
}
