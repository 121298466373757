import { GraphicProcessor } from "lib/graphic-processor";
import { IPoint } from "lib/math/types";
import { Cad3dOp } from "../base";
import { cadOpType, OpFactory } from "../factory";

export interface IOpFun { type: cadOpType, graphicProcessor: GraphicProcessor; args: any[]; }

export class MultiOp extends Cad3dOp {

  public opType = cadOpType.MULTIOP;

  public operationsList: Cad3dOp[];

  constructor(childs: IOpFun[]) {
    super();
    this.operationsList = childs.map((c) => {
      const op = OpFactory.instanciateOP<any>(c.type, c.graphicProcessor, c.args);
      op.launchSelectOnFinish = false;
      return op;
    });
  }

  protected iniSettingsOp(): void {
    // Read setting from array operations
  }

  public async start() {
    this.iniSettingsOp();
    this.initializeSnap();
    this.initializeWorkingPlane();

    this.registerCancel();
    this.registerInputs();
    this.registerUpdaters();

    for (const c of this.operationsList) {      
      c.workingPlane = this.workingPlane;
      c.start();
    }
  }

  public moveLastPoint(p: IPoint) {
    for (const c of this.operationsList) {
      c.moveLastPoint(p);
    }
  }

  public setLastPoint(): void {
    this.save();
  }

  public save(): void {
    for (const c of this.operationsList) {
      c.lastPoint = this.lastPoint;
      c.save();
    }
    this.endOperation();
  }
}