import { style } from "lib/styles/style-cache";
import { textMultiPosTypeV, textMultiPosTypeH } from "lib/text/styles";
import { dimArrows } from "./arrow";

// Standard + Precisión unidad alternativa = 3; 
// Factor alt sub-cero= 100.000000; 
// Precisión tolerancia alternativa = 3; 
// Tamaño flecha  = 2.500000; 
// Tamaño de la marca de centro = 2.500000; 
// Precisión: = 2; 
// Distancia de línea de cota = 3.750000; 
// Separador decimales = ,; 
// Ampliar líneas de cota = 1.250000; 
// Línea de extensión offset = 0.625000; 
// Desfase de texto = 0.625000; 
// Pos vertical texto = 1; 
// Precisión tolerancia = 2; 
// Alineación interior del texto = Off; 
// Línea forzada de cota  = On; 
// Alineación exterior de texto = Off; 
// Posición vertical tolerancia = 0; 
// Altura del texto = 2.500000; 
// Supresión cero lineal = 8; 
// Supresión de ceros = 8

// ISO-25 + Alt precision = 2; 
// Alt sub-zero factor= 100.000000; 
// Alt tol precision = 2; 
// Arrow size  = 0.180000; 
// Center mark size = 0.090000; 
// Precision = 4; 
// Dim line spacing = 0.380000; 
// Decimal separator = .; 
// Ext line extension = 0.180000; 
// Ext line offset = 0.062500; 
// Text offset = 0.090000; 
// Text pos vert = 0; 
// Tol precision = 4; 
// Text inside align = On; 
// Dim line forced  = Off; 
// Text outside align = On; 
// Tol pos vert = 1; 
// Text height = 0.180000; 
// Linear Zero suppression = 0; 
// Zero Suppression = 0

export interface IDimBuildProperties {
  name: string;
  descripcion: string;
  /** Prolongación de líneas laterales */
  ext: number;
  /** Distancia entre el inicio de las líneas laterales y el objeto */
  offset: number;
  /** Distancia del objeto a la línea Base */
  distBaseLine: number;
  /** Bloqueo de la distancia del objeto a la línea Base */
  blockDistBaseLine: boolean;
  // (según http://acotacion-normalizada.blogspot.com.es/)
  /** Mínima distancia de la primera linea base de cota */
  minDistBaseLine1: number;
  /** Mínima distancia entre linea base de cota */
  minDistBaseLine2: number;
  /** Prolongación de línea Base */
  extBaseLine: number;

  /** Id de estilo de flechas */
  arrowId: dimArrows;

  /** Objeto que describe las propiedades del texto */
  textStyleId: string;
  /** Tipo de punto base horizontal
   *
   * @type {textMultiPosTypeH}
   * @memberof IDimBuildProperties
   */
  basePointH: textMultiPosTypeH;
  /** Tipo de punto base vertical
   *
   * @type {textMultiPosTypeV}
   * @memberof IDimBuildProperties
   */
  basePointV: textMultiPosTypeV;
  /** Distancia de la etiqueta al CENTRO de la acotación paralela a la línea base de acotación */
  fontOffsetY: number;
  /** Distancia entre línea Base y etiqueta de texto */
  fontOffsetX: number;
  /** Rotación de la etiqueta respecto a la línea base */
  fontRotation: number;
  /** Escala de la etiqueta */
  fontScale: number;
}

export class DimStyleBuilder implements style<IDimBuildProperties>, IDimBuildProperties {

  public default: boolean = false;
  public styleId: string;

  // Nombre identificador del estilo
  public name: string = "Nuevo estilo";
  // Descripción del estilo
  public descripcion: string = "descripcion";

  // Escala de creación y aplicar a visualización
  public scale: number = 0.001; // 1:1000
  public applyScale: boolean = true;

  public distBaseLine: number = 10;
  public blockDistBaseLine: boolean = false;

  public minDistBaseLine1: number = 8; // 8 mm a aescala 1:1000 --> 8 metros
  public minDistBaseLine2: number = 5; // 5 mm a aescala 1:1000 --> 5 metros

  public extBaseLine: number = 1;
  public offset: number = 1;
  public ext: number = 1;

  public arrowId: dimArrows = dimArrows.ARROW;

  public textStyleId: string;
  public basePointV: textMultiPosTypeV = textMultiPosTypeV.MEDIAN;
  public basePointH: textMultiPosTypeH = textMultiPosTypeH.MIDDLE;
  public fontOffsetX: number = 0;
  public fontOffsetY: number = 0.5;

  public fontRotation: number = 0;
  public fontScale: number = 1;

  constructor(opts?: IDimBuildProperties) {
    if (opts !== undefined) {
      if (opts.name !== undefined) this.name = opts.name;
      if (opts.descripcion !== undefined) this.descripcion = opts.descripcion;
      this.override(opts);
    }
  }

  public clone() {
    const dolly = new DimStyleBuilder(this);
    dolly.default = this.default;
    dolly.styleId = this.styleId;
    return dolly;
  }

  public override(opts: Partial<IDimBuildProperties>) {
    if (opts) {
      if (opts.distBaseLine !== undefined) this.distBaseLine = opts.distBaseLine;
      if (opts.blockDistBaseLine !== undefined) this.blockDistBaseLine = opts.blockDistBaseLine;
      if (opts.minDistBaseLine1 !== undefined) this.minDistBaseLine1 = opts.minDistBaseLine1;
      if (opts.minDistBaseLine2 !== undefined) this.minDistBaseLine2 = opts.minDistBaseLine2;

      if (opts.extBaseLine !== undefined) this.extBaseLine = opts.extBaseLine;
      if (opts.offset !== undefined) this.offset = opts.offset;
      if (opts.ext !== undefined) this.ext = opts.ext;

      if (opts.arrowId !== undefined) this.arrowId = opts.arrowId;

      if (opts.textStyleId !== undefined) this.textStyleId = opts.textStyleId;
      if (opts.basePointV !== undefined) this.basePointV = opts.basePointV;
      if (opts.basePointH !== undefined) this.basePointH = opts.basePointH;
      if (opts.fontOffsetY !== undefined) this.fontOffsetY = opts.fontOffsetY;
      if (opts.fontOffsetX !== undefined) this.fontOffsetX = opts.fontOffsetX;
      if (opts.fontRotation !== undefined) this.fontRotation = opts.fontRotation;

      if (opts.fontScale !== undefined) this.fontScale = opts.fontScale;
    }
  }
}

export function getCustomDimStyleProperties(customDimStyle: DimStyleBuilder, defDimStyle: DimStyleBuilder): Partial<IDimBuildProperties> {
  const dimStyleProperties: Partial<IDimBuildProperties> = {};
  if (customDimStyle.distBaseLine !== defDimStyle.distBaseLine)
    dimStyleProperties.distBaseLine = customDimStyle.distBaseLine;
  if (customDimStyle.blockDistBaseLine !== defDimStyle.blockDistBaseLine)
    dimStyleProperties.blockDistBaseLine = customDimStyle.blockDistBaseLine;
  if (customDimStyle.minDistBaseLine1 !== defDimStyle.minDistBaseLine1)
    dimStyleProperties.minDistBaseLine1 = customDimStyle.minDistBaseLine1;
  if (customDimStyle.minDistBaseLine2 !== defDimStyle.minDistBaseLine2)
    dimStyleProperties.minDistBaseLine2 = customDimStyle.minDistBaseLine2;
  if (customDimStyle.extBaseLine !== defDimStyle.extBaseLine)
    dimStyleProperties.extBaseLine = customDimStyle.extBaseLine;
  if (customDimStyle.offset !== defDimStyle.offset)
    dimStyleProperties.offset = customDimStyle.offset;
  if (customDimStyle.ext !== defDimStyle.ext)
    dimStyleProperties.ext = customDimStyle.ext;
  if (customDimStyle.arrowId !== defDimStyle.arrowId)
    dimStyleProperties.arrowId = customDimStyle.arrowId;
  if (customDimStyle.textStyleId !== defDimStyle.textStyleId)
    dimStyleProperties.textStyleId = customDimStyle.textStyleId;
  if (customDimStyle.fontOffsetY !== defDimStyle.fontOffsetY)
    dimStyleProperties.fontOffsetY = customDimStyle.fontOffsetY;
  if (customDimStyle.fontOffsetX !== defDimStyle.fontOffsetX)
    dimStyleProperties.fontOffsetX = customDimStyle.fontOffsetX;
  if (customDimStyle.fontRotation !== defDimStyle.fontRotation)
    dimStyleProperties.fontRotation = customDimStyle.fontRotation;
  if (customDimStyle.fontScale !== defDimStyle.fontScale)
    dimStyleProperties.fontScale = customDimStyle.fontScale;
  return dimStyleProperties;
}
