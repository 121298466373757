import { client } from "shared/utils/rest-client";

const API_URL = process.env.REACT_APP_BACKEND_URL;

function calculate(projectId: string, buildingModelUrl: string) {
  return client.post(`${API_URL}/fem/calculate`, {
    project_id: projectId,
    building_model_url: "storeys" + buildingModelUrl,
  });
}

export const femService = {
  calculate,
};
