import { GraphicProcessor } from "lib/graphic-processor";
import { setDefaultLineStyleId } from "lib/materials";
import { isLineBasedGeometry } from "lib/models/checktools";
import { StyleCacheHandler } from "../styles/style-cache";
import { LineStyleBuilder } from "./line";

export let lineStyleCache: LineStyleCacheHandler;

export let currentLineStyleId: string;

export class LineStyleCacheHandler extends StyleCacheHandler<LineStyleBuilder> {

  static initialize() {
    lineStyleCache = new LineStyleCacheHandler();
    lineStyleCache.loadDefaultItems();
  }
  static clear() {
    lineStyleCache.clear();
    lineStyleCache = undefined!;
  }

  private loadDefaultItems() {
    const firstStyle = new LineStyleBuilder();
    firstStyle.default = true;
    firstStyle.name = "Continuous";
    firstStyle.dash = 0;
    firstStyle.gap = 0;
    firstStyle.dist = 0;
    firstStyle.styleId = "LineDef00";
    this.saveStyle(firstStyle);
    currentLineStyleId = firstStyle.styleId;
    setDefaultLineStyleId(firstStyle.styleId);

    const secondStyle = new LineStyleBuilder();
    secondStyle.default = true;
    secondStyle.name = "One dash";
    secondStyle.dash = 3;
    secondStyle.gap = 2;
    secondStyle.dist = 0.2;
    secondStyle.styleId = "LineDef01";
    this.saveStyle(secondStyle);

    const thirdStyle = new LineStyleBuilder();
    thirdStyle.default = true;
    thirdStyle.name = "Other dash";
    thirdStyle.dash = 3;
    thirdStyle.gap = 2;
    thirdStyle.dist = 0.5;
    thirdStyle.styleId = "LineDef02";
    this.saveStyle(thirdStyle);
  }

  load(itemsSaved: Record<string, LineStyleBuilder>) {
    for (const id in itemsSaved) {
      if (Object.prototype.hasOwnProperty.call(itemsSaved, id)) {
        const style = new LineStyleBuilder(itemsSaved[id]);
        style.styleId = id;
        this.saveStyle(style);
      }
    }
  }

  isStyleInUse(id: string, graphicProc: GraphicProcessor) {
    const dataModel = graphicProc.getDataModelManager();
    for (const data of dataModel.iterDataByFilter(isLineBasedGeometry)) {
      if (data.material.lineStyleId === id)
        return true;
    }
    return false;
  }
}
