import * as THREE from "three";
import { addIpoint } from "lib/math/point";
import { BeamData } from "lib/models/structural/beam";
import { markBulkTypes, ObjDataSelector } from "../selector-data";
import { SelectionManager } from "lib/selection/selection-manager";
import { cadOpType } from "lib/operations/factory";

export class BeamDataSelector extends ObjDataSelector<BeamData> {

  public override buildSelectMarks() {
    const { basePoint, points } = this.data.definition;
    const bulkData: markBulkTypes = {};
    bulkData.vertex = [addIpoint(points[1], basePoint)];
    bulkData.origin = [basePoint];
    return bulkData;
  }

  public override launchMarkOP(selectionManager: SelectionManager, mark: THREE.Object3D) {
    if (selectionManager.originSelector.pointObj === mark) {
      return {
        type: cadOpType.EDITBEAM,
        graphicProcessor: this.graphicProc,
        args: [this.data, 0],
      };
      // return {
      //   type: cadOpType.MOVE,
      //   graphicProcessor: this.graphicProc,
      //   args: [[this.data], this.data.definition.basePoint],
      // };
    } else if (selectionManager.vertexSelector.pointObj === mark) {
      return {
        type: cadOpType.EDITBEAM,
        graphicProcessor: this.graphicProc,
        args: [this.data, 1],
      };
    }
  }
}
