import styled from "styled-components";
import { useAuth } from "shared/components/auth/context";
import UserIcon from "shared/components/icons/user";
import Menu from "shared/components/ui/menu";

const UserMenu = () => {
  const { signOut, user } = useAuth();
  return (
    <Menu>
      <Menu.Button>
        <Wrapper>
          <UserIcon />
          <Username>{user?.username}</Username>
        </Wrapper>
      </Menu.Button>
      <Menu.List>
        <Menu.Link to="/lite/projects">Projects</Menu.Link>
        <Menu.Item onSelect={signOut}>Sign out</Menu.Item>
      </Menu.List>
    </Menu>
  );
};

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 4px;
`;

const Username = styled.span`
  font-size: 0.9rem;
`;
export default UserMenu;
