import { updateArcDimension } from "lib/dimension/arc-dim-builder";
import { getAzimutBisection, lineAzimut2p, normalizeAngle } from "lib/math/angles";
import { vectorDist3D } from "lib/math/distance";
import { IPoint } from "lib/math/types";
import { ArcDimensionData, arcDimensionParam } from "lib/models/dimension/arc-dim";
import { cadOpType } from "../factory";
import { EditDimensionOP } from "./dimension-base-edit";


abstract class EditArcDimensionOP extends EditDimensionOP<arcDimensionParam> {

  constructor(threeObj: ArcDimensionData, vertex: number) {
    super(threeObj);
    this.vertexToEdit = vertex;
  }

  public async start() {
    await super.start();
    const planeManager = this.graphicProcessor.getPlaneManager();
    planeManager.activePlane.position = this.newDimInfo.arcBase.center;
    planeManager.activePlane.rotation = this.newDimInfo.arcBase.plane;
    planeManager.activePlane.locked = true;
  }

  public moveLastPoint(point: IPoint) {
    const { center, radius, azimutO, angleCenter } = this.newDimInfo.arcBase
    const planeManager = this.graphicProcessor.getPlaneManager();
    const c = planeManager.activePlane.getRelativePoint(center);
    const p = planeManager.activePlane.getRelativePoint(point);
    if (this.vertexToEdit === 0) {
      let dimTextOriginDirection = getAzimutBisection(azimutO, angleCenter);
      const dimTextDirection = normalizeAngle(lineAzimut2p(c, p));
      if (!this.newDimInfo.InnerArc) {
        dimTextOriginDirection = dimTextOriginDirection - Math.PI;
      }
      this.dimStyle.fontOffsetX = dimTextDirection - dimTextOriginDirection;
    } else {
      const d = vectorDist3D(point, center) - radius;
      this.dimStyle.distBaseLine = d;
    }
    this.newDimInfo.InnerArc = this.dimDirectionIsInArc(this.newDimInfo.arcBase, point);
    updateArcDimension(this.objDataAux[0].graphicObj as THREE.Group, this.newDimInfo, this.dimStyle, this.txtStyle);
  }
}

export class EditArcLengthDimensionOP extends EditArcDimensionOP {
  public opType = cadOpType.EDITARCANGLEDIM;
}
export class EditArcAngleDimensionOP extends EditArcDimensionOP {
  public opType = cadOpType.EDITARCLONGDIM;
}