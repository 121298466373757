const TextIcon = ({ ...props }) => {
  return (
    <svg
      className="h-5 w-5"
      viewBox="0 0 100 100"
      fill="currentColor"
      {...props}
    >
      <path d="M93.75 9.375V21.875C93.75 22.7038 93.4208 23.4987 92.8347 24.0847C92.2487 24.6708 91.4538 25 90.625 25C89.7962 25 89.0013 24.6708 88.4153 24.0847C87.8292 23.4987 87.5 22.7038 87.5 21.875V12.5H53.125V87.5H71.875C72.7038 87.5 73.4987 87.8292 74.0847 88.4153C74.6708 89.0013 75 89.7962 75 90.625C75 91.4538 74.6708 92.2487 74.0847 92.8347C73.4987 93.4208 72.7038 93.75 71.875 93.75H28.125C27.2962 93.75 26.5013 93.4208 25.9153 92.8347C25.3292 92.2487 25 91.4538 25 90.625C25 89.7962 25.3292 89.0013 25.9153 88.4153C26.5013 87.8292 27.2962 87.5 28.125 87.5H46.875V12.5H12.5V21.875C12.5 22.7038 12.1708 23.4987 11.5847 24.0847C10.9987 24.6708 10.2038 25 9.375 25C8.5462 25 7.75134 24.6708 7.16529 24.0847C6.57924 23.4987 6.25 22.7038 6.25 21.875V9.375C6.25 8.5462 6.57924 7.75134 7.16529 7.16529C7.75134 6.57924 8.5462 6.25 9.375 6.25H90.625C91.4538 6.25 92.2487 6.57924 92.8347 7.16529C93.4208 7.75134 93.75 8.5462 93.75 9.375Z" />
    </svg>
  );
};

export default TextIcon;
