import { GraphicProcessor } from "lib/graphic-processor";
import { AlignedDimensionData } from "lib/models/dimension/aligned-dim";
import { AngleDimensionData } from "lib/models/dimension/angle-dim";
import { ArcDimensionData, arcDimType } from "lib/models/dimension/arc-dim";
import { CircularDimensionData, circularDimType } from "lib/models/dimension/circular-dim";
import { LinealDimensionData } from "lib/models/dimension/lineal-dim";
import { AlignedDimensionCommand } from "../dimension/aligned-dim";
import { AngleDimensionCommand } from "../dimension/angle-dim";
import { ArcAngleDimensionCommand, ArcLongDimensionCommand } from "../dimension/arc-dim";
import { DiameterDimensionCommand, RadialDimensionCommand } from "../dimension/circular-dim";
import { LinearDimensionCommand } from "../dimension/lineal-dim";
import { PrimitiveCommandCreator } from "./primitives";


export class AlignedDimCommandCreator extends PrimitiveCommandCreator {
  protected createDataFromCommand(graphicProc: GraphicProcessor, objData: AlignedDimensionData, layerId: string): AlignedDimensionData {
    const command = new AlignedDimensionCommand(objData.definition, layerId, graphicProc);
    command.execute();
    const newObjData = command.createdData;
    command.delete();
    return newObjData;
  }
}
export class LinealDimCommandCreator extends PrimitiveCommandCreator {
  protected createDataFromCommand(graphicProc: GraphicProcessor, objData: LinealDimensionData, layerId: string): LinealDimensionData {
    const command = new LinearDimensionCommand(objData.definition, layerId, graphicProc);
    command.execute();
    const newObjData = command.createdData;
    command.delete();
    return newObjData;
  }
}
export class AngleDimCommandCreator extends PrimitiveCommandCreator {
  protected createDataFromCommand(graphicProc: GraphicProcessor, objData: AngleDimensionData, layerId: string): AngleDimensionData {
    const command = new AngleDimensionCommand(objData.definition, layerId, graphicProc);
    command.execute();
    const newObjData = command.createdData;
    command.delete();
    return newObjData;
  }
}
export class ArcDimCommandCreator extends PrimitiveCommandCreator {
  protected createDataFromCommand(graphicProc: GraphicProcessor, objData: ArcDimensionData, layerId: string): ArcDimensionData {
    const cmd = objData.definition.type === arcDimType.ANG ? ArcAngleDimensionCommand : ArcLongDimensionCommand;
    const command = new cmd(objData.definition, layerId, graphicProc);
    command.execute();
    const newObjData = command.createdData;
    command.delete();
    return newObjData;
  }
}
export class CircularDimCommandCreator extends PrimitiveCommandCreator {
  protected createDataFromCommand(graphicProc: GraphicProcessor, objData: CircularDimensionData, layerId: string): CircularDimensionData {
    const cmd = objData.definition.type === circularDimType.DIAMETER ? DiameterDimensionCommand : RadialDimensionCommand;
    const command = new cmd(objData.definition, layerId, graphicProc);
    command.execute();
    const newObjData = command.createdData;
    command.delete();
    return newObjData;
  }
}
