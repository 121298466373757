import { cadOpType } from "lib/operations/factory";
import { useOperation } from "../operations/context";
import ToolbarButton from "../toolbar/toolbar-button";
import ToolbarSection from "../toolbar/toolbar-section";
import DimensionIcon from "../icons/dimension";
import LinearDimensionIcon from "../icons/dimension-linear";
import RadiusIcon from "../icons/radius";
import AngleDimensionIcon from "../icons/angle-dimension";
import ArcDimensionIcon from "../icons/arc-dimension";
import { useUI } from "shared/components/ui/context";

const DimensionSection = () => {
  const { openModal } = useUI();
  const { launchOperation } = useOperation();
  return (
    <ToolbarSection title="Dimension" className={"border-b border-gray-600"}>
      <ToolbarSection.Row>
        <ToolbarButton onClick={() => openModal("DIMENSION_MNG")} tooltip="dimension manager">
          <DimensionIcon />
        </ToolbarButton>
        <ToolbarButton
          onClick={() => launchOperation(cadOpType.ALIGNEDDIM)}
          tooltip="Dimension"
        >
          <DimensionIcon />
        </ToolbarButton>
        <ToolbarButton
          onClick={() => launchOperation(cadOpType.LINEARDIM)}
          tooltip="Linear dimension"
        >
          <LinearDimensionIcon />
        </ToolbarButton>
        <ToolbarButton
          onClick={() => launchOperation(cadOpType.ARCLONGDIM)}
          tooltip="Arc dimension"
        >
          <ArcDimensionIcon />
        </ToolbarButton>
        <ToolbarButton
          onClick={() => launchOperation(cadOpType.RADIUSDIM)}
          tooltip="Radius dimension"
        >
          <RadiusIcon />
        </ToolbarButton>
        <ToolbarButton
          onClick={() => launchOperation(cadOpType.ANGLEDIM)}
          tooltip="Angle dimension"
        >
          <AngleDimensionIcon />
        </ToolbarButton>
      </ToolbarSection.Row>
    </ToolbarSection>
  );
};

export default DimensionSection;
