const TrimIcon = ({ ...props }) => {
  return (
    <svg className="h-5 w-5" viewBox="0 0 100 100" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.5 47.5H59.1667C56.87 47.5 55 49.37 55 51.6667C55 53.9633 56.87 55.8333 59.1667 55.8333H62.5C64.7967 55.8333 66.6667 53.9633 66.6667 51.6667C66.6667 49.37 64.7967 47.5 62.5 47.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.5615 14.506C58.4348 13.641 55.9982 14.6693 55.1332 16.7977L29.3982 80.0977C28.5332 82.2277 29.5598 84.6627 31.6882 85.5277C32.1998 85.736 32.7298 85.8343 33.2515 85.8343C34.8982 85.8343 36.4598 84.8527 37.1182 83.2377V83.236L62.8532 19.9343C63.7182 17.806 62.6898 15.371 60.5615 14.506Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.8333 47.5H92.5C90.2033 47.5 88.3333 49.37 88.3333 51.6667C88.3333 53.9633 90.2033 55.8333 92.5 55.8333H95.8333C98.13 55.8333 100 53.9633 100 51.6667C100 49.37 98.13 47.5 95.8333 47.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.1667 47.5H75.8333C73.5367 47.5 71.6667 49.37 71.6667 51.6667C71.6667 53.9633 73.5367 55.8333 75.8333 55.8333H79.1667C81.4633 55.8333 83.3333 53.9633 83.3333 51.6667C83.3333 49.37 81.4633 47.5 79.1667 47.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 51.6667C35 49.37 33.13 47.5 30.8333 47.5H4.16667C1.87 47.5 1.90735e-06 49.37 1.90735e-06 51.6667C1.90735e-06 53.9633 1.87 55.8333 4.16667 55.8333H30.8333C33.13 55.8333 35 53.9633 35 51.6667Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TrimIcon;
