import { FC } from "react";
import { PrimaryButton, SecondaryButton } from "shared/components/ui/button";
import styled from "styled-components";
import { useAnalyzing } from "./hook/use-analyzing";
import LoadingDots from "shared/components/ui/loading-dots";
import { AnalysisProjectStatusMPValueEnum } from "lib/apis/analysis-projects/api";
import { formatTime } from "shared/utils/dates";

interface Props {
  onClose: () => void;
}

const Analyzing: FC<Props> = ({ onClose }) => {

  const { status, error, seconds, lastEvents, cancelAnalysing } = useAnalyzing();

  return (
    <Wrapper className="text-xs">
      <Header>
        <h1>Analyzing</h1>
      </Header>
      <Content>
        <Box>
          <div className="flex">
            <h1>{status} </h1>
            <div className="flex-grow flex justify-center">
              {status === AnalysisProjectStatusMPValueEnum.Running && <LoadingDots />}
              {status === AnalysisProjectStatusMPValueEnum.Error && <h1>{error}</h1>}
            </div>
            <h1>{formatTime(seconds)}</h1>
          </div>
          <h1>Events:</h1>
          <div>{lastEvents.map((e, i) => (
            <li key={e.name + i}>
              {e.name} - {e.date.toLocaleString()}
            </li>
          ))}</div>
        </Box>
        <Box className="flex justify-center gap-2">
          <PrimaryButton disabled={status === AnalysisProjectStatusMPValueEnum.Running} onClick={() => { onClose(); }}>
            Accept
          </PrimaryButton>
          <SecondaryButton
            disabled={status === AnalysisProjectStatusMPValueEnum.Canceled || status === AnalysisProjectStatusMPValueEnum.Error}
            onClick={cancelAnalysing}>
            Cancel
          </SecondaryButton>
        </Box>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
        overflow: hidden;
        border-radius: 4px;
        width: 300px;
        margin: -32px;
        background-color: var(--color-gray-300);
        `;
const Header = styled.div`
        background-color: var(--color-gray-300);
        border-bottom: 2px solid var(--color-primary);
        padding: 6px;
        display: flex;
        justify-content: space-between;
        `;
const Content = styled.div`
        display: grid;
        gap: 4px;
        padding: 6px;
        `;
const Box = styled.div`
        background-color: var(--color-gray-200);
        padding: 8px;
        border-radius: 4px;
        overflow: hidden;
        `;
export default Analyzing;