import * as THREE from "three";
import { dimensionCache } from "lib/dimension/cache";
import { IPoint } from "lib/math/types";
import { IObjData } from '../objdata';
import { objDataType } from "../types";
import { textStyleCache } from "lib/text/cache";
import { arcParam } from "lib/math/arc";
import { circleParam } from "lib/geometries/circle";
import { polygonParam } from "lib/geometries/polygon";
import { createCircularDimension, radialObj, updateCircularDimension } from "lib/dimension/circular-dim-builder";
import { dimDependence, DimensionData, dimensionParam } from "./dimension";
import { getIsegmentFromIndex, arcLineToArcParam, IArcLineParam } from "lib/math/line";
import { isLineData } from "../checktools";
import { eulerAnglesToAxisAngle, mirrorAngle, normalizeAngle } from "lib/math/angles";
import { GraphicProcessor } from "lib/graphic-processor";

export enum circularDimType { RADIUS, DIAMETER };

export interface circularDimensionParam extends dimensionParam {
  type: circularDimType;
  objBase: arcParam | circleParam | polygonParam;
  dimDirectionAngle: number;
  dataId?: string | undefined;
  data?: IObjData;
  edgeIndex?: number;
}

export class CircularDimensionData extends DimensionData {

  public type = objDataType.CIRCULARDIM;
  protected nameObj: string = "Circular dimension";
  public definition: circularDimensionParam;

  constructor(definition: circularDimensionParam) {
    super();
    this.definition = {
      styleId: definition.styleId,
      type: definition.type,
      customStyleProp: definition.customStyleProp,
      objBase: definition.objBase,
      dimDirectionAngle: definition.dimDirectionAngle,
      data: definition.data,
      edgeIndex: definition.edgeIndex,
    }
  }
  static createObj(definition: circularDimensionParam) {
    const dimStyle = dimensionCache.loadStylefromCache(definition.styleId, definition.customStyleProp)!;
    const styleText = textStyleCache.loadStylefromCache(dimStyle?.textStyleId)!;
    return createCircularDimension(
      definition.type,
      definition.objBase,
      definition.dimDirectionAngle,
      dimStyle,
      styleText
    );
  }
  public createGraphicObj() {
    this.graphicObj = CircularDimensionData.createObj(this.definition);
    this.addObjAsDependence();
  }
  public cloneDefinition(): circularDimensionParam {
    return {
      styleId: this.definition.styleId,
      type: this.definition.type,
      customStyleProp: this.definition.customStyleProp,
      objBase: this.definition.objBase,
      dimDirectionAngle: this.definition.dimDirectionAngle,
      data: this.definition.data,
      edgeIndex: this.definition.edgeIndex,
    }
  }
  public cloneMaterial(): undefined {
    return;
  }
  public createObject(definition?: circularDimensionParam): THREE.Group {
    return CircularDimensionData.createObj(definition ?? this.definition);
  }

  // ---------------------------------------------------------------

  public override addObjAsDependence() {
    if (this.definition.data) {
      const dep = { data: this };
      this.definition.data.addToMyDependences([dep]);
    }
  }
  public removeObjAsDependence() {
    if (this.definition.data) {
      const dep = { data: this };
      this.definition.data.removeFromMyDependences([dep]);
    }
  }
  public regenerateReferences(graphicProc: GraphicProcessor) {
    if (this.definition.dataId) {
      const modelMngr = graphicProc.getDataModelManager();
      this.definition.data = modelMngr.getData(this.definition.dataId)!;
    }
  }
  public relinkOneDependence(dep: dimDependence) {
    this.definition.data = dep.data;
  }
  public unlinkOneDependence(dimPos: number | undefined) {
    this.definition.data = undefined;
  }

  // ---------------------------------------------------------------

  public override regenerateObjectFromDefinition(): void {
    this.updateDependences();
    const dimStyle = dimensionCache.loadStylefromCache(this.definition.styleId, this.definition.customStyleProp)!;
    const styleText = textStyleCache.loadStylefromCache(dimStyle?.textStyleId)!;
    updateCircularDimension(this.graphicObj, this.definition, dimStyle, styleText);
  }

  private updateDependences() {
    if (this.definition.data) {
      const data = this.definition.data;
      if (isLineData(data)) {
        const edge = getIsegmentFromIndex(data.definition, this.definition.edgeIndex);
        const arc = arcLineToArcParam(edge.p1, edge.p2, edge.arc as IArcLineParam);
        this.definition.objBase = arc;
      } else {
        this.definition.objBase = data.definition as radialObj;
      }
    }
  }
  public translate(distance: IPoint): void {
    // Nothing to do
  }
  public rotate(angleX: number, angleY: number, angleZ: number, basePoint: IPoint): void {
    angleX = normalizeAngle(angleX);
    angleY = normalizeAngle(angleY);
    angleZ = normalizeAngle(angleZ);

    const { angle } = eulerAnglesToAxisAngle(angleX, angleY, angleZ);
    this.definition.dimDirectionAngle = normalizeAngle(this.definition.dimDirectionAngle - angle);
    this.regenerateObjectFromDefinition();
  }
  public scale(factorX: number, factorY: number, factorZ: number, basePoint: IPoint): void {
    // Nothing to do
  }
  public mirror(startPoint: IPoint, endPoint: IPoint): void {
    this.definition.dimDirectionAngle = mirrorAngle(this.definition.dimDirectionAngle, startPoint, endPoint);
    this.regenerateObjectFromDefinition();
  }

  // -------------------------------------------------------------------------

  protected exportDefinitionToJSON(): circularDimensionParam {
    return {
      styleId: this.definition.styleId,
      type: this.definition.type,
      customStyleProp: this.definition.customStyleProp,
      objBase: this.definition.objBase,
      dimDirectionAngle: this.definition.dimDirectionAngle,
      dataId: this.definition.dataId,
      edgeIndex: this.definition.edgeIndex,
    }
  }
}