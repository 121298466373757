import { FC } from "react";
import { PrimaryButton } from "shared/components/ui/button";
import { useUI } from "shared/components/ui/context";
import LoadingDots from "shared/components/ui/loading-dots";
import Page from "../components/layout/page";
import useProjects from "../components/projects/hooks/use-projects";
import ProjectList from "../components/projects/project-list";

const Projects: FC = () => {
  const { projects, loading } = useProjects();
  const { openModal } = useUI();
  return (
    <Page
      title="Projects"
      actions={
        <PrimaryButton onClick={() => openModal("ADD_PROJECT")}>
          New project
        </PrimaryButton>
      }
    >
      {loading && (
        <div className="flex flex-col justify-center items-center h-72">
          <LoadingDots />
        </div>
      )}
      {projects && <ProjectList projects={projects} />}
    </Page>
  );
};

export default Projects;
