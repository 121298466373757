import * as THREE from "three";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { defaultColor, defaultLineStyleId, defaultLineWidth, IBaseMaterial } from "./base";
import { BaseMaterialCache } from "./base-cache";

export interface ILineMaterial extends IBaseMaterial {
  width: number;
  lineStyleId: string;
}

export type LineMaterialType = THREE.LineBasicMaterial | THREE.LineDashedMaterial | LineMaterial;

export class LineBasicMaterialCache extends BaseMaterialCache<ILineMaterial, THREE.LineBasicMaterial> {

  public getMaterial(lineBasicMat: ILineMaterial): THREE.LineBasicMaterial {
    for (const [key, value] of this.map) {
      if (
        key.lineStyleId === lineBasicMat.lineStyleId &&
        key.width === lineBasicMat.width &&
        key.color.r === lineBasicMat.color.r &&
        key.color.g === lineBasicMat.color.g &&
        key.color.b === lineBasicMat.color.b &&
        key.color.a === lineBasicMat.color.a
      ) {
        return value;
      }
    }
    const material = new THREE.LineBasicMaterial({
      color: new THREE.Color(
        lineBasicMat.color.r / 255,
        lineBasicMat.color.g / 255,
        lineBasicMat.color.b / 255
      ),
      transparent: (lineBasicMat.color.a !== 1),
      opacity: lineBasicMat.color.a,
      linewidth: lineBasicMat.width,
      depthFunc: THREE.AlwaysDepth,
    });
    this.map.set(lineBasicMat, material);
    return material;
  }
}

export function getDefaultLineMaterial(): ILineMaterial {
  return {
    color: { r: defaultColor.r, g: defaultColor.g, b: defaultColor.b, a: defaultColor.a },
    lineStyleId: defaultLineStyleId,
    width: defaultLineWidth
  };
}

export function getCurrentLineMaterial(lineMaterial?: Partial<ILineMaterial>): ILineMaterial {
  const defMat = getDefaultLineMaterial();
  return {
    color: lineMaterial?.color ?? defMat.color,
    width: lineMaterial?.width ?? defMat.width,
    lineStyleId: lineMaterial?.lineStyleId ?? defMat.lineStyleId,
  };
}