import { IPoint } from "lib/math/types";
import { IObjData } from "lib/models/objdata";
import { objDataType } from "lib/models/types";
import {
  PointDataHint,
  LineDataHint,
  PolygonDataHint,
  CircleDataHint,
  TextDataHint,
  RegionDataHint,
  ArcDataHint,
  EllipseDataHint,
  EllipseArcDataHint,
  LoadHint,
  SolidDataHint,
  BeamDataHint,
  WallDataHint,
  ColumnDataHint,
} from "./hint-calculator";

const ObjDataHints = {
  [objDataType.POINT]: PointDataHint,
  [objDataType.POLYLINE]: LineDataHint,
  [objDataType.POLYGON]: PolygonDataHint,
  [objDataType.CIRCLE]: CircleDataHint,
  [objDataType.ARC]: ArcDataHint,
  [objDataType.ELLIPSE]: EllipseDataHint,
  [objDataType.ELLIPSEARC]: EllipseArcDataHint,
  [objDataType.TEXT]: TextDataHint,
  [objDataType.REGION]: RegionDataHint,

  [objDataType.ALIGNEDDIM]: null,
  [objDataType.LINEARDIM]: null,
  [objDataType.ARCDIM]: null,
  [objDataType.CIRCULARDIM]: null,
  [objDataType.ANGULARDIM]: null,

  [objDataType.BLOCK]: SolidDataHint,

  [objDataType.CUBE]: SolidDataHint,
  [objDataType.CYLINDER]: SolidDataHint,
  [objDataType.PLANE]: SolidDataHint,
  [objDataType.CONE]: SolidDataHint,
  [objDataType.RRPRIM]: SolidDataHint,
  [objDataType.PPRISM]: SolidDataHint,

  [objDataType.BLOCKREF]: SolidDataHint,
  [objDataType.BEAM]: BeamDataHint,
  [objDataType.WALL]: WallDataHint,
  [objDataType.COLUMN]: ColumnDataHint,
  [objDataType.SLAB]: SolidDataHint,
  [objDataType.FOOTER]: SolidDataHint,
  [objDataType.STRIPFOOTER]: SolidDataHint,
  [objDataType.PILECAP]: SolidDataHint,

  [objDataType.LOAD]: LoadHint,
}

export function buildHintData(data: IObjData, param?: THREE.Intersection[], prevPoint?: IPoint) {
  const dataSelectorBuilder = ObjDataHints[data.type];
  if (dataSelectorBuilder) {
    const handler = new dataSelectorBuilder(data, param, prevPoint);
    return handler;
  } else {
    console.warn(`[HINT SNAP DataModel] objDataType ${data.type} and name ${data.objName} doesn't support`);
  }
}