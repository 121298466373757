import { createSlab, createWaffleSlab, updateSlab } from "lib/geometries/structural/slab";
import { materialCache } from "lib/materials/base";
import { getCurrentSolidMaterial, ISolidMaterial } from "lib/materials/solid";
import { addIpoint, copyIPoint, substractIpoint } from "lib/math/point";
import { objDataType } from "../types";
import { StructuralElementData } from "./structural";
import { slabParam } from "../../models-struc/types/slab";
import { mirrorPoint } from "lib/math/mirror";
import { rotatePoint } from "lib/math/rotate";
import { IPoint } from "lib/math/types";
import { scalePoint } from "lib/math/scale";
import { waffleGeomGenerator } from "lib/models-struc/waffle/waffle-geometry";
import { currentShellCrossSectionId } from "lib/models-struc/cross-sections-shape/shell-cross-sections/cache";
import { buildingElemType } from "lib/models-struc/types/struc-base";
import { vector3Equals } from "lib/math/epsilon";

export class SlabData extends StructuralElementData {

  public type: buildingElemType = objDataType.SLAB;
  protected nameObj: string = "Slab";
  public definition: slabParam;

  constructor(definition: slabParam, material?: ISolidMaterial) {
    super();

    if (definition.holes.length) {
      for (const hole of definition.holes) {
        if (vector3Equals(hole[0], hole[hole.length - 1])) {
          hole.pop();
        }
      }
    }

    this.definition = {
      storeyId: definition.storeyId,
      name: definition.name,
      lnkObjIds: definition.lnkObjIds ? definition.lnkObjIds.slice() : [],
      materialType: definition.materialType,
      shellCrossSectionId: definition.shellCrossSectionId ?? currentShellCrossSectionId,

      deadLoadId: definition.deadLoadId ?? "",
      liveLoadId: definition.liveLoadId ?? "",

      ptos2D: definition.ptos2D.map(copyIPoint),
      holes: definition.holes.length ? definition.holes?.map(h => h?.map(copyIPoint)) : [],
      depth: definition.depth,
      basePoint: copyIPoint(definition.basePoint),
      rotation: copyIPoint(definition.rotation),
      offset: copyIPoint(definition.offset),
      scale: copyIPoint(definition.scale),
    };
    if (definition.cog) this.definition.cog = definition.cog;
    if (definition.waffleShelCrossSectionId) this.definition.waffleShelCrossSectionId = definition.waffleShelCrossSectionId;
    this.material = material ?? getCurrentSolidMaterial();
  }

  static createObj(definition: slabParam, material: ISolidMaterial) {
    const threeMaterial = materialCache.getSolidMaterial(material);
    const graphicObj = createSlab(definition.ptos2D, definition.depth, definition.holes, threeMaterial);
    const position = definition.basePoint;
    const rotation = definition.rotation;
    graphicObj.position.set(position.x, position.y, position.z);
    graphicObj.rotation.set(rotation.x, rotation.y, rotation.z);
    return graphicObj;
  }
  public createGraphicObj() {
    if (this.graphicObj) {
      console.warn("Attention: Slab graphic object already created!!"); return;
    }
    this.graphicObj = SlabData.createObj(this.definition, this.material);
  }
  public cloneDefinition(): slabParam {
    const solid = super.cloneSolidDefinition();
    const def: slabParam = {
      storeyId: this.definition.storeyId,
      name: this.definition.name,
      lnkObjIds: this.definition.lnkObjIds ? this.definition.lnkObjIds.slice() : [],
      materialType: this.definition.materialType,
      shellCrossSectionId: this.definition.shellCrossSectionId,

      deadLoadId: this.definition.deadLoadId,
      liveLoadId: this.definition.liveLoadId,

      depth: this.definition.depth,
      ptos2D: this.definition.ptos2D.map(copyIPoint),
      holes: this.definition.holes.length ? this.definition.holes?.map(h => h?.map(copyIPoint)) : [],
      basePoint: copyIPoint(solid.basePoint),
      rotation: copyIPoint(solid.rotation),
      offset: copyIPoint(solid.offset),
      scale: copyIPoint(solid.scale),
    }
    if (this.definition.cog) def.cog = this.definition.cog;
    if (this.definition.waffleShelCrossSectionId) def.waffleShelCrossSectionId = this.definition.waffleShelCrossSectionId;

    return def;
  }
  public createObject(definition?: slabParam, material?: ISolidMaterial): THREE.Mesh {
    return SlabData.createObj(definition ?? this.definition, material ?? this.material);
  }

  public regenerateDefinition(): void {
    // To avoid problems with holes, set position and rotation to zero
    this.graphicObj.position.set(0, 0, 0);
    this.graphicObj.rotation.set(0, 0, 0);
    updateSlab(this.graphicObj, this.definition.ptos2D, this.definition.depth, this.definition.holes);
    const position = this.definition.basePoint;
    const rotation = this.definition.rotation;
    this.graphicObj.position.set(position.x, position.y, position.z);
    this.graphicObj.rotation.set(rotation.x, rotation.y, rotation.z);
    this.graphicObj.updateMatrixWorld();
  }

  public override rotate(angleX: number, angleY: number, angleZ: number, basePoint: IPoint): void {
    const ptos = this.definition.ptos2D.map(p => addIpoint(p, this.definition.basePoint));
    const holes = this.definition.holes.map(h => h.map(p => addIpoint(p, this.definition.basePoint)));
    this.definition.basePoint = rotatePoint(this.definition.basePoint, angleX, angleY, angleZ, basePoint);
    const ptosRot = ptos.map(p => rotatePoint(p, angleX, angleY, angleZ, basePoint));
    this.definition.ptos2D = ptosRot.map(p => substractIpoint(p, this.definition.basePoint));
    const holesMirr = holes.map(h => h.map(p => rotatePoint(p, angleX, angleY, angleZ, basePoint)));
    this.definition.holes = holesMirr.map(h => h.map(p => substractIpoint(p, this.definition.basePoint)));

    this.regenerateObjectFromDefinition();
    super.rotate(angleX, angleY, angleZ, basePoint);
  }
  public override mirror(startPoint: IPoint, endPoint: IPoint): void {
    const ptos = this.definition.ptos2D.map(p => addIpoint(p, this.definition.basePoint));
    const holes = this.definition.holes.map(h => h.map(p => addIpoint(p, this.definition.basePoint)));
    this.definition.basePoint = mirrorPoint(this.definition.basePoint, startPoint, endPoint);
    const ptosMirr = ptos.map(p => mirrorPoint(p, startPoint, endPoint));
    this.definition.ptos2D = ptosMirr.map(p => substractIpoint(p, this.definition.basePoint));
    const holesMirr = holes.map(h => h.map(p => mirrorPoint(p, startPoint, endPoint)));
    this.definition.holes = holesMirr.map(h => h.map(p => substractIpoint(p, this.definition.basePoint)));

    this.regenerateObjectFromDefinition();
    super.mirror(startPoint, endPoint);
  }
  public override scale(factorX: number, factorY: number, factorZ: number, basePoint: IPoint): void {

    const ptos = this.definition.ptos2D.map(p => addIpoint(p, this.definition.basePoint));
    const holes = this.definition.holes.map(h => h.map(p => addIpoint(p, this.definition.basePoint)));

    this.definition.basePoint = scalePoint(this.definition.basePoint, factorX, factorY, factorZ, basePoint);
    const ptosScaled = ptos.map(p => scalePoint(p, factorX, factorY, factorZ, basePoint));
    this.definition.ptos2D = ptosScaled.map(p => substractIpoint(p, this.definition.basePoint));
    const holesMirr = holes.map(h => h.map(p => scalePoint(p, factorX, factorY, factorZ, basePoint)));
    this.definition.holes = holesMirr.map(h => h.map(p => substractIpoint(p, this.definition.basePoint)));

    this.regenerateObjectFromDefinition();
    super.scale(factorX, factorY, factorZ, basePoint);
  }

  // **************************************************

  public waffleGraphicObj: THREE.Group;

  public showHideWaffle() {
    if (this.waffleGraphicObj) {
      this.waffleGraphicObj.visible = !this.waffleGraphicObj.visible;
    } else {
      const waffle = waffleGeomGenerator.getWaffleGeometry(this.definition.name)!;
      const { x, y, z } = this.definition.basePoint;
      this.waffleGraphicObj = createWaffleSlab(this.definition.depth, waffle.WaffleGeo, waffle.SolidGeo);
      this.waffleGraphicObj.position.set(x, y, z);
      this.layerObj.threeObject.add(this.waffleGraphicObj);
    }
  }
}
