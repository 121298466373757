const CircleIcon = ({ ...props }) => {
  return (
    <svg
      className="h-5 w-5"
      width="101"
      height="101"
      viewBox="0 0 101 101"
      fill="none"
      {...props}
    >
      <path
        d="M57.875 45.125V57.875H45.125V45.125H57.875Z"
        stroke="currentColor"
        strokeWidth="6.25"
      />
      <path d="M58 46L84 20" stroke="currentColor" strokeWidth="6.25" />
      <path
        d="M97.75 50.875C97.75 76.7622 76.7622 97.75 50.875 97.75C24.9878 97.75 4 76.7622 4 50.875C4 24.9878 24.9878 4 50.875 4C76.7622 4 97.75 24.9878 97.75 50.875V50.875Z"
        stroke="currentColor"
        strokeWidth="6.25"
      />
    </svg>
  );
};

export default CircleIcon;
