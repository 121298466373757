import { CylinderEditDataCommand } from "lib/commands/edition/cylinder";

import { linealPrecision } from "lib/general-settings";
import { cylinderParam } from "lib/geometries/solid/cylinder";
import { volumeCylinder } from "lib/geometries/volume";
import { IPoint } from "lib/math/types";
import { CylinderData } from "lib/models/solids/cylinder";
import { dataInfoProperty } from "../properties";
import { SolidDataDefinitionHandler } from "../solid";
import { ISolidMaterial } from '../../materials/solid';

export type cylinderProp = cylinderParam & { volume: number; centroid: IPoint; }
export type cylinderView = dataInfoProperty<cylinderProp>

export class CylinderDataDefinitionHandler extends SolidDataDefinitionHandler<cylinderProp> {

  protected data: CylinderData;

  protected buildInfoProperties() {
    this.definitionInfo = {};
    const def = this.data.definition;
    this.definitionInfo.radius = this.getNumberView(def.radius, "Radius", "m", linealPrecision, undefined, (radius: number) => radius > 0);
    this.definitionInfo.height = this.getNumberView(def.height, "Height", "m", linealPrecision, undefined, (height: number) => height > 0);
    this.fillSolidDefinition(this.data);
    this.definitionInfo.volume = this.getNumberView(volumeCylinder(def), "Volume", "m³", linealPrecision, false);
  }

  private checkNewDefinition(newDefinition: cylinderProp): boolean {
    if (!newDefinition) { return false; }
    if (!this.checkSolidNewDefinition(newDefinition)) { return false; }
    if (!this.checkNumber(newDefinition.radius)) { return false; }
    if (!this.checkNumber(newDefinition.height)) { return false; }
    return true;
  }

  private changedNewDefinition(oldDefinition: cylinderParam, newDefinition: cylinderProp): cylinderParam | null {
    let def = oldDefinition;
    let changed: boolean = false;
    if (newDefinition) {
      const solid = this.changedSolidNewDefinition(oldDefinition, newDefinition)
      if (solid !== null) {
        def.basePoint = solid.basePoint;
        def.rotation = solid.rotation;
        def.scale = solid.scale;
        def.offset = solid.offset;
        changed = true;
      }
      const radius = this.changedNumber(def.radius, newDefinition.radius);
      if (radius !== null) {
        def.radius = radius;
        changed = true;
      }
      const height = this.changedNumber(def.height, newDefinition.height);
      if (height !== null) {
        def.height = height;
        changed = true;
      }
    }
    return (changed ? def : null);
  }

  private checkAndChangedDefinition(oldDefinition: cylinderParam, newDefinition: cylinderProp): cylinderParam | null {
    let def = null;
    if (this.checkNewDefinition(newDefinition)) {
      def = this.changedNewDefinition(oldDefinition, newDefinition);
    }
    return def;
  }

  public saveAndRegenerate = (newDefinition: cylinderProp) => {
    const cylinderDefinition = this.checkAndChangedDefinition(this.data.cloneDefinition(), newDefinition);
    if (cylinderDefinition) {
      const command = new CylinderEditDataCommand(this.data, cylinderDefinition, null, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    };
  }
  public saveAndRegenerateMaterial = (newMaterial: ISolidMaterial) => {
    const cylinderMaterial = this.checkAndChangedSolidMaterial(this.data.material, newMaterial);
    if (cylinderMaterial) {
      const command = new CylinderEditDataCommand(this.data, null, cylinderMaterial, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    };
  }
}