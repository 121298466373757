import { PileCapEditDataCommand } from "lib/commands/structural/pile-cap";
import { linealPrecision } from "lib/general-settings"
import { ISolidMaterial } from "lib/materials/solid";
import { IPoint } from "lib/math/types";
import { beamCrossSectionCache } from "lib/models-struc/cross-sections-shape/beam-cross-sections/cache";
import { ICircular, isCircularSection } from "lib/models-struc/cross-sections-shape/beam-cross-sections/types";
import { shellCrossSectionCache } from "lib/models-struc/cross-sections-shape/shell-cross-sections/cache";
import { isFlatCSSParams } from "lib/models-struc/cross-sections-shape/shell-cross-sections/types";
import { pileCapParam } from "lib/models-struc/types/pile-cap";
import { PileCapData } from "lib/models/structural/pile-cap";
import { StructuralDataDefinitionHandler } from "./structural";

export type pileCapProp = pileCapParam & { thickness: number, diameter: number, centroid: IPoint; }

export class PileCapDataDefinitionHandler extends StructuralDataDefinitionHandler<pileCapProp> {

  protected data: PileCapData;

  protected buildInfoProperties() {
    super.buildInfoProperties();
    this.fillPileCapDefinition(this.data);
  }

  private fillPileCapDefinition(data: PileCapData) {
    const repr = data.definition;

    const ShellCSSs = shellCrossSectionCache.getAllLoadedStyles().sort((a, b) => a.name.localeCompare(b.name));
    const flatCSSnameList: [string, string][] = [];
    for (const shellCSS of ShellCSSs) {
      const param = shellCSS.parameters;
      if (isFlatCSSParams(param)) flatCSSnameList.push([shellCSS.name, shellCSS.styleId]);
    }
    const capSection = shellCrossSectionCache.loadStylefromCache(repr.capSectionId)!;
    this.structuralInfo.capSectionId = { publicName: "Cap shell section", type: "tagList", value: capSection.styleId, tagList: flatCSSnameList };
    this.structuralInfo.thickness = this.getNumberView(capSection.thickness, "Section thickness", "m", linealPrecision, false);

    const BeamCSSs = beamCrossSectionCache.getAllLoadedStyles().sort((a, b) => a.name.localeCompare(b.name));
    const circularSectionnameList: [string, string][] = [];
    for (const BeamCSS of BeamCSSs) {
      const param = BeamCSS.parameter;
      if (isCircularSection(param)) circularSectionnameList.push([BeamCSS.name, BeamCSS.styleId]);
    }
    const pileSection = beamCrossSectionCache.loadStylefromCache(repr.pileSections)!;
    const diameter = (pileSection.parameter as ICircular).radius * 2;
    this.structuralInfo.pileSections = { publicName: "Pile beam section", type: "tagList", value: pileSection.styleId, tagList: circularSectionnameList };
    this.structuralInfo.diameter = this.getNumberView(diameter, "Section diameter", "m", linealPrecision, false);

    this.structuralInfo.pilePenetration = this.getNumberView(repr.pilePenetration, "Pile penetration", "m", linealPrecision);
    this.structuralInfo.height = this.getNumberView(repr.height, "Height", "m", linealPrecision);
  }

  private checkNewStructural(newStructural: pileCapProp) {
    const repr = newStructural;
    if (!newStructural) { return false; }
    if (!this.checkStructuralNewDefinition(newStructural)) { return false; }
    if (repr.capSectionId !== undefined && !this.checkString(repr.capSectionId)) { return false; }
    if (repr.pileSections !== undefined && !this.checkString(repr.pileSections)) { return false; }
    if (repr.pilePenetration !== undefined && !this.checkNumber(repr.pilePenetration)) { return false; }
    if (repr.height !== undefined && !this.checkNumber(repr.height)) { return false; }
    return true;
  }

  private changedPileCapNewStructural(oldDefinition: pileCapParam, newDefinition: pileCapProp) {
    let def = oldDefinition;
    let changed: boolean = false;
    if (newDefinition) {
      const name = this.changedString(def.name, newDefinition.name);
      if (name !== null) {
        def.name = name;
        changed = true;
      }
      const capSectionId = this.changedString(def.capSectionId, newDefinition.capSectionId);
      if (capSectionId !== null) {
        def.capSectionId = capSectionId;
        changed = true;
      }
      const pileSections = this.changedString(def.pileSections, newDefinition.pileSections);
      if (pileSections !== null) {
        def.pileSections = pileSections;
        changed = true;
      }
      const pilePenetration = this.changedNumber(def.pilePenetration, newDefinition.pilePenetration);
      if (pilePenetration !== null) {
        def.pilePenetration = pilePenetration;
        changed = true;
      }
      const height = this.changedNumber(def.height, newDefinition.height);
      if (height !== null) {
        def.height = height;
        changed = true;
      }
    }
    return (changed ? def : null);
  }

  public saveAndRegenerate = (newDefinition: pileCapProp) => {
    const pileCapDefinition = this.checkAndChangedDefinition(this.data.cloneDefinition(), newDefinition);
    if (pileCapDefinition) {
      const command = new PileCapEditDataCommand(this.data, pileCapDefinition, null, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }

  public saveAndRegenerateMaterial = (newMaterial: ISolidMaterial) => {
    const pileCapMaterial = this.checkAndChangedSolidMaterial(this.data.material, newMaterial);
    if (pileCapMaterial) {
      const command = new PileCapEditDataCommand(this.data, null, pileCapMaterial, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }

  public saveAndRegenerateStruct = (newStructural: pileCapProp) => {
    if (this.checkNewStructural(newStructural)) {
      const pileCapStrucParam = this.changedPileCapNewStructural(this.data.cloneDefinition(), newStructural);
      if (pileCapStrucParam) {
        const command = new PileCapEditDataCommand(this.data, pileCapStrucParam, null, this.graphicProcessor);
        if (command) this.graphicProcessor.storeAndExecute(command);
      }
    }
  }
}