import { ChangeEvent, CSSProperties, InputHTMLAttributes, useCallback } from "react";
import RenderIf from "../render-if";
import styled from "styled-components";

const Wrapper = styled.label`
  text-transform: capitalize;
`;
const Select = styled.select`
  min-width: 150px;
`;
interface Props extends InputHTMLAttributes<HTMLInputElement> {
  value: any;
  onChange?: (value: any) => void;
  type?: "text" | "select" | "number";
  options?: any[];
  label?: string;
  style?: CSSProperties;
}
const ContentEditable = ({
  onChange,
  value,
  type = "text",
  options,
  label,
  style,
  ...rest
}: Props) => {

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      event.preventDefault();
      if (onChange) {
        onChange(event.target.value);
      }
    },
    [onChange]
  );

  return (
    <Wrapper style={style}>
      {label}
      {type === "select" ? (
        <Select
          className="block w-full p-1 rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-indigo-600 text-gray-700 sm:text-sm capitalize"
          value={value}
          onChange={handleChange}
        >
          <RenderIf isTrue={!!options?.length}>
            {options?.map((value, index) => (
              <option key={index}>{value}</option>
            ))}
          </RenderIf>
        </Select>
      ) : (
        <input
          className="block w-full rounded-md p-1 border-none hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm text-gray-700"
          value={value}
          onChange={handleChange}
          type={type}
          disabled={!onChange}
          {...rest}
        />
      )}
    </Wrapper>
  );
};

export default ContentEditable;
