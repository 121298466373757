import jsPDF from "jspdf";
import { GraphicProcessor } from '../../../../../lib/graphic-processor';

export function useScreenshotProject(graphicProcessor: GraphicProcessor, name?: string) {

  const screenshotProject = async () => {
    const snapshot = await graphicProcessor.takeSnapshot();
    var pdf = new jsPDF("landscape");
    pdf.addImage(snapshot, "PNG", 0, 0, 330, 210);
    pdf.save(name || "drawing");
  }

  return screenshotProject;
}
