import { FC } from "react";
import { Route, RouteProps, Switch } from "react-router-dom";
import NotFound from "shared/pages/not-found";
import { useModules } from "./hooks/use-modules";
import CADLayout from "modules/cad/components/layout/layout";
import Account from "modules/cad/pages/account";
import Project from "modules/cad/pages/project";
import Projects from "modules/cad/pages/projects";
import LiteLayout from "modules/lite/components/layout/layout";
import StrucLayout from "modules/struc/components/layout/layout";
import LiteProject from "modules/lite/pages/project";
import LiteProjects from "modules/lite/pages/projects";
import NewLiteProject from "modules/lite/pages/new-project";
import { useTheme } from "../ui/hooks/use-theme";
import Measurements from "modules/lite/pages/measurements";
import EditProject from "modules/lite/pages/edit-project";
import StrucProjects from "modules/struc/pages/projects";
import NewStrucProject from "modules/struc/pages/create-project";
import StrucProject from "modules/struc/pages/project";
import EditStrucProject from "modules/struc/pages/edit-project";
import { useUserMessages } from '../ui/hooks/use-messag-user';
import { OperationProvider } from "modules/cad/components/operations/context";
import { GraphicProcessorProvider } from "modules/cad/components/viewport/context";
import { FileProvider } from "modules/cad/components/files/context";
import { StrucProjectProvider } from "modules/struc/components/context";


const LiteRoute: FC<RouteProps> = ({ children, ...props }) => {
  const { showLiteModule } = useModules();
  if (!showLiteModule) {
    return null;
  }
  return (
    <Route {...props}>
      <LiteLayout>{children}</LiteLayout>
    </Route>
  );
};

const CADRoute: FC<RouteProps> = ({ children, ...props }) => {
  const { showCadModule } = useModules();
  if (!showCadModule) {
    return null;
  }
  return (
    <Route {...props}>
      <FileProvider>
        <CADLayout>{children}</CADLayout>
      </FileProvider>
    </Route>
  );
};
const CADProjRoute: FC<RouteProps> = ({ children, ...props }) => {
  const { showCadModule } = useModules();
  if (!showCadModule) {
    return null;
  }
  return (
    <Route {...props}>
      <FileProvider>
        <GraphicProcessorProvider>
          <OperationProvider>
            <CADLayout>{children}</CADLayout>
          </OperationProvider>
        </GraphicProcessorProvider>
      </FileProvider>
    </Route>
  );
};

const StrucRoute: FC<RouteProps> = ({ children, ...props }) => {
  const { showStrucModule } = useModules();
  if (!showStrucModule) {
    return null;
  }
  return (
    <Route {...props}>
      <StrucLayout>{children}</StrucLayout>
    </Route>
  );
};
const StrucProjRoute: FC<RouteProps> = ({ children, ...props }) => {
  const { showStrucModule } = useModules();
  if (!showStrucModule) {
    return null;
  }
  return (
    <Route {...props}>
      <GraphicProcessorProvider>
        <StrucProjectProvider>
          <OperationProvider>
            <StrucLayout>{children}</StrucLayout>
          </OperationProvider>
        </StrucProjectProvider>
      </GraphicProcessorProvider>
    </Route>
  );
};

function AuthenticatedApp() {
  useTheme();
  useUserMessages();
  return (
    <Switch>
      <StrucRoute exact path="/struc/projects/new">
        <NewStrucProject />
      </StrucRoute>
      <StrucRoute exact path="/struc/projects/:id/edit">
        <EditStrucProject />
      </StrucRoute>
      <StrucProjRoute path="/struc/projects/:id">
        <StrucProject />
      </StrucProjRoute>
      <StrucRoute exact path="/struc/projects">
        <StrucProjects />
      </StrucRoute>

      <LiteRoute path="/lite/projects/new">
        <NewLiteProject />
      </LiteRoute>
      <LiteRoute path="/lite/projects/:id/measurements">
        <Measurements />
      </LiteRoute>
      <LiteRoute path="/lite/projects/:id/edit">
        <EditProject />
      </LiteRoute>
      <LiteRoute path="/lite/projects/:id">
        <LiteProject />
      </LiteRoute>
      <LiteRoute path="/lite/projects">
        <LiteProjects />
      </LiteRoute>

      <CADRoute path="/cad/account">
        <Account />
      </CADRoute>
      <CADProjRoute path="/cad/projects/:id">
        <Project />
      </CADProjRoute>
      <CADRoute path="/cad/projects">
        <Projects />
      </CADRoute>
      <CADRoute path="/">
        <Projects />
      </CADRoute>

      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default AuthenticatedApp;
