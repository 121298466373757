import MeasurementResults from "../components/measurements/measurement-results";
import { useMeasurements } from "../components/measurements/hooks/use-measurements";

import Page from "../components/ui/page";

const Measurements = () => {
  const { measurements } = useMeasurements();
  return (
    <Page title="Measurements">
      <MeasurementResults measurements={measurements} />
    </Page>
  );
};

export default Measurements;
