import * as THREE from "three";
import { buffer2IPoints } from "lib/math/point";
import { SolidData } from "lib/models/solids/solid";
import { SelectionManager } from "../selection-manager";
import { ObjDataSelector, markBulkTypes } from "./selector-data";


export class SolidDataSelector extends ObjDataSelector<SolidData> {

  public override buildSelectMarks() {
    // At this moment, we paint the vertices from their own geometry
    const obj = this.data.graphicObj as THREE.Mesh;
    const geom = obj.geometry as THREE.BufferGeometry;
    const buff = geom.getAttribute("position").clone().applyMatrix4(obj.matrixWorld);

    const bulkData: markBulkTypes = {};
    bulkData.vertex = buffer2IPoints(buff.array as Float32Array);
    bulkData.origin = [this.data.definition.basePoint];
    return bulkData;
  }

  public override launchMarkOP(selectionManager: SelectionManager, mark: THREE.Object3D, index?: number) {
    // launchOP(cadOpType.MOVE, this.graphcProcc, [this.data.graphicObj, this.data.definition]);
  }
}
