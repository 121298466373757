import { MeshProject } from "lib/apis/mesh-projects/api";
import { dataInfoProperty } from "lib/properties/properties";
import { MeshProject as MeshProjectEcore } from "modules/struc/models/ecore/mesh";

export const defaultShellSize = 0.5;
export const defaultBeamSize = 0.5;
export const defaultNodalBcThres = 0.001;
export const defaultMpcThres = 0.5;
export const defaultMergeTolerance = 0.01;

export interface MeshProperties {
  params: MeshGenParams;
  project: MeshProject | undefined;
}

export interface MeshGenParams {
  shellSize: number;
  beamSize: number;
  nodalBcThres: number;
  mpcThres: number;
  mergeTolerance: number;
}

export type MeshProjectWithErrors = { name: string, errors: string[] }
export function isMeshProject(meshProjectData: MeshProjectEcore | MeshProjectWithErrors): meshProjectData is MeshProjectEcore {
  return "eClass" in meshProjectData;
}


export const getMeshInfoProp = (mesh: MeshGenParams): dataInfoProperty<MeshGenParams> => {
  if (mesh) {
    return {
      shellSize: { publicName: "Shell element size", value: mesh.shellSize, type: "number" },
      beamSize: { publicName: "Beam element size", value: mesh.beamSize, type: "number" },
      nodalBcThres: { publicName: "Nodal boundary condition threshold", value: mesh.nodalBcThres, type: "number" },
      mpcThres: { publicName: "MPC threshold", value: mesh.mpcThres, type: "number" },
      mergeTolerance: { publicName: "Nodal merge tolerance", value: mesh.mergeTolerance, type: "number" },
    };
  } else {
    return {};
  }
};