import { EventBus } from "lib/events/event-bus";
import { GraphicProcessor } from "lib/graphic-processor";

export class LayerDelete {

  private graphicProcessor: GraphicProcessor;

  constructor(graphicProcessor: GraphicProcessor) {
    this.graphicProcessor = graphicProcessor;
  }

  run(layerId: string) {
    const dataModel = this.graphicProcessor.getDataModelManager();
    const layerManager = dataModel.layerManager;
    const layer2Delete = layerManager.deleteLayerById(layerId);
    if (layer2Delete?.length) {
      EventBus.enableDispatch = false;
      const dataToDelete = [];
      for (const layer of layer2Delete) {
        for (const data of layer.objDatas) {
          dataToDelete.push(data);
          this.graphicProcessor.removeFromLayer(data);
        }
      }
      EventBus.enableDispatch = true;

      layerManager.layerObserver.dispatchLoadLayers();
      dataModel.dispatchDeletedObjs(dataToDelete);
    }
  }
}
