import { Link as RouterLink } from "react-router-dom";
import {
  Menu as ReachMenu,
  MenuList,
  MenuButton,
  MenuItem,
  MenuLink,
  MenuProps,
  MenuListProps,
} from "@reach/menu-button";
import styled, { StyledComponent } from "styled-components";
import { FC, ForwardRefExoticComponent } from "react";

interface LinkProps {
  to: string;
}

interface Composition {
  Button: FC<any>;
  List: StyledComponent<ForwardRefExoticComponent<MenuListProps>, {}>;
  Link: FC<LinkProps>;
  Item: StyledComponent<ForwardRefExoticComponent<any>, {}>;
}

const Menu: FC<MenuProps> & Composition = ({ children }) => {
  return <ReachMenu>{children}</ReachMenu>;
};

const Link: FC<LinkProps> = ({ children, to, ...props }) => {
  return (
    <MenuLink as={RouterLinkWrapper} to={to} {...props}>
      {children}
    </MenuLink>
  );
};

const Button: FC<any> = ({ children, ...props }) => {
  return (
    <MenuButtonWrapper
      {...props}
      onClick={(event: any) => {
        event.preventDefault();
        event.stopPropagation();
      }}
    >
      {children}
    </MenuButtonWrapper>
  );
};

const MenuButtonWrapper = styled(MenuButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text-secondary);
  &:hover {
    color: var(--color-text);
  }
`;
const RouterLinkWrapper = styled(RouterLink)`
  user-select: none;
  cursor: pointer;
  display: block;
  color: inherit;
  font: inherit;
  text-decoration: none;
  text-decoration: initial;
  padding: 5px 20px;
  &:hover {
    color: var(--color-text);
  }
`;

const List = styled(MenuList)`
  border: 1px solid var(--color-divider);
  background-color: var(--color-background);
  color: var(--color-text-secondary);
  padding: 8px;
  border-radius: var(--radius);
  font-size: 0.85rem;
  margin-top: 4px;
  padding: 4px 0;
  text-align: left;
`;

const Item = styled(MenuItem)`
  user-select: none;
  cursor: pointer;
  display: block;
  color: inherit;
  font: inherit;
  text-decoration: none;
  text-decoration: initial;
  padding: 5px 20px;
  &:hover {
    color: var(--color-text);
  }
`;

Menu.Button = Button;
Menu.List = List;
Menu.Link = Link;
Menu.Item = Item;

export default Menu;
