import axios, { AxiosResponse, ResponseType } from "axios";
import { gunzipSync, gzip } from "zlib";

export const updateRequestOK = 200;
export const createRequestOK = 201;
export const statusRequestOK = 200;

export enum requestStatus {
  VOID = "VOID",
  RUNNING = "RUNNING",
  DONE = "DONE",
  ERROR = "ERROR",
  CANCELED = "CANCELLED",
}

export type fileUrl = {
  Name: string;
  Url: string;
}

export type fileData = {
  name: string;
  data: string;
}

export const saveFile = (content: string, filename: string, contentType: string) => {
  const link = document.createElement("a");
  link.style.display = "none";
  const blob = new Blob([content], { 'type': contentType });
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
}

export async function uploadFileToS3(presignedUploadUrl: string, result: Buffer): Promise<void> {
  // No headers, because axios automatically inyect Content-Type header
  // but S3 does not expect this header, and return forbidden response
  try {
    await axios.put(presignedUploadUrl, result, { headers: null })
  } catch (error) {
    console.error('There was an error sending to S3: ', error);
    throw error;
  }
}

export async function downloadFileFromS3(presignedDownloadUrl: string, responseType: ResponseType = "arraybuffer"): Promise<AxiosResponse<any>> {
  // Property responseType must be 'arraybuffer' because by default 
  // it is 'stream' and un-compress process will fails
  try {
    const res = await axios.get(presignedDownloadUrl, { responseType });
    return res.data;
  } catch (error) {
    console.error('There was an error receiveing from S3: ', error);
    throw error;
  }
}

export async function downloadSomeFilesFromS3<T>(filesUrl: fileUrl[], responseType: ResponseType = "arraybuffer"): Promise<{ name: string, data: T }[]> {
  // Property responseType must be 'arraybuffer' because by default 
  // it is 'stream' and un-compress process will fails
  const files: { name: string, data: T }[] = [];
  await Promise.allSettled(filesUrl.map(async fileUrl =>
    await axios.get(fileUrl.Url, { responseType }).then(r => files.push({ name: fileUrl.Name, data: r?.data }))))
    .catch(error => console.error('There was an error receiveing from S3: ', error))
  return files;
}

export async function compressAndUploadToS3(dataToCompress: string, uploadUrl: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    gzip(dataToCompress, async (error: Error | null, data: Buffer) => {
      if (error === null) {
        await uploadFileToS3(uploadUrl, data);
        resolve();
      } else {
        reject(error);
      }
    });
  });
}

export async function downloadFileFromS3AndParse<T>(url: string): Promise<T> {
  const dataBuffer = await downloadFileFromS3(url);
  const string = gunzipSync(Buffer.from(dataBuffer)).toString();
  return JSON.parse(string);
}
