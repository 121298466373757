import useProject from "modules/lite/components/projects/hooks/use-project";
import ProjectBoard from "modules/lite/components/projects/project-board";
import { FC } from "react";
import Page from "../components/ui/page";
import ProjectStatus from "../components/projects/project-status";
import Tabs from "shared/components/ui/tabs";
import { useTabs } from "shared/components/ui/tabs/hooks/use-tabs";
import RenderIf from "shared/components/ui/render-if";
import ProjectMeasurements from "../components/projects/project-measurements";
import ProjectFEM from "../components/projects/project-fem";
import ProjectOptimizer from "../components/projects/project-optimizer";

const TAB_OPTIONS = ["summary", "measurements", "FEM", "optimizer"];

const LiteProject: FC = () => {
  const { project } = useProject();
  const { activeTab, handleSelect } = useTabs(TAB_OPTIONS);

  return (
    <Page
      title="Project board"
      backRoute={"/lite/projects"}
      actions={<></>}
      subtitle={<ProjectStatus status={project?.status} />}
    >
      <Tabs
        options={TAB_OPTIONS}
        activeTab={activeTab}
        onSelect={handleSelect}
      />
      {project && (
        <div>
          <RenderIf isTrue={activeTab === "summary"}>
            <ProjectBoard project={project} />
          </RenderIf>
          <RenderIf isTrue={activeTab === "measurements"}>
            <ProjectMeasurements />
          </RenderIf>
          <RenderIf isTrue={activeTab === "FEM"}>
            <ProjectFEM />
          </RenderIf>
          <RenderIf isTrue={activeTab === "optimizer"}>
            <ProjectOptimizer />
          </RenderIf>
        </div>
      )}
    </Page>
  );
};

export default LiteProject;
