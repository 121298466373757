import { GraphicProcessor } from "lib/graphic-processor";
import { propSetting } from "lib/operations/step-operations";
import { useState } from 'react';
import { StructModel3D, testNewShaders4Points } from "lib/helpers/structmodel3d";

import { displacementList } from "lib/models-struc/hypothesis/hypothesis"

interface meshViewerControl {
  isVisible: boolean;
  deformationRange: number;
  deformationType: string;
  beamsFaMsType: string;
  shellsFaMsType: string;
  paletteDXDYDZ: boolean;
  paletteDR: boolean;
  paletteOrange: boolean;
  showWireframe: boolean;
  showNodesAsBalls: boolean,
  showNodes: boolean;
  showBeams: boolean;
  showFatBeams: boolean;
  showTriangles: boolean;
  showQuads: boolean;
  showCells: boolean;
  // Mostrar los nodos en colores individuales, fruto de alguna paleta...
  showColoredNodes: boolean;
  // Para mostrar los numeritos identificativos de los nodos/beams/shells.
  showNodesIds: boolean;
  showPointedNodeId: boolean;
  showBeamsIds: boolean;
  showShellsIds: boolean;
  // Para mostrar la paleta de colores en curso.
  showPalette: boolean;
  // Para mostrar los puntos de integracion, que previamente habremos cargado.
  showIntegrationPoints: boolean;
  // Para mostrar los centros de gravedad.
  showCOGs: boolean;
  // Activacion del modo clipping 3D para el mesh en los planos X-Y-Z.
  activateClippingModeXYZ: boolean;
  // Cantidades de clipping para los ejes X-Y-Z en [0, 1] ([0], por defecto se ve todo y 1 no se ve nada).
  clipX: number,
  clipY: number,
  clipZ: number,
  // Inversion del plano de clipping, para ver al otro lado del mismo. Por defecto [false].
  invClipX: boolean,
  invClipY: boolean,
  invClipZ: boolean,
  // Visualizacion de los pertinentes planos de clipping, por defecto todos [true].
  showClipX: boolean,
  showClipY: boolean,
  showClipZ: boolean,
}

const nodesDeformList = ["None for NODE DEFORMATIONS", "Dx", "Dy", "Dz", "DRx", "DRy", "DRz", "Dsum", "DRsum"];
const beamsFaMsList = ["None for BEAMS F&M", "N", "VY", "VZ", "MT", "MFY", "MFZ"];
const shellsFaMsList = ["None for SHELLS F&M", "NXX", "NYY", "NXY", "MXX", "MYY", "MXY", "QX", "QY"];

const initialValues: meshViewerControl = {
  isVisible: true,
  deformationRange: 0,
  deformationType: displacementList[6],
  beamsFaMsType: beamsFaMsList[0],          // "None for BEAMS F&M".
  shellsFaMsType: shellsFaMsList[0],        // "None for SHELLS F&M".
  paletteDXDYDZ: false,
  paletteDR: false,
  paletteOrange: false,
  showWireframe: false,
  showNodesAsBalls: false,
  showNodes: true,
  showBeams: true,
  showFatBeams: false,
  showTriangles: true,
  showQuads: true,
  showCells: true,
  showColoredNodes: false,
  showNodesIds: false,
  showPointedNodeId: false,
  showBeamsIds: false,
  showShellsIds: false,
  showPalette: false,
  showIntegrationPoints: false,
  showCOGs: true,
  activateClippingModeXYZ: false,
  clipX: 0.0,
  clipY: 0.0,
  clipZ: 0.0,
  invClipX: false,
  invClipY: false,
  invClipZ: false,
  showClipX: true,
  showClipY: true,
  showClipZ: true,
}

export function useMeshViewer(graphicProcessor: GraphicProcessor) {

  const [meshViewerParam, setMeshViewerParam] = useState<meshViewerControl>(initialValues);

  const getPropSettingsFromMeshViewer = (params: meshViewerControl): propSetting<meshViewerControl> => {
    return {
      propValue: {
        // Check para ver [ON] todo el modelo de mallado.
        isVisible: {
          type: "checkbox",
          publicName: "Mesh visible",
          value: params.isVisible,
          customCb: (val: meshViewerControl) => { handleShowMesh(val.isVisible) },
        },
        // Slider para ajustar el desplazamiento de las deformaciones, en el rango [0, 1000]. Def: [0].
        deformationRange: {
          type: "range",
          publicName: "Displacement",
          value: params.deformationRange,
          min: 0,
          max: 1000,
          step: 1,
          customCb: (val: meshViewerControl) => { handleDeformationsSlider(val.deformationRange) },
        },

        // RadioButton para seleccionar el modo de deformaciones que usaremos, por defecto la Dsum que es la XYZ.
        deformationType: {
          type: "radio",
          publicName: "Deformation type",
          value: params.deformationType,
          list: displacementList,
          customCb: (val: meshViewerControl) => { handleDeformationType(val.deformationType) },
        },
        
        // Boton auxiliar para restaurar la paleta por defecto, la 3D de gradientes de colores posicionales.
        setDefaultPalette: {
          type: "button",
          publicName: "Use default 3D color palette",
          value: undefined,
          buttonCb: () => { handlePaletteSelection(true, -1) },
        },

        // Boton para usar la paleta Salome-Meca 2D.
        setSalomeMecaPalette: {
          type: "button",
          publicName: "Use Salome-Meca 2D color palette",
          value: undefined,
          buttonCb: () => { handlePaletteSelection(true, -2) },
        },

        // RadioButton para seleccionar la variable de F&M para BEAMS que queremos representar con la paleta de colores.
        // Las opciones posibles son [None],N,VY,VZ,MT,MFY,MFZ. Y la [None] es la por defecto.
        beamsFaMsType: {
          type: "radio",
          publicName: "Beams F&M type",
          value: params.beamsFaMsType,
          list: beamsFaMsList,
          customCb: (val: meshViewerControl) => { handleBeamsFaMsType(val.beamsFaMsType) },
        },

        // Idem para seleccion de variable de F&M para SHELLS...
        // Opciones posibles: [None, NXX, NYY, NXY, MXX, MYY, MXY, QX, QY] con None por defecto.
        shellsFaMsType: {
          type: "radio",
          publicName: "Shells F&M type",
          value: params.shellsFaMsType,
          list: shellsFaMsList,
          customCb: (val: meshViewerControl) => { handleShellsFaMsType(val.shellsFaMsType) },
        },

        paletteDXDYDZ: {
          type: "checkbox",
          publicName: "(Dx, Dy, Dz) colors",
          value: params.paletteDXDYDZ,
          customCb: (val: meshViewerControl) => { handlePaletteSelection(val.paletteDXDYDZ, 1) },
        },
        paletteDR: {
          type: "checkbox",
          publicName: "DR colors",
          value: params.paletteDR,
          customCb: (val: meshViewerControl) => { handlePaletteSelection(val.paletteDR, 2) },
        },
        paletteOrange: {
          type: "checkbox",
          publicName: "Orange color",
          value: params.paletteOrange,
          customCb: (val: meshViewerControl) => { handlePaletteSelection(val.paletteOrange, 3) },
        },
        showWireframe: {
          type: "checkbox",
          publicName: "Wireframe mode",
          value: params.showWireframe,
          customCb: (val: meshViewerControl) => { handleShowWire(val.showWireframe) },
        },
        showNodesAsBalls: {
          type: "checkbox",
          publicName: "Nodes as balls",
          value: params.showNodesAsBalls,
          customCb: (val: meshViewerControl) => { handleShowNodesAsBalls(val.showNodesAsBalls) },
        },
        showNodes: {
          type: "checkbox",
          publicName: "Show nodes",
          value: params.showNodes,
          customCb: (val: meshViewerControl) => { handleShowNodes(val.showNodes) },
        },
        showBeams: {
          type: "checkbox",
          publicName: "Show beams",
          value: params.showBeams,
          customCb: (val: meshViewerControl) => { handleShowBeams(val.showBeams) },
        },
        showFatBeams: {
          type: "checkbox",
          publicName: "Show fat beams",
          value: params.showFatBeams,
          customCb: (val: meshViewerControl) => { handleShowFatBeams(val.showFatBeams) },
        },
        showTriangles: {
          type: "checkbox",
          publicName: "Show triangles",
          value: params.showTriangles,
          customCb: (val: meshViewerControl) => { handleShowTriangles(val.showTriangles) },
        },
        showQuads: {
          type: "checkbox",
          publicName: "Show quads",
          value: params.showQuads,
          customCb: (val: meshViewerControl) => { handleShowQuads(val.showQuads) },
        },
        showCells: {
          type: "checkbox",
          publicName: "Show cells",
          value: params.showCells,
          customCb: (val: meshViewerControl) => { handleShowCells(val.showCells) },
        },
        // Para mostrar los nodos/points con colores individuales.
        showColoredNodes: {
          type: "checkbox",
          publicName: "Colored nodes",
          value: params.showColoredNodes,
          customCb: (val: meshViewerControl) => { handleShowColoredNodes(val.showColoredNodes) },
        },
        // Para mostrar los id's (numericos) de los nodos (todos o solo el pinchado) + beams + shells.
        // Muestra TODOS los id's de todos los nodos.
        showNodesIds: {
          type: "checkbox",
          publicName: "Show ALL nodes ID's",
          value: params.showNodesIds,
          customCb: (val: meshViewerControl) => { handleShowIds4NodesBeamsShells(1, val.showNodesIds) },
        },
        // Muestra solo el id del nodo sobre el que ponemos el cursor.
        showPointedNodeId: {
          type: "checkbox",
          publicName: "Show pointed node ID",
          value: params.showPointedNodeId,
          customCb: (val: meshViewerControl) => { handleShowIds4NodesBeamsShells(4, val.showPointedNodeId) },
        },
        showBeamsIds: {
          type: "checkbox",
          publicName: "Show beams ID's",
          value: params.showBeamsIds,
          customCb: (val: meshViewerControl) => { handleShowIds4NodesBeamsShells(2, val.showBeamsIds) },
        },
        showShellsIds: {
          type: "checkbox",
          publicName: "Show shells ID's",
          value: params.showShellsIds,
          customCb: (val: meshViewerControl) => { handleShowIds4NodesBeamsShells(3, val.showShellsIds) },
        },

        // Para mostrar la paleta de colores en curso.
        showPalette: {
          type: "checkbox",
          publicName: "Show palette",
          value: params.showPalette,
          customCb: (val: meshViewerControl) => { handleShowPalette(val.showPalette) },
        },
        // Para mostrar los puntos de integracion, si es que los hay.
        showIntegrationPoints: {
          type: "checkbox",
          publicName: "Show integration points",
          value: params.showIntegrationPoints,
          customCb: (val: meshViewerControl) => { handleShowIntegrationPoints(val.showIntegrationPoints) },
        },

        // Para mostrar los centros de gravedad, aka COG's.
        showCOGs: {
          type: "checkbox",
          publicName: "Show centers of gravity",
          value: params.showCOGs,
          customCb: (val: meshViewerControl) => { handleShowCOGs(val.showCOGs) },
        },

        // Boton auxiliar y temporal para lanzar la carga del mesh via un fichero .json siempre con el mismo nombre.
        loadJSONFile: {
          type: "button",
          publicName: "Load json file",
          value: undefined,
          buttonCb: () => {
            // handleLoadJSONFile("/files_mesh3d/mesh_3d.json");
            handleLoadJSONFile_FileSelectionDialog();
          },
        },
        // Boton auxiliar y temporal para lanzar la carga de deformaciones via fichero seleccionable for dialogo.
        // Obviamente debiera haber ya un mesh cargado y compatible: es decir con exactamente el mismo numero de nodos
        // que de deformaciones.
        loadDeformationsFile: {
          type: "button",
          publicName: "Load deformations file",
          value: undefined,
          buttonCb: () => {
            handleLoadDeformationsFile_FileSelectionDialog();
          },
        },
        // Boton auxiliar/temporal para lanzar la carga de puntos de integracion via fichero seleccionable for dialogo.
        // Obviamente debiera haber ya un mesh cargado y coherente.
        loadIntegrationPointsFile: {
          type: "button",
          publicName: "Load integration points file",
          value: undefined,
          buttonCb: () => {
            handleLoadIntegrationPointsFile_FileSelectionDialog();
          },
        },
        // Boton auxiliar para cargar los variopintos ficheros de SALOME-MECA que nos ha pasado JL, incluso HDF5!!!.
        loadSalomeMecaTxtFiles: {
          type: "button",
          publicName: "Load SALOME/HDF files",
          value: undefined,
          buttonCb: () => {
            handleLoadSalomeMecaFiles_FileSelectionDialog();
          },
        },

        // Boton para la creacion de deformaciones aleatorias.
        createRandomDeformations: {
          type: "button",
          publicName: "Create random deformations",
          value: undefined,
          buttonCb: () => {
            handleCreateRandomDeformations();
          },
        },

        // Para activar el modo de clipping por planos X-Y-Z. Recuerda que se llaman igual el callback y el parametro.
        activateClippingModeXYZ: {
          type: "checkbox",
          publicName: "Activate XYZ clipping",
          value: params.activateClippingModeXYZ,
          customCb: (val: meshViewerControl) => { handleSetClippingMode(val.activateClippingModeXYZ) },
        },

        clipX: {
          type: "range",
          publicName: "Clipping in X axis",
          value: params.clipX,
          min: 0,
          max: 1,
          step: 0.001,
          customCb: (val: meshViewerControl) => { handleClipXYZSlider(0, val.clipX) },
        },
        showClipX: {
          type: "checkbox",
          publicName: "Show plane YZ",
          value: params.showClipX,
          customCb: (val: meshViewerControl) => { handleShowClipXYZ(0, val.showClipX) },
        },
        invClipX: {
          type: "checkbox",
          publicName: "Invert clip in X",
          value: params.invClipX,
          customCb: (val: meshViewerControl) => { handleInvClipXYZ(0, val.invClipX) },
        },

        clipY: {
          type: "range",
          publicName: "Clipping in Y axis",
          value: params.clipY,
          min: 0,
          max: 1,
          step: 0.001,
          customCb: (val: meshViewerControl) => { handleClipXYZSlider(1, val.clipY) },
        },
        showClipY: {
          type: "checkbox",
          publicName: "Show plane XZ",
          value: params.showClipY,
          customCb: (val: meshViewerControl) => { handleShowClipXYZ(1, val.showClipY) },
        },
        invClipY: {
          type: "checkbox",
          publicName: "Invert clip in Y",
          value: params.invClipY,
          customCb: (val: meshViewerControl) => { handleInvClipXYZ(1, val.invClipY) },
        },

        clipZ: {
          type: "range",
          publicName: "Clipping in Z axis",
          value: params.clipZ,
          min: 0,
          max: 1,
          step: 0.001,
          customCb: (val: meshViewerControl) => { handleClipXYZSlider(2, val.clipZ) },
        },
        showClipZ: {
          type: "checkbox",
          publicName: "Show plane XY",
          value: params.showClipZ,
          customCb: (val: meshViewerControl) => { handleShowClipXYZ(2, val.showClipZ) },
        },
        invClipZ: {
          type: "checkbox",
          publicName: "Invert clip in Z",
          value: params.invClipZ,
          customCb: (val: meshViewerControl) => { handleInvClipXYZ(2, val.invClipZ) },
        },

        drawTestArrows: {
          type: "button",
          publicName: "Draw test arrows",
          value: undefined,
          buttonCb: () => {
            handleCreateArrows();
          },
        },

        showGraphicInfo: {
          type: "button",
          publicName: "Show graphic info",
          value: undefined,
          buttonCb: () => {
            handleShowGraphicInfo(graphicProcessor);
          },
        }
      },
      propCallback: (param: meshViewerControl) => {
        setMeshViewerParam(param);
      },
    };
  }

  // Accesor al objeto Three grafico, que recordemos que tiene el modelo original colgado en su userData.
  const getModelGO = () => {
    // Ya que tenemos el graphicProcessor en curso le podemos sacar el mesh grafico del que sabemos su nombre.
    const meshName = "mesh_model3D";
    const sceneMngr = graphicProcessor.getSceneManager();
    const obj3D = sceneMngr.mainScene.getObjectByName(meshName);
    if (obj3D) {
      return obj3D;
    } else {
      console.error("ERROR: No se ha encontrado el objeto grafico '" + meshName + "'.");
      window.alert("ERROR: No se ha encontrado el objeto grafico '" + meshName + "'.");
      return null;
    }
  };

  // Accesor al modelo de malla, colgado del objeto grafico.
  const getModel = () => {
    const obj3D = getModelGO();
    if (obj3D) {
      if (obj3D.userData) {
        // Del objeto grafico saco el modelo inicial.
        const model3D = obj3D.userData as StructModel3D;
        return model3D;
      } else {
        console.error("ERROR: No se ha encontrado el modelo original dentro del objeto grafico.");
        window.alert("ERROR: No se ha encontrado el modelo original dentro del objeto grafico.");
      }
    }
    return null;
  };

  // Suponemos inicialmente que NO se ve el objeto y el boton NO esta activado.
  const handleShowMesh = (val: boolean) => {
    const obj3D = getModelGO();
    if (obj3D) {
      obj3D.visible = val;
      console.log("mesh_model3D VISIBLE: " + (obj3D.visible ? "ON" : "OFF"));
    }
  };

  // Aqui gestionamos el slider de las deformaciones.
  const handleDeformationsSlider = (value: number) => {
    const model3D = getModel();
    if (model3D) {
      console.log("Setting deformation coefficient: " + value);
      model3D.applyDeformationsCoeff(value);
    }
  };

  // Aqui el tipo de deformacion que aplicaremos X/Y/Z/XYZ/rotX...
  const handleDeformationType = (val: string) => {
    const model3D = getModel();
    if (model3D) {
      const index = displacementList.indexOf(val);
      if (-1 === index) {
        const msg = "ERROR: Invalid deformation type <" + val + ">";
        console.error(msg);
        window.alert(msg);
      } else {
        model3D.setPaletteById(index);
        if (model3D.setDeformationType(val)) {
          console.log("Deformation type selected: " + val);
        } else {
          const msg = "ERROR: Invalid deformation type <" + val + ">";
          console.error(msg);
          window.alert(msg);
        }
      }
    }
  };

  const handleBeamsFaMsType = (val: string) => {
    const model3D = getModel();
    if (model3D) {
      const index = beamsFaMsList.indexOf(val);
      if (-1 === index) {
        const msg = "ERROR: Invalid BEAMS-F&M type <" + val + ">";
        console.error(msg);
        window.alert(msg);
      } else {
        // Recuerda que index 0 es "None for BEAMS F&M".
        model3D.setPaletteById(index + 10);
      }
    }

    console.log(`Seleccionada la paleta para BEAM-F&M de tipo "${val}"...`);
  };

  const handleShellsFaMsType = (val: string) => {
    const model3D = getModel();
    if (model3D) {
      const index = shellsFaMsList.indexOf(val);
      if (-1 === index) {
        const msg = "ERROR: Invalid SHELLS-F&M type <" + val + ">";
        console.error(msg);
        window.alert(msg);
      } else {
        if (index === 0) {
          // "None for SHELLS F&M", luego ponemos la paletuca por defecto, la guai...
        } else {
          model3D.setPaletteById(index + 20);
        }        
      }
    }

    console.log(`Seleccionada la paleta para SHELL-F&M de tipo "${val}"...`);
  };

  // Aqui seleccionamos entre la paleta por defecto, basada en RGB por distancias, o la paleta del grado de deformacion,
  // o la paleta naranja...
  // Como de momento solo tenemos 3 paletas lo hago con un biEstado true/false. Inicialmente a false para usar RGB y
  // cuando se active el checkbox pasamos a paleta de modo DEFORMATION.
  const handlePaletteSelection = (val: boolean, paletteId: number) => {
    const model3D = getModel();
    if (model3D) {
      if (val === false) {
        paletteId = 0;
      }
      model3D.setPaletteById(paletteId);
      console.log("Palette: " + (val ? "DEFORMATION" : "RGB"));
    }
  };

  // Show [W]ireframe on/[off].
  const handleShowWire = (val: boolean) => {
    const model3D = getModel();
    if (model3D) {
      model3D.setWireFrameOnOff(val);
      console.log("mesh_model3D WIREFRAME VISIBLE: " + (val ? "ON" : "OFF"));
    }
  };

  // Con el valor true, se visualizan los nodos como bolitas azules con sprites, mientras que con el valor por defecto,
  // false, se visualizan como simples puntos cuadrados, que en teoria es mucho mas liviano.
  const handleShowNodesAsBalls = (val: boolean) => {
    const model3D = getModel();
    if (model3D) {
      model3D.showNodesAsBallsOrPoints(val);
      console.log("Show nodes as: " + (val ? "Balls" : "Points"));
    }
  }

  // Show [N]odes [on]/off.
  const handleShowNodes = (val: boolean) => {
    const model3D = getModel();
    if (model3D) {
      model3D.setVisibilityOnOff(StructModel3D.name4Nodes, val);
      console.log("mesh_model3D NODES visibility: " + (val ? "ON" : "OFF"));
    }
  };

  // Show [B]eams [on]/off.
  const handleShowBeams = (val: boolean) => {
    const model3D = getModel();
    if (model3D) {
      model3D.setVisibilityOnOff(StructModel3D.name4Beams, val);
      console.log("mesh_model3D BEAMS visibility: " + (val ? "ON" : "OFF"));
    }
  };

  // Show [B]eams on/[off] as fat lines.
  const handleShowFatBeams = (val: boolean) => {
    const model3D = getModel();
    if (model3D) {
      model3D.setVisibilityOnOff(StructModel3D.name4FatBeams, val);
      console.log("mesh_model3D FAT BEAMS visibility: " + (val ? "ON" : "OFF"));
    }
  };

  // Show [T]riangles [on]/off.
  const handleShowTriangles = (val: boolean) => {
    const model3D = getModel();
    if (model3D) {
      model3D.setVisibilityOnOff(StructModel3D.name4Tris, val);
      console.log("mesh_model3D TRIANGLES visibility: " + (val ? "ON" : "OFF"));
    }
  };

  // Show [Q]uads [on]/off.
  const handleShowQuads = (val: boolean) => {
    const model3D = getModel();
    if (model3D) {
      model3D.setVisibilityOnOff(StructModel3D.name4Quads, val);
      console.log("mesh_model3D QUADS visibility: " + (val ? "ON" : "OFF"));
    }
  };

  // Show [C]ells [on]/off.
  const handleShowCells = (val: boolean) => {
    const model3D = getModel();
    if (model3D) {
      model3D.setVisibilityOnOff(StructModel3D.name4TrisEdges, val);
      model3D.setVisibilityOnOff(StructModel3D.name4QuadsEdges, val);
      console.log("mesh_model3D CELLS visibility: " + (val ? "ON" : "OFF"));
    }
  };

  // Show nodes with individual colors: on/[off].
  const handleShowColoredNodes = (val: boolean) => {
    console.log("handleShowColoredNodes: " + val);
    const model3D = getModel();
    if (model3D) {
      model3D.setColoredNodes(val);
      console.log("mesh_model3D show NODES with individual colours: " + (val ? "ON" : "OFF"));
    }
  };

  // Muestra los numeritos de los indices de los nodos[1], o de los beams[2] o de los shells[3]: on/[off].
  // Ademas con el valor 4 solo se muestra el id del nodo sobre el que pongamos el cursor.
  const handleShowIds4NodesBeamsShells = (whichOne: 1 | 2 | 3 | 4, val: boolean) => {
    console.log("handleShowNodesIds: " + val);
    const model3D = getModel();
    if (model3D) {
      if (whichOne === 1) {
        model3D.setNodesIds(val);
        console.log("mesh_model3D show NODES with numeric ID's: " + (val ? "ON" : "OFF"));
        return;
      }
      if (whichOne === 2) {
        model3D.setBeamsIds(val);
        console.log("mesh_model3D show BEAMS with numeric ID's: " + (val ? "ON" : "OFF"));
        return;
      }
      if (whichOne === 3) {
        model3D.setShellsIds(val);
        console.log("mesh_model3D show SHELLS with numeric ID's: " + (val ? "ON" : "OFF"));
        return;
      }
      if (whichOne === 4) {
        model3D.setPointedNodeId(val, graphicProcessor);        
        return;
      }
    }
  };

  // Muestra la paleta de colores.
  const handleShowPalette = (val: boolean) => {
    const model3D = getModel();
    if (model3D) {
      if (model3D.setVisibilityOnOff(StructModel3D.name4Palette, val)) {
        console.log("mesh_model3D PALETTE visibility: " + (val ? "ON" : "OFF"));
      }
    }
  };

  // Muestra los puntos de integracion.
  const handleShowIntegrationPoints = (val: boolean) => {
    const model3D = getModel();
    if (model3D) {
      if (model3D.setVisibilityOnOff(StructModel3D.name4IPoints, val)) {
        console.log("mesh_model3D INTEGRATION POINTS visibility: " + (val ? "ON" : "OFF"));
      } else {
        window.alert("WARNING: Primero se debe cargar algun fichero con puntos de integracion.");
      } 
    }
  };

  // Muestra los centros de gravedad aka COG's.
  const handleShowCOGs = (val: boolean) => {
    const model3D = getModel();
    if (model3D) {
      if (model3D.setVisibilityOnOff(StructModel3D.name4COGs, val)) {
        console.log("mesh_model3D COG's (CENTERS OF GRAVITY POINTS) visibility: " + (val ? "ON" : "OFF"));
      } 
    }
  };

  const handleLoadJSONFile = (fileName: string) => {
    console.log("Trying to load mesh file '" + fileName + "'...");
    const obj4JSON = StructModel3D.readJSON(fileName);
    if (obj4JSON) {
      const model = StructModel3D.createStructModel3D_from_JSON_graphicProcessor(obj4JSON, graphicProcessor);
      if (model) {
        console.log("\tCorrectly loaded mesh model file '" + fileName + "'...");
      } else {
        window.alert("ERROR: Can't load mesh model file '" + fileName + "'.");
      }
    }
  };

  const handleLoadJSONFile_FileSelectionDialog = () => {
    console.log("Trying to load mesh file with file selection dialog...");
    const file_input = document.createElement('input') as HTMLInputElement;
    
    const processFile = () => {
      if (file_input.files) {
        if (file_input.files.length) {
          const file = file_input.files[0];
          let fileName = file.name;
          // Solo permitimos abrir ficheros que esten en el directorio files_mesh3d/.
          // Ademas sin este prefijo no funciona la carga, que supongo limitada solo a este directorio.
          if (!fileName.startsWith("/files_mesh3d/")) {
            fileName = "/files_mesh3d/" + fileName;
          }
          console.log("Selected file: '" + fileName + "'");
          handleLoadJSONFile(fileName);
        }
      }
    };

    file_input.addEventListener("change", processFile, false);
    file_input.type = 'file';
    file_input.accept = '.json';
    // No funcionan.
    // file_input.title = 'Select JSON file to load a mesh model';
    // file_input.pattern = '*.json';
    file_input.click();
  };

  const handleLoadDeformationsFile_FileSelectionDialog = () => {
    // Solo dejamos cargar deformaciones si hay un modelo previamente cargado.
    const model = getModel();
    if (model) {
      console.log("Trying to load deformations file with file selection dialog...");
      const file_input = document.createElement('input') as HTMLInputElement;

      const processFile = () => {
        if (file_input.files) {
          if (file_input.files.length) {
            const file = file_input.files[0];
            let fileName = file.name;
            // Solo permitimos abrir ficheros que esten en el directorio files_mesh3d/.
            // Ademas sin este prefijo no funciona la carga, que supongo limitada solo a este directorio.
            if (!fileName.startsWith("/files_mesh3d/")) {
              fileName = "/files_mesh3d/" + fileName;
            }
            console.log("Selected file: '" + fileName + "'");
            if (model.setDeformations4File(fileName)) {
              console.log("\t Assigned deformations file '" + fileName + "'.");
            } else {
              console.log("\t ERROR: Can't associate deformations file '" + fileName + "'.");
            }      
          }
        }
      };
  
      file_input.addEventListener("change", processFile, false);
      file_input.type = 'file';
      file_input.accept = '.dat';
      // file_input.title = 'Select deformations file for current mesh model';
      // file_input.pattern = '*.*';
      file_input.click();
    } else {
      window.alert("ERROR: You must load a mesh model previously.");
    }
  };

  const handleLoadIntegrationPointsFile_FileSelectionDialog = () => {
    // Solo dejamos cargar puntos de integracion si hay un modelo previamente cargado.
    const model = getModel();
    if (model) {
      console.log("Trying to load integration points file with file selection dialog...");
      const file_input = document.createElement('input') as HTMLInputElement;

      const processFile = () => {
        if (file_input.files) {
          if (file_input.files.length) {
            const file = file_input.files[0];
            let fileName = file.name;
            // Solo permitimos abrir ficheros que esten en el directorio files_mesh3d/.
            // Ademas sin este prefijo no funciona la carga, que supongo limitada solo a este directorio.
            if (!fileName.startsWith("/files_mesh3d/")) {
              fileName = "/files_mesh3d/" + fileName;
            }
            console.log("Selected file: '" + fileName + "'");
            if (model.setIntegrationPoints4File(fileName)) {
              console.log("\t Assigned integration points file '" + fileName + "'.");
            } else {
              console.log("\t ERROR: Can't associate integration points file '" + fileName + "'.");
            }      
          }
        }
      };
  
      file_input.addEventListener("change", processFile, false);
      file_input.type = 'file';
      file_input.accept = '.dat';
      file_input.click();
    } else {
      window.alert("ERROR: You must load a mesh model previously.");
    }
  };

  const handleLoadSalomeMecaFiles_FileSelectionDialog = () => {
    const model = getModel();
    if (model) {
      console.log("Trying to load another data file with file selection dialog...");
      // Habria que destruirlo despues???.
      const file_input = document.createElement('input') as HTMLInputElement;

      const processFile = async () => {
        if (file_input.files) {
          if (file_input.files.length) {
            const file = file_input.files[0];
            let fileName = file.name;
            // Solo permitimos abrir ficheros que esten en el directorio files_mesh3d/.
            // Ademas sin este prefijo no funciona la carga, que supongo limitada solo a este directorio.
            if (!fileName.startsWith("/files_mesh3d/")) {
              fileName = "/files_mesh3d/" + fileName;
            }
            console.log("Selected file: '" + fileName + "'");
            // OJO que ahora pillamos tanto *.TXT como *.RMED. Otra cosa es que los podamos procesar bien...
            let result = await model.processSalomeMecaFile(fileName);
            if (result) {
              console.log("\t Processed SALOME-MECA file '" + fileName + "'.");
            } else {
              console.log("\t ERROR: Can't process SALOME-MECA '" + fileName + "'.");
            }      
          }
        }
      };
  
      file_input.addEventListener("change", processFile, false);
      file_input.type = 'file';
      // No ponemos el accept ya que no pilla el .rmed/hdf asi que procesamos la extension en mi parte...
      // file_input.accept = '.rmed, application/x-hdf, .txt';
      file_input.click();
    } else {
      window.alert("ERROR: You must load a mesh model previously.");
    }
  };
  const handleCreateRandomDeformations = () => {
    console.log("Creating random deformations for current model...");
    const model = getModel();
    if (model) {
      model.createRandomDeformations4Nodes();
    } else {
      window.alert("ERROR: You must load a mesh model previously.");
    }
  };

  const handleSetClippingMode = (val: boolean) => {
    const model3D = getModel();
    if (model3D) {
      model3D.setClippingModeXYZ(val, graphicProcessor);
      console.log("mesh_model3D clipping mode XYZ: " + (val ? "ON" : "OFF"));
    }
  };

  const handleClipXYZSlider = (axisXYZ: 0 | 1 | 2, value: number) => {
    console.log("\thandleClipXYZSlider(" + axisXYZ + ", " + value + ")");
    const model3D = getModel();
    if (model3D) {
      model3D.clipXYZ(axisXYZ, value);
    }
  };

  const handleInvClipXYZ = (axisXYZ: 0 | 1 | 2, val: boolean) => {
    console.log("\thandleInvClipXYZ(" + axisXYZ + ", " + val + ")");
    const model3D = getModel();
    if (model3D) {
      model3D.invertClipPlaneXYZ(axisXYZ, val);
    }
  };

  const handleShowClipXYZ = (axisXYZ: 0 | 1 | 2, val: boolean) => {
    console.log("\thandleShowClipXYZ(" + axisXYZ + ", " + val + ")");
    const model3D = getModel();
    if (model3D) {
      model3D.showClipPlaneXYZ(axisXYZ, val);
    }
  };

  const handleCreateArrows = () => {
    console.log("\thandleCreateArrows()");
    const model3D = getModel();
    if (model3D) {
      // model3D.testArrowsPerformance();
      const obj3D = getModelGO();
      if (obj3D) {
        testNewShaders4Points(obj3D as THREE.Scene, 2);
      }
    }    
  };

  const handleShowGraphicInfo = (graPro: GraphicProcessor) => {
    console.log("\thandleShowGraphicInfo()");
    const model3D = getModel();
    if (model3D) {
      model3D.showGraphicInfo(graPro);
    }
  };


  return {
    meshViewerSettings: getPropSettingsFromMeshViewer(meshViewerParam),
  };
}