import { CopyCommand } from "lib/commands/copy";
import { copyIPoint } from "lib/math/point";
import { cloneDataModel } from "lib/models/model-creator/datamodel-factory";
import { cadOpType } from "../factory";
import { settingsOpModes } from "../step-operations";
import { TranslationBaseOP } from "../transform/translation";

export class CopyOP extends TranslationBaseOP {

  public opType = cadOpType.COPY;

  public setLastPoint(): void {
    if (this.basePoint) {
      this.endPoints.push(copyIPoint(this.lastPoint));
      for (const aux of this.objDataAux) {
        const obj = cloneDataModel(aux);
        this.setAuxObj(obj.graphicObj);
        this.saveToTempScene(obj.graphicObj);
      }
    } else {
      this.setNextStep();
    }
  }

  public cancelOperation() {
    const step = this.settingsOpManager.currCfg;
    if (step.stepMode === settingsOpModes.SELECTOBJS && step.multiSelect && this.objDataOrigin.length) {
      this.setNextStep();
    } else {
      this.save();
      this.endOperation();
    }
  }

  public save() {
    if (this.graphicProcessor && this.basePoint && this.endPoints.length) {
      const command = new CopyCommand(
        this.basePoint,
        this.endPoints,
        this.objDataOrigin.slice(),
        this.getCurrentSceneId(),
        this.graphicProcessor
      );
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
}