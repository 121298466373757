const DimensionLinearIcon = ({ ...props }) => {
  return (
    <svg className="h-5 w-5" viewBox="0 0 100 100" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4.16667C10 1.87 8.13 0 5.83333 0H4.16667C1.87 0 0 1.87 0 4.16667V95.8333C0 98.13 1.87 100 4.16667 100H5.83333C8.13 100 10 98.13 10 95.8333V23.3333V23.3233V4.16667Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.8333 0H94.1667C91.87 0 90 1.87 90 4.16667V23.3333V95.8333C90 98.13 91.87 100 94.1667 100H95.8333C98.13 100 100 98.13 100 95.8333V4.16667C100 1.87 98.13 0 95.8333 0Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.3708 14.014C81.0725 13.559 80.5842 13.2407 79.9992 13.2407C79.0792 13.2407 78.3325 13.9873 78.3325 14.9073V21.6673H21.6658V15.0007C21.6658 14.7823 21.6225 14.5673 21.5375 14.3623C21.3692 13.9557 21.0442 13.6307 20.6375 13.4623C20.2292 13.294 19.7708 13.294 19.3625 13.4623C19.1592 13.5457 18.9742 13.669 18.8208 13.8223L10.4875 22.1557C10.4092 22.234 10.3692 22.334 10.3108 22.424C10.2475 22.5173 10.1658 22.6007 10.1242 22.7023C10.0842 22.8023 10.0825 22.9123 10.0608 23.0173C10.0408 23.1207 10.0008 23.219 9.99918 23.324C9.99918 23.3257 10.0008 23.3273 10.0008 23.329C10.0008 23.3307 9.99918 23.3323 9.99918 23.334C9.99918 23.434 10.0392 23.529 10.0575 23.629C10.0775 23.739 10.0792 23.8507 10.1208 23.9557C10.1592 24.0523 10.2375 24.1307 10.2942 24.219C10.3558 24.3157 10.3975 24.4207 10.4825 24.5057L18.8158 32.9307C18.8158 32.9323 18.8175 32.9323 18.8175 32.9323C18.9725 33.089 19.1558 33.2123 19.3608 33.2973C19.5642 33.3823 19.7825 33.4257 19.9992 33.4257C20.2158 33.4257 20.4308 33.3823 20.6342 33.2973C20.7908 33.234 20.9208 33.1307 21.0492 33.0257C21.0875 32.994 21.1358 32.9807 21.1725 32.944C21.1725 32.944 21.1725 32.9423 21.1742 32.9407C21.3292 32.7873 21.4525 32.604 21.5375 32.4007C21.6225 32.1973 21.6658 31.9807 21.6658 31.764V31.759V25.0007H78.3325V31.6673C78.3325 31.884 78.3775 32.099 78.4625 32.304C78.6308 32.7107 78.9558 33.0357 79.3625 33.204C79.5675 33.289 79.7825 33.334 79.9992 33.334C80.2175 33.334 80.4325 33.289 80.6375 33.204C80.8408 33.1207 81.0258 32.999 81.1792 32.844L89.5125 24.5123C89.8375 24.1857 89.9992 23.759 89.9992 23.334C89.9992 22.9073 89.8375 22.4807 89.5125 22.1557L81.3708 14.014Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DimensionLinearIcon;
