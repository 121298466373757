import * as THREE from "three";
import { MultiEdition } from "../edition-multi";
import { lineAddVertex, lineAuxCreate, lineCreateIPoints, lineMoveVertex } from "../../geometries/line";
import { cadOpType } from "../factory";
import { settingsOpModes } from "../step-operations";
import { IPoint } from "../../math/types";
import { IObjData, } from "../../models/objdata";
import { intersectObjects } from "../../math/intersections";
import { LineData } from "../../models/primitives/line";
import { PolygonData } from "../../models/primitives/polygon";
import { getAuxMaterialLine } from "../../materials";
import { getPolylinePointPosition } from "../../math/point";
import { TrimLinesCommand } from "../../commands/edition/trimlines";
import { isLinealGeometry } from "lib/models/checktools";

export class TrimLinesOP extends MultiEdition {

  public opType = cadOpType.TRIMLINES;
  private auxLine: THREE.Line;

  public trimLines: (LineData | PolygonData)[] = [];
  public masterLine!: LineData;

  public leftResultsLines: THREE.Line[] = [];
  public rightResultsLines: THREE.Line[] = [];

  public chooseIn: boolean = true;

  protected iniSettingsOp() {

    this.settingsOpManager.setCfg([{
      infoMsg: `Select entities to trim.`,
      stepMode: settingsOpModes.SELECTOBJS,
      multiSelect: true,
      enableSelectMarks: true,
      filterFun: (o: IObjData) => {
        const res = isLinealGeometry(o);
        return res;
      },
      endStepCallback: async () => {
        this.trimLines = this.objDataOrigin as (LineData | PolygonData)[];
        this.objDataOrigin = [];
      },
    }, {
      infoMsg: `Select entity for cutting edges.`,
      stepMode: settingsOpModes.SELECTOBJS,
      multiSelect: false,
      enableSelectMarks: true,
      filterFun: (o: IObjData) => {
        if (this.trimLines.some((l) => l.id === o.id)) {
          return false;
        }
        const res = isLinealGeometry(o);
        return res;
      },
      endStepCallback: async () => {
        this.unRegisterRaycast();
        this.masterLine = this.objDataOrigin[0] as LineData;
        this.showResult();
      },
    }, {
      infoMsg: `Trim lines.`,
      stepMode: settingsOpModes.WAITMODE,
      startStepCallback: () => {
        this.registerInputs();
        this.registerUpdaters();
      },
      stepFun: this.dispathSaveMouseCoordinates.bind(this),
    }]);
  }

  public async start() {
    this.iniSettingsOp();
    this.auxLine = lineAuxCreate();
    lineAddVertex(this.auxLine, 0, 0, 0);
    lineAddVertex(this.auxLine, 0, 0, 0);
    this.saveToTempScene(this.auxLine);

    this.registerCancel();
    this.registerRaycast();
    this.setStartObjs();
    if (this.objDataOrigin.length > 0) {
      this.setNextStep();
    }
  }

  private showHideOriGeoms(visible: boolean) {
    for (let i: number = 0, l: number = this.trimLines.length; i < l; i++) {
      this.trimLines[i].visibleGraphicObject = visible;
    }
  }

  private showResult(): void {
    this.graphicProcessor.unselectAll();
    // Hide original objects
    this.showHideOriGeoms(false);

    let res = intersectObjects(this.masterLine, this.trimLines);
    for (let i = 0, l = res.length; i < l; i++) {
      const r = res[i];
      if (r === null || r.left.length === 0 || r.right.length === 0) {
        // Object without intersections: take out of trimLine stack
        const objs = this.trimLines.splice(i, 1);
        if (objs[0]) {
          objs[0].visibleGraphicObject = true;
        }
        for (const object of objs) {
          this.graphicProcessor.unSelectObjData(object);
        }
      } else {
        // Generate and save results of intersections
        for (const points of r.left) {
          const line = lineCreateIPoints(points, undefined, getAuxMaterialLine());
          this.leftResultsLines.push(line);
          this.saveToTempScene(line);
        }
        for (const points of r.right) {
          const line = lineCreateIPoints(points, undefined, getAuxMaterialLine());
          this.rightResultsLines.push(line);
          this.saveToTempScene(line);
        }
      }
    }
    // No intersection results --> cancel operation
    if (this.leftResultsLines.length === 0 && this.rightResultsLines.length === 0) {
      this.cancelOperation();
    }
  }

  private chooseRes(p: IPoint): void {
    const res = getPolylinePointPosition(this.masterLine.definition.points, p);
    this.chooseIn = res.pointPosition < 0;

    lineMoveVertex(this.auxLine, p.x, p.y, p.z, 0);
    lineMoveVertex(
      this.auxLine,
      res.pointOnLine.x,
      res.pointOnLine.y,
      res.pointOnLine.z,
      1
    );
  }

  public setLastPoint() {
    this.chooseRes(this.lastPoint);
    this.endOperation();
  }
  public moveLastPoint(point: IPoint) {
    if (this.trimLines.length > 0 && this.masterLine) {
      this.chooseRes(point);
      this.leftResultsLines.forEach((l) => {
        l.visible = this.chooseIn;
      });
      this.rightResultsLines.forEach((l) => {
        l.visible = !this.chooseIn;
      });
    }
  }

  public cancelOperation(): void {
    this.showHideOriGeoms(true);
    if (this.leftResultsLines.length) {
      this.endOperation();
    } else {
      super.cancelOperation();
    }
  }

  public endOperation(): void {
    this.showHideOriGeoms(true);
    if (this.leftResultsLines.length) {
      this.save();
    }
    super.endOperation();
  }

  public save() {
    if (this.graphicProcessor) {
      const command = new TrimLinesCommand(this.chooseIn, this.masterLine, this.trimLines, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
}