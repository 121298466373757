import { IPoint } from "lib/math/types";
import { style } from "lib/styles/style-cache";
import { copyIPoint } from '../../math/point';

export interface IPileCapTemplate {
  name: string;  
  capCoords: IPoint[];
  pileCoords: IPoint[];
}

export class PileCapTemplate implements style<IPileCapTemplate>, IPileCapTemplate {

  public default: boolean = false;
  public styleId: string;

  public name: string = "Custom pile cap template";
  public capCoords: IPoint[] = [];
  public pileCoords: IPoint[] = [];

  constructor(opts?: IPileCapTemplate) {
    if (opts !== undefined) {
      this.override(opts);
    }
  }

  public override(newProperties: Partial<IPileCapTemplate>) {
    if (newProperties) {
      if (newProperties.name !== undefined) this.name = newProperties.name;
      if (newProperties.capCoords !== undefined) this.capCoords = newProperties.capCoords.map(copyIPoint);
      if (newProperties.pileCoords !== undefined) this.pileCoords = newProperties.pileCoords.map(copyIPoint);
    }
  }

  public clone(): PileCapTemplate {
    const dolly = new PileCapTemplate(this);
    dolly.default = this.default;
    dolly.styleId = this.styleId;
    return dolly;
  }
}


