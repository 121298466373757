import { lineAddVertex, lineMoveVertex } from "../../geometries/line";
import { mirrorObj } from "../../geometries/mirror";
import { setGeometryFromThreeObj } from "../../helpers/cloning";
import { vector3Equals } from "../../math/epsilon";
import { copyIPoint } from "../../math/point";
import { IPoint } from "../../math/types";
import { TransformEdition } from "./transform-edition";
import { cadOpType } from "../factory";
import { settingsOpModes } from "../step-operations";
import { MirrorCommand } from "lib/commands/transform/mirror";

export class MirrorOP extends TransformEdition {

  public opType = cadOpType.MIRROR;
  public endPoint: IPoint | null = null;
  public withCopy: boolean = true;

  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([
      {
        infoMsg: "Select objects.",
        stepMode: settingsOpModes.SELECTOBJS,
        multiSelect: true,
        enableSelectMarks: true,
        filterFun: () => true,
        endStepCallback: async () => {
          this.unRegisterRaycast();
          this.setAuxObjs();
          this.objDataAux.forEach((o) => this.saveToTempScene(o.graphicObj));
          this.registerInputs();
          this.registerUpdaters();
          this.initializeSnap();
        },
      },
      {
        infoMsg: "Specify the first point of mirror line",
        stepMode: settingsOpModes.DEFAULTXYZ,
        cmdLineListener: this.addPointFromExt.bind(this),
        endStepCallback: () => {
          this.basePoint = copyIPoint(this.lastPoint);
          lineAddVertex(this.auxLine, this.basePoint.x, this.basePoint.y, this.basePoint.z);
          lineAddVertex(this.auxLine, this.basePoint.x, this.basePoint.y, this.basePoint.z);
        },
      },
      {
        infoMsg: "Specify the second point of mirror line",
        stepMode: settingsOpModes.DEFAULTXYZ,
        cmdLineListener: this.addPointFromExt.bind(this),
        endStepCallback: () => {
          this.endPoint = copyIPoint(this.lastPoint);
          this.unRegisterRaycast();
          this.unRegisterInputs();
        },
      },
      {
        infoMsg: "Delete source objects (Yes/No): ",
        stepMode: settingsOpModes.ONEBOX,
        currValue: () => "N",
        cmdLineListener: (cmd: string) => {
          this.withCopy = ((cmd.toLowerCase() !== "yes") && (cmd.toLowerCase() !== "y"));
          this.save();
          this.endOperation();
        }
      },
    ]);
  }

  public moveLastPoint(pto: IPoint) {
    if (this.basePoint) {
      lineMoveVertex(this.auxLine, pto.x, pto.y, this.basePoint.z);
      this.endPoint = { x: pto.x, y: pto.y, z: this.basePoint.z };
      if (!vector3Equals(this.basePoint, this.endPoint)) {
        for (let index = 0; index < this.objDataOrigin.length; index++) {
          setGeometryFromThreeObj(this.objDataOrigin[index].graphicObj, this.objDataAux[index].graphicObj);
          mirrorObj(this.objDataAux[index].graphicObj, this.basePoint, this.endPoint);
        }
      }
    }
  }

  public save() {
    if (this.basePoint && this.endPoint) {
      const command = new MirrorCommand(this.basePoint, this.endPoint, this.objDataOrigin, this.withCopy, this.graphicProcessor);
      this.graphicProcessor.storeAndExecute(command);
    }
  }
}