import { FC } from "react";
import styled from "styled-components";

const Layout: FC = ({ children }) => {
  return (
    <Wrapper>
      <main>{children}</main>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  height: 100vh;
  background: var(--color-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 24px;
  padding-right: 24px;
`;
export default Layout;
