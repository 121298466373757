const IntersectionIcon = ({ ...props }) => {
  return (
    <svg className="h-5 w-5" viewBox="0 0 100 100" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.7886 6.05566L61.7065 40.1377C59.2388 38.5038 56.3012 37.5254 53.1372 37.5254C44.5448 37.5254 37.5122 44.5579 37.5122 53.1504C37.5122 53.6018 37.6085 54.0268 37.6465 54.4688L4.28467 65.5894L6.26221 71.522L39.4165 60.4746C39.6434 60.8965 39.8489 61.3341 40.1123 61.7319L6.26221 95.582L10.4858 99.8057L44.2993 65.9922C46.8206 67.738 49.8565 68.7754 53.1372 68.7754C61.7296 68.7754 68.7622 61.7429 68.7622 53.1504C68.7622 52.3353 68.6399 51.5523 68.5181 50.77L100.012 40.272L98.0347 34.3394L66.3452 44.8984C66.2219 44.7024 66.1106 44.5026 65.979 44.3125L100.012 10.2793L95.7886 6.05566ZM53.1372 43.7754C54.5837 43.7754 55.9354 44.117 57.1533 44.6909L52.2461 49.5981L43.8354 52.4058C44.2175 47.5532 48.1791 43.7754 53.1372 43.7754ZM62.4756 52.7842C62.4804 52.9091 62.5122 53.0242 62.5122 53.1504C62.5122 58.3648 58.3519 62.5254 53.1372 62.5254C51.5744 62.5254 50.1302 62.115 48.8403 61.4512L55.0293 55.2622L62.4756 52.7842Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IntersectionIcon;
