import { OperationActionType, operationAction } from "lib/events/operation";
import { GraphicProcessor } from '../graphic-processor';
import { settingOp, ISelectObj, ISelectVertex, ISelectEdge } from "./step-operations";

export class SettingOpManager {

  public index = 0;
  public cfg: settingOp[] = [];
  public currCfg: settingOp;

  private graphicProcessor: GraphicProcessor;

  constructor(graphicProcessor: GraphicProcessor) {
    this.graphicProcessor = graphicProcessor;
  }

  public hasFilterFun(step: any): step is ISelectObj | ISelectVertex | ISelectEdge {
    if (step.filterFun) return true;
    return false;
  }

  public setCfg(cfg: settingOp[]) {
    this.cfg = cfg;
    this.setCurrentStep();
  }
  public goNextStep(indx?: number) {
    if (this.currCfg.endStepCallback) {
      this.currCfg.endStepCallback();
    }
    if (indx !== undefined) {
      this.index = indx;
    } else {
      if (this.index !== undefined) this.index++;
    }
    this.setCurrentStep();
  }
  private setCurrentStep() {
    this.currCfg = this.cfg[this.index];
    if (this.currCfg) {
      if (this.currCfg.startStepCallback) {
        this.currCfg.startStepCallback(this.currCfg);
      }
      this.dispatchSetCurrStep();
    }
  }

  private dispatchSetCurrStep() {
    this.dispatch({
      type: OperationActionType.SET_CURRENT_CONFIG,
      payload: { currCfg: this.currCfg },
    });
  }
  public dispatchUpdateCurrStep() {
    this.dispatch({
      type: OperationActionType.UPDATE_CURRENT_CONFIG,
      payload: { currCfg: this.currCfg },
    });
  }
  public dispatch(action: operationAction) {
    this.graphicProcessor.operationObserver.dispatch(action);
  }
}
