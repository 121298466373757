import { cadOpType } from "../factory";
import { createCube, createBufferCube } from "../../geometries/solid/cube";
import { Parameter3dOP } from "../solids/parameter-3d";
import { copyIPoint } from "../../math/point";
import { IPoint } from "../../math/types";
import { vectorDist3D } from "../../math/distance";
import { lineAngle2p, lineSlope2p } from "../../math/angles";
import { CubeCommand } from "../../commands/primitives/cube";
import { settingsOpModes } from "../step-operations";
import { getCurrentSolidMaterial } from "lib/materials";
import { getXYZEulerFromEulerAngles } from "lib/math/plane";

export class CubeOP extends Parameter3dOP {
  public opType = cadOpType.CUBE;
  protected static sampleData = { side: 1 };

  constructor() {
    super();
    this.offSet.x = CubeOP.sampleData.side * 0.5;
    this.offSet.y = CubeOP.sampleData.side * 0.5;
    this.offSet.z = CubeOP.sampleData.side * 0.5;
  }
  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Insert point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
      endStepCallback: () => {
        this.basePoint = copyIPoint(this.lastPoint);
        const planeManager = this.graphicProcessor.getPlaneManager();
        planeManager.activePlane.position = this.basePoint;
        planeManager.activePlane.locked = true;
      }
    }, {
      infoMsg: "Insert side: ",
      stepMode: settingsOpModes.ONEBOX,
      currValue: () => (CubeOP.sampleData.side).toFixed(3),
      cmdLineListener: (cmd: string) => {
        const side = parseFloat(cmd);
        if (side) {
          CubeOP.sampleData.side = side;
          this.offSet.x = CubeOP.sampleData.side * 0.5;
          this.offSet.y = CubeOP.sampleData.side * 0.5;
          this.offSet.z = CubeOP.sampleData.side * 0.5;
          this.endOperation();
        }
      },
      endStepCallback: () => {
        this.endOperation();
      }
    }]);
  }

  protected createAux() {
    return createCube(CubeOP.sampleData.side);
  }
  protected createGeom() {
    return createBufferCube(CubeOP.sampleData.side);
  }

  public moveLastPoint(point: IPoint) {
    if (this.numPoints === 0) {
      this.basePoint = copyIPoint(point);
      const planeManager = this.graphicProcessor.getPlaneManager();
      this.rotation = planeManager.activePlane.rotation;
      this.recalculateObj(); 

    } else if (this.numPoints === 1) {
      CubeOP.sampleData.side = vectorDist3D(this.basePoint, point);
      this.offSet.x = CubeOP.sampleData.side * 0.5;
      this.offSet.y = CubeOP.sampleData.side * 0.5;
      this.offSet.z = CubeOP.sampleData.side * 0.5;

      const planeManager = this.graphicProcessor.getPlaneManager();
      const { x, y, z } = planeManager.activePlane.rotation;
      const p1 = planeManager.activePlane.getRelativePoint(point);
      const angle = lineAngle2p({ x: 0, y: 0, z: 0 }, p1);
      const slope = lineSlope2p({ x: 0, y: 0, z: 0 }, p1);
      const currRot = getXYZEulerFromEulerAngles(0, -slope, angle, "ZYX");
      this.rotation = { x: x + currRot.x, y: y + currRot.y, z: z + currRot.z };
      this.recalculateObj();
    }
  }

  public save(): void {
    if (this.graphicProcessor) {
      const command = new CubeCommand(
        CubeOP.sampleData.side,
        this.basePoint,
        this.rotation,
        this.offSet,
        { x: 1, y: 1, z: 1 },
        this.getCurrentSceneId(),
        this.graphicProcessor,
        getCurrentSolidMaterial()
      );
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
}
