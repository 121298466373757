import { ChamferLinesCommand } from "lib/commands/edition/chamfer";
import { linealPrecision } from "lib/general-settings";
import { isLinealGeometry } from "lib/models/checktools";
import { IObjData } from "lib/models/objdata";
import { dataInfoProperty } from "lib/properties/properties";
import * as THREE from "three";
import { setPosBuffer } from "../../geometries";
import { lineAuxCreate, lineCreateIPoints } from "../../geometries/line";
import { setColorMaterial } from "../../materials";
import { angleBetweenLines } from "../../math/angles";
import { getChamferInLine, getChamferInPolyLine, getChamfersBetweenLine, IChamferOptions } from "../../math/chamfer";
import { getBufferFromPolylineParam, IPolylineParam, linesAreAdjacent } from "../../math/line";
import { ISegment } from "../../math/types";
import { MultiEdition } from "../edition-multi";
import { cadOpType } from "../factory";
import { settingsOpModes, propSetting, IEdgeReference } from "../step-operations";
import { userMessageEvents } from '../../events/user-messages';

interface IChamferSettings { distance1: number, distance2: number, polylineBtn: undefined }
abstract class ChamferOp extends MultiEdition {

  protected resLine: THREE.Line;

  protected firstEdge: IEdgeReference;
  protected firstEdgeAux: THREE.Line;

  protected secondEdge: IEdgeReference;
  protected secondEdgeAux: THREE.Line;

  // Tiene en cuenta todos los vértices de una polilínea (true), o solo dos aristas a seleccionar (false).
  public polylineMode: boolean = false;
  public isSameLine: boolean = false;

  // Resultado nueva línea
  public infoResults: IPolylineParam | null;

  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: `Select first line.`,
      stepMode: settingsOpModes.SELECTEDGE,
      filterFun: (o: IObjData) => {
        if (!isLinealGeometry(o)) {
          userMessageEvents.dispatchError("Select a polyline or polygon");
          return false;
        }
        return true;
      },
      getEdgeCallback: (ref: IEdgeReference) => {
        if (ref) {
          if (ref.arc) {
            userMessageEvents.dispatchError("Arc selected, only accept segments");
            return;
          }
          this.firstEdge = ref;
          this.firstEdgeAux = lineCreateIPoints([ref.segment.p1, ref.segment.p2]);
          setColorMaterial(this.firstEdgeAux, "#00cc00");
          this.saveToTempScene(this.firstEdgeAux);
          this.setNextStep();
        }
      },
    }, {
      infoMsg: `Select second line.`,
      stepMode: settingsOpModes.SELECTEDGE,
      filterFun: (o: IObjData) => {
        if (!isLinealGeometry(o)) {
          userMessageEvents.dispatchError("Select a polyline or polygon");
          return false;
        }
        return true;
      },
      getEdgeCallback: (ref: IEdgeReference) => {
        if (ref) {
          if (ref.arc) {
            userMessageEvents.dispatchError("Arc selected, only accept segments");
            return;
          }
          this.isSameLine = ref.data === this.firstEdge.data;
          this.secondEdge = ref;
          if (!this.isSameLine && !linesAreAdjacent(this.secondEdge.data?.definition as IPolylineParam, this.firstEdge.data?.definition as IPolylineParam)) {
            userMessageEvents.dispatchError("Segments must be join in a vertex");
            return;
          }
          this.secondEdgeAux = lineCreateIPoints([ref.segment.p1, ref.segment.p2]);
          setColorMaterial(this.secondEdgeAux, "#00cc00");
          this.saveToTempScene(this.secondEdgeAux);
          // setStyleMaterial(this.secondEdge, "#0000ff");

          this.unRegisterRaycast();
          this.calculateChamfer();
          this.setNextStep(3);
        }
      },
    }, {
      infoMsg: `Select polyline.`,
      stepMode: settingsOpModes.SELECTOBJS,
      multiSelect: false,
      enableSelectMarks: true,
      filterFun: (o: IObjData) => {
        const res = isLinealGeometry(o);
        return res;
      },
      endStepCallback: this.unRegisterRaycast.bind(this),

    }, {
      infoMsg: "Insert chamfer",
      stepMode: settingsOpModes.WAITMODE,
      panelProp: this.setPanelProperties(),
      stepFun: this.endOperation.bind(this),
    }]);
  }
  protected abstract setPanelProperties(): propSetting<IChamferSettings>
  protected abstract calculateChamfer(): void;

  public async start() {
    this.iniSettingsOp();
    this.graphicProcessor.unselectAll();

    this.resLine = lineAuxCreate();
    setColorMaterial(this.resLine, "#00cc00");
    this.saveToTempScene(this.resLine);

    this.registerCancel();
    this.registerRaycast();
  }

  protected showSolution(): void {
    if (this.infoResults) {
      const pointsBuff = getBufferFromPolylineParam(this.infoResults);
      setPosBuffer(this.resLine, pointsBuff);
      this.resLine.visible = true;
      this.firstEdgeAux.visible = false;
      this.secondEdgeAux.visible = false;
    }
  }

  public endOperation(): void {
    if (this.infoResults) {
      this.save();
      super.endOperation();
    } else {
      this.cancelOperation();
    }
  }
}

export class StraightChamferOP extends ChamferOp {

  public opType = cadOpType.CHAMFER;

  // Chaflan definido por dos distancias (false) o por una distancia y un ángulo (true).
  public angleInput: boolean = false;

  public distance1: number = 1;
  public distance2: number = 1.5;
  public angle: number;

  protected setPanelProperties() {
    const panelProp: dataInfoProperty<IChamferSettings> = {
      distance1: {
        publicName: "Distance 1",
        value: this.distance1,
        type: "number",
        precision: linealPrecision,
        units: "m",
      },
      distance2: {
        publicName: "Distance 2",
        value: this.distance2,
        type: "number",
        precision: linealPrecision,
        units: "m",
      },
      polylineBtn: {
        publicName: "Apply to polyline",
        type: "button",
        buttonCb: () => {
          this.polylineMode = true;
          this.calculateChamfer();
        }
      },
    }
    return {
      propValue: panelProp,
      propCallback: this.updatePanel.bind(this),
    };
  }

  private updatePanel(newChamferSettings: IChamferSettings) {
    this.distance1 = newChamferSettings.distance1;
    this.distance2 = newChamferSettings.distance2;
    this.calculateChamfer();
  }

  public calculateChamfer(): void {

    const opts: IChamferOptions = {
      d1: this.distance1,
      d2: this.distance2,
      angle: this.angle,
      angleInput: this.angleInput,

      firstEdgeIndex: this.firstEdge.p1Index,
      secondEdgeIndex: this.secondEdge.p1Index,
    };

    this.infoResults = null;
    if (this.polylineMode) {
      this.infoResults = getChamferInPolyLine(this.firstEdge.data, opts);
      this.showSolution();
    } else {
      this.getDistanceAngle(this.firstEdge.segment, this.secondEdge.segment);
      if (this.isSameLine) {
        this.infoResults = getChamferInLine(this.firstEdge.data, opts);
      } else {
        this.infoResults = getChamfersBetweenLine(this.firstEdge.data, this.secondEdge.data, opts);
      }
      if (this.infoResults) {
        this.showSolution();
      } else {
        console.error("No se ha podido formar redondeo. Radio demasiado largo.");
      }
    }
  }

  private getDistanceAngle(segmentA: ISegment, segmentB: ISegment): void {
    const lineAStart = segmentA.p1;
    const lineAEnd = segmentA.p2;
    const lineBStart = segmentB.p1;
    const lineBEnd = segmentB.p2;

    const betha = angleBetweenLines(lineAStart, lineAEnd, lineBStart, lineBEnd);
    if (this.angleInput) {
      const gamma = Math.PI - betha - this.angle;
      this.distance2 = this.distance1 / Math.sin(gamma) * Math.sin(this.angle);
    } else {
      const b = Math.sqrt(this.distance1 * this.distance1 + this.distance2 * this.distance2 - 2 * this.distance1 * this.distance2 * Math.cos(betha));
      this.angle = Math.acos((b * b + this.distance1 * this.distance1 - this.distance2 * this.distance2) / (2 * b * this.distance1));
    }
  }

  public save() {
    if (this.infoResults) {
      const opts: IChamferOptions = {
        d1: this.distance1,
        d2: this.distance2,
        angle: this.angle,
        angleInput: this.angleInput,

        firstEdgeIndex: this.firstEdge.p1Index,
        secondEdgeIndex: this.secondEdge.p1Index,
      };
      const command = new ChamferLinesCommand([this.firstEdge.data!, this.secondEdge.data!], opts, this.polylineMode, this.getCurrentSceneId(), this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
}