import { cadOpType } from "../factory";
import { createBufferCylinder, createCylinder } from "../../geometries/solid/cylinder";
import { Parameter3dOP } from "../solids/parameter-3d";
import { IPoint } from "../../math/types";
import { vectorDist2D } from "../../math/distance";
import { copyIPoint } from "../../math/point";
import { CylinderCommand } from "../../commands/primitives/cylinder";
import { settingsOpModes } from "../step-operations";
import { getCurrentSolidMaterial } from "lib/materials";
import { addEulerAnglesToEulerAngles } from "lib/math/plane";

export class CylinderOP extends Parameter3dOP {
  public opType = cadOpType.CYLINDER;
  protected static sampleData = { radius: 1.5, height: 2 };

  constructor() {
    super();
    this.offSet.z = CylinderOP.sampleData.height * 0.5;
  }
  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Insert center: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
      endStepCallback: () => {
        this.basePoint = copyIPoint(this.lastPoint);
        const planeManager = this.graphicProcessor.getPlaneManager();
        planeManager.activePlane.position = this.basePoint;
        planeManager.activePlane.locked = true;
      }
    }, {
      infoMsg: "Insert radius: ",
      stepMode: settingsOpModes.ONEBOX,
      currValue: () => (CylinderOP.sampleData.radius).toFixed(3),
      cmdLineListener: (cmd: string) => {
        const r = parseFloat(cmd);
        if (r) {
          CylinderOP.sampleData.radius = r;
          this.setNextStep();
          this.numPoints++;
          this.recalculateObj();
        }
      },
      endStepCallback: () => {
        const planeManager = this.graphicProcessor.getPlaneManager();
        planeManager.activePlane.rotation = addEulerAnglesToEulerAngles({ x: Math.PI * 0.5, y: 0, z: 0 }, this.rotation);
      }
    }, {
      infoMsg: "Insert height: ",
      stepMode: settingsOpModes.ONEBOX,
      currValue: () => (CylinderOP.sampleData.height).toFixed(3),
      cmdLineListener: (cmd: string) => {
        const h = parseFloat(cmd);
        if (h) {
          CylinderOP.sampleData.height = h;
          this.endOperation();
        }
      },
      endStepCallback: this.endOperation.bind(this),
    }]);
  }

  protected createAux() {
    return createCylinder(
      CylinderOP.sampleData.radius,
      CylinderOP.sampleData.height
    );
  }
  protected createGeom() {
    return createBufferCylinder(
      CylinderOP.sampleData.radius,
      CylinderOP.sampleData.height
    );
  }

  public moveLastPoint(point: IPoint) {
    if (this.numPoints === 0) {
      this.basePoint = copyIPoint(point);
      const planeManager = this.graphicProcessor.getPlaneManager();
      this.rotation = planeManager.activePlane.rotation;
      this.recalculateObj();

    } else if (this.numPoints === 1) {
      const planeManager = this.graphicProcessor.getPlaneManager();
      const p1 = planeManager.activePlane.getRelativePoint(point);
      CylinderOP.sampleData.radius = vectorDist2D({ x: 0, y: 0, z: 0 }, p1);
      this.recalculateObj();

    } else if (this.numPoints === 2) {
      const planeManager = this.graphicProcessor.getPlaneManager();
      const p1 = planeManager.activePlane.getRelativePoint(point);
      CylinderOP.sampleData.height = p1.y;
      this.offSet.z = CylinderOP.sampleData.height * 0.5;
      this.recalculateObj();
    }
  }

  public save(): void {
    if (this.graphicProcessor) {
      this.offSet.z = CylinderOP.sampleData.height * 0.5;
      const command = new CylinderCommand(
        CylinderOP.sampleData.radius,
        CylinderOP.sampleData.height,
        this.basePoint,
        this.rotation,
        this.offSet,
        { x: 1, y: 1, z: 1 },
        this.getCurrentSceneId(),
        this.graphicProcessor,
        getCurrentSolidMaterial()
      );
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
}
