import { IObjData } from "lib/models/objdata";
import { cloneDataModel } from "lib/models/model-creator/datamodel-factory";
import { GraphicProcessor } from "../../graphic-processor";
import { IPoint } from "../../math/types";
import { cadOpType } from "../../operations/factory";
import { TransformCommand } from "./transform";

export class RotateCommand extends TransformCommand {
  protected opType = cadOpType.ROTATE;

  private basePoint: IPoint;
  private angleX: number;
  private angleY: number;
  private angleZ: number;

  constructor(basePoint: IPoint, angleX: number, angleY: number, angleZ: number, objDatas: IObjData[], withCopy: boolean, graphicProcessor: GraphicProcessor) {
    super(objDatas, graphicProcessor);
    this.basePoint = basePoint;
    this.angleX = angleX;
    this.angleY = angleY;
    this.angleZ = angleZ;
    this.withCopy = withCopy;
  }

  protected transformFun = (obj: IObjData) => {
    if (this.withCopy) {
      if (!this.createdObjs.has(obj)) {
        const data = cloneDataModel(obj);
        data.rotate(this.angleX, this.angleY, this.angleZ, this.basePoint);
        this.graphicProcessor.addToLayer(data, obj.layerId);
        this.createdObjs.set(obj, data);
      }
    } else {
      obj.rotate(this.angleX, this.angleY, this.angleZ, this.basePoint);
    }
  };

  protected unDoTransformFun = (obj: IObjData) => {
    if (this.withCopy) {
      const alreadyCopied = this.createdObjs.get(obj)!;
      this.graphicProcessor.removeFromLayer(alreadyCopied);
    } else {
      obj.rotate(-this.angleX, -this.angleY, -this.angleZ, this.basePoint);
    }
  };
}
