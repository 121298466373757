import { CmdStack } from "../helpers/arrays";
import { ICommand } from "./base";
import { UndoRedoAction, UndoRedoActionType } from "lib/events/undo-redo";
import { ObserverManager } from '../events/event-bus';

/** undoredo stack.
 *
 * @export
 * @class CommandInvoker
 * @extends {CmdStack<ICommand>}
 */
export class CommandInvoker extends CmdStack<ICommand> {

  private commandObserver: ObserverManager<UndoRedoAction> = new ObserverManager();

  /** Añade y ejecuta el comando
   *
   * @param {ICommand} cmd Comando undoRedo
   * @memberof CommandInvoker
   */
  storeAndExecute(cmd: ICommand) {
    this.store(cmd);
    cmd.execute();
    this.dispatchUndoRedoEvent();
  }
  /** Añade un comando. No lo ejecuta.
   *
   * @param {ICommand} cmd Comando undoRedo
   * @memberof CommandInvoker
   */
  store(cmd: ICommand): void {
    if (this.currCmdIndex < this.commands.length) {
      // Avisar a los comandos de que ya no van a hacer falta
      for (let i = this.currCmdIndex; i < this.commands.length; i++) {
        this.commands[i].delete();
      }
    }
    super.store(cmd);
    this.dispatchUndoRedoEvent();
  }
  /** Deshacer
   *
   * @param {number} step Número de comandos a deshacer
   * @param {boolean} [noMessage] Mensaje debug.
   * @memberof CommandInvoker
   */
  undo(step: number): void {
    for (const command of this.iter(step, true)) {
      command.unExecute();
    }
    this.dispatchUndoRedoEvent();
  }
  /** Rehacer
   *
   * @param {number} step Número de comandos a reshacer
   * @param {boolean} [noMessage] Mensaje debug.
   * @memberof CommandInvoker
   */
  redo(step: number) {
    for (const command of this.iter(step, false)) {
      command.execute();
    }
    this.dispatchUndoRedoEvent();
  }
  /** Vacia la pila de comandos
   *
   * @memberof CommandInvoker
   */
  clear(): void {
    for (const command of this.commands) {
      command.delete();
    }
    super.clear();
    this.dispatchUndoRedoEvent();
  }

  private *iter(step: number, isUndo?: boolean) {
    if (isUndo) {
      for (let i: number = 0; i < step; i++) {
        if (this.currCmdIndex > 0) {
          this.currCmdIndex--;
          yield this.commands[this.currCmdIndex];
        }
      }
    } else {
      for (let i: number = 0; i < step; i++) {
        if (this.currCmdIndex < this.commands.length) {
          yield this.commands[this.currCmdIndex];
          this.currCmdIndex++;
        }
      }
    }
  }

  // ***********************************************************

  subscribe(listener: (action: UndoRedoAction) => void) {
    this.commandObserver.subscribe(listener);
  }
  unsubscribe(listener: (action: UndoRedoAction) => void) {
    this.commandObserver.unsubscribe(listener);
  }
  private dispatchUndoRedoEvent() {
    this.commandObserver.dispatch({
      type: UndoRedoActionType.SET_COMMANDS,
      payload: {
        commands: this.commands,
        currentCommandIndex: this.currCmdIndex,
      },
    });
  }
}
