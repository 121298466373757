import { materialCache } from "lib/materials";
import { ISolidMaterial } from "lib/materials/solid";
import { mirrorRotation, normalizeAngle } from "lib/math/angles";
import { mirrorPoint } from "lib/math/mirror";
import { addEulerAnglesToEulerAngles } from "lib/math/plane";
import { addIpoint, copyIPoint } from "lib/math/point";
import { rotatePoint } from "lib/math/rotate";
import { scalePoint } from "lib/math/scale";
import { IPoint } from "lib/math/types";
import { ObjData } from "../objdata";
import { solidDefinition } from "../types";

export interface solidParam {
  basePoint: IPoint,
  rotation: IPoint,
  scale: IPoint,
  offset: IPoint,
}

export abstract class SolidData extends ObjData {

  public definition: solidDefinition;
  public material: ISolidMaterial;
  public graphicObj: THREE.Mesh;

  public abstract regenerateDefinition(): void;

  protected cloneSolidDefinition(): solidParam {
    return {
      basePoint: copyIPoint(this.definition.basePoint),
      rotation: copyIPoint(this.definition.rotation),
      offset: copyIPoint(this.definition.offset),
      scale: copyIPoint(this.definition.scale),
    };
  }

  public override regenerateObjectFromDefinition(): void {
    // Each solid, regenerate its own definition
    this.regenerateDefinition();
    this.regenerateDependences();
  }
  public override regenerateObjectFromMaterial() {
    const threeMaterial = materialCache.getSolidMaterial(this.material);
    this.graphicObj.material = threeMaterial;
    if (this.graphicObj.children.length) {
      this.graphicObj.children.forEach(o => (o as THREE.Mesh).material = threeMaterial)
    }
  }

  public getBasePoint(): IPoint {
    return this.definition.basePoint;
  }

  public override translate(distance: IPoint) {
    this.definition.basePoint = addIpoint(this.definition.basePoint, distance);
    this.regenerateObjectFromDefinition();
  }
  public override rotate(angleX: number, angleY: number, angleZ: number, basePoint: IPoint): void {
    angleX = normalizeAngle(angleX);
    angleY = normalizeAngle(angleY);
    angleZ = normalizeAngle(angleZ);
    this.definition.basePoint = rotatePoint(this.definition.basePoint, angleX, angleY, angleZ, basePoint);
    const addRotation = { x: angleX, y: angleY, z: angleZ };
    this.definition.rotation = addEulerAnglesToEulerAngles(this.definition.rotation, addRotation);
    this.regenerateObjectFromDefinition();
  }
  public override scale(factorX: number, factorY: number, factorZ: number, basePoint: IPoint): void {
    this.definition.basePoint = scalePoint(this.definition.basePoint, factorX, factorY, factorZ, basePoint);
    this.definition.offset.x *= factorX;
    this.definition.offset.y *= factorX;
    this.definition.offset.z *= factorX;
    this.regenerateObjectFromDefinition();
  }
  public override mirror(startPoint: IPoint, endPoint: IPoint): void {
    this.definition.basePoint = mirrorPoint(this.definition.basePoint, startPoint, endPoint);
    this.definition.rotation = mirrorRotation(this.definition.rotation, startPoint, endPoint);
    this.definition.offset.y *= -1;
    this.regenerateObjectFromDefinition();
  }
  public delete(): void {
    this.graphicObj.geometry.dispose();
    super.delete();
  }
}
