import { ISolidMaterial } from "lib/materials";
import { objDataType } from "lib/models/types";
import { CubeData } from "lib/models/solids/cube";
import { cubeParam } from "../../geometries/solid/cube";
import { GraphicProcessor } from "../../graphic-processor";
import { IPoint } from "../../math/types";
import { cadOpType } from "../../operations/factory";
import { createCommand } from "./create-base";

export class CubeCommand extends createCommand {

  public createdData: CubeData;
  protected opType = cadOpType.CUBE;
  protected objType = objDataType.CUBE;
  protected param: cubeParam;
  protected material: ISolidMaterial;

  constructor(
    side: number,
    basePoint: IPoint,
    rotation: IPoint,
    offset: IPoint,
    scale: IPoint,
    layerId: string,
    graphicProc: GraphicProcessor,
    material: ISolidMaterial
  ) {
    super(layerId, graphicProc);
    this.param = {
      side,
      basePoint,
      rotation,
      offset,
      scale,
    };
    this.material = material;
  }
}