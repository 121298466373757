import { CircleEditDataCommand } from "lib/commands/edition/circle";

import { linealPrecision } from "lib/general-settings";
import { circleParam } from "lib/geometries/circle";
import { ILineMaterial } from "lib/materials";
import { CircleData } from "lib/models/primitives/circle";
import { DataDefinitionHandler } from "../base";

type circleProp = circleParam & {
  diameter: number;
  circumference: number;
  area: number;
};

export class CircleDataDefinitionHandler extends DataDefinitionHandler<circleProp, ILineMaterial, undefined, undefined> {

  protected data: CircleData;

  protected buildInfoProperties() {
    const def = this.data.definition;
    this.definitionInfo = {
      center: this.getPointView(def.center, "Center", "m", linealPrecision),
      radius: this.getNumberView(def.radius, "Radius", "m", linealPrecision, undefined, (radius: number) => radius > 0),
      azimutO: this.getAngleView(def.azimutO, "Origin"),
      plane: this.getPointAngleView(def.plane, "Rotation"),
      diameter: this.getNumberView(def.radius * 2, "Diameter", "m", linealPrecision, false),
      circumference: this.getNumberView(2 * Math.PI * def.radius, "Circumference", "m", linealPrecision, false),
      area: this.getNumberView(Math.PI * def.radius * def.radius, "Area", "m²", linealPrecision, false),
    };
    this.materialInfo = this.getLineMaterialView(this.data.material);
  }

  private checkNewDefinition(newDefinition: circleProp): boolean {
    if (!newDefinition) { return false; }
    if (!this.checkPoint(newDefinition.center)) { return false; }
    if (!this.checkNumber(newDefinition.radius)) { return false; }
    if (!this.checkAngle(newDefinition.azimutO)) { return false; }
    if (!this.checkPoint(newDefinition.plane)) { return false; }
    return true;
  }

  private changedNewDefinition(oldDefinition: circleParam, newDefinition: circleProp): circleParam | null {
    let def = oldDefinition;
    let changed: boolean = false;
    if (newDefinition) {
      const center = this.changedPoint(def.center, newDefinition.center);
      if (center !== null) {
        def.center = center;
        changed = true;
      }
      const radius = this.changedNumber(def.radius, newDefinition.radius);
      if (radius !== null) {
        def.radius = radius;
        changed = true;
      }
      const azimutO = this.changedAngle(def.azimutO, newDefinition.azimutO);
      if (azimutO !== null) {
        def.azimutO = azimutO;
        changed = true;
      }
      const plane = this.changedPointRotation(def.plane, newDefinition.plane);
      if (plane !== null) {
        def.plane = plane;
        changed = true;
      }
    }
    return changed ? def : null;
  }

  private checkAndChangedDefinition(oldDefinition: circleParam, newDefinition: circleProp): circleParam | null {
    let def = null;
    if (this.checkNewDefinition(newDefinition)) {
      def = this.changedNewDefinition(oldDefinition, newDefinition);
    }
    return def;
  }

  public saveAndRegenerate = (newDefinition: circleProp) => {
    const circleDefinition = this.checkAndChangedDefinition(this.data.cloneDefinition(), newDefinition);
    if (circleDefinition) {
      const command = new CircleEditDataCommand(this.data, circleDefinition, null, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  };
  public saveAndRegenerateMaterial = (newMaterial: ILineMaterial) => {
    const circleMaterial = this.checkAndChangedLineMaterial(this.data.material, newMaterial);
    if (circleMaterial) {
      const command = new CircleEditDataCommand(this.data, null, circleMaterial, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  };
}