import CubeIcon from "../../icons/cube";
import PolygonalPrismIcon from "../../icons/pprism";
import RectangularPrismIcon from "../../icons/rprism";
import ConeIcon from "../../icons/cone";
import RegionIcon from "../../icons/region";
import CylinderIcon from "../../icons/cylinder";
import { useOperation } from "../../operations/context";
import ToolbarButton from "../toolbar-button";
import ToolbarSection from "../toolbar-section";
import PlaneIcon from "../../icons/plane";

const ModelingSection = () => {
  const {
    createCone,
    createCube,
    createCylinder,
    createPPrism,
    createRPrism,
    createPlane,
    createRegion,
    createBlock,
    createBlockRef,
  } = useOperation();
  return (
    <ToolbarSection title="Modeling" className={"border-b border-gray-600"}>
      <ToolbarSection.Row>
        <ToolbarButton onClick={createPlane} tooltip="Plane">
          <PlaneIcon />
        </ToolbarButton>
        <ToolbarButton onClick={createRegion} tooltip="Region">
          <RegionIcon />
        </ToolbarButton>
        <ToolbarButton onClick={createBlock} tooltip="Block">
          <RegionIcon />
        </ToolbarButton>
        <ToolbarButton onClick={createBlockRef} tooltip="BlocRef">
          <RegionIcon />
        </ToolbarButton>
        <ToolbarButton onClick={createCube} tooltip="Cube">
          <CubeIcon />
        </ToolbarButton>
        <ToolbarButton onClick={createPPrism} tooltip="Polygonal Prism">
          <PolygonalPrismIcon />
        </ToolbarButton>
        <ToolbarButton onClick={createRPrism} tooltip="Rectangular Prism">
          <RectangularPrismIcon />
        </ToolbarButton>
        <ToolbarButton onClick={createCylinder} tooltip="Cylinder">
          <CylinderIcon />
        </ToolbarButton>
        <ToolbarButton onClick={createCone} tooltip="Cone">
          <ConeIcon />
        </ToolbarButton>
      </ToolbarSection.Row>
    </ToolbarSection>
  );
};

export default ModelingSection;
