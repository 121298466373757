import { useParams } from "react-router-dom";
import ViewportSidebar from "modules/cad/components/sidebar/viewport-sidebar";
import LoadingDots from "shared/components/ui/loading-dots";
import { useGetStrucProjectFiles } from "../components/project/hook/use-project";
import ProjectToolbar from "modules/cad/components/toolbar/toolbar-project";
import Toolbar from "modules/cad/components/toolbar";
import HorizontalResizer from "shared/components/ui/resizer/horizontal-resizer";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useMainGraphicContext } from "modules/cad/components/viewport/context";
import { initializeResources, unloadResources } from "../../../lib/resources";
import Viewport from "../components/viewport-mode/viewport";
import { userMessageEvents } from "lib/events/user-messages";
import { useHistory } from "react-router";
import { EventBus } from "lib/events/event-bus";
import { MeshProjectStatusMPValueEnum } from "lib/apis/mesh-projects/api";
import { useUI } from "shared/components/ui/context";
import { AnalysisProjectStatusMPValueEnum } from "lib/apis/analysis-projects/api";
import { setStruVERSION } from "../models/project";
import { useFavicon } from "shared/utils/hooks/use-favicon";
import { useAuth } from "shared/components/auth/context";
import { useDownloadResources } from "../components/project/hook/use-resources-project";
import { analysisManager } from "lib/models-struc/analysis/analysismodel-manager";
import { hypothesisManager } from "lib/models-struc/hypothesis/hypothesismodel-manager";
import { meshManager } from "lib/models-struc/mesh/meshmodel-manager";
import { getStrucProject, loadProject } from "../../../lib/models-struc/application/load-project";

const StrucProject = () => {

  const fv = useFavicon();
  const { user } = useAuth();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { openModal } = useUI();

  const graphic = useMainGraphicContext();

  const responseProject = useGetStrucProjectFiles(user!.id, id);
  const resources = useDownloadResources(responseProject.project?.DataFiles!);

  const [resourcesLoaded, setResourcesLoaded] = useState<boolean>(false);

  useEffect(() => {
    initializeResources();
    return () => {
      unloadResources();
    }
  }, [])

  useEffect(() => {
    const loadData = async () => {
      try {
        const project = responseProject.project!;
        setStruVERSION(project.DataModelVersion);
        await loadProject(resources, graphic, meshManager, hypothesisManager, analysisManager, project);
        setResourcesLoaded(true);

        meshManager.getMeshingStatus().then((status) => {
          if (status === MeshProjectStatusMPValueEnum.Running) {
            fv.setRunning();
            openModal("MESHING");
          }
        });
        analysisManager.getAnalyzingStatus().then((status) => {
          if (status === AnalysisProjectStatusMPValueEnum.Running) {
            openModal("ANALYZING");
            fv.setRunning();
          }
        });
      } catch (err) {
        console.error(err);
        EventBus.enableDispatch = true;
        userMessageEvents.dispatchError("Error loading project")
        history.push("/struc/projects");
      };
    }
    if (!resourcesLoaded && !responseProject.isLoading && !resources.isLoading) {
      loadData();
    }
  }, [responseProject.isLoading, resources.isLoading])


  if (responseProject.error) {
    return (
      <div className="h-screen bg-gray-800 flex flex-col items-center justify-center">
        <LoadingDots />
      </div>
    );
  }

  if (!resourcesLoaded || responseProject.isLoading || resources.isLoading) {
    return (
      <div className="h-screen bg-gray-800 flex flex-col items-center justify-center">
        <LoadingDots />
      </div>
    );
  }

  return (
    <MainWrapper className="-inset-0">
      <ProjectToolbar />
      <Toolbar />
      <HorizontalResizer
        id={"main"}
        leftClassName={"w-min"}
        leftChild={<ViewportSidebar />}
        rightClassName={"w-full"}
        rightChild={<Viewport project={getStrucProject(responseProject.project!, resources.Sites!, resources.Content!, resources.Hypothesis!)} />}
      />
    </MainWrapper>
  );
};

const MainWrapper = styled.section`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto 1fr;
`;

export default StrucProject;
