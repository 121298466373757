import { GraphicProcessor } from "lib/graphic-processor";
import { hypothesisDispatcher } from "lib/models-struc/hypothesis/dispatcher";
import { Hypothesis, isSSEHypothesis, isWindHypothesis } from "lib/models-struc/hypothesis/hypothesis";
import { hypothesisManager } from "lib/models-struc/hypothesis/hypothesismodel-manager";
import { SSEhypothesisManager } from "lib/models-struc/hypothesis/sse";
import { windhypothesisManager } from "lib/models-struc/hypothesis/wind";

export class HypothesisImport {

  import(hypos: Hypothesis[]) {
    for (let hypo of hypos) {
      if (isWindHypothesis(hypo)) {
        windhypothesisManager.addHypothesis(hypo);
      } else if (isSSEHypothesis(hypo)) {
        SSEhypothesisManager.addHypothesis(hypo);
      } else {
        hypothesisManager.addHypothesis(hypo);
      }
    }
    hypothesisDispatcher.dispatchLoad();
  }
  importLoadsToHypothesis(graphicProc: GraphicProcessor) {
    const strucMng = graphicProc.getStructuralModelManager();
    const loads = strucMng.getLoadElements();
    for (const load of loads) {
      const hypoId = load.definition.hypothesisId;
      const hypo = hypothesisManager.getHypothesisByid(hypoId);
      if (hypo) {
        hypothesisManager.addLoad(load);
        continue;
      }
      const windHypo = windhypothesisManager.getWindHypothesisById(hypoId);
      if (windHypo) {
        windhypothesisManager.addHypothesisLoad(windHypo, load);
        continue;
      }
    }
  }
}

export class HypothesisExport {
  export() {
    const hypothesis = hypothesisManager.getAllHypothesis();
    const windHypothesis = windhypothesisManager.getWindHypothesis();
    const sseHypothesis = SSEhypothesisManager.getActiveSSE();
    return [...hypothesis, ...windHypothesis, ...sseHypothesis];
  }
}