import { PrimaryButton } from "shared/components/ui/button";
import styled from "styled-components";
import LoadingDots from "shared/components/ui/loading-dots";
import { MeshProjectStatusMPValueEnum } from "lib/apis/mesh-projects/api";
import { waffleGeomGenerator } from "lib/models-struc/waffle/waffle-geometry";
import { useMainGraphicContext } from "modules/cad/components/viewport/context";
import { useTimer } from "shared/components/ui/hooks/use-timer";
import { useEffect, useState } from "react";
import { useFavicon } from "shared/utils/hooks/use-favicon";

interface eventStatus {
  name: string;
  date: Date;
}

interface Props {
  onClose: () => void;
}

const CalculateWaffle = ({ onClose }: Props) => {

  const graphicProc = useMainGraphicContext();
  const { seconds, setIsActive } = useTimer();

  const [status, setStatus] = useState(MeshProjectStatusMPValueEnum.Running);
  const [error, setError] = useState<string | undefined>(undefined);
  const [lastEvents, setLastEvents] = useState<eventStatus[]>([]);

  const { setDefault, setRunning } = useFavicon();

  useEffect(() => {
    const initMeshing = async () => {
      setIsActive(true);
      setRunning();
      setLastEvents([{ name: "Calculating waffles", date: new Date() }]);
      const resWaffles = await waffleGeomGenerator.waffleReGenerate([], graphicProc);
      if (resWaffles.length === 0) throw new Error("Thre is not waffles defined")
      return resWaffles;
    }
    initMeshing().then((resWaffles) => {
      setLastEvents(prev => [...prev, { name: "Saving waffles", date: new Date() }]);
      const strMng = graphicProc.getStructuralModelManager();
      for (const waffle of resWaffles) {
        const slab = strMng.getSlabFromName(waffle.Name)!;
        if (slab.waffleGraphicObj) {
          slab.layerObj.threeObject.remove(slab.waffleGraphicObj);
          slab.waffleGraphicObj = undefined!;
        }
        slab.showHideWaffle();
      }
      setLastEvents(prev => [...prev, { name: "Saved waffles", date: new Date() }]);
      setStatus(MeshProjectStatusMPValueEnum.Done);

    }).catch((err) => {
      console.error(err);
      setStatus(MeshProjectStatusMPValueEnum.Error);
      setLastEvents(prev => [...prev, { name: err.message, date: new Date() }]);
      //setError(err.toJSON ? err.toJSON().message : err.message);

    }).finally(() => {
      setIsActive(false);
      setDefault();
    });
  }, []);

  return (
    <Wrapper className="text-xs">
      <Header>
        <h1>Calculate waffles slabs</h1>
      </Header>
      <Content>
        <Box>
          <div className="flex">
            <h1>{status} </h1>
            <div className="flex-grow flex justify-center">
              {status === MeshProjectStatusMPValueEnum.Running && <LoadingDots />}
              {status === MeshProjectStatusMPValueEnum.Error && <h1>{error}</h1>}
            </div>
            <h1>{Math.floor(seconds / 60) + ' min : '}{(seconds % 60) < 10 ? `0${(seconds % 60)} sg` : (seconds % 60) + ' sg'}</h1>
          </div>
          <h1>Events:</h1>
          <div>{lastEvents.map((e, i) => (
            <li key={e.name + i}>
              {e.name} - {e.date.toLocaleString()}
            </li>
          ))}</div>
        </Box>
        <Box className="flex justify-center gap-2">
          <PrimaryButton disabled={status === MeshProjectStatusMPValueEnum.Running} onClick={() => {
            onClose();
          }}>
            Accept
          </PrimaryButton>
        </Box>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
        overflow: hidden;
        border-radius: 4px;
        width: 300px;
        margin: -32px;
        background-color: var(--color-gray-300);
        `;
const Header = styled.div`
        background-color: var(--color-gray-300);
        border-bottom: 2px solid var(--color-primary);
        padding: 6px;
        display: flex;
        justify-content: space-between;
        `;
const Content = styled.div`
        display: grid;
        gap: 4px;
        padding: 6px;
        `;
const Box = styled.div`
        background-color: var(--color-gray-200);
        padding: 8px;
        border-radius: 4px;
        overflow: hidden;
        `;
export default CalculateWaffle;