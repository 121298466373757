import { angularPrecision, getUserAngleUnitSufix, linealPrecision, radAngleToUser } from "lib/general-settings";
import { dataInfoProperty } from "lib/properties/properties";
import { ShellCrossSection } from "./shellcs-shapes";

export enum ShelCSSType { FLAT, WALL, WAFFLE, SANDWICH } //FOAMWAFFLE, CONCRETEWAFFLE, EMPTYWAFFLE }
export const ShelCSSTypeNames: string[] = [];
ShelCSSTypeNames[ShelCSSType.FLAT] = "Flat";
ShelCSSTypeNames[ShelCSSType.WALL] = "Wall";
ShelCSSTypeNames[ShelCSSType.WAFFLE] = "Waffle";
ShelCSSTypeNames[ShelCSSType.SANDWICH] = "Sandwich";
// slabTypeNames[slabType.FOAMWAFFLE] = "Foam waffle";
// slabTypeNames[slabType.CONCRETEWAFFLE] = "Concrete waffle";
// slabTypeNames[slabType.EMPTYWAFFLE] = "Empty waffle";

export interface IShellCrossSection {
  name: string;
  thickness: number;
  parameters: shellCrossSectionParam;
}
interface paramSection {
  shellSectionType: ShelCSSType,
}
export interface flatShellSection extends paramSection {
  shellSectionType: ShelCSSType.FLAT;
}
export interface wallShellSection extends paramSection {
  shellSectionType: ShelCSSType.WALL;
}
export interface waffleCrossSection extends paramSection, waffleParameters {
  shellSectionType: ShelCSSType.WAFFLE,
}
interface waffleParameters {
  centerWaffleX: number;
  centerWaffleY: number;
  compressionLayerDepth: number;
  dropPanelRatio: number;
  innerNonstBeamWidth: number; // default value -> depth
  minDropPanelEdge: number;
  numberOfBlocks: number;
  outerNonstBeamWidth: number; // default value -> depth
  ribWidth: number;
  rotationAngle: number;
  xInteraxis: number;
  yInteraxis: number;
}
export interface sandwichCrossSection extends paramSection, sandwichParameters {
  shellSectionType: ShelCSSType.SANDWICH,
}
interface sandwichParameters extends waffleParameters {
  lowerCompressionLayerDepth: number;
}

export type shellCrossSectionParam = flatShellSection | wallShellSection | waffleCrossSection | sandwichCrossSection;

// interface waffleSlabParam extends paramSection {
//   shellSectionType: slabType.CONCRETEWAFFLE | slabType.EMPTYWAFFLE;
//   compressionLayer: number;
//   ribWidth: number;
//   ribSpacing: number;
//   dropPaneldepth: number;
// }
// interface foamWaffleSlabParam extends paramSection {
//   shellSectionType: slabType.FOAMWAFFLE;
//   xInteraxis: number;
//   yInteraxis: number;
//   ribWidth: number;
//   numberOfBlocks: number;
//   outerNonstBeamWidth: number; // default value -> depth
//   innerNonstBeamWidth: number; // default value -> depth
//   minDropPanelEdge: number;
//   rotationAngle: number;
//   centerWaffleX: number;
//   centerWaffleY: number;
//   dropPanelRatio: number;
//   compressionLayerDepth: number;
// }

export function isFlatCSSParams(params: shellCrossSectionParam): params is flatShellSection {
  return params.shellSectionType === ShelCSSType.FLAT;
}
export function isWallCSSParams(params: shellCrossSectionParam): params is wallShellSection {
  return params.shellSectionType === ShelCSSType.WALL;
}
export function isWaffleCSSParams(params: shellCrossSectionParam): params is waffleCrossSection {
  return params.shellSectionType === ShelCSSType.WAFFLE;
}
export function isSandwichCSSParams(params: shellCrossSectionParam): params is sandwichCrossSection {
  return params.shellSectionType === ShelCSSType.SANDWICH;
}


export const getShellCrossSectionInfoprop = (prop: ShellCrossSection) => {
  if (prop) {
    const editable = !prop.default;
    const info: dataInfoProperty<typeof prop> = {}
    info.name = { type: "string", publicName: "Name", value: prop.name, editable };
    info.thickness = { type: "number", value: prop.thickness, publicName: "Depth", units: "m", precision: linealPrecision, editable };
    const param = prop.parameters;
    info.parameters = { publicName: "" };
    info.parameters.shellSectionType = { type: "list", value: prop.parameters.shellSectionType, publicName: "Shell section type", list: ShelCSSTypeNames, editable: false };
    if (isWaffleCSSParams(param)) {
      info.parameters = {
        xInteraxis: { type: "number", value: param.xInteraxis, publicName: "X interaxis", units: "m", precision: linealPrecision, editable },
        yInteraxis: { type: "number", value: param.yInteraxis, publicName: "Y interaxis", units: "m", precision: linealPrecision, editable },
        ribWidth: { type: "number", value: param.ribWidth, publicName: "Rib width", units: "m", precision: linealPrecision, editable },
        numberOfBlocks: { type: "number", value: param.numberOfBlocks, publicName: "Number of blocks", precision: 0, editable },
        outerNonstBeamWidth: { type: "number", value: param.outerNonstBeamWidth, publicName: "Outer nonst beam width", units: "m", precision: linealPrecision, editable },
        innerNonstBeamWidth: { type: "number", value: param.innerNonstBeamWidth, publicName: "Inner nonst beam width", units: "m", precision: linealPrecision, editable },
        minDropPanelEdge: { type: "number", value: param.minDropPanelEdge, publicName: "Min drop panel edge", units: "m", precision: linealPrecision, editable },
        rotationAngle: { type: "number", value: radAngleToUser(param.rotationAngle), publicName: "Rotation angle", units: getUserAngleUnitSufix(), precision: angularPrecision, editable },
        centerWaffleX: { type: "number", value: param.centerWaffleX, publicName: "Center waffle X", units: "m", precision: linealPrecision, editable },
        centerWaffleY: { type: "number", value: param.centerWaffleY, publicName: "Center waffle Y", units: "m", precision: linealPrecision, editable },
        dropPanelRatio: { type: "number", value: param.dropPanelRatio, publicName: "Drop panel ratio", units: "m", precision: linealPrecision, editable },
        compressionLayerDepth: { type: "number", value: param.compressionLayerDepth, publicName: "Compression layer depth", units: "m", precision: linealPrecision, editable },
      } as any

    } else if (isSandwichCSSParams(param)) {
      info.parameters = {
        xInteraxis: { type: "number", value: param.xInteraxis, publicName: "X interaxis", units: "m", precision: linealPrecision, editable },
        yInteraxis: { type: "number", value: param.yInteraxis, publicName: "Y interaxis", units: "m", precision: linealPrecision, editable },
        ribWidth: { type: "number", value: param.ribWidth, publicName: "Rib width", units: "m", precision: linealPrecision, editable },
        numberOfBlocks: { type: "number", value: param.numberOfBlocks, publicName: "Number of blocks", precision: 0, editable },
        outerNonstBeamWidth: { type: "number", value: param.outerNonstBeamWidth, publicName: "Outer nonst beam width", units: "m", precision: linealPrecision, editable },
        innerNonstBeamWidth: { type: "number", value: param.innerNonstBeamWidth, publicName: "Inner nonst beam width", units: "m", precision: linealPrecision, editable },
        minDropPanelEdge: { type: "number", value: param.minDropPanelEdge, publicName: "Min drop panel edge", units: "m", precision: linealPrecision, editable },
        rotationAngle: { type: "number", value: radAngleToUser(param.rotationAngle), publicName: "Rotation angle", units: getUserAngleUnitSufix(), precision: angularPrecision, editable },
        centerWaffleX: { type: "number", value: param.centerWaffleX, publicName: "Center waffle X", units: "m", precision: linealPrecision, editable },
        centerWaffleY: { type: "number", value: param.centerWaffleY, publicName: "Center waffle Y", units: "m", precision: linealPrecision, editable },
        dropPanelRatio: { type: "number", value: param.dropPanelRatio, publicName: "Drop panel ratio", units: "m", precision: linealPrecision, editable },
        compressionLayerDepth: { type: "number", value: param.compressionLayerDepth, publicName: "Compression layer depth", units: "m", precision: linealPrecision, editable },
        lowerCompressionLayerDepth: { type: "number", value: param.lowerCompressionLayerDepth, publicName: "Lower compression layer depth", units: "m", precision: linealPrecision, editable },
      } as any;
    }
    return info;
  } else {
    return {}
  }
};

export function getDefaultShellCrossSectionShape(type: ShelCSSType): shellCrossSectionParam {
  switch (type) {
    case ShelCSSType.FLAT:
      return {
        shellSectionType: type,
      }
    case ShelCSSType.WALL:
      return {
        shellSectionType: type,
      }
    case ShelCSSType.WAFFLE:
      return {
        shellSectionType: type,
        xInteraxis: 0.82,
        yInteraxis: 0.82,
        ribWidth: 0.12,
        numberOfBlocks: 6,
        outerNonstBeamWidth: 0.3,
        innerNonstBeamWidth: 0.3,
        minDropPanelEdge: 0,
        rotationAngle: 0,
        centerWaffleX: 0,
        centerWaffleY: 0,
        dropPanelRatio: 0.17,
        compressionLayerDepth: 0.05,
      }

    case ShelCSSType.SANDWICH:
      return {
        shellSectionType: type,
        xInteraxis: 0.82,
        yInteraxis: 0.82,
        ribWidth: 0.12,
        numberOfBlocks: 6,
        outerNonstBeamWidth: 0.3,
        innerNonstBeamWidth: 0.3,
        minDropPanelEdge: 0,
        rotationAngle: 0,
        centerWaffleX: 0,
        centerWaffleY: 0,
        dropPanelRatio: 0.17,
        compressionLayerDepth: 0.05,
        lowerCompressionLayerDepth: 0.05,
      }
  }

}