import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "shared/components/auth/context";
import { useUI } from "shared/components/ui/context";
import { useOperation } from "../../operations/context";
import { SAVE_FILE } from "modules/cad/services/projects";
import { GraphicProcessor } from '../../../../../lib/graphic-processor';
import { ModelDataExporter } from "lib/input-output/database/data-model-export";

interface Params {
  id: string;
}

export default function useSaveProject(graphicProc: GraphicProcessor) {

  const params = useParams<Params>();
  const { id } = params;
  const { user } = useAuth();
  const { openToast } = useUI();
  const { cleanChanges } = useOperation();
  const [mutate] = useMutation(SAVE_FILE);

  const saveProject = useCallback(async () => {
    if (user && id) {
      const exporter = new ModelDataExporter();
      const content = exporter.saveProjectCAD(graphicProc);
      const planes = graphicProc.getPlaneManager().exportToJSON();
      const cameraCfg = graphicProc.mainCameraCfgExportToJSON();
      const snapshot = await graphicProc.takeSnapshot();
      mutate({
        variables: {
          id,
          content,
          snapshot,
          planes,
          cameraCfg,
        },
      }).then(() => {
        cleanChanges();
        openToast({
          title: "Proyecto actualizado correctamente.",
          status: "success",
        });
      });
    }
  }, [user, id, graphicProc, mutate, cleanChanges, openToast]);

  return saveProject;
}
