import { GraphicProcessor } from "lib/graphic-processor";
import { isEqual } from "lib/math/epsilon";
import { settingsOpModes } from "lib/operations/step-operations";
import { FormEvent, useRef } from "react";
import { useOpSettings } from "../steps/hooks/use-op-settings";
import PositionInput from "./position-input";
import { useCoordinatePositionType } from './hooks/use-position';

interface Props {
  graphicProc: GraphicProcessor;
  usePosition: useCoordinatePositionType;
}

const PositionToolbar = ({ graphicProc, usePosition }: Props) => {

  const { stateCoord, setStateCoord, dispatchSetCoordinates } = usePosition;
  const { opSettings } = useOpSettings(graphicProc);

  const refX = useRef<HTMLInputElement>(null);
  const refY = useRef<HTMLInputElement>(null);
  const refZ = useRef<HTMLInputElement>(null);

  const handleSubmitPosition = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let x = parseFloat((refX.current as HTMLInputElement).value);
    let y = parseFloat((refY.current as HTMLInputElement).value);
    let z = parseFloat((refZ.current as HTMLInputElement).value);
    setStateCoord({
      xCoord: x, yCoord: y, zCoord: z,
      xLocked: !isEqual(x, stateCoord.xCoord, 0.001) ? true : stateCoord.xLocked,
      yLocked: !isEqual(y, stateCoord.yCoord, 0.001) ? true : stateCoord.yLocked,
      zLocked: !isEqual(z, stateCoord.zCoord, 0.001) ? true : stateCoord.zLocked,
    })
    dispatchSetCoordinates();
  }
  const unlockXCoord = () => { setStateCoord({ ...stateCoord, xLocked: false }) };
  const unlockYCoord = () => { setStateCoord({ ...stateCoord, yLocked: false }) };
  const unlockZCoord = () => { setStateCoord({ ...stateCoord, zLocked: false }) };

  return (
    <form onSubmit={handleSubmitPosition}>
      <div className="flex justify-center items-center space-x-2">
        <PositionInput
          editable={opSettings.currentStep?.stepMode === settingsOpModes.DEFAULTXYZ}
          reference={refX}
          value={stateCoord.xCoord.toFixed(3)}
          label="X"
          name="x"
          isPositionLocked={stateCoord.xLocked}
          unlockPosition={unlockXCoord}
        />
        <PositionInput
          editable={opSettings.currentStep?.stepMode === settingsOpModes.DEFAULTXYZ}
          reference={refY}
          value={stateCoord.yCoord.toFixed(3)}
          label="Y"
          name="y"
          isPositionLocked={stateCoord.yLocked}
          unlockPosition={unlockYCoord}
        />
        <PositionInput
          editable={opSettings.currentStep?.stepMode === settingsOpModes.DEFAULTXYZ}
          reference={refZ}
          value={stateCoord.zCoord.toFixed(3)}
          label="Z"
          name="z"
          isPositionLocked={stateCoord.zLocked}
          unlockPosition={unlockZCoord}
        />
        <input type="submit" className="sr-only" tabIndex={-1} />
      </div>
    </form>
  );
};

export default PositionToolbar;
