import { ChangeEvent, FC } from "react";
import { SecondaryButton } from "shared/components/ui/button";
import CloseIcon from "modules/cad/components/icons/close";
import styled from "styled-components";
import PropertiesSelect from "modules/cad/components/properties/component-select";
import PanelPropertiesForm from "modules/cad/components/properties/properties-panel-form";
import Checkbox from "shared/components/ui/checkbox";
import { useAddHypothesis } from './hooks/use-add-hypothesis';

interface Props {
  onClose: () => void;
}

const AddHypothesisForm: FC<Props> = ({ onClose }) => {

  const {
    hypothesis,
    hypothesisProperties,
    editHypothesisProperties,
    hypothesIsEditable,
    setHypothesIsEditable,
    setCurrHypothesisName,
    currHypothesisName,
    addHypothesisSet,
  } = useAddHypothesis();

  return (
    <Wrapper className="text-xs">
      <Header>
        <h1>Add new hypothesis</h1>
        <button onClick={onClose}>
          <CloseIcon className="h-4 w-4" />
        </button>
      </Header>
      <Content>
        <ActionBar>
          <label className={"text-xs text-gray-400"}>{"Predefined:"}</label>
          <PropertiesSelect
            srOnly={true}
            value={currHypothesisName}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              setCurrHypothesisName(event.target.value);
            }}
            wheelEvent={true}
          >
            {hypothesis.map((val, i) => (
              <option key={val.uid} value={val.name}> {val.name} </option>
            ))}
          </PropertiesSelect>
          <Checkbox
            defaultChecked={false}
            label={"editable"}
            onChange={() => { setHypothesIsEditable(!hypothesIsEditable) }}
          />
        </ActionBar>
        <ActionBar0 className="layers">

          <PanelPropertiesForm panelProp={{
            propValue: hypothesisProperties,
            propCallback: editHypothesisProperties
          }} />
        </ActionBar0>
        <BottomToolBar>
          <SecondaryButton onClick={addHypothesisSet}>
            Add hypothesis
          </SecondaryButton>
          <SecondaryButton onClick={onClose}>
            Cancel
          </SecondaryButton>
        </BottomToolBar>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow: hidden;
  border-radius: 4px;
  width: 400px;
  margin: -32px;
  background-color: var(--color-gray-300);
`;
const Header = styled.div`
  background-color: var(--color-gray-300);
  border-bottom: 2px solid var(--color-primary);
  padding: 8px;
  display: flex;
  justify-content: space-between;
`;
const Content = styled.div`
  display: grid;
  /* grid-template-rows: 36px auto 36px; */
  grid-template-columns: auto;
  gap: 4px;
  padding: 8px;
  height: 100%;
`;
const Box = styled.div`
  background-color: var(--color-gray-200);
  padding: 8px;
  border-radius: 4px;
  overflow: hidden;
`;
const ActionBar = styled(Box)`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
`;
const ActionBar0 = styled(Box)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  width: 100%;
`;
const BottomToolBar = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
export default AddHypothesisForm;
