import { requestStatus, saveFile } from "lib/apis/utils";
import { userMessageEvents } from "lib/events/user-messages";
import { analysisManager } from "lib/models-struc/analysis/analysismodel-manager";
import { analysisDispatcher } from "lib/models-struc/analysis/dispatcher";
import { beamFMList, displacementList, loadHypothesisList, nodalFMList, shellFMList } from "lib/models-struc/hypothesis/hypothesis";
import { hypothesisManager } from "lib/models-struc/hypothesis/hypothesismodel-manager";
import { SSEhypothesisManager } from "lib/models-struc/hypothesis/sse";
import { windhypothesisManager } from "lib/models-struc/hypothesis/wind";
import { meshManager } from "lib/models-struc/mesh/meshmodel-manager";
import { resultType, solutionManager } from "lib/models-struc/solution/solutionmodel-manager";
import { dataInfoProperty } from "lib/properties/properties";
import { IPanelSetting } from "modules/cad/components/sidebar/context";
import { useMainGraphicContext } from "modules/cad/components/viewport/context";
import { useUI } from "shared/components/ui/context";

export function useAnalysisPanel() {

  const getPropSettingsFromHypothesisSolve = (): IPanelSetting<{ hypothesis: { active: boolean }[] }> | null => {

    const solveList = analysisManager.analysis.solveHypothesis;

    const hypothesis = hypothesisManager.getAllHypothesis();
    const hypothesisOptions: dataInfoProperty<{ active: boolean }>[] = hypothesis.map((h) => {
      const isSelfWeight = h.tag === loadHypothesisList.SELF_WEIGHT;
      const numLoads = hypothesisManager.getLoadsByHypotesis(h).length;
      const editable = isSelfWeight || (!isSelfWeight && numLoads > 0);
      const publicName = isSelfWeight ? h.name : `${h.name} (${numLoads} Loads)`;
      return { active: { publicName, type: "checkbox", value: solveList.includes(h), editable } };
    });
    const sseHypothesis = SSEhypothesisManager.getActiveSSEHypothesis();
    for (const hypo of sseHypothesis) {
      hypothesisOptions.push({ active: { publicName: hypo.sseDir, type: "checkbox", value: solveList.includes(hypo) } });
    }
    const windHypothesis = windhypothesisManager.getWindHypothesis()!;
    for (const hypo of windHypothesis) {
      const loads = windhypothesisManager.getWindLoadHypothesisByUiid(hypo.uid);
      hypothesisOptions.push({ active: { publicName: `${hypo.name} (${loads.length} Loads)`, type: "checkbox", value: solveList.includes(hypo) } });
    }
    if (hypothesisOptions.length === 0) return null;
    return {
      title: "Hypothesis",
      setting: {
        propValue: {
          hypothesis: hypothesisOptions,
        },
        propCallback: (hy) => {
          analysisManager.analysis.solveHypothesis = [];
          const hypothesis = hypothesisManager.getAllHypothesis();
          const sseHypothesis = SSEhypothesisManager.getActiveSSEHypothesis();
          const windHypothesis = windhypothesisManager.getWindHypothesis()!;
          const allHypothesis = [...hypothesis, ...sseHypothesis, ...windHypothesis];
          allHypothesis.forEach((h, i) => {
            if (hy.hypothesis[i].active) analysisManager.analysis.solveHypothesis.push(h);
          });
        }
      },
    }
  }
  const getPropSettingsFromCodeAnalysis = (): IPanelSetting<{}> | null => {
    return {
      title: "Code analysis",
      setting: {
        propValue: {
          analysisId: {
            type: "string", value: analysisManager.analysis.project?.Id, publicName: "AnalysisId", editable: false
          },
          ecoreAnalysis: {
            type: "button", value: undefined, publicName: "Download Ecore", buttonCb: () => {
              downloadAnalysisModelToFile();
            }
          },
          solve: {
            type: "button", value: undefined, publicName: "Launch code analysis", buttonCb: () => {
              launchAnalyzingAction();
            }
          },
          solutionsDownload: {
            type: "button", value: undefined, publicName: "Download solutions", buttonCb: () => {
              downloadSolutions();
            }
          },
        },
      },
    }
  }
  const getPropSettingsFromDisplacement = (prop: { view: string }): IPanelSetting<{ view: string }> | null => {
    return {
      title: "Results - Displacements",
      setting: {
        propValue: {
          view: {
            publicName: "", type: "radio", list: displacementList, value: displacementList[0],
            customCb: (param) => { }
          },
        },
      },
    }
  }
  const getPropSettingsFromBeamFM = (prop: { view: string }): IPanelSetting<{ view: string }> | null => {
    return {
      title: "Results - Beam F&M",
      setting: {
        propValue: {
          view: {
            publicName: "", type: "radio", list: beamFMList, value: beamFMList[0], customCb: (param) => { }
          },
        },
      },
    }
  }
  const getPropSettingsFromShellFM = (prop: { view: string }): IPanelSetting<{ view: string }> | null => {
    return {
      title: "Results - Shells F&M",
      setting: {
        propValue: {
          view: {
            publicName: "", type: "radio", list: shellFMList, value: shellFMList[0], customCb: (param) => { }
          },
        },
      },
    }
  }
  const getPropSettingsFromNodalFM = (prop: { view: string }): IPanelSetting<{ view: string }> | null => {
    return {
      title: "Results - Nodal F&M",
      setting: {
        propValue: {
          view: {
            publicName: "", type: "radio", list: nodalFMList, value: nodalFMList[0], customCb: (param) => { }
          },
        },
      },
    }
  }

  const { openModal } = useUI();
  const graphicProc = useMainGraphicContext();

  function launchAnalyzingAction(): void {
    const errors = analysisManager.checkAnalysisProjectErrors(graphicProc);
    if (errors) {
      analysisManager.status = requestStatus.ERROR;
      const message = "The analysis project has errors that make the analysis impossible. Please, check the downloaded file for more info ...";
      userMessageEvents.dispatchError(message);
      saveFile(JSON.stringify(errors), `${errors.name}.json`, "application/json");
    } else {
      openModal("ANALYZING");
    }
  }
  function downloadAnalysisModelToFile(): void {
    // This is neccessary to load data relative to loads and its FEMStructualElemets
    meshManager.downloadMesh(graphicProc).then(() => {
      const analysisProjectData = analysisManager.exportAnalysisProjectStruct(graphicProc);
      saveFile(JSON.stringify(analysisProjectData), `analysis_project.json`, "application/json");
    });
  }
  function downloadSolutions(): void {
    if (analysisManager.analysis.project) {
      const analysisProjectId = analysisManager.analysis.project.Id;
      const hypothesis = analysisManager.analysis.solveHypothesis;
      solutionManager.getResult(analysisProjectId, resultType.DISPL_NODES, hypothesis).then((result) => {
        if (result) {
          analysisDispatcher.dispatchSolve(analysisManager.analysis.solveHypothesis);
        }
      });
    }
  }

  return {
    getPropSettingsFromCodeAnalysis,
    getPropSettingsFromHypothesisSolve,
    getPropSettingsFromDisplacement,
    getPropSettingsFromBeamFM,
    getPropSettingsFromShellFM,
    getPropSettingsFromNodalFM,
  }
}
