import * as THREE from "three";
import { ColumnEditDataCommand } from "lib/commands/structural/column";
import { linealPrecision, userNormalizeAngleToRad } from "lib/general-settings";
import { heightType } from "lib/models-struc/types/struc-base";
import { columnParam } from "lib/models-struc/types/column";
import { ColumnData } from "lib/models/structural/column";
import { ISolidMaterial } from '../../materials/solid';
import { StructuralDataDefinitionHandler } from "./structural";
import { IPoint } from "lib/math/types";

export type columnProp = columnParam & {
  centroid: IPoint;
  compoundGroupName: string,
  xDir: string,
  yVect: string,
  ZDir: string,
  showAxis: unknown,
}
export class ColumnDataDefinitionHandler extends StructuralDataDefinitionHandler<columnProp> {

  protected data: ColumnData;

  protected buildInfoProperties() {
    super.buildInfoProperties();
    this.fillColumnDefinition(this.data);
  }

  private fillColumnDefinition(data: ColumnData) {
    const def = data.definition;
    this.structuralInfo.compoundGroupName = this.getStringView(data.compoundGroupName, "Complex-Name", false);
    this.structuralInfo.height = this.getNumberView(def.height, "Height", "m", linealPrecision, def.heightType !== heightType.STOREY);
    this.structuralInfo.crossSectionId = this.getBeamCrossSectionView(def.crossSectionId);
    this.structuralInfo.orientation = this.getAngleView(def.orientation, "Orientation");

    const v = this.getVectorsParsed(def.rotation.z);
    this.structuralInfo.xDir = this.getStringView(v[0], "X Dir", false);
    this.structuralInfo.yVect = this.getStringView(v[1], "Y Vect", false);
    this.structuralInfo.ZDir = this.getStringView(v[2], "Z Dir", false);
    this.structuralInfo.showAxis = {
      type: "button", publicName: "Show local axis",
      buttonCb: () => { this.data.showAxisHelper(this.graphicProcessor) }
    }
  }

  private getVectorsParsed(orientation: number) {
    const precision = 4;
    const x = '( 0,  0,  1)';
    const angle = new THREE.Euler(0, 0, orientation);
    const yVec = new THREE.Vector3(0, 1, 0);
    yVec.applyEuler(angle)
    const y = `(${yVec.x.toFixed(precision)}, ${yVec.y.toFixed(precision)}, ${yVec.z.toFixed(precision)})`;
    const zVec = new THREE.Vector3(-1, 0, 0);
    zVec.applyEuler(angle);
    const z = `(${zVec.x.toFixed(precision)}, ${zVec.y.toFixed(precision)}, ${zVec.z.toFixed(precision)})`;
    return [x, y, z];
  }

  private checkNewStructural(newStructural: columnProp) {
    if (!newStructural) { return false; }
    if (!this.checkStructuralNewDefinition(newStructural)) { return false; }
    if (!this.checkNumber(newStructural.height, (value) => {
      const strucMng = this.graphicProcessor.getStructuralModelManager();
      const storey = strucMng.currBuilding.getStoreyFromId(this.data.definition.storeyId)!;
      return storey?.height >= value;
    })) { return false; }
    if (!this.checkString(newStructural.crossSectionId)) { return false; }
    return true;
  }

  private changedColumnNewStructural(oldDefinition: columnParam, newDefinition: columnProp) {
    let def = oldDefinition;
    let changed: boolean = false;
    if (newDefinition) {
      const height = this.changedNumber(def.height, newDefinition.height);
      if (height !== null) {
        def.height = height;
        changed = true;
      }
      const crossSectionId = this.changedString(def.crossSectionId, newDefinition.crossSectionId);
      if (crossSectionId !== null) {
        def.crossSectionId = crossSectionId;
        changed = true;
      }
      const orientRad = userNormalizeAngleToRad(newDefinition.orientation);
      const orientation = this.changedNumber(def.rotation?.z, orientRad);
      if (orientation !== null) {
        def.orientation = orientation;
        def.rotation.z = orientation;
        changed = true;
      }
    }
    return (changed ? def : null);
  }

  public saveAndRegenerate = (newDefinition: columnProp) => {
    const columnDefinition = this.checkAndChangedDefinition(this.data.cloneDefinition(), newDefinition);
    if (columnDefinition) {
      const command = new ColumnEditDataCommand(this.data, columnDefinition, null, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }

  public saveAndRegenerateMaterial = (newMaterial: ISolidMaterial) => {
    const columnMaterial = this.checkAndChangedSolidMaterial(this.data.material, newMaterial);
    if (columnMaterial) {
      const command = new ColumnEditDataCommand(this.data, null, columnMaterial, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }

  public saveAndRegenerateStruct = (newStructural: columnProp) => {
    if (this.checkNewStructural(newStructural)) {
      const columnStrucParam = this.changedColumnNewStructural(this.data.cloneDefinition(), newStructural);
      if (columnStrucParam) {
        const command = new ColumnEditDataCommand(this.data, columnStrucParam, null, this.graphicProcessor);
        if (command) this.graphicProcessor.storeAndExecute(command);
      }
    }
  }
}