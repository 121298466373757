import cn from "classnames";
import { ChangeEvent, forwardRef, InputHTMLAttributes, ReactNode } from "react";
import { useDebounceCallback } from "shared/utils/hooks/use-debounce";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label: string;
  error?: string;
  srOnly?: boolean;
  adornment?: ReactNode;
}

const PropertiesRange = forwardRef<HTMLInputElement, Props>((props, inputRef) => {
  const {
    className,
    children,
    id,
    label,
    type,
    error,
    srOnly,
    adornment,
    onChange,
    ...rest
  } = props;

  const handleOnChange = useDebounceCallback((event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (onChange) {
      onChange(event);
    }
  }, 100);

  const rootClassName = cn(
    "bg-gray-700 block w-full rounded text-gray-100 hover:bg-gray-600",
    "focus:bg-gray-600 px-1 py-0 text-xs leading-5 disabled:text-gray-400",
    error
      ? "border-red-500 focus:outline-none focus:ring-red-500 focus:border-red-500"
      : "border-gray-600 focus:ring-indigo-500 focus:border-indigo-500",
    className
  );

  return (
    <div className={srOnly ? "w-full" : "flex gap-2 items-baseline w-full"}>
      <label
        htmlFor={id}
        className={srOnly ? "sr-only" : "text-xs text-gray-400 truncate text-right flex-1"}
      >
        {label}
      </label>
      <div className={`relative flex items-center ${srOnly ? "w-full" : "flex-1"}`}>
        <input
          id={id}
          ref={inputRef}
          className={rootClassName}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          type={"range"}
          onChange={handleOnChange}
          {...rest}
        />
        {adornment && (
          <div className="absolute right-1 z-10 text-xs">{adornment}</div>
        )}
      </div>
    </div>
  );
});

PropertiesRange.displayName = "PropertiesRange";

export default PropertiesRange;
