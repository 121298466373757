import * as THREE from "three";
import { addIpoint, copyIPoint, substractIpoint } from "../math/point";
import { IPoint } from "../math/types";
import { getNormalFromEuler } from "../math/plane";
import { rotatePoint } from "../math/rotate";
import { IBox } from "../math/box";
import { userPlaneLines, userPlaneGrid } from "../styles/colors";
import { scalePoint } from "../math/scale";
import { scuGridHelperDefault } from "../scu-grid";

/** Properties to build plane helpers */

export interface gridGraphicSettings {
  size: number;
  division: number;
  colorCenterLine: number | string;
  colorGrid: number | string;
}
const planeHelperDefault: gridGraphicSettings = {
  size: 25,
  division: 5,
  colorCenterLine: userPlaneLines,
  colorGrid: userPlaneGrid,
};
/** Save definition of planes and its three helpers */

export class PlaneDefinition {

  public position: IPoint;
  public rotation: IPoint; // Euler rotation XYZ
  public locked: boolean = false;
  public active: boolean = true;

  get normal() { return getNormalFromEuler(this.rotation); }

  public axesHelper: THREE.AxesHelper | undefined;
  public gridHelper: THREE.GridHelper | undefined;
  public gridHelperSettings: gridGraphicSettings = scuGridHelperDefault;

  constructor(position?: IPoint, rotation?: IPoint) {
    this.position = position ? copyIPoint(position) : { x: 0, y: 0, z: 0 };
    this.rotation = rotation ? copyIPoint(rotation) : { x: 0, y: 0, z: 0 };
  }
  public clone() {
    const newPlane = new PlaneDefinition(this.position, this.rotation);
    newPlane.locked = this.locked;
    newPlane.active = this.active;
    newPlane.gridHelperSettings = {
      size: this.gridHelperSettings.size,
      division: this.gridHelperSettings.division,
      colorCenterLine: this.gridHelperSettings.colorCenterLine,
      colorGrid: this.gridHelperSettings.colorGrid,
    };
    return newPlane;
  }
  public setAsMainPlane() {
    this.gridHelperSettings = {
      size: scuGridHelperDefault.size,
      division: scuGridHelperDefault.division,
      colorCenterLine: scuGridHelperDefault.colorCenterLine,
      colorGrid: scuGridHelperDefault.colorGrid,
    };
    this.setPlaneHelpers();
  }
  public setAsUserPlane() {
    this.gridHelperSettings = {
      size: planeHelperDefault.size,
      division: planeHelperDefault.division,
      colorCenterLine: planeHelperDefault.colorCenterLine,
      colorGrid: planeHelperDefault.colorGrid,
    };
    this.setPlaneHelpers();
  }
  public getRelativeCornerVertices(): IBox {
    const size = this.gridHelperSettings.size * 0.5;
    return {
      min: { x: -size, y: -size, z: 0 },
      max: { x: size, y: size, z: 0 },
    };
  }
  public getAbsoluteCornerVertices(): IPoint[] {
    const size = this.gridHelperSettings.size * 0.5;
    let p0 = rotatePoint(
      { x: size, y: size, z: 0 },
      this.rotation.x,
      this.rotation.y,
      this.rotation.z
    );
    let p1 = rotatePoint(
      { x: -size, y: size, z: 0 },
      this.rotation.x,
      this.rotation.y,
      this.rotation.z
    );
    let p2 = rotatePoint(
      { x: -size, y: -size, z: 0 },
      this.rotation.x,
      this.rotation.y,
      this.rotation.z
    );
    let p3 = rotatePoint(
      { x: size, y: -size, z: 0 },
      this.rotation.x,
      this.rotation.y,
      this.rotation.z
    );
    p0 = addIpoint(p0, this.position);
    p1 = addIpoint(p1, this.position);
    p2 = addIpoint(p2, this.position);
    p3 = addIpoint(p3, this.position);
    return [p0, p1, p2, p3];
  }

  public getRelativePoint(absPoint: IPoint): IPoint {
    return getRelativePoint(absPoint, this.position, this.rotation);
  }
  public getAbsolutePoint(relPoint: IPoint): IPoint {
    return getAbsolutePoint(relPoint, this.position, this.rotation);
  }

  private setPlaneHelpers() {
    this.axesHelper = new THREE.AxesHelper(this.gridHelperSettings.size * 0.2);
    this.axesHelper.position.set(
      this.position.x,
      this.position.y,
      this.position.z
    );
    this.axesHelper.rotation.set(
      this.rotation.x,
      this.rotation.y,
      this.rotation.z
    );

    this.gridHelper = new THREE.GridHelper(
      this.gridHelperSettings.size,
      this.gridHelperSettings.division,
      this.gridHelperSettings.colorCenterLine,
      this.gridHelperSettings.colorGrid
    );
    this.gridHelper.position.set(
      this.position.x,
      this.position.y,
      this.position.z - 0.00001
    );
    this.gridHelper.rotation.set(
      this.rotation.x,
      this.rotation.y,
      this.rotation.z
    );
    this.gridHelper.rotateX(Math.PI * 0.5); // Helper is Y up axis
  }
  public updatePlaneHelpersGeom() {
    if (this.axesHelper) {
      this.axesHelper.position.set(
        this.position.x,
        this.position.y,
        this.position.z
      );
      this.axesHelper.rotation.set(
        this.rotation.x,
        this.rotation.y,
        this.rotation.z
      );
    }
    if (this.gridHelper) {
      this.gridHelper.position.set(
        this.position.x,
        this.position.y,
        this.position.z - 0.00001
      );
      this.gridHelper.rotation.set(
        this.rotation.x,
        this.rotation.y,
        this.rotation.z
      );
      this.gridHelper.rotateX(Math.PI * 0.5); // Helper is Y up axis
    }
  }
  public updatePlaneHelpers() {
    if (this.axesHelper) {
      const newAxes = new THREE.AxesHelper(this.gridHelperSettings.size * 0.2);
      this.axesHelper.geometry.dispose();
      this.axesHelper.geometry = newAxes.geometry;
    }
    if (this.gridHelper) {
      const newGrid = new THREE.GridHelper(
        this.gridHelperSettings.size,
        this.gridHelperSettings.division,
        this.gridHelperSettings.colorCenterLine,
        this.gridHelperSettings.colorGrid
      );

      this.gridHelper.geometry.dispose();
      (this.gridHelper.material as THREE.LineBasicMaterial).dispose();
      this.gridHelper.geometry = newGrid.geometry;
      this.gridHelper.material = newGrid.material;
    }
  }
  public hidePlaneHelpers() {
    if (this.axesHelper) {
      this.axesHelper.visible = false;
    }
    if (this.gridHelper) {
      this.gridHelper.visible = false;
    }
  }
  public showPlaneHelpers() {
    if (this.axesHelper) {
      this.axesHelper.visible = true;
    }
    if (this.gridHelper) {
      this.gridHelper.visible = true;
    }
  }
  public addToScene(scene: THREE.Scene) {
    if (this.gridHelper && this.axesHelper) {
      scene.add(this.gridHelper);
      scene.add(this.axesHelper);
    }
  }
  public removeFromScene(scene: THREE.Scene) {
    if (this.gridHelper && this.axesHelper) {
      scene.remove(this.gridHelper);
      scene.remove(this.axesHelper);
    }
  }
}

export function getRelativePoint(absPoint: IPoint, position: IPoint, rotation: IPoint) {
  let p0 = substractIpoint(absPoint, position);
  p0 = rotatePoint(p0, -rotation.x, 0, 0);
  p0 = rotatePoint(p0, 0, -rotation.y, 0);
  p0 = rotatePoint(p0, 0, 0, -rotation.z);
  return p0;
}

export function getAbsolutePoint(relPoint: IPoint, position: IPoint, rotation: IPoint, scale?: IPoint) {
  let p0 = scale ? scalePoint(relPoint, scale.x, scale?.y, scale.z) : relPoint;
  p0 = rotatePoint(p0, rotation.x, rotation.y, rotation.z);
  p0 = addIpoint(p0, position);
  return p0;
}
