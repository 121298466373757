import ParagraphIcon from "../../icons/paragraph";
import TextIcon from "../../icons/text";
import { useOperation } from "../../operations/context";
import ToolbarButton from "../toolbar-button";
import ToolbarSection from "../toolbar-section";

const TextSection = () => {
  const { createText, createParagraph } = useOperation();
  return (
    <ToolbarSection title="Dimension" className={"border-b border-gray-600"}>
      <ToolbarSection.Row>
        <ToolbarButton onClick={createText} tooltip="Text">
          <TextIcon />
        </ToolbarButton>
        <ToolbarButton onClick={createParagraph} tooltip="Paragraph">
          <ParagraphIcon />
        </ToolbarButton>
      </ToolbarSection.Row>
    </ToolbarSection>
  );
};

export default TextSection;
