import * as THREE from "three";
import { getCurrentLineMaterial, ILineMaterial } from "lib/materials";
import { IPolylineParam, copyIArcLine, getBufferFromPolylineParam } from "lib/math/line";
import { mirrorPoint } from "lib/math/mirror";
import { copyIPoint, addIpoint } from "lib/math/point";
import { rotatePoint } from "lib/math/rotate";
import { scalePoint } from "lib/math/scale";
import { IPoint } from "lib/math/types";
import { Line2 } from "three/examples/jsm/lines/Line2";
import { objDataType } from "../types";
import { LineBaseData } from "./line-base";
import { lineBaseCreate } from '../../geometries/line';


export class LineData extends LineBaseData {

  public type = objDataType.POLYLINE;
  protected nameObj: string = "Polyline";
  public definition: IPolylineParam;

  constructor(definition: IPolylineParam, material?: ILineMaterial) {
    super();
    this.definition = {
      isClosed: definition.isClosed,
      points: definition.points.map(copyIPoint),
      arcs: definition.arcs.map((a) => {
        return a ? copyIArcLine(a) : 0;
      }),
    };
    this.material = material ?? getCurrentLineMaterial();
  }

  static createObj(definition: IPolylineParam, material: ILineMaterial) {
    const buffer = getBufferFromPolylineParam(definition);
    return lineBaseCreate(buffer, material);
  }
  public createObject(definition?: IPolylineParam, material?: ILineMaterial): THREE.Line | Line2 {
    return LineData.createObj(definition ?? this.definition, material ?? this.material);
  }
  public getBufferGeom(): Float32Array {
    return getBufferFromPolylineParam(this.definition);
  }

  public cloneDefinition(): IPolylineParam {
    return {
      isClosed: this.definition.isClosed,
      points: this.definition.points.map(copyIPoint),
      arcs: this.definition.arcs.map((a) => {
        return a ? copyIArcLine(a) : 0;
      }),
    };
  }
  public translate(distance: IPoint) {
    this.definition.points = this.definition.points.map((p) =>
      addIpoint(p, distance)
    );
    this.definition.arcs.forEach((p) => {
      if (p) {
        p.center = addIpoint(p.center, distance);
      }
    });
    this.regenerateObjectFromDefinition();
  }
  public rotate(
    angleX: number,
    angleY: number,
    angleZ: number,
    basePoint: IPoint
  ): void {
    this.definition.points = this.definition.points.map((p) =>
      rotatePoint(p, angleX, angleY, angleZ, basePoint)
    );
    this.definition.arcs.forEach((p) => {
      if (p) {
        p.center = rotatePoint(p.center, angleX, angleY, angleZ, basePoint);
      }
    });
    this.regenerateObjectFromDefinition();
  }
  public scale(
    factorX: number,
    factorY: number,
    factorZ: number,
    basePoint: IPoint
  ): void {
    this.definition.points = this.definition.points.map((p) =>
      scalePoint(p, factorX, factorY, factorZ, basePoint)
    );
    this.definition.arcs.forEach((p) => {
      if (p) {
        p.center = scalePoint(p.center, factorX, factorY, factorZ, basePoint);
        p.radius *= factorX;
      }
    });
    this.regenerateObjectFromDefinition();
  }
  public mirror(startPoint: IPoint, endPoint: IPoint): void {
    this.definition.points = this.definition.points.map((p) =>
      mirrorPoint(p, startPoint, endPoint)
    );
    this.definition.arcs.forEach((p) => {
      if (p) {
        p.center = mirrorPoint(p.center, startPoint, endPoint);
      }
    });
    this.regenerateObjectFromDefinition();
  }
  public delete(): void {
    this.graphicObj.geometry.dispose();
  }
}
