import { GraphicProcessor } from "lib/graphic-processor";
import { structElemType } from "../types/struc-base";
import { isEqual, vector2Equals } from "lib/math/epsilon";
import { Building } from "../struc-building";
import { addOverlappedAndContiguousObjsAsLinkedObjs } from "./struc-linkedobj";
import { IStrucElementData, SupportElementTypes } from "lib/models/structural/structural";
import { isFoundationData, isSupportData } from "lib/models/checktools";
import { objDataType } from "lib/models/types";

export function EditConsolidation(structElement: IStrucElementData, graphicProcessor: GraphicProcessor): void {
  structElement.removeAllLinkedObject();
  resolveStorey(graphicProcessor, structElement);
  if (isSupportData(structElement)) {
    verticalConsolidateOnCreateAndUpdate(graphicProcessor, structElement);
  }
  addOverlappedAndContiguousObjsAsLinkedObjs(structElement, graphicProcessor);
}
export function AddConsolidation(structElement: IStrucElementData, graphicProcessor: GraphicProcessor): void {
  structElement.removeAllLinkedObject();
  checkName(graphicProcessor, structElement);
  resolveStorey(graphicProcessor, structElement);
  if (isSupportData(structElement)) {
    verticalConsolidateOnCreateAndUpdate(graphicProcessor, structElement);
  }
  addOverlappedAndContiguousObjsAsLinkedObjs(structElement, graphicProcessor);
}
export function DeleteConsolidation(structElement: IStrucElementData, graphicProcessor: GraphicProcessor): void {
  structElement.removeAllLinkedObject();
  if (isSupportData(structElement)) {
    verticalConsolidateOnDelete(graphicProcessor, structElement);
  }
}

function checkName(graphicProcessor: GraphicProcessor, structElement: IStrucElementData) {
  const currName = structElement.strucName;
  const strucMng = graphicProcessor.getStructuralModelManager();
  const elements = strucMng.currBuilding.getAllStructElements(structElement.type);
  for (const elem of elements) {
    if (elem !== structElement && elem.strucName === currName) {
      const newName = strucMng.currBuilding.getNextElemName(structElement.type);
      structElement.definition.name = newName;
      checkName(graphicProcessor, structElement);
    }
  }
}
function resolveStorey(graphicProcessor: GraphicProcessor, structData: IStrucElementData): void {
  const strucMng = graphicProcessor.getStructuralModelManager();
  const currBuilding = strucMng.currBuilding;
  const basePoint = structData.definition.basePoint;
  let storey = currBuilding.getStoreyFromLevel(basePoint.z);
  if (isFoundationData(structData) && isEqual(basePoint.z, storey.level)) {
    const indx = currBuilding.storeys.indexOf(storey);
    storey = currBuilding.storeys[indx + 1];
  }

  const layerData = strucMng.getLayerByStoreyIdAndStructuralType(storey.id, structData.type);
  if (storey.id !== structData.storeyId || layerData.id !== structData.layerId) {
    console.warn("Warning: Structural element storey does not match. It will be fixed.");

    const oldStorey = currBuilding.getStoreyFromId(structData.definition.storeyId)!;
    oldStorey.deleteStructuralElement(structData);
    storey.addStructuralElement(structData);
    structData.definition.storeyId = storey.id;

    const lyrMng = graphicProcessor.getLayerManager();
    lyrMng.moveObjData2Layer(structData, layerData.id);
  }
}

function verticalConsolidateOnCreateAndUpdate(graphicProcessor: GraphicProcessor, structElement: SupportElementTypes) {
  if (belongsToGroupWithMultipleElements(graphicProcessor, structElement)) {
    removeFromGroup(graphicProcessor, structElement);
  }
  const candidateGroups = getCandidateGroups(graphicProcessor, structElement);
  addToGroups(graphicProcessor, structElement, candidateGroups);
}
function verticalConsolidateOnDelete(graphicProcessor: GraphicProcessor, structElement: SupportElementTypes) {
  removeFromGroup(graphicProcessor, structElement);
}

function belongsToGroupWithMultipleElements(graphicProcessor: GraphicProcessor, structElement: SupportElementTypes): boolean {
  const strucMng = graphicProcessor.getStructuralModelManager();
  const elements = strucMng.currBuilding.getCompoundElementsOfGroup(structElement.type, structElement.compoundGroupName);
  return (elements.length > 1);
}

function addLinkedObjects(structElementA: SupportElementTypes, structElementB: SupportElementTypes) {
  if (structElementA !== undefined && structElementB !== undefined) {
    structElementA.addLinkedObject(structElementB);
    structElementB.addLinkedObject(structElementA);
  }
}
function removeLinkedObjects(structElementA: SupportElementTypes, structElementB: SupportElementTypes) {
  if (structElementA !== undefined && structElementB !== undefined) {
    structElementA.removeLinkedObject(structElementB);
    structElementB.removeLinkedObject(structElementA);
  }
}
function removeLinkedObjectsFromGroup(structElement: SupportElementTypes, suppBefore: SupportElementTypes[], suppAfter: SupportElementTypes[]) {
  if (suppBefore.length > 0) { removeLinkedObjects(structElement, suppBefore[suppBefore.length - 1]); }
  if (suppAfter.length > 0) { removeLinkedObjects(structElement, suppAfter[0]); }
}

function removeFromGroup(graphicProcessor: GraphicProcessor, structElement: SupportElementTypes) {
  const strucMng = graphicProcessor.getStructuralModelManager();
  const mapSupports = strucMng.currBuilding.getCompoundElements(structElement.type);
  const suppStrucElems = mapSupports.get(structElement.compoundGroupName);
  if (suppStrucElems && suppStrucElems.length > 0) {
    const index = suppStrucElems.indexOf(structElement as SupportElementTypes);
    const supportsBefore: SupportElementTypes[] = suppStrucElems.slice(0, index);
    const supportsAfter: SupportElementTypes[] = suppStrucElems.slice(index + 1);
    if ((supportsBefore.length === 0) || (supportsAfter.length === 0)) {
      strucMng.currBuilding.deleteCompoundElement(structElement.compoundGroupName, structElement);
    } else {
      strucMng.currBuilding.deleteFullCompoundElement(structElement.compoundGroupName, structElement.type);
      strucMng.currBuilding.addCompoundElements(strucMng.currBuilding.getNextCompoundNameAndUpdateElementsName(structElement.type, supportsBefore), supportsBefore);
      strucMng.currBuilding.addCompoundElements(strucMng.currBuilding.getNextCompoundNameAndUpdateElementsName(structElement.type, supportsAfter), supportsAfter);
    }
    removeLinkedObjectsFromGroup(structElement, supportsBefore, supportsAfter);
  }
}
function getCandidateGroups(graphicProcessor: GraphicProcessor, structElement: SupportElementTypes): string[] {
  const candidateGroups = [];
  const strucMng = graphicProcessor.getStructuralModelManager();
  const compoundElementGroups = strucMng.currBuilding.getCompoundElements(structElement.type);
  for (let [name, compoundElements] of compoundElementGroups) {
    if (!compoundElements.some(e => e.id === structElement.id)) {
      if (areCandidateGroup(structElement, compoundElements)) {
        if (checkFitGroup(structElement, compoundElements)) {
          candidateGroups.push(name);
        }
      }
    }
  }
  return candidateGroups;
}
function areCandidateGroup(structElement: SupportElementTypes, elements: SupportElementTypes[]): boolean {
  // Are candidates for union if all elements have same x and y coord
  const firstBasePoint = structElement.definition.basePoint;
  return elements.every(c => {
    const candBasePoint = c.definition.basePoint;
    return vector2Equals(candBasePoint, firstBasePoint) && !isEqual(candBasePoint.z, firstBasePoint.z);
  });
}
function checkFitGroup(structElement: SupportElementTypes, elements: SupportElementTypes[]): boolean {
  return upFitGroup([structElement], elements) || downFitGroup([structElement], elements);
}
function upFitGroup(candidates: SupportElementTypes[], elements: SupportElementTypes[]): boolean {
  if (candidates.length && elements.length) {
    const candidateRepr = candidates[candidates.length - 1].definition;
    const elementRepr = elements[0].definition;
    return isEqual((candidateRepr.basePoint.z - candidateRepr.height), elementRepr.basePoint.z);
  }
  return false;
}
function downFitGroup(candidates: SupportElementTypes[], elements: SupportElementTypes[]): boolean {
  if (candidates.length && elements.length) {
    const candidateRepr = candidates[0].definition;
    const elementRepr = elements[elements.length - 1].definition;
    return isEqual(candidateRepr.basePoint.z, elementRepr.basePoint.z - elementRepr.height);
  }
  return false;
}

function addToGroups(graphicProcessor: GraphicProcessor, structElement: SupportElementTypes, groups: string[]) {
  const strucMng = graphicProcessor.getStructuralModelManager();
  const building = strucMng.currBuilding;
  let candidates = [structElement];
  let lastCompoundGroupName = structElement.compoundGroupName;
  if (groups.length) {
    for (let group of groups) {
      const elements = building.getCompoundElementsOfGroup(structElement.type, group);
      if (elements.length > 0) {
        building.deleteFullCompoundElement(lastCompoundGroupName, structElement.type);
        building.updateElementsName(group, candidates);
        if (upFitGroup(candidates, elements)) {
          addLinkedObjects(candidates[candidates.length - 1], elements[0]);
          building.insertCompoundElements(group, 0, candidates);
          candidates = [...candidates, ...elements];
        } else if (downFitGroup(candidates, elements)) {
          addLinkedObjects(elements[elements.length - 1], candidates[0]);
          building.addCompoundElements(group, candidates);
          candidates = [...elements, ...candidates];
        }
      }
      lastCompoundGroupName = group;
    }
  } else {
    const compoundGroupName = building.getNextElemName(structElement.type);
    building.updateElementsName(compoundGroupName, candidates);
    building.addCompoundElements(compoundGroupName, candidates);
  }
}

// ---------------------------------------------------------------------------------------

// Function to dump elements with linked objects to debug funcionality easyly
export function dumpLinkedObjects(graphicProcessor: GraphicProcessor) {
  const strucMng = graphicProcessor.getStructuralModelManager();
  let exportedElements = [];
  const structElements = strucMng.getStructElements();
  const orderesElements = structElements.sort((a, b) => (a.strucName > b.strucName) ? 1 : ((b.strucName > a.strucName) ? -1 : 0));
  for (let elem of orderesElements) {
    const lnkObjs = [];
    const ordereslnkObjs = elem.lnkObjs.sort((a, b) => (a.strucName > b.strucName) ? 1 : ((b.strucName > a.strucName) ? -1 : 0));
    for (let obj of ordereslnkObjs) {
      lnkObjs.push({
        id: obj.id,
        name: obj.strucName,
      });
    }
    exportedElements.push({
      id: elem.id,
      name: elem.strucName,
      lnkObjs,
    });
  }
  return exportedElements;
}
// Function to dump compounds elements to debug funcionality easyly
export function dumpCompoundElements(graphicProcessor: GraphicProcessor) {
  const projMng = graphicProcessor.getProjectModelManager();
  const compoundElements = [];
  for (let site of projMng.project.sites) {
    for (let building of site.buildings) {
      compoundElements.push(dumpCompoundBuildingElements(building, objDataType.COLUMN));
      compoundElements.push(dumpCompoundBuildingElements(building, objDataType.WALL));
    }
  }
  return compoundElements;
}
// Function to dump compounds elements to debug funcionality easyly
function dumpCompoundBuildingElements(building: Building, structElemType: structElemType) {
  const mapSupports = building.getCompoundElements(structElemType);
  const compounds = [];
  for (let compound of mapSupports) {
    const groupName = compound[0];
    const elements = [];
    for (let comp of compound[1]) {
      console.assert(!elements.find(el => el.id === comp.id), `Elemento (${comp.id}) repetido en Compound group "${comp.strucName}"`);
      elements.push({
        name: comp.strucName,
        compoundGroupName: comp.compoundGroupName,
        id: comp.id
      });
    }
    compounds.push({ groupName: groupName, elements: elements });
  }
  return compounds;
}