import { compressAndUploadToS3 } from "lib/apis/utils";
import { PlanesSettings } from "lib/coordinates/plane-manager";
import { CameraSettings } from "lib/graphic-processor";
import { ICADModelPersistence } from "lib/input-output/database/loader";
import { AnalysisProperties } from "lib/models-struc/analysis/analysis";
import { Hypothesis } from "lib/models-struc/hypothesis/hypothesis";
import { MeshProperties } from "lib/models-struc/mesh/mesh";
import { StrucSite } from "modules/struc/models/project";

export const updateFiles = {
  analysis: async (data: AnalysisProperties, url: string) => {
    await compressAndUploadToS3(JSON.stringify(data), url);
  },
  cameraConfig: async (data: CameraSettings, url: string) => {
    await compressAndUploadToS3(JSON.stringify(data), url);
  },
  content: async (data: ICADModelPersistence, url: string) => {
    await compressAndUploadToS3(JSON.stringify(data), url);
  },
  hypothesis: async (data: Hypothesis[], url: string) => {
    await compressAndUploadToS3(JSON.stringify(data), url);
  },
  mesh: async (data: MeshProperties, url: string) => {
    await compressAndUploadToS3(JSON.stringify(data), url);
  },
  planes: async (data: PlanesSettings, url: string) => {
    await compressAndUploadToS3(JSON.stringify(data), url);
  },
  sites: async (data: StrucSite[], url: string) => {
    await compressAndUploadToS3(JSON.stringify(data), url);
  },
  snapshot: async (data: string, url: string) => {
    await compressAndUploadToS3(data, url);
  },
}
