import { GraphicProcessor } from "lib/graphic-processor";
import { StyleCacheHandler } from "lib/styles/style-cache";
import { sectionType } from "./types";
import { BeamCrossSection } from "./beamcs-shapes";

export let beamCrossSectionCache: beamCrossSectionCacheHandler;

export let currentBeamCrossSectionId: string;

export class beamCrossSectionCacheHandler extends StyleCacheHandler<BeamCrossSection> {

  static initialize() {
    beamCrossSectionCache = new beamCrossSectionCacheHandler();
    beamCrossSectionCache.loadDefaultItems();
  }
  static clear() {
    beamCrossSectionCache.clear();
    beamCrossSectionCache = undefined!;
  }
  private loadDefaultItems() {
    const beamCrossSection0 = new BeamCrossSection({
      name: "rectangular section",
      parameter: {
        beamSectionType: sectionType.RECT,
        height: 0.3,
        width: 0.3,
      }
    });
    beamCrossSection0.default = true;
    beamCrossSection0.styleId = "BeamDef00";
    beamCrossSectionCache.saveStyle(beamCrossSection0);
    currentBeamCrossSectionId = beamCrossSection0.styleId;

    const beamCrossSection1 = new BeamCrossSection({
      name: "circular section",
      parameter: {
        beamSectionType: sectionType.CIRC,
        radius: 0.2,
      }
    });
    beamCrossSection1.default = true;
    beamCrossSection1.styleId = "BeamDef01";
    beamCrossSectionCache.saveStyle(beamCrossSection1);

    const beamCrossSection2 = new BeamCrossSection({
      name: "T section",
      parameter: {
        beamSectionType: sectionType.T,
        height: 0.5,
        webThickness: 0.15,
        topFlangeLength: 0.5,
        topFlangeThickness: 0.15,
      }
    });
    beamCrossSection2.default = true;
    beamCrossSection2.styleId = "BeamDef02";
    beamCrossSectionCache.saveStyle(beamCrossSection2);

    const beamCrossSection3 = new BeamCrossSection({
      name: "I section",
      parameter: {
        beamSectionType: sectionType.I,
        height: 0.5,
        webThickness: 0.15,
        topFlangeLength: 0.5,
        topFlangeThickness: 0.15,
        bottomFlangeLength: 0.5,
        bottomFlangeThickness: 0.15,
      }
    });
    beamCrossSection3.default = true;
    beamCrossSection3.styleId = "BeamDef03";
    beamCrossSectionCache.saveStyle(beamCrossSection3);

    const beamCrossSection4 = new BeamCrossSection({
      name: "U section",
      parameter: {
        beamSectionType: sectionType.U,
        height: 0.5,
        width: 0.5,
        verticalWebThickness: 0.15,
        horizontalFlangeThickness: 0.15,
      }
    });
    beamCrossSection4.default = true;
    beamCrossSection4.styleId = "BeamDef04";
    beamCrossSectionCache.saveStyle(beamCrossSection4);

    const beamCrossSection5 = new BeamCrossSection({
      name: "Quad section",
      parameter: {
        beamSectionType: sectionType.QUAD,
        height: 0.5,
        width: 0.5,
        verticalWebThickness: 0.15,
        horizontalFlangeThickness: 0.15,
      }
    });
    beamCrossSection5.default = true;
    beamCrossSection5.styleId = "BeamDef05";
    beamCrossSectionCache.saveStyle(beamCrossSection5);

    const beamCrossSection6 = new BeamCrossSection({
      name: "Tube section",
      parameter: {
        beamSectionType: sectionType.TUBE,
        radius: 0.5,
        thickness: 0.15,
      }
    });
    beamCrossSection6.default = true;
    beamCrossSection6.styleId = "BeamDef06";
    beamCrossSectionCache.saveStyle(beamCrossSection6);

    const beamCrossSection7 = new BeamCrossSection({
      name: "custom section",
      parameter: {
        beamSectionType: sectionType.CUSTOM,
        polyline: {
          isClosed: true,
          arcs: [],
          points: [
            { x: -0.2, y: -0.2, z: 0 },
            { x: 0, y: 0.2, z: 0 },
            { x: 0.2, y: -0.2, z: 0 },
          ],
        }
      }
    });
    beamCrossSection7.default = true;
    beamCrossSection7.styleId = "BeamDef07";
    beamCrossSectionCache.saveStyle(beamCrossSection7);
  }

  load(itemsSaved: Record<string, BeamCrossSection>) {
    for (const id in itemsSaved) {
      if (Object.prototype.hasOwnProperty.call(itemsSaved, id)) {
        const beamCSS = new BeamCrossSection(itemsSaved[id]);
        beamCSS.styleId = id;
        this.saveStyle(beamCSS);
      }
    }
  }

  isSectionInUse(id: string, graphicProc: GraphicProcessor) {
    const strucModel = graphicProc.getStructuralModelManager();
    for (const storey of strucModel.currBuilding.storeys) {
      for (const column of storey.columns) {
        if (column.definition.crossSectionId === id)
          return true;
      }
      for (const beam of storey.beams) {
        if (beam.definition.crossSectionId === id)
          return true;
      }
      for (const pileCap of storey.pileCaps) {
        if (pileCap.definition.pileSections === id)
          return true;
      }
    }
    return false;
  }
}
