const OffsetIcon = ({ ...props }) => {
  return (
    <svg
      className="h-5 w-5"
      viewBox="0 0 100 100"
      fill="currentColor"
      {...props}
    >
      <path d="M98.3334 80.8334H19.0834L15.1184 76.3633L15.0484 64.1467L20.0434 59.1666H36.6666C37.5883 59.1666 38.3332 58.4199 38.3332 57.5V23.19L42.3564 19.1668H59.3098L63.333 23.19V57.5C63.333 58.4199 64.0779 59.1666 64.9996 59.1666H98.333C99.2547 59.1666 99.9996 58.4199 99.9996 57.5C99.9996 56.5801 99.2547 55.8334 98.333 55.8334H66.6666V22.5C66.6666 22.0584 66.4916 21.6334 66.1783 21.3217L61.1783 16.3217C60.8666 16.0084 60.4434 15.8334 60 15.8334H41.6666C41.2232 15.8334 40.8 16.0084 40.4883 16.3217L35.4883 21.3217C35.175 21.6334 35 22.0584 35 22.5V55.8334H19.3551C18.9135 55.8334 18.49 56.0084 18.1783 56.3201L12.2016 62.2801C11.8865 62.5951 11.7082 63.0234 11.7115 63.4701L11.7883 77.0117C11.79 77.415 11.94 77.8051 12.2082 78.1084L17.0865 83.6084C17.4016 83.9617 17.8564 84.1668 18.3332 84.1668H98.3332C99.2549 84.1668 99.9998 83.4201 99.9998 82.5002C99.9998 81.5802 99.2551 80.8334 98.3334 80.8334Z" />
      <path d="M98.3334 92.5H14.0232L3.3332 81.8334V58.1901L14.0232 47.5H25C25.4434 47.5 25.8666 47.325 26.1783 47.0117C26.49 46.6985 26.6666 46.275 26.6666 45.8334L26.6615 19.9885L39.1482 7.50022H62.7682L75 19.8518V45.8334C75 46.7533 75.7449 47.5 76.6666 47.5H98.3332C99.2549 47.5 99.9998 46.7533 99.9998 45.8334C99.9998 44.9135 99.2549 44.1668 98.3332 44.1668H78.3332V19.1668C78.3332 18.7268 78.1598 18.3051 77.8516 17.9934L64.6482 4.65999C64.335 4.34495 63.9082 4.16663 63.4633 4.16663H38.4584C38.015 4.16663 37.5918 4.34163 37.2801 4.65491L23.8168 18.1199C23.5035 18.4332 23.3285 18.8567 23.3285 19.2983L23.3336 44.1666H13.3336C12.8902 44.1666 12.467 44.3416 12.1553 44.6549L0.488281 56.3217C0.175 56.6334 0 57.0584 0 57.5V82.5051C0 82.9268 0.159961 83.3334 0.448242 83.6418L12.1566 95.3469C12.4684 95.6586 12.8916 95.8336 13.3334 95.8336H98.3334C99.2551 95.8336 100 95.0869 100 94.167C100 93.2471 99.2551 92.5 98.3334 92.5Z" />
    </svg>
  );
};

export default OffsetIcon;
