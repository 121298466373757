const PointIcon = ({ ...props }) => {
  return (
    <svg
      className="h-5 w-5"
      viewBox="0 0 100 100"
      fill="currentColor"
      {...props}
    >
      <path d="M57.3844 48.8889H51.1111V42.1533H48.8889V48.8889H42.6156V51.1111H48.8889V57.8478H51.1111V51.1111H57.3844V48.8889Z" />
      <path d="M100 48.8889H83.3056C82.73 31.3656 68.6333 17.2711 51.1111 16.6944V0H48.8889V16.6944C31.3644 17.2689 17.27 31.3656 16.6944 48.8889H0V51.1111H16.6944C17.27 68.6367 31.3644 82.73 48.8889 83.3056V100H51.1111V83.3056C68.6333 82.7278 82.73 68.6344 83.3056 51.1111H100V48.8889ZM51.1111 79.4422V62.9867H48.8889V79.4433C33.4967 78.8711 21.1311 66.5022 20.56 51.1111H37.7778V48.8889H20.56C21.1311 33.4967 33.4967 21.1311 48.8889 20.56V37.0144H51.1111V20.56C66.5022 21.1333 78.8689 33.4978 79.44 48.8889H62.2222V51.1111H79.44C78.8689 66.5011 66.5022 78.8689 51.1111 79.4422Z" />
    </svg>
  );
};

export default PointIcon;
