import * as THREE from "three";
import { getCurrentSolidMaterial, ISolidMaterial, materialCache } from "lib/materials";
import { setPosRotEsc } from "../../geometries";
import { createBufferCube, createCube, cubeParam } from "../../geometries/solid/cube";
import { copyIPoint } from "../../math/point";
import { IPoint } from "../../math/types";
import { objDataType } from "../types";
import { SolidData } from "./solid";

export class CubeData extends SolidData {

  public type = objDataType.CUBE;
  protected nameObj: string = "Cube";
  public definition: cubeParam;

  constructor(definition: cubeParam, material?: ISolidMaterial) {
    super();
    this.definition = {
      side: definition.side,
      basePoint: copyIPoint(definition.basePoint),
      rotation: copyIPoint(definition.rotation),
      offset: copyIPoint(definition.offset),
      scale: copyIPoint(definition.scale),
    };
    this.material = material ?? getCurrentSolidMaterial();
  }
  static createObj(definition: cubeParam, material: ISolidMaterial) {
    const threeMaterial = materialCache.getSolidMaterial(material);
    return createCube(
      definition.side,
      threeMaterial,
      definition.basePoint,
      definition.rotation,
      definition.offset,
      definition.scale
    );
  }
  public createGraphicObj() {
    if (this.graphicObj) {
      console.warn("Attention: Cube graphic object already created!!");
      return;
    }
    this.graphicObj = CubeData.createObj(this.definition, this.material);
  }
  public cloneDefinition(): cubeParam {
    const def = super.cloneSolidDefinition() as cubeParam;
    def.side = this.definition.side;
    return def;
  }
  public createObject(definition?: cubeParam, material?: ISolidMaterial): THREE.Mesh {
    return CubeData.createObj(definition ?? this.definition, material ?? this.material);
  }
  public regenerateDefinition() {
    const geom = createBufferCube(this.definition.side);
    this.graphicObj.geometry = geom;
    const { basePoint, offset, rotation, scale } = this.definition;
    setPosRotEsc(this.graphicObj, basePoint, offset, rotation, scale);
  }
  public scale(factorX: number, factorY: number, factorZ: number, basePoint: IPoint): void {
    this.definition.side *= factorX;
    super.scale(factorX, factorY, factorZ, basePoint);
  }
}