import * as THREE from "three";
import { pointCreate } from "../../geometries/point";
import { IPoint } from "../../math/types";
import { copyIPoint } from "../../math/point";
import { cadOpType } from "../factory";
import { PointCommand } from "../../commands/primitives/point";
import { Cad3dOp } from "../base";
import { settingsOpModes } from "../step-operations";
import { getCurrentPointMaterial } from "lib/materials";

/** Creacion de multiples puntos
 *
 * @export
 * @class PointOP
 * @extends {CAD.OP.PlaneHandlerOP}
 */
export class PointOP extends Cad3dOp {

  public opType = cadOpType.POINTS;
  public pointsDef: IPoint[] = [];
  public auxPto: THREE.Points;

  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Insert point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
    }]);
  }

  public async start() {
    this.iniSettingsOp();
    this.auxPto = pointCreate(0, 0, 0);
    this.saveToTempScene(this.auxPto);
    this.initializeSnap();
    this.initializeWorkingPlane();
    this.registerCancel();
    this.registerInputs();
    this.registerUpdaters();
  }

  public setLastPoint(): void {
    const point = pointCreate(
      this.lastPoint.x,
      this.lastPoint.y,
      this.lastPoint.z
    );
    this.pointsDef.push(copyIPoint(this.lastPoint));
    this.saveToTempScene(point);
  }
  public moveLastPoint(pto: IPoint) {
    this.auxPto.position.set(pto.x, pto.y, pto.z);
  }

  public cancelOperation(): void {
    if (!this.finished) {
      this.endOperation();
    }
  }
  public endOperation(): void {
    if (!this.finished) {
      if (this.pointsDef.length > 0) this.save();
      this.pointsDef = [];
      super.endOperation();
    }
  }
  public save() {
    if (this.graphicProcessor && this.pointsDef.length > 0) {
      const command = new PointCommand(
        this.pointsDef,
        this.getCurrentSceneId(),
        this.graphicProcessor,
        getCurrentPointMaterial()
      );
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
}
