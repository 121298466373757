import * as THREE from "three";
import { IObjData } from "lib/models/objdata";
import { IPoint } from "lib/math/types";
import { lineAddVertex, lineAuxCreate } from "lib/geometries/line";
import { MultiEdition } from "../edition-multi";

/** Used by move, copy, rotate, escale, mirror, offset
 *
 * @export
 * @abstract
 * @class BasicMultiEdition
 * @extends {MultiEdition}
 */

export abstract class TransformEdition extends MultiEdition {

  public basePoint: IPoint | null = null;
  protected auxLine: THREE.Line;

  constructor(objs?: IObjData[], basePoint?: IPoint) {
    super(objs);
    this.basePoint = basePoint ?? null;
  }

  public async start() {
    this.iniSettingsOp();
    this.auxLine = lineAuxCreate();
    this.saveToTempScene(this.auxLine);
    this.registerCancel();

    this.setStartObjs();

    if (this.objDataOrigin.length === 0) {
      this.registerRaycast();
    } else {
      if (this.basePoint) {
        const { x, y, z } = this.basePoint;
        lineAddVertex(this.auxLine, x, y, z, 0);
        lineAddVertex(this.auxLine, x, y, z, 1);
        this.lastPoint = { x, y, z };
        this.setNextStep(2);
      } else {
        this.setNextStep();
      }
    }
  }

  public setLastPoint(): void {
    this.setNextStep();
  }
}
