/* tslint:disable */
/* eslint-disable */
/**
 * FreeCAD API
 * API for accesing functionalities based on FreeCAD.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: pablo.campillo@clerhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Task
 */
export interface Task {
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'Id': string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'ProjectId': string;
    /**
     * 
     * @type {WaffleGenerationRequestParams}
     * @memberof Task
     */
    'Params'?: WaffleGenerationRequestParams;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'CreatedAt'?: string;
    /**
     * 
     * @type {TaskStatus}
     * @memberof Task
     */
    'MPStatus'?: TaskStatus;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'UploadUrl'?: string;
}
/**
 * 
 * @export
 * @interface TaskAllOf
 */
export interface TaskAllOf {
    /**
     * 
     * @type {string}
     * @memberof TaskAllOf
     */
    'CreatedAt'?: string;
    /**
     * 
     * @type {TaskStatus}
     * @memberof TaskAllOf
     */
    'MPStatus'?: TaskStatus;
    /**
     * 
     * @type {string}
     * @memberof TaskAllOf
     */
    'UploadUrl'?: string;
}
/**
 * 
 * @export
 * @interface TaskId
 */
export interface TaskId {
    /**
     * 
     * @type {string}
     * @memberof TaskId
     */
    'Id': string;
}
/**
 * 
 * @export
 * @interface TaskStatus
 */
export interface TaskStatus {
    /**
     * 
     * @type {string}
     * @memberof TaskStatus
     */
    'MPValue': TaskStatusMPValueEnum;
    /**
     * 
     * @type {string}
     * @memberof TaskStatus
     */
    'ChangedAt'?: string;
}

export const TaskStatusMPValueEnum = {
    Running: 'RUNNING',
    Error: 'ERROR',
    Done: 'DONE',
    Canceled: 'CANCELED'
} as const;

export type TaskStatusMPValueEnum = typeof TaskStatusMPValueEnum[keyof typeof TaskStatusMPValueEnum];

/**
 * 
 * @export
 * @interface WaffleGenerationRequest
 */
export interface WaffleGenerationRequest {
    /**
     * 
     * @type {string}
     * @memberof WaffleGenerationRequest
     */
    'ProjectId': string;
    /**
     * 
     * @type {WaffleGenerationRequestParams}
     * @memberof WaffleGenerationRequest
     */
    'Params'?: WaffleGenerationRequestParams;
}
/**
 * 
 * @export
 * @interface WaffleGenerationRequestParams
 */
export interface WaffleGenerationRequestParams {
    /**
     * 
     * @type {Array<string>}
     * @memberof WaffleGenerationRequestParams
     */
    'WaffleNames'?: Array<string>;
}

/**
 * TasksApi - axios parameter creator
 * @export
 */
export const TasksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns all information about a Task.
         * @summary Returns a Task.
         * @param {string} id Id of the Task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tasksIdGet', 'id', id)
            const localVarPath = `/tasks/{Id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TasksApi - functional programming interface
 * @export
 */
export const TasksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TasksApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns all information about a Task.
         * @summary Returns a Task.
         * @param {string} id Id of the Task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TasksApi - factory interface
 * @export
 */
export const TasksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TasksApiFp(configuration)
    return {
        /**
         * Returns all information about a Task.
         * @summary Returns a Task.
         * @param {string} id Id of the Task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksIdGet(id: string, options?: any): AxiosPromise<Task> {
            return localVarFp.tasksIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TasksApi - object-oriented interface
 * @export
 * @class TasksApi
 * @extends {BaseAPI}
 */
export class TasksApi extends BaseAPI {
    /**
     * Returns all information about a Task.
     * @summary Returns a Task.
     * @param {string} id Id of the Task
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public tasksIdGet(id: string, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).tasksIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WaffleSlabsApi - axios parameter creator
 * @export
 */
export const WaffleSlabsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns an analysis project with an upload url.
         * @summary Returns an analysis project with an upload url.
         * @param {WaffleGenerationRequest} waffleGenerationRequest A Mesh Project Model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        waffleGeneratePost: async (waffleGenerationRequest: WaffleGenerationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'waffleGenerationRequest' is not null or undefined
            assertParamExists('waffleGeneratePost', 'waffleGenerationRequest', waffleGenerationRequest)
            const localVarPath = `/waffle/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(waffleGenerationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WaffleSlabsApi - functional programming interface
 * @export
 */
export const WaffleSlabsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WaffleSlabsApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns an analysis project with an upload url.
         * @summary Returns an analysis project with an upload url.
         * @param {WaffleGenerationRequest} waffleGenerationRequest A Mesh Project Model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async waffleGeneratePost(waffleGenerationRequest: WaffleGenerationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.waffleGeneratePost(waffleGenerationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WaffleSlabsApi - factory interface
 * @export
 */
export const WaffleSlabsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WaffleSlabsApiFp(configuration)
    return {
        /**
         * Returns an analysis project with an upload url.
         * @summary Returns an analysis project with an upload url.
         * @param {WaffleGenerationRequest} waffleGenerationRequest A Mesh Project Model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        waffleGeneratePost(waffleGenerationRequest: WaffleGenerationRequest, options?: any): AxiosPromise<TaskId> {
            return localVarFp.waffleGeneratePost(waffleGenerationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WaffleSlabsApi - object-oriented interface
 * @export
 * @class WaffleSlabsApi
 * @extends {BaseAPI}
 */
export class WaffleSlabsApi extends BaseAPI {
    /**
     * Returns an analysis project with an upload url.
     * @summary Returns an analysis project with an upload url.
     * @param {WaffleGenerationRequest} waffleGenerationRequest A Mesh Project Model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaffleSlabsApi
     */
    public waffleGeneratePost(waffleGenerationRequest: WaffleGenerationRequest, options?: AxiosRequestConfig) {
        return WaffleSlabsApiFp(this.configuration).waffleGeneratePost(waffleGenerationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


