import { GraphicProcessor } from "lib/graphic-processor";
import { IPoint } from "lib/math/types";
import { BlockData } from "lib/models/block";
import { cloneDataModel } from "lib/models/model-creator/datamodel-factory";
import { IObjData } from "lib/models/objdata";
import { ArcData } from "lib/models/primitives/arc";
import { CircleData } from "lib/models/primitives/circle";
import { EllipseData } from "lib/models/primitives/ellipse";
import { EllipseArcData } from "lib/models/primitives/ellipse-arc";
import { LineData } from "lib/models/primitives/line";
import { PointData } from "lib/models/primitives/point";
import { PolygonData } from "lib/models/primitives/polygon";
import { TextData } from "lib/models/text";
import { ArcCommand } from "../primitives/arc";
import { BlockRefCommand } from "../primitives/block";
import { CircleCommand } from "../primitives/circle";
import { EllipseCommand } from "../primitives/ellipse";
import { EllipseArcCommand } from "../primitives/ellipse-arc";
import { PointCommand } from "../primitives/point";
import { PolygonCommand } from "../primitives/polygon";
import { PolyLineCommand } from "../primitives/polyline";
import { TextCommand } from "../primitives/text";

export abstract class ObjDataCommandCreator {

  protected abstract createDataFromCommand(graphicProc: GraphicProcessor, objData: IObjData, layerId: string): IObjData;

  public executeCreate(graphicProc: GraphicProcessor, objData: IObjData, layerId: string) {
    return this.createDataFromCommand(graphicProc, objData, layerId);
  }

  protected abstract translateAndCreateDataFromCommand(graphicProc: GraphicProcessor, objData: IObjData, vector: IPoint, layerId: string): IObjData[];

  public executeTranslateAndCreate(graphicProc: GraphicProcessor, objData: IObjData, vector: IPoint, layerId: string) {
    return this.translateAndCreateDataFromCommand(graphicProc, objData, vector, layerId);
  }
}
export abstract class PrimitiveCommandCreator extends ObjDataCommandCreator {

  protected translateAndCreateDataFromCommand(graphicProc: GraphicProcessor, objData: IObjData, vector: IPoint, layerId: string) {
    const copyData = cloneDataModel(objData);
    copyData.translate(vector);
    const newObjData = this.createDataFromCommand(graphicProc, copyData, layerId);
    return [newObjData];
  }
}
export class PointCommandCreator extends PrimitiveCommandCreator {
  protected createDataFromCommand(graphicProc: GraphicProcessor, objData: PointData, layerId: string): PointData {
    const command = new PointCommand([objData.definition], layerId, graphicProc, objData.material);
    command.execute();
    const newObjData = command.createdData[0]
    command.delete();
    return newObjData;
  }
}
export class LineCommandCreator extends PrimitiveCommandCreator {
  protected createDataFromCommand(graphicProc: GraphicProcessor, objData: LineData, layerId: string): LineData {
    const command = new PolyLineCommand(objData.definition, layerId, graphicProc, objData.material);
    command.execute();
    const newObjData = command.createdData;
    command.delete();
    return newObjData;
  }
}
export class ArcCommandCreator extends PrimitiveCommandCreator {
  protected createDataFromCommand(graphicProc: GraphicProcessor, objData: ArcData, layerId: string): ArcData {
    const command = new ArcCommand(objData.definition, layerId, graphicProc, objData.material);
    command.execute();
    const newObjData = command.createdData;
    command.delete();
    return newObjData;
  }
}
export class PolygonCommandCreator extends PrimitiveCommandCreator {
  protected createDataFromCommand(graphicProc: GraphicProcessor, objData: PolygonData, layerId: string): PolygonData {
    const command = new PolygonCommand(objData.definition, layerId, graphicProc, objData.material);
    command.execute();
    const newObjData = command.createdData;
    command.delete();
    return newObjData;
  }
}
export class CircleCommandCreator extends PrimitiveCommandCreator {
  protected createDataFromCommand(graphicProc: GraphicProcessor, objData: CircleData, layerId: string): CircleData {
    const command = new CircleCommand(objData.definition, layerId, graphicProc, objData.material);
    command.execute();
    const newObjData = command.createdData;
    command.delete();
    return newObjData;
  }
}
export class EllipseCommandCreator extends PrimitiveCommandCreator {
  protected createDataFromCommand(graphicProc: GraphicProcessor, objData: EllipseData, layerId: string): EllipseData {
    const command = new EllipseCommand(objData.definition, layerId, graphicProc, objData.material);
    command.execute();
    const newObjData = command.createdData;
    command.delete();
    return newObjData;
  }
}
export class EllipseArcCommandCreator extends PrimitiveCommandCreator {
  protected createDataFromCommand(graphicProc: GraphicProcessor, objData: EllipseArcData, layerId: string): EllipseArcData {
    const command = new EllipseArcCommand(objData.definition, layerId, graphicProc, objData.material);
    command.execute();
    const newObjData = command.createdData;
    command.delete();
    return newObjData;
  }
}
export class TextCommandCreator extends PrimitiveCommandCreator {
  protected createDataFromCommand(graphicProc: GraphicProcessor, objData: TextData, layerId: string): TextData {
    const command = new TextCommand(objData.definition, layerId, graphicProc);
    command.execute();
    const newObjData = command.createdData;
    command.delete();
    return newObjData;
  }
}
export class BlockCommandCreator extends PrimitiveCommandCreator {
  protected createDataFromCommand(graphicProc: GraphicProcessor, objData: BlockData, layerId: string): BlockData {
    const command = new BlockRefCommand(objData.definition, layerId, graphicProc);
    command.execute();
    const newObjData = command.createdData;
    command.delete();
    return newObjData;
  }
}
