import { useEffect } from "react";
import styled from "styled-components";

import LoadingDots from "shared/components/ui/loading-dots";
import Page from "../components/ui/page";

import { PrimaryLink } from "shared/components/ui/link";
import { useUI } from "shared/components/ui/context";
import { useAuth } from "shared/components/auth/context";

import { useGetStrucProjectsList } from "../components/projects/hooks/use-projects";
import ProjectItemLink from "../components/projects/project-link";
import { PrimaryButton } from "shared/components/ui/button";
import ChevronRightIcon from "shared/components/icons/chevron-right";
import ChevronLeftIcon from "shared/components/icons/chevron-left";

const StrucProjects = () => {

  const { user } = useAuth();
  const { openToast, closeToast } = useUI();

  const {
    error,
    iniProjsIsLoading,
    nextProjsIsLoading,
    projects,
    canPreviousPage,
    canNextPage,
    goToNextPage,
    goToPrevPage,
  } = useGetStrucProjectsList(user!.id);

  useEffect(() => {
    if (error) {
      openToast({
        title: 'Error loading projects v0.2.',
        status: 'error',
      })
    }
  }, [error, openToast])


  return (
    <Page
      title="Projects"
      actions={
        <PrimaryLink
          onClick={() => { closeToast() }}
          to="/struc/projects/new">
          New project
        </PrimaryLink>
      }
    >
      {iniProjsIsLoading && (
        <div className="flex flex-col justify-center items-center h-72">
          <LoadingDots />
        </div>
      )}
      {(!iniProjsIsLoading && projects) && (
        <div className="flex items-center gap-5">
          <PrimaryButton className={"h-10 w-20"} onClick={goToPrevPage} disabled={!canPreviousPage}>
            <ChevronLeftIcon />
          </PrimaryButton>

          <Wrapper>
            {projects.map(({ projectData, snapShots }) => (
              <ProjectItemLink
                key={projectData.Id}
                version={projectData.DataModelVersion}
                id={projectData.Id}
                name={`${projectData.DataModelVersion} ${projectData.Name}`}
                updatedAt={projectData.UpdatedAt}
                snapshot={snapShots}
                projectsNameList={projects.map(p => p.projectData.Name)}
              />
            ))}
          </Wrapper>

          <PrimaryButton className={"h-10 w-20"} onClick={goToNextPage} disabled={!canNextPage} loading={nextProjsIsLoading}>
            <ChevronRightIcon />
          </PrimaryButton>
        </div>
      )}
    </Page>
  );
};

const Wrapper = styled.div`
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
  gap: 1.25rem;
  user-select: none;
  width: 100%;
  flex-flow: row wrap;
  align-content: stretch;
  cursor: default;
`;

export default StrucProjects;
