import { StrucStorey } from "modules/struc/models/project";
import { euConcreteType, standard, usConcreteType } from "modules/struc/models/codes";
import { useRef } from "react";
import Section from "shared/components/ui/section";
import Input from "../../../../lite/components/ui/input";
import StrucStoreyList from "../../storey/storey-list";
import StoreyBulkUploader from "../../storey/storey-bulk-uploader";
import { PrimaryButton } from "shared/components/ui/button";
import BulkUploadIcon from "shared/components/icons/bulk-upload";
import CollectionIcon from "shared/components/icons/collection";
import TrashIcon from "shared/components/icons/trash";
import { useStoreysForm } from "./hook/use-project-storeys";

interface Props {
  concreteStandard: standard;
  concreteTypes: euConcreteType[] | usConcreteType[];
  footingLevel: number;
  editForm: boolean;
  register: any;
  storeys: StrucStorey[];
  setStoreys: (val: StrucStorey[]) => void;
}

const StoreysForm = ({
  footingLevel,
  editForm,
  register,
  storeys,
  concreteStandard,
  concreteTypes,
  setStoreys,
}: Props) => {

  const uploadCopies = useRef<number>(1);

  const {
    uploadBulkDXF,
    uploadDXF,
    recalculateStoreys,
    sortStoreys,
    updateStorey,
    changeName,
    deleteStorey,
    copyStorey,
  } = useStoreysForm(footingLevel, concreteStandard, storeys, setStoreys);

  return (
    <Section title="Storeys" isCollapsible={true}>
      {!editForm &&
        <div className="grid grid-cols-8 gap-6">
          <div className="col-span-2">
            <Input
              type="number"
              defaultValue={footingLevel}
              id="footing_level"
              label="Footing level"
              name="FootingLevel"
              ref={register({
                required: true,
                valueAsNumber: true,
              })}
              onChange={ev => recalculateStoreys()}
              dark
            />
          </div>

          <StoreyBulkUploader
            uploadFile={(file) => uploadBulkDXF(file)}
            isMultiple={true} >
            <BulkUploadIcon />
            Massive DXF upload
          </StoreyBulkUploader>

          <StoreyBulkUploader
            uploadFile={(file) => uploadDXF(file[0], uploadCopies.current)}
            isMultiple={false} >
            <BulkUploadIcon />
            upload DXF
          </StoreyBulkUploader>

          <Input
            className="col-span-1"
            type="number"
            min="1"
            defaultValue={uploadCopies.current}
            id="fileCopies"
            label="Upload DXF copies"
            onChange={ev => { uploadCopies.current = +ev.target.value; }}
            dark
          />

          <PrimaryButton type="button" onClick={() => uploadDXF(undefined, 1)}>
            <CollectionIcon className="w-6 h-6" />
            Add Storey
          </PrimaryButton>

          <PrimaryButton type="button" onClick={() => setStoreys([])}>
            <TrashIcon className="w-8 h-8" />
            Delete all storeys
          </PrimaryButton>

        </div>}

      {storeys.length > 0 &&
        <StrucStoreyList
          className={editForm ? "pointer-events-none" : ""}
          storeys={storeys}
          changeName={changeName}
          onChange={updateStorey}
          onDrop={sortStoreys}
          onDelete={!editForm ? deleteStorey : undefined}
          onCopy={!editForm ? copyStorey : undefined}
          concreteTypes={concreteTypes}
        />
      }
    </Section>
  );
};

export default StoreysForm;
