import { IPoint } from "lib/math/types";
import { IPolylineParam } from "lib/math/line";
import { originPoint } from "lib/models-struc/types/struc-base";
import { columnParam } from "lib/models-struc/types/column";
import { createExtrusionMesh, updateExtrusionMesh } from "./extrusion";
import { generateRectangularSection, getBoundingBoxSection } from "lib/models-struc/cross-sections-shape/beam-cross-sections/utils";
import { calculateOffsetBuffer0 } from "lib/math/offset-buffer";
import ClipperLib from "lib/helpers/clipper";

export function createFooter(lenght: number, width: number, depth: number, material?: THREE.MeshPhongMaterial) {
  const contourSection: IPoint[] = generateRectangularSection(lenght, width, originPoint.CENTERCENTER);
  const mesh = createExtrusionMesh(contourSection, depth, undefined, material);
  mesh.geometry.translate(0, 0, -depth);
  return mesh;
}
export function updateFooter(mesh: THREE.Mesh, lenght: number, width: number, depth: number): void {
  const contourSection: IPoint[] = generateRectangularSection(lenght, width, originPoint.CENTERCENTER);
  updateExtrusionMesh(mesh, contourSection, depth);
  mesh.geometry.translate(0, 0, -depth);
}
export function getFooterLenghtAndWidthFromColumn(distance: number, col: columnParam): { length: number, width: number } {
  let len = 0, wid = 0;
  const bbx = getBoundingBoxSection(col.crossSectionId);
  const distX = (bbx.max.x - bbx.min.x);
  const distY = (bbx.max.y - bbx.min.y);
  len = distX + (2 * distance);
  wid = distY + (2 * distance);
  return { length: len, width: wid }
}

export function createStripFooter(wallPtos: IPolylineParam, width: number, depth: number, material?: THREE.MeshPhongMaterial) {
  const res = calculateOffsetBuffer0(wallPtos, width * 0.5, ClipperLib.EndType.etOpenSquare);
  const contour = res.shift()?.points as IPoint[];
  const holes = res.map(r => r.points) as IPoint[][];
  const mesh = createExtrusionMesh(contour, depth, holes, material);
  mesh.geometry.translate(0, 0, -depth);
  return mesh;
}
export function updateStripFooter(mesh: THREE.Mesh, wallPtos: IPolylineParam, width: number, depth: number): void {
  const res = calculateOffsetBuffer0(wallPtos, width * 0.5, ClipperLib.EndType.etOpenSquare);
  const contour = res.shift()?.points as IPoint[];
  const holes = res.map(r => r.points) as IPoint[][];
  updateExtrusionMesh(mesh, contour, depth, holes);
  mesh.geometry.translate(0, 0, -depth);
}