import { baseUriModel } from "./mesh-exporter";
import { loadType } from "lib/models-struc/types/load";
import { GeoRepresentation as GeoRepresentationEcore } from "modules/struc/models/ecore/representation";
import { Vector as VectorEcore } from "modules/struc/models/ecore/location";
import { Point as PointEcore, WireGeometry as WireGeometryEcore } from "modules/struc/models/ecore/geometry";
import { getEcoreFloat } from "./helper-ecore";
import { getEcorePlacement, getEcoreVector3 } from "./locations";
import { getEcorePointGeometry, getEcoreOpenWireGeometry, getEcoreClosedWireGeometry } from "./geometry";
import { IEcoreBuildingElementExport } from "./struc-elem-base";
import { ILoad as ILoadEcore } from "modules/struc/models/ecore/analysis";
import { femStructuralManager } from "lib/models-struc/mesh/femstructural-manager";
import { LoadStructuralData } from "lib/models/structural/load";
import { windhypothesisManager } from "lib/models-struc/hypothesis/wind";
import { windSubTypes } from "lib/models-struc/hypothesis/hypothesis";
import { representationUriModel } from "modules/struc/models/ecore/uri";

export class EcoreLoadElem {

  private strucData: LoadStructuralData;
  private parentElem: IEcoreBuildingElementExport;

  setStrucElem(strData: LoadStructuralData, parentElem: IEcoreBuildingElementExport) {
    this.strucData = strData;
    this.parentElem = parentElem;
  }

  exportToEcore(): ILoadEcore {
    const repr = this.strucData.definition;
    const loadEcore: ILoadEcore = {
      eClass: baseUriModel + "analysis/Load",
      vector: this.getEcoreDirection(),
      geometry: repr.externalGeo ? [this.getEcoreReprentationLoad()] : [],
      element: femStructuralManager.getFEMStructuralElemRef(this.strucData),
    };
    const value = getEcoreFloat(Math.abs(repr.loadValue));
    if (value !== undefined) loadEcore.value = value;
    return loadEcore;
  }

  private getEcoreDirection(): VectorEcore {
    const hypoId = this.strucData.definition.hypothesisId;
    const windHypo = windhypothesisManager.getWindHypothesisById(hypoId);
    if (windHypo) {
      if (windHypo.subType === windSubTypes.WX1P) return getEcoreVector3({ x: 1, y: 0, z: 0 });
      if (windHypo.subType === windSubTypes.WX2P) return getEcoreVector3({ x: 1, y: 0, z: 0 });
      if (windHypo.subType === windSubTypes.WX1N) return getEcoreVector3({ x: -1, y: 0, z: 0 });
      if (windHypo.subType === windSubTypes.WX2N) return getEcoreVector3({ x: -1, y: 0, z: 0 });
      if (windHypo.subType === windSubTypes.WY1P) return getEcoreVector3({ x: 0, y: 1, z: 0 });
      if (windHypo.subType === windSubTypes.WY2P) return getEcoreVector3({ x: 0, y: 1, z: 0 });
      if (windHypo.subType === windSubTypes.WY1N) return getEcoreVector3({ x: 0, y: -1, z: 0 });
      return getEcoreVector3({ x: 0, y: -1, z: 0 }); // windHypo.subType === windSubTypes.WY2N 
    } else {
      return this.parentElem.getEcoreDirection();
    }
  }

  private getEcoreReprentationLoad(): GeoRepresentationEcore<PointEcore | WireGeometryEcore> {
    const repr = this.strucData.definition;
    return {
      eClass: `${representationUriModel}GeoRepresentation`,
      placement: getEcorePlacement(repr.basePoint),
      base: this.getEcoreGeometryLoad(),
    }
  }

  private getEcoreGeometryLoad(): PointEcore | WireGeometryEcore {
    const repr = this.strucData.definition;
    switch (repr.type) {
      case loadType.CONCENTRATED:
        return getEcorePointGeometry({ x: 0, y: 0, z: 0 });
      case loadType.LINEAL:
        return getEcoreOpenWireGeometry(repr.ptos2D);
      case loadType.SUPERFICIAL:
        return getEcoreClosedWireGeometry(repr.ptos2D);
    }
  }
}
