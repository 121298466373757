import { useCallback } from "react";
import styled from "styled-components";
import { canvasOptions, GraphicProcessor } from '../../../../lib/graphic-processor';
import { useCoordinatePosition } from "../position/hooks/use-position";
import { DynamicInput } from "../steps/dynamic-input";
import useViewportLite from "./hooks/use-viewport-lite";

function cleanFields() {
  const activeElement = document.activeElement as HTMLInputElement | HTMLSelectElement | HTMLButtonElement;
  const tagName = document.activeElement?.tagName;
  if (tagName === "INPUT" || tagName === "SELECT" || tagName === "BUTTON") {
    activeElement.blur();
  }
}

const VieportWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr;
`;

const Wrapper = styled.section`
  z-index: 0;
  position: relative;
  overflow: hidden;
`;

const ViewportLite = ({ graphicProc, options }: { graphicProc: GraphicProcessor, options?: canvasOptions }) => {

  const { status, mountContainer, unMountContainer } = useViewportLite(graphicProc, options);

  const usePosition = useCoordinatePosition(graphicProc);

  const setContainerRef = useCallback((node: HTMLDivElement) => {
    if (node) {
      mountContainer(node);
    } else {
      unMountContainer();
    }
  }, [])

  return (
    <VieportWrapper>
      <Wrapper id="viewportLite" ref={setContainerRef} onMouseEnter={cleanFields}>
        {status === "mounted" &&
          <DynamicInput graphicProc={graphicProc} usePosition={usePosition} />
        }
        {/* {status === "mounted" && <PixelInfo viewportRef={ref.current} />} */}
      </Wrapper>
      {/* <Console /> */}
      {/* {status === "mounted" && <FooterToolbar graphicProc={graphicProc} />} */}
    </VieportWrapper>
  );
}

export default ViewportLite;
