import * as THREE from "three";
import { moveObj } from "./geometries/translation";
import { mulIpoint } from "./math/point";
import { IPoint } from "./math/types";
import { getDataBuilder } from "./models/model-creator/datamodel-factory";
import { definitionType, materialType, objDataType } from "./models/types";

interface Indexable {
  id: number,
  name: string,
}

export interface blockItem {
  itemType: objDataType,
  itemDef: definitionType,
  material?: materialType,
}

export interface blockParam extends Indexable {
  blockItems: blockItem[],
  basePoint: IPoint,
  rotation?: IPoint,
  scale?: IPoint,
}

export interface blockRefParam {
  blockId: number,
  position: IPoint,
  rotation: IPoint,
  scale: IPoint,
}

class GenericCacheHandler<T extends Indexable>{

  private mapItems: Map<number, T> = new Map();

  public getById(id: number): T | undefined {
    return this.mapItems.get(id);
  }

  public getByName(name: string): T | undefined {
    for (let value of this.mapItems.values()) {
      if (value.name === name) {
        return value;
      }
    }
  }

  public getAllData(): T[] {
    return [...this.mapItems.values()];
  }

  public set(item: T): number {
    const currentIndex = (this.mapItems.size);
    item.id = currentIndex;
    this.mapItems.set(currentIndex, item);
    return currentIndex;
  }

  public setById(item: T): void {
    this.mapItems.set(item.id, item);
  }
}

export class BlockManager {

  private static blocks: GenericCacheHandler<blockParam> = new GenericCacheHandler();

  public static createBlock(block: blockParam): number {
    return BlockManager.blocks.set(block);
  }

  public static getBlockDefById(blockId: number): blockParam | undefined {
    return BlockManager.blocks.getById(blockId);
  }

  public static getBlockDefByName(name: string): blockParam | undefined {
    return BlockManager.blocks.getByName(name);
  }

  public static getBlockGraphicObj(blockId: number): THREE.Group {
    const group = new THREE.Group();
    const block = BlockManager.blocks.getById(blockId);
    if (block) {
      block.blockItems.forEach(obj => {
        const threeObj = getDataBuilder(obj.itemType, obj.itemDef, obj.material);
        if (threeObj) {
          moveObj(threeObj, mulIpoint(block.basePoint, -1));
          group.add(threeObj);
        }
      });
    }
    return group;
  }

  public static getBlocksDefinition(): blockParam[] {
    return BlockManager.blocks.getAllData();
  }

  public static setBlocksDefinition(blocks: blockParam[]): void {
    if (blocks) {
      blocks.forEach(b => {
        BlockManager.blocks.setById(b);
      });
    }
  }
}