import { useCallback, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useUI } from "shared/components/ui/context";
import { useAuth } from "shared/components/auth/context";
import { fetchDeleteProject } from "lib/models-struc/infrastructure/delete-project";
import { userMessageEvents } from "lib/events/user-messages";

type Status = "idle" | "loading" | "success" | "error";

export function useDeleteProject() {

  const queryClient = useQueryClient();

  const { user } = useAuth();
  const { closeModal, modalData } = useUI();
  const [status, setStatus] = useState<Status>("idle");

  const mutation = useMutation((variables: { userId: string, projectId: string }) => {
    return fetchDeleteProject(variables.userId, variables.projectId);
  }, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['GET_STRUC_PROJECTS']);
      // const query = queryClient.getQueriesData<projectListResponse[]>(['GET_STRUC_PROJECTS']);
      // const state = query[0][1].filter(h => h.projectData.Id !== data.Id);
      // queryClient.setQueryData(["GET_STRUC_PROJECTS"], () => [...state]);
    }
  })

  const deleteProject = useCallback(async () => {
    const { id } = modalData as { id: string, version: string };
    const projectId = id;
    const userId = user!.id;
    try {
      setStatus('loading');
      await mutation.mutateAsync({ userId, projectId });
      userMessageEvents.dispatchSucces("Project successfully deleted");
      setStatus("success");

    } catch (error) {
      userMessageEvents.dispatchError("Delete project failed");
      setStatus("error");

    } finally {
      closeModal();
    }

  }, [modalData, user, mutation, closeModal]);

  return { deleteProject, status };
}
