const RedoIcon = ({ ...props }) => {
  return (
    <svg className="w-5 h-5" fill="none" viewBox="0 0 100 100" {...props}>
      <path
        d="M80.3437 42.8438L61.5937 61.5937C61.3032 61.8866 60.9576 62.1191 60.5768 62.2778C60.196 62.4364 59.7875 62.5181 59.375 62.5181C58.9625 62.5181 58.554 62.4364 58.1732 62.2778C57.7924 62.1191 57.4468 61.8866 57.1563 61.5937C56.8633 61.3032 56.6309 60.9576 56.4722 60.5768C56.3136 60.196 56.2319 59.7875 56.2319 59.375C56.2319 58.9625 56.3136 58.554 56.4722 58.1732C56.6309 57.7924 56.8633 57.4468 57.1563 57.1562L70.5938 43.75H40.625C36.481 43.75 32.5067 45.3962 29.5765 48.3265C26.6462 51.2567 25 55.231 25 59.375C25 63.519 26.6462 67.4933 29.5765 70.4235C32.5067 73.3538 36.481 75 40.625 75H50C50.8288 75 51.6237 75.3292 52.2097 75.9153C52.7958 76.5013 53.125 77.2962 53.125 78.125C53.125 78.9538 52.7958 79.7487 52.2097 80.3347C51.6237 80.9208 50.8288 81.25 50 81.25H40.625C34.8234 81.25 29.2594 78.9453 25.157 74.843C21.0547 70.7406 18.75 65.1766 18.75 59.375C18.75 53.5734 21.0547 48.0094 25.157 43.907C29.2594 39.8047 34.8234 37.5 40.625 37.5H70.5938L57.1563 24.0938C56.5678 23.5053 56.2372 22.7072 56.2372 21.875C56.2372 21.0428 56.5678 20.2447 57.1563 19.6562C57.7447 19.0678 58.5428 18.7372 59.375 18.7372C60.2072 18.7372 61.0053 19.0678 61.5937 19.6562L80.3437 38.4062C80.6367 38.6968 80.8691 39.0424 81.0278 39.4232C81.1864 39.804 81.2681 40.2125 81.2681 40.625C81.2681 41.0375 81.1864 41.446 81.0278 41.8268C80.8691 42.2076 80.6367 42.5532 80.3437 42.8438Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default RedoIcon;
