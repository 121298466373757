import { GraphicProcessor } from "lib/graphic-processor";
import { AngleDimensionData, angleDimensionParam } from "lib/models/dimension/angle-dim";
import { objDataType } from "lib/models/types";
import { cadOpType } from "lib/operations/factory";
import { EditDataCommand } from "../edition/edit-base";
import { createCommand } from "../primitives/create-base";

export class AngleDimensionCommand extends createCommand {

  public createdData: AngleDimensionData;
  protected opType = cadOpType.ANGLEDIM;
  protected objType = objDataType.ANGULARDIM;
  protected param: angleDimensionParam;
  protected material: undefined;

  constructor(params: angleDimensionParam, layerId: string, graphicProc: GraphicProcessor) {
    super(layerId, graphicProc);
    this.param = params;
  }
}

export class AngleDimensionEditDataCommand extends EditDataCommand {
  
  protected opType = cadOpType.EDITANGLEDIM;

  constructor(obj: AngleDimensionData, newDefinition: angleDimensionParam | null, graphicProcessor: GraphicProcessor) {
    super(obj, newDefinition, null, graphicProcessor);
  }
}