
import { useEffect, useReducer } from "react";
import { CompositeNode } from "lib/helpers/composite-tree";
import { ShellCrossSection } from "lib/models-struc/cross-sections-shape/shell-cross-sections/shellcs-shapes";
import { shellCrossSectionCache } from "lib/models-struc/cross-sections-shape/shell-cross-sections/cache";
import { getShellCrossSectionInfoprop } from "lib/models-struc/cross-sections-shape/shell-cross-sections/types";
import { cacheAction, CacheActionType } from "lib/styles/style-cache";
import { IPanelSetting, itemType, ShellCSItem } from "modules/cad/components/sidebar/context";

interface shellCrossSectionState {
  shellCSNodes: CompositeNode<ShellCSItem>;
}

function buildShellCrossSectionTree(): shellCrossSectionState {
  const scsList = shellCrossSectionCache.getAllLoadedStyles().sort((a, b) => a.name.localeCompare(b.name));
  const scsNode = new CompositeNode("Shell cross section", { type: itemType.SHELLCS } as ShellCSItem);
  for (const scs of scsList) {
    const node = scsNode.addChildLeaf(scs.name, { info: scs, type: itemType.SHELLCS });
    node.id = scs.styleId;
  }
  return { shellCSNodes: scsNode };
}

function shellReducer(state: shellCrossSectionState, action: cacheAction<ShellCrossSection>): shellCrossSectionState {
  switch (action.type) {
    case CacheActionType.DELETE_CACHE: {
      const { id } = action.payload;
      const node = state.shellCSNodes.getNodeById(id);
      if (node) {
        state.shellCSNodes.removeChild(node);
      }
      return { shellCSNodes: state.shellCSNodes.cloneTree() };
    }
    case CacheActionType.SAVE_CACHE: {
      const { id, style } = action.payload;
      if (!state.shellCSNodes.getNodeById(id)) {
        const node = state.shellCSNodes.addChildLeaf<ShellCSItem>(style.name, { info: style, type: itemType.SHELLCS });
        node.id = id;
      }
      return { shellCSNodes: state.shellCSNodes.cloneTree() };
    }
    case CacheActionType.UPDATE_CACHE: {
      const { id, style } = action.payload;
      const node = state.shellCSNodes.getNodeById<ShellCSItem>(id);
      if (node) {
        node.props = { info: style, type: itemType.SHELLCS }
        if (node.name !== style.name) {
          node.name = style.name;
          return { shellCSNodes: state.shellCSNodes.cloneTree() };
        }
      }
      return { shellCSNodes: state.shellCSNodes.cloneTree() };
    }
    default:
      throw new Error(`Action is not defined.`);
  }
}

export function useShellCrossSections() {

  const [shellState, shellDispatch] = useReducer(shellReducer, {}, buildShellCrossSectionTree);

  useEffect(() => {
    shellCrossSectionCache.subscribe(shellDispatch);
    return () => {
      shellCrossSectionCache?.unsubscribe(shellDispatch);
    }
  }, []);

  return {
    shellCrossSectionsNodes: shellState.shellCSNodes,
  }
}

export const getPropSettingsFromShellCS = (shellCSParams: ShellCrossSection): IPanelSetting<ShellCrossSection> => {
  return {
    title: "Shell cross section",
    setting: {
      propValue: getShellCrossSectionInfoprop(shellCSParams),
      propCallback: (s) => {
        shellCrossSectionCache.updateStyle(shellCSParams.styleId, s)
      }
    }
  }
}