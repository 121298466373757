import { GraphicProcessor } from "lib/graphic-processor";
import { isLoadData, isStructData } from "lib/models/checktools";
import { IObjData } from "lib/models/objdata";
import { DeleteConsolidation } from "../checkers.ts/struc-consolidate";
import { hypothesisManager } from "../hypothesis/hypothesismodel-manager";

export class RemoveObjDataFromLayer {

  static run(data: IObjData, graphicProcessor: GraphicProcessor) {

    const selectionMng = graphicProcessor.getSelectionManager();
    const dataModel = graphicProcessor.getDataModelManager();
    const layerManager = dataModel.layerManager;

    if (!dataModel.isRegister(data)) {
      console.warn(`[REMOVE_OBJ] Object ${data.objName} not register`);
      return;
    }

    selectionMng.unSelectObjData(data);
    layerManager.removeObjDataFromLayer(data);
    dataModel.unregisterData(data);

    if (isLoadData(data)) {
      const strcMdlManager = graphicProcessor.getStructuralModelManager();
      strcMdlManager.unregisterLoadData(data);

      const indx = data.parentStrucElem.loads.indexOf(data);
      data.parentStrucElem.loads.splice(indx, 1);

      hypothesisManager.deletedLoad(data);
    }

    if (isStructData(data)) {
      const strcMdlManager = graphicProcessor.getStructuralModelManager();
      strcMdlManager.unregisterStrucData(data);

      // Delete loads
      for (let load of data.loads) {
        if (!dataModel.isRegister(load)) {
          console.warn(`[REMOVE_OBJ] Object ${load.objName} not register`);
          return;
        }
        selectionMng.unSelectObjData(load);
        layerManager.removeObjDataFromLayer(load);
        dataModel.unregisterData(load);
        strcMdlManager.unregisterLoadData(load);
        hypothesisManager.deletedLoad(load);
      }

      data.deleteAxisHelper();
      DeleteConsolidation(data, graphicProcessor);
    }
  }
}