import * as THREE from "three";
import { IPoint } from "lib/math/types";
import { createExtrusionMesh, updateExtrusionMesh } from "./extrusion";
import { vectorDist3D } from "lib/math/distance";
import { growthWidth, originPoint } from "lib/models-struc/types/struc-base";
import { beamCrossSectionCache } from "lib/models-struc/cross-sections-shape/beam-cross-sections/cache";
import { generatePointsBeamCrossSection } from "lib/models-struc/cross-sections-shape/beam-cross-sections/utils";

export function createBeam(points: IPoint[], crossSectionId: string, growthType: growthWidth, material?: THREE.MeshPhongMaterial) {
  const section = beamCrossSectionCache.loadStylefromCache(crossSectionId)!;
  let origin = originPoint.TOPCENTER;
  switch (growthType) {
    case growthWidth.TOLEFT: origin = originPoint.TOPLEFT; break;
    case growthWidth.TORIGHT: origin = originPoint.TOPRIGHT; break;
  }
  const { contour, holes } = generatePointsBeamCrossSection(section, origin);
  const dist = points.length > 1 ? vectorDist3D(points[0], points[1]) : 0;
  const threeObj = createExtrusionMesh(contour, dist, [holes], material);
  threeObj.geometry.rotateY(Math.PI * 0.5);
  threeObj.geometry.rotateX(Math.PI * 0.5);
  return threeObj;
}

export function updateBeam(mesh: THREE.Mesh, points: IPoint[], crossSectionId: string, growthType: growthWidth) {
  const section = beamCrossSectionCache.loadStylefromCache(crossSectionId)!;
  let origin = originPoint.TOPCENTER;
  switch (growthType) {
    case growthWidth.TOLEFT: origin = originPoint.TOPLEFT; break;
    case growthWidth.TORIGHT: origin = originPoint.TOPRIGHT; break;
  }
  const { contour, holes } = generatePointsBeamCrossSection(section, origin);
  const dist = points.length > 1 ? vectorDist3D(points[0], points[1]) : 0;
  updateExtrusionMesh(mesh, contour, dist, [holes]);
  mesh.geometry.rotateY(Math.PI * 0.5);
  mesh.geometry.rotateX(Math.PI * 0.5);
}