import OrthoModeIcon from "../icons/ortho-mode";
import ToolbarButton from "../toolbar/toolbar-button";
import { useSnap } from "./hooks/use-snap";

const OrthoModeButton = () => {
  const { isOrthoModeActive, toggleOrthoMode } = useSnap();
  return (
    <ToolbarButton
      icon={<OrthoModeIcon className="h-5 w-5" />}
      onClick={toggleOrthoMode}
      tooltip="Ortho Mode"
      isActive={isOrthoModeActive}
    />
  );
};

export default OrthoModeButton;
