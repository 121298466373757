import { LoadEditDataCommand } from "lib/commands/structural/load"
import { linealPrecision } from "lib/general-settings"
import { IPoint } from "lib/math/types"
import { hypothesisManager } from "lib/models-struc/hypothesis/hypothesismodel-manager"
import { loadParam, loadType } from "lib/models-struc/types/load"
import { LoadStructuralData } from "lib/models/structural/load"
import { DataDefinitionHandler } from "../base"

export type loadProp = loadParam & { centroid: IPoint; }

const loadVAlueUnits = {
  [loadType.CONCENTRATED]: "kN",
  [loadType.LINEAL]: "kN/m",
  [loadType.SUPERFICIAL]: "kN/m²",
}

export class LoadDataDefinitionHandler extends DataDefinitionHandler<loadProp, undefined, undefined, loadParam> {

  protected data: LoadStructuralData

  protected buildInfoProperties() {
    this.fillLoadDefinition(this.data);
    this.structuralInfo = {};
    this.fillLoadStructural(this.data);
  }

  private fillLoadDefinition(data: LoadStructuralData) {
    const def = data.definition;
    const isEditable = !data.isDefaultSlabLoad;
    this.definitionInfo = {
      basePoint: this.getPointView(def.basePoint, "Base point", "m", linealPrecision, isEditable),
    }
  }

  private fillLoadStructural(data: LoadStructuralData) {
    const def = data.definition;
    const strMdlMng = this.graphicProcessor.getStructuralModelManager();
    const parentElem = strMdlMng.getStructElemFromId(def.parentStructElemId);

    const hypo = hypothesisManager.getHypothesisByid(def.hypothesisId);
    if (hypo) {
      const hypothesisList: [string, string][] = hypothesisManager.getAllHypothesis().map(h => [h.name, h.uid]);
      this.structuralInfo.hypothesisId = { publicName: "Hypothesis", type: "tagList", tagList: hypothesisList, value: def.hypothesisId };
    }
    this.structuralInfo.name = this.getStringView(def.name, "Name");
    this.structuralInfo.parentStructElemId = this.getStringView(parentElem?.strucName ?? "Empty", "Parent", false);
    this.structuralInfo.loadValue = {
      type: "number",
      publicName: "Load value",
      value: def.loadValue / 1000,
      editable: true,
      precision: linealPrecision,
      units: hypo ? loadVAlueUnits[def.type] : "kN",
      parseFun: (v: number) => v * 1000,
    }
    this.structuralInfo.externalGeo = this.getBooleanView(def.externalGeo, "External geometry", true);
  }

  private checkNewDefinition(newDefinition: loadProp): boolean {
    if (!newDefinition) { return false; }
    if (!this.checkPoint(newDefinition.basePoint)) { return false; }
    return true;
  }

  private changedNewDefinition(oldDefinition: loadParam, newDefinition: loadProp): loadParam | null {
    let def = oldDefinition;
    let changed: boolean = false;
    if (newDefinition) {
      const basePoint = this.changedPoint(oldDefinition.basePoint, newDefinition.basePoint)
      if (basePoint !== null) {
        def.basePoint = basePoint;
        changed = true;
      }
    }
    return (changed ? def : null);
  }

  private checkAndChangedDefinition(oldDefinition: loadParam, newDefinition: loadProp): loadParam | null {
    let def = null;
    if (this.checkNewDefinition(newDefinition)) {
      def = this.changedNewDefinition(oldDefinition, newDefinition);
    }
    return def;
  }

  private checkNewStructural(newStructural: loadParam) {
    const repr = newStructural;
    if (!newStructural) { return false; }
    if (!this.checkString(newStructural.name)) return false;
    if (repr.loadValue !== undefined && !this.checkNumber(repr.loadValue)) { return false; }
    return true;
  }

  private changedLoadNewStructural(oldDefinition: loadParam, newDefinition: loadParam) {
    let def = oldDefinition;
    let changed: boolean = false;
    if (newDefinition) {
      const name = this.changedString(def.name, newDefinition.name);
      if (name !== null) {
        def.name = name;
        changed = true;
      }
      const hypothesis = this.changedString(def.hypothesisId, newDefinition.hypothesisId);
      if (hypothesis !== null) {
        def.hypothesisId = hypothesis;
        changed = true;
      }
      const loadValue = this.changedNumber(def.loadValue, newDefinition.loadValue);
      if (loadValue !== null) {
        def.loadValue = loadValue;
        changed = true;
      }
    }
    return (changed ? def : null);
  }

  public saveAndRegenerate = (newDefinition: loadProp) => {
    const loadDefinition = this.checkAndChangedDefinition(this.data.cloneDefinition(), newDefinition);
    if (loadDefinition) {
      const command = new LoadEditDataCommand(this.data, loadDefinition, null, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }

  public saveAndRegenerateStruct = (newStructural: loadParam) => {
    if (this.checkNewStructural(newStructural)) {
      const loadDefinition = this.changedLoadNewStructural(this.data.cloneDefinition(), newStructural);
      if (loadDefinition) {
        const command = new LoadEditDataCommand(this.data, loadDefinition, null, this.graphicProcessor);
        if (command) this.graphicProcessor.storeAndExecute(command);
      }
    }
  }
}
