import { getEcoreFloat } from "../helper-ecore";
import { getEcorePlacement, getEcoreVector3 } from "../locations";
import { getEcoreClosedWireGeometry } from "../geometry";
import { ExtrudedRepresentation, GeoRepresentation } from "modules/struc/models/ecore/representation";
import { Vector as EcoreVector } from "modules/struc/models/ecore/location";
import { WireGeometry as EcoreWireGeometry } from "modules/struc/models/ecore/geometry";
import { EcoreStrucElemBase } from "../struc-elem-base";
import { baseUriModel } from "../mesh-exporter";
import { PileCap as EcorePileCap } from "modules/struc/models/ecore/structural";
import { PileCapData } from "lib/models/structural/pile-cap";
import { FEMStructuralElement } from "modules/struc/models/ecore/mesh";
import { representationUriModel } from "modules/struc/models/ecore/uri";
import { shellCrossSectionCache } from "lib/models-struc/cross-sections-shape/shell-cross-sections/cache";
import { beamCrossSectionCache } from "lib/models-struc/cross-sections-shape/beam-cross-sections/cache";
import { ICircular } from "lib/models-struc/cross-sections-shape/beam-cross-sections/types";

export class EcorePileCapElem extends EcoreStrucElemBase {

  public exportFemStructuralElementToEcore(storeyRef: number, elemRef: number): FEMStructuralElement {
    // TODO: throw new Error("Method not implemented.");
    return undefined!;
  }

  private strucElem: PileCapData;

  public setStrucElem(strElem: PileCapData) {
    this.strucElem = strElem;
  }
  public exportToEcore(materialRef: number): EcorePileCap {
    const repr = this.strucElem.definition;
    const pileCap: EcorePileCap = {
      eClass: baseUriModel + "structural/Footing",
      id: this.strucElem.id,
      name: repr.name,
      material: this.getEcoreMaterialRef(materialRef),
      placement: getEcorePlacement({ ...repr.basePoint, z: 0 }),
      representation: this.getRepresentation(),
      pile_number: repr.pileCoords.length,
    }
    const section = beamCrossSectionCache.loadStylefromCache(repr.capSectionId)!;
    const param = section.parameter as ICircular;
    const pile_diameter = getEcoreFloat(param.radius * 2, 0.1);
    const pile_height = getEcoreFloat(repr.height, 1);
    if (pile_diameter !== undefined) pileCap.pile_diameter = pile_diameter;
    if (pile_height !== undefined) pileCap.pile_height = pile_height;
    return pileCap;
  }
  private getRepresentation(): ExtrudedRepresentation<GeoRepresentation<EcoreWireGeometry>> {
    return {
      eClass: `${representationUriModel}ExtrudedRepresentation`,
      placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
      direction: this.getEcoreDirection(),
      base: {
        eClass: `${representationUriModel}GeoRepresentation`,
        placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
        base: this.getEcoreGeometryPolylineColumn()
      },
      // TODO: add piles. additions: { }
    }
  }
  public getEcoreDirection(): EcoreVector {
    const repr = this.strucElem.definition;
    const capSection = shellCrossSectionCache.loadStylefromCache(repr.capSectionId)!;
    const depth = capSection.thickness;
    const direction = { x: 0, y: 0, z: -depth };
    return getEcoreVector3(direction);
  }
  private getEcoreGeometryPolylineColumn(): EcoreWireGeometry {
    const repr = this.strucElem.definition;
    return getEcoreClosedWireGeometry(repr.capCoords);
  }
}
