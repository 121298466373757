const ViewportIcon = ({ ...props }) => {
  return (
    <svg className="h-5 w-5" viewBox="0 0 100 100" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7863 3.06966H77.2137C88.0756 3.06966 96.9304 11.9244 96.9304 22.7863V77.2137C96.9304 88.0756 88.0756 96.9303 77.2137 96.9303H22.7863C11.9245 96.9303 3.06967 88.0756 3.06967 77.2137V22.7863C3.06967 11.9244 11.9245 3.06966 22.7863 3.06966ZM22.7863 12.8689H77.2137C82.6446 12.8689 87.013 17.3554 87.013 22.7863V77.2137C87.013 82.6446 82.6446 87.013 77.2137 87.013H22.7863C17.3554 87.013 12.869 82.6446 12.869 77.2137V22.7863C12.869 17.3554 17.3554 12.8689 22.7863 12.8689Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ViewportIcon;
