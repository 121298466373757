import styled from "styled-components";
import CloseIcon from "modules/cad/components/icons/close";
import Menu from "shared/components/ui/menu";
import DotsVerticalIcon from "shared/components/icons/dots-vertical";
import PropertiesSelect from "modules/cad/components/properties/component-select";
import { SecondaryButton } from "shared/components/ui/button";
import PanelPropertiesForm from "modules/cad/components/properties/properties-panel-form";
import ViewportLite from "modules/cad/components/viewport/viewport-lite";
import { ChangeEvent, useEffect, useMemo } from "react";
import HorizontalResizer from "shared/components/ui/resizer/horizontal-resizer";
import Section from "shared/components/ui/section";
import TableGrid from "../../pile-cap/table-grid";
import { OpFactory, cadOpType } from "lib/operations/factory";
import { useMainGraphicContext } from "modules/cad/components/viewport/context";
import { canvasOptions, GraphicProcessor } from "lib/graphic-processor";
import { isCustomSection, sectionType } from "lib/models-struc/cross-sections-shape/beam-cross-sections/types";
import { useBeamCrossSectionManager } from "./hook/use-beam-cross-sections";
import RectangleIcon from "shared/components/icons/rectangle";
import CircleIcon from "modules/cad/components/icons/circle";
import TextIcon from "modules/cad/components/icons/text";
import TrashIcon from "shared/components/icons/trash";
import PolygonIcon from "modules/cad/components/icons/polygon";
import ToolbarButton from "modules/cad/components/toolbar/toolbar-button";
import PointIcon from "modules/cad/components/icons/point";

const canvasSettings: canvasOptions = {
  showMainGrid: false,
  camera: "perspective",
};

interface Props { onClose: () => void }

const BeamCrossSectionDlg = ({ onClose }: Props) => {

  const mainGraphicProc = useMainGraphicContext();
  useEffect(() => {
    OpFactory.stopOp(mainGraphicProc);
    return () => {
      mainGraphicProc.launchOP(cadOpType.SELECT);
    };
  }, [mainGraphicProc]);
  const graphicProc = useMemo(() => new GraphicProcessor("beamCrossSectionGPlite"), []);

  const {
    canBeSaved,
    saveChanges,
    selectStyle,
    editStyle,
    selectedStyle,
    BeamCrossSectionLoaded,
    styleProperties,
    deleteStyle,
    createStyle,
    contourRows,
    updateCustomContour,
    launchInsertVertex,
    launchDeleteVertex,
  } = useBeamCrossSectionManager(graphicProc);

  return (
    <Wrapper className="text-xs rounded">
      <Header>
        <h1>Beam cross section shapes</h1>
        <button onClick={onClose}>
          <CloseIcon className="h-4 w-4" />
        </button>
      </Header>
      <Content>
        <Box className={"flex gap-2 items-center justify-between"}>
          <label className={"text-xs text-gray-400 pr-2"}>Beam cross section:</label>
          <PropertiesSelect
            srOnly={true}
            value={selectedStyle.styleId}
            wheelEvent={true}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              const newStyle = event.target.value;
              selectStyle(newStyle);
            }}>
            {BeamCrossSectionLoaded.map((val, i) => (
              <option key={i} value={val.styleId}> {`${val.name} ${val.default ? "[Default]" : ""}`} </option>
            ))}
          </PropertiesSelect>
          <Menu>
            <Menu.Button>
              <DotsVerticalIcon />
            </Menu.Button>
            <Menu.List>
              <Menu.Item onSelect={() => createStyle(sectionType.RECT)} >
                <div className="flex gap-2">
                  <RectangleIcon className="h-5 w-5" />
                  <span>New rectangular section</span>
                </div>
              </Menu.Item>
              <Menu.Item onSelect={() => createStyle(sectionType.QUAD)} >
                <div className="flex gap-2">
                  <RectangleIcon className="h-5 w-5" />
                  <span>New quad section</span>
                </div>
              </Menu.Item>
              <Menu.Item onSelect={() => createStyle(sectionType.CIRC)} >
                <div className="flex gap-2">
                  <CircleIcon className="h-5 w-5" />
                  <span>New circular section</span>
                </div>
              </Menu.Item>
              <Menu.Item onSelect={() => createStyle(sectionType.TUBE)} >
                <div className="flex gap-2">
                  <CircleIcon className="h-5 w-5" />
                  <span>New tube section</span>
                </div></Menu.Item>
              <Menu.Item onSelect={() => createStyle(sectionType.T)} >
                <div className="flex gap-2">
                  <TextIcon className="h-5 w-5" />
                  <span>New T section</span>
                </div>
              </Menu.Item>
              <Menu.Item onSelect={() => createStyle(sectionType.I)} >
                <div className="flex gap-2">
                  <span className="h-5 w-5 text-center">I</span>
                  <span>New I section</span>
                </div>
              </Menu.Item>
              <Menu.Item onSelect={() => createStyle(sectionType.U)} >
                <div className="flex gap-2">
                  <span className="h-5 w-5 text-center">U</span>
                  <span>New U section</span>
                </div>
              </Menu.Item>
              <Menu.Item onSelect={() => createStyle(sectionType.CUSTOM)} >
                <div className="flex gap-2">
                  <PolygonIcon className="h-5 w-5" />
                  <span>New custom section</span>
                </div>
              </Menu.Item>
              <Menu.Item onSelect={deleteStyle}>
                <div className="flex gap-2">
                  <TrashIcon className="h-5 w-5" />
                  <span>Delete</span>
                </div>
              </Menu.Item>
            </Menu.List>
          </Menu>
        </Box>
        <HorizontalResizer id={"pileCapModal"}
          leftClassName={"w-1/2 p-2 min-w-min overflow-auto layers"}
          leftChild={
            <>
              <PanelPropertiesForm panelProp={{
                propValue: styleProperties,
                propCallback: editStyle
              }} />

              {isCustomSection(selectedStyle.parameter) &&
                <Section
                  title="Contour coordinates"
                  className={"whitespace-nowrap text-gray-400"}
                  withBorder={false}
                  isCollapsible={false}
                  optionalComponent={!selectedStyle.default &&
                    <>
                      <ToolbarButton onClick={launchInsertVertex} tooltip="Insert vertex">
                        <PointIcon className="h-5 w-5" />
                      </ToolbarButton>
                      <ToolbarButton onClick={launchDeleteVertex} tooltip="Delete vertex">
                        <TrashIcon className="h-5 w-5" />
                      </ToolbarButton>
                    </>
                  }>
                  <TableGrid
                    header={["Vertex", "X (m)", "Y (m)"]}
                    rows={contourRows}
                    disableColumn={[0]}
                    updateData={updateCustomContour} />
                </Section>}
            </>
          }
          rightClassName={"w-1/2"}
          rightChild={
            <ViewportLite graphicProc={graphicProc} options={canvasSettings} />
          }
        />
        <Box className={"flex justify-around items-center"}>
          <SecondaryButton onClick={saveChanges} disabled={!canBeSaved}>
            Save
          </SecondaryButton>
          <SecondaryButton onClick={onClose}>
            Close
          </SecondaryButton>
        </Box>
      </Content>
    </Wrapper >
  )
};

const Wrapper = styled.div`
  overflow: hidden;
  width: 800px;
  margin: -32px;
  background-color: var(--color-gray-300);
`;
const Header = styled.div`
  background-color: var(--color-gray-300);
  border-bottom: 2px solid var(--color-primary);
  padding: 8px;
  display: flex;
  justify-content: space-between;
`;
const Content = styled.div`
  display: grid;
  grid-template-rows: 36px 340px 36px;
  grid-template-columns: auto;
  gap: 4px;
  padding: 8px;
  height: 100%;
`;
const Box = styled.div`
  background-color: var(--color-gray-200);
  padding: 8px;
  border-radius: 4px;
  overflow: hidden;
`;
export default BeamCrossSectionDlg;