import { blockParam, blockItem, BlockManager, blockRefParam } from "lib/blocks";
import { BlockCommand, BlockRefCommand } from "lib/commands/primitives/block";

import { copyIPoint } from "lib/math/point";
import { IPoint } from "lib/math/types";
import { Cad3dOp } from "../base";
import { MultiEdition } from "../edition-multi";
import { cadOpType } from "../factory";
import { settingsOpModes } from "../step-operations";

export class BlockOP extends MultiEdition {
  public opType: cadOpType.BLOCK;
  private basePoint: IPoint;

  public async start() {
    this.iniSettingsOp();
    if (this.objDataOrigin.length === 0) {
      this.registerCancel();
      this.registerRaycast();
    } else {
      this.setNextStep();
    }
  }
  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([
      {
        infoMsg: "Select objects.",
        stepMode: settingsOpModes.SELECTOBJS,
        multiSelect: true,
        enableSelectMarks: true,
        filterFun: () => true,
        endStepCallback: async () => {
          this.unRegisterRaycast();
          this.registerInputs();
          this.registerUpdaters();
          this.initializeSnap();
        }
      },
      {
        infoMsg: "Specify base point",
        stepMode: settingsOpModes.DEFAULTXYZ,
        cmdLineListener: this.addPointFromExt.bind(this),
        endStepCallback: () => {
          this.basePoint = copyIPoint(this.lastPoint);
          this.save();
          this.endOperation();
        },
      }
    ]);
  }
  public setLastPoint(): void {
    this.setNextStep();
  }
  private getBlockParam(): blockParam {
    const blockElements: blockItem[] = [];
    this.objDataOrigin.forEach(obj => {
      const item = { itemType: obj.type, itemDef: obj.definition, material: obj.material };
      blockElements.push(item);
    });
    return { id: -1, name: "Nuevo bloque", blockItems: blockElements, basePoint: this.basePoint };
  }
  public save() {
    if (this.graphicProcessor) {
      const block = this.getBlockParam();
      const command = new BlockCommand(block, this.objDataOrigin, this.getCurrentSceneId(), this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
  // public cancelOperation() :void {
  //   if (this.finished === false) {
  //     this.save();
  //     this.endOperation();
  //   }
  // }
}

export class BlockRefOP extends Cad3dOp {
  public opType: cadOpType.BLOCKREF;
  public blockId: number;
  public auxBlock: THREE.Group;

  constructor(blockId: number) {
    super();
    this.blockId = blockId ?? 0;
  }
  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Insert block: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
    }]);
  }
  public async start() {
    this.initializeBlockRef();
    this.iniSettingsOp();
    this.initializeSnap();
    this.initializeWorkingPlane();
    this.registerCancel();
    this.registerInputs();
    this.registerUpdaters();
  }
  private initializeBlockRef(): void {
    this.auxBlock = BlockManager.getBlockGraphicObj(this.blockId);
    this.auxBlock.children.forEach(child => { this.setAuxObj(child); });
    this.saveToTempScene(this.auxBlock);
  }
  public setLastPoint(): void {
    this.endOperation();
  }
  public moveLastPoint(pto: IPoint) {
    this.auxBlock.position.set(pto.x, pto.y, pto.z);
  }
  public endOperation(): void {
    if (!this.finished) {
      this.save();
      super.endOperation();
    }
  }
  public save() {
    if (this.graphicProcessor) {
      const blockRef: blockRefParam = { blockId: this.blockId, position: this.lastPoint, rotation: { x: 0, y: 0, z: 0 }, scale: { x: 1, y: 1, z: 1 } };
      const command = new BlockRefCommand(blockRef, this.getCurrentSceneId(), this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
}