import { IArcLineParam } from "lib/math/line";
import { IPoint, ISegment } from "lib/math/types";
import { IObjData } from "lib/models/objdata";
import { dataInfoProperty } from "lib/properties/properties";

export interface IPointReference {
  dataId?: string; // Only for persistence
  data?: IObjData;
  edgeIndex?: number;
  factor?: number;
  point: IPoint;
}
export interface IEdgeReference {
  dataId?: string;  // Only for persistence
  data?: IObjData;
  p1Index: number;
  p2Index: number;
  segment: ISegment;
  arc?: IArcLineParam | 0;
}

export enum settingsOpModes {
  SELECTOBJS,
  SELECTVERTEX,
  SELECTEDGE,
  SELECTFACE,
  WAITMODE,
  DEFAULTXYZ,
  ONEBOX
}

export enum coordModes { XYZ, POLAR }
export enum xyzModes { ABS, REL }

export interface propSetting<T> {
  propValue: dataInfoProperty<T> & Record<string, any>;
  propCallback?(v: T): void;
}

interface ISettingOp {
  stepMode: settingsOpModes;
  infoMsg: string;
  cmdLineListener?: (a: any) => void;
  startStepCallback?: (step: settingOp) => void;
  endStepCallback?: () => void;
  panelProp?: propSetting<any>;
}
// Entrada dinamica
export interface IDefaultXYZMode extends ISettingOp {
  stepMode: settingsOpModes.DEFAULTXYZ;
  coordMode?: coordModes;
  xyzModes?: xyzModes;
}
export interface IOneBoxMode extends ISettingOp {
  stepMode: settingsOpModes.ONEBOX;
  currValue?(): number | string;
  cmdLineUpdateListener?: (a: any) => void;
  hide?: boolean;
}
// Entrada Selector Tools
export interface ISelectObj extends ISettingOp {
  stepMode: settingsOpModes.SELECTOBJS;
  enableSelectMarks: boolean;
  multiSelect: boolean;
  filterFun: (obj: IObjData) => boolean;
  getObjsCallback?(...arg: any): void;
}
export interface ISelectVertex extends ISettingOp {
  stepMode: settingsOpModes.SELECTVERTEX;
  filterFun: (obj: IObjData) => boolean;
  getVertexCallback: (ref: IPointReference) => void;
}
export interface ISelectEdge extends ISettingOp {
  stepMode: settingsOpModes.SELECTEDGE;
  filterFun: (obj: IObjData) => boolean;
  getEdgeCallback: (ref: IEdgeReference) => void;
}
interface IWaitUser extends ISettingOp {
  stepMode: settingsOpModes.WAITMODE;
  stepFun(): void;
}

export type settingOp =
  IDefaultXYZMode |
  IOneBoxMode |
  ISelectObj |
  IWaitUser |
  ISelectVertex |
  ISelectEdge;
