import { useParams } from 'react-router-dom'
import Page from '../components/ui/page'

import { SecondaryLink } from 'shared/components/ui/link'
import { PrimaryButton } from 'shared/components/ui/button'
import LoadingDots from 'shared/components/ui/loading-dots'

import { useAuth } from 'shared/components/auth/context'
import { useGetStrucProject, useGetStrucProjectSites } from '../components/project/hook/use-project'

import { useEditProject } from '../components/project/form/hook/use-edit-project'
import ProjectForm from '../components/project/form/project-form'

const EditStrucProject = () => {

  const { user } = useAuth();
  const { id } = useParams<{ id: string }>();

  const getProject = useGetStrucProject(user!.id, id);
  const getSites = useGetStrucProjectSites(user!.id, id);

  const {
    editProject,
    status,
  } = useEditProject();

  if (getProject.isLoading || getSites.isLoading) {
    return (
      <div className="h-screen bg-gray-800 flex flex-col items-center justify-center">
        <LoadingDots />
      </div>
    );
  }
  const currProject = getProject.projectData!;
  const sites = getSites.sites![0];

  return (
    <Page title="Edit project">
      <ProjectForm
        submit={(project, storeys) => {
          project.DataModelVersion = "0.2";
          editProject(currProject.Id, project, sites, storeys);
        }}
        currProject={currProject}
        site={sites}
      >
        <div className="flex items-center justify-end space-x-2">
          <SecondaryLink to="/struc/projects" variant="tertiary">
            Cancel
          </SecondaryLink>
          <PrimaryButton type="submit" loading={status === 'loading'}>
            Edit project
          </PrimaryButton>
        </div>
      </ProjectForm>
    </Page>
  )
}



export default EditStrucProject
