/* tslint:disable */
/* eslint-disable */
/**
 * Analysis Projects API
 * API for managing analysis calculations.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: pablo.campillo@clerhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AnalysisProject
 */
export interface AnalysisProject {
    /**
     * 
     * @type {string}
     * @memberof AnalysisProject
     */
    'UploadUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisProject
     */
    'MPValue': AnalysisProjectMPValueEnum;
    /**
     * 
     * @type {string}
     * @memberof AnalysisProject
     */
    'ChangedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisProject
     */
    'Id': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisProject
     */
    'MeshProjectID'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisProject
     */
    'CreatedAt'?: string;
    /**
     * 
     * @type {AnalysisProjectStatus}
     * @memberof AnalysisProject
     */
    'MPStatus'?: AnalysisProjectStatus;
    /**
     * 
     * @type {string}
     * @memberof AnalysisProject
     */
    'InputS3Key'?: string;
    /**
     * 
     * @type {Array<AnalysisProjectAllOfEvents>}
     * @memberof AnalysisProject
     */
    'Events'?: Array<AnalysisProjectAllOfEvents>;
}

export const AnalysisProjectMPValueEnum = {
    Running: 'RUNNING',
    Error: 'ERROR',
    Done: 'DONE',
    Canceled: 'CANCELED'
} as const;

export type AnalysisProjectMPValueEnum = typeof AnalysisProjectMPValueEnum[keyof typeof AnalysisProjectMPValueEnum];

/**
 * 
 * @export
 * @interface AnalysisProjectAllOf
 */
export interface AnalysisProjectAllOf {
    /**
     * 
     * @type {string}
     * @memberof AnalysisProjectAllOf
     */
    'Id': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisProjectAllOf
     */
    'MeshProjectID'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisProjectAllOf
     */
    'CreatedAt'?: string;
    /**
     * 
     * @type {AnalysisProjectStatus}
     * @memberof AnalysisProjectAllOf
     */
    'MPStatus'?: AnalysisProjectStatus;
    /**
     * 
     * @type {string}
     * @memberof AnalysisProjectAllOf
     */
    'InputS3Key'?: string;
    /**
     * 
     * @type {Array<AnalysisProjectAllOfEvents>}
     * @memberof AnalysisProjectAllOf
     */
    'Events'?: Array<AnalysisProjectAllOfEvents>;
}
/**
 * 
 * @export
 * @interface AnalysisProjectAllOfEvents
 */
export interface AnalysisProjectAllOfEvents {
    /**
     * 
     * @type {string}
     * @memberof AnalysisProjectAllOfEvents
     */
    'Name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisProjectAllOfEvents
     */
    'CreatedAt'?: string;
}
/**
 * 
 * @export
 * @interface AnalysisProjectRequest
 */
export interface AnalysisProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof AnalysisProjectRequest
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisProjectRequest
     */
    'MeshProjectID': string;
}
/**
 * 
 * @export
 * @interface AnalysisProjectStatus
 */
export interface AnalysisProjectStatus {
    /**
     * 
     * @type {string}
     * @memberof AnalysisProjectStatus
     */
    'MPValue': AnalysisProjectStatusMPValueEnum;
    /**
     * 
     * @type {string}
     * @memberof AnalysisProjectStatus
     */
    'ChangedAt'?: string;
}

export const AnalysisProjectStatusMPValueEnum = {
    Running: 'RUNNING',
    Error: 'ERROR',
    Done: 'DONE',
    Canceled: 'CANCELED'
} as const;

export type AnalysisProjectStatusMPValueEnum = typeof AnalysisProjectStatusMPValueEnum[keyof typeof AnalysisProjectStatusMPValueEnum];

/**
 * 
 * @export
 * @interface FileUrl
 */
export interface FileUrl {
    /**
     * 
     * @type {string}
     * @memberof FileUrl
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof FileUrl
     */
    'Url': string;
}
/**
 * 
 * @export
 * @interface LoadCombinationStructure
 */
export interface LoadCombinationStructure {
    /**
     * 
     * @type {string}
     * @memberof LoadCombinationStructure
     */
    'ResultType': LoadCombinationStructureResultTypeEnum;
    /**
     * 
     * @type {Array<LoadCombinationType>}
     * @memberof LoadCombinationStructure
     */
    'Values': Array<LoadCombinationType>;
}

export const LoadCombinationStructureResultTypeEnum = {
    DisplNodes: 'displ_nodes',
    DisplIpBeams: 'displ_ip_beams',
    DisplIpShells: 'displ_ip_shells',
    FmNodalBeams: 'fm_nodal_beams',
    FmNodalShells: 'fm_nodal_shells',
    FmPiBeams: 'fm_pi_beams',
    FmPiShells: 'fm_pi_shells'
} as const;

export type LoadCombinationStructureResultTypeEnum = typeof LoadCombinationStructureResultTypeEnum[keyof typeof LoadCombinationStructureResultTypeEnum];

/**
 * 
 * @export
 * @interface LoadCombinationType
 */
export interface LoadCombinationType {
    /**
     * 
     * @type {string}
     * @memberof LoadCombinationType
     */
    'CombType': LoadCombinationTypeCombTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof LoadCombinationType
     */
    'NumComb': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof LoadCombinationType
     */
    'ConsPhases': Array<number>;
}

export const LoadCombinationTypeCombTypeEnum = {
    Uls: 'ULS',
    SlsChar: 'SLS_CHAR',
    SlsFreq: 'SLS_FREQ',
    SlsQfreq: 'SLS_QFREQ'
} as const;

export type LoadCombinationTypeCombTypeEnum = typeof LoadCombinationTypeCombTypeEnum[keyof typeof LoadCombinationTypeCombTypeEnum];

/**
 * 
 * @export
 * @interface UploadUrlObj
 */
export interface UploadUrlObj {
    /**
     * 
     * @type {string}
     * @memberof UploadUrlObj
     */
    'UploadUrl': string;
}

/**
 * AnalysisProjectsApi - axios parameter creator
 * @export
 */
export const AnalysisProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes an Analysis Project and its files.
         * @summary Removes an Analysis Project.
         * @param {string} id Id of the Analysis Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisProjectsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('analysisProjectsIdDelete', 'id', id)
            const localVarPath = `/analysis_projects/{Id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all information about an Analysis Project.
         * @summary Returns an Analysis Project.
         * @param {string} id Id of the Analysis Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisProjectsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('analysisProjectsIdGet', 'id', id)
            const localVarPath = `/analysis_projects/{Id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns an url with credentials to upload a new file of the model.
         * @summary Returns an url with credentials.
         * @param {string} id Id of the Analysis Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisProjectsIdUploadGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('analysisProjectsIdUploadGet', 'id', id)
            const localVarPath = `/analysis_projects/{Id}/upload`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns an analysis project with an upload url.
         * @summary Returns an analysis project with an upload url.
         * @param {AnalysisProjectRequest} analysisProjectRequest Id of the required Mesh Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisProjectsPost: async (analysisProjectRequest: AnalysisProjectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'analysisProjectRequest' is not null or undefined
            assertParamExists('analysisProjectsPost', 'analysisProjectRequest', analysisProjectRequest)
            const localVarPath = `/analysis_projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(analysisProjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalysisProjectsApi - functional programming interface
 * @export
 */
export const AnalysisProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalysisProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes an Analysis Project and its files.
         * @summary Removes an Analysis Project.
         * @param {string} id Id of the Analysis Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysisProjectsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analysisProjectsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all information about an Analysis Project.
         * @summary Returns an Analysis Project.
         * @param {string} id Id of the Analysis Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysisProjectsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analysisProjectsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns an url with credentials to upload a new file of the model.
         * @summary Returns an url with credentials.
         * @param {string} id Id of the Analysis Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysisProjectsIdUploadGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadUrlObj>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analysisProjectsIdUploadGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns an analysis project with an upload url.
         * @summary Returns an analysis project with an upload url.
         * @param {AnalysisProjectRequest} analysisProjectRequest Id of the required Mesh Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysisProjectsPost(analysisProjectRequest: AnalysisProjectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analysisProjectsPost(analysisProjectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnalysisProjectsApi - factory interface
 * @export
 */
export const AnalysisProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalysisProjectsApiFp(configuration)
    return {
        /**
         * Removes an Analysis Project and its files.
         * @summary Removes an Analysis Project.
         * @param {string} id Id of the Analysis Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisProjectsIdDelete(id: string, options?: any): AxiosPromise<AnalysisProject> {
            return localVarFp.analysisProjectsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all information about an Analysis Project.
         * @summary Returns an Analysis Project.
         * @param {string} id Id of the Analysis Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisProjectsIdGet(id: string, options?: any): AxiosPromise<AnalysisProject> {
            return localVarFp.analysisProjectsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns an url with credentials to upload a new file of the model.
         * @summary Returns an url with credentials.
         * @param {string} id Id of the Analysis Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisProjectsIdUploadGet(id: string, options?: any): AxiosPromise<UploadUrlObj> {
            return localVarFp.analysisProjectsIdUploadGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns an analysis project with an upload url.
         * @summary Returns an analysis project with an upload url.
         * @param {AnalysisProjectRequest} analysisProjectRequest Id of the required Mesh Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisProjectsPost(analysisProjectRequest: AnalysisProjectRequest, options?: any): AxiosPromise<AnalysisProject> {
            return localVarFp.analysisProjectsPost(analysisProjectRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalysisProjectsApi - object-oriented interface
 * @export
 * @class AnalysisProjectsApi
 * @extends {BaseAPI}
 */
export class AnalysisProjectsApi extends BaseAPI {
    /**
     * Removes an Analysis Project and its files.
     * @summary Removes an Analysis Project.
     * @param {string} id Id of the Analysis Project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisProjectsApi
     */
    public analysisProjectsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AnalysisProjectsApiFp(this.configuration).analysisProjectsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all information about an Analysis Project.
     * @summary Returns an Analysis Project.
     * @param {string} id Id of the Analysis Project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisProjectsApi
     */
    public analysisProjectsIdGet(id: string, options?: AxiosRequestConfig) {
        return AnalysisProjectsApiFp(this.configuration).analysisProjectsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns an url with credentials to upload a new file of the model.
     * @summary Returns an url with credentials.
     * @param {string} id Id of the Analysis Project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisProjectsApi
     */
    public analysisProjectsIdUploadGet(id: string, options?: AxiosRequestConfig) {
        return AnalysisProjectsApiFp(this.configuration).analysisProjectsIdUploadGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns an analysis project with an upload url.
     * @summary Returns an analysis project with an upload url.
     * @param {AnalysisProjectRequest} analysisProjectRequest Id of the required Mesh Project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisProjectsApi
     */
    public analysisProjectsPost(analysisProjectRequest: AnalysisProjectRequest, options?: AxiosRequestConfig) {
        return AnalysisProjectsApiFp(this.configuration).analysisProjectsPost(analysisProjectRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnalysisProjectsLoadCombinationApi - axios parameter creator
 * @export
 */
export const AnalysisProjectsLoadCombinationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a list of FileUrl objects with the id of the load combination and the url of the file with combination values.
         * @summary Gets file urls for each load combination.
         * @param {string} id Id of the Analysis Project
         * @param {Array<string>} combIds List of load combination ids.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisProjectsIdCombinationsFilesGet: async (id: string, combIds: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('analysisProjectsIdCombinationsFilesGet', 'id', id)
            // verify required parameter 'combIds' is not null or undefined
            assertParamExists('analysisProjectsIdCombinationsFilesGet', 'combIds', combIds)
            const localVarPath = `/analysis_projects/{Id}/combinations_files/`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (combIds) {
                localVarQueryParameter['CombIds'] = combIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a dictionary that contains structure of load combination based on result_type, comb_type, cons_phase
         * @summary Gets available load combination.
         * @param {string} id Id of the Analysis Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisProjectsIdCombinationsGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('analysisProjectsIdCombinationsGet', 'id', id)
            const localVarPath = `/analysis_projects/{Id}/combinations`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalysisProjectsLoadCombinationApi - functional programming interface
 * @export
 */
export const AnalysisProjectsLoadCombinationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalysisProjectsLoadCombinationApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a list of FileUrl objects with the id of the load combination and the url of the file with combination values.
         * @summary Gets file urls for each load combination.
         * @param {string} id Id of the Analysis Project
         * @param {Array<string>} combIds List of load combination ids.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysisProjectsIdCombinationsFilesGet(id: string, combIds: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileUrl>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analysisProjectsIdCombinationsFilesGet(id, combIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a dictionary that contains structure of load combination based on result_type, comb_type, cons_phase
         * @summary Gets available load combination.
         * @param {string} id Id of the Analysis Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysisProjectsIdCombinationsGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LoadCombinationStructure>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analysisProjectsIdCombinationsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnalysisProjectsLoadCombinationApi - factory interface
 * @export
 */
export const AnalysisProjectsLoadCombinationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalysisProjectsLoadCombinationApiFp(configuration)
    return {
        /**
         * Returns a list of FileUrl objects with the id of the load combination and the url of the file with combination values.
         * @summary Gets file urls for each load combination.
         * @param {string} id Id of the Analysis Project
         * @param {Array<string>} combIds List of load combination ids.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisProjectsIdCombinationsFilesGet(id: string, combIds: Array<string>, options?: any): AxiosPromise<Array<FileUrl>> {
            return localVarFp.analysisProjectsIdCombinationsFilesGet(id, combIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a dictionary that contains structure of load combination based on result_type, comb_type, cons_phase
         * @summary Gets available load combination.
         * @param {string} id Id of the Analysis Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisProjectsIdCombinationsGet(id: string, options?: any): AxiosPromise<Array<LoadCombinationStructure>> {
            return localVarFp.analysisProjectsIdCombinationsGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalysisProjectsLoadCombinationApi - object-oriented interface
 * @export
 * @class AnalysisProjectsLoadCombinationApi
 * @extends {BaseAPI}
 */
export class AnalysisProjectsLoadCombinationApi extends BaseAPI {
    /**
     * Returns a list of FileUrl objects with the id of the load combination and the url of the file with combination values.
     * @summary Gets file urls for each load combination.
     * @param {string} id Id of the Analysis Project
     * @param {Array<string>} combIds List of load combination ids.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisProjectsLoadCombinationApi
     */
    public analysisProjectsIdCombinationsFilesGet(id: string, combIds: Array<string>, options?: AxiosRequestConfig) {
        return AnalysisProjectsLoadCombinationApiFp(this.configuration).analysisProjectsIdCombinationsFilesGet(id, combIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a dictionary that contains structure of load combination based on result_type, comb_type, cons_phase
     * @summary Gets available load combination.
     * @param {string} id Id of the Analysis Project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisProjectsLoadCombinationApi
     */
    public analysisProjectsIdCombinationsGet(id: string, options?: AxiosRequestConfig) {
        return AnalysisProjectsLoadCombinationApiFp(this.configuration).analysisProjectsIdCombinationsGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnalysisProjectsResultsApi - axios parameter creator
 * @export
 */
export const AnalysisProjectsResultsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a list of FileUrl objects with the name of the hypothesis and the url of the file with the hypothesis displacements.
         * @summary Gets displacements of a set of hypothesis.
         * @param {string} id Id of the Analysis Project
         * @param {'displ_nodes' | 'displ_ip_beams' | 'displ_ip_shells' | 'fm_nodal_beams' | 'fm_nodal_shells' | 'fm_ip_beams' | 'fm_ip_shells'} type Result type (displ &#x3D; displacements, fm &#x3D; forces &amp; moments)
         * @param {Array<string>} hypos List of hypothesis ids.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisProjectsIdResultsGet: async (id: string, type: 'displ_nodes' | 'displ_ip_beams' | 'displ_ip_shells' | 'fm_nodal_beams' | 'fm_nodal_shells' | 'fm_ip_beams' | 'fm_ip_shells', hypos: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('analysisProjectsIdResultsGet', 'id', id)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('analysisProjectsIdResultsGet', 'type', type)
            // verify required parameter 'hypos' is not null or undefined
            assertParamExists('analysisProjectsIdResultsGet', 'hypos', hypos)
            const localVarPath = `/analysis_projects/{Id}/results`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (hypos) {
                localVarQueryParameter['Hypos'] = hypos;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalysisProjectsResultsApi - functional programming interface
 * @export
 */
export const AnalysisProjectsResultsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalysisProjectsResultsApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a list of FileUrl objects with the name of the hypothesis and the url of the file with the hypothesis displacements.
         * @summary Gets displacements of a set of hypothesis.
         * @param {string} id Id of the Analysis Project
         * @param {'displ_nodes' | 'displ_ip_beams' | 'displ_ip_shells' | 'fm_nodal_beams' | 'fm_nodal_shells' | 'fm_ip_beams' | 'fm_ip_shells'} type Result type (displ &#x3D; displacements, fm &#x3D; forces &amp; moments)
         * @param {Array<string>} hypos List of hypothesis ids.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysisProjectsIdResultsGet(id: string, type: 'displ_nodes' | 'displ_ip_beams' | 'displ_ip_shells' | 'fm_nodal_beams' | 'fm_nodal_shells' | 'fm_ip_beams' | 'fm_ip_shells', hypos: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileUrl>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analysisProjectsIdResultsGet(id, type, hypos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnalysisProjectsResultsApi - factory interface
 * @export
 */
export const AnalysisProjectsResultsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalysisProjectsResultsApiFp(configuration)
    return {
        /**
         * Returns a list of FileUrl objects with the name of the hypothesis and the url of the file with the hypothesis displacements.
         * @summary Gets displacements of a set of hypothesis.
         * @param {string} id Id of the Analysis Project
         * @param {'displ_nodes' | 'displ_ip_beams' | 'displ_ip_shells' | 'fm_nodal_beams' | 'fm_nodal_shells' | 'fm_ip_beams' | 'fm_ip_shells'} type Result type (displ &#x3D; displacements, fm &#x3D; forces &amp; moments)
         * @param {Array<string>} hypos List of hypothesis ids.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisProjectsIdResultsGet(id: string, type: 'displ_nodes' | 'displ_ip_beams' | 'displ_ip_shells' | 'fm_nodal_beams' | 'fm_nodal_shells' | 'fm_ip_beams' | 'fm_ip_shells', hypos: Array<string>, options?: any): AxiosPromise<Array<FileUrl>> {
            return localVarFp.analysisProjectsIdResultsGet(id, type, hypos, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalysisProjectsResultsApi - object-oriented interface
 * @export
 * @class AnalysisProjectsResultsApi
 * @extends {BaseAPI}
 */
export class AnalysisProjectsResultsApi extends BaseAPI {
    /**
     * Returns a list of FileUrl objects with the name of the hypothesis and the url of the file with the hypothesis displacements.
     * @summary Gets displacements of a set of hypothesis.
     * @param {string} id Id of the Analysis Project
     * @param {'displ_nodes' | 'displ_ip_beams' | 'displ_ip_shells' | 'fm_nodal_beams' | 'fm_nodal_shells' | 'fm_ip_beams' | 'fm_ip_shells'} type Result type (displ &#x3D; displacements, fm &#x3D; forces &amp; moments)
     * @param {Array<string>} hypos List of hypothesis ids.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisProjectsResultsApi
     */
    public analysisProjectsIdResultsGet(id: string, type: 'displ_nodes' | 'displ_ip_beams' | 'displ_ip_shells' | 'fm_nodal_beams' | 'fm_nodal_shells' | 'fm_ip_beams' | 'fm_ip_shells', hypos: Array<string>, options?: AxiosRequestConfig) {
        return AnalysisProjectsResultsApiFp(this.configuration).analysisProjectsIdResultsGet(id, type, hypos, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnalysisProjectsStatusApi - axios parameter creator
 * @export
 */
export const AnalysisProjectsStatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Stops an analysis project job.
         * @summary Cancel an analysis project job.
         * @param {string} id Id of the Analysis Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisProjectsIdCancelPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('analysisProjectsIdCancelPost', 'id', id)
            const localVarPath = `/analysis_projects/{Id}/cancel`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets status analysis project (RUNNING | ERROR | DONE).
         * @summary Gets status analysis project.
         * @param {string} id Id of the Analysis Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisProjectsIdStatusGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('analysisProjectsIdStatusGet', 'id', id)
            const localVarPath = `/analysis_projects/{Id}/status`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates status analysis project (RUNNING | ERROR | DONE).
         * @summary Updates status analysis project.
         * @param {string} id Id of the Analysis Project
         * @param {AnalysisProjectStatus} analysisProjectStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisProjectsIdStatusPut: async (id: string, analysisProjectStatus: AnalysisProjectStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('analysisProjectsIdStatusPut', 'id', id)
            // verify required parameter 'analysisProjectStatus' is not null or undefined
            assertParamExists('analysisProjectsIdStatusPut', 'analysisProjectStatus', analysisProjectStatus)
            const localVarPath = `/analysis_projects/{Id}/status`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(analysisProjectStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalysisProjectsStatusApi - functional programming interface
 * @export
 */
export const AnalysisProjectsStatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalysisProjectsStatusApiAxiosParamCreator(configuration)
    return {
        /**
         * Stops an analysis project job.
         * @summary Cancel an analysis project job.
         * @param {string} id Id of the Analysis Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysisProjectsIdCancelPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisProjectStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analysisProjectsIdCancelPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets status analysis project (RUNNING | ERROR | DONE).
         * @summary Gets status analysis project.
         * @param {string} id Id of the Analysis Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysisProjectsIdStatusGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisProjectStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analysisProjectsIdStatusGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates status analysis project (RUNNING | ERROR | DONE).
         * @summary Updates status analysis project.
         * @param {string} id Id of the Analysis Project
         * @param {AnalysisProjectStatus} analysisProjectStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysisProjectsIdStatusPut(id: string, analysisProjectStatus: AnalysisProjectStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisProjectStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analysisProjectsIdStatusPut(id, analysisProjectStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnalysisProjectsStatusApi - factory interface
 * @export
 */
export const AnalysisProjectsStatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalysisProjectsStatusApiFp(configuration)
    return {
        /**
         * Stops an analysis project job.
         * @summary Cancel an analysis project job.
         * @param {string} id Id of the Analysis Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisProjectsIdCancelPost(id: string, options?: any): AxiosPromise<AnalysisProjectStatus> {
            return localVarFp.analysisProjectsIdCancelPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets status analysis project (RUNNING | ERROR | DONE).
         * @summary Gets status analysis project.
         * @param {string} id Id of the Analysis Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisProjectsIdStatusGet(id: string, options?: any): AxiosPromise<AnalysisProjectStatus> {
            return localVarFp.analysisProjectsIdStatusGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates status analysis project (RUNNING | ERROR | DONE).
         * @summary Updates status analysis project.
         * @param {string} id Id of the Analysis Project
         * @param {AnalysisProjectStatus} analysisProjectStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisProjectsIdStatusPut(id: string, analysisProjectStatus: AnalysisProjectStatus, options?: any): AxiosPromise<AnalysisProjectStatus> {
            return localVarFp.analysisProjectsIdStatusPut(id, analysisProjectStatus, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalysisProjectsStatusApi - object-oriented interface
 * @export
 * @class AnalysisProjectsStatusApi
 * @extends {BaseAPI}
 */
export class AnalysisProjectsStatusApi extends BaseAPI {
    /**
     * Stops an analysis project job.
     * @summary Cancel an analysis project job.
     * @param {string} id Id of the Analysis Project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisProjectsStatusApi
     */
    public analysisProjectsIdCancelPost(id: string, options?: AxiosRequestConfig) {
        return AnalysisProjectsStatusApiFp(this.configuration).analysisProjectsIdCancelPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets status analysis project (RUNNING | ERROR | DONE).
     * @summary Gets status analysis project.
     * @param {string} id Id of the Analysis Project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisProjectsStatusApi
     */
    public analysisProjectsIdStatusGet(id: string, options?: AxiosRequestConfig) {
        return AnalysisProjectsStatusApiFp(this.configuration).analysisProjectsIdStatusGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates status analysis project (RUNNING | ERROR | DONE).
     * @summary Updates status analysis project.
     * @param {string} id Id of the Analysis Project
     * @param {AnalysisProjectStatus} analysisProjectStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisProjectsStatusApi
     */
    public analysisProjectsIdStatusPut(id: string, analysisProjectStatus: AnalysisProjectStatus, options?: AxiosRequestConfig) {
        return AnalysisProjectsStatusApiFp(this.configuration).analysisProjectsIdStatusPut(id, analysisProjectStatus, options).then((request) => request(this.axios, this.basePath));
    }
}


