
export const standards = ['EU', 'US'] as const;
export type standard = typeof standards[number];

export const concreteCodes = ['CBH-87', 'NB-1225001-1', 'NSR-10', 'ACI 318-11', 'EHE-08'];
export type concreteCode = typeof concreteCodes[number];

export const loadCodes = ['CTE-DB-SE-AE', 'NB-1225001', 'NSR-10'];
export type loadCode = typeof loadCodes[number];

export const euConcreteTypes = ['HA21', 'HA25', 'HA30', 'HA35', 'HA40', 'HA45', 'HA50', 'HA55', 'HA60', 'HA65', 'HA70'];
export type euConcreteType = typeof euConcreteTypes[number];

export const usConcreteTypes = ['2500psi', '3000psi', '4000psi', '5000psi', '6000psi', '7000psi', '8000psi', '9000psi'];
export type usConcreteType = typeof usConcreteTypes[number];

export const euSteelTypes = ['B400SD', 'B500SD'];
export type euSteelType = typeof euSteelTypes[number];

export const usSteelTypes = ['60ksi', '75ksi'];
export type usSteelType = typeof usSteelTypes[number];
