import { MatrixPolarCommand, MatrixRandomCommand, MatrixRectangularCommand } from "../../commands/edition/matrix";
import { getBoundingBoxMultiObj } from "../../../lib/math/box";
import { getCenterMinZMultiObjData } from "../../geometries/centroids";
import { groupCreate } from "../../geometries/group";
import { applyNoise, generatePolarMatrixPoints, generateRectangularMatrixPoints } from "../../geometries/matrix";
import { vectorDist3D } from "../../math/distance";
import { IPoint } from "../../math/types";
import { MultiEdition } from "../edition-multi";
import { cadOpType } from "../factory";
import { settingOp, settingsOpModes } from "../step-operations";
import { dataInfoProperty } from "lib/properties/properties";
import { getUserAngleUnitSufix, radAngleToUser, userAngleToRad } from '../../general-settings';
import { cloneDataModel } from "lib/models/model-creator/datamodel-factory";
export interface IMatrixBaseParameters {
  rowNumber: number;
  rowSeparation: number;
  columnNumber: number;
  columnSeparation: number;
  levelNumber: number;
  levelSeparation: number;
  alternate: boolean;
}
export interface IMatrixRandomParameters extends IMatrixBaseParameters {
  noiseX: number;
  noiseY: number;
  noiseZ: number;
  isRotated: boolean;
}
export interface IMatrixPolarParameters extends IMatrixBaseParameters {
  radius: number;
  azimutStart: number;
  azimutEnd: number;
  isRotated: boolean;
}

enum matrixType { RECTANGULAR, RANDOM, POLAR }

type matrixTypes = IMatrixBaseParameters | IMatrixRandomParameters | IMatrixPolarParameters;

type matrixRectProperties = IMatrixBaseParameters & {
  matrixType: matrixType.RECTANGULAR
  rowTotalDist: number;
  columnTotalDist: number;
  levelTotalDist: number;
}
type matrixRandProperties = IMatrixRandomParameters & {
  matrixType: matrixType.RANDOM
  rowTotalDist: number;
  columnTotalDist: number;
  levelTotalDist: number;
}
type matrixPolarProperties = IMatrixPolarParameters & {
  matrixType: matrixType.POLAR
  columnTotalDist: number;
  levelTotalDist: number;
}
type matrixProperties = matrixRectProperties | matrixRandProperties | matrixPolarProperties;

export class MatrixOP extends MultiEdition {

  public opType = cadOpType.MATRIX;

  public currMatrixType: matrixType = matrixType.RECTANGULAR;
  public rectMatrix: IMatrixBaseParameters;
  public randomMatrix: IMatrixRandomParameters;
  public polarMatrix: IMatrixPolarParameters;

  public centerPoint: IPoint;
  public matrixObjs: THREE.Object3D[][] = [];
  public matrixGroup: THREE.Group;

  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([
      {
        infoMsg: "Select objects",
        stepMode: settingsOpModes.SELECTOBJS,
        multiSelect: true,
        enableSelectMarks: true,
        filterFun: () => true,
        endStepCallback: async () => {
          this.unRegisterRaycast();
          this.registerInputs();
          this.registerUpdaters();
          this.initializeSnap();
          this.iniMatrixParams();
        },
      },
      {
        infoMsg: "Specify base point",
        stepMode: settingsOpModes.DEFAULTXYZ,
        cmdLineListener: this.addPointFromExt.bind(this),
        panelProp: this.setPanelProperties(),
        startStepCallback: (step: settingOp) => {
          step.panelProp = this.setPanelProperties();
        },
        endStepCallback: async () => {
          this.save();
          this.endOperation();
        },
      },
    ]);
  }

  private setPanelProperties() {
    let panelProp: dataInfoProperty<matrixProperties> = {};
    panelProp.matrixType = {
      publicName: "Matrix type",
      value: this.currMatrixType,
      editable: true,
      type: "list",
      list: ["Rectangular", "Random", "Polar"],
    }
    if (this.rectMatrix && this.currMatrixType === matrixType.RECTANGULAR) {
      this.setPanelPropertiesRectangular(this.rectMatrix, panelProp);
    } else if (this.randomMatrix && this.currMatrixType === matrixType.RANDOM) {
      this.setPanelPropertiesRandom(panelProp);
    } else if (this.polarMatrix && this.currMatrixType === matrixType.POLAR) {
      this.setPanelPropertiesPolar(panelProp);
    }
    return {
      propValue: panelProp,
      propCallback: this.changeMatrixParams.bind(this),
    };
  }
  private setPanelPropertiesRectangular(matrixProp: matrixTypes, panelProp: dataInfoProperty<matrixRectProperties>) {
    panelProp.rowNumber = {
      type: "number",
      title: "Number of elements",
      publicName: "Rows",
      value: matrixProp.rowNumber,
      precision: 1,
    }
    panelProp.columnNumber = {
      type: "number",
      publicName: "Columns",
      value: matrixProp.columnNumber,
      precision: 1,
    }
    panelProp.levelNumber = {
      type: "number",
      publicName: "Levels",
      value: matrixProp.levelNumber,
      precision: 1,
    }
    panelProp.alternate = {
      publicName: "Alternate",
      value: matrixProp.alternate,
      type: "checkbox",
    }
    panelProp.rowSeparation = {
      type: "number",
      title: "Distance between elements",
      publicName: "Rows",
      value: matrixProp.rowSeparation,
      precision: 3,
    }
    panelProp.columnSeparation = {
      type: "number",
      publicName: "Columns",
      value: matrixProp.columnSeparation,
      precision: 3,
    }
    panelProp.levelSeparation = {
      type: "number",
      publicName: "Level",
      value: matrixProp.levelSeparation,
      precision: 3,
    }
    panelProp.rowTotalDist = {
      type: "number",
      title: "Total distance",
      publicName: "Rows",
      value: getTotalSepRows(matrixProp),
      precision: 3,
      customCb: (val: number) => {
        const prop = this.getCurrMatrixProperty();
        prop.rowSeparation = setTotalSepRows(val, prop);
        this.generateMatrix();
      },
    }
    panelProp.columnTotalDist = {
      type: "number",
      publicName: "Columns",
      value: getTotalSepColumns(matrixProp),
      precision: 3,
      customCb: (val: number) => {
        const prop = this.getCurrMatrixProperty();
        prop.columnSeparation = setTotalSepColumns(val, prop);
        this.generateMatrix();
      },
    }
    panelProp.levelTotalDist = {
      type: "number",
      publicName: "Level",
      value: getTotalSepLevels(matrixProp),
      precision: 3,
      customCb: (val: number) => {
        const prop = this.getCurrMatrixProperty();
        prop.levelSeparation = setTotalSepLevels(val, prop);
        this.generateMatrix();
      },
    }
    return panelProp;
  }
  private setPanelPropertiesRandom(panelProp: dataInfoProperty<matrixRandProperties>) {
    this.setPanelPropertiesRectangular(this.randomMatrix, panelProp);
    panelProp.noiseX = {
      type: "number",
      title: "Noise factors",
      publicName: "noiseX",
      value: this.randomMatrix.noiseX,
      precision: 3,
    }
    panelProp.noiseY = {
      type: "number",
      publicName: "noiseY",
      value: this.randomMatrix.noiseY,
      precision: 3,
    }
    panelProp.noiseZ = {
      type: "number",
      publicName: "noiseZ",
      value: this.randomMatrix.noiseZ,
      precision: 3,
    }
    panelProp.isRotated = {
      publicName: "isRotated",
      value: this.randomMatrix.isRotated,
      type: "checkbox",
    }
    return panelProp;
  }
  private setPanelPropertiesPolar(panelProp: dataInfoProperty<matrixPolarProperties>) {
    panelProp.rowNumber = {
      type: "number",
      title: "Number of elements",
      publicName: "Rows",
      value: this.polarMatrix.rowNumber,
      precision: 1,
    }
    panelProp.columnNumber = {
      type: "number",
      publicName: "Columns",
      value: this.polarMatrix.columnNumber,
      precision: 1,
    }
    panelProp.levelNumber = {
      type: "number",
      publicName: "Levels",
      value: this.polarMatrix.levelNumber,
      precision: 1,
    }
    panelProp.alternate = {
      publicName: "Alternate",
      value: this.polarMatrix.alternate,
      type: "checkbox",
    }
    panelProp.columnSeparation = {
      type: "number",
      title: "Distance between elements",
      publicName: "Columns",
      value: this.polarMatrix.columnSeparation,
      precision: 3,
    }
    panelProp.levelSeparation = {
      type: "number",
      publicName: "Level",
      value: this.polarMatrix.levelSeparation,
      precision: 3,
    }
    panelProp.columnTotalDist = {
      type: "number",
      title: "Total distance",
      publicName: "Columns",
      value: getTotalSepColumns(this.polarMatrix),
      precision: 3,
      customCb: (val: number) => {
        const prop = this.getCurrMatrixProperty();
        prop.columnSeparation = setTotalSepColumns(val, prop);
        this.generateMatrix();
      },
    }
    panelProp.levelTotalDist = {
      type: "number",
      publicName: "Level",
      value: getTotalSepLevels(this.polarMatrix),
      precision: 3,
      customCb: (val: number) => {
        const prop = this.getCurrMatrixProperty();
        prop.levelSeparation = setTotalSepLevels(val, prop);
        this.generateMatrix();
      },
    }
    panelProp.radius = {
      type: "number",
      title: "Other options",
      publicName: "radius",
      value: this.polarMatrix.radius,
      precision: 3,
      units: "m",
    }
    panelProp.azimutStart = {
      type: "number",
      publicName: "azimutStart",
      value: radAngleToUser(this.polarMatrix.azimutStart),
      precision: 4,
      units: getUserAngleUnitSufix(),
    }
    panelProp.azimutEnd = {
      type: "number",
      publicName: "azimutEnd",
      value: radAngleToUser(this.polarMatrix.azimutEnd),
      precision: 4,
      units: getUserAngleUnitSufix(),
    }
    panelProp.isRotated = {
      publicName: "isRotated",
      value: this.polarMatrix.isRotated,
      editable: true,
      type: "checkbox",
    }
    return panelProp;
  }
  public changeMatrixParams = (newMatrix: matrixProperties) => {
    if (this.currMatrixType !== newMatrix.matrixType) {
      this.currMatrixType = newMatrix.matrixType;
      this.generateMatrix();
    } else {
      const prop = this.getCurrMatrixProperty();
      prop.rowNumber = newMatrix.rowNumber;
      prop.rowSeparation = newMatrix.rowSeparation;
      prop.columnNumber = newMatrix.columnNumber;
      prop.columnSeparation = newMatrix.columnSeparation;
      prop.levelNumber = newMatrix.levelNumber;
      prop.levelSeparation = newMatrix.levelSeparation;
      prop.alternate = newMatrix.alternate;
      switch (this.currMatrixType) {
        case matrixType.RANDOM:
          (prop as IMatrixRandomParameters).noiseX = (newMatrix as matrixRandProperties).noiseX;
          (prop as IMatrixRandomParameters).noiseY = (newMatrix as matrixRandProperties).noiseY;
          (prop as IMatrixRandomParameters).noiseZ = (newMatrix as matrixRandProperties).noiseZ;
          (prop as IMatrixRandomParameters).isRotated = (newMatrix as matrixRandProperties).isRotated;
          break;
        case matrixType.POLAR:
          (prop as IMatrixPolarParameters).radius = (newMatrix as matrixPolarProperties).radius;
          (prop as IMatrixPolarParameters).azimutStart = userAngleToRad((newMatrix as matrixPolarProperties).azimutStart);
          (prop as IMatrixPolarParameters).azimutEnd = userAngleToRad((newMatrix as matrixPolarProperties).azimutEnd);
          (prop as IMatrixPolarParameters).isRotated = (newMatrix as matrixPolarProperties).isRotated;
          break;
        default:
          console.log("Incorrect matrix received on changeMatrixParams()");
      }
      this.generateMatrix();
    }
  }

  private iniMatrixParams() {
    this.centerPoint = getCenterMinZMultiObjData(this.objDataOrigin);
    const bbox = getBoundingBoxMultiObj(this.objDataOrigin);
    this.rectMatrix = {
      rowNumber: 3,
      rowSeparation: bbox.max.y - bbox.min.y,
      columnNumber: 4,
      columnSeparation: bbox.max.x - bbox.min.x,
      levelNumber: 1,
      levelSeparation: 1,
      alternate: false,
    };
    this.randomMatrix = {
      rowNumber: 3,
      rowSeparation: bbox.max.y - bbox.min.y,
      columnNumber: 4,
      columnSeparation: bbox.max.x - bbox.min.x,
      levelNumber: 1,
      levelSeparation: 1,
      noiseX: 0.6,
      noiseY: 0.6,
      noiseZ: 0.6,
      isRotated: true,
      alternate: false,
    };
    this.polarMatrix = {
      rowNumber: 3,
      rowSeparation: bbox.max.y - bbox.min.y,
      columnNumber: 4,
      columnSeparation: bbox.max.x - bbox.min.x,
      levelNumber: 1,
      levelSeparation: 1,
      radius: vectorDist3D(bbox.min, bbox.max) * 0.5,
      azimutStart: 0,
      azimutEnd: 2 * Math.PI,
      isRotated: true,
      alternate: false,
    };
    this.generateMatrix();
  }

  private generateMatrix() {
    if (this.finished === false) {
      if (this.matrixGroup) {
        for (let i = this.matrixGroup.children.length - 1; i >= 0; i--) {
          this.matrixGroup.remove(this.matrixGroup.children[i]);
        }
      }
      if (this.matrixGroup === undefined) {
        this.matrixGroup = groupCreate();
        this.saveToTempScene(this.matrixGroup);
      }
      if (this.currMatrixType === matrixType.RECTANGULAR) {
        this.generateRectangleMatrix(this.rectMatrix);
      } else if (this.currMatrixType === matrixType.RANDOM) {
        this.generateRandomMatrix(this.randomMatrix);
      } else if (this.currMatrixType === matrixType.POLAR) {
        this.generatePolarMatrix(this.polarMatrix);
      }
      this.updatePanelData();
    }
  }
  private updatePanelData() {
    this.settingsOpManager.currCfg = {
      ...this.settingsOpManager.currCfg,
      panelProp: this.setPanelProperties(),
    };
    this.settingsOpManager.dispatchUpdateCurrStep();
  }
  private getCurrMatrixProperty() {
    if (this.currMatrixType === matrixType.RECTANGULAR) {
      return this.rectMatrix;
    } else if (this.currMatrixType === matrixType.RANDOM) {
      return this.randomMatrix;
    } else {
      return this.polarMatrix;
    }
  }

  private createMatrixAuxObjsForIndex(index: number) {
    this.matrixObjs[index] = [];
    for (const objData of this.objDataOrigin) {
      const auxObj = cloneDataModel(objData);
      auxObj.translate({ x: -this.centerPoint.x, y: -this.centerPoint.y, z: -this.centerPoint.z })
      this.setAuxObj(auxObj.graphicObj);
      this.matrixObjs[index].push(auxObj.graphicObj);
    }
  }

  private createMatrixAuxObjs(position: IPoint[], rotation?: number[]) {
    for (let i = 0, l = position.length; i < l; i++) {
      const pos = position[i];
      const rot = rotation !== undefined ? rotation[i] : undefined;
      // crear objetos fantasma
      this.createMatrixAuxObjsForIndex(i);
      this.matrixGroup.add(groupCreate(this.matrixObjs[i]));
      this.matrixGroup.children[i].position.set(pos.x, pos.y, pos.z);
      if (rot) this.matrixGroup.children[i].rotateZ(rot);
    }
    this.matrixGroup.position.set(
      this.centerPoint.x,
      this.centerPoint.y,
      this.centerPoint.z
    );
  }

  private generateRectangleMatrix(MatrixParam: IMatrixBaseParameters | IMatrixRandomParameters) {
    this.matrixObjs.length = 0;
    const position: IPoint = { x: 0, y: 0, z: 0 };
    const newPos = generateRectangularMatrixPoints(
      position,
      MatrixParam.columnNumber,
      MatrixParam.columnSeparation,
      MatrixParam.rowNumber,
      MatrixParam.rowSeparation,
      MatrixParam.levelNumber,
      MatrixParam.levelSeparation,
      MatrixParam.alternate
    );

    this.createMatrixAuxObjs(newPos);
  }

  private generateRandomMatrix(MatrixParam: IMatrixRandomParameters) {
    this.generateRectangleMatrix(MatrixParam);
    for (let i: number = 0, l: number = this.matrixObjs.length; i < l; i++) {
      const objs: THREE.Object3D[] = this.matrixObjs[i];
      applyNoise(
        objs,
        MatrixParam.noiseX,
        MatrixParam.noiseY,
        MatrixParam.noiseZ,
        MatrixParam.isRotated,
        true
      );
    }
  }

  private generatePolarMatrix(MatrixParam: IMatrixPolarParameters) {
    this.matrixObjs.length = 0;
    const position: IPoint = { x: 0, y: 0, z: 0 };
    const [newPos, newRot] = generatePolarMatrixPoints(
      position,
      MatrixParam.azimutStart,
      MatrixParam.azimutEnd,
      MatrixParam.radius,
      MatrixParam.isRotated,
      MatrixParam.columnNumber,
      MatrixParam.columnSeparation,
      MatrixParam.rowNumber,
      MatrixParam.levelNumber,
      MatrixParam.levelSeparation,
      MatrixParam.alternate
    );
    this.createMatrixAuxObjs(newPos, newRot);
  }

  public start() {
    this.iniSettingsOp();
    this.registerCancel();
    this.setStartObjs();
    if (this.objDataOrigin.length === 0) {
      this.registerRaycast();
    } else {
      this.setNextStep();
    }
  }

  public setLastPoint(): void {
    this.setNextStep();
  }

  public moveLastPoint(pto: IPoint) {
    this.matrixGroup.position.set(pto.x, pto.y, pto.z);
    this.matrixGroup.updateMatrix();
  }

  public save() {
    if (this.graphicProcessor && this.centerPoint) {
      let command = undefined;
      if (this.currMatrixType === matrixType.RECTANGULAR) {
        command = new MatrixRectangularCommand(
          this.objDataOrigin,
          this.lastPoint,
          this.rectMatrix,
          this.getCurrentSceneId(),
          this.graphicProcessor
        );
      } else if (this.currMatrixType === matrixType.RANDOM) {
        command = new MatrixRandomCommand(
          this.objDataOrigin,
          this.lastPoint,
          this.randomMatrix,
          this.getCurrentSceneId(),
          this.graphicProcessor
        );
      } else if (this.currMatrixType === matrixType.POLAR) {
        command = new MatrixPolarCommand(
          this.objDataOrigin,
          this.lastPoint,
          this.polarMatrix,
          this.getCurrentSceneId(),
          this.graphicProcessor
        );
      }
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }

  public cancelOperation(): void {
    if (this.finished === false) {
      const step = this.settingsOpManager.currCfg;
      if (step.stepMode === settingsOpModes.SELECTOBJS && step.multiSelect && this.objDataOrigin.length) {
        this.setNextStep();
      } else {
        super.cancelOperation();
      }
    }
  }
}

function getTotalSepRows(matrixParam: matrixTypes): number {
  return (matrixParam.rowNumber - 1) * matrixParam.rowSeparation;
}
function setTotalSepRows(rowTotal: number, matrixParam: matrixTypes): number {
  return (matrixParam.rowNumber > 1) ? (rowTotal / (matrixParam.rowNumber - 1)) : 1;
}
function getTotalSepColumns(matrixParam: matrixTypes): number {
  return (matrixParam.columnNumber - 1) * matrixParam.columnSeparation;
}
function setTotalSepColumns(colTotal: number, matrixParam: matrixTypes): number {
  return (matrixParam.columnNumber > 1) ? (colTotal / (matrixParam.columnNumber - 1)) : 1;
}
function getTotalSepLevels(matrixParam: matrixTypes): number {
  return (matrixParam.levelNumber - 1) * matrixParam.levelSeparation;
}
function setTotalSepLevels(levelTotal: number, matrixParam: matrixTypes): number {
  return (matrixParam.levelNumber > 1) ? (levelTotal / (matrixParam.levelNumber - 1)) : 1;
}