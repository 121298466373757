import { getUserAngleUnitSufix } from "lib/general-settings";
import { GraphicProcessor } from "lib/graphic-processor";
import { Cad3dOp } from "lib/operations/base";
import { TransformEdition } from "lib/operations/transform/transform-edition";
import { EventManager } from "lib/operations/event-manager";
import { coordModes, IDefaultXYZMode, xyzModes } from "lib/operations/step-operations";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import LockIcon from "shared/components/icons/lock";
import { useCoordinatePositionType } from "../position/hooks/use-position";

interface Props {
  graphicProc: GraphicProcessor;
  useCoordinates: useCoordinatePositionType;
  setFreeze: (freeze: boolean) => void;
  currentStep: IDefaultXYZMode;
  currentOperation: Cad3dOp;
  freeze: boolean;
  visible: boolean;
}
const DynamicInputXYZ: FC<Props> = (props) => {

  const {
    graphicProc,
    useCoordinates,
    setFreeze,
    freeze,
    visible,
    currentStep,
    currentOperation,
  } = props;

  const eventManager = useMemo(() => new EventManager(graphicProc), [graphicProc]);

  const inputX = useRef<HTMLInputElement>(null);
  const inputY = useRef<HTMLInputElement>(null);
  const inputZ = useRef<HTMLInputElement>(null);
  const [focusedInput, setFocusedInput] = useState<HTMLInputElement | null>(inputX.current);

  const {
    setLastPoint, stateCoord,
    setStateCoord, currXyzMode,
    setCurrXyzMode, coordMode,
    setCoordMode, dispatchSetCoordinates,
  } = useCoordinates;

  const [edittingX, setEdittingX] = useState(false);
  const [edittingY, setEdittingY] = useState(false);
  const [edittingZ, setEdittingZ] = useState(false);

  // ***************************************************************

  useEffect(() => {
    const input = inputX.current;
    setFocusedInput(input);
    return () => {
      input?.blur();
    }
  }, [currentStep, inputX, setFocusedInput]);

  useEffect(() => {
    if (!freeze && visible) {
      resetEditStateInputs();
    }
  }, [freeze, visible])

  useEffect(() => {
    setCoordMode(currentStep.coordMode === coordModes.POLAR ? coordModes.POLAR : coordModes.XYZ);
    setCurrXyzMode(currentStep.xyzModes === xyzModes.REL ? xyzModes.REL : xyzModes.ABS);
    if (currentStep.xyzModes === xyzModes.REL && (currentOperation as TransformEdition).basePoint) {
      const pto = (currentOperation as TransformEdition).basePoint;
      setLastPoint(pto ?? undefined);
    }
  }, [currentOperation, currentStep, graphicProc, setCoordMode, setCurrXyzMode, setLastPoint])

  useEffect(() => {
    if (focusedInput) {
      if (focusedInput === inputX.current && !edittingX) {
        focusedInput.focus();
        focusedInput.select();
      } else if (focusedInput === inputY.current && !edittingY) {
        focusedInput.focus();
        focusedInput.select();
      } else if (focusedInput === inputZ.current && !edittingZ) {
        focusedInput.focus();
        focusedInput.select();
      }
    }
  });

  // ***************************************************************

  const tabHandler = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    setFreeze(false);
    const elem = event.target;
    if (inputX.current === elem) {
      if (edittingX) {
        const x = parseFloat((elem as HTMLInputElement).value);
        if (!isNaN(x)) {
          setStateCoord({ ...stateCoord, xCoord: x, xLocked: true });
        }
      } else {
        setStateCoord({ ...stateCoord, xLocked: false });
      }
      setEdittingX(false);
      if (inputY?.current) setFocusedInput(inputY.current);

    } else if (inputY.current === elem) {
      if (edittingY) {
        const y = parseFloat((elem as HTMLInputElement).value);
        if (!isNaN(y)) {
          setStateCoord({ ...stateCoord, yCoord: y, yLocked: true });
        }
      } else {
        setStateCoord({ ...stateCoord, yLocked: false });
      }
      setEdittingY(false);
      if (inputZ?.current) setFocusedInput(inputZ.current);
      else setFocusedInput(inputX.current);

    } else if (inputZ.current === elem) {
      if (edittingZ) {
        const z = parseFloat((elem as HTMLInputElement).value);
        if (!isNaN(z)) {
          setStateCoord({ ...stateCoord, zCoord: z, zLocked: true });
        }
      } else {
        setStateCoord({ ...stateCoord, zLocked: false });
      }
      setEdittingZ(false);
      if (inputX?.current) {
        event.preventDefault();
        setFocusedInput(inputX.current);
      }
    }
  }, [edittingX, edittingY, edittingZ, setFreeze, setStateCoord, stateCoord]);

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Tab" || event.key === ",") {
      event.preventDefault();
      tabHandler(event);
      resetEditStateInputs();

    } else if (event.key === "Enter") {
      if (currentStep.cmdLineListener) {
        const v = (event.target as HTMLInputElement).value;
        const newVal = parseFloat(v);
        if (isNaN(newVal)) {
          currentStep.cmdLineListener(v);
        } else {
          dispatchSetCoordinates();
        }
        event.preventDefault();
        setFocusedInput(inputX.current);
        setStateCoord({
          ...stateCoord,
          xLocked: false,
          yLocked: false,
          zLocked: false,
        });
        setFreeze(false);
      }
      resetEditStateInputs();

    } else if (event.key === "Escape") {
      currentOperation?.cancelOperation();
      resetEditStateInputs();

    } else {
      console.log("[DynamicInputXYZ] KEYPRESSED " + event.key + " " + event.ctrlKey + " " + event.altKey);
      setFreeze(true);
    }
  }, [currentOperation, currentStep, dispatchSetCoordinates, setFreeze, setStateCoord, stateCoord, tabHandler]);

  // ***************************************************************

  const handleSecondMouseUp = useCallback((event: PointerEvent) => {
    if (currentStep.cmdLineListener) {
      const v = (inputX.current as HTMLInputElement).value;
      const newVal = parseFloat(v);
      if (isNaN(newVal)) {
        currentStep?.cmdLineListener(v);
        resetEditStateInputs();
      } else {
        dispatchSetCoordinates();
      }
    } else {
      currentOperation.cancelOperation();
    }
  }, [currentStep, dispatchSetCoordinates, currentOperation]);

  const handleMainMouseUp = useCallback((event: PointerEvent) => {
    dispatchSetCoordinates();
  }, [dispatchSetCoordinates]);

  useEffect(() => {
    eventManager.connectMouseSecondUpEvent(handleSecondMouseUp);
    eventManager.connectMouseMainUpEvent(handleMainMouseUp);
    return () => {
      eventManager.disconnectMouseSecondUpEvent(handleSecondMouseUp);
      eventManager.disconnectMouseMainUpEvent(handleMainMouseUp);
    };
  }, [eventManager, handleMainMouseUp, handleSecondMouseUp]);

  // ***************************************************************

  const resetEditStateInputs = () => {
    setEdittingX(false);
    setEdittingY(false);
    setEdittingZ(false);
  }
  const resetInputs = () => {
    resetEditStateInputs();
    setStateCoord({
      ...stateCoord,
      xLocked: false,
      yLocked: false,
      zLocked: false,
    });
  }

  return (
    <span className="text-black space-x-0.5 flex items-center">
      {currXyzMode === xyzModes.REL && <div className="text-gray-300 bg-gray-700 p-1">{"@"}</div>}
      <input
        id="dynamic-input-0"
        className="p-1 rounded-sm"
        type="string"
        ref={inputX}
        size={10}
        value={!edittingX ? stateCoord.xCoord.toFixed(3) : undefined}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === "Control" || event.key === "Alt" || event.key === "AltGraph") {
            event.preventDefault();
          } else if (event.key === "@") {
            event.preventDefault();
            setCoordMode(coordModes.XYZ);
            setCurrXyzMode(xyzModes.REL);
            resetInputs();
          } else if (event.key === "#") {
            event.preventDefault();
            setCoordMode(coordModes.XYZ);
            setCurrXyzMode(xyzModes.ABS);
            resetInputs();
          } else if (event.key === "<" && freeze) {
            event.preventDefault();
            setCurrXyzMode(xyzModes.ABS);
            setCoordMode(coordModes.POLAR);
            resetInputs();
            tabHandler(event);
          } else {
            setEdittingX(true);
            handleKeyDown(event);
          }
        }}
        onChange={() => { }}
      />
      {stateCoord.xLocked && <LockIcon className="h-4 text-gray-500" />}

      {coordMode === coordModes.POLAR && <div className="text-gray-300 bg-gray-700 p-1">{"<"}</div>}
      <div className="relative flex items-center col-span-4">
        <input
          id="dynamic-input-1"
          className="p-1 rounded-sm"
          type="string"
          ref={inputY}
          size={10}
          value={!edittingY ? stateCoord.yCoord.toFixed(3) : undefined}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Control" || event.key === "Alt" || event.key === "AltGraph") {
              event.preventDefault();
            } else {
              setEdittingY(true);
              handleKeyDown(event);
            }
          }}
          onChange={() => { }} />
        {coordMode === coordModes.POLAR &&
          <div className="absolute right-1 text-gray-700">{getUserAngleUnitSufix()}</div>
        }
      </div>
      {stateCoord.yLocked && <LockIcon className="h-4 text-gray-500" />}

      {coordMode !== coordModes.POLAR &&
        <input
          id="dynamic-input-2"
          className="p-1 rounded-sm"
          type="string"
          ref={inputZ}
          size={10}
          value={!edittingZ ? stateCoord.zCoord.toFixed(3) : undefined}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Control" || event.key === "Alt" || event.key === "AltGraph") {
              event.preventDefault();
            } else {
              setEdittingZ(true);
              handleKeyDown(event);
            }
          }}
          onChange={() => { }}
        />}
      {coordMode !== coordModes.POLAR && stateCoord.zLocked &&
        <LockIcon className="h-4 text-gray-500 " />
      }
    </span>
  );
};

export default DynamicInputXYZ;