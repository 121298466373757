import { Hypothesis, hypothesisTypes, loadHypothesisList } from 'lib/models-struc/hypothesis/hypothesis';
import { v4 as uuid } from 'uuid'
import { HypothesisApi } from "./api";

const hypothesisOK = 200;

export async function getHypothesisFromStandardCode(standardCode: string = "EC2"): Promise<Hypothesis[]> {
  let hypothesis: Hypothesis[] = [];

  const hypothesisEndPoint = new HypothesisApi();
  const hypothesisRes = await hypothesisEndPoint.standardCodesIdHypothesisGet(standardCode);

  if (hypothesisRes.status === hypothesisOK) {
    for (let hypo of hypothesisRes.data) {
      const mappedHypo: Hypothesis = {
        uid: uuid(),
        name: hypo.id,
        favourableLoadFactor: hypo.favourable_load_factor!,
        unfavourableLoadFactor: hypo.unfavourable_load_factor!,
        massRatio: hypo.mass_ratio!,
        psi0: hypo.psi_0!,
        psi1: hypo.psi_1!,
        psi2: hypo.psi_2!,
        unfavorableSse: hypo.unfavorable_sse!,
        service: hypo.service!,
        constructionPhase: hypo.construction_phase!,
        type: hypo.type! as hypothesisTypes,
        tag: hypo.id! as loadHypothesisList,
      }
      hypothesis.push(mappedHypo);
    }
  }
  return hypothesis;
}