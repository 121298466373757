import Section from "shared/components/ui/section";
import { useMeasurements } from "../measurements/hooks/use-measurements";
import MeasurementResults from "../measurements/measurement-results";
import MeasurementEvents from "../measurements/measurement-events";
import { PrimaryButton } from "shared/components/ui/button";
import useProject from "./hooks/use-project";
import styled from "styled-components";

const Side = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const ProjectMeasurements = () => {
  const { measurements, events, projectId } = useMeasurements();
  const { calculateMeasurements } = useProject();
  return (
    <>
      <Section title="Events">
        <Side>  
        <MeasurementEvents events={events} projectId={projectId} />  
          <PrimaryButton onClick={calculateMeasurements}>
              Calculate
          </PrimaryButton>      
        </Side>
      </Section>
      <Section title="Results">
        <MeasurementResults measurements={measurements} />
      </Section>
    </>
  );
};

export default ProjectMeasurements;
