import EyeIcon from "shared/components/icons/eye";
import Modal from "shared/components/ui/modal";
import { useModal } from "shared/components/ui/modal/hooks/use-modal";
import { useEffect, useRef } from "react";
import { loadDXF } from "./utils";
import ExclamationIcon from "shared/components/icons/exclamation";

interface Props {
  errors: String[];
}

const DXFErrorsViewer = ({ errors }: Props) => {
  const { isOpen, open, close } = useModal();
  return (
    <>
      <button
        aria-label="show dxf errors"
        onClick={open}
        className="flex items-center hover:text-gray-700 focus:outline-none"
      >
        <ExclamationIcon className="cursor-pointer h-6 w-6 flex items-center hover:text-gray-700" color="red"/>
      </button>
      <Modal isOpen={isOpen} onClose={close} title={"DXF con errores"}>
        <h1 className="text-lg leading-6 font-medium text-gray-400">
          {errors?.map(error => <h2 className="text-lg leading-6 font-medium text-red-600">{error}</h2>)}
        </h1>
      </Modal>
    </>
  );
};

export default DXFErrorsViewer;
