import { Wall } from "modules/struc/models/ecore/structural";
import { growthWidth, heightType } from "lib/models-struc/types/struc-base";
import { dataModelPersistence } from "lib/input-output/database/loader";
import { objDataType } from "lib/models/types";
import { Storey } from "lib/models-struc/struc-storey";
import { IWallStretch, wallParam, wallTypes } from "lib/models-struc/types/wall";
import { IPolylineParam, setIsClosedFromPointsArray } from "lib/math/line";
import { LineSegment, OffsetGeometry } from 'modules/struc/models/ecore/geometry';
import { normalizeIpoint, pointLinePositionXY, substractIpoint } from 'lib/math/point';
import { rotatePointZ } from 'lib/math/rotate';
import { IPoint } from 'lib/math/types';
import { ExtrudedRepresentation, GeoRepresentation } from 'modules/struc/models/ecore/representation';
import { isZero } from 'lib/math/epsilon';
import { ILogger } from 'shared/utils/logger/logger';
import { EcoreImporter } from './structuralElement';

export class EcoreWallImporter extends EcoreImporter {

  constructor(
    protected ecoreElem: Wall,
    protected logger: ILogger) {
    super();
  }

  getObjData(storey: Storey, layerId: string, cssIdsRefs: string[], materialRefs: string[]): dataModelPersistence {
    const materialIndex = this.ecoreElem.material.$ref.split(".").pop()!;
    const shellCssRef = this.ecoreElem.css.$ref.split(".").pop()!;

    const { basePoint, rotation } = this.getPlacement(this.ecoreElem.placement);
    const basePto = { ...basePoint, z: basePoint.z + storey.level }

    const geom = this.ecoreElem.representation.base.base;
    const { ptos2D, normals } = this.getWallGeometry(geom);
    const holes = this.getWallHolesGeometry(basePto);

    const stretches: IWallStretch[] = [];
    for (let i = 0; i < normals.length; i++) {
      const stretch: IWallStretch = {
        normal: normals[i],
        orientation: 1,
        holes: [],
      }
      // Edge Points
      const p0 = ptos2D.points[i];
      const p1 = ptos2D.points[i + 1] ? ptos2D.points[i + 1] : ptos2D.points[0];

      // Check if hole is in edge
      for (let j = holes.length - 1; j >= 0; j--) {
        const hole = holes[j];
        const pos = pointLinePositionXY(hole[0], p0, p1);
        if (isZero(pos)) {
          stretch.holes.push({ points: hole });
        }
        holes.splice(j, 1);
      }
      stretches.push(stretch);
    }

    const param: wallParam = {
      storeyId: storey.id,
      name: this.ecoreElem.name,
      lnkObjIds: [],
      materialType: materialRefs[parseInt(materialIndex)],

      wallType: this.ecoreElem.isRetaining ? wallTypes.RETAINING : wallTypes.SHEAR,
      heightType: heightType.STOREY,

      ptos2D: ptos2D,
      widthType: growthWidth.CENTER,
      height: -this.ecoreElem.representation.direction.z!,
      stretch: stretches,
      shellCrossSectionId: cssIdsRefs[parseInt(shellCssRef)],

      basePoint: basePto,
      rotation,
      offset: { x: 0, y: 0, z: 0 },
      scale: { x: 1, y: 1, z: 1 },
    }

    return this.getDataModel(objDataType.WALL, layerId, param);
  }

  private getWallHolesGeometry(basePoint: IPoint): IPoint[][] {
    const holesGeom = this.ecoreElem.representation.substractions;
    if (holesGeom === undefined || holesGeom?.length === 0) return [];
    const holes: IPoint[][] = [];
    for (const hole of holesGeom) {
      const h = hole as ExtrudedRepresentation<GeoRepresentation<OffsetGeometry>>;
      const holeGeom = h.base.base.wiregeometry.geometries as LineSegment[];
      const holePtos: IPoint[] = [];
      const { p0, p1 } = holeGeom[0];
      let pto0 = { x: p0.x ?? 0, y: p0.y ?? 0, z: p0.z ?? 0 };
      let pto1 = { x: p1.x ?? 0, y: p1.y ?? 0, z: p1.z ?? 0 };
      holePtos.push(pto0, pto1);
      for (let i = 1; i < holeGeom.length; i++) {
        const { p1 } = holeGeom[i];
        const pto1 = { x: p1.x ?? 0, y: p1.y ?? 0, z: p1.z ?? 0 };
        holePtos.push(pto1);
      }
      holes.push(holePtos.map(p => substractIpoint(p, basePoint)));
    }
    return holes;
  }
  private getWallGeometry(geom: OffsetGeometry) {
    const wireGeom = geom.wiregeometry;
    const ptos2D: IPolylineParam = {
      points: [{ x: 0, y: 0, z: 0 }],
      arcs: [],
      isClosed: false,
    };
    const normals = [];
    for (const strecht of wireGeom.geometries) {
      const { p0, p1 } = strecht as LineSegment;
      const pto0 = { x: p0.x ?? 0, y: p0.y ?? 0, z: p0.z ?? 0 };
      const pto1 = { x: p1.x ?? 0, y: p1.y ?? 0, z: p1.z ?? 0 };
      ptos2D.points.push(pto1);

      const xVec = normalizeIpoint(substractIpoint(pto1, pto0));
      const normal = rotatePointZ(xVec, -Math.PI * 0.5);
      normals.push(normal);
    }
    setIsClosedFromPointsArray(ptos2D);
    return { ptos2D, normals };
  }
}
