import { Ecore } from "./base";
import { WireGeometry } from "./geometry";
import { GeoRepresentation } from "./representation";

export interface CrossSectionShapeSet extends Ecore {
  crosssectionshapes: CrossSectionShape[];
}
export interface ICrossSectionShapeRef extends Ecore {
  $ref: string;
}
export interface CrossSectionShape extends Ecore {
}
export interface BeamCSS extends CrossSectionShape {
}

export const defaulRectangleShape = { height: 0.3, width: 0.4 }
export interface RectangleShape extends BeamCSS {
  width?: number;
  height?: number;
}
export const defaulTShape = { wt: 0.1, ft: 0.1 }
export interface TShape extends RectangleShape {
  wt?: number;
  ft?: number;
}
export const defaulIShape = { lft: 0.1, lw: 0.4 }
export interface IShape extends TShape {
  lft?: number;
  lw?: number;
}
export const defaulUShape = { thf: 0.1, tvw: 0.1 }
export interface UShape extends RectangleShape {
  thf?: number;
  tvw?: number;
}
export interface QuadShape extends UShape {
}
export const defaulCircleShape = { radio: 0.3 }
export interface CircleShape extends BeamCSS {
  radio?: number;
}
export const defaulTubeShape = { thickness: 0.2 }
export interface TubeShape extends CircleShape {
  thickness?: number;
}
export interface CustomCSS extends BeamCSS {
  representation: GeoRepresentation<WireGeometry>;
}

// ------------------------------------------------------------

export const defaultShellParam = { thickness: 0.3 }
export interface ShellCSS extends CrossSectionShape {
  thickness?: number;
}
export const defaultWaffleShapeParam = {
  xInteraxis: 0.82,
  yInteraxis: 0.82,
  ribWidth: 0.15,
  numberOfBlocks: 6,
  outerNonstBeamWidth: 0,
  innerNonstBeamWidth: 0,
  minDropPanelEdge: 0,
  rotationAngle: 0,
  centerWaffleX: 0,
  centerWaffleY: 0,
  dropPanelRatio: 0,
  compressionLayerDepth: 0.05,
}
export interface WaffleCSS extends ShellCSS {
  xInteraxis?: number;
  yInteraxis?: number;
  ribWidth?: number;
  numberOfBlocks?: number;
  outerNonstBeamWidth?: number;
  innerNonstBeamWidth?: number;
  minDropPanelEdge?: number;
  rotationAngle?: number;
  centerWaffleX?: number;
  centerWaffleY?: number;
  dropPanelRatio?: number;
  compressionLayerDepth?: number;
}
export const defaultSandwichParam = { lowerCompressionLayerDepth: 0.05 }
export interface SandwichCSS extends WaffleCSS {
  lowerCompressionLayerDepth?: number;
}
export interface FlatCSS extends ShellCSS {
}
export interface WallCSS extends ShellCSS {
}
