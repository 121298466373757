import { linealPrecision } from "lib/general-settings";
import { ISolidMaterial } from '../../materials/solid';
import { growthWidthTypeNames, heightType } from "../../models-struc/types/struc-base";
import { wallParam } from "../../models-struc/types/wall";
import { WallData } from "lib/models/structural/wall";
import { WallEditDataCommand } from "lib/commands/structural/wall";
import { StructuralDataDefinitionHandler } from "./structural";
import { IPoint } from "lib/math/types";
import { shellCrossSectionCache } from "lib/models-struc/cross-sections-shape/shell-cross-sections/cache";
import { isWallCSSParams } from "lib/models-struc/cross-sections-shape/shell-cross-sections/types";

export type wallProp = wallParam & {
  thickness: number;
  centroid: IPoint;
  compoundGroupName: string,
  changeNormals: any,
  showAxis: any,
}
export class WallDataDefinitionHandler extends StructuralDataDefinitionHandler<wallProp> {

  protected data: WallData;

  protected buildInfoProperties() {
    super.buildInfoProperties();
    this.fillWallDefinition(this.data);
  }

  private fillWallDefinition(data: WallData) {
    const repr = data.definition;
    const wallCSSnameList: [string, string][] = [];
    const ShellCSSs = shellCrossSectionCache.getAllLoadedStyles().sort((a, b) => a.name.localeCompare(b.name));
    for (const shellCSS of ShellCSSs) {
      if (isWallCSSParams(shellCSS.parameters)) {
        wallCSSnameList.push([shellCSS.name, shellCSS.styleId]);
      }
    }
    const section = ShellCSSs.find(s => s.styleId === repr.shellCrossSectionId)!;

    this.structuralInfo.compoundGroupName = this.getStringView(data.compoundGroupName, "Complex-Name", false);
    this.structuralInfo.height = this.getNumberView(repr.height, "Height", "m", linealPrecision, repr.heightType !== heightType.STOREY);
    this.structuralInfo.shellCrossSectionId = { publicName: "Shell cross section", type: "tagList", tagList: wallCSSnameList, value: repr.shellCrossSectionId };
    this.structuralInfo.thickness = this.getNumberView(section.thickness, "Width", "m", linealPrecision, false);
    this.structuralInfo.widthType = this.getEnumView(repr.widthType, growthWidthTypeNames, "Width growth");

    this.structuralInfo.showAxis = {
      type: "button", publicName: "Show axis",
      buttonCb: () => { this.data.showAxisHelper(this.graphicProcessor) }
    }
    this.structuralInfo.changeNormals = {
      type: "button", publicName: "Invert Normals",
      buttonCb: () => {
        const def = this.data.definition
        def.stretch.forEach(s => s.orientation *= -1);
        this.data.regenerateDefinition();
      }
    }
  }

  private checkNewStructural(newStructural: wallProp) {
    if (!newStructural) { return false; }
    if (newStructural.shellCrossSectionId !== undefined && !this.checkString(newStructural.shellCrossSectionId)) { return false; }
    if (!this.checkStructuralNewDefinition(newStructural)) { return false; }
    if (!this.checkNumber(newStructural.height)) { return false; }
    if (!this.checkEnum(newStructural.widthType)) { return false; }
    return true;
  }

  private changedWallNewStructural(oldDefinition: wallParam, newDefinition: wallProp) {
    let def = oldDefinition;
    let changed: boolean = false;
    if (newDefinition) {
      const shellCrossSectionId = this.changedString(def.shellCrossSectionId, newDefinition.shellCrossSectionId);
      if (shellCrossSectionId !== null) {
        def.shellCrossSectionId = shellCrossSectionId;
        changed = true;
      }
      const height = this.changedNumber(def.height, newDefinition.height);
      if (height !== null) {
        def.height = height;
        changed = true;
      }
      const widthType = this.changedEnum(def.widthType, newDefinition.widthType);
      if (widthType !== null) {
        def.widthType = widthType;
        changed = true;
      }
    }
    return (changed ? def : null);
  }

  public saveAndRegenerate = (newDefinition: wallProp) => {
    const wallDefinition = this.checkAndChangedDefinition(this.data.cloneDefinition(), newDefinition);
    if (wallDefinition) {
      const command = new WallEditDataCommand(this.data, wallDefinition, null, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }

  public saveAndRegenerateMaterial = (newMaterial: ISolidMaterial) => {
    const wallMaterial = this.checkAndChangedSolidMaterial(this.data.material, newMaterial);
    if (wallMaterial) {
      const command = new WallEditDataCommand(this.data, null, wallMaterial, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }

  public saveAndRegenerateStruct = (newStructural: wallProp) => {
    if (this.checkNewStructural(newStructural)) {
      const wallStrucParam = this.changedWallNewStructural(this.data.cloneDefinition(), newStructural);
      if (wallStrucParam) {
        const command = new WallEditDataCommand(this.data, wallStrucParam, null, this.graphicProcessor);
        this.graphicProcessor.storeAndExecute(command);
      }
    }
  }
}