const QuadrantIcon = ({ ...props }) => {
  return (
    <svg className="h-5 w-5" viewBox="0 0 100 100" fill="none" {...props}>
      <path
        d="M61.1659 11.4013V0H35.8236V12.3648C23.9732 16.8207 14.9089 26.6524 11.434 38.8189H0V64.1546H12.3387C16.7095 75.7347 26.2775 84.7227 38.0886 88.3764V99.9826H63.4309V87.9142C75.1025 83.8006 84.2932 74.4245 88.1889 62.6548H100V37.3147H88.178C84.0404 24.8103 73.8141 14.9983 61.1659 11.4013ZM41.7967 5.97096H55.1949V19.3626H41.7967V5.97096ZM5.97314 44.792H19.3713V58.1836H5.97314V44.792ZM57.4621 94.016H44.0705V80.6222H57.4621V94.016ZM63.4352 81.5814V74.6512H38.0973V82.1307C29.53 78.9632 22.5148 72.456 18.7369 64.1546H25.3423V38.8189H17.636C20.6706 29.918 27.2606 22.6391 35.8214 18.7544V25.3335H61.1637V17.6077C70.5594 20.8035 78.2111 28.076 81.8735 37.3256H74.6534V62.6635H81.8953C78.5163 71.1654 71.839 78.0171 63.4352 81.5814ZM94.0269 56.6904H80.6287V43.2987H94.0269V56.6904Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default QuadrantIcon;
