import * as THREE from "three";
import { GraphicProcessor } from "lib/graphic-processor";
import { IBox, isPointInBox } from "lib/math/box";
import { isLineIntersectBbox } from "lib/math/intersections";
import { copyIPoint } from "lib/math/point";
import { IPoint } from "lib/math/types";
import { IObjData } from "lib/models/objdata";

export class SelectionBox {

  private graphicProcessor: GraphicProcessor;
  camera: THREE.Camera;
  private deep: number;

  startPoint: IPoint | undefined;
  endPoint: IPoint | undefined;
  bbox: IBox;
  intersect: boolean = false;

  private collection: IObjData[] = [];

  private frustum = new THREE.Frustum();

  private vecNear = new THREE.Vector3();
  private vecTopLeft = new THREE.Vector3();
  private vecTopRight = new THREE.Vector3();
  private vecDownRight = new THREE.Vector3();
  private vecDownLeft = new THREE.Vector3();

  constructor(camera: THREE.Camera, graphicProcessor: GraphicProcessor, deep?: number) {
    this.graphicProcessor = graphicProcessor;
    this.camera = camera;
    this.deep = deep || Number.MAX_VALUE;
  }
  select = () => {
    this.collection = [];
    this.updateFrustum();
    this.searchDataInFrustum();
    return this.collection;
  }

  private updateFrustum = () => {
    if (this.startPoint && this.endPoint) {
      const tmpPoint = new THREE.Vector3();

      const vecFarTopLeft = new THREE.Vector3();
      const vecFarTopRight = new THREE.Vector3();
      const vecFarDownRight = new THREE.Vector3();
      const vecFarDownLeft = new THREE.Vector3();

      const vectemp1 = new THREE.Vector3();
      const vectemp2 = new THREE.Vector3();
      const vectemp3 = new THREE.Vector3();

      const startPoint = copyIPoint(this.startPoint);
      const endPoint = copyIPoint(this.endPoint);

      // Avoid invalid frustum
      if (startPoint.x === endPoint.x) {
        endPoint.x += Number.EPSILON;
      }
      if (startPoint.y === endPoint.y) {
        endPoint.y += Number.EPSILON;
      }

      (this.camera as THREE.PerspectiveCamera).updateProjectionMatrix();
      this.camera.updateMatrixWorld();
      const mat = new THREE.Matrix4().multiplyMatrices(this.camera.projectionMatrix, this.camera.matrixWorldInverse)
      this.frustum.setFromProjectionMatrix(mat);

      let xMin = Math.min(this.startPoint.x, this.endPoint.x);
      let yMax = Math.max(this.startPoint.y, this.endPoint.y);
      let xMax = Math.max(this.startPoint.x, this.endPoint.x);
      let yMin = Math.min(this.startPoint.y, this.endPoint.y);
      this.bbox = { min: { x: xMin, y: yMin, z: 0 }, max: { x: xMax, y: yMax, z: 0 } };

      if ((this.camera as THREE.PerspectiveCamera).isPerspectiveCamera) {

        tmpPoint.x = startPoint.x;
        tmpPoint.y = startPoint.y;
        tmpPoint.z = startPoint.z;

        tmpPoint.x = this.bbox.min.x;
        tmpPoint.y = this.bbox.max.y;
        endPoint.x = this.bbox.max.x;
        endPoint.y = this.bbox.min.y;

        this.vecNear.setFromMatrixPosition(this.camera.matrixWorld);
        this.vecTopLeft.copy(tmpPoint);
        this.vecTopRight.set(endPoint.x, tmpPoint.y, 0);
        this.vecDownRight.x = endPoint.x;
        this.vecDownRight.y = endPoint.y;
        this.vecDownRight.z = endPoint.z;
        this.vecDownLeft.set(tmpPoint.x, endPoint.y, 0);

        this.vecTopLeft.unproject(this.camera);
        this.vecTopRight.unproject(this.camera);
        this.vecDownRight.unproject(this.camera);
        this.vecDownLeft.unproject(this.camera);

        vectemp1.copy(this.vecTopLeft).sub(this.vecNear);
        vectemp2.copy(this.vecTopRight).sub(this.vecNear);
        vectemp3.copy(this.vecDownRight).sub(this.vecNear);
        vectemp1.normalize();
        vectemp2.normalize();
        vectemp3.normalize();

        vectemp1.multiplyScalar(this.deep);
        vectemp2.multiplyScalar(this.deep);
        vectemp3.multiplyScalar(this.deep);
        vectemp1.add(this.vecNear);
        vectemp2.add(this.vecNear);
        vectemp3.add(this.vecNear);

        const planes = this.frustum.planes;
        planes[0].setFromCoplanarPoints(this.vecNear, this.vecTopLeft, this.vecTopRight);
        planes[1].setFromCoplanarPoints(this.vecNear, this.vecTopRight, this.vecDownRight);
        planes[2].setFromCoplanarPoints(this.vecDownRight, this.vecDownLeft, this.vecNear);
        planes[3].setFromCoplanarPoints(this.vecDownLeft, this.vecTopLeft, this.vecNear);
        // planes[4].setFromCoplanarPoints(this.vecTopRight, this.vecDownRight, this.vecDownLeft);
        // planes[5].setFromCoplanarPoints(vectemp3, vectemp2, vectemp1);
        // planes[5].normal.multiplyScalar(- 1);

      } else if ((this.camera as THREE.OrthographicCamera).isOrthographicCamera) {

        var left = this.bbox.min.x;
        var top = this.bbox.max.y;
        var right = this.bbox.max.x;
        var down = this.bbox.min.y;

        this.vecTopLeft.set(left, top, - 1);
        this.vecTopRight.set(right, top, - 1);
        this.vecDownRight.set(right, down, - 1);
        this.vecDownLeft.set(left, down, - 1);

        vecFarTopLeft.set(left, top, 1);
        vecFarTopRight.set(right, top, 1);
        vecFarDownRight.set(right, down, 1);
        vecFarDownLeft.set(left, down, 1);

        this.vecTopLeft.unproject(this.camera);
        this.vecTopRight.unproject(this.camera);
        this.vecDownRight.unproject(this.camera);
        this.vecDownLeft.unproject(this.camera);

        vecFarTopLeft.unproject(this.camera);
        vecFarTopRight.unproject(this.camera);
        vecFarDownRight.unproject(this.camera);
        vecFarDownLeft.unproject(this.camera);

        const planes = this.frustum.planes;
        planes[0].setFromCoplanarPoints(this.vecTopLeft, vecFarTopLeft, vecFarTopRight);
        planes[1].setFromCoplanarPoints(this.vecTopRight, vecFarTopRight, vecFarDownRight);
        planes[2].setFromCoplanarPoints(vecFarDownRight, vecFarDownLeft, this.vecDownLeft);
        planes[3].setFromCoplanarPoints(vecFarDownLeft, vecFarTopLeft, this.vecTopLeft);
        planes[4].setFromCoplanarPoints(this.vecTopRight, this.vecDownRight, this.vecDownLeft);
        planes[5].setFromCoplanarPoints(vecFarDownRight, vecFarTopRight, vecFarTopLeft);
        planes[5].normal.multiplyScalar(- 1);

      } else {

        console.error('THREE.SelectionBox: Unsupported camera type.');

      }
    }
  }
  private searchDataInFrustum = () => {
    const layers = this.graphicProcessor.getRaycaster().getStrucLayer2Raycast();
    const dataModel = this.graphicProcessor.getDataModelManager();
    dataModel.iterAllDataFromLayers(layers, (data) => {
      if (data.isVisible && !data.isLocked) {
        const object = data.graphicObj;
        const bbx0 = new THREE.Box3();
        bbx0.expandByObject(object);
        // First filter by bbox
        if (this.frustum.intersectsBox(bbx0)) {
          // Second filter by geometry
          if (this.intersect) {
            // Touch           
            if (this.intersectObj(object)) {
              this.collection.push(data);
            }
          } else {
            // Contains
            if (this.containObj(object)) {
              this.collection.push(data);
            }
          }
        }
      }
    });
  }
  private containObj(object: THREE.Object3D) {
    if (this.startPoint && this.endPoint) {
      if (object instanceof THREE.Mesh || object instanceof THREE.Line || object instanceof THREE.Points) {
        const vector = new THREE.Vector3();
        if (object.geometry.vertices?.length > 0) {
          for (let i = 0; i < object.geometry.vertices.length; i++) {
            vector.copy(object.geometry.vertices[i]);
            object.localToWorld(vector);
            vector.project(this.camera);
            const p1 = this.graphicProcessor.vectorToScreenCoord(vector.x, vector.y);
            if (!isPointInBox(p1, this.bbox)) {
              return false;
            }
          }
          return true;

        } else if (object.geometry.type === "LineGeometry") {
          const pos = (object.geometry as THREE.BufferGeometry).getAttribute("instanceStart");
          const count = pos.array.length / pos.itemSize;
          for (let i = 0; i < count; i++) {
            vector.setX(pos.array[i * 3]);
            vector.setY(pos.array[i * 3 + 1]);
            vector.setZ(pos.array[i * 3 + 2]);
            object.localToWorld(vector);
            vector.project(this.camera);
            const p1 = this.graphicProcessor.vectorToScreenCoord(vector.x, vector.y);
            if (!isPointInBox(p1, this.bbox)) {
              return false;
            }
          }
          return true;
        } else {
          const pos = (object.geometry as THREE.BufferGeometry).getAttribute("position");
          for (let i = 0; i < pos.count; i++) {
            vector.fromBufferAttribute(pos, i);
            object.localToWorld(vector);
            vector.project(this.camera);
            const p1 = this.graphicProcessor.vectorToScreenCoord(vector.x, vector.y);
            if (!isPointInBox(p1, this.bbox)) {
              return false;
            }
          }
          return true;
        }

      } else if (object instanceof THREE.Group) {
        for (const obj of object.children) {
          if (!this.containObj(obj)) return false;
        }
        return true;
      }
    }
    return false;
  }
  private intersectObj(object: THREE.Object3D) {
    if (this.startPoint && this.endPoint) {
      if (object instanceof THREE.Line || object instanceof THREE.Mesh) {
        const vector0 = new THREE.Vector3()
        const vector1 = new THREE.Vector3()
        if (object.geometry.vertices?.length > 0) {
          for (let i = 0; i < object.geometry.vertices.length - 1; i++) {
            vector0.copy(object.geometry.vertices[i]);
            object.localToWorld(vector0);
            vector0.project(this.camera);
            const p0 = this.graphicProcessor.vectorToScreenCoord(vector0.x, vector0.y);

            vector1.copy(object.geometry.vertices[i + 1]);
            object.localToWorld(vector1);
            vector1.project(this.camera);
            const p1 = this.graphicProcessor.vectorToScreenCoord(vector1.x, vector1.y);

            if (isLineIntersectBbox(p0, p1, this.bbox)) return true;
          }
        } else if (object.geometry.type === "LineGeometry") {
          const pos = (object.geometry as THREE.BufferGeometry).getAttribute("instanceStart");
          const count = pos.array.length / pos.itemSize;
          for (let i = 0; i < count - 1; i++) {
            vector0.setX(pos.array[i * 3]);
            vector0.setY(pos.array[i * 3 + 1]);
            vector0.setZ(pos.array[i * 3 + 2]);
            object.localToWorld(vector0);
            vector0.project(this.camera);
            const p0 = this.graphicProcessor.vectorToScreenCoord(vector0.x, vector0.y);

            vector1.setX(pos.array[(i + 1) * 3]);
            vector1.setY(pos.array[(i + 1) * 3 + 1]);
            vector1.setZ(pos.array[(i + 1) * 3 + 2]);
            object.localToWorld(vector1);
            vector1.project(this.camera);
            const p1 = this.graphicProcessor.vectorToScreenCoord(vector1.x, vector1.y);

            if (isLineIntersectBbox(p0, p1, this.bbox)) return true;
          }
        } else {
          const pos = (object.geometry as THREE.BufferGeometry).getAttribute("position");
          for (let i = 0; i < pos.count - 1; i++) {
            vector0.fromBufferAttribute(pos, i);
            object.localToWorld(vector0);
            vector0.project(this.camera);
            const p0 = this.graphicProcessor.vectorToScreenCoord(vector0.x, vector0.y);

            vector1.fromBufferAttribute(pos, i + 1);
            object.localToWorld(vector1);
            vector1.project(this.camera);
            const p1 = this.graphicProcessor.vectorToScreenCoord(vector1.x, vector1.y);

            if (isLineIntersectBbox(p0, p1, this.bbox)) return true;
          }
        }
        return false;

      } else if (object instanceof THREE.Points) {
        const pos = (object.geometry as THREE.BufferGeometry).getAttribute("position");
        const vector1 = new THREE.Vector3().fromBufferAttribute(pos, 0);
        object.localToWorld(vector1);
        vector1.project(this.camera);
        const p1 = this.graphicProcessor.vectorToScreenCoord(vector1.x, vector1.y);
        if (isPointInBox(p1, this.bbox)) return true;

      } else if (object instanceof THREE.Group) {
        for (const obj of object.children) {
          if (this.intersectObj(obj)) return true;
        }
        return false;
      }
    }
    return false;
  }
}
