import { getAngleBetweenAzimuts, getAzimutBisection, isBetweenAzimuts, isBetweenDirection, lineAngle2p, lineAzimut2p, lineSlope2p, normalizeAngle, ORIENT } from "./angles";
import { arcParam, arcPointsCR2p, circleGetCenter3p, getArcDirectionFrom3p } from "./arc";
import { distancePointToLine3D, getDistancePointToArc, vectorDist3D } from "./distance";
import { vector2Equals, vector3Equals } from "./epsilon";
import { intersect2D, intersectLineCircle } from "./intersections";
import { addIpoint, copyIPoint, getAzimutPolarPoint, getMiddlePoint, IpointsToBuffer, pointsAreCollinear, substractIpoint } from "./point";
import { IPoint, ISegment } from "./types";

export interface IPolylineParam {
  isClosed: boolean;
  /** Vertex of the polyline
   *
   * @type {IPoint[]}
   * @memberof IPolylineParam
   */
  points: IPoint[];
  /** Arcs definition between vertex 
   * If does not exist arc position in array is null 
   * @type {IArcLineParam[]}
   * @memberof IPolylineParam
   */
  arcs: (IArcLineParam | 0)[];
};
export interface IArcLineParam {
  p1Tangent: boolean;
  p3Tangent: boolean;
  center: IPoint;
  radius: number;
  direction: ORIENT;
};

export function copyIPolylineParam(line: IPolylineParam): IPolylineParam {
  return {
    isClosed: line.isClosed,
    points: line.points.map(copyIPoint),
    arcs: line.arcs.map(l => {
      if (l === 0) return 0;
      return copyIArcLine(l);
    })
  }
}
export function copyIArcLine(arc: IArcLineParam): IArcLineParam {
  return {
    p1Tangent: arc.p1Tangent,
    p3Tangent: arc.p3Tangent,
    center: copyIPoint(arc.center),
    radius: arc.radius,
    direction: arc.direction,
  };
}
export function getNumEdges(line: IPolylineParam): number {
  if (line.isClosed) return line.points.length;
  return line.points.length - 1;
}
export function* iterPolylineEdges(params: IPolylineParam) {
  const { arcs, isClosed, points } = params;
  for (let i = 0; i < points.length; i++) {
    const p0 = points[i];
    const p1 = points[i + 1];
    const arc = arcs[i];
    if (p0 && p1) {
      yield { i, p0, arc, p1 };
    }
    if (p0 && p1 === undefined && isClosed) {
      yield { i, p0, arc, p1: points[0] };
    }
  }
}

export function reverseIPolylineParam(param: IPolylineParam) {
  const { arcs, isClosed, points } = param;
  const reversePoints = points.reverse();
  const reverseArcs = arcs.reverse();
  for (const arc of reverseArcs) {
    if (arc) {
      const temp = arc.p1Tangent
      arc.p1Tangent = arc.p3Tangent;
      arc.p3Tangent = temp;
      arc.direction = arc.direction === ORIENT.CCW ? ORIENT.CW : ORIENT.CCW;
    }
  }
  if (isClosed) {
    const firstArc = reverseArcs.shift()!;
    reverseArcs.push(firstArc);
  }
  return { arcs: reverseArcs, isClosed, points: reversePoints }

}
export function get2DLineEquation(p0: IPoint, p1: IPoint): { m: number, c: number } {
  // Line equation ---> y = m·x + c
  const x1 = p0.x;
  const y1 = p0.y;
  const x2 = p1.x;
  const y2 = p1.y;;
  const m = (y2 - y1) / (x2 - x1);
  const c = -(x1 * (y2 - y1)) / (x2 - x1) + y1;
  return { m, c }
}
export function arcLineToArcParam(p0: IPoint, p1: IPoint, arc: IArcLineParam): arcParam {
  const center = arc.center;
  const radius = arc.radius;
  const azimutO = normalizeAngle(lineAzimut2p(center, p0));
  const azimutF = normalizeAngle(lineAzimut2p(center, p1));
  let angleCenter = getAngleBetweenAzimuts(azimutO, azimutF, arc.direction);
  if (arc.direction === ORIENT.CCW) angleCenter *= -1;
  const plane = { x: 0, y: 0, z: 0 };
  return { center, radius, azimutO, angleCenter, plane };
}
function getArcDefinition(p1: IPoint, p2: IPoint, p3: IPoint): IArcLineParam | null {
  const center = circleGetCenter3p(p1, p2, p3);
  if (center) {
    const radius: number = vectorDist3D(center, p1);
    const direction = getArcDirectionFrom3p(p1, p2, p3, { x: 0, y: 0, z: 0 });
    return { p1Tangent: false, center, radius, p3Tangent: false, direction };
  }
  return null;
}
export function getArcStartAzimut(p1: IPoint, arc: IArcLineParam): number {
  let angle = lineAzimut2p(arc.center, p1);
  if (arc.direction === ORIENT.CW) {
    angle += Math.PI * 0.5;
  } else {
    angle -= Math.PI * 0.5;
  }
  return normalizeAngle(angle);
}
export function getArcEndAzimut(arc: IArcLineParam, p3: IPoint): number {
  let angle = lineAzimut2p(arc.center, p3);
  if (arc.direction === ORIENT.CW) {
    angle += Math.PI * 0.5;
  } else {
    angle -= Math.PI * 0.5;
  }
  return normalizeAngle(angle);
}
export function getArcStartAngle(p1: IPoint, arc: IArcLineParam): number {
  let angle = lineAngle2p(arc.center, p1);
  if (arc.direction === ORIENT.CW) {
    angle -= Math.PI * 0.5;
  } else {
    angle += Math.PI * 0.5;
  }
  return normalizeAngle(angle);
}
export function getArcEndAngle(arc: IArcLineParam, p3: IPoint): number {
  let angle = lineAngle2p(arc.center, p3);
  if (arc.direction === ORIENT.CW) {
    angle -= Math.PI * 0.5;
  } else {
    angle += Math.PI * 0.5;
  }
  return normalizeAngle(angle);
}
export function getArcLong(p1: IPoint, arcDef: IArcLineParam, p3: IPoint): number {
  const azimut1 = lineAzimut2p(arcDef.center, p1);
  const azimut2 = lineAzimut2p(arcDef.center, p3);
  const angle = getAngleBetweenAzimuts(azimut1, azimut2, arcDef.direction);
  return angle * arcDef.radius;
}
export function getArcAngleCenter(p1: IPoint, arcDef: IArcLineParam, p3: IPoint): number {
  const azimutO = lineAzimut2p(arcDef.center, p1);
  const azimutEnd = lineAzimut2p(arcDef.center, p3);
  return getAngleBetweenAzimuts(azimutO, azimutEnd, arcDef.direction);
}
export function getArcVertex(p1: IPoint, arcDef: IArcLineParam, p3: IPoint): IPoint | null | undefined {
  const azimutS = getArcStartAzimut(p1, arcDef);
  const azimutE = getArcEndAzimut(arcDef, p3);
  const p11 = getAzimutPolarPoint(p1, azimutS, 10);
  const p33 = getAzimutPolarPoint(p3, azimutE, 10);
  return intersect2D(p1, p11, p3, p33, false)?.p;
}
export function getArcP1TangentDefinition(p1: IPoint, prevAzimut: number, p3: IPoint): IArcLineParam {
  let direction = ORIENT.CCW;
  const azimut200 = normalizeAngle(prevAzimut + Math.PI);
  const azimutP1P3 = normalizeAngle(lineAzimut2p(p1, p3));
  if (!isBetweenDirection(azimutP1P3, prevAzimut, azimut200, ORIENT.CW)) {
    direction = ORIENT.CW;
  }
  const MP = getMiddlePoint(p1, p3);
  const d = vectorDist3D(p1, MP);
  const mathPi100 = Math.PI * 0.5;
  const azimutP1Center = direction === ORIENT.CW ? prevAzimut + mathPi100 : prevAzimut - mathPi100;
  let angleCenterP1P3 = azimutP1Center - azimutP1P3;
  if (angleCenterP1P3 < 0) angleCenterP1P3 *= -1;
  const angleP1CenterPM = Math.PI - angleCenterP1P3 - mathPi100;
  const radius = d / Math.sin(angleP1CenterPM);
  const center = getAzimutPolarPoint(p1, azimutP1Center, radius);

  return { p1Tangent: true, center, radius, p3Tangent: true, direction };
}
export function getArcP3TangentDefinition(p1: IPoint, nextAzimut: number, p3: IPoint): IArcLineParam {
  let direction = ORIENT.CCW;
  const azimut200 = normalizeAngle(nextAzimut + Math.PI);
  const azimutP1P3 = normalizeAngle(lineAzimut2p(p3, p1));
  if (isBetweenAzimuts(azimutP1P3, nextAzimut, azimut200)) {
    direction = ORIENT.CW;
  }
  const MP = getMiddlePoint(p1, p3);
  const d = vectorDist3D(p3, MP);
  const azimutP3Center = direction === ORIENT.CW ? nextAzimut + Math.PI * 0.5 : nextAzimut - Math.PI * 0.5;
  let angleCenterP3P1 = azimutP3Center - azimutP1P3;
  if (angleCenterP3P1 < 0) angleCenterP3P1 *= -1;
  const angleP3CenterPM = Math.PI - angleCenterP3P1 - Math.PI * 0.5;
  const radius = d / Math.sin(angleP3CenterPM);
  const center = getAzimutPolarPoint(p3, azimutP3Center, radius);

  return { p1Tangent: true, center, radius, p3Tangent: true, direction };
}

/*  OPEN polyline                                                    */
/*            0            1             2             | 3 edges     */
/*      0 --------- 1 ----------- 2 ---------- 3       | 4 points    */

/*  CLOSED polyline                                                  */
/*       0            1             2          3       | 4 edges     */
/* 0 --------- 1 ----------- 2 ---------- 3 ------ 0*  | 4 points    */

export function setIsClosedFromPointsArray(param: IPolylineParam) {
  const p0 = param.points[0];
  const pN = param.points[param.points.length - 1];
  param.isClosed = vector3Equals(p0, pN);
}

export function polylineContainArcs(param: IPolylineParam): boolean {
  return param.arcs.some(a => a !== 0);
}

export function getBufferFromPolylineParam(param: IPolylineParam): Float32Array {

  let vertex: IPoint[] = [];
  const points = param.points;
  const arcs = param.arcs;

  if (!polylineContainArcs(param)) {
    vertex = param.isClosed && points[0] ? points.concat(points[0]) : points;
    return IpointsToBuffer(vertex);
  } else {

    function addEdge(p0: IPoint, p1: IPoint, arc: IArcLineParam | 0) {
      if (arc !== 0) {
        // add arc
        vertex.push(...arcPointsCR2p(arc.center, arc.radius, p0, p1, arc.direction));
        vertex.push(p1);
      } else {
        // add edge
        vertex.push(p1);
      }
    }

    vertex = [points[0]];
    for (let i = 0; i < points.length - 1; i++) {
      // First vertex of edge 
      const p0 = points[i];
      // Second vertex of edge
      const p1 = points[i + 1];
      // Arc in edge
      const arc = arcs[i]
      addEdge(p0, p1, arc);
    }
    if (param.isClosed) {
      // First vertex of edge 
      const p0 = points[points.length - 1];
      // Second vertex of edge
      const p1 = points[0];
      // Arc in edge
      const arc = arcs[points.length - 1]
      addEdge(p0, p1, arc);
    }
    return IpointsToBuffer(vertex);
  }
}
export function getMiddlePointArcDefinition(p1: IPoint, arcDef: IArcLineParam, p3: IPoint): IPoint {
  const azimut1 = lineAzimut2p(arcDef.center, p1);
  const azimut2 = lineAzimut2p(arcDef.center, p3);
  const angle = getAngleBetweenAzimuts(azimut1, azimut2, arcDef.direction);
  const azi = getAzimutBisection(azimut1, angle, arcDef.direction);
  return getAzimutPolarPoint(arcDef.center, azi, arcDef.radius);
}
export function getIsegmentFromIndex(line: IPolylineParam, index?: number): ISegment & { arc?: IArcLineParam } {
  const numEdges = line.isClosed ? line.points.length : line.points.length - 1;
  if (index === undefined) index = numEdges - 1
  if (index >= numEdges) index = numEdges - 1;
  const p1 = line.points[index];
  const p2 = line.points[index + 1] !== undefined ? line.points[index + 1] : line.points[0];
  const arc = line.arcs[index];
  if (arc) {
    return { p1, arc, p2 };
  } else {
    return { p1, p2 };
  }
}
export function getEdgePolylineFromIndex(line: IPolylineParam, indx: number): ISegment | arcParam {
  const p1 = line.points[indx];
  const p2 = line.points[indx + 1] ?? line.points[0];
  const arc = line.arcs[indx];
  if (arc) {
    return arcLineToArcParam(p1, p2, arc);
  } else {
    return { p1, p2 };
  }
}
export function getMiddlePointEdgePolylineFromIndex(line: IPolylineParam, index: number): IPoint {
  const numEdges = line.isClosed ? line.points.length : line.points.length - 1;
  if (index === undefined) index = numEdges - 1
  if (index >= numEdges) index = numEdges - 1;
  const p1 = line.points[index];
  const p2 = line.points[index + 1] !== undefined ? line.points[index + 1] : line.points[0];
  const arc = line.arcs[index];
  if (arc) {
    return getMiddlePointArcDefinition(p1, arc, p2);
  } else {
    return getMiddlePoint(p1, p2);
  }
}
export function getNearPolylineEdgeIndex(line: IPolylineParam, pto: IPoint): number {
  const { points, arcs, isClosed } = line;
  let index = 0;
  let distance = Infinity;
  for (let i = 0; i < points.length; i++) {
    const p0 = points[i];
    let p1 = points[i + 1];
    if (p1 === undefined) {
      if (isClosed) {
        p1 = points[0];
      } else {
        break;
      }
    }
    const arc = arcs[i];
    let d;
    if (arc) {
      const azStart = lineAzimut2p(arc.center, p0);
      const azEnd = lineAzimut2p(arc.center, p1);
      d = getDistancePointToArc(arc.center, arc.radius, azStart, azEnd, arc.direction, pto);
    } else {
      d = distancePointToLine3D(p0, p1, pto, true);
    }
    if (d < distance) {
      distance = d;
      index = i;
    }
  }
  return index;
}

export function movePolyArcVertex(def: IPolylineParam, point: IPoint, vertexIndex: number) {
  const resolveStretchForward = (indx: number): boolean => {
    const currDef = def.points[indx];
    if (currDef === undefined) return true;
    const doNextStep = calculateStrechForWard(def, indx);
    if (doNextStep) return resolveStretchForward(indx + 1);
    return doNextStep;
  };
  const resolveStretchBackward = (indx: number): boolean => {
    const p1 = def.points[indx];
    if (p1 === undefined) return false;
    const doNextStep = calculateStrechBackWard(def, indx);
    if (doNextStep) return resolveStretchBackward(indx - 1);
    return doNextStep;
  };

  const indexP1 = vertexIndex;
  const p0 = def.points[indexP1 - 1];
  const arc0 = def.arcs[indexP1 - 1];
  const p1 = def.points[indexP1];
  const arc = def.arcs[indexP1];
  const p2 = def.points[indexP1 + 1];

  def.points[indexP1] = point;

  if (arc0) {
    let newArc;
    if (arc0.p3Tangent) {
      if (arc) {
        // punto --- arc0 --- PUNTO --- arc
        const azimut = getArcStartAzimut(point, arc);
        newArc = getArcP1TangentDefinition(p0, azimut, point);
      } else {
        // punto --- arco --- PUNTO --- punto?
        if (p2) {
          const azimut = normalizeAngle(lineAzimut2p(point, p2));
          newArc = getArcP3TangentDefinition(p0, azimut, point);
        } else {
          const azimut = getArcEndAzimut(arc0, point);
          newArc = getArcP3TangentDefinition(p0, azimut, point);
        }
      }
    } else {
      const pAux = getMiddlePointArcDefinition(p0, arc0, p1);
      newArc = getArcDefinition(p0, pAux, point);
      if (newArc === null) return false;
    }
    newArc.p1Tangent = arc0.p1Tangent;
    newArc.p3Tangent = arc0.p3Tangent;
    def.arcs[indexP1 - 1] = newArc;
  }
  if (arc) {
    let newArc;
    if (arc.p1Tangent) {
      if (arc0) {
        // arco --- PUNTO --- arco --- punto
        const pAux = getMiddlePointArcDefinition(p1, arc, p2);
        newArc = getArcDefinition(point, pAux, p2);
      } else {
        // punto --- PUNTO --- arco --- punto
        const azimut = normalizeAngle(lineAzimut2p(p0, point));
        newArc = getArcP1TangentDefinition(point, azimut, p2);
      }
    } else {
      const pAux = getMiddlePointArcDefinition(p1, arc, p2);
      newArc = getArcDefinition(point, pAux, p2);
    }
    if (newArc === null) return false;
    newArc.p1Tangent = arc.p1Tangent;
    newArc.p3Tangent = arc.p3Tangent;
    def.arcs[indexP1] = newArc;
  }
  resolveStretchBackward(indexP1 - 2);
  resolveStretchForward(indexP1 + 1);
}
export function movePolyArcEdge(def: IPolylineParam, point: IPoint, edgeIndex: number) {
  const resolveStretchForward = (indx: number): boolean => {
    const currDef = def.points[indx];
    if (currDef === undefined) return true;
    const doNextStep = calculateStrechForWard(def, indx);
    if (doNextStep) return resolveStretchForward(indx + 1);
    return doNextStep;
  };
  const resolveStretchBackward = (indx: number): boolean => {
    const p1 = def.points[indx];
    if (p1 === undefined) return false;
    const doNextStep = calculateStrechBackWard(def, indx);
    if (doNextStep) return resolveStretchBackward(indx - 1);
    return doNextStep;
  };

  const numEdges = def.isClosed ? def.points.length : def.points.length - 1;
  if (edgeIndex === undefined) edgeIndex = numEdges - 1
  if (edgeIndex >= numEdges) edgeIndex = numEdges - 1;
  const indexP1 = edgeIndex;
  const indexP2 = def.points[edgeIndex + 1] !== undefined ? edgeIndex + 1 : 0;

  const p1 = def.points[indexP1];
  const p2 = def.points[indexP2];
  let arc = def.arcs[indexP1];
  if (arc === undefined) arc = 0;

  if (arc) {
    // ARCO
    const newArc = getArcDefinition(p1, point, p2);
    if (newArc === null) return false;
    newArc.p1Tangent = arc.p1Tangent;
    newArc.p3Tangent = arc.p3Tangent;
    def.arcs[indexP1] = newArc;
  } else {
    // PUNTO ---> PUNTO
    const distVect = substractIpoint(point, getMiddlePoint(p1, p2));
    def.points[indexP1] = addIpoint(p1, distVect);
    def.points[indexP2] = addIpoint(p2, distVect);
  }
  resolveStretchBackward(indexP1 - 1);
  resolveStretchForward(indexP1 + 1);
}
function calculateStrechForWard(def: IPolylineParam, edgeIndx: number): boolean {

  // Previous Edge
  const p0 = def.points[edgeIndx - 1];
  let arc0 = def.arcs[edgeIndx - 1] ?? 0;
  if (p0 === undefined) return false;

  // Edge to adjust
  const p1 = def.points[edgeIndx];
  let arc = def.arcs[edgeIndx] ?? 0;
  const p2 = def.points[edgeIndx + 1];
  if (p2 === undefined) return false;

  if (arc0 === 0 && arc === 0) {
    // recta ---> RECTA
    return true;

  } else if (arc0 !== 0 && arc === 0) {
    // arco ---> RECTA
    if (arc0.p3Tangent === false) return false;
    const azimut = getArcEndAzimut(arc0, p1);
    const auxPto = getAzimutPolarPoint(p1, azimut, 10);
    const center = getMiddlePoint(p1, p2);
    const radius = vectorDist3D(center, p1);
    const p = intersectLineCircle(p1, auxPto, { center, radius });
    def.points[edgeIndx + 1] = copyIPoint(p[0]);

  } else if (arc0 === 0 && arc !== 0) {
    // recta ---> ARCO
    let newArc;
    if (arc.p1Tangent) {
      const azimut = normalizeAngle(lineAzimut2p(p0, p1));
      newArc = getArcP1TangentDefinition(p1, azimut, p2);
    } else {
      return false;
    }
    newArc.p1Tangent = arc.p1Tangent;
    newArc.p3Tangent = arc.p3Tangent;
    def.arcs[edgeIndx] = newArc;
    if (arc.p3Tangent === false) return false;

  } else if (arc0 !== 0 && arc !== 0) {
    // arco ---> ARCO
    if (arc0.p3Tangent === false) return false;
    const azimut = getArcEndAzimut(arc0, p1);
    const newArc = getArcP1TangentDefinition(p1, azimut, p2);
    newArc.p1Tangent = arc.p1Tangent;
    newArc.p3Tangent = arc.p3Tangent;
    def.arcs[edgeIndx] = newArc;
  }
  return true;
}
function calculateStrechBackWard(def: IPolylineParam, edgeIndx: number): boolean {

  // Edge to adjust
  const p0 = def.points[edgeIndx];
  const arc0 = def.arcs[edgeIndx] ?? 0;
  const p1 = def.points[edgeIndx + 1];
  // Next edge
  const arc = def.arcs[edgeIndx + 1] ?? 0;
  const p2 = def.points[edgeIndx + 2];

  if (p2 === undefined) return false;

  if (arc0 === 0 && arc === 0) {
    // RECTA ---> recta
    return true;

  } else if (arc0 !== 0 && arc === 0) {
    // ARCO ---> recta
    let newArc;
    if (arc0.p3Tangent) {
      const azimut = normalizeAngle(lineAzimut2p(p1, p2));
      newArc = getArcP3TangentDefinition(p0, azimut, p1);
    } else {
      const azimut = getArcStartAzimut(p0, arc0);
      newArc = getArcP1TangentDefinition(p0, azimut, p1);
    }
    newArc.p1Tangent = arc0.p1Tangent;
    newArc.p3Tangent = arc0.p3Tangent;
    def.arcs[edgeIndx] = newArc;
    if (arc0.p1Tangent === false) return false;

  } else if (arc0 === 0 && arc !== 0) {
    // RECTA ---> arco
    if (arc.p1Tangent === false) return false;
    const azimut = getArcStartAzimut(p1, arc);
    const auxPto = getAzimutPolarPoint(p1, azimut, 10);
    const center = getMiddlePoint(p1, p0);
    const radius = vectorDist3D(center, p1);
    const p = intersectLineCircle(p1, auxPto, { center, radius });
    def.points[edgeIndx] = copyIPoint(p[1]);
    if (!arc.p3Tangent) return false;
  } else if (arc0 !== 0 && arc !== 0) {
    // ARCO ---> Arco
    if (arc.p1Tangent === false) return false;
    const azimut = getArcStartAzimut(p1, arc);
    const newArc = getArcP3TangentDefinition(p0, azimut, p1);
    newArc.p1Tangent = arc.p1Tangent;
    newArc.p3Tangent = arc.p3Tangent;
    def.arcs[edgeIndx] = newArc;
  }
  return true;
}


/** Comprueba si dos líneas, NO COLINEALES!!, son consecutivas 
 * (sus vértices final o inicial coinciden) y devuelve el vertice en cuestión
 *
 * @export
 * @param {THREE.Line} lineA
 * @param {THREE.Line} lineB
 * @returns {void | MEC.IPoint}
 */
export function linesAreAdjacent(lineA: IPolylineParam, lineB: IPolylineParam): IPoint | null {
  const lineANumVertex = lineA.points.length;
  const lineBNumVertex = lineB.points.length;
  const lineAFirstVertex = lineA.points[0];
  const lineALastVertex = lineA.points[lineANumVertex - 1];
  const lineBFirstVertex = lineB.points[0];
  const lineBLastVertex = lineB.points[lineBNumVertex - 1];

  const lineAa: ISegment = { p1: lineA.points[0], p2: lineA.points[1] };
  const lineAb: ISegment = { p1: lineA.points[lineANumVertex - 2], p2: lineA.points[lineANumVertex - 1] };

  const lineBc: ISegment = { p1: lineB.points[0], p2: lineB.points[1] };
  const lineBd: ISegment = { p1: lineB.points[lineBNumVertex - 2], p2: lineB.points[lineBNumVertex - 1] };

  if (lineAFirstVertex && lineALastVertex && lineBFirstVertex && lineBLastVertex) {
    // Lineas en las que coinciden solo uno de los extremos
    if (vector3Equals(lineAFirstVertex, lineBFirstVertex) && !vector3Equals(lineALastVertex, lineBLastVertex) && !edgesAreCollinear(lineAa, lineBc)) { return lineBFirstVertex; }
    if (vector3Equals(lineAFirstVertex, lineBLastVertex) && !vector3Equals(lineALastVertex, lineBFirstVertex) && !edgesAreCollinear(lineAa, lineBd)) { return lineBLastVertex; }
    if (vector3Equals(lineALastVertex, lineBFirstVertex) && !vector3Equals(lineAFirstVertex, lineBLastVertex) && !edgesAreCollinear(lineAb, lineBc)) { return lineBFirstVertex; }
    if (vector3Equals(lineALastVertex, lineBLastVertex) && !vector3Equals(lineAFirstVertex, lineBFirstVertex) && !edgesAreCollinear(lineAb, lineBd)) { return lineBLastVertex; }

    // Líneas que coinciden ambos extremos y están formadas por más de un vértice (filtramos las líneas de un vértice, por si se han colado).
    if (vector3Equals(lineAFirstVertex, lineBFirstVertex) && vector3Equals(lineALastVertex, lineBLastVertex) && lineANumVertex >= 2 && lineBNumVertex >= 2) { return lineBFirstVertex; }
    if (vector3Equals(lineAFirstVertex, lineBLastVertex) && vector3Equals(lineALastVertex, lineBFirstVertex) && lineANumVertex >= 2 && lineBNumVertex >= 2) { return lineBLastVertex; }
    if (vector3Equals(lineALastVertex, lineBFirstVertex) && vector3Equals(lineAFirstVertex, lineBLastVertex) && lineANumVertex >= 2 && lineBNumVertex >= 2) { return lineBFirstVertex; }
    if (vector3Equals(lineALastVertex, lineBLastVertex) && vector3Equals(lineAFirstVertex, lineBFirstVertex) && lineANumVertex >= 2 && lineBNumVertex >= 2) { return lineBLastVertex; }
  }
  return null;
}
export function edgesAreCollinear(lineA: ISegment, lineB: ISegment): boolean {
  if (!pointsAreCollinear(lineA.p1, lineA.p2, lineB.p1)) return false
  if (!pointsAreCollinear(lineA.p1, lineA.p2, lineB.p2)) return false
  return true;
}


/** Calculo de una línea paralela en un plano 2D Vertical (XZ,YZ) o Horizontal (XY).
 *
 * @export
 * @param {ISegment} lineRef
 * @param {number} dist El signo de la distancia indíca si queremos la línea a izda(+) o dcha(-), arriba(+) o abajo(-) de la original.
 * @param {boolean} [horizontal] Plano Horizontal true (default), plano Vertical false.
 * @returns {ISegment}
 */
export function getParallelLine2DFromDist(lineRef: ISegment, dist: number, horizontal?: boolean): ISegment {
  if (horizontal === undefined) horizontal = true;

  const lineV1 = lineRef.p1;
  const lineV2 = lineRef.p2;
  const A = lineAngle2p(lineV1, lineV2);

  if (!vector2Equals(lineV1, lineV2)) {
    if (horizontal) {
      const x1 = lineV1.x + dist * Math.cos(A + Math.PI * 0.5);
      const y1 = lineV1.y + dist * Math.sin(A + Math.PI * 0.5);
      const x2 = lineV2.x + dist * Math.cos(A + Math.PI * 0.5);
      const y2 = lineV2.y + dist * Math.sin(A + Math.PI * 0.5);
      return {
        p1: { x: x1, y: y1, z: lineV1.z },
        p2: { x: x2, y: y2, z: lineV2.z },
      };
    } else {
      const V = lineSlope2p(lineV1, lineV2);
      const dr = -dist * Math.sin(V);
      const x1 = lineV1.x + dr * Math.cos(A);
      const y1 = lineV1.y + dr * Math.sin(A);
      const z1 = lineV1.z + dist * Math.cos(V);
      const x2 = lineV2.x + dr * Math.cos(A);
      const y2 = lineV2.y + dr * Math.sin(A);
      const z2 = lineV2.z + dist * Math.cos(V);
      return {
        p1: { x: x1, y: y1, z: z1 },
        p2: { x: x2, y: y2, z: z2 },
      };
    }
  } else {
    // Linea completamente VERTICAL
    if (horizontal) {
      // Paralela en Plano YZ
      const x1 = lineV1.x;
      const y1 = lineV1.y + dist;
      const z1 = lineV1.z;
      const x2 = lineV2.x;
      const y2 = lineV2.y + dist;
      const z2 = lineV2.z;
      return {
        p1: { x: x1, y: y1, z: z1 },
        p2: { x: x2, y: y2, z: z2 },
      };
    } else {
      // Paralela en Plano XZ
      const x1 = lineV1.x + dist;
      const y1 = lineV1.y;
      const z1 = lineV1.z;
      const x2 = lineV2.x + dist;
      const y2 = lineV2.y;
      const z2 = lineV2.z;
      return {
        p1: { x: x1, y: y1, z: z1 },
        p2: { x: x2, y: y2, z: z2 },
      };
    }
  }
}
/** Calculo de la línea paralela a otra línea AB que pasa por un punto P
 * Operación en 3D
 * @export
 * @param {ISegment} Linea
 * @param {IPoint} P Punto de la línea paralela
 * @returns {ISegment} Línea paralela
 */
export function getParalleLine3DFromPoint(lineSegment: ISegment, P: IPoint): ISegment {
  // Vector director de la recta AB, que lo comparte con la recta paralela
  const u = substractIpoint(lineSegment.p2, lineSegment.p1);

  const A = lineSegment.p1;
  const B = lineSegment.p2;

  // Calculo del plano que pasa por A y es perpendicular a la recta Q1Q2
  const coefA = u.x;
  const coefB = u.y;
  const coefC = u.z;
  let coefD = - coefA * A.x - coefB * A.y - coefC * A.z;

  // Intersección de recta con el plano calculado
  let t = -(coefA * P.x + coefB * P.y + coefC * P.z + coefD) / (coefA * u.x + coefB * u.y + coefC * u.z);
  const Q1 = { x: P.x + u.x * t, y: P.y + u.y * t, z: P.z + u.z * t };

  // Calculo del plano que pasa por B y es perpendicular a la recta PQ
  coefD = - coefA * B.x - coefB * B.y - coefC * B.z;
  // Intersección de recta con el plano calculado
  t = -(coefA * P.x + coefB * P.y + coefC * P.z + coefD) / (coefA * u.x + coefB * u.y + coefC * u.z);
  const Q2 = { x: P.x + u.x * t, y: P.y + u.y * t, z: P.z + u.z * t };

  return { p1: Q1, p2: Q2 };
}