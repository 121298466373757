import { PrimaryButton, SecondaryButton } from "shared/components/ui/button";
import Input from "shared/components/ui/input";
import { useAddProject } from "./hooks/use-add-project";

interface Props {
  onClose: () => void;
}

const AddProjectForm = ({ onClose }: Props) => {

  const { ref, handleSubmit, errors } = useAddProject();

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-6">
        <div>
          <h1 className="text-lg leading-6 font-medium text-gray-400">
            New project
          </h1>
        </div>
        <Input
          id="name"
          name="name"
          ref={ref({
            required: "Campo requerido",
          })}
          error={errors.name?.type}
        />
        <input type="submit" className="sr-only" />
        <div className="flex justify-end space-x-4">
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
          <PrimaryButton type="submit">Create project</PrimaryButton>
        </div>
      </div>
    </form>
  );
};

export default AddProjectForm;
