import * as THREE from "three";
import { BeamEditDataCommand } from "lib/commands/structural/beam";
import { growthWidthTypeNames } from "lib/models-struc/types/struc-base";
import { beamParam } from "lib/models-struc/types/beam";
import { BeamData } from "lib/models/structural/beam";
import { ISolidMaterial } from '../../materials/solid';
import { linealPrecision, userNormalizeAngleToRad } from "lib/general-settings";
import { StructuralDataDefinitionHandler } from "./structural";
import { IPoint } from "lib/math/types";
import { crossProductIpoint, magnitudeIpoint, mulIpoint, normalizeIpoint } from "lib/math/point";
import { getRotationVector } from "lib/math/rotate";

export type beamProp = beamParam & {
  endPoint: IPoint,
  length: number,
  xDir: string,
  yVect: string,
  ZDir: string,
  showAxis: any,
  centroid: IPoint;
}
export class BeamDataDefinitionHandler extends StructuralDataDefinitionHandler<beamProp> {

  protected data: BeamData;

  protected buildInfoProperties() {
    super.buildInfoProperties();
    this.fillBeamDefinition(this.data);
  }
  private fillBeamDefinition(data: BeamData) {
    const repr = data.definition;
    this.structuralInfo.crossSectionId = this.getBeamCrossSectionView(repr.crossSectionId);
    this.structuralInfo.growthType = this.getEnumView(repr.growthType, growthWidthTypeNames, "Width growth");
    this.structuralInfo.orientation = this.getAngleView(repr.orientation, "Orientation");

    const [vx, vy, vz] = this.getVectorsParsed(repr);
    this.structuralInfo.xDir = this.getStringView(vx, "X Dir", false);
    this.structuralInfo.yVect = this.getStringView(vy, "Y Vect", false);
    this.structuralInfo.ZDir = this.getStringView(vz, "Z Dir", false);

    this.structuralInfo.length = this.getNumberView(magnitudeIpoint(repr.points[1]), "Beam length", "m", linealPrecision);
    this.structuralInfo.endPoint = this.getPointView(repr.points[1], "End point:", "m", linealPrecision);
    this.structuralInfo.showAxis = {
      type: "button", publicName: "Show local axis",
      buttonCb: () => { this.data.showAxisHelper(this.graphicProcessor) }
    }
  }

  private getVectorsParsed(param: beamParam) {
    const precision = 4;
    const xVec = normalizeIpoint(param.points[1]);
    const rotL = getRotationVector(xVec, param.orientation);
    const yVec = new THREE.Vector3(0, 0, 1);
    yVec.applyEuler(rotL);
    const zVec = crossProductIpoint(xVec, yVec);

    const x = `(${xVec.x.toFixed(precision)}, ${xVec.y.toFixed(precision)}, ${xVec.z.toFixed(precision)})`;
    const y = `(${yVec.x.toFixed(precision)}, ${yVec.y.toFixed(precision)}, ${yVec.z.toFixed(precision)})`;
    const z = `(${zVec.x.toFixed(precision)}, ${zVec.y.toFixed(precision)}, ${zVec.z.toFixed(precision)})`;
    return [x, y, z];
  }

  private checkNewStructural(newStructural: beamParam) {
    if (!newStructural) { return false; }
    if (!this.checkStructuralNewDefinition(newStructural)) { return false; }
    if (newStructural.crossSectionId !== undefined && !this.checkString(newStructural.crossSectionId)) { return false; }
    if (newStructural.growthType !== undefined && !this.checkEnum(newStructural.growthType)) { return false; }
    return true;
  }

  private changedBeamNewStructural(oldDefinition: beamParam, newDefinition: beamProp) {
    let def = oldDefinition;
    let changed: boolean = false;
    if (newDefinition) {
      const name = this.changedString(def.name, newDefinition.name);
      if (name !== null) {
        def.name = name;
        changed = true;
      }
      const crossSectionId = this.changedString(def.crossSectionId, newDefinition.crossSectionId);
      if (crossSectionId !== null) {
        def.crossSectionId = crossSectionId;
        changed = true;
      }
      const growthType = this.changedEnum(def.growthType, newDefinition.growthType);
      if (growthType !== null) {
        def.growthType = growthType;
        changed = true;
      }
      const orientRad = userNormalizeAngleToRad(newDefinition.orientation);
      const orientation = this.changedNumber(def.orientation, orientRad);
      if (orientation !== null) {
        def.orientation = orientation;
        changed = true;
      }
      const oldLength = magnitudeIpoint(def.points[1]);
      const length = this.changedNumber(oldLength, newDefinition.length);
      if (length !== null) {
        const factor = length / oldLength;
        newDefinition.endPoint = mulIpoint(newDefinition.endPoint, factor);
        changed = true;
      }
      const endPoint = this.changedPoint(def.points[1], newDefinition.endPoint);
      if (endPoint !== null) {
        newDefinition.length = magnitudeIpoint(endPoint);
        def.points[1] = endPoint;
        const beamVect = normalizeIpoint(endPoint);
        const rot = getRotationVector(beamVect);
        def.rotation = { x: rot.x, y: rot.y, z: rot.z };
        changed = true;
      }
    }
    return (changed ? def : null);
  }

  saveAndRegenerate = (newDefinition: beamProp) => {
    const beamDefinition = this.checkAndChangedDefinition(this.data.cloneDefinition(), newDefinition);
    if (beamDefinition) {
      const command = new BeamEditDataCommand(this.data, beamDefinition, null, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
  saveAndRegenerateMaterial = (newMaterial: ISolidMaterial) => {
    const beamMaterial = this.checkAndChangedSolidMaterial(this.data.material, newMaterial);
    if (beamMaterial) {
      const command = new BeamEditDataCommand(this.data, null, beamMaterial, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
  saveAndRegenerateStruct = (newStructural: beamProp) => {
    if (this.checkNewStructural(newStructural)) {
      const beamStrucParam = this.changedBeamNewStructural(this.data.cloneDefinition(), newStructural);
      if (beamStrucParam) {
        const command = new BeamEditDataCommand(this.data, beamStrucParam, null, this.graphicProcessor);
        if (command) this.graphicProcessor.storeAndExecute(command);
      }
    }
  }
}
