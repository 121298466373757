import SignInForm from "shared/components/auth/signin-form";
import AuthContainer from "shared/components/auth/auth-container";

const SignIn = () => {
  return (
    <AuthContainer title="Sign in to your account">
      <SignInForm />
    </AuthContainer>
  );
};

export default SignIn;
