import { ObserverManager } from "lib/events/event-bus";
import { LayerAction, LayerActionType } from "lib/events/layers";
import { IObjData } from "lib/models/objdata";
import { LayerData } from "./layer-data";
import { LayerManager } from "./layer-manager";

export class LayerDispatcher {

  public layerObserver = new ObserverManager<LayerAction>();

  private layrManager: LayerManager

  constructor(lyrMng: LayerManager) {
    this.layrManager = lyrMng;
  }

  public subscribe(listener: (action: LayerAction) => void) {
    this.layerObserver.subscribe(listener);
  }
  public unsubscribe(listener: (action: LayerAction) => void) {
    this.layerObserver.unsubscribe(listener);
  }

  public dispatchLoadLayers(): void {
    this.layerObserver.dispatch({
      type: LayerActionType.LOAD_LAYERS,
      payload: {
        currentLayer: this.layrManager.currentLayer,
        layerManager: this.layrManager,
      },
    });
  }
  public dispatchAddLayer(newLayer: LayerData) {
    this.layerObserver.dispatch({
      type: LayerActionType.ADD_LAYER,
      payload: {
        addedLayer: newLayer,
        layerManager: this.layrManager,
      },
    });
  }

  public dispatchDeleteLayer(layer: LayerData, difference: Set<LayerData>) {
    this.layerObserver.dispatch({
      type: LayerActionType.DELETE_LAYER,
      payload: {
        deletedLayer: layer,
        deletedLayers: [...difference],
        layerManager: this.layrManager,
      },
    });
  }
  public dispatchClearLayer(layer: LayerData) {
    this.layerObserver.dispatch({
      type: LayerActionType.CLEAR_LAYER,
      payload: {
        clearedLayer: layer,
        layerManager: this.layrManager,
      },
    });
  }
  public dispatchRenameLayer(newLayerName: string, layer: LayerData) {
    this.layerObserver.dispatch({
      type: LayerActionType.RENAME_LAYER,
      payload: {
        layer: layer,
        newName: newLayerName,
        layerManager: this.layrManager,
      },
    });
  }
  public dispatchVisibilityLayer(layer: LayerData, visible: boolean) {
    this.layerObserver.dispatch({
      type: LayerActionType.VISIBILITY_LAYERS,
      payload: {
        layer: layer,
        visible,
      },
    });
  }
  public dispatchLockLayerEvent(layer: LayerData, blockedOn: boolean): void {
    this.layerObserver.dispatch({
      type: LayerActionType.LOCK_LAYER,
      payload: {
        lockedLayer: layer,
        lockedStatus: blockedOn,
      },
    });
  }
  public dispatchSetCurrentLayer(currentLayer: LayerData) {
    this.layerObserver.dispatch({
      type: LayerActionType.SET_CURRENT_LAYER,
      payload: {
        currentLayer: currentLayer,
        layerManager: this.layrManager,
      },
    });
  }
  public dispatchDragLayers(source: LayerData, target: LayerData): void {
    this.layerObserver.dispatch({
      type: LayerActionType.DRAG_LAYERS,
      payload: {
        layerMoved: source,
        layerTarget: target,
        layerManager: this.layrManager,
      },
    });
  }
  public dispatchDragObject2Layer(data: IObjData, layer: LayerData): void {
    this.layerObserver.dispatch({
      type: LayerActionType.DRAG_OBJECT2LAYER,
      payload: {
        srcObject: data,
        dstLayer: layer,
        layerManager: this.layrManager,
      },
    });
  }
  public dispatchAddObj2Layer(data: IObjData, layer: LayerData): void {
    this.layerObserver.dispatch({
      type: LayerActionType.ADD_OBJECT2LAYER,
      payload: {
        srcObject: data,
        dstLayer: layer,
        layerManager: this.layrManager,
      },
    });
  }
}

// export let layerDispatcher: LayerDispatcher;
// export function initLayerDispatcher() {
//   layerDispatcher = new LayerDispatcher();
//   return layerDispatcher;
// }