const MirrorIcon = ({ ...props }) => {
  return (
    <svg className="h-5 w-5" viewBox="0 0 100 100" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.7197 6.25L6.25 100.037H43.811V7.25098L37.7197 6.25ZM68.6401 6.25L62.561 7.25098V100.037H100.11L68.6401 6.25ZM50.061 6.27441V12.5244H56.311V6.27441H50.061ZM50.061 18.7744V31.2744H56.311V18.7744H50.061ZM37.561 26.3672V93.7866H14.9414L37.561 26.3672ZM68.811 26.3916L91.4307 93.7866H68.811V26.3916ZM50.061 37.5244V50.0244H56.311V37.5244H50.061ZM50.061 56.2744V68.7744H56.311V56.2744H50.061ZM50.061 75.0244V87.5244H56.311V75.0244H50.061ZM50.061 93.7744V100.024H56.311V93.7744H50.061Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MirrorIcon;
