import * as THREE from "three";
import { circleBuffer } from "lib/geometries/circle";
import { getVertexFromIndex } from "lib/geometries/line";
import { getPolarPoint } from "lib/math/point";
import { rotatePoint } from "lib/math/rotate";
import { IPoint } from "lib/math/types";
import { CircleData } from "lib/models/primitives/circle";
import { cadOpType } from "lib/operations/factory";
import { SelectionManager } from "../selection-manager";
import { ObjDataSelector } from "./selector-data";


export class CircleDataSelector extends ObjDataSelector<CircleData> {

  public override buildSelectMarks() {
    const { center, radius, azimutO, plane } = this.data.definition;
    let points: IPoint[] = [];
    for (let i = 0; i < 4; i++) {
      let vertex = getPolarPoint(center, i * Math.PI * 0.5, radius);
      points.push(rotatePoint(vertex, plane.x, plane?.y, plane.z, center));
    }
    return {
      vertex: points,
      center: [center],
      line: [circleBuffer(center, radius, azimutO, plane)],
    };
  }

  public override launchMarkOP(selectionManager: SelectionManager, mark: THREE.Object3D, vertexIndex: number) {
    if (selectionManager.vertexSelector.pointObj === mark) {
      return {
        type: cadOpType.EDITCIRCLE,
        graphicProcessor: this.graphicProc,
        args: [this.data, getVertexFromIndex(mark as THREE.Points, vertexIndex)],
      };
    }
    if (selectionManager.centerSelector.pointObj === mark) {
      return {
        type: cadOpType.MOVE,
        graphicProcessor: this.graphicProc,
        args: [[this.data], this.data.definition.center],
      };
    }
  }
}
