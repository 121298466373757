import { FC, ReactNode } from "react";
import { LeftArrowLink } from "shared/components/ui/link";
import styled from "styled-components";
interface Props {
  title: string;
  actions?: ReactNode;
  subtitle?: ReactNode;
  backRoute?: string;
}

const Page: FC<Props> = ({ children, actions, title, subtitle ,backRoute}) => {
  return (
    <div>
      <Header>
        <Side>
          {backRoute? <LeftArrowLink to={backRoute}></LeftArrowLink> : ''}
          <Title>{title}</Title>
          {subtitle}
        </Side>
        <Side>{actions}</Side>
      </Header>
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </div>
  );
};

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
`;

const Side = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const Title = styled.h1`
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 500;
  color: var(--color-gray-700);
`;
const ChildrenWrapper = styled.div`
  padding-bottom: 8px;
`;
export default Page;
