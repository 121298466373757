import { createColumn, updateColumn } from "lib/geometries/structural/column";
import { getCurrentSolidMaterial, ISolidMaterial, materialCache } from "lib/materials";
import { cloneColumnParam, columnParam } from "lib/models-struc/types/column";
import { objDataType } from "../types";
import { StructuralElementData } from "./structural";
import { AxisHelperColumnData } from "lib/selection/axis-helper-objdata";
import { mirrorAngle, normalizeAngle } from "lib/math/angles";
import { IPoint } from "lib/math/types";
import { rotatePoint } from "lib/math/rotate";
import { mirrorPoint } from "lib/math/mirror";
import { buildingElemType } from "lib/models-struc/types/struc-base";
import { GraphicProcessor } from "lib/graphic-processor";

export class ColumnData extends StructuralElementData {

  public type: buildingElemType = objDataType.COLUMN;
  protected nameObj: string = "Column";

  public compoundGroupName: string;
  public definition: columnParam;

  constructor(definition: columnParam, material?: ISolidMaterial) {
    super();
    this.definition = cloneColumnParam(definition);
    this.material = material ?? getCurrentSolidMaterial();
  }
  static createObj(definition: columnParam, material: ISolidMaterial) {
    const threeMaterial = materialCache.getSolidMaterial(material);
    const graphicObj = createColumn(definition.crossSectionId, definition.height, threeMaterial);
    graphicObj.rotateZ(definition.orientation);
    const position = definition.basePoint;
    graphicObj.position.set(position.x, position.y, position.z);
    graphicObj.updateMatrixWorld();
    return graphicObj;
  }
  public createGraphicObj() {
    if (this.graphicObj) {
      console.warn("Attention: Column graphic object already created!!");
      return;
    }
    this.graphicObj = ColumnData.createObj(this.definition, this.material);
  }
  public cloneDefinition(): columnParam {
    return cloneColumnParam(this.definition);
  }
  public createObject(definition?: columnParam, material?: ISolidMaterial): THREE.Mesh {
    return ColumnData.createObj(definition ?? this.definition, material ?? this.material);
  }

  public regenerateDefinition() {
    this.graphicObj.position.set(0, 0, 0);
    this.graphicObj.rotation.set(0, 0, 0);
    updateColumn(this.graphicObj, this.definition.crossSectionId, this.definition.height);
    this.graphicObj.rotateZ(this.definition.orientation);
    const position = this.definition.basePoint;
    this.graphicObj.position.set(position.x, position.y, position.z);
    this.graphicObj.updateMatrixWorld();
    this.updateAxisHelper();
  }
  public override rotate(angleX: number, angleY: number, angleZ: number, basePoint: IPoint): void {
    angleX = normalizeAngle(angleX);
    angleY = normalizeAngle(angleY);
    angleZ = normalizeAngle(angleZ);
    this.definition.basePoint = rotatePoint(this.definition.basePoint, angleX, angleY, angleZ, basePoint);
    this.definition.orientation = normalizeAngle(this.definition.orientation + angleZ);
    this.regenerateObjectFromDefinition();
    super.rotate(angleX, angleY, angleZ, basePoint);
  }
  public override mirror(startPoint: IPoint, endPoint: IPoint): void {
    this.definition.basePoint = mirrorPoint(this.definition.basePoint, startPoint, endPoint);
    this.definition.orientation = normalizeAngle(-mirrorAngle(-this.definition.orientation, startPoint, endPoint));
    this.regenerateObjectFromDefinition();
    super.mirror(startPoint, endPoint);
  }

  public showAxisHelper(graphicProc: GraphicProcessor) {
    if (this.axisHelper === undefined) {
      this.axisHelper = new AxisHelperColumnData(this, graphicProc);
    }
    this.axisHelper.showHide();
  }
}
