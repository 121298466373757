import { updateObjBboxBSphere, adjustDrawRange } from "lib/geometries";
import { arcBuffer } from "lib/geometries/arc";
import { getBoundingBox } from "lib/geometries/bounding-box";
import { circleParam } from "lib/geometries/circle";
import { getVertexFromIndex } from "lib/geometries/line";
import { polygonParam } from "lib/geometries/polygon";
import { mecDirectionMathDirection, getAzimutBisection, normalizeAngle, isBetweenDirection, getBisection, lineAzimut2p, getAngleBetweenAzimuts, lineAngle2p, ORIENT, isDirectionInArc } from "lib/math/angles";
import { arcParam, isArcParam } from "lib/math/arc";
import { vectorDist3D } from "lib/math/distance";
import { copyIPoint, getPolarPoint, IpointsToBuffer, pointLinePositionXY } from "lib/math/point";
import { IPoint } from "lib/math/types";
import { circularDimensionParam, circularDimType } from "lib/models/dimension/circular-dim";
import { createText, modifyText } from "lib/text/builder";
import { sdfDoubleSidedType, textMultiPosTypeH, textMultiPosTypeV, TextOptsBuilder } from "lib/text/styles";
import * as THREE from "three";
import { Box3 } from "three";
import { getArrowSymbol, updateArrowSymbol } from "./arrow";
import { DimStyleBuilder } from "./style";
import { getDimArrows, getDimAuxGeometry, getDimAuxLineMaterial, getDimGeometry, getDimLabel } from "./line-dim-builder";

export type radialObj = arcParam | circleParam | polygonParam;

export function createCircularDimension(dimType: circularDimType, objParam: radialObj, direction: number, dimStyle: DimStyleBuilder, txtStyle: TextOptsBuilder): THREE.Group {
  const textLabel = getCircularDimLabelText(dimType, objParam, Math.PI * 0.5, dimStyle, txtStyle);

  const min = (textLabel.geometry.boundingBox as THREE.Box3).min;
  const max = (textLabel.geometry.boundingBox as THREE.Box3).max;
  const textLong = (max.x - min.x);

  const arrow = getArrowSymbol(dimStyle.arrowId);
  const bbox = arrow.geometry.boundingBox as Box3;
  const arrowLong = (bbox.max.x - bbox.min.x) * 2;

  if (Math.abs(dimStyle.distBaseLine) < arrowLong + textLong) {
    dimStyle.distBaseLine = arrowLong + textLong;
  }

  updateCircularDimLabelText(dimType, textLabel, objParam, direction, dimStyle, txtStyle);
  const dimGeometry = getCircularDimGeometry(dimType, objParam, dimStyle, direction);

  const arrows = getCircularDimArrow(dimType, dimGeometry, dimStyle);
  let auxGeometry;
  if (isArcParam(objParam)) {
    auxGeometry = getCircularAuxGeometry(objParam, dimGeometry, dimStyle, direction);
  }

  const dim = new THREE.Group();
  dim.add(dimGeometry);
  dim.add(textLabel);
  dim.add(arrows[0]);
  dim.add(arrows[1]);
  if (auxGeometry) dim.add(auxGeometry);
  dim.rotation.set(objParam.plane.x, objParam.plane.y, objParam.plane.z);
  dim.position.set(objParam.center.x, objParam.center.y, objParam.center.z);
  return dim;
}

export function updateCircularDimension(dimensionGroup: THREE.Group, dimInfo: circularDimensionParam, dimStyle: DimStyleBuilder, txtStyle: TextOptsBuilder): void {
  const objParam = dimInfo.objBase;
  dimStyle.override(dimInfo.customStyleProp);
  if (dimStyle.blockDistBaseLine || Math.abs(dimStyle.minDistBaseLine1) > Math.abs(dimStyle.distBaseLine)) {
    dimStyle.distBaseLine = dimStyle.minDistBaseLine1;
  }

  let arrows = getDimArrows(dimensionGroup);
  updateArrowSymbol(dimensionGroup, arrows[0], dimStyle.arrowId);
  updateArrowSymbol(dimensionGroup, arrows[1], dimStyle.arrowId);
  arrows = getDimArrows(dimensionGroup);
  const textLabel = getDimLabel(dimensionGroup);
  // modifyText(textLabel, {}, txtStyle, true);

  const min = (textLabel.geometry.boundingBox as THREE.Box3).min;
  const max = (textLabel.geometry.boundingBox as THREE.Box3).max;
  const textLong = (max.x - min.x);

  const arrow = getArrowSymbol(dimStyle.arrowId);
  const bbox = getBoundingBox(arrow) as THREE.Box3;
  const arrowLong = (bbox.max.x - bbox.min.x) * 2;
  if (Math.abs(dimStyle.distBaseLine) < arrowLong + textLong) {
    dimStyle.distBaseLine = arrowLong + textLong;
  }
  updateCircularDimLabelText(dimInfo.type, textLabel, objParam, dimInfo.dimDirectionAngle, dimStyle, txtStyle);

  const geometry = getDimGeometry(dimensionGroup);
  let newGeometry = getCircularDimBuffer(dimInfo.type, objParam, dimStyle, dimInfo.dimDirectionAngle);
  geometry.geometry = newGeometry;
  updateObjBboxBSphere(geometry);

  updateCircularDimArrow(dimInfo.type, geometry, arrows[0], arrows[1], dimStyle);

  // En el caso de necesitar calculamos arco de acotacion
  if (isArcParam(objParam)) {
    const auxGeometry = getDimAuxGeometry(dimensionGroup);
    newGeometry = getCircularAuxBuffer(objParam, dimStyle, dimInfo.dimDirectionAngle);
    auxGeometry.geometry = newGeometry;
    adjustDrawRange(auxGeometry);
    updateObjBboxBSphere(auxGeometry);
    if (!isDirectionInArc(dimInfo.dimDirectionAngle, objParam.azimutO, objParam.angleCenter)) {
      auxGeometry.visible = true;
    } else {
      auxGeometry.visible = false;
    }
  }
  dimensionGroup.rotation.set(objParam.plane.x, objParam.plane.y, objParam.plane.z);
  dimensionGroup.position.set(objParam.center.x, objParam.center.y, objParam.center.z);
}


function getCircularDimGeometry(dimType: circularDimType, objInfo: radialObj, dimStyle: DimStyleBuilder, angle: number): THREE.LineSegments {
  const geometry: THREE.BufferGeometry = getCircularDimBuffer(dimType, objInfo, dimStyle, angle);
  const resultsLines = new THREE.LineSegments(geometry, getDimAuxLineMaterial());
  updateObjBboxBSphere(resultsLines);
  return resultsLines;
}
function getCircularDimBuffer(dimType: circularDimType, objInfo: radialObj, dimStyle: DimStyleBuilder, angle: number): THREE.BufferGeometry {
  const position: IPoint[] = [];
  const center = { x: 0, y: 0, z: 0 };
  const radius = objInfo.radius;
  angle = mecDirectionMathDirection(angle);
  if (dimType === circularDimType.RADIUS) {
    position[0] = copyIPoint(center);
  } else if (dimType === circularDimType.DIAMETER) {
    position[0] = getPolarPoint(center, angle + Math.PI, radius);
    if (dimStyle.distBaseLine > 0) {
      position[3] = getPolarPoint(center, angle + Math.PI, radius + Math.abs(dimStyle.extBaseLine));
    }
  }
  position[1] = getPolarPoint(center, angle, radius);
  position[2] = getPolarPoint(center, angle, radius + dimStyle.distBaseLine);

  const geometry: THREE.BufferGeometry = new THREE.BufferGeometry();
  const coords: Float32Array = IpointsToBuffer(position);
  geometry.setAttribute("position", new THREE.BufferAttribute(coords, 3));

  let indexPoints: number[] = [1, 2];
  if (dimStyle.distBaseLine > 0) {
    if (dimType === circularDimType.RADIUS) indexPoints = [0, 2];
    else if (dimType === circularDimType.DIAMETER) indexPoints = [2, 3];
  }
  geometry.setIndex(new THREE.BufferAttribute(new Uint16Array(indexPoints), 1));
  (geometry.index as THREE.BufferAttribute).needsUpdate = true;
  geometry.setDrawRange(0, 3);
  return geometry;
}
function getCircularAuxGeometry(objInfo: arcParam, geom: THREE.LineSegments, dimStyle: DimStyleBuilder, angle: number): THREE.Line {
  const geometry: THREE.BufferGeometry = getCircularAuxBuffer(objInfo, dimStyle, angle);
  const resultsLines = new THREE.Line(geometry, getDimAuxLineMaterial());
  adjustDrawRange(resultsLines);
  updateObjBboxBSphere(resultsLines);

  if (!isDirectionInArc(angle, objInfo.azimutO, objInfo.angleCenter)) resultsLines.visible = true;
  else resultsLines.visible = false;
  return resultsLines;
}
function getCircularAuxBuffer(objInfo: arcParam, dimStyle: DimStyleBuilder, angle?: number): THREE.BufferGeometry {
  if (angle === undefined) {
    angle = 0;
    if (isArcParam(objInfo)) {
      angle = mecDirectionMathDirection(getAzimutBisection(objInfo.azimutO, objInfo.angleCenter));
    }
  } else {
    angle = mecDirectionMathDirection(angle);
  }
  const center = { x: 0, y: 0, z: 0 };
  const angleStart = mecDirectionMathDirection(objInfo.azimutO);
  const angleEnd = normalizeAngle(angleStart - objInfo.angleCenter);
  const direction = objInfo.angleCenter < 0 ? ORIENT.CCW : ORIENT.CW;

  let Pstart, Pend;
  if (!isBetweenDirection(angle, angleStart, angleEnd, direction)) {
    const middleDirection = getBisection(angleStart, angleEnd, direction);
    const middlePoint = getPolarPoint(center, middleDirection, objInfo.radius);

    const auxPoint = getPolarPoint(center, angle, objInfo.radius + dimStyle.distBaseLine);
    const relPos = pointLinePositionXY(auxPoint, center, middlePoint);
    if (direction === ORIENT.CW) {
      if (relPos < 0) { // mitad izda
        Pstart = getPolarPoint(center, angle + Math.abs(dimStyle.extBaseLine) / objInfo.radius, objInfo.radius);
        Pend = getPolarPoint(center, angleStart + Math.abs(dimStyle.offset) / objInfo.radius, objInfo.radius);
      } else if (relPos > 0) {
        Pstart = getPolarPoint(center, angleEnd - Math.abs(dimStyle.offset) / objInfo.radius, objInfo.radius);
        Pend = getPolarPoint(center, angle - Math.abs(dimStyle.extBaseLine) / objInfo.radius, objInfo.radius);
      }
    } else if (direction === ORIENT.CCW) {
      if (relPos < 0) { // mitad izda
        Pstart = getPolarPoint(center, angleEnd + Math.abs(dimStyle.offset) / objInfo.radius, objInfo.radius);
        Pend = getPolarPoint(center, angle + Math.abs(dimStyle.extBaseLine) / objInfo.radius, objInfo.radius);
      } else if (relPos > 0) {
        Pstart = getPolarPoint(center, angle - Math.abs(dimStyle.extBaseLine) / objInfo.radius, objInfo.radius);
        Pend = getPolarPoint(center, angleStart - Math.abs(dimStyle.offset) / objInfo.radius, objInfo.radius);
      }
    }
  } else {
    Pstart = getPolarPoint(center, angleStart, objInfo.radius);
    Pend = getPolarPoint(center, angleEnd, objInfo.radius);
  }
  const azimutO = normalizeAngle(lineAzimut2p(center, Pstart as IPoint));
  const azimutEnd = normalizeAngle(lineAzimut2p(center, Pend as IPoint));
  let angleCenter = getAngleBetweenAzimuts(azimutO, azimutEnd, direction);
  if (direction === ORIENT.CCW) angleCenter *= - 1;
  const newBuffer = arcBuffer(center, objInfo.radius, azimutO, angleCenter);
  const newGeometry = new THREE.BufferGeometry();
  newGeometry.setAttribute("position", new THREE.BufferAttribute(newBuffer, 3));
  return newGeometry;
}

// LABEL

function getCircularDimLabelText(dimType: circularDimType, objInfo: radialObj, direction: number, dimStyle: DimStyleBuilder, opts: TextOptsBuilder): THREE.Mesh {
  let text;
  if (dimType === circularDimType.RADIUS) {
    text = "R" + objInfo.radius.toFixed(3);
  } else if (dimType === circularDimType.DIAMETER) {
    text = "Ø" + (objInfo.radius * 2).toFixed(3);
  }
  opts.basePointH = dimStyle.basePointH; // textMultiPosTypeH.MIDDLE;
  opts.basePointV = dimStyle.basePointV; // textMultiPosTypeV.MEDIAN;
  opts.doubleSided = sdfDoubleSidedType.HOR;
  const label = createText({ text }, opts);
  updateCircularDimLabelText(dimType, label, objInfo, direction, dimStyle, opts);
  return label;
}
function updateCircularDimLabelText(dimType: circularDimType, label: THREE.Mesh, objInfo: radialObj, direction: number, dimStyle: DimStyleBuilder, opts: TextOptsBuilder): void {
  const angle = mecDirectionMathDirection(direction);
  const center = { x: 0, y: 0, z: 0 };

  const min = (label.geometry.boundingBox as THREE.Box3).min;
  const max = (label.geometry.boundingBox as THREE.Box3).max;
  const offsetY = (max.y - min.y) * 0.5;
  const offsetX = (max.x - min.x) * 0.5;

  let int; // Punto intersección texto linea Base
  if (dimStyle.distBaseLine >= 0) {
    int = getPolarPoint(center, angle, objInfo.radius + dimStyle.distBaseLine - offsetX);
  } else {
    int = getPolarPoint(center, angle, objInfo.radius + dimStyle.distBaseLine + offsetX);
  }

  let pos;
  if (Math.abs(angle) < Math.PI * 0.5 || Math.abs(angle) > Math.PI * 1.5) {
    pos = getPolarPoint(int, Math.PI * 0.5 + angle, Math.abs(dimStyle.fontOffsetY) + offsetY);
  } else {
    pos = getPolarPoint(int, Math.PI * 0.5 + angle, -Math.abs(dimStyle.fontOffsetY) - offsetY);
  }

  opts.basePointH = textMultiPosTypeH.MIDDLE;
  opts.basePointV = textMultiPosTypeV.MEDIAN;
  opts.doubleSided = sdfDoubleSidedType.HOR;
  if (dimType === circularDimType.RADIUS) {
    const text = "R" + objInfo.radius.toFixed(3);
    modifyText(label, text, opts);
    label.position.set(pos.x, pos.y, pos.z);

  } else if (dimType === circularDimType.DIAMETER) {
    const text = "Ø" + (objInfo.radius * 2).toFixed(3);
    modifyText(label, text, opts);
    label.position.set(pos.x, pos.y, pos.z);
  }

  label.rotation.set(0, 0, 0);
  if (Math.abs(angle) < Math.PI * 0.5 || Math.abs(angle) > Math.PI * 1.5) {
    label.rotateZ(angle);
  } else {
    label.rotateZ(angle - Math.PI);
  }
}

// ARROWS

function getCircularDimArrow<T extends THREE.Mesh | THREE.Line>(dimType: circularDimType, geom: THREE.LineSegments, dimStyle: DimStyleBuilder): T[] {
  const arrow1 = getArrowSymbol(dimStyle.arrowId) as T;
  const arrow2 = getArrowSymbol(dimStyle.arrowId) as T;
  updateCircularDimArrow(dimType, geom, arrow1, arrow2, dimStyle);
  return [arrow1, arrow2];
}
function updateCircularDimArrow(dimType: circularDimType, geom: THREE.LineSegments, arrow1: THREE.Object3D, arrow2: THREE.Object3D, dimStyle: DimStyleBuilder): void {
  const center = getVertexFromIndex(geom, 0) as IPoint;
  let pos = getVertexFromIndex(geom as THREE.Line, 1) as IPoint;
  const radius = vectorDist3D(center, pos);
  arrow1.position.set(pos.x, pos.y, pos.z);

  const angle = lineAngle2p(center, pos);
  arrow1.rotation.set(0, 0, 0);
  arrow1.rotateZ(angle);
  const pos2 = getVertexFromIndex(geom as THREE.Line, 2) as IPoint;
  if (vectorDist3D(center, pos2) < radius) arrow1.rotateZ(Math.PI);

  pos = getVertexFromIndex(geom as THREE.Line, 0) as IPoint;
  if (pos) arrow2.position.set(pos.x, pos.y, pos.z);
  arrow2.rotation.set(0, 0, 0);
  arrow2.setRotationFromEuler(arrow1.rotation);
  arrow2.rotateZ(Math.PI);

  if (dimType === circularDimType.RADIUS || (dimType === circularDimType.DIAMETER && dimStyle.distBaseLine < 0)) {
    arrow2.visible = false;
  } else {
    arrow2.visible = true;
  }
}