import { createCircularDimension, updateCircularDimension } from "lib/dimension/circular-dim-builder";
import { circleParam } from "lib/geometries/circle";
import { getAzimutBisection, lineAzimut2p } from "lib/math/angles";
import { arcParam } from "lib/math/arc";
import { vectorDist3D } from "lib/math/distance";
import { getAzimutPolarPoint } from "lib/math/point";
import { IPoint } from "lib/math/types";
import { isArcData, isLineData, isRadiusBasedGeometry } from "lib/models/checktools";
import { IObjData } from "lib/models/objdata";
import { cadOpType } from "../factory";
import { IPointReference, settingsOpModes } from "../step-operations";
import { DimensionOP } from "./dimension-base";
import * as THREE from "three";
import { polygonParam } from "lib/geometries/polygon";
import { circularDimensionParam, circularDimType } from "lib/models/dimension/circular-dim";
import { getCustomDimStyleProperties } from "lib/dimension/style";
import { DiameterDimensionCommand, RadialDimensionCommand } from "lib/commands/dimension/circular-dim";
import { getArrowSymbol } from "lib/dimension/arrow";
import { arcLineToArcParam, getIsegmentFromIndex } from "lib/math/line";


abstract class CircularDimensionOP extends DimensionOP {

  /* POSICION DE LOS PUNTOS QUE DEFINEN LA GEOMETRÍA DE LA ACOTACIÓN
      Acotación fuera del circulo
       0-----------------------------1----------------------------------·------2
       |            radio            | longFlecha | distBaseLine | textYOffset |
      Acotación dentro del circulo
       0        2-----·----------------------------------1
       |        |textYOffset | distBaseLine | longFlecha |
  */
  protected abstract type: circularDimType;

  protected objInfo: circleParam | arcParam | polygonParam;
  protected edgeIndex: number | undefined;
  protected dimDirection: number;
  protected arrowLong: number;

  protected iniSettingsOp() {
    this.dimDirection = Math.PI * 0.5;
    this.settingsOpManager.setCfg([{
      infoMsg: "Select object (arc, circle or polygon).",
      stepMode: settingsOpModes.SELECTVERTEX,
      filterFun: (obj: IObjData) => {
        if (isRadiusBasedGeometry(obj)) return true;
        if (isLineData(obj)) return true;
        return false;
      },
      getVertexCallback: (ref: IPointReference) => {
        const data = ref.data;
        if (data) {
          if (isRadiusBasedGeometry(data)) {
            this.objDataOrigin = [data];
            this.objInfo = data.definition;
            this.setNextStep();
          } else if (isLineData(data)) {
            this.edgeIndex = ref.edgeIndex;
            const edge = getIsegmentFromIndex(data.definition, ref.edgeIndex);
            if (edge.arc) {
              this.objInfo = arcLineToArcParam(edge.p1, edge.p2, edge.arc)
              this.objDataOrigin = [data];
              this.setNextStep();
            }
          }
        }
      },
      endStepCallback: this.unRegisterRaycast.bind(this),
    }, {
      infoMsg: "Insert dimension.",
      stepMode: settingsOpModes.WAITMODE,
      startStepCallback: () => {
        this.registerInputs();
        this.registerUpdaters();
        this.initializeSnap();
        this.inicializeCircularDim();
      },
      stepFun: this.dispathSaveMouseCoordinates.bind(this),
    }]);
  }

  public async start() {
    await super.start();
    this.registerCancel();
    this.registerRaycast();
  }

  private inicializeCircularDim(): void {
    const planeManager = this.graphicProcessor.getPlaneManager();
    planeManager.activePlane.position = this.objInfo.center;
    planeManager.activePlane.rotation = this.objInfo.plane;
    planeManager.activePlane.locked = true;

    const arrow = getArrowSymbol(this.dimStyle.arrowId);
    const bbox = arrow.geometry.boundingBox as THREE.Box3;
    this.arrowLong = (bbox.max.x - bbox.min.x) * 2;

    const data = this.objDataOrigin[0];
    if (isArcData(data)) {
      this.dimDirection = getAzimutBisection(data.definition.azimutO, data.definition.angleCenter);
    }
    const dim = createCircularDimension(this.type, this.objInfo, this.dimDirection, this.dimStyle, this.txtStyle);
    this.saveDimtoTemp(dim);
  }

  public setLastPoint(): void {
    this.save();
    this.endOperation();
  }
  public moveLastPoint(point: IPoint) {
    const planeManager = this.graphicProcessor.getPlaneManager();
    const c = planeManager.activePlane.getRelativePoint(this.objInfo.center);
    const p = planeManager.activePlane.getRelativePoint(point);
    this.dimDirection = lineAzimut2p(c, p);

    const P1 = getAzimutPolarPoint(c, this.dimDirection, this.objInfo.radius);
    this.dimStyle.distBaseLine = vectorDist3D(P1, p);

    const min = (this.textLabel.geometry.boundingBox as THREE.Box3).min;
    const max = (this.textLabel.geometry.boundingBox as THREE.Box3).max;
    const textLong = (max.x - min.x);

    if (Math.abs(this.dimStyle.distBaseLine) < this.arrowLong + textLong) {
      this.dimStyle.distBaseLine = this.arrowLong + textLong;
    }
    if (vectorDist3D(this.objInfo.center, point) > this.objInfo.radius) {
      this.dimStyle.distBaseLine = Math.abs(this.dimStyle.distBaseLine);
    } else {
      this.dimStyle.distBaseLine = -Math.abs(this.dimStyle.distBaseLine);
    }
    const dimInfo: circularDimensionParam = {
      styleId: this.dimStyle.styleId,
      type: this.type,
      customStyleProp: getCustomDimStyleProperties(this.dimStyle, this.defDimStyle),
      objBase: this.objInfo,
      dimDirectionAngle: this.dimDirection,
      data: this.objDataOrigin[0],
    };
    updateCircularDimension(this.dimensionGroup, dimInfo, this.dimStyle, this.txtStyle);
  }
}
export class RadialDimensionOP extends CircularDimensionOP {

  public opType = cadOpType.RADIUSDIM;
  protected type = circularDimType.RADIUS;

  public save() {
    const dimInfo: circularDimensionParam = {
      styleId: this.dimStyle.styleId,
      type: this.type,
      customStyleProp: getCustomDimStyleProperties(this.dimStyle, this.defDimStyle),
      objBase: this.objInfo,
      dimDirectionAngle: this.dimDirection,
      data: this.objDataOrigin[0],
      edgeIndex: this.edgeIndex,

    };
    const command = new RadialDimensionCommand(dimInfo, this.getCurrentSceneId(), this.graphicProcessor);
    if (command) this.graphicProcessor.storeAndExecute(command);
  }
}
export class DiametroDimensionOP extends CircularDimensionOP {

  public opType = cadOpType.DIAMETERDIM;
  protected type = circularDimType.DIAMETER;

  public save() {
    const dimInfo: circularDimensionParam = {
      styleId: this.dimStyle.styleId,
      type: this.type,
      customStyleProp: getCustomDimStyleProperties(this.dimStyle, this.defDimStyle),
      objBase: this.objInfo,
      dimDirectionAngle: this.dimDirection,
      data: this.objDataOrigin[0],
      edgeIndex: this.edgeIndex,
    };
    const command = new DiameterDimensionCommand(dimInfo, this.getCurrentSceneId(), this.graphicProcessor);
    if (command) this.graphicProcessor.storeAndExecute(command);
  }
}