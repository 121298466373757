import { IColor } from "lib/math/types";
import { IObjData } from "../models/objdata";
import { objDataType } from "../models/types";
import { ArcDataDefinitionHandler } from "./primitives/arc";
import { BlockDataDefinitionHandler } from "./block";
import { CircleDataDefinitionHandler } from "./primitives/circle";
import { ConeDataDefinitionHandler } from "./solids/cone";
import { CubeDataDefinitionHandler } from "./solids/cube";
import { CylinderDataDefinitionHandler } from "./solids/cylinder";
import { AlignedDimensionDefinitionHandler } from "./dimension/aligned-dim";
import { AngleDimensionDefinitionHandler } from "./dimension/angle-dim";
import { ArcDimensionDefinitionHandler } from "./dimension/arc-dim";
import { CircularDimensionDefinitionHandler } from "./dimension/circular-dim";
import { LinealDimensionDefinitionHandler } from "./dimension/lineal-dim";
import { EllipseDataDefinitionHandler } from "./primitives/ellipse";
import { EllipseArcDataDefinitionHandler } from "./primitives/ellipse-arc";
import { PlaneDataDefinitionHandler } from "./solids/plane";
import { PointDataDefinitionHandler } from "./primitives/point";
import { PolygonDataDefinitionHandler } from "./primitives/polygon";
import { PolylineDataDefinitionHandler } from "./primitives/polyline";
import { PPrismDataDefinitionHandler } from "./solids/pprism";
import { RegionDataDefinitionHandler } from "./solids/region";
import { RPrismDataDefinitionHandler } from "./solids/rprism";
import { BeamDataDefinitionHandler } from "./structural/beam";
import { ColumnDataDefinitionHandler } from "./structural/column";
import { SlabDataDefinitionHandler } from "./structural/slab";
import { TextDataDefinitionHandler } from "./text";
import { WallDataDefinitionHandler } from "./structural/wall";
import { LoadDataDefinitionHandler } from "./structural/load";
import { FooterDataDefinitionHandler } from "./structural/footer";
import { PileCapDataDefinitionHandler } from "./structural/pile-cap";
import { NoneDataDefinitionHandler } from "./base";
import { GraphicProcessor } from "lib/graphic-processor";
import { StripFooterDataDefinitionHandler } from "./structural/stripfooter";

const ObjDataDefinitionHandler = {
  [objDataType.POINT]: PointDataDefinitionHandler,
  [objDataType.POLYLINE]: PolylineDataDefinitionHandler,
  [objDataType.POLYGON]: PolygonDataDefinitionHandler,
  [objDataType.CIRCLE]: CircleDataDefinitionHandler,
  [objDataType.TEXT]: TextDataDefinitionHandler,
  [objDataType.REGION]: RegionDataDefinitionHandler,
  [objDataType.ARC]: ArcDataDefinitionHandler,
  [objDataType.ELLIPSE]: EllipseDataDefinitionHandler,
  [objDataType.ELLIPSEARC]: EllipseArcDataDefinitionHandler,
  [objDataType.CUBE]: CubeDataDefinitionHandler,
  [objDataType.CYLINDER]: CylinderDataDefinitionHandler,
  [objDataType.PLANE]: PlaneDataDefinitionHandler,
  [objDataType.CONE]: ConeDataDefinitionHandler,
  [objDataType.RRPRIM]: RPrismDataDefinitionHandler,
  [objDataType.PPRISM]: PPrismDataDefinitionHandler,
  [objDataType.BLOCK]: BlockDataDefinitionHandler,
  [objDataType.ALIGNEDDIM]: AlignedDimensionDefinitionHandler,
  [objDataType.ANGULARDIM]: AngleDimensionDefinitionHandler,
  [objDataType.ARCDIM]: ArcDimensionDefinitionHandler,
  [objDataType.CIRCULARDIM]: CircularDimensionDefinitionHandler,
  [objDataType.LINEARDIM]: LinealDimensionDefinitionHandler,
  [objDataType.COLUMN]: ColumnDataDefinitionHandler,
  [objDataType.SLAB]: SlabDataDefinitionHandler,
  [objDataType.BEAM]: BeamDataDefinitionHandler,
  [objDataType.WALL]: WallDataDefinitionHandler,
  [objDataType.LOAD]: LoadDataDefinitionHandler,
  [objDataType.FOOTER]: FooterDataDefinitionHandler,
  [objDataType.STRIPFOOTER]: StripFooterDataDefinitionHandler,
  [objDataType.PILECAP]: PileCapDataDefinitionHandler,
  [objDataType.BLOCKREF]: null,
}

export function getDataDefinitionHandler(data: IObjData, graphicProc: GraphicProcessor) {
  const definitionHandlerBuilder = ObjDataDefinitionHandler[data.type];
  if (definitionHandlerBuilder) {
    const handler = new definitionHandlerBuilder();
    handler.setObjData(data, graphicProc);
    return handler;
  }
  return new NoneDataDefinitionHandler();
}

interface IProperty {
  type: "number" | "string" | "checkbox" | "list" | "tagList" | "color" | "button" | "range" | "radio",
  publicName: string;
  title?: string;
}
export interface IValueProperty<T> extends IProperty {
  value: T | (() => T);
  editable?: boolean;
  check?: (a: T) => boolean;
  checkError?: string;
  customCb?: (val: any) => void;
  parseFun?: (v: T) => T;
}
interface INumberProperty extends IValueProperty<number> {
  type: "number",
  max?: number;
  min?: number;
  units?: string;
  precision?: number;
}
interface IStringProperty extends IValueProperty<string> {
  type: "string",
}
export interface ICheckBoxProperty extends IValueProperty<boolean> {
  type: "checkbox",
}
interface IListProperty extends IValueProperty<number> {
  type: "list",
  list: string[];
}
interface ITagListProperty extends IValueProperty<string | number> {
  type: "tagList",
  tagList: [string, string | number][];
}
export interface IColorProperty extends IValueProperty<IColor> {
  type: "color",
}
interface IRangeProperty extends IValueProperty<number> {
  type: "range",
  max: number;
  min: number;
  step: number;
}
interface IRadioProperty extends IValueProperty<string> {
  type: "radio",
  list: string[];
}
interface IButtonProperty extends IProperty {
  type: "button",
  editable?: boolean;
  buttonCb: () => void;
}

export type infoProperty =
  INumberProperty |
  IStringProperty |
  ICheckBoxProperty |
  IListProperty |
  ITagListProperty |
  IColorProperty |
  IButtonProperty |
  IRangeProperty |
  IRadioProperty;

export type dataInfoProperty<T> = {
  [P in keyof T]?:
  T[P] extends IColor ? IColorProperty :
  T[P] extends boolean ? ICheckBoxProperty :
  T[P] extends Array<infer U> ? Array<dataInfoProperty<U>> :
  T[P] extends object ? dataInfoProperty<T[P]> & { publicName: string } :
  infoProperty
}

export function isInfoProperty(obj: any): obj is infoProperty {
  return obj && (obj.value !== undefined || obj.type);
}
