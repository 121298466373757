import ProjectTreeMenu from "../tree-menu/tree-menu";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import StoreyNavigation from "modules/struc/components/storey-navigation/storey-navigator";
import PropertyPanel from "../properties/property-panel";
import VerticalResizer from "shared/components/ui/resizer/vertical-resizer";
import { useMainGraphicContext } from "../viewport/context";
import { LayerProvider } from "../layers/context";
import { SideBarProvider } from "./context";

const SideBarWrapper = styled.div`
  min-width: 256px;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
`;

const ViewportSidebar = () => {

  const isStrucModule = (pathname: string) => pathname.includes("struc");
  const { pathname } = useLocation();
  const strucModule = isStrucModule(pathname);

  const graphicProcessor = useMainGraphicContext();

  return (
    <SideBarWrapper aria-label="sidebar" className="border-gray-600">
      {strucModule && <StoreyNavigation />}
      <SideBarProvider>
        <LayerProvider graphicProc={graphicProcessor}>
          <VerticalResizer id={"mainXX"}
            topChild={<ProjectTreeMenu />}
            bottomChild={<PropertyPanel />}
          />
        </LayerProvider>
      </SideBarProvider>
    </SideBarWrapper>
  );
};

export default ViewportSidebar;
