import { MeasurementResult } from "modules/lite/models/measurement";
import { useCallback, useState } from "react";
import RenderIf from "shared/components/ui/render-if";
import Table from "shared/components/ui/table";

interface Props {
  measurements?: MeasurementResult;
}

function useExpanded() {
  const [expandingKey, setExpandingKey] = useState<string | null>(null);
  const handleExpand = useCallback(
    (selectedKey) => {
      const rowKeyToExpand = selectedKey !== expandingKey ? selectedKey : null;
      setExpandingKey(rowKeyToExpand);
    },
    [expandingKey]
  );
  return { expandingKey, handleExpand };
}
const MeasurementTable = ({ measurements }: Props) => {
  const { expandingKey, handleExpand } = useExpanded();
  if (!measurements) return null;
  const { summary, buildings } = measurements;
  return (
    <RenderIf isTrue={!!summary && !!buildings}>
      <Table.Container>
        <Table>
          <thead>
            <tr>
              <Table.HeaderCell colSpan={2} rowSpan={2} />
              <Table.HeaderCell rowSpan={2}>depth</Table.HeaderCell>
              <Table.HeaderCell rowSpan={2}>m2</Table.HeaderCell>
              <Table.HeaderCell colSpan={6} rowSpan={1}>
                steel
              </Table.HeaderCell>
              <Table.HeaderCell colSpan={5} rowSpan={1}>
                concrete
              </Table.HeaderCell>
              <Table.HeaderCell colSpan={7} rowSpan={1}>
                blocks
              </Table.HeaderCell>
            </tr>
            <tr>
              <Table.HeaderCell>type</Table.HeaderCell>
              <Table.HeaderCell>kg</Table.HeaderCell>
              <Table.HeaderCell>kg/m2</Table.HeaderCell>
              <Table.HeaderCell>kg/m3</Table.HeaderCell>
              <Table.HeaderCell>Additional</Table.HeaderCell>
              <Table.HeaderCell>Total</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>m3</Table.HeaderCell>
              <Table.HeaderCell>m3/m2</Table.HeaderCell>
              <Table.HeaderCell>Additional</Table.HeaderCell>
              <Table.HeaderCell>Total</Table.HeaderCell>
              <Table.HeaderCell>Number</Table.HeaderCell>
              <Table.HeaderCell>Depth</Table.HeaderCell>
              <Table.HeaderCell>Large</Table.HeaderCell>
              <Table.HeaderCell>Width</Table.HeaderCell>
              <Table.HeaderCell>m3</Table.HeaderCell>
              <Table.HeaderCell>Additional</Table.HeaderCell>
              <Table.HeaderCell>Total</Table.HeaderCell>
            </tr>
          </thead>
          <Table.Body>
            <Table.Row onClick={() => handleExpand(summary.name)}>
              <Table.Cell>{summary.name}</Table.Cell>
              <Table.Cell />
              <Table.Cell>{summary.summary.depth}</Table.Cell>
              <Table.Cell>{summary.summary.m2?.toFixed(2)}</Table.Cell>
              <Table.Cell>{summary.summary.steel_type}</Table.Cell>
              <Table.Cell>{summary.summary.steel_kg?.toFixed(2)}</Table.Cell>
              <Table.Cell>{summary.summary.steel_kg_m2?.toFixed(2)}</Table.Cell>
              <Table.Cell>{summary.summary.steel_kg_m3?.toFixed(2)}</Table.Cell>
              <Table.Cell>{summary.summary.steel_additional}</Table.Cell>
              <Table.Cell>{summary.summary.steel_total?.toFixed(2)}</Table.Cell>
              <Table.Cell>{summary.summary.concrete_type}</Table.Cell>
              <Table.Cell>{summary.summary.concrete_m3?.toFixed(2)}</Table.Cell>
              <Table.Cell>
                {summary.summary.concrete_m3_m2?.toFixed(2)}
              </Table.Cell>
              <Table.Cell>{summary.summary.concrete_additional}</Table.Cell>
              <Table.Cell>
                {summary.summary.concrete_total?.toFixed(2)}
              </Table.Cell>
              <Table.Cell>{summary.summary.blocks_number}</Table.Cell>
              <Table.Cell>{summary.summary.blocks_depth}</Table.Cell>
              <Table.Cell>{summary.summary.blocks_large}</Table.Cell>
              <Table.Cell>{summary.summary.blocks_width}</Table.Cell>
              <Table.Cell>{summary.summary.blocks_m3?.toFixed(2)}</Table.Cell>
              <Table.Cell>{summary.summary.blocks_additional}</Table.Cell>
              <Table.Cell>
                {summary.summary.blocks_total?.toFixed(2)}
              </Table.Cell>
            </Table.Row>
            {summary.element_types.map((measurement, index) => (
              <Table.Row key={index} isOpen={expandingKey === summary.name}>
                <Table.Cell />
                <Table.Cell>{measurement.element_type}</Table.Cell>
                <Table.Cell>{measurement.depth}</Table.Cell>
                <Table.Cell>{measurement.m2?.toFixed(2)}</Table.Cell>
                <Table.Cell>{measurement.steel_type}</Table.Cell>
                <Table.Cell>{measurement.steel_kg?.toFixed(2)}</Table.Cell>
                <Table.Cell>{measurement.steel_kg_m2?.toFixed(2)}</Table.Cell>
                <Table.Cell>{measurement.steel_kg_m3?.toFixed(2)}</Table.Cell>
                <Table.Cell>{measurement.steel_additional}</Table.Cell>
                <Table.Cell>{measurement.steel_total?.toFixed(2)}</Table.Cell>
                <Table.Cell>{measurement.concrete_type}</Table.Cell>
                <Table.Cell>{measurement.concrete_m3?.toFixed(2)}</Table.Cell>
                <Table.Cell>
                  {measurement.concrete_m3_m2?.toFixed(2)}
                </Table.Cell>
                <Table.Cell>
                  {measurement.concrete_total?.toFixed(2)}
                </Table.Cell>
                <Table.Cell>{measurement.concrete_additional}</Table.Cell>
                <Table.Cell>
                  {measurement.concrete_total?.toFixed(2)}
                </Table.Cell>
                <Table.Cell>{measurement.blocks_number}</Table.Cell>
                <Table.Cell>{measurement.blocks_depth}</Table.Cell>
                <Table.Cell>{measurement.blocks_large}</Table.Cell>
                <Table.Cell>{measurement.blocks_width}</Table.Cell>
                <Table.Cell>{measurement.blocks_m3?.toFixed(2)}</Table.Cell>
                <Table.Cell>{measurement.blocks_additional}</Table.Cell>
              </Table.Row>
            ))}
            {buildings.map(({ storeys }, index) =>
              storeys.map((storey, storeyIndex) => (
                <>
                  <Table.Row
                    key={storeyIndex}
                    onClick={() => handleExpand(storey.name)}
                  >
                    <Table.Cell>{storey.name}</Table.Cell>
                    <Table.Cell />
                    <Table.Cell>{storey.summary.depth}</Table.Cell>
                    <Table.Cell>{storey.summary.m2?.toFixed(2)}</Table.Cell>
                    <Table.Cell>{storey.summary.steel_type}</Table.Cell>
                    <Table.Cell>
                      {storey.summary.steel_kg?.toFixed(2)}
                    </Table.Cell>
                    <Table.Cell>
                      {storey.summary.steel_kg_m2?.toFixed(2)}
                    </Table.Cell>
                    <Table.Cell>
                      {storey.summary.steel_kg_m3?.toFixed(2)}
                    </Table.Cell>
                    <Table.Cell>{storey.summary.steel_additional}</Table.Cell>
                    <Table.Cell>
                      {storey.summary.steel_total?.toFixed(2)}
                    </Table.Cell>
                    <Table.Cell>{storey.summary.concrete_type}</Table.Cell>
                    <Table.Cell>
                      {storey.summary.concrete_m3?.toFixed(2)}
                    </Table.Cell>
                    <Table.Cell>
                      {storey.summary.concrete_m3_m2?.toFixed(2)}
                    </Table.Cell>
                    <Table.Cell>
                      {storey.summary.concrete_additional}
                    </Table.Cell>
                    <Table.Cell>
                      {storey.summary.concrete_total?.toFixed(2)}
                    </Table.Cell>
                    <Table.Cell>{storey.summary.blocks_number}</Table.Cell>
                    <Table.Cell>{storey.summary.blocks_depth}</Table.Cell>
                    <Table.Cell>{storey.summary.blocks_large}</Table.Cell>
                    <Table.Cell>{storey.summary.blocks_width}</Table.Cell>
                    <Table.Cell>
                      {storey.summary.blocks_m3?.toFixed(2)}
                    </Table.Cell>
                    <Table.Cell>{storey.summary.blocks_additional}</Table.Cell>
                    <Table.Cell>
                      {storey.summary.blocks_total?.toFixed(2)}
                    </Table.Cell>
                  </Table.Row>
                  {storey.element_types.map((measurement, measurementIndex) => (
                    <Table.Row
                      key={measurementIndex}
                      isOpen={expandingKey === storey.name}
                    >
                      <Table.Cell />
                      <Table.Cell>{measurement.element_type}</Table.Cell>
                      <Table.Cell>{measurement.depth}</Table.Cell>
                      <Table.Cell>{measurement.m2?.toFixed(2)}</Table.Cell>
                      <Table.Cell>{measurement.steel_type}</Table.Cell>
                      <Table.Cell>
                        {measurement.steel_kg?.toFixed(2)}
                      </Table.Cell>
                      <Table.Cell>
                        {measurement.steel_kg_m2?.toFixed(2)}
                      </Table.Cell>
                      <Table.Cell>
                        {measurement.steel_kg_m3?.toFixed(2)}
                      </Table.Cell>
                      <Table.Cell>{measurement.steel_additional}</Table.Cell>
                      <Table.Cell>
                        {measurement.steel_total?.toFixed(2)}
                      </Table.Cell>
                      <Table.Cell>{measurement.concrete_type}</Table.Cell>
                      <Table.Cell>
                        {measurement.concrete_m3?.toFixed(2)}
                      </Table.Cell>
                      <Table.Cell>
                        {measurement.concrete_m3_m2?.toFixed(2)}
                      </Table.Cell>
                      <Table.Cell>{measurement.concrete_additional}</Table.Cell>
                      <Table.Cell>
                        {measurement.concrete_total?.toFixed(2)}
                      </Table.Cell>
                      <Table.Cell>{measurement.blocks_number}</Table.Cell>
                      <Table.Cell>{measurement.blocks_depth}</Table.Cell>
                      <Table.Cell>{measurement.blocks_large}</Table.Cell>
                      <Table.Cell>{measurement.blocks_width}</Table.Cell>
                      <Table.Cell>
                        {measurement.blocks_m3?.toFixed(2)}
                      </Table.Cell>
                      <Table.Cell>{measurement.blocks_additional}</Table.Cell>
                      <Table.Cell>
                        {measurement.blocks_total?.toFixed(2)}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </>
              ))
            )}
          </Table.Body>
        </Table>
      </Table.Container>
    </RenderIf>
  );
};

export default MeasurementTable;
