import Page from "../components/ui/page";
import useProjects from "../components/projects/hooks/use-projects";
import ProjectList from "../components/projects/project-list";
import Tabs from "shared/components/ui/tabs";
import { PrimaryLink } from "shared/components/ui/link";

const LiteProjects = () => {
  const { projects } = useProjects();
  return (
    <Page
      title="Projects"
      actions={<PrimaryLink to="/lite/projects/new">New project</PrimaryLink>}
    >
      {/*       <Tabs options={["all", "succeeded", "created", "failed"]} />
       */}
      {projects && <ProjectList projects={projects} />}
    </Page>
  );
};

export default LiteProjects;
