import { SSERecord, SSEDirs, SSHypothesis } from "lib/models-struc/hypothesis/hypothesis";
import { SSEhypothesisManager } from "lib/models-struc/hypothesis/sse";
import PropertiesAccordion from "modules/cad/components/properties/component-accordion";
import PropertiesButton from "modules/cad/components/properties/component-button";
import PropertiesSelect from "modules/cad/components/properties/component-select";
import { useCallback, useMemo, useState } from "react";
import { ScatterChart, CartesianGrid, XAxis, YAxis, ZAxis, Tooltip, Scatter } from "recharts";
import { useBoundContainer } from "shared/components/ui/hooks/use-heigth";
import Table from "../../../../shared/components/ui/table/table";

function getColumn(values: SSERecord[]) {
  const len = values.length;
  const column = [{ Header: 'x', accessor: 'id', }];
  for (let i = 0; i < len; i++) {
    column.push({ Header: (i - 1).toString(), accessor: `data${i}` })
  }
  return column;
}
function getDataTable(values: SSERecord[]) {
  const data: Record<string, any>[] = [{ id: "T" }, { id: "a/g" }];
  for (let i = 0; i < values.length; i++) {
    const { T, ag } = values[i];
    const key = `data${i}`;
    data[0][key] = T;
    data[1][key] = ag;
  }
  return data;
}

interface props {
  type: SSEDirs,
}
export const SSEPropertyPanel = ({ type }: props) => {

  const SSE = useMemo<SSHypothesis>(() => SSEhypothesisManager.getSSE(type), [type])

  const SSE2import = SSEhypothesisManager.getActiveSSEDirs(type);

  const [data, setData] = useState([...SSE.values]);
  const [column, setColumn] = useState(() => getColumn(data));
  const [dataTable, setDataTable] = useState(() => getDataTable(data));

  const importSSEValues = useCallback((indx: number) => {
    const fromSSE1 = SSEhypothesisManager.getSSE(SSE2import[indx]);
    SSE.values = fromSSE1.values.map(v => ({ T: v.T, ag: v.ag }));
    setData([...SSE.values]);
    setColumn(getColumn(SSE.values));
    setDataTable(getDataTable(SSE.values))
  }, [SSE, SSE2import])

  const updateData = useCallback((rowIndex: number, columnId: string, value: string) => {
    setDataTable((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: parseFloat(value)
          };
        }
        return row;
      })
    );

    const rowI = parseInt(columnId.charAt(columnId.length - 1));
    const key: keyof SSERecord = rowIndex === 0 ? "T" : "ag";
    setData((old) =>
      old.map((row, index) => {
        if (index === rowI) {
          return {
            ...old[index],
            [key]: parseFloat(value)
          };
        }
        return row;
      })
    )

    SSE.values[rowI][key] = parseFloat(value);

  }, [SSE.values])

  const addPoint = useCallback(() => {
    const lastData = SSE.values[SSE.values.length - 1];
    SSE.values.push({ T: lastData.T, ag: lastData.ag });
    setData([...SSE.values]);
    setColumn(getColumn(SSE.values));
    setDataTable(getDataTable(SSE.values));
  }, [SSE])

  const removePoint = useCallback(() => {
    if (SSE.values.length > 1) {
      SSE.values.pop();
      setData([...SSE.values]);
      setColumn(getColumn(SSE.values));
      setDataTable(getDataTable(SSE.values));
    }
  }, [SSE.values])

  const { divRef, bounds } = useBoundContainer()

  return (
    <div className="layers h-full p-2 space-y-2 overflow-y-auto" >
      <PropertiesAccordion title={`Properties ${type}`}>
        {SSE2import.length > 0 &&
          <PropertiesSelect
            srOnly={true}
            value={0}
            onChange={(ev) => {
              const indx = (ev.target as HTMLSelectElement).selectedIndex;
              importSSEValues(indx - 1);
            }}
          >
            <option disabled selected value={0}>Importr SSE</option>
            {SSE2import.map((val, i) => (
              <option key={i}> {val} </option>
            ))}
          </PropertiesSelect>
        }

        <div className="pt-2 flex gap-2">
          <PropertiesButton
            label={"Add point"}
            buttonCb={addPoint}
          />
          <PropertiesButton
            disabled={SSE.values.length === 1}
            label={"Remove point"}
            buttonCb={removePoint}
          />
        </div>
        <div className="pt-2">
          <Table
            columns={column}
            hideColumn={true}
            data={dataTable}
            updateMyData={updateData}
          />
        </div>

        <div ref={divRef}>
          <ScatterChart
            width={bounds.width ?? 300}
            height={bounds.width / 2}
            margin={{ top: 20, right: 10, bottom: 10, left: 10 }}
          >
            <CartesianGrid />
            <XAxis type="number" dataKey="T" style={{ fontSize: "0.75rem" }} name="T"
              label={{ value: 'T', position: "bottom", offset: 0, fill: 'white' }} />
            <YAxis type="number" dataKey="ag" style={{ fontSize: "0.75rem" }} name="a/g"
              label={{ value: `a/g`, position: "left", offset: -20, fill: 'white' }} />
            <ZAxis type="number" range={[50]} />
            <Tooltip cursor={{ strokeDasharray: "3 3" }} />
            <Scatter
              name="SSE"
              data={data}
              fill="#8884d8"
              line
              shape="circle"
            />
          </ScatterChart>
        </div>


      </PropertiesAccordion>
    </div>
  );
};