const ArcDimensionIcon = ({ ...props }) => {
  return (
    <svg className="h-5 w-5" viewBox="0 0 100 100" fill="none" {...props}>
      <path
        d="M10 23.83V27.83C44.28 27.83 72.17 55.72 72.17 90H76.17C76.17 53.514 46.486 23.83 10 23.83Z"
        fill="currentColor"
      />
      <path
        d="M10 8V12C53.01 12 88 46.991 88 90H92C92 44.785 55.215 8 10 8Z"
        fill="currentColor"
      />
      <path
        d="M8 0C7.46957 0 6.96086 0.170241 6.58579 0.473271C6.21071 0.7763 6 1.1873 6 1.61584V37.3842C6 37.8127 6.21071 38.2237 6.58579 38.5267C6.96086 38.8298 7.46957 39 8 39C8.53043 39 9.03914 38.8298 9.41421 38.5267C9.78929 38.2237 10 37.8127 10 37.3842V1.61584C10 1.1873 9.78929 0.7763 9.41421 0.473271C9.03914 0.170241 8.53043 0 8 0Z"
        fill="currentColor"
      />
      <path
        d="M68.3258 90H97.6742C98.0258 90 98.363 89.7893 98.6117 89.4142C98.8603 89.0391 99 88.5304 99 88C99 87.4696 98.8603 86.9609 98.6117 86.5858C98.363 86.2107 98.0258 86 97.6742 86H68.3258C67.9742 86 67.637 86.2107 67.3883 86.5858C67.1397 86.9609 67 87.4696 67 88C67 88.5304 67.1397 89.0391 67.3883 89.4142C67.637 89.7893 67.9742 90 68.3258 90Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArcDimensionIcon;
