import { dimensionCache } from "lib/dimension/cache";
import { radAngleToUser, getUserAngleUnitSufix, angularPrecision, userNormalizeAngleToRad, linealPrecision } from "lib/general-settings";
import { GraphicProcessor } from "lib/graphic-processor";
import { copyMaterial, ILineMaterial, IPointMaterial, ISolidMaterial } from "lib/materials";
import { epsilonLinealPrecision, isEqual, isEqualAngle } from "lib/math/epsilon";
import { copyIPoint } from "lib/math/point";
import { IColor, IPoint } from "lib/math/types";
import { beamCrossSectionCache } from "lib/models-struc/cross-sections-shape/beam-cross-sections/cache";
import { IObjData } from "lib/models/objdata";
import { copyColor } from "lib/styles/colors";
import { lineStyleCache } from "lib/line-styles/cache";
import { textStyleCache } from "lib/text/cache";
import { dataInfoProperty, ICheckBoxProperty, IColorProperty, infoProperty } from "./properties";

export abstract class DataDefinitionHandler<T, U, V, W> {

  protected graphicProcessor: GraphicProcessor;
  protected abstract data: IObjData;
  protected abstract buildInfoProperties(): void

  public definitionInfo: dataInfoProperty<T>;
  public materialInfo: dataInfoProperty<U>;
  public styleInfo: dataInfoProperty<V>;
  public structuralInfo: dataInfoProperty<W>;

  public saveAndRegenerate: (newDefinition: T) => void;
  public saveAndRegenerateMaterial: (newMaterial: U) => void;
  public saveAndRegenerateStyle: (newStyle: V) => void;
  public saveAndRegenerateStruct: (newStructural: W) => void;

  public setObjData(objData: IObjData, graphicProc: GraphicProcessor) {
    this.data = objData;
    this.graphicProcessor = graphicProc;
    this.buildInfoProperties();
  }

  protected getAngleView(angle: number, name: string, units?: string, precision: number = angularPrecision, editable: boolean = true, func?: (angle: number) => boolean): infoProperty {
    return {
      type: "number",
      value: radAngleToUser(angle),
      publicName: name,
      units: units ? units : getUserAngleUnitSufix(),
      precision,
      editable,
      check: func,
    }
  }
  protected getNumberView(num: number, name: string, units: string, precision: number, editable: boolean = true, func?: (num: number) => boolean): infoProperty {
    return {
      type: "number",
      value: num,
      publicName: name,
      units: units,
      precision,
      editable,
      check: func,
    }
  }
  protected getRangeView(num: number, name: string, precision: number, min: number, max: number, step: number, editable: boolean = true): infoProperty {
    return {
      type: "range",
      value: num,
      publicName: name,
      min,
      max,
      step,
      editable,
    }
  }
  protected getBooleanView(bool: boolean, name: string, editable: boolean = true, func?: (num: boolean) => boolean): ICheckBoxProperty {
    return {
      value: bool,
      type: "checkbox",
      publicName: name,
      editable,
      check: func,
    }
  }
  protected getPointView(point: IPoint, name: string, units: string, precision: number, editable: boolean = true, func?: (num: number) => boolean): dataInfoProperty<IPoint> & { publicName: string } {
    return {
      publicName: name,
      x: this.getNumberView(point.x, "X", units, precision, editable, func ? () => func(point.x) : () => { return true; }),
      y: this.getNumberView(point.y, "Y", units, precision, editable, func ? () => func(point.y) : () => { return true; }),
      z: this.getNumberView(point.z, "Z", units, precision, editable, func ? () => func(point.z) : () => { return true; }),
    }
  }
  protected getPointAngleView(point: IPoint, name: string, isEditable: boolean = true): dataInfoProperty<IPoint> & { publicName: string } {
    return {
      publicName: name,
      x: this.getAngleView(point.x, "X", undefined, undefined, isEditable),
      y: this.getAngleView(point.y, "Y", undefined, undefined, isEditable),
      z: this.getAngleView(point.z, "Z", undefined, undefined, isEditable),
    }
  }
  protected getColorView(color: IColor): IColorProperty {
    return {
      publicName: "Color",
      type: "color",
      value: color,
      editable: true,
    }
  }
  protected getLineMaterialView(material: ILineMaterial): dataInfoProperty<ILineMaterial & { opacity: number }> {
    const layer = this.data.layerObj;
    if (layer.isBulkData) {
      return {
        color: this.getColorView(material.color),
      }
    } else {
      return {
        lineStyleId: this.getLineStyleView(material.lineStyleId),
        color: this.getColorView(material.color),
        opacity: this.getRangeView(material.color.a, "Opacity", 1, 0, 1, 0.1, true),
        width: this.getNumberView(material.width, "Width", "m", linealPrecision),
      }
    }
  }
  protected getPointMaterialView(material: IPointMaterial): dataInfoProperty<IPointMaterial & { opacity: number }> {
    return {
      color: this.getColorView(material.color),
      opacity: this.getRangeView(material.color.a, "Opacity", 1, 0, 1, 0.1, true),
      size: this.getNumberView(material.size, "Size", "m", linealPrecision),
    }
  }
  protected getSolidMaterialView(material: ISolidMaterial): dataInfoProperty<ISolidMaterial & { opacity: number }> {
    return {
      color: this.getColorView(material.color),
      opacity: this.getRangeView(material.color.a, "Opacity", 1, 0, 1, 0.1, true),
    }
  }
  protected getLineStyleView(lineStyle: string): infoProperty {
    const styles = lineStyleCache.getAllLoadedStyles();
    return {
      publicName: "Line style",
      type: "tagList",
      tagList: styles.map(s => [s.name, s.styleId]),
      value: lineStyle,
    }
  }
  protected getTextStyleView(textStyleId: string): infoProperty {
    const styles = textStyleCache.getAllLoadedStyles();
    return {
      publicName: "Text style",
      type: "tagList",
      tagList: styles.map(s => [s.name, s.styleId]),
      value: textStyleId,
    }
  }
  protected getDimensionStyleView(dimensionStyleId: string): infoProperty {
    const styles = dimensionCache.getAllLoadedStyles();
    return {
      publicName: "Dimension style",
      type: "tagList",
      tagList: styles.map(s => [s.name, s.styleId]),
      value: dimensionStyleId,
    }
  }
  protected getBeamCrossSectionView(crossSectionId: string): infoProperty {
    const beamCS = beamCrossSectionCache.getAllLoadedStyles().sort((a, b) => a.name.localeCompare(b.name));
    const beamCrossSectionListNames: [string, string][] = beamCS.map(s => [s.name, s.styleId]);
    return {
      publicName: "Beam cross section",
      value: crossSectionId,
      type: "tagList",
      tagList: beamCrossSectionListNames
    };
  }

  protected getEnumView(enumValue: number, listStrings: string[], name: string, editable: boolean = true): infoProperty {
    return {
      publicName: name,
      type: "list",
      list: listStrings,
      value: enumValue,
      editable,
    }
  }
  protected getStringView(str: string, name: string, editable: boolean = true, func?: (num: string) => boolean): infoProperty {
    return {
      type: "string",
      value: str,
      publicName: name,
      editable,
      check: func,
    }
  }

  // BASIC TYPES CHECKERS AND SETTERS

  protected checkNumber(num?: number, func?: (num: number) => boolean): boolean {
    if (num !== undefined) {
      if (func) {
        return func(num);
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  protected checkEnum(enu?: number, func?: (enu: number) => boolean): boolean {
    return this.checkNumber(enu, func);
  }
  protected checkString(str?: string, func?: (str: string) => boolean): boolean {
    if (str !== undefined) {
      if (func) {
        return func(str);
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  protected checkBoolean(bool?: boolean, func?: (bool: boolean) => boolean): boolean {
    if (bool !== undefined) {
      if (func) {
        return func(bool);
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  protected checkAngle(angle?: number, func?: (angle: number) => boolean): boolean {
    if (angle !== undefined) {
      if (func) {
        return func(angle);
      } else {
        const normalizedAngle = userNormalizeAngleToRad(angle);
        return (0 <= normalizedAngle && normalizedAngle <= 360);
      }
    } else {
      return false;
    }
  }
  protected checkPoint(point?: IPoint, func?: (point: IPoint) => boolean): boolean {
    if (point !== undefined) {
      if (func) {
        return func(point);
      } else {
        return (point !== undefined && (point.x !== undefined) && (point.y !== undefined) && (point.z !== undefined));
      }
    } else {
      return false;
    }
  }
  protected checkColor(color?: IColor, func?: (point: IColor) => boolean): boolean {
    if (color !== undefined) {
      if (func) {
        return func(color);
      } else {
        return (color !== undefined && (color.r !== undefined) && (color.g !== undefined) && (color.b !== undefined) && (color.a !== undefined));
      }
    } else {
      return false;
    }
  }

  protected changedNumber(oldNumber: number, newNumber?: number): number | null {
    let changed: boolean = false;
    let numberRes: number = oldNumber;
    if (newNumber !== undefined) {
      if (!isEqual(oldNumber, newNumber, epsilonLinealPrecision)) {
        numberRes = newNumber;
        changed = true;
      }
    }
    return (changed ? numberRes : null);
  }
  protected changedEnum(oldEnum: number, newEnum?: number): number | null {
    return this.changedNumber(oldEnum, newEnum)
  }
  protected changedString(oldString: string, newstring?: string): string | null {
    let changed: boolean = false;
    let stringRes: string = oldString;
    if (newstring !== undefined) {
      if (oldString !== newstring) {
        stringRes = newstring;
        changed = true;
      }
    }
    return (changed ? stringRes : null);
  }
  protected changedBoolean(oldBoolean: boolean, newBoolean?: boolean): boolean | null {
    let changed: boolean = false;
    let booleanRes: boolean = oldBoolean;
    if (newBoolean !== undefined && oldBoolean !== newBoolean) {
      booleanRes = newBoolean;
      changed = true;
    }
    return (changed ? booleanRes : null);
  }
  protected changedAngle(oldAngle: number, newAngle?: number): number | null {
    let changed: boolean = false;
    let angleRes: number = oldAngle;
    if (newAngle !== undefined) {
      const userAngle = userNormalizeAngleToRad(newAngle);
      if (!isEqualAngle(oldAngle, userAngle)) {
        angleRes = userAngle;
        changed = true;
      }
    }
    return (changed ? angleRes : null);
  }
  protected changedPoint(oldPoint: IPoint, newPoint?: IPoint): IPoint | null {
    let changed: boolean = false;
    let pointRes: IPoint = copyIPoint(oldPoint);
    if (newPoint !== undefined) {
      if (newPoint.x !== undefined) {
        if (!isEqual(oldPoint.x, newPoint.x, epsilonLinealPrecision)) {
          pointRes.x = newPoint.x;
          changed = true;
        }
      }
      if (newPoint.y !== undefined) {
        if (!isEqual(oldPoint.y, newPoint.y, epsilonLinealPrecision)) {
          pointRes.y = newPoint.y;
          changed = true;
        }
      }
      if (newPoint.z !== undefined) {
        if (!isEqual(oldPoint.z, newPoint.z, epsilonLinealPrecision)) {
          pointRes.z = newPoint.z;
          changed = true;
        }
      }
    }
    return (changed ? pointRes : null);
  }
  protected changedPointRotation(oldRotation: IPoint, newRotation?: IPoint): IPoint | null {
    let changed: boolean = false;
    let rotationRes: IPoint = copyIPoint(oldRotation);
    if (newRotation !== undefined) {
      if (newRotation.x !== undefined) {
        const userAngleX = userNormalizeAngleToRad(newRotation.x);
        if (!isEqualAngle(oldRotation.x, userAngleX)) {
          rotationRes.x = userAngleX;
          changed = true;
        }
      }
      if (newRotation.y !== undefined) {
        const userAngleY = userNormalizeAngleToRad(newRotation.y);
        if (!isEqualAngle(oldRotation.y, userAngleY)) {
          rotationRes.y = userAngleY;
          changed = true;
        }
      }
      if (newRotation.z !== undefined) {
        const userAngleZ = userNormalizeAngleToRad(newRotation.z);
        if (!isEqualAngle(oldRotation.z, userAngleZ)) {
          rotationRes.z = userAngleZ;
          changed = true;
        }
      }
    }
    return (changed ? rotationRes : null);
  }
  protected changedColor(oldColor: IColor, newColor?: IColor): IColor | null {
    let changed: boolean = false;
    const colorRes = copyColor(oldColor);
    if (newColor !== undefined) {
      if (newColor.r !== undefined) {
        if (!isEqual(oldColor.r, newColor.r)) {
          colorRes.r = newColor.r;
          changed = true;
        }
      }
      if (newColor.g !== undefined) {
        if (!isEqual(oldColor.g, newColor.g)) {
          colorRes.g = newColor.g;
          changed = true;
        }
      }
      if (newColor.b !== undefined) {
        if (!isEqual(oldColor.b, newColor.b)) {
          colorRes.b = newColor.b;
          changed = true;
        }
      }
      if (newColor.a !== undefined) {
        if (!isEqual(oldColor.a, newColor.a)) {
          colorRes.a = newColor.a;
          changed = true;
        }
      }
    }
    return (changed ? colorRes : null);
  }

  // MATERIAL CHECKERS AND SETTERS

  protected checkNewPointMaterial(newMaterial: IPointMaterial | null): boolean {
    if (!newMaterial) { return false; }
    if (!this.checkColor(newMaterial.color)) { return false; }
    if (!this.checkNumber(newMaterial.size, () => newMaterial.size > 0)) { return false; }
    return true;
  }
  protected checkNewLineMaterial(newMaterial: ILineMaterial | null): boolean {
    if (!newMaterial) { return false; }
    if (!this.checkColor(newMaterial.color)) { return false; }
    if (newMaterial.width !== undefined && !this.checkNumber(newMaterial.width, () => newMaterial.width > 0)) { return false; }
    if (newMaterial.lineStyleId !== undefined && !this.checkString(newMaterial.lineStyleId)) { return false; }
    return true;
  }
  protected checkNewSolidMaterial(newMaterial: ISolidMaterial | null): boolean {
    if (!newMaterial) { return false; }
    if (!this.checkColor(newMaterial.color)) { return false; }
    // TODO: manage textures
    return true;
  }
  protected changedNewPointMaterial(oldMaterial: IPointMaterial, newMaterial: IPointMaterial): IPointMaterial | null {
    let materialRes = copyMaterial(oldMaterial);
    let changed: boolean = false;
    if (newMaterial) {
      const color = this.changedColor(oldMaterial.color, newMaterial.color);
      if (color !== null) {
        materialRes.color = color;
        changed = true;
      }
      const size = this.changedNumber(oldMaterial.size, newMaterial.size);
      if (size !== null) {
        materialRes.size = size;
        changed = true;
      }
    }
    return changed ? materialRes : null;
  }
  protected changedNewLineMaterial(oldMaterial: ILineMaterial, newMaterial: ILineMaterial): ILineMaterial | null {
    let materialRes = copyMaterial(oldMaterial);
    let changed: boolean = false;
    if (newMaterial) {
      const color = this.changedColor(oldMaterial.color, newMaterial.color);
      if (color !== null) {
        materialRes.color = color;
        changed = true;
      }
      const width = this.changedNumber(oldMaterial.width, newMaterial.width);
      if (width !== null) {
        materialRes.width = width;
        changed = true;
      }
      const lineStyleId = this.changedString(oldMaterial.lineStyleId, newMaterial.lineStyleId);
      if (lineStyleId !== null) {
        materialRes.lineStyleId = lineStyleId;
        changed = true;
      }
    }
    return changed ? materialRes : null;
  }
  protected changedNewSolidMaterial(oldMaterial: ISolidMaterial, newMaterial: ISolidMaterial): ISolidMaterial | null {
    let materialRes = copyMaterial(oldMaterial);
    let changed: boolean = false;
    if (newMaterial) {
      const color = this.changedColor(oldMaterial.color, newMaterial.color);
      if (color !== null) {
        materialRes.color = color;
        changed = true;
      }
      // TODO: manage texture
      // const texture = this.changedNumber(oldMaterial.texture, newMaterial.texture);
      // if (texture !== null) {
      //   materialRes.texture = texture;
      //   changed = true;
      // }
    }
    return changed ? materialRes : null;
  }
  protected checkAndChangedPointMaterial(oldMaterial: IPointMaterial, newMaterial: IPointMaterial): IPointMaterial | null {
    let mat = null;
    if (this.checkNewPointMaterial(newMaterial)) {
      mat = this.changedNewPointMaterial(oldMaterial, newMaterial);
    }
    return mat;
  }
  protected checkAndChangedLineMaterial(oldMaterial: ILineMaterial, newMaterial: ILineMaterial): ILineMaterial | null {
    let mat = null;
    if (this.checkNewLineMaterial(newMaterial)) {
      mat = this.changedNewLineMaterial(oldMaterial, newMaterial);
    }
    return mat;
  }
  protected checkAndChangedSolidMaterial(oldMaterial: ISolidMaterial, newMaterial: ISolidMaterial): ISolidMaterial | null {
    let mat = null;
    if (this.checkNewSolidMaterial(newMaterial)) {
      mat = this.changedNewSolidMaterial(oldMaterial, newMaterial);
    }
    return mat;
  }
}

export class NoneDataDefinitionHandler extends DataDefinitionHandler<null, null, null, null> {
  public data: IObjData;
  protected buildInfoProperties(): void {
    console.log("[PROPERTIES FORM] Object has not properties handler")
  }
}
