import { coneParam } from "./solid/cone";
import { cubeParam } from "./solid/cube";
import { cylinderParam } from "./solid/cylinder";
import { pprismParam } from "./solid/pprism";
import { rprismParam } from "./solid/rprism";

export function volumeCube(cube: cubeParam): number {
    return (3 * cube.side);
}
export function volumeCylinder(cylinder: cylinderParam): number {
    return (Math.PI * cylinder.radius * cylinder.radius * cylinder.height);
}
export function volumeCone(cone: coneParam): number {
    return ((1 / 3) * Math.PI * cone.height * ((cone.lowerRadius * cone.lowerRadius) + (cone.lowerRadius * cone.upperRadius) + (cone.upperRadius * cone.upperRadius)));
}
export function volumePPrism(pprism: pprismParam): number {
    const betha = Math.PI * (1 - 2 / pprism.sides);
    return (pprism.sides * 0.5 * pprism.radius * pprism.radius * Math.sin(betha) * pprism.height);
}
export function volumeRPrism(rprism: rprismParam): number {
    return (rprism.sideX * rprism.sideY * rprism.sideZ);
}