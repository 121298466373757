import { useLayerContext } from "./context";
import { TreeView } from "shared/components/ui/tree/tree";
import { TreeNodeOptions } from "./layer-tree-options";
import { useEffect } from "react";
import { MenuCtx } from "shared/components/ui/context-menu/context-menu";
import { useLayerTreeContextMenu } from "./hooks/use-layer-tree-ctx-menu";

const idMenu = "ctxLayerMenu"
const LayerTreeMenu = ({ showObject }: { showObject: boolean }) => {

  const {
    layerTree,
    expandedKeys,
    scrollOffset,
    currentLayer,
    setCurrentNode,
    setShowObjects,
    dragObject
  } = useLayerContext();

  useEffect(() => {
    setShowObjects(showObject);
  }, [setShowObjects, showObject]);

  const {
    menuCtxItems,
    setMenuContextual,
  } = useLayerTreeContextMenu();

  return (
    <MenuCtx setting={menuCtxItems} idMenu={idMenu}>
      <TreeView
        treeData={layerTree}
        currentNodeId={currentLayer}
        expandedKeys={expandedKeys.current}
        scrollOffset={scrollOffset.current}
        setActiveNodeCb={setCurrentNode}
        setCurrentNodeCb={setMenuContextual}
        dragNode={dragObject}
        startDepth={0}
        nodeOptions={TreeNodeOptions}
      />
    </MenuCtx>
  );
};

export default LayerTreeMenu;
