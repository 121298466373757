import * as THREE from "three";
import { Line2 } from "three/examples/jsm/lines/Line2";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { isBufferGeom, setPosBuffer, updateObjBboxBSphere } from ".";
import { getAuxMaterialLine, ILineMaterial, LineMaterialType, materialCache } from "../materials";
import { lineAngle2p, lineSlope2p } from "../math/angles";
import { vector3Equals } from "../math/epsilon";
import { getBufferFromPolylineParam, IPolylineParam } from "../math/line";
import { addIpoint, IpointsToBuffer, substractIpoint } from "../math/point";
import { IPoint, ISegment } from "../math/types";

export function lineCreate(buffer?: Float32Array, indices?: undefined, color?: number | string, material?: LineMaterialType): THREE.Line;
export function lineCreate(buffer?: Float32Array, indices?: number[], color?: number | string, material?: LineMaterialType): THREE.LineSegments;
export function lineCreate(buffer?: Float32Array, indices?: number[] | undefined, color?: number | string, material?: LineMaterialType): THREE.LineSegments | THREE.Line {

  const geometry: THREE.BufferGeometry = new THREE.BufferGeometry();
  if (!buffer) {
    geometry.setAttribute("position", new THREE.Float32BufferAttribute(new Float32Array([]), 3));
    geometry.setDrawRange(0, 0);
  } else {
    geometry.setAttribute("position", new THREE.Float32BufferAttribute(buffer, 3));
    geometry.setDrawRange(0, buffer.length / 3);
  }

  if (indices?.length) {
    geometry.setIndex(new THREE.BufferAttribute(new Uint16Array(indices), 1));
    geometry.setDrawRange(0, indices.length);
  }

  const mat = material ?? getAuxMaterialLine();
  if (color) mat.color = new THREE.Color(color);

  const line = indices !== undefined ? new THREE.LineSegments(geometry, mat) : new THREE.Line(geometry, mat);
  if (mat.type !== "LineBasicMaterial") line.computeLineDistances();
  updateObjBboxBSphere(line);
  return line;
}

export function lineCreateIPoints(points: IPoint[], indices?: number[], material?: LineMaterialType): THREE.Line | THREE.LineSegments {
  const buffer = IpointsToBuffer(points);
  return lineCreate(buffer, indices, undefined, material);
}
export function lineCreateIPolylineParam(param: IPolylineParam, material?: LineMaterialType): THREE.Line {
  const buffer = getBufferFromPolylineParam(param);
  return lineCreate(buffer, undefined, undefined, material);
}
export function lineAuxCreate() {
  const line = lineCreate(
    undefined,
    undefined,
    undefined,
    getAuxMaterialLine()
  );
  return line;
}

/** Constructor de lineas punteadas con el color dado.
 * Recuerda que en las lineas punteadas por defecto hay una relacion 3:1 entre el punteado y el hueco; ademas
 * scale es la escala inversa multiplicativa del tamaño de ambos, punteado y hueco.
 *
 * @export
 * @param {IPoint[]} points
 * @param {number[]} [indices]
 * @param {number} [color=0xffffff]
 * @param {number} [dashSize=3]
 * @param {number} [gapSize=1]
 * @param {number} [scale=1]
 * @returns {THREE.Line}
 */
export function dashedLineCreateIPoints(
  points: IPoint[],
  indices?: number[],
  color: number = 0xffffff,
  dashSize: number = 3,
  gapSize: number = 1,
  scale: number = 1
): THREE.Line | THREE.LineSegments {

  const buffer = IpointsToBuffer(points);
  const line = lineCreate(buffer, indices);
  // A esa linea le quitaremos el material anteriormente asignado y lo sustituiremos por este.
  const newMaterial = new THREE.LineDashedMaterial({
    color,
    dashSize,
    gapSize,
    scale,
  });
  let oldMaterial = line.material as THREE.Material;
  line.material = newMaterial;
  oldMaterial.dispose();
  oldMaterial = undefined!;
  // Creo que a esta funcion la hay que llamar continuamente para que se vea bien.
  if (newMaterial.type !== "LineBasicMaterial") line.computeLineDistances();
  return line;
}

export function lineBaseCreate(buffer: Float32Array, material: ILineMaterial): THREE.Line | Line2 {
  if (material.width === 1) {
    const geometry: THREE.BufferGeometry = new THREE.BufferGeometry();
    geometry.setAttribute("position", new THREE.Float32BufferAttribute(buffer, 3));
    geometry.setDrawRange(0, buffer.length / 3);
    const threeMaterial = materialCache.getMaterial(material);
    const line = new THREE.Line(geometry, threeMaterial);
    if (threeMaterial.type !== "LineBasicMaterial") line.computeLineDistances();
    updateObjBboxBSphere(line);
    return line;

  } else {
    const geom = new LineGeometry();
    geom.setPositions(buffer);
    const threeMaterial = materialCache.getMaterial(material) as LineMaterial;
    threeMaterial.resolution.set(window.innerWidth, window.innerHeight);
    const line = new Line2(geom, threeMaterial);
    line.computeLineDistances();
    updateObjBboxBSphere(line);
    return line;
  }
}

// *****************************************************************************************

/** Añade un vertice a una línea en un posición determinada
 *
 * @export
 * @param {THREE.Line} line - Línea a modificar
 * @param {number} x - Coordenada x del nuevo punto
 * @param {number} y - Coordenada y del nuevo punto
 * @param {number} z - Coordenada z del nuevo punto
 * @param {number} [index] - admite valores desde -1 a número de vértices. Si no se especifica se añade al final.
 * @memberof MEC.CAD.GEO
 */
export function lineAddVertex(line: THREE.Line | THREE.Points | THREE.Mesh, x: number, y: number, z: number, index?: number): void {
  if (index === undefined) {
    index = (line.geometry as THREE.BufferGeometry).drawRange.count - 1;
  }
  console.assert(index >= -1);
  const numVertex = (line.geometry as THREE.BufferGeometry).drawRange.count;
  if (index >= numVertex) index = numVertex - 1;
  console.assert(index <= numVertex);
  const position = (line.geometry as THREE.BufferGeometry).getAttribute("position");

  // Ajustamos el tamaño del bufferArray
  const buffer = position.array;
  const bufferArrayCopy = new Float32Array(buffer.length + 3);

  // Modificamos los valores del bufferArray
  // Corremos las coordenadas del resto de puntos hasta el final
  let currPos = 0;
  if (index === -1) {
    bufferArrayCopy[currPos++] = x;
    bufferArrayCopy[currPos++] = y;
    bufferArrayCopy[currPos++] = z;
  }
  for (let i: number = 0; i < buffer.length; i += 3) {
    bufferArrayCopy[currPos++] = buffer[i];
    bufferArrayCopy[currPos++] = buffer[i + 1];
    bufferArrayCopy[currPos++] = buffer[i + 2];
    if (i / 3 === index) {
      bufferArrayCopy[currPos++] = x;
      bufferArrayCopy[currPos++] = y;
      bufferArrayCopy[currPos++] = z;
    }
  }
  setPosBuffer(line, bufferArrayCopy);
}
/** Cambia de posición un vertice de una línea
 *
 * @export
 * @param {THREE.Line} line
 * @param {number} x
 * @param {number} y
 * @param {number} z
 * @param {number} [index]
 * @memberof MEC.CAD.GEO
 */
export function lineMoveVertex(line: THREE.Line, x: number, y: number, z: number, index?: number, relIndices?: number[]): void {
  const geometry = line.geometry as THREE.BufferGeometry;
  if (index === undefined) {
    index = geometry.drawRange.count - 1;
  }
  console.assert(index >= 0);
  console.assert(index < geometry.drawRange.count);

  let pos: number = index * 3;
  const position = geometry.getAttribute("position") as THREE.BufferAttribute;
  const array = position.array as Float32Array;
  if (
    relIndices === undefined ||
    relIndices.length === 0 ||
    relIndices.indexOf(index + 1) === -1
  ) {
    array[pos] = x;
    array[pos + 1] = y;
    array[pos + 2] = z;
  } else {
    // Partimos de que la geometría es correcta. Asi que podemos sacar los vertices relativos a partir de lo existente.
    const relDefs: Map<number, IPoint> = new Map<number, IPoint>();
    for (let i: number = 0, l: number = relIndices.length; i < l; i++) {
      const currPos: number = relIndices[i];
      let prevPoint: IPoint | null;
      if (currPos > 0) {
        prevPoint = getVertexFromIndex(line, currPos - 1);
      } else {
        prevPoint = { x: 0, y: 0, z: 0 };
      }
      const point = getVertexFromIndex(line, currPos);
      if (point && prevPoint) {
        relDefs.set(currPos, substractIpoint(point, prevPoint));
      }
    }

    array[pos] = x;
    array[pos + 1] = y;
    array[pos + 2] = z;

    pos = index + 1;
    while (relIndices.indexOf(pos) > -1) {
      const prevPoint: IPoint = {
        x: array[(pos - 1) * 3],
        y: array[(pos - 1) * 3 + 1],
        z: array[(pos - 1) * 3 + 2],
      };
      const currPoint = relDefs.get(pos);
      if (currPoint) {
        const point: IPoint = addIpoint(currPoint, prevPoint);
        array[pos * 3] = point.x;
        array[pos * 3 + 1] = point.y;
        array[pos * 3 + 2] = point.z;
        pos++;
      }
    }
  }

  if (checkCloseGeometry(line) && index === 0) {
    const lastIndex: number = getNumVertices(line) - 1;
    pos = lastIndex * 3;
    array[pos] = x;
    array[pos + 1] = y;
    array[pos + 2] = z;
  }
  updateObjBboxBSphere(line);
}
/** Borra un vertice de una geometria
 *
 * @export
 * @param {THREE.Line} line
 * @param {number} [VrtxIndex]
 */
export function bufferRemoveVertex(line: THREE.Line | THREE.Points | THREE.Mesh, VrtxIndex?: number): void {
  if (isBufferGeom(line.geometry)) {
    const geom = line.geometry;
    const numVertex: number = geom.drawRange.count;
    if (VrtxIndex === undefined) {
      VrtxIndex = numVertex - 1;
    }
    console.assert(VrtxIndex >= 0);
    console.assert(VrtxIndex < numVertex);

    const position = geom.getAttribute("position");
    const bufferArrayLength: number = position.array.length;
    // Con esto usaríamos el constructor del array original
    const bufferArrayCopy = new Float32Array(bufferArrayLength - 3);
    let currentPosition: number = 0;
    for (let i: number = 0; i < numVertex; ++i) {
      if (i !== VrtxIndex) {
        bufferArrayCopy[currentPosition] = geom.getAttribute("position").array[i * 3];
        bufferArrayCopy[currentPosition + 1] = geom.getAttribute("position").array[i * 3 + 1];
        bufferArrayCopy[currentPosition + 2] = geom.getAttribute("position").array[i * 3 + 2];
        currentPosition += 3;
      }
    }
    setPosBuffer(line, bufferArrayCopy);
    updateObjBboxBSphere(line);
  } else {
    throw new Error("Geometría no válida");
  }
}

/** Devuelve el vértice de una línea/polilinea a partir de su vértice
 *
 * @export
 * @param {THREE.Line} line
 * @param {number} [index] - Indice opcional, si no se pasa nada se devuelve el último vertice
 * @returns {MATH.IPoint}
 * @memberof MEC.CAD.GEO
 */
export function getVertexFromIndex(line: THREE.Line | THREE.Points | THREE.Mesh, index?: number): IPoint | null {
  const geom = line.geometry;
  if (!isBufferGeom(geom)) {
    if (index === undefined) index = geom.vertices.length - 1;
    return geom.vertices[index];
  }
  if (index === undefined) {
    if (line instanceof THREE.LineSegments) {
      index = geom.getAttribute("position").count - 1;
    } else if (geom.drawRange.count < Infinity) {
      index = geom.drawRange.count - 1;
    } else {
      index = 0;
    }
  }
  if (index < 0) index = geom.drawRange.count - 1 + index;

  console.assert(index >= 0);
  if (line instanceof THREE.LineSegments) {
    console.assert(index <= geom.getAttribute("position").count - 1);
  } else {
    console.assert(index <= geom.drawRange.count);
  }

  const position: number = /*geom.drawRange.start * 3 +*/ index * 3;
  const buffer = (geom as THREE.BufferGeometry).getAttribute("position")
    .array as Float32Array;
  const x: number = buffer[position];
  const y: number = buffer[position + 1];
  const z: number = buffer[position + 2];
  if (x === undefined || y === undefined || z === undefined) {
    return null;
  }
  const pto = { x, y, z };
  return pto;
}

/** Devuelve array de Ipoints dado un objeto THREE
 *
 * @export
 * @param {THREE.Line} line
 * @returns {IPoint[]}
 */
export function lineExtractAllVertex(line: THREE.Line): IPoint[] {
  if (line.geometry instanceof THREE.BufferGeometry) {
    const position = line.geometry.getAttribute("position")
      .array as Float32Array;
    let numVertices = position.length / 3;
    if (numVertices === 0) return [];
    const isClosed = checkCloseGeometry(line);
    if (isClosed) numVertices -= 1;
    const retPoints: IPoint[] = new Array(numVertices - 1);
    let count = 0;
    for (let i = 0; i < numVertices; i++) {
      retPoints[i] = {
        x: position[count++],
        y: position[count++],
        z: position[count++],
      };
    }
    return retPoints;
  }
  return [];
}
/** Devuelvo array de Isegments dado un objeto THREE
 *
 * @export
 * @param {THREE.Line} line
 * @returns {MATH.ISegment[]}
 */
export function lineExtractAllSegments(line: THREE.Line): ISegment[] {
  const geometry = line.geometry;
  let lines: ISegment[] = [];
  if (isBufferGeom(geometry)) {
    const buffer = (geometry as THREE.BufferGeometry).getAttribute("position")
      .array;
    const startEdge = geometry.drawRange.start;
    const numEdges = geometry.drawRange.count - startEdge - 1;
    lines = new Array(numEdges);
    for (let i: number = 0; i < numEdges; i++) {
      const pos: number = (startEdge + i) * 3;
      const currentLine: ISegment = {
        p1: { x: buffer[pos], y: buffer[pos + 1], z: buffer[pos + 2] },
        p2: { x: buffer[pos + 3], y: buffer[pos + 4], z: buffer[pos + 5] },
      };
      lines[i] = currentLine;
    }
  }
  return lines;
}

/** Devuelve el NÚMERO de vertices de una polilinea
 * (no el índice máximo)
 *
 * @export
 * @param {THREE.Line} line
 * @returns {number}
 */
export function getNumVertices(line: THREE.Line): number {
  const geometry = line.geometry as THREE.BufferGeometry;
  if (isBasedIndex(line)) {
    return geometry.getAttribute("position").count;
  } else {
    return geometry.drawRange.count - geometry.drawRange.start;
  }
}
/** Comprueba que el primer punto y el último de una línea coinciden
 *
 * @export
 * @param {THREE.Line} line
 * @returns
 */
export function checkCloseGeometry(line: THREE.Line): boolean {
  if (isBufferGeom(line.geometry)) {
    if (getNumVertices(line) > 2) {
      const firstVertex = getVertexFromIndex(line, 0);
      const lastVertex = getVertexFromIndex(line);
      if (firstVertex && lastVertex) {
        return vector3Equals(firstVertex, lastVertex);
      }
    } else {
      return false;
    }
  }
  return false;
}

/** Comprueba si la geometría esta basada en vértices
 *
 * @export
 * @param {THREE.Line} threeObj
 * @returns {boolean}
 */
export function isBasedIndex(threeObj: THREE.Line): boolean {
  const index = (threeObj.geometry as THREE.BufferGeometry).getIndex();
  if (index && index.count > 0) {
    return true;
  } else {
    return false;
  }
}

export function getRotationLine(vertex0: IPoint, vertex1: IPoint) {
  const angle = lineAngle2p(vertex0, vertex1);
  const slope = lineSlope2p(vertex0, vertex1);
  
  const angles = new THREE.Euler(slope, 0, angle - Math.PI * 0.5, "ZXY");
  angles.reorder("XYZ");
  return angles;
}
