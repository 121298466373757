import { GraphicProcessor } from "lib/graphic-processor";
import { isTextData } from "lib/models/checktools";
import { StyleCacheHandler } from "lib/styles/style-cache";
import { TextOptsBuilder, textMultiPosTypeH, textMultiPosTypeV, sdfDoubleSidedType } from "./styles";

export let textStyleCache: TextStyleCacheHandler;

export let currentTextStyleId: string;

export class TextStyleCacheHandler extends StyleCacheHandler<TextOptsBuilder> {

  static initialize() {
    textStyleCache = new TextStyleCacheHandler();
    textStyleCache.loadDefaultItems();
  }
  static clear() {
    textStyleCache.clear();
    textStyleCache = undefined!;
  }

  private loadDefaultItems() {
    const defaultStyle = new TextOptsBuilder();
    defaultStyle.default = true;
    defaultStyle.styleId = "TextDef00";
    defaultStyle.name = "Default";
    defaultStyle.size = 0.4;
    this.saveStyle(defaultStyle);
    currentTextStyleId = defaultStyle.styleId;

    const styleLeftBaselineNone = new TextOptsBuilder();
    styleLeftBaselineNone.default = true;
    styleLeftBaselineNone.styleId = "TextDef01";
    styleLeftBaselineNone.name = "LeftBaseline";
    styleLeftBaselineNone.basePointH = textMultiPosTypeH.LEFT;
    styleLeftBaselineNone.basePointV = textMultiPosTypeV.BASELINE;
    styleLeftBaselineNone.doubleSided = sdfDoubleSidedType.NONE;
    styleLeftBaselineNone.size = 1;
    this.saveStyle(styleLeftBaselineNone);

    const styleMiddleMedianFront = new TextOptsBuilder();
    styleMiddleMedianFront.default = true;
    styleMiddleMedianFront.styleId = "TextDef02";
    styleMiddleMedianFront.name = "MiddleMedian";
    styleMiddleMedianFront.basePointH = textMultiPosTypeH.MIDDLE;
    styleMiddleMedianFront.basePointV = textMultiPosTypeV.MEDIAN;
    styleMiddleMedianFront.doubleSided = sdfDoubleSidedType.FRONT;
    styleMiddleMedianFront.size = 1;
    this.saveStyle(styleMiddleMedianFront);

    const styleRightAscenderHor = new TextOptsBuilder();
    styleRightAscenderHor.default = true;
    styleRightAscenderHor.styleId = "TextDef03";
    styleRightAscenderHor.name = "RightAscender";
    styleRightAscenderHor.basePointH = textMultiPosTypeH.RIGHT;
    styleRightAscenderHor.basePointV = textMultiPosTypeV.ASCENDER;
    styleRightAscenderHor.doubleSided = sdfDoubleSidedType.HOR;
    styleRightAscenderHor.size = 1;
    this.saveStyle(styleRightAscenderHor);
  }

  load(itemsSaved: Record<string, TextOptsBuilder>) {
    for (const id in itemsSaved) {
      if (Object.prototype.hasOwnProperty.call(itemsSaved, id)) {
        const text = new TextOptsBuilder(itemsSaved[id]);
        text.styleId = id;
        this.saveStyle(text);
      }
    }
  }

  isStyleInUse(id: string, graphicProc: GraphicProcessor) {
    const dataModel = graphicProc.getDataModelManager();
    for (const data of dataModel.iterDataByFilter(isTextData)) {
      if (data.definition.styleId === id)
        return true;
    }
    return false;
  }

}

export function getDefaultTextStyle() {
  return textStyleCache.loadStylefromCache(currentTextStyleId)!;
}
