import { currentLineStyleId } from "lib/line-styles/cache";
import { Cad3dOp } from "../base";

export abstract class WireframeOP extends Cad3dOp {

  protected lineStyleId: string;

  constructor(styleId?: string) {
    super();
    this.lineStyleId = styleId ?? currentLineStyleId;
  }

  public async start() {
    this.iniSettingsOp();
    this.initializeSnap();
    this.initializeWorkingPlane();
    this.registerCancel();
    this.registerInputs();
    this.registerUpdaters();
  }
}
