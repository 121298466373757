import * as THREE from "three";
import { getDimAuxGeometry, getDimGeometry, getDimLabel } from "lib/dimension/line-dim-builder";
import { lineExtractAllVertex } from "lib/geometries/line";
import { IpointsToBuffer } from "lib/math/point";
import { cadOpType } from "lib/operations/factory";
import { ArcDimensionData, arcDimType } from "lib/models/dimension/arc-dim";
import { getAbsolutePoint } from "lib/coordinates/plane";
import { SelectionManager } from "lib/selection/selection-manager";
import { ObjDataSelector } from "../selector-data";

export class ArcDimensionDataSelector extends ObjDataSelector<ArcDimensionData> {

  public override buildSelectMarks() {
    /*   POSICION DE LOS PUNTOS QUE DEFINEN LA GEOMETRÍA DE LA ACOTACIÓN
            2          4
            |         /
           0|________/1
            |       /
            |      /
            3     5
  
            7___8
  
            .6
    */
    const threeObj = this.data.graphicObj;
    const pos = threeObj.position;
    const rot = threeObj.rotation;
    const dimGeom = getDimGeometry(threeObj);
    const v = lineExtractAllVertex(dimGeom);

    const buffer0 = IpointsToBuffer([v[2], v[3]].map(p => getAbsolutePoint(p, pos, rot)));
    const buffer1 = IpointsToBuffer([v[4], v[5]].map(p => getAbsolutePoint(p, pos, rot)));
    const auxGeom = getDimAuxGeometry(threeObj);
    const bufferArc = auxGeom.geometry as THREE.BufferGeometry;
    const buffAttr = bufferArc.getAttribute("position").clone();
    const bufferArrayArc = buffAttr.applyMatrix4(threeObj.matrixWorld).array as Float32Array;

    const textPos = getDimLabel(threeObj);
    return {
      vertex: [
        getAbsolutePoint(textPos.position, pos, rot),
        getAbsolutePoint(v[0], pos, rot),
        getAbsolutePoint(v[1], pos, rot),
        getAbsolutePoint(v[7], pos, rot),
        getAbsolutePoint(v[8], pos, rot),
        pos
      ],
      line: [buffer0, buffer1, bufferArrayArc],
    };
  }

  public override launchMarkOP(selectionManager: SelectionManager, mark: THREE.Object3D, vertexIndex: number) {
    if (selectionManager.vertexSelector.pointObj === mark) {
      if (vertexIndex < 3) {
        const type = this.data.definition.type === arcDimType.ANG ? cadOpType.EDITARCANGLEDIM : cadOpType.EDITARCLONGDIM;
        return { type, graphicProcessor: this.graphicProc, args: [this.data, vertexIndex] };
      }
    }
  }
}
