/* tslint:disable */
/* eslint-disable */
/**
 * Mesh Projects API
 * API for managing mesh generation.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: pablo.campillo@clerhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface MeshProject
 */
export interface MeshProject {
    /**
     * 
     * @type {string}
     * @memberof MeshProject
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof MeshProject
     */
    'Id': string;
    /**
     * 
     * @type {string}
     * @memberof MeshProject
     */
    'CreatedAt'?: string;
    /**
     * 
     * @type {MeshProjectStatus}
     * @memberof MeshProject
     */
    'MPStatus'?: MeshProjectStatus;
    /**
     * 
     * @type {string}
     * @memberof MeshProject
     */
    'InputS3Key'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeshProject
     */
    'UploadUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeshProject
     */
    'DownloadUrl'?: string;
    /**
     * 
     * @type {Array<MeshProjectAllOfEvents>}
     * @memberof MeshProject
     */
    'Events'?: Array<MeshProjectAllOfEvents>;
}
/**
 * 
 * @export
 * @interface MeshProjectAllOf
 */
export interface MeshProjectAllOf {
    /**
     * 
     * @type {string}
     * @memberof MeshProjectAllOf
     */
    'Id': string;
    /**
     * 
     * @type {string}
     * @memberof MeshProjectAllOf
     */
    'CreatedAt'?: string;
    /**
     * 
     * @type {MeshProjectStatus}
     * @memberof MeshProjectAllOf
     */
    'MPStatus'?: MeshProjectStatus;
    /**
     * 
     * @type {string}
     * @memberof MeshProjectAllOf
     */
    'InputS3Key'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeshProjectAllOf
     */
    'UploadUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeshProjectAllOf
     */
    'DownloadUrl'?: string;
    /**
     * 
     * @type {Array<MeshProjectAllOfEvents>}
     * @memberof MeshProjectAllOf
     */
    'Events'?: Array<MeshProjectAllOfEvents>;
}
/**
 * 
 * @export
 * @interface MeshProjectAllOfEvents
 */
export interface MeshProjectAllOfEvents {
    /**
     * 
     * @type {string}
     * @memberof MeshProjectAllOfEvents
     */
    'Name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeshProjectAllOfEvents
     */
    'CreatedAt'?: string;
}
/**
 * 
 * @export
 * @interface MeshProjectParams
 */
export interface MeshProjectParams {
    /**
     * 
     * @type {string}
     * @memberof MeshProjectParams
     */
    'Name': string;
}
/**
 * 
 * @export
 * @interface MeshProjectStatus
 */
export interface MeshProjectStatus {
    /**
     * 
     * @type {string}
     * @memberof MeshProjectStatus
     */
    'MPValue': MeshProjectStatusMPValueEnum;
    /**
     * 
     * @type {string}
     * @memberof MeshProjectStatus
     */
    'ChangedAt'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum MeshProjectStatusMPValueEnum {
    Running = 'RUNNING',
    Error = 'ERROR',
    Done = 'DONE',
    Canceled = 'CANCELED'
}


/**
 * MeshProjectsApi - axios parameter creator
 * @export
 */
export const MeshProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes a Mesh Project and its files.
         * @summary Removes a Mesh Project.
         * @param {string} id Id of the Mesh Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meshProjectsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('meshProjectsIdDelete', 'id', id)
            const localVarPath = `/mesh_projects/{Id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of urls with credentials to download mesh output files.
         * @summary Returns a list of urls with credentials.
         * @param {string} id Id of the Mesh Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meshProjectsIdDownloadAllGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('meshProjectsIdDownloadAllGet', 'id', id)
            const localVarPath = `/mesh_projects/{Id}/download_all`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns an url with credentials to download a new zip file with mesh model.
         * @summary Returns an url with credentials.
         * @param {string} id Id of the Mesh Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meshProjectsIdDownloadGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('meshProjectsIdDownloadGet', 'id', id)
            const localVarPath = `/mesh_projects/{Id}/download`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all information about a Mesh Project.
         * @summary Returns a Mesh Project.
         * @param {string} id Id of the Mesh Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meshProjectsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('meshProjectsIdGet', 'id', id)
            const localVarPath = `/mesh_projects/{Id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns an url with credentials to upload a new file of the model.
         * @summary Returns an url with credentials.
         * @param {string} id Id of the Mesh Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meshProjectsIdUploadGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('meshProjectsIdUploadGet', 'id', id)
            const localVarPath = `/mesh_projects/{Id}/upload`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a mesh project with an upload url.
         * @summary Returns a mesh project with an upload url.
         * @param {MeshProjectParams} meshProjectParams Parameters to create the Mesh Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meshProjectsPost: async (meshProjectParams: MeshProjectParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'meshProjectParams' is not null or undefined
            assertParamExists('meshProjectsPost', 'meshProjectParams', meshProjectParams)
            const localVarPath = `/mesh_projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(meshProjectParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeshProjectsApi - functional programming interface
 * @export
 */
export const MeshProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MeshProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes a Mesh Project and its files.
         * @summary Removes a Mesh Project.
         * @param {string} id Id of the Mesh Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meshProjectsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeshProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meshProjectsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of urls with credentials to download mesh output files.
         * @summary Returns a list of urls with credentials.
         * @param {string} id Id of the Mesh Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meshProjectsIdDownloadAllGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meshProjectsIdDownloadAllGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns an url with credentials to download a new zip file with mesh model.
         * @summary Returns an url with credentials.
         * @param {string} id Id of the Mesh Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meshProjectsIdDownloadGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeshProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meshProjectsIdDownloadGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all information about a Mesh Project.
         * @summary Returns a Mesh Project.
         * @param {string} id Id of the Mesh Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meshProjectsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeshProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meshProjectsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns an url with credentials to upload a new file of the model.
         * @summary Returns an url with credentials.
         * @param {string} id Id of the Mesh Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meshProjectsIdUploadGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeshProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meshProjectsIdUploadGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a mesh project with an upload url.
         * @summary Returns a mesh project with an upload url.
         * @param {MeshProjectParams} meshProjectParams Parameters to create the Mesh Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meshProjectsPost(meshProjectParams: MeshProjectParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeshProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meshProjectsPost(meshProjectParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MeshProjectsApi - factory interface
 * @export
 */
export const MeshProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MeshProjectsApiFp(configuration)
    return {
        /**
         * Removes a Mesh Project and its files.
         * @summary Removes a Mesh Project.
         * @param {string} id Id of the Mesh Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meshProjectsIdDelete(id: string, options?: any): AxiosPromise<MeshProject> {
            return localVarFp.meshProjectsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of urls with credentials to download mesh output files.
         * @summary Returns a list of urls with credentials.
         * @param {string} id Id of the Mesh Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meshProjectsIdDownloadAllGet(id: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.meshProjectsIdDownloadAllGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns an url with credentials to download a new zip file with mesh model.
         * @summary Returns an url with credentials.
         * @param {string} id Id of the Mesh Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meshProjectsIdDownloadGet(id: string, options?: any): AxiosPromise<MeshProject> {
            return localVarFp.meshProjectsIdDownloadGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all information about a Mesh Project.
         * @summary Returns a Mesh Project.
         * @param {string} id Id of the Mesh Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meshProjectsIdGet(id: string, options?: any): AxiosPromise<MeshProject> {
            return localVarFp.meshProjectsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns an url with credentials to upload a new file of the model.
         * @summary Returns an url with credentials.
         * @param {string} id Id of the Mesh Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meshProjectsIdUploadGet(id: string, options?: any): AxiosPromise<MeshProject> {
            return localVarFp.meshProjectsIdUploadGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a mesh project with an upload url.
         * @summary Returns a mesh project with an upload url.
         * @param {MeshProjectParams} meshProjectParams Parameters to create the Mesh Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meshProjectsPost(meshProjectParams: MeshProjectParams, options?: any): AxiosPromise<MeshProject> {
            return localVarFp.meshProjectsPost(meshProjectParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeshProjectsApi - object-oriented interface
 * @export
 * @class MeshProjectsApi
 * @extends {BaseAPI}
 */
export class MeshProjectsApi extends BaseAPI {
    /**
     * Removes a Mesh Project and its files.
     * @summary Removes a Mesh Project.
     * @param {string} id Id of the Mesh Project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeshProjectsApi
     */
    public meshProjectsIdDelete(id: string, options?: AxiosRequestConfig) {
        return MeshProjectsApiFp(this.configuration).meshProjectsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of urls with credentials to download mesh output files.
     * @summary Returns a list of urls with credentials.
     * @param {string} id Id of the Mesh Project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeshProjectsApi
     */
    public meshProjectsIdDownloadAllGet(id: string, options?: AxiosRequestConfig) {
        return MeshProjectsApiFp(this.configuration).meshProjectsIdDownloadAllGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns an url with credentials to download a new zip file with mesh model.
     * @summary Returns an url with credentials.
     * @param {string} id Id of the Mesh Project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeshProjectsApi
     */
    public meshProjectsIdDownloadGet(id: string, options?: AxiosRequestConfig) {
        return MeshProjectsApiFp(this.configuration).meshProjectsIdDownloadGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all information about a Mesh Project.
     * @summary Returns a Mesh Project.
     * @param {string} id Id of the Mesh Project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeshProjectsApi
     */
    public meshProjectsIdGet(id: string, options?: AxiosRequestConfig) {
        return MeshProjectsApiFp(this.configuration).meshProjectsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns an url with credentials to upload a new file of the model.
     * @summary Returns an url with credentials.
     * @param {string} id Id of the Mesh Project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeshProjectsApi
     */
    public meshProjectsIdUploadGet(id: string, options?: AxiosRequestConfig) {
        return MeshProjectsApiFp(this.configuration).meshProjectsIdUploadGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a mesh project with an upload url.
     * @summary Returns a mesh project with an upload url.
     * @param {MeshProjectParams} meshProjectParams Parameters to create the Mesh Project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeshProjectsApi
     */
    public meshProjectsPost(meshProjectParams: MeshProjectParams, options?: AxiosRequestConfig) {
        return MeshProjectsApiFp(this.configuration).meshProjectsPost(meshProjectParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MeshProjectsStatusApi - axios parameter creator
 * @export
 */
export const MeshProjectsStatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Stops a mesh project job.
         * @summary Cancel a mesh project job.
         * @param {string} id Id of the Mesh Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meshProjectsIdCancelPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('meshProjectsIdCancelPost', 'id', id)
            const localVarPath = `/mesh_projects/{Id}/cancel`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets status mesh project (RUNNING | ERROR | DONE).
         * @summary Gets status mesh project.
         * @param {string} id Id of the Mesh Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meshProjectsIdStatusGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('meshProjectsIdStatusGet', 'id', id)
            const localVarPath = `/mesh_projects/{Id}/status`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates status mesh project (RUNNING | ERROR | DONE).
         * @summary Updates status mesh project.
         * @param {string} id Id of the Mesh Project
         * @param {MeshProjectStatus} meshProjectStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meshProjectsIdStatusPut: async (id: string, meshProjectStatus: MeshProjectStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('meshProjectsIdStatusPut', 'id', id)
            // verify required parameter 'meshProjectStatus' is not null or undefined
            assertParamExists('meshProjectsIdStatusPut', 'meshProjectStatus', meshProjectStatus)
            const localVarPath = `/mesh_projects/{Id}/status`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(meshProjectStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeshProjectsStatusApi - functional programming interface
 * @export
 */
export const MeshProjectsStatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MeshProjectsStatusApiAxiosParamCreator(configuration)
    return {
        /**
         * Stops a mesh project job.
         * @summary Cancel a mesh project job.
         * @param {string} id Id of the Mesh Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meshProjectsIdCancelPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeshProjectStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meshProjectsIdCancelPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets status mesh project (RUNNING | ERROR | DONE).
         * @summary Gets status mesh project.
         * @param {string} id Id of the Mesh Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meshProjectsIdStatusGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeshProjectStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meshProjectsIdStatusGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates status mesh project (RUNNING | ERROR | DONE).
         * @summary Updates status mesh project.
         * @param {string} id Id of the Mesh Project
         * @param {MeshProjectStatus} meshProjectStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meshProjectsIdStatusPut(id: string, meshProjectStatus: MeshProjectStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeshProjectStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meshProjectsIdStatusPut(id, meshProjectStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MeshProjectsStatusApi - factory interface
 * @export
 */
export const MeshProjectsStatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MeshProjectsStatusApiFp(configuration)
    return {
        /**
         * Stops a mesh project job.
         * @summary Cancel a mesh project job.
         * @param {string} id Id of the Mesh Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meshProjectsIdCancelPost(id: string, options?: any): AxiosPromise<MeshProjectStatus> {
            return localVarFp.meshProjectsIdCancelPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets status mesh project (RUNNING | ERROR | DONE).
         * @summary Gets status mesh project.
         * @param {string} id Id of the Mesh Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meshProjectsIdStatusGet(id: string, options?: any): AxiosPromise<MeshProjectStatus> {
            return localVarFp.meshProjectsIdStatusGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates status mesh project (RUNNING | ERROR | DONE).
         * @summary Updates status mesh project.
         * @param {string} id Id of the Mesh Project
         * @param {MeshProjectStatus} meshProjectStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meshProjectsIdStatusPut(id: string, meshProjectStatus: MeshProjectStatus, options?: any): AxiosPromise<MeshProjectStatus> {
            return localVarFp.meshProjectsIdStatusPut(id, meshProjectStatus, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeshProjectsStatusApi - object-oriented interface
 * @export
 * @class MeshProjectsStatusApi
 * @extends {BaseAPI}
 */
export class MeshProjectsStatusApi extends BaseAPI {
    /**
     * Stops a mesh project job.
     * @summary Cancel a mesh project job.
     * @param {string} id Id of the Mesh Project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeshProjectsStatusApi
     */
    public meshProjectsIdCancelPost(id: string, options?: AxiosRequestConfig) {
        return MeshProjectsStatusApiFp(this.configuration).meshProjectsIdCancelPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets status mesh project (RUNNING | ERROR | DONE).
     * @summary Gets status mesh project.
     * @param {string} id Id of the Mesh Project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeshProjectsStatusApi
     */
    public meshProjectsIdStatusGet(id: string, options?: AxiosRequestConfig) {
        return MeshProjectsStatusApiFp(this.configuration).meshProjectsIdStatusGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates status mesh project (RUNNING | ERROR | DONE).
     * @summary Updates status mesh project.
     * @param {string} id Id of the Mesh Project
     * @param {MeshProjectStatus} meshProjectStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeshProjectsStatusApi
     */
    public meshProjectsIdStatusPut(id: string, meshProjectStatus: MeshProjectStatus, options?: AxiosRequestConfig) {
        return MeshProjectsStatusApiFp(this.configuration).meshProjectsIdStatusPut(id, meshProjectStatus, options).then((request) => request(this.axios, this.basePath));
    }
}


