
import * as THREE from "three";
import { radAngleToUser, getUserAngleUnitSufix } from "lib/general-settings";
import { getVertexFromIndex } from "lib/geometries/line";
import { mecDirectionMathDirection, getAzimutBisection, normalizeAngle, lineAngle2p, ORIENT, isBetweenDirection } from "lib/math/angles";
import { arcParam } from "lib/math/arc";
import { vector3Equals } from "lib/math/epsilon";
import { getPolarPoint, IpointsToBuffer } from "lib/math/point";
import { IPoint } from "lib/math/types";
import { arcDimensionParam, arcDimType } from "lib/models/dimension/arc-dim";
import { createText, modifyText } from "lib/text/builder";
import { TextOptsBuilder, textMultiPosTypeH, textMultiPosTypeV, sdfDoubleSidedType } from "lib/text/styles";
import { DimStyleBuilder } from "./style";
import { getDimArrows, getDimAuxGeometry, getDimAuxLineMaterial, getDimGeometry, getDimLabel } from "./line-dim-builder";
import { adjustDrawRange, updateObjBboxBSphere } from "lib/geometries";
import { updateArrowSymbol, getArrowSymbol } from "./arrow";
import { arcBufferCR2p } from "lib/geometries/arc";

export function createArcDimension(dimType: arcDimType, arcParam: arcParam, ArcDimIsInArc: boolean, dimStyle: DimStyleBuilder, txtStyle: TextOptsBuilder): THREE.Group {
  const dimGeometry = getArcDimGeometry(arcParam, dimStyle);
  const textLabel = getArcDimLabelText(dimType, arcParam, ArcDimIsInArc, dimStyle, txtStyle);
  const auxGeometry = getArcAuxGeometry(arcParam, ArcDimIsInArc, dimStyle);
  const direction = arcParam.angleCenter < 0 ? ORIENT.CCW : ORIENT.CW;
  const arrows = getArcDimArrows(auxGeometry as THREE.Line, dimGeometry, direction, dimStyle);

  const dim = new THREE.Group();
  dim.add(dimGeometry);
  dim.add(textLabel);
  dim.add(arrows[0]);
  dim.add(arrows[1]);
  dim.add(auxGeometry);
  dim.rotation.set(arcParam.plane.x, arcParam.plane.y, arcParam.plane.z);
  dim.position.set(arcParam.center.x, arcParam.center.y, arcParam.center.z);
  return dim;
}
export function updateArcDimension(dim: THREE.Group, dimInfo: arcDimensionParam, dimStyle: DimStyleBuilder, txtStyle: TextOptsBuilder): void {
  const arcParam = dimInfo.arcBase;
  dimStyle.override(dimInfo.customStyleProp);
  if (dimStyle.blockDistBaseLine || Math.abs(dimStyle.minDistBaseLine1) > Math.abs(dimStyle.distBaseLine)) {
    dimStyle.distBaseLine = dimStyle.minDistBaseLine1;
  }

  const geometry = getDimGeometry(dim);
  let newGeometry = getArcDimBuffer(arcParam, dimStyle);
  geometry.geometry = newGeometry;
  updateObjBboxBSphere(geometry);

  const auxGeometry = getDimAuxGeometry(dim);
  newGeometry = getArcAuxGeometryBuffer(arcParam, dimInfo.InnerArc, dimStyle);
  auxGeometry.geometry = newGeometry;
  adjustDrawRange(auxGeometry);
  updateObjBboxBSphere(auxGeometry);

  let arrows = getDimArrows(dim);
  updateArrowSymbol(dim, arrows[0], dimStyle.arrowId);
  updateArrowSymbol(dim, arrows[1], dimStyle.arrowId);
  arrows = getDimArrows(dim);
  const direction = arcParam.angleCenter < 0 ? ORIENT.CCW : ORIENT.CW;
  updateArcDimArrows(arrows[0], arrows[1], auxGeometry, geometry, direction);

  const textLabel = getDimLabel(dim);

  updateArcDimLabelText(dimInfo.type, textLabel, arcParam, dimInfo.InnerArc, dimStyle, txtStyle);

  dim.rotation.set(arcParam.plane.x, arcParam.plane.y, arcParam.plane.z);
  dim.position.set(arcParam.center.x, arcParam.center.y, arcParam.center.z);
}

function getArcDimGeometry(arcInfo: arcParam, dimStyle: DimStyleBuilder): THREE.LineSegments {
  const geometry: THREE.BufferGeometry = getArcDimBuffer(arcInfo, dimStyle);
  const resultsLines = new THREE.LineSegments(geometry, getDimAuxLineMaterial());
  return resultsLines;
}
function getArcDimBuffer(arcInfo: arcParam, dimStyle: DimStyleBuilder): THREE.BufferGeometry {
  const center = { x: 0, y: 0, z: 0 };
  const newRadius = arcInfo.radius + dimStyle.distBaseLine;
  let angleStart = mecDirectionMathDirection(arcInfo.azimutO);
  let angleEnd = normalizeAngle(angleStart - arcInfo.angleCenter);

  const position: IPoint[] = new Array(9);
  position[0] = getPolarPoint(center, angleStart, newRadius);
  position[1] = getPolarPoint(center, angleEnd, newRadius);
  position[6] = center;
  // Puntos intersección linea base - lineas laterales
  position[7] = getPolarPoint(center, angleStart, arcInfo.radius);
  position[8] = getPolarPoint(center, angleEnd, arcInfo.radius);

  angleStart = lineAngle2p(position[7], position[0]);
  position[2] = getPolarPoint(position[0], angleStart, dimStyle.ext);
  position[3] = getPolarPoint(position[7], angleStart, dimStyle.offset);
  angleEnd = lineAngle2p(position[8], position[1]);
  position[4] = getPolarPoint(position[1], angleEnd, dimStyle.ext);
  position[5] = getPolarPoint(position[8], angleEnd, dimStyle.offset);

  const coords: Float32Array = IpointsToBuffer(position);
  const geometry: THREE.BufferGeometry = new THREE.BufferGeometry();
  geometry.setAttribute("position", new THREE.BufferAttribute(coords, 3));
  geometry.setDrawRange(2, 4);
  return geometry;
}
function getArcAuxGeometry(arcInfo: arcParam, arcDimIsInArc: boolean, dimStyle: DimStyleBuilder): THREE.Line {
  const geometry: THREE.BufferGeometry = getArcAuxGeometryBuffer(arcInfo, arcDimIsInArc, dimStyle);
  const resultsLines = new THREE.Line(geometry, getDimAuxLineMaterial());
  adjustDrawRange(resultsLines);
  updateObjBboxBSphere(resultsLines);
  return resultsLines;
}
function getArcAuxGeometryBuffer(arcInfo: arcParam, arcDimIsInArc: boolean, dimStyle: DimStyleBuilder): THREE.BufferGeometry {
  const center = { x: 0, y: 0, z: 0 };
  const angleStart = mecDirectionMathDirection(arcInfo.azimutO);
  const angleEnd = normalizeAngle(angleStart - arcInfo.angleCenter);

  const newRadius = arcInfo.radius + dimStyle.distBaseLine;
  // Puntos intersección linea base - lineas laterales
  const P0 = getPolarPoint(center, angleStart, arcInfo.radius + dimStyle.distBaseLine);
  const P1 = getPolarPoint(center, angleEnd, arcInfo.radius + dimStyle.distBaseLine);

  let angle = mecDirectionMathDirection(getAzimutBisection(arcInfo.azimutO, arcInfo.angleCenter));
  if (!arcDimIsInArc) angle = angle + Math.PI;

  let newArcCoords: Float32Array;
  const direction = arcInfo.angleCenter < 0 ? ORIENT.CCW : ORIENT.CW;
  if (isBetweenDirection(angle, angleStart, angleEnd, direction)) {
    newArcCoords = arcBufferCR2p(center, newRadius, P0, P1, direction);
  } else {
    newArcCoords = arcBufferCR2p(center, newRadius, P1, P0, direction);
  }
  const newArcBuffer: THREE.BufferGeometry = new THREE.BufferGeometry();
  newArcBuffer.setAttribute("position", new THREE.BufferAttribute(newArcCoords, 3));
  return newArcBuffer;
}

// LABELS

function getArcDimLabelText(dimType: arcDimType, arcInfo: arcParam, arcDimIsInArc: boolean, dimStyle: DimStyleBuilder, txtOpts: TextOptsBuilder): THREE.Mesh {
  txtOpts.basePointH = dimStyle.basePointH; // textMultiPosTypeH.MIDDLE;
  txtOpts.basePointV = dimStyle.basePointV; // textMultiPosTypeV.MEDIAN;
  txtOpts.doubleSided = sdfDoubleSidedType.HOR;
  const label = createText({ text: "text" }, txtOpts);
  updateArcDimLabelText(dimType, label, arcInfo, arcDimIsInArc, dimStyle, txtOpts);
  return label;
}
function updateArcDimLabelText(dimType: arcDimType, label: THREE.Mesh, arcInfo: arcParam, arcDimIsInArc: boolean, dimStyle: DimStyleBuilder, opts: TextOptsBuilder): void {
  let arcAngle = Math.abs(arcInfo.angleCenter);
  if (!arcDimIsInArc) {
    arcAngle = Math.PI * 2 - arcAngle;
    if (arcAngle < 0) arcAngle = arcAngle + 2 * Math.PI;
  }

  let text: string = "";
  if (dimType === arcDimType.ANG) {
    text = "L" + (arcAngle * arcInfo.radius).toFixed(3);
  } else {
    text = radAngleToUser(arcAngle).toFixed(4) + getUserAngleUnitSufix();
  }
  opts.basePointH = textMultiPosTypeH.MIDDLE;
  opts.basePointV = textMultiPosTypeV.MEDIAN;
  opts.doubleSided = sdfDoubleSidedType.HOR;
  modifyText(label, text, opts);

  let angleDir = mecDirectionMathDirection(getAzimutBisection(arcInfo.azimutO, arcInfo.angleCenter) + dimStyle.fontOffsetX);
  if (!arcDimIsInArc) angleDir = normalizeAngle(angleDir + Math.PI);

  const min = (label.geometry.boundingBox as THREE.Box3).min;
  const max = (label.geometry.boundingBox as THREE.Box3).max;
  const offsetY = (max.y - min.y) * 0.5;

  const position = getPolarPoint({ x: 0, y: 0, z: 0 }, angleDir, arcInfo.radius + dimStyle.distBaseLine + dimStyle.fontOffsetY + offsetY);
  label.position.set(position.x, position.y, position.z);

  label.rotation.set(0, 0, 0);
  if (angleDir < Math.PI) {
    label.rotateZ(angleDir - Math.PI * 0.5);
  } else {
    label.rotateZ(angleDir + Math.PI * 0.5);
  }
}

// ARROWS

export function getArcDimArrows<T extends THREE.Mesh | THREE.Line>(auxArc: THREE.Object3D, dimGeom: THREE.LineSegments, direction: ORIENT, dimStyle: DimStyleBuilder): T[] {
  const arrow1 = getArrowSymbol(dimStyle.arrowId) as T;
  const arrow2 = getArrowSymbol(dimStyle.arrowId) as T;
  updateArcDimArrows(arrow1, arrow2, auxArc, dimGeom, direction);
  return [arrow1, arrow2];
}
export function updateArcDimArrows(arrow1: THREE.Object3D, arrow2: THREE.Object3D, auxArc: THREE.Object3D, dimGeom: THREE.LineSegments, direction: ORIENT): void {
  const center = getVertexFromIndex(dimGeom, 6) as IPoint;
  let pos = getVertexFromIndex(dimGeom as THREE.Line, 0) as IPoint;

  arrow1.position.set(pos.x, pos.y, pos.z);
  let angle = lineAngle2p(center, pos);
  arrow1.rotation.set(0, 0, 0);
  const auxArcV1 = getVertexFromIndex(auxArc as THREE.Line, 0) as IPoint;
  if (vector3Equals(pos, auxArcV1)) {
    if (direction === ORIENT.CW) arrow1.rotateZ(angle - Math.PI * 0.5);
    else arrow1.rotateZ(angle + Math.PI * 0.5);
  } else {
    if (direction === ORIENT.CW) arrow1.rotateZ(angle + Math.PI * 0.5);
    else arrow1.rotateZ(angle - Math.PI * 0.5);
  }

  pos = getVertexFromIndex(dimGeom as THREE.Line, 1) as IPoint;
  arrow2.position.set(pos.x, pos.y, pos.z);
  angle = lineAngle2p(center, pos);
  arrow2.rotation.set(0, 0, 0);
  const auxArcV3 = getVertexFromIndex(auxArc as THREE.Line) as IPoint;
  if (vector3Equals(pos, auxArcV3)) {
    if (direction === ORIENT.CW) arrow2.rotateZ(angle + Math.PI * 0.5);
    else arrow2.rotateZ(angle - Math.PI * 0.5);
  } else {
    if (direction === ORIENT.CW) arrow2.rotateZ(angle - Math.PI * 0.5);
    else arrow2.rotateZ(angle + Math.PI * 0.5);
  }
}