export function getCurrentTime() {
  const date = new Date();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  return hours + ":" + minutes + ":" + seconds;
}
interface UnitAndValue {
  unit: string;
  value: number;
}
const DATE_UNITS = {
  day: 86400,
  hour: 3600,
  minute: 60,
  second: 1,
} as Record<Intl.RelativeTimeFormatUnit, number>;

const getSecondsDiff = (timestamp: number) => (Date.now() - timestamp) / 1000;

const getUnitAndValueDate = (secondsElapsed: number) => {
  for (const [unit, secondsInUnit] of Object.entries(DATE_UNITS)) {
    if (secondsElapsed >= secondsInUnit || unit === "second") {
      const value = Math.floor(secondsElapsed / secondsInUnit) * -1;
      return { value, unit };
    }
  }
};

export const getTimeAgo = (date: string, locale = "en") => {
  const timestamp = new Date(date).getTime();
  const rtf = new Intl.RelativeTimeFormat(locale);
  const secondsElapsed = getSecondsDiff(timestamp);
  const { value, unit } = getUnitAndValueDate(secondsElapsed) as UnitAndValue;
  return rtf.format(value, unit as Intl.RelativeTimeFormatUnit);
};

export const formatDate = (date: string | undefined) =>
  date
    ? new Date(date).toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
      year: "numeric",
    })
    : "";

export const formatTime = (seconds: number) => {
  const min = Math.floor(seconds / 60) + ' min';
  const seg = (seconds % 60) < 10 ? `0${(seconds % 60)} sg` : (seconds % 60) + ' sg';
  return `${min} : ${seg}`;
}