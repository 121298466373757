import * as THREE from "three";
import { vectorDist2D } from "./distance";
import { isZeroAng } from "./epsilon";
import { mirrorPoint } from "./mirror";
import { IPlane, getNormalOfPlane, addEulerAnglesToEulerAngles } from "./plane";
import { dotProductIpoint, getPolarPoint, magnitudeIpoint, substractIpoint } from "./point";
import { IPoint } from "./types";

export enum angleUnit {
  RAD = 0,
  DEG,
  GRA,
}

export enum ORIENT { CCW = "Counterclockwise", CW = "Clockwise" };

export function degToRad(degrees: number) { return degrees * Math.PI / 180; }
export function centToRad(grads: number) { return grads * Math.PI / 200; }
export function degToCent(degrees: number) { return degrees * (200 / 180); }
export function centToDeg(grads: number) { return grads * (180 / 200); }
export function radToDeg(radians: number) { return radians * 180 / Math.PI; }
export function radToCent(radians: number) { return radians * 200 / Math.PI; }

export function eulerAnglesToAxisAngle(x: number, y: number, z: number, order?: string) {
  const e = new THREE.Euler(x, y, z, order);
  const q = new THREE.Quaternion();
  q.setFromEuler(e);
  let axis;
  const angle = 2 * Math.acos(q.w);
  if (1 - (q.w * q.w) < 0.000001) {
    axis = [q.x, q.y, q.z];
  } else {
    const s = Math.sqrt(1 - (q.w * q.w));
    axis = [q.x / s, q.y / s, q.z / s];
  }
  return { angle: angle, axis: { x: axis[0], y: axis[1], z: axis[2] } };
}
export function axisAngleToEulerAngles(angle: number, axis: IPoint) {
  const q = new THREE.Quaternion();
  const axisNomr = new THREE.Vector3(axis.x, axis.y, axis.z);
  q.setFromAxisAngle(axisNomr, angle);

  const m = new THREE.Matrix4();
  m.makeRotationFromQuaternion(q);

  const eu0 = new THREE.Euler();
  eu0.setFromRotationMatrix(m);

  const eu = new THREE.Euler();
  eu.setFromQuaternion(q);
  return { x: eu.x, y: eu.y, z: eu.z };
}

/** Devuelve la conversión a angulo positivo a partir de un angulo negativo en radianes
 *
 * @export
 * @param {number} angle - Angulo de entrada, en radianes.
 * @returns {number} - Angulo de salida, en radianes.
 * @memberof MEC.CAD.GEO
 */
export function normalizeAngle(
  angle: number,
  unit: angleUnit = angleUnit.RAD,
  admitNegative: boolean = false
): number {
  let maxValue = Math.PI * 2;
  if (unit === angleUnit.DEG) maxValue = 360;
  else if (unit === angleUnit.GRA) maxValue = 400;

  if (isZeroAng(angle)) return 0;
  if (isZeroAng(angle - maxValue)) return maxValue;
  if (angle < 0) {
    if (admitNegative) return angle % maxValue;
    return (angle % maxValue) + maxValue;
  }
  if (angle > maxValue) return angle % maxValue;
  return angle;
}

/** El azimut (en radianes) para una lí­nea (NORTE, HORARIO)
 *      v2       0        v2
 *          -    |     +
 * -PI/2 -90 --- v1 --- PI/2 90
 *          -    |     +
 *      v2       PI       v2
 *
 * @export
 * @param {IPoint} v1 - punto inicial
 * @param {IPoint} v2 - punto final
 * @returns {number} - Azimut (en radianes)
 * @memberof MEC.CAD.GEO
 */
export function lineAzimut2p(v1: IPoint, v2: IPoint): number {
  return Math.atan2(v2.x - v1.x, v2.y - v1.y);
}
/** El ángulo (en radianes) para una lí­nea (ESTE, ANTIHORARIO)
 *      v2    PI/2 90      v2
 *          +    |     +
 * PI 180 ------ v1 --------- 0
 *          -    |     -
 *      v2   -PI/2 -90     v2
 *
 * @export
 * @param {IPoint} v1 - punto inicial
 * @param {IPoint} v2 - punto final
 * @param {string} [mode] - plano donde se proyecta el angulo, por defecto XY
 * @returns {number} - Ã¡ngulo (en radianes)
 * @memberof MEC.CAD.GEO
 */
export function lineAngle2p(v1: IPoint, v2: IPoint, mode?: "XY" | "XZ" | "YZ"): number {
  if (mode === undefined || mode === "XY") {
    return Math.atan2(v2.y - v1.y, v2.x - v1.x);
  } else if (mode === "XZ") {
    return Math.atan2(v2.z - v1.z, v2.x - v1.x);
  } else if (mode === "YZ") {
    return Math.atan2(v2.z - v1.z, v2.y - v1.y);
  } else {
    return Math.atan2(v2.y - v1.y, v2.x - v1.x);
  }
}
/** Calculo de la pendiente entre dos puntos
 *      v2    PI/2 90      v2
 *          +    |     +
 *     0 ------ v1 --------- 0
 *          -    |     -
 *      v2   -PI/2 -90     v2
 *
 * @export
 * @param {IPoint} v1
 * @param {IPoint} v2
 * @returns {number} Pendiente expresada en radianes
 */
export function lineSlope2p(v1: IPoint, v2: IPoint): number {
  const dist2D = vectorDist2D(v1, v2);
  return Math.atan2(v2.z - v1.z, dist2D);
}

/** Cálculo de ángulo mirror respecto a una recta
* @export
* @param {number} angle - ángulo de entrada
* @param {IPoint} v1 - punto inicial
* @param {IPoint} v2 - punto final
* @returns {number} Ángulo realizado el mirror
*/
export function mirrorAngle(angle: number, v1: IPoint, v2: IPoint): number {
  const pAux = getPolarPoint(v1, angle, 10);
  const mirrorPAux = mirrorPoint(pAux, v1, v2);
  const mirrorAngle = lineAngle2p(v1, mirrorPAux);
  return normalizeAngle(mirrorAngle);
  // const lineAngle = normalizeAngle(lineAngle2p(v1, v2));
  // const complemAngle = Math.PI - (((Math.PI * 0.5) - lineAngle) + angle);
  // return normalizeAngle(complemAngle - ((Math.PI * 0.5) - lineAngle));
}
export function mirrorRotation(rotation: IPoint, v1: IPoint, v2: IPoint): IPoint {
  const angleZ = normalizeAngle((Math.PI - lineAngle2p(v1, v2)));
  const rot1 = addEulerAnglesToEulerAngles(rotation, { x: 0, y: 0, z: angleZ });
  const q1 = new THREE.Quaternion().setFromEuler(new THREE.Euler(rot1.x, rot1.y, rot1.z));
  const qN = new THREE.Quaternion(0, 1, 0, 0);
  const b = qN.clone().multiply(q1).multiply(qN);
  const rot2 = new THREE.Euler().setFromQuaternion(b);
  const rotN = addEulerAnglesToEulerAngles(rot2, { x: 0, y: 0, z: -angleZ });
  return rotN;
}

export function mecDirectionMathDirection(direction: number) {
  direction = -direction + Math.PI * 0.5;
  return normalizeAngle(direction);
}

/**
 * Calculo angulo convexo entre dos direcciones
 *
 * @export
 * @param {number} angle1
 * @param {number} angle2
 * @returns {number}
 */
export function getMinAngleBetweenMathDirections(angle1: number, angle2: number): number {
  let angle = Math.abs(angle1 - angle2);
  const ang1 = Math.abs((angle1 + Math.PI * 2) - angle2);
  if (angle > ang1) angle = ang1;
  const ang2 = Math.abs((angle2 + Math.PI * 2) - angle1);
  if (angle > ang2) angle = ang2;
  return angle;
}
/** Angulo entre dos direcciones expresados en azimuts, teniendo en cuenta la dirección a seguir (Por defecto CW)
 *
 * @export
 * @param {number} azimut1
 * @param {number} azimut2
 * @param {number} [direction]
 * @returns {number}
 */
export function getAngleBetweenAzimuts(azimut1: number, azimut2: number, direction?: ORIENT): number {
  if (undefined === direction) direction = ORIENT.CW;
  const angle = azimut2 - azimut1;
  if (direction === ORIENT.CCW) {
    return normalizeAngle(Math.PI * 2 - angle);
  }
  return normalizeAngle(angle);
}
export function getAngleBetweenMathDirections(angle1: number, angle2: number, direction?: ORIENT): number {
  if (undefined === direction) direction = ORIENT.CCW;
  let angle;
  if (direction === ORIENT.CW) {
    angle = angle1 - angle2;
  } else {
    angle = angle2 - angle1;
  }
  return normalizeAngle(angle);
}

/** Comprueba si una dirección este contenida entre otras dos. Incluye inicio y fin.
 * NO SON AZIMUTES
 * @export
 * @param {number} dir Dirección a comprobar
 * @param {number} startDir Dirección inicio
 * @param {number} endDir Dirección fin
 * @param {number} [direction] Opcionalmente, el sentido. Por defecto es antihorario: MEC.CTS.ANG.ORIENT.CCW
 * @returns {boolean}
 */
export function isBetweenDirection(dir: number, startDir: number, endDir: number, direction?: ORIENT): boolean {
  if (undefined === direction) direction = ORIENT.CCW;
  dir = normalizeAngle(dir);
  // Abaratamos el coste de la función comprobando con epsilon los ángulos inicio y fin. Así no tenemos que comprobar con epsilon todas los casos siguientes.
  if (isZeroAng(startDir - dir) || isZeroAng(endDir - dir)) {
    return true;
  }
  if (direction === ORIENT.CW) {
    if (endDir - startDir < 0) {
      if ((startDir < dir && endDir < dir) || (startDir > dir && endDir > dir)) {
        // Dirección fuera
        return false;
      }
    } else {
      // desarrollo cruza origen
      if (startDir < dir && endDir > dir) {
        // Dirección fuera
        return false;
      }
    }
  } else if (direction === ORIENT.CCW) {
    if (endDir - startDir > 0) {
      if ((startDir < dir && endDir < dir) || (startDir > dir && endDir > dir)) {
        // Dirección fuera
        return false;
      }
    } else {
      // desarrollo cruza origen
      if (startDir > dir && endDir < dir) {
        // Dirección fuera
        return false;
      }
    }
  }
  return true;
}
export function isBetweenAzimuts(az: number, startAz: number, endAz: number): boolean {
  if (isZeroAng(startAz - az) || isZeroAng(endAz - az)) return true;
  az = normalizeAngle(az - startAz);
  endAz = normalizeAngle(endAz - startAz);
  startAz = 0;
  if (endAz > az) return true;
  return false;
}

/** Devuelve el angulo menor entre dos segmentos
 *
 * @export
 * @param {IPoint} lStart1
 * @param {IPoint} lEnd1
 * @param {IPoint} lStart2
 * @param {IPoint} lEnd2
 * @returns {number}
 */
export function angleBetweenLines(lStart1: IPoint, lEnd1: IPoint, lStart2: IPoint, lEnd2: IPoint): number {
  // Vector director de la recta 1
  const V1 = substractIpoint(lEnd1, lStart1);
  // Vector director de la recta 2
  const V2 = substractIpoint(lEnd2, lStart2);
  // Cálculo del ángulo más pequeño
  let angle = angleBetweenVectors(V1, V2);
  if (angle > Math.PI * 0.5) angle = Math.PI - angle;
  return angle;
}
/** Devuelve el angulo menor entre dos vectores
 *
 * @param {IPoint} vector1
 * @param {IPoint} vector2
 * @returns {number}
 */
export function angleBetweenVectors(vector1: IPoint, vector2: IPoint): number {
  const magnitA = magnitudeIpoint(vector1);
  const magnitB = magnitudeIpoint(vector2);
  return Math.acos((dotProductIpoint(vector1, vector2) / (magnitA * magnitB)));
}

/** Devuelve la bisectriz entre dos direcciones en radianes
 *
 * @export
 * @param {number} angle1 dirección origen en radianes
 * @param {number} angle2 dirección final en radianes
 * @param {number} direction antihorario/horario, por defecto Antohorario
 * @returns {number}
 */
export function getBisection(angle1: number, angle2: number, direction: ORIENT = ORIENT.CCW, factor: number = 0.5): number {
  const arcAngle = getAngleBetweenMathDirections(angle1, angle2, direction);
  const middleAngle = arcAngle * factor;
  return addAngleToDirection(middleAngle, angle1, direction);
}
/** Calculo azimut central de un arco definido por su azimut inicial y angulo de desarrollo
 * angulo central < 0 ----> sentido CCW
 * angulo central > 0 ----> sentido CW
 *
 * @export
 * @param {number} azimutO
 * @param {number} angleCenter
 * @returns {number}
 */
export function getAzimutBisection(azimutO: number, angleCenter: number, direction?: ORIENT): number {
  const middleAngle = Math.abs(angleCenter) * 0.5;
  if (direction === undefined) direction = angleCenter < 0 ? ORIENT.CCW : ORIENT.CW;
  let angle;
  if (direction === ORIENT.CW) {
    angle = azimutO + middleAngle;
  } else {
    angle = azimutO - middleAngle;
  }
  return normalizeAngle(angle);
}

export function addAngleToDirection(angle: number, origin: number, direction?: ORIENT): number {
  if (undefined === direction) direction = ORIENT.CCW;
  let res;
  if (direction === ORIENT.CW) {
    res = origin - angle;
  } else {
    res = origin + angle;
  }
  return normalizeAngle(res);
}

export function isDirectionInArc(currAzimut: number, azimutO: number, angleCenter: number): boolean {
  const angleStart = mecDirectionMathDirection(azimutO);
  const angleEnd = normalizeAngle(angleStart - angleCenter);
  const direction = angleCenter < 0 ? ORIENT.CCW : ORIENT.CW;
  const angle = mecDirectionMathDirection(currAzimut);
  return isBetweenDirection(angle, angleStart, angleEnd, direction);
}

export function angleBetweenPlanes(plane1: IPlane, plane2: IPlane): number {
  // Calculo de las normales
  const Nplane1 = getNormalOfPlane(plane1);
  const Nplane2 = getNormalOfPlane(plane2);
  // Cálculo del ángulo más pequeño
  let angle = angleBetweenVectors(Nplane1, Nplane2);
  if (angle > Math.PI * 0.5) angle = Math.PI - angle;
  return angle;
}
export function angleBetweenXYPlane(normalPlane: IPoint): number {
  let angle = angleBetweenVectors(normalPlane, { x: 0, y: 0, z: 1 });
  if (angle > Math.PI * 0.5) angle = Math.PI - angle;
  return angle;
}
export function angleBetweenXZPlane(normalPlane: IPoint): number {
  let angle = angleBetweenVectors(normalPlane, { x: 0, y: 1, z: 0 });
  if (angle > Math.PI * 0.5) angle = Math.PI - angle;
  return angle;
}
export function angleBetweenYZPlane(normalPlane: IPoint): number {
  let angle = angleBetweenVectors(normalPlane, { x: 1, y: 0, z: 0 });
  if (angle > Math.PI * 0.5) angle = Math.PI - angle;
  return angle;
}
