import { IObjData } from "lib/models/objdata";
import { getDataDefinitionHandler } from "lib/properties/properties";
import PropertiesAccordion from "../properties/component-accordion";
import { MemoizedPanelPropertiesForm } from "../properties/properties-panel-form";
import { useMainGraphicContext } from "../viewport/context";
import LayerSelector from "./layer-selector";

const SelectionPropertiesForm = ({ objData }: { objData: IObjData }) => {

  const graphicProcessor = useMainGraphicContext();
  const dataDefinition = getDataDefinitionHandler(objData, graphicProcessor);

  return (
    <div className="flex flex-col overflow-y-auto h-full w-full layers px-2 space-y-2">
      <PropertiesAccordion title="General">
        <LayerSelector objData={objData} />
      </PropertiesAccordion>
      {
        dataDefinition?.structuralInfo &&
        <PropertiesAccordion title="Structural">
          <MemoizedPanelPropertiesForm
            panelProp={{
              propValue: dataDefinition.structuralInfo,
              propCallback: dataDefinition.saveAndRegenerateStruct,
            }} />
        </PropertiesAccordion>
      }
      {
        dataDefinition?.materialInfo &&
        <PropertiesAccordion title="Material">
          <MemoizedPanelPropertiesForm
            panelProp={{
              propValue: dataDefinition.materialInfo,
              propCallback: dataDefinition.saveAndRegenerateMaterial,
            }} />
        </PropertiesAccordion>
      }
      {
        dataDefinition?.definitionInfo &&
        <PropertiesAccordion title="Geometry">
          <MemoizedPanelPropertiesForm
            panelProp={{
              propValue: dataDefinition.definitionInfo,
              propCallback: dataDefinition.saveAndRegenerate,
            }} />
        </PropertiesAccordion>
      }
      {
        dataDefinition?.styleInfo &&
        <PropertiesAccordion title="Style">
          <MemoizedPanelPropertiesForm
            panelProp={{
              propValue: dataDefinition.styleInfo,
              propCallback: dataDefinition.saveAndRegenerateStyle,
            }} />
        </PropertiesAccordion>
      }
    </div >
  );
};

export default SelectionPropertiesForm;
