import { TextCommand, TextParagraphCommand } from "lib/commands/primitives/text";

import { radAngleToUser, userAngleToRad } from "lib/general-settings";
import { pointCreate } from "lib/geometries/point";
import { filledPolygon2DPoints } from "lib/geometries/solid/region";
import { lineAngle2p, normalizeAngle } from "lib/math/angles";
import { copyIPoint, getPolarPoint } from "lib/math/point";
import { IPoint } from "lib/math/types";
import { textParam } from "lib/models/text";
import { white } from "lib/styles/colors";
import { createText, getTextWidths, modifyText } from "lib/text/builder";
import { loadTextFont } from "lib/text/font-loader";
import { textMultiPosTypeH, textMultiPosTypeV, TextOptsBuilder } from "lib/text/styles";
import { currentTextStyleId, textStyleCache } from "lib/text/cache";
import * as THREE from "three";
import { Cad3dOp } from "../base";
import { cadOpType } from "../factory";
import { settingsOpModes } from "../step-operations";

export class TextOP extends Cad3dOp {

  public opType = cadOpType.TEXT;
  public static sampleData: textParam = {
    styleId: currentTextStyleId,
    text: "abc123",
    position: { x: 0, y: 0, z: 0 },
    angleO: 0,
    plane: { x: 0, y: 0, z: 0 },
    scale: 1
  };

  protected styleId: string = TextOP.sampleData.styleId;
  protected text: string = TextOP.sampleData.text;
  protected position: IPoint = TextOP.sampleData.position;
  protected angleO: number = TextOP.sampleData.angleO;
  protected plane: IPoint = TextOP.sampleData.plane;
  protected scale: number = TextOP.sampleData.scale;
  protected styleOpts: TextOptsBuilder | undefined;

  protected auxText: THREE.Mesh;
  protected txtPtoHelper: THREE.Points;
  protected fakeCursor: THREE.Mesh;
  protected intervalId: NodeJS.Timeout | undefined; // To blink the cursor

  constructor(styleId: string) {
    super();
    this.styleId = styleId ?? currentTextStyleId;
  }
  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Insert point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
    }, {
      infoMsg: "Insert rotation: ",
      stepMode: settingsOpModes.ONEBOX,
      currValue: () => radAngleToUser(this.angleO).toFixed(4),
      cmdLineUpdateListener: (cmd: string) => {
        const h = userAngleToRad(parseFloat(cmd));
        if (!isNaN(h)) {
          this.angleO = h;
          this.rotateAux(this.angleO);
        }
      },
      cmdLineListener: (cmd: string) => {
        const h = userAngleToRad(parseFloat(cmd));
        if (!isNaN(h)) {
          this.angleO = h;
          this.hideWorkingPlaneAndCleanText();
          this.rotateAux(this.angleO);
          this.setNextStep();
          this.numPoints++;
        }
      },
    }, {
      infoMsg: "Insert text",
      stepMode: settingsOpModes.ONEBOX,
      hide: true,
      cmdLineUpdateListener: (cmd: string) => {
        this.text = cmd;
        this.updateText();
      },
      cmdLineListener: (cmd: string) => {
        this.text = cmd;
        this.endOperation();
      },
    }]);
  }
  public async start() {
    this.iniSettingsOp();
    const text: textParam = {
      styleId: this.styleId,
      text: this.text,
      position: this.position,
      angleO: this.angleO,
      plane: this.plane,
      scale: this.scale
    };
    this.styleOpts = await textStyleCache.loadStyle(this.styleId);
    this.auxText = createText(text, this.styleOpts);
    this.saveToTempScene(this.auxText);

    const fakeMaterial = new THREE.MeshPhongMaterial({ color: white, side: THREE.DoubleSide });
    const cursorPoints = this.getFakeCursorPoints(this.styleOpts);
    this.fakeCursor = filledPolygon2DPoints(cursorPoints, fakeMaterial);
    this.saveToTempScene(this.fakeCursor);
    this.startCursor();

    this.initializeSnap();
    this.initializeWorkingPlane();
    this.registerCancel();
    this.registerInputs();
    this.registerUpdaters();
  }
  public setLastPoint(): void {
    if (this.numPoints === 1) {
      this.position = copyIPoint(this.lastPoint);
      const { x, y, z } = this.position;
      this.txtPtoHelper = pointCreate(x, y, z);
      this.saveToTempScene(this.txtPtoHelper);
      this.auxText.position.set(x, y, z);
      this.fakeCursor.position.set(x, y, z);

      const planeManager = this.graphicProcessor.getPlaneManager();
      planeManager.activePlane.position = this.position;
      planeManager.activePlane.locked = true;

      this.setNextStep();
    } else if (this.numPoints === 2) {
      this.angleO = this.getAngle(this.position, this.lastPoint);
      this.hideWorkingPlaneAndCleanText();
      this.setNextStep();
    } else if (this.numPoints === 3) {
      this.endOperation();
    }
  }
  public moveLastPoint(pto: IPoint) {
    if (this.numPoints === 0) {
      this.position = copyIPoint(pto);
      const { x, y, z } = this.position;
      this.auxText.position.set(x, y, z);
      this.fakeCursor.position.set(x, y, z);
    } else if (this.numPoints === 1) {
      this.angleO = this.getAngle(this.position, pto);
      this.rotateAux(this.angleO);
    }
  }
  private hideWorkingPlaneAndCleanText() {
    this.workingPlane.hideActivePlane();
    this.text = "";
    this.updateText();
  }
  private getAngle(basePoint: IPoint, pto: IPoint) {
    const c = this.currPlane.getRelativePoint(basePoint);
    const p = this.currPlane.getRelativePoint(pto);
    return normalizeAngle(lineAngle2p(c, p));
  }
  private rotateAux(angle: number) {
    const { x, y, z } = this.currPlane.rotation;
    this.auxText.rotation.set(x, y, z);
    this.fakeCursor.rotation.set(x, y, z);
    this.auxText.rotateZ(angle);
    this.fakeCursor.rotateZ(angle);
  }
  private getFakeCursorPoints(styleOpts?: TextOptsBuilder) {
    const yDim = (styleOpts !== undefined) ? styleOpts.size : 100;
    let distUp = yDim;
    let distDown = 0;
    if (styleOpts) {
      switch (styleOpts.basePointV) {
        case textMultiPosTypeV.ASCENDER:
          { distUp = yDim * 0.1; distDown = (-1) * yDim * 0.9; break; } //
        case textMultiPosTypeV.BASELINE:
          { distUp = yDim; distDown = 0; break; } // ok
        case textMultiPosTypeV.DESCENDER:
          { distUp = yDim * 1.1; distDown = yDim * 0.1; break; } // ok
        case textMultiPosTypeV.MEDIAN:
          { distUp = yDim / 2; distDown = (-1) * yDim / 2; break; } // ok
      }
    }
    return [
      { x: - (yDim * 0.05), y: distDown, z: 0 },
      { x: - (yDim * 0.05), y: distUp, z: 0 },
      { x: + (yDim * 0.05), y: distUp, z: 0 },
      { x: + (yDim * 0.05), y: distDown, z: 0 }
    ];
  }
  private updateCursor() {
    if (!this.finished) {
      this.fakeCursor.visible = !this.fakeCursor.visible;
    }
  }
  public startCursor() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.fakeCursor.visible = true;
    this.intervalId = setInterval(this.updateCursor.bind(this), 500);
  }
  public updateText() {
    modifyText(this.auxText, this.text, this.styleOpts);
    // Update position fake cursor
    if (this.styleOpts && this.styleOpts.basePointH !== textMultiPosTypeH.RIGHT) {
      const font = loadTextFont(this.styleOpts.font) as THREE.Font;
      const textWidths = getTextWidths(this.text, this.styleOpts.size, font);
      if (textWidths.length > 0) {
        const pos = this.currPlane.getRelativePoint(this.position)
        const dist = this.styleOpts.basePointH === textMultiPosTypeH.LEFT ? textWidths[0] : textWidths[0] / 2;
        let pto = getPolarPoint(pos, this.angleO, dist);
        pto = this.currPlane.getAbsolutePoint(pto);
        this.fakeCursor.position.set(pto.x, pto.y, pto.z);
      }
    }
  }
  public endOperation(): void {
    if (this.finished === false) {
      TextOP.sampleData = {
        styleId: this.styleId,
        text: "abc123",
        position: this.position,
        angleO: this.angleO,
        plane: this.currPlane.rotation,
        scale: this.scale
      };
      this.save();
    }
    super.endOperation();
  }
  public save() {
    if (this.graphicProcessor) {
      const text: textParam = {
        styleId: this.styleId,
        text: this.text,
        position: this.position,
        angleO: this.angleO,
        plane: this.currPlane.rotation,
        scale: this.scale
      };
      const command = new TextCommand(text, this.getCurrentSceneId(), this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
}

export class TextParagraphOP extends TextOP {
  constructor(styleId: string) {
    super(styleId);
    this.opType = cadOpType.TEXTP;
    //this.enterFinish = false;
  }
  public cancelOperation(): void {
    if (this.finished === false) {
      super.endOperation();
    }
  }
  public save() {
    if (this.graphicProcessor) {
      const text: textParam = {
        styleId: this.styleId,
        text: this.text,
        position: this.position,
        angleO: this.angleO,
        plane: this.currPlane.rotation,
        scale: this.scale
      };
      const command = new TextParagraphCommand(text, this.getCurrentSceneId(), this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
}