import { FC } from "react";
import { QUERIES } from "shared/styles/constants";
import styled from "styled-components";

interface Props {
  title: string;
}
const AuthContainer: FC<Props> = ({ children, title }) => {
  return (
    <>
      <Title>{title}</Title>
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </>
  );
};

const Title = styled.h1`
  color: var(--color-gray-600);
  font-size: 1.875rem;
  font-weight: 600;
  text-align: center;
`;

const ChildrenWrapper = styled.div`
  background-color: var(--color-white);
  padding: 32px;
  box-shadow: var(--shadow);
  width: 100%;
  margin-top: 16px;
  @media ${QUERIES.mobileAndUp} {
    border-radius: 8px;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export default AuthContainer;
