import { Location, Project } from "modules/lite/models/project";
import { DXF, Storey } from "modules/lite/models/storey";
import { FC } from "react";
import RenderIf from "shared/components/ui/render-if";
import Section from "shared/components/ui/section";
import StoreyBulkUploader from "../storeys/storey-bulk-uploader";
import StoreyList from "../storeys/storey-list";
import Input from "../ui/input";
import MapInput from "../ui/map/map-input";

interface Props {
  onChangeLocation: (location: Location) => void;
  onChangeStorey: (storey: Storey) => void;
  onDropStorey: (source: number, destination: number) => void;
  onChangeFootingLevel: (footingLevel: number) => void;
  onBulkDXFUpload: (files: DXF[]) => void;
  onSubmit: (event: any) => void;
  register: any;
  project?: Project;
  storeys: Storey[];
}

const ProjectForm: FC<Props> = ({
  onChangeLocation,
  onChangeStorey,
  onDropStorey,
  onChangeFootingLevel,
  onBulkDXFUpload,
  onSubmit,
  project,
  register,
  storeys,
  children,
}) => {
  return (
    <form onSubmit={onSubmit}>
      <div className="space-y-4">
        <Section title="Details">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-4">
              <Input
                defaultValue={project?.name}
                id="name"
                label="Name"
                name="name"
                ref={register}
              />
            </div>

            <div className="col-span-4">
              <Input
                defaultValue={project?.address.street}
                id="street"
                label="Street"
                name="address.street"
                ref={register}
              />
            </div>
            <div className="col-span-1">
              <Input
                defaultValue={project?.address.number}
                id="number"
                label="Number"
                name="address.number"
                ref={register}
              />
            </div>
            <div className="col-span-6 sm:col-span-1">
              <Input
                defaultValue={project?.address.zip}
                id="zip"
                label="Postal code"
                name="address.zip"
                ref={register}
              />
            </div>
            <div className="col-span-6 sm:col-span-2">
              <Input
                defaultValue={project?.address.city}
                id="city"
                label="City"
                name="address.city"
                ref={register}
              />
            </div>
            <div className="col-span-6 sm:col-span-2">
              <Input
                defaultValue={project?.address.country}
                id="country"
                label="Country"
                name="address.country"
                ref={register}
              />
            </div>
          </div>
        </Section>
        <Section title="Storeys">
          <div className="grid grid-cols-6 gap-6 pb-4">
            <div className="col-span-6 sm:col-span-1">
              <Input
                defaultValue={project?.footing_level}
                id="footing_level"
                label="Footing level"
                name="footing_level"
                step={0.1}
                type="number"
                ref={register({
                  required: true,
                  valueAsNumber: true,
                })}
                onChange={(event) =>
                   {if(!Number.isNaN(event.target.valueAsNumber)) onChangeFootingLevel(event.target.valueAsNumber)}
                }
              />
            </div>
            <div className="col-span-6 sm:col-span-1">
              <Input
                defaultValue={project?.number_of_storeys}
                id="number_of_storeys"
                label="Storeys"
                name="number_of_storeys"
                type="number"
                ref={register({
                  required: true,
                  valueAsNumber: true,
                })}
              />
            </div>
            <div className="col-span-6 sm:col-span-1">
              <RenderIf isTrue={!storeys.length}>
                <StoreyBulkUploader onChange={onBulkDXFUpload} />
              </RenderIf>
            </div>
          </div>
          <RenderIf isTrue={!!storeys.length}>
            <div className="border-t border-gray-200">
              <StoreyList
                storeys={storeys}
                onChange={onChangeStorey}
                onDrop={onDropStorey}
              />
            </div>
          </RenderIf>
        </Section>
        {children}
      </div>
    </form>
  );
};

export default ProjectForm;
