import { cadOpType } from "../factory";
import { copyIPoint } from "../../math/point";
import { IPoint } from "../../math/types";
import { vectorDist3D } from "../../math/distance";
import { lineAngle2p, lineSlope2p } from "../../math/angles";
import { createBufferPlane, createPlane } from "../../geometries/plane";
import { Parameter3dOP } from "../solids/parameter-3d";
import { PlaneCommand } from "../../commands/primitives/plane";
import { settingsOpModes } from "../step-operations";
import { getCurrentSolidMaterial } from "lib/materials";
import { getXYZEulerFromEulerAngles } from "lib/math/plane";

export class PlaneOP extends Parameter3dOP {

  public opType = cadOpType.PLANE;

  protected static sampleData = {
    width: 2,
    height: 2,
    horizontalDivisions: 1,
    verticalDivisions: 1,
  };

  constructor() {
    super();
    this.offSet.x = PlaneOP.sampleData.width * 0.5;
    this.offSet.y = PlaneOP.sampleData.height * 0.5;
  }
  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Insert point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
      endStepCallback: () => {
        this.basePoint = copyIPoint(this.lastPoint);
        const planeManager = this.graphicProcessor.getPlaneManager();
        planeManager.activePlane.position = this.basePoint;
        planeManager.activePlane.locked = true;
      }
    }, {
      infoMsg: "Insert width: ",
      stepMode: settingsOpModes.ONEBOX,
      currValue: () => (PlaneOP.sampleData.width).toFixed(3),
      cmdLineListener: (cmd: string) => {
        const w = parseFloat(cmd);
        if (w) {
          PlaneOP.sampleData.width = w;
          this.offSet.x = PlaneOP.sampleData.width * 0.5;
          this.setNextStep();
          this.numPoints++;
          this.recalculateObj();
        }
      },
      endStepCallback: () => {
        const planeManager = this.graphicProcessor.getPlaneManager();
        planeManager.activePlane.position = this.basePoint;
        planeManager.activePlane.rotation = this.rotation;
        planeManager.activePlane.locked = true;
      }
    }, {
      infoMsg: "Insert length: ",
      stepMode: settingsOpModes.ONEBOX,
      currValue: () => (PlaneOP.sampleData.height).toFixed(3),
      cmdLineListener: (cmd: string) => {
        const h = parseFloat(cmd);
        if (h) {
          PlaneOP.sampleData.height = h;
          this.offSet.y = PlaneOP.sampleData.height * 0.5;
          this.endOperation();
        }
      },
      endStepCallback: () => {
        this.endOperation();
      }
    }]);
  }
  protected createAux() {
    return createPlane(
      PlaneOP.sampleData.width,
      PlaneOP.sampleData.height,
      PlaneOP.sampleData.horizontalDivisions,
      PlaneOP.sampleData.verticalDivisions
    );
  }
  protected createGeom() {
    return createBufferPlane(
      PlaneOP.sampleData.width,
      PlaneOP.sampleData.height,
      PlaneOP.sampleData.horizontalDivisions,
      PlaneOP.sampleData.verticalDivisions
    );
  }

  public moveLastPoint(point: IPoint) {
    if (this.numPoints === 0) {
      this.basePoint = copyIPoint(point);
      const planeManager = this.graphicProcessor.getPlaneManager();
      this.rotation = planeManager.activePlane.rotation;
      this.recalculateObj(); 

    } else if (this.numPoints === 1) {
      PlaneOP.sampleData.width = vectorDist3D(this.lastPoint, point);
      this.offSet.x = PlaneOP.sampleData.width * 0.5;
      const planeManager = this.graphicProcessor.getPlaneManager();
      const { x, y, z } = planeManager.activePlane.rotation;
      const p1 = planeManager.activePlane.getRelativePoint(point);
      const angle = lineAngle2p({ x: 0, y: 0, z: 0 }, p1);
      const slope = lineSlope2p({ x: 0, y: 0, z: 0 }, p1);
      const currRot = getXYZEulerFromEulerAngles(0, -slope, angle, "ZYX");
      this.rotation = { x: x + currRot.x, y: y + currRot.y, z: z + currRot.z };
      this.recalculateObj();

    } else if (this.numPoints === 2) {
      const planeManager = this.graphicProcessor.getPlaneManager();
      const p1 = planeManager.activePlane.getRelativePoint(point);
      PlaneOP.sampleData.height = Math.abs(p1.y);
      if (p1.y > 0) this.offSet.y = PlaneOP.sampleData.height * 0.5;
      else this.offSet.y = -PlaneOP.sampleData.height * 0.5;
      this.recalculateObj();
    }
  }

  public save(): void {
    if (this.graphicProcessor) {
      const command = new PlaneCommand(
        PlaneOP.sampleData.width,
        PlaneOP.sampleData.height,
        PlaneOP.sampleData.horizontalDivisions,
        PlaneOP.sampleData.verticalDivisions,
        this.basePoint,
        this.rotation,
        this.offSet,
        { x: 1, y: 1, z: 1 },
        this.getCurrentSceneId(),
        this.graphicProcessor,
        getCurrentSolidMaterial()
      );
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
}
