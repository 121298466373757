const SaveIcon = ({ ...props }) => {
  return (
    <svg
      className="h-5 w-5"
      viewBox="0 0 100 100"
      fill="currentColor"
      {...props}
    >
      <path d="M17 10C13.1739 10 10 13.1739 10 17V83C10 86.8261 13.1739 90 17 90H83C86.8261 90 90 86.8261 90 83V29C90.001 28.6057 89.9242 28.215 89.774 27.8504C89.6239 27.4858 89.4033 27.1543 89.125 26.875L73.125 10.875C72.8457 10.5967 72.5142 10.3761 72.1496 10.226C71.785 10.0758 71.3943 9.99906 71 10H17ZM17 16H25V41C25.0001 41.7956 25.3162 42.5586 25.8788 43.1212C26.4414 43.6838 27.2044 43.9999 28 44H68C68.7956 43.9999 69.5586 43.6838 70.1212 43.1212C70.6838 42.5586 70.9999 41.7956 71 41V17.25L84 30.25V83C84 83.6059 83.6059 84 83 84H17C16.3941 84 16 83.6059 16 83V17C16 16.3941 16.3941 16 17 16ZM31 16H65V38H31V16ZM50 51C42.8558 51 37 56.8558 37 64C37 71.1441 42.8558 77 50 77C57.1442 77 63 71.1441 63 64C63 56.8558 57.1442 51 50 51ZM50 57C53.9015 57 57 60.0985 57 64C57 67.9015 53.9015 71 50 71C46.0985 71 43 67.9015 43 64C43 60.0985 46.0985 57 50 57Z" />
    </svg>
  );
};

export default SaveIcon;
