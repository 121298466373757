import PropertiesSelect from "../properties/component-select";
import { useLayerContext } from "../layers/context";
import { IObjData } from "lib/models/objdata";

interface Props {
  objData: IObjData;
}
const LayerSelector = ({ objData }: Props) => {

  const { getLayerList, addObjectToLayer } = useLayerContext();

  return (
    <PropertiesSelect
      label="Layer"
      onChange={(event: any) => {
        const objectId = objData.id;
        const layerId = event.target.value as string;
        addObjectToLayer(objectId, layerId);
      }}
      value={objData.layerId}
    >
      {getLayerList.map((layer) => (
        <option key={layer.id} value={layer.id}>
          {layer.name}
        </option>
      ))}
    </PropertiesSelect>
  );
};

export default LayerSelector;
