const ScaleIcon = ({ ...props }) => {
  return (
    <svg className="h-5 w-5" viewBox="0 0 100 100" fill="none" {...props}>
      <path
        d="M18.75 6.20117V9.32617V56.25H-0.0244141V59.375V99.9512H43.689V81.2012H93.75V6.20117H18.75ZM25 12.4512H87.5V74.9512H43.689V56.25H25V12.4512ZM56.2256 18.7012V24.9512H70.5566L47.7661 47.7417L52.1851 52.1606L74.9756 29.3701V43.7012H81.2256V18.7012H56.2256ZM6.22559 62.5H18.75V81.2012H37.439V93.7012H6.22559V62.5ZM25 62.5H37.439V74.9512H25V62.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ScaleIcon;
