import { City, Continent } from "lib/apis/standard-codes/api";
import { getAllCountries, getCitiesForCountry } from "lib/apis/standard-codes/countries-cities";
import { ChangeEvent, useCallback, useEffect, useState } from "react";

export function useCountryCities(iniCountry?: string, iniCity?: string) {

  const [countries, setCountries] = useState<Continent[]>([]);
  const [currentCountry, setCurrentCountry] = useState<string[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [currentCity, setCurrentCity] = useState<string>();

  const setInitialValues = (countries: Continent[]) => {
    for (let continent of countries) {
      const country = continent.Countries?.find(c => c.Country === iniCountry);
      if (country) {
        setCurrentCountry([country.Country!, continent.ContinentISO, country.CountryISO!]);
        setCurrentCity(iniCity);
      }
    }
  }

  useEffect(() => {
    async function fetchCountries() {
      const countries = await getAllCountries();
      setCountries(countries);
      if (iniCountry) {
        setInitialValues(countries);
      }
    }
    fetchCountries();
  }, [])

  useEffect(() => {
    async function fetchCities() {
      if (currentCountry.length) {
        const cities = await getCitiesForCountry(currentCountry[1], currentCountry[2]);
        setCities(cities);
      }
    }
    fetchCities();
  }, [currentCountry])

  const handleChangeCountry = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const countryOpt = event.target.selectedOptions[0];
      if (countryOpt) {
        const continentOpt = countryOpt.closest('optgroup');
        if (continentOpt && countries) {
          const selectedContinent = countries.find(c => c.Continent === continentOpt.label);
          if (selectedContinent && selectedContinent.Countries) {
            const selectedCountry = selectedContinent.Countries.find(c => c.Country === countryOpt.value);
            if (selectedCountry) {
              setCurrentCountry([selectedCountry.Country!, selectedContinent.ContinentISO, selectedCountry.CountryISO!]);
            }
          }
        }
      }
    }, [countries]
  );

  const handleChangeCity = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const city = event.target.selectedOptions[0].value;
      setCurrentCity(city);
    }, []
  );

  return {
    countries,
    currentCountry,
    handleChangeCountry,
    cities,
    currentCity,
    handleChangeCity
  }
}