import { EllipseArcEditDataCommand } from "lib/commands/edition/ellipse-arc";

import { radAngleToUser, userAngleToRad } from "lib/general-settings";
import { setPosBuffer } from "lib/geometries";
import { ellipseArcCreate } from "lib/geometries/ellipse-arc";
import { lineAddVertex, lineAuxCreate, lineMoveVertex } from "lib/geometries/line";
import { lineAzimut2p, normalizeAngle } from "lib/math/angles";
import { vectorDist3D } from "lib/math/distance";
import { ellipseArcBuffer } from "lib/math/ellipse-arc";
import { copyIPoint } from "lib/math/point";
import { IPoint } from "lib/math/types";
import { EllipseArcData } from "lib/models/primitives/ellipse-arc";
import { mecDirectionMathDirection } from "../../math/angles";
import { SimpleEdition } from "../edition";
import { cadOpType } from "../factory";
import { settingsOpModes } from "../step-operations";
import { editEllipseParameters } from "./ellipse";

export class EllipseArcEditOP extends SimpleEdition {

  public opType = cadOpType.EDITELLIPSEARC;

  private objData: EllipseArcData;
  private param: editEllipseParameters;
  private aAxis: number;
  private bAxis: number;
  private azimutStart: number;
  private azimutEnd: number;

  constructor(objData: EllipseArcData, param: editEllipseParameters, iniPoint: IPoint) {
    super(iniPoint);
    this.objData = objData;
    this.param = param;
    const objDef = this.objData.definition;
    this.aAxis = objDef.a;
    this.bAxis = objDef.b;
    this.azimutStart = objDef.azimutStart;
    this.azimutEnd = objDef.azimutEnd;
    this.auxPoly = ellipseArcCreate(objDef.center, this.aAxis, this.bAxis, objDef.azimutO, this.azimutStart, this.azimutEnd);
    this.auxLine = lineAuxCreate();
    const { x, y, z } = copyIPoint(objDef.center);
    lineAddVertex(this.auxLine, x, y, z);
    lineAddVertex(this.auxLine, x, y, z);
  }
  protected iniSettingsOp() {
    if (this.param === editEllipseParameters.SEMIMAJORAXIS) {
      this.settingsOpManager.setCfg([{
        infoMsg: "Insert semiaxis mayor: ",
        stepMode: settingsOpModes.ONEBOX,
        currValue: () => (this.aAxis).toFixed(3),
        cmdLineListener: (cmd: string) => {
          const h = parseFloat(cmd);
          if (h) {
            this.aAxis = h;
            this.endOperation()
          }
        },
      }]);
    } else if (this.param === editEllipseParameters.SEMIMINORAXIS) {
      this.settingsOpManager.setCfg([{
        infoMsg: "Insert semiaxis mayor: ",
        stepMode: settingsOpModes.ONEBOX,
        currValue: () => (this.bAxis).toFixed(3),
        cmdLineListener: (cmd: string) => {
          const h = parseFloat(cmd);
          if (h) {
            this.bAxis = h;
            this.endOperation()
          }
        },
      }]);
    } else if (this.param === editEllipseParameters.STARTANGLE) {
      this.settingsOpManager.setCfg([{
        infoMsg: "Insert start angle: ",
        stepMode: settingsOpModes.ONEBOX,
        currValue: () => radAngleToUser(this.azimutStart).toFixed(4),
        cmdLineListener: (cmd: string) => {
          const h = userAngleToRad(parseFloat(cmd));
          if (!isNaN(h)) {
            this.azimutStart = h;
            this.endOperation()
          }
        },
      }]);
    } else if (this.param === editEllipseParameters.ENDANGLE) {
      this.settingsOpManager.setCfg([{
        infoMsg: "Insert semiaxis mayor: ",
        stepMode: settingsOpModes.ONEBOX,
        currValue: () => radAngleToUser(this.azimutEnd).toFixed(4),
        cmdLineListener: (cmd: string) => {
          const h = userAngleToRad(parseFloat(cmd));
          if (!isNaN(h)) {
            this.azimutEnd = h;
            this.endOperation()
          }
        },
      }]);
    }
  }
  private getAzimut(pto: IPoint, center: IPoint, azimutO: number) {
    const c = this.currPlane.getRelativePoint(center);
    const p = this.currPlane.getRelativePoint(pto);
    return normalizeAngle(mecDirectionMathDirection(azimutO) + normalizeAngle(lineAzimut2p(c, p)));
  }
  private setAppropiateProperty(pto: IPoint) {
    const objDef = this.objData.definition;
    if (this.param === editEllipseParameters.SEMIMAJORAXIS) {
      this.aAxis = vectorDist3D(objDef.center, pto);
    } else if (this.param === editEllipseParameters.SEMIMINORAXIS) {
      this.bAxis = vectorDist3D(objDef.center, pto);
    } else if (this.param === editEllipseParameters.STARTANGLE) {
      this.azimutStart = this.getAzimut(pto, objDef.center, objDef.azimutO);
    } else {
      this.azimutEnd = this.getAzimut(pto, objDef.center, objDef.azimutO);
    }
  }
  public moveLastPoint(pto: IPoint) {
    lineMoveVertex(this.auxLine, pto.x, pto.y, pto.z);
    this.setAppropiateProperty(pto);
    this.calculateEllipseArc();
  }
  public calculateEllipseArc(): void {
    const objDef = this.objData.definition;
    let coords = ellipseArcBuffer(objDef.center, this.aAxis, this.bAxis, objDef.azimutO, this.azimutStart, this.azimutEnd, objDef.plane);
    if (!coords) coords = new Float32Array(0);
    setPosBuffer(this.auxPoly, coords);
  }
  public setLastPoint(): void {
    this.setAppropiateProperty(this.lastPoint);
    this.endOperation();
  }
  public save() {
    if (this.graphicProcessor) {
      const objDef = this.objData.definition;
      const ellipseArc = {
        center: objDef.center,
        a: this.aAxis,
        b: this.bAxis,
        azimutO: objDef.azimutO,
        azimutStart: this.azimutStart,
        azimutEnd: this.azimutEnd,
        plane: objDef.plane,
      };
      const command = new EllipseArcEditDataCommand(this.objData, ellipseArc, null, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
}