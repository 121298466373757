import { FC } from "react";
import Input from "shared/components/ui/input";
import { User } from "shared/models/user";

interface Props {
  user: User;
}
const AccountForm: FC<Props> = ({ user }) => {
  return (
    <div className="rounded-md border border-gray-700 py-6 px-4 sm:p-6">
      <div className="space-y-2">
        <Input label="Usuario" value={user.username} />
        <Input label="Correo electrónico" value={user.email} />
        <Input
          label="Contraseña"
          type="password"
          disabled
          value={user.username}
        />
      </div>
    </div>
  );
};

export default AccountForm;
