import { PprismEditDataCommand } from "lib/commands/edition/rprism";

import { linealPrecision } from "lib/general-settings";
import { pprismParam } from "lib/geometries/solid/pprism";
import { volumePPrism } from "lib/geometries/volume";
import { IPoint } from "lib/math/types";
import { PPrismData } from "lib/models/solids/pprism";
import { dataInfoProperty } from "../properties";
import { SolidDataDefinitionHandler } from "../solid";
import { ISolidMaterial } from '../../materials/solid';

export type pprismProp = pprismParam & { volume: number; centroid: IPoint; }
export type pprismView = dataInfoProperty<pprismProp>

export class PPrismDataDefinitionHandler extends SolidDataDefinitionHandler<pprismProp> {

  protected data: PPrismData;

  protected buildInfoProperties() {
    this.definitionInfo = {};
    const def = this.data.definition;
    this.definitionInfo.radius = this.getNumberView(def.radius, "Radius", "m", linealPrecision, true, (radius: number) => radius > 0);
    this.definitionInfo.height = this.getNumberView(def.height, "Height", "m", linealPrecision, true, (height: number) => height > 0);
    this.definitionInfo.sides = this.getNumberView(def.sides, "Sides", "", 0, true, (side: number) => side > 2);
    this.definitionInfo.open = this.getBooleanView(def.open, "Open");
    this.fillSolidDefinition(this.data);
    this.definitionInfo.volume = this.getNumberView(volumePPrism(def), "Volume", "m³", linealPrecision, false);
  }

  private checkNewDefinition(newDefinition: pprismProp): boolean {
    if (!newDefinition) { return false; }
    if (!this.checkSolidNewDefinition(newDefinition)) { return false; }
    if (!this.checkNumber(newDefinition.radius)) { return false; }
    if (!this.checkNumber(newDefinition.height)) { return false; }
    if (!this.checkNumber(newDefinition.sides)) { return false; }
    if (!this.checkBoolean(newDefinition.open)) { return false; }
    return true;
  }

  private changedNewDefinition(oldDefinition: pprismParam, newDefinition: pprismProp): pprismParam | null {
    let def = oldDefinition;
    let changed: boolean = false;
    if (newDefinition) {
      const solid = this.changedSolidNewDefinition(oldDefinition, newDefinition);
      if (solid !== null) {
        def.basePoint = solid.basePoint;
        def.rotation = solid.rotation;
        def.scale = solid.scale;
        def.offset = solid.offset;
        changed = true;
      }
      const radius = this.changedNumber(def.radius, newDefinition.radius);
      if (radius !== null) {
        def.radius = radius;
        changed = true;
      }
      const height = this.changedNumber(def.height, newDefinition.height);
      if (height !== null) {
        def.height = height;
        changed = true;
      }
      const sides = this.changedNumber(def.sides, newDefinition.sides);
      if (sides !== null) {
        def.sides = sides;
        changed = true;
      }
      const open = this.changedBoolean(def.open, newDefinition.open);
      if (open !== null) {
        def.open = open;
        changed = true;
      }
    }
    return (changed ? def : null);
  }

  private checkAndChangedDefinition(oldDefinition: pprismParam, newDefinition: pprismProp): pprismParam | null {
    let def = null;
    if (this.checkNewDefinition(newDefinition)) {
      def = this.changedNewDefinition(oldDefinition, newDefinition);
    }
    return def;
  }

  public saveAndRegenerate = (newDefinition: pprismProp) => {
    const pprismDefinition = this.checkAndChangedDefinition(this.data.cloneDefinition(), newDefinition);
    if (pprismDefinition) {
      const command = new PprismEditDataCommand(this.data, pprismDefinition, null, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    };
  }
  public saveAndRegenerateMaterial = (newMaterial: ISolidMaterial) => {
    const pprismMaterial = this.checkAndChangedSolidMaterial(this.data.material, newMaterial);
    if (pprismMaterial) {
      const command = new PprismEditDataCommand(this.data, null, pprismMaterial, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    };
  }
}