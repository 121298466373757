import { GraphicProcessor } from "../graphic-processor";
import { cadOpType } from "../operations/factory";

export type undoRedoCallback = () => void;

/** Un comando de UndoRedo. Contiene toda la información para ejecutarse y deshacerse.
 *
 * @export
 * @interface ICommand
 */
export interface ICommand {
  /** Nombre del comando
   *
   * @type {string}
   * @memberof Command
   */
  commandName: string;

  /** Ejecutar
   *
   * @memberof Command
   */
  execute: () => void;

  /** Deshacer
   *
   * @memberof Command
   */
  unExecute: () => void;

  /**  Función llamada cuando va a desaparecer el comando. Por si hace falta borrar referencias, etc... para liberar memoria.
   *
   * @memberof Command
   */
  delete(): void;
}

export abstract class CadCommand implements ICommand {

  protected abstract opType: cadOpType;
  protected graphicProcessor: GraphicProcessor;
  protected layerId: string;

  get commandName(): string {
    return this.graphicProcessor.getOpName(this.opType);
  }

  constructor(graphicProcessor: GraphicProcessor) {
    this.graphicProcessor = graphicProcessor;
  }
  public abstract execute(): void;
  public abstract unExecute(): void;
  public abstract delete(): void;
}
