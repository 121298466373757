const PlaneIcon = ({ ...props }) => {
  return (
    <svg className="h-5 w-5" viewBox="0 0 100 100" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66667 84.1667H11.6667V74.1667H1.66667V84.1667Z"
        stroke="currentColor"
        strokeWidth="3.33333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.3333 25.8333H98.3333V15.8333H88.3333V25.8333Z"
        stroke="currentColor"
        strokeWidth="3.33333"
      />
      <path
        d="M6.66667 72.5V19.1667"
        stroke="currentColor"
        strokeWidth="3.33333"
      />
      <path
        d="M86.6667 20.8333H6.66667"
        stroke="currentColor"
        strokeWidth="3.33333"
      />
      <path
        d="M11.6667 79.1667H95"
        stroke="currentColor"
        strokeWidth="3.33333"
      />
      <path
        d="M93.3333 25.8333V77.5"
        stroke="currentColor"
        strokeWidth="3.33333"
      />
    </svg>
  );
};

export default PlaneIcon;
