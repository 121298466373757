import * as THREE from "three";
import { updateObjBboxBSphere } from "../geometries";

export function deepCopyThreeObj(threeObj: THREE.Object3D, recursive: boolean = true): THREE.Object3D {
    const dolly = threeObj.clone(recursive) as THREE.Line;
    if ((threeObj as THREE.Line).geometry) {
        dolly.geometry = (threeObj as THREE.Line).geometry.clone();
    }
    if ((threeObj as THREE.Line).material) {
        dolly.material = ((threeObj as THREE.Line).material as THREE.Material).clone()
    }
    return dolly;
}

export function setGeometryFromThreeObj(threeObjFrom: THREE.Object3D, threeObjTo: THREE.Object3D): void {
    // Ajuste del bufferGeometry
    const fromGeom = (threeObjFrom as THREE.Line).geometry as THREE.BufferGeometry;
    const toGeom = (threeObjTo as THREE.Line).geometry as THREE.BufferGeometry;
    if (fromGeom && toGeom) {
        toGeom.copy(fromGeom);
        // const fromBuffer = fromGeom.getAttribute("position") as THREE.BufferAttribute;
        // const toBuffer = toGeom.getAttribute("position") as THREE.BufferAttribute;

        // toBuffer.array = new Float32Array(fromBuffer.array);
        // toBuffer.count = fromBuffer.count;

        // if (toGeom.drawRange) {
        //     toGeom.drawRange.start = fromGeom.drawRange.start;
        //     toGeom.drawRange.count = fromGeom.drawRange.count;
        // }
        // toBuffer.needsUpdate = true;
    }
    // const index = newBuffer.getIndex();
    // if (index) {
    //     threeObj.geometry.setIndex(index);
    //     threeObj.geometry.index.needsUpdate = true;
    // }

    // Ajuste del position, rotation y scale
    const posFrom = threeObjFrom.position;
    const rotFrom = threeObjFrom.rotation;
    const scaFrom = threeObjFrom.scale;

    threeObjTo.position.set(posFrom.x, posFrom.y, posFrom.z);
    threeObjTo.rotation.set(rotFrom.x, rotFrom.y, rotFrom.z);
    threeObjTo.scale.set(scaFrom.x, scaFrom.y, scaFrom.z);

    updateObjBboxBSphere(threeObjTo);
    threeObjTo.updateMatrixWorld();
}