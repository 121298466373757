import * as THREE from "three";
import { COLORS } from "lib/styles/colors";

/** Gestor de escenas. Las capas del modelo de datos se prepresentan en el SceneGraph por THREE.Groups
 *
 * @export
 * @class SceneManager
 */
export class SceneManager {

  /** Escena base de la que cuelgan las capas (THREE.Groups), 
   *
   * @private
   * @type {THREE.Scene}
   * @memberof SceneManager
   */
  private scene: THREE.Scene;
  /** Escena auxiliar para mostrar HUB's, ejes auxiliares, grid, etc...
   *
   * @private
   * @type {THREE.Scene}
   * @memberof SceneManager
   */
  private sceneHelperLoc: THREE.Scene;
  /** Escena auxiliar para ser usada por operaciones CAD
   *
   * @private
   * @type {THREE.Scene}
   * @memberof SceneManager
   */
  private sceneAuxLoc: THREE.Scene;
  /** Escena auxiliar para mostrar las marcas de seleccion
   *
   * @private
   * @type {THREE.Scene}
   * @memberof SceneManager
   */
  private sceneSelectionLoc: THREE.Scene;

  /** Primer nodo del SceneGraph correspondiente a la capa raíz
   *
   * @private
   * @type {THREE.Group}
   * @memberof SceneManager
   */
  private rootGroup: THREE.Group;

  constructor() {
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(COLORS.sceneBackground);
    this.scene.name = "main";
    this.createLights(this.scene);

    // Ahora le agregamos el grupo que sera la capa raiz etiquetada como rootLabel.
    this.rootGroup = new THREE.Group();
    this.rootGroup.name = "root";
    this.scene.add(this.rootGroup);

    // Construimos las escenas helper, auxiliar y de selección.
    this.sceneHelperLoc = new THREE.Scene();
    this.sceneHelperLoc.name = "helper";
    this.sceneAuxLoc = new THREE.Scene();
    this.sceneAuxLoc.name = "aux";
    this.createLights(this.sceneAuxLoc);
    this.sceneSelectionLoc = new THREE.Scene();
    this.sceneSelectionLoc.name = "selection";
  }

  public reset(): void {
    SceneManager.sceneDestruction(this.scene);
    SceneManager.sceneDestruction(this.sceneHelperLoc);
    SceneManager.sceneDestruction(this.sceneAuxLoc);
    SceneManager.sceneDestruction(this.sceneSelectionLoc);
  }
  public resetRootGroup(): void {
    SceneManager.sceneDestruction(this.rootGroup);
    this.scene.add(this.rootGroup);
  }

  public clearTempScene(): void {
    this.sceneAuxLoc.clear();
    this.createLights(this.sceneAuxLoc);
  }

  private createLights(scene: THREE.Scene): void {

    const hemiLight = new THREE.HemisphereLight("#ffffff", "#c1c1c1", 0.9);
    hemiLight.position.set(0, 1000, 0);
    scene.add(hemiLight);
    const l0 = new THREE.DirectionalLight("#a2a2a2", 0.4);
    l0.position.set(-1000, -1000, 0);
    scene.add(l0);
    const l1 = new THREE.DirectionalLight("#a2a2a2", 0.4);
    l1.position.set(1000, 1000, 0);
    scene.add(l1);

    // Truco para poner una fuente de luz puntual en la misma posicion de la camara.
    // La putada es que me obliga a agregar la luz a la camara.
    // AL FINAL NO SE USA, PUES CON VARIOS VIEWPORTS DA UNA SENSACION RARA, como de que otro anda husmeando por otro lado...
    // const pointLight = new THREE.PointLight(0xffffff, 0.9);
    // this._perspCamera.add(pointLight);
    // scene.add(this._perspCamera);
  }

  get mainScene(): THREE.Scene {
    return this.scene;
  }
  get helperScene(): THREE.Scene {
    return this.sceneHelperLoc;
  }
  get auxScene(): THREE.Scene {
    return this.sceneAuxLoc;
  }
  get selectionScene(): THREE.Scene {
    return this.sceneSelectionLoc;
  }
  get rootLayer(): THREE.Group {
    return this.rootGroup;
  }

  /** Ultradestructor de escenas aprovechando todo nuestro conocimiento de Three.
   * @param scene
   */
  public static sceneDestruction(scene: THREE.Scene | THREE.Group, dispose: boolean = true): void {
    // No me fio de recorrer recursivamente los objetos de la escena y aplicarles una desctruccion diferida recursiva.
    // En vez de ello recorro en preorden la escena y acumulo la lista de objetos disponibles.
    // Despues a esa lista le doy la vuelta y destruyo uno a uno sus objetos. Eso parece mas seguro y rapido, ya que al hacerlo
    // al reves destruire siempre primero a los hijos que a los padres.
    // Antes acumulaba los id's numericos de los morituri, pero buscarlos mediante scene.getObjectById() es EXTRAORDINARIAMENTE
    // LENTO pues es recursivo (Unos 48000 objetos tardaban 53 segundos). Asi que mejor acumulo los propios objetos directamente.
    const vObjs: THREE.Object3D[] = [];
    scene.traverse((object: THREE.Object3D) => { vObjs.push(object) });

    // Damos la vuelta.
    vObjs.reverse();

    console.log("Vamos a borrar " + vObjs.length + " objetos graficos de la escena '" + scene.name + "'");
    // Los matamos de uno en uno en esta llamada.
    SceneManager.sceneDestroyObjects(scene, vObjs, dispose);

    // Y rematamos la jugada asi por si quedase alguna zurraspa que rematar...
    scene.clear();
  }
  /** Destruccion avanzada de solo los objetos dados en la escena dada.
   * @param scene
   * @param vObjs
   */
  private static sceneDestroyObjects(scene: THREE.Scene | THREE.Group, vObjs: THREE.Object3D[], dispose: boolean = true): void {
    type Type2InnerDispose = THREE.Mesh | THREE.Line;

    const hasGeometry = (obj: any): obj is Type2InnerDispose => {
      if (obj.geometry) return true;
      return false;
    };
    const hasMaterial = (obj: any): obj is Type2InnerDispose => {
      if (obj.material) return true;
      return false;
    };

    const numObjects = vObjs.length;
    for (let i = 0; i < numObjects; ++i) {
      let object = vObjs[i];
      if (!object) {
        window.alert("No es posible tener un objeto ya borrado.");
        debugger;
      } else {
        if (dispose) {
          if (hasGeometry(object)) {
            object.geometry.dispose();
            object.geometry = undefined!;
          }
          if (hasMaterial(object)) {
            if (Array.isArray(object.material)) {
              const numMaterials = object.material.length;
              for (let j = 0; j < numMaterials; ++j) {
                object.material[j].dispose();
              }
              object.material.length = 0;
            } else {
              // Queda meter:
              // if (obj.material.map) {
              // 	obj.material.map.dispose();
              // 	obj.material.map = undefined;
              // }
              object.material.dispose();
            }
            object.material = undefined!;
          }
        }
        // Seria necesario esto?
        scene.remove(object);

        // Borramos del padre siempre existente, salvo en raiz.
        if (object.parent) {
          object.parent.remove(object);
        }

        // Quitamos a los YA inexistentes hijos.
        if (object.children) {
          object.children.length = 0;
        }
        // Y se suicida al objeto.
        object = undefined!;
        // Por si las moscas para que no quede referencia alguna posible.
        vObjs[i] = object;
      }
    }
    vObjs.length = 0;
  }

  // ********************************************************************** */

  /**
   * Para saber si el nodo "descendant" lo es del nodo "ancestor", es decir si esta contenido, con el grado que sea, dentro
   * de la "capa" dada por el nodo ancestro.
   * Ademas un nodo no puede ser ancestro de si mismo, obviamente, ya que o bien serian los 2 nodos iguales o se introduciria
   * un bucle cerrado que joderia nuestro arbol de sceneGraph.
   *
   * @static
   * @param {THREE.Object3D} ancestor
   * @param {THREE.Object3D} descendant
   * @returns {boolean}
   * @memberof SceneManager
   */
  public static isAncestor(ancestor: THREE.Object3D, descendant: THREE.Object3D): boolean {

    if (ancestor === descendant) {
      return false;
    }

    // Sencillamente ascenderemos, trepando padre a padre, desde descendant hasta alcanzar al ancestor o llegar a la raiz.
    // No usamos el traverseAncestor() que es mas costoso ya que no podemos salir cuando queramos.
    let parent = descendant.parent;
    while (parent) {
      if (parent === ancestor) {
        return true;
      }
      // Avance hacia arriba.
      descendant = parent;
      parent = descendant.parent;
    }
    return false;
  }

}
