/**
 * \file graphic-viewport-manager.ts
 * Implementacion de la clase GraphicViewportManager, gestor centralizado de los graphic viewports, que abreviare con
 * el acronimo GVP.
 */

import { CameraControls } from "./helpers/camera-controls/CameraControls";
// Otro control de Yomotsu para las pulsaciones:
// npm install --save-dev hold-event (posiblemente con --legacy-peer-deps)
import { KeyboardKeyHold } from "hold-event";

import { GraphicProcessor } from "./graphic-processor";
import { setMouseControls } from "./mouse-settings";
import { isSelectButtonPressed } from "lib/mouse-settings";
import * as THREE from "three";
import { XYZmo_RCOH8 } from "./helpers/xyzmo_rcoh8";
import { GraphicViewport, areOkDimms01, duplicateOrthographicCamera } from "./graphic-viewport";

// De momento aqui ira la configuracion basica de los N vp's disponibles, con datos como sus nombres y dimensiones, asi
// como sus camaras, etc, para cuando la salvamos/recuperamos.
export type Settings4GVP = any;

// Datos sobre los div's en que se descompone otro.
interface Info4Sons {
  // Hijo subdivision primera (por la izquierda o por arriba).
  leftSon: HTMLDivElement;
  // Hijo subdivision segunda (por la derecha o por abajo).
  rightSon: HTMLDivElement;
  // Hijo separador (vertical u horizontal) entre los hijos subdivision anteriores.
  divisionSon: HTMLDivElement;
  // Flag que si es verdadero estamos ante una subdivision vertical EXOR horizontal en caso contrario.
  isVertical: boolean;
  // Metemos el callback del slider separador para poderlo suprimir correctamente.
  downCallback: (event: PointerEvent) => void;
};

interface Info4Div {
  // El div padre del nodo div en curso. Solo el raiz no lo tendra.
  father?: HTMLDivElement;
  // Informacion sobre los hijos si es que los tiene (no es hoja).
  sons?: Info4Sons;
};



export class GraphicViewportManager {

  /**
   * El padre y señor de este gestor, necesario para extraer ciertos valores y hacerlos accesibles a los GVP.
   * Ademas recuerda que este gestor tambien es el owner de cada GVP que produzca.
   * Sera accesible desde el exterior por la propiedad RO owner.
   */
  private _ownerGPO: GraphicProcessor;

  /**
   * Necesitamos algun tipo de estructura en forma de arbol ternario donde iran los DIV's no solo de los VP implicados, sino
   * tambien los DIV's intermedios padres de los mismos. Por ejemplo al subdividir inicialmente el mainView ponemos su div
   * como padre y creamos los subDiv's hijos COMO HIJOS DEL MISMO, y asi recursivamente.
   * En cada entrada hay un slider, por lo que el size de este mapa es el numero de sliders.
   */
  _divTree3: Map<HTMLDivElement, Info4Div>;

  /**
   * Mantenemos una serie de viewports (bien sean los originales directamente suministrados desde el exterior o bien sean los
   * nuevos que sean fruto de la subdivision V/H de un VP existente), que pueden ser de 2 formas: Con o sin elemento HTML
   * asociado. Si no se dan tiramos de las camaras/controles predefinidos y hacemos un render total.
   * Se agrega un viewport con la fmc addViewport(). Ojo, que aqui NO esta el mainView, de momento...
   * \ToDo: Aunque quizas debiera ponerlo como el primero, SIEMPRE en la posicion 0.
   */
  _vViewports: GraphicViewport[];

  /**
   * Aqui tenemos los viewports seleccionados para su destruccion diferida, que se hace en cuanto es posible.
   * Ademas los viewports seleccionados para morir se quitan del dmc complementario _vViewports y si se puede se reintegra
   * su area al vp hermano que los genero.
   */
  _vViewports2Delete: GraphicViewport[];

  /**
   * En determinadas ocasiones el gestor de VP's se bloquea para impedir que en el exterior se atienda a ciertos eventos
   * que este misero gestor quiere procesar sin interferencias. Para ello colocar a true esta variable, que normalmente
   * sera false.
   */
  _isBlocked: boolean;

  /**
   * Indice del VP activo dentro del vector _vViewports.
   */
  index4ActiveGVP: number;

  // ___________________________________________Fin_de_la_declaracion_de_datos_miembro______________________________________
  // ^^^^^^^ Poner encima los nuevos DMC's que vayas agregando.
  
  /**
   * Constructor de un GVP manager a partir del GPO en el que se basa su funcionamiento.
   *
   * @param gPO
   */
  constructor(gPO: GraphicProcessor) {
    this._ownerGPO = gPO;
    this._divTree3 = new Map();
    this._vViewports = [];
    this._vViewports2Delete = [];

    // No nos bloquearemos hasta que sea menester.
    this._isBlocked = false;

    this.index4ActiveGVP = -1;
  }

  get owner(): GraphicProcessor { return this._ownerGPO; }

  /**
   * Vacia/destruye todos los vp's acumulados, haciendo como un reset del componente.
   * No tocar nunca el owner, de forma que con mount()/unmount() todo siga funcionando correctamente.
   *
   * @memberof GraphicViewportManager
   */
  destroy(): void {
    // Machacamos todos los viewports que hubiera.
    if (this._vViewports.length) {
      for (const vp of this._vViewports) {
        vp.destroyViewport();
      }
      this._vViewports.length = 0;
      this._vViewports2Delete.length = 0;
    }

    if (this._divTree3.size) {
      // Para los elementos HTML hace falta una destruccion mas exhaustiva, pero al reves, empezando por los hijos y acabando por el primero.
      for (let elemHTML of [...this._divTree3.keys()].reverse()) {
        elemHTML.remove();
      }
      this._divTree3.clear();
    }

    // Ojo, esto NI tocarlo, para que el componente se pueda reconstruir en el mount().
    // this._ownerGPO = (undefined as unknown as GraphicProcessor);
  }

  /**
   * Devuelve el numero de viewports actuales. Nunca se tiene en cuenta al mainViewport, asi que solo son los vp almacenados
   * en el array _vViewports.
   *
   * @returns {number}
   * @memberof GraphicViewportManager
   */
  public getNumViewports(): number {
    return this._vViewports.length;
  }

  public getNumViewports2Delete(): number {
    return this._vViewports2Delete.length;
  }

  public getViewportN(n: number): GraphicViewport {
    // Comprobacion psicopatica que desaparecera.
    if (n < 0 || this._vViewports.length <= n) {
      console.error("Acceso chungo!!!.");
      debugger;
    }
    return this._vViewports[n];
  }

  /**
   * Accesor a un viewport mediante su nombre (no vacio, unico e irrepetible).
   * Atencion: Si como nombre damos "mainView" nos referimos a la vista principal, que no es un viewport.
   * En caso de error devuelve null.
   *
   * @param {string} name
   * @returns {IViewportDef}
   * @memberof GraphicProcessor
   */
  public getViewport(name: string): GraphicViewport | null {
    if (name === "mainView") {
      return this._ownerGPO._mainViewport;
    }

    for (let i = 0; i < this._vViewports.length; ++i) {
      if (name === this._vViewports[i].name) {
        return this._vViewports[i];
      }
    }
    return null;
  }

  public getActiveViewport(): GraphicViewport {
    if (this.index4ActiveGVP === -1) {
      // En este caso no hay ningun viewport definido en este manager, asi que lo que hacemos es devolver el mainViewPort
      // del owner.
      return this.owner.getMainViewDefinition();
    }
    return this._vViewports[this.index4ActiveGVP];
  }

  public setActiveViewport(newVP: GraphicViewport): boolean {
    if (null === newVP || undefined === newVP) {
      window.alert("setActiveViewport() con MIERDA!!!.");
      debugger;
      return false;
    }

    const pos4NewVP = this._vViewports.indexOf(newVP);
    if (-1 === pos4NewVP) {
      if (newVP.name === "mainView") {
        // Este es el mainViewport del GPO, que esta al margen del manager y pertenece al GPO.
        this.owner.dispatchViewportEvent(newVP);
        return true;
      }
      window.alert("setActiveViewport() con vp no registrado!!!.");
      debugger;
      return false;
    }

    const prevActiveVP = this.getActiveViewport();
    if (true || newVP !== prevActiveVP) {
      const prevName = prevActiveVP ? prevActiveVP.name : "NonAssignedYet???";
      const newName = newVP.name;

      console.log(`setActiveViewport(): '${prevName}' ===> '${newName}'`);
      this.index4ActiveGVP = pos4NewVP;
      // Informamos al dueño para que dispare eventos de nivel superior...      
      this.owner.dispatchViewportEvent(newVP);
      // Efectivamente ha habido un cambio.
      return true;
    }
    return false;
  }

  /**
   * Devuelve un vector con todos los vp's ACTIVOS (los enabled).
   * Ojo, que incluimos tambien al mainView.
   *
   * @returns
   */
  public getEnabledViewports(): GraphicViewport[] {
    const viewPorts = [];
    // Ojo, que en la cuenta tambien devolvemos al mainView, que no consideramos un vp en el estricto sentido.
    if (this._ownerGPO._mainViewport.enabled) {
      viewPorts.push(this._ownerGPO._mainViewport);
    }
    for (let i = 0; i < this._vViewports.length; ++i) {
      if (this._vViewports[i].enabled) {
        viewPorts.push(this._vViewports[i]);
      }
    }
    return viewPorts;
  }

  /**
   * Contador de los vp's ACTIVOS aka enabled, sin tener en cuenta al mainView.
   *
   * @returns
   */
  public countEnabledViewports(): number {
    let cnt: number = 0;
    // Ojo, que en la cuenta tambien NO devolvemos al mainView.
    for (let i = 0; i < this._vViewports.length; ++i) {
      if (this._vViewports[i].enabled) {
        ++cnt;
      }
    }
    return cnt;
  }

  /**
   * Constructor/registrador centralizado de un viewport minimo, solo con el nombre y los 4 parametros en [0, 1] que
   * definen su extension. Los 4 parametros son de la forma [left01, top01, width01, height01] y deben cumplir la
   * pertinente coherencia geometrica... Siempre van referenciados sobre el total destinado a la pantalla grafica
   * completa, aka al container global.
   * No admitimos nombres repetidos, ya que automaticamente se registra el gvp en el manager.
   * En caso de error se devuelve null.
   *
   * @param name
   * @param ltwh01
   * @returns
   */
  public createViewport(name: string, ltwh01: [number, number, number, number]): GraphicViewport | null {
    if (this.hasName(name)) {
      return null;
    }
    if (!areOkDimms01(ltwh01)) {
      return null;
    }

    // \ToDo: Comprobar que no se solapan las dimensiones con otros vp's existentes...
    const newVP = new GraphicViewport(this);
    newVP.name = name;

    newVP.left01 = ltwh01[0];
    newVP.top01 = ltwh01[1];
    newVP.width01 = ltwh01[2];
    newVP.height01 = ltwh01[3];
    return newVP;
  }

  private hasName(name: string): boolean {
    for (const vp of this._vViewports) {
      if (vp.name === name) {
        return true;
      }
    }
    return false;
  }

  /**
   * Similar a GraphicViewport.configureViewport() pero aqui todo se basa en que desde el exterior se nos daran los %
   * de left/top y width/height segun se trate de una division vertical u horizontal del div padre.
   * De esta forma aumentaria la precision y no quedarian pixels "inexactos" perdidos. \ToDo: Revisarlo!!!.
   *
   * @param {GraphicViewport} vp
   * @param {number} dimXY
   * @param {number} [x=-1]
   * @param {number} [y=-1]
   * @param {HTMLDivElement} fatherDiv
   * @param {boolean} isVertical
   * @param {number} xyTPC
   * @param {number} whTPC
   * @param {boolean} [setGizmoCallback=true]
   * @memberof GraphicViewportManager
   */
  public simplyConfigureViewport(vp: GraphicViewport,
    dimXY: number, x: number = -1, y: number = -1,
    fatherDiv: HTMLDivElement, isVertical: boolean,
    xyTPC: number, whTPC: number,
    setGizmoCallback: boolean = true): void {
    // [1] Creamos el subcontainer del canvas principal.
    const subContainer = document.createElement("div");

    fatherDiv.appendChild(subContainer);

    if (isVertical) {
      const left100 = xyTPC;
      const width100 = whTPC;
      subContainer.style.left = left100 + "%";
      subContainer.style.top = "0%";
      subContainer.style.width = width100 + "%";
      subContainer.style.height = "100%";
    } else {
      const top100 = xyTPC;
      const height100 = whTPC;
      subContainer.style.left = "0%";
      subContainer.style.top = top100 + "%";
      subContainer.style.width = "100%";
      subContainer.style.height = height100 + "%";
    }

    // Esto es fundamental.
    subContainer.style.position = "absolute";
    // [4] Al div le damos un borde.
    subContainer.style.borderWidth = "2px";
    subContainer.style.borderStyle = "solid";
    // Para depurar lo ponemos rojo, pero en la vida real lo dejamos color desactivado.
    // subContainer.style.borderColor = "red";
    subContainer.style.borderColor = GraphicViewport._inactiveBorderColor;

    // [5] Le damos el nuevo div con los nuevos callbacks, tras quitarle los posibles previos...
    if (vp.elemHTML) {
      vp.removeIntroOutroCallbacks2Div(vp.elemHTML);
    }

    vp.elemHTML = subContainer;
    // Hemos cambiado el div principal del vp, asi que quedaria hacer que los otros div's (titulo+new+close) tambien se
    // incorporaran al nuevo div.
    if (vp.titleHTML !== null && vp.newHTML !== null && vp.closeHTML !== null) {
      if (vp.titleHTML !== vp.elemHTML.appendChild(vp.titleHTML)) {
        debugger;
      }
      if (vp.newHTML !== vp.elemHTML.appendChild(vp.newHTML)) {
        debugger;
      }
      if (vp.closeHTML !== vp.elemHTML.appendChild(vp.closeHTML)) {
        debugger;
      }
    } else {
      // Esto es perfectamente valido cuando se crea un nuevo VP mitad.
      vp.createElementsHTML();
    }

    vp.addIntroOutroCallbacks2Div(vp.elemHTML);

    // Lo ponemos antes de la posible creacion del gyzmo...
    this.updateViewport4Div(vp);

    // [6] Si no hubiera gizmo lo creamos.
    if (vp.gizmo === null) {
      vp.gizmo = new XYZmo_RCOH8(vp, true);
    }

    // Si no se dan coordenadas para el gizmo las ponemos nosotros separandolo de la esquina superior derecha segun las dimensiones dadas.
    if (-1 === x) {
      x = vp.widthPx - dimXY;
    }
    if (-1 === y) {
      y = 0;
    }
    console.log("\tGizmo en (" + x + ", " + y + ")");
    vp.gizmo.init(x, y, dimXY, vp.camera as THREE.Camera, vp.elemHTML);
    if (setGizmoCallback) {
      vp.gizmo.subscribeMouseEvents();
    }
  } // public simplyConfigureViewport(vp: GraphicViewport, dimXY: number, x: number = -1, y: number = -1, fatherDiv: HTMLDivElement, isVertical: boolean, xyTPC: number, whTPC: number, setGizmoCallback: boolean = true): void

  /**
   * Efectua el resto de la configuracion necesaria para el viewport dado, incorporandole un DIV interno y el gizmo en las
   * coordenadas dadas con esa dimension de cuadro.
   * ATENCION: Necesita una camara previamente establecida en el VP para establecer su coordinacion con el gizmo.
   * Ojito: Es aqui donde se crea el div asociado al vp, y le podemos dar o no un callback para cuando se hace dobleClick en el.
   *
   * @param {GraphicViewport} vp
   * @param {number} dimXY
   * @param {number} [x=-1]
   * @param {number} [y=-1]
   * @param {HTMLDivElement} [fatherDiv]
   * @param {boolean} [setGizmoCallback=true]
   * @returns {void}
   * @memberof GraphicViewportManager
   */
  public configureViewport_DEL(vp: GraphicViewport, dimXY: number, x: number = -1, y: number = -1, fatherDiv?: HTMLDivElement, setGizmoCallback: boolean = true): void {

    if (!vp.camera) {
      console.error(`ERROR: El viewport "${vp.name}" NO tiene asignada camara.`);
      return;
    }

    // [1] Creamos el subcontainer del canvas principal.
    const subContainer = document.createElement("div");

    // Necesitare el container DOM del renderer (de tipo HTMLCanvasElement) para crear el subElemento HTML.
    // Ojo que this._container es de tipo HTMLDivElement.
    // ANTES ERA ASI:    
    // const canvasCntnr = this._ownerGPO._renderer.domElement;
    // [2] Se agrega al padre del canvas, no al propio canvas.
    // (canvasCntnr.parentElement as HTMLElement).appendChild(subContainer);
    // [3] Se posiciona con coordenadas absolutas referenciandolo con offsets de su "padre-hermano" canvas...
    // Estas son las dimensiones para el VP en pixeles respecto a su container.
    // const [xVP, yVP, wVP, hVP] = vp.getXYWH4ViewportInDivCanvasElement(canvasCntnr);
    // AHORA LO HACEMOS DIFERENTE: Apunta a su HTMLDivElement padre.
    if (!fatherDiv) {
      fatherDiv = this._ownerGPO.container;
    }

    fatherDiv.appendChild(subContainer);
    let [xVP, yVP, wVP, hVP] = vp.getXYWH4ViewportInHTMLDivElement(this._ownerGPO.container);

    if (true) {
      // A veces pasa que perdemos un pixel por una division de un numero impar. Lo corregimos aqui.
      const r = fatherDiv.getBoundingClientRect();
      const w = r.width;
      const h = r.height;
      if (r.right - (xVP + wVP) === 1) {
        console.info("Correccion de un pixel de anchura [" + wVP + "] para adaptarse al padre [" + w + "].");
        ++wVP;
      }
      if (r.bottom - (yVP + hVP) === 1) {
        console.info("Correccion de un pixel de altura [" + hVP + "] para adaptarse al padre [" + h + "].");
        ++hVP;
      }
    }

    // Esos pixels absolutos (sobre GPO) dados por [xVP, yVP, wVP, hVP] los relativizamos respecto al padre local.
    const [left100, top100, width100, height100] = GraphicViewportManager.getPercentages(xVP, yVP, wVP, hVP, fatherDiv);

    // Damos los valores en pixels, ya que los 01 los tenemos y los aplicamos a las dimensiones totales del canvas.
    vp.leftPx = xVP;
    vp.topPx = yVP;
    vp.widthPx = wVP;
    vp.heightPx = hVP;

    subContainer.style.left = left100 + "%";
    subContainer.style.top = top100 + "%";
    subContainer.style.width = width100 + "%";
    subContainer.style.height = height100 + "%";
    subContainer.style.position = "absolute";

    // [4] Al div le damos un borde.
    subContainer.style.borderWidth = "2px";
    subContainer.style.borderStyle = "solid";
    // Para depurar lo ponemos rojo, pero en la vida real lo dejamos color desactivado.
    // subContainer.style.borderColor = "red";
    subContainer.style.borderColor = GraphicViewport._inactiveBorderColor;

    if (true) {
      // Comprobacion psicopatica que desaparecera...
      // Comparamos los valores en pixels con los obtenidos del rect.
      const r = subContainer.getBoundingClientRect();
      if (r.left !== vp.leftPx) {
        console.error("Divergencia left: " + r.left + " vs " + vp.leftPx);
      }
      if (r.top !== vp.topPx) {
        console.error("Divergencia top: " + r.top + " vs " + vp.topPx);
      }
      if (r.width !== vp.widthPx) {
        console.error("Divergencia width: " + r.width + " vs " + vp.widthPx);
      }
      if (r.height !== vp.heightPx) {
        console.error("Divergencia height: " + r.height + " vs " + vp.heightPx);
      }
    }

    // if (false) {
    //   // Intento de resize. Funcionaria, pero no va bien.
    //   // \ToDo: Quizas con las nuevas incorporaciones pueda funcionar ahora correctamente???.
    //   subContainer.style.resize = "both";
    //   subContainer.style.overflow = "auto";
    // }
    // [5] Damos un container con sus callbacks...
    if (vp.elemHTML) {
      vp.removeIntroOutroCallbacks2Div(vp.elemHTML);
    }
    vp.elemHTML = subContainer;
    vp.addIntroOutroCallbacks2Div(vp.elemHTML);

    // [6] Si no hubiera gizmo lo creamos.
    if (vp.gizmo === null) {
      vp.gizmo = new XYZmo_RCOH8(vp, true);

      // No queremos callback para el gizmo, para su parte axial, pero ademas queremos admitir que se destruya el vp con el boton "X".
      if (!setGizmoCallback) {
        if (vp.gizmo.setNonClickableAxes()) {
          setGizmoCallback = true;
        } else {
          window.alert("Que mi madre es esto???");
          debugger;
        }
      }
    }

    // Si no se dan coordenadas para el gizmo las ponemos nosotros separandolo de la esquina superior derecha segun las dimensiones dadas.
    if (-1 === x) {
      // Esto habia antes que no podia ser correcto.
      // x = wVP - dimXY;
      x = xVP + wVP - dimXY;
      // Vuelvo a lo anterior, pues la correccion va peor???...
      x = wVP - dimXY;
    }
    if (-1 === y) {
      y = 0;
    }
    console.log("\tGizmo en (" + x + ", " + y + ")");
    vp.gizmo.init(x, y, dimXY, vp.camera, vp.elemHTML);
    if (setGizmoCallback) {
      vp.gizmo.subscribeMouseEvents();
    }

  } // public configureViewport_DEL(vp: GraphicViewport, dimXY: number, x: number = -1, y: number = -1, fatherDiv?: HTMLDivElement, setGizmoCallback: boolean = true): void


  /**
   * Incorpora un viewport plenamente configurado desde el exterior.
   * En caso de error se devuelve false. OJO: Ya debe traer previamente la camara, sobre la que creariamos el control.
   *
   * @param {GraphicViewport} viewport
   * @param {boolean} [createControl=true]
   * @returns {boolean}
   * @memberof GraphicViewportManager
   */
  public addViewport(viewport: GraphicViewport, createControl: boolean = true): boolean {
    // Hay 4 cosas que no admitimos:
    // Nombre vacio o repetido.
    // Que se nos de algo con enabled a true o bien que no haya camara, o bien que haya camControl predefinido.
    // El camControl (si lo hubiere) lo debemos generar nosotros para engancharlo a su generador de eventos...
    if (viewport.name === "" || viewport.enabled || !viewport.camera || viewport.controls) {
      return false;
    }

    // Tampoco permitire el nombre "mainView" que esta reservado para la vista principal, que aunque tecnicamente
    // no es un viewport, de cara al exterior se comportaria como tal.
    if (viewport.name === "mainView") {
      return false;
    }

    for (let i = 0; i < this._vViewports.length; ++i) {
      if (viewport.name === this._vViewports[i].name) {
        console.error("\tViewport repetido: " + viewport.name);
        return false;
      }
    }

    console.log(" +--------------------------------+");
    console.log(" + VP: " + viewport.name);
    console.log(" +--------------------------------+");

    // De momento no comprobamos si las variables del RECT son correctas.
    // Si se da elemento HTML, sacamos las dimensiones del mismo.
    // Ojo, que el true indica que es la primera vez que se esta asignando.
    if (viewport.elemHTML && viewport.elemHTML !== this._ownerGPO._container) {
      this.resizeViewport(viewport, true);
    } else {
      this.resizeViewport(viewport, true);
    }

    // Creamos el control de movimiento.
    if (createControl) {
      if (viewport.elemHTML) {
        viewport.controls = this.owner.createCameraControls(viewport.camera as THREE.PerspectiveCamera | THREE.OrthographicCamera, viewport.elemHTML);
      } else {
        viewport.controls = this.owner.createCameraControls(viewport.camera as THREE.PerspectiveCamera | THREE.OrthographicCamera, this._ownerGPO._renderer.domElement);
      }
      setMouseControls(viewport.controls, false);

      // Conexion al teclado.
      this.setKeyboardControls(viewport);

      // Almacenamos el estado actual de su camara para poder volver a el mas adelante por la cosa ETabs. Funciona!!!.
      // Pero solo lo hacemos INMEDIATAMENTE DESPUES DE LA creacion del control.
      viewport.storeCameraStatus();
    }

    // Y si no hay color lo ponemos rosa de momento por tocar los cojones...
    if (!viewport.backgroundColor) {
      viewport.backgroundColor = new THREE.Color("pink");
    }

    // Con esto ya esta listo para funcionar.
    viewport.enabled = true;

    this._vViewports.push(viewport);

    return true;
  }

  /**
   * Coloca al viewport dado en la lista de los que van a morir, en la destruccion diferida.
   * En caso de que no se le pueda colocar en la death row se devuelve false.
   *
   * @param {GraphicViewport} vp
   * @returns {boolean}
   * @memberof GraphicViewportManager
   */
  public addViewport2Delete(vp: GraphicViewport): boolean {
    const posSrc = this._vViewports.indexOf(vp);
    if (-1 === posSrc) {
      window.alert("ERROR: El viewport que se quiere destruir no esta entre los vivos...");
      return false;
    }
    const posDst = this._vViewports2Delete.indexOf(vp);
    if (-1 !== posDst) {
      window.alert("ERROR: El viewport que se quiere destruir ya esta entre los destruibles...");
      return false;
    }
    // Si solo quedase uno no permito su borrado.
    if (1 === this._vViewports.length) {
      window.alert("ERROR: El viewport que se quiere destruir es el ultimo y no lo permitire!!!.");
      return false;
    }

    // Lo colocamos en la death row esperando su ejecucion, que no haremos hasta acabar el siguiente rendering.
    // De esa forma separamos la destruccion del callback que nos ha traido hasta aqui y evitamos problemas.
    this._vViewports2Delete.push(vp);
    return true;
  }

  /**
   * Recorre todos los divs registrados disponibles y nos devuelve las claves correspondientes a aquellos valores que
   * incluyen al div dado.
   *
   * @private
   * @param {HTMLDivElement} div
   * @returns {HTMLDivElement[]}
   * @memberof GraphicViewportManager
   */
  private getKeyDivs4AssociatedDivs(div: HTMLDivElement): HTMLDivElement[] {
    const vResult: HTMLDivElement[] = [];

    for (const [key, value] of this._divTree3) {
      if (value.sons?.leftSon === div) {
        vResult.push(key);
        continue;
      }
      if (value.sons?.divisionSon === div) {
        vResult.push(key);
        continue;
      }
      if (value.sons?.rightSon === div) {
        vResult.push(key);
        continue;
      }
    }
    return vResult;
  }

  /**
   * Entre los N viewports disponibles (no se cuenta al mainViewport), si alguno de los existentes tiene al div dado internamente
   * lo devolvemos. En caso contrario se devuelve null.
   *
   * @param {HTMLDivElement} div
   * @returns {(GraphicViewport | null)}
   * @memberof GraphicViewportManager
   */
  public getViewport4Div(div: HTMLDivElement): GraphicViewport | null {
    const numVPs = this._vViewports.length;
    for (let i = 0; i < numVPs; ++i) {
      const vp = this._vViewports[i];
      if (vp.elemHTML === div) {
        return vp;
      }
    }

    return null;
  }

  /**
   * Tenemos un div origen que nos aporta unas dimensiones que vamos a asignar en el div destino.
   *
   * @static
   * @param {HTMLDivElement} srcDiv
   * @param {HTMLDivElement} dstDiv
   * @memberof GraphicViewportManager
   */
  public static resizeDivTo(srcDiv: HTMLDivElement, dstDiv: HTMLDivElement): void {
    // Este parametro es de tipo DOMRect.
    const r = srcDiv.getBoundingClientRect();

    dstDiv.style.left = r.left + "px";
    dstDiv.style.top = r.top + "px";
    dstDiv.style.width = r.width + "px";
    dstDiv.style.height = r.height + "px";
    // Estas no lo tengo claro si son opcionales.
    dstDiv.style.right = r.right + "px";
    dstDiv.style.bottom = r.bottom + "px";
    // Hara falta algo mas???.
  }

  /**
   * Relocaliza la camara destino a partir de la posicion y orientacion de la camara origen.
   * PERO como no ha habido forma humana de que funcionase con las camaras lo intentamos con
   * sus controles asociados. TAMPOCO FUNCIONA.
   *
   * @static
   * @param {CameraControls} srcCamCtrls
   * @param {CameraControls} dstCamCtrls
   * @memberof GraphicViewportManager
   */
  public static relocateCameraTo(srcCamCtrls: CameraControls, dstCamCtrls: CameraControls): void {

    const srcCfg = srcCamCtrls.toJSON();
    dstCamCtrls.fromJSON(srcCfg);

    // // Nada de lo intentado, mucho, ha valido, asi que lo delegamos a los cameraControls...
    // // La signatura anterior era public static relocateCameraTo(srcCam: THREE.Camera, dstCam: THREE.Camera): void
    // const pos = new THREE.Vector3();
    // srcCam.getWorldPosition(pos);
    // dstCam.position.set(pos.x, pos.y, pos.z);
    // return;
    // // Sera el control el que toca los cojones???...
    // // No funciona, sacado de https://stackoverflow.com/questions/49201438/threejs-apply-properties-from-one-camera-to-another-camera
    // srcCam.matrixAutoUpdate = false;
    // srcCam.updateMatrixWorld(true);
    // const mtrx = new THREE.Matrix4();
    // mtrx.copy(srcCam.matrixWorld);
    // dstCam.matrixWorld.copy(mtrx);
    // const d = new THREE.Vector3();
    // const q = new THREE.Quaternion();
    // const s = new THREE.Vector3();
    // dstCam.matrixWorld.decompose(d, q, s);
    // dstCam.position.copy(d);
    // dstCam.quaternion.copy(q);
    // dstCam.scale.copy(s);
    // return;
    // const name = dstCam.name;
    // dstCam.copy(srcCam);
    // // Y le devolvemos el nombre original.
    // dstCam.name = name;
    // debugger;
    // return;
    // const x = srcCam.position.x;
    // const y = srcCam.position.y;
    // const z = srcCam.position.z;
    // dstCam.position.set(x, y, z);
    // // dstCam.quaternion.copy(srcCam.quaternion);
    // dstCam.rotation.copy(srcCam.rotation);
    // // dstCam.updateMatrixWorld();
    // // Quizas haya que mover tambien el gizmo???...
  }

  /**
   * Comparador de igualdad de rect's, informando en consola de las desavenencias.
   *
   * @static
   * @param {DOMRect} r1
   * @param {DOMRect} r2
   * @returns {boolean}
   * @memberof GraphicViewportManager
   */
  public static areEqualRects(r1: DOMRect, r2: DOMRect): boolean {
    const equalLeft = (r1.left === r2.left);
    const equalTop = (r1.top === r2.top);
    const equalRight = (r1.right === r2.right);
    const equalBottom = (r1.bottom === r2.bottom);
    const equalWidth = (r1.width === r2.width);
    const equalHeight = (r1.height === r2.height);

    if (equalLeft && equalTop && equalRight && equalBottom && equalWidth && equalHeight) {
      return true;
    }

    if (!equalLeft) {
      console.error("Divergencia left: " + r1.left + " vs " + r2.left);
    }
    if (!equalTop) {
      console.error("Divergencia top: " + r1.top + " vs " + r2.top);
    }
    if (!equalRight) {
      console.error("Divergencia right: " + r1.right + " vs " + r2.right);
    }
    if (!equalBottom) {
      console.error("Divergencia bottom: " + r1.bottom + " vs " + r2.bottom);
    }

    if (!equalWidth) {
      console.error("Divergencia width: " + r1.width + " vs " + r2.width);
    }
    if (!equalHeight) {
      console.error("Divergencia height: " + r1.height + " vs " + r2.height);
    }
    return false;
  }

  /**
   * \ToDo: BORRARLA!!!.
   * El div dado lo coloca al 100% teoricamente de lo que marca su padre.
   * Pero ademas devuelve las dimensiones anteriores, para que puedan ser reutilizadas mas adelante.
   *
   * @private
   * @static
   * @param {HTMLDivElement} div
   * @returns {[string, string, string, string]}
   * @memberof GraphicViewportManager
   */
  private static setDivTo100(div: HTMLDivElement): [string, string, string, string] {
    const prevLeft = div.style.left;
    const prevTop = div.style.top;
    const prevWidth = div.style.width;
    const prevHeight = div.style.height;

    div.style.left = "0%";
    div.style.top = "0%";
    div.style.width = "100%";
    div.style.height = "100%";

    // A lo mejor hay que volverselo a recordar???.
    div.style.position = "absolute";
    // Le damos color inactivo, pues el raton estaria fuera...
    div.style.borderColor = "red";
    div.style.borderColor = GraphicViewport._inactiveBorderColor;

    if (true) {
      // Comprobacion psicopatica que desaparecera.
      // Se supone que deberia ocupar exactamente el mismo espacio que su puto padre. Aseguremonos de ello.
      const parent = div.parentElement as HTMLDivElement;
      console.log("Div '" + div.id + "' vs parent '" + parent.id + "'...");
      const dRct = div.getBoundingClientRect();
      const pRct = parent.getBoundingClientRect();
      if (!GraphicViewportManager.areEqualRects(dRct, pRct)) {
        console.error("El div y su padre difieren en dimensiones!!!.");
      }
    }

    return [prevLeft, prevTop, prevWidth, prevHeight];
  }

  /**
   * Destruccion diferida de los viewports marcados para morir con posible recuperacion de area y reintegro a sus dueños
   * originales, si es que ello es posible. Es diferida ya que debe ser ejecutada justo despues de acabar todos los rendering.
   */
  public deferredViewportsDestruction(): void {

    this.debug(" BEFORE VP DELETION!!!");

    // Normalmente solo se borraran de uno en uno, pero quien sabe mas adelante...
    const numMorituri = this._vViewports2Delete.length;

    for (let n = 0; n < numMorituri; ++n) {
      // [1] Localizar el vp + div que vamos a eliminar.
      // Este es el vp que vamos a eliminar y su div asociado.
      let vpoSrc2Delete = this._vViewports2Delete[n];
      let divSrc2Delete = vpoSrc2Delete.elemHTML;

      // Lo primero para evitar problemas: Quitarle al morituri sus keystrokes lo antes posible.
      vpoSrc2Delete.removeKeystrokesCallbacks();

      console.log(`\nDESTROY VP "${vpoSrc2Delete.name}" + DIV "${divSrc2Delete.id}" [parent <${divSrc2Delete.parentElement ? divSrc2Delete.parentElement.id : "NONE"}>]`);

      // [2] Eliminacion del VP en su almacenamiento.
      const posVpo2Delete = this._vViewports.indexOf(vpoSrc2Delete);
      this._vViewports.splice(posVpo2Delete, 1);

      // [3] Obtener el div/divs que contienen al eliminable. Creo que solo debiera ser EXACTAMENTE uno solo.
      const vDivsKeys = this.getKeyDivs4AssociatedDivs(divSrc2Delete);
      const numKeys = vDivsKeys.length;
      if (numKeys !== 1) {
        debugger;
      } else {
        // [4] Efectivamente solo hay un div paterno que contiene al destruible.
        // Obtenemos sus datos para buscar un div "hermano" que agrandar que aproveche el hueco que queda.
        let divDad = vDivsKeys[0];
        console.error(` =======================>>> KEYNODE FATHER DIV: "${divDad.id}".`);

        let isRoot: boolean = false;
        if (divDad === this.owner.container) {
            console.error("WARNING: Deletion at root level!!!.");
            isRoot = true;
            // Ojo, que esto puede pasar no solo en el caso en que se borra a nivel de root pero sigue quedando algo que
            // tiene una division, sino TAMBIEN cuando no queda division alguna.
            if (this._divTree3.size === 1) {
              console.error("WARNING: Deletion with only one VP remaining and NO subdivisions.");
            }
        }

        // Solo hay que fundir a divSrc2Delete y a su "hermano" superviviente, borrar a divSrc2Delete (y su vp), al
        // separador y reasignar el div superviviente al div paterno.
        const infoValue = this._divTree3.get(divDad) as Info4Div;
        const infoSons = infoValue.sons as Info4Sons;
        const divGranpa = infoValue.father as HTMLDivElement;
        const divSlider = infoSons.divisionSon as HTMLDivElement;

        if (infoValue.father) {
          if (divGranpa === divDad) {
            debugger;
          }
        } else {
          // Es el raiz de nuestro arbol.
          if (divDad === this._ownerGPO.container) {
            console.log("El padre es el nodo raiz.");
          } else {
            debugger;
          }
        }

        // [5] Destruimos slider bisagra entre el VP destruible y su div hermano.
        divSlider.removeEventListener("pointerdown", infoSons.downCallback);
        this.removeIntroOutroCallbacks2SliderDiv(divSlider);
        divSlider.remove();
        infoSons.divisionSon = (undefined as unknown) as HTMLDivElement;
        infoSons.downCallback = (undefined as unknown) as (event: PointerEvent) => void;

        // [6] Sacamos al div hermano que sera ampliado, que internamente podria tener un vp o mas...
        // Si borramos al izquierdo el que queda es el derecho, o al reves (tambien para arriba y abajo).
        let divBro2Resize = (infoSons.leftSon === divSrc2Delete) ? infoSons.rightSon : infoSons.leftSon;

        // Del div hermano superviviente intentamos sacar el VP asociado, si es que lo hay.
        // Podria no haber un unico VP, en cuyo caso el div hermano contendra varios VP's y subDiv's.
        const vpoBro2Resize = this.getViewport4Div(divBro2Resize);
        if (vpoBro2Resize) {
          console.log(`BROTHER VP to resize "${vpoBro2Resize.name}" + DIV "${divBro2Resize.id}" [parent <${divBro2Resize.parentElement ? divBro2Resize.parentElement.id : "NONE"}>]`);

          // Aqui va el codigo nuevo:
          // Hemos quitado un VP y toca aumentar el otro (el bro) al tamaño total ocupado por ambos, que es el
          // del padre dadDiv, pero en realidad lo mas correcto parece reasignar al vp bro el div padre, que
          // ya tiene el tamaño. Habria que cambiar el control conservando la camara y ciertos parametros...
          vpoBro2Resize.readjust2NewDiv(divDad);
          // if (false) {
          //   // Prueba experimental.
          //   console.log("Esto?????????????????????????????????????????");
          //   this.updateViewport4Div(vpoBro2Resize);
          // }
        } else {
          // Pero cuando borramos un vp que NO tiene un "hermano" vp por asi decirlo, sino que tiene como hermano a un div que
          // es padre de otros 2 o mas vp's entonces lo que tenemos que hacer es ampliar a ese div hermano sobreviviente para que
          // tome el espacio del borrado. La pregunta aqui es si se hace automaticamente al destruir al original???. NO!!!.
          divBro2Resize = infoSons.leftSon === divSrc2Delete ? infoSons.rightSon : infoSons.leftSon;
          console.log(`\tDiv to resize "${divBro2Resize.id}"`);

          if (isRoot) {
            // Obviamente no podemos borrar la raiz.
          } else {
            this._divTree3.delete(divDad);
          }
          vpoSrc2Delete.destroyViewport();
          vpoSrc2Delete = (undefined as unknown) as GraphicViewport;

          // Destruimos el div que toca a ver si el hermano hace un resize.
          // Algun callback que quitar antes de la destruccion???.
          divSrc2Delete.remove();
          divSrc2Delete = (undefined as unknown) as HTMLDivElement;

          // Por otro lado hay que modificar al unico abuelo arboreo cambiandole el nodo que hemos podado por
          // el hermano superviviente, y modificar el separador para que tenga en cuenta al nuevo.
          const abuelos = this.getKeyDivs4AssociatedDivs(divDad);
          if (abuelos.length !== 1) {
            if (isRoot && 0 === abuelos.length) {
              // En este caso simplemente hay que quitar los 3 descendientes del hermano (es decir leftSon,
              // rightSon mas divisionSon) y colgarlos del x-root.
              const theRoot = this.owner.container;
              const infoValueDst = this._divTree3.get(theRoot) as Info4Div;
              const infoSonsDst = infoValueDst.sons as Info4Sons;

              // Quitaremos esos 3 hijos del root.
              let prevLeftSon = infoSonsDst.leftSon;
              let prevRightSon = infoSonsDst.rightSon;
              prevLeftSon.remove();
              prevRightSon.remove();

              if (!this._divTree3.has(divBro2Resize)) {
                debugger;
              }

              const infoValueSrc = this._divTree3.get(divBro2Resize) as Info4Div;
              const infoSonsSrc = infoValueSrc.sons as Info4Sons;

              this._divTree3.delete(divBro2Resize);

              // Reasignacion.
              infoSonsDst.leftSon = infoSonsSrc.leftSon;
              infoSonsDst.rightSon = infoSonsSrc.rightSon;
              infoSonsDst.divisionSon = infoSonsSrc.divisionSon;

              theRoot.appendChild(infoSonsDst.leftSon);
              theRoot.appendChild(infoSonsDst.rightSon);
              theRoot.appendChild(infoSonsDst.divisionSon);

              // Queda ajustar mas padres...
              if (this._divTree3.has(infoSonsDst.leftSon)) {
                const infoValue = this._divTree3.get(infoSonsDst.leftSon) as Info4Div;
                infoValue.father = theRoot;
              }
              if (this._divTree3.has(infoSonsDst.rightSon)) {
                const infoValue = this._divTree3.get(infoSonsDst.rightSon) as Info4Div;
                infoValue.father = theRoot;
              }

              this.updateContainedViewports4Div(theRoot);

              const theFirstVP = this._vViewports[0];
              this.setActiveViewport(theFirstVP);
              theFirstVP.graphicActivation();

              debugger;
              continue;
            } else {
              debugger;
            }
          } else {
            const theAbuelo = abuelos[0];
            // No solo basta con modificar las entradas, sino que habra que hacer una reconexion de div's.
            const infoValue = this._divTree3.get(theAbuelo) as Info4Div;
            const infoSons = infoValue.sons as Info4Sons;

            // Por cojones tenemos al nodo a sustituir como hijo izquierdo EXOR hijo derecho.
            if (infoSons.leftSon === divDad && infoSons.rightSon !== divDad) {
              // Hay que sustituir al izquierdo.
              infoSons.leftSon = divBro2Resize;
            } else {
              if (infoSons.leftSon !== divDad && infoSons.rightSon === divDad) {
                // Hay que sustituir al derecho.
                infoSons.rightSon = divBro2Resize;
              } else {
                // Nunca se debiera llegar aqui...
                debugger;
              }
            }

            theAbuelo.appendChild(divBro2Resize);
            // Creo que hay que dar al bro las dimensiones del dad.
            divBro2Resize.style.left = divDad.style.left;
            divBro2Resize.style.top = divDad.style.top;
            divBro2Resize.style.width = divDad.style.width;
            divBro2Resize.style.height = divDad.style.height;
            divBro2Resize.style.position = divDad.style.position;
          }

          // Machacamos al div anterior. Quizas se haga en otro sitio...
          divDad.remove();
          divDad = (undefined as unknown) as HTMLDivElement;

          // Esto es lo que falta para asegurar el resize de todos los vp's contenidos en la parte sobreviviente.
          this.updateContainedViewports4Div(divBro2Resize);

          // Aqui hay que poner a alguno de los vp's disponibles como el ACTIVO.
          // Intentare poner al primero que cuelgue de esta nueva parte, aunque tambien podria directamente
          // poner al ultimo de la fila...
          const theNextVP = this.getViewport4Div(divBro2Resize);
          if (theNextVP) {
            this.setActiveViewport(theNextVP);
            theNextVP.graphicActivation();
          } else {
            const theLastVP = this._vViewports[this._vViewports.length - 1];
            this.setActiveViewport(theLastVP);
            theLastVP.graphicActivation();
          }

          continue;
        }

        // Eliminamos la entrada de nuestra tabla, pero solo si no es el div supremo.
        if (divDad.id !== "viewport") {
          // Duda: Debiera borrarlo si y solo si sus datos internos estan acabados???...
          this._divTree3.delete(divDad);
        } else {
          if (this._divTree3.size === 1) {
            this._divTree3.delete(divDad);
          }
        }

        // Destruccion total del vp morituri.
        vpoSrc2Delete.destroyViewport();
        vpoSrc2Delete = (undefined as unknown) as GraphicViewport;

        if (divSrc2Delete) {
          divSrc2Delete.remove();
          divSrc2Delete = (undefined as unknown) as HTMLDivElement;
        }

        // Y colocamos como activo al vp que acabamos de redimensionar.
        // this._ownerGPO.setActiveViewport(vpoBro2Resize as GraphicViewport);
        this.setActiveViewport(vpoBro2Resize as GraphicViewport);
        vpoBro2Resize.graphicActivation();
        vpoBro2Resize.setInfo("After deletion...");
      }

      this.debug(" TRAS LA DESTRUCCION...");

    } // for (let n = 0; n < numMorituri; ++n)


    // Todos estan muertos.
    this._vViewports2Delete.length = 0;

    if (this._vViewports.length === 1 && this._ownerGPO._isGreyStrucMode) {
      // En este modo NO vamos a permitir que se borre el unico vp que queda. Asi que habria que quitarle la "X".
      const onlyVP = this._vViewports[0];

      return;
    }

    if (this._vViewports.length === 1) {
      // Estamos en el caso de que solo queda un vp (el ultimo ampliado tras la reciente ultima fusion)
      // y debemos destruirlo y volver a colocar el mainView. Pero ojo, que tambien habria que posicionar
      // la camara en curso de mainView en la misma posicion que este ultimo VP para que se vea lo mismo.
      let otherVP = this._vViewports[0];

      // Vamos a dejar solo el mainView y lo activamos para disponer de su camara.
      this._ownerGPO.setActiveViewport(this._ownerGPO._mainViewport);
      if (!this._ownerGPO._onlyMainView) {
        this._ownerGPO._onlyMainView = true;
      } else {
        debugger;
      }
      if (!this._ownerGPO.renderMainView()) {
        this._ownerGPO.renderMainView(true);
      } else {
        debugger;
      }

      if (true) {
        // Reasignacion de la camara principal: en la misma posicion y mirando al mismo sitio que en el vp.
        const dstCam = this._ownerGPO.getCursorCamera();
        const srcCam = otherVP.camera as THREE.Camera;
        if (dstCam !== srcCam) {
          const srcCamCtrls = this._ownerGPO.getActiveViewport().controls as CameraControls;
          const dstCamCtrls = otherVP.controls as CameraControls;
          console.log("Relocate main camera to last viewport position.");
          GraphicViewportManager.relocateCameraTo(srcCamCtrls, dstCamCtrls);
        }
        // if ((dstCam as THREE.PerspectiveCamera).isPerspectiveCamera === true) {
        //   (dstCam as THREE.PerspectiveCamera).updateProjectionMatrix();
        // } else {
        //   (dstCam as THREE.OrthographicCamera).updateProjectionMatrix();
        // }
      }
      otherVP.destroyViewport();
      otherVP = (undefined as unknown) as GraphicViewport;
      this._vViewports.length = 0;
      this._divTree3.clear();
    }
  }

  /**
   * Obtiene el div "hermano puro" asi como el div slider (y en ese orden) asociados al div dado.
   * @param div
   */
  private getBrotherDivs(div: HTMLDivElement): [HTMLDivElement, HTMLDivElement] {
    // Eso se busca desde el padre.
    const divParent = div.parentElement as HTMLDivElement;
    const res = this.getDivsABC(divParent);
    if (!res) {
      console.error("ERROR: No tenemos registrado al div padre???.");
      debugger;
    }

    const [divSonA, divSonB, divSlider] = res as [HTMLDivElement, HTMLDivElement, HTMLDivElement, boolean];
    const divBrother = (div === divSonA) ? divSonB : divSonA;
    return [divBrother, divSlider];
  }

  private getUncleDivs(div: HTMLDivElement): [HTMLDivElement, HTMLDivElement] | null {
    // El padre del div dado.
    const divParent = div.parentElement as HTMLDivElement;
    // El abuelo del div dado.
    const divGranpa = divParent.parentElement as HTMLDivElement;

    // Sacamos la info almacenada para el abuelo, de donde obtendremos el div tio que queremos reajustar.
    const result = this.getDivsABC(divGranpa);
    if (!result) {
      console.error("ERROR: No tenemos registrado al div abuelo???.");
      debugger;
      return null;
    } else {
      // Sacamos al div tio, como el hijo del abuelo que no es el padre.
      const [divSonA, divSonB, divSlider] = result as [HTMLDivElement, HTMLDivElement, HTMLDivElement, boolean];
      const divUncle = (divParent === divSonA) ? divSonB : divSonA;
      return [divUncle, divSlider];
    }
  }

  private getAssociatedDivs4Readjusting(div: HTMLDivElement): [HTMLDivElement, HTMLDivElement] | null {
    const [divBrother, divSlider] = this.getBrotherDivs(div);
    if (!divBrother.hidden && !divSlider.hidden) {
      // Esos 2 divs estan visibles, luego los podemos devolver sin mas por ser los que habria que retocar.
      return [divBrother, divSlider];
    } else {
      if (divBrother.hidden && divSlider.hidden) {
        // Esos 2 divs hermanos asociados ya estaban ocultos por haber sido previamente ocultados en una accion anterior.
        // Asi que nos vamos a por los equivalentes "tios".
        return this.getUncleDivs(div);
      } else {
        // Error imposible.
        console.error("Los hermanos estan en un estado NO coherente.");
        debugger;
      }
    }

    return null;
  }

  private areAllSonsHidden(div: HTMLDivElement): boolean {
    const result = this.getDivsABC(div);
    if (!result) {
      console.error("ERROR: No tenemos registrado al div???.");
      debugger;
    } else {
      // Sacamos al div tio, como el hijo del abuelo que no es el padre.
      const [divSonA, divSonB, divSlider] = result as [HTMLDivElement, HTMLDivElement, HTMLDivElement, boolean];
      if (divSonA.hidden && divSonB.hidden && divSlider.hidden) {
        return true;
      }
    }

    return false;
  }

  private areAllSonsShowed(div: HTMLDivElement): boolean {
    const result = this.getDivsABC(div);
    if (!result) {
      console.error("ERROR: No tenemos registrado al div???.");
      debugger;
    } else {
      // Sacamos al div tio, como el hijo del abuelo que no es el padre.
      const [divSonA, divSonB, divSlider] = result as [HTMLDivElement, HTMLDivElement, HTMLDivElement, boolean];
      if (!divSonA.hidden && !divSonB.hidden && !divSlider.hidden) {
        return true;
      }
    }

    return false;
  }

  /**
   * Visualizador de la configuracion de TODOS los vp.
   *
   * @private
   * @memberof GraphicViewportManager
   */
  private seeCurrentConfig4AllViewports(): void {
    const cnt = this._vViewports.length;
    for (let i = 0; i < cnt; ++i) {
      const vp = this._vViewports[i];
      console.log(`[${i}] "${vp.name}" ${vp.enabled ? "ON " : "OFF"}`);
      const txtDivs = this.getDivsChain(vp.elemHTML);
      console.log("\t" + txtDivs);
    }
  }

  /**
   * Recorre el div dado y sus ancestros indicando su estado de activacion.
   * Con ello se genera una cadena que se devuelve al exterior.
   *
   * @private
   * @param {HTMLDivElement} div
   * @returns {string}
   * @memberof GraphicViewportManager
   */
  private getDivsChain(div: HTMLDivElement): string {
    let res = "";

    while (div && div.id.length) {
      res += '"' + div.id + '":' + (div.hidden ? "HIDDEN" : "SHOW");
      div = div.parentElement as HTMLDivElement;
      if (div) {
        res += " --> ";
      }
    }

    return res;
  }

  private getDivsChainSize(div: HTMLDivElement): string {
    let res = "";
    let i = 0;
    const end = this._ownerGPO._container.parentElement as HTMLDivElement;

    while (div !== end) {
      if (i) {
        res += "\n";
      }

      const r = div.getBoundingClientRect();
      res += `\t[${i}] div:"${div.id}" ${div.hidden ? "HIDDEN" : "SHOW"} [${r.left}, ${r.top}, ${r.width}, ${r.height}]`;
      ++i;
      div = div.parentElement as HTMLDivElement;
    }

    return res;
  }

  private applyDimensions2Div(div: HTMLDivElement, dims: [string, string, string, string]): void {
    const [left, top, width, height] = dims;
    div.style.left = left;
    div.style.top = top;
    div.style.width = width;
    div.style.height = height;
  }

  /**
   * Devuelve true si el vp dado efectivamente puede ser usado para ser mostrado (onOff true) u ocultado (false).
   *
   * @private
   * @param {GraphicViewport} vp
   * @param {boolean} onOff
   * @returns {boolean}
   * @memberof GraphicViewportManager
   */
  private testViewport4ShowHide(vp: GraphicViewport, onOff: boolean): boolean {
    if (-1 === this._vViewports.indexOf(vp)) {
      return false;
    }
    if (onOff) {
      // Se quiere activar, luego debe estar previamente inactivo.
      return vp.enabled === false;
    } else {
      // Al reves.
      return vp.enabled === true;
    }
  }

  /**
   * Tenemos 2 divs A y B que deberian ser la particion "exacta" de su padre C, sin fisuras, ya sea horizontal o verticalmente:
   *
   *    +-----------+          +-----+-----+
   *    |           |          |     |     |
   *    |     C     |    ===>  |  A  |  B  |
   *    |           |          |     |     |
   *    +-----------+          +-----+-----+
   *
   *    +-----------+          +-----+-----+
   *    |           |          |     A     |
   *    |     C     |    ===>  +-----------+
   *    |           |          |     B     |
   *    +-----------+          +-----+-----+
   *
   * Las mitades A y B siempre van en el orden primero:segundo, sea izquierdo:derecho en el caso vertical o bien superior:inferior
   * en el caso horizontal. Lo que comprobamos es que el ajuste de A + B a C es perfecto en cuanto a numeros enteros.
   *
   * @static
   * @param {HTMLDivElement} divA
   * @param {HTMLDivElement} divB
   * @param {HTMLDivElement} divC
   * @param {boolean} isVertical
   * @returns {boolean}
   * @memberof GraphicViewportManager
   */
  public static testFitting4DivsABC(divA: HTMLDivElement, divB: HTMLDivElement, divC: HTMLDivElement, isVertical: boolean): boolean {
    const rA = divA.getBoundingClientRect();
    const rB = divB.getBoundingClientRect();
    const rC = divC.getBoundingClientRect();

    let numErrors = 0;
    // La ESI debe coincidir entre A y C.
    if (rA.left === rC.left && rA.top === rC.top) {
      ;
    } else {
      ++numErrors;
    }
    // Y la EID debe coincidir entre B y C.
    if (rB.right === rC.right && rB.bottom === rC.bottom) {
      ;
    } else {
      ++numErrors;
    }

    if (isVertical) {
    } else {
    }
    return numErrors === 0;
  }

  /**
   * Genera un nombre de vp que no esta entre los ya registrados. En caso de existencia previa le pone como postfijo un
   * "_1", o un "_2" o lo que se necesite hasta llegar a un nombre inexistente.
   *
   * @param srcName
   * @returns
   */
  private getNewName4NewViewport(srcName: string): string {
    let found = false;
    const numVPs = this._vViewports.length;
    let index = 1;
    while (!found) {
      let i = 0;
      for (; i < numVPs; ++i) {
        if (srcName === this._vViewports[i].name) {
          console.error("WARNING: Intentando repetir viewport.");
          break;
        }
      }
      if (i === numVPs) {
        // Se ha recorrido todo hasta el final y no se ha encontrado disidencia, luego el nombre es valido.
        found = true;
      } else {
        // Hay una repeticion, asi que metemos un postfijo al nombre y seguimos buscando...
        srcName += "_" + index;
        ++index;
      }
    }
    return srcName;
  }

  private renameDivId(srcId: string, delta = +1): string {
    // Asumimos entradas de la forma { L_000, R_000, U_000, D_000 } y les incremenamos el numero final en el delta.
    let id = srcId.slice(0, 2);
    let str000 = srcId.slice(2);
    let int000 = parseInt(str000);
    int000 += delta;
    // Y ahora justificamos el numero a la forma 000 original.
    return id + int000.toString().padStart(3, '0');
  }
  
  /**
   * Dado un viewport de origen (ya plenamente funcional) se le reduce a la mitad de su espacio y en la mitad restante
   * que quedaria vacia se genera un nuevo viewport, pero por el nuevo comportamiento ETabs, se replica la camara del vp
   * original en el nuevo vp, haciendo una copia de la misma para que en el nuevo vp sea del mismo tipo y en las mismas
   * posicion y direccion de enfoque.
   * El parametro isVertical a true hace que la generacion sea VERTICAL; si es false la generacion sera HORIZONTAL:
   *
   *    +-----------+          +-----+-----+
   *    |           |          |     |     |
   *    |   srcVP   |    ===>  |srcVP|newVP|
   *    |           |          |     |     |
   *    +-----------+          +-----+-----+
   *
   *    +-----------+          +-----+-----+
   *    |           |          |   srcVP   |
   *    |   srcVP   |    ===>  +-----------+
   *    |           |          |   newVP   |
   *    +-----------+          +-----+-----+
   *
   * Si la generacion ha sido posible se devuelve el nuevo viewport, ya registrado y operacional.
   * En caso contrario se devuelve null.
   *
   * ATENCION: Cuando lo que se quiere dividir es el mainView no hacemos lo anterior, (no cogemos el mainView y lo dividimos
   * a la mitad y agregamos un nuevo vp en el espacio restante). En vez de ello lo que haremos sera crear 2 nuevos VP "hemisfericos"
   * y desactivar la mainView. Ambos hemisferios a su vez seran "mutuamente-colapsables" haciendo que vuelva a instaurarse la mainView.
   *
   * @param srcVP
   * @param isVertical
   * @returns
   */
  public subdivision4Viewport(srcVP: GraphicViewport, isVertical: boolean = true): GraphicViewport | null {

    // Resuelto el problema de repetir el viewport, es decir que nunca se podra darle el mismo nombre...
    let oldNameVP = srcVP.name;
    let nameNewVP = srcVP.name;
    nameNewVP += isVertical ? "+R" : "+D";
    nameNewVP = this.getNewName4NewViewport(nameNewVP);

    // Llegados aqui, tenemos un vp origen, srcVP, con su div original: lo que haremos sera quitarle ese div original y darle
    // uno nuevo, y creamos otro nuevo vp, newVP, con su propio div. El div original lo haremos padre de ambos divs y les
    // asignaremos mitad y mitad usando %. Ademas podria haber que modificar el registro en this._divTree3 con los cambios???.
    // Y al srcVP le cambia el div, luego debemos destruir el controlador de su camara y recrearlo con el nuevo div...
    // Llegados aqui, tenemos un vp origen srcVP (con su propio div original que llamamos srcDiv), que tendremos que
    // partir 2 mitades (horizontales o verticales), A y B, (siendo A el que retiene parte de srcVP y B el totalmente
    // nuevo):
    //
    // +-----+    +-----+             +-----------+    +-----+-----+
    // |     |    | A   |             |  srcVP    | => | A   | B   |
    // |     |    |     |             |           |    |     |     |
    // |srcVP| => +-----+             +-----------+    +-----+-----+
    // |     |    | B   |
    // |     |    |     |
    // +-----+    +-----+
    //
    // El div original srcDiv no desaparece, sino que pasa a ser el padre de los divA y divB, pero como viewport debe
    // generarse uno (A) completamente nuevo que sustituya a srcVP. No vale una solucion parcial de generar un nuevo
    // div y darselo al control de srcVP ya que internamente ese control tiene codigo que accede al antiguo div, lo que
    // impide la remocion en caliente del mismo...
    let oldSrcDivId = srcVP.elemHTML.id;

    if (srcVP.controls) {
      // Este era el bug por el que no se pintaba. Pero la putada es que al destruirlo se jode su recuperabilidad...
      srcVP.controls.enabled = false;
      // Con esto quitamos los callbacks basados en el antiguo div, que no se pueden modificar.
      srcVP.controls.dispose();
      srcVP.controls = (undefined as unknown) as CameraControls;
      // Al destruir el control hemos perdido su estado previamente salvado. La destruccion es necesaria ya que se va a
      // dar un nuevo div para el vp.
      srcVP.isStatusSaved = false;
    } else {
      debugger;
    }

    // Este se quedara como padre del antiguo y el nuevo vp (de ambos), pero habra que darle un nuevo DIV al srcVP con el tamaño correspondiente.
    const fatherDiv = srcVP.elemHTML;

    const newVP = new GraphicViewport(this);
    newVP.name = nameNewVP;

    // Mismo color que el mainView.
    if (this.owner._mainViewport.backgroundColor) {
      newVP.backgroundColor = new THREE.Color(this.owner._mainViewport.backgroundColor);
    }

    // Hasta que no se agregue no podra ser activado (ya lo hace en el constructor).
    newVP.enabled = false;
    newVP.viewPlaneType = srcVP.viewPlaneType;
    // Obviamente el nuevo tendra el mismo tipo que el viejo.
    newVP.type = srcVP.type;

    // Cuando la dimension implicada no es par perdemos un pixel, asi que hay que tenerlo en cuenta.
    const canvasAABB = fatherDiv.getBoundingClientRect();
    const widthCanvas = canvasAABB.width;
    const heightCanvas = canvasAABB.height;

    // Para no liarnos con pixels perdidos consideraremos las divisiones impares.
    let firstHalfTPC = 50;
    let secondHalfTPC = 50;

    if (isVertical) {
      if (widthCanvas % 2) {
        console.warn("Atencion: Division vertical y la anchura del container es impar: " + widthCanvas);
        firstHalfTPC = 100 * ((widthCanvas - 1) / 2) / widthCanvas;
        secondHalfTPC = 100 - firstHalfTPC;
      }
    } else {
      if (heightCanvas % 2) {
        console.warn("Atencion: Division horizontal y la altura del container es impar: " + heightCanvas);
        firstHalfTPC = 100 * ((heightCanvas - 1) / 2) / heightCanvas;
        secondHalfTPC = 100 - firstHalfTPC;
      }
    }

    // MODIFICACION ETabs: Para el nuevo VP se genera una nueva camara exactamente igual (aka mismo tipo y mismos
    // parametros) a la del vp original, ya sea ortografica o de perspectiva.
    const isPerspective = (srcVP.camera as THREE.PerspectiveCamera).isPerspectiveCamera === true;
    if (isPerspective) {
      // No tengo del todo claro que el clone() realmente duplique lo que necesitamos y ademas lo haga con deep-copies.
      newVP.camera = (srcVP.camera as THREE.PerspectiveCamera).clone();
      newVP.camera.name = "perspCam_" + newVP.name + "_clon";
      // (newVP.camera as THREE.PerspectiveCamera).aspect = 1;
    } else {
      // Esto habia inicialmente y no funcionaba.
      // newVP.camera = (srcVP.camera as THREE.OrthographicCamera).clone();
      newVP.camera = duplicateOrthographicCamera(srcVP.camera as THREE.OrthographicCamera);
      newVP.camera.name = "orthoCam_" + newVP.name + "_clon";
      // Esto habia antes.
      // const newCamera = this._ownerGPO.createPerspectiveCamera(1);
      // newCamera.name = "perspCam_" + newVP.name;
      // const oldPosition = (srcVP.camera as THREE.Camera).position;
      // newCamera.position.set(oldPosition.x, oldPosition.y, oldPosition.z);
      // newCamera.lookAt(0, 0, 0);
      // newVP.camera = newCamera;
    }

    if (true) {
      // Estos offsets son para situar a los gizmos con respecto a la esquina superior derecha del VP.
      const [offsetX, offsetY] = [undefined, 40];
      const dimXY = 128;

      // [A]: Reconfiguramos al vp original para readaptarlo a su nuevo padre en un nuevo div con dimensiones hemiples.
      this.simplyConfigureViewport(srcVP, dimXY, offsetX, offsetY, fatherDiv, isVertical, 0, firstHalfTPC, false);
      // El "nuevo" div original conserva el nombre, pero incrementado en una unidad para diferenciarlo.
      srcVP.elemHTML.id = this.renameDivId(oldSrcDivId);
      // ...pero al padre le damos un nombre mas "paterno". Hacemos que empiece por F para denotarlo como area paterna.
      if (fatherDiv.id[0] !== "F") {
        fatherDiv.id = "F" + fatherDiv.id.slice(1);
      } else {
        debugger;
      }

      // Ademas reconstruimos el control de camara para srcVP. De esta forma el autoCentrado en cursor estara correcto.
      // Hay que hacerlo justo aqui ya que se ha creado un div nuevo en srcVP.elemHTML.
      const srcCam = srcVP.camera as THREE.PerspectiveCamera | THREE.OrthographicCamera;
      srcVP.removeKeystrokesCallbacks();
      srcVP.controls = this._ownerGPO.createCameraControls(srcCam, srcVP.elemHTML);
      setMouseControls(srcVP.controls, false);
      srcVP.owner.setKeyboardControls(srcVP);

      if (false) {
        // Salvamos el status inicial del control recien creado. /DuDa aqui!!!.
        srcVP.storeCameraStatus();
      }

      // [B]: Le toca el reajuste al nuevo.
      this.simplyConfigureViewport(newVP, dimXY, offsetX, offsetY, fatherDiv, isVertical, firstHalfTPC, secondHalfTPC);
      // Que se llama igual que su hermano el original, pero con el numero incrementado para distinguirlo y posiblemente
      // con otra letra inicial distinta.
      let newDivId = srcVP.elemHTML.id;
      newDivId = this.renameDivId(newDivId);
      if (isVertical) {
        newVP.elemHTML.id = "R" + newDivId.slice(1);
      } else {
        newVP.elemHTML.id = "D" + newDivId.slice(1);
      }

      const [, , wVP, hVP] = newVP.getXYWH4ViewportInHTMLDivElement(this._ownerGPO.container);
      const aspect = wVP / hVP;
      if (isPerspective) {
        (newVP.camera as THREE.PerspectiveCamera).aspect = aspect;
        // Creo que despues de esto habria que hacer el clasico update para las camaras.
        (newVP.camera as THREE.PerspectiveCamera).updateProjectionMatrix();
      } else {
        // Antes tenia esto:
        // (newVP.camera as THREE.PerspectiveCamera).aspect = aspect;
        // Pero es que las ortoCam's no tienen aspect ratio.
        // (newVP.camera as THREE.OrthographicCamera).aspect = aspect;
      }
    }

    // Toca agregar el nuevo vp, pues el original ya estaba registrado y solo hemos cambiado su interior.
    if (this.addViewport(newVP)) {
      // Queda por crear y configurar el slider y toda la infraestructura asociada.
      const divSrcVP = srcVP.elemHTML;
      const divNewVP = newVP.elemHTML;
      this.configureSeparatorSlider4DivsAB(divSrcVP, divNewVP, isVertical, fatherDiv);
      const res = GraphicViewport.areViewportsContiguous(srcVP, newVP);
      if (!res) {
        console.error("Discontinuidad???");
      }

      // Ademas al modificar el vp original le cambiamos el nombre de X a X' y lo reflejamos.
      srcVP.name += "'";
      if (srcVP.titleHTML?.textContent) {
        srcVP.titleHTML.textContent += "'";
      }
      console.log(`\tChanged SRC viewport "${oldNameVP}" to "${srcVP.name}".`);

      // El nuevo vp ademas siempre sera puesto como el actual, y el origen ya fue desactivado.
      this.setActiveViewport(newVP);
      return newVP;
    } else {
      // Ojo que quedaria por destruir lo creado hasta el momento...
      debugger;
    }

    console.error(`ERROR: Subdivision del vp "${newVP.name}" no finalizada.`);
    return null;
  }

  /**
   * Version simplificada aprovechando los nuevos conocimientos.
   *
   * @param isVertical
   * @returns
   */
  private subdivision4MainView(isVertical: boolean = true): GraphicViewport | null {
    // Creamos 2 vp A y B (top-down/left-right segun la division empleada) con las correspondientes dimensiones "hemisfericas"
    // respecto del original mainView. Luego las configuramos y agregamos a pantalla poniendolas como activas tras desactivar
    // la visualizacion de mainView.
    // Mas adelante, al colapsar cualquiera de ellas (estando solo las 2), las eliminamos a ambas y restablecemos mainView.
    const vpA = new GraphicViewport(this);
    const vpB = new GraphicViewport(this);

    // Mismo color que su "padre".
    if (this._ownerGPO._mainViewport.backgroundColor) {
      vpA.backgroundColor = new THREE.Color(this._ownerGPO._mainViewport.backgroundColor);
      vpB.backgroundColor = new THREE.Color(this._ownerGPO._mainViewport.backgroundColor);
    }

    if (isVertical) {
      //                                      +-------+-------+
      vpA.name = "LEFT"; //                   | LEFT  | RIGHT |
      vpB.name = "RIGHT"; //                  +-------+-------+
      vpA.left01 = 0.0;
      vpB.left01 = 0.5;
      vpA.top01 = vpB.top01 = 0.0;
      vpA.width01 = vpB.width01 = 0.5;
      vpA.height01 = vpB.height01 = 1.0;
    } else {
      vpA.name = "UP"; //                     +------+
      vpB.name = "DOWN"; //                   |  UP  |
      vpA.left01 = vpB.left01 = 0.0; //       +------+
      vpA.top01 = 0.0; //                     | DOWN |
      vpB.top01 = 0.5; //                     +------+
      vpA.width01 = vpB.width01 = 1.0;
      vpA.height01 = vpB.height01 = 0.5;
    }

    // El aspect es complicado y no se puede coger de la camara inicial.
    const canvasCntnr = this._ownerGPO._renderer.domElement;
    // Cuando la dimension implicada no es par perdemos un pixel, asi que hay que tenerlo en cuenta.
    const canvasAABB = canvasCntnr.getBoundingClientRect();
    const widthCanvas = canvasAABB.width;
    const heightCanvas = canvasAABB.height;

    if (isVertical) {
      if (widthCanvas % 2) {
        console.warn("Atencion: Division vertical y la anchura del container es impar: " + widthCanvas);
        // Corregimos los valores 01 para adaptarnos y no perder el pixel intermedio.
        vpA.width01 = Math.trunc(widthCanvas / 2) / widthCanvas;
        vpB.width01 = 1 - vpA.width01;
      }
    } else {
      if (heightCanvas % 2) {
        console.warn("Atencion: Division horizontal y la altura del container es impar: " + heightCanvas);
        vpA.height01 = Math.trunc(heightCanvas / 2) / heightCanvas;
        vpB.height01 = 1 - vpA.height01;
      }
    }

    vpA.enabled = vpB.enabled = false;
    vpA.viewPlaneType = vpB.viewPlaneType = this._ownerGPO._mainViewport.viewPlaneType;
    const isPerspective = ((this._ownerGPO._mainViewport.camera as THREE.PerspectiveCamera).isPerspectiveCamera === true);
    // Coño!!! con esto me quito el error de compilacion por variables no usadas!!!.
    const [, , wVP, hVP] = vpA.getXYWH4ViewportInHTMLDivElement(canvasCntnr);
    // Simplificamos con el mismo aspectRatio para ambas camaras.
    const aspect = wVP / hVP;

    if (isPerspective) {
      vpA.camera = (this._ownerGPO._mainViewport.camera as THREE.PerspectiveCamera).clone();
      (vpA.camera as THREE.PerspectiveCamera).aspect = aspect;
      vpB.camera = (this._ownerGPO._mainViewport.camera as THREE.PerspectiveCamera).clone();
      (vpB.camera as THREE.PerspectiveCamera).aspect = aspect;
    } else {
      const oldPosition = (this._ownerGPO._mainViewport.camera as THREE.Camera).position;
      const camA = this._ownerGPO.createPerspectiveCamera(aspect);
      camA.position.set(oldPosition.x, oldPosition.y, oldPosition.z);
      camA.lookAt(0, 0, 0);
      vpA.camera = camA;
      const camB = this._ownerGPO.createPerspectiveCamera(aspect);
      camB.position.set(oldPosition.x, oldPosition.y, oldPosition.z);
      camB.lookAt(0, 0, 0);
      vpB.camera = camB;
    }

    // Damos nombres diferenciales a ambas camaras.
    vpA.camera.name += "_" + vpA.name;
    vpB.camera.name += "_" + vpB.name;

    const dimXY = 128;

    if (true) {
      this.simplyConfigureViewport(vpA, dimXY, undefined, undefined, this._ownerGPO.container, isVertical, 0, 50);
      this.simplyConfigureViewport(vpB, dimXY, undefined, undefined, this._ownerGPO.container, isVertical, 50, 50);
    } else {
      this.configureViewport_DEL(vpA, dimXY);
      this.configureViewport_DEL(vpB, dimXY);
    }

    const divA = vpA.elemHTML;
    const divB = vpB.elemHTML;

    // De momento aprovechamos para darle el mismo nombre que a sus vp's contenedores.
    divA.id = vpA.name;
    divB.id = vpB.name;

    if (!this.addViewport(vpA)) {
      // Ojo, que quedaria destruir camara/s.
      debugger;
      return null;
    }
    if (!this.addViewport(vpB)) {
      // Ojo, que quedaria destruir camara/s.
      debugger;
      return null;
    }

    // this.resizeViewport(vpA, false);
    // this.resizeViewport(vpB, false);
    const fatherDiv = this._ownerGPO.container;

    GraphicViewportManager.testFitting4DivsABC(divA, divB, fatherDiv, isVertical);

    // Llegados aqui podemos crear el separador entre ambos div's y registrarlos a todos.
    this.configureSeparatorSlider4DivsAB(divA, divB, isVertical, fatherDiv);
    const res = GraphicViewport.areViewportsContiguous(vpA, vpB);
    if (!res) {
      debugger;
    }

    // Desactivamos a mainView, ya que estos 2 toman su lugar.
    if (this._ownerGPO._onlyMainView) {
      this._ownerGPO._onlyMainView = false;
    }

    if (this._ownerGPO.renderMainView()) {
      this._ownerGPO.renderMainView(false);
    }

    return vpB;

  } // private subdivision4MainView(isVertical: boolean = true): GraphicViewport | null

  /**
   * Dado un div que tenemos registrado como "padre", devolvemos en este orden los siguientes valores:
   * [0] Primer subDiv hijo (el correspondiente a la mitad izquierda o superior).
   * [1] Segundo subDiv hijo (el correspondiente a la mitad derecha o inferior).
   * [2] Separador entre ambas mitades.
   * [3] Un booleano para indicar si la separacion es vertical (true) u horizontal (false).
   *
   * @param fatherDiv
   * @returns
   */
  private getDivsABC(fatherDiv: HTMLDivElement): [HTMLDivElement, HTMLDivElement, HTMLDivElement, boolean] | null {
    if (this._divTree3.has(fatherDiv)) {
      const infoSons = this._divTree3.get(fatherDiv);
      if (infoSons?.sons) {
        const sons = infoSons.sons as Info4Sons;
        // Ademas comprobamos que estan correctamente incluidos entre los hijos de fatherDiv sin errores.
        const sonA = sons.leftSon;
        const sonB = sons.rightSon;
        const sonC = sons.divisionSon;
        const isVertical = sons.isVertical;
        if (true) {
          const vChildren = Array.from(fatherDiv.childNodes);
          if (-1 === vChildren.indexOf(sonA)) {
            debugger;
          }
          if (-1 === vChildren.indexOf(sonB)) {
            debugger;
          }
          if (-1 === vChildren.indexOf(sonC)) {
            debugger;
          }
        }
        return [sonA, sonB, sonC, isVertical];
      }
    }
    return null;
  }

  /**
   * Para saber si el div dado se corresponde con alguna subdivision dentro de la cual puede haber MAS de un vp.
   *
   * @private
   * @param {HTMLDivElement} div
   * @returns {boolean}
   * @memberof GraphicViewportManager
   */
  private isSubdivision(div: HTMLDivElement): boolean {
    // Si es subDiv estara como clave en nuestro mapa.
    const isKey = this._divTree3.has(div);
    // Aun NO SE si podria estar dentro de la parte div de la informacion de los hijos o incluso como padre...
    // Asi que recorremos todo lo almacenado para asegurarnos.
    let numAppearances = 0;
    for (const [key, value] of this._divTree3) {
      let isFather = false;
      if (value.father) {
        if (value.father === div) {
          // console.log("Esta como padre.");
          isFather = true;
        }
        if (isFather) {
          if (div !== key) {
            console.error(`ERROR: El div "${div.id}" es PADRE pero no aparece como CLAVE "${key.id}".`);
            debugger;
          }
        }
      }
      if (value.sons) {
        const res = this.getDivsABC(key);
        if (!res) {
          debugger;
        }

        // Aqui tenemos los hemiDivs A y B (ambas mitades, izquierda y derecha o bien superior e inferior, correspondientes o
        // bien a vp's o bien a subdivisiones recursivas...) mas el separador C cuya naturaleza esta especificada en isVerticalC.
        const [divSonA, divSonB, divSonC,] = res as [HTMLDivElement, HTMLDivElement, HTMLDivElement, boolean];
        if (divSonA === div) {
          // console.log("Es el primer hijo div.");
          ++numAppearances;
        }
        if (divSonB === div) {
          // console.log("Es el segundo hijo div.");
          ++numAppearances;
        }
        if (divSonC === div) {
          // console.error("ERROR: Coincide con el separador???.");
          debugger;
        }
      }
      if (!numAppearances) {
        if (isKey) {
          // console.error("El div '" + div.id + "' solamente aparece como CLAVE.");
          if (isFather) {
            // console.error(" Y es padre.");
          } else {
            // console.error(" Y NO es padre.");
            // debugger;
          }
        }
      } else {
        if (numAppearances === 1) {
          // console.log("Una aparicion como hijo div.");
        } else {
          debugger;
        }
      }
    } // for (const [key, value] of this._divTree3)

    if (!numAppearances) {
      console.error("ERROR: El div '" + div.id + "' no aparece en el registro.");
      debugger;
    }

    return isKey;
  }

  public static divDraw(div: HTMLDivElement): void {
    const r = div.getBoundingClientRect();
    GraphicViewportManager.lineDraw(r.left, r.top, r.right, r.bottom);
    GraphicViewportManager.lineDraw(r.left, r.bottom, r.right, r.top);
  }

  private static lineDraw(x1: number, y1: number, x2: number, y2: number): void {
    if (x2 < x1) {
      let tmp: number;
      tmp = x2; x2 = x1; x1 = tmp;
      tmp = y2; y2 = y1; y1 = tmp;
    }

    const lineLength = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    const m = (y2 - y1) / (x2 - x1);

    const degree = Math.atan(m) * 180 / Math.PI;

    document.body.innerHTML += "<div class='line' style='transform-origin: top left; transform: rotate(" + degree + "deg); width: " + lineLength + "px; height: 1px; background: black; position: absolute; top: " + y1 + "px; left: " + x1 + "px;'></div>";
  }

  /**
   * Devuelve los coeficientes en 01 para el PADRE del DIV dado, siempre con respecto al contenedor global.
   *
   * @param {HTMLDivElement} div
   * @returns {[number, number]}
   * @memberof GraphicViewportManager
   */
  getParentWidthHeight01(div: HTMLDivElement): [number, number] {
    const parentDiv = div.parentElement as HTMLDivElement;
    // Es hijo al primer nivel del padre global que ocupa todo.
    if (parentDiv === this._ownerGPO._container) {
      return [1, 1];
    }

    // Es hijo a un nivel superior, luego devolvemos los valores en [0, 1] del padre respecto al nivel global.
    const r = parentDiv.getBoundingClientRect();
    return [r.width / this._ownerGPO.width, r.height / this._ownerGPO.height];
  }

  /**
   * Funcion de soporte del callback onPointerMove4Separator() efectuado contra un div padre que contiene a otros 2 subDivs
   * (que a su vez pueden ser vp's o subdivisiones) y a un separador intermedio. Aqui se redimensiona y recoloca todo lo
   * necesario. Recuerda, que es el div de la forma parteA + sliderSepAB + parteB el que soporta este evento, no el propio
   * slider, que movemos desde aqui.
   *
   * @private
   * @param {PointerEvent} event
   * @returns
   * @memberof GraphicViewportManager
   */
  private processSliderSeparatorMovement(event: PointerEvent) {
    event.stopPropagation();
    if (!isSelectButtonPressed(event)) {
      return;
    }

    // El problema aqui esta en que, como slider, me pueden cambiar totalmente los divs que estoy separando y debo saberlo,
    // asi que no me puedo fiar de los datos temporales externos divA y divB. Recuerda que este evento pertenece al div padre.
    // Pero como saber el destinatario???. Este evento llega con event.currentTarget === fatherDiv y esto lo podria usar para
    // buscar en this._divTree3 las 2 mitades div implicadas (que pueden corresponder con vp o ser a su vez subdivisiones)
    // asi como su div separador. Ojo que esas mitades perfectamente pueden ser diferentes.
    // En event.button tenemos el id numerico del boton pulsado: 0, 1 y 2 para izquierdo, central y derecho respectivamente.
    // PERO AQUI SIEMPRE LLEGA UN -1.
    const divTarget = event.currentTarget as HTMLDivElement;
    // En divTarget.childNodes estan todos los descendientes DIRECTOS (entre los que estan los 2 hemiDivs y el separador).
    const res = this.getDivsABC(divTarget);
    if (!res) {
      debugger;
    }

    // Aqui tenemos los hemiDivs A y B (ambas mitades, izquierda y derecha o bien superior e inferior, correspondientes o
    // bien a vp's o bien a subdivisiones recursivas...) mas el separador C cuya naturaleza esta especificada en isVerticalC.
    const [divSonA, divSonB, divSepC, isVerticalC] = res as [HTMLDivElement, HTMLDivElement, HTMLDivElement, boolean];

    // Comprobamos si los hijos mitad A y B se corresponden con VP's o son a su vez subdivisiones.
    const isSubDivA = this.isSubdivision(divSonA);
    const isSubDivB = this.isSubdivision(divSonB);
    // De los que no sean subdivisiones sacamos sus vp's asociados, que podrian diferir de los inicialmente planteados.
    let vpSonA: GraphicViewport | null = null;
    let vpSonB: GraphicViewport | null = null;
    // console.log("onPointerMOVE4Separator(): Pointer MOVE sobre div '" + divTarget.id + "' en:");
    if (!isSubDivA) {
      vpSonA = this.getViewport4Div(divSonA);
      if (!vpSonA) {
        debugger;
      } else {
        // console.log("\tPrimer VP: '" + vpSonA.name + "'");
      }
    } else {
      // console.log("\tPrimer DIV: '" + divSonA.id + "'");
    }
    if (!isSubDivB) {
      vpSonB = this.getViewport4Div(divSonB);
      if (!vpSonB) {
        debugger;
      } else {
        // console.log("\tSegundo VP: '" + vpSonB.name + "'");
      }
    } else {
      // console.log("\tSegundo DIV: '" + divSonB.id + "'");
    }

    const x = event.clientX;
    const y = event.clientY;
    const divTargetRect = divTarget.getBoundingClientRect();
    const xL = divTargetRect.left;
    const xR = divTargetRect.right;
    const yT = divTargetRect.top;
    const yB = divTargetRect.bottom;

    if (true) {
      // Limito el movimiento para que no se pueda llegar a dejar un div casi sin tamaño y la bolita del slider no se salga de madre.
      const limit = 25;
      const x2 = x - divSepC.offsetWidth / 2;
      const y2 = y - divSepC.offsetHeight / 2;
      if ((x2 < xL + limit || xR - limit < x2) || (y2 < yT + limit) || (yB - limit < y2)) {
        return;
      }
    }

    event.preventDefault();
    // Creo que esto impide que se inmiscuya la seleccion.
    event.stopPropagation();

    if (this._isBlocked === false) {
      // No deberia ser posible.
      debugger;
    }

    if (isVerticalC) {
      const width = divTargetRect.width;
      // El valor event.pageX es un entero expresado en pixels para la coordenada X del puntero del raton, relativo al documento
      // entero, cuando se produjo el evento. Esta propiedad toma en cuenta la barra de desplazamiento horizontal de la pagina.
      // https://developer.mozilla.org/es/docs/Web/API/UIEvent/pageX
      const sliderPosX = Math.max(0, Math.min(width, event.pageX - xL));

      // Ahora va perfecto con el calc() y la resta.
      const leftTPC = 100 * (sliderPosX - divSepC.offsetWidth / 2) / width;
      divSepC.style.left = "calc(" + leftTPC + "% - " + (divSepC.offsetWidth / 2) + "px)";

      // El div en la parte izquierda tendria un width del leftTPC% y la derecha lo restante.
      const rightTPC = 100 - leftTPC;

      if (vpSonA) {
        vpSonA.elemHTML.style.width = leftTPC + "%";
        this.updateViewport4Div(vpSonA);
      } else {
        divSonA.style.width = leftTPC + "%";
        this.updateContainedViewports4Div(divSonA);
      }

      if (vpSonB) {
        // Para mover la otra parte es imprescindible el left.
        vpSonB.elemHTML.style.left = leftTPC + "%";
        vpSonB.elemHTML.style.width = rightTPC + "%";
        this.updateViewport4Div(vpSonB);
      } else {
        divSonB.style.left = leftTPC + "%";
        divSonB.style.width = rightTPC + "%";
        this.updateContainedViewports4Div(divSonB);
      }
    } else {
      const height = divTargetRect.height;
      const sliderPosY = Math.max(0, Math.min(height, event.pageY - yT));

      const topTPC = 100 * (sliderPosY - divSepC.offsetHeight / 2) / height;
      const downTPC = 100 - topTPC;
      divSepC.style.top = "calc(" + topTPC + "% - " + (divSepC.offsetHeight / 2) + "px)";

      // Y ahora metemos mano a los VP's.
      if (vpSonA) {
        vpSonA.elemHTML.style.height = topTPC + "%";
        this.updateViewport4Div(vpSonA);
      } else {
        divSonA.style.height = topTPC + "%";
        this.updateContainedViewports4Div(divSonA);
      }

      if (vpSonB) {
        vpSonB.elemHTML.style.top = topTPC + "%";
        vpSonB.elemHTML.style.height = downTPC + "%";
        this.updateViewport4Div(vpSonB);
      } else {
        divSonB.style.top = topTPC + "%";
        divSonB.style.height = downTPC + "%";
        this.updateContainedViewports4Div(divSonB);
      }
    }
  } // private processSliderSeparatorMovement(event: PointerEvent)

  private updateContainedViewports4Div(div: HTMLDivElement): void {
    if (this._divTree3.has(div)) {
      // Si tenemos el div, buscamos entre sus hijos aquellos que tengan VP's asociados.
      const infoSons = this._divTree3.get(div);
      if (infoSons?.sons) {
        const sons = infoSons.sons as Info4Sons;
        // Ademas comprobamos que estan correctamente incluidos entre los hijos de fatherDiv sin errores.
        const divA = sons.leftSon;
        const divB = sons.rightSon;

        const vpA = this.getViewport4Div(divA);
        if (vpA) {
          // Aqui habria que reajustar los parametros del VP para que vayan en consonancia con el div.
          this.updateViewport4Div(vpA);
        } else {
          // Es una subdivision y por tanto seguimos con la llamada recursiva, creo.
          this.updateContainedViewports4Div(divA);
        }
        const vpB = this.getViewport4Div(divB);
        if (vpB) {
          this.updateViewport4Div(vpB);
        } else {
          this.updateContainedViewports4Div(divB);
        }
      } else {
        console.error("ERROR: El div '" + div.id + "' no tiene registrada informacion de hijos.");
        debugger;
      }
    } else {
      console.error("ERROR: No tenemos registrado el div '" + div.id + "'.");
      debugger;
    }
  }

  /**
   * Para saber si el slider dado es de separacion vertical u horizontal.
   *
   * @private
   * @param {HTMLDivElement} sldrDiv
   * @returns {boolean}
   * @memberof GraphicViewportManager
   */
  private isSliderMovementVertical(sldrDiv: HTMLDivElement): boolean {
    // Su padre es clave en el mapa seguro.
    const res = this.getDivsABC(sldrDiv.parentElement as HTMLDivElement);
    if (!res) {
      debugger;
    }

    const [, , divSonC, isVertical] = res as [HTMLDivElement, HTMLDivElement, HTMLDivElement, boolean];
    if (divSonC !== sldrDiv) {
      debugger;
    }
    return isVertical;
  }

  /**
   * Nos devuelve un factor en [0, 1] indicativo de la posicion del slider dentro de su padre.
   *
   * @private
   * @param {HTMLDivElement} sldrDiv
   * @returns {number}
   * @memberof GraphicViewportManager
   */
  private getSliderRelativePosition01(sldrDiv: HTMLDivElement): number {
    const parent = sldrDiv.parentElement as HTMLDivElement;
    const rPrnt = parent.getBoundingClientRect();
    const rSldr = sldrDiv.getBoundingClientRect();
    const isVertical = this.isSliderMovementVertical(sldrDiv);

    let f01: number;
    if (isVertical) {
      const W = rPrnt.width;
      const L = rPrnt.left;
      const x = rSldr.left + 0.5 * rSldr.width;
      f01 = (x - L) / W;
    } else {
      const H = rPrnt.height;
      const T = rPrnt.top;
      const y = rSldr.top + 0.5 * rSldr.height;
      f01 = (y - T) / H;
    }

    return f01;
  }

  /**
   * Crea, configura y registra un slider para los div's dados (ambos correspondientes a sendos vp's que forman una "particion"
   * con respecto a su padre contenedor.
   * Ademas propone los callbacks necesarios para el slider y lo registra todo en el arbol de DIV's.
   * El orden para dar los div's es izquierda-derecha o arriba-abajo.
   * Devuelve como resultado el div/slider para que se le pueda dar nombre, etc...
   * Por otra parte si se detectan dimensiones no enteras aqui nos preocupamos de redimensionar los divs implicados.
   * \ToDo: Mas adelante casi seguro que se puede quitar el parametro fatherDiv que es extraible de ambos divs.
   *
   * @param {HTMLDivElement} divA
   * @param {HTMLDivElement} divB
   * @param {boolean} isVertical
   * @param {HTMLDivElement} fatherDiv
   * @memberof GraphicViewportManager
   */
  public configureSeparatorSlider4DivsAB(divA: HTMLDivElement, divB: HTMLDivElement, isVertical: boolean, fatherDiv: HTMLDivElement): HTMLDivElement {
    let isFractionaryDivA = false;
    let isFractionaryDivB = false;
    if (GraphicViewport.testDiv4NonIntegerDims(divA)) {
      console.error("\tEl divA tiene dimensiones decimales!!!.");
      isFractionaryDivA = true;
    }
    if (GraphicViewport.testDiv4NonIntegerDims(divB)) {
      console.error("\tEl divB tiene dimensiones decimales!!!.");
      isFractionaryDivB = true;
    }

    // Ambos div's son hijos del mismo padre y forman una particion matematica/geometrica del mismo.
    if (divA.parentElement && divB.parentElement && divA.parentElement === divB.parentElement) {
      if (fatherDiv !== divA.parentElement) {
        debugger;
      }
    } else {
      debugger;
    }

    let isFractionaryDivFatherAB = false;
    if (GraphicViewport.testDiv4NonIntegerDims(fatherDiv)) {
      console.error("\tEl padre de divA y divB tiene dimensiones decimales!!!.");
      isFractionaryDivFatherAB = true;
      // debugger;
    }

    if (isFractionaryDivA || isFractionaryDivB) {
      // Tenemos una parte o las 2 que son fraccionarias, es decir que sus dimensiones NO son enteras.
      // Hay que arreglarlo aqui y ahora para evitar dimensiones fraccionarias que se llevan mal con mostrar bordes.
      // debugger;

    }

    const divSepAB = this.createSeparatorSliderDivAB(divA, divB, isVertical);

    let previousCursor = "";

    // Aqui esta el meollo del callback: Al pulsar el boton de seleccion sobre el slider se registran otros 2 callbacks, uno para gestionar el
    // movimiento y el otro para liberar los 2 callbacks anteriores al liberar el boton. Solo admitiremos el boton de seleccion.
    // BUGS al moverse: Parece que, efectivamente, se eliminan al poner event.preventDefault en los callbacks de down y move.
    // Sacado de https://stackoverflow.com/questions/5429827/how-can-i-prevent-text-element-selection-with-cursor-drag
    
    // CALLBACK[1/3]: Al pulsar el boton de seleccion sobre el slider.
    const onPointerDown4Separator = (event: PointerEvent) => {
      event.stopPropagation();
      // En event.button tenemos el id numerico del boton pulsado: 0, 1 y 2 para izquierdo, central y derecho respectivamente.
      // Aqui llega correctamente. Filtramos para obligar al uso del boton de seleccion.
      if (!isSelectButtonPressed(event)) {
        return;
      } else {
        // if (event.isPrimary === false) {
        //   return;
        // }
        event.preventDefault();
      }

      // El que recibe el evento es el div separador.
      const sepTarget = event.currentTarget as HTMLDivElement;
      // Y a partir de el obtenemos los div's hermanos que separa, sean correspondientes a subdivisiones o a vp's.
      // Para ello buscamos a su padre.
      const parent = sepTarget.parentElement as HTMLDivElement;
      const res = this.getDivsABC(parent);
      if (!res) {
        debugger;
      }
      if (fatherDiv !== parent) {
        debugger;
      }

      const [divSonA, divSonB, divSepC] = res as [HTMLDivElement, HTMLDivElement, HTMLDivElement, boolean];
      if (divSepC !== sepTarget) {
        debugger;
      }

      console.log("onPointerDOWN4Separator(): Pointer DOWN en slider '" + divSepC.id + "'.");
      // Comprobamos si los hijos mitad A y B se corresponden con VP's o son a su vez subdivisiones.
      const isSubDivA = this.isSubdivision(divSonA);
      const isSubDivB = this.isSubdivision(divSonB);
      // De los que no sean subdivisiones sacamos sus vp's asociados, que podrian diferir de los inicialmente planteados.
      let vpSonA: GraphicViewport | null = null;
      let vpSonB: GraphicViewport | null = null;
      if (!isSubDivA) {
        vpSonA = this.getViewport4Div(divSonA);
        if (!vpSonA) {
          debugger;
        } else {
          console.log("\tPrimer VP: '" + vpSonA.name + "'");
        }
      } else {
        console.log("\tPrimer DIV: '" + divSonA.id + "'");
      }
      if (!isSubDivB) {
        vpSonB = this.getViewport4Div(divSonB);
        if (!vpSonB) {
          debugger;
        } else {
          console.log("\tSegundo VP: '" + vpSonB.name + "'");
        }
      } else {
        console.log("\tSegundo DIV: '" + divSonB.id + "'");
      }

      // Truco para que funcione sin problemas con la seleccion. Merito de JaWS.
      // Al pulsar se impiden otras selecciones. Y al liberar se vuelven a permitir.
      divSonA.style.userSelect = 'none';
      divSonA.style.pointerEvents = 'none';
      divSonB.style.userSelect = 'none';
      divSonB.style.pointerEvents = 'none';

      if (this._isBlocked === false) {
        this._isBlocked = true;
      } else {
        // No deberia ser posible.
        console.error("No debiera ser posible que este bloqueado.");
      }

      // Dejamos el cursor que tenia.
      previousCursor = parent.style.cursor;
      parent.style.cursor = divSepAB.style.cursor;

      // Cambiamos el color del splitter al del border de un vp seleccionado.
      sepTarget.style.backgroundColor = GraphicViewport._activeBorderColor;
      sepTarget.style.border = "4px solid " + GraphicViewport._activeBorderColor;

      // Al pulsar el raton sobre el div separador automaticamente registramos estos 2 callbacks.
      // La duda es si tiene que ser obligatoriamente en window o lo podriamos hacer en el div padre.
      parent.addEventListener("pointermove", onPointerMove4Separator);
      parent.addEventListener("pointerup", onPointerUp4Separator);
    };

    // CALLBACK[2/3]: Al mover el slider manteniendo pulsado el boton de seleccion.
    const onPointerMove4Separator = (event: PointerEvent) => {
      this.processSliderSeparatorMovement(event);
    };

    // CALLBACK[3/3]: Al soltar el boton de seleccion. Ojo, que no hay que comprobar boton.
    const onPointerUp4Separator = (event: PointerEvent) => {
      const divTarget = event.currentTarget as HTMLDivElement;
      const res = this.getDivsABC(divTarget);
      if (!res) {
        debugger;
      }
      if (fatherDiv !== divTarget) {
        debugger;
      }

      const [divSonA, divSonB] = res as [HTMLDivElement, HTMLDivElement, HTMLDivElement, boolean];

      // Comprobamos si los hijos mitad A y B se corresponden con VP's o son a su vez subdivisiones.
      const isSubDivA = this.isSubdivision(divSonA);
      const isSubDivB = this.isSubdivision(divSonB);
      // De los que no sean subdivisiones sacamos sus vp's asociados, que podrian diferir de los inicialmente planteados.
      let vpSonA: GraphicViewport | null = null;
      let vpSonB: GraphicViewport | null = null;
      console.log("onPointerUP4Separator() Pointer UP sobre div '" + divTarget.id + "' en:");
      if (!isSubDivA) {
        vpSonA = this.getViewport4Div(divSonA);
        if (!vpSonA) {
          debugger;
        } else {
          console.log("\tPrimer VP: '" + vpSonA.name + "'");
        }
      } else {
        console.log("\tPrimer DIV: '" + divSonA.id + "'");
      }
      if (!isSubDivB) {
        vpSonB = this.getViewport4Div(divSonB);
        if (!vpSonB) {
          debugger;
        } else {
          console.log("\tSegundo VP: '" + vpSonB.name + "'");
        }
      } else {
        console.log("\tSegundo DIV: '" + divSonB.id + "'");
      }

      // Truco para que funcione sin problemas con la seleccion. Merito de JaWS.
      // Al pulsar se impiden otras selecciones. Y al liberar se vuelven a permitir.
      divSonA.style.removeProperty('user-select');
      divSonA.style.removeProperty('pointer-events');
      divSonB.style.removeProperty('user-select');
      divSonB.style.removeProperty('pointer-events');

      // Al soltar el boton desregistramos lo que registramos al pulsarlo.
      divTarget.removeEventListener("pointermove", onPointerMove4Separator);
      divTarget.removeEventListener("pointerup", onPointerUp4Separator);

      console.log(" ===> END POINTER UP. Desconectados callbacks.");

      if (this._isBlocked === true) {
        this._isBlocked = false;
      } else {
        // No deberia ser posible.
        debugger;
      }

      // Dejamos el cursor como estaba.
      divTarget.style.cursor = previousCursor;
      // Y recuperamos el color del splitter como el del vp no activo.
      divSepAB.style.backgroundColor = GraphicViewport._inactiveBorderColor;
      divSepAB.style.border = "4px solid gray";
    };

    // Registramos al down con el callback que se encarga de registrar lo demas.
    // Este callback se lo agregamos al propio slider separador dentro de los datos guardados, para que pueda desconectarse.
    divSepAB.addEventListener("pointerdown", onPointerDown4Separator);

    // Ademas meto un par de callbacks de entrada y salida para colorear y descolorear el div del slider.
    this.addIntroOutroCallbacks2SliderDiv(divSepAB);

    if (true) {
      // En alguno de los valores (no claves) previamente existentes de this._divTree3 puede estar presente fatherDiv como "hijo".
      const vKeys = this.getKeyDivs4AssociatedDivs(fatherDiv);
      const numKeys = vKeys.length;
      if (numKeys) {
        console.log("Current div '" + fatherDiv.id + "'");
        for (let i = 0; i < numKeys; ++i) {
          console.log("\t[" + i + "/" + numKeys + "] Subdivision of div '" + vKeys[i].id + "'");
        }
      } else {
        console.log("Current div '" + fatherDiv.id + "' seems to be a leaf.");
      }
    }

    // Finalmente registramos al padre DIV con sus 3 hijitos.
    const infoSons: Info4Sons = {
      leftSon: divA,
      rightSon: divB,
      divisionSon: divSepAB,
      isVertical: isVertical,
      downCallback: onPointerDown4Separator
    };
    // Esta es la informacion para el nodo.
    const infoNode: Info4Div = {
      sons: infoSons
    };

    if (fatherDiv !== this._ownerGPO.container) {
      // Esto creo que faltaba.
      infoNode.father = fatherDiv.parentElement as HTMLDivElement;
    }

    if (this._divTree3.has(fatherDiv)) {
      debugger;
    }
    this._divTree3.set(fatherDiv, infoNode);

    return divSepAB;
  } // public configureSeparatorSlider4DivsAB(divA: HTMLDivElement, divB: HTMLDivElement, isVertical: boolean, fatherDiv: HTMLDivElement): HTMLDivElement

  private addIntroOutroCallbacks2SliderDiv(sldrDv: HTMLDivElement): void {
    sldrDv.addEventListener("mouseenter", this.lambdaMouseIntro);
    sldrDv.addEventListener("mouseleave", this.lambdaMouseOutro);
  }

  private removeIntroOutroCallbacks2SliderDiv(sldrDv: HTMLDivElement): void {
    sldrDv.removeEventListener("mouseenter", this.lambdaMouseIntro);
    sldrDv.removeEventListener("mouseleave", this.lambdaMouseOutro);
  }

  private lambdaMouseIntro = (event: MouseEvent) => {
    const div = event.currentTarget as HTMLDivElement;
    console.log(`SLIDER INTRO "${div.id}"`);

    // Al entrar se debe dejar el bordecito con el color del activo en curso.
    div.style.borderColor = GraphicViewport._activeBorderColor;
  };

  private lambdaMouseOutro = (event: MouseEvent) => {
    const div = event.currentTarget as HTMLDivElement;
    console.log(`SLIDER OUTRO "${div.id}"`);

    // Al salir se debe dejar el bordecito del color de los abandonados.
    div.style.borderColor = GraphicViewport._inactiveBorderColor;
  };

  /**
   * Dados 2 divs con un padre comun al que parten (en perfecta particion) en 2 zonas verticales, se devuelve el porcentaje
   * en que lo hacen respecto al primero. Por ejemplo:
   *
   *            +-------------+----+
   *            |    80%      | 20%|
   *            |    divA     |divB| ===> 80%
   *            |             |    |
   *            +-------------+----+
   *            <El padre ocupa eso>
   *
   * Ojo, que siempre hay que darlos en el correcto orden izquierdo-derecho.
   *
   * @static
   * @param {HTMLDivElement} divA
   * @param {HTMLDivElement} divB
   * @returns {number}
   * @memberof GraphicViewportManager
   */
  public static getVerticalPercentage(divA: HTMLDivElement, divB: HTMLDivElement): number {
    if (divA.parentElement && divB.parentElement && divA.parentElement === divB.parentElement) {
      ;
    } else {
      debugger;
    }

    const rA = divA.getBoundingClientRect();
    const rB = divB.getBoundingClientRect();
    // El padre.
    const divP = divA.parentElement as HTMLDivElement;
    const rP = divP.getBoundingClientRect();

    const wA = rA.width;
    const wB = rB.width;
    const wP = rP.width;
    if (wA + wB !== wP) {
      console.error(`Anchuras no concordantes: ${wA} + ${wB} = ${wA + wB} !== ${wP} ===> dif: ${wP - wA - wB}`);
      // debugger;
    }

    const tpc = wA * 100 / wP;
    return tpc;
  }

  /**
   * Version horizontal, dando primero el de arriba y luego el de abajo.
   *
   * @static
   * @param {HTMLDivElement} divA
   * @param {HTMLDivElement} divB
   * @returns {number}
   * @memberof GraphicViewportManager
   */
  public static getHorizontalPercentage(divA: HTMLDivElement, divB: HTMLDivElement): number {
    if (divA.parentElement && divB.parentElement && divA.parentElement === divB.parentElement) {
      ;
    } else {
      debugger;
    }

    const rA = divA.getBoundingClientRect();
    const rB = divB.getBoundingClientRect();
    // El padre.
    const divP = divA.parentElement as HTMLDivElement;
    const rP = divP.getBoundingClientRect();

    const hA = rA.height;
    const hB = rB.height;
    const hP = rP.height;
    if (hA + hB !== hP) {
      console.error(`Alturas no concordantes: ${hA} + ${hB} = ${hA + hB} !== ${hP} ===> dif: ${hP - hA - hB}`);
      // debugger;
    }

    const tpc = hA * 100 / hP;
    return tpc;
  }

  public static calcPercentsA2B(rA: [number, number, number, number], rB: [number, number, number, number]): [number, number, number, number] {
    // Dadas unas dimensiones para algo grande como la fuente A, generamos los % para el destino piquico B.
    // Las dimensiones como siempre son de la forma [left, top, width, height] con respecto a la ESI.
    const xA = rA[0];
    const yA = rA[1];
    const wA = rA[2];
    const hA = rA[3];
    const xB = rB[0];
    const yB = rB[1];
    const wB = rB[2];
    const hB = rB[3];

    const tpcLA = 100 * (xB - xA) / wA;
    const tpcTA = 100 * (yB - yA) / hA;
    const tpcWA = 100 * wB / wA;
    const tpcHA = 100 * hB / hA;

    return [tpcLA, tpcTA, tpcWA, tpcHA];
  }

  public static getPercentages(left: number, top: number, width: number, height: number,
                               parentDiv: HTMLDivElement): [number, number, number, number] {
    // Las coordenadas (left, right) y dimensiones (width, height) ya calculadas como absolutas respecto al GPO las voy a convertir
    // a porcentajes sobre fatherDiv para la futura construccion de hijos div's del mismo, es decir del padre local.
    const rP = parentDiv.getBoundingClientRect();
    const rA = [rP.left, rP.top, rP.width, rP.height] as [number, number, number, number];
    const rB = [left, top, width, height] as [number, number, number, number];
    return GraphicViewportManager.calcPercentsA2B(rA, rB);
  }

  /**
   * Crea un separador SLIDER vertical u horizontal entre los 2 div's dados, que deben estar contiguos vertical u
   * horizontalmente. Al ser algo con mas "cuerpo" es mas facil usarlo.
   * Ademas ambos div's comparten el mismo padre comun, del que son una particion en el sentido matematico/geometrico del termino.
   *
   * Inspirado en el ejemplo de Three.js: https://threejs.org/examples/#webgl_multiple_scenes_comparison
   * Mas la informacion de: https://htmldom.dev/make-a-draggable-element/ y https://htmldom.dev/create-resizable-split-views/
   * Y los agradecimientos a JaWS.
   *
   * @param divA
   * @param divB
   * @param isVertical
   * @returns
   */
  private createSeparatorSliderDivAB(divA: HTMLDivElement, divB: HTMLDivElement, isVertical: boolean): HTMLDivElement {

    if (GraphicViewport.testDiv4NonIntegerDims(divA)) {
      console.error("\tEl divA tiene dimensiones decimales!!!.");
    }
    if (GraphicViewport.testDiv4NonIntegerDims(divB)) {
      console.error("\tEl divB tiene dimensiones decimales!!!.");
    }

    const divSepAB = document.createElement("div");
    // Le damos el padre comun a ambos hermanos divA y divB.
    if (divA.parentElement && divB.parentElement && divA.parentElement === divB.parentElement) {
      const parent = divA.parentElement;
      parent.appendChild(divSepAB);
    } else {
      debugger;
    }

    // Le damos nombre.
    divSepAB.id = (isVertical ? "V" : "H") + "=" + divA.id + "+" + divB.id;
    // Y tamaño correcto en la mitad de sus separandos. Recuerda que las AABB van referenciadas a la ESI.
    const aabbA = divA.getBoundingClientRect();
    const aabbB = divB.getBoundingClientRect();
    // Comprobacion psicopatica que desaparecera.
    let error = true;
    if (isVertical) {
      divSepAB.style.cursor = "col-resize";
      // Las alturas coinciden y A esta a la izquierda de B.
      if (aabbA.height === aabbB.height) {
        error = false;
      } else {
        console.log("Division vertical: Las alturas NO coinciden: " + aabbA.height + " vs " + aabbB.height);
      }
    } else {
      divSepAB.style.cursor = "row-resize";
      // Las anchuras coinciden y A esta encima de B.
      if (aabbA.width === aabbB.width) {
        error = false;
      } else {
        console.log("Division horizontal: Las anchuras NO coinciden: " + aabbA.width + " vs " + aabbB.width);
      }
    }

    if (error) {
      console.log("ERROR: No coincidencia en dimensiones???.");
      // debugger;
    }

    // La informacion de estilos CSS para div esta sacada de:
    // https://www.w3schools.com/cssref/pr_class_position.asp
    // Me da a mi en mi puta nariz que para que esto funcione correctamente el padre deberia ser asignado previamente???. Si.
    // Es aqui donde creamos el rombo que sirve de slider/splitter/resizer.
    divSepAB.style.position = "absolute";
    divSepAB.style.width = "40px";
    divSepAB.style.height = "40px";
    divSepAB.style.backgroundColor = GraphicViewport._inactiveBorderColor;
    divSepAB.style.opacity = "0.5";
    divSepAB.style.border = "4px solid gray";
    // Esto es lo que le da radio y lo convierte en un circulo.
    // divSepAB.style.borderRadius = "50%";
    // En vez de un circulo convertimos al cuadrado en un rombo con un simple giro y luego le cambiamos la escala en X o Y
    // para estirarlo segun convenga. Eso se hace poniendo ambas transformaciones en una misma linea y va de derecha a izquierda.
    const styleTransform = " rotate(45deg)";
    // divSepAB.style.transform = "rotate(45deg)";
    if (isVertical) {
      // Esto va a la mitad.
      divSepAB.style.top = "calc(50% - 20px)";
      // Pero esto se calcula en funcion de las posiciones relativas.
      const tpc = GraphicViewportManager.getVerticalPercentage(divA, divB);
      divSepAB.style.left = "calc(" + tpc + "% - 20px)";
      divSepAB.style.transform = "scaleX(0.5)" + styleTransform;
    } else {
      const tpc = GraphicViewportManager.getHorizontalPercentage(divA, divB);
      divSepAB.style.top = "calc(" + tpc + "% - 20px)";
      divSepAB.style.left = "calc(50% - 20px)";
      divSepAB.style.transform = "scaleY(0.5)" + styleTransform;
    }

    if (false) {
      // Un intento de lograr un borde redondeado para este rhombus.
      divSepAB.style.borderRadius = "5px";
    }

    // divSepAB.style.background = "yellow";
    // Creo que esto es necesario para que se vea por encima.
    divSepAB.style.zIndex = "10";

    // Por defecto es auto, pero asi lo dejamos claro. pointer-events: auto;
    // Si lo quisieramos anular "none".
    // Info sacada de https://www.w3schools.com/cssref/css3_pr_pointer-events.asp
    divSepAB.style.pointerEvents = "auto";

    // En la demo de Three lo ponen como "disable touch scroll".
    divSepAB.style.touchAction = "none";

    return divSepAB;
  }

  public updateViewport(vp: GraphicViewport): void {
    // La referencia.
    const ref = this._ownerGPO._container.getBoundingClientRect();
    const W = ref.width;
    const H = ref.height;
    const L = ref.left;
    const T = ref.top;
    const div = vp.elemHTML;
    const rDv = div.getBoundingClientRect();
    const w = rDv.width;
    const h = rDv.height;
    const l = rDv.left;
    const t = rDv.top;

    // Estos son los valores previos al resize.
    //    const [w01, h01, l01, t01] = [vp.width01, vp.height01, vp.left01, vp.top01];
    //    const [wPx, hPx, lPx, tPx] = [vp.widthPx, vp.heightPx, vp.leftPx, vp.topPx];
    // Fijaremos los dmc's *01 y *Px del vp.
    vp.width01 = w / W;
    vp.height01 = h / H;
    vp.left01 = (l - L) / W;
    vp.top01 = (t - T) / H;

    vp.widthPx = w;
    vp.heightPx = h;
    vp.leftPx = l;
    vp.topPx = t;

    // const pc = (n: number): string => { return " " + (n * 100).toPrecision(3) + "% " };
    // const log = (txt: string, a: number, b: number, c: number, d: number): void => {
    //   console.log("\t" + txt + ": " + a + pc(b) + " ===> " + c + pc(d));  
    // };
    // this.seeDivParents(div);
    // Veamos la evolucion:
    // console.log("ANTES ====> DESPUES VP '" + vp.name + "' ('" + div.id + "' + " + div.parentElement?.id + "):");
    // log("W", wPx, w01, vp.widthPx, vp.width01);
    // log("H", hPx, h01, vp.heightPx, vp.height01);
    // log("l", lPx, l01, vp.leftPx, vp.left01);
    // log("t", tPx, t01, vp.topPx, vp.top01);
  }

  private seeDivParents(div: HTMLDivElement): void {
    let level = 0;
    while (div) {
      console.log("[" + level + "] '" + div.id + "' " + this.strInfo4DivDimensions(div));
      ++level;

      if (div === this._ownerGPO.container) {
        return;
      }
      div = div.parentElement as HTMLDivElement;
    }
  }

  /**
   * Realiza una completa actualizacion del vp dado para que sus parametros internos se ajusten totalmente al tamaño
   * de su div asociado.
   *
   * @param {GraphicViewport} vp
   * @memberof GraphicViewportManager
   */
  public updateViewport4Div(vp: GraphicViewport): void {

    // Fijaremos los dmc's *01 y *Px del vp.
    vp.setParams01Px(this._ownerGPO._container);

    const aspect = vp.widthPx / vp.heightPx;

    if (vp.camera) {
      if ((vp.camera as THREE.PerspectiveCamera).isPerspectiveCamera === true) {
        (vp.camera as THREE.PerspectiveCamera).aspect = aspect;
        (vp.camera as THREE.PerspectiveCamera).updateProjectionMatrix();
      } else {
        // Hay que dotar de cierto aspect ratio tambien a las camaras ortograficas para que no aberren.
        const orthoCamera = vp.camera as THREE.OrthographicCamera;
        const frustumSize = this._ownerGPO._height;
        orthoCamera.left = -(frustumSize * aspect) / 2;
        orthoCamera.right = (frustumSize * aspect) / 2;
        orthoCamera.top = frustumSize / 2;
        orthoCamera.bottom = -frustumSize / 2;
        orthoCamera.updateProjectionMatrix();
      }
    }

    if (vp.controls) {
      vp.controls.update(this._ownerGPO._clock.getDelta());
    }

    if (vp.gizmo) {
      vp.gizmo.handleResize(vp.widthPx, vp.heightPx);
    }
  }

  /**
   * Gestor del resize encargado de mantener el orden con los viewports y sus componentes internos.
   *
   * @param vp
   * @param isFirst
   */
  public resizeViewport(vp: GraphicViewport, isFirst: boolean): void {
    if (vp.elemHTML) {
      // Ojo que aunque el elemento HTML se dio mediante referenciado a la esquina superior izquierda, aqui se devuelven
      // tamaños y medidas en pixels RELATIVOS A LA ESQUINA INFERIOR izquierda, que normalizamos al intervalo [0, 1] tambien
      // referenciado a esa esquina inferior izquierda.
      const aabb = vp.elemHTML.getBoundingClientRect();

      // Estas son las coordenadas en pantalla de nuestro container global, dentro del que se circunscribe el elemento HTML que por
      // cojones es hijo de este. Recuerda que estas AABB y sus posiciones estan referenciados a la esquina superior izquierda.
      const aabbCntnr = this._ownerGPO._container.getBoundingClientRect();
      const leftCntnr = aabbCntnr.left;
      const topCntnr = aabbCntnr.top;

      // Ojo, que estas coordenadas que me pasan desde el exterior son RELATIVAS A TODA LA PANTALLA, no al cacho de nuestro container.
      const left = aabb.left;
      const top = aabb.top;
      const width = aabb.width;
      const height = aabb.height;

      // Siempre referenciamos estos valores absolutos (los dmc *01) para caer en el intervalo [0, 1].
      if (isFirst) {
        // Si es la primera vez los asignamos para que se ajusten en funcion de los datos del elemHTML respecto al container gordo.
        // Es decir *01 = f(container/elemHtml).
        vp.left01 = (left - leftCntnr) / this._ownerGPO._width;
        vp.top01 = (top - topCntnr) / this._ownerGPO._height;
        vp.width01 = width / this._ownerGPO._width;
        vp.height01 = height / this._ownerGPO._height;
      } else {
        // Pero si NO es la primera vez se supone que provenimos de un reSize en serio y tenemos que aplicar al elemHTML los
        // coeficientes en [0, 1] con respecto al tamaño del container gordo. Es decir elemHtml = g(container, *01).
        const canvasCntnr = this._ownerGPO._renderer.domElement;
        const [xVP, yVP, wVP, hVP] = vp.getXYWH4ViewportInHTMLDivElement(canvasCntnr);
        const aspect = wVP / hVP;

        const fatherDiv = vp.elemHTML.parentElement as HTMLDivElement;
        // Esos pixels absolutos (sobre GPO) dados por [xVP, yVP, wVP, hVP] los relativizamos respecto al padre local.
        const [left100, top100, width100, height100] = GraphicViewportManager.getPercentages(xVP, yVP, wVP, hVP, fatherDiv);

        // Damos los valores en pixels, ya que los 01 los tenemos y los aplicamos a las dimensiones totales del canvas.
        vp.leftPx = xVP;
        vp.topPx = yVP;
        vp.widthPx = wVP;
        vp.heightPx = hVP;

        vp.elemHTML.style.left = left100 + "%";
        vp.elemHTML.style.top = top100 + "%";
        vp.elemHTML.style.width = width100 + "%";
        vp.elemHTML.style.height = height100 + "%";
        vp.elemHTML.style.position = "absolute";

        // Ademas tenemos que actualizar la camara.
        if (vp.camera) {
          if ((vp.camera as THREE.PerspectiveCamera).isPerspectiveCamera === true) {
            (vp.camera as THREE.PerspectiveCamera).aspect = aspect;
            (vp.camera as THREE.PerspectiveCamera).updateProjectionMatrix();
          } else {
            // Hay que dotar de cierto aspect ratio tambien a las camaras ortograficas para que no aberren.
            const orthoCamera = vp.camera as THREE.OrthographicCamera;
            const frustumSize = this._ownerGPO._height;
            orthoCamera.left = -(frustumSize * aspect) / 2;
            orthoCamera.right = (frustumSize * aspect) / 2;
            orthoCamera.top = frustumSize / 2;
            orthoCamera.bottom = -frustumSize / 2;
            orthoCamera.updateProjectionMatrix();
          }
        }

        if (vp.controls) {
          vp.controls.update(this._ownerGPO._clock.getDelta());
        }

        if (vp.gizmo) {
          vp.gizmo.handleResize(wVP, hVP);
        }
      }
    } else {
      console.log("Viewport directo.");
    }
  }

  /**
   * Intercambia entre si los vp en las posiciones dadas.
   * OJO, que no se efectuan comprobaciones.
   *
   * @param {number} posA
   * @param {number} posB
   * @memberof GraphicViewportManager
   */
  public interchangeViewports(posA: number, posB: number): void {
    [this._vViewports[posA], this._vViewports[posB]] = [this._vViewports[posB], this._vViewports[posA]];
  }

  /**
   * Para activar o desactivar simultaneamente todos los VP que empiecen con el nombre dado.
   *
   * @param {string} name
   * @param {boolean} onOff
   * @memberof GraphicViewportManager
   */
  public setAllViewportsOnOff(name: string, onOff: boolean): void {
    const numViewports = this._vViewports.length;
    for (let n = 0; n < numViewports; ++n) {
      const vp = this._vViewports[n];
      if (vp.name.startsWith(name)) {
        vp.enabled = onOff;
        if (vp.elemHTML) {
          vp.elemHTML.hidden = !onOff;
        }
      }
    }
  }

  /**
   * Activa solamente el VP dado, desactivando los restantes.
   * Ojo, que no se hace ninguna comprobacion.
   *
   * @param {number} n
   * @memberof GraphicViewportManager
   */
  public activateOnlyViewportInput(n: number): void {
    const num = this._vViewports.length;
    for (let i = 0; i < num; ++i) {
      const viewport = this._vViewports[i];
      if (viewport.enabled) {
        const onOff = i === n ? true : false;
        // viewport.enabled = onOff;
        // Y activamos o no su camControl de haberlo.
        if (viewport.controls) {
          // Esto es comun entre CameraControls y OrbitControls.
          viewport.controls.enabled = onOff;
        }
        // Y para el unico activo tambien deberiamos actualizar su camara en curso, perspectiva u ortografica, por si la pide el JaWS...
        if (onOff) {
          // \ToDo...
        }
      }
    }
  }

  public handleResize(): void {
    const numViewports = this._vViewports.length;
    if (numViewports) {
      for (let i = 0; i < numViewports; ++i) {
        const viewport = this._vViewports[i];
        // Con el false hacemos patente que se trata de una operacion de resize de algo ya asignado, no del resize primario inicial.
        this.resizeViewport(viewport, false);
      }
    }
  }

  /**
   * Para saber desde el exterior si ya tenemos activado el modo de cuadruple viewport.
   */
  public isQuadViewportMode(): boolean {
    const numViewports = this._vViewports.length;
    if (numViewports >= 4) {
      // Estamos en el susodicho modo si tenemos los 4 viewports con los nombres "vpA", "vpB", "vpC" y "vpD".
      const vNames: string[] = ["vpA", "vpB", "vpC", "vpD"];
      for (let n = 0; n < numViewports; ++n) {
        const vp = this._vViewports[n];
        const name = vp.name;
        const pos = vNames.indexOf(name);
        if (pos !== -1) {
          vNames.splice(pos, 1);
          if (!vNames.length) {
            return true;
          }
        }
      }
    }
    return false;
  }

  /**
   * Para saber desde el exterior si ya tenemos activado el modo de triple viewport, que es el usado para los DXF.
   */
  public isTripleViewportMode(): boolean {
    const numViewports = this._vViewports.length;
    if (numViewports === 3) {
      // Estamos en el susodicho modo si tenemos SOLO los 3 viewports con los nombres "storeys", "plan", y "elevation".
      const vNames: string[] = ["storeys", "plan", "elevation"];
      for (let n = 0; n < numViewports; ++n) {
        const vp = this._vViewports[n];
        const name = vp.name;
        const pos = vNames.indexOf(name);
        if (pos !== -1) {
          vNames.splice(pos, 1);
          if (!vNames.length) {
            return true;
          }
        }
      }
    }
    return false;
  }

  /**
   * Visualiza informacion sobre el vp dado y efectua ciertas comprobaciones.
   *
   * @param {GraphicViewport} vp
   * @memberof GraphicViewportManager
   */
  public logVP(vp: GraphicViewport): void {
    const div = vp.elemHTML;
    // Este es el padre real en el arbol HTML que debiera coincidir con el padre "registrado".
    const parent = div.parentElement as HTMLDivElement;
    console.log(" +-------------------------------------------------------------------------------------------------------");
    console.log(` | VP "${vp.name}" Div "${div.id}" Parent "${parent.id}".`);
    // Comprobamos que el vp esta en la lista. Recuerda que el vp activo siempre estara en la ultima posicion.
    const posVP = this._vViewports.indexOf(vp);
    const cntVP = this._vViewports.length;
    if (-1 === posVP) {
      // Parece que a veces se me quedan separaciones de un pixel entre los divs y se cuela la accion al div del mainView.
      if (vp === this._ownerGPO._mainViewport) {
        console.error("ESTAS EN EL MAINVIEWPORT. Posible hueco???...");
        return;
      } else {
        console.error(" | ERROR: El VP no esta registrado!!!.");
        debugger;
      }
    }
    console.log(` | Pos ${posVP}/${cntVP}`);
    // Veamos si el padre real como HTML es el mismo que tenemos fichado en los datos.
    const vKeys = this.getKeyDivs4AssociatedDivs(div);
    if (vKeys.length === 1) {
      const value = this._divTree3.get(vKeys[0]) as Info4Div;
      if (value.father) {
        const parent2 = value.father;
        if (parent2 !== parent) {
          console.error(" | ERROR: El padre registrado '" + parent2.id + "'no coincide con el real!!!.");
          console.error(" | Dims: " + this.strInfo4DivDimensions(parent2));
          debugger;
        }
      }
    } else {
      debugger;
    }

    console.log(` | Div dims: ${this.strInfo4DivDimensions(div)}`);
    console.log(` | Fth dims: ${this.strInfo4DivDimensions(parent)}`);
    console.log(" +-------------------------------------------------------------------------------------------------------");
  }

  /**
   * Devuelve una cadena con las dimensiones del div dado.
   *
   * @private
   * @param {HTMLDivElement} div
   * @returns {string}
   * @memberof GraphicViewportManager
   */
  private strInfo4DivDimensions(div: HTMLDivElement): string {
    const r = div.getBoundingClientRect();
    const txt = "LT(" + r.left + ", " + r.top + ") <-> RB(" + r.right + ", " + r.bottom + ") : W*H " + r.width + "*" + r.height;

    return txt;
  }

  /**
   * Auxiliar depurativo que nos devuelve una cadena con las dimensiones del div dado, en formato:
   * Left-Top, Right-Bottom, Width*Height:
   * [LT(260.000, 66.000), RB(2117.000, 1327.000)] W*H(1857.000:1261.000)
   * @param div
   * @returns
   */
  private getDivDimms(div: HTMLDivElement): string {
    const r = div.getBoundingClientRect();
    let txt = `[LT(${r.left.toFixed(3)}, ${r.top.toFixed(3)}), RB(${r.right.toFixed(3)}, ${r.bottom.toFixed(3)})] `
      + `W*H(${r.width.toFixed(3)}:${r.height.toFixed(3)})`;
    return txt;
  }

  /**
   * Visualizador para la depuracion del contenido del dmc _divTree3. Opcionalmente se le puede pasar un mensajito.
   * Con el parametro adicional podemos sacar menos informacion, sino te salen los AABB.
   * @param msg
   */
  public debug(msg: string = "", basic: boolean = false): void {

    if (msg.length) {
      console.log("+---------------------------------------------------");
      console.log("|");
      console.log(`|\t${msg}`);
      console.log("|");
      console.log("+---------------------------------------------------");
    }

    const echo = (div: HTMLDivElement | undefined | null): string => {
      let txt = "----";
      if (div) {
        if (basic) {
          txt = `"${div.id}"`;
        } else {
          // Sacamos las dimensiones.
          txt = `"${div.id}" ` + this.getDivDimms(div) + ` ===> parent DIV: `;
          if (div.parentElement) {
            txt += `"${div.parentElement.id}"`;
          } else {
            txt += "----";
          }

          const associatedVP = this.getViewport4Div(div);
          if (associatedVP) {
            txt += ` ===> VP: "${associatedVP.name}"`;
          }
        }
      }

      return txt;

    };

    const testFather = (son: HTMLDivElement, dad: HTMLDivElement): string => {
      if (son.parentElement) {
        if (son.parentElement !== dad) {
          return ` <============= ERROR: Different father (${son.parentElement.id})?.`;
        } else {
          return "";
        }
      } else {
        return " <============= ERROR: Null parent!!!.";
      }

    };

    let N = this._divTree3.size;
    let i = 0;

    console.log("DIVS:");
    for (const [div, info] of this._divTree3) {
      console.log(`\t[${i}/${N}] DIV "${div.id}" ${basic ? "" : this.getDivDimms(div)}`);
      console.log(`\t\t FATHER DIV: ${echo(info.father)}`);
      if (info.sons) {
        const iSons = info.sons;
        console.log(`\t\t DIV SONS:`);
        console.log(`\t\t\t [1] leftSon: ${echo(iSons.leftSon)} ${testFather(iSons.leftSon, div)}`);
        console.log(`\t\t\t [2] rightSon: ${echo(iSons.rightSon)} ${testFather(iSons.leftSon, div)}`);
        console.log(`\t\t\t [3] divisionSon: ${echo(iSons.divisionSon)} isVertical: ${iSons.isVertical} ${testFather(iSons.leftSon, div)}`);
      } else {
        console.log(`\t\t DIV SONS: NONE`);
      }
      ++i;
    }

    // Sacamos el vp activo.
    const activeVP = this.getActiveViewport();

    N = this._vViewports.length;
    i = 0;
    console.log("VP's:");
    for (const vp of this._vViewports) {
      const isActive = vp === activeVP;
      console.log(`\t[${i}/${N}] VP "${vp.name}" ${isActive ? "<<==== ACTIVE!!!" : ""}`);
      console.log(`\t\t DIV: ${echo(vp.elemHTML)}`);
      ++i;
    }
  }

  public setKeyboardControls(vp: GraphicViewport): void {

    // window.alert(`Creating keystrokes for vp "${vp.name}".`);
    console.error(`Creating keystrokes for vp "${vp.name}".`);
    if (vp.keystrokesCallbacks.length) {
      console.error(`WARNING: There were ${vp.keystrokesCallbacks.length} previous callbacks.`);
    }

    const control = vp.controls as CameraControls;

    if (!control.getCamera()) {
      window.alert("CRITICAL ERROR: The control has no camera!!!.");
    }

    // Asignamos teclas para el movimiento, en plan videojuego total, adaptando lo que hace Yomotsu en:
    // https://yomotsu.github.io/camera-controls/examples/keyboard.html
    // Usaremos las teclas W, A, S, D para moverse en plan panning-trucking por el plano XY y las flechas del cursor para
    // rotaciones en torno a los ejes X-Y-Z con respecto a la posicion de la camara.

    // Estos son los N callbacks de teclado que podra soportar el control de este vp. Los ponemos asi para poderlos
    // desregistrar cuando sea necesario.
    const truck_A = (event: any) => {
      // Solo tenemos en cuenta a los controles correspondientes al vp activo en curso, para evitar que se muevan las
      // otras camaras cuando mariconeamos solo con una.
      if (vp !== this.getActiveViewport()) {
        return;
      }
      // Con los enableTransition a false queda muy bien este movimento en XY.
      control.truck(-0.01 * event.deltaTime, 0, false);
    };

    const truck_D = (event: any) => {
      if (vp !== this.getActiveViewport()) {
        return;
      }
      control.truck(+0.01 * event.deltaTime, 0, false);
    };

    const forward_W = (event: any) => {
      if (vp !== this.getActiveViewport()) {
        return;
      }
      control.forward(+0.01 * event.deltaTime, false);
    };

    const forward_S = (event: any) => {
      if (vp !== this.getActiveViewport()) {
        return;
      }
      control.forward(-0.01 * event.deltaTime, false);
    };

    const rotate_left = (event: any) => {
      if (vp !== this.getActiveViewport()) {
        return;
      }
      control.rotate(-0.05 * THREE.MathUtils.DEG2RAD * event.deltaTime, 0, false);
    };

    const rotate_right = (event: any) => {
      if (vp !== this.getActiveViewport()) {
        return;
      }
      control.rotate(+0.05 * THREE.MathUtils.DEG2RAD * event.deltaTime, 0, false);
    };

    const rotate_up = (event: any) => {
      if (vp !== this.getActiveViewport()) {
        return;
      }
      control.rotate(0, -0.05 * THREE.MathUtils.DEG2RAD * event.deltaTime, false);
    };

    const rotate_down = (event: any) => {
      if (vp !== this.getActiveViewport()) {
        return;
      }
      control.rotate(0, +0.05 * THREE.MathUtils.DEG2RAD * event.deltaTime, false);
    };

    // [R]ear vision. Nos vamos a la posicion simetrica de la actual 180º.
    const rotate_R_180 = (event: any) => {
      if (vp !== this.getActiveViewport()) {
        return;
      }
      console.error(`rotate_R_180(vp="${vp.name}")`);
      control.rotate(180 * THREE.MathUtils.DEG2RAD, 0, false);
    };

    // [E]ast vision. Nos vamos a la posicion a 90º de la actual por nuestra izquierda.
    const rotate_E_90 = (event: any) => {
      if (vp !== this.getActiveViewport()) {
        return;
      }
      control.rotate(-90 * THREE.MathUtils.DEG2RAD, 0, false);
    };

    const position = new THREE.Vector3();

    const up_U = (event: any) => {
      if (vp !== this.getActiveViewport()) {
        return;
      }
      control.getPosition(position);
      position.z += 1;
      console.log(`Position UP: (${position.x.toFixed(3)}, ${position.y.toFixed(3)}, ${position.z.toFixed(3)})`)
      control.setPosition(position.x, position.y, position.z);
    };

    const down_J = (event: any) => {
      if (vp !== this.getActiveViewport()) {
        return;
      }
      control.getPosition(position);
      position.z -= 1;
      console.log(`Position DOWN: (${position.x.toFixed(3)}, ${position.y.toFixed(3)}, ${position.z.toFixed(3)})`)
      control.setPosition(position.x, position.y, position.z);
    };

    // Zoom/unZoom del vp activo, ahora con "E" de [E]xpand.
    const zoom_M = (event: any) => {
      if (vp !== this.getActiveViewport()) {
        return;
      }

      console.log(`Let's zoom current vp '${vp.name}'`);
      this.zoom();
    };

    // Imprime informacion grafica.
    const info_I = (event: any) => {
      if (vp !== this.getActiveViewport()) {
        return;
      }

      this.owner.infoWGL();
      this.owner.seeRendererInfo();
    };

    const KEYCODE = {
      W: 87,
      A: 65,
      S: 83,
      D: 68,
      I: 73,
      ARROW_LEFT: 37,
      ARROW_UP: 38,
      ARROW_RIGHT: 39,
      ARROW_DOWN: 40,
      R: 82,
      E: 69,
      K: 75,
      U: 85,
      J: 74,
      // Para evitar problemas con CTRL-Z ponemos el zooM con "M".
      M: 77
    };

    // Para centralizar la generacion de callbacks, dando la tecla, el tiempo y la funcion asignada.
    // Ademas incluyo el tipo de evento ("holdStart", "holdEnd", "holding") para los callbacks que solo requieren
    // activarse al soltar la tecla pertinente, en vez de ejecutarse continuamente..
    const assignKey2Callback = (keyCode: number, time: number, callbackFunct: (event: any) => void, typeOfHold: string = 'holding'): void => {
      const key = new KeyboardKeyHold(keyCode, time);
      key.addEventListener(typeOfHold, callbackFunct);
      vp.keystrokesCallbacks.push([key, callbackFunct]);
    };

    // Para las camaras ortograficas solo permito panning, mientras que para las de perspectiva tambien hay rotacion.
    assignKey2Callback(KEYCODE.W, 16.666, forward_W);
    assignKey2Callback(KEYCODE.A, 16.666, truck_A);
    assignKey2Callback(KEYCODE.S, 16.666, forward_S);
    assignKey2Callback(KEYCODE.D, 16.666, truck_D);
    // Ojo, que estos se ejecutan al soltar la tecla.
    assignKey2Callback(KEYCODE.M, 50.0, zoom_M, "holdEnd");
    assignKey2Callback(KEYCODE.I, 50.0, info_I, "holdEnd");
    
    const availableKeys: string[] = ['K', 'W', 'A', 'S', 'D', 'M', 'I'];

    if ((control.getCamera() as THREE.PerspectiveCamera).isPerspectiveCamera === true) {
      assignKey2Callback(KEYCODE.ARROW_LEFT, 16.666, rotate_left);
      assignKey2Callback(KEYCODE.ARROW_RIGHT, 16.666, rotate_right);
      assignKey2Callback(KEYCODE.ARROW_UP, 16.666, rotate_up);
      assignKey2Callback(KEYCODE.ARROW_DOWN, 16.666, rotate_down);

      // La rotacion de 180 grados en el plano de suelo con la R.
      assignKey2Callback(KEYCODE.R, 100, rotate_R_180);

      // 45 grados lateral al ESTE.
      assignKey2Callback(KEYCODE.E, 100, rotate_E_90);

      // Subimos un metro para arriba sin variar direccion de vision.
      assignKey2Callback(KEYCODE.U, 100, up_U);
      // Pabajo.
      assignKey2Callback(KEYCODE.J, 100, down_J);

      availableKeys.push('Left', 'Right', 'Up', 'Down', 'R', 'E', 'U', 'J');
    }

    // Por ultimo siempre metemos la tecla K donde saldran todas las teclas disponibles.
    const help_K = (event: any) => {
      if (vp !== this.getActiveViewport()) {
        return;
      }
      const msg = `Available keys: [${availableKeys}].`;
      console.log(msg);
      // Mostramos el mensaje durante 6 segundos y luego desaparece.
      vp.setInfo(msg, true, 6);
    };

    assignKey2Callback(KEYCODE.K, 100, help_K, "holdEnd");

    console.error(`\tCreated ${vp.keystrokesCallbacks.length} callbacks.`);
  }

  /**
   * Genera un JSON con todos los datos de configuracion de los vp's presentes.
   */
  public exportToJSON(): Settings4GVP {
  }

  public importFromJSON(json: Settings4GVP): void {

  }

  public isZoomedState(): boolean {
    const N = this._vViewports.length;
    if (N === 1) {
      return false;
    }
    
    let numDisabled = 0;
    for (const vp of this._vViewports) {
      if (vp.enabled === false) {
        ++numDisabled;
      }
    }

    if (numDisabled) {
      return true;
    }
    return false;
  }

  private getCurrentSizes01(): [number, number, number, number][] {
    const vPrevSizes: [number, number, number, number][] = [];
    for (const vp of this._vViewports) {
      vPrevSizes.push([vp.left01, vp.top01, vp.width01, vp.height01]);
    }

    return vPrevSizes;
  }

  /**
   * Almacena el tamaño anterior del vp que sufre el zoom para poder recuperarlo.
   * Solo tendra tamaño cuando estemos en zoom; el resto del tiempo sera null.
   */
  private prevSize01: [number, number, number, number];

  /**
   * Simplemente efectua un zoom del vp activo en curso, o bien si estamos en estado de zoom deja las cosas como antes.
   * WARNING: Durante el zoom de ampliacion NO se puede borrar el unico vp disponible.
   * @returns 
   */
  private zoom(): void {
    // Hay 2 situaciones:
    // [A] Si estamos en tamaños normales, se "minimiza" a los demas vp's no activos y se expande al maximo al activo
    // (tras un guardado previo de su tamaño, solo el del vp activo).
    // [B] Si estamos en estado maximizado, logicamente del vp activo, se le devuelve a el a su tamaño original, y a los
    // demas se los "reactiva".
    // En ambos casos no cambia el vp activo.

    // Si solo hay un vp no tiene sentido.
    if (this._vViewports.length === 1) {
      return;
    }

    // Active VP.
    const aVP = this.getActiveViewport();

    // Veamos si estamos en estado [A] (tamaños normales) o [B] (ya tenemos un vp ampliado).
    // En el caso A se amplia el vp activo y se minimizan los restantes.
    if (this.isZoomedState() === false) {
      console.error(` >>> ZOOMING VP "${aVP.name}"`);
      // Ponemos todos los vp's al 0% menos al activo que le toca el 100%
      for (const vp of this._vViewports) {
        if (vp !== aVP) {
          // Simplemente basta con deshabilitar el vp y ocultar su div interno.
          vp.enabled = false;
          vp.elemHTML.hidden = true;
        } else {
          // Almacenamos el tamaño 01 previo a la ampliacion...
          this.prevSize01 = [vp.left01, vp.top01, vp.width01, vp.height01] as [number, number, number, number];
          // ...y maximizamos su superficie con los coef 01.
          vp.left01 = vp.top01 = 0.0;
          vp.width01 = vp.height01 = 1.0;
        }
      }

      // Desactivo todos los sliders para que no me jodan.
      this.iterate4AllSliders((sldr: HTMLDivElement): void => {
        sldr.hidden = true;
      });
    } else {
      console.error(` >>> UNDO ZOOMING VP "${aVP.name}"`);
      // Ahora toca dejarlo todo como estaba antes del zoom.
      for (const vp of this._vViewports) {
        if (vp !== aVP) {
          // Simplemente basta con deshabilitar el vp y ocultar su div interno.
          vp.enabled = true;
          vp.elemHTML.hidden = false;
        } else {
          // Recuperamos el tamaño 01 previo a la ampliacion...
          vp.left01 = this.prevSize01[0];
          vp.top01 = this.prevSize01[1];
          vp.width01 = this.prevSize01[2];
          vp.height01 = this.prevSize01[3];
          this.prevSize01 = null!;
        }
      }

      // Reactivo todos los sliders.
      this.iterate4AllSliders((sldr: HTMLDivElement): void => {
        sldr.hidden = false;
      });
    }
    
    this.owner.handleWindowResize();
  }

  private iterate4AllSliders(functor4Slider: (sldr: HTMLDivElement) => void): void {
    for (const value of this._divTree3.values()) {
      if (value.sons) {
        functor4Slider(value.sons.divisionSon);
      }
    }
  }


} // class GraphicViewportManager

