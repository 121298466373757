/**
 * \file xyzmo.ts
 * Implementacion de la clase XYZmo, nuestro propio gizmo rotacional.
 */

import { GraphicViewport } from "lib/graphic-viewport";
import * as THREE from "three";
import { CameraControls } from "./camera-controls/CameraControls";
import { SceneManager } from '../layers/scene-manager';

let boxGeometry: THREE.BufferGeometry | undefined = undefined;
let xAxisMaterial: THREE.MeshBasicMaterial | undefined = undefined;
let yAxisMaterial: THREE.MeshBasicMaterial | undefined = undefined;
let zAxisMaterial: THREE.MeshBasicMaterial | undefined = undefined;
let posXAxisMaterial: THREE.SpriteMaterial | undefined = undefined;
let posYAxisMaterial: THREE.SpriteMaterial | undefined = undefined;
let posZAxisMaterial: THREE.SpriteMaterial | undefined = undefined;
let negXAxisMaterial: THREE.SpriteMaterial | undefined = undefined;
let negYAxisMaterial: THREE.SpriteMaterial | undefined = undefined;
let negZAxisMaterial: THREE.SpriteMaterial | undefined = undefined;

export function clearXyzGyzmoSharedcomponents() {
  boxGeometry?.dispose();
  boxGeometry = undefined;

  xAxisMaterial?.dispose();
  xAxisMaterial = undefined;
  yAxisMaterial?.dispose();
  yAxisMaterial = undefined;
  zAxisMaterial?.dispose();
  zAxisMaterial = undefined;

  posXAxisMaterial?.map?.dispose();
  posXAxisMaterial?.dispose();
  posXAxisMaterial = undefined

  posYAxisMaterial?.map?.dispose();
  posYAxisMaterial?.dispose();
  posYAxisMaterial = undefined

  posZAxisMaterial?.map?.dispose();
  posZAxisMaterial?.dispose();
  posZAxisMaterial = undefined

  negXAxisMaterial?.map?.dispose();
  negXAxisMaterial?.dispose();
  negXAxisMaterial = undefined

  negYAxisMaterial?.map?.dispose();
  negYAxisMaterial?.dispose();
  negYAxisMaterial = undefined

  negZAxisMaterial?.map?.dispose();
  negZAxisMaterial?.dispose();
  negZAxisMaterial = undefined
}

/**
 * Nuesta propia implementacion de un gizmo rotacional, inspirada en la del editor de Three, que a su vez es la del Blender.
 * Ha sido una conversion fuertecita, ya que la version original es todo JS mierdoso de prototype, ducktyping y la puta de su madre,
 * con eventos y mierdas varias que en nuestro entorno es imposible que funcionen.
 * En esta version el gizmo se mueve solidariamente con la escena y admite doble click para poner la vista desde el eje pulsado.
 * Lo que NO admite es lo inverso: mover el gizmo y que la escena se mueva con solidaridad.
 * Suprimimos el panel y simplemente damos una esquina (x0, y0) y un tamaño del lado para crear el cuadrado hipotetico donde se pinta.
 * Ademas por peticion de JaWS hacemos lo mismo que en Blender: Si pulsas otra vez en algo en lo que ya estas automaticamente te vas
 * al opuesto. Es decir que pulso en la +Z y me voy a la misma. Luego vuelvo a pulsar en la misma y me iria automaticamente a -Z.
 */
export class XYZmo {

  /** Camara externa, la del editor exterior, necesaria para hacerla rotar y cambiar de angulo. */
  externCamera: THREE.Camera;
  /**
   * Coordenadas de la esquina superior izquierda del hipotetico cuadro del gizmo, referidas a un origen (0, 0) en la
   * esquina superior izquierda. Se extenderan hasta un maximo de las dimensiones limite del canvas contenedor menos
   * nuestas dimensiones,
   */
  x0: number;
  y0: number;
  /** Dimensiones del panel donde metemos el gizmo. */
  dimXY: number;
  /** Panel contenedor donde metemos al panel del gizmo. */
  parentContainer: HTMLDivElement;
  /** Es true cuando tenemos que efectuar la animacion correspondiente para colocar la camara externa en su posicion. */
  animating: boolean;
  /** La posicion del raton. */
  mouse: THREE.Vector2;
  /** La camara local que aqui se usa internamente para la visualizacion. */
  camera: THREE.OrthographicCamera;
  /** Objecto grafico con el grupo de todos los ejes, sprites y hostias. */
  gObj: THREE.Group;
  /** Al final metemos todo en una escena, tanto el grupo principal de ejes como los activadores (sprites estaticos de cierre y subdivisiones por derecha y por abajo). */
  scene: THREE.Scene;
  /** Los componentes de gObj por separado para el tema de las colisiones con el raton y saber que has pulsado o movido. Mas los 3 activadores. */
  vInteractiveGObjects: THREE.Object3D[];
  /** Un colisionador para saber que componente tocamos con el raton. */
  raycaster: THREE.Raycaster;
  /** Posicion, angulo y radio de giro que se aplicaran a la camara externa para que sufra lo mismo que el gizmo. */
  targetPosition: THREE.Vector3;
  targetQuaternion: THREE.Quaternion;
  radius: number;
  /**
   * La posicion del centro exterior en torno a la que giraremos. Ese sera el punto focal al que siempre miremos en las animaciones.
   * \ToDo: Dar infraestructura para que sea externamente seleccionable, si es que es necesario.
   */
  externCenter: THREE.Vector3;
  /** Un par de quaterniones creo que para angulo inicial y final con la animacion. */
  q1: THREE.Quaternion;
  q2: THREE.Quaternion;
  /** Ratio de giro para la animacion en angulos (aka radianes) por segundo. */
  turnRate: number;
  /** Reloj necesario para temporizar las animaciones debidas al dobleClick de ejes. */
  clock: THREE.Clock;

  /** Cadena con el ultimo eje seleccionado en curso. Si aun no lo hubiera sera cadena vacia. */
  selectedAxis: string;

  /** El viewport paterno es necesario para cuando se haga click sobre algun pseudoBoton del gizmo, para transmitirle la orden. */
  parentViewport: GraphicViewport;

  /** Andamiaje para poder utilizar la infraestructura de rotacion del CameraControls del viewport paterno. */
  useCameraControls: boolean;

  /** Calbacks especiales usando la infraestructura CameraControls que se disparan al inicio y fin del movimiento de la camara. */
  readonly startMovingCamera = () => {
    console.log("START-----------------------");
    if (!this.animating) {
      this.animating = true;
    } else {
      console.error("Not possible!!!.");
    }
  }

  readonly finishMovingCamera = () => {
    console.log("END-----------------------");
    if (this.animating) {
      this.animating = false;
    } else {
      console.error("Not possible!!!.");
    }
  }

  /**
   * Constructor con la infraestructura minima, despues de la cual hay que llamar al init() con sus parametros.
   * La idea es reducir al maximo las nuevas creaciones.
   * Ademas cuando el parametro 'isMenu4VP' es true creamos elementos graficos adicionales para posibilitar el control del viewport paterno.
   * 'isClosable' dice si hay elemento grafico de cierre y 'useCameraControls' permite usar la rotacion externa mediante la infraestructura
   * del propio viewport. En caso contrario usaremos la nuestra propia.
   *
   * @param {boolean} [isMenu4VP=true]
   * @param {boolean} [isClosable=true]
   * @param {GraphicViewport} parentViewport
   * @param {boolean} [useCameraControls=false]
   * @memberof XYZmo
   */
  constructor(isMenu4VP: boolean = true, isClosable: boolean = true, parentViewport: GraphicViewport, useCameraControls: boolean = false) {
    // Guardate a tu padre, que te hara falta...
    this.parentViewport = parentViewport;

    // Este flag permite usar la infraestructura del cameraControls del viewport paterno para ejecutar la animacion de rotacion.
    this.useCameraControls = useCameraControls;

    this.camera = new THREE.OrthographicCamera(-2, 2, 2, -2, 0, 4);
    // Aqui colocamos la camara, desde +Z mirando al plano XY desde +Z.
    this.camera.position.set(0, 0, 2);

    // Esta es la maldad grafica, donde formaremos el grupo gObj con el que compondremos todo el trampantojo.
    this.builtGraphicGyzmo(isMenu4VP, isClosable, parentViewport);

    // El reloj que temporiza animaciones, uno por cada gizmo que arrancamos y paramos cuando es necesario.
    // Lo creamos parado y lo activaremos al gestionar el doble click.
    this.clock = new THREE.Clock(false);
  }

  private builtGraphicGyzmo(isMenu4VP: boolean = true, isClosable: boolean = true, parentViewport: GraphicViewport) {

    this.vInteractiveGObjects = [];

    if (boxGeometry === undefined) boxGeometry = new THREE.BoxBufferGeometry(0.8, 0.05, 0.05).translate(0.4, 0, 0);

    const color1X = new THREE.Color('#ff3653');
    const color2Y = new THREE.Color('#8adb00');
    const color3Z = new THREE.Color('#2c8fff');

    if (xAxisMaterial === undefined) xAxisMaterial = new THREE.MeshBasicMaterial({ color: color1X, toneMapped: false });
    if (yAxisMaterial === undefined) yAxisMaterial = new THREE.MeshBasicMaterial({ color: color2Y, toneMapped: false });
    if (zAxisMaterial === undefined) zAxisMaterial = new THREE.MeshBasicMaterial({ color: color3Z, toneMapped: false });

    // 3 ejecitos de colores y 6 sprites (+X, +Y, +Z, -X, -Y, -Z).
    const xAxis = new THREE.Mesh(boxGeometry, xAxisMaterial);
    const yAxis = new THREE.Mesh(boxGeometry, yAxisMaterial);
    const zAxis = new THREE.Mesh(boxGeometry, zAxisMaterial);

    // El mesh del ejecito X no sufre rotacion ya que es el inicial y esta colocado correctamente.
    // El del ejecito Y es como el del X, pero aplicandole una rotacion de +90º (CCW) en torno a Z.
    yAxis.rotation.z = Math.PI / 2;
    // El del ejecito Z es como el del X, pero aplicandole -90º de rotacion (CW) en torno a Y
    zAxis.rotation.y = - Math.PI / 2;

    // Grupo con 3 ejes mas los 6 sprites correspondientes en los extremos de los ejes.
    this.gObj = new THREE.Group();
    this.gObj.add(xAxis);
    this.gObj.add(yAxis);
    this.gObj.add(zAxis);

    if (posXAxisMaterial === undefined) posXAxisMaterial = this.getSpriteMaterial(color1X, 'X');
    if (posYAxisMaterial === undefined) posYAxisMaterial = this.getSpriteMaterial(color2Y, 'Y');
    if (posZAxisMaterial === undefined) posZAxisMaterial = this.getSpriteMaterial(color3Z, 'Z');
    if (negXAxisMaterial === undefined) negXAxisMaterial = this.getSpriteMaterial(color1X);
    if (negYAxisMaterial === undefined) negYAxisMaterial = this.getSpriteMaterial(color2Y);
    if (negZAxisMaterial === undefined) negZAxisMaterial = this.getSpriteMaterial(color3Z);

    // Los nombres ayudaran a distinguirlos.
    const posXAxisHelper = new THREE.Sprite(posXAxisMaterial);
    posXAxisHelper.name = '+X';
    const posYAxisHelper = new THREE.Sprite(posYAxisMaterial);
    posYAxisHelper.name = '+Y';
    const posZAxisHelper = new THREE.Sprite(posZAxisMaterial);
    posZAxisHelper.name = '+Z';
    const negXAxisHelper = new THREE.Sprite(negXAxisMaterial);
    negXAxisHelper.name = '-X';
    const negYAxisHelper = new THREE.Sprite(negYAxisMaterial);
    negYAxisHelper.name = '-Y';
    const negZAxisHelper = new THREE.Sprite(negZAxisMaterial);
    negZAxisHelper.name = '-Z';

    posXAxisHelper.position.x = 1;
    posYAxisHelper.position.y = 1;
    posZAxisHelper.position.z = 1;
    negXAxisHelper.position.x = -1;
    negXAxisHelper.scale.setScalar(0.8);
    negYAxisHelper.position.y = -1;
    negYAxisHelper.scale.setScalar(0.8);
    negZAxisHelper.position.z = -1;
    negZAxisHelper.scale.setScalar(0.8);

    this.gObj.add(posXAxisHelper);
    this.gObj.add(posYAxisHelper);
    this.gObj.add(posZAxisHelper);
    this.gObj.add(negXAxisHelper);
    this.gObj.add(negYAxisHelper);
    this.gObj.add(negZAxisHelper);

    // Recuerda que ahora para que funcione bien la parte estatica todo debe estar en una escena, por cortesia de las "peculiaridades" de Three.js...
    this.scene = new THREE.Scene();
    this.scene.add(this.gObj);

    // Para reducir las intersecciones solo consideramos los clicks sobre los sprites axiales.
    this.vInteractiveGObjects.push(posXAxisHelper);
    this.vInteractiveGObjects.push(posYAxisHelper);
    this.vInteractiveGObjects.push(posZAxisHelper);
    this.vInteractiveGObjects.push(negXAxisHelper);
    this.vInteractiveGObjects.push(negYAxisHelper);
    this.vInteractiveGObjects.push(negZAxisHelper);

    if (isMenu4VP) {
      // Atentos, que en este caso necesitaremos 3 sprites:
      //                                                      +----------+
      //                                                      |         X|    Para cerrar el vierport propietario, reintegrandolo si es posible a su original.
      //                                                      |         >|    Para abrir un nuevo viewport por la derecha, partiendo el original verticalmente.
      //                                                      |         v|    Para abrir un nuevo viewport por debajo, partiendo el original horizontalmente.
      // [1] Arriba a la derecha la "X" para la destruccion del viewport.
      const closeVP = (isClosable) ? new THREE.Sprite(this.getSpriteMaterial(new THREE.Color('#FFFFFF'), "X", false)) : null;
      const scale = 0.5;
      const x = -2.75;
      let y = -2.75;
      const incY = +1.25;

      if (closeVP) {
        closeVP.name = "closeVP";
        // Reduccion para hacerlo del tamaño deseado.
        closeVP.scale.setScalar(scale);
        // Esto por raro que pareza es lo que lo coloca correctamente arriba a la derecha.
        closeVP.center.set(x, y);
        // Con este posicionamiento espero ponerlo por delante de las bolas del gizmo, de forma que siempre reciba primero el dblClck.
        closeVP.position.z = 2;
        this.scene.add(closeVP);
        this.vInteractiveGObjects.push(closeVP);
        y += incY;
      }

      // [2] Justo debajo de la "X" tendriamos la flecha apuntando a la derecha, que sirve para abrir un nuevo viewport partido con el actual a la derecha.
      // PERO SOLO SI EL VIEWPORT paterno lo tenia activado.
      if (true) {
        const subDiv2RightVP = new THREE.Sprite(this.getSpriteMaterial(new THREE.Color('pink'), "→", false));
        subDiv2RightVP.name = "rightVP";
        subDiv2RightVP.scale.setScalar(scale);
        subDiv2RightVP.center.set(x, y);
        subDiv2RightVP.position.z = 2;
        this.scene.add(subDiv2RightVP);
        this.vInteractiveGObjects.push(subDiv2RightVP);
      }
      y += incY;

      // [3] La flecha apuntando abajo, para la subdivision por debajo, pero solo si es posible.
      if (true) {
        const subDiv2DownVP = new THREE.Sprite(this.getSpriteMaterial(new THREE.Color('orange'), "↓", false));
        subDiv2DownVP.name = "downVP";
        subDiv2DownVP.scale.setScalar(scale);
        subDiv2DownVP.center.set(x, y);
        subDiv2DownVP.position.z = 2;
        this.scene.add(subDiv2DownVP);
        this.vInteractiveGObjects.push(subDiv2DownVP);
      }
    }
  }
  // Funcion auxiliar que crea los 6 sprites en los ejes.
  private getSpriteMaterial(color: THREE.Color, text?: string, isCircle: boolean = true): THREE.SpriteMaterial {
    const canvas = document.createElement('canvas');
    // Esto es lo que hace el circulito relleno.
    if (isCircle) {
      canvas.width = 64;
      canvas.height = 64;
      const context = canvas.getContext('2d') as CanvasRenderingContext2D;
      context.beginPath();
      // Arco de (x, y, radio, anguloInicio, anguloFin), que es la circunferencia rellena.
      context.arc(32, 32, 16, 0, 2 * Math.PI);
      context.closePath();
      context.fillStyle = color.getStyle();
      context.fill();
      if (text) {
        context.font = '24px Arial';
        context.textAlign = 'center';
        context.fillStyle = '#000000';
        context.fillText(text, 32, 41);
      }
    } else {
      // Cuadradito.
      canvas.width = 32;
      canvas.height = 32;
      const context = canvas.getContext('2d') as CanvasRenderingContext2D;
      context.rect(1, 1, 31, 31);
      context.fillStyle = color.getStyle();
      context.fill();
      if (text) {
        context.font = '24px Arial';
        context.textAlign = 'center';
        context.fillStyle = '#000000';
        context.fillText(text, 16, 26);
      }
    }

    const texture = new THREE.CanvasTexture(canvas);
    return new THREE.SpriteMaterial({ map: texture, toneMapped: false });
  }

  public subscribeMouseEvents() {
    this.parentContainer.addEventListener("dblclick", this.mouseClickHandler)
  }
  public unSubscribeMouseEvents() {
    this.parentContainer.removeEventListener("dblclick", this.mouseClickHandler)
  }
  private mouseClickHandler = (event: MouseEvent) => {
    const x0 = event.offsetX;
    const y0 = event.offsetY;
    console.log(`OK Gizmo ${this.parentViewport.name}: (${x0}, ${y0})`);
    const XY = this.inViewport(x0, y0);
    if (XY) {
      const touch = this.processDoubleClickXY(XY.x, XY.y);
      if (touch) {
        event.stopPropagation();
      }
    }
  }

  /**
   * Por que sera que todo lo que es construido tiene que ser destruido...
   * El objetivo aqui es que no quede ni una sola posible referencia con vida, aunque nos pasemos...
   */
  public destroy(): void {
    this.unSubscribeMouseEvents();
    this.externCamera = undefined!;
    // Quizas haya que quitarlo de algun sitio...
    this.parentContainer = undefined!;
    this.mouse = undefined!;
    this.camera = undefined!;
    this.gObj = undefined!;

    this.vInteractiveGObjects.length = 0;
    this.vInteractiveGObjects = undefined!;
    this.raycaster = undefined!;

    SceneManager.sceneDestruction(this.scene, false);
    this.scene = undefined!;

    this.targetPosition = undefined!;
    this.targetQuaternion = undefined!;
    this.externCenter = undefined!;
    this.q1 = undefined!;
    this.q2 = undefined!;
    this.clock = undefined!;
    this.selectedAxis = undefined!;
    // Antes de nada eliminamos los posibles callbacks de uso de la infraestructura CameraControls.
    if (this.parentViewport.controls) {
      const cntrls = this.parentViewport.controls as CameraControls;
      cntrls.removeEventListener('wake', this.startMovingCamera);
      cntrls.removeEventListener('sleep', this.finishMovingCamera);
    }
    this.parentViewport = undefined!;
  }

  /**
   * Hace que los ejes asociados al gizmo no puedan ser pulsados.
   *
   * @returns {boolean}
   * @memberof XYZmo
   */
  setNonClickableAxes(): boolean {
    if (this.vInteractiveGObjects.length >= 6) {
      const vNames: string[] = ['+X', '+Y', '+Z', '-X', '-Y', '-Z'];
      // Comprobamos que tenemos como intersectables a esos 6 nombres y en ese preciso orden.
      for (let i = 0; i < 6; ++i) {
        if (this.vInteractiveGObjects[i].name !== vNames[i]) {
          return false;
        }
      }
      // Tenemos los 6 chorongos. Los eliminamos como colisionables?. Si, pero no los quitamos del array, ya que se usan en
      // otras zonas de codigo. Simplemente los quito de que intervengan en colisiones.
      for (let i = 0; i < 6; ++i) {
        this.vInteractiveGObjects[i].raycast = () => { };
      }
      return true;
    }
    return false;
  }

  /**
   * Aqui podemos inicializar realmente el gizmo con el tamaño dado en la coordenada dada, controlando la camara
   * dada y dentro del contenedor dado. Ojo que (x, y) seria su esquina superior derecha y el gizmo se extenderia
   * dimXY tanto en anchura como en altura y esos 3 valores son dados en pixels.
   *
   * @param {number} x
   * @param {number} y
   * @param {number} dimXY
   * @param {THREE.Camera} externOuterCam
   * @param {HTMLDivElement} container
   * @memberof XYZmo
   */
  init(x: number, y: number, dimXY: number, externOuterCam: THREE.Camera, container: HTMLDivElement): void {
    this.x0 = x;
    this.y0 = y;
    this.dimXY = dimXY;

    this.selectedAxis = "";

    this.externCamera = externOuterCam;
    this.parentContainer = container;

    this.animating = false;
    this.raycaster = new THREE.Raycaster();

    this.targetPosition = new THREE.Vector3();
    this.targetQuaternion = new THREE.Quaternion();
    this.radius = 0;
    this.q1 = new THREE.Quaternion();
    this.q2 = new THREE.Quaternion();
    this.externCenter = new THREE.Vector3();
    // Multiplicamos la velocidad de giro por este factor, de forma que cuanto menor sea esta mas gradual es la animacion.
    const factor = 0.25;
    this.turnRate = factor * 2 * Math.PI;
  }

  /**
   * Sera llamado desde el exterior ante un cambio de tamaño de la aplicacion, con los nuevos datos de anchura y altura.
   *
   * @memberof XYZmo
   */
  handleResize(newWidth: number, newHeight: number): void {
    // Desplazamos la esquina superior izquierda del gizmo, pero solo la x, pues la y sigue con valor 0.
    this.x0 = newWidth - this.dimXY;
    // Posiblemente sea opcional, ya que la proporcion no varia.
    this.camera.updateMatrixWorld();
  }

  /**
   * Una lambda expresion para saber si unas coordenadas (x, y) caen dentro del hipotetico viewport del gizmo.
   * Ojo que las coordenadas de entrada (x, y) son de pantalla y en caso de caer en el viewport las normalizamos en [0, dimXY).
   * En caso de que caigan devolvemos {x: x', y: y'} con las coordenadas adaptadas al viewport del gizmo, y si no un buen null.
   * @param {number} x
   * @param {number} y
   * @param {GraphicViewport} viewport
   * @returns {boolean}
   */
  readonly inViewport = (x: number, y: number): { x: number, y: number } | null => {
    // Ojo, que para evitar problemas requerimos estar en el interior TOTAL, no tocando la frontera.
    if (x <= this.x0 || this.x0 + this.dimXY <= x) {
      return null;
    }
    if (y <= this.y0 || this.y0 + this.dimXY <= y) {
      return null;
    }
    return {
      x: x - this.x0,
      y: y - this.y0
    };
  };

  /**
   * Gestion de la seleccion externa: Es llamado automaticamente desde el exterior cada vez que se nos hace un doble click
   * dentro de los dominios XY de nuestro gizmo. Las coordenadas nos llegan ya normalizadas en el intervalo [0, dimXY].
   * Aqui comprobamos si esto afecta a alguno de los 6 ejes/sprites y en tal caso se ordena la colocacion de esa vista.
   * 
   * @returns true si las (x, y) dadas tocan algo que obliga a un cambio o false en caso contrario.
   */
  private processDoubleClickXY(x: number, y: number): boolean {
    // Hay un problema a veces: Puede coincidir POSICIONALMENTE un gizmo de un viewport sobre el gizmo de mainView.
    // Y si hacemos dobleClick para cerrar ese viewport secundario tambien estariamos pulsando en el gizmo de mainView
    // para agregar nuevos viewports en subdivision vertical. Vaya lio!!!.
    // Solucion: Solo se permite atender al que esta en curso. Asi que sacamos al padre VP y al abuelo GP.
    const fatherVP = this.parentViewport;
    if (!fatherVP) {
      console.warn("ERROR: Gizmo without parent viewport???...");
      debugger;
      return false;
    }
    const ownerGPO = fatherVP.owner.owner;
    if (!ownerGPO) {
      window.alert("ERROR: Gizmo without grandfather graphicProcessor???...");
      debugger;
      return false;
    } else {
      if (ownerGPO.getActiveViewport() !== fatherVP) {
        console.error("ATENCION: Cagando fuera de tu pota...");
        console.log(`\tHas pulsado en el gizmo del vp "${fatherVP.name}" ${fatherVP.enabled ? "ACTIVO" : "INACTIVO"}`);
        console.log(`\tque no coindice con el vp activo en curso "${ownerGPO.getActiveViewport().name}".`);
        return false;
      }
    }

    // Si el vp dueño no esta activo no debiera sufrir esta llamada.
    if (!fatherVP.enabled) {
      console.error(`Has pulsado en el gizmo del vp "${fatherVP.name}" que estava INACTIVO.`);
      debugger;
      return false;
    }

    // Mientras esta animando pasamos de todos los clicks.
    if (this.animating === true) {
      return false;
    }

    // Con la posicion del raton "dentro del hipotetico panel" componemos el rayo para ver si tocamos sprite.
    // Busco coordenadas en [-1, 1] dentro de [0, dimXY].
    x = (x / this.dimXY) * 2 - 1;
    y = -(y / this.dimXY) * 2 + 1;

    const mouse = new THREE.Vector2(x, y);
    this.raycaster.setFromCamera(mouse, this.camera);
    // Recuerda, no calculamos las intersecciones con los hijos del grupo, sino solo con los 6 sprites; y sin recursividad (el false).
    const vIntersects = this.raycaster.intersectObjects(this.vInteractiveGObjects, false);
    const numIntersects = vIntersects.length;

    if (numIntersects > 0) {
      // if (numIntersects > 1) {
      //   window.alert("ATENCION: Hay " + numIntersects + " intersecciones!!!.");
      //   for (let i = 0; i < numIntersects; ++i) {
      //     console.log("Intersection[" + i + "] ===> " + vIntersects[i].object.name);
      //   }
      // }

      // Nos quedamos con el primero, el mas cercano.
      const intersection = vIntersects[0];
      let object = intersection.object;

      // Podriamos estar pulsando alguno de los nuevos activadores (cerrar/subdividirVH viewport).
      if (object.name[0] !== "+" && object.name[0] !== "-") {
        console.log(`CLICKED OPTION: ${object.name} in vp "${fatherVP.name}".`);

        if (object.name === "downVP" || object.name === "rightVP") {
          // Se crea un hermano vertical u horizontal.
          const isVertical = (object.name === "rightVP");
          const newVP = ownerGPO.managerVP.subdivision4Viewport(fatherVP, isVertical);
          if (!newVP) {
            console.error("\tERROR: Can't subdivide this!!!.");
            return false;
          } else {
            console.log(`\tAdded new viewport "${newVP.name}".`);
          }
        } else {
          if (object.name === "closeVP") {
            // Toca destruir a este viewport, (que es totalmente destruible, ya que en caso contrario no tendria ese boton),
            // y devolver ese espacio sobrante a su hermano generador, si es que es ello posible...
            // Ademas esa destruccion debe ser diferida para evitar problemas.
            ownerGPO.managerVP.addViewport2Delete(fatherVP);
          } else {
            console.error(`ERROR: Opcion "${object.name}" aun no implementada.`);
            return false;
          }
        }
        return true;
      }

      console.log("CLICKED AXIS: " + object.name);
      if (object.name === this.selectedAxis) {
        // Al pulsar otra vez sobre el eje donde ya estabamos previamente pasamos como en Blender: Al eje opuesto.
        // Simple cambio de signo en el mismo nombre.
        this.selectedAxis = (object.name[0] === "+" ? "-" : "+") + object.name[1];
        console.log("REVERSE SELECTION TO: " + this.selectedAxis);
        // Y cogemos el objeto grafico correspondiente.
        for (let i = 0; i < 6; ++i) {
          if (this.vInteractiveGObjects[i].name === this.selectedAxis) {
            object = this.vInteractiveGObjects[i];
            break;
          }
        }
      } else {
        this.selectedAxis = object.name;
      }

      if (this.useCameraControls) {
        this.prepareAnimationDataUsingCameraControls(object, this.externCenter);
        this.animating = false;
        console.log("Starting animation by cameraControls..");
      } else {
        this.prepareAnimationData(object, this.externCenter);
        this.animating = true;
        console.log("Starting animation.");
      }

      return true;
    }

    return false;
  }

  private getDestinationData(object: THREE.Object3D, dstPos: THREE.Vector3, dstRot: THREE.Vector3, angles?: [number, number]): boolean {
    let alpha = +Infinity;
    let beta = +Infinity;
    switch (object.name) {
      case '+X':
        dstPos.set(1, 0, 0);
        dstRot.set(0, Math.PI * 0.5, 0);
        if (angles) {
          alpha = 90;
          beta = 90;
        }
        break;
      case '+Y':
        dstPos.set(0, 1, 0);
        dstRot.set(-Math.PI * 0.5, 0, 0);
        if (angles) {
          alpha = 2 * 90;
          beta = 90;
        }
        break;
      case '+Z':
        dstPos.set(0, 0, 1);
        // Como la camara esta colocada en el propio eje Z, no hace falta angulo que rotar para llegar al mismo.
        dstRot.set(0, 0, 0);
        if (angles) {
          alpha = 0;
          beta = 0;
        }
        break;
      case '-X':
        dstPos.set(-1, 0, 0);
        dstRot.set(0, -Math.PI * 0.5, 0);
        if (angles) {
          alpha = 3 * 90;
          beta = 90;
        }
        break;
      case '-Y':
        dstPos.set(0, -1, 0);
        dstRot.set(Math.PI * 0.5, 0, 0);
        if (angles) {
          alpha = 0;
          beta = 90;
        }
        break;
      case '-Z':
        dstPos.set(0, 0, -1);
        dstRot.set(0, Math.PI, 0);
        if (angles) {
          alpha = 0;
          beta = 2 * 90;
        }
        break;
      default:
        console.error("ERROR XYZmo: Eje de nombre invalido: '" + object.name + "'.");
        debugger;
        return false;
    }

    if (angles) {
      angles[0] = alpha * THREE.MathUtils.DEG2RAD;
      angles[1] = beta * THREE.MathUtils.DEG2RAD;
    }

    return true;
  }

  /**
   * Prepara todos los datos necesarios para ejecutar una animacion GRADUAL que se movera desde la orientacion actual de la escena
   * hacia la orientacion del eje/sprite seleccionado (+X, -X, +Y, -Y, +Z, -Z).
   *
   * @private
   * @param {THREE.Object3D} object
   * @param {THREE.Vector3} focusPoint
   * @returns {void}
   * @memberof XYZmo
   */
  private prepareAnimationData(object: THREE.Object3D, focusPoint: THREE.Vector3): void {

    // Posicion destino.
    const dstPos = new THREE.Vector3();
    // Rotacion destino dada mediante los angulos de Euler.
    const dstRot = new THREE.Vector3();

    if (!this.getDestinationData(object, dstPos, dstRot)) {
      return;
    }

    // Asignamos la posicion destino (X, Y, Z) asi como su quaternion final, creado este ultimo a partir de los 3 angulos de Euler.
    this.targetPosition.set(dstPos.x, dstPos.y, dstPos.z);
    const dstRotEuler = new THREE.Euler(dstRot.x, dstRot.y, dstRot.z);
    this.targetQuaternion.setFromEuler(dstRotEuler);

    console.log(`Posicion destino: (${this.targetPosition.x}, ${this.targetPosition.y}, ${this.targetPosition.z})`);
    // Tomamos como radio para esa trayectoria la distancia de la camara al punto de interes.
    this.radius = this.externCamera.position.distanceTo(focusPoint);
    // Esta es la posicion final respecto al objeto de interes tomando en cuenta el radio mas la posicion del gizmo solicitada.
    this.targetPosition.multiplyScalar(this.radius).add(focusPoint);

    // En la posicion de interes miramos a la camara externa...
    const dummyObserver = new THREE.Object3D();
    dummyObserver.position.copy(focusPoint);
    dummyObserver.lookAt(this.externCamera.position);
    // Y tras mirar a la camara externa sacamos su quaternion que es la rotacion de ORIGEN.
    this.q1.copy(dummyObserver.quaternion);
    // Y luego (en esa misma posicion) lo ponemos a mirar a la posicion destino, sacamos su quaternion y esa es la rotacion destino.
    dummyObserver.lookAt(this.targetPosition);
    this.q2.copy(dummyObserver.quaternion);

    // Y activamos el reloj del sistema para temporizar la animacion que se viene.
    this.clock.start();
  }

  private prepareAnimationDataUsingCameraControls(object: THREE.Object3D, focusPoint: THREE.Vector3): void {
    // Posicion destino.
    const dstPos = new THREE.Vector3();
    // Rotacion destino dada mediante los angulos de Euler.
    const dstRot = new THREE.Vector3();

    const angles: [number, number] = [Infinity, Infinity];

    if (!this.getDestinationData(object, dstPos, dstRot, angles)) {
      return;
    }

    const [alpha, beta] = angles;
    const cntrls = this.parentViewport.controls as CameraControls;

    cntrls.addEventListener('wake', this.startMovingCamera);
    cntrls.addEventListener('sleep', this.finishMovingCamera);

    cntrls.rotateTo(alpha, beta, true);
    cntrls.update(1 / 60);
  }

  /**
   * Funcion de actualizacion para la animacion ejecutada cuando seleccionamos un eje en el gizmo.
   * En caso de acabar la animacion devuelve un false para informar al exterior.
   */
  updateRotationAnimation(): boolean {
    if (!this.animating) {
      debugger;
    }

    // Del reloj sacamos el incremento acumulativo de tiempo.
    const delta = this.clock.getDelta();
    const step = delta * this.turnRate;
    const focusPoint = this.externCenter;

    // Anima la posicion haciendo un slerp y luego escalando la posicion en/sobre la esfera unitaria.
    // Con esto se hace rotar a q1 hacia q2 pero en un paso angular (en radianes) dado por step. Ademas NUNCA superaria q2.
    this.q1.rotateTowards(this.q2, step);
    // Hemos variado q1 y colocamos la camara externa en esa misma posicion radialmente...
    this.externCamera.position.set(0, 0, 1).applyQuaternion(this.q1).multiplyScalar(this.radius).add(focusPoint);

    // Si hacemos esto, variando la orientacion de la camara esta cabeceara y no estara vertical.
    // this.externCamera.quaternion.rotateTowards(this.targetQuaternion, step);
    // Esto la mantiene erguida cual polla enhiesta.
    this.externCamera.lookAt(focusPoint);

    // Ademas movemos solidariamente al propio gizmo traidor. DE PUTA MADRE FUNCIONA!!!.
    this.gObj.quaternion.copy(this.externCamera.quaternion).invert();
    this.gObj.updateMatrixWorld();

    // Fin de la animacion/trayectoria alcanzado?.
    // Calculamos el angulo en radianes entre ambos quaterniones (siempre positivo) y lo comparamos con un epsilon.
    const alpha = this.q1.angleTo(this.q2);
    const epsilon = 0.0001;
    // console.log("ANIMATION ===> alpha: " + alpha + "    delta: " + delta);
    if (alpha < epsilon) {
      console.log("End of axial rotation.");
      this.animating = false;
      // Se acabo la animacion, luego detenemos el reloj para evitar acumulaciones indebidas.
      this.clock.stop();

      if (alpha !== 0.0) {
        console.log(alpha);
      }

      return false;
    }
    // La animacion aun no ha acabado.
    return true;
  }

  /**
   * Renderizador del gizmo al que se le dan externamente las coordenadas para hacer el scissoring.
   * Me ha costado un cojon y parte del otro que esto funcionara siempre correctamente.
   *
   * @param {THREE.WebGLRenderer} renderer
   * @param {number} xSc
   * @param {number} ySc
   * @memberof XYZmo
   */
  render(renderer: THREE.WebGLRenderer, xSc: number, ySc: number): void {
    const pointAux = new THREE.Vector3();

    // Mueve el gizmo solidariamente con la camara externa.
    this.gObj.quaternion.copy(this.externCamera.quaternion).invert();
    this.gObj.updateMatrixWorld();
    // Eso sera para que apunte siempre parriba???.
    pointAux.set(0, 0, 1);
    pointAux.applyQuaternion(this.externCamera.quaternion);

    // Cambios de color de los sprites: Como el Blender...
    if (true) {
      const posXAxisHelper = this.vInteractiveGObjects[0] as THREE.Sprite;
      const posYAxisHelper = this.vInteractiveGObjects[1] as THREE.Sprite;
      const posZAxisHelper = this.vInteractiveGObjects[2] as THREE.Sprite;
      const negXAxisHelper = this.vInteractiveGObjects[3] as THREE.Sprite;
      const negYAxisHelper = this.vInteractiveGObjects[4] as THREE.Sprite;
      const negZAxisHelper = this.vInteractiveGObjects[5] as THREE.Sprite;

      if (pointAux.x >= 0) {
        posXAxisHelper.material.opacity = 1;
        negXAxisHelper.material.opacity = 0.5;
      } else {
        posXAxisHelper.material.opacity = 0.5;
        negXAxisHelper.material.opacity = 1;
      }

      if (pointAux.y >= 0) {
        posYAxisHelper.material.opacity = 1;
        negYAxisHelper.material.opacity = 0.5;
      } else {
        posYAxisHelper.material.opacity = 0.5;
        negYAxisHelper.material.opacity = 1;
      }

      if (pointAux.z >= 0) {
        posZAxisHelper.material.opacity = 1;
        negZAxisHelper.material.opacity = 0.5;
      } else {
        posZAxisHelper.material.opacity = 0.5;
        negZAxisHelper.material.opacity = 1;
      }
    }

    // Tenemos la cosa de que cuando nos movemos solidariamente con la escena, sin animacion por medio, podemos estar cambiando
    // sin querer o medio queriendo el selectedAxis, que obviamente no tiene por que coincidir con el previo. Lo solucionamos.
    if (!this.animating) {
      if (this.updateCurrentAxis(pointAux)) {
        // console.log(pointAux);
        // console.log("Estamos en el eje '" + this.selectedAxis + "'." );
      }
    }

    // Parece que esto es muy necesario para evitar problemas.
    renderer.clearDepth();
    // El scissoring lo he agregado yo, pues en el original no lo habia.
    // RECUERDA: En setScissor() y setViewport() la coordenada Y va al reves, empieza en 0 ABAJO.
    renderer.setScissorTest(true);
    renderer.setScissor(xSc, ySc, this.dimXY, this.dimXY);
    renderer.setViewport(xSc, ySc, this.dimXY, this.dimXY);
    renderer.render(this.scene, this.camera);
    // Finalmente desactivamos el tijereteo.
    renderer.setScissorTest(false);
    // ATENCION: Al salir de aqui debemos asegurarnos de que el renderer vuelve a tener el tamaño original, mediante una
    // subsecuente llamada a renderer.setSize(width, height, true) hecha desde el exterior.
    // Si no se hace esa llamada lo que queda es un batiburrillo con todo en el area del gizmo.
  }

  /**
   * En funcion del vector dado actualiza el dmc selectedAxis, que podria ser alguno de los 6 posibles o "" en caso de estar en una
   * situacion de rotacion que no cae en ningun eje. Ademas en caso de que caiga en alguno de los ejes devolvemos true.
   *
   * @param {THREE.Vector3} v
   * @returns {boolean}
   * @memberof XYZmo
   */
  updateCurrentAxis(v: THREE.Vector3): boolean {
    // Si cae en alguno de los ejes tendremos una componente practicamente 1 (o -1) y las otras 2 practicamente 0.
    // Asi que tendremos las odiosas comparaciones...
    const epsilon = 0.0001;
    if (v.x > 1.0 - epsilon) {
      this.selectedAxis = "+X";
      return true;
    }
    if (v.x < -1.0 + epsilon) {
      this.selectedAxis = "-X";
      return true;
    }
    if (v.y > 1.0 - epsilon) {
      this.selectedAxis = "+Y";
      return true;
    }
    if (v.y < -1.0 + epsilon) {
      this.selectedAxis = "-Y";
      return true;
    }
    if (v.z > 1.0 - epsilon) {
      this.selectedAxis = "+Z";
      return true;
    }
    if (v.z < -1.0 + epsilon) {
      this.selectedAxis = "-Z";
      return true;
    }
    // No hay un eje concreto definido.
    this.selectedAxis = "";
    return false;
  }

} // class XYZmo
