import { ArcAngleDimensionEditDataCommand, ArcLongDimensionEditDataCommand } from "lib/commands/dimension/arc-dim";

import { ArcDimensionData, arcDimensionParam, arcDimType } from "lib/models/dimension/arc-dim";
import { DimensionDataDefinitionHandler, dimensionStyleParam } from "./dimension";

export class ArcDimensionDefinitionHandler extends DimensionDataDefinitionHandler<arcDimensionParam> {

  protected data: ArcDimensionData;

  public saveAndRegenerateStyle = (newStyle: dimensionStyleParam) => {
    const arcDimensionDefinition = this.checkAndChangedStyle(newStyle) as arcDimensionParam;
    if (arcDimensionDefinition) {
      let fnCommand = null;
      if (arcDimensionDefinition.type === arcDimType.ANG) {
        fnCommand = ArcAngleDimensionEditDataCommand;
      } else if (arcDimensionDefinition.type === arcDimType.LONG) {
        fnCommand = ArcLongDimensionEditDataCommand;
      }
      if (fnCommand) {
        const command = new fnCommand(this.data, arcDimensionDefinition, this.graphicProcessor);
        if (command) this.graphicProcessor.storeAndExecute(command);
      }
    }
  };
}