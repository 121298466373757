import { GraphicProcessor } from "lib/graphic-processor";
import { copyIPoint, removeContigousEqualPoints } from "lib/math/point";
import { IPoint } from "lib/math/types";
import { objDataType } from "lib/models/types";
import { growthWidth, storeyMaterialType } from "lib/models-struc/types/struc-base";
import { beamParam } from "lib/models-struc/types/beam";
import { cadOpType } from "lib/operations/factory";
import { createStructuralElementCommand } from "./struc-simple";
import { EditBaseStructCommand } from "./struc-edit-base";
import { BeamData } from "lib/models/structural/beam";

export class BeamCommand extends createStructuralElementCommand {

  protected elemType: objDataType.BEAM = objDataType.BEAM;
  protected opType = cadOpType.BEAM;
  protected structuralParam: beamParam[] = [];
  public createdObjs: BeamData[] = [];

  constructor(
    growthType: growthWidth,
    materialType: storeyMaterialType,
    points: IPoint[],
    crossSectionId: string,
    basePoint: IPoint,
    rotation: IPoint,
    offset: IPoint,
    scale: IPoint,
    graphicProc: GraphicProcessor,
  ) {
    const strucMng = graphicProc.getStructuralModelManager();
    const storeyId = strucMng.currBuilding.getStoreyFromLevel(basePoint.z).id;
    const layerId = strucMng.getLayerByStoreyIdAndStructuralType(storeyId, objDataType.BEAM).id;
    super(storeyId, layerId, graphicProc);

    const name = strucMng.currBuilding.getNextBeamName();
    const cleanPoints = removeContigousEqualPoints(points.map(copyIPoint));
    this.structuralParam.push({
      name,
      storeyId,
      lnkObjIds: [],
      materialType,

      orientation: 0,
      crossSectionId,
      points: cleanPoints,
      growthType: growthType,
      basePoint: copyIPoint(basePoint),
      rotation: copyIPoint(rotation),
      offset: copyIPoint(offset),
      scale: copyIPoint(scale),
    });
  }
}

export class BeamEditDataCommand extends EditBaseStructCommand {
  protected opType = cadOpType.EDITBEAM;
}
