import CloseIcon from "modules/cad/components/icons/close";
import PropertiesSelect from "modules/cad/components/properties/component-select";
import PanelPropertiesForm from "modules/cad/components/properties/properties-panel-form";
import ToolbarButton from "modules/cad/components/toolbar/toolbar-button";
import { ChangeEvent, FC } from "react";
import TrashIcon from "shared/components/icons/trash";
import { SecondaryButton } from "shared/components/ui/button";
import styled from "styled-components";
import { useDeleteHypothesis } from "./hooks/use-delete-hypothesis";

interface Props {
  onClose: () => void;
}
const DeleteHypothesisForm: FC<Props> = ({ onClose }) => {

  const {
    hypothesisSets,
    currHypothesis,
    setCurrHypothesisName,
    hypothesisProperties,
    editHypothesisProperties,
    deleteHypothesisSet,
  } = useDeleteHypothesis();

  return (
    <Wrapper className="text-xs">
      <Header>
        <h1>Delete hypothesis</h1>
        <button onClick={onClose}>
          <CloseIcon className="h-4 w-4" />
        </button>
      </Header>
      <Content>
        <ActionBar>
          <label className={"text-xs text-gray-400"}>{"Hypothesis:"}</label>
          <PropertiesSelect
            srOnly={true}
            value={currHypothesis?.name}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              setCurrHypothesisName(event.target.value);
            }}
            wheelEvent={true}
          >
            {currHypothesis ? hypothesisSets.map((val, i) => (
              <option key={val.name} value={val.name}> {val.name} </option>
            )) : <option key={"none"} value={"None"}> {"Empty hypothesis list"} </option>}
          </PropertiesSelect>
          {currHypothesis &&
            <ToolbarButton onClick={deleteHypothesisSet} tooltip="Delete Hypothesis">
              <TrashIcon className="h-6 hover:text-red-400" />
            </ToolbarButton>
          }
        </ActionBar>
        {currHypothesis &&
          <ActionBar0 className="layers">
            <PanelPropertiesForm panelProp={{
              propValue: hypothesisProperties,
              propCallback: editHypothesisProperties
            }} />
          </ActionBar0>
        }
        <BottomToolBar>
          <SecondaryButton onClick={onClose}>
            Close
          </SecondaryButton>
        </BottomToolBar>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow: hidden;
  border-radius: 4px;
  width: 400px;
  margin: -32px;
  background-color: var(--color-gray-300);
`;
const Header = styled.div`
  background-color: var(--color-gray-300);
  border-bottom: 2px solid var(--color-primary);
  padding: 8px;
  display: flex;
  justify-content: space-between;
`;
const Content = styled.div`
  display: grid;
  /* grid-template-rows: 36px auto 36px; */
  grid-template-columns: auto;
  gap: 4px;
  padding: 8px;
  height: 100%;
`;
const Box = styled.div`
  background-color: var(--color-gray-200);
  padding: 8px;
  border-radius: 4px;
  overflow: hidden;
`;
const ActionBar = styled(Box)`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
`;
const ActionBar0 = styled(Box)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  width: 100%;
`;
const BottomToolBar = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export default DeleteHypothesisForm;
