import * as THREE from "three";
import { updateObjBboxBSphere } from ".";
import { mirrorBuffer } from "../math/mirror";
import { IPoint } from "../math/types";

export function mirrorObj(threeObj: THREE.Object3D, startPoint: IPoint, endPoint: IPoint) {
  if (startPoint === undefined || endPoint === undefined) return;
  if (threeObj instanceof THREE.Line || threeObj instanceof THREE.Points) {
    const bufferGeometry = (threeObj.geometry as THREE.BufferGeometry);
    mirrorBuffer(bufferGeometry, startPoint, endPoint);
    updateObjBboxBSphere(threeObj);

  } else {
    //const strtPnt = substractIpoint(startPoint, threeObj.position);
    //const endPnt = substractIpoint(endPoint, threeObj.position);

    const bufferGeometry = (threeObj as THREE.Mesh).geometry as THREE.BufferGeometry;
    bufferGeometry.applyMatrix4(threeObj.matrixWorld);
    mirrorBuffer(bufferGeometry, startPoint, endPoint);
    threeObj.position.set(0, 0, 0);
    threeObj.rotation.set(0, 0, 0);

    // // http://www.euclideanspace.com/maths/geometry/affine/reflection/quaternion/index.htm
    // const angleZ = normalizeAngle((Math.PI - lineAngle2p(startPoint, endPoint)));
    // rotateObj(threeObj, 0, 0, angleZ, threeObj.position);
    // const qN = new THREE.Quaternion(0, 1, 0, 0);
    // const b = qN.clone().multiply(threeObj.quaternion).multiply(qN);
    // threeObj.quaternion.set(b.x, b.y, b.z, b.w);
    // rotateObj(threeObj, 0, 0, -angleZ, threeObj.position);
    
    updateObjBboxBSphere(threeObj);
    threeObj.updateMatrixWorld();

    // // Hay que calcular el nuevo position, haciéndole un mirror al position original
    // const curPosition = threeObj.position;
    // const newPosition = mirrorPoint(curPosition, startPoint, endPoint);
    // threeObj.position.set(newPosition.x, newPosition.y, newPosition.z);
    // threeObj.updateMatrix();
    // updateObjBboxBSphere(threeObj);
  }
}