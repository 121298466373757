/* tslint:disable */
/* eslint-disable */
/**
 * Standard Codes API
 * API for managing information related with Standard Codes for structure design and analysis.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: pablo.campillo@clerhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface City
 */
export interface City {
    /**
     * 
     * @type {string}
     * @memberof City
     */
    'CountryISO': string;
    /**
     * 
     * @type {string}
     * @memberof City
     */
    'CityName': string;
    /**
     * 
     * @type {number}
     * @memberof City
     */
    'Latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof City
     */
    'Longitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof City
     */
    'AproxElevation'?: number;
    /**
     * 
     * @type {string}
     * @memberof City
     */
    'Timezone'?: string;
    /**
     * 
     * @type {number}
     * @memberof City
     */
    'Population'?: number;
}
/**
 * 
 * @export
 * @interface Continent
 */
export interface Continent {
    /**
     * 
     * @type {string}
     * @memberof Continent
     */
    'ContinentISO': string;
    /**
     * 
     * @type {string}
     * @memberof Continent
     */
    'Continent': string;
    /**
     * 
     * @type {Array<ContinentCountries>}
     * @memberof Continent
     */
    'Countries'?: Array<ContinentCountries>;
}
/**
 * 
 * @export
 * @interface ContinentCountries
 */
export interface ContinentCountries {
    /**
     * 
     * @type {string}
     * @memberof ContinentCountries
     */
    'CountryISO'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContinentCountries
     */
    'Country'?: string;
}
/**
 * 
 * @export
 * @interface Hypothesis
 */
export interface Hypothesis {
    /**
     * 
     * @type {string}
     * @memberof Hypothesis
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Hypothesis
     */
    'created_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof Hypothesis
     */
    'favourable_load_factor'?: number;
    /**
     * 
     * @type {number}
     * @memberof Hypothesis
     */
    'unfavourable_load_factor'?: number;
    /**
     * 
     * @type {number}
     * @memberof Hypothesis
     */
    'mass_ratio'?: number;
    /**
     * 
     * @type {number}
     * @memberof Hypothesis
     */
    'psi_0'?: number;
    /**
     * 
     * @type {number}
     * @memberof Hypothesis
     */
    'psi_1'?: number;
    /**
     * 
     * @type {number}
     * @memberof Hypothesis
     */
    'psi_2'?: number;
    /**
     * 
     * @type {number}
     * @memberof Hypothesis
     */
    'unfavorable_sse'?: number;
    /**
     * 
     * @type {number}
     * @memberof Hypothesis
     */
    'service'?: number;
    /**
     * 
     * @type {number}
     * @memberof Hypothesis
     */
    'construction_phase'?: number;
    /**
     * 
     * @type {string}
     * @memberof Hypothesis
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface StandardCode
 */
export interface StandardCode {
    /**
     * 
     * @type {string}
     * @memberof StandardCode
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StandardCode
     */
    'created_at'?: string;
}

/**
 * GeonamesApi - axios parameter creator
 * @export
 */
export const GeonamesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns cities of a country.
         * @summary Returns cities of a country.
         * @param {string} continentIso Continent ISO name
         * @param {string} countryIso Country ISO name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continentsContinentIsoCountriesCountryIsoCitiesGet: async (continentIso: string, countryIso: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'continentIso' is not null or undefined
            assertParamExists('continentsContinentIsoCountriesCountryIsoCitiesGet', 'continentIso', continentIso)
            // verify required parameter 'countryIso' is not null or undefined
            assertParamExists('continentsContinentIsoCountriesCountryIsoCitiesGet', 'countryIso', countryIso)
            const localVarPath = `/continents/{continent_iso}/countries/{country_iso}/cities`
                .replace(`{${"continent_iso"}}`, encodeURIComponent(String(continentIso)))
                .replace(`{${"country_iso"}}`, encodeURIComponent(String(countryIso)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all continents with their cities.
         * @summary Returns all continents with their cities.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continentsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/continents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeonamesApi - functional programming interface
 * @export
 */
export const GeonamesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeonamesApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns cities of a country.
         * @summary Returns cities of a country.
         * @param {string} continentIso Continent ISO name
         * @param {string} countryIso Country ISO name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async continentsContinentIsoCountriesCountryIsoCitiesGet(continentIso: string, countryIso: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<City>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.continentsContinentIsoCountriesCountryIsoCitiesGet(continentIso, countryIso, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all continents with their cities.
         * @summary Returns all continents with their cities.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async continentsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Continent>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.continentsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GeonamesApi - factory interface
 * @export
 */
export const GeonamesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeonamesApiFp(configuration)
    return {
        /**
         * Returns cities of a country.
         * @summary Returns cities of a country.
         * @param {string} continentIso Continent ISO name
         * @param {string} countryIso Country ISO name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continentsContinentIsoCountriesCountryIsoCitiesGet(continentIso: string, countryIso: string, options?: any): AxiosPromise<Array<City>> {
            return localVarFp.continentsContinentIsoCountriesCountryIsoCitiesGet(continentIso, countryIso, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all continents with their cities.
         * @summary Returns all continents with their cities.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continentsGet(options?: any): AxiosPromise<Array<Continent>> {
            return localVarFp.continentsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GeonamesApi - object-oriented interface
 * @export
 * @class GeonamesApi
 * @extends {BaseAPI}
 */
export class GeonamesApi extends BaseAPI {
    /**
     * Returns cities of a country.
     * @summary Returns cities of a country.
     * @param {string} continentIso Continent ISO name
     * @param {string} countryIso Country ISO name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeonamesApi
     */
    public continentsContinentIsoCountriesCountryIsoCitiesGet(continentIso: string, countryIso: string, options?: AxiosRequestConfig) {
        return GeonamesApiFp(this.configuration).continentsContinentIsoCountriesCountryIsoCitiesGet(continentIso, countryIso, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all continents with their cities.
     * @summary Returns all continents with their cities.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeonamesApi
     */
    public continentsGet(options?: AxiosRequestConfig) {
        return GeonamesApiFp(this.configuration).continentsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HypothesisApi - axios parameter creator
 * @export
 */
export const HypothesisApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns all hypothesis for the standard code.
         * @summary Returns all hypothesis for the standard code.
         * @param {string} id Name of the standard code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        standardCodesIdHypothesisGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('standardCodesIdHypothesisGet', 'id', id)
            const localVarPath = `/standard_codes/{id}/hypothesis`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the hypothesis of a standard code.
         * @summary Deletes a hypothesis.
         * @param {string} id Name of the standard code
         * @param {string} hId Name of the Hypothesis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        standardCodesIdHypothesisHIdDelete: async (id: string, hId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('standardCodesIdHypothesisHIdDelete', 'id', id)
            // verify required parameter 'hId' is not null or undefined
            assertParamExists('standardCodesIdHypothesisHIdDelete', 'hId', hId)
            const localVarPath = `/standard_codes/{id}/hypothesis/{h_id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"h_id"}}`, encodeURIComponent(String(hId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets an hypothesis of an standard code.
         * @summary Gets an hypothesis.
         * @param {string} id Name of the standard code
         * @param {string} hId Name of the Hypothesis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        standardCodesIdHypothesisHIdGet: async (id: string, hId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('standardCodesIdHypothesisHIdGet', 'id', id)
            // verify required parameter 'hId' is not null or undefined
            assertParamExists('standardCodesIdHypothesisHIdGet', 'hId', hId)
            const localVarPath = `/standard_codes/{id}/hypothesis/{h_id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"h_id"}}`, encodeURIComponent(String(hId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new hypothesis for the standard code.
         * @summary Creates a new hypothesis for the standard code.
         * @param {string} id Name of the standard code
         * @param {Hypothesis} hypothesis 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        standardCodesIdHypothesisPost: async (id: string, hypothesis: Hypothesis, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('standardCodesIdHypothesisPost', 'id', id)
            // verify required parameter 'hypothesis' is not null or undefined
            assertParamExists('standardCodesIdHypothesisPost', 'hypothesis', hypothesis)
            const localVarPath = `/standard_codes/{id}/hypothesis`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hypothesis, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HypothesisApi - functional programming interface
 * @export
 */
export const HypothesisApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HypothesisApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns all hypothesis for the standard code.
         * @summary Returns all hypothesis for the standard code.
         * @param {string} id Name of the standard code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async standardCodesIdHypothesisGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Hypothesis>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.standardCodesIdHypothesisGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes the hypothesis of a standard code.
         * @summary Deletes a hypothesis.
         * @param {string} id Name of the standard code
         * @param {string} hId Name of the Hypothesis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async standardCodesIdHypothesisHIdDelete(id: string, hId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Hypothesis>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.standardCodesIdHypothesisHIdDelete(id, hId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets an hypothesis of an standard code.
         * @summary Gets an hypothesis.
         * @param {string} id Name of the standard code
         * @param {string} hId Name of the Hypothesis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async standardCodesIdHypothesisHIdGet(id: string, hId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Hypothesis>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.standardCodesIdHypothesisHIdGet(id, hId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new hypothesis for the standard code.
         * @summary Creates a new hypothesis for the standard code.
         * @param {string} id Name of the standard code
         * @param {Hypothesis} hypothesis 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async standardCodesIdHypothesisPost(id: string, hypothesis: Hypothesis, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Hypothesis>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.standardCodesIdHypothesisPost(id, hypothesis, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HypothesisApi - factory interface
 * @export
 */
export const HypothesisApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HypothesisApiFp(configuration)
    return {
        /**
         * Returns all hypothesis for the standard code.
         * @summary Returns all hypothesis for the standard code.
         * @param {string} id Name of the standard code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        standardCodesIdHypothesisGet(id: string, options?: any): AxiosPromise<Array<Hypothesis>> {
            return localVarFp.standardCodesIdHypothesisGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the hypothesis of a standard code.
         * @summary Deletes a hypothesis.
         * @param {string} id Name of the standard code
         * @param {string} hId Name of the Hypothesis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        standardCodesIdHypothesisHIdDelete(id: string, hId: string, options?: any): AxiosPromise<Hypothesis> {
            return localVarFp.standardCodesIdHypothesisHIdDelete(id, hId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets an hypothesis of an standard code.
         * @summary Gets an hypothesis.
         * @param {string} id Name of the standard code
         * @param {string} hId Name of the Hypothesis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        standardCodesIdHypothesisHIdGet(id: string, hId: string, options?: any): AxiosPromise<Hypothesis> {
            return localVarFp.standardCodesIdHypothesisHIdGet(id, hId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new hypothesis for the standard code.
         * @summary Creates a new hypothesis for the standard code.
         * @param {string} id Name of the standard code
         * @param {Hypothesis} hypothesis 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        standardCodesIdHypothesisPost(id: string, hypothesis: Hypothesis, options?: any): AxiosPromise<Hypothesis> {
            return localVarFp.standardCodesIdHypothesisPost(id, hypothesis, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HypothesisApi - object-oriented interface
 * @export
 * @class HypothesisApi
 * @extends {BaseAPI}
 */
export class HypothesisApi extends BaseAPI {
    /**
     * Returns all hypothesis for the standard code.
     * @summary Returns all hypothesis for the standard code.
     * @param {string} id Name of the standard code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HypothesisApi
     */
    public standardCodesIdHypothesisGet(id: string, options?: AxiosRequestConfig) {
        return HypothesisApiFp(this.configuration).standardCodesIdHypothesisGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the hypothesis of a standard code.
     * @summary Deletes a hypothesis.
     * @param {string} id Name of the standard code
     * @param {string} hId Name of the Hypothesis
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HypothesisApi
     */
    public standardCodesIdHypothesisHIdDelete(id: string, hId: string, options?: AxiosRequestConfig) {
        return HypothesisApiFp(this.configuration).standardCodesIdHypothesisHIdDelete(id, hId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets an hypothesis of an standard code.
     * @summary Gets an hypothesis.
     * @param {string} id Name of the standard code
     * @param {string} hId Name of the Hypothesis
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HypothesisApi
     */
    public standardCodesIdHypothesisHIdGet(id: string, hId: string, options?: AxiosRequestConfig) {
        return HypothesisApiFp(this.configuration).standardCodesIdHypothesisHIdGet(id, hId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new hypothesis for the standard code.
     * @summary Creates a new hypothesis for the standard code.
     * @param {string} id Name of the standard code
     * @param {Hypothesis} hypothesis 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HypothesisApi
     */
    public standardCodesIdHypothesisPost(id: string, hypothesis: Hypothesis, options?: AxiosRequestConfig) {
        return HypothesisApiFp(this.configuration).standardCodesIdHypothesisPost(id, hypothesis, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StandardCodeApi - axios parameter creator
 * @export
 */
export const StandardCodeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns all standard codes.
         * @summary Returns all standard codes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        standardCodesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/standard_codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes a standard code given its id.
         * @summary Removes a standard code given its id.
         * @param {string} id Name of the standard code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        standardCodesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('standardCodesIdDelete', 'id', id)
            const localVarPath = `/standard_codes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new standard code.
         * @summary Creates a new standard code.
         * @param {StandardCode} standardCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        standardCodesPost: async (standardCode: StandardCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'standardCode' is not null or undefined
            assertParamExists('standardCodesPost', 'standardCode', standardCode)
            const localVarPath = `/standard_codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(standardCode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StandardCodeApi - functional programming interface
 * @export
 */
export const StandardCodeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StandardCodeApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns all standard codes.
         * @summary Returns all standard codes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async standardCodesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StandardCode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.standardCodesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes a standard code given its id.
         * @summary Removes a standard code given its id.
         * @param {string} id Name of the standard code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async standardCodesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StandardCode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.standardCodesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new standard code.
         * @summary Creates a new standard code.
         * @param {StandardCode} standardCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async standardCodesPost(standardCode: StandardCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StandardCode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.standardCodesPost(standardCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StandardCodeApi - factory interface
 * @export
 */
export const StandardCodeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StandardCodeApiFp(configuration)
    return {
        /**
         * Returns all standard codes.
         * @summary Returns all standard codes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        standardCodesGet(options?: any): AxiosPromise<Array<StandardCode>> {
            return localVarFp.standardCodesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Removes a standard code given its id.
         * @summary Removes a standard code given its id.
         * @param {string} id Name of the standard code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        standardCodesIdDelete(id: string, options?: any): AxiosPromise<StandardCode> {
            return localVarFp.standardCodesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new standard code.
         * @summary Creates a new standard code.
         * @param {StandardCode} standardCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        standardCodesPost(standardCode: StandardCode, options?: any): AxiosPromise<StandardCode> {
            return localVarFp.standardCodesPost(standardCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StandardCodeApi - object-oriented interface
 * @export
 * @class StandardCodeApi
 * @extends {BaseAPI}
 */
export class StandardCodeApi extends BaseAPI {
    /**
     * Returns all standard codes.
     * @summary Returns all standard codes.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StandardCodeApi
     */
    public standardCodesGet(options?: AxiosRequestConfig) {
        return StandardCodeApiFp(this.configuration).standardCodesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes a standard code given its id.
     * @summary Removes a standard code given its id.
     * @param {string} id Name of the standard code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StandardCodeApi
     */
    public standardCodesIdDelete(id: string, options?: AxiosRequestConfig) {
        return StandardCodeApiFp(this.configuration).standardCodesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new standard code.
     * @summary Creates a new standard code.
     * @param {StandardCode} standardCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StandardCodeApi
     */
    public standardCodesPost(standardCode: StandardCode, options?: AxiosRequestConfig) {
        return StandardCodeApiFp(this.configuration).standardCodesPost(standardCode, options).then((request) => request(this.axios, this.basePath));
    }
}


