import { IPoint } from "./types";

const epsilon = 0.0001;
const epsilonAng = 0.0000001;
export const epsilonLinealPrecision = 0.001;

export function isZero(v: number, epsln: number = epsilon) {
  if (v < 0.0) v = -v;
  return v <= epsln;
}

export function isZeroAng(v: number) {
  if (v < 0.0) v = -v;
  return v <= epsilonAng;
}

export function isEqualAngle(angA: number, angB: number, epsln: number = epsilonAng): boolean {
  let diff = angA - angB;
  if (diff < 0.0) diff = -diff;
  return diff <= epsln;
}

export function vector3EqualZero(v1: IPoint): boolean {
  if (!isZero(v1.x)) {
    return false;
  }
  if (!isZero(v1.y)) {
    return false;
  }
  if (!isZero(v1.z)) {
    return false;
  }
  return true;
}

/** Floating point equal. */
export function isEqual(a: number, b: number, epsln: number = epsilon): boolean {
  let diff = a - b;
  (diff < 0) && (diff = -diff);
  return (diff <= epsln);
}
/** Floating point less than. */
export function isSmallerThan(a: number, b: number, e: number = 0.00001): boolean {
  const diff = a - b;
  if (isEqual(a, b, e)) return false;
  return diff < 0;
}
/** Floating point greater than. */
export function isBiggerThan(a: number, b: number, e: number = 0.00001): boolean {
  const diff = b - a;
  if (isEqual(a, b, e)) return false;
  return diff < 0;
}
/** Floating point less or equal than. */
export function isSmallerEqThan(a: number, b: number, e: number = epsilon): boolean {
  const diff = a - b;
  if (isEqual(a, b, e)) return true;
  return diff < 0;
}
/** Floating point greater or equal than. */
export function isBiggerEqThan(a: number, b: number, e: number = epsilon): boolean {
  const diff = b - a;
  if (isEqual(a, b, e)) return true;
  return diff < 0;
}

export function round(value: number, decimals: number) {
  return Math.round(value * 10 * decimals) / 10 * decimals;
}


/**
 * Compara posición 3D de dos puntos teniendo en cuenta epsilon.
 *
 * @export
 * @param {IPoint} v1
 * @param {IPoint} v2
 * @returns {boolean}
 * @memberof MEC.CAD.GEO
 */
export function vector3Equals(v1: IPoint, v2: IPoint): boolean {
  if (!isZero(v1.x - v2.x)) {
    return false;
  }
  if (!isZero(v1.y - v2.y)) {
    return false;
  }
  if (!isZero(v1.z - v2.z)) {
    return false;
  }
  return true;
}
export function vector2Equals(v1: IPoint, v2: IPoint): boolean {
  if (!isZero(v1.x - v2.x)) {
    return false;
  }
  if (!isZero(v1.y - v2.y)) {
    return false;
  }
  return true;
}
export function vector2EqualsXZ(v1: IPoint, v2: IPoint): boolean {
  if (!isZero(v1.x - v2.x)) { return false; }
  if (!isZero(v1.z - v2.z)) { return false; }
  return true;
}
export function vector2EqualsYZ(v1: IPoint, v2: IPoint): boolean {
  if (!isZero(v1.z - v2.z)) { return false; }
  if (!isZero(v1.y - v2.y)) { return false; }
  return true;
}