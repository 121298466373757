import * as THREE from "three";
import { GraphicProcessor } from "lib/graphic-processor";
import { addIpoint, getMiddlePoint, normalizeIpoint, substractIpoint } from "lib/math/point";
import { getRotationVector } from "lib/math/rotate";
import { BeamData } from "lib/models/structural/beam";
import { IObjData } from "lib/models/objdata";
import { WallData } from "lib/models/structural/wall";
import { iterPolylineEdges } from "lib/math/line";
import { ColumnData } from "lib/models/structural/column";

export interface IAxisHelper {
  update(): void;
  showHide(): void;
  delete(): void;
}

abstract class AxisHelperData<T extends IObjData> implements IAxisHelper {

  private graphicProcessor: GraphicProcessor
  protected helper: THREE.AxesHelper;
  protected data: T;
  public abstract update(): void

  constructor(data: T, graphicProcessor: GraphicProcessor) {
    this.graphicProcessor = graphicProcessor;
    this.helper = new THREE.AxesHelper(1);
    this.data = data;
    this.update();
  }
  showHide() {
    const scene = this.graphicProcessor._sceneManager.selectionScene;
    if (this.helper.parent) {
      scene.remove(this.helper);
    } else {
      scene.add(this.helper);
    }
  }
  delete() {
    if (this.helper.parent) {
      const scene = this.graphicProcessor._sceneManager.selectionScene;
      scene.remove(this.helper);
      this.helper.geometry.dispose();
      (this.helper.material as THREE.Material).dispose();
    }
    this.helper = undefined!;
    this.data = undefined!;
    this.graphicProcessor = undefined!;
  }
}
export class AxisHelperBeamData extends AxisHelperData<BeamData> {

  update() {
    const repr = this.data.definition;
    const xVect = normalizeIpoint(repr.points[1]);
    const rotL = getRotationVector(xVect, repr.orientation + Math.PI * 0.5);
    this.helper.rotation.set(rotL.x, rotL.y, rotL.z);
    const pos = repr.basePoint;
    this.helper.position.set(pos.x, pos.y, pos.z);
    // this.helper.position.copy(this.data.graphicObj.position);
    // this.helper.rotation.copy(this.data.graphicObj.rotation);
  }
}
export class AxisHelperColumnData extends AxisHelperData<ColumnData> {

  update() {
    const repr = this.data.definition;
    const pi2 = Math.PI * 0.5;
    const rot = new THREE.Euler(repr.orientation, -pi2, 0, "YXZ");
    rot.reorder("XYZ");
    this.helper.rotation.set(rot.x, rot.y, rot.z);
    this.helper.position.copy(this.data.graphicObj.position);
  }
}

export class AxisHelperWallData implements IAxisHelper {

  private graphicProcessor: GraphicProcessor;
  protected helpers: THREE.AxesHelper[] = [];
  protected data: WallData;

  constructor(data: WallData, graphicProcessor: GraphicProcessor) {
    this.graphicProcessor = graphicProcessor;
    this.data = data;
    this.update();
  }
  showHide() {
    const scene = this.graphicProcessor._sceneManager.selectionScene;
    if (this.helpers[0]?.parent) {
      scene.remove(...this.helpers);
    } else {
      scene.add(...this.helpers);
    }
  }
  delete() {
    if (this.helpers[0]?.parent) {
      const scene = this.graphicProcessor._sceneManager.selectionScene;
      scene.remove(...this.helpers);
      this.helpers.forEach(h => h.geometry.dispose());
      this.helpers.forEach(h => (h.material as THREE.Material).dispose());
    }
    this.helpers.length = 0;
    this.data = undefined!;
    this.graphicProcessor = undefined!;
  }
  update() {
    const def = this.data.definition;
    for (const edge of iterPolylineEdges(def.ptos2D)) {
      const { i, p0, p1 } = edge;
      const helper = this.helpers[i] ?? new THREE.AxesHelper(1);
      const pos = addIpoint(getMiddlePoint(p0, p1), def.basePoint);
      const vecX = substractIpoint(p1, p0);
      const rotL = getRotationVector(vecX, Math.PI * 0.5);
      helper.rotation.set(rotL.x, rotL.y, rotL.z);
      helper.position.set(pos.x, pos.y, pos.z);
      helper.scale.setZ(1 * def.stretch[i].orientation);
      if (!this.helpers.includes(helper)) {
        this.helpers.push(helper);
      }
    }
  }
}
