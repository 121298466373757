import { FC } from "react";
import { Link } from "react-router-dom";
import Logo from "../icons/logo";
import Menu from "../ui/menu";
import { useModules } from "./hooks/use-modules";

interface Props {
  nameModule: string;
  setModule: (path: string) => void;
}
const MenuSelect: FC<Props> = ({ nameModule, setModule }) => {
  return (
    <Menu.Item onSelect={() => setModule(nameModule)}>
      <div>
        <span className="text-base font-semibold text-gray-700 dark:text-gray-200">
          Grey
        </span>
        <span className="text-base font-semibold text-gray-400">
          {nameModule}
        </span>
      </div>
    </Menu.Item>
  );
};

const ModuleSelect = () => {
  const { module, setModule, showModuleOptions } = useModules();
  return showModuleOptions ? (
    <Menu>
      <Menu.Button>
        <div className="flex space-x-2 items-center">
          <Logo className="w-auto h-8 rounded-full flex-shrink-0" />
          <div>
            <span className="text-lg font-semibold text-gray-700 dark:text-gray-200">
              Grey
            </span>
            <span className="text-lg font-semibold text-gray-400">
              {module}
            </span>
          </div>
        </div>
      </Menu.Button>
      <Menu.List>
        {/* {module !== "lite" && <MenuSelect nameModule="lite" setModule={setModule}></MenuSelect>} */}
        {module !== "cad" && <MenuSelect nameModule="cad" setModule={setModule}></MenuSelect>}
        {module !== "struc" && <MenuSelect nameModule="struc" setModule={setModule}></MenuSelect>}
      </Menu.List>
    </Menu>
  ) : (
    <Link className="flex space-x-2 items-center" to="/lite/projects">
      <Logo className="w-auto h-8 rounded-full flex-shrink-0" />
      <div>
        <span className="text-lg font-semibold text-gray-700">Grey</span>
        <span className="text-lg font-semibold text-gray-400">{module}</span>
      </div>
    </Link>
  );
};

export default ModuleSelect;
