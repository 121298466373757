const RadiusIcon = ({ ...props }) => {
  return (
    <svg className="h-5 w-5" viewBox="0 0 100 100" fill="none" {...props}>
      <path
        d="M50 1.38889C40.3857 1.38889 30.9872 4.23988 22.9932 9.58133C14.9991 14.9228 8.7685 22.5148 5.08924 31.3973C1.40998 40.2798 0.447316 50.0539 2.32299 59.4836C4.19866 68.9132 8.82842 77.5749 15.6268 84.3732C22.4252 91.1716 31.0869 95.8014 40.5165 97.6771C49.9461 99.5527 59.7202 98.5901 68.6027 94.9108C77.4852 91.2315 85.0772 85.0009 90.4187 77.0069C95.7602 69.0128 98.6112 59.6144 98.6112 50C98.6112 37.1075 93.4896 24.7431 84.3733 15.6267C75.2569 6.5104 62.8925 1.38889 50 1.38889V1.38889ZM50 93.2222C41.6204 93.2293 33.4195 90.8005 26.3952 86.2313C19.371 81.6621 13.8264 75.1495 10.4362 67.4863C7.04613 59.8231 5.95669 51.3397 7.30051 43.0685C8.64434 34.7974 12.3635 27.0952 18.0053 20.8994C23.6471 14.7036 30.9683 10.2814 39.0778 8.17101C47.1873 6.0606 55.7354 6.353 63.6817 9.01262C71.628 11.6722 78.6299 16.5844 83.8352 23.1512C89.0404 29.718 92.2246 37.6564 93 46L85.6667 38.6667C85.136 38.3524 84.5159 38.2236 83.9039 38.3008C83.292 38.3779 82.7232 38.6564 82.287 39.0926C81.8509 39.5287 81.5724 40.0975 81.4953 40.7094C81.4181 41.3214 81.5469 41.9415 81.8612 42.4722L86.7778 47.2222H53.7223L58.6667 42.2778C59.1511 41.7626 59.4208 41.0821 59.4208 40.375C59.4208 39.6679 59.1511 38.9874 58.6667 38.4722C58.1501 37.9797 57.4638 37.705 56.75 37.705C56.0363 37.705 55.35 37.9797 54.8334 38.4722L45.3056 48C44.8131 48.5166 44.5384 49.2029 44.5384 49.9167C44.5384 50.6304 44.8131 51.3167 45.3056 51.8333L54.8334 61.3611C55.35 61.8536 56.0363 62.1283 56.75 62.1283C57.4638 62.1283 58.1501 61.8536 58.6667 61.3611C59.1511 60.8459 59.4208 60.1654 59.4208 59.4583C59.4208 58.7512 59.1511 58.0707 58.6667 57.5555L53.7223 52.7778H86.7778L81.8612 57.7222C81.3768 58.2374 81.1071 58.9179 81.1071 59.625C81.1071 60.3321 81.3768 61.0126 81.8612 61.5278C82.3648 62.0277 83.0406 62.3159 83.75 62.3333C84.4691 62.323 85.1562 62.0343 85.6667 61.5278L93 54.1944C91.9523 64.8753 86.9709 74.7852 79.024 81.998C71.077 89.2109 60.7322 93.2114 50 93.2222Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default RadiusIcon;
