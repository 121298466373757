import { DangerButton, SecondaryButton } from "shared/components/ui/button";
import { useDeleteProject } from "./hooks/use-delete-project";

const ProjectDeleteModal = () => {
  const { deleteProject, cancelDeleteProject } = useDeleteProject();
  return (
    <div className="space-y-6 max-w-sm">
      <div>
        <h1 className="text-lg leading-6 font-medium text-gray-400">
          Delete selected project
        </h1>
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            Are you sure you want to delete this project? This action cannot be
            undone.
          </p>
        </div>
      </div>
      <div className="flex justify-center space-x-4">
        <SecondaryButton onClick={cancelDeleteProject} fullWidth>
          Cancel
        </SecondaryButton>
        <DangerButton onClick={deleteProject} fullWidth>
          Confirm
        </DangerButton>
      </div>
    </div>
  );
};

export default ProjectDeleteModal;
