import { useEffect, useCallback, useState, useRef } from "react";

const useContextMenu = (idMenu: string, setShow: (val: boolean) => void, hasItems: boolean) => {

  const wrapperMenuRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });

  const handleContextMenu = useCallback((event) => {
    if (!hasItems) {
      event.preventDefault();
      return;
    }
    let targetElement = event.target;
    do {
      if (targetElement?.id?.includes && targetElement.id.includes(idMenu)) {
        event.preventDefault();
        setAnchorPoint({ x: event.pageX, y: event.pageY });
        setShow(true);
        return;
      }
      targetElement = targetElement.parentNode;
    }
    while (targetElement);
    setShow(false);
  }, [hasItems, idMenu, setShow]);

  const handleClick = useCallback((ev: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(ev.target as Node)) {
      setShow(false);
    }
  }, [setShow]);

  useEffect(() => {
    const htmlElem = wrapperMenuRef.current!;
    htmlElem.addEventListener("mousedown", handleClick);
    htmlElem.addEventListener("contextmenu", handleContextMenu);
    return () => {
      htmlElem.addEventListener("mousedown", handleClick);
      htmlElem.removeEventListener("contextmenu", handleContextMenu);
    };
  }, [handleClick, handleContextMenu]);

  return {
    x: anchorPoint.x,
    y: anchorPoint.y,
    wrapperMenuRef,
    menuRef,
  };
};

export default useContextMenu;