const ParallelIcon = ({ ...props }) => {
  return (
    <svg
      className="h-5 w-5"
      viewBox="0 0 100 100"
      fill="currentColor"
      {...props}
    >
      <path d="M11.3284 61.7013C11.5047 61.9537 11.7291 62.1689 11.9888 62.3346C12.2484 62.5002 12.5381 62.6131 12.8414 62.6666C13.1446 62.7201 13.4555 62.7134 13.7561 62.6466C14.0568 62.5799 14.3413 62.4545 14.5934 62.2777L72.5102 21.7225C73.0196 21.3659 73.3665 20.8216 73.4746 20.2093C73.5827 19.597 73.4432 18.9668 73.0866 18.4574C72.7301 17.948 72.1858 17.6011 71.5735 17.493C70.9612 17.3849 70.331 17.5244 69.8216 17.881L11.9049 58.4363C11.3958 58.793 11.0492 59.3373 10.9411 59.9495C10.833 60.5616 10.9723 61.1917 11.3284 61.7013V61.7013Z" />
      <path d="M26.9126 75.0331C27.2691 75.5425 27.8134 75.8894 28.4257 75.9975C29.038 76.1056 29.6682 75.9661 30.1776 75.6096L88.0943 35.0543C88.6037 34.6978 88.9506 34.1535 89.0587 33.5412C89.1668 32.9289 89.0273 32.2987 88.6708 31.7893C88.3142 31.2799 87.7699 30.933 87.1576 30.8248C86.5453 30.7167 85.9151 30.8563 85.4057 31.2128L27.489 71.7681C26.98 72.1249 26.6333 72.6691 26.5252 73.2813C26.4171 73.8935 26.5564 74.5236 26.9126 75.0331V75.0331Z" />
    </svg>
  );
};

export default ParallelIcon;
