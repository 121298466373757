import { IPoint, ISegment } from "./types";
import { IObjData } from "../models/objdata";

export interface IBox {
  min: IPoint; // Lower Left
  max: IPoint; // Top Right
}

export function isPointInBox(p: IPoint, b: IBox): boolean {
  if (!p || !b) return false;
  return (p.x >= b.min.x) && (p.x <= b.max.x) && (p.y >= b.min.y) && (p.y <= b.max.y);
}

export function mergeBoxes(b1: IBox, b2: IBox): IBox {
  if (!b1) return b2;
  if (!b2) return b1;
  const res: IBox = {
    min: {
      x: Math.min(b1.min.x, b2.min.x),
      y: Math.min(b1.min.y, b2.min.y),
      z: Math.min(b1.min.z, b2.min.z),
    },
    max: {
      x: Math.max(b1.max.x, b2.max.x),
      y: Math.max(b1.max.y, b2.max.y),
      z: Math.max(b1.max.z, b2.max.z),
    },
  };
  return res;
}

export function getBoundingBoxMultiObj(objsData: IObjData[]): IBox {
  console.assert(objsData.length > 0);
  let box = objsData[0].getBoundingBox();
  if (box) {
    for (let i: number = 1, l: number = objsData.length; i < l; i++) {
      const bb = objsData[i].getBoundingBox();
      if (bb) {
        box = mergeBoxes(box, bb);
      }
    }
    return box;
  } else {
    throw new Error();
  }
}

/**
 * Devuelve la axis aligned bounding box de los puntos dados.
 * Ojo que la calculamos en 3D usando las 3 componentes.
 *
 * @export
 * @param {IPoint[]} points
 * @returns {IBox}
 */
export function getBoundingBoxPoints(points: IPoint[]): IBox {

  const aabb: IBox = {
    min: {
      x: +Infinity,
      y: +Infinity,
      z: +Infinity,
    },
    max: {
      x: -Infinity,
      y: -Infinity,
      z: -Infinity,
    },
  };

  const numPoints = points.length;
  for (let i = 0; i < numPoints; i++) {
    const p = points[i];
    if (p.x < aabb.min.x) {
      aabb.min.x = p.x;
    }
    if (p.x > aabb.max.x) {
      aabb.max.x = p.x;
    }
    if (p.y < aabb.min.y) {
      aabb.min.y = p.y;
    }
    if (p.y > aabb.max.y) {
      aabb.max.y = p.y;
    }
    if (p.z < aabb.min.z) {
      aabb.min.z = p.z;
    }
    if (p.z > aabb.max.z) {
      aabb.max.z = p.z;
    }
  }

  return aabb;
}

export function lineGetBbox(line: ISegment): IBox {
  const min: IPoint = { x: 0, y: 0, z: 0 };
  min.x = (line.p1.x <= line.p2.x) ? line.p1.x : line.p2.x;
  min.y = (line.p1.y <= line.p2.y) ? line.p1.y : line.p2.y;
  min.z = (line.p1.z <= line.p2.z) ? line.p1.z : line.p2.z;
  const max: IPoint = { x: 0, y: 0, z: 0 };
  max.x = (line.p1.x >= line.p2.x) ? line.p1.x : line.p2.x;
  max.y = (line.p1.y >= line.p2.y) ? line.p1.y : line.p2.y;
  max.z = (line.p1.z >= line.p2.z) ? line.p1.z : line.p2.z;
  return { min, max };
}
