import { CompositeNode, ICompositeComponent } from "lib/helpers/composite-tree";
import { StructModel3D } from "lib/helpers/structmodel3d";
import { analysisManager } from "lib/models-struc/analysis/analysismodel-manager";
import { resultType, solutionManager } from "lib/models-struc/solution/solutionmodel-manager";
import { BeamCSItem, beamFMItem, codeAnalysisItem, displacementItem, hypoSetItem, hypoSolveItem, ItemInfo, itemType, meshItem, nodalFMItem, ShellCSItem, shellFMItem, StrucElemItem, useSideBarContext } from "modules/cad/components/sidebar/context";
import { useMainGraphicContext } from "modules/cad/components/viewport/context";
import { useReducer, useEffect, useCallback, useRef } from "react";
import { NodeData } from "shared/components/ui/tree/tree";
import { useAnalysis } from "../../analysis/hook/use-analysis";
import { useShellCrossSections } from "../../cross-sections/hooks/use-shell-cross-sections-nodes";
import { useHypothesis } from "../../hypothesis/hooks/use-hypothesis";
import { useMesh } from "../../mesh/hook/use-mesh";
import { useBeamCrossSections } from "../../cross-sections/hooks/use-beam-cross-sections-nodes";

const updateNodeTree = (tree: ICompositeComponent<ItemInfo>, newNode: ICompositeComponent<ItemInfo>, nodeId?: string) => {
  if (!nodeId) nodeId = newNode.id
  const oldNode = tree.getNodeById(nodeId);
  if (oldNode) {
    const parentNode = oldNode.parent as CompositeNode<any>;
    const index = parentNode.children.indexOf(oldNode);
    parentNode.children[index] = newNode;
    newNode.parent = parentNode;
  }
}

const createProjectTree = (
  beamCrossSectionsNodes: ICompositeComponent<BeamCSItem>,
  shellCrossSectionsNodes: ICompositeComponent<ShellCSItem>,
  hypoSetsNodes: ICompositeComponent<hypoSetItem>,
  meshNode: ICompositeComponent<meshItem>,
  solutionNodes: CompositeNode<hypoSolveItem> | null) => {

  const tree = new CompositeNode<ItemInfo>();
  tree.addChild<StrucElemItem>("Structural elements", { info: undefined, type: itemType.STRUCELEMENT });
  // CROSS SECTION SHAPES NODE
  const csNode = tree.addChild("Cross sections");
  csNode.addChildNode(beamCrossSectionsNodes);
  csNode.addChildNode(shellCrossSectionsNodes);
  // MESH NODE
  tree.addChildNode(meshNode);
  // HYPOTHESIS SET NODE
  tree.addChildNode(hypoSetsNodes);
  // ANALYSIS NODE
  const nodeAnalysis = tree.addChild<codeAnalysisItem>("Code analysis", { info: undefined, type: itemType.CODEANALYSIS });
  nodeAnalysis.addChild("Analysis settings");
  nodeAnalysis.addChildLeaf("Hypothesis", { info: undefined, type: itemType.SOLVEANALYSIS });
  if (solutionNodes) {
    // SOLUTION NODES
    nodeAnalysis.addChildNode(solutionNodes);
  }
  tree.addChild("Boundary conditions");
  return tree;
}
interface projectState {
  tree: ICompositeComponent<ItemInfo>;
  currentNodeId: string;
}

enum TreeActionType { LOADBCSS, LOADSCSS, LOADMESH, LOADHYPO, LOADSOLUTION, SETNODE }

type TreeAction =
  | { type: TreeActionType.LOADBCSS; payload: { node: ICompositeComponent<BeamCSItem> } }
  | { type: TreeActionType.LOADSCSS; payload: { node: ICompositeComponent<ShellCSItem> } }
  | { type: TreeActionType.LOADMESH; payload: { node: ICompositeComponent<meshItem> } }
  | { type: TreeActionType.LOADHYPO; payload: { node: ICompositeComponent<hypoSetItem> } }
  | { type: TreeActionType.LOADSOLUTION; payload: { node: ICompositeComponent<hypoSolveItem> } }
  | { type: TreeActionType.SETNODE; payload: { id: string } };

function reducer(state: projectState, action: TreeAction): projectState {
  switch (action.type) {
    case TreeActionType.LOADBCSS:
    case TreeActionType.LOADSCSS:
    case TreeActionType.LOADHYPO:
    case TreeActionType.LOADSOLUTION:
      if (!action.payload.node) return state;
      updateNodeTree(state.tree, action.payload.node);
      return { ...state, tree: state.tree.cloneTree() };

    case TreeActionType.LOADMESH:
      const node = action.payload.node;
      const meshNode = state.tree.getNodeById(node.id)!;
      if (meshNode) meshNode.props = { ...node.props };
      return { ...state, tree: state.tree.cloneTree() };

    case TreeActionType.SETNODE:
      return { ...state, currentNodeId: action.payload.id };
    default:
      throw new Error(`Action is not defined.`);
  }
}

export function useStrucProjectTree() {

  const { setData } = useSideBarContext();

  const { beamCrossSectionsNodes } = useBeamCrossSections();
  useEffect(() => {
    dispatch({ type: TreeActionType.LOADBCSS, payload: { node: beamCrossSectionsNodes } });
  }, [beamCrossSectionsNodes]);

  const { shellCrossSectionsNodes } = useShellCrossSections();
  useEffect(() => {
    dispatch({ type: TreeActionType.LOADSCSS, payload: { node: shellCrossSectionsNodes } });
  }, [shellCrossSectionsNodes]);

  const { meshNode } = useMesh();
  useEffect(() => {
    dispatch({ type: TreeActionType.LOADMESH, payload: { node: meshNode } });
  }, [meshNode]);

  const { hypoSetsNodes } = useHypothesis();
  useEffect(() => {
    dispatch({ type: TreeActionType.LOADHYPO, payload: { node: hypoSetsNodes } });
  }, [hypoSetsNodes]);

  const { solutionNodes } = useAnalysis();
  useEffect(() => {
    dispatch({ type: TreeActionType.LOADSOLUTION, payload: { node: solutionNodes! } });
  }, [solutionNodes]);

  const [state, dispatch] = useReducer(reducer, {}, () => {
    const tree = createProjectTree(beamCrossSectionsNodes, shellCrossSectionsNodes, hypoSetsNodes, meshNode, solutionNodes);
    const currentNodeId = tree.children[0].id;
    return { tree, currentNodeId }
  });

  const graphicProc = useMainGraphicContext();

  const setDisplacementNode = useCallback(async (props: displacementItem) => {
    if (analysisManager.analysis.project) {
      const hypoId = props.info.uid;
      let hypo = analysisManager.getHypothesisFromId(hypoId);
      if (hypo) {
        const result = await solutionManager.getResult(analysisManager.analysis.project.Id, resultType.DISPL_NODES, [hypo]);
        if (result && result.length > 0) {
          const model = StructModel3D.getCurrentModel(graphicProc);
          if (model) {
            model.setDeformations4String(result[0].data);
          }
        }
      } else {
        console.error("[setDisplacementNode] Hypothesis not found");
      }
    }
  }, [graphicProc])

  const setBeamFMNode = useCallback(async (props: beamFMItem) => {
    if (analysisManager.analysis.project) {
      const hypoId = props.info.uid;
      let hypo = analysisManager.getHypothesisFromId(hypoId);
      if (hypo) {
        const result = await solutionManager.getResult(analysisManager.analysis.project.Id, resultType.FM_NODAL_BEAMS, [hypo]);
        if (result && result.length > 0) {
          const model = StructModel3D.getCurrentModel(graphicProc);
          if (model) {
            model.setEsfuerzos4String(result[0].data);
          }
        }
      } else {
        console.error("[setBeamFMNode] Hypothesis not found");
      }
    }
  }, [graphicProc])

  const setShellFMNode = useCallback(async (props: shellFMItem) => {
    if (analysisManager.analysis.project) {
      const hypoId = props.info.uid;
      let hypo = analysisManager.getHypothesisFromId(hypoId);
      if (hypo) {
        const result = await solutionManager.getResult(analysisManager.analysis.project.Id, resultType.FM_NODAL_SHELLS, [hypo]);
        if (result && result.length > 0) {
          const model = StructModel3D.getCurrentModel(graphicProc);
          if (model) {
            model.setEsfuerzos4String(result[0].data);
          }
        }
      } else {
        console.error("[setShellFMNode] Hypothesis not found");
      }
    }
  }, [graphicProc])

  const setNodalFMNode = useCallback(async (props: nodalFMItem) => {
    if (analysisManager.analysis.project) {
      const hypoId = props.info.uid;
      let hypo = analysisManager.getHypothesisFromId(hypoId);
      if (hypo) {
        const result = await solutionManager.getResult(analysisManager.analysis.project.Id, resultType.FM_NODAL_BEAMS, [hypo]);
        if (result && result.length > 0) {
          const model = StructModel3D.getCurrentModel(graphicProc);
          if (model) {
            model.setEsfuerzos4String(result[0].data);
          }
        }
      } else {
        console.error("[setNodalFMNode] Hypothesis not found");
      }
    }
  }, [graphicProc])


  const setCurrentNode = useCallback(async (currNode: NodeData<ItemInfo>) => {
    const node = state.tree.getNodeById<ItemInfo>(currNode.id)!;
    if (node.props) {
      const nodeProp = node.props;
      switch (nodeProp.type) {
        case itemType.LOAD:
          const load = nodeProp.info;
          const selecMng = graphicProc.getSelectionManager();
          if (selecMng.canBeSelected(load)) {
            selecMng.selectObjData(load);
          }
          selecMng.dispatchSelectedObject(load);
          break;
        case itemType.DISPLACEMENT:
          await setDisplacementNode(nodeProp);
          break;
        case itemType.BEAMFM:
          await setBeamFMNode(nodeProp);
          break;
        case itemType.SHELLFM:
          await setShellFMNode(nodeProp);
          break;
        case itemType.NODALFM:
          await setNodalFMNode(nodeProp);
          break;
      }
    }
    setData(node.props);
    dispatch({ type: TreeActionType.SETNODE, payload: { id: node.id } })

  }, [graphicProc, setBeamFMNode, setData, setDisplacementNode, setNodalFMNode, setShellFMNode, state.tree])


  const expandedKeys = useRef<string[]>([]);
  const scrollOffset = useRef<{ offset: number }>({ offset: 0 });

  return {
    projectTree: state.tree,
    expandedKeys,
    scrollOffset,
    currentNodeId: state.currentNodeId,
    setCurrentNode,
  }
}
