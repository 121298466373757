import { ellipseArcParam } from "lib/geometries/ellipse-arc";
import { lineBaseCreate } from "lib/geometries/line";
import { getCurrentLineMaterial, ILineMaterial } from "lib/materials";
import { eulerAnglesToAxisAngle, normalizeAngle } from "lib/math/angles";
import { ellipseArcBuffer } from "lib/math/ellipse-arc";
import { mirrorPoint } from "lib/math/mirror";
import { addIpoint, copyIPoint } from "lib/math/point";
import { rotatePoint } from "lib/math/rotate";
import { scalePoint } from "lib/math/scale";
import { IPoint } from "lib/math/types";
import { objDataType } from "../types";
import { LineBaseData } from "./line-base";

export class EllipseArcData extends LineBaseData {

  public type = objDataType.ELLIPSEARC;
  protected nameObj: string = "Ellipse arc";
  public definition: ellipseArcParam;

  constructor(definition: ellipseArcParam, material?: ILineMaterial) {
    super();
    this.definition = {
      center: copyIPoint(definition.center),
      a: definition.a,
      b: definition.b,
      azimutO: definition.azimutO,
      azimutStart: definition.azimutStart,
      azimutEnd: definition.azimutEnd,
      plane: copyIPoint(definition.plane),
    };
    this.material = material ?? getCurrentLineMaterial();
  }

  static createObj(definition: ellipseArcParam, material: ILineMaterial) {
    const buffer = ellipseArcBuffer(
      definition.center,
      definition.a,
      definition.b,
      definition.azimutO,
      definition.azimutStart,
      definition.azimutEnd,
      definition.plane
    );
    return lineBaseCreate(buffer, material);
  }
  public createObject(definition?: ellipseArcParam, material?: ILineMaterial) {
    return EllipseArcData.createObj(definition ?? this.definition, material ?? this.material);
  }
  public getBufferGeom(): Float32Array {
    return ellipseArcBuffer(
      this.definition.center,
      this.definition.a,
      this.definition.b,
      this.definition.azimutO,
      this.definition.azimutStart,
      this.definition.azimutEnd,
      this.definition.plane
    );
  }

  public cloneDefinition(): ellipseArcParam {
    return {
      center: copyIPoint(this.definition.center),
      a: this.definition.a,
      b: this.definition.b,
      azimutO: this.definition.azimutO,
      azimutStart: this.definition.azimutStart,
      azimutEnd: this.definition.azimutEnd,
      plane: copyIPoint(this.definition.plane),
    };
  }
  public translate(distance: IPoint) {
    this.definition.center = addIpoint(this.definition.center, distance);
    this.regenerateObjectFromDefinition();
  }
  public rotate(
    angleX: number,
    angleY: number,
    angleZ: number,
    basePoint: IPoint
  ): void {
    angleX = normalizeAngle(angleX);
    angleY = normalizeAngle(angleY);
    angleZ = normalizeAngle(angleZ);

    this.definition.center = rotatePoint(
      this.definition.center,
      angleX,
      angleY,
      angleZ,
      basePoint
    );
    const { angle } = eulerAnglesToAxisAngle(angleX, angleY, angleZ);
    this.definition.azimutO = normalizeAngle(this.definition.azimutO - angle);
    this.regenerateObjectFromDefinition();
  }
  public scale(
    factorX: number,
    factorY: number,
    factorZ: number,
    basePoint: IPoint
  ): void {
    this.definition.center = scalePoint(
      this.definition.center,
      factorX,
      factorY,
      factorZ,
      basePoint
    );
    this.definition.a *= factorX;
    this.definition.b *= factorX;
    this.regenerateObjectFromDefinition();
  }
  public mirror(startPoint: IPoint, endPoint: IPoint): void {
    this.definition.center = mirrorPoint(
      this.definition.center,
      startPoint,
      endPoint
    );
    this.regenerateObjectFromDefinition();
  }
  public delete(): void {
    this.graphicObj.geometry.dispose();
  }
}
