import * as THREE from "three";
import * as Lucida_Console_Regular from "./fonts/Lucida_Console_Regular.json"
import * as NotCourierSans_Regular from "./fonts/NotCourierSans_Regular.json"
import * as DroidSerif_Regular from "./fonts/DroidSerif_Regular.json"
import * as SourceSansPro_Regular from "./fonts/SourceSansPro_Regular.json"

export enum fontType { LUCIDA = 0, COURIER, DROID, SOURCE }
type fontNames = "LucidaConsole" | "NotCourierSans" | "DroidSerif" | "SourceSans";
export const fontArrayNames: string[] = [
  getfontName(fontType.LUCIDA),
  getfontName(fontType.COURIER),
  getfontName(fontType.DROID),
  getfontName(fontType.SOURCE),
];
function getfontName(font: fontType): fontNames {
  if (font === fontType.LUCIDA) return "LucidaConsole";
  if (font === fontType.COURIER) return "NotCourierSans";
  if (font === fontType.DROID) return "DroidSerif";
  if (font === fontType.SOURCE) return "SourceSans";
  // by default
  return "SourceSans";
}
interface IFontFiles {
  name: string;
  /** fichero json para el loader de THREE.Font */
  glyphsDef: any;
  glyphsfile: string;
}

const fontDefinitions: Map<fontNames, IFontFiles> = new Map();
fontDefinitions.set("LucidaConsole", {
  name: "Lucida Console",
  glyphsDef: Lucida_Console_Regular,
  glyphsfile: "/fonts/Lucida_Console_Regular.json",
});
fontDefinitions.set("NotCourierSans", {
  name: "NotCourierSans",
  glyphsDef: NotCourierSans_Regular,
  glyphsfile: "/fonts/NotCourierSans_Regular.json",
});
fontDefinitions.set("DroidSerif", {
  name: "Droid Serif",
  glyphsDef: DroidSerif_Regular,
  glyphsfile: "/fonts/DroidSerif_Regular.json",
});
fontDefinitions.set("SourceSans", {
  name: "SourceSans",
  glyphsDef: SourceSansPro_Regular,
  glyphsfile: "/fonts/SourceSansPro_Regular.json",
});

// ---------------------------------------------------------------------------------------------------

export function loadTextFont(font: fontType) {
  const fontName = getfontName(font);
  const def = fontDefinitions.get(fontName); // return gliph JSON
  if (def) {
    const gliph = def.glyphsDef;
    const font = new THREE.Font(gliph.default);
    return font;
  }
}