import Page from '../components/ui/page'
import ProjectForm from '../components/project/form/project-form'
import { SecondaryLink } from 'shared/components/ui/link'
import { PrimaryButton } from 'shared/components/ui/button'
import { useCreateProject } from '../components/project/form/hook/use-create-project'

const NewStrucProject = () => {

  const {
    status,
    addProject
  } = useCreateProject();

  return (
    <Page title="New project">
      <ProjectForm        
        submit={(project, storeys) => { 
          project.DataModelVersion = "0.2";
          addProject(project, storeys); 
        }}
      >
        <div className="flex items-center justify-end gap-2">
          <SecondaryLink to="/struc/projects" variant="tertiary">
            Cancel
          </SecondaryLink>
          <PrimaryButton type="submit" loading={status === 'loading'}>
            Create project
          </PrimaryButton>
        </div>
      </ProjectForm>
    </Page>
  )
}

export default NewStrucProject
