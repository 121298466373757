import { AngleDimensionEditDataCommand } from "lib/commands/dimension/angle-dim";
import { AngleDimensionData, angleDimensionParam } from "lib/models/dimension/angle-dim";
import { dataInfoProperty } from "../properties";
import { DimensionDataDefinitionHandler, dimensionStyleParam } from './dimension';

export type angleDimensionView = dataInfoProperty<angleDimensionParam>

export class AngleDimensionDefinitionHandler extends DimensionDataDefinitionHandler<angleDimensionParam> {

  protected data: AngleDimensionData;

  public saveAndRegenerateStyle = (newStyle: dimensionStyleParam) => {
    const angleDimensionDefinition = this.checkAndChangedStyle(newStyle) as angleDimensionParam;
    if (angleDimensionDefinition) {
      const command = new AngleDimensionEditDataCommand(this.data, angleDimensionDefinition, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  };
}