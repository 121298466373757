import { SSEhypothesisManager } from "lib/models-struc/hypothesis/sse"
import { windhypothesisManager } from "lib/models-struc/hypothesis/wind"
import { ItemInfo, itemType } from "modules/cad/components/sidebar/context"
import { useMainGraphicContext } from "modules/cad/components/viewport/context"
import { useCallback, useState } from "react"
import { useUI } from "shared/components/ui/context"
import { menuCtxSettings } from "shared/components/ui/context-menu/context-menu"
import { NodeData } from "shared/components/ui/tree/tree"

export function useStrucProjectTreeContextMenu() {

  const { openModal } = useUI();
  const graphicProc = useMainGraphicContext();
  
  const [menuCtxItems, setmenuCtxItems] = useState<menuCtxSettings[]>([])

  const setMenuContextual = useCallback((node?: NodeData<ItemInfo>) => {
    if (node && node.data) {
      const nodeProp = node.data;
      switch (nodeProp.type) {
        case itemType.LOAD:
          setmenuCtxItems([
            { label: "Add Hypothesis", callback: () => openModal("ADD_HYPOTHESIS") },
            { label: "Delete hypothesis", callback: () => openModal("DELETE_HYPOTHESIS") }
          ]);
          break;
        case itemType.HYPOSET:
          const items = [
            { label: "Add Hypothesis", callback: () => openModal("ADD_HYPOTHESIS") },
            { label: "Delete hypothesis", callback: () => openModal("DELETE_HYPOTHESIS") },
          ];
          if (!SSEhypothesisManager.hasSSE()) {
            items.push({ label: "Add SSE", callback: () => SSEhypothesisManager.initSSE() });
          }
          items.push({ label: "Calculate wind hypothesis", callback: () => openModal("WIND_HYPOTHESIS_WIZARD") });
          if (windhypothesisManager.hasHypothesis()) {
            items.push({ label: "Remove wind hypothesis", callback: () => windhypothesisManager.deleteWindHypothesis(graphicProc) });
          }
          setmenuCtxItems(items);
          break;
        case itemType.SHELLCS:
          setmenuCtxItems([
            { label: "Add Shell cross section", callback: () => openModal("SHELL_CROSS_SECTIONS") },
          ]);
          break;
        case itemType.BEAMCS:
          setmenuCtxItems([
            { label: "Add Beam cross section", callback: () => openModal("BEAM_CROSS_SECTIONS") },
          ]);
          break;
        case itemType.TOOLS:
          setmenuCtxItems([
            { label: "Add Storey Drift", callback: () => openModal("STOREYDRIFTWIZARD") },
          ]);
          break;
        case itemType.STOREYDRIFT:
          setmenuCtxItems([
            { label: "Show Storey Drift", callback: () => openModal("STOREYDRIFTINFO", { name: nodeProp.info.name }) },
          ]);
          break;
        default:
          setmenuCtxItems([]);
          break;
      }
    } else {
      setmenuCtxItems([]);
    }
  }, [graphicProc, openModal])

  return {
    menuCtxItems,
    setMenuContextual,
  }
}