import { mecDirectionMathDirection, normalizeAngle } from "./angles";
import { getPointOnEllipse } from "./ellipse";
import { rotateBuffer } from "./rotate";
import { IPoint } from "./types";

/**
 * Transforma angulo desde el centro a parámetro de ángulo elipsoidal
 * https://math.stackexchange.com/questions/493104/evaluating-int-ab-frac12-r2-mathrm-d-theta-to-find-the-area-of-an-ellips/687384#687384
 *
 * @export
 * @param {IPoint} center
 * @param {number} a
 * @param {number} b
 * @param {number} azimut azimut relativo al sistema de la elipse
 * @returns {number} parámetro t
 */
export function getEllipseCenterAngle(center: IPoint, a: number, b: number, azimut: number): number {
    const angle = mecDirectionMathDirection(azimut);
    if (Math.PI * 0.5 < angle && angle <= Math.PI * 1.5) {
        return Math.atan(a * Math.tan(angle) / b) - Math.PI;
    } else {
        return Math.atan(a * Math.tan(angle) / b);
    }
}

export function ellipseArcBuffer(center: IPoint, a: number, b: number, azimutO: number, azimutStart: number, azimutEnd: number, plane?: IPoint): Float32Array {
    if (plane === undefined) plane = { x: 0, y: 0, z: 0 };

    const sides: number = 64;
    const coords: Float32Array = new Float32Array((sides + 1) * 3);
    const start = getEllipseCenterAngle(center, a, b, azimutStart);
    const end = getEllipseCenterAngle(center, a, b, azimutEnd);
    const incAng = (normalizeAngle(start - end)) / sides;
    let currAng = start;
    for (let i: number = 0; i <= sides; i++) {
        const position: number = i * 3;
        const point: IPoint = getPointOnEllipse(center, a, b, currAng, azimutO);
        coords[position] = point.x;
        coords[position + 1] = point.y;
        coords[position + 2] = point.z;
        currAng -= incAng;
    }
    if ((plane.x !== 0) || (plane.y !== 0) || (plane.z !== 0)) {
        rotateBuffer(coords, plane.x, plane.y, plane.z, center);
    }
    return coords;
}
