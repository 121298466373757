export const getFemResultsBaseUrlFromBuildingModelUrl = (
  buildingModelUrl: string
) => buildingModelUrl.split("/model.fcstd")[0];

export const createFemResultsLinks = (buildingModelUrl: string) => {
  const rawLinks = [
    "building.comm",
    "buildingR.mess",
    "buildingR.rmed",
    "elements.json",
    "fem_data.pkl",
    "member_forces.out",
    "model.med",
    "nodal_def.out",
    "structural_elem.pkl",
  ];
  const baseUrl = getFemResultsBaseUrlFromBuildingModelUrl(buildingModelUrl);
  return rawLinks.map((link) => ({
    label: link,
    value: `${baseUrl}/fem/${link}`,
  }));
};
