import { LinealDimensionEditDataCommand } from "lib/commands/dimension/lineal-dim";

import { LinealDimensionData, linealDimensionParam } from "lib/models/dimension/lineal-dim";
import { DimensionDataDefinitionHandler, dimensionStyleParam } from "./dimension";


export class LinealDimensionDefinitionHandler extends DimensionDataDefinitionHandler<linealDimensionParam> {

  protected data: LinealDimensionData;

  public saveAndRegenerateStyle = (newStyle: dimensionStyleParam) => {
    const linealDimensionDefinition = this.checkAndChangedStyle(newStyle) as linealDimensionParam;
    if (linealDimensionDefinition) {
      const command = new LinealDimensionEditDataCommand(this.data, linealDimensionDefinition, this.graphicProcessor);
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  };

}