import * as THREE from "three";
import { mainPlaneGrid, mainPlaneLines, planeOpXY, planeOpXZ, planeOpYZ, userPlaneOp } from "../styles/colors";
import { setPosBuffer } from "../geometries";
import { lineCreate } from "../geometries/line";
import { getBoundingBox2DLine } from "../geometries/bounding-box";
import { createPlane } from "../geometries/plane";
import { pointCreate } from "../geometries/point";
import { getAuxPhongMaterial, setOpacityMaterial, setColorMaterial } from "../materials";
import { IPoint } from "../math/types";
import { planeSnap } from "./plane-manager";
import { gridGraphicSettings } from "./plane";
import { copyIPoint } from "../math/point";
import { GraphicProcessor } from "lib/graphic-processor";

// Parámetros para la visualización de los Helper del plano activo
let showActivePlane = true;
let activePlaneSize = 1;
let activePlaneOpacity = 1;

let workingGridHelper: gridGraphicSettings = {
  size: activePlaneSize,
  division: 10,
  colorCenterLine: mainPlaneLines,
  colorGrid: mainPlaneGrid,
};
let materialXYauxPlane: THREE.MeshPhongMaterial;
let materialXZauxPlane: THREE.MeshPhongMaterial;
let materialYZauxPlane: THREE.MeshPhongMaterial;
let materialUserPlane: THREE.MeshPhongMaterial;

function iniWorkingPlaneMaterials() {
  materialXYauxPlane = new THREE.MeshPhongMaterial({
    color: planeOpXY,
    transparent: true,
    opacity: 0.3,
    side: THREE.DoubleSide,
  });
  materialXZauxPlane = new THREE.MeshPhongMaterial({
    color: planeOpXZ,
    transparent: true,
    opacity: 0.3,
    side: THREE.DoubleSide,
  });
  materialYZauxPlane = new THREE.MeshPhongMaterial({
    color: planeOpYZ,
    transparent: true,
    opacity: 0.3,
    side: THREE.DoubleSide,
  });
  materialUserPlane = new THREE.MeshPhongMaterial({
    color: userPlaneOp,
    transparent: true,
    opacity: 0.3,
    side: THREE.DoubleSide,
  });
}
function disposeWorkingPlaneMaterials() {
  materialXYauxPlane.dispose();
  materialXZauxPlane.dispose();
  materialYZauxPlane.dispose();
  materialUserPlane.dispose();
}

// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

export class mouseWorkingPlane {

  private graphicProcessor: GraphicProcessor;

  private planeAuxObj: THREE.Mesh;

  private intersectPlaneLine: THREE.Line;
  private intersectPlanePoint: THREE.Points;

  private threeObjGrid: THREE.GridHelper;
  private threeObjGridLimits: THREE.Line;

  constructor(graphicProcessor: GraphicProcessor) {
    this.graphicProcessor = graphicProcessor;
    iniWorkingPlaneMaterials();
    this.updateCurrentPlane();
    const pto = this.graphicProcessor.getMouseCoordinates();
    this.movePlaneAuxHelperObj(pto);
  }

  clean() {
    if (this.planeAuxObj) {
      this.deleteFromTempScene(this.planeAuxObj);
      this.planeAuxObj.geometry.dispose();
      this.planeAuxObj = undefined!;
    }
    disposeWorkingPlaneMaterials();

    if (this.intersectPlaneLine) {
      this.deleteFromTempScene(this.intersectPlaneLine);
      this.intersectPlaneLine.geometry.dispose();
      (this.intersectPlaneLine.material as THREE.Material).dispose();
      this.intersectPlaneLine = undefined!;
    }
    if (this.intersectPlanePoint) {
      this.deleteFromTempScene(this.intersectPlanePoint);
      this.intersectPlanePoint.geometry.dispose();
      (this.intersectPlanePoint.material as THREE.Material).dispose();
      this.intersectPlanePoint = undefined!;
    }
    if (this.threeObjGrid) {
      this.deleteFromTempScene(this.threeObjGrid);
      this.threeObjGrid.geometry.dispose();
      (this.threeObjGrid.material as THREE.Material).dispose();
      this.threeObjGrid = undefined!;
    }
    if (this.threeObjGridLimits) {
      this.deleteFromTempScene(this.threeObjGridLimits);
      this.threeObjGridLimits.geometry.dispose();
      (this.threeObjGridLimits.material as THREE.Material).dispose();
      this.threeObjGridLimits = undefined!;
    }
  }

  updateCurrentPlane() {
    this.updatePlaneAuxHelperObj();
  }
  setCurrPlanePosition(p: IPoint) {
    const planeManager = this.graphicProcessor.getPlaneManager();
    planeManager.lastMainPosition = copyIPoint(p);
    planeManager.activePlane.position.x = p.x;
    planeManager.activePlane.position.y = p.y;
    planeManager.activePlane.position.z = p.z;
  }
  movePlaneAuxHelperObj(p: IPoint) {
    if (showActivePlane) {
      this.planeAuxObj.position.set(p.x, p.y, p.z);
      this.planeAuxObj.updateMatrix();

      this.intersectPlanePoint.position.set(p.x, p.y, 0);
      this.intersectPlanePoint.updateMatrix();
      this.intersectPlaneLine.position.set(p.x, p.y, 0);

      this.intersectPlaneLine.updateMatrix();
      this.threeObjGrid.position.set(p.x, p.y, p.z);
      this.threeObjGrid.updateMatrix();

      this.threeObjGridLimits.position.set(p.x, p.y, p.z);
      this.threeObjGridLimits.updateMatrix();
    }
  }
  showActivePlane() {
    this.showHideActivePlane(true);
  }
  hideActivePlane() {
    this.showHideActivePlane(false);
  }
  private updatePlaneAuxHelperObj() {
    if (showActivePlane) {
      const planeManager = this.graphicProcessor.getPlaneManager();
      const { x, y, z } = planeManager.activePlane.rotation;
      if (this.planeAuxObj === undefined) this.createAuxPlaneObj();

      this.planeAuxObj.rotation.set(x, y, z);
      this.planeAuxObj.updateMatrix();

      this.threeObjGrid.rotation.set(x, y, z);
      this.threeObjGrid.rotateX(Math.PI * 0.5); // Helper is Y up axis

      this.threeObjGridLimits.rotation.set(x, y, z);
      this.threeObjGridLimits.updateMatrix();

      this.updateIntersectLine();

      // Update de materiales del plano helper
      this.setPlaneMaterial();
    }
  }
  private setPlaneMaterial() {
    const planeManager = this.graphicProcessor.getPlaneManager();
    // Update de materiales del plano helper
    if (planeManager.isUserPlaneActive) {
      this.planeAuxObj.material = materialUserPlane;
    } else {
      switch (planeManager.currentPlaneOPSnap) {
        case planeSnap.XY:
          this.planeAuxObj.material = materialXYauxPlane;
          break;
        case planeSnap.XZ:
          this.planeAuxObj.material = materialXZauxPlane;
          break;
        case planeSnap.YZ:
          this.planeAuxObj.material = materialYZauxPlane;
          break;
      }
    }
  }
  private updateIntersectLine() {
    const planeManager = this.graphicProcessor.getPlaneManager();
    if (planeManager.isUserPlaneActive) {
      this.intersectPlaneLine.visible = false;
      this.intersectPlanePoint.visible = false;
      setColorMaterial(this.intersectPlaneLine, userPlaneOp);
      setColorMaterial(this.intersectPlanePoint, userPlaneOp);
      setColorMaterial(this.threeObjGridLimits, userPlaneOp);
    } else {
      this.intersectPlaneLine.visible = true;
      this.intersectPlanePoint.visible = true;
      const size = activePlaneSize * 0.5;
      let buffer;
      let color;
      switch (planeManager.currentPlaneOPSnap) {
        case planeSnap.XY:
          buffer = new Float32Array(0);
          color = planeOpXY;
          break;
        case planeSnap.XZ:
          buffer = new Float32Array([-size, 0, 0, size, 0, 0]);
          color = planeOpXZ;
          break;
        case planeSnap.YZ:
          buffer = new Float32Array([0, -size, 0, 0, size, 0]);
          color = planeOpYZ;
          break;
      }
      if (buffer) setPosBuffer(this.intersectPlaneLine, buffer);
      if (color) setColorMaterial(this.intersectPlaneLine, color);
      if (color) setColorMaterial(this.intersectPlanePoint, color);
      if (color) setColorMaterial(this.threeObjGridLimits, color);
      setOpacityMaterial(this.threeObjGridLimits, 0.7);
    }
  }
  private createAuxPlaneObj() {
    const planeManager = this.graphicProcessor.getPlaneManager();
    const { x, y, z } = planeManager.activePlane.rotation;

    this.planeAuxObj = createPlane(1, 1, 1, 5);
    const material = getAuxPhongMaterial();
    this.planeAuxObj.material = material;
    const size = activePlaneSize;
    this.planeAuxObj.scale.set(size, size, 1);
    this.planeAuxObj.rotation.set(x, y, z);
    this.planeAuxObj.updateMatrix();
    this.saveToTempScene(this.planeAuxObj);

    this.intersectPlaneLine = lineCreate();
    this.saveToTempScene(this.intersectPlaneLine);

    this.intersectPlanePoint = pointCreate(0, 0, 0, 5);
    this.saveToTempScene(this.intersectPlanePoint);

    this.threeObjGrid = new THREE.GridHelper(
      workingGridHelper.size,
      workingGridHelper.division,
      workingGridHelper.colorCenterLine,
      workingGridHelper.colorGrid
    );
    this.threeObjGrid.rotation.set(x, y, z);
    this.threeObjGrid.rotateX(Math.PI * 0.5); // Helper is Y up axis
    setOpacityMaterial(this.threeObjGrid, activePlaneOpacity);
    this.saveToTempScene(this.threeObjGrid);

    this.threeObjGridLimits = getBoundingBox2DLine({
      min: { x: -0.5, y: -0.5, z: 0 },
      max: { x: 0.5, y: 0.5, z: 0 },
    });
    this.threeObjGridLimits.scale.set(size, size, 1);
    this.saveToTempScene(this.threeObjGridLimits);
  }

  private saveToTempScene(threeObj: THREE.Object3D) {
    const scene = this.graphicProcessor?.getAuxScene();
    scene.add(threeObj);
  }
  private deleteFromTempScene(threeObj: THREE.Object3D): void {
    const scene = this.graphicProcessor?.getAuxScene();
    scene?.remove(threeObj);
  }
  private showHideActivePlane(show: boolean) {
    showActivePlane = show;
    if (this.threeObjGrid) { this.threeObjGrid.visible = show; }
    if (this.intersectPlaneLine) { this.intersectPlaneLine.visible = show; }
    if (this.intersectPlanePoint) { this.intersectPlanePoint.visible = show; }
    if (this.threeObjGridLimits) { this.threeObjGridLimits.visible = show; }
    if (this.planeAuxObj) { this.planeAuxObj.visible = show; }
    this.updateCurrentPlane();
  }
  private changePlaneHelperSize() {
    if (this.planeAuxObj) {
      const size = activePlaneSize;
      this.planeAuxObj.scale.set(size, size, 1);
      this.threeObjGrid.scale.set(size, size, 1);
      this.threeObjGridLimits.scale.set(size, size, 1);
    }
  }
  private changePlaneHelperOpacity() {
    if (this.threeObjGrid) {
      const opacity = activePlaneOpacity;
      setOpacityMaterial(this.threeObjGrid, opacity);
    }
  }
}
