import { FC } from "react";

interface Props {
  isTrue: boolean;
}
const RenderIf: FC<Props> = ({ children, isTrue }) => {
  return isTrue ? <>{children}</> : null;
};

export default RenderIf;
