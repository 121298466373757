import { StoreyDriftAction, StoreyDriftActionType } from "lib/events/storey-drift";
import { CompositeLeaf, CompositeNode } from "lib/helpers/composite-tree";
import { storeyDriftRepo } from "lib/models-struc/storey-drift/storey-drift-repo";
import { storeyDriftItem, itemType, toolsItem } from "modules/cad/components/sidebar/context";
import { useEffect, useMemo, useReducer } from "react";

function buildStoreyDriftNodes(storeyDriftState: CompositeLeaf<storeyDriftItem>[]) {
  const combinationsNode = new CompositeNode<toolsItem>("Load combination", { info: undefined, type: itemType.TOOLS });
  combinationsNode.id = "Load_combination_node";
  const toolNode = combinationsNode.addChild("Tools", { info: undefined, type: itemType.TOOLS });
  toolNode.id = "Tool_node";
  for (const SDNode of storeyDriftState) {
    toolNode.addChildNode(SDNode);
  }
  return combinationsNode;
}
interface storeyDriftState {
  storeyDriftNodes: CompositeLeaf<storeyDriftItem>[];
}

function reducer(state: storeyDriftState, action: StoreyDriftAction): storeyDriftState {
  switch (action.type) {
    case StoreyDriftActionType.ADD_COMBINATIONS:
    case StoreyDriftActionType.DELETE_COMB: {
      const storeyDriftNodes: CompositeLeaf<storeyDriftItem>[] = [];
      const comb = storeyDriftRepo.getAll();
      for (const [id, item] of comb) {
        const prop: storeyDriftItem = { info: { id, ...item }, type: itemType.STOREYDRIFT };
        const node = new CompositeLeaf(item.name, prop);
        node.id = id;
        storeyDriftNodes.push(node);
      }
      return { storeyDriftNodes };
    }
    default:
      throw new Error(`Action is not defined.`);
  }
}

export function useStoreyDrift() {

  const [storeyDriftState, dispatch] = useReducer(reducer, { storeyDriftNodes: [] });

  useEffect(() => {
    storeyDriftRepo.eventBus.subscribe(dispatch);
    return () => {
      storeyDriftRepo.eventBus.unsubscribe(dispatch);
    }
  }, []);

  const nodes = useMemo(() => {
    return buildStoreyDriftNodes(storeyDriftState.storeyDriftNodes)
  }, [storeyDriftState])

  return {
    storeyDriftNodes: nodes,
  }
}