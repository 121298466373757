import { ObserverManager } from "lib/events/event-bus";
import { userMessageEvents } from "lib/events/user-messages";
import { v4 as uuid } from 'uuid'

export enum CacheActionType {
  UPDATE_CACHE, SAVE_CACHE, DELETE_CACHE
}

export type cacheAction<T> = {
  type: CacheActionType;
  payload: {
    id: string,
    style: T,
  };
};

type cacheActionCallback<T> = (action: cacheAction<T>) => void;

// -------------------------------------------------------------

export interface style<T> {
  styleId: string,
  default: boolean,
  clone: () => T,
  override: (opt: Partial<T>) => void,
}

export class StyleCacheHandler<T extends style<T>> {

  private cacheMap: Map<string, T> = new Map();
  private cacheObserver = new ObserverManager<cacheAction<T>>();

  protected clear() {
    this.cacheMap.clear();
  }
  get length() {
    return this.cacheMap.size;
  }

  public loadStyle(id: string, overrideStyle?: Partial<T>): T | undefined {
    const style = this.cacheMap.get(id);
    if (style) {
      const styleCopy = style.clone();
      if (overrideStyle) {
        styleCopy.override(overrideStyle);
      }
      return styleCopy;
    }
  }
  public loadStylefromCache(id: string, overrideStyle?: Partial<T>): T | undefined {
    const style = this.cacheMap.get(id);
    if (style) {
      const styleCopy = style.clone();
      if (overrideStyle) {
        styleCopy.override(overrideStyle);
      }
      return styleCopy;
    }
  }

  /* ***************************************************** */

  public saveStyle(definition: T): string {
    const id = definition.styleId ?? uuid();
    definition.styleId = id;
    this.cacheMap.set(id, definition);
    this.cacheObserver.dispatch({
      type: CacheActionType.SAVE_CACHE,
      payload: {
        id,
        style: definition,
      }
    })
    return id;
  }
  public updateStyle(id: string, definition: Partial<T>): void {
    const style = this.cacheMap.get(id);
    if (style) {
      style.override(definition);
      this.cacheObserver.dispatch({
        type: CacheActionType.UPDATE_CACHE,
        payload: { id: id, style, }
      })
    }
  }

  /* ***************************************************** */

  public deleteStyle(id: string): boolean {
    const style = this.cacheMap.get(id);
    if (style && !style.default) {
      this.cacheMap.delete(id);
      this.cacheObserver.dispatch({
        type: CacheActionType.DELETE_CACHE,
        payload: { id: id, style }
      })
      return true;
    } else {
      userMessageEvents.dispatchError("Default styles can't be deleted");
      return false;
    }
  }

  /* ***************************************************** */

  public getAllLoadedStyles(): T[] {
    const styles = [];
    for (const style of this.cacheMap.values()) {
      styles.push(style.clone());
    }
    return styles
  }

  public exportStyles(): Record<string, T> {
    const data: Record<string, T> = {};
    const styles = this.getAllLoadedStyles();
    for (const style of styles) {
      if (!style.default) {
        data[style.styleId] = { ...style };
      }
    }
    return data;
  }

  /* ***************************************************** */

  public subscribe(callback: cacheActionCallback<T>) {
    this.cacheObserver.subscribe(callback);
  }
  public unsubscribe(callback: cacheActionCallback<T>) {
    this.cacheObserver.unsubscribe(callback);
  }
}
