import { GraphicProcessor } from "lib/graphic-processor";
import GridIcon from "../icons/grid";
import ViewportIcon from "../icons/viewport";
import ToolbarButton from "../toolbar/toolbar-button";
import { useViewportMode } from "./hooks/use-viewport-mode";

const ViewportCADToolbar = ({ graphicProc }: { graphicProc: GraphicProcessor }) => {
  const {
    viewportMode,
    setPerspectiveMode,
    setElevationMode,
    setPlanMode,
    setProfileMode,
    setMultiViewportMode,
  } = useViewportMode(graphicProc);
  return (
    <div className="flex items-center divide-x divide-gray-600">
      <ToolbarButton
        icon={<ViewportIcon className="h-4 w-4" />}
        onClick={setPerspectiveMode}
        tooltip="Viewport"
        className="tooltip tooltip-top"
        isActive={viewportMode !== "multiple"}
      />
      <ToolbarButton
        icon={<GridIcon className="h-5 w-5" />}
        onClick={setMultiViewportMode}
        tooltip="Multiviewport"
        className="tooltip tooltip-top"
        isActive={viewportMode === "multiple"}
      />
      <ToolbarButton
        className="px-1"
        onClick={setPerspectiveMode}
        title="Perspective"
        isActive={viewportMode === "perspective"}
      />
      <ToolbarButton
        className="px-1"
        onClick={setElevationMode}
        title="Elevation"
        isActive={viewportMode === "elevation"}
      />
      <ToolbarButton
        className="px-1"
        onClick={setPlanMode}
        title="Plan"
        isActive={viewportMode === "plan"}
      />
      <ToolbarButton
        className="px-1"
        onClick={setProfileMode}
        title="Profile"
        isActive={viewportMode === "profile"}
      />
      <div className="h-8" />
    </div>
  );
};

export default ViewportCADToolbar;
