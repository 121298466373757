import { addIpoint, dotProductIpoint, mulIpoint, normalizeIpoint, substractIpoint } from "./point";
import { IPoint } from "./types";
import { crossProductIpoint } from "./point";
import * as THREE from "three";
import { isZero } from "./epsilon";

/** Definimos el plano con tres puntos no colineares
 *
 * @export
 * @interface IPlane
 * @memberof MEC.MATH
 */
export interface IPlane {
  p1: IPoint;
  p2: IPoint;
  p3: IPoint;
}
/** Calcula de la normal a un plano definido por 3 puntos en sentido horario
 * (sacacorchos rule)
 * @export
 * @param {IPlane} plane
 * @returns {IPoint}
 */
export function getNormalOfPlane(plane: IPlane): IPoint {
  const a = substractIpoint(plane.p3, plane.p1);
  const b = substractIpoint(plane.p2, plane.p1);
  return crossProductIpoint(a, b);
}
export function getNormalFromEuler(rotation: IPoint): IPoint {
  const normal = new THREE.Vector3(0, 0, 1);
  const euler = new THREE.Euler(rotation.x, rotation.y, rotation.z)
  return normal.applyEuler(euler);
}

export function getEulerFromNormalAndPoint(normal: IPoint, pto: IPoint = { x: 0, y: 0, z: 0 }) {
  const normalV = new THREE.Vector3(normal.x, normal.y, normal.z);
  const ptoV = new THREE.Vector3(pto.x, pto.y, pto.z);

  const p = new THREE.Plane();
  p.setFromNormalAndCoplanarPoint(normalV, ptoV);
  const v = new THREE.Vector3();
  p.projectPoint(new THREE.Vector3(ptoV.x, ptoV.y, ptoV.z + 1), v);
  const vY = v.subVectors(v, ptoV);
  const vx = crossProductIpoint(vY, normalV);
  const vX = new THREE.Vector3(vx.x, vx.y, vx.z);

  var rotationMatrix = new THREE.Matrix4();
  rotationMatrix.makeBasis(vX, vY, normalV);

  return new THREE.Euler().setFromRotationMatrix(rotationMatrix, "XYZ");
}

export function getEulerFromXYvectors(vx: IPoint, vy: IPoint) {
  const vX = new THREE.Vector3(vx.x, vx.y, vx.z);
  const vY = new THREE.Vector3(vy.x, vy.y, vy.z);
  const normal = crossProductIpoint(vx, vy);
  const vNormal = new THREE.Vector3(normal.x, normal.y, normal.z);
  var rotationMatrix = new THREE.Matrix4();
  rotationMatrix.makeBasis(vX, vY, vNormal);
  return new THREE.Euler().setFromRotationMatrix(rotationMatrix, "XYZ");
}

export function getXYZEulerFromEulerAngles(angleX: number, angleY: number, angleZ: number, order: string) {
  const rotation = new THREE.Euler(angleX, angleY, angleZ, order);
  rotation.reorder("XYZ");
  return { x: rotation.x, y: rotation.y, z: rotation.z };
}

export function addEulerAnglesToEulerAngles(euler0: IPoint, euler1: IPoint) {
  const oriQ = new THREE.Quaternion();
  oriQ.setFromEuler(new THREE.Euler(euler0.x, euler0.y, euler0.z));
  const endQ = new THREE.Quaternion();
  endQ.setFromEuler(new THREE.Euler(euler1.x, euler1.y, euler1.z));
  endQ.multiply(oriQ);
  const e = new THREE.Euler().setFromQuaternion(endQ);
  return { x: e.x, y: e.y, z: e.z };
}

export function projectPointInPlane(pto: IPoint, planePto: IPoint, planeNormal: IPoint) {
  const v = substractIpoint(pto, planePto);
  const d = v.x * planeNormal.x + v.y * planeNormal.y + v.z * planeNormal.z;
  return substractIpoint(pto, mulIpoint(planeNormal, d));
}

// http://paulbourke.net/geometry/pointlineplane/
export function intersectLinePlane(lineStart: IPoint, lineEnd: IPoint, plane: IPlane): IPoint | null {

  const vectLine = substractIpoint(lineEnd, lineStart);
  const p3Ls = substractIpoint(plane.p3, lineStart);
  const N = crossProductIpoint(substractIpoint(plane.p3, plane.p1), substractIpoint(plane.p2, plane.p1));

  const n = (N.x * p3Ls.x) + (N.y * p3Ls.y) + (N.z * p3Ls.z);
  if (isZero(n)) console.warn("n es 0, ¿los puntos de definición del plano no serán colineares, no?");

  const d = (N.x * vectLine.x) + (N.y * vectLine.y) + (N.z * vectLine.z);
  if (isZero(d)) return null; // línea paralela o conteniada en el plano

  const u = n / d;
  return addIpoint(lineStart, mulIpoint(vectLine, u));
}

/**Determines the point of intersection between a plane defined by a point and a normal vector and a line defined by a point and a direction vector.
 *
 * @param planePoint    A point on the plane.
 * @param planeNormal   The normal vector of the plane.
 * @param linePoint     A point on the line.
 * @param lineDirection The direction vector of the line.
 * @return The point of intersection between the line and the plane, null if the line is parallel to the plane.
 */
export function lineIntersection(planePoint: IPoint, planeNormal: IPoint, linePoint: IPoint, lineDirection: IPoint): IPoint | null {
  const dir = normalizeIpoint(lineDirection);
  const d = dotProductIpoint(planeNormal, dir);
  if (d === 0) return null; // línea paralela o conteniada en el plano
  const n = dotProductIpoint(planeNormal, planePoint) - dotProductIpoint(planeNormal, linePoint);
  const u = n / d;
  return addIpoint(linePoint, mulIpoint(normalizeIpoint(lineDirection), u));
}