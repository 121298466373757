import { GraphicProcessor } from "lib/graphic-processor";
import { Project } from "../struc-project";

export class ProjectModelManager {

  public project: Project;
  protected graphicProcessor: GraphicProcessor;

  constructor(graphicProcessor: GraphicProcessor) {
    this.graphicProcessor = graphicProcessor;
  }

  public addProject(proj: Project) {
    if (proj) {
      this.project = proj;
    }
  }

  public clearAll() {
    for (const site of this.project.sites) {
      for (const building of site.buildings) {
        building.clear();
      }
      site.buildings.length = 0;
    }
    this.project.sites.length = 0;
  }
}