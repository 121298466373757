import { IPoint } from "lib/math/types";
import { isZero, vector3Equals } from "lib/math/epsilon";
import { calculateOffsetBuffer } from "lib/math/offset-buffer";

import { Arc, Circle, defaultOffsetWith, GrowthWidth, LineSegment, OffsetGeometry, Point, Rectangle, WireGeometry } from "modules/struc/models/ecore/geometry";
import { geometryUriModel } from "modules/struc/models/ecore/uri";
import { growthWidth } from "lib/models-struc/types/struc-base";
import { getEcoreFloat } from "./helper-ecore";
import { getEcoreVector3, getEcoreVector2 } from "./locations";


export function getEcorePointGeometry(point: IPoint): Point {
  return {
    eClass: `${geometryUriModel}Point`,
    coords: getEcoreVector3(point),
  };
}

export function getEcoreCircle(center: IPoint, radius: number): Circle {
  const circle: Circle = {
    eClass: `${geometryUriModel}Circle`,
    center: getEcoreVector2(center),
  };
  const ecoreRadius = getEcoreFloat(radius, 0);
  if (ecoreRadius) circle.radio = radius;
  return circle;
}

export function getEcoreRectangle(p0: IPoint, p1: IPoint): Rectangle {
  return {
    eClass: `${geometryUriModel}Rectangle`,
    p0: getEcoreVector2(p0),
    p1: getEcoreVector2(p1),
  };
}


export function getEcoreClosedWireGeometry(points: IPoint[]): WireGeometry {
  const geometries: LineSegment[] = [];
  const isZeroZ = points.every(p => isZero(p.z));
  for (let i = 0; i < points.length; i++) {
    let ptos = points.slice(i, i + 2);
    if (ptos.length === 1 && !vector3Equals(ptos[0], points[0])) {
      // Only one point, and last point != first point => concat first point to line segment
      ptos = ptos.concat(points[0]);
    }
    if (ptos.length === 2) {
      geometries.push(getEcoreLineSegment(ptos[0], ptos[1], isZeroZ));
    }
  }
  return {
    eClass: `${geometryUriModel}WireGeometry`,
    geometries: geometries,
  };
}
export function getEcoreOpenWireGeometry(points: IPoint[]): WireGeometry {
  const geometries: LineSegment[] = []
  const isZeroZ = points.every(p => isZero(p.z));
  for (let i = 0; i < points.length; i++) {
    let ptos = points.slice(i, i + 2);
    if (ptos.length === 2) {
      const lineSegment = getEcoreLineSegment(ptos[0], ptos[1], isZeroZ);
      geometries.push(lineSegment);
    }
  }
  return {
    eClass: `${geometryUriModel}WireGeometry`,
    geometries: geometries,
  };
}
export function getEcoreLineSegment(p0: IPoint, p1: IPoint, is2D: boolean = true): LineSegment {
  return {
    eClass: `${geometryUriModel}LineSegment`,
    p0: is2D ? getEcoreVector2(p0) : getEcoreVector3(p0),
    p1: is2D ? getEcoreVector2(p1) : getEcoreVector3(p1),
  };
}
function getEcoreArc(p0: IPoint, p1: IPoint, pa: IPoint): Arc {
  return {
    eClass: `${geometryUriModel}Arc`,
    p0: getEcoreVector3(p0),
    p1: getEcoreVector3(p1),
    pa: getEcoreVector3(pa),
  };
}

export function getEcoreOffsetGeometry(points: IPoint[], widthType: growthWidth, widthValue: number): OffsetGeometry {
  if (widthType === growthWidth.TOLEFT) {
    points = calculateOffsetBuffer({ points, arcs: [], isClosed: false }, widthValue * 0.5)[0].points;
  }
  if (widthType === growthWidth.TORIGHT) {
    points = calculateOffsetBuffer({ points, arcs: [], isClosed: false }, -widthValue * 0.5)[0].points;
  }
  const wall: OffsetGeometry = {
    eClass: `${geometryUriModel}OffsetGeometry`,
    wiregeometry: getEcoreOpenWireGeometry(points),
    growthWidth: GrowthWidth.CENTER,
  };
  // const grwthWdth = getEcoreGrowthWidthWall(widthType);
  // if (grwthWdth !== undefined) wall.growthWidth = grwthWdth;
  const width = getEcoreFloat(widthValue, defaultOffsetWith);
  if (width !== undefined) wall.width = width;
  return wall;
}
function getEcoreGrowthWidthWall(widthType: growthWidth): GrowthWidth {
  if (widthType === growthWidth.TOLEFT) {
    return GrowthWidth.LEFT;
  } else if (widthType === growthWidth.TORIGHT) {
    return GrowthWidth.RIGHT;
  }
  return GrowthWidth.CENTER;
}
