import { circlePoints } from "lib/geometries/circle";
import { getBoundingBoxPoints, IBox } from "lib/math/box";
import { calculateCentroidPoints } from "lib/math/centroid";
import { substractIpoint } from "lib/math/point";
import { IPoint } from "lib/math/types";
import { originPoint } from "../../types/struc-base";
import { BeamCrossSection } from "./beamcs-shapes";
import { beamCrossSectionCache } from "./cache";
import { ISectionT, ISectionI, ISectionU, sectionType } from "./types";

export function generatePointsBeamCrossSection(section: BeamCrossSection, originPto: originPoint = originPoint.CENTERCENTER): { contour: IPoint[]; holes: IPoint[]; } {
  const pto = { x: 0, y: 0, z: 0 };
  const param = section.parameter;
  let sectionPtos: IPoint[] = [];
  let holePtos: IPoint[] = [];
  switch (param.beamSectionType) {
    case sectionType.CIRC:
      sectionPtos = circlePoints(pto, param.radius); break;
    case sectionType.TUBE:
      sectionPtos = circlePoints(pto, param.radius);
      holePtos = circlePoints(pto, param.radius - param.thickness);
      break;
    case sectionType.RECT:
      sectionPtos = generateRectangularSection(param.width, param.height); break;
    case sectionType.QUAD:
      sectionPtos = generateRectangularSection(param.width, param.height);
      holePtos = generateRectangularSection(param.width - param.horizontalFlangeThickness, param.height - param.verticalWebThickness);
      break;
    case sectionType.T:
      sectionPtos = generateTSection(param); break;
    case sectionType.I:
      sectionPtos = generateISection(param); break;
    case sectionType.U:
      sectionPtos = generateUSection(param); break;
    case sectionType.CUSTOM:
      sectionPtos = param.polyline.points; break;
  }
  const referencePto = referenceSectionPoint(sectionPtos, originPto);
  const contour = sectionPtos.map(p => substractIpoint(p, referencePto));
  const holes = holePtos.map(p => substractIpoint(p, referencePto));
  return { contour, holes }
}
export function getBoundingBoxSection(crossSectionId: string): IBox {
  const section = beamCrossSectionCache.loadStylefromCache(crossSectionId)!;
  const param = section.parameter;
  switch (param.beamSectionType) {
    case sectionType.CIRC:
    case sectionType.TUBE:
      const r = param.radius;
      return {
        min: { x: -r, y: -r, z: 0 },
        max: { x: r, y: r, z: 0 },
      }
    case sectionType.RECT:
    case sectionType.QUAD:
    case sectionType.T:
    case sectionType.I:
    case sectionType.U:
    case sectionType.CUSTOM:
      const { contour } = generatePointsBeamCrossSection(section);
      const bbox = getBoundingBoxPoints(contour);
      return bbox;
  }
}
export function generateRectangularSection(deltaX: number, deltaY: number, originPto: originPoint = originPoint.CENTERCENTER): IPoint[] {

  //    1_____2
  //    |     |
  //    |_____|
  //    4     3

  switch (originPto) {
    case originPoint.TOPLEFT:
      return [
        { x: 0, y: 0, z: 0 },
        { x: deltaX, y: 0, z: 0 },
        { x: deltaX, y: -deltaY, z: 0 },
        { x: 0, y: -deltaY, z: 0 }
      ];
    case originPoint.TOPCENTER:
      return [
        { x: -deltaX * 0.5, y: 0, z: 0 },
        { x: deltaX * 0.5, y: 0, z: 0 },
        { x: deltaX * 0.5, y: -deltaY, z: 0 },
        { x: -deltaX * 0.5, y: -deltaY, z: 0 },
      ];
    case originPoint.TOPRIGHT:
      return [
        { x: -deltaX, y: 0, z: 0 },
        { x: 0, y: 0, z: 0 },
        { x: 0, y: -deltaY, z: 0 },
        { x: -deltaX, y: -deltaY, z: 0 },
      ];
    case originPoint.CENTERLEFT:
      return [
        { x: 0, y: deltaY * 0.5, z: 0 },
        { x: deltaX, y: deltaY * 0.5, z: 0 },
        { x: deltaX, y: -deltaY * 0.5, z: 0 },
        { x: 0, y: -deltaY * 0.5, z: 0 },
      ];
    case originPoint.CENTERCENTER:
      return [
        { x: -deltaX * 0.5, y: deltaY * 0.5, z: 0 },
        { x: deltaX * 0.5, y: deltaY * 0.5, z: 0 },
        { x: deltaX * 0.5, y: -deltaY * 0.5, z: 0 },
        { x: -deltaX * 0.5, y: -deltaY * 0.5, z: 0 },
      ];
    case originPoint.CENTERRIGHT:
      return [
        { x: -deltaX, y: deltaY * 0.5, z: 0 },
        { x: 0, y: deltaY * 0.5, z: 0 },
        { x: 0, y: -deltaY * 0.5, z: 0 },
        { x: -deltaX, y: -deltaY * 0.5, z: 0 },
      ];
    case originPoint.BOTTOMLEFT:
      return [
        { x: 0, y: deltaY, z: 0 },
        { x: deltaX, y: deltaY, z: 0 },
        { x: deltaX, y: 0, z: 0 },
        { x: 0, y: 0, z: 0 },
      ];
    case originPoint.BOTTOMCENTER:
      return [
        { x: -deltaX * 0.5, y: deltaY, z: 0 },
        { x: deltaX * 0.5, y: deltaY, z: 0 },
        { x: deltaX * 0.5, y: 0, z: 0 },
        { x: -deltaX * 0.5, y: 0, z: 0 },
      ];
    case originPoint.BOTTOMRIGHT:
      return [
        { x: -deltaX, y: deltaY, z: 0 },
        { x: 0, y: deltaY, z: 0 },
        { x: 0, y: 0, z: 0 },
        { x: -deltaX, y: 0, z: 0 },
      ];
  }
}
function generateTSection(param: ISectionT): IPoint[] {
  const {
    height, topFlangeLength, topFlangeThickness, webThickness
  } = param;

  //  0 _______ 1
  //   |__   __|
  //  7  6| |3  2
  //      | |   
  //      |_|   
  //     5   4

  // Calculate section with origin BOTTOM-LEFT corner
  const ptos2D: IPoint[] = [];
  ptos2D.push({ x: 0, y: height, z: 0 });
  ptos2D.push({ x: topFlangeLength, y: height, z: 0 });
  ptos2D.push({ x: topFlangeLength, y: height - topFlangeThickness, z: 0 });
  const aux = (topFlangeLength - webThickness) * 0.5;
  ptos2D.push({ x: topFlangeLength - aux, y: height - topFlangeThickness, z: 0 });
  ptos2D.push({ x: topFlangeLength - aux, y: 0, z: 0 });
  ptos2D.push({ x: aux, y: 0, z: 0 });
  ptos2D.push({ x: aux, y: height - topFlangeThickness, z: 0 });
  ptos2D.push({ x: 0, y: height - topFlangeThickness, z: 0 });
  return ptos2D;
}
function generateISection(param: ISectionI): IPoint[] {
  const {
    height, topFlangeLength, topFlangeThickness, webThickness, bottomFlangeLength, bottomFlangeThickness } = param;

  //  5 _____ 6 
  //   |_   _|
  //  4 3| |8 7
  //     | |  
  //  1 2| |9 10 
  //   |_____|
  //  0       11

  // Calculate section with origin BOTTOM-LEFT corner
  const ptos2D: IPoint[] = [];
  ptos2D.push({ x: 0, y: 0, z: 0 });
  ptos2D.push({ x: 0, y: bottomFlangeThickness, z: 0 });
  const auxBottom = (bottomFlangeLength - webThickness) * 0.5;
  ptos2D.push({ x: auxBottom, y: bottomFlangeThickness, z: 0 });
  ptos2D.push({ x: auxBottom, y: height - topFlangeThickness, z: 0 });
  const auxTop = (topFlangeLength - webThickness) * 0.5;
  ptos2D.push({ x: auxBottom - auxTop, y: height - topFlangeThickness, z: 0 });
  ptos2D.push({ x: auxBottom - auxTop, y: height, z: 0 });
  ptos2D.push({ x: (auxBottom - auxTop) + topFlangeLength, y: height, z: 0 });
  ptos2D.push({ x: (auxBottom - auxTop) + topFlangeLength, y: height - topFlangeThickness, z: 0 });
  ptos2D.push({ x: bottomFlangeLength - auxBottom, y: height - topFlangeThickness, z: 0 });
  ptos2D.push({ x: bottomFlangeLength - auxBottom, y: bottomFlangeThickness, z: 0 });
  ptos2D.push({ x: bottomFlangeLength, y: bottomFlangeThickness, z: 0 });
  ptos2D.push({ x: bottomFlangeLength, y: 0, z: 0 });
  return ptos2D;
}
function generateUSection(param: ISectionU): IPoint[] {
  const {
    height, width, verticalWebThickness, horizontalFlangeThickness,
  } = param;

  // 1 _ 2  5 _ 6 
  //  | |    | |
  //  | |    | |
  //  | |3__4| |
  //  |________|
  // 0          7

  // Calculate section with origin BOTTOM-LEFT corner
  const ptos2D: IPoint[] = [];
  ptos2D.push({ x: 0, y: 0, z: 0 });
  ptos2D.push({ x: 0, y: height, z: 0 });
  ptos2D.push({ x: horizontalFlangeThickness, y: height, z: 0 });
  ptos2D.push({ x: horizontalFlangeThickness, y: verticalWebThickness, z: 0 });
  ptos2D.push({ x: width - horizontalFlangeThickness, y: verticalWebThickness, z: 0 });
  ptos2D.push({ x: width - horizontalFlangeThickness, y: height, z: 0 });
  ptos2D.push({ x: width, y: height, z: 0 });
  ptos2D.push({ x: width, y: 0, z: 0 });
  return ptos2D;
}
function referenceSectionPoint(contourSection: IPoint[], originPto: originPoint): IPoint {
  const bbox = getBoundingBoxPoints(contourSection);
  let base = calculateCentroidPoints(contourSection)!;
  switch (originPto) {
    case originPoint.TOPLEFT:
      base.x = bbox.min.x;
      base.y = bbox.max.y;
      break;
    case originPoint.TOPCENTER:
      base.y = bbox.max.y; break;
    case originPoint.TOPRIGHT:
      base.x = bbox.max.x;
      base.y = bbox.max.y;
      break;

    case originPoint.CENTERLEFT:
      base.x = bbox.min.x; break;
    case originPoint.CENTERRIGHT:
      base.x = bbox.max.x; break;

    case originPoint.BOTTOMLEFT:
      base.x = bbox.min.x;
      base.y = bbox.min.y;
      break;
    case originPoint.BOTTOMCENTER:
      base.y = bbox.min.y; break;
    case originPoint.BOTTOMRIGHT:
      base.x = bbox.max.x;
      base.y = bbox.min.y;
      break;
  }
  return base;
}