import { solidParam } from "lib/models/solids/solid";
import * as THREE from "three";
import { setPosRotEsc } from "..";
import { getAuxMeshBasicMaterial } from "../../materials";
import { IPoint } from "../../math/types";

export interface pprismParam extends solidParam {
    radius: number,
    height: number,
    sides: number,
    open: boolean,
}

export function createBufferPPrism(radius: number, height: number, sides: number, open: boolean) {
    const geom = new THREE.CylinderBufferGeometry(radius, radius, height, sides, undefined, open);
    geom.rotateX(Math.PI * 0.5);
    return geom
}

export function createPPrism(radius: number, height: number, sides: number, open: boolean, material?: THREE.MeshPhongMaterial, basePoint?: IPoint, rotation?: IPoint, offset?: IPoint, scale?: IPoint) {
    const geom = createBufferPPrism(radius, height, sides, open);
    const mat = material ?? getAuxMeshBasicMaterial();
    const threeObj = new THREE.Mesh(geom, mat);
    if (basePoint && offset && rotation && scale) {
        setPosRotEsc(threeObj, basePoint, offset, rotation, scale);
    }
    return threeObj;
}
