const CylinderIcon = ({ ...props }) => {
  return (
    <svg
      className="h-5 w-5"
      viewBox="0 0 100 100"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.2563 78.6665L59.928 80.664C58.6146 80.4542 57.3103 80.29 55.9787 80.1714L56.1611 78.1558C57.5383 78.2835 58.8882 78.4476 60.2563 78.6665Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.3034 80.0255L65.7379 81.9683C64.461 81.5852 63.1841 81.2751 61.8889 81.0197L62.2811 79.0314C63.6401 79.305 64.9809 79.6333 66.3034 80.0255Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.1315 82.406L71.1374 84.1755C70.0155 83.537 68.8207 83.0263 67.5985 82.5793L68.2917 80.6731C69.6142 81.1565 70.9093 81.7129 72.1315 82.406Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.8087 80.6366L32.4927 82.5429C31.2888 82.9807 30.0666 83.5005 28.9447 84.1208L27.9597 82.3513C29.1728 81.6764 30.4953 81.1109 31.8087 80.6366Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.8192 79.0131L38.2114 80.9923C36.9619 81.2477 35.5846 81.5669 34.3716 81.9318L33.7879 79.989C35.0921 79.606 36.4876 79.2685 37.8192 79.0131Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.9393 78.1467L44.1217 80.1624C42.7992 80.2809 41.4858 80.436 40.1724 80.6458L39.8532 78.6483C41.2122 78.4294 42.5712 78.2652 43.9393 78.1467Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.1089 78.0007L53.9903 80.0164C51.3818 79.8614 48.7185 79.8614 46.11 80.0164L45.9914 77.9916C48.6821 77.8366 51.4183 77.8366 54.1089 78.0007Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.1414 15.3594C73.7003 16.8004 71.5478 18.0773 68.8572 19.108C58.3957 23.1302 41.6043 23.1302 31.1428 19.108C28.4431 18.0773 26.2906 16.8004 24.8495 15.3594V84.6224C25.2508 84.2211 25.6886 83.8563 26.1355 83.5188L27.3577 85.1423C22.2775 88.9183 25.5153 92.6578 31.8634 95.1022C41.8506 98.9329 58.1494 98.9329 68.1366 95.1022C74.3752 92.7034 77.7317 88.9821 72.7244 85.2061L73.9466 83.5826C74.357 83.8927 74.7674 84.2393 75.1414 84.6224V15.3594ZM68.8572 96.981C58.3774 101.012 41.6226 101.012 31.1428 96.981C26.1538 95.0657 23.0071 92.3386 22.8338 89.2101L22.8247 11.0635C22.8247 7.82561 25.9987 4.98905 31.1428 3.00985C41.6043 -1.00328 58.3957 -1.00328 68.8572 3.00985C73.9739 4.97993 77.1479 7.84385 77.1753 11.0635L77.1662 89.2101C76.9838 92.3386 73.8462 95.0657 68.8572 96.981ZM68.1366 4.89785C77.4307 8.47318 77.3942 13.672 68.1366 17.2291C58.1676 21.0598 41.8324 21.0598 31.8634 17.2291C22.5511 13.6538 22.5876 8.45494 31.8634 4.89785C41.8324 1.06713 58.1676 1.06713 68.1366 4.89785Z"
      />
    </svg>
  );
};

export default CylinderIcon;
