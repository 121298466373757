import { createContext, FC, useCallback, useMemo, useReducer, useContext } from "react";
import { cadOpType } from "../../../../lib/operations/factory";
import { useMainGraphicContext } from "../viewport/context";
import { initialOperationState, operationReducer, OperationActions, OperationState } from "./reducer";

const OperationContext = createContext<OperationState & OperationActions>({} as OperationState & OperationActions);

const OperationProvider: FC = ({ children }) => {

  const [state, dispatch] = useReducer(operationReducer, initialOperationState);

  const graphicProc = useMainGraphicContext();

  const launchOperation = useCallback(
    (operationType: cadOpType, withSelectedObjects: boolean = false) => {
      if (withSelectedObjects) {
        const objs = graphicProc.getSelectedObjs();
        graphicProc.launchOP<any>(operationType, [objs]);
      } else {
        graphicProc.launchOP(operationType);
      }
    }, [graphicProc]
  );

  const createPoint = useCallback(() => {
    launchOperation(cadOpType.POINTS);
  }, [launchOperation]);

  const createLine = useCallback(() => {
    launchOperation(cadOpType.LINE);
  }, [launchOperation]);

  const createPolyline = useCallback(() => {
    launchOperation(cadOpType.POLYLINE);
  }, [launchOperation]);

  const createPolygon = useCallback(() => {
    launchOperation(cadOpType.POLYGON);
  }, [launchOperation]);

  const createCircle = useCallback(() => {
    launchOperation(cadOpType.CIRCLE);
  }, [launchOperation]);

  const createText = useCallback(() => {
    launchOperation(cadOpType.TEXT);
  }, [launchOperation]);

  const createParagraph = useCallback(() => {
    launchOperation(cadOpType.TEXTP);
  }, [launchOperation]);

  const createCube = useCallback(() => {
    launchOperation(cadOpType.CUBE);
  }, [launchOperation]);

  const createCylinder = useCallback(() => {
    launchOperation(cadOpType.CYLINDER);
  }, [launchOperation]);

  const createRPrism = useCallback(() => {
    launchOperation(cadOpType.RPRISM);
  }, [launchOperation]);

  const createPPrism = useCallback(() => {
    launchOperation(cadOpType.PPRISM);
  }, [launchOperation]);

  const createPlane = useCallback(() => {
    launchOperation(cadOpType.PLANE);
  }, [launchOperation]);

  const createCone = useCallback(() => {
    launchOperation(cadOpType.CONE);
  }, [launchOperation]);

  const createEllipse = useCallback(() => {
    launchOperation(cadOpType.ELLIPSE);
  }, [launchOperation]);

  const createEllipseArc = useCallback(() => {
    launchOperation(cadOpType.ELLIPSEARC);
  }, [launchOperation]);

  const createArc = useCallback(() => {
    launchOperation(cadOpType.ARC);
  }, [launchOperation]);

  const createChamfer = useCallback(() => {
    launchOperation(cadOpType.CHAMFER);
  }, [launchOperation]);

  const createAlign = useCallback(() => {
    launchOperation(cadOpType.ALIGN);
  }, [launchOperation]);

  const cleanChanges = useCallback(() => {
    dispatch({ type: "CLEAN_CHANGES" });
  }, [dispatch]);

  const calcDistanceMeasure = useCallback(() => {
    launchOperation(cadOpType.DISTANCEMEASURE);
  }, [launchOperation]);

  const move = useCallback(() => {
    launchOperation(cadOpType.MOVE, true);
  }, [launchOperation]);

  const copy = useCallback(() => {
    launchOperation(cadOpType.COPY, true);
  }, [launchOperation]);

  const rotate = useCallback(() => {
    launchOperation(cadOpType.ROTATE, true);
  }, [launchOperation]);

  const trim = useCallback(() => {
    launchOperation(cadOpType.TRIMLINES, true);
  }, [launchOperation]);

  const scale = useCallback(() => {
    launchOperation(cadOpType.SCALE, true);
  }, [launchOperation]);

  const mirror = useCallback(() => {
    launchOperation(cadOpType.MIRROR, true);
  }, [launchOperation]);

  const extend = useCallback(() => {
    launchOperation(cadOpType.EXTEND, true);
  }, [launchOperation]);

  const stretch = useCallback(() => {
    launchOperation(cadOpType.STRETCH, true);
  }, [launchOperation]);

  const createRegion = useCallback(() => {
    launchOperation(cadOpType.REGION);
  }, [launchOperation]);

  const createBlock = useCallback(() => {
    launchOperation(cadOpType.BLOCK, true);
  }, [launchOperation]);

  const createBlockRef = useCallback(() => {
    launchOperation(cadOpType.BLOCKREF);
  }, [launchOperation]);

  const createBeam = useCallback(() => {
    launchOperation(cadOpType.BEAM);
  }, [launchOperation]);

  const createColumn = useCallback(() => {
    launchOperation(cadOpType.COLUMN);
  }, [launchOperation]);

  const createSlab = useCallback(() => {
    launchOperation(cadOpType.SLAB);
  }, [launchOperation]);

  const createSlabHole = useCallback(() => {
    launchOperation(cadOpType.SLABHOLE);
  }, [launchOperation]);

  const createWall = useCallback(() => {
    launchOperation(cadOpType.WALL);
  }, [launchOperation]);

  const createWallHole = useCallback(() => {
    launchOperation(cadOpType.WALLHOLE);
  }, [launchOperation]);

  const createConcentratedLoad = useCallback(() => {
    launchOperation(cadOpType.LOADCONCENTRATED);
  }, [launchOperation]);

  const createLinealLoad = useCallback(() => {
    launchOperation(cadOpType.LOADLINEAL);
  }, [launchOperation]);

  const createSuperficialLoad = useCallback(() => {
    launchOperation(cadOpType.LOADSUPERFICIAL);
  }, [launchOperation]);

  const createFooter = useCallback(() => {
    launchOperation(cadOpType.FOOTER, true);
  }, [launchOperation]);

  const createPileCap = useCallback(() => {
    launchOperation(cadOpType.PILECAP);
  }, [launchOperation]);

  const value = useMemo(
    () => ({
      ...state,
      cleanChanges,
      launchOperation,
      createPoint,
      createLine,
      createPolyline,
      createPolygon,
      createCircle,
      createText,
      createParagraph,
      createCube,
      createCylinder,
      createRPrism,
      createPPrism,
      createPlane,
      createCone,
      createArc,
      createEllipse,
      createEllipseArc,
      createChamfer,
      createAlign,
      createRegion,
      createBlock,
      createBlockRef,
      calcDistanceMeasure,
      move,
      copy,
      rotate,
      trim,
      scale,
      mirror,
      extend,
      stretch,
      createBeam,
      createColumn,
      createSlab,
      createSlabHole,
      createWall,
      createWallHole,
      createConcentratedLoad,
      createLinealLoad,
      createSuperficialLoad,
      createFooter,
      createPileCap
    }),
    [
      state,
      cleanChanges,
      launchOperation,
      createPoint,
      createLine,
      createPolyline,
      createPolygon,
      createCircle,
      createText,
      createParagraph,
      createCube,
      createCylinder,
      createRPrism,
      createPPrism,
      createPlane,
      createCone,
      createArc,
      createEllipse,
      createEllipseArc,
      createChamfer,
      createAlign,
      createRegion,
      createBlock,
      createBlockRef,
      calcDistanceMeasure,
      move,
      copy,
      rotate,
      trim,
      scale,
      mirror,
      extend,
      stretch,
      createBeam,
      createColumn,
      createSlab,
      createSlabHole,
      createWall,
      createWallHole,
      createConcentratedLoad,
      createLinealLoad,
      createSuperficialLoad,
      createFooter,
      createPileCap,
    ]
  );
  return <OperationContext.Provider value={value}> {children} </OperationContext.Provider>;
};

function useOperation() {
  const context = useContext(OperationContext);
  if (Object.keys(context).length === 0) {
    throw new Error("useOperation should be used inside Operation Provider");
  }
  return context;
}

export { OperationProvider, useOperation };
