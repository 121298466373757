import AuthContainer from "shared/components/auth/auth-container";
import SignUpForm from "shared/components/auth/signup-form";

const SignUp = () => {
  return (
    <AuthContainer title="Sign up a new account">
      <SignUpForm />
    </AuthContainer>
  );
};

export default SignUp;
