import { PrimaryButton } from "shared/components/ui/button";
import Section from "shared/components/ui/section";
import styled from "styled-components";
import { useMeasurements } from "../measurements/hooks/use-measurements";
import OptimizerResultsEvents from "../optimizer/optimizer-events";
import OptimizerResults from "../optimizer/optimizer-results";
import useProject from "./hooks/use-project";

const Side = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const ProjectOptimizer = () => {
  const { events, buildingModelUrl } = useMeasurements();
  const { calculateOptimizerResults } = useProject();
  return (
    <>
      <Section title="Events">
        <Side>  
        <OptimizerResultsEvents events={events} />
            <PrimaryButton onClick={calculateOptimizerResults}>
                Calculate
            </PrimaryButton>      
        </Side>
      </Section>
      <Section title="Results">
        <OptimizerResults buildingModelUrl={buildingModelUrl} />
      </Section>
    </>
  );
};

export default ProjectOptimizer;
