import { blockRefParam } from "lib/blocks";
import { GraphicProcessor } from "lib/graphic-processor";
import { IObjData } from "lib/models/objdata";
import { cadOpType } from "lib/operations/factory";
import { EditDataCommand } from "./edit-base";

export class BlockRefEditDataCommand extends EditDataCommand {
    protected opType = cadOpType.EDITBLOCKREF;

    constructor(obj: IObjData, newDefinition: blockRefParam | null, graphicProcessor: GraphicProcessor) {
        super(obj, newDefinition, null, graphicProcessor);
    }
}