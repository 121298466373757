import DxfParser from "dxf-parser";
import { v4 as uuid } from 'uuid';
import { DxfLoaderData } from "lib/input-output/loaders/dxf";
import { StrucStorey } from "modules/struc/models/project";
import { EventBus } from "lib/events/event-bus";
import { LayerManager } from "lib/layers/layer-manager";
import { dataModelPersistence, ICADModelPersistence } from "./loader";

const urlToTextFile = async (url: string) => {
  const fileContent: string = await fetch(url).then(r => r.text());
  const parser = new DxfParser();
  const dxf = parser.parseSync(fileContent);
  return dxf;
};

export async function buildModelDataFromStoreys(storeys: StrucStorey[]): Promise<ICADModelPersistence> {
  EventBus.enableDispatch = false;
  const layerMng = new LayerManager();
  const lyrStoreys = layerMng.addLayer("Storeys");
  const lyrImport = layerMng.addLayer("Imported (dxf)");
  layerMng.setCurrentLayer(lyrStoreys);
  for (let storey of storeys) {
    layerMng.addLayer(storey.floor_name);
  }
  const dxfLoader = new DxfLoaderData(layerMng);
  const objData: dataModelPersistence[] = [];
  for (let storey of storeys) {
    layerMng.setCurrentLayer(lyrImport);
    const lyr = layerMng.addLayer(storey.floor_name + "dxf");
    lyr.setBulkData(true);
    if (storey.dxf_url) {
      const dxfData = await urlToTextFile(storey.dxf_url);
      if (dxfData) {
        const data = dxfLoader.getDxfObjDatas(dxfData, lyr.name, storey.level, true);
        objData.push(...data);
      }
    }
  }
  objData.forEach((d) => { d.id = uuid(); });

  const layerData = layerMng.exportLayerModel();

  EventBus.enableDispatch = true;
  return {
    layerData,
    objData,
    blockData: []
  };
}

export function buildPlaneDataFromStoreys(storey: StrucStorey[]) {
  // const planeMng = new PlaneManager();
  // for (let i = 0; i < storey.length; i++) {
  //   planeMng.addUserPlane({ x: 0, y: 0, z: storey[i].level });
  // }
  // return planeMng.exportToJSON();
  return {
    mainPlane: {
      position: { x: 0, y: 0, z: 0 },
      rotation: { x: 0, y: 0, z: 0 },
    },
    userPlanes: []
  };
}
