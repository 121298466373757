import { linealPrecision } from "lib/general-settings";
import { IPolylineParam } from "lib/math/line";
import { dataInfoProperty } from "lib/properties/properties";
import { BeamCrossSection } from "./beamcs-shapes";

export enum sectionType { RECT, QUAD, CIRC, TUBE, T, I, U, CUSTOM }
export const beamCrossSectionTypeList: string[] = [];
beamCrossSectionTypeList[sectionType.RECT] = "Rectangular";
beamCrossSectionTypeList[sectionType.QUAD] = "Quad shape";
beamCrossSectionTypeList[sectionType.CIRC] = "Circular";
beamCrossSectionTypeList[sectionType.TUBE] = "Tube shape";
beamCrossSectionTypeList[sectionType.T] = "T Section";
beamCrossSectionTypeList[sectionType.I] = "I Section";
beamCrossSectionTypeList[sectionType.U] = "U Section";
beamCrossSectionTypeList[sectionType.CUSTOM] = "Custom";

export interface IBeamCrossSection {
  name: string,
  parameter: beamCrossSection
}
interface paramSection {
  beamSectionType: sectionType,
}
export interface IRectangular extends paramSection {
  beamSectionType: sectionType.RECT,
  height: number,
  width: number,
}
export interface IQuadShape extends paramSection {
  beamSectionType: sectionType.QUAD,
  height: number,
  width: number,
  verticalWebThickness: number,
  horizontalFlangeThickness: number,
}
export interface ICircular extends paramSection {
  beamSectionType: sectionType.CIRC,
  radius: number,
}
export interface ITube extends paramSection {
  beamSectionType: sectionType.TUBE,
  radius: number,
  thickness: number,
}
export interface ISectionT extends paramSection {
  beamSectionType: sectionType.T,
  height: number,
  webThickness: number,
  topFlangeLength: number,
  topFlangeThickness: number,
}
export interface ISectionI extends paramSection {
  beamSectionType: sectionType.I,
  height: number,
  webThickness: number,
  topFlangeLength: number,
  topFlangeThickness: number,
  bottomFlangeLength: number,
  bottomFlangeThickness: number,
}
export interface ISectionU extends paramSection {
  beamSectionType: sectionType.U,
  height: number,
  width: number,
  verticalWebThickness: number,
  horizontalFlangeThickness: number,
}
export interface ICustom extends paramSection {
  beamSectionType: sectionType.CUSTOM,
  polyline: IPolylineParam,
}

export type beamCrossSection = IRectangular | IQuadShape | ICircular | ITube | ISectionT | ISectionI | ISectionU | ICustom;

export function isRectangularSection(section: beamCrossSection): section is IRectangular {
  return section.beamSectionType === sectionType.RECT;
}
export function isQuadSection(section: beamCrossSection): section is IQuadShape {
  return section.beamSectionType === sectionType.QUAD;
}
export function isCircularSection(section: beamCrossSection): section is ICircular {
  return section.beamSectionType === sectionType.CIRC;
}
export function isTubeSection(section: beamCrossSection): section is ITube {
  return section.beamSectionType === sectionType.TUBE;
}
export function isTSection(section: beamCrossSection): section is ISectionT {
  return section.beamSectionType === sectionType.T;
}
export function isISection(section: beamCrossSection): section is ISectionI {
  return section.beamSectionType === sectionType.I;
}
export function isUSection(section: beamCrossSection): section is ISectionU {
  return section.beamSectionType === sectionType.U;
}
export function isCustomSection(section: beamCrossSection): section is ICustom {
  return section.beamSectionType === sectionType.CUSTOM;
}

export const getBeamCrossSectionInfoprop = (prop: BeamCrossSection) => {
  if (prop) {
    const editable = !prop.default;
    const info: dataInfoProperty<typeof prop> = {}
    info.name = { type: "string", publicName: "Name", value: prop.name, editable };
    info.parameter = { publicName: "" };
    info.parameter.beamSectionType = { type: "list", value: prop.parameter.beamSectionType, publicName: "Cross section type", list: beamCrossSectionTypeList, editable: false };

    if (isRectangularSection(prop.parameter)) {
      (info.parameter as dataInfoProperty<IRectangular>).height = { type: "number", value: prop.parameter.height, publicName: "Height", units: "m", precision: linealPrecision, editable };
      (info.parameter as dataInfoProperty<IRectangular>).width = { type: "number", value: prop.parameter.width, publicName: "Width", units: "m", precision: linealPrecision, editable };

    } else if (isQuadSection(prop.parameter)) {
      const param = info.parameter as dataInfoProperty<IQuadShape>
      param.height = { type: "number", value: prop.parameter.height, publicName: "Height", units: "m", precision: linealPrecision, editable };
      param.width = { type: "number", value: prop.parameter.width, publicName: "Width", units: "m", precision: linealPrecision, editable };
      param.verticalWebThickness = { type: "number", value: prop.parameter.verticalWebThickness, publicName: "Vertical web thickness", units: "m", precision: linealPrecision, editable };
      param.horizontalFlangeThickness = { type: "number", value: prop.parameter.horizontalFlangeThickness, publicName: "Horizontal Flange Thickness", units: "m", precision: linealPrecision, editable };

    } else if (isCircularSection(prop.parameter)) {
      (info.parameter as dataInfoProperty<ICircular>).radius = { type: "number", value: prop.parameter.radius, publicName: "Radius", units: "m", precision: linealPrecision, editable };

    } else if (isTubeSection(prop.parameter)) {
      (info.parameter as dataInfoProperty<ITube>).radius = { type: "number", value: prop.parameter.radius, publicName: "Radius", units: "m", precision: linealPrecision, editable };
      (info.parameter as dataInfoProperty<ITube>).thickness = { type: "number", value: prop.parameter.thickness, publicName: "Thickness", units: "m", precision: linealPrecision, editable };

    } else if (isTSection(prop.parameter)) {
      const param = info.parameter as dataInfoProperty<ISectionT>
      param.height = { type: "number", value: prop.parameter.height, publicName: "Height", units: "m", precision: linealPrecision, editable };
      param.webThickness = { type: "number", value: prop.parameter.webThickness, publicName: "Web thickness", units: "m", precision: linealPrecision, editable };
      param.topFlangeLength = { type: "number", value: prop.parameter.topFlangeLength, publicName: "Top flange length", units: "m", precision: linealPrecision, editable };
      param.topFlangeThickness = { type: "number", value: prop.parameter.topFlangeThickness, publicName: "Top flange thickness", units: "m", precision: linealPrecision, editable }

    } else if (isISection(prop.parameter)) {
      const param = info.parameter as dataInfoProperty<ISectionI>
      param.height = { type: "number", value: prop.parameter.height, publicName: "Height", units: "m", precision: linealPrecision, editable };
      param.webThickness = { type: "number", value: prop.parameter.webThickness, publicName: "Web thickness", units: "m", precision: linealPrecision, editable };
      param.topFlangeLength = { type: "number", value: prop.parameter.topFlangeLength, publicName: "Top flange length", units: "m", precision: linealPrecision, editable };
      param.topFlangeThickness = { type: "number", value: prop.parameter.topFlangeThickness, publicName: "Top flange thickness", units: "m", precision: linealPrecision, editable };
      param.bottomFlangeLength = { type: "number", value: prop.parameter.bottomFlangeLength, publicName: "Bottom flange length", units: "m", precision: linealPrecision, editable };
      param.bottomFlangeThickness = { type: "number", value: prop.parameter.bottomFlangeThickness, publicName: "Bottom flange thickness", units: "m", precision: linealPrecision, editable };

    } else if (isUSection(prop.parameter)) {
      const param = info.parameter as dataInfoProperty<ISectionU>
      param.height = { type: "number", value: prop.parameter.height, publicName: "Height", units: "m", precision: linealPrecision, editable };
      param.width = { type: "number", value: prop.parameter.width, publicName: "Web thickness", units: "m", precision: linealPrecision, editable };
      param.verticalWebThickness = { type: "number", value: prop.parameter.verticalWebThickness, publicName: "Vertical web thickness", units: "m", precision: linealPrecision, editable };
      param.horizontalFlangeThickness = { type: "number", value: prop.parameter.horizontalFlangeThickness, publicName: "Horizontal Flange Thickness", units: "m", precision: linealPrecision, editable };
    }
    return info;
  } else {
    return {}
  }
};
export function getDefaultBeamCrossSectionShape(type: sectionType): beamCrossSection {
  switch (type) {
    case sectionType.RECT:
      return {
        beamSectionType: type,
        height: 0.2,
        width: 0.2
      }
    case sectionType.QUAD:
      return {
        beamSectionType: type,
        height: 0.5,
        width: 0.5,
        verticalWebThickness: 0.15,
        horizontalFlangeThickness: 0.15,
      }
    case sectionType.CIRC:
      return {
        beamSectionType: type,
        radius: 0.2
      }
    case sectionType.TUBE:
      return {
        beamSectionType: type,
        radius: 0.5,
        thickness: 0.15,
      }
    case sectionType.T:
      return {
        beamSectionType: type,
        height: 0.5,
        webThickness: 0.15,
        topFlangeLength: 0.5,
        topFlangeThickness: 0.15,
      }
    case sectionType.I:
      return {
        beamSectionType: type,
        height: 0.5,
        webThickness: 0.15,
        topFlangeLength: 0.5,
        topFlangeThickness: 0.15,
        bottomFlangeLength: 0.5,
        bottomFlangeThickness: 0.15,
      }
    case sectionType.U:
      return {
        beamSectionType: type,
        height: 0.5,
        width: 0.5,
        verticalWebThickness: 0.15,
        horizontalFlangeThickness: 0.15,
      }
    case sectionType.CUSTOM:
      return {
        beamSectionType: type,
        polyline: {
          isClosed: true,
          arcs: [],
          points: [
            { x: -0.2, y: -0.2, z: 0 },
            { x: 0, y: 0.2, z: 0 },
            { x: 0.2, y: -0.2, z: 0 },
          ],
        }
      }
  }
}

