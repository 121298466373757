import * as THREE from "three";
import { IBox } from "../math/box";
import { lineCreate } from "./line";

export function getBoundingBox(threeObj: THREE.Object3D): THREE.Box3 {
  const box3Helper = new THREE.Box3();
  return box3Helper.setFromObject(threeObj).clone();
}

export function getBoundingBox2DLine(
  bbox: THREE.Box3 | IBox,
  colorLine: string | number = "#ffff00"
): THREE.Line {
  const buffer = new Float32Array(15);
  buffer[0] = bbox.min.x;
  buffer[1] = bbox.max.y;
  buffer[2] = 0;
  buffer[3] = bbox.max.x;
  buffer[4] = bbox.max.y;
  buffer[5] = 0;
  buffer[6] = bbox.max.x;
  buffer[7] = bbox.min.y;
  buffer[8] = 0;
  buffer[9] = bbox.min.x;
  buffer[10] = bbox.min.y;
  buffer[11] = 0;
  buffer[12] = bbox.min.x;
  buffer[13] = bbox.max.y;
  buffer[14] = 0;
  return lineCreate(buffer, undefined, colorLine);
}

export function getBoundingBoxHelper(threeObj: THREE.Object3D) {
  const box = new THREE.BoxHelper(threeObj, "#ffff00");
  return box;
}

export function getBoundingSphereHelper(threeObj: THREE.Object3D) {
  const box = new THREE.Box3();
  box.expandByObject(threeObj);
  const s = new THREE.Sphere();
  box.getBoundingSphere(s);
  const geometry = new THREE.SphereBufferGeometry(s.radius);
  const material = new THREE.MeshBasicMaterial({ color: 0xffff00, wireframe: true });
  const sphere = new THREE.Mesh(geometry, material);
  sphere.position.copy(s.center);
  sphere.updateMatrix();
  sphere.geometry.computeBoundingBox();
  sphere.geometry.computeBoundingSphere();
  return sphere;
}

