import { PolylineEditDataCommand } from "lib/commands/edition/polyline";
import { PolyLineCommand } from "lib/commands/primitives/polyline";
import { EventBus } from "lib/events/event-bus";
import { editObjAction, EditActionType } from "lib/events/objectdata";
import { userMessageEvents } from "lib/events/user-messages";
import { GraphicProcessor, graphicProcessor000 } from "lib/graphic-processor";
import { IPolylineParam, copyIPolylineParam } from "lib/math/line";
import { BeamCrossSection } from "lib/models-struc/cross-sections-shape/beam-cross-sections/beamcs-shapes";
import { beamCrossSectionCache, currentBeamCrossSectionId } from "lib/models-struc/cross-sections-shape/beam-cross-sections/cache";
import { getBeamCrossSectionInfoprop, getDefaultBeamCrossSectionShape, isCustomSection, sectionType } from "lib/models-struc/cross-sections-shape/beam-cross-sections/types";
import { generatePointsBeamCrossSection } from "lib/models-struc/cross-sections-shape/beam-cross-sections/utils";
import { IObjData } from "lib/models/objdata";
import { LineData } from "lib/models/primitives/line";
import { cadOpType } from "lib/operations/factory";
import { SelectOP } from "lib/operations/select";
import { dataInfoProperty } from "lib/properties/properties";
import { useResources } from "modules/struc/services/hooks/use-resources";
import { useRef, useState, useCallback, useEffect } from "react";

function getGridRows(selectedStyle: BeamCrossSection) {
  if (isCustomSection(selectedStyle.parameter)) {
    const ptos = selectedStyle.parameter.polyline.points;
    return ptos.map((p, i) => ([(i + 1).toString(), p.x.toFixed(3), p.y.toFixed(3)]));
  } else {
    return [];
  }
}

export function useBeamCrossSectionManager(graphicProc: GraphicProcessor) {

  const { updateResource2 } = useResources("beam_cross_section");

  const cacheHandler = beamCrossSectionCache;
  const contourObjData = useRef<IObjData>();
  const holesObjData = useRef<IObjData[]>([]);

  const [BeamCrossSectionLoaded, setBeamCrossSectionLoaded] = useState<BeamCrossSection[]>(() => {
    const list = cacheHandler.getAllLoadedStyles();
    return list.sort((a, b) => a.name.localeCompare(b.name));
  });
  const [canBeSaved, setCanBeSaved] = useState<boolean>(false);
  const selectedStyle = useRef(cacheHandler.loadStylefromCache(currentBeamCrossSectionId)!);
  const [styleProperties, setStyleProperties] = useState<dataInfoProperty<BeamCrossSection>>(getBeamCrossSectionInfoprop(selectedStyle.current));

  const [contourRows, setContourRows] = useState(() => getGridRows(selectedStyle.current));

  const drawTemplate = useCallback((template: BeamCrossSection, graphicProc: GraphicProcessor) => {
    (graphicProc.currentOp as SelectOP).operationLauncherEnabled = isCustomSection(template.parameter);
    EventBus.enableDispatch = false;
    if (contourObjData.current) {
      graphicProc.removeFromLayer(contourObjData.current);
    }
    if (holesObjData.current.length) {
      holesObjData.current.forEach(c => graphicProc.removeFromLayer(c));
    }

    const layerManager = graphicProc.getLayerManager();
    const { id } = layerManager.getRootLayer();
    contourObjData.current = undefined;
    holesObjData.current = [];

    const { contour, holes } = generatePointsBeamCrossSection(template);
    const line1: IPolylineParam = { points: contour, isClosed: true, arcs: [] };
    const cmd = new PolyLineCommand(line1, id, graphicProc)
    cmd.execute();
    contourObjData.current = cmd.createdData;

    if (holes.length) {
      const line2: IPolylineParam = { points: holes, isClosed: true, arcs: [] };
      const cmd0 = new PolyLineCommand(line2, id, graphicProc)
      cmd0.execute();
      holesObjData.current = [cmd0.createdData];
    }

    graphicProc.zoomFitV2();
    EventBus.enableDispatch = true;
  }, [])

  const selectStyle = (id: string) => {
    if (selectedStyle.current.styleId !== id) {
      const newTemplate = cacheHandler.loadStylefromCache(id)!;
      selectedStyle.current = newTemplate;
      // Actualizo estado de sus propiedades a mostrar
      setStyleProperties({ ...getBeamCrossSectionInfoprop(newTemplate) });
      setContourRows(getGridRows(newTemplate));
      // Dibujo el nuevo template
      drawTemplate(newTemplate, graphicProc);
      setCanBeSaved(false);
    }
  }

  // --------------------------------------------------------------------

  const deleteStyle = async () => {
    const itemId = selectedStyle.current.styleId;
    if (beamCrossSectionCache.isSectionInUse(itemId, graphicProcessor000)) {
      userMessageEvents.dispatchWarning(`Section "${selectedStyle.current.name}" is being used`);
      return;
    }
    const deleted = cacheHandler.deleteStyle(itemId);
    if (deleted) {
      selectedStyle.current = BeamCrossSectionLoaded[0].styleId === itemId ? BeamCrossSectionLoaded[1].clone() : BeamCrossSectionLoaded[0].clone();
      setBeamCrossSectionLoaded((prev: BeamCrossSection[]) => prev.filter(b => b.styleId !== itemId));
      setStyleProperties({ ...getBeamCrossSectionInfoprop(selectedStyle.current) });
      setContourRows(getGridRows(selectedStyle.current));
      drawTemplate(selectedStyle.current, graphicProc);
      setCanBeSaved(false);
      await updateResource2();
    }
  };
  const createStyle = async (sectionType: sectionType) => {
    const beamCSSparameters = getDefaultBeamCrossSectionShape(sectionType);
    const beamCSS = new BeamCrossSection({
      name: "New beam cross section shape",
      parameter: beamCSSparameters
    });
    beamCrossSectionCache.saveStyle(beamCSS);
    selectedStyle.current = beamCSS.clone();
    setBeamCrossSectionLoaded((prev: BeamCrossSection[]) => {
      const list = [...prev, beamCSS];
      return list.sort((a, b) => a.name.localeCompare(b.name));
    });
    setStyleProperties({ ...getBeamCrossSectionInfoprop(selectedStyle.current) });
    setContourRows(getGridRows(selectedStyle.current));
    drawTemplate(selectedStyle.current, graphicProc);
    setCanBeSaved(false);
    await updateResource2();
  };
  const saveChanges = async () => {
    const nameList = BeamCrossSectionLoaded.map(s => s.name)
    if (!nameList.includes(selectedStyle.current.name)) {
      const newDef = selectedStyle.current.clone();
      cacheHandler.updateStyle(selectedStyle.current.styleId, newDef);
      setBeamCrossSectionLoaded(() => {
        const list = cacheHandler.getAllLoadedStyles();
        return list.sort((a, b) => a.name.localeCompare(b.name));
      });
      setStyleProperties({ ...getBeamCrossSectionInfoprop(selectedStyle.current) });
      setCanBeSaved(false);
      await updateResource2();
    } else {
      userMessageEvents.dispatchWarning(`Section with name "${selectedStyle.current.name}" already exists.`);
    }
  }

  // --------------------------------------------------------------------

  const editFromCanva = (action: editObjAction) => {
    if (action.type === EditActionType.EDIT_OBJ) {
      const data = action.payload.objsEdited[0] as LineData;
      if (contourObjData.current === data) {
        const newTemplate = cacheHandler.loadStylefromCache(selectedStyle.current.styleId)!;
        if (isCustomSection(newTemplate.parameter)) {
          newTemplate.parameter.polyline = copyIPolylineParam(data.definition);
          selectedStyle.current = newTemplate;
          hasChanged();
          // Actualizo el estado de la parte de UI correspondiente
          setContourRows(getGridRows(newTemplate));
        }
      }
    }
  }
  const editFromPanelProp = (style: BeamCrossSection) => {
    selectedStyle.current.override(style);
    setStyleProperties({ ...getBeamCrossSectionInfoprop(selectedStyle.current) });
    hasChanged();
  }
  const editFromTableCustomContour = (rowIndex: number, columnIndex: number, value: any) => {
    const row = contourRows[rowIndex];
    row[columnIndex] = value;
    const newPto = { x: parseFloat(row[1]), y: parseFloat(row[2]), z: 0 };

    // Simulo un cambio en el objeto gráfico
    const contourObj = contourObjData.current as LineData;
    const def = contourObj.cloneDefinition();
    def.points[rowIndex] = newPto;
    const command = new PolylineEditDataCommand(contourObj, def, null, graphicProc);
    graphicProc.storeAndExecute(command);
  }
  const hasChanged = () => {
    const original = cacheHandler.loadStylefromCache(selectedStyle.current.styleId)!;
    const originalJson = JSON.stringify(original);
    const editedJson = JSON.stringify(selectedStyle.current);
    const hasChanged = originalJson !== editedJson;
    setCanBeSaved(hasChanged);
  }

  useEffect(() => {
    const dtMdlMngr = graphicProc.getDataModelManager();
    dtMdlMngr.subscribe(editFromCanva);
    drawTemplate(selectedStyle.current, graphicProc);
    const plneMngr = graphicProc.getPlaneManager();
    plneMngr.mainPlane.gridHelperSettings.size = 5;
    plneMngr.mainPlane.gridHelperSettings.division = 50;
    plneMngr.mainPlane.updatePlaneHelpers();
    plneMngr.addMainPlaneHelperToScene();
    return () => {
      const dtMdlMngr = graphicProc.getDataModelManager();
      dtMdlMngr.unsubscribe(editFromCanva);
    };
  }, []);

  // --------------------------------------------------------------------

  const launchInsertVertex = () => {
    const objData = contourObjData.current as LineData;
    const l = objData.definition.points.length
    const indexToEdit = l - 1;
    const iniPto = objData.definition.points[l - 1];
    graphicProc.launchOP<any>(cadOpType.ADDPOLYLINEVERTEX, [objData, indexToEdit, iniPto]);
  }
  const launchDeleteVertex = () => {
    // Simulo un cambio en el objeto gráfico
    const contourObj = contourObjData.current as LineData;
    if (contourObj.definition.points.length > 3) {
      const def = contourObj.cloneDefinition();
      def.points.pop();
      const command = new PolylineEditDataCommand(contourObj, def, null, graphicProc);
      graphicProc.storeAndExecute(command);
    }
  }

  return {
    canBeSaved,
    saveChanges,
    selectStyle,
    deleteStyle,
    createStyle,
    editStyle: editFromPanelProp,
    selectedStyle: selectedStyle.current,
    BeamCrossSectionLoaded,
    styleProperties,
    contourRows,
    updateCustomContour: editFromTableCustomContour,
    launchInsertVertex,
    launchDeleteVertex,
  };
}
