import { CircleEditDataCommand } from "lib/commands/edition/circle";

import { setPosBuffer } from "lib/geometries";
import { circleBuffer, circleCreate, circleParam } from "lib/geometries/circle";
import { lineAddVertex, lineAuxCreate, lineMoveVertex } from "lib/geometries/line";
import { vectorDist3D } from "lib/math/distance";
import { copyIPoint } from "lib/math/point";
import { IPoint } from "lib/math/types";
import { CircleData } from "lib/models/primitives/circle";
import { SimpleEdition } from "../edition";
import { cadOpType } from "../factory";
import { settingsOpModes } from "../step-operations";

export class CircleEditOP extends SimpleEdition {

  public opType = cadOpType.EDITCIRCLE;

  private objData: CircleData;
  private radius: number = 1;

  constructor(objData: CircleData, iniPoint: IPoint) {
    super(iniPoint);
    this.objData = objData;
    const objDef = this.objData.definition;
    this.auxPoly = circleCreate(objDef.center, objDef.radius, objDef.azimutO);
    this.auxLine = lineAuxCreate();
    const { x, y, z } = copyIPoint(objDef.center);
    lineAddVertex(this.auxLine, x, y, z);
    lineAddVertex(this.auxLine, x, y, z);
  }
  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([{
      infoMsg: "Insert radius: ",
      stepMode: settingsOpModes.ONEBOX,
      currValue: () => (this.radius).toFixed(3),
      cmdLineListener: (cmd: string) => {
        const r = parseFloat(cmd);
        if (r) {
          this.radius = r;
          this.endOperation();
        }
      },
    }]);
  }
  public setLastPoint(): void {
    this.radius = vectorDist3D(this.lastPoint, this.objData.definition.center);
    this.endOperation();
  }
  public moveLastPoint(pto: IPoint) {
    lineMoveVertex(this.auxLine, pto.x, pto.y, pto.z);
    this.radius = vectorDist3D(pto, this.objData.definition.center);
    this.calculateCircle();
  }
  private calculateCircle(): void {
    const objDef = this.objData.definition;
    let coords = circleBuffer(objDef.center, this.radius, objDef.azimutO, objDef.plane);
    if (!coords) coords = new Float32Array(0);
    setPosBuffer(this.auxPoly, coords);
  }
  public save() {
    if (this.graphicProcessor) {
      const objDef = this.objData.definition;
      const circle: circleParam = {
        center: objDef.center,
        radius: this.radius,
        azimutO: objDef.azimutO,
        plane: objDef.plane,
      };
      const command = new CircleEditDataCommand(this.objData, circle, null, this.graphicProcessor);
      this.graphicProcessor.storeAndExecute(command);
    }
  }
}