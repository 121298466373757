import { downloadFileFromS3, downloadFileFromS3AndParse } from "lib/apis/utils";
import { gunzipSync } from "zlib";

import { PlanesSettings } from "lib/coordinates/plane-manager";
import { CameraSettings } from "lib/graphic-processor";
import { ICADModelPersistence } from "lib/input-output/database/loader";
import { AnalysisProperties } from "lib/models-struc/analysis/analysis";
import { Hypothesis } from "lib/models-struc/hypothesis/hypothesis";
import { MeshProperties } from "lib/models-struc/mesh/mesh";
import { StrucSite } from "modules/struc/models/project";
import { IBeamCrossSection } from "lib/models-struc/cross-sections-shape/beam-cross-sections/types";
import { IDimBuildProperties } from "lib/dimension/style";
import { ILineStyle } from "lib/line-styles/line";
import { IShellCrossSection } from "lib/models-struc/cross-sections-shape/shell-cross-sections/types";
import { ITextOptions } from "lib/text/styles";
import { IPileCapTemplate } from "lib/models-struc/pile-cap-templates/pile-cap-template";

export const downloadFiles = {

  analysis: async (url: string) => {
    try {
      const data = await downloadFileFromS3AndParse<AnalysisProperties>(url);
      return data;
    } catch (error) {
      console.error("[DOWNLOAD_FILE] analysis" + error);
      return null;
    }
  },
  cameraConfig: async (url: string) => {
    try {
      const data = await downloadFileFromS3AndParse<CameraSettings>(url);
      return data;
    } catch (error) {
      console.error("[DOWNLOAD_FILE] cameraConfig" + error);
      return null;
    }
  },
  content: async (url: string) => {
    try {
      const data = await downloadFileFromS3AndParse<ICADModelPersistence>(url);
      return data;
    } catch (error) {
      console.error("[DOWNLOAD_FILE] content" + error);
      return null;
    }
  },
  hypothesis: async (url: string) => {
    try {
      const data = await downloadFileFromS3AndParse<Hypothesis[]>(url);
      return data;
    } catch (error) {
      console.error("[DOWNLOAD_FILE] hypothesis" + error);
      return null;
    }
  },
  mesh: async (url: string) => {
    try {
      const data = await downloadFileFromS3AndParse<MeshProperties>(url);
      return data;
    } catch (error) {
      console.error("[DOWNLOAD_FILE] mesh" + error);
      return null;
    }
  },
  planes: async (url: string) => {
    try {
      const data = await downloadFileFromS3AndParse<PlanesSettings>(url);
      return data;
    } catch (error) {
      console.error("[DOWNLOAD_FILE] planes" + error);
      return null;
    }
  },
  sites: async (url: string) => {
    try {
      const data = await downloadFileFromS3AndParse<StrucSite[]>(url);
      return data;
    } catch (error) {
      console.error("[DOWNLOAD_FILE] sites" + error);
      return null;
    }
  },
  snapshot: async (url: string) => {
    try {
      const dataBuffer = await downloadFileFromS3(url);
      const data = await gunzipSync(Buffer.from(dataBuffer)).toString();
      return data;
    } catch (error) {
      console.error("[DOWNLOAD_FILE] snapshot" + error);
      return null;
    }
  },

  beamCrossSection: async (url: string) => {
    try {
      const data = await downloadFileFromS3AndParse<IBeamCrossSection>(url);
      return data;
    } catch (error) {
      console.error("[DOWNLOAD_FILE] beamCrossSection" + error);
      return null;
    }
  },
  dimension: async (url: string) => {
    try {
      const data = await downloadFileFromS3AndParse<IDimBuildProperties>(url);
      return data;
    } catch (error) {
      console.error("[DOWNLOAD_FILE] dimension" + error);
      return null;
    }
  },
  lineStyles: async (url: string) => {
    try {
      const data = await downloadFileFromS3AndParse<ILineStyle>(url);
      return data;
    } catch (error) {
      console.error("[DOWNLOAD_FILE] lineStyles" + error);
      return null;
    }
  },
  shellCrossSections: async (url: string) => {
    try {
      const data = await downloadFileFromS3AndParse<IShellCrossSection>(url);
      return data;
    } catch (error) {
      console.error("[DOWNLOAD_FILE] shellCrossSections" + error);
      return null;
    }
  },
  textStyles: async (url: string) => {
    try {
      const data = await downloadFileFromS3AndParse<ITextOptions>(url);
      return data;
    } catch (error) {
      console.error("[DOWNLOAD_FILE] textStyles" + error);
      return null;
    }
  },
  pileCapTemplates: async (url: string) => {
    try {
      const data = await downloadFileFromS3AndParse<IPileCapTemplate>(url);
      return data;
    } catch (error) {
      console.error("[DOWNLOAD_FILE] pileCapTemplates" + error);
      return null;
    }
  },
}