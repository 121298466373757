import { useCallback, useState } from "react";
import { GraphicProcessor } from "lib/graphic-processor";

type ViewportMode =
  | "perspective"
  | "plan"
  | "elevation"
  | "profile"
  | "multiple";

export function useViewportMode(graphicProcessor: GraphicProcessor) {

  const [viewportMode, setViewportMode] = useState<ViewportMode>("perspective");
  const setPerspectiveMode = useCallback(() => {
    graphicProcessor.activateOrthograficCameraView(false);
    setViewportMode("perspective");
    graphicProcessor.disableMultiViewportMode();
  }, [graphicProcessor]);
  
  const setElevationMode = useCallback(() => {
    graphicProcessor.activateOrthograficCameraView(true, [0, -1, 0]);
    setViewportMode("elevation");
    graphicProcessor.disableMultiViewportMode();
  }, [graphicProcessor]);
  
  const setPlanMode = useCallback(() => {
    graphicProcessor.activateOrthograficCameraView(true, [0, 0, 1]);
    setViewportMode("plan");
    graphicProcessor.disableMultiViewportMode();
  }, [graphicProcessor]);
  
  const setProfileMode = useCallback(() => {
    graphicProcessor.activateOrthograficCameraView(true, [-1, 0, 0]);
    setViewportMode("profile");
    graphicProcessor.disableMultiViewportMode();
  }, [graphicProcessor]);
  
  const setMultiViewportMode = useCallback(() => {
    setViewportMode("multiple");
    graphicProcessor.enableMultiViewportMode();
  }, [graphicProcessor]);

  return {
    viewportMode,
    setPerspectiveMode,
    setElevationMode,
    setPlanMode,
    setProfileMode,
    setMultiViewportMode,
  };
}
