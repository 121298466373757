import { useForm } from "react-hook-form";
import { useCallback } from "react";
import { useAuth } from "../context";
import { useParams } from "react-router-dom";

interface FormData {
  code: string;
}
interface Params {
  username: string;
}
export default function useConfirmAccount() {
  const { username } = useParams<Params>();
  const { confirmAccount, loading, error } = useAuth();
  const { handleSubmit, register } = useForm<FormData>({
    shouldFocusError: true,
  });

  const onValid = useCallback(
    ({ code }: FormData) => {
      confirmAccount(username, code);
    },
    [confirmAccount, username]
  );
  return {
    handleSubmit: handleSubmit(onValid),
    ref: register,
    loading,
    error,
  };
}
