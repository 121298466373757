import { mecDirectionMathDirection } from "./angles";
import { rotateBuffer } from "./rotate";
import { IPoint } from "./types";

/**
 * Calcula el punto en una elipse a partir de un centro, semieje mayor a, semieje menor b y un ángulo.
 *
 * @export
 * @param {IPoint} center - centro de la elipse
 * @param {number} a - semieje mayor
 * @param {number} b - semieje menor
 * @param {number} t - Parámetro en radianes. Origen ESTE. sentido antihorario
 * @param [number] azimutO - Angulo de inclinación de la elipse respecto al eje X
 * @returns {IPoint} - El punto
 * @memberof MEC.MATH
 */
export function getPointOnEllipse(center: IPoint, a: number, b: number, t: number, azimutO?: number): IPoint {
    const x: number = a * Math.cos(t);
    const y: number = b * Math.sin(t);
    if (azimutO !== undefined) {
        const angleStart = mecDirectionMathDirection(azimutO);
        return {
            x: x * Math.cos(angleStart) - y * Math.sin(angleStart) + center.x,
            y: x * Math.sin(angleStart) + y * Math.cos(angleStart) + center.y,
            z: center.z,
        };
    }
    return { x: x + center.x, y: y + center.y, z: center.z };
}

export function ellipseBuffer(center: IPoint, aAxis: number, bAxis: number, azimutO: number, plane?: IPoint): Float32Array {
    if (plane === undefined) plane = { x: 0, y: 0, z: 0 };

    const sides: number = 64;
    const coords: Float32Array = new Float32Array((sides + 1) * 3);
    for (let i: number = 0; i < sides; i++) {
        const position: number = i * 3;
        const currAng: number = 2 * Math.PI * i / sides;
        const point: IPoint = getPointOnEllipse(center, aAxis, bAxis, currAng, azimutO);
        coords[position] = point.x;
        coords[position + 1] = point.y;
        coords[position + 2] = point.z;
    }
    coords[sides * 3] = coords[0];
    coords[sides * 3 + 1] = coords[1];
    coords[sides * 3 + 2] = coords[2];

    if ((plane.x !== 0) || (plane.y !== 0) || (plane.z !== 0)) {
        rotateBuffer(coords, plane.x, plane.y, plane.z, center);
    }
    return coords;
}

export function getEllipseFlattening(a: number, b: number) {
    return (a - b) / a;
}
export function getEllipseMinorAxis(a: number, f: number) {
    return a - f * a;
}