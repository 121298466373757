import { storageService } from "shared/services/storage";
import * as THREE from "three";
import { DXFLoader } from "three-dxf-loader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { loadTextFont, fontType } from "lib/text/font-loader";
const dxf = require("dxf");

export interface dxfLayer {
  colorNumber: Number;
  flags: Number;
  lineTypeName: String;
  lineWeigthEnum: String;
  name: String;
  type: String;
};
export interface Dimension {
  min: {
    x: null | number;
    y: null | number;
    z: null | number;
  };
  max: {
    x: null | number;
    y: null | number;
    z: null | number;
  };
}
const getDimension = (entities: any[]) => {
  const dims: Dimension = {
    min: { x: null, y: null, z: null },
    max: { x: null, y: null, z: null },
  };
  entities.forEach((object) => {
    const bbox = new THREE.Box3().setFromObject(object);
    if (bbox.min.x && (dims.min.x === null || dims.min.x > bbox.min.x))
      dims.min.x = bbox.min.x;
    if (bbox.min.y && (dims.min.y === null || dims.min.y > bbox.min.y))
      dims.min.y = bbox.min.y;
    if (bbox.min.z && (dims.min.z === null || dims.min.z > bbox.min.z))
      dims.min.z = bbox.min.z;
    if (bbox.max.x && (dims.max.x === null || dims.max.x < bbox.max.x))
      dims.max.x = bbox.max.x;
    if (bbox.max.y && (dims.max.y === null || dims.max.y < bbox.max.y))
      dims.max.y = bbox.max.y;
    if (bbox.max.z && (dims.max.z === null || dims.max.z < bbox.max.z))
      dims.max.z = bbox.max.z;
  });
  return dims;
};

const isLocal = (url: string) => url.includes("blob:");

export const loadDXF = async (dxf_url: string, canvas: HTMLCanvasElement) => {
  const url = isLocal(dxf_url) ? dxf_url : await storageService.get(dxf_url);
  const loader = new DXFLoader();
  const scene = new THREE.Scene();
  const onLoad = (data: any) => {
    if (data && data.entities) {
      data.entities.forEach((ent: any) => scene.add(ent));
      const sizes = {
        width: 800,
        height: 600,
      };
      const dims = getDimension(data.entities);
      const aspectRatio = sizes.width / sizes.height;
      const upperRightCorner = {
        x: dims.max.x as number,
        y: dims.max.y as number,
      };
      const lowerLeftCorner = {
        x: dims.min.x as number,
        y: dims.min.y as number,
      };
      let vp_width = upperRightCorner.x - lowerLeftCorner.x;
      let vp_height = upperRightCorner.y - lowerLeftCorner.y;
      const center = {
        x: vp_width / 2 + lowerLeftCorner.x,
        y: vp_height / 2 + lowerLeftCorner.y,
      };
      const extentsAspectRatio = Math.abs(vp_width / vp_height);
      vp_width =
        aspectRatio > extentsAspectRatio
          ? vp_height * aspectRatio
          : vp_width / aspectRatio;
      const viewPort = {
        bottom: -vp_height / 2,
        left: -vp_width / 2,
        top: vp_height / 2,
        right: vp_width / 2,
        center: {
          x: center.x,
          y: center.y,
        },
      };
      const camera = new THREE.OrthographicCamera(
        viewPort.left,
        viewPort.right,
        viewPort.top,
        viewPort.bottom,
        1,
        19
      );
      camera.position.z = 10;
      camera.position.x = viewPort.center.x;
      camera.position.y = viewPort.center.y;
      scene.background = new THREE.Color(0xfffffff);
      scene.add(camera);
      const controls = new OrbitControls(camera, canvas);
      controls.target.x = camera.position.x;
      controls.target.y = camera.position.y;
      controls.target.z = 0;
      controls.enableRotate = false;
      controls.enablePan = true;
      controls.enableZoom = true;
      const renderer = new THREE.WebGLRenderer({ canvas });
      renderer.setClearColor(0xfffffff, 1);
      renderer.setSize(sizes.width, sizes.height);
      const render = () => {
        renderer.render(scene, camera);
      };
      controls.addEventListener("change", render);
      render();
      controls.update();
    }
  };
  const onError = (error: any) => {
    console.log(error);
  };
  const onProgress = (xhr: any) => {
    console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
  };
  const font = loadTextFont(fontType.LUCIDA);
  loader.setFont(font);
  loader.load(url, onLoad, onProgress, onError);
};

const PERIMETER_NOT_FOUND_ERROR_MESSAGE= 'Perimeter layer not found';

const layerNames = ['perimeter' , 'openings' , 'columns' , 'wall' , 'stairs' , 'slope' , 'beams', 'dl' , 'll'];

const concreteTypes = ['HA21', 'HA25', 'HA30', 'HA35', 'HA40', 'HA45', 'HA50', 'HA55', 'HA60', 'HA65', 'HA70', '2500psi', '3000psi', '4000psi', '5000psi', '6000psi', '7000psi', '8000psi', '9000psi'];

const slabTypes = ['tfw' , 'tcw' , 'ts' , 'tiw' , 'tfs'];

const interAxisTypes = [82, 86 , 78.5];

const layerParameters = ['t', 'v','d','fc','i','r','c','n'];

const loadTypes = ['r', 'of', 's', 'p', 'ar', 'nar', 'sa', 'sb', 'o'];

const checkOpeningLayer = (layer:string[]) => layer.length > 1;

const isValidParameter = (parameter: string) => {
  const indexParam = layerParameters.findIndex(layerParam => parameter.includes(layerParam))
  const parameterType = layerParameters[indexParam];
  const parameterValue =  parameter.replace(parameterType,'');
  switch (parameterType) {
    case 't':
      if (loadTypes.includes(parameterValue)) return true;
      return false;
    case 'v':
      if (!isNaN(Number(parameterValue))) return true;
        return false;
    case 'd':
      if (!isNaN(Number(parameterValue))) return true;
      return false;
    case 'fc':
      if (concreteTypes.includes(parameterValue)) return true;
      return false;
    case 'i':
      if (interAxisTypes.includes(Number(parameterValue))) return true;
      return false;
    case 'r':
      if (!isNaN(Number(parameterValue))) return true;
      return false;
    case 'c':
      if (!isNaN(Number(parameterValue))) return true;
      return false;
    case 'n':
      if (Number.isInteger(Number(parameterValue))) return true;
      return false;
    default:
      return false;
  }
};

const isAllowedParameter = (parameter: string, allowedParameters: string[]) => {
  for (let i = 0; i < allowedParameters.length; i++) {
    if (parameter.includes(allowedParameters[i])) return true;
  }
  return false;
}

const hasDuplicateParameters =  (parameters: string[]) => {
  const uniqueParameters: string[] = [];
  for (let i = 0; i < parameters.length; i++) {
    const indexParam = layerParameters.findIndex(layerParam => parameters[i].includes(layerParam))
    const parameterType = layerParameters[indexParam];
    if (uniqueParameters.includes(parameterType)) {
        return true;
    }
    uniqueParameters.push(parameterType);
  }
  return false;
}

const checkLayerParameter = (layer:string[], allowedParameters: string[]) => {
  const layerParameters = [...layer];
  const layerName = layerParameters.shift();
  
  if (hasDuplicateParameters(layerParameters)) return true;

  for (let i = 0; i < layerParameters.length; i++) {
    if (!isAllowedParameter(layerParameters[i], allowedParameters)) return true;
    if (!isValidParameter(layerParameters[i])) return true;
  }

  return false;
}

const checkPerimeterLayer = (layer:string[]) => {
  const layerParameters = [...layer];
  const layerName = layerParameters.shift();
  if (!slabTypes.includes(layerParameters[0])) return true;
  let isFlatSlab = false;
  for (let i = 0; i < layerParameters.length; i++) {
     if (layerParameters[i].includes('tfs')) {
      isFlatSlab = true;
      return;
     } 
  }
  layerParameters.shift();
  const paramsAllowed = isFlatSlab ? ['d', 'fc'] : [ 'd', 'fc', 'i', 'r', 'c', 'n'];
  return checkLayerParameter(layerParameters, paramsAllowed);

}

const checkLayer = (layer: string[]) => {

  const layerErrors = [];
  switch (layer[0]) {
    case 'perimeter':
      if (checkPerimeterLayer(layer)) layerErrors.push(`Error in ${layer[0]} layer: ${layer.join('_')}`);
      break;
    case 'openings':
      if (checkOpeningLayer(layer)) layerErrors.push(`Error in ${layer[0]} layer: ${layer.join('_')}`);
      break;
    case 'columns':
      if (checkLayerParameter(layer, ['fc'])) layerErrors.push(`Error in ${layer[0]} layer: ${layer.join('_')}`);
      break;
    case 'wall':
     if (checkLayerParameter(layer, ['fc'])) layerErrors.push(`Error in ${layer[0]} layer: ${layer.join('_')}`);
      break;
    case 'stairs':
      if (checkLayerParameter(layer, ['d'])) layerErrors.push(`Error in ${layer[0]} layer: ${layer.join('_')}`);
      break;
    case 'slope':
      if (checkLayerParameter(layer, ['d'])) layerErrors.push(`Error in ${layer[0]} layer: ${layer.join('_')}`);
      break;
    case 'beams':
      if (checkLayerParameter(layer, ['d','fc'])) layerErrors.push(`Error in ${layer[0]} layer: ${layer.join('_')}`);
      break;
    case 'dl':
      if (checkLayerParameter(layer, ['v'])) layerErrors.push(`Error in ${layer[0]} layer: ${layer.join('_')}`);
      break;
    case 'll':
      if (checkLayerParameter(layer, ['t','v'])) layerErrors.push(`Error in ${layer[0]} layer: ${layer.join('_')}`);
      break;
    default:
      break;
  }
  return layerErrors;

}

const checkLayers = (layers: dxfLayer[] ) :string[] => {
  const layersErrors: string[] = [];
  const layerNames = Object.keys(layers);
  const layersParameters = layerNames.map(layer => layer.split('_'));

  const perimeterLayerExist = layerNames.find(layer => layer.startsWith('perimeter'));
  if ( !perimeterLayerExist ) layersErrors.push(PERIMETER_NOT_FOUND_ERROR_MESSAGE);

  for (let i = 0; i < layersParameters.length; i++) {
    const error = checkLayer(layersParameters[i]);
    layersErrors.push(...error)
  }
  
  return layersErrors;
}

export const fileToData = (file: File ) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsText(file)
  })
}

export const checkDXFFile = async (file: File) => {
  const data: string  = await fileToData(file) as string;
  const layers :dxfLayer[] = await dxf.parseString(data).tables.layers as dxfLayer[];
  const layersErrors: string[] = checkLayers(layers) as string[];
  return layersErrors;
};