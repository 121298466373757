import { IColor, IPoint } from "lib/math/types";
import { TextData, textParam } from "lib/models/text";
import { equalsColor } from "lib/styles/colors";
import * as THREE from "three";
import { loadTextFont } from "./font-loader";
import { TextOptsBuilder, sdfDoubleSidedType, textMultiPosTypeV, textMultiPosTypeH } from "./styles";
import { getDefaultTextStyle } from "./cache";

// Overwrite THREE function "generateShapes", adding letterSpacing and lineSpacing

function generateShapes(font: THREE.Font, text: string, size: number, letterSpacing = 0, lineSpacing = 0) {
  if (size === undefined) size = 100;
  const shapes: THREE.Shape[] = [];
  const paths = createPaths(text, size, font.data, letterSpacing, lineSpacing);
  for (let p = 0, pl = paths.length; p < pl; p++) {
    Array.prototype.push.apply(shapes, paths[p].toShapes(false));
  }
  return shapes;
}
function createPaths(text: string, size: number, data: any, letterSpacing: number, lineSpacing: number) {
  const chars = Array.from ? Array.from(String(text)) : String(text).split(""); // see #13988
  const scale = size / data.resolution;
  let line_height = (data.boundingBox.yMax - data.boundingBox.yMin + data.underlineThickness) * scale;
  const paths = [];
  let offsetX = 0, offsetY = 0;
  for (const char of chars) {
    if (char === "\n") {
      offsetX = 0;
      offsetY -= line_height + lineSpacing;
    } else {
      const ret = createPath(char, scale, offsetX, offsetY, data);
      if (ret) {
        offsetX += ret.offsetX + letterSpacing;
        paths.push(ret.path);
      }
    }
  }
  return paths;
}
function createPath(char: string, scale: number, offsetX: number, offsetY: number, data: any): { offsetX: number, path: THREE.ShapePath } | undefined {
  const glyph = data.glyphs[char] || data.glyphs["?"];
  if (!glyph) {
    console.error('THREE.Font: character "' + char + '" does not exists in font family ' + data.familyName + ".");
    return;
  }
  const path = new THREE.ShapePath();
  let x, y, cpx, cpy, cpx1, cpy1, cpx2, cpy2;
  if (glyph.o) {
    const outline = glyph._cachedOutline || (glyph._cachedOutline = glyph.o.split(" "));
    for (let i = 0, l = outline.length; i < l;) {
      const action = outline[i++];
      switch (action) {
        case "m": // moveTo
          x = outline[i++] * scale + offsetX;
          y = outline[i++] * scale + offsetY;
          path.moveTo(x, y);
          break;

        case "l": // lineTo
          x = outline[i++] * scale + offsetX;
          y = outline[i++] * scale + offsetY;
          path.lineTo(x, y);
          break;

        case "q": // quadraticCurveTo
          cpx = outline[i++] * scale + offsetX;
          cpy = outline[i++] * scale + offsetY;
          cpx1 = outline[i++] * scale + offsetX;
          cpy1 = outline[i++] * scale + offsetY;
          path.quadraticCurveTo(cpx1, cpy1, cpx, cpy);
          break;

        case "b": // bezierCurveTo
          cpx = outline[i++] * scale + offsetX;
          cpy = outline[i++] * scale + offsetY;
          cpx1 = outline[i++] * scale + offsetX;
          cpy1 = outline[i++] * scale + offsetY;
          cpx2 = outline[i++] * scale + offsetX;
          cpy2 = outline[i++] * scale + offsetY;
          path.bezierCurveTo(cpx1, cpy1, cpx2, cpy2, cpx, cpy);
          break;
      }
    }
  }
  return { offsetX: glyph.ha * scale, path: path };
}

// -----------------------------------------------------------------------------------------------------

export function createText(opts: Partial<textParam>, styleOpts?: TextOptsBuilder): THREE.Mesh {
  if (styleOpts === undefined) styleOpts = getDefaultTextStyle();

  const mssg = opts.text !== undefined ? opts.text : "";
  const position = opts.position !== undefined ? opts.position : { x: 0, y: 0, z: 0 };
  const angle = opts.angleO !== undefined ? opts.angleO : 0;
  const plane = opts.plane !== undefined ? opts.plane : { x: 0, y: 0, z: 0 };
  const scale = opts.scale !== undefined ? opts.scale : 1;

  const font = loadTextFont(styleOpts.font) as THREE.Font;
  const shapes = generateShapes(font, mssg, styleOpts.size, styleOpts.letterSpacing, styleOpts.lineHeight) as THREE.Shape[];
  const geometry = new THREE.ShapeBufferGeometry(shapes);
  geometry.computeBoundingBox();
  const bbox = geometry.boundingBox as THREE.Box3;
  const height = bbox.max.y - bbox.min.y;
  const textWidths = getTextWidths(mssg, styleOpts.size, font);
  const textHeights = getTextHeights(font, height);
  adjustBasePoint(geometry, textWidths, textHeights, styleOpts.basePointV, styleOpts.basePointH);

  const material = getMaterialTextFromCache(styleOpts);
  const textObj = new THREE.Mesh(geometry, material);

  manageDoubleSided(textObj, styleOpts, geometry, textWidths[0], height);

  changeTextProperties(textObj, position, plane, angle, scale);

  return textObj;
}
export function modifyText(textObj: THREE.Mesh, text: string, styleOpts?: TextOptsBuilder) {
  if (styleOpts === undefined) styleOpts = getDefaultTextStyle();

  const font = loadTextFont(styleOpts.font) as THREE.Font;
  const shapes = generateShapes(font, text, styleOpts.size, styleOpts.letterSpacing, styleOpts.lineHeight) as THREE.Shape[];
  const newGeometry = new THREE.ShapeBufferGeometry(shapes);
  newGeometry.computeBoundingBox();
  const bbox = newGeometry.boundingBox?.clone() as THREE.Box3;
  const height = bbox.max.y - bbox.min.y;
  const textWidths = getTextWidths(text, styleOpts.size, font);
  const textHeights = getTextHeights(font, height);
  adjustBasePoint(newGeometry, textWidths, textHeights, styleOpts.basePointV, styleOpts.basePointH);

  // Por si las moscas.
  textObj.geometry.dispose();
  textObj.geometry = newGeometry;

  const mat = getMaterialTextFromCache(styleOpts);
  textObj.material = mat;

  manageDoubleSided(textObj, styleOpts, newGeometry, textWidths[0], height);
}
function manageDoubleSided(textObj: THREE.Mesh, styleOpts: TextOptsBuilder, newGeometry: THREE.ShapeBufferGeometry, textWidth: number, textHeight: number) {
  if (styleOpts.doubleSided !== sdfDoubleSidedType.NONE && styleOpts.doubleSided !== sdfDoubleSidedType.FRONT) {
    // Share geometry and material, optimizing resources and thus modifications are reflected on both sides
    let bSide = textObj.children[0] as THREE.Mesh;
    if (bSide) {
      bSide.geometry = newGeometry;
    } else {
      bSide = new THREE.Mesh(newGeometry, textObj.material);
      textObj.add(bSide);
    }

    // Fix x position depending of text alignment
    let translateX = 0;
    if (styleOpts.basePointH === textMultiPosTypeH.LEFT) {
      translateX = textWidth;
    } else if (styleOpts.basePointH === textMultiPosTypeH.MIDDLE) {
      translateX = 0;
    } else if (styleOpts.basePointH === textMultiPosTypeH.RIGHT) {
      translateX = (-1 * textWidth);
    }

    if (styleOpts.doubleSided === sdfDoubleSidedType.VER) {
      bSide.position.x = 0;
      bSide.position.y = textHeight;
      bSide.rotation.y = 0;
      bSide.rotation.x = Math.PI;
    } else if (styleOpts.doubleSided === sdfDoubleSidedType.HOR) {
      bSide.position.x = translateX;
      bSide.position.y = 0;
      bSide.rotation.y = Math.PI;
      bSide.rotation.x = 0;
    }
  } else {
    textObj.clear();
  }
}
export function changeTextStyle(textData: TextData, newStyleOpts: TextOptsBuilder) {
  textData.definition.styleId = newStyleOpts.styleId;

  const textObj = textData.graphicObj;
  const text = textData.definition.text;
  const position = textData.definition.position;
  const angle = textData.definition.angleO;
  const plane = textData.definition.plane;
  const scale = textData.definition.scale;

  const font = loadTextFont(newStyleOpts.font) as THREE.Font;
  const shapes = generateShapes(font, text, newStyleOpts.size, newStyleOpts.letterSpacing, newStyleOpts.lineHeight) as THREE.Shape[];
  const newGeometry = new THREE.ShapeBufferGeometry(shapes);
  newGeometry.computeBoundingBox();
  const bbox = newGeometry.boundingBox as THREE.Box3;
  const height = bbox.max.y - bbox.min.y;
  const TextWidths = getTextWidths(text, newStyleOpts.size, font);
  const TextHeights = getTextHeights(font, height);
  adjustBasePoint(newGeometry, TextWidths, TextHeights, newStyleOpts.basePointV, newStyleOpts.basePointH);

  textObj.geometry = newGeometry;

  const material = getMaterialTextFromCache(newStyleOpts);
  textObj.material = material;

  if (newStyleOpts.doubleSided !== sdfDoubleSidedType.NONE && newStyleOpts.doubleSided !== sdfDoubleSidedType.FRONT) {
    // Compartimos geometría y material, optimizando recursos y así las modificaciones se reflejan en ambas caras
    let bSide;
    if (textObj.children.length === 0) {
      bSide = new THREE.Mesh(newGeometry, material);
      textObj.add(bSide);
    } else {
      bSide = textObj.children[0];
    }
    if (newStyleOpts.doubleSided === sdfDoubleSidedType.VER) {
      bSide.rotation.y = 0;
      bSide.rotation.x = Math.PI;
    } else if (newStyleOpts.doubleSided === sdfDoubleSidedType.HOR) {
      bSide.rotation.y = Math.PI;
      bSide.rotation.x = 0;
    }
  } else {
    if (textObj.children.length > 0) {
      textObj.remove(textObj.children[0]);
    }
  }
  changeTextProperties(textObj, position, plane, angle, scale);
}
export function changeTextProperties(textObj: THREE.Mesh, position: IPoint, rotation: IPoint, angle: number, scale: number) {
  textObj.position.set(position.x, position.y, position.z);
  textObj.rotation.set(rotation.x, rotation.y, rotation.z);
  // Estoy en mi plano. Ajusto rotación en Z 
  textObj.rotateZ(angle);
  textObj.scale.set(scale, scale, 1);
  textObj.updateMatrix();

  textObj.geometry.computeBoundingBox();
  textObj.geometry.computeBoundingSphere();
}

interface ITextHeights {
  ascender: number;
  capHeight: number;
  median: number;
  middle: number;
  descender: number;
}
function adjustBasePoint(textGeom: THREE.ShapeBufferGeometry, widths: number[], heights: ITextHeights, baseV: textMultiPosTypeV, baseH: textMultiPosTypeH) {
  let adjX = 0;
  let adjY = 0;
  switch (baseV) {
    case textMultiPosTypeV.ASCENDER: adjY = heights.ascender; break;
    case textMultiPosTypeV.MEDIAN: adjY = heights.median; break;
    case textMultiPosTypeV.DESCENDER: adjY = heights.descender; break;
    case textMultiPosTypeV.BASELINE: break;
  }
  switch (baseH) {
    case textMultiPosTypeH.LEFT: break;
    case textMultiPosTypeH.MIDDLE: adjX = widths[0] * 0.5; break;
    case textMultiPosTypeH.RIGHT: adjX = widths[0]; break;
  }
  textGeom.translate(-adjX, -adjY, 0);
}
function getTextHeights(font: THREE.Font, size: number): ITextHeights {
  const data = font.data as any;
  const totalH = data.ascender - data.descender;
  const factor = size / totalH;
  return {
    ascender: data.ascender * factor,
    capHeight: data.ascender * factor,
    median: (totalH * 0.5 + data.descender) * factor,
    middle: (totalH * 0.5 + data.descender) * factor,
    descender: data.descender * factor,
  };
}
export function getTextWidths(text: string, size: number, font: THREE.Font): number[] {
  const data = font.data as any;
  const linesWidth = [0];
  let lineC = 0;
  const chars = String(text).split("");
  const scale = size / data.resolution;
  for (const char of chars) {
    if (char === "\n") {
      lineC++;
      linesWidth[lineC] = 0;
    } else {
      const glyph = data.glyphs[char] || data.glyphs["?"];
      const w = glyph.ha * scale as number;
      linesWidth[lineC] += w;
    }
  }
  return linesWidth;
}

/* ************************************************************ */
//  MATERIAL MANAGEMENT (SHARED)
/* ************************************************************ */

class textMaterialsCache {

  public color: IColor;
  public side: sdfDoubleSidedType;
  private material!: THREE.MeshBasicMaterial;

  constructor(side: sdfDoubleSidedType, color: IColor) {
    this.color = color;
    this.side = side;
    this.material = new THREE.MeshBasicMaterial({
      color: new THREE.Color(this.color.r / 255, this.color.g / 255, this.color.b / 255),
      transparent: false,
      opacity: 1,
      side: this.side === sdfDoubleSidedType.NONE ? THREE.DoubleSide : THREE.FrontSide,
    });
  }
  public getMaterial() {
    return this.material;
  }
}

const fontMaterialCache: textMaterialsCache[] = [];
function getMaterialTextFromCache(opts: TextOptsBuilder): THREE.MeshBasicMaterial {
  const { color, doubleSided } = opts
  for (const mat of fontMaterialCache) {
    if (mat.side === doubleSided && equalsColor(mat.color, color)) {
      return mat.getMaterial();
    }
  }
  const newMarkMaterial = new textMaterialsCache(doubleSided, color);
  fontMaterialCache.push(newMarkMaterial);
  return newMarkMaterial.getMaterial();
}
export function cleanFontMaterialCache() {
  for (const mat of fontMaterialCache) {
    const material = mat.getMaterial();
    material.map?.dispose();
    material.map = null
    material.dispose();
  }
  fontMaterialCache.length = 0;
}
