const WorkingPlaneIcon = ({ ...props }) => {
  return (
    <svg className="h-6 w-6" viewBox="0 0 100 100" fill="currentColor" {...props}>
      <path d="M13.1449 75.833L13.0449 41.194L41.9379 57.981L42.0299 90.879L41.0319 90.883L41.5319 90.019L41.0319 90.883L42.0299 90.879L42.0319 92.622L13.1449 75.833ZM15.1369 74.681L40.0249 89.145L39.9399 59.138L15.0549 44.674L15.1369 74.681Z" />
      <path d="M86.848 40.526L85.3571 41.39L56.7271 57.914L56.82 92.549L86.947 75.154L86.848 40.526Z" />
      <path d="M20.4858 25.353L50.6038 7.95801L79.4898 24.741L49.8648 41.847L49.3628 42.136L20.4858 25.353ZM49.3668 40.98L49.8688 40.118L49.3668 40.98ZM24.4718 25.356L49.3658 39.825L75.4998 24.737L50.6038 10.272L24.4718 25.356Z" />
    </svg>
  );
};

export default WorkingPlaneIcon;