import * as THREE from "three";
import { IPoint } from "../math/types";
import { FixedStack } from '../helpers/arrays';
import { GraphicProcessor } from "lib/graphic-processor";
import { EventManager } from "lib/operations/event-manager";
import { ObserverManager } from "lib/events/event-bus";
import { MouseCoordinatesAction, MousePositionActionType } from "lib/events/mouse-position";
import { RaycasterModel } from "./raycaster";
import { snapSettings, SnapTool } from "lib/operations/snap/snap";
import { vector3Equals } from "lib/math/epsilon";

export class CoordinateResolver {

  private graphicProcessor: GraphicProcessor;
  private eventManager: EventManager;

  private coordinateObserver: ObserverManager<MouseCoordinatesAction> = new ObserverManager();

  private mousePosition: IPoint = { x: 0, y: 0, z: 0 };
  get mouseCoordinates(): IPoint { return this.mousePosition; }

  pointsList = new FixedStack<IPoint>(5);
  raycaster: RaycasterModel;
  snap: SnapTool;

  constructor(graphicProc: GraphicProcessor) {
    this.graphicProcessor = graphicProc;
    this.eventManager = new EventManager(this.graphicProcessor);
    this.raycaster = new RaycasterModel(this.graphicProcessor);
  }

  subscribeMouseCoordinateListener() {
    this.eventManager.connectMouseMoveEvent(this.mouseCoordinateChanged);
    this.snap = new SnapTool(this.graphicProcessor);
  }
  unsubscribeMouseCoordinateListener() {
    this.eventManager.disconnectMouseMoveEvent(this.mouseCoordinateChanged);
    this.eventManager = undefined!;
    this.pointsList.clear();
    this.pointsList = undefined!;
    this.raycaster.clearRaycaster();
    this.raycaster = undefined!;
    this.snap.stopOperationSnap();
    this.snap = undefined!;
  }
  private mouseCoordinateChanged = (event: PointerEvent) => {
    const raycastedPoint = this.calculateMouseCoordinates(event);

    // snap filter
    const prevPto = this.pointsList.getLast();
    let snapPoint = this.snap.filterPoint(prevPto ?? raycastedPoint, raycastedPoint);
    
    const noSnapApplied = vector3Equals(raycastedPoint, snapPoint);
    const planeManager = this.graphicProcessor.getPlaneManager();
    
    // ortho mode
    if (snapSettings.orto && noSnapApplied && !planeManager.isUserPlaneActive) {
      snapPoint = this.filterOrtoAngle(prevPto, snapPoint);
    }

    this.mousePosition.x = snapPoint.x;
    this.mousePosition.y = snapPoint.y;
    this.mousePosition.z = snapPoint.z;
  };
  private calculateMouseCoordinates(event: PointerEvent): IPoint {

    const viewPort = this.graphicProcessor.getActiveViewport();
    const rect = viewPort.elemHTML!.getBoundingClientRect();
    const { width, height, left, top } = rect;
    const cursorCamera = viewPort.camera!;

    const planeManager = this.graphicProcessor.getPlaneManager();
    planeManager.setMainPlaneFromView(viewPort.viewPlaneType);

    const x = event.clientX - left;
    const y = event.clientY - top;
    const xx = (2 * x) / width - 1;
    const yy = 1 - (2 * y) / height;
    const rMouse = new THREE.Vector2(xx, yy);

    if (this.raycaster) {
      const pto = this.mousePosition;
      const size = this.graphicProcessor.getSizeUnitFromPixelUnit(4, pto);
      this.raycaster.setRaycasterThreshold(size);
      this.raycaster.intersectObjects(rMouse, cursorCamera);

      const planeManager = this.graphicProcessor.getPlaneManager();
      return planeManager.filterPointPlane(this.raycaster.ray);
    }
    return { x: 0, y: 0, z: 0 };
  }
  private filterOrtoAngle(prevPto: IPoint | undefined, pto: IPoint): IPoint {
    if (prevPto === undefined) return pto;
    const planeManager = this.graphicProcessor.getPlaneManager();
    const currPlane = planeManager.activePlane;
    if (currPlane.locked) {
      const p0 = currPlane.getRelativePoint(prevPto);
      const p1 = currPlane.getRelativePoint(pto);
      const f = this.snap.filterOrto(p0, p1);
      return currPlane.getAbsolutePoint(f);
    } else {
      return this.snap.filterOrto(prevPto, pto);
    }
  }

  // ************************************************************************

  subscribe(listener: (action: MouseCoordinatesAction) => void) {
    this.coordinateObserver.subscribe(listener);
  }
  unsubscribe(listener: (action: MouseCoordinatesAction) => void) {
    this.coordinateObserver.unsubscribe(listener);
  }
  dispatchChangeCoordinate(point?: IPoint): void {
    if (point === undefined) point = this.mousePosition;
    this.coordinateObserver.dispatch({
      type: MousePositionActionType.CHANGE_MOUSE_COORDINATES,
      payload: { point },
    });
  }
  dispatchSaveCoordinate(point?: IPoint) {
    if (point === undefined) point = this.mousePosition;
    this.pointsList.push(point);
    this.coordinateObserver.dispatch({
      type: MousePositionActionType.SAVE_MOUSE_COORDINATES,
      payload: { point },
    });
  }
}
